export const ParkingCalenderIcon = (props) => (
    <svg
        width={22}
        height={22}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19 6.556v-.667A2.892 2.892 0 0 0 16.111 3H5.889A2.892 2.892 0 0 0 3 5.889v.667h16ZM3 7.889v8.222A2.892 2.892 0 0 0 5.889 19h10.222A2.892 2.892 0 0 0 19 16.111V7.889H3Zm4.222 8.444a1.11 1.11 0 1 1 0-2.221 1.11 1.11 0 0 1 0 2.221Zm0-4a1.11 1.11 0 1 1 0-2.221 1.11 1.11 0 0 1 0 2.221Zm3.778 4a1.11 1.11 0 1 1 0-2.221 1.11 1.11 0 0 1 0 2.221Zm0-4a1.11 1.11 0 1 1 0-2.221 1.11 1.11 0 0 1 0 2.221Zm3.778 0a1.11 1.11 0 1 1 0-2.221 1.11 1.11 0 0 1 0 2.221Z"
            fill={props?.fill ?? "#c1c5cb"}
        />
    </svg>
)