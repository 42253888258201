import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { yesOrNoLang } from '../../../utils/accountUtiles'
import { TextBox, ToggleButtonComponent } from '../../../components'
import { useStyles } from '../styles'

export const RegisteredDetails = ({
    data = "",
    updateState = () => false,
    t = () => false
}) => {
    const classes = useStyles()
    return (
        <Box className={classes.box} marginTop="16px">
            <Typography className={classes.title}>{t("Registered Detail")}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={2}>
                    <ToggleButtonComponent
                        options={yesOrNoLang(t)}
                        value={data?.registered_company}
                        onChange={(value) => updateState("registered_company", value)}
                        label={t("Registered Company")}
                    />
                </Grid>
                {
                    data?.registered_company &&
                    <Grid item xs={12} sm={6} md={2}>
                        <TextBox
                            isrequired
                            label={t("CR Number")}
                            placeholder={"Enter CR Number"}
                            onChange={(value) => updateState("cr_number", value.target.value)}
                            value={data?.cr_number}
                            errorMessage={data?.error?.cr_number}
                            isError={data?.error?.cr_number?.length > 0}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={2}>
                    <ToggleButtonComponent
                        options={yesOrNoLang(t)}
                        value={data?.tax_registered}
                        onChange={(value) => updateState("tax_registered", value)}
                        label={t("Tax Registered")}
                    />
                </Grid>
                {
                    data?.tax_registered &&
                    <Grid item xs={12} sm={6} md={2}>
                        <TextBox
                            isrequired
                            label={t("Tax Number")}
                            placeholder={t("Enter Tax Number")}
                            onChange={(value) => updateState("tax_number", value.target.value)}
                            value={data?.tax_number}
                            errorMessage={data?.error?.tax_number}
                            isError={data?.error?.tax_number?.length > 0}
                        />
                    </Grid>
                }
            </Grid>
        </Box>
    )
}