import React from "react";
import { withNavBars } from "../../HOCs";
import { PropertyFinderDetails } from "./propertyFinderDetails";

class PropertyFinderDetailsParent extends React.Component {
    render() {
        return <PropertyFinderDetails />;
    }
}

export default withNavBars(PropertyFinderDetailsParent);
