import { Button, Grid, Box } from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import { LoginSuccess } from "../../router/access";
import { LocalStorageKeys, RetainLocalStorage } from "../../utils";
import { Routes } from '../../router/routes';
import { withStyles } from "@mui/styles";
import Lottie from "react-lottie";
import animation from './404JsonLottie.json'

const styles = (theme) => ({
  root: {
    backgroundSize: "cover",
    height: "100vh",
  },
  goHomeBtn: {
    backgroundColor: '#5078E1',
    color: '#fff'
  },
  textAlign: {
    textAlign: 'center'
  }
});

class NotFound extends React.Component {
  goHome = () => {
    if (localStorage.getItem(LocalStorageKeys.permission)) {

      let path = LoginSuccess(JSON.parse(localStorage.getItem(LocalStorageKeys.permission)));
      this.props.history.push(path);
    } else {
      RetainLocalStorage();
      this.props.history.push(Routes.login);
    }
  };
  render() {

    const { classes } = this.props;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      // here is where we will declare lottie animation
      // "animation" is what we imported before animationData: animation,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className={classes.root}>
        <Grid
          container
          style={{ height: "100vh", width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            {/* <Typography variant="h1">404</Typography>
          <Typography variant="h2">Page not Found</Typography> */}
            {/* <Link href="/" underline="hover" onClick={this.goHome}>
            Go Home
          </Link> */}
            <Lottie options={defaultOptions} height={300} width={300} />
            <Box className={classes.textAlign}>
              {" "}
              <Button
                className={classes.goHomeBtn}
                onClick={() => this.goHome()}
              >
                Go Home
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(NotFound));
