import React from "react";
import { withNavBars } from "../../HOCs";
import { UnitTableDetails } from "./unitTableDetails";

class UnitTableDetailsParent extends React.Component {
    render() {
        return <UnitTableDetails />
    }
}

export default withNavBars(UnitTableDetailsParent);
