import * as React from "react"
const SecurityViolationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={-0.17}
        x2={0.98}
        y1={-0.318}
        y2={1.086}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#dfe9f2" />
        <stop offset={0.391} stopColor="#d6e0e9" />
        <stop offset={1} stopColor="#bfc8d1" />
      </linearGradient>
      <linearGradient
        id="a"
        x2={1}
        y1={-169.357}
        y2={-169.357}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={0.5}
        x2={0.5}
        y1={1.047}
        y2={0.418}
      />
      <linearGradient
        id="d"
        x1={0.263}
        x2={1.035}
        y1={-0.256}
        y2={1.016}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#5961c3" />
        <stop offset={1} stopColor="#3a41ac" />
      </linearGradient>
    </defs>
    <g data-name="Group 113647">
      <path
        fill="url(#b)"
        d="M0 0h1.455v3.273H0z"
        data-name="Rectangle 57521"
        transform="translate(18.909 8.529)"
      />
      <path
        fill="url(#a)"
        d="M12 9.545A2.545 2.545 0 1 0 14.544 7 2.545 2.545 0 0 0 12 9.545Z"
        data-name="Path 100478"
        transform="translate(-.364 -3.562)"
      />
      <path
        fill="url(#c)"
        d="M31 8.545A2.545 2.545 0 1 0 33.544 6 2.545 2.545 0 0 0 31 8.545Z"
        data-name="Path 100479"
        transform="translate(-5.545 -3.289)"
      />
      <path
        fill="url(#d)"
        d="M32.18 14a2.9 2.9 0 0 0-2.412 1.284l-3.041 3.444-3.006-2.345a1.088 1.088 0 0 0-1.356 1.7l3.6 3.231a1.456 1.456 0 0 0 1.668.195l2-1.092L29.8 22l.153 1.455.065.625.007.109.7 10.538a1.091 1.091 0 0 0 2.182 0l.688-8.6a2.542 2.542 0 0 0 1.494-2.313v-6.905A2.909 2.909 0 0 0 32.18 14Z"
        data-name="Path 100480"
        transform="translate(-3.091 -5.471)"
      />
      <path
        fill="#5059c9"
        d="m25.455 3.438-1.4-1.959a.3.3 0 0 1 .292-.473l5.584.881a.726.726 0 0 1 .615.718v.833l-2.545.727Z"
        data-name="Path 100481"
      />
      <path
        fill="#343434"
        d="M32 16.529v1.455h-5.142l-.153-1.455Z"
        data-name="Path 100482"
      />
      <path
        fill="#35c1f1"
        d="M10.547 8.529A2.545 2.545 0 0 0 8 11.074v7.273a2.542 2.542 0 0 0 1.494 2.313l.688 8.6a1.091 1.091 0 0 0 2.182 0l.7-10.538.007-.109v-.067l.013-.195c0-.023-.007-.045-.007-.068l.007-.3v-6.909a2.545 2.545 0 0 0-2.537-2.545Z"
        data-name="Path 100483"
      />
      <path
        fill="#7b83eb"
        d="M30.545 4.893H24s0-1.091 1.455-1.455h5.091Z"
        data-name="Path 100484"
      />
    </g>
  </svg>
)
export default SecurityViolationIcon
