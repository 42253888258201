export const functionalLocationTable = (t) => [
    { title: "", field: 'icon' },
    { title: t("Image"), field: "image_url" },
    { title: t("Location Name"), field: "name" },
    { title: t("Description"), field: "description" },
    { title: t("Latitude & Longitute"), field: "latitude_data" },
    { title: t("Associate with parent location"), field: "is_parent" },
    { title: t("Status"), field: "status" },
];
export const functionalLocationPath = [
    "image_url",
    "name",
    "description",
    "latitude_data",
    "is_parent",
    "status"
];

export const functionalLocationDataType = [
    { type: ["function_location_more"], name: "icon" },
    { type: ["avatarmanagement"], name: "image_url" },
    { type: ["description"], name: "name" },
    { type: ["description"], name: "description" },
    { type: ["text"], name: "latitude_data" },
    { type: ["text"], name: "is_parent" },
    { type: ["status"], name: "status" },
];