export const ParkingAreaPath = ["propertyName", "vehicleParkingArea", "description", "status"]

export const ParkingAreaHeading = (t) => [
    { title: t("Property Name"), field: "propertyName" },
    { title: t("Parking Area Name"), field: "vehicleParkingArea" },
    { title: t("Notes"), field: "description" },
    { title: t("Status"), field: "status", },
    { title: "", field: "icon" },
]

export const ParkingAreaType = [
    { type: ["text"], name: "propertyName", width: "200px" },
    { type: ["text"], name: "vehicleParkingArea", width: "200px" },
    { type: ["text"], name: "description", width: '250px' },
    { type: ["status"], name: "status", width: "600px" },
    { type: ["more"], icon: "icon" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]