import React from "react";
import { withNavBars } from "../../HOCs";
import { ServiceProviderListing } from "./serviceProviderListing";

class ServiceProviderListingParent extends React.Component {
  render() {
    return <ServiceProviderListing />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ServiceProviderListingParent, props);
