import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import InspectionMaster from './InspectionMaster'


class InspectionMasterParent extends Component {
    render() {
        return (
            <div><InspectionMaster /></div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(InspectionMasterParent, props) 
