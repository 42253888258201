import React from 'react';
import { Grid, Typography } from "@mui/material";
import { propertyStyles } from "./style";

export const BusinessDetailsViewing = (props) => {
    const { t } = (props);
    const classes = propertyStyles();

    return (

        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container className={classes.imagebox1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography className={classes.title}>
                                        {t("AUTHORITY & UTILITIES DETAILS")}
                                    </Typography>

                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>
                                        {t("Municipality Authority")}
                                    </Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.municipality_authority ? props?.data?.municipality_authority : " - "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>
                                        {t("Electricity & Water Authority")}
                                    </Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.electrical_water_authority ? props?.data?.electrical_water_authority : " - "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>
                                        {t("Utilities Electricity Connection")}
                                    </Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.util_electricity_connection ? props?.data?.util_electricity_connection : " - "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>
                                        {t("Utilities Natural Gas Connection")}
                                    </Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.util_gas_connection ? props?.data?.util_gas_connection : " - "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>
                                        {t("Utilities Water Connection")}
                                    </Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.util_water_connection ? props?.data?.util_water_connection : " - "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>
                                        {t("National Geographic Addressing")}
                                    </Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.smart_geographic_addressing ? props?.data?.smart_geographic_addressing : " - "}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>


    )
}