import React from "react";
import { withNavBars } from "../../../HOCs";
import ParkingSlot from './parkingSlot';

class ParkingSlotParent extends React.Component {
  render() {
    return <ParkingSlot {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ParkingSlotParent, props);
