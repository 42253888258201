import React from 'react'
import { DayCalendarStyles } from './style'
import { Grid, Box } from '@mui/material'
import moment from 'moment'
import { eachHourOfInterval } from 'date-fns'
import { Stack, Typography } from '@mui/material'

export const DailyCalendar = ({ progress = 0, list = [], handleEvent = () => false }) => {

    const classes = DayCalendarStyles()

    const [data, setData] = React.useState([])
    const [loadMore, setLoadMore] = React.useState({})

    React.useEffect(() => {
        const result = eachHourOfInterval({
            start: new Date(2022, 7, 17, 0),
            end: new Date(2022, 7, 17, 23)
        })
        setData(result)
    }, [])

    const load = (data) => {
        setLoadMore(data)
    }
    return (
        <Box className={classes.calRow}>
            {
                data?.map(e => {
                    return (
                        <>
                            <Grid container>
                                <Grid item md={1} sm={1} className={classes.timeCell}>
                                    <Box marginTop="12px">
                                        {moment(e).format("HH:mm")}
                                    </Box>
                                </Grid>
                                <Grid item md={11} sm={11} className={classes.eventCell} p={1}>
                                    <Box className={classes.contentbox}>
                                        {
                                            list?.map((li) => {
                                                return (
                                                    (li?.event?.length < 2 || loadMore?.time === li?.time) ?
                                                        <Stack className={loadMore?.time === li?.time ? classes.rowOverflow : ""} spacing={0.5}>
                                                            {li?.event?.map((ev) => {
                                                                return (
                                                                    li?.time === moment(e).format("HH:mm:ss") &&
                                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        className={ev?.is_expired ? classes.expiredStrip : ev?.booking_status_id === 1 ? classes.successStrip : classes.cancelledStrip} p={0.7} onClick={() => handleEvent({ data: ev, date: "" })}>
                                                                        <Box display="flex" alignItems="center">
                                                                            <Typography className={classes.event_label}>{ev?.title}</Typography>
                                                                        </Box>
                                                                    </Stack>
                                                                )
                                                            })}
                                                        </Stack> :
                                                        (
                                                            li?.time === moment(e).format("HH:mm:ss") &&
                                                            <Stack>
                                                                <Stack direction={"row"} spacing={2} alignItems={"center"}
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    className={li?.event?.[0]?.is_expired ? classes.expiredStrip : li?.event?.[0]?.booking_status_id === 1 ? classes.successStrip : classes.cancelledStrip}
                                                                    p={0.7} onClick={() => handleEvent({ data: li?.event?.[0], date: "" })}>
                                                                    <Box display="flex" alignItems="center">
                                                                        <Typography className={classes.event_label}>{li?.event?.[0]?.title}</Typography>
                                                                    </Box>
                                                                </Stack>
                                                                <Box onClick={() => load({ time: moment(e).format("HH:mm:ss") })} sx={{ cursor: "pointer" }}> <Typography className={classes.event_label}>{li?.event?.length - 1}+ More</Typography></Box>
                                                            </Stack>
                                                        )
                                                )

                                            })
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    )
                })
            }
        </Box>
    )
}
