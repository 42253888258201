import * as React from "react";

export const FilterIcon = (props) => {

    const {
        width = 16,
        height = 16,
    } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10.25 10.257"
            width={width}
            height={height}
            {...props}
        >
            <path id="icons8-funnel" d="M6.694,6A.7.7,0,0,0,6,6.694v.858a2.085,2.085,0,0,0,.794,1.636h0l2.815,2.2v4.2a.417.417,0,0,0,.659.339l1.944-1.389a.417.417,0,0,0,.175-.339V11.387L15.2,9.19h0A2.085,2.085,0,0,0,16,7.553V6.694A.7.7,0,0,0,15.306,6Zm.139.833h8.333v.719a1.248,1.248,0,0,1-.476.981l-2.974,2.32a.417.417,0,0,0-.161.329v2.8l-1.111.794V11.183a.417.417,0,0,0-.161-.329L7.31,8.534a1.248,1.248,0,0,1-.476-.981Z" transform="translate(-5.875 -5.875)" fill="#091b29" stroke="#091b29" stroke-width="0.25" />
        </svg>
    )
}
