import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({


    btnroot: {
        backgroundColor: "white",
        padding: "8px 14px 14px 14px",
    },
    previousbtn: {
        backgroundColor: "white",
        color: theme.typography.color.primary,
        border: "1px solid #CED3DD",
        borderRadius: theme.palette.borderRadius,
        padding: "8px 12px",
        boxShadow: "none",
        '&:hover': {
            backgroundColor: "white",
        }
    },
    submitbtn: {
        borderRadius: theme.palette.borderRadius,
        marginInlineStart: "8px",
        padding: "8px 12px",
        boxShadow: "none"
    },

    content: {
        //backgroundColor: "white",
        margin: "6px",
        borderRadius: "4px",
        //boxShadow: "0px 0px 16px #00000014",
        height: `calc(100vh - 252px)`,
        overflowX: 'hidden',
        padding: "8px"

    },
    preview: {
        borderRadius: "4px",

    },
    btnRoot: {
        backgroundColor: "white",
        boxShadow: "0px 10px 25px #0000000A"
    }
}));