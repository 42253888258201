export const SecurityPersonals = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={32}
        {...props}
    >
        <defs>
            <linearGradient
                id="b"
                x1={0.212}
                y1={0.046}
                x2={0.784}
                y2={1.408}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#5961c3" />
                <stop offset={1} stopColor="#3a41ac" />
            </linearGradient>
            <linearGradient
                id="a"
                x1={0.25}
                y1={0.5}
                x2={0.882}
                y2={0.5}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#c48f0c" />
                <stop offset={0.251} stopColor="#d19b16" />
                <stop offset={0.619} stopColor="#dca51f" />
                <stop offset={1} stopColor="#e0a922" />
            </linearGradient>
            <linearGradient id="c" x1={153.208} x2={153.84} xlinkHref="#a" />
            <linearGradient
                id="f"
                x1={0.843}
                y1={0.172}
                x2={-0.382}
                y2={0.811}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#e5a505" />
                <stop offset={0.011} stopColor="#e9a804" />
                <stop offset={0.061} stopColor="#f4b102" />
                <stop offset={0.133} stopColor="#fbb600" />
                <stop offset={0.331} stopColor="#fdb700" />
            </linearGradient>
            <linearGradient
                id="g"
                x1={1.367}
                y1={0.173}
                x2={0.178}
                y2={0.793}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#fede00" />
                <stop offset={1} stopColor="#ffd000" />
            </linearGradient>
            <radialGradient
                id="d"
                cx={0.5}
                cy={0.5}
                r={0.507}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#ffcf54" />
                <stop offset={0.261} stopColor="#fdcb4d" />
                <stop offset={0.639} stopColor="#f7c13a" />
                <stop offset={1} stopColor="#f0b421" />
            </radialGradient>
            <radialGradient
                id="e"
                cx={0.072}
                cy={0.077}
                r={2.419}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#c26715" />
                <stop offset={0.508} stopColor="#b85515" />
                <stop offset={1} stopColor="#ad3f16" />
            </radialGradient>
        </defs>
        <g data-name="Group 102438">
            <path data-name="Rectangle 53878" fill="none" d="M0 0h32v32H0z" />
            <g data-name="icons8-police (1)" transform="translate(-1 1)">
                <ellipse
                    data-name="Ellipse 129462"
                    cx={8.78}
                    cy={4.976}
                    rx={8.78}
                    ry={4.976}
                    transform="translate(8.585 3)"
                    fill="#5059c9"
                />
                <path
                    data-name="Path 96912"
                    d="M17.366 30.571C12.193 30.571 8 33.452 8 36.592v.669a1.17 1.17 0 0 0 1.171 1.171h16.39a1.17 1.17 0 0 0 1.171-1.171v-.669c0-3.14-4.193-6.021-9.366-6.021Z"
                    transform="translate(0 -11.432)"
                    fill="url(#b)"
                />
                <path
                    data-name="Path 96913"
                    d="M20.293 18.851a9.222 9.222 0 0 1-2.927 6.978 9.222 9.222 0 0 1-2.927-6.978v-2.388h5.854Z"
                    fill="#fff"
                />
                <path
                    data-name="Path 96914"
                    d="M20.293 18.851a9.222 9.222 0 0 1-2.927 6.978 9.222 9.222 0 0 1-2.927-6.978v-2.388h5.854Z"
                    fill="#fff"
                />
                <path
                    data-name="Path 96915"
                    d="M20.293 18.805c0 1.11-2.927 2.341-2.927 2.341s-2.927-1.231-2.927-2.341v-4.118h5.854Z"
                    fill="#d6a121"
                />
                <path
                    data-name="Path 96916"
                    d="m16.195 21.731.585.585-.457 2.393a6.986 6.986 0 0 0 1.043 1.143 6.978 6.978 0 0 0 1.043-1.143l-.457-2.392.585-.585-1.171-.586Z"
                    fill="#3a41ac"
                />
                <path
                    data-name="Path 96917"
                    d="M32.7 18h-.308v2.341h.408a.6.6 0 0 0 .609-.433l.2-1A.834.834 0 0 0 32.7 18Z"
                    transform="translate(-10.113 -6.22)"
                    fill="url(#a)"
                />
                <path
                    data-name="Path 96918"
                    d="M14.427 18h.308v2.341h-.412a.6.6 0 0 1-.609-.433l-.2-1a.834.834 0 0 1 .913-.908Z"
                    transform="translate(-2.281 -6.22)"
                    fill="url(#c)"
                />
                <path
                    data-name="Path 96919"
                    d="M15.12 13.477a13.279 13.279 0 0 0-.12 1.872v3.921c0 2.006 3.161 4.465 5.268 4.465s5.268-2.459 5.268-4.465v-3.921a14.185 14.185 0 0 0-.075-1.513Z"
                    transform="translate(-2.902 -4.344)"
                    fill="url(#d)"
                />
                <path
                    data-name="Path 96920"
                    d="M19.854 10.545C15.171 10.545 14 12 14 13.738a3.519 3.519 0 0 0 .585 2.341c0-1.756.585-4.683 2.341-4.683.742 0 1.331 1.171 2.927 1.171 1.348 0 1.683-1.171 2.927-1.171 1.756 0 2.341 2.927 2.341 4.683.585-.585.585-2.341.585-2.341.001-2.008-1.169-3.193-5.852-3.193Z"
                    transform="translate(-2.488 -3.128)"
                    fill="url(#e)"
                />
                <ellipse
                    data-name="Ellipse 129463"
                    cx={4.683}
                    cy={2.341}
                    rx={4.683}
                    ry={2.341}
                    transform="translate(12.683 7.683)"
                    fill="#5059c9"
                />
                <path
                    data-name="Path 96921"
                    d="M17.366 7.098c-3.233 0-5.854.561-5.854 1.254v2.258s.585-.585 5.854-.585 5.854.585 5.854.585V8.352c-.001-.693-2.621-1.254-5.854-1.254Z"
                    fill="#7b83eb"
                />
                <path
                    data-name="Path 96922"
                    d="M25.171 10.585 24 10v2.341a1.17 1.17 0 0 0 1.171-1.171Z"
                    transform="translate(-6.634 -2.902)"
                    fill="url(#f)"
                />
                <path
                    data-name="Path 96923"
                    d="M23.171 10 22 10.585v.585a1.17 1.17 0 0 0 1.171 1.171Z"
                    transform="translate(-5.805 -2.902)"
                    fill="url(#g)"
                />
            </g>
        </g>
    </svg>
)
