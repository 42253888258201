export const dummyjson = [
    {
        "name": "Manager DashBoard",
        "icon": "images/sampleimage.svg",
        "id": "b69b6343-dc95-408b-ab7d-96a0921ec1e9",
        "router": "dasdboard",
        "nav_icon": "DashBoard",
        "link": [
            "dashboard"
        ],
        "active_status": true,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Lease Manager DashBoard",
        "icon": "images/sampleimage.svg",
        "nav_icon": "DashBoard",
        "id": "923de3d6-6b99-41e0-a1fe-1724b0b2559a",
        "router": "leasemanagerdashboard",
        "link": [
            "leasemanagerdashboard"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Security Manager DashBoard",
        "icon": "images/sampleimage.svg",
        "nav_icon": "DashBoard",
        "id": "4065cb9e-d4d0-4d2f-a167-ca33fed11ead",
        "router": "securitydashboard",
        "link": [
            "securitydashboard"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Organization",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Organization",
        "id": "0b511ea6-5c06-4fdc-9123-e793c44088f6",
        "router": null,
        "link": [
            "companyMaster",
            "companyCreation",
            "editcompany",
            "jobs",
            "department"
        ],
        "active_status": true,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": [
            {
                "name": "Companies",
                "icon": "images/document.svg",
                "nav_icon": "Companies",
                "id": "f59472eb-37ac-44c4-8055-11e776f08d58",
                "parent_id": "0b511ea6-5c06-4fdc-9123-e793c44088f6",
                "router": "companyMaster",
                "link": [
                    "companyMaster",
                    "companyCreation",
                    "editcompany"
                ],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Companies Creation",
                "icon": "images/document.svg",
                "id": "e5d147d2-9cca-492e-8cb7-619dea5a928a",
                "parent_id": "0b511ea6-5c06-4fdc-9123-e793c44088f6",
                "router": "companyCreation",
                "active_status": true,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Companies Edit",
                "icon": "images/document.svg",
                "id": "aff42570-6582-4a17-99a9-bc14f23a1359",
                "parent_id": "0b511ea6-5c06-4fdc-9123-e793c44088f6",
                "router": "editcompany",
                "active_status": true,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Department",
                "icon": "images/document.svg",
                "nav_icon": "Department",
                "id": "c49712c8-9204-4663-84ab-34bfaa55428e",
                "parent_id": "0b511ea6-5c06-4fdc-9123-e793c44088f6",
                "router": "department",
                "link": [
                    "department"
                ],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Section",
                "icon": "images/document.svg",
                "nav_icon": "Section",
                "id": "a91add24-361b-44c4-814b-2f6f320b9c15",
                "parent_id": "0b511ea6-5c06-4fdc-9123-e793c44088f6",
                "router": null,
                "link": [],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Jobs",
                "icon": "images/document.svg",
                "nav_icon": "Jobs",
                "id": "58647b45-6ca4-4fc2-a991-a401eb1d446e",
                "parent_id": "0b511ea6-5c06-4fdc-9123-e793c44088f6",
                "router": "jobs",
                "link": [
                    "jobs"
                ],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Users",
                "icon": "images/document.svg",
                "nav_icon": "Users",
                "id": "97939b1c-c0aa-43f7-9606-ba4f17169104",
                "parent_id": "0b511ea6-5c06-4fdc-9123-e793c44088f6",
                "router": null,
                "link": [],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            }
        ]
    },
    {
        "name": "Accounts",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Accounts",
        "id": "d47fd268-8474-4369-9134-aa5afb0777e1",
        "router": null,
        "link": [
            "vendorListing",
            "vendorCreation",
            "editVendor",
            "serviceProviderListing",
            "serviceProviderView",
            "serviceProviderCreateEdit"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": [
            {
                "name": "Customers",
                "icon": "images/document.svg",
                "nav_icon": "Customers",
                "id": "4e650520-24c0-4268-aed4-49b3b2e670e2",
                "parent_id": "d47fd268-8474-4369-9134-aa5afb0777e1",
                "router": null,
                "link": [],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Vendors",
                "icon": "images/document.svg",
                "nav_icon": "Vendors",
                "id": "9240d123-056a-4f4b-bdc5-7c34c3d631d1",
                "parent_id": "d47fd268-8474-4369-9134-aa5afb0777e1",
                "router": "vendorListing",
                "link": [
                    "vendorListing",
                    "vendorCreation",
                    "editVendor"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Vendors Creation",
                "icon": "images/document.svg",
                "id": "4731ee8a-ea13-4eb2-90de-3b8513820222",
                "parent_id": "d47fd268-8474-4369-9134-aa5afb0777e1",
                "router": "vendorCreation",
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                },
                "active_status": false
            },
            {
                "name": "Vendors Edit",
                "icon": "images/document.svg",
                "id": "ef763d23-6c24-418b-ad3b-8af01fd80c6e",
                "parent_id": "d47fd268-8474-4369-9134-aa5afb0777e1",
                "router": "editVendor",
                "active_status": false,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Service Providers",
                "icon": "images/document.svg",
                "nav_icon": "ServiceProviders",
                "id": "0cc76125-e561-472b-ab1f-e2bf6f4bc87a",
                "parent_id": "d47fd268-8474-4369-9134-aa5afb0777e1",
                "router": "serviceProviderListing",
                "link": [
                    "serviceProviderListing",
                    "serviceProviderView",
                    "serviceProviderCreateEdit"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Service Providers View",
                "icon": "images/document.svg",
                "id": "ab5b9fba-6816-4730-8ded-ce3007e99aad",
                "parent_id": "d47fd268-8474-4369-9134-aa5afb0777e1",
                "router": "serviceProviderView",
                "active_status": false,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Service Providers Create Edit",
                "icon": "images/document.svg",
                "id": "6b60c987-5051-4327-9d52-a9ab576dc51e",
                "parent_id": "d47fd268-8474-4369-9134-aa5afb0777e1",
                "router": "serviceProviderCreateEdit",
                "active_status": false,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            }
        ]
    },
    {
        "name": "Contacts",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Contacts",
        "id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
        "router": null,
        "link": [
            "contactsList",
            "contactView",
            "createContact",
            "residentTable",
            "residentTableDetail",
            "ownerTable",
            "ownerTableDetail",
            "salesagentlist",
            "salesagent",
            "salesview"
        ],
        "active_status": true,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": [
            {
                "name": "All Contacts",
                "icon": "images/document.svg",
                "nav_icon": "Contacts",
                "id": "1f38d9cb-f310-4cb1-a142-656e7a564838",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "contactsList",
                "link": [
                    "contactsList",
                    "contactView",
                    "createContact"
                ],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Contact View",
                "icon": "images/document.svg",
                "id": "68ed70c5-66a8-4771-86d9-6d8b395fff1d",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "contactView",
                "active_status": true,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Contact Creation",
                "icon": "images/document.svg",
                "id": "eaf3a7e6-4cbf-4dac-80ad-04f85a554108",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "createContact",
                "active_status": true,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Tenants & Residents",
                "icon": "images/document.svg",
                "nav_icon": "Tenant",
                "id": "deb1c2b7-b7a3-4fcd-86c4-72bf4a209d98",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "residentTable",
                "link": [
                    "residentTable",
                    "residentTableDetail"
                ],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Tenants & Residents Detail",
                "icon": "images/document.svg",
                "id": "766e0991-c042-4bf4-b2b2-760d459fbbfa",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "residentTableDetail",
                "active_status": true,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Landlords & Owners",
                "icon": "images/document.svg",
                "nav_icon": "LandLords",
                "id": "94a8f3ca-6182-48ff-9879-035d4ab21d17",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "ownerTable",
                "link": [
                    "ownerTable",
                    "ownerTableDetail"
                ],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Landlords & Owners Detail",
                "icon": "images/document.svg",
                "id": "69b513d7-fb1f-43ad-9019-7d03ee0c741c",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "ownerTableDetail",
                "active_status": true,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Sales Agents",
                "icon": "images/document.svg",
                "nav_icon": "SaleAgents",
                "id": "aa62e5b9-a46b-4384-864e-83dce7d66fbc",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "salesagentlist",
                "link": [
                    "salesagentlist",
                    "salesagent",
                    "salesview"
                ],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Sales Agent Creation Edit",
                "icon": "images/document.svg",
                "id": "00319f01-07c9-4e27-b02e-d90795d4292d",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "salesagent",
                "active_status": true,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Sales Agent View",
                "icon": "images/document.svg",
                "id": "216e91bf-b667-470b-ae57-9b3ff98eb659",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": "salesview",
                "active_status": true,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Brokers",
                "icon": "images/document.svg",
                "nav_icon": "Brokers",
                "id": "12136958-f98d-4327-9fa0-9238482eca75",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": null,
                "link": [],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Prospects",
                "icon": "images/document.svg",
                "nav_icon": "Prospects",
                "id": "1b615f58-e072-4417-b265-a561e7ac75f9",
                "parent_id": "860a9df0-5b6a-4838-8e6b-d993c7e40208",
                "router": null,
                "link": [],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            }
        ]
    },
    {
        "name": "Security Roles",
        "icon": "images/sampleimage.svg",
        "nav_icon": "SecurityRoles",
        "id": "bfe1fa13-fc93-4935-b23d-714151ab1df8",
        "router": null,
        "link": [],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": [
            {
                "name": "Role Builder",
                "icon": "images/document.svg",
                "nav_icon": "SecurityRoles",
                "id": "cb28f151-6c2d-4c6f-a0c3-1ea7c4b85391",
                "parent_id": "bfe1fa13-fc93-4935-b23d-714151ab1df8",
                "router": null,
                "link": [],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Workflow Builder",
                "icon": "images/document.svg",
                "nav_icon": "SecurityRoles",
                "id": "f060c430-b0af-4987-ad1f-0637cecb6d79",
                "parent_id": "bfe1fa13-fc93-4935-b23d-714151ab1df8",
                "router": null,
                "link": [],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Manage Roles",
                "icon": "images/document.svg",
                "nav_icon": "SecurityRoles",
                "id": "5a0eee06-49b9-435b-bc0d-e9ac06853a65",
                "parent_id": "bfe1fa13-fc93-4935-b23d-714151ab1df8",
                "router": null,
                "link": [],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            }
        ]
    },
    {
        "name": "Data Management",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Dashboard",
        "id": "3ca0cdf6-e471-4213-b6c3-09afa9796755",
        "router": null,
        "link": [
            "configure"
        ],
        "active_status": true,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": [
            {
                "name": "Import",
                "icon": "images/document.svg",
                "nav_icon": "Dashboard",
                "id": "0b119e71-494f-4b20-9cb6-443f3897021f",
                "parent_id": "3ca0cdf6-e471-4213-b6c3-09afa9796755",
                "router": "null",
                "link": [],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                },
                "children": [
                    {
                        "name": "General/Maintenance",
                        "icon": "images/document.svg",
                        "nav_icon": "Request",
                        "id": "f0e470e0-988a-4a8a-b6da-e3a263cd53c2",
                        "parent_id": "0bc8608a-3665-490e-9183-6b5c6bab9cd5",
                        "router": "request",
                        "link": [
                            "request"
                        ],
                        "active_status": false,
                        "type": "menu",
                        "permission": {
                            "create": true,
                            "update": true,
                            "read": true,
                            "delete": true
                        }
                    },
                    {
                        "name": "Access Request",
                        "icon": "images/document.svg",
                        "nav_icon": "Request",
                        "id": "1bb5871f-abf6-4050-8b34-7f110450b737",
                        "parent_id": "0bc8608a-3665-490e-9183-6b5c6bab9cd5",
                        "router": "agreementUnitAccess",
                        "link": [
                            "agreementUnitAccess"
                        ],
                        "active_status": false,
                        "type": "menu",
                        "permission": {
                            "create": true,
                            "update": true,
                            "read": true,
                            "delete": true
                        }
                    }
                ]
            },
            {
                "name": "Export",
                "icon": "images/document.svg",
                "nav_icon": "Dashboard",
                "id": "ab4f2c14-17bf-45fc-b7dc-6ae497e355d1",
                "parent_id": "3ca0cdf6-e471-4213-b6c3-09afa9796755",
                "router": "null",
                "link": [],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Auto Numbering",
                "icon": "images/document.svg",
                "nav_icon": "Dashboard",
                "id": "c1129a74-1a00-47c5-8251-67bd35ce09e6",
                "parent_id": "3ca0cdf6-e471-4213-b6c3-09afa9796755",
                "router": "configure",
                "link": [
                    "configure"
                ],
                "active_status": true,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            }
        ]
    },
    {
        "name": "Reports",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Reports",
        "id": "7d0e391d-ade0-4b61-b76f-bd83692014aa",
        "router": null,
        "link": [],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Subscribe",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Subscribe",
        "id": "eb3cff69-1155-4889-ac93-19667c9e5883",
        "router": null,
        "link": [],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Marketplace",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Marketplace",
        "id": "fb3fecd6-f92d-472c-b502-66cda63a36bb",
        "router": null,
        "link": [],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Companies",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Companies",
        "id": "de283932-4459-49fa-97e8-82127afb39ff",
        "router": "companyMaster",
        "link": [
            "companyMaster",
            "companyCreation",
            "editcompany"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Company Creation",
        "icon": "images/sampleimage.svg",
        "id": "9cfdd919-ec3e-4216-a93f-519a27ec08b7",
        "router": "companyCreation",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Company Edit",
        "icon": "images/sampleimage.svg",
        "id": "09fde659-eeda-4e6b-8e09-7c80ce6c42d4",
        "router": "editcompany",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Properties",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "5662bd56-fd46-4c32-b5bc-5fa9d69dac70",
        "router": "properties",
        "link": [
            "properties",
            "propertycreation",
            "propertyview",
            "block",
            "createBlockNew",
            "blockView",
            "floor",
            "createFloorNew",
            "floorDetails",
            "unit",
            "createUnitNew",
            "unitDetails"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Property Creation Edit",
        "icon": "images/sampleimage.svg",
        "id": "27a9f9a4-a46d-4fa6-8e79-bdfe677dc50c",
        "router": "propertycreation",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Property View",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "fbe0fcf0-87c3-410b-8925-0a956ba62058",
        "router": "propertyview",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Block",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "3e940388-b9ad-4b0b-999f-c9eb4c0db50c",
        "router": "block",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Block Creatiion Edit",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "489f65cd-82dc-48f0-8231-98baf64f0313",
        "router": "createBlockNew",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Block View",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "fbf35b95-6f8c-410a-8e64-c16a0c093e81",
        "router": "blockView",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Floor",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "ce874669-db68-4592-8fb9-3d66764fd2c1",
        "router": "floor",
        "active_status": true,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Floor Creation Edit",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "30c6b48b-e3f2-4fb5-91a7-0a4685ca0492",
        "router": "createFloorNew",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Floor View",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "15f826c9-f14b-495a-b48d-f76347bf4c10",
        "router": "floorDetails",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Unit",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "16bf8d5d-4006-42b5-8b97-52951d8d1d21",
        "router": "unit",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Unit Creation Edit",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "f4504fe1-642a-403a-a983-89592b2d092d",
        "router": "createUnitNew",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Unit View",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Properties",
        "id": "1ba9d14e-c17b-4c7e-9f34-dbf143b0696c",
        "router": "unitDetails",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Price Book",
        "icon": "images/sampleimage.svg",
        "nav_icon": "PriceBook",
        "id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
        "router": null,
        "link": [
            "pricingComponent",
            "pricingTable",
            "pricingTablePropertyView",
            "pricingTableUnitView",
            "unit_vacancy",
            "vacancy_details"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": [
            {
                "name": "Pricing Component",
                "icon": "images/document.svg",
                "nav_icon": "PricingComponent",
                "id": "b732b026-e406-46db-8b0f-5bdefe40b8bc",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "pricingComponent",
                "link": [
                    "pricingComponent"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Pricing Table",
                "icon": "images/document.svg",
                "nav_icon": "PricingTable",
                "id": "8c40c5f2-d131-4e20-92b2-3d5d515af597",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "pricingTable",
                "link": [
                    "pricingTable",
                    "pricingTablePropertyView",
                    "pricingTableUnitView"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Pricing Table Property View",
                "icon": "images/document.svg",
                "id": "f5f1a4de-e9f3-468b-873e-ec018d905619",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "pricingTablePropertyView",
                "active_status": false,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Pricing Table Unit View",
                "icon": "images/document.svg",
                "id": "f01b82fb-a440-49fe-ae16-546b7c0c4089",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "pricingTableUnitView",
                "active_status": false,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Short Term Rentals",
                "icon": "images/document.svg",
                "nav_icon": "ShortTermPricing",
                "id": "4c599e91-426d-4cbb-bc49-dd880c047bc7",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "null",
                "link": [],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Override Pricing",
                "icon": "images/document.svg",
                "nav_icon": "OverridePricing",
                "id": "7b1163ac-6e0c-49db-8e9c-29917b85fbb0",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "null",
                "link": [],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Vacant Period View",
                "icon": "images/document.svg",
                "nav_icon": "VacantPeriodView",
                "id": "f258a538-f390-43b4-ac80-251e3628190a",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "unit_vacancy",
                "link": [
                    "unit_vacancy",
                    "vacancy_details"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Vacant Period Detail",
                "icon": "images/document.svg",
                "id": "cc68a382-6481-4630-8bdc-c959ad635c00",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "vacancy_details",
                "active_status": false,
                "type": "page",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Delivery Milestones",
                "icon": "images/document.svg",
                "nav_icon": "DeliveryMilestone",
                "id": "4bcf127f-1442-4790-b2f6-872fcda52a87",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "null",
                "link": [],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Price Appraisals",
                "icon": "images/document.svg",
                "nav_icon": "PriceAppraisals",
                "id": "7e03dc9a-286b-40c2-82ba-4dc77df00930",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "null",
                "link": [],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "RERA Price Chart",
                "icon": "images/document.svg",
                "nav_icon": "Dashboard",
                "id": "1d0d9b62-1ac3-43e5-8cb1-e6278ed8db4f",
                "parent_id": "f83532c7-c0fb-49e1-aed3-600ea38dac03",
                "router": "null",
                "link": [],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            }
        ]
    },
    {
        "name": "Requests",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Request",
        "id": "0bc8608a-3665-490e-9183-6b5c6bab9cd5",
        "router": null,
        "link": [
            "request",
            "agreementUnitAccess"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": [
            {
                "name": "General/Maintenance",
                "icon": "images/document.svg",
                "nav_icon": "Request",
                "id": "f0e470e0-988a-4a8a-b6da-e3a263cd53c2",
                "parent_id": "0bc8608a-3665-490e-9183-6b5c6bab9cd5",
                "router": "request",
                "link": [
                    "request"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Access Request",
                "icon": "images/document.svg",
                "nav_icon": "Request",
                "id": "1bb5871f-abf6-4050-8b34-7f110450b737",
                "parent_id": "0bc8608a-3665-490e-9183-6b5c6bab9cd5",
                "router": "agreementUnitAccess",
                "link": [
                    "agreementUnitAccess"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            }
        ]
    },
    {
        "name": "Property Search",
        "icon": "images/sampleimage.svg",
        "nav_icon": "PropertySearch",
        "id": "57ab4625-7209-4d7a-afbe-98ed89deddf1",
        "router": "propertyFinder",
        "link": [
            "propertyFinder",
            "propertyResult",
            "shortListedUnits",
            "shortlistLead",
            "createQuotation"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Property Search Result",
        "icon": "images/sampleimage.svg",
        "id": "20d1d469-8f5f-4f2a-9319-f434d665f777",
        "router": "propertyResult",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Short Listed Units",
        "icon": "images/sampleimage.svg",
        "id": "b6cfe609-0ec3-47ba-9af0-b31b6fb9a2d3",
        "router": "shortListedUnits",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Short Listed Lead",
        "icon": "images/sampleimage.svg",
        "id": "c6687bdd-35e6-473e-966c-65bf867c44ac",
        "router": "shortlistLead",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Create Quotation",
        "icon": "images/sampleimage.svg",
        "id": "2b735574-76d6-4480-a506-f570212da17c",
        "router": "createQuotation",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Leads",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Leads",
        "id": "c9cfcce4-0e96-40f7-ba0b-eb2fe5552592",
        "router": "leadnew",
        "link": [
            "leadnew"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Opportunities",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Opportunities",
        "id": "55f96c77-c116-4636-a171-3e9a69ae0673",
        "router": "leads",
        "link": [
            "leads",
            "leadsDetails",
            "createOpportunity"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Lead Details",
        "icon": "images/sampleimage.svg",
        "id": "2c92c51c-38d2-4431-b517-5e6a75d8baa1",
        "router": "leadsDetails",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Opportunity Creation Edit",
        "icon": "images/sampleimage.svg",
        "id": "c246543b-0416-41cd-a79a-d3bc21f8c5a3",
        "router": "createOpportunity",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Quotations",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Reports",
        "id": "3967fc20-a479-4fdf-b885-4b3cbb25b1a0",
        "router": "quotation",
        "link": [
            "quotation",
            "quotation_view"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Quotation View",
        "icon": "images/sampleimage.svg",
        "id": "4424b32a-9cf1-4e98-a0cb-6846c1fdc135",
        "router": "quotation_view",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Reservations",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Reservation",
        "id": "4b36da1f-7b8d-4980-8320-bd5bc6eb8f13",
        "router": null,
        "link": [],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Agreements",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Agreements",
        "id": "d1e85ec5-88c9-4a2e-9f9b-e55ffd50cfee",
        "router": "aggreements",
        "link": [
            "aggreements",
            "agreementView"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Agreement View",
        "icon": "images/sampleimage.svg",
        "id": "a25df1dc-b872-44b8-8f66-15961d322f0f",
        "router": "agreementView",
        "active_status": false,
        "type": "page",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Inspections",
        "icon": "images/sampleimage.svg",
        "nav_icon": "InspectionManagement",
        "id": "dfac17b8-434e-49d7-996f-59d541e80233",
        "router": null,
        "link": [],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Invoices",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Invoices",
        "id": "aeb50cde-47a4-4a1f-8baa-67087972a944",
        "router": null,
        "link": [],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Account Statement",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Reports",
        "id": "3b86bd8a-0732-4c2b-b3ed-d51a88e13f92",
        "router": null,
        "link": [],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Swift Entries",
        "icon": "images/sampleimage.svg",
        "nav_icon": "SwiftEntries",
        "id": "f0aca16a-26a5-4a0a-917e-bb14e8ab2e45",
        "router": null,
        "link": [
            "vendorEntry",
            "serviceProvider",
            "domesticHelpRequest",
            "visitorAndWorkedGatePass"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": [
            {
                "name": "Vendors",
                "icon": "images/document.svg",
                "nav_icon": "Vendors",
                "id": "4e841489-887c-4498-9d28-2b23b78a5a03",
                "parent_id": "f0aca16a-26a5-4a0a-917e-bb14e8ab2e45",
                "router": "vendorEntry",
                "link": [
                    "vendorEntry"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Service Providers",
                "icon": "images/document.svg",
                "nav_icon": "ServiceProviders",
                "id": "a48073c4-d624-42b1-b6ed-29c82a428331",
                "parent_id": "f0aca16a-26a5-4a0a-917e-bb14e8ab2e45",
                "router": "serviceProvider",
                "link": [
                    "serviceProvider"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Domestic Help",
                "icon": "images/document.svg",
                "nav_icon": "DomesticHelp",
                "id": "afe0a688-78e9-4b12-84ba-7019300e1101",
                "parent_id": "f0aca16a-26a5-4a0a-917e-bb14e8ab2e45",
                "router": "domesticHelpRequest",
                "link": [
                    "domesticHelpRequest"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Visitors/Workers",
                "icon": "images/document.svg",
                "nav_icon": "Worker",
                "id": "5e7301e8-2c02-491d-93f2-24d2aeae1412",
                "parent_id": "f0aca16a-26a5-4a0a-917e-bb14e8ab2e45",
                "router": "visitorAndWorkedGatePass",
                "link": [
                    "visitorAndWorkedGatePass"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            }
        ]
    },
    {
        "name": "Gate Pass",
        "icon": "images/sampleimage.svg",
        "nav_icon": "GatePass",
        "id": "0a222165-7175-40c5-b09e-b3be552929ea",
        "router": null,
        "link": [
            "visitorAndWorkedGatePass",
            "domesticHelpRequest"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": [
            {
                "name": "Visitors/Workers",
                "icon": "images/document.svg",
                "nav_icon": "Worker",
                "id": "3e09c14d-952b-4768-8c96-4da606d3fbcc",
                "parent_id": "0a222165-7175-40c5-b09e-b3be552929ea",
                "router": "visitorAndWorkedGatePass",
                "link": [
                    "visitorAndWorkedGatePass"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            },
            {
                "name": "Domestic Help",
                "icon": "images/document.svg",
                "nav_icon": "DomesticHelp",
                "id": "2972fc7f-cc9a-43a9-9ffe-2e6c4225cef1",
                "parent_id": "0a222165-7175-40c5-b09e-b3be552929ea",
                "router": "domesticHelpRequest",
                "link": [
                    "domesticHelpRequest"
                ],
                "active_status": false,
                "type": "menu",
                "permission": {
                    "create": true,
                    "update": true,
                    "read": true,
                    "delete": true
                }
            }
        ]
    },
    {
        "name": "Parking Slots",
        "icon": "images/sampleimage.svg",
        "nav_icon": "ParkingSlot",
        "id": "732249c6-6d99-4f94-8d9d-d0364d36b0e3",
        "router": "parkingSlot",
        "link": [
            "parkingSlot"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Delivery Collection",
        "icon": "images/sampleimage.svg",
        "nav_icon": "DeliveryCollections",
        "id": "d1e2fe85-9a0c-4aea-b1b2-51c5df9d4eb7",
        "router": "deliveryOrderCollection",
        "link": [
            "deliveryOrderCollection"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Vehicles",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Vehicles",
        "id": "a4d45fa9-7c72-4441-885d-ffd2c47224db",
        "router": null,
        "link": [],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Emergency Numbers",
        "icon": "images/sampleimage.svg",
        "nav_icon": "EmergencyNumber",
        "id": "a56b61e3-d7a5-42f1-b42b-0410809db4ee",
        "router": "master",
        "link": [
            "master"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Settings",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Settings",
        "id": "3220110f-a515-4d1a-bd41-a5a0689782fa",
        "router": null,
        "link": [
            "propertyType",
            "blockType",
            "unitType",
            "utilities",
            "utilitiesMapping",
            "utilitiesMappingPropertyView",
            "utilitiesMappingUnitView",
            "amenities",
            "amenitiesMapping",
            "amenitiesMappingPropertyView",
            "amenitiesMappingUnitView",
            "accessGates",
            "accessGatesPropertyView",
            "parkingSlotMaster",
            "parkingArea"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Modules",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Modules",
        "id": "2e21805e-01b5-4b26-84e5-8dc187d50d58",
        "router": null,
        "link": [
            "propertyType",
            "blockType",
            "unitType",
            "pricingComponent",
            "parkingSlotMaster",
            "accessGates",
            "taxGroup",
            "taxItem",
            "currencyMaster",
            "serviceProvider",
            "servicesMaster",
            "pricingTable",
            "pricingTablePropertyView",
            "pricingTableUnitView",
            "master",
            "parkingArea",
            "accessGatesPropertyView",
            "unit_vacancy",
            "vacancy_details"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        },
        "children": []
    },
    {
        "name": "Enrollment",
        "icon": "images/sampleimage.svg",
        "nav_icon": "Enrollment",
        "id": "de4c1c00-20e8-41ca-a9f8-2076cff3d510",
        "router": null,
        "link": [
            "propertyFinder",
            "propertyResult",
            "leads",
            "leadsDetails",
            "quotation",
            "quotation_view",
            "aggreements",
            "agreementView",
            "shortListedUnits",
            "shortlistLead",
            "createQuotation",
            "createOpportunity"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    },
    {
        "name": "Community Services",
        "icon": "images/sampleimage.svg",
        "nav_icon": "CommunityHoaManagement",
        "id": "729247dc-2916-4451-b9ce-efb7f4e4707d",
        "router": null,
        "link": [
            "communitycard",
            "master",
            "announcement",
            "createannounsment",
            "managementcommittee",
            "managementcommitteeView"
        ],
        "active_status": false,
        "type": "menu",
        "permission": {
            "create": true,
            "update": true,
            "read": true,
            "delete": true
        }
    }
]