import * as React from "react"

export const OwnerAssign = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 105401">
      <path
        d="M7.07 3a1.462 1.462 0 0 0-1.449 1.3H4.465A1.467 1.467 0 0 0 3 5.767v8.791a1.467 1.467 0 0 0 1.465 1.465h3.994a4.23 4.23 0 0 1 4.959-6.512V5.767A1.467 1.467 0 0 0 11.953 4.3H10.8A1.462 1.462 0 0 0 9.349 3Zm0 .977h2.279a.489.489 0 1 1 0 .977H7.07a.489.489 0 0 1 0-.977Zm4.721 5.86a3.581 3.581 0 1 0 3.581 3.581 3.581 3.581 0 0 0-3.581-3.581Zm0 .977a.326.326 0 0 1 .326.326v1.953h1.953a.326.326 0 1 1 0 .651h-1.954V15.7a.326.326 0 1 1-.651 0v-1.956H9.512a.326.326 0 1 1 0-.651h1.953V11.14a.326.326 0 0 1 .326-.326Z"
        fill="#fff"
      />
      <path data-name="Rectangle 55506" fill="none" d="M0 0h20v20H0z" />
    </g>
  </svg>
)
