import { Box, Grid, IconButton, Stack } from "@mui/material"
import React from "react"
import FilterIMG from '../../../assets/filter'
import FilterIcon from '../../../assets/filterIcon'
import { CalendarDateChange } from "../../../components"
import { CustomDropDown } from "../../scheduleBoard/components/customDropDown"
import { useStyles } from "./style"

export const CalendarHeader = ({ t, selected = "", weekdates = () => false, weekprev = () => false, weeknext = () => false, month = "", monthprev = () => false, monthnext = () => false, open = "", daily = "", prevdate = () => false, nextdate = () => false, setDropdownValue = () => false, filterData = {}, openFilter = () => false }) => {
    const classes = useStyles()
    // const [size, setSize] = React.useState({ isMedium: true, isNormal: false })
    return (
        <Box>
            <Grid container alignItems={"center"} justifyContent="space-between" >
                <Stack direction={"row"} spacing={2} width="250px"></Stack>
                <Grid item>
                    <Box>
                        {
                            selected === "Weekly" &&
                            <CalendarDateChange state={weekdates} prev={weekprev} next={weeknext} comp={"weekly"} />
                        }
                        {
                            selected === "Monthly" &&
                            <CalendarDateChange state={month} prev={monthprev} next={monthnext} comp={"month"} />
                        }
                        {
                            selected === "Daily" &&
                            <CalendarDateChange state={daily} prev={prevdate} next={nextdate} comp={"daily"} />
                        }
                    </Box>
                </Grid>
                <Grid item >

                    <Stack direction="row" spacing={2} alignItems={"center"}>

                        {
                            (filterData?.category?.length > 0||filterData?.role?.length>0||filterData?.property?.length>0) ?

                                <IconButton onClick={() => openFilter()}>
                                    <FilterIcon />
                                </IconButton>

                                :
                                <IconButton onClick={() => openFilter()} className={classes.iconButton}>
                                    <FilterIMG />
                                </IconButton>
                        }
                        <Box className={classes.verticalborder} />
                        <CustomDropDown t={t} type={selected} setType={setDropdownValue} />

                    </Stack>

                </Grid>

            </Grid>

        </Box>
    )
}