import * as React from "react"
const AmenityMappingNoSlot = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={464.525}
    height={458.768}
    {...props}
  >
    <g data-name="Group 110717">
      <g data-name="Background Complete">
        <g data-name="Group 108495">
          <path
            fill="#f5f7fa"
            d="m256.682 27.324 62.628-15.142L334.45 74.81l-62.627 15.142z"
            data-name="Rectangle 56392"
          />
        </g>
        <g data-name="Group 108496">
          <path
            fill="#f5f7fa"
            d="m148.227 90.321 12.31-63.245 63.246 12.31-12.311 63.246z"
            data-name="Rectangle 56393"
          />
        </g>
        <g data-name="Group 108497">
          <path
            fill="#f5f7fa"
            d="M74.795 63.58s-8.892 43.036 10.072 66.046l64.685-3.074s-15.885-15.507-14.242-52.749Z"
            data-name="Path 99535"
          />
        </g>
        <g data-name="Group 108498">
          <path
            fill="#f5f7fa"
            d="M7.03 150.412S2.368 197.95 52.652 206.08l58.478-2.524s-41.235-27.029-39.669-53.144Z"
            data-name="Path 99536"
          />
        </g>
        <g data-name="Group 108499">
          <path
            fill="#f5f7fa"
            d="M0 252.558s18.43 22.21 20.108 36.757a58.526 58.526 0 0 1-2.357 25.181l61.938-17.751s15.127-29.718-17.751-61.938Z"
            data-name="Path 99537"
          />
        </g>
        <g data-name="Group 108500">
          <path
            fill="#f5f7fa"
            d="M89.071 330.849H24.639s.138.955.466 2.6c2.04 10.22 11.428 47.084 40.824 47.565 34.132.56 62.108 0 62.108 0s-41.004-20.317-38.966-50.165Z"
            data-name="Path 99538"
          />
        </g>
        <g data-name="Group 108501">
          <path
            fill="#f5f7fa"
            d="M95.919 421.756s15.335-.347 38.4-9.419c26-10.226 38.66 11.8 38.66 11.8l38.578-27.211s-21.455-19.587-48.137-15.822c-18.681 2.636-35.268 16.837-35.268 16.837Z"
            data-name="Path 99539"
          />
        </g>
        <g data-name="Group 108502">
          <path
            fill="#f5f7fa"
            d="M234.314 401.825s27.156 30.852 30.152 56.942l56.941-30.151s-9.283-38.011-30.151-56.942Z"
            data-name="Path 99540"
          />
        </g>
        <g data-name="Group 108503">
          <path
            fill="#f5f7fa"
            d="M340.078 394.19s-12.049-44.824 16.685-68.928l57.362 5.983s-18.157 38.082-11.6 62.945Z"
            data-name="Path 99541"
          />
        </g>
        <g data-name="Group 108504">
          <path
            fill="#f5f7fa"
            d="m384.837 285.144 61.938-17.751 17.752 61.938-61.939 17.751z"
            data-name="Rectangle 56394"
          />
        </g>
        <g data-name="Group 108505">
          <path
            fill="#f5f7fa"
            d="m399.65 200.573 59.14-2.525s-.089.882-.326 2.406c-1.471 9.461-8.644 43.664-35.607 45.259-31.307 1.851-57.008 2.434-57.008 2.434s36.841-20.258 33.801-47.574Z"
            data-name="Path 99542"
          />
        </g>
        <g data-name="Group 108506">
          <path
            fill="#f5f7fa"
            d="M451.418 131.924s-14.509 17.485-15.83 28.938a46.075 46.075 0 0 0 1.855 19.823l-48.76-13.974s-11.909-23.4 13.974-48.761Z"
            data-name="Path 99543"
          />
        </g>
      </g>
      <g data-name="Group 108960">
        <g data-name="Group 108957">
          <path
            fill="#ced3dd"
            d="M95.311 386.454a21.319 21.319 0 0 0-26.363 12.077 23.222 23.222 0 0 0 21.713-.828 12.777 12.777 0 0 0 4.717-4.434 6.855 6.855 0 0 0 .461-6.274"
            data-name="Path 99628"
          />
        </g>
        <g data-name="Group 108959">
          <g data-name="Group 108958">
            <path
              fill="#263238"
              d="M107.739 383.894c.014.079-1.451.392-3.793.987s-5.553 1.5-9.019 2.75-6.521 2.6-8.7 3.636-3.51 1.729-3.55 1.658a4.2 4.2 0 0 1 .875-.592 47.196 47.196 0 0 1 2.511-1.4 72.011 72.011 0 0 1 17.8-6.415 45.681 45.681 0 0 1 2.825-.526 4.165 4.165 0 0 1 1.051-.098Z"
              data-name="Path 99629"
            />
          </g>
        </g>
      </g>
      <g data-name="Group 108967">
        <g data-name="Group 108961">
          <path
            fill="#ced3dd"
            d="M359.888 63.662a21.867 21.867 0 0 1-.287-16.789c1.962-5.1 6.386-9.18 10.995-12.112 2.734 5.19 4.049 11.575 1.973 17.062s-6.9 9.738-12.384 11.819"
            data-name="Path 99630"
          />
        </g>
        <g data-name="Group 108962">
          <path
            fill="#ced3dd"
            d="M350.908 61.731c2.562-2.622 2.945-6.855 1.645-10.283s-4.016-6.142-6.993-8.282c-.741-.533-1.8-1.03-2.493-.435a2.127 2.127 0 0 0-.542 1.431c-.395 3.588-.534 7.305.635 10.72s3.891 6.493 7.434 7.183Z"
            data-name="Path 99631"
          />
        </g>
        <g data-name="Group 108964">
          <g data-name="Group 108963">
            <path
              fill="#263238"
              d="M346.574 49.917a6.224 6.224 0 0 1 .766 1.063 11.117 11.117 0 0 1 .787 1.321 18.509 18.509 0 0 1 .906 1.826 20.806 20.806 0 0 1 .9 2.3 27.586 27.586 0 0 1 .783 2.731 28.969 28.969 0 0 1 0 12.925 27.536 27.536 0 0 1-.782 2.732 21.026 21.026 0 0 1-.9 2.3 18.5 18.5 0 0 1-.906 1.826 11.134 11.134 0 0 1-.785 1.321 6.28 6.28 0 0 1-.766 1.063c-.1-.06.974-1.6 2.118-4.355a22.43 22.43 0 0 0 .827-2.293c.292-.828.488-1.745.723-2.7a30.182 30.182 0 0 0 0-12.718c-.236-.956-.432-1.873-.725-2.7a22.322 22.322 0 0 0-.825-2.288c-1.146-2.759-2.217-4.291-2.121-4.354Z"
              data-name="Path 99632"
            />
          </g>
        </g>
        <g data-name="Group 108966">
          <g data-name="Group 108965">
            <path
              fill="#263238"
              d="M365.658 51.688a6.532 6.532 0 0 1-.382 1.286c-.272.82-.719 1.986-1.319 3.409a71.318 71.318 0 0 1-13 20.209c-1.046 1.136-1.923 2.026-2.556 2.613a6.536 6.536 0 0 1-1.012.881 6.708 6.708 0 0 1 .868-1.022c.586-.63 1.419-1.556 2.425-2.717a83.333 83.333 0 0 0 12.94-20.113c.64-1.4 1.138-2.538 1.467-3.333a6.778 6.778 0 0 1 .569-1.213Z"
              data-name="Path 99633"
            />
          </g>
        </g>
      </g>
      <g data-name="Group 108983">
        <g data-name="Group 108969">
          <g data-name="Group 108968">
            <path
              fill="#ced3dd"
              d="M12.922 68.893a12.018 12.018 0 0 1 9.4-.479 21.22 21.22 0 0 1 7.993 5.306c4.415 4.413 7.118 11.071 8.632 17.127-6.82 1.247-13.79-2.766-17.2-5.024-5.387-3.568-12.954-13.288-8.828-16.93"
              data-name="Path 99634"
            />
          </g>
        </g>
        <g data-name="Group 108971">
          <g data-name="Group 108970">
            <path
              fill="#ced3dd"
              d="M50.737 92.473a10.255 10.255 0 0 1-5.563-8.664 13.616 13.616 0 0 1 3.8-9.813c1.134-1.22 2.632-2.323 4.295-2.215a4.383 4.383 0 0 1 3.519 2.715 8.891 8.891 0 0 1 .486 4.593c-.6 5.309-2.15 10.337-6.539 13.384"
              data-name="Path 99635"
            />
          </g>
        </g>
        <g data-name="Group 108974">
          <g data-name="Group 108973">
            <g data-name="Group 108972">
              <path
                fill="#263238"
                d="M58.112 106.245a20.123 20.123 0 0 1-2.742-3.084 19.137 19.137 0 0 1-2.327-3.864 41.924 41.924 0 0 1-2.079-5.1 20.526 20.526 0 0 1-.734-9.948 14.875 14.875 0 0 1 .9-2.907 3.279 3.279 0 0 1 .549-.971c.118.048-.579 1.479-.958 3.961a22 22 0 0 0 .914 9.657 49.265 49.265 0 0 0 2 5.034 21.165 21.165 0 0 0 2.14 3.841 29.721 29.721 0 0 1 2.337 3.381Z"
                data-name="Path 99636"
              />
            </g>
          </g>
        </g>
        <g data-name="Group 108977">
          <g data-name="Group 108976">
            <g data-name="Group 108975">
              <path
                fill="#263238"
                d="M19.338 75.786a11.876 11.876 0 0 1 1.755 1.132c1.1.765 2.684 1.894 4.624 3.3 3.881 2.824 9.2 6.786 14.964 11.3s10.871 8.761 14.5 11.9a194.665 194.665 0 0 1 4.253 3.773 11.776 11.776 0 0 1 1.487 1.466 11.94 11.94 0 0 1-1.665-1.26l-4.394-3.6a840.764 840.764 0 0 0-14.611-11.734l-14.83-11.444-4.49-3.483a12.026 12.026 0 0 1-1.593-1.35Z"
                data-name="Path 99637"
              />
            </g>
          </g>
        </g>
        <g data-name="Group 108979">
          <g data-name="Group 108978">
            <path
              fill="#ced3dd"
              d="M47.695 100.125a23.989 23.989 0 0 0-21.8 2.32c-1.608 1.076-3.239 2.759-2.853 4.655s2.559 2.811 4.472 3.126a20.5 20.5 0 0 0 20.552-9.735"
              data-name="Path 99638"
            />
          </g>
        </g>
        <g data-name="Group 108982">
          <g data-name="Group 108981">
            <g data-name="Group 108980">
              <path
                fill="#263238"
                d="M31.286 104.2c-.021-.043.311-.249.931-.583s1.538-.782 2.693-1.291a27.032 27.032 0 0 1 9.584-2.337 16.968 16.968 0 0 1 5.434.732 16.556 16.556 0 0 1 6.379 3.8c.509.492.772.778.739.814-.092.106-1.225-.978-3.346-2.264a17.729 17.729 0 0 0-3.96-1.733 17.442 17.442 0 0 0-5.227-.65 30.4 30.4 0 0 0-9.42 2.1 34.958 34.958 0 0 1-3.807 1.412Z"
                data-name="Path 99639"
              />
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109011">
        <g data-name="Group 108987">
          <g data-name="Group 108986">
            <g data-name="Group 108985">
              <g data-name="Group 108984">
                <path
                  fill="#ced3dd"
                  d="M359.912 421.31c-2.921-1.159-5.377 1.478-5.87 3.683s.186 4.484.858 6.641c1.536 4.929 3.2 10.067 6.859 13.7a8.467 8.467 0 0 0 3.9 2.387 4.418 4.418 0 0 0 4.262-1.138c1.218-1.361 1.184-3.409.942-5.219a36.285 36.285 0 0 0-5.565-14.973c-1.372-2.1-3.065-4.133-5.384-5.084"
                  data-name="Path 99640"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 108991">
          <g data-name="Group 108990">
            <g data-name="Group 108989">
              <g data-name="Group 108988">
                <path
                  fill="#ced3dd"
                  d="M347.106 414.136a15.621 15.621 0 0 1-7.445 6.311 8.054 8.054 0 0 1-5.172.846c-1.725-.445-3.192-2.261-2.669-3.963a4.589 4.589 0 0 1 2.1-2.3c2.155-1.383 4.777-2.569 5.639-4.982.491-1.376.337-3.024 1.265-4.152a3.3 3.3 0 0 1 3.92-.647 5.641 5.641 0 0 1 2.632 3.25 6.356 6.356 0 0 1-.27 5.637Z"
                  data-name="Path 99641"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 108995">
          <g data-name="Group 108994">
            <g data-name="Group 108993">
              <g data-name="Group 108992">
                <path
                  fill="#ced3dd"
                  d="M362.014 415.504a9.367 9.367 0 0 0 3.522 8 16.6 16.6 0 0 0 8.334 3.377 27.492 27.492 0 0 0 10.6-.521 9.756 9.756 0 0 0 3.817-1.677 4.314 4.314 0 0 0 1.734-3.64c-.2-2.061-2.2-3.413-4.031-4.38a69.433 69.433 0 0 0-10.041-4.335c-2.778-.948-5.758-1.726-8.625-1.094s-5.178 2.3-5.347 4.689"
                  data-name="Path 99642"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109000">
          <g data-name="Group 108999">
            <g data-name="Group 108998">
              <g data-name="Group 108997">
                <g data-name="Group 108996">
                  <path
                    fill="#263238"
                    d="M338.667 388.815a5.512 5.512 0 0 0 .507 1.067l1.495 2.836a46.235 46.235 0 0 1 1.974 4.3 36.06 36.06 0 0 1 1.617 5.551 20.36 20.36 0 0 1 .393 5.727 10.549 10.549 0 0 1-1.349 4.438 9.974 9.974 0 0 1-1.995 2.433 5.6 5.6 0 0 0-.88.781 5.5 5.5 0 0 0 1-.636 9.115 9.115 0 0 0 2.168-2.4 10.518 10.518 0 0 0 1.5-4.568 19.9 19.9 0 0 0-.361-5.868 33.9 33.9 0 0 0-1.677-5.619 39.738 39.738 0 0 0-2.087-4.3 64.045 64.045 0 0 0-1.633-2.769 5.538 5.538 0 0 0-.672-.973Z"
                    data-name="Path 99643"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109005">
          <g data-name="Group 109004">
            <g data-name="Group 109003">
              <g data-name="Group 109002">
                <g data-name="Group 109001">
                  <path
                    fill="#263238"
                    d="M339.833 390.987a2.335 2.335 0 0 0 .273.471l.856 1.294a168.799 168.799 0 0 1 3.078 4.8c2.547 4.085 5.854 9.862 9.2 16.4s6.063 12.621 7.842 17.094c.457 1.114.848 2.13 1.178 3.027s.633 1.664.848 2.3l.526 1.459a2.291 2.291 0 0 0 .216.5 2.367 2.367 0 0 0-.123-.529l-.441-1.49c-.188-.648-.456-1.429-.772-2.333s-.676-1.936-1.114-3.06a176.23 176.23 0 0 0-7.73-17.189c-3.354-6.554-6.716-12.318-9.343-16.366a98.62 98.62 0 0 0-3.211-4.725l-.932-1.244a2.325 2.325 0 0 0-.351-.409Z"
                    data-name="Path 99644"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109010">
          <g data-name="Group 109009">
            <g data-name="Group 109008">
              <g data-name="Group 109007">
                <g data-name="Group 109006">
                  <path
                    fill="#263238"
                    d="M346.873 401.74a10.353 10.353 0 0 0 .9 1.347c.622.837 1.57 2.016 2.829 3.4a57.806 57.806 0 0 0 4.711 4.559 56.647 56.647 0 0 0 6.4 4.853 40.3 40.3 0 0 0 13.291 5.9 19.821 19.821 0 0 0 2.489.419c.369.044.709.106 1.027.124l.871.019a10.4 10.4 0 0 0 1.62-.021 48.2 48.2 0 0 1-5.93-.873 42.519 42.519 0 0 1-13.1-5.97 60.323 60.323 0 0 1-6.372-4.787 64.926 64.926 0 0 1-4.753-4.455 63.91 63.91 0 0 1-2.93-3.277 10.326 10.326 0 0 0-1.053-1.238Z"
                    data-name="Path 99645"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109095">
        <g data-name="Group 109015">
          <g data-name="Group 109012">
            <path
              fill="#fff"
              d="m368.734 274.697 61.92-17.719 17.718 61.92-61.92 17.718z"
              data-name="Rectangle 56395"
            />
          </g>
          <g data-name="Group 109014">
            <g data-name="Group 109013">
              <path
                fill="#ced3dd"
                d="M448.423 318.88s-.039-.1-.1-.3l-.259-.858-.981-3.33-3.67-12.592-12.959-44.8.279.154-61.923 17.783h-.006l.179-.322 17.717 61.948-.279-.155 44.866-12.728 12.624-3.557 3.343-.931.866-.234c.2-.052.3-.074.3-.074a2.217 2.217 0 0 1-.272.091l-.835.253-3.287.969-12.543 3.656-44.939 13-.217.063-.062-.217-17.785-61.928-.072-.251.251-.072h.006l61.942-17.716.217-.062.061.217 12.778 45 3.543 12.576.919 3.3c.1.361.173.638.228.842a2.54 2.54 0 0 1 .07.275Z"
                data-name="Path 99646"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109016">
          <path
            d="m400.019 284.189-1.25-4.481-1.179 4.249-1.066.291-3.131-2.942 1.194 4.368-2.214.6-2.315-8.47 1.973-.539 4.312 4.076 1.568-5.682 1.973-.538 2.338 8.463Z"
            data-name="Path 99647"
          />
          <path
            d="m409.135 279.931-3.581.978-.216 1.827-2.446.668 1.426-9.491 2.36-.645 6.064 7.446-2.492.68Zm-1.184-1.576-1.83-2.412-.349 3.008Z"
            data-name="Path 99648"
          />
          <path
            d="m416.592 276.398.829 3.037-2.4.655-.84-3.073-4.7-4.514 2.53-.692 2.927 2.816 1.089-3.912 2.324-.635Z"
            data-name="Path 99649"
          />
        </g>
        <g data-name="Group 109042">
          <g data-name="Group 109021">
            <g data-name="Group 109018">
              <g data-name="Group 109017">
                <path
                  fill="#263238"
                  d="M386.072 303.68a28.236 28.236 0 0 1 3.738-6.672 28.236 28.236 0 0 1-3.738 6.672Z"
                  data-name="Path 99650"
                />
              </g>
            </g>
            <g data-name="Group 109020">
              <g data-name="Group 109019">
                <path
                  fill="#263238"
                  d="M391.724 302.224a33.415 33.415 0 0 1-7.628-3.331 33.415 33.415 0 0 1 7.628 3.331Z"
                  data-name="Path 99651"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109026">
            <g data-name="Group 109023">
              <g data-name="Group 109022">
                <path
                  fill="#263238"
                  d="M396.846 299.406a19.5 19.5 0 0 1 3.329-5.4 19.466 19.466 0 0 1-3.329 5.4Z"
                  data-name="Path 99652"
                />
              </g>
            </g>
            <g data-name="Group 109025">
              <g data-name="Group 109024">
                <path
                  fill="#263238"
                  d="M401.581 298.391a22.985 22.985 0 0 1-6.207-3.017 23.061 23.061 0 0 1 6.207 3.017Z"
                  data-name="Path 99653"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109031">
            <g data-name="Group 109028">
              <g data-name="Group 109027">
                <path
                  fill="#263238"
                  d="M408.038 296.215a19.489 19.489 0 0 1 3.329-5.4 19.488 19.488 0 0 1-3.329 5.4Z"
                  data-name="Path 99654"
                />
              </g>
            </g>
            <g data-name="Group 109030">
              <g data-name="Group 109029">
                <path
                  fill="#263238"
                  d="M412.773 295.199a23.018 23.018 0 0 1-6.21-3.019 23.019 23.019 0 0 1 6.207 3.016Z"
                  data-name="Path 99655"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109036">
            <g data-name="Group 109033">
              <g data-name="Group 109032">
                <path
                  fill="#263238"
                  d="M418.517 293.226a19.47 19.47 0 0 1 3.328-5.4 19.493 19.493 0 0 1-3.328 5.4Z"
                  data-name="Path 99656"
                />
              </g>
            </g>
            <g data-name="Group 109035">
              <g data-name="Group 109034">
                <path
                  fill="#263238"
                  d="M423.251 292.211a22.982 22.982 0 0 1-6.207-3.017 22.947 22.947 0 0 1 6.207 3.017Z"
                  data-name="Path 99657"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109041">
            <g data-name="Group 109038">
              <g data-name="Group 109037">
                <path
                  fill="#263238"
                  d="M427.097 290.51a18.632 18.632 0 0 1 3.136-5.349 18.57 18.57 0 0 1-3.136 5.349Z"
                  data-name="Path 99658"
                />
              </g>
            </g>
            <g data-name="Group 109040">
              <g data-name="Group 109039">
                <path
                  fill="#263238"
                  d="M431.701 289.419a21.966 21.966 0 0 1-6.128-2.817 21.965 21.965 0 0 1 6.128 2.817Z"
                  data-name="Path 99659"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109068">
          <g data-name="Group 109047">
            <g data-name="Group 109044">
              <g data-name="Group 109043">
                <path
                  fill="#263238"
                  d="M390.136 312.937a28.272 28.272 0 0 1 3.738-6.673 28.218 28.218 0 0 1-3.738 6.673Z"
                  data-name="Path 99660"
                />
              </g>
            </g>
            <g data-name="Group 109046">
              <g data-name="Group 109045">
                <path
                  fill="#263238"
                  d="M395.787 311.48a33.433 33.433 0 0 1-7.627-3.331 33.382 33.382 0 0 1 7.627 3.331Z"
                  data-name="Path 99661"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109052">
            <g data-name="Group 109049">
              <g data-name="Group 109048">
                <path
                  fill="#263238"
                  d="M400.91 308.665a19.465 19.465 0 0 1 3.329-5.4 19.467 19.467 0 0 1-3.329 5.4Z"
                  data-name="Path 99662"
                />
              </g>
            </g>
            <g data-name="Group 109051">
              <g data-name="Group 109050">
                <path
                  fill="#263238"
                  d="M405.645 307.65a22.985 22.985 0 0 1-6.207-3.017 22.982 22.982 0 0 1 6.207 3.017Z"
                  data-name="Path 99663"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109057">
            <g data-name="Group 109054">
              <g data-name="Group 109053">
                <path
                  fill="#263238"
                  d="M412.102 305.473a19.505 19.505 0 0 1 3.329-5.4 19.464 19.464 0 0 1-3.329 5.4Z"
                  data-name="Path 99664"
                />
              </g>
            </g>
            <g data-name="Group 109056">
              <g data-name="Group 109055">
                <path
                  fill="#263238"
                  d="M416.837 304.458a22.98 22.98 0 0 1-6.207-3.016 23.018 23.018 0 0 1 6.207 3.016Z"
                  data-name="Path 99665"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109062">
            <g data-name="Group 109059">
              <g data-name="Group 109058">
                <path
                  fill="#263238"
                  d="M422.58 302.485a19.465 19.465 0 0 1 3.329-5.4 19.465 19.465 0 0 1-3.329 5.4Z"
                  data-name="Path 99666"
                />
              </g>
            </g>
            <g data-name="Group 109061">
              <g data-name="Group 109060">
                <path
                  fill="#263238"
                  d="M427.315 301.469a22.94 22.94 0 0 1-6.207-3.016 23.019 23.019 0 0 1 6.207 3.016Z"
                  data-name="Path 99667"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109067">
            <g data-name="Group 109064">
              <g data-name="Group 109063">
                <path
                  fill="#263238"
                  d="M431.163 299.769a18.57 18.57 0 0 1 3.136-5.349 18.57 18.57 0 0 1-3.136 5.349Z"
                  data-name="Path 99668"
                />
              </g>
            </g>
            <g data-name="Group 109066">
              <g data-name="Group 109065">
                <path
                  fill="#263238"
                  d="M435.763 298.68a21.966 21.966 0 0 1-6.128-2.817 22 22 0 0 1 6.128 2.817Z"
                  data-name="Path 99669"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109094">
          <g data-name="Group 109073">
            <g data-name="Group 109070">
              <g data-name="Group 109069">
                <path
                  fill="#263238"
                  d="M393.413 322.443a28.237 28.237 0 0 1 3.738-6.672 28.237 28.237 0 0 1-3.738 6.672Z"
                  data-name="Path 99670"
                />
              </g>
            </g>
            <g data-name="Group 109072">
              <g data-name="Group 109071">
                <path
                  fill="#263238"
                  d="M399.063 320.989a33.433 33.433 0 0 1-7.627-3.331 33.382 33.382 0 0 1 7.627 3.331Z"
                  data-name="Path 99671"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109078">
            <g data-name="Group 109075">
              <g data-name="Group 109074">
                <path
                  fill="#263238"
                  d="M404.187 318.171a19.5 19.5 0 0 1 3.329-5.4 19.465 19.465 0 0 1-3.329 5.4Z"
                  data-name="Path 99672"
                />
              </g>
            </g>
            <g data-name="Group 109077">
              <g data-name="Group 109076">
                <path
                  fill="#263238"
                  d="M408.922 317.156a22.982 22.982 0 0 1-6.207-3.017 23.061 23.061 0 0 1 6.207 3.017Z"
                  data-name="Path 99673"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109083">
            <g data-name="Group 109080">
              <g data-name="Group 109079">
                <path
                  fill="#263238"
                  d="M415.379 314.98a19.489 19.489 0 0 1 3.329-5.4 19.489 19.489 0 0 1-3.329 5.4Z"
                  data-name="Path 99674"
                />
              </g>
            </g>
            <g data-name="Group 109082">
              <g data-name="Group 109081">
                <path
                  fill="#263238"
                  d="M420.114 313.964a22.981 22.981 0 0 1-6.207-3.016 23.021 23.021 0 0 1 6.207 3.016Z"
                  data-name="Path 99675"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109088">
            <g data-name="Group 109085">
              <g data-name="Group 109084">
                <path
                  fill="#263238"
                  d="M425.857 311.991a19.5 19.5 0 0 1 3.329-5.4 19.464 19.464 0 0 1-3.329 5.4Z"
                  data-name="Path 99676"
                />
              </g>
            </g>
            <g data-name="Group 109087">
              <g data-name="Group 109086">
                <path
                  fill="#263238"
                  d="M430.592 310.98a22.984 22.984 0 0 1-6.207-3.017 23.062 23.062 0 0 1 6.207 3.017Z"
                  data-name="Path 99677"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109093">
            <g data-name="Group 109090">
              <g data-name="Group 109089">
                <path
                  fill="#263238"
                  d="M434.438 309.28a18.571 18.571 0 0 1 3.136-5.349 18.571 18.571 0 0 1-3.136 5.349Z"
                  data-name="Path 99678"
                />
              </g>
            </g>
            <g data-name="Group 109092">
              <g data-name="Group 109091">
                <path
                  fill="#263238"
                  d="M439.042 308.18a21.964 21.964 0 0 1-6.128-2.817 22 22 0 0 1 6.128 2.817Z"
                  data-name="Path 99679"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109179">
        <g data-name="Group 109099">
          <g data-name="Group 109096">
            <path
              fill="#fff"
              d="M244.014 15.385 306.648.271l15.114 62.635-62.634 15.113z"
              data-name="Rectangle 56396"
            />
          </g>
          <g data-name="Group 109098">
            <g data-name="Group 109097">
              <path
                fill="#ced3dd"
                d="M321.763 62.906s-.034-.105-.086-.3l-.222-.869q-.293-1.17-.84-3.368l-3.134-12.736L306.431.325l.271.166-62.621 15.146h-.006l.192-.314c5.521 22.938 10.7 44.432 15.079 62.642l-.272-.166 45.364-10.828 12.763-3.019 3.38-.789.874-.2c.2-.043.306-.06.306-.06a2.535 2.535 0 0 1-.276.079l-.844.217-3.326.829-12.685 3.122-45.45 11.085-.219.053-.053-.219c-4.4-18.205-9.6-39.694-15.148-62.626l-.061-.253.254-.061h.005L306.595.052l.219-.053.052.219 10.862 45.5 3.007 12.714c.338 1.45.6 2.565.779 3.338l.192.851a2.451 2.451 0 0 1 .057.285Z"
                data-name="Path 99680"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109100">
          <path
            d="m269.652 23.066.451 1.879 3.77-.9.447 1.866-3.769.9.7 2.927-2.415.579-2.046-8.537 6.7-1.606.447 1.866Z"
            data-name="Path 99681"
          />
          <path
            d="m283.072 24.93.447 1.866-6.856 1.644-2.048-8.538 6.7-1.606.448 1.866-4.306 1.032.346 1.44 3.793-.91.433 1.806-3.794.909.374 1.562Z"
            data-name="Path 99682"
          />
          <path
            d="M292.419 22.18c.354 1.477-.658 2.635-2.951 3.185l-4.611 1.106-2.048-8.539 4.367-1.046c2.244-.539 3.562.114 3.878 1.431a2.075 2.075 0 0 1-.7 2.129 2.217 2.217 0 0 1 2.065 1.734Zm-6.8-3.076.4 1.647 1.658-.4c.8-.193 1.152-.573 1.021-1.122s-.611-.718-1.416-.525Zm4.3 3.393c-.14-.585-.647-.76-1.476-.561l-2.024.486.415 1.731 2.024-.485c.825-.195 1.198-.581 1.057-1.167Z"
            data-name="Path 99683"
          />
        </g>
        <g data-name="Group 109126">
          <g data-name="Group 109105">
            <g data-name="Group 109102">
              <g data-name="Group 109101">
                <path
                  fill="#263238"
                  d="M261.563 44.009a9.862 9.862 0 0 1 1.558-2.768 9.873 9.873 0 0 1 1.989-2.477 9.842 9.842 0 0 1-1.558 2.769 9.876 9.876 0 0 1-1.989 2.476Z"
                  data-name="Path 99684"
                />
              </g>
            </g>
            <g data-name="Group 109104">
              <g data-name="Group 109103">
                <path
                  fill="#263238"
                  d="M266.28 43.212a11.377 11.377 0 0 1-3.11-1.421 11.416 11.416 0 0 1-2.858-1.876 11.377 11.377 0 0 1 3.11 1.42 11.379 11.379 0 0 1 2.858 1.877Z"
                  data-name="Path 99685"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109110">
            <g data-name="Group 109107">
              <g data-name="Group 109106">
                <path
                  fill="#263238"
                  d="M270.947 41.596a9.88 9.88 0 0 1 1.67-2.7 9.834 9.834 0 0 1 2.089-2.392 9.852 9.852 0 0 1-1.67 2.7 9.852 9.852 0 0 1-2.089 2.392Z"
                  data-name="Path 99686"
                />
              </g>
            </g>
            <g data-name="Group 109109">
              <g data-name="Group 109108">
                <path
                  fill="#263238"
                  d="M275.692 40.993a11.384 11.384 0 0 1-3.049-1.547 11.378 11.378 0 0 1-2.779-1.992 11.384 11.384 0 0 1 3.049 1.547 11.378 11.378 0 0 1 2.779 1.992Z"
                  data-name="Path 99687"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109115">
            <g data-name="Group 109112">
              <g data-name="Group 109111">
                <path
                  fill="#263238"
                  d="M280.871 38.962a9.849 9.849 0 0 1 1.67-2.7 9.81 9.81 0 0 1 2.089-2.392 9.88 9.88 0 0 1-1.67 2.7 9.858 9.858 0 0 1-2.089 2.392Z"
                  data-name="Path 99688"
                />
              </g>
            </g>
            <g data-name="Group 109114">
              <g data-name="Group 109113">
                <path
                  fill="#263238"
                  d="M285.615 38.359a11.378 11.378 0 0 1-3.049-1.547 11.361 11.361 0 0 1-2.778-1.992 11.415 11.415 0 0 1 3.049 1.547 11.39 11.39 0 0 1 2.778 1.992Z"
                  data-name="Path 99689"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109120">
            <g data-name="Group 109117">
              <g data-name="Group 109116">
                <path
                  fill="#263238"
                  d="M290.794 36.328a9.837 9.837 0 0 1 1.67-2.7 9.852 9.852 0 0 1 2.089-2.393 9.85 9.85 0 0 1-1.67 2.7 9.834 9.834 0 0 1-2.089 2.393Z"
                  data-name="Path 99690"
                />
              </g>
            </g>
            <g data-name="Group 109119">
              <g data-name="Group 109118">
                <path
                  fill="#263238"
                  d="M295.539 35.725a11.361 11.361 0 0 1-3.049-1.546 11.372 11.372 0 0 1-2.779-1.993 11.383 11.383 0 0 1 3.049 1.547 11.465 11.465 0 0 1 2.779 1.992Z"
                  data-name="Path 99691"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109125">
            <g data-name="Group 109122">
              <g data-name="Group 109121">
                <path
                  fill="#263238"
                  d="M300.718 33.694a9.848 9.848 0 0 1 1.669-2.7 9.9 9.9 0 0 1 2.089-2.393 9.82 9.82 0 0 1-1.67 2.7 9.818 9.818 0 0 1-2.088 2.393Z"
                  data-name="Path 99692"
                />
              </g>
            </g>
            <g data-name="Group 109124">
              <g data-name="Group 109123">
                <path
                  fill="#263238"
                  d="M305.463 33.092a11.4 11.4 0 0 1-3.049-1.547 11.421 11.421 0 0 1-2.779-1.993 11.419 11.419 0 0 1 3.05 1.548 11.361 11.361 0 0 1 2.778 1.992Z"
                  data-name="Path 99693"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109152">
          <g data-name="Group 109131">
            <g data-name="Group 109128">
              <g data-name="Group 109127">
                <path
                  fill="#263238"
                  d="M264.249 54.1a9.842 9.842 0 0 1 1.557-2.768 9.838 9.838 0 0 1 1.99-2.477 9.872 9.872 0 0 1-1.558 2.769 9.9 9.9 0 0 1-1.989 2.476Z"
                  data-name="Path 99694"
                />
              </g>
            </g>
            <g data-name="Group 109130">
              <g data-name="Group 109129">
                <path
                  fill="#263238"
                  d="M268.963 53.303a11.362 11.362 0 0 1-3.11-1.421 11.368 11.368 0 0 1-2.858-1.876 22.4 22.4 0 0 1 5.968 3.3Z"
                  data-name="Path 99695"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109136">
            <g data-name="Group 109133">
              <g data-name="Group 109132">
                <path
                  fill="#263238"
                  d="M273.632 51.687a9.85 9.85 0 0 1 1.67-2.7 9.81 9.81 0 0 1 2.089-2.392 9.882 9.882 0 0 1-1.67 2.7 9.875 9.875 0 0 1-2.089 2.392Z"
                  data-name="Path 99696"
                />
              </g>
            </g>
            <g data-name="Group 109135">
              <g data-name="Group 109134">
                <path
                  fill="#263238"
                  d="M278.376 51.08a11.415 11.415 0 0 1-3.049-1.547 11.359 11.359 0 0 1-2.778-1.992 22.432 22.432 0 0 1 5.827 3.539Z"
                  data-name="Path 99697"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109141">
            <g data-name="Group 109138">
              <g data-name="Group 109137">
                <path
                  fill="#263238"
                  d="M283.555 49.053a9.85 9.85 0 0 1 1.67-2.7 9.81 9.81 0 0 1 2.089-2.392 9.849 9.849 0 0 1-1.67 2.7 9.859 9.859 0 0 1-2.089 2.392Z"
                  data-name="Path 99698"
                />
              </g>
            </g>
            <g data-name="Group 109140">
              <g data-name="Group 109139">
                <path
                  fill="#263238"
                  d="M288.3 48.45a11.347 11.347 0 0 1-3.049-1.547 11.353 11.353 0 0 1-2.779-1.992 11.384 11.384 0 0 1 3.049 1.547 11.434 11.434 0 0 1 2.779 1.992Z"
                  data-name="Path 99699"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109146">
            <g data-name="Group 109143">
              <g data-name="Group 109142">
                <path
                  fill="#263238"
                  d="M293.479 46.419a9.847 9.847 0 0 1 1.669-2.7 9.9 9.9 0 0 1 2.089-2.393 9.82 9.82 0 0 1-1.67 2.7 9.843 9.843 0 0 1-2.088 2.393Z"
                  data-name="Path 99700"
                />
              </g>
            </g>
            <g data-name="Group 109145">
              <g data-name="Group 109144">
                <path
                  fill="#263238"
                  d="M298.223 45.817a11.435 11.435 0 0 1-3.049-1.547 11.422 11.422 0 0 1-2.779-1.993 11.4 11.4 0 0 1 3.05 1.547 11.378 11.378 0 0 1 2.778 1.993Z"
                  data-name="Path 99701"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109151">
            <g data-name="Group 109148">
              <g data-name="Group 109147">
                <path
                  fill="#263238"
                  d="M303.402 43.785a9.837 9.837 0 0 1 1.67-2.7 9.851 9.851 0 0 1 2.089-2.393 9.85 9.85 0 0 1-1.67 2.7 9.81 9.81 0 0 1-2.089 2.393Z"
                  data-name="Path 99702"
                />
              </g>
            </g>
            <g data-name="Group 109150">
              <g data-name="Group 109149">
                <path
                  fill="#263238"
                  d="M308.147 43.18a11.383 11.383 0 0 1-3.049-1.547 11.372 11.372 0 0 1-2.779-1.993 11.437 11.437 0 0 1 3.049 1.548 11.4 11.4 0 0 1 2.779 1.992Z"
                  data-name="Path 99703"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109178">
          <g data-name="Group 109157">
            <g data-name="Group 109154">
              <g data-name="Group 109153">
                <path
                  fill="#263238"
                  d="M266.977 63.612a9.831 9.831 0 0 1 1.558-2.768 9.831 9.831 0 0 1 1.989-2.476 9.858 9.858 0 0 1-1.558 2.768 9.853 9.853 0 0 1-1.989 2.476Z"
                  data-name="Path 99704"
                />
              </g>
            </g>
            <g data-name="Group 109156">
              <g data-name="Group 109155">
                <path
                  fill="#263238"
                  d="M271.693 62.815a11.362 11.362 0 0 1-3.11-1.421 11.361 11.361 0 0 1-2.858-1.876 22.475 22.475 0 0 1 5.968 3.3Z"
                  data-name="Path 99705"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109162">
            <g data-name="Group 109159">
              <g data-name="Group 109158">
                <path
                  fill="#263238"
                  d="M276.363 61.199a9.85 9.85 0 0 1 1.67-2.7 9.834 9.834 0 0 1 2.089-2.392 9.849 9.849 0 0 1-1.67 2.7 9.833 9.833 0 0 1-2.089 2.392Z"
                  data-name="Path 99706"
                />
              </g>
            </g>
            <g data-name="Group 109161">
              <g data-name="Group 109160">
                <path
                  fill="#263238"
                  d="M281.105 60.596a11.361 11.361 0 0 1-3.049-1.546 11.372 11.372 0 0 1-2.779-1.993 22.46 22.46 0 0 1 5.828 3.539Z"
                  data-name="Path 99707"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109167">
            <g data-name="Group 109164">
              <g data-name="Group 109163">
                <path
                  fill="#263238"
                  d="M286.283 58.565a9.867 9.867 0 0 1 1.67-2.7 9.876 9.876 0 0 1 2.089-2.393 9.849 9.849 0 0 1-1.67 2.7 9.834 9.834 0 0 1-2.089 2.393Z"
                  data-name="Path 99708"
                />
              </g>
            </g>
            <g data-name="Group 109166">
              <g data-name="Group 109165">
                <path
                  fill="#263238"
                  d="M291.028 57.963a11.383 11.383 0 0 1-3.049-1.547 11.4 11.4 0 0 1-2.779-1.993 22.427 22.427 0 0 1 5.828 3.54Z"
                  data-name="Path 99709"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109172">
            <g data-name="Group 109169">
              <g data-name="Group 109168">
                <path
                  fill="#263238"
                  d="M296.207 55.932a9.817 9.817 0 0 1 1.67-2.7 9.852 9.852 0 0 1 2.089-2.393 9.863 9.863 0 0 1-1.67 2.7 9.852 9.852 0 0 1-2.089 2.393Z"
                  data-name="Path 99710"
                />
              </g>
            </g>
            <g data-name="Group 109171">
              <g data-name="Group 109170">
                <path
                  fill="#263238"
                  d="M300.951 55.329a11.415 11.415 0 0 1-3.049-1.547 11.422 11.422 0 0 1-2.778-1.992 22.431 22.431 0 0 1 5.827 3.539Z"
                  data-name="Path 99711"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109177">
            <g data-name="Group 109174">
              <g data-name="Group 109173">
                <path
                  fill="#263238"
                  d="M306.13 53.298a9.833 9.833 0 0 1 1.67-2.7 9.852 9.852 0 0 1 2.089-2.393 9.834 9.834 0 0 1-1.67 2.7 9.852 9.852 0 0 1-2.089 2.393Z"
                  data-name="Path 99712"
                />
              </g>
            </g>
            <g data-name="Group 109176">
              <g data-name="Group 109175">
                <path
                  fill="#263238"
                  d="M310.875 52.695a11.383 11.383 0 0 1-3.049-1.547 11.385 11.385 0 0 1-2.779-1.992 11.347 11.347 0 0 1 3.049 1.547 11.4 11.4 0 0 1 2.779 1.992Z"
                  data-name="Path 99713"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109263">
        <g data-name="Group 109183">
          <g data-name="Group 109180">
            <path
              fill="#fff"
              d="M422.824 95.856s-18.43 22.21-20.108 36.758a58.525 58.525 0 0 0 2.357 25.181l-61.938-17.751s-15.127-29.719 17.751-61.938Z"
              data-name="Path 99714"
            />
          </g>
          <g data-name="Group 109182">
            <g data-name="Group 109181">
              <path
                fill="#ced3dd"
                d="M422.824 95.856s-.409-.1-1.168-.314l-3.34-.935-12.615-3.558-44.877-12.729.218-.055a88.259 88.259 0 0 0-10.013 11.687 65.122 65.122 0 0 0-7.787 14.584 52.259 52.259 0 0 0-3.307 17.154 43.994 43.994 0 0 0 3.271 17.9l.161.339-.16-.132 61.928 17.783-.274.3a55.168 55.168 0 0 1-2.64-14.355 60.046 60.046 0 0 1-.027-6.782 40.149 40.149 0 0 1 .734-6.369 47.693 47.693 0 0 1 4.068-10.847 97.576 97.576 0 0 1 4.857-8.559 153.973 153.973 0 0 1 7.9-11.307 125.11 125.11 0 0 1 2.257-2.852l.6-.715.214-.233s-.052.08-.186.255l-.571.738-2.2 2.892a165.578 165.578 0 0 0-7.788 11.363c-1.546 2.529-3.235 5.349-4.794 8.561a47.64 47.64 0 0 0-3.991 10.78 39.85 39.85 0 0 0-.7 6.312 59.586 59.586 0 0 0 .048 6.738 54.926 54.926 0 0 0 2.648 14.219l.141.417-.416-.118-61.952-17.724-.113-.032-.047-.1-.171-.361a44.437 44.437 0 0 1-3.313-18.12 52.687 52.687 0 0 1 3.353-17.32 65.453 65.453 0 0 1 7.867-14.685 88.462 88.462 0 0 1 10.09-11.73l.093-.091.125.036 44.975 13.021 12.522 3.647 3.278.961c.741.219 1.102.336 1.102.336Z"
                data-name="Path 99715"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109184">
          <path
            d="m374.768 102.601 1.37-4.447-3.288 2.939-1.053-.33-1.047-4.168-1.356 4.321-2.189-.687 2.628-8.377 1.951.612 1.427 5.758 4.389-3.934 1.951.612-2.6 8.385Z"
            data-name="Path 99716"
          />
          <path
            d="m384.741 103.941-3.543-1.111-1.169 1.422-2.418-.759 6.326-7.217 2.334.732 1.082 9.541-2.465-.774Zm-.146-1.961-.237-3.019-1.918 2.344Z"
            data-name="Path 99717"
          />
          <path
            d="m392.38 105.68-1.292-.4-.7 2.226-2.371-.743 2.628-8.378 3.83 1.2c2.287.718 3.35 2.352 2.749 4.268a2.912 2.912 0 0 1-2.461 2.127l.98 3.2-2.537-.8Zm1.368-4.277-1.316-.413-.77 2.454 1.316.413c.982.308 1.616.007 1.856-.759s-.105-1.387-1.086-1.695Z"
            data-name="Path 99718"
          />
        </g>
        <g data-name="Group 109210">
          <g data-name="Group 109189">
            <g data-name="Group 109186">
              <g data-name="Group 109185">
                <path
                  fill="#263238"
                  d="M351.088 109.66a19.357 19.357 0 0 1 5.7-2.748 19.326 19.326 0 0 1-5.7 2.748Z"
                  data-name="Path 99719"
                />
              </g>
            </g>
            <g data-name="Group 109188">
              <g data-name="Group 109187">
                <path
                  fill="#263238"
                  d="M355.563 111.344a11.428 11.428 0 0 1-1.973-2.792 11.406 11.406 0 0 1-1.527-3.06 11.368 11.368 0 0 1 1.974 2.792 11.405 11.405 0 0 1 1.526 3.06Z"
                  data-name="Path 99720"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109194">
            <g data-name="Group 109191">
              <g data-name="Group 109190">
                <path
                  fill="#263238"
                  d="M360.412 112.294a19.365 19.365 0 0 1 5.811-2.512 19.394 19.394 0 0 1-5.811 2.512Z"
                  data-name="Path 99721"
                />
              </g>
            </g>
            <g data-name="Group 109193">
              <g data-name="Group 109192">
                <path
                  fill="#263238"
                  d="M364.816 114.16a11.38 11.38 0 0 1-1.857-2.871 11.354 11.354 0 0 1-1.4-3.119 11.4 11.4 0 0 1 1.857 2.87 11.413 11.413 0 0 1 1.4 3.12Z"
                  data-name="Path 99722"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109199">
            <g data-name="Group 109196">
              <g data-name="Group 109195">
                <path
                  fill="#263238"
                  d="M370.313 115.009a19.4 19.4 0 0 1 5.811-2.513 19.335 19.335 0 0 1-5.811 2.513Z"
                  data-name="Path 99723"
                />
              </g>
            </g>
            <g data-name="Group 109198">
              <g data-name="Group 109197">
                <path
                  fill="#263238"
                  d="M374.717 116.874a11.394 11.394 0 0 1-1.857-2.87 11.427 11.427 0 0 1-1.4-3.12 11.351 11.351 0 0 1 1.858 2.871 11.354 11.354 0 0 1 1.399 3.119Z"
                  data-name="Path 99724"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109204">
            <g data-name="Group 109201">
              <g data-name="Group 109200">
                <path
                  fill="#263238"
                  d="M380.215 117.723a19.365 19.365 0 0 1 5.811-2.512 19.365 19.365 0 0 1-5.811 2.512Z"
                  data-name="Path 99725"
                />
              </g>
            </g>
            <g data-name="Group 109203">
              <g data-name="Group 109202">
                <path
                  fill="#263238"
                  d="M384.619 119.589a11.414 11.414 0 0 1-1.857-2.871 11.39 11.39 0 0 1-1.4-3.119 11.37 11.37 0 0 1 1.857 2.87 11.375 11.375 0 0 1 1.4 3.12Z"
                  data-name="Path 99726"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109209">
            <g data-name="Group 109206">
              <g data-name="Group 109205">
                <path
                  fill="#263238"
                  d="M390.117 120.438a19.4 19.4 0 0 1 5.811-2.513 19.364 19.364 0 0 1-5.811 2.513Z"
                  data-name="Path 99727"
                />
              </g>
            </g>
            <g data-name="Group 109208">
              <g data-name="Group 109207">
                <path
                  fill="#263238"
                  d="M394.521 122.303a11.381 11.381 0 0 1-1.858-2.87 11.366 11.366 0 0 1-1.4-3.12 22.482 22.482 0 0 1 3.257 5.99Z"
                  data-name="Path 99728"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109236">
          <g data-name="Group 109215">
            <g data-name="Group 109212">
              <g data-name="Group 109211">
                <path
                  fill="#263238"
                  d="M349.704 119.45a19.36 19.36 0 0 1 5.7-2.749 19.393 19.393 0 0 1-5.7 2.749Z"
                  data-name="Path 99729"
                />
              </g>
            </g>
            <g data-name="Group 109214">
              <g data-name="Group 109213">
                <path
                  fill="#263238"
                  d="M354.181 121.133a11.367 11.367 0 0 1-1.974-2.792 11.387 11.387 0 0 1-1.526-3.059 22.486 22.486 0 0 1 3.5 5.851Z"
                  data-name="Path 99730"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109220">
            <g data-name="Group 109217">
              <g data-name="Group 109216">
                <path
                  fill="#263238"
                  d="M359.027 122.08a19.4 19.4 0 0 1 5.811-2.513 19.334 19.334 0 0 1-5.811 2.513Z"
                  data-name="Path 99731"
                />
              </g>
            </g>
            <g data-name="Group 109219">
              <g data-name="Group 109218">
                <path
                  fill="#263238"
                  d="M363.431 123.949a11.37 11.37 0 0 1-1.857-2.87 11.425 11.425 0 0 1-1.4-3.12 22.407 22.407 0 0 1 3.257 5.99Z"
                  data-name="Path 99732"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109225">
            <g data-name="Group 109222">
              <g data-name="Group 109221">
                <path
                  fill="#263238"
                  d="M368.929 124.798a19.365 19.365 0 0 1 5.811-2.512 19.365 19.365 0 0 1-5.811 2.512Z"
                  data-name="Path 99733"
                />
              </g>
            </g>
            <g data-name="Group 109224">
              <g data-name="Group 109223">
                <path
                  fill="#263238"
                  d="M373.333 126.664a11.412 11.412 0 0 1-1.857-2.871 11.391 11.391 0 0 1-1.4-3.119 22.44 22.44 0 0 1 3.257 5.99Z"
                  data-name="Path 99734"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109230">
            <g data-name="Group 109227">
              <g data-name="Group 109226">
                <path
                  fill="#263238"
                  d="M378.831 127.513a19.4 19.4 0 0 1 5.811-2.513 19.363 19.363 0 0 1-5.811 2.513Z"
                  data-name="Path 99735"
                />
              </g>
            </g>
            <g data-name="Group 109229">
              <g data-name="Group 109228">
                <path
                  fill="#263238"
                  d="M383.235 129.38a11.358 11.358 0 0 1-1.858-2.87 11.367 11.367 0 0 1-1.4-3.12 22.482 22.482 0 0 1 3.257 5.99Z"
                  data-name="Path 99736"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109235">
            <g data-name="Group 109232">
              <g data-name="Group 109231">
                <path
                  fill="#263238"
                  d="M388.732 130.227a19.365 19.365 0 0 1 5.811-2.512 19.365 19.365 0 0 1-5.811 2.512Z"
                  data-name="Path 99737"
                />
              </g>
            </g>
            <g data-name="Group 109234">
              <g data-name="Group 109233">
                <path
                  fill="#263238"
                  d="M393.136 132.093a22.482 22.482 0 0 1-3.257-5.99 22.366 22.366 0 0 1 3.257 5.99Z"
                  data-name="Path 99738"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109262">
          <g data-name="Group 109241">
            <g data-name="Group 109238">
              <g data-name="Group 109237">
                <path
                  fill="#263238"
                  d="M351.119 129.68a19.393 19.393 0 0 1 5.7-2.749 19.36 19.36 0 0 1-5.7 2.749Z"
                  data-name="Path 99739"
                />
              </g>
            </g>
            <g data-name="Group 109240">
              <g data-name="Group 109239">
                <path
                  fill="#263238"
                  d="M355.596 131.362a22.468 22.468 0 0 1-3.5-5.852 22.44 22.44 0 0 1 3.5 5.852Z"
                  data-name="Path 99740"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109246">
            <g data-name="Group 109243">
              <g data-name="Group 109242">
                <path
                  fill="#263238"
                  d="M360.443 132.313a19.334 19.334 0 0 1 5.811-2.513 19.431 19.431 0 0 1-5.811 2.513Z"
                  data-name="Path 99741"
                />
              </g>
            </g>
            <g data-name="Group 109245">
              <g data-name="Group 109244">
                <path
                  fill="#263238"
                  d="M364.847 134.18a22.44 22.44 0 0 1-3.257-5.99 22.483 22.483 0 0 1 3.257 5.99Z"
                  data-name="Path 99742"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109251">
            <g data-name="Group 109248">
              <g data-name="Group 109247">
                <path
                  fill="#263238"
                  d="M370.344 135.027a19.365 19.365 0 0 1 5.811-2.512 19.365 19.365 0 0 1-5.811 2.512Z"
                  data-name="Path 99743"
                />
              </g>
            </g>
            <g data-name="Group 109250">
              <g data-name="Group 109249">
                <path
                  fill="#263238"
                  d="M374.748 136.893a22.467 22.467 0 0 1-3.257-5.991 22.392 22.392 0 0 1 3.257 5.991Z"
                  data-name="Path 99744"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109256">
            <g data-name="Group 109253">
              <g data-name="Group 109252">
                <path
                  fill="#263238"
                  d="M380.246 137.742a19.335 19.335 0 0 1 5.811-2.513 19.4 19.4 0 0 1-5.811 2.513Z"
                  data-name="Path 99745"
                />
              </g>
            </g>
            <g data-name="Group 109255">
              <g data-name="Group 109254">
                <path
                  fill="#263238"
                  d="M384.65 139.607a22.441 22.441 0 0 1-3.257-5.99 22.482 22.482 0 0 1 3.257 5.99Z"
                  data-name="Path 99746"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109261">
            <g data-name="Group 109258">
              <g data-name="Group 109257">
                <path
                  fill="#263238"
                  d="M390.148 140.456a19.365 19.365 0 0 1 5.811-2.512 19.395 19.395 0 0 1-5.811 2.512Z"
                  data-name="Path 99747"
                />
              </g>
            </g>
            <g data-name="Group 109260">
              <g data-name="Group 109259">
                <path
                  fill="#263238"
                  d="M394.552 142.322a22.392 22.392 0 0 1-3.257-5.991 22.467 22.467 0 0 1 3.257 5.991Z"
                  data-name="Path 99748"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109347">
        <g data-name="Group 109267">
          <g data-name="Group 109264">
            <path
              fill="#fff"
              d="m139.517 76.765 12.307-63.272 63.272 12.306-12.307 63.273z"
              data-name="Rectangle 56397"
            />
          </g>
          <g data-name="Group 109266">
            <g data-name="Group 109265">
              <path
                fill="#ced3dd"
                d="M202.763 89.08s.012-.109.047-.308l.159-.883.637-3.412c.568-2.991 1.4-7.348 2.448-12.885l8.8-45.8.178.264-63.248-12.276h-.006l.305-.206-12.345 63.238-.178-.264 45.757 9.023 12.862 2.564 3.4.689.877.183c.2.043.3.072.3.072a2.221 2.221 0 0 1-.284-.042l-.858-.154-3.369-.629-12.835-2.439-45.942-8.825-.221-.043.043-.221 12.276-63.252.05-.255.255.05h.006l63.238 12.341.222.043-.044.221-9.05 45.9c-1.1 5.506-1.961 9.839-2.554 12.813-.3 1.459-.523 2.582-.681 3.36l-.179.853a2.338 2.338 0 0 1-.066.28Z"
                data-name="Path 99749"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109268">
          <path
            d="m167.176 36.515 1.662-1.285a1.938 1.938 0 0 0 1.292 1.214c.7.151 1.172-.2 1.348-1.018l.795-3.691-2.968-.638.4-1.877 5.371 1.157-1.167 5.42c-.48 2.231-1.87 3.087-4.028 2.622a3.812 3.812 0 0 1-2.705-1.904Z"
            data-name="Path 99750"
          />
          <path
            d="m180.798 38.844-3.63-.782-1.032 1.518-2.478-.533 5.637-7.767 2.39.514 1.955 9.4-2.527-.544Zm-.325-1.944-.514-2.984-1.696 2.509Z"
            data-name="Path 99751"
          />
          <path
            d="m194.335 34.521-1.848 8.583-2-.43-2.8-5.389-.984 4.573-2.379-.511 1.848-8.583 2 .43 2.8 5.389.984-4.574Z"
            data-name="Path 99752"
          />
        </g>
        <g data-name="Group 109294">
          <g data-name="Group 109273">
            <g data-name="Group 109270">
              <g data-name="Group 109269">
                <path
                  fill="#263238"
                  d="M154.148 47.509a19.4 19.4 0 0 1 5.554-3.038 9.84 9.84 0 0 1-2.652 1.748 9.873 9.873 0 0 1-2.902 1.29Z"
                  data-name="Path 99753"
                />
              </g>
            </g>
            <g data-name="Group 109272">
              <g data-name="Group 109271">
                <path
                  fill="#263238"
                  d="M158.705 48.96a11.4 11.4 0 0 1-2.114-2.687 11.359 11.359 0 0 1-1.681-2.977 11.428 11.428 0 0 1 2.114 2.684 11.359 11.359 0 0 1 1.681 2.98Z"
                  data-name="Path 99754"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109278">
            <g data-name="Group 109275">
              <g data-name="Group 109274">
                <path
                  fill="#263238"
                  d="M163.594 49.661a19.4 19.4 0 0 1 5.675-2.808 9.856 9.856 0 0 1-2.722 1.637 9.831 9.831 0 0 1-2.953 1.171Z"
                  data-name="Path 99755"
                />
              </g>
            </g>
            <g data-name="Group 109277">
              <g data-name="Group 109276">
                <path
                  fill="#263238"
                  d="M168.088 51.298a11.409 11.409 0 0 1-2-2.772 11.383 11.383 0 0 1-1.558-3.043 11.36 11.36 0 0 1 2 2.771 11.38 11.38 0 0 1 1.558 3.044Z"
                  data-name="Path 99756"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109283">
            <g data-name="Group 109280">
              <g data-name="Group 109279">
                <path
                  fill="#263238"
                  d="M173.622 51.863a19.365 19.365 0 0 1 5.675-2.807 9.856 9.856 0 0 1-2.722 1.637 9.844 9.844 0 0 1-2.953 1.17Z"
                  data-name="Path 99757"
                />
              </g>
            </g>
            <g data-name="Group 109282">
              <g data-name="Group 109281">
                <path
                  fill="#263238"
                  d="M178.116 53.5a11.384 11.384 0 0 1-2-2.771 11.38 11.38 0 0 1-1.558-3.044 11.378 11.378 0 0 1 2 2.772 11.383 11.383 0 0 1 1.558 3.043Z"
                  data-name="Path 99758"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109288">
            <g data-name="Group 109285">
              <g data-name="Group 109284">
                <path
                  fill="#263238"
                  d="M183.65 54.066a19.364 19.364 0 0 1 5.675-2.807 9.913 9.913 0 0 1-2.722 1.637 9.843 9.843 0 0 1-2.953 1.17Z"
                  data-name="Path 99759"
                />
              </g>
            </g>
            <g data-name="Group 109287">
              <g data-name="Group 109286">
                <path
                  fill="#263238"
                  d="M188.144 55.703a11.385 11.385 0 0 1-2-2.771 11.4 11.4 0 0 1-1.558-3.044 11.409 11.409 0 0 1 2 2.772 11.367 11.367 0 0 1 1.558 3.043Z"
                  data-name="Path 99760"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109293">
            <g data-name="Group 109290">
              <g data-name="Group 109289">
                <path
                  fill="#263238"
                  d="M193.678 56.269a19.411 19.411 0 0 1 5.674-2.808 19.379 19.379 0 0 1-5.674 2.808Z"
                  data-name="Path 99761"
                />
              </g>
            </g>
            <g data-name="Group 109292">
              <g data-name="Group 109291">
                <path
                  fill="#263238"
                  d="M198.172 57.906a11.379 11.379 0 0 1-2-2.772 11.383 11.383 0 0 1-1.558-3.043 11.365 11.365 0 0 1 2 2.771 11.38 11.38 0 0 1 1.558 3.044Z"
                  data-name="Path 99762"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109320">
          <g data-name="Group 109299">
            <g data-name="Group 109296">
              <g data-name="Group 109295">
                <path
                  fill="#263238"
                  d="M152.116 57.589a19.39 19.39 0 0 1 5.481-3.168 9.871 9.871 0 0 1-2.61 1.81 9.838 9.838 0 0 1-2.871 1.358Z"
                  data-name="Path 99763"
                />
              </g>
            </g>
            <g data-name="Group 109298">
              <g data-name="Group 109297">
                <path
                  fill="#263238"
                  d="M156.707 58.932a11.336 11.336 0 0 1-2.177-2.636 11.361 11.361 0 0 1-1.752-2.936 11.393 11.393 0 0 1 2.177 2.636 11.412 11.412 0 0 1 1.752 2.936Z"
                  data-name="Path 99764"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109304">
            <g data-name="Group 109301">
              <g data-name="Group 109300">
                <path
                  fill="#263238"
                  d="M161.611 59.517a19.408 19.408 0 0 1 5.606-2.942 19.344 19.344 0 0 1-5.606 2.942Z"
                  data-name="Path 99765"
                />
              </g>
            </g>
            <g data-name="Group 109303">
              <g data-name="Group 109302">
                <path
                  fill="#263238"
                  d="M166.143 61.046a11.343 11.343 0 0 1-2.068-2.723 11.419 11.419 0 0 1-1.63-3.005 11.369 11.369 0 0 1 2.068 2.723 11.437 11.437 0 0 1 1.63 3.005Z"
                  data-name="Path 99766"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109309">
            <g data-name="Group 109306">
              <g data-name="Group 109305">
                <path
                  fill="#263238"
                  d="M171.688 61.48a19.362 19.362 0 0 1 5.607-2.941 19.4 19.4 0 0 1-5.607 2.941Z"
                  data-name="Path 99767"
                />
              </g>
            </g>
            <g data-name="Group 109308">
              <g data-name="Group 109307">
                <path
                  fill="#263238"
                  d="M176.22 63.011a11.377 11.377 0 0 1-2.067-2.723 11.362 11.362 0 0 1-1.63-3.006 11.381 11.381 0 0 1 2.067 2.723 11.38 11.38 0 0 1 1.63 3.006Z"
                  data-name="Path 99768"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109314">
            <g data-name="Group 109311">
              <g data-name="Group 109310">
                <path
                  fill="#263238"
                  d="M181.763 63.445a19.373 19.373 0 0 1 5.606-2.941 19.373 19.373 0 0 1-5.606 2.941Z"
                  data-name="Path 99769"
                />
              </g>
            </g>
            <g data-name="Group 109313">
              <g data-name="Group 109312">
                <path
                  fill="#263238"
                  d="M186.297 64.98a11.381 11.381 0 0 1-2.067-2.723 11.38 11.38 0 0 1-1.63-3.006 11.375 11.375 0 0 1 2.063 2.719 11.367 11.367 0 0 1 1.634 3.01Z"
                  data-name="Path 99770"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109319">
            <g data-name="Group 109316">
              <g data-name="Group 109315">
                <path
                  fill="#263238"
                  d="M191.843 65.41a19.343 19.343 0 0 1 5.606-2.942 19.344 19.344 0 0 1-5.606 2.942Z"
                  data-name="Path 99771"
                />
              </g>
            </g>
            <g data-name="Group 109318">
              <g data-name="Group 109317">
                <path
                  fill="#263238"
                  d="M196.375 66.939a11.369 11.369 0 0 1-2.068-2.723 11.416 11.416 0 0 1-1.63-3.006 11.388 11.388 0 0 1 2.068 2.724 11.419 11.419 0 0 1 1.63 3.005Z"
                  data-name="Path 99772"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109346">
          <g data-name="Group 109325">
            <g data-name="Group 109322">
              <g data-name="Group 109321">
                <path
                  fill="#263238"
                  d="M150.679 67.126a19.361 19.361 0 0 1 5.481-3.169 19.361 19.361 0 0 1-5.481 3.169Z"
                  data-name="Path 99773"
                />
              </g>
            </g>
            <g data-name="Group 109324">
              <g data-name="Group 109323">
                <path
                  fill="#263238"
                  d="M155.27 68.468a11.336 11.336 0 0 1-2.177-2.636 11.343 11.343 0 0 1-1.752-2.936 11.393 11.393 0 0 1 2.177 2.636 11.413 11.413 0 0 1 1.752 2.936Z"
                  data-name="Path 99774"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109330">
            <g data-name="Group 109327">
              <g data-name="Group 109326">
                <path
                  fill="#263238"
                  d="M160.174 69.053a19.374 19.374 0 0 1 5.606-2.941 19.373 19.373 0 0 1-5.606 2.941Z"
                  data-name="Path 99775"
                />
              </g>
            </g>
            <g data-name="Group 109329">
              <g data-name="Group 109328">
                <path
                  fill="#263238"
                  d="M164.706 70.58a11.4 11.4 0 0 1-2.068-2.723 11.433 11.433 0 0 1-1.63-3.006 11.369 11.369 0 0 1 2.068 2.723 11.416 11.416 0 0 1 1.63 3.006Z"
                  data-name="Path 99776"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109335">
            <g data-name="Group 109332">
              <g data-name="Group 109331">
                <path
                  fill="#263238"
                  d="M170.251 71.017a19.361 19.361 0 0 1 5.612-2.937 19.4 19.4 0 0 1-5.607 2.941Z"
                  data-name="Path 99777"
                />
              </g>
            </g>
            <g data-name="Group 109334">
              <g data-name="Group 109333">
                <path
                  fill="#263238"
                  d="M174.783 72.547a11.351 11.351 0 0 1-2.067-2.723 11.345 11.345 0 0 1-1.63-3.006 11.381 11.381 0 0 1 2.067 2.723 11.381 11.381 0 0 1 1.63 3.006Z"
                  data-name="Path 99778"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109340">
            <g data-name="Group 109337">
              <g data-name="Group 109336">
                <path
                  fill="#263238"
                  d="M180.329 72.98a19.374 19.374 0 0 1 5.606-2.941 19.373 19.373 0 0 1-5.606 2.941Z"
                  data-name="Path 99779"
                />
              </g>
            </g>
            <g data-name="Group 109339">
              <g data-name="Group 109338">
                <path
                  fill="#263238"
                  d="M184.863 74.511a11.381 11.381 0 0 1-2.067-2.723 11.382 11.382 0 0 1-1.633-3.008 11.374 11.374 0 0 1 2.067 2.724 11.384 11.384 0 0 1 1.633 3.007Z"
                  data-name="Path 99780"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109345">
            <g data-name="Group 109342">
              <g data-name="Group 109341">
                <path
                  fill="#263238"
                  d="M190.406 74.946a19.408 19.408 0 0 1 5.606-2.942 19.344 19.344 0 0 1-5.606 2.942Z"
                  data-name="Path 99781"
                />
              </g>
            </g>
            <g data-name="Group 109344">
              <g data-name="Group 109343">
                <path
                  fill="#263238"
                  d="M194.937 76.48a11.431 11.431 0 0 1-2.067-2.724 11.419 11.419 0 0 1-1.63-3.005 11.369 11.369 0 0 1 2.068 2.723 11.358 11.358 0 0 1 1.629 3.006Z"
                  data-name="Path 99782"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109431">
        <g data-name="Group 109351">
          <g data-name="Group 109348">
            <path
              fill="#fff"
              d="M16.901 132.359s-4.662 47.538 45.622 55.673l58.478-2.524s-41.238-27.034-39.669-53.149Z"
              data-name="Path 99783"
            />
          </g>
          <g data-name="Group 109350">
            <g data-name="Group 109349">
              <path
                fill="#ced3dd"
                d="M16.901 132.359s.385-.018 1.154-.026l3.415-.026 13.034-.058 46.828-.112h.231l-.01.235a29.232 29.232 0 0 0 .678 7.5 38.913 38.913 0 0 0 2.611 7.436 65.08 65.08 0 0 0 9.167 13.925 118.786 118.786 0 0 0 12.462 12.647 148.871 148.871 0 0 0 14.673 11.414l.655.444-.787.034-2.516.108-55.963 2.389h-.048a67.767 67.767 0 0 1-16.3-4.7 49.092 49.092 0 0 1-12.969-8.271 46.272 46.272 0 0 1-8.709-10.447 50.067 50.067 0 0 1-4.833-10.56 60.147 60.147 0 0 1-2.9-16.092 49.908 49.908 0 0 1 0-4.345c.021-.492.052-.865.074-1.115a2.339 2.339 0 0 1 .049-.375c0 .008 0 .126-.01.378s-.02.635-.035 1.115c-.02.983-.03 2.442.072 4.335a61.375 61.375 0 0 0 3.016 16.009 50.1 50.1 0 0 0 4.855 10.471 46.081 46.081 0 0 0 8.679 10.33 48.792 48.792 0 0 0 12.889 8.161 67.566 67.566 0 0 0 16.2 4.635h-.048l55.96-2.439 2.517-.109-.132.478a148.681 148.681 0 0 1-14.728-11.464 118.82 118.82 0 0 1-12.5-12.709 65.373 65.373 0 0 1-9.215-14.045 39.34 39.34 0 0 1-2.629-7.535 29.638 29.638 0 0 1-.672-7.627l.221.235-46.7-.111-13.08-.058-3.455-.026c-.785-.008-1.201-.029-1.201-.029Z"
                data-name="Path 99784"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109352">
          <path
            d="M46.433 140.934v8.78h-2.044l-3.875-4.678v4.678H38.08v-8.78h2.044L44 145.613v-4.679Z"
            data-name="Path 99785"
          />
          <path
            d="M47.733 145.324a4.89 4.89 0 1 1 4.879 4.566 4.591 4.591 0 0 1-4.879-4.566Zm7.25 0a2.374 2.374 0 1 0-2.371 2.509 2.351 2.351 0 0 0 2.371-2.509Z"
            data-name="Path 99786"
          />
          <path
            d="m67.395 140.934-3.762 8.78h-2.446l-3.751-8.78h2.685l2.383 5.72 2.433-5.72Z"
            data-name="Path 99787"
          />
        </g>
        <g data-name="Group 109378">
          <g data-name="Group 109357">
            <g data-name="Group 109354">
              <g data-name="Group 109353">
                <path
                  fill="#263238"
                  d="M35.105 164.062a14.2 14.2 0 0 1 2.522-2.875 14.2 14.2 0 0 1 2.889-2.507 14.176 14.176 0 0 1-2.522 2.876 14.211 14.211 0 0 1-2.889 2.506Z"
                  data-name="Path 99788"
                />
              </g>
            </g>
            <g data-name="Group 109356">
              <g data-name="Group 109355">
                <path
                  fill="#263238"
                  d="M40.869 164.212a16.382 16.382 0 0 1-3.316-2.442 16.464 16.464 0 0 1-2.981-2.841 16.382 16.382 0 0 1 3.316 2.442 16.506 16.506 0 0 1 2.981 2.841Z"
                  data-name="Path 99789"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109362">
            <g data-name="Group 109359">
              <g data-name="Group 109358">
                <path
                  fill="#263238"
                  d="M46.513 162.909a9.854 9.854 0 0 1 2.158-2.33 9.862 9.862 0 0 1 2.51-1.947 9.846 9.846 0 0 1-2.158 2.331 9.842 9.842 0 0 1-2.51 1.946Z"
                  data-name="Path 99790"
                />
              </g>
            </g>
            <g data-name="Group 109361">
              <g data-name="Group 109360">
                <path
                  fill="#263238"
                  d="M51.285 163.23a11.411 11.411 0 0 1-2.695-2.1 11.384 11.384 0 0 1-2.344-2.49 11.378 11.378 0 0 1 2.695 2.1 11.338 11.338 0 0 1 2.344 2.49Z"
                  data-name="Path 99791"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109367">
            <g data-name="Group 109364">
              <g data-name="Group 109363">
                <path
                  fill="#263238"
                  d="M58.015 162.907a9.827 9.827 0 0 1 2.158-2.33 9.843 9.843 0 0 1 2.51-1.947 9.838 9.838 0 0 1-2.159 2.331 9.829 9.829 0 0 1-2.509 1.946Z"
                  data-name="Path 99792"
                />
              </g>
            </g>
            <g data-name="Group 109366">
              <g data-name="Group 109365">
                <path
                  fill="#263238"
                  d="M62.787 163.228a11.389 11.389 0 0 1-2.695-2.1 11.354 11.354 0 0 1-2.344-2.49 11.4 11.4 0 0 1 2.695 2.1 11.422 11.422 0 0 1 2.344 2.49Z"
                  data-name="Path 99793"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109372">
            <g data-name="Group 109369">
              <g data-name="Group 109368">
                <path
                  fill="#263238"
                  d="M68.783 162.905a9.854 9.854 0 0 1 2.158-2.33 9.862 9.862 0 0 1 2.51-1.947 9.846 9.846 0 0 1-2.158 2.331 9.842 9.842 0 0 1-2.51 1.946Z"
                  data-name="Path 99794"
                />
              </g>
            </g>
            <g data-name="Group 109371">
              <g data-name="Group 109370">
                <path
                  fill="#263238"
                  d="M73.555 163.226a11.389 11.389 0 0 1-2.695-2.1 11.353 11.353 0 0 1-2.344-2.49 11.4 11.4 0 0 1 2.695 2.105 11.392 11.392 0 0 1 2.344 2.485Z"
                  data-name="Path 99795"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109377">
            <g data-name="Group 109374">
              <g data-name="Group 109373">
                <path
                  fill="#263238"
                  d="M77.677 162.645a9.411 9.411 0 0 1 2.054-2.326 9.426 9.426 0 0 1 2.415-1.95 9.383 9.383 0 0 1-2.055 2.326 9.412 9.412 0 0 1-2.414 1.95Z"
                  data-name="Path 99796"
                />
              </g>
            </g>
            <g data-name="Group 109376">
              <g data-name="Group 109375">
                <path
                  fill="#263238"
                  d="M82.345 162.858a10.92 10.92 0 0 1-2.681-1.994 10.872 10.872 0 0 1-2.338-2.387 10.909 10.909 0 0 1 2.681 1.994 10.848 10.848 0 0 1 2.338 2.387Z"
                  data-name="Path 99797"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109404">
          <g data-name="Group 109383">
            <g data-name="Group 109380">
              <g data-name="Group 109379">
                <path
                  fill="#263238"
                  d="M43.563 172.544a9.865 9.865 0 0 1 2-2.465 9.881 9.881 0 0 1 2.379-2.1 9.809 9.809 0 0 1-2 2.465 9.819 9.819 0 0 1-2.379 2.1Z"
                  data-name="Path 99798"
                />
              </g>
            </g>
            <g data-name="Group 109382">
              <g data-name="Group 109381">
                <path
                  fill="#263238"
                  d="M48.344 172.555a11.4 11.4 0 0 1-2.825-1.925 11.347 11.347 0 0 1-2.5-2.333 11.385 11.385 0 0 1 2.825 1.926 11.384 11.384 0 0 1 2.5 2.332Z"
                  data-name="Path 99799"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109388">
            <g data-name="Group 109385">
              <g data-name="Group 109384">
                <path
                  fill="#263238"
                  d="M54.408 172.147a9.866 9.866 0 0 1 2.1-2.381 9.858 9.858 0 0 1 2.463-2 9.854 9.854 0 0 1-2.1 2.381 9.819 9.819 0 0 1-2.463 2Z"
                  data-name="Path 99800"
                />
              </g>
            </g>
            <g data-name="Group 109387">
              <g data-name="Group 109386">
                <path
                  fill="#263238"
                  d="M59.186 172.354a11.367 11.367 0 0 1-2.743-2.04 11.368 11.368 0 0 1-2.4-2.433 11.415 11.415 0 0 1 2.744 2.04 11.373 11.373 0 0 1 2.399 2.433Z"
                  data-name="Path 99801"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109393">
            <g data-name="Group 109390">
              <g data-name="Group 109389">
                <path
                  fill="#263238"
                  d="M65.563 171.898a9.866 9.866 0 0 1 2.1-2.381 9.823 9.823 0 0 1 2.464-2.006 9.858 9.858 0 0 1-2.1 2.381 9.865 9.865 0 0 1-2.464 2.006Z"
                  data-name="Path 99802"
                />
              </g>
            </g>
            <g data-name="Group 109392">
              <g data-name="Group 109391">
                <path
                  fill="#263238"
                  d="M70.341 172.105a11.421 11.421 0 0 1-2.743-2.04 11.367 11.367 0 0 1-2.4-2.433 11.416 11.416 0 0 1 2.744 2.04 11.347 11.347 0 0 1 2.399 2.433Z"
                  data-name="Path 99803"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109398">
            <g data-name="Group 109395">
              <g data-name="Group 109394">
                <path
                  fill="#263238"
                  d="M75.789 170.98a9.867 9.867 0 0 1 2.1-2.38 9.849 9.849 0 0 1 2.463-2.006 9.812 9.812 0 0 1-2.1 2.381 9.838 9.838 0 0 1-2.463 2.005Z"
                  data-name="Path 99804"
                />
              </g>
            </g>
            <g data-name="Group 109397">
              <g data-name="Group 109396">
                <path
                  fill="#263238"
                  d="M80.563 171.185a11.416 11.416 0 0 1-2.744-2.04 11.347 11.347 0 0 1-2.4-2.433 11.437 11.437 0 0 1 2.744 2.04 11.376 11.376 0 0 1 2.4 2.433Z"
                  data-name="Path 99805"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109403">
            <g data-name="Group 109400">
              <g data-name="Group 109399">
                <path
                  fill="#263238"
                  d="M85.34 170.364a19.4 19.4 0 0 1 4.016-4.894 9.872 9.872 0 0 1-1.807 2.612 9.873 9.873 0 0 1-2.209 2.282Z"
                  data-name="Path 99806"
                />
              </g>
            </g>
            <g data-name="Group 109402">
              <g data-name="Group 109401">
                <path
                  fill="#263238"
                  d="M90.109 170.006a11.381 11.381 0 0 1-2.965-1.7 11.41 11.41 0 0 1-2.673-2.133 11.4 11.4 0 0 1 2.966 1.7 11.373 11.373 0 0 1 2.672 2.133Z"
                  data-name="Path 99807"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109430">
          <g data-name="Group 109409">
            <g data-name="Group 109406">
              <g data-name="Group 109405">
                <path
                  fill="#263238"
                  d="M56.308 181.061a9.854 9.854 0 0 1 2.038-2.435 9.826 9.826 0 0 1 2.41-2.07 9.886 9.886 0 0 1-2.039 2.436 9.841 9.841 0 0 1-2.409 2.069Z"
                  data-name="Path 99808"
                />
              </g>
            </g>
            <g data-name="Group 109408">
              <g data-name="Group 109407">
                <path
                  fill="#263238"
                  d="M61.09 181.142a11.38 11.38 0 0 1-2.8-1.966 11.4 11.4 0 0 1-2.466-2.369 11.4 11.4 0 0 1 2.8 1.967 11.356 11.356 0 0 1 2.466 2.368Z"
                  data-name="Path 99809"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109414">
            <g data-name="Group 109411">
              <g data-name="Group 109410">
                <path
                  fill="#263238"
                  d="M65.974 180.408a9.869 9.869 0 0 1 2.137-2.35 9.838 9.838 0 0 1 2.492-1.969 9.842 9.842 0 0 1-2.137 2.35 9.818 9.818 0 0 1-2.492 1.969Z"
                  data-name="Path 99810"
                />
              </g>
            </g>
            <g data-name="Group 109413">
              <g data-name="Group 109412">
                <path
                  fill="#263238"
                  d="M70.749 180.685a11.4 11.4 0 0 1-2.714-2.079 11.4 11.4 0 0 1-2.367-2.468 11.387 11.387 0 0 1 2.714 2.08 11.382 11.382 0 0 1 2.367 2.467Z"
                  data-name="Path 99811"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109419">
            <g data-name="Group 109416">
              <g data-name="Group 109415">
                <path
                  fill="#263238"
                  d="M76.212 179.637a9.848 9.848 0 0 1 2.137-2.35 9.856 9.856 0 0 1 2.492-1.97 9.863 9.863 0 0 1-2.137 2.351 9.865 9.865 0 0 1-2.492 1.969Z"
                  data-name="Path 99812"
                />
              </g>
            </g>
            <g data-name="Group 109418">
              <g data-name="Group 109417">
                <path
                  fill="#263238"
                  d="M80.987 179.913a11.334 11.334 0 0 1-2.714-2.079 11.407 11.407 0 0 1-2.367-2.467 11.334 11.334 0 0 1 2.714 2.079 11.407 11.407 0 0 1 2.367 2.467Z"
                  data-name="Path 99813"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109424">
            <g data-name="Group 109421">
              <g data-name="Group 109420">
                <path
                  fill="#263238"
                  d="M86.45 178.865a9.869 9.869 0 0 1 2.137-2.35 9.838 9.838 0 0 1 2.492-1.969 9.869 9.869 0 0 1-2.137 2.35 9.837 9.837 0 0 1-2.492 1.969Z"
                  data-name="Path 99814"
                />
              </g>
            </g>
            <g data-name="Group 109423">
              <g data-name="Group 109422">
                <path
                  fill="#263238"
                  d="M91.225 179.142a11.4 11.4 0 0 1-2.714-2.079 11.4 11.4 0 0 1-2.367-2.468 11.387 11.387 0 0 1 2.714 2.08 11.381 11.381 0 0 1 2.367 2.467Z"
                  data-name="Path 99815"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109429">
            <g data-name="Group 109426">
              <g data-name="Group 109425">
                <path
                  fill="#263238"
                  d="M96.688 178.094a19.4 19.4 0 0 1 4.629-4.32 9.863 9.863 0 0 1-2.137 2.351 9.865 9.865 0 0 1-2.492 1.969Z"
                  data-name="Path 99816"
                />
              </g>
            </g>
            <g data-name="Group 109428">
              <g data-name="Group 109427">
                <path
                  fill="#263238"
                  d="M101.463 178.37a11.334 11.334 0 0 1-2.714-2.079 11.373 11.373 0 0 1-2.367-2.468 11.388 11.388 0 0 1 2.714 2.08 11.407 11.407 0 0 1 2.367 2.467Z"
                  data-name="Path 99817"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109515">
        <g data-name="Group 109435">
          <g data-name="Group 109432">
            <path
              fill="#fff"
              d="M10.525 229.13s18.429 22.209 20.108 36.757a58.526 58.526 0 0 1-2.357 25.181l61.938-17.751s15.126-29.718-17.751-61.937Z"
              data-name="Path 99818"
            />
          </g>
          <g data-name="Group 109434">
            <g data-name="Group 109433">
              <path
                fill="#ced3dd"
                d="M10.525 229.13s.364-.118 1.1-.336l3.278-.962 12.522-3.646 44.974-13.021.125-.037.093.092a88.458 88.458 0 0 1 10.091 11.729 65.417 65.417 0 0 1 7.866 14.686 52.661 52.661 0 0 1 3.353 17.319 44.425 44.425 0 0 1-3.313 18.121l-.17.36-.047.1-.114.032-61.945 17.719-.415.119.141-.418a55 55 0 0 0 2.648-14.218 59.584 59.584 0 0 0 .047-6.738 39.745 39.745 0 0 0-.7-6.313 47.63 47.63 0 0 0-3.996-10.779c-1.559-3.212-3.248-6.033-4.794-8.561a166.551 166.551 0 0 0-7.787-11.364l-2.2-2.892-.572-.737a5.573 5.573 0 0 1-.185-.255l.214.233.6.714c.521.637 1.284 1.591 2.257 2.853a153.662 153.662 0 0 1 7.9 11.307 98.064 98.064 0 0 1 4.857 8.558 47.824 47.824 0 0 1 4.068 10.847 40.062 40.062 0 0 1 .733 6.37 60.037 60.037 0 0 1-.026 6.782 55.161 55.161 0 0 1-2.64 14.354l-.275-.3 61.929-17.783-.16.132.16-.34a43.991 43.991 0 0 0 3.271-17.9 52.233 52.233 0 0 0-3.307-17.154 65.1 65.1 0 0 0-7.786-14.584 88.4 88.4 0 0 0-10.013-11.687l.217.056-44.876 12.735-12.615 3.557-3.34.936c-.76.21-1.168.314-1.168.314Z"
                data-name="Path 99819"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" data-name="Group 109436">
          <path
            d="M37.594 237.408a4.89 4.89 0 1 1 6.021 2.9 4.59 4.59 0 0 1-6.021-2.9Zm6.918-2.167a2.374 2.374 0 1 0-1.512 3.1 2.35 2.35 0 0 0 1.512-3.1Z"
            data-name="Path 99820"
          />
          <path
            d="M47.715 234.238a4.54 4.54 0 0 1 3.244-5.8 4.646 4.646 0 0 1 4.1.451l-1.081 1.837a2.6 2.6 0 0 0-2.282-.363 2.509 2.509 0 0 0 1.5 4.787 2.6 2.6 0 0 0 1.668-1.6l1.935.891a4.643 4.643 0 0 1-3.107 2.71 4.542 4.542 0 0 1-5.977-2.913Z"
            data-name="Path 99821"
          />
          <path
            d="m57.963 228.492-2.573.806-.589-1.88 7.5-2.351.588 1.88-2.561.8 2.036 6.5-2.37.742Z"
            data-name="Path 99822"
          />
        </g>
        <g data-name="Group 109462">
          <g data-name="Group 109441">
            <g data-name="Group 109438">
              <g data-name="Group 109437">
                <path
                  fill="#263238"
                  d="M38.163 259.08a14.274 14.274 0 0 1 1.429-3.557 14.327 14.327 0 0 1 1.9-3.33 14.209 14.209 0 0 1-1.43 3.557 14.33 14.33 0 0 1-1.899 3.33Z"
                  data-name="Path 99823"
                />
              </g>
            </g>
            <g data-name="Group 109440">
              <g data-name="Group 109439">
                <path
                  fill="#263238"
                  d="M43.712 257.29a16.858 16.858 0 0 1-4-1.188 16.842 16.842 0 0 1-3.818-1.676 16.838 16.838 0 0 1 4 1.187 16.845 16.845 0 0 1 3.818 1.677Z"
                  data-name="Path 99824"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109446">
            <g data-name="Group 109443">
              <g data-name="Group 109442">
                <path
                  fill="#263238"
                  d="M48.656 254.168a19.44 19.44 0 0 1 3-5.6 9.919 9.919 0 0 1-1.269 2.921 9.938 9.938 0 0 1-1.731 2.679Z"
                  data-name="Path 99825"
                />
              </g>
            </g>
            <g data-name="Group 109445">
              <g data-name="Group 109444">
                <path
                  fill="#263238"
                  d="M53.32 252.868a11.679 11.679 0 0 1-3.288-1.077 11.644 11.644 0 0 1-3.09-1.559 11.65 11.65 0 0 1 3.289 1.077 11.647 11.647 0 0 1 3.089 1.559Z"
                  data-name="Path 99826"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109451">
            <g data-name="Group 109448">
              <g data-name="Group 109447">
                <path
                  fill="#263238"
                  d="M59.634 250.306a19.44 19.44 0 0 1 3-5.6 9.9 9.9 0 0 1-1.268 2.921 9.927 9.927 0 0 1-1.732 2.679Z"
                  data-name="Path 99827"
                />
              </g>
            </g>
            <g data-name="Group 109450">
              <g data-name="Group 109449">
                <path
                  fill="#263238"
                  d="M64.299 249.006a11.68 11.68 0 0 1-3.289-1.077 11.647 11.647 0 0 1-3.089-1.559 11.639 11.639 0 0 1 3.288 1.077 11.664 11.664 0 0 1 3.09 1.559Z"
                  data-name="Path 99828"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109456">
            <g data-name="Group 109453">
              <g data-name="Group 109452">
                <path
                  fill="#263238"
                  d="M69.913 246.689a19.451 19.451 0 0 1 3-5.6 19.483 19.483 0 0 1-3 5.6Z"
                  data-name="Path 99829"
                />
              </g>
            </g>
            <g data-name="Group 109455">
              <g data-name="Group 109454">
                <path
                  fill="#263238"
                  d="M74.578 245.39a11.68 11.68 0 0 1-3.289-1.077 11.677 11.677 0 0 1-3.09-1.559 11.651 11.651 0 0 1 3.289 1.077 11.684 11.684 0 0 1 3.09 1.559Z"
                  data-name="Path 99830"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109461">
            <g data-name="Group 109458">
              <g data-name="Group 109457">
                <path
                  fill="#263238"
                  d="M78.314 243.46a18.547 18.547 0 0 1 2.807-5.529 18.643 18.643 0 0 1-2.807 5.529Z"
                  data-name="Path 99831"
                />
              </g>
            </g>
            <g data-name="Group 109460">
              <g data-name="Group 109459">
                <path
                  fill="#263238"
                  d="M82.843 242.093a11.155 11.155 0 0 1-3.238-.978 11.165 11.165 0 0 1-3.049-1.464 11.163 11.163 0 0 1 3.238.978 11.131 11.131 0 0 1 3.049 1.464Z"
                  data-name="Path 99832"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109488">
          <g data-name="Group 109467">
            <g data-name="Group 109464">
              <g data-name="Group 109463">
                <path
                  fill="#263238"
                  d="M41.15 268.28a9.847 9.847 0 0 1 1.192-2.95 9.884 9.884 0 0 1 1.657-2.717 9.868 9.868 0 0 1-1.192 2.951 9.927 9.927 0 0 1-1.657 2.716Z"
                  data-name="Path 99833"
                />
              </g>
            </g>
            <g data-name="Group 109466">
              <g data-name="Group 109465">
                <path
                  fill="#263238"
                  d="M45.779 266.867a11.7 11.7 0 0 1-3.316-1 11.626 11.626 0 0 1-3.128-1.484 11.7 11.7 0 0 1 3.316 1 11.647 11.647 0 0 1 3.128 1.484Z"
                  data-name="Path 99834"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109472">
            <g data-name="Group 109469">
              <g data-name="Group 109468">
                <path
                  fill="#263238"
                  d="M51.519 264.673a19.447 19.447 0 0 1 3.083-5.546 9.855 9.855 0 0 1-1.314 2.9 9.869 9.869 0 0 1-1.769 2.646Z"
                  data-name="Path 99835"
                />
              </g>
            </g>
            <g data-name="Group 109471">
              <g data-name="Group 109470">
                <path
                  fill="#263238"
                  d="M56.204 263.449a11.658 11.658 0 0 1-3.271-1.13 11.678 11.678 0 0 1-3.065-1.609 11.654 11.654 0 0 1 3.271 1.131 11.659 11.659 0 0 1 3.065 1.608Z"
                  data-name="Path 99836"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109477">
            <g data-name="Group 109474">
              <g data-name="Group 109473">
                <path
                  fill="#263238"
                  d="M62.232 261.116a19.483 19.483 0 0 1 3.083-5.546 9.866 9.866 0 0 1-1.314 2.9 9.873 9.873 0 0 1-1.769 2.646Z"
                  data-name="Path 99837"
                />
              </g>
            </g>
            <g data-name="Group 109476">
              <g data-name="Group 109475">
                <path
                  fill="#263238"
                  d="M66.917 259.893a11.683 11.683 0 0 1-3.272-1.131 11.661 11.661 0 0 1-3.064-1.609 11.654 11.654 0 0 1 3.271 1.131 11.665 11.665 0 0 1 3.065 1.609Z"
                  data-name="Path 99838"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109482">
            <g data-name="Group 109479">
              <g data-name="Group 109478">
                <path
                  fill="#263238"
                  d="M71.843 257.196a19.446 19.446 0 0 1 3.083-5.546 19.483 19.483 0 0 1-3.083 5.546Z"
                  data-name="Path 99839"
                />
              </g>
            </g>
            <g data-name="Group 109481">
              <g data-name="Group 109480">
                <path
                  fill="#263238"
                  d="M76.527 255.972a11.66 11.66 0 0 1-3.271-1.13 11.676 11.676 0 0 1-3.065-1.609 11.656 11.656 0 0 1 3.272 1.131 11.662 11.662 0 0 1 3.064 1.608Z"
                  data-name="Path 99840"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109487">
            <g data-name="Group 109484">
              <g data-name="Group 109483">
                <path
                  fill="#263238"
                  d="M80.894 253.769a19.342 19.342 0 0 1 2.4-5.868 19.505 19.505 0 0 1-2.4 5.868Z"
                  data-name="Path 99841"
                />
              </g>
            </g>
            <g data-name="Group 109486">
              <g data-name="Group 109485">
                <path
                  fill="#263238"
                  d="M85.398 252.008a11.65 11.65 0 0 1-3.384-.742 11.68 11.68 0 0 1-3.234-1.242 11.679 11.679 0 0 1 3.384.743 11.733 11.733 0 0 1 3.234 1.241Z"
                  data-name="Path 99842"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109514">
          <g data-name="Group 109493">
            <g data-name="Group 109490">
              <g data-name="Group 109489">
                <path
                  fill="#263238"
                  d="M44.178 277.805a19.469 19.469 0 0 1 3.071-5.55 9.9 9.9 0 0 1-1.308 2.9 9.84 9.84 0 0 1-1.763 2.65Z"
                  data-name="Path 99843"
                />
              </g>
            </g>
            <g data-name="Group 109492">
              <g data-name="Group 109491">
                <path
                  fill="#263238"
                  d="M48.863 276.58a11.652 11.652 0 0 1-3.273-1.127 11.666 11.666 0 0 1-3.068-1.606 11.674 11.674 0 0 1 3.274 1.128 11.649 11.649 0 0 1 3.067 1.605Z"
                  data-name="Path 99844"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109498">
            <g data-name="Group 109495">
              <g data-name="Group 109494">
                <path
                  fill="#263238"
                  d="M53.412 274.536a9.884 9.884 0 0 1 1.427-2.846 9.88 9.88 0 0 1 1.872-2.575 9.884 9.884 0 0 1-1.427 2.846 9.88 9.88 0 0 1-1.872 2.575Z"
                  data-name="Path 99845"
                />
              </g>
            </g>
            <g data-name="Group 109497">
              <g data-name="Group 109496">
                <path
                  fill="#263238"
                  d="M58.141 273.497a11.611 11.611 0 0 1-3.224-1.258 11.669 11.669 0 0 1-3-1.728 11.659 11.659 0 0 1 3.224 1.259 11.694 11.694 0 0 1 3 1.727Z"
                  data-name="Path 99846"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109503">
            <g data-name="Group 109500">
              <g data-name="Group 109499">
                <path
                  fill="#263238"
                  d="M63.169 270.996a19.467 19.467 0 0 1 3.3-5.42 9.9 9.9 0 0 1-1.427 2.846 9.885 9.885 0 0 1-1.873 2.574Z"
                  data-name="Path 99847"
                />
              </g>
            </g>
            <g data-name="Group 109502">
              <g data-name="Group 109501">
                <path
                  fill="#263238"
                  d="M67.898 269.958a11.662 11.662 0 0 1-3.224-1.258 11.62 11.62 0 0 1-3-1.729 11.7 11.7 0 0 1 3.224 1.259 11.713 11.713 0 0 1 3 1.728Z"
                  data-name="Path 99848"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109508">
            <g data-name="Group 109505">
              <g data-name="Group 109504">
                <path
                  fill="#263238"
                  d="M72.926 267.457a19.429 19.429 0 0 1 3.3-5.42 19.466 19.466 0 0 1-3.3 5.42Z"
                  data-name="Path 99849"
                />
              </g>
            </g>
            <g data-name="Group 109507">
              <g data-name="Group 109506">
                <path
                  fill="#263238"
                  d="M77.655 266.419a11.647 11.647 0 0 1-3.224-1.259 11.656 11.656 0 0 1-3-1.728 11.659 11.659 0 0 1 3.224 1.259 11.623 11.623 0 0 1 3 1.728Z"
                  data-name="Path 99850"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109513">
            <g data-name="Group 109510">
              <g data-name="Group 109509">
                <path
                  fill="#263238"
                  d="M82.684 263.918a19.419 19.419 0 0 1 3.3-5.421 19.481 19.481 0 0 1-3.3 5.421Z"
                  data-name="Path 99851"
                />
              </g>
            </g>
            <g data-name="Group 109512">
              <g data-name="Group 109511">
                <path
                  fill="#263238"
                  d="M87.412 262.88a11.623 11.623 0 0 1-3.224-1.258 11.69 11.69 0 0 1-3-1.728 11.625 11.625 0 0 1 3.224 1.258 11.691 11.691 0 0 1 3 1.728Z"
                  data-name="Path 99852"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109599">
        <g data-name="Group 109519">
          <g data-name="Group 109516">
            <path
              fill="#fff"
              d="M88.636 315.611H24.205s.137.955.466 2.6c2.039 10.219 11.427 47.083 40.824 47.565 34.131.559 62.108 0 62.108 0s-41.004-20.317-38.967-50.165Z"
              data-name="Path 99853"
            />
          </g>
          <g data-name="Group 109518">
            <g data-name="Group 109517">
              <path
                fill="#ced3dd"
                d="M88.636 315.611a2.5 2.5 0 0 1 .007.3v.916l-.017 1.521.144 2.07a35.233 35.233 0 0 0 1.007 5.67 40.366 40.366 0 0 0 2.8 7.263 60.5 60.5 0 0 0 12.87 16.541 97.8 97.8 0 0 0 10.049 8.164 102.506 102.506 0 0 0 12.207 7.524l.8.412-.892.017c-9.479.177-19.795.266-30.717.272l-16.8-.063-8.685-.089c-2.924-.066-5.866-.011-8.861-.268a32.053 32.053 0 0 1-16.785-6.923 48.01 48.01 0 0 1-11.266-13.358 80.606 80.606 0 0 1-6.758-15.064 94.926 94.926 0 0 1-3.755-14.873l-.042-.256h.263l46.805.116 13.05.058 3.421.025.87.012a2.394 2.394 0 0 1 .285.012s-.109.008-.311.012l-.895.013-3.465.025-13.1.058-46.658.116.221-.255a94.922 94.922 0 0 0 3.766 14.782 80.326 80.326 0 0 0 6.737 14.963 47.579 47.579 0 0 0 11.146 13.2 31.554 31.554 0 0 0 16.518 6.808c2.93.254 5.9.2 8.815.267l8.683.093 16.8.076a1820.66 1820.66 0 0 0 30.708-.222l-.1.429a102.359 102.359 0 0 1-12.251-7.586 97.265 97.265 0 0 1-10.062-8.232 60.341 60.341 0 0 1-12.854-16.693 40.01 40.01 0 0 1-2.759-7.328 34.29 34.29 0 0 1-.949-5.712l-.111-2.078.058-1.495.041-.923c.01-.223.022-.317.022-.317Z"
                data-name="Path 99854"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109520">
          <path
            d="m45.195 333.9.816-1.831a5.535 5.535 0 0 0 2.9.865c1.028 0 1.43-.289 1.43-.714 0-1.393-4.992-.377-4.992-3.638 0-1.568 1.279-2.847 3.888-2.847a6.573 6.573 0 0 1 3.186.765l-.765 1.844a5.185 5.185 0 0 0-2.433-.678c-1.041 0-1.418.351-1.418.791 0 1.342 4.98.338 4.98 3.574 0 1.53-1.28 2.835-3.889 2.835a6.916 6.916 0 0 1-3.703-.966Z"
            data-name="Path 99855"
          />
          <path
            d="M60.971 332.771v1.919h-7.05v-8.78h6.887v1.919H56.38v1.48h3.9v1.857h-3.9v1.6Z"
            data-name="Path 99856"
          />
          <path
            d="M70.263 329.159c0 1.994-1.506 3.236-3.9 3.236h-1.534v2.295h-2.483v-8.78h4.017c2.392 0 3.9 1.242 3.9 3.249Zm-2.509 0c0-.815-.514-1.292-1.543-1.292h-1.38v2.571h1.38c1.027 0 1.541-.476 1.541-1.279Z"
            data-name="Path 99857"
          />
          <path
            d="M73.116 327.88h-2.7v-1.97h7.865v1.97H75.6v6.81h-2.484Z"
            data-name="Path 99858"
          />
        </g>
        <g data-name="Group 109546">
          <g data-name="Group 109525">
            <g data-name="Group 109522">
              <g data-name="Group 109521">
                <path
                  fill="#263238"
                  d="M43.502 345.513a14.3 14.3 0 0 1 2.528-2.881 14.265 14.265 0 0 1 2.9-2.512 14.222 14.222 0 0 1-2.528 2.881 14.244 14.244 0 0 1-2.9 2.512Z"
                  data-name="Path 99859"
                />
              </g>
            </g>
            <g data-name="Group 109524">
              <g data-name="Group 109523">
                <path
                  fill="#263238"
                  d="M49.336 345.663a16.876 16.876 0 0 1-3.377-2.446 16.884 16.884 0 0 1-3.046-2.847 16.841 16.841 0 0 1 3.377 2.446 16.8 16.8 0 0 1 3.046 2.847Z"
                  data-name="Path 99860"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109530">
            <g data-name="Group 109527">
              <g data-name="Group 109526">
                <path
                  fill="#263238"
                  d="M55.035 344.356a9.906 9.906 0 0 1 2.165-2.335 9.9 9.9 0 0 1 2.517-1.951 9.876 9.876 0 0 1-2.166 2.335 9.878 9.878 0 0 1-2.516 1.951Z"
                  data-name="Path 99861"
                />
              </g>
            </g>
            <g data-name="Group 109529">
              <g data-name="Group 109528">
                <path
                  fill="#263238"
                  d="M59.863 344.68a11.669 11.669 0 0 1-2.746-2.107 11.675 11.675 0 0 1-2.4-2.495 11.669 11.669 0 0 1 2.746 2.107 11.622 11.622 0 0 1 2.4 2.495Z"
                  data-name="Path 99862"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109535">
            <g data-name="Group 109532">
              <g data-name="Group 109531">
                <path
                  fill="#263238"
                  d="M66.673 344.352a9.891 9.891 0 0 1 2.165-2.334 9.844 9.844 0 0 1 2.517-1.951 9.9 9.9 0 0 1-2.166 2.335 9.887 9.887 0 0 1-2.516 1.95Z"
                  data-name="Path 99863"
                />
              </g>
            </g>
            <g data-name="Group 109534">
              <g data-name="Group 109533">
                <path
                  fill="#263238"
                  d="M71.505 344.673a11.669 11.669 0 0 1-2.746-2.107 11.675 11.675 0 0 1-2.4-2.495 11.669 11.669 0 0 1 2.746 2.107 11.675 11.675 0 0 1 2.4 2.495Z"
                  data-name="Path 99864"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109540">
            <g data-name="Group 109537">
              <g data-name="Group 109536">
                <path
                  fill="#263238"
                  d="M77.569 344.349a9.885 9.885 0 0 1 2.165-2.335 9.891 9.891 0 0 1 2.517-1.951 9.906 9.906 0 0 1-2.165 2.335 9.918 9.918 0 0 1-2.517 1.951Z"
                  data-name="Path 99865"
                />
              </g>
            </g>
            <g data-name="Group 109539">
              <g data-name="Group 109538">
                <path
                  fill="#263238"
                  d="M82.401 344.669a11.654 11.654 0 0 1-2.745-2.107 11.639 11.639 0 0 1-2.4-2.495 11.669 11.669 0 0 1 2.746 2.107 11.675 11.675 0 0 1 2.399 2.495Z"
                  data-name="Path 99866"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109545">
            <g data-name="Group 109542">
              <g data-name="Group 109541">
                <path
                  fill="#263238"
                  d="M86.563 344.087a9.43 9.43 0 0 1 2.06-2.331 9.442 9.442 0 0 1 2.421-1.955 9.448 9.448 0 0 1-2.061 2.331 9.447 9.447 0 0 1-2.42 1.955Z"
                  data-name="Path 99867"
                />
              </g>
            </g>
            <g data-name="Group 109544">
              <g data-name="Group 109543">
                <path
                  fill="#263238"
                  d="M91.292 344.299a11.144 11.144 0 0 1-2.73-2 11.109 11.109 0 0 1-2.392-2.392 11.165 11.165 0 0 1 2.73 2 11.163 11.163 0 0 1 2.392 2.392Z"
                  data-name="Path 99868"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109572">
          <g data-name="Group 109551">
            <g data-name="Group 109548">
              <g data-name="Group 109547">
                <path
                  fill="#263238"
                  d="M48.146 352.37a9.869 9.869 0 0 1 2.1-2.388 9.858 9.858 0 0 1 2.465-2.013 9.91 9.91 0 0 1-2.1 2.388 9.893 9.893 0 0 1-2.465 2.013Z"
                  data-name="Path 99869"
                />
              </g>
            </g>
            <g data-name="Group 109550">
              <g data-name="Group 109549">
                <path
                  fill="#263238"
                  d="M52.981 352.573a11.672 11.672 0 0 1-2.8-2.041 11.651 11.651 0 0 1-2.46-2.437 11.689 11.689 0 0 1 2.8 2.041 11.661 11.661 0 0 1 2.46 2.437Z"
                  data-name="Path 99870"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109556">
            <g data-name="Group 109553">
              <g data-name="Group 109552">
                <path
                  fill="#263238"
                  d="M59.124 352.406a9.923 9.923 0 0 1 2.2-2.3 9.878 9.878 0 0 1 2.547-1.91 9.9 9.9 0 0 1-2.2 2.3 9.851 9.851 0 0 1-2.547 1.91Z"
                  data-name="Path 99871"
                />
              </g>
            </g>
            <g data-name="Group 109555">
              <g data-name="Group 109554">
                <path
                  fill="#263238"
                  d="M63.949 352.805a11.689 11.689 0 0 1-2.711-2.151 11.669 11.669 0 0 1-2.358-2.534 11.632 11.632 0 0 1 2.711 2.151 11.668 11.668 0 0 1 2.358 2.534Z"
                  data-name="Path 99872"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109561">
            <g data-name="Group 109558">
              <g data-name="Group 109557">
                <path
                  fill="#263238"
                  d="M70.41 352.603a9.88 9.88 0 0 1 2.2-2.3 9.9 9.9 0 0 1 2.547-1.911 9.9 9.9 0 0 1-2.2 2.3 9.9 9.9 0 0 1-2.547 1.911Z"
                  data-name="Path 99873"
                />
              </g>
            </g>
            <g data-name="Group 109560">
              <g data-name="Group 109559">
                <path
                  fill="#263238"
                  d="M75.235 353.001a11.655 11.655 0 0 1-2.711-2.151 11.643 11.643 0 0 1-2.358-2.534 11.671 11.671 0 0 1 2.712 2.152 11.661 11.661 0 0 1 2.357 2.533Z"
                  data-name="Path 99874"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109566">
            <g data-name="Group 109563">
              <g data-name="Group 109562">
                <path
                  fill="#263238"
                  d="M80.777 352.09a9.923 9.923 0 0 1 2.2-2.3 9.874 9.874 0 0 1 2.546-1.91 9.871 9.871 0 0 1-2.2 2.3 9.884 9.884 0 0 1-2.546 1.91Z"
                  data-name="Path 99875"
                />
              </g>
            </g>
            <g data-name="Group 109565">
              <g data-name="Group 109564">
                <path
                  fill="#263238"
                  d="M85.602 352.489a11.675 11.675 0 0 1-2.711-2.151 11.669 11.669 0 0 1-2.358-2.534 11.655 11.655 0 0 1 2.711 2.151 11.643 11.643 0 0 1 2.358 2.534Z"
                  data-name="Path 99876"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109571">
            <g data-name="Group 109568">
              <g data-name="Group 109567">
                <path
                  fill="#263238"
                  d="M90.452 351.858a19.376 19.376 0 0 1 4.208-4.741 9.89 9.89 0 0 1-1.909 2.543 9.883 9.883 0 0 1-2.299 2.198Z"
                  data-name="Path 99877"
                />
              </g>
            </g>
            <g data-name="Group 109570">
              <g data-name="Group 109569">
                <path
                  fill="#263238"
                  d="M95.285 351.69a11.659 11.659 0 0 1-2.946-1.822 11.682 11.682 0 0 1-2.639-2.244 11.7 11.7 0 0 1 2.946 1.823 11.664 11.664 0 0 1 2.639 2.243Z"
                  data-name="Path 99878"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109598">
          <g data-name="Group 109577">
            <g data-name="Group 109574">
              <g data-name="Group 109573">
                <path
                  fill="#263238"
                  d="M58.591 360.062a9.857 9.857 0 0 1 2.195-2.3 9.9 9.9 0 0 1 2.542-1.915 9.857 9.857 0 0 1-2.195 2.3 9.887 9.887 0 0 1-2.542 1.915Z"
                  data-name="Path 99879"
                />
              </g>
            </g>
            <g data-name="Group 109576">
              <g data-name="Group 109575">
                <path
                  fill="#263238"
                  d="M63.415 360.454a11.65 11.65 0 0 1-2.715-2.148 11.716 11.716 0 0 1-2.362-2.532 11.638 11.638 0 0 1 2.715 2.149 11.7 11.7 0 0 1 2.362 2.531Z"
                  data-name="Path 99880"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109582">
            <g data-name="Group 109579">
              <g data-name="Group 109578">
                <path
                  fill="#263238"
                  d="M68.386 360.039a9.891 9.891 0 0 1 2.29-2.212 9.917 9.917 0 0 1 2.62-1.809 9.867 9.867 0 0 1-2.29 2.212 9.916 9.916 0 0 1-2.62 1.809Z"
                  data-name="Path 99881"
                />
              </g>
            </g>
            <g data-name="Group 109581">
              <g data-name="Group 109580">
                <path
                  fill="#263238"
                  d="M73.192 360.627a11.666 11.666 0 0 1-2.625-2.256 11.658 11.658 0 0 1-2.256-2.625 11.648 11.648 0 0 1 2.625 2.257 11.682 11.682 0 0 1 2.256 2.624Z"
                  data-name="Path 99882"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109587">
            <g data-name="Group 109584">
              <g data-name="Group 109583">
                <path
                  fill="#263238"
                  d="M78.763 359.934a9.9 9.9 0 0 1 2.29-2.211 9.891 9.891 0 0 1 2.619-1.809 9.869 9.869 0 0 1-2.289 2.212 9.867 9.867 0 0 1-2.62 1.808Z"
                  data-name="Path 99883"
                />
              </g>
            </g>
            <g data-name="Group 109586">
              <g data-name="Group 109585">
                <path
                  fill="#263238"
                  d="M83.571 360.523a11.687 11.687 0 0 1-2.625-2.257 11.666 11.666 0 0 1-2.256-2.624 11.667 11.667 0 0 1 2.624 2.256 11.688 11.688 0 0 1 2.257 2.625Z"
                  data-name="Path 99884"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109592">
            <g data-name="Group 109589">
              <g data-name="Group 109588">
                <path
                  fill="#263238"
                  d="M89.144 359.83a9.906 9.906 0 0 1 2.289-2.211 9.888 9.888 0 0 1 2.62-1.809 9.913 9.913 0 0 1-2.29 2.212 9.854 9.854 0 0 1-2.619 1.808Z"
                  data-name="Path 99885"
                />
              </g>
            </g>
            <g data-name="Group 109591">
              <g data-name="Group 109590">
                <path
                  fill="#263238"
                  d="M93.949 360.418a11.641 11.641 0 0 1-2.624-2.256 11.688 11.688 0 0 1-2.257-2.625 11.687 11.687 0 0 1 2.625 2.257 11.666 11.666 0 0 1 2.256 2.624Z"
                  data-name="Path 99886"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109597">
            <g data-name="Group 109594">
              <g data-name="Group 109593">
                <path
                  fill="#263238"
                  d="M99.523 359.726a19.4 19.4 0 0 1 4.909-4.02 9.9 9.9 0 0 1-2.29 2.211 9.9 9.9 0 0 1-2.619 1.809Z"
                  data-name="Path 99887"
                />
              </g>
            </g>
            <g data-name="Group 109596">
              <g data-name="Group 109595">
                <path
                  fill="#263238"
                  d="M104.328 360.314a23.02 23.02 0 0 1-4.881-4.881 11.656 11.656 0 0 1 2.625 2.257 11.642 11.642 0 0 1 2.256 2.624Z"
                  data-name="Path 99888"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109683">
        <g data-name="Group 109603">
          <g data-name="Group 109600">
            <path
              fill="#fff"
              d="M235.288 385.88s27.155 30.852 30.151 56.941l56.941-30.151s-9.283-38.011-30.151-56.941Z"
              data-name="Path 99889"
            />
          </g>
          <g data-name="Group 109602">
            <g data-name="Group 109601">
              <path
                fill="#ced3dd"
                d="M235.288 385.88a2.611 2.611 0 0 1 .246-.143l.764-.417 3.015-1.623 11.512-6.161 41.3-22.006.141-.075.115.107c6.977 6.477 13.016 15.076 17.98 24.847a163.831 163.831 0 0 1 12.273 32.2c-.264.588-.082.168-.145.3l-.015.008-.031.016-.061.032-.122.065-.244.129-.487.258-.974.516-1.942 1.028-3.859 2.043-7.609 4.027-14.718 7.786-26.882 14.2-.271.143-.058-.317a61.25 61.25 0 0 0-1.374-7.007c-.312-1.123-.556-2.252-.913-3.333q-.56-1.615-1.1-3.19c-.187-.523-.349-1.052-.553-1.561s-.421-1.01-.63-1.509l-1.234-2.957q-1.334-2.863-2.658-5.532a162.547 162.547 0 0 0-10.642-17.574 195.135 195.135 0 0 0-7.882-10.643l-2.191-2.718-.57-.7a6.01 6.01 0 0 1-.181-.236s.08.079.214.222l.591.666c.521.6 1.279 1.491 2.245 2.677 1.934 2.371 4.717 5.9 7.991 10.579a159.309 159.309 0 0 1 10.749 17.565q1.342 2.664 2.687 5.54l1.25 2.965.637 1.514c.207.511.372 1.042.561 1.567l1.119 3.2c.362 1.086.612 2.222.929 3.352a61.212 61.212 0 0 1 1.4 7.064l-.33-.174 26.858-14.251 14.711-7.8 7.608-4.03 3.858-2.044 1.942-1.028.974-.516.488-.258.244-.129.122-.065.061-.032.03-.016.015-.008c-.054.127.143-.3-.117.283a164.217 164.217 0 0 0-12.214-32.093c-4.935-9.741-10.93-18.3-17.835-24.743l.256.032-41.269 21.72-11.618 6.087-3.079 1.6-.8.41c-.178.096-.278.141-.278.141Z"
                data-name="Path 99890"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109604">
          <path
            d="m266.292 390.73.433-2.055a1.942 1.942 0 0 0 1.77.088c.63-.339.765-.91.366-1.65l-1.787-3.325-2.674 1.437-.908-1.691 4.839-2.6 2.625 4.883c1.081 2.011.577 3.564-1.367 4.61a3.807 3.807 0 0 1-3.297.303Z"
            data-name="Path 99891"
          />
          <path
            d="m272.047 384.462-2.3-4.287 2.188-1.176 2.268 4.221c.712 1.325 1.55 1.558 2.467 1.065s1.173-1.315.461-2.64l-2.269-4.22 2.155-1.159 2.3 4.288c1.241 2.308.588 4.326-1.7 5.555s-4.33.661-5.57-1.647Z"
            data-name="Path 99892"
          />
          <path
            d="m278.487 375.473 2.188-1.176 3.225 6 3.69-1.984.931 1.735-5.877 3.16Z"
            data-name="Path 99893"
          />
        </g>
        <g data-name="Group 109630">
          <g data-name="Group 109609">
            <g data-name="Group 109606">
              <g data-name="Group 109605">
                <path
                  fill="#263238"
                  d="M263.043 409.471a28.169 28.169 0 0 1 2.278-7.3 28.272 28.272 0 0 1-2.278 7.3Z"
                  data-name="Path 99894"
                />
              </g>
            </g>
            <g data-name="Group 109608">
              <g data-name="Group 109607">
                <path
                  fill="#263238"
                  d="M268.272 406.88a33.328 33.328 0 0 1-8.151-1.683 33.45 33.45 0 0 1 8.151 1.683Z"
                  data-name="Path 99895"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109614">
            <g data-name="Group 109611">
              <g data-name="Group 109610">
                <path
                  fill="#263238"
                  d="M272.702 403.064a19.447 19.447 0 0 1 2.139-5.975 19.423 19.423 0 0 1-2.139 5.975Z"
                  data-name="Path 99896"
                />
              </g>
            </g>
            <g data-name="Group 109613">
              <g data-name="Group 109612">
                <path
                  fill="#263238"
                  d="M277.124 401.092a22.96 22.96 0 0 1-6.7-1.668 22.96 22.96 0 0 1 6.7 1.668Z"
                  data-name="Path 99897"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109619">
            <g data-name="Group 109616">
              <g data-name="Group 109615">
                <path
                  fill="#263238"
                  d="M282.992 397.629a19.476 19.476 0 0 1 2.14-5.976 19.476 19.476 0 0 1-2.14 5.976Z"
                  data-name="Path 99898"
                />
              </g>
            </g>
            <g data-name="Group 109618">
              <g data-name="Group 109617">
                <path
                  fill="#263238"
                  d="M287.415 395.657a23.021 23.021 0 0 1-6.7-1.669 23 23 0 0 1 6.7 1.669Z"
                  data-name="Path 99899"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109624">
            <g data-name="Group 109621">
              <g data-name="Group 109620">
                <path
                  fill="#263238"
                  d="M292.627 392.539a19.413 19.413 0 0 1 2.14-5.975 19.485 19.485 0 0 1-2.14 5.975Z"
                  data-name="Path 99900"
                />
              </g>
            </g>
            <g data-name="Group 109623">
              <g data-name="Group 109622">
                <path
                  fill="#263238"
                  d="M297.049 390.567a22.96 22.96 0 0 1-6.7-1.668 22.96 22.96 0 0 1 6.7 1.668Z"
                  data-name="Path 99901"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109629">
            <g data-name="Group 109626">
              <g data-name="Group 109625">
                <path
                  fill="#263238"
                  d="M300.463 388.108a18.58 18.58 0 0 1 1.962-5.881 18.58 18.58 0 0 1-1.962 5.881Z"
                  data-name="Path 99902"
                />
              </g>
            </g>
            <g data-name="Group 109628">
              <g data-name="Group 109627">
                <path
                  fill="#263238"
                  d="M304.739 386.09a21.974 21.974 0 0 1-6.578-1.49 21.956 21.956 0 0 1 6.578 1.49Z"
                  data-name="Path 99903"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109656">
          <g data-name="Group 109635">
            <g data-name="Group 109632">
              <g data-name="Group 109631">
                <path
                  fill="#263238"
                  d="M267.357 416.619a19.467 19.467 0 0 1 2.155-5.965 19.468 19.468 0 0 1-2.155 5.965Z"
                  data-name="Path 99904"
                />
              </g>
            </g>
            <g data-name="Group 109634">
              <g data-name="Group 109633">
                <path
                  fill="#263238"
                  d="M271.785 414.666a23.02 23.02 0 0 1-6.693-1.7 23.082 23.082 0 0 1 6.693 1.7Z"
                  data-name="Path 99905"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109640">
            <g data-name="Group 109637">
              <g data-name="Group 109636">
                <path
                  fill="#263238"
                  d="M277.224 411.805a19.425 19.425 0 0 1 2.4-5.873 19.423 19.423 0 0 1-2.4 5.873Z"
                  data-name="Path 99906"
                />
              </g>
            </g>
            <g data-name="Group 109639">
              <g data-name="Group 109638">
                <path
                  fill="#263238"
                  d="M281.729 410.033a23.054 23.054 0 0 1-6.616-1.966 22.969 22.969 0 0 1 6.616 1.966Z"
                  data-name="Path 99907"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109645">
            <g data-name="Group 109642">
              <g data-name="Group 109641">
                <path
                  fill="#263238"
                  d="M287.438 406.999a19.425 19.425 0 0 1 2.4-5.873 19.492 19.492 0 0 1-2.4 5.873Z"
                  data-name="Path 99908"
                />
              </g>
            </g>
            <g data-name="Group 109644">
              <g data-name="Group 109643">
                <path
                  fill="#263238"
                  d="M291.943 405.227a22.988 22.988 0 0 1-6.617-1.966 22.963 22.963 0 0 1 6.617 1.966Z"
                  data-name="Path 99909"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109650">
            <g data-name="Group 109647">
              <g data-name="Group 109646">
                <path
                  fill="#263238"
                  d="M296.513 401.963a19.46 19.46 0 0 1 2.4-5.873 19.462 19.462 0 0 1-2.4 5.873Z"
                  data-name="Path 99910"
                />
              </g>
            </g>
            <g data-name="Group 109649">
              <g data-name="Group 109648">
                <path
                  fill="#263238"
                  d="M301.019 400.191a22.963 22.963 0 0 1-6.617-1.966 22.963 22.963 0 0 1 6.617 1.966Z"
                  data-name="Path 99911"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109655">
            <g data-name="Group 109652">
              <g data-name="Group 109651">
                <path
                  fill="#263238"
                  d="M305.092 397.48a19.394 19.394 0 0 1 1.683-6.112 19.394 19.394 0 0 1-1.683 6.112Z"
                  data-name="Path 99912"
                />
              </g>
            </g>
            <g data-name="Group 109654">
              <g data-name="Group 109653">
                <path
                  fill="#263238"
                  d="M309.355 395.2a23.049 23.049 0 0 1-6.807-1.183 23.052 23.052 0 0 1 6.807 1.183Z"
                  data-name="Path 99913"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109682">
          <g data-name="Group 109661">
            <g data-name="Group 109658">
              <g data-name="Group 109657">
                <path
                  fill="#263238"
                  d="M272.995 425.75a19.427 19.427 0 0 1 2.172-5.96 19.4 19.4 0 0 1-2.172 5.96Z"
                  data-name="Path 99914"
                />
              </g>
            </g>
            <g data-name="Group 109660">
              <g data-name="Group 109659">
                <path
                  fill="#263238"
                  d="M277.429 423.809a23 23 0 0 1-6.689-1.715 23.086 23.086 0 0 1 6.689 1.715Z"
                  data-name="Path 99915"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109666">
            <g data-name="Group 109663">
              <g data-name="Group 109662">
                <path
                  fill="#263238"
                  d="M281.609 421.088a19.435 19.435 0 0 1 2.418-5.867 19.465 19.465 0 0 1-2.418 5.867Z"
                  data-name="Path 99916"
                />
              </g>
            </g>
            <g data-name="Group 109665">
              <g data-name="Group 109664">
                <path
                  fill="#263238"
                  d="M286.12 419.328a22.977 22.977 0 0 1-6.611-1.985 23 23 0 0 1 6.611 1.985Z"
                  data-name="Path 99917"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109671">
            <g data-name="Group 109668">
              <g data-name="Group 109667">
                <path
                  fill="#263238"
                  d="M290.699 416.08a19.435 19.435 0 0 1 2.418-5.867 19.437 19.437 0 0 1-2.418 5.867Z"
                  data-name="Path 99918"
                />
              </g>
            </g>
            <g data-name="Group 109670">
              <g data-name="Group 109669">
                <path
                  fill="#263238"
                  d="M295.21 414.317a23 23 0 0 1-6.612-1.985 23.081 23.081 0 0 1 6.612 1.985Z"
                  data-name="Path 99919"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109676">
            <g data-name="Group 109673">
              <g data-name="Group 109672">
                <path
                  fill="#263238"
                  d="M299.789 411.066a19.463 19.463 0 0 1 2.418-5.867 19.5 19.5 0 0 1-2.418 5.867Z"
                  data-name="Path 99920"
                />
              </g>
            </g>
            <g data-name="Group 109675">
              <g data-name="Group 109674">
                <path
                  fill="#263238"
                  d="M304.299 409.307a23 23 0 0 1-6.611-1.985 22.977 22.977 0 0 1 6.611 1.985Z"
                  data-name="Path 99921"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109681">
            <g data-name="Group 109678">
              <g data-name="Group 109677">
                <path
                  fill="#263238"
                  d="M308.879 406.056a19.469 19.469 0 0 1 2.417-5.867 19.467 19.467 0 0 1-2.417 5.867Z"
                  data-name="Path 99922"
                />
              </g>
            </g>
            <g data-name="Group 109680">
              <g data-name="Group 109679">
                <path
                  fill="#263238"
                  d="M313.389 404.296a23 23 0 0 1-6.611-1.985 23 23 0 0 1 6.611 1.985Z"
                  data-name="Path 99923"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109753">
        <g data-name="Group 109687">
          <g data-name="Group 109684">
            <path
              fill="#fff"
              d="M98.342 408.83s15.335-.347 38.4-9.419c26-10.227 38.66 11.8 38.66 11.8L213.98 384s-21.457-19.582-48.139-15.82c-18.678 2.638-35.268 16.84-35.268 16.84Z"
              data-name="Path 99924"
            />
          </g>
          <g data-name="Group 109686">
            <g data-name="Group 109685">
              <path
                fill="#ced3dd"
                d="m98.342 408.83 32.12-23.961-.011.008a91.788 91.788 0 0 1 16.094-10.486 75.124 75.124 0 0 1 9.847-4.153 48.435 48.435 0 0 1 11.047-2.44 54.293 54.293 0 0 1 23.924 2.982 72.373 72.373 0 0 1 22.523 12.8l.272.24.244.216-.27.19-38.588 27.2-.207.147-.143-.224a32.317 32.317 0 0 0-12.9-11.6 28.352 28.352 0 0 0-15.557-2.64 36.612 36.612 0 0 0-7.175 1.52c-2.246.745-4.394 1.589-6.473 2.364-4.175 1.5-8.071 2.759-11.643 3.754a125.8 125.8 0 0 1-16.933 3.569c-2 .258-3.538.4-4.581.47-.523.029-.919.052-1.186.056a3.573 3.573 0 0 1-.4 0l.4-.038 1.181-.1c1.04-.1 2.578-.264 4.567-.546a131.487 131.487 0 0 0 16.873-3.7c3.559-1.013 7.442-2.287 11.6-3.8 2.069-.781 4.207-1.631 6.48-2.4a36.878 36.878 0 0 1 7.248-1.56 28.734 28.734 0 0 1 15.8 2.64 31.143 31.143 0 0 1 7.222 4.857 32.3 32.3 0 0 1 5.9 6.9l-.35-.077 38.567-27.226-.025.4-.263-.232a72 72 0 0 0-22.349-12.707 53.884 53.884 0 0 0-23.7-2.981 48.111 48.111 0 0 0-10.953 2.4 74.76 74.76 0 0 0-9.795 4.1 91.773 91.773 0 0 0-16.047 10.382l-.009.006Z"
                data-name="Path 99925"
              />
            </g>
          </g>
        </g>
        <g data-name="Group 109710">
          <g data-name="Group 109698">
            <g data-name="Group 109692">
              <g data-name="Group 109689">
                <g data-name="Group 109688">
                  <path
                    fill="#263238"
                    d="M158.435 393.874a19.5 19.5 0 0 1 .572-6.322 19.5 19.5 0 0 1-.572 6.322Z"
                    data-name="Path 99926"
                  />
                </g>
              </g>
              <g data-name="Group 109691">
                <g data-name="Group 109690">
                  <path
                    fill="#263238"
                    d="M162.221 390.855a22.983 22.983 0 0 1-6.9.065 22.983 22.983 0 0 1 6.9-.065Z"
                    data-name="Path 99927"
                  />
                </g>
              </g>
            </g>
            <g data-name="Group 109697">
              <g data-name="Group 109694">
                <g data-name="Group 109693">
                  <path
                    fill="#263238"
                    d="M164.906 387.618a18.57 18.57 0 0 1 .424-6.186 18.57 18.57 0 0 1-.424 6.186Z"
                    data-name="Path 99928"
                  />
                </g>
              </g>
              <g data-name="Group 109696">
                <g data-name="Group 109695">
                  <path
                    fill="#263238"
                    d="M168.541 384.59a21.943 21.943 0 0 1-6.741.209 21.948 21.948 0 0 1 6.741-.209Z"
                    data-name="Path 99929"
                  />
                </g>
              </g>
            </g>
          </g>
          <g data-name="Group 109709">
            <g data-name="Group 109703">
              <g data-name="Group 109700">
                <g data-name="Group 109699">
                  <path
                    fill="#263238"
                    d="M171.463 382.18a19.453 19.453 0 0 1 1.127-6.246 19.512 19.512 0 0 1-1.127 6.246Z"
                    data-name="Path 99930"
                  />
                </g>
              </g>
              <g data-name="Group 109702">
                <g data-name="Group 109701">
                  <path
                    fill="#263238"
                    d="M175.496 379.512a22.954 22.954 0 0 1-6.879-.544 23.039 23.039 0 0 1 6.879.544Z"
                    data-name="Path 99931"
                  />
                </g>
              </g>
            </g>
            <g data-name="Group 109708">
              <g data-name="Group 109705">
                <g data-name="Group 109704">
                  <path
                    fill="#263238"
                    d="M178.457 376.526a18.605 18.605 0 0 1 .968-6.125 18.605 18.605 0 0 1-.968 6.125Z"
                    data-name="Path 99932"
                  />
                </g>
              </g>
              <g data-name="Group 109707">
                <g data-name="Group 109706">
                  <path
                    fill="#263238"
                    d="M182.345 373.83a21.927 21.927 0 0 1-6.734-.387 22.008 22.008 0 0 1 6.734.387Z"
                    data-name="Path 99933"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109731">
          <g data-name="Group 109715">
            <g data-name="Group 109712">
              <g data-name="Group 109711">
                <path
                  fill="#263238"
                  d="M168.409 398.259a21.544 21.544 0 0 1 .424-6.667 21.544 21.544 0 0 1-.424 6.667Z"
                  data-name="Path 99934"
                />
              </g>
            </g>
            <g data-name="Group 109714">
              <g data-name="Group 109713">
                <path
                  fill="#263238"
                  d="M172.311 394.98a25.472 25.472 0 0 1-7.265.257 25.57 25.57 0 0 1 7.265-.257Z"
                  data-name="Path 99935"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109720">
            <g data-name="Group 109717">
              <g data-name="Group 109716">
                <path
                  fill="#263238"
                  d="M175.281 390.825a14.886 14.886 0 0 1 .58-5.514 14.887 14.887 0 0 1-.58 5.514Z"
                  data-name="Path 99936"
                />
              </g>
            </g>
            <g data-name="Group 109719">
              <g data-name="Group 109718">
                <path
                  fill="#263238"
                  d="M178.626 388.237a17.566 17.566 0 0 1-6.028-.031 17.565 17.565 0 0 1 6.028.031Z"
                  data-name="Path 99937"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109725">
            <g data-name="Group 109722">
              <g data-name="Group 109721">
                <path
                  fill="#263238"
                  d="M182.89 384.08a14.887 14.887 0 0 1 .58-5.514 14.887 14.887 0 0 1-.58 5.514Z"
                  data-name="Path 99938"
                />
              </g>
            </g>
            <g data-name="Group 109724">
              <g data-name="Group 109723">
                <path
                  fill="#263238"
                  d="M186.235 381.495a17.567 17.567 0 0 1-6.028-.031 17.632 17.632 0 0 1 6.028.031Z"
                  data-name="Path 99939"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109730">
            <g data-name="Group 109727">
              <g data-name="Group 109726">
                <path
                  fill="#263238"
                  d="M189.57 379.108a14.886 14.886 0 0 1 .58-5.514 14.886 14.886 0 0 1-.58 5.514Z"
                  data-name="Path 99940"
                />
              </g>
            </g>
            <g data-name="Group 109729">
              <g data-name="Group 109728">
                <path
                  fill="#263238"
                  d="M192.915 376.519a17.567 17.567 0 0 1-6.028-.031 17.634 17.634 0 0 1 6.028.031Z"
                  data-name="Path 99941"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109752">
          <g data-name="Group 109736">
            <g data-name="Group 109733">
              <g data-name="Group 109732">
                <path
                  fill="#263238"
                  d="M144.749 393.66a21.588 21.588 0 0 1 .423-6.668 21.592 21.592 0 0 1-.423 6.668Z"
                  data-name="Path 99942"
                />
              </g>
            </g>
            <g data-name="Group 109735">
              <g data-name="Group 109734">
                <path
                  fill="#263238"
                  d="M148.65 390.38a12.884 12.884 0 0 1-3.623.389 12.875 12.875 0 0 1-3.642-.131 12.9 12.9 0 0 1 3.624-.389 12.954 12.954 0 0 1 3.641.131Z"
                  data-name="Path 99943"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109741">
            <g data-name="Group 109738">
              <g data-name="Group 109737">
                <path
                  fill="#263238"
                  d="M151.621 386.225a14.887 14.887 0 0 1 .58-5.514 14.881 14.881 0 0 1-.58 5.514Z"
                  data-name="Path 99944"
                />
              </g>
            </g>
            <g data-name="Group 109740">
              <g data-name="Group 109739">
                <path
                  fill="#263238"
                  d="M154.963 383.637a17.567 17.567 0 0 1-6.028-.031 17.566 17.566 0 0 1 6.028.031Z"
                  data-name="Path 99945"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109746">
            <g data-name="Group 109743">
              <g data-name="Group 109742">
                <path
                  fill="#263238"
                  d="M159.23 379.48a14.887 14.887 0 0 1 .58-5.514 14.88 14.88 0 0 1-.58 5.514Z"
                  data-name="Path 99946"
                />
              </g>
            </g>
            <g data-name="Group 109745">
              <g data-name="Group 109744">
                <path
                  fill="#263238"
                  d="M162.575 376.896a17.566 17.566 0 0 1-6.028-.031 17.568 17.568 0 0 1 6.028.031Z"
                  data-name="Path 99947"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109751">
            <g data-name="Group 109748">
              <g data-name="Group 109747">
                <path
                  fill="#263238"
                  d="M165.91 374.508a14.886 14.886 0 0 1 .58-5.514 14.881 14.881 0 0 1-.58 5.514Z"
                  data-name="Path 99948"
                />
              </g>
            </g>
            <g data-name="Group 109750">
              <g data-name="Group 109749">
                <path
                  fill="#263238"
                  d="M169.255 371.92a17.567 17.567 0 0 1-6.028-.031 17.567 17.567 0 0 1 6.028.031Z"
                  data-name="Path 99949"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109837">
        <g data-name="Group 109757">
          <g data-name="Group 109754">
            <path
              fill="#fff"
              d="M392.393 175.721h64.432s-.138.955-.467 2.6c-2.039 10.22-11.427 47.084-40.823 47.565-34.132.56-62.109 0-62.109 0s41.005-20.318 38.967-50.165Z"
              data-name="Path 99950"
            />
          </g>
          <g data-name="Group 109756">
            <g data-name="Group 109755">
              <path
                fill="#ced3dd"
                d="M392.393 175.721s.012.113.022.322l.041.923c.018.395.034.906.057 1.5l-.11 2.078a34.474 34.474 0 0 1-.949 5.711 40.027 40.027 0 0 1-2.76 7.329 60.349 60.349 0 0 1-12.853 16.693 97.606 97.606 0 0 1-10.062 8.232 102.456 102.456 0 0 1-12.252 7.586l-.1-.429c9.474.155 19.79.227 30.708.222l16.8-.076 8.683-.093c2.918-.066 5.884-.014 8.814-.267a31.555 31.555 0 0 0 16.519-6.809 47.574 47.574 0 0 0 11.145-13.2 80.361 80.361 0 0 0 6.738-14.963 95.019 95.019 0 0 0 3.766-14.783l.221.256-46.658-.117-13.1-.057-3.466-.026-.895-.012c-.2 0-.311-.012-.311-.012a2.41 2.41 0 0 1 .286-.012l.87-.012 3.421-.025 13.049-.058 46.806-.116h.262l-.041.256a94.954 94.954 0 0 1-3.755 14.873 80.614 80.614 0 0 1-6.759 15.064 48.011 48.011 0 0 1-11.258 13.353 32.051 32.051 0 0 1-16.785 6.922c-3 .258-5.936.2-8.86.268l-8.686.09-16.8.063c-10.921-.007-21.238-.1-30.717-.272l-.891-.017.795-.412a102.6 102.6 0 0 0 12.212-7.519 97.789 97.789 0 0 0 10.049-8.165 60.5 60.5 0 0 0 12.87-16.54 40.358 40.358 0 0 0 2.8-7.263 35.224 35.224 0 0 0 1.004-5.68c.054-.779.1-1.47.144-2.069l-.016-1.521v-.916a2.7 2.7 0 0 1 .002-.3Z"
                data-name="Path 99951"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109758">
          <path
            d="M413.597 191.088h-3.713l-.69 1.705h-2.531l3.876-8.779h2.445l3.889 8.779h-2.584Zm-.728-1.832-1.129-2.809-1.129 2.809Z"
            data-name="Path 99952"
          />
          <path
            d="M425.481 187.262c0 1.994-1.5 3.236-3.9 3.236h-1.53v2.295h-2.488v-8.779h4.014c2.399 0 3.904 1.241 3.904 3.248Zm-2.507 0c0-.815-.515-1.292-1.543-1.292h-1.38v2.571h1.38c1.032 0 1.543-.476 1.543-1.279Z"
            data-name="Path 99953"
          />
          <path
            d="M430.52 190.46h-1.357v2.333h-2.48v-8.779h4.013c2.4 0 3.9 1.241 3.9 3.248a2.914 2.914 0 0 1-1.718 2.772l1.893 2.759h-2.658Zm.026-4.49h-1.383v2.571h1.38c1.028 0 1.543-.476 1.543-1.279s-.512-1.292-1.54-1.292Z"
            data-name="Path 99954"
          />
        </g>
        <g data-name="Group 109784">
          <g data-name="Group 109763">
            <g data-name="Group 109760">
              <g data-name="Group 109759">
                <path
                  fill="#263238"
                  d="M390.525 205.78a28.225 28.225 0 0 1 5.526-5.288 28.225 28.225 0 0 1-5.526 5.288Z"
                  data-name="Path 99955"
                />
              </g>
            </g>
            <g data-name="Group 109762">
              <g data-name="Group 109761">
                <path
                  fill="#263238"
                  d="M396.354 206.039a33.348 33.348 0 0 1-6.319-5.416 33.444 33.444 0 0 1 6.319 5.416Z"
                  data-name="Path 99956"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109768">
            <g data-name="Group 109765">
              <g data-name="Group 109764">
                <path
                  fill="#263238"
                  d="M402.077 204.843a19.5 19.5 0 0 1 4.764-4.194 19.441 19.441 0 0 1-4.764 4.194Z"
                  data-name="Path 99957"
                />
              </g>
            </g>
            <g data-name="Group 109767">
              <g data-name="Group 109766">
                <path
                  fill="#263238"
                  d="M406.902 205.257a22.96 22.96 0 0 1-5.053-4.7 23.025 23.025 0 0 1 5.053 4.7Z"
                  data-name="Path 99958"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109773">
            <g data-name="Group 109770">
              <g data-name="Group 109769">
                <path
                  fill="#263238"
                  d="M413.713 205.065a19.5 19.5 0 0 1 4.764-4.194 19.441 19.441 0 0 1-4.764 4.194Z"
                  data-name="Path 99959"
                />
              </g>
            </g>
            <g data-name="Group 109772">
              <g data-name="Group 109771">
                <path
                  fill="#263238"
                  d="M418.538 205.48a22.962 22.962 0 0 1-5.053-4.7 22.96 22.96 0 0 1 5.053 4.7Z"
                  data-name="Path 99960"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109778">
            <g data-name="Group 109775">
              <g data-name="Group 109774">
                <path
                  fill="#263238"
                  d="M424.607 205.273a19.472 19.472 0 0 1 4.764-4.195 19.472 19.472 0 0 1-4.764 4.195Z"
                  data-name="Path 99961"
                />
              </g>
            </g>
            <g data-name="Group 109777">
              <g data-name="Group 109776">
                <path
                  fill="#263238"
                  d="M429.432 205.687a23 23 0 0 1-5.053-4.7 23 23 0 0 1 5.053 4.7Z"
                  data-name="Path 99962"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109783">
            <g data-name="Group 109780">
              <g data-name="Group 109779">
                <path
                  fill="#263238"
                  d="M433.608 205.185a18.609 18.609 0 0 1 4.563-4.2 18.6 18.6 0 0 1-4.563 4.2Z"
                  data-name="Path 99963"
                />
              </g>
            </g>
            <g data-name="Group 109782">
              <g data-name="Group 109781">
                <path
                  fill="#263238"
                  d="M438.329 205.489a21.976 21.976 0 0 1-5.036-4.487 21.976 21.976 0 0 1 5.036 4.487Z"
                  data-name="Path 99964"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109810">
          <g data-name="Group 109789">
            <g data-name="Group 109786">
              <g data-name="Group 109785">
                <path
                  fill="#263238"
                  d="M385.473 212.358a28.161 28.161 0 0 1 5.671-5.132 28.233 28.233 0 0 1-5.671 5.132Z"
                  data-name="Path 99965"
                />
              </g>
            </g>
            <g data-name="Group 109788">
              <g data-name="Group 109787">
                <path
                  fill="#263238"
                  d="M391.293 212.78a33.456 33.456 0 0 1-6.167-5.589 33.369 33.369 0 0 1 6.167 5.589Z"
                  data-name="Path 99966"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109794">
            <g data-name="Group 109791">
              <g data-name="Group 109790">
                <path
                  fill="#263238"
                  d="M397.047 211.745a19.454 19.454 0 0 1 4.878-4.06 19.5 19.5 0 0 1-4.878 4.06Z"
                  data-name="Path 99967"
                />
              </g>
            </g>
            <g data-name="Group 109793">
              <g data-name="Group 109792">
                <path
                  fill="#263238"
                  d="M401.863 212.293a23.01 23.01 0 0 1-4.921-4.839 23.011 23.011 0 0 1 4.921 4.839Z"
                  data-name="Path 99968"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109799">
            <g data-name="Group 109796">
              <g data-name="Group 109795">
                <path
                  fill="#263238"
                  d="M408.672 212.289a19.5 19.5 0 0 1 4.878-4.06 19.452 19.452 0 0 1-4.878 4.06Z"
                  data-name="Path 99969"
                />
              </g>
            </g>
            <g data-name="Group 109798">
              <g data-name="Group 109797">
                <path
                  fill="#263238"
                  d="M413.483 212.837a23.01 23.01 0 0 1-4.921-4.839 22.95 22.95 0 0 1 4.921 4.839Z"
                  data-name="Path 99970"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109804">
            <g data-name="Group 109801">
              <g data-name="Group 109800">
                <path
                  fill="#263238"
                  d="M419.556 212.799a19.458 19.458 0 0 1 4.879-4.061 19.458 19.458 0 0 1-4.879 4.061Z"
                  data-name="Path 99971"
                />
              </g>
            </g>
            <g data-name="Group 109803">
              <g data-name="Group 109802">
                <path
                  fill="#263238"
                  d="M424.368 213.346a22.975 22.975 0 0 1-4.921-4.838 22.975 22.975 0 0 1 4.921 4.838Z"
                  data-name="Path 99972"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109809">
            <g data-name="Group 109806">
              <g data-name="Group 109805">
                <path
                  fill="#263238"
                  d="M428.556 212.961a18.526 18.526 0 0 1 4.677-4.07 18.626 18.626 0 0 1-4.677 4.07Z"
                  data-name="Path 99973"
                />
              </g>
            </g>
            <g data-name="Group 109808">
              <g data-name="Group 109807">
                <path
                  fill="#263238"
                  d="M433.263 213.395a21.971 21.971 0 0 1-4.909-4.625 21.972 21.972 0 0 1 4.909 4.625Z"
                  data-name="Path 99974"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109836">
          <g data-name="Group 109815">
            <g data-name="Group 109812">
              <g data-name="Group 109811">
                <path
                  fill="#263238"
                  d="M375.763 219.714a28.279 28.279 0 0 1 5.671-5.133 28.2 28.2 0 0 1-5.671 5.133Z"
                  data-name="Path 99975"
                />
              </g>
            </g>
            <g data-name="Group 109814">
              <g data-name="Group 109813">
                <path
                  fill="#263238"
                  d="M381.583 220.138a33.413 33.413 0 0 1-6.167-5.59 33.413 33.413 0 0 1 6.167 5.59Z"
                  data-name="Path 99976"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109820">
            <g data-name="Group 109817">
              <g data-name="Group 109816">
                <path
                  fill="#263238"
                  d="M387.337 219.101a19.43 19.43 0 0 1 4.878-4.061 19.477 19.477 0 0 1-4.878 4.061Z"
                  data-name="Path 99977"
                />
              </g>
            </g>
            <g data-name="Group 109819">
              <g data-name="Group 109818">
                <path
                  fill="#263238"
                  d="M392.148 219.648a22.975 22.975 0 0 1-4.921-4.838 23.037 23.037 0 0 1 4.921 4.838Z"
                  data-name="Path 99978"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109825">
            <g data-name="Group 109822">
              <g data-name="Group 109821">
                <path
                  fill="#263238"
                  d="M398.963 219.645a19.486 19.486 0 0 1 4.878-4.061 19.477 19.477 0 0 1-4.878 4.061Z"
                  data-name="Path 99979"
                />
              </g>
            </g>
            <g data-name="Group 109824">
              <g data-name="Group 109823">
                <path
                  fill="#263238"
                  d="M403.773 220.192a22.975 22.975 0 0 1-4.921-4.838 23.038 23.038 0 0 1 4.921 4.838Z"
                  data-name="Path 99980"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109830">
            <g data-name="Group 109827">
              <g data-name="Group 109826">
                <path
                  fill="#263238"
                  d="M409.846 220.154a19.5 19.5 0 0 1 4.878-4.06 19.454 19.454 0 0 1-4.878 4.06Z"
                  data-name="Path 99981"
                />
              </g>
            </g>
            <g data-name="Group 109829">
              <g data-name="Group 109828">
                <path
                  fill="#263238"
                  d="M414.658 220.702a22.985 22.985 0 0 1-4.922-4.839 22.984 22.984 0 0 1 4.922 4.839Z"
                  data-name="Path 99982"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109835">
            <g data-name="Group 109832">
              <g data-name="Group 109831">
                <path
                  fill="#263238"
                  d="M418.845 220.316a18.607 18.607 0 0 1 4.678-4.07 18.554 18.554 0 0 1-4.678 4.07Z"
                  data-name="Path 99983"
                />
              </g>
            </g>
            <g data-name="Group 109834">
              <g data-name="Group 109833">
                <path
                  fill="#263238"
                  d="M423.556 220.75a21.971 21.971 0 0 1-4.91-4.624 21.974 21.974 0 0 1 4.91 4.624Z"
                  data-name="Path 99984"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 109921">
        <g data-name="Group 109841">
          <g data-name="Group 109838">
            <path
              fill="#fff"
              d="M336.797 378.903s-12.05-44.824 16.685-68.928l57.362 5.984s-18.157 38.081-11.6 62.944Z"
              data-name="Path 99985"
            />
          </g>
          <g data-name="Group 109840">
            <g data-name="Group 109839">
              <path
                fill="#ced3dd"
                d="M336.797 378.903s.4-.019 1.163-.028l3.35-.028 12.681-.059 45.248-.107-.215.278a47.659 47.659 0 0 1-1.22-7.142 63.294 63.294 0 0 1-.175-7.546 94 94 0 0 1 2.057-15.766 146.639 146.639 0 0 1 4.475-16.248 165.538 165.538 0 0 1 6.448-16.41l.207.37-2.358-.246-55-5.762.175-.053a50.344 50.344 0 0 0-10.283 11.924 54.7 54.7 0 0 0-5.82 13.07 77.646 77.646 0 0 0-3.088 23.218 101.328 101.328 0 0 0 1.325 15.194c.285 1.746.553 3.079.743 3.976l.219 1.017a3.446 3.446 0 0 1 .068.346 2.173 2.173 0 0 1-.106-.337l-.256-1.009a67.236 67.236 0 0 1-.815-3.968 97.223 97.223 0 0 1-1.46-15.215 77.277 77.277 0 0 1 3-23.335 54.911 54.911 0 0 1 5.823-13.185 50.717 50.717 0 0 1 10.352-12.059l.075-.065.1.011 55.006 5.713 2.358.246.362.037-.153.334a166 166 0 0 0-6.43 16.35 146.884 146.884 0 0 0-4.475 16.188 93.939 93.939 0 0 0-2.069 15.68 62.736 62.736 0 0 0 .154 7.493 47.133 47.133 0 0 0 1.19 7.07l.069.28h-.284l-45.368-.107-12.639-.059-3.314-.028a45.022 45.022 0 0 1-1.12-.033Z"
                data-name="Path 99986"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109842">
          <path
            d="m354.818 327.314 1.638-1.416a2.042 2.042 0 0 0 1.483 1.082c.754.087 1.221-.3 1.329-1.125l.49-3.717-3.2-.366.248-1.888 5.8.663-.717 5.456c-.295 2.247-1.686 3.215-4.015 2.949a4.1 4.1 0 0 1-3.056-1.638Z"
            data-name="Path 99987"
          />
          <path
            d="m363.663 325.532.63-4.789 2.621.3-.62 4.716c-.2 1.48.389 2.149 1.487 2.274s1.825-.393 2.021-1.873l.62-4.716 2.58.3-.63 4.789c-.34 2.581-2.145 3.84-4.884 3.527s-4.164-1.947-3.825-4.528Z"
            data-name="Path 99988"
          />
          <path
            d="m383.591 322.95-1.137 8.642-2.158-.247-3.485-5.073-.605 4.605-2.567-.294 1.136-8.642 2.158.247 3.484 5.074.605-4.606Z"
            data-name="Path 99989"
          />
          <path
            d="m391.993 330.765-.249 1.889-7.438-.851 1.136-8.641 7.267.831-.246 1.887-4.673-.534-.192 1.456 4.116.471-.24 1.826-4.117-.47-.207 1.58Z"
            data-name="Path 99990"
          />
        </g>
        <g data-name="Group 109868">
          <g data-name="Group 109847">
            <g data-name="Group 109844">
              <g data-name="Group 109843">
                <path
                  fill="#263238"
                  d="M344.497 343.14a30.289 30.289 0 0 1 6.345-4.748 30.266 30.266 0 0 1-6.345 4.748Z"
                  data-name="Path 99991"
                />
              </g>
            </g>
            <g data-name="Group 109846">
              <g data-name="Group 109845">
                <path
                  fill="#263238"
                  d="M350.649 343.903a35.356 35.356 0 0 1-6.194-5.9 35.165 35.165 0 0 1 6.194 5.9Z"
                  data-name="Path 99992"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109852">
            <g data-name="Group 109849">
              <g data-name="Group 109848">
                <path
                  fill="#263238"
                  d="M356.823 343.215a21.033 21.033 0 0 1 5.44-3.735 20.988 20.988 0 0 1-5.436 3.733Z"
                  data-name="Path 99993"
                />
              </g>
            </g>
            <g data-name="Group 109851">
              <g data-name="Group 109850">
                <path
                  fill="#263238"
                  d="M361.897 344.041a24.154 24.154 0 0 1-4.919-5.081 24.086 24.086 0 0 1 4.919 5.081Z"
                  data-name="Path 99994"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109857">
            <g data-name="Group 109854">
              <g data-name="Group 109853">
                <path
                  fill="#263238"
                  d="M369.131 344.439a21.032 21.032 0 0 1 5.436-3.733 20.988 20.988 0 0 1-5.436 3.733Z"
                  data-name="Path 99995"
                />
              </g>
            </g>
            <g data-name="Group 109856">
              <g data-name="Group 109855">
                <path
                  fill="#263238"
                  d="M374.205 345.265a24.153 24.153 0 0 1-4.919-5.081 24.085 24.085 0 0 1 4.919 5.081Z"
                  data-name="Path 99996"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109862">
            <g data-name="Group 109859">
              <g data-name="Group 109858">
                <path
                  fill="#263238"
                  d="M380.655 345.585a20.96 20.96 0 0 1 5.436-3.734 21.005 21.005 0 0 1-5.436 3.734Z"
                  data-name="Path 99997"
                />
              </g>
            </g>
            <g data-name="Group 109861">
              <g data-name="Group 109860">
                <path
                  fill="#263238"
                  d="M385.728 346.41a24.152 24.152 0 0 1-4.919-5.081 24.152 24.152 0 0 1 4.919 5.081Z"
                  data-name="Path 99998"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109867">
            <g data-name="Group 109864">
              <g data-name="Group 109863">
                <path
                  fill="#263238"
                  d="M390.199 346.28a19.968 19.968 0 0 1 5.223-3.755 20.011 20.011 0 0 1-5.223 3.755Z"
                  data-name="Path 99999"
                />
              </g>
            </g>
            <g data-name="Group 109866">
              <g data-name="Group 109865">
                <path
                  fill="#263238"
                  d="M395.173 346.98a23.15 23.15 0 0 1-4.921-4.869 23.152 23.152 0 0 1 4.921 4.869Z"
                  data-name="Path 100000"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109894">
          <g data-name="Group 109873">
            <g data-name="Group 109870">
              <g data-name="Group 109869">
                <path
                  fill="#263238"
                  d="M342.663 365.495a30.122 30.122 0 0 1 6.052-5.07 30.034 30.034 0 0 1-6.052 5.07Z"
                  data-name="Path 100001"
                />
              </g>
            </g>
            <g data-name="Group 109872">
              <g data-name="Group 109871">
                <path
                  fill="#263238"
                  d="M348.851 365.937a35.473 35.473 0 0 1-6.536-5.567 35.473 35.473 0 0 1 6.536 5.567Z"
                  data-name="Path 100002"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109878">
            <g data-name="Group 109875">
              <g data-name="Group 109874">
                <path
                  fill="#263238"
                  d="M354.975 364.93a20.864 20.864 0 0 1 5.2-4.01 20.864 20.864 0 0 1-5.2 4.01Z"
                  data-name="Path 100003"
                />
              </g>
            </g>
            <g data-name="Group 109877">
              <g data-name="Group 109876">
                <path
                  fill="#263238"
                  d="M360.09 365.491a24.359 24.359 0 0 1-5.215-4.818 24.361 24.361 0 0 1 5.215 4.818Z"
                  data-name="Path 100004"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109883">
            <g data-name="Group 109880">
              <g data-name="Group 109879">
                <path
                  fill="#263238"
                  d="M367.337 365.513a20.862 20.862 0 0 1 5.2-4.01 20.8 20.8 0 0 1-5.2 4.01Z"
                  data-name="Path 100005"
                />
              </g>
            </g>
            <g data-name="Group 109882">
              <g data-name="Group 109881">
                <path
                  fill="#263238"
                  d="M372.451 366.074a24.317 24.317 0 0 1-5.214-4.818 24.322 24.322 0 0 1 5.214 4.818Z"
                  data-name="Path 100006"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109888">
            <g data-name="Group 109885">
              <g data-name="Group 109884">
                <path
                  fill="#263238"
                  d="M378.911 366.059a20.864 20.864 0 0 1 5.2-4.01 20.864 20.864 0 0 1-5.2 4.01Z"
                  data-name="Path 100007"
                />
              </g>
            </g>
            <g data-name="Group 109887">
              <g data-name="Group 109886">
                <path
                  fill="#263238"
                  d="M384.025 366.62a24.387 24.387 0 0 1-5.214-4.818 24.323 24.323 0 0 1 5.214 4.818Z"
                  data-name="Path 100008"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109893">
            <g data-name="Group 109890">
              <g data-name="Group 109889">
                <path
                  fill="#263238"
                  d="M388.481 366.253a19.826 19.826 0 0 1 4.991-4.02 19.826 19.826 0 0 1-4.991 4.02Z"
                  data-name="Path 100009"
                />
              </g>
            </g>
            <g data-name="Group 109892">
              <g data-name="Group 109891">
                <path
                  fill="#263238"
                  d="M393.489 366.701a23.312 23.312 0 0 1-5.2-4.606 23.381 23.381 0 0 1 5.2 4.606Z"
                  data-name="Path 100010"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109920">
          <g data-name="Group 109899">
            <g data-name="Group 109896">
              <g data-name="Group 109895">
                <path
                  fill="#263238"
                  d="M342.582 354.572a30.2 30.2 0 0 1 6.2-4.915 30.113 30.113 0 0 1-6.2 4.915Z"
                  data-name="Path 100011"
                />
              </g>
            </g>
            <g data-name="Group 109898">
              <g data-name="Group 109897">
                <path
                  fill="#263238"
                  d="M348.756 355.171a35.313 35.313 0 0 1-6.371-5.732 35.5 35.5 0 0 1 6.371 5.732Z"
                  data-name="Path 100012"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109904">
            <g data-name="Group 109901">
              <g data-name="Group 109900">
                <path
                  fill="#263238"
                  d="M354.907 354.32a20.944 20.944 0 0 1 5.319-3.876 20.945 20.945 0 0 1-5.319 3.876Z"
                  data-name="Path 100013"
                />
              </g>
            </g>
            <g data-name="Group 109903">
              <g data-name="Group 109902">
                <path
                  fill="#263238"
                  d="M360.003 355.011a24.284 24.284 0 0 1-5.072-4.949 24.219 24.219 0 0 1 5.072 4.949Z"
                  data-name="Path 100014"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109909">
            <g data-name="Group 109906">
              <g data-name="Group 109905">
                <path
                  fill="#263238"
                  d="M367.247 355.217a20.864 20.864 0 0 1 5.32-3.876 20.973 20.973 0 0 1-5.32 3.876Z"
                  data-name="Path 100015"
                />
              </g>
            </g>
            <g data-name="Group 109908">
              <g data-name="Group 109907">
                <path
                  fill="#263238"
                  d="M372.344 355.908a24.219 24.219 0 0 1-5.072-4.949 24.219 24.219 0 0 1 5.072 4.949Z"
                  data-name="Path 100016"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109914">
            <g data-name="Group 109911">
              <g data-name="Group 109910">
                <path
                  fill="#263238"
                  d="M378.801 356.057a20.883 20.883 0 0 1 5.319-3.876 20.944 20.944 0 0 1-5.319 3.876Z"
                  data-name="Path 100017"
                />
              </g>
            </g>
            <g data-name="Group 109913">
              <g data-name="Group 109912">
                <path
                  fill="#263238"
                  d="M383.897 356.747a24.244 24.244 0 0 1-5.072-4.948 24.247 24.247 0 0 1 5.072 4.948Z"
                  data-name="Path 100018"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109919">
            <g data-name="Group 109916">
              <g data-name="Group 109915">
                <path
                  fill="#263238"
                  d="M388.363 356.494a19.924 19.924 0 0 1 5.107-3.891 19.91 19.91 0 0 1-5.107 3.891Z"
                  data-name="Path 100019"
                />
              </g>
            </g>
            <g data-name="Group 109918">
              <g data-name="Group 109917">
                <path
                  fill="#263238"
                  d="M393.355 357.07a23.194 23.194 0 0 1-5.066-4.737 23.194 23.194 0 0 1 5.066 4.737Z"
                  data-name="Path 100020"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 110005">
        <g data-name="Group 109925">
          <g data-name="Group 109922">
            <path
              fill="#fff"
              d="M84.963 44.01s-8.892 43.036 10.071 66.045l64.685-3.075s-15.885-15.5-14.242-52.747Z"
              data-name="Path 100021"
            />
          </g>
          <g data-name="Group 109924">
            <g data-name="Group 109923">
              <path
                fill="#ced3dd"
                d="M84.963 44.01a2.027 2.027 0 0 1 .27.032l.818.126 3.212.512 12.259 2.012 43.99 7.317.194.032-.008.2a102.336 102.336 0 0 0 2.051 26.179c.444 2.3 1.127 4.568 1.78 6.875l1.176 3.422c.42 1.137.909 2.256 1.364 3.4.487 1.125 1.042 2.227 1.567 3.349.607 1.078 1.144 2.206 1.817 3.26a34.829 34.829 0 0 0 4.446 6.073l.393.419-.563.027-8.09.385-56.6 2.66-.118.006-.072-.09a46.728 46.728 0 0 1-7.272-12.784 69.509 69.509 0 0 1-3.478-12.855A106.741 106.741 0 0 1 82.817 62.9a128.922 128.922 0 0 1 1.266-13.982c.236-1.607.45-2.836.609-3.662l.19-.933a2.069 2.069 0 0 1 .081-.313 2.711 2.711 0 0 1-.044.32l-.153.94c-.135.829-.326 2.06-.538 3.668a137.512 137.512 0 0 0-1.136 13.971 107.954 107.954 0 0 0 1.391 21.589 69.407 69.407 0 0 0 3.5 12.766 46.414 46.414 0 0 0 7.231 12.644l-.19-.084 56.594-2.718 8.09-.383-.169.445a35.124 35.124 0 0 1-4.525-6.179c-.68-1.066-1.222-2.2-1.834-3.293-.529-1.132-1.087-2.244-1.578-3.378-.457-1.148-.949-2.276-1.371-3.423l-1.181-3.447c-.655-2.323-1.339-4.61-1.784-6.92a102.344 102.344 0 0 1-2.011-26.305l.186.231-43.815-7.518-12.288-2.135-3.247-.575-.837-.154a2.647 2.647 0 0 1-.291-.062Z"
                data-name="Path 100022"
              />
            </g>
          </g>
        </g>
        <g fill="#ced3dd" stroke="rgba(0,0,0,0)" data-name="Group 109926">
          <path
            d="m103.339 58.194 4.143.266c2.9.186 4.785 2 4.613 4.695s-2.272 4.253-5.175 4.067l-4.144-.266Zm3.608 7.044a2.41 2.41 0 1 0 .309-4.807l-1.566-.1-.308 4.807Z"
            data-name="Path 100023"
          />
          <path
            d="m120.268 66.156-.123 1.915-7.034-.452.563-8.762 6.871.442-.123 1.915-4.419-.284-.1 1.477 3.894.25-.119 1.853-3.894-.25-.1 1.6Z"
            data-name="Path 100024"
          />
          <path
            d="M121.337 63.748a4.541 4.541 0 0 1 5.112-4.247 4.645 4.645 0 0 1 3.66 1.894l-1.669 1.326a2.593 2.593 0 0 0-2-1.159 2.509 2.509 0 0 0-.321 5.007 2.593 2.593 0 0 0 2.131-.893l1.486 1.528a4.644 4.644 0 0 1-3.873 1.41 4.54 4.54 0 0 1-4.526-4.866Z"
            data-name="Path 100025"
          />
        </g>
        <g data-name="Group 109952">
          <g data-name="Group 109931">
            <g data-name="Group 109928">
              <g data-name="Group 109927">
                <path
                  fill="#263238"
                  d="M93.98 82.935a14.253 14.253 0 0 1 2.522-2.876 14.2 14.2 0 0 1 2.89-2.506 14.253 14.253 0 0 1-2.522 2.876 14.171 14.171 0 0 1-2.89 2.506Z"
                  data-name="Path 100026"
                />
              </g>
            </g>
            <g data-name="Group 109930">
              <g data-name="Group 109929">
                <path
                  fill="#263238"
                  d="M99.744 83.085a16.44 16.44 0 0 1-3.315-2.442 16.369 16.369 0 0 1-2.981-2.842 16.523 16.523 0 0 1 3.316 2.443 16.436 16.436 0 0 1 2.98 2.841Z"
                  data-name="Path 100027"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109936">
            <g data-name="Group 109933">
              <g data-name="Group 109932">
                <path
                  fill="#263238"
                  d="M105.389 81.78a19.394 19.394 0 0 1 4.668-4.277 9.847 9.847 0 0 1-2.158 2.331 9.842 9.842 0 0 1-2.51 1.946Z"
                  data-name="Path 100028"
                />
              </g>
            </g>
            <g data-name="Group 109935">
              <g data-name="Group 109934">
                <path
                  fill="#263238"
                  d="M110.163 82.103a11.379 11.379 0 0 1-2.695-2.105 11.337 11.337 0 0 1-2.344-2.489 11.4 11.4 0 0 1 2.695 2.105 11.367 11.367 0 0 1 2.344 2.489Z"
                  data-name="Path 100029"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109941">
            <g data-name="Group 109938">
              <g data-name="Group 109937">
                <path
                  fill="#263238"
                  d="M116.89 81.78a19.394 19.394 0 0 1 4.668-4.277 9.847 9.847 0 0 1-2.158 2.331 9.842 9.842 0 0 1-2.51 1.946Z"
                  data-name="Path 100030"
                />
              </g>
            </g>
            <g data-name="Group 109940">
              <g data-name="Group 109939">
                <path
                  fill="#263238"
                  d="M121.663 82.101a11.411 11.411 0 0 1-2.7-2.1 11.409 11.409 0 0 1-2.344-2.49 11.378 11.378 0 0 1 2.695 2.105 11.337 11.337 0 0 1 2.349 2.485Z"
                  data-name="Path 100031"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109946">
            <g data-name="Group 109943">
              <g data-name="Group 109942">
                <path
                  fill="#263238"
                  d="M127.663 81.78a19.344 19.344 0 0 1 4.668-4.277 9.838 9.838 0 0 1-2.159 2.331 9.848 9.848 0 0 1-2.509 1.946Z"
                  data-name="Path 100032"
                />
              </g>
            </g>
            <g data-name="Group 109945">
              <g data-name="Group 109944">
                <path
                  fill="#263238"
                  d="M132.431 82.099a11.389 11.389 0 0 1-2.695-2.1 11.353 11.353 0 0 1-2.344-2.49 22.473 22.473 0 0 1 5.039 4.594Z"
                  data-name="Path 100033"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109951">
            <g data-name="Group 109948">
              <g data-name="Group 109947">
                <path
                  fill="#263238"
                  d="M136.552 81.518a18.507 18.507 0 0 1 4.47-4.276 9.454 9.454 0 0 1-2.055 2.326 9.389 9.389 0 0 1-2.415 1.95Z"
                  data-name="Path 100034"
                />
              </g>
            </g>
            <g data-name="Group 109950">
              <g data-name="Group 109949">
                <path
                  fill="#263238"
                  d="M141.221 81.731a10.888 10.888 0 0 1-2.681-2 10.876 10.876 0 0 1-2.339-2.386 21.433 21.433 0 0 1 5.02 4.381Z"
                  data-name="Path 100035"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 109978">
          <g data-name="Group 109957">
            <g data-name="Group 109954">
              <g data-name="Group 109953">
                <path
                  fill="#263238"
                  d="M96.234 92.88a19.351 19.351 0 0 1 4.381-4.57 9.856 9.856 0 0 1-2 2.465 9.867 9.867 0 0 1-2.381 2.105Z"
                  data-name="Path 100036"
                />
              </g>
            </g>
            <g data-name="Group 109956">
              <g data-name="Group 109955">
                <path
                  fill="#263238"
                  d="M101.016 92.889a11.425 11.425 0 0 1-2.825-1.926 11.383 11.383 0 0 1-2.5-2.332 11.385 11.385 0 0 1 2.825 1.926 11.356 11.356 0 0 1 2.5 2.332Z"
                  data-name="Path 100037"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109962">
            <g data-name="Group 109959">
              <g data-name="Group 109958">
                <path
                  fill="#263238"
                  d="M107.08 92.48a19.382 19.382 0 0 1 4.565-4.387 19.382 19.382 0 0 1-4.565 4.387Z"
                  data-name="Path 100038"
                />
              </g>
            </g>
            <g data-name="Group 109961">
              <g data-name="Group 109960">
                <path
                  fill="#263238"
                  d="M111.863 92.688a11.4 11.4 0 0 1-2.743-2.04 11.368 11.368 0 0 1-2.4-2.433 11.415 11.415 0 0 1 2.744 2.04 11.347 11.347 0 0 1 2.399 2.433Z"
                  data-name="Path 100039"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109967">
            <g data-name="Group 109964">
              <g data-name="Group 109963">
                <path
                  fill="#263238"
                  d="M118.235 92.231a19.352 19.352 0 0 1 4.565-4.386 9.854 9.854 0 0 1-2.1 2.381 9.818 9.818 0 0 1-2.465 2.005Z"
                  data-name="Path 100040"
                />
              </g>
            </g>
            <g data-name="Group 109966">
              <g data-name="Group 109965">
                <path
                  fill="#263238"
                  d="M123.013 92.438a11.346 11.346 0 0 1-2.743-2.039 11.419 11.419 0 0 1-2.4-2.433 22.467 22.467 0 0 1 5.146 4.472Z"
                  data-name="Path 100041"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109972">
            <g data-name="Group 109969">
              <g data-name="Group 109968">
                <path
                  fill="#263238"
                  d="M128.463 91.311a19.352 19.352 0 0 1 4.565-4.386 9.854 9.854 0 0 1-2.1 2.381 9.858 9.858 0 0 1-2.465 2.005Z"
                  data-name="Path 100042"
                />
              </g>
            </g>
            <g data-name="Group 109971">
              <g data-name="Group 109970">
                <path
                  fill="#263238"
                  d="M133.239 91.518a11.361 11.361 0 0 1-2.744-2.039 11.4 11.4 0 0 1-2.4-2.433 22.467 22.467 0 0 1 5.146 4.472Z"
                  data-name="Path 100043"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109977">
            <g data-name="Group 109974">
              <g data-name="Group 109973">
                <path
                  fill="#263238"
                  d="M138.012 90.697a19.4 19.4 0 0 1 4.016-4.894 9.849 9.849 0 0 1-1.807 2.613 9.855 9.855 0 0 1-2.209 2.281Z"
                  data-name="Path 100044"
                />
              </g>
            </g>
            <g data-name="Group 109976">
              <g data-name="Group 109975">
                <path
                  fill="#263238"
                  d="M142.781 90.34a22.457 22.457 0 0 1-5.638-3.835 22.41 22.41 0 0 1 5.638 3.835Z"
                  data-name="Path 100045"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Group 110004">
          <g data-name="Group 109983">
            <g data-name="Group 109980">
              <g data-name="Group 109979">
                <path
                  fill="#263238"
                  d="M99.629 103.503a19.353 19.353 0 0 1 4.382-4.569 19.352 19.352 0 0 1-4.382 4.569Z"
                  data-name="Path 100046"
                />
              </g>
            </g>
            <g data-name="Group 109982">
              <g data-name="Group 109981">
                <path
                  fill="#263238"
                  d="M104.412 103.515a11.385 11.385 0 0 1-2.825-1.926 11.384 11.384 0 0 1-2.5-2.332 11.4 11.4 0 0 1 2.825 1.925 11.375 11.375 0 0 1 2.5 2.333Z"
                  data-name="Path 100047"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109988">
            <g data-name="Group 109985">
              <g data-name="Group 109984">
                <path
                  fill="#263238"
                  d="M109.285 102.71a19.351 19.351 0 0 1 4.565-4.386 19.352 19.352 0 0 1-4.565 4.386Z"
                  data-name="Path 100048"
                />
              </g>
            </g>
            <g data-name="Group 109987">
              <g data-name="Group 109986">
                <path
                  fill="#263238"
                  d="M114.063 102.917a22.458 22.458 0 0 1-5.146-4.472 11.35 11.35 0 0 1 2.744 2.04 11.382 11.382 0 0 1 2.402 2.432Z"
                  data-name="Path 100049"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109993">
            <g data-name="Group 109990">
              <g data-name="Group 109989">
                <path
                  fill="#263238"
                  d="M119.511 101.79a19.352 19.352 0 0 1 4.565-4.386 19.352 19.352 0 0 1-4.565 4.386Z"
                  data-name="Path 100050"
                />
              </g>
            </g>
            <g data-name="Group 109992">
              <g data-name="Group 109991">
                <path
                  fill="#263238"
                  d="M124.289 101.997a22.4 22.4 0 0 1-5.146-4.472 22.467 22.467 0 0 1 5.146 4.472Z"
                  data-name="Path 100051"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 109998">
            <g data-name="Group 109995">
              <g data-name="Group 109994">
                <path
                  fill="#263238"
                  d="M129.737 100.87a19.352 19.352 0 0 1 4.565-4.386 19.4 19.4 0 0 1-4.565 4.386Z"
                  data-name="Path 100052"
                />
              </g>
            </g>
            <g data-name="Group 109997">
              <g data-name="Group 109996">
                <path
                  fill="#263238"
                  d="M134.515 101.08a11.34 11.34 0 0 1-2.744-2.039 11.449 11.449 0 0 1-2.4-2.433 22.443 22.443 0 0 1 5.147 4.472Z"
                  data-name="Path 100053"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 110003">
            <g data-name="Group 110000">
              <g data-name="Group 109999">
                <path
                  fill="#263238"
                  d="M139.963 99.95a19.351 19.351 0 0 1 4.565-4.386 19.352 19.352 0 0 1-4.565 4.386Z"
                  data-name="Path 100054"
                />
              </g>
            </g>
            <g data-name="Group 110002">
              <g data-name="Group 110001">
                <path
                  fill="#263238"
                  d="M144.74 100.157a11.346 11.346 0 0 1-2.743-2.039 11.419 11.419 0 0 1-2.4-2.433 22.467 22.467 0 0 1 5.146 4.472Z"
                  data-name="Path 100055"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g data-name="Group 110007">
        <text
          fill="#4e5a6b"
          data-name="For Daily slot basis"
          fontFamily="NunitoSans-ExtraBold, Nunito Sans"
          fontSize={16}
          fontWeight={800}
          transform="translate(234.526 210)"
        >
          <tspan x={-73} y={0}>
            {"For Daily Slot Basis"}
          </tspan>
        </text>
        <text
          fill="#98a0ac"
          data-name="Applicable days will considered as slots"
          fontFamily="NunitoSans-SemiBold, Nunito Sans"
          fontSize={12}
          fontWeight={600}
          transform="translate(234.526 236)"
        >
          <tspan x={-107} y={0}>
            {"Applicable days will considered as slots"}
          </tspan>
        </text>
        <text
          fill="#98a0ac"
          data-name="Choose your applicable days"
          fontFamily="NunitoSans-Italic, Nunito Sans"
          fontSize={12}
          fontStyle="italic"
          transform="translate(234.526 272)"
        >
          <tspan x={-76} y={0}>
            {"Choose your applicable days"}
          </tspan>
        </text>
      </g >
    </g >
  </svg >
)
export default AmenityMappingNoSlot
