import * as React from "react"
const PortalUserIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 105298">
      <g data-name="Group 105332">
        <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 55483" />
        <path
          fill="#091b29"
          d="M7.429 1a4.286 4.286 0 1 0 4.286 4.286A4.3 4.3 0 0 0 7.429 1Zm0 1.286a3 3 0 1 1-3 3 2.99 2.99 0 0 1 3-3Zm7.286 7.286a4.416 4.416 0 0 0-.738.062l-.148.614a1.714 1.714 0 0 1-2.152 1.242l-.516-.153a4.758 4.758 0 0 0-.731 1.346l.383.368a1.715 1.715 0 0 1 0 2.471l-.383.368a4.753 4.753 0 0 0 .731 1.346l.516-.153a1.714 1.714 0 0 1 2.152 1.242l.148.614a4.432 4.432 0 0 0 1.477 0l.148-.614a1.714 1.714 0 0 1 2.152-1.242l.516.153A4.758 4.758 0 0 0 19 15.89l-.383-.368a1.715 1.715 0 0 1 0-2.471l.383-.369a4.753 4.753 0 0 0-.731-1.346l-.516.153a1.714 1.714 0 0 1-2.153-1.242l-.148-.614a4.416 4.416 0 0 0-.738-.062ZM2.6 11.286A1.655 1.655 0 0 0 1 12.991v.652a3.981 3.981 0 0 0 2.047 3.44 8.055 8.055 0 0 0 4.168 1.06 8.777 8.777 0 0 0 3.044-.523 5.562 5.562 0 0 1-.659-1.126 7.8 7.8 0 0 1-2.386.363c-2.374 0-4.929-1.006-4.929-3.214v-.652c0-.228.142-.419.31-.419h6.819a5.525 5.525 0 0 1 .61-1.286ZM14.714 13a1.286 1.286 0 1 1-1.286 1.286A1.286 1.286 0 0 1 14.714 13Z"
        />
      </g>
    </g>
  </svg>
)
export default PortalUserIcon
