import * as React from "react"

export const KYC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 102062" transform="translate(-121 -201)">
      <circle
        data-name="Ellipse 129431"
        cx={20}
        cy={20}
        r={20}
        transform="translate(121 201)"
        fill="#edf7f8"
      />
      <g data-name="Group 103256">
        <path data-name="Rectangle 55102" fill="none" d="M131 211h20v20h-20z" />
        <path
          d="M148.071 215.571h-4.928v.857a1.284 1.284 0 0 1-1.286 1.286h-1.714a1.284 1.284 0 0 1-1.286-1.285v-.858h-4.928A1.929 1.929 0 0 0 132 217.5v9a1.931 1.931 0 0 0 1.929 1.929h14.142A1.929 1.929 0 0 0 150 226.5v-9a1.929 1.929 0 0 0-1.929-1.929Zm-11.357 3.858a1.286 1.286 0 1 1-1.286 1.286 1.287 1.287 0 0 1 1.286-1.286Zm2.143 4.5-.009.09-.021.133c-.193.866-.973 1.281-2.113 1.281-1.2 0-2-.459-2.134-1.414l-.009-.094v-.511a.643.643 0 0 1 .639-.557h3.086a.643.643 0 0 1 .557.639Zm8.014.639h-4.461a.643.643 0 0 1 0-1.273l.09-.009h4.281l.086.009a.642.642 0 0 1 0 1.273Zm0-3H142.414a.645.645 0 0 1 0-1.277h4.457a.645.645 0 0 1 0 1.277ZM141 213a2.143 2.143 0 0 0-2.143 2.143v.429h4.286v-.429A2.143 2.143 0 0 0 141 213Z"
          fill="#6dafb3"
        />
      </g>
    </g>
  </svg>
)
