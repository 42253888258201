import React from "react";

export const AgreementValue = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <g id="Group_98671" data-name="Group 98671" transform="translate(-256 -84)">
    <g id="Group_95678" data-name="Group 95678" transform="translate(-284)">
      <rect id="Rectangle_49614" data-name="Rectangle 49614" width="50" height="50" rx="25" transform="translate(540 84)" fill="#bdf2d2"/>
    </g>
    <g id="icons8-ticket" transform="translate(272.361 98.742)">
      <path id="Path_2698" data-name="Path 2698" d="M5.888,7.819H16.146" transform="translate(-2.289 3.578)" fill="#4acc7e"/>
      <path id="Path_2699" data-name="Path 2699" d="M5.888,7.819H16.146" transform="translate(-2.289 6.998)" fill="#4acc7e"/>
      <path id="Path_2697" data-name="Path 2697" d="M5.888,7.819H16.146" transform="translate(-2.417 -0.503)" fill="#4acc7e"/>
      <path id="icons8-bill_1_" data-name="icons8-bill (1)" d="M8.825,5A.81.81,0,0,0,8,5.81V22.546a2.982,2.982,0,0,0,2.969,2.969H22.307a2.982,2.982,0,0,0,2.969-2.969V5.81a.81.81,0,0,0-1.139-.74l-2.117.942-2.382-.953a.81.81,0,0,0-.6,0l-2.4.96-2.4-.96a.81.81,0,0,0-.6,0l-2.382.953L9.139,5.069A.81.81,0,0,0,8.825,5Zm2.685,6.478H21.767a.81.81,0,0,1,0,1.62H11.509a.81.81,0,0,1,0-1.62Zm0,4.859h5.939a.81.81,0,0,1,0,1.62H11.509a.81.81,0,0,1,0-1.62Zm9.178,0h1.08a.81.81,0,0,1,0,1.62h-1.08a.81.81,0,1,1,0-1.62Zm-9.178,3.239h5.939a.81.81,0,0,1,0,1.62H11.509a.81.81,0,1,1,0-1.62Zm9.178,0h1.08a.81.81,0,1,1,0,1.62h-1.08a.81.81,0,1,1,0-1.62Z" transform="translate(-8 -5)" fill="#4acc7e"/>
    </g>
  </g>
</svg>

)