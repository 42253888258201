import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import CameraIcon from "../utils/cameraIcon";
import { ProfileCreateEditStyle } from "./style";
import { AlertContext, BackdropContext } from "../../../contexts";
import { AlertProps, allowed_file_size, assestType, singleFileUpload } from "../../../utils";


export const ProfileCreateEdit = ({
    company = 1,
    onChange = () => false, value = null, isError = false, errorMessage = "", isReadOnly = false , is_file=false
}) => {
    const classes = ProfileCreateEditStyle()
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);

    const upload = async (data) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        try {
            const uploaded_file = await singleFileUpload(data, { companyId: company, type: assestType?.Thumbnails }, alert, allowed_file_size)
            if (uploaded_file?.[0]?.url) {
                onChange(uploaded_file?.[0]?.url)
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            }
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
        }
        catch (err) {
            console.log(err, 'err')
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: `Some Thing Went Wrong.`,
            });
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
        }
    }
    return (
        <>
            <Box className={classes.profileBox}>
                <Avatar className={classes.avatar} src={value}></Avatar>
                <label>
                    <Box className={classes.cameraBox}>
                    <CameraIcon />
                   </Box>
                    {
                        !isReadOnly &&
                        <input type='file' style={{ display: 'none' }} accept="image/*"
                            onClick={(event) => {
                                event.target.value = null
                            }}
                            onChange={(e) => is_file ? onChange(e?.target?.files) : upload(e?.target?.files?.[0])} />
                    }
                </label>
            </Box>
            {isError && (
                <Typography variant={"caption"} color={"error"}>
                    {errorMessage}
                </Typography>
            )}
        </>
    )
}