import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import { config } from '../../config';

const AnyReactComponent = () => <img height={60} width={60} src='/images/Group_4268.svg' alt='marker' />;
const getMapOptions = () => {
  return {
    fullscreenControl: true,
    zoomControl: true
  };
};

class SimpleMap extends Component {

  render(props) {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '35vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `${config.googleMapApiKey}` }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={getMapOptions}
        >
          <AnyReactComponent
            lat={this.props.lat}
            lng={this.props.lng}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;