import { Box, Grid } from '@mui/material'
import React from 'react'
import { CardDetails } from '../propertymanagerdashboard/components'
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent"
import { DialogDrawer, Subheader } from '../../components'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import { NetWorkCallMethods, accessCheckRender, enumSelect, enum_types, getRoutePermissionNew, pageName } from '../../utils'
import moment from 'moment'
import { AuthContext } from '../../contexts'

export const ListingDashBoard = (props) => {
    const [zoom, setZoom] = React.useState(false);
    const [zoomGraph, setZoomGraph] = React.useState("");
    const [dashBoardData, setDashboradData] = React.useState({})
    const [enumValue, setEnumValue] = React.useState({})
    const [selected, setSelected] = React.useState({
        listingUnitValue: enumValue?.purpose_global_type?.[0],
        companyBasedLeadYear: { label: moment(new Date()).format("YYYY"), value: moment(new Date()).format("YYYY") },
        leadTrendvalue: { label: moment(new Date()).format("YYYY"), value: moment(new Date()).format("YYYY") },
    })
    const [years, setYears] = React.useState([])
    const [permission, setPermission] = React.useState({})

    const { loading, handleLoading } = props;

    const auth = React.useContext(AuthContext)

    const zoomGraphFunction = (e) => {
        setZoomGraph(e);
        setZoom(true);
    };

    // close popup
    const handleCloseModule = () => {
        setZoom(false);
    };

    const data = [
        {
            name: 'Jan',
            value1: 4000,
            value2: 2400,
            amount: 2400,
        },
        {
            name: 'Feb',
            value1: 3000,
            value2: 1398,
            amount: 2210,
        },
        {
            name: 'Mar',
            value1: 2000,
            value2: 9800,
            amount: 2290,
        },
        {
            name: 'Apr',
            value1: 2780,
            value2: 3908,
            amount: 2000,
        },
        {
            name: 'May',
            value1: 1890,
            value2: 4800,
            amount: 2181,
        },
        {
            name: 'Jun',
            value1: 2390,
            value2: 3800,
            amount: 2500,
        },
        {
            name: 'Jul',
            value1: 3490,
            value2: 4300,
            amount: 2100,
        },
        {
            name: 'Aug',
            value1: 2000,
            value2: 9800,
            amount: 2290,
        },
        {
            name: 'Sep',
            value1: 2780,
            value2: 3908,
            amount: 2000,
        },
        {
            name: 'Oct',
            value1: 1890,
            value2: 4800,
            amount: 2181,
        },
        {
            name: 'Nov',
            value1: 2390,
            value2: 3800,
            amount: 2500,
        },
        {
            name: 'Dec',
            value1: 3490,
            value2: 4300,
            amount: 2100,
        },
    ];

    const newBarData = [
        {
            name: "Jan",
            value: 500,
            fill: "#0980d1"
        },
        {
            name: "Feb",
            value: 557,
            fill: "#56b701"
        },
        {
            name: "Mar",
            value: 233,
            fill: "#992a31"
        },
        {
            name: "Apr",
            value: 1514,
            fill: "#36f99b"
        },
        {
            name: "May",
            value: 22,
            fill: "#c5d26a"
        },
        {
            name: "Jun",
            value: 5677,
            fill: "#80737e"
        },
        {
            name: "Jul",
            value: 9909,
            fill: "#194208"
        },
        {
            name: "Aug",
            value: 120,
            fill: "#4aa243"
        },
        {
            name: "Sep",
            value: 188,
            fill: "#163552"
        },
        {
            name: "Nov",
            value: 888,
            fill: "#1e0b84"
        },
        {
            name: "Dec",
            value: 346,
            fill: "#4c74ac"
        },
    ]


    const pieData = [
        {
            "name": "Letters Acknowledged",
            "fill": "#FF9340",
            "count": 8
        },
        {
            "name": "Letters Sent",
            "fill": "#57CDDF",
            "count": 15
        },
        {
            "name": "Letters Replied",
            "fill": "#5AC782",
            "count": 4
        }
    ]

    React.useEffect(() => {

        const _ = getRoutePermissionNew(auth)
        if (_) {
            setPermission(_)
            if (_?.read) {
                getEnum()
                getListingDashboard()
                getYearList()
                handleLoading(false)
            }
        }
        // eslint-disable-next-line
    }, [])


    const getListingDashboard = () => {

        const payload = {}

        NetworkCall(
            `${config.api_url}/listing_manager/`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const leadCard1 = [
                    {
                        "count": res?.data?.data?.active_leads_count,
                        "image": "/images/activeLead.svg",
                        "sub": "Active leads",
                        "image_type": true
                    },
                    {
                        "count": res?.data?.data?.converted_leads_count,
                        "image": "/images/propertdashboard/block.svg",
                        "sub": "Converted Leads",
                        "image_type": true
                    }
                ]

                const leadCard2 = [
                    {
                        "count": res?.data?.data?.public_unit_count,
                        "image": "/images/publicPic.svg",
                        "sub": "Public",
                        "image_type": true
                    },
                    {
                        "count": res?.data?.data?.priavte_unit_count,
                        "image": "/images/privatePic.svg",
                        "sub": "Private",
                        "image_type": true
                    }
                ]
                setDashboradData({ ...dashBoardData, cardData1: leadCard1, cardData2: leadCard2 })
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const getEnum = async () => {
        const result = await enumSelect([enum_types.purpose_global_type])
        setEnumValue({
            purpose_global_type: result?.purpose_global_type
        })
    }

    const handleListingUnit = (value) => {
        setSelected({ ...selected, listingUnitValue: value })
    }

    const getYearList = (startYear) => {
        var currentYear = new Date().getFullYear() + 20, years = [];
        startYear = startYear || 1980;
        while (startYear <= currentYear) {
            years.push(startYear++);
        }

        const yearsData = years?.map((val) => {
            return {
                value: val,
                label: val
            }
        });
        setYears(yearsData)
    }

    const handleChangeLeadTrend = (value) => {
        setSelected({ ...selected, leadTrendvalue: value })
    }

    const handleChangeCompanyBasedLeads = (value) => {
        setSelected({ ...selected, companyBasedLeadYear: value })
    }

    const render = () => {
        return (
            <>
                <Subheader title="Listing Dashboard" hideBackButton />
                <Box p={2} sx={{ overflow: "auto", height: "calc(100vh - 119px)" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={4} lg={1.5} sx={{ display: "flex" }}>
                            <Grid container spacing={2}>
                                {dashBoardData?.cardData1?.map((x) => {
                                    return (
                                        <Grid item xs={12}>
                                            <CardDetails data={x} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={4.5}>
                            <GraphComponent
                                title={"Lead Source"}
                                graphData={pieData ?? []}
                                isPie
                                innerRadius={75}
                                paddingAngle={2}
                                isTotal
                                total={10}
                                margin={"30px"}
                                minWidth={true}
                                isZoom
                                onZoomClick={zoomGraphFunction}
                                textMargin={'0px 0px 0px'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12
                                    }
                                }
                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    top: {
                                        lg: "auto",
                                        md: '0px',
                                        sm: "3px",
                                    },
                                    height: {
                                        md: "400px"
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={1.5} sx={{ display: "flex" }}>
                            <Grid container spacing={2}>
                                {dashBoardData?.cardData2?.map((x) => {
                                    return (
                                        <Grid item xs={12}>
                                            <CardDetails data={x} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={4.5}>
                            <GraphComponent
                                title={"Listing Units"}
                                isProgressChart
                                margin={"50px"}
                                select
                                total={70}
                                padding={"8px 16px"}
                                selectValue={selected?.listingUnitValue}
                                options={enumValue?.purpose_global_type}
                                handleChange={handleListingUnit}
                                isZoom
                                isProgress
                                onZoomClick={zoomGraphFunction}
                                subTitle={"Residential"}
                                size={30}
                                alignItems={'center'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 8,
                                        lg: 6
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 4,
                                        lg: 6
                                    }
                                }
                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    top: {
                                        lg: "auto",
                                        md: '0px',
                                        sm: "3px",
                                    },
                                    height: {
                                        md: "400px"
                                    }
                                }}
                                align={"center"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>

                            <GraphComponent
                                title={"Lead Trend"}
                                graphData={data ?? []}
                                isLineChart
                                key1={"value1"}
                                key2={"value2"}
                                margin={"12px"}
                                select
                                // isSale
                                selectValue={selected?.leadTrendvalue}
                                options={years}
                                handleChange={handleChangeLeadTrend}
                                isZoom
                                onZoomClick={zoomGraphFunction}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <GraphComponent
                                title={"Company Based Leads"}
                                graphData={newBarData ?? []}
                                newBarChart
                                margin={"12px"}
                                select
                                // isSale
                                selectValue={selected?.companyBasedLeadYear}
                                options={years}
                                handleChange={handleChangeCompanyBasedLeads}
                                isZoom
                                onZoomClick={zoomGraphFunction}
                                gheight={300}
                            />
                        </Grid>
                    </Grid>

                    {/* zoom dialog for each graph */}
                    <DialogDrawer
                        header={zoomGraph}
                        maxWidth={"sm"}
                        handleClose={handleCloseModule}
                        open={zoom}
                        height={"auto"}
                        borderRadius={"12px"}
                        padding={'0px'}
                        onClose={handleCloseModule}
                        component={
                            <Box>
                                {
                                    zoomGraph === "Lead Trend" &&
                                    <GraphComponent
                                        graphData={data ?? []}
                                        isLineChart
                                        key1={"value1"}
                                        key2={"value2"}
                                        // isZoom
                                        // onZoomClick={zoomGraphFunction}
                                        margin={"0px"}
                                        padding={"16px"}
                                        maxHeightGraph={"250px"}
                                        minWidth={false}
                                        height={"auto"}
                                        is_popUp
                                        justifyContent={'start'}
                                        divider1={
                                            {
                                                xs: 12,
                                                sm: 12,
                                                md: 12,
                                                lg: 12
                                            }
                                        }
                                        divider={
                                            {
                                                xs: 12,
                                                sm: 12,
                                                md: 12,
                                                lg: 12
                                            }
                                        }

                                        gradiantStyle={{
                                            margin: '0px 0px 0px',
                                            display: "flex",
                                            alignItems: "center",
                                            grid_template: "100%",
                                            maxHeight: "220px",
                                            top: {
                                                lg: "auto",
                                                md: '-8px',
                                                sm: "auto",
                                            },
                                            height: {
                                                md: "220px",
                                                sm: "220px",
                                                lg: "100%"
                                            }
                                        }}

                                    />
                                }
                                {
                                    zoomGraph === "Listing Units" &&
                                    <GraphComponent
                                        isProgressChart
                                        margin={"12px"}
                                        // select
                                        total={70}
                                        // isSale
                                        // selectValue={selectedValue?.lead}
                                        // options={options}
                                        // handleChange={handleChangeLeadCount}
                                        // isZoom
                                        isProgress
                                        onZoomClick={zoomGraphFunction}
                                        subTitle={"Residential"}
                                        is_popUp
                                        justifyContent={'start'}
                                        divider1={
                                            {
                                                xs: 4,
                                                sm: 8,
                                                md: 8,
                                                lg: 8
                                            }
                                        }
                                        divider={
                                            {
                                                xs: 8,
                                                sm: 4,
                                                md: 4,
                                                lg: 4
                                            }
                                        }

                                        gradiantStyle={{
                                            margin: '0px 0px 0px',
                                            display: "flex",
                                            alignItems: "center",
                                            grid_template: "100%",
                                            maxHeight: "220px",
                                            top: {
                                                lg: "auto",
                                                md: '-8px',
                                                sm: "auto",
                                            },
                                            height: {
                                                md: "220px",
                                                sm: "220px",
                                                lg: "100%"
                                            }
                                        }}
                                    />
                                }
                                {
                                    zoomGraph === "Company Based Leads" &&
                                    <GraphComponent
                                        graphData={newBarData ?? []}
                                        newBarChart
                                        margin={"12px"}
                                        // select
                                        // isSale
                                        // selectValue={selectedValue?.lead}
                                        // options={options}
                                        // handleChange={handleChangeLeadCount}
                                        // isZoom
                                        onZoomClick={zoomGraphFunction}
                                        gheight={300}
                                    />
                                }
                                {
                                    zoomGraph === "Lead Source" &&
                                    <GraphComponent
                                        graphData={pieData ?? []}
                                        isPie
                                        total={10}
                                        isTotal
                                        isSale
                                        innerRadius={70}
                                        margin={"0px"}
                                        padding={"16px"}
                                        maxHeightGraph={"250px"}
                                        minWidth={false}
                                        height={"auto"}
                                        is_popUp
                                        justifyContent={'start'}
                                        divider1={
                                            {
                                                xs: 12,
                                                sm: 7.4,
                                                md: 7.4,
                                                lg: 7.4
                                            }
                                        }
                                        divider={
                                            {
                                                xs: 12,
                                                sm: 4.6,
                                                md: 4.6,
                                                lg: 4.6
                                            }
                                        }

                                        gradiantStyle={{
                                            margin: '0px 0px 0px',
                                            display: "flex",
                                            alignItems: "center",
                                            grid_template: "100%",
                                            maxHeight: "220px",
                                            top: {
                                                lg: "auto",
                                                md: '-8px',
                                                sm: "auto",
                                            },
                                            height: {
                                                md: "220px",
                                                sm: "220px",
                                                lg: "100%"
                                            }
                                        }}
                                    />
                                }
                            </Box>
                        }
                    />
                </Box>
            </>
        )
    }

    return (
        <Box>
            {accessCheckRender(render, permission, pageName.dashboard, loading)}
        </Box>
    )
}