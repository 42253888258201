import * as React from "react"

const CalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="icons8-calendar (4)">
      <g data-name="Path 97609" fill="#fff">
        <path d="M14.316 15.5H1.684C1.031 15.5.5 14.988.5 14.36V5.012h15v9.346c0 .63-.531 1.141-1.184 1.141Z" />
        <path
          d="M14.316 15c.37 0 .684-.293.684-.64V5.512H1v8.846c0 .348.313.641.684.641h12.632m0 1H1.684C.758 16 0 15.262 0 14.36V4.512h16v9.846c0 .903-.758 1.641-1.684 1.641Z"
          fill="#6dafb3"
        />
      </g>
      <path
        data-name="Path 97610"
        d="M16 2.872v2.459H0V2.872a1.668 1.668 0 0 1 1.684-1.641h12.632A1.668 1.668 0 0 1 16 2.872Z"
        fill="#6dafb3"
      />
      <path
        data-name="Path 97611"
        d="M11.789 1.641a1.231 1.231 0 1 0 1.263 1.231 1.247 1.247 0 0 0-1.263-1.231Zm-7.579 0a1.231 1.231 0 1 0 1.263 1.231A1.247 1.247 0 0 0 4.21 1.641Z"
        fill="#2d6e72"
      />
      <path
        data-name="Path 97612"
        d="M11.789 0a.834.834 0 0 0-.842.821v2.051a.842.842 0 0 0 1.684 0V.821A.834.834 0 0 0 11.789 0ZM4.21 0a.834.834 0 0 0-.842.821v2.051a.842.842 0 0 0 1.684 0V.821A.834.834 0 0 0 4.21 0Z"
        fill="#b0bec5"
      />
      <g data-name="Group 104572">
        <path
          data-name="Path 97613"
          d="M3.368 6.974h1.684v1.641H3.368Zm2.526 0h1.684v1.641H5.894Zm2.526 0h1.684v1.641H8.421Zm2.526 0h1.684v1.641h-1.683ZM3.368 9.436h1.684v1.638H3.368Zm2.526 0h1.684v1.638H5.894Zm2.526 0h1.684v1.638H8.421Zm2.526 0h1.684v1.638h-1.683Zm-7.578 2.461h1.684v1.641H3.368Zm2.526 0h1.684v1.641H5.894Zm2.526 0h1.684v1.641H8.421Zm2.526 0h1.684v1.641h-1.683Z"
          fill="#90a4ae"
        />
      </g>
    </g>
  </svg>
)

export default CalendarIcon
