export const Bookings = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105172">
            <g
                data-name="LS - Resource Management - Bookings"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 98222"
                    d="M19.529 7H9.5a3.5 3.5 0 0 0-.021 7h1.575v-2.739a2.231 2.231 0 0 1 2.229-2.229 2.232 2.232 0 0 1 2.224 2.229V14h4.017a3.5 3.5 0 0 0 0-7Zm.991 3.014-2 2a.5.5 0 0 1-.706 0l-1-1a.5.5 0 0 1 .706-.706l.645.645 1.643-1.643a.5.5 0 1 1 .706.706Z"
                />
                <path
                    data-name="Path 98223"
                    d="m17.726 15.409-3.212-.334v-3.814a1.231 1.231 0 1 0-2.462 0v6.388h-.011l-1.215-1.015a1.184 1.184 0 1 0-1.517 1.815l2.466 2.058h7.263v-3.611a1.5 1.5 0 0 0-1.312-1.487Z"
                />
                <path
                    data-name="Path 98224"
                    d="M12.052 22.503a.5.5 0 0 0 .5.5h5.988a.5.5 0 0 0 .5-.5v-1h-6.988Z"
                />
            </g>
        </g>
    </svg>
)