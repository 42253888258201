export const Request = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <path
            data-name="Path 93625"
            d="M22.626 10.243a4.009 4.009 0 0 0-2.968-2.856 30.939 30.939 0 0 0-9.315 0 4.009 4.009 0 0 0-2.968 2.856 16.785 16.785 0 0 0-.341 3.465 16.782 16.782 0 0 0 .341 3.465 4.009 4.009 0 0 0 2.968 2.856 22.454 22.454 0 0 0 2.277.261q.162.254.352.541c.506.766.893 1.3 1.186 1.671a1 1 0 0 0 1.686 0c.293-.372.68-.906 1.186-1.671.127-.192.243-.372.352-.541a22.474 22.474 0 0 0 2.277-.261 4.009 4.009 0 0 0 2.968-2.856 16.782 16.782 0 0 0 .341-3.465 16.782 16.782 0 0 0-.341-3.465Zm-6.746 8.092a.513.513 0 0 1-.395.395 2.948 2.948 0 0 1-.485.037 2.886 2.886 0 0 1-.485-.037.513.513 0 0 1-.395-.395 2.948 2.948 0 0 1-.037-.485 2.886 2.886 0 0 1 .037-.485.513.513 0 0 1 .395-.395 2.949 2.949 0 0 1 .485-.035 2.886 2.886 0 0 1 .485.037.513.513 0 0 1 .395.395 2.948 2.948 0 0 1 .037.485 2.886 2.886 0 0 1-.037.483Zm1.894-5.1a3.7 3.7 0 0 1-1.133.976c-.674.416-.782.587-.782.921v.132a.86.86 0 0 1-1.72 0v-.305a1.742 1.742 0 0 1 .3-1.023 2.845 2.845 0 0 1 .929-.8c.806-.474.953-.74.953-1.093 0-.444-.378-.731-.963-.731a1.176 1.176 0 0 0-1.24.866.91.91 0 0 1-.873.634h-.067a.916.916 0 0 1-.7-.4.907.907 0 0 1-.114-.788 2.788 2.788 0 0 1 1.091-1.476 3.361 3.361 0 0 1 1.9-.543 3.089 3.089 0 0 1 1.965.676 2.253 2.253 0 0 1 .822 1.741 2.033 2.033 0 0 1-.368 1.213Z"
            fill={props?.fill ?? "#c1c5cb"}
        />
        <path data-name="Rectangle 52035" fill="none" d="M0 0h30v30H0z" />
    </svg>
)
