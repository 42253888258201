export const AmenitiesBooking = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Amenties Booking">
            <g data-name="Layer 2" fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 93637"
                    d="M22.813 11.651V9.977a1.674 1.674 0 0 0-1.674-1.674h-2.233v-.558a.558.558 0 1 0-1.116 0v.558h-5.58v-.558a.558.558 0 0 0-1.116 0v.558H8.861a1.674 1.674 0 0 0-1.674 1.674v1.674Z"
                />
                <path
                    data-name="Path 93638"
                    d="M7.187 12.768v8.371a1.674 1.674 0 0 0 1.674 1.674h12.278a1.674 1.674 0 0 0 1.674-1.674v-8.371Zm10.966 3.214-3.907 3.348a.558.558 0 0 1-.757-.03l-1.674-1.674a.558.558 0 1 1 .789-.789l1.309 1.309 3.516-3.014a.558.558 0 1 1 .725.848Z"
                />
            </g>
            <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
