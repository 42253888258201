/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
import React, { createContext } from "react";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { enumSelect, enum_types, LocalStorageKeys, NetWorkCallMethods, AlertProps } from "../../../utils";
import uuid from 'uuid'
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../../router/routes";
import { format } from "date-fns";
import { AlertContext } from "../../../contexts";
// import { AlertContext } from "../../../contexts";

export const CreateEditContext = createContext();

const initialState = {
    startEndDate: {},
    account: {},
    rateConfiguration: {},
    contractDetail: "",
    termsAndCondition: ""
}

const CreateEditContextProvider = (props) => {

    const [contractData, setContractData] = React.useState({ ...initialState })
    const [enumValue, setEnumValue] = React.useState({
        rate_config: []
    })
    const [selected, setSelected] = React.useState({
        type3: false,
        type4: false,
        type5: false,
        type6: false
    })
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)
    const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

    const state = useLocation()?.state

    const history = useHistory()

    const alert = React.useContext(AlertContext);

    // update state

    const updateState = (key, value) => {
        setContractData({ ...contractData, [key]: value })
    }
    // useEffect

    React.useEffect(() => {
        getEnum()
        // eslint-disable-next-line 
    }, [])
    React.useEffect(() => {
        if (Object.keys(state?.viewData)?.length > 0) {
            const data = state?.viewData

            const constructedAccount = {
                id: data?.account?.id,
                account_no: data?.account?.account_no,
                name: data?.account?.account_contact?.first_name,
                email_id: data?.account?.account_contact?.email_id,
                mobile_no: data?.account?.account_contact?.mobile_no,
                mobile_no_country_code: data?.account?.account_contact?.mobile_no_country_code,
                logo: null,
                street_1: null,
                street_2: null,
                city: null,
                landmark: null,
                door_no: null,
                state: null,
                country: null
            }
            setContractData(
                {
                    ...contractData,
                    startEndDate: { startDate: new Date(data?.start_date), endDate: new Date(data?.end_date) },
                    account: constructedAccount,
                    contractDetail: data?.contract_details,
                    termsAndCondition: data?.term_condition,
                    id: data?.id
                }
            )

            const constructedTodoList = data?.scope?.map((data) => {
                return {
                    rowid: uuid.v4(),
                    id: data?.id,
                    property: {
                        property_address: {
                            city: data?.property?.address?.city,
                            district: data?.property?.address?.district,
                            state: data?.property?.address?.state,
                            country: data?.property?.address?.country
                        },
                        property_assets: [],
                        property_id: data?.property?.id,
                        property_logo: data?.property?.logo ?? null,
                        property_name: data?.property?.name,
                    },
                    unit: {
                        address: {
                            city: data?.unit?.address?.city,
                            district: data?.unit?.address?.district,
                            state: data?.unit?.address?.state,
                            country: data?.unit?.address?.country
                        },
                        unit_assets: [],
                        unit_id: data?.unit?.id,
                        unit_logo: data?.unit?.logo ?? null,
                        unit_name: data?.unit?.name,
                    }
                }
            })
            setTodoList(constructedTodoList)

            setScopeCount(data?.scope?.length)

            let constructedCommissionList = state?.viewData?.commission?.map((data) => {
                return {
                    rowid: uuid.v4(),
                    pricingFactor: { label: data?.commission_config?.name, value: data?.commission_config?.id },
                    pricingFactorAmount: data?.value,
                    pricingComponent: { label: data?.pricing_component?.name, value: data?.pricing_component?.id },
                    id: data?.id,
                    error: {
                        pricingFactor: "",
                        pricingFactorAmount: "",
                        pricingComponent: "",
                    }
                }
            })

            setCommissionList(constructedCommissionList)
            
        }
        if(state?.type === "edit"){
            setScopeStatus(true)
        }
        // eslint-disable-next-line 
    }, [state])

    // get enum

    const getEnum = async () => {
        const result = await enumSelect([enum_types?.contract_commission_rate_type]);
        setEnumValue({ rate_config: result?.contract_commission_rate_type });
    };


    // Choose account

    const [ownerPopup, setOwnerPopup] = React.useState(false)
    const [ownerList, setOwnerList] = React.useState([])
    const [ownerLoading, setOwnerLoading] = React.useState(false)

    const getOwnerList = (offset = 0, search = "", addMore) => {
        if (addMore) {
            setOwnerLoading(true)
        }
        const payload = {
            offset: offset,
            limit: 10,
            search: search,
            type: ["Property Owner"], // only fetch owner account
            company_id: state?.selectedCompany?.value
        }
        NetworkCall(
            `${config.api_url}/account/get_all`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (addMore) {
                setOwnerList(res?.data?.data?.list)
                setOwnerLoading(false)
            } else {
                setOwnerList(ownerList.concat(res?.data?.data?.list));
                setOwnerLoading(false)
            }

        }).catch((err) => {
            console.log(err)
        })
    }



    // Scope section
    const [scopeCount, setScopeCount] = React.useState(1)
    const [propertyPopup, setPropertyPopup] = React.useState(false)
    const [unitPopup, setUnitPopup] = React.useState(false)
    const [propertyList, setPropertyList] = React.useState([])
    const [unitList, setUnitList] = React.useState([])
    const [propertyLoading, setProppertyLoading] = React.useState(false)
    const [selectedTodo, setSelectedTodo] = React.useState({})
    const [unitLoading, setunitLoading] = React.useState(false)
    const [delectedScope, setDeletedScope] = React.useState([])
    const [scopeStatus, setScopeStatus] = React.useState(false)

    const initialTodoState = {
        rowid: uuid.v4(),
        property: {},
        unit: {}
    }

    const [todoList, setTodoList] = React.useState([
        { ...initialTodoState }
    ])


    const addTodo = () => {
        setTodoList([...todoList, {
            rowid: uuid.v4(),
            property: {},
            unit: {}
        }])
    }

    const removeTodoList = (data) => {
        if (data?.id) {
            setDeletedScope([...delectedScope, data?.id])
        }
        setScopeCount(scopeCount - 1)
        setTodoList(todoList?.filter?.(item => item?.rowid !== data.rowid))
    }

    const handleIncreaseScope = (value) => {
        // if (value === 1) {
        setScopeCount(value)
        addTodo()
        // }
        // else {
        //     if (Object.keys(todoList?.[value - 2]?.property)?.length !== 0 || Object.keys(todoList?.[value - 2]?.unit)?.length !== 0) {
        //         setScopeCount(value)
        //         addTodo()
        //     }
        // }
    }

    const handleDecreaseScope = (value) => {
        // if (Object.keys(todoList?.[value]?.property)?.length === 0 && Object.keys(todoList?.[value]?.unit)?.length === 0) {
        setTodoList(todoList.filter((i, index) => index !== todoList?.length - 1))
        setDeletedScope([...delectedScope, todoList?.[value - 1]?.id])
        setScopeCount(value)
        // }
    }

    const getPropertyList = (offset = 0, search = "", addMore) => {
        if (addMore) {
            setProppertyLoading(true)
        }

        const payload = {
            limit: 10,
            offset: offset,
            search: search,
            selected_units: [],
            company_id: state?.selectedCompany?.value
        }
        NetworkCall(
            `${config.api_url}/contract/property_scope`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (addMore) {
                setPropertyList(res?.data?.data?.data)
            } else {
                setPropertyList(propertyList.concat(res?.data?.data?.data));
            }
            setProppertyLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }


    const getUnitList = (offset = 0, search = "", addMore) => {
        if (addMore) {
            setunitLoading(true)
        }

        let selectedUnit = []
        if (todoList?.length > 1) {
            todoList?.map((d) => {
                if (Object.keys(d?.unit)?.length > 0) {
                    selectedUnit.push(d?.unit?.unit_id)
                }
            })
        }

        const payload = {
            limit: 10,
            offset: offset,
            search: search,
            selected_units: selectedUnit,
            company_id: state?.selectedCompany?.value,
            property_id: selectedTodo?.property?.property_id,
            owner_id: contractData?.account?.id,
            current_date: format(new Date(), "yyyy-MM-dd")
        }
        NetworkCall(
            `${config.api_url}/contract/unit_scope`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (addMore) {
                setUnitList(res?.data?.data?.data)
            } else {
                setUnitList(unitList.concat(res?.data?.data?.data));
            }
            setunitLoading(false)
        }).catch((err) => {
            console.log(err)
        })
    }

    const updateTodoState = (type, value) => {
        let tempArray = []
        todoList?.map((d) => {
            if (d?.rowid === selectedTodo?.rowid) {
                if (type === "property") {
                    tempArray.push({ ...selectedTodo, "property": value, unit: {} })
                } else {
                    tempArray.push({ ...selectedTodo, "unit": value })
                }
            }
            else {
                tempArray.push(d)
            }
        })
        setTodoList(tempArray)
    }

    const removeAllScope = () => {
        setScopeCount(0)
        setTodoList([])
    }

    // switch button condition to hide show ui

    const [selectedSection, setSelectedSection] = React.useState({})



    // commission section

    const initialCommissionState = {
        rowid: uuid.v4(),
        pricingFactor: "",
        pricingFactorAmount: "",
        pricingComponent: "",
        error: {
            pricingFactor: "",
            pricingFactorAmount: "",
            pricingComponent: "",
        }
    }

    const [commissionList, setCommissionList] = React.useState([
        { ...initialCommissionState }
    ])

    const [selectedCommissionEnum, setSelectedCommissionEnum] = React.useState(enumValue?.rate_config?.[0]?.value)

    const validate = () => {
        let isValid = true

        // scope of work 
        // scope condition currently no need

        // if (todoList?.length === 0) {
        //     alert.setSnack({
        //         ...alert,
        //         open: true,
        //         severity: AlertProps.severity.error,
        //         msg: "Please add Atlease one Scope Work",
        //     });
        //     return false
        // }

        // todoList?.map((i) => {
        //     if (Object.keys(i?.property)?.length === 0 && Object.keys(i?.unit)?.length === 0) {
        //         isValid = false
        //         alert.setSnack({
        //             ...alert,
        //             open: true,
        //             severity: AlertProps.severity.error,
        //             msg: "Please Fill Scope Work",
        //         });
        //     }
        // })

        if (Object.keys(contractData?.startEndDate)?.length === 0) {
            isValid = false
            alert.setSnack({
                ...alert,
                open: true, msg: "Please add contract start & end date",
                severity: AlertProps.severity.error
            })
        } else if (Object.keys(contractData?.account)?.length === 0) {
            isValid = false
            alert.setSnack({
                ...alert,
                open: true, msg: "Please add account",
                severity: AlertProps.severity.error
            })
        } else if (!scopeStatus) {
            isValid = false
            alert.setSnack({
                ...alert,
                open: true, msg: "Please add scope of work",
                severity: AlertProps.severity.error
            })
        }

        if (selected?.type4) {
            if (selectedCommissionEnum === "Custom") {
                return true
            } else {
                let temp_commissionList = commissionList?.map((data, i) => {
                    let error = data?.error

                    if (data?.pricingFactor?.length === 0) {
                        isValid = false;
                        error.pricingFactor = "Pricing Factor is Required"
                    }

                    if (data?.pricingFactorAmount?.length === 0) {
                        isValid = false;
                        error.pricingFactorAmount = "pricing Factor Amount is Required"
                    }
                    if (selectedCommissionEnum === "Percentage Base") {
                        if (data?.pricingComponent?.length === 0) {
                            isValid = false;
                            error.pricingComponent = "Pricing Component is Required"
                        }
                    }

                    return { ...data, error }
                })


                setCommissionList(temp_commissionList)
            }
        } else {
            isValid = false
            alert.setSnack({
                ...alert,
                open: true, msg: "Please add Commission Rate Configuration",
                severity: AlertProps.severity.error
            })
        }

        if (selected?.type5) {
            if (contractData?.contractDetail?.length === 0) {
                isValid = false
                alert.setSnack({
                    ...alert,
                    open: true, msg: "Please add contract detail",
                    severity: AlertProps.severity.error
                })
            }
        } else {
            isValid = false
            alert.setSnack({
                ...alert,
                open: true, msg: "Please add Contract Details",
                severity: AlertProps.severity.error
            })
        }


        if (selected?.type6) {
            if (contractData?.termsAndCondition?.length === 0) {
                isValid = false
                alert.setSnack({
                    ...alert,
                    open: true, msg: "Please add Terms and conditions",
                    severity: AlertProps.severity.error
                })
            }
        } else {
            isValid = false
            alert.setSnack({
                ...alert,
                open: true, msg: "Please add Contract Details",
                severity: AlertProps.severity.error
            })
        }


        return isValid
    }

    const resetCommission = (data) => {
        setSelectedCommissionEnum(data?.value)
        setCommissionList([{ ...initialCommissionState }])
    }

    const addCommission = () => {
        setCommissionList([...commissionList, {
            rowid: uuid.v4(),
            pricingFactor: "",
            pricingFactorAmount: "",
            pricingComponent: "",
            error: initialCommissionState?.error
        }])
    }

    const removeCommissionList = (data) => {
        setCommissionList(commissionList?.filter?.(item => item?.rowid !== data.rowid))
    }


    const updateCommissionState = (key, value, rowData) => {
        let tempArray = []
        commissionList?.map((d) => {
            if (d?.rowid === rowData?.rowid) {
                let error = d?.error
                error[key] = ""
                tempArray.push({ ...d, [key]: value, error })
            }
            else {
                tempArray.push(d)
            }
        })
        setCommissionList(tempArray)
    }

    // Create and Edit and view apis
    const createContract = () => {

        if (validate()) {
            setIsDisableBtn(true)
            const customScope = todoList?.map((e) => {
                return {
                    property_id: e?.property?.property_id,
                    unit_id: e?.unit?.unit_id,
                    id: e?.id ?? undefined
                }
            })

            const customCommission = commissionList?.map((e) => {
                return {
                    commission_rate_id: e?.pricingFactor?.value ?? undefined,
                    currency_id: selectedCompany?.currency_id ?? undefined,
                    commission_rate_type: selectedCommissionEnum ?? undefined,
                    value: Number(e?.pricingFactorAmount) ?? undefined,
                    pricing_component_id: e?.pricingComponent?.value ?? undefined,
                    id: e?.id ?? undefined
                }
            })

            const payload = {
                id: state?.type === "edit" ? contractData?.id : undefined,
                contract_type: state?.contractType?.id,
                start_date: moment(contractData?.startEndDate?.startDate).format("YYYY-MM-DD") ?? undefined,
                end_date: moment(contractData?.startEndDate?.endDate).format("YYYY-MM-DD") ?? undefined,
                account_id: contractData?.account?.id ?? undefined,
                contract_details: contractData?.contractDetail ?? undefined,
                term_condition: contractData?.termsAndCondition ?? undefined,
                company_id: state?.selectedCompany?.value,
                deleted_scopes: delectedScope?.length > 0 ? delectedScope : [],
                scopes: customScope,
                commission: selectedCommissionEnum === "Custom" ? [] : customCommission,
                contract_type_value: state?.viewData?.type?.value
            }

            NetworkCall(
                `${config.api_url}/contract/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                setIsDisableBtn(false)
                history.push(Routes.contract)
            }).catch((err) => {
                console.log(err)
                setIsDisableBtn(false)

            })
        }
    }

    return (
        <CreateEditContext.Provider
            value={{
                scopeCount,
                propertyPopup,
                setPropertyPopup,
                propertyList,
                getPropertyList,
                propertyLoading,
                ownerPopup,
                setOwnerPopup,
                ownerList,
                getOwnerList,
                addTodo,
                todoList,
                updateTodoState,
                setSelectedTodo,
                unitPopup,
                setUnitPopup,
                getUnitList,
                unitList,
                unitLoading,
                selectedTodo,
                updateState,
                selectedSection,
                setSelectedSection,
                selectedCompany,
                enumValue,
                contractData,
                createContract,
                state,
                ownerLoading,
                removeTodoList,
                handleIncreaseScope,
                handleDecreaseScope,
                commissionList,
                addCommission,
                resetCommission,
                removeCommissionList,
                updateCommissionState,
                setSelectedCommissionEnum,
                removeAllScope,
                selectedCommissionEnum,
                setScopeStatus,
                selected,
                setSelected,
                isDisableBtn
            }}
        >
            {props.children}
        </CreateEditContext.Provider>
    );
};

export default CreateEditContextProvider;
