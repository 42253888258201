import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TemplateListIcon } from "../../../assets/templateListIcon";
import { SearchFilter } from "../../../components";
import { useStyles } from "./style";
export const EditInspectionItems = ({ details = "", setData = () => false, data = {}, selectedUnit = {}, selectedInspectionItem = [], updateState = () => false, list = [], fetchMoreData = () => false, handleSearch = () => false, searchText = "", onUpdateItem = () => false }) => {
    const classes = useStyles()
    const onSelectInspectionItem = (val) => {
        let filteredData = []
        let new_item = []
        let old_items_id = data?.old_items?.map((x) => { return x.mapping_id })
        const old_items = data.old_items.map((x) => {
            if (data.selectedInspectionItem.includes(val)) {
                filteredData = data.selectedInspectionItem.filter((x) => { return x !== val })
                if (x.mapping_id === val && x.is_active) {
                    return {
                        name: x.name,
                        activity_id: x.activity_id,
                        mapping_id: x.mapping_id,
                        is_active: false
                    }
                }
                else {
                    return x
                }
            }
            else if (!data.selectedInspectionItem.includes(val)) {
                filteredData = [...data.selectedInspectionItem, val]
                if (x.mapping_id === val && !x.is_active) {
                    return {
                        name: x.name,
                        activity_id: x.activity_id,
                        mapping_id: x.mapping_id,
                        is_active: true
                    }
                }
                else {
                    return x
                }
            }
            return null;
        }
        )
        if (!data.new_items.includes(val)) {
            !old_items_id.includes(val) ?
                new_item = [...data.new_items, val] :
                new_item = data.new_items
        }
        else {
            new_item = data.new_items.filter((x) => { return x !== val })
        }
        updateState("selectedInspectionItem", filteredData, "old_items", old_items, "new_items", new_item)
    }
    const SelectAllItems = () => {
        let old_items_id = data?.old_items?.map((x) => { return x.mapping_id })
        if (data?.selectedInspectionItem?.length === 0) {
            const selectAll = list.map((x) => { return x.id })
            const filteredData = selectAll.filter((x) => !old_items_id.includes(x))
            updateState("selectedInspectionItem", selectAll, "new_items", filteredData)
        }
        else {
            updateState("selectedInspectionItem", [])
        }
    }
    const onUpdate = () => {
        onUpdateItem(data.new_items, data.old_items)
    }
    return (

        <Box p={2}>
            <Box mt={2}>
                <SearchFilter placeholder="Search Inspection Items" value={searchText} handleChange={(value) => handleSearch(value)} />
            </Box>
            <Grid container mt={2}>
                <Grid item xs={5}>
                    <Typography className={classes.listText}>Item lists</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography className={classes.listText} textAlign="center">Serial Number</Typography>
                </Grid>
                <Grid item xs={2} textAlign="end">
                    <Box mt={-1.5}>
                        <Checkbox
                            onChange={() => SelectAllItems()}
                            checked={data?.selectedInspectionItem?.length > 0}
                            icon={<RadioButtonUncheckedIcon sx={{ color: "#E4E8EE" }} />}
                            checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />} />
                    </Box>
                </Grid>
            </Grid>
            <InfiniteScroll
                dataLength={list?.length ?? ""}
                next={fetchMoreData}
                hasMore={true}
                style={{ overflow: "overlay", }}
                height={250}
            >
                {
                    list?.length > 0 ? list?.map((x, i) => {
                        return (
                            <>
                                <Grid container mt={1}>
                                    <Grid item xs={5}>
                                        <Stack direction="row" spacing={1} flexWrap="wrap">
                                            <Box><TemplateListIcon /></Box>
                                            <Box><Typography className={classes.inspectionName}>{x.name}</Typography></Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography className={classes.listsubText} textAlign="center">{x.serial_number ?? "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="end">
                                        <Box mt={-1.5}>
                                            <Checkbox
                                                onChange={() => onSelectInspectionItem(x.id)}
                                                checked={selectedInspectionItem.includes(x.id) ? true : false}
                                                icon={<RadioButtonUncheckedIcon sx={{ color: "#E4E8EE" }} />}
                                                checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />} />
                                        </Box>
                                    </Grid>
                                </Grid>
                                {(list?.length - 1) !== i &&
                                    <Box mt={0.5}>
                                        <Divider className={classes.divStyle} />
                                    </Box>
                                }
                            </>
                        )
                    })
                        :
                        <Box mt={2}>
                            <Typography textAlign={"center"}>No Data Found</Typography>
                        </Box>
                }

            </InfiniteScroll>
            <Box display="flex" mt={2} p={1}>
                <Button variant="contained" fullWidth onClick={() => onUpdate()}>{"Update"}</Button>
            </Box>
        </Box>
    )
}