import React from "react"

export const DisabilityLeaveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_106765" data-name="Group 106765" transform="translate(-3837 23701)">
      <g id="Rectangle_55652" data-name="Rectangle 55652" transform="translate(3837 -23701)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-wheelchair" d="M20.136,6a2.028,2.028,0,1,0,2.029,2.027A2.028,2.028,0,0,0,20.136,6ZM17.473,9.936c-.015,0-.028,0-.044,0-.5.017-2.547.095-3.643.226a1.772,1.772,0,0,0-1.449,1.129l-1.024,2.68a.76.76,0,0,0,.3.905,5.536,5.536,0,0,1,1.238-.656l.912-2.388a.252.252,0,0,1,.15-.139l1.159.387L15,13.869a5.558,5.558,0,0,1,4,1.9l.514-3.028a2.139,2.139,0,0,0,.112-.653v0h0a2.149,2.149,0,0,0-1.811-2.116.68.68,0,0,0-.277-.034l-.046,0Zm-2.66,4.938a4.563,4.563,0,1,0,4.563,4.563A4.563,4.563,0,0,0,14.813,14.873Zm4.593,1.407a5.525,5.525,0,0,1,.926,2.4h.819l1.521,3.042.033-.013.044.09a.76.76,0,1,0,1.369-.661l-1.775-3.671a.753.753,0,0,0-.435-.382l0,0Zm-4.593,2.4a.761.761,0,1,1-.761.761A.761.761,0,0,1,14.813,18.676Zm5.311,2.458a5.526,5.526,0,0,1-.425.987l1.615,1.49a.508.508,0,0,0,.344.135h2.028a.507.507,0,1,0,0-1.014h-1.83Z" transform="translate(3831.75 -23704)" fill="#dba159"/>
    </g>
  </svg>
  

)