import { Grid, Typography } from '@mui/material';
import React from 'react';
import { SearchFilter, SelectBox, Subheader, TableWithPagination, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew } from '../../utils';
import { useStyles } from "./style";
import { NewLoader } from '../../components/newLoader';
import { Heading, Path, Type } from "./utils/tableUtils";
import { FileUploadCard } from './component/fileUploadCard';
import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { BackendRoutes, Routes } from '../../router/routes';
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import { NetworkCall } from '../../networkcall';
import { saveAs } from 'file-saver';
import { config } from '../../config';
import { constructUploadPayload } from './utils/common';


const ImportFileUpload = (props) => {
    const { t, loading, handleLoading } = props;
    const file_upload_limit = 10
    const allowed_file_format = ["csv", "xls", "xlsx"]
    const profile_id = localStorage.getItem(LocalStorageKeys?.profileID)

    // useHooks
    const classes = useStyles()
    const debounce = UseDebounce()
    const history = useHistory()
    const { state } = useLocation()

    // useContext
    const auth = React.useContext(AuthContext)
    const alert = React.useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext);
    const upload_file_ref = React.useRef(null);

    // useState
    const [data, setData] = React.useState({ row: [], totalRowsCount: 0, })
    const [searchText, setSearchText] = React.useState("")
    const [permission, setPermission] = React.useState({})
    const [loader, setLoader] = React.useState(true)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [isClickDisabled, setIsClickDisabled] = React.useState(false)
    const [selectedSubCategory, setSelectedSubCategory] = React.useState("")

    // useEffect to get permission and data when loading the screen
    React.useEffect(() => {
        if (state?.category_id) {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                setPermission(perm)
                if (perm?.read) { getSubCategories() }
            }
        } else {
            history.replace(Routes.import)
        }
        // eslint-disable-next-line
    }, [auth])

    // Function to get initial sub-categories
    const getSubCategories = () => {
        setIsClickDisabled(true)
        let payload = { category_id: state.category_id }
        NetworkCall(
            `${config?.api_url}/${BackendRoutes.import_get_sub_categories}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.data?.data?.map((_) => {
                return {
                    ..._,
                    value: _?.id,
                    label: _?.name ?? "",
                }
            })
            if (temp_res?.length > 0) {
                setSelectedSubCategory(temp_res?.[0])
                getData(0, limit, searchText, state?.category_id, temp_res?.[0])
            }
        }).catch((error) => {
            console.log(error)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }

    // Function to get the data
    const getData = (offset, limit, search, category_id, sub_category) => {
        let payload = {
            offset, limit, search, category_id,
            sub_category_id: sub_category?.value,
        }
        NetworkCall(
            `${config?.api_url}${BackendRoutes.import_get_import_list}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.data?.data?.map((_) => {
                return {
                    file_name: _?.file_name ?? "-",
                    uploaded_date: _?.created_at,
                    file_format: _?.file_format ? `.${_?.file_format}` : "-",
                    file_size: _?.file_size ? `${(_?.file_size / 1000000).toFixed(2)} MB` : "-",
                    status: _?.status ?? "-",
                    data: _,
                }
            })
            setData({
                row: temp_res,
                totalRowsCount: res?.data?.data?.count,
            })
            setLoader(false)
            handleLoading(false)
        }).catch((error) => {
            console.log(error)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        }).finally(() => {
            setIsClickDisabled(false)
        });
    }

    // Function for search in search component
    const handleSearch = (value) => {
        setSearchText(value)
        debounce(() => searchTableFunction(value), 800)
    }

    // Function to search data in the list
    const searchTableFunction = (value) => {
        getData(0, limit, value, state?.category_id, selectedSubCategory)
    }


    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        switch (type) {
            case "download":
                handleDownload(data?.data)
                break;
            case "delete":
                handleDelete(data?.data)
                break;
            default:
                break;
        }
    }

    // Function to handle download
    const handleDownload = (data) => {
        saveAs(data?.file_url, `${data?.file_name}.${data?.file_format}`);
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getData(offset, limit, searchText, state?.category_id, selectedSubCategory)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getData(0, value, searchText, state?.category_id, selectedSubCategory)
    }

    // Function to handle response manually for the sub-category dropdown
    const constructSubCategoryresponse = (array) => {
        const temp_sub_category = array?.data?.map((i) => {
            return {
                ...i,
                label: i?.name,
                value: i?.id
            }
        })
        return temp_sub_category
    };

    // Function to handle sub category change
    const handleSubCategoryChange = (value) => {
        setSelectedSubCategory(value)
        getData(0, limit, searchText, state?.category_id, value)
    }

    // Function to handle file upload
    const handleChooseFile = () => {
        upload_file_ref.current.click()
    };

    // Function to handle file upload
    const handleFileUpload = (file) => {
        if ((file?.size / 1000000) <= file_upload_limit) {
            if (allowed_file_format?.includes(file?.name?.split(".")?.[1])) {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: true, message: "Uploading File...",
                });
                setIsClickDisabled(true)
                const formData = constructUploadPayload(file, profile_id, state, selectedSubCategory);

                NetworkCall(
                    `${config?.api_url}${BackendRoutes.import_upload_file}`,
                    NetWorkCallMethods.post, formData, null, true, false
                ).then((res) => {
                    getData(0, limit, searchText, state?.category_id, selectedSubCategory)
                    alert.setSnack({
                        ...alert, open: true, msg: t("File Uploaded Successfully!!!"),
                        severity: AlertProps.severity.success
                    })
                    backdrop.setBackDrop({
                        ...backdrop,
                        open: false, message: "",
                    });
                }).catch((error) => {
                    console.log(error)
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                }).finally(() => {
                    setIsClickDisabled(false)
                });
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: `${t("Supported file formats are .csv, .xls, .xlsx")}`,
                    severity: AlertProps.severity.error
                })
            }
        } else {
            alert.setSnack({
                ...alert, open: true, msg: `${t("File size should not exceed ")}${file_upload_limit}`,
                severity: AlertProps.severity.error
            })
        }
    };

    // Function to handle delete
    const handleDelete = (data) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true, message: "Deleting File...",
        });
        setIsClickDisabled(true)
        let payload = {
            upsert_fields: {
                id: data?.id,
                is_delete: true,
                updated_at: new Date().toISOString(),
                updated_by: profile_id
            },
        }
        NetworkCall(
            `${config?.api_url}${BackendRoutes.import_upsert}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            getData(0, limit, searchText, state?.category_id, selectedSubCategory)
            alert.setSnack({
                ...alert, open: true, msg: t("File Deteleted Successfully!!!"),
                severity: AlertProps.severity.success
            })
        }).catch((error) => {
            console.log(error)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        }).finally(() => {
            setIsClickDisabled(false)
            backdrop.setBackDrop({
                ...backdrop,
                open: false, message: "",
            });
        });
    }

    // Function to handle download template
    const handleDownloadTemplate = () => {
        if (selectedSubCategory?.sheet_url) {
            let sheet_url_split_array = selectedSubCategory?.sheet_url?.split('/') ?? [];
            let file_name = sheet_url_split_array?.[sheet_url_split_array?.length - 1];
            try {
                saveAs(selectedSubCategory?.sheet_url, file_name);
            } catch (error) {
                alert.setSnack({
                    ...alert, open: true, msg: t("File Not Available"),
                    severity: AlertProps.severity.error
                });
            }
        } else {
            alert.setSnack({
                ...alert, open: true, msg: t("File Not Available"),
                severity: AlertProps.severity.error
            });
        }
    };

    // Rendering function
    const render = () => {
        return <div>
            <Subheader
                title={state?.category_name}
                goBack={() => history.goBack(-1)}
                handle_download_template={handleDownloadTemplate} />

            {
                loader ? (
                    <NewLoader minusHeight="100px" />
                ) : (
                    <div className={classes.root}>

                        <div className={classes.file_upload_root}>
                            <div className={classes.file_upload_content}>
                                <Grid container spacing={"12px"}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={"12px"} alignItems={"center"}>
                                            <Grid item xs={12} sm={4} md={6}>
                                                <Grid container justifyContent={"start"}>
                                                    <Typography className={classes.title}>
                                                        {t("BULK UPLOAD")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={6}>
                                                <Grid container justifyContent={"end"} spacing={"12px"}>
                                                    <Grid item xs={12} sm={6} md={6} lg={4}>
                                                        <SelectBox
                                                            isRequired
                                                            isReadOnly={isClickDisabled}
                                                            placeholder={t("Select Sub-Category")}
                                                            value={selectedSubCategory}
                                                            onChange={(value) => handleSubCategoryChange(value)}
                                                            isPaginate
                                                            debounceTimeout={800}
                                                            loadOptions={(search, array, handleLoading) =>
                                                                loadOptionsApis(
                                                                    BackendRoutes?.import_get_sub_categories,
                                                                    { category_id: state?.category_id },
                                                                    search,
                                                                    array,
                                                                    handleLoading,
                                                                    "data",
                                                                    {},
                                                                    constructSubCategoryresponse
                                                                )} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileUploadCard
                                            t={t}
                                            file_upload_limit={file_upload_limit}
                                            upload_file_ref={upload_file_ref}
                                            handleChooseFile={handleChooseFile}
                                            handleFileUpload={handleFileUpload} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className={classes.table_root}>
                            <div className={classes.table_content}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container spacing={"12px"} alignItems={"center"}>
                                            <Grid item xs={12} sm={6}>
                                                <Grid container justifyContent={"start"}>
                                                    <Typography className={classes.title}>
                                                        {t("UPLOADED FILES")}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Grid container justifyContent={"end"}>
                                                    <Grid item xs={12} sm={12} md={12} lg={8}>
                                                        <SearchFilter value={searchText} placeholder={t("Search")}
                                                            handleChange={(value) => handleSearch(value)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TableWithPagination
                                            heading={Heading(t)}
                                            rows={data?.row}
                                            path={Path}
                                            showpagination={true}
                                            showpdfbtn={false}
                                            showexcelbtn={false}
                                            showSearch={false}
                                            handleIcon={handleTableIcon}
                                            onClick={() => console.log("")}
                                            tableType="no-side"
                                            dataType={Type}
                                            handlePagination={handleTablePagination}
                                            handleChangeLimit={handleTablePageLimit}
                                            totalRowsCount={data?.totalRowsCount}
                                            page={page}
                                            limit={limit}
                                            mdHeight={`calc(100vh - 423px) !important`}
                                            height={'calc(100vh - 520px)'}
                                            view={permission?.read}
                                            edit={permission?.update}
                                            delete={permission?.delete} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                )}
        </div >
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}

export default withNamespaces("import")(ImportFileUpload); 