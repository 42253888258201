import React from "react"

export const InternalResourceIcon = (props) => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={26}
        data-name="Group 118072"
        {...props}
      >
        <path
          fill="#00897b"
          d="m15 17.952-3.1.619-3.09-.619S2 19.181 2 26h19.81c0-6.794-6.81-8.048-6.81-8.048"
          data-name="Path 101903"
        />
        <path
          fill="#ff9800"
          d="m11.91 21.667-3.1-3.715v-3.714H15v3.714Z"
          data-name="Path 101904"
        />
        <path
          fill="#ffa726"
          d="M18.714 10.524a1.238 1.238 0 1 1-1.238-1.238 1.238 1.238 0 0 1 1.238 1.238m-11.143 0a1.238 1.238 0 1 0-1.238 1.238 1.238 1.238 0 0 0 1.238-1.238"
          data-name="Path 101905"
        />
        <path
          fill="#ffb74d"
          d="M17.476 6.809c0-4.726-11.143-3.077-11.143 0v4.333a5.572 5.572 0 1 0 11.143 0Z"
          data-name="Path 101906"
        />
        <path
          fill="#424242"
          d="M11.904 1.238c-3.76 0-6.19 3.049-6.19 6.81v1.415l1.238 1.061v-3.1l7.429-2.476 2.476 2.476v3.1l1.238-1.078v-1.4c0-2.492-.643-4.962-3.714-5.571l-.619-1.237Z"
          data-name="Path 101907"
        />
        <path
          fill="#784719"
          d="M13.762 10.524a.619.619 0 1 1 .619.619.62.62 0 0 1-.619-.619m-4.952 0a.619.619 0 1 0 .619-.619.62.62 0 0 0-.619.619"
          data-name="Path 101908"
        />
        <path
          fill="#f9a825"
          d="M15.614 18.11v5.414H8.19v-5.416a10.3 10.3 0 0 0-2.476 1.109v6.784h12.381v-6.774a10.337 10.337 0 0 0-2.481-1.117Z"
          data-name="Path 101909"
        />
        <path
          fill="#263238"
          d="M8.19 23.524H5.714v-1.857H8.19Zm9.905-1.857h-2.481v1.857h2.476Z"
          data-name="Path 101910"
        />
        <path
          fill="#ff5722"
          d="M18.095 6.809V6.19A5.571 5.571 0 0 0 12.524.619h-1.238A5.571 5.571 0 0 0 5.715 6.19v.619H4.476v1.857h14.857V6.809Z"
          data-name="Path 101911"
        />
        <path
          fill="#d84315"
          d="M10.048 0h3.714v6.81h-3.714Z"
          data-name="Path 101912"
        />
        <rect
          width={24}
          height={26}
          fill="#fff"
          data-name="Rectangle 58616"
          rx={1}
          style={{
            mixBlendMode: "hue",
            isolation: "isolate",
          }}
        />
      </svg>

    )
}