import Stack from '@mui/material/Stack';
import React from 'react';
import { ChipComponent, FilterChips } from '.';
import { allowfilterState } from '../utils';

export const FilterList = ({
    filterData = {},
    onClearChips = null,
    backgroundColor,
    component
}) => {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            flexWrap={"wrap"}
        >
            <>
                {
                    Object.keys(filterData)?.length > 0 &&
                    <>
                        {
                            (filterData?.range1 && filterData?.range2 && filterData?.rangetype?.label) &&
                            < ChipComponent
                                value={`${filterData?.range1 ? filterData?.range1 : '-'} - ${filterData?.range2 ? filterData?.range2 : '-'} ${filterData?.rangetype?.label ?? ''}`}
                                onClear={() => onClearChips('range')}
                                backgroundColor={backgroundColor}
                            />
                        }
                        {
                            (filterData?.budget1 && filterData?.budget2 && filterData?.budgettype?.label) &&
                            <ChipComponent
                                value={`${filterData?.budget1 ? filterData?.budget1 : '-'} - ${filterData?.budget2 ? filterData?.budget2 : '-'} ${filterData?.budgettype?.label ?? ''}`}
                                onClear={() => onClearChips('budget')}
                                backgroundColor={backgroundColor}
                            />
                        }
                        {
                            Object.keys(filterData)?.filter(_ => allowfilterState?.includes(_))?.length > 0 &&
                            <FilterChips
                                data={Object.keys(filterData)?.filter(_ => allowfilterState?.includes(_))?.reduce((t, _) => ({ ...t, [_]: filterData[_] }), {})}
                                onClear={onClearChips}
                                backgroundColor={backgroundColor}
                            />
                        }
                    </>
                }
            </>
            {component && component}
        </Stack>
    )
}