import { Box, Divider, Grid, Pagination, Stack, Typography, useMediaQuery } from "@mui/material"
import moment from "moment"
import React, { useState } from "react"
import { ActivePropertyIcon } from "../../assets"
import { LeadIcon } from "../../assets/lead"
import NoDataFound from "../../assets/noData"
import { DialogDrawer, SearchFilter } from "../../components"
import { CardDetails } from "../propertymanagerdashboard/components"
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent"
import { DynamicTabs } from "../serviceHubDashboard/components/tab"
import { useStylesCreation } from "./style"
export const FacilityDashboard = ({ t, data = {}, details = {}, page = 1, limit = 10, tabValue = "1", handleTabChange = () => false, handleMapView = () => false, searchText = "", handleSearch = () => false, goToRequestPage = () => false, handlePagination = () => false, requestDetails = {} }) => {
    const classes = useStylesCreation()
    const [zoom, setZoom] = useState(false);
    const mobileStyle = useMediaQuery('(max-width:600px)');
    const [zoomGraph, setZoomGraph] = useState("");
    const scale = {
        x: {
            grid: {
                // drawBorder: false,
                display: false,
            }
        },
        y: {
            grid: {
                // drawBorder: false,
                display: false,
            }
        },
    };
    // zoom function
    const zoomGraphFunction = (e) => {
        setZoomGraph(e);
        setZoom(true);
    };
    const TabList = [
        {
            title: t("Maintenance"),
            value: 1,
            count: 20
        },
        {
            title: t("General"),
            value: 2,
            count: 10
        },

    ]
    const status = [
        {
            icon: <LeadIcon />,
            count: data?.details?.unassigned_job_count ?? 0,
            sub: t("Unassigned"),
            icon_type: true
        },
        {
            image: "/images/propertdashboard/block.svg",
            count: data?.details?.commenced_count ?? 0,
            sub: t("Commenced"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/pausedIcon.png",
            count: data?.details?.paushed_count ?? 0,
            sub: t("Paused"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/managerIcon.png",
            count: data?.details?.job_yet_verify_count ?? 0,
            sub: t("Manager Review"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/assigned.svg",
            count: data?.details?.assigned_job_count ?? 0,
            sub: t("Assigned"),
            image_type: true
        },
        {
            image: "/images/propertdashboard/activeunits.png",
            count: data?.details?.completed_jobs_count ?? 0,
            sub: t("Completed"),
            image_type: true
        },
    ]
    //pagination
    const returnPageNumber = () => {
        //check if props.rows have a index key
        const checkIndexKeyInType = requestDetails?.requestData?.some((_) => _?.index);
        if (requestDetails?.requestData?.length && checkIndexKeyInType) {
            if (requestDetails?.requestData?.length === 1) {
                return `${requestDetails?.requestData?.[0]?.index} of ${tabValue === 1 ? requestDetails?.maintenanceCount : requestDetails?.generalCount}`;
            } else {
                let lastCountIndex = requestDetails?.requestData?.length - 1;
                return `${requestDetails?.requestData?.[0]?.index} - ${requestDetails?.requestData?.[lastCountIndex]?.index} of ${tabValue === 1 ? requestDetails?.maintenanceCount : requestDetails?.generalCount}`;
            }
        }
        return "";
    };
    // close popup
    const handleCloseModule = () => {
        setZoom(false);
    };
    return (
        <Box className={classes.dashboardRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    <GraphComponent
                        t={t}
                        padding={"16px"}
                        graphTitle={"Work Orders"}
                        chartHeader={t("Work Orders")}
                        graphData={data?.WorkOrders ?? []}
                        is_semi_circle_new
                        isShowLegend={true}
                        margin={"20px"}
                        minWidth={true}
                        onZoomClick={zoomGraphFunction}
                        textMargin={'0px 0px 16px'}
                        divider={
                            {
                                xs: 12,
                                sm: 6,
                                md: 12,
                                lg: 12
                            }
                        }
                        divider1={
                            {
                                xs: 12,
                                sm: 6,
                                md: 12,
                                lg: 12
                            }
                        }
                        gradiantStyle={{
                            margin: '15px 0px 0px',
                            top: {
                                lg: "auto",
                                md: '16px',
                                sm: "0px important",
                            },
                            bottom: {
                                lg: "auto",
                                md: '16px',
                                sm: "0px",
                            },
                            height: {
                                md: "400px"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                    {/* work Orders */}
                    <GraphComponent
                        t={t}
                        graphTitle={"Work Order Type"}
                        chartHeader={t("Work Order Type")}
                        padding={"16px"}
                        graphData={data?.inspection_order_graph}
                        innerRadius={60}
                        isTotal={true}
                        total={data?.inspectionJobTotal}
                        is_semi_circle_new
                        isShowLegend={true}
                        margin={"20px"}
                        onZoomClick={zoomGraphFunction}
                        textMargin={'0px 0px 16px'}
                        divider1={
                            {
                                xs: 12,
                                sm: 7.4,
                                md: 12,
                                lg: 12
                            }
                        }
                        divider={
                            {
                                xs: 12,
                                sm: 4.6,
                                md: 12,
                                lg: 12
                            }
                        }
                        gradiantStyle={{
                            margin: '0px 0px 0px',
                            display: "auto",
                            alignItems: "center",
                            top: {
                                lg: "auto",
                                md: '16px',
                                sm: "3px",
                            },
                            height: {
                                md: "400px"
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2.5}>
                    <GraphComponent
                        graphTitle={"Third Party Jobs"}
                        chartHeader={t("Third Party Jobs")}
                        graphData={data?.third_party_request?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                        height={"278px"}
                        is_pie_new
                        isShowLegend={true}
                        margin={"20px"}
                        innerRadius={70}
                        onZoomClick={zoomGraphFunction}
                        textMargin={'0px 0px 16px'}
                        divider1={
                            {
                                xs: 12,
                                sm: 12,
                                md: 12,
                                lg: 12
                            }
                        }
                        divider={
                            {
                                xs: 12,
                                sm: 12,
                                md: 12,
                                lg: 12
                            }
                        }
                        gradiantStyle={{
                            margin: '0px 0px 0px',
                            display: "auto",
                            alignItems: "center",
                            top: {
                                lg: "auto",
                                md: '16px',
                                sm: "3px",
                            },
                            height: {
                                md: "400px"
                            }
                        }}
                        t={t}

                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={1.5} sx={{ display: "flex" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CardDetails data={{
                                icon: <LeadIcon />,
                                count: data?.reopened_request ?? 0,
                                sub: t("Reopened Requests"),
                                icon_type: true
                            }} padding={16} t={t} />
                        </Grid>
                        <Grid item xs={12}>
                            <CardDetails data={{
                                icon: <ActivePropertyIcon />,
                                count: data?.reassign_jobs ?? 0,
                                sub: t("Rescheduled Jobs"),
                                icon_type: true
                            }} padding={16} t={t} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={4}>
                        {/* Job Status */}
                        <GraphComponent
                            t={t}
                            padding={"0px 16px"}
                            graphTitle={"Job Status"}
                            chartHeader={t("Job Status")}
                            graphData={data?.job_status_graph}
                            race_bar
                            scale={scale}
                            chartHeight={"350px"}
                            gheight="300px"
                            isUtility={false}
                            onZoomClick={zoomGraphFunction}
                            justifyContent={'start'}
                            isNoLegend={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                        <Grid container spacing={2}>
                            {status?.map((x) => {
                                return (
                                    <Grid item xs={6} md={4} lg={6}>
                                        <CardDetails data={x} maxHeight={"120px"} t={t} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={4}>
                        <GraphComponent
                            t={t}
                            padding={"8px 16px"}
                            graphTitle={"Hours Distribution"}
                            chartHeader={t("Hours Distribution")}
                            graphData={data?.ProjectedHoursGraphData}
                            margin={"10px"}
                            chartHeight={"350px"}
                            is_line_bar
                            scale={scale}
                            gheight="300px"
                            onZoomClick={zoomGraphFunction}
                            isNoLegend={true}
                            justifyContent={'start'}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={6}>
                        <Grid container>
                            <Box className={classes.customCard}>
                                <Grid container>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <GraphComponent
                                            padding={"16px"}
                                            graphTitle={"Maintenance Request"}
                                            chartHeader={t("Maintenance Request")}
                                            height={"360px"}
                                            graphData={data?.maintenance_type?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                            is_pie_new
                                            noStyle
                                            onZoomClick={zoomGraphFunction}
                                            margin={"36px"}
                                            isShowLegend={true}
                                            innerRadius={70}
                                            t={t}
                                            textMargin={'0px 0px 16px'}
                                            divider1={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            divider={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            gradiantStyle={{
                                                margin: '0px 0px 0px',
                                                display: "auto",
                                                alignItems: "center",
                                                top: {
                                                    lg: "auto",
                                                    md: '16px',
                                                    sm: "3px",
                                                },
                                                height: {
                                                    md: "400px"
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <GraphComponent
                                            boxShadow={false}
                                            graphTitle={"By Category"}
                                            chartHeader={t("By Category")}
                                            height={"350px"}
                                            graphData={data?.maintenance_category?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                            is_semi_circle_new
                                            noStyle
                                            margin={"20px"}
                                            innerRadius={70}
                                            isShowLegend={true}
                                            textMargin={'0px 0px 16px'}
                                            divider1={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            divider={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            gradiantStyle={{
                                                margin: '15px 0px 0px',
                                                display: "auto",
                                                alignItems: "center",
                                                top: {
                                                    lg: "auto",
                                                    md: '16px',
                                                    sm: "3px",
                                                },
                                                height: {
                                                    md: "400px"
                                                }
                                            }}
                                            t={t}

                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                            <Grid container className={classes.customCard} mt={2}>
                                <Grid item xs={12} md={6} lg={6}>
                                <GraphComponent
                                            graphTitle={"General Request"}
                                            chartHeader={t("General Request")}
                                            height={"340px"}
                                            graphData={data?.general_type?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                            is_pie_new
                                            noStyle
                                            isShowLegend={true}
                                            onZoomClick={zoomGraphFunction}
                                            margin={"36px"}
                                            innerRadius={70}
                                            t={t}
                                            textMargin={'0px 0px 16px'}
                                            divider1={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            divider={
                                                {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 12,
                                                    lg: 12
                                                }
                                            }
                                            gradiantStyle={{
                                                margin: '0px 0px 0px',
                                                display: "auto",
                                                alignItems: "center",
                                                top: {
                                                    lg: "auto",
                                                    md: '16px',
                                                    sm: "3px",
                                                },
                                                height: {
                                                    md: "400px"
                                                }
                                            }}
                                        />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <GraphComponent
                                        graphTitle={"By Category"}
                                        chartHeader={t("By Category")}
                                        height={"375px"}
                                        graphData={data?.general_category?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                        is_semi_circle_new
                                        isShowLegend={true}
                                        noStyle
                                        margin={"20px"}
                                        innerRadius={70}
                                        textMargin={'0px 0px 16px'}
                                        divider1={
                                            {
                                                xs: 12,
                                                sm: 12,
                                                md: 12,
                                                lg: 12
                                            }
                                        }
                                        divider={
                                            {
                                                xs: 12,
                                                sm: 12,
                                                md: 12,
                                                lg: 12
                                            }
                                        }
                                        gradiantStyle={{
                                            margin: '0px 0px 0px',
                                            display: "auto",
                                            alignItems: "center",
                                            top: {
                                                lg: "auto",
                                                md: '16px',
                                                sm: "3px",
                                            },
                                            height: {
                                                md: "400px"
                                            }
                                        }}
                                        t={t}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Box className={classes.tabBlock} height={requestDetails?.requestData?.length > 0 ? "750px" : "725px"}>
                            <DynamicTabs tabList={TabList} value={tabValue} handleChange={handleTabChange} count={requestDetails} handleMapView={handleMapView} t={t} />
                            <Box mt={2}>
                                <SearchFilter placeholder={t("Search Request ID, Request Name, Category")} value={searchText} handleChange={(e) => handleSearch(e)} />
                            </Box>
                            {requestDetails?.requestData?.length > 0 ?
                                <>

                                    {/* <InfiniteScroll
                                                            dataLength={details?.requestData?.length ?? ""}
                                                            next={fetchMoreData}
                                                            hasMore={true}
                                                            height={450}
                                                        > */}
                                    <Box mt={2}>
                                        {requestDetails?.requestData?.map((val, i) => {
                                            return (
                                                <Box style={{ cursor: "pointer" }} onClick={() => goToRequestPage(val)}>
                                                    <Typography className={classes.serviceTitle}>{val?.name}</Typography>
                                                    <Stack direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        {mobileStyle ?
                                                            <>
                                                                <Typography className={classes.serviceSubTxt} noWrap>{val?.property_name}</Typography>
                                                                <Box className={classes.seperateDot}></Box>
                                                                <Typography className={classes.serviceSubTxt} noWrap>{val?.category_name}</Typography>
                                                                <Box className={classes.seperateDot}></Box>
                                                                <Typography className={classes.serviceSubTxt} noWrap>&#x202a;{moment(val?.created_at).format("DD MMM YY")}&#x202c;</Typography>
                                                            </>
                                                            : <>
                                                                <Typography className={classes.serviceSubTxt} noWrap>{val?.maintenance_request_no}</Typography>                                                                
                                                                <Typography className={classes.serviceSubTxt} noWrap>{val?.general_request_no}</Typography>                                                                
                                                                <Box className={classes.seperateDot}></Box>
                                                                <Typography className={classes.serviceSubTxt} noWrap>{val?.property_name}</Typography>
                                                                <Box className={classes.seperateDot}></Box>
                                                                <Typography className={classes.serviceSubTxt} noWrap>{val?.category_name}</Typography>
                                                                <Box className={classes.seperateDot}></Box>
                                                                <Typography className={classes.serviceSubTxt} noWrap>&#x202a;{moment(val?.created_at).format("DD MMM YY")}&#x202c;</Typography>
                                                                <Box className={classes.seperateDot}></Box>
                                                                <Typography className={classes.serviceSubTxt} noWrap style={{ width: "250px" }}>{val?.description}</Typography>
                                                            </>
                                                        }
                                                    </Stack>
                                                    {requestDetails?.requestData?.length - 1 !== i &&
                                                        <Box mt={1} mb={1}><Divider /></Box>
                                                    }
                                                </Box>

                                            )
                                        })}

                                    </Box>
                                    {/* </InfiniteScroll> */}
                                    <>
                                        <div className={classes.serviceHubpagination}>
                                            <div className={classes.flexRow}>
                                                <p className={classes.typo}>Rows per page: 10</p>

                                            </div>
                                            <div className={classes.flexRow}>
                                                {requestDetails?.requestData?.length > 0 && (
                                                    <p className={classes.typo}>{returnPageNumber()}</p>
                                                )}
                                                <Pagination
                                                    className={classes.paginate}
                                                    shape="rounded"
                                                    count={tabValue === 1 ? Math.ceil(requestDetails?.maintenanceCount / limit) : Math.ceil(requestDetails?.generalCount / limit)}
                                                    page={page}
                                                    size={mobileStyle ? "small" : "medium"}
                                                    // onChange={handleChange}
                                                    onChange={(e, value) => handlePagination(value)}
                                                    color="primary"
                                                // showFirstButton
                                                // showLastButton
                                                />
                                            </div>
                                        </div>
                                    </>
                                </>
                                :
                                <Box mt={"10%"} display={"flex"} justifyContent={"center"}>
                                    <NoDataFound />
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={5}>
                        <GraphComponent
                            t={t}
                            graphTitle={"Item Category"}
                            chartHeader={t("Item Category")}
                            graphData={data?.item_category ?? []}
                            padding={"16px"}
                            is_variable_pie_new
                            margin={"20px"}
                            minWidth={true}
                            onZoomClick={zoomGraphFunction}
                            textMargin={'0px 0px 16px'}
                            divider={
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 12,
                                    lg: 12
                                }
                            }
                            divider1={
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 12,
                                    lg: 12
                                }
                            }
                            gradiantStyle={{
                                margin: '15px 0px 0px',
                                top: {
                                    lg: "auto",
                                    md: '16px',
                                    sm: "0px important",
                                },
                                bottom: {
                                    lg: "auto",
                                    md: '16px',
                                    sm: "0px",
                                },
                                height: {
                                    md: "400px"
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={5}>
                        {/* work Orders */}
                        <GraphComponent
                            t={t}
                            graphTitle={"Item SubCategory"}
                            chartHeader={t("Item SubCategory")}
                            padding={"16px"}
                            graphData={data?.item_sub_category ?? []}
                            is_pie_new
                            isShowLegend={true}
                            margin={"20px"}
                            minWidth={true}
                            onZoomClick={zoomGraphFunction}
                            textMargin={'0px 0px 16px'}
                            divider={
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 12,
                                    lg: 12
                                }
                            }
                            divider1={
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 12,
                                    lg: 12
                                }
                            }
                            gradiantStyle={{
                                margin: '15px 0px 0px',
                                top: {
                                    lg: "auto",
                                    md: '16px',
                                    sm: "0px important",
                                },
                                bottom: {
                                    lg: "auto",
                                    md: '16px',
                                    sm: "0px",
                                },
                                height: {
                                    md: "400px"
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={2} sx={{ display: "flex" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CardDetails data={{
                                    icon: <LeadIcon />,
                                    count: data?.item_count ?? 0,
                                    sub: t("Item Count"),
                                    icon_type: true
                                }} padding={16} t={t} />
                            </Grid>
                            <Grid item xs={12}>
                                <CardDetails data={{
                                    icon: <ActivePropertyIcon />,
                                    count: data?.unit_item_count ?? 0,
                                    sub: t("Unit Item Count"),
                                    icon_type: true
                                }} padding={16} t={t} />
                            </Grid>
                            <Grid item xs={12}>
                                <CardDetails data={{
                                    icon: <ActivePropertyIcon />,
                                    count: data?.unit_item_type ?? 0,
                                    sub: t("Assets Count"),
                                    icon_type: true
                                }} padding={16} t={t} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* zoom dialog for each graph */}
            <DialogDrawer
                header={t(zoomGraph)}
                maxWidth={(zoomGraph === "Maintenance Request" || zoomGraph === "General Request") ? "lg" : "md"}
                handleClose={handleCloseModule}
                open={zoom}
                height={"auto"}
                borderRadius={"12px"}
                padding={'0px'}
                onClose={handleCloseModule}
                component={
                    <div>
                        {zoomGraph === "Work Orders" && (
                            <GraphComponent
                                graphData={data?.WorkOrders}
                                isPie
                                t={t}
                                margin={"20px"}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '-8px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                        {zoomGraph === "Work Order Type" && (
                            <GraphComponent
                                graphData={data?.inspection_order_graph}
                                isPie
                                innerRadius={100}
                                isTotal
                                total={data?.inspectionJobTotal}
                                paddingAngle={2}
                                t={t}
                                margin={"20px"}
                                padding={"16px"}
                                centerTop={"44%"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '-8px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}

                            />
                        )}
                        {zoomGraph === "Job Status" && (
                            <GraphComponent
                                // title={"Inspection Job Status"}
                                graphData={data?.job_status_graph}
                                isBar
                                scale={scale}
                                t={t}
                                margin={"20px"}
                                minWidth={false}
                                gheight={'500px'}
                                gwidth={400}
                                is_popUp
                                justifyContent={'start'}
                                padding={"0px 16px 16px"}
                                // divider1={
                                //     {
                                //         xs: 12,
                                //         sm: 4,
                                //         md: 4,
                                //         lg: 4
                                //     }
                                // }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12
                                    }
                                }

                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    grid_template: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    maxHeight: "220px",
                                    top: {
                                        lg: "auto",
                                        md: '16px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "220px",
                                        sm: "220px",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                        {zoomGraph === "Hours Distribution" && (
                            <GraphComponent
                                graphData={data?.ProjectedHoursGraphData ?? []}
                                isBar
                                scale={scale}
                                margin={"20px"}
                                minWidth={false}
                                gheight={'500px'}
                                gwidth={'400px'}
                                t={t}
                                is_popUp
                                justifyContent={'start'}
                                padding={"0px 16px 16px"}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 4,
                                        md: 4,
                                        lg: 4
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12
                                    }
                                }

                                gradiantStyle={{
                                    margin: '15px 0px 0px',
                                    grid_template: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '16px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                        {zoomGraph === "Item Category" && (
                            <GraphComponent
                                graphData={data?.item_category}
                                isPie
                                t={t}
                                margin={"20px"}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '-8px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                        {zoomGraph === "Item SubCategory" && (
                            <GraphComponent
                                graphData={data?.item_sub_category}
                                isPie
                                innerRadius={100}
                                isTotal
                                total={data?.itemSubCategoryTotal}
                                // paddingAngle={2}
                                t={t}
                                margin={"20px"}
                                padding={"16px"}
                                centerTop={"44%"}
                                maxHeightGraph={"250px"}
                                minWidth={false}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '-8px',
                                        sm: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}

                            />
                        )}
                        {zoomGraph === "Maintenance Request" && (
                            <Box className={classes.customCard}>
                                <GraphComponent
                                    // title={"Maintenance Request"}
                                    // header={t("Maintenance Request")}
                                    graphData={data?.maintenance_type?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                    isPie
                                    t={t}
                                    // noStyle
                                    is_popUp
                                    // margin={"20px"}
                                    innerRadius={70}
                                    isTotal
                                    total={data?.maintenance_type?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                    // textMargin={'0px 0px 16px'}
                                    divider1={
                                        {
                                            xs: 12,
                                            sm: 5,
                                            md: 5,
                                            lg: 5
                                        }
                                    }
                                    divider={
                                        {
                                            xs: 12,
                                            sm: 7,
                                            md: 7,
                                            lg: 7
                                        }
                                    }
                                    centerTop={"44%"}
                                    gradiantStyle={{
                                        margin: '0px 0px 0px',
                                        display: "flex",
                                        alignItems: "center",
                                        grid_template: "100%",
                                        maxHeight: "auto",
                                        top: {
                                            lg: "auto",
                                            md: '-8px',
                                            sm: "auto",
                                        },
                                        height: {
                                            md: "auto",
                                            sm: "auto",
                                            lg: "100%"
                                        }
                                    }}
                                />
                                <GraphComponent
                                    t={t}
                                    boxShadow={false}
                                    title={"By Category"}
                                    header={t("By Category")}
                                    graphData={data?.maintenance_category?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                    isPie
                                    is_popUp
                                    // noStyle
                                    // margin={"20px"}
                                    innerRadius={70}
                                    isTotal
                                    centerTop={"44%"}
                                    total={data?.maintenance_category?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                    // textMargin={'0px 0px 16px'}
                                    divider1={
                                        {
                                            xs: 12,
                                            sm: 5,
                                            md: 5,
                                            lg: 5
                                        }
                                    }
                                    divider={
                                        {
                                            xs: 12,
                                            sm: 7,
                                            md: 7,
                                            lg: 7
                                        }
                                    }
                                    gradiantStyle={{
                                        margin: '0px 0px 0px',
                                        display: "flex",
                                        alignItems: "center",
                                        grid_template: "100%",
                                        maxHeight: "auto",
                                        top: {
                                            lg: "auto",
                                            md: '-8px',
                                            sm: "auto",
                                        },
                                        height: {
                                            md: "auto",
                                            sm: "auto",
                                            lg: "100%"
                                        }
                                    }}
                                />
                            </Box>
                        )}
                        {zoomGraph === "General Request" && (
                            <Box className={classes.customCard}>
                                <GraphComponent
                                    t={t}

                                    title={"General Request"}
                                    header={t("General Request")}
                                    graphData={data?.general_type?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                    isPie
                                    // noStyle
                                    // margin={"20px"}
                                    is_popUp
                                    innerRadius={70}
                                    isTotal
                                    total={data?.general_type?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                    textMargin={'0px 0px 16px'}
                                    divider1={
                                        {
                                            xs: 12,
                                            sm: 5,
                                            md: 5,
                                            lg: 5
                                        }
                                    }
                                    divider={
                                        {
                                            xs: 12,
                                            sm: 7,
                                            md: 7,
                                            lg: 7
                                        }
                                    }
                                    centerTop={"44%"}
                                    gradiantStyle={{
                                        margin: '0px 0px 0px',
                                        display: "flex",
                                        alignItems: "center",
                                        grid_template: "100%",
                                        maxHeight: "auto",
                                        top: {
                                            lg: "auto",
                                            md: '-8px',
                                            sm: "auto",
                                        },
                                        height: {
                                            md: "auto",
                                            sm: "auto",
                                            lg: "100%"
                                        }
                                    }}
                                />
                                <GraphComponent
                                    t={t}

                                    title={"By Category"}
                                    header={t("By Category")}
                                    graphData={data?.general_category?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                    isPie
                                    // noStyle
                                    // margin={"20px"}
                                    innerRadius={70}
                                    isTotal
                                    total={data?.general_category?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                    textMargin={'0px 0px 16px'}
                                    is_popUp
                                    divider1={
                                        {
                                            xs: 12,
                                            sm: 5,
                                            md: 5,
                                            lg: 5
                                        }
                                    }
                                    divider={
                                        {
                                            xs: 12,
                                            sm: 7,
                                            md: 7,
                                            lg: 7
                                        }
                                    }
                                    centerTop={"44%"}
                                    gradiantStyle={{
                                        margin: '0px 0px 0px',
                                        display: "flex",
                                        alignItems: "center",
                                        grid_template: "100%",
                                        maxHeight: "auto",
                                        top: {
                                            lg: "auto",
                                            md: '-8px',
                                            sm: "auto",
                                        },
                                        height: {
                                            md: "auto",
                                            sm: "auto",
                                            lg: "100%"
                                        }
                                    }}
                                />
                            </Box>)}
                        {zoomGraph === "Third Party Jobs" && (
                            <GraphComponent
                                graphData={data?.third_party_request?.map((val) => { return { name: val?.type, count: parseInt(val?.count), fill: val?.fill } }) ?? []}
                                total={data?.third_party_request?.map((item) => parseInt(item?.count)).reduce((a, b) => a + b, 0) ?? 0}
                                centerTitle="Total"
                                isPie
                                innerRadius={100}
                                isTotal
                                margin={"0px"}
                                minWidth={false}
                                paddingAngle={2}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                centerTop={"44%"}
                                t={t}

                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }
                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '0px',
                                        sm: "auto",
                                    },
                                    bottom: {
                                        sm: "auto",
                                        md: "-16px",
                                        lg: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}
                            />
                        )}
                    </div>
                }
            />
        </Box>
    )
}