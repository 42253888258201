import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "../styles";
export const BankingDetails = ({ data = {}, t }) => {
    const classes = useStyles()
    return (
        <Box className={classes.box} marginTop="16px">
            <Typography className={classes.title} marginBottom={"14px"}>{t("Banking Basic Details")}</Typography>
            <Grid container justifyContent="space-between" row rowSpacing={1} columnSpacing={3}>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Bank Account Type")}</Typography>
                        <Typography className={classes.sub}>
                            {(data?.account_type !== null && data?.account_type?.length > 0) ? data?.account_type : "-"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Account Number")}</Typography>
                        <Typography className={classes.sub}>
                            {(data?.bank_account_no !== null && data?.bank_account_no?.length > 0) ? data?.bank_account_no : "-"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Bank Name")}</Typography>
                        <Typography className={classes.sub}>{(data?.bank_name !== null && data?.bank_name?.length > 0) ? data?.bank_name : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Bank Routing Type")}</Typography>
                        <Typography className={classes.sub}>{(data?.routing_type !== null && data?.routing_type?.length > 0) ? data?.routing_type : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Branch Name")}</Typography>
                        <Typography className={classes.sub}>{(data?.branch_name !== null && data?.branch_name?.length > 0) ? data?.branch_name : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Bank Routing Code")}</Typography>
                        <Typography className={classes.sub}>{(data?.routing_code !== null && data?.routing_code?.length > 0) ? data?.routing_code : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Address Line 1")}</Typography>
                        <Typography className={classes.sub}>
                            {(data?.address_1 !== null && data?.address_1?.length > 0) ? data?.address_1 : "-"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box >
                        <Typography className={classes.heading}>{t("Address Line 2")}</Typography>
                        <Typography className={classes.sub}>{(data?.address_2 !== null && data?.address_2?.length > 0) ? data?.address_2 : "-"}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("City")}</Typography>
                        <Typography className={classes.sub}>{(data?.city !== null && data?.city?.length > 0) ? data?.city : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Country")}</Typography>
                        <Typography className={classes.sub}>
                            {(data?.country_master?.label !== null && data?.country_master?.label?.length > 0) ? data?.country_master?.label : "-"}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Currency")}</Typography>
                        <Typography className={classes.sub}>{(data?.bank_currency?.name !== null && data?.bank_currency?.name?.length > 0) ? data?.bank_currency?.name : "-"}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}></Grid>
                {/* <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Preferred Cash Collection Office")}</Typography>
                        <Typography className={classes.sub}>
                            {(data?.cash_collection_office !== null && data?.cash_collection_office?.length > 0) ? data?.cash_collection_office : "-"}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Box>
                        <Typography className={classes.heading}>{t("Cheque Name")}</Typography>
                        <Typography className={classes.sub}>
                            {(data?.cheque_name !== null && data?.cheque_name?.length > 0) ? data?.cheque_name : "-"}
                        </Typography>
                    </Box>

                </Grid> */}



            </Grid>
        </Box>
    )
}