import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { stringAvatar } from '../../../utils'
import { ProfileCardStyle } from './style'
export const ProfileCard = ({ data = {} }) => {
    const classes = ProfileCardStyle()
    return (
        <Box>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <Avatar src={data?.image_url} {...stringAvatar(data?.first_name)}></Avatar>
                <Stack>
                    <Stack>
                        <Typography className={classes.name}> {data?.first_name} </Typography>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Typography className={classes.mobno_email}>{data?.mobile_no_country_code ?? ""} {data?.mobile_no}</Typography>
                            <Box className={classes.dot}></Box>
                            <Typography className={classes.mobno_email}>{data?.email_id}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}