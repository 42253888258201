import { makeStyles } from "@mui/styles";
import { Bold, ExtraBold, SemiBold, Regular } from "../../../utils";
export const CreateBackupFormUseStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        width: "400px"
    },
    header: {
        postion: "fixed",
        top: 0,
        display: "flex",
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
    },
    close_button: {
        cursor: "pointer",
        height: "32px",
        width: "32px",
    },
    header_title: {
        fontSize:"1rem",
        fontFamily: ExtraBold,
        color: theme.typography.color.primary,
        marginInlineStart: "16px"
    },
    content: {
        padding: "16px",
        height: `calc(100vh - 144px)`,
        overflow: "overlay"
    },
    footer: {
        width: "inherit",
        position: "fixed",
        bottom: 0,
        display: "flex",
        borderTop: `1px solid ${theme.palette.border.secondary}`,
    },
    create_button: {
        height: "40px",
        display: "flex",
    },
    backuptitle: {
        color: "#091B29",
        fontFamily: Bold,
        fontSize:"0.875rem",
    },
    statusBox: {
        color: "#FFFFFF",
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        padding: "2px 4px",
        backgroundColor: "#5AC782",
        borderRadius: "4px",
        border: '0.5px solid #5AC782'

    },
    desc: {
        color: "#4E5A6B",
        fontFamily: Regular,
        fontSize:"0.75rem",
    },
    parentBox: {
        border: '1px solid #E4E8EE',
        backgroundColor: '#F5F7FA',
        padding: '16px',
        borderRadius: "4px"
    },
    restoreTitle: {
        color: "#98A0AC",
        fontFamily: Bold,
        fontSize:"0.75rem",
        margin: "20px 0px 12px"
    },
    date: {
        color: "#091B29",
        fontFamily: Bold,
        fontSize:"0.875rem",
        marginBottom: "5px"
    },
    person: {
        color: "#4E5A6B",
        fontFamily: SemiBold,
        fontSize:"0.75rem",
    },
    restoreIcon: {
        border: "1px solid #CED3DD",
        borderRadius: "50%",
        padding: "12px",
        display: "flex",
        alignItems: "center"
    },
    borderBox: {
        borderLeft: "1px solid #CED3DD",
        height: "40px",
        marginLeft: "23px"
    },
    content1: {
        width: "404px"
    },
    statusBox1: {
        color: "#FFFFFF",
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        padding: "2px 4px",
        backgroundColor: "red",
        borderRadius: "4px",
        border: '0.5px solid red'
    },
    boxRoot: {
        padding: "10px 18px",
        borderRadius: "4px",
        border: "1px solid #E4E8EE",
        height: "100%",
        textAlign: "center"
    },
    typeName: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#091B29",
        marginTop: "9.4px"

    },
    boxRootSelect: {
        padding: "10px 18px",
        borderRadius: "4px",
        border: "1px solid #5078E1",
        backgroundColor: "#F1F7FF",
        height: "100%",
        textAlign: "center"
    },
    typeNameSelect: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#5078E1",
        marginTop: "9.4px"
    }
}))

export const ConfirmDialogUseStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    },
    close_button: {
        position: "absolute",
        top: 20,
        right: 20
    },
    question_text: {
        fontSize:"1.125rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    button_div: {
        width: "100%",
        display: "flex",
    },
    cancel_button: {
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: theme.palette.borderRadius,
        height: "40px",
        backgroundColor: theme.palette.background.tertiary1,
        "&:hover": {
            backgroundColor: theme.palette.background.tertiary1,
        }
    },
    cancel_button_text: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    yes_button: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.palette.borderRadius,
        height: "40px",
        marginInlineStart: "8px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        }
    },
    yes_button_text: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.white
    }
}))
