import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
export const MapSource = {
  "Phone call": {
    image: "images/leadactivity/viewactivityimages/phone.svg",
    background: "images/leadactivity/viewactivityimages/phoneback.svg",
    text: "Phone call",
    edit: <ModeEditOutlinedIcon style={{color:"#27AFCF" , fontSize:"1rem"}} />,
  },
  "Follow up": {
    image: "images/leadactivity/viewactivityimages/followup.svg",
    background: "images/leadactivity/viewactivityimages/followupback.svg",
    text: "Follow up",
    edit: <ModeEditOutlinedIcon  style={{color:"#35A27D" , fontSize:"1rem"}} />,
  },
  Meeting: {
    image: "images/leadactivity/viewactivityimages/meeting.svg",
    background: "images/leadactivity/viewactivityimages/meetingbackground.svg",
    text: "Meeting",
    edit: <ModeEditOutlinedIcon style={{color:"#8887D4" , fontSize:"1rem"}} />,
  },
  Appointment: {
    image: "images/leadactivity/viewactivityimages/calander.svg",
    background: "images/leadactivity/viewactivityimages/calnderback.svg",
    text: "Appointment",
    edit: <ModeEditOutlinedIcon style={{color:"#F34276" , fontSize:"1rem"}} />,
  },
  "Arrange Site Visit": {
    image: "images/leadactivity/viewactivityimages/site.svg",
    background: "images/leadactivity/viewactivityimages/siteback.svg",
    text: "Arrange Site Visit",
    edit: <ModeEditOutlinedIcon style={{color:"#E29336" , fontSize:"1rem"}} />,
  },
  "Send Quotation": {
    image: "images/leadactivity/viewactivityimages/qutation.svg",
    background: "images/leadactivity/viewactivityimages/qutationback.svg",
    text: "Send Quotation",
    edit: <ModeEditOutlinedIcon style={{color:"#27AFCF" , fontSize:"1rem"}} />,
  },
  "Send Email": {
    image: "images/leadactivity/viewactivityimages/mail.svg",
    background: "images/leadactivity/viewactivityimages/meetingbackground.svg",
    text: "Send Email",
    edit: <ModeEditOutlinedIcon style={{color:"#8887D4" , fontSize:"1rem"}} />,
  },
};

export const type_constant = {
  Phone: "Phone call",
  Follow: "Follow up",
  Meeting: "Meeting",
  Appointment: "Appointment",
  Arrange: "Arrange Site Visit",
  Quotation: "Send Quotation",
  Email: "Send Email",
};
