export const PreventiveMaintenance = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105102">
            <g
                data-name="FM - Service Requests - Preventive Maintenance"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <g data-name="Group 105013">
                    <g data-name="Group 105012">
                        <path
                            data-name="Path 97978"
                            d="M21.913 16.263a12.482 12.482 0 0 0-5.34 0 1.332 1.332 0 0 0-.643.372 2.528 2.528 0 0 0-1.868 0 1.332 1.332 0 0 0-.644-.372 12.481 12.481 0 0 0-5.34 0A1.405 1.405 0 0 0 7 17.633v.487a3.894 3.894 0 0 0 3.547 3.929 3.749 3.749 0 0 0 3.953-3.743 5.832 5.832 0 0 0-.027-.834 1.606 1.606 0 0 1 1.054 0 3.61 3.61 0 0 0-.027.647 3.894 3.894 0 0 0 3.547 3.929A3.749 3.749 0 0 0 23 18.306v-.673a1.406 1.406 0 0 0-1.087-1.37Zm-9.838 3.367a1.871 1.871 0 0 1-2.65 0 .468.468 0 1 1 .663-.661.957.957 0 0 0 1.323 0 .468.468 0 1 1 .663.661Zm8.5 0a1.871 1.871 0 0 1-2.65 0 .468.468 0 0 1 .663-.661.957.957 0 0 0 1.323 0 .468.468 0 1 1 .663.661Z"
                        />
                    </g>
                </g>
                <g data-name="Group 105015">
                    <g data-name="Group 105014">
                        <path
                            data-name="Path 97979"
                            d="M10.748 8A3.753 3.753 0 0 0 7 11.748v4.017a2.254 2.254 0 0 1 .878-.415c.329-.073.664-.116 1-.163v-3.439a1.876 1.876 0 0 1 1.871-1.875.937.937 0 0 0 0-1.874Z"
                        />
                    </g>
                </g>
                <g data-name="Group 105017">
                    <g data-name="Group 105016">
                        <path
                            data-name="Path 97980"
                            d="M19.244 8a.937.937 0 1 0 0 1.874 1.876 1.876 0 0 1 1.874 1.874v3.438c.334.047.668.088 1 .163a2.254 2.254 0 0 1 .878.416v-4.017A3.753 3.753 0 0 0 19.247 8Z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
