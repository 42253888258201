import * as React from "react"

export const CalenderIcon = (props) => {

    const {
        width = 16,
        height = 16,
    } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={width}
            height={height}
            {...props}
        >
            <g id="Group_96235" data-name="Group 96235">
                <g id="Group_98145" data-name="Group 98145">
                    <g id="Rectangle_51039" data-name="Rectangle 51039" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                        <rect width="16" height="16" stroke="none" />
                        <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
                    </g>
                    <path id="icons8-calendar_14_" data-name="icons8-calendar (14)" d="M8.708,6A2.718,2.718,0,0,0,6,8.708v9.583A2.718,2.718,0,0,0,8.708,21h9.583A2.718,2.718,0,0,0,21,18.292V8.708A2.718,2.718,0,0,0,18.292,6Zm0,1.25h9.583A1.449,1.449,0,0,1,19.75,8.708v.625H7.25V8.708A1.449,1.449,0,0,1,8.708,7.25ZM7.25,10.583h12.5v7.708a1.449,1.449,0,0,1-1.458,1.458H8.708A1.449,1.449,0,0,1,7.25,18.292ZM9.958,12.25A1.042,1.042,0,1,0,11,13.292,1.042,1.042,0,0,0,9.958,12.25Zm3.542,0a1.042,1.042,0,1,0,1.042,1.042A1.042,1.042,0,0,0,13.5,12.25Zm3.542,0a1.042,1.042,0,1,0,1.042,1.042A1.042,1.042,0,0,0,17.042,12.25ZM9.958,16A1.042,1.042,0,1,0,11,17.042,1.042,1.042,0,0,0,9.958,16ZM13.5,16a1.042,1.042,0,1,0,1.042,1.042A1.042,1.042,0,0,0,13.5,16Z" transform="translate(-5.5 -5.5)" fill="#98a0ac" />
                </g>
            </g>
        </svg>
    )
}
