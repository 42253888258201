import * as React from "react"

const LinkIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 54869"
                    transform="translate(-6263 3167)"
                    fill="#5078e1"
                    stroke="#707070"
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
        <g
            data-name="Mask Group 6490"
            transform="translate(6263 -3167)"
            clipPath="url(#a)"
        >
            <path
                data-name="icons8-add-link (1)"
                d="M-6256.168 3172a5.168 5.168 0 1 0 0 10.335h1.88a.94.94 0 1 0 0-1.879h-1.88a3.288 3.288 0 1 1 0-6.577h1.88a.94.94 0 1 0 0-1.879Zm7.516 0a.94.94 0 0 0 0 1.879h1.879a3.292 3.292 0 0 1 3.288 3.288 3.248 3.248 0 0 1-.479 1.706 5.953 5.953 0 0 1 1.682.845 5.093 5.093 0 0 0 .676-2.551 5.171 5.171 0 0 0-5.166-5.167Zm-7.983 4.228a.94.94 0 1 0 0 1.879h10.333a.94.94 0 1 0 0-1.879Zm10.8 3.288a5.167 5.167 0 1 0 5.167 5.167 5.168 5.168 0 0 0-5.167-5.167Zm0 1.409a.47.47 0 0 1 .47.47v2.819h2.819a.47.47 0 1 1 0 .94h-2.817v2.819a.47.47 0 1 1-.94 0v-2.82h-2.818a.47.47 0 1 1 0-.94h2.819v-2.813a.47.47 0 0 1 .467-.475Z"
                fill="#5078e1"
            />
        </g>
    </svg>
)

export default LinkIcon
