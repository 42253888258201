export const GeneralJobTabIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <g id="Group_115986" data-name="Group 115986" transform="translate(4807.801 7031)">
    <path id="icons8-image_2_" data-name="icons8-image (2)" d="M8.75,6A2.75,2.75,0,0,0,6,8.75V17.7l3.753-3.63a2.067,2.067,0,0,1,2.813,0l3.089,2.988,1.024-.99a2.073,2.073,0,0,1,2.813,0L24,20.429V8.75A2.75,2.75,0,0,0,21.25,6Zm9.5,3.5A2.25,2.25,0,1,1,16,11.75,2.253,2.253,0,0,1,18.25,9.5Zm0,1.5a.75.75,0,1,0,.75.75A.75.75,0,0,0,18.25,11Zm-7.091,4a.52.52,0,0,0-.363.144L6,19.788V21.25a2.75,2.75,0,0,0,2.5,2.737L14.577,18.1l-3.054-2.954A.523.523,0,0,0,11.159,15Zm6.928,2a.521.521,0,0,0-.363.143L10.639,24H21.25a2.75,2.75,0,0,0,2.537-1.69l-5.336-5.162A.524.524,0,0,0,18.087,17Z" transform="translate(-4813.801 -7037)" fill="#98a0ac" opacity="0"/>
    <path id="icons8-cv" d="M13.625,3a2.03,2.03,0,0,0-2,1.8h-1.6A2.025,2.025,0,0,0,8,6.825v12.15A2.025,2.025,0,0,0,10.025,21h10.35A2.025,2.025,0,0,0,22.4,18.975V6.825A2.025,2.025,0,0,0,20.375,4.8h-1.6a2.03,2.03,0,0,0-2-1.8Zm0,1.35h3.15a.675.675,0,0,1,0,1.35h-3.15a.675.675,0,0,1,0-1.35ZM15.2,9.3a1.8,1.8,0,1,1-1.8,1.8A1.8,1.8,0,0,1,15.2,9.3Zm-2.784,4.95h5.57a.816.816,0,0,1,.815.816v.188c0,.865-1.144,2.146-3.6,2.146s-3.6-1.281-3.6-2.146v-.188A.816.816,0,0,1,12.416,14.25Z" transform="translate(-4813.801 -7034)" fill="#98a0ac"/>
  </g>
</svg>

    )
}