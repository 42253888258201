import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className='sa'>{children}</Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const CustomTabs = (props) => {

    const {
        tabList = [],
        active = 0,
        onChangeTab = null,
        tabPanel = null,
        activeIconColor = "#fff",
        rightSideComponent = null,
        justifyContent = true,
        sx = {},
        clearFilter = () => false,
        alignment = "row",
        t = () => false,
        clearBtnColor = "#FF4B4B",
        clearBtnWeight = "400"
    } = props;


    const handleChange = (event, newValue) => {
        onChangeTab(newValue);
    };

    const tabRender = () => {
        return (
            <Tabs
                value={active}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons={false}
                sx={{
                    flex: justifyContent ? 1 : 'auto',
                    ...sx.tabs ?? {}
                }}
            >
                {
                    tabList?.length && tabList?.map((_, index) => {
                        return (
                            <Tab
                                label={
                                    <Stack direction={'row'} alignItems="center" gap={1}>
                                        {(active >= 0 && _?.icon) && _?.icon(activeIconColor)}
                                        <Typography>
                                            {_?.title ? _?.title : `Tab ${index}`}
                                        </Typography>
                                    </Stack>
                                }
                                {...a11yProps(index)}
                            />
                        )
                    })
                }
            </Tabs>
        )
    }

    return (
        <Box sx={{ width: '100%', flex: 1 }}>
            <Stack direction={alignment} alignItems={'center'} gap={1}>
                {alignment === "row"
                    ? <>
                        {tabRender()}
                        <Stack direction={"row"} gap={"16px"}
                            alignItems={"center"} justifyContent={"end"}>
                            {clearFilter &&
                                <Typography sx={{ color: clearBtnColor, fontWeight: clearBtnWeight, fontSize: "0.75rem", cursor: "pointer" }} onClick={clearFilter}> <u>{t("Clear All")}</u> </Typography>}
                            {rightSideComponent && rightSideComponent}
                        </Stack>
                    </>
                    : <>
                        <Stack direction={"row"} gap={"16px"}
                            alignItems={"center"} justifyContent={"space-between"}
                            width={"100%"}>
                            {tabRender()}
                            {clearFilter &&
                                <Typography sx={{ color: clearBtnColor, fontWeight: clearBtnWeight, fontSize: "0.75rem", cursor: "pointer" }} onClick={clearFilter}> <u>Clear All</u> </Typography>}
                        </Stack>
                        <Stack direction={"row"} gap={"16px"}
                            alignItems={"center"} justifyContent={"end"}
                            width={"100%"}>
                            {rightSideComponent && rightSideComponent}
                        </Stack>
                    </>
                }

            </Stack>
            <TabPanel value={active} index={active}>
                {tabPanel}
            </TabPanel>
        </Box>
    );
}