export const CommunityHoaManagement = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 98377">
      <g data-name="Group 8127">
        <g data-name="Group 98373">
          <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
          <g fill={props?.fill ?? "#c1c5cb"}>
            <path
              data-name="Path 94746"
              d="M21.168 15.208a3.285 3.285 0 0 1-2.133.787h-.165a4.053 4.053 0 0 1 .7 2.274v2.436H23v-2.436a3.473 3.473 0 0 0-1.833-3.061Z"
            />
            <path
              data-name="Path 94747"
              d="M17.56 14.766a2.503 2.503 0 0 1-.1.119 2.7 2.7 0 1 0 0-4.375 3.287 3.287 0 0 1 .1 4.267Z"
            />
            <path
              data-name="Path 94748"
              d="M10.966 15.994a3.285 3.285 0 0 1-2.133-.787A3.473 3.473 0 0 0 7 18.268v2.436h3.435v-2.436a4.053 4.053 0 0 1 .7-2.276h-.169Z"
            />
            <path
              data-name="Path 94749"
              d="M10.966 15.393a2.671 2.671 0 0 0 1.57-.508 1.43 1.43 0 0 1-.1-.119 3.287 3.287 0 0 1 .1-4.256 2.695 2.695 0 1 0-1.57 4.883Z"
            />
            <path
              data-name="Path 94750"
              d="M17.133 15.205a3.285 3.285 0 0 1-4.267 0 3.482 3.482 0 0 0-1.833 3.061v2.436h7.932v-2.436a3.482 3.482 0 0 0-1.832-3.061Z"
            />
            <path
              data-name="Path 94751"
              d="M15 15.39a2.7 2.7 0 1 0-2.7-2.7 2.7 2.7 0 0 0 2.7 2.7Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
