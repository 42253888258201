export const VacancyPath = ["unitName", "unitCategory", "floorName", "blockName", "currentStatus", "status"]


export const Vacancyheading = [
    { title: "Unit Name", field: "unitName", },
    { title: "Unit Category", field: "unitCategory", },
    { title: "Floor Name", field: "floorName", },
    { title: "Block Name", field: "blockName", },
    { title: "Unit Current Status", field: "currentStatus", },
    { title: "Status", field: "status", },
]
export const UnitVacancyPath = ["startDate", "endDate", "currentStatus", "agreementNumber", "image", "companyName", "primaryContactName", "primaryContactNumber", "reservationNumber"]


export const UnitVacancyheading = [
    { title: "Start Date", field: "startDate", },
    { title: "End Date", field: "endDate", },
    { title: "Occupancy Status", field: "currentStatus", },
    { title: "Agreement Number", field: "agreementNumber", },
    { title: "Company Name", field: "companyName", },
    { title: " ", field: "image", },
    { title: "Primary Contact Name", field: "primaryContactName", },
    { title: "Primary Contact Number", field: "primaryContactNumber", },
    { title: "Reservation Number", field: "reservationNumber", },
]
