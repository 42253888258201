export const ParkingSlotMasterPath = [
    "parkingSlot",
    "parkingUnitId",
    "perHourRate",
    "location",
    "propertyName",
    "groupMasterName",
    "status"
];


export const ParkingSlotMasterHeading = (t) => [
    { title: t("Parking Slot"), field: "parkingSlot" },
    { title: t("Parking Unit ID"), field: "parkingUnitId" },
    { title: t("Parking Number"), field: "parkingNumber" },
    { title: t("Location"), field: "location" },
    { title: t("Per Hour Rate"), field: "perHourRate" },
    { title: t("Property"), field: "propertyName" },
    { title: t("Parking Group Master"), field: "groupMasterName" },
    { title: t("Status"), field: "status" },
    { title: "", field: 'icon' },

];
