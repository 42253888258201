import * as React from "react"

export const Tools = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={15.273} height={16} {...props}>
        <path
            d="M3.455 0a1.274 1.274 0 0 0-1.273 1.273v.545h-.909a.91.91 0 0 0-.909.909v1.816a.91.91 0 0 0 .908.909h5.455a.91.91 0 0 0 .909-.909v-.936a.9.9 0 0 0-.266-.642l-.878-.879a.914.914 0 0 0-.643-.268h-.394v-.545A1.274 1.274 0 0 0 4.182 0Zm7.46.453a.345.345 0 0 0-.084.011 3.186 3.186 0 0 0-.649.241 3.271 3.271 0 0 0-.364 5.651v2.007h3.636V6.357A3.271 3.271 0 0 0 13.09.706a3.186 3.186 0 0 0-.649-.241A.361.361 0 0 0 12 .82v2.453a.364.364 0 0 1-.727 0V.82a.365.365 0 0 0-.358-.367Zm-9.46 2.456h4.318l.772.773v.681H1.454Zm.727 2.545v2.909h3.273V5.455ZM.545 8A.546.546 0 0 0 0 8.545v6.909a.545.545 0 1 0 1.091 0v-.182h13.091v.182a.545.545 0 1 0 1.091 0V8.545a.545.545 0 1 0-1.091 0v.545H1.091v-.545A.546.546 0 0 0 .545 8Zm.545 3.636h13.092v1.091H1.091Z"
            fill="#896db3"
        />
    </svg>
)

