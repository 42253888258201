export const FunctionalLocations = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105121">
            <g
                data-name="FM - Masters - Functional Location"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <g data-name="Group 105009">
                    <g data-name="Group 105008">
                        <path
                            data-name="Path 97957"
                            d="M15.031 7a5.154 5.154 0 0 0-4.191 8.156l3.8 5.924a.469.469 0 0 0 .789 0l3.813-5.945A5.156 5.156 0 0 0 15.031 7Zm0 7.5a2.344 2.344 0 1 1 2.344-2.344 2.346 2.346 0 0 1-2.344 2.344Z"
                        />
                    </g>
                </g>
                <g data-name="Group 105011">
                    <g data-name="Group 105010">
                        <path
                            data-name="Path 97958"
                            d="m18.7 17.777-2.36 3.69a1.55 1.55 0 0 1-2.609 0l-2.364-3.69c-2.08.481-3.362 1.362-3.362 2.415 0 1.827 3.623 2.813 7.031 2.813s7.031-.986 7.031-2.812c-.005-1.059-1.289-1.94-3.367-2.416Z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)