import React from "react"

export const ProjectedBomIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.16" viewBox="0 0 24 24.16">
            <g id="Group_118506" data-name="Group 118506" transform="translate(-197 -106)">
                <g id="Rectangle_52035" data-name="Rectangle 52035" transform="translate(197 106)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <g id="Group_118527" data-name="Group 118527">
                    <g id="Rectangle_52035-2" data-name="Rectangle 52035" transform="translate(197 106)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                        <rect width="24" height="24" stroke="none" />
                        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                    </g>
                    <path id="icons8-screw_1_" data-name="icons8-screw (1)" d="M4.021,18.04l1.842,5.6L2.039,24.958A.873.873,0,0,1,1.783,25a.785.785,0,0,1-.741-1.038l1.482-4.31a.73.73,0,0,1,.183-.3Zm2.233-2.233,1.951,5.927-1,1L5.263,16.8ZM8.42,13.642l1.946,5.932-.923.923L7.5,14.565Zm6.793-6.819,3.965,3.965c-.1.083-.2.177-.308.282l-7.263,7.263L9.661,12.4l5.275-5.275A3.556,3.556,0,0,0,15.213,6.823ZM25,8.5a.782.782,0,0,1-.23.553L23.456,10.37a2.347,2.347,0,0,1-1.482.683c-.052.005-.1.005-.157.005a2.232,2.232,0,0,1-.423-.042,2.63,2.63,0,0,1-.407-.125c-.068-.031-.136-.063-.2-.1a2.31,2.31,0,0,1-.36-.245c-.057-.047-.115-.1-.167-.151l-.172-.172L15.807,5.941l-.146-.151a2.293,2.293,0,0,1-.031-3.245L16.945,1.23a.693.693,0,0,1,.256-.172.786.786,0,0,1,.59,0,.757.757,0,0,1,.261.172l6.72,6.72A.782.782,0,0,1,25,8.5Z" transform="translate(195.999 105.16)" fill="#78b1fe" />
                </g>
            </g>
        </svg>

    )
}