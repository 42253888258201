import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold, remCalc } from "../../utils";

export const MyOrgStyle = makeStyles((theme) => ({
    tableSection: {
        backgroundColor: "white",
        borderRadius: theme.palette.borderRadius,
        padding: "20px",
    },
    addbtn: {
        marginTop: "10px"
    },
    historyCard: {
        padding: "15px"
    },
    dashedborder: {
        borderColor: theme.palette.border.secondary,
        borderLeftStyle: "dashed",
        borderLeftWidth: "2px",
        height: "80px",
        marginLeft: "9px",
        marginTop: "-2px"
    },
    noborder: {
        height: "80px",
        marginLeft: "9px",
    },
    historydot: {
        height: "8px",
        width: "8px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    // title: {
    //       fontSize:"0.75rem",
    //       color: theme.typography.color.tertiary,
    //       fontFamily: SemiBold
    // },
    titlecontent: {
        fontSize: "0.75rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
        marginLeft: "5px"
    },
    memberName: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: SemiBold
    },
    subdetails: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular
    },
    role: {
        fontSize: "0.875rem",
        marginBottom: "10px",
        // backgroundColor: "#EBFBFF",
        // color: "#2CA2BF",
        borderRadius: theme.palette.borderRadius,
        padding: "5px",
        width: "fit-content",
        textTransform: "capitalize"
    },
    dividerStyle: {
        height: "40px"
    },
    cancelbtn: {
        '&:hover': {
            backgroundColor: "white"
        }
    },
    propertyName: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary,
        margin: "10px 0px"
    },
    delTxt: {
        color: theme.typography.color.primary,
        fontSize: "1rem",
        fontWeight: "bold",
    },
    delIcon: {
        width: "35px",
        height: "35px",
        margin: "auto",
        borderRadius: "50%",
        padding: "10px",
        backgroundColor: theme.palette.error.light
    },
    delBox: {
        backgroundColor: "#fff",
        width: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    delIconSvg: {
        display: "flex",
        margin: "auto"
    },
    delBtn: {
        fontSize: 13,
        fontWeight: "500"
    },
    delBtn_outlined: {
        fontSize: 13,
        fontWeight: "500",
        '&:hover': {
            background: "#fff"
        }
    },
    avatarStyle: {
        width: "56px",
        height: "56px",
        margin: "5px 0px 5px 16px !important"
    },
    historyTitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    },
    detailsBlock: {
        margin: "5px 0px 5px 16px !important"
    },
    viewcontent: {
        padding: "12px"
    },
    menuBox: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        margin: "8px 4px"
    },
    selectedMenuBox: {
        border: `1px solid ${theme.palette.primary.main}`,
        background: theme.palette.info.light,
        borderRadius: 4,
        margin: "8px 4px"
    },
    menuAvatar: {
        height: 30,
        width: 30,
        borderRadius: 8
    },
    menuTitle: {
        fontSize: remCalc(14),
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    menuSubtitle: {
        fontSize: remCalc(12),
        color: theme.typography.color.primary,
        fontFamily: SemiBold
    }
}))