import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    Label: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
    },
    select: {
        padding: "10px",
        textAlign: "center",
        borderRadius: "10px",
        color: "white",
        fontSize:"0.875rem",
        backgroundColor: "#5078E1",
        cursor: "pointer",
    },
    selected: {
        padding: "10px",
        textAlign: "center",
        borderRadius: "10px",
        fontSize:"0.875rem",
        cursor: "pointer",
        border: "1px solid #E4E8EE",
    },
}));

export const TapSelect = (props) => {
 

    const classes = useStyles(props);
    return (
        <>
            <Typography
                onClick={() => props.IsSelected(props.data)}
                className={
                    props?.data?.active
                        ? classes.select
                        : classes.selected
                }
            >
                {props?.data?.value}
            </Typography>
        </>
    );
};


