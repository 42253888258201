import React from "react";
import { withNavBars } from "../../HOCs";
import UtilityManagerDashboard from "./utilityDashboard";


class UtilityManagerDashboardParent extends React.Component {
    render() {
        return <UtilityManagerDashboard {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UtilityManagerDashboardParent, props);