import React from "react";
import { withNavBars } from "../../HOCs";
import MyOrgPage from "./myOrg";

class MyOrgParent extends React.Component {
    render() {
        return <MyOrgPage />
    }
}
const props = {
    boxShadow: false
}

export default withNavBars(MyOrgParent, props);
