import { Box, Typography } from "@mui/material"
import { TableWithPagination } from "../../../components"

import { useStyles } from "./styles"

export const Table = ({ title = null, path = [], heading = [], dataType = [], rows = [], padding = false , amountOnchange=()=>false ,currency="",dateOnchange=()=>false}) => {

    const classes = useStyles()

    return (
        <Box p={padding ? 2 : 0} sx={{ backgroundColor: "#F2F4F7" }}>
            {
                title &&
                <>
                    <Typography className={classes.title}>{title}</Typography>
                    <Box height="4px" />
                </>
            }


            <TableWithPagination
                headingColor="#E4E8EE"
                cellColor="#F2F4F7"
                heading={heading}
                rows={rows}
                path={path}
                showpagination={false}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                onClick={() => console.log("")}
                tableType="no-side"
                dataType={dataType}
                //height={`calc(100vh - 510px)`}
                view={true}
                edit={true}
                delete={true}
                milstoneOnchange={amountOnchange}
                currency={currency}
                dateOnchange={dateOnchange}
                 />

        </Box>
    )
}