import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import { SwitchIcons } from "../../../utils/timeOffRequestList"
import { useStyles } from "../style"

export const StatsList = ({t, leaveList = [] }) => {
    const classes = useStyles()
 

    return (
        <Box mt={2}>
            <Typography className={classes.statsTitle}>{t("paidOffstats")}</Typography>
            {leaveList.filter((x) => x.type === "Paid")?.map((x) => {
                return (
                    <Box mt={2} className={classes.list}>
                        <Stack direction="row">
                            <Box className={classes.icon}><Box mt={1}>{
                             SwitchIcons(x.name)
                            }</Box></Box>
                            <Box sx={{ marginInlineStart: "8px" }}>
                                <Typography className={classes.leaveName}>{x.name}</Typography>
                            </Box>
                        </Stack>

                        <Box className={classes.availableLeave}>
                            {t("Available")} : {x.approved_leave>0?x.total_leave-x.approved_leave:x.total_leave}/{x.total_leave}
                        </Box>

                    </Box>
                )
            })}
            {(leaveList?.length === 0 || leaveList.filter((x) => x.type === "Paid")?.length===0) &&
                <Box className={classes.leaveName} display="flex" justifyContent="center">
                    {t("noDataFound")}
                </Box>
            }
            <Box mt={2}>
                <Typography className={classes.statsTitle}>{t("lossOfPay")}</Typography>
                {leaveList.filter((x) => x.type === "Earned")?.map((x) => {
                    return (
                        <Box mt={2} className={classes.list}>
                            <Stack direction="row">
                                <Box className={classes.icon}><Box mt={1}>{
                                   SwitchIcons(x.name)
                                }</Box></Box>
                                <Box sx={{ marginInlineStart: "8px" }}>
                                    <Typography className={classes.leaveName}>{x.name}</Typography>
                                </Box>
                            </Stack>
                            <Box className={classes.availableLeave}>
                                {t("Available")} : {x.approved_leave>0?(x.total_leave-x.approved_leave):x.total_leave}/{x.total_leave}
                            </Box>
                        </Box>
                    )
                })}
                {(leaveList?.length === 0 || leaveList.filter((x) => x.type === "Earned")?.length===0)&&
                    <Box className={classes.leaveName} display="flex" justifyContent="center">
                       {t("noDataFound")}
                    </Box>
                }
            </Box>
            <Box mt={2}>
                <Typography className={classes.statsTitle}>{t("Short Leave")}</Typography>
                {leaveList.filter((x) => x.type === "Short Leave")?.map((x) => {
                    return (
                        <Box mt={2} className={classes.list}>
                            <Stack direction="row">
                                <Box className={classes.icon}><Box mt={1}>{
                                   SwitchIcons(x.name)
                                }</Box></Box>
                                <Box sx={{ marginInlineStart: "8px" }}>
                                    <Typography className={classes.leaveName}>{x.name}</Typography>
                                </Box>
                            </Stack>
                            <Box className={classes.availableLeave}>
                                {t("Available")} : {x.approved_leave>0?(x.total_leave-x.approved_leave):x.total_leave}/{x.total_leave}
                            </Box>
                        </Box>
                    )
                })}
                {(leaveList?.length === 0 || leaveList.filter((x) => x.type === "Short Leave")?.length===0)&&
                    <Box className={classes.leaveName} display="flex" justifyContent="center">
                       {t("noDataFound")}
                    </Box>
                }
            </Box>
        </Box>
    )
}