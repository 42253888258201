export const configurationlisted = [
    {
        label: "Dashboard Banner",
        value: 1,
    },
    {
        label: "Community Banner",
        value: 2,
    },
    {
        label: "Service Banner",
        value: 3,
    },

    {
        label: "Welcome Screen Banner",
        value: 4,
    },
    // {
    //     label: "Login Banner",
    //     value: 5,
    // },
    // {
    //     label: "OTP Banner",
    //     value: 6,
    // },
    // {
    //     label: "Welcome Screen Banner",
    //     value: 7,
    // },

]