import { ActualBomAmount } from "../../../assets/actualBOMAmount"
import { AssetsCovered } from "../../../assets/assetsCovered"
import { CompletedJobAsset } from "../../../assets/completedJobAsset"
import { ExecutedJobAsset } from "../../../assets/executedJobAsset"
import { ProjectedBomAmount } from "../../../assets/projectedBomAmount"
import { ProjectedBomIcon } from "../../../assets/projectedBomIcon"
import { ResourceAsset } from "../../../assets/resourceAsset"
import { ScheduledJobAsset } from "../../../assets/scheduledJobAsset"
import { UpcomingJobAsset } from "../../../assets/upcomingJobAsset"

export const Path = ["info","reference_no", "pmp_type", "name", "execution", "resource_type", "created_at", "is_active"]

export const Heading = (t) => [
    { title: t(""), field: "info" },
    { title: t("ID"), field: "reference_no" },
    { title: t("PMP Type"), field: "pmp_type" },
    { title: t("Plan Name"), field: "name" },
    { title: t("Execution Period"), field: "execution", },
    // { title: t("Frequency"), field: "frequency", },
    { title: t("Resource Type"), field: "resource_type", },
    { title: t("Created on"), field: "created_at", },
    { title: t("Status"), field: "is_active", },
]

export const Type = [
    { type: ["info"], name: "info" },
    { type: ["text"], name: "reference_no" },
    { type: ["text"], name: "pmp_type" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "execution" },
    // { type: ["text"], name: "frequency" },
    { type: ["text"], name: "resource_type" },
    { type: ["date"], name: "created_at" },
    { type: ["status"], name: "is_active" },
]

export const cardData = (t, val) => {
    return (
        [
            {
                name: t("Scheduled Jobs"),
                count: val?.schedule_job ?? 0,
                icon: <ScheduledJobAsset />,
                type:"scheduled"
            },
            {
                name: t("Upcoming Jobs"),
                count: val?.upcomming_job ?? 0,
                icon: <UpcomingJobAsset />,
                type:"upcomming"
            },
            {
                name: t("Executed Jobs"),
                count: val?.executed_job ?? 0,
                icon: <ExecutedJobAsset />,
                type:"executed"
            },
            {
                name: t("Completed Jobs"),
                count: val?.completed_jobs ?? 0,
                icon: <CompletedJobAsset />,
                type:"completed"
            },
            {
                name: t("Assets Covered"),
                count: val?.asset_covered ?? 0,
                icon: <AssetsCovered />,
                type:"asset"
            },
            {
                name: t("Resources"),
                count: val?.resource ?? 0,
                icon: <ResourceAsset />,
                type:"resource"
            },
        ]
    )
}

export const BomDetails = (t, val,company) => {
    return (
        [
            {
                name: t("Projected BOM Items"),
                count: val?.total_bom_item ?? 0,
                icon: <ProjectedBomIcon />,
                type:"bom"
            },
            {
                name: t("Projected BOM Value"),
                count: val?.bom_total>0?`${val?.bom_total} ${company?.code}` : `0 ${company?.code}`,
                icon: <ProjectedBomAmount />
            },
            {
                name: t("Actual BOM Value"),
                count: val?.actual_bom_total_Amount>0?`${val?.actual_bom_total_Amount} ${company?.code}`:`0 ${company?.code}`,
                icon: <ActualBomAmount />
            },
        ]
    )
}