import * as React from "react"

export const Loader = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 107307" transform="translate(-1008 -319)">
      <circle
        data-name="Ellipse 129564"
        cx={20}
        cy={20}
        r={20}
        transform="translate(1008 319)"
        fill="#ffe4e4"
      />
      <g data-name="Group 105523">
        <path
          data-name="Rectangle 55540"
          fill="none"
          d="M1016 327h24v24h-24z"
        />
        <path
          d="M1021.889 331a.889.889 0 1 0 0 1.778v.444a6.165 6.165 0 0 0 4 5.723v.109a6.164 6.164 0 0 0-4 5.723v.444a.889.889 0 1 0 0 1.778h12.444a.889.889 0 1 0 0-1.778v-.444a6.165 6.165 0 0 0-4-5.723v-.109a6.164 6.164 0 0 0 4-5.723v-.444a.889.889 0 1 0 0-1.778h-12.444Zm1.778 1.778h8.889v.444a4.435 4.435 0 0 1-3.333 4.3.889.889 0 0 0-.667.86v1.239a.889.889 0 0 0 .667.861 4.434 4.434 0 0 1 3.333 4.3v.444h-.28a4.216 4.216 0 0 0-8.328 0h-.28v-.444a4.434 4.434 0 0 1 3.332-4.302.889.889 0 0 0 .667-.86v-1.239a.889.889 0 0 0-.667-.861 4.435 4.435 0 0 1-3.333-4.3Z"
          fill="#b3776d"
        />
      </g>
    </g>
  </svg>
)
