import * as React from "react"
 const VerifiedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={0.427}
        x2={0.569}
        y1={45.915}
        y2={46.921}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0370c8" />
        <stop offset={0.484} stopColor="#036fc5" />
        <stop offset={0.775} stopColor="#036abd" />
        <stop offset={1} stopColor="#0362b0" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={0.411}
        x2={0.605}
        y1={172.254}
        y2={173.337}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#75daff" />
        <stop offset={1} stopColor="#1ea2e4" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={0.259}
        x2={0.55}
        y1={81.084}
        y2={82.071}
      />
      <linearGradient
        id="e"
        x1={0.146}
        x2={0.854}
        y1={69.047}
        y2={69.754}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#21ad64" />
        <stop offset={1} stopColor="#088242" />
      </linearGradient>
      <radialGradient
        id="c"
        cx={0.5}
        cy={0.5}
        r={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={0.009} stopOpacity={0.992} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
    </defs>
    <g data-name="Group 113351">
      <path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 57482" />
      <g transform="translate(-1 -1)">
        <path
          fill="url(#b)"
          d="M6.116 9h18.977a1.116 1.116 0 0 1 1.116 1.116v14.512a1.116 1.116 0 0 1-1.116 1.116H6.116A1.116 1.116 0 0 1 5 24.628V10.116A1.116 1.116 0 0 1 6.116 9Z"
          data-name="Path 100266"
          transform="translate(0 -1.767)"
        />
        <path
          d="M14.491 18.396a3.35 3.35 0 1 0-6.7 0v1.674a1.116 1.116 0 0 0 1.116 1.116h4.465a1.116 1.116 0 0 0 1.119-1.116Z"
          data-name="Path 100267"
          opacity={0.05}
        />
        <path
          d="M11.14 15.326a3.069 3.069 0 0 0-3.07 3.07v1.674a.838.838 0 0 0 .837.837h4.463a.838.838 0 0 0 .837-.837v-1.674a3.069 3.069 0 0 0-3.067-3.07Z"
          data-name="Path 100268"
          opacity={0.07}
        />
        <path
          d="M22.305 13.93h-5.584a1.117 1.117 0 0 0 0 2.233h5.584a1.117 1.117 0 0 0 0-2.233Z"
          data-name="Path 100269"
          opacity={0.05}
        />
        <path
          d="M16.721 14.209a.838.838 0 0 0-.837.837.838.838 0 0 0 .837.837h5.581a.838.838 0 0 0 .837-.837.838.838 0 0 0-.837-.837Z"
          data-name="Path 100270"
          opacity={0.07}
        />
        <path
          d="M26.205 17.949v4.912a1.12 1.12 0 0 1-1.116 1.116h-7.784a6.025 6.025 0 0 1-.028-.558 6.167 6.167 0 0 1 1.937-4.465h-2.493a1.117 1.117 0 0 1 0-2.233h5.584a1.11 1.11 0 0 1 .966.564 1.36 1.36 0 0 1 .151-.006 6.092 6.092 0 0 1 2.783.67Z"
          data-name="Path 100271"
          opacity={0.05}
        />
        <path
          d="M26.21 18.267v4.593a1.12 1.12 0 0 1-1.116 1.116h-7.51c-.011-.184-.022-.368-.022-.558a5.842 5.842 0 0 1 2.433-4.744h-3.274a.837.837 0 1 1 0-1.674h5.581a.837.837 0 0 1 .793.575c.106-.006.212-.017.324-.017a5.8 5.8 0 0 1 2.791.709Z"
          data-name="Path 100272"
          opacity={0.07}
        />
        <circle
          cx={2.233}
          cy={2.233}
          r={2.233}
          fill="url(#c)"
          data-name="Ellipse 129697"
          transform="translate(8.907 10.581)"
        />
        <path
          fill="url(#a)"
          d="M23.907 5h-3.349a.558.558 0 0 0-.558.558v3.349a.558.558 0 0 0 .558.558h3.349a.558.558 0 0 0 .558-.558V5.558A.558.558 0 0 0 23.907 5Zm-1.674 3.349a.558.558 0 1 1 .558-.558.558.558 0 0 1-.558.558Z"
          data-name="Path 100273"
          transform="translate(-6.628)"
        />
        <path
          fill="url(#d)"
          d="M12.116 17.674a1.674 1.674 0 1 1 1.674 1.674 1.674 1.674 0 0 1-1.674-1.674Zm1.674 2.791A2.791 2.791 0 0 0 11 23.256v1.674a.558.558 0 0 0 .558.558h4.465a.558.558 0 0 0 .558-.558v-1.674a2.791 2.791 0 0 0-2.79-2.791Z"
          data-name="Path 100274"
          transform="translate(-2.651 -4.86)"
        />
        <path
          fill="#75daff"
          d="M22.303 17.279h-5.582a.558.558 0 0 0-.558.558.558.558 0 0 0 .558.558h5.582a.558.558 0 0 0 .558-.558.558.558 0 0 0-.558-.558Z"
          data-name="Path 100275"
        />
        <path
          fill="#75daff"
          d="M22.303 14.488h-5.582a.558.558 0 0 0-.558.558.558.558 0 0 0 .558.558h5.582a.558.558 0 0 0 .558-.558.558.558 0 0 0-.558-.558Z"
          data-name="Path 100276"
        />
        <path
          d="M22.86 17.837a.56.56 0 0 1-.558.558h-2.411a6.094 6.094 0 0 1 2.662-1.055.562.562 0 0 1 .246.251.517.517 0 0 1 .061.246Z"
          data-name="Path 100277"
          opacity={0.05}
        />
        <path
          d="M22.861 17.838a.56.56 0 0 1-.558.558h-1.898a5.778 5.778 0 0 1 2.394-.8.517.517 0 0 1 .062.242Z"
          data-name="Path 100278"
          opacity={0.07}
        />
        <path
          fill="url(#e)"
          d="M39.163 33.581A5.581 5.581 0 1 1 33.581 28a5.582 5.582 0 0 1 5.582 5.581Z"
          data-name="Path 100279"
          transform="translate(-10.163 -10.163)"
        />
        <path
          fill="#fff"
          d="m22.663 25.848-2.233-2.232a.279.279 0 0 1 0-.395l.395-.395a.279.279 0 0 1 .395 0l1.641 1.64 3.036-3.036a.279.279 0 0 1 .395 0l.395.395a.279.279 0 0 1 0 .395l-3.628 3.628a.279.279 0 0 1-.396 0Z"
          data-name="Path 100280"
        />
      </g>
    </g>
  </svg>
)
export default VerifiedIcon

