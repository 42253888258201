import React from "react";
import { withNavBars } from "../../HOCs";
import CheckListItemView from "./checkListItemView";

class CheckListItemViewParent extends React.Component {
  render() {
    return <CheckListItemView/>;
  }
}

export default withNavBars(CheckListItemViewParent);