import { Box, Button, Grid } from "@mui/material";
import React from 'react';
import { CustomCheckBox, SelectBox, TextBox } from "../../../components";


export const FormGenerator = (props) => {
    const {
        components = [],
    } = props;

    const switchComponents = (val) => {

        if (val?.isActive) {

            switch (val.component) {
                case "text":
                    return (
                        <TextBox
                            isRequired={val?.isRequired}
                            label={val?.label}
                            placeholder={val?.placeholder}
                            value={val.value}
                            onChange={val?.onChange}
                            isError={val?.error?.length > 0}
                            errorMessage={val?.error}
                            multiline={val?.multiline}
                            isReadonly={val?.isReadonly}
                        />
                    )

                case "button":
                    return (
                        <Button sx={{ padding: "8px", boxShadow: "none", marginTop: "4px" }} onClick={val?.onClick} variant="contained" fullWidth>
                            {val?.label}
                        </Button>
                    )

                case "check":
                    return (
                        <CustomCheckBox label={val?.label} checked={val?.value} onChange={val?.onChange} />
                    )

                case "select":
                    return (
                        <SelectBox
                            isRequired={val?.isRequired}
                            label={val?.label}
                            placeholder={val?.placeholder}
                            value={val.value}
                            onChange={val?.onChange}
                            isError={val?.error?.length > 0}
                            errorMessage={val?.error}
                            isReadonly={val?.isReadonly}
                            isPaginate={val?.isPaginate}
                            loadOptions={val?.loadOptions}
                            loading={val?.loading}
                            debounceTimeout={800}
                            options={val?.options ?? []}

                        />
                    )
                default:
                    return null;
            }
        }
    }

    return (
        <Box>
            {
                <Grid container>
                    {
                        components?.length && components.map(_ => (
                            <>
                                <Grid item xs={12}>
                                    {switchComponents(_)}
                                    {
                                        _?.isActive &&
                                        <Box height="8px" />
                                    }

                                </Grid>
                            </>
                        ))
                    }
                </Grid>
            }
        </Box>
    )
}

