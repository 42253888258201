import * as React from "react"

export const OwnerUnit = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 102948" transform="translate(-11563 -17154)">
            <circle
                data-name="Ellipse 129451"
                cx={12}
                cy={12}
                r={12}
                transform="translate(11563 17154)"
                fill="#f0f9f7"
            />
            <path
                d="M11573.571 17160a2.857 2.857 0 1 0 2.857 2.857 2.86 2.86 0 0 0-2.857-2.857Zm4 6.3a1.34 1.34 0 0 0-.829.278l-1.518 1.2a1.333 1.333 0 0 0-.51 1.053v2.138a1.037 1.037 0 0 0 1.036 1.031h3.643a1.037 1.037 0 0 0 1.036-1.037v-2.138a1.333 1.333 0 0 0-.51-1.053l-1.518-1.2a1.34 1.34 0 0 0-.83-.272Zm-7.286.562a1.287 1.287 0 0 0-1.285 1.281v.343c0 1.65 2.008 2.943 4.571 2.943.216 0 .426-.013.633-.031a1.6 1.6 0 0 1-.061-.435v-2.138a1.9 1.9 0 0 1 .728-1.5l.59-.466Zm6 2h.286a.429.429 0 0 1 .429.429v.857a.429.429 0 0 1-.429.429h-.286a.429.429 0 0 1-.429-.429v-.857a.429.429 0 0 1 .43-.434Zm2.286 0h.286a.429.429 0 0 1 .429.429v.857a.429.429 0 0 1-.429.429h-.286a.429.429 0 0 1-.429-.429v-.857a.429.429 0 0 1 .429-.434Z"
                fill="#43aa8b"
                data-name="Group 102947"
            />
        </g>
    </svg>
)

