export const EmpBookingCalDays = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun"
]

export const Events = [
    {
        date: "2022-10-18",
        event: [
            {
                title: "BKID-0007, Mohamed Al-Arabi",
                background_color: "#8FAEFF"
            },
            {
                title: "BKID-0007, Mohamed Al-Arabi",
                background_color: "#8FAEFF"
            },
        ],
    },
]

export const UserList = [
    {
        image: "https://www.dmarge.com/wp-content/uploads/2021/01/dwayne-the-rock--480x320.jpg",
        name: "Bianca Porter",
        role: "Employee role here"
    }
]

export const weeklyCalendarData = [
    {
        name: "Debra Ortiz",
        role: "Role Here",
        image: "https://thumbs.dreamstime.com/b/closeup-photo-funny-excited-lady-raise-fists-screaming-loudly-celebrating-money-lottery-winning-wealthy-rich-person-wear-casual-172563278.jpg",
        process: "60",
        date: "18-10-2022",
        events: [
            {
                time: "01:00",
                data: [
                    {
                        title: "11111111",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                    {
                        title: "22222222",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                ]
            },
            {
                time: "04:00",
                data: [
                    {
                        title: "33333333",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    }
                ]
            },
        ]
    },
    {
        name: "Debra Ortiz",
        role: "Role Here",
        image: "https://thumbs.dreamstime.com/b/closeup-photo-funny-excited-lady-raise-fists-screaming-loudly-celebrating-money-lottery-winning-wealthy-rich-person-wear-casual-172563278.jpg",
        process: "60",
        date: "17-10-2022",
        events: [
            {
                time: "01:00",
                data: [
                    {
                        title: "11111111",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                    {
                        title: "22222222",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                ]
            },
            {
                time: "04:00",
                data: [
                    {
                        title: "33333333",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    }
                ]
            },
        ]
    },
    {
        name: "Debra Ortiz",
        role: "Role Here",
        image: "https://thumbs.dreamstime.com/b/closeup-photo-funny-excited-lady-raise-fists-screaming-loudly-celebrating-money-lottery-winning-wealthy-rich-person-wear-casual-172563278.jpg",
        process: "60",
        date: "18-10-2022",
        events: [
            {
                time: "02:00",
                data: [
                    {
                        title: "11111111",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                    {
                        title: "22222222",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                ]
            },
            {
                time: "05:00",
                data: [
                    {
                        title: "33333333",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    }
                ]
            },
        ]
    },
    {
        name: "Debra Ortiz",
        role: "Role Here",
        image: "https://thumbs.dreamstime.com/b/closeup-photo-funny-excited-lady-raise-fists-screaming-loudly-celebrating-money-lottery-winning-wealthy-rich-person-wear-casual-172563278.jpg",
        process: "60",
        date: "18-10-2022",
        events: [
            {
                time: "03:00",
                data: [
                    {
                        title: "11111111",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                    {
                        title: "22222222",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                ]
            },
            {
                time: "06:00",
                data: [
                    {
                        title: "33333333",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    }
                ]
            },
        ]
    },
    {
        name: "Debra Ortiz",
        role: "Role Here",
        image: "https://thumbs.dreamstime.com/b/closeup-photo-funny-excited-lady-raise-fists-screaming-loudly-celebrating-money-lottery-winning-wealthy-rich-person-wear-casual-172563278.jpg",
        process: "60",
        date: "18-10-2022",
        events: [
            {
                time: "07:00",
                data: [
                    {
                        title: "11111111",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                    {
                        title: "22222222",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    },
                ]
            },
            {
                time: "08:00",
                data: [
                    {
                        title: "33333333",
                        status: "Meter Reading",
                        ins: "INS-423561"
                    }
                ]
            },
        ]
    },
]