import * as React from "react"

export const ConvertToResrvation = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={294.648}
        height={220}
        {...props}
    >
        <defs>
            <clipPath id="b">
                <path
                    data-name="Rectangle 52463"
                    transform="rotate(38 9.4 27.314)"
                    fill="#fff"
                    stroke="#707070"
                    d="M0 0h19.518v30.566H0z"
                />
            </clipPath>
            <filter
                id="a"
                x={0}
                y={15.871}
                width={141.256}
                height={150.287}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={12} />
                <feGaussianBlur stdDeviation={8} result="blur" />
                <feFlood floodOpacity={0.078} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g data-name="Group 98961">
            <path
                data-name="Rectangle 52464"
                fill="none"
                d="M15.647 0h279v220h-279z"
            />
            <g data-name="Group 98874">
                <path
                    data-name="Path 95128"
                    d="M281.303 130.581a82.61 82.61 0 0 1-11.489 15.56 105.01 105.01 0 0 1-27.529 20.728 406.1 406.1 0 0 1-49.724 22.609 335.598 335.598 0 0 1-23.264 7.741q-6.381 1.859-12.332 3.32a254.78 254.78 0 0 1-35.548 6.251h-35a28.015 28.015 0 0 1-1.178-.193 88.959 88.959 0 0 1-25.992-4.831A74.394 74.394 0 0 1 39.424 191.6a59.52 59.52 0 0 1-15.051-15.9c-20.4-31.766 28.1-85.778 90.623-123.774h.007q3.575-2.174 7.2-4.271a372.427 372.427 0 0 1 24.765-13.113A331.842 331.842 0 0 1 175.94 22.2h.02a.065.065 0 0 1 .034-.01h.02c.089-.044.2-.085.285-.122h.021q5.719-2.1 11.394-3.9C260.158-4.879 314.14 72.72 281.301 130.589"
                    fill="#e8ffff"
                />
                <g data-name="Group 98878">
                    <g data-name="Group 98876">
                        <path
                            data-name="Path 95154"
                            d="m77.67 48.514 34.278-4.209a2.642 2.642 0 0 1 2.695 2.54l7.572 61.672a2.642 2.642 0 0 1-2 3.117l-34.278 4.21a2.642 2.642 0 0 1-2.694-2.541L75.67 51.63a2.642 2.642 0 0 1 2-3.117Z"
                            fill="#8ed58e"
                        />
                        <g data-name="Path 95155" fill="none" strokeLinecap="round">
                            <path d="m82.032 53.901 26.986-3.313a.238.238 0 0 1 .23.236l6.77 55.139a.238.238 0 0 1-.166.284l-26.985 3.313a.238.238 0 0 1-.23-.235l-6.771-55.14a.238.238 0 0 1 .166-.284Z" />
                            <path
                                d="M82.035 53.901c-.11.014-.187.141-.17.285l6.771 55.139c.018.144.122.249.233.235l26.982-3.313c.112-.013.188-.14.17-.285l-6.77-55.139c-.018-.143-.122-.249-.234-.235l-26.982 3.313m-.243-1.985 26.982-3.313c1.205-.148 2.31.739 2.462 1.977l6.77 55.139c.152 1.238-.705 2.365-1.91 2.513l-26.983 3.313c-1.205.148-2.31-.738-2.462-1.976l-6.77-55.14c-.152-1.237.705-2.365 1.91-2.513Z"
                                fill="#62a762"
                            />
                        </g>
                        <path
                            data-name="Path 95156"
                            d="M97.936 71.87a7.411 7.411 0 1 1-6.453 8.26 7.411 7.411 0 0 1 6.453-8.26Z"
                            fill="#62a762"
                        />
                        <path
                            data-name="Path 95157"
                            d="M96.095 56.877a4.56 4.56 0 1 1-3.97 5.081 4.56 4.56 0 0 1 3.97-5.081Z"
                            fill="#62a762"
                        />
                        <path
                            data-name="Path 95158"
                            d="M100.471 92.521a4.56 4.56 0 1 1-3.97 5.082 4.56 4.56 0 0 1 3.97-5.082Z"
                            fill="#62a762"
                        />
                    </g>
                    <g data-name="Group 98966">
                        <path
                            data-name="Path 95154"
                            d="m25.235 108.588 24.876-30.72a2.782 2.782 0 0 1 3.918-.31l48.288 39.103a2.782 2.782 0 0 1 .512 3.897l-24.877 30.72a2.782 2.782 0 0 1-3.918.31l-48.288-39.102a2.782 2.782 0 0 1-.511-3.898Z"
                            fill="#8ed58e"
                        />
                        <g data-name="Path 95155" fill="none" strokeLinecap="round">
                            <path d="m31.928 109.664 20.628-25.473a.254.254 0 0 1 .358-.025l43.172 34.96a.254.254 0 0 1 .05.356l-20.628 25.473a.254.254 0 0 1-.358.025l-43.173-34.96a.254.254 0 0 1-.049-.356Z" />
                            <path
                                d="M31.928 109.665c-.085.105-.063.264.05.355l43.172 34.96c.113.092.273.08.358-.025l20.628-25.474c.085-.105.063-.263-.05-.355l-43.172-34.96c-.113-.091-.273-.08-.358.025l-20.628 25.474m-1.555-1.259 20.629-25.474a2.256 2.256 0 0 1 3.17-.32l43.173 34.96a2.256 2.256 0 0 1 .346 3.168l-20.629 25.474a2.256 2.256 0 0 1-3.17.32l-43.173-34.96a2.256 2.256 0 0 1-.346-3.168Z"
                                fill="#62a762"
                            />
                        </g>
                        <path
                            data-name="Path 95156"
                            d="M57.608 109.371a7.411 7.411 0 1 1 1.096 10.424 7.411 7.411 0 0 1-1.096-10.424Z"
                            fill="#62a762"
                        />
                        <path
                            data-name="Path 95157"
                            d="M45.868 99.866a4.56 4.56 0 1 1 .674 6.413 4.56 4.56 0 0 1-.674-6.413Z"
                            fill="#62a762"
                        />
                        <path
                            data-name="Path 95158"
                            d="M73.777 122.466a4.56 4.56 0 1 1 .674 6.413 4.56 4.56 0 0 1-.674-6.413Z"
                            fill="#62a762"
                        />
                    </g>
                    <g data-name="Group 98967">
                        <path
                            data-name="Path 95154"
                            d="m23.954 107.711 25.933-29.833a2.782 2.782 0 0 1 3.927-.173l46.894 40.764a2.782 2.782 0 0 1 .375 3.913L75.15 152.215a2.782 2.782 0 0 1-3.927.173l-46.894-40.764a2.782 2.782 0 0 1-.375-3.913Z"
                            fill="#8ed58e"
                        />
                        <g data-name="Path 95155" fill="none" strokeLinecap="round">
                            <path d="M30.606 109.02 52.11 84.282a.254.254 0 0 1 .359-.013l41.926 36.446a.254.254 0 0 1 .037.357L72.928 145.81a.254.254 0 0 1-.359.013l-41.926-36.446a.254.254 0 0 1-.037-.357Z" />
                            <path
                                d="M30.605 109.02c-.088.102-.071.262.038.357l41.926 36.446c.11.095.27.09.359-.013l21.504-24.738c.089-.102.072-.262-.037-.357L52.468 84.27c-.109-.095-.27-.089-.358.013L30.605 109.02m-1.509-1.312L50.601 82.97a2.256 2.256 0 0 1 3.18-.21l41.926 36.446a2.256 2.256 0 0 1 .235 3.178l-21.505 24.738a2.256 2.256 0 0 1-3.18.21l-41.926-36.446a2.256 2.256 0 0 1-.235-3.178Z"
                                fill="#62a762"
                            />
                        </g>
                        <path
                            data-name="Path 95156"
                            d="M56.28 109.623a7.411 7.411 0 1 1 .732 10.456 7.411 7.411 0 0 1-.731-10.456Z"
                            fill="#62a762"
                        />
                        <path
                            data-name="Path 95157"
                            d="M44.88 99.714a4.56 4.56 0 1 1 .45 6.433 4.56 4.56 0 0 1-.45-6.433Z"
                            fill="#62a762"
                        />
                        <path
                            data-name="Path 95158"
                            d="M71.983 123.274a4.56 4.56 0 1 1 .45 6.433 4.56 4.56 0 0 1-.45-6.433Z"
                            fill="#62a762"
                        />
                    </g>
                    <g data-name="Group 98875">
                        <g transform="rotate(-1.26 .335 8.4) scale(.999)" filter="url(#a)">
                            <path
                                data-name="Path 95154"
                                d="m26.56 55.811 37.367-25.975c1.414-.983 3.51-.412 4.681 1.273l46.253 66.539c1.172 1.685.973 3.85-.438 4.83l-37.368 25.976c-1.411.981-3.509.413-4.68-1.273L26.121 60.642c-1.172-1.685-.974-3.85.437-4.831Z"
                                fill="#97e697"
                            />
                        </g>
                        <g data-name="Path 95155" fill="none" strokeLinecap="round">
                            <path d="m33.686 59.523 31.26-21.909a.313.313 0 0 1 .425.11l40.672 58.031a.313.313 0 0 1-.042.438l-31.26 21.909a.313.313 0 0 1-.426-.11L33.644 59.96a.313.313 0 0 1 .042-.438Z" />
                            <path
                                d="M33.686 59.524c-.13.09-.148.286-.042.437l40.671 58.03c.106.152.297.201.426.111L106 96.193c.129-.09.147-.286.041-.437L65.371 37.725c-.106-.151-.296-.2-.425-.11l-31.26 21.909m-1.148-1.638 31.26-21.91c1.032-.723 2.472-.453 3.211.6l40.671 58.032c.74 1.054.5 2.5-.531 3.223l-31.26 21.909c-1.032.723-2.473.454-3.212-.6l-40.67-58.031c-.74-1.054-.501-2.5.53-3.223Z"
                                fill="#62a762"
                            />
                        </g>
                        <path
                            data-name="Path 95156"
                            d="M68.016 75.171a10.238 10.238 0 1 1-2.508 14.26 10.238 10.238 0 0 1 2.508-14.26Z"
                            fill="#62a762"
                        />
                        <path
                            data-name="Path 95157"
                            d="M56.62 59.602a5.1 5.1 0 1 1-1.249 7.105 5.1 5.1 0 0 1 1.25-7.105Z"
                            fill="#62a762"
                        />
                        <path
                            data-name="Path 95158"
                            d="M83.847 96.61a5.1 5.1 0 1 1-1.25 7.105 5.1 5.1 0 0 1 1.25-7.104Z"
                            fill="#62a762"
                        />
                    </g>
                    <g
                        data-name="Mask Group 6359"
                        transform="translate(20.892 120.917)"
                        clipPath="url(#b)"
                    >
                        <g data-name="Group 98880">
                            <path
                                data-name="Path 95169"
                                d="M34.137 21.446a13.893 13.893 0 0 1-13.8 13.981 13.893 13.893 0 0 1-13.8-13.981 13.893 13.893 0 0 1 13.8-13.982 13.893 13.893 0 0 1 13.8 13.982"
                                fill="#f69e1d"
                            />
                            <path
                                data-name="Path 95170"
                                d="M36.624 21.446a14.133 14.133 0 0 1-14.281 13.981 14.133 14.133 0 0 1-14.28-13.981 14.134 14.134 0 0 1 14.28-13.982 14.133 14.133 0 0 1 14.28 13.982"
                                fill="#ffe200"
                            />
                            <path
                                data-name="Path 95171"
                                d="M34.141 21.445a11.676 11.676 0 0 1-11.794 11.55 11.676 11.676 0 0 1-11.8-11.55 11.676 11.676 0 0 1 11.8-11.55 11.676 11.676 0 0 1 11.8 11.55"
                                fill="#f9a41a"
                            />
                            <path
                                data-name="Path 95172"
                                d="M33.74 21.446a11.278 11.278 0 0 1-11.391 11.157 11.278 11.278 0 0 1-11.4-11.157 11.278 11.278 0 0 1 11.4-11.157 11.278 11.278 0 0 1 11.4 11.157"
                                fill="#faba14"
                            />
                            <path
                                data-name="Path 95173"
                                d="M26.058 24.657v1.813l-6.815-.055v-1.672l3.306-3.587a5.852 5.852 0 0 0 .455-.551 3.864 3.864 0 0 0 .325-.53 2.615 2.615 0 0 0 .195-.51 1.939 1.939 0 0 0 .065-.489 1.548 1.548 0 0 0-.084-.534.959.959 0 0 0-.253-.384 1.1 1.1 0 0 0-.419-.234 2.062 2.062 0 0 0-.59-.083 3.124 3.124 0 0 0-.313.012q-.158.014-.32.044t-.327.076q-.165.046-.334.108t-.336.138q-.167.076-.333.166t-.329.194q-.164.1-.326.222l-.692-1.621a3.411 3.411 0 0 1 .317-.249 3.937 3.937 0 0 1 .359-.214q.19-.1.4-.191t.445-.165q.232-.075.467-.13t.472-.093q.24-.036.477-.053t.483-.014a5.365 5.365 0 0 1 .733.06 4.1 4.1 0 0 1 .655.152 3.336 3.336 0 0 1 .577.248 2.989 2.989 0 0 1 .5.342 2.674 2.674 0 0 1 .4.422 2.429 2.429 0 0 1 .287.486 2.584 2.584 0 0 1 .172.549 3.162 3.162 0 0 1 .057.613 4.153 4.153 0 0 1-.022.434 3.819 3.819 0 0 1-.067.417 3.637 3.637 0 0 1-.112.4 3.6 3.6 0 0 1-.156.384q-.089.188-.209.386t-.27.407q-.15.209-.33.428t-.391.45l-2.204 2.372Z"
                                fill="#ffe200"
                            />
                        </g>
                    </g>
                </g>
                <path
                    data-name="Path 95129"
                    d="M269.813 79.988v66.154a105.008 105.008 0 0 1-27.529 20.728 406.007 406.007 0 0 1-49.725 22.609V79.988Z"
                    fill="#99c4c8"
                />
                <path
                    data-name="Path 95130"
                    d="m217.838 22.06-32.584 67.085H86.261l51.61-66.84Z"
                    fill="#f7ecde"
                />
                <path
                    data-name="Path 95131"
                    d="m141.778 22.305-51.61 66.84h2.943v117.647h28.306a254.786 254.786 0 0 0 35.548-6.251q5.949-1.467 12.332-3.321c7.34-2.142 15.106-4.7 23.264-7.741V82.144Z"
                    fill="#68a7ad"
                />
                <path
                    data-name="Path 95132"
                    d="M142.11 55.602h-.947a13.364 13.364 0 0 0-13.367 13.364v31.922h27.241V68.532a12.93 12.93 0 0 0-12.93-12.93"
                    fill="#e8ffff"
                />
                <path
                    data-name="Path 95133"
                    d="M280.576 92.867h-89.858l-53.11-70.807 29.9.006 58.409.009Z"
                    fill="#f7ecde"
                />
                <path
                    data-name="Path 95134"
                    d="M280.575 92.866H264.11l-96.6-70.8 58.409.009Z"
                    fill="#e9dac1"
                />
                <path
                    data-name="Path 95135"
                    d="m130.988 107.881-23.2 47.761H37.311l36.744-47.586Z"
                    fill="#f7ecde"
                />
                <path
                    data-name="Path 95136"
                    d="m76.166 108.056-36.743 47.586v35.959a74.386 74.386 0 0 0 19.818 10.165 88.964 88.964 0 0 0 25.994 4.831s.4.078 1.178.193h23.332v-51.148h.154Z"
                    fill="#68a7ad"
                />
                <path
                    data-name="Path 95137"
                    d="M169.297 155.638v41.579q-6.381 1.859-12.332 3.32a254.833 254.833 0 0 1-35.548 6.25h-11.67v-51.149Z"
                    fill="#99c4c8"
                />
                <path
                    data-name="Path 95138"
                    d="M175.84 158.467h-63.972l-37.814-50.411h19.055l43.817.009Z"
                    fill="#f7ecde"
                />
                <path
                    data-name="Path 95139"
                    d="M88.869 166.245v40.546h-2.455a28.015 28.015 0 0 1-1.178-.193 88.959 88.959 0 0 1-25.994-4.832v-35.032A15.059 15.059 0 0 1 74.3 151.677a14.571 14.571 0 0 1 14.569 14.569"
                    fill="#e8ffff"
                />
                <path
                    data-name="Path 95140"
                    d="M70.73 134.57a3.325 3.325 0 1 1 3.325 3.325 3.325 3.325 0 0 1-3.325-3.325"
                    fill="#e8ffff"
                />
                <path
                    data-name="Path 95141"
                    d="M175.841 158.467H163.7l-70.589-50.409 43.817.009Z"
                    fill="#e9dac1"
                />
                <path
                    data-name="Path 95142"
                    d="M281.303 130.582a82.61 82.61 0 0 1-11.489 15.56 105.009 105.009 0 0 1-27.529 20.728 406.1 406.1 0 0 1-49.726 22.609 335.598 335.598 0 0 1-23.264 7.741q-6.381 1.859-12.332 3.32c1.389-4.309 4.662-9.972 12.332-14.06 5.341-2.845 12.814-4.93 23.264-5.266 38.007-1.212 22.331-23.488 38.625-43.32 9.823-11.954 26.738-11.914 38.628-10a86.041 86.041 0 0 1 11.489 2.683"
                    fill="#92ba92"
                />
            </g>
        </g>
    </svg>
)

