import React from "react";
import { withNavBars } from "../../HOCs";
import { UnitVacancyDetails } from "./unitVacancyDetail";

class UnitVacancyDetailsParent extends React.Component {
    render() {
        return <UnitVacancyDetails />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UnitVacancyDetailsParent, props);
