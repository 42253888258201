import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { PropertyTypeCardStyle } from './style'

export const PropertyTypeCard = ({
    header = "",
    data = [],
    selected = {},
    handleClick = () => false,
    error = false,
    errorMsg = "",
    isRequired = false,
    isReadOnly = false
}) => {
    const classes = PropertyTypeCardStyle()


    const hanldeChange = (val) => {
        if (!isReadOnly) {
            handleClick(val)
        }
    }
    return (
        <Stack spacing={1}>
            <Typography className={classes.header}>{header}{isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                *
            </Typography>}</Typography>
            <Stack direction={"row"}>
                {
                    data?.map((e) => {
                        return (
                            <Box className={selected?.title === e?.title ? classes.selectedCard : classes.card} onClick={() => hanldeChange(e)}>
                                <Stack alignItems={"center"} justifyContent={"center"} pt={2} spacing={0.5}>
                                    {selected?.title === e?.title ? e?.selectedIcon : e?.notSelectedIcon}
                                    <Typography className={selected?.title === e?.title ? classes.selectedLabel : classes.label}>
                                        {e?.title}
                                    </Typography>
                                </Stack>
                            </Box>
                        )
                    })
                }
            </Stack>
            {error && (
                <Typography variant={"caption"} color={"error"}>
                    {errorMsg}
                </Typography>
            )}
        </Stack >
    )
}