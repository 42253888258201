export const Visitor = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98445">
            <g data-name="Group 98436" fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 94959"
                    d="M15.024 7v2.3h.952V7Zm-4.458 2.192 1.662 1.626c.237-.231.437-.428.675-.658L11.24 8.532Zm7.532.967.674.658 1.661-1.626c-.236-.23-.438-.426-.674-.659Zm1.553 2.735v.934H22v-.934ZM9 12.894v.933h2.349v-.932Z"
                />
                <path
                    data-name="Path 94960"
                    d="M17.596 16.691h-4.191a1.44 1.44 0 0 0-1.362 1.5v3.811h6.917v-3.811a1.44 1.44 0 0 0-1.364-1.5Z"
                />
                <path
                    data-name="Path 94961"
                    d="M15.498 16.03a2.317 2.317 0 1 0-2.363-2.316 2.341 2.341 0 0 0 2.363 2.316Z"
                />
            </g>
            <path data-name="Rectangle 52141" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
