import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import React, { useState } from "react"
import DeleteIcon from "../../../assets/delete"
import { TextBox } from "../../../components"
import { CustomSelectBox } from "../../../components/customSelectBox"
import { enumSelect, enum_types, useWindowDimensions } from "../../../utils"
import { useStyles } from "../../requestView/steps/style"
import { addId, calculateAmount, initialState } from "../../requestView/steps/utils"
import { TableHeader } from "../utils"


export const ProposedBOM = ({ t = () => false, state = {}, updateState = () => false, data = {} }) => {
    const classes = useStyles()
    const [tableRowData, setTableRowData] = useState([])
    const [enumValue, setEnumValue] = React.useState({})
    const size = useWindowDimensions()
    React.useEffect(() => {
        getEnum()
        if (data?.BOM?.length > 0) {
            setTableRowData(addId(data?.BOM))
        }
        //eslint-disable-next-line
    }, [])
    const getEnum = async () => {
        const result = await enumSelect([enum_types.inspection_item_type])
        setEnumValue({
            inspection_item_type: result?.inspection_item_type?.filter((i) => i?.value !== "Product"),
        })
    }
    const updateBOMState = (data, k, v, index, tableName) => {
        const result = calculateAmount(data, k, v, tableRowData)
        setTableRowData(result)
        updateState("BOM", addId(result))
    }
    const addNewBOM = async (tableName) => {
        const result = [...tableRowData, initialState]
        await setTableRowData(addId(result))
        await updateState("BOM", addId(result))
    }
    const removeObjectFromArray = (val, tableName) => {
        const result = tableRowData?.filter((x, i) => { return x?.auto_inc_id !== val?.auto_inc_id })
        // setTotalAmountAfterDeduction(AmountWithDeduction ?? 0)
        setTableRowData(result)
        updateState("BOM", result)

    }

    const InspectionItemManualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.name,
                value: i?.id,
                ...i
            }
        })
        return details
    }
    return (
        <Box height={size?.height - 315} p={2}>
            <Box>
                <Box>
                    <TableContainer className={classes.table} style={{ maxHeight: size?.height - 385 }}>
                        <Table stickyHeader>
                            <TableHead >
                                <TableRow sx={{
                                    "& th": {
                                        color: "#4E5A6B",
                                        backgroundColor: "#F2F4F7"
                                    }
                                }}>
                                    {
                                        TableHeader(t)?.map((e) => {
                                            return (
                                                <TableCell className={classes.headCell} sx={{ width: e?.delete === "delete" ? 30 : e?.width }}>
                                                    <Typography className={classes.headText}> {e?.title} </Typography>
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            {
                                <TableBody>
                                    {tableRowData?.map((val, i) => {
                                        return (
                                            <TableRow>
                                                <TableCell className={classes.bodyCell}>
                                                    <Box sx={{ width: 200 }}>
                                                        <CustomSelectBox
                                                            value={val?.item_type}
                                                            menuOptionWidth={190}
                                                            menuOptionPadding={"0px 8px"}
                                                            options={enumValue?.inspection_item_type}
                                                            onChange={(value) => {
                                                                updateBOMState(val, "item_type", value)
                                                            }}
                                                            menuOptionsHeight={"150px"}
                                                            isReadOnly={(state?.main?.request_status === "Cancelled" || state?.main?.request_status === "Closed")}
                                                            placeholder={t("Select the Item Type")}
                                                        />
                                                    </Box>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>

                                                    <Box sx={{ width: 230 }}>

                                                        <CustomSelectBox
                                                            value={val?.item_category}
                                                            menuOptionWidth={230}
                                                            menuOptionPadding={"0px 8px"}
                                                            networkCallData={
                                                                {
                                                                    path: "/inspection_item_category/get",
                                                                    payload: {
                                                                        "item_type": val?.item_type?.value?.length > 0 ? [val?.item_type?.value] : null,
                                                                        is_active: [true]
                                                                    },
                                                                    mappingVariable: "data",
                                                                    manualResponse: InspectionItemManualResponse
                                                                }
                                                            }
                                                            key={JSON.stringify(val?.item_type)}
                                                            onChange={(value) => {
                                                                updateBOMState(val, "item_category", value)
                                                            }}
                                                            menuOptionsHeight={"150px"}
                                                            isReadOnly={(state?.main?.request_status === "Cancelled" || state?.main?.request_status === "Closed")}
                                                            placeholder={t("Select the Item Category")}
                                                        />
                                                    </Box>

                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Box sx={{ width: 230 }}>
                                                        <CustomSelectBox
                                                            value={val?.item_subCategory}
                                                            menuOptionWidth={230}
                                                            menuOptionPadding={"0px 8px"}
                                                            networkCallData={
                                                                {
                                                                    path: "/inspection_item_subcategory",
                                                                    payload: {
                                                                        "item_type": val?.item_type?.value ? [val?.item_type?.value] : null,
                                                                        "category_id": val?.item_category?.value ? val?.item_category?.value : null,
                                                                        is_active: [true]
                                                                    },
                                                                    mappingVariable: "list",
                                                                    manualResponse: InspectionItemManualResponse
                                                                }
                                                            }
                                                            key={JSON.stringify({ item_category: val?.item_category, item_type: val?.item_type })}
                                                            onChange={(value) => {
                                                                updateBOMState(val, "item_subCategory", value)
                                                            }}
                                                            menuOptionsHeight={"150px"}
                                                            isReadOnly={(state?.main?.request_status === "Cancelled" || state?.main?.request_status === "Closed")}
                                                            placeholder={t("Select the Item Subcategory")}
                                                        />
                                                    </Box>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>

                                                    <Box sx={{ width: 270 }}>

                                                        <CustomSelectBox
                                                            value={val?.itemName}
                                                            menuOptionWidth={270}
                                                            menuOptionPadding={"0px 8px"}
                                                            networkCallData={
                                                                {
                                                                    path: "inspection_items/getAll",
                                                                    payload: {
                                                                        company_id: state?.main?.company,
                                                                        "items": val?.item_type?.value ? [val?.item_type?.value] : null,
                                                                        "category_ids": val?.item_category?.value > 0 ? [val?.item_category?.value] : null
                                                                    },
                                                                    mappingVariable: "data",
                                                                    manualResponse: InspectionItemManualResponse
                                                                }
                                                            }
                                                            key={JSON.stringify(val?.itemTypeCategory)}
                                                            onChange={(value) => {
                                                                updateBOMState(val, "itemName", value)
                                                            }}
                                                            menuOptionsHeight={"150px"}
                                                            isReadOnly={(state?.main?.request_status === "Cancelled" || state?.main?.request_status === "Closed")}
                                                            placeholder={t("Select the Item Name")}
                                                        />
                                                    </Box>

                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Box sx={{ width: 120 }}>
                                                        <TextBox
                                                            label={""}
                                                            placeholder={t("Price")}
                                                            value={val?.price ?? 0}
                                                            onChange={(e) => updateBOMState(val, "price", e?.target?.value, i)}
                                                            height={38}
                                                            padding={"6px 8px"}
                                                            type={"number"}
                                                            textAlign={"right"}
                                                            endAdornment={state?.main?.companyData?.currency_symbol}
                                                            isReadonly={(state?.main?.request_status === "Cancelled" || state?.main?.request_status === "Closed")}
                                                        />

                                                    </Box>

                                                </TableCell>
                                                <TableCell className={classes.bodyCell2}>

                                                    <Box sx={{ width: 55 }}>
                                                        <TextBox
                                                            label={""}
                                                            placeholder={t("QTY")}
                                                            value={val?.qty ?? 0}
                                                            onChange={(e) => updateBOMState(val, "qty", e?.target?.value, i)}
                                                            height={38}
                                                            padding={"6px 8px"}
                                                            type={"number"}
                                                            textAlign={"right"}
                                                            isReadonly={(state?.main?.request_status === "Cancelled" || state?.main?.request_status === "Closed")}
                                                        />

                                                    </Box>

                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                        <Box sx={{ width: 100 }}>
                                                            <TextBox
                                                                label={""}
                                                                placeholder={t("Total Amount")}
                                                                value={Number(val?.amount ?? 0)?.toFixed(2)?.toLocaleString('en-IN') ?? 0}
                                                                // onChange={(e) => updateState(val,"amount",e?.target?.value)}
                                                                isReadonly
                                                                height={38}
                                                                padding={"6px 8px"}
                                                                type={"number"}
                                                                textAlign={"right"}
                                                                endAdornment={state?.main?.companyData?.currency_symbol}
                                                            />

                                                        </Box>
                                                    </Stack>
                                                </TableCell>
                                                {(state?.main?.request_status !== "Cancelled" && state?.main?.request_status !== "Closed") && <TableCell className={classes.bodyCell}>
                                                    <Box sx={{ cursor: "pointer",display:"flex",justifyContent:"center" }}>
                                                        <DeleteIcon onClick={() => removeObjectFromArray(val)} />
                                                    </Box>
                                                </TableCell>}
                                            </TableRow>
                                        )
                                    })}

                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                    {(state?.main?.request_status !== "Cancelled" && state?.main?.request_status !== "Closed") && <Box mt={1} mb={1}><Typography className={classes.addBillLine} onClick={() => addNewBOM()}>{t("Add New BOM Item")}</Typography></Box>}
                </Box>
            </Box>
        </Box>
    )
}