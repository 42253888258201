import * as React from "react"
const CaretUpIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={9} height={6.188} {...props}>
    <path
      fill="#98a0ac"
      d="M8.578 6.188H.422a.422.422 0 0 1-.336-.678L4.164.166a.422.422 0 0 1 .671 0L8.913 5.51a.422.422 0 0 1-.335.678Z"
      data-name="icons8-sort-down (1)"
    />
  </svg>
)
export default CaretUpIcon