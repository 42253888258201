import React from "react";
import Company from "./companyselect";
import { LoginSuccess } from "../../router/access";
import { LocalStorageKeys } from "../../utils";
import { withRouter } from 'react-router-dom';

class CompanyPagetParent extends React.Component {
    componentDidMount() {
        if (localStorage.getItem(LocalStorageKeys.authToken)) {
            if (localStorage.getItem(LocalStorageKeys.permission)) {
                this?.props?.history?.push(LoginSuccess(JSON.parse(localStorage.getItem(LocalStorageKeys.permission))))


            }
        }
    }
    render() {
        return <Company />;
    }
}

export default withRouter(CompanyPagetParent);