import React from "react";
import { withNavBars } from "../../../HOCs";
import CommunityDiscussion  from "./communitydiscuss";

class CommunityCardParent extends React.Component {
  render() {
    return <CommunityDiscussion />;
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(CommunityCardParent,props);
