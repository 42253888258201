import React from "react";
import { withNavBars } from "../../../HOCs";
import VendorEntry from './vendorEntry';

class VendorEntryParent extends React.Component {
  render() {
    return <VendorEntry {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(VendorEntryParent, props);
