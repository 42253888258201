import { Box, Grid, Typography } from "@mui/material";
import { Pdfpreview } from "../../createquotation/steps/pdfPreview";
import { tabStyles } from "./styles";
export const QuotationDetails = ({ data = {} }) => {
    const classes = tabStyles()

    return (
        <div>
            <Box p={2} className={classes.card}>
                {/*quotation details*/}
                <Typography className={classes.title}>QUOTATION DETAILS</Typography>
                <Grid container spacing={2}>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Quotation Number</Typography>
                        <Typography className={classes.sub}>{data?.quotation?.quotation_no}</Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Payment Period</Typography>
                        <Typography className={classes.sub}>{data?.quotation?.lease_period}</Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Currency</Typography>
                        <Typography className={classes.sub}>{data?.symbol}</Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Lines Total Amount</Typography>
                        <Typography className={classes.sub}>{data?.total_amount}</Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Total Taxes</Typography>
                        <Typography className={classes.sub}>{data?.total_tax}</Typography>
                    </Grid>



                </Grid>
            </Box>
            <br />
            <Box p={2} className={classes.card}>
                <Pdfpreview height={280} url={data?.quotation?.quotation_assets?.[0]?.url} />
            </Box>
        </div>
    )
}