import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";
export const CostingApprovalStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 150px)`,
    overflow: "hidden",
  },
  costingCharges: {
    backgroundColor: "#F2F4F7",
    borderRadius: theme.palette.borderRadius,
    padding: "16px"
  },
  acknowledgeTxt: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: Bold
  },
  costingTitle: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: SemiBold
  },
  costingAmount: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: Bold
  },
  finalBox: {
    backgroundColor: "#E4E8EE",
    borderRadius: "6px",
    padding: "8px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0
  },
  addNote: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: "#5078E1",
    cursor: "pointer"
  },
  notesBox: {
    border: "1px solid #CED3DD",
    // padding:"16px",
    borderRadius: theme.palette.borderRadius
  },
  notesLabel: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: Bold
  },
  removeTxt: {
    fontSize: "0.875rem",
    color: "#FF4B4B",
    fontFamily: Bold,
    cursor: "pointer"
  },
  fixBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "16px"
  },
  rejectbtn: {
    backgroundColor: "#FF4B4B",
    "&:hover": {
      backgroundColor: "#FF4B4B",
    }
  },
  approvebtn: {
    backgroundColor: "#5AC782",
    "&:hover": {
      backgroundColor: "#5AC782",
    }
  },
  filterButton: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "12px"
  },
  applynoborder: {
    padding: "0px 40px",
    position: "relative",
  },
  avatars1: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "45px",
    width: "45px",
    backgroundColor: "#F2F4F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  notes: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme?.typography?.color?.secondary,
  },
  title: {
    fontSize: "1rem",
    fontFamily: Bold,
    color: theme?.typography?.color?.primary,
  },
  border: {
    borderLeft: `2px dashed ${theme?.palette?.primary?.main}`,
    padding: "0px 40px",
    position: "relative",
    // "&:last-child": {
    //     borderLeft: "none",
    // },
  },
  breakupTitle: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: Regular
  },
  breakupContent: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: SemiBold,
    float: "right",
  },
  gridBox: {
    background: "#fff",
    borderRadius: 4,
    boxShadow: "0px 0px 16px #00000014",
    height: "100%",
  },
  requestorImage: {
    width: "80px",
    height: "80px",
    borderRadius:"50% !important"
  },
  requestedHdrLabels: {
    fontSize: "0.875rem",
    color: theme.typography.color.secondary,
    fontFamily: SemiBold
  },
  requestedValues: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
    display: "flex",
    justifyContent: "end",
    textAlign: "end"
  },
  reportingBox: {
    border: "1px solid #E4E8EE",
    borderRadius: "389px",
    padding: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  reportingPersonImg: {
    width: "24px",
    height: "24px",
    marginRight: "8px",
    borderRadius:"50% !important",
    fontSize:"0.75rem !important"
  },
  requestDetailsBox: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "8px"
  },
  requestTitle: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: Bold
  },
  statusBox: {
    borderRadius: theme.palette.borderRadius,
    fontSize: '0.75rem',
    fontFamily: SemiBold,
    color: "#FFFFFF",
    padding: "5px"
  },
  requestNo: {
    fontSize: "0.875rem",
    color: theme.typography.color.secondary,
    fontFamily: Regular
  },
  requestdot: {
    width: "6px",
    height: "6px",
    backgroundColor: theme.typography.color.secondary,
    borderRadius: "50%"
  },
  BillingAmountTxt: {
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    color: theme.typography.color.tertiary
  },
  itemName: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: Regular
  },
  viewDetailsTxt: {
    color: "#5078E1",
    fontFamily: SemiBold,
    fontSize: "0.875rem",
    cursor:"pointer"
  },
  finalamttext:{
    color:theme.typography.color.primary,
    fontFamily: SemiBold,
    fontSize: "0.875rem",
  }
}))