import { Box, Typography } from "@mui/material"
import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { SearchFilter } from "../../../components"
import { Cards } from "./card"

export const ChooseProperty = ({ t, propertyData = [], updateState = () => false, selectedProperty = {}, fetchMoreData = () => false, handleSearch = () => false, searchText = "" }) => {

    return (
        <Box p={2}>
            <SearchFilter placeholder={t("Search property")} value={searchText} handleChange={(value) => handleSearch(value)} />
            <Box mt={"8px"}>
            <InfiniteScroll
                dataLength={propertyData?.length ?? ""}
                next={fetchMoreData}
                hasMore={true}
                height={263}
            >
                <Box mt={2}>
                    {propertyData?.length > 0 ? propertyData?.map((val) => {
                        return (
                            <Cards details={val} isSelect={val?.id === selectedProperty?.id ? true : false} onClick={() => updateState("selectedProperty", val)} />
                        )
                    })
                        :
                        <Typography textAlign={"center"}>{t("No Data Found")}</Typography>
                    }
                </Box>
            </InfiniteScroll>
            </Box>
        </Box>
    )
}