import { BussinessTripIcon } from "../assets/timeOffRequest/bussinessTrip";
import { CausalLeaveIcon } from "../assets/timeOffRequest/causalLeave";
import { DisabilityLeaveIcon } from "../assets/timeOffRequest/disabilityLeave";
import { EarnedLeaveIcon } from "../assets/timeOffRequest/earnedLeave";
import { HolidayPayIcon } from "../assets/timeOffRequest/holidayleave";
import { MedicalLeaveIcon } from "../assets/timeOffRequest/medicalLeave";
import { ParentalLeaveIcon } from "../assets/timeOffRequest/parentalLeave";
import { PersonalLeaveIcon } from "../assets/timeOffRequest/personalLeave";
import { ShortLeaveIcon } from "../assets/timeOffRequest/shortLeave";
import { SickLeaveIcon } from "../assets/timeOffRequest/sickLeave";
import { WFHIcon } from "../assets/timeOffRequest/wfh";

export const PaidLeaveList = [
    {
        icon: <SickLeaveIcon />,
        name: "Sick Leave",
        total: "10",
        available: "5",
    },
    {
        icon: <CausalLeaveIcon />,
        name: "Causal Leave",
        total: "10",
        available: "8",
    },
    {
        icon: <ShortLeaveIcon />,
        name: "Short Leave",
        total: "10",
        available: "5",
    },
    {
        icon: <EarnedLeaveIcon />,
        name: "Earned Leave",
    },
    {
        icon: <ParentalLeaveIcon />,
        name: "Parental Leave",
    },
    {
        icon: <DisabilityLeaveIcon />,
        name: "Disability Leave",
    },
    {
        icon: <BussinessTripIcon />,
        name: "Bussiness Trip",
    },
    {
        icon: <HolidayPayIcon />,
        name: "Holiday Pay",
    },
    {
        icon: <WFHIcon />,
        name: "Work From Home",
        total: "10",
        available: "5",
    },
]

export const UnPaidLeaveList = [
    {
        icon: <PersonalLeaveIcon />,
        name: "Personal Leave"
    },
    {
        icon: <MedicalLeaveIcon />,
        name: "Medical Leave"
    }
]

//switch Icons
export const SwitchIcons = (val) => {
    switch (val) {
        case "Sick Leave":
            return <SickLeaveIcon />
        case "Casual Leave":
            return <CausalLeaveIcon />
        case "Personal Leave":
            return <PersonalLeaveIcon />
        case "Disability Leave":
            return <DisabilityLeaveIcon />
        case "Earned Leave":
            return <EarnedLeaveIcon />
        case "Parental Leave":
            return <ParentalLeaveIcon />
        case "Short Leave":
            return <ShortLeaveIcon />
        case "Holiday Pay":
            return <HolidayPayIcon />
        case "Medical Leave":
            return <MedicalLeaveIcon />
        case "Business Trip":
            return <BussinessTripIcon />
        case "Work From Home":
            return <WFHIcon />
        default:
            return <CausalLeaveIcon />
    }
}