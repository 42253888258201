import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../../utils";
export const useStylesCreation = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: "4px !important",
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    height: (props) => (props?.height ? props?.height : "100%"),
    padding: (props) => props?.padding ?? "19px",
    minHeight: (props) => (props?.maxHeight ?? "100%"),
    width: "100%",
    overflow: "auto",
    display: 'flex',
    flexFlow: (props) => props?.flexFlow ?? "column",
  },
  detailsCard1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  countCard: {
    fontSize:"1.5rem",
    color: theme.typography.color.primary,
    fontFamily: ExtraBold,
  },
  detailsCard2: {
    color: "#091B29",
    fontFamily: SemiBold,
    fontSize:"0.875rem",
    maxWidth: (props) => props?.maxWidth ?? "107px",
    width: "100%",
  },
  titleFont: {
    color: "#091B29",
    fontSize:"1rem",
    fontFamily: ExtraBold,
  },
  flexBoxWithSpace: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: (props) => props?.graphParentPadding ?? "0px",
  },
  graphDataCount: {
    color: "#4E5A6B",
    fontFamily: SemiBold,
    fontSize:"1rem",
    display: 'flex',
    alignItems: 'center'
  },
  graphDataCountNo: {
    color: "#4E5A6B",
    fontFamily: SemiBold,
    fontSize:"1rem",
    display: 'flex',
    alignItems: 'center',
    marginInlineStart: "4px"
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    padding: '5px',
    marginInline: "4px"
  },
  dotParent: {
    display: "flex",
    paddingTop: 0,
    alignItems: "center",
  },
  graphDataTitle: {
    color: "#98A0AC",
    fontFamily: SemiBold,
    fontSize:"0.75rem",
    marginInlineStart: "8px",
  },
  graphmapData: {
    maxWidth: (props) => props?.maxWidthGraph ?? "221px",
    width: "100%",
    margin: "0 auto",
  },
  areaMeter: {
    color: "#091B29",
    fontSize:"1.125rem",
    fontFamily: Bold,
  },
  map: {
    borderRadius: theme.palette.borderRadius,
    overflow: "hidden",
    height: "100%",
    minHeight: '500px',
    position: "relative",
  },
  searchBox: {
    // position: "absolute",
    top: 12,
    right: 0,
    left: 0,
    // zIndex: 9999,
    padding: "0px 10px",
    // width: "85%",
    display: "flex",
    justifyContent: "center"
  },
  optionBox: {
    position: "absolute",
    top: 12,
    right: 0,
    left: 0,
    zIndex: 9999,
    padding: "16px",
    width: "280px",
    // marginLeft:"140px",
    backgroundColor: "white",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius
  },

  listText: {
    backgroundColor: "#fff",
    width: "120px !important",
    color: "#1C1C1C",
    boxShadow: "#5C86CB2E",
    borderRadius: "4px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  listText1: {
    width: "120px !important",
    color: "#98A0AC",
    borderRadius: "4px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  tabname: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    cursor: "pointer",
  },
  list: {
    backgroundColor: "#F5F7FA",
    display: "flex",
    padding: "5px",
    borderRadius: "4px",
    "&.MuiListItem-root": {
      width: "100px !important",
      padding: "0px!important",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      cursor: "pointer",
      borderRadius: " 4px",
    },
  },
  cardNew: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  Expensestitle: {
    fontSize:"0.875rem",
    fontFamily: ExtraBold,
  },
  grid: {
    display: "flex",
    padding: "0px 12px",
    overflow: "auto",
    // alignItems: "center",
  },
  num: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: Regular,
  },
  noData: {
    textAlign: "center",
    marginTop: "30%",
    [theme.breakpoints.down("md")]: {
      marginTop: "10%",
    },
  },
  weatherBox: {
    maxWidth: "140px",
    width: "100%",
    borderRadius: "4px",
    backgroundColor: theme.palette.weather.main,
    padding: "16px",
    position: "absolute",
    bottom: 16,
    right: 16,
  },
  totalUnit: {
    color: theme.typography.color.white,
    fontSize:"1rem",
    fontFamily: Regular,
  },
  finder: {
    backgroundColor: "#5078E1",
    padding: "10px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  noDataGraph: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  },
  graphMargin: {
    marginTop: (props) => props?.margin ?? "20px",
  },
  requestTab: {
    padding: "12px 24px 24px",
  },
  scrollLgend: {
    maxHeight: (props) => props?.maxHeightGraph ?? "102px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  graphParentFlex: {
    display: "flex",
    alignItems: "center",
  },
  dividerBox: {
    padding: "12px",
  },
  dividerBoxText: {
    color: "#FFFFFF",
    fontSize:"0.875rem",
    fontFamily: Bold,
  },
  cardHandover: {
    display: 'flex',
    alignItems: 'center'
  },
  gridGraphParent: {
    paddingLeft: (props) => props?.gridGraphParentPadding ?? '55px !important',
  },
  mapRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  zoomInOut: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    // zIndex: 9999,
    padding: "0px 10px",
    height: 'auto',
    width: "auto"
  },
  countBox: {
    borderRadius: "25px",
    padding: "3px",
    color: "white",
    width: "30px",
    fontSize:"0.75rem"
  },
  tabtitle: {
    fontSize:"0.875rem",
    fontFamily: Bold,
    marginInlineStart: "8px"
  },
  viewBox: {
    backgroundColor: "#F1F7FF",
    borderRadius: theme.palette.borderRadius,
    padding: "5px",
    cursor: "pointer"
  },
  viewMapTitle: {
    fontSize:"0.875rem",
    color: "#5078E1",
    fontFamily: SemiBold
  },
  resourceBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRight: "1px solid #E4E8EE",
    padding: "5px",
  },
  cutomselectdrp: {
    backgroundColor: "white",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius
  },
  sourceName: {
    fontSize:"0.875rem",
    color: theme.typography.color.primary,
    fontFamily: SemiBold,
    width: "150px"
  },
  resourceImg: {
    width: "28px",
    height: "28px"
  },
  resource: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "5px",
    width: 220,
  },
  selectedResource: {
    borderRadius: theme.palette.borderRadius,
    padding: "5px",
    width: 220,
    backgroundColor: "#F1F7FF",
    border: "1px solid #5078E1"
  },
  resourceName: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.primary,
    width: "155px"
  },
  resourceSubName: {
    fontSize:"0.75rem",
    fontFamily: Regular,
    color: theme.typography.color.secondary,
    width: "155px"
  }
}));
