export const Tenant = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98422">
            <path data-name="Rectangle 52123" fill="none" d="M0 0h30v30H0z" />
            <path
                d="M20.011 7.78h1.317v2.928l-1.317-.987Zm1.317 13.633V13.71l-5.743-4.323a.145.145 0 0 0-.18 0L9.667 13.71v7.7a.587.587 0 0 0 .586.59h10.484a.587.587 0 0 0 .586-.586Zm1.394-9.036-7.137-5.346a.145.145 0 0 0-.179 0l-7.133 5.347a.685.685 0 0 0-.143.947l.023.032a.651.651 0 0 0 .451.274.651.651 0 0 0 .507-.131l5.993-4.512a.644.644 0 0 1 .782 0l5.989 4.512a.682.682 0 0 0 .962-.144l.023-.031a.685.685 0 0 0-.143-.947ZM11.741 17.2a3.754 3.754 0 1 1 3.754 3.754 3.754 3.754 0 0 1-3.754-3.754Zm3.754 3.254a3.239 3.239 0 0 1-2.09-.762 2.26 2.26 0 0 1 2.09-1.379 2.287 2.287 0 0 1 2.09 1.379 3.24 3.24 0 0 1-2.09.762Zm-1-3.732a1 1 0 1 1 1 1 1 1 0 0 1-1-1Zm4.252.478a3.241 3.241 0 0 1-.787 2.12 2.777 2.777 0 0 0-1.609-1.371 1.5 1.5 0 1 0-1.716 0 2.752 2.752 0 0 0-1.608 1.372 3.254 3.254 0 1 1 5.721-2.12Z"
                fill={props?.fill ?? "#c1c5cb"}
                fillRule="evenodd"
            />
        </g>
    </svg>
)
