import { Box } from "@mui/material";
import React from "react";
import { CompanySettingsList } from "../../../components";
import { useStyles } from "./styles";


export const GlobalControls = ({
    t = () => false,
    data = {},
    updateState = () => false,
}) => {
    const classes = useStyles();
    return (
        <Box>
            <Box className={classes.settingRoot}>
                {/*Visitor Pass Auto Approval*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Visitor Pass Auto Approval")}
                        onChange={(value) => updateState("is_visitor_worker_pass_approval", value)}
                        value={data?.is_visitor_worker_pass_approval}
                        toggle={true}
                    />
                </Box>
                {/*Unit Request Access Auto Approval*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Unit Request Access Auto Approval")}
                        onChange={(value) => updateState("is_unit_request_access_approval", value)}
                        value={data?.is_unit_request_access_approval}
                        toggle={true}
                    />
                </Box>
            </Box>
        </Box>
    )
}