import React from "react"

export const GeneralIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_110224" data-name="Group 110224" transform="translate(-184 -328.5)">
    <rect id="Rectangle_55241" data-name="Rectangle 55241" width="32" height="32" rx="2" transform="translate(184 328.5)" fill="#f1edce"/>
    <g id="Group_110223" data-name="Group 110223" transform="translate(23193 9165)">
      <g id="Group_108965" data-name="Group 108965" transform="translate(-23003 -8830)">
        <rect id="Rectangle_50402" data-name="Rectangle 50402" width="20" height="20" transform="translate(0 0)" fill="none"/>
        <path id="icons8-communicate" d="M12.3,4a1.4,1.4,0,0,0-1.4,1.4v6a.6.6,0,0,0,1.024.424L13.745,10H18.3a1.4,1.4,0,0,0,1.4-1.4V5.4A1.4,1.4,0,0,0,18.3,4ZM6.362,8.5a2.775,2.775,0,1,0,2.775,2.775A2.778,2.778,0,0,0,6.362,8.5ZM3.33,14.8a1.352,1.352,0,0,0-1.392,1.3v1.233C1.937,18.855,3.84,20,6.362,20s4.425-1.146,4.425-2.664V16.1A1.351,1.351,0,0,0,9.4,14.8Z" transform="translate(-0.271 -2.334)" fill="#b3a16d"/>
      </g>
    </g>
  </g>
</svg>
)