import { Box } from "@mui/material";
import { ReservationCard } from "./reservationCard";
export const ReservationHistory = () => {
    const history = [
        {
            reservation_no: "RS124811234",
            is_active: true,
            date: "22 Mar 22 - 22 Mar 22",
        },
        {
            reservation_no: "RS124811234",
            is_active: false,
            date: "22 Mar 22 - 22 Mar 22",
        },
        {
            reservation_no: "RS124811234",
            is_active: false,
            date: "22 Mar 22 - 22 Mar 22",
        }
    ]
    return (
        <Box p={2}>
            {
                history?.map((val) => {
                    return <ReservationCard view={false} data={val} />
                })
            }

        </Box>
    )
}