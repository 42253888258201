export const ContractTableHeading = (t) => {
    const Heading = [
        { title: "", field: "icon" },
        { title: t("contractId"), field: "contract_id" },
        { title: t("date"), field: "date" },
        { title: t("type"), field: "contract_type" },
        { title: t("accountNo"), field: "account_id" },
        { title: t("accountName"), field: "account_name" },
        { title: t("startDate"), field: "start_date" },
        { title: t("endDate"), field: "end_date" },
        { title: t("createdBy"), field: "created_by" },
        { title: t("status"), field: "status" },
        { title: "", field: "publish" }
    ]
    return Heading
}


export const ContractTablePath = [
    "contract_id",
    "date",
    "contract_type",
    "account_id",
    "account_name",
    "start_date",
    "end_date",
    "created_by",
    "status",
]


export const ContractTableData = [
    {
        contract_id: "contract 001",
        contract_type: "type 12",
        account_id: "34324234",
        resource_id: "23423",
        name: "zoro",
        start_and_end: "Feb2 - Feb 28",
    },
    {
        contract_id: "contract 001",
        contract_type: "type 12",
        account_id: "34324234",
        resource_id: "23423",
        name: "zoro",
        start_and_end: "Feb2 - Feb 28",
    },
    {
        contract_id: "contract 001",
        contract_type: "type 12",
        account_id: "34324234",
        resource_id: "23423",
        name: "zoro",
        start_and_end: "Feb2 - Feb 28",
    },
    {
        contract_id: "contract 001",
        contract_type: "type 12",
        account_id: "34324234",
        resource_id: "23423",
        name: "zoro",
        start_and_end: "Feb2 - Feb 28",
    },
    {
        contract_id: "contract 001",
        contract_type: "type 12",
        account_id: "34324234",
        resource_id: "23423",
        name: "zoro",
        start_and_end: "Feb2 - Feb 28",
    },
]


export const Strip = (data) => {
    return [
        {
            count: "01",
            title: "Contract Start & End Date",
            type: "button",
            buttonText: "Add Date",
            date: true,
            label: data?.startEndDate ?? null
        },
        {
            count: "02",
            title: "Choose Account",
            type: "button",
            buttonText: "Add Owner",
            popup: true,
            label: data?.account ?? null
        },
        {
            count: "03",
            title: "Scope Of Work",
            type: "button",
            buttonText: "Add Scope",
            expand: true,
            scopeMenu: true,
            label: {}
        },
        {
            count: "04",
            title: "Commission Rate Configuration",
            type: "toggle",
            expand: true,
            label: {}
        },
        {
            count: "05",
            title: "Contract Details",
            type: "toggle",
            expand: true,
            label: {}
        },
        {
            count: "06",
            title: "Terms & Conditions",
            type: "toggle",
            expand: true,
            label: {}
        },
    ]
}

export const ScopeMenu = ["Property", "Unit"]