import { Box, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import DeleteIcon from '../../../assets/delete';
import IconInfo from '../../../assets/iconInfo';
import { TextBox, ToggleButtonComponent } from '../../../components';
import { CreateDeliveryMilestoneTemplateStyles } from '../style';

export const StepCard = (props) => {
    const { t } = (props)
    const classes = CreateDeliveryMilestoneTemplateStyles()
    const [hover, setHover] = React.useState(false)
    const [hover2, setHover2] = React.useState(false)

    const STATUS_OPTIONS = (t) => [
        { label: t("percentage"), value: "percentage" },
        { label: t("amount"), value: "amount" }
    ]

    const EndTroment = () => {
        return (
            <InputAdornment position="end">
                <Typography>
                    %
                </Typography>
            </InputAdornment>
        )
    }
    return (<>
        {props?.list &&
            <>
                <Grid container spacing={3} onMouseOver={props?.view ? () => setHover(false) : () => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <Grid item xs={10}>
                        <div style={{ backgroundColor: "white" }}  >
                            <Box className={classes.box2}>
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Box>
                                        <Typography className={classes.label}>{`${t("Step")}${props?.index + 1}`}</Typography>
                                    </Box>
                                    <Box position={"relative"} onMouseOver={() => setHover2(true)} onMouseLeave={() => setHover2(false)}>
                                        <IconInfo />
                                        {hover2 &&
                                            <Box position={"absolute"} className={classes.info}>
                                                <Typography className={classes.subHeadingInfo}>
                                                    {props?.list?.description}
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                                <Box height={"10px"} />
                                <Box>
                                    <Typography className={classes.heading}>
                                        {props?.list?.name}
                                    </Typography>

                                </Box>

                                {props?.index === 0 &&
                                    <>
                                        {
                                            props?.template_type !== "variable" &&
                                            <>
                                                <Box height={"10px"} />
                                                <Box>
                                                    <ToggleButtonComponent
                                                        options={STATUS_OPTIONS(t)}
                                                        isMulti={false}
                                                        fullWidth={false}
                                                        value={props?.list?.value_type}
                                                        marginInlineEnd={"8px"}
                                                        onChange={props?.view ? () => false : (value) => {

                                                            props?.updateState("value_type", value, props?.index)

                                                        }}
                                                        isReadOnly={props?.view}

                                                    />
                                                </Box></>
                                        }

                                        <Box height={"10px"} />
                                        {props?.list?.value_type === "amount" ?
                                            <>
                                                {
                                                    props?.index > 0 &&
                                                    <Box>
                                                        <TextBox
                                                            color={"#F2F4F7"}
                                                            label={props?.index === 0 ? t("Enter amount") : t("Enter Percentage")}
                                                            value={props?.list?.value}
                                                            placeholder={props?.index === 0 ? t("Enter amount") : t("Enter Percentage")}
                                                            onChange={props?.view ? () => false : (value) => props?.updateState("value", value.target.value, props?.index)}
                                                            isReadonly={props?.view}
                                                            isrequired
                                                            onBlur={props?.validateFunc}
                                                            type='number'
                                                            onKeyPress={(e) => {
                                                                if (e.key === 'e') {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                        {props?.index}
                                                    </Box>
                                                }

                                            </> :
                                            <Box>
                                                {
                                                    props?.template_type !== "variable" &&
                                                    <TextBox
                                                        label={props?.template_type !== "variable" ? t("Enter Percentage") : t("Enter Amount")}
                                                        placeholder={props?.template_type !== "variable" ? t("Enter Percentage") : t("Enter Amount")}
                                                        value={props?.list?.value}
                                                        onChange={props?.view ? () => false : (value) => props?.updateState("value", value.target.value, props?.index)}
                                                        isReadonly={(props?.view || props?.template_type === "variable") ? true : false}
                                                        color={props?.view ? "#F2F4F7" : false}
                                                        isrequired
                                                        onBlur={props?.validateFunc}
                                                        type='number'
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <Typography>
                                                                    %
                                                                </Typography>
                                                            </InputAdornment>
                                                        }

                                                    />
                                                }

                                            </Box>}
                                    </>
                                }
                                <Box height={"10px"} />
                                {props?.index > 0 &&
                                    <Box>
                                        {
                                            props?.template_type !== "variable" &&
                                            <TextBox
                                                label={props?.template_type === "variable" ? t("Enter Amount") : t("Enter Percentage")}
                                                placeholder={props?.template_type === "variable" ? t("Enter Amount") : t("Enter Percentage")}
                                                value={props?.list?.value}
                                                onChange={props?.view ? () => false : (value) => props?.updateState("value", value.target.value, props?.index)}
                                                color={props?.view ? "#F2F4F7" : false}
                                                isrequired
                                                onBlur={props?.validateFunc}
                                                isReadonly={(props?.view || props?.template_type === "variable") ? true : false}
                                                endAdornment={
                                                    <EndTroment />
                                                }
                                                type='number'
                                                onKeyPress={(e) => {
                                                    if (e.key === 'e') {
                                                        e.preventDefault();
                                                    }
                                                }}

                                            />
                                        }

                                    </Box>
                                }
                            </Box>

                        </div>
                    </Grid>
                    {props?.index === props?.length - 1 && hover &&
                        <>
                            <Grid item xs={2} marginY={"50px"} >
                                <IconButton className={classes.delete} onClick={() => props?.delete(props?.index)}
                                    disabled={props?.view}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </>
                    }

                </Grid>
                {props?.index + 1 !== props?.length && (
                    <Typography
                        className={classes.color}
                    >
                        | &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>

                )}
            </>
        }
    </>

    )
}