import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold, Regular } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  avatar1: {
    padding: "35px",
    background: theme?.typography?.color?.secondary ?? "",
    height: "120px",
    width: "120px",
  },
  avatar2: {
    background: theme?.palette?.background?.tertiary2 ?? "",
    height: "120px",
    width: "120px",
    margin: "auto",
  },
  tabtitle: {
    fontSize:"0.75rem",
    color: theme?.typography?.color?.secondary ?? "",
    fontFamily: Bold,
    marginInlineStart: "7px",
  },
  tabtitle1: {
    fontSize:"0.75rem",
    color: theme?.palette?.primary?.main,
    fontFamily: Bold,
    marginInlineStart: "7px",
  },
  selectBack1: {
    backgroundColor: theme?.palette?.info?.light ?? "",
    display: "flex",
    alignItems: "center",
    padding: "14px 20px",
    position: "relative",
  },
  selectBack: {
    display: "flex",
    alignItems: "center",
    padding: "14px 20px",
    backgroundColor: "white",
  },
  tab: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  borderAbsolute: {
    position: "absolute",
    backgroundColor: theme?.palette?.primary?.main,
    height: "2px",
    width: "100%",
    bottom: 0,
    left: 0,
    rigth: 0,
    borderRadius: "16px 16px 0px 0px",
  },
  roleRoot: {
    padding: "16px",
    overflow: ({ size }) => (size?.width <= 899 && size?.height <= 850) && "overlay",
    height: ({ size }) => (size?.width <= 899 && size?.height <= 850) && `calc(100vh - 230px)`,
  },
  next: {
    marginInlineStart: "8px",
    fontFamily: SemiBold,
    color: "white",
    backgroundColor: theme?.typography?.status?.primary ?? "",
    "&:hover": {
      backgroundColor: theme?.typography?.status?.primary ?? "",
    },
  },
  Cancel: {
    backgroundColor: theme?.typography?.color?.white ?? "",
    color: theme?.typography?.color?.primary ?? "",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: SemiBold,
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    marginRight: "8px",
    "&:hover": {
      backgroundColor: theme?.typography?.color?.white ?? "",
    },
  },
  bottombtn: {
    padding: "11px 16px",
    backgroundColor: "white",
    boxShadow: "0px 0px 16px #00000014",
    display: "flex",
    justifyContent: "space-between",
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    position: "sticky",
    bottom: "0px",
    width: "100%",
    zIndex: 2,
  },
  title: {
    color: theme?.typography?.color?.primary ?? "",
    fontSize:"0.875rem",
    fontFamily: Bold,
  },
  subtitle: {
    color: theme?.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: Regular,
    marginTop: "6px",
  },
  selectAll: {
    color: theme?.typography?.color?.secondary ?? "",
    fontFamily: Bold,
    fontSize:"0.75rem",
    marginLeft: "8px",
  },
  draft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme?.typography?.color?.white,
    backgroundColor: theme?.typography?.color?.tertiary,
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    fontSize:"0.75rem",
    fontFamily: Bold,
  },
  step2Title: {
    color: theme?.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: Bold,
  },
  announsmentDetailsParent: {
    padding: "12px",
    borderRight: "1px solid #E4E8EE",
    [theme.breakpoints.down("md")]: {
      borderRight: "none",
    },
  },
  content: {
    height: `calc(100vh - 363px)`,
    overflow: "auto",
  },
  announsmentDetailsroot: {
    backgroundColor: "white",
    margin: "16px",
    borderRadius: "4px",
  },
  noPadding: {
    paddingTop: "0px !important",
  },
  select: {
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "8px",
    fontSize:"0.75rem",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  unSelect: {
    backgroundColor: "white",
    color: theme?.palette?.background?.announsment ?? "",
    fontSize:"0.75rem",
    padding: "8px",
    fontWeight: 600,
    fontFamily: SemiBold,
    border: `1px solid ${theme?.palette?.background?.secondary}`,
    // borderRadius:"4px 0px 0px 4px",
    "&:hover": {
      backgroundColor: "white ",
    },
  },
  typeName: {
    color: theme?.typography?.color?.primary ?? "",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
  },
  emailPreview: {
    background: "white",
    "&:hover": {
      background: "white",
    },
  },
  detailsPadding: {
    padding: "12px",
  },
  announsmentTitle: {
    fontSize:"1rem",
    fontFamily: Bold,
    color: "white",
  },
  titleBar: {
    backgroundColor: theme?.typography?.status?.primary ?? "",
    padding: "16px 37px",
    position: "relative",
    borderRadius: "8px 8px 0px 0px",
  },
  arrowIcon: {
    color: "white",
    fontSize:"1rem",
    position: "absolute",
    top: "20px",
    bottom: "0",
    left: "14px",
  },
  chip: {
    borderRadius: "4px",
    padding: "4px",
    color: theme?.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: Bold,
    backgroundColor: theme?.palette?.border?.secondary ?? "",
  },
  dateAndTime: {
    borderRadius: "4px",
    padding: "4px",
    color: theme?.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: Regular,
  },
  reactQuil: {
    "& .ql-editor": {
      padding: "0px 0px 16px 0px",
      textAlign: "start",
      fontSize:"0.875rem",
      fontFamily: Bold,
      color: "#091B29"

    },
  },
  detailscontent: {
    height: `calc(100vh - 435px)`,
    overflow: "auto",
    backgroundColor: theme?.palette?.background?.annoumsmentTeritary ?? "",
    padding: "0px 16px 16px",
  },
  detailsBox: {
    backgroundColor: "white",
    padding: "16px",
  },
  addbutton: {
    color: theme.palette.primary.main,
    borderRadius: "22px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  menu: {
    "& .MuiPopover-paper": {
      boxShadow: `0 0px 8px -4px ${theme?.palette?.border?.tertiary}`,
      border: `1px solid ${theme?.palette?.border?.tertiary}`,
      borderRadius: "5px",
    },
  },
  menuItems: {
    display: "flex",
    alignItems: "center",
  },
  menuContant: {
    color: theme?.typography?.color?.primary ?? "",
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    fontWeight: 600,
  },
  flexBox: {
    padding: "10px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fileroot: {
    width: "100%",
    fontSize:"0.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "9px",
    backgroundColor: theme?.palette?.info?.primary ?? "",
    height: "188px",
  },
  imgContainer: {
    width: "100%",
    maxWidth: "188px",
    margin: "0px 10px",
    borderRadius: "12px",
    display: "flex",
    height: "188px",
  },
  delete: {
    color: "red",
    position: "absolute",
    top: "4px",
    right: "8px",
    backgroundColor: "white",
  },
  uploadTittle: {
    fontSize:"0.75rem",
    color: theme?.typography?.status?.primary ?? "",
    fontFamily: SemiBold,
  },
  uploadcount: {
    fontSize:"1rem",
    color: theme?.palette?.upload ?? "",
    fontFamily: SemiBold,
    paddingLeft: "16px",
  },
  imagesParent: {
    padding: "0px !important",
  },
  fileName: {
    color: theme?.typography?.color?.secondary ?? "",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
  },
  type: {
    color: theme?.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: Regular,
  },
  dot: {
    backgroundColor: theme?.palette?.border?.primary ?? "",
    height: "6px",
    width: "6px",
    margin: "0 6px",
    borderRadius: "50%",
  },
  detailsBox1: {
    padding: "6px",
    backgroundColor: theme?.palette?.info?.light ?? "",
    borderRadius: "4px",
    minHeight: "57px",
    height: "100%",
    width: "100%",
    position: 'relative'
  },
  headerBox: {
    backgroundColor: theme?.palette?.background?.annoumsmentTeritary ?? "",
    padding: "9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  headerBox1: {
    backgroundColor: theme?.palette?.background?.annoumsmentTeritary ?? "",
    padding: "11px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  section: {
    position: 'absolute',
    bottom: '1px'
  }
}));
