import React from 'react'
import ImgUpload  from '../../../components/imgUpload'
import { Avatar, Box, Stack } from '@mui/material';
import { stringAvatar } from '../../../utils';
import { ImageUploadStyle } from './style';
import { AlertDialog } from '../../../components';

export const ImageUpload = ({
    images = [],
    setImages = () => false,
    deletedAssetURL = [],
    setDeletedAssetURL = () => false,
    isView = false,
    xpadding = "16px"
}) => {
    const classes = ImageUploadStyle()
    const [uuid, setUUID] = React.useState(1);
    const [openImg, setOpenImg] = React.useState({
        data: {},
        open: false
    })

    const handleAssetFile = (data) => {
        if (data.asset_type === 3) {
            setDeletedAssetURL([...deletedAssetURL, data?.id].filter(Boolean));
            setImages(images?.filter((val) => val?.file_meta?.id !== data?.file_meta?.id));
        } else {
            setDeletedAssetURL([...deletedAssetURL, data?.id].filter(Boolean));
            setImages(images?.filter((val) => val?.url !== data?.url));
        }

    };

    return (
        <>
            {
                isView ?
                    <Stack direction={"row"} className={classes.viewImageBox} spacing={1}>
                        {
                            images?.map((data) => {
                                return (
                                    <>
                                        <Avatar
                                            className={classes.avatar}
                                            src={data?.url}
                                            {...stringAvatar(data?.file_meta?.name)}
                                            onClick={() => setOpenImg({
                                                ...openImg,
                                                data: data,
                                                open: true
                                            })}></Avatar>
                                    </>
                                )
                            })
                        }
                    </Stack>
                    :
                    <Box px={xpadding ?? 2} py={1}>
                        <ImgUpload
                            accept={'image/*'}
                            setUUID={setUUID ?? null}
                            uuid={uuid ?? null}
                            updateImageURL={setImages}
                            handleFile={handleAssetFile}
                            selectedImageURL={images ?? []}
                            id={2}
                            imgHeight={"100px"}
                        />
                    </Box>
            }
            <AlertDialog
                header="Asset View"
                open={openImg?.open}
                onClose={() => {
                    setOpenImg({
                        ...openImg,
                        open: false
                    })
                }}
                component={
                    <Box height="450px" alignItems="center">
                        <Avatar className={classes.popupAvatar} src={openImg?.data?.url}></Avatar>
                    </Box>
                }
                medium={openImg?.data?.asset_type === 4 ? false : true}

            />
        </>
    )
}