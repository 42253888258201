import * as React from "react"

const ClockIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      data-name="Path 97580"
      d="M16 8a8 8 0 1 1-8-8 8 8 0 0 1 8 8"
      fill="#6dafb3"
    />
    <path
      data-name="Path 97584"
      d="M8 1.714A6.286 6.286 0 1 0 14.286 8 6.286 6.286 0 0 0 8 1.714Zm0 6.857A.571.571 0 1 1 8.571 8 .572.572 0 0 1 8 8.571Z"
      fill="#fff"
    />
    <g data-name="Group 104470">
      <path
        data-name="Path 97585"
        d="M8.571 7.303H7.428V2.857h1.143Zm1.677 3.933.988-.988-2.249-2.247-.988.987Z"
      />
      <path
        data-name="Path 97586"
        d="M8 6.857A1.143 1.143 0 1 0 9.143 8 1.143 1.143 0 0 0 8 6.857Zm0 1.714A.571.571 0 1 1 8.571 8 .572.572 0 0 1 8 8.571Z"
      />
    </g>
  </svg>
)

export default ClockIcon
