export const ListingsView = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-1{fill:#c1c5cb}"}</style>
        </defs>
        <g
            id="Group_102126"
            data-name="Group 102126"
            transform="translate(24689 -3973)"
        >
            <g
                id="Listing_View"
                data-name="Listing View"
                transform="translate(-24704 3846.77)"
            >
                <g
                    id="Group_102122"
                    data-name="Group 102122"
                    transform="translate(22 134.23)"
                >
                    <g id="Group_102121" data-name="Group 102121">
                        <g id="Group_102120" data-name="Group 102120">
                            <g id="XMLID_1_">
                                <path
                                    id="Path_96832"
                                    data-name="Path 96832"
                                    className="cls-1"
                                    d="M268.674 623.4v3.792H267.2v-2.355a.176.176 0 0 0-.176-.176h-1.73a.176.176 0 0 0-.176.176v2.355h-1.478V623.4l2.517-2.1Z"
                                    transform="translate(-261.123 -616.226)"
                                    style={{
                                        fill: props?.fill ?? "#c1c5cb",
                                    }}
                                />
                                <path
                                    id="Path_96833"
                                    data-name="Path 96833"
                                    className="cls-1"
                                    d="M1018.09 657.71h3.85v1.132h-3.85Z"
                                    transform="translate(-1007.714 -652.257)"
                                    style={{
                                        fill: props?.fill ?? "#c1c5cb",
                                    }}
                                />
                                <path
                                    id="Path_96834"
                                    data-name="Path 96834"
                                    className="cls-1"
                                    d="M38 357.38v10.854a.484.484 0 0 1-.484.484H22.483a.484.484 0 0 1-.484-.484V357.38Zm-1.423 4.436v-1.483a.176.176 0 0 0-.176-.176H32.2a.176.176 0 0 0-.176.176v1.483a.176.176 0 0 0 .176.176h4.2a.176.176 0 0 0 .177-.176Zm0 1.372a.176.176 0 0 0-.176-.176H32.2a.176.176 0 0 0 0 .352h4.2a.176.176 0 0 0 .177-.176Zm0 1.288a.176.176 0 0 0-.176-.176H32.2a.176.176 0 0 0 0 .352h4.2a.176.176 0 0 0 .177-.175Zm0 1.288a.176.176 0 0 0-.176-.176H32.2a.176.176 0 0 0 0 .352h4.2a.176.176 0 0 0 .177-.175Zm-9.431-6a.176.176 0 0 0-.225 0l-3.545 2.958a.176.176 0 1 0 .225.27l.564-.471v3.679a.176.176 0 0 0 .176.176h1.828a.176.176 0 0 0 .176-.176v-2.355h1.378v2.355a.176.176 0 0 0 .176.176h1.828a.176.176 0 0 0 .176-.176v-3.674l.564.471a.176.176 0 1 0 .225-.27Z"
                                    transform="translate(-22 -355.056)"
                                    style={{
                                        fill: props?.fill ?? "#c1c5cb",
                                    }}
                                />
                                <path
                                    id="Path_96835"
                                    data-name="Path 96835"
                                    className="cls-1"
                                    d="M38 134.714v1.486H22v-1.489a.484.484 0 0 1 .484-.484h15.032a.484.484 0 0 1 .484.487Zm-1.133.5a.176.176 0 0 0-.176-.176h-6.56a.176.176 0 1 0 0 .352h6.56a.176.176 0 0 0 .176-.174Zm-10.08 0a.347.347 0 1 0-.347.348.347.347 0 0 0 .347-.346Zm-1.454 0a.347.347 0 1 0-.347.348.347.347 0 0 0 .347-.346Zm-1.454 0a.348.348 0 1 0-.348.348.348.348 0 0 0 .348-.346Z"
                                    transform="translate(-22 -134.23)"
                                    style={{
                                        fill: props?.fill ?? "#c1c5cb",
                                    }}
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24689 3973)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
        </g>
    </svg>
)