import { Box, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { Stepper } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
  AlertProps,
  NetWorkCallMethods, useWindowDimensions
} from "../../../utils";
import { againstCompanyStep, initialState, praicingJSON } from "../common";
import {
  CompanyPreview, ComponentDetails, ComponentType
} from "../stepComponents";
import { againstCompanyStyle } from "../style";

export const CreateAgainstCompany = (props) => {
  const { t = () => false } = (props)
  // state
  const [step, setStep] = useState(1);
  const [state, setState] = useState({ ...initialState });
  const [unitDetails, setUnitDetails] = useState("");
  const [isDisableBtn,setIsDisableBtn]=React.useState(false)

  // context
  const alert = useContext(AlertContext);

  /*---------------------------------------------------------------------------*/
  //   classes
  const classes = againstCompanyStyle();
  const size = useWindowDimensions();

  /*-------------------------------functions--------------------------------------*/
  // get pricing units details by filter of appraisal details
  const getDetails = () => {
    const data = {
      company_id: props?.company?.value,
      component_type_applicablity: {
        is_primary: state?.componentType?.is_primary,
        is_one_time: state?.componentType?.is_one_time,
        is_refundable: state?.componentType?.is_refundable,
        is_item_based_component: state?.componentType?.is_item_based_component,
        is_parking_slot: state?.componentType?.is_parking_slot,
      },
      based_on: state?.Based_On,
      component_id: state?.component_name?.value,
      existing_price:
        state?.amountType?.value === "Specific" ? state?.amount : undefined,
      start:
        state?.amountType?.value === "Range" ? state?.minAmount : undefined,
      end: state?.amountType?.value === "Range" ? state?.maxAmount : undefined,
    };
    NetworkCall(
      `${config.api_url}/pricing_appraisal/get_by_filter`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setUnitDetails(response?.data?.data?.[0]?.no_of_units ?? 0);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };
  //   next
  const next = () => {
    if (step === 1) {
      if (validationComponentType()) {
        setStep(step + 1);
      } else {
        return true;
      }
    }
    if (step === 2) {
      if (validationComponentDetails()) {
        setStep(step + 1);
        getDetails();
      } else {
        return true;
      }
    }
  };
  //   back
  const back = () => {
    setStep(step - 1);
  };
  // sumbit
  const sumbit = () => {
    setIsDisableBtn(true)
    const data = {
      company_id: props?.company?.value,
      component_type_applicablity: {
        is_primary: state?.componentType?.is_primary,
        is_one_time: state?.componentType?.is_one_time,
        is_refundable: state?.componentType?.is_refundable,
        is_item_based_component: state?.componentType?.is_item_based_component,
        is_parking_slot: state?.componentType?.is_parking_slot,
      },
      appraisal_type: state?.amountType?.value,
      new_appraisal_amount: state?.appraisal_amount,
      component_based: state?.Based_On,
      component_revenue_type: state?.revenueType,
      pricing_component_id: state?.component_name?.value,
      present_specific_value:
        state?.amountType?.value === "Specific" ? state?.amount : null,
      effective_start_date: new Date(state?.StartDate),
      present_range_value:
        state?.amountType?.value === "Range"
          ? `(${state?.minAmount},${state?.maxAmount})`
          : null,
    };
    NetworkCall(
      `${config.api_url}/pricing_appraisal/create`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setState({ ...initialState })
        props?.onReload();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Appraisal Created successfully."),
        });
        setIsDisableBtn(false)
      })
      .catch((error) => {
        props?.onReload();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
        setIsDisableBtn(false)
      });
  };
  // validation select component type
  const validationComponentType = () => {
    let isValid = true;
    let error = state?.error;
    if (state?.componentType?.length === 0) {
      isValid = false;
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.info,
        msg: t("Please Select Component Type"),
      });
    }
    setState({ ...state, error });
    return isValid;
  };
  // validatecompoent Details
  const validationComponentDetails = () => {
    let isValid = true;
    let error = state?.error;
    if (state?.revenueType?.length === 0) {
      isValid = false;
      error.revenueType = t("Component Revenue Type is Required");
    }
    if (state?.Based_On?.length === 0) {
      isValid = false;
      error.Based_On = t("Component Based On is Required");
    }
    if (state?.component_name?.length === 0) {
      isValid = false;
      error.component_name = t("Pricing Component Name On is Required");
    }
    if (state?.amountType?.value === "Range") {
      if (state?.minAmount?.length === 0) {
        isValid = false;
        error.minAmount = "Present Min Amount Value (Range) is Required";
      }
      if (state?.maxAmount?.length === 0) {
        isValid = false;
        error.maxAmount = "Present Max Amount Value (Range) is Required";
      }
      if (state?.maxAmount <= state?.minAmount) {
        isValid = false;
        error.maxAmount = " Max Amount (Range) must be greater than Min Amount";
      }
    } else {
      if (state?.amount?.length === 0) {
        isValid = false;
        error.amount = t("Present Amount Value (Range) is Required");
      }
    }

    if (state?.appraisal_amount?.length === 0) {
      isValid = false;
      error.appraisal_amount = t("New Appraisal Amount is Required");
    }
    if (state?.StartDate?.length === 0) {
      isValid = false;
      error.StartDate = t("Effective Start Period is Required");
    }
    if (state?.amountType?.length === 0) {
      isValid = false;
      error.amountType = t("amountType");
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.info,
        msg: t("Please Select Amout Type."),
      });
    }
    setState({ ...state, error });
    return isValid;
  };
  // updateState
  const updateState = (key, value) => {
    let error = state?.error;
    error[key] = "";
    setState({ ...state, [key]: value, error });
  };

  /**----------------------------------------------------- */
  // stepper component
  const components = {
    1: (
      <ComponentType
        handleChange={(e, value) => updateState("componentType", value)}
        list={praicingJSON ?? []}
        selectedComponent={state?.componentType?.id}
        t={t}
      />
    ),
    2: (
      <ComponentDetails
        updateState={updateState}
        state={state}
        company={props?.company}
        t={t}
      />
    ),
    3: <CompanyPreview t={t} state={state} unitDetails={unitDetails} />,
  };
  return (
    <div>
      {/* steper */}
      <Stepper step={step} steps={againstCompanyStep(step)} t={t} />
      {/* component */}
      <Box
        className={classes.popUpParent}
        style={{ height: size?.height - 289 }}
      >
        {components[step]}
      </Box>
      {/* button */}
      <Box className={classes.btnParent}>
        <Box>
          {step !== 1 && (
            <Button onClick={back} className={classes.Cancel}>
              {t("Back")}
            </Button>
          )}
        </Box>
        <Button onClick={step === 3 ? sumbit : next} className={classes.next}  disabled={isDisableBtn}>
          {step === 3 ? t("Create Appraisal Value") : t("Next")}
        </Button>
      </Box>
    </div>
  );
};
