import {timeZoneConverter} from "../utils"

export const configurationMasterAction = (type, data, offset) => {

    const returnDateFormat = (date, format = "DD MMM YY", delimiter = "") => date ? timeZoneConverter(date , format) : delimiter;
    const returnData = (name, delimiter = "") => name ? name?.toString()?.trim() : delimiter;

    switch (type) {
        case 'Common Banner':
            const mapData = data?.map((_, index) => {
                let mainData = {}
                mainData.id = _?.id;
                mainData.updatedby = returnData(`${returnData(_?.first_name)} ${returnData(_?.last_name)}`, '-');
                mainData.updated_at = returnDateFormat(_?.updated_at);
                mainData.company = returnData(_?.company_name, '-');
                mainData.company_id = returnData(_?.company_id, '-');
                mainData.count = _?.assets?.length;
                mainData.index = offset + index + 1;
                mainData.is_active = _?.is_active;
                mainData.icon = "editview";
                return mainData
            })
            return mapData

        case 'Dashboard Banner':
            const mapData1 = data?.map((_, index) => {
                let mainData = {}
                mainData.id = _?.id;
                mainData.updated_by = returnData(`${returnData(_?.first_name)} ${returnData(_?.last_name)}`, '-');
                mainData.updated_at = returnDateFormat(_?.updated_at);
                mainData.company = returnData(_?.company_name, '-');
                mainData.company_id = returnData(_?.company_id, '-');
                mainData.count = _?.assets?.length;
                mainData.index = offset + index + 1;
                mainData.is_active = _?.is_active;
                mainData.icon = "editview";
                return mainData
            })
            return mapData1
        default:
            return ''
    }
}