import React from "react";
import  KycScreen  from './kycscreen';
import { withNavBars } from "./../../HOCs";

class KycScreenParent extends React.Component {
  render() {
    return <KycScreen />;
  }
}
const props = {
      boxShadow: false
  }
export default withNavBars(KycScreenParent , props);
