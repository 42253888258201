import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { FormGenerator, UploadComponent } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, enum_types, LocalStorageKeys, networkCallback, NetWorkCallMethods } from "../../../utils";
import { initialState, returnEditPayload, returnSavePayload } from "../utils";
import { VehicleMasterComponentStyles } from "./styles";

const Vehicles = ({
    setData = () => false,
    data = {},
    company = {},
    reload = () => false,
    onClose = () => false,
    setSelectedType = () => false,
    t,
    btnName

}) => {
    const classes = VehicleMasterComponentStyles();
    const alert = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(null);
    const [assets, setAssets] = React.useState([]);

    //initial load
    React.useEffect(() => {
        if (data?.isEdit) {
            let result = returnEditPayload(data);
            setAssets(result?.assets)
            setData(result)
        }

        // eslint-disable-next-line
    }, [])

    //updateState
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    };
    //loadoption
    const loadOptionData = async (search, array, type) => {
        setLoading(type);
        let result;
        let enumTypes = [enum_types.vehicle_master_type_enum, enum_types.vehicle_master_period_enum]

        if (enumTypes?.includes(type)) {
            result = await networkCallback({ enumName: [type] }, "enum", setLoading);
            if (!result) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                return {
                    options: [],
                    hasMore: false
                }
            }
            return {
                options: result?.[type] ?? [],
                hasMore: false
            }
        }
        else {
            return {
                options: [],
                hasMore: false
            }
        }
    }
    //form json
    const toolForm = [
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: t("Vehicle Type"),
            placeholder: t("Select Vehicle Type"),
            loading: loading === enum_types.vehicle_master_type_enum,
            value: data?.vehicle_type,
            onChange: (value) => updateState("vehicle_type", value),
            error: data?.error?.vehicle_type,
            isRequired: true,
            loadOptions: (search, array) => loadOptionData(search, array, enum_types.vehicle_master_type_enum),
            debounceTimeout: 800,
            isPaginate: true,
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "text",
            label: t("Vehicle Name"),
            placeholder: t("Enter Vehicle Name"),
            value: data?.name,
            onChange: (value) => updateState("name", value.target.value),
            isRequired: true,
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "text",
            label: t("Vehicle Number"),
            placeholder: t("Enter Vehicle Number"),
            value: data?.number,
            onChange: (value) => updateState("number", value.target.value),
            error: data?.error?.number,
            isRequired: true
        },
        {
            size: {
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "text",
            label: t("Cost"),
            placeholder: t("Enter Rate"),
            value: data?.rate,
            onChange: (value) => updateState("rate", value.target.value),
            error: data?.error?.rate,
            isRequired: true,
            type: "number",
            endAdornment: company?.currency_symbol
        },
        {
            size: {
                xs: 3,
                sm: 3,
                md: 3,
                lg: 3
            },
            isActive: true,
            component: "select",
            label: t("Period"),
            placeholder: t("Select Period"),
            value: data?.period,
            onChange: (value) => updateState("period", value),
            error: data?.error?.period,
            isRequired: true,
            loading: loading === enum_types.vehicle_master_period_enum,
            loadOptions: (search, array) => loadOptionData(search, array, enum_types.vehicle_master_period_enum),
            debounceTimeout: 800,
            isPaginate: true,
        },
        {
            size: {
                xs: 6,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "text",
            label: t("Description"),
            placeholder: t("Enter Description"),
            value: data?.description,
            onChange: (value) => updateState("description", value.target.value),
            error: data?.error?.description,
            multiline: true
        },

    ]

    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (data?.name?.length === 0) {
            isValid = false;
            error.name = t("vehicle name is Required");
        }
        if (data?.rate?.length === 0) {
            isValid = false;
            error.rate = t("Rate is Required");
        }
        if (data?.period?.length === 0) {
            isValid = false;
            error.period = t("Daily Rate is Required");
        }
        if (data?.number?.length === 0) {
            isValid = false;
            error.number = t("Vehicles Number is Required");
        }
        if (data?.vehicles_type?.length === 0) {
            isValid = false;
            error.vehicles_type = t("Vehicles Type is Required");
        }
        // if (assets?.length === 0) {
        //     isValid = false;
        //     error.assets = "Assets is Required";
        // }
        setData({ ...data, error });
        return isValid;
    }
    //onsubmit
    const onSubmit = () => {
        const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileID)

        if (validateForm()) {
            const payload = returnSavePayload(data, company, assets, userProfileId)
            NetworkCall(
                `${config.api_url}/vehicle-master/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                reload();

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${t("Vehicle")} ${data?.id ? t("Updated") : t("Created")}`,

                });
                setData({ ...initialState })
                onClose()
            }).catch((err) => {
                console.log(err)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),

                });
            })
        }

    }

    return (
        <Box className={`${classes.resources}`}>
            <Box className={classes.formRoot} >
                <Grid container >
                    <Grid item xs={12} p={1} className={classes.fromSection}>
                        {/* Add tool */}
                        <FormGenerator t={t} components={toolForm} />
                        {

                            <UploadComponent
                                handleChange={(val) => updateState("assets", val)}
                                logo_title={t("IMAGES")}
                                errorMessage={data?.error?.assets}
                                isError={data?.error?.assets?.length > 0}
                                assets={assets}
                                setAssets={setAssets}
                                xs={2}
                                //isrequired
                                marginTop
                                btnName={btnName}
                            />
                        }

                    </Grid>
                </Grid>

            </Box >
            {/* buttons */}
            < Box display="flex" className={`${classes.btngrp}`} p={1} >
                <Button variant="outlined" className={`${classes.btn_outlined}`} onClick={onClose}>{t("Cancel")}</Button>
                <Button variant="contained" className={`${classes.btn_contained}`} onClick={onSubmit}>{data?.isEdit === true ? t("Update") : t("Create")}</Button>
            </Box >
        </Box >
    )
}
export default Vehicles