export const KycRequests = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 30 30"
    style={{
      enableBackground: "new 0 0 30 30",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st0{fill:#c1c5cb}"}</style>
    <circle
      className="st0"
      cx={12.7}
      cy={10.7}
      r={3.5}
      style={{
        fill: props?.fill ?? "#c1c5cb",
      }}
    />
    <path
      className="st0"
      d="M17.6 15.3c-.4-.6-.9-1-1.5-1.4-1.7 1.8-4.6 2-6.5.2l-.2-.2C7.9 14.7 7 16.3 7 18v1.4c0 .3.2.6.6.6h7.2c-.3-2 .9-3.9 2.8-4.7z"
      style={{
        fill: props?.fill ?? "#c1c5cb",
      }}
    />
    <path
      className="st0"
      d="M19.5 15.8c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5zm1 5c0 .3-.2.6-.6.6s-.6-.2-.6-.6v-2.4h-.2c-.3 0-.6-.2-.6-.6 0-.3.2-.6.6-.6h.8c.3 0 .6.2.6.6v3z"
      style={{
        fill: props?.fill ?? "#c1c5cb",
      }}
    />
  </svg>
)