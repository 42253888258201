import React from "react";
import { withNavBars } from "../../HOCs";
import ContractManagerDashboard from "./contractManagerDashboard";

class ContractManagerDashboardParent extends React.Component {
  render() { return <ContractManagerDashboard {...this.props} />; }
}

const props = { boxShadow: false }

export default withNavBars(ContractManagerDashboardParent, props);
