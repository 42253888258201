import * as React from "react"

const TentativeIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 104833" transform="translate(-344.84 -312.09)">
            <rect
                data-name="Rectangle 55272"
                width={40}
                height={40}
                rx={4}
                transform="translate(344.84 312.09)"
                fill="#fff4eb"
            />
            <g data-name="Group 104810">
                <path
                    data-name="Rectangle 55278"
                    fill="none"
                    d="M352.84 320.545h24v24h-24z"
                />
                <path
                    d="M356.561 323.547a.644.644 0 0 0-.721.641v15a.643.643 0 0 0 .643.643h6a1.5 1.5 0 0 0 1.5-1.5v-7.322a3.2 3.2 0 0 0-1.181-2.49l-5.912-4.828a.643.643 0 0 0-.329-.144Zm14.707 2.571a1.286 1.286 0 1 0 1.287 1.285 1.285 1.285 0 0 0-1.287-1.285Zm-1.188 3a1.283 1.283 0 0 0-.462.053H369.59l-.014.005-2.569.888a.643.643 0 0 0-.248.152l-1.719 1.651a.643.643 0 0 0 .887.931l1.613-1.538.936-.256-.586 2.043a1.281 1.281 0 0 0 .35 1.28l.03.029c.01.01.042.041.065.059l.007.006 2.479 1.834.9 3.113a.642.642 0 0 0 .616.464.66.66 0 0 0 .157-.019.643.643 0 0 0 .474-.755l-.75-3.589a.642.642 0 0 0-.164-.313l-1.614-1.685.775-2.714a1.283 1.283 0 0 0-1.139-1.635Zm1.739 2.659-.379 1.327 1.371 1.028a.643.643 0 1 0 .772-1.028Zm-13.622.337h1.714a.643.643 0 0 1 .643.643v2.571a.643.643 0 0 1-.643.643h-1.714a.643.643 0 0 1-.643-.643v-2.567a.643.643 0 0 1 .643-.643Zm9.643 3v1.1l-1.619 2.631a.642.642 0 0 0 1.059.725l2.036-2.679a.641.641 0 0 0 .126-.306l.034-.265Z"
                    fill="#ff9340"
                />
            </g>
        </g>
    </svg>
)

export default TentativeIcon
