import React from "react"

export const HistoryIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_112543" data-name="Group 112543" transform="translate(-14797 -9521)">
            <g id="Group_112544" data-name="Group 112544">
                <g id="Rectangle_57304" data-name="Rectangle 57304" transform="translate(14797 9521)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-time-machine" d="M11,4A6.981,6.981,0,0,0,6.1,6V5.4a.7.7,0,1,0-1.4,0V7.85a.7.7,0,0,0,.672.7.7.7,0,0,0,.316,0H7.85a.7.7,0,1,0,0-1.4H6.936A5.6,5.6,0,1,1,5.4,11,.7.7,0,1,0,4,11a7,7,0,1,0,7-7Zm-.01,3.14a.7.7,0,0,0-.69.71V11.7a.7.7,0,0,0,.7.7h2.45a.7.7,0,1,0,0-1.4H11.7V7.85a.7.7,0,0,0-.71-.71Z" transform="translate(14798 9522)" fill="#4e5a6b" />
            </g>
        </g>
    </svg>

)