import React from 'react';
import { Box } from "@mui/material";
import ImgUpload from "../../../components/imgUpload";
import { useStylesCreation } from '../../propertcreation/createproperty/style';

export const Assets = (props) => {

    const classes = useStylesCreation()
    const [uuid, setUUID] = React.useState(1);

    const {
        data,
        isEdit,
        updateState,
        onChangeParticularState
    } = props;

    const {
        deletedImages = [],
        uploadedImages = []
    } = data ?? {};

    const handleAssetFile = (val) => {
        let filteredImages = uploadedImages?.filter((_) => _?.url !== val.url)
        if(isEdit && val?.id){
            let updateAssets = {
                deletedImages:[...deletedImages,val?.id],
                uploadedImages:filteredImages
            }
            onChangeParticularState('assets',updateAssets);
        }
        else{
            updateState('', 'uploadedImages', filteredImages);
        }
    }

    const updateImageURL = (val) => {
        updateState('', 'uploadedImages', val)
    }



    return (
        <>
            <Box className={classes.imagebox1}>
                <ImgUpload
                    accept={'image/*'}
                    setUUID={setUUID ?? null}
                    uuid={uuid ?? null}
                    updateImageURL={updateImageURL}
                    handleFile={handleAssetFile}
                    selectedImageURL={uploadedImages ?? []}
                    id={2}
                />
            </Box>
        </>

    )
}