import * as React from "react"
export const SaleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    {...props}
  >
    <path
      fill="#e4bb00"
      d="M17.5 1.5c6.833-.83 9.5 2.17 8 9h-3v6h-4v-6h-3c-.435-3.338.232-6.338 2-9Zm2 3c1.837.14 2.17.806 1 2-.797-.457-1.13-1.124-1-2Z"
      style={{
        opacity: 0.913,
      }}
    />
    <path
      fill="#f1c34a"
      d="M14.5 17.5c-1.43.946-2.764 2.113-4 3.5 1.655 1.444 2.322 3.278 2 5.5-3.347-2.453-6.68-2.453-10 0-1.333-3.333-1.333-6.667 0-10 4.335-2.793 8.335-2.46 12 1Z"
      style={{
        opacity: 0.958,
      }}
    />
    <path
      fill="#f2bf3f"
      d="M14.5 17.5c2.174.25 3.84 1.25 5 3-.73 1.545-2.063 2.378-4 2.5 1.063 1.188 2.397 1.688 4 1.5v4c-2.579.106-4.912-.56-7-2 .322-2.222-.345-4.056-2-5.5 1.236-1.387 2.57-2.554 4-3.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#dfa826"
      d="M19.5 28.5v-4c-1.603.188-2.937-.312-4-1.5 1.937-.122 3.27-.955 4-2.5 1.984-.656 3.984-1.322 6-2 3.356.387 4.356 2.053 3 5a58.501 58.501 0 0 0-9 5Z"
      style={{
        opacity: 0.969,
      }}
    />
  </svg>
)

