import React from "react";

export const OpenReservationsIcon = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_243_2356)">
      <circle cx="25.3999" cy="21.9191" r="21" fill="#2D99FE" />
    </g>
    <g clip-path="url(#clip0_243_2356)">
      <path
        d="M24.1968 27.2219H25.8031V28.8281H24.1968V27.2219Z"
        fill="white"
      />
      <path
        d="M30.5986 27.2219H32.2049V28.8281H30.5986V27.2219Z"
        fill="white"
      />
      <path
        d="M17.7954 21.1969H19.4017V22.8031H17.7954V21.1969Z"
        fill="white"
      />
      <path
        d="M17.7954 27.2219H19.4017V28.8281H17.7954V27.2219Z"
        fill="white"
      />
      <path
        d="M34.414 11.5062H33.8875V13.7156C33.8875 14.104 33.5727 14.4188 33.1844 14.4188C32.7961 14.4188 32.4813 14.104 32.4813 13.7156V11.5062V10.7031C32.4813 10.3148 32.1664 10 31.7781 10C31.3898 10 31.075 10.3148 31.075 10.7031V11.5062H29.3688V13.7156C29.3688 14.104 29.0539 14.4188 28.6656 14.4188C28.2773 14.4188 27.9625 14.104 27.9625 13.7156V11.5062V10.7031C27.9625 10.3148 27.6477 10 27.2594 10C26.8711 10 26.5563 10.3148 26.5563 10.7031V11.5062H24.85V13.7156C24.85 14.104 24.5352 14.4188 24.1469 14.4188C23.7586 14.4188 23.4438 14.104 23.4438 13.7156V11.5062V10.7031C23.4438 10.3148 23.1289 10 22.7406 10C22.3523 10 22.0375 10.3148 22.0375 10.7031V11.5062H20.3312V13.7156C20.3312 14.104 20.0164 14.4188 19.6281 14.4188C19.2398 14.4188 18.925 14.104 18.925 13.7156V11.5062V10.7031C18.925 10.3148 18.6102 10 18.2219 10C17.8336 10 17.5188 10.3148 17.5188 10.7031V11.5062H15.5859C14.1601 11.5062 13 12.6663 13 14.0922V16.025H37V14.0922C37 12.6663 35.8399 11.5062 34.414 11.5062Z"
        fill="white"
      />
      <path
        d="M13 31.4141C13 32.84 14.1601 34 15.5859 34H34.414C35.8399 34 37 32.84 37 31.4141V17.4313H13V31.4141ZM27.2094 29.5313C27.2094 29.9196 26.8945 30.2344 26.5062 30.2344H23.4937C23.1054 30.2344 22.7905 29.9196 22.7905 29.5313V26.5188C22.7905 26.1305 23.1054 25.8157 23.4937 25.8157H26.5062C26.8945 25.8157 27.2094 26.1305 27.2094 26.5188V29.5313ZM29.1922 20.4938C29.1922 20.1055 29.507 19.7907 29.8953 19.7907H32.9078C33.2961 19.7907 33.6109 20.1055 33.6109 20.4938V23.5063C33.6109 23.8946 33.2961 24.2094 32.9078 24.2094H29.8953C29.507 24.2094 29.1922 23.8946 29.1922 23.5063V20.4938ZM29.1922 26.5188C29.1922 26.1305 29.507 25.8157 29.8953 25.8157H32.9078C33.2961 25.8157 33.6109 26.1305 33.6109 26.5188V29.5313C33.6109 29.9196 33.2961 30.2344 32.9078 30.2344H29.8953C29.507 30.2344 29.1922 29.9196 29.1922 29.5313V26.5188ZM22.9052 21.5029C23.1798 21.2283 23.625 21.2283 23.8996 21.5029L24.4675 22.0708L26.1004 20.4378C26.375 20.1633 26.8202 20.1632 27.0948 20.4378C27.3694 20.7124 27.3694 21.1576 27.0948 21.4322L24.9648 23.5623C24.8329 23.6942 24.6541 23.7683 24.4676 23.7683C24.2811 23.7683 24.1023 23.6942 23.9704 23.5623L22.9053 22.4972C22.6307 22.2226 22.6307 21.7774 22.9052 21.5029ZM16.389 20.4938C16.389 20.1055 16.7038 19.7907 17.0921 19.7907H20.1047C20.493 19.7907 20.8078 20.1055 20.8078 20.4938V23.5063C20.8078 23.8946 20.493 24.2094 20.1047 24.2094H17.0921C16.7038 24.2094 16.389 23.8946 16.389 23.5063V20.4938ZM16.389 26.5188C16.389 26.1305 16.7038 25.8157 17.0921 25.8157H20.1047C20.493 25.8157 20.8078 26.1305 20.8078 26.5188V29.5313C20.8078 29.9196 20.493 30.2344 20.1047 30.2344H17.0921C16.7038 30.2344 16.389 29.9196 16.389 29.5313V26.5188Z"
        fill="white"
      />
      <path
        d="M30.5986 21.1969H32.2049V22.8031H30.5986V21.1969Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_243_2356"
        x="0.399902"
        y="0.919067"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_243_2356"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_243_2356"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_243_2356">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(13 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
