export const RequestMain = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <path
            data-name="Rectangle 52494"
            style={{
                fill: "none",
            }}
            d="M0 0h30v30H0z"
        />
        <path
            data-name="Path 96787"
            d="M15.05 0H5.7A1.687 1.687 0 0 0 4 1.67v13.66A1.687 1.687 0 0 0 5.7 17h9.35a1.687 1.687 0 0 0 1.7-1.67V1.67A1.687 1.687 0 0 0 15.05 0ZM8.385 3.294a2.05 2.05 0 1 1 2.978 2.39.964.964 0 0 0-.492.827v.053a.531.531 0 0 1-1.062 0v-.053a2.029 2.029 0 0 1 1.023-1.747.985.985 0 0 0 .468-1.095.974.974 0 0 0-.72-.72.987.987 0 0 0-1.092.463.956.956 0 0 0-.088.2.531.531 0 0 1-1.014-.317Zm2.484 4.94a.378.378 0 0 1-.011.106.63.63 0 0 1-.027.1l-.048.1a.939.939 0 0 1-.069.08.539.539 0 0 1-.377.154.445.445 0 0 1-.2-.043.527.527 0 0 1-.17-.112.939.939 0 0 1-.069-.08l-.048-.1-.032-.1a.812.812 0 0 1-.011-.106.54.54 0 0 1 .159-.377.549.549 0 0 1 .749 0 .535.535 0 0 1 .154.378Zm3.225 6.641H6.656a.531.531 0 0 1 0-1.062h7.437a.531.531 0 0 1 0 1.062Zm0-2.125H6.656a.531.531 0 0 1 0-1.062h7.437a.531.531 0 0 1 0 1.062Zm0-2.125H6.656a.531.531 0 0 1 0-1.062h7.437a.531.531 0 0 1 0 1.062Z"
            transform="translate(5 7)"
            style={{
                fill: props?.fill ?? "#c1c5cb",
            }}
        />
    </svg>
)