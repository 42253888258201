import React from "react";
import { withNavBars } from "../../HOCs";
import DutyRoster from './dutyRoster'

class DutyRosterParent extends React.Component {
    render() {
        return (
            <DutyRoster />
        );
    }
}

const props = {
    boxShadow: false
}
export default withNavBars(DutyRosterParent, props);