import * as React from "react"

const Step1IconSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} {...props}>
    <path
      data-name="Path 100080"
      d="M22 0a22 22 0 1 0 22 22A22 22 0 0 0 22 0Z"
      fill="#ede7f6"
    />
    <path
      data-name="Path 100081"
      d="M22 44a21.558 21.558 0 0 0 13.09-4.4c-.66-8.36-9.02-9.9-9.02-9.9l-4.07.88-4.07-.88s-8.36 1.54-9.02 9.9A21.558 21.558 0 0 0 22 44Z"
      fill="#673ab7"
    />
    <path
      data-name="Path 100082"
      d="M22 36.3a6.467 6.467 0 0 0 6.49-5.83 13.411 13.411 0 0 0-2.2-.77 4.32 4.32 0 0 1-4.4 4.29 4.391 4.391 0 0 1-4.4-4.29 13.41 13.41 0 0 0-2.2.77A6.85 6.85 0 0 0 22 36.3Z"
      fill="#311b92"
    />
    <path
      data-name="Path 100083"
      d="M30.8 20.35a1.65 1.65 0 1 1-1.65-1.65 1.692 1.692 0 0 1 1.65 1.65m-14.3 0A1.65 1.65 0 1 0 14.85 22a1.692 1.692 0 0 0 1.65-1.65"
      fill="#edbd28"
    />
    <path
      data-name="Path 100084"
      d="M22 34.1c-4.4 0-4.4-4.4-4.4-4.4v-4.4h8.8v4.4s0 4.4-4.4 4.4Z"
      fill="#edb405"
    />
    <path
      data-name="Path 100085"
      d="M29.7 16.17c0-6.49-15.4-4.18-15.4 0v4.84a7.7 7.7 0 0 0 15.4 0Z"
      fill="#ffca28"
    />
    <path
      data-name="Path 100086"
      d="M22 7.7c-5.39 0-8.8 4.73-8.8 9.02v1.98l2.2 2.2v-4.4l10.12-3.3 3.08 3.3v4.4l2.2-2.2v-.88c0-3.52-.88-7.48-5.28-8.36l-.88-1.76Z"
      fill="#795548"
    />
    <path
      data-name="Path 100087"
      d="M24.2 19.8a1.1 1.1 0 1 1 1.1 1.1 1.039 1.039 0 0 1-1.1-1.1m-6.6 0a1.1 1.1 0 1 0 1.1-1.1 1.039 1.039 0 0 0-1.1 1.1"
      fill="#784719"
    />
  </svg>
)

export default Step1IconSvg
