import * as React from "react"

const CreditSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18.171} height={18} {...props}>
    <g data-name="Group 107572">
      <path data-name="Rectangle 56160" fill="none" d="M0 0h18v18H0z" />
      <path
        data-name="icons8-receive-cash (1)"
        d="M17.143 8.026a4.855 4.855 0 0 0-1.774.748c-.774.4-1.49.784-1.944 1.063a2.334 2.334 0 0 1-2.279 2.872H8.219a.585.585 0 1 1 0-1.171h2.927a1.166 1.166 0 0 0 .769-2.047 1.741 1.741 0 0 0-.964-.295H8.967a8.515 8.515 0 0 1-1.957-.218 5.7 5.7 0 0 0-1.394-.169c-2.861 0-4.495 3.513-4.563 3.662a.585.585 0 0 0 .062.59l2.732 3.7a.582.582 0 0 0 .47.236.587.587 0 0 0 .568-.437 1.47 1.47 0 0 1 1.385-1.122 19.007 19.007 0 0 1 2.312.193 19.248 19.248 0 0 0 2.371.2c1.078 0 1.854-.827 4.1-3.122 2.85-2.916 3.122-3.17 3.122-3.748a.981.981 0 0 0-1.028-.935Zm-2.484-5.075a1.957 1.957 0 0 1-1.249 1.823 3.328 3.328 0 0 0-2.525-2.525 1.952 1.952 0 0 1 3.774.7Zm-4.488 0a2.537 2.537 0 1 0 2.537 2.537 2.537 2.537 0 0 0-2.537-2.537Z"
        fill="#43aa8b"
      />
    </g>
  </svg>
)

export default CreditSvg