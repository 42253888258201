import { Button, Card, Checkbox, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import makeStyles from "@mui/styles/makeStyles";
import * as React from 'react';


const useStyles = makeStyles((theme) => ({
    Label: {
      color: theme.typography.color.tertiary,
      fontSize:"0.75rem",
    },
    text: {},
    card:{
      padding:10,marginTop:10,display:"flex",justifyContent:"space-between"
    },
    selectBox: {
      width: "100%",
      height: "35px",
  
      "& .Mui-disabled": {
        backgroundColor: "#F0F0F0",
      },
  
      "& .MuiOutlinedInput-root": {
        borderRadius: "7px",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        transform: "translate(14px, -6px) scale(0.75) !important",
      },
      "& .MuiFormLabel-root.MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75) !important",
      },
      "& .MuiInputLabel-outlined": {
        transform: "translate(14px, 9px) scale(1) !important",
      },
      "& .MuiAutocomplete-inputRoot": {
        height: "39px",
        "& input": {
          padding: "0.5px 4px !important",
        },
      },
      "& .MuiAutocomplete-tag": {
        margin: "0px",
        height: "20px",
        borderRadius: "5px",
        "& .MuiChip-deleteIcon": {
          width: "17px",
        },
      },
    },
  }));

export const SelectUser=(props)=> {
  const{placeholder,disabled,value} =props;
    const classes = useStyles();
 
     const getLabel = (props) => {
        return (
          <Typography variant="body1" className={classes.Label} gutterBottom>
            {props.label}{props.isrequired && <Typography variant="caption" style={{ color: "red" }}>*</Typography>}
          </Typography>
        );
      };

      const onSelect =(option)=>{
        props?.onChange(props.state,option)
      }
      

      const removeUser =(Id)=>{
        props?.onChange(props.state,{value:"",label:""})
      }
   

  return (
      <div>
          <>
     {getLabel(props)}
    <Autocomplete
      id="country-select-demo"
      sx={{
        width: "100%",
        height: props.multi ? "" : "35px",
        marginTop: "4px",
      }}
      disableClearable={false}
      options={props.options}
      placeholder={placeholder}
      // getOptionLabel={(option) => option.label}
      value={""}
      disabled={disabled}
      className={classes.selectBox}
      renderOption={(props, option) => (
        <div style={{display:"flex",alignItems:"center"}}>
         <Checkbox  checked={value?.value === option?.value}
                    onChange={()=>onSelect(option)}/>
         <Typography sx={{ml:1}}>{option.label}</Typography>  
        </div>
       )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          style={{ height: props.multi ? "" : "35px" }}
          value={""}
        />
      )}
    />
    {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
       {value?.label &&         
            <Card key={value.value} className={classes.card}>
              <div style={{display:"flex",alignItems:"center"}}>
             <Typography variant={"h7"} sx={{ml:1}}>
               {value?.label}
             </Typography>
              </div>  
              <div>
                <Button style={{color:"red"}} onClick={()=>removeUser(value?.value)}>Remove</Button>
               </div>
            </Card>         
        }
    </>   
    </div>
  );
}