export const Listings = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-1{fill:#c1c5cb}"}</style>
        </defs>
        <g
            id="Group_102127"
            data-name="Group 102127"
            transform="translate(24673 -3989)"
        >
            <g id="Listing" transform="translate(-24666 3996)">
                <path
                    id="Path_96836"
                    data-name="Path 96836"
                    className="cls-1"
                    d="M189.906 30h-9.437a.469.469 0 0 0 0 .938h9.438a.469.469 0 0 0 0-.937Zm0 0"
                    transform="translate(-174.375 -29.063)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96837"
                    data-name="Path 96837"
                    className="cls-1"
                    d="M180.469 90.938h4.719a.469.469 0 0 0 0-.937h-4.719a.469.469 0 0 0 0 .938Zm0 0"
                    transform="translate(-174.375 -87.188)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96838"
                    data-name="Path 96838"
                    className="cls-1"
                    d="M180.469 271.938h4.719a.469.469 0 0 0 0-.937h-4.719a.469.469 0 0 0 0 .938Zm0 0"
                    transform="translate(-174.375 -262.531)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96839"
                    data-name="Path 96839"
                    className="cls-1"
                    d="M185.188 452.938a.469.469 0 0 0 0-.937h-4.719a.469.469 0 0 0 0 .938Zm0 0"
                    transform="translate(-174.375 -437.875)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96840"
                    data-name="Path 96840"
                    className="cls-1"
                    d="M189.906 211h-9.437a.469.469 0 0 0 0 .938h9.438a.469.469 0 0 0 0-.937Zm0 0"
                    transform="translate(-174.375 -204.406)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96841"
                    data-name="Path 96841"
                    className="cls-1"
                    d="M189.906 392h-9.437a.469.469 0 0 0 0 .938h9.438a.469.469 0 0 0 0-.937Zm0 0"
                    transform="translate(-174.375 -379.75)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96842"
                    data-name="Path 96842"
                    className="cls-1"
                    d="M4.429 3.7a.469.469 0 0 0 .259-.419V1.406a.469.469 0 0 0-.259-.419L2.554.049a.47.47 0 0 0-.419 0L.259.987A.469.469 0 0 0 0 1.406v1.875a.469.469 0 0 0 .259.419l1.616.808v1.327l-1.616.808A.469.469 0 0 0 0 7.062v1.875a.469.469 0 0 0 .259.419l1.616.808v1.327L.259 12.3a.469.469 0 0 0-.259.419v1.875a.469.469 0 0 0 .259.419l1.875.938a.47.47 0 0 0 .419 0l1.875-.937a.469.469 0 0 0 .259-.419v-1.876a.469.469 0 0 0-.259-.419l-1.616-.808v-1.327l1.616-.808a.469.469 0 0 0 .259-.419V7.062a.469.469 0 0 0-.259-.419l-1.615-.808V4.509ZM2.343 14.125a.469.469 0 1 1 .469-.469.469.469 0 0 1-.469.469Zm0-5.656A.469.469 0 1 1 2.812 8a.469.469 0 0 1-.469.469Zm0-5.656a.469.469 0 1 1 .469-.469.469.469 0 0 1-.469.468Zm0 0"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24673 3989)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
        </g>
    </svg>
)