import * as React from "react"

const IconInfo = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
        <path
            data-name="icons8-info (3)"
            d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 1.2A6.8 6.8 0 1 1 1.2 8 6.791 6.791 0 0 1 8 1.2ZM8 4a.8.8 0 1 0 .8.8A.8.8 0 0 0 8 4Zm-.009 2.791A.6.6 0 0 0 7.4 7.4v4.4a.6.6 0 1 0 1.2 0V7.4a.6.6 0 0 0-.609-.609Z"
            fill="#98a0ac"
        />
    </svg>
)

export default IconInfo
