import Box from '@mui/material/Box';
import GoogleMapReact from "google-map-react";
import React, { Component } from "react";
import styled from "styled-components";
import { config } from '../../config';
import AutoComplete from './autoComplete';
import { mapStyle } from "./mapStyle";
import Marker from "./marker";

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  position:relative;
`;

class MyGoogleMap extends Component {
  state = {
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: null,
    zoom: 9,
    address: "",
    result: null,
    draggable: true,
    lat: null,
    lng: null,
  };

  componentDidMount() {
    if (this.props.lat && this.props.lng) {
      this.setUserLocation(this.props)
    } else {
      this?.setCurrentLocation();
    }
  }

  // componentWillUnmount() {
  // }

  onMarkerInteraction = (childKey, childProps, mouse) => {
    this.setState({
      draggable: false,
      lat: mouse?.lat ?? "",
      lng: mouse?.lng ?? "",
    });
  };

  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this?.setState({ draggable: true });
    this?._generateAddress();
  };

  _onChange = ({ center, zoom }) => {
    this?.setState({
      // center: center,
      zoom: zoom,
    });
  };

  _onClick = (value) => {
    this?.setState({
      lat: value.lat,
      lng: value.lng,
    });
    this?._generateAddressForOnClick(value);
  };

  apiHasLoaded = (map, maps) => {
    const { mapLoad = true } = this.props;

    this?.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
      // center:(!mapLoad && this.props.lat && this.props.lng) ? {
      //   lat:this.props.lat,
      //   lng:this.props.lng
      // } : map.getCenter(),
      zoom: 12
    });

    if (mapLoad) {
      this._generateAddress();
    }
  };

  addPlace = (place) => {
    this?.setState({
      places: [place],
      lat: place?.geometry?.location.lat(),
      lng: place?.geometry?.location.lng(),
    });
    this._generateAddress();
  };

  _generateAddress() {
    const { mapApi } = this.state;

    const geocoder = new mapApi.Geocoder();

    geocoder.geocode(
      { location: { lat: this?.state?.lat, lng: this?.state?.lng } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            // this.zoom = 12;
            this.setState({
              result: results?.[0],
              address: results?.[0]?.formatted_address,
            });
            if (this?.props?.mapResult) {
              this?.props?.mapResult({
                ...this.state,
              });
            }
          } else {
            window.alert("No results found");
          }
        } else {
          // window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  _generateAddressForOnClick(value) {
    const { mapApi } = this.state;

    const geocoder = new mapApi.Geocoder();

    geocoder.geocode(
      { location: { lat: value?.lat, lng: value?.lng } },
      (results, status) => {
        if (status === "OK") {
          if (results?.[0]) {
            // this.zoom = 12;
            this.setState({
              result: results?.[0],
              address: results?.[0]?.formatted_address,
            });
            if (this?.props?.mapResult) {
              this.props.mapResult({
                ...this.state,
              });
            }
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  // Get Current Location Coordinates
  setCurrentLocation() {

    if ("geolocation" in navigator) {
      const success = (position) => {
        this.setState({
          center: [position.coords.latitude, position.coords.longitude],
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
      }
      const error = (error) => {
        console.log(error)
      }

      navigator.geolocation.getCurrentPosition(success, error);
    }
  }


  setUserLocation = (val) => {
    this.setState({
      center: {
        lat: Number(val?.lat),
        lng: Number(val?.lng),
      },
      lat: Number(val?.lat),
      lng: Number(val?.lng),
      zoom: val?.zoom ?? 9
    });
  }

  render() {
    // const {
    //   places, mapApiLoaded, mapInstance, mapApi,
    // } = this.state;
    const { mapApiLoaded, mapInstance, mapApi } = this.state;
    const {
      readonly,
      defaultCenter = {
        "lat": 13.0482176,
        "lng": 80.2095104
      },
      mapStyles
    } = this.props;

    return (
      <Wrapper>
        {/* <div className="info-wrapper">
          <div className="map-details">Latitude: <span>{this.state.lat}</span>, Longitude: <span>{this.state.lng}</span></div>
          <div className="map-details">Zoom: <span>{this.state.zoom}</span></div>
          <div className="map-details">Address: <span>{this.state.address}</span></div>
        </div> */}
        <GoogleMapReact
          options={{
            styles: mapStyles ? mapStyle[mapStyles] : mapStyle["style1"],
            disableDefaultUI: true
          }}
          center={this.state.center}
          zoom={this.state.zoom}
          defaultCenter={defaultCenter}
          draggable={this.state.draggable}
          onChange={this._onChange}
          onChildMouseDown={!readonly ? this.onMarkerInteraction : null}
          onChildMouseUp={!readonly ? this.onMarkerInteractionMouseUp : null}
          onChildMouseMove={!readonly ? this.onMarkerInteraction : null}
          // onChildClick={!readonly ? this._onClick : null}
          onClick={!readonly ? this._onClick : null}
          bootstrapURLKeys={{
            // libraries: ["places", "geometry"],
            key: `${config.googleMapApiKey}`,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this?.apiHasLoaded(map, maps)}

        >
          <Marker
            text={this?.state?.address}
            lat={this?.state?.lat}
            lng={this?.state?.lng}
          />
        </GoogleMapReact>
        {this?.props?.isInput && mapApiLoaded && (
          <Box sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            width: `calc(100% - 20px)`,

            ...this.props.autoCompletePlacement
          }}>
            <AutoComplete
              map={mapInstance}
              mapApi={mapApi}
              addplace={this.addPlace}
              fullScreenControl={this.props.fullScreenControl}
              fullScreenMap={this.props.fullScreenMap}
            />
          </Box>
        )}
      </Wrapper>
    );
  }
}

export default MyGoogleMap;
