export const CorrectiveMaintenance = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105127">
            <g
                data-name="FM - Service Requests - Corrective Maintenance"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 97969"
                    d="M14.291 13.712a3.691 3.691 0 1 0 0 5.222 3.691 3.691 0 0 0 0-5.222Zm-.365 4.857a3.175 3.175 0 1 1 0-4.492 3.175 3.175 0 0 1 0 4.492Z"
                />
                <path
                    data-name="Path 97970"
                    d="m16.381 19.446.251-.251a.258.258 0 0 0 0-.365l-.662-.664a4.676 4.676 0 1 0-2.448 2.448l.662.662a.258.258 0 0 0 .365 0l.251-.251 1.9 1.9a.258.258 0 0 0 .365 0l1.214-1.214a.258.258 0 0 0 0-.365Zm-7.642-.18a4.158 4.158 0 1 1 5.88 0 4.158 4.158 0 0 1-5.88 0Zm5.629 1.465-.359-.365a4.533 4.533 0 0 0 1.713-1.713l.359.359Zm2.515 1.648-1.716-1.719.849-.849 1.716 1.716Z"
                />
                <path
                    data-name="Path 97971"
                    d="m13.832 16.604-.233-.13c.005-.055.008-.109.008-.162s0-.107-.008-.162l.233-.13a.258.258 0 0 0 .123-.292 2.306 2.306 0 0 0-.64-1.076.259.259 0 0 0-.3-.038l-.224.125a.033.033 0 0 1-.025 0 3.129 3.129 0 0 0-.294-.172v-.25a.258.258 0 0 0-.192-.249 2.565 2.565 0 0 0-.667-.085 2.472 2.472 0 0 0-.606.086.258.258 0 0 0-.192.249v.258a2.009 2.009 0 0 0-.291.163.037.037 0 0 1-.026 0l-.223-.125a.259.259 0 0 0-.3.038 2.306 2.306 0 0 0-.641 1.075.258.258 0 0 0 .124.292l.232.13q-.008.082-.008.162t.008.162l-.232.13a.258.258 0 0 0-.124.292 2.305 2.305 0 0 0 .64 1.076.259.259 0 0 0 .3.038l.224-.125a.037.037 0 0 1 .025 0 3.185 3.185 0 0 0 .294.172v.25a.258.258 0 0 0 .191.249 2.457 2.457 0 0 0 .638.085h.026a2.472 2.472 0 0 0 .606-.086.258.258 0 0 0 .191-.249v-.258a1.963 1.963 0 0 0 .292-.163.034.034 0 0 1 .025 0l.223.125a.259.259 0 0 0 .3-.038 2.309 2.309 0 0 0 .641-1.075.258.258 0 0 0-.118-.292Zm-.767-.545a1.328 1.328 0 0 1 .025.253 1.35 1.35 0 0 1-.025.253.258.258 0 0 0 .128.274l.2.112a1.79 1.79 0 0 1-.307.51l-.048-.027a.545.545 0 0 0-.566.018 1.446 1.446 0 0 1-.216.12.526.526 0 0 0-.306.48v.041a1.9 1.9 0 0 1-.287.026 1.927 1.927 0 0 1-.336-.026v-.042a.526.526 0 0 0-.306-.48 1.445 1.445 0 0 1-.215-.12.547.547 0 0 0-.567-.019l-.049.027a1.789 1.789 0 0 1-.306-.51l.2-.113a.258.258 0 0 0 .128-.274 1.283 1.283 0 0 1 0-.506.259.259 0 0 0-.128-.274l-.2-.113a1.8 1.8 0 0 1 .307-.51l.048.027a.547.547 0 0 0 .566-.018 1.484 1.484 0 0 1 .215-.12.525.525 0 0 0 .306-.48v-.034a1.846 1.846 0 0 1 .287-.026 2.118 2.118 0 0 1 .336.026v.042a.525.525 0 0 0 .306.48 1.467 1.467 0 0 1 .216.121.546.546 0 0 0 .566.018l.049-.027a1.793 1.793 0 0 1 .306.51l-.2.113a.259.259 0 0 0-.128.274Z"
                />
                <path
                    data-name="Path 97972"
                    d="M11.64 15.32a1 1 0 1 0 1 1 1 1 0 0 0-1-1Zm0 1.476a.48.48 0 1 1 .48-.48.48.48 0 0 1-.48.476Z"
                />
                <path
                    data-name="Path 97973"
                    d="M22.761 7h-7.749a.258.258 0 0 0-.258.258v4.908h.517V7.517h7.232v9.815H16.82v.517h5.941a.258.258 0 0 0 .258-.258V7.258A.258.258 0 0 0 22.761 7Z"
                />
                <path
                    data-name="Path 97974"
                    d="m15.97 9.677.548-.548.548.548.365-.365-.548-.548.548-.548-.365-.365-.548.548-.548-.548-.365.365.548.548-.548.548Z"
                />
                <path
                    data-name="Path 97975"
                    d="m21.715 15.083-.548.548-.548-.548-.365.365.548.548-.548.548.365.365.548-.548.548.548.365-.365-.548-.548.548-.548Z"
                />
                <path
                    data-name="Path 97976"
                    d="m18.596 12.706-.576-.481-.331.4.775.646a.259.259 0 0 0 .364-.033l1.292-1.55-.4-.331Z"
                />
                <path
                    data-name="Path 97977"
                    d="M18.887 10.1a2.325 2.325 0 1 0 2.325 2.325 2.325 2.325 0 0 0-2.325-2.325Zm0 4.133a1.808 1.808 0 1 1 1.808-1.808 1.808 1.808 0 0 1-1.808 1.808Z"
                />
            </g>
        </g>
    </svg>
)