import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import { imageStyles } from "./style";

export const ImageCardComponent = ({ list }) => {
  const classes = imageStyles();
  const [image, setImage] = React.useState(0);

  const leftClick = () => {
    setImage(image - 1);
  };

  const rightClick = () => {
    setImage(image + 1);
  };

  return (
    <>
      <div>
        <div className={classes.topImage}>

          {/* BIG IMAGE SECTION */}


          <img src={list[image]} alt="" />


          {/* ARROWS SECTION */}

          <div className={classes.arrows}>

            {/* LEFT ARROW */}

            {image > 0 && (
              <div className={classes.leftArrow}>
                <ArrowBackIosIcon onClick={leftClick} />
              </div>
            )}

            {/* RIGHT ARROW */}

            {image < list?.length - 1 && (
              <div className={classes.rightArrow}>
                <ArrowForwardIosIcon onClick={rightClick} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* BOTTOM IMAGES SECTION */}

      <div className={classes.bottomImages}>
        {list.map((e, index) => {
          return (
            <>
              <img
                className={`${classes.images} ${index === image ? classes.activeImage : ""
                  }`}
                onClick={() => setImage(index)}
                src={e}
                alt="assests"
              />
            </>
          );
        })}
      </div>
    </>
  );
};
