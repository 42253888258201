import { Typography } from "@mui/material";
import React from 'react'
import { TableWithInfinityScroll, TableWithPagination } from "../../../../components";
import { useStyles } from "./style";

export const TableDetail = ({ title, heading, path, dataType, tabelheight, tableData, fetchMoreData = () => false, infinityheight, noDataSvg = false, fontSize = "", tableWithPagination, handleTablePagination = () => false, handleTablePageLimit = () => false, limit, page, list, handleTableIcon = () => false, totalRowsCount }) => {

    const classes = useStyles();

    return <div className={classes.root}>
        <Typography className={classes.title}>
            {title}
        </Typography>
        {/* As per CR commented */}
        {/* <TableWithPagination
            heading={heading}
            rows={tableData ?? []}
            path={path}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            tableType="no-side"
            dataType={dataType}
            height={tabelheight}
            view={true}
            edit={true}
            delete={true} /> */}
        {tableWithPagination ?
            <TableWithPagination
                heading={heading}
                rows={tableData ?? []}
                path={path}
                showpagination={true}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                // handleIcon={handleTableIcon}
                onClick={() => console.log("")}
                tableType="no-side"
                dataType={dataType}
                handlePagination={handleTablePagination}
                handleChangeLimit={handleTablePageLimit}
                totalRowsCount={totalRowsCount}
                page={page}
                noDataText={"No Discussion Found"}
                limit={limit}
                height={tabelheight}
                view={true}
                edit={true}
                delete={true}
            />
            

            :

            <TableWithInfinityScroll
                heading={heading}
                rows={tableData ?? []}
                path={path}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                fetchMoreData={fetchMoreData}
                showpagination={false}
                view={true}
                edit={true}
                delete={true}
                dataType={dataType}
                height={tabelheight}
                infinityheight={infinityheight}
                noDataSvg={noDataSvg}
                fontSize={fontSize}
                noDataText={"No Discussion Found"}
            />
            // ""
        }
    </div>
}