import * as React from "react"

const CarIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={42.78}
        height={89.97}
        {...props}
    >
        <g data-name="Group 105711">
            <path
                data-name="Path 98957"
                d="M2.137 66.101a42.115 42.115 0 0 0-.416 5.89v4.192A13.785 13.785 0 0 0 15.508 89.97h11.764a13.785 13.785 0 0 0 13.787-13.787v-4.192a42.116 42.116 0 0 0-.416-5.89l-.822-5.781a41.741 41.741 0 0 1-.411-6.469l.335-24.01a14.849 14.849 0 0 1 .66-4.159 14.723 14.723 0 0 0 .66-4.365V10.661A10.658 10.658 0 0 0 30.401 0H12.387A10.658 10.658 0 0 0 1.726 10.661V21.32a14.777 14.777 0 0 0 .66 4.365 14.656 14.656 0 0 1 .66 4.159l.335 24.01a41.74 41.74 0 0 1-.411 6.469l-.822 5.782Z"
                fill="#e0e0e0"
            />
            <path
                data-name="Path 98958"
                d="M39.726 64.911a38.982 38.982 0 0 1 .395 5.555v3.954a13.01 13.01 0 0 1-13.008 13.008h-11.1A13.01 13.01 0 0 1 3 74.42v-3.954a38.927 38.927 0 0 1 .395-5.555l.779-5.457a39.722 39.722 0 0 0 .389-6.1l-.314-22.657a13.819 13.819 0 0 0-.622-3.927A13.789 13.789 0 0 1 3 22.654V12.597a10.062 10.062 0 0 1 10.06-10.06h17.006a10.062 10.062 0 0 1 10.06 10.06v10.06a13.788 13.788 0 0 1-.627 4.116 13.789 13.789 0 0 0-.622 3.927l-.314 22.657a39.679 39.679 0 0 0 .389 6.1l.779 5.457Z"
                fill="#fcfcfc"
            />
            <path
                data-name="Path 98959"
                d="M2.781 63.191a12.565 12.565 0 0 1-2.623-2.3c-.373-.746 0-1.428.249-1.368s3.17 1.3 3.17 1.3l-.8 2.358Z"
                fill="#e2dfe6"
            />
            <path
                data-name="Path 98960"
                d="M39.999 63.191a12.565 12.565 0 0 0 2.623-2.3c.373-.746 0-1.428-.249-1.368s-3.17 1.3-3.17 1.3l.8 2.358Z"
                fill="#e2dfe6"
            />
            <g data-name="Group 105279">
                <g data-name="Group 105278">
                    <path
                        data-name="Path 98961"
                        d="M11.954 87.119a2.045 2.045 0 0 1 .13 1.066 13.151 13.151 0 0 1-6.1-5.009 12.564 12.564 0 0 0 3.045 1.888c1.125.487 2.45.925 2.921 2.055Z"
                        fill="#fff"
                    />
                    <path
                        data-name="Path 98962"
                        d="M30.823 87.119a2.045 2.045 0 0 0-.13 1.066 13.151 13.151 0 0 0 6.1-5.009 12.564 12.564 0 0 1-3.045 1.888c-1.125.487-2.45.925-2.921 2.055Z"
                        fill="#fff"
                    />
                    <path
                        data-name="Path 98963"
                        d="M7.887 40.49c-.059 4.711-.173 9.027-.173 10.612 0 4.754-3.543 9.227-3.543 9.227V41.896c1.217-.524 2.466-.962 3.716-1.406Z"
                        fill="#45474a"
                    />
                    <path
                        data-name="Path 98964"
                        d="M7.789 28.775c.146 2.991.151 7.107.108 11.034-1.255.346-2.5.725-3.727 1.163V26.99a49.881 49.881 0 0 1 3.618 1.779Z"
                        fill="#45474a"
                    />
                    <path
                        data-name="Path 98965"
                        d="M4.171 25.973v-4.879s3.078.092 3.543 6.431c.011.173.027.352.038.535a40.8 40.8 0 0 0-3.575-2.088Z"
                        fill="#45474a"
                    />
                    <path
                        data-name="Path 98966"
                        d="M26.174 89.034h-9.569a13.988 13.988 0 0 1-3.353-.406 7.356 7.356 0 0 1 4.138-1.276h8.005a7.327 7.327 0 0 1 4.138 1.276 14.266 14.266 0 0 1-3.353.406Z"
                        fill="#45474a"
                    />
                    <path
                        data-name="Path 98967"
                        d="M34.893 40.49c.059 4.711.173 9.027.173 10.612 0 4.754 3.543 9.227 3.543 9.227V41.896c-1.217-.524-2.466-.962-3.716-1.406Z"
                        fill="#45474a"
                    />
                    <path
                        data-name="Path 98968"
                        d="M34.99 28.775c-.146 2.991-.151 7.107-.108 11.034 1.255.346 2.5.725 3.727 1.163V26.99a49.881 49.881 0 0 0-3.618 1.779Z"
                        fill="#45474a"
                    />
                    <path
                        data-name="Path 98969"
                        d="M38.609 25.973v-4.879s-3.078.092-3.543 6.431c-.011.173-.027.352-.038.535a40.793 40.793 0 0 1 3.575-2.088Z"
                        fill="#45474a"
                    />
                    <path
                        data-name="Path 98970"
                        d="M36.699 63.423a17.11 17.11 0 0 0-3.234-7.713 1.56 1.56 0 0 0-1.39-.617c-1.79.151-6.161.481-10.682.481s-8.9-.33-10.682-.481a1.559 1.559 0 0 0-1.39.617 17.11 17.11 0 0 0-3.234 7.713 1.581 1.581 0 0 0 .8 1.606c1.6.871 5.636 2.385 14.512 2.385s12.922-1.514 14.512-2.385a1.577 1.577 0 0 0 .788-1.606Zm-3.4-38.694a29.432 29.432 0 0 0-23.81 0 36.131 36.131 0 0 1-1.487-8.546 2.211 2.211 0 0 1 1.222-2.131 27.545 27.545 0 0 1 24.34 0 2.211 2.211 0 0 1 1.222 2.131 36.41 36.41 0 0 1-1.489 8.546Z"
                        fill="#45474a"
                    />
                </g>
                <path
                    data-name="Path 98971"
                    d="M8.536 2.547a7.925 7.925 0 0 0-3.17 1.85 3.167 3.167 0 0 1-.746.6.937.937 0 0 1-.649.07 7.952 7.952 0 0 1 5.371-3.191v.022c-.011.373-.454.546-.806.654Z"
                    fill="#ff320e"
                />
                <path
                    data-name="Path 98972"
                    d="M34.244 2.547a7.925 7.925 0 0 1 3.17 1.85 3.167 3.167 0 0 0 .746.6.937.937 0 0 0 .649.07 7.952 7.952 0 0 0-5.371-3.191v.022c.011.373.454.546.806.654Z"
                    fill="#ff320e"
                />
            </g>
        </g>
    </svg>
)

export default CarIcon
