import makeStyles from "@mui/styles/makeStyles";


export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white",
    padding: "24px 16px",
    margin: "14px",
    height: `calc(100vh - 147px)`,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "13px",
  },

  img3: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  outerCircle: {
    padding: "2px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
  },
  noPadding: {
    paddingTop: "0px !important",
  },
  PaddingTop: {
    paddingTop: "7px !important",
  },
  previousbtn: {
    backgroundColor: "white",
    color: theme.typography.color.primary,
    border: "1px solid #CED3DD",
    borderRadius: theme.palette.borderRadius,
    padding: "8px 12px",
    boxShadow: "none",
    '&:hover': {
      backgroundColor: "white",
    }
  },
  submitbtn: {
    borderRadius: theme.palette.borderRadius,
    marginLeft: "8px",
    padding: "8px 12px",
    boxShadow: "none"
  },
  alt_divider: {
    display: "none"
  },
  divider_menu: {
    border: "1px solid #EBEEF3",
    margin: "3px 0px"
  },
  menuparent: {
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
}));
