import * as React from "react"

export const OppertunityTimeLine = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 107675" transform="translate(-121 -503)">
      <circle
        data-name="Ellipse 39517"
        cx={20}
        cy={20}
        r={20}
        transform="translate(121 503)"
        fill="#fff9e4"
      />
      <g data-name="Group 102054">
        <g data-name="Group 105382">
          <path
            data-name="Path 96788"
            d="M145.406 520.462a1.261 1.261 0 0 1-.5.84c-1.485 1.486-2.985 2.958-4.451 4.463a1 1 0 0 1-1.691-.008c-.656-.708-1.372-1.361-2.046-2.054-.34-.349-.6-.744-.162-1.2s.827-.249 1.205.089c.062.056.117.121.178.178 2.047 1.879 1.333 2 3.4-.064q1.338-1.337 2.68-2.668a.772.772 0 0 1 .907-.255.744.744 0 0 1 .478.682"
            fill="#fbfbfb"
          />
          <path
            d="M137.238 514.001a2.133 2.133 0 0 0-2.114 1.9h-1.211a1.674 1.674 0 0 0-1.663 1.662v2.85a3.1 3.1 0 0 0 3.088 3.088h.023a5.474 5.474 0 0 0 4.489 3.748v1.477h-1.662a3.091 3.091 0 0 0-3.088 3.088v.475a.712.712 0 0 0 .712.712h9.5a.712.712 0 0 0 .712-.712v-.475a3.091 3.091 0 0 0-3.087-3.087h-1.662v-1.478a5.474 5.474 0 0 0 4.489-3.748h.023a3.1 3.1 0 0 0 3.087-3.088v-2.85a1.674 1.674 0 0 0-1.662-1.662h-1.211a2.133 2.133 0 0 0-2.113-1.9Zm-3.325 3.325h1.187v4.512c0 .073.008.143.011.215a1.645 1.645 0 0 1-1.436-1.64v-2.85a.227.227 0 0 1 .238-.237Zm12.112 0h1.188a.227.227 0 0 1 .238.237v2.85a1.645 1.645 0 0 1-1.436 1.64c0-.072.011-.143.011-.215Z"
            fill="#ffcd40"
          />
        </g>
      </g>
    </g>
  </svg>
)
