import * as React from "react"

export const LeadAgreement = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
        <path data-name="Rectangle 33" fill="none" d="M0 0h18v18H0z" />
        <g data-name="Group 5326">
            <path
                data-name="Union 14"
                d="M3.8 18h-.02a2.737 2.737 0 0 1-1.905-.774A2.8 2.8 0 0 1 1 15.187v-.03a2.84 2.84 0 0 1 1.853-2.676V5.519A2.842 2.842 0 0 1 1 2.842s0-.01 0-.015v-.015A2.805 2.805 0 0 1 1.9.749 2.737 2.737 0 0 1 3.78 0h.032A2.812 2.812 0 0 1 5.62 4.968a2.777 2.777 0 0 1-.913.551v4.5c.134-.053.262-.118.4-.16a25.788 25.788 0 0 1 3.634-.607 7.37 7.37 0 0 0 3.1-1.008 2.965 2.965 0 0 0 1.2-2.73 2.785 2.785 0 0 1-.9-.539A2.812 2.812 0 0 1 13.938 0h.033a2.736 2.736 0 0 1 1.894.764 2.805 2.805 0 0 1 .885 2.048v.03a2.842 2.842 0 0 1-1.857 2.678 5.134 5.134 0 0 1-1.98 4.28 8.669 8.669 0 0 1-3.9 1.336 30.12 30.12 0 0 0-3.372.546 1.9 1.9 0 0 0-.766.39.512.512 0 0 0-.163.415 2.781 2.781 0 0 1 .909.549A2.812 2.812 0 0 1 3.813 18Z"
                fill={props?.color}
            />
        </g>
    </svg>
)

