import React from "react"

export const RatingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g id="Group_113387" data-name="Group 113387" transform="translate(-541 -418)">
            <g id="Rectangle_57465" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                <rect width="26" height="26" stroke="none" />
                <rect x="0.5" y="0.5" width="25" height="25" fill="none" />
            </g>
            <path id="icons8-star_2_" data-name="icons8-star (2)" d="M15.506,5a.863.863,0,0,0-.777.477L11.766,11.4,4.731,12.485a.863.863,0,0,0-.478,1.462l4.866,4.866L8.035,25.857a.863.863,0,0,0,1.251.9L15.5,23.51l6.214,3.242a.863.863,0,0,0,1.251-.9l-1.084-7.043,4.866-4.866a.863.863,0,0,0-.478-1.462L19.235,11.4,16.272,5.477A.863.863,0,0,0,15.506,5ZM15.5,7.791l2.391,4.782a.863.863,0,0,0,.64.467l5.793.892L20.353,17.9a.863.863,0,0,0-.243.741L21,24.435l-5.1-2.662a.863.863,0,0,0-.8,0L10,24.435l.891-5.791a.863.863,0,0,0-.243-.741L6.676,13.932l5.793-.892a.863.863,0,0,0,.64-.467Z" transform="translate(539 414.6)" fill="#78b1fe" />
        </g>
    </svg>

)