import { Box, Typography, Grid, Stack } from '@mui/material'
import React from 'react'
import NoneSlot from '../../../assets/noneslot'
import { AvailableSlotsStyle } from './style'
import moment from 'moment'
import { withNamespaces } from 'react-i18next'
import { Bold, useWindowDimensions } from '../../../utils'
import { ToggleButtonComponent } from '../../../components'
import { AmenitiesBookingContext } from '../amenitiesBookingContext'
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ElectricIcon from '../utils/electricIcon'

const AvailableSlots = (
    {
        availableSlot = [],
        openPopup = () => false,
        selectSlot = () => false,
        selectedSlot = [],
        t = () => false,
        selectedVenue = {},
        data = {}
    }
) => {
    const {
        slotToggle,
        slotType,
        // setSlotType
    } = React.useContext(AmenitiesBookingContext)

    const size = useWindowDimensions()
    const classes = AvailableSlotsStyle({ size, selectedVenue })
    var selectedIndex = []
    if (selectedSlot?.length > 0) {
        selectedIndex = selectedSlot?.map(e => {
            return e.index
        })
    }

    // const notes = {
    //     "Slot basis": "Available slots can be chosen",
    //     "Half day": "5hr slot considered as half day",
    //     "Full Day": "10hrs slots considered as full day"
    // }

    return (
        <Box>
            <Box sx={{ position: "relative" }}>
                {
                    availableSlot?.length !== 0 &&
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <ToggleButtonComponent
                            t={t}
                            options={slotToggle}
                            value={slotType}
                            // onChange={(value) => setSlotType(value)}
                            onChange={() => {}}
                            isMulti={false}
                            fullWidth={false}
                            labelFontSize="12px"
                            height="32px"
                            buttonStyle={{ fontFamily: Bold }}
                        />
                        {/* <Stack p={1} className={classes.notesBox} direction={"row"} spacing={2} alignItems={"center"}>
                            <InfoOutlinedIcon sx={{ color: "#78B1FE", fontSize:"1rem" }} />
                            <Typography className={classes.notesText}>{notes?.[slotType]}</Typography>
                        </Stack> */}
                        {
                            selectedVenue?.slots_info?.[1]?.slots?.length > 0 &&
                            <Stack direction={"row"} spacing={1} alignItems={"center"} className={classes.peakBox} px={1}>
                                <ElectricIcon />
                                <Typography className={classes.peakBoxText}>{`Peak Hour ${selectedVenue?.symbol}${selectedVenue?.slots_info?.[1]?.rate} / hr`}</Typography>
                            </Stack>
                        }
                    </Stack>
                }
                <Box className={classes.root_none}>
                    {
                        availableSlot?.length === 0 ?
                            <Box className={classes.none}>
                                <Stack alignItems={"center"} spacing={2}>
                                    <NoneSlot />
                                    <Stack alignItems={"center"} spacing={1}>
                                        <Typography className={classes.noneTitle}>{t("SelectVenuetoviewslots")}</Typography>
                                        <Typography className={classes.noneContent}>{t("Slotswillbeviewableonceyouselectthevenue")}</Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                            :
                            <Box >
                                <Grid container sx={{ height: "calc(100vh - 320px)", overflow: "auto" }}>
                                    <Grid item md={12} lg={12} sm={12} pt={2}>
                                        <Grid container spacing={1}>
                                            {
                                                availableSlot?.map((list, index) => {
                                                    return (
                                                        <Grid item sm={4} md={4} lg={3}
                                                            onClick={() => {
                                                                if (selectedVenue?.period === "Hourly" ? !list?.info?.is_booked && !list?.info?.is_expired
                                                                    : !list?.is_booked && !list?.is_expired) {
                                                                    if (selectedVenue?.operational_status !== "Under Maintenance") {
                                                                        selectSlot(list, index)
                                                                    }
                                                                }
                                                            }}
                                                            sx={{ position: "relative" }}>

                                                            <Stack p={1} className={
                                                                selectedVenue?.operational_status === "Under Maintenance" ? classes.maintanceBox :
                                                                    (selectedVenue?.period === "Hourly" ? list?.info?.is_expired : list?.is_expired) ? classes.booked_box :
                                                                        (selectedVenue?.period === "Hourly" ? list?.info?.is_booked : list?.is_booked) ? classes.booked_box :
                                                                            selectedIndex.includes(index) ? classes.selectedBox : classes.box
                                                            }
                                                                alignItems={"center"} justifyContent={"center"}>

                                                                {/* {selectedVenue?.is_overbooking && <Typography className={classes.overBookingLabel}>OB</Typography>} */}

                                                                {list?.info?.is_peak && <Box className={classes.slotPeakBox}><ElectricIcon /></Box>}

                                                                <Typography noWrap className={
                                                                    selectedVenue?.operational_status === "Under Maintenance" ? classes.maintanceText :
                                                                        (selectedVenue?.period === "Hourly" ? list?.info?.is_expired : list?.is_expired) ?
                                                                            classes.slotTimeBooked :
                                                                            (selectedVenue?.period === "Hourly" ? list?.info?.is_booked : list?.is_booked) ?
                                                                                classes.slotTimeBooked : selectedIndex.includes(index) ? classes.selectedTime : classes.time
                                                                }
                                                                >
                                                                    {list?.check_in_date ? moment(list?.check_in_date).format("DD MMM YY") :
                                                                        moment(list?.check_in_time, "HH:mm").format("hh:mm A")} - {list?.check_out_date ? moment(list?.check_out_date).format("DD MMM YY") :
                                                                            moment(list?.check_out_time, "HH:mm").format("hh:mm A")}
                                                                </Typography>

                                                                {
                                                                    selectedVenue?.operational_status === "Under Maintenance" ?
                                                                        <Typography className={classes.maintanceContent} mt={1}>{t("Under Maintenance")}</Typography> :

                                                                        (selectedVenue?.period === "Hourly" ? list?.info?.is_expired : list?.is_expired) ?
                                                                            <Typography className={classes.slotTimeBooked} mt={1}>{t("expired")}</Typography>
                                                                            :
                                                                            (selectedVenue?.period === "Hourly" ? list?.info?.is_booked : list?.is_booked) ?
                                                                                <Typography className={classes.slotTimeBooked} mt={1}>{t("booked")}</Typography>
                                                                                :
                                                                                <Stack direction={"row"} alignItems={"center"} spacing={1} mt={1}>
                                                                                    {/* <Typography className={selectedIndex.includes(index) ? classes.selectedContent : classes.content} noWrap>
                                                                                        {list?.check_in_date ? "24 hrs" : `${selectedVenue?.slot_partition} hrs`}
                                                                                    </Typography>
                                                                                    <Box className={classes.dot}></Box> */}
                                                                                    <Typography className={selectedIndex.includes(index) ? classes.selectedContent : classes.content} noWrap>
                                                                                        {
                                                                                            selectedVenue?.is_overbooking &&
                                                                                            `${selectedVenue?.period === "Hourly" ? list?.info?.book_count : list?.book_cout} / ${selectedVenue?.participants_count} Booked`
                                                                                            // :
                                                                                            // `${selectedVenue?.participants_count} Persons / Members`
                                                                                        }
                                                                                    </Typography>
                                                                                </Stack>
                                                                }
                                                            </Stack>

                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box >
                    }
                </Box >
            </Box >
        </Box >
    )
}

export default withNamespaces("amenityBooking")(AvailableSlots)
