import { FromAppIcon, UnVerifiedIcon, ValidIcon, VerifiedIcon } from "../../../assets/leasemanagerdashboard";
import * as All from "../assets";

export const StatDataFunc = (t=()=>false,_) => {
    let cardData = [
        {
            count: _?.property_count ?? 0,
            label: t("Properties"),
            icon: (All["ActiveAgreement"])
        },
        {
            count: _?.unit_count ?? 0,
            label: t("Total Units"),
            icon: (All["AgreementTerminationReq"])
        },
        {
            count: _?.block_count ?? 0,
            label: t("Blocks"),
            icon: (All["VacantUnit"])
        },
        {
            count: _?.vacant_count ?? 0,
            label: t("Vacant Units"),
            icon: (All["VacantUnit"])
        },
        {
            count: _?.floor_count ?? 0,
            label: t("Floors"),
            icon: (All["AgreementTerminationReq"])
        },
        {
            count: _?.occupied_count ?? 0,
            label: t("Occupied Units"),
            icon: (All["AgreementTerminationReq"])
        },
    ]
    let cardDetails1 = [
        {
            count: _?.activeAgreement ?? 0,
            label: "Active Agreement",
            icon: (All["ActiveAgreement"])
        },
        {
            count: _?.terminatedRequest ?? 0,
            label: "Termination Req",
            icon: (All["AgreementTerminationReq"])
        },
        // {
        //     count: _?.annoucements ?? 0,
        //     label: "Announcement",
        //     icon: (All["Announcements"])
        // }
        {
            count: _?.owners ?? 0,
            label: "Owners",
            icon: (All["Owners"])
        },
    ]

    let cardDetails2 =  [
        {
            count: _?.tenant_and_residents_count ?? 0,
            label: t("Residents"),
            icon: (All["Residents"])
        },
        {
            count: _?.owner_count ?? 0,
            label: "Owners",
            icon: (All["Owners"])
        },
        {
            count: _?.occupants_count ?? 0,
            label: t("Occupants"),
            icon: (All["NewResidents"])
        },
        {
            count: _?.public_contacts_count ?? 0,
            label: t("Public Contacts"),
            icon: (All["ResidentsLeft"])
        },
        {
            count: _?.committee_members ?? 0,
            label: t("Committee Members"),
            icon: (All["NewResidents"])
        },
        {
            count: _?.registered_worker_count ?? 0,
            label: t("Registered Workers"),
            icon: (All["ResidentsLeft"])
        },
    ]

    let cardDetails3 = [
        {
            count: _?.managementTeams ?? 0,
            label: t("Management Teams"),
            icon: (All["NewResidents"])
        },
        {
            count: _?.companyEmployees ?? 0,
            label: t("Employees"),
            icon: (All["Employees"])
        },
        {
            count: _?.securities ?? 0,
            label: t("Security Personals"),
            icon: (All["SecurityPersonals"])
        },
        // {
        //     count: _?.coResidents ?? 0,
        //     label: "Co-Residents",
        //     icon: (All["NewResidents"])
        // },
    ]

    let cardDetails4 = [
        {
            count: _?.publised_announcement_count ?? 0,
            label: t("Published Announcements"),
            icon: (All["Residents"])
        },
        {
            count: _?.draft_announcement_count ?? 0,
            label: t("In Draft"),
            icon: (All["Discussion"])
        },
        {
            count: _?.polls ?? 0,
            label: t("Active Polls/Survey"),
            icon: (All["Polls"])
        },
    ]

    let cardDetails5 = [
        {
            count: _?.team_count ?? 0,
            label: t("Management Team"),
            icon: (All["NewResidents"])
        },
        {
            count: _?.registered_worker_count ?? 0,
            label: t("Registered Workers"),
            icon: (All["DomesticContact"])
        },
        {
            count: _?.public_contacts_count ?? 0,
            label: t("Public Contacts"),
            icon: (All["PublicContact"])
        },
        // {
        //     count: _?.contractEmployees ?? 0,
        //     label: "Contract Employees",
        //     icon: (All["ContractEmployees"])
        // },
    ]

    let cardDetails6 = [
        {
            count: _?.security_personal_count ?? 0,
            label: toString("Security Personals"),
            icon: (All["SecurityPersonals"])
        },
        {
            count: _?.resource_count ?? 0,
            label: t("Resources"),
            icon: (All["Owners"])
        },
        {
            count: _?.open_login_request_count ?? 0,
            label: t("Open Login Requests"),
            icon: (All["Owners"])
        },
    ]
    let bookingData = [
        {
            count: _?.booking_today_count ?? 0,
            label: t("Bookings Today"),
            icon: (All["Residents"])
        },
        {
            count: _?.booking_tomorrow_count ?? 0,
            label: t("Bookings Tomorrow"),
            icon: (All["Discussion"])
            // label: "Cancelled Bookings",

            // icon: (All["Polls"])
        },
        {
            count: _?.unpaid_count ?? 0,
            label: t("Unpaid Bookings"),

            icon: (All["Surveys"])
        },
    ]
    let kyc_type = [
        {
            count: _?.kyc_type?.verified ?? 0,
            name: t("Verified KYC"),
            icon: <VerifiedIcon />
        },
        {
            count: _?.kyc_type?.pending ?? 0,
            name: t("Unverified KYC"),
            icon: <UnVerifiedIcon />
        },
        {
            count: _?.kyc_type?.valid ?? 0,
            name: t("Valid"),
            icon: <ValidIcon />
        },
        {
            count: _?.kyc_type?.app_count??0,
            name: t("From App"),
            icon: <FromAppIcon />
        },
    ]
    let correspondence_type={
        data:_?.correspondence_type?.map((val)=>{
            return{
                ...val,
                name:val?.type
            }
        }),
        total:_?.correspondence_type?.map((item) => parseInt(item?.count))
        .reduce((a, b) => a + b, 0)
    }
    let WorkerRequest_data=[
        {
            name:"Approved",
            count:parseInt(_?.registered_work_group_counts?.approved)??0,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
        {
            name:"Pending",
            count:parseInt(_?.registered_work_group_counts?.pending)??0,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
        {
            name:"Cancelled",
            count:parseInt(_?.registered_work_group_counts?.cancelled)??0,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
        {
            name:"Rejected",
            count:parseInt(_?.registered_work_group_counts?.rejected)??0,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        }
    ]
    let loginAccessRequest=[
        {
            name:"Approved",
            count:parseInt(_?.login_access_request_count?.approved)??0,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
        {
            name:"Pending",
            count:parseInt(_?.login_access_request_count?.pending)??0,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
        {
            name:"Cancelled",
            count:parseInt(_?.login_access_request_count?.cancelled)??0,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
        {
            name:"Revoked",
            count:parseInt(_?.login_access_request_count?.revoked)??0,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
        {
            name:"Rejected",
            count:parseInt(_?.login_access_request_count?.rejected)??0,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        }
    ]
    return { cardData, cardDetails1, cardDetails2, cardDetails3, cardDetails4, cardDetails5, cardDetails6, bookingData,kyc_type,correspondence_type,WorkerRequest_data,loginAccessRequest }
}



export const TableData = [
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-001",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-002",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-003",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-004",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-005",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-006",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-007",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-008",
    },
    {
        "saleRep": { image: "/images/propertybg.svg", name: "Bala" },
        "unitName": "UNT-009",
    },
]

export const GraphDetails1 = [
    {
        "name": "Violation 1",
        "count": 1,
        "fill": "#c55837"
    },
    {
        "name": "Violation 2",
        "count": 2,
        "fill": "#3943fc"
    },
    {
        "name": "Violation3",
        "count": 11,
        "fill": "#fb620e"
    }
]

export const GraphDetails2 = {
    "title": "Violation Status",
    "graphData": [
        {
            "name": "Open",
            "count": 10,
            "fill": "#F3E137"
        },
        {
            "name": "InProgress",
            "count": 4,
            "fill": "#5AC782"
        },
        {
            "name": "New",
            "count": 8,
            "fill": "#fb620e"
        }
    ],
    "total": 22,
}