import React from "react";

function RecordActualExpense() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="0.177"
          cy="0.274"
          r="0.722"
          gradientTransform="matrix(1.133 0 0 1 -.067 0)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0"></stop>
          <stop offset="1" stopOpacity="0"></stop>
        </radialGradient>
        <linearGradient
          id="linear-gradient"
          x1="0.157"
          x2="0.853"
          y1="0.157"
          y2="0.853"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fede00"></stop>
          <stop offset="1" stopColor="#ffd000"></stop>
        </linearGradient>
      </defs>
      <g data-name="Group 109100" transform="translate(-49 -953)">
        <g transform="translate(50.001 954)">
          <circle
            cx="5.429"
            cy="5.429"
            r="5.429"
            fill="#c9d4e0"
            data-name="Ellipse 129638"
            transform="translate(8 16.143)"
          ></circle>
          <circle
            cx="4.286"
            cy="4.286"
            r="4.286"
            fill="#b2bbc7"
            data-name="Ellipse 129639"
            transform="translate(9.143 17.286)"
          ></circle>
          <path
            fill="#c9d4e0"
            d="M18.116 36.16v-.817a1.781 1.781 0 00.947.272.89.89 0 00.591-.18.605.605 0 00.213-.489q0-.645-.91-.644a5.761 5.761 0 00-.717.054v-2.422h2.4v.781h-1.583v.868q.19-.018.36-.018a1.479 1.479 0 011.046.351 1.226 1.226 0 01.378.944 1.393 1.393 0 01-.451 1.072 1.729 1.729 0 01-1.222.416 2.621 2.621 0 01-1.052-.188z"
            data-name="Path 100037"
            transform="translate(-6.05 -12.4)"
          ></path>
          <path
            d="M11.046 30.909a10.207 10.207 0 004.154 1.328 9.124 9.124 0 005.3 2.79 5.426 5.426 0 10-9.459-4.118z"
            data-name="Path 100038"
            opacity="0.05"
            transform="translate(-3.02 -9.857)"
          ></path>
          <path
            d="M11.266 30.315a9.575 9.575 0 004.3 1.364A8.483 8.483 0 0020.97 34.5a5.19 5.19 0 001-3.067 5.408 5.408 0 00-10.705-1.113z"
            data-name="Path 100039"
            opacity="0.07"
            transform="translate(-3.114 -9.857)"
          ></path>
          <circle
            cx="8"
            cy="8"
            r="8"
            fill="#ff8f6b"
            data-name="Ellipse 129640"
            transform="translate(10.857 8.143)"
          ></circle>
          <circle
            cx="6.857"
            cy="6.857"
            r="6.857"
            fill="#ed6c47"
            data-name="Ellipse 129641"
            transform="translate(12 9.286)"
          ></circle>
          <path
            fill="#f5be00"
            d="M26.492 27.251h-3.365v-.927h1.1V23.01l-1.132.245v-.95l2.3-.464v4.482h1.095z"
            data-name="Path 100040"
            transform="translate(-8.184 -8.075)"
          ></path>
          <path
            fill="#ff8f6b"
            d="M32.049 27.342q-1.9 0-1.9-2.668a3.634 3.634 0 01.514-2.11 1.713 1.713 0 011.49-.726q1.856 0 1.855 2.713a3.575 3.575 0 01-.506 2.071 1.667 1.667 0 01-1.453.72zm.052-4.609q-.76 0-.761 1.911 0 1.8.746 1.8.728 0 .727-1.855t-.713-1.855z"
            data-name="Path 100041"
            transform="translate(-11.207 -8.074)"
          ></path>
          <path
            fill="url(#radial-gradient)"
            d="M16 20a7.981 7.981 0 003.013 6.249 10.252 10.252 0 009.158-13.066A7.988 7.988 0 0016 20z"
            data-name="Path 100042"
            transform="translate(-5.143 -3.857)"
          ></path>
          <circle
            cx="9.143"
            cy="9.143"
            r="9.143"
            fill="url(#linear-gradient)"
            data-name="Ellipse 129642"
            transform="translate(4 3)"
          ></circle>
          <circle
            cx="7.429"
            cy="7.429"
            r="7.429"
            fill="#f5be00"
            data-name="Ellipse 129643"
            transform="translate(5.714 4.714)"
          ></circle>
          <g data-name="Group 109101" transform="translate(11.101 8.46)">
            <path
              fill="#fee119"
              d="M16.427 19.607v-1.363a2.977 2.977 0 001.58.454 1.485 1.485 0 00.987-.3 1.012 1.012 0 00.356-.815q0-1.076-1.519-1.075a9.539 9.539 0 00-1.2.091v-4.044h4.008v1.3H18v1.449q.318-.03.6-.03a2.471 2.471 0 011.746.585 2.047 2.047 0 01.631 1.575 2.326 2.326 0 01-.752 1.789 2.886 2.886 0 01-2.039.694 4.346 4.346 0 01-1.759-.31z"
              data-name="Path 100043"
              transform="translate(-16.427 -12.555)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RecordActualExpense;