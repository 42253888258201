import React from "react";
import { withNavBars } from "../../HOCs";
import UtilityReadings from "./utilityReadings"

class UtilityReadingsParent extends React.Component {
    render() {
        return <UtilityReadings/>;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UtilityReadingsParent, props);
