import React from "react";
import { withNavBars } from "../../HOCs";
import DynamicMaintenancePlanner from "./dynamicMaintenancePlanner";

class DynamicMaintenancePlannerParent extends React.Component {
    render() {
        return <DynamicMaintenancePlanner  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(DynamicMaintenancePlannerParent, props);
