import React from 'react'
import { DutyRosterHeading, DutyRosterPath, DutyRosterType } from '../utils/dutyRoster'
import { AlertContext } from '../../../contexts'
import { format } from 'date-fns'
import { AlertProps, NetWorkCallMethods } from '../../../utils'
import { NetworkCall } from '../../../networkcall'
import { config } from '../../../config'
import { TableWithPagination } from '../../../components'

export const TableList = ({
    type = "",
    dutyData = {},
    t = () => false,
}) => {
    const alert = React.useContext(AlertContext)
    const [tableData, setTableData] = React.useState({
        list: [],
        count: 0,
    })
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)


    React.useEffect(() => {
        getTableData()
        // eslint-disable-next-line
    }, [])

    const getTableData = (offset = 0, limit = 10) => {
        const payload = {
            limit, offset, resource_id: "", contact_id: "",
            date: format(dutyData?.viewDate, "yyyy-MM-dd"),
        }
        if (type === "resource") {
            payload.resource_id = dutyData?.selectedEmploee?.id;
        } else {
            payload.contact_id = dutyData?.selectedEmploee?.id;
        }
        NetworkCall(
            `${config.api_url}${type === "resource" ? `/duty_roaster/calendar_log` : `/contact_duty_roaster/calendar_log`}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            const constructedData = res?.data?.data?.map((e) => {
                return {
                    resource_name: type === "resource" ? e?.resource_name : e?.contact_name,
                    location: e?.location_name,
                    checkin: format(new Date(`${e?.checkin_date} ${e?.checkin_time}`), "dd MMM yy p") + (e?.in_timezone && ` (${e?.in_timezone})`) ?? "-",
                    checkout: e?.checkout_date !== null ? format(new Date(`${e?.checkout_date} ${e?.checkout_time}`), "dd MMM yy p") + (e?.out_timezone && ` (${e?.out_timezone})`) : "-",
                    check_in_distance: e?.in_distance ? `${e?.in_distance.toFixed(2)} ${t("Kms")}` : "-",
                    check_out_distance: e?.out_distance ? `${e?.out_distance.toFixed(2)} ${t("Kms")}` : "-",
                    status: e?.status,
                    time_diff: e?.time_diff
                }
            })

            setTableData({
                list: constructedData,
                count: res?.data?.count,
            })

        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong
            });
        })
    }


    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getTableData(offset, limit)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getTableData(0, value)
    }


    return (
        <>
            <TableWithPagination
                heading={DutyRosterHeading(t)}
                rows={tableData?.list}
                path={DutyRosterPath}
                showpagination={true}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                onClick={() => false}
                tableType="no-side"
                dataType={DutyRosterType}
                handlePagination={handleTablePagination}
                handleChangeLimit={handleTablePageLimit}
                totalRowsCount={tableData?.count}
                page={page}
                limit={limit}
                height={'calc(100vh - 290px)'} />
        </>
    )
}