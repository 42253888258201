import * as React from "react"
const OnBoardingIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <g data-name="Group 105298">
            <g data-name="Group 105332">
                <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 55483" />
                <path
                    fill="#091b29"
                    d="M10 1a1.432 1.432 0 0 0-1.425 1.544l-4.511 2.6a1.431 1.431 0 1 0-1.428 2.468v4.778a1.431 1.431 0 1 0 1.428 2.469l4.509 2.6a1.431 1.431 0 1 0 2.852 0l4.511-2.6a1.431 1.431 0 1 0 1.427-2.471V7.61a1.431 1.431 0 1 0-1.428-2.469l-4.509-2.6A1.432 1.432 0 0 0 10 1Zm-.813 2.608a1.426 1.426 0 0 0 1.628 0L15.318 6.2a1.432 1.432 0 0 0 .813 1.407v4.783a1.432 1.432 0 0 0-.812 1.4l-4.501 2.602a1.426 1.426 0 0 0-1.628 0L4.677 13.8a1.432 1.432 0 0 0-.813-1.407V7.61a1.432 1.432 0 0 0 .812-1.4ZM10 5.5a2.041 2.041 0 0 0-1.532.654 2.145 2.145 0 0 0 0 2.784 2.123 2.123 0 0 0 3.065 0 2.145 2.145 0 0 0 0-2.784A2.041 2.041 0 0 0 10 5.5Zm0 1.227a.736.736 0 0 1 .615.241.921.921 0 0 1 0 1.154.736.736 0 0 1-.615.241.736.736 0 0 1-.615-.241.921.921 0 0 1 0-1.154.736.736 0 0 1 .615-.241Zm-1.841 3.682a1.441 1.441 0 0 0-1.432 1.432v.614a1.84 1.84 0 0 0 1.1 1.552 4.6 4.6 0 0 0 2.171.493 4.6 4.6 0 0 0 2.171-.493 1.84 1.84 0 0 0 1.1-1.552v-.614a1.441 1.441 0 0 0-1.432-1.432Zm0 1.227h3.682a.2.2 0 0 1 .2.2v.614c0 .087-.09.275-.456.472a3.507 3.507 0 0 1-1.589.346 3.507 3.507 0 0 1-1.589-.346c-.366-.2-.456-.385-.456-.472v-.614a.2.2 0 0 1 .208-.2Z"
                />
            </g>
        </g>
    </svg>
)
export default OnBoardingIcon
