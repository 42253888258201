import * as React from "react"

export const TableIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 107578" transform="translate(-622 -339)">
      <rect
        data-name="Rectangle 53619"
        width={32}
        height={32}
        rx={4}
        transform="translate(622 339)"
        fill="#f1f7ff"
      />
      <g data-name="Group 107577">
        <path
          data-name="Path 97708"
          d="M644.5 350.506h-6.75l-1.5-1.506h-3.75a1.508 1.508 0 0 0-1.5 1.506v3.012h15v-1.506a1.508 1.508 0 0 0-1.5-1.506Z"
          fill="#ffb73f"
        />
        <path
          data-name="Path 97709"
          d="M644.5 350.506h-12a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h12a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5Z"
          fill="#ffe9a6"
        />
      </g>
    </g>
  </svg>
)
