import * as React from "react"

const LogOut = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      d="M2.444 0A2.455 2.455 0 0 0 0 2.444v11.112A2.455 2.455 0 0 0 2.444 16h8a2.455 2.455 0 0 0 2.444-2.444.667.667 0 1 0-1.333 0 1.1 1.1 0 0 1-1.111 1.111h-8a1.1 1.1 0 0 1-1.111-1.111V2.444a1.1 1.1 0 0 1 1.111-1.111h8a1.1 1.1 0 0 1 1.111 1.111.667.667 0 1 0 1.333 0A2.455 2.455 0 0 0 10.444 0Zm9.771 4.215a.667.667 0 0 0-.464 1.145l1.973 1.973h-9.5a.667.667 0 1 0 0 1.333h9.5l-1.973 1.974a.667.667 0 1 0 .943.943L15.8 8.471a.667.667 0 0 0 0-.943l-3.111-3.111a.667.667 0 0 0-.478-.2Z"
      fill="#FF4B4B"
    />
  </svg>
)

export default LogOut
