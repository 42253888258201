import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
    profileicon: {
        fontSize: "97px",
        padding: "22px",
        backgroundColor: "#F2F4F7",
        borderRadius: "50%",
        position: "relative",
    },
    address: {
        margin: "30px 0px 0px  0px",
        borderBottom: "4px solid #F5F7FA",
        borderTop: "4px solid #F5F7FA",
        padding: "8px 0px",
    },
    address1: {
        borderBottom: "4px solid #F5F7FA",
    },
    addresstitle: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        textTransform: "uppercase"
    },
    addAddress: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: "#5078E1",
        cursor: "pointer",
    },
    addAddressButtonContainerStyle: {
        padding: "15px",
        background: "white",
        border: "1px solid #E4E8EE",
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        "& button": {
            padding: '10px',
            marginLeft: '10px',
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
            },
        },
    },
    addContactParent: {
        marginTop: '8px'
    },
    addressbox: {
        padding: "8px",
        border: "1px solid #071741",
        borderRadius: theme.palette.borderRadius,
        position: "relative",
        display: "inline-block"
    },
    next: {
        marginLeft: "10px",
        fontFamily: SemiBold,
        color: "#fff",
        backgroundColor: '#5078E1',
        "&:hover": {
            backgroundColor: '#5078E1',
        },
    },
    Cancel: {
        backgroundColor: "#FFFFFF ",
        color: "#091B29",
        fontSize:"0.875rem",
        fontWeight: 600,
        fontFamily: SemiBold,
        border: '1px solid #E4E8EE',
    },
    addressList: {
        fontSize:"0.75rem",
        color: "#98A0AC",
        fontFamily: SemiBold,
        marginTop: "12px",
    },
    addresstitles: {
        fontSize:"0.875rem",
        color: "#091B29",
        fontFamily: Bold,
    },
    primary: {
        fontSize:"0.75rem",
        padding: "2px 4px 1px 4px",
        backgroundColor: "#071741",
        borderRadius: "4px",
        color: "white",
        fontFamily: ExtraBold,
    },
    addressbox1: {
        padding: "8px",
        border: "1px solid #071741",
        borderRadius: "8px",
        position: "relative",
        display: "inline-block"
    },
    profileTitle: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: "#4E5A6B",
        marginBottom: "12px"
    },
    uploadImage: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: "#071741",
        cursor: "pointer",
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        padding: "4px 10px",
        display: "inline-block",
        marginTop: "12px"
    },
    close: {
        position: "absolute",
        right: "0px",
        top: "0px",
        backgroundColor: "red",
        borderRadius: "50%",
        color: "white",
        fontSize: "1.25rem",
    },
    imgdiv: {
        position: "relative",
        height: "100px",
        width: "100px",
    },
    accountName: {
        color: '#091B29',
        fontFamily: Bold,
        fontSize:"0.875rem",
    },
    accountNamelist: {
        color: '#091B29',
        fontFamily: SemiBold,
        fontSize:"0.875rem",
    },
    accountNo: {
        color: "#4E5A6B",
        fontSize:"0.75rem",
    },
    accountRoot: {
        border: "1px solid #E4E8EE",
        boxShadow: "0px 4px 16px #0000001F",
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
    },
    accountDropdownRoot: {
        borderTop: "2px solid #E4E8EE",

    },
    accountListRoot: {
        overflow: "auto",
        // boxShadow: "0px 0px 16px #00000014",
        marginTop: "10px",
        // position:"absolute",
        width:'100%',
        zIndex:1,
        backgroundColor:'#fff'
    },
    AccountImg: {
        padding: "12px 14px 10px 14px",
        backgroundColor: "#EEF9EE",
        borderRadius: theme.palette.borderRadius
    },
    AccountImg1: {
        borderRadius: theme.palette.borderRadius,
        padding: "12px 14px 10px 14px",
        backgroundColor: "#F2F4F7",
    },
    titleMidle: {
        color: '#4E5A6B',
        fontFamily: Bold,
        fontSize:"0.75rem",
        marginBottom:"8px"
    },
    selectedAccount:{
        backgroundColor:"#e2eafd"
    },
    accountlistroot:{
        borderRadius:'4px'
    },
    tickIcon: {
        color: "#5078e1",
        fontSize: "2.5rem"
  },
  subTitle:{
    fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
  },
  btn: {
    border: "1px solid #CED3DD",
    color: "#091B29",
    fontFamily: Bold,
    fontSize:"0.875rem",
    backgroundColor: "white ",
    width:"100%",
    "&:hover": {
        backgroundColor: "white ",
    },
},
sumbitBtn: {
    fontFamily: Bold,
    fontSize:"0.875rem",
    width:"100%",
}
}));