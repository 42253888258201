import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { AlertDialog, DailyCalendar, Subheader } from '../../components'
import { Calendars } from './calendars'
import DeclinePopup from './declinePopup'
import CheckAvailability from './checkAvailability'
import { accessCheckRender, getRoutePermissionNew } from '../../utils'
import { AuthContext } from '../../contexts'
import moment from 'moment'
import { AmenitiesBookingContext } from './amenitiesBookingContext'
import BookingRecidentPopup from './bookingRecidentPopup'

const AmenitiesBooking = () => {


    const {
        title,
        openPopup,
        handleTableIcon,
        handleEvent,
        handleMonthEvent,
        closeDailyCalendarPopup,
        getCompany,
        handleCompanyChange,
        handlePropertyChange,
        handleAmenityCategoryChange,
        handleAmenityChange,
        handleAmenityViewChange,
        getDateRange,
        updateAmenityBookingList,
        getAmenityBookingList,
        getAmenityBookingCalandarList,
        handleSearchTextChange,
        handlePagination,
        handleChangeLimit,
        closeCheckAvailability,
        closeBookingResidentPopup,
        goBack,
        // state
        // openCA,
        openDecline,
        companyList,
        selectedCompany,
        propertyList,
        selectedProperty,
        amenityCategoryList,
        selectedAmenityCategory,
        amenityList,
        selectedAmenity,
        selectedAmenityView,
        searchText,
        amenityBooking,
        page,
        limit,
        amenityBookingDetail,
        dateRange,
        calendarData,
        calendarEvent,
        openDailyCalendar,
        weekdates,
        selected,
        initialLoad,
        step,
        data,
        // state functions
        setWeekdates,
        setSelected,
        setInitialLoad,
        setOpenDecline,
        t = () => false,
        fromType
    } = useContext(AmenitiesBookingContext)

    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        // eslint-disable-next-line
    }, [auth]);


    React.useEffect(() => {
        if (dateRange?.length > 0 && selectedAmenity?.value && selectedCompany?.value && !initialLoad) {
            getAmenityBookingList(
                selectedAmenity, dateRange,
                selectedCompany, searchText, 0, limit
            )
            getAmenityBookingCalandarList(
                selectedAmenity, dateRange,
                selectedCompany, selected
            )
            setInitialLoad(true)
        }
        // eslint-disable-next-line
    }, [dateRange, selectedAmenity, selectedCompany])


    const render = () => {
        return (
            <Box>
                <Subheader
                    hideBackButton={step === "step1" ? true : false}
                    title={step === "step1" ? t(title) : t("CheckAvailability")}
                    goBack={() => goBack()}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => handleCompanyChange(e)}
                    selectProperty
                    propertyOptions={propertyList}
                    propertyValue={selectedProperty}
                    onPropertyChange={(e) => handlePropertyChange(e)}
                    isReadOnly={step !== "step1"} />

                {
                    step === "step1" &&
                    <Calendars
                        permission={permission}
                        openPopup={openPopup}
                        handleTableIcon={handleTableIcon}
                        amenityCategoryList={amenityCategoryList}
                        selectedAmenityCategory={selectedAmenityCategory}
                        handleAmenityCategoryChange={handleAmenityCategoryChange}
                        amenityList={amenityList}
                        selectedAmenity={selectedAmenity}
                        selectedAmenityView={selectedAmenityView}
                        handleAmenityChange={handleAmenityChange}
                        handleAmenityViewChange={handleAmenityViewChange}
                        searchText={searchText}
                        handleSearchTextChange={handleSearchTextChange}
                        amenityBooking={amenityBooking}
                        calendarData={calendarData}
                        page={page}
                        limit={limit}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        getDateRange={getDateRange}
                        handleEvent={handleEvent}
                        handleMonthEvent={handleMonthEvent}
                        setWeekdates={setWeekdates}
                        weekdates={weekdates}
                        selected={selected}
                        setSelected={setSelected}
                        t={t}
                        fromType={fromType}
                    />
                }

                {
                    step === "step2" &&
                    <CheckAvailability
                        t={t}
                        getAmenityBookingList={getAmenityBookingList}
                        closeCheckAvailability={closeCheckAvailability}
                        selectedCompany={selectedCompany}
                        selectedProperty={selectedProperty}
                        selectedAmenityCategory={selectedAmenityCategory} />
                }

                {
                    step === "step3" &&
                    <BookingRecidentPopup
                        t={t}
                        getAmenityBookingList={getAmenityBookingList}
                        closeCheckAvailability={closeCheckAvailability}
                        closeBookingResidentPopup={closeBookingResidentPopup}
                        amenityData={data?.selectedVenue}
                        bookingDate={data?.selectedDate}
                        slots={data?.selected_slot}
                        selectedCompany={selectedCompany} selectedProperty={selectedProperty} />
                }

                {/* Check Availability */}
                {/* <AlertDialog xl open={openCA}
                    onClose={closeCheckAvailability}
                    header={t("CheckAvailability")}
                    component={<CheckAvailability
                        getAmenityBookingList={getAmenityBookingList}
                        closeCheckAvailability={closeCheckAvailability}
                        selectedCompany={selectedCompany}
                        selectedProperty={selectedProperty}
                        selectedAmenityCategory={selectedAmenityCategory} />} /> */}
                {/* Decline popup */}
                <AlertDialog md open={openDecline}
                    onClose={() => setOpenDecline(!openDecline)}
                    header={calendarEvent !== "" ? calendarEvent?.booking_id : amenityBookingDetail?.booking_id}
                    component={
                        <DeclinePopup
                            amenityBookingDetail={calendarEvent !== "" ? calendarEvent : amenityBookingDetail}
                            type="decline" closeDecline={() => setOpenDecline(!openDecline)}
                            updateAmenityBookingList={updateAmenityBookingList} />
                    } />

                {/* Daily calender for month calendar */}

                <AlertDialog md open={openDailyCalendar}
                    onClose={closeDailyCalendarPopup}
                    header={`Daily ${moment(openDailyCalendar?.selectedDate).format("DD-MM-YYYY")}`}
                    component={
                        <DailyCalendar progress="0" list={calendarData?.dailyList} handleEvent={handleEvent} />
                    } />

            </Box>
        )
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}
export default AmenitiesBooking
