import React from "react";
import { Subheader } from "../../components";

import { DashBoard } from "./dashboard";
import { withNamespaces } from "react-i18next";

const ClientManagerDashboard = ({
  loading = true,
  handleLoading = () => false,
  t = () => false,
}) => {

  return (
    <div>
      <Subheader
        hideBackButton
        title={t("Client Manager Dashboard")}
      />
      <DashBoard
        loading={loading}
        handleLoading={handleLoading}
        t={t} />
    </div>
  );

};
export default withNamespaces("clientManagerDashboard")(ClientManagerDashboard);
