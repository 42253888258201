import { Grid, Typography } from "@mui/material";
import React from 'react';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { unitStyles } from "../style";
import { convertTimeUtcToZoneCalander } from "../../../utils";

export const GovermentDetails = (props) => {
      const { t } = (props);
      const classes = unitStyles()
      const size = useWindowDimensions()


      return (
            <div style={{ height: size?.height - 210, overflow: "auto", padding: "4px", margin: "-4px" }}>
                  <div className={classes.card}>
                        <Typography className={classes.title} >{t("ID DETAILS")}</Typography>
                        {/*goverment detials first card */}
                        <Grid container>
                              <Grid item md={12}>
                                    <Grid container>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Nationality")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.nationality}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("National ID")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.national_id}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("National ID Expiry")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.national_id_expiry ? convertTimeUtcToZoneCalander(props?.data?.national_id_expiry) : '-'}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Passport ID")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.passport_id}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Passport Expiry")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.passport_expiry ? convertTimeUtcToZoneCalander(props?.data?.passport_expiry) : '-'}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Resident ID")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.resident_id}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Resident ID Expiry")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.resident_id_expiry ? convertTimeUtcToZoneCalander(props?.data?.resident_id_expiry) : "-"}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Visa Number")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.visa_no}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Visa Expiry")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.visa_expiry ? convertTimeUtcToZoneCalander(props?.data?.visa_expiry) : "-"}</Typography>
                                          </Grid>
                                    </Grid>

                              </Grid>
                        </Grid>
                  </div>
            </div>
      )
}