import { Stack, Typography } from "@mui/material";
import { config } from "../../config";
import { login_tag } from "../../utils";
import { useStyles } from "./style";

export const EnvTag = (props) => {

    const classes = useStyles(props)

    return (
        <Stack className={classes.tag}
            direction="row" spacing={"8px"}
            justifyContent="center" alignItems={"center"}>
            {login_tag?.[config?.app_env]?.icon}
            <Typography className={classes.tag_text}>
                {login_tag?.[config?.app_env]?.label}
            </Typography>
        </Stack>
    )
}