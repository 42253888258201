import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import  InventoryMaster  from './inventoryMaster'

class InventoryMasterParent extends Component {
    render() {
        return (
            <div>
                <InventoryMaster />
            </div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(InventoryMasterParent, props)