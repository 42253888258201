export const OccupancyView = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105164">
            <g
                data-name="LS - CRM - Availilbility View"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 98204"
                    d="M8 13.237v6.915A2.847 2.847 0 0 0 10.847 23h8.678a2.847 2.847 0 0 0 2.847-2.847v-6.916ZM18.658 16.3l-3.824 3.8a.654.654 0 0 1-.488.19.779.779 0 0 1-.488-.19l-2.17-2.142a.671.671 0 0 1 .949-.949l1.681 1.681 3.336-3.336.027.027a.707.707 0 0 1 .949 0 .623.623 0 0 1 .028.919Zm.868-7.4h-.271V7.678a.678.678 0 1 0-1.356 0V8.9h-5.424V7.678a.678.678 0 0 0-1.356 0V8.9h-.272A2.847 2.847 0 0 0 8 11.747v.136h14.373v-.137A2.847 2.847 0 0 0 19.525 8.9Z"
                />
            </g>
        </g>
    </svg>
)