import React from "react"

export const AssignIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="232" height="54" viewBox="0 0 232 54">
  <g id="Group_105084" data-name="Group 105084" transform="translate(948 22462)">
    <rect id="Rectangle_55498" data-name="Rectangle 55498" width="231" height="54" transform="translate(-947 -22462)" fill="none"/>
    <g id="Group_105084-2" data-name="Group 105084" transform="translate(-9.612 -39)">
      <g id="Group_105090" data-name="Group 105090" transform="translate(0 -2)">
        <g id="icons8-male-user" transform="translate(-847 -22418)">
          <path id="Path_97589" data-name="Path 97589" d="M24,4A20,20,0,1,0,44,24,20,20,0,0,0,24,4Z" transform="translate(0 0)" fill="#ede7f6"/>
          <path id="Path_97590" data-name="Path 97590" d="M24,44a19.6,19.6,0,0,0,11.9-4c-.6-7.6-8.2-9-8.2-9l-3.7.8L20.3,31s-7.6,1.4-8.2,9A19.6,19.6,0,0,0,24,44Z" transform="translate(0 0)" fill="#673ab7"/>
          <path id="Path_97591" data-name="Path 97591" d="M24,37a5.879,5.879,0,0,0,5.9-5.3,12.191,12.191,0,0,0-2-.7,3.927,3.927,0,0,1-4,3.9,3.992,3.992,0,0,1-4-3.9,12.191,12.191,0,0,0-2,.7A6.227,6.227,0,0,0,24,37Z" transform="translate(0 0)" fill="#311b92"/>
          <path id="Path_97592" data-name="Path 97592" d="M32,22.5A1.5,1.5,0,1,1,30.5,21,1.538,1.538,0,0,1,32,22.5m-13,0A1.5,1.5,0,1,0,17.5,24,1.538,1.538,0,0,0,19,22.5" transform="translate(0 0)" fill="#ffa726"/>
          <path id="Path_97593" data-name="Path 97593" d="M24,35c-4,0-4-4-4-4V27h8v4S28,35,24,35Z" transform="translate(0 0)" fill="#ff9800"/>
          <path id="Path_97594" data-name="Path 97594" d="M31,18.7c0-5.9-14-3.8-14,0v4.4a7,7,0,0,0,14,0Z" transform="translate(0 0)" fill="#ffb74d"/>
          <path id="Path_97595" data-name="Path 97595" d="M24,11a8.181,8.181,0,0,0-8,8.2V21l2,2V19l9.2-3L30,19v4l2-2v-.8c0-3.2-.8-6.8-4.8-7.6L26.4,11Z" transform="translate(0 0)" fill="#424242"/>
          <path id="Path_97596" data-name="Path 97596" d="M26,22a1,1,0,1,1,1,1,.945.945,0,0,1-1-1m-6,0a1,1,0,1,0,1-1,.945.945,0,0,0-1,1" transform="translate(0 0)" fill="#784719"/>
        </g>
        <g id="Group_105084-3" data-name="Group 105084" transform="translate(0 4)" opacity="0.75">
          <path id="Path_97589-2" data-name="Path 97589" d="M20,4A16,16,0,1,0,36,20,16,16,0,0,0,20,4Z" transform="translate(-795 -22418)" fill="#dcf9ff"/>
          <g id="icons8-design" transform="translate(-787 -22410)">
            <path id="Path_98891" data-name="Path 98891" d="M0,0H5.091V17.536H0Z" transform="matrix(0.707, -0.707, 0.707, 0.707, 4, 7.6)" fill="#42a5f5"/>
            <path id="Path_98892" data-name="Path 98892" d="M0,0H.8V2.546H0Z" transform="matrix(-0.707, -0.707, 0.707, -0.707, 8.871, 8.871)" fill="#1565c0"/>
            <path id="Path_98893" data-name="Path 98893" d="M0,0H.8V1.414H0Z" transform="matrix(0.707, 0.707, -0.707, 0.707, 15.729, 12.129)" fill="#1565c0"/>
            <path id="Path_98894" data-name="Path 98894" d="M0,0H.8V1.414H0Z" transform="matrix(0.707, 0.707, -0.707, 0.707, 8.917, 5.317)" fill="#1565c0"/>
            <path id="Path_98895" data-name="Path 98895" d="M0,0H.8V1.414H0Z" transform="matrix(0.707, 0.707, -0.707, 0.707, 18.141, 14.541)" fill="#1565c0"/>
            <path id="Path_98896" data-name="Path 98896" d="M0,0H.8V1.414H0Z" transform="matrix(0.707, 0.707, -0.707, 0.707, 11.317, 7.717)" fill="#1565c0"/>
            <path id="Path_98897" data-name="Path 98897" d="M0,0H2.546V.8H0Z" transform="matrix(0.707, -0.707, 0.707, 0.707, 15.129, 15.13)" fill="#1565c0"/>
            <path id="Path_98898" data-name="Path 98898" d="M38.153,6.627l-1.46-1.46a.57.57,0,0,0-.806,0l-.687.688L37.465,8.12l.688-.687a.571.571,0,0,0,0-.806" transform="translate(-18.72 -0.6)" fill="#e57373"/>
            <path id="Path_98899" data-name="Path 98899" d="M0,0H14.4V3.2H0Z" transform="matrix(0.707, -0.707, 0.707, 0.707, 5.163, 16.571)" fill="#ff9800"/>
            <path id="Path_98900" data-name="Path 98900" d="M0,0H1.6V3.2H0Z" transform="matrix(-0.707, 0.707, -0.707, -0.707, 18.744, 7.52)" fill="#b0bec5"/>
            <path id="Path_98901" data-name="Path 98901" d="M5.762,35.429,5,38.457l3.028-.762Z" transform="translate(-0.6 -18.857)" fill="#ffc107"/>
            <path id="Path_98902" data-name="Path 98902" d="M5.386,39.172,5,40.7l1.531-.386Z" transform="translate(-0.6 -21.103)" fill="#37474f"/>
          </g>
        </g>
        <g id="Group_105087" data-name="Group 105087" transform="translate(-147)" opacity="0.5">
          <path id="Path_97589-3" data-name="Path 97589" d="M20,4A16,16,0,1,0,36,20,16,16,0,0,0,20,4Z" transform="translate(-787 -22414)" fill="#eef9ee"/>
          <g id="icons8-movers" transform="translate(-775 -22405)">
            <path id="Path_98916" data-name="Path 98916" d="M10,9h9.328v5.331H10Z" transform="translate(-6.668 -3.334)" fill="#ce93d8"/>
            <path id="Path_98917" data-name="Path 98917" d="M20.663,5a1,1,0,0,0-1-1H15a1,1,0,0,0-1,1V7.665h6.663Z" transform="translate(-9.336)" fill="#9c27b0"/>
            <path id="Path_98918" data-name="Path 98918" d="M20.663,14H14V12.666A.667.667,0,0,1,14.666,12H20a.667.667,0,0,1,.666.666Z" transform="translate(-9.336 -5.335)" fill="#e1bee7"/>
            <path id="Path_98919" data-name="Path 98919" d="M.666,33.664h0A.666.666,0,0,1,0,33V28H1.333v5A.666.666,0,0,1,.666,33.664Z" transform="translate(0 -16.004)" fill="#2d4c5c"/>
            <path id="Path_98920" data-name="Path 98920" d="M44.666,33.664h0A.666.666,0,0,1,44,33V28h1.333v5A.666.666,0,0,1,44.666,33.664Z" transform="translate(-29.341 -16.004)" fill="#2d4c5c"/>
            <path id="Path_98921" data-name="Path 98921" d="M8,23H9.333v1H8Z" transform="translate(-5.335 -12.67)" fill="#ff9800"/>
            <path id="Path_98922" data-name="Path 98922" d="M36,23h1.333v1H36Z" transform="translate(-24.006 -12.67)" fill="#ff9800"/>
            <path id="Path_98923" data-name="Path 98923" d="M3.646,23.513l-.594-2.468a1.3,1.3,0,0,0-.121-.355l-.784-1.545.518-3.479v-.276a1.37,1.37,0,0,0-1.2-1.383A1.333,1.333,0,0,0,0,15.333v3.372a1.664,1.664,0,0,0,.38,1.059L1.8,21.485l.561,2.307a.659.659,0,0,0,.876.5.682.682,0,0,0,.411-.774" transform="translate(0 -6.669)" fill="#546e7a"/>
            <path id="Path_98924" data-name="Path 98924" d="M2.219,18.665l.05-.333.4-2.665v-.276a1.37,1.37,0,0,0-1.2-1.383A1.333,1.333,0,0,0,0,15.333v3.332Z" transform="translate(0 -6.669)" fill="#e64a19"/>
            <path id="Path_98925" data-name="Path 98925" d="M1.333,5A1.333,1.333,0,1,1,0,6.333,1.333,1.333,0,0,1,1.333,5" transform="translate(0 -0.667)" fill="#ffb74d"/>
            <path id="Path_98926" data-name="Path 98926" d="M37.054,23.513a.682.682,0,0,0,.411.774.659.659,0,0,0,.876-.5l.561-2.307,1.418-1.72a1.664,1.664,0,0,0,.38-1.059V15.333a1.333,1.333,0,0,0-1.464-1.326,1.37,1.37,0,0,0-1.2,1.383v.276l.518,3.479-.784,1.545a1.3,1.3,0,0,0-.121.355l-.594,2.468" transform="translate(-24.701 -6.669)" fill="#546e7a"/>
            <path id="Path_98927" data-name="Path 98927" d="M42.69,18.665V15.333a1.333,1.333,0,0,0-1.464-1.326,1.37,1.37,0,0,0-1.2,1.383v.276l.4,2.665.05.333Z" transform="translate(-26.69 -6.669)" fill="#0097a7"/>
            <path id="Path_98928" data-name="Path 98928" d="M41.358,5a1.333,1.333,0,1,1-1.333,1.333A1.333,1.333,0,0,1,41.358,5" transform="translate(-26.69 -0.667)" fill="#ffb74d"/>
            <path id="Path_98929" data-name="Path 98929" d="M35.23,17.484,34.857,19H33.025a.5.5,0,0,0,0,1h2.324a.666.666,0,0,0,.642-.49l.469-1.7a.636.636,0,0,0-.613-.8h0A.636.636,0,0,0,35.23,17.484Z" transform="translate(-21.689 -8.669)" fill="#ffb74d"/>
            <path id="Path_98930" data-name="Path 98930" d="M4.779,17.484,5.151,19H6.983a.5.5,0,0,1,0,1H4.66a.666.666,0,0,1-.642-.49l-.469-1.7a.635.635,0,0,1,.613-.8h0A.635.635,0,0,1,4.779,17.484Z" transform="translate(-2.351 -8.669)" fill="#ffb74d"/>
          </g>
        </g>
        <g id="Group_105085" data-name="Group 105085" transform="translate(-95 4)" opacity="0.75">
          <path id="Path_97589-4" data-name="Path 97589" d="M20,4A16,16,0,1,0,36,20,16,16,0,0,0,20,4Z" transform="translate(-795 -22418)" fill="#f6f4e7"/>
          <g id="icons8-tools" transform="translate(-788.999 -22412)">
            <path id="Path_98903" data-name="Path 98903" d="M8.8,21.487c.044-.044.577-.621,1.42-1.509,1.243-1.287,6.657-7.057,6.746-7.145.4-.4,2.308,1.065,3.906-.533A3.683,3.683,0,0,0,21.8,8.439l-2.042,2.042a2.462,2.462,0,0,1-2.219-2.219l2.042-2a3.8,3.8,0,0,0-3.906.932C14.121,8.75,15.5,10.7,15.1,11.1c-.089.044-7.678,7.279-7.767,7.367h0c-.754.71-.8.754-.843.754a1.718,1.718,0,0,0-.044,2.352A1.678,1.678,0,0,0,8.8,21.487ZM7.775,19.579a.666.666,0,1,1-.666.666A.654.654,0,0,1,7.775,19.579Z" transform="translate(0 -0.043)" fill="#607d8b"/>
            <path id="Path_98904" data-name="Path 98904" d="M21.09,19.758,9.772,8.53,8.663,6.888,6.621,6,6,6.621l.888,2.042L8.53,9.772,19.847,21Z" transform="translate(-0.001)" fill="#b0bec5"/>
            <path id="Path_98905" data-name="Path 98905" d="M21.758,19.228c-.932-2.219-1.465-1.021-2.53,0h0c-1.021,1.021-2.219,1.553,0,2.53,2.175.932,4.571,5.193,5.5,6.169a2.06,2.06,0,0,0,3.151,0h0a2.061,2.061,0,0,0,0-3.151C26.951,23.844,22.69,21.448,21.758,19.228Z" transform="translate(-6.661 -6.66)" fill="#ffc107"/>
            <path id="Path_98906" data-name="Path 98906" d="M19.252,19.228c1.021-1.021,1.553-2.219,2.53,0l-2.53,2.53C17.077,20.826,18.231,20.294,19.252,19.228Z" transform="translate(-6.684 -6.66)" fill="#ff9800"/>
          </g>
        </g>
        <g id="Group_105089" data-name="Group 105089" transform="translate(2)" opacity="0.5">
          <g id="Group_105086" data-name="Group 105086" transform="translate(42)">
            <path id="Path_97589-5" data-name="Path 97589" d="M20,4A16,16,0,1,0,36,20,16,16,0,0,0,20,4Z" transform="translate(-795 -22414)" fill="#f9eeee"/>
          </g>
          <g id="icons8-car-rental" transform="translate(-747 -22406)">
            <path id="Path_98907" data-name="Path 98907" d="M10.222,36.41A.428.428,0,0,0,9.778,36H8.444A.428.428,0,0,0,8,36.41v2.051a.428.428,0,0,0,.444.41H9.778a.428.428,0,0,0,.444-.41Zm12,0a.428.428,0,0,0-.444-.41H20.444a.428.428,0,0,0-.444.41v2.051a.428.428,0,0,0,.444.41h1.333a.428.428,0,0,0,.444-.41Z" transform="translate(-1.111 -18.872)" fill="#6d4c41"/>
            <path id="Path_98908" data-name="Path 98908" d="M22.667,23.513H12s1.493-3.692,1.778-4.1a1.029,1.029,0,0,1,.889-.41H20a1.021,1.021,0,0,1,.889.41C21.113,19.77,22.667,23.513,22.667,23.513Z" transform="translate(-3.333 -8.846)" fill="#e3f2fd"/>
            <path id="Path_98909" data-name="Path 98909" d="M21.333,28.41,15.111,28l-6.222.41A.857.857,0,0,0,8,29.231v3.282a.428.428,0,0,0,.444.41H21.778a.428.428,0,0,0,.444-.41V29.231A.857.857,0,0,0,21.333,28.41Z" transform="translate(-1.111 -14.154)" fill="#ff3d00"/>
            <path id="Path_98910" data-name="Path 98910" d="M16.667,18.821a1.6,1.6,0,0,1,1.4,1.129c.095.215.887,2.047.887,2.047l.224.516H8.825L9.048,22c.268-.621.8-1.846.883-2.039.476-1.054,1.194-1.136,1.4-1.136h5.333m0-.821H11.333a2.464,2.464,0,0,0-2.222,1.641c-.113.25-.889,2.051-.889,2.051H6.444A.428.428,0,0,0,6,22.1v.821a.428.428,0,0,0,.444.41H21.556a.428.428,0,0,0,.444-.41V22.1a.428.428,0,0,0-.444-.41H19.778s-.794-1.836-.889-2.051A2.458,2.458,0,0,0,16.667,18h0Z" transform="translate(0 -8.256)" fill="#ff3d00"/>
            <path id="Path_98911" data-name="Path 98911" d="M22.444,32.641a.428.428,0,0,1-.444.41H10.444a.428.428,0,0,1-.444-.41V31.41a.428.428,0,0,1,.444-.41H22a.428.428,0,0,1,.444.41Z" transform="translate(-2.222 -15.923)" fill="#37474f"/>
            <path id="Path_98912" data-name="Path 98912" d="M11.667,32a.617.617,0,1,0,.667.615A.643.643,0,0,0,11.667,32Zm10.222,0a.617.617,0,1,0,.667.615A.643.643,0,0,0,21.889,32Z" transform="translate(-2.778 -16.513)" fill="#fff176"/>
            <g id="Group_105088" data-name="Group 105088" transform="translate(9.111 4)">
              <path id="Path_98913" data-name="Path 98913" d="M25.778,8.641H20V7h2.222v.821L23.111,7H24v.821L24.889,7h.889l.889.409v.821Z" transform="translate(-16.889 -5.769)" fill="#ffa000"/>
              <path id="Path_98914" data-name="Path 98914" d="M15.222,4A2.142,2.142,0,0,0,13,6.051,2.142,2.142,0,0,0,15.222,8.1a2.142,2.142,0,0,0,2.222-2.051A2.142,2.142,0,0,0,15.222,4Zm-.889,2.462a.412.412,0,1,1,.444-.41A.428.428,0,0,1,14.333,6.462Z" transform="translate(-13 -4)" fill="#ffa000"/>
            </g>
            <path id="Path_98915" data-name="Path 98915" d="M23.654,8.641V7H22.578a1.889,1.889,0,0,1,0,1.641Z" transform="translate(-9.21 -1.769)" fill="#d67c05"/>
          </g>
        </g>
      </g>
      <g id="Group_105091" data-name="Group 105091" transform="translate(0 0.389)">
        <path id="icons8-sparkling" d="M4,32a.353.353,0,0,1-.335-.241l-.356-1.068-1.068-.356a.353.353,0,0,1,0-.67l1.068-.356.356-1.068A.353.353,0,0,1,4,28H4a.353.353,0,0,1,.335.241l.356,1.068,1.068.356a.353.353,0,0,1,0,.67l-1.068.356-.356,1.068A.353.353,0,0,1,4,32Z" transform="translate(-944.266 -22439.002) rotate(-8)" fill="#78b1fe"/>
        <path id="icons8-sparkling-2" data-name="icons8-sparkling" d="M4.5,33a.441.441,0,0,1-.419-.3l-.445-1.335L2.3,30.919a.441.441,0,0,1,0-.837l1.335-.445L4.081,28.3A.441.441,0,0,1,4.5,28h0a.441.441,0,0,1,.419.3l.445,1.335,1.335.445a.441.441,0,0,1,0,.837l-1.335.445L4.919,32.7A.441.441,0,0,1,4.5,33Z" transform="translate(-792.993 -22406.385) rotate(11)" fill="#78b1fe"/>
        <path id="icons8-sparkling-3" data-name="icons8-sparkling" d="M4,32a.353.353,0,0,1-.335-.241l-.356-1.068-1.068-.356a.353.353,0,0,1,0-.67l1.068-.356.356-1.068A.353.353,0,0,1,4,28H4a.353.353,0,0,1,.335.241l.356,1.068,1.068.356a.353.353,0,0,1,0,.67l-1.068.356-.356,1.068A.353.353,0,0,1,4,32Z" transform="matrix(0.966, 0.259, -0.259, 0.966, -886.099, -22405.014)" fill="#78b1fe"/>
        <path id="icons8-sparkling-4" data-name="icons8-sparkling" d="M4,32a.353.353,0,0,1-.335-.241l-.356-1.068-1.068-.356a.353.353,0,0,1,0-.67l1.068-.356.356-1.068A.353.353,0,0,1,4,28H4a.353.353,0,0,1,.335.241l.356,1.068,1.068.356a.353.353,0,0,1,0,.67l-1.068.356-.356,1.068A.353.353,0,0,1,4,32Z" transform="matrix(0.966, 0.259, -0.259, 0.966, -705.099, -22405.014)" fill="#78b1fe"/>
        <path id="icons8-sparkling-5" data-name="icons8-sparkling" d="M4,32a.353.353,0,0,1-.335-.241l-.356-1.068-1.068-.356a.353.353,0,0,1,0-.67l1.068-.356.356-1.068A.353.353,0,0,1,4,28H4a.353.353,0,0,1,.335.241l.356,1.068,1.068.356a.353.353,0,0,1,0,.67l-1.068.356-.356,1.068A.353.353,0,0,1,4,32Z" transform="matrix(0.966, 0.259, -0.259, 0.966, -745.65, -22444.014)" fill="#78b1fe"/>
        <path id="icons8-sparkling-6" data-name="icons8-sparkling" d="M4,32a.353.353,0,0,1-.335-.241l-.356-1.068-1.068-.356a.353.353,0,0,1,0-.67l1.068-.356.356-1.068A.353.353,0,0,1,4,28H4a.353.353,0,0,1,.335.241l.356,1.068,1.068.356a.353.353,0,0,1,0,.67l-1.068.356-.356,1.068A.353.353,0,0,1,4,32Z" transform="translate(-858.596 -22440.52) rotate(-17)" fill="#78b1fe"/>
      </g>
    </g>
  </g>
</svg>

    )
}