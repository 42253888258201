
export const myOrgTableHeading = (t) => {
    return [
        { title: t("Image"), field: "image" },
        { title: t("Contact Name"), field: "contact_name" },
        { title: t("Type"), field: "role_type" },
        { title: t("Role"), field: "role" },
        { title: t("Start Period"), field: "start_date" },
        { title: t("Status"), field: "is_active" },
        { title: "", field: "more" },
    ]
}

export const myOrgTableDatatype = [
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "contact_name" },
    { type: ["text"], name: "role_type" },
    { type: ["role"], name: "role" },
    { type: ["text"], name: "start_date" },
    { type: ["status"], name: "is_active" },
    { type: ["more"], name: "icon" },
];

export const myOrgTablePath = [
    "image",
    "contact_name",
    "role_type",
    "role",
    "start_date",
    "is_active",
    "more"
]