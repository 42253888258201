import * as React from "react"

export const TickImageComponent = (props) => {
  return (
    <>
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="Group 105489" transform="translate(-129 -275)">
      <circle
        data-name="Ellipse 129665"
        cx={8}
        cy={8}
        r={8}
        transform="translate(129 275)"
        fill="#fff"
      />
      <path
        data-name="icons8-ok (2)"
        d="M137 277a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6Zm2.568 4.968-3 3a.45.45 0 0 1-.637 0l-1.5-1.5a.45.45 0 1 1 .636-.636l1.183 1.182 2.682-2.682a.45.45 0 0 1 .637.636Z"
        fill="#5ac782"
      />
    </g>
  </svg>
    </>
  )
}
