export const Path = ["coa_type","ledger_name","name", "is_active"]

export const Heading = (t) => [
    { title: t("COA Type"), field: "coa_type" },
    { title: t("Ledger Reference"), field: "ledger_name" },
    { title: t("Name"), field: "name" },
    // { title: t("Number"), field: "number" },
    { title: t("Status"), field: "is_active", },
    { title: "", field: "icon" },
]

export const Type = [
    { type: ["text"], name: "coa_type" },
    { type: ["text"], name: "ledger_name" },
    { type: ["text"], name: "name" },
    // { type: ["number"], name: "number"},
    { type: ["status"], name: "is_active" },
    { type: ["more"], icon: "icon" },
]

export const defaultAddState = {
    formType: "add",
    name: "",
    ledger_reference:"",
    type: "",
    // number:"",
    // defaultNumber:"",
    is_active: true,
    error: {
        name: "",
        type: "",
        ledger_reference:"",
    }
}

export const defaultFilterData = { is_active: [true],type:[],ledger_reference_no:[] }