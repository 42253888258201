import * as React from "react"

const PhoneCallIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <defs>
      <clipPath id="a">
        <rect
          data-name="Rectangle 48034"
          width={32}
          height={32}
          rx={8}
          transform="translate(-.479 -.479)"
          fill="#30afce"
        />
      </clipPath>
    </defs>
    <g data-name="Group 5075" transform="translate(-27.521 -590.521)">
      <rect
        data-name="Rectangle 45496"
        width={32}
        height={32}
        rx={8}
        transform="translate(27.521 590.521)"
        fill="#30afce"
      />
      <g
        data-name="Mask Group 88"
        transform="translate(28 591)"
        opacity={0.65}
        clipPath="url(#a)"
      >
        <g data-name="Group 6857" fill="none" stroke="#57bfd8">
          <g data-name="Group 6849">
            <g data-name="Rectangle 47938">
              <path
                stroke="none"
                d="M-50.853-4.58-30.167.964l-5.542 20.687-20.687-5.543z"
              />
              <path d="m-50.5-3.967 19.721 5.284-5.284 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 47939">
              <path
                stroke="none"
                d="m-24.623-19.723 20.686 5.543L-9.48 6.506-30.166.963z"
              />
              <path d="m-24.27-19.11 19.72 5.283-5.284 19.72L-29.554.61z" />
            </g>
            <g data-name="Rectangle 47940">
              <path
                stroke="none"
                d="m-49.67-2.53 17.455 4.676-4.677 17.455-17.455-4.677z"
              />
              <path d="M-49.316-1.918-32.828 2.5l-4.418 16.488-16.488-4.418z" />
            </g>
            <g data-name="Rectangle 47941">
              <path
                stroke="none"
                d="m-23.44-17.674 17.454 4.677-4.677 17.454L-28.117-.22z"
              />
              <path d="m-23.087-17.062 16.488 4.418-4.418 16.489-16.488-4.418z" />
            </g>
            <g data-name="Rectangle 47942">
              <path
                stroke="none"
                d="m-48.25-.071 13.575 3.637-3.637 13.576-13.575-3.638z"
              />
              <path d="m-47.896.541 12.609 3.379-3.379 12.61-12.609-3.38z" />
            </g>
            <g data-name="Rectangle 47943">
              <path
                stroke="none"
                d="m-22.02-15.214 13.575 3.637-3.638 13.575-13.575-3.637z"
              />
              <path d="m-21.667-14.602 12.61 3.379-3.38 12.609-12.609-3.379z" />
            </g>
            <g data-name="Rectangle 47944">
              <path
                stroke="none"
                d="m-46.83 2.388 9.697 2.598-2.599 9.697-9.697-2.598z"
              />
              <path d="m-46.477 3 8.731 2.34-2.34 8.73-8.73-2.339z" />
            </g>
            <g data-name="Rectangle 47945">
              <path
                stroke="none"
                d="m-20.601-12.756 9.697 2.598-2.598 9.697-9.697-2.598z"
              />
              <path d="m-20.247-12.143 8.73 2.339-2.339 8.731-8.73-2.34z" />
            </g>
            <g data-name="Rectangle 47946">
              <path
                stroke="none"
                d="m-45.41 4.846 5.817 1.56-1.559 5.817-5.818-1.559z"
              />
              <path d="m-45.057 5.459 4.852 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47947">
              <path
                stroke="none"
                d="m-19.181-10.297 5.817 1.559-1.559 5.817-5.817-1.558z"
              />
              <path d="m-18.828-9.685 4.852 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47948">
              <path
                stroke="none"
                d="m-44.464 6.486 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m-44.11 7.098 2.265.607-.607 2.266-2.266-.607z" />
            </g>
            <g data-name="Rectangle 47949">
              <path
                stroke="none"
                d="m-18.235-8.658 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m-17.881-8.045 2.266.607-.608 2.266-2.266-.607z" />
            </g>
          </g>
          <g data-name="Group 6850">
            <g data-name="Rectangle 47950">
              <path
                stroke="none"
                d="m-33.285 25.85 20.686 5.542-5.542 20.686-20.687-5.543z"
              />
              <path d="m-32.931 26.462 19.72 5.284-5.284 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 47951">
              <path
                stroke="none"
                d="m-7.055 10.706 20.686 5.543-5.543 20.686-20.686-5.543z"
              />
              <path d="m-6.702 11.318 19.72 5.284-5.284 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 47952">
              <path
                stroke="none"
                d="m-32.102 27.898 17.455 4.677-4.677 17.454-17.455-4.677z"
              />
              <path d="m-31.748 28.51 16.488 4.419-4.418 16.488-16.488-4.418z" />
            </g>
            <g data-name="Rectangle 47953">
              <path
                stroke="none"
                d="m-5.872 12.755 17.454 4.677-4.677 17.454-17.454-4.677z"
              />
              <path d="m-5.519 13.367 16.488 4.418-4.418 16.488-16.488-4.418z" />
            </g>
            <g data-name="Rectangle 47954">
              <path
                stroke="none"
                d="m-30.682 30.358 13.575 3.637-3.637 13.575-13.575-3.637z"
              />
              <path d="m-30.328 30.97 12.609 3.379-3.379 12.609-12.609-3.379z" />
            </g>
            <g data-name="Rectangle 47955">
              <path
                stroke="none"
                d="m-4.452 15.214 13.575 3.638-3.638 13.575-13.575-3.638z"
              />
              <path d="m-4.099 15.827 12.61 3.378-3.38 12.61-12.609-3.38z" />
            </g>
            <g data-name="Rectangle 47956">
              <path
                stroke="none"
                d="m-29.262 32.816 9.697 2.599-2.599 9.697-9.697-2.599z"
              />
              <path d="m-28.909 33.429 8.731 2.34-2.34 8.73-8.73-2.34z" />
            </g>
            <g data-name="Rectangle 47957">
              <path
                stroke="none"
                d="m-3.033 17.673 9.697 2.598-2.598 9.697-9.697-2.598z"
              />
              <path d="m-2.68 18.285 8.732 2.34-2.34 8.73-8.73-2.339z" />
            </g>
            <g data-name="Rectangle 47958">
              <path
                stroke="none"
                d="m-27.843 35.275 5.818 1.559-1.559 5.818-5.818-1.56z"
              />
              <path d="m-27.49 35.887 4.853 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47959">
              <path
                stroke="none"
                d="m-1.613 20.131 5.817 1.56-1.559 5.817-5.817-1.559z"
              />
              <path d="m-1.26 20.744 4.852 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47960">
              <path
                stroke="none"
                d="m-26.896 36.914 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m-26.543 37.527 2.266.607-.607 2.266-2.266-.607z" />
            </g>
            <g data-name="Rectangle 47961">
              <path
                stroke="none"
                d="m-.667 21.77 3.232.867-.866 3.232-3.232-.866z"
              />
              <path d="m-.313 22.383 2.266.607-.608 2.266-2.266-.607z" />
            </g>
          </g>
          <g data-name="Group 6851">
            <g data-name="Rectangle 47962">
              <path
                stroke="none"
                d="m-29.112 2.961 20.687 5.543-5.543 20.686-20.687-5.543z"
              />
              <path d="m-28.758 3.574 19.72 5.284-5.284 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 47963">
              <path
                stroke="none"
                d="M-2.882-12.182 17.804-6.64 12.26 14.047-8.425 8.504z"
              />
              <path d="m-2.529-11.57 19.72 5.284-5.284 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 47964">
              <path
                stroke="none"
                d="m-27.929 5.01 17.455 4.677-4.677 17.454-17.455-4.676z"
              />
              <path d="m-27.575 5.623 16.488 4.418-4.418 16.488-16.488-4.418z" />
            </g>
            <g data-name="Rectangle 47965">
              <path
                stroke="none"
                d="m-1.7-10.133 17.455 4.677-4.677 17.454L-6.376 7.32z"
              />
              <path d="m-1.346-9.52 16.489 4.417-4.418 16.488-16.489-4.418z" />
            </g>
            <g data-name="Rectangle 47966">
              <path
                stroke="none"
                d="m-26.509 7.47 13.575 3.637-3.637 13.575-13.575-3.637z"
              />
              <path d="m-26.155 8.082 12.61 3.379-3.38 12.609-12.609-3.379z" />
            </g>
            <g data-name="Rectangle 47967">
              <path
                stroke="none"
                d="m-.28-7.674 13.576 3.638L9.658 9.539-3.917 5.9z"
              />
              <path d="m.074-7.061 12.61 3.378-3.38 12.61-12.608-3.38z" />
            </g>
            <g data-name="Rectangle 47968">
              <path
                stroke="none"
                d="m-25.09 9.928 9.698 2.599-2.599 9.697-9.696-2.599z"
              />
              <path d="m-24.736 10.54 8.731 2.34-2.34 8.731-8.73-2.34z" />
            </g>
            <g data-name="Rectangle 47969">
              <path
                stroke="none"
                d="m1.14-5.215 9.697 2.598L8.24 7.08l-9.697-2.598z"
              />
              <path d="m1.494-4.603 8.73 2.34-2.339 8.73-8.73-2.339z" />
            </g>
            <g data-name="Rectangle 47970">
              <path
                stroke="none"
                d="m-23.67 12.387 5.818 1.559-1.559 5.818-5.818-1.56z"
              />
              <path d="m-23.316 13 4.852 1.3-1.3 4.851-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47971">
              <path
                stroke="none"
                d="m2.56-2.756 5.817 1.558L6.82 4.62 1 3.061z"
              />
              <path d="m2.913-2.144 4.852 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47972">
              <path
                stroke="none"
                d="m-22.723 14.026 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m-22.37 14.639 2.266.607-.607 2.266-2.266-.607z" />
            </g>
            <g data-name="Rectangle 47973">
              <path
                stroke="none"
                d="m3.506-1.117 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m3.86-.505 2.266.607-.607 2.267-2.266-.608z" />
            </g>
          </g>
          <g data-name="Group 6852">
            <g data-name="Rectangle 47974">
              <path
                stroke="none"
                d="m-11.544 33.389 20.686 5.543L3.6 59.618l-20.686-5.543z"
              />
              <path d="m-11.19 34.001 19.72 5.284-5.284 19.72-19.72-5.283z" />
            </g>
            <g data-name="Rectangle 47975">
              <path
                stroke="none"
                d="m14.685 18.246 20.686 5.542-5.542 20.687-20.687-5.543z"
              />
              <path d="m15.039 18.858 19.72 5.284-5.284 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 47976">
              <path
                stroke="none"
                d="m-10.361 35.438 17.454 4.677-4.677 17.454-17.454-4.677z"
              />
              <path d="m-10.008 36.05 16.489 4.418-4.418 16.489-16.489-4.418z" />
            </g>
            <g data-name="Rectangle 47977">
              <path
                stroke="none"
                d="m15.868 20.295 17.454 4.676-4.676 17.455-17.455-4.677z"
              />
              <path d="m16.222 20.907 16.488 4.418-4.418 16.488-16.488-4.418z" />
            </g>
            <g data-name="Rectangle 47978">
              <path
                stroke="none"
                d="m-8.941 37.898 13.575 3.637L.997 55.11l-13.576-3.637z"
              />
              <path d="m-8.588 38.51 12.61 3.379L.643 54.498l-12.61-3.379z" />
            </g>
            <g data-name="Rectangle 47979">
              <path
                stroke="none"
                d="m17.288 22.754 13.575 3.637-3.637 13.576-13.575-3.638z"
              />
              <path d="m17.642 23.366 12.609 3.379-3.379 12.61-12.609-3.38z" />
            </g>
            <g data-name="Rectangle 47980">
              <path
                stroke="none"
                d="m-7.522 40.356 9.697 2.598-2.598 9.697-9.697-2.598z"
              />
              <path d="m-7.168 40.969 8.73 2.339-2.339 8.731-8.73-2.34z" />
            </g>
            <g data-name="Rectangle 47981">
              <path
                stroke="none"
                d="m18.708 25.213 9.697 2.598-2.599 9.697-9.697-2.598z"
              />
              <path d="m19.061 25.825 8.731 2.34-2.34 8.73-8.73-2.339z" />
            </g>
            <g data-name="Rectangle 47982">
              <path
                stroke="none"
                d="m-6.102 42.815 5.818 1.559-1.56 5.817-5.817-1.558z"
              />
              <path d="m-5.749 43.427 4.852 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47983">
              <path
                stroke="none"
                d="m20.127 27.671 5.818 1.56-1.559 5.817-5.818-1.559z"
              />
              <path d="m20.48 28.284 4.853 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47984">
              <path
                stroke="none"
                d="m-5.156 44.454 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m-4.802 45.067 2.266.607-.607 2.266-2.266-.607z" />
            </g>
            <g data-name="Rectangle 47985">
              <path
                stroke="none"
                d="m21.074 29.31 3.232.867-.866 3.232-3.232-.866z"
              />
              <path d="m21.427 29.923 2.266.607-.607 2.266-2.266-.607z" />
            </g>
          </g>
          <g data-name="Group 6853">
            <g data-name="Rectangle 47986">
              <path
                stroke="none"
                d="m1.606-34.866 20.686 5.542L16.75-8.637-3.937-14.18z"
              />
              <path d="m1.96-34.254 19.72 5.284-5.284 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 47987">
              <path
                stroke="none"
                d="m27.835-50.01 20.686 5.543-5.542 20.686-20.687-5.543z"
              />
              <path d="m28.189-49.398 19.72 5.284-5.284 19.72-19.72-5.283z" />
            </g>
            <g data-name="Rectangle 47988">
              <path
                stroke="none"
                d="m2.789-32.817 17.454 4.676-4.677 17.455-17.454-4.677z"
              />
              <path d="m3.142-32.205 16.489 4.418L15.213-11.3l-16.489-4.418z" />
            </g>
            <g data-name="Rectangle 47989">
              <path
                stroke="none"
                d="m29.018-47.961 17.454 4.677-4.676 17.454-17.455-4.677z"
              />
              <path d="M29.372-47.349 45.86-42.93l-4.418 16.489-16.488-4.418z" />
            </g>
            <g data-name="Rectangle 47990">
              <path
                stroke="none"
                d="m4.209-30.358 13.575 3.637-3.637 13.576L.57-16.783z"
              />
              <path d="m4.562-29.746 12.61 3.379-3.379 12.61-12.61-3.38z" />
            </g>
            <g data-name="Rectangle 47991">
              <path
                stroke="none"
                d="m30.438-45.501 13.575 3.637-3.637 13.575L26.8-31.926z"
              />
              <path d="M30.792-44.89 43.4-41.51l-3.379 12.609-12.609-3.379z" />
            </g>
            <g data-name="Rectangle 47992">
              <path
                stroke="none"
                d="m5.628-27.9 9.697 2.599-2.598 9.697-9.697-2.598z"
              />
              <path d="m5.982-27.287 8.73 2.34-2.339 8.73-8.73-2.339z" />
            </g>
            <g data-name="Rectangle 47993">
              <path
                stroke="none"
                d="m31.858-43.043 9.697 2.598-2.599 9.697-9.697-2.598z"
              />
              <path d="m32.211-42.43 8.731 2.339-2.34 8.731-8.73-2.34z" />
            </g>
            <g data-name="Rectangle 47994">
              <path
                stroke="none"
                d="m7.048-25.44 5.818 1.558-1.56 5.818-5.817-1.559z"
              />
              <path d="m7.401-24.828 4.852 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47995">
              <path
                stroke="none"
                d="m33.277-40.584 5.818 1.559-1.559 5.817-5.818-1.558z"
              />
              <path d="m33.63-39.972 4.853 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 47996">
              <path
                stroke="none"
                d="m7.994-23.801 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m8.348-23.189 2.266.607-.607 2.266-2.266-.607z" />
            </g>
            <g data-name="Rectangle 47997">
              <path
                stroke="none"
                d="m34.224-38.945 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m34.577-38.332 2.266.607-.607 2.266-2.266-.607z" />
            </g>
          </g>
          <g data-name="Group 6854">
            <g data-name="Rectangle 47998">
              <path
                stroke="none"
                d="M19.174-4.438 39.86 1.105l-5.543 20.686-20.686-5.543z"
              />
              <path d="m19.527-3.825 19.72 5.284-5.283 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 47999">
              <path
                stroke="none"
                d="m45.403-19.581 20.686 5.543-5.542 20.686L39.86 1.105z"
              />
              <path d="m45.757-18.969 19.72 5.284-5.284 19.72L40.473.751z" />
            </g>
            <g data-name="Rectangle 48000">
              <path
                stroke="none"
                d="M20.357-2.389 37.81 2.288l-4.677 17.454-17.454-4.677z"
              />
              <path d="M20.71-1.776 37.2 2.642 32.78 19.13l-16.489-4.418z" />
            </g>
            <g data-name="Rectangle 48001">
              <path
                stroke="none"
                d="m46.586-17.532 17.454 4.677-4.676 17.454L41.909-.078z"
              />
              <path d="m46.94-16.92 16.488 4.418L59.01 3.986 42.522-.432z" />
            </g>
            <g data-name="Rectangle 48002">
              <path
                stroke="none"
                d="m21.777.07 13.575 3.638-3.637 13.575-13.576-3.637z"
              />
              <path d="m22.13.683 12.61 3.379L31.36 16.67l-12.61-3.379z" />
            </g>
            <g data-name="Rectangle 48003">
              <path
                stroke="none"
                d="m48.006-15.073 13.575 3.638L57.944 2.14 44.369-1.498z"
              />
              <path d="m48.36-14.46 12.609 3.378-3.379 12.61-12.609-3.38z" />
            </g>
            <g data-name="Rectangle 48004">
              <path
                stroke="none"
                d="m23.196 2.53 9.697 2.598-2.598 9.697-9.697-2.599z"
              />
              <path d="m23.55 3.142 8.73 2.34-2.339 8.73-8.73-2.34z" />
            </g>
            <g data-name="Rectangle 48005">
              <path
                stroke="none"
                d="m49.426-12.614 9.697 2.598-2.599 9.697-9.697-2.598z"
              />
              <path d="m49.78-12.002 8.73 2.34-2.34 8.73-8.73-2.339z" />
            </g>
            <g data-name="Rectangle 48006">
              <path
                stroke="none"
                d="m24.616 4.988 5.818 1.559-1.56 5.818-5.817-1.56z"
              />
              <path d="m24.97 5.6 4.851 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 48007">
              <path
                stroke="none"
                d="m50.845-10.156 5.818 1.56-1.559 5.817-5.818-1.559z"
              />
              <path d="m51.199-9.543 4.852 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 48008">
              <path
                stroke="none"
                d="m25.562 6.627 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m25.916 7.24 2.266.607-.607 2.266-2.266-.607z" />
            </g>
            <g data-name="Rectangle 48009">
              <path
                stroke="none"
                d="m51.792-8.516 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m52.145-7.904 2.266.607-.607 2.266-2.266-.607z" />
            </g>
          </g>
          <g data-name="Group 6855">
            <g data-name="Rectangle 48010">
              <path
                stroke="none"
                d="m23.347-27.326 20.686 5.543L38.49-1.097 17.804-6.64z"
              />
              <path d="m23.7-26.713 19.72 5.284-5.283 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 48011">
              <path
                stroke="none"
                d="m49.576-42.47 20.687 5.544L64.72-16.24l-20.687-5.543z"
              />
              <path d="m49.93-41.857 19.72 5.284-5.284 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 48012">
              <path
                stroke="none"
                d="M24.53-25.277 41.984-20.6 37.307-3.146 19.853-7.822z"
              />
              <path d="m24.884-24.664 16.488 4.418-4.418 16.488-16.489-4.418z" />
            </g>
            <g data-name="Rectangle 48013">
              <path
                stroke="none"
                d="m50.76-40.42 17.454 4.677-4.677 17.454-17.455-4.677z"
              />
              <path d="M51.113-39.808 67.6-35.39l-4.418 16.488-16.488-4.418z" />
            </g>
            <g data-name="Rectangle 48014">
              <path
                stroke="none"
                d="m25.95-22.817 13.575 3.637-3.637 13.575-13.575-3.637z"
              />
              <path d="m26.304-22.205 12.609 3.379-3.379 12.609-12.61-3.379z" />
            </g>
            <g data-name="Rectangle 48015">
              <path
                stroke="none"
                d="m52.18-37.96 13.574 3.637-3.637 13.575-13.575-3.638z"
              />
              <path d="m52.533-37.348 12.609 3.378-3.379 12.61-12.609-3.38z" />
            </g>
            <g data-name="Rectangle 48016">
              <path
                stroke="none"
                d="m27.37-20.359 9.696 2.599-2.598 9.697-9.697-2.599z"
              />
              <path d="m27.723-19.746 8.731 2.34-2.34 8.73-8.73-2.34z" />
            </g>
            <g data-name="Rectangle 48017">
              <path
                stroke="none"
                d="m53.599-35.502 9.697 2.598-2.599 9.697L51-25.805z"
              />
              <path d="m53.952-34.89 8.731 2.34-2.34 8.73-8.73-2.339z" />
            </g>
            <g data-name="Rectangle 48018">
              <path
                stroke="none"
                d="m28.789-17.9 5.818 1.559-1.56 5.818-5.817-1.56z"
              />
              <path d="m29.143-17.288 4.851 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 48019">
              <path
                stroke="none"
                d="m55.018-33.043 5.818 1.558-1.559 5.818-5.818-1.559z"
              />
              <path d="m55.372-32.431 4.852 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 48020">
              <path
                stroke="none"
                d="m29.735-16.26 3.232.865-.866 3.232-3.232-.866z"
              />
              <path d="m30.089-15.648 2.266.607-.607 2.266-2.266-.607z" />
            </g>
            <g data-name="Rectangle 48021">
              <path
                stroke="none"
                d="m55.965-31.404 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m56.318-30.792 2.266.607-.607 2.267-2.266-.608z" />
            </g>
          </g>
          <g data-name="Group 6856">
            <g data-name="Rectangle 48022">
              <path
                stroke="none"
                d="m40.914 3.102 20.687 5.543-5.543 20.686-20.686-5.543z"
              />
              <path d="m41.268 3.714 19.72 5.284-5.284 19.72-19.72-5.283z" />
            </g>
            <g data-name="Rectangle 48023">
              <path
                stroke="none"
                d="M67.144-12.041 87.83-6.5l-5.543 20.687-20.686-5.543z"
              />
              <path d="m67.497-11.43 19.72 5.285-5.283 19.72-19.72-5.284z" />
            </g>
            <g data-name="Rectangle 48024">
              <path
                stroke="none"
                d="m42.097 5.151 17.455 4.677-4.677 17.454-17.454-4.677z"
              />
              <path d="m42.451 5.763 16.488 4.418-4.418 16.489-16.488-4.418z" />
            </g>
            <g data-name="Rectangle 48025">
              <path
                stroke="none"
                d="M68.327-9.992 85.78-5.316 81.104 12.14 63.65 7.462z"
              />
              <path d="M68.68-9.38 85.17-4.962 80.75 11.526 64.262 7.108z" />
            </g>
            <g data-name="Rectangle 48026">
              <path
                stroke="none"
                d="m43.517 7.61 13.576 3.638-3.638 13.575-13.575-3.637z"
              />
              <path d="m43.871 8.223 12.61 3.379L53.1 24.21l-12.609-3.379z" />
            </g>
            <g data-name="Rectangle 48027">
              <path
                stroke="none"
                d="m69.747-7.533 13.575 3.637L79.684 9.68 66.11 6.042z"
              />
              <path d="m70.1-6.92 12.61 3.378-3.38 12.61-12.608-3.38z" />
            </g>
            <g data-name="Rectangle 48028">
              <path
                stroke="none"
                d="m44.937 10.07 9.697 2.597-2.598 9.697-9.697-2.598z"
              />
              <path d="m45.29 10.682 8.732 2.339-2.34 8.731-8.731-2.34z" />
            </g>
            <g data-name="Rectangle 48029">
              <path
                stroke="none"
                d="m71.166-5.074 9.697 2.598-2.598 9.697-9.697-2.598z"
              />
              <path d="m71.52-4.462 8.73 2.34-2.339 8.73-8.73-2.339z" />
            </g>
            <g data-name="Rectangle 48030">
              <path
                stroke="none"
                d="m46.356 12.528 5.818 1.559-1.559 5.817-5.817-1.558z"
              />
              <path d="m46.71 13.14 4.852 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 48031">
              <path
                stroke="none"
                d="m72.586-2.616 5.818 1.56-1.56 5.817-5.817-1.559z"
              />
              <path d="m72.94-2.003 4.851 1.3-1.3 4.852-4.852-1.3z" />
            </g>
            <g data-name="Rectangle 48032">
              <path
                stroke="none"
                d="m47.303 14.167 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m47.657 14.78 2.266.607-.608 2.266-2.266-.607z" />
            </g>
            <g data-name="Rectangle 48033">
              <path
                stroke="none"
                d="m73.532-.976 3.232.866-.866 3.232-3.232-.866z"
              />
              <path d="m73.886-.364 2.266.607-.607 2.266-2.266-.607z" />
            </g>
          </g>
        </g>
      </g>
      <path
        data-name="icons8-phone (4)"
        d="m42.75 600.1.635 1.5a1.713 1.713 0 0 1-.38 1.9l-1.184 1.124a.56.56 0 0 0-.173.493 6.033 6.033 0 0 0 .762 2.057 6.478 6.478 0 0 0 1.434 1.722.565.565 0 0 0 .541.1l1.385-.462a1.5 1.5 0 0 1 1.722.606l.911 1.338a1.716 1.716 0 0 1-.191 2.155l-.6.607a2.232 2.232 0 0 1-2.262.572q-2.815-.878-5.176-5.213t-1.668-7.368a2.41 2.41 0 0 1 1.588-1.777l.8-.254a1.525 1.525 0 0 1 1.856.9Z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default PhoneCallIcon
