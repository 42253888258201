import { Grid, Box, IconButton, Badge } from "@mui/material";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { SearchFilter, TableWithPagination, FilterGenerator } from "../../components";
import { Aggreementheading, Aggreementpath } from "../../utils/quotations";
import { quotationsStyles } from "./styles";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import {
  enumSelect,
  enum_types,
} from "../../utils";

const AggreementsTable = ({
  handleSearch = () => false,
  searchText = "",
  revenue = {},
  handleIcon = () => false,
  list = [],
  handlePagination = () => false,
  handleChangeLimit = () => false,
  page = "",
  limit = "",
  t = () => false,
  permission = {},
  selectedCompany,
  filterData = {},
  getAgreement = () => false,
  enable_double_click=true,
  enable_single_click=true
}) => {
  const classes = quotationsStyles();
  const [drawer, setDrawer] = useState(false);

  const [enumValue, setEnumValue] = useState({
    type: [],
  });
  const drawerclose = () => {
    setDrawer(false);
  };
  const onApplyFilter = (value) => {
    getAgreement(value);
  };
  // enum
  const getEnum = async () => {
    const result = await enumSelect([enum_types.agreement_status]);
    setEnumValue({
      type: result?.agreement_status,
    });
  };

  React.useEffect(() => {
    getEnum()
  }, [])


  return (
    <div >
      <Grid container justifyContent="space-between">
        <Grid item xs={4}>
          {/*search field*/}
          <SearchFilter value={searchText} placeholder={t("seacrhAgreement")} handleChange={(value) => handleSearch(value)} height={"40px"} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}} />
        </Grid>
        <Grid item xs={8} justifyContent={"end"} display={"flex"}>
          <Box style={{ float: "right" }}>
            <IconButton
              size="small"
              className={classes.img}
              onClick={() => setDrawer(true)}
            >
              <Badge variant="dot" color="primary"
                invisible={
                  !Boolean(
                    (Boolean(filterData?.account?.length > 0) ||
                      Boolean(filterData.status?.length > 0))
                  )
                }>
                <img src="/images/filter.svg" alt="filter" />
              </Badge>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      {/*table container*/}
      <TableWithPagination
        heading={Aggreementheading(t)}
        rows={list?.list}
        path={Aggreementpath}
        showpagination={true}
        count="2"
        showpdfbtn={false}
        showexcelbtn={false}
        showSearch={false}
        tableType="no-side"
        handleIcon={handleIcon}
        onClick={() => console.log("")}
        dataType={[
          { type: ["info"], icon: "icon" },
          { type: ["text"], name: "agreement_no" },
          { type: ["date"], name: "generate_date" },
          { type: ["text"], name: "account_no" },
          { type: ["long_text"], name: "account_name" },
          { type: ["text"], name: "agreement_type" },
          { type: ["date"], name: "start_date" },
          { type: ["date"], name: "end_date" },
          { type: ["q_status"], name: "status" },
        ]}
        handlePagination={handlePagination}
        handleChangeLimit={handleChangeLimit}
        totalRowsCount={list?.count}
        page={page}
        limit={limit}
        height={`calc(100vh - 300px)`}
        view={permission?.read}
        edit={permission?.update}
        delete={permission?.delete} 
        enable_double_click={enable_double_click}
        enable_single_click={enable_single_click}
        />

      {drawer && (
        <FilterGenerator
          open={drawer}
          onClose={drawerclose}
          components={[
            {
              component: "select",
              value: filterData?.account,
              options: [],
              isMulti: true,
              label: t("Customer-AccountID"),
              state_name: "account",
              loadOptions: (search, array, handleLoading) =>
                loadOptionsApis(
                  "agreement/get_agreement_account",
                  {
                    company_id: selectedCompany?.value

                  },
                  search,
                  array,
                  handleLoading,
                  "data",
                  { label: t("label"), value: "value", agreement: true },)
              ,
              debounceTimeout: 800,
              isPaginate: true,
              placeholder: t("Select Account ID")
            },
            {
              component: "select",
              value: filterData?.status,
              options: enumValue?.type?.filter((x) => x?.value !== "Offboarding (In Moveout Stage)"),
              isMulti: true,
              label: t("Status"),
              state_name: "status",
              placeholder: t("Select Status")
            },
          ]}
          onApply={(value) => onApplyFilter(value)}
        />
      )}
    </div>
  )
}
export default withNamespaces("agreement")(AggreementsTable);