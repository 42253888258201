import React from 'react'
import { SearchFilter } from '../../../components'
import { Avatar, Divider, Grid, Stack, Typography } from '@mui/material'
import { PropertyUnitSelectStyle } from './style'
import NoImageUnitProperty from '../utlis/noImagePropertyUnit'
import InfiniteScroll from 'react-infinite-scroll-component'

export const PropertyUnitSelect = ({
    list = [],
    handleLoadMorePropertyUnit = () => false,
    onselect = () => false,
    value = [],
    search = () => false,
    searchText = "",
    error = false,
    errorMsg = "",
    isReadOnly = false,
    t = () => false
}) => {
    const classes = PropertyUnitSelectStyle()

    return (
        <>
            <SearchFilter
                value={searchText}
                handleChange={(e) => search(e)}
                placeholder={t("Search Property")}
                isReadonly={isReadOnly}
            />
            <Typography className={classes.header} mt={"10px"}>{t("Associated Property")}</Typography>
            {
                error && <Typography variant={"caption"} color={"error"}>{errorMsg}</Typography>
            }
            <Grid container mt={1} sx={{ display: "block !important" }}>
                <InfiniteScroll
                    dataLength={list?.length}
                    next={handleLoadMorePropertyUnit}
                    hasMore={true}
                    height={"calc(100vh - 240px)"}
                >
                    {
                        list?.map((e) => {
                            return (
                                <Grid item md={12} sm={12} lg={12} mb={2}>
                                    <Stack direction={"row"} className={value?.filter((i) => i?.property_id === e?.property_id)?.length > 0 ? classes.selectedBox : classes.notSelectedBox} p={"12px"} onClick={() => onselect(e)}>
                                        {
                                            e?.asset_url ?
                                                <Avatar src={e?.asset_url} sx={{ height: 38, width: 38 }}></Avatar> :
                                                <NoImageUnitProperty />
                                        }
                                        <Stack sx={{ marginInlineStart: "8px" }}>
                                            <Stack direction={"row"}
                                                divider={<Divider orientation="vertical" flexItem className={classes.stackDivider} />}>
                                                <Typography className={classes.title} sx={{ marginInlineEnd: "4px", width: "200px" }} noWrap>{e?.property_name ?? "-"}</Typography>
                                                <Typography className={classes.title} sx={{ marginInlineStart: "4px" }}>{e?.property_no ?? "-"}</Typography>
                                            </Stack>
                                            <Typography className={classes.subtitle}>{e?.city ?? "-"}, {e?.state ?? "-"}</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            )
                        })
                    }
                </InfiniteScroll>
            </Grid>
        </>
    )
}