import { Badge, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import FilterIMG from "../../assets/filter";
import { BubbleTab, ConfirmDialog, FilterGenerator, LineIndicatorTab, SearchFilter, Subheader, SuccessDialog, TableWithPagination, UseDebounce, VerticalDivider } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes } from "../../router/routes";
import { accessCheckRender, AlertProps, enumSelect, enum_types, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AddFacilitiesDialog, AddPropertyDialog, AddUnitDialog } from "./components";
import { PublicListingStyles } from "./style";
import { constructAdditionalPayload, constructFacilityDropdownData, ConstructFilterComponent, constructPropertyDropdownData, constructResponseGetData, constructUnitDropdownData, getApiEndpoint, isFilterApplied } from "./utils/common";
import { revoked_tabs_data, revoked_tabs_path, listing_tab_data, listing_tab_paths } from "./utils/constant";
import { facilityHeading, facilityRevokedHeading, facility_revoked_table_path, facility_revoked_table_type, facility_table_path, facility_table_type, privateHeading, private_table_path, private_table_type, propertyHeading, property_table_path, property_table_type, publicHeading, public_table_path, public_table_type, unitRevokedHeading, unit_revoked_table_path, unit_revoked_table_type } from "./utils/tableUtils";

const PublicListing = (props) => {
    const { t, loading, handleLoading } = props;
    const [is_table_loading, set_is_table_loading] = React.useState(true);
    const initialMainTabValue = listing_tab_data?.property_listing
    const initialRevokedSubTabValue = revoked_tabs_data?.unit_revoked_list
    const history = useHistory()
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext)
    const classes = PublicListingStyles()
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})
    const [mainTabValue, setMainTabValue] = React.useState(initialMainTabValue)
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [revokedSubTabValue, setRevokedSubTabValue] = React.useState(initialRevokedSubTabValue)
    const [tableData, setTableData] = React.useState({
        row: [],
        row_count: 0
    })
    // eslint-disable-next-line
    const [isButtonDisable, setIsButtonDisable] = React.useState(false)
    const [isAddPropertyOpen, setIsAddPropertyOpen] = React.useState(false)
    const [isAddUnitOpen, setIsAddUnitOpen] = React.useState(false)
    const [isAddFacilityOpen, setIsAddFacilityOpen] = React.useState(false)
    const [isAddPropertySearchDropdownOpen, setIsAddPropertySearchDropdownOpen] = React.useState(false)
    const [isAddUnitSearchDropdownOpen, setIsAddUnitSearchDropdownOpen] = React.useState(false)
    const [isAddFacilitySearchDropdownOpen, setIsAddFacilitySearchDropdownOpen] = React.useState(false)
    const [propertyDropdownOffset, setPropertyDropdownOffset] = React.useState(0)
    const [unitDropdownOffset, setUnitDropdownOffset] = React.useState(0)
    const [facilityDropdownOffset, setFacilityDropdownOffset] = React.useState(0)
    const dropdownLimit = 20
    const [propertyDropdownSearchText, setPropertyDropdownSearchText] = React.useState("")
    const [unitDropdownSearchText, setUnitDropdownSearchText] = React.useState("")
    const [facilityDropdownSearchText, setFacilityDropdownSearchText] = React.useState("")
    const [propertyDropdownList, setPropertyDropdownList] = React.useState([])
    const [unitDropdownList, setUnitDropdownList] = React.useState([])
    const [facilityDropdownList, setFacilityDropdownList] = React.useState([])
    const [isPropertyDropdownLoading, setIsPropertyDropdownLoading] = React.useState(true)
    const [isUnitDropdownLoading, setIsUnitDropdownLoading] = React.useState(true)
    const [isFacilityDropdownLoading, setIsFacilityDropdownLoading] = React.useState(true)
    const [propertyUpdateList, setPropertyUpdateList] = React.useState([])
    const [unitUpdateList, setUnitUpdateList] = React.useState([])
    const [facilityUpdateList, setFacilityUpdateList] = React.useState([])
    const [selectedProperty, setSelectedProperty] = React.useState("")
    const [isRevokeConfirmDialogOpen, setIsRevokeConfirmDialogOpen] = React.useState(false)
    const [confirmRevokeUnitData, setConfirmRevokeUnitData] = React.useState({})
    const [isRevokeSuccessDialogOpen, setIsRevokeSuccessDialogOpen] = React.useState(false)
    const [drawer, setDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({
        property_type: [],
        unit_type: [],
        revenue_type: [],
    })
    const [filterOption, setFilterOption] = React.useState({
        property_type: [],
        unit_type: [],
        revenue_type: [],
    })

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getFiterOptions()
                getData(0, limit, searchText, mainTabValue, revokedSubTabValue, filterData)
            }
        }
        // eslint-disable-next-line
    }, [auth])

    //Main Tab handle change
    const handleMainTabChange = (v) => {
        if (mainTabValue?.value !== v?.value) {
            setMainTabValue(v)
            getData(0, limit, searchText, v, revokedSubTabValue, filterData)
        }
    }

    //Revoked Sub Tab handle change
    const handleRevokedSubTabChange = (v) => {
        if (revokedSubTabValue?.value !== v?.value) {
            setRevokedSubTabValue(v)
            getData(0, limit, searchText, mainTabValue, v, filterData)
        }
    }

    // Function for search in search component
    const handleSearch = (v) => {
        setSearchText(v)
        debounce(() => searchTableFunction(v), 800)
    }

    // Function to search data in list
    const searchTableFunction = (v) => {
        getData(0, limit, v, mainTabValue, revokedSubTabValue, filterData)
    }

    // Function to handle heading for table
    const handleTableHeading = (selected_main_tab, selected_revoked_sub_tab) => {
        let temp_heading = []
        switch (selected_main_tab?.value) {
            case listing_tab_data.property_listing.value:
                temp_heading = propertyHeading(t)
                break;
            case listing_tab_data.public_lisiting.value:
                temp_heading = publicHeading(t)
                break;
            case listing_tab_data.private_listing.value:
                temp_heading = privateHeading(t)
                break;
            case listing_tab_data.public_facility_listing.value:
                temp_heading = facilityHeading(t)
                break;
            case listing_tab_data.private_facility_listing.value:
                temp_heading = facilityHeading(t)
                break;
            case listing_tab_data.revoked.value:
                switch (selected_revoked_sub_tab?.value) {
                    case revoked_tabs_data.unit_revoked_list.value:
                        temp_heading = unitRevokedHeading(t)
                        break;
                    case revoked_tabs_data.facility_revoked_list.value:
                        temp_heading = facilityRevokedHeading(t)
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return temp_heading
    }

    // Function to handle heading for table
    const handleTablePath = (selected_main_tab, selected_revoked_sub_tab) => {
        let temp_path = []
        switch (selected_main_tab?.value) {
            case listing_tab_data.property_listing.value:
                temp_path = property_table_path
                break;
            case listing_tab_data.public_lisiting.value:
                temp_path = public_table_path
                break;
            case listing_tab_data.private_listing.value:
                temp_path = private_table_path
                break;
            case listing_tab_data.public_facility_listing.value:
                temp_path = facility_table_path
                break;            
            case listing_tab_data.private_facility_listing.value:
                temp_path = facility_table_path
                break;
            case listing_tab_data.revoked.value:
                switch (selected_revoked_sub_tab?.value) {
                    case revoked_tabs_data.unit_revoked_list.value:
                        temp_path = unit_revoked_table_path
                        break;
                    case revoked_tabs_data.facility_revoked_list.value:
                        temp_path = facility_revoked_table_path
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return temp_path
    }

    // Function to handle heading for table
    const handleTableType = (selected_main_tab, selected_revoked_sub_tab) => {
        let temp_type = []
        switch (selected_main_tab?.value) {
            case listing_tab_data.property_listing.value:
                temp_type = property_table_type
                break;
            case listing_tab_data.public_lisiting.value:
                temp_type = public_table_type
                break;
            case listing_tab_data.private_listing.value:
                temp_type = private_table_type
                break;
            case listing_tab_data.public_facility_listing.value:
                temp_type = facility_table_type
                break;
            case listing_tab_data.private_facility_listing.value:
                temp_type = facility_table_type
                break;
            case listing_tab_data.revoked.value:
                switch (selected_revoked_sub_tab?.value) {
                    case revoked_tabs_data.unit_revoked_list.value:
                        temp_type = unit_revoked_table_type
                        break;
                    case revoked_tabs_data.facility_revoked_list.value:
                        temp_type = facility_revoked_table_type
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return temp_type
    }

    //Funtion to get table data
    const getData = (offset = 0, limit = 10, search = "", selected_main_tab, selected_revoked_sub_tab, filter) => {
        set_is_table_loading(true);
        let temp_payload = { offset, limit, search }

        let payload = constructAdditionalPayload(selected_main_tab, selected_revoked_sub_tab, temp_payload, filter)

        let api_endpoint = getApiEndpoint(selected_main_tab, selected_revoked_sub_tab)
        if (api_endpoint?.length > 0) {
            NetworkCall(
                `${api_endpoint === "/listing_mapping/facility_listing" ? config.api_url : config.public_listing_api_url}${api_endpoint}`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then((res) => {
                const temp_table_data = constructResponseGetData(res?.data?.response, selected_main_tab, selected_revoked_sub_tab, t)
                setTableData(temp_table_data)
                handleLoading(false)
                set_is_table_loading(false);
            }).catch((err) => {
                console.log(err)
                alert.setSnack({
                    ...alert, open: true, msg: t("Some Thing Went Wrong"),
                    severity: AlertProps.severity.error
                })
                set_is_table_loading(false);
            })
        } else {
            setTableData({ row: [], row_count: 0 })
            handleLoading(false)
            set_is_table_loading(false);
        }
    }

    //Function to handle click on table
    const handleIcon = (t, d) => {
        switch (t) {
            case "unit_revoke":
                setIsRevokeConfirmDialogOpen(true)
                setConfirmRevokeUnitData(d?.data)
                break;
            case "facility_revoke":
                setIsRevokeConfirmDialogOpen(true)
                setConfirmRevokeUnitData(d?.data)
                break;
            default:
                break;
        }
    }

    // Function to handle pagination in table
    const handleTablePagination = (v) => {
        setPage(v)
        let offset = (v - 1) * limit
        getData(offset, limit, searchText, mainTabValue, revokedSubTabValue, filterData)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (v) => {
        setLimit(v)
        setPage(1)
        getData(0, v, searchText, mainTabValue, revokedSubTabValue, filterData)
    }

    //Function to handle Add Button
    const handleAdd = (selected_main_tab) => {
        switch (selected_main_tab?.value) {
            case listing_tab_data.property_listing.value:
                setIsAddPropertyOpen(true)
                break;
            case listing_tab_data.public_lisiting.value:
                setIsAddUnitOpen(true)
                break;
            case listing_tab_data.private_listing.value:
                setIsAddUnitOpen(true)
                break;
            case listing_tab_data.public_facility_listing.value:
                setIsAddFacilityOpen(true)
                break;
            case listing_tab_data.private_facility_listing.value:
                setIsAddFacilityOpen(true)
                break;
            default:
                break;
        }
    }

    //Function to load properties for dropdown initially
    const propertyDropdownOnClick = () => {
        let exclude_properties = propertyUpdateList?.map(i => i?.data?.id)
        setPropertyDropdownOffset(0)
        getPropertyDropdownList(propertyDropdownSearchText, 0, exclude_properties, false)
    }

    //Function to load properties for dropdown initially
    const unitDropdownOnClick = () => {
        let exclude_units = unitUpdateList?.map(i => i?.data?.id)
        setUnitDropdownOffset(0)
        getUnitDropdownList(unitDropdownSearchText, 0, exclude_units, false)
    }
    //Function to load facility for dropdown initially
    const facilityDropdownOnClick = () => {
        let exclude_facilities = facilityUpdateList?.map(i => i?.data?.id)

        setFacilityDropdownOffset(0)
        getFaciltyDropdownList(facilityDropdownSearchText, 0, exclude_facilities, false)
    }

    //Function for property infinity scroll next
    const propertyDropdownNext = () => {
        let exclude_properties = propertyUpdateList?.map(i => i?.data?.id)
        let offset = propertyDropdownOffset + dropdownLimit
        setPropertyDropdownOffset(offset)
        getPropertyDropdownList(propertyDropdownSearchText, offset, exclude_properties, true)
    };

    //Function for unit infinity scroll next
    const unitDropdownNext = () => {
        let exclude_units = unitUpdateList?.map(i => i?.data?.id)
        let offset = unitDropdownOffset + dropdownLimit
        setUnitDropdownOffset(offset)
        getUnitDropdownList(unitDropdownSearchText, offset, exclude_units, true)
    };
    //Function for facility infinity scroll next
    const facilityDropdownNext = () => {
        let exclude_facilities = facilityUpdateList?.map(i => i?.data?.id)
        let offset = facilityDropdownOffset + dropdownLimit
        setFacilityDropdownOffset(offset)
        getFaciltyDropdownList(facilityDropdownSearchText, offset, exclude_facilities, true)
    };

    //Function for property infinity scroll search
    const propertyDropdownsearch = (v) => {
        let exclude_properties = propertyUpdateList?.map(i => i?.data?.id)
        setPropertyDropdownOffset(0)
        getPropertyDropdownList(v, 0, exclude_properties, false)
    };

    //Function for unit infinity scroll search
    const unitDropdownsearch = (v) => {
        let exclude_units = unitUpdateList?.map(i => i?.data?.id)
        setUnitDropdownOffset(0)
        getUnitDropdownList(v, 0, exclude_units, false)
    };
    //Function for facility infinity scroll search
    const facilityDropdownsearch = (v) => {
        let exclude_facilities = facilityUpdateList?.map(i => i?.data?.id)
        setFacilityDropdownOffset(0)
        getFaciltyDropdownList(v, 0, exclude_facilities, false)
    };

    //Function to get property dropdown list
    const getPropertyDropdownList = (search = "", offset = 0, exclude_properties = [], next = false) => {
        let payload = { search, offset, limit: dropdownLimit, exclude_properties }

        NetworkCall(
            `${config.api_url}${BackendRoutes.listing_mapping_get_user_accessed_property}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.data?.data
            let temp_list = temp_res?.map(i => { return constructPropertyDropdownData(i) }) ?? []
            if (next) {
                setPropertyDropdownList(propertyDropdownList?.concat(temp_list))
            } else {
                setPropertyDropdownList(temp_list)
            }
            setIsPropertyDropdownLoading(false)
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
            setIsPropertyDropdownLoading(false)
        })
    }

    //Function to get unit dropdown list
    const getUnitDropdownList = (search = "", offset = 0, exclude_units = [], next = false) => {
        let payload = {
            search, offset, exclude_units,
            limit: dropdownLimit,
            property_id: selectedProperty?.value,
            is_public: mainTabValue?.value === listing_tab_data.public_lisiting.value ? true : undefined,
            is_private: mainTabValue?.value === listing_tab_data.private_listing.value ? true : undefined,
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.listing_mapping_get_units}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.data?.data
            let temp_list = temp_res?.map(i => { return constructUnitDropdownData(i) }) ?? []
            if (next) {
                setUnitDropdownList(unitDropdownList?.concat(temp_list))
            } else {
                setUnitDropdownList(temp_list)
            }
            setIsUnitDropdownLoading(false)
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }
    //Function to get facility dropdown list
    const getFaciltyDropdownList = (search = "", offset = 0, exclude_facilities = [], next = false) => {
        let payload = {
            search, offset, exclude_facilities,
            limit: dropdownLimit,
            property_id: selectedProperty?.value
        }

        NetworkCall(
            `${config.api_url}${BackendRoutes.get_facility}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.data?.data
            let temp_list = temp_res?.map(i => { return constructFacilityDropdownData(i) }) ?? []
            if (next) {
                setFacilityDropdownList(facilityDropdownList?.concat(temp_list))
            } else {
                setFacilityDropdownList(temp_list)
            }
            setIsFacilityDropdownLoading(false)
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }

    //Function to get property type options
    const getPropertyTypeOption = async () => {
        let temp_option;
        await NetworkCall(
            `${config.public_listing_api_url}${BackendRoutes.sync_global_property_type}`,
            NetWorkCallMethods.post, {}, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.response?.property_type
            temp_option = temp_res?.map(i => { return { value: i?.id, label: i?.type } }) ?? []
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
        return temp_option
    }

    //Function to get unit type options
    const getUnitTypeOption = async () => {
        let temp_option;
        await NetworkCall(
            `${config.public_listing_api_url}${BackendRoutes.sync_global_unit_type}`,
            NetWorkCallMethods.post, {}, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.response?.unit_type
            temp_option = temp_res?.map(i => { return { value: i?.id, label: i?.type } }) ?? []
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
        return temp_option
    }

    //Function to get unit type options
    const getAmenityCategoryOption = async () => {
        let temp_option;
        await NetworkCall(
            `${config.public_listing_api_url}${BackendRoutes.sync_global_unit_type}`,
            NetWorkCallMethods.post, {}, null, true, false
        ).then((res) => {
            let temp_res = res?.data?.response?.unit_type
            temp_option = temp_res?.map(i => { return { value: i?.id, label: i?.type } }) ?? []
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
        return temp_option
    }

    //Function to get fiter options
    const getFiterOptions = async () => {
        const [
            temp_enum,
            temp_global_property_type,
            temp_global_unit_type,
            temp_global_amenity_category,
        ] = await Promise.all([
            await enumSelect([enum_types.revenue_type]),
            await getPropertyTypeOption(),
            await getUnitTypeOption(),
            await getAmenityCategoryOption(),
        ]);
        setFilterOption({
            property_type: temp_global_property_type,
            unit_type: temp_global_unit_type,
            amenity_category: temp_global_amenity_category,
            revenue_type: temp_enum?.revenue_type
        })
    }

    //Function to update add property state
    const updateAddPropertyState = async (type, index, k, v, payload) => {
        setIsButtonDisable(true)
        let temp_list = []
        let updated_object = {}
        for (let i = 0; i < propertyUpdateList?.length; i++) {
            if (i === index) {
                switch (type) {
                    case "value":
                        updated_object = {
                            ...propertyUpdateList[i],
                            form: {
                                ...propertyUpdateList[i]?.form,
                                [k]: v
                            }
                        }
                        temp_list = [...temp_list, { ...updated_object }]
                        break;
                    case "sync":
                        await NetworkCall(
                            `${config.api_url}${BackendRoutes.listing_mapping_update_property_sync}`,
                            NetWorkCallMethods.post, payload, null, true, false
                            // eslint-disable-next-line
                        ).then((res) => {
                            updated_object = {
                                ...propertyUpdateList[i],
                                [k]: v
                            }
                            temp_list = [...temp_list, { ...updated_object }]
                            getData(0, limit, searchText, mainTabValue, revokedSubTabValue, filterData)
                            // eslint-disable-next-line
                        }).catch((err) => {
                            console.log(err)
                            alert.setSnack({
                                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                                severity: AlertProps.severity.error
                            })
                            temp_list = [...temp_list, { ...propertyUpdateList[i] }]
                            setIsButtonDisable(false)
                        })
                        break;
                    case "delete":
                        break;

                    default:
                        break;
                }
            } else {
                temp_list = [...temp_list, { ...propertyUpdateList[i] }]
            }
        }
        setPropertyUpdateList(temp_list)
        setIsButtonDisable(false)
    };

    //Function to update add unit state
    const updateAddUnitState = async (type, index, k, v, payload) => {
        setIsButtonDisable(true)
        let temp_list = []
        let updated_object = {}
        for (let i = 0; i < unitUpdateList?.length; i++) {
            if (i === index) {
                switch (type) {
                    case "value":
                        updated_object = {
                            ...unitUpdateList[i],
                            form: {
                                ...unitUpdateList[i]?.form,
                                [k]: v
                            }
                        }
                        temp_list = [...temp_list, { ...updated_object }]
                        break;
                    case "sync":
                        await NetworkCall(
                            `${config.api_url}${BackendRoutes.listing_mapping_update_unit_sync}`,
                            NetWorkCallMethods.post,
                            {
                                ...payload,
                                [mainTabValue?.payload_key_value?.is_key]: true,
                                size_type: mainTabValue?.payload_key_value?.size_type_value,
                            },
                            null, true, false
                            // eslint-disable-next-line
                        ).then((res) => {
                            updated_object = {
                                ...unitUpdateList[i],
                                [k]: v
                            }
                            temp_list = [...temp_list, { ...updated_object }]
                            getData(0, limit, searchText, mainTabValue, revokedSubTabValue, filterData)
                            // eslint-disable-next-line
                        }).catch((err) => {
                            console.log(err)
                            switch (err?.response?.status) {
                                case 406:
                                    alert.setSnack({
                                        ...alert, open: true, msg: t(err?.response?.data?.message),
                                        severity: AlertProps.severity.warning
                                    })
                                    break;

                                default:
                                    alert.setSnack({
                                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                                        severity: AlertProps.severity.error
                                    })
                                    break;
                            }
                            temp_list = [...temp_list, { ...unitUpdateList[i] }]
                            setIsButtonDisable(false)
                        })
                        break;
                    case "delete":
                        break;

                    default:
                        break;
                }
            } else {
                temp_list = [...temp_list, { ...unitUpdateList[i] }]
            }
        }
        setUnitUpdateList(temp_list)
        setIsButtonDisable(false)
    };
    //Function to update add facility state
    const updateAddFacilityState = async (type, index, k, v, payload) => {
        setIsButtonDisable(true)
        let temp_list = []
        let updated_object = {}
        for (let i = 0; i < facilityUpdateList?.length; i++) {
            if (i === index) {
                switch (type) {
                    case "value":
                        updated_object = {
                            ...facilityUpdateList[i],
                            form: {
                                ...facilityUpdateList[i]?.form,
                                [k]: v
                            }
                        }
                        temp_list = [...temp_list, { ...updated_object }]
                        break;
                    case "sync":
                        await NetworkCall(
                            `${config.api_url}${BackendRoutes.listing_mapping_update_facility_sync}`,
                            NetWorkCallMethods.post,
                            {
                                ...payload,
                                [mainTabValue?.payload_key_value?.is_key]: true,
                                size_type: mainTabValue?.payload_key_value?.size_type_value,
                            },
                            null, true, false
                            // eslint-disable-next-line
                        ).then((res) => {
                            updated_object = {
                                ...facilityUpdateList[i],
                                [k]: v
                            }
                            temp_list = [...temp_list, { ...updated_object }]
                            getData(0, limit, searchText, mainTabValue, revokedSubTabValue, filterData)
                            // eslint-disable-next-line
                        }).catch((err) => {
                            console.log(err)
                            switch (err?.response?.status) {
                                case 406:
                                    alert.setSnack({
                                        ...alert, open: true, msg: t(err?.response?.data?.message),
                                        severity: AlertProps.severity.warning
                                    })
                                    break;

                                default:
                                    alert.setSnack({
                                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                                        severity: AlertProps.severity.error
                                    })
                                    break;
                            }
                            temp_list = [...temp_list, { ...facilityUpdateList[i] }]
                            setIsButtonDisable(false)
                        })
                        break;
                    case "delete":
                        break;

                    default:
                        break;
                }
            } else {
                temp_list = [...temp_list, { ...facilityUpdateList[i] }]
            }
        }
        setFacilityUpdateList(temp_list)
        setIsButtonDisable(false)
    };
    //Function to yes revoke a unit
    const handleRevokeYes = () => {
        let payload = {}
        let apiUrl;
        switch (mainTabValue?.value) {
            case "private_listing":
                payload = {
                    unitId: confirmRevokeUnitData?.id,
                    is_private: false,
                    client_id: localStorage.getItem(LocalStorageKeys?.clinetID)
                }
                apiUrl = `${config.public_listing_api_url}${BackendRoutes.revoke_unit}`
                break;
            case "public_lisiting":
                payload = {
                    unitId: confirmRevokeUnitData?.id,
                    is_public: false,
                    client_id: localStorage.getItem(LocalStorageKeys?.clinetID)
                }
                apiUrl = `${config.public_listing_api_url}${BackendRoutes.revoke_unit}`
                break;
            case "public_facility_listing":
                payload = {
                    id: confirmRevokeUnitData?.id,
                    data: {
                        is_revoked: true,
                    }
                }
                apiUrl = `${config.api_url}${BackendRoutes.listing_facility_revoke}`
                break;
            case "private_facility_listing":
                payload = {
                    id: confirmRevokeUnitData?.id,
                    data: {
                        is_revoked: true,
                    }
                }
                apiUrl = `${config.api_url}${BackendRoutes.listing_facility_revoke}`
                break;
            default:
                break;

        }
        setIsButtonDisable(true)

        NetworkCall(
            apiUrl,
            ["public_facility_listing", "private_facility_listing"].includes(mainTabValue?.value)
                ? NetWorkCallMethods.post
                : NetWorkCallMethods.put,
            payload, null, true, false
        ).then((res) => {
            getData(0, limit, searchText, mainTabValue, revokedSubTabValue, filterData)
            setIsButtonDisable(false)
            setIsRevokeConfirmDialogOpen(false)
            setIsRevokeSuccessDialogOpen(true)
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
            setIsButtonDisable(false)
        })
    }

    //Function to no revoke a unit
    const handleRevokeNo = () => setIsRevokeConfirmDialogOpen(false)

    //Function to okay success revoke a unit
    const handleRevokeSuccessOkay = () => {
        setIsRevokeSuccessDialogOpen(false)
    }

    //Function to apply filter
    const onApplyFilter = (v) => {
        setFilterData(v)
        getData(0, limit, searchText, mainTabValue, revokedSubTabValue, v)
    }
    const render = () => {
        return <>
            <Subheader
                hideBackButton
                title={t("Listing Mapping")}
                goBack={() => history.goBack()} />
            <Stack className={classes.body} direction={"column"}>
                <Stack direction={"column"} divider={<Divider />}>
                    <div className={classes.tab_div}>
                        <LineIndicatorTab
                            t={t}
                            path={listing_tab_paths}
                            data={listing_tab_data}
                            selected={mainTabValue}
                            onClick={handleMainTabChange} />
                    </div>
                    <div className={classes.search_filter_add_div}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <div className={classes.search_div}>
                                <SearchFilter placeholder="Search" value={searchText} handleChange={(v) => handleSearch(v)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}/>
                            </div>
                            {mainTabValue?.value === listing_tab_data.revoked.value &&
                                <BubbleTab
                                    t={t}
                                    path={revoked_tabs_path}
                                    data={revoked_tabs_data}
                                    selected={revokedSubTabValue}
                                    onClick={handleRevokedSubTabChange} />}
                            <div className={classes.search_div}>
                                <Stack direction="row" justifyContent={"end"}
                                    divider={<VerticalDivider />}>

                                    <IconButton className={classes.filter_button} onClick={() => setDrawer(true)}>
                                        <Badge variant="dot" color="primary" invisible={isFilterApplied(mainTabValue, revokedSubTabValue, filterData)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                    {mainTabValue?.value !== listing_tab_data.revoked.value &&
                                        <Button className={classes.add_button} variant="outlined"
                                            onClick={() => handleAdd(mainTabValue)} >
                                            <Typography className={classes.add_button_text}>{t(mainTabValue?.add_button_text)}</Typography>
                                        </Button>}
                                </Stack>
                            </div>
                        </Stack>
                    </div>
                </Stack>
                <div className={classes.table_div}>
                    <TableWithPagination
                        is_loading={is_table_loading}
                        heading={handleTableHeading(mainTabValue, revokedSubTabValue)}
                        rows={tableData?.row}
                        path={handleTablePath(mainTabValue, revokedSubTabValue)}
                        showpagination={true}
                        showpdfbtn={false}
                        showexcelbtn={false}
                        showSearch={false}
                        handleIcon={handleIcon}
                        onClick={() => { }}
                        tableType="no-side"
                        dataType={handleTableType(mainTabValue, revokedSubTabValue)}
                        handlePagination={handleTablePagination}
                        handleChangeLimit={handleTablePageLimit}
                        totalRowsCount={tableData?.row_count}
                        page={page}
                        limit={limit}
                        height={tableData?.row?.length > 0 ? 'calc(100vh - 362px)' : 'calc(100vh - 325px)'}
                        view={permission.read}
                        edit={permission.update}
                        delete={permission.delete}
                        isButtonDisable={isButtonDisable} />
                </div>
            </Stack>
            <ConfirmDialog
                isDialogOpen={isRevokeConfirmDialogOpen}
                setIsDialogOpen={setIsRevokeConfirmDialogOpen}
                isButtonDisable={isButtonDisable}
                question={t(`Are you sure do you want to revoke this ${mainTabValue?.value === "facility_listing" ? "Facility" : "Unit"}?`)}
                yes_text={t("Yes")}
                no_text={t("No")}
                yes_action={handleRevokeYes}
                no_action={handleRevokeNo} />
            <SuccessDialog
                isDialogOpen={isRevokeSuccessDialogOpen}
                setIsDialogOpen={setIsRevokeSuccessDialogOpen}
                isButtonDisable={isButtonDisable}
                message={t(`${mainTabValue?.value === "facility_listing" ? "Facility" : "Unit"} revoked successfully!`)}
                okay_text={t("Okay")}
                okay_action={handleRevokeSuccessOkay} />
            <AddPropertyDialog
                t={t}
                isOpen={isAddPropertyOpen}
                setIsOpen={setIsAddPropertyOpen}
                isButtonDisable={isButtonDisable}
                title={t("Add Property")}
                isSearchDropdownOpen={isAddPropertySearchDropdownOpen}
                setIsSearchDropdownOpen={setIsAddPropertySearchDropdownOpen}
                propertyDropdownList={propertyDropdownList}
                setPropertyDropdownList={setPropertyDropdownList}
                propertyDropdownOnClick={propertyDropdownOnClick}
                propertyDropdownNext={propertyDropdownNext}
                isPropertyDropdownLoading={isPropertyDropdownLoading}
                setIsPropertyDropdownLoading={setIsPropertyDropdownLoading}
                propertyUpdateList={propertyUpdateList}
                setPropertyUpdateList={setPropertyUpdateList}
                propertyTypeOption={filterOption?.property_type}
                propertyDropdownSearchText={propertyDropdownSearchText}
                setPropertyDropdownSearchText={setPropertyDropdownSearchText}
                propertyDropdownsearch={propertyDropdownsearch}
                updateState={updateAddPropertyState}
            />
            <AddUnitDialog
                t={t}
                isOpen={isAddUnitOpen}
                setIsOpen={setIsAddUnitOpen}
                isButtonDisable={isButtonDisable}
                title={t("Add Unit")}
                isSearchDropdownOpen={isAddUnitSearchDropdownOpen}
                setIsSearchDropdownOpen={setIsAddUnitSearchDropdownOpen}
                unitDropdownList={unitDropdownList}
                setUnitDropdownList={setUnitDropdownList}
                unitDropdownOnClick={unitDropdownOnClick}
                unitDropdownNext={unitDropdownNext}
                isUnitDropdownLoading={isUnitDropdownLoading}
                setIsUnitDropdownLoading={setIsUnitDropdownLoading}
                unitUpdateList={unitUpdateList}
                setUnitUpdateList={setUnitUpdateList}
                unitTypeOption={filterOption?.unit_type}
                unitDropdownSearchText={unitDropdownSearchText}
                setUnitDropdownSearchText={setUnitDropdownSearchText}
                unitDropdownsearch={unitDropdownsearch}
                updateState={updateAddUnitState}
                selectedProperty={selectedProperty}
                setSelectedProperty={setSelectedProperty}
            />
            <AddFacilitiesDialog
                t={t}
                isOpen={isAddFacilityOpen}
                setIsOpen={setIsAddFacilityOpen}
                isButtonDisable={isButtonDisable}
                title={t("Add Facility")}
                isSearchDropdownOpen={isAddFacilitySearchDropdownOpen}
                setIsSearchDropdownOpen={setIsAddFacilitySearchDropdownOpen}
                unitDropdownList={facilityDropdownList}
                setUnitDropdownList={setFacilityDropdownList}
                unitDropdownOnClick={facilityDropdownOnClick}
                unitDropdownNext={facilityDropdownNext}
                isUnitDropdownLoading={isFacilityDropdownLoading}
                setIsUnitDropdownLoading={setIsFacilityDropdownLoading}
                unitUpdateList={facilityUpdateList}
                setUnitUpdateList={setFacilityUpdateList}
                unitTypeOption={filterOption?.amenity_category}
                unitDropdownSearchText={facilityDropdownSearchText}
                setUnitDropdownSearchText={setFacilityDropdownSearchText}
                unitDropdownsearch={facilityDropdownsearch}
                updateState={updateAddFacilityState}
                selectedProperty={selectedProperty}
                setSelectedProperty={setSelectedProperty}
            />
            <FilterGenerator
                open={drawer}
                onClose={() => setDrawer(false)}
                components={ConstructFilterComponent(t, mainTabValue, revokedSubTabValue, filterData, filterOption)}
                onApply={(value) => onApplyFilter(value)}
            />
        </>
    }

    return (<>{accessCheckRender(render, permission, "", loading)}</>)
}

export default withNamespaces("publicListing")(PublicListing);
