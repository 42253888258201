import React from "react";
import { withNavBars } from "../../HOCs";
import MaintenancePlanner from "./maintenancePlanner";

class MaintenancePlannerParent extends React.Component {
    render() {
        return <MaintenancePlanner  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(MaintenancePlannerParent, props);
