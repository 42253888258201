export const ResourceManagement = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105176">
            <g
                data-name="LS - Resource Management"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <g data-name="Group 105073">
                    <path
                        data-name="Path 98230"
                        d="m22.585 13.782-.746-.132a.507.507 0 0 1-.4-.377 6.588 6.588 0 0 0-.666-1.6.505.505 0 0 1 .015-.55l.436-.622a.508.508 0 0 0-.057-.648l-1.019-1.018a.5.5 0 0 0-.645-.057l-.622.436a.515.515 0 0 1-.552.015 6.569 6.569 0 0 0-1.6-.666.514.514 0 0 1-.379-.4l-.129-.746a.506.506 0 0 0-.5-.418H14.28a.506.506 0 0 0-.5.418l-.129.746a.514.514 0 0 1-.379.4 6.728 6.728 0 0 0-1.6.663.51.51 0 0 1-.55-.015l-.622-.435a.5.5 0 0 0-.645.057l-1.02 1.019a.508.508 0 0 0-.057.648l.436.622a.505.505 0 0 1 .015.55 6.588 6.588 0 0 0-.666 1.6.507.507 0 0 1-.4.377l-.746.132a.507.507 0 0 0-.418.5v1.443a.5.5 0 0 0 .418.5l.746.132a.507.507 0 0 1 .4.377 6.588 6.588 0 0 0 .666 1.6.51.51 0 0 1-.015.552l-.436.622a.5.5 0 0 0 .057.645l1.019 1.019a.5.5 0 0 0 .645.057l.622-.436a.51.51 0 0 1 .55-.015 6.624 6.624 0 0 0 1.6.663.514.514 0 0 1 .379.4l.129.746a.506.506 0 0 0 .5.418h1.443a.506.506 0 0 0 .5-.418l.129-.746a.514.514 0 0 1 .379-.4 6.57 6.57 0 0 0 1.6-.666.51.51 0 0 1 .552.018l.622.434a.5.5 0 0 0 .645-.054l1.019-1.019a.505.505 0 0 0 .057-.645l-.436-.622a.51.51 0 0 1-.015-.552 6.588 6.588 0 0 0 .666-1.6.507.507 0 0 1 .4-.377l.746-.132a.5.5 0 0 0 .418-.5V14.28a.507.507 0 0 0-.418-.5Zm-4.263 3.69a4.134 4.134 0 0 0-6.643 0 4.145 4.145 0 1 1 6.643 0Z"
                    />
                    <path
                        data-name="Path 98231"
                        d="M15.001 12.07a1.562 1.562 0 1 0 1.564 1.563 1.563 1.563 0 0 0-1.564-1.563Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
