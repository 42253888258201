import * as React from "react"

const AgreementIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={40} height={40}>
        <g data-name="Group 99036" transform="translate(-128 -386)">
            <rect
                data-name="Rectangle 52718"
                width={40}
                height={40}
                rx={8}
                transform="translate(128 386)"
                fill="#f1f7ff"
            />
            <g data-name="Group 99035">
                <g data-name="Group 93811">
                    <path
                        data-name="icons8-warranty (1)"
                        d="M148.018 396.003a1.925 1.925 0 0 0-1.418.524l-.447.41-.594-.124a2.01 2.01 0 0 0-2.33 1.369l-.182.578-.577.19a2.008 2.008 0 0 0-1.336 2.347l.131.591-.4.453a2.01 2.01 0 0 0 .018 2.7l.41.447-.123.594a2 2 0 0 0 .518 1.8l-2.488 2.489a.675.675 0 0 0 .277 1.126l2.584.795.795 2.584a.675.675 0 0 0 1.122.279l3.875-3.875c.056.006.109.017.166.018s.088-.011.133-.015l3.872 3.872a.675.675 0 0 0 1.122-.279l.795-2.584 2.584-.795a.675.675 0 0 0 .279-1.122l-2.486-2.486a2 2 0 0 0 .517-1.8l-.123-.594.41-.447a2.01 2.01 0 0 0 .018-2.7l-.4-.453.131-.591a2.008 2.008 0 0 0-1.341-2.354l-.577-.19-.182-.578a2.01 2.01 0 0 0-2.332-1.368l-.594.124-.445-.412a1.941 1.941 0 0 0-1.382-.523Zm-5.086 12.543.179.057.19.576a2.011 2.011 0 0 0 2.35 1.337l.591-.132.313.28-2.74 2.74-.57-1.854a.675.675 0 0 0-.446-.446l-1.854-.57Zm10.136 0 1.987 1.987-1.854.57a.675.675 0 0 0-.446.447l-.57 1.854-2.741-2.741.313-.279.592.132a2.011 2.011 0 0 0 2.35-1.337l.19-.576Z"
                        fill="#78b1fe"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default AgreementIcon
