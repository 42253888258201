import * as React from "react"

export const PropertyManager = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <path
      data-name="Path 93404"
      d="M6.937 0H1.312A1.314 1.314 0 0 0 0 1.312v3.375A1.314 1.314 0 0 0 1.312 6h5.625A1.314 1.314 0 0 0 8.25 4.687V1.312A1.314 1.314 0 0 0 6.937 0Zm0 0"
      fill={props?.color}
    />
    <path
      data-name="Path 93405"
      d="M6.937 7.5H1.312A1.314 1.314 0 0 0 0 8.813v7.875a1.314 1.314 0 0 0 1.312 1.313h5.625a1.314 1.314 0 0 0 1.313-1.313V8.813A1.314 1.314 0 0 0 6.937 7.5Zm0 0"
      fill={props?.color}
    />
    <path
      data-name="Path 93406"
      d="M16.688 12h-5.625a1.314 1.314 0 0 0-1.313 1.313v3.375A1.314 1.314 0 0 0 11.063 18h5.625A1.314 1.314 0 0 0 18 16.688v-3.375A1.314 1.314 0 0 0 16.688 12Zm0 0"
      fill={props?.color}
    />
    <path
      data-name="Path 93407"
      d="M16.688 0h-5.625A1.314 1.314 0 0 0 9.75 1.312v7.875a1.314 1.314 0 0 0 1.313 1.313h5.625A1.314 1.314 0 0 0 18 9.187V1.312A1.314 1.314 0 0 0 16.688 0Zm0 0"
      fill={props?.color}
    />
  </svg>
)
