import * as React from "react"
const AirConditioner = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19.647}
    height={8.387}
    {...props}
  >
    <path
      fill="#4e5a6b"
      d="M1.335 0A1.339 1.339 0 0 0 0 1.34v5.707a1.339 1.339 0 0 0 1.335 1.34h16.972a1.339 1.339 0 0 0 1.34-1.336v-5.73A1.339 1.339 0 0 0 18.307 0Zm0 .738h16.972a.6.6 0 0 1 .6.6v4.5H.738V1.321a.6.6 0 0 1 .597-.583Zm14.87 1.015a.369.369 0 0 0 0 .738h1.414a.369.369 0 1 0 0-.738ZM.738 6.582h18.167v.465a.6.6 0 0 1-.6.6H1.335a.6.6 0 0 1-.6-.6Z"
    />
  </svg>
)
export default AirConditioner
