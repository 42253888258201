import { Close } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { InActiveIcon } from "../../assets/inActivateIcon";
import { PublishFormIcon } from "../../assets/publishForm";
import { AlertDialog, FilterGenerator, LoadingSection, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods, UnitBlockingDataType, UnitBlockingHeading, UnitBlockingPath, wrapLabelsInT } from "../../utils";
import { useStyles } from "./style";
import FilterIcon from "../../assets/filterIcon";

const UnitBlockingList = ({ t }) => {
    const [tableData, setTableData] = React.useState({
        list: []
    })
    const auth = React.useContext(AuthContext)
    const [searchText, setSearchText] = React.useState("")
    const [permission, setPermission] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [loading, setLoading] = React.useState(true)
    const backdrop = React.useContext(BackdropContext)
    const [drawer, setDrawer] = React.useState(false)
    const [selectedCompany, setSelectedCompany] = React.useState("")
    const alert = React.useContext(AlertContext)
    const [companyList, setCompanyList] = React.useState([])
    const [enumValue, setEnumValue] = React.useState({
        block_unit_type: []
    })
    const [filterData, setFilterData] = React.useState({
        status: [],
    })
    const [propertyData, setPropertyData] = React.useState([])
    const [propertyValue, setPropertyValue] = React.useState([])
    const [blockingStatus, setBlockingStatus] = React.useState({ type: "Pending", data: {} })
    const [showModal, setShowModal] = React.useState(false)
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    const classes = useStyles()

    const getUnitBlockingList = (companyId, propertyId, offset = 0, limit = 10, searchKey = "", filterData = {}) => {
        const requestPayload = {
            start: offset ?? 0,
            length: limit ?? 10,
            company_id: companyId ?? 1,
            search: searchKey?.length > 0 ? searchKey : undefined,
            property_id: Array.isArray(propertyId) ? propertyId : [propertyId],
            "status": filterData?.status?.length > 0 ? filterData?.status?.map((x) => x?.value) : undefined, //enum resource_leave_type
        };
        NetworkCall(
            `${config.api_url}/unit_block_requests`,
            NetWorkCallMethods.post,
            requestPayload,
            null,
            true,
            false
        )
            .then((response) => {
                setLoading(false)
                const result = response?.data?.data?.map((val) => {
                    return {
                        ...val,
                        duration: `${val.duration} ${val.duration_type}`
                    }
                })
                setTableData({ list: result, count: response?.data?.count })
            }).catch((err) => {

                setLoading(false)

            })
    }
    // enum get
    const getEnum = async () => {
        const result = await enumSelect([enum_types.block_unit_type]);
        setEnumValue({ block_unit_type: result?.block_unit_type })
    };
    const getPropertyDropdown = (company) => {
        let allProperties = { value: company?.property?.map((i) => i.id) ?? [], label: "All Properties" }
        let result = company?.property?.map((i) => {
            return {
                label: i.name,
                value: i.id
            }
        })
        result.unshift(allProperties)
        setPropertyData(result)
        setPropertyValue(result?.[0])
        getUnitBlockingList(company?.value, result?.[0]?.value, 0, 10, "")
    }
    React.useEffect(() => {

        const perm = getRoutePermissionNew(auth)
        let company = getCompanyOption(backdrop, auth, alert)
        if (perm) {
            setPermission(perm)
            setCompanyList(company?.list)
            getPropertyDropdown(company?.selected)
            setSelectedCompany(company?.selected)

            getEnum()

        }
        //eslint-disable-next-line
    }, [auth])
    //handle company change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        // getUnitBlockingList(value?.value,propertyValue?.value, 0, limit, "")
        getPropertyDropdown(value)

        setFilterData({ job: [], type: [] })
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getUnitBlockingList(selectedCompany?.value, propertyValue?.value, offset, limit, "")
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getUnitBlockingList(selectedCompany?.value, propertyValue?.value, 0, value, "")

    }
    const handleSearch = (value) => {
        setSearchText(value)
        getUnitBlockingList(selectedCompany?.value, propertyValue?.value, 0, 10, value)
    }
    //handlePropertyChange
    const onPropertyChange = (val) => {
        setPropertyValue(val)
        getUnitBlockingList(selectedCompany?.value, val?.value, 0, 10, "", filterData)
    }
    const openDrawer = () => {
        setDrawer(!drawer)
    }
    const onApplyFilter = (value) => {
        getUnitBlockingList(selectedCompany?.value, propertyValue?.value, 0, 10, "", value)
        setFilterData(value)
    }
    //changeStatus
    const handleStatus = (val) => {
        setIsDisableBtn(true)
        const requestPayload = {
            "unit_id": val?.data?.unit_id,
            "status": val?.type === "approve" ? "Approved" : "Rejected",
            "request_id": val?.data?.id
        };
        NetworkCall(
            `${config.api_url}/unit_block_requests/block_unit`,
            NetWorkCallMethods.post,
            requestPayload,
            null,
            true,
            false
        )
            .then((response) => {
                setShowModal(false)
                setIsDisableBtn(false)
                if (response?.data?.data?.code === 406) {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: response?.data?.data?.msg,
                    });
                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: `${val.type === "approve" ? "Approved" : "Rejected"} Successfully`,
                    });
                    getUnitBlockingList(selectedCompany?.value, propertyValue?.value, 0, 10, "")
                }

            }).catch((error) => {
                if (error.response) {

                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: `Something went wrong`,
                    });
                }
                setIsDisableBtn(false)

            })
    }
    //handle Button 
    const handleIcon = (type, data) => {
        if (type !== "view") {
            setShowModal(true)
            setBlockingStatus({
                type: type, data: data
            })
        }
    }
    const render = () => {
        return (

            <Box>
                <Subheader title={t("Unit Blocking")} hideBackButton={true} select
                    options={companyList}
                    value={selectedCompany}
                    propertyValue={propertyValue}
                    onchange={(e) => {
                        handleCompanyChange(e)
                    }}
                    onPropertyChange={(e) => {
                        onPropertyChange(e)
                    }}
                    selectProperty
                    propertyOptions={propertyData}
                />
                {loading ?
                    <LoadingSection top="20vh" message={t("Fetching Details")} />
                    :
                    <Box className={classes.root}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={4}>
                                <Box>
                                    {/*search */}
                                    <SearchFilter label={false} placeholder={t("Search Unit Name")} handleChange={(value) => handleSearch(value)} value={searchText} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                                </Box>
                            </Grid>

                            <Grid item xs={8}>
                                <Box display={"flex"} alignItems="center" justifyContent={'end'}>
                                    <Stack
                                        direction="row"
                                        spacing={0.6}
                                        display={"flex"}
                                        alignItems="center"
                                    >
                                        <Box onClick={openDrawer}>
                                            {filterData?.status?.length > 0 ?
                                                <FilterIcon /> :
                                                <IconButton
                                                    size="small"
                                                    className={classes.img}

                                                >
                                                    <img src="/images/filter.svg" alt="filter" />
                                                </IconButton>
                                            }
                                        </Box>
                                    </Stack>
                                    <Box width={"15px"} />
                                </Box>
                            </Grid>
                        </Grid>

                        <TableWithPagination
                            heading={UnitBlockingHeading(t)}
                            rows={tableData?.list}
                            dataType={UnitBlockingDataType}
                            path={UnitBlockingPath}
                            showpagination
                            tableType="no-side"
                            handleIcon={handleIcon}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={tableData?.count}
                            page={page}
                            limit={limit}
                            view={permission?.read && true}
                            height={`calc(100vh - 300px)`}
                            edit={true}
                            delete={true} />
                    </Box>
                }

                {/* filter */}
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        onClose={() => setDrawer(false)}
                        components={[
                            {
                                component: "select",
                                value: filterData?.status,
                                isMulti: true,
                                label: t("Status"),
                                state_name: "status",
                                options: wrapLabelsInT(enumValue?.block_unit_type, t),
                                placeholder: t("Select Status")
                            },
                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                )}
                <AlertDialog
                    open={showModal}
                    isNormal
                    isnotTitle={true}
                    onClose={() => setShowModal(false)}
                    component={
                        <Box p={1}>
                            <Box display={"flex"} justifyContent="end" style={{ cursor: "pointer" }} onClick={() => setShowModal(false)}><Close /></Box>
                            <Box p={1}>
                                <center>
                                    <Box>{blockingStatus?.type === "approve" ? <PublishFormIcon /> : <InActiveIcon />} </Box>
                                    <Box mt={1}>
                                        <Typography className={classes.goBacktext}>{t("Do you want to ")}{t(blockingStatus?.type)}?</Typography>
                                    </Box>

                                </center>

                            </Box>
                            <Box p={2}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <Button variant="outlined" className={classes.draftbtn} fullWidth onClick={() => setShowModal(false)}>{t("No")}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" onClick={() => handleStatus(blockingStatus)} fullWidth disabled={isDisableBtn}>{t("Yes")}, {blockingStatus?.type === "approve" ? t("Approve") : t("Reject")}</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    }
                />
            </Box>
        )
    }
    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
}
export default withNamespaces("inspection")(UnitBlockingList)