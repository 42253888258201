import * as React from "react"

const VisitorAndSecurityIcon = (props) => (
    <svg
        height={40}
        viewBox="0 0 64 64"
        width={40}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M47.5 43h-5c-3.296.086-3.297 4.914 0 5h5c3.295-.085 3.297-4.914 0-5zM46 46c-.022 1.314-1.978 1.315-2 0v-1c.022-1.314 1.978-1.315 2 0zM47 37c-.07-2.637-3.93-2.636-4 0v4h4z" />
        <path d="M61 33.385c-11.478 0-14.965-6.557-15.105-6.832-.34-.678-1.45-.678-1.79 0-.034.068-3.55 6.832-15.105 6.832a1 1 0 0 0-1 1c0 .036.01.798.158 2.02A18.777 18.777 0 0 0 25 35.43v-1.12c5.682-1.884 9.317-8.47 7.58-14.44.718-1.234.328-5.057.42-6.87 4.933.374 5.46-6.755.5-7h-25c-4.94.24-4.454 7.373.5 7 .093 1.85-.3 5.62.42 6.87-1.737 5.972 1.898 12.555 7.58 14.44v1.12a18.793 18.793 0 0 0-5.173 1.955c-1.035-.837-2.62-.843-3.663-.007a21.215 21.215 0 0 0-5.828 6.99c-.634 1.172-.344 2.73.661 3.597A18.905 18.905 0 0 0 2 54v2c0 1.1.9 2 2 2h34c1.361-.005 2.163-1.235 2-2.52a23.876 23.876 0 0 0 4.706 2.014c.191.059.397.059.588 0C61.809 52.414 62 34.564 62 34.384a1 1 0 0 0-1-1zM21 9c4.625.147 4.624 6.854 0 7-4.625-.147-4.624-6.854 0-7zm9.79 10c-2.394 5.274-17.186 5.274-19.58 0zM11 23c0-.35.02-.69.05-1.03 4.273 3.99 15.628 3.989 19.9 0C31.605 27.855 26.751 33.066 21 33c-5.385.023-10.017-4.43-10-10zm12 11.83V36a2 2 0 0 1-4 0v-1.17c1.295.225 2.705.225 4 0zM9.388 38.96c.03-.024.068-.034.1-.054-.185.141-.364.29-.543.438.15-.125.288-.265.443-.384zm-5.276 6.327c.058-.112.133-.216.193-.327-.077.142-.15.288-.223.432.013-.034.013-.072.03-.105zM38 56H4c-.064-2.408.058-5.051.853-7.271 1.167.1 2.279-.576 2.812-1.606 1-1.934 2.442-3.663 4.17-4.999a3.001 3.001 0 0 0 1.08-3.055 16.842 16.842 0 0 1 4.355-1.649c.138.366.33.704.563 1.01l-2.43 10.928a.918.918 0 0 0 .346.934l4.7 3.524a.915.915 0 0 0 1.102 0l4.7-3.524a.918.918 0 0 0 .345-.934L24.167 38.43c.233-.306.425-.644.563-1.01 1.34.3 2.615.767 3.815 1.364.893 4.36 3.25 11.005 9.455 15.435V56zm9.5-6h-5a4.473 4.473 0 0 1-3.327-1.497 18.853 18.853 0 0 0-1.167-2.94c-.048-1.97 1.24-3.662 2.994-4.287V37c0-2.206 1.794-4 4-4s4 1.794 4 4v4.276c4.695 1.681 3.537 8.678-1.5 8.724z" />
    </svg>
)

export default VisitorAndSecurityIcon
