import { getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";
import { country_flag } from "./mobile.js";
//Function to validate email
export const ValidateEmail = (email) => {
  if (!email) {
    return false;
  }

  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
  return Boolean(re.test(email.trim()));
};
export let Validatelat = (lat) => {
  let re = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,15}/g;
  return Boolean(re.test(lat));
};
//Function to check wheather the 'e' is number key event or not based on 'isNumber' key
export let IsNumberKey = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (!(e.which >= 48 && e.which <= 57)) e.preventDefault();
    }
  }
};

//Function to check wheather the 'e' is number key event and is down or not.
export let IsNumberKeyDown = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (e.which === 38 || e.which === 40) e.preventDefault();
    }
  }
};

//Function to check wheather the 'e' is text key event or not.
export let IsTextKeyDown = (event, isOnlyText) => {
  var inputValue = event.which;

  if (isOnlyText) {
    if (
      !(
        (inputValue >= 65 && inputValue <= 90) ||
        (inputValue >= 97 && inputValue <= 122)
      ) &&
      inputValue !== 32 &&
      inputValue !== 0
    ) {
      event.preventDefault();
    }
  }
};
export const changeMobileLimitBasedOnCC = (
  country_dial_code,
  isReturnValueNeeded
) => {
  const selectedCountry = country_flag?.filter(
    (cc) => cc.dial_code === country_dial_code
  );
  const ISO2Code = selectedCountry?.[0]?.code;
  const phoneNumber = getExampleNumber(ISO2Code, examples);
  const limit = phoneNumber?.nationalNumber?.length ?? 10;
  if (isReturnValueNeeded) {
    return limit;
  }
};
export function getMobileLimitBasedOnCC(selectedCountryValue) {
  let mobileNumberLimitBasedOnCC = changeMobileLimitBasedOnCC(
    selectedCountryValue,
    true
  );
  return mobileNumberLimitBasedOnCC;
}


export const isEmptyObject = (object,alert) => {
  let isValid = true

  let newObj = object?.filter((val) => val?.is_active)

  if (newObj?.length > 0) {
    for (let obj of newObj) {

      for (let key in obj) {
        // if (!obj[key].toString()) return false
        if (key !== "file") {
          if (!obj[key].toString()) isValid = false
        } else {
          isValid = true
        }
      }
    }



    // newObj?.map((data) => {
    //   if (moment(data?.valid_to).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD")) {
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "Valid To cannot be greater than Today",
    //     });
    //     isValid = false
    //     return false
    //   } else {
    //     isValid = true
    //   }

    //   if (moment(data?.valid_from).format("YYYY-MM-DD") <= moment(data?.valid_to).format("YYYY-MM-DD")) {
    //     isValid = true
    //   } else {
    //     // console.log();
    //     alert.setSnack({
    //       ...alert,
    //       open: true,
    //       severity: AlertProps.severity.error,
    //       msg: "Valid From cannot be greater than valid To",
    //     });
    //     isValid = false
    //   }
    // })
  }

  return isValid
}
