export const INITIAL_QUICK_VIEW_STATE = {
    id: "",
    lead_name: "",
    reference_no: "",
    purpose: "",
    revenue_type: "",
    mobile_no: "",
    email_id: "",
    description: "",
};

export const TabData = ({ t = () => false, count = {} }) => {
    return {
        activity_comments: {
            value: "activity_comments",
            label: t("Activity Comments"),
            count: count?.activity_comments ?? 0,
        },
        internal: {
            value: "internal",
            label: t("Internal"),
            count: count?.internal ?? 0,
        },
        external: {
            value: "external",
            label: t("External"),
            count: count?.external ?? 0,
        },
        to_do_list: {
            value: "to_do_list",
            label: t("To Do List"),
            count: count?.to_do_list ?? 0,
        },
        activity: {
            value: "activity",
            label: t("Activity"),
            count: count?.activity ?? 0,
        },
    }
};

export const TabPaths = ({ t = () => false }) => [
    TabData(t)?.activity_comments?.value,
    TabData(t)?.internal?.value,
    TabData(t)?.external?.value,
    TabData(t)?.to_do_list?.value,
    TabData(t)?.activity?.value,
];

export const INITIAL_INTERNAL_TABLE_OFFSET = 0;

export const INITIAL_INTERNAL_TABLE_PAGE = 1;

export const INITIAL_INTERNAL_TABLE_LIMIT = 10;

export const INTERNAL_TABLE_PATH = [
    "image",
    "property_name",
    "unit_name",
    "unit_no",
    "unit_type",
]

export const INTERNAL_TABLE_HEADING = ({ t = () => false }) => [
    { title: t("Image"), field: "image" },
    { title: t("Property Name"), field: "property_name" },
    { title: t("Unit Name"), field: "unit_name" },
    { title: t("Unit No"), field: "unit_no" },
    { title: t("Unit Type"), field: "unit_type" },
]

export const INTERNAL_TABLE_TYPE = [
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "unit_type" },
]

export const INITIAL_EXTERNAL_TABLE_OFFSET = 0;

export const INITIAL_EXTERNAL_TABLE_PAGE = 1;

export const INITIAL_EXTERNAL_TABLE_LIMIT = 10;

export const EXTERNAL_TABLE_PATH = [
    "property_name",
    "unit_name",
    "unit_type",
    "period",
    "price",
    "agent",
    "owner",
    "listing_site",
]

export const EXTERNAL_TABLE_HEADING = ({ t = () => false }) => [
    { title: t("Property Name"), field: "property_name" },
    { title: t("Unit Name"), field: "unit_name" },
    { title: t("Unit Type"), field: "unit_type" },
    { title: t("Period"), field: "period" },
    { title: t("Price"), field: "price" },
    { title: t("Agent"), field: "agent" },
    { title: t("Owner"), field: "owner" },
    { title: t("Listing Site"), field: "listing_site" },
]

export const EXTERNAL_TABLE_TYPE = [
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "unit_type" },
    { type: ["text"], name: "period" },
    { type: ["text"], name: "price" },
    { type: ["text"], name: "agent" },
    { type: ["text"], name: "owner" },
    { type: ["text"], name: "listing_site" },
]

export const INITIAL_TO_DO_LIST_TABLE_OFFSET = 0;

export const INITIAL_TO_DO_LIST_TABLE_PAGE = 1;

export const INITIAL_TO_DO_LIST_TABLE_LIMIT = 10;

export const TO_DO_LIST_TABLE_PATH = [
    "is_done",
    "name",
    "description",
]

export const TO_DO_LIST_TABLE_HEADING = ({ t = () => false }) => [
    { title: "", field: "is_done" },
    { title: t("Name"), field: "name" },
    { title: t("Description"), field: "description" },
    { title: t("Edit"), field: "icon" },
]

export const TO_DO_LIST_TABLE_TYPE = [
    { type: ["checkBox"], name: "is_done" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "description" },
    { type: ["icon"], icon: "icon" },
]

export const INITIAL_TO_DO_FORM_STATE = {
    form_type: "",
    id: "",
    name: "",
    description: "",
    error: {
        name: "",
        description: "",
    }
}

export const TO_DO_FORM_CTA = {
    add: {
        header: "Add To Do",
        buttonText: "Create",
    },
    edit: {
        header: "Edit To Do",
        buttonText: "Update",
    },
    view: {
        header: "View To Do",
        buttonText: "Mark As Complete",
    },
}

export const INITIAL_ACTIVITY_TABLE_OFFSET = 0;

export const INITIAL_ACTIVITY_TABLE_PAGE = 1;

export const INITIAL_ACTIVITY_TABLE_LIMIT = 10;

export const ACTIVITY_TABLE_PATH = [
    "image",
    "activity_name",
    "action_info",
    "date",
    "created_by",
    "priority",
    "status",
]

export const ACTIVITY_TABLE_HEADING = ({ t = () => false }) => [
    { title: "", field: "image" },
    { title: t("Activity Name"), field: "activity_name" },
    { title: t("Action Info"), field: "action_info" },
    { title: t("Date"), field: "date" },
    { title: t("Created By"), field: "created_by" },
    { title: t("Priority"), field: "priority" },
    { title: t("Status"), field: "status" },
]

export const ACTIVITY_TABLE_TYPE = [
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "activity_name" },
    { type: ["text"], name: "action_info" },
    { type: ["date"], name: "date" },
    { type: ["text"], name: "created_by" },
    { type: ["text"], name: "priority" },
    { type: ["status2"], name: "status" },
]

export const LeadNewheading = (t) => [
    { title: t("Lead ID"), field: "reference_no" },
    { title: t("Created By"), field: "name" },
    { title: t("Lead Description"), field: "description" },
    { title: t("Created Date"), field: "created_at" },
];

export const INITIAL_COMMENT_OFFSET = 0;

export const INITIAL_COMMENT_LIMIT = 10;

export const PRIORITY_BG_COLOR = {
    urgent: "#ef476f",
    high: "#efb93c",
    medium: "#ffd166",
    low: "#06d6a0",
}