import * as React from "react"

export const RenewalRequest = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={19.739} {...props}>
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 55500" fill="none" d="M0 0h20v19.739H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 105702">
      <g data-name="Group 105372" clipPath="url(#a)">
        <path
          d="M2.884 2A1.887 1.887 0 0 0 1 3.884v10.465a2.726 2.726 0 0 0 2.721 2.721h6.091a5.43 5.43 0 0 1 .661-6.7h-.892a.628.628 0 1 1 0-1.256H12.3a5.462 5.462 0 0 1 3.353-.272V3.884A1.887 1.887 0 0 0 13.767 2Zm14.023 3.37v3.943a5.407 5.407 0 0 1 1.674 1.351V7.233a1.88 1.88 0 0 0-1.674-1.863Zm-12.767.4h8.372a.628.628 0 0 1 0 1.256H4.14a.628.628 0 0 1 0-1.256Zm0 3.349h3.348a.628.628 0 1 1 0 1.256H4.14a.628.628 0 0 1 0-1.256Zm10.256.419a4.6 4.6 0 1 0 4.6 4.6A4.6 4.6 0 0 0 14.4 9.535Zm0 1.674a2.925 2.925 0 0 1 2.093.891v-.473a.419.419 0 1 1 .837 0v1.67a.418.418 0 0 1-.419.419h-1.674a.419.419 0 1 1 0-.837h.821a2.082 2.082 0 1 0 .313 1.954.418.418 0 0 1 .789.279 2.929 2.929 0 1 1-2.76-3.907Zm-10.256.416h3.348a.628.628 0 1 1 0 1.256H4.14a.628.628 0 0 1 0-1.256Z"
          fill="#78b1fe"
        />
      </g>
    </g>
  </svg>
)
