export const MilestoneTemplate = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105171">
            <g
                data-name="13 Flag"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 98220"
                    d="M8.524 7A.525.525 0 0 0 8 7.524v14.952a.524.524 0 1 0 1.048 0V7.524A.525.525 0 0 0 8.524 7Z"
                />
                <path
                    data-name="Path 98221"
                    d="M20.913 14.356a1.48 1.48 0 0 1 0-1.607l1.79-2.806a1.061 1.061 0 0 0 .039-1.109 1.26 1.26 0 0 0-1.116-.635H9.45v10.707h12.176a1.26 1.26 0 0 0 1.116-.635 1.061 1.061 0 0 0-.039-1.109Zm-2.54-1.674-2.835 2.832a1.129 1.129 0 0 1-1.6 0l-1.35-1.35a.833.833 0 1 1 1.177-1.177l.71.71a.372.372 0 0 0 .526 0l2.191-2.191a.832.832 0 1 1 1.177 1.177Z"
                />
            </g>
        </g>
    </svg>
)