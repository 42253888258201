import React from "react";
import { withNavBars } from "../../HOCs";
import Profession from "./profession";

class ProfessionParent extends React.Component {
  render() {
    return <Profession />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ProfessionParent, props);
