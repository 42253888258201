import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import React from 'react';
import { BuldingIcon } from '../../components/svg';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { AlertProps, allowed_file_size, Bold, SemiBold, singleFileUpload } from '../../utils';
import { withNamespaces } from 'react-i18next';

const CustomTypography = styled(Typography, {
    shouldForwardProp: prop => prop !== "fontFamily" && prop !== "fontSize" && prop !== "color"
})(({ fontFamily, fontSize, color }) => ({
    fontFamily,
    fontSize,
    color
}))

const ImageWrapper = styled('div')({
    width: 120,
    height: 120,
    borderRadius: 71,
    backgroundColor: "#F2F4F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const CustomInput = styled('input')({
    position: 'absolute',
    opacity: 0,
    inset: 0,
    zIndex: 1,
    cursor: 'pointer'
})

const CustomAvatar = styled(Avatar)({
    width: 'inherit',
    height: 'inherit',
    borderRadius: 'inherit',
})

const UploadButton = styled(Button)(({ theme }) => ({
    borderColor: "#E4E8EE",
    position: "relative",
    marginTop: theme.spacing(2),
    color: "#071741",
    background: "#FFFFFF",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    cursor: "pointer",
    '&:hover': {
        background: "#FFFFFF"
    },
    borderRadius: theme.palette.borderRadius
}))

const LogoUpload = (props) => {

    const {
        logo_title = "",
        accept = "image/*",
        buttonName = "Upload Image",
        fileUploadUrl = true,
        handleChange = null,
        data = {},
        placeholderLogo = "",
        profile = false,
        isReadonly = false,
        isError = false,
        errorMessage = "",
        isrequired = false,
        removeBtn = true,
        t
    } = props;

    const theme = useTheme();
    const alert = React.useContext(AlertContext);

    const [loading, setLoading] = React.useState(false);

    const handleUpload = async (data) => {


        // const {height} = await findImageSize(data?.[0]);


        // if(height > 30){
        //     alert.setSnack({
        //         open: true,
        //         horizontal: AlertProps.horizontal.center,
        //         vertical: AlertProps.vertical.top,
        //         msg: `Image Height Shoud Be 30px`,
        //         severity: AlertProps.severity.error,
        //     });
        // }
        //only allow if file selected
        if (data?.length) {
            if (fileUploadUrl) {
                executeImageData(data)
            }
            else {
                handleChange && handleChange(data)
            }

        }
    }

    const executeImageData = async (data) => {

        setLoading(true);

        let uploaded_file = await singleFileUpload(data?.[0], { tenantId: `${config.tenantId}` }, alert, allowed_file_size)
        if (uploaded_file?.[0]?.url) {
            handleChange && handleChange({ src: uploaded_file?.[0]?.url });
            setLoading(false);
        } else {
            handleChange(null);
            setLoading(false);
            alert.setSnack({
                open: true,
                horizontal: AlertProps.horizontal.center,
                vertical: AlertProps.vertical.top,
                msg: t("Unable to upload"),
                severity: AlertProps.severity.error,
            });
        }
    }

    const removeImage = () => {
        handleChange && handleChange(null)
    }

    return (
        <div>
            <Stack
                alignItems={"center"}
            >
                {logo_title &&
                    <CustomTypography
                        fontFamily={Bold}
                        fontSize={12}
                        color={theme.typography.color.secondary}
                        sx={{
                            mb: 2
                        }}
                    >
                        {logo_title}
                        {isrequired && (
                            <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                *
                            </Typography>
                        )}
                    </CustomTypography>
                }
                {
                    loading ? (
                        <Skeleton width={141} height={141} variant={"circular"} />
                    ) : (
                        <>
                            <br />
                            <ImageWrapper justifyContent={props?.float ?? "center"}>
                                {
                                    data?.src ? (

                                        <CustomAvatar src={data?.src} />
                                    ) : (
                                        <>
                                            {placeholderLogo ? placeholderLogo :
                                                profile ? <PersonOutlineIcon sx={{ fontSize: "100px", color: "#E4E8EE" }} /> :
                                                    <BuldingIcon />}
                                        </>
                                    )
                                }
                            </ImageWrapper>
                        </>
                    )
                }
                {
                    !isReadonly &&
                    <>
                        <br />
                        {buttonName &&
                            <UploadButton
                                variant={"outlined"}
                                disabled={loading}
                            >
                                {t(buttonName)}
                                <CustomInput
                                    type="file"
                                    onChange={(e) => handleUpload(e.target.files)}
                                    accept={accept}
                                />
                            </UploadButton>
                        }
                    </>
                }
                {
                    (!removeBtn && !isReadonly) &&
                    <>
                        {
                            (data?.src && !loading) &&
                            <CustomTypography
                                fontFamily={Bold}
                                fontSize={12}
                                color={theme.palette.primary.main}
                                onClick={removeImage}
                                sx={{
                                    mt: 1,
                                    cursor: "pointer"
                                }}
                            >
                                {t("Remove Image")}
                            </CustomTypography>
                        }
                    </>
                }
                {
                    !isReadonly &&
                    <>
                        {isError && (
                            <Typography variant={"caption"} color={"error"}>
                                {errorMessage}
                            </Typography>
                        )}
                    </>
                }
            </Stack >
        </div >
    )
}

export default withNamespaces("logoUpload")(LogoUpload)
