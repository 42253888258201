import React from "react"

export const SelectedAsset = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Group_118299" data-name="Group 118299" transform="translate(-28 -346)">
        <g id="Rectangle_58675" data-name="Rectangle 58675" transform="translate(28 346)" fill="#f5f7fa" stroke="#e4e8ee" stroke-width="1">
            <rect width="40" height="40" rx="6" stroke="none" />
            <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" fill="none" />
        </g>
        <g id="Group_118298" data-name="Group 118298" transform="translate(32.5 350.166)">
            <g id="Group_118297" data-name="Group 118297">
                <path id="icons8-plus-math_1_" data-name="icons8-plus-math (1)" d="M12.813,9.929l-2.335-.594L9.919,7H9.863L9.335,9.335,7,9.929V10l2.335.475.523,2.335h.066l.553-2.335,2.335-.47Z" transform="translate(-7 -7)" fill="#ced3dd" />
                <path id="icons8-plus-math_1_2" data-name="icons8-plus-math (1)" d="M11.844,9.441,9.9,8.946,9.433,7H9.386L8.946,8.946,7,9.441V9.5l1.946.4.436,1.946h.055L9.9,9.9l1.946-.391Z" transform="translate(19.156 20)" fill="#ced3dd" />
                <g id="icons8-living-room" transform="translate(5.813 8)">
                    <path id="Path_101926" data-name="Path 101926" d="M11.839,36.391H10.4a.242.242,0,0,1-.233-.309L11,33.176A.243.243,0,0,1,11.235,33h1.434a.242.242,0,0,1,.233.309l-.83,2.906A.243.243,0,0,1,11.839,36.391Z" transform="translate(-7.178 -21.375)" fill="#717b89" />
                    <path id="Path_101927" data-name="Path 101927" d="M33.235,36.391h1.434a.242.242,0,0,0,.233-.309l-.83-2.906A.243.243,0,0,0,33.839,33H32.4a.242.242,0,0,0-.233.309L33,36.215A.243.243,0,0,0,33.235,36.391Z" transform="translate(-18.521 -21.375)" fill="#717b89" />
                    <path id="Path_101928" data-name="Path 101928" d="M31.266,10.453A1.453,1.453,0,0,0,29.813,9H25.453A1.453,1.453,0,0,0,24,10.453v6.781h7.266Z" transform="translate(-14.313 -9)" fill="#959ca6" />
                    <path id="Path_101929" data-name="Path 101929" d="M16.266,10.453A1.453,1.453,0,0,0,14.813,9H10.453A1.453,1.453,0,0,0,9,10.453v6.781h7.266Z" transform="translate(-6.578 -9)" fill="#959ca6" />
                    <path id="Path_101930" data-name="Path 101930" d="M23.531,25.906H9V23.969A.969.969,0,0,1,9.969,23H22.562a.969.969,0,0,1,.969.969Z" transform="translate(-6.578 -16.219)" fill="#465160" />
                    <path id="Path_101931" data-name="Path 101931" d="M6.422,25.719H5.938A1.938,1.938,0,0,1,4,23.781v-6.3A.485.485,0,0,1,4.484,17H5.938a.485.485,0,0,1,.484.484Z" transform="translate(-4 -13.125)" fill="#b8bdc4" />
                    <path id="Path_101932" data-name="Path 101932" d="M34.072,33.176A.243.243,0,0,0,33.839,33H32.4a.242.242,0,0,0-.233.309l.327,1.145h1.938Z" transform="translate(-18.521 -21.375)" opacity="0.05" />
                    <path id="Path_101933" data-name="Path 101933" d="M34.072,33.176A.243.243,0,0,0,33.839,33H32.4a.242.242,0,0,0-.233.309l.258.9h1.938Z" transform="translate(-18.521 -21.375)" opacity="0.07" />
                    <path id="Path_101934" data-name="Path 101934" d="M11.528,33.176A.242.242,0,0,1,11.761,33h1.434a.242.242,0,0,1,.233.309L13.1,34.453H11.163Z" transform="translate(-7.693 -21.375)" opacity="0.05" />
                    <path id="Path_101935" data-name="Path 101935" d="M11.6,33.176A.242.242,0,0,1,11.834,33h1.434a.242.242,0,0,1,.233.309l-.258.9H11.305Z" transform="translate(-7.767 -21.375)" opacity="0.07" />
                    <path id="Path_101936" data-name="Path 101936" d="M22.891,17H21.437a.485.485,0,0,0-.484.484v5.328H4v.969a1.938,1.938,0,0,0,1.938,1.937h15.5a1.938,1.938,0,0,0,1.938-1.937v-6.3A.485.485,0,0,0,22.891,17Z" transform="translate(-4 -13.125)" fill="#b8bdc4" />
                </g>
            </g>
        </g>
    </g>
</svg>
  )

}