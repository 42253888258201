import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        fontSize:"0.875rem"
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    addDialog: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            width: "320px",
        },
    },
    addDialogHeader: {
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    addDialogHeaderTitle: {
        fontFamily: ExtraBold,
        fontSize:"1rem",
        color: "#091B29"
    },
    addDialogCloseButton: {
        padding: "0px"
    },
    addDialogBody: {
        padding: "24px",
        overflowY: "overlay"
    },
    addDialogFooter: {
        display: "flex",
        columnGap: "12px",
        padding: "24px",
    },
    addDialogFooterButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: "white"
    },
    addDialogFooterCloseButton: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: "#5078E1",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
    },
    addDialogFieldLabel: {
        color: "#98A0AC",
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        marginBottom: "5px",
    },
    addDialogButtonContainer: {
        display: "flex",
        rowGap: "8px",
        columnGap: "8px",
    },
    addDialogButtonSelected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: "white",
    },
    addDialogButtonUnSelected: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#E4E8EE ",
        },
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: "#98A0AC",
    },
}));