import * as React from "react"

const EmailSlIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32.158}
        height={32.158}
        {...props}
    >
        <defs>
            <clipPath id="a">
                <rect
                    data-name="Rectangle 53782"
                    width={32.158}
                    height={32.158}
                    rx={8}
                    fill="#30afce"
                />
            </clipPath>
        </defs>
        <g data-name="Group 101897">
            <rect
                data-name="Rectangle 53685"
                width={32.158}
                height={32.158}
                rx={8}
                fill="#78b1fe"
            />
            <g data-name="Mask Group 6473" opacity={0.65} clipPath="url(#a)">
                <g data-name="Group 101896" fill="none" stroke="#68a5f8">
                    <g data-name="Group 101888">
                        <g data-name="Rectangle 53686">
                            <path
                                stroke="none"
                                d="M-52.68-4.743-31.25.998l-5.742 21.43-21.43-5.742z"
                            />
                            <path d="m-52.326-4.131 20.463 5.483-5.483 20.463-20.463-5.483z" />
                        </g>
                        <g data-name="Rectangle 53687">
                            <path
                                stroke="none"
                                d="m-25.508-20.431 21.429 5.742L-9.821 6.74-31.25.998z"
                            />
                            <path d="m-25.155-19.819 20.464 5.483-5.484 20.464L-30.638.645z" />
                        </g>
                        <g data-name="Rectangle 53688">
                            <path
                                stroke="none"
                                d="m-51.454-2.62 18.081 4.844-4.845 18.081-18.081-4.845z"
                            />
                            <path d="m-51.1-2.008 17.115 4.586-4.586 17.115-17.116-4.586z" />
                        </g>
                        <g data-name="Rectangle 53689">
                            <path
                                stroke="none"
                                d="M-24.283-18.308-6.2-13.464l-4.845 18.082-18.081-4.845z"
                            />
                            <path d="m-23.93-17.696 17.116 4.586L-11.4 4.005-28.515-.58z" />
                        </g>
                        <g data-name="Rectangle 53690">
                            <path
                                stroke="none"
                                d="m-49.983-.073 14.063 3.768-3.768 14.063-14.063-3.768z"
                            />
                            <path d="m-49.63.54 13.097 3.509-3.509 13.097-13.097-3.51z" />
                        </g>
                        <g data-name="Rectangle 53691">
                            <path
                                stroke="none"
                                d="m-22.812-15.76 14.063 3.768-3.768 14.063-14.063-3.769z"
                            />
                            <path d="m-22.458-15.148 13.097 3.51-3.51 13.096-13.096-3.51z" />
                        </g>
                        <g data-name="Rectangle 53692">
                            <path
                                stroke="none"
                                d="m-48.513 2.474 10.045 2.691-2.691 10.045-10.045-2.691z"
                            />
                            <path d="m-48.16 3.086 9.08 2.433-2.433 9.079-9.079-2.433z" />
                        </g>
                        <g data-name="Rectangle 53693">
                            <path
                                stroke="none"
                                d="m-21.341-13.214 10.045 2.692-2.692 10.045-10.045-2.692z"
                            />
                            <path d="m-20.988-12.601 9.08 2.432-2.433 9.08-9.08-2.433z" />
                        </g>
                        <g data-name="Rectangle 53694">
                            <path
                                stroke="none"
                                d="m-47.042 5.02 6.027 1.616-1.615 6.027-6.027-1.615z"
                            />
                            <path d="m-46.689 5.633 5.062 1.357-1.356 5.061-5.062-1.356z" />
                        </g>
                        <g data-name="Rectangle 53695">
                            <path
                                stroke="none"
                                d="m-19.87-10.667 6.027 1.615-1.615 6.028-6.028-1.615z"
                            />
                            <path d="m-19.517-10.054 5.061 1.356-1.356 5.061-5.061-1.356z" />
                        </g>
                        <g data-name="Rectangle 53696">
                            <path
                                stroke="none"
                                d="m-46.062 6.72 3.348.896-.897 3.348-3.348-.897z"
                            />
                            <path d="m-45.708 7.332 2.382.638-.638 2.382-2.382-.638z" />
                        </g>
                        <g data-name="Rectangle 53697">
                            <path
                                stroke="none"
                                d="m-18.89-8.968 3.348.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m-18.537-8.356 2.382.638-.638 2.382-2.382-.638z" />
                        </g>
                    </g>
                    <g data-name="Group 101889">
                        <g data-name="Rectangle 53698">
                            <path
                                stroke="none"
                                d="m-34.48 26.778 21.428 5.742-5.741 21.43-21.43-5.743z"
                            />
                            <path d="m-34.127 27.39 20.463 5.484-5.483 20.463-20.463-5.483z" />
                        </g>
                        <g data-name="Rectangle 53699">
                            <path
                                stroke="none"
                                d="m-7.31 11.09 21.43 5.742-5.742 21.43-21.429-5.742z"
                            />
                            <path d="m-6.956 11.703 20.464 5.483-5.484 20.463-20.463-5.483z" />
                        </g>
                        <g data-name="Rectangle 53700">
                            <path
                                stroke="none"
                                d="m-33.255 28.9 18.081 4.846-4.845 18.08-18.081-4.844z"
                            />
                            <path d="m-32.902 29.513 17.116 4.586-4.586 17.115-17.116-4.586z" />
                        </g>
                        <g data-name="Rectangle 53701">
                            <path
                                stroke="none"
                                d="m-6.084 13.213 18.082 4.845L7.153 36.14l-18.081-4.845z"
                            />
                            <path d="m-5.73 13.826 17.115 4.586L6.8 35.527l-17.115-4.586z" />
                        </g>
                        <g data-name="Rectangle 53702">
                            <path
                                stroke="none"
                                d="m-31.784 31.449 14.063 3.768-3.768 14.063-14.063-3.769z"
                            />
                            <path d="m-31.43 32.06 13.096 3.51-3.509 13.097-13.097-3.51z" />
                        </g>
                        <g data-name="Rectangle 53703">
                            <path
                                stroke="none"
                                d="M-4.613 15.761 9.45 19.53 5.682 33.592-8.38 29.824z"
                            />
                            <path d="m-4.26 16.373 13.098 3.51-3.51 13.097-13.096-3.51z" />
                        </g>
                        <g data-name="Rectangle 53704">
                            <path
                                stroke="none"
                                d="m-30.314 33.996 10.045 2.691-2.691 10.045-10.045-2.692z"
                            />
                            <path d="m-29.96 34.608 9.079 2.433-2.433 9.078-9.079-2.432z" />
                        </g>
                        <g data-name="Rectangle 53705">
                            <path
                                stroke="none"
                                d="M-3.142 18.308 6.903 21 4.21 31.044l-10.045-2.691z"
                            />
                            <path d="m-2.789 18.92 9.08 2.433-2.433 9.079-9.08-2.433z" />
                        </g>
                        <g data-name="Rectangle 53706">
                            <path
                                stroke="none"
                                d="m-28.843 36.543 6.027 1.615-1.615 6.027-6.027-1.615z"
                            />
                            <path d="m-28.49 37.155 5.062 1.356-1.356 5.062-5.062-1.357z" />
                        </g>
                        <g data-name="Rectangle 53707">
                            <path
                                stroke="none"
                                d="m-1.672 20.855 6.028 1.615-1.615 6.027-6.028-1.615z"
                            />
                            <path d="m-1.318 21.467 5.061 1.357-1.356 5.061-5.061-1.356z" />
                        </g>
                        <g data-name="Rectangle 53708">
                            <path
                                stroke="none"
                                d="m-27.863 38.24 3.348.898-.897 3.348-3.348-.897z"
                            />
                            <path d="m-27.51 38.853 2.383.638-.638 2.382-2.382-.638z" />
                        </g>
                        <g data-name="Rectangle 53709">
                            <path
                                stroke="none"
                                d="m-.691 22.553 3.348.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m-.338 23.166 2.382.638-.638 2.382-2.382-.638z" />
                        </g>
                    </g>
                    <g data-name="Group 101890">
                        <g data-name="Rectangle 53710">
                            <path
                                stroke="none"
                                d="m-30.158 3.068 21.43 5.742-5.742 21.429-21.43-5.742z"
                            />
                            <path d="m-29.804 3.68 20.463 5.483-5.483 20.464-20.463-5.484z" />
                        </g>
                        <g data-name="Rectangle 53711">
                            <path
                                stroke="none"
                                d="m-2.986-12.62 21.43 5.742-5.743 21.43L-8.728 8.81z"
                            />
                            <path d="M-2.632-12.007 17.83-6.524l-5.483 20.463-20.464-5.483z" />
                        </g>
                        <g data-name="Rectangle 53712">
                            <path
                                stroke="none"
                                d="m-28.932 5.19 18.081 4.845-4.845 18.082-18.08-4.845z"
                            />
                            <path d="m-28.578 5.803 17.115 4.586-4.586 17.115-17.115-4.586z" />
                        </g>
                        <g data-name="Rectangle 53713">
                            <path
                                stroke="none"
                                d="m-1.76-10.497 18.08 4.845-4.844 18.081-18.081-4.845z"
                            />
                            <path d="m-1.407-9.885 17.115 4.586-4.586 17.116L-5.993 7.23z" />
                        </g>
                        <g data-name="Rectangle 53714">
                            <path
                                stroke="none"
                                d="m-27.461 7.738 14.063 3.769-3.768 14.063L-31.23 21.8z"
                            />
                            <path d="m-27.107 8.35 13.097 3.51-3.51 13.097-13.097-3.51z" />
                        </g>
                        <g data-name="Rectangle 53715">
                            <path
                                stroke="none"
                                d="m-.29-7.95 14.063 3.77-3.768 14.062-14.063-3.768z"
                            />
                            <path d="m.064-7.337 13.097 3.51L9.651 9.27-3.444 5.76z" />
                        </g>
                        <g data-name="Rectangle 53716">
                            <path
                                stroke="none"
                                d="m-25.99 10.285 10.044 2.692-2.691 10.045-10.045-2.692z"
                            />
                            <path d="m-25.637 10.898 9.079 2.432-2.433 9.08-9.079-2.433z" />
                        </g>
                        <g data-name="Rectangle 53717">
                            <path
                                stroke="none"
                                d="m1.181-5.402 10.045 2.691L8.534 7.334-1.51 4.643z"
                            />
                            <path d="m1.535-4.79 9.078 2.433-2.432 9.079L-.9 4.289z" />
                        </g>
                        <g data-name="Rectangle 53718">
                            <path
                                stroke="none"
                                d="m-24.52 12.832 6.027 1.615-1.615 6.028-6.027-1.615z"
                            />
                            <path d="m-24.166 13.445 5.061 1.356-1.356 5.061-5.062-1.356z" />
                        </g>
                        <g data-name="Rectangle 53719">
                            <path
                                stroke="none"
                                d="M2.652-2.855 8.679-1.24 7.064 4.787 1.036 3.172z"
                            />
                            <path d="M3.005-2.243 8.067-.887 6.71 4.175 1.65 2.819z" />
                        </g>
                        <g data-name="Rectangle 53720">
                            <path
                                stroke="none"
                                d="m-23.54 14.53 3.348.898-.897 3.348-3.348-.897z"
                            />
                            <path d="m-23.186 15.143 2.382.638-.638 2.382-2.382-.638z" />
                        </g>
                        <g data-name="Rectangle 53721">
                            <path
                                stroke="none"
                                d="M3.632-1.157 6.98-.26l-.897 3.348-3.348-.897z"
                            />
                            <path d="m3.986-.544 2.382.638-.639 2.382-2.382-.638z" />
                        </g>
                    </g>
                    <g data-name="Group 101891">
                        <g data-name="Rectangle 53722">
                            <path
                                stroke="none"
                                d="M-11.959 34.589 9.47 40.33 3.728 61.76-17.7 56.018z"
                            />
                            <path d="m-11.605 35.201 20.463 5.483-5.483 20.463-20.464-5.483z" />
                        </g>
                        <g data-name="Rectangle 53723">
                            <path
                                stroke="none"
                                d="m15.213 18.901 21.429 5.742-5.742 21.43L9.47 40.33z"
                            />
                            <path d="m15.566 19.514 20.463 5.483-5.483 20.463-20.463-5.483z" />
                        </g>
                        <g data-name="Rectangle 53724">
                            <path
                                stroke="none"
                                d="m-10.734 36.711 18.082 4.845-4.845 18.081-18.081-4.845z"
                            />
                            <path d="M-10.38 37.324 6.735 41.91 2.15 59.025l-17.115-4.586z" />
                        </g>
                        <g data-name="Rectangle 53725">
                            <path
                                stroke="none"
                                d="m16.438 21.024 18.081 4.845-4.845 18.08-18.08-4.844z"
                            />
                            <path d="m16.792 21.636 17.115 4.586-4.586 17.115-17.115-4.586z" />
                        </g>
                        <g data-name="Rectangle 53726">
                            <path
                                stroke="none"
                                d="M-9.263 39.26 4.8 43.026 1.032 57.09l-14.063-3.768z"
                            />
                            <path d="M-8.909 39.872 4.188 43.38.678 56.478l-13.096-3.51z" />
                        </g>
                        <g data-name="Rectangle 53727">
                            <path
                                stroke="none"
                                d="m17.909 23.572 14.063 3.768-3.768 14.063-14.063-3.768z"
                            />
                            <path d="m18.263 24.184 13.097 3.51-3.51 13.096-13.097-3.509z" />
                        </g>
                        <g data-name="Rectangle 53728">
                            <path
                                stroke="none"
                                d="m-7.792 41.806 10.045 2.692L-.44 54.542l-10.044-2.691z"
                            />
                            <path d="M-7.438 42.419 1.64 44.85-.792 53.93l-9.08-2.433z" />
                        </g>
                        <g data-name="Rectangle 53729">
                            <path
                                stroke="none"
                                d="m19.38 26.119 10.044 2.691-2.691 10.045-10.045-2.692z"
                            />
                            <path d="m19.733 26.731 9.079 2.433-2.433 9.078L17.3 35.81z" />
                        </g>
                        <g data-name="Rectangle 53730">
                            <path
                                stroke="none"
                                d="m-6.322 44.353 6.028 1.615-1.615 6.028-6.028-1.615z"
                            />
                            <path d="m-5.968 44.966 5.061 1.356-1.356 5.061-5.061-1.356z" />
                        </g>
                        <g data-name="Rectangle 53731">
                            <path
                                stroke="none"
                                d="m20.85 28.666 6.027 1.615-1.615 6.027-6.027-1.615z"
                            />
                            <path d="m21.204 29.278 5.061 1.356-1.356 5.062-5.062-1.357z" />
                        </g>
                        <g data-name="Rectangle 53732">
                            <path
                                stroke="none"
                                d="m-5.341 46.051 3.348.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m-4.987 46.664 2.381.638-.638 2.382-2.382-.638z" />
                        </g>
                        <g data-name="Rectangle 53733">
                            <path
                                stroke="none"
                                d="m21.83 30.364 3.348.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m22.184 30.976 2.382.639-.638 2.382-2.382-.639z" />
                        </g>
                    </g>
                    <g data-name="Group 101892">
                        <g data-name="Rectangle 53734">
                            <path
                                stroke="none"
                                d="m1.663-36.118 21.43 5.741-5.742 21.43-21.43-5.742z"
                            />
                            <path d="m2.017-35.506 20.463 5.483L16.997-9.56l-20.463-5.483z" />
                        </g>
                        <g data-name="Rectangle 53735">
                            <path
                                stroke="none"
                                d="m28.835-51.806 21.429 5.742-5.742 21.429-21.429-5.742z"
                            />
                            <path d="m29.189-51.194 20.463 5.483-5.483 20.464-20.464-5.483z" />
                        </g>
                        <g data-name="Rectangle 53736">
                            <path
                                stroke="none"
                                d="M2.889-33.996 20.97-29.15 16.125-11.07l-18.08-4.845z"
                            />
                            <path d="m3.242-33.383 17.116 4.586-4.586 17.115-17.116-4.586z" />
                        </g>
                        <g data-name="Rectangle 53737">
                            <path
                                stroke="none"
                                d="m30.06-49.683 18.082 4.844-4.845 18.082-18.081-4.845z"
                            />
                            <path d="m30.414-49.071 17.115 4.586-4.586 17.115-17.115-4.586z" />
                        </g>
                        <g data-name="Rectangle 53738">
                            <path
                                stroke="none"
                                d="m4.36-31.448 14.063 3.768-3.768 14.063L.592-17.385z"
                            />
                            <path d="m4.713-30.836 13.097 3.51-3.509 13.097-13.097-3.51z" />
                        </g>
                        <g data-name="Rectangle 53739">
                            <path
                                stroke="none"
                                d="m31.531-47.136 14.063 3.769-3.768 14.063-14.063-3.769z"
                            />
                            <path d="m31.885-46.523 13.097 3.51-3.51 13.096-13.096-3.51z" />
                        </g>
                        <g data-name="Rectangle 53740">
                            <path
                                stroke="none"
                                d="m5.83-28.901 10.045 2.691-2.691 10.045-10.045-2.691z"
                            />
                            <path d="m6.184-28.289 9.079 2.433-2.433 9.079-9.079-2.433z" />
                        </g>
                        <g data-name="Rectangle 53741">
                            <path
                                stroke="none"
                                d="m33.002-44.589 10.045 2.692-2.692 10.045-10.044-2.692z"
                            />
                            <path d="m33.356-43.976 9.078 2.432-2.432 9.08-9.08-2.433z" />
                        </g>
                        <g data-name="Rectangle 53742">
                            <path
                                stroke="none"
                                d="m7.3-26.354 6.028 1.615-1.615 6.027-6.027-1.615z"
                            />
                            <path d="m7.654-25.742 5.062 1.357-1.356 5.061-5.062-1.356z" />
                        </g>
                        <g data-name="Rectangle 53743">
                            <path
                                stroke="none"
                                d="m34.472-42.042 6.028 1.615-1.615 6.028-6.028-1.615z"
                            />
                            <path d="m34.826-41.43 5.061 1.357-1.356 5.061-5.061-1.356z" />
                        </g>
                        <g data-name="Rectangle 53744">
                            <path
                                stroke="none"
                                d="m8.281-24.656 3.348.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m8.635-24.043 2.382.638-.638 2.382-2.382-.638z" />
                        </g>
                        <g data-name="Rectangle 53745">
                            <path
                                stroke="none"
                                d="m35.453-40.343 3.348.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m35.807-39.73 2.382.637-.639 2.382-2.382-.638z" />
                        </g>
                    </g>
                    <g data-name="Group 101893">
                        <g data-name="Rectangle 53746">
                            <path
                                stroke="none"
                                d="m19.862-4.597 21.43 5.742-5.742 21.43-21.43-5.743z"
                            />
                            <path d="M20.216-3.985 40.679 1.5l-5.483 20.463-20.463-5.483z" />
                        </g>
                        <g data-name="Rectangle 53747">
                            <path
                                stroke="none"
                                d="m47.034-20.284 21.429 5.741-5.742 21.43-21.429-5.742z"
                            />
                            <path d="m47.388-19.672 20.463 5.483-5.483 20.463L41.904.791z" />
                        </g>
                        <g data-name="Rectangle 53748">
                            <path
                                stroke="none"
                                d="M21.088-2.474 39.169 2.37l-4.845 18.08-18.08-4.844z"
                            />
                            <path d="m21.441-1.862 17.116 4.586L33.97 19.84l-17.116-4.586z" />
                        </g>
                        <g data-name="Rectangle 53749">
                            <path
                                stroke="none"
                                d="m48.26-18.162 18.08 4.845-4.844 18.081L43.415-.08z"
                            />
                            <path d="m48.613-17.55 17.115 4.587-4.586 17.115L44.027-.434z" />
                        </g>
                        <g data-name="Rectangle 53750">
                            <path
                                stroke="none"
                                d="m22.559.074 14.063 3.768-3.768 14.063-14.063-3.769z"
                            />
                            <path d="m22.912.686 13.097 3.51L32.5 17.291l-13.097-3.51z" />
                        </g>
                        <g data-name="Rectangle 53751">
                            <path
                                stroke="none"
                                d="m49.73-15.614 14.063 3.768-3.768 14.063L45.962-1.55z"
                            />
                            <path d="m50.084-15.002 13.097 3.51-3.51 13.097-13.096-3.51z" />
                        </g>
                        <g data-name="Rectangle 53752">
                            <path
                                stroke="none"
                                d="m24.03 2.62 10.044 2.692-2.691 10.045-10.045-2.692z"
                            />
                            <path d="m24.383 3.233 9.079 2.433-2.433 9.078-9.079-2.432z" />
                        </g>
                        <g data-name="Rectangle 53753">
                            <path
                                stroke="none"
                                d="m51.201-13.067 10.045 2.692L58.554-.331 48.51-3.022z"
                            />
                            <path d="m51.555-12.455 9.078 2.433-2.432 9.079-9.08-2.433z" />
                        </g>
                        <g data-name="Rectangle 53754">
                            <path
                                stroke="none"
                                d="m25.5 5.168 6.027 1.615-1.615 6.027-6.027-1.615z"
                            />
                            <path d="m25.853 5.78 5.062 1.356-1.356 5.062-5.062-1.357z" />
                        </g>
                        <g data-name="Rectangle 53755">
                            <path
                                stroke="none"
                                d="M52.671-10.52 58.7-8.905l-1.615 6.027-6.028-1.615z"
                            />
                            <path d="m53.025-9.908 5.061 1.357L56.73-3.49 51.67-4.846z" />
                        </g>
                        <g data-name="Rectangle 53756">
                            <path
                                stroke="none"
                                d="m26.48 6.866 3.348.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m26.834 7.478 2.382.638-.638 2.382-2.382-.638z" />
                        </g>
                        <g data-name="Rectangle 53757">
                            <path
                                stroke="none"
                                d="M53.652-8.822 57-7.925l-.897 3.348-3.348-.897z"
                            />
                            <path d="m54.006-8.21 2.382.639-.639 2.382-2.382-.638z" />
                        </g>
                    </g>
                    <g data-name="Group 101894">
                        <g data-name="Rectangle 53758">
                            <path
                                stroke="none"
                                d="m24.186-28.307 21.429 5.742-5.742 21.429-21.43-5.742z"
                            />
                            <path d="m24.54-27.695 20.462 5.483L39.52-1.748 19.056-7.232z" />
                        </g>
                        <g data-name="Rectangle 53759">
                            <path
                                stroke="none"
                                d="m51.357-43.995 21.43 5.742-5.743 21.43-21.429-5.742z"
                            />
                            <path d="M51.71-43.382 72.175-37.9 66.69-17.436l-20.463-5.483z" />
                        </g>
                        <g data-name="Rectangle 53760">
                            <path
                                stroke="none"
                                d="m25.411-26.184 18.081 4.844-4.845 18.082-18.08-4.845z"
                            />
                            <path d="m25.765-25.572 17.115 4.586-4.586 17.115-17.115-4.586z" />
                        </g>
                        <g data-name="Rectangle 53761">
                            <path
                                stroke="none"
                                d="m52.583-41.872 18.08 4.845-4.844 18.081-18.081-4.845z"
                            />
                            <path d="m52.936-41.26 17.115 4.586-4.586 17.116-17.115-4.586z" />
                        </g>
                        <g data-name="Rectangle 53762">
                            <path
                                stroke="none"
                                d="m26.882-23.637 14.063 3.769-3.768 14.063-14.063-3.769z"
                            />
                            <path d="m27.236-23.024 13.097 3.51-3.51 13.096-13.097-3.51z" />
                        </g>
                        <g data-name="Rectangle 53763">
                            <path
                                stroke="none"
                                d="m54.054-39.324 14.063 3.768-3.769 14.063-14.063-3.768z"
                            />
                            <path d="m54.407-38.712 13.097 3.51-3.51 13.097-13.096-3.51z" />
                        </g>
                        <g data-name="Rectangle 53764">
                            <path
                                stroke="none"
                                d="m28.353-21.09 10.044 2.692-2.691 10.045-10.045-2.692z"
                            />
                            <path d="m28.706-20.477 9.079 2.432-2.433 9.08-9.079-2.433z" />
                        </g>
                        <g data-name="Rectangle 53765">
                            <path
                                stroke="none"
                                d="m55.524-36.777 10.045 2.691-2.692 10.045-10.044-2.691z"
                            />
                            <path d="m55.878-36.165 9.078 2.433-2.432 9.079-9.079-2.433z" />
                        </g>
                        <g data-name="Rectangle 53766">
                            <path
                                stroke="none"
                                d="m29.823-18.543 6.027 1.615-1.615 6.028-6.027-1.615z"
                            />
                            <path d="m30.177-17.93 5.061 1.356-1.356 5.061-5.062-1.356z" />
                        </g>
                        <g data-name="Rectangle 53767">
                            <path
                                stroke="none"
                                d="m56.995-34.23 6.027 1.615-1.615 6.027-6.027-1.615z"
                            />
                            <path d="m57.348-33.618 5.062 1.356-1.357 5.062-5.061-1.356z" />
                        </g>
                        <g data-name="Rectangle 53768">
                            <path
                                stroke="none"
                                d="m30.804-16.844 3.347.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m31.157-16.232 2.382.638-.638 2.382-2.382-.638z" />
                        </g>
                        <g data-name="Rectangle 53769">
                            <path
                                stroke="none"
                                d="m57.975-32.532 3.348.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m58.329-31.92 2.382.639-.639 2.382-2.382-.638z" />
                        </g>
                    </g>
                    <g data-name="Group 101895">
                        <g data-name="Rectangle 53770">
                            <path
                                stroke="none"
                                d="m42.384 3.214 21.43 5.742-5.743 21.429-21.429-5.742z"
                            />
                            <path d="M42.738 3.826 63.2 9.31l-5.483 20.463-20.463-5.483z" />
                        </g>
                        <g data-name="Rectangle 53771">
                            <path
                                stroke="none"
                                d="m69.556-12.474 21.429 5.742-5.742 21.43-21.43-5.743z"
                            />
                            <path d="m69.91-11.861 20.462 5.483-5.483 20.463-20.463-5.483z" />
                        </g>
                        <g data-name="Rectangle 53772">
                            <path
                                stroke="none"
                                d="m43.61 5.336 18.08 4.845-4.844 18.081-18.081-4.845z"
                            />
                            <path d="m43.963 5.949 17.115 4.586-4.586 17.115-17.115-4.586z" />
                        </g>
                        <g data-name="Rectangle 53773">
                            <path
                                stroke="none"
                                d="m70.781-10.351 18.081 4.845-4.845 18.08-18.08-4.844z"
                            />
                            <path d="M71.135-9.739 88.25-5.153l-4.586 17.115-17.115-4.586z" />
                        </g>
                        <g data-name="Rectangle 53774">
                            <path
                                stroke="none"
                                d="m45.08 7.884 14.063 3.768-3.768 14.063-14.063-3.768z"
                            />
                            <path d="m45.434 8.497 13.097 3.509-3.51 13.097-13.096-3.51z" />
                        </g>
                        <g data-name="Rectangle 53775">
                            <path
                                stroke="none"
                                d="m72.252-7.803 14.063 3.768-3.768 14.063L68.484 6.26z"
                            />
                            <path d="m72.606-7.191 13.097 3.51-3.51 13.096-13.097-3.509z" />
                        </g>
                        <g data-name="Rectangle 53776">
                            <path
                                stroke="none"
                                d="m46.551 10.431 10.045 2.692-2.692 10.044-10.044-2.691z"
                            />
                            <path d="m46.905 11.044 9.078 2.432-2.432 9.079-9.079-2.433z" />
                        </g>
                        <g data-name="Rectangle 53777">
                            <path
                                stroke="none"
                                d="m73.723-5.256 10.044 2.691L81.076 7.48 71.03 4.788z"
                            />
                            <path d="m74.076-4.644 9.079 2.433-2.433 9.078-9.078-2.432z" />
                        </g>
                        <g data-name="Rectangle 53778">
                            <path
                                stroke="none"
                                d="m48.022 12.978 6.027 1.615-1.615 6.028-6.027-1.615z"
                            />
                            <path d="m48.375 13.59 5.062 1.357-1.357 5.061-5.061-1.356z" />
                        </g>
                        <g data-name="Rectangle 53779">
                            <path
                                stroke="none"
                                d="m75.193-2.71 6.027 1.616-1.615 6.027-6.027-1.615z"
                            />
                            <path d="m75.547-2.097 5.061 1.356-1.356 5.062-5.062-1.357z" />
                        </g>
                        <g data-name="Rectangle 53780">
                            <path
                                stroke="none"
                                d="m49.002 14.676 3.348.897-.897 3.348-3.348-.897z"
                            />
                            <path d="m49.356 15.289 2.382.638-.639 2.382-2.382-.638z" />
                        </g>
                        <g data-name="Rectangle 53781">
                            <path
                                stroke="none"
                                d="m76.174-1.011 3.348.897-.898 3.348-3.347-.897z"
                            />
                            <path d="M76.527-.399 78.91.24l-.638 2.382-2.382-.639z" />
                        </g>
                    </g>
                </g>
            </g>
            <path
                data-name="icons8-mail (5)"
                d="M10.722 9.954a2.3 2.3 0 0 0-2.29 2.106l7.649 4.13 7.649-4.13a2.3 2.3 0 0 0-2.292-2.106Zm-2.3 3.4v6.55a2.3 2.3 0 0 0 2.3 2.3h10.716a2.3 2.3 0 0 0 2.3-2.3v-6.55l-7.387 4a.573.573 0 0 1-.544 0Z"
                fill="#fff"
            />
        </g>
    </svg>
)

export default EmailSlIcon
