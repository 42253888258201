import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold, remCalc } from "../../utils";

export const Contract360Style = makeStyles((theme) => ({
    dropdownNotSelected: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 6
    },
    dropdownSelected: {
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: 6,
        background: theme.palette.info.light
    },
    optionHeader: {
        fontSize: remCalc(12),
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    optionSubTitle: {
        fontSize: remCalc(12),
        fontFamily: Regular,
        color: theme.typography.color.secondary
    },
    dot: {
        height: 6,
        width: 6,
        background: theme.palette.border.secondary,
        borderRadius: 50,
        marginInline: "16px"
    },
    resetBtn: {
        height: 40,
        color: theme.typography.color.primary,
        fontSize: remCalc(14),
        fontFamily: Bold,
        "&:hover": {
            background: "transparent"
        }
    },
    searchBtn: {
        height: 40,
        fontSize: remCalc(14),
        fontFamily: Bold,
    },
    contractDetailHeader: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    tabs: {
        width: "100%",
        borderBottom: `1px solid ${theme.palette.border.secondary}`
    },
    tabTitle: {
        fontSize: remCalc(14),
        fontFamily: SemiBold
    },
    noContractDisplay: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 300px)"
    },
    noContractText: {
        fontSize: remCalc(14),
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    }
}))