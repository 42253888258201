import React from "react"

export const ExecutedJobAsset = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.001" viewBox="0 0 24 24.001">
            <g id="Group_118506" data-name="Group 118506" transform="translate(0 0.001)">
                <g id="Rectangle_52035" data-name="Rectangle 52035" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="Subtraction_59" data-name="Subtraction 59" d="M10630-2624.585h-13.8a2.7,2.7,0,0,1-2.7-2.7v-16.2a2.7,2.7,0,0,1,2.7-2.7h2.13a2.694,2.694,0,0,1,2.668-2.4h4.2a2.694,2.694,0,0,1,2.669,2.4H10630a2.7,2.7,0,0,1,2.7,2.7v16.2A2.7,2.7,0,0,1,10630-2624.585Zm-10.828-16.229a2.222,2.222,0,0,0-2.221,2.218v7.856a2.223,2.223,0,0,0,2.221,2.22h7.855a2.222,2.222,0,0,0,2.218-2.22v-7.856a2.22,2.22,0,0,0-2.218-2.218Zm1.827-5.969a.889.889,0,0,0-.9.9.889.889,0,0,0,.9.9h4.2a.89.89,0,0,0,.9-.9.89.89,0,0,0-.9-.9Zm6.028,17.238h-7.855a1.183,1.183,0,0,1-1.2-1.2v-6.319h10.247v6.319A1.183,1.183,0,0,1,10627.027-2629.545Zm-3.928-3.074a.855.855,0,0,0-.854.854.855.855,0,0,0,.854.854.855.855,0,0,0,.854-.854A.855.855,0,0,0,10623.1-2632.619Zm-2.9,0a.855.855,0,0,0-.854.854.855.855,0,0,0,.854.854.855.855,0,0,0,.854-.854A.855.855,0,0,0,10620.2-2632.619Zm5.807-3.074a.855.855,0,0,0-.854.854.855.855,0,0,0,.854.854.855.855,0,0,0,.854-.854A.855.855,0,0,0,10626-2635.693Zm-2.9,0a.855.855,0,0,0-.854.854.855.855,0,0,0,.854.854.855.855,0,0,0,.854-.854A.855.855,0,0,0,10623.1-2635.693Zm-2.9,0a.855.855,0,0,0-.854.854.855.855,0,0,0,.854.854.855.855,0,0,0,.854-.854A.855.855,0,0,0,10620.2-2635.693Zm8.026-2.391h-10.247v-.512a1.182,1.182,0,0,1,1.2-1.193h7.855a1.182,1.182,0,0,1,1.2,1.193v.511Z" transform="translate(-10611.1 2648.584)" fill="#6dafb3" />
            </g>
        </svg>
    )
}