export const FacilityAndAmenitiesInvoice = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105168">
            <g
                data-name="LS - Invoice Management - Amenity Invoice"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 98215"
                    d="M4 21.034v1.341a.626.626 0 0 0 1.252 0v-.715h18.859v.715a.626.626 0 0 0 1.252 0v-3.4H4Z"
                />
                <path
                    data-name="Path 98216"
                    d="M23.061 13.704H6.3a2.3 2.3 0 0 0-2.3 2.3v1.72h21.363v-1.72a2.3 2.3 0 0 0-2.302-2.3Z"
                />
                <path
                    data-name="Path 98217"
                    d="M12.335 10.933a1.051 1.051 0 0 0-1.05 1.05v.469h6.793v-.469a1.051 1.051 0 0 0-1.05-1.05Z"
                />
                <path
                    data-name="Path 98218"
                    d="M10.034 11.983a2.3 2.3 0 0 1 2.3-2.3h4.693a2.3 2.3 0 0 1 2.3 2.3v.469h3.352v-3.15a2.3 2.3 0 0 0-2.3-2.3H8.984a2.3 2.3 0 0 0-2.3 2.3v3.15h3.35Z"
                />
            </g>
        </g>
    </svg>
)