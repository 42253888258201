import makeStyles from "@mui/styles/makeStyles";
export const VechicleMasterStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    margin: "20px",
    backgroundColor: theme.palette.background.tertiary1,
    border: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: theme.palette.borderRadius,
  },
}))