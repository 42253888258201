export const Worker = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98479">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 95043"
                    d="M17.482 19.493a4.893 4.893 0 0 1-5.964 0V23h5.964Z"
                />
                <path
                    data-name="Path 95044"
                    d="M18.488 18.905h-.128v4.09h3.639v-.579a3.516 3.516 0 0 0-3.511-3.511Z"
                />
                <path
                    data-name="Path 95045"
                    d="M10.639 18.905h-.127A3.516 3.516 0 0 0 7 22.422v.579h3.639Z"
                />
                <path
                    data-name="Path 95046"
                    d="M16.258 8.932v3.181H18.5a3.982 3.982 0 0 0-.778-1.972 4.07 4.07 0 0 0-1.464-1.209Z"
                />
                <path
                    data-name="Path 95047"
                    d="M11.277 10.141a3.982 3.982 0 0 0-.778 1.972h2.242V8.932a4.069 4.069 0 0 0-1.464 1.209Z"
                />
                <path
                    data-name="Path 95048"
                    d="M14.5 19.628a4.029 4.029 0 0 0 4.025-4.025v-.633h-8.05v.633a4.029 4.029 0 0 0 4.025 4.025Z"
                />
                <path data-name="Rectangle 52145" d="M13.621 8h1.759v4.113h-1.759z" />
                <path
                    data-name="Rectangle 52146"
                    d="M9.486 12.992h10.028v1.099H9.486z"
                />
            </g>
            <path data-name="Rectangle 52168" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
