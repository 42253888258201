import * as React from "react"

export const Account = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16.762} {...props}>
        <path
            d="M7.2.004a2.2 2.2 0 0 0-1.361.47l-5.61 4.42A.6.6 0 0 0 .6 5.961h13.2a.6.6 0 0 0 .371-1.071L8.562.47A2.2 2.2 0 0 0 7.2.004Zm0 1.2a1 1 0 0 1 .619.216l4.251 3.341H2.33l4.251-3.348a1 1 0 0 1 .619-.216Zm0 1.165a.8.8 0 1 0 .8.8.8.8 0 0 0-.8-.808Zm-5.8 4.4v5.248a1.8 1.8 0 0 0 .4 3.552H6v-1.2H1.8a.6.6 0 1 1 0-1.2H6v-1.2h-.8v-5.2H4v5.2H2.6v-5.2Zm5.2 0v3.056a1.353 1.353 0 0 1 .8-.256h.4v-2.8Zm2.6 0v2.8h1.2v-2.8Zm2.6 0v2.8H13v-2.8Zm-4 3.6a1.009 1.009 0 0 0-1 1v4.4a1.009 1.009 0 0 0 1 1H15a1.009 1.009 0 0 0 1-1v-4.4a1.009 1.009 0 0 0-1-1Zm.2 1.2h6.8v.8H8Zm0 2h6.8v2H8Z"
            fill={props?.color}
        />
    </svg>
)


