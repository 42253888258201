import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    box: {
        // border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
    },
    box2: {
        // border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "12px",
    },
    secondBox: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "11px",
        height: "100%",
    },
    profile_box: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "8px 12px 12px 12px",
        height: "295px"
    },
    title: {
        fontSize:"0.75rem",
        color: "#4E5A6B",
        fontFamily: `${Bold}`,
        marginBottom: "8px"

    },
    label: {
        fontSize:"0.75rem",
        color: "#98A0AC",
        fontFamily: `${SemiBold}`,
        marginBottom: "8px"
    },
    root: {
        height: `calc(100vh - 199px)`,
        overflow: "auto",
        padding: "8px 0px 8px 8px"
    },
    btnRoot: {
        backgroundColor: "white"
    },
    saveBtn: {
        boxShadow: "none",
        padding: "8px 12px"
    },
    cancelBtn: {
        boxShadow: "none",
        padding: "8px 12px",
        backgroundColor: "white",
        border: "1px solid #E4E8EE",
        color: theme.typography.color.primary
    },
    tabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: `${Bold}`,
    },
    tabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: `${Bold}`,
    },
    subtabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: `${Regular}`,
    },
    subtabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: `${Regular}`,
    },
    selectBack1: {
        borderTopLeftRadius: "12px",
        // borderTopRightRadius: "12px",
        // backgroundColor: '#F1F7FF',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 16px',
        borderRight:"1px solid #E4E8EE"
    },
    selectBack: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 16px',
        borderRight:"1px solid #E4E8EE"
    },
}))