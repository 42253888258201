import * as React from "react"
const BookedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 110164" transform="translate(-1271 -1689.75)">
      <circle
        cx={20}
        cy={20}
        r={20}
        fill="#eef9ee"
        data-name="Ellipse 129693"
        transform="translate(1271 1689.75)"
      />
      <path
        fill="#5ac782"
        d="M1299 1706.083v-.625a2.711 2.711 0 0 0-2.708-2.708h-9.584a2.711 2.711 0 0 0-2.708 2.708v.625Zm-15 1.25v7.708a2.711 2.711 0 0 0 2.708 2.709h9.583a2.711 2.711 0 0 0 2.709-2.708v-7.709Zm3.958 7.917a1.042 1.042 0 1 1 1.042-1.042 1.042 1.042 0 0 1-1.042 1.042Zm0-3.75a1.042 1.042 0 1 1 1.042-1.042 1.042 1.042 0 0 1-1.042 1.042Zm3.542 3.75a1.042 1.042 0 1 1 1.042-1.042 1.042 1.042 0 0 1-1.042 1.042Zm0-3.75a1.042 1.042 0 1 1 1.042-1.042 1.042 1.042 0 0 1-1.042 1.042Zm3.542 0a1.042 1.042 0 1 1 1.042-1.042 1.042 1.042 0 0 1-1.042 1.042Z"
      />
    </g>
  </svg>
)
export default BookedIcon
