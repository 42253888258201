import * as React from "react"
export const PropertyIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          stroke="#707070"
          d="M0 0h32v32H0z"
          data-name="Rectangle 55813"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" data-name="Mask Group 93152">
      <path
        fill="#d35230"
        d="m27.251 8.307-6.77-3.692-6.769 3.692V28h13.539Z"
        data-name="Path 99149"
      />
      <path
        fill="#b83c1f"
        d="M27.25 8.923a.615.615 0 0 1-.294-.075l-6.475-3.531-6.474 3.531a.616.616 0 1 1-.59-1.081l6.77-3.692a.618.618 0 0 1 .59 0l6.769 3.692a.615.615 0 0 1-.3 1.156Z"
        data-name="Path 99150"
      />
      <path
        fill="#ff8f6b"
        d="M23.021 24.618v-2.346h2.535v2.346Zm-3.807 0v-2.346h2.537v2.346Zm3.807-4.1v-2.347h2.535v2.344Zm-3.807 0v-2.347h2.537v2.344Zm3.807-4.109v-2.347h2.535v2.347Zm-3.807 0v-2.347h2.537v2.347Zm-3.8 0v-2.347h2.53v2.347Zm7.612-4.1V9.962h2.535v2.344Zm-3.807 0V9.962h2.532v2.344Zm-3.8 0V9.962h2.525v2.344Z"
        data-name="Union 27"
      />
      <path
        fill="#ff8f6b"
        d="M15.016 18.109h2.968v2.968h-2.968z"
        data-name="Rectangle 55805"
      />
      <path
        fill="#ff8f6b"
        d="M15.016 23.055h2.968v2.968h-2.968z"
        data-name="Rectangle 55806"
      />
      <path
        d="M18.904 18.604a1.23 1.23 0 0 0-.026-2.146l-5.513-3.007V28h5.539Z"
        data-name="Path 99151"
        opacity={0.05}
      />
      <path
        d="M18.596 18.408a.923.923 0 0 0 .134-1.681l-5.365-2.926v14.2h5.231Z"
        data-name="Path 99152"
        opacity={0.07}
      />
      <path
        fill="#199be2"
        d="m18.289 17.538-6.77-3.692-6.769 3.692V28h13.539Z"
        data-name="Path 99153"
      />
      <path
        fill="#0078d4"
        d="M18.288 18.153a.615.615 0 0 1-.294-.075l-6.475-3.532-6.474 3.532a.616.616 0 0 1-.59-1.081l6.769-3.692a.618.618 0 0 1 .59 0l6.769 3.692a.615.615 0 0 1-.3 1.156Z"
        data-name="Path 99154"
      />
      <path
        fill="#0d62ab"
        d="M14.001 25.745v-2.464h2.535v2.464Zm-3.807 0v-2.464h2.537v2.464Zm-3.8 0v-2.464h2.53v2.464Zm7.607-4.305v-2.459h2.535v2.459Zm-3.807 0v-2.459h2.537v2.459Zm-3.8 0v-2.459h2.53v2.459Z"
        data-name="Union 28"
      />
    </g>
  </svg>
)
