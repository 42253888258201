import * as React from "react"
export const Money = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 111505">
      <g data-name="icons8-cash (4)">
        <path
          fill="#a5d6a7"
          d="M1.142 6.571h21.714V18H1.142Z"
          data-name="Path 100217"
        />
        <path
          fill="#388e3c"
          d="M.571 18.571h22.857V6H.571ZM22.285 7.143v10.286H1.714V7.143Z"
          data-name="Path 100218"
        />
        <path
          fill="#388e3c"
          d="M5.142 11.142a1.143 1.143 0 1 0 1.143 1.143 1.143 1.143 0 0 0-1.143-1.143Zm6.857-2.857a4 4 0 1 0 4 4 4 4 0 0 0-4-4ZM3.554 6.571H1.142v2.412a1.707 1.707 0 0 0 2.412-2.412Zm0 11.429a1.707 1.707 0 0 0-2.412-2.412V18Zm19.3 0v-2.412A1.707 1.707 0 0 0 20.442 18Z"
          data-name="Path 100219"
        />
        <path
          fill="#388e3c"
          d="M18.857 13.428a1.143 1.143 0 1 0-1.143-1.143 1.143 1.143 0 0 0 1.143 1.143ZM12 16.285a4 4 0 1 0-4-4 4 4 0 0 0 4 4Zm8.445 1.714h2.412v-2.412a1.707 1.707 0 0 0-2.412 2.412Zm0-11.429a1.707 1.707 0 0 0 2.412 2.412V6.57Zm-19.3 0v2.412A1.707 1.707 0 0 0 3.557 6.57Z"
          data-name="Path 100220"
        />
      </g>
    </g>
  </svg>
)