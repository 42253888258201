import { Stack, Typography } from '@mui/material'
import React from 'react'
import { BottomTotalCardStyle } from './style'

export const BottomTotalCard = ({
    logo,
    title = "",
    subtitle = 0,
    customPadding=1
}) => {
    const classes = BottomTotalCardStyle()
    return (
        <Stack direction={"row"} columnGap={1} p={customPadding} alignItems={"center"}>
            {logo}
            <Stack>
                <Typography className={classes.title} noWrap>{title}</Typography>
                <Typography className={classes.subtitle}>{subtitle}</Typography>
            </Stack>
        </Stack>
    )
}