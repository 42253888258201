import React from "react"

export const VendorAssetIcon = (props) =>{
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        data-name="Group 118073"
        {...props}
      >
        <g data-name="Group 118072" transform="translate(8 7)">
          <path
            fill="#cfd8dc"
            d="M-1 18.591a6.613 6.613 0 0 1 5.909-3.545 6.613 6.613 0 0 1 5.909 3.545v2.364H-1Zm26 0a6.7 6.7 0 0 0-11.818 0v2.364H25ZM4.909 13.864a2.955 2.955 0 1 0-2.955-2.955 2.954 2.954 0 0 0 2.955 2.955m14.182 0a2.955 2.955 0 1 0-2.955-2.955 2.954 2.954 0 0 0 2.955 2.955"
            data-name="Path 101913"
          />
          <path
            fill="#ffca28"
            d="M15.546 8.545A3.545 3.545 0 1 1 12 5a3.545 3.545 0 0 1 3.545 3.545"
            data-name="Path 101914"
          />
          <path
            fill="#546e7a"
            d="M19.091 18A7.928 7.928 0 0 0 12 13.864 7.928 7.928 0 0 0 4.909 18v2.955h14.182Z"
            data-name="Path 101915"
          />
          <rect
            width={24}
            height={26}
            fill="#fff"
            data-name="Rectangle 58616"
            rx={1}
            style={{
              mixBlendMode: "hue",
              isolation: "isolate",
            }}
          />
        </g>
      </svg>
    )
}