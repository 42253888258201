import * as React from "react"

const MobileIcon = () => {
    return (
        <svg id="Group_102757" data-name="Group 102757" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect id="Rectangle_54873" data-name="Rectangle 54873" width="24" height="24" fill="none" />
            <path id="icons8-smartphone" d="M13.8,4A1.809,1.809,0,0,0,12,5.8V18.2A1.809,1.809,0,0,0,13.8,20h6a1.809,1.809,0,0,0,1.8-1.8V5.8A1.809,1.809,0,0,0,19.8,4Zm0,1.2h6a.591.591,0,0,1,.6.6V18.2a.591.591,0,0,1-.6.6h-6a.591.591,0,0,1-.6-.6V5.8A.591.591,0,0,1,13.8,5.2Zm3,1.2a.6.6,0,1,0,.6.6A.6.6,0,0,0,16.8,6.4Zm-1,10a.6.6,0,1,0,0,1.2h2a.6.6,0,1,0,0-1.2Z" transform="translate(-4.999)" fill="#98a0ac" />
        </svg>

    )
}
export default MobileIcon