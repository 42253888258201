export const INITIAL_TABLE_OFFSET = 0;

export const INITIAL_TABLE_PAGE = 1;

export const INITIAL_TABLE_LIMIT = 10;

export const TABLE_PATH = [
    "utility_category_name",
    "utility_name",
    "description",
    "provider",
    "service_provider_name",
    "price_per_uom",
    "is_billing",
    "is_tax_enabled",
    "hsn_sac_code",
    "tax_group_name",
    "item_type_name",
    "item_category_name",
    "item_master_name",
    "is_active",
]

export const TABLE_HEADING = (t) => [
    { title: "", field: "icon" },
    { title: t("Utility Category Name"), field: "utility_category_name" },
    { title: t("Utility Name"), field: "utility_name" },
    { title: t("Description"), field: "description" },
    { title: t("Provider"), field: "provider" },
    { title: t("Service Provider Name"), field: "service_provider_name" },
    { title: t("Price Per UOM"), field: "price_per_uom" },
    { title: t("Billing"), field: "is_billing" },
    { title: t("Tax Enabled"), field: "is_tax_enabled" },
    { title: t("HSN/SAC Code"), field: "hsn_sac_code" },
    { title: t("TAX Group Name"), field: "tax_group_name" },
    { title: t("Item Type"), field: "item_type_name" },
    { title: t("Item Category"), field: "item_category_name" },
    { title: t("Item Master"), field: "item_master_name" },
    { title: t("Status"), field: "is_active" },
]

export const TABLE_TYPE = [
    { type: ["more_4"], icon: "icon" },
    { type: ["long_text"], name: "utility_category_name" },
    { type: ["long_text"], name: "utility_name" },
    { type: ["long_text"], name: "description" },
    { type: ["text"], name: "provider" },
    { type: ["long_text"], name: "service_provider_name" },
    { type: ["text"], name: "price_per_uom" },
    { type: ["yes_no"], name: "is_billing" },
    { type: ["yes_no"], name: "is_tax_enabled" },
    { type: ["long_text"], name: "hsn_sac_code" },
    { type: ["long_text"], name: "tax_group_name" },
    { type: ["long_text"], name: "item_type_name" },
    { type: ["long_text"], name: "item_category_name" },
    { type: ["long_text"], name: "item_master_name" },
    { type: ["status"], name: "is_active" },
]

export const INITIAL_ADD_FORM_STATE = {
    form_type: "add",
    id: "",
    utility_category: "",
    utility_name: "",
    description: "",
    provider: "Internal",
    service_provider: "",
    uom: "",
    price: "",
    currency: "",
    is_billing: false,
    is_tax_enabled: false,
    hsn_sac_code: "",
    tax_group: "",
    item_type: "",
    item_category: "",
    item_master: "",
    is_active: true,
    is_delete: false,
    error: {
        utility_category: "",
        utility_name: "",
        description: "",
        service_provider: "",
        uom: "",
        price: "",
        hsn_sac_code: "",
        tax_group: "",
        item_type: "",
        item_category: "",
        item_master: "",
    }
}

export const INITIAL_FILTER_STATE = { is_active: [true] }