export const ManagedInvoices = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Invoice Manage">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 93632"
                    d="M15.644 19.813a3.536 3.536 0 0 1-1.489 2.567h6.314a2.89 2.89 0 0 0 2.868-2.567Z"
                />
                <path
                    data-name="Path 93633"
                    d="M6.664 9.264v4.778h1.924V9.225a.966.966 0 0 0-1.279-.911.983.983 0 0 0-.645.95Z"
                />
                <path
                    data-name="Path 93634"
                    d="M15.326 19.171h4.813v-9.95a1.6 1.6 0 0 0-1.6-1.6H7.626a1.6 1.6 0 0 1 1.6 1.6v10.187a2.94 2.94 0 0 0 2.3 2.913 2.893 2.893 0 0 0 3.477-2.832.319.319 0 0 1 .323-.318Zm-.953-9.625h4.171a.321.321 0 1 1 0 .642h-4.171a.321.321 0 1 1 0-.642Zm0 1.925h4.171a.321.321 0 1 1 0 .642h-4.171a.321.321 0 1 1 0-.642Zm0 1.925h4.171a.321.321 0 1 1 0 .642h-4.171a.321.321 0 1 1 0-.642Zm-2.607 0h.032a.63.63 0 0 0 .6-.389.613.613 0 0 0-.378-.8l-.611-.2a1.283 1.283 0 0 1-.854-.874 1.254 1.254 0 0 1 .921-1.543v-.369a.321.321 0 1 1 .642 0v.358a1.253 1.253 0 0 1 .963 1.216.334.334 0 0 1-.321.336.308.308 0 0 1-.321-.306.619.619 0 0 0-.611-.642h-.032a.63.63 0 0 0-.6.389.613.613 0 0 0 .378.8l.611.2a1.283 1.283 0 0 1 .854.874 1.254 1.254 0 0 1-.921 1.543v.358a.321.321 0 0 1-.642 0v-.358a1.253 1.253 0 0 1-.963-1.216.334.334 0 0 1 .321-.336.308.308 0 0 1 .321.306v.03a.611.611 0 0 0 .611.611Zm-.922 1.925h7.7a.321.321 0 1 1 0 .642h-7.7a.321.321 0 0 1 0-.642Zm0 2.567a.321.321 0 1 1 0-.642h7.7a.321.321 0 1 1 0 .642Z"
                />
            </g>
            <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
