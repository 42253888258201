export const GeneralRequest = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105161">
            <g
                data-name="LS - Agreements - General Requests"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 98197"
                    d="M21.024 7h-6.025a1.974 1.974 0 0 0-1.975 1.975v9.037a.469.469 0 0 0 .75.375l1.883-1.412h5.367A1.974 1.974 0 0 0 23 15V8.975A1.974 1.974 0 0 0 21.024 7Zm-3.013 7.716a.469.469 0 1 1 .469-.469.469.469 0 0 1-.468.468Zm.469-2.073v.1a.469.469 0 0 1-.937 0v-.5a.469.469 0 0 1 .469-.469.786.786 0 1 0-.788-.792.469.469 0 1 1-.937 0 1.724 1.724 0 1 1 2.193 1.66Zm0 0"
                />
                <path
                    data-name="Path 98198"
                    d="M12.195 16.743a2.216 2.216 0 1 1-2.216-2.216 2.216 2.216 0 0 1 2.216 2.216Zm0 0"
                />
                <path
                    data-name="Path 98199"
                    d="M9.979 18.957A2.979 2.979 0 0 0 7 21.941v.589a.469.469 0 0 0 .469.469h5.021a.469.469 0 0 0 .469-.469v-.589a2.979 2.979 0 0 0-2.98-2.984Zm0 0"
                />
            </g>
        </g>
    </svg>
)