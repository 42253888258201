import { Avatar, Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import DeleteIcon from '../../../assets/delete'
import { stringAvatar } from '../../../utils'
import { LeadCardStyle } from './style'

export const ListCard = ({
    name = "",
    url = "",
    leadNo = "",
    address = "",
    handleDelete = () => false,
    heading = "" }) => {
    const classes = LeadCardStyle()
    return (
        <>
            <Stack spacing={1} >
                <Typography className={classes.heading}>{heading}</Typography>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} className={classes.borderBox}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Avatar className={classes.avatar} src={url} {...stringAvatar(name)}></Avatar>
                        <Stack sx={{ marginInlineStart: "8px" }}>
                            <Typography className={classes.title}>{name}</Typography>
                            {leadNo?.length > 0 &&
                                <Typography className={classes.subTitle}>{leadNo}</Typography>
                            }
                            {address?.length > 0 &&
                                <Typography className={classes.subTitle}>{address}</Typography>
                            }
                        </Stack>
                    </Stack>
                    <Box onClick={() => handleDelete()} sx={{ cursor: "pointer" }}>
                        <DeleteIcon />
                    </Box>
                </Stack>
            </Stack >
        </>
    )
}
