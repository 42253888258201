import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { CardStyle } from "./style";
import { stringAvatar } from "../../utils";
export const AddMemberPopUp = (props) => {
  const classes = CardStyle();
  const { data, selectedData } = props;

  return (
    <div
      id={data?.id}
      className={classes.card1}
      onClick={() => props?.selectUser(data)}
      style={{ cursor: "pointer", borderBottom: "1px solid #E4E8EE" }}
    >
      <div className={classes.Cardcontent}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box style={{ marginRight: "4px" }}>
            <Avatar
              src={data?.image_url ?? ""}
              className={classes.avatarcard}
              {...stringAvatar(data?.first_name)}
            />
          </Box>
          <Box flexGrow={1} marginLeft="10px">
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                <Typography
                  variant="subtitle2"
                  className={classes.title1}
                  noWrap
                >
                  {data?.first_name}&nbsp;
                  {data?.last_name}
                </Typography>
              </Box>
            </Box>
            <Box
              alignItems="center"
              display="flex"
              marginTop="4px"
              style={{ flexFlow: "wrap !important" }}
              className={classes.boxes}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                  >
                    {data?.mobile_no_country_code}&nbsp;
                    {data?.mobile_no}
                  </Typography>
                </Box>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Box className={classes.dot} />
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                  >
                    {data?.email_id}
                  </Typography>
                </Box>
              </div>
            </Box>
          </Box>
          <Box>
            {selectedData?.id === data?.id && (
              <Avatar src={"/images/ticknew.svg"} className={classes.tick} />
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};
