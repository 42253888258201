import React from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  IconButton,
  Stack,
  Button,
} from "@mui/material";
import { TreeStylesParent } from "./style";
import { styled } from "@mui/material/styles";
import EditIMG from "../assests/edit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DialogBox } from "../../../components";
import moment from "moment";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#5078E1",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const PermissionCard = (props) => {
  const { t } = (props)
  const classes = TreeStylesParent();
  const [sucessDialog, setSucessDialog] = React.useState(false);

  //delete func
  const deleteFunc = () => {
    setSucessDialog(false)
    props?.deletePermission()
  }
  //delete popup
  const Proceed = (props) => {
    const { t } = props
    return (
      <div className={classes.proceedDialog}>
        <Box>
          <Typography className={classes.convert}>{props?.title}</Typography>
          <Typography className={classes.convertProcess}>
            {props?.sub}
          </Typography>
          <Box display="flex" alignItems="center" className={classes.marginTop}>
            <Button className={classes.yes} onClick={deleteFunc}>
              {t("Yes")}
            </Button>
            <Button className={classes.No} onClick={props?.noClick}>
              {t("No")}
            </Button>
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <>
      <Box
        className={
          props?.id === props?.permissionID
            ? classes.selectedpermissionBox
            : classes.permissionBox
        }
        onClick={() => props?.seclectPermission(props?.data)}
      >
        <Box display={"flex"}>
          <Stack>
            <Typography className={classes.permissionTitle}>
              {props?.title}
            </Typography>
            <Typography className={classes.permissionSubTitle}>
              {props?.data?.module?.label}
            </Typography>
            {
              <Typography className={classes.permissionSubTitle}>
                {t("last updated at")} :{moment(props?.data?.updated_at).format("YYYY-MM-DD")}
              </Typography>
            }
          </Stack>
          {props?.isNew && <>&nbsp;&nbsp;<span className={classes.new}> {"New"}</span></>}
        </Box>
        <Box className={classes.permissionEditSection}>
          <Box>
            <Stack direction={"row"}>
              {props?.copycard ? !props?.data?.is_default &&
                <IconButton disabled={props?.disabled}>
                  <DeleteForeverOutlinedIcon
                    className={classes.deleteIcon}
                    onClick={() => setSucessDialog(true)}
                  // onClick={props?.deletePermission}
                  />
                </IconButton> :
                <IconButton disabled={props?.disabled}>
                  <DeleteForeverOutlinedIcon
                    className={classes.deleteIcon}
                    onClick={() => setSucessDialog(true)}
                  />
                </IconButton>

              }
              {(props?.copycard && props?.id === props?.permissionID) &&
                <>
                  {props?.commonPermission ?
                    <IconButton disabled={props?.disabled}>
                      <ContentCopyIcon
                        onClick={() => props?.copyPermissionFunction(props?.data, "", true)}
                      />
                    </IconButton>
                    :
                    <IconButton disabled={props?.disabled}>
                      <ContentCopyIcon
                        onClick={() => props?.copyPermissionFunction(props?.data?.id, props?.data?.client_role_id, false)}
                      />
                    </IconButton>
                  }
                </>
              }
            </Stack>

          </Box>
          {!props?.commonPermission &&
            <>
              <Box sx={{ m: 1 }}>
                <EditIMG onClick={() => props?.editFunction(props?.data)} />
              </Box>
              <Box className={classes.marginLeft}>
                <FormControlLabel
                  className={classes.formLabel}
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      disabled={props?.disabled}
                      checked={props?.data?.is_active}
                      onClick={(e) =>
                        props?.activePermission(e?.target?.checked, props?.index)
                      }
                    />
                  }
                />
              </Box>
            </>
          }
        </Box>
      </Box>
      {/* sucess dialog */}
      <DialogBox
        open={sucessDialog}
        isnotTitle
        handleClose={() => setSucessDialog(false)}
        onClose={() => setSucessDialog(false)}
        maxWidth={"sm"}
        component={
          <Proceed
            t={t}
            title={t("Are you sure want to delete...?")}
            noClick={() => setSucessDialog(false)}
          />
        }
      />
    </>
  );
};
