import * as React from "react"
export const CalanderSelect = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24.6} {...props}>
    <g data-name="Group 115666">
      <path
        fill="#ff9d6c"
        d="M4.8 22.2v-12H24v12a2.407 2.407 0 0 1-2.4 2.4H7.2a2.407 2.407 0 0 1-2.4-2.4Z"
        data-name="Path 100936"
      />
      <path
        fill="#c6541b"
        d="M24 7.8v3.6H4.8V7.8a2.407 2.407 0 0 1 2.4-2.4h14.4A2.407 2.407 0 0 1 24 7.8Z"
        data-name="Path 100937"
      />
      <path
        d="M19.8 6a1.8 1.8 0 1 0 1.8 1.8A1.8 1.8 0 0 0 19.8 6ZM9.6 6a1.8 1.8 0 1 0 1.8 1.8A1.8 1.8 0 0 0 9.6 6Z"
        data-name="Path 100938"
        opacity={0.07}
      />
      <path
        fill="#b0bec5"
        d="M19.8 4.2a1.2 1.2 0 0 0-1.2 1.2v2.4a1.2 1.2 0 0 0 2.4 0V5.4a1.2 1.2 0 0 0-1.2-1.2Zm-10.2 0a1.2 1.2 0 0 0-1.2 1.2v2.4a1.2 1.2 0 0 0 2.4 0V5.4a1.2 1.2 0 0 0-1.2-1.2Z"
        data-name="Path 100939"
      />
      <path
        fill="#c6541b"
        d="M16.8 18.6h2.4V21h-2.4Zm-3.6 0h2.4V21h-2.4Zm-3.6 0H12V21H9.6Zm7.2-3.6h2.4v2.4h-2.4Zm-3.6 0h2.4v2.4h-2.4Zm-3.6 0H12v2.4H9.6Z"
        data-name="Path 100940"
      />
      <path
        fill="#e2ae24"
        d="M7.2 0a7.2 7.2 0 1 0 7.2 7.2A7.2 7.2 0 0 0 7.2 0Z"
        data-name="Path 100941"
      />
      <path
        fill="#ced3dd"
        d="M7.2 1.8a5.4 5.4 0 1 0 5.4 5.4 5.4 5.4 0 0 0-5.4-5.4Z"
        data-name="Path 100942"
      />
      <g fill="#8d6c16" data-name="Group 115660">
        <path d="M6.6 3h1.2v4.2H6.6Z" data-name="Path 100943" />
        <path
          d="m9.91 9.061-.806.806-2.29-2.29.805-.807Z"
          data-name="Path 100944"
        />
        <path
          d="M7.2 6.3a.9.9 0 1 0 .9.9.9.9 0 0 0-.9-.9Z"
          data-name="Path 100945"
        />
      </g>
    </g>
  </svg>
)

export const CalanderUnSelect = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24.6} {...props}>
    <g data-name="Group 115666">
      <path
        fill="#ced3dd"
        d="M4.8 22.2v-12H24v12a2.407 2.407 0 0 1-2.4 2.4H7.2a2.407 2.407 0 0 1-2.4-2.4Z"
        data-name="Path 100936"
      />
      <path
        fill="#98a0ac"
        d="M24 7.8v3.6H4.8V7.8a2.407 2.407 0 0 1 2.4-2.4h14.4A2.407 2.407 0 0 1 24 7.8Z"
        data-name="Path 100937"
      />
      <path
        fill="#37474f"
        d="M19.8 6a1.8 1.8 0 1 0 1.8 1.8A1.8 1.8 0 0 0 19.8 6ZM9.6 6a1.8 1.8 0 1 0 1.8 1.8A1.8 1.8 0 0 0 9.6 6Z"
        data-name="Path 100938"
      />
      <path
        fill="#b0bec5"
        d="M19.8 4.2a1.2 1.2 0 0 0-1.2 1.2v2.4a1.2 1.2 0 0 0 2.4 0V5.4a1.2 1.2 0 0 0-1.2-1.2Zm-10.2 0a1.2 1.2 0 0 0-1.2 1.2v2.4a1.2 1.2 0 0 0 2.4 0V5.4a1.2 1.2 0 0 0-1.2-1.2Z"
        data-name="Path 100939"
      />
      <path
        fill="#98a0ac"
        d="M16.8 18.6h2.4V21h-2.4Zm-3.6 0h2.4V21h-2.4Zm-3.6 0H12V21H9.6Zm7.2-3.6h2.4v2.4h-2.4Zm-3.6 0h2.4v2.4h-2.4Zm-3.6 0H12v2.4H9.6Z"
        data-name="Path 100940"
      />
      <path
        fill="#f2f4f7"
        d="M7.2 0a7.2 7.2 0 1 0 7.2 7.2A7.2 7.2 0 0 0 7.2 0Z"
        data-name="Path 100941"
      />
      <path
        fill="#eee"
        d="M7.2 1.8a5.4 5.4 0 1 0 5.4 5.4 5.4 5.4 0 0 0-5.4-5.4Z"
        data-name="Path 100942"
      />
      <g fill="#98a0ac" data-name="Group 115660">
        <path d="M6.6 3h1.2v4.2H6.6Z" data-name="Path 100943" />
        <path
          d="m9.91 9.061-.806.806-2.29-2.29.805-.807Z"
          data-name="Path 100944"
        />
        <path
          d="M7.2 6.3a.9.9 0 1 0 .9.9.9.9 0 0 0-.9-.9Z"
          data-name="Path 100945"
        />
      </g>
    </g>
  </svg>
)

export const ClockSlected = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 115667">
      <path
        fill="#a1c56a"
        d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0Z"
        data-name="Path 100946"
      />
      <path
        fill="#eee"
        d="M12 3a9 9 0 1 0 9 9 9 9 0 0 0-9-9Z"
        data-name="Path 100947"
      />
      <g fill="#588218" data-name="Group 115661">
        <path d="M11 5h2v7h-2Z" data-name="Path 100943" />
        <path
          d="m16.516 15.102-1.343 1.343-3.818-3.817 1.343-1.344Z"
          data-name="Path 100944"
        />
        <path
          d="M12 10.5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5Z"
          data-name="Path 100945"
        />
      </g>
    </g>
  </svg>
)

export const ClockUnSlected = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 115667">
      <path
        fill="#a1c56a"
        d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0Z"
        data-name="Path 100946"
      />
      <path
        fill="#eee"
        d="M12 3a9 9 0 1 0 9 9 9 9 0 0 0-9-9Z"
        data-name="Path 100947"
      />
      <g fill="#588218" data-name="Group 115661">
        <path d="M11 5h2v7h-2Z" data-name="Path 100943" />
        <path
          d="m16.516 15.102-1.343 1.343-3.818-3.817 1.343-1.344Z"
          data-name="Path 100944"
        />
        <path
          d="M12 10.5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5Z"
          data-name="Path 100945"
        />
      </g>
      <path
        fill="#ced3dd"
        d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0Z"
        data-name="Path 100946"
      />
      <path
        fill="#eee"
        d="M12 3a9 9 0 1 0 9 9 9 9 0 0 0-9-9Z"
        data-name="Path 100947"
      />
      <g fill="#98a0ac" data-name="Group 115661">
        <path d="M11 5h2v7h-2Z" data-name="Path 100943" />
        <path
          d="m16.516 15.102-1.343 1.343-3.818-3.817 1.343-1.344Z"
          data-name="Path 100944"
        />
        <path
          d="M12 10.5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5Z"
          data-name="Path 100945"
        />
      </g>
    </g>
  </svg>
)
