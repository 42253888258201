import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MyLocationIcon from "@mui/icons-material/MyLocation";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from '../../../assets';
import { CustomMap, Location, SelectBox } from "../../../components";
import { useWindowDimensions } from "../../../utils";
import { LocationTextField } from "../../propertyFinder4/components";
import { useStylesCreation } from "./style";
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';


export const MapComponent = (props) => {

  // eslint-disable-next-line
  const { t } = props;
  const classes = useStylesCreation(props);
  const size = useWindowDimensions();
  const [location, setLocation] = useState({});
  const [state, setState] = useState({
    value: 0,
    zoom: null
  })

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(getCoordinates);
  };
  const getCoordinates = (position) => {
    setLocation({
      latitude: position?.coords?.latitude,
      longitude: position?.coords?.longitude,
    });
  };
  useEffect(() => {
    getLocation();
    // eslint-disable-next-line
  }, []);

  const zoomFunction = (value, bool) => {
    setState({
      zoom: bool,
      value
    })
  }



  return (
    <div className={classes.card}>
      {
        (location?.latitude && location?.longitude) ?
          <>
            <Box className={classes.flexBoxWithSpace}>
              {props?.title &&
                <Box>
                  <Typography className={classes.titleFont}>{props?.title}</Typography>
                </Box>
              }
              <Box>
                <TabComponent
                  tabList={props?.tabList}
                  tabNameState={props?.tabNameState}
                  keyType={props?.keyType}
                  updateState={props?.updateState}
                />
              </Box>

              {props?.isZoom && (
                // <img
                //   src="/images/propertdashboard/enlare.png"
                //   alt="zoom"
                //   onClick={() => props?.onZoomClick(props?.title)}
                // />
                <>

                  <Box className={classes.viewMap} onClick={() => props?.onZoomClick(props?.title)}>
                    <Stack direction="row" spacing={0.2} alignItems={"center"}>
                      <img
                        src="/images/propertdashboard/LocationOn.svg"
                        alt="location" />
                      <Typography className={classes.viewMaptxt}>{t("View in Map")}</Typography>
                    </Stack>
                  </Box>
                </>
              )}
              {
                props?.is_close &&
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                  <Box minWidth={"300px"}>
                    <SelectBox
                      value={props?.data?.selectedProperty}
                      loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                          "property/get_company_props",
                          {
                            company_id: props?.company_id
                          },
                          search,
                          array,
                          handleLoading,
                          "props",
                          {}
                        )
                      }
                      isPaginate={true}
                      isRequired
                      onChange={(val) => props?.updateState("selectedProperty", val)}
                      menuPlacement={"bottom"}
                      menuOptionHeight={"140px"}
                      errorMessage={props?.data?.error?.category}
                      isError={props?.data?.error?.category?.length > 0}
                      debounceTimeout={800}

                    />
                  </Box>

                  <Box>

                    <IconButton onClick={() => props?.onZoomClick(props?.title)}><CancelOutlinedIcon />   </IconButton>
                  </Box>
                </Box>
              }


            </Box>

            <div
              className={classes.map}
              style={{ height: (props?.isZoom || props?.is_close) ? null : size?.height - 200 }}
            >
              <div className={classes.searchBox}>
                {/* search field */}
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={props?.zoom ? 6 : 10}>
                    <Location
                      value={props?.data?.location}
                      handleChange={(value) => props?.updateState("location", value)}
                      textFieldComponent={(ref, value, handleChange) => (
                        <LocationTextField
                          label={t("Location")}
                          inputRef={ref}
                          handleChange={handleChange}
                          value={value}
                          placeholder={t("Select City")}
                          isPointer
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton className={classes.finder} onClick={props?.sumbit}>
                      <MyLocationIcon style={{ color: "#fff" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
              {/* map */}
              {!props?.mapLoading && (
                <CustomMap
                  map_id="dashboad_map"
                  lat={
                    props?.data?.location?.latitude ??
                    Number(
                      props?.mapData?.[0]?.address?.latitude ?? location?.latitude
                    )
                  }
                  lang={
                    props?.data?.location?.longitude ??
                    Number(
                      props?.mapData?.[0]?.address?.longitude ?? location?.longitude
                    )
                  }
                  radius={0}
                  list={props?.mapData ?? []}
                  zoom={10}
                  hideControl={true}
                  status={
                    props?.tabNameState === "Occupied"
                      ? "Occupied Units"
                      : "Vacant Units"
                  }
                  isDashBoard
                  mapIcon={
                    props?.tabNameState === "Occupied"
                      ? "images/occupiedmap.svg"
                      : "images/vacantmap.svg"
                  }
                  state={state}
                  is_zoom={true}

                />
              )}
              {/*  */}
              <Box className={classes.weatherBox}>
                <Typography className={classes.totalUnit}>
                  {props?.total ?? 0}
                </Typography>
                <Box className={classes.dotParent}>
                  <Box
                    className={classes.dot}
                    style={{
                      backgroundColor:
                        props?.tabNameState === "Occupied" ? "red" : "green",
                    }}
                  />
                  <Box>
                    <Typography className={classes.graphDataTitle}>
                      {t("Total")}&nbsp;
                      {props?.tabNameState === "Occupied" ? "Occupied" : "Vacant"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <span className={classes.zoomInOut}>
                <div className="zoom-control">
                  <div className="plus" onClick={() => {
                    zoomFunction(state + 5, true)
                  }}>
                    <PlusIcon />
                  </div>
                  <div className="minus" onClick={() => {
                    zoomFunction(state - 5, false)
                  }}>
                    <MinusIcon />
                  </div>
                </div>
              </span>
            </div>
          </>
          :
          <Box className={classes.mapRoot}>
            <Typography className={classes.titleFont}>{t("Please Check Your Location")}</Typography>
          </Box>
      }


    </div >
  );
};

// tab component
const TabComponent = (props) => {
  const classes = useStylesCreation(props);
  return (
    <List className={classes.list}>
      {props?.tabList?.map((x) => {
        return (
          <ListItem
            className={
              x === props?.tabNameState ? classes.listText : classes.listText1
            }
            onClick={() => props?.updateState(props?.keyType, x)}
          >
            <Typography className={classes.tabname}>{x}</Typography>
          </ListItem>
        );
      })}
    </List>
  );
};