import { Paper } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { InventoryHeading, InventoryPath, InventoryType } from "../.././utils/inventory"
import { TableWithPagination } from "../../components"
import InventorySearchBar from "./inventorySearchBar"
import { InspectionMasterStyles } from "./style"


const TableComp = ({ t, handleTableIcon, handleAdd = () => false, data = [], handleSearch = () => false, searchText = "",
    openfilter = () => false, handlePagination = () => false, handleChangeLimit = () => false, page = "", limit = "", totalRows = "", permissions = {} }) => {
    const classes = InspectionMasterStyles()
    const Heading = InventoryHeading(t)
    return (
        <>
            <Paper className={classes.roots}>
                <InventorySearchBar
                    handleAdd={handleAdd}
                    handleSearch={handleSearch}
                    searchText={searchText}
                    permissions={permissions}
                    openfilter={openfilter}
                />
                <TableWithPagination
                    handleIcon={handleTableIcon}
                    // onClick={handleClick}
                    heading={Heading}
                    rows={data}
                    path={InventoryPath}
                    showpagination={true}
                    tableType="no-side"
                    dataType={InventoryType}
                    totalRowsCount={totalRows}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    page={page}
                    limit={limit}
                    height={'calc(100vh - 290px)'} />
            </Paper>

        </>
    )
}
export default withNamespaces("inspection")(TableComp)