import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useStyles } from "./styles";


export const MyBookingCard = (props) => {
    const classes = useStyles()
    return (
        <>
            <Box className={classes.root}>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={3}>
                        <Stack direction={"row"} alignItems={"center"} >
                            <Avatar
                                variant="square"
                                src={props?.image_url ?? "/images/amenityMiniPlaceholder.png"}
                                className={classes.image}
                            />
                            <Stack className={classes?.titleStack}>
                                <Typography className={classes.text1}>
                                    {props?.label1}
                                </Typography>
                                <Typography className={classes.text2}>
                                    {props?.value1 ?? " - "}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack direction={"row"} alignItems={"center"} >
                            <Stack className={classes?.titleStack}>
                                <Typography className={classes.text1}>
                                    {props?.label2}
                                </Typography>
                                <Typography className={classes.text2}>
                                    {props?.value2 ?? " - "}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack direction={"row"} alignItems={"center"} >
                            <Stack className={classes?.titleStack}>
                                <Typography className={classes.text1}>
                                    {props?.label3}
                                </Typography>
                                <Typography className={classes.text2}>
                                    {props?.value3 ?? " - "}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack direction={"row"} alignItems={"center"} >
                            <Stack className={classes?.titleStack}>
                                <Typography className={classes.text1}>
                                    {props?.label4}
                                </Typography>
                                <Typography className={classes.text2}>
                                    {props?.value4 ?? " - "}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>



                </Grid>
            </Box>
            {props?.hidden && <Divider className={classes.Divider} />}
        </>
    );
};


