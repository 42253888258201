import React from "react";
import { withNavBars } from "../../HOCs";
import AgreementTermination from "./agreementTermination";

class AgreementTerminationParent extends React.Component {
  render() {
    return <AgreementTermination />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(AgreementTerminationParent, props);
