import { Avatar, Box, Typography } from "@mui/material";
import { stringAvatar } from "../../../utils";
import { TickIcon } from "../../svg";
import { useStyles } from "../styles";
export const Card = ({ data, selected }) => {
    const classes = useStyles()
    return (
        <Box display="flex" alignItems="center">
            <Box>
                <Avatar src={data?.url}  {...stringAvatar(data?.label)} />
            </Box>
            <Box flexGrow={1} marginInlineStart="12px">
                <Typography className={classes.name}>{data?.label}</Typography>
                <Typography className={classes.email}>{data?.label1}</Typography>
            </Box>
            {
                selected &&
                <Box>
                    <TickIcon />
                </Box>

            }

        </Box>

    )
}