import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useStyles } from "./styles";
export const PricingDetailsTable = ({ t, val, applyDiscount, onChangeDiscountValue }) => {
    const classes = useStyles()

    return (
        <Box className={classes.pricing} display="flex" justifyContent="space-between">
            <Box> <Typography className={classes.priceName}>{val?.PCname}</Typography>
                {
                    val?.discountValue > 0 && <Typography className={classes.discountName}>{t("Discount")}</Typography>
                }
                <Typography className={classes.discountName}>{t("Tax Amount")}</Typography>

            </Box>
            <Box alignItems="flex-end">
                {
                    val?.discountValue > 0 ?
                        applyDiscount ?
                            <Typography className={classes.priceValue}>
                                {val?.currencySymbol}&nbsp;{Math.round(val?.discountPrice)}</Typography>
                            :
                            <Typography noWrap className={classes.discountValue1}>
                                <span className={classes.discountValue2}> {val?.amount}</span>
                                &nbsp;{val?.currencySymbol} {Math.round(val?.discountPrice)}</Typography>
                        : <Typography noWrap className={classes.priceValue}>{val?.amount}</Typography>
                }

                {
                    applyDiscount ?
                        (
                            <TextField
                                variant="standard"
                                autoComplete="off"
                                inputProps={{ minlength: 0, maxLength: 3 }}
                                sx={[{
                                    width: "40px",
                                    float: "right",
                                    marginBottom: "6px",
                                    '& input': {
                                        fontSize:"0.75rem",

                                    }
                                }
                                ]}
                                fullWidth
                                type="number"
                                value={val?.discountValue}
                                onChange={(e) => onChangeDiscountValue(e.target.value)}
                                InputProps={
                                    {
                                        endAdornment: <InputAdornment
                                            sx={[{

                                                '& p': {
                                                    fontSize:"0.75rem"
                                                }
                                            }
                                            ]}
                                            position="end">%</InputAdornment>,
                                    }
                                }
                            />
                        )
                        : <>
                            {
                                val?.discountValue > 0 && <Typography className={classes.discountValue}>{val?.discountValue}%</Typography>
                            }
                        </>
                }<Typography className={classes.discountValue}>{val?.currencySymbol}&nbsp;{Math.round(val?.appliedTaxAmount)}</Typography>


            </Box>


        </Box>
    )
}