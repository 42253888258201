import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold, Regular } from "../../utils";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white",
    padding: "16px 0px 16px 16px",
    margin: "14px",
    height: `calc(100vh - 147px)`,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "13px",
  },
  outerCircle: {
    padding: "2px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
  },
  tableMain: {
    padding: "0px 16px !important",
  },
  PropertyName: {
    color: theme?.palette?.upload,
    fontSize:"1rem",
    fontFamily: Bold,
    marginInlineStart: "10px",
  },
  graftParent: {
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    borderRadius: 4,
    padding: "10px",
    margin: "16px 0px 2px",
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
  },
  catagory: {
    color: theme?.typography?.color?.tertiary,
    fontSize:"0.75rem",
    fontFamily: SemiBold,
  },
  catagoryName: {
    color: theme?.typography?.color?.primary,
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    marginTop: "5px",
    direction: "ltr",
    textAlign: "-webkit-match-parent"
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
  addressDeatils: {
    color: theme?.typography?.color?.secondary,
    fontSize:"0.875rem",
    fontFamily: Regular,
    marginInlineStart: "7px",
  },
  addressDetailsBox: {
    padding: "10px 0px 13px",
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
  },
  addressBoxFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addressflexBox: {
    display: "flex",
    paddingBottom: "3px",
  },
  resultValue: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    marginBottom: "4px",
    marginTop: "5px",
  },
  bathDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0px 13px",
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
    borderTop: `1px solid ${theme?.palette?.border?.secondary}`,
  },
  bedText: {
    color: theme?.typography?.color?.primary,
    fontFamily: SemiBold,
    fontSize:"0.75rem",
    marginInlineStart: "7px",
  },
  dot: {
    borderRadius: "50%",
    width: "6px",
    height: "6px",
    background: theme?.palette?.border?.primary,
    marginRight: "7px",
  },
  totalAmountParent: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px 13px",
    borderTop: `1px solid ${theme?.palette?.border?.secondary}`,
  },
  padding: {
    padding: "10px 0px 13px",
  },
  amountCard: {
    borderRadius: "4px",
    padding: "10px",
    backgroundColor: theme?.palette?.background?.secondary,
  },
  amountTitle: {
    color: theme?.typography?.color?.tertiary,
    fontFamily: Regular,
    fontSize:"0.75rem",
  },
  amountContant: {
    fontFamily: SemiBold,
    fontSize:"0.875rem",
  },
  amountParnt: {
    padding: "16px 0px",
  },
  borderLeft: {
    borderInlineStart: `1px solid ${theme?.palette?.border?.secondary}`,
    padding: "0px 16px",
    overflow:"auto"
  },
  noPadding: {
    padding: "14px 0px 0px !important",
  },
  paddingLeft: {
    paddingLeft: "0px !important",
  },
  next: {
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "14px",
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  btnParent: {
    padding: "16px",
    backgroundColor: theme?.palette?.background?.tertiary1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export const useStylesForm = makeStyles((theme) => ({
  addQuestionCard2: {
    cursor: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
  },
  addQuestionContent1: {
    marginRight: "12px",
    display: "flex",
    alignItems: "center",
  },
  addQuestionContent2: {
    display: "flex",
    alignItems: "center",
  },

  addNewDialogQuestionText: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
  },
  addNewDialogInfoIcon2: {
    display: "flex",
    alignItems: "center",
  },
  addNewDialogFormContent: {
    padding: "24px",
  },
  addNewDialogFormFieldLabel: {
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    fontSize:"0.875rem",
    marginBottom: "5px",
  },
  addNewDialogFormButtonUnSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme?.palette?.border?.secondary,
    },
  },
  addNewDialogFormButtonTextUnSelected: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    color: theme?.typography?.color?.secondary,
  },
  addNewDialogFormButtonSelected: {
    borderRadius: theme.palette.borderRadius,
    height: "45px",
    border: `1px solid ${theme?.palette?.primary?.main}`,
    backgroundColor: theme?.palette?.primary?.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  addNewDialogFormButtonTextSelected: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    color: "white",
  },
  progressBar:{
    borderRadius:'100px'
  },
  next: {
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "14px",
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "12px",
    backgroundColor: "#FFFFFF",
    color: "#4E5A6B",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
    fontSize:"0.875rem",
    fontFamily: SemiBold,
  },
}));
