import { Box } from "@mui/material";
// import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dateTimepicker.css";
export const DateTimePicker = ({ dateTime = "", handleDateChange = () => false, isReadOnly = false }) => {
  return (

    <Box mt={"6px"} >
      <DatePicker
        selected={dateTime}
        onChange={date => handleDateChange(date)}
        showTimeSelect
        peekNextMonth
        disabled={isReadOnly}
        showMonthDropdown
        showYearDropdown
        closeOnScroll={true}
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="dd MMM yy h:mm aa"
        yearDropdownItemNumber={70}
        // dropdownMode="select"
        minDate={new Date()}
      />
    </Box>
  );
}