import React from 'react'
import { Box, Typography, Stack, Divider } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CalendarDropDownStyles } from './style';

export const CalendarDropdown = ({ open = {}, selected = {}, showAll = () => false, setDropdownValue = {}, rightalign = "", right = false, select = "" }) => {
    const classes = CalendarDropDownStyles(right)
    const calendarType = ["Daily", "Weekly", "Monthly"]
    const calendarTypeForParking = ["Weekly", "Monthly"]

    React.useEffect(() => {
        if (select === "P") {
            setDropdownValue("Daily")
        }
        // eslint-disable-next-line
    }, [select])
    return (
        <Box
            p={1} ml={1}
            className={right ? classes.dropdown2 : classes.dropdown}
            sx={{ right: rightalign }}
        >
            {select === "P" ?
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography > {"Daily"} </Typography>
                    <Box pl={2}>
                        {
                            <KeyboardArrowDownIcon />
                        }
                    </Box>
                </Stack>
                :
                <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={showAll} >
                    <Typography > {selected} </Typography>
                    <Box pl={2}>
                        {
                            open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                        }
                    </Box>
                </Stack>
            }
            {
                open ?
                    <Box className={right && classes.bottom}>
                        <Box mt={1} mb={1}>
                            <Divider />
                        </Box>

                        <Stack spacing={1}>
                            {
                                right ? calendarTypeForParking.map(e => {
                                    return (
                                        <>
                                            <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={() => setDropdownValue(e)}>
                                                <Typography className={classes.selected}> {e} </Typography>
                                            </Stack>
                                        </>
                                    )
                                })
                                    :
                                    calendarType.map(e => {
                                        return (
                                            <>
                                                <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={() => setDropdownValue(e)}>
                                                    <Typography className={classes.selected}> {e} </Typography>
                                                </Stack>
                                            </>
                                        )
                                    })
                            }
                        </Stack>
                    </Box>
                    : " "
            }
        </Box>
    )
}