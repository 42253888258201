import * as React from "react"
const TenentActiveIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x2={1}
        y1={705.5}
        y2={705.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c8d3de" />
        <stop offset={1} stopColor="#c8d3de" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.225}
        x2={0.705}
        y1={78.537}
        y2={79.466}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fcfcfc" />
        <stop offset={0.495} stopColor="#f4f4f4" />
        <stop offset={0.946} stopColor="#e8e8e8" />
        <stop offset={1} stopColor="#e8e8e8" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={0.5}
        x2={0.5}
        y1={134.277}
        y2={135.326}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#d43a02" />
        <stop offset={1} stopColor="#b9360c" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0.395}
        x2={0.532}
        y1={205.541}
        y2={206.635}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#33bef0" />
        <stop offset={1} stopColor="#0a85d9" />
      </linearGradient>
      <radialGradient
        id="a"
        cx={0.072}
        cy={0.078}
        r={1.567}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c26715" />
        <stop offset={0.508} stopColor="#b85515" />
        <stop offset={1} stopColor="#ad3f16" />
      </radialGradient>
      <radialGradient xlinkHref="#a" id="f" cx={0.297} cy={0.562} r={4.159} />
    </defs>
    <g data-name="Group 113970">
      <g data-name="icons8-caretaker (2)">
        <path
          fill="url(#b)"
          d="M22 40H6v.889a.889.889 0 0 0 .889.889h14.222a.889.889 0 0 0 .889-.889Z"
          data-name="Path 100595"
          transform="translate(-2.742 -21.556)"
        />
        <path
          fill="url(#c)"
          d="M22 20H6v-8.444L14 4l8 7.556Z"
          data-name="Path 100596"
          transform="translate(-2.742 -1.556)"
        />
        <path
          fill="#de490d"
          d="M9.035 12.222h4.444a.445.445 0 0 1 .444.444v7.556H8.591v-7.556a.445.445 0 0 1 .444-.444Z"
          data-name="Path 100597"
        />
        <path
          d="M11.258 2.889a.887.887 0 0 0-.589.224L8.287 5.251 3.258 10v2.4l8-7.094 8 7.094V10l-5.029-4.749-2.382-2.138a.887.887 0 0 0-.589-.224Z"
          data-name="Path 100598"
          opacity={0.05}
        />
        <path
          d="M11.258 2.444a.89.89 0 0 0-.589.222L8.287 4.792 3.258 9.515v2.382l8-7.053 8 7.054V9.515l-5.029-4.723-2.382-2.126a.89.89 0 0 0-.589-.222Z"
          data-name="Path 100599"
          opacity={0.07}
        />
        <path
          fill="url(#d)"
          d="m21.537 10.781-8.52-7.557a.889.889 0 0 0-1.179 0l-8.52 7.557a.444.444 0 0 0-.028.636l.727.776a.444.444 0 0 0 .618.03l7.792-6.809 7.792 6.81a.445.445 0 0 0 .618-.03l.727-.776a.444.444 0 0 0-.027-.637Z"
          data-name="Path 100600"
          transform="translate(-1.17 -1)"
        />
        <path
          fill="url(#e)"
          d="M32.444 39.429c-2.454 0-4.444 1.446-4.444 2.936v.428a.445.445 0 0 0 .444.444h8a.445.445 0 0 0 .444-.444v-.428c.001-1.49-1.988-2.936-4.444-2.936Z"
          data-name="Path 100601"
          transform="translate(-14.964 -21.238)"
        />
        <path
          fill="#ecc255"
          d="M18.814 18.267a1.367 1.367 0 0 1-2.667 0v-1.876h2.667Z"
          data-name="Path 100602"
        />
        <path
          fill="#ecc255"
          d="M19.744 15.333h-.335v1.333h.38a.278.278 0 0 0 .264-.247l.088-.568a.434.434 0 0 0-.397-.518Z"
          data-name="Path 100603"
        />
        <path
          fill="#ecc255"
          d="M15.214 15.333h.335v1.333h-.38a.278.278 0 0 1-.264-.247l-.091-.567a.434.434 0 0 1 .4-.519Z"
          data-name="Path 100604"
        />
        <path
          fill="#ffd976"
          d="M17.48 12.37c-1.1 0-2.222.167-2.222 2.537v1.654c0 .847 1.333 1.884 2.222 1.884s2.222-1.037 2.222-1.883v-1.655c0-2.37-1.125-2.537-2.222-2.537Z"
          data-name="Path 100605"
        />
        <path
          fill="url(#a)"
          d="M34.667 25c-1.061 0-1.333.444-1.333.444-.635 0-1.333.42-1.333 1.778a8 8 0 0 0 .444 2.222c.152-1.958.466-2.928.952-2.928.352 0 .668.261 1.27.261a6.965 6.965 0 0 0 1.27-.261c.833 0 .952 2.619.952 2.928a8.429 8.429 0 0 0 .444-2.222c0-1.369-.941-2.222-2.666-2.222Z"
          data-name="Path 100606"
          transform="translate(-17.187 -13.222)"
        />
        <path
          fill="url(#f)"
          d="M36.332 25c-1.156 0-1.333.444-1.333.444a1.951 1.951 0 0 0 .064.572s.2.762 1.4.762A1.679 1.679 0 0 0 38.11 25Z"
          data-name="Path 100607"
          transform="translate(-18.852 -13.222)"
        />
      </g>
    </g>
  </svg>
)
export default TenentActiveIcon
