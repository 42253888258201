import * as React from "react"

export const UnitCategory = (props) => {

    const {
        width = 16,
        height = 16,
    } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={width}
            height={height}
            {...props}
        >
            <g id="Group_97962" data-name="Group 97962" transform="translate(-0.001 15.85)">
                <g id="Group_96235" data-name="Group 96235">
                    <g id="Rectangle_51039" data-name="Rectangle 51039" transform="translate(0.001 -15.85)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                        <rect width="16" height="16" stroke="none" />
                        <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
                    </g>
                    <path id="icons8-apartment" d="M8,4a.536.536,0,0,0-.389.165l-3.846,4A2.949,2.949,0,0,0,3,10.218v7.175a.939.939,0,0,0,.188.576.793.793,0,0,0,.619.317h8.387a.793.793,0,0,0,.619-.317A.938.938,0,0,0,13,17.393V10.218a2.949,2.949,0,0,0-.769-2.054l-3.846-4A.536.536,0,0,0,8,4Zm5,.007a.533.533,0,0,0-.386.158L11,5.848l.744.773L13,5.309l3.46,3.6a1.923,1.923,0,0,1,.469,1.311v7H13.714v.179a1.681,1.681,0,0,1-.25.893h3.73A.844.844,0,0,0,18,17.393V10.218a2.972,2.972,0,0,0-.768-2.054l-3.846-4A.533.533,0,0,0,13,4.007Zm-5,1.3,3.46,3.6a1.948,1.948,0,0,1,.469,1.311v7H4.071v-7A1.948,1.948,0,0,1,4.54,8.907ZM6.214,10.071a.714.714,0,1,0,.714.714A.714.714,0,0,0,6.214,10.071Zm3.571,0a.714.714,0,1,0,.714.714A.714.714,0,0,0,9.786,10.071Zm5,0a.714.714,0,1,0,.714.714A.714.714,0,0,0,14.786,10.071Zm-8.571,2.5a.714.714,0,1,0,.714.714A.714.714,0,0,0,6.214,12.571Zm3.571,0a.714.714,0,1,0,.714.714A.714.714,0,0,0,9.786,12.571Zm5,0a.714.714,0,1,0,.714.714A.714.714,0,0,0,14.786,12.571Zm-8.571,2.5a.714.714,0,1,0,.714.714A.714.714,0,0,0,6.214,15.071Zm3.571,0a.714.714,0,1,0,.714.714A.714.714,0,0,0,9.786,15.071Zm5,0a.714.714,0,1,0,.714.714A.714.714,0,0,0,14.786,15.071Z" transform="translate(-2.5 -19.351)" fill="#98a0ac" />
                </g>
            </g>
        </svg>
    )
}
