/* eslint-disable array-callback-return */
import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CustomCheckBox, TextBox } from "../../../components";
import { stringAvatar, useWindowDimensions } from "../../../utils";
import { SearchFilter } from "../../../components";
import { componentStyles } from "./sytle";
export const TeamList = ({ title = "Teams",
    list = [],
    selected = 1,
    name = "",
    handleSearch = () => false, searchText = "",
    isSearch = false, setSelected = () => false,
    contactSetting = false,
    fetchMoreData = () => false,
    seacrh = true,
    styles = false,
    addTeam = () => false,
    changeStatus = () => false,
    permission = {},
    t=()=>false
}) => {
    const classes = componentStyles()
    const [search, setSearch] = React.useState("")
    const size = useWindowDimensions()
    return (
        <>
            {!contactSetting ? (
                <Box className={classes.listRoot}>
                    {!seacrh && (
                        <>
                            <TextBox
                                onChange={(e) => setSearch(e.target.value)}
                                label={false}
                                placeholder={t("Search Settings")}
                                color="#F5F7FA"
                            />
                            <Box height="12px" />
                        </>
                    )}

                    {list
                        ?.filter((s) => {
                            if (s === "") {
                                return s;
                            } else if (
                                s?.label?.toLowerCase()?.includes(search?.toLowerCase())
                            ) {
                                return s;
                            }
                        })
                        ?.map((val) => {
                            return (
                                <Typography
                                    onClick={() => setSelected(val)}
                                    className={
                                        selected === val?.value
                                            ? classes.labelSelected
                                            : classes.lable
                                    }
                                >
                                    {val?.label}
                                </Typography>
                            );
                        })}
                </Box>
            ) : (
                <>
                    <Box
                        className={classes.boxRoot}
                    >
                        <Box>

                            <Typography className={classes.heading}>{t(`${title}`)}</Typography>
                        </Box>
                        {permission.create &&
                            <Box>
                                <Button
                                    variant="contained"
                                    className={classes.btn3}
                                    onClick={() => addTeam("add")}
                                >
                                    {t("Add Team")}
                                </Button>

                            </Box>
                        }
                    </Box>

                    {isSearch === true &&
                        <Box margin={"16px 16px 0px 16px"}>
                            <SearchFilter
                                placeholder={name}
                                handleChange={(value) => handleSearch(value)}
                                value={searchText}
                            />
                        </Box>
                    }
                    <InfiniteScroll
                        dataLength={list?.length ?? ""}
                        next={fetchMoreData}
                        hasMore={true}
                        height={size?.height - 278}
                    >
                        <Box className={styles === "true" ? classes.listRoot : classes.newListRoot}>
                            {list?.length > 0 && list?.map((val) => {
                                return (
                                    <Box
                                        display={"flex"}
                                        onClick={() => setSelected(val)}
                                        className={
                                            selected === val?.id
                                                ? classes.labelSelectedContact
                                                : classes.lableContact
                                        }
                                    >
                                        <Box>
                                            {/* {val?.image_url ? ( */}
                                                <Avatar src={val?.image_url ?? val?.logo}  {...stringAvatar(val?.first_name ?? val?.name)}/>
                                            {/* ) : val?.logo ? (
                                                <Avatar src={val?.logo} />
                                            ) : (
                                                <Avatar />
                                            )} */}
                                        </Box>
                                        <Box className={classes.listBox}>
                                            <Box>
                                                <Typography
                                                    className={
                                                        classes.lableContact
                                                    }
                                                >
                                                    {val?.first_name && val?.first_name}
                                                    {val?.name && val?.name}

                                                    <Typography
                                                        className={
                                                            classes.jobText
                                                        }
                                                    >
                                                        {val?.module_name && (val?.module_name ?? "-")}{" "}

                                                    </Typography>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <CustomCheckBox
                                                    checked={val?.is_active} onChange={() => changeStatus(val)}
                                                />
                                            </Box>


                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </InfiniteScroll>
                </>
            )}
        </>
    );
}