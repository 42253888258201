import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Stack, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { CustomDropDownStyles } from './style';
import { SemiBold } from '../../../utils';
const calendarType = ["Daily", "Weekly", "Monthly"]

export const CustomDropDown = ({ type = "", setType = "", t = () => false }) => {
    const classes = CustomDropDownStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const setDropdownValue = (value) => {
        setType(value)
        setAnchorEl(null);
    }

    return (
        <Box p={1} ml={1} className={classes.customdrp}>
            <Stack direction={"row"}
                spacing={1}
                alignItems={"center"}
                onClick={handleClick}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >

                <Typography > {t(type)} </Typography>

                <Box pt={"6px"} paddingInlineStart={2}>
                    {
                        open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                    }
                </Box>
            </Stack>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        width: 120,
                        marginTop: "6px",
                        marginLeft: "-8px"

                    },
                }}
            >
                {
                    calendarType.map(e => {
                        return (
                            <MenuItem onClick={() => setDropdownValue(e)} style={{ backgroundColor: "white" }}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography className={classes.selected} style={{ fontSize: "0.875rem", fontFamily: SemiBold }}>{t(e)}</Typography>

                                </Stack >
                            </MenuItem >
                        )
                    })
                }
                {/* <MenuItem onClick={handleClose} style={{ backgroundColor: "white" }}>
                    <Stack spacing={1}>
                        {
                            calendarType.map(e => {
                                return (
                                    <>
                                        <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={() => setDropdownValue(e)}>
                                            <Typography className={classes.selected} style={{ fontSize: "0.875rem", fontFamily: SemiBold }}> {e} </Typography>

                                        </Stack >
                                    </>
                                )
                            })
                        }
                    </Stack >
                </MenuItem > */}
            </Menu >
        </Box >
    )
}