import React from "react"

export const ProjectedBomAmount = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_118506" data-name="Group 118506" transform="translate(-197 -106)">
          <g id="Rectangle_52035" data-name="Rectangle 52035" transform="translate(197 106)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="24" height="24" stroke="none"/>
            <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
          </g>
          <path id="icons8-bill_14_" data-name="icons8-bill (14)" d="M8.884,5A.868.868,0,0,0,8,5.868V23.815A3.2,3.2,0,0,0,11.184,27H23.342a3.2,3.2,0,0,0,3.184-3.184V5.868A.868.868,0,0,0,25.3,5.074l-2.27,1.01L20.48,5.062a.868.868,0,0,0-.644,0L17.263,6.091,14.691,5.062a.868.868,0,0,0-.644,0L11.492,6.084,9.221,5.074A.869.869,0,0,0,8.884,5Zm2.879,6.947h11a.868.868,0,1,1,0,1.737h-11a.868.868,0,1,1,0-1.737Zm0,5.21h6.368a.868.868,0,1,1,0,1.737H11.763a.868.868,0,1,1,0-1.737Zm9.842,0h1.158a.868.868,0,1,1,0,1.737H21.605a.868.868,0,1,1,0-1.737Zm-9.842,3.474h6.368a.868.868,0,0,1,0,1.737H11.763a.868.868,0,1,1,0-1.737Zm9.842,0h1.158a.868.868,0,1,1,0,1.737H21.605a.868.868,0,1,1,0-1.737Z" transform="translate(191.737 102)" fill="#b3aa6d"/>
        </g>
      </svg>
    )
}