import * as React from "react"
const CalendarSmallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.739}
    height={14.154}
    {...props}
  >
    <path
      fill="#a4b2c1"
      d="M2.123 0v1.415h-.708A1.42 1.42 0 0 0 0 2.831v9.908a1.42 1.42 0 0 0 1.415 1.415h9.908a1.42 1.42 0 0 0 1.415-1.415V2.831a1.42 1.42 0 0 0-1.415-1.415h-.708V0H9.2v1.415H3.539V0Zm-.708 2.831h9.908v1.415H1.415Zm0 2.831h9.908v7.077H1.415Zm1.416 1.415v1.416h2.831V7.077Zm4.246 1.415v1.415h2.831V8.493ZM2.831 9.908v1.415h2.831V9.908Z"
    />
  </svg>
)
export default CalendarSmallIcon
