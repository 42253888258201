import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold } from "../../utils";


export const useStyles = makeStyles((theme) => ({

    editCardRoot: {
        padding: "12px",
        backgroundColor: (props) => props?.noBg ? "white" : "#F1F7FF",
        boxShadow: (props) => props?.noBg ? "0px 0px 16px #00000014" : "#F1F7FF",
        marginBottom: (props) => props?.noBg ? "auto" : "12px",
        borderRadius: "4px",
        minHeight:"77px",
        marginTop:"2px"
    },
    cardTitle: {
        fontFamily: ExtraBold,
        fontSize:"0.875rem"
    },
    updateBtn:{
        fontSize:"0.75rem",
        "&:hover": {
            backgroundColor: "white",
        },
    },
    moneyRoot:{
        backgroundColor:"#EEF9EE",
        borderRadius: "4px",
        padding: "12px",

    },
    billing_hour:{
        fontSize:"1rem",
        fontFamily:Bold,
        color:"#4E5A6B"
    },
    billing_hourValue:{
        fontSize:"0.875rem",
        fontFamily:ExtraBold,
        marginRight:"4px"
    },
    editBtn:{
        fontFamily:Bold,
        color:"#5078E1",
       cursor:"pointer"
    },
    enterRoot:{
        backgroundColor:"#F1F7FF",
        borderRadius: "4px",
        padding: "12px",
        border:"1px solid ##5078E1" 
    },
    fromTitle:{
        fontFamily: Bold,
        fontSize:"1rem",
        margin:"8px 0px"
    },
    hr:{
        color:"#98A0AC",
        fontSize:"0.875rem",
        fontFamily:Bold
    },
    inputRoot:{
        backgroundColor:"white",
        padding:"4px",
        borderRadius:"4px"
    },
    unbillableRoot:{
        backgroundColor:"#F2F4F7",
        padding:"6px 12px",
        borderRadius:"4px"
    },
    hourLabel:{
        color:"#4E5A6B",
        fontFamily:SemiBold,
        fontSize:"0.875rem"
    },
    hourValue:{
        fontFamily:Bold,
        fontSize:"0.875rem"
    },
    hourRoot:{
        border:"1px solid #E4E8EE",
        borderRadius:"4px",
        marginTop:"12px"
    },
    hourBreakText:{
        fontSize:"0.75rem",
        fontFamily:Bold,
        color:"#98A0AC",
        textAlign:"center",
        margin:"8px 0px"
    }
}));