import React from "react"

export const InActiveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
        <g id="Group_113423" data-name="Group 113423" transform="translate(-15518.5 -7261)">
            <rect id="icons8-cardboard-box-48" width="80" height="80" transform="translate(15518.5 7261)" fill="none" />
            <g id="Group_113422" data-name="Group 113422" transform="translate(-7.429 -2)">
                <g id="icons8-single-page-mode" transform="translate(15543.93 7292.561)" opacity="0.5">
                    <rect id="Rectangle_57474" data-name="Rectangle 57474" width="24.921" height="2.519" rx="1.259" transform="translate(5.038 11.174)" fill="#1565c0" />
                    <rect id="Rectangle_57475" data-name="Rectangle 57475" width="24.921" height="2.519" rx="1.259" transform="translate(5.038 17.268)" fill="#1565c0" />
                    <rect id="Rectangle_57476" data-name="Rectangle 57476" width="24.921" height="2.519" rx="1.259" transform="translate(5.038 23.363)" fill="#1565c0" />
                    <rect id="Rectangle_57477" data-name="Rectangle 57477" width="24.921" height="2.519" rx="1.259" transform="translate(5.038 29.458)" fill="#1565c0" />
                </g>
                <g id="icons8-single-page-mode-2" data-name="icons8-single-page-mode" transform="translate(15540.93 7281.721)">
                    <rect id="Rectangle_57474-2" data-name="Rectangle 57474" width="30.018" height="2.857" rx="1.428" transform="translate(5.713 12.671)" fill="#c3e4ff" />
                    <rect id="Rectangle_57475-2" data-name="Rectangle 57475" width="30.018" height="2.857" rx="1.428" transform="translate(5.713 19.583)" fill="#c3e4ff" />
                    <rect id="Rectangle_57476-2" data-name="Rectangle 57476" width="30.018" height="2.857" rx="1.428" transform="translate(5.713 26.494)" fill="#c3e4ff" />
                    <rect id="Rectangle_57477-2" data-name="Rectangle 57477" width="30.018" height="2.857" rx="1.428" transform="translate(5.713 33.406)" fill="#c3e4ff" />
                </g>
                <g id="icons8-single-page-mode-3" data-name="icons8-single-page-mode" transform="translate(15537.929 7271)">
                    <rect id="Rectangle_57473" data-name="Rectangle 57473" width="47.299" height="58.031" rx="4" transform="translate(0 0)" fill="#90caf9" />
                    <rect id="Rectangle_57476-3" data-name="Rectangle 57476" width="35.299" height="3" rx="1.5" transform="translate(6 27.6)" fill="#1565c0" />
                    <rect id="Rectangle_57478" data-name="Rectangle 57478" width="35.299" height="3" rx="1.5" transform="translate(6 12.6)" fill="#1565c0" />
                    <rect id="Rectangle_57477-3" data-name="Rectangle 57477" width="35.299" height="3" rx="1.5" transform="translate(6 35.4)" fill="#1565c0" />
                    <rect id="Rectangle_57480" data-name="Rectangle 57480" width="35.299" height="3" rx="1.5" transform="translate(6 43.2)" fill="#1565c0" />
                    <rect id="Rectangle_57479" data-name="Rectangle 57479" width="35.299" height="3" rx="1.5" transform="translate(6 20.4)" fill="#1565c0" />
                    <g id="icons8-minus" transform="translate(28.969 38.6)">
                        <path id="Path_99320" data-name="Path 99320" d="M15.031,26.062A11.031,11.031,0,1,1,26.062,15.031,11.043,11.043,0,0,1,15.031,26.062Z" fill="#f44336" />
                        <path id="Path_99321" data-name="Path 99321" d="M14,21H25.031v3.309H14Z" transform="translate(-4.485 -7.624)" fill="#fff" />
                    </g>
                </g>
            </g>
        </g>
    </svg>

)