import { Avatar, Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { AmenityPaymentDetailsStyle } from './style'

export const AmenityPaymentDetails = () => {
    const classes = AmenityPaymentDetailsStyle()
    return (
        <Box className={classes.box} p={2} ml={2} mt={2}>
            <Stack spacing={1}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Avatar className={classes.avatar} src='https://loftsixfour.com/wp-content/uploads/2020/06/6.20.2019-DSC_9290-TheYard.jpg'></Avatar>
                        <Stack>
                            <Typography className={classes.name}>Amenity Name Here</Typography>
                            <Typography className={classes.id}>BKID-123431</Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography className={classes.name}>$2.00</Typography>
                        <Typography className={classes.paid}>Paid</Typography>
                    </Stack>
                </Stack>
                <Divider />
                <Stack spacing={1}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.title}>Invoice Amount</Typography>
                        <Typography className={classes.content}>$ 2.00</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.title}>VAT Amount</Typography>
                        <Typography className={classes.content}>$.12</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.totaltitle}>Total</Typography>
                        <Typography className={classes.totalcontent}>$ 2.12</Typography>
                    </Stack>
                </Stack>
                <Divider />
                <Stack spacing={1}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.title}>Payment Receipt No</Typography>
                        <Typography className={classes.content}>RCP-12345678</Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography className={classes.title}>Paid on</Typography>
                        <Typography className={classes.content}>22 Jul 2022</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}