import React from "react";
import { withNavBars } from "../../HOCs";
import Contacts from "../contacts/contacts";

const VendorViewNew = () => {
    return (
        <Contacts reltype="Vendor" />
    )
}

export default withNavBars(VendorViewNew)