import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes } from "../../router/routes";
import { NetWorkCallMethods } from "../constants";

export const getPropertyHierarchyDetails = async ({ property = "" }) => {
    const payload = { property_id: property?.id };

    const response = await NetworkCall(
        `${config.api_url}${BackendRoutes?.property_board_getPropertyHierarchyLabel}`,
        NetWorkCallMethods.post, payload, null, true, false
    );

    return response?.data?.data ?? "Not Available";
}