import React from "react";
import { withNavBars } from "../../../HOCs";
import { ShortTermPricingUnitView } from "./shortTermPricingUnitView";

class ShortTermPricingUnitViewParent extends React.Component {
    render() {
        return <ShortTermPricingUnitView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ShortTermPricingUnitViewParent, props);
