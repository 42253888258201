import React from "react"

export const ReservationIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_112532" data-name="Group 112532" transform="translate(-14797 -9521)">
    <g id="Rectangle_57304" data-name="Rectangle 57304" transform="translate(14797 9521)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="24" height="24" stroke="none"/>
      <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
    </g>
    <path id="icons8-caretaker_1_" data-name="icons8-caretaker (1)" d="M11.6,4a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,11.6,4Zm4.9,7.712a1.641,1.641,0,0,0-1.016.341L13.625,13.52A1.634,1.634,0,0,0,13,14.81v2.62a1.271,1.271,0,0,0,1.269,1.27h4.462A1.271,1.271,0,0,0,20,17.43V14.81a1.632,1.632,0,0,0-.625-1.29l-1.859-1.468A1.641,1.641,0,0,0,16.5,11.712ZM7.575,12.4A1.577,1.577,0,0,0,6,13.975v.42C6,16.416,8.46,18,11.6,18c.264,0,.521-.016.775-.038a1.953,1.953,0,0,1-.075-.533V14.81a2.33,2.33,0,0,1,.891-1.84l.723-.571Zm7.35,2.45h.35a.525.525,0,0,1,.525.525v1.05a.525.525,0,0,1-.525.525h-.35a.525.525,0,0,1-.525-.525v-1.05A.525.525,0,0,1,14.925,14.85Zm2.8,0h.35a.525.525,0,0,1,.525.525v1.05a.525.525,0,0,1-.525.525h-.35a.525.525,0,0,1-.525-.525v-1.05A.525.525,0,0,1,17.725,14.85Z" transform="translate(14796 9521.65)" fill="#4e5a6b"/>
  </g>
</svg>

)