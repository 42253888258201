export const scale = {
    x: { stacked: true, grid: { display: false, } },
    y: { stacked: true, grid: { display: false, } },
};

export const AreaStatisticsListHeading = ({ t = () => false }) => [
    { title: t("Unit/Seat Type"), field: "unit_type" },
    { title: t("Carpet"), field: "carpet_area" },
    { title: t("Buildup"), field: "build_up_area" },
    { title: t("Super Buildup"), field: "super_build_up_area" },
    { title: t("Units/Seats"), field: "units" },
];

export const AreaStatisticsListPath = ["unit_type", "carpet_area", "build_up_area", "super_build_up_area", "units"];

export const AreaStatisticsListDataType = [
    { type: ["text"], name: "unit_type", },
    { type: ["text"], name: "carpet_area", },
    { type: ["text"], name: "build_up_area", },
    { type: ["text"], name: "super_build_up_area", },
    { type: ["text"], name: "units", },
];

export const INITIAL_UOM = { label: "Sq. Meter", value: 2 }

export const OccupancyPropertyListHeading = ({ t = () => false }) => [
    { title: t("Property Name"), field: "property_name" },
    { title: t("Total Units/Seats"), field: "total_units" },
    { title: t("Vacant"), field: "vacant" },
    { title: t("Occupied"), field: "occupied_units" },
    { title: t("Percentage"), field: "percentage" },
];

export const OccupancyPropertyListPath = ["property_name", "total_units", "vacant", "occupied_units", "percentage"];

export const OccupancyPropertyListDataType = [
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "total_units" },
    { type: ["text"], name: "vacant" },
    { type: ["text"], name: "occupied_units" },
    { type: ["text"], name: "percentage" },
];

export const UnitStatusOptions = ({ t = () => false }) => [
    { label: t("Occupied"), value: "Occupied", backgroundColor: "#E57373", barThickness: 40, borderWidth: 1, },
    { label: t("Vacant"), value: "Vacant", backgroundColor: "#4CAF50", barThickness: 40, borderWidth: 1, },
    { label: t("Sold"), value: "Sold", backgroundColor: "#ffb347", barThickness: 40, borderWidth: 1, },
];

export const UnitCategoryOptions = ({ t = () => false }) => [
    { label: t("Occupied"), value: "Occupied", backgroundColor: "#E57373", barThickness: 18, borderWidth: 1, },
    { label: t("Vacant"), value: "Vacant", backgroundColor: "#4CAF50", barThickness: 18, borderWidth: 1, },
    { label: t("Sold"), value: "Sold", backgroundColor: "#ffb347", barThickness: 18, borderWidth: 1, },
];