export const UploadImagePlaceholder = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
        <g
            data-name="Group 110550"
            style={{
                transformOrigin: "182px 246px",
            }}
            transform="matrix(1.5 0 0 1.5 -182 -246)"
        >
            <rect
                width={32}
                height={32}
                fill="#f1f7ff"
                data-name="Rectangle 56647"
                rx={4}
                transform="translate(182 246)"
            />
            <path
                fill="#78b1fe"
                d="M204.25 261.417h-.242a6.041 6.041 0 0 0-12.017 0h-.241a3.75 3.75 0 0 0 0 7.5h12.5a3.75 3.75 0 1 0 0-7.5Zm-2.667 1.467a.626.626 0 0 1-.458.2.616.616 0 0 1-.425-.167l-2.075-1.937v5.229a.625.625 0 0 1-1.25 0v-5.23l-2.075 1.938a.625.625 0 1 1-.85-.917l3.125-2.917a.628.628 0 0 1 .85 0L201.55 262a.626.626 0 0 1 .033.883Z"
            />
        </g>
    </svg>
)