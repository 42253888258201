import React from "react";
import { withNavBars } from "../../HOCs";
import  ServiceInspection from "./serviceInspection";
class ServiceInspectionParent extends React.Component {
  render() {
    return <ServiceInspection />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(ServiceInspectionParent, props);