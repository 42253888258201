import * as React from "react"
const SuccessIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38.998}
    height={38.997}
    {...props}
  >
    <path
      fill="#5ac782"
      d="M36.882 19.614a.214.214 0 0 1 0-.233l1.606-2.508a3.215 3.215 0 0 0-1.231-4.6l-2.646-1.368a.22.22 0 0 1-.118-.2l.138-2.977a3.215 3.215 0 0 0-3.363-3.363l-2.978.138a.267.267 0 0 1-.2-.117l-1.37-2.645a3.216 3.216 0 0 0-4.6-1.232l-2.504 1.607a.214.214 0 0 1-.233 0L16.874.51a3.215 3.215 0 0 0-4.6 1.231l-1.363 2.646a.216.216 0 0 1-.2.118l-2.978-.14a3.215 3.215 0 0 0-3.367 3.363l.133 2.978a.22.22 0 0 1-.117.2l-2.641 1.37a3.216 3.216 0 0 0-1.232 4.6l1.607 2.51a.214.214 0 0 1 0 .233L.51 22.122a3.215 3.215 0 0 0 1.231 4.6l2.646 1.368a.22.22 0 0 1 .118.2l-.139 2.978a3.228 3.228 0 0 0 .933 2.425 3.175 3.175 0 0 0 2.425.938l2.978-.138a.247.247 0 0 1 .2.117l1.367 2.646a3.218 3.218 0 0 0 4.6 1.231l2.51-1.607a.212.212 0 0 1 .233 0l2.508 1.606a3.215 3.215 0 0 0 4.6-1.231l1.368-2.646a.246.246 0 0 1 .2-.118l2.977.138a3.215 3.215 0 0 0 3.363-3.363l-.138-2.978a.22.22 0 0 1 .117-.2l2.646-1.367a3.216 3.216 0 0 0 1.232-4.6Zm-8.766-3.906a47.331 47.331 0 0 0-9.69 9.695 1.5 1.5 0 0 1-1.2.6h-.006a1.5 1.5 0 0 1-1.2-.6 19.237 19.237 0 0 0-4.621-4.655 1.501 1.501 0 1 1 1.662-2.5 20 20 0 0 1 4.163 3.824 49.407 49.407 0 0 1 9.119-8.785 1.5 1.5 0 1 1 1.774 2.419Z"
      data-name="icons8-instagram-check-mark (2)"
    />
  </svg>
)
export default SuccessIcon
