import { Box, Button } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FormGenerator } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../../utils";
import { StatusOptionList } from "../../../utils/insepectionMaster";

const Form = ({ t, data = {}, setData = () => false, reload = () => false, onClose = () => false, colorOptions = [], inspectionItemTypeOptions = [], categoryOptions = [], updateState = () => false }) => {
    const alert = React.useContext(AlertContext);
    const client_id = localStorage.getItem(LocalStorageKeys.clinetID)
const [isDisableBtn,setIsDisableBtn] = React.useState(false)

    //validate form
    const validateForm = () => {
        let isValid = true;
        let error = data.error;
        if (data?.inspectionType?.length === 0) {
            isValid = false;
            error.inspectionType = `${t("inspectionType")} ${t("isRequired")}`;
        }
        if (data?.title?.length === 0) {
            isValid = false;
            error.title = `${t("categoryName")} ${t("isRequired")}`;
        }
        if (data?.name?.length === 0) {
            isValid = false;
            error.name = `${t("name")} ${t("isRequired")}`;
        }
        if (data?.color?.length === 0) {
            isValid = false;
            error.color = `${t("color")} ${t("isRequired")}`;
        }
        setData({ ...data, error });
        return isValid;
    };
    //on submit
    const onSubmit = () => {
        if (validateForm()) {
            setIsDisableBtn(true)
            const payload = {
                "data": {
                    "id": data?.id,
                    "category_id": data?.title?.value,
                    "is_active": data?.status,
                    "name": data?.name,
                    "color": data?.color?.value,
                    "client": client_id
                }
            }
            NetworkCall(
                `${config.api_url}/inspection_feedback_sub_category/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                reload()
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `${data?.isEdit ? t("Edited") : t("Added")} ${t("Successfully")}`,
                });
                onClose()
            }).catch((error) => {
                setIsDisableBtn(false)
                if (error.response) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: `${t("Cannot Be")} ${data?.isEdit ? t("Edited") : t("Added")}`,
                    });
                }
                onClose()
            })
        } else {
            return false
        }
    }
    // const manualResponse = (array) => array?.map(_ => {
    //     return {
    //         ..._,
    //         label: _?.name,
    //         value: _?.id,
    //     }
    // });
    //form data
    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("inspection_item_type"),
            placeholder: t("selectInspectionItemType"),
            value: data?.inspectionType,
            onChange: (value) => updateState("inspectionType", value),
            error: data?.error?.inspectionType,
            isRequired: true,
            options: inspectionItemTypeOptions,
            isReadonly: data?.isEdit

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("categoryName"),
            placeholder: t("selectCategoryName"),
            value: data?.title,
            onChange: (value) => updateState("title", value),
            error: data?.error?.title,
            isRequired: true,
            options: categoryOptions,
            menuPlacement: "bottom"

        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("name"),
            placeholder: t("enterName"),
            value: data?.name,
            onChange: (e) => updateState("name", e.target.value),
            error: data?.error?.name,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "select",
            label: t("color"),
            placeholder: t("selectColor"),
            value: data?.color,
            onChange: (value) => updateState("color", value),
            error: data?.error?.color,
            isRequired: true,
            options: colorOptions,
            debounceTimeout: 800,
            isPaginate: true,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("Active / Inactive"),
            placeholder: t("status"),
            value: data?.status,
            onChange: (value) => updateState("status", value),
            error: data?.error?.status,
            isRequired: true,
            options: StatusOptionList(t)
        },
    ]

    return (

        <Box p={2} >
            {/*form generator */}
            <Box height="350px">
                <FormGenerator t={t} components={changeInput} />
            </Box>
            <Box mt={12}>
                <Button variant="contained" fullWidth onClick={onSubmit} disabled={isDisableBtn}>{data?.isEdit ? t("edit") : t("add")}</Button>
            </Box>
        </Box >
    )
}
export default withNamespaces("inspectionItemmaster")(Form); 