import React from "react"

export const SelectedResourceIcon = (props) =>{
    return(
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    data-name="Group 118073"
    {...props}
  >
    <g data-name="Group 118072">
      <path
        fill="#00897b"
        d="m23 24.952-3.1.619-3.09-.619S10 26.181 10 33h19.81c0-6.794-6.81-8.048-6.81-8.048"
        data-name="Path 101903"
      />
      <path
        fill="#ff9800"
        d="m19.91 28.667-3.1-3.715v-3.714H23v3.714Z"
        data-name="Path 101904"
      />
      <path
        fill="#ffa726"
        d="M26.714 17.524a1.238 1.238 0 1 1-1.238-1.238 1.238 1.238 0 0 1 1.238 1.238m-11.143 0a1.238 1.238 0 1 0-1.238 1.238 1.238 1.238 0 0 0 1.238-1.238"
        data-name="Path 101905"
      />
      <path
        fill="#ffb74d"
        d="M25.476 13.809c0-4.726-11.143-3.077-11.143 0v4.333a5.572 5.572 0 1 0 11.143 0Z"
        data-name="Path 101906"
      />
      <path
        fill="#424242"
        d="M19.904 8.238c-3.76 0-6.19 3.049-6.19 6.81v1.415l1.238 1.061v-3.1l7.429-2.476 2.476 2.476v3.1l1.238-1.078v-1.4c0-2.492-.643-4.962-3.714-5.571l-.619-1.237Z"
        data-name="Path 101907"
      />
      <path
        fill="#784719"
        d="M21.762 17.524a.619.619 0 1 1 .619.619.62.62 0 0 1-.619-.619m-4.952 0a.619.619 0 1 0 .619-.619.62.62 0 0 0-.619.619"
        data-name="Path 101908"
      />
      <path
        fill="#f9a825"
        d="M23.614 25.11v5.414H16.19v-5.416a10.3 10.3 0 0 0-2.476 1.109v6.784h12.381v-6.774a10.337 10.337 0 0 0-2.481-1.117Z"
        data-name="Path 101909"
      />
      <path
        fill="#263238"
        d="M16.19 30.524h-2.476v-1.857h2.476Zm9.905-1.857h-2.481v1.857h2.476Z"
        data-name="Path 101910"
      />
      <path
        fill="#ff5722"
        d="M26.095 13.809v-.619a5.571 5.571 0 0 0-5.571-5.571h-1.238a5.571 5.571 0 0 0-5.571 5.571v.619h-1.239v1.857h14.857v-1.857Z"
        data-name="Path 101911"
      />
      <path
        fill="#d84315"
        d="M18.048 7h3.714v6.81h-3.714Z"
        data-name="Path 101912"
      />
    </g>
  </svg>
    )
}