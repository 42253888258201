

import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ScheduleCard } from './scheduleCard';
import { useStyles } from './style';
const times = [{ label: "00:00-04:00", start: "00", end: "04" }, { label: "04:00-08:00", start: "04", end: "08" }, { label: "08:00-12:00", start: "08", end: "12" }, { label: "12:00-16:00", start: "12", end: "16" }, { label: "16:00-20:00", start: "16", end: "20" }, { label: "20:00-24:00", start: "20", end: "24" }]
export const WeeklyCalendar = ({ t, dates = {}, weekdatas = [], companyId = "", propertyId = "", resourceType = "", fetchMoreData = () => false }) => {
    const classes = useStyles()


    return (
        <>
            <Grid container>
                <Grid item md={0.5} xs={0.5} className={classes.cells}>
                    <ScheduleIcon />
                </Grid>
                {dates?.map((e) => {
                    return (
                        <Grid item md={1.64} xs={1.64} className={classes.cells}>
                            <Stack alignItems={"center"} justifyContent={"center"}>
                                <Typography className={classes.dayLabel}>
                                    {moment(e).format("ddd")}
                                </Typography>
                                <Typography className={classes.dateLabel}>
                                    {moment(e).format("DD")}
                                </Typography>
                            </Stack>
                        </Grid>
                    );
                })}
            </Grid>
            <Box>
                <InfiniteScroll
                    dataLength={weekdatas.length}
                    next={fetchMoreData}
                    hasMore={true}
                    style={{ overflow: "overlay", }}
                    height={`calc(100vh - 310px)`}
                >

                    <Grid container className={classes.content}>
                        {
                            times.map(time => {
                                return (
                                    <>
                                        <Grid item md={0.5} xs={0.5} className={classes.cells}>
                                            <Typography className={classes.time_label}> {time.label} </Typography>
                                        </Grid>


                                        {
                                            dates.map(date => {
                                                return (

                                                    <Grid item md={1.64} xs={1.64} className={classes.content_cells}>
                                                        {
                                                            weekdatas.map(wd => {

                                                                return (
                                                                    <div>
                                                                        {wd.schedule.map((x) =>

                                                                            (moment(wd.date).isSame(moment(date).format("YYYY-MM-DD")))
                                                                                &&
                                                                                (x?.start?.slice(11, 13) === time.start && x?.end?.slice(11, 13) === time.end)
                                                                                ?
                                                                                <Stack spacing={1}>

                                                                                    {Object.keys(x.resources).length > 0 &&
                                                                                        <ScheduleCard
                                                                                            datas={x}
                                                                                            type={x.resources?.hasOwnProperty("move-in") ? "move-in" : x.resources?.hasOwnProperty("move-out") ? "move-out" : x.resources?.hasOwnProperty("service")?"service":"site_visit"}
                                                                                            count={Object.keys(x.resources).length}
                                                                                            companyId={companyId}
                                                                                            propertyId={propertyId}
                                                                                            resourceType={resourceType}
                                                                                            t={t}
                                                                                        />
                                                                                    }
                                                                                </Stack>
                                                                                : ""

                                                                        )}
                                                                    </div>



                                                                )

                                                            })
                                                        }
                                                    </Grid>

                                                )
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                    </Grid>
                </InfiniteScroll>
            </Box>
        </>
    )
}