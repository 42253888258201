import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        backgroundColor: "white",
    },
    grid: {
        height: ({ size }) => size?.height,
        alignItems: "center",
    },
    left_section: {
        height: ({ size }) => size?.height,
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
        padding: "16px",
        paddingInlineEnd: "0px",
    },
    right_section: {
        height: ({ size }) => size?.height,
        alignItems: "center",
        display: "flex",
        justifyContent: "end",
    },
    web_background: {
        height: ({ size }) => size?.height - 32,
        width: "100%",
        borderRadius: theme.palette.borderRadius_2,
    },
    web_right_section_card: {
        display: "contents",
        height: ({ size }) => size?.height,
        padding: "0px",
    },
    web_back_button: {
        cursor: "pointer",
        height: "32px",
        width: "32px",
    },
    web_content: {
        height: "100vh",
        overflow: "overlay",
        display: "flex",
        position: "relative",
        padding: "16px",
        backgroundColor: "white",
    },
    web_logo: {
        height: "32px",
        objectFit: "contain",
    },
    web_main_text: {
        textAlign: "center",
        fontSize: remCalc(24),
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    web_sub_text: {
        textAlign: "center",
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
    },
    web_forget_password: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.primary_2,
        textAlign: "end",
        cursor: "pointer",
    },
    web_login_button: {
        height: "48px",
        width: "100%",
        borderRadius: theme.palette.borderRadius_2,
        boxShadow: "0px 6px 10px #00000014",
        backgroundColor: theme.palette.background.button_background_1,
        border: "1px solid #5078E1",
    },
    web_login_button_text: {
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.white,
    },
    web_log_in_button: {
        height: "48px",
        width: "100%",
        borderRadius: theme.palette.borderRadius_2,
        boxShadow: "0px 6px 10px #00000014",
        border: "1px solid #5078E1",
        "&:hover": {
            backgroundColor: theme.palette.background.tertiary1,
        },
    },
    web_log_in_button_text: {
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.primary_2,
    },
    mob_right_section_card: {
        padding: "0px",
    },
    // mob_content: {
    //     height: "calc(100vh - 54px)",
    //     overflow: "overlay",
    // },
    mob_body_items: {
        padding: "16px",
    },
    mob_background: {
        height: "235px",
        borderRadius: theme.palette.borderRadius,
    },
    mob_background_image: {
        height: "235px",
        width: "100%",
        borderRadius: theme.palette.borderRadius,
    },
    mob_bottom_items: {
        padding: "16px",
    },
    mob_logo: {
        height: "32px",
        objectFit: "contain",
    },
    mob_main_text: {
        textAlign: "center",
        fontSize: remCalc(18),
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    mob_sub_text: {
        textAlign: "center",
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
    },
    mob_set_up_new_button: {
        height: "48px",
        width: "100%",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        backgroundColor: theme.palette.background.button_background_1,
        border: "1px solid #5078E1",
    },
    mob_set_up_new_button_text: {
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.white,
    },
    mob_log_in_button: {
        height: "48px",
        width: "100%",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        backgroundColor: "#ffffff75",
        "&:hover": {
            backgroundColor: "#ffffff75",
        },
    },
    mob_log_in_button_text: {
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.white,
    },
    version_no: {
        fontSize: remCalc(12),
        fontFamily: Regular,
        color: theme.typography.color.tertiary,
    },
    powered_by_logo: {
        height: "11px",
        objectFit: "contain",
    },
}));