import React from "react";
import { withNavBars } from "../../HOCs";

import InvoiceView from "./invoiceView";

class InvoiceViewParent extends React.Component {
    render() {
        return <InvoiceView  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(InvoiceViewParent, props);
