import React from "react";
import { withNavBars } from "../../HOCs";
import BackupRestore from "./backupRestore";

class backupRestoreParent extends React.Component {
    render() {
        return <BackupRestore {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(backupRestoreParent, props);
