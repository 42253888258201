import React from "react";
import { withNavBars } from "../../../HOCs";
import { Communication } from "./communication";

class CommunicationParent extends React.Component {
  render() {
    return <Communication />;
  }
}

export default withNavBars(CommunicationParent);
