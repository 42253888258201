import React from "react"
import { withNavBars } from "../../HOCs"
import InspectionList from "./inspection"
import AssignModalContextProvider from '../../contexts/assignModalContext';

const InspectionListParent = () => {
    return (
        <AssignModalContextProvider>
        <InspectionList />
        </AssignModalContextProvider>
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(InspectionListParent, props)