export const RetryIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} {...props}>
        <g
            data-name="Group 110653"
            style={{
                transformOrigin: "215.5px 10px",
            }}
            transform="matrix(1.45833 0 0 1.45833 -215.5 -10)"
        >
            <rect
                width={24}
                height={24}
                fill="#ffecec"
                data-name="Rectangle 56948"
                rx={4}
                transform="translate(215.5 10)"
            />
            <path
                d="M227.5 17.886v-2.743l-3.429 3.429 3.429 3.429v-2.743a4.117 4.117 0 0 1 4.114 4.114 4.117 4.117 0 0 1-4.114 4.114 4.117 4.117 0 0 1-4.114-4.114h-1.372a5.485 5.485 0 0 0 5.486 5.486 5.485 5.485 0 0 0 5.486-5.486 5.485 5.485 0 0 0-5.486-5.486Z"
                style={{
                    fill: "#ff4b4b",
                }}
            />
        </g>
    </svg>
)