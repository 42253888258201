import * as React from "react"
const DeductionDetailsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      fill="#4e5a6b"
      d="M4.21 0a4.211 4.211 0 1 0 4.211 4.211A4.21 4.21 0 0 0 4.21 0Zm7.777 1.678a.632.632 0 0 0-.434.191L9.869 3.553a.632.632 0 0 0 0 .893l1.684 1.684a.632.632 0 1 0 .893-.893l-.606-.606h3.107a.632.632 0 1 0 0-1.263H11.84l.606-.606a.632.632 0 0 0-.459-1.084Zm-8.554.006H5.28a.421.421 0 0 1 0 .842H3.791l-.137.842h.557a1.686 1.686 0 0 1 1.684 1.684 1.734 1.734 0 0 1-1.737 1.684A2.253 2.253 0 0 1 2.665 6.3a.421.421 0 0 1 .557-.632 1.554 1.554 0 0 0 .936.23.893.893 0 0 0 .895-.842.843.843 0 0 0-.842-.842H3.158a.421.421 0 0 1-.415-.489l.275-1.684a.421.421 0 0 1 .415-.357ZM8.21 8.421a.632.632 0 0 0-.632.632v5.053A1.9 1.9 0 0 0 9.474 16h4.211a1.9 1.9 0 0 0 1.895-1.895V9.053a.632.632 0 0 0-.632-.632Zm.632 1.263h5.474v4.421a.622.622 0 0 1-.632.632h-4.21a.622.622 0 0 1-.632-.632Zm-5.27.414a.632.632 0 0 0-.44 1.085l.606.606H.631a.632.632 0 1 0 0 1.263h3.107l-.606.606a.632.632 0 1 0 .893.893l1.684-1.684a.632.632 0 0 0 0-.893L4.025 10.29a.632.632 0 0 0-.453-.19Zm7.164.428a.632.632 0 1 0 0 1.263h1.684a.632.632 0 1 0 0-1.263Z"
      data-name="Group 115874"
    />
  </svg>
)
export default DeductionDetailsIcon
