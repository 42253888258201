import * as React from "react"
const StayListingIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
    >
        <defs>
            <clipPath id="clip-path">
                <path
                    fill="#6a69d2"
                    d="M0 0H24V24H0z"
                    data-name="Rectangle 56642"
                    transform="translate(580 21)"
                ></path>
            </clipPath>
        </defs>
        <g data-name="Group 110543" transform="translate(-152 -317)">
            <rect
                width="48"
                height="48"
                fill="#eaeaff"
                data-name="Rectangle 56639"
                rx="8"
                transform="translate(152 317)"
            ></rect>
            <g
                clipPath="url(#clip-path)"
                data-name="Mask Group 93120"
                transform="translate(-416 308)"
            >
                <g fill="#6a69d2" fillRule="evenodd" transform="translate(583 21)">
                    <path
                        d="M19.2 24.863H7.946a3.375 3.375 0 01-3.375-3.375v-8.039a3.375 3.375 0 011.865-3.018l5.625-2.812a3.376 3.376 0 013.018 0l5.625 2.812a3.374 3.374 0 011.866 3.018v8.039a3.375 3.375 0 01-3.37 3.375z"
                        data-name="Path 100172"
                        opacity="0.35"
                        transform="translate(-4.571 -1.238)"
                    ></path>
                    <path
                        d="M6.952 3.6a.332.332 0 01.585 0l.555 1.045a.088.088 0 00.027.03.091.091 0 00.037.016l1.166.2a.332.332 0 01.258.224.336.336 0 01-.079.333l-.822.849a.1.1 0 00-.021.035.083.083 0 000 .041l.165 1.171a.33.33 0 01-.473.343l-1.062-.52a.091.091 0 00-.079 0l-1.063.52a.331.331 0 01-.472-.343l.166-1.171a.1.1 0 000-.041.086.086 0 00-.021-.035l-.822-.85a.33.33 0 01.18-.556l1.165-.2a.091.091 0 00.037-.016.088.088 0 00.027-.03z"
                        data-name="Path 100173"
                        transform="translate(-4.576 -1.179)"
                    ></path>
                    <path
                        d="M19.524 3.6a.332.332 0 01.585 0l.555 1.045a.088.088 0 00.027.03.091.091 0 00.037.016l1.166.2a.331.331 0 01.181.556l-.822.851a.1.1 0 00-.021.035.083.083 0 000 .041l.165 1.171a.33.33 0 01-.472.343l-1.062-.52a.116.116 0 00-.042-.009.1.1 0 00-.039.009l-1.063.52a.33.33 0 01-.473-.343l.165-1.171a.083.083 0 000-.041.086.086 0 00-.021-.035l-.822-.851a.328.328 0 01-.077-.332.334.334 0 01.258-.224l1.166-.2a.091.091 0 00.037-.016.088.088 0 00.027-.03z"
                        data-name="Path 100174"
                        transform="translate(-4.773 -1.179)"
                    ></path>
                    <path
                        d="M13.385 1.318a.332.332 0 01.585 0l.555 1.045a.088.088 0 00.027.03.091.091 0 00.037.016l1.166.2a.331.331 0 01.181.556l-.822.85a.1.1 0 00-.021.035.064.064 0 00-.006.04l.165 1.171a.33.33 0 01-.472.343l-1.062-.52a.091.091 0 00-.079 0l-1.062.52a.33.33 0 01-.473-.343l.165-1.171a.083.083 0 000-.04.086.086 0 00-.021-.035l-.822-.85a.328.328 0 01-.076-.332.334.334 0 01.258-.224l1.166-.2a.091.091 0 00.037-.016.088.088 0 00.027-.03z"
                        data-name="Path 100175"
                        transform="translate(-4.677 -1.143)"
                    ></path>
                    <path
                        d="M14.8 20.571h-2.25a1.125 1.125 0 00-1.121 1.129v3.375h4.5V21.7a1.125 1.125 0 00-1.129-1.129z"
                        data-name="Path 100176"
                        transform="translate(-4.679 -1.446)"
                    ></path>
                    <path
                        d="M9.107 19.375H7.982a1.125 1.125 0 01-1.125-1.125v-1.125A1.125 1.125 0 017.982 16h1.125a1.125 1.125 0 011.125 1.125v1.125a1.125 1.125 0 01-1.125 1.125z"
                        data-name="Path 100177"
                        transform="translate(-4.607 -1.375)"
                    ></path>
                    <path
                        d="M19.393 19.375h-1.125a1.125 1.125 0 01-1.125-1.125v-1.125A1.125 1.125 0 0118.268 16h1.125a1.125 1.125 0 011.125 1.125v1.125a1.125 1.125 0 01-1.125 1.125z"
                        data-name="Path 100178"
                        transform="translate(-4.768 -1.375)"
                    ></path>
                    <path
                        d="M14.25 19.375h-1.125A1.125 1.125 0 0112 18.25v-1.125A1.125 1.125 0 0113.125 16h1.125a1.125 1.125 0 011.125 1.125v1.125a1.125 1.125 0 01-1.125 1.125z"
                        data-name="Path 100179"
                        transform="translate(-4.688 -1.375)"
                    ></path>
                    <path
                        d="M9.107 14.8H7.982a1.125 1.125 0 01-1.125-1.125v-1.121a1.125 1.125 0 011.125-1.125h1.125a1.125 1.125 0 011.125 1.125v1.125A1.125 1.125 0 019.107 14.8z"
                        data-name="Path 100180"
                        transform="translate(-4.607 -1.304)"
                    ></path>
                    <path
                        d="M19.393 14.8h-1.125a1.125 1.125 0 01-1.125-1.125v-1.121a1.125 1.125 0 011.125-1.125h1.125a1.125 1.125 0 011.125 1.125v1.125a1.125 1.125 0 01-1.125 1.121z"
                        data-name="Path 100181"
                        transform="translate(-4.768 -1.304)"
                    ></path>
                    <path
                        d="M14.25 14.8h-1.125A1.125 1.125 0 0112 13.679v-1.125a1.125 1.125 0 011.125-1.125h1.125a1.125 1.125 0 011.125 1.125v1.125A1.125 1.125 0 0114.25 14.8z"
                        data-name="Path 100182"
                        transform="translate(-4.688 -1.304)"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
)
export default StayListingIcon
