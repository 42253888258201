import React from "react";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import makeStyles from "@mui/styles/makeStyles";
// import Timeline from "@mui/lab/Timeline";
// import TimelineItem from "@mui/lab/TimelineItem";
// import TimelineSeparator from "@mui/lab/TimelineSeparator";
// import TimelineConnector from "@mui/lab/TimelineConnector";
// import TimelineContent from "@mui/lab/TimelineContent";
// import TimelineDot from "@mui/lab/TimelineDot";
import moment from "moment-timezone";
import { Bold, ExtraBold, SemiBold } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  connecter: {
    marginTop: "-21px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
  },
  btn2: {
    borderRadius: theme.palette.borderRadius,
    color: theme.palette.secondary.main,
  },
  subText: {
    fontSize:"1rem",
    fontFamily: SemiBold,
    color: theme.typography.color.secondary,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
  },
  waterLeakageIcons: {
    color: "#071741",
    fontFamily: SemiBold,
    fontWeight: "600",
    marginRight: "4px",
  },
  active: {
    padding: "10px 14px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  disactive: {
    padding: "10px 14px",
    textAlign: "center",
    borderRadius: "10px",
    fontSize:"0.875rem",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Label: {
    color: theme.typography.color.tertiary,
    fontSize:"0.75rem",
  },
  // title: {
  //   display: "flex",
  //   alignItems: "center",
  //   fontSize:"0.75rem",
  //   fontFamily: ExtraBold,
  //   color: theme.typography.color.primary,
  // },
  DialogCloseIcon: {
    color: "#7C8594",
    fontSize: "22px",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
    padding: " 4px 10px 4px 10px",
    alignItems: "center",
    width: "100%",
  },
  bottomTitle: {
    fontSize:"1rem",
    fontFamily: ExtraBold,
    color: "#091B29",
    fontWeight: "600px",
  },
  quotationsummaryquest: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: SemiBold,
  },
  quotationsummaryans: {
    fontSize:"0.875rem",
    color: "#091B29",
    fontFamily: SemiBold,
    fontWeight: "600",
    marginBottom: '5px'
  },
  title: {
    fontSize:"1rem",
    fontFamily: ExtraBold,
    color: "#091B29",
    fontWeight: "600",
  },
  title2: {
    fontSize:"0.75rem",
    fontFamily: ExtraBold,
    color: "#98A0AC",
    fontWeight: "600",
  },
  menu: {
    fontSize:"1rem",
    fontFamily: ExtraBold,
    color: "#071741",
  },
  image: {
    maxWidth: "200px",
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 3px 6px #00000014",
    display: "flex",
  },
  contactDetailsName: {
    fontSize:"0.75rem",
    fontFamily: ExtraBold,
    color: "#091B29",
    fontWeight: "600",
  },
  service: {
    backgroundColor: "#5078E11E",
    borderRadius: "5px",
    padding: "3px 10px",
    cursor: "pointer",
  },
  servicetittle: {
    fontSize:"0.75rem",
    color: "#091B29",
    fontWeight: 600,
  },
  top: {
    marginTop: "-45px",
    "& li": {
      "&.MuiTimelineItem-root": {
        "&:before": {
          padding: "0px",
          display: "none",
        },
      },
    },
  },
  top1: {
    "& ul": {
      marginTop: "0px",
    },
    "& li": {
      "&.MuiTimelineItem-root": {
        "&:before": {
          padding: "0px",
          display: "none",
        },
      },
    },
  },
  labelTittle: {
    color: "#091B29",
    fontSize:"1rem",
    fontFamily: Bold,
  },
  description: {
    color: "#7C8594",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
  },
  createnewsectionlog: {
    marginTop: "20px",
  },
  contactDetails: {
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    padding: "5px 5px",
    alignItems: "center",
  },
  contactDetailsNumbers: {
    fontSize:"0.75rem",
    fontFamily: ExtraBold,
    color: "#7C8594",
    fontWeight: "600",
  },
  phoneIconBtn: {
    backgroundColor: "#E4E8EE",
    padding: "12px",
    color: "#7C8594",
    border: "1px solid #E4E8EE",
    borderRadius: "50px",
    float: "right",
    fontSize:"0.75rem",
    "&:hover": {
      backgroundColor: "#E4E8EE",
    },
  },
  phoneIcon: {
    fontSize:"1rem",
    "&:hover": {
      fontSize:"1rem",
    },
  },
  imgContainer: {
    width: "100%",
    maxWidth: "150px",
    margin: "0px 10px",
    borderRadius: "12px",
    display: "flex",
  },
  imagepreiew: {
    marginTop: '20px'
  },
  imagePreviewParent: {
    display: 'flex',
    overflowX: 'scroll'
  }
}));

// const steps = [
//   {
//     label: "In-Progress",
//     description: "Picked on 30-11-2021 9:00 AM",
//     src: "images/icons8-clock.svg",
//     line: true,
//   },
//   {
//     label: "New",
//     description: "Created on 29-11-2021 10:00 AM",
//     src: "images/Path 953.svg",
//     line: false,
//   },
// ];

export const RequestSummary = (props) => {
  const classes = useStyles();
  moment.defaultFormat = "DD MMM YY";
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.BorderLine}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Box className={classes.service}>
              <Typography className={classes.servicetittle}>
                {props?.data?.category?.label ?? "-"}
              </Typography>
            </Box>
            <Box className={classes.service} style={{ marginLeft: "10px" }}>
              <Typography className={classes.servicetittle}>
                {props?.data?.subcategory?.label ?? "-"}
              </Typography>
            </Box>
          </Box>
          <div>
            <Typography className={classes.title}>
              {props?.data?.problem ?? "-"}
            </Typography>
            <Typography className={classes.title2}>
              {props?.data?.description ?? "-"}
            </Typography>
            <Grid container textAlign="left" marginBottom="5px">
              <Grid item xs={4}>
                <p className={classes.quotationsummaryans}>Problem Since</p>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Box className={classes.waterLeakageIcons}>
                    <img
                      src="images/icons8-calendar (5).svg"
                      alt="no_img"
                    ></img>
                  </Box>
                  <Box className={classes.quotationsummaryquest}>
                    {moment(props?.data?.since)
                      .tz(moment.tz.guess())
                      .format("DD MMM YY")}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <p className={classes.quotationsummaryans}>Unit</p>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Box className={classes.waterLeakageIcons}>
                    <img src="images/Group 619.svg" alt="no_img"></img>
                  </Box>
                  <Box className={classes.quotationsummaryquest}>
                    {props?.data?.unit?.label ?? "-"}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <p className={classes.quotationsummaryans}>Raised On</p>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Box className={classes.waterLeakageIcons}>
                    <img
                      src="images/icons8-calendar (5).svg"
                      alt="no_img"
                    ></img>
                  </Box>
                  <Box className={classes.quotationsummaryquest}>26 Jan 21</Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <p className={classes.quotationsummaryans}>
                  Preferred Visit Date And Time
                </p>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Box className={classes.waterLeakageIcons}>
                    <img
                      src="images/icons8-schedule (1).svg"
                      alt="no_img"
                    ></img>
                  </Box>
                  <Box className={classes.quotationsummaryquest}>
                    {moment(props?.data?.preferreddate)
                      // .tz(moment.tz.guess())
                      .format("DD MMM YY , hh:mm a")}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <div className={classes.imagepreiew}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.quotationsummaryans}>
                    Images
                  </Typography>
                  <div className={classes.imagePreviewParent}>
                    {props?.images?.map((x) => {
                      return (
                        <Box className={classes.imgContainer}>
                          <div style={{ position: "relative" }}>
                            <img
                              type="file"
                              accept="image/*"
                              multiple
                              alt="not fount"
                              width={"250px"}
                              src={x.url}
                              style={{
                                objectFit: "cover",
                                height: "150px",
                                width: "100%",
                                borderRadius: "12px",
                              }}
                            />
                          </div>
                        </Box>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid marginTop="10px">
              <Typography className={classes.quotationsummaryans}>
                Contact Details
              </Typography>
              <Grid container className={classes.contactDetails}>
                <Grid item lg={1} xs={1} md={1} sm={1} display="flex">
                  <IconButton className={classes.phoneIconBtn}>
                    <PhoneIcon className={classes.phoneIcon} />
                  </IconButton>
                </Grid>
                <Grid item lg={11} xs={11} md={11} sm={11}>
                  <div style={{ marginLeft: "8px" }}>
                    <Typography className={classes.contactDetailsName}>
                      {props?.data?.name ?? "-"}
                    </Typography>
                    <Typography className={classes.contactDetailsNumbers}>
                      {props?.data?.mobile?.mobile_code ?? "-"}&nbsp;
                      {props?.data?.mobile?.mobile ?? "-"}
                      &nbsp;| {props?.data?.altermobile?.mobile_code ?? "-"}
                      &nbsp;
                      {props?.data?.altermobile?.mobile ?? "-"}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
