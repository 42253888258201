import * as React from "react"

export const Profile = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props}>
        <g data-name="Group 99046" transform="translate(-144 -116)">
            <rect
                data-name="Rectangle 49528"
                width={54}
                height={54}
                rx={8}
                transform="translate(144 116)"
                fill="#f2f4f7"
            />
            <path
                data-name="icons8-user (2)"
                d="M169.4 127.999a6.5 6.5 0 1 0 6.5 6.5 6.515 6.515 0 0 0-6.5-6.5Zm0 1.95a4.55 4.55 0 1 1-4.55 4.55 4.535 4.535 0 0 1 4.55-4.55Zm-7.814 13.65a2.6 2.6 0 0 0-2.586 2.586v.989a6.057 6.057 0 0 0 3.449 5.241 14.63 14.63 0 0 0 6.951 1.584 14.63 14.63 0 0 0 6.951-1.584 6.283 6.283 0 0 0 3.294-4.266h.155v-1.964a2.6 2.6 0 0 0-2.586-2.586Zm0 1.95h15.628a.623.623 0 0 1 .637.636v.989a3.961 3.961 0 0 1-2.441 3.534 12.957 12.957 0 0 1-6.01 1.341 12.957 12.957 0 0 1-6.009-1.341 3.961 3.961 0 0 1-2.441-3.534v-.989a.622.622 0 0 1 .636-.636Z"
                fill="#e4e8ee"
            />
        </g>
    </svg>
)


