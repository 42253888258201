import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import { PropertyUnitAddStyle } from './style';
import { CreateEditContext } from '../create/createEditContext';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '../../../assets/delete';

export const PropertyUnitAdd = ({ data = {}, t = () => false }) => {
    const {
        setPropertyPopup,
        setSelectedTodo,
        setUnitPopup,
        removeTodoList,
        state,
    } = React.useContext(CreateEditContext)
    const classes = PropertyUnitAddStyle()
    return (
        <>
            <Stack direction={"row"} alignItems="center" justifyContent={"space-between"} spacing={2}>
                <Grid container className={classes.container}>
                    <Grid item className={classes.grid} md={6}>
                        {
                            data?.property?.property_id?.length > 0 ?
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} alignItems="center" p={1} sx={{ width: 230 }}>
                                        <Typography className={classes.selectedTitle} noWrap>{data?.property?.property_name ?? "-"}, </Typography>
                                        <Typography className={classes.selectedSubTitle} noWrap>{`${data?.property?.property_address?.state ? data?.property?.property_address?.state : "-"} ${data?.property?.property_address?.country ? data?.property?.property_address?.country : "-"}`}</Typography>
                                    </Stack>
                                    {
                                        state?.type !== "view" &&
                                        <IconButton onClick={() => {
                                            setPropertyPopup(true)
                                            setSelectedTodo(data)
                                        }}>
                                            <CloseIcon sx={{ fontSize:"0.75rem" }} />
                                        </IconButton>
                                    }
                                </Stack>
                                :
                                <Stack direction={"row"} p={1} spacing={2} onClick={() => {
                                    if (state?.type !== "view") {
                                        setPropertyPopup(true)
                                        setSelectedTodo(data)
                                    }
                                }
                                }>
                                    <AddIcon sx={{ color: "#5078E1" }} />
                                    <Typography className={classes.text}>{t("Choose Property")}</Typography>
                                </Stack>
                        }
                    </Grid>
                    <Grid item className={classes.grid} md={6}>
                        {
                            data?.unit?.unit_id?.length > 0 ?
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} alignItems="center" p={1} sx={{ width: 230 }}>
                                        <Typography className={classes.selectedTitle} noWrap>{data?.unit?.unit_name ?? "-"}, </Typography>
                                        <Typography className={classes.selectedSubTitle} noWrap>
                                            {`${data?.unit?.address?.state ? data?.unit?.address?.state : "-"} ${data?.unit?.address?.country ? data?.unit?.address?.country : "-"}`}
                                        </Typography>
                                    </Stack>
                                    {
                                        state?.type !== "view" &&
                                        <IconButton onClick={() => {
                                            setUnitPopup(true)
                                            setSelectedTodo(data)
                                        }}>
                                            <CloseIcon sx={{ fontSize:"0.75rem" }} />
                                        </IconButton>
                                    }
                                </Stack>
                                :
                                <Stack direction={"row"} p={1} spacing={2} onClick={() => {
                                    if (state?.type !== "view") {
                                        setUnitPopup(true)
                                        setSelectedTodo(data)
                                    }
                                }}>
                                    <AddIcon sx={{ color: "#5078E1" }} />
                                    <Typography className={classes.text}>{t("Choose Unit")}</Typography>
                                </Stack>
                        }
                    </Grid>
                </Grid>
                {
                    state?.type !== "view" &&
                    <Box className={classes.deleteBtn} onClick={() => removeTodoList(data)}>
                        <DeleteIcon />
                    </Box>
                }
            </Stack>
        </>
    )
}