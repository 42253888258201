export const NumberSequenceHeading = (t)=>[
    { title: t("Configuration Name"), field: "configuration_name" },
    { title: t("Configuration Id"), field: "configuration_id" },
    { title: t("Number Of Preference"), field: "no_of_preference" },
    { title: t("Prefix"), field: "prefix" },
    { title: t("Minimum Number Of Digit"), field: "min_num_of_digit" },
    { title: t("Seed Value"), field: "seed_value" },
]

export const NumberSequenceType = [
    { type: ["text"], name: "configuration_name" },
    { type: ["text"], name: "configuration_id" },
    { type: ["text"], name: "no_of_preference" },
    { type: ["text"], name: "prefix" },
    { type: ["text"], name: "min_num_of_digit" },
    { type: ["text"], name: "seed_value" },
]

export const NumberSequencePath = [
    "configuration_name",
    "configuration_id",
    "no_of_preference",
    "prefix",
    "min_num_of_digit",
    "seed_value"
]