export const Brokers = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="broker">
      <path
        data-name="Path 93625"
        d="m22.674 18.496-3.116-2.493a.312.312 0 0 0-.386 0l-1.724 1.377a5.521 5.521 0 0 0-3.069-1.926v-.408a3.116 3.116 0 0 0 1.807-2.228 1.087 1.087 0 0 0 .374-2.072V9.703a2.493 2.493 0 0 0-2.492-2.493h-2.181a2.181 2.181 0 0 0-2.182 2.181v1.352a1.091 1.091 0 0 0 .374 2.075 3.116 3.116 0 0 0 1.807 2.231v.405a5.546 5.546 0 0 0-4.2 4.4l-.474 2.564a.312.312 0 0 0 .312.368h14.022a.312.312 0 0 0 .312-.312V19.05c.542 0 .819.069.916-.209a.312.312 0 0 0-.1-.346Zm-5.711-.726c-.935.742-1.1.8-1.006 1.072s.293.209.916.209v3.116h-2.537c-.523-3.655-.458-3.593-.689-3.829a.738.738 0 0 0 .38-.449c1.159.829 1.187.935 1.43.816s.168-.15.168-2.088a4.814 4.814 0 0 1 1.337 1.153Zm-3.829-1.415c-.72-.953-.623-.726-.623-1.106a3.225 3.225 0 0 0 1.246 0c-.001.374.102.149-.624 1.106Zm1.757-.109c.159.065.112-.174.112 1.558l-1.433-1.025.583-.757a4.914 4.914 0 0 1 .738.224Zm1.359-4.079v-.879a.467.467 0 0 1 0 .879Zm-6.232-.879v.879a.467.467 0 0 1 0-.879Zm.623.907V11.17a2.181 2.181 0 0 0 1.144-.816 1.636 1.636 0 0 0 .1-.153 2.181 2.181 0 0 0 1.87 1.059h1.869v.935a2.493 2.493 0 0 1-4.985 0Zm.735 4.051a5.266 5.266 0 0 1 .735-.218l.573.766-1.421 1.028c0-1.726-.047-1.5.112-1.577Zm-3.079 3.739a4.926 4.926 0 0 1 2.343-3.368v1.81a.312.312 0 0 0 .312.312c.14 0 .1 0 1.284-.848.034-.025.034.262.383.446-.221.227-.143 0-.689 3.829H7.898Zm10.133 2.181v-1.87a.312.312 0 0 1 .312-.312h1.246a.312.312 0 0 1 .312.312v1.87Z"
        fill={props?.fill ?? "#c1c5cb"}
      />
    </g>
    <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
  </svg>
)
