import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: 'calc(100vh - 160px)',
        overflow: "auto",
        backgroundColor: "white"
    },
    root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        width: '100%',
        padding: theme.spacing(1),
        height: 'calc(100vh - 176px)',
        overflow: 'auto',
    },
    label: {
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        marginBottom: theme.spacing(1)
    },
    title_template: {
        fontSize:"1rem",
        color: "#091B29",
        fontFamily: Bold,
        // padding: "15px"
    },
    tab: {
        fontFamily: Bold,
        fontSize:"0.875rem"
    },
    tab_container: {
        '& .MuiTabs-indicator': {
            height: '4px',
            borderRadius: "4px 4px 0px 0px"
        }
    },
    container: {
        border: `1px solid #E4E8EE`,
        borderRadius: '8px',
        height: '100%',
        position: "relative",
        cursor: "pointer"
    },
    container1: {
        padding: "12px",
        borderTop: `1px solid #E4E8EE`,
    },
    language: {
        color: '#98A0AC',
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        backgroundColor: '#F2F4F7',
        borderRadius: '4px',
        padding: '5px'
    },
    view: {
        color: '#5078E1',
        fontFamily: Bold,
        fontSize:"0.75rem",
        cursor: 'pointer',
        position:"absolute",
        top:"0",
        left:"0",
        right:"0",
        width:"100%",
        textAlign:"center",
        zIndex:999,
        backgroundColor:'#ffffff96',
        height:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"

    },
    pdfImg: {
        height: '119px',
        overflow: 'auto',
        padding: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: '#F5F7FA',
        borderRadius: "4px 4px 0px 0px",
        position:"relative"
    },
    templateTitle: {
        color: "#4E5A6B",
        fontSize:"0.875rem",
        fontFamily: Bold,
        marginTop: "13px"
    },
    selectBoxParent: {
        position: "absolute",
        top: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        zIndex: 999
    },
    selectBox: {
        backgroundColor: "#5078E1",
        borderRadius: '0px 0px 8px 8px',
        display: "flex",
        alignItems: "center",
        padding: "6px"
        // justifyContent:"center"
    },
    DoneParent: {
        backgroundColor: "#fff",
        borderRadius: "50%",
        height: '20px',
        width: '20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    doneText: {
        color: '#fff',
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        marginLeft: "10px"
    },

    selectBoxDefault: {
        backgroundColor: "#fff",
        borderRadius: '0px 0px 8px 8px',
        display: "flex",
        alignItems: "center",
        padding: "6px",
        border: `1px solid #5078E1`,
        borderTop: "none",
        cursor: "pointer"

        // justifyContent:"center"
    },
    DoneParentDefault: {
        backgroundColor: "#5078E1",
        borderRadius: "50%",
        height: '20px',
        width: '20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    doneTextDefault: {
        color: '#5078E1',
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        marginLeft: "10px"
    },
    dialogTitle: {
        color: '#4E5A6B',
        fontFamily: Bold,
        fontSize:"0.875rem"
    },
    pdfImgs: {
        height: "100%",
        // display:"flex",
        // alignItems:"center",
        // justifyContent:"center",
        width: '100%',
        textAlign: 'center',
        overflow: 'auto'
    },
    tabName: {
        color: "#4E5A6B",
        fontFamily: Bold,
        fontSize:"0.875rem",
        textAlign: "center",
        cursor: "pointer",
        position: "relative",
        padding: "16px 0px 9px",



    },
    tabNameseleted: {
        color: "#5078E1",
        fontFamily: Bold,
        fontSize:"0.875rem",
        position: "relative",
        textAlign: "center",
        padding: "16px 0px 9px",
        cursor: "pointer"
    },
    absolute: {
        position: "absolute",
        height: "5px",
        backgroundColor: "#5078E1",
        borderRadius: "4px 4px 0px 0px",
        bottom: "-4px",
        width: "100%",
        left: 0,
        right: 0
    },
    save: {
        backgroundColor: "#5078E1",
        color: "#fff",
        fontFamily: SemiBold
    },
    explore:{
        border: "1px solid #5078E1",
        color: "#5078E1",
        fontFamily: SemiBold,
        backgroundColor:"#fff",
        '&:hover':{
            border: "1px solid #5078E1",
            color: "#5078E1",
            fontFamily: SemiBold,
            backgroundColor:"#fff"
        }
    },
    tabOnly: {
        [theme.breakpoints.down('md')]: {
            marginBottom: "16px"

        },
    },
    buttons: {
        position: "absolute",
        bottom: "-24px",
        width: "97.5%",
        display: "flex",
        justifyContent: "end"
    },
    custom:{
        background:"#5AC782",
        padding:"2px 4px",
        backgroundColor:"4px",
        position:"absolute",
        bottom:"8px",
        left:"8px",
        color:"#fff",
        fontSize:"0.75",
        fontFamily:SemiBold,
        borderRadius:"4px",
        cursor:"pointer",
        zIndex:999
    },
    paginate: {
        direction: "ltr",
        "& .MuiButtonBase-root.MuiPaginationItem-root": {
          borderRadius: theme.palette.borderRadius,
          border: "none",
          color: "#4E5A6B",
          fontSize:"0.75",
          fontFamily:Bold
        },
        "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
          color: "#FFFF",
          borderRadius: theme.palette.borderRadius,
          border: "none",
          fontSize:"0.75",
          fontFamily:Bold
        //   backgroundColor: "#5078E1",
        },
      },

      pageCount:{
        color:"#4E5A6B",
        fontSize:"0.75",
        fontFamily:Bold,
        padding:"0px 10px",
        '& span':{
            color:"#98A0AC",
            fontSize:"0.75",
            fontFamily:Bold, 
        }
      },
      root1: {
          backgroundColor: '#FFFFFF',
          borderRadius: 4,
          width: '100%',
          overflow: 'auto',
      }
}))

