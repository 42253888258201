import React from "react";
import { withNavBars } from "../../HOCs";
import KycDocumentType from "./kycDocumentType";

class KycDocumentTypeParent extends React.Component {
  render() {
    return <KycDocumentType {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(KycDocumentTypeParent, props);
