import React from "react";
import { Box } from "@mui/material";
import { useStyles } from "./style";

export const Tab = (props) => {
  // classes
  const classes = useStyles();
  //   props
  const { tabValue, onClickTab, t } = props;

  const tabData = [
    {
      label: t("Published"),
    },
    {
      label: t("Draft"),
      is_draft: true,
    },
  ];
  return (
    <Box className={classes.tab}>
      {tabData?.map((x, index) => {
        return (
          <Box
            className={
              tabValue === index ? classes.activetabContant : classes.tabContant
            }
            onClick={() => onClickTab(index)}
          >
            {x?.label}
            <Box className={tabValue === index ? classes.borderAbsolute : ""} />
            {x?.is_draft && (
              <span className={tabValue === 1 ? classes.draft1 : classes.draft}>
                {props?.count ?? 0}
              </span>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
