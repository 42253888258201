import { Box, Grid } from '@mui/material'
import React from 'react'
import { EmployeeCard } from './component/employeeCard'
import { styled } from '@mui/material/styles';
import { CalendarChangeBtn } from './component/calendarChangeBtn';
import { Calendar } from './component/calender';
import { addMonths, endOfMonth, endOfWeek, format, startOfMonth, startOfWeek, subMonths } from 'date-fns'
import { ChangeEmployee } from './component/changeEmployee';
import { AlertDialog, Subheader, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { AlertProps, NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from '../../utils';
import { TableList } from './component/tableList';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

const CustomPaper = styled('div')(({ theme }) => ({
    borderRadius: "4px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 100px)`,
    overflow: "hidden",
    margin: "16px"
}))

const DutyRoster = ({
    loading,
    t = () => false
}) => {
    const alert = React.useContext(AlertContext)
    const debounce = UseDebounce()
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);

    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [permission, setPermission] = React.useState({});
    const [selectedMonth, setSelectedMonth] = React.useState(new Date())
    const [dates, setDates] = React.useState([])
    const [dutyData, setDutyData] = React.useState({
        openEmployee: false,
        employeeList: [],
        employeeListCount: 0,
        selectedEmploee: "",
        calendarData: [],
        openTable: false,
        viewDate: new Date()
    })
    const [offset, setOffset] = React.useState(0)
    const [search, setSearch] = React.useState("")

    const updateState = (key, value) => {
        setDutyData({ ...dutyData, [key]: value })
    }

    const monthStart = startOfMonth(selectedMonth);
    const monthEnd = endOfMonth(monthStart);
    const monthstartDate = startOfWeek(monthStart);
    const monthendDate = endOfWeek(monthEnd);
    const day = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
    ]

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list);
                    setSelectedCompany(company?.selected);
                    let finaldates = dateRange(monthstartDate, monthendDate)
                    setDates(finaldates)
                    getEmployee(0, "", false, true, company?.selected?.value)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const handleCompanyChange = (e) => {
        setSelectedCompany(e);
        getEmployee(0, "", false, true, e?.value)
    };

    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return dates;
    }

    const monthnext = () => {
        let tempMonth = addMonths(selectedMonth, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        setSelectedMonth(tempMonth)
        let finaldays = dateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setDates(finaldays)
        getCalendarData(monthStart, monthEnd, dutyData?.selectedEmploee)
        // sendDateRange(tempDateRange, selected)
    }

    const monthprev = () => {
        let tempMonth = subMonths(selectedMonth, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        setSelectedMonth(tempMonth)
        let finaldays = dateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setDates(finaldays)
        getCalendarData(monthStart, monthEnd, dutyData?.selectedEmploee)
        // sendDateRange(tempDateRange, selected)
    }

    const getEmployee = (offset = 0, search = "", bool = false, initial = false, company_id = selectedCompany?.value) => {
        const payload = {
            company_id: company_id,
            offset: offset,
            limit: 10,
            active: [true],
            search: search
        }
        NetworkCall(
            `${config.api_url}/resources/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (initial) {
                getCalendarData(monthStart, monthEnd, res?.data?.result, res?.data?.count, initial, false, company_id)
            } else {
                if (bool) {
                    setDutyData({
                        ...dutyData,
                        employeeList: [...dutyData?.employeeList, ...res?.data?.result],
                        employeeListCount: res?.data?.count
                    })
                } else {
                    if (initial) {
                        setDutyData({
                            ...dutyData,
                            employeeList: res?.data?.result,
                            employeeListCount: res?.data?.count,
                            selectedEmploee: res?.data?.result?.[0]

                        })
                    }
                    else {
                        setDutyData({
                            ...dutyData,
                            employeeList: res?.data?.result,
                            employeeListCount: res?.data?.count,
                        })
                    }

                }
            }
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong
            });
        })
    }



    const getCalendarData = (startDate = monthStart, endDate = monthEnd, empData = [], empDataCount = 0, initial = false, calendar = false, company_id = selectedCompany?.value) => {
        const payload = {
            company_id: company_id,
            resource_id: initial ? empData?.[0]?.id : empData?.id,
            date_range: [
                format(startDate, "yyyy-MM-dd"),
                format(endDate, "yyyy-MM-dd")
            ]
        }
        NetworkCall(
            `${config.api_url}/duty_roaster/calendar_view`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            if (initial) {
                setDutyData({
                    ...dutyData,
                    employeeList: empData,
                    employeeListCount: empDataCount,
                    selectedEmploee: empData?.[0],
                    calendarData: res?.data?.data
                })
            } else if (calendar) {
                setDutyData({
                    ...dutyData,
                    calendarData: res?.data?.data,
                    openEmployee: false,
                    selectedEmploee: empData
                })

            } else {
                setDutyData({
                    ...dutyData,
                    calendarData: res?.data?.data
                })
            }
        }).catch((err) => {
            console.log(err)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong
            });
        })
    }


    const fetchMoreEmployee = () => {
        const off = offset + 10
        setOffset(off)
        getEmployee(off, "", true)
    }

    const handleSelectEmployee = (data) => {
        getCalendarData(startOfMonth(selectedMonth), endOfMonth(selectedMonth), data, 0, false, true)
    }

    const handleSearchEmploee = (search = "") => {
        setSearch(search)
        setOffset(0)
        debounce(() => getEmployee(0, search), 800)
    }

    const openTableView = (date) => {
        setDutyData({
            ...dutyData,
            openTable: true,
            viewDate: date
        })
    }

    const render = () => {
        return (
            <>
                <Subheader
                    hideBackButton={true}
                    title={t("On Duty Allocation")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) => handleCompanyChange(e)} />
                <CustomPaper>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={3} sm={6}>
                            <EmployeeCard
                                openPopup={() => updateState("openEmployee", true)}
                                data={dutyData?.selectedEmploee} t={t} />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                            <CalendarChangeBtn monthnext={monthnext} monthprev={monthprev} selectedMonth={selectedMonth} t={t} />
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <Calendar
                            selectedMonth={selectedMonth}
                            dates={dates}
                            day={day}
                            calendarData={dutyData?.calendarData}
                            openTableView={openTableView} t={t} />
                    </Box>
                </CustomPaper>

                <AlertDialog
                    open={dutyData?.openEmployee}
                    onClose={() => {
                        updateState("openEmployee", false)
                        setSearch("")
                    }}
                    header={t("Change Employee")}
                    isNormal={true}
                    component={
                        <Box p={2}>
                            <ChangeEmployee
                                dutyData={dutyData}
                                fetchMoreData={fetchMoreEmployee}
                                handleSelect={handleSelectEmployee}
                                search={search}
                                handleSearchEmploee={handleSearchEmploee}
                                t={t} />
                        </Box>}
                />

                <AlertDialog
                    open={dutyData?.openTable}
                    onClose={() => updateState("openTable", false)}
                    header={moment(dutyData?.viewDate).format("DD-MM-YYYY")}
                    lg={true}
                    component={
                        <Box p={2}>
                            <TableList dutyData={dutyData} t={t} selectedCompany={selectedCompany} />
                        </Box>}
                />
            </>
        )
    }

    return <>{accessCheckRender(render, permission, "", loading)}</>

}

export default withNamespaces("dutyRoster")(DutyRoster)