import * as React from "react"

const Furniture = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 96257">
      <path
        d="M6.549 3.098a2.37 2.37 0 0 0-2.361 2.361V6.74a2.007 2.007 0 0 0-1.817 1.989 1.982 1.982 0 0 0 1.453 1.887v2.473a2.732 2.732 0 0 0 1.453 2.409v.864a.545.545 0 1 0 1.09 0v-.554c.06 0 .12.009.182.009h6.908c.059 0 .117-.005.176-.009v.554a.545.545 0 1 0 1.09 0v-.864a2.73 2.73 0 0 0 1.459-2.4v-2.479a1.982 1.982 0 0 0 1.45-1.886 2.007 2.007 0 0 0-1.817-1.989V5.459a2.37 2.37 0 0 0-2.363-2.361Zm0 1.09h6.9a1.264 1.264 0 0 1 1.272 1.272v1.338a2.371 2.371 0 0 0-1.817 2.295v1.131a1.973 1.973 0 0 0-.908-.223h-4a1.973 1.973 0 0 0-.908.223V9.092a2.371 2.371 0 0 0-1.811-2.294V5.459a1.264 1.264 0 0 1 1.272-1.271Zm2.18 2.543a.545.545 0 1 0 .545.545.545.545 0 0 0-.545-.545Zm2.543 0a.545.545 0 1 0 .545.545.545.545 0 0 0-.546-.545Zm-6.901 1.09h.274a.545.545 0 0 0 .14 0 1.261 1.261 0 0 1 1.22 1.267v4a.545.545 0 0 0 .545.545h6.9a.545.545 0 0 0 .545-.545v-4a1.261 1.261 0 0 1 1.22-1.267.545.545 0 0 0 .138 0h.276a.908.908 0 0 1 0 1.817.545.545 0 0 0-.544.546v2.911a1.626 1.626 0 0 1-1.635 1.629H6.549a1.626 1.626 0 0 1-1.635-1.635v-2.907a.545.545 0 0 0-.543-.545.908.908 0 1 1 0-1.817Zm3.633 3.27h4a.9.9 0 0 1 .908.908v.545H7.094v-.546a.9.9 0 0 1 .908-.907Z"
        fill="#071741"
        data-name="Group 96235"
      />
    </g>
  </svg>
)

export default Furniture
