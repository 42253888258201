import React from 'react'
import { CreateNewEditContext } from '../createNewEditContext'
import { Box, Button, Divider, Radio, Stack, Typography } from '@mui/material';
import { NewRateConfigComponent } from './newRateConfigComponent';
import { NewRateConfigurationStyle } from './style';

// need to add translate (t)

export const NewRateConfiguration = () => {

    const {
        enumValue,
        contractData,
        resetCommission,
        addCommission,
        t,
        selectedValue,
        setSelectedValue,
        stateData
    } = React.useContext(CreateNewEditContext)

    const classes = NewRateConfigurationStyle()

    const radio = {
        "Fixed Pricing": { radioValue: "a", subtitle: t("Fixed Pricing") },
        "Percentage Base": { radioValue: "b", subtitle: t("Percentage Base") },
        "Custom": { radioValue: "c", subtitle: t("Custom") },
    }
    let mapData = enumValue?.rateConfiguration?.map((e, i) => {
        return {
            label: e?.label,
            value: e?.value,
            ...radio?.[e?.value]
        }
    })

    return (
        <>
            {
                mapData?.map((d, i, length) => {
                    return (
                        <Box>

                            <Stack direction={"row"} py={2}
                                onClick={() => {
                                    if (stateData?.type !== "view") {
                                        resetCommission(d)
                                        setSelectedValue(d?.radioValue)
                                    }
                                }}
                                sx={{ cursor: "pointer" }} alignItems={"center"}>
                                <Radio
                                    checked={selectedValue === d?.radioValue}
                                    value={d?.radioValue}
                                    name="radio-buttons"
                                    sx={{ height: 18, width: 18 }}
                                />
                                <Stack sx={{ marginInlineStart: "10px" }}>
                                    <Typography className={classes.title}> {d?.label}</Typography>
                                    {/* <Typography className={classes.subtitle}> {d?.subtitle} </Typography> */}
                                </Stack>
                            </Stack>
                            <Box mb={selectedValue === d?.radioValue ? 2 : 0}>
                                {
                                    (selectedValue === d?.radioValue && selectedValue !== 'c') &&
                                    contractData?.commissionList?.map((row, i) => {
                                        return (
                                            <Box mb={1}>
                                                <NewRateConfigComponent t={t} selectedValue={selectedValue} data={d} rowData={row} index={i} />
                                            </Box>
                                        )
                                    })
                                }


                                {
                                    (selectedValue === d?.radioValue && selectedValue !== 'c' && stateData?.type !== "view") &&
                                    <Box mt={2}>
                                        <Button variant="outlined" className={classes.addBtn}
                                            onClick={addCommission}
                                        //     disabled={state?.type === "view"}
                                        >
                                            {t("Add Pricing Factor")}
                                        </Button>
                                    </Box>
                                }
                            </Box>


                            {i !== length?.length - 1 && <Divider></Divider>}

                        </Box>
                    )
                })
            }
        </>
    )
}