export const PriceBook = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98465">
            <path
                d="M19.677 8h-3.765a.439.439 0 0 0-.313.129L7.128 16.6a.439.439 0 0 0 0 .621l5.648 5.648a.439.439 0 0 0 .621 0l8.474-8.469a.439.439 0 0 0 .128-.312v-3.766A2.325 2.325 0 0 0 19.677 8Zm-4.707 9.822h-.031v.5a.44.44 0 0 1-.879 0v-.5h-.5a.44.44 0 0 1 0-.879h1.41a.5.5 0 1 0 0-1h-.941a1.381 1.381 0 0 1 0-2.762h.031v-.5a.44.44 0 0 1 .879 0v.5h.5a.44.44 0 1 1 0 .879h-1.41a.5.5 0 1 0 0 1h.941a1.381 1.381 0 0 1 0 2.762Zm4.707-7.061a.439.439 0 1 1 .439-.439.44.44 0 0 1-.439.44Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path data-name="Rectangle 52149" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
