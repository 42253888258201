export const Settings = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <path data-name="Rectangle 52494" fill="none" d="M0 0h30v30H0z" />
        <g data-name="Configuration">
            <path
                data-name="Path 95316"
                d="M22.177 13.871 21 13.722a5.906 5.906 0 0 0-.356-.859l.726-.934a.929.929 0 0 0-.07-1.237l-.984-.984a.932.932 0 0 0-1.241-.079l-.933.726a5.885 5.885 0 0 0-.864-.355l-.149-1.175A.936.936 0 0 0 16.2 8h-1.4a.936.936 0 0 0-.929.823L13.722 10a5.817 5.817 0 0 0-.859.356l-.934-.726a.93.93 0 0 0-1.237.07l-.984.984a.932.932 0 0 0-.079 1.241l.726.934a5.837 5.837 0 0 0-.355.863l-1.175.149A.936.936 0 0 0 8 14.8v1.4a.936.936 0 0 0 .823.929l1.177.149a5.906 5.906 0 0 0 .356.859l-.726.934a.929.929 0 0 0 .07 1.237l.984.984a.932.932 0 0 0 1.241.079l.934-.726a5.77 5.77 0 0 0 .863.355l.149 1.172A.936.936 0 0 0 14.8 23h1.4a.936.936 0 0 0 .929-.823L17.278 21a5.906 5.906 0 0 0 .859-.356l.934.726a.929.929 0 0 0 1.237-.076l.984-.984a.931.931 0 0 0 .079-1.241l-.726-.934a5.77 5.77 0 0 0 .355-.857l1.172-.149A.936.936 0 0 0 23 16.2v-1.4a.935.935 0 0 0-.823-.929ZM15.5 18.625a3.125 3.125 0 1 1 3.125-3.125 3.129 3.129 0 0 1-3.125 3.125Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
        </g>
    </svg>
)
