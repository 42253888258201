export const Dashboard = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g
      id="Group_99694"
      data-name="Group 99694"
      transform="translate(-1646 480)"
    >
      <g
        id="Group_8127"
        data-name="Group 8127"
        transform="translate(-10 -1483)"
      >
        <g
          id="Group_99684"
          data-name="Group 99684"
          transform="translate(-4 -42)"
        >
          <path
            id="Rectangle_31"
            data-name="Rectangle 31"
            transform="translate(1660 1045)"
            style={{
              fill: "none",
            }}
            d="M0 0h30v30H0z"
          />
          <g
            id="dashboard_1_"
            data-name="dashboard (1)"
            transform="translate(1668 1053)"
          >
            <path
              id="Path_95402"
              data-name="Path 95402"
              className="cls-2"
              d="M5.781 0H1.094A1.1 1.1 0 0 0 0 1.094v2.812A1.1 1.1 0 0 0 1.094 5h4.687a1.1 1.1 0 0 0 1.094-1.094V1.094A1.1 1.1 0 0 0 5.781 0Zm0 0"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95403"
              data-name="Path 95403"
              className="cls-2"
              d="M5.781 213.332H1.094A1.1 1.1 0 0 0 0 214.426v6.563a1.1 1.1 0 0 0 1.094 1.094h4.687a1.1 1.1 0 0 0 1.094-1.094v-6.563a1.1 1.1 0 0 0-1.094-1.094Zm0 0"
              transform="translate(0 -207.082)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95404"
              data-name="Path 95404"
              className="cls-2"
              d="M283.113 341.332h-4.687a1.1 1.1 0 0 0-1.094 1.094v2.813a1.1 1.1 0 0 0 1.094 1.094h4.688a1.1 1.1 0 0 0 1.094-1.094v-2.812a1.1 1.1 0 0 0-1.095-1.095Zm0 0"
              transform="translate(-269.207 -331.332)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95405"
              data-name="Path 95405"
              className="cls-2"
              d="M283.113 0h-4.687a1.1 1.1 0 0 0-1.094 1.094v6.562a1.1 1.1 0 0 0 1.094 1.094h4.688a1.1 1.1 0 0 0 1.094-1.094V1.094A1.1 1.1 0 0 0 283.113 0Zm0 0"
              transform="translate(-269.207)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
