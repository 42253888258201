import ClearIcon from "@mui/icons-material/Clear";
import { Dialog, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import React from "react";
import { ParkingDetails } from "../../../components";
import { useStyles } from "../style";


export const GatePassModal = ({t=()=>false,title="",open=false,setOpen=()=>false,request_id=""}) =>{

    const classes=useStyles()

    return(
        <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className={classes.dialog}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.dialogTitle}>{title}</Typography>
          <ClearIcon
            onClick={() => setOpen(false)}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <Divider />
        <DialogContent style={{ padding: 0 }}>
          <ParkingDetails
            passId={request_id}
            onCancel={() => setOpen(false)}
            t={t}
          />
        </DialogContent>
      </Dialog>
    )
}