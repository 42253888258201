import React from "react";
import { withNavBars } from "../../HOCs";
import SiteVisit from "./siteVisit";

class SiteVisitParent extends React.Component {
  render() {
    return <SiteVisit />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(SiteVisitParent, props);