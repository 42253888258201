import CircleIcon from "@mui/icons-material/Circle";
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import QRCode from "react-qr-code";
import { timeZoneConverter } from "../../../utils";
import { DeclineQRcodeStyle } from './style';

export const DeclineQRcode = ({ data = {},selectedCompany={} }) => {
    const classes = DeclineQRcodeStyle()
    return (
        <Box p={2} >
            {/* <Box className={classes.shareIcon}>
                <ShareSvg />
            </Box> */}
            <Stack alignItems={"center"} spacing={2}>
                <img src={selectedCompany?.logo} alt='logo' width={"100px"}/>
                {data?.qr_no?.length > 0 &&
                    <QRCode value={data?.qr_no} size={150} />
                }

                <div
                    className={classes.divRoot}
                >
                    <Box
                        className={classes.boxRoot}
                    >
                        <Box style={{ overflow: "hidden" }}>
                            <CircleIcon
                                className={classes.circle}
                            />
                        </Box>
                        <Box flexGrow={1} padding={"8px 36px"}>
                            <Box display={"flex"}>
                                {data?.parking_slot?.allocated_from &&
                                    <Typography className={classes.expire2}>
                                        {
                                            timeZoneConverter(data?.parking_slot?.allocated_from , "DD MMM YY,hh:mm A")
                                               + " - "
                                        }
                                    </Typography>
                                }
                                {data?.parking_slot?.allocated_to &&
                                    <Typography className={classes.expire2} marginLeft={"2px"}>
                                        {
                                            timeZoneConverter(data?.parking_slot?.allocated_to , "DD MMM YY,hh:mm A")
                                            }
                                    </Typography>
                                }
                            </Box>

                            <Typography
                                variant="subtitle2"
                                className={classes.expire2}
                                textAlign={"center"}
                            >
                                Slot Details
                            </Typography>

                        </Box>
                        <Box style={{ overflow: "hidden" }}>
                            <CircleIcon
                                className={classes.circle2}
                            />
                        </Box>
                    </Box>
                </div>
            </Stack>

        </Box>
    )
}