import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../../utils";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
export const useStylesCreation = makeStyles((theme) => ({
  card: {
    boxShadow: (props) => !props?.boxShadow ? "none" : "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    height: (props) => (props?.height ? props?.height : "100%"),
    padding: (props) => props?.padding ?? "16px",
    minHeight: (props) => (props?.maxHeight ?? "100%"),
    width: "100%",
    overflow: "hidden",
    display: 'flex',
    flexFlow: (props) => props?.flexFlow ?? "column",
    position: "relative",
  },
  info_icon: {
    display: "flex",
    alignSelf: "self-end"
  },
  cardDetail: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    height: (props) => (props?.height ? props?.height : "100%"),
    padding: (props) => props?.padding ?? "16px",
    minHeight: (props) => (props?.maxHeight ?? "100%"),
    width: "100%",
    overflow: "auto",
    display: 'flex',
    flexFlow: (props) => props?.flexFlow ?? "column",
    justifyContent: (props) => (props?.justifyContent ?? "space-between")
  },
  detailsCard1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  countCard: {
    fontSize: "1.5rem",
    color: theme.typography.color.primary,
    fontFamily: ExtraBold,
  },
  detailsCard2: {
    color: "#091B29",
    fontFamily: SemiBold,
    fontSize: "0.875rem",
    maxWidth: (props) => props?.maxWidth ?? "107px",
    width: "100%",
  },
  titleFont: {
    color: "#091B29",
    fontSize: "1rem",
    fontFamily: ExtraBold,
    marginInlineStart: "8px"
  },
  flexBoxWithSpace: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: (props) => props?.graphParentPadding ?? "0px",
    paddingBottom: "12px"
  },
  graphDataCount: {
    color: "#4E5A6B",
    fontFamily: SemiBold,
    fontSize: "1rem",
    display: 'flex',
    alignItems: 'center',
    marginInlineStart: "8px !important"
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    padding: '5px'
  },
  dotParent: {
    display: "flex",
    paddingTop: 0,
    alignItems: "center",
  },
  graphDataTitle: {
    color: "#98A0AC",
    fontFamily: SemiBold,
    fontSize: "0.75rem",
    marginInlineStart: "8px ! important",
    textTransform: "capitalize"
  },
  graphmapData: {
    maxWidth: (props) => props?.maxWidthGraph ?? "221px",
    width: "100%",
    margin: "0 auto",
  },
  areaMeter: {
    color: "#091B29",
    fontSize: "1.125rem",
    fontFamily: Bold,
  },
  map: {
    borderRadius: theme.palette.borderRadius,
    overflow: "hidden",
    height: "100%",
    minHeight: '500px',
    position: "relative",
  },
  searchBox: {
    position: "absolute",
    top: 12,
    right: 0,
    left: 0,
    zIndex: 1,
    padding: "0px 10px",
    width: "85%",
  },
  listText: {
    backgroundColor: "#fff",
    width: "120px !important",
    color: "#1C1C1C",
    boxShadow: "#5C86CB2E",
    borderRadius: "4px",
    textAlign: "center",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  listText1: {
    width: "120px !important",
    color: "#98A0AC",
    borderRadius: "4px",
    textAlign: "center",
    height: "30px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  listtabText: {
    backgroundColor: "#fff",
    width: "fit-content !important",
    color: "#1C1C1C",
    boxShadow: "#5C86CB2E",
    borderRadius: "4px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  listtabText1: {
    width: "fit-content !important",
    color: "#98A0AC",
    borderRadius: "4px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  tabname: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    cursor: "pointer",
  },
  list: {
    backgroundColor: "#F5F7FA",
    display: "flex",
    padding: "5px",
    borderRadius: "4px",
    "&.MuiListItem-root": {
      width: "100px !important",
      padding: "0px!important",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      cursor: "pointer",
      borderRadius: " 4px",
    },
  },
  cardNew: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  Expensestitle: {
    fontSize: "0.875rem",
    fontFamily: ExtraBold,
  },
  grid: {
    display: "flex",
    padding: "0px 12px",
    overflow: "auto",
    // alignItems: "center",
  },
  num: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: Regular,
  },
  noData: {
    textAlign: "center",
    // marginTop: "30%",
    [theme.breakpoints.down("md")]: {
      marginTop: "10%",
    },
  },
  weatherBox: {
    maxWidth: "140px",
    width: "100%",
    borderRadius: "4px",
    backgroundColor: theme.palette.weather.main,
    padding: "16px",
    position: "absolute",
    bottom: 16,
    right: 16,
  },
  totalUnit: {
    color: theme.typography.color.white,
    fontSize: "1rem",
    fontFamily: Regular,
  },
  finder: {
    backgroundColor: "#5078E1",
    padding: "10px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#5078E1",
    },
  },
  noDataGraph: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  },
  graphMargin: {
    marginTop: (props) => props?.margin ?? "20px",
    height: "100%",
  },
  graphMarginFlex: {
    margin: (props) => props?.margin ?? "auto"
  },
  requestTab: {
    padding: "12px 24px 24px",
  },
  scrollLgend: {
    maxHeight: (props) => props?.maxHeightGraph ?? "90px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  graphParentFlex: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  dividerBox: {
    padding: "12px",
  },
  dividerBoxText: {
    color: "#FFFFFF",
    fontSize: "0.875rem",
    fontFamily: Bold,
  },
  cardHandover: {
    display: 'flex',
    alignItems: 'center',
    // paddingInline: "12px"

  },
  gridGraphParent: {
    // paddingLeft: (props) => props?.gridGraphParentPadding ?? '55px !important',
    display: "grid",
    gridTemplateColumns: (props) => props?.gradiantStyle?.grid_template ?? "50% 50%",
    gridGap: "10px",
    width: "100%"
  },
  mapRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  zoomInOut: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    // zIndex: 1,
    padding: "0px 10px",
    height: 'auto',
    width: "auto"
  },
  viewMaptxt: {
    fontSize: "0.875rem",
    color: "#5078E1",
    fontFamily: SemiBold
  },
  viewMap: {
    backgroundColor: "#F1F7FF",
    borderRadius: theme.palette.borderRadius,
    padding: "4px 8px",
    height: "30px"
  },
  KYCcard: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    alignItems: "center"
  },
  title: {
    fontSize: "1.125rem",
    fontFamily: Bold,
    color: theme.typography.color.primary
  },
  subTitle: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: theme.typography.color.primary
  },
  viewBox: {
    padding: "3px",
    border: "1px solid #5078E1",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#F1F7FF",
    width: "50px",
    display: "flex",
    textAlign: "center",
    marginTop: "5px",
    justifyContent: 'center'
  },
  viewTxt: {
    fontSize: "0.75rem",
    fontFamily: Bold,
    color: "#5078E1"
  },
  grapAbsolute: {
    position: (props) => props?.is_popUp ? "relative" : props?.position ?? "absolute",
    left: "0",
    right: "0",
    bottom: "16px",
    height: (props) => props?.gradiantStyle?.height?.lg ?? 'auto',
    maxHeight: (props) => props?.gradiantStyle?.maxHeight ?? '63px',
    width: "100%",
    overflow: "overlay",
    display: (props) => props?.is_popUp ? 'flex' : 'auto',
    alignItems: (props) => props?.gradiantStyle?.alignItems ?? 'auto',
    [theme.breakpoints.down("md")]: {
      position: (props) => props?.gradiantStyle?.relative?.md ?? "relative !important",
      top: (props) => props?.gradiantStyle?.top?.md ?? "16px",
      maxHeight: (props) => props?.gradiantStyle?.height?.md ?? '63px',
      height: (props) => props?.gradiantStyle?.height1?.md ?? "100% !important",
      bottom: (props) => props?.gradiantStyle?.bottom?.md ?? "16px",


    },
    [theme.breakpoints.down("sm")]: {
      position: (props) => props?.gradiantStyle?.relative?.sm ?? "relative !important",
      top: (props) => props?.gradiantStyle?.top?.sm ?? "16px",
      maxHeight: (props) => props?.gradiantStyle?.height?.sm ?? '63px',
      bottom: (props) => props?.gradiantStyle?.bottom?.sm ?? "16px",
      height: "100% !important"

    },

  },
  graphHeigth: {
    overflow: "auto",
    overflowX: "hidden",
    display: "flex",
    alignItems: (props) => props?.gradiantStyle?.alignItems ?? "end",
    margin: (props) => props?.gradiantStyle?.margin ?? "auto",
    padding: '0px 16px',
    width: "100%",
  },
  gradiantRelative: {
    height: (props) => props?.gradiantStyle?.height ?? '100%',
    maxHeight: (props) => props?.gradiantStyle?.maxHeight ?? '63px',
    minHeight: (props) => props?.gradiantStyle?.maxHeight ?? '63px'

  },
  graph_pagination_container: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
    marginTop: "16px",
  },
  graph_pagination: {
    direction: "ltr",
    "& .MuiButtonBase-root.MuiPaginationItem-root": {
      borderRadius: theme.palette.borderRadius,
      border: "none",
      color: "#606060",
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      color: "white",
      borderRadius: theme.palette.borderRadius,
      border: "none",
      backgroundColor: "#5078E1",
    },
  },
}));


export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    padding: "12px",
    backgroundColor: "#FFFFFF",
    color: "#4E5A6B",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
    fontSize: "0.875rem",
    fontFamily: SemiBold,
  },
}));