export const againstCompanyStep = (step) => [
  {
    label: ("Component Type"),
    step: 1,
    value: step,
  },
  {
    label: "Component Details & Appraisal Value",
    step: 2,
    value: step,
  },
  {
    label: "Preview and Create",
    step: 3,
    value: step,
  },
];

export const againstPropertyStep = (step) => [
  {
    label: "Property Details",
    step: 1,
    value: step,
  },
  {
    label: "Component Type",
    step: 2,
    value: step,
  },
  {
    label: "Component Details & Appraisal Value",
    step: 3,
    value: step,
  },
  {
    label: "Preview and Create",
    step: 4,
    value: step,
  },
];

export const JSON = [
  {
    name: "Appraise Primary Pricing Component",
    tooltip: "Appraise Primary Pricing Component",
    id: 1,
  },
  {
    name: "Appraise secondary components such as Maintenance and Other Services",
    tooltip:
      "Appraise secondary components such as Maintenance and Other Services",
    id: 2,
  },
];

export const JSON1 = [
  {
    name: "Specific Amount Value",
    checked: false,
    sub: "Ex: 1000, 2000, 3000",
    id: 1,
    value: "Specific",
  },
  {
    name: "Amount Value Range",
    checked: false,
    sub: "Ex: 500 - 2000, 2500 - 4500",
    id: 2,
    value: "Range",
  },
];

export const initialState = {
  componentType: "",
  revenueType: "",
  Based_On: "",
  component_name: "",
  amount: "",
  appraisal_amount: "",
  StartDate: "",
  endDate: "",
  amountType: "",
  minAmount: "",
  maxAmount: "",
  error: {
    componentType: "",
    revenueType: "",
    Based_On: "",
    component_name: "",
    amount: "",
    appraisal_amount: "",
    StartDate: "",
    endDate: "",
    minAmount: "",
    maxAmount: "",
  },
};

export const initialStateProperty = {
  type: "Appartment",
  unitPurpose: "",
  unitCatergory: "",
  unitType: "",
  componentType: "",
  property: "",
  revenueType: "",
  Based_On: "",
  component_name: "",
  amount: "",
  appraisal_amount: "",
  StartDate: "",
  endDate: "",
  amountType: "",
  minAmount: "",
  maxAmount: "",
  error: {
    type: "",
    Purpose: "",
    Catergory: "",
    property: "",
    unitType: "",
    componentType: "",
    revenueType: "",
    Based_On: "",
    component_name: "",
    amount: "",
    appraisal_amount: "",
    StartDate: "",
    endDate: "",
    amountType: "",
    minAmount: "",
    maxAmount: "",
  },
};

export const TabelData = [
  {
    Appraisal_Reference_ID: "Appraisal_Reference_ID",
    Component_Type: "Component_Type",
    Pricing_Component_Name: "Pricing_Component_Name",
    Revenue_Type: "Revenue_Type",
    Component_Based_On: "Component_Based_On",
    Previous_Value: "Previous_Value",
    Present_Value: "Present_Value",
    Status: "Status",
  },
];

export const praicingJSON = [
  {
    name: "Primary Pricing Component",
    tooltip:
      "Base rent or monthly rental amount. you can have only one primary pricing component per property.",
    id: 1,
    type: "Primary",
    is_primary: true,
    is_one_time: false,
    is_refundable: false,
    is_item_based_component: false,
    is_parking_slot: false,
    is_short_term_rent: false,
    primaryColor: "#B3776D",
    secondaryColor: "#FEEAEA80",
  },
  {
    name: "Secondary Components",
    tooltip:
      "Identify and configure pricing for maintenance and other services",
    id: 2,
    type: "Secondary",
    is_primary: false,
    is_one_time: false,
    is_refundable: false,
    is_item_based_component: false,
    is_parking_slot: false,
    is_short_term_rent: false,
    primaryColor: "#896DB3",
    secondaryColor: "#EDE4FE80",
  },
  {
    name: "One Time Charges",
    tooltip: "Configure pricing which has to be calculated as one time charges",
    id: 3,
    type: "Onetime",
    is_primary: false,
    is_one_time: true,
    is_refundable: false,
    is_item_based_component: false,
    is_parking_slot: false,
    is_short_term_rent: false,
    primaryColor: "#6DAFB3",
    secondaryColor: "#DBF0F180",
  },
  {
    name: "Refundables Price Component",
    tooltip:
      "Configure pricing which has to be calculated as refundable amount",
    id: 4,
    type: "Refundable",
    is_primary: false,
    is_one_time: false,
    is_refundable: true,
    is_item_based_component: false,
    is_parking_slot: false,
    is_short_term_rent: false,
    primaryColor: "#6D80B3",
    secondaryColor: "#E4EDFF80",
  },
  {
    name: "Inventory Items Component",
    tooltip: "Configure pricing for items like services, materials etc.",
    id: 5,
    type: "Item",
    is_primary: false,
    is_one_time: false,
    is_refundable: false,
    is_item_based_component: true,
    is_parking_slot: false,
    is_short_term_rent: false,
    primaryColor: "#B3A16D",
    secondaryColor: "#FFFAD880",
  },
  {
    name: "Parking Slot Component",
    tooltip:
      "Configure pricing for items that has to be calculated for parking slot amount.",
    id: 6,
    type: "Parking",
    is_primary: false,
    is_one_time: false,
    is_refundable: false,
    is_item_based_component: false,
    is_parking_slot: true,
    is_short_term_rent: false,
    primaryColor: "#B3776D",
    secondaryColor: "#FEEAEA80",
  },
  // {
  //   name: "Appraise Short Term Rent",
  //   tooltip:
  //     "Configure pricing for items that has to be calculated for parking slot amount.",
  //   id: 7,
  //   type: "Short Term",
  //   is_primary: false,
  //   is_one_time: false,
  //   is_refundable: false,
  //   is_item_based_component: false,
  //   is_parking_slot: false,
  //   is_short_term_rent: true,
  // },
];

export const getRangeFunction = (value) => {
  const data = value?.split(",").reduce((prevValue, currValue, index) => {
    return {
      ...prevValue,
      [index === 0 ? "start" : "end"]: Number(
        currValue.replace(/[^a-zA-Z0-9.]/g, "")
      ),
    };
  }, {});

  return data
};
