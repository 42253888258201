import React from 'react';
import { withMasters } from '../../HOCs';

const MasterType = (props) => {
    return (
        <div />
    )
}

export default withMasters(MasterType)
