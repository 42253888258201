import React from "react"

export const BlockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57">
        <g id="Group_112538" data-name="Group 112538" transform="translate(-14715.664 -9782.664)">
            <g id="Rectangle_57306" data-name="Rectangle 57306" transform="translate(14715.664 9782.664)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                <rect width="57" height="57" stroke="none" />
                <rect x="0.5" y="0.5" width="56" height="56" fill="none" />
            </g>
            <path id="icons8-reservation" d="M.027,34.415,4.685,11.124A1.4,1.4,0,0,1,6.055,10H38.663a1.4,1.4,0,0,1,1.384,1.591L36.787,34.883a1.4,1.4,0,0,1-1.384,1.2H1.4a1.4,1.4,0,0,1-1.37-1.671Zm10.687-9.974H29.347a1.4,1.4,0,1,0,0-2.795H10.714a1.4,1.4,0,1,0,0,2.795ZM41.547,14.32,44.7,31.643a1.4,1.4,0,0,1-1.375,1.648H38.891Z" transform="translate(14721.664 9787.664)" fill="#78b1fe" />
        </g>
    </svg>

)