import { Stack, Typography } from "@mui/material";
import React from "react";
import { ContractDetailsCardStyle } from "./style";

export const ContractDetailCard = ({
    title = "",
    subtitle = "",
    logo
}) => {
    const classes = ContractDetailsCardStyle()
    return (
        <Stack direction={"row"} alignItems={"center"} p={2}
            className={classes.card} columnGap={1}>
            {logo}
            <Stack rowGap={"4px"}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.subtitle}>{subtitle}</Typography>
            </Stack>
        </Stack>
    )
}