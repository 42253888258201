import { Box, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { unitStyles } from "../style";
// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';
import { AddMemberCard } from './addmembercard';
import { AlertDialog } from '../../../components'


// Reporting Details card
const list = [
    {
        name: "Reporting Details"
    }
]

export const UnitDetail = (props) => {
    const { t } = (props);
    const classes = unitStyles()
    const size = useWindowDimensions()
    const [imageViwer, setImageViwer] = React.useState(false)
    // const { data } = props;
    // const address = data ? {
    //     latitude: data?.latitude,
    //     longitude: data?.longitude,
    // } : {};


    // const GoogleMap = () => {
    //     // google map
    //     return (
    //         <MyGoogleMap
    //             lat={address?.latitude}
    //             lng={address?.longitude}
    //             center={{
    //                 lat: address?.latitude,
    //                 lng: address?.longitude
    //             }}
    //             zoom={13}
    //             readonly

    //         />
    //     )
    // }

    return (
        <div style={{ height: size?.height - 210, overflow: "auto", padding: "4px", margin: "-4px" }}>
            {/*sales agent  first card */}
            <Grid container className={classes.card}>
                {/* propfile image */}
                <Grid item md={2} lg={1.5} className={classes.imgDiv}>
                    <img src={props?.data?.url ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                        onClick={() => setImageViwer(true)}
                    />
                </Grid>
                {/* profile details */}
                <Grid item md={10} lg={10.5} className={classes.imgDiv2}>
                    <Typography className={classes.title} style={{ marginBottom: '10px' }}>{t("Profile Details")}</Typography>
                    <Grid container>
                        <Grid item md={12} lg={9}>
                            <Box display="flex" justifyContent="space-between" marginTop="8px">
                                <Box>
                                    <Typography className={classes.heading}>{t("Name")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.first_name}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("Company Identification Number")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.company_registration_no}</Typography>
                                </Box>

                                <Box>
                                    <Typography className={classes.heading}>{t("Relation Ship")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.relationship}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("UCID")}</Typography>
                                    <Typography className={classes.sub}>{props?.data?.customer_id}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>{t("Properties")}</Typography>
                                    {
                                        props?.data?.property?.map((x, index) => {
                                            return (
                                                <Typography className={classes.sub}>{index + 1}.&nbsp;{x?.name ?? ""}</Typography>
                                            )
                                        })
                                    }

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={0} lg={4.5} />
            </Grid>
            <br />
            {/*unit detials fourth card */}
            <div className={classes.card}>
                <Grid container spacing={2}>
                    {/* <Grid item xs={6}>
                        <div className={classes.map}>
                            <GoogleMap />
                        </div>
                    </Grid> */}
                    <Grid item xs={12}>
                        <div className={classes.addressRoot}>
                            <Stack direction="row" >
                                <img src='/images/loc.svg' alt='' />
                                <Typography className={classes.title}>&nbsp;{t("Address")}</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Typography className={classes.sub}>
                                {props?.data?.door_no + "," ?? ""}
                                {props?.data?.street_1 + "," ?? ""}
                                {props?.data?.street_2 + "," ?? ""}
                                {props?.data?.city + "," ?? ""}
                                {props?.data?.district + "," ?? ""}
                                {props?.data?.state + "," ?? ""}
                                {props?.data?.country + "," ?? ""}
                                {props?.data?.zipcode ?? ""}

                            </Typography>
                            <Box height="16px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Latitude")} : </Typography>
                                <Typography className={classes.sub1} >&nbsp;{props?.data?.latitude}</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Longitude")} : </Typography>
                                <Typography className={classes.sub1} >&nbsp;{props?.data?.longitude}</Typography>
                            </Stack>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* contact */}
            <div className={classes.card} style={{ marginTop: '20px' }}>
                <div className={classes.addressRoot}>
                    <Stack direction="row" >
                        <img src='/images/Group 7015.svg' alt='' />
                        <Typography className={classes.title}>&nbsp;{t("Contact & Other Information")}</Typography>
                    </Stack>
                    <Grid container>
                        <Grid item xs={3}>
                            <Box marginTop="18px">
                                <Typography className={classes.heading}>{t("Preferred Method Of Contact")} :</Typography>
                                <Typography className={classes.sub}>{props?.data?.preferred_mode_contact}</Typography>
                            </Box>
                            <Box marginTop="12px">
                                <Typography className={classes.heading}>{t("Primary Mobile")} :</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.mobile_no_country_code}-{props?.data?.mobile_no}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box marginTop="12px">
                                <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.email_id}</Typography>
                            </Box>
                            <Box marginTop="12px">
                                <Typography className={classes.heading}>{t("Primary Telephone")} :</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.phone_code ?? "+91"}-{props?.data?.phone}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box marginTop="12px">
                                <Typography className={classes.heading}>{t("Emergency Contact Relationship")} :</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.preferred_mode_contact_emergency_code}-{props?.data?.preferred_mode_contact_emergency}</Typography>
                            </Box>
                            {
                                props?.data?.facebook?.length > 0 && <Box marginTop="12px">
                                    <Typography className={classes.heading}>{t("Facebook")} :</Typography>
                                    <Typography className={classes.sub} noWrap>{props?.data?.facebook}</Typography>
                                </Box>
                            }

                        </Grid>
                        <Grid item xs={3}>
                            {
                                props?.data?.linkedin?.length > 0 &&
                                <Box marginTop="12px">
                                    <Typography className={classes.heading}>{t("Linkdedin")} :</Typography>
                                    <Typography className={classes.sub} noWrap>{props?.data?.linkedin}</Typography>
                                </Box>
                            }
                            {
                                props?.data?.twitter?.length > 0 &&
                                <Box marginTop="12px">
                                    <Typography className={classes.heading}>{t("Twitter")} :</Typography>
                                    <Typography className={classes.sub} noWrap>{props?.data?.twitter}</Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* reporting details */}
            <div style={{ marginTop: '20px' }}>
                <AddMemberCard t={t} isNormal data={list} member={props?.data?.reporting_details?.[0]} isView />
            </div>


            {/* image viewer */}
            {/* image viewer */}
            <AlertDialog isNormal isnotTitle component={
                <div style={{ display: 'flex' }}>
                    <img src={props?.data?.image_url} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                </div>
            } open={imageViwer} onClose={() => setImageViwer(false)} />
        </div>
    )
}