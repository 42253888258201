import { makeStyles } from "@mui/styles";

export const CalendarDateChangeStyle = makeStyles((theme) => ({
    dateLabel: {
        fontSize:"0.875rem",
        fontWeight: "bold",
        color: theme.typography.color.primary,
        marginLeft: "16px",
        marginRight: "16px"
    },
    button: {
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 35,
        width: 35,
        textAlign: "center",
        backgroundColor: "white"
    },
    button_icon: {
        fontSize:"1rem",
        color: theme.typography.color.tertiary
    }
}))