import * as React from "react"
const UnclearedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <defs>
      <clipPath id="a">
        <path
          fill="#ff9340"
          d="M0 0h20v19.739H0z"
          data-name="Rectangle 53779"
        />
      </clipPath>
    </defs>
    <g data-name="Group 112691" transform="translate(-128 -490)">
      <rect
        width={40}
        height={40}
        fill="#fff4eb"
        data-name="NoPath - Copy (7)"
        rx={6}
        transform="translate(128 490)"
      />
      <g data-name="Group 112673">
        <path fill="none" d="M136 498h24v24h-24z" data-name="Rectangle 55282" />
        <g data-name="Group 112696">
          <g
            clipPath="url(#a)"
            data-name="Group 102047"
            transform="translate(138 500.131)"
          >
            <path
              fill="#ff9340"
              d="M17.27 15.157c.077 2.014-1.157 3.065-3.129 2.539a1.654 1.654 0 0 0-2.1.775c-1.151 1.666-2.895 1.693-4.026.058a1.733 1.733 0 0 0-2.189-.829c-1.944.526-3.217-.552-3.051-2.591a1.691 1.691 0 0 0-1.116-1.95c-1.916-.905-2.2-2.536-.708-4.037a1.553 1.553 0 0 0 .4-2.076c-.914-1.9-.111-3.384 1.977-3.6a1.749 1.749 0 0 0 1.767-1.531A2.225 2.225 0 0 1 8.8.566a1.8 1.8 0 0 0 2.448-.023 2.207 2.207 0 0 1 3.647 1.3 1.827 1.827 0 0 0 1.833 1.619c2.016.2 2.827 1.681 1.958 3.514a1.645 1.645 0 0 0 .408 2.192c1.436 1.437 1.128 3.053-.657 4-1.219.646-1.219.646-1.167 1.995"
              data-name="Path 96787"
            />
            <g
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth={1.25}
              data-name="Group 102129"
            >
              <path d="m7 6.87 6 6" data-name="Line 1287" />
              <path d="m13 6.87-6 6" data-name="Line 1288" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default UnclearedIcon
