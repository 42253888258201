import React from "react";
import { withNavBars } from "../../HOCs";
import CreateAccount from '../addAccount/createAccount'

const OwnerAccountCreateParent = () =>{
    return <CreateAccount reltype="Property Owner"/>
}
const props = {
    boxShadow: false
}
export default withNavBars(OwnerAccountCreateParent, props);