import React from "react";
import { withNavBars } from "../../HOCs";
import PricingComponent from "./pricingComponent";

class PricingComponentParent extends React.Component {
  render() {
    return <PricingComponent />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(PricingComponentParent, props);
