import React from "react"

export const UpcomingJobAsset = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.16" viewBox="0 0 24 24.16">
        <g id="Group_118506" data-name="Group 118506" transform="translate(-197 -106)">
          <g id="Rectangle_52035" data-name="Rectangle 52035" transform="translate(197 106)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="24" height="24" stroke="none"/>
            <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
          </g>
          <path id="icons8-paste-special" d="M15.5,3a2.706,2.706,0,0,0-2.67,2.4H10.7A2.7,2.7,0,0,0,8,8.1V24.3A2.7,2.7,0,0,0,10.7,27H24.5a2.7,2.7,0,0,0,2.7-2.7V8.1a2.7,2.7,0,0,0-2.7-2.7H22.37A2.706,2.706,0,0,0,19.7,3Zm0,1.8h4.2a.9.9,0,1,1,0,1.8H15.5a.9.9,0,0,1,0-1.8Zm2.156,7.368a.74.74,0,0,1,.657.538l.717,2.3h2.317l.124.009a.793.793,0,0,1,.318,1.405l-1.875,1.42.716,2.3.028.118a.755.755,0,0,1-1.184.756L17.6,19.591l-1.875,1.42-.1.064a.763.763,0,0,1-1.056-.94l.717-2.3-1.875-1.42-.095-.084A.787.787,0,0,1,13.852,15h2.317l.717-2.3.05-.127A.733.733,0,0,1,17.656,12.168Z" transform="translate(191.4 103.16)" fill="#b3a16d"/>
        </g>
      </svg>
      

    )
}