import * as React from "react"
export const TenantIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    {...props}
  >
    <path
      fill="#b33204"
      d="M12.5 2.5c1.333 0 2 .667 2 2A282.073 282.073 0 0 1 2 9.5c-.684-1.284-.517-2.45.5-3.5a59.36 59.36 0 0 1 10-3.5Z"
      style={{
        opacity: 0.869,
      }}
    />
    <path
      fill="#a02f09"
      d="M12.5 2.5c1.788-1.122 3.788-1.29 6-.5a42.3 42.3 0 0 0 11 3.5 8.434 8.434 0 0 1-.5 4 86.793 86.793 0 0 0-14.5-5c0-1.333-.667-2-2-2Z"
      style={{
        opacity: 0.898,
      }}
    />
    <path
      fill="#0f528e"
      d="M6.5 9.5a9.865 9.865 0 0 1 6 1c1.377 4.46-.29 6.293-5 5.5-2.334-1.842-2.668-4.01-1-6.5ZM25.5 14.5c-3.35 2.995-6.017 2.495-8-1.5.312-2.281 1.645-3.448 4-3.5 2.989.301 4.322 1.968 4 5Z"
      style={{
        opacity: 0.948,
      }}
    />
    <path
      fill="#11518c"
      d="M25.5 14.5c3.81 1.254 3.81 2.254 0 3v-3Z"
      style={{
        opacity: 0.584,
      }}
    />
    <path
      fill="#25a6e1"
      d="M14.5 16.5h3c.175 2.12-.159 4.12-1 6h-2c-1.333-2-1.333-4 0-6Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#135a98"
      d="M14.5 16.5c-1.333 2-1.333 4 0 6a66.075 66.075 0 0 1-10 3c-.929-1.189-1.262-2.522-1-4 2.027-3.436 4.86-4.436 8.5-3 .417-1.256 1.25-1.923 2.5-2Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#125997"
      d="M17.5 16.5c.722.418 1.222 1.084 1.5 2 3.64-1.436 6.473-.436 8.5 3a26.817 26.817 0 0 0-8 4l-3-2v-1c.841-1.88 1.175-3.88 1-6Z"
      style={{
        opacity: 0.998,
      }}
    />
    <path
      fill="#13528d"
      d="M27.5 21.5c.973 2.448 1.306 5.115 1 8h-8c.067-1.459-.266-2.792-1-4a26.817 26.817 0 0 1 8-4Z"
      style={{
        opacity: 0.949,
      }}
    />
    <path
      fill="#145590"
      d="M3.5 21.5c-.262 1.478.071 2.811 1 4a66.075 66.075 0 0 0 10-3h2v1c-3.667.333-5.667 2.333-6 6h-8c-.306-2.885.027-5.552 1-8Z"
      style={{
        opacity: 0.971,
      }}
    />
    <path
      fill="#25a8e3"
      d="m16.5 23.5 3 2c.734 1.208 1.067 2.541 1 4h-10c.333-3.667 2.333-5.667 6-6Z"
      style={{
        opacity: 1,
      }}
    />
  </svg>
)

