import { Grid, Link } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import Lottie from "react-lottie";
import animation from "./assets/lottiejson/500error.json";
// import { Routes } from './router/routes';

const styles = (theme) => ({
  root: {
    height: "100vh",
    textAlign: "center",
  },
  goHomeBtn: {
    backgroundColor: "#5078E1",
    color: "#fff !important",
    padding: "10px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    width: "76px",
    margin: "0 auto",
  },
  textAlign: {
    textAlign: "center",
  },
});

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  goHome = () => {
    this.props.history.push("/");
  };

  componentDidCatch(error, info) {
    console.log("info:", info);
    console.log("error:", error);

    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service

    //TODO:
    // logErrorToMyService(error, info);
  }

  render() {
    const { classes } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      // here is where we will declare lottie animation
      // "animation" is what we imported before animationData: animation,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Grid
          container
          classes={this.props.classes.root}
          id="error_catcher_root"
          className={this.props.classes.root}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item id="error_catcher_item1">
            <Lottie options={defaultOptions} height={300} width={300} />
            <Link
              id="error_catcher_link"
              href="/"
              underline="hover"
              className={classes.goHomeBtn}
            >
              Try again
            </Link>
          </Grid>
        </Grid>
      );
    }
    return this.props.children;
  }
}

export default withStyles(styles)(AppErrorBoundary);
