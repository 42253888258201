import React from "react";
import { withNavBars } from "../../HOCs";
import  RequestView  from "./requestView";

class RequestViewParent extends React.Component {
    render() {
        return <RequestView {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(RequestViewParent,props);
