import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        // border: "2px solid white",
        padding: "16px",
        backgroundColor: "white",
        // margin: "14px",
        // height: `calc(100vh - 210px)`,
        overflow: "auto",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
    },
    // inifiniteScroll: {
    //     overflow: "hidden !important"
    // },
    noDataStyle:{
        height:"calc(100vh - 320px)",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",

    }
}))