import * as React from "react"
const BlockUnitIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <g data-name="Group 105462">
            <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 55517" />
            <path
                fill="#ff9340"
                d="M9.238 2.002a.574.574 0 0 0-.354.123L3.47 6.389a2.838 2.838 0 0 0-1.089 2.244v7.269a.953.953 0 0 0 .952.952h4.381a1.917 1.917 0 0 1 .271-.979l3.428-5.714a1.9 1.9 0 0 1 3.268 0l1.414 2.354V8.633a2.838 2.838 0 0 0-1.089-2.244L9.592 2.123a.574.574 0 0 0-.354-.121Zm3.809 8a1.142 1.142 0 0 0-.98.555l-3.428 5.714a1.142 1.142 0 0 0 .98 1.731h6.857a1.142 1.142 0 0 0 .98-1.731l-3.428-5.714a1.142 1.142 0 0 0-.981-.555Zm0 1.524a.381.381 0 0 1 .381.381v3.047a.381.381 0 1 1-.762 0v-3.052a.381.381 0 0 1 .381-.378Zm0 4.476a.476.476 0 1 1-.476.476.476.476 0 0 1 .476-.476Z"
                data-name="icons8-smart-home-error (2)"
            />
        </g>
    </svg>
)
export default BlockUnitIcon
