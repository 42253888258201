import { Box, Button, Divider, Stack, Typography } from "@mui/material"
import { useStyles } from "./styles";
import { Time } from "../../assets";
import { HourBillInput } from "./hourBillInput";
import React from "react";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import { LoadingSection } from "../loadingSection";

export const UpdateForm = ({ t, agreement_inspection_id, resource_id, onClose = () => false,status="",reload=()=>false }) => {
    const classes = useStyles();
    const alert = React.useContext(AlertContext);
    const [data, setData] = React.useState({
        value: 0,
        hours: [],
        total_hour: 0,
        unbillable_hour: 0,
        details: {},
        loading: true,
        disable: true
    })
    const [isDisableBtn,setIsDisableBtn]=React.useState(false)



    const updateState = (key, value) => {
        if (key === "value") {
            const unBillable_hour = (Number(data?.total_hour) - Number(value))

            setData({
                ...data,
                [key]: value,
                unbillable_hour: unBillable_hour
            })
        }
    }

    const getDetails = () => {
        const payload = {
            resource_id,
            agreement_inspection_id
        }

        NetworkCall(
            `${config.api_url}/move-in-out/preview_inspection`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {

            const result = res?.data?.data?.result;

            const hourData = [
                {
                    label: t("Projected Hours"),
                    value: result?.projected_hours
                },
                {
                    label: t("Actual Hours"),
                    value: result?.inspection_resource_detals?.actual_hours > 0 ? result?.inspection_resource_detals?.actual_hours : 1
                },
                {
                    label: t("Other Hours"),
                    value: result?.inspection_other_hours?.map(i => i?.hour_value)?.reduce((a, b) => a + b, 0)
                }
            ]
            setData({

                details: result,
                value: result?.inspection_resource_detals?.billable_hour ?? 0,
                unbillable_hour: result?.inspection_resource_detals?.unbillable_hour ?? result?.total_working_hours ?? 0,
                hours: hourData,
                total_hour: result?.total_working_hours,
                loading: false,
                disable: false
            })
        }).catch((err) => {
            console.log(err)
        })
    }


    React.useEffect(() => {
        getDetails()
        // eslint-disable-next-line
    }, [])
    const onUpdate = () => {
setIsDisableBtn(true)
        setData({ ...data, disable: true })
        const payload = {
            upsert_field: {
                id: data?.details?.inspection_resource_detals?.id,
                // actual_hours: data?.hours?.[1]?.value,
                billable_hour: parseInt(data?.value),
                unbillable_hour: data?.unbillable_hour,
            }
        }

        NetworkCall(
            `${config.api_url}/move-in-out/update_resource`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Billing Hours Updated")
            });
            onClose()
            reload()
            setData({ ...data, disable: false })


        }).catch((err) => {
            console.log(err)
            setData({ ...data, disable: false })
setIsDisableBtn(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Cannot Update Billing Hours")
            });

        })
    }

    return (
        <Box p={3} height="500px">
            {
                data?.loading ?
                    <LoadingSection top="10vh" />
                    :
                    <Box>

                        <center>
                            <Time />
                            <Typography className={classes.fromTitle}>{t("Actual work & billing hour")}</Typography>
                        </center>

                        <HourBillInput t={t} onChange={(e) => updateState('value', e)} value={data?.value} status={status}/>

                        <Box marginTop="8px" display="flex" alignItems="center" className={classes.unbillableRoot}>

                            <Box flexGrow={1}>
                                <Typography className={classes.billing_hour}>{t("Unbillable hour")}</Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.fromTitle}>{data?.unbillable_hour}</Typography>
                            </Box>
                        </Box>

                        <Typography className={classes.hourBreakText}>{t("Hours Break Down")}</Typography>

                        <Box className={classes.hourRoot} p={1} marginBottom="16px">
                            {
                                data?.hours?.map((val) => {
                                    return (
                                        <Stack direction="row" p={1} justifyContent="space-between">
                                            <Typography className={classes.hourLabel}>{val?.label}</Typography>
                                            <Typography className={classes.hourValue}>{val?.value}</Typography>

                                        </Stack>
                                    )
                                })
                            }

                            <Divider />
                            <Stack direction="row" p={1} justifyContent="space-between">
                                <Typography className={classes.hourLabel}>{t("Total Hours")}</Typography>
                                <Typography className={classes.hourValue}>{data?.total_hour}</Typography>

                            </Stack>
                        </Box>

                        <Button sx={{ padding: "8px" }} disabled={status==="Completed"?true:!isDisableBtn ? false : true} variant="contained" fullWidth onClick={onUpdate}>{t("Update & Submit Work Hour")}</Button>
                    </Box>
            }


        </Box>
    )
}