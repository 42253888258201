import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    layoutSwitch: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        cursor: "pointer",
    },
    selectedBtn: {
        width: "32px",
        background: theme.palette.primary.main,
        borderRadius: theme.palette.borderRadius,
        color: "#fff",
        height: "32px",
        margin: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    notSelectedBtn: {
        width: "32px",
        borderRadius: theme.palette.borderRadius,
        color: theme.typography.color.tertiary,
        height: "32px",
        margin: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "13px",
        height: '40px'
    },
}))