import * as React from "react"
const ChooseUnit = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 113954" transform="translate(7176 23931)">
      <rect
        width={40}
        height={40}
        fill="#f1f7ff"
        data-name="Rectangle 57624"
        rx={8}
        transform="translate(-7176 -23931)"
      />
      <g data-name="Group 113949">
        <path
          fill="#1e88e5"
          d="M-7156.632-23923h-9.474a1.267 1.267 0 0 0-1.263 1.263v13.895h12v-13.895a1.267 1.267 0 0 0-1.263-1.263Z"
          data-name="Path 100595"
        />
        <path
          fill="#0d47a1"
          d="M-7162.317-23920.474h-2.526v2.526h2.526Zm0 4.421h-2.526v2.526h2.526Zm0 4.421h-2.526v2.526h2.526Z"
          data-name="Path 100596"
        />
        <path
          fill="#1976d2"
          d="M-7167.369-23907.842h12v1.263h-12Z"
          data-name="Path 100597"
        />
        <path
          fill="#90caf9"
          d="M-7161.053-23900.263h16.421v-18.948a1.267 1.267 0 0 0-1.263-1.263h-13.895a1.267 1.267 0 0 0-1.263 1.263Z"
          data-name="Path 100598"
        />
        <path
          fill="#64b5f6"
          d="M-7144.632-23900.263h-16.421v1.263h16.421Z"
          data-name="Path 100599"
        />
        <path
          fill="#1565c0"
          d="M-7151.58-23904.053h-2.526v2.526h2.526Zm4.421 0h-2.526v2.526h2.526Zm-8.842 0h-2.526v2.526h2.526Zm4.421-8.842h-2.526v2.526h2.526Zm4.421 0h-2.526v2.526h2.526Zm-8.842 0h-2.526v2.526h2.526Zm4.421 4.421h-2.526v2.526h2.526Zm4.421 0h-2.526v2.526h2.526Zm-8.842 0h-2.526v2.526h2.526Zm4.421-8.842h-2.526v2.526h2.526Zm4.421 0h-2.526v2.526h2.526Zm-8.842 0h-2.526v2.526h2.526Z"
          data-name="Path 100600"
        />
      </g>
    </g>
  </svg>
)
export default ChooseUnit
