import * as React from "react"
const Restore = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      fill="#5078e1"
      d="M8 0a7.981 7.981 0 0 0-3.007.585A8 8 0 0 0 1.933 2.8l-.015-.423a.8.8 0 1 0-1.6.056l.084 2.4a.8.8 0 0 0 .828.767l2.4-.084a.8.8 0 1 0-.055-1.6l-.513.018a6.418 6.418 0 0 1 2.532-1.867A6.4 6.4 0 1 1 1.6 8 .8.8 0 1 0 0 8a8 8 0 1 0 8-8Z"
      data-name="icons8-restore (1)"
    />
  </svg>
)
export default Restore
