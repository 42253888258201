import React from "react";
import { TableWithPagination } from "../../components";
import {
  fileManagerType,
  fileManagerHeading,
  fileManagerPath,
} from "../../utils";

export const TableView = (
  {
    t,
    selectedFolder = {},
    data = [],
    handleTableIcon = () => false
  }
) => {
  return (
    <div>
      <TableWithPagination
        heading={fileManagerHeading(t)}
        rows={data?.list}
        path={fileManagerPath}
        showpagination={false}
        showpdfbtn={false}
        showexcelbtn={false}
        //   totalRowsCount={list?.count}
        //   page={page ?? 1}
        //   limit={limit ?? 10}
        tableType="no-side"
        dataType={fileManagerType}
        height={"calc(100vh - 381px)"}
        handleIcon={handleTableIcon}
        //   handlePagination={handlePagination}
        //   handleChangeLimit={handleChangeLimit}
        view={true}
        edit={![0, 1].includes(selectedFolder?.id)}
        delete={![0, 1].includes(selectedFolder?.id)}
        detail={true}
        download={![0, 1].includes(selectedFolder?.id)}
      />
    </div>
  );
};
