
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ImageCardStyles } from "./styles";
import FileViewer from "react-file-viewer";

export const ImageCard = ({
  image = "",
  imageName = "",
  storage = "",
  type = "",
  onClick = () => false,
  is_pdf = false,
}) => {
  const [showText, setShowtext] = useState(false);
  const classes = ImageCardStyles();

  const types = "pdf";
  const file = image;

  return (
    <Box
      className={`${classes.imgcard}`}
      onMouseOver={() => setShowtext(true)}
      onMouseLeave={() => setShowtext(false)}
    >
      <Box>
        {is_pdf ? (
          <div className={`${classes.image}`}>
            <FileViewer fileType={types} filePath={file} />
          </div>
        ) : (
          <>
            {
              type === 'mp4' ?
                <video
                  className={`${classes.image}`}
                  controls
                >
                  <source src={image} type="video/mp4" />
                </video> : <img src={image} className={`${classes.image}`} alt={"images"} />
            }
          </>

        )}
      </Box>
      {showText === true && (
        <div className={classes.hoverCard}>
          <Box>
            <DeleteOutlineIcon
              className={`${classes.deleteicon}`}
              onClick={onClick}
            />
          </Box>
          <Box className={`${classes.imgtext}`}>
            <Typography className={`${classes.imgName}`}>
              {imageName}
            </Typography>
            <Box display={"flex"}>
              <Typography className={`${classes.textName}`}>
                {storage}
              </Typography>
              <div className={`${classes.dot}`}></div>
              <Typography className={`${classes.textName}`}>{type}</Typography>
            </Box>
          </Box>
        </div>
      )}
    </Box>
  );
};
