import React from 'react'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { TextBox } from '../../../components'
import MemberCountIcon from '../../../assets/MemberCountIcon'
import { MemberCountStyle } from './style'

export const MemberCount = ({
    handleContinueMemberCount = () => false,
    validateCount = () => false,
    member = {},
    setMember = () => false,
    t
}) => {
    const classes = MemberCountStyle()
    return (
        <>
            <Grid container>
                {/* <Grid item xs={12}>
                    <Box
                        className={classes.flexWithPadding}
                    >
                        <Box flexGrow={1}>
                            <Typography className={classes.modalTitle}>
                                Enter Member Count
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => onCLoseDrwPC()}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid> */}
                <Grid item xs={12} p={"16px 0px 0px"}>
                    <Stack direction={"row"} justifyContent={"space-between"} px={2}>
                        <Stack direction={"row"} spacing={1}>
                            <MemberCountIcon />
                            <Stack>
                                <Typography className={classes.pcTitle}>{t("Enter the number of members for whom you would like to book")}</Typography>
                                {/* <Typography className={classes.pcSubTitle}>This entered count members only allowed</Typography> */}
                            </Stack>
                        </Stack>
                        <Box sx={{ width: 50 }}>
                            <TextBox
                                value={member?.count}
                                label=""
                                placeholder={""}
                                onChange={(e) => setMember({ ...member, count: e?.target?.value })}
                                type="number" />
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} p={2}>
                    <Button fullWidth variant="contained"
                        disabled={member?.count?.length === 0}
                        className={classes.confirmBtn}
                        onClick={() => handleContinueMemberCount()}>
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}