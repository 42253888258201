import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: `0px 8px 16px ${theme.palette.shadow.paper}`,
        borderRadius: "12px 0px 0px 12px"
    },
    iconBtn: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        padding: "12px"

    },
    closeDiv: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        padding: "12px 0px 8px 4px",
        borderRadius: "12px 0px 0px 12px",
        boxShadow: `0px 8px 16px ${theme.palette.shadow.paper}`,
        cursor: "pointer",
        marginBottom: 14
    },
    image: {
        height: "52px",
        width: "52px",
        borderRadius: theme.palette.borderRadius,
        objectFit: "cover"
    },
    name: {
        color: theme.typography.color.primary,
        fontSize:"1rem",
        fontFamily: Bold,
        textTransform: "capitalize",
        marginTop: "4px"
    },
    name1: {
        color: theme.typography.color.primary,
        fontSize:"0.75rem",
        fontFamily: Bold,
        textTransform: "capitalize",
        marginTop: "4px"
    },
    switch: {
        color: theme.palette.primary.main,
        fontSize:"0.875rem",
        fontFamily: Bold,
        cursor: "pointer"
    },
    budget: {
        color: theme.typography.color.secondary,
        fontSize:"0.875rem",
        fontFamily: Bold,

    },
    email: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
    }
}));