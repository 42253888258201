import {
    Avatar,
    Box,
    Divider,
    Grid, Stack, Typography
} from "@mui/material";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import ShowMoreText from "react-show-more-text";
import ImageViewer from "react-simple-image-viewer";
import { LoadingSection, Subheader } from "../../../components";
import { config } from "../../../config";
import { AlertContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
    AlertProps,
    NetWorkCallMethods,
    assestType,
    convertTimeUtcToZoneCalander
} from "../../../utils";
import { AllocatedCard } from "../../maintenancerequest/components/profilecard";
import { useStyles } from "../../maintenancerequest/style";
import { AssetView } from "../../requestView/components";
import { CustomTypography, useStyles as useStyles1 } from "../../requestView/steps/style";

export const ViewServiceRequest = (props) => {
    const classes = useStyles();
    const { t } = props;
    const classes1 = useStyles1()
    // context
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    // props
    const { request, value } = props;
    // state
    const [loading, setLoading] = React.useState(true)
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [datas, setDatas] = useState({
        data: {},
        imagesListForView: [],
    });
    // get details
    const getDetails = () => {

        const payload = {
            id: value?.id,
            type: request === "Maintenance" ? "Maintenance" : "General"
        }
        NetworkCall(
            `${config.api_url}/request/view`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const tempImageList = response?.data?.data?.table?.[0]?.images?.map(
                ({ url }) => url
            );
            setDatas({
                ...datas,
                data: response?.data?.data ?? {},
                imagesListForView: tempImageList,
                is_show: true,
                showServiceCreate: false,
                service_inspection: response?.data?.data?.table?.[0]?.service_inspection ?? [],
                delivery_inspection: response?.data?.data?.table?.[0]?.delivery_inspection ?? [],
                general_inspection: response?.data?.data?.table?.[0]?.general_inspection ?? [],
                selected_service_inspection: response?.data?.data?.table?.[0]?.service_inspection?.[0],
                selected_delivery_inspection: response?.data?.data?.table?.[0]?.delivery_inspection?.[0],
                selected_general_inspection: response?.data?.data?.table?.[0]?.general_inspection?.[0]
            });
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong please try again"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        });
    };
    // open image viwer
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    // close image viwer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            {/* body */}
            {
                loading ?
                    <LoadingSection top="20vh" message={"Fetching Details"} />
                    :
                    <div>
                        <div style={{ padding: "20px" }}>
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={8}
                                    lg={8}
                                    borderRight="1px solid #E4E8EE"
                                >
                                    <Grid container spacing={3} p={2}>

                                        {/* {datas?.data?.table?.[0]?.images?.length > 0 && ( */}
                                        <Grid item xs={6} md={6} sm={6} lg={6}>
                                            <Box>
                                                <AssetView
                                                    asset={datas?.data?.table?.[0]?.images
                                                        ?.filter((x) => x?.asset_type !== assestType?.Videos)
                                                        ?.filter((x) => x?.asset_type !== assestType?.Documents)
                                                        ?.map((x) => x)}
                                                    assetAll={datas?.data?.table?.[0]?.images}
                                                    openImageViewer={openImageViewer}
                                                    isNormal
                                                    maxWidth={"sm"}
                                                    isMaintenance={true}
                                                />
                                            </Box>
                                            {isViewerOpen && (
                                                <ImageViewer
                                                    src={datas?.imagesListForView}
                                                    currentIndex={currentImage}
                                                    onClose={closeImageViewer}
                                                    disableScroll={false}
                                                    backgroundStyle={{
                                                        backgroundColor: "rgba(0,0,0,0.9)",
                                                    }}
                                                    closeOnClickOutside={true}
                                                />
                                            )}
                                        </Grid>


                                        {/* content */}
                                        <Grid item xs={6} md={6} sm={6} lg={6}>
                                            <Box>
                                                {/* <Typography className={classes.title}>
                {datas?.data?.table?.[0]?.subject}
            </Typography> */}
                                                <Box className={classes.catagory}>
                                                    <Box className={classes1.service}>
                                                        <Typography className={classes.servicetittle} noWrap>
                                                            {datas?.data?.table?.[0]?.catagory?.type}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        className={classes1.service}
                                                        style={{ marginLeft: "10px" }}
                                                    >
                                                        <Typography className={classes.servicetittle} noWrap>
                                                            {datas?.data?.table?.[0]?.subcatagory?.type}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box mt={1}>
                                                    <ShowMoreText
                                                        lines={3}
                                                        more="Show More"
                                                        less="Show Less"
                                                        className={classes1.description}
                                                        anchorClass={classes1.seeMoreLessTextStyle}
                                                        expanded={false}
                                                        truncatedEndingComponent={"... "}
                                                    >
                                                        <Typography className={classes1.description}>
                                                            {datas?.data?.table?.[0]?.description}
                                                        </Typography>
                                                    </ShowMoreText>
                                                </Box>
                                                {datas?.data?.table?.[0]?.name?.length > 0 &&
                                                    <Box mt={1}>
                                                        <CustomTypography>

                                                            {"Contact_Details"}
                                                        </CustomTypography>
                                                        <AllocatedCard
                                                            isImageType
                                                            viewDetails={{
                                                                member: {
                                                                    image_url: "images/phonecall.svg",
                                                                    first_name: datas?.data?.table?.[0]?.name ?? "-",
                                                                    last_name: "",
                                                                    reference_no: "",
                                                                    mobile_no_country_code:
                                                                        `${datas?.data?.table?.[0]?.mobile_country_code ?? ""}`,
                                                                    mobile_no: datas?.data?.table?.[0]?.mobile ?? "-",
                                                                    email_id: datas?.data?.table?.[0]?.alternative ?? null,
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                }
                                                <Box mt={1.5} className={classes1.detailsReqBox}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={5} md={5} lg={5}>
                                                            <Box>
                                                                {/* PROBLEM SINCE */}

                                                                {datas?.data?.table?.[0]?.problemsince?.length > 0 && (
                                                                    <Grid item xs={12}>

                                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                                            <Box className={classes.waterLeakageIcons}>
                                                                                <img
                                                                                    src="images/icons8-calendar (5).svg"
                                                                                    alt="no_img"
                                                                                ></img>
                                                                            </Box>
                                                                            <Typography className={classes.quotationsummaryans}>
                                                                                {t("PROBLEM SINCE")}
                                                                            </Typography>

                                                                        </Box>
                                                                        <Box className={classes.quotationsummaryquest}>
                                                                            {datas?.data?.table?.[0]?.problemsince ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.problemsince) : "-"}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                                {/* RAISED ON */}

                                                                {datas?.data?.table?.[0]?.raisedon?.length > 0 && (
                                                                    <Grid item xs={12} mt={1}>

                                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                                            <Box className={classes.waterLeakageIcons}>
                                                                                <img
                                                                                    src="images/icons8-calendar (5).svg"
                                                                                    alt="no_img"
                                                                                ></img>
                                                                            </Box>
                                                                            <Typography className={classes.quotationsummaryans}>
                                                                                {t("RAISED ON")}
                                                                            </Typography>

                                                                        </Box>
                                                                        <Box className={classes.quotationsummaryquest}>
                                                                            {datas?.data?.table?.[0]?.raisedon ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.raisedon)
                                                                                : "-"}
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={7} md={7} lg={7}>
                                                            <Box className={classes1.applyBorderLeft}>
                                                                {/* Property and unit details */}
                                                                <Grid item xs={12} ml={2}>
                                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                                        <Box className={classes.waterLeakageIcons}>
                                                                            <img src="images/Group 619.svg" alt="no_img"></img>
                                                                        </Box>
                                                                        <Typography className={classes.quotationsummaryans} noWrap>
                                                                            {"Property_and_unit_details"}
                                                                        </Typography>

                                                                    </Box>
                                                                    <Box>
                                                                        <Stack direction="row" alignItems="center" spacing={0.5}>
                                                                            <Typography className={classes.quotationsummaryquest} noWrap>
                                                                                {datas?.data?.table?.[0]?.unit?.property?.name}
                                                                            </Typography>
                                                                            <Box className={classes1.dotService}></Box>
                                                                            <Typography className={classes.quotationsummarySubquest} noWrap>
                                                                                {datas?.data?.table?.[0]?.unit?.unit?.name}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </Box>
                                                                </Grid>
                                                                {/* RAISED ON */}
                                                                {datas?.data?.table?.[0]?.raisedon?.length > 0 && (
                                                                    <Grid item xs={12} ml={2} mt={1}>

                                                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                                                            <Box className={classes.waterLeakageIcons}>
                                                                                <img
                                                                                    src="images/icons8-schedule (1).svg"
                                                                                    alt="no_img"
                                                                                ></img>
                                                                            </Box>
                                                                            <Typography
                                                                                className={classes.quotationsummaryans}
                                                                                noWrap
                                                                            >
                                                                                {"PREFERRED_VISIT"}
                                                                            </Typography>

                                                                        </Box>
                                                                        <Box className={classes.quotationsummaryquest}>
                                                                            {datas?.data?.table?.[0]?.preferredtime ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.preferredtime, "yyyy-MMM-dd hh:mm aaaa") : "-"
                                                                            }
                                                                        </Box>
                                                                    </Grid>
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    {
                                        request &&
                                        <Box className={classes1.detailsBox}>
                                            <CustomTypography>
                                                3<sup>rd</sup> {"PARTY WORK ORDER"}
                                            </CustomTypography>
                                            <Grid container spacing={3}>
                                                {/* PROBLEM SINCE */}

                                                {/* {datas?.data?.table?.[0]?.work_order_ref_no?.length > 0 && ( */}
                                                <Grid item xs={3}>
                                                    <Typography className={classes.quotationsummaryans}>
                                                        {"Ticket/Case Reference"}
                                                    </Typography>
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        {/* <Box className={classes.waterLeakageIcons}>
                        <img
                            src="images/icons8-calendar (5).svg"
                            alt="no_img"
                        ></img>
                    </Box> */}
                                                        <Box className={classes.quotationsummaryquest}>
                                                            {datas?.data?.table?.[0]?.work_order_ref_no ?? "-"}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                {/* )} */}

                                                {/* UNIT*/}

                                                {/* {datas?.data?.table?.[0]?.work_order_date 
             && ( */}
                                                <Grid item xs={2.5}>
                                                    <Typography className={classes.quotationsummaryans}>
                                                        {"Date Opened"}
                                                    </Typography>
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        {/* <Box className={classes.waterLeakageIcons}>
                            <img src="images/Group 619.svg" alt="no_img"></img>
                        </Box> */}
                                                        <Box className={classes.quotationsummaryquest}>
                                                            {datas?.data?.table?.[0]?.work_order_date ? convertTimeUtcToZoneCalander(datas?.data?.table?.[0]?.work_order_date) : "-"}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                {/* )} */}

                                                {/* RAISED ON */}

                                                {/* {datas?.data?.table?.[0]?.vendor_ref?.label?.length > 0 && ( */}
                                                <Grid item xs={2.5}>
                                                    <Typography className={classes.quotationsummaryans}>
                                                        {"Vendor"}
                                                    </Typography>
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        {/* <Box className={classes.waterLeakageIcons}>
                        <img
                            src="images/icons8-calendar (5).svg"
                            alt="no_img"
                        ></img>
                    </Box> */}
                                                        <Box className={classes.quotationsummaryquest}>
                                                            {datas?.data?.table?.[0]?.vendor_ref?.value ? datas?.data?.table?.[0]?.vendor_ref?.label
                                                                : "-"}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                {/* )} */}

                                                {/* PREFERRED VISIT DATE AND TIME */}


                                                <Grid item xs={4}>
                                                    <Typography
                                                        className={classes.quotationsummaryans}
                                                        noWrap
                                                    >
                                                        {"Work Order Status"}
                                                    </Typography >
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        {/* <Box className={classes.waterLeakageIcons}>
                        <img
                            src="images/icons8-schedule (1).svg"
                            alt="no_img"
                        ></img>
                    </Box> */}
                                                        <Box className={classes.quotationsummaryquest}>
                                                            {datas?.data?.table?.[0]?.work_order_status ?? "-"
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Grid >

                                            </Grid >
                                        </Box >
                                    }

                                </Grid >

                                {/* track */}
                                < Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    lg={4}
                                    style={{ padding: "4px", position: "relative" }}
                                >
                                    <Box mt={0.5}>
                                        <Subheader
                                            title={"Track"}
                                            hideBackButton
                                        />
                                        <Box>
                                            <Grid container direction={"column"} spacing={1}>
                                                <Box className={classes1.createnewsectionlog1} height={datas?.data?.table?.[0]?.type !== null ? 375 : (datas?.data?.table?.[0]?.type === null && datas?.data?.table?.[0]?.is_acknowleged) ? 215 : 310}>
                                                    {datas?.data?.table?.[0]?.status
                                                        .map((x, index, array) => {
                                                            return (
                                                                <Box className={classes1.treeBox}>
                                                                    <Box className={datas?.data?.table?.[0]?.status?.length - 1 !== index ? classes1.border : classes1.applynoborder}>
                                                                        <Avatar
                                                                            variant="circular"
                                                                            className={classes1.avatars1}
                                                                        >
                                                                            <Avatar
                                                                                variant="circular"
                                                                                style={{
                                                                                    height: "100%",
                                                                                    width: "100%",
                                                                                    backgroundColor: "#5078E1",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        x?.status === "Open"
                                                                                            ? "images/icons8-clock.svg"
                                                                                            : "images/Path 953.svg"
                                                                                    }
                                                                                    alt="document"
                                                                                />
                                                                            </Avatar>
                                                                        </Avatar>
                                                                        <Typography
                                                                            className={classes1.title}
                                                                        >
                                                                            {x?.status}
                                                                        </Typography>
                                                                        <Box height={'4px'} />
                                                                        {
                                                                            <>
                                                                                <>
                                                                                    {x?.status?.length > 0 ?
                                                                                        <Typography
                                                                                            className={classes1.notes}
                                                                                        >
                                                                                            Notes: {x?.notes ?? "-"}
                                                                                        </Typography>
                                                                                        :
                                                                                        <Typography
                                                                                            className={classes1.title}
                                                                                        >
                                                                                            {x?.notes}
                                                                                        </Typography>
                                                                                    }
                                                                                    <Box height={'4px'} />
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        divider={<Divider orientation="vertical" flexItem />}
                                                                                        spacing={2}
                                                                                    >
                                                                                        <Typography
                                                                                            className={classes1.dateTime}
                                                                                        >
                                                                                            {x?.created_at ? moment(x?.created_at).format("DD MMM YY hh:mm a") : "-"
                                                                                            }
                                                                                        </Typography>
                                                                                        {x?.created_by !== null &&
                                                                                            <Typography
                                                                                                className={classes1.dateTime}
                                                                                            >
                                                                                                {x?.created_by ? x?.created_by : "-"
                                                                                                }
                                                                                            </Typography>
                                                                                        }
                                                                                    </Stack>
                                                                                    <Box height={'15px'} />
                                                                                </>
                                                                            </>
                                                                        }
                                                                        {
                                                                            datas?.data?.table?.[0]?.status?.length !== index + 1 && <Box height={'34px'} />
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        })}
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid >
                            </Grid >
                        </div >
                    </div >
            }
            {/* bottom btn */}
            {/* <div className={classes.buttonParent}>
        {datas?.data?.table?.[0]?.status?.[0]?.type !== "Closed" && (
          <Button
            className={classes.Editbutton}
            variant="contained"
            onClick={editFunction}
          >
            Edit
          </Button>
        )}

        {datas?.data?.table?.[0]?.status?.some(
          (item) => item?.type === "Open"
        ) && (
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => CloseDialog("open")}
          >
            Close Request
          </Button>
        )}
      </div> */}
        </>
    );
};
