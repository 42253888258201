import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import styled from "@mui/material/styles/styled";
import React from 'react';
import { CustomTypography } from '.';
import { SemiBold } from '../../../utils';

const Chips = styled(Stack, {
    shouldForwardProp: prop => prop !== 'backgroundColor'
})(({ theme, backgroundColor = "#F5F7FA" }) => ({
    backgroundColor: "#F5F7FA",
    borderRadius: 4,
    padding: theme.spacing(1),
    border: "1px solid #E4E8EE"
}))

export const ChipComponent = ({ value = "", count = "", onClear = null, backgroundColor }) => {
    return (
        <>
            {
                value &&
                <Chips
                    direction={"row"}
                    alignItems={"center"}
                    gap={1}
                    backgroundColor={backgroundColor}
                >
                    <CustomTypography
                        color={"#091B29"}
                        fontSize={12}
                        fontFamily={SemiBold}
                        sx={{ flex: 1 }}
                    >
                        {value}
                        {
                            count &&
                            <CustomTypography
                                component={"span"}
                                color={"inherit"}
                                fontSize={"inherit"}
                                fontFamily={"inherit"}
                            >, +{count}
                            </CustomTypography>
                        }
                    </CustomTypography>
                    <IconButton
                        size={"small"}
                        sx={{ p: 0 }}
                        onClick={onClear}
                    >
                        <CloseIcon sx={{ fontSize:"1rem" }} htmlColor='#98A0AC' />
                    </IconButton>
                </Chips>
            }
        </>

    )
}