import { Box, Stack, Typography, Grid, Button, Divider } from '@mui/material'
import React from 'react'
import moment from 'moment';
import { startOfWeek, startOfMonth, endOfMonth, endOfWeek, addMonths, eachMonthOfInterval, eachYearOfInterval, addWeeks, addYears, subDays } from "date-fns";
import { CalendarStyle } from './style';
import { MonthDropDown } from './monthDropdown';
import { TextBox } from '../textbox';
export const ContractCalendar = ({ click = () => false }) => {

    const classes = CalendarStyle()
    const days = ["S", "M", "T", "W", "T", "F", "S"]

    const [month, setMonth] = React.useState(new Date())
    const [finalDays, setFinalDays] = React.useState([])
    const [selectedType, setSelectedType] = React.useState("")
    const [endCount, setEndCount] = React.useState("")
    const [selectedDate, setSelectedDate] = React.useState(new Date())
    const [finalRange, setFinalRange] = React.useState("")

    const types = [
        "Week",
        "Month",
        "Year"
    ]

    // menu dropdown month and year

    const [selectedMonth, setSelectedMonth] = React.useState(moment(new Date()).format("MMMM"))

    const [selectedYear, setSelectedYear] = React.useState(moment(new Date()).format("YYYY"))

    const months = eachMonthOfInterval({
        start: new Date(2014, 0, 6),
        end: new Date(2014, 11, 10)
    })

    const years = eachYearOfInterval({
        start: new Date(Number(moment(new Date()).format("YYYY")), 0, 6),
        end: new Date(2100, 11, 10)
    })
    // end

    //dateRange
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    React.useEffect(() => {
        let tempMonth = new Date(`${moment(new Date()).format("DD")} ${selectedMonth} ${selectedYear}`)
        setMonth(tempMonth)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        let finaldays = dateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setFinalDays(finaldays)
        // eslint-disable-next-line
    }, [selectedMonth, selectedYear])


    const handleDateRangeCount = (type, date = selectedDate, countNumber = endCount) => {
        const number = Number(countNumber)
        if (endCount?.length > 0) {
            const count = () => {
                setSelectedType(type)
                switch (type) {
                    case "Week":
                        return addWeeks(date, number)
                    case "Month":
                        return addMonths(date, number)
                    case "Year":
                        return addYears(date, number)
                    default:
                        return false
                }
            }
            setFinalRange({ startDate: selectedDate, endDate: subDays(count(), 1) })
        } else {
        }
    }

    return (
        <Box p={0}>
            <Typography className={classes.title}>Contract Starts On</Typography>

            <Stack className={classes.calendar} spacing={3} p={1.3}>
                <Stack direction={"row"} spacing={6}>
                    <MonthDropDown type="month" list={months}
                        selected={selectedMonth} setSelected={setSelectedMonth} />
                    <MonthDropDown type="year" list={years}
                        selected={selectedYear} setSelected={setSelectedYear} />
                </Stack>

                <Box pt={1}>
                    <Grid container>
                        {
                            days.map((day) => {
                                return (
                                    <Grid item xs={1.7} textAlign={"center"} className={classes.day} >
                                        <Box><span>{day}</span></Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid container>
                        {
                            finalDays.map((e) => {
                                return (
                                    <Grid item xs={1.7} className={moment(e).format("M") === moment(month).format("M") ? classes.date_curr_month : classes.date_not_curr_month} textAlign={"center"}>
                                        <Stack p={1} alignItems="center" justifyContent={"center"}>
                                            <Box mt={1} className={moment(e).format("DD MM YYYY") === moment(selectedDate).format("DD MM YYYY") && classes.selectedDate}>
                                                <Typography className={(moment(new Date()).format("DD-MM-YYYY") === moment(e).format("DD-MM-YYYY") && moment(selectedDate).format("DD-MM-YYYY") !== moment(e).format("DD-MM-YYYY")) ? classes.today : classes.date}
                                                    onClick={() => {
                                                        setSelectedDate(e)
                                                        handleDateRangeCount(selectedType, e)
                                                    }}>
                                                    {moment(e).format("DD")}</Typography>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Stack>

            <Box mb={2}>
                <Stack>
                    <Typography className={classes.title}>Contract Ends On</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Box sx={{ width: 100 }}>
                            <TextBox value={endCount} label="" placeholder={"0"} type="number"
                                height={45}
                                onChange={(e) => {
                                    setEndCount(e?.target?.value)
                                    handleDateRangeCount(selectedType, selectedDate, e?.target?.value)
                                }} />
                        </Box>
                        <Stack direction={"row"} spacing={1}>

                            {
                                types?.map((e) => {
                                    return (
                                        <Box p={2} className={e === selectedType ? classes.selected_type : classes.non_selected_type} onClick={() => handleDateRangeCount(e)}>
                                            <Typography sx={{ fontSize:"0.75rem" }}>
                                                {e}
                                            </Typography>
                                        </Box>
                                    )
                                })
                            }
                        </Stack>
                    </Stack >
                </Stack >
            </Box >

            <Divider></Divider>


            <Box>
                <Stack direction={"row"} justifyContent={"space-between"} p={1} alignItems="center">
                    <Stack spacing={1}>
                        <Typography className={classes.secondaryTitle}>Start and end date</Typography>
                        <Typography className={classes.rangeDate}>
                            {`${finalRange?.startDate ? moment(finalRange?.startDate).format("DD MMM YY") : "-"} - ${finalRange?.endDate ? moment(finalRange?.endDate).format("DD MMM YY") : "-"} `}
                        </Typography>
                    </Stack>
                    <Button variant='contained' className={classes.btn}
                        disabled={finalRange === "" ? true : false}
                        onClick={() => click(finalRange)}
                    >Apply</Button>
                </Stack>
            </Box>
        </Box >
    )
}
