import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        padding: "16px",
        backgroundColor: "white",
        margin: "14px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "13px",
        height: '40px'
      },
      outerCircle: {
        padding: "2px",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "50%",
      },

}))