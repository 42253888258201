export const Diassociate = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 102649">
      <path data-name="Rectangle 52494" fill="none" d="M0 0h30v30H0z" />
      <path
        d="m23.935 51.858.668-.668a1.839 1.839 0 0 1 .5.429 1.573 1.573 0 0 1 0 2.214l-3.538 3.537a1.573 1.573 0 0 1-2.214 0l-2.9-2.9a1.573 1.573 0 0 1 0-2.214l3.537-3.537a1.573 1.573 0 0 1 2.214 0 1.84 1.84 0 0 1 .429.5l-.668.668a1.839 1.839 0 0 1-.5-.429.526.526 0 0 0-.738 0L17.193 53a.526.526 0 0 0 0 .738l2.9 2.9a.526.526 0 0 0 .738 0l3.534-3.538a.526.526 0 0 0 0-.738 1.839 1.839 0 0 1-.43-.504Zm-.975-9.481a.261.261 0 0 1 .519.051l-.179 1.755a.261.261 0 1 1-.519-.051Zm8.262 9.683a.261.261 0 1 1-.033.521l-1.76-.11a.261.261 0 0 1 .033-.521ZM29.9 55.207a.26.26 0 1 1-.39.345l-1.163-1.322a.26.26 0 0 1 .39-.345ZM26.748 56.5a.261.261 0 0 1-.521-.033l.11-1.76a.261.261 0 1 1 .521.033Zm-6.99-12.947a.261.261 0 1 1 .4-.331l1.117 1.364a.261.261 0 0 1-.4.331Zm-1.432 3.095a.261.261 0 0 1 .051-.519l1.754.175a.261.261 0 0 1-.051.519Zm3.484 6.114 2.7-2.7-.746-.746-2.7 2.7a.527.527 0 0 0 .746.746Zm2.99-4.432.527.913 3.306-1.909a.527.527 0 1 0-.527-.913Zm1.02-1.586a1.84 1.84 0 0 0-.285-.593.522.522 0 0 1 .191-.713l4.332-2.5a.526.526 0 0 1 .713.191l2.048 3.547a.526.526 0 0 1-.191.713l-4.332 2.5a.526.526 0 0 1-.713-.191 1.841 1.841 0 0 0-.371-.544l-.818.472a1.84 1.84 0 0 0 .285.593 1.573 1.573 0 0 0 2.138.573l4.332-2.5a1.573 1.573 0 0 0 .573-2.138l-2.048-3.547a1.573 1.573 0 0 0-2.138-.573l-4.332 2.5a1.57 1.57 0 0 0-.573 2.138 1.841 1.841 0 0 0 .371.544Z"
        transform="translate(-10 -34.826)"
        fill="#c1c5cb"
        fillRule="evenodd"
        style={{
          fill: props?.fill ?? "#c1c5cb",
        }}
      />
    </g>
  </svg>
)