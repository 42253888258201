export const Owners = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32.5} height={32} {...props}>
        <defs>
            <linearGradient
                id="a"
                x1={0.449}
                y1={-0.008}
                x2={0.512}
                y2={0.992}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#33bef0" />
                <stop offset={1} stopColor="#0a85d9" />
            </linearGradient>
        </defs>
        <path data-name="Rectangle 33" fill="none" d="M0 0h32v32H0z" />
        <g data-name="Group 96941">
            <path
                data-name="Path 93477"
                d="M16.93 30.571C12 30.571 8 33.318 8 36.312v.638a1.116 1.116 0 0 0 1.116 1.116h15.628a1.116 1.116 0 0 0 1.116-1.116v-.638c0-2.994-3.997-5.741-8.93-5.741Z"
                transform="translate(-1.5 -12.299)"
                fill="url(#a)"
            />
            <path
                data-name="Path 93478"
                d="M18.221 18.512c0 1.059-2.791 3.907-2.791 3.907s-2.79-2.849-2.79-3.907v-3.927h5.581Z"
                fill="#ecbc3e"
            />
            <path
                data-name="Path 93479"
                d="M20.406 11.256h-.294v2.233h.392a.57.57 0 0 0 .581-.413l.192-.951a.8.8 0 0 0-.871-.869Z"
                fill="#ecbc3e"
            />
            <path
                data-name="Path 93480"
                d="M10.454 11.256h.294v2.233h-.392a.57.57 0 0 1-.581-.413l-.192-.951a.8.8 0 0 1 .871-.869Z"
                fill="#ecbc3e"
            />
            <path
                data-name="Path 93481"
                d="M15.43 4.781c-2.478 0-5.023.377-5.023 5.735v3.738c0 1.913 3.014 4.257 5.023 4.257s5.023-2.344 5.023-4.257v-3.738c.001-5.358-2.546-5.735-5.023-5.735Z"
                fill="#ffd56b"
            />
            <path
                data-name="Path 93482"
                d="M15.43 4.304c-2.43 0-2.791.913-2.791.913a3.829 3.829 0 0 0-2.79 3.247 22.838 22.838 0 0 0 .558 3.907c.3-3.653 1.255-5.023 2.233-5.023.707 0 1.269.558 2.791.558 1.285 0 1.6-.558 2.791-.558 1.674 0 2.233 4.447 2.233 5.023a24.19 24.19 0 0 0 .558-3.907c-.001-1.913-2.117-4.16-5.583-4.16Z"
                fill="#864407"
            />
            <path
                data-name="Path 93483"
                d="M15.552 4c-2.324 0-2.913 1.218-2.913 1.218a2.638 2.638 0 0 0 .291 1.218s.424.913 2.847.913A3.37 3.37 0 0 0 19.339 4Z"
                fill="#743903"
            />
            <path
                data-name="Path 93484"
                d="M25.756 26.326a.693.693 0 0 1 .279-.555v-.918a.553.553 0 0 1 0-.961v-.915h-2.791v4.465l.558.559h1.573l.659-.559v-.565a.693.693 0 0 1-.278-.551Z"
                fill="#e9cc03"
            />
            <path
                data-name="Path 93485"
                d="M28.268 19.349a3.628 3.628 0 1 0-7.256 0c0 .094.007.186.015.279h-.015v2.233a1.117 1.117 0 0 0 1.116 1.116h5.024a1.117 1.117 0 0 0 1.116-1.116v-2.233h-.014c.007-.093.014-.185.014-.279Zm-3.628-2.228a1.116 1.116 0 1 1-1.116 1.116 1.117 1.117 0 0 1 1.116-1.116Z"
                fill="#fede00"
            />
        </g>
    </svg>
)