import { Button } from '@mui/material'
import React from 'react'
import { UnitAccountCardStyle } from './styles'
import { UnitAccountCard } from './unitAccountCard'

export const AddAccount = ({
    hide = false,
    openClick = () => false,
    type = "",
    title = "",
    subTitle = "",
    image_url = "",
    edit = false,
    data = {},
    index = 0,
    t = () => false
}) => {
    const classes = UnitAccountCardStyle({edit})
    const openAccount = () => {
        openClick(type, index)
    }
    return (
        <>
            {
                !hide ?
                    <Button variant='contained'
                        fullWidth
                        className={classes.addAccountBtn}
                        disableElevation
                        onClick={() => openAccount()}>
                        {t("Add Person")}
                    </Button>
                    :
                    <>
                        <UnitAccountCard
                            title={title}
                            subTitle={subTitle}
                            image_url={image_url}
                            roundAvatar
                            edit={edit}
                            data={data}
                            openEdit={openAccount}
                            type={"rounded"}
                            t={t} />
                    </>
            }
        </>
    )
}