import React from "react";
import { withNavBars } from "../../HOCs";
import Correspondences from "./correspondences";

class CorrespondencesParent extends React.Component {
  render() { return <Correspondences {...this.props} />; }
}

const props = { boxShadow: false }

export default withNavBars(CorrespondencesParent, props);
