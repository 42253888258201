export const PropertyType = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98484">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 95009"
                    d="m21.775 10.241-2.084-2.084A.535.535 0 0 0 19.313 8H9.677a.535.535 0 0 0-.378.157L7.157 10.3a.536.536 0 0 0 .378.914h13.93a.536.536 0 0 0 .31-.973Z"
                />
                <path
                    data-name="Path 95010"
                    d="m21.788 17.224-3.212-2.143a.537.537 0 0 0-.669 0l-3.212 2.143a.536.536 0 0 0 .335.954h6.424a.536.536 0 0 0 .335-.954Z"
                />
                <path
                    data-name="Path 95011"
                    d="m14.294 17.224-3.212-2.143a.537.537 0 0 0-.669 0L7.2 17.224a.536.536 0 0 0 .335.954h6.425a.536.536 0 0 0 .335-.954Z"
                />
                <path
                    data-name="Path 95012"
                    d="M10.116 14.636a1.034 1.034 0 0 1 .632-.207 1.075 1.075 0 0 1 .668.234l2.812 1.874V11.75H7.536v4.609Zm-.974-1.814a.535.535 0 0 1 .535-.536h2.141a.535.535 0 0 1 .535.536v.536a.535.535 0 0 1-.535.536H9.677a.535.535 0 0 1-.535-.536Z"
                />
                <path
                    data-name="Path 95013"
                    d="M14.227 23v-4.328a1.054 1.054 0 0 1-.268.042H7.536v3.75a.536.536 0 0 0 .535.536h.535v-2.678a.536.536 0 0 1 .535-.536h1.071a.536.536 0 0 1 .535.536v2.679Zm-2.944-2.678a.536.536 0 0 1 .535-.536h.535a.536.536 0 0 1 .535.536v.536a.536.536 0 0 1-.535.536h-.535a.536.536 0 0 1-.535-.536Z"
                />
                <path
                    data-name="Path 95014"
                    d="m14.763 16.538 2.848-1.9a1.034 1.034 0 0 1 .632-.207 1.075 1.075 0 0 1 .668.234l2.544 1.7V11.75h-6.692v4.788Zm2.41-4.252h2.14a.535.535 0 0 1 .535.536v.536a.535.535 0 0 1-.535.536h-2.14a.535.535 0 0 1-.535-.536v-.536a.535.535 0 0 1 .535-.536Z"
                />
                <path
                    data-name="Path 95015"
                    d="M14.763 18.672V23h1.338v-2.678a.536.536 0 0 1 .535-.536h1.071a.536.536 0 0 1 .535.536v2.679h2.677a.536.536 0 0 0 .535-.536v-3.75h-6.423a1.054 1.054 0 0 1-.268-.042Zm4.015 1.649a.536.536 0 0 1 .535-.536h.535a.536.536 0 0 1 .535.536v.536a.536.536 0 0 1-.535.536h-.535a.536.536 0 0 1-.535-.536Z"
                />
            </g>
            <path data-name="Rectangle 52157" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
