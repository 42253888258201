import React from "react"

export const DeleteSvgIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_113297" data-name="Group 113297" transform="translate(0.335)">
    <g id="Rectangle_56657" data-name="Rectangle 56657" transform="translate(-0.335)" fill="#fff" stroke="#e4e8ee" stroke-width="1">
      <rect width="24" height="24" rx="4" stroke="none"/>
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="none"/>
    </g>
    <path id="icons8-trash_2_" data-name="icons8-trash (2)" d="M12,4A2.548,2.548,0,0,0,9.45,6.1H6.93a.522.522,0,0,0-.177,0h-1.2a.526.526,0,1,0,0,1.05H6.34l.928,9.11A2,2,0,0,0,9.285,18h5.429a2,2,0,0,0,2.017-1.74l.928-9.11h.788a.526.526,0,1,0,0-1.05h-1.2a.581.581,0,0,0-.177,0H14.549A2.548,2.548,0,0,0,12,4Zm0,1.05A1.448,1.448,0,0,1,13.422,6.1H10.577A1.448,1.448,0,0,1,12,5.05ZM7.45,7.15h9.1l-.918,9.009a.9.9,0,0,1-.917.791H9.285a.9.9,0,0,1-.917-.791ZM10.7,8.892a.538.538,0,0,0-.544.533v5.25a.553.553,0,0,0,1.105,0V9.425a.512.512,0,0,0-.162-.379A.568.568,0,0,0,10.7,8.892Zm2.579,0a.538.538,0,0,0-.544.533v5.25a.553.553,0,0,0,1.105,0V9.425a.512.512,0,0,0-.162-.379A.568.568,0,0,0,13.281,8.892Z" transform="translate(0.273 1)" fill="#ff4b4b"/>
  </g>
</svg>

)