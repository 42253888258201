import React from "react";

function NoSelectionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="136"
      height="32"
      viewBox="0 0 136 32"
    >
      <g data-name="Group 113822" transform="translate(-979 -384)">
        <rect
          width="136"
          height="32"
          fill="#f5f7fa"
          data-name="Rectangle 57599"
          rx="8"
          transform="translate(979 384)"
        ></rect>
        <g data-name="Group 113821" transform="translate(147.723 106.723)">
          <path
            fill="#ced3dd"
            d="M2.569 0h15.416a2.569 2.569 0 012.569 2.569v15.416a2.569 2.569 0 01-2.569 2.569H2.569A2.569 2.569 0 010 17.985V2.569A2.569 2.569 0 012.569 0z"
            data-name="Path 100576"
            transform="translate(837 283)"
          ></path>
          <path
            fill="#fff"
            d="M11.854 4a2.333 2.333 0 00-2.409 2.248A2.333 2.333 0 0011.854 8.5a2.333 2.333 0 002.409-2.248A2.333 2.333 0 0011.854 4zm2.9 5.4H8.958a.929.929 0 00-.958.89v.342a2.063 2.063 0 001.278 1.813 5.751 5.751 0 002.576.548c1.856 0 3.854-.739 3.854-2.361v-.342a.929.929 0 00-.958-.89z"
            transform="translate(835.423 284.781)"
          ></path>
        </g>
        <rect
          width="99"
          height="6"
          fill="#ced3dd"
          data-name="Rectangle 57600"
          rx="3"
          transform="translate(1010 393)"
        ></rect>
        <rect
          width="24"
          height="4"
          fill="#e4e8ee"
          data-name="Rectangle 57601"
          rx="2"
          transform="translate(1010 403)"
        ></rect>
        <rect
          width="24"
          height="4"
          fill="#e4e8ee"
          data-name="Rectangle 57603"
          rx="2"
          transform="translate(1074 403)"
        ></rect>
        <rect
          width="30"
          height="4"
          fill="#e4e8ee"
          data-name="Rectangle 57602"
          rx="2"
          transform="translate(1039 403)"
        ></rect>
      </g>
    </svg>
  );
}

export default NoSelectionIcon;
