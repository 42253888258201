import { useContext, useEffect, useState } from 'react';
import { DialogContext } from '../../contexts';

export const Circle = (props) => {

    const {
        infoWindow = null,
        setInfoWindow = null,
    } = props;

    const dialog = useContext(DialogContext);

    const [circle, setCircle] = useState(null);

    useEffect(() => {


        if (!circle) {
            setCircle(new window.google.maps.Circle({}));
        }
        if (!infoWindow) {
            setInfoWindow(new window.google.maps.InfoWindow({
                content: '',
                // pixelOffset: new window.google.maps.Size(100,100),
                // disableAutoPan: true
            }))
        }

        // remove circle from map on unmount
        return () => {
            if (circle) {
                circle.setMap(null);
            }
            if (infoWindow) {
                infoWindow.setMap(null);
            }
        };
        // eslint-disable-next-line
    }, [circle, infoWindow]);


    useEffect(() => {

        if (circle) {
            circle.setOptions(props);
        }
        // eslint-disable-next-line
    }, [props]);

    useEffect(() => {

        if (circle) {
            circle.addListener("mouseover", (e) => {
                circle.setOptions(props?.mouseover)
                // toggleInfoWindow(props?.hoverComponent)
            });

            circle.addListener("mouseout", () => {
                circle.setOptions(props?.mouseout)
                // toggleInfoWindow()
            });


            circle.addListener("click", () => {

                dialog.setDialog({
                    ...dialog,
                    open: true,
                    // body: props?.component,
                    body: props?.component && props?.component(circle),
                    sx: [{
                        '& .MuiDialogContent-root': {
                            padding: 0
                        }
                    }]
                })
            });
        }
        // eslint-disable-next-line
    }, [circle]);

    // const returnComponent = (Component) = (props) => {
    //     return <Component {...props} circle={circle} />
    // }


    // const toggleInfoWindow = (component) => {
    //     if (infoWindow) {
    //         let findPopup = document?.getElementsByClassName('gm-style-iw-t');
    //         if (infoWindow?.getMap() && !component) {
    //             findPopup[0].classList.remove('asd');
    //             infoWindow.close();
    //         }
    //         else {
    //             if (findPopup?.length) {
    //                 findPopup[0].classList.add('asd');
    //                 // findPopup[0].style.padding = "16px"
    //             }
    //             var content = document.createElement('div');

    //             ReactDOM.render(component, content);
    //             infoWindow.setContent(content);
    //             // infoWindow.setPosition(circle.getBounds().getNorthEast());
    //             infoWindow.setPosition(circle.getCenter());
    //             infoWindow.open(circle.getMap(), circle);
    //         }
    //     }
    // }

    return null;
}