import { Box, Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Avatar } from '@mui/material';

import moment from 'moment';
import React from 'react'
import { eachHourOfInterval } from 'date-fns'
import { weeklyCalendarStyle, eventListStyle } from './style'
import { CalProgressbar } from '../../screens/resourceBoard/component/calProgressbar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CalendarLoadIcon from '../../assets/calenderLoadIcon';
import { stringAvatar } from '../../utils';

export const WeeklyCalendar = ({ weekdates = [], data = [], handleEvent = () => false, }) => {
    const classes = weeklyCalendarStyle()

    const hours = eachHourOfInterval({
        start: new Date(2022, 7, 25, 0),
        end: new Date(2022, 7, 25, 23)
    })

            const HtmlTooltip= styled(({ className, ...props }) => (
                <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: '#fff',
                    maxWidth: "100%",
                    border: '1px solid #dadde9',
                },
            }));


    return (
        <Box>
            <TableContainer className={classes.table_container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.loadCell}>
                                <Stack spacing={1} alignItems={"center"}>
                                    {/* <Typography className={classes.listTitle}> Load </Typography> */}
                                    <CalendarLoadIcon />

                                </Stack>
                            </TableCell>
                            {
                                weekdates?.map((e) => {
                                    return (
                                        <TableCell className={classes.headCells}>

                                            <Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
                                                <Stack alignItems={"center"}>
                                                    <Typography className={classes.dayLabel}>
                                                        {moment(e).format("ddd")}
                                                    </Typography>
                                                    <Typography className={classes.dateLabel}>
                                                        {moment(e).format("DD")}
                                                    </Typography>
                                                </Stack>
                                                {
                                                    data?.map(d => {
                                                        return (
                                                            d?.date === moment(e).format("DD-MM-YYYY") ?
                                                                (d?.process && <CalProgressbar value={d?.process} />) : ""
                                                        )
                                                    })
                                                }
                                            </Stack>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            hours?.map((val) => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.titleCells}>

                                            <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"}>
                                                <Typography sx={{ textAlign: "center" }}>{moment(val).format("HH:mm")}</Typography>
                                            </Stack>
                                        </TableCell>
                                        {
                                            weekdates?.map(date => {
                                                return (
                                                    <TableCell className={classes.cells} sx={{ padding: "8px !important" }}>

                                                        {
                                                            data?.map(wd => {
                                                                return (
                                                                    wd?.date === moment(date).format("YYYY-MM-DD") &&
                                                                    <div>
                                                                        {
                                                                            wd?.data?.filter(item => item.time === moment(val).format("HH:mm:ss"))?.map(evnt => {

                                                                                return (
                                                                                    <Box>
                                                                                        {
                                                                                            evnt?.event?.map((d) => {
                                                                                                return (
                                                                                                    <Box>
                                                                                                        {
                                                                                                            evnt?.event?.length < 2 &&
                                                                                                            <Box sx={{ cursor: "pointer", width: "100%", overflow: "hidden" }}>

                                                                                                                <HtmlTooltip
                                                                                                                    title={
                                                                                                                        <React.Fragment>
                                                                                                                            <CustomToolTip data={evnt} />
                                                                                                                        </React.Fragment>
                                                                                                                    }
                                                                                                                    arrow className={classes.customTooltip}
                                                                                                                >

                                                                                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}
                                                                                                                        className={d?.is_expired ? classes.expiredStrip : d?.booking_status_id === 1 ? classes.successStrip : classes.cancelledStrip}
                                                                                                                        p={0.7} >
                                                                                                                        <Typography className={classes.event_label} onClick={() => handleEvent(date)} noWrap> {d?.title} </Typography>
                                                                                                                    </Stack>
                                                                                                                </HtmlTooltip>
                                                                                                            </Box>
                                                                                                        }

                                                                                                    </Box>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        {
                                                                                            evnt?.event?.length > 1 &&
                                                                                            <Box sx={{ cursor: "pointer", width: "100%", overflow: "hidden" }}>
                                                                                                <HtmlTooltip
                                                                                                    title={evnt?.event[0].status === "Meter Reading" ?
                                                                                                        <React.Fragment>
                                                                                                            <CustomToolTip data={evnt?.event?.[0]} />

                                                                                                        </React.Fragment> : ""
                                                                                                    }
                                                                                                    arrow className={classes.customTooltip}
                                                                                                >


                                                                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}
                                                                                                        sx={{
                                                                                                            // backgroundColor: evnt?.event[0]?.status === "Meter Reading" ? "#FFFAD880" : evnt?.event[0]?.status === "Move In" ? "#FEEAEA80" : "#DBF0F180",
                                                                                                            // color: evnt?.event[0]?.status === "Meter Reading" ? "#B3A16D" : evnt?.event[0]?.status === "Move In" ? "#B3776D" : "#6DAFB3",
                                                                                                            width: "100%"
                                                                                                        }}
                                                                                                        className={evnt?.event?.[0]?.is_expired ? classes.expiredStrip : evnt?.event?.[0]?.booking_status_id === 1 ? classes.successStrip : classes.cancelledStrip}
                                                                                                        p={0.7}>
                                                                                                        <Typography className={classes.event_label} noWrap> {evnt?.event?.[0]?.title} </Typography>

                                                                                                    </Stack>
                                                                                                </HtmlTooltip >
                                                                                                <Typography className={classes.moreLabel} onClick={() => handleEvent(date)}> +{evnt?.event?.length - 1} more </Typography>
                                                                                            </Box >
                                                                                        }
                                                                                    </Box >
                                                                                )
                                                                            })
                                                                        }
                                                                    </div >
                                                                )
                                                            })
                                                        }
                                                    </TableCell >
                                                )
                                            })
                                        }
                                    </TableRow >
                                )
                            })
                        }
                    </TableBody >

                </Table >
            </TableContainer >
        </Box >
    )
}

const CustomToolTip = ({ data = {} }) => {
    const classes = eventListStyle()
    const toolData = data?.event?.[0]
    // const getClass = {
    //     1: classes.boxSuccess
    // }
    return (
        <Stack spacing={2} direction={"row"}>
            {/* <ChairIcon /> */}
            <Avatar src={toolData?.facility_assets?.[0]} {...stringAvatar(toolData?.request_type, 8, "2px")} className={classes.toolTipAvatar}></Avatar>
            <Stack spacing={1}>
                {/* <Typography className={getClass?.[toolData?.booking_status_id]} p={0.5}>{toolData?.booking_status}</Typography> */}
                <Typography className={classes.ttTitle}> {toolData?.request_type} </Typography>
                <Typography className={classes.ttsecTitle}> {toolData?.period === "Daily" ? "00:00 - 24:00" : ""} {moment(toolData?.date).format("DD MMM YY")} </Typography>
                <Typography className={classes.ttassigned_by}> Booked By: {toolData?.booked_by}, {toolData?.booked_by_mobile_country_code}{toolData?.booked_by_mobile}  </Typography>

            </Stack>
        </Stack>
    )
}