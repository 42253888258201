export const FinalSettlementHeading = (t) => {
    return [
        { title: t("Agreement No"), field: "agreement_no" },
        { title: t("Account No"), field: "account_no" },
        { title: t("Account Name"), field: "account_name" },
        { title: t("generated Date"), field: "generated_date" },
        { title: t("Start Date"), field: "start_date" },
        { title: t("End Date"), field: "end_date" },
        { title: t("Status"), field: "status" },
    ];
}

export const FinalSettlementPath = [
    "agreement_no",
    "account_no",
    "account_name",
    "generated_date",
    "start_date",
    "end_date",
    "status"
]

export const finalSettlementListOptions = (t) => [
    {
        label: t("Expired"),
        value: "Expired"
    },
    {
        label: t("Terminated"),
        value: "Terminated"
    },
]



//// view page data

// -------------------- Property & Unit Details

export const ProertyUnitHeading = (t) => {
    return [
        { title: t("Property No"), field: "property_id" },
        { title: t("Property Name"), field: "property_name" },
        { title: t("Unit No"), field: "unit_id" },
        { title: t("Unit Name"), field: "unit_name" },
        { title: t("Location"), field: "location" },
        { title: t("Move In Date"), field: "move_in_date" },
        { title: t("Move Out Date"), field: "move_out_date" },
    ];
}

export const ProertyUnitPath = [
    "property_id",
    "property_name",
    "unit_id",
    "unit_name",
    "location",
    "move_in_date",
    "move_out_date"
]

export const PropertyUnitDatatype = [
    { type: ["text"], name: "property_id" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "location" },
    { type: ["date"], name: "move_in_date" },
    { type: ["date"], name: "move_out_date" },
]

//  ---------- Termination Request

export const TerminationRequestHeading = (t) => {
    return [
        { title: t("Request ID"), field: "request_id" },
        { title: t("Request Date"), field: "request_date" },
        { title: t("Accepted By"), field: "accepted_by" },
        { title: t("Accepted Date"), field: "accepted_date" },
        { title: t("Contract Exit Date"), field: "contract_exit_date" },
        { title: t("Move Out Completed Date"), field: "move_out_completed" },
        { title: t("Actual Exit Date"), field: "actual_exit_date" },
    ];
}

export const TerminationRequestPath = [
    "request_id",
    "request_date",
    "accepted_by",
    "accepted_date",
    "contract_exit_date",
    "move_out_completed",
    "actual_exit_date"
]

export const TerminationRequestDatatype = [
    { type: ["text"], name: "request_id" },
    { type: ["date"], name: "request_date" },
    { type: ["text"], name: "accepted_by" },
    { type: ["date"], name: "accepted_date" },
    { type: ["date"], name: "contract_exit_date" },
    { type: ["date"], name: "move_out_completed" },
    { type: ["date"], name: "actual_exit_date" },
]

// ----------- scheduled Invoice 

export const ScheduledHeading = (t) => {
    return [
        { title: t("Schedule No"), field: "schedule_no" },
        { title: t("Billing Date"), field: "billing_date" },
        { title: t("Billing Period"), field: "billing_period" },
        { title: t("Total Amount"), field: "total_amount" },
        { title: t("Payment Method"), field: "payment_method" },
        { title: t("Posting Date"), field: "posting_date" },
        { title: t("Posting Status"), field: "posting_status" },
        { title: t("Invoice No"), field: "invoice_no" },
        { title: t("Invoice Status"), field: "invoice_status" },
    ];
}

export const ScheduledPath = [
    "schedule_no",
    "billing_date",
    "billing_period",
    "total_amount",
    "payment_method",
    "posting_date",
    "posting_status",
    "invoice_no",
    "invoice_status"
]

export const ScheduledDatatype = [
    { type: ["text"], name: "schedule_no" },
    { type: ["date"], name: "billing_date" },
    { type: ["text"], name: "billing_period" },
    { type: ["text"], name: "total_amount" },
    { type: ["text"], name: "payment_method" },
    { type: ["date"], name: "posting_date" },
    { type: ["text"], name: "posting_status" },
    { type: ["text"], name: "invoice_no" },
    { type: ["status"], name: "invoice_status" },
]

// --------------- Posted Invoice 

export const PostedInvoiceHeading = (t) => {
    return [
        { title: t("Invoice No"), field: "invoice_id" },
        { title: t("Invoice Date"), field: "invoice_date" },
        { title: t("Invoice Type"), field: "invoice_type" },
        { title: t("Total Amount"), field: "total_amount" },
        { title: t("Payment Method"), field: "payment_method" },
        { title: t("Settlement Date"), field: "settlement_date" },
        { title: t("Settle Amount"), field: "settlement_amount" },
        { title: t("Due Amount"), field: "due_amount" },
        { title: t("Invoice Status"), field: "invoice_status" },
    ];
}

export const PostedInvoicePath = [
    "invoice_id",
    "invoice_date",
    "invoice_type",
    "total_amount",
    "payment_method",
    "settlement_date",
    "settlement_amount",
    "due_amount",
    "invoice_status"
]

export const PostedInvoiceDatatype = [
    { type: ["text"], name: "invoice_id" },
    { type: ["date"], name: "invoice_date" },
    { type: ["text"], name: "invoice_type" },
    { type: ["text"], name: "total_amount" },
    { type: ["text"], name: "payment_method" },
    { type: ["date"], name: "settlement_date" },
    { type: ["text"], name: "settlement_amount" },
    { type: ["text"], name: "due_amount" },
    { type: ["status"], name: "invoice_status" },
]

// -------------------- Receipt & Settlement

export const ReceiptSettlementHeading = (t) => {
    return [
        { title: t("Receipt No"), field: "receipt_no" },
        { title: t("Receipt Date"), field: "receipt_date" },
        { title: t("Payment Method"), field: "payment_method" },
        { title: t("Cheque Number"), field: "cheque_no" },
        { title: t("Paid Amount"), field: "paid_amount" },
        { title: t("Used Amount"), field: "used_amount" },
        { title: t("Unused Amount"), field: "unused_amount" },
        { title: t("Settle Invoices"), field: "settle_invoice" },
        { title: t("Status"), field: "status" },
    ];
}

export const ReceiptSettlementPath = [
    "receipt_no",
    "receipt_date",
    "payment_method",
    "cheque_no",
    "paid_amount",
    "used_amount",
    "unused_amount",
    "settle_invoice",
    "status"
]

export const ReceiptSettlementDatatype = [
    { type: ["text"], name: "receipt_no" },
    { type: ["date"], name: "receipt_date" },
    { type: ["text"], name: "payment_method" },
    { type: ["text"], name: "cheque_no" },
    { type: ["text"], name: "paid_amount" },
    { type: ["text"], name: "used_amount" },
    { type: ["text"], name: "unused_amount" },
    { type: ["long_text"], name: "settle_invoice" },
    { type: ["status"], name: "status" },
]

// --------------- Credit Notes

export const CreditNoteHeading = (t) => {
    return [
        { title: t("Credit Note ID"), field: "credit_note_id" },
        { title: t("Date"), field: "date" },
        { title: t("Credit Amount"), field: "credit_amount" },
        { title: t("Used Amount"), field: "used_amount" },
        { title: t("Unused Amount"), field: "unused_amount" },
        { title: t("Settle Invoices"), field: "settle_invoice" },
        { title: t("Status"), field: "status" },
    ];
}

export const CreditNotePath = [
    "credit_note_id",
    "date",
    "credit_amount",
    "used_amount",
    "unused_amount",
    "settle_invoice",
    "status"
]

export const CreditNoteDatatype = [
    { type: ["text"], name: "credit_note_id" },
    { type: ["date"], name: "date" },
    { type: ["text"], name: "credit_amount" },
    { type: ["text"], name: "used_amount" },
    { type: ["text"], name: "unused_amount" },
    { type: ["long_text"], name: "settle_invoice" },
    { type: ["status"], name: "status" },
]


// --------------- Debit Notes

export const DebitNoteHeading = (t) => {
    return [
        { title: t("Debit Note ID"), field: "debit_note_id" },
        { title: t("Date"), field: "date" },
        { title: t("Scheduled Invoice No"), field: "scheduled_invoice_no" },
        { title: t("Debit Invoice No"), field: "debit_invoice_no" },
        { title: t("Posted Date"), field: "posted_date" },
        { title: t("Debit Amount"), field: "debit_amount" },
        { title: t("Status"), field: "status" },
    ];
}

export const DebitNotePath = [
    "debit_note_id",
    "date",
    "scheduled_invoice_no",
    "debit_invoice_no",
    "posted_date",
    "debit_amount",
    "status"
]

export const DebitNoteDatatype = [
    { type: ["text"], name: "debit_note_id" },
    { type: ["date"], name: "date" },
    { type: ["text"], name: "scheduled_invoice_no" },
    { type: ["text"], name: "debit_invoice_no" },
    { type: ["date"], name: "posted_date" },
    { type: ["text"], name: "debit_amount" },
    { type: ["status"], name: "status" },
]

// --------------- Refundable

export const RefundableHeading = (t) => {
    return [
        { title: t("Rent Component"), field: "rent_component" },
        { title: t("Schedule Invoice ID"), field: "schedule_invoice_id" },
        { title: t("Invoice Number"), field: "invoice_no" },
        { title: t("Refundable Amount"), field: "refundable_amount" },
        { title: t("Receipt Number"), field: "receipt_no" },
        { title: t("Receipt Date"), field: "receipt_date" },
        { title: t("Settlement ID"), field: "settlement_id" },
        { title: t("Settled Amount"), field: "settlement_amount" },
        { title: t("Status"), field: "status" },
    ];
}

export const RefundablePath = [
    "rent_component",
    "schedule_invoice_id",
    "invoice_no",
    "refundable_amount",
    "receipt_no",
    "receipt_date",
    "settlement_id",
    "settlement_amount",
    "status"
]

export const RefundableDatatype = [
    { type: ["text"], name: "rent_component" },
    { type: ["text"], name: "schedule_invoice_id" },
    { type: ["text"], name: "invoice_no" },
    { type: ["text"], name: "refundable_amount" },
    { type: ["text"], name: "receipt_no" },
    { type: ["date"], name: "receipt_date" },
    { type: ["text"], name: "settlement_id" },
    { type: ["text"], name: "settlement_amount" },
    { type: ["status"], name: "status" },
]


// --------------- Correspondance

export const CorrespondanceHeading = (t) => {
    return [
        { title: t("Credit Note ID"), field: "credit_note_id" },
        { title: t("Date"), field: "date" },
        { title: t("Proeprty"), field: "property" },
        { title: t("Unit Number"), field: "unit_no" },
        { title: t("Correspondences Type"), field: "correspondance_type" },
        { title: t("Acknowledged On"), field: "acknowledged_no" },
        { title: t("Status"), field: "status" },
    ];
}

export const CorrespondancePath = [
    "credit_note_id",
    "date",
    "property",
    "unit_no",
    "correspondance_type",
    "acknowledged_no",
    "status"
]

export const CorrespondanceDatatype = [
    { type: ["text"], name: "credit_note_id" },
    { type: ["date"], name: "date" },
    { type: ["text"], name: "property" },
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "correspondance_type" },
    { type: ["date"], name: "acknowledged_no" },
    { type: ["status"], name: "status" },
]