import * as React from "react"

const InvoiceSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 107572">
      <path data-name="Rectangle 56160" fill="none" d="M0 0h18v18H0z" />
      <path
        d="M2.907 1a.632.632 0 0 0-.643.631v13.053A2.325 2.325 0 0 0 4.58 17h8.842a2.325 2.325 0 0 0 2.316-2.316V1.631a.632.632 0 0 0-.888-.577l-1.651.734-1.858-.743a.632.632 0 0 0-.469 0l-1.871.748-1.871-.748a.632.632 0 0 0-.469 0l-1.858.743-1.651-.734A.632.632 0 0 0 2.907 1Zm2.094 5.053h8a.632.632 0 1 1 0 1.263h-8a.632.632 0 1 1 0-1.263Zm0 3.789h4.632a.632.632 0 0 1 0 1.263H5.001a.632.632 0 1 1 0-1.263Zm7.158 0h.842a.632.632 0 1 1 0 1.263h-.842a.632.632 0 1 1 0-1.263Zm-7.158 2.526h4.632a.632.632 0 1 1 0 1.263H5.001a.632.632 0 1 1 0-1.263Zm7.158 0h.842a.632.632 0 1 1 0 1.263h-.842a.632.632 0 1 1 0-1.263Z"
        fill="#78b1fe"
      />
    </g>
  </svg>
)

export default InvoiceSvg