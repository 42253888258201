import React from 'react';
import { Grid, } from "@mui/material";
import { AlertDialog, OtherInfoCard } from '../../../components';
import CityProperty from '../../../assets/cityproperty';
import { Units } from './units';

export const OtherInfo = (props) => {

    const { t } = (props);
    const data = [
        {
            id: 1,
            name: t("Total units"),
            view: t("View units"),
            count: props?.data?.data?.unit_count?.[0]?.count_id,
            image: <CityProperty color={"#F5FFFB"} />,
            color: "#6248E2",
        },
    ]
    const [view, setView] = React.useState(false)
    const [index, setIndex] = React.useState(0)
    const open = () => {
        setView(true)
    }

    const close = () => {
        setView(false)
    }
    return (

        <div>
            <Grid container spacing={2}>
                {data.map((item, index) => {
                    return (
                        <Grid item xs={2}>
                            <OtherInfoCard data={item} index={index} open={open} setIndex={setIndex} />
                        </Grid>
                    )
                })}

            </Grid>
            {/* General request popup */}
            <AlertDialog
                open={view}
                onClose={close}
                header={<>
                    {
                        index === 0 && "Units"
                    }

                </>
                }
                component={
                    <>
                        {
                            index === 0 && <Units t={t} />
                        }

                    </>
                }
            />
        </div>


    )
}