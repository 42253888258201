export const DutyRosterHeading = (t = () => false) => [
    { title: t("Resource Name"), field: "resource_name" },
    { title: t("Location"), field: "location" },
    { title: t("Check In"), field: "checkin" },
    { title: t("Check In Distance"), field: "check_in_distance" },
    { title: t("Check Out"), field: "checkout" },
    { title: t("Check Out Distance"), field: "check_out_distance" },
    { title: t("Time Difference"), field: "time_diff" },
    { title: t("Status"), field: "status" },
]

export const DutyRosterPath = ["resource_name", "location", "checkin", "check_in_distance", "checkout", "check_out_distance", "time_diff", "status"]

export const DutyRosterType = [
    { type: ["text"], name: "resource_name" },
    { type: ["text"], name: "location" },
    { type: ["text"], name: "checkin" },
    { type: ["text"], name: "check_in_distance" },
    { type: ["text"], name: "checkout" },
    { type: ["text"], name: "check_out_distance" },
    { type: ["text"], name: "time_diff" },
    { type: ["text"], name: "status" },
]
