import { Avatar, Stack, Typography } from "@mui/material"
import { reportCardStyles } from "./style"
import { Arrow } from "./arrow"

export const ReportCard = ({ data = {}, onClick = () => false }) => {
    const classes = reportCardStyles()

    return (
        <Stack className={classes.root}
            direction={"row"} spacing={"16px"}
            justifyContent={"space-between"} alignItems={"center"}
            onClick={() => onClick(data)}>
            <Avatar className={classes.avatar} variant="rounded"
                src={data?.image}>
                {data?.report_name ? data?.report_name?.substring(0, 2).toUpperCase() : "#"}
            </Avatar>
            <Stack className={classes.text_div}
                direction={"column"} spacing={"4px"}>
                {data?.report_name &&
                    <Typography className={classes.report}>
                        {data?.report_name}
                    </Typography>}
            </Stack>
            <Arrow />
        </Stack>
    )
}