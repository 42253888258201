import React from "react";
import { withNavBars } from "../../HOCs";
import ManageInvoice from "./manageInvoice";
class ManageInvoiceParent extends React.Component {
  render() {
    return <ManageInvoice />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(ManageInvoiceParent, props);
