import { makeStyles } from "@mui/styles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";
export const AssignCompenentStyles = makeStyles((theme) => ({
    dateTimePicker: {
        fontFamily: SemiBold,
        color: theme.typography.color.primary,
        padding: "14px",
        backgroundColor: "#fff",
        fontSize: "0.8125rem"
    },
    main: {
        backgroundColor: "white",
        width: "500px"
        // height: `calc(100vh - 200px)`
    },
    topNav: {
        postion: "fixed",
        top: 0,
        width: "500px",
        border: "1px solid #E4E8EE",
        backgroundColor:"#F2F4F7 !important"
    },
    counthr: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,

    },
    section1: {
        padding: "12px",
        borderRight: `1px solid ${theme.palette.border.secondary}`,

    },
    formRoot: {
        height: `calc(100vh - 150px)`,
        overflow: "overlay"
    },
    tophdrTitle: {
        fontSize:"1rem",
        fontFamily: ExtraBold,
        color: theme.typography.color.primary

    },
    // subHeader: {
    //     display: "flex",
    //     justifyContent: "space-between",
    //     backgroundColor: theme.palette.info.light,
    //     borderRadius: theme.palette.borderRadius,
    //     padding: "10px"
    // },
    modalName: {
        fontSize:"0.875rem",
        color: theme.palette.info.main,
        fontFamily: SemiBold,
        marginTop: "10px"
    },
    steps: {
        backgroundColor: theme.palette.border.secondary,
        borderRadius: theme.palette.borderRadius,
        width: "70px",
        textAlign: "center",
        marginTop: "20px",
        marginBottom: "15px",
        padding: "5px",
    },
    stepTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold,
    },
    section1header: {
        backgroundColor: theme.palette.info.light,
        borderRadius: theme.palette.borderRadius,
        padding: "10px"
    },
    subHeader: {
        backgroundColor: "#DBF0F180",
        borderRadius: theme.palette.borderRadius,
        padding: "10px",
        // margin: "2px"
    },
    datePicker: {

        display: "flex",
        marginBottom: "4px",
        // justifyContent: "space-between",
    },
    dividerstyle1: {
        borderColor: "#E4E8EE"
    },
    datePickerText: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold,
        marginTop: "2px",
        marginInlineStart: "12px"

    },
    icon: {
        // marginTop: "3px",
        color: "#98A0AC",
        fontSize:"1.125rem",
    },
    step2: {
        backgroundColor: theme.palette.info.light,
        borderRadius: theme.palette.borderRadius,
        textAlign: "center",
        marginTop: "20px",
        marginBottom: "15px",
        display: "flex",
        padding: "5px 8px"
    },
    PrimaryTitle: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontFamily: Bold,
        marginInlineStart: "8px"
        // marginTop:"10px" 
    },
    dividerstyle: {
        marginTop: "20px",
        borderColor: "#E4E8EE"
    },

    resourceTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
    },
    resourceSubTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Regular,
    },
    assignedhrs: {
        backgroundColor: theme.palette.border.secondary,
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        padding: "3px 10px",
        height: "25px"
    },
    assignText: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
        marginInlineStart: "8px"

    },
    searchBlock: {
        boxShadow: "0px 0px 10px #0000000D",
        border: `1px solid ${theme.palette.border.secondary}`,
        marginTop: "10px",
        marginBottom: "15px",

        borderRadius: "4px"
    },
    selecttitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        padding: "10px 10px 0px 10px",
    },
    footer: {
        border: `1px solid ${theme.palette.border.secondary}`,
        position: "fixed",
        width: 500,
        bottom: 0
    },
    cancelBtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: "white",
        height: "40px",
        marginTop: "6px",
        "&:hover": {
            backgroundColor: "white",
            border: `1px solid ${theme.palette.border.secondary}`,

        },
    },
    submit: {
        height: "40px",
        marginTop: "6px"
    },
    editAssignedhrs: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "3px 10px",
        marginTop: "5px"
    },
    edithoursText: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.success.main,
        marginLeft: "6px",
        marginRight: "4px",
        marginTop: "5px"


    },
    editIcon: {
        fontSize:"1rem",
        color: theme.palette.info.main,
        marginTop: "5px"


    },
    checkIcon: {
        fontSize:"1rem",
        color: theme.palette.primary.main,
        marginLeft: "10px",
        marginTop: "5px"

    },
    smalltextfield: {
        width: "100px",
        height: "30px",
        marginLeft: "5px",
        "&:hover": {
            border: `1px solid ${theme.palette.border.secondary}`
        }
    },
    arrowicon: {
        marginTop: "7px"
    },
    noData: {
        textAlign: "center",
        marginTop: "10px"
    },
    popupCards: {
        height: "150px",
        overflowY: "auto",
    },
    schedule: {
        backgroundColor: "white",
        borderRadius: theme.palette.borderRadius,
        padding: "5px"
    },
    menuList: {
        backgroundColor: "#FFFFFF !important",
        "&:hover": {
            backgroundColor: "white !important",
        },
    },
    schedulefix: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#5078E1",
        height: "28px",
        width: "80px",
        padding: "6px 6px 6px 6px",
        color: "white",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
    },
    schedulestrict: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#FFFFFF",
        height: "28px",
        width: "80px",
        padding: "6px 6px 6px 6px",
        color: theme.typography.color.primary,
        fontSize:"0.75rem",
        fontFamily: SemiBold,
    },
    estimatedhr: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px"
    },
    totalhr: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
        margin: "2px 0px 0px 5px !important",
        marginInlineStart: "12px !important"
    },
    menublock: {
        border: "1px solid #E4E8EE",
        boxShadow: "0px 0px 16px #00000014",
    }

}))
export const PopUpCardStyles = makeStyles((theme) => ({

    assignInsBox: {
        borderRadius: 4,
        background: theme.palette.background.secondary,
        padding: "2px 6px",
        // margin: "15px"
    },
    otherResourcediv: {
        // width: 420
    },
    selectInsBox: {
        borderRadius: 4,
        background: theme.palette.background.secondary,
        padding: "2px 6px",
        marginBottom: "20px"
    },
    userName: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontWeight: "600"
    },
    discDiv: {
        width: 6,
        height: 6,
        background: theme.palette.border.primary,
        borderRadius: "50%",
        marginLeft: 6,
    },
    num: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",

    },
    mail: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        marginLeft: 6,
    },
    numEmail: {
        alignItems: "center",
        marginTop: 7,

    },
    userDiv: {
        justifyContent: "space-between",
        cursor: "pointer",

    },
    type: {
        backgroundColor: theme.palette.info.main,
        color: theme.typography.color.white,
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.75rem",
        fontFamily: Bold,
        padding: "3px",
        marginLeft: "8px"
    },
    hrstext: {
        marginInlineStart: "3px",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    icon: {
        marginTop: "3px"
    },
    hoursection: {
        backgroundColor: theme.palette.background.darkSecondary,
        padding: "5px",
        textAlign: "center",
        borderRadius: theme.palette.borderRadius
    },
    totalhourRate: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold,
    },
    perhourRate: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
    },
    deletepointer: {
        cursor: "pointer",
        marginTop: "10px"
    },

}))
export const addOtherResourcesStyles = makeStyles((theme) => ({
    otherresources: {
        backgroundColor: theme.palette.background.dark,
        margin: "20px",

    },
    dividerstyle1: {
        borderColor: "#E4E8EE"
    },
    otherTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold,
        marginBottom: "2px"
    },
    otherSubText: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        marginBottom: "15px"
    },
    icon: {
        marginTop: "5px"
    },
    searchBlock: {
        boxShadow: "0px 0px 10px #0000000D",
        border: `1px solid ${theme.palette.border.secondary}`,
        marginTop: "20px",
        borderRadius: "4px"
    },
    selecttitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        padding: "10px 10px 0px 10px",
    },
    addresourcebtn: {
        "&:hover": {
            backgroundColor: "white",
            border: `1px solid ${theme.palette.info.main}`,

        },
    },
    otherres: {
        cursor: "pointer"
    },
    popupCardsother: {
        // height: "300px",
        // overflowY: "auto",
    },
    noDataOther: {
        textAlign: "center",
        marginTop: "30px"
    },
}))
