import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import CalendarPicker from '@mui/lab/CalendarPicker';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import { useTheme } from '@mui/styles';
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AlertContext } from "../../contexts";
import { AlertProps, Regular, SemiBold } from "../../utils";
import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';
const styles = makeStyles((theme) => ({
    placeholder: {
        fontFamily: Regular,
        fontSize:"0.875rem",
        whiteSpace: "nowrap",
        color: "#98A0AC"
    },
    // div: {
    //     min: "300px"
    // },
    title: {
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        color: "#4E5A6B",
        marginBottom: "8px"
    },
    type: {
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        color: "#4E5A6B",
        backgroundColor: "#F2F4F7",
        borderRadius: theme.palette.borderRadius,
        padding: "4px 8px",
        textAlign: "center",
        marginRight: "4px",
        marginLeft: "4px",
        cursor: "pointer"

    },
    textField: {
        width: "70px",
        padding: "4px",
        [`& fieldset`]: {
            borderRadius: theme.palette.borderRadius,
            height: (props) => (props.multiline ? "unset" : 32),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "11px 14px",
        },
    },
    dialog: {
        "& .MuiPopover-paper": {
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 8px 24px #0717411F"
        }
    },
    typea: {
        fontFamily: SemiBold,
        fontSize:"0.75rem",

        borderRadius: theme.palette.borderRadius,
        padding: "4px 8px",
        textAlign: "center",
        marginRight: "4px",
        marginLeft: "4px",
        cursor: "pointer",
        backgroundColor: "#5078E1",
        color: "white"
    },
    value: {
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        color: "#091B29",
    },
}));
const PopoverDatePicker2 = ({ startIcon = "", placeholder = "", updateState = () => false,
    disabled = false,
    startDate = "",
    endDate = "",
    border = false,
    label = {
        start: "Check In",
        end: "Check out"
    },
    dateImg = false
}) => {

    const classes = styles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const alert = React.useContext(AlertContext);
    const onChange = (dates) => {
        updateState("startDate", dates)
    };
    const onChange1 = (dates) => {
        if (startDate) {
            updateState("endDate", dates)
            handleClose()
        } else {

            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("First Fill start Date"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }

    };
    const handleClick = (event) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const theme = useTheme()
    return (
        <div>
            <Box sx={{ cursor: "pointer", border: border ? "1px solid #E4E8EE" : "none", borderRadius: theme.palette.borderRadius }} display="flex" alignItems="center" height="45px" onClick={handleClick}>
                <Box marginLeft="8px" marginTop="6px">
                    <img src={startIcon} alt="" />
                </Box>
                <Box flexGrow={1} marginLeft="4px">
                    {
                        startDate && endDate ?
                            <>
                                <Typography className={classes.value}>{moment(startDate)
                                    .tz(moment.tz.guess())
                                    .format("DD MMM YY")} - {moment(endDate)
                                        .tz(moment.tz.guess())
                                        .format("DD MMM YY")}</Typography>
                            </>
                            : <Typography className={classes.placeholder}>{placeholder}</Typography>
                    }
                </Box>
                <Box marginTop="4px" marginRight="8px">
                    {
                        dateImg ?
                            <img src="/images/icons8-calendar (5).svg" alt="" />
                            : <ArrowDropDownIcon style={{ fontSize: "26px", color: "#98A0AC" }} />
                    }

                </Box>

            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={classes.dialog}
            >
                <div className={classes.div}>
                    <Box p={1.4}>


                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={2}
                        >
                            <div>
                                <Typography className={classes.title}>{label?.start}</Typography>

                                <DatePicker
                                    selected={startDate}
                                    onChange={onChange}
                                    selectsDisabledDaysInRange
                                    inline
                                    disabled
                                />
                            </div>
                            <div>
                                <Typography className={classes.title}>{label?.end}</Typography>

                                <DatePicker
                                    minDate={startDate}
                                    selected={endDate}
                                    onChange={onChange1}
                                    selectsDisabledDaysInRange
                                    inline
                                    disabled
                                />
                            </div>

                        </Stack>



                    </Box>


                </div>
            </Popover>
        </div>
    )
}
export default withNamespaces("popoverDatePicker")(PopoverDatePicker2)

