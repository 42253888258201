import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const CreateInvoiceFreeTextStyle = makeStyles((theme)=>({
    addreason:{
        fontSize:"0.75rem",
        fontFamily:SemiBold,
        color:theme.palette.primary.main,
        cursor:"pointer"
    },
    invoiceType:{
        background:theme.palette.info.light,
        padding:"2px 4px",
        borderRadius:4
    },
    invoiceText:{
        fontSize:"0.75rem",
        fontFamily:Bold,
        color:theme.palette.info.main
    },
    debitType:{
        background:theme.palette.warning.light,
        padding:"2px 4px",
        borderRadius:4
    },
    debitText:{
        fontSize:"0.75rem",
        fontFamily:Bold,
        color:theme.palette.warning.main,
        marginInlineStart:"8px"
    },
    creditType:{
        background:theme.palette.success.light,
        padding:"2px 4px",
        borderRadius:4
    },
    creditText:{
        fontSize:"0.75rem",
        fontFamily:Bold,
        color:theme.palette.success.main,
        marginInlineStart:"8px"
    },
    remarktxt:{
        '& .MuiInput-input':{
            height:"12px !important",
            width:"100%",
            fontSize:"0.75rem",
            fontFamily:SemiBold
        }
    },
    bodyContent:{
        height:"calc(100vh - 230px)",
        overflow:"auto"
    },
    editIcon:{
        height:40,
        width:40,
        textAlign:"center",
        border:`1px solid ${theme.palette.primary.main}`,
        borderRadius:4,
        alignItems:"center",
        justifyContent:"center",
        display:"flex"
    },
    topBtn:{
        height:40
    },
    noneBtn:{
        display:"none"
    },
    descText:{
        fontSize:"0.75rem",
        fontFamily:SemiBold,
        width:220
    }
}))

export const CustomerPopupStyle = makeStyles((theme)=>({
    box:{
        border:`1px solid ${theme.palette.border.secondary}`,
        borderRadius:4,
        cursor:"pointer"
    },
    selectedBox:{
        border:`1px solid ${theme.palette.primary.main}`,
        borderRadius:4,
        background:theme.palette.background.selected,
        cursor:"pointer"
    },
    avatar:{
        height:40,
        width:40
    },
    addbtn:{
        height:40
    },
    tabs:{
        width:"100%",
        '& .MuiTabs-flexContainer MuiTabs-flexContainer':{
            justifyContent:"space-between"  
        },
        '& .MuiTabs-flexContainer':{
            display:"flex",
            justifyContent: "space-between"
        }
    },
    name:{
        fontSize:"0.875rem",
        color:theme.typography.color.primary,
        fontFamily:Bold
    },
    desc:{
        fontSize:"0.75rem",
        color:theme.typography.color.secondary
    },
    pricingLabel:{
        fontSize:"0.875rem",
        fontFamily:Bold,
        color:theme.typography.color.secondary,
        // textAlign:"center"
    },
    infinityBox:{
        width:"420px",
        marginLeft:"-10px"
    }
}))