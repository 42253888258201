export const ServiceInvoices = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105146">
            <g
                data-name="IM - Invoice Management - Service Invocies"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <g data-name="Group 105038">
                    <g data-name="Group 105032">
                        <g data-name="Group 105024">
                            <path
                                data-name="Path 98081"
                                d="M16.016 14.512a.747.747 0 0 1-.53-.219.25.25 0 1 1 .354-.354.25.25 0 0 0 .354-.354.25.25 0 1 1 .354-.354.75.75 0 0 1-.53 1.28Z"
                            />
                        </g>
                        <g data-name="Group 105025">
                            <path
                                data-name="Path 98082"
                                d="M15.666 14.365a.249.249 0 0 1-.177-.073l-1.538-1.535a.25.25 0 0 1 .354-.354l1.536 1.536a.25.25 0 0 1-.177.427Z"
                            />
                        </g>
                        <g data-name="Group 105026">
                            <path
                                data-name="Path 98083"
                                d="M16.37 13.658a.249.249 0 0 1-.177-.073l-1.536-1.536a.25.25 0 0 1 .354-.354l1.535 1.532a.25.25 0 0 1-.177.427Z"
                            />
                        </g>
                        <g data-name="Group 105027">
                            <path
                                data-name="Path 98084"
                                d="M14.482 12.476a.249.249 0 0 1-.177-.073l-1.744-1.745a.25.25 0 0 1 .354-.354l1.744 1.745a.25.25 0 0 1-.177.427Z"
                            />
                        </g>
                        <g data-name="Group 105028">
                            <path
                                data-name="Path 98085"
                                d="M12.738 10.733a.25.25 0 0 1-.25-.25v-.707a.25.25 0 0 1 .5 0v.707a.25.25 0 0 1-.25.25Z"
                            />
                        </g>
                        <g data-name="Group 105029">
                            <path
                                data-name="Path 98086"
                                d="M12.031 10.734a.25.25 0 0 1 0-.5h.707a.25.25 0 0 1 0 .5Z"
                            />
                        </g>
                        <g data-name="Group 105030">
                            <path
                                data-name="Path 98087"
                                d="M12.031 10.733a.25.25 0 0 1-.23-.151l-.531-1.238a.25.25 0 1 1 .459-.2l.531 1.238a.25.25 0 0 1-.23.349Z"
                            />
                        </g>
                        <g data-name="Group 105031">
                            <path
                                data-name="Path 98088"
                                d="M12.737 10.026a.247.247 0 0 1-.1-.02l-1.238-.531a.25.25 0 1 1 .2-.459l1.238.531a.25.25 0 0 1-.1.48Z"
                            />
                        </g>
                    </g>
                    <g data-name="Group 105037">
                        <g data-name="Group 105033">
                            <path
                                data-name="Path 98089"
                                d="M16.016 11.486a1.5 1.5 0 1 1 .546-2.9.25.25 0 0 1 .086.41l-.632.632a.25.25 0 0 0 0 .354.257.257 0 0 0 .354 0l.632-.632a.25.25 0 0 1 .41.086 1.5 1.5 0 0 1-1.4 2.047Zm-.064-2.5a.991.991 0 0 0-.644.29 1 1 0 0 0 0 1.414 1.024 1.024 0 0 0 1.414 0 1 1 0 0 0 .29-.643l-.29.29a.769.769 0 0 1-1.061 0 .751.751 0 0 1 0-1.061Z"
                            />
                        </g>
                        <g data-name="Group 105034">
                            <path
                                data-name="Path 98090"
                                d="M12.243 14.509a.75.75 0 0 1-.531-1.28.25.25 0 1 1 .354.354.25.25 0 1 0 .354.354.25.25 0 1 1 .354.354.747.747 0 0 1-.531.218Z"
                            />
                        </g>
                        <g data-name="Group 105035">
                            <path
                                data-name="Path 98091"
                                d="M12.596 14.363a.25.25 0 0 1-.177-.427l2.963-2.963a.25.25 0 0 1 .354.354l-2.963 2.963a.249.249 0 0 1-.177.073Z"
                            />
                        </g>
                        <g data-name="Group 105036">
                            <path
                                data-name="Path 98092"
                                d="M11.884 13.655a.25.25 0 0 1-.177-.427l2.963-2.963a.25.25 0 0 1 .354.354l-2.963 2.963a.25.25 0 0 1-.177.073Z"
                            />
                        </g>
                    </g>
                </g>
                <g data-name="Group 105039">
                    <path
                        data-name="Path 98093"
                        d="M9.75 21.75a.25.25 0 0 1-.246-.248V8.249a.25.25 0 0 1 .5 0v13.253a.25.25 0 0 1-.254.248Z"
                    />
                </g>
                <g data-name="Group 105040">
                    <path
                        data-name="Path 98094"
                        d="M18.752 21.75a.25.25 0 0 1-.25-.25V8.249a.25.25 0 0 1 .5 0v13.253a.25.25 0 0 1-.25.248Z"
                    />
                </g>
                <g data-name="Group 105041">
                    <path
                        data-name="Path 98095"
                        d="M17.252 18.506h-.25a.25.25 0 0 1 0-.5h.25a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105042">
                    <path
                        data-name="Path 98096"
                        d="M17.252 19.006a.25.25 0 0 1-.224-.138l-.25-.5a.25.25 0 0 1 .447-.224l.25.5a.25.25 0 0 1-.223.362Z"
                    />
                </g>
                <g data-name="Group 105043">
                    <path
                        data-name="Path 98097"
                        d="M17.252 19.006h-.25a.25.25 0 0 1 0-.5h.25a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105044">
                    <path
                        data-name="Path 98098"
                        d="M16.253 18.756h-.75a.25.25 0 1 1 0-.5h.75a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105045">
                    <path
                        data-name="Path 98099"
                        d="M13.003 18.756H11.25a.25.25 0 1 1 0-.5h1.753a.25.25 0 0 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105046">
                    <path
                        data-name="Path 98100"
                        d="M17.252 16.998h-.25a.25.25 0 0 1 0-.5h.25a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105047">
                    <path
                        data-name="Path 98101"
                        d="M17.252 17.498a.25.25 0 0 1-.224-.138l-.25-.5a.25.25 0 1 1 .447-.224l.25.5a.25.25 0 0 1-.223.362Z"
                    />
                </g>
                <g data-name="Group 105048">
                    <path
                        data-name="Path 98102"
                        d="M17.252 17.499h-.25a.25.25 0 0 1 0-.5h.25a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105049">
                    <path
                        data-name="Path 98103"
                        d="M16.253 17.249h-.75a.25.25 0 1 1 0-.5h.75a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105050">
                    <path
                        data-name="Path 98104"
                        d="M13.751 17.249h-2.5a.25.25 0 0 1 0-.5h2.5a.25.25 0 0 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105051">
                    <path
                        data-name="Path 98105"
                        d="M17.251 15.752h-6a.25.25 0 0 1 0-.5h6a.25.25 0 0 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105052">
                    <path
                        data-name="Path 98106"
                        d="M17.252 20.007h-.25a.25.25 0 0 1 0-.5h.25a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105053">
                    <path
                        data-name="Path 98107"
                        d="M17.252 20.507a.25.25 0 0 1-.224-.138l-.25-.5a.25.25 0 1 1 .447-.224l.25.5a.25.25 0 0 1-.223.362Z"
                    />
                </g>
                <g data-name="Group 105054">
                    <path
                        data-name="Path 98108"
                        d="M17.252 20.507h-.25a.25.25 0 0 1 0-.5h.25a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105055">
                    <path
                        data-name="Path 98109"
                        d="M16.253 20.257h-.75a.25.25 0 1 1 0-.5h.75a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105056">
                    <path
                        data-name="Path 98110"
                        d="M13.752 20.257h-2.5a.25.25 0 0 1 0-.5h2.5a.25.25 0 0 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105057">
                    <path
                        data-name="Path 98111"
                        d="M19.003 21.752h-9.5a.25.25 0 0 1 0-.5h9.5a.25.25 0 1 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105058">
                    <path
                        data-name="Path 98112"
                        d="M20.75 8.499a.25.25 0 0 1-.254-.25.75.75 0 0 0-1.5 0 .25.25 0 1 1-.5 0 1.25 1.25 0 1 1 2.5 0 .25.25 0 0 1-.246.25Z"
                    />
                </g>
                <g data-name="Group 105059">
                    <path
                        data-name="Path 98113"
                        d="M21.752 12.999a1.25 1.25 0 0 1-1.249-1.249.25.25 0 0 1 .5 0 .75.75 0 1 0 1.5 0 .25.25 0 0 1 .5 0 1.25 1.25 0 0 1-1.251 1.249Z"
                    />
                </g>
                <g data-name="Group 105060">
                    <path
                        data-name="Path 98114"
                        d="M9.751 8.499a.25.25 0 0 1-.25-.25 1.25 1.25 0 0 1 1.249-1.25.25.25 0 0 1 0 .5.75.75 0 0 0-.749.75.25.25 0 0 1-.25.25Z"
                    />
                </g>
                <g data-name="Group 105061">
                    <path
                        data-name="Path 98115"
                        d="M19.752 7.499h-9a.25.25 0 1 1 0-.5h9a.25.25 0 0 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105062">
                    <path
                        data-name="Path 98116"
                        d="M20.75 12.002a.25.25 0 0 1-.25-.25v-3.5a.25.25 0 0 1 .5 0v3.5a.25.25 0 0 1-.25.25Z"
                    />
                </g>
                <g data-name="Group 105063">
                    <path
                        data-name="Path 98117"
                        d="M21.753 13.002h-3a.25.25 0 0 1 0-.5h3a.25.25 0 0 1 0 .5Z"
                    />
                </g>
                <g data-name="Group 105064">
                    <path
                        data-name="Path 98118"
                        d="M22.751 12.001a.25.25 0 0 1-.252-.25V10.5a.25.25 0 0 1 .5 0v1.251a.25.25 0 0 1-.248.25Z"
                    />
                </g>
                <g data-name="Group 105065">
                    <path
                        data-name="Path 98119"
                        d="M19.751 23.005h-11a.751.751 0 0 1-.75-.75v-1.5a.751.751 0 0 1 .75-.75h1a.25.25 0 0 1 0 .5h-1a.25.25 0 0 0-.25.25v1.5a.25.25 0 0 0 .25.25h11a.25.25 0 0 0 .25-.25v-1.5a.25.25 0 0 0-.249-.25h-1a.25.25 0 0 1 0-.5h1a.75.75 0 0 1 .749.75v1.5a.751.751 0 0 1-.75.75Z"
                    />
                </g>
                <g data-name="Group 105066">
                    <path
                        data-name="Path 98120"
                        d="M22.751 10.751a.249.249 0 0 1-.177-.073l-.5-.5a.25.25 0 0 1 .354-.354l.5.5a.25.25 0 0 1-.177.427Z"
                    />
                </g>
                <g data-name="Group 105067">
                    <path
                        data-name="Path 98121"
                        d="M21.752 10.751a.25.25 0 0 1-.177-.427l.5-.5a.25.25 0 0 1 .354.354l-.5.5a.249.249 0 0 1-.177.073Z"
                    />
                </g>
                <g data-name="Group 105068">
                    <path
                        data-name="Path 98122"
                        d="M21.75 10.751a.249.249 0 0 1-.177-.073l-.5-.5a.25.25 0 0 1 .354-.354l.5.5a.25.25 0 0 1-.177.427Z"
                    />
                </g>
                <g data-name="Group 105069">
                    <path
                        data-name="Path 98123"
                        d="M20.751 10.751a.25.25 0 0 1-.177-.427l.5-.5a.25.25 0 0 1 .354.354l-.5.5a.249.249 0 0 1-.177.073Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)