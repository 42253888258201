import { Grid } from "@mui/material";
import React from "react";
import UnitCard from "../../propertyFinder3/component/unitCard";
export const UnitList = ({ list, onOpen, quotation = false }) => {
    return (
        <Grid container spacing={2}>
            {
                list?.map((val, index) => {
                    return (
                        <Grid item xs={6}>
                            <UnitCard
                                data={val}
                                noBtn
                                delete={true}
                                currency={val?.symbol}
                                onClick={onOpen}
                            />
                        </Grid>
                    )
                })
            }

        </Grid>

    )
}