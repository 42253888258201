export const configurationList = [
    'Dashboard Banner',
    'Community Banner',
    'Service Banner',
    'Login Banner',
    'OTP Banner',
    'Welcome Screen Banner',
];

export const returnFileSize = (number) => {
    if (number < 1024) {
        return number + 'bytes';
    } else if (number >= 1024 && number < 1048576) {
        return (number / 1024).toFixed(1) + 'KB';
    } else if (number >= 1048576) {
        return (number / 1048576).toFixed(1) + 'MB';
    }
}


export const conditions = {
    'Company Banner': {
        width: 343,
        height: 109
    },
    'Client Banner': {
        width: 435,
        height: 237
    },
    'Welcome Screen Banner': {
        width: 435,
        height: 889
    },
}

export const payloadType = {
    'Dashboard Banner': 'Dashboard',
    'Community Banner': 'Community',
    'Service Banner': 'Services',
}

export const getUrlBasedOnTitle = {
    "Login Banner": "login_banner_url",
    "OTP Banner": "otp_banner_url",
    "Welcome Screen Banner": "welcome_banner_url",
}