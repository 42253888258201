export const ServiceProviderPath = [
    "imageUrl",
    "name",
    "serviceProviderCode",
    "city",
    "primaryMobileNo",
    "primaryEmailId",
    "status"
]

export const ServiceProviderHeading = [
    { title: "Image", field: "imageUrl" },
    { title: "Provider Name", field: "name" },
    { title: "Provider Code", field: "serviceProviderCode" },
    { title: "City", field: "city" },
    { title: "Primary Mobile No", field: "primaryMobileNo" },
    { title: "Primary Email ID", field: "primaryEmailId" },
    { title: "Status", field: "status", },
    { title: "", field: "icon" },
]

export const ServiceProviderType = [
    { type: ["logo"], name: "imageUrl" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "serviceProviderCode" },
    { type: ["text"], name: "city" },
    { type: ["text"], name: "primaryMobileNo" },
    { type: ["text"], name: "primaryEmailId" },
    { type: ["status"], name: "status" },
    { type: ["more"], icon: "icon" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]