import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "16px",
    backgroundColor: "white",
    border: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: theme.palette.borderRadius,
  },
  utilityBlock: {
    borderLeft: "2px solid #CED3DD",
    borderWidth: "medium"
  },
  utilityTitle: {
    fontSize:"1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold
  },
  dividerStyle: {
    border: "1px solid #CED3DD"
  },
  utilityBox: {
    height: `calc(100vh - 300px)`,
    overflowY: "overlay"
  },
  reqblock: {
    backgroundColor: "#5078E1",
    backgroundImage: `url(${"/images/requestView.svg"})`,
    height: "250px"
  },
  closeicon: {
    color: "white",
    cursor: "pointer"
  },
  q1hdr: {
    color: "white",
    fontFamily: Bold,
    fontSize:"1rem",
    marginLeft: "15px"
  },
  q1subhdr: {
    color: "white",
    fontFamily: Regular,
    fontSize:"1rem",
    marginLeft: "15px"
  },
  yesbtn: {
    backgroundColor: "white",
    color: "#5078E1",
    "&:hover": {
      backgroundColor: "white",
      color: "#5078E1",
    }
  },
  nobtn: {
    color: "white",
    border: "1px solid #E4E8EE",
    "&:hover": {
      color: "white",
      border: "1px solid white",

    }
  },
  receiptBox: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
  },
  receipthdr: {
    fontSize:"0.75rem",
    fontFamily: Regular,
    color: theme.typography.color.tertiary
  },
  receiptblock: {
    backgroundColor: "#F2F4F7"
  },
  receiptBlockhdr: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,

    // display:"flex",
    // justifyContent:"center"
  },
  receiptTitle: {
    fontSize: "1.25rem",
    color: theme.typography.color.primary,
    fontFamily: ExtraBold
  },
  receiptDetailsBox: {
    backgroundColor: "#E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "5px"
  },
  receiptdetailTitle: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: ExtraBold
  },
  subTitle: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: SemiBold
  },
  valueTxt: {
    fontSize:"0.75rem",
    color: theme.typography.color.Primary,
    fontFamily: Bold
  },
  scrollover: {
    height: "300px",
    overflow: "scroll"
  },
  downBtn: {
    border: `1px solid ${theme?.typography?.color?.primary}`,
    color: theme?.typography?.color?.primary,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid ${theme?.typography?.color?.primary}`,
    }
  },
  filterImg: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    //marginTop: "4px",
    padding: "12px"
  }
}))