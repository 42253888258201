import { Box, Button, Stack, Typography } from "@mui/material"
import CloseIcon from "../../../assets/closeIcon"
import { ConfirmDialogUseStyles } from "./styles"

export const ConfirmDialog = ({
    onClose = () => false,
    state,
}) => {
    const classes = ConfirmDialogUseStyles()

    return (
        <div className={classes.root}>
            <Stack direction={"column"} p={"20px"}
                alignItems={"center"}>
                {state?.icon}
                <Box height={"10px"} />
                <Typography className={classes.question_text}>{state?.question}</Typography>
                <Box height={"20px"} />
                <Stack className={classes.button_div}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}>
                    <Button className={classes.cancel_button} fullWidth
                        onClick={state?.cancel?.action}>
                        <Typography className={classes.cancel_button_text}>
                            {state?.cancel?.label}
                        </Typography>
                    </Button>
                    <Button className={classes.yes_button} fullWidth
                        onClick={state?.yes?.action}>
                        <Typography className={classes.yes_button_text}>
                            {state?.yes?.label}
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
            <div className={classes.close_button} onClick={onClose}>
                <CloseIcon />
            </div>
        </div>
    )
}