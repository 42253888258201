import React from "react"

export const NoComponentIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="316" height="277" viewBox="0 0 316 277">
  <defs>
    <filter id="Rectangle_57461" x="0" y="0" width="162" height="99" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-color="#272727" flood-opacity="0.078"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_57461-2" x="154" y="0" width="162" height="99" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-2"/>
      <feFlood flood-color="#272727" flood-opacity="0.078"/>
      <feComposite operator="in" in2="blur-2"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_57461-3" x="0" y="89" width="162" height="99" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-3"/>
      <feFlood flood-color="#272727" flood-opacity="0.051"/>
      <feComposite operator="in" in2="blur-3"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_57461-4" x="154" y="89" width="162" height="99" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-4"/>
      <feFlood flood-color="#272727" flood-opacity="0.051"/>
      <feComposite operator="in" in2="blur-4"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_57461-5" x="0" y="178" width="162" height="99" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-5"/>
      <feFlood flood-color="#272727" flood-opacity="0.039"/>
      <feComposite operator="in" in2="blur-5"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <filter id="Rectangle_57461-6" x="154" y="178" width="162" height="99" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur-6"/>
      <feFlood flood-color="#272727" flood-opacity="0.039"/>
      <feComposite operator="in" in2="blur-6"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_113398" data-name="Group 113398" transform="translate(-710 -282)">
    <g id="Group_113394" data-name="Group 113394" transform="translate(0 -81)">
      <g id="Group_113388" data-name="Group 113388" transform="translate(185)">
        <g transform="matrix(1, 0, 0, 1, 525, 363)" filter="url(#Rectangle_57461)">
          <rect id="Rectangle_57461-7" data-name="Rectangle 57461" width="144" height="81" rx="4" transform="translate(9 6)" fill="#fff"/>
        </g>
        <g id="Group_113387" data-name="Group 113387" transform="translate(52 -37)">
          <g id="Rectangle_57465" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="26" height="26" stroke="none"/>
            <rect x="0.5" y="0.5" width="25" height="25" fill="none"/>
          </g>
          <path id="icons8-text-input-form" d="M6.4,11A3.419,3.419,0,0,0,3,14.4V23.69a3.419,3.419,0,0,0,3.4,3.4H25.6a3.419,3.419,0,0,0,3.4-3.4V14.4A3.419,3.419,0,0,0,25.6,11Zm0,1.857H25.6A1.534,1.534,0,0,1,27.143,14.4V23.69A1.534,1.534,0,0,1,25.6,25.238H6.4A1.534,1.534,0,0,1,4.857,23.69V14.4A1.534,1.534,0,0,1,6.4,12.857Zm1.238,8.667a.929.929,0,1,0,.929.929.929.929,0,0,0-.929-.929Zm3.1,0a.929.929,0,1,0,.929.929.929.929,0,0,0-.929-.929Zm3.1,0a.929.929,0,1,0,.929.929.929.929,0,0,0-.929-.929Z" transform="translate(538 412.571)" fill="#78b1fe"/>
        </g>
        <rect id="Rectangle_57466" data-name="Rectangle 57466" width="66" height="8" rx="4" transform="translate(574 424)" fill="#e4e8ee"/>
      </g>
      <g id="Group_113389" data-name="Group 113389" transform="translate(185)">
        <g transform="matrix(1, 0, 0, 1, 525, 363)" filter="url(#Rectangle_57461-2)">
          <rect id="Rectangle_57461-8" data-name="Rectangle 57461" width="144" height="81" rx="4" transform="translate(163 6)" fill="#fff"/>
        </g>
        <g id="Group_113387-2" data-name="Group 113387" transform="translate(206 -37)">
          <g id="Rectangle_57465-2" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="26" height="26" stroke="none"/>
            <rect x="0.5" y="0.5" width="25" height="25" fill="none"/>
          </g>
          <path id="icons8-checked-radio-button" d="M9.682,11a7.682,7.682,0,1,0,7.682,7.682A7.7,7.7,0,0,0,9.682,11Zm0,1.773a5.909,5.909,0,1,1-5.909,5.909A5.9,5.9,0,0,1,9.682,12.773Zm2.937,2.946a.886.886,0,0,0-.609.268L8.5,19.5,7.058,18.055A.886.886,0,1,0,5.8,19.309l2.068,2.068a.886.886,0,0,0,1.253,0l4.136-4.136a.886.886,0,0,0-.644-1.521Zm7.4,2.076a.886.886,0,1,0,0,1.773h7.091a.886.886,0,1,0,0-1.773Z" transform="translate(539 412.909)" fill="#78b1fe"/>
        </g>
        <rect id="Rectangle_57467" data-name="Rectangle 57467" width="66" height="8" rx="4" transform="translate(727 424)" fill="#e4e8ee"/>
      </g>
    </g>
    <g id="Group_113395" data-name="Group 113395" transform="translate(0 -81)" opacity="0.75">
      <g id="Group_113393" data-name="Group 113393" transform="translate(185)">
        <g transform="matrix(1, 0, 0, 1, 525, 363)" filter="url(#Rectangle_57461-3)">
          <rect id="Rectangle_57461-9" data-name="Rectangle 57461" width="144" height="81" rx="4" transform="translate(9 95)" fill="#fff"/>
        </g>
        <g id="Group_113387-3" data-name="Group 113387" transform="translate(52 52)">
          <g id="Rectangle_57465-3" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="26" height="26" stroke="none"/>
            <rect x="0.5" y="0.5" width="25" height="25" fill="none"/>
          </g>
          <path id="icons8-checkbox" d="M5.25,12A3.264,3.264,0,0,0,2,15.25v7.682a3.264,3.264,0,0,0,3.25,3.25h7.682a3.264,3.264,0,0,0,3.25-3.25V15.25A3.264,3.264,0,0,0,12.932,12Zm0,1.773h7.682a1.464,1.464,0,0,1,1.477,1.477v7.682a1.464,1.464,0,0,1-1.477,1.477H5.25a1.464,1.464,0,0,1-1.477-1.477V15.25A1.464,1.464,0,0,1,5.25,13.773Zm6.778,2.356a.886.886,0,0,0-.609.268l-3.51,3.51L6.468,18.464a.886.886,0,1,0-1.254,1.253l2.068,2.068a.886.886,0,0,0,1.253,0l4.136-4.136a.886.886,0,0,0-.644-1.521Zm7.4,2.076a.886.886,0,1,0,0,1.773h7.682a.886.886,0,1,0,0-1.773Z" transform="translate(539 411.454)" fill="#78b1fe"/>
        </g>
        <rect id="Rectangle_57468" data-name="Rectangle 57468" width="66" height="8" rx="4" transform="translate(573 514)" fill="#e4e8ee"/>
      </g>
      <g id="Group_113390" data-name="Group 113390" transform="translate(185)">
        <g transform="matrix(1, 0, 0, 1, 525, 363)" filter="url(#Rectangle_57461-4)">
          <rect id="Rectangle_57461-10" data-name="Rectangle 57461" width="144" height="81" rx="4" transform="translate(163 95)" fill="#fff"/>
        </g>
        <g id="Group_113387-4" data-name="Group 113387" transform="translate(206 52)">
          <g id="Rectangle_57465-4" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="26" height="26" stroke="none"/>
            <rect x="0.5" y="0.5" width="25" height="25" fill="none"/>
          </g>
          <path id="icons8-star_2_" data-name="icons8-star (2)" d="M15.506,5a.863.863,0,0,0-.777.477L11.766,11.4,4.731,12.485a.863.863,0,0,0-.478,1.462l4.866,4.866L8.035,25.857a.863.863,0,0,0,1.251.9L15.5,23.51l6.214,3.242a.863.863,0,0,0,1.251-.9l-1.084-7.043,4.866-4.866a.863.863,0,0,0-.478-1.462L19.235,11.4,16.272,5.477A.863.863,0,0,0,15.506,5ZM15.5,7.791l2.391,4.782a.863.863,0,0,0,.64.467l5.793.892L20.353,17.9a.863.863,0,0,0-.243.741L21,24.435l-5.1-2.662a.863.863,0,0,0-.8,0L10,24.435l.891-5.791a.863.863,0,0,0-.243-.741L6.676,13.932l5.793-.892a.863.863,0,0,0,.64-.467Z" transform="translate(539 414.6)" fill="#78b1fe"/>
        </g>
        <rect id="Rectangle_57469" data-name="Rectangle 57469" width="66" height="8" rx="4" transform="translate(727 514)" fill="#e4e8ee"/>
      </g>
    </g>
    <g id="Group_113396" data-name="Group 113396" transform="translate(0 -81)" opacity="0.4">
      <g id="Group_113392" data-name="Group 113392" transform="translate(185)">
        <g transform="matrix(1, 0, 0, 1, 525, 363)" filter="url(#Rectangle_57461-5)">
          <rect id="Rectangle_57461-11" data-name="Rectangle 57461" width="144" height="81" rx="4" transform="translate(9 184)" fill="#fff"/>
        </g>
        <g id="Group_113387-5" data-name="Group 113387" transform="translate(52 141)">
          <g id="Rectangle_57465-5" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="26" height="26" stroke="none"/>
            <rect x="0.5" y="0.5" width="25" height="25" fill="none"/>
          </g>
          <path id="icons8-dropdown-field" d="M16.987,10.978a.857.857,0,0,0-.124.012H6.143A3.156,3.156,0,0,0,3,14.134v8.571a3.156,3.156,0,0,0,3.143,3.143H16.859a.857.857,0,0,0,.278,0h6.72A3.156,3.156,0,0,0,27,22.705V14.134a3.156,3.156,0,0,0-3.143-3.143H17.141A.857.857,0,0,0,16.987,10.978ZM6.143,12.705h10V24.134h-10a1.416,1.416,0,0,1-1.429-1.429V14.134A1.416,1.416,0,0,1,6.143,12.705Zm11.714,0h6a1.416,1.416,0,0,1,1.429,1.429v8.571a1.416,1.416,0,0,1-1.429,1.429h-6Zm5.421,3.988a.857.857,0,0,0-.6.263l-1.108,1.108-1.108-1.108a.857.857,0,1,0-1.212,1.212l1.714,1.714a.857.857,0,0,0,1.212,0l1.714-1.714a.857.857,0,0,0-.614-1.475Z" transform="translate(539 413.021)" fill="#78b1fe"/>
        </g>
        <rect id="Rectangle_57472" data-name="Rectangle 57472" width="66" height="8" rx="4" transform="translate(573 603)" fill="#e4e8ee"/>
      </g>
      <g id="Group_113391" data-name="Group 113391" transform="translate(185)">
        <g transform="matrix(1, 0, 0, 1, 525, 363)" filter="url(#Rectangle_57461-6)">
          <rect id="Rectangle_57461-12" data-name="Rectangle 57461" width="144" height="81" rx="4" transform="translate(163 184)" fill="#fff"/>
        </g>
        <g id="Group_113387-6" data-name="Group 113387" transform="translate(206 141)">
          <g id="Rectangle_57465-6" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="26" height="26" stroke="none"/>
            <rect x="0.5" y="0.5" width="25" height="25" fill="none"/>
          </g>
          <path id="icons8-calendar_14_" data-name="icons8-calendar (14)" d="M10.153,6A4.168,4.168,0,0,0,6,10.153V24.847A4.168,4.168,0,0,0,10.153,29H24.847A4.168,4.168,0,0,0,29,24.847V10.153A4.168,4.168,0,0,0,24.847,6Zm0,1.917H24.847a2.222,2.222,0,0,1,2.236,2.236v.958H7.917v-.958A2.222,2.222,0,0,1,10.153,7.917ZM7.917,13.028H27.083V24.847a2.222,2.222,0,0,1-2.236,2.236H10.153a2.222,2.222,0,0,1-2.236-2.236Zm4.153,2.556a1.6,1.6,0,1,0,1.6,1.6,1.6,1.6,0,0,0-1.6-1.6Zm5.431,0a1.6,1.6,0,1,0,1.6,1.6A1.6,1.6,0,0,0,17.5,15.583Zm5.431,0a1.6,1.6,0,1,0,1.6,1.6A1.6,1.6,0,0,0,22.931,15.583Zm-10.861,5.75a1.6,1.6,0,1,0,1.6,1.6A1.6,1.6,0,0,0,12.069,21.333Zm5.431,0a1.6,1.6,0,1,0,1.6,1.6A1.6,1.6,0,0,0,17.5,21.333Z" transform="translate(537 413)" fill="#78b1fe"/>
        </g>
        <rect id="Rectangle_57471" data-name="Rectangle 57471" width="66" height="8" rx="4" transform="translate(727 603)" fill="#e4e8ee"/>
      </g>
    </g>
  </g>
</svg>

)