export const PublicContact = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
        <defs>
            <linearGradient
                id="a"
                x1={0.168}
                y1={0.098}
                x2={0.715}
                y2={0.974}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#31abec" />
                <stop offset={0.324} stopColor="#2498e1" />
                <stop offset={0.704} stopColor="#1988d8" />
                <stop offset={1} stopColor="#1582d5" />
            </linearGradient>
            <linearGradient
                id="b"
                x1={0.74}
                y1={0.991}
                x2={0.164}
                y2={0.119}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#0968b5" />
                <stop offset={1} stopColor="#0471c7" />
            </linearGradient>
            <linearGradient
                id="c"
                x1={0.5}
                y1={-0.496}
                x2={0.703}
                y2={1.579}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#33bef0" />
                <stop offset={1} stopColor="#0a85d9" />
            </linearGradient>
            <linearGradient
                id="d"
                x1={0.384}
                y1={-0.266}
                x2={1.062}
                y2={1.189}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#61d5ff" />
                <stop offset={1} stopColor="#2ba1f2" />
            </linearGradient>
        </defs>
        <g data-name="Group 102438">
            <path data-name="Rectangle 53878" fill="none" d="M0 0h32v32H0z" />
            <g transform="translate(1 -.286)">
                <path
                    data-name="Path 96878"
                    d="M12.143 33.429h-8A1.143 1.143 0 0 1 3 32.286v-5.143A5.143 5.143 0 0 1 8.143 22a5.143 5.143 0 0 1 5.143 5.143v5.143a1.143 1.143 0 0 1-1.143 1.143Z"
                    transform="translate(0 -6.857)"
                    fill="url(#a)"
                />
                <path
                    data-name="Path 96879"
                    d="M36.143 33.429h-8A1.143 1.143 0 0 1 27 32.286v-5.143A5.143 5.143 0 0 1 32.143 22a5.143 5.143 0 0 1 5.143 5.143v5.143a1.143 1.143 0 0 1-1.143 1.143Z"
                    transform="translate(-10.286 -6.857)"
                    fill="url(#b)"
                />
                <path
                    data-name="Path 96880"
                    d="M12.143 33.429h-8A1.143 1.143 0 0 1 3 32.286v-5.143A5.143 5.143 0 0 1 8.143 22a5.143 5.143 0 0 1 5.143 5.143v5.143a1.143 1.143 0 0 1-1.143 1.143Z"
                    transform="translate(0 -6.857)"
                    fill="url(#c)"
                />
                <circle
                    data-name="Ellipse 129459"
                    cx={2.857}
                    cy={2.857}
                    r={2.857}
                    transform="translate(12.143 6)"
                    fill="url(#d)"
                />
                <circle
                    data-name="Ellipse 129460"
                    cx={2.857}
                    cy={2.857}
                    r={2.857}
                    transform="translate(5.286 8.286)"
                    fill="url(#c)"
                />
                <path
                    data-name="Path 96881"
                    d="M36.143 33.429h-8A1.143 1.143 0 0 1 27 32.286v-5.143A5.143 5.143 0 0 1 32.143 22a5.143 5.143 0 0 1 5.143 5.143v5.143a1.143 1.143 0 0 1-1.143 1.143Z"
                    transform="translate(-10.286 -6.857)"
                    fill="url(#c)"
                />
                <circle
                    data-name="Ellipse 129461"
                    cx={2.857}
                    cy={2.857}
                    r={2.857}
                    transform="translate(19 8.286)"
                    fill="url(#c)"
                />
                <g data-name="Group 102442" opacity={0.05}>
                    <path
                        data-name="Path 96882"
                        d="M13.127 26h-2.7a.572.572 0 0 1-.571-.571v-6.857a5.106 5.106 0 0 1 .812-2.762 5.106 5.106 0 0 0-1.061-.451 6.238 6.238 0 0 0-.893 3.213v6.857a1.7 1.7 0 0 0 .446 1.143h2.983a1.139 1.139 0 0 0 .984-.572Z"
                    />
                    <path
                        data-name="Path 96883"
                        d="M21.285 25.429v-6.857a6.238 6.238 0 0 0-.894-3.214 5.106 5.106 0 0 0-1.061.451 5.106 5.106 0 0 1 .812 2.762v6.857a.572.572 0 0 1-.571.571h-2.7a1.138 1.138 0 0 0 .985.571h2.983a1.7 1.7 0 0 0 .446-1.141Z"
                    />
                </g>
                <g data-name="Group 102443" opacity={0.07}>
                    <path
                        data-name="Path 96884"
                        d="M12.891 26.285h-2.462a.858.858 0 0 1-.857-.857v-6.857a5.39 5.39 0 0 1 .844-2.894 5.068 5.068 0 0 0-.531-.226A5.959 5.959 0 0 0 9 18.571v6.857a1.424 1.424 0 0 0 .58 1.143h2.563a1.134 1.134 0 0 0 .748-.286Z"
                    />
                    <path
                        data-name="Path 96885"
                        d="M21 25.429v-6.858a5.955 5.955 0 0 0-.885-3.119 5.12 5.12 0 0 0-.531.226 5.387 5.387 0 0 1 .844 2.894v6.857a.858.858 0 0 1-.857.857h-2.462a1.134 1.134 0 0 0 .749.286h2.561A1.424 1.424 0 0 0 21 25.429Z"
                    />
                </g>
                <path
                    data-name="Path 96886"
                    d="M24.286 31.714h-9.143A1.143 1.143 0 0 1 14 30.571v-6.857A5.714 5.714 0 0 1 19.714 18a5.714 5.714 0 0 1 5.714 5.714v6.857a1.143 1.143 0 0 1-1.142 1.143Z"
                    transform="translate(-4.714 -5.143)"
                    fill="url(#d)"
                />
            </g>
        </g>
    </svg>
)