import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";

export const DashboardStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 16px 80px",
    backgroundColor: theme.palette.background.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "10px 10px 80px",
    },
  },
  loader: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tabBlock: {
    backgroundColor: "white",
    borderRadius: theme.palette.borderRadius,
    padding: "16px",
    position: "relative",
    [theme.breakpoints.only('xs')]: {
      padding: "12px",
    },
  },
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    height: (props) => (props?.height ? props?.height : "100%"),
    padding: (props) => props?.padding ?? "19px",
    minHeight: (props) => (props?.maxHeight ?? "100%"),
    width: "100%",
    overflow: "auto",
    display: 'flex',
    flexFlow: (props) => props?.flexFlow ?? "column",
  },
  customCard: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    // margin:"16px 0px 0px 16px",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    height: (props) => (props?.height ? props?.height : "100%"),
    padding: (props) => props?.padding ?? "19px",
    minHeight: (props) => (props?.maxHeight ?? "100%"),
    width: "100%",
    overflow: "auto",
    // display: 'flex',
    // justifyContent:"space-between"
    // flexFlow: (props) => props?.flexFlow ?? "row",
  },
  hdrtext: {
    textTransform: "capitialize"
  },
  seperateDot: {
    width: "6px",
    height: "6px",
    backgroundColor: "#CED3DD",
    borderRadius: "50%",
    marginInline: "8px"
  },
  serviceTitle: {
    fontSize:"1rem",
    fontFamily: SemiBold,
    color: theme.typography.color.secondary
  },
  serviceSubTxt: {
    fontSize:"0.75rem",
    fontFamily: Regular,
    color: theme.typography.color.secondary
  },
  paginate: {
    "& .MuiButtonBase-root.MuiPaginationItem-root": {
      borderRadius: theme.palette.borderRadius,
      border: "none",
      color: "#606060",
    },
    "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
      color: "white",
      borderRadius: theme.palette.borderRadius,
      border: "none",
      backgroundColor: "#5078E1",
    },
  },
  serviceHubpagination: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    padding: "16px"
  },
  typo: {
    fontSize:"0.875rem",
    color: "#4E5A6B",
    fontFamily: `${Bold}`,
    fontWeight: "bold",
    margin: 0,
    overflow: "hidden",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    direction: 'ltr'
  },
  select: {
    color: "red",
    "& .MuiSelect-select": {
      paddingBlock: 0,
      fontSize:"0.875rem",
      color: "#4E5A6B",
      fontFamily: `${Bold}`,
      fontWeight: "bold",
      height: 20,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
}));
