import * as React from "react"

const BikeIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40.127}
        height={92.23}
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path
                    data-name="Path 99067"
                    d="m8.441 211.2 6.34-.055 2.078.908 21.059-.456a1.983 1.983 0 0 0 1.94-2.025v-.21a1.939 1.939 0 0 0-1.931-1.9l-21.353-.073-1.782 1.211-6.432.211.081 2.386Z"
                    transform="translate(-8.36 -207.39)"
                    fill="#bdbfc9"
                />
            </clipPath>
            <clipPath id="b">
                <path
                    data-name="Path 99090"
                    d="M362.795 112.392c4.271-1.919 4.038-6.673 4.038-6.673v-.034s.232-4.754-4.038-6.673-9.223-1.264-11.178-.96-6.309 3.446-7.236 4.754a4.241 4.241 0 0 0-.891 2.355l-.013 1.081a4.118 4.118 0 0 0 .873 2.355c.926 1.309 5.272 4.449 7.228 4.754s6.945.96 11.217-.96Z"
                    transform="translate(-343.475 -97.695)"
                    fill="none"
                />
            </clipPath>
            <clipPath id="c">
                <path
                    data-name="Path 99092"
                    d="M362.795 99.009c4.271 1.919 4.038 6.673 4.038 6.673v.034s.232 4.754-4.038 6.673-9.223 1.264-11.178.96-6.309-3.446-7.236-4.754a4.241 4.241 0 0 1-.891-2.355l-.013-1.081a4.118 4.118 0 0 1 .873-2.355c.926-1.309 5.272-4.449 7.228-4.754s6.945-.96 11.217.96Z"
                    transform="translate(-343.475 -97.691)"
                    fill="none"
                />
            </clipPath>
            <clipPath id="d">
                <path
                    data-name="Path 99131"
                    d="M24.822 149.007a38.954 38.954 0 0 0-.048 2.215.4.4 0 0 1-.034.159q.141.007.285.007c1.9 0 3.447-.9 3.447-2.014s-1.544-2.014-3.447-2.014c-.092 0-.182 0-.272.007a13.573 13.573 0 0 1 .069 1.64Z"
                    transform="translate(-24.74 -147.36)"
                    fill="#fae9c8"
                />
            </clipPath>
            <clipPath id="e">
                <path
                    data-name="Path 99135"
                    d="M574.724 135.984c.059-1.436.067-2.873.067-4.31a1.092 1.092 0 0 1 .047-.309 6.68 6.68 0 0 0-.4-.014c-2.692 0-4.875 1.754-4.875 3.919s2.183 3.919 4.875 3.919c.13 0 .258 0 .384-.013-.049-1.063-.141-2.125-.1-3.191Z"
                    transform="translate(-569.56 -131.35)"
                    fill="#d6d8e6"
                />
            </clipPath>
        </defs>
        <g data-name="19187163 [Converted]">
            <g data-name="\xD1\xEB\xEE\xE9 1">
                <g data-name="Group 105365" transform="matrix(0 -1 1 0 0 92.23)">
                    <g data-name="Group 105364">
                        <g data-name="Group 105363">
                            <path
                                data-name="Path 99064"
                                d="m50.319 27.309.16-.01a8.924 8.924 0 0 0 6.982-4.2 55.27 55.27 0 0 0 2.213-3.9 7.704 7.704 0 0 1-.577.087 23.775 23.775 0 0 1-2.315 3.043 1.844 1.844 0 0 1-1.753.553 6.791 6.791 0 0 1-4.811 2.343l-19.909.955a1.022 1.022 0 0 0-.722.329 1.059 1.059 0 0 0-.279.767 1.048 1.048 0 0 0 1.1 1l19.915-.955Z"
                                fill="#a9a9ba"
                            />
                            <g data-name="Group 105333">
                                <path
                                    data-name="Path 99065"
                                    d="m.465 28.781 6.34-.055 2.078.908 21.059-.456a1.983 1.983 0 0 0 1.94-2.025v-.21a1.939 1.939 0 0 0-1.931-1.9L8.598 24.97l-1.782 1.211-6.432.211.081 2.386Z"
                                    fill="#bdbfc9"
                                />
                                <g
                                    data-name="Group 105332"
                                    clipPath="url(#a)"
                                    transform="translate(.384 24.971)"
                                >
                                    <path
                                        data-name="Path 99066"
                                        d="m-.617 3.336 7.265-.067 2.246.836 21.262-.635a.481.481 0 0 0 .465-.436.8.8 0 0 0-.814-.877l-21.014.419-2.181-.318-7.619.144.39.934Z"
                                        fill="#e1e2e8"
                                    />
                                </g>
                            </g>
                            <path
                                data-name="Path 99074"
                                d="M9.33 16.299s-6.838.346-7.88.518S0 19.229 0 19.229v1.151s.406 2.244 1.448 2.417 7.88.518 7.88.518Z"
                                fill="#e6e6e6"
                            />
                            <path
                                data-name="Path 99075"
                                d="M14.966 12.762s-9.512 1.381-9.512 6.56v.921c0 5.179 9.512 6.56 9.512 6.56Z"
                                fill="#e6e6e6"
                            />
                            <path
                                data-name="Path 99076"
                                d="M17.011 12.762s-9.632 1.381-9.632 6.56v.921c0 5.179 9.632 6.56 9.632 6.56Z"
                                fill="#fcfcfc"
                            />
                            <path
                                data-name="Path 99077"
                                d="m51.341 28.981-6.21-.956a3.144 3.144 0 0 1-2.066-1.268L41.91 25.15l9.522 3.482-.089.348Zm-8.454-3.088.471.655a2.78 2.78 0 0 0 1.828 1.121l4.414.679-6.711-2.455Z"
                                fill="#3a3a40"
                            />
                            <path
                                data-name="Path 99078"
                                d="m37.158 25.075 1.01.926a.7.7 0 0 0 .495.181l4.107-.179a.872.872 0 0 0 .6-.282l1.167-1.274-7.384.629Z"
                                fill="#808396"
                            />
                            <path
                                data-name="Path 99079"
                                d="m35.635 24.183 1.416 1.3a.966.966 0 0 0 .695.253l5.757-.252a1.225 1.225 0 0 0 .848-.395l1.635-1.784-10.349.881Z"
                                fill="#ced2e3"
                            />
                            <path
                                data-name="Path 99080"
                                d="m37.158 14.897 1.01-.926a.7.7 0 0 1 .495-.181l4.107.179a.872.872 0 0 1 .6.282l1.167 1.274-7.384-.629Z"
                                fill="#808396"
                            />
                            <path
                                data-name="Path 99081"
                                d="m35.635 15.785 1.416-1.3a.966.966 0 0 1 .695-.253l5.757.252a1.225 1.225 0 0 1 .848.395l1.635 1.784-10.349-.881Z"
                                fill="#ced2e3"
                            />
                            <path
                                data-name="Path 99082"
                                d="M18.139 27.526a73.719 73.719 0 0 0 15.159-2.07c4.481-1.259 12.6-1.221 12.6-1.221l-.542-9.03s-7.572.147-12.054-1.113a73.858 73.858 0 0 0-15.175-2.07c-2.426.06-5.083-.142-7.247 1.858l-.217.524a14.069 14.069 0 0 0 .057 10.889l.16.377c2.51 2.745 4.837 1.8 7.263 1.858Z"
                                fill="#283242"
                            />
                            <path
                                data-name="Path 99083"
                                d="M11.277 19.78a12.33 12.33 0 0 1 .94-4.733l.064-.154c1.408-1.123 3.115-1.146 5.074-1.172.272 0 .543-.007.812-.014a72.4 72.4 0 0 1 14.676 2.007 48.333 48.333 0 0 0 10.922 1.174l.341 5.679a53.145 53.145 0 0 0-11.263 1.262 72.324 72.324 0 0 1-14.66 2.007c-.6-.014-1.162.022-1.706.057-1.686.11-2.826.184-4.167-1.169l-.041-.1a12.314 12.314 0 0 1-.99-4.851Z"
                                fill="#303c4f"
                            />
                            <path
                                data-name="Rectangle 55540"
                                fill="#374458"
                                d="M27.293 12.11v15.229h-1.854V12.11z"
                            />
                            <path
                                data-name="Path 99084"
                                d="M78.105 15.681v8.458a.417.417 0 0 1-.417.417h-.562a.417.417 0 0 1-.417-.417v-8.458a.417.417 0 0 1 .417-.417h.562a.417.417 0 0 1 .417.417Z"
                                fill="#919191"
                            />
                            <g data-name="Group 105345">
                                <path
                                    data-name="Path 99085"
                                    d="M60.053 26.459c4.271-1.919 4.038-6.673 4.038-6.673v-.034s.232-4.754-4.038-6.673-9.223-1.264-11.178-.96-6.309 3.446-7.236 4.754a4.241 4.241 0 0 0-.891 2.355l-.013 1.081a4.118 4.118 0 0 0 .873 2.355c.926 1.309 5.272 4.449 7.228 4.754s6.945.96 11.217-.96Z"
                                    fill="#fff"
                                    stroke="#71829f"
                                    strokeWidth={0.2}
                                />
                                <path
                                    data-name="Path 99086"
                                    d="M57.101 24.966c-4.165 0-10.171-2.289-10.171-5.111s6.006-5.111 10.171-5.111 5.436 2.289 5.436 5.111-1.272 5.111-5.436 5.111Z"
                                    fill="#fff"
                                />
                                <g
                                    data-name="Group 105343"
                                    transform="translate(40.733 11.762)"
                                    clipPath="url(#b)"
                                >
                                    <path
                                        data-name="Path 99089"
                                        d="M9.874.062c-2.589 1.606-2.926 3.008-5.7 4.306S-.065 6.863-.065 7.922-1.191 4.3-1.191 4.129 2.898-.69 3.009-.69s6.865.752 6.865.752Z"
                                        fill="#e6e6e6"
                                    />
                                </g>
                                <g
                                    data-name="Group 105344"
                                    clipPath="url(#c)"
                                    transform="translate(40.733 11.762)"
                                >
                                    <path
                                        data-name="Path 99091"
                                        d="M9.874 15.954c-2.589-1.606-2.926-3.008-5.7-4.306S-.065 9.153-.065 8.094s-1.126 3.622-1.126 3.793 4.089 4.819 4.2 4.819 6.865-.752 6.865-.752Z"
                                        fill="#e6e6e6"
                                    />
                                </g>
                                <g
                                    data-name="Group 106541"
                                    transform="translate(52.834 17.143)"
                                >
                                    <ellipse
                                        data-name="Ellipse 129715"
                                        cx={2.731}
                                        cy={2.713}
                                        rx={2.731}
                                        ry={2.713}
                                        fill="#e3e3e3"
                                    />
                                    <path
                                        data-name="Path 99087"
                                        d="M.975 2.712A1.756 1.756 0 1 1 2.73 4.455 1.751 1.751 0 0 1 .975 2.712Zm3.4 0A1.645 1.645 0 1 0 2.73 4.345a1.641 1.641 0 0 0 1.645-1.633Z"
                                        fill="#b0b0b0"
                                    />
                                    <ellipse
                                        data-name="Ellipse 129716"
                                        cx={1.453}
                                        cy={1.444}
                                        rx={1.453}
                                        ry={1.444}
                                        transform="translate(1.277 1.269)"
                                        fill="#fafafa"
                                    />
                                    <path
                                        data-name="Path 99088"
                                        d="M.192 2.35a.223.223 0 0 1 .252-.188.221.221 0 0 1 .189.25.221.221 0 0 1-.252.188.221.221 0 0 1-.191-.219.143.143 0 0 1 .002-.031Zm.315 1.127a.223.223 0 0 1 .295.108.221.221 0 0 1-.11.294.224.224 0 0 1-.3-.108.23.23 0 0 1-.02-.092.221.221 0 0 1 .13-.2Zm.285-2.432a.222.222 0 0 1 .314-.022.221.221 0 0 1 .023.312.224.224 0 0 1-.314.022.218.218 0 0 1-.077-.167.218.218 0 0 1 .055-.145Zm.484 3.5a.224.224 0 0 1 .308-.066.222.222 0 0 1 .067.306.223.223 0 0 1-.308.066.22.22 0 0 1-.1-.185.215.215 0 0 1 .035-.119Zm.731-4.28a.223.223 0 0 1 .277.149.22.22 0 0 1-.151.275.225.225 0 0 1-.278-.149.221.221 0 0 1-.008-.061.222.222 0 0 1 .161-.21Zm.722 4.546a.222.222 0 0 1 .223.222.222.222 0 0 1-.223.222.221.221 0 0 1-.223-.222.221.221 0 0 1 .224-.218Zm.446-4.4a.223.223 0 0 1 .277-.149.221.221 0 0 1 .152.275.222.222 0 0 1-.278.149.219.219 0 0 1-.16-.212.224.224 0 0 1 .008-.063Zm.7 4.064a.224.224 0 0 1 .308.066.218.218 0 0 1-.067.306.225.225 0 0 1-.308-.066.216.216 0 0 1-.036-.119.218.218 0 0 1 .1-.185Zm.478-3.459a.224.224 0 0 1 .314.022.218.218 0 0 1-.023.312.225.225 0 0 1-.314-.022.216.216 0 0 1-.054-.144.222.222 0 0 1 .078-.158Zm.3 2.565a.223.223 0 0 1 .3-.11.222.222 0 0 1 .11.294.223.223 0 0 1-.295.11.222.222 0 0 1-.13-.2.208.208 0 0 1 .022-.092Zm.359-1.424a.223.223 0 0 1 .252.188.222.222 0 0 1-.189.25.221.221 0 0 1-.252-.188.13.13 0 0 1 0-.031.223.223 0 0 1 .194-.213Z"
                                        fill="#b0b0b0"
                                    />
                                </g>
                            </g>
                            <path
                                data-name="Path 99093"
                                d="M67.303 22.77h22.435a2.489 2.489 0 0 0 2.489-2.489v-.987a2.489 2.489 0 0 0-2.489-2.489h-22.46a29.652 29.652 0 0 1 .025 5.964Z"
                                fill="#283242"
                            />
                            <path
                                data-name="Path 99094"
                                d="M65.858 22.768v-5.92a.986.986 0 0 1 1.081-.91l16.775.637a.973.973 0 0 1 .991.91v4.642a.974.974 0 0 1-.991.91l-16.775.637a.986.986 0 0 1-1.081-.906Z"
                                fill="#fcfcfc"
                                stroke="#3a3a40"
                                strokeWidth={0.2}
                            />
                            <rect
                                data-name="Rectangle 55541"
                                width={1.946}
                                height={13.194}
                                rx={0.973}
                                transform="rotate(91.66 32.496 45.196)"
                                fill="#d1d1d1"
                            />
                            <path
                                data-name="Path 99095"
                                d="M70.215 20.273v-.928a2.47 2.47 0 0 1 2.574-2.468l6.617.276a2.471 2.471 0 0 1 2.368 2.468v.377a2.47 2.47 0 0 1-2.368 2.468l-6.617.276a2.471 2.471 0 0 1-2.574-2.469Z"
                                fill="#fcfcfc"
                            />
                            <g data-name="Group 105346">
                                <path
                                    data-name="Path 99096"
                                    d="M68.325 31.969a17.708 17.708 0 0 0-1.232 5.823h.03a.4.4 0 1 1-.365.23 16.375 16.375 0 0 1 .193-5.247l-1.353-.883.707-.883 2.02.963Z"
                                    fill="#bcbecc"
                                />
                                <path
                                    data-name="Path 99097"
                                    d="m66.055 28.015-1.192 2.51 1.373.643 1.192-2.51Z"
                                    fill="#636473"
                                />
                                <path
                                    data-name="Path 99098"
                                    d="m64.056 32.259-1.881 3.959 1.373.643 1.881-3.959Z"
                                    fill="#2c2c33"
                                />
                                <path
                                    data-name="Path 99099"
                                    d="m64.919 30.059-.483 1.015 1.611.755.483-1.015Z"
                                    fill="#d6d8e6"
                                />
                                <path
                                    data-name="Path 99100"
                                    d="m63.841 31.182-.23.484a.6.6 0 0 0 .289.8l1.4.654a.607.607 0 0 0 .806-.287l.23-.484a.6.6 0 0 0-.289-.8l-1.4-.655a.607.607 0 0 0-.806.287Z"
                                    fill="#40404a"
                                />
                                <path
                                    data-name="Path 99101"
                                    d="m66.522 25.078 1.318 1.294-1.107 1.2-2.046-1.866Z"
                                    fill="#a3a5b3"
                                />
                                <path
                                    data-name="Path 99102"
                                    d="m66.644 26.497-1.085 2.28 1.609.754 1.085-2.284a.725.725 0 0 0-.35-.968l-.284-.134a.736.736 0 0 0-.975.348Z"
                                    fill="#d6d8e6"
                                />
                                <path
                                    data-name="Path 99103"
                                    d="m62.17 37.565.33.158a.668.668 0 0 0 .889-.308l.152-.312a.659.659 0 0 0-.309-.883l-.33-.158a.668.668 0 0 0-.889.308l-.152.312a.659.659 0 0 0 .309.883Z"
                                    fill="#a5a6bf"
                                />
                            </g>
                            <path
                                data-name="Path 99106"
                                d="M68.324 8.339a17.708 17.708 0 0 1-1.232-5.823h.03a.4.4 0 1 0-.365-.23 16.375 16.375 0 0 0 .193 5.247l-1.353.883.707.883 2.02-.963Z"
                                fill="#bcbecc"
                            />
                            <path
                                data-name="Path 99107"
                                d="m66.055 12.297-1.192-2.51 1.373-.643 1.192 2.51Z"
                                fill="#636473"
                            />
                            <path
                                data-name="Path 99108"
                                d="m64.055 8.053-1.881-3.958 1.373-.643 1.881 3.958Z"
                                fill="#2c2c33"
                            />
                            <path
                                data-name="Path 99109"
                                d="m64.919 10.253-.483-1.021 1.611-.755.483 1.016Z"
                                fill="#d6d8e6"
                            />
                            <path
                                data-name="Path 99110"
                                d="m63.841 9.13-.23-.484a.6.6 0 0 1 .289-.8l1.4-.655a.607.607 0 0 1 .806.287l.23.484a.6.6 0 0 1-.289.8l-1.4.655a.607.607 0 0 1-.806-.287Z"
                                fill="#40404a"
                            />
                            <g data-name="Group 105347" transform="translate(64.687 12.736)">
                                <path
                                    data-name="Path 99111"
                                    d="M8.036.912 1.994.774a1.025 1.025 0 0 0-1.052.993l-.008.306a1.018 1.018 0 0 0 .995 1.022l6.109.14V.911Z"
                                    fill="#575757"
                                />
                                <rect
                                    data-name="Rectangle 55542"
                                    width={1.946}
                                    height={13.194}
                                    rx={0.973}
                                    transform="rotate(-91.66 6.8 6.263)"
                                    fill="#d1d1d1"
                                />
                                <path
                                    data-name="Path 99112"
                                    d="m8.036 13.236-6.042.138a1.025 1.025 0 0 1-1.052-.993l-.008-.306a1.018 1.018 0 0 1 .995-1.022l6.109-.14v2.324Z"
                                    fill="#575757"
                                />
                                <path
                                    data-name="Path 99113"
                                    d="m1.834 2.498 1.318-1.294-1.107-1.2L-.001 1.87Z"
                                    fill="#a3a5b3"
                                />
                            </g>
                            <path
                                data-name="Path 99114"
                                d="m65.488 26.104.513-.126a.832.832 0 0 0 .619-.82V14.603a.87.87 0 0 0-.619-.851l-.524-.218c-.082-.024-.157-.1-.242-.1h-.207a1.034 1.034 0 0 0-.92.692l-1.341 4.271v2.96l1.341 4.149a.924.924 0 0 0 .92.571h.207c.086.001.171.051.253.027Z"
                                fill="#8a8a8a"
                            />
                            <g data-name="Group 105348" transform="translate(64.161 13.297)">
                                <ellipse
                                    data-name="Ellipse 129717"
                                    cx={1.334}
                                    cy={1.324}
                                    rx={1.334}
                                    ry={1.324}
                                    fill="#d1d1d1"
                                />
                                <path
                                    data-name="Path 99115"
                                    d="m1.971 2.063-.979.181-.647-.751.332-.932.979-.181.647.751Z"
                                    fill="#e6e6e6"
                                />
                            </g>
                            <g data-name="Group 105349" transform="translate(64.161 23.712)">
                                <ellipse
                                    data-name="Ellipse 129718"
                                    cx={1.334}
                                    cy={1.324}
                                    rx={1.334}
                                    ry={1.324}
                                    fill="#d1d1d1"
                                />
                                <path
                                    data-name="Path 99116"
                                    d="m1.971 2.063-.979.181-.647-.751.332-.932.979-.181.647.751Z"
                                    fill="#e6e6e6"
                                />
                            </g>
                            <path
                                data-name="Path 99117"
                                d="m66.644 13.814-1.085-2.284 1.609-.754 1.085 2.284a.725.725 0 0 1-.35.968l-.284.134a.736.736 0 0 1-.975-.348Z"
                                fill="#d6d8e6"
                            />
                            <path
                                data-name="Path 99118"
                                d="m62.169 2.747.33-.158a.668.668 0 0 1 .889.308l.152.312a.659.659 0 0 1-.309.883l-.33.158a.668.668 0 0 1-.889-.308l-.152-.312a.659.659 0 0 1 .309-.883Z"
                                fill="#a5a6bf"
                            />
                            <g data-name="Group 105350" fill="#2c2c33">
                                <path
                                    data-name="Path 99121"
                                    d="m66.512 9.704 1.782-1.159a1.5 1.5 0 0 0 .66-1.093l.383-2.9.361.047-.383 2.9a1.86 1.86 0 0 1-.82 1.352l-1.783 1.153Z"
                                />
                                <path
                                    data-name="Path 99122"
                                    d="M68.748 5.283 69.362 0a1.025 1.025 0 0 1 .905 1.134l-.378 3.25a1.029 1.029 0 0 1-1.141.9Z"
                                />
                                <path
                                    data-name="Path 99123"
                                    d="m66.686 10.226-.364-.555L67.359 9l.364.555Z"
                                />
                            </g>
                            <g data-name="Group 105351" fill="#2c2c33">
                                <path
                                    data-name="Path 99124"
                                    d="m66.512 30.426.2-.3 1.782 1.159a1.865 1.865 0 0 1 .822 1.358l.382 2.893-.361.047-.383-2.9a1.5 1.5 0 0 0-.662-1.1Z"
                                />
                                <path
                                    data-name="Path 99125"
                                    d="m68.748 34.844.614 5.283a1.025 1.025 0 0 0 .905-1.134l-.378-3.25a1.029 1.029 0 0 0-1.141-.9Z"
                                />
                                <path
                                    data-name="Path 99126"
                                    d="m66.686 29.9-.364.555 1.037.671.363-.556Z"
                                />
                            </g>
                            <g data-name="Group 105352" transform="translate(61.312 18.385)">
                                <ellipse
                                    data-name="Ellipse 129719"
                                    cx={1.576}
                                    cy={1.565}
                                    rx={1.576}
                                    ry={1.565}
                                    fill="#d1d1d1"
                                />
                                <path
                                    data-name="Path 99127"
                                    d="m2.329 2.439-1.157.213-.765-.889L.8.663 1.957.45l.765.889Z"
                                    fill="#e6e6e6"
                                />
                            </g>
                            <g data-name="Group 105355">
                                <path
                                    data-name="Path 99128"
                                    d="M2.053 21.681c-.417 0-.754-.863-.754-1.928s.337-1.928.754-1.928.754.863.754 1.928-.338 1.928-.754 1.928Z"
                                    fill="#f21c00"
                                />
                                <g data-name="Group 105354">
                                    <path
                                        data-name="Path 99129"
                                        d="M2.438 19.39a38.954 38.954 0 0 0-.048 2.215.4.4 0 0 1-.034.159q.141.007.285.007c1.9 0 3.447-.9 3.447-2.014s-1.544-2.014-3.447-2.014c-.092 0-.182 0-.272.007a13.573 13.573 0 0 1 .069 1.64Z"
                                        fill="#fcfcfc"
                                    />
                                    <g
                                        data-name="Group 105353"
                                        clipPath="url(#d)"
                                        transform="translate(2.356 17.743)"
                                    >
                                        <path
                                            data-name="Path 99130"
                                            d="M.086 3.142a9.63 9.63 0 0 0 3.878-.694c1.507-.769.093-.295.093-.295a21.42 21.42 0 0 1-3.924-.029C-.898 1.886.087 3.14.087 3.14Z"
                                            fill="#fcfcfc"
                                        />
                                    </g>
                                </g>
                                <path
                                    data-name="Rectangle 55543"
                                    fill="#283242"
                                    d="M1.919 21.795V17.71h.738v4.085z"
                                />
                            </g>
                            <path
                                data-name="Path 99132"
                                d="m78.021 24.776-10.671.342a.3.3 0 0 1-.311-.285.3.3 0 0 1 .291-.317l10.671-.342a.3.3 0 0 1 .311.3.3.3 0 0 1-.291.302Z"
                                fill="#6e6e6e"
                            />
                            <g data-name="Group 105362">
                                <g data-name="Group 105358">
                                    <g data-name="Group 105357">
                                        <path
                                            data-name="Path 99133"
                                            d="M73.119 20.449c.059-1.436.067-2.873.067-4.31a1.092 1.092 0 0 1 .047-.309 6.68 6.68 0 0 0-.4-.014c-2.692 0-4.875 1.754-4.875 3.919s2.183 3.919 4.875 3.919c.13 0 .258 0 .384-.013-.049-1.063-.141-2.125-.1-3.191Z"
                                            fill="#d6d8e6"
                                        />
                                        <g
                                            data-name="Group 105356"
                                            clipPath="url(#e)"
                                            transform="translate(67.955 15.815)"
                                        >
                                            <path
                                                data-name="Path 99134"
                                                d="M5.16 1.73S1.808 1.582-.326 3.08s-.13.573-.13.573 4.093-.407 5.551.055.065-1.978.065-1.978Z"
                                                fill="#e4e6f5"
                                            />
                                        </g>
                                    </g>
                                    <path
                                        data-name="Rectangle 55544"
                                        fill="#283242"
                                        d="M73.856 15.769v7.949h-1.045v-7.949z"
                                    />
                                </g>
                                <path
                                    data-name="Path 99136"
                                    d="m71.275 24.144.072-.1a2.244 2.244 0 0 0 .33-1.186v-5.885a2.351 2.351 0 0 0-.33-1.221l-.077-.163a2.42 2.42 0 0 0-2.055-1.187h-.454a2.661 2.661 0 0 0-1.267.349l-1.475.843v8.645l1.475.747a2.582 2.582 0 0 0 1.267.253h.454a2.34 2.34 0 0 0 2.06-1.091Z"
                                    fill="#a3a6b5"
                                />
                                <g data-name="Group 105361">
                                    <g
                                        data-name="Group 105359"
                                        transform="translate(66.396 15.118)"
                                    >
                                        <ellipse
                                            data-name="Ellipse 129720"
                                            cx={2.314}
                                            cy={2.299}
                                            rx={2.314}
                                            ry={2.299}
                                            fill="#dddeeb"
                                        />
                                        <ellipse
                                            data-name="Ellipse 129721"
                                            cx={2.071}
                                            cy={2.057}
                                            rx={2.071}
                                            ry={2.057}
                                            transform="translate(.243 .242)"
                                            fill="#343442"
                                        />
                                        <path
                                            data-name="Path 99137"
                                            d="M2.065 2.368a.241.241 0 0 1-.089.259l.03-.006a.228.228 0 0 0 .143-.118l1.931-.7-2.014.4a.228.228 0 0 0-.184-.028l-.029.01a.246.246 0 0 1 .212.183Z"
                                            fill="#e2e2ff"
                                        />
                                        <path
                                            data-name="Rectangle 55545"
                                            fill="#a7bdb4"
                                            d="M1.698 1.586v1.687h-.336V1.586z"
                                        />
                                        <path
                                            data-name="Path 99138"
                                            d="m1.192 3.837.057-.09.046.026.046.033-.059.094-.046-.033.033-.045-.033.045-.043-.031ZM1.43.614l.1-.051.051.1-.1.049-.052-.1Zm.265 3.485.032-.106.107.033-.031.107-.109-.034ZM1.971.43l.113-.017.016.11-.111.016-.018-.11Zm.285 3.769v-.111h.119v.111ZM2.581.53l.016-.11.055.008-.016.11Zm.006-.055Zm.209 3.548.107-.033.035.106-.11.034-.033-.106Zm.3-3.336.047-.1.049.024-.047.1-.051-.023Zm.022-.052Zm.171 3.168.093-.059.063.088-.1.065-.06-.094ZM3.5.947l.073-.081.084.079-.079.078Zm.193 2.5.037-.041.033-.045.088.069-.039.052-.037.041-.083-.075Zm.157-2.1.1-.055.057.1-.1.053-.053-.1Zm.125 1.646.043-.1.1.039-.043.106-.1-.043Zm.087-1.156.108-.026.025.111-.11.023-.023-.108Zm.048.642.008-.111.112.006-.008.113Z"
                                            fill="#e2e2ff"
                                        />
                                    </g>
                                    <g
                                        data-name="Group 105360"
                                        transform="translate(66.396 20.122)"
                                    >
                                        <ellipse
                                            data-name="Ellipse 129722"
                                            cx={2.314}
                                            cy={2.299}
                                            rx={2.314}
                                            ry={2.299}
                                            fill="#dddeeb"
                                        />
                                        <ellipse
                                            data-name="Ellipse 129723"
                                            cx={2.071}
                                            cy={2.057}
                                            rx={2.071}
                                            ry={2.057}
                                            transform="translate(.243 .242)"
                                            fill="#343442"
                                        />
                                        <path
                                            data-name="Path 99139"
                                            d="M2.065 2.368a.241.241 0 0 1-.089.259l.03-.006a.228.228 0 0 0 .143-.118l1.931-.7-2.014.4a.228.228 0 0 0-.184-.028l-.029.01a.246.246 0 0 1 .212.183Z"
                                            fill="#e2e2ff"
                                        />
                                        <path
                                            data-name="Path 99140"
                                            d="m1.394 3.933.054-.1a1.788 1.788 0 0 0 .865.224 1.77 1.77 0 0 0 .55-.087l.035.106a1.908 1.908 0 0 1-1.505-.146Z"
                                            fill="red"
                                        />
                                        <path
                                            data-name="Path 99141"
                                            d="M2.864 3.973a1.771 1.771 0 0 0 .972-.771l.1.057a1.889 1.889 0 0 1-1.033.82Z"
                                            fill="#ffa557"
                                        />
                                        <path
                                            data-name="Path 99142"
                                            d="M1.361.683a1.9 1.9 0 0 1 .954-.256A1.871 1.871 0 0 1 3.933 3.26l-.1-.057a1.751 1.751 0 0 0 .25-.9A1.775 1.775 0 0 0 1.415.783l-.057-.1Z"
                                            fill="#e2e2ff"
                                        />
                                    </g>
                                </g>
                                <path
                                    data-name="Path 99143"
                                    d="m66.018 15.591-.361.14v.5l-.843.334v6.7l.843.334v.5l.361.125Z"
                                    fill="#595959"
                                />
                            </g>
                            <path
                                data-name="Path 99148"
                                d="m78.021 14.443-10.671-.342a.3.3 0 0 0-.311.285.3.3 0 0 0 .291.317l10.671.342a.3.3 0 0 0 .311-.3.3.3 0 0 0-.291-.302Z"
                                fill="#6e6e6e"
                            />
                        </g>
                    </g>
                    <rect
                        data-name="Rectangle 55546"
                        width={3.566}
                        height={2.017}
                        rx={1.008}
                        transform="translate(50.903 27.884)"
                        fill="#3a3a40"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default BikeIcon
