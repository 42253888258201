import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../../utils";

export const AgreementDetailCardStyle = makeStyles((theme) => ({
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold,
    },
    header: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: SemiBold
    },
    subheader: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Regular
    },
    chip:{
        backgroundColor:"#F2F4F7",
        padding:"3px 6px",
        borderRadius:"4px",
        color:"#1C1C1C",
        fontFamily:Bold,
        fontSize:"0.75rem",
        // position:"absolute",
        top:12,
        right:12

    }
}))

export const DetailCardStyle = makeStyles((theme) => ({
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold,
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        direction: "ltr"
    },
    contentNo: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        direction: "ltr"
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: "50%",
        background: theme.palette.background.primary,
    },
    avatar: {
        height: 40,
        width: 40,
        borderRadius: 4
    },
    box: {
        background: "#fff",
        width: "100%",
        borderRadius: "4px",
        padding: "12px",
        boxShadow: "0px 0px 16px #00000014",
        height:"100%"
    },
    truncate: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // whiteSpace: 'nowrap',
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        direction: "ltr"
      },
      statusText:{
        color:"#091B29",
        fontFamily:Bold,
        fontSize:"0.875rem"
      },
      sendRequest:{
        backgroundColor:"#5078E1",
        borderRadius:"4px",
        color:"#fff",
        fontFamily:Bold,
        fontSize:"0.875rem",
        padding:"11px 16px",
        '&:hover':{
            backgroundColor:"#5078E1",
            borderRadius:"4px",
            color:"#fff",
            fontFamily:Bold, 
        }
      },
      resendRequest:{
        backgroundColor:"#fff",
        border:"1px solid #CED3DD",
        borderRadius:"4px",
        color:"#091B29",
        fontFamily:Bold,
        fontSize:"0.875rem",
        padding:"11px 16px",
        '&:hover':{
            backgroundColor:"#fff",
            border:"1px solid #CED3DD",
            borderRadius:"4px",
            color:"#091B29",
            fontFamily:Bold, 
        },
        '& p':{
            color:"#091B29",
            fontFamily:Bold,
            fontSize:"0.875rem",
        }
      }
}))

export const SettlementAmountCardStyle = makeStyles((theme , props) => ({
    amount: {
        fontSize:"1rem",
        color: "#fff",
        fontFamily: Bold,
        marginInlineStart: "8px",
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold,
        paddingBottom: 6,
        
    },
    box: {
        borderRadius: 4,
        border: (props)=>`1px solid ${props?.backgroundColor ?? "#5AC782"}`,
        padding: 12,
        backgroundColor:(props)=>props?.backgroundColor ?? "#5AC782",
        color:"#fff"
    }
}))

export const CollapesStyle = makeStyles((theme) => ({
    title: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
        marginInline: "8px",
    },
    box: {
        background: "#fff",
        borderRadius: 4,
        boxShadow: "0px 0px 16px #00000014",
    },
    iconBtn: {
        height: 24,
        width: 24,
        background: theme.palette.background.tab_background_1,
        borderRadius: 4
    },
    tab:{
        border:"1px solid E4E8EE",
        display:"flex",
        alignItems:"center",
        gap:"16px",
        overflowX:"scroll",
        width:"100%",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    tabTitle:{
        padding:"20px 20px 0px",
        cursor:"pointer",
      '& p':{
        color:"#4E5A6B",
        fontFamily:SemiBold,
        fontSize:"14px",
        paddingBottom:"20px",
        position:"relative",
      }
    },
    tabTitle1:{
        padding:"20px 20px 0px",
        cursor:"pointer",
      '& p':{
        color:"#5078E1",
        fontFamily:SemiBold,
        fontSize:"14px",
        paddingBottom:"20px",
        position:"relative",
      }
    },
    absolute:{
        position:"absolute",
        width:"100%",
        borderRadius:"4px 4px 0px 0px",
        bottom:"0",
        left:"0",
        right:"0",
        background:"#5078E1",
        height:"3px"
    }

}))

export const CollapesInfoCardStyle = makeStyles((theme) => ({
    title: {
        fontSize:"1rem",
        fontFamily: Bold,
        color: theme.typography.color.primary,
        direction: "ltr",
        textAlign: "-webkit-match-parent"
    },
    content: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.primary
    },
    box: {
        padding: 10,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        width: 230,
        height: 60,
        marginInlineStart: "16px"

    }
}))