import React from "react";
import { withNavBars } from "../../HOCs";
import  ServiceHubDashboard  from "./serviceHubDashboard";
class ServiceHubDashboardParent extends React.Component {
    render() {
        return (
            <ServiceHubDashboard />
        );
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ServiceHubDashboardParent, props);