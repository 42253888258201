export const AccountStatement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    x={0}
    y={0}
    viewBox="0 0 30 30"
    style={{
      enableBackground: "new 0 0 30 30",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st0{fill:#c1c5cb}"}</style>
    <path
      className="st0"
      d="M21.1 15.2V8.4c0-.3-.2-.5-.5-.5h-1.4v-.4c0-.3-.2-.5-.5-.5h-8.4c-.1 0-.2 0-.3.1L7.2 10c-.1 0-.2.2-.2.3v12.3c0 .2.2.4.5.4h11.3c2.4 0 4.2-2 4.2-4.2.1-1.5-.8-2.8-1.9-3.6zm-.9-6.3v5.9c-.3-.1-.6-.2-.9-.2V8.9h.9zM9.8 8.6v1.2H8.6l1.2-1.2zM8 22V10.8h2.3c.3 0 .5-.2.5-.5V8h7.5v6.7c-1.2.2-2.3.8-3 1.8h-6c-.3 0-.5.2-.5.5s.2.5.5.5h5.5c-.1.3-.2.6-.2.9H9.4c-.3 0-.5.2-.5.5s.2.5.5.5h5.3c.1.3.1.7.2.9H9.4c-.3 0-.5.2-.5.5s.2.5.5.5h5.9c.2.4.5.7.9 1H8V22zm10.8 0c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3 3.3 1.5 3.3 3.4c0 1.8-1.5 3.2-3.3 3.2z"
      style={{
        fill: props?.fill ?? "#c1c5cb",
      }}
    />
    <path
      className="st0"
      d="M19.6 18.5c-.4-.3-.9-.4-1-.5 0-.1 0-.1.1-.2s.3-.1.4.1c.2.2.5.2.7 0 .2-.2.2-.5 0-.7-.1-.1-.3-.2-.4-.3 0-.3-.2-.5-.5-.5s-.5.2-.5.5c-.6.3-.9 1-.5 1.5.2.4.6.5.9.7.2.1.5.2.5.4 0 .1-.1.2-.2.3-.2.1-.5 0-.7-.1-.2-.2-.5-.1-.7.2-.2.2-.1.5.2.7.2.1.3.2.5.2 0 .3.2.5.5.5s.5-.2.5-.5v-.1c.7-.4 1-1.6.2-2.2zM10.4 15H16c.3 0 .5-.2.5-.5v-1.9c0-.3-.2-.5-.5-.5h-1.4v-1.4c0-.3-.2-.5-.5-.5h-1.9c-.3 0-.5.2-.5.5v.9h-1.4c-.3 0-.5.2-.5.5v2.3c.1.4.3.6.6.6zm5.1-1.9v.9h-.9v-.9h.9zm-2.8-1.8h.9v2.8h-.9v-2.8zm-1 1.4v1.4h-.9v-1.4h.9z"
      style={{
        fill: props?.fill ?? "#c1c5cb",
      }}
    />
  </svg>
)
