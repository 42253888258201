import { Avatar, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { TextBox } from "../../components";
import { DbTaskStyle } from "./style";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { v4 as uuidv4 } from "uuid";

const initialState = {
  host: "",
  userName: "",
  password: "",
  defaultDb: "",
  clientName: "",
  error: {
    host: "",
    userName: "",
    password: "",
    defaultDb: "",
    clientName: "",
  },
};

export const DbTask = () => {
  const classes = DbTaskStyle();

  const [data, setData] = React.useState({ ...initialState });
  const [tableData, setTableData] = React.useState([]);
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ id: uuidv4(), ...data, [key]: value, error });
  };

  const validate = () => {
    let isValid = true;
    let error = data.error;
    if (data.host.length === 0) {
      isValid = false;
      error.host = "Host Name is required";
    }
    if (data.userName.length === 0) {
      isValid = false;
      error.userName = "User Name is required";
    }
    if (data.password.length === 0) {
      isValid = false;
      error.password = "Password is required";
    }
    if (data.defaultDb.length === 0) {
      isValid = false;
      error.defaultDb = "Default Db is required";
    }
    if (data.clientName.length === 0) {
      isValid = false;
      error.clientName = "Client Name is required";
    }
    if (!isValid) {
      alert("Please fill the fields");
    }
    setData({ ...data, error });
    return isValid;
  };

  const submit = () => {
    if (validate()) {
      setTableData([...tableData, data]);
      setData({ ...initialState });
    }
  };

  const handleEdit = (data) => {
    if (data.id === tableData[0].id) {
      setData({
        ...initialState,
        host: data?.host,
        userName: data?.userName,
        password: data?.password,
        defaultDb: data?.defaultDb,
        clientName: data?.clientName,
      });
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                
              {/* LOGO SECTION */}

              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <div className={classes.cardBoxes}>
                    <Typography className={classes.text}>
                      Client Logo
                    </Typography>
                    <Avatar
                      className={classes.avatar}
                      src="/images/hotels.hpg"
                    />
                    <Typography className={classes.upload}>
                      Upload image
                    </Typography>
                  </div>
                </Grid>

                {/* TEXT BOX SECTION */}

                <Grid item xs={10}>
                  <div className={classes.cardBox}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextBox
                          label="Host"
                          varient="outlined"
                          placeholder="Enter Host"
                          required
                          onChange={(e) => updateState("host", e.target.value)}
                          value={data?.host}
                          disabled
                          error={data?.error?.host?.length > 0}
                          helperText={
                            data?.error?.host ? data?.error?.host : false
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox
                          label="Username"
                          varient="outlined"
                          placeholder="Enter User Name"
                          required
                          onChange={(e) =>
                            updateState("userName", e.target.value)
                          }
                          value={data?.userName}
                          disabled
                          error={data?.error?.userName?.length > 0}
                          helperText={
                            data?.error?.userName
                              ? data?.error?.userName
                              : false
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox
                          type="password"
                          label="Password"
                          varient="outlined"
                          placeholder="Enter Password"
                          required
                          onChange={(e) =>
                            updateState("password", e.target.value)
                          }
                          value={data?.password}
                          disabled
                          error={data?.error?.password?.length > 0}
                          helperText={
                            data?.error?.password
                              ? data?.error?.password
                              : false
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox
                          label="Default DB"
                          varient="outlined"
                          placeholder="Enter Default DB"
                          required
                          onChange={(e) =>
                            updateState("defaultDb", e.target.value)
                          }
                          value={data?.defaultDb}
                          disabled
                          error={data?.error?.defaultDb?.length > 0}
                          helperText={
                            data?.error?.defaultDb
                              ? data?.error?.defaultDb
                              : false
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextBox
                          label="Client Name"
                          varient="outlined"
                          placeholder="Enter Client Name"
                          required
                          onChange={(e) =>
                            updateState("clientName", e.target.value)
                          }
                          value={data?.clientName}
                          disabled
                          error={data?.error?.clientName?.length > 0}
                          helperText={
                            data?.error?.clientName
                              ? data?.error?.clientName
                              : false
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* BUTTON SECTION */}

          <Grid className={classes.align} container>
            <Grid className={classes.buttons} item xs={2}>
              <Button
                className={classes.create}
                onClick={submit}
                variant="contained"
              >
                Create
              </Button>
              {/* <Button className={classes.update} onClick={submit} variant="contained">
                update
              </Button> */}
            </Grid>
          </Grid>

          {/* TABLE SECTION */}

          <Grid className={classes.tableData} container>
            <Grid item lg={8}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell>Host</TableCell>
                      <TableCell align="right">User Name</TableCell>
                      <TableCell align="right">Password</TableCell>
                      <TableCell align="right">Default DB</TableCell>
                      <TableCell align="right">Client Name</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow
                        className={classes.bodyTableRow}
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row?.host}
                        </TableCell>
                        <TableCell align="right">{row?.userName}</TableCell>
                        <TableCell align="right">{row?.password}</TableCell>
                        <TableCell align="right">{row?.defaultDb}</TableCell>
                        <TableCell align="right">{row?.clientName}</TableCell>
                        <TableCell className={classes.lastCell} align="right">
                          <Button
                            className={classes.editBtn}
                            onClick={() => handleEdit(row)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            className={classes.deleteBtn}
                            // onClick={() => handleDelete(row)}
                          >
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
