import * as React from "react"

export const Download = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12.94}
        height={12.948}
        {...props}
    >
        <path
            data-name="icons8-download (2)"
            d="M6.462 0a.539.539 0 0 0-.531.547v7.685L4.695 6.996a.539.539 0 1 0-.762.762L6.09 9.915a.539.539 0 0 0 .762 0l2.157-2.157a.539.539 0 1 0-.762-.762L7.009 8.232V.547A.539.539 0 0 0 6.461 0ZM1.977 3.962A1.985 1.985 0 0 0 0 5.939v5.032a1.985 1.985 0 0 0 1.977 1.977h8.986a1.985 1.985 0 0 0 1.977-1.977V5.939a1.985 1.985 0 0 0-1.977-1.977H8.806a.539.539 0 1 0 0 1.078h2.157a.891.891 0 0 1 .9.9v5.032a.891.891 0 0 1-.9.9H1.977a.891.891 0 0 1-.9-.9V5.939a.891.891 0 0 1 .9-.9h2.157a.539.539 0 1 0 0-1.078Z"
            fill="#091b29"
        />
    </svg>
)


