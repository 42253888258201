import * as React from "react"

export const BuldingIcon = (props) => {
  const width = props?.width ? props?.width : 42;
  const height = props?.width ? props?.width : 40.001;
  const color = props?.width ? props?.width : "#ced3dd";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 42 40.001"
      {...props}>
      <path d="M17.16,4A5,5,0,0,0,12,9V27H8.5A5.516,5.516,0,0,0,3,32.5v10A1.5,1.5,0,0,0,4.5,44h39A1.5,1.5,0,0,0,45,42.5v-17A5.516,5.516,0,0,0,39.5,20H35V12.5a5.013,5.013,0,0,0-3.7-4.828l-13-3.5A5,5,0,0,0,17.16,4Zm-.092,3a1.937,1.937,0,0,1,.451.066l13,3.5A2,2,0,0,1,32,12.5V41H22V32.5A5.516,5.516,0,0,0,16.5,27H15V9a2,2,0,0,1,2.068-2ZM19,13a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1Zm7,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1Zm-7,7a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V21a1,1,0,0,0-1-1Zm7,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V21a1,1,0,0,0-1-1Zm0,7a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V28a1,1,0,0,0-1-1Zm12,0h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H38a1,1,0,0,1-1-1V28A1,1,0,0,1,38,27ZM8,34h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V35A1,1,0,0,1,8,34Zm7,0h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H15a1,1,0,0,1-1-1V35A1,1,0,0,1,15,34Zm11,0a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V35a1,1,0,0,0-1-1Zm12,0h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H38a1,1,0,0,1-1-1V35A1,1,0,0,1,38,34Z" transform="translate(-3 -3.999)"
        fill={color}
      />
    </svg>
  )
}