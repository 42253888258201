import React, { useState, useEffect } from "react";
import { AlertContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import moment from "moment";
import { DatePicke } from "./components";
import { useHistory } from "react-router";
import { Routes } from "../../router/routes";

export const HandOverDateComponent = (props) => {
  const { t = () => false } = props
  const history = useHistory();
  const [handOverDate, setHandOverDate] = useState(new Date());
  const [handOverData, setHandOverData] = useState([]);
  const [tabValue, setTabValue] = React.useState("Ready To Occupy")
  // context;
  const alert = React.useContext(AlertContext);
  const colors = [
    {
      backgroundColor: "#F6F1FE",
      borderColor: "#896DB3"
    },
    {
      backgroundColor: "#EDF7F8",
      borderColor: "#6DAFB3"
    },
    {
      backgroundColor: "#FFFCEB",
      borderColor: "#B3A16D"
    },
    {
      backgroundColor: "#F1F6FF",
      borderColor: "#6D80B3"
    },
    {
      backgroundColor: "#FEF4F4",
      borderColor: "#B3776D"
    },
  ]
  //get date
  const getDate = (val) => {
    let date = val?._d ? val?._d : val;
    setHandOverDate(date);
    HandoverDateFunction(tabValue, date);
  };
  // property vs handover date api call
  const HandoverDateFunction = (type, date) => {


    const payload = {
      company_id: props?.selectedcompany?.value,
      type: type,
      date: moment(date).format("YYYY-MM-DD") ?? new Date()
    };

    NetworkCall(
      `${config.api_url}/property_manager/ready_to_occupy`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let result = response.data.data?.map((val, index) => {
          return {
            id: val?.id ?? "",
            title: val?.name ?? " - ",
            location: val?.address ?? " - ",
            totalUnits: val?.total_units ?? " - ",
            backgroundColor: colors?.map((x) => { return x.backgroundColor })?.[index],
            borderColor: colors?.map((x) => { return x.borderColor })?.[index]
          };
        });
        setHandOverData(result);

        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
      })
      .catch((error) => {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  //   useEffect
  useEffect(() => {
    if (props?.selectedcompany?.label?.length > 0) {
      HandoverDateFunction(tabValue, handOverDate?.date);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedTeams]);
  const handleChangeTab = (value) => {
    setTabValue(value)
    HandoverDateFunction(value, handOverDate);
  }
  const handleOnClick = (value) => {
    history.push(Routes?.unit + "?propertyID=" + value?.id);
  }
  return (
    <>
      <DatePicke outStatndingData={handOverData ?? []} getDate={getDate}
        handleOnClick={handleOnClick}
        updateState={handleChangeTab}
        dateValue={handOverDate}
        tabNameState={tabValue}
        tabList={[{label: t("Move-In Ready"),value:"Ready To Occupy"}, {label: t("Off-Plan Ready"),value:"Off-Plan Ready"}]}
        keyType={"name"} />
    </>
  );
};
