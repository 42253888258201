import React from "react";

function ContactIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g data-name="Group 113946" transform="translate(7176 23931)">
        <rect
          width="40"
          height="40"
          fill="rgba(103,58,183,0.1)"
          data-name="Rectangle 57624"
          rx="8"
          transform="translate(-7176 -23931)"
        ></rect>
        <g data-name="Group 113949">
          <g
            data-name="icons8-new-contact (1)"
            transform="translate(-7172 -23929)"
          >
            <path
              fill="#673ab7"
              d="M23.636 9.182h-9.818L11.636 7H6.182A2.181 2.181 0 004 9.182V13h21.818v-1.636a2.181 2.181 0 00-2.182-2.182"
              data-name="Path 100581"
            ></path>
            <path
              fill="#673ab7"
              d="M23.636 11H6.182A2.181 2.181 0 004 13.182v12a2.181 2.181 0 002.182 2.182h2.727v-.7a1.091 1.091 0 111.091 0v.7h9.818v-.7a1.091 1.091 0 111.091 0v.7h2.727a2.181 2.181 0 002.182-2.182v-12A2.181 2.181 0 0023.636 11"
              data-name="Path 100582"
              transform="translate(0 -1.818)"
            ></path>
            <path
              fill="#d1c4e9"
              d="M14 19.182A2.182 2.182 0 1111.818 17 2.181 2.181 0 0114 19.182m1.636 4.909a4.484 4.484 0 00-3.818-1.636A4.484 4.484 0 008 24.091v1.091h7.636zm1.636-6.545h8.182v1.091h-8.181zm0 2.727h8.182v1.091h-8.181zM20.509 23h-3.236v1.091h3.045z"
              data-name="Path 100583"
              transform="translate(-1.818 -4.545)"
            ></path>
            <path
              fill="#fbc02d"
              d="M34.182 28l-1.347 1.3-1.856-.263-.324 1.848-1.655.884.823 1.686L29 35.14l1.656.879.324 1.848 1.856-.263 1.346 1.3 1.347-1.3 1.856.263.324-1.848 1.655-.879-.823-1.686.823-1.685-1.656-.879-.324-1.848-1.856.263L34.182 28z"
              data-name="Path 100584"
              transform="translate(-11.364 -9.545)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ContactIcon;
