import { Box, Button, Container, Grid, Hidden, Stack, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";
import { useHistory } from "react-router-dom";
import { TextBox } from "../../components";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { EnvTag } from "../../components/envTag";
import { TitleBar } from "../../components/titlebar";
import { BackButtonRound } from "../../assets/back_button_round";
import { ForgotPasswordIcon } from "./assets/forgetPasswordIcon";
import { EmailSentSuccessIcon } from "./assets/emailSentSuccessIcon";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { BackendRoutes } from "../../router/routes";

const InitialState = {
    email: "",
    error: {
        email: "",
    },
};

export const ForgotPasswordPage = ({
    t = () => false,
    size = {},
    screen_state = {},
}) => {
    const version = localStorage.getItem(LocalStorageKeys.version);
    const classes = useStyles({ size });
    const history = useHistory();
    const alert = React.useContext(AlertContext);
    const [disable, setDisable] = React.useState(false);
    const [is_success, set_is_success] = React.useState(false);
    const [value, setValue] = React.useState({ ...InitialState });

    const updateState = (key, email) => {
        let error = value.error;
        error[key] = "";
        setValue({ ...value, [key]: email, error });
    };

    const isIamValideToLogin = () => {
        let isValid = true;
        let error = value.error;
        if (value.email.length === 0) {
            isValid = false;
            error.email = "Email is Required";
        }
        setValue({ ...value, error });
        return isValid;
    };
    const handleSubmit = () => {
        setDisable(true);
        if (isIamValideToLogin()) {
            const payload = {
                email_id: value?.email?.toLowerCase()
            };
            NetworkCall(
                `${config.authapi}${BackendRoutes?.auth_forgotpassword}`,
                NetWorkCallMethods.post, payload,
                { "x-build-code": config.app_x_build },
                false, false,
            ).then((response) => {
                if (response.status === 200) {
                    set_is_success(true);
                    setDisable(false);
                } else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: "Invalid Email",
                    });
                    setDisable(false);
                }
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "User Not Found",
                });
                setDisable(false);
            });
        } else {
            setDisable(false);
            return false;
        }
    };

    const handleGoBack = () => {
        history.goBack(-1);
    }

    return (
        <>
            {/* Mobile UI */}
            <Hidden smUp>
                <Container className={classes.mob_right_section_card} maxWidth="sm">
                    <TitleBar is_back_button title={t("Forgot Password")} goBack={handleGoBack} />
                    <Grid container className={classes.mob_content}>
                        <Grid item xs={12} className={classes.mob_body_items}
                            sx={{ height: size?.height - 134, overflow: "overlay" }}>
                            <Box display={"flex"} justifyContent={"center"}>
                                {is_success
                                    ? <EmailSentSuccessIcon />
                                    : <ForgotPasswordIcon />}
                            </Box>
                            <Box height={"24px"} />
                            <Typography className={classes.mob_main_text}>
                                {is_success
                                    ? t("The reset password link sent successfully")
                                    : t("Reset your password")}
                            </Typography>
                            <Box height={"8px"} />
                            <Typography className={classes.mob_sub_text}>
                                {is_success
                                    ? (`${t("Check")} "${value.email}"`)
                                    : t("we will send you a link to reset your password")}
                            </Typography>
                            {is_success
                                ? <></>
                                : <TextBox
                                    fullWidth
                                    value={value.email}
                                    onChange={(e) => updateState("email", e.target.value)}
                                    label={t("Email Id")}
                                    placeholder={t("Enter your Email Id")}
                                    isError={value.error.email.length > 0}
                                    errorMessage={value.error.email}
                                    isRequired />
                            }
                        </Grid>
                        <Grid item xs={12} alignSelf={"self-end"} className={classes.mob_bottom_items}>
                            <Stack direction={"column"} alignItems={"start"}>
                                <Button className={classes.mob_set_up_new_button} disabled={disable}
                                    onClick={() => is_success
                                        ? handleGoBack()
                                        : handleSubmit()}>
                                    <Typography className={classes.mob_set_up_new_button_text}>
                                        {is_success
                                            ? t("Back")
                                            : t("Submit")}
                                    </Typography>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Hidden>

            {/* Web & Tablet UI */}
            <Hidden smDown>
                <Container className={classes.web_right_section_card} maxWidth="sm">
                    <Grid container className={classes.web_content} justifyContent={"space-between"}>
                        <EnvTag top={16} right={16} />
                        <Grid item xs={12}>
                            <Box className={classes.web_back_button} onClick={() => history.goBack(-1)}>
                                <BackButtonRound />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction={"column"} alignItems={"start"} display={"grid"}>
                                <Box height={"16px"} />
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    {is_success
                                        ? <EmailSentSuccessIcon />
                                        : <ForgotPasswordIcon />}
                                </Box>
                                <Box height={"24px"} />
                                <Typography className={classes.web_main_text}>
                                    {is_success
                                        ? t("The reset password link sent successfully")
                                        : t("Reset your password")}
                                </Typography>
                                <Box height={"12px"} />
                                <Typography className={classes.web_sub_text}>
                                    {is_success
                                        ? (`${t("Check")} "${value.email}"`)
                                        : t("we will send you a link to reset your password")}
                                </Typography>
                                {is_success
                                    ? <Box height={"185px"} />
                                    : <>
                                        <Box height={"24px"} />
                                        <TextBox
                                            fullWidth
                                            value={value.email}
                                            onChange={(e) => updateState("email", e.target.value)}
                                            label={t("Email Id")}
                                            placeholder={t("Enter your Email Id")}
                                            isError={value.error.email.length > 0}
                                            errorMessage={value.error.email}
                                            isRequired />
                                    </>}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} alignSelf={"self-end"}>
                            <Stack direction={"column"} alignItems={"start"} display={"grid"}>
                                <Button className={classes.web_login_button} disabled={disable}
                                    onClick={() => is_success
                                        ? handleGoBack()
                                        : handleSubmit()}>
                                    <Typography className={classes.web_login_button_text}>
                                        {is_success
                                            ? t("Back")
                                            : t("Submit")}
                                    </Typography>
                                </Button>
                                <Box height={"24px"} />
                                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                                    justifyContent={"space-between"} alignItems={"center"}>
                                    {version &&
                                        <Typography className={classes.version_no}>
                                            {`V ${version}`}
                                        </Typography>
                                    }
                                    <img className={classes.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Hidden>
        </>
    );
}