import { Box } from '@mui/material'
import React from 'react'
import { SearchFilter } from '../../../components'
import { UserList } from '../../../components/userList'

export const AlertEmpList = ({empList={}}) => {
    return (
        <Box m={2}>
            <SearchFilter
                value={""}
                placeholder="Search Employee"
            />
            <Box mt={1}>
                <UserList handleClick={""} userAlert={true} empList={empList}/>
            </Box>
        </Box>
    )
}