export const RestoreBackupIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={80}
    height={80}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.586}
        x2={0.409}
        y1={0.011}
        y2={1.016}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#32bdef" />
        <stop offset={1} stopColor="#1ea2e4" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={0.768}
        x2={0.224}
        y1={-0.023}
        y2={0.938}
      />
      <radialGradient
        id="b"
        cx={3.247}
        cy={1.96}
        r={2.496}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0.752} stopColor="#076cb3" />
        <stop offset={0.825} stopColor="#138cd2" />
        <stop offset={0.865} stopColor="#1898de" />
        <stop offset={1} stopColor="#199ae0" />
      </radialGradient>
    </defs>
    <g data-name="Group 114064" transform="translate(1.59 1.999)">
      <path
        fill="url(#b)"
        d="M16.4 11.012 8 21.294V5.051a1.011 1.011 0 0 1 1.725-.713Z"
        data-name="Path 100647"
        transform="translate(1.117 .028)"
      />
      <circle
        cx={6.81}
        cy={6.81}
        r={6.81}
        fill="url(#a)"
        data-name="Ellipse 130010"
        transform="translate(29.548 31.241)"
      />
      <path
        fill="url(#c)"
        d="M70.362 36.22A34.292 34.292 0 0 0 36.392 4a33.91 33.91 0 0 0-23.618 9.524l.075.007a34.2 34.2 0 0 0-3.732 4.176v3.673a1.349 1.349 0 0 0 1.348 1.348h16.3a1.01 1.01 0 0 0 .715-1.723l-4.588-4.588c.221-.14.453-.262.679-.395l-.019-.017a25.293 25.293 0 0 1 13.176-3.49A25.539 25.539 0 1 1 13.871 50.1a.844.844 0 0 0-1.178-.339l-5.859 3.49a.863.863 0 0 0-.32 1.149 34.039 34.039 0 0 0 63.848-18.18Z"
        data-name="Path 100648"
      />
    </g>
  </svg>
)