import { Avatar, Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { DetailCardStyle } from './style'
import { stringAvatar } from '../../../utils'
import moment from 'moment'


export const DetailCard = ({
    title = "",
    name = "",
    mobile_no = "",
    email = "",
    image = null,
    image_url = null
}) => {
    const classes = DetailCardStyle()
    return (
        <Stack className={classes.box} spacing={0.7}>
            <Typography className={classes.title}>{title}</Typography>
            <Stack direction={"row"} marginTop={'9.3px !important'}>
                {
                    image ? <Avatar src={image} className={classes.avatar} style={{ marginInlineEnd: "10px" }} /> : <Avatar style={{ marginInlineEnd: "10px" }} src={image_url} className={classes.avatar} {...stringAvatar(name === "-" ? "" : name)} />

                }
                <Stack>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <Typography className={classes.name} noWrap>{name}</Typography>
                            </Box>
                        </Grid>

                    </Grid>
                    <Box>
                        <Grid container spacing={'6px'}>
                            <Grid item xs={5.4}>
                                <Tooltip title={mobile_no} placement="top-start" arrow>
                                    <Typography className={classes.content} noWrap>{mobile_no}</Typography>
                                </Tooltip>
                            </Grid>
                            {(!!mobile_no && !!email) &&
                                <Grid item xs={0.8} style={{ paddingLeft: "0px" }}>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                        <Box className={classes.dot}></Box>
                                    </Box>
                                </Grid>
                            }

                            <Grid item xs={5.8}>
                                <Tooltip title={email} placement="top-start" arrow>
                                    <Typography className={classes.content} noWrap>{email}</Typography>
                                </Tooltip>
                            </Grid>

                        </Grid>
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    )
}
export const SettlementCard = ({
    title = "",
    name = "",
    mobile_no = "",
    email = "",
    content = null,
    t = () => false,
    costing_request = false,
    status,
    sendRequest = () => false
}) => {
    const classes = DetailCardStyle()

    const statusText = {
        "Pending": {
            name: "Pending",
            time: moment(costing_request?.[0]?.created_at).format("DD MMM YYYY"),
            img: "/images/Group 115814.svg"
        },
        "Rejected": {
            name: "Rejected",
            time: moment(costing_request?.[0]?.created_at).format("DD MMM YYYY"),
            img: "/images/Group 115815.svg"
        },
        "Approved": {
            name: "Approved",
            time: moment(costing_request?.[0]?.created_at).format("DD MMM YYYY"),
            img: "/images/Group 115816.svg"
        }
    }
    return (
        <Stack className={classes.box} spacing={0.7}>
            {costing_request?.length ? <Typography className={classes.title}>{title}</Typography> : ""}

            {
                !costing_request?.length > 0 ?
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "100%" }}>
                        <Box>
                            <Typography className={classes.statusText}>{t("Send final settlement")}</Typography>
                            <Typography className={classes.statusText}>{t("approval request")}</Typography>
                        </Box>
                        <Button className={classes.sendRequest} onClick={sendRequest}>
                            {t('Send Request')}
                        </Button>
                    </Box> :
                    <Box marginTop={costing_request?.[0]?.status === "Rejected" ? '1.3px !important' : '9.3px !important'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Avatar src={statusText[costing_request?.[0]?.status]?.img} className={classes.avatar} style={{ marginInlineEnd: "10px" }} />
                            <Box>
                                <Typography className={classes.name}>{statusText[costing_request?.[0]?.status]?.name ?? '-'}</Typography>
                                <Typography className={classes.content}>on {statusText[costing_request?.[0]?.status]?.time ?? '-'}</Typography>

                            </Box>
                        </Box>
                        {
                            costing_request?.[0]?.status === "Rejected" &&
                            <Box>
                                <Button className={classes.resendRequest} onClick={sendRequest}>
                                    {t('Resend')}
                                </Button>
                            </Box>
                        }
                    </Box>
            }

        </Stack>
    )
}