import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { FormGenerator } from "../../components"
import { StatusOptionList } from "../../utils/inventory"
import { MyOrgStyle } from "./style"
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import { config } from "../../config"
import { stringAvatar } from "../../utils"
export const AddForm = (
    {
        t = () => false,
        formdata = "",
        setFormData = () => false,
        updateState = () => false,
        onFormSubmit = () => false,
        onCloseModal = () => false,
        isDisableBtn = false,
        companyList = [],
        enumValue = {}
    }
) => {
    const classes = MyOrgStyle()


    const updateFormData = (key, value) => {
        if (key === "contact") {
            setFormData({ ...formdata, [key]: { label: value?.first_name, value: value?.id, ...value }, error: { ...formdata?.error, [key]: "" } })
        } else {
            setFormData({ ...formdata, [key]: value, error: { ...formdata?.error, [key]: "" } })
        }
    }

    const manualResponse = (array) => array?.map(_ => {
        return {
            ..._,
            label: _?.first_name,
            value: _?.id,
        }
    });

    const manualRoleResponse = (array) => array?.map(_ => {
        return {
            ..._,
            label: _?.role_name,
            value: _?.id,
        }
    });
    const formComittee = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggle",
            label: t("Role Type"),
            value: formdata?.role_type,
            placeholder: t("Select Role Type"),
            onChange: (value) => {
                if (value === "Company") {
                    updateState("role_type", value)
                } else {
                    setFormData({
                        ...formdata,
                        role_type: value,
                        company_Name: ""
                    })
                }
            },
            error: formdata?.error?.role_type,
            isRequired: true,
            options: enumValue?.role_type
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: formdata?.role_type === "Company",
            component: "select",
            label: t("Company"),
            value: formdata?.company_Name,
            placeholder: t("Select Company"),
            onChange: (value) => {
                setFormData({
                    ...formdata,
                    company_Name: value,
                    contact: ""
                })
            },
            error: formdata?.error?.company_Name,
            isRequired: true,
            options: companyList,
            menuPlacement: "bottom"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("Contact"),
            value: formdata?.contact,
            placeholder: t("Select Contact"),
            onChange: (value) => updateFormData("contact", value),
            error: formdata?.error?.contact,
            isRequired: true,
            isPaginate: true,
            isReadOnly: false,
            menuPlacement: "bottom",
            key: JSON.stringify(formdata?.company_Name),
            loadOptions: (search, array, handleLoading) => loadOptionsApis(
                "/contact/list",
                {
                    "tenantId": `${config.tenantId}`,
                    "company_id": formdata?.company_Name?.value ?? undefined,
                },
                search,
                array,
                handleLoading,
                "data",
                {},
                manualResponse

            ),
            customComponent: (props) => {
                return (
                    <Stack direction={"row"} columnGap={1} alignItems="center" p={1}
                        className={props?.isSelected ? classes.selectedMenuBox : classes.menuBox}>
                        <Avatar src={props?.data?.image_url} {...stringAvatar(props?.data?.first_name)}
                            className={classes.menuAvatar}></Avatar>
                        <Stack>
                            <Typography className={classes.menuTitle}>{props?.data?.first_name}</Typography>
                            <Typography className={classes.menuSubtitle}>{`${props?.data?.mobile_no_country_code} ${props?.data?.mobile_no}`} {`${props?.data?.email_id}`}</Typography>
                        </Stack>
                    </Stack>
                )
            }
        },

        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "select",
            label: t("Role"),
            value: formdata?.role,
            placeholder: t("Select Role"),
            onChange: (value) => updateFormData("role", value),
            error: formdata?.error?.role,
            isRequired: true,
            isPaginate: true,
            isReadonly: formdata?.type === "edit" ? true : false,
            menuPlacement: "bottom",
            loadOptions: (search, array, handleLoading) => loadOptionsApis(
                "/my_organisation/get_roles",
                {
                    is_all_roles: false,
                    is_active: [true]
                },
                search,
                array,
                handleLoading,
                "data",
                {},
                manualRoleResponse
            ),
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "date",
            label: t("Start Period"),
            value: formdata?.start_period,
            onChange: (value) => updateState("start_period", value),
            placeholder: t("Choose Start Period"),
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggle",
            label: t("Status"),
            value: formdata?.is_active,
            placeholder: t("Select Status"),
            onChange: (value) => updateState("is_active", value),
            error: formdata?.error?.status,
            isRequired: true,
            options: StatusOptionList
        },
    ]


    return (
        <Box p={2}>
            <FormGenerator t={t} components={formComittee} />
            <Grid container>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <Stack direction="row" justifyContent={"end"} spacing={1}>
                        <Button variant="outlined" onClick={() => onCloseModal()} className={classes.cancelbtn}>{t("Cancel")}</Button>
                        <Button variant="contained" onClick={() => onFormSubmit()} disabled={isDisableBtn}>{formdata.type === "add" ? t("Add") : t("Save")}</Button>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}