import React from "react";
import { withNavBars } from "../../HOCs";
import { UnitTable } from "./unitTable";

class UnitTableParent extends React.Component {
    render() {
        return <UnitTable />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UnitTableParent, props);
