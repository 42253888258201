import React from 'react';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { CustomPaper, CustomTypography, TitleDesc, IconWithText } from '../../companyCreation/components';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';
import { PhoneIcon, Location, BuldingIcon } from '../../../components/svg';
import { returnValue } from '../../companyCreation/utils';
import { Bold, SemiBold } from '../../../utils';
import { Box, Hidden } from '@mui/material';
const Image = styled(Avatar)({
    width: 141,
    height: 141,
    borderRadius: 71,
    backgroundColor: "#F2F4F7",
})

export const VendorBasicDetailsView = (props) => {
    const { t } = (props);

    const theme = useTheme();

    const {
        data = {}
    } = props;

    const {
        profileDetails = null,
        address = null,
        contactInformation = null,
        logo = null,
    } = data;

    const constructAddress = (value = "", delimiter = "") => value ? value?.concat(",") : delimiter;


    return (
        <div>
            {/* <CustomPaper height={'100%'}> */}
            <Box sx={{ margin: "8px 16px 0px 16px" }}>
                <CustomPaper backgroundColor="#F5F7FA" >
                    {/* <Stack
                        direction={{ sm: "row", xs: "column",md:'column',lg:"row" }}
                        divider={<Divider flexItem orientation={"vertical"} />}
                        gap={{ xs: 2, sm: 3 }}
                        alignItems={{ sm: "center" }}
                    > */}
                    <Grid container bgcolor="#F5F7FA">
                        {/* for web */}
                        <Hidden mdDown>
                            <Grid item xs={12} md={12} lg={2}>
                                <Image
                                    src={logo?.src ? logo?.src : ""}
                                    alt={"profile_img"}
                                    sx={{
                                        border: logo?.src ? 0 : '1px solid #e3e5ec'
                                    }}
                                >
                                    {!(!!logo?.src) && <BuldingIcon />}
                                </Image>
                            </Grid>
                            <Divider flexItem orientation={"vertical"} sx={{ m: "0px 8px 0px 16px" }} />
                        </Hidden>
                        {/* for tab */}
                        <Hidden mdUp>
                            <Grid item xs={12} md={12} lg={2} display={"flex"} justifyContent={"center"} mb={2}>
                                <Image
                                    src={logo?.src ? logo?.src : ""}
                                    alt={"profile_img"}
                                    sx={{
                                        border: logo?.src ? 0 : '1px solid #e3e5ec'
                                    }}
                                >
                                    {!(!!logo?.src) && <BuldingIcon />}
                                </Image>
                            </Grid>
                            <Divider flexItem orientation={"vertical"} sx={{ m: "0px 8px 0px 16px" }} />
                        </Hidden>
                        <Grid item xs={12} md={12} lg={9} ml={2}>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTypography
                                        fontSize={12}
                                        fontFamily={Bold}
                                        // marginBottom={theme?.spacing(2)}
                                        color={theme?.typography?.color?.secondary}
                                    >
                                        {t("Profile Details")}
                                    </CustomTypography>
                                </Grid>
                                <Grid item xs={6} lg={3} md={4}>
                                    <TitleDesc
                                        title={t("Vendor's Company")}
                                        desc={returnValue(profileDetails?.vendorCompany?.label, "-")}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} md={4}>
                                    <TitleDesc
                                        title={t("Vendor Code")}
                                        desc={returnValue(profileDetails?.vendorCode, "-")}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} md={4}>
                                    <TitleDesc
                                        title={t("Vendor Name")}
                                        desc={returnValue(profileDetails?.vendorName, "-")}
                                    />
                                </Grid>
                                <Grid item xs={6} lg={3} md={4}>
                                    <TitleDesc
                                        title={t("Vendor's Country")}
                                        desc={returnValue(profileDetails?.vendorCountry?.label, '-')}
                                    />
                                </Grid>
                                <Grid item xs={6} md={12}>
                                    <TitleDesc
                                        title={t("Vendor Description")}
                                        dangerouslySetInnerHTML={returnValue(profileDetails?.vendorDescription, "-")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid >

                        {/* </Stack> */}
                    </Grid>

                </CustomPaper>
            </Box>
            {/* </CustomPaper> */}
            <CustomPaper>
                <Grid container spacing={2}>
                    {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4.5}
                        sx={{
                            height: { xs: "300px" }
                        }}
                    >
                        <div
                            style={{
                                borderRadius: 8,
                                overflow: "hidden",
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            <MyGoogleMap
                                lat={address?.latitude}
                                lng={address?.longitude}
                                center={{
                                    lat: address?.latitude,
                                    lng: address?.longitude
                                }}
                                readonly
                            />
                        </div>
                    </Grid> */}
                    {/* when map component is needed plz use below commented grid item */}

                    {/* <Grid item xs={12} sm={6} md={3}> */}
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <CustomPaper
                            border={"1px solid #E4E8EE"}
                            boxShadow={"none"}
                            borderRadius={8}
                            padding={theme.spacing(2)}
                            height={'100%'}
                        >
                            <IconWithText
                                icon={<Location />}
                                text={t("Address")}
                            />
                            <CustomTypography
                                fontFamily={SemiBold}
                                marginBottom={theme?.spacing(2)}
                                marginTop={theme?.spacing(2)}
                                color={theme?.typography?.color?.primary}
                            >
                                {
                                    returnValue((`
                                    ${constructAddress(address?.doorNumber)} 
                                    ${constructAddress(address?.addressLineTwo)}
                                    ${constructAddress(address?.addressLineTwo)}
                                    ${constructAddress(address?.landmark)}
                                    ${constructAddress(address?.area)}
                                    ${constructAddress(address?.state)}
                                    ${constructAddress(address?.country)}
                                    ${returnValue(address?.pincode)}
                                    `)
                                        .trim(), "-"
                                    )
                                }
                            </CustomTypography>
                            {/* <CustomTypography
                                fontSize={12}
                                fontFamily={SemiBold}
                                marginBottom={theme?.spacing(2)}
                                color={theme?.typography?.color?.tertiary}
                            >
                                {t("Latitude")} : {" "}
                                <CustomTypography
                                    component={"span"}
                                    fontSize={12}
                                    fontFamily={SemiBold}
                                    color={theme?.typography?.color?.primary}
                                >
                                    {returnValue(address?.latitude, '-')}
                                </CustomTypography>
                            </CustomTypography>
                            <CustomTypography
                                fontSize={12}
                                fontFamily={SemiBold}
                                // marginBottom={theme?.spacing(2)}
                                color={theme?.typography?.color?.tertiary}
                            >
                                {t("Longitude")} : {" "}
                                <CustomTypography
                                    component={"span"}
                                    fontSize={12}
                                    fontFamily={SemiBold}
                                    color={theme?.typography?.color?.primary}
                                >
                                    {returnValue(address?.longitude, '-')}
                                </CustomTypography>
                            </CustomTypography> */}
                        </CustomPaper>
                    </Grid>
                    {/* when map component is needed plz use below commented grid item */}
                    {/* <Grid item xs={12} sm={6} md={4.5}> */}
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <CustomPaper
                            border={"1px solid #E4E8EE"}
                            boxShadow={"none"}
                            borderRadius={8}
                            padding={theme.spacing(2)}
                            height={'100%'}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <IconWithText
                                        icon={<PhoneIcon />}
                                        text={t("Contact & Other Information")}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        direction="ltr"
                                        textAlign="-webkit-match-parent"
                                        title={t("Primary Telephone")}
                                        desc={(
                                            returnValue((`${returnValue(contactInformation?.primaryTelephone?.mobile_code)} ${returnValue(contactInformation?.primaryTelephone?.mobile)}`)?.trim(), "-")
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={t("Primary Mobile")}
                                        direction="ltr"
                                        textAlign="-webkit-match-parent"
                                        desc={(
                                            returnValue((`${returnValue(contactInformation?.primaryMobile?.mobile_code)} ${returnValue(contactInformation?.primaryMobile?.mobile)}`)?.trim(), "-")
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={t("Email Address :")}
                                        desc={returnValue(contactInformation?.primaryEmailAddress, "-")}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={t("Website")}
                                        desc={returnValue(contactInformation?.website, "-")}
                                    />
                                </Grid>
                            </Grid>
                        </CustomPaper>
                    </Grid>
                </Grid >
            </CustomPaper >
        </div >
    )
}