import * as React from "react"
export const ResourcesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <linearGradient
        id="c"
        x1={0.399}
        x2={0.533}
        y1={-143.315}
        y2={-144.413}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0370c8" />
        <stop offset={0.484} stopColor="#036fc5" />
        <stop offset={0.775} stopColor="#036abd" />
        <stop offset={1} stopColor="#0362b0" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={0.252}
        x2={0.889}
        y1={-474.431}
        y2={-474.431}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c48f0c" />
        <stop offset={0.251} stopColor="#d19b16" />
        <stop offset={0.619} stopColor="#dca51f" />
        <stop offset={1} stopColor="#e0a922" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={-1415.154}
        x2={-1414.519}
        y1={-474.431}
        y2={-474.431}
      />
      <linearGradient
        id="f"
        x1={0.434}
        x2={0.539}
        y1={-156.259}
        y2={-157.345}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fede00" />
        <stop offset={1} stopColor="#ffd000" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={0.388}
        x2={0.6}
        y1={-645.993}
        y2={-646.953}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#4b4b4b" />
        <stop offset={1} stopColor="#3b3b3b" />
      </linearGradient>
      <radialGradient
        id="e"
        cx={0.5}
        cy={0.5}
        r={0.438}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </radialGradient>
      <radialGradient
        id="b"
        cx={0.072}
        cy={0.077}
        r={2.785}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c26715" />
        <stop offset={0.508} stopColor="#b85515" />
        <stop offset={1} stopColor="#ad3f16" />
      </radialGradient>
      <radialGradient xlinkHref="#b" id="h" cx={0.469} cy={0.094} r={1.295} />
    </defs>
    <g data-name="Group 113642">
      <path
        fill="url(#c)"
        d="M19.2 30.571c-6.186 0-11.2 3.445-11.2 7.2v.8a1.4 1.4 0 0 0 1.4 1.4H29a1.4 1.4 0 0 0 1.4-1.4v-.8c0-3.755-5.014-7.2-11.2-7.2Z"
        data-name="Path 100440"
        transform="translate(1 -9.971)"
      />
      <path
        fill="#d6a121"
        d="M23.7 21.599c0 1.328-1.4 2.8-3.5 2.8s-3.5-1.472-3.5-2.8v-4.924h7Z"
        data-name="Path 100441"
      />
      <path
        fill="url(#a)"
        d="M32.758 18h-.369v2.8h.492a.714.714 0 0 0 .729-.518l.241-1.192A1 1 0 0 0 32.758 18Z"
        data-name="Path 100442"
        transform="translate(-6.317 -6.198)"
      />
      <path
        fill="url(#d)"
        d="M14.614 18h.369v2.8h-.493a.714.714 0 0 1-.729-.518l-.241-1.192A1 1 0 0 1 14.614 18Z"
        data-name="Path 100443"
        transform="translate(-.655 -6.198)"
      />
      <path
        fill="url(#e)"
        d="M21.3 6.4c-3.109 0-6.3.473-6.3 7.192v4.689c0 2.4 3.78 5.339 6.3 5.339s6.3-2.94 6.3-5.339v-4.689c0-6.72-3.191-7.192-6.3-7.192Z"
        data-name="Path 100444"
        transform="translate(-1.1 -2.72)"
      />
      <path
        fill="url(#b)"
        d="M14 14a33.915 33.915 0 0 0 .7 5.6l1.4-5.6h9.8s1.4 4.878 1.4 5.6A36.9 36.9 0 0 0 28 14Z"
        data-name="Path 100445"
        transform="translate(-.8 -5)"
      />
      <path
        fill="#ff832b"
        d="M28.25 11.8h-16.1a.35.35 0 0 1-.35-.35v-.7a.35.35 0 0 1 .35-.35h16.1a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35Z"
        data-name="Path 100446"
      />
      <path
        fill="#fc6900"
        d="M27.2 9.7V9a6.3 6.3 0 0 0-6.3-6.3h-1.4A6.3 6.3 0 0 0 13.2 9v.7a.7.7 0 0 1-.7.7h15.4a.7.7 0 0 1-.7-.7Z"
        data-name="Path 100447"
      />
      <path
        fill="#fc720f"
        d="M19.5 2h1.4a.7.7 0 0 1 .7.7v7.7h-2.8V2.7a.7.7 0 0 1 .7-.7Z"
        data-name="Path 100448"
      />
      <path
        fill="url(#f)"
        d="M25.6 31.674V37.5h-9.8v-5.826a12.849 12.849 0 0 0-2.8 1.289V40.3h15.4v-7.337a12.849 12.849 0 0 0-2.8-1.289Z"
        data-name="Path 100449"
        transform="translate(-.5 -10.302)"
      />
      <path
        fill="url(#g)"
        d="M0 0h2.8v2.1H0z"
        data-name="Rectangle 57519"
        transform="translate(12.5 25.1)"
      />
      <path
        fill="url(#g)"
        d="M0 0h2.8v2.1H0z"
        data-name="Rectangle 57520"
        transform="translate(25.1 25.1)"
      />
      <path
        fill="url(#h)"
        d="M21.408 23c-2.716 0-3.458 1.316-6.048 1.393.882 2.107 3.92 4.207 6.048 4.207s5.166-2.1 6.048-4.207C24.866 24.316 24.124 23 21.408 23Zm1.75 4.2c-1.05 0-1.05-1.05-1.75-1.05s-.7 1.05-1.75 1.05a1.029 1.029 0 0 1-.7-1.75 3.014 3.014 0 0 1 2.45-1.05 3.014 3.014 0 0 1 2.45 1.05 1.029 1.029 0 0 1-.7 1.75Z"
        data-name="Path 100450"
        transform="translate(-1.208 -7.7)"
      />
    </g>
  </svg>
)