import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import ActivityBoard from './activityBoard'

class ActivityBoardParent extends Component {
    render() {
        return (
            <div>
                <ActivityBoard />
            </div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ActivityBoardParent, props)