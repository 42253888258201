import React from "react";
import { withNavBars } from "../../HOCs";
import ReceiptView from "./receiptView";

class ReceiptViewtParent extends React.Component {
    render() {
        return <ReceiptView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ReceiptViewtParent, props);
