import React, { useState } from "react";
import { Subheader } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import Dashboard from "./dashboard";
import { accessCheckRender, constructPropertyList, getCalendarOptions, getCompanyOption, getRoutePermissionNew, pageName } from "../../utils";
import { withNamespaces } from "react-i18next";

const VisitorandSecurityDashboard = (props) => {
  const { loading, handleLoading, t = () => false } = props;

  // useContext
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  // useState
  const [calendar_value, set_calendar_value] = useState(getCalendarOptions()[6]);
  const [company_list, set_company_list] = React.useState([]);
  const [selected_company, set_selected_company] = React.useState({});
  const [property_list, set_property_list] = React.useState([]);
  const [selected_property, set_selected_property] = React.useState({});
  const [permission, set_permission] = React.useState({});

  // useEffect to get permission
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      set_permission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          set_company_list(company?.list);
          set_selected_company(company?.selected);
          getPropertyList(company?.selected);
        }
      }
    }
    // eslint-disable-next-line
  }, [auth])

  // Function to change property list
  const getPropertyList = (company) => {
    let property = constructPropertyList(company)
    set_property_list(property?.list);
    set_selected_property(property?.list[0]);
    handleLoading(false);
  };

  // Function to handle company change
  const handleCompanyChange = (company) => {
    set_selected_company(company);
    getPropertyList(company);
  };

  // Function to handle property change
  const handlePropertyChange = (property) => {
    set_selected_property(property);
  };

  // Function to handle calendar change
  const handleCalendarChange = (date_range) => {
    set_calendar_value(date_range);
  };

  const render = () => {
    return (
      <>
        <Subheader
          hideBackButton={true}
          title={t("Visitor and Security Dashboard")}
          calanderselect
          calanderValue={calendar_value}
          calendarOptions={getCalendarOptions()}
          onChangeCalendar={(date_range) => handleCalendarChange(date_range)}
          select
          options={company_list ?? []}
          value={selected_company}
          onchange={(company) => handleCompanyChange(company)}
          selectProperty
          propertyOptions={property_list ?? []}
          propertyValue={selected_property}
          onPropertyChange={(property) => handlePropertyChange(property)} />
        <Dashboard
          calendar={calendar_value}
          company={selected_company}
          property={selected_property}
          t={t}
        />
      </>
    );
  };

  return <div>{accessCheckRender(render, permission, pageName.dashboard, loading)}</div>;
};
export default withNamespaces("visitorandSecurityDashboard")(VisitorandSecurityDashboard);
