import React from "react"

export const CausalLeaveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_106763" data-name="Group 106763" transform="translate(-3837 23769)">
            <g id="Rectangle_55650" data-name="Rectangle 55650" transform="translate(3837 -23769)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <path id="icons8-calendar_5_" data-name="icons8-calendar (5)" d="M22,9.556V8.889A2.892,2.892,0,0,0,19.111,6H8.889A2.892,2.892,0,0,0,6,8.889v.667ZM6,10.889v8.222A2.892,2.892,0,0,0,8.889,22H19.111A2.892,2.892,0,0,0,22,19.111V10.889Zm4.222,8.444a1.111,1.111,0,1,1,1.111-1.111A1.111,1.111,0,0,1,10.222,19.333Zm0-4a1.111,1.111,0,1,1,1.111-1.111A1.111,1.111,0,0,1,10.222,15.333Zm3.778,4a1.111,1.111,0,1,1,1.111-1.111A1.111,1.111,0,0,1,14,19.333Zm0-4a1.111,1.111,0,1,1,1.111-1.111A1.111,1.111,0,0,1,14,15.333Zm3.778,0a1.111,1.111,0,1,1,1.111-1.111A1.111,1.111,0,0,1,17.778,15.333Z" transform="translate(3835 -23771)" fill="#5ac782" />
        </g>
    </svg>

)