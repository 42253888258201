import * as React from "react"

export const Inspection = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 105471">
      <path data-name="Rectangle 55518" fill="none" d="M0 0h20v20H0z" />
      <path
        d="M8.625 1a2.03 2.03 0 0 0-2 1.8h-1.6A2.027 2.027 0 0 0 3 4.825v12.15A2.027 2.027 0 0 0 5.025 19h10.35a2.027 2.027 0 0 0 2.025-2.025V4.825A2.027 2.027 0 0 0 15.375 2.8h-1.6a2.03 2.03 0 0 0-2-1.8Zm0 1.35h3.15a.675.675 0 0 1 0 1.35h-3.15a.675.675 0 0 1 0-1.35Zm-1.8 4.95a.675.675 0 1 1-.675.675.675.675 0 0 1 .675-.675Zm2.7 0h4.05a.675.675 0 1 1 0 1.35h-4.05a.675.675 0 0 1 0-1.35Zm-2.7 3.15A1.125 1.125 0 1 1 5.7 11.575a1.126 1.126 0 0 1 1.125-1.125Zm2.7.45h4.05a.675.675 0 1 1 0 1.35h-4.05a.675.675 0 0 1 0-1.35Zm-2.7 3.6a.675.675 0 1 1-.675.675.675.675 0 0 1 .675-.675Zm2.7 0h4.05a.675.675 0 1 1 0 1.35h-4.05a.675.675 0 0 1 0-1.35Z"
        fill={props?.color ?? "#c1c5cb"}
      />
    </g>
  </svg>
)
