import React from "react"


export const CurrencyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="Group_108468" data-name="Group 108468" transform="translate(-47 -8.5)">
                <g id="Rectangle_56387" data-name="Rectangle 56387" transform="translate(47 8.5)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="16" height="16" stroke="none" />
                    <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
                </g>
                <g id="icons8-profit" transform="translate(42 -10.5)">
                    <path id="Path_99531" data-name="Path 99531" d="M5,23H21v8.421H5Z" fill="#a5d6a7" />
                    <path id="Path_99532" data-name="Path 99532" d="M11.684,29.526a.842.842,0,1,1-.842-.842A.845.845,0,0,1,11.684,29.526Zm10.105,0a.842.842,0,1,1-.842-.842A.845.845,0,0,1,21.789,29.526Zm-3.368,0A2.526,2.526,0,1,1,15.895,27,2.534,2.534,0,0,1,18.421,29.526Z" transform="translate(-2.895 -2.316)" fill="#388e3c" />
                    <path id="Path_99533" data-name="Path 99533" d="M20.158,23.842v6.737H5.842V23.842H20.158M21,23H5v8.421H21Z" fill="#388e3c" />
                    <path id="Path_99534" data-name="Path 99534" d="M6,24v1.474A1.053,1.053,0,0,0,7.474,24Zm15.158,0H19.684a1.053,1.053,0,0,0,1.474,1.474Zm0,6.105a1.053,1.053,0,0,0-1.474,1.474h1.474ZM7.474,31.579A1.053,1.053,0,0,0,6,30.105v1.474Z" transform="translate(-0.579 -0.579)" fill="#388e3c" />
                </g>
            </g>
        </svg>

    )
}