import {
  Box
} from "@mui/material";
import React from "react";
import { CreateQuotationListContext } from "../../../contexts/createQuotationContext";
import { LeaseForm, SaleForm } from "../component";
export const LeadDetails = ({ company_id }) => {
  const { dataNew, updateStateNew, setDataNew, selectedAccount, setSelectedAccount, t, opperEdit ,setOpperEdit } = React.useContext(CreateQuotationListContext);
  return (
    <Box >


      {
        (dataNew?.searchdata?.revenue_type === "Lease" || dataNew?.searchdata?.revenue_type === "Manage") &&
        <LeaseForm
          selectedAccount={selectedAccount}
          data={dataNew}
          t={t}
          updateState={updateStateNew}
          purpose={dataNew?.searchdata?.property_purpose}
          type={dataNew?.searchdata?.revenue_type}
          setData={setDataNew}
          setSelectedAccount={setSelectedAccount}
          opperEdit={opperEdit} 
          setOpperEdit={setOpperEdit}
        />
      }

      {
        dataNew?.searchdata?.revenue_type === "Sale" &&
        <SaleForm
          selectedAccount={selectedAccount}
          data={dataNew}
          updateState={updateStateNew}
          purpose={dataNew?.searchdata?.property_purpose}
          type={dataNew?.searchdata?.revenue_type}
          setData={setDataNew}
          t={t}
          opperEdit={opperEdit} 
          setOpperEdit={setOpperEdit}
        />
      }

      {/* Short Term Rental */}
      {
        dataNew?.searchdata?.property_purpose === "Short Term Rental" &&
        <LeaseForm
          selectedAccount={selectedAccount}
          data={dataNew}
          purpose={dataNew?.searchdata?.property_purpose}
          type={dataNew?.searchdata?.revenue_type}
          updateState={updateStateNew}
          setData={setDataNew}
          t={t}
          opperEdit={opperEdit} 
          setOpperEdit={setOpperEdit}
        />
      }


    </Box>


  );
};
