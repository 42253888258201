import * as React from "react"

const OnlinePaymentSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 107268" transform="translate(-6535 14470)">
      <rect
        data-name="Rectangle 55731"
        width={32}
        height={32}
        rx={2}
        transform="translate(6535 -14470)"
        fill="rgba(237,228,254,0.5)"
      />
      <g data-name="Group 107267">
        <path
          data-name="Rectangle 55480"
          fill="none"
          d="M6543-14462h16v16h-16z"
        />
        <path
          d="M6546.8-14462a1.8 1.8 0 0 0-1.8 1.8v12.4a1.8 1.8 0 0 0 1.8 1.8h9.2a1.8 1.8 0 0 0 1.8-1.8v-7.8h-4.6a1.8 1.8 0 0 1-1.8-1.8v-4.6Zm5.8.352v4.248a.6.6 0 0 0 .6.6h4.248Zm-1.2 7.26a1.727 1.727 0 0 1 1.067.359l1.875 1.477a1.717 1.717 0 0 1 .658 1.352v2.236a1.362 1.362 0 0 1-1.35 1.364h-4.5a1.362 1.362 0 0 1-1.35-1.362v-2.238a1.716 1.716 0 0 1 .658-1.355l1.875-1.477a1.73 1.73 0 0 1 1.067-.356Zm-.6 2.788a.6.6 0 0 0-.6.6v1.2a.6.6 0 0 0 .6.6h1.2a.6.6 0 0 0 .6-.6v-1.2a.6.6 0 0 0-.6-.6Z"
          fill="#b69ee6"
        />
      </g>
    </g>
  </svg>
)

export default OnlinePaymentSvg