import { useContext, useEffect, useState } from 'react';
// import ReactDOM from 'react-dom';
import { PopoverContext } from '../../contexts';

export const Marker = (props) => {

    const [marker, setMarker] = useState();
    // const [infoWindow, setInfoWindow] = useState();

    const {
        infoWindow = null,
        setInfoWindow = null,
        hideDialog = false
    } = props;

    const dialog = useContext(PopoverContext);

    useEffect(() => {

        if (!marker) {
            setMarker(new window.google.maps.Marker({
                animation: window.google.maps.Animation.DROP
            }));
        }
        if (!infoWindow) {
            setInfoWindow(new window.google.maps.InfoWindow({
                content: '',
            }))
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
            if (infoWindow) {
                infoWindow.setMap(null);
            }
        };
        // eslint-disable-next-line
    }, [marker, infoWindow]);


    useEffect(() => {
        if (marker) {
            if (!marker.getMap()) {
                marker.setMap(props?.map);
            }
            marker.setOptions(props);
        }
        // eslint-disable-next-line
    }, [marker, props]);


    useEffect(() => {

        if (marker) {
            marker.addListener("click", (event) => {
                // if (infoWindow) {
                //     if (infoWindow?.getMap()) {
                //         infoWindow.close()
                //     }
                //     else {
                //         var content = document.createElement('div');
                //         ReactDOM.render(props?.component, content);
                //         infoWindow.setContent(content);
                //         infoWindow.open(marker.getMap(), marker);
                //     }
                // }
                if (hideDialog) {
                    return false
                } else {
                    dialog.setDialog({
                        ...dialog,
                        anchorEl: event?.domEvent?.currentTarget,
                        open: true,
                        body: props?.component,
                        sx: [{
                            '& .MuiDialogContent-root': {
                                padding: 0
                            }
                        }]
                    })
                }
            });
        }
        // eslint-disable-next-line
    }, [marker, props?.component, hideDialog])

    return null;
}