import React from 'react';
import { useStyles } from "../styles";
import { Box, Button, Typography } from "@mui/material";


export const ApprovedDialog = ({
  t,
  cancel = () => false,
  termination = () => false,
  title = "",
  sub = "", disable = false

}) => {
  const language = localStorage.getItem("i18nextLng")
  const classes = useStyles()
  return (
    <div className={language === "ar" ? classes.proceedDialogArabic : classes.proceedDialog}>
      <Box style={{ marginInlineEnd: "8px" }}>
        <Typography className={classes.convert}>{title}</Typography>
        <Typography className={classes.convertProcess}>
          {sub}
        </Typography>
        <Box display="flex" alignItems="center" className={classes.marginTop}>
          <Button className={classes.yes} onClick={termination} disabled={disable}>
            {t("yes_agreement")}
          </Button>
          <Button className={classes.No} onClick={cancel}>
            {t("No_agreement")}
          </Button>
        </Box>
      </Box>
    </div>
  )
}