import React from "react"

export const ParkIcon = () =>(
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_112499" data-name="Group 112499" transform="translate(-154 -2545)">
    <g id="Rectangle_57296" data-name="Rectangle 57296" transform="translate(154 2545)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="32" height="32" stroke="none"/>
      <rect x="0.5" y="0.5" width="31" height="31" fill="none"/>
    </g>
    <path id="icons8-lake_1_" data-name="icons8-lake (1)" d="M17.668,5.016a2.162,2.162,0,0,0-1.95,1.195,1.153,1.153,0,0,0-.049.116l-1.68,4.715A2.161,2.161,0,0,0,15.949,14.1H16.6v2.45a1.05,1.05,0,1,0,2.1,0V14.1h.651a2.161,2.161,0,0,0,1.965-3.062L19.637,6.327a1.153,1.153,0,0,0-.051-.116A2.162,2.162,0,0,0,17.668,5.016ZM26.75,7.1a3.85,3.85,0,0,0-1.05,7.55v3.3a1.05,1.05,0,1,0,2.1,0v-3.3A3.85,3.85,0,0,0,26.75,7.1Zm-16.878,7.7A5.717,5.717,0,0,0,4,20.522v2.332a8.76,8.76,0,0,0,8.75,8.75H26.4a5.6,5.6,0,1,0,0-11.2H20.918a8.325,8.325,0,0,1-6.628-3.313,5.717,5.717,0,0,0-4.417-2.285Zm-.3,7.7H12.4a.7.7,0,1,1,0,1.4H9.6a.7.7,0,1,1-.029-1.4Zm12.6,2.1H25A.7.7,0,1,1,25,26H22.2a.7.7,0,0,1-.029-1.4Zm-7.7,2.1H17.3a.7.7,0,0,1,0,1.4H14.5a.7.7,0,1,1-.029-1.4Z" transform="translate(152 2542.69)" fill="#5ac782"/>
  </g>
</svg>
)