import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { RemarksPopupStyle } from './style'

export const RemarksPopup = ({ t, close = () => false, handleUpdate = () => false }) => {
    const classes = RemarksPopupStyle()
    const [remark, setRemark] = React.useState("")
    return (
        <Box p={2}>
            <Stack spacing={1}>
                <Typography className={classes.title}>{t("Remarks")}:</Typography>
                <TextField id="outlined-basic" multiline value={remark} onChange={(value) => setRemark(value.target.value)}
                    rows={3} variant="outlined" placeholder={t("Type here...")} />
            </Stack>
            <Stack direction={"row"} mt={3}>
                <Button variant='outlined' fullWidth className={classes.closebtn} onClick={close}>{t("Close")}</Button>
                <Button variant='contained' fullWidth className={classes.updatebtn} onClick={()=>handleUpdate(remark)}>{t("Update")}</Button>
            </Stack>
        </Box>
    )
}