import { Box, Button, Typography } from "@mui/material";
import { SendLink } from "../../../assets";
import { componantsStyles } from "./styles";

export const SendPaymentLink = ({ onClose = () => false, email_id = "", mobile = "", t }) => {
    const classes = componantsStyles()
    return (
        <Box p={3}>
            <Box>
                <center>
                    <SendLink />
                    <Typography className={classes.titleText}>{t("Payment Link Sent successfully")}</Typography>
                    <Typography className={classes.sendLink}>{t("Payment link sent to")} {mobile} & {email_id}</Typography>
                </center>
                <Button onClick={onClose} variant="contained" fullWidth>{t("close")}</Button>
            </Box>
        </Box>
    )
}