import { Box, Typography } from "@mui/material";
import { TableWithPagination } from "../../../components";
import { genInvoiceheading, genInvoicePath, genInvoiceRows, Scheduleheading, SchedulePath } from "../../../utils/quotations";
import { tabStyles } from "./styles";
export const Invoices = ({ scheduled_invoices = [] }) => {
    const classes = tabStyles()
    return (
        <div>
            {/*scheduled invoice table */}
            <Box p={2} className={classes.card}>
                <Typography className={classes.title}>SCHEDULED INVOICES</Typography>
                <TableWithPagination
                    heading={Scheduleheading}
                    rows={scheduled_invoices}
                    path={SchedulePath}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    tableType="no-side"
                    dataType={[
                        { type: ["text"], name: "id" },
                        { type: ["text"], name: "name" },
                        { type: ["date"], name: "from" },
                        { type: ["date"], name: "to" },
                        { type: ["date"], name: "due_date" },
                        { type: ["text"], name: "amount" },

                    ]}
                    totalRowsCount={5}
                    height={`calc(100vh - 500px)`}
                    view={true}
                    edit={true}
                    delete={true} />
            </Box>
            <br />
            {/*generated invoice table */}
            <Box p={2} className={classes.card}>
                <Typography className={classes.title}>GENERATED INVOICES</Typography>
                <TableWithPagination
                    heading={genInvoiceheading}
                    rows={genInvoiceRows}
                    path={genInvoicePath}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    tableType="no-side"
                    dataType={[
                        { type: ["text"], name: "invoice_no" },
                        { type: ["date"], name: "raised" },
                        { type: ["date"], name: "due_date" },
                        { type: ["payment_status"], name: "status" },
                        { type: ["text"], name: "pending_amount" },
                        { type: ["text"], name: "total_amount" },
                    ]}
                    totalRowsCount={5}
                    height={`calc(100vh - 500px)`}
                    view={true}
                    edit={true}
                    delete={true} />
            </Box>

        </div>
    )
}