import * as React from "react"
const LinkIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props?.width ?? 24} height={24} {...props}>
    <g data-name="Group 112375">
      <path
        fill="#4e5a6b"
        d="M5.214 12.928a4.546 4.546 0 0 0 .33 6.728 4.678 4.678 0 0 0 6.236-.438l1.79-1.79a.909.909 0 1 0-1.285-1.286l-1.819 1.819a2.83 2.83 0 0 1-3.86.208 2.725 2.725 0 0 1-.107-3.956l1.93-1.929A.909.909 0 0 0 7.142 11Zm5.785-5.786a.909.909 0 0 0 1.286 1.286l1.82-1.82a2.83 2.83 0 0 1 3.858-.207 2.725 2.725 0 0 1 .107 3.956l-1.928 1.928a.909.909 0 1 0 1.286 1.286l1.928-1.929a4.546 4.546 0 0 0-.33-6.729 4.678 4.678 0 0 0-6.236.439Zm-1.928 7.07a.909.909 0 1 0 1.285 1.286l5.143-5.143a.909.909 0 1 0-1.285-1.285Z"
        data-name="icons8-link (2)"
      />
    </g>
  </svg>
)
export default LinkIcon
