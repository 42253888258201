import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../../utils";
export const StatementOfAccountComponentStyles = makeStyles((theme) => ({
    dividerStyle: {
        height: "40px"
    },
    filterIconBox: {
        textAlign: "end",
        cursor: "pointer"
    },
    menutext: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.palette.secondary.main
    },
    alt_divider: {
        display: "none"
    },
    divider_menu: {
        border: "1px solid #EBEEF3",
        margin: "3px 0px"
    },
    menuparent: {
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: "0px",
        paddingBottom: "0px",
    },

    btngrp: {
        display: "flex",
        justifyContent: "end",

    },
    btn_outlined: {
        backgroundColor: theme.palette.background.tertiary1,
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: Bold,
        marginRight: "10px",
        marginLeft: "10px",
        '&:hover': {
            backgroundColor: theme.palette.background.tertiary1,
            border: `1px solid ${theme.palette.border.secondary}`,
        }
    },
    btn_contained: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        marginRight: "3px",
        marginLeft: "3px",
    },
    userCard: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5px"
    },
    userName: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    userDetails: {
        marginLeft: "12px"
    },
    mail: {
        fontSize:"0.75rem",
        fontFamily: Regular,
        color: theme.typography.color.secondary
    },
    dot: {
        width: "6px",
        height: "6px",
        backgroundColor: theme.palette.border.primary,
        marginRight: "5px",
        marginLeft: "5px",
        borderRadius: "50%",
        marginTop: "5px"
    },
    userCardParent: {
        marginTop: "25px"
    },
    imgtext: {
        padding: "15px 20px",
        textTransform: "uppercase",
        fontFamily: Bold,
        fontSize:"0.75rem",
        color: theme.typography.color.secondary
    },
    selectedImg: {
        width: "150px",
        height: "150px",
        borderRadius: "4px",
        marginRight: "10px",
        marginLeft: "10px"
    },
    editResources: {
        padding: "20px"
    },
    editUser: {
        padding: "10px",
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: theme.palette.borderRadius,
        marginTop: "10px"
    },
    editTitle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary,
        // marginBottom:"10px"
    },
    edituserDetails: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px"
    },
    editcontent: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.typography.color.primary,
    },
    editImage: {
        width: 330,
        height: 250,
        borderRadius: theme.palette.borderRadius
    },
    subImage: {
        width: 122,
        height: 122,
        borderRadius: theme.palette.borderRadius
    },
    tools: {
        border: `1px solid ${theme.palette.border.primary}`,
    },
    subtransImage: {
        position: "absolute",
        bottom: 0,
        top: 40,
        opacity: 0.6,
        fontFamily: Bold,
        fontSize: "30px",
        display: "flex",
        justifyContent: "center",
        color: "white",
        width: 122,
        height: 122

    },

    mainImg: {
        height: "210px",
        width: "100%",
        borderRadius: "4px"
    },
    subImg: {
        height: "100px",
        width: "100%",
        borderRadius: "4px"
    },
    fromSection: {
        height: `450px`,
        overflow: "auto"
    },
    userDetailsCard: {
        border: "1px solid #CED3DD",
        borderRadius: "4px",
        padding: "8px",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5px"
    },
    formRoot: {
        padding: "0px 8px"
    },
    downBtn: {
        border: `1px solid ${theme?.palette.border.secondary}`,
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "white",
            border: `1px solid ${theme?.palette.border.secondary}`,
        }
    }

}))