import * as React from "react"

const CreditNotesSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 102948" transform="translate(-11563 -17154)">
      <circle
        data-name="Ellipse 129451"
        cx={24}
        cy={24}
        r={24}
        transform="translate(11563 17154)"
        fill="#f0f9f7"
      />
      <g data-name="Group 102947">
        <path
          data-name="Rectangle 54909"
          fill="none"
          d="M11575 17166h24v24h-24z"
        />
        <path
          data-name="icons8-receive-cash (1)"
          d="M11596.161 17176.9a5.462 5.462 0 0 0-2 .842c-.871.452-1.676.882-2.187 1.2a2.626 2.626 0 0 1-2.563 3.231h-3.289a.659.659 0 1 1 0-1.317h3.293a1.312 1.312 0 0 0 .865-2.3 1.959 1.959 0 0 0-1.084-.331h-2.233a9.579 9.579 0 0 1-2.2-.245 6.412 6.412 0 0 0-1.569-.19c-3.219 0-5.057 3.952-5.133 4.12a.658.658 0 0 0 .069.664l3.07 4.16a.655.655 0 0 0 .53.266.661.661 0 0 0 .639-.492 1.654 1.654 0 0 1 1.558-1.262 21.383 21.383 0 0 1 2.6.217 21.654 21.654 0 0 0 2.668.222c1.213 0 2.086-.93 4.61-3.512 3.206-3.28 3.512-3.567 3.512-4.217a1.1 1.1 0 0 0-1.156-1.056Zm-2.8-5.71a2.2 2.2 0 0 1-1.4 2.05 3.744 3.744 0 0 0-2.841-2.84 2.2 2.2 0 0 1 4.245.79Zm-5.049 0a2.854 2.854 0 1 0 2.854 2.854 2.854 2.854 0 0 0-2.849-2.844Z"
          fill="#43aa8b"
        />
      </g>
    </g>
  </svg>
)

export default CreditNotesSvg
