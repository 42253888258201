import { Box, Grid, Stack } from '@mui/material'
import React from 'react'
import { CalendarDateChange, LayoutSwitch } from '../../../components'
import { CalendarHeaderStyle } from './style'
import { CalendarDropDown } from '../components'
import { AmenitiesBookingContext } from '../amenitiesBookingContext'
import { CustomDropdown } from './customDropdown'

export const CalendarHeader = (
    {
        selected = {},
        weekprev = () => false,
        weeknext = () => false,
        monthnext = () => false,
        monthprev = () => false,
        month = "",
        weekdates = {},
        setDropdownValue = () => false,
        setselect = () => false,
        select = {},
        showAll = () => false,
        open = {},
        daily = "",
        nextdate = () => false,
        prevdate = () => false,
        amenityCategoryList = [],
        selectedAmenityCategory = {},
        handleAmenityCategoryChange = () => false,
        amenityList = [],
        selectedAmenity = {},
        selectedAmenityView = {},
        // handleAmenityMasterChange = () => false,
        handleAmenityViewChange = () => false,
        t = () => false,
    }
) => {
    const classes = CalendarHeaderStyle()

    const { amenities, handleAmenityMasterChange, handleDescriptionChange } = React.useContext(AmenitiesBookingContext)

    return (
        <Box p={"16px 24px 8px 24px"}>
            <Grid container alignItems={"center"} spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <CustomDropdown
                                t={t}
                                list={amenities?.amenityCategory}
                                selected={amenities?.selectedAmenityCategory}
                                handleChange={handleAmenityCategoryChange}
                                disableView
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <CustomDropdown
                                t={t}
                                list={amenities?.amenityMaster}
                                selected={amenities?.selectedAmenityMaster}
                                handleChange={handleAmenityMasterChange} 
                                master />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <CustomDropdown
                                t={t}
                                list={amenities?.amenityDescription}
                                selected={amenities?.selectedAmenityDescription}
                                handleChange={handleDescriptionChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} className={classes.dateChange}>
                    <Box>
                        {
                            selected === "Weekly" &&
                            <CalendarDateChange state={weekdates} prev={weekprev} next={weeknext} comp={"weekly"} />
                        }
                        {
                            selected === "Monthly" &&
                            <CalendarDateChange state={{ Date: month }} prev={monthprev} next={monthnext} comp={"month"} />
                        }
                        {
                            selected === "Daily" &&
                            <CalendarDateChange state={{ Date: daily }} prev={prevdate} next={nextdate} comp={"daily"} />
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Stack direction={"row"} alignItems={"center"} sx={{ float: "right" }} spacing={1}>
                        <CalendarDropDown t={t} open={open} selected={selected} showAll={showAll} setDropdownValue={setDropdownValue} />
                        <LayoutSwitch setselect={setselect} select={select} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}