import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { UseDebounce } from "..";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods, ValidateEmail } from "../../utils";
import { AddContact } from "../quotationContact/steps/addContact";
const initialState = {
    profilePic: "",
    name: "",
    email: "",
    mobile: "",
    alternativemobile: "",
    telephone: "",
    fax: "",
    nationality: "",
    gender: "",
    files: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    idType: "",
    idNo: "",
    idExpire: "",
    address: "",
    passportNo: "",
    visaNo: "",
    passportExipre: "",
    contact_id: "",
    proofFile: [],
    error: {
        name: "",
        email: "",
        mobile: "",
        nationality: "",
        gender: "",
        idType: "",
        idNo: "",
        idExpire: "",
        passportNo: "",
        visaNo: "",
        passportExipre: "",
        proofFile: ""
    }
}
export const NewUser = ({ t, lead_id, company_id, revenue_type, onClose = () => false, CheckContact = () => false }) => {
    const debounce = UseDebounce()
    const [contact, setContact] = React.useState({ ...initialState });
    const [disable, setDisable] = React.useState(false);
    const alert = React.useContext(AlertContext);
    //validate contact
    const validateContact = () => {
        let isValid = true;
        let error = contact.error;
        //validate name
        if (contact?.name?.length === 0) {
            isValid = false;
            error.name = t("Name is Required");
        }
        //validate email
        if (contact?.email?.length === 0) {
            isValid = false;
            error.email = t("Email ID is Required");
        }
        //validate email
        if (contact?.email?.length > 0 && ValidateEmail(contact?.email) === false) {
            isValid = false;
            error.email = t("Invalide Email ID");
        }
        //validate phone
        if (contact?.mobile?.length === 0) {
            isValid = false;
            error.mobile = t("Phone Number is Required");
        }
        //validate proof
        if (contact?.proofFile?.length === 0) {
            isValid = false;
            error.proofFile = t("Atleast upload one proof is Required");
        }

        setContact({ ...contact, error });
        return isValid;
    }
    //check existing contact
    const checkExsits = (type, value, msg) => {
        setDisable(false)
        let datas = {
            tenantId: `${config.tenantId}`,
            [type]: value
        }
        NetworkCall(
            `${config.api_url}/contact/findContactExisting`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.is_Exist && value?.length > 0) {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.info,
                        msg: `${msg} ${t("is already exists")}`,
                    });
                    setDisable(true)
                } else {
                    setDisable(false)
                }

            })
            .catch((error) => {
                console.log(error)
            });
    }
    //update state of contact
    const updateState = (key, value) => {
        if (key === 'email') {
            debounce(() => checkExsits("email_id", value, 'Email Id'), 1000);
        }
        if (key === "mobile") {
            debounce(() => checkExsits("mobile_no", value?.mobile, 'Mobile Number'), 1000);
        }
        let error = contact.error;
        error[key] = "";
        setContact({ ...contact, [key]: value, error });
    };
    //create contact
    const createContact = () => {
        setDisable(true)
        let proof = contact?.proofFile?.filter((val) => val?.is_active).map(val => {
            return {
                proof_id: val?.type?.value,
                proof_type: val?.type?.proof_type,
                kyc_type: "Contact",
                name: val?.name,
                number: val?.id_no,
                valid_from: val?.valid_from,
                valid_to: val?.valid_to,
                url: val?.file,
                is_mandatory: val?.mandatry_verify,
                country_id: val?.issuing_country?.value,
                is_active: true
            }
        })
        const formData = new FormData();
        formData.append("first_name", `${contact?.name}`);
        formData.append("lead_id", `${lead_id}`);
        formData.append("email_id", `${contact?.email}`);
        formData.append("mobile_no", `${contact?.mobile?.mobile}`);
        formData.append("mobile_no_country_code", `${contact?.mobile?.mobile_code}`);
        formData.append("alternative_mobile", `${contact?.alternativemobile?.mobile}`);
        formData.append("alternative_mobile_country_code", `${contact?.alternativemobile?.mobile_code}`);
        formData.append("phone", `${contact?.telephone?.mobile}`);
        formData.append("phone_code", `${contact?.telephone?.mobile_code}`);
        formData.append("fax_no", `${contact?.fax?.mobile}`);
        formData.append("nationality", `${contact?.nationality}`);
        formData.append("image_url", `${contact?.profilePic?.src}`);
        formData.append("facebook", `${contact?.facebook}`);
        formData.append("twitter", `${contact?.twitter}`);
        formData.append("linkedin", `${contact?.linkedin}`);
        formData.append("street_1", `${contact?.address?.addressLineOne}`);
        formData.append("street_1", `${contact?.address?.addressLineTwo}`);
        formData.append("landmark", `${contact?.address?.landmark}`);
        formData.append("country", `${contact?.address?.country}`);
        formData.append("state", `${contact?.address?.state}`);
        formData.append("district", `${contact?.address?.district}`);
        formData.append("proof", JSON.stringify(proof));
        formData.append("company_id", `${company_id}`)
        formData.append("revenue_type", `${revenue_type}`)
        NetworkCall(
            `${config.api_url}/contact/add`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
        )
            .then((response) => {
                CheckContact(response?.data?.data?.result)
                setDisable(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Contact Successfully Created"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            })
            .catch((error) => {
                console.log(error);
                setDisable(false)
            });
    }
    //onsubmit
    const onSubmit = () => {
        if (validateContact()) {
            createContact()
        }
    }
    return (

        <Box p={2}>
            <Box height="400px" overflow="auto">
                <AddContact data={contact} contact={contact} updateState={updateState} />
            </Box>
            <Stack direction="row" justifyContent="space-between">
                <Button onClick={onClose} variant="outlined">{t("Close")}</Button>
                <Button disabled={disable} variant="contained" onClick={onSubmit}>{t("Create New Contact")}</Button>
            </Stack>
        </Box>
    )
}