import styled from '@mui/material/styles/styled';

export const CustomPaper = styled('div', {
    shouldForwardProp: prop => prop !== "borderRadius" && prop !== "padding" && prop !== "boxShadow" && prop !== "border" && prop !== "height" && prop !== "marginBottom" && prop !== "backgroundColor"
})(({ borderRadius = 12, padding = 12, boxShadow = "0px 0px 16px #00000014", border, height, marginBottom, backgroundColor }) => ({
    borderRadius,
    padding,
    boxShadow,
    border,
    height,
    marginBottom,
    backgroundColor
}))