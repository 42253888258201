
import { Box, Button } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { initialState, productType, StatusOptionList } from "../.././utils/inventory"
import { FormGenerator } from "../../components"
import { config } from "../../config"
import { AlertContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { AlertProps, allowed_file_size, assestType, multiFileUpload, NetWorkCallMethods } from "../../utils"
import { FIleUpload } from "./fileUpload"
import { InspectionMasterStyles } from "./style"

const AddPopup = ({ t, isEdit = false, companyId = "", setIsOpen = "", editData = {}, reload = () => false }) => {
    const [img, setImg] = React.useState([]);
    const [data, setData] = React.useState({ ...initialState })
    const alert = React.useContext(AlertContext);
    const classes = InspectionMasterStyles()
    //initialLoad
    React.useEffect(() => {
        if (isEdit === true) {
            setImg(editData.assets)
            setData({
                ...data,
                id: editData?.id,
                type: editData?.type,
                is_active: editData?.is_active,
                description: editData?.description,
                name: editData?.name
            });
        }
        // eslint-disable-next-line
    }, [])
    //validateForm
    const validateForm = () => {
        let isValid = true;
        let error = data.error;

        if (data?.name?.length === 0) {
            isValid = false;
            error.name = `${t("productName")} ${t("isRequired")}`;
        }
        if (data?.type?.length === 0) {
            isValid = false;
            error.type = `${t("productType")} ${t("isRequired")}`;
        }
        if (data?.is_active?.length === 0) {
            isValid = false;
            error.status = `${t("status")} ${t("isRequired")}`;
        }
        setData({ ...data, error });
        return isValid;
    };
    // img upload func
    const updateImgState = async (data, priority) => {
        const uploaded_files = await multiFileUpload(data, { type: assestType?.General_Images }, alert, allowed_file_size, priority = false)
        if (uploaded_files?.length > 0) {
            let dataImage = ([...img, ...uploaded_files])
            setImg(dataImage)
        }
    }
    //Form Submit
    const handleSubmit = () => {
        if (validateForm()) {
            const payload = {
                company_id: companyId,
                type: data.type,
                name: data.name,
                description: data.description,
                is_active: data.is_active,
                assets: img,
                id: data?.id ?? undefined
            }
            NetworkCall(
                `${config.api_url}/inventory/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    setIsOpen(false)
                    reload()
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: `${t("Item Successfully")} ${isEdit === true ? t("Updated") : t("Created")}`,
                    });

                }).catch((err) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: `${t("Cannot Be")} ${isEdit === true ? t("Updated") : t("Created")}`,
                    });
                })
        }
        else {
            return false
        }
    }
    // uploaded img close func
    const handleImgClose = (value) => {
        const delImg = img.filter((i) => {
            return (
                i.id !== value.id
            )
        })
        setImg(delImg)
    }
    //handle state update
    const updateState = (k, v) => {
        let error = data.error;
        error[k] = "";
        setData({ ...data, [k]: v, error })

    }



    const changeInput = [
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            component: "text",
            isActive: true,
            label: t("productName"),
            placeholder: t("enterProductName"),
            value: data?.name,
            onChange: (e) => updateState("name", e.target.value),
            error: data?.error?.name,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("productType"),
            placeholder: t("productType"),
            value: data?.type,
            onChange: (value) => updateState("type", value),
            error: data?.error?.type,
            isRequired: true,
            options: productType
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            component: "text",
            isActive: true,
            label: t("description"),
            placeholder: t("enterDescription"),
            value: data?.description,
            onChange: (e) => updateState("description", e.target.value),
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6
            },
            isActive: true,
            component: "toggle",
            label: t("status"),
            placeholder: t("status"),
            value: data?.is_active,
            onChange: (value) => updateState("is_active", value),
            error: data?.error?.status,
            isRequired: true,
            options: StatusOptionList
        },
    ]


    return (
        <Box className={classes.inventory_head}>
            <Box p={2} className={classes.formRoot}>
                <FormGenerator t={t} components={changeInput} />
                {/* ImageUpload */}
                <Box>
                    <FIleUpload updateImgState={updateImgState} img={img} setImg={setImg} handleImgClose={handleImgClose} />
                </Box>
                {/* buttons */}

            </Box>
            <Box display="flex" className={`${classes.btngrp}`}>
                <Button variant="outlined" className={`${classes.btn_outlined}`} onClick={() => setIsOpen(false)}>{t("Cancel")}</Button>
                <Button variant="contained" className={`${classes.btn_contained}`} onClick={() => handleSubmit()}>{isEdit === true ? t("Save Changes") : t("Add Item")}</Button>

            </Box >
        </Box >
    )
}
export default withNamespaces("inspection")(AddPopup)