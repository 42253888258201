export const VacantPeriodView = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98343">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <path
                    data-name="Path 94722"
                    d="M21.364 8h-1.4v1.4a.468.468 0 0 1-.935 0V8h-7.478v1.4a.468.468 0 0 1-.935 0V8h-1.4A1.287 1.287 0 0 0 8 9.4v1.684h14.953V9.4A1.577 1.577 0 0 0 21.364 8ZM8 12.065v8.551a1.268 1.268 0 0 0 1.262 1.4h12.15A1.577 1.577 0 0 0 23 20.617v-8.552Zm4.159 7.85h-1.122a.369.369 0 0 1-.374-.374v-1.167a.369.369 0 0 1 .374-.374h1.169a.369.369 0 0 1 .374.374v1.168a.409.409 0 0 1-.421.374Zm0-4.206h-1.122a.369.369 0 0 1-.374-.374v-1.167a.369.369 0 0 1 .374-.374h1.169a.369.369 0 0 1 .374.374v1.168a.409.409 0 0 1-.421.374Zm3.741 4.207h-1.171a.369.369 0 0 1-.374-.374v-1.168a.369.369 0 0 1 .374-.374H15.9a.369.369 0 0 1 .374.374v1.168a.344.344 0 0 1-.374.374Zm0-4.206h-1.171a.369.369 0 0 1-.374-.374v-1.168a.369.369 0 0 1 .374-.374H15.9a.369.369 0 0 1 .374.374v1.168a.344.344 0 0 1-.374.374Zm3.738 4.206h-1.171a.369.369 0 0 1-.374-.374v-1.168a.369.369 0 0 1 .374-.374h1.168a.369.369 0 0 1 .374.374v1.168a.344.344 0 0 1-.373.374Zm0-4.206h-1.171a.369.369 0 0 1-.374-.374v-1.168a.369.369 0 0 1 .374-.374h1.168a.369.369 0 0 1 .374.374v1.168a.344.344 0 0 1-.373.374Z"
                    fill={props?.fill ?? "#c1c5cb"}
                />
            </g>
        </g>
    </svg>
)
