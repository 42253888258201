import * as React from "react"
export const AgreementDeclined = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={73.804}
    height={61.869}
    {...props}
  >
    <defs>
      <filter
        id="a"
        width={51.09}
        height={61.869}
        x={11.416}
        y={0.001}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur result="blur" stdDeviation={3} />
        <feFlood floodOpacity={0.102} />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 118719">
      <path
        fill="#f5f5f5"
        d="M65.52 55.308c0 .923-12.813 1.671-28.62 1.671s-28.62-.748-28.62-1.671 12.813-1.671 28.62-1.671 28.62.748 28.62 1.671"
        data-name="Path 102004"
      />
      <path fill="none" d="M0 1.979h73.804v55H0z" data-name="Rectangle 58722" />
      <g data-name="Group 118715" transform="rotate(-19 -40099.373 42633.242)">
        <rect
          width={14}
          height={14}
          fill="#f5f7fa"
          data-name="Rectangle 57697"
          rx={7}
          transform="translate(11701 15390)"
        />
        <g data-name="Group 114509">
          <path
            fill="#c1c5cb"
            d="M11708 15393.5a1.75 1.75 0 1 0 1.75 1.75 1.754 1.754 0 0 0-1.75-1.75Zm-2.012 4.2a.792.792 0 0 0-.788.788v.212a1.587 1.587 0 0 0 .824 1.294 3.683 3.683 0 0 0 1.976.506 3.683 3.683 0 0 0 1.976-.509 1.587 1.587 0 0 0 .824-1.291v-.21a.792.792 0 0 0-.787-.788Z"
            data-name="icons8-account (2)"
          />
        </g>
      </g>
      <g data-name="Group 118716" transform="rotate(18.01 841.493 -1.868)">
        <circle
          cx={7}
          cy={7}
          r={7}
          fill="#f5f7fa"
          data-name="Ellipse 129562"
          transform="translate(103 277)"
        />
        <g data-name="Group 105249">
          <path
            fill="none"
            d="M105.8 279.8h8.4v8.4h-8.4z"
            data-name="Rectangle 33"
          />
          <path
            fill="#c1c5cb"
            d="M111.49 280.849a.314.314 0 0 0-.218.109l-.2.23a.647.647 0 0 0-.1-.013.632.632 0 0 0-.5.218l-3.684 4.241a.471.471 0 0 0-.107.215l-.178.862a.16.16 0 0 0 .051.151.163.163 0 0 0 .105.04.2.2 0 0 0 .053-.008l.835-.288a.472.472 0 0 0 .2-.139l.437-.5a.878.878 0 0 1 .775-1.294 1.759 1.759 0 0 1 .32.038l2.148-2.462a.658.658 0 0 0 .131-.643l.2-.225a.318.318 0 0 0-.268-.532Zm-2.381 1.632-.76.217a1.128 1.128 0 0 0-.545.346l-1.117 1.285a.769.769 0 0 0-.184.565.711.711 0 0 0 .16.4Zm2.288.285-1.753 2.013a2.5 2.5 0 0 0 .492.054.732.732 0 0 0 .522-.247.159.159 0 0 1 .264.175 1.033 1.033 0 0 1-.783.388 3.194 3.194 0 0 1-.667-.08l-.133-.02a2.38 2.38 0 0 0-.384-.054.557.557 0 0 0-.516.367.55.55 0 0 0 .3.7l1.048.484a.323.323 0 0 1 .04.021l.027.016a2.334 2.334 0 0 0 1.184.325 2.364 2.364 0 0 0 .854-.14l.054-.019a3.036 3.036 0 0 1 1.155-.159h.159a.239.239 0 0 0 .24-.241v-2.233a.238.238 0 0 0-.22-.239h-.019a5.51 5.51 0 0 1-1.822-1.076Z"
          />
        </g>
      </g>
      <g data-name="Group 118707" transform="translate(-228.76 -174.796)">
        <g filter="url(#a)" transform="translate(228.76 174.8)">
          <g
            fill="#fff"
            stroke="#e9ecef"
            data-name="Rectangle 58709"
            transform="translate(21.42 7)"
          >
            <rect width={31.09} height={41.869} stroke="none" rx={1} />
            <rect
              width={32.09}
              height={42.869}
              x={-0.5}
              y={-0.5}
              fill="none"
              rx={1.5}
            />
          </g>
        </g>
        <rect
          width={28.039}
          height={13.018}
          fill="#ffecec"
          data-name="Rectangle 58710"
          rx={1}
          transform="translate(251.74 183.655)"
        />
        <rect
          width={28.039}
          height={1.001}
          fill="#e4e8ee"
          data-name="Rectangle 58711"
          rx={0.501}
          transform="translate(251.74 202.677)"
        />
        <rect
          width={5.007}
          height={1.001}
          fill="#e4e8ee"
          data-name="Rectangle 58720"
          rx={0.501}
          transform="translate(251.74 197.674)"
        />
        <rect
          width={6.008}
          height={1.001}
          fill="#e4e8ee"
          data-name="Rectangle 58721"
          rx={0.501}
          transform="translate(273.771 197.674)"
        />
        <rect
          width={28.039}
          height={1.001}
          fill="#e4e8ee"
          data-name="Rectangle 58712"
          rx={0.501}
          transform="translate(251.74 205.688)"
        />
        <rect
          width={28.039}
          fill="#e9ecef"
          data-name="Rectangle 58713"
          transform="translate(251.74 202.681)"
        />
        <rect
          width={28.039}
          fill="#e9ecef"
          data-name="Rectangle 58714"
          transform="translate(251.74 206.687)"
        />
        <rect
          width={28.039}
          height={1.001}
          fill="#e4e8ee"
          data-name="Rectangle 58715"
          rx={0.501}
          transform="translate(251.74 207.69)"
        />
        <rect
          width={28.039}
          height={1.001}
          fill="#e9ecef"
          data-name="Rectangle 58716"
          rx={0.501}
          transform="translate(251.74 209.692)"
        />
        <rect
          width={28.039}
          fill="#e4e8ee"
          data-name="Rectangle 58717"
          transform="translate(251.74 209.691)"
        />
        <rect
          width={28.039}
          height={1.001}
          fill="#e4e8ee"
          data-name="Rectangle 58718"
          rx={0.501}
          transform="translate(251.74 211.694)"
        />
        <g data-name="Group 118744">
          <g data-name="Group 118708">
            <path
              fill="#276add"
              d="m268.478 218.425-.018.005h.024Z"
              data-name="Path 101998"
            />
            <g data-name="Group 118707">
              <path
                fill="#276add"
                d="M269.484 218.43c-.2-.409-1.033-.424-1.408-.406a11.49 11.49 0 0 0-2.3.428.181.181 0 0 0-.2.049l-.07.037a.1.1 0 0 0-.036-.034.186.186 0 0 0-.225-.09 1.126 1.126 0 0 0-.3.167 1.207 1.207 0 0 0-.1.1c.027-.089.1-.341.125-.433.043-.164-.367-.19-.418-.056l-.133.348-.043.111a1.384 1.384 0 0 1-.082.146 1.082 1.082 0 0 0-.056-.339.209.209 0 0 0-.025-.046c-.034-.114-.1-.154-.179-.132a.151.151 0 0 0-.07.056 3.362 3.362 0 0 0 .017-1.115.187.187 0 0 0-.015-.045 2.283 2.283 0 0 0 .024-.236v-.009a.1.1 0 0 0-.035-.139.1.1 0 0 0-.151.051 13.385 13.385 0 0 1-1.059 1.366c-.143.167-.281.322-.4.448-.046.044-.093.088-.137.134a.224.224 0 0 0-.018.023.693.693 0 0 1-.186.145.105.105 0 0 0 .063.2.617.617 0 0 1 .271-.032.183.183 0 0 0 .158-.061c.259-.273.556-.5.821-.772a2.453 2.453 0 0 0 .3-.385c-.024.312-.092.623-.125.932-.008.071-.011.142-.014.213-.042.3-.08.6-.109.845a1.562 1.562 0 0 0-.046.294.158.158 0 0 0 .014.07.081.081 0 0 0 .033.05.2.2 0 0 0 .3.009 2.15 2.15 0 0 0 .286-.771c.02-.069.04-.137.061-.2a.185.185 0 0 0 .178.031.82.82 0 0 0 .247-.163.2.2 0 0 0 .152.067.684.684 0 0 0 .479-.27.105.105 0 0 0 .068.029.148.148 0 0 0 .042 0c.127 0 .512-.031.589-.183a.223.223 0 0 0 .05-.007 18.192 18.192 0 0 1 1.706-.365 3.51 3.51 0 0 1 .953-.061c.055-.017.116-.035.006 0-.091.027-.066.018-.024 0-.544.109-1.772.464-2.315.652-.246.086-.493.178-.734.277a2.723 2.723 0 0 0-.689.322.188.188 0 0 0-.085.217c.008.164.148.221.331.138 1.018-.464.439-.206 1.37-.588.508-.209 1.085-.352 1.611-.513a4.229 4.229 0 0 1 .732-.154c.314-.04.466-.013.694-.056.133-.028.156-.191.106-.294Z"
                data-name="Path 101999"
              />
            </g>
          </g>
        </g>
        <path
          fill="#ff4b4b"
          d="M265.721 185.163a5 5 0 1 0 5 5 5.008 5.008 0 0 0-5-5Zm0 1a3.968 3.968 0 0 1 2.443.85l-5.593 5.593a3.984 3.984 0 0 1 3.15-6.443Zm3.15 1.557a3.984 3.984 0 0 1-5.593 5.593Z"
        />
      </g>
    </g>
  </svg>
)
