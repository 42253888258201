import React from "react"

export const ScheduledJobAsset = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.16" viewBox="0 0 24 24.16">
            <g id="Group_118506" data-name="Group 118506" transform="translate(-197 -106)">
                <g id="Rectangle_52035" data-name="Rectangle 52035" transform="translate(197 106)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-person-calendar" d="M17.143,15.429a5.714,5.714,0,1,1,5.714-5.714A5.721,5.721,0,0,1,17.143,15.429Zm1.143,3.429v-.571A1.714,1.714,0,0,1,20,16.571h8a1.714,1.714,0,0,1,1.714,1.714v.571ZM29.714,20v6.286A1.714,1.714,0,0,1,28,28H20a1.714,1.714,0,0,1-1.714-1.714V20Zm-8,5.429a.857.857,0,1,0-.857.857A.857.857,0,0,0,21.714,25.429Zm0-2.857a.857.857,0,1,0-.857.857A.857.857,0,0,0,21.714,22.571Zm3.143,2.857a.857.857,0,1,0-.857.857A.857.857,0,0,0,24.857,25.429Zm0-2.857a.857.857,0,1,0-.857.857A.857.857,0,0,0,24.857,22.571Zm3.143,0a.857.857,0,1,0-.857.857A.857.857,0,0,0,28,22.571ZM17.143,26.286v-8a2.855,2.855,0,0,1,.058-.571H10.571A2.575,2.575,0,0,0,8,20.286v.686c0,3.3,4.016,5.886,9.143,5.886l.058,0A2.842,2.842,0,0,1,17.143,26.286Z" transform="translate(190.442 102.16)" fill="#6d80b3" />
            </g>
        </svg>

    )
}