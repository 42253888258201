import { Box, Grid, Stack, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import { LoadingSection } from '../../../components';
import { MonthlyCalendarStyles, eventListStyle } from './style';
import { SwitchColors } from './weeklyCalendar';

export const MonthlyCalendar = ({ dates = {},
    month = {}, data = [],
    openDeclinePopup = () => false, loading = false,
    handleSelected = () => false, t,showDailyCalendar=()=>false }) => {
    const classes = MonthlyCalendarStyles()
    const days = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",

    ]
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));

    // const handleClick = (e) => {
    //     handleSelected(e)
    // }

    return (
        <Box>
            <Grid container>
                {
                    days.map(e => {
                        return (
                            <Grid item xs={1.7} textAlign={"center"} p={1}>
                                <span className={classes.days}> {e} </span>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container mt={0} className={classes.calDates}>
                <Grid item md={12} lg={12}>
                    <Grid container>
                        {
                            loading ?
                                <Grid item xs={12}>
                                    <LoadingSection />
                                </Grid>
                                :
                                dates.map(date => {
                                    return (
                                        <Grid item xs={1.7} className={classes.date_outline}>
                                            <Stack mt={1} justifyContent={"space-between"} height="100%" pb={2}>
                                                <Box className={classes.date}><span className={moment(date).format("M") === moment(month).format("M")
                                                    ? " " : classes.hidden_other_month_dates}>{moment(date).format("DD")}</span></Box>
                                                <Box sx={{ textAlign: "right" }}>

                                                    <Box m={1}>
                                                        {
                                                            data?.map(evnt => {
                                                                return (
                                                                    evnt?.calendarData?.map(x => {

                                                                        return (

                                                                            moment(date).format("DD") === moment(evnt?.date).format("DD")
                                                                                && moment(evnt?.date).format("MM") === moment(date).format("MM") ?

                                                                                <Stack spacing={1}>
                                                                                    {

                                                                                        x?.activity?.length > 1 ?
                                                                                        <>
                                                                                            <HtmlTooltip
                                                                                                title={
                                                                                                    // x?.parking_master_parking_type === "Reserved" ?
                                                                                                    <React.Fragment>
                                                                                                        <CustomToolTip t={t} data={x?.activity?.[0]} openDeclinePopup={openDeclinePopup} handleSelected={handleSelected} date={date} />
                                                                                                    </React.Fragment >
                                                                                                    // : ""
                                                                                                }
                                                                                                arrow className={classes.customTooltip}
                                                                                            >
                                                                                                <Stack direction={"row"} spacing={1} sx={{ backgroundColor: SwitchColors(x?.activity?.[0]?.category)?.backgroundColor }} p={0.4}
                                                                                                    alignItems={"center"}
                                                                                                    // onClick={() => handleSelected(date)}
                                                                                                >
                                                                                                    <Typography className={classes.event_label} style={{ color: SwitchColors(x?.activity?.[0]?.category)?.color }}>{`${x?.activity?.[0]?.title}`}</Typography>
                                                                                                </Stack>
                                                                                            </HtmlTooltip >
                                                                                            <HtmlTooltip
                                                                                                title={
                                                                                                    // x?.parking_master_parking_type === "Reserved" ?
                                                                                                    <React.Fragment>
                                                                                                        <CustomToolTip t={t} data={x?.activity?.[1]} openDeclinePopup={openDeclinePopup} handleSelected={handleSelected} date={date} />
                                                                                                    </React.Fragment >
                                                                                                    // : ""
                                                                                                }
                                                                                                arrow className={classes.customTooltip}
                                                                                            >
                                                                                                <Stack direction={"row"} spacing={1} sx={{ backgroundColor: SwitchColors(x?.activity?.[1]?.category)?.backgroundColor }} p={0.4}
                                                                                                    alignItems={"center"}
                                                                                                    // onClick={() => handleSelected(date)}
                                                                                                >
                                                                                                    <Typography className={classes.event_label} style={{ color: SwitchColors(x?.activity?.[1]?.category)?.color }}>{`${x?.activity?.[1]?.title}`}</Typography>
                                                                                                </Stack>
                                                                                            </HtmlTooltip >
                                                                                            {x?.activity?.length > 2 &&
                                                                                                <Box onClick={() => showDailyCalendar(date)}>
                                                                                                    <Typography className={classes.event_label} sx={{ color: "#78B1FE", cursor: "pointer" }}>{`+ ${x?.activity?.length - 2} more`}</Typography>
                                                                                                </Box>
                                                                                            }
                                                                                        </>
                                                                                    
                                                                                    :x?.activity?.length === 1 &&
                                                                                        <HtmlTooltip
                                                                                            title={
                                                                                                // x?.parking_master_parking_type === "Reserved" ?
                                                                                                <React.Fragment>
                                                                                                    <CustomToolTip t={t} data={x?.activity?.[0]} openDeclinePopup={openDeclinePopup} handleSelected={handleSelected} date={date} />
                                                                                                </React.Fragment >
                                                                                                // : ""
                                                                                            }
                                                                                            arrow className={classes.customTooltip}
                                                                                        >
                                                                                            <Stack direction={"row"} spacing={1} sx={{ backgroundColor: SwitchColors(x?.activity?.[0]?.category)?.backgroundColor }} p={0.4}
                                                                                                alignItems={"center"}
                                                                                                // onClick={() => handleSelected(date)}
                                                                                            >
                                                                                                <Typography className={classes.event_label} style={{ color: SwitchColors(x?.activity?.[0]?.category)?.color }}>{`${x?.activity?.[0]?.title}`}</Typography>
                                                                                            </Stack>
                                                                                        </HtmlTooltip >
                                                                                    }


                                                                                </Stack >
                                                                                : ""
                                                                        )
                                                                    })

                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    )
                                })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
const CustomToolTip = ({ data = {}, handleSelected = () => false, date = "", t }) => {
    const classes = eventListStyle()
    return (
        <Box p={1}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <img src={"/images/announcement.svg"} width="40px" height="40px" alt="icon" className={classes.announceImg} />
                </Grid>
                <Grid item xs={10}>
                    <Typography className={classes.ttTitle}> {data?.title} </Typography>
                    <Box ml={1} mt={1}>
                        <Typography className={classes.ttsecTitle}>{data?.category}</Typography>
                        <Typography className={classes.ttassigned_by}>{`${t("Created on")} ${moment(data.created_at).format("DD MMM YYYY")} ${t("by")} ${data?.created_by_first_name}`}</Typography>
                        <Typography className={classes.moreLabel} onClick={() => {
                            handleSelected(data)
                        }}> {t("View More")}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}