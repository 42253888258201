import { Box, Divider } from '@mui/material'
import React from 'react'
import { CreateCard } from '../freeTextInvoice/components'

export const ChoosePopup = ({ data = {}, click = () => false}) => {
    return (
        <Box>
            {
                data?.map((e, index, length) => {
                    return (
                        <>
                            <CreateCard data={e} click={() => click(e?.name)} />
                            {length.length - 1 !== index && <Divider></Divider>}
                        </>
                    )
                })
            }
        </Box>
    )
}