import * as React from "react"

const ShareSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 94997"
    width={20}
    height={20}
    {...props}
  >
    <g data-name="Group 94998">
      <path data-name="Rectangle 50293" fill="none" d="M0 0h20v20H0z" />
      <path
        d="M14.421 3a2.587 2.587 0 0 0-2.579 2.579 2.46 2.46 0 0 0 .124.609L7.548 8.4a2.539 2.539 0 0 0-1.969-.977 2.579 2.579 0 1 0 0 5.158 2.539 2.539 0 0 0 1.969-.981l4.417 2.21a2.46 2.46 0 0 0-.124.609 2.579 2.579 0 1 0 2.579-2.579 2.539 2.539 0 0 0-1.969.977l-4.417-2.21A2.46 2.46 0 0 0 8.158 10a2.46 2.46 0 0 0-.124-.609l4.417-2.21a2.539 2.539 0 0 0 1.969.977 2.579 2.579 0 0 0 0-5.158Zm0 1.105a1.474 1.474 0 1 1-1.474 1.474 1.465 1.465 0 0 1 1.474-1.474ZM5.579 8.526A1.474 1.474 0 1 1 4.105 10a1.465 1.465 0 0 1 1.474-1.474Zm8.842 4.421a1.474 1.474 0 1 1-1.474 1.474 1.465 1.465 0 0 1 1.474-1.474Z"
        fill="#5078e1"
      />
    </g>
  </svg>
)

export default ShareSvg