import * as React from "react"

const ApartmentImg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 100539">
      <path data-name="Rectangle 53108" fill="none" d="M0 0h24v24H0z" />
      <path
        d="M5.171 4a.585.585 0 1 0 0 1.171h.2V12.6a1.368 1.368 0 0 0-1.176 1.351v4.293a.585.585 0 0 0 .585.585h5.073a.585.585 0 0 0 .585-.585v-2.537a5.1 5.1 0 0 1 .066-.78h-.652a.585.585 0 0 0-.585.585v2.146h-3.9v-3.707a.2.2 0 0 1 .2-.2h.39a.585.585 0 0 0 .585-.585v-8h9.366v5.483a5.046 5.046 0 0 1 1.171.228V5.171h.2a.585.585 0 1 0 0-1.171Zm2.924 2.732a.39.39 0 0 0-.39.39V7.9a.39.39 0 0 0 .39.39h.78a.39.39 0 0 0 .39-.39v-.78a.39.39 0 0 0-.39-.39Zm2.732 0a.39.39 0 0 0-.39.39V7.9a.39.39 0 0 0 .39.39h.78a.39.39 0 0 0 .39-.39v-.78a.39.39 0 0 0-.39-.39Zm2.732 0a.39.39 0 0 0-.39.39V7.9a.39.39 0 0 0 .39.39h.78a.39.39 0 0 0 .39-.39v-.78a.39.39 0 0 0-.39-.39ZM8.095 9.463a.39.39 0 0 0-.39.39v.78a.39.39 0 0 0 .39.39h.78a.39.39 0 0 0 .39-.39v-.78a.39.39 0 0 0-.39-.39Zm2.732 0a.39.39 0 0 0-.39.39v.78a.39.39 0 0 0 .39.39h.78a.39.39 0 0 0 .39-.39v-.78a.39.39 0 0 0-.39-.39Zm2.732 0a.39.39 0 0 0-.39.39v.78a.39.39 0 0 0 .389.39 5.049 5.049 0 0 1 1.158-.322.339.339 0 0 0 .014-.069v-.78a.39.39 0 0 0-.39-.39Zm1.951 1.951a4.293 4.293 0 1 0 4.293 4.293 4.293 4.293 0 0 0-4.291-4.292Zm-7.415.786a.39.39 0 0 0-.39.39v.78a.39.39 0 0 0 .39.39h.78a.39.39 0 0 0 .39-.39v-.78a.39.39 0 0 0-.39-.39Zm2.732 0a.39.39 0 0 0-.39.39v.78a.39.39 0 0 0 .39.39 5.066 5.066 0 0 1 .974-1.5.376.376 0 0 0-.194-.059Zm4.683.78a1.171 1.171 0 1 1-1.171 1.171 1.171 1.171 0 0 1 1.173-1.176Zm-8.583 1.947a.39.39 0 0 0-.39.39v.78a.39.39 0 0 0 .39.39h.78a.39.39 0 0 0 .39-.39v-.78a.39.39 0 0 0-.39-.39Zm6.829 1.173h3.512a.586.586 0 0 1 .585.585v.2c0 .862-1.048 1.561-2.341 1.561s-2.341-.7-2.341-1.561v-.2a.586.586 0 0 1 .585-.585Z"
        fill="#98a0ac"
      />
    </g>
  </svg>
)

export default ApartmentImg
