import React from "react";
import { Box, Button, Grid, Typography, Divider } from "@mui/material";
// import ShowMoreText from "react-show-more-text";
import { MapSource } from "./commom";
import { LeadStylesParent } from "./style";
import { convertTimeUtcToZoneCalander, useWindowDimensions } from "../../../../utils";

export const ViewActivity = (props) => {
  const { t } = (props)
  // classes
  const classes = LeadStylesParent(props);
  const previewType = MapSource[props?.type?.name];
  const size = useWindowDimensions();
  return (
    <div>
      <div
        style={{
          height: size?.height - 370,
          textAlign: "left",
          overflow: "auto",
          padding: "24px",
        }}
      >
        <Box
          className={classes.typeCard1}
          style={{ backgroundImage: `url(${previewType?.background})` }}
        >
          <Box display="flex" alignItems="center">
            <img src={previewType?.image} alt={previewType?.text} />
            <Typography className={classes.typeName}>
              {previewType?.text}
            </Typography>
          </Box>
          {
            props?.Status && 
            <>
                      {(props?.state?.status !== "Closed") && (
            <Box className={classes.edit} onClick={props?.openEdit}>
              {previewType?.edit}
            </Box>
          )}
            </>
          }
        </Box>

        <Box className={classes.flexBox}>
          <Box className={classes.flexBox1}>
            <Typography className={classes.title}>{t("CATEGORY")}</Typography>
            <Typography className={classes.sub}>
              {props?.state?.Category?.label}
            </Typography>
          </Box>
          {
            !!props?.state?.visitorDate && <Box className={classes.flexBox1}>
              <Typography className={classes.title}>CALL DATE & TIME</Typography>
              <Typography className={classes.sub}>
                {convertTimeUtcToZoneCalander(props?.state?.visitorDate , "DD MMM YY h:mm a")}
              </Typography>
            </Box>
          }
          {/* <Box className={classes.flexBox1}>
            <Typography className={classes.title}>SUB CATEGORY</Typography>
            <Typography className={classes.sub}>
              {props?.state?.subcatagory?.label}
            </Typography>
          </Box>
          <Box className={classes.flexBox1}>
            <Typography className={classes.title}>PURPOSE</Typography>
            <Typography className={classes.sub}>
              {props?.state?.Purpose?.value}
            </Typography>
          </Box> */}
          {/* {props?.state?.visitorDate?.length > 0 && (
            <Box className={classes.flexBox1}>
              <Typography className={classes.title}>START DATE</Typography>
              <Typography className={classes.sub}>
                {moment(props?.state?.visitorDate).format("DD MMM YY")}
              </Typography>
            </Box>
          )} */}
          {props?.state?.mobile?.mobile_code?.length > 0 && (
            <Box className={classes.flexBox1}>
              <Typography className={classes.title}>{t("PHONE NUMBER")}</Typography>
              <Typography className={classes.sub}>
                {props?.state?.mobile?.mobile_code} -{" "}
                {props?.state?.mobile?.mobile}
              </Typography>
            </Box>
          )}
          {props?.state?.email?.length > 0 && (
            <Box className={classes.flexBox1}>
              <Typography className={classes.title}>{t("EMAIL ID")}</Typography>
              <Typography className={classes.sub}>
                {props?.state?.email}
              </Typography>
            </Box>
          )}
          {props?.state?.location?.length > 0 && (
            <Box className={classes.flexBox1}>
              <Typography className={classes.title}>{t("LOCATION")}</Typography>
              <Typography className={classes.sub}>
                {props?.state?.location}
              </Typography>
            </Box>
          )}
          {props?.state?.Followup?.length > 0 && (
            <Box className={classes.flexBox1}>
              <Typography className={classes.title}>{t("FOLLOW UP")}</Typography>
              <Typography className={classes.sub}>
                {props?.state?.Followup}
              </Typography>
            </Box>
          )}
          {props?.state?.closed_on?.length > 2 && (
            <Box className={classes.flexBox1}>
              <Typography className={classes.title}>{t("CLOSED ON")}</Typography>
              <Typography className={classes.sub}>
                {convertTimeUtcToZoneCalander(props?.state?.closed_on)}
              </Typography>
            </Box>
          )}
          {props?.state?.remarks?.length > 0 && (
            <Box className={classes.flexBox1}>
              <Typography className={classes.title}>{t("REMARK")}</Typography>
              <Typography className={classes.sub}>
                {props?.state?.remarks}
              </Typography>
            </Box>
          )}

          <Box className={classes.flexBox1}>
            <Typography className={classes.title}>{t("PRIORITY")}</Typography>
            <Typography className={classes.sub}>
              {props?.state?.Priority}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.margin}>
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.title}>{t("NOTE")}</Typography>
            {/* <ShowMoreText
              lines={2}
              more="Show More"
              less="Show Less"
              className={classes.sub}
              anchorClass={classes.seeMoreLessTextStyle}
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              <Typography className={classes.sub}>
                {props?.state?.Notes ?? ""}
              </Typography>
            </ShowMoreText> */}
            {(props?.state?.Notes && props?.state?.Notes?.length > 0) && (props?.state?.Notes?.split('\n').map((item, i) => <div key={i}>{item}</div>))}

          </Grid >
        </Grid >
      </div >
      {
        props?.Status && 
        <>
        {
        (props?.state?.status !== "Closed") && (
          <Box className={classes.bottomButton}>
            <Button className={classes.next} onClick={props?.viewDialogFunction}>
              {t("Mark As Completed")}
            </Button>
          </Box>
        )
      }
        </>
      }
      
    </div >
  );
};
