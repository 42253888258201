import * as React from "react";
import { Drawer } from "@mui/material";

export function DrawerComponent(props) {
  return (
    <div>
      <React.Fragment>
        <Drawer
          onClose={props?.onClose}
          anchor={props?.anchor ?? "right"}
          open={props?.open}
          className={props?.DrawerClass}
        >
          {props?.component}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
