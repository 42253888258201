import { withStyles } from "@mui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Routes } from "../../router/routes";
import { ExtraBold } from "../../utils";
import MastersType from "./masterTypes";
import { withNamespaces } from "react-i18next";

const styles = theme => ({
    backgroundImage: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    rootmain: {
        '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            margin: '10px',
        },
        width: "100%",
        maxWidth: "auto",
        padding: "0 !important",
        "& .MuiPaper-root": {
            width: "991px !important",
            borderRadius: theme.palette.borderRadius,
        },
        "& .MuiDialogContent-root": {
            padding: "0px !important",
            position: 'relative',
            display: 'flex'
        },
        '& .MuiDialog-container': {
            padding: (props) => props?.padding ?? '',
        }
    },
    closeIcon: {
        position: 'absolute',
        top: '24px',
        right: '24px',
        cursor: 'pointer'
    },
    welcome: {
        color: '#091B29',
        fontSize: '40px',
        fontFamily: ExtraBold,
        position: 'absolute',
        top: '65px',
        left: '65px',
    },
    name: {
        color: '#5078E1 !important',
    }
});

class Masters extends React.Component {

    state = {
        open: this.props.location?.state?.main?.isBanner === true ? true : false,
    }

    changeOpen = () => {
        this.setState({ open: !this.state.open })
    }

    render() {
        const { match, t = () => false } = this.props;
        let property_master = {
            route: '/masters/property_master',
            type: 'Property Master'
        }

        let business_master = {
            route: '/masters/business_master',
            type: 'Business Master'
        }

        let service_master = {
            route: '/masters/service_master',
            type: 'Service Master'
        }

        return (
            <>
                {/* Property Master */}
                {
                    match.url === `${property_master.route}/property_type` && (
                        <MastersType
                            {...this.props}
                            type={property_master.type}
                            header={t("Property Type")}
                            title={"Property Type"}
                            create={true}
                            isSelect
                        />
                    )
                }
                {
                    match.url === `${property_master.route}/pricing_component` && (
                        <MastersType
                            {...this.props}
                            type={property_master.type}
                            header={t("Pricing Component")}
                            title={"Pricing Component"}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${property_master.route}/unit_rental_breakup` && (
                        <MastersType
                            {...this.props}
                            type={property_master.type}
                            title={"Unit Rental Breakup"}
                            header={t("Unit Rental Breakup")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${property_master.route}/block_tType` && (
                        <MastersType
                            {...this.props}
                            type={property_master.type}
                            header={t("Block Type")}
                            title={"Block Type"}
                            create={true}
                            isSelect
                        />
                    )
                }
                {
                    match.url === `${property_master.route}/unit_purpose` && (
                        <MastersType
                            {...this.props}
                            type={property_master.type}
                            header={t("Unit Purpose")}
                            title={"Unit Purpose"}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${property_master.route}/unit_type` && (
                        <MastersType
                            {...this.props}
                            type={property_master.type}
                            header={t("Unit Type")}
                            title={"Unit Type"}
                            create={true}
                            isSelect
                        />
                    )
                }
                {
                    match.url === `${property_master.route}/ameneties` && (
                        <MastersType
                            {...this.props}
                            type={property_master.type}
                            header={t("Ameneties")}
                            title={"Ameneties"}
                            create={true}
                        />
                    )
                }
                {/* Business Master */}
                {
                    match.url === `${business_master.route}/country` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            header={t("Country")}
                            title={"Country"}
                            create={false}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/currency` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Currency"}
                            header={t("Currency")}
                            create={false}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/tax_group` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Tax Group"}
                            header={t("Tax Group")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/tax_item` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Tax Item"}
                            header={t("Tax Item")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/Company` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Company"}
                            header={t("Company")}
                            create={true}
                            routes={{
                                add: Routes.companyCreation,
                                edit: Routes.editcompany,
                                view: Routes.editcompany,
                            }}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/state` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"State"}
                            header={t("State")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/city` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"City"}
                            header={t("City")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/locality` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Locality"}
                            header={t("Locality")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/invoice` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Invoice"}
                            header={t("Invoice")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/quotation_status` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Quotation status"}
                            header={t("Quotation status")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/lead_type` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Lead type"}
                            header={t("Lead type")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/lead_status` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Lead status"}
                            header={t("Lead status")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/activity` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Activity"}
                            header={t("Activity")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/activity_category` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Activity Category"}
                            header={t("Activity Category")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/activity_sub_category` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title="Activity Sub-Category"
                            header={t("Activity Sub-Category")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${business_master.route}/move_in_move_out` && (
                        <MastersType
                            {...this.props}
                            type={business_master.type}
                            title={"Move-in Move-out"}
                            header={t("Move-in Move-out")}
                            create={true}
                        />
                    )
                }
                {/* Service Master */}
                {
                    match.url === `${service_master.route}/general` && (
                        <MastersType
                            {...this.props}
                            type={service_master.type}
                            title={"General"}
                            header={t("General")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${service_master.route}/general_sub_category` && (
                        <MastersType
                            {...this.props}
                            type={service_master.type}
                            title={"General Sub-Category"}
                            header={t("General Sub-Category")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${service_master.route}/general_status` && (
                        <MastersType
                            {...this.props}
                            type={service_master.type}
                            title={"General status"}
                            header={t("General status")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${service_master.route}/maintenance` && (
                        <MastersType
                            {...this.props}
                            type={service_master.type}
                            title={"Maintenance"}
                            header={t("Maintenance")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${service_master.route}/maintenance_sub_category` && (
                        <MastersType
                            {...this.props}
                            type={service_master.type}
                            title={"Maintenance Sub-Category"}
                            header={t("Maintenance Sub-Category")}
                            create={true}
                        />
                    )
                }
                {
                    match.url === `${service_master.route}/maintenance_status` && (
                        <MastersType
                            {...this.props}
                            type={service_master.type}
                            title={"Maintenance status"}
                            header={t("Maintenance status")}
                            create={true}
                        />
                    )
                }

            </>
        )
    }
}
const props = {
    master: true
}

// export default withRouter(withNavBars(withStyles(styles)(Masters), props));
export default withRouter(withNamespaces("mastersType")(withNavBars(withStyles(styles)(Masters), props)));