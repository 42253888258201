export const ShortTermPricing = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Short Term">
            <g data-name="Group 96941" fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 93625"
                    d="M10.12 15.545H6.785v1.091h3.335a7.043 7.043 0 0 0 .174 1.09H7.875v1.095h2.764a7.1 7.1 0 1 0 12.349-6.826l1.12-.933-.7-.838-1.12.933a7.189 7.189 0 0 0-3.639-1.994 1.616 1.616 0 0 0 .164-.7 1.636 1.636 0 0 0-3.272 0 1.616 1.616 0 0 0 .164.7 7.08 7.08 0 0 0-3.6 1.994l-1.118-.936-.7.838 1.12.933a7.075 7.075 0 0 0-.77 1.374H5.694v1.09h4.6a7.05 7.05 0 0 0-.174 1.089Zm12 .545a4.861 4.861 0 0 1-.984 2.944l-.327.435-4.179-3.107v-5.18h.545a4.944 4.944 0 0 1 4.95 4.908Zm-5.489-7.634a.545.545 0 1 1 .545.545.546.546 0 0 1-.54-.544Z"
                />
                <path
                    data-name="Path 93626"
                    d="M21.034 16.09a3.855 3.855 0 0 0-3.308-3.778v3.505l2.84 2.1a3.776 3.776 0 0 0 .468-1.827Z"
                />
            </g>
            <path data-name="Rectangle 52035" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
