import * as React from "react"

export const AgreementTag = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 8128">
            <g data-name="Group 93808">
                <path
                    data-name="Path 4137"
                    d="m5.404 16.621 2.254.885.859 2.146 3.488-3.488-3.783-3.111Z"
                    fill="#4e5a6b"
                />
                <path
                    data-name="Path 4138"
                    d="m19.613 16.621-2.254.885-.859 2.146-3.488-3.488 3.783-3.111Z"
                    fill="#4e5a6b"
                />
                <path
                    data-name="icons8-warranty (1)"
                    d="M12.389 3.003a1.791 1.791 0 0 0-1.317.488l-.416.382-.556-.115a1.87 1.87 0 0 0-2.166 1.273l-.169.538-.536.177a1.868 1.868 0 0 0-1.244 2.185l.122.55-.375.417a1.87 1.87 0 0 0 .016 2.514l.381.416-.114.553a1.862 1.862 0 0 0 .485 1.677L4.184 16.37a.628.628 0 0 0 .259 1.044l2.4.739.739 2.4a.628.628 0 0 0 1.044.259l3.6-3.6c.052.005.1.016.155.017s.082-.01.123-.014l3.6 3.6a.628.628 0 0 0 1.044-.259l.739-2.4 2.4-.739a.628.628 0 0 0 .259-1.044l-2.297-2.315a1.862 1.862 0 0 0 .481-1.672l-.114-.553.384-.416a1.87 1.87 0 0 0 .013-2.519l-.375-.421.122-.55a1.868 1.868 0 0 0-1.243-2.186l-.536-.177-.169-.538a1.869 1.869 0 0 0-2.17-1.268l-.553.115-.417-.383a1.805 1.805 0 0 0-1.283-.487ZM7.658 14.672l.167.053.175.535a1.871 1.871 0 0 0 2.186 1.244l.55-.123.291.26-2.547 2.55-.531-1.724a.628.628 0 0 0-.415-.415L5.81 16.52Zm9.429 0 1.849 1.849-1.724.531a.628.628 0 0 0-.415.415l-.531 1.724-2.55-2.55.291-.259.55.123a1.871 1.871 0 0 0 2.186-1.244l.177-.536Z"
                    fill={props?.color}
                />
            </g>
        </g>
    </svg>
)


