import * as React from "react"
export const StayIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    {...props}
  >
    <path
      fill="#af1c22"
      d="M18.5 3.5c-2 2.667-4 2.667-6 0 2-2.667 4-2.667 6 0Z"
      style={{
        opacity: 0.738,
      }}
    />
    <path
      fill="#b01523"
      d="M12.5 3.5v3c-2.281-.423-3.948.244-5 2-4.314-.272-4.814-1.605-1.5-4 .414.457.914.79 1.5 1 1.032-2.693 2.698-3.36 5-2Z"
      style={{
        opacity: 0.85,
      }}
    />
    <path
      fill="#b81725"
      d="M23.5 8.5c-1.052-1.756-2.719-2.423-5-2v-3c2.302-1.36 3.968-.693 5 2 1-1.333 2-1.333 3 0 .962 2.383-.038 3.383-3 3Z"
      style={{
        opacity: 0.748,
      }}
    />
    <path
      fill="#279de0"
      d="M23.5 8.5c.762.768 1.762 1.268 3 1.5.752.67 1.086 1.504 1 2.5-.968 2.107-1.302 4.44-1 7h-22c.302-2.56-.032-4.893-1-7a2.427 2.427 0 0 1 .5-2 19.603 19.603 0 0 0 3.5-2 53.081 53.081 0 0 0 8-2 53.081 53.081 0 0 0 8 2Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#0166b5"
      d="M3.5 12.5c.968 2.107 1.302 4.44 1 7h22c-.302-2.56.032-4.893 1-7v15h-24v-15Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#015499"
      d="M20.5 13.5h4v4h-4v-4Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#004e92"
      d="M13.5 13.5h4v4h-4v-4Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#015499"
      d="M6.5 13.5h4v4h-4v-4Z"
      style={{
        opacity: 1,
      }}
    />
  </svg>
)

