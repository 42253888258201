import React from "react";
import { agreementTime , agreementTime1 , agreementTime2} from "../../../utils/agreementUtils";
import { Box, Grid, Typography } from "@mui/material";
import { useStyleTimeLine } from "../styles";

export const TabCompoent = ({ t,details, ...props}) => {
  const classes = useStyleTimeLine(props);
  const array = details?.is_renewal ? agreementTime2(t, props?.step , props?.is_show , props?.indexOfOne , details) : details?.quotation?.id ? agreementTime(t, props?.step , props?.is_show , props?.indexOfOne , details) : agreementTime1(t, props?.step , props?.is_show , props?.indexOfOne , details)
  return (
    <div className={classes.tabRoot}>
      <Grid container spacing={3}>
        {array?.filter((item)=>item?.is_active === true)?.map((x, index, length) => {
          return (
            <Grid item sm={4} md={3} lg={1.7}>
              <Box
                className={classes.displayBox}
                onClick={() =>  details?.is_renewal ? index=== 0 ? props?.onChange(index): props?.is_show[index - 1]  ?  props?.onChange(index) : false : details?.quotation?.id ? index === 0 ?  props?.onChange(index)  :  x?.title === "Agreement" ? props?.is_show[0] ? props?.onChange(index) : false : props?.is_show[index-1] ? props?.onChange(index) : false : props?.onChange(index)}                style={{
                  backgroundColor: props?.step === index ? "#F1F7FF" : "#fff",
                  border:
                    props?.step === index
                      ? "1px solid #F1F7FF"
                      : "1px solid #E4E8EE",
                }}
              >
                <Box
                  className={classes.selected}
                  style={{
                    backgroundColor:
                    details?.quotation?.id ? props?.is_show[index] ? props?.step === index ? "#fff" : "#F5F7FA" : props?.step === index ? "#78B1FE" : "#F5F7FA" : props?.is_show[index +1] ? props?.step === index ? "#fff" : "#F5F7FA" : props?.step === index ? "#78B1FE" : "#F5F7FA" ,
                    border:
                    details?.quotation?.id ? props?.is_show[index] ? props?.step === index ? "#fff" : "#F5F7FA" : props?.step === index
                        ? "1px solid #78B1FE"
                        : "1px solid #F5F7FA": props?.is_show[index + 1] ? props?.step === index ? "#fff" : "#F5F7FA" : props?.step === index
                        ? "1px solid #78B1FE"
                        : "1px solid #F5F7FA",
                  }}
                >
                  {x?.image}
                </Box>
                <Box marginInlineStart={"8px"}>
                  <Typography
                    className={classes.title}
                    style={{ color: x?.color }}
                  >
                    {x?.title}
                  </Typography>
                  <Typography
                    className={classes.subTitleTab}
                    style={{ color: x?.subcolor }}
                  >
                    {x?.subTitle}
                  </Typography>
                </Box>
                {length?.length -1 !== index && <Box className={classes.absolute} />}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
