/* eslint-disable no-unused-vars */
import {
    Button,
    Divider,
    Grid, List,
    ListItemButton,
    ListItemText, Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { CheckboxesGroup } from "./checkBoxFilter";
import { Bold } from "../../utils";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    active: {
        fontSize:"0.75rem",
        padding: "2px 6px",
        color: "#5078E1",
        backgroundColor: "#E4E8EE",
        borderRadius: "50%",
        width: "100%",
        display: "inline",
        fontFamily: Bold,
    },
    filterdata: {
        fontSize:"1rem",
        fontFamily: Bold,
    },
    roots: {
        overflow: "scroll",
        height: '400px',
        // [theme.breakpoints.down("md")]: {
        //     height: '330px',
        // },
        // [theme.breakpoints.down("sm")]: {
        //     height: '342px',
        // },
    },
    btncontainer: {
        padding: "14px",
        backgroundColor: "#F5F7FA",
        // position: 'absolute',
        // bottom: '0',
    },
    applybtn: {
        padding: "12px",
        borderRadius: "10px",
    },
    cancelbtn: {
        padding: "12px",
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        borderRadius: "10px",
    },
}));
const filterTypes = {
    CHECKBOX: "CHECKBOX",
    DATE: "DATE",
};
export const Filter = ({
    filterData = [],
    onGoBack = () => false,
    selectedList = {},
    filters = {},
    onApply = () => false,
    updateState = () => false,
    filterStartDate = {},
    onClose = () => false,
}) => {
    const size = 400;
    const classes = useStyles(size);
    const [state, setState] = React.useState({ selectedList, filters });
    const [search, setSearch] = React.useState("");
    const onListSelected = (event, filter) => {
        setState({ ...state, selectedList: filter });
    };
    const onApplyBtnClicked = () => {
        onApply(state.filters)
    };
    const handleCheckFilter = () => {
        let _checkFilter = true;
        let iskey = Object.keys(state.filters).map(key => {
            return state.filters[key].length > 0
        });
        for (let i = 0; i < iskey.length; i++) {
            if (!iskey[i]) {
                _checkFilter = iskey[i];
            }
        }
        return !_checkFilter
    }
    const onFilterChange = (key, values, type) => {
        let filters = state.filters;
        if (type === filterTypes.CHECKBOX) {
            if (values.isChecked) {
                filters[key].push(values.value);
            } else {
                filters[key].splice(filters[key].indexOf(values.value), 1);
            }
        }
        setState({ ...state, filters });
    };
    const resetFilter = () => {
        if (filterData.length) {
            let filters = state.filters;
            filterData.forEach((filter) => {
                if (!filters?.[filter.key]?.length) {
                    filters[filter.key] = [];
                }
            });
            setState({ ...state, filters });
        }
    };
    React.useEffect(() => {
        resetFilter();
        // eslint-disable-next-line
    }, []);
    return (
        <div className={classes.root}>
            <Grid container sx={{ padding: "0px" }}>
                <Grid item xs={12} container >
                    <Grid
                        item
                        xs={4} sm={4} md={6}
                        sx={{
                            padding: "16px",
                            borderRight: "1px solid #C1C1C1", overflow: "scroll",
                            height: '400px',
                        }}
                    >
                        <List component="nav">
                            {filterData.map((filter, key) => {
                                return (
                                    <ListItemButton
                                        key={key}
                                        selected={state.selectedList.key === filter.key}
                                        onClick={(event) => onListSelected(event, filter)}
                                    >
                                        <ListItemText className={classes.filterdata}>
                                            {filter.title}&nbsp;
                                            <span className={classes.active}>
                                                {state.filters?.[filter.key]?.length ?? 0}
                                            </span>
                                        </ListItemText>
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    </Grid>
                    {state.selectedList.key && (
                        <Grid item xs={8} sm={8} md={6} style={{
                            padding: "16px",
                            overflow: "scroll",
                            height: '400px',
                        }}>
                            {state.selectedList.values.length ? (
                                <>
                                    {state.selectedList.filterType === filterTypes.CHECKBOX && (
                                        <CheckboxesGroup
                                            search={search}
                                            selectedValues={state.filters[state.selectedList.key]}
                                            onSelect={(values) =>
                                                onFilterChange(
                                                    state.selectedList.key,
                                                    values,
                                                    filterTypes.CHECKBOX
                                                )
                                            }
                                            filters={state.selectedList.values}
                                        />
                                    )}
                                </>
                            ) : (
                                <Typography variant={"caption"}>
                                    No Filters Available
                                </Typography>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Divider />
                <Grid container className={classes.btncontainer} spacing={1}>
                    <Grid item xs={6}>
                        <Button
                            variant="contain"
                            fullWidth={true}
                            className={classes.cancelbtn}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            fullWidth={true}
                            className={classes.applybtn}
                            disabled={handleCheckFilter()}
                            onClick={() => {
                                onApplyBtnClicked();
                            }}
                        >
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};