import { Box, Typography, Grid, Stack, Button } from '@mui/material'
import React from 'react'
import NoneSlot from '../../../assets/noneslot'
import { AvailableSlotsStyle } from './style'

export const AvailableSlots = ({ selectedVenue = "", availableSlot = {}, openPopup = () => false }) => {
    const classes = AvailableSlotsStyle()
    return (
        <Box>
            <Box className={classes.root_none}>
                {
                    selectedVenue === "" ?
                        <Box className={classes.none}>
                            <Stack alignItems={"center"} spacing={2}>
                                <NoneSlot />
                                <Stack alignItems={"center"} spacing={1}>
                                    <Typography className={classes.noneTitle}>Select Venue to view slots</Typography>
                                    <Typography className={classes.noneContent}>Slots will be viewable once you select the venue</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        :
                        <Box className={classes.slot_section}>
                            <Grid container>
                                {
                                    availableSlot.map((e) => {
                                        return (
                                            <Grid item sm={5.7} className={classes.box} p={2} m={0.5}>
                                                <Stack textAlign={"center"}>
                                                    <Typography className={classes.time}>{e.start_time} - {e.end_time}</Typography>
                                                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                        <Typography className={classes.content}>1 Hour</Typography>
                                                        <Box className={classes.dot}></Box>
                                                        <Typography className={classes.content}>{e.person_or_slot} Persons / Slot</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                }
            </Box>
            <Box>
                {
                    selectedVenue !== "" &&

                    <Stack direction={"row"} spacing={1}>
                        <Button variant="outlined" fullWidth disableElevation >Book for walk-in</Button>
                        <Button variant="contained" fullWidth disableElevation 
                        onClick={openPopup}>Book for Resident</Button>
                    </Stack>
                }
            </Box>
        </Box>
    )
}