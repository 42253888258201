import React from "react";

function ResidentialActiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g data-name="Group 113774" transform="translate(8945 23374)">
        <g
          data-name="icons8-business-building"
          transform="translate(-8949 -23376)"
        >
          <path
            fill="#0078d4"
            d="M0 0H13.263V5.684H0z"
            data-name="Rectangle 57577"
            transform="translate(9.474 17.316)"
          ></path>
          <path
            fill="#199be2"
            d="M14 6.895h9.474v-.948A.948.948 0 0022.526 5h-7.579a.948.948 0 00-.947.947z"
            data-name="Path 100573"
            transform="translate(-2.632)"
          ></path>
          <path
            fill="#199be2"
            d="M10 11v9.474h13.263V11z"
            data-name="Path 100574"
            transform="translate(-.526 -3.158)"
          ></path>
          <path
            fill="#005094"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57578"
            transform="translate(11.368 19.211)"
          ></path>
          <path
            fill="#005094"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57586"
            transform="translate(18.947 19.211)"
          ></path>
          <path
            fill="#005094"
            d="M0 0H2.842V3.789H0z"
            data-name="Rectangle 57579"
            transform="translate(14.684 19.211)"
          ></path>
          <path
            fill="#0d62ab"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57580"
            transform="translate(11.368 13.526)"
          ></path>
          <path
            fill="#0d62ab"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57581"
            transform="translate(15.158 13.526)"
          ></path>
          <path
            fill="#0d62ab"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57585"
            transform="translate(18.947 13.526)"
          ></path>
          <path
            fill="#0d62ab"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57582"
            transform="translate(11.368 9.737)"
          ></path>
          <path
            fill="#0d62ab"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57583"
            transform="translate(15.158 9.737)"
          ></path>
          <path
            fill="#0d62ab"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57584"
            transform="translate(18.947 9.737)"
          ></path>
          <path
            fill="#35c1f1"
            d="M22.737 9.947H9.474a.474.474 0 010-.947h13.263a.474.474 0 010 .947z"
            data-name="Path 100575"
            transform="translate(0 -2.105)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ResidentialActiveIcon;
