import { Box, Button, Grid, Stack, Divider, IconButton } from "@mui/material";
import { LoadingSection, SearchFilter, TableWithPagination } from "../../../components";
import { Propertyheading, Propertypath } from "../../../utils/tableData";
import { useStyles } from "../style";
import FilterIMG from "../../../assets/filter";
import FilterIcon from "../../../assets/filterIcon";
export const AccountTable = ({
  permission = {},
  handleSearch = () => false,
  searchText = "",
  handleIcon = () => false,
  data = {},
  handlePagination = () => false,
  handleChangeLimit = () => false,
  page = "",
  limit = "",
  selectedCompany = {},
  onUnitClick = () => false,
  handleOnClickAddPropertyButton = () => false,
  totalRowsCount = "",
  draweropen = () => false,
  loading = false,
  enable_double_click = false,
  enable_single_click = false,
  filterData = {},
  t
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={4}>
          {/*search field*/}
          <SearchFilter
            value={searchText}
            placeholder={t("Search Properties")}
            handleChange={(value) => handleSearch(value)}
            customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
          />
        </Grid>
        <Grid item xs={8}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            justifyContent='end'
          >
            <Box>
              {(filterData?.property_type?.length > 0||filterData?.property_hierarchy?.length > 0||filterData?.property_purpose?.length > 0||filterData?.status!==undefined) ?
                <Box onClick={draweropen}><FilterIcon /></Box>
                : <IconButton onClick={draweropen} className={classes.filterimg}>
                  <FilterIMG color="#091b29" />
                </IconButton>
              }
            </Box>
            {/*Add Btn*/}
            {permission?.create && <Box>
              <Button
                onClick={handleOnClickAddPropertyButton}
                variant="contained"
                className={classes.btn}
              >
                {" "}
                {t("New Property")}
              </Button>
            </Box>}
          </Stack>
        </Grid>
      </Grid>
      {loading ? <LoadingSection /> :
        <TableWithPagination
          heading={Propertyheading(t)}
          rows={data}
          path={Propertypath}
          showpagination={true}
          showpdfbtn={false}
          showexcelbtn={false}
          showSearch={false}
          tableType="no-side"
          count="2"
          handleIcon={handleIcon}
          dataType={[
            { type: ["info"], icon: "icon" },
            { type: ["text"], name: "propertyNo" },
            { type: ["long_text"], name: "propertyName" },
            { type: ["text"], name: "location" },
            { type: ["propertyType"], name: "propertyPurpose" },
            { type: ["text"], name: "propertyHierarchy" },
          ]}
          handlePagination={handlePagination}
          handleChangeLimit={handleChangeLimit}
          onUnitClick={onUnitClick}
          totalRowsCount={totalRowsCount}
          page={page}
          limit={limit}
          height={`calc(100vh - 300px)`}
          view={permission?.read}
          edit={permission?.update}
          enable_double_click={enable_double_click}
          enable_single_click={enable_single_click}
          delete={permission?.delete} />
      }
    </Box>
  );
};
