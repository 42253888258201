import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material"
import React from "react"
import { useStyles } from "./style"

export const VisitorSlotSelection = ({ label = "", isRequired = false, options = [], value = "", updateState = () => false, error = "",handleChange=()=>false,selectedSlot="" }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(false)
    const open = Boolean(anchorEl)
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleApply = () => {
        updateState(selectedSlot)
        setAnchorEl(null)
    }
    return (
        <Box sx={{ padding: "16px 0px 16px 16px" }}>
            <Typography className={classes.title}>{label}
                {isRequired && <Typography variant="caption" color="error">*</Typography>}
            </Typography>
            <Box display={"flex"} justifyContent={value?.label?.length > 0 ? "space-between" : "end"} className={classes.slotInputBox} onClick={(event) => setAnchorEl(event.currentTarget)} mt={1} >
                <Typography className={classes.slotValue}>{value?.label}</Typography>
                <img src={'/images/date_picker_icon.svg'} alt="" />
            </Box>
            {error?.length > 0 &&
                <Box mt={1}>
                    <Typography className={classes.errorTitle}>{error}</Typography>
                </Box>
            }
            <Menu
                anchorEl={anchorEl}
                open={open}
                className={classes.menublock}
                onClose={handleClose}

                // anchorOrigin={{
                //     vertical: "top",
                //     horizontal: "right",
                //   }}
                //   transformOrigin={{
                //     vertical: "top",
                //     horizontal: "left",
                //   }}
                PaperProps={{ sx: { width: "360px", backgroundColor: "white", border: "1px solid #E4E8EE", boxShadow: "0px 0px 16px #00000014", marginTop: "4px" } }}
            >
                <MenuItem className={classes.menuList} disableRipple>
                    <Box>
                        <Grid container spacing={1} alignItems={"center"}>
                            {options?.map((x) => {
                                return (
                                    <Grid item xs={12} sm={12} md={6} lg={6} onClick={() => handleChange(x)}>
                                        <Box className={selectedSlot?.value === x?.value ? classes.selectedSlotBox : classes.slotBox}>
                                            <Typography className={classes.slotTime} style={{ color: selectedSlot?.value === x?.value ? "#5078E1" : "#091B29" }}>{x?.label}</Typography>
                                        </Box>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Box>
                </MenuItem>
                <MenuItem className={classes.menuList}>
                    <Grid item xs={12}>
                        <Button variant="contained" fullWidth onClick={() => handleApply()}>{"Apply"}</Button>
                    </Grid>
                </MenuItem>
            </Menu>
        </Box>
    )
}