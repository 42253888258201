import { Close, InfoOutlined } from "@mui/icons-material"
import { Box, Button, Divider, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery } from "@mui/material"
import React, { useState } from "react"
import AddBOMIcon from "../../../assets/addBOMIcon"
import DeductionDetailsIcon from "../../../assets/addDeduction"
import BOMIcon from "../../../assets/bomIcon"
import DeductionIcon from "../../../assets/deductionIcon"
import DeleteIcon from "../../../assets/delete"
import EditIMG from "../../../assets/editimage"
import TotalDeductionIcon from "../../../assets/totaldeductionIcon"
import { AlertDialog, TextBox } from "../../../components"
import { CustomSelectBox } from "../../../components/customSelectBox"
import { NewFormStep } from "../../../components/newFormSteps"
import { config } from "../../../config"
import { AlertContext, AuthContext } from "../../../contexts"
import { NetworkCall } from "../../../networkcall"
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, checkNumber, useWindowDimensions } from "../../../utils"
import { BottomTotalCard } from "../../freeTextInvoice/components/bottomTotalCard"
import TotalAfterTaxes from "../../freeTextInvoice/utlis/totalAfterTaxed"
import TotalBeforeAmount from "../../freeTextInvoice/utlis/totalBeforeAmount"
import TotalDiscount from "../../freeTextInvoice/utlis/totalDiscount"
import TotalTaxes from "../../freeTextInvoice/utlis/totalTaxes"
import { useStyles } from "./style"
import { DeductionTableHeader, TableHeader, addId, calculateAmount, initialState, initialdeductionState } from "./utils"
import { TypeCategoryComp } from "../../freeTextInvoice/components/typeCategoryComp"

export const ProposedBOM = ({ t = () => false, state = {} }) => {
    const classes = useStyles()
    const [tableData, setTableData] = useState([])
    const [tableRowData, setTableRowData] = useState(addId([initialState]))
    const [isAddDeduction, setIsAddDeduction] = useState(false)
    const [deductionTable, setDeductionTable] = useState([])
    const alert = React.useContext(AlertContext)
    const matches = useMediaQuery('(max-width:1024px)')
    const [isAddBOM, setIsAddBOM] = useState(false)
    const [value, setValue] = useState({
        isApply: false,
        isSave: false,
        isFreeze: false,
        sendApprovalReq: false
    })
    const [isEdit, setIsEdit] = useState("Yes")
    const [showFreezebtn, setShowFreezebtn] = useState(false)
    const [totalAmountAfterDeduction, setTotalAmountAfterDeduction] = useState(0)
    const size = useWindowDimensions()
    const auth = React.useContext(AuthContext)
    const is_tax_editable = auth?.auth?.auth?.is_taxable

    React.useEffect(() => {
        getCostingDetails()
        //eslint-disable-next-line
    }, [])
    const updateState = (data, k, v, index, tableName) => {
        if (tableName === "deduction") {
            if (k === "deductionAmount") {
                let allData = [...deductionTable];
                allData[index] = {
                    ...allData[index],
                    [k]: v,
                };
                setDeductionTable([...allData]);
                const totalLineAmount = checkNumber(tableRowData?.map((item) => parseInt(item?.totalAmountAfterTaxes))
                    .reduce((a, b) => a + b, 0)) ?? 0
                const deductionAmount = checkNumber(allData?.map((item) => parseInt(item?.deductionAmount))
                    .reduce((a, b) => a + b, 0))
                const AmountWithDeduction = totalLineAmount - deductionAmount
                setTotalAmountAfterDeduction(AmountWithDeduction)
            }
            else {
                let allData = [...deductionTable];
                allData[index] = {
                    ...allData[index],
                    [k]: v,
                    itemName: k === "itemTypeCategory" ? "" : k === "itemName" ? v : allData[index]?.itemName
                };
                setDeductionTable([...allData]);
            }


        }
        else {
            const result = calculateAmount(data, k, v, tableRowData)
            const deductionAmount = deductionTable?.map((item) => parseInt(item?.deductionAmount))
                .reduce((a, b) => a + b, 0)
            const totalLineAmount = result?.map((item) => parseInt(item?.totalAmountAfterTaxes))
                .reduce((a, b) => a + b, 0)
            const AmountWithDeduction = totalLineAmount - deductionAmount
            setTotalAmountAfterDeduction(AmountWithDeduction ?? 0)
            setTableRowData(result)
        }
    }
    const addNewBOM = (tableName) => {
        if (tableName === "deduction") {
            const result = [...deductionTable, initialdeductionState]
            setDeductionTable(addId(result))
        }
        else {
            const result = [...tableRowData, initialState]
            setTableRowData(addId(result))
        }

    }

    const closeModal = () => {
        setValue({ ...value, isSave: false })
    }
    const addDeduction = () => {
        if (deductionTable?.length === 0) {
            setIsAddDeduction(true)
            const result = [...deductionTable, initialdeductionState]
            setDeductionTable(addId(result))
        }
    }

    const validate = () => {
        const result = tableRowData?.every((x) => {
            if (x?.itemName?.value?.length > 0) {
                return true
            }
            else {
                return false
            }
        })
        return result;
    };
    const deductionValidate = () => {
        if (deductionTable?.length > 0) {
            const result = deductionTable?.every((x) => {
                if (x?.itemName?.value?.length > 0) {
                    return true
                }
                else {
                    return false
                }
            })
            return result;
        }
        else {
            return true
        }
    };
    const save = () => {
        const totalDiscount = tableRowData?.map((item) => parseInt(item?.totalDiscount)).reduce((a, b) => a + b, 0)
        const totalAmountWithTax = tableRowData?.map((item) => parseInt(item?.lineAmount)).reduce((a, b) => a + b, 0)
        const totalTaxAmount = tableRowData?.map((item) => parseInt(item?.taxAmount)).reduce((a, b) => a + b, 0)
        const totalAmountBeforeTax = tableRowData?.map((item) => parseInt(item?.totalAmount)).reduce((a, b) => a + b, 0)
        const totalDeductionAmount = deductionTable?.map((item) => parseInt(item?.deductionAmount)).reduce((a, b) => a + b, 0)
        const totalAmountAfterTax = checkNumber(totalAmountWithTax) - checkNumber(totalDeductionAmount)
        const deductionDetails = deductionTable?.map((x) => {
            return {
                "id": x?.id,
                "description": x?.description,
                "reference_no": x?.referenceNumber,
                "amount": x?.deductionAmount,
                "currency_id": state?.main?.companyData?.currency_id,
                "inspection_item_id":x?.itemName?.value,
                "is_active": true
            }
        })
        const items = tableRowData?.map((val) => {
            return {
                "id": val?.id,
                "inspection_item": val?.itemName?.value,
                "amount_per_item": val?.price,
                "description": val?.description,
                "quatity": val?.qty,
                "discount": val?.discount,
                "vat_group_master_id": val?.taxGroup?.value,
                "tax_amount": val?.taxAmount,
                "line_amount": val?.lineAmount,
                "currency_id": state?.main?.companyData?.currency_id,
                "discount_type": val?.discountType?.value === "%" ? "Percentage" : "Amount",
                "is_active": true,
                "created_by": localStorage.getItem(LocalStorageKeys?.profileID),
                "updated_by": val?.updated_by
            }
        })
        let data = {

            "id": tableData?.list?.[0]?.costing?.[0]?.id ?? undefined,
            "maintenance_request_id": state?.main?.request === "Maintenance" ? state?.main?.value : undefined,
            "general_request_id": state?.main?.request === "General" ? state?.main?.value : undefined,
            "currency_id": state?.main?.companyData?.currency_id,
            "total_discount": totalDiscount,
            "total_deduction": totalDeductionAmount,
            "total_amount_before_tax": totalAmountBeforeTax,
            "total_tax_amount": totalTaxAmount,
            "total_amount_after_tax": totalAmountAfterTax,
            "unit_id": state?.main?.unit_id,
            "company_id": state?.main?.companyData?.value,
            "items": items,
            "deduction": deductionDetails ?? [],
            "notes": "",
            "bom_type":"Projected"

        }
        if (validate()&&deductionValidate() && totalDeductionAmount <= totalAmountWithTax) {
            const payload = {
                "costing": data
            }
            NetworkCall(
                `${config.api_url}/request_costing/upsert_costing`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {
                setValue({ ...value, isSave: true, isApply: false })
                getCostingDetails()
                setShowFreezebtn(true)
                // freezeData()
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            })
        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg:totalDeductionAmount > totalAmountWithTax ? t("Deductions cannot be more than Billed Amount") : !deductionValidate() ? t("Please choose Item in deductions") : t("Please choose the item"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
    }
    const freezeData = () => {
        const payload = {
            "request_id": state?.main?.value,
            "type": state?.main?.request,
            "is_proposed_bom_frozen": true,
        }

        NetworkCall(
            `${config.api_url}/request_costing/update_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setValue({ ...value, isFreeze: true, isSave: true })
            getCostingDetails()

        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    const sendForApproval = () => {
        // setValue({ ...value, sendApprovalReq: true, isSave: true, isFreeze: false })
        const payload = {
            "request_id": state?.main?.value,
            "type": state?.main?.request,
            "bom_type":"Projected"
        }

        NetworkCall(
            `${config.api_url}/request_costing/send_for_approval`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {

            if (response?.data?.data === "Approval already sent") {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: response?.data?.data,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            }
            else {
                setValue({ ...value, sendApprovalReq: true, isSave: true, isFreeze: false })
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: response?.data?.data ? response?.data?.data : t("Approval Request Sent Successfully"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                getCostingDetails()
            }


        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something Went Wrong"),
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }

    const getCostingDetails = () => {
        const payload = {
            "request_id": state?.main?.value,
            "type": state?.main?.request,
            "bom_type":"Projected"
        }

        NetworkCall(
            `${config.api_url}/request_costing/get_costing`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            const result = response?.data?.data?.map((val) => {
                return {
                    ...val,
                    amount: `${val?.symbol?.length > 0 ? val?.symbol : ""} ${val?.amount}`,
                    status: val?.initial_costing_status ?? val?.costing_status,
                    request_no: val?.general_request_no ?? val?.maintenance_request_no
                }
            })
            setIsEdit(result?.[0]?.costing?.length > 0 ? "No" : "Yes")
            setTableData({ list: [result?.[0]], costingDetails: response?.data?.data?.[0]?.costing })
            if (response?.data?.data?.[0]?.costing?.length > 0) {
                setIsAddBOM(true)
                const rowData = response?.data?.data?.[0]?.costing?.[0]?.items?.map((val) => {
                    let amount = val?.quatity * val?.amount_per_item
                    let totalDiscount = val?.discount > 0 ? (val?.discount_type === "Percentage" ? (amount * checkNumber(val?.discount)) / 100 : checkNumber(val?.discount)) : 0
                    let totalAmount = amount - totalDiscount
                    return {
                        id: val?.id,
                        itemName: { value: val?.inspection_item_id, label: val?.inspection_item_name },
                        description: val?.description,
                        price: val?.amount_per_item,
                        qty: val?.quatity,
                        amount: val?.quatity * val?.amount_per_item,
                        discount: val?.discount,
                        taxGroup: {
                            label: val?.vat_group?.label,
                            value: val?.vat_group?.value,
                            vat_group_item: val?.vat_group?.vat_item
                        },
                        taxAmount: val?.tax_amount,
                        lineAmount: val?.line_amount,
                        discountType: val?.discount_type === "Percentage" ? { label: "%", value: "%" } : { label: state?.main?.companyData?.currency_symbol, value: state?.main?.companyData?.currency_symbol },
                        totalDiscount: totalDiscount,
                        totalAmount: totalAmount,
                        totalTaxes: val?.tax_amount,
                        totalAmountAfterTaxes: val?.line_amount,
                        itemTypeCategory: {
                            type: { value: val?.item, label: val?.item },
                            category: val?.category
                        },
                    }
                })
                setIsAddDeduction(response?.data?.data?.[0]?.costing?.[0]?.deduction?.length > 0)
                const deductionDetails = response?.data?.data?.[0]?.costing?.[0]?.deduction?.map((x) => {
                    return {
                        id: x?.id,
                        description: x?.description,
                        referenceNumber: x?.reference_no,
                        deductionAmount: x?.amount,
                        currency_id: x?.currency_id,
                        itemTypeCategory: {
                            type: { value: x?.item, label: x?.item },
                            category: x?.category
                        },
                        itemName: { value: x?.inspection_item_id, label: x?.inspection_item_name },
                    }
                })
                setDeductionTable(deductionDetails?.length > 0 ? addId(deductionDetails) : [])
                setTableRowData(rowData?.length > 0 ? addId(rowData) : addId([initialState]))
                const deductionAmount = deductionDetails?.map((item) => parseInt(item?.deductionAmount))
                    .reduce((a, b) => a + b, 0)
                const totalLineAmount = rowData?.map((item) => parseInt(item?.totalAmountAfterTaxes))
                    .reduce((a, b) => a + b, 0)
                const AmountWithDeduction = totalLineAmount - deductionAmount
                setTotalAmountAfterDeduction(AmountWithDeduction ?? 0)
            }


        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }

    const removeObjectFromArray = (val, tableName) => {
        if (tableName === "deduction") {
            const result = deductionTable?.filter((x, i) => { return x?.auto_inc_id !== val?.auto_inc_id })
            setDeductionTable(result)
            const deductionAmount = result?.map((item) => parseInt(item?.deductionAmount))
                .reduce((a, b) => a + b, 0)
            const totalLineAmount = tableRowData?.map((item) => parseInt(item?.totalAmountAfterTaxes))
                .reduce((a, b) => a + b, 0)
            const AmountWithDeduction = totalLineAmount - deductionAmount
            setTotalAmountAfterDeduction(AmountWithDeduction ?? 0)
        }
        else {
            const result = tableRowData?.filter((x, i) => { return x?.auto_inc_id !== val?.auto_inc_id })
            const deductionAmount = deductionTable?.map((item) => parseInt(item?.deductionAmount))
                .reduce((a, b) => a + b, 0)
            const totalLineAmount = result?.map((item) => parseInt(item?.totalAmountAfterTaxes))
                .reduce((a, b) => a + b, 0)
            const AmountWithDeduction = totalLineAmount - deductionAmount
            setTotalAmountAfterDeduction(AmountWithDeduction ?? 0)
            setTableRowData(result)
        }

    }

    const InspectionItemManualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                label: i?.name,
                value: i?.id,
                ...i
            }
        })
        return details
    }
    const handleEdit = () => {
        setIsEdit("Yes")
        setShowFreezebtn(false)
    }
    return (
        <Box >
            <Box>
                <Box>
                    {!isAddBOM &&
                        <Box onClick={() => setIsAddBOM(state?.main?.request_status!=="Cancelled"&&state?.main?.request_status!=="Closed"?true:false)} p={2} className={classes.trackboxmod} mt={tableData?.list?.[0]?.initial_costing_status !== null ? 2 : 0}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <AddBOMIcon />
                                <Typography className={classes.BOMText}>{t("Add Bill of Materials and Costing")}</Typography>
                            </Stack>
                        </Box>
                    }
                    {isAddBOM &&
                        <Box>
                            <Box className={classes.costingBoxAll} style={{ height:size?.height - 290, overflow: "scroll" }}>

                                <Box pt={2} pr={2} pl={2}>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <BOMIcon />
                                            <Typography className={classes.visitingChargesText}>{t("Bill of Materials and Costing")}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            {(!tableData?.list?.[0]?.is_proposed_bom_frozen && isEdit === "Yes"&&state?.main?.request_status!=="Cancelled"&&state?.main?.request_status!=="Closed") &&
                                                <Box className={classes.deductionBox} onClick={() => addDeduction()}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <DeductionIcon />
                                                        <Typography className={classes.deductiontxt}>{t("Add Deduction")}</Typography>
                                                    </Stack>
                                                </Box>
                                            }
                                            {!tableData?.list?.[0]?.is_proposed_bom_frozen && showFreezebtn &&
                                                <Button variant="outlined" className={classes.outlinebtn} onClick={() => freezeData()}>{t("Freeze")}</Button>
                                            }
                                            {(tableData?.list?.[0]?.costing_status !== "Approved" && tableData?.list?.[0]?.costing_status !== "Pending") && tableData?.list?.[0]?.is_proposed_bom_frozen &&
                                                <Button variant="outlined" className={classes.outlinebtn} onClick={() => sendForApproval()}>{t("Send for Approval")}</Button>
                                            }
                                            {!tableData?.list?.[0]?.is_proposed_bom_frozen &&
                                                <>
                                                    <Button variant="outlined" className={classes.outlinebtn} onClick={() => save()} disabled={(isEdit === "No" || (state?.main?.request_status==="Cancelled"||state?.main?.request_status==="Closed")) ? true : false}>{t("Save")}</Button>
                                                    {tableData?.list?.[0]?.costing?.length > 0 && isEdit === "No" && state?.main?.request_status!=="Cancelled"&&state?.main?.request_status!=="Closed"&&
                                                        <Box className={classes.invoiceIcon} onClick={() => handleEdit()}><EditIMG /></Box>
                                                    }
                                                </>

                                            }
                                        </Stack>
                                    </Box>
                                    <Box >
                                        <TableContainer className={classes.table} style={{ maxHeight:deductionTable?.length===0?size?.height-500 :size?.height - 540 }}>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        {
                                                            TableHeader(t)?.map((e) => {
                                                                return (
                                                                    <TableCell className={classes.headCell} sx={{ width: e?.delete === "delete" ? 30 : "auto" }}>
                                                                        <Typography className={classes.headText}> {e?.title} </Typography>
                                                                    </TableCell>
                                                                )
                                                            })
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                {
                                                    <TableBody>
                                                        {tableRowData?.map((val, i) => {
                                                            return (
                                                                <TableRow>
                                                                      <TableCell className={classes.bodyCell}>
                                                                        <Box sx={{ width: 200 }}>
                                                                            <TypeCategoryComp
                                                                                t={t}
                                                                                onChange={(value) => {
                                                                                    updateState(val, "itemTypeCategory", value)
                                                                                }}
                                                                                value={val?.itemTypeCategory}
                                                                                isReadOnly={tableData?.list?.[0]?.is_frozen || isEdit === "No" || (state?.main?.request_status === "Cancelled" || state?.main?.request_status === "Closed")}
                                                                            />
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell className={classes.bodyCell}>

                                                                        <Box sx={{ width: 190 }}>

                                                                            <CustomSelectBox
                                                                                value={val?.itemName}
                                                                                menuOptionWidth={190}
                                                                                menuOptionPadding={"0px 8px"}
                                                                                networkCallData={
                                                                                    {
                                                                                        path: "inspection_items/getAll",
                                                                                        payload: {
                                                                                            company_id: state?.main?.company,
                                                                                            "items": val?.itemTypeCategory?.type?.value?.length > 0 ? [val?.itemTypeCategory?.type?.value] : null,
                                                                                            "category_ids": val?.itemTypeCategory?.category?.label?.length > 0 ? [val?.itemTypeCategory?.category?.value] : null
                                                                                        },
                                                                                        mappingVariable: "data",
                                                                                        manualResponse: InspectionItemManualResponse
                                                                                    }
                                                                                }
                                                                                key={JSON.stringify(val?.itemTypeCategory)}
                                                                                onChange={(value) => {
                                                                                    updateState(val, "itemName", value)
                                                                                }}
                                                                                isReadOnly={tableData?.list?.[0]?.is_proposed_bom_frozen || isEdit === "No" || (state?.main?.request_status==="Cancelled"||state?.main?.request_status==="Closed")}
                                                                                placeholder={t("Select the Item")}
                                                                                disableColor={true}
                                                                            />
                                                                        </Box>

                                                                    </TableCell>
                                                                    {/* <TableCell className={classes.bodyCell}>
                                                                        <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                                            <Box sx={{ width: 160 }}>
                                                                                <TextBox
                                                                                    label={""}
                                                                                    placeholder={t("Description")}
                                                                                    value={val?.description}
                                                                                    onChange={(e) => updateState(val, "description", e.target.value, i)}
                                                                                    height={38}
                                                                                    padding={"6px 8px"}
                                                                                    isReadonly={tableData?.list?.[0]?.is_frozen || isEdit === "No"}
                                                                                />

                                                                            </Box>
                                                                            {
                                                                                (val?.error?.description?.length > 0) &&
                                                                                <Tooltip arrow title={val?.error?.description ?? ""} placement="top">
                                                                                    <Box m="4px 0px 0px 4px">
                                                                                        <InfoOutlined style={{ color: "#FF4B4B" }} />
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            }
                                                                        </Stack>
                                                                    </TableCell> */}
                                                                    <TableCell className={classes.bodyCell}>
                                                                        <Box sx={{ width: 120 }}>
                                                                            <TextBox
                                                                                label={""}
                                                                                placeholder={t("Price")}
                                                                                value={val?.price ?? 0}
                                                                                onChange={(e) => updateState(val, "price", e?.target?.value, i)}
                                                                                height={38}
                                                                                padding={"6px 8px"}
                                                                                type={"number"}
                                                                                textAlign={"right"}
                                                                                endAdornment={state?.main?.companyData?.currency_symbol}
                                                                                isReadonly={tableData?.list?.[0]?.is_proposed_bom_frozen || isEdit === "No" || (state?.main?.request_status==="Cancelled"||state?.main?.request_status==="Closed")}
                                                                            />

                                                                        </Box>

                                                                    </TableCell>
                                                                    <TableCell className={classes.bodyCell2}>

                                                                        <Box sx={{ width: 40 }}>
                                                                            <TextBox
                                                                                label={""}
                                                                                placeholder={t("QTY")}
                                                                                value={val?.qty ?? 0}
                                                                                onChange={(e) => updateState(val, "qty", e?.target?.value, i)}
                                                                                height={38}
                                                                                padding={"6px 8px"}
                                                                                type={"number"}
                                                                                textAlign={"right"}
                                                                                isReadonly={tableData?.list?.[0]?.is_proposed_bom_frozen || isEdit === "No" || (state?.main?.request_status==="Cancelled"||state?.main?.request_status==="Closed")}
                                                                            />

                                                                        </Box>

                                                                    </TableCell>
                                                                    <TableCell className={classes.bodyCell}>
                                                                        <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                                            <Box sx={{ width: 130 }}>
                                                                                <TextBox
                                                                                    label={""}
                                                                                    placeholder={t("Amount")}
                                                                                    value={Number(val?.amount ?? 0)?.toFixed(2)?.toLocaleString('en-IN') ?? 0}
                                                                                    // onChange={(e) => updateState(val,"amount",e?.target?.value)}
                                                                                    isReadonly
                                                                                    height={38}
                                                                                    padding={"6px 8px"}
                                                                                    type={"number"}
                                                                                    textAlign={"right"}
                                                                                    endAdornment={state?.main?.companyData?.currency_symbol}
                                                                                />

                                                                            </Box>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell className={classes.bodyCell}>
                                                                        <Stack sx={{ width: "130px" }} direction={"row"} columnGap={"4px"} alignItems={"center"}>

                                                                            <NewFormStep component={[
                                                                                {
                                                                                    sm: 12,
                                                                                    md: 12,
                                                                                    lg: 12,
                                                                                    isActive: true,
                                                                                    type: "textWithSelect",
                                                                                    label: "",
                                                                                    value: val?.discount,
                                                                                    handleChange: (value) => {
                                                                                        updateState(val, "discount", value)
                                                                                    },
                                                                                    placeholder: t("Select Discount"),
                                                                                    customOption: [
                                                                                        { label: "%", value: "%" },
                                                                                        { label: state?.main?.companyData?.currency_symbol, value: state?.main?.companyData?.currency_symbol },
                                                                                    ],
                                                                                    selectChange: (value) => updateState(val, "discountType", value),
                                                                                    selectedValue: val?.discountType,
                                                                                    customHeight: 38,
                                                                                    textAlign:"right",
                                                                                    customPadding: "6px 8px",
                                                                                    header: "",
                                                                                    isReadOnly: tableData?.list?.[0]?.is_proposed_bom_frozen || isEdit === "No" || (state?.main?.request_status==="Cancelled"||state?.main?.request_status==="Closed")
                                                                                }
                                                                            ]} />
                                                                            {
                                                                                (val?.error?.discount?.length > 0 && checkNumber(val?.discount) !== 0) &&
                                                                                <Tooltip arrow title={val?.discount ?? ""} placement="top">
                                                                                    <Box m="4px 0px 0px 4px">
                                                                                        <InfoOutlined style={{ color: "#FF4B4B" }} />
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            }
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell className={classes.bodyCell}>
                                                                        <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                                            <Box sx={{ width: 160 }}>

                                                                                <CustomSelectBox
                                                                                    value={val?.taxGroup}
                                                                                    menuOptionWidth={180}
                                                                                    menuOptionPadding={"0px 8px"}
                                                                                    networkCallData={
                                                                                        {
                                                                                            path: "vat-group/getAll",
                                                                                            payload: {
                                                                                                country_id: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.data?.country?.id
                                                                                            },
                                                                                            mappingVariable: "vat_group_master",
                                                                                        }
                                                                                    }
                                                                                    onChange={(value) => {
                                                                                        updateState(val, "taxGroup", value)
                                                                                    }}
                                                                                    isReadOnly={tableData?.list?.[0]?.is_proposed_bom_frozen || isEdit === "No" || (is_tax_editable?!val?.isTaxable:true) || (state?.main?.request_status==="Cancelled"||state?.main?.request_status==="Closed")}
                                                                                    placeholder={t("Select Tax Group")}
                                                                                    disableColor={true}
                                                                                />
                                                                            </Box>
                                                                            {
                                                                                (val?.error?.taxGroup?.length > 0) &&
                                                                                <Tooltip arrow title={val?.error?.taxGroup ?? ""} placement="top">
                                                                                    <Box m="4px 0px 0px 4px">
                                                                                        <InfoOutlined style={{ color: "#FF4B4B" }} />
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            }
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell className={classes.bodyCell}>
                                                                    <Box sx={{ width: 100 }}>
                                                                        <TextBox
                                                                            label={""}
                                                                            height={38}
                                                                            isReadonly
                                                                            textAlign={"right"}
                                                                            value={Number(val?.taxAmount ?? 0)?.toFixed(2)?.toLocaleString('en-IN')}
                                                                            padding={"6px 8px"} />
                                                                            </Box>
                                                                    </TableCell>
                                                                    <TableCell className={classes.bodyCell}>
                                                                        <Box sx={{ width: 120 }}>
                                                                            <TextBox
                                                                                label={""}
                                                                                height={38}
                                                                                isReadonly
                                                                                textAlign={"right"}
                                                                                value={Number(val?.lineAmount ?? 0)?.toFixed(2)?.toLocaleString('en-IN')}
                                                                                padding={"6px 8px"}
                                                                                endAdornment={state?.main?.companyData?.currency_symbol} />
                                                                        </Box>
                                                                    </TableCell>
                                                                    {(!tableData?.list?.[0]?.is_proposed_bom_frozen && isEdit === "Yes"&&state?.main?.request_status!=="Cancelled"&&state?.main?.request_status!=="Closed") && <TableCell className={classes.bodyCell}>
                                                                        <Box sx={{ cursor: "pointer" }}>
                                                                            <DeleteIcon onClick={() => removeObjectFromArray(val)} />
                                                                        </Box>
                                                                    </TableCell>}
                                                                </TableRow>
                                                            )
                                                        })}

                                                    </TableBody>
                                                }
                                            </Table>
                                        </TableContainer>
                                        {(!tableData?.list?.[0]?.is_proposed_bom_frozen && isEdit === "Yes"&&state?.main?.request_status!=="Cancelled"&&state?.main?.request_status!=="Closed") && <Box mt={1} mb={1}><Typography className={classes.addBillLine} onClick={() => addNewBOM()}>{t("Add New BOM Item")}</Typography></Box>}
                                    </Box>
                                </Box>

                                {isAddDeduction &&
                                    <>
                                        <Box mt={(tableData?.list?.[0]?.is_proposed_bom_frozen || isEdit === "No") ? 1 : 0}><Divider /></Box>
                                        <Box>
                                            <Stack direction="row" spacing={1} alignItems="center" pl={2} pr={2} pt={2}>
                                                <DeductionDetailsIcon />
                                                <Typography className={classes.visitingChargesText}>{t("Deductions")}</Typography>
                                            </Stack>
                                            <Box pb={2} pl={2} pr={2} >
                                                <TableContainer className={classes.deductionSection} style={{ maxHeight: size?.height - 550 }}>
                                                    <Table stickyHeader>
                                                        <TableHead>
                                                            <TableRow>
                                                                {
                                                                    DeductionTableHeader(t)?.map((e) => {
                                                                        return (
                                                                            <TableCell className={classes.headCell} sx={{ width: e?.delete === "delete" ? 30 : "auto" }}>
                                                                                <Typography className={classes.headText}> {e?.title} </Typography>
                                                                            </TableCell>
                                                                        )
                                                                    })
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {deductionTable?.map((val, i) => {
                                                                return (
                                                                    <TableRow>
                                                                               <TableCell className={classes.bodyCell}>
                                                                        <Box sx={{ width: 200 }}>
                                                                            <TypeCategoryComp
                                                                                t={t}
                                                                                onChange={(value) => {
                                                                                    updateState(val, "itemTypeCategory", value, i, "deduction")
                                                                                }}
                                                                                value={val?.itemTypeCategory}
                                                                                isReadOnly={tableData?.list?.[0]?.is_frozen || isEdit === "No" || (state?.main?.request_status === "Cancelled" || state?.main?.request_status === "Closed")}
                                                                            />
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell className={classes.bodyCell}>

                                                                        <Box sx={{ width: 250 }}>

                                                                            <CustomSelectBox
                                                                                value={val?.itemName}
                                                                                menuOptionWidth={190}
                                                                                menuOptionPadding={"0px 8px"}
                                                                                networkCallData={
                                                                                    {
                                                                                        path: "inspection_items/getAll",
                                                                                        payload: {
                                                                                            company_id: state?.main?.company,
                                                                                            "items": val?.itemTypeCategory?.type?.value?.length > 0 ? [val?.itemTypeCategory?.type?.value] : null,
                                                                                            "category_ids": val?.itemTypeCategory?.category?.label?.length > 0 ? [val?.itemTypeCategory?.category?.value] : null
                                                                                        },
                                                                                        mappingVariable: "data",
                                                                                        manualResponse: InspectionItemManualResponse
                                                                                    }
                                                                                }
                                                                                onChange={(value) => {
                                                                                    updateState(val, "itemName", value, i, "deduction")
                                                                                }}
                                                                                key={JSON.stringify(val?.itemTypeCategory)}
                                                                                isReadOnly={tableData?.list?.[0]?.is_frozen || isEdit === "No" || (state?.main?.request_status === "Cancelled" || state?.main?.request_status === "Closed")}
                                                                                placeholder={t("Select the Item")}
                                                                                disableColor={true}
                                                                            />
                                                                        </Box>

                                                                    </TableCell>
                                                                        <TableCell className={classes.bodyCell} style={{ width: "30%" }}>

                                                                            <Box>
                                                                                <TextBox
                                                                                    label={""}
                                                                                    placeholder={t("Description")}
                                                                                    value={val?.description}
                                                                                    onChange={(e) => updateState(val, "description", e?.target?.value, i, "deduction")}
                                                                                    height={40}
                                                                                    padding={"6px 8px"}
                                                                                    isReadonly={tableData?.list?.[0]?.is_proposed_bom_frozen || isEdit === "No"||(state?.main?.request_status==="Cancelled"||state?.main?.request_status==="Closed")}
                                                                                />
                                                                            </Box>

                                                                        </TableCell>
                                                                        <TableCell className={classes.bodyCell} style={{ width: "30%" }}>

                                                                            <Box >
                                                                                <TextBox
                                                                                    label={""}
                                                                                    placeholder={t("Reference Number")}
                                                                                    value={val?.referenceNumber ?? 0}
                                                                                    onChange={(e) => updateState(val, "referenceNumber", e?.target?.value, i, "deduction")}
                                                                                    height={40}
                                                                                    padding={"6px 8px"}
                                                                                    isReadonly={tableData?.list?.[0]?.is_proposed_bom_frozen || isEdit === "No"||(state?.main?.request_status==="Cancelled"||state?.main?.request_status==="Closed")}
                                                                                />
                                                                            </Box>
                                                                        </TableCell>
                                                                        {/* <TableCell className={classes.bodyCell} sx={{width:"30%"}}></TableCell> */}
                                                                        <TableCell className={classes.bodyCell}></TableCell>
                                                                        <TableCell className={classes.bodyCell}>
                                                                            <Stack direction="row" spacing={2} alignItems="center">
                                                                                <Box width={140} display={"flex"} justifyContent={"end"} alignItems={"end"}>
                                                                                    <TextBox
                                                                                        label={""}
                                                                                        placeholder={t("Amount")}
                                                                                        value={val?.deductionAmount ?? 0}
                                                                                        onChange={(e) => updateState(val, "deductionAmount", e?.target?.value, i, "deduction")}
                                                                                        height={40}
                                                                                        padding={"6px 8px"}
                                                                                        type={"number"}
                                                                                        textAlign="right"
                                                                                        isReadonly={tableData?.list?.[0]?.is_proposed_bom_frozen || isEdit === "No"||(state?.main?.request_status==="Cancelled"||state?.main?.request_status==="Closed")}
                                                                                        endAdornment={state?.main?.companyData?.currency_symbol}
                                                                                    />
                                                                                </Box>
                                                                                {(!tableData?.list?.[0]?.is_proposed_bom_frozen && isEdit === "Yes") &&
                                                                                    <Box className={classes.costingDeleteIcon}>
                                                                                        <DeleteIcon onClick={() => removeObjectFromArray(val, "deduction")} />
                                                                                    </Box>
                                                                                }
                                                                            </Stack>
                                                                        </TableCell>

                                                                    </TableRow>
                                                                )
                                                            })}

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {(!tableData?.list?.[0]?.is_proposed_bom_frozen && isEdit === "Yes"&&state?.main?.request_status!=="Cancelled"&&state?.main?.request_status!=="Closed") && <Box className={classes.addNewDeduction}><Typography className={classes.addBillLine} onClick={() => addNewBOM("deduction")}>{t("Add New Deduction")}</Typography></Box>}

                                            </Box>
                                        </Box>
                                    </>
                                }



                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
            <Box className={classes.bottomCard}>
                <Stack direction={matches ? "column" : "row"} spacing={1} alignItems={matches?"start":"center"}>
                    <Stack direction={"row"} columnGap={0.5} divider={<Divider orientation="vertical" flexItem />} alignItems={"center"} className={classes.containBox}>

                        <BottomTotalCard
                            customPadding={0.5}
                            logo={<TotalDiscount />}
                            title={t("Total Discount")}
                            subtitle={`${tableRowData?.map((item) => parseInt(item?.totalDiscount))
                                .reduce((a, b) => a + b, 0)?.toFixed(2)?.toLocaleString('en-IN')} ${state?.main?.companyData?.code}`} />

                        <BottomTotalCard
                            customPadding={0.5}
                            logo={<TotalDeductionIcon />}
                            title={t("Total Deductions")}
                            subtitle={`${checkNumber(deductionTable?.map((item) => parseInt(item?.deductionAmount)).reduce((a, b) => a + b, 0))?.toFixed(2)?.toLocaleString('en-IN')} ${state?.main?.companyData?.code}` ?? 0} />

                        <BottomTotalCard
                            customPadding={0.5}
                            logo={<TotalBeforeAmount />}
                            title={t("Total Before Taxes")}
                            subtitle={`${tableRowData?.map((item) => parseInt(item?.totalAmount))
                                .reduce((a, b) => a + b, 0)?.toFixed(2)?.toLocaleString('en-IN')} ${state?.main?.companyData?.code}`} />

                        <BottomTotalCard
                            customPadding={0.5}
                            logo={<TotalTaxes />}
                            title={t("Total Taxes")}
                            subtitle={`${tableRowData?.map((item) => parseInt(item?.taxAmount))
                                .reduce((a, b) => a + b, 0)?.toFixed(2)?.toLocaleString('en-IN')} ${state?.main?.companyData?.code}`} />
                        <BottomTotalCard
                            logo={<TotalAfterTaxes />}
                            customPadding={0.5}
                            title={t("Total After Taxes")}
                            subtitle={`${checkNumber(tableRowData?.map((item) => parseInt(item?.totalAmountAfterTaxes))
                                .reduce((a, b) => a + b, 0))?.toFixed(2)?.toLocaleString('en-IN')} ${state?.main?.companyData?.code}`} />
                        <BottomTotalCard
                            logo={<TotalAfterTaxes />}
                            customPadding={0.5}
                            title={t("Total After Deduction")}
                            subtitle={`${totalAmountAfterDeduction >= 0 ? totalAmountAfterDeduction ?? 0 : checkNumber(tableRowData?.map((item) => parseInt(item?.totalAmountAfterTaxes))
                                .reduce((a, b) => a + b, 0))?.toFixed(2)?.toLocaleString('en-IN')} ${state?.main?.companyData?.code}`} />

                    </Stack>
                    <Stack direction={"row"} spacing={1}>
                       
                        {tableData?.list?.[0]?.costing?.length > 0 && tableData?.list?.[0]?.costing_status !== null &&

                            <Stack direction="row" spacing={1} alignItems="center" className={classes.costingStatusBox} divider={<Divider orientation="vertical" flexItem />}>
                                <Box className={classes.costingStatusTxt} style={{ backgroundColor: tableData?.list?.[0]?.costing_status === "Pending" ? "#FF9340" : tableData?.list?.[0]?.costing_status === "Approved" ? "#5AC782" : "#FF4B4B" }}>{tableData?.list?.[0]?.costing_status === "Pending" ? t("Waiting for approval") : tableData?.list?.[0]?.costing_status}</Box>
                               
                            </Stack>

                        }
                    </Stack>
                </Stack>
            </Box>

            <AlertDialog open={value?.isSave}
                // onClose={() => handleClose()}
                isnotTitle={true}
                medium
                component={
                    <Box className={classes.showComplete} p={1} alignItems="center">
                        <Box display={"flex"} justifyContent="end" onClick={() => closeModal()}>
                            <Close className={classes.closeicon} />
                        </Box>
                        <Grid container mt={6}>
                            <Grid item xs={4}></Grid>
                            <Grid item xs={8}>
                                <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                    <Typography className={classes.q1hdr}>
                                        {value?.isFreeze ? t("BOM Details are saved successFully") : value?.sendApprovalReq ? t("Costing Details sent for approval successfully") : t("BOM Details are added successFully")}
                                    </Typography>
                                </Box>
                                <Box mt={3} ml={2} display="flex" justifyContent={"center"} alignItems="center">
                                    <Button variant="contained" className={classes.yesbtn} onClick={() => closeModal()}>{t("Okay")}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />
        </Box>
    )
}