import React from "react"

export const ResourceAsset = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.16" viewBox="0 0 24 24.16">
            <g id="Group_118506" data-name="Group 118506" transform="translate(-197 -106)">
                <g id="Rectangle_52035" data-name="Rectangle 52035" transform="translate(197 106)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-worker_1_" data-name="icons8-worker (1)" d="M16.751,1a.818.818,0,0,0-.842.818v.363a6,6,0,0,0-4.727,3.728h-.728a.818.818,0,0,0,0,1.636H23a.818.818,0,1,0,0-1.636h-.671a6,6,0,0,0-4.783-3.733V1.818A.818.818,0,0,0,16.751,1Zm-6,7.636a6,6,0,0,0,11.972,0Zm-.3,7.636A2.46,2.46,0,0,0,8,18.727v.654c0,3.071,3.649,5.5,8.367,5.619h.72c4.718-.12,8.367-2.548,8.367-5.619v-.654A2.46,2.46,0,0,0,23,16.272Z" transform="translate(192 105.161)" fill="#b3776d" />
            </g>
        </svg>
    )
}