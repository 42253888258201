import React from "react"

export const SickLeaveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_106762" data-name="Group 106762" transform="translate(-3837 23803)">
            <g id="Rectangle_55649" data-name="Rectangle 55649" transform="translate(3837 -23803)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <path id="icons8-hotel-bed" d="M11.195,4a3.122,3.122,0,1,0,3.122,3.122A3.122,3.122,0,0,0,11.195,4Zm0,1.171a.585.585,0,1,1-.585.585A.586.586,0,0,1,11.195,5.171Zm0,1.561a.39.39,0,0,1,.39.39V8.683a.39.39,0,0,1-.78,0V7.122A.39.39,0,0,1,11.195,6.732ZM3.576,7.5A.585.585,0,0,0,3,8.1V19.415a.585.585,0,1,0,1.171,0v-.585H18.219v.585a.585.585,0,1,0,1.171,0V17.073a1.366,1.366,0,0,0-1.366-1.366H4.171V8.1A.585.585,0,0,0,3.576,7.5ZM6.9,11.415a1.561,1.561,0,1,0,1.561,1.561A1.561,1.561,0,0,0,6.9,11.415Zm4.162,0a1.43,1.43,0,0,0-1.43,1.43v1.692h8.349a2.144,2.144,0,0,0-1.91-3.122Z" transform="translate(3838 -23803)" fill="#ff4b4b" />
        </g>
    </svg>

)