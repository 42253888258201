import React from "react";
import SetPassword from "./setpassword";
import { LoginSuccess } from "../../router/access";
import { LocalStorageKeys } from "../../utils";
import { withRouter } from 'react-router-dom';

class SetPasswordPagetParent extends React.Component {
    componentDidMount() {
        if (localStorage.getItem(LocalStorageKeys?.authToken)) {
            if (localStorage.getItem(LocalStorageKeys?.permission)) {
                this?.props?.history?.push(LoginSuccess(JSON.parse(localStorage.getItem(LocalStorageKeys.permission))))

            }
        }
    }
    render() {
        return <SetPassword />;
    }
}

export default withRouter(SetPasswordPagetParent);