export const ContactPath = [
    "image",
    "name",
    "department",
    "ucid",
    "jobTitle",
    "mobileNumber",
    "email",
    "relationship",
    "lastLogin",
    "status"
]

export const ContactHeading = [
    { title: "Image", field: "image" },
    { title: "UCID", field: "ucid" },
    { title: "Name", field: "name" },
    { title: "Department", field: "department" },
    { title: "Job Title", field: "jobTitle" },
    { title: "Mobile Number", field: "mobileNumber" },
    { title: "Email", field: "email" },
    { title: "Relationship", field: "relationship" },
    { title: "Last Login", field: "lastLogin" },
    { title: "Status", field: 'status', },
    { title: "", field: "icon" },
]

export const ContactType = [
    { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "ucid" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "department" },
    { type: ["text"], name: "jobTitle" },
    { type: ["text"], name: "mobileNumber" },
    { type: ["text"], name: "email" },
    { type: ["text"], name: "relationship" },
    { type: ["date"], name: "lastLogin" },
    { type: ["status"], name: "status" },
    { type: ["more_4"], icon: "icon" },
];