import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: "24px",
    textAlign: "center",
    padding: "14px 0px",
    cursor: "pointer",
    boxShadow: "0px 2px 6px #00000014",
    margin: "8px",
  },
  board:{
    backgroundColor: "white",
    borderRadius: theme.palette.borderRadius,
    border:"1px solid #E4E8EE",
    textAlign: "center",
    padding: "14px 0px",
    cursor: "pointer",
    margin: "8px",
  },
  title: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
  },
}));

export const ActivityCard = (props) => {
  const classes = useStyles();
  const {t = () => false} = props

  return (
    <div className={props?.activityBoard?classes.board:classes.root} onClick={props?.onClick}>
      <img src={props.data.image} alt={props.data.title} />
      <Typography className={classes.title}>{t(props.data.title)}</Typography>
    </div>
  );
};
