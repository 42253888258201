export const DeliveryCollections = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Delivery Collection">
            <path data-name="Rectangle 52494" fill="none" d="M0 0h30v30H0z" />
            <g data-name="Delivery Collection">
                <g data-name="Group 99023" transform="translate(7 9)" fill={props?.fill ?? "#c1c5cb"}>
                    <path
                        data-name="Path 95317"
                        d="M10.268-.003H.518A.508.508 0 0 0 .005.51v6.158a.511.511 0 0 0 .513.513h9.75a.515.515 0 0 0 .513-.513V.513a.511.511 0 0 0-.513-.516Z"
                    />
                    <path
                        data-name="Path 95318"
                        d="M0 9.749a.515.515 0 0 0 .513.513h.554a2.585 2.585 0 0 1 2.2-2.053H.518A1.45 1.45 0 0 1 0 8.117Z"
                    />
                    <path
                        data-name="Path 95319"
                        d="M14.851 10.263h.636A.515.515 0 0 0 16 9.75v-4.5c0-.806-1.965-2.689-3.043-2.689h-1.149v4.105a1.544 1.544 0 0 1-1.539 1.539H3.931a2.585 2.585 0 0 1 2.2 2.053h3.66a2.579 2.579 0 0 1 5.06 0Zm-2.2-6.435c2.627-.128 2.52 2.586 2.52 2.586h-2.52Z"
                    />
                    <circle
                        data-name="Ellipse 129233"
                        cx={1.55}
                        cy={1.55}
                        r={1.55}
                        transform="translate(10.769 9.213)"
                    />
                    <circle
                        data-name="Ellipse 129234"
                        cx={1.55}
                        cy={1.55}
                        r={1.55}
                        transform="translate(2.045 9.213)"
                    />
                </g>
            </g>
        </g>
    </svg>
)
