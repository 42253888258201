import React from "react";
import { withRouter } from "react-router-dom";
import { withNavBars } from "../../HOCs";
import { Routes } from "../../router/routes";
import { ConfigurationMaster } from "./configurationMaster";
import { ConfigurationMasterViewEdit } from "./ConfigurationMasterViewEdit";

class ConfigurationMasterParent extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <>
        {match.path === Routes.configuration && <ConfigurationMaster {...this.props} />}
        {match.path === Routes.configuration_details && <ConfigurationMasterViewEdit {...this.props} />}
        {match.path === Routes.configuration_add && <ConfigurationMasterViewEdit {...this.props} />}
      </>
    )
  }
}
const props = {
  boxShadow: false
}
export default withRouter(withNavBars(ConfigurationMasterParent, props));