import * as React from "react"

const DebitNotesSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 102949" transform="translate(-11563 -17227)">
      <circle
        data-name="Ellipse 129452"
        cx={24}
        cy={24}
        r={24}
        transform="translate(11563 17227)"
        fill="#fff4eb"
      />
      <g data-name="Group 107571">
        <path
          data-name="Rectangle 54909"
          fill="none"
          d="M11575 17239h24v24h-24z"
        />
        <path
          d="M11585.864 17242a1.587 1.587 0 0 0-1.591 1.579v7.669c0 .029.008.057.009.085l1.355-.8v-5.826a1.357 1.357 0 0 0 1.363-1.354h9.318a.677.677 0 1 0 0-1.353Zm7.045 2.256a2.97 2.97 0 0 0-2.727 3.158 2.97 2.97 0 0 0 2.727 3.158 2.97 2.97 0 0 0 2.727-3.158 2.97 2.97 0 0 0-2.727-3.158Zm-5.454 2.255a.9.9 0 1 0 .909.9.906.906 0 0 0-.909-.9Zm-4.091.224-1.336.408a3.408 3.408 0 0 0-1.906 1.467l-3.025 5a.669.669 0 0 0-.097.345v4.511a.68.68 0 0 0 .682.677h3.718a3.866 3.866 0 0 0 2.641-1.043l5.631-5.177a1.612 1.612 0 0 0 .028-2.323 1.63 1.63 0 0 0-2.025-.225l-4 2.353a.679.679 0 1 1-.7-1.163l.38-.224Zm7.7 4.739a2.183 2.183 0 0 1-.222 1.353h5.476a.677.677 0 1 0 0-1.353Z"
          fill="#ff9340"
        />
      </g>
    </g>
  </svg>
)

export default DebitNotesSvg
