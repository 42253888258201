import * as React from "react"
const UnVerifiedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={0.427}
        x2={0.569}
        y1={57.915}
        y2={58.921}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0370c8" />
        <stop offset={0.484} stopColor="#036fc5" />
        <stop offset={0.775} stopColor="#036abd" />
        <stop offset={1} stopColor="#0362b0" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={0.411}
        x2={0.605}
        y1={217.254}
        y2={218.337}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#75daff" />
        <stop offset={1} stopColor="#1ea2e4" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={0.259}
        x2={0.55}
        y1={102.26}
        y2={103.248}
      />
      <linearGradient
        id="e"
        x1={-7.384}
        x2={0.806}
        y1={91.886}
        y2={103.489}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffda1c" />
        <stop offset={1} stopColor="#feb705" />
      </linearGradient>
      <radialGradient
        id="c"
        cx={0.5}
        cy={0.5}
        r={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={0.009} stopOpacity={0.992} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="f"
        cx={-0.656}
        cy={-0.011}
        r={1.002}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#4b4b4b" />
        <stop offset={0.531} stopColor="#393939" />
        <stop offset={1} stopColor="#252525" />
      </radialGradient>
    </defs>
    <g data-name="Group 113352">
      <path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 57483" />
      <g transform="translate(-1.285 -1)">
        <path
          fill="url(#b)"
          d="M6.143 9h19.428a1.143 1.143 0 0 1 1.143 1.143V25a1.143 1.143 0 0 1-1.143 1.143H6.143A1.143 1.143 0 0 1 5 25V10.143A1.143 1.143 0 0 1 6.143 9Z"
          data-name="Path 100281"
          transform="translate(0 -1.714)"
        />
        <path
          d="M14.714 18.715a3.429 3.429 0 1 0-6.857 0v1.714A1.143 1.143 0 0 0 9 21.572h4.571a1.143 1.143 0 0 0 1.143-1.143Z"
          data-name="Path 100282"
          opacity={0.05}
        />
        <path
          d="M11.286 15.571a3.143 3.143 0 0 0-3.143 3.143v1.714a.857.857 0 0 0 .857.857h4.571a.857.857 0 0 0 .857-.857v-1.714a3.143 3.143 0 0 0-3.142-3.143Z"
          data-name="Path 100283"
          opacity={0.07}
        />
        <path
          d="M22.714 13.571H17a1.143 1.143 0 0 0 0 2.286h5.714a1.143 1.143 0 0 0 0-2.286Z"
          data-name="Path 100284"
          opacity={0.05}
        />
        <path
          d="M17 13.857a.857.857 0 0 0-.857.857.857.857 0 0 0 .857.857h5.714a.857.857 0 0 0 .857-.857.857.857 0 0 0-.857-.857Z"
          data-name="Path 100285"
          opacity={0.07}
        />
        <path
          d="M22.714 16.429H17a1.143 1.143 0 0 0 0 2.286h5.714a1.143 1.143 0 0 0 0-2.286Z"
          data-name="Path 100286"
          opacity={0.05}
        />
        <path
          d="M17 16.714a.857.857 0 0 0-.857.857.857.857 0 0 0 .857.857h5.714a.857.857 0 0 0 .857-.857.857.857 0 0 0-.857-.857Z"
          data-name="Path 100287"
          opacity={0.07}
        />
        <circle
          cx={2.286}
          cy={2.286}
          r={2.286}
          fill="url(#c)"
          data-name="Ellipse 129698"
          transform="translate(9 10.714)"
        />
        <path
          fill="url(#a)"
          d="M24 5h-3.429a.572.572 0 0 0-.571.571V9a.572.572 0 0 0 .571.571H24A.572.572 0 0 0 24.571 9V5.571A.572.572 0 0 0 24 5Zm-1.714 3.429a.571.571 0 1 1 .571-.571.572.572 0 0 1-.571.571Z"
          data-name="Path 100288"
          transform="translate(-6.429)"
        />
        <path
          fill="url(#d)"
          d="M12.143 17.714a1.714 1.714 0 1 1 1.714 1.714 1.714 1.714 0 0 1-1.714-1.714Zm1.714 2.857A2.857 2.857 0 0 0 11 23.429v1.714a.572.572 0 0 0 .571.571h4.571a.572.572 0 0 0 .571-.571v-1.714a2.857 2.857 0 0 0-2.856-2.858Z"
          data-name="Path 100289"
          transform="translate(-2.571 -4.714)"
        />
        <path
          fill="#75daff"
          d="M22.715 17H17a.572.572 0 0 0-.571.571.572.572 0 0 0 .571.571h5.714a.572.572 0 0 0 .571-.571.572.572 0 0 0-.57-.571Z"
          data-name="Path 100290"
        />
        <path
          fill="#75daff"
          d="M22.715 14.143H17a.572.572 0 0 0-.571.571.572.572 0 0 0 .571.571h5.714a.572.572 0 0 0 .571-.571.572.572 0 0 0-.57-.571Z"
          data-name="Path 100291"
        />
        <path
          d="M26.714 22.691v.594a1.146 1.146 0 0 1-1.143 1.143h-6.24l3.251-5.149a1.123 1.123 0 0 1 .989-.565 1.138 1.138 0 0 1 1.017.617Z"
          data-name="Path 100292"
          opacity={0.05}
        />
        <path
          d="M26.714 23.223v.063a1.146 1.146 0 0 1-1.143 1.143h-5.908l3.166-5a.842.842 0 0 1 .743-.429.858.858 0 0 1 .76.457Z"
          data-name="Path 100293"
          opacity={0.07}
        />
        <path
          fill="#F3E137"
          d="m38.9 38.82-5.4-8.52a.574.574 0 0 0-1.006 0L27.1 38.82a.6.6 0 0 0-.1.327.569.569 0 0 0 .571.567h10.858a.569.569 0 0 0 .571-.567.6.6 0 0 0-.1-.327Z"
          data-name="Path 100294"
          transform="translate(-9.429 -10.714)"
        />
        <path
          fill="url(#f)"
          d="M37.018 40.714a.644.644 0 0 1-.457-.168.536.536 0 0 1-.18-.407.519.519 0 0 1 .182-.409.666.666 0 0 1 .455-.159.647.647 0 0 1 .454.161.527.527 0 0 1 .177.407.539.539 0 0 1-.175.412.642.642 0 0 1-.456.163Zm.635-5.5-.138 3.586a.2.2 0 0 1-.2.2h-.623a.2.2 0 0 1-.2-.2l-.13-3.591a.2.2 0 0 1 .2-.212h.89a.2.2 0 0 1 .201.216Z"
          data-name="Path 100295"
          transform="translate(-13.443 -12.857)"
        />
      </g>
    </g>
  </svg>
)
export default UnVerifiedIcon
