import { Avatar, Box, Typography } from "@mui/material";
import { stringAvatar } from "../../utils";
import { useStyles } from "./styles";
export const DropDownCard = ({ data = {}, onClick = () => false, disable = false }) => {
    const classes = useStyles()
    return (

        <div>
            <Box display="flex" alignItem="center" >
                <Box marginTop="4px">
                    <Avatar style={{marginInlineEnd:"8px"}} sx={{ height: 30, width: 30 }} src={data?.contact?.get_assets_url_id?.length > 0 ? data?.contact?.get_assets_url_id : null} 
                     {...stringAvatar(data?.contact?.first_name)}/>
                </Box>
                <Box flexGrow={1} marginLeft="8px">
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            <Typography className={classes.name1} noWrap>{data?.lead_no ?? ""}</Typography>
                            <Typography className={classes.email} noWrap>{data?.contact?.first_name ?? ""}&nbsp;{data?.contact?.last_name ?? ""}</Typography>

                        </Box>

                    </Box>

                </Box>
            </Box>

        </div >
    )
}