import React from "react";
import { withNavBars } from "../../HOCs";
import ParkingArea from "./parkingArea";

class ParkingAreaParent extends React.Component {
  render() {
    return <ParkingArea />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ParkingAreaParent, props);
