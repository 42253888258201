import React from "react";
import { withNavBars } from "../../HOCs";
import Subscriptions from "./subscriptions";
import SubscriptionsContext from "./subscriptionsContext";

class SubscriptionsParent extends React.Component {
    render() {
        return (
            <SubscriptionsContext {...this.props}>
                <Subscriptions />
            </SubscriptionsContext>
        );
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(SubscriptionsParent, props);
