import React from "react";
import { withNavBars } from "../../HOCs";
import ExpenseGroupMaster from "./expenseGroupMaster";

class ExpenseGroupMasterParent extends React.Component {
  render() {
    return <ExpenseGroupMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ExpenseGroupMasterParent, props);
