export const Path = ["name", "description", "is_active"]

export const Heading = (t) => [
    { title: t("Name"), field: "name" },
    { title: t("Description"), field: "description" },
    { title: t("Status"), field: "is_active", },
    { title: "", field: "icon" },
]

export const Type = [
    { type: ["text"], name: "name" },
    { type: ["description"], name: "description" },
    { type: ["status"], name: "is_active" },
    { type: ["more"], icon: "icon" },
]

export const defaultAddState = {
    formType: "add",
    id: "",
    name: "",
    description: "",
    is_active: true,
    is_delete: false,
    error: {
        name: "",
    }
}

export const addAmenityActiveOptions = [
    {
      label: ("Active"),
      value: true,
    },
    {
      label: ("Inactive"),
      value: false,
    },
  ];

export const defaultFilterData = { is_active: [true] }