import React from "react";
import { withNavBars } from "../../HOCs";
import QuotationsList from "./quotations";

class QuotationParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <QuotationsList   {...this?.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(QuotationParent, props);
