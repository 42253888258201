

export const ResidentPath = ["saleRep", "name", "unit_name", "mobile_number", 'occupants_count', "last_login"]

export const ResidentHeading = (t) => [
    { title: t("Picture"), field: "saleRep" },
    { title: t("Name"), field: "name" },
    { title: t("Unit Name"), field: "unit_name" },
    { title: t("Mobile Number"), field: "mobile_number" },
    { title: t("Occupants"), field: "occupants_count" },
    { title: t("Last Login"), field: "last_login" },
    // { title: t("Lease Start Date"), field: "start_date" },
    // { title: t("Lease End Date"), field: "end_date" },


]

export const ResidentType = [
    { type: ["avatarTextDashboard"], name: "saleRep" },
    // { type: ["avatar"], name: "saleRep" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "mobile_number" },
    { type: ["text"], name: "occupants_count" },
    { type: ["date"], name: "last_login" },
    // { type: ["date"], name: "end_date" },


    // { title: "Unit Name", field: "unitName" },

]
export const OwnerPath = ["saleRep", "name", "unit_name", "mobile_number", 'occupants_count', "last_login"]

export const OwnerHeading =(t) =>[
    { title: t("Picture"), field: "saleRep" },
    { title: t("Name"), field: "name" },
    { title: t("Unit Name"), field: "unit_name" },
    { title: t("Mobile Number"), field: "mobile_number" },
    { title: t("Occupants"), field: "occupants_count" },
    { title: t("Last Login"), field: "last_login" },
    // { title: "Unit Name", field: "unitName" },

]


export const OwnerType = [
    { type: ["avatarTextDashboard"], name: "saleRep" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "mobile_number" },
    { type: ["text"], name: "occupants_count" },
    { type: ["date"], name: "last_login" },
    // { type: ["text"], name: "unitName" },

]
export const DiscussionPath = ["title", "created_by", "unit_name", "threads_count"]

export const DiscussionHeading = (t) =>[
    { title: t("Discussion"), field: "title" },
    { title: t("Created By"), field: "created_by" },
    { title: t("Unit Name"), field: "unit_name" },
    { title: t("No.of threads"), field: "threads_count" },

]

export const DiscussionDataType = [
    { type: ["text"], name: "title" },
    { type: ["text"], name: "created_by" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "threads_count" },
]
export const AmenityBookingPath = ["booking_id", "amenities_name", "total_hours", "booked_by", "unit_name", "payment_status"]

export const AmenityBookingHeading = (t) => [
    { title: t("Booking Id"), field: "booking_id" },
    { title: t("Amenity"), field: "amenities_name" },
    { title: t("Duration"), field: "total_hours" },
    { title: t("Booked By"), field: "booked_by" },
    { title: t("Unit Name"), field: "unit_name" },
    { title: t("Payment Status"), field: "payment_status" },
    // { title: "Slot Status", field: "slot_status" },

]

export const AmenityBookingDataType = [
    { type: ["text"], name: "booking_id" },
    { type: ["text"], name: "amenities_name" },
    { type: ["text"], name: "total_hours" },
    { type: ["text"], name: "booked_by" },
    { type: ["text"], name: "unit_name" },
    { type: ["aminities_booking_status"], name: "payment_status" },
    // { type: ["aminities_booking_status"], name: "slot_status" },
]