import * as React from "react"
const ParkingSlotIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32.5}
    height={32}
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={0.139}
        x2={0.78}
        y1={-0.041}
        y2={1.133}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor={props?.color?props?.color:"#5078E1"} />
        <stop offset={1} stopColor={props?.color?props?.color:"#5078E1"} />
      </linearGradient>
      <linearGradient
        id="a"
        x1={0.5}
        x2={0.5}
        y1={-0.597}
        y2={1.331}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor={props?.color?props?.color:"#5078E1"} />
        <stop offset={1} stopColor={props?.color?props?.color:"#5078E1"} />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={0.5}
        x2={0.5}
        y1={-5.03}
        y2={1.824}
      />
    </defs>
    <g data-name="Group 113643">
      <g data-name="icons8-parking-and-3rd-floor (1)">
        <path
          fill={props?.color?props?.color:"#5078E1"}
          d="M31.293 12.585 21.146 4 11 12.585v20.293a1.561 1.561 0 0 0 1.561 1.561h17.171a1.561 1.561 0 0 0 1.561-1.561Z"
          data-name="Path 100530"
          transform="translate(-.646 -2.439)"
        />
        <path
          fill={props?.color?props?.color:"#5078E1"}
          d="M32.432 12.086 21.389 2.29a1.153 1.153 0 0 0-1.528 0l-11.043 9.8a.576.576 0 0 0-.037.824l.943 1.005a.575.575 0 0 0 .8.038l10.1-8.827 10.1 8.827a.577.577 0 0 0 .8-.038l.943-1.005a.577.577 0 0 0-.035-.828Z"
          data-name="Path 100531"
          transform="translate(-.125 -2)"
        />
        <path
          fill={props?.color?props?.color:"#5078E1"}
          d="M31.634 26.561H9.78A.781.781 0 0 1 9 25.78a.781.781 0 0 1 .78-.78h21.854a.781.781 0 0 1 .78.78.781.781 0 0 1-.78.781Z"
          data-name="Path 100532"
          transform="translate(-.207 -7.049)"
        />
        <path
          d="M20.377 7.804a2.435 2.435 0 0 1 1.612.506 1.613 1.613 0 0 1 .606 1.3 1.846 1.846 0 0 1-1.518 1.914v.021a1.988 1.988 0 0 1 1.29.571 1.7 1.7 0 0 1 .474 1.23 2.016 2.016 0 0 1-.763 1.641 3.079 3.079 0 0 1-2.012.624 3.427 3.427 0 0 1-1.79-.406v-1.149a2.611 2.611 0 0 0 1.664.585 1.938 1.938 0 0 0 1.2-.343 1.1 1.1 0 0 0 .45-.927q0-1.285-1.964-1.285h-.581v-.98h.558q1.742.001 1.742-1.211 0-1.117-1.359-1.117a2.231 2.231 0 0 0-1.428.542V8.236a3.656 3.656 0 0 1 1.818-.432m0-.78a4.437 4.437 0 0 0-2.2.532l-.4.224v3.179l.485-.393v2.3h1.365c1.184 0 1.184.354 1.184.5a.328.328 0 0 1-.142.305 1.207 1.207 0 0 1-.731.183 1.831 1.831 0 0 1-1.179-.416l-1.265-1v3.2l.376.228a4.2 4.2 0 0 0 2.195.519 3.847 3.847 0 0 0 2.505-.8 2.806 2.806 0 0 0 1.051-2.245 2.487 2.487 0 0 0-.872-1.94 2.653 2.653 0 0 0 .624-1.8 2.4 2.4 0 0 0-.886-1.9 3.2 3.2 0 0 0-2.109-.676Zm-1.817 3.3.492-.4a1.463 1.463 0 0 1 .936-.368c.578 0 .578.133.578.336 0 .151 0 .431-.962.431H18.56Z"
          data-name="Path 100533"
          opacity={0.05}
        />
        <path
          d="M20.376 7.805a2.435 2.435 0 0 1 1.612.506 1.613 1.613 0 0 1 .606 1.3 1.846 1.846 0 0 1-1.516 1.917v.021a1.988 1.988 0 0 1 1.29.571 1.7 1.7 0 0 1 .474 1.23 2.016 2.016 0 0 1-.763 1.641 3.079 3.079 0 0 1-2.012.624 3.427 3.427 0 0 1-1.79-.406v-1.152a2.611 2.611 0 0 0 1.664.585 1.938 1.938 0 0 0 1.2-.343 1.1 1.1 0 0 0 .45-.927q0-1.285-1.964-1.285h-.585v-.98h.558q1.743 0 1.743-1.211 0-1.117-1.359-1.117a2.231 2.231 0 0 0-1.428.542V8.237a3.656 3.656 0 0 1 1.818-.432m0-.39a4.058 4.058 0 0 0-2.008.482l-.2.112v2.132l.636-.516a1.831 1.831 0 0 1 1.182-.455c.852 0 .969.349.969.727 0 .246 0 .821-1.353.821h-.945v1.76h.975c1.574 0 1.574.65 1.574.894a.716.716 0 0 1-.3.616 1.577 1.577 0 0 1-.967.263 2.212 2.212 0 0 1-1.422-.5l-.633-.5v2.176l.188.114a3.822 3.822 0 0 0 1.993.462 3.473 3.473 0 0 0 2.259-.712 2.4 2.4 0 0 0 .913-1.945 2.086 2.086 0 0 0-.583-1.5 2.11 2.11 0 0 0-.519-.395 2.178 2.178 0 0 0 .855-1.844 2 2 0 0 0-.753-1.592 2.819 2.819 0 0 0-1.861-.6Z"
          data-name="Path 100534"
          opacity={0.07}
        />
        <path
          fill="#fff"
          d="M18.276 15.205v-1.149a2.611 2.611 0 0 0 1.664.585 1.938 1.938 0 0 0 1.2-.343 1.1 1.1 0 0 0 .45-.927q0-1.285-1.964-1.285h-.585v-.981h.558q1.743 0 1.743-1.211 0-1.117-1.359-1.116a2.231 2.231 0 0 0-1.428.542V8.237a3.656 3.656 0 0 1 1.822-.432 2.435 2.435 0 0 1 1.612.506 1.613 1.613 0 0 1 .606 1.3 1.846 1.846 0 0 1-1.516 1.917v.021a1.988 1.988 0 0 1 1.29.571 1.7 1.7 0 0 1 .474 1.23 2.016 2.016 0 0 1-.763 1.641 3.079 3.079 0 0 1-2.012.624 3.434 3.434 0 0 1-1.792-.41Z"
          data-name="Path 100535"
        />
        <g data-name="Group 113672">
          <path
            d="M20.526 21.853a3.135 3.135 0 0 1 2.085.626 2.183 2.183 0 0 1 .74 1.763 2.436 2.436 0 0 1-.773 1.861 2.936 2.936 0 0 1-2.09.724h-1.04v2.476a.355.355 0 0 1-.355.355h-.581a.355.355 0 0 1-.354-.355v-7.1a.355.355 0 0 1 .355-.354h2.012m-1.076 3.924h.838a1.855 1.855 0 0 0 1.268-.386 1.379 1.379 0 0 0 .436-1.089q0-1.377-1.6-1.377h-.941v2.852m1.076-4.7h-2.013a1.137 1.137 0 0 0-1.135 1.136v7.1a1.137 1.137 0 0 0 1.136 1.135h.581a1.137 1.137 0 0 0 1.136-1.136v-1.7h.259a3.7 3.7 0 0 0 2.623-.935 3.2 3.2 0 0 0 1.02-2.431 2.937 2.937 0 0 0-1.017-2.359 3.9 3.9 0 0 0-2.59-.81Zm-.3 2.628h.161c.82 0 .82.29.82.6a.591.591 0 0 1-.173.5 1.213 1.213 0 0 1-.75.19h-.056v-1.29Z"
            data-name="Path 100536"
            opacity={0.05}
          />
          <path
            d="M20.525 21.853a3.135 3.135 0 0 1 2.085.626 2.183 2.183 0 0 1 .74 1.763 2.436 2.436 0 0 1-.773 1.861 2.936 2.936 0 0 1-2.09.724h-1.04v2.476a.355.355 0 0 1-.355.355h-.581a.355.355 0 0 1-.354-.355v-7.1a.355.355 0 0 1 .355-.354h2.012m-1.076 3.923h.838a1.855 1.855 0 0 0 1.268-.386 1.379 1.379 0 0 0 .436-1.089q0-1.377-1.6-1.377h-.941v2.852m1.076-4.309h-2.013a.746.746 0 0 0-.745.745v7.1a.746.746 0 0 0 .745.745h.581a.746.746 0 0 0 .745-.745v-2.09h.649a3.321 3.321 0 0 0 2.357-.83 2.823 2.823 0 0 0 .9-2.146 2.564 2.564 0 0 0-.879-2.061 3.524 3.524 0 0 0-2.337-.718Zm-.687 1.847h.551c1.066 0 1.21.5 1.21.987a1 1 0 0 1-.3.8 1.5 1.5 0 0 1-1.009.288h-.452V23.31Z"
            data-name="Path 100537"
            opacity={0.07}
          />
          <path
            fill="#fff"
            d="M19.448 26.828v2.476a.355.355 0 0 1-.355.355h-.581a.356.356 0 0 1-.354-.356v-7.1a.355.355 0 0 1 .355-.35h2.013a3.135 3.135 0 0 1 2.085.626 2.183 2.183 0 0 1 .74 1.763 2.437 2.437 0 0 1-.773 1.861 2.936 2.936 0 0 1-2.09.724h-1.04Zm0-3.908v2.852h.838a1.855 1.855 0 0 0 1.268-.386 1.379 1.379 0 0 0 .436-1.089q0-1.377-1.6-1.377Z"
            data-name="Path 100538"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default ParkingSlotIcon
