export const MoveOut = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98470">
            <path
                data-name="Path 94998"
                d="m21.393 14.118-1.619-1.619a.237.237 0 0 1 0-.334l.33-.33a.235.235 0 0 1 .334 0l1.619 1.619v-.513a.236.236 0 0 1 .237-.233h.466a.235.235 0 0 1 .237.235v1.648a.471.471 0 0 1-.471.471h-1.644a.237.237 0 0 1-.235-.238v-.466a.234.234 0 0 1 .233-.238h.513Zm-3.1.471h-.471a4.235 4.235 0 0 0-4.234 4.235v.471H7.471A.471.471 0 0 1 7 18.824V8.471A.471.471 0 0 1 7.471 8h10.353a.471.471 0 0 1 .471.471Zm-2.814 6.587h-.008v-.471a4.235 4.235 0 0 1 4.235-4.235c.141 0 .471 0 .471.008v4.227a.471.471 0 0 1-.471.471Z"
                fill={props?.fill ?? "#c1c5cb"}
                fillRule="evenodd"
            />
            <path data-name="Rectangle 52154" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
