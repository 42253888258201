import * as React from "react"
const ContractTypeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={38}
    data-name="Group 115457"
    {...props}
  >
    <path
      fill="#fff8e7"
      d="M4.75 0h28.5A4.75 4.75 0 0 1 38 4.75v28.5A4.75 4.75 0 0 1 33.25 38H4.75A4.75 4.75 0 0 1 0 33.25V4.75A4.75 4.75 0 0 1 4.75 0Z"
      data-name="Path 100578"
    />
    <path
      fill="#ff9340"
      d="M16.083 8.999a2.5 2.5 0 0 0-2.583 2.5v8.5h1.75a3.755 3.755 0 0 1 3.75 3.75v4.5a1.733 1.733 0 0 1-.176.75h5.426a.75.75 0 0 0 .75-.75v-15a2.5 2.5 0 0 0-1.85-2.414l-6.5-1.75a2.462 2.462 0 0 0-.567-.086Zm.917 4.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm3.5 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm-3.5 3.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm3.5 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm5.5 0v3.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1v1.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1v3h2.75a.75.75 0 0 0 .75-.75v-8.5a2.758 2.758 0 0 0-2.749-2.75Zm-5.5 3.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm-9.25.5A2.758 2.758 0 0 0 8.5 23.75v4.5a.75.75 0 0 0 .75.75h8a.75.75 0 0 0 .75-.75v-4.5a2.758 2.758 0 0 0-2.75-2.751Zm9.25 3h1a.491.491 0 0 1 .39.2h.01v.016a.486.486 0 0 1 .1.284v1a.487.487 0 0 1-.1.285v.015h-.01a.491.491 0 0 1-.39.2h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm-9.5 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm3.5 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Z"
    />
  </svg>
)
export default ContractTypeIcon
