import React from "react";
import { withNavBars } from "../../HOCs";
import BlockView from "./blockView";

class BlockViewingParent extends React.Component {
    render() {
        return <BlockView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(BlockViewingParent, props);
