import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.secondary,
    },
    body: {
        padding: "16px",
        height: `calc(100vh - 118px)`,
        overflow: "overlay",
    },
    table: {
        width: "100%",
        height: "100%",
    },
    graph: {
        width: "100%",
        height: "100%",
    },
}));