export const AlreadyPaid = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 30 30"
    style={{
      enableBackground: "new 0 0 30 30",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st0{fill:#c1c5cb}"}</style>
    <path
      className="st0"
      d="M18.8 7c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2S21.1 7 18.8 7zm-.5 6.3-1.7-1.8.6-.6 1.1 1.1 2-2 .6.6-2.6 2.7zM9.8 22.7v-2.6H7.3l2.5 2.6z"
      style={{
        fill: props?.fill ?? "#c1c5cb",
      }}
    />
    <path
      className="st0"
      d="M16.4 16.4H8.9v-1h6.9c-.4-.3-.7-.6-1-1H8.9v-1h5.3c-.2-.3-.3-.6-.4-1h-5v-.9h4.7c0-.2-.1-.3-.1-.5 0-.9.2-1.7.6-2.3H7V19h3.7v4h7.5v-6.6c-.7-.1-1.3-.3-1.9-.5v.5zm0 3.8h-4.7v-1h4.7v1zm0-1.9h-4.7v-1h4.7v1z"
      style={{
        fill: props?.fill ?? "#c1c5cb",
      }}
    />
  </svg>
)
