import React from "react"

export const ServiceOfferedIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg"  width="36" height="36" viewBox="0 0 36 36">
  <defs>
    <linearGradient id="linear-gradient" x1="0.5" y1="0.925" x2="0.5" y2="0.098" gradientUnits="objectBoundingBox">
      <stop offset="0.859" stop-color="#d97218"/>
      <stop offset="0.904" stop-color="#c46716"/>
      <stop offset="1" stop-color="#ba6215"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="-0.102" y1="0.5" x2="1.072" y2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#ffcf54"/>
      <stop offset="0.261" stop-color="#fdcb4d"/>
      <stop offset="0.639" stop-color="#f7c13a"/>
      <stop offset="1" stop-color="#f0b421"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" x1="0.5" y1="-0.556" x2="0.5" y2="1.299" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#eba84b"/>
      <stop offset="0.252" stop-color="#e9a144"/>
      <stop offset="0.624" stop-color="#e28d31"/>
      <stop offset="1" stop-color="#d97218"/>
    </linearGradient>
    <linearGradient id="linear-gradient-5" x1="0.905" y1="40.345" x2="1.592" y2="39.658"/>
  </defs>
  <g id="Group_113555" data-name="Group 113555" transform="translate(10044 23263)">
    <rect id="icons8-smart-home-checked-96" width="36" height="36" transform="translate(-10044 -23263)" fill="#fff" opacity="0"/>
    <g id="icons8-reception" transform="translate(-10046 -23268)">
      <path id="Path_100179" data-name="Path 100179" d="M6,24H31V39a1.667,1.667,0,0,1-1.667,1.667H7.667A1.667,1.667,0,0,1,6,39Z" transform="translate(-0.333 -2.667)" fill="url(#linear-gradient)"/>
      <path id="Path_100180" data-name="Path 100180" d="M15.333,14.667a3.333,3.333,0,1,1,3.333-3.333,3.333,3.333,0,0,1-3.333,3.333" transform="translate(-1.333 0)" fill="url(#linear-gradient-2)"/>
      <path id="Path_100181" data-name="Path 100181" d="M36.333,14.667a3.333,3.333,0,1,1,3.333-3.333,3.333,3.333,0,0,1-3.333,3.333" transform="translate(-4.833 0)" fill="url(#linear-gradient-2)"/>
      <path id="Path_100182" data-name="Path 100182" d="M9,22.219a6.142,6.142,0,0,1,11.667,0v1.406H9Z" transform="translate(-0.833 -1.667)" fill="#199be2"/>
      <rect id="Rectangle_57499" data-name="Rectangle 57499" width="28.333" height="3.333" rx="1.667" transform="translate(4 19.667)" fill="url(#linear-gradient-4)"/>
      <path id="Path_100183" data-name="Path 100183" d="M36.333,14.667a3.333,3.333,0,1,1,3.333-3.333,3.333,3.333,0,0,1-3.333,3.333" transform="translate(-4.833 0)" fill="url(#linear-gradient-5)"/>
      <path id="Path_100184" data-name="Path 100184" d="M33,28h2.5V40.083a1.25,1.25,0,0,1-1.25,1.25h0A1.25,1.25,0,0,1,33,40.083Z" transform="translate(-4.833 -3.333)" fill="#35c1f1"/>
      <path id="Path_100185" data-name="Path 100185" d="M38,28h2.5V40.083a1.25,1.25,0,0,1-1.25,1.25h0A1.25,1.25,0,0,1,38,40.083Z" transform="translate(-5.667 -3.333)" fill="#35c1f1"/>
      <path id="Path_100186" data-name="Path 100186" d="M25.226,26.867l4.963-7.089A4.167,4.167,0,0,1,33.6,18h2.232A4.167,4.167,0,0,1,40,22.166v7.917a1.247,1.247,0,0,1-2.423.417H35.833a.833.833,0,1,0-1.667,0h-2.5V23.555a.482.482,0,0,0-.878-.277L27.274,28.3a1.25,1.25,0,0,1-2.048-1.433Z" transform="translate(-3.5 -1.667)" fill="#35c1f1"/>
      <path id="Path_100187" data-name="Path 100187" d="M31.5,39.083V24.225a.478.478,0,0,0-.483-.483.46.46,0,0,0-.392.2l-3.517,5.025a1.251,1.251,0,1,1-2.05-1.433L28.933,22H27.917l-3.542,5.058a2.083,2.083,0,1,0,3.417,2.383l2.875-4.108v13.75a2.086,2.086,0,0,0,.417,1.25h1.25a2.048,2.048,0,0,0,.233-.017A1.241,1.241,0,0,1,31.5,39.083Z" transform="translate(-3.333 -2.333)" opacity="0.05"/>
      <path id="Path_100188" data-name="Path 100188" d="M31.583,39.083V24.225a.478.478,0,0,0-.483-.483.46.46,0,0,0-.392.2l-3.517,5.025a1.251,1.251,0,1,1-2.05-1.433L29.016,22h-.508L24.8,27.292a1.662,1.662,0,0,0-.275,1.25,1.687,1.687,0,0,0,.683,1.075,1.716,1.716,0,0,0,.958.3,1.686,1.686,0,0,0,1.367-.708l3.517-5.025a.05.05,0,0,1,.05-.025.062.062,0,0,1,.067.067V39.083a1.646,1.646,0,0,0,.575,1.25h.675a2.048,2.048,0,0,0,.233-.017A1.241,1.241,0,0,1,31.583,39.083Z" transform="translate(-3.416 -2.333)" opacity="0.07"/>
    </g>
  </g>
</svg>

)