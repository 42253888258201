import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Avatar, Box, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { stringAvatar } from '../../../utils';
import { useStyles } from "../styles";

export const UnitCard = ({ t, data, selected, onClick = () => false }) => {
    const classes = useStyles()
    return (
        <Box className={classes.selectedListCardRoot} display="flex" alignItems="center">
            <Box>
                <Avatar src={data?.logo} {...stringAvatar(data?.name)} />
            </Box>
            <Box flexGrow={1} marginInlineStart="12px">
                <Typography className={classes.name}>{data?.name}</Typography>
                <Typography className={classes.email}>{data?.unit_no}</Typography>
            </Box>
            <Box>
                <Typography className={classes.vacant}>{t("Vacant")}</Typography>
            </Box>
            <Box paddingLeft={'24px'}>
                <Checkbox
                    onChange={() => onClick(data)}
                    checked={data?.is_active}
                    icon={<RadioButtonUncheckedIcon sx={{ color: "#E4E8EE" }} />}
                    checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />} />
            </Box>

        </Box>

    )
}