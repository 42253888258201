import React from "react"

export const RescheduleDateIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_117799" data-name="Group 117799" transform="translate(-18265 23487)">
                <rect id="Rectangle_58581" data-name="Rectangle 58581" width="48" height="48" rx="24" transform="translate(18265 -23487)" fill="#ffecec" />
                <path id="icons8-schedule_6_" data-name="icons8-schedule (6)" d="M6.571,0a6.571,6.571,0,1,0,6.571,6.571A6.584,6.584,0,0,0,6.571,0Zm0,1.714A4.857,4.857,0,1,1,1.714,6.571,4.844,4.844,0,0,1,6.571,1.714ZM6.558,2.845a.857.857,0,0,0-.844.869V6.571a.857.857,0,0,0,.251.606L7.108,8.32A.857.857,0,1,0,8.32,7.108l-.892-.892v-2.5a.857.857,0,0,0-.871-.869Zm7.054.584a7.674,7.674,0,0,1,.674,3.143A7.742,7.742,0,0,1,14.148,8H24V7.143a3.719,3.719,0,0,0-3.714-3.714Zm0,6.286a7.7,7.7,0,0,1-10.183,3.9v6.674A3.719,3.719,0,0,0,7.143,24H20.286A3.719,3.719,0,0,0,24,20.286V9.714Zm.1,2.857A1.429,1.429,0,1,1,12.286,14,1.428,1.428,0,0,1,13.714,12.571Zm4.857,0A1.429,1.429,0,1,1,17.143,14,1.428,1.428,0,0,1,18.571,12.571ZM8.857,17.714a1.429,1.429,0,1,1-1.429,1.429A1.428,1.428,0,0,1,8.857,17.714Zm4.857,0a1.429,1.429,0,1,1-1.429,1.429A1.428,1.428,0,0,1,13.714,17.714Z" transform="translate(18277 -23475)" fill="#ff4b4b" />
            </g>
        </svg>

    )
}