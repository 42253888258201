import * as React from "react"
export const ThreadParty = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 102948" transform="translate(-11563 -17154)">
      <circle
        cx={12}
        cy={12}
        r={12}
        fill="#ffebdc"
        data-name="Ellipse 129451"
        transform="translate(11563 17154)"
      />
      <path
        fill="#ff9340"
        d="M11573.661 17165.871h-.022a2.436 2.436 0 0 1 0-4.871h.03a2.436 2.436 0 0 1 0 4.871Zm6.708 4.5-2.192-3.658a.73.73 0 0 0-1.253 0l-2.191 3.653a.73.73 0 0 0 .626 1.106h4.384a.73.73 0 0 0 .626-1.106Zm-3.062-2.79a.244.244 0 0 1 .487 0v1.948a.244.244 0 0 1-.487 0Zm.244 3.219a.3.3 0 1 1 .3-.3.3.3 0 0 1-.3.3Zm-3.21-.688 1.96-3.258a.8.8 0 0 0-.087-.009h-5.3a.94.94 0 0 0-.913.969v.37a2.262 2.262 0 0 0 1.163 1.955 4.577 4.577 0 0 0 2.368.6h.053a5.486 5.486 0 0 0 .584-.033 1.217 1.217 0 0 1 .172-.591Z"
      />
    </g>
  </svg>
)
