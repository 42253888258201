import React from "react";
import { withNavBars } from "../../HOCs";
import CreateFixedMaintenancePlanner from "./createFixedPlanner";

class CreateMaintenancePlannerParent extends React.Component {
    render() {
        return <CreateFixedMaintenancePlanner  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CreateMaintenancePlannerParent, props);
