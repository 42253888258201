import { Box, Table, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { TotalTableStyle } from './style'

const TotalTable = ({ invoiceData = {}, t = () => false, selectedCompany = {} }) => {
    const classes = TotalTableStyle()
    const language = localStorage.getItem("i18nextLng")
    return (
        <Box>
            <Table>
                <TableRow>
                    <TableCell className={classes.normalCell}>
                        <Typography className={classes.font10}>{t("TotalTaxableAmount")}</Typography>
                    </TableCell>
                    <TableCell className={classes.normalCell}>
                        <Typography className={classes.font11} sx={{ textAlign:language === "ar" ? "left" : "right" }}> {selectedCompany?.currency_symbol ?? '-'} {(invoiceData?.totalTaxableAmount).toLocaleString('en-IN')} </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.normalCell}>
                        <Typography className={classes.font10}>{t("TotalTaxes")}</Typography>
                    </TableCell>
                    <TableCell className={classes.normalCell}>
                        <Typography className={classes.font10sec} sx={{ textAlign:language === "ar" ? "left" : "right" }}>{selectedCompany?.currency_symbol ?? '-'} {(invoiceData?.totalTaxes).toLocaleString('en-IN')}</Typography></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.totalCell}>
                        <Typography className={classes.font10}>{t("TotalAmountDue")}</Typography>
                    </TableCell>
                    <TableCell className={classes.totalCell}>
                        <Typography className={classes.font10} sx={{ textAlign:language === "ar" ? "left" : "right" }}>{selectedCompany?.currency_symbol ?? '-'} {(invoiceData?.totalAmount).toLocaleString('en-IN')}</Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </Box>
    )
}
export default withNamespaces("freeTextInvoice")(TotalTable)