import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, LinearProgress, Avatar } from "@mui/material";
import { useStyles } from "../style";
import Location from "../../../assets/locationsymbol";
import WebSiteImg from "../../../assets/website";
import Bed from "../../../assets/bed";
import Bath from "../../../assets/bath";
import Furniture from "../../../assets/furnichair";
import {
  useWindowDimensions,
  NetWorkCallMethods,
  AlertProps,
  stringAvatar,
} from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { BackdropContext, AlertContext } from "../../../contexts";
import { constractData } from "../common";

export const Status = ({ t, id = "", company = {}, reload = false }) => {
  // styles
  const classes = useStyles();
  //   useDimension
  const size = useWindowDimensions();
  //   context
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  // states
  const [details, setDetails] = useState("");

  // -----------------------------------------FUNCTIONS------------------------------------------
  // get appraisal list
  const getList = async () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading...",
    });
    const payload = {
      id: id,
    };
    NetworkCall(
      `${config.api_url}/rental_index/get_details`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const result = constractData(
          t,
          response?.data,
          company?.SelectedCompany
        );
        setDetails(result);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading...",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading...",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Internal error. Please try again later."),
        });
      });
  };

  // useEffect
  useEffect(() => {
    if (id?.length > 0) getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload]);


  return (
    <div style={{ height: size?.height - 180,}}>
      {id?.length > 0 ? (
        <>
          <Box display="flex" alignItems="center">
            <Avatar src={details?.property_logo} alt={""} {...stringAvatar(details?.property_name)} />
            <Typography className={classes.PropertyName}>
              {details?.property_name}
            </Typography>
          </Box>
          {/* property Details */}
          <Box className={classes.graftParent}>
            <Grid container spacing={1}>
              {details?.propertyDetails?.map((x) => {
                return (
                  <Grid item xs={3}>
                    <Box>
                      <Typography className={classes.catagory} noWrap>
                        {x?.catagory}
                      </Typography>
                      <Typography className={classes.catagoryName} noWrap>
                        {x?.result}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {/* contact Details */}
          <Box className={classes.addressDetailsBox}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className={classes.addressflexBox}>
                  <Box>
                    <Location />
                  </Box>
                  <Box>
                    <Typography className={classes.addressDeatils}>
                      {details?.door_no?.length > 0
                        ? details?.door_no + ","
                        : ""}
                      {details?.street_1?.length > 0
                        ? details?.street_1 + ","
                        : ""}
                      {details?.street_2?.length > 0
                        ? details?.street_2 + ","
                        : ""}
                      {details?.landmark?.length > 0
                        ? details?.landmark + ","
                        : ""}
                      {details?.city?.length > 0 ? details?.city + "," : ""}
                      {details?.state?.length > 0 ? details?.state + "," : ""}
                      {details?.zipcode?.length > 0 ? details?.zipcode : ""}
                    </Typography>
                  </Box>
                </Box>
                <Box height={"10px"} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className={classes.addressflexBox}>
                  <Box>
                    <WebSiteImg />
                  </Box>
                  <Box>
                    <Typography className={classes.addressDeatils}>
                      {details?.email ?? ""}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* pricing value */}
          <Box className={classes.padding}>
            <Grid container spacing={1}>
              {details?.pricingValues?.map((x) => {
                return (
                  <Grid item xs={x?.divider}>
                    <Box>
                      <Typography className={classes.catagory} noWrap>
                        {x?.catagory}
                      </Typography>
                      <Typography className={classes.catagoryName} noWrap>
                        {x?.result}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
              {details?.pricingProgress?.map((x) => {
                return (
                  <Grid item xs={x?.divider}>
                    <Box>
                      <Typography className={classes.catagory} noWrap>
                        {x?.catagory}
                      </Typography>
                      <Box>
                        <Typography
                          className={classes.resultValue}
                          style={{ color: x?.color }}
                        >{`${Math.floor(x?.result)}%`}</Typography>
                        <LinearProgress
                          value={x?.result}
                          variant="determinate"
                          color={x?.progress}
                          className={classes.progressBar}
                        />
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {/* totalAmount */}
          <Box className={classes.totalAmountParent}>
            <Typography className={classes.catagory}>
              {t("Amount")} :
            </Typography>
            &nbsp;
            <Typography className={classes.catagoryName} noWrap>
              {company?.SelectedCompany?.currency_symbol ?? "-"}{" "}
              {details?.actual_value ?? 0}
            </Typography>
          </Box>
          {/* bed */}
          <Box className={classes.bathDetails}>
            <Box className={classes.flexBox}>
              <Bed />
              <Typography className={classes.bedText}>
                {details?.total_bed_rooms} {t("Bedrooms")}
              </Typography>
            </Box>
            <Box className={classes.dot} />
            <Box className={classes.flexBox}>
              <Bath />
              <Typography className={classes.bedText}>
                {details?.total_baths} {t("Baths")}
              </Typography>
            </Box>
            <Box className={classes.dot} />
            <Box className={classes.flexBox}>
              <Furniture />
              <Typography className={classes.bedText}>
                {t(details?.furnishing)}
              </Typography>
            </Box>
          </Box>
          {/* amount */}
          <Box className={classes.amountParnt}>
            <Grid container spacing={2}>
              {details?.amountDetails?.map((x) => {
                return (
                  <Grid item xs={6}>
                    <Box className={classes.amountCard}>
                      <Typography className={classes.amountTitle} noWrap>
                        {x?.title}
                      </Typography>
                      <Typography className={classes.amountContant}>
                        {x?.contant}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
