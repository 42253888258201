import * as React from "react"

export const Assigned = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 102062" transform="translate(-121 -201)">
      <circle
        data-name="Ellipse 129431"
        cx={20}
        cy={20}
        r={20}
        transform="translate(121 201)"
        fill="#f6f1fe"
      />
      <g data-name="Group 103256">
        <path data-name="Rectangle 55102" fill="none" d="M131 211h20v20h-20z" />
        <path
          d="M138.857 212a4.286 4.286 0 1 0 4.286 4.286 4.3 4.3 0 0 0-4.286-4.286Zm6 8.571a4.714 4.714 0 1 0 4.714 4.714 4.714 4.714 0 0 0-4.714-4.714Zm-10.928 1.715a1.931 1.931 0 0 0-1.929 1.928v.514c0 2.477 3.013 4.415 6.857 4.415a10.6 10.6 0 0 0 1.838-.158 5.559 5.559 0 0 1-.531-6.7Zm10.929 0a.483.483 0 0 1 .456.326l.476 1.388h1.585a.482.482 0 0 1 .291.866l-1.283.973.434 1.391a.482.482 0 0 1-.749.53l-1.211-.907-1.211.907a.482.482 0 0 1-.749-.53l.434-1.39-1.283-.974a.482.482 0 0 1 .291-.866h1.585l.477-1.389a.483.483 0 0 1 .456-.325Z"
          fill="#896db3"
        />
      </g>
    </g>
  </svg>
)
