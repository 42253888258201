export const settlementPath = ["reciepts_no", "settlement_no", "datetime", "account_no", "agreement_id", "invoice_no", "invoice_date", "invoice_due_amount", "amount_settled"]

export const getSettlementHeading = (t) => {
    const settlementheading = [
        { title: t("receiptNo"), field: "reciepts_no", },
        { title: t("settlement_no"), field: "settlement_no", },
        { title: t("datetime"), field: "datetime", },
        { title: t("account_no"), field: "account_no", },
        { title: t("agreementNo"), field: "agreement_id", },
        { title: t("invoiceNo"), field: "invoice_no", },
        { title: t("invoice_date"), field: "invoice_date", },
        { title: t("invoiceDue"), field: "invoice_due_amount" },
        { title: t("amount_settled"), field: "amount_settled" },
    ]
    return settlementheading
}

export const settlementdataType = [
    { type: ["text"], name: "reciepts_no" },
    { type: ["text"], name: "settlement_no" },
    { type: ["date"], name: "datetime" },
    { type: ["text"], name: "account_no" },
    { type: ["text"], name: "agreement_id" },
    { type: ["text"], name: "invoice_no" },
    { type: ["date"], name: "invoice_date" },
    { type: ["text"], name: "invoice_due_amount" },
    { type: ["text"], name: "amount_settled" },

]
