import React from "react";
import { withNavBars } from "../../HOCs";
import AggreementsList from "./aggreements";

class AggreementsListParent extends React.Component {
    render() {
        return <AggreementsList />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AggreementsListParent, props);
