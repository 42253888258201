export const LoginAccess = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-2{fill:#c1c5cb;fill-rule:evenodd}"}</style>
        </defs>
        <g id="Announcement" transform="translate(24889 -2957)">
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24889 2957)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
            <g
                id="Login_Access"
                data-name="Login Access"
                transform="translate(-24883 2964)"
            >
                <path
                    id="Path_96773"
                    data-name="Path 96773"
                    className="cls-2"
                    d="M13.608 7.171a.686.686 0 1 0-.97-.97l-1.182 1.182a1.6 1.6 0 0 0 0 2.263l1.182 1.182a.686.686 0 0 0 .97-.97L12.95 9.2h5.592a.686.686 0 0 0 0-1.371H12.95Z"
                    transform="translate(-.942 -.514)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96774"
                    data-name="Path 96774"
                    className="cls-2"
                    d="M4.75 16h6.629a4.343 4.343 0 0 0 4.343-4.343.686.686 0 0 0-1.371 0 2.971 2.971 0 0 1-2.971 2.971H7.917A4.331 4.331 0 0 1 4.75 16ZM7.917 1.371h3.461a2.971 2.971 0 0 1 2.972 2.972.686.686 0 0 0 1.371 0A4.343 4.343 0 0 0 11.379 0H4.75a4.331 4.331 0 0 1 3.167 1.371Z"
                    transform="translate(-.407)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96775"
                    data-name="Path 96775"
                    d="M0 4.343a4.343 4.343 0 0 1 8.686 0v7.314a4.343 4.343 0 0 1-8.686 0Z"
                    style={{
                        opacity: 0.4,
                        fill: props?.fill ?? "#c1c5cb",
                        fillRule: "evenodd",
                    }}
                />
            </g>
        </g>
    </svg>
)