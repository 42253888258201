import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Avatar, Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { DialogHeader, MobileNumberInputComponent, TextBox } from "../../../components";
import { componantsStyles } from "./styles";
export const AccountForm = ({ onClose = () => false, disableAcc = false, updateStateAcc = () => false, onSubmitAccount = () => false, account = {}, t = () => false }) => {
    const classes = componantsStyles()

    return (
        <Box width={ "428px"}>
            <DialogHeader p={1.5} isLeft title={t("Convert To Agreement")} onClose={onClose} />
            <Divider/>
            <Box p={2} height={`calc(100vh - 134px)`} overflow="auto"  >


                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography className={classes.createTitle}>{t("Create Quick Account")}</Typography>
                        <Typography className={classes.title}>{t("Account Details")}</Typography>

                            {
                                account?.url ?
                                    <div className={classes.imgDiv}>
                                        <img src={URL.createObjectURL(account?.url?.[0])} alt="" style={{ objectFit: "cover", height: "94px", width: "94px", borderRadius: "50%" }} />
                                        <IconButton onClick={() => updateStateAcc("url", "")} className={classes.close} size="small">
                                            <CloseIcon style={{ fontSize:"1.125rem", color: "white" }} />
                                        </IconButton>
                                    </div>
                                    :
                                    <IconButton className={classes.profileimg}>
                                        <PersonOutlineIcon className={classes.profileicon} />
                                        <label htmlFor="uplodebtn">
                                            <Avatar
                                                sx={{ width: 24, height: 24 }}
                                                className={classes.profileimgs}
                                                type="file"
                                            >
                                                <img
                                                    src="/images/craeateprofilequtation.svg"
                                                    alt="-"
                                                    className={classes.profileicons}
                                                />
                                            </Avatar>
                                        </label>
                                        <input
                                            type="file"
                                            name="img"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id="uplodebtn"
                                            onChange={(value) => {
                                                updateStateAcc("url", value.target.files)
                                            }

                                            }
                                        />
                                    </IconButton>
                            }
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            value={account?.name}
                            onChange={(e) => updateStateAcc("name", e.target.value)}
                            label={t("Account Name")}
                            placeholder={t("Enter Account Name")}
                            isError={account?.error?.name?.length > 0}
                            errorMessage={account?.error?.name}
                            isrequired
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MobileNumberInputComponent
                            label={t("Mobile Number")}
                            xs={3.2}
                            value={account?.mobile}
                            handleChange={(value) => updateStateAcc("mobile", value)}
                            isError={account?.error?.mobile?.length > 0}
                            errorMessage={account?.error?.mobile}
                            isRequired
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox
                            value={account?.email}
                            onChange={(e) => updateStateAcc("email", e.target.value)}
                            label={t("Email ID")} placeholder={t("Enter Email ID")}
                            isError={account?.error?.email?.length > 0}
                            errorMessage={account?.error?.email}
                            isrequired
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box p={2} display="flex" justifyContent="space-between">
                <Button onClick={onClose} className={classes.btn} variant="outlined">{t("Back")}</Button>
                <Button disabled={disableAcc} onClick={onSubmitAccount} className={classes.sumbitBtn} variant="contained">{t("Create Account")}</Button>
            </Box>
        </Box>
    )
}