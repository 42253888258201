import React from "react"

export const InspectionItemIcon = () =>(

<svg xmlns="http://www.w3.org/2000/svg"  width="36" height="36" viewBox="0 0 36 36">
  <defs>
    <linearGradient id="linear-gradient" x1="0.277" y1="-0.062" x2="0.674" y2="0.939" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#c77740"/>
      <stop offset="1" stop-color="#b0622b"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.5" y1="0.497" x2="0.5" y2="0.889" gradientUnits="objectBoundingBox">
      <stop offset="0.442" stop-color="#878786"/>
      <stop offset="0.594" stop-color="#9f9f9e"/>
      <stop offset="0.859" stop-color="#c3c3c3"/>
      <stop offset="1" stop-color="#d1d1d1"/>
    </linearGradient>
    <linearGradient id="linear-gradient-3" x1="-28.709" y1="39.01" x2="-28.575" y2="40.13" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#c3cdd9"/>
      <stop offset="1" stop-color="#9fa7b0"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#3079d6"/>
      <stop offset="1" stop-color="#297cd2"/>
    </linearGradient>
  </defs>
  <g id="Group_113556" data-name="Group 113556" transform="translate(10044 23263)">
    <rect id="icons8-smart-home-checked-96" width="36" height="36" transform="translate(-10044 -23263)" fill="#fff" opacity="0"/>
    <g id="icons8-reception" transform="translate(-10047 -23269)">
      <g id="icons8-inspection_2_" data-name="icons8-inspection (2)" transform="translate(8 7)">
        <path id="Path_100189" data-name="Path 100189" d="M32.286,4H22.571a1.619,1.619,0,1,1-3.238,0H9.619A1.619,1.619,0,0,0,8,5.619V34.762a1.619,1.619,0,0,0,1.619,1.619H32.286A1.619,1.619,0,0,0,33.9,34.762V5.619A1.619,1.619,0,0,0,32.286,4Z" transform="translate(-8 -2.381)" fill="url(#linear-gradient)"/>
        <path id="Path_100190" data-name="Path 100190" d="M31.857,40.1H10.81a.81.81,0,0,1-.81-.81V32H32.667v7.286A.81.81,0,0,1,31.857,40.1Z" transform="translate(-8.381 -7.714)" fill="url(#linear-gradient-2)"/>
        <path id="Path_100191" data-name="Path 100191" d="M31.857,33.524H10.81a.81.81,0,0,1-.81-.81V6.81A.81.81,0,0,1,10.81,6H31.857a.81.81,0,0,1,.81.81v25.9A.81.81,0,0,1,31.857,33.524Z" transform="translate(-8.381 -2.762)" fill="#f3f3f3"/>
        <path id="Path_100192" data-name="Path 100192" d="M28.333,6.857V3.619H24.946a2.414,2.414,0,0,0-4.559,0H17V6.857a.81.81,0,0,0,.81.81h9.714A.81.81,0,0,0,28.333,6.857ZM22.667,3.619a.81.81,0,1,1-.81.81A.81.81,0,0,1,22.667,3.619Z" transform="translate(-9.714 -2)" fill="url(#linear-gradient-3)"/>
        <path id="Path_100193" data-name="Path 100193" d="M20.338,29.137l-5.127-5.127a.72.72,0,0,1,0-1.018l1.018-1.018a.72.72,0,0,1,1.018,0l3.6,3.6,7.289-7.289a.72.72,0,0,1,1.018,0l1.018,1.018a.72.72,0,0,1,0,1.018l-8.816,8.815A.72.72,0,0,1,20.338,29.137Z" transform="translate(-9.333 -5.062)" fill="url(#linear-gradient-4)"/>
      </g>
    </g>
  </g>
</svg>

)