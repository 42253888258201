import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  unactive: {
    padding: "10px",
    // marginTop: "24px",
    textAlign: "center",
    borderRadius: "10px",
    fontSize:"0.875rem",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    margin: 5,
  },
  active: {
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    // marginTop: "24px",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
    margin: 5,
  },
  Label: {
    color: theme.typography.color.tertiary,
    fontSize:"0.75rem",
  },
}));

export const Period = (props) => {
  const classes = useStyles();
  const [state,setState] = useState("")


  const getLabel = (props) => {
    return (
      <Typography variant="body1" className={classes.Label} gutterBottom>
        {props.label}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  useEffect(()=>{
   if(props){
       setState(props.value)
   }
    // eslint-disable-next-line
  },[])

  const update=(val)=>{
   setState(val)
   props.onChange(props.state,val)
  }

  return (
    <>
    <div>
    {getLabel(props)}
      <div style={{ display: "flex" }}>
        <Typography
          onClick={() => update("Yearly")}
          className={state=== "Yearly" ? classes.active : classes.unactive}
        >
          Yearly
        </Typography>
        <Typography
          onClick={() => update("One Time")}
          className={state=== "One Time" ? classes.active : classes.unactive}
        >
          One Time
        </Typography>
      </div>
    </div>
     
    </>
  );
};
