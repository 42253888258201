import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../../utils";
export const tableStyles = makeStyles((theme) => ({
    btn: {
        fontFamily: `${SemiBold}`,
        color: theme?.palette?.background?.tertiary1,
        backgroundColor: theme?.palette?.primary?.main,
        padding: "5px 11px",
        marginLeft: "10px",
        fontSize:"0.75rem",
        "&:hover": {
            backgroundColor: theme?.palette?.primary?.main,
            color: theme?.palette?.background?.tertiary1,
        },
        height: '40px'

    },
    btn2: {
        fontFamily: `${SemiBold}`,
        color: theme?.palette?.background?.tertiary1,
        backgroundColor: theme?.palette?.primary?.main,
        padding: "5px 11px",
        fontSize:"0.75rem",
        "&:hover": {
            backgroundColor: theme?.palette?.primary?.main,
            color: theme?.palette?.background?.tertiary1,
        },
        height: '40px'

    },
    btn4: {
        fontFamily: `${SemiBold}`,
        color: theme?.palette?.background?.tertiary1,
        backgroundColor: theme?.palette?.primary?.main,
        padding: "11px",
        fontSize:"0.75rem",
        "&:hover": {
            backgroundColor: theme?.palette?.primary?.main,
            color: theme?.palette?.background?.tertiary1,
        },
        height: '40px'
    },
    btn3: {
        fontFamily: `${SemiBold}`,
        color: theme?.typography.color.secondary,
        backgroundColor: theme?.palette?.background?.tertiary1,
        padding: "5px 11px",
        fontSize:"0.75rem",
        border: "1px solid #CED3DD",
        "&:hover": {
            backgroundColor: theme?.palette?.background?.tertiary1,
            color: theme?.typography.color.secondary,
        },
        height: '40px'
    },

    filterimg: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        //marginTop: "4px",
        padding: "12px",
    },

    customScrollbar: {
        "&::-webkit-scrollbar": {
            width: "0px !important",
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "1em",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
        },
    },

}));