import { Box, Grid, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import React from 'react';
import GreenClock from '../utils/greenClock';
import LocationIcon from '../utils/locationIcon';
import { CalendarStyle } from './style';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        // maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const Calendar = ({
    selectedMonth = new Date(),
    dates = [],
    day = [],
    calendarData = {},
    openTableView = () => false,
    t = () => false
}) => {
    const classes = CalendarStyle()

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        {
                            day?.map((e) => {
                                return (
                                    <Grid item xs={1.7} display={"flex"} justifyContent={"center"}>
                                        <Typography className={classes.dayText}>{e}</Typography>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid container className={classes.calDates} mt={2}>
                        {
                            dates?.map((e) => {
                                return (
                                    <Grid item xs={1.7} display={"flex"} justifyContent={"center"} className={classes.date_outline}>
                                        <Stack className={classes.contentBox} height={"100%"} justifyContent={"space-between"} p={1}>
                                            <Typography className={
                                                format(e, "MM") === format(selectedMonth, "MM") ?
                                                    classes.dateText : classes.nonMonthDate}>
                                                {format(e, "dd")}
                                            </Typography>
                                            {
                                                calendarData[format(e, "yyyy-MM-dd")]?.count > 0 && calendarData[format(e, "yyyy-MM-dd")]?.logs?.[0]?.time_diff !==null &&
                                                <Box className={classes.listBox}>
                                                    <HtmlTooltip
                                                        arrow
                                                        title={
                                                            <ToolTipComp data={calendarData[format(e, "yyyy-MM-dd")]?.logs?.[0]} t={t} />
                                                        }>

                                                        {/* <Typography display={"flex"} alignItems={"center"} justifyContent={"center"} pl={"4px"}>
                                                            <GreenTick />&nbsp;
                                                            <Typography className={classes.listText}>  {calendarData[format(e, "yyyy-MM-dd")]?.logs?.[0]?.status}
                                                            </Typography>
                                                        </Typography> */}
                                                        <Box className={classes.timeDifference}>
                                                        <Typography className={classes.listText}>{calendarData[format(e, "yyyy-MM-dd")]?.logs?.[0]?.time_diff}</Typography>
                                                        </Box>
                                                    </HtmlTooltip>
                                                    {
                                                        calendarData[format(e, "yyyy-MM-dd")]?.count > 1 && calendarData[format(e, "yyyy-MM-dd")]?.logs?.[0]?.time_diff!==null &&
                                                        <Typography className={classes.viewMoreText} onClick={() => openTableView(e)}>{t("View More")}</Typography>
                                                    }

                                                </Box>
                                            }
                                        </Stack>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


const ToolTipComp = ({ t = () => false, ...props }) => {
    const classes = CalendarStyle()
    return (
        <Grid container p={"8px 4px"}>
            <Grid item xs={12}>
                <Stack direction="row" columnGap={1} display={"flex"} alignItems={"center"}>
                    <LocationIcon />
                    <Typography className={classes.dutyLocationText}>{t("Duty Location")}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} mt={1}>
                <Typography className={classes.locationName}>{props?.data?.location_name}</Typography>
            </Grid>
            <Grid item xs={6} mt={1}>
                <Stack direction={"row"} display={"flex"} alignItems={"center"}>
                    <GreenClock />
                    <Typography className={classes.checkInOutText}>{t("Check In")}: <span className={classes.checkInOutTime}>{props?.data?.checkin_date?.length > 0 ? format(new Date(`${props?.data?.checkin_date} ${props?.data?.checkin_time}`), "p") : "-"}</span></Typography>
                </Stack>
            </Grid>
            <Grid item xs={6} mt={1}>
                <Stack direction={"row"} display={"flex"} alignItems={"center"}>
                    <GreenClock />
                    <Typography className={classes.checkInOutText}>{t("Check Out")}: <span className={classes.checkInOutTime}>{props?.data?.checkout_date?.length > 0 ? format(new Date(`${props?.data?.checkout_date} ${props?.data?.checkout_time}`), "p") : "-"}</span></Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}