import { Box, Stack, Typography } from "@mui/material";
import { useStyles } from "./style";

export const CardDetail = ({ data }) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <div className={classes.row}>
            <Typography className={classes.count}>{data?.count}</Typography>
            {data?.icon()}
        </div>
        <Box height={"6px"} />
        <div className={classes.rows}>
            <Typography className={classes.label}>{
                <CardText
                    label={data?.label}
                />
            }</Typography>
        </div>
        {/* {data?.label} */}


    </div>
}

export const CardText = ({ label }) => {
    const classes = useStyles();
    let splited = (label).split(" ")
    return (
        <Stack>
            {splited?.[0] && <Typography className={classes.stackTitle}>{splited?.[0]}</Typography>}
            {splited?.[1] && <Typography className={classes.stackTitle}>{splited?.[1]?.length === 1 ? `${splited?.[1]} ${splited?.[2]?.length ? splited?.[2] : ''}` : `${splited?.[1]}`} </Typography>}
        </Stack>
    )
}