import { Box, Stack, Typography } from "@mui/material";
import { Profile, Sqft } from "../../assets";
import { useStyles } from "./styles";
export const LeadCard = ({ data = {}, onClick = () => false, disable = false }) => {
    const classes = useStyles()
    return (

        <div>
            <Box display="flex" alignItem="center">
                <Box marginTop="4px">
                    {
                        data?.contact?.get_assets_url_id?.length > 0 ?
                            <img className={classes.image}
                                src={data?.contact?.get_assets_url_id} alt="" />
                            :
                            <Profile />
                    }

                </Box>
                <Box flexGrow={1} marginLeft="8px">
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>
                            <Typography className={classes.name}>{data?.contact?.first_name ?? ""}&nbsp;{data?.contact?.last_name ?? ""}</Typography>
                            {
                                data?.budget_range &&
                                <Stack direction="row" spacing={1} marginTop="8px">
                                    <Sqft />
                                    <Typography className={classes.budget}>{data?.budget_range[0] ?? ""}-{data?.budget_range[1] ?? ""}&nbsp;{data?.company?.uom ?? ""}</Typography>
                                </Stack>
                            }
                        </Box>
                        <Box marginLeft="8px">
                            <Stack direction="row" spacing={1} marginTop="4px">
                                {
                                    !disable &&
                                    <Typography className={classes.switch} onClick={onClick}>Switch Lead</Typography>

                                }
                                {/* <Typography className={classes.switch}>View</Typography> */}
                            </Stack>
                            {
                                data?.required_area &&

                                <Stack direction="row" spacing={1} marginTop="8px">
                                    <Sqft />
                                    <Typography className={classes.budget}>{data?.budget_range[0] ?? ""}-{data?.budget_range[1] ?? ""}&nbsp;{data?.company?.uom ?? ""}</Typography>
                                </Stack>
                            }
                        </Box>

                    </Box>

                </Box>
            </Box>

        </div >
    )
}