import * as React from "react"
export const Time = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="icons8-story-time (1)">
      <path
        fill="#7e57c2"
        d="M32 16A16 16 0 1 1 16 0a16.047 16.047 0 0 1 16 16Z"
        data-name="Path 100208"
      />
      <path
        fill="#eee"
        d="M28.8 16A12.8 12.8 0 1 1 16 3.2 12.838 12.838 0 0 1 28.8 16Z"
        data-name="Path 100209"
      />
      <path
        fill="#ffccbc"
        d="M28.8 16v.8H15.2V3.2h.8A12.838 12.838 0 0 1 28.8 16Z"
        data-name="Path 100210"
      />
      <path
        fill="#455a64"
        d="M15.2 28.8h1.6v-3.2h-1.6Zm-12-12h3.2v-1.6H3.2v1.6Zm15.2-.8a2.4 2.4 0 1 1-2.4-2.4 2.357 2.357 0 0 1 2.4 2.4"
        data-name="Path 100211"
      />
      <path
        fill="#455a64"
        d="M15.2 5.6h1.6v8.8h-1.6Zm11.2 9.6v1.6h-8.8v-1.6Z"
        data-name="Path 100212"
      />
      <path
        fill="#eee"
        d="M16.8 16a.8.8 0 1 1-.8-.8.756.756 0 0 1 .8.8"
        data-name="Path 100213"
      />
      <path
        fill="#ff8a65"
        d="m21.12 1.84.16.64a1.275 1.275 0 0 0 1.04 1.04l.64.16c.32.08.32.56 0 .56l-.64.16a1.275 1.275 0 0 0-1.04 1.04l-.16.64c-.08.32-.56.32-.56 0l-.16-.64a1.275 1.275 0 0 0-1.04-1.04l-.64-.16c-.32-.08-.32-.56 0-.56l.64-.16a1.275 1.275 0 0 0 1.04-1.04l.16-.64c0-.32.48-.32.56 0Z"
        data-name="Path 100214"
      />
      <path
        fill="#ff7043"
        d="m21.76 10.56.08.48a.946.946 0 0 0 .72.72l.48.08c.24.08.24.32 0 .4l-.48.08a.946.946 0 0 0-.72.72l-.08.48c-.08.24-.32.24-.4 0l-.08-.48a.946.946 0 0 0-.72-.72l-.48-.08c-.24-.08-.24-.32 0-.4l.48-.08a.946.946 0 0 0 .72-.72l.08-.48c.08-.24.4-.24.4 0Z"
        data-name="Path 100215"
      />
      <path
        fill="#ff8a65"
        d="m29.76 6.56.08.48a.946.946 0 0 0 .72.72l.48.08c.24.08.24.32 0 .4l-.48.08a.946.946 0 0 0-.72.72l-.08.48c-.08.24-.32.24-.4 0l-.08-.48a.946.946 0 0 0-.72-.72l-.48-.08c-.24-.08-.24-.32 0-.4l.48-.08a.946.946 0 0 0 .72-.72l.08-.48c.08-.24.4-.24.4 0Z"
        data-name="Path 100216"
      />
    </g>
  </svg>
)

