import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../styles'
import { format } from 'date-fns'

export const IdDetails = ({
    t = () => false,
    data = ""
}) => {
    const classes = useStyles()
    return (
        <>
            <Box className={classes.box} mt={2}>
                <Typography className={classes.title} marginBottom={"14px"}>{t("ID Details")}</Typography>

                <Grid container>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("National ID")}:</Typography>
                        <Typography className={classes.sub}>
                            {data?.national_id?.length ? data?.national_id : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("National ID Expiry")}:</Typography>
                        <Typography className={classes.sub}>
                            {data?.national_id_expiry ? format(new Date(data?.national_id_expiry), "dd-MM-yyyy") : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("Passport ID")}:</Typography>
                        <Typography className={classes.sub}>
                            {data?.passport_id?.length ? data?.passport_id : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("Passport Expiry")}:</Typography>
                        <Typography className={classes.sub}>
                            {data?.passport_expiry ? format(new Date(data?.passport_expiry), "dd-MM-yyyy") : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("Resident ID")}:</Typography>
                        <Typography className={classes.sub}>
                            {data?.resident_id?.length ? data?.resident_id : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("Resident ID Expiry")}:</Typography>
                        <Typography className={classes.sub}>
                            {data?.resident_id_expiry ? format(new Date(data?.resident_id_expiry), "dd-MM-yyyy") : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("Visa Number")}:</Typography>
                        <Typography className={classes.sub}>
                            {data?.visa_no?.length ? data?.visa_no : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("Visa Expiry")}:</Typography>
                        <Typography className={classes.sub}>
                            {data?.visa_expiry ? format(new Date(data?.visa_expiry), "dd-MM-yyyy") : "-"}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}