import * as React from "react"

export const InvoiveIMG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17.402}
        height={14.139}
        {...props}
    >
        <path
            data-name="Payment History"
            d="M1.7 0A1.651 1.651 0 0 0 .5.533 1.883 1.883 0 0 0 0 1.818a1.772 1.772 0 0 0 1.7 1.818h1.326v9.9a.619.619 0 0 0 .25.5.532.532 0 0 0 .53.061l1.653-.707 1.286.686a.538.538 0 0 0 .484.012l1.472-.7 1.472.7a.538.538 0 0 0 .484-.012l1.286-.686 1.657.71a.555.555 0 0 0 .212.044.538.538 0 0 0 .318-.1.619.619 0 0 0 .25-.5v-9.9h1.32a1.651 1.651 0 0 0 1.2-.533 1.883 1.883 0 0 0 .5-1.285A1.772 1.772 0 0 0 15.7 0Zm0 1.212h1.326v1.212H1.7a.576.576 0 0 1-.567-.606.619.619 0 0 1 .167-.428.543.543 0 0 1 .4-.178Zm12.673 0H15.7a.576.576 0 0 1 .567.606.619.619 0 0 1-.167.428.543.543 0 0 1-.4.178h-1.324Zm-8.134 2.02h4.921a.607.607 0 0 1 0 1.212H6.242a.607.607 0 0 1 0-1.212Zm0 2.828h4.921a.607.607 0 0 1 0 1.212H6.242a.607.607 0 0 1 0-1.212Zm0 2.828h3.026a.607.607 0 0 1 0 1.212H6.242a.607.607 0 0 1 0-1.212Z"
            fill={props?.color}
        />
    </svg>
)


