import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";
import ReactQuill from "react-quill";
import {timeZoneConverter} from "../../../utils"

const modules = {
  toolbar: false,
};

export const NotificationCard = ({ data = {}, onCardClick = () => false }) => {
  const classes = useStyles(data);

  return (
    <Box
      className={classes.notificationFlex}
      onClick={() => onCardClick(data?.id)}
      style={{ backgroundColor: data?.is_read === true ? "#fff" : "" }}
    >
      <Box>
        {data?.description?.[0]?.images?.length > 0 &&
        data?.notifications_type === "User" ? (
          <Box className={classes.IconBackGroundColor}>
            <Avatar src={data?.description?.[0]?.images} />
          </Box>
        ) : (
          <Box className={classes.bellBackGroundColor}>
            <NotificationsIcon className={classes.notification} />
          </Box>
        )}
      </Box>
      <Box>
        {data?.notifications_type === "User" ? (
          <Typography className={classes.title}>
            {data?.title?.length > 0
              ? `${data?.title?.substring(0, 25)}${
                  data?.title?.length > 25 ? "..." : ""
                } `
              : "-"}
          </Typography>
        ) : (
          <Typography className={classes.title}>
            {data?.title?.length > 0
              ? `${data?.title?.substring(0, 15)}${
                  data?.title?.length > 15 ? "..." : ""
                } `
              : "-"}
          </Typography>
        )}

        {data?.notifications_type === "User" ? (
          <>
            <ReactQuill
              readOnly
              theme="bubble"
              value={
                data?.description?.[0]?.Description?.length > 0
                  ? `${data?.description?.[0]?.Description?.substring(0, 100)}${
                      data?.title?.length > 100 ? "..." : ""
                    }`
                  : "-"
              }
              modules={modules}
              className={classes.des}
            />
          </>
        ) : data?.description?.length > 0 ? (
          <ReactQuill
            readOnly
            theme="bubble"
            value={data?.description?.[0]?.Description ?? ""}
            modules={modules}
            className={classes.des}
          />
        ) : (
          "-"
        )}

        <Typography className={classes.date}>
          {timeZoneConverter(new Date(data?.triggered_at) , "DD MMM YY,hh:mm")
        }
        </Typography>
      </Box>
      <Chip
        label={
          data?.notifications_type === "User" ? data?.category ?? "-" : "Expiry"
        }
        clickable
        className={classes.chip}
      />
    </Box>
  );
};
