export const Customers = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 98379">
      <g data-name="Group 8127">
        <g data-name="Group 98373">
          <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
          <g fill={props?.fill ?? "#c1c5cb"}>
            <g data-name="Group 98363" transform="translate(12.547 8)">
              <circle
                data-name="Ellipse 129214"
                cx={1.682}
                cy={1.682}
                r={1.682}
                transform="rotate(-67.486 2.576 1.18)"
              />
              <path
                data-name="Path 94761"
                d="M3.647 3.88H1.738a1.742 1.742 0 0 0-1.74 1.74v1.533h5.39V5.62a1.742 1.742 0 0 0-1.741-1.74Z"
              />
            </g>
            <path
              data-name="Path 94762"
              d="M18.011 18.297a1.482 1.482 0 0 0-1.409-1.056h-1.74l-2.457-.934h-1.5v5.356h.678l1.582.921h4.626l4.7-4.7-.415-.415a1.526 1.526 0 0 0-2.089.065l-1.316 1.316a1.017 1.017 0 0 1-.724.3h-3.2v-.848h3.255Z"
            />
            <path data-name="Path 94763" d="M8 16.307h2.057V23H8Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
