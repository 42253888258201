import * as React from "react"
const DebitInactive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 115364">
      <g data-name="icons8-invoice (1)">
        <path
          fill="#ced3dd"
          d="M24 4.263A1.263 1.263 0 0 0 22.737 3H1.263A1.263 1.263 0 0 0 0 4.263v16.421a1.263 1.263 0 0 0 1.263 1.263h21.474A1.263 1.263 0 0 0 24 20.684Z"
          data-name="Path 100845"
        />
        <path
          fill="#98a0ac"
          d="M20.526 8.053H3.474a.316.316 0 0 1-.316-.316V6.474a.316.316 0 0 1 .316-.316h17.052a.316.316 0 0 1 .316.316v1.263a.316.316 0 0 1-.316.316Z"
          data-name="Path 100846"
        />
        <path
          fill="#98a0ac"
          d="M20.526 11.21h-1.263a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.315h1.263a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.315Z"
          data-name="Path 100847"
        />
        <path
          fill="#98a0ac"
          d="M16.105 11.21H3.474a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.315h12.631a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.315Z"
          data-name="Path 100848"
        />
        <path
          fill="#98a0ac"
          d="M20.526 13.737h-1.263a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.315h1.263a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.315Z"
          data-name="Path 100849"
        />
        <path
          fill="#98a0ac"
          d="M14.842 13.737H3.474a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.315h11.368a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.315Z"
          data-name="Path 100850"
        />
        <path
          fill="#98a0ac"
          d="M20.526 16.263h-1.263a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.315h1.263a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.315Z"
          data-name="Path 100851"
        />
        <path
          fill="#98a0ac"
          d="M16.105 16.263H3.474a.316.316 0 0 1-.316-.316v-.632A.316.316 0 0 1 3.474 15h12.631a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.315Z"
          data-name="Path 100852"
        />
        <path
          fill="#98a0ac"
          d="M20.526 20.052h-5.684a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.315h5.684a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.315Z"
          data-name="Path 100853"
        />
      </g>
    </g>
  </svg>
)
export default DebitInactive
