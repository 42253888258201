import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { AggrementComponentStyles } from "./styles";

export const PostCard = ({ icon = "", title = "", description = "", status = "", bgColor = "", onClick = () => false, statusFlg = false, statusColor = "", statusBg = "", mainTitle = null, open = false }) => {
    const classes = AggrementComponentStyles()
    return (
        <Box p={mainTitle ? "4px" : 0}>
            {
                mainTitle && <Typography className={classes.quoDtlCardTitle}>{mainTitle}</Typography>

            }

            <Box p={mainTitle ? 0 : 2} marginTop={mainTitle ? "8px" : "0px"} display="flex" alignItems="center" className={`${classes.postCard}`}>
                {/*icon*/}
                <Box >
                    {
                        open ?
                            <Avatar sx={{ borderRadius: "4px", width: "50px" }}>
                                {icon}
                            </Avatar>
                            :
                            <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: bgColor, padding: "12px", borderRadius: "4px", width: "50px" }}>
                                {icon}
                            </Box>
                    }


                </Box>
                <Box flexGrow={1} className={`${classes.postCardContent}`}>
                    {/*title*/}
                    <Typography className={`${classes.postCardTitle}`}>{title}</Typography>
                    {/*description*/}
                    <Typography className={`${classes.postCardDescription}`}>{description}</Typography>
                </Box>
                <Box>
                    {
                        statusFlg ?
                            <Typography className={classes.status}
                                onClick={onClick}
                                sx={{ color: statusColor, backgroundColor: statusBg, cursor: "pointer" }}
                            >{status}</Typography>
                            :
                            <Typography className={`${classes.postCardStatus}`} onClick={onClick}>{status}</Typography>
                    }

                </Box>
            </Box>
        </Box>
    )
}