import React from "react"

export const SchoolIcons = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_112487" data-name="Group 112487" transform="translate(-154 -2545)">
    <g id="Rectangle_57287" data-name="Rectangle 57287" transform="translate(154 2545)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="32" height="32" stroke="none"/>
      <rect x="0.5" y="0.5" width="31" height="31" fill="none"/>
    </g>
    <path id="icons8-school" d="M15.92,2a.952.952,0,0,0-.32.066L2.587,7.144a.952.952,0,1,0,.692,1.773L15.946,3.974,28.614,8.917a.952.952,0,1,0,.692-1.773L16.292,2.066A.952.952,0,0,0,15.92,2Zm-9.5,8.253a1.9,1.9,0,0,0-1.9,1.9V25.488a1.9,1.9,0,0,0,1.9,1.9h7.335a2.519,2.519,0,0,0,4.374,0h7.335a1.9,1.9,0,0,0,1.9-1.9V12.157a1.9,1.9,0,0,0-1.9-1.9H19.755A2.857,2.857,0,0,0,16.9,13.109V25.17a.952.952,0,1,1-1.9,0V13.109a2.857,2.857,0,0,0-2.857-2.857Z" transform="translate(154.054 2546)" fill="#78b1fe"/>
  </g>
</svg>

)