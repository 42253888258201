import React from 'react';
import { MiniPropertyDetail } from "../../../../components";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { UtilityList } from './utilityMaster';


export const UnitDetails = (props) => {
    const { t } = props
    const size = useWindowDimensions()
    return (
        <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "24px" }}>
            <MiniPropertyDetail
                t={t}
                logo={props?.data?.logo}
                unit_no={props?.data?.unit_no}
                property_name={props?.data?.property?.name}
                unit_name={props?.data?.name}
                address={props?.data?.addressByID} />
            <UtilityList
                t={t}
                permission={props?.permission}
                unitID={props?.unitID}
                logo={props?.data?.logo}
                unit_no={props?.data?.unit_no}
                property_name={props?.data?.property?.name}
                unit_name={props?.data?.name}
                location={props?.data?.addressByID} />
        </div>
    )
}