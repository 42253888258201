import React from "react";
import { withNavBars } from "../../HOCs";
import ResourceManagerDashboard from "./dashboard";

class ResourceManagerDashboardParent extends React.Component {
    render() {
        return <ResourceManagerDashboard {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ResourceManagerDashboardParent, props);