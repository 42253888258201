import React from "react"

export const TemplateListIcon = () =>{
    return(
        <svg id="Group_105196" data-name="Group 105196" xmlns="http://www.w3.org/2000/svg" width="31" height="31.844" viewBox="0 0 31 31.844">
  <g id="Group_105195" data-name="Group 105195">
    <path id="icons8-plus-math_1_" data-name="icons8-plus-math (1)" d="M12.813,9.929l-2.335-.594L9.919,7H9.863L9.335,9.335,7,9.929V10l2.335.475.523,2.335h.066l.553-2.335,2.335-.47Z" transform="translate(-7 -7)" fill="#ced3dd"/>
    <path id="icons8-plus-math_1_2" data-name="icons8-plus-math (1)" d="M11.844,9.441,9.9,8.946,9.433,7H9.386L8.946,8.946,7,9.441V9.5l1.946.4.436,1.946h.055L9.9,9.9l1.946-.391Z" transform="translate(19.156 20)" fill="#ced3dd"/>
    <g id="icons8-living-room" transform="translate(5.813 8)">
      <path id="Path_98931" data-name="Path 98931" d="M11.839,36.391H10.4a.242.242,0,0,1-.233-.309L11,33.176A.243.243,0,0,1,11.235,33h1.434a.242.242,0,0,1,.233.309l-.83,2.906A.243.243,0,0,1,11.839,36.391Z" transform="translate(-7.178 -21.375)" fill="#717b89"/>
      <path id="Path_98932" data-name="Path 98932" d="M33.235,36.391h1.434a.242.242,0,0,0,.233-.309l-.83-2.906A.243.243,0,0,0,33.839,33H32.4a.242.242,0,0,0-.233.309L33,36.215A.243.243,0,0,0,33.235,36.391Z" transform="translate(-18.521 -21.375)" fill="#717b89"/>
      <path id="Path_98933" data-name="Path 98933" d="M31.266,10.453A1.453,1.453,0,0,0,29.813,9H25.453A1.453,1.453,0,0,0,24,10.453v6.781h7.266Z" transform="translate(-14.313 -9)" fill="#959ca6"/>
      <path id="Path_98934" data-name="Path 98934" d="M16.266,10.453A1.453,1.453,0,0,0,14.813,9H10.453A1.453,1.453,0,0,0,9,10.453v6.781h7.266Z" transform="translate(-6.578 -9)" fill="#959ca6"/>
      <path id="Path_98935" data-name="Path 98935" d="M23.531,25.906H9V23.969A.969.969,0,0,1,9.969,23H22.562a.969.969,0,0,1,.969.969Z" transform="translate(-6.578 -16.219)" fill="#465160"/>
      <path id="Path_98936" data-name="Path 98936" d="M6.422,25.719H5.938A1.938,1.938,0,0,1,4,23.781v-6.3A.485.485,0,0,1,4.484,17H5.938a.485.485,0,0,1,.484.484Z" transform="translate(-4 -13.125)" fill="#b8bdc4"/>
      <path id="Path_98937" data-name="Path 98937" d="M34.072,33.176A.243.243,0,0,0,33.839,33H32.4a.242.242,0,0,0-.233.309l.327,1.145h1.938Z" transform="translate(-18.521 -21.375)" opacity="0.05"/>
      <path id="Path_98938" data-name="Path 98938" d="M34.072,33.176A.243.243,0,0,0,33.839,33H32.4a.242.242,0,0,0-.233.309l.258.9h1.938Z" transform="translate(-18.521 -21.375)" opacity="0.07"/>
      <path id="Path_98939" data-name="Path 98939" d="M11.528,33.176A.242.242,0,0,1,11.761,33h1.434a.242.242,0,0,1,.233.309L13.1,34.453H11.163Z" transform="translate(-7.693 -21.375)" opacity="0.05"/>
      <path id="Path_98940" data-name="Path 98940" d="M11.6,33.176A.242.242,0,0,1,11.834,33h1.434a.242.242,0,0,1,.233.309l-.258.9H11.305Z" transform="translate(-7.767 -21.375)" opacity="0.07"/>
      <path id="Path_98941" data-name="Path 98941" d="M22.891,17H21.437a.485.485,0,0,0-.484.484v5.328H4v.969a1.938,1.938,0,0,0,1.938,1.937h15.5a1.938,1.938,0,0,0,1.938-1.937v-6.3A.485.485,0,0,0,22.891,17Z" transform="translate(-4 -13.125)" fill="#b8bdc4"/>
    </g>
  </g>
</svg>

    )
}