import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const CustomTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "color" && prop !== 'fontSize' && prop !== 'fontWeight' && prop !== 'marginBottom' && prop !== 'fontFamily'
})(({ color = "#000", fontSize = 12, fontWeight, marginBottom, fontFamily }) => ({
    color,
    fontSize,
    fontWeight,
    marginBottom,
    fontFamily
}));

export const HoverDetails = (props) => {

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"10px"}
            sx={{
                // backgroundColor:"#5E6365",
                pb: '12px',
                pr: '12px'
            }}
        >
            <CustomTypography fontFamily={"crayond_bold"} fontSize={16} >Hitachi Dense</CustomTypography>
            <CustomTypography fontFamily={"crayond_regular"} fontSize={14}>Vandalur</CustomTypography>
        </Stack>
    )

}