export const ChargeIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        data-name="Group 117008"
        {...props}
    >
        <rect
            width={40}
            height={40}
            fill="#fef4f4"
            data-name="Rectangle 58391"
            rx={8}
        />
        <path
            fill="#b3776d"
            d="M13.643 12a.632.632 0 0 0-.643.631v13.053A2.325 2.325 0 0 0 15.316 28h8.842a2.325 2.325 0 0 0 2.316-2.316V12.631a.632.632 0 0 0-.888-.577l-1.651.734-1.858-.743a.632.632 0 0 0-.469 0l-1.871.748-1.871-.748a.632.632 0 0 0-.469 0l-1.858.743-1.651-.734a.632.632 0 0 0-.245-.054Zm2.094 5.053h8a.632.632 0 1 1 0 1.263h-8a.632.632 0 0 1 0-1.263Zm0 3.789h4.632a.632.632 0 1 1 0 1.263h-4.632a.632.632 0 0 1 0-1.263Zm7.158 0h.842a.632.632 0 1 1 0 1.263h-.842a.632.632 0 0 1 0-1.263Zm-7.158 2.526h4.632a.632.632 0 1 1 0 1.263h-4.632a.632.632 0 1 1 0-1.263Zm7.158 0h.842a.632.632 0 1 1 0 1.263h-.842a.632.632 0 1 1 0-1.263Z"
            data-name="icons8-bill (14)"
        />
    </svg>
)