import * as React from "react"

const Step3IconDisabledSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} {...props}>
    <path
      data-name="Path 100098"
      d="M41 35.722A5.227 5.227 0 0 1 35.722 41H8.278A5.227 5.227 0 0 1 3 35.722V14.611L22 3l19 11.611Z"
      fill="#98a0ac"
    />
    <path
      data-name="Path 100099"
      d="M33.611 33.611H10.389V31.5h23.222Zm0-8.444H10.389v2.111h23.222Zm0-6.333H10.389v2.111h23.222Z"
      fill="#e1f5fe"
    />
  </svg>
)

export default Step3IconDisabledSvg