export const LocationPin = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 48450"
                    transform="translate(257 136)"
                    fill="#091b29"
                    d="M0 0h16v16H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 96361">
            <g
                data-name="Mask Group 6285"
                clipPath="url(#a)"
                transform="translate(-257 -136)"
            >
                <path
                    data-name="icons8-location (1)"
                    d="M264.525 137a5.521 5.521 0 0 0-4.195 9.116c.1.111 2.414 2.743 3.187 3.48a1.458 1.458 0 0 0 2.015 0c.9-.858 3.1-3.375 3.191-3.486a5.521 5.521 0 0 0-4.2-9.11Zm0 7.15a1.625 1.625 0 1 1 1.625-1.625 1.625 1.625 0 0 1-1.625 1.625Z"
                    fill="#091b29"
                />
            </g>
        </g>
    </svg>
)