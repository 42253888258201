import React from "react"

export const ShareIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_112357" data-name="Group 112357" transform="translate(-14226 -7885)">
            <g id="Rectangle_57243" data-name="Rectangle 57243" transform="translate(14226 7885)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <path id="icons8-share" d="M16.605,5A2.395,2.395,0,0,0,14.3,8.024l-.042.021L9.325,10.509l-.042.021a2.394,2.394,0,1,0,0,2.94l.041.021,4.931,2.465.04.02a2.4,2.4,0,1,0,.531-.97l-.078-.039L9.819,12.5l-.077-.038a2.4,2.4,0,0,0,0-.928l.078-.039,4.93-2.465L14.824,9a2.395,2.395,0,1,0,1.781-4Z" transform="translate(14226 7885)" fill="#5078e1" />
        </g>
    </svg>

)