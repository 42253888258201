import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: ({ width }) => width,
        height: ({ height }) => height,
        clipPath: ({ is_reverse }) => is_reverse
            ? "polygon(0% 0%, 50% 100%, 100% 0%)"
            : "polygon(0% 100%, 50% 0%, 100% 100%)",
    },
    gap: {
        width: ({ width }) => width,
        height: ({ gap_height }) => gap_height,
    },
    tooltip_value: {
        color: "#4E5A6B",
        fontSize: "1rem",
        fontFamily: SemiBold,
    },
    tooltip_legend: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
    },
    tooltip_label: {
        color: "#7C8594",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
    },
    tooltip_sub_label: {
        color: "#7C8594",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
    },
}));

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} followCursor />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "#7C8594",
        backgroundColor: "#F2F4F7",
        border: "1px solid #E4E8EE",
        display: "flex",
        alignItems: "center",
        padding: "4px 7px",
        zIndex: 999,
        borderRadius: "4px",
        position: "relative",
    },
}));