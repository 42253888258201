import * as React from "react"

export const Transport = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16.865} height={16} {...props}>
        <path
            d="M4.108 0A3.247 3.247 0 0 0 .865 3.243v1.11a.646.646 0 0 0-.865.62v2.162a.649.649 0 0 0 .865.62v7.38A.865.865 0 0 0 1.73 16h.432a.865.865 0 0 0 .865-.865h3.459v-3.243a2.754 2.754 0 0 1 .5-1.589l.384-1.644a2.867 2.867 0 0 1 .146-.45l-5.357.007V3.243A1.948 1.948 0 0 1 4.108 1.3h8.216a1.948 1.948 0 0 1 1.946 1.943V6.5a2.788 2.788 0 0 1 2.162 1.225V4.973a.649.649 0 0 0-.649-.649.641.641 0 0 0-.216.04V3.243A3.247 3.247 0 0 0 12.324 0Zm3.027 2.162a.65.65 0 1 0 0 1.3H9.3a.65.65 0 1 0 0-1.3Zm2.976 5.189a1.952 1.952 0 0 0-1.9 1.5L7.8 10.632q0 .021-.008.041a1.926 1.926 0 0 0-.442 1.219v3.676a.433.433 0 0 0 .432.432h.865a.433.433 0 0 0 .432-.432v-.432h6.054v.432a.433.433 0 0 0 .432.432h.865a.433.433 0 0 0 .432-.432v-3.676a1.926 1.926 0 0 0-.441-1.218q0-.021-.009-.042L16 8.856a1.951 1.951 0 0 0-1.894-1.5Zm0 1.3h3.995a.642.642 0 0 1 .631.5l.186.8h-5.63l.186-.8a.642.642 0 0 1 .632-.502Zm-5.787 2.16a.865.865 0 1 1-.865.865.865.865 0 0 1 .865-.865Zm4.973 1.3a.649.649 0 1 1-.649.649.649.649 0 0 1 .652-.652Zm5.622 0a.649.649 0 1 1-.649.649.649.649 0 0 1 .649-.652Z"
            fill="#eba766"
        />
    </svg>
)

