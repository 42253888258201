export const TownshipAmmenities = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 102652">
            <path data-name="Rectangle 54844" fill="none" d="M0 0h30v30H0z" />
            <g data-name="township ameneties" fill="#c1c5cb">
                <path
                    data-name="Path 97576"
                    d="M16.108 5.577a2.682 2.682 0 0 0-1.563.5 5.788 5.788 0 0 0-1.022-.692c0-.059.007-.118.007-.178a2.7 2.7 0 1 0-5.4 0c0 .06 0 .119.007.178a5.787 5.787 0 0 0-1.022.692 2.715 2.715 0 1 0 .2.155 5.541 5.541 0 0 1 .855-.584 2.7 2.7 0 0 0 5.33 0 5.542 5.542 0 0 1 .855.584 2.7 2.7 0 1 0 1.76-.656ZM8 8.285a2.456 2.456 0 1 1-2.452-2.462A2.461 2.461 0 0 1 8 8.285Zm2.824-.615a2.462 2.462 0 1 1 2.456-2.462 2.462 2.462 0 0 1-2.452 2.461Zm5.28 3.077a2.462 2.462 0 1 1 2.456-2.462 2.461 2.461 0 0 1-2.452 2.461Z"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                    transform="translate(4.152 4.5)"
                />
                <path
                    data-name="Path 97577"
                    d="M213.724 80.488a2.827 2.827 0 0 0-4.005 0 .123.123 0 1 0 .174.174 2.582 2.582 0 0 1 3.657 0 .123.123 0 1 0 .174-.174Z"
                    transform="translate(-191.465 -68.471)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97578"
                    d="M218.944 93.272a2.229 2.229 0 0 0-1.589.66.123.123 0 0 0 .174.174 2 2 0 0 1 2.829 0 .123.123 0 1 0 .174-.174 2.229 2.229 0 0 0-1.588-.66Z"
                    transform="translate(-198.688 -81.349)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97579"
                    d="M226.818 106.887a1.6 1.6 0 0 0-1.137.472.123.123 0 1 0 .174.174 1.359 1.359 0 0 1 1.926 0 .123.123 0 1 0 .174-.174 1.6 1.6 0 0 0-1.137-.472Z"
                    transform="translate(-206.562 -94.225)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97580"
                    d="M234.419 120.5a.738.738 0 1 0 .737.738.738.738 0 0 0-.737-.738Zm0 1.231a.492.492 0 1 1 .491-.493.492.492 0 0 1-.491.495Z"
                    transform="translate(-214.163 -107.102)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97581"
                    d="M34.364 81.925a.123.123 0 0 0-.123.123v1.231h-1.719v-1.231a.123.123 0 0 0-.246 0v2.708a.123.123 0 0 0 .246 0v-1.231h1.719v1.231a.123.123 0 0 0 .246 0v-2.708a.123.123 0 0 0-.123-.123Z"
                    transform="translate(-23.681 -70.617)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97582"
                    d="M14.78 91a.123.123 0 0 0-.123.123v.739h-.368a.123.123 0 0 0 0 .246h.368v.739a.123.123 0 0 0 .246 0v-1.723A.123.123 0 0 0 14.78 91Z"
                    transform="translate(-6.553 -79.201)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97583"
                    d="M77.8 91.124a.123.123 0 1 0-.246 0v1.723a.123.123 0 1 0 .246 0v-.739h.368a.123.123 0 0 0 0-.246H77.8Z"
                    transform="translate(-66.502 -79.201)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97584"
                    d="M137.641 27.462h-1.1a.123.123 0 0 0-.123.123v2.708a.123.123 0 0 0 .246 0v-.985h.981a.739.739 0 0 0 .738-.738v-.37a.739.739 0 0 0-.742-.738Zm.492 1.108a.492.492 0 0 1-.492.492h-.981v-1.354h.981a.492.492 0 0 1 .492.492Z"
                    transform="translate(-122.171 -19.108)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97585"
                    d="M36.423 128.871h-.063v-5.415a.367.367 0 0 0-.109-.262.361.361 0 0 0-.261-.107l-3.068.013v-1.239a.369.369 0 0 0-.368-.369h-.368v-.621a.369.369 0 0 0-.369-.369h-3.439a.369.369 0 0 0-.369.369v.621h-.368a.369.369 0 0 0-.368.369v1.226h-1.228a.369.369 0 0 0-.369.369v1.586h-1.6a.369.369 0 0 0-.368.37l.006 3.446h-.063a.4.4 0 0 0-.428.369v.492a.4.4 0 0 0 .428.369l12.774.014a.4.4 0 0 0 .428-.369v-.492a.4.4 0 0 0-.43-.37Zm-8.167-8a.123.123 0 0 1 .123-.123h3.437a.123.123 0 0 1 .123.123v.616h-3.683v-.616Zm7.735 2.463a.131.131 0 0 1 .087.036.123.123 0 0 1 .036.088v5.415h-3.193v-5.526Zm-12.039 2.078a.123.123 0 0 1 .123-.123H25.8a.123.123 0 0 0 .123-.123v-1.709a.123.123 0 0 1 .123-.123h1.228v5.525h-3.316Zm-.485 4.308v-.492c0-.066.085-.123.182-.123h3.624v.739h-3.624c-.097-.002-.182-.059-.182-.125Zm4.052-7.858a.123.123 0 0 1 .123-.123h4.912a.123.123 0 0 1 .123.123v8h-1.229v-1.852a.123.123 0 0 0-.123-.123h-2.456a.123.123 0 0 0-.123.123v1.846h-1.227Zm1.474 6.272h.982v1.724h-.982Zm1.228 1.724v-1.724h.979v1.724Zm6.385-.123c0 .066-.085.123-.182.123h-3.5v-.739h3.5c.1 0 .182.058.182.123Z"
                    transform="translate(-15.117 -107.102)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97586"
                    d="M113.9 156.333h3.684a.123.123 0 0 0 .123-.123v-3.81a.123.123 0 0 0-.123-.123H113.9a.123.123 0 0 0-.123.123v3.815a.123.123 0 0 0 .123.118Zm2.333-2.462v.985h-.982v-.985Zm-.982-.246v-1.108h.982v1.108Zm2.21 1.231h-.982v-.985h.982Zm-1.228.246v.985h-.982v-.987Zm-1.233-.245h-.982v-.985H115Zm-.982.246H115v.985h-.982Zm2.456.985v-.988h.982v.985Zm.982-2.461h-.982v-1.108h.982ZM115 152.518v1.108h-.982v-1.108Z"
                    transform="translate(-100.759 -137.149)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97587"
                    d="M211.244 212.5h.738a.123.123 0 0 0 .123-.123v-.977a.123.123 0 0 0-.123-.123h-.738a.123.123 0 0 0-.123.123v.985a.123.123 0 0 0 .123.115Zm.123-.985h.492v.739h-.492Z"
                    transform="translate(-192.826 -192.949)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97588"
                    d="M233.909 212.5h.737a.123.123 0 0 0 .123-.123v-.977a.123.123 0 0 0-.123-.123h-.737a.123.123 0 0 0-.123.123v.985a.123.123 0 0 0 .123.115Zm.123-.985h.491v.739h-.491Z"
                    transform="translate(-214.261 -192.949)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97589"
                    d="M211.244 185.271h.738a.123.123 0 0 0 .123-.123v-.985a.123.123 0 0 0-.123-.123h-.738a.123.123 0 0 0-.123.123v.985a.123.123 0 0 0 .123.123Zm.123-.984h.492v.739h-.492Z"
                    transform="translate(-192.826 -167.195)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97590"
                    d="M233.909 185.271h.737a.123.123 0 0 0 .123-.123v-.985a.123.123 0 0 0-.123-.123h-.737a.123.123 0 0 0-.123.123v.985a.123.123 0 0 0 .123.123Zm.123-.984h.491v.739h-.491Z"
                    transform="translate(-214.261 -167.195)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97591"
                    d="M211.244 239.735h.738a.123.123 0 0 0 .123-.123v-.985a.123.123 0 0 0-.123-.123h-.738a.123.123 0 0 0-.123.123v.985a.123.123 0 0 0 .123.123Zm.123-.985h.492v.739h-.492Z"
                    transform="translate(-192.826 -218.705)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97592"
                    d="M233.909 239.735h.737a.123.123 0 0 0 .123-.123v-.985a.123.123 0 0 0-.123-.123h-.737a.123.123 0 0 0-.123.123v.985a.123.123 0 0 0 .123.123Zm.123-.985h.491v.739h-.491Z"
                    transform="translate(-214.261 -218.705)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97593"
                    d="M71.6 215.557h-.738a.123.123 0 0 0-.123.123v.985a.123.123 0 0 0 .123.123h.738a.123.123 0 0 0 .123-.123v-.985a.123.123 0 0 0-.123-.123Zm-.123.985h-.492v-.742h.492Z"
                    transform="translate(-60.054 -197.001)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97594"
                    d="M48.957 215.557h-.737a.123.123 0 0 0-.123.123v.985a.123.123 0 0 0 .123.123h.737a.123.123 0 0 0 .123-.123v-.985a.123.123 0 0 0-.123-.123Zm-.123.985h-.491v-.742h.491Z"
                    transform="translate(-38.644 -197.001)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97595"
                    d="M71.6 242.786h-.738a.123.123 0 0 0-.123.123v.984a.123.123 0 0 0 .123.123h.738a.123.123 0 0 0 .123-.123v-.984a.123.123 0 0 0-.123-.123Zm-.123.985h-.492v-.739h.492Z"
                    transform="translate(-60.054 -222.753)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97596"
                    d="M48.957 242.786h-.737a.123.123 0 0 0-.123.123v.984a.123.123 0 0 0 .123.123h.737a.123.123 0 0 0 .123-.123v-.984a.123.123 0 0 0-.123-.123Zm-.123.985h-.491v-.739h.491Z"
                    transform="translate(-38.644 -222.753)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97597"
                    d="M117.581 245.556a.123.123 0 1 0 0-.246H113.9a.123.123 0 1 0 0 .246Z"
                    transform="translate(-100.759 -225.141)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
        </g>
    </svg>
)