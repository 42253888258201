export const Discussion = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
        <defs>
            <linearGradient
                id="b"
                x1={0.217}
                y1={-0.087}
                x2={0.789}
                y2={0.868}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#737be6" />
                <stop offset={1} stopColor="#4750b3" />
            </linearGradient>
            <clipPath id="a">
                <path
                    data-name="Rectangle 52013"
                    transform="translate(82 183)"
                    fill="#fff"
                    stroke="#707070"
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 102438">
            <g
                data-name="Group 6395"
                transform="translate(-78 -179)"
                clipPath="url(#a)"
            >
                <g data-name="Group 95673">
                    <path data-name="Rectangle 50956" fill="#fff" d="M82 184h24v24H82z" />
                    <g data-name="icons8-comments (1)">
                        <path
                            data-name="Path 7298"
                            d="M24.9 8H5.1A1.1 1.1 0 0 0 4 9.1v14.3a1.1 1.1 0 0 0 1.1 1.1h4.4v4.4a.55.55 0 0 0 .939.389l4.788-4.789H24.9a1.1 1.1 0 0 0 1.1-1.1V9.1A1.1 1.1 0 0 0 24.9 8Z"
                            fill="url(#b)"
                            transform="translate(78.761 177.017)"
                        />
                        <path
                            data-name="Path 7299"
                            d="M86.966 192.544h13.591a1.082 1.082 0 0 0 1.133-1.024v-1.024a1.082 1.082 0 0 0-1.133-1.024H86.966a1.082 1.082 0 0 0-1.133 1.024v1.024a1.082 1.082 0 0 0 1.133 1.024Z"
                            opacity={0.05}
                        />
                        <path
                            data-name="Path 7300"
                            d="M86.941 192.3h13.64a.814.814 0 0 0 .852-.768v-1.024a.814.814 0 0 0-.852-.768h-13.64a.814.814 0 0 0-.852.768v1.024a.814.814 0 0 0 .852.768Z"
                            opacity={0.07}
                        />
                        <path
                            data-name="Path 7301"
                            d="M86.857 197h9.215a1.024 1.024 0 0 0 1.024-1.024v-1.024a1.024 1.024 0 0 0-1.024-1.024h-9.215a1.024 1.024 0 0 0-1.024 1.024v1.024A1.024 1.024 0 0 0 86.857 197Z"
                            opacity={0.05}
                        />
                        <path
                            data-name="Path 7302"
                            d="M87.377 196.744h9.215a.768.768 0 0 0 .768-.768v-1.024a.768.768 0 0 0-.768-.768h-9.215a.768.768 0 0 0-.768.768v1.024a.768.768 0 0 0 .768.768Z"
                            opacity={0.07}
                        />
                        <path
                            data-name="Path 7303"
                            d="M97.116 194.953v1.024a.512.512 0 0 1-.512.512h-9.215a.512.512 0 0 1-.512-.512v-1.024a.512.512 0 0 1 .512-.512h9.215a.512.512 0 0 1 .512.512Z"
                            fill="#fff"
                        />
                        <path
                            data-name="Path 7304"
                            d="M101.177 190.518v1.024a.544.544 0 0 1-.57.512H86.915a.544.544 0 0 1-.57-.512v-1.024a.544.544 0 0 1 .57-.512h13.692a.544.544 0 0 1 .57.512Z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
            <path data-name="Rectangle 53878" fill="none" d="M0 0h32v32H0z" />
        </g>
    </svg>
)