import * as React from "react"

const BackgroundType = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="97.002" height="68.166" viewBox="0 0 97.002 68.166">
            <defs>
                <filter id="Union_15" x="0" y="0" width="97.002" height="68.166" filterUnits="userSpaceOnUse">
                    <feOffset dy="6" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="4" result="blur" />
                    <feFlood flood-color="#3b3b63" flood-opacity="0.122" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_15)">
                <path id="Union_15-2" data-name="Union 15" d="M-21099.021-18298.832c-.211-2.742-2.178-5.3-5.531-7.205A25.616,25.616,0,0,0-21117-18309h-49a6,6,0,0,1-6-6v-22a6,6,0,0,1,6-6h61a6,6,0,0,1,6,6v38.166Z" transform="translate(21184 18349)" fill={props?.color} />
            </g>
        </svg>

    )
}
export default BackgroundType