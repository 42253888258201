export const DeliveryMilestones = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 102130">
            <g data-name="delivery milestone">
                <path
                    data-name="Path 96844"
                    d="M16.945 5.49a.525.525 0 0 1-.25.45L10.1 9.788v6.2h1a.537.537 0 0 1 .5.5.5.5 0 0 1-.5.5h-3a.472.472 0 0 1-.5-.5.5.5 0 0 1 .5-.5h1V1.492a.673.673 0 0 1 .25-.45.572.572 0 0 1 .5 0h.05l6.8 4a.6.6 0 0 1 .245.448Z"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                    transform="translate(2.4 6.015)"
                />
            </g>
            <path
                data-name="Rectangle 52494"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
        </g>
    </svg>
)
