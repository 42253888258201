import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, SemiBold, remCalc } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: '0px 0px 16px #00000014',
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#ffffff",
        margin:"16px",
        padding: "8px",
        height: 'calc(100vh - 150px)'
    },
    tabs: {
        "&.MuiTab-root": { padding: "12px 16px !important" }
    },
    fixedBottom: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        padding: "16px"
    },
    drawerHeader: {
        fontSize: remCalc(16),
        fontFamily: ExtraBold,
        color: theme.typography.color.primary
    },
    applyBorder: {
        borderRight: "1px solid #CED3DD"
    },
    detailhdr: {
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    subhdrs:{
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,   
    },
    subhdrvalues: {
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.primary,
        marginTop:"5px"
    },
    optionsheader:{
        backgroundColor:"#F2F4F7",
        borderRadius:theme.palette.borderRadius,
        padding:"0px 8px 8px 8px"
    },
    addnew: {
        fontSize: "0.875rem",
        color: theme.palette.primary.main,
        fontFamily: SemiBold,
        padding: "4px",
        cursor: "pointer",
        whiteSpace: "noWrap",
        width: "150px"
      },
      documentImg:{
        height:"100px",
        width:"100px",
        border:"1px solid #CED3DD",
        borderRadius:theme.palette.borderRadius,
        padding:"4px",
        objectFit:"contain"
      },
      seeMoreLessTextStyle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
}))