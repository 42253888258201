export const filterOptions={
    requestType:[
        {
            label:"All",
            value:"All"
        },
        {
            label:"Maintenance",
            value:"Maintenance"
        },
        {
            label:"General",
            value:"General"
        },
        {
            label:"Final Settlement",
            value:"Final Settlement"
        }
    ]
}
export const notes = [
    {
        notes: "Request Approved"
    },
    {
        notes: "Request Rejected",
        status: "Rejected",
        comments:"test",
        created_at:new Date()
    }
]