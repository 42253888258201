export const MeterReadings = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Meter Reading">
            <g data-name="Group 96943">
                <g data-name="Group 96942">
                    <g
                        data-name="Group 96941"
                        fill={props?.fill ?? "#c1c5cb"}
                        transform="translate(7.348 6.429)"
                    >
                        <path
                            data-name="Path 93625"
                            d="M2.802 6.965a4.888 4.888 0 0 0 1.327 4.082l.865-.863a3.658 3.658 0 0 1-.142-4.9l-.866-.866a4.836 4.836 0 0 0-1.184 2.547Z"
                        />
                        <circle
                            data-name="Ellipse 129192"
                            cx={0.306}
                            cy={0.306}
                            r={0.306}
                            transform="translate(7.346 7.346)"
                        />
                        <path
                            data-name="Path 93626"
                            d="M7.067 2.789a4.857 4.857 0 0 0-2.647 1.2l.863.863a3.664 3.664 0 0 1 4.741 0l.865-.865a4.85 4.85 0 0 0-3.822-1.198Z"
                        />
                        <path
                            data-name="Path 93627"
                            d="M9.795 17.14h1.836v-2.248a8.2 8.2 0 0 1-1.836.74Z"
                        />
                        <path
                            data-name="Path 93628"
                            d="M3.673 17.14h1.836v-1.508a8.2 8.2 0 0 1-1.836-.74Z"
                        />
                        <path
                            data-name="Path 93629"
                            d="m11.318 4.416-.863.863a3.615 3.615 0 0 1 .813 1.731c.019.112.028.223.038.335h.323a.306.306 0 1 1 0 .612h-.322a3.637 3.637 0 0 1-1 2.232l.868.857a4.877 4.877 0 0 0 .143-6.63Z"
                        />
                        <path
                            data-name="Path 93630"
                            d="M7.652 1.224a6.428 6.428 0 1 0 6.427 6.429 6.428 6.428 0 0 0-6.427-6.429Zm3.972 10.241a.627.627 0 0 1-.444.192.545.545 0 0 1-.436-.178l-.864-.864a.612.612 0 0 1-.013-.85 3.034 3.034 0 0 0 .829-1.808H8.514a.913.913 0 0 1-1.724 0h-.975a.306.306 0 0 1 0-.612h.975a.913.913 0 0 1 1.724 0h2.179c-.008-.077-.013-.153-.026-.232a3.02 3.02 0 0 0-.848-1.626 3.061 3.061 0 0 0-4.378 4.278.612.612 0 0 1-.012.851l-.864.864a.62.62 0 0 1-.436.181.6.6 0 0 1-.426-.182 5.473 5.473 0 0 1 .026-7.67.293.293 0 0 1 .035-.053.332.332 0 0 1 .04-.026 5.482 5.482 0 0 1 7.515-.193c.073.065.138.137.208.205l.026.017c.007.007.01.017.017.025a5.484 5.484 0 0 1 .054 7.681Z"
                        />
                        <path
                            data-name="Path 93631"
                            d="M7.652 0a7.652 7.652 0 1 0 7.652 7.652A7.652 7.652 0 0 0 7.652 0Zm0 14.692a7.04 7.04 0 1 1 7.04-7.04 7.04 7.04 0 0 1-7.04 7.04Z"
                        />
                    </g>
                </g>
            </g>
            <path data-name="Rectangle 52035" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
