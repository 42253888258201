import React from "react";
import { withNavBars } from "../../HOCs";
import ResourceBoard from "./resourceBoard";

class ResourceBoardParent extends React.Component {
    render() {
        return <ResourceBoard {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ResourceBoardParent, props);