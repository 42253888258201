import React from "react"

export const CalendarIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_112294" data-name="Group 112294" transform="translate(-13981 -7826)">
    <g id="Group_112293" data-name="Group 112293">
      <g id="Rectangle_57213" data-name="Rectangle 57213" transform="translate(13981 7826)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-schedule_1_" data-name="icons8-schedule (1)" d="M4.381,0A4.381,4.381,0,1,0,8.762,4.381,4.39,4.39,0,0,0,4.381,0Zm0,1.143A3.238,3.238,0,1,1,1.143,4.381,3.229,3.229,0,0,1,4.381,1.143ZM4.372,1.9a.571.571,0,0,0-.563.58v1.9a.571.571,0,0,0,.167.4l.762.762a.571.571,0,1,0,.808-.808l-.594-.595V2.476a.571.571,0,0,0-.58-.58Zm4.7.389a5.089,5.089,0,0,1,.358,1.143h4.092a1.335,1.335,0,0,1,1.333,1.333v.571H9.432a5.128,5.128,0,0,1-.358,1.143h5.783v7.048a1.335,1.335,0,0,1-1.333,1.333H4.762a1.335,1.335,0,0,1-1.333-1.333V9.432a5.128,5.128,0,0,1-1.143-.358v4.449A2.479,2.479,0,0,0,4.762,16h8.762A2.479,2.479,0,0,0,16,13.524V4.762a2.479,2.479,0,0,0-2.476-2.476ZM9.143,8a.952.952,0,1,0,.952.952A.952.952,0,0,0,9.143,8Zm3.238,0a.952.952,0,1,0,.952.952A.952.952,0,0,0,12.381,8ZM5.9,11.429a.952.952,0,1,0,.952.952A.952.952,0,0,0,5.9,11.429Zm3.238,0a.952.952,0,1,0,.952.952A.952.952,0,0,0,9.143,11.429Z" transform="translate(13985 7830)" fill="#4e5a6b"/>
    </g>
  </g>
</svg>
)