import { Box, Grid } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { SearchFilter, TableWithPagination } from "../../../components"
import { ActivityBoardHeading, ActivityBoardPath } from "../../../utils"
import { useStyles } from "./style"

const TableView = ({ t, tableData = {}, permission = {},
    searchText = "",
    handleSearch = () => false,
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    handleIcon = () => false,
    hideSearch = false }) => {
    const classes = useStyles()

    return (
        <Box>
            <Box className={classes.root}>
                <Grid container justifyContent="space-between">
                    {!hideSearch && <Grid item xs={4}>
                        <Box>
                            {/*search */}
                            <SearchFilter label={false} placeholder={t("searchActivity")} handleChange={(value) => handleSearch(value)} value={searchText} />
                        </Box>
                    </Grid>}

                    {/* <Grid item xs={8}>
                        <Box display={"flex"} alignItems="center" sx={{ float: "right" }}>
                            <Stack
                                direction="row"
                                spacing={0.6}
                                display={"flex"}
                                alignItems="center"
                            >
                                <Box>
                                    <IconButton
                                        size="small"
                                        className={classes.img}
                                    // onClick={openDrawer}
                                    >
                                        <img src="/images/filter.svg" alt="filter" />
                                    </IconButton>
                                </Box>
                            </Stack>
                            <Box width={"15px"} />
                        </Box>
                    </Grid> */}
                </Grid>

                <TableWithPagination
                    heading={ActivityBoardHeading(t)}
                    rows={tableData?.list}
                    dataType={[
                        { type: ["img"], name: "img" },
                        { type: ["text"], name: "name" },
                        { type: ["description"], name: "action_info" },
                        { type: ["date"], name: "date" },
                        { type: ["text"], name: "createdBy" },
                        { type: ["text"], name: "priority" },
                        { type: ["status2"], name: "status" }
                    ]}
                    path={ActivityBoardPath}
                    showpagination
                    tableType="no-side"
                    handleIcon={handleIcon}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={tableData?.count}
                    page={page}
                    limit={limit}
                    view={permission?.read && true}
                    height={`calc(100vh - 365px)`}
                    edit={true}
                    delete={true} />
            </Box>
        </Box>
    )
}
export default withNamespaces("activityBoard")(TableView)