import React from "react";
import { withNavBars } from "../../../HOCs";
import ServiceProvider from './serviceProvider';

class ServiceProviderParent extends React.Component {
  render() {
    return <ServiceProvider {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ServiceProviderParent, props);
