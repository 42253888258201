import React from 'react'
import { withNavBars } from "../../HOCs"
import  InspectionBulk from './inspectionBulk'
const InspectionItemsParent = () =>{
    return (
      <InspectionBulk/>
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(InspectionItemsParent, props)