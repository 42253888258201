import * as React from "react"

export const ArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={5} height={10} {...props}>
    <path
      d="M.128.813 3.951 5 .128 9.187a.509.509 0 0 0 0 .673.408.408 0 0 0 .615 0l4.13-4.524a.509.509 0 0 0 0-.673L.743.139a.408.408 0 0 0-.615 0 .509.509 0 0 0 0 .674Z"
      fill={props?.color ?? "#fff"}
    />
  </svg>
)
