import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import InputRange from "react-input-range";

export const RangeSlider1 = (props) => {
  const [val, setVal] = React.useState({
    min: props?.value[0] ? props.value?.[0] : 0,
    max: props?.value[1] ? props.value?.[1] : 50000,
  });

  const handleValue = (key, e) => {
    if (key === 1) {
      setVal({
        min: e?.target?.value,
        max: val?.max,
      });
      props.onChange(Object.values(val));
    } else if (key === 2) {
      setVal({ max: e.target.value, min: val.min });

      props.onChange(Object.values(val));
    } else {
      props.onChange(Object.values(e));
    }
  };
  return (
    <Grid container spacing={1}>
      {props.hide ? (
        ""
      ) : (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                onChange={(e) => {
                  handleValue(1, e);
                }}
                fullWidth
                size="small"
                value={val.min}
                label="min"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) => {
                  handleValue(2, e);
                }}
                size="small"
                fullWidth
                value={val.max}
                label="max"
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} style={{ padding: "12px" }}>
        <Typography>{props.title}</Typography>
        {/* <Slider
        className={classes.sliderStyle}
        getAriaLabel={() => "Minimum distance shift"}
        value={value}
        valueLabelDisplay="auto"
        min={props.min}
        max={props.max}
        // valueLabelFormat={}
        onChange={handleChange2}
        disableSwap
      /> */}
        <InputRange
          step={5}
          formatLabel={(value) => null}
          draggableTrack={false}
          allowSameValues={false}
          maxValue={50000}
          minValue={0}
          value={val}
          onChange={(value) => {
            setVal(value);
            handleValue(3, value);
          }}
          onChangeComplete={(args) => handleValue}
        />
      </Grid>
    </Grid>
  );
};
