import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    root: {
        margin: "16px",
        backgroundColor: "white",
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius
    },
    customtab: {
        "&.MuiTab-root": { padding: "12px 16px !important" }
      },
}))