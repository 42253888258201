import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
// import { IncrementDecrement } from '../../../components/IncrementDecrement'
import CreateStrip from '../components/createStrip'
import { Strip } from '../../../utils/contract'
import { OwnerPopup } from '../ownerPopup'
import { AlertDialog, Subheader } from '../../../components'
import { CreateEditContext } from './createEditContext'
import { PropertyPopup } from '../propertyPopup'
import { UnitPopup } from '../unitPopup'
import { useHistory } from 'react-router-dom'
import { ContractCreateEditStyle } from './style'
import moment from 'moment'
import { accessCheckRender, getRoutePermissionNew } from '../../../utils'
import { AuthContext } from '../../../contexts'
import { withNamespaces } from 'react-i18next'


const ContractCreateEdit = ({ t = () => false }) => {
    const classes = ContractCreateEditStyle()
    const [permission, setPermission] = React.useState({})
    const {
        propertyPopup,
        setPropertyPopup,
        ownerPopup,
        setOwnerPopup,
        unitPopup,
        setUnitPopup,
        contractData,
        createContract,
        state,
        selectedTodo,
        selected, 
        setSelected,isDisableBtn } = React.useContext(CreateEditContext)

    const history = useHistory()
    const auth = React.useContext(AuthContext)


    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
        }

        if (state?.type === "view" || state?.type === "edit") {
            setSelected({
                ...selected,
                type3: true,
                type4: true,
                type5: true,
                type6: true
            })
        }
        // eslint-disable-next-line
    }, [auth, state])

    const render = () => {
        return (
            <Box>
                <Subheader
                    title={state?.type === "view" ? t("View Contract") : state?.type === "edit" ? t("Edit Contract") : t("Create Contract")}
                    select
                    isReadOnly
                    value={state?.selectedCompany}
                    goBack={() => history.goBack()}
                />


                <Box sx={{ height: "calc(100vh - 150px)", overflow: "auto" }}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ padding: "16px 16px 4px 16px" }}>
                        {
                            state?.type === "view" &&
                            <Stack spacing={1}>
                                <Typography className={classes.viewHeaderText}>{state?.viewData?.contract_no}</Typography>
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    <Typography className={classes.viewSecondary}>{state?.viewData?.type?.name} {t("Contract")}</Typography>
                                    <Box className={classes.dot}></Box>
                                    <Typography className={classes.viewSecondary}>{t("Created by")} {state?.viewData?.created_person} on {moment(state?.viewData?.created_at).format('DD MMM YY')}</Typography>
                                </Stack>
                            </Stack >
                        }
                        {
                            state?.type !== "view" &&
                            <Typography className={classes.selectedContractLabel}>
                                {state?.contractType?.name} {t("Contract")}
                            </Typography>
                        }
                        {
                            state?.type !== "view" &&
                            <Button variant="contained" onClick={() => createContract()} className={classes.createBtn} disabled={isDisableBtn}>
                                {state?.type === "edit" ? t("Update Contract") : t("Create Contract")}
                            </Button>
                        }
                    </Stack >
                    <Stack m={2} spacing={3}>
                        {
                            Strip(contractData)?.map((data, i) => {
                                return (
                                    <Box>
                                        <CreateStrip
                                            data={data}
                                            click={() => setOwnerPopup(!ownerPopup)}
                                            selected={selected}
                                            setSelected={setSelected}
                                            index={i + 1}
                                            t={t}
                                        />
                                    </Box>
                                )
                            })
                        }
                    </Stack>
                </Box >

                <AlertDialog
                    medium={true}
                    open={ownerPopup}
                    onClose={() => setOwnerPopup(!ownerPopup)}
                    header={t("Add Owner")}
                    component={
                        <OwnerPopup t={t} />
                    }
                />

                <AlertDialog
                    medium={true}
                    open={propertyPopup}
                    onClose={() => setPropertyPopup(!propertyPopup)}
                    header={t("Add Property")}
                    component={
                        <PropertyPopup t={t} selected={selectedTodo} />
                    }
                />

                <AlertDialog
                    medium={true}
                    open={unitPopup}
                    onClose={() => setUnitPopup(!unitPopup)}
                    header={t("Add Unit")}
                    component={
                        <UnitPopup t={t} selected={selectedTodo} />
                    }
                />

            </Box >
        )
    }


    return <div>
        {accessCheckRender(render, permission)}
    </div>

}
export default withNamespaces("contract")(ContractCreateEdit)

