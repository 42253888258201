import { makeStyles } from "@mui/styles";
import { Bold, SemiBold, remCalc } from "../../utils";

export const CustomSelectBoxStyle = makeStyles((theme) => ({
    selectBox: {
        height: (props) => props?.height ?? 32,
        borderRadius: 4,
        border: `1px solid ${theme.palette.border.secondary}`,
        background: (props) => props?.background ?? "#fff"
    },
    listText: {
        fontSize: remCalc(14),
        color: (props) => props?.textColor ?? theme.typography.color.primary,
        fontFamily: SemiBold,
        padding: "8px",
        cursor: "pointer"
    },
    noOptions: {
        fontSize: remCalc(14),
        color: theme.typography.color.secondary,
        fontFamily: Bold,
    },
    selectedListText: {
        fontSize: remCalc(14),
        color:  "#fff",
        // (props) => props?.textColor ?? "#fff",
        background: "#2684FF",
        fontFamily: SemiBold,
        padding: (props) => props?.padding ?? "8px",
        cursor: "pointer"
    },
    hoverlist: {
        fontSize: remCalc(14),
        color: theme.typography.color.primary,
        background: "#DEEBFF",
        fontFamily: SemiBold,
        padding: "8px",
        cursor: "pointer"
    }
}))