import React from "react";
import { withNavBars } from "../../HOCs";
import Request from './request'

const CreateMaintanceRequest = () => {
  return <><Request /></>;
};
const props = {
  boxShadow: false,
};
export default withNavBars(CreateMaintanceRequest, props);
