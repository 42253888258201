import { makeStyles } from "@mui/styles";
import { Bold, Italic, Regular, SemiBold } from "../../utils";

export const CheckAvailabilityStyles = makeStyles((theme) => ({
    grid_border: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: "none"
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold,
        textTransform: "uppercase"
    },
    available_slot: {
        // height: "100%"
    },
    venueSection: {
        height: `calc ( 100vh - 200px)`,
        overflow: "auto"
    },
    btnBox: {
        // padding: 4,
        // position: "relative",
        // right: 4,
        // bottom: 8
        position: "absolute",
        bottom: "0px",
        right: "0px",
        width: "100%",
        backgroundColor: "#fff",
        padding: "16px",
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    walkinBtn: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        '&:hover': {
            background: "transparent"
        }
    },
    noVenueBox:{
        position:"relative",
        height: "calc(100vh - 320px)",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },
    noneTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold,
    },
}))

export const ViewCourtStyle = makeStyles((theme) => ({
    court_name: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    subname: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    },
    content: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    contentDay: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
        marginInlineStart: "16px"
    },
    court_title: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: 50,
        background: theme.palette.background.tab_background_1
    },
    subTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    },
    seeMoreLessTextStyle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
    },
    description: {
        fontFamily: Regular,
        color: theme.typography.color.secondary,
        fontSize:"0.75rem"
    },
}))

export const BookingResidentPopupStyle = makeStyles((theme) => ({
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    border: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4
    },
    title: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold,
        textTransform: "uppercase"
    },
    grid_border: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: "none"
    },
    invoiceTitle1: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold
    },
    invoiceTitle2: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: "italic"
    },
    editIcon: {
        cursor: "pointer"
    },
    bookingAvatar: {
        borderRadius: "50px",
        height: 40,
        width: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.success.light
    },
    proceedDialog: {
        padding: "54px 50px 51px 228px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundImage: `url(${"images/announsment.svg"})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: theme?.palette?.primary?.main
    },
    proceedDialog1: {
        padding: "78px 50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        backgroundImage: `url(${"images/success.svg"})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: theme?.palette?.primary?.main
    },
    convert: {
        color: "white",
        fontSize:"1rem",
        fontFamily: Bold,
    },
    convertProcess: {
        color: "white",
        fontSize:"1rem",
        fontFamily: Regular,
        marginTop: '7px'
    },
    yes: {
        color: theme?.palette?.primary?.main,
        fontSize:"0.875rem",
        fontFamily: Bold,
        border: `1px solid white`,
        backgroundColor: "white",
        padding: "7px 29px",
        width: "100%",
        "&:hover": {
            border: `1px solid white`,
            backgroundColor: "white",
        },
    },
    No: {
        color: "white",
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        border: `1px solid white`,
        padding: "7px 29px",
        backgroundColor: theme?.palette?.primary?.main,
        marginInlineStart: "10px",
        width: "100%",
        "&:hover": {
            border: `1px solid white`,
            backgroundColor: theme?.palette?.primary?.main,
        },
    },
    publishDialog: {
        '& .MuiPaper-root': {
            background: "transparent !important"
        }
    },
    backBtn: {
        height: 40,
        width: 112,
        color: theme.typography.color.primary,
        fontFamily: Bold,
        fontSize:"0.875rem",
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: 4,
        background: "transparent",
        "&:hover": {
            background: "transparent"
        }
    },
    slotLabel: {
        background: theme.palette.info.light,
        color: theme.palette.info.main,
        padding: "2px 4px 2px 4px",
        fontSize:"0.75rem",
        borderRadius: 4,
        fontFamily: SemiBold,
        whiteSpace: "noWrap",
        height: "18px"
    },
    createLabel: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Italic
    },
    optionAvatar: {
        height: 32,
        width: 32
    },
    optionHeader: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    optionSubTitle: {
        fontSize:"0.75rem",
        fontFamily: Regular,
        color: theme.typography.color.secondary,
        direction: "ltr"
    },
    btnBox: {
        position: "absolute",
        bottom: "0px",
        right: "0px",
        width: "100%",
        backgroundColor: "#fff",
        padding: "16px",
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    bookBtn: {
        height: 40,
        width: 150,
        borderRadius: 4,
        fontSize:"0.875rem",
        fontFamily: Bold
    },
    walkinBtn: {
        height: 40,
        width: 150,
        borderRadius: 4,
        fontSize:"0.875rem",
        fontFamily: Bold,
        "&:hover": {
            background: 'transparent'
        }
    }
}))

export const BookingSuccessStyle = makeStyles((theme) => ({
    title: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    msgBox: {
        borderRadius: 4,
        background: theme.palette.warning.light,
        width: 300,
        display: "flex",
        textAlign: "center"
    },
    msgBoxText: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.warning.main
    },
    invoiceText: {
        fontSize:"0.75rem",
        fontFamily: Italic,
        color: theme.typography.color.tertiary

    }
}))