export const GatePass = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98472">
            <g transform="translate(-42.214 7)" fill={props?.fill ?? "#c1c5cb"}>
                <circle
                    data-name="Ellipse 129222"
                    cx={1.235}
                    cy={1.235}
                    r={1.235}
                    transform="translate(55.961)"
                />
                <path
                    data-name="Path 95004"
                    d="M55.353 4.274a.251.251 0 0 1 .252-.216v3.726h.844a.833.833 0 0 0 1.531 0h.8V4.057a.252.252 0 0 1 .253.217v3.51h1.192V4.269a1.425 1.425 0 0 0-1.433-1.4h-3.2a1.426 1.426 0 0 0-1.435 1.4v3.515h1.192v-3.51Z"
                />
                <path
                    data-name="Path 95005"
                    d="M57.355 10.822v3.463a.716.716 0 1 0 1.431 0v-3.174h-.8a.831.831 0 0 1-.631-.289Z"
                />
                <path
                    data-name="Path 95006"
                    d="M56.451 11.11h-.838v3.174a.716.716 0 1 0 1.431 0v-3.422a.83.83 0 0 1-.593.248Z"
                />
                <path
                    data-name="Path 95007"
                    d="M53.773 10.671h2.677a.394.394 0 0 0 .394-.394V8.616a.394.394 0 0 0-.394-.394h-2.677V7.43a.373.373 0 0 0-.373-.373h-1.6a.373.373 0 0 0-.373.373v6.719a.214.214 0 0 0-.214.214v.636h2.773v-.636a.214.214 0 0 0-.214-.214v-3.478Zm-1.443-.862a.27.27 0 1 1 .27.27.27.27 0 0 1-.27-.27Zm.27-1.8a.452.452 0 1 1-.452.452.452.452 0 0 1 .452-.457Z"
                />
                <path
                    data-name="Path 95008"
                    d="M63.002 14.15V7.431a.373.373 0 0 0-.373-.373h-1.6a.373.373 0 0 0-.373.373v.792h-2.677a.394.394 0 0 0-.394.394v1.661a.394.394 0 0 0 .394.394h2.677v3.478a.214.214 0 0 0-.214.214V15h2.773v-.636a.214.214 0 0 0-.213-.214Zm-1.173-4.071a.27.27 0 1 1 .27-.27.27.27 0 0 1-.269.27Zm0-2.075a.452.452 0 1 1-.452.452.452.452 0 0 1 .453-.452Z"
                />
            </g>
            <path data-name="Rectangle 52156" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
