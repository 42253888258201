import * as React from "react"

export const UnusedAmount = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
        <g data-name="Group 98669" transform="translate(-544 -84)">
            <rect
                data-name="Rectangle 49614"
                width={50}
                height={50}
                rx={25}
                transform="translate(544 84)"
                fill="#fae1c3"
            />
            <path
                data-name="icons8-order-history (1)"
                d="M562.488 99a2.1 2.1 0 0 0-2.088 2.093v10.93h-.7a.7.7 0 0 0-.7.7v.93a3.027 3.027 0 0 0 3.023 3.023h6.5a6.019 6.019 0 0 1-.518-1.4h-5.982a1.63 1.63 0 0 1-1.623-1.625v-.233h7.465a6.014 6.014 0 0 1 .272-1.4h-6.346v-10.925a.7.7 0 0 1 .7-.7h10.7a.7.7 0 0 1 .7.7v6.744a6.054 6.054 0 0 1 1.4.168v-6.912A2.1 2.1 0 0 0 573.186 99Zm1.86 3.721a.7.7 0 1 0 .7.7.7.7 0 0 0-.699-.7Zm2.326 0a.7.7 0 1 0 0 1.4h4.651a.7.7 0 1 0 0-1.4Zm-2.326 2.791a.7.7 0 1 0 .7.7.7.7 0 0 0-.699-.7Zm2.326 0a.7.7 0 1 0 0 1.4h4.651a.7.7 0 1 0 0-1.4Zm-2.325 2.788a.7.7 0 1 0 .7.7.7.7 0 0 0-.7-.7Zm2.326 0a.7.7 0 0 0 0 1.4h2.852a6.05 6.05 0 0 1 1.973-1.373.7.7 0 0 0-.174-.025Zm7.209.465a5.116 5.116 0 1 0 5.116 5.119 5.116 5.116 0 0 0-5.116-5.117Zm-1.86 1.86h3.721a.465.465 0 0 1 0 .93v.93a1.849 1.849 0 0 1-.643 1.4 1.849 1.849 0 0 1 .643 1.4v.93a.465.465 0 0 1 0 .93h-3.722a.465.465 0 1 1 0-.93v-.93a1.849 1.849 0 0 1 .643-1.4 1.849 1.849 0 0 1-.643-1.4v-.93a.465.465 0 0 1 0-.93Zm.93.93v.93h1.86v-.93Zm.93 2.791a.931.931 0 0 0-.93.93v.749l.783-.261a.468.468 0 0 1 .294 0l.783.261v-.749a.931.931 0 0 0-.93-.927Z"
                fill="#e09e51"
            />
        </g>
    </svg>
)

