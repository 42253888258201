export const Vendors = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98442">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 94942"
                    d="M21.296 21.558h-.3v-7.5a1.713 1.713 0 0 1-1-.328 1.7 1.7 0 0 1-1 .328 1.668 1.668 0 0 1-.222-.018v5.577a.569.569 0 0 1-.562.576h-2.016v-1.581l.712 1.219a.446.446 0 0 0 .386.224.439.439 0 0 0 .23-.066.467.467 0 0 0 .154-.632l-1.431-2.45a.441.441 0 0 0-.41-.218h-1.668a.442.442 0 0 0-.416.218l-1.431 2.45a.467.467 0 0 0 .154.632.439.439 0 0 0 .23.066.446.446 0 0 0 .386-.224l.716-1.227v1.587h-2.023a.569.569 0 0 1-.562-.576v-5.573a1.668 1.668 0 0 1-.222.018 1.7 1.7 0 0 1-1-.328 1.713 1.713 0 0 1-1 .328v7.5h-.3a.722.722 0 0 0 0 1.443h12.596a.722.722 0 0 0 0-1.443Z"
                />
                <path
                    data-name="Path 94943"
                    d="M14.999 14.104a1.112 1.112 0 1 0 .936 1.1 1.027 1.027 0 0 0-.936-1.1Z"
                />
                <path
                    data-name="Path 94944"
                    d="M8.714 13.248a.986.986 0 0 0 .287.043 1 1 0 0 0 .958-.732l.053-.18a1.013 1.013 0 0 0 .794.893.977.977 0 0 0 .2.02 1.007 1.007 0 0 0 .98-.826l.024-.125a1.013 1.013 0 0 0 .9.946 1 1 0 0 0 1.094-.919l.007-.076a1 1 0 1 0 2 0l.007.076a1.01 1.01 0 0 0 .994.924.991.991 0 0 0 .1 0 1.012 1.012 0 0 0 .9-.946l.024.125a1.007 1.007 0 0 0 .98.826.977.977 0 0 0 .2-.02 1.012 1.012 0 0 0 .794-.893l.053.18a1 1 0 0 0 .958.732.987.987 0 0 0 .287-.043 1.031 1.031 0 0 0 .672-1.277l-.9-3.1A1.194 1.194 0 0 0 19.915 8h-9.831a1.194 1.194 0 0 0-1.14.871l-.9 3.1a1.031 1.031 0 0 0 .67 1.277Z"
                />
            </g>
            <path data-name="Rectangle 52138" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
