import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";
export const ParkingGroupingMasterStyles = makeStyles((theme) => ({
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "10px",
        height: "40px",
        width: '40px'
    },
    btn: {
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        height: "40px"

    },
    btn2: {
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 6px 10px #00000014",
        "&:hover": {
            backgroundColor: "#fff"
        }

    },
    card: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#EEF2FC",
        padding: "12px",
    },
    recBox: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#85A1EA",
        padding: "12px",
        textAlign: "center"

    },
    textSmall: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: "white"
    },
    textRight: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#091B29",
        padding: "8px 36px"
    },
    textLeft: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#98A0AC",
        padding: "8px 16px"
    },
    text2: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: "#4E5A6B",
        marginLeft: "12px",
        marginBottom: "4px"

    },
    text1: {
        fontSize:"1rem",
        fontFamily: SemiBold,
        color: "#091B29",
        marginLeft: "12px",
        marginBottom: "4px"

    },
    text3: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: "#98A0AC",
        marginLeft: "12px"

    },
    span: {
        backgroundColor: "#4E5A6B",
        borderRadius: "5px",
        color: "white",
        padding: "2px",
        fontSize:"0.75rem",
        marginLeft: "6px"
    },
    periodStyle: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    hour: {
        backgroundColor: "#F5F7FA",
        padding: "12px 8px 8px 8px",
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        minHeight: 45,
        overflow: "auto"
    },
    TextField: {
        padding: "0px 14px",
    }
}));