import React from "react";
import { withNavBars } from "../../HOCs";
import ShortListUnits from "./shortlistedUnits";

class ShortListedUnitsParent extends React.Component {
        constructor(props) {
    super(props);
    this.state = {

    }
  }
    render() {

        return <ShortListUnits btns={true} {...this?.props}/>;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ShortListedUnitsParent, props);
