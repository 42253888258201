import * as React from "react"

const MeetIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={33} height={32} {...props}>
    <defs>
      <clipPath id="a">
        <rect
          data-name="Rectangle 53578"
          width={33}
          height={32}
          rx={8}
          transform="translate(.098)"
          fill="#35a27d"
        />
      </clipPath>
    </defs>
    <g data-name="Group 101854" transform="translate(-.097)">
      <rect
        data-name="Rectangle 53577"
        width={33}
        height={32}
        rx={8}
        transform="translate(.098)"
        fill="#8887d4"
      />
      <g data-name="Mask Group 6470" opacity={0.5} clipPath="url(#a)">
        <g data-name="Group 101853" fill="none">
          <g data-name="Group 101839">
            <g data-name="Path 96596">
              <path d="M10.26 5.713a12.271 12.271 0 0 1 16.718 4.456 12.145 12.145 0 0 1-4.482 16.637A12.271 12.271 0 0 1 5.78 22.348a12.145 12.145 0 0 1 4.48-16.635Z" />
              <path
                d="M10.736 6.592a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.13 8.454c3.098 5.34 9.985 7.176 15.353 4.092l.022-.012a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.13-8.454c-3.098-5.34-9.985-7.176-15.353-4.092l-.022.012m-.5-.866.024-.013c5.853-3.363 13.337-1.368 16.717 4.457 3.38 5.824 1.374 13.272-4.48 16.635-5.853 3.363-13.337 1.367-16.717-4.457C2.405 16.53 4.401 9.095 10.237 5.726Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96597">
              <path d="M11.253 7.423a10.281 10.281 0 0 1 14.007 3.736 10.176 10.176 0 0 1-3.754 13.936A10.281 10.281 0 0 1 7.5 21.36a10.176 10.176 0 0 1 3.754-13.937Z" />
              <path
                d="M11.733 8.3a9.149 9.149 0 0 0-4.298 5.599 9.101 9.101 0 0 0 .93 6.96c2.55 4.397 8.223 5.908 12.643 3.369l.017-.01a9.149 9.149 0 0 0 4.298-5.599 9.101 9.101 0 0 0-.93-6.96C21.844 7.262 16.172 5.75 11.75 8.29l-.017.01m-.5-.866.02-.011c4.904-2.818 11.174-1.146 14.006 3.734 2.831 4.88 1.15 11.12-3.753 13.938-4.904 2.817-11.175 1.145-14.007-3.735-2.827-4.873-1.155-11.103 3.734-13.926Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96598">
              <path d="M12.244 9.132a8.291 8.291 0 0 1 11.295 3.012 8.206 8.206 0 0 1-3.026 11.24 8.291 8.291 0 0 1-11.295-3.012 8.206 8.206 0 0 1 3.026-11.24Z" />
              <path
                d="M12.728 10.008a7.186 7.186 0 0 0-3.375 4.397 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.641 9.932 2.646l.013-.008a7.186 7.186 0 0 0 3.376-4.397 7.147 7.147 0 0 0-.73-5.466C20.67 9.192 16.214 8.005 12.742 10l-.014.008m-.5-.866.016-.01c3.955-2.271 9.012-.923 11.295 3.013 2.284 3.935.928 8.967-3.026 11.24-3.955 2.272-9.012.923-11.296-3.012-2.28-3.93-.931-8.955 3.011-11.231Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96599">
              <path d="M13.237 10.843a6.3 6.3 0 0 1 8.584 2.29 6.237 6.237 0 0 1-2.299 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.543Z" />
              <path
                d="M13.725 11.716a5.223 5.223 0 0 0-2.454 3.196 5.194 5.194 0 0 0 .53 3.972c1.458 2.51 4.697 3.374 7.222 1.923l.01-.006a5.223 5.223 0 0 0 2.454-3.196 5.194 5.194 0 0 0-.53-3.971c-1.458-2.511-4.697-3.374-7.222-1.924l-.01.006m-.5-.866.012-.007c3.006-1.727 6.849-.702 8.584 2.289a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.727-6.849.702-8.584-2.289a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96600">
              <path d="M14.394 12.839a3.98 3.98 0 0 1 5.422 1.446 3.939 3.939 0 0 1-1.452 5.395 3.98 3.98 0 0 1-5.423-1.446 3.939 3.939 0 0 1 1.453-5.395Z" />
              <path
                d="M14.887 13.71a2.933 2.933 0 0 0-1.378 1.794 2.914 2.914 0 0 0 .297 2.228 2.983 2.983 0 0 0 4.06 1.08l.005-.003a2.933 2.933 0 0 0 1.377-1.794 2.914 2.914 0 0 0-.297-2.229 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.866.007-.004a3.98 3.98 0 0 1 5.422 1.445 3.94 3.94 0 0 1-1.453 5.396 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.446-5.391Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96601">
              <path d="M15.222 14.263a2.322 2.322 0 0 1 3.16.841 2.3 2.3 0 0 1-.847 3.146 2.322 2.322 0 0 1-3.161-.84 2.3 2.3 0 0 1 .848-3.147Z" />
              <path
                d="M15.717 15.132a1.297 1.297 0 0 0-.478 1.777c.362.625 1.17.84 1.797.479l.005-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.798-.479l-.004.003m-.5-.866.004-.003a2.322 2.322 0 0 1 3.163.844c.64 1.102.26 2.51-.848 3.147a2.322 2.322 0 0 1-3.162-.843 2.298 2.298 0 0 1 .843-3.145Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101840">
            <g data-name="Path 96602">
              <path d="M9.943-19.188a12.271 12.271 0 0 1 16.718 4.457 12.145 12.145 0 0 1-4.482 16.637A12.271 12.271 0 0 1 5.463-2.553a12.145 12.145 0 0 1 4.48-16.635Z" />
              <path
                d="M10.42-18.309a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.128 8.454c3.099 5.34 9.987 7.176 15.355 4.092l.02-.012a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.128-8.454c-3.098-5.34-9.986-7.176-15.354-4.092l-.021.012m-.5-.866.023-.013c5.853-3.363 13.338-1.367 16.717 4.457 3.38 5.825 1.374 13.273-4.48 16.635C16.328 5.267 8.844 3.271 5.464-2.553 2.09-8.37 4.084-15.806 9.92-19.175Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96603">
              <path d="M10.936-17.478a10.281 10.281 0 0 1 14.007 3.736A10.176 10.176 0 0 1 21.19.194 10.281 10.281 0 0 1 7.182-3.54a10.176 10.176 0 0 1 3.754-13.937Z" />
              <path
                d="M11.416-16.6a9.149 9.149 0 0 0-4.297 5.598 9.101 9.101 0 0 0 .929 6.96C10.599.355 16.27 1.867 20.69-.673l.017-.01a9.149 9.149 0 0 0 4.298-5.599 9.101 9.101 0 0 0-.929-6.96c-2.551-4.397-8.223-5.908-12.644-3.368l-.017.01m-.5-.867.02-.01c4.904-2.818 11.175-1.146 14.006 3.734 2.831 4.88 1.15 11.12-3.753 13.937C16.285 3.012 10.014 1.34 7.183-3.54c-2.828-4.874-1.156-11.104 3.733-13.927Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96604">
              <path d="M11.927-15.768a8.291 8.291 0 0 1 11.296 3.012 8.206 8.206 0 0 1-3.027 11.24A8.291 8.291 0 0 1 8.901-4.528a8.206 8.206 0 0 1 3.026-11.24Z" />
              <path
                d="M12.412-14.893a7.186 7.186 0 0 0-3.376 4.397 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.642 9.932 2.646l.014-.007a7.186 7.186 0 0 0 3.375-4.398 7.147 7.147 0 0 0-.73-5.465c-2.004-3.455-6.46-4.642-9.932-2.647l-.013.008m-.5-.866.015-.009c3.955-2.272 9.012-.924 11.296 3.012 2.283 3.935.928 8.968-3.027 11.24-3.955 2.272-9.012.924-11.295-3.012-2.28-3.93-.932-8.955 3.01-11.23Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96605">
              <path d="M12.92-14.057a6.3 6.3 0 0 1 8.585 2.289 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.542Z" />
              <path
                d="M13.408-13.185a5.223 5.223 0 0 0-2.453 3.196 5.194 5.194 0 0 0 .53 3.972c1.457 2.511 4.696 3.374 7.221 1.924l.01-.006a5.223 5.223 0 0 0 2.454-3.196 5.194 5.194 0 0 0-.53-3.972c-1.457-2.511-4.697-3.374-7.222-1.923l-.01.005m-.5-.866.012-.007c3.006-1.726 6.85-.702 8.585 2.29a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.726-6.85.702-8.585-2.29a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96606">
              <path d="M14.078-12.062a3.98 3.98 0 0 1 5.421 1.446 3.939 3.939 0 0 1-1.452 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.453-5.395Z" />
              <path
                d="M14.57-11.192a2.933 2.933 0 0 0-1.378 1.795 2.914 2.914 0 0 0 .297 2.228 2.983 2.983 0 0 0 4.06 1.08l.005-.002a2.933 2.933 0 0 0 1.378-1.795 2.914 2.914 0 0 0-.298-2.228 2.983 2.983 0 0 0-4.06-1.08l-.004.002m-.5-.866.007-.004a3.98 3.98 0 0 1 5.422 1.446 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.446-5.39Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96607">
              <path d="M14.905-10.638a2.322 2.322 0 0 1 3.16.842 2.3 2.3 0 0 1-.847 3.145 2.322 2.322 0 0 1-3.161-.839 2.3 2.3 0 0 1 .848-3.148Z" />
              <path
                d="M15.4-9.769a1.297 1.297 0 0 0-.478 1.777c.363.625 1.17.84 1.798.48l.004-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.798-.48l-.004.003m-.5-.866.004-.002a2.322 2.322 0 0 1 3.163.843c.64 1.102.26 2.51-.847 3.147a2.322 2.322 0 0 1-3.163-.843 2.298 2.298 0 0 1 .843-3.145Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101841">
            <g data-name="Path 96608">
              <path d="M31.607 18.151a12.271 12.271 0 0 1 16.718 4.457 12.145 12.145 0 0 1-4.482 16.637 12.271 12.271 0 0 1-16.716-4.458 12.145 12.145 0 0 1 4.48-16.636Z" />
              <path
                d="M32.084 19.03a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.129 8.455c3.098 5.34 9.986 7.176 15.354 4.092l.021-.013a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.129-8.453c-3.098-5.34-9.986-7.176-15.354-4.093l-.021.013m-.5-.866.023-.014c5.853-3.362 13.338-1.367 16.717 4.458 3.38 5.824 1.374 13.272-4.48 16.635-5.852 3.363-13.337 1.367-16.716-4.458-3.375-5.816-1.38-13.252 4.456-16.621Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96609">
              <path d="M32.6 19.862a10.281 10.281 0 0 1 14.008 3.736 10.176 10.176 0 0 1-3.754 13.936 10.281 10.281 0 0 1-14.007-3.735A10.176 10.176 0 0 1 32.6 19.862Z" />
              <path
                d="M33.08 20.739a9.149 9.149 0 0 0-4.297 5.598 9.101 9.101 0 0 0 .929 6.96c2.551 4.398 8.223 5.909 12.644 3.37l.017-.01a9.149 9.149 0 0 0 4.297-5.6 9.101 9.101 0 0 0-.929-6.959c-2.551-4.397-8.223-5.909-12.643-3.369l-.017.01m-.5-.866.019-.011c4.904-2.818 11.175-1.146 14.006 3.734 2.832 4.88 1.15 11.12-3.753 13.938-4.904 2.817-11.175 1.145-14.006-3.735-2.828-4.873-1.155-11.104 3.734-13.926Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96610">
              <path d="M33.592 21.571a8.291 8.291 0 0 1 11.295 3.012 8.206 8.206 0 0 1-3.027 11.24 8.291 8.291 0 0 1-11.295-3.012 8.206 8.206 0 0 1 3.027-11.24Z" />
              <path
                d="M34.076 22.447a7.186 7.186 0 0 0-3.376 4.397 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.641 9.932 2.646l.014-.008a7.186 7.186 0 0 0 3.376-4.397 7.147 7.147 0 0 0-.73-5.466c-2.004-3.454-6.46-4.641-9.933-2.646l-.013.008m-.5-.866.015-.01c3.955-2.272 9.012-.923 11.296 3.012 2.283 3.936.928 8.968-3.027 11.24-3.955 2.272-9.012.924-11.295-3.012-2.28-3.93-.932-8.954 3.01-11.23Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96611">
              <path d="M34.584 23.282a6.3 6.3 0 0 1 8.585 2.29 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.543Z" />
              <path
                d="M35.072 24.155a5.223 5.223 0 0 0-2.453 3.196 5.194 5.194 0 0 0 .53 3.971c1.457 2.512 4.697 3.375 7.221 1.924l.01-.006a5.223 5.223 0 0 0 2.454-3.196 5.194 5.194 0 0 0-.53-3.971c-1.457-2.512-4.697-3.375-7.222-1.924l-.01.006m-.5-.866.012-.007c3.006-1.727 6.85-.702 8.585 2.289a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.727-6.85.702-8.585-2.289a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96612">
              <path d="M35.742 25.277a3.98 3.98 0 0 1 5.421 1.447 3.939 3.939 0 0 1-1.452 5.394 3.98 3.98 0 0 1-5.422-1.445 3.939 3.939 0 0 1 1.453-5.396Z" />
              <path
                d="M36.234 26.148a2.933 2.933 0 0 0-1.378 1.794 2.914 2.914 0 0 0 .298 2.229 2.983 2.983 0 0 0 4.06 1.08l.004-.003a2.933 2.933 0 0 0 1.378-1.794 2.914 2.914 0 0 0-.298-2.229 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.866.007-.004a3.98 3.98 0 0 1 5.422 1.445 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.421-1.445 3.939 3.939 0 0 1 1.445-5.391Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96613">
              <path d="M36.57 26.701a2.322 2.322 0 0 1 3.16.842 2.3 2.3 0 0 1-.848 3.145 2.322 2.322 0 0 1-3.161-.839 2.3 2.3 0 0 1 .849-3.148Z" />
              <path
                d="M37.064 27.57a1.297 1.297 0 0 0-.478 1.777c.363.626 1.17.84 1.798.48l.004-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.797-.479l-.005.003m-.5-.866.005-.003a2.322 2.322 0 0 1 3.162.843c.64 1.102.26 2.511-.847 3.148a2.322 2.322 0 0 1-3.163-.844 2.298 2.298 0 0 1 .843-3.144Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101842">
            <g data-name="Path 96614">
              <path d="M-32.45 5.167a12.271 12.271 0 0 1 16.718 4.457 12.145 12.145 0 0 1-4.482 16.637 12.271 12.271 0 0 1-16.716-4.458 12.145 12.145 0 0 1 4.48-16.636Z" />
              <path
                d="M-31.973 6.047a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.129 8.454c3.098 5.34 9.986 7.176 15.354 4.092l.021-.013a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.129-8.453c-3.098-5.34-9.986-7.177-15.354-4.093l-.021.013m-.5-.866.023-.014c5.853-3.362 13.338-1.367 16.717 4.458 3.38 5.824 1.374 13.272-4.48 16.635-5.852 3.363-13.337 1.367-16.716-4.458-3.375-5.816-1.38-13.252 4.456-16.621Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96615">
              <path d="M-31.457 6.878a10.281 10.281 0 0 1 14.008 3.736 10.176 10.176 0 0 1-3.754 13.936 10.281 10.281 0 0 1-14.007-3.735 10.176 10.176 0 0 1 3.753-13.937Z" />
              <path
                d="M-30.977 7.755a9.149 9.149 0 0 0-4.297 5.598 9.101 9.101 0 0 0 .929 6.96c2.551 4.398 8.223 5.909 12.644 3.37l.017-.01a9.149 9.149 0 0 0 4.297-5.6 9.101 9.101 0 0 0-.929-6.959c-2.551-4.397-8.223-5.909-12.643-3.37l-.018.01m-.5-.865.02-.011c4.904-2.818 11.175-1.146 14.006 3.734 2.832 4.88 1.15 11.12-3.753 13.938-4.904 2.817-11.175 1.145-14.006-3.735-2.828-4.873-1.155-11.104 3.734-13.926Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96616">
              <path d="M-30.465 8.587A8.291 8.291 0 0 1-19.17 11.6a8.206 8.206 0 0 1-3.027 11.24 8.291 8.291 0 0 1-11.295-3.012 8.206 8.206 0 0 1 3.027-11.24Z" />
              <path
                d="M-29.981 9.463a7.186 7.186 0 0 0-3.376 4.397 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.641 9.932 2.646l.014-.008a7.186 7.186 0 0 0 3.376-4.397 7.147 7.147 0 0 0-.73-5.466c-2.004-3.454-6.46-4.641-9.933-2.646l-.013.008m-.5-.866.015-.01c3.955-2.272 9.012-.923 11.296 3.012 2.283 3.936.928 8.968-3.027 11.24-3.955 2.272-9.012.924-11.295-3.012-2.28-3.93-.932-8.954 3.01-11.23Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96617">
              <path d="M-29.473 10.298a6.3 6.3 0 0 1 8.585 2.29 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.543Z" />
              <path
                d="M-28.985 11.17a5.223 5.223 0 0 0-2.453 3.197 5.194 5.194 0 0 0 .53 3.971c1.457 2.512 4.697 3.375 7.221 1.924l.01-.006a5.223 5.223 0 0 0 2.454-3.196 5.194 5.194 0 0 0-.53-3.971c-1.457-2.512-4.697-3.375-7.222-1.924l-.01.006m-.5-.866.012-.007c3.006-1.727 6.85-.702 8.585 2.289a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.727-6.85.702-8.585-2.289a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96618">
              <path d="M-28.315 12.293a3.98 3.98 0 0 1 5.421 1.447 3.939 3.939 0 0 1-1.452 5.394 3.98 3.98 0 0 1-5.422-1.445 3.939 3.939 0 0 1 1.453-5.396Z" />
              <path
                d="M-27.823 13.164a2.933 2.933 0 0 0-1.378 1.794 2.914 2.914 0 0 0 .298 2.229 2.983 2.983 0 0 0 4.06 1.08l.004-.003a2.933 2.933 0 0 0 1.378-1.794 2.914 2.914 0 0 0-.298-2.229 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.866.007-.005a3.98 3.98 0 0 1 5.422 1.446 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.421-1.445 3.939 3.939 0 0 1 1.445-5.391Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96619">
              <path d="M-27.487 13.717a2.322 2.322 0 0 1 3.16.842 2.3 2.3 0 0 1-.848 3.145 2.322 2.322 0 0 1-3.161-.839 2.3 2.3 0 0 1 .849-3.148Z" />
              <path
                d="M-26.993 14.587a1.297 1.297 0 0 0-.478 1.776c.363.626 1.17.84 1.798.48l.004-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.797-.479l-.005.003m-.5-.866.005-.003a2.322 2.322 0 0 1 3.162.843c.64 1.102.26 2.511-.847 3.148a2.322 2.322 0 0 1-3.163-.844 2.298 2.298 0 0 1 .843-3.144Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101843">
            <g data-name="Path 96620">
              <path d="M-10.786 42.507a12.271 12.271 0 0 1 16.718 4.457A12.145 12.145 0 0 1 1.45 63.6a12.271 12.271 0 0 1-16.716-4.459 12.145 12.145 0 0 1 4.48-16.635Z" />
              <path
                d="M-10.309 43.386a11.112 11.112 0 0 0-5.22 6.8A11.055 11.055 0 0 0-14.4 58.64c3.098 5.34 9.986 7.176 15.354 4.092l.021-.012a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.129-8.454c-3.098-5.34-9.986-7.176-15.354-4.092l-.021.012m-.5-.866.023-.013c5.853-3.363 13.338-1.367 16.717 4.457C9.311 52.79 7.305 60.236 1.452 63.6c-5.853 3.363-13.338 1.367-16.717-4.457-3.375-5.817-1.38-13.253 4.456-16.622Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96621">
              <path d="M-9.793 44.217a10.281 10.281 0 0 1 14.008 3.736A10.176 10.176 0 0 1 .46 61.889a10.281 10.281 0 0 1-14.007-3.735 10.176 10.176 0 0 1 3.753-13.937Z" />
              <path
                d="M-9.312 45.094a9.149 9.149 0 0 0-4.298 5.599 9.101 9.101 0 0 0 .929 6.96c2.551 4.397 8.223 5.908 12.644 3.369l.017-.01a9.149 9.149 0 0 0 4.298-5.599 9.101 9.101 0 0 0-.93-6.96c-2.55-4.397-8.223-5.908-12.643-3.369l-.017.01m-.5-.866.019-.011c4.904-2.817 11.175-1.145 14.006 3.735 2.832 4.88 1.15 11.12-3.753 13.937-4.904 2.818-11.175 1.146-14.006-3.734-2.828-4.874-1.155-11.104 3.734-13.927Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96622">
              <path d="M-8.801 45.927a8.291 8.291 0 0 1 11.295 3.011 8.206 8.206 0 0 1-3.027 11.24 8.291 8.291 0 0 1-11.295-3.011A8.206 8.206 0 0 1-8.8 45.927Z" />
              <path
                d="M-8.317 46.802a7.186 7.186 0 0 0-3.376 4.397 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.642 9.933 2.646l.013-.007a7.186 7.186 0 0 0 3.376-4.398 7.147 7.147 0 0 0-.73-5.465c-2.004-3.455-6.46-4.642-9.933-2.647l-.013.008m-.5-.866.016-.009c3.954-2.272 9.012-.924 11.295 3.012 2.283 3.935.928 8.968-3.027 11.24-3.955 2.272-9.012.923-11.295-3.012-2.28-3.93-.932-8.955 3.01-11.231Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96623">
              <path d="M-7.809 47.638a6.3 6.3 0 0 1 8.585 2.289 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.542Z" />
              <path
                d="M-7.32 48.51a5.223 5.223 0 0 0-2.454 3.196 5.194 5.194 0 0 0 .53 3.972c1.457 2.511 4.697 3.374 7.222 1.923l.01-.005A5.223 5.223 0 0 0 .44 54.4a5.194 5.194 0 0 0-.53-3.972c-1.457-2.511-4.697-3.374-7.221-1.924l-.01.006m-.5-.866.011-.007c3.006-1.727 6.85-.702 8.585 2.29a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.726-6.85.702-8.585-2.29a6.237 6.237 0 0 1 2.289-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96624">
              <path d="M-6.651 49.633a3.98 3.98 0 0 1 5.421 1.446 3.939 3.939 0 0 1-1.452 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.453-5.395Z" />
              <path
                d="M-6.159 50.503a2.933 2.933 0 0 0-1.378 1.795 2.914 2.914 0 0 0 .298 2.228 2.983 2.983 0 0 0 4.06 1.08l.004-.003a2.933 2.933 0 0 0 1.378-1.794 2.914 2.914 0 0 0-.298-2.229 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.866.008-.004a3.98 3.98 0 0 1 5.421 1.446 3.94 3.94 0 0 1-1.452 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.445-5.39Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96625">
              <path d="M-5.823 51.057a2.322 2.322 0 0 1 3.16.841 2.3 2.3 0 0 1-.848 3.146 2.322 2.322 0 0 1-3.16-.84 2.3 2.3 0 0 1 .848-3.147Z" />
              <path
                d="M-5.329 51.926a1.297 1.297 0 0 0-.478 1.777c.363.625 1.17.84 1.798.479l.004-.003a1.297 1.297 0 0 0 .479-1.776 1.322 1.322 0 0 0-1.798-.48l-.005.003m-.5-.866.005-.002a2.322 2.322 0 0 1 3.162.843c.64 1.102.26 2.51-.847 3.147a2.322 2.322 0 0 1-3.163-.843 2.298 2.298 0 0 1 .843-3.145Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101844">
            <g data-name="Path 96626">
              <path d="M-10.938 17.89a12.271 12.271 0 0 1 16.719 4.457 12.145 12.145 0 0 1-4.482 16.637 12.271 12.271 0 0 1-16.716-4.458 12.145 12.145 0 0 1 4.48-16.636Z" />
              <path
                d="M-10.46 18.77a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.128 8.454c3.099 5.34 9.987 7.176 15.355 4.092l.02-.012a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.128-8.454c-3.098-5.34-9.986-7.176-15.354-4.092l-.021.012m-.5-.866.023-.013C-5.084 14.528 2.4 16.523 5.78 22.348 9.16 28.172 7.154 35.62 1.3 38.983c-5.853 3.363-13.337 1.367-16.717-4.457-3.374-5.817-1.379-13.253 4.457-16.622Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96627">
              <path d="M-9.944 19.601a10.281 10.281 0 0 1 14.007 3.736A10.176 10.176 0 0 1 .31 37.273a10.281 10.281 0 0 1-14.007-3.735A10.176 10.176 0 0 1-9.944 19.6Z" />
              <path
                d="M-9.464 20.478a9.149 9.149 0 0 0-4.297 5.599 9.101 9.101 0 0 0 .929 6.96c2.55 4.397 8.223 5.908 12.643 3.369l.017-.01a9.149 9.149 0 0 0 4.298-5.599 9.101 9.101 0 0 0-.929-6.96C.646 19.44-5.026 17.928-9.447 20.468l-.017.01m-.5-.866.02-.011c4.904-2.818 11.175-1.146 14.006 3.734 2.831 4.88 1.15 11.12-3.753 13.938-4.904 2.817-11.175 1.145-14.006-3.735-2.828-4.873-1.156-11.103 3.733-13.926Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96628">
              <path d="M-8.953 21.31a8.291 8.291 0 0 1 11.295 3.012 8.206 8.206 0 0 1-3.026 11.24 8.291 8.291 0 0 1-11.295-3.012 8.206 8.206 0 0 1 3.026-11.24Z" />
              <path
                d="M-8.469 22.186a7.186 7.186 0 0 0-3.375 4.397 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.641 9.932 2.646l.014-.008a7.186 7.186 0 0 0 3.375-4.397 7.147 7.147 0 0 0-.73-5.466c-2.004-3.454-6.46-4.641-9.932-2.646l-.014.008m-.5-.866.016-.01c3.955-2.271 9.012-.923 11.296 3.012 2.283 3.936.927 8.968-3.027 11.24-3.955 2.273-9.012.924-11.295-3.011-2.28-3.93-.932-8.955 3.01-11.231Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96629">
              <path d="M-7.96 23.021a6.3 6.3 0 0 1 8.585 2.29 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.543Z" />
              <path
                d="M-7.472 23.894a5.223 5.223 0 0 0-2.454 3.196 5.194 5.194 0 0 0 .53 3.972c1.458 2.51 4.697 3.374 7.222 1.923l.01-.006A5.223 5.223 0 0 0 .29 29.783a5.194 5.194 0 0 0-.53-3.971c-1.457-2.511-4.697-3.374-7.222-1.924l-.01.006m-.5-.866.012-.007c3.006-1.727 6.85-.702 8.585 2.289a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.727-6.85.702-8.585-2.289a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96630">
              <path d="M-6.802 25.017a3.98 3.98 0 0 1 5.42 1.446 3.939 3.939 0 0 1-1.451 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.453-5.395Z" />
              <path
                d="M-6.31 25.887a2.933 2.933 0 0 0-1.378 1.794 2.914 2.914 0 0 0 .297 2.229 2.983 2.983 0 0 0 4.06 1.08l.005-.003a2.933 2.933 0 0 0 1.378-1.794 2.914 2.914 0 0 0-.298-2.229 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.866.007-.004a3.98 3.98 0 0 1 5.422 1.445 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.422-1.445A3.939 3.939 0 0 1-6.81 25.02Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96631">
              <path d="M-5.975 26.44a2.322 2.322 0 0 1 3.16.842 2.3 2.3 0 0 1-.847 3.146 2.322 2.322 0 0 1-3.161-.84 2.3 2.3 0 0 1 .848-3.147Z" />
              <path
                d="M-5.48 27.31a1.297 1.297 0 0 0-.478 1.777c.363.625 1.17.84 1.798.479l.004-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.798-.479l-.004.003m-.5-.866.004-.003a2.322 2.322 0 0 1 3.163.844c.64 1.102.26 2.51-.847 3.147a2.322 2.322 0 0 1-3.163-.844 2.298 2.298 0 0 1 .843-3.144Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101845">
            <g data-name="Path 96632">
              <path d="M-11.253-7.01A12.271 12.271 0 0 1 5.465-2.555 12.145 12.145 0 0 1 .983 14.083a12.271 12.271 0 0 1-16.716-4.458 12.145 12.145 0 0 1 4.48-16.636Z" />
              <path
                d="M-10.776-6.131a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.128 8.454c3.099 5.34 9.987 7.176 15.355 4.092l.02-.013a11.112 11.112 0 0 0 5.22-6.8A11.055 11.055 0 0 0 4.6-2.05c-3.098-5.34-9.986-7.176-15.354-4.093l-.021.013m-.5-.866.023-.014C-5.4-10.373 2.085-8.378 5.464-2.553 8.844 3.27 6.838 10.719.984 14.082c-5.853 3.363-13.337 1.367-16.717-4.458-3.374-5.816-1.378-13.252 4.457-16.621Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96633">
              <path d="M-10.26-5.3A10.281 10.281 0 0 1 3.747-1.564 10.176 10.176 0 0 1-.006 12.372a10.281 10.281 0 0 1-14.008-3.735A10.176 10.176 0 0 1-10.26-5.3Z" />
              <path
                d="M-9.78-4.423a9.149 9.149 0 0 0-4.297 5.598 9.101 9.101 0 0 0 .929 6.96c2.551 4.398 8.223 5.909 12.643 3.37l.017-.01a9.149 9.149 0 0 0 4.298-5.6 9.101 9.101 0 0 0-.929-6.959C.33-5.461-5.342-6.973-9.763-4.433l-.017.01m-.5-.866.02-.011C-5.356-8.118.915-6.446 3.746-1.566c2.831 4.88 1.15 11.12-3.753 13.938-4.904 2.817-11.175 1.145-14.006-3.735-2.828-4.873-1.156-11.104 3.733-13.926Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96634">
              <path d="M-9.269-3.59A8.291 8.291 0 0 1 2.027-.58 8.206 8.206 0 0 1-1 10.661 8.291 8.291 0 0 1-12.295 7.65 8.206 8.206 0 0 1-9.27-3.59Z" />
              <path
                d="M-8.784-2.715a7.186 7.186 0 0 0-3.376 4.397 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.641 9.932 2.646l.014-.008A7.186 7.186 0 0 0 1.89 5.39a7.147 7.147 0 0 0-.73-5.466C-.841-3.53-5.298-4.718-8.77-2.723l-.013.008m-.5-.866.015-.01C-5.314-5.862-.257-4.513 2.027-.578 4.31 3.357 2.955 8.39-1 10.661c-3.955 2.272-9.012.924-11.295-3.012-2.28-3.93-.932-8.954 3.01-11.23Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96635">
              <path d="M-8.276-1.88A6.3 6.3 0 0 1 .309.41a6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.543Z" />
              <path
                d="M-7.788-1.007a5.223 5.223 0 0 0-2.453 3.196 5.194 5.194 0 0 0 .53 3.971c1.457 2.512 4.696 3.375 7.221 1.924l.01-.006A5.223 5.223 0 0 0-.026 4.882 5.194 5.194 0 0 0-.556.911c-1.457-2.512-4.697-3.375-7.222-1.924l-.01.006m-.5-.866.012-.007C-5.27-3.607-1.426-2.582.31.409a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.727-6.85.702-8.585-2.289a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96636">
              <path d="M-7.118.116a3.98 3.98 0 0 1 5.421 1.446 3.939 3.939 0 0 1-1.452 5.394 3.98 3.98 0 0 1-5.422-1.445A3.939 3.939 0 0 1-7.118.116Z" />
              <path
                d="M-6.626.986A2.933 2.933 0 0 0-8.004 2.78a2.914 2.914 0 0 0 .297 2.229 2.983 2.983 0 0 0 4.06 1.08l.005-.003a2.933 2.933 0 0 0 1.378-1.794 2.914 2.914 0 0 0-.298-2.229 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.866.007-.004A3.98 3.98 0 0 1-1.697 1.56 3.94 3.94 0 0 1-3.15 6.956a3.98 3.98 0 0 1-5.422-1.445A3.939 3.939 0 0 1-7.126.12Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96637">
              <path d="M-6.29 1.54a2.322 2.322 0 0 1 3.16.841 2.3 2.3 0 0 1-.848 3.145 2.322 2.322 0 0 1-3.161-.839 2.3 2.3 0 0 1 .848-3.148Z" />
              <path
                d="M-5.796 2.409a1.297 1.297 0 0 0-.478 1.776c.363.626 1.17.84 1.798.48l.004-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.798-.479l-.004.003m-.5-.866.004-.003a2.322 2.322 0 0 1 3.163.843c.64 1.102.26 2.511-.847 3.148a2.322 2.322 0 0 1-3.163-.844 2.298 2.298 0 0 1 .843-3.144Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101846">
            <g data-name="Path 96638">
              <path d="M10.41 30.329a12.271 12.271 0 0 1 16.72 4.457 12.145 12.145 0 0 1-4.483 16.637 12.271 12.271 0 0 1-16.716-4.459 12.145 12.145 0 0 1 4.48-16.635Z" />
              <path
                d="M10.888 31.208a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.129 8.454c3.098 5.34 9.986 7.176 15.354 4.092l.021-.012a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.129-8.454c-3.098-5.34-9.986-7.176-15.354-4.092l-.021.012m-.5-.866.023-.013c5.853-3.363 13.338-1.367 16.717 4.457 3.38 5.825 1.374 13.272-4.48 16.635-5.852 3.363-13.337 1.367-16.716-4.457-3.375-5.817-1.38-13.253 4.456-16.622Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96639">
              <path d="M11.404 32.04a10.281 10.281 0 0 1 14.008 3.735 10.176 10.176 0 0 1-3.754 13.936A10.281 10.281 0 0 1 7.65 45.976a10.176 10.176 0 0 1 3.753-13.937Z" />
              <path
                d="M11.885 32.916a9.149 9.149 0 0 0-4.298 5.599 9.101 9.101 0 0 0 .929 6.96c2.551 4.397 8.223 5.908 12.644 3.369l.017-.01a9.149 9.149 0 0 0 4.297-5.599 9.101 9.101 0 0 0-.929-6.96c-2.551-4.397-8.223-5.908-12.643-3.369l-.017.01m-.5-.866.019-.01c4.904-2.818 11.175-1.146 14.006 3.734 2.832 4.88 1.15 11.12-3.753 13.937-4.904 2.818-11.175 1.146-14.006-3.734-2.828-4.874-1.155-11.104 3.734-13.927Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96640">
              <path d="M12.396 33.749A8.291 8.291 0 0 1 23.69 36.76 8.206 8.206 0 0 1 20.664 48 8.291 8.291 0 0 1 9.37 44.99a8.206 8.206 0 0 1 3.027-11.24Z" />
              <path
                d="M12.88 34.624a7.186 7.186 0 0 0-3.376 4.397 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.642 9.932 2.646l.014-.007a7.186 7.186 0 0 0 3.376-4.398 7.147 7.147 0 0 0-.73-5.465c-2.004-3.455-6.46-4.642-9.933-2.647l-.013.008m-.5-.866.015-.009c3.955-2.272 9.012-.924 11.296 3.012 2.283 3.935.928 8.968-3.027 11.24-3.955 2.272-9.012.923-11.295-3.012-2.28-3.93-.932-8.955 3.01-11.231Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96641">
              <path d="M13.388 35.46a6.3 6.3 0 0 1 8.585 2.289 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.542Z" />
              <path
                d="M13.876 36.332a5.223 5.223 0 0 0-2.453 3.196 5.194 5.194 0 0 0 .53 3.972c1.457 2.511 4.697 3.374 7.221 1.923l.01-.005a5.223 5.223 0 0 0 2.454-3.196 5.194 5.194 0 0 0-.53-3.972c-1.457-2.511-4.697-3.374-7.222-1.924l-.01.006m-.5-.866.012-.007c3.006-1.727 6.85-.702 8.585 2.29a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.726-6.85.702-8.585-2.29a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96642">
              <path d="M14.546 37.455a3.98 3.98 0 0 1 5.421 1.446 3.939 3.939 0 0 1-1.452 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.453-5.395Z" />
              <path
                d="M15.038 38.325a2.933 2.933 0 0 0-1.378 1.795 2.914 2.914 0 0 0 .298 2.228 2.983 2.983 0 0 0 4.06 1.08l.004-.003a2.933 2.933 0 0 0 1.378-1.794 2.914 2.914 0 0 0-.298-2.229 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.866.007-.004a3.98 3.98 0 0 1 5.422 1.446 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.421-1.446 3.939 3.939 0 0 1 1.445-5.39Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96643">
              <path d="M15.374 38.879a2.322 2.322 0 0 1 3.16.841 2.3 2.3 0 0 1-.848 3.146 2.322 2.322 0 0 1-3.161-.84 2.3 2.3 0 0 1 .849-3.147Z" />
              <path
                d="M15.868 39.748a1.297 1.297 0 0 0-.478 1.777c.363.625 1.17.84 1.798.479l.004-.002a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.797-.48l-.005.003m-.5-.866.005-.002a2.322 2.322 0 0 1 3.162.843c.64 1.102.26 2.51-.847 3.147a2.322 2.322 0 0 1-3.163-.843 2.298 2.298 0 0 1 .843-3.145Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101847">
            <g data-name="Path 96644">
              <path d="M31.456-6.465a12.271 12.271 0 0 1 16.718 4.457 12.145 12.145 0 0 1-4.482 16.636 12.271 12.271 0 0 1-16.715-4.458 12.145 12.145 0 0 1 4.48-16.635Z" />
              <path
                d="M31.933-5.586a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.13 8.454c3.098 5.34 9.985 7.176 15.353 4.092l.021-.012a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.128-8.454c-3.099-5.34-9.987-7.176-15.354-4.092l-.022.012m-.5-.866.023-.013c5.854-3.363 13.338-1.368 16.718 4.457 3.38 5.824 1.374 13.272-4.48 16.635-5.853 3.363-13.337 1.367-16.717-4.457-3.375-5.817-1.379-13.253 4.456-16.622Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96645">
              <path d="M32.45-4.755a10.281 10.281 0 0 1 14.007 3.736 10.176 10.176 0 0 1-3.754 13.936 10.281 10.281 0 0 1-14.007-3.735A10.176 10.176 0 0 1 32.45-4.755Z" />
              <path
                d="M32.93-3.878a9.149 9.149 0 0 0-4.298 5.599 9.101 9.101 0 0 0 .93 6.96c2.55 4.397 8.222 5.908 12.643 3.369l.017-.01A9.149 9.149 0 0 0 46.52 6.44a9.101 9.101 0 0 0-.93-6.96c-2.55-4.397-8.222-5.908-12.643-3.369l-.017.01m-.5-.866.02-.011c4.903-2.818 11.174-1.146 14.006 3.734 2.831 4.88 1.15 11.12-3.753 13.938-4.904 2.817-11.175 1.145-14.007-3.735C25.87 4.31 27.541-1.92 32.43-4.744Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96646">
              <path d="M33.441-3.046A8.291 8.291 0 0 1 44.736-.034a8.206 8.206 0 0 1-3.026 11.24 8.291 8.291 0 0 1-11.295-3.012 8.206 8.206 0 0 1 3.026-11.24Z" />
              <path
                d="M33.925-2.17a7.186 7.186 0 0 0-3.375 4.397 7.147 7.147 0 0 0 .73 5.466c2.003 3.454 6.459 4.641 9.932 2.646l.013-.008a7.186 7.186 0 0 0 3.376-4.397 7.147 7.147 0 0 0-.73-5.466c-2.004-3.454-6.46-4.641-9.932-2.646l-.014.008m-.5-.866.016-.01c3.955-2.271 9.012-.923 11.295 3.013 2.284 3.935.928 8.968-3.026 11.24-3.955 2.272-9.012.923-11.296-3.012-2.28-3.93-.932-8.955 3.011-11.231Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96647">
              <path d="M34.433-1.335a6.3 6.3 0 0 1 8.585 2.29 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.584-2.29 6.237 6.237 0 0 1 2.3-8.543Z" />
              <path
                d="M34.922-.462a5.223 5.223 0 0 0-2.454 3.196 5.194 5.194 0 0 0 .53 3.972c1.457 2.51 4.697 3.374 7.222 1.923l.01-.005a5.223 5.223 0 0 0 2.454-3.197 5.194 5.194 0 0 0-.53-3.971c-1.458-2.511-4.697-3.374-7.222-1.924l-.01.006m-.5-.866.012-.007c3.005-1.727 6.849-.702 8.584 2.289a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.727-6.85.702-8.585-2.289a6.237 6.237 0 0 1 2.289-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96648">
              <path d="M35.591.66a3.98 3.98 0 0 1 5.422 1.447 3.939 3.939 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.422-1.446A3.939 3.939 0 0 1 35.591.661Z" />
              <path
                d="M36.083 1.531a2.933 2.933 0 0 0-1.378 1.795 2.914 2.914 0 0 0 .298 2.228 2.983 2.983 0 0 0 4.06 1.08l.004-.003a2.933 2.933 0 0 0 1.378-1.794 2.914 2.914 0 0 0-.297-2.229 2.983 2.983 0 0 0-4.06-1.08l-.005.003m-.5-.866.008-.004a3.98 3.98 0 0 1 5.422 1.445 3.94 3.94 0 0 1-1.453 5.396 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.445-5.39Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96649">
              <path d="M36.42 2.085a2.322 2.322 0 0 1 3.16.841 2.3 2.3 0 0 1-.849 3.146 2.322 2.322 0 0 1-3.16-.84 2.3 2.3 0 0 1 .848-3.147Z" />
              <path
                d="M36.914 2.954a1.297 1.297 0 0 0-.478 1.777c.362.625 1.17.84 1.797.479l.005-.003a1.297 1.297 0 0 0 .478-1.776 1.322 1.322 0 0 0-1.798-.48l-.004.003m-.5-.866.004-.003a2.322 2.322 0 0 1 3.163.844c.64 1.102.26 2.51-.848 3.147a2.322 2.322 0 0 1-3.162-.843 2.298 2.298 0 0 1 .843-3.145Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101848">
            <g data-name="Path 96650">
              <path d="M31.14-31.366a12.271 12.271 0 0 1 16.718 4.457 12.145 12.145 0 0 1-4.482 16.637 12.271 12.271 0 0 1-16.716-4.459 12.145 12.145 0 0 1 4.48-16.635Z" />
              <path
                d="M31.617-30.487a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.128 8.454c3.099 5.34 9.987 7.176 15.354 4.092l.022-.012a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.129-8.454c-3.098-5.34-9.986-7.176-15.354-4.092l-.021.012m-.5-.866.023-.013c5.853-3.363 13.338-1.367 16.717 4.457 3.38 5.825 1.374 13.273-4.48 16.635C37.525-6.91 30.04-8.907 26.66-14.73c-3.375-5.817-1.379-13.253 4.457-16.622Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96651">
              <path d="M32.133-29.656A10.281 10.281 0 0 1 46.14-25.92a10.176 10.176 0 0 1-3.754 13.936 10.281 10.281 0 0 1-14.007-3.735 10.176 10.176 0 0 1 3.754-13.937Z" />
              <path
                d="M32.613-28.779a9.149 9.149 0 0 0-4.298 5.6 9.101 9.101 0 0 0 .93 6.959c2.55 4.397 8.223 5.909 12.643 3.369l.017-.01a9.149 9.149 0 0 0 4.298-5.599 9.101 9.101 0 0 0-.929-6.96c-2.551-4.397-8.223-5.908-12.644-3.368l-.017.01m-.5-.867.02-.01c4.904-2.818 11.175-1.146 14.006 3.734 2.831 4.88 1.15 11.12-3.753 13.937-4.904 2.818-11.175 1.146-14.006-3.734-2.828-4.874-1.156-11.104 3.733-13.927Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96652">
              <path d="M33.124-27.946a8.291 8.291 0 0 1 11.295 3.012 8.206 8.206 0 0 1-3.026 11.24 8.291 8.291 0 0 1-11.295-3.012 8.206 8.206 0 0 1 3.026-11.24Z" />
              <path
                d="M33.608-27.07a7.186 7.186 0 0 0-3.375 4.396 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.642 9.932 2.646l.014-.007a7.186 7.186 0 0 0 3.375-4.398 7.147 7.147 0 0 0-.73-5.465c-2.004-3.455-6.46-4.642-9.932-2.647l-.014.008m-.5-.866.016-.009c3.955-2.272 9.012-.924 11.295 3.012 2.284 3.935.928 8.968-3.026 11.24-3.955 2.272-9.012.924-11.296-3.012-2.28-3.93-.931-8.955 3.011-11.23Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96653">
              <path d="M34.117-26.235a6.3 6.3 0 0 1 8.584 2.289 6.237 6.237 0 0 1-2.299 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.542Z" />
              <path
                d="M34.605-25.363a5.223 5.223 0 0 0-2.454 3.196 5.194 5.194 0 0 0 .53 3.972c1.458 2.511 4.697 3.374 7.222 1.924l.01-.006a5.223 5.223 0 0 0 2.454-3.196 5.194 5.194 0 0 0-.53-3.972c-1.458-2.511-4.697-3.374-7.222-1.923l-.01.005m-.5-.866.012-.007c3.006-1.726 6.85-.702 8.584 2.29a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.727-6.849.702-8.584-2.29a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96654">
              <path d="M35.275-24.24a3.98 3.98 0 0 1 5.42 1.446 3.939 3.939 0 0 1-1.451 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.453-5.395Z" />
              <path
                d="M35.767-23.37a2.933 2.933 0 0 0-1.378 1.795 2.914 2.914 0 0 0 .297 2.228 2.983 2.983 0 0 0 4.06 1.08l.005-.002a2.933 2.933 0 0 0 1.378-1.795 2.914 2.914 0 0 0-.298-2.228 2.983 2.983 0 0 0-4.06-1.08l-.004.002m-.5-.866.007-.004a3.98 3.98 0 0 1 5.422 1.446 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.446-5.39Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96655">
              <path d="M36.102-22.816a2.322 2.322 0 0 1 3.16.842 2.3 2.3 0 0 1-.847 3.145 2.322 2.322 0 0 1-3.161-.839 2.3 2.3 0 0 1 .848-3.148Z" />
              <path
                d="M36.597-21.947a1.297 1.297 0 0 0-.478 1.777c.363.625 1.17.84 1.798.48l.004-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.798-.48l-.004.003m-.5-.866.004-.002a2.322 2.322 0 0 1 3.163.843c.64 1.102.26 2.51-.847 3.147a2.322 2.322 0 0 1-3.163-.843 2.298 2.298 0 0 1 .843-3.145Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101849">
            <g data-name="Path 96656">
              <path d="M52.804 5.973a12.271 12.271 0 0 1 16.718 4.457 12.145 12.145 0 0 1-4.482 16.637 12.271 12.271 0 0 1-16.716-4.458 12.145 12.145 0 0 1 4.48-16.636Z" />
              <path
                d="M53.28 6.853a11.112 11.112 0 0 0-5.219 6.8 11.055 11.055 0 0 0 1.128 8.454c3.099 5.34 9.987 7.176 15.355 4.092l.02-.013a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.128-8.453c-3.098-5.34-9.986-7.176-15.354-4.093l-.021.013m-.5-.866.023-.014c5.853-3.362 13.338-1.367 16.717 4.458 3.38 5.824 1.374 13.272-4.48 16.635-5.853 3.363-13.337 1.367-16.716-4.458-3.375-5.816-1.38-13.252 4.456-16.621Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96657">
              <path d="M53.797 7.684a10.281 10.281 0 0 1 14.007 3.736 10.176 10.176 0 0 1-3.753 13.936 10.281 10.281 0 0 1-14.007-3.735 10.176 10.176 0 0 1 3.753-13.937Z" />
              <path
                d="M54.277 8.56a9.149 9.149 0 0 0-4.297 5.6 9.101 9.101 0 0 0 .929 6.96c2.551 4.397 8.223 5.908 12.643 3.368l.017-.01a9.149 9.149 0 0 0 4.298-5.598 9.101 9.101 0 0 0-.929-6.96c-2.551-4.397-8.223-5.909-12.644-3.369l-.017.01m-.5-.866.02-.011c4.904-2.818 11.175-1.146 14.006 3.734 2.831 4.88 1.15 11.12-3.753 13.938-4.904 2.817-11.175 1.145-14.006-3.735-2.828-4.873-1.156-11.104 3.733-13.926Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96658">
              <path d="M54.788 9.393a8.291 8.291 0 0 1 11.296 3.012 8.206 8.206 0 0 1-3.027 11.24 8.291 8.291 0 0 1-11.295-3.012 8.206 8.206 0 0 1 3.026-11.24Z" />
              <path
                d="M55.273 10.269a7.186 7.186 0 0 0-3.376 4.397 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.641 9.932 2.646l.014-.008a7.186 7.186 0 0 0 3.375-4.397 7.147 7.147 0 0 0-.73-5.466c-2.003-3.454-6.46-4.641-9.932-2.646l-.013.008m-.5-.866.015-.01c3.955-2.272 9.012-.923 11.296 3.012 2.283 3.936.928 8.968-3.027 11.24-3.955 2.272-9.012.924-11.295-3.011-2.28-3.93-.932-8.955 3.01-11.231Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96659">
              <path d="M55.78 11.104a6.3 6.3 0 0 1 8.586 2.29 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.543Z" />
              <path
                d="M56.27 11.977a5.223 5.223 0 0 0-2.454 3.196 5.194 5.194 0 0 0 .53 3.971c1.457 2.512 4.696 3.375 7.221 1.924l.01-.006a5.223 5.223 0 0 0 2.454-3.196 5.194 5.194 0 0 0-.53-3.971c-1.457-2.512-4.697-3.375-7.222-1.924l-.01.006m-.5-.866.012-.007c3.006-1.727 6.85-.702 8.585 2.289a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.727-6.85.702-8.585-2.289a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96660">
              <path d="M56.939 13.1a3.98 3.98 0 0 1 5.421 1.446 3.939 3.939 0 0 1-1.452 5.394 3.98 3.98 0 0 1-5.422-1.445 3.939 3.939 0 0 1 1.453-5.395Z" />
              <path
                d="M57.43 13.97a2.933 2.933 0 0 0-1.377 1.794 2.914 2.914 0 0 0 .297 2.229 2.983 2.983 0 0 0 4.06 1.08l.005-.003a2.933 2.933 0 0 0 1.378-1.794 2.914 2.914 0 0 0-.298-2.229 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.866.007-.004a3.98 3.98 0 0 1 5.422 1.445 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.422-1.445 3.939 3.939 0 0 1 1.446-5.391Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96661">
              <path d="M57.767 14.523a2.322 2.322 0 0 1 3.16.842 2.3 2.3 0 0 1-.848 3.145 2.322 2.322 0 0 1-3.161-.839 2.3 2.3 0 0 1 .849-3.148Z" />
              <path
                d="M58.261 15.393a1.297 1.297 0 0 0-.478 1.776c.363.626 1.17.84 1.798.48l.004-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.798-.479l-.004.003m-.5-.866.004-.003a2.322 2.322 0 0 1 3.163.843c.64 1.102.26 2.511-.847 3.148a2.322 2.322 0 0 1-3.163-.844 2.298 2.298 0 0 1 .843-3.144Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101850">
            <g data-name="Path 96662">
              <path d="M52.653-18.642a12.271 12.271 0 0 1 16.718 4.457 12.145 12.145 0 0 1-4.482 16.637 12.271 12.271 0 0 1-16.716-4.459 12.145 12.145 0 0 1 4.48-16.635Z" />
              <path
                d="M53.13-17.763a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.129 8.454c3.098 5.34 9.986 7.176 15.354 4.092l.021-.012a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.129-8.454c-3.098-5.34-9.986-7.176-15.354-4.092l-.021.012m-.5-.866.023-.013c5.853-3.363 13.338-1.367 16.717 4.457C72.75-8.36 70.744-.912 64.89 2.45c-5.852 3.363-13.337 1.368-16.716-4.457-3.375-5.817-1.38-13.253 4.456-16.622Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96663">
              <path d="M53.646-16.932a10.281 10.281 0 0 1 14.008 3.736A10.176 10.176 0 0 1 63.9.74a10.281 10.281 0 0 1-14.007-3.735 10.176 10.176 0 0 1 3.753-13.937Z" />
              <path
                d="M54.126-16.055a9.149 9.149 0 0 0-4.297 5.6 9.101 9.101 0 0 0 .929 6.959C53.309.9 58.98 2.413 63.4-.127l.018-.01a9.149 9.149 0 0 0 4.297-5.599 9.101 9.101 0 0 0-.929-6.96c-2.551-4.397-8.223-5.908-12.643-3.368l-.018.01m-.5-.867.02-.01c4.904-2.818 11.175-1.146 14.006 3.734C70.484-8.317 68.802-2.077 63.9.74c-4.904 2.818-11.175 1.146-14.006-3.734-2.828-4.874-1.156-11.104 3.733-13.927Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96664">
              <path d="M54.637-15.222a8.291 8.291 0 0 1 11.296 3.012A8.206 8.206 0 0 1 62.906-.97a8.291 8.291 0 0 1-11.295-3.012 8.206 8.206 0 0 1 3.026-11.24Z" />
              <path
                d="M55.122-14.347a7.186 7.186 0 0 0-3.376 4.398 7.147 7.147 0 0 0 .73 5.465c2.004 3.455 6.46 4.642 9.932 2.647l.014-.008a7.186 7.186 0 0 0 3.375-4.398 7.147 7.147 0 0 0-.73-5.465c-2.003-3.454-6.459-4.642-9.932-2.646l-.013.007m-.5-.866.015-.009c3.955-2.272 9.012-.924 11.296 3.012 2.283 3.935.928 8.968-3.027 11.24-3.955 2.272-9.012.924-11.295-3.012-2.28-3.93-.932-8.954 3.01-11.23Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96665">
              <path d="M55.63-13.511a6.3 6.3 0 0 1 8.585 2.289 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.542Z" />
              <path
                d="M56.118-12.639a5.223 5.223 0 0 0-2.453 3.196 5.194 5.194 0 0 0 .53 3.972c1.457 2.511 4.696 3.374 7.221 1.924l.01-.006A5.223 5.223 0 0 0 63.88-6.75a5.194 5.194 0 0 0-.53-3.972c-1.457-2.511-4.697-3.374-7.222-1.923l-.01.005m-.5-.866.012-.006c3.006-1.727 6.85-.703 8.585 2.288a6.236 6.236 0 0 1-2.3 8.543c-3.006 1.727-6.85.702-8.585-2.29a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96666">
              <path d="M56.788-11.516a3.98 3.98 0 0 1 5.421 1.446 3.939 3.939 0 0 1-1.452 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.453-5.395Z" />
              <path
                d="M57.28-10.645a2.933 2.933 0 0 0-1.378 1.794 2.914 2.914 0 0 0 .298 2.228 2.983 2.983 0 0 0 4.06 1.08l.004-.002a2.933 2.933 0 0 0 1.378-1.795 2.914 2.914 0 0 0-.298-2.228 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.867.007-.004a3.98 3.98 0 0 1 5.422 1.446 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.421-1.446 3.939 3.939 0 0 1 1.445-5.39Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96667">
              <path d="M57.616-10.092a2.322 2.322 0 0 1 3.16.842 2.3 2.3 0 0 1-.848 3.145 2.322 2.322 0 0 1-3.161-.839 2.3 2.3 0 0 1 .849-3.148Z" />
              <path
                d="M58.11-9.223a1.297 1.297 0 0 0-.478 1.777c.363.625 1.17.84 1.798.48l.004-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.797-.48l-.005.003m-.5-.866.005-.002a2.322 2.322 0 0 1 3.162.843c.64 1.102.26 2.511-.847 3.147a2.322 2.322 0 0 1-3.163-.843 2.298 2.298 0 0 1 .843-3.145Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101851">
            <g data-name="Path 96668">
              <path d="M52.336-43.544a12.271 12.271 0 0 1 16.719 4.457 12.145 12.145 0 0 1-4.482 16.637 12.271 12.271 0 0 1-16.716-4.459 12.145 12.145 0 0 1 4.48-16.635Z" />
              <path
                d="M52.813-42.665a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.13 8.454c3.098 5.34 9.985 7.176 15.353 4.092l.022-.012a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.13-8.454c-3.098-5.34-9.985-7.176-15.353-4.092l-.022.012m-.5-.866.024-.013c5.853-3.363 13.337-1.367 16.717 4.457 3.38 5.825 1.374 13.273-4.48 16.635-5.853 3.363-13.337 1.368-16.717-4.457-3.375-5.817-1.379-13.253 4.456-16.622Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96669">
              <path d="M53.33-41.834a10.281 10.281 0 0 1 14.007 3.736 10.176 10.176 0 0 1-3.754 13.936 10.281 10.281 0 0 1-14.007-3.735 10.176 10.176 0 0 1 3.754-13.937Z" />
              <path
                d="M53.81-40.957a9.149 9.149 0 0 0-4.298 5.6 9.101 9.101 0 0 0 .93 6.959c2.55 4.397 8.222 5.909 12.643 3.369l.017-.01a9.149 9.149 0 0 0 4.298-5.599 9.101 9.101 0 0 0-.93-6.96c-2.55-4.397-8.222-5.908-12.643-3.368l-.017.01m-.5-.867.02-.01c4.904-2.818 11.174-1.146 14.006 3.734 2.831 4.88 1.15 11.12-3.753 13.937-4.904 2.818-11.175 1.146-14.007-3.734C46.75-32.77 48.421-39 53.31-41.823Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96670">
              <path d="M54.321-40.124a8.291 8.291 0 0 1 11.295 3.012 8.206 8.206 0 0 1-3.026 11.24 8.291 8.291 0 0 1-11.295-3.012 8.206 8.206 0 0 1 3.026-11.24Z" />
              <path
                d="M54.805-39.249a7.186 7.186 0 0 0-3.375 4.398 7.147 7.147 0 0 0 .73 5.465c2.003 3.455 6.46 4.642 9.932 2.647l.013-.008a7.186 7.186 0 0 0 3.376-4.398 7.147 7.147 0 0 0-.73-5.465c-2.004-3.455-6.46-4.642-9.932-2.647l-.014.008m-.5-.866.016-.009c3.955-2.272 9.012-.924 11.295 3.012 2.284 3.935.928 8.968-3.026 11.24-3.955 2.272-9.012.924-11.296-3.012-2.28-3.93-.931-8.954 3.011-11.23Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96671">
              <path d="M55.314-38.413a6.3 6.3 0 0 1 8.584 2.289 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.584-2.29 6.237 6.237 0 0 1 2.3-8.542Z" />
              <path
                d="M55.802-37.54a5.223 5.223 0 0 0-2.454 3.195 5.194 5.194 0 0 0 .53 3.972c1.458 2.511 4.697 3.374 7.222 1.924l.01-.006a5.223 5.223 0 0 0 2.454-3.196 5.194 5.194 0 0 0-.53-3.972c-1.458-2.511-4.697-3.374-7.222-1.923l-.01.005m-.5-.866.012-.007c3.006-1.726 6.849-.702 8.584 2.29a6.236 6.236 0 0 1-2.3 8.542c-3.006 1.727-6.85.702-8.584-2.29a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96672">
              <path d="M56.471-36.418a3.98 3.98 0 0 1 5.422 1.446 3.939 3.939 0 0 1-1.452 5.395 3.98 3.98 0 0 1-5.423-1.446 3.939 3.939 0 0 1 1.453-5.395Z" />
              <path
                d="M56.963-35.548a2.933 2.933 0 0 0-1.377 1.795 2.914 2.914 0 0 0 .297 2.228 2.983 2.983 0 0 0 4.06 1.08l.004-.002a2.933 2.933 0 0 0 1.378-1.795 2.914 2.914 0 0 0-.297-2.228 2.983 2.983 0 0 0-4.06-1.08l-.005.002m-.5-.866.008-.004a3.98 3.98 0 0 1 5.422 1.446 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.422-1.446 3.939 3.939 0 0 1 1.445-5.39Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96673">
              <path d="M57.3-34.994a2.322 2.322 0 0 1 3.16.842 2.3 2.3 0 0 1-.849 3.145 2.322 2.322 0 0 1-3.16-.839 2.3 2.3 0 0 1 .848-3.148Z" />
              <path
                d="M57.794-34.125a1.297 1.297 0 0 0-.478 1.777c.362.625 1.17.84 1.797.48l.005-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.798-.48l-.004.003m-.5-.866.004-.002a2.322 2.322 0 0 1 3.163.843c.64 1.102.26 2.51-.848 3.147a2.322 2.322 0 0 1-3.162-.843 2.298 2.298 0 0 1 .843-3.145Z"
                fill="#9493d8"
              />
            </g>
          </g>
          <g data-name="Group 101852">
            <g data-name="Path 96674">
              <path d="M74-6.205A12.271 12.271 0 0 1 90.72-1.748a12.145 12.145 0 0 1-4.482 16.637 12.271 12.271 0 0 1-16.716-4.458 12.145 12.145 0 0 1 4.48-16.636Z" />
              <path
                d="M74.478-5.325a11.112 11.112 0 0 0-5.22 6.8 11.055 11.055 0 0 0 1.128 8.454c3.099 5.34 9.987 7.176 15.355 4.092l.02-.013a11.112 11.112 0 0 0 5.22-6.8 11.055 11.055 0 0 0-1.128-8.453c-3.098-5.34-9.986-7.176-15.354-4.092l-.021.012m-.5-.866L74-6.205c5.853-3.362 13.338-1.367 16.717 4.458 3.38 5.824 1.374 13.272-4.48 16.635-5.853 3.363-13.337 1.367-16.717-4.458-3.374-5.816-1.379-13.252 4.457-16.621Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96675">
              <path d="M74.994-4.494A10.281 10.281 0 0 1 89-.758a10.176 10.176 0 0 1-3.754 13.936A10.281 10.281 0 0 1 71.24 9.443a10.176 10.176 0 0 1 3.754-13.937Z" />
              <path
                d="M75.474-3.617a9.149 9.149 0 0 0-4.298 5.599 9.101 9.101 0 0 0 .93 6.96c2.55 4.397 8.223 5.908 12.643 3.368l.017-.01a9.149 9.149 0 0 0 4.298-5.598 9.101 9.101 0 0 0-.929-6.96c-2.551-4.397-8.223-5.909-12.644-3.369l-.017.01m-.5-.866.02-.011C79.898-7.312 86.169-5.64 89-.76c2.831 4.88 1.15 11.12-3.753 13.938-4.904 2.817-11.175 1.145-14.006-3.735C68.413 4.57 70.085-1.66 74.974-4.483Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96676">
              <path d="M75.985-2.785A8.291 8.291 0 0 1 87.28.227a8.206 8.206 0 0 1-3.026 11.24A8.291 8.291 0 0 1 72.96 8.455a8.206 8.206 0 0 1 3.026-11.24Z" />
              <path
                d="M76.47-1.91a7.186 7.186 0 0 0-3.376 4.398 7.147 7.147 0 0 0 .73 5.466c2.004 3.454 6.46 4.641 9.932 2.646l.014-.008a7.186 7.186 0 0 0 3.375-4.397 7.147 7.147 0 0 0-.73-5.466c-2.004-3.454-6.46-4.641-9.932-2.646l-.014.008m-.5-.866.016-.01c3.955-2.272 9.012-.923 11.296 3.012 2.283 3.936.927 8.968-3.027 11.24-3.955 2.272-9.012.924-11.296-3.011-2.28-3.93-.931-8.955 3.011-11.231Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96677">
              <path d="M76.978-1.074a6.3 6.3 0 0 1 8.585 2.29 6.237 6.237 0 0 1-2.3 8.543 6.3 6.3 0 0 1-8.585-2.29 6.237 6.237 0 0 1 2.3-8.543Z" />
              <path
                d="M77.466-.201a5.223 5.223 0 0 0-2.454 3.196 5.194 5.194 0 0 0 .53 3.971C77 9.478 80.24 10.341 82.765 8.89l.01-.006a5.223 5.223 0 0 0 2.454-3.196 5.194 5.194 0 0 0-.53-3.971C83.24-.795 80-1.658 77.476-.207l-.01.006m-.5-.866.012-.007c3.006-1.727 6.85-.702 8.584 2.289a6.236 6.236 0 0 1-2.3 8.542c-3.005 1.727-6.849.702-8.584-2.289a6.237 6.237 0 0 1 2.288-8.535Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96678">
              <path d="M78.136.922a3.98 3.98 0 0 1 5.42 1.446 3.939 3.939 0 0 1-1.451 5.394 3.98 3.98 0 0 1-5.422-1.445A3.939 3.939 0 0 1 78.136.922Z" />
              <path
                d="M78.628 1.792a2.933 2.933 0 0 0-1.378 1.794 2.914 2.914 0 0 0 .297 2.229 2.983 2.983 0 0 0 4.06 1.08l.005-.003a2.933 2.933 0 0 0 1.378-1.794 2.914 2.914 0 0 0-.298-2.229 2.983 2.983 0 0 0-4.06-1.08l-.004.003m-.5-.866.007-.004a3.98 3.98 0 0 1 5.422 1.445 3.94 3.94 0 0 1-1.453 5.395 3.98 3.98 0 0 1-5.422-1.445A3.939 3.939 0 0 1 78.128.926Z"
                fill="#9493d8"
              />
            </g>
            <g data-name="Path 96679">
              <path d="M78.963 2.345a2.322 2.322 0 0 1 3.16.842 2.3 2.3 0 0 1-.847 3.145 2.322 2.322 0 0 1-3.161-.839 2.3 2.3 0 0 1 .848-3.148Z" />
              <path
                d="M79.458 3.215a1.297 1.297 0 0 0-.478 1.776c.363.626 1.17.84 1.798.48l.004-.003a1.297 1.297 0 0 0 .478-1.777 1.322 1.322 0 0 0-1.798-.479l-.004.003m-.5-.866.004-.003a2.322 2.322 0 0 1 3.163.843c.64 1.102.26 2.511-.847 3.148a2.322 2.322 0 0 1-3.163-.844 2.298 2.298 0 0 1 .843-3.144Z"
                fill="#9493d8"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        d="M16.43 9.256a1.55 1.55 0 1 0 1.549 1.55 1.55 1.55 0 0 0-1.549-1.55Zm-6.2.775a1.55 1.55 0 1 0 1.549 1.55 1.55 1.55 0 0 0-1.55-1.55Zm12.4 0a1.55 1.55 0 1 0 1.55 1.55 1.55 1.55 0 0 0-1.548-1.55Zm-6.2 3.488a2.326 2.326 0 0 0-2.325 2.325v1.163h-.969a.582.582 0 1 0 0 1.163h6.589a.582.582 0 1 0 0-1.163h-.969v-1.162a2.326 2.326 0 0 0-2.326-2.326Zm-6.2.775a1.55 1.55 0 0 0-1.551 1.551v3.1a1.55 1.55 0 0 0 1.453 1.547l2.616.2s.2 2.379.2 2.4a.581.581 0 0 0 1.157-.079v-3.1a.582.582 0 0 0-.437-.563l-1.889-.4v-3.1a1.55 1.55 0 0 0-1.55-1.556Zm12.4 0a1.55 1.55 0 0 0-1.55 1.55v3.1l-1.889.4a.582.582 0 0 0-.437.563v3.1a.581.581 0 0 0 1.157.079c0-.026.2-2.4.2-2.4l2.616-.2a1.55 1.55 0 0 0 1.452-1.541v-3.1a1.55 1.55 0 0 0-1.547-1.551Z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default MeetIcon

