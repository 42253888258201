import { Box, Grid } from '@mui/material'
import React from 'react'
import { ReceiptHistoryHeading } from '../../utils/invoice'
import { TableWithPagination, SearchFilter } from '../../components'
import { ReceiptHistoryStyle } from './style'
export const ReceiptHistoryTable = ({ ReceiptTableData = {}, searchText = "", handleSearch = () => false }) => {
    const classes = ReceiptHistoryStyle()
    return (
        <Box>
            <Grid container className={classes.content} spacing={2}>
                <Grid item xs={4}>
                    <SearchFilter placeholder={"Search Receipt History"} value={searchText} handleChange={(value) => handleSearch(value)} />
                </Grid>
            </Grid>
            <TableWithPagination
                rows={ReceiptTableData}
                heading={ReceiptHistoryHeading}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                onClick={() => false}
                dataType={[
                    { type: ["text"], name: "created_at" },
                    { type: ["text"], name: "reciepts_no" },
                    { type: ["text"], name: "settlement_no" },
                    { type: ["text"], name: "source" },
                    { type: ["text"], name: "tagged_by" },
                    { type: ["text"], name: "amount_tagged" },

                ]}
                tableType="no-side"
                showpagination={false}
                height={`calc(100vh - 310px)`}
                noDataSvg

            />
        </Box>
    )
}