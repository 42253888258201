export const DownloadIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={15.99} height={16} {...props}>
        <path
            fill={props?.fill ?? "#4e5a6b"}
            d="M13.985 5.978a.666.666 0 0 0-.656.676v9.5L11.8 14.623a.666.666 0 1 0-.942.942l2.665 2.665a.666.666 0 0 0 .942 0l2.665-2.665a.666.666 0 1 0-.942-.942l-1.527 1.527v-9.5a.666.666 0 0 0-.677-.676Zm-5.542 4.9A2.453 2.453 0 0 0 6 13.317v6.218a2.453 2.453 0 0 0 2.443 2.443h11.1a2.453 2.453 0 0 0 2.443-2.443v-6.218a2.453 2.453 0 0 0-2.443-2.443h-2.661a.666.666 0 1 0 0 1.333h2.665a1.1 1.1 0 0 1 1.11 1.11v6.218a1.1 1.1 0 0 1-1.11 1.11H8.443a1.1 1.1 0 0 1-1.11-1.11v-6.218a1.1 1.1 0 0 1 1.11-1.11h2.665a.666.666 0 1 0 0-1.333Z"
            data-name="icons8-download (7)"
            transform="translate(-6 -5.978)"
        />
    </svg>
)
