import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
  tabContant: {
    padding: "12px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    marginTop: "53px",
  },
  tabTitle: {
    color: "#4E5A6B",
    fontSize:"1rem",
    fontFamily: SemiBold,
  },
  image: {
    borderRight: "1px solid #E4E8EE",
  },
  viewCard: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "16px",
    marginTop: '16px'
  },
  customTypography: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    color: '#4E5A6B',
    marginBottom: 10
  }
}));
export const useStylesDetailsCard = makeStyles((backgroundColor, props) => ({
  root: {
    padding: "12px",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  heading: {
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    color: " #98A0AC",
  },
  sub: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    color: "#091B29",
    marginTop: "6px",
  },
  status: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    padding: "2px 10px",
    borderRadius: "2px",
    display: "inline-block",
    marginTop: "6px",
  },
  btn: {
    padding: "0px",
    boxShadow: "none",
    marginTop: "6px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  title: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    color: "#4E5A6B",
  },
  imageView: {
    height: "100px",
    width: "100px",
    margin: '0 auto'
  },
}));