import CheckCircle from "@mui/icons-material/CheckCircle"
import { Avatar, Box, Stack, Typography } from "@mui/material"
import React from "react"
import EditIconSvg from "../../../assets/editIcon"
import { stringAvatar } from "../../../utils"
import { useStyles } from "./style"
export const Cards = ({ details = {}, isSelect = false, onClick = () => false, edit = false, unit = false, setStep = () => false, request = false, updateState = () => false }) => {
    const classes = useStyles()
    const goToUnitSelection = () => {
        setStep(2)
        updateState("disable", false)
    }
    return (
        <Box className={isSelect ? classes.selcardblock : classes.cardblock} onClick={onClick}>

            <Stack direction="row" flexWrap={"wrap"}>
                <Avatar src={details?.logo ?? details?.image_url}  {...stringAvatar(details?.name ?? details?.label)} />
                <Box marginInlineStart={'8px'}>
                    <Typography className={classes.name}>{details?.name ?? details?.label}</Typography>
                    {unit ?
                        <Box display="flex">
                            {details?.floor?.name?.length > 0 &&
                                <Typography className={classes.location}>{details?.floor?.name} ,</Typography>
                            }
                            {
                                details?.block?.name?.length > 0 &&
                                <Typography className={classes.location}>{details?.floor?.name} ,</Typography>

                            }

                            <Typography className={classes.location}>{`${details?.property?.name}`}</Typography>
                        </Box>
                        : request ?
                            <Typography className={classes.location}>{`${details?.request_no}, ${details?.category}`}</Typography>
                            : <Typography className={classes.location}>{`${details?.area}, ${details?.city}`}</Typography>
                    }
                </Box>
            </Stack>
            {isSelect &&
                <Box className={classes.checkIcon}><CheckCircle /></Box>
            }
            {edit &&
                <Box className={classes.checkIcon} onClick={() => goToUnitSelection()}><EditIconSvg /></Box>
            }
        </Box>
    )
}