
import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
      root: {
            height: "100vh",
            backgroundColor: "white",
      },
      grid: {
            height: ({ size }) => size?.height,
            alignItems: "center",
      },
      left_section: {
            height: ({ size }) => size?.height,
            [theme.breakpoints.down("sm")]: {
                  display: "none"
            },
            padding: "16px",
            paddingInlineEnd: "0px",
      },
      web_background: {
            height: ({ size }) => size?.height - 32,
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
      },
      right_section: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            display: "flex",
            justifyContent: "end",
      },
      web_right_section_card: {
            display: "contents",
            height: ({ size }) => size?.height,
            padding: "0px",
      },
      web_content: {
            height: "100vh",
            overflow: "overlay",
            display: "flex",
            position: "relative",
            padding: "16px 0px",
            backgroundColor: "white",
      },
      web_logo: {
            height: "32px",
            objectFit: "contain",
      },
      web_description: {
            fontSize: remCalc(20),
            fontFamily: SemiBold,
            color: theme.typography.color.secondary,
      },
      web_set_up_new_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: theme.palette.background.button_background_1,
            border: "1px solid #5078E1",
      },
      web_set_up_new_button_text: {
            fontSize: remCalc(14),
            fontFamily: SemiBold,
            color: theme.typography.color.white,
      },
      web_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius_2,
            boxShadow: "0px 6px 10px #00000014",
            border: "1px solid #5078E1",
            "&:hover": {
                  backgroundColor: theme.palette.background.tertiary1,
            },
      },
      web_log_in_button_text: {
            fontSize: remCalc(14),
            fontFamily: SemiBold,
            color: theme.typography.color.primary_2,
      },
      web_terms_of_use_description: {
            textAlign: "center",
            fontSize: remCalc(12),
            fontFamily: Regular,
            color: theme.typography.color.secondary,
      },
      web_terms_of_use_word: {
            fontSize: remCalc(12),
            fontFamily: Bold,
            color: theme.typography.color.secondary,
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
      },
      web_version_no: {
            fontSize: remCalc(12),
            fontFamily: Regular,
            color: theme.typography.color.tertiary,
      },
      web_powered_by_logo: {
            height: "11px",
            objectFit: "contain",
      },
      mob_right_section_card: {
            height: ({ size }) => size?.height,
            alignItems: "center",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            padding: "0px",
      },
      mob_content: {
            paddingTop: ({ size }) => size?.height - 336,
            overflow: "overlay",
      },
      mob_items: {
            padding: "16px 16px 18px 16px",
      },
      mob_logo: {
            height: "32px",
            objectFit: "contain",
      },
      mob_description: {
            fontSize: remCalc(20),
            fontFamily: SemiBold,
            color: "#FEF4F4",
            textShadow: "0px 6px 10px #00000033",
      },
      mob_set_up_new_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: theme.palette.background.button_background_1,
            border: "1px solid #5078E1",
      },
      mob_set_up_new_button_text: {
            fontSize: remCalc(14),
            fontFamily: SemiBold,
            color: theme.typography.color.white,
      },
      mob_log_in_button: {
            height: "48px",
            width: "100%",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            backgroundColor: "#ffffff75",
            "&:hover": {
                  backgroundColor: "#ffffff75",
            },
      },
      mob_log_in_button_text: {
            fontSize: remCalc(14),
            fontFamily: SemiBold,
            color: theme.typography.color.white,
      },
      mob_terms_of_use_description: {
            width: "100%",
            fontSize: remCalc(12),
            fontFamily: Regular,
            color: theme.typography.color.white,
      },
      mob_terms_of_use_word: {
            fontSize: remCalc(12),
            fontFamily: Bold,
            color: theme.typography.color.white,
            textDecoration: "underline",
            display: "inline",
            cursor: "pointer",
      },
      drawer: {
            "& .MuiDrawer-paper": {
                  minWidth: ({ size }) => size?.width > 599 && "500px",
                  maxWidth: ({ size }) => size?.width > 599 && "500px",
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
            },
            "& .MuiContainer-root": {
                  padding: "0px 8px",
            },
      },
      drawer_header_grid: {
            display: "flex",
            alignItems: "center",
            justifyContent: ({ size }) => size?.width > 599 ? "start" : "end",
            padding: "16px",
            backgroundColor:"#F2F4F7"
      },
      drawer_header_text: {
            marginLeft: "12px",
            fontSize: remCalc(16),
            fontFamily: ExtraBold,
            color: theme.typography.color.primary,
      },
      drawer_content_box: {
            height: ({ size }) => size?.width > 599 ? "calc(100vh - 145px)" : "450px",
            overflow: "overlay",
            padding: "16px",
      },
      drawer_button_box: {
            padding: "16px",
            borderTop: `1px solid ${theme.palette.border.secondary}`
      },
      agree_and_start_button: {
            height: "48px",
            fontFamily: Bold,
      },
      welcome_backdrop: {
            width: "100%",
            objectFit: "contain",
      },
}));