import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import React from "react";
// import Slider from "react-slick";
import { Slider } from "../../components";
import { ApprovalPopupStyle } from "./style";
import NoDataFound from "../../assets/noData";

export const ApprovalPopup = ({ t, data = {}, handleRemarksPopup = () => false,isDisableBtn=false }) => {
    const classes = ApprovalPopupStyle()

    return (
        <Box>
            <Box className={classes.innerContent}>
                <Grid container>
                    <Grid item md={12}>
                        {
                            data?.url?.length > 0 ?
                                <Slider uploadImage={false}
                                    heightSx={'330px'}
                                    leftDivider={2}
                                    divider={8}
                                    rightDivider={2}
                                    customStyle={classes.sideColor}
                                    assets={
                                        data?.url?.map((e) => {
                                            return (
                                                {
                                                    url: e?.src,
                                                    type: e?.type,
                                                    name: e?.name,
                                                    asset_type: data?.url?.[0]?.type === "application/pdf" ? 4 : 1
                                                }
                                            )
                                        })} />
                                :
                                <Box className={classes.noData}>
                                    <NoDataFound />
                                </Box>
                        }
                    </Grid>
                    <Grid item md={12}>
                        <Box className={classes.bottomContent} ml={4} mt={2} mb={2} mr={4}>
                            <Grid container p={2} spacing={2}>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>{t("Proof")}</Typography>
                                        <Typography className={classes.content}>{data?.identification_master?.type}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>{t("Name As In Proof")}</Typography>
                                        <Typography className={classes.content}>{data?.name}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>{t("Id Proof Number")}</Typography>
                                        <Typography className={classes.content}>{data?.number}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>{t("Vaild From")}</Typography>
                                        <Typography className={classes.content}>&#x202a;{moment(data?.valid_from).format("DD MMM YY")}&#x202c;</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>{t("Vaild To")}</Typography>
                                        <Typography className={classes.content}>&#x202a;{moment(data?.valid_to).format("DD MMM YY")}&#x202c;</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={4}>
                                    <Stack spacing={1}>
                                        <Typography className={classes.title}>{t("Issuing Country")}</Typography>
                                        <Typography className={classes.content}>{data?.country_master?.country_name}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {
                data?.status === "Yet to be Verified" ?
                    <Box p={2}>
                        <Stack direction={"row"} spacing={2}>
                            <Button fullWidth variant="contained" className={classes.decline} onClick={() => handleRemarksPopup("Decline Proof", "popup")} disabled={isDisableBtn}>{t("Decline")}</Button>
                            <Button fullWidth variant="contained" className={classes.approve} onClick={() => handleRemarksPopup("Approve Proof", "popup")} disabled={isDisableBtn}>{t("Approve")}</Button>
                        </Stack>
                    </Box>
                    :
                    <Box p={2}>
                        <Stack spacing={1}>
                            <Typography className={classes[`${data?.status}`]}>{data?.status}</Typography>
                            <Stack direction={"row"} spacing={1} textAlign={"center"}>
                                <Typography className={classes.remarkTitle}>{t("Remarks")}:</Typography>
                                <Typography className={classes.remarkContent}>{data?.remarks}</Typography>
                            </Stack>
                        </Stack>
                    </Box>
            }
        </Box>
    )
}