import { Box, Grid, Stack, Typography } from "@mui/material";
// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';
import { useStyles } from "../styles";
import { AuthContext } from "../../../contexts";
import React from "react";
export const Address = ({ data = {}, t }) => {
    const classes = useStyles()
    const auth = React.useContext(AuthContext)

    return (
        <Box className={classes.box} marginTop="16px">
            <Grid container spacing={2}>
                {/* <Grid item xs={5}>
                    <div className={classes.map}>
                        <MyGoogleMap />
                    </div>
                </Grid> */}
                <Grid item xs={6}>
                    <div className={classes.addressRoot}>
                        <Stack direction="row" >
                            <img src='/images/loc.svg' alt='' />
                            <Typography className={classes.title}>&nbsp;{t("Address")}</Typography>
                        </Stack>
                        <Box height="12px" />
                        {data?.address?.length > 0 ?
                            <Typography className={classes.sub}>
                                {data?.address?.[0]?.street_1 ?? ""}
                                {data?.address?.[0]?.street_1 ? ", " : ""}
                                {data?.address?.[0]?.street_2 ?? ""}
                                {data?.address?.[0]?.street_2 ? ", " : ""}
                                {data?.address?.[0]?.street_3 ?? ""}
                                {data?.address?.[0]?.street_3 ? ", " : ""}
                                {data?.address?.[0]?.district ?? ""}
                                {data?.address?.[0]?.district ? ", " : ""}
                                {data?.address?.[0]?.city ?? ""}
                                {data?.address?.[0]?.city ? ", " : ""}
                                {data?.address?.[0]?.state ?? ""}
                                {data?.address?.[0]?.state ? ", " : ""}
                                {data?.address?.[0]?.country ?? ""}
                                {data?.address?.[0]?.country ? ", " : ""}
                                {data?.address?.[0]?.zipcode ?? ""}
                            </Typography>
                            :
                            <Typography>-</Typography>
                        }
                        {/* <Box height="16px" />
                        <Stack direction="row" >
                            <Typography className={classes.heading}>Latitude : </Typography>
                            <Typography className={classes.sub1} > {data?.address?.[0]?.lat}</Typography>
                        </Stack>
                        <Box height="12px" />
                        <Stack direction="row" >
                            <Typography className={classes.heading}>Longitude : </Typography>
                            <Typography className={classes.sub1} > {data?.address?.[0]?.long}</Typography>
                        </Stack> */}
                    </div>
                </Grid>
                {/* when map component is needed plz use below commented grid item */}

                {/* <Grid item xs={4}> */}
                <Grid item xs={6}>
                    <div className={classes.addressRoot}>
                        <Stack direction="row" >
                            <img src='/images/Group 7015.svg' alt='' />
                            <Typography className={classes.title}>&nbsp;{t("Contact & Other Information")}</Typography>
                        </Stack>
                        <Grid container>
                            <Grid item xs={6}>
                                {/* <Box marginTop="18px">
                                    <Typography className={classes.heading}>Title :</Typography>
                                    <Typography className={classes.sub}>{data?.title ?? "-"}&nbsp;</Typography>
                                </Box> */}
                                <Box marginTop="18px">
                                    <Typography className={classes.heading}>{t("Primary Telephone")} :</Typography>
                                    <Typography className={auth?.auth?.auth?.language === 'ar' ? classes.subNo : classes.sub}>{data?.phone_code ?? "-"}&nbsp;{data?.telephone ?? " "}</Typography>
                                </Box >
                                <Box marginTop="12px">
                                    <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                                    <Typography className={classes.sub}>{(data?.email_id !== "" && data?.email_id !== null) ? data?.email_id : "-"}</Typography>
                                </Box>

                            </Grid >
                            <Grid item xs={6}>
                                <Box marginTop="18px">
                                    <Typography className={classes.heading}>{t("Primary Contact")} :</Typography>
                                    <Typography className={auth?.auth?.auth?.language === 'ar' ? classes.subNo : classes.sub}>{(data?.mobile_no_country_code !== "" && data?.mobile_no_country_code !== null) ? data?.mobile_no_country_code : "-"}&nbsp;{data?.mobile_no}</Typography>
                                </Box>

                            </Grid>
                        </Grid >
                    </div >
                </Grid >
            </Grid >
        </Box >
    )
}