import { Stack, Typography } from '@mui/material'
import React from 'react'
import { SettlementAmountCardStyle } from './style'
import PaidIcon from '../utils/paidIcon'
import RecoveredIcon from '../utils/recoveredIcon'

export const SettlementAmountCard = ({
    title = "",
    amount = "",
    type = "",
    backgroundColor
}) => {
    const classes = SettlementAmountCardStyle({
        backgroundColor
    })
    return (
        <Stack>
            <Typography className={classes.title} noWrap>{title}</Typography>
            <Stack className={classes.box} direction={"row"} alignItems={"center"}>
                {type === "paid" ? <PaidIcon /> : <RecoveredIcon />}
                <Typography style={{marginInlineStart:"8px"}} className={classes.amount}>{amount}</Typography>
            </Stack>
        </Stack>
    )
}