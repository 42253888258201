import * as React from "react"
const SelectedProperty = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.5}
        x2={0.5}
        y1={-1.237}
        y2={0.677}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0d61a9" />
        <stop offset={0.482} stopColor="#0d60a7" />
        <stop offset={0.711} stopColor="#0f5c9f" />
        <stop offset={0.886} stopColor="#125592" />
        <stop offset={1} stopColor="#154e85" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={1.048}
        x2={0.433}
        y1={0.5}
        y2={0.5}
      />
      <linearGradient
        id="b"
        x1={0.5}
        x2={0.5}
        y1={-0.073}
        y2={0.823}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#9332bf" />
        <stop offset={1} stopColor="#912fbd" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.111}
        x2={0.91}
        y1={-0.074}
        y2={1.104}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#32bdef" />
        <stop offset={1} stopColor="#1ea2e4" />
      </linearGradient>
      <linearGradient
        xlinkHref="#b"
        id="e"
        x1={0.111}
        x2={0.406}
        y1={0.5}
        y2={0.5}
      />
      <linearGradient
        id="f"
        x1={-3.333}
        x2={-2.333}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={0.247}
        x2={1.47}
        y1={0.146}
        y2={1.855}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ac4ad5" />
        <stop offset={1} stopColor="#ae4cd5" />
      </linearGradient>
      <linearGradient
        xlinkHref="#c"
        id="h"
        x1={-0.036}
        x2={1.04}
        y1={-0.186}
        y2={1.214}
      />
      <linearGradient
        xlinkHref="#c"
        id="i"
        x1={0.234}
        x2={2.464}
        y1={-0.648}
        y2={9.559}
      />
    </defs>
    <g data-name="Group 115541">
      <g data-name="icons8-skyscrapers (2)">
        <path
          fill="url(#a)"
          d="M28 11h5.116a.465.465 0 0 1 .465.465v1.4H28Z"
          data-name="Path 100968"
          transform="translate(-14.302 -4.349)"
        />
        <path
          fill="url(#d)"
          d="M25 14h7.907a.465.465 0 0 1 .465.465v13.488H25Z"
          data-name="Path 100969"
          transform="translate(-12.698 -5.953)"
        />
        <path
          fill="url(#b)"
          d="M13.581 10.651H8V9.366a.93.93 0 0 1 .514-.832L13.581 6Z"
          data-name="Path 100970"
          transform="translate(-3.605 -1.674)"
        />
        <path
          fill="url(#c)"
          d="M36.86 16.93h.93V16h-.93Zm0 1.86h.93v-.93h-.93ZM35 16.93h.93V16H35Zm0 1.86h.93v-.93H35Zm1.86 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93ZM35 20.651h.93v-.93H35Zm0 1.86h.93v-.93H35Zm0 1.86h.93v-.93H35Zm1.86 1.86h.93V25.3h-.93Zm0 1.86h.93v-.93h-.93ZM35 26.233h.93V25.3H35Zm0 1.86h.93v-.93H35Z"
          data-name="Path 100971"
          transform="translate(-18.047 -7.023)"
        />
        <path
          fill="url(#e)"
          d="M5 24.744h8.372V8l-7.82 3.475a.93.93 0 0 0-.552.85Z"
          data-name="Path 100972"
          transform="translate(-2 -2.744)"
        />
        <path
          fill="url(#f)"
          d="M0 0h5.581v1.86H0z"
          data-name="Rectangle 58088"
          transform="rotate(180 6.849 4.256)"
        />
        <path
          fill="url(#g)"
          d="M7.93 17.86H7v.93h.93ZM9.791 16H8.86v.93h.93Zm0 1.86H8.86v.93h.93Zm-1.86 1.86H7v.93h.93Zm0 1.86H7v.93h.93Zm0 1.86H7v.93h.93Zm1.86-3.721H8.86v.93h.93Zm0 1.86H8.86v.93h.93Zm0 1.86H8.86v.93h.93ZM7.93 25.3H7v.93h.93Zm0 1.86H7v.93h.93Zm1.86-1.86h-.93v.93h.93Zm0 1.86h-.93v.93h.93Z"
          data-name="Path 100973"
          transform="translate(-3.07 -7.023)"
        />
        <path
          fill="url(#h)"
          d="M15.465 6h7.442a.465.465 0 0 1 .465.465v17.209H15V6.465A.465.465 0 0 1 15.465 6Z"
          data-name="Path 100974"
          transform="translate(-7.349 -1.674)"
        />
        <path
          fill="url(#i)"
          d="M19.465 1h3.721a.465.465 0 0 1 .465.465v2.326H19V1.465A.465.465 0 0 1 19.465 1Z"
          data-name="Path 100975"
          transform="translate(-9.488 1)"
        />
        <path
          fill="#50e6ff"
          d="M15.093 5.256v.93h-.93v-.93Zm-.93 2.791h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-5.581h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.862-5.58h.93v-.93h-.93Zm1.86-1.86h.93V2.93h-.93Zm-1.86 0h.93V2.93h-.93Zm0 3.721h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-5.581h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm5.581 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-3.716h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.861-3.72h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-3.72h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm5.581 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.861-1.861h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Z"
          data-name="Path 100976"
        />
        <path
          d="M16.023 6.651V22h.463V6.651Zm-8.837-.93V22h.465V5.488Z"
          data-name="Path 100977"
          opacity={0.05}
        />
        <path
          d="M16.019 6.651V22h.233V6.651ZM7.419 22h.233V5.488l-.233.116Z"
          data-name="Path 100978"
          opacity={0.07}
        />
      </g>
    </g>
  </svg>
)
export default SelectedProperty
