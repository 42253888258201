import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../../utils";

export const StepCardStyle = makeStyles((theme) => ({
    box: {
        background: theme.palette.info.light,
        borderRadius: 4,
        height: 371,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    disabledBox: {
        background: theme.palette.background.tertiary,
        borderRadius: 4,
        height: 371,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    step: {
        background: theme.palette.info.main,
        borderRadius: 4,
        height: 18,
        width: 46,
        padding: "2px 8px",
        fontFamily: Bold,
        textTransform: "capitalize"
    },
    disabledStep: {
        background: theme.palette.background.primary,
        borderRadius: 4,
        height: 18,
        width: 46,
        padding: "2px 8px",
        fontFamily: Bold,
        textTransform: "capitalize"
    },
    stepText: {
        color: "#fff",
        fontSize:"0.75rem",
        whiteSpace: "no-wrap"
    },
    content: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold
    },
    customBtn: {
        background: theme.palette.background.selected,
        borderRadius: 4,
        height: 32,
        padding: "0px 8px",
        textAlign: "center",
    },
    disabledCustomBtn: {
        background: theme.palette.border.secondary,
        borderRadius: 4,
        height: 32,
        padding: "0px 8px",
        textAlign: "center",
    },
    customBtnText: {
        color: theme.palette.primary.main,
        fontSize:"0.75rem",
        fontFamily: Bold,
        marginTop: 6,
        whiteSpace: "no-wrap",
        cursor: "pointer"
    },
    disabledCustomBtnText: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: Bold,
        marginTop: 6,
        whiteSpace: "no-wrap",
        cursor: "pointer"
    },
    step3Text: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    }
}))

export const PropertyUnitDetailsStyle = makeStyles((theme) => ({
    headCell: {
        padding: "4px",
        background: "#E8E5E5",
        border: `1px solid ${theme.palette.border.primary}`
    },
    headText: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    bodyCell: {
        padding: "4px",
        border: `1px solid ${theme.palette.border.primary}`,
        minWidth: "125px"
    },
    bodyText: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    addBillLine: {
        fontSize:"0.875rem",
        color: theme.palette.primary.main,
        fontFamily: Bold,
        padding: "4px",
        cursor: "pointer",
        whiteSpace: "noWrap"
    },
    billLineCell: {
        border: `1px solid ${theme.palette.border.primary}`
    },
    customText: {
        border: "none",
        '& .MuiFilledInput-input': {
            // height: "14px !important",
            padding: "0px !important",
            fontSize: 14,
            height: 35,
            fontFamily: Bold,
            border:'none'
        },
        "& .MuiInputBase-root":{
            border:"1px solid red !important"
        },
        "& .MuiOutlinedInput-root":{
            // border: `1px solid ${theme.palette.border.secondary}`
        }
    },
    textBoxTypography: {
        width: "125px",
        margin: "auto",
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    table: {
        maxHeight: "200px",
        overflow: "auto"
    }
}))

export const TotalTableStyle = makeStyles((theme) => ({
    normalCell: {
        padding: "4px",
        border: `1px solid ${theme.palette.border.primary}`
    },
    totalCell: {
        padding: "4px",
        background: "#E8E5E5",
        border: `1px solid ${theme.palette.border.primary}`
    },
    font10: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
        direction: "ltr"
    },
    font11: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold,
        direction: "ltr"
    },
    font10sec: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold,
        direction: "ltr"
    }
}))

export const PricingComponentCardStyle = makeStyles((theme) => ({
    title: {
        fontSize:"0.875rem",
        fontFamily: SemiBold
    },
    selectedTitle: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    },
    content: {
        fontSize:"0.75rem",
        fontFamily: SemiBold
    },
    selectedContent: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    },
}))