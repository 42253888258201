export const WaitingForApprovalPath = ["visitor_worker_image", "request_type", "date", "reference_id", "visitor_worker_name", "unit_id", "visitors_workers", "stay_type", "entry_type", "entry_gate", "start_date", "end_date"];

export const WaitingForApprovalHeading = (t) => [
    { title: t("Image"), field: "visitor_worker_image" },
    { title: t("Request Type"), field: "request_type" },
    { title: t("Date"), field: "date" },
    { title: t("Reference ID"), field: "reference_id" },
    { title: t("Visitor/Worker Name"), field: "visitor_worker_name" },
    { title: t("Unit ID"), field: "unit_id" },
    { title: t("Visitors/Workers"), field: "visitors_workers" },
    { title: t("Stay Type"), field: "stay_type" },
    { title: t("Entry Type"), field: "entry_type" },
    { title: t("Entry Gate"), field: "entry_gate" },
    { title: t("Start Date"), field: "start_date" },
    { title: t("End Date"), field: "end_date" },
];
export const WaitingForApprovalType = [
    { type: ["logo"], name: "visitor_worker_image" },
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "visitor_worker_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "visitors_workers" },
    { type: ["text"], name: "stay_type" },
    { type: ["text"], name: "entry_type" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "start_date" },
    { type: ["text"], name: "end_date" },
];


export const ApprovedPath = ["visitor_worker_image", "request_type", "date", "reference_id", "visitor_worker_name", "unit_id", "visitors_workers", "stay_type", "entry_type", "entry_gate", "start_date", "end_date"];

export const ApprovedHeading = (t) => [

    { title: t("Image"), field: "visitor_worker_image" },
    { title: t("Request Type"), field: "request_type" },
    { title: t("Date"), field: "date" },
    { title: t("Reference ID"), field: "reference_id" },
    { title: t("Visitor/Worker Name"), field: "visitor_worker_name" },
    { title: t("Unit ID"), field: "unit_id" },
    { title: t("Visitors/Workers"), field: "visitors_workers" },
    { title: t("Stay Type"), field: "stay_type" },
    { title: t("Entry Type"), field: "entry_type" },
    { title: t("Entry Gate"), field: "entry_gate" },
    { title: t("Start Date"), field: "start_date" },
    { title: t("End Date"), field: "end_date" },
];
export const ApprovedType = [
    { type: ["logo"], name: "visitor_worker_image" },
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "visitor_worker_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "visitors_workers" },
    { type: ["text"], name: "stay_type" },
    { type: ["text"], name: "entry_type" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "start_date" },
    { type: ["text"], name: "end_date" },
];

export const DeclinedPath = ["visitor_worker_image", "request_type", "date", "reference_id", "visitor_worker_name", "unit_id", "visitors_workers", "stay_type", "entry_type", "entry_gate", "start_date", "end_date"];

export const DeclinedHeading = (t) => [


    { title: t("Image"), field: "visitor_worker_image" },
    { title: t("Request Type"), field: "request_type" },
    { title: t("Date"), field: "date" },
    { title: t("Reference ID"), field: "reference_id" },
    { title: t("Visitor/Worker Name"), field: "visitor_worker_name" },
    { title: t("Unit ID"), field: "unit_id" },
    { title: t("Visitors/Workers"), field: "visitors_workers" },
    { title: t("Stay Type"), field: "stay_type" },
    { title: t("Entry Type"), field: "entry_type" },
    { title: t("Entry Gate"), field: "entry_gate" },
    { title: t("Start Date"), field: "start_date" },
    { title: t("End Date"), field: "end_date" },
];
export const DeclinedType = [
    { type: ["logo"], name: "visitor_worker_image" },
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "visitor_worker_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "visitors_workers" },
    { type: ["text"], name: "stay_type" },
    { type: ["text"], name: "entry_type" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "start_date" },
    { type: ["text"], name: "end_date" },
];

export const EntriesPath = ["visitor_worker_image", "request_type", "date", "reference_id", "visitor_worker_name", "unit_id", "stay_type", "entry_type", "entry_gate", "check_in", "check_out", "status"];

export const EntriesHeading = (t) => [
    { title: t("Image"), field: "visitor_worker_image" },
    { title: t("Request Type"), field: "request_type" },
    { title: t("Date"), field: "date" },
    { title: t("Reference ID"), field: "reference_id" },
    { title: t("Visitor/Worker Name"), field: "visitor_worker_name" },
    { title: t("Unit ID"), field: "unit_id" },
    { title: t("Stay Type"), field: "stay_type" },
    { title: t("Entry Type"), field: "entry_type" },
    { title: t("Entry Gate"), field: "entry_gate" },
    { title: t("Check In"), field: "check_in" },
    { title: t("Check Out"), field: "check_out" },
    { title: t("Status"), field: "status" },
];
export const EntriesType = [
    { type: ["logo"], name: "visitor_worker_image" },
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "date" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "visitor_worker_name" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "stay_type" },
    { type: ["text"], name: "entry_type" },
    { type: ["text"], name: "entry_gate" },
    { type: ["text"], name: "check_in" },
    { type: ["text"], name: "check_out" },
    { type: ["text"], name: "status" },
];