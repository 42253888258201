import React from "react"

export const ContractIcon = (props) =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <defs>
          <linearGradient
            id="a"
            x1={0.048}
            x2={0.509}
            y1={45.957}
            y2={46.418}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#3079d6" />
            <stop offset={1} stopColor="#297cd2" />
          </linearGradient>
          <linearGradient
            id="c"
            x2={1}
            y1={0.5}
            y2={0.5}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#fede00" />
            <stop offset={1} stopColor="#ffd000" />
          </linearGradient>
          <radialGradient
            id="b"
            cx={0.922}
            cy={0.709}
            r={0.706}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0.348} />
            <stop offset={0.936} stopOpacity={0.098} />
            <stop offset={1} stopOpacity={0} />
          </radialGradient>
        </defs>
        <g data-name="Group 118108">
          <g data-name="icons8-agreement-new (1)" transform="translate(-8 -5)">
            <path
              fill="#50e6ff"
              d="M25.744 11.14v13.953a1.116 1.116 0 0 1-1.116 1.116H10.116A1.116 1.116 0 0 1 9 25.093V6.116A1.116 1.116 0 0 1 10.116 5H19.6Z"
              data-name="Path 101916"
            />
            <path
              fill="url(#a)"
              d="M28 5v5.023a1.116 1.116 0 0 0 1.116 1.116h5.024Z"
              data-name="Path 101917"
              transform="translate(-8.395)"
            />
            <path
              fill="#057093"
              d="M11.791 21.187a.559.559 0 0 1-.282-1.04 8.29 8.29 0 0 0 1.2-.925 6.451 6.451 0 0 1-.921-3.56c0-2.661 1.033-4.52 2.512-4.52 1.442 0 1.953 1.707 1.953 3.169a7.444 7.444 0 0 1-1.989 4.937 1.546 1.546 0 0 0 .872.266 4.107 4.107 0 0 0 2.139-.606 4.265 4.265 0 0 1-.465-2.017c0-1.228.564-2.959 1.818-2.959.46 0 1.53.22 1.53 2.258a4.177 4.177 0 0 1-1.175 2.743 1.2 1.2 0 0 0 .235.022c.6 0 .9-.324 1.388-.9a2.922 2.922 0 0 1 2.343-1.335.558.558 0 0 1 0 1.116c-.665 0-1 .357-1.489.938a2.826 2.826 0 0 1-2.238 1.296 2.146 2.146 0 0 1-1.183-.325 5.193 5.193 0 0 1-2.9.884 2.675 2.675 0 0 1-1.668-.577 9.074 9.074 0 0 1-1.4 1.059.557.557 0 0 1-.28.076Zm2.511-8.93c-.674 0-1.4 1.368-1.4 3.4a5.833 5.833 0 0 0 .612 2.728 6.316 6.316 0 0 0 1.621-4.079c.005-.791-.223-2.05-.833-2.05Zm4.33 2.791c-.264 0-.7.892-.7 1.842a3.516 3.516 0 0 0 .23 1.3 3.045 3.045 0 0 0 .886-2c-.001-.344-.042-1.143-.416-1.143Z"
              data-name="Path 101918"
            />
            <path
              fill="url(#b)"
              d="M32.7 26a6.687 6.687 0 0 0-6.083 9.488h5.523a1.116 1.116 0 0 0 1.116-1.116v-8.344A6.876 6.876 0 0 0 32.7 26Z"
              data-name="Path 101919"
              opacity={0.3}
              transform="translate(-7.512 -9.279)"
            />
            <path
              fill="#fdb700"
              d="m25.186 17.837 1.3.729 1.491.018.761 1.281 1.281.761.018 1.491.729 1.3-.729 1.3-.018 1.491-1.281.761-.761 1.281-1.491.018-1.3.729-1.3-.729-1.491-.018-.761-1.281-1.281-.761-.018-1.491-.73-1.299.729-1.3.018-1.491 1.281-.761.761-1.281 1.491-.018Z"
              data-name="Path 101920"
            />
            <circle
              cx={3.907}
              cy={3.907}
              r={3.907}
              fill="url(#c)"
              data-name="Ellipse 131057"
              transform="translate(21.279 19.512)"
            />
          </g>
        </g>
      </svg>
    )
}