export const OverridePricing = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98381">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g data-name="Group 98365">
                    <g data-name="Group 98364">
                        <path
                            data-name="Path 94768"
                            d="M21.699 15.694a.44.44 0 0 1 0-.389l.559-1.144a1.3 1.3 0 0 0-.562-1.729l-1.125-.6a.44.44 0 0 1-.228-.314l-.22-1.255a1.3 1.3 0 0 0-1.471-1.061l-1.261.178a.44.44 0 0 1-.37-.12l-.915-.89a1.3 1.3 0 0 0-1.818 0l-.916.886a.44.44 0 0 1-.37.12l-1.261-.174a1.3 1.3 0 0 0-1.471 1.069l-.22 1.255a.44.44 0 0 1-.228.314l-1.125.6a1.3 1.3 0 0 0-.562 1.729l.559 1.133a.44.44 0 0 1 0 .389l-.559 1.144a1.3 1.3 0 0 0 .562 1.729l1.125.6a.44.44 0 0 1 .228.314l.22 1.255a1.3 1.3 0 0 0 1.284 1.082 1.338 1.338 0 0 0 .187-.013l1.261-.178a.439.439 0 0 1 .37.12l.916.886a1.3 1.3 0 0 0 1.818 0l.916-.886a.44.44 0 0 1 .37-.12l1.261.178a1.3 1.3 0 0 0 1.471-1.069l.22-1.255a.44.44 0 0 1 .228-.314l1.125-.6a1.3 1.3 0 0 0 .562-1.729Zm-8.233-4.087a1.586 1.586 0 1 1-1.586 1.586 1.588 1.588 0 0 1 1.586-1.586Zm-.819 7.054a.433.433 0 1 1-.612-.612l5.71-5.71a.433.433 0 1 1 .612.612Zm4.279.732a1.586 1.586 0 1 1 1.586-1.586 1.588 1.588 0 0 1-1.585 1.586Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
                <g data-name="Group 98367">
                    <g data-name="Group 98366">
                        <path
                            data-name="Path 94769"
                            d="M16.927 17.086a.721.721 0 1 0 .721.721.722.722 0 0 0-.721-.721Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
                <g data-name="Group 98369">
                    <g data-name="Group 98368">
                        <path
                            data-name="Path 94770"
                            d="M13.466 12.472a.721.721 0 1 0 .721.721.722.722 0 0 0-.721-.721Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
