import { AnnounsmentDetails, AnnounsmentPerson } from "../../assets";

export const tabTittle = (value, classes,isView=false) => [
    {
      label: "Property & Roles",
      imgSelect:
        (value === 1&&!isView)? (
          <img src="/images/correct_icon.svg" alt="icon" />
        ) : (
          <AnnounsmentPerson color={value === 0 ? "#5078E1" : "#98A0AC"} />
        ),
      className: value === 0 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 0 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 0 ? classes.subtabtitle1 : classes.subtabtitle,
      value:0,
      is_active:true
    },
    {
      label: isView?"View Form":"Create Form",
  
      imgSelect: (
        <AnnounsmentDetails color={value === 1 ? "#5078E1" : "#98A0AC"} />
      ),
      className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
      selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
      subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle,
      value:1,
      is_active:true
    },
    {
        label: "Replies",
    
        imgSelect: (
          <AnnounsmentDetails color={value === 2 ? "#5078E1" : "#98A0AC"} />
        ),
        className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
        selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
        subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle,
        value:2,
        is_active:isView?true:false
      },
  ];
