export const TeamsIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 107958">
            <path
                data-name="icons8-people (1)"
                d="M12 4.801a2.4 2.4 0 1 0 2.4 2.4 2.4 2.4 0 0 0-2.4-2.4Zm-5.2.8a2 2 0 1 0 2 2 2 2 0 0 0-2-2Zm10.4 0a2 2 0 1 0 2 2 2 2 0 0 0-2-2Zm-5.2 13.6a3.6 3.6 0 0 1-3.6-3.6v-3.8a1.4 1.4 0 0 1 1.4-1.4h4.4a1.4 1.4 0 0 1 1.4 1.4v3.8a3.6 3.6 0 0 1-3.6 3.6Zm-4.4-3.6v-3.8a2.191 2.191 0 0 1 .5-1.4H5.4a1.4 1.4 0 0 0-1.4 1.4v2.6a3.2 3.2 0 0 0 3.2 3.2 3.125 3.125 0 0 0 .824-.108h.008a4.367 4.367 0 0 1-.432-1.892Zm8.8 0v-3.8a2.191 2.191 0 0 0-.5-1.4h2.7a1.4 1.4 0 0 1 1.4 1.4v2.6a3.2 3.2 0 0 1-3.2 3.2 3.125 3.125 0 0 1-.824-.108h-.008a4.367 4.367 0 0 0 .432-1.892Z"
                fill={props?.fill ?? "#98a0ac"}
            />
        </g>
    </svg>
)