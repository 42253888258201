export const Companies = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98341">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g data-name="Group 98334">
                    <g data-name="Group 98333" fill={props?.fill ?? "#c1c5cb"}>
                        <path
                            data-name="Path 94720"
                            d="M21.75 10.875h-3.125v-.625A1.251 1.251 0 0 0 17.375 9h-3.75a1.251 1.251 0 0 0-1.25 1.25v.625H9.25A1.251 1.251 0 0 0 8 12.125V14a1.251 1.251 0 0 0 1.25 1.25h5v-.316a.312.312 0 0 1 .313-.313h1.875a.312.312 0 0 1 .313.313v.313h5A1.251 1.251 0 0 0 23 14v-1.875a1.251 1.251 0 0 0-1.25-1.25Zm-4.375 0h-3.75v-.625h3.75Z"
                        />
                        <path
                            data-name="Path 94721"
                            d="M22.827 15.588a.313.313 0 0 0-.328.031 1.234 1.234 0 0 1-.749.257h-5v.938a.312.312 0 0 1-.313.313h-1.875a.312.312 0 0 1-.313-.312v-.944h-5a1.234 1.234 0 0 1-.749-.257.312.312 0 0 0-.5.249v4.383a1.251 1.251 0 0 0 1.25 1.25h12.5a1.251 1.251 0 0 0 1.25-1.25v-4.375a.313.313 0 0 0-.173-.283Z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
