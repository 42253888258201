import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    imageViewer: {
        '& .styles-module_close__2I1sI': {
            color: 'white',
            position: 'absolute',
            top: '67px',
            right: '100px',
            fontSize: '40px',
            fontWeight: 'bold',
            opacity: 0.2,
            cursor: 'pointer',
        }
    }
}));