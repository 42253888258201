import makeStyles from "@mui/styles/makeStyles";
export const StatementOfAccountStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    margin: "20px",
    backgroundColor: theme.palette.background.tertiary1,
    border: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: theme.palette.borderRadius,
  },
  root2: {
    padding: "20px",
    margin: "20px",
    backgroundColor: theme.palette.background.tertiary1,
    border: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: theme.palette.borderRadius,
    height: `calc(100vh - 280px)`
  },
  rootView: {
    // padding: "20px",
    margin: "20px",
    backgroundColor: theme.palette.background.tertiary,
    border: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: theme.palette.borderRadius,
    overflow: "auto",
    height: `calc(100vh - 147px)`,
  },
  rootView2: {
    padding: "20px",
    backgroundColor: theme.palette.background.tertiary1,
    borderTopLeftRadius: theme.palette.borderRadius,
    borderTopRightRadius: theme.palette.borderRadius,
  },
  btn: {
    marginTop: "28px",
    height: "43px"
  },
  downBtn: {
    border: `1px solid ${theme?.palette.border.secondary}`,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
      border: `1px solid ${theme?.palette.border.secondary}`,
    }
  },
  doc: {
    margin: "20px",
    boxShadow: "0px 3px 16px #00000029",
    width: "558px",
    "& .pg-viewer-wrapper": {
      overflowY: "auto"
    }
  },
  docRoot: {
  }
}))