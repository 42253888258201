import React from "react";
import { withNavBars } from "../../HOCs";

import LogoApperances from "./logoApperence";

class LogoApperancesParent extends React.Component {
    render() {
        return <LogoApperances {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(LogoApperancesParent, props);
