import React from "react";
import { withNavBars } from "../../HOCs";
import InviteTriggerComp from "./inviteTrigger";

const InviteTriggerParent = () => {
    return <InviteTriggerComp />;
}
const props = {
    boxShadow: false
}
export default withNavBars(InviteTriggerParent, props);
