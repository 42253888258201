import { Box, Grid } from "@mui/material";
import { QuotationDetailCard } from "../components";
import { AggrementTabStyles } from "./styles";
import { NetWorkCallMethods, convertTimeUtcToZoneCalander } from '../../../utils';
import { BackdropContext } from '../../../contexts';
import React from 'react';
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import {  TemplateGenerator } from "../../../components";
import { NewLoader } from "../../../components/newLoader";


export const AgreementDocument = ({ details = {}, t = () => false }) => {
    const classes = AggrementTabStyles()
    const backdrop = React.useContext(BackdropContext)

    const QuotationCardHeader = {
        quotationNumber: details?.agreement_no,
        type: null,
        Date: convertTimeUtcToZoneCalander(details?.lease_start_date)
    }
    const [pdf, setPdf] = React.useState({
        bool: false,
        data: ""
    })
    const [template, setTemplate] = React.useState(false);

    const QuotationCardTable = []
    //download pdf
    const downloadPdf = () => {
        setTemplate(true)
    }
    const generate = async (value, is_open) => {
        setPdf({
            bool: false,
            data: ""
        })
        setTemplate(false)
        const payload = {
            id: details?.id,
            type_master_id: value?.value ?? undefined

        };
        await NetworkCall(
            `${config.api_url}/agreement/template`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setPdf({
                    bool: true,
                    data: res?.data
                })
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            })
            .catch((err) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            });
    }

    React.useEffect(() => {
        generate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details?.id])
    return (
        <Box >
            <Grid container >
                <Grid item xs={4} p={2}>
                    <QuotationDetailCard onClick={downloadPdf} value={QuotationCardTable} headerDet={QuotationCardHeader} />
                </Grid>
                <Grid item xs={8} className={classes.pdfSection}>
                <Box bgcolor={"#F5F7FA"}>
                                    {/* <IframeViwer pdf={pdf} 
                                    height={`calc(100vh -252px) !important`} 
                                    root={{
                                        height:`calc(100vh -252px) !important`
                                    } }/> */}
                                    <Box style={{ height: `calc(100vh - 252px)` }}>
                                        {
                                            pdf?.bool ?
                                                <iframe
                                                    src={`data:application/pdf;base64,${pdf?.data
                                                        }`}
                                                    alt="pdf"
                                                    width="100%"
                                                    style={{ height: `calc(100vh - 252px)` }}
                                                    title="quotation"

                                                />

                                                :
                                                <NewLoader minusHeight={'250px'}/>
                                        }
                                    </Box>
                                </Box>

                </Grid>
            </Grid>

            <TemplateGenerator t={t} name={t("Agreement Template")} open={template} onClose={() => setTemplate(false)} type={3} generate={generate} />

            {/* <AlertDialog
                header={details?.agreement_no}
                onClose={() => {
                    setOpenpdf(false)
                    setPdf({
                        bool: false,
                        data: ""
                    })
                }}
                open={openpdf}
                // render={renderButton()}
                component={
                    <Box p={1} bgcolor={"#F5F7FA"}>

                        <Box style={{ height: `calc(100vh - 100px)` }}>
                            {
                                pdf?.bool ?
                                    <iframe
                                        src={`data:application/pdf;base64,${pdf?.data
                                            }`}
                                        alt="pdf"
                                        width="100%"
                                        style={{ height: `calc(100vh - 152px)` }}
                                        title="agreement"
                                    />

                                    :
                                    <NewLoader />
                            }
                        </Box>
                    </Box>
                }
            /> */}

        </Box>
    )
}