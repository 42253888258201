import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import AppoinmentIcon from "../../../assets/appointment"
import EmailSlIcon from "../../../assets/emailsl"
import EventIcon from "../../../assets/event"
import FollowupIcon from "../../../assets/followup"
import MeetIcon from "../../../assets/meet"
import PhoneCallIcon from "../../../assets/phonecall"
import QuotationIcon from "../../../assets/quotation"
import SiteVisitsIcon from "../../../assets/sitevisits"
import { LocalStorageKeys, stringAvatar } from "../../../utils"
import { useStyles } from "./style"
import { AuthContext } from "../../../contexts"
import { Link } from "react-router-dom"
import { Routes } from "../../../router/routes"

export const SwitchColors = (val) => {
    // switch (val) {
    //     case "Phone call":
    //         return "#30AFCE1F"
    //     case "Follow up":
    //         return "#35A27D"
    //     case "Appointment":
    //         return "#E53468"
    //     case "Arrange Site Visit":
    //         return "#E29336"
    //     case "Quotation":
    //         return "#5078E11F"
    //     case "Meeting":
    //         return '#8887D4'
    //     default:
    //         return "#6EA9FA"
    // }
    switch (val) {
        case "Phone call":
            return "#30AFCE1F"
        case "Follow up":
            return "#3EA6831F"
        case "Appointment":
            return "#E534681F"
        case "Arrange Site Visit":
            return "#E293361F"
        case "Quotation":
            return "#5078E11F"
        case "Meeting":
            return '#DCDBF2'
        default:
            return "#5078E11F"
    }
}

export const ViewActivity = ({ data = {}, handleClose = () => false, t }) => {
    const classes = useStyles()
    const auth = React.useContext(AuthContext)
    const activityLogo = {
        "Phone call": <PhoneCallIcon />,
        "Follow up": <FollowupIcon />,
        "Event": <EventIcon />,
        "Send Email": <EmailSlIcon />,
        "Send Quotation": <QuotationIcon />,
        "Arrange Site Visit": <SiteVisitsIcon />,
        "Appointment": <AppoinmentIcon />,
        "Meeting": <MeetIcon />
    }

    const state = data?.lead === "proxy_lead" ? {
        main: {
            company: JSON.parse(localStorage.getItem(LocalStorageKeys?.selectedCompany)),
            id: data?.lead_id,
            selectedCompany: JSON.parse(localStorage.getItem(LocalStorageKeys?.selectedCompany))
        },
    }
        :
        {
            id: data?.lead_id,
            company: JSON.parse(localStorage.getItem(LocalStorageKeys?.selectedCompany))
        }

    return (
        <Box p={2}>
            <Box display={"flex"} justifyContent={"space-between"} sx={{ backgroundColor: SwitchColors(data?.activity_name) }} className={classes.viewbox} alignItems="center">
                <Stack direction="row" alignItems={"center"}>
                    {/* <img src="/images/activitycall.svg" alt="icon" /> */}
                    <Box>
                        {activityLogo?.[data?.activity_name]}
                    </Box>
                    <Typography className={classes.viewTitle}>{data?.activity_name ?? "-"}</Typography>
                    {/* <Typography className={classes.link} onClick={onClickRoutes}>{data?.no ?? "-"}</Typography> */}
                    <Link className={classes.link} to={{
                        pathname: data?.lead === "lead" ? Routes.leadsDetails : Routes.leadview,
                        state: state,
                    }}> {data?.no ?? "-"}</Link>
                </Stack>
                {/* <Typography className={classes.viewTitle}>20 days left</Typography> */}
            </Box>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                    <Box p={1.5} className={classes.borderBox}>
                        <Typography className={classes.headingact}>{t("Created By")}</Typography>
                        <Stack direction="row" spacing={1} alignItems={"center"} mt={1}>
                            <Avatar src={data?.image_url ?? ""} {...stringAvatar(data?.first_name)} />
                            <Stack direction="column" sx={{ marginInlineStart: "8px" }}>
                                <Typography className={classes.name}>{data?.first_name}</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography className={classes.mobileNo1}>{data?.phone ? data?.phone : data?.created_by_mobile_no ?? "-"}</Typography>
                                    <div className={classes.dot}></div>
                                    <Typography className={classes.mobileNo}>{data?.email ? data?.email : data?.email_id ?? "-"}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    {data?.lead_name?.length > 0 &&
                        <Box p={1.5} className={classes.borderBox}>
                            <Typography className={classes.headingact}>{t("Details")}</Typography>
                            <Stack direction="row" spacing={1} alignItems={"center"} mt={1}>
                                <Avatar  {...stringAvatar(data?.lead_name)} src={data?.image_url} />
                                <Stack direction="column" sx={{ marginInlineStart: "8px" }}>
                                    <Typography className={classes.name}>{data?.lead_name}</Typography>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography className={classes.mobileNo1}>{data?.lead_mobile_no}</Typography>
                                        <div className={classes.dot}></div>
                                        <Typography className={classes.mobileNo}>{data?.lead_email_id}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box >
                    }
                </Grid >
            </Grid >
            <Grid container spacing={2} mt={1}>
                <Grid item xs={3}>
                    <Typography className={classes.headingact}>{t("Category")}</Typography>
                    <Typography className={classes.mobileNo} mt={1}>{data?.activity_name}</Typography>

                </Grid >
                {/* <Grid item xs={3}>
                <Typography className={classes.headingact}>Purpose</Typography>
                <Typography className={classes.mobileNo} mt={1}> {data?.purpose ?? "-"} </Typography>

            </Grid> */}
                < Grid item xs={3} >
                    <Typography className={classes.headingact}>{t("Start Date")}</Typography>
                    <Typography className={classes.mobileNo} mt={1}> {moment(data?.date_time?.slice(0,10)).format("DD-MM-YYYY") ?? "-"} </Typography>

                </Grid >
                <Grid item xs={3}>
                    <Typography className={classes.headingact}>{t("Phone Number")}</Typography>
                    <Typography className={auth?.auth?.auth?.language === 'ar' ? classes.mobileNortl : classes.mobileNo1} mt={1}>{data?.phone ?? "-"}</Typography>

                </Grid >
                <Grid item xs={3}>
                    <Typography className={classes.headingact}>{t("Priority")}</Typography>
                    <Typography className={classes.mobileNo} mt={1} sx={{ textTransform: "capitalize" }}>{data?.priority}</Typography>

                </Grid>
            </Grid >
            <Box mt={2}><Divider /></Box>
            <Box mt={2}>
                <Typography className={classes.headingact}>{t("Note")}</Typography>
                <Typography className={classes.mobileNo} mt={1}>{data?.notes ?? "-"}</Typography>

            </Box >
            <Box mt={4} display="flex" justifyContent={"end"} alignItems="end">
                <Button variant="outlined" className={classes.closebtn} onClick={handleClose}>{t("Close")}</Button>
            </Box >
        </Box >
    )
}