import { Box, Stack, Typography } from "@mui/material"
import React from "react"
import EditIconSvg from "../../../assets/editIcon"
import { TemplateIcon } from "../../../assets/templateIcon"
import { Cards } from "./card"
import { useStyles } from "./style"
export const Preview = ({ t, selectedUnit = {}, selectedInspectionItem = {}, setStep = () => false, maintenanceRequest = [], updateState = () => false, setData = () => false, data = {} }) => {
    const classes = useStyles()
    React.useEffect(() => {
        setData({ ...data, disable: false })
        //eslint-disable-next-line
    }, [])
    return (
        <Box p={2} height={350}>
            <Typography className={classes.previewHdr}>{t("UNIT DETAILS")}</Typography>
            <Box mt={1}><Cards details={selectedUnit} edit={true} setStep={setStep} unit={true} updateState={updateState} /></Box>
            <Box mt={2}>
                <Typography className={classes.previewHdr}>{t("Inspection Details")}</Typography>
                <Box className={classes.unitblock} style={{ backgroundColor: "#FEEAEA80" }} mt={1}>
                    <Stack direction="row" flexWrap={"wrap"}>
                        <Box className={classes.templateIcon}><TemplateIcon /></Box>
                        <Box marginInlineStart={'8px'}>
                            <Typography className={classes.name}>{t("Unit Name")}</Typography>
                            <Typography className={classes.location}>{`${selectedInspectionItem?.length} ${t("Items selected for inspection")}`}</Typography>

                        </Box>
                    </Stack>
                    <Box className={classes.checkIcon} onClick={() => setStep(3)}><EditIconSvg /></Box>
                </Box>
            </Box>
        </Box>
    )
}