export const FacilityManagement = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 98440">
      <g fill={props?.fill ?? "#c1c5cb"}>
        <path
          data-name="Path 94934"
          d="M21.333 12.043a1.491 1.491 0 0 0 0-.587l.547-.426a.319.319 0 0 0 .079-.405l-.491-.874a.3.3 0 0 0-.381-.132l-.63.273a2.244 2.244 0 0 0-.5-.3l-.084-.688a.307.307 0 0 0-.3-.274h-.982a.307.307 0 0 0-.3.274l-.084.688a2.253 2.253 0 0 0-.5.3l-.63-.273a.3.3 0 0 0-.381.132l-.487.874a.319.319 0 0 0 .08.406l.547.426a1.491 1.491 0 0 0 0 .587l-.547.426a.319.319 0 0 0-.08.406l.491.874a.3.3 0 0 0 .381.132l.63-.274a2.244 2.244 0 0 0 .5.3l.084.688a.307.307 0 0 0 .3.274h.982a.307.307 0 0 0 .3-.274l.084-.688a2.253 2.253 0 0 0 .5-.3l.63.273a.3.3 0 0 0 .381-.132l.491-.875a.319.319 0 0 0-.08-.406ZM19.084 13a1.251 1.251 0 1 1 1.215-1.25A1.232 1.232 0 0 1 19.084 13Z"
        />
        <path
          data-name="Path 94935"
          d="M18.932 21.75h-1.367v-1.343a1.249 1.249 0 0 0 .759-1.157 1.215 1.215 0 1 0-2.429 0 1.249 1.249 0 0 0 .759 1.157v1.343h-1.366v-5.019a.608.608 0 1 0-1.215 0v5.019h-1.214v-2.031a.462.462 0 0 0-.459-.469h-1.515a.462.462 0 0 0-.455.469v2.031H9.215v-9.687h4.251a.617.617 0 0 0 .607-.625v-1.094A1.08 1.08 0 0 0 13.01 9.25h-.759v-.625a.608.608 0 1 0-1.215 0v.625h-.759a1.08 1.08 0 0 0-1.062 1.094v.469A1.234 1.234 0 0 0 8 12.063v10.312a.617.617 0 0 0 .607.625h10.325a.625.625 0 0 0 0-1.25Z"
        />
        <path
          data-name="Path 94936"
          d="M12.403 16.437h-1.519a.469.469 0 0 0 0 .938h1.519a.469.469 0 0 0 0-.937Z"
        />
        <path
          data-name="Path 94937"
          d="M12.403 12.687h-1.519a.469.469 0 0 0 0 .938h1.519a.469.469 0 0 0 0-.937Z"
        />
        <path
          data-name="Path 94938"
          d="M12.403 14.562h-1.519a.469.469 0 0 0 0 .938h1.519a.469.469 0 0 0 0-.937Z"
        />
      </g>
      <path data-name="Rectangle 52136" fill="none" d="M0 0h30v30H0z" />
    </g>
  </svg>
)
