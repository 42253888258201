
import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { AlertDialog, IframeViwer, SearchFilter, TableWithPagination, TemplateGenerator } from "../../../components";
import { getInvoiceHeading, invoicedataType, invoicePath } from "../../../utils/receipt";
import { ReceiptStyles } from "../styles";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { AlertContext } from "../../../contexts";

const InvoiceTable = ({ t, id = "", list = [], currency = "", getInvoice = () => false, setList = () => false, total = 0, availableTotal = 0, updateState = () => false, view = false, datas = {}, setData = () => false, count = 0 }) => {
    const classes = ReceiptStyles();
    const invoiceHeading = getInvoiceHeading(t, list?.filter((val) => val?.is_active)?.length);
    const [search, setSearch] = React.useState("");
    const [limit, setLimit] = React.useState(10)
    const [template, setTemplate] = React.useState(false);
    const alert = React.useContext(AlertContext);
    const [page, setPage] = React.useState(1);

    const [pdf, setPdf] = React.useState({
        bool: false,
        data: ""
    })

    const [open, setOpen] = React.useState({
        bool: false,
        data: {}
    })
    //initial load
    React.useEffect(() => {
        if (id) {
            getInvoice(0, "", 10)
        }
        // eslint-disable-next-line
    }, [id])
    //if create
    const create = (data, check) => {
        const edited = list.map((val) =>
            val?.id === data?.main?.id ? {
                ...val, is_active: check
            } : val
        );
        setList(edited);
        let total = edited?.filter((i) => i?.is_active)?.map(val => parseFloat(val?.total_amount_value ?? 0)).reduce((a, b) => a + b, 0);
        updateState("amountTopay", total)
    }

    //if view
    const views = (data, check) => {

        const edited = list.map((val) => val?.id === data?.main?.id ? { ...val, is_active: check, } : val);
        let total = parseFloat(datas?.available_balance).toFixed(2);
        let tempArray = []
        for (let item of edited) {

            if (item?.is_active) {

                let amount = parseFloat(item?.total_amount_value)?.toFixed(2) - parseFloat(total)?.toFixed(2) <= 0 ? 0 : parseFloat(item?.total_amount_value)?.toFixed(2) - parseFloat(total)?.toFixed(2)


                tempArray.push({
                    ...item,
                    invoice_due_amount: amount,
                    result: parseFloat(item?.total_amount_value)?.toFixed(2) - parseFloat(amount)?.toFixed(2),
                })

                total = total - item?.total_amount_value

            } else {
                tempArray.push({ ...item });
            }
        }


        if (calculation(list)?.avalBalance > 0) {
            setList(tempArray);
        } else {
            if (check === false) {
                setList(edited)
            }
        }
    }
    //handle icon
    const handleIcon = (type, data, check) => {
        if (type === "checkBox") {
            if (view) {
                views(data, check)
            } else {
                create(data, check)
            }
        }
    }
    //handleOpen
    const handleOpen = (data = {}) => {
        setTemplate(true)
        setOpen({
            ...open,
            data: data,
        })
    }
    //handleIcon
    const handleLink = (data) => {
        handleOpen(data?.main)
    }
    //handle search
    const handleSearch = (value) => {
        setSearch(value);
        getInvoice(0, value, limit)
    }
    //calculate available  
    const calculation = (list) => {

        let ourBalance = datas?.available_balance ? datas?.available_balance : 0;

        let activeAmt = list?.reduce((total, item) => {
            if (item.is_active) {
                return total = total + (item?.total_amount_value ? item?.total_amount_value : 0)
            }
            return total
        }, 0)


        let avalBalance = ourBalance - activeAmt; //-200
        let totalAmt = activeAmt > ourBalance ? ourBalance : activeAmt

        return {
            avalBalance: avalBalance < 0 ? 0 : avalBalance,
            totalAmt,
            as: avalBalance
        }
    }

    const generate = (value) => {
        setPdf({
            bool: false,
            data: ""
        })
        setOpen({
            ...open,
            bool: !open?.bool
        })
        setTemplate(false)
        const variables = {
            "invoice_id": open?.data?.id,
            type_master_id: value?.value ?? undefined
        }
        // console.log(variables) 
        NetworkCall(
            `${config.api_url}/invoice/template`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
        ).then((res) => {
            setPdf({
                data: res?.data,
                bool: true,

            })

        }).catch((err) => {
            setPdf({
                pdf: "",
                bool: false,
            })
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some thing went wrong"),
            });
        })

    }

    // pagination function
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getInvoice(offset, search,limit);
    };
    // pagination limit change function
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getInvoice(0, search, value);
    };

    return (
        <Box >
            <Grid container alignItems="center" className={classes.invoiceRoot}>
                <Grid item xs={8}>
                    <Typography className={classes.title}>{t("invoices")} ({count})</Typography>
                </Grid>
                <Grid item xs={4}>
                    <SearchFilter value={search} handleChange={(value) => handleSearch(value)} placeholder={t("search")} />
                </Grid>
            </Grid>
            <Box p={1}>
                <TableWithPagination
                    heading={invoiceHeading}
                    rows={list}
                    totalRowsCount={count}
                    path={invoicePath}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    tableType="no-side"
                    msg={t("All Invoices Are Paid")}
                    handleLink={handleLink}
                    view={true}
                    handleIcon={handleIcon}
                    dataType={invoicedataType}
                    height={"auto"}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    showpagination={count > 10 ? true : false}
                    page={page}
                    limit={limit}
                />
            </Box>
            <Stack direction="row" className={classes.totalRoot} justifyContent={view ? "space-between" : "flex-end"}>
                {
                    view &&
                    <Box>
                        <Typography className={classes.available}>{t("availableAmount")}</Typography>
                        <Typography className={classes.totalAmountValue}>{currency ?? list?.[0]?.symbol} {calculation(list)?.avalBalance ?? 0}</Typography>
                    </Box>
                }

                {
                    view ?
                        <Box>
                            <Typography textAlign="right" className={classes.totalAmount}>{t("totalAmount")}</Typography>
                            <Typography textAlign="right" className={classes.totalAmountValue}>{currency ?? list?.[0]?.symbol} {calculation(list)?.totalAmt ?? 0}</Typography>
                        </Box>
                        :
                        <Box>
                            <Typography textAlign="right" className={classes.totalAmount}>{t("totalAmount")}</Typography>
                            <Typography textAlign="right" className={classes.totalAmountValue}>{currency ?? list?.[0]?.symbol} {total}</Typography>
                        </Box>
                }

            </Stack>
            <AlertDialog
                header={open?.data?.invoice_id}
                onClose={() => {
                    setOpen({
                        ...open,
                        bool: !open?.bool
                    })
                }}
                open={open?.bool}
                render={false}
                component={
                    <Box>
                        <IframeViwer pdf={pdf} />
                    </Box>
                }
            />


            <TemplateGenerator t={t} name={t("Invoice Template")} btnText={t("Generate")} open={template} onClose={() => setTemplate(false)} type={2} generate={generate} />
        </Box>
    )
}
export default withNamespaces("receipt")(InvoiceTable); 