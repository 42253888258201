import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { CalendarChangeBtnStyle } from './style';
import { format } from 'date-fns';

export const CalendarChangeBtn = ({
    monthnext = () => false,
    monthprev = () => false,
    selectedMonth = new Date(),
}) => {
    const classes = CalendarChangeBtnStyle()
    return (
        <>
            <Stack direction={"row"} alignItems={"center"} columnGap={"33px"}>
                <Box className={classes.arrowBox} onClick={monthprev}>
                    <KeyboardArrowLeftIcon className={classes.arrowColor} />
                </Box>
                <Typography className={classes.dateText}>{format(selectedMonth, "MMMM yyyy")}</Typography>
                <Box className={classes.arrowBox} onClick={monthnext}>
                    <KeyboardArrowRightIcon className={classes.arrowColor} />
                </Box>
            </Stack>
        </>
    )
}