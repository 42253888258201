import React from "react";
import { Box, Typography } from "@mui/material";
import { LeadStylesParent } from "./style";
export const Card = (props) => {
  const classes = LeadStylesParent(props);
  const { t = () => false } = props;
  return (
    <Box
      className={
        props?.type === props?.data?.name ? classes.cardParent1 : classes.cardParent
      }
      onClick={() => props?.onClick(props?.data)}
    >
      <Box>
        <img src={props?.data?.image_url} alt={props?.data?.name} />
      </Box>
      <Box height={"10px"} />
      <Box>
        <Typography className={classes.type}>{t(props?.data?.name)}</Typography>
      </Box>
    </Box>
  );
};
