import * as React from "react"

const AcceptIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 110232" transform="translate(-344.84 -312.088)">
            <rect
                data-name="Rectangle 55272"
                width={40}
                height={40}
                rx={4}
                transform="translate(344.84 312.088)"
                fill="#eef9ee"
            />
            <g data-name="Group 104828">
                <path
                    data-name="Rectangle 55282"
                    fill="none"
                    d="M352.84 320.088h24v24h-24z"
                />
                <path
                    data-name="icons8-instagram-check-mark (2)"
                    d="M372.863 332.141a.1.1 0 0 1 0-.108l.741-1.158a1.484 1.484 0 0 0-.568-2.121l-1.221-.631a.1.1 0 0 1-.054-.094l.064-1.374a1.484 1.484 0 0 0-1.552-1.552l-1.375.064a.123.123 0 0 1-.094-.054l-.631-1.221a1.485 1.485 0 0 0-2.121-.569l-1.158.742a.1.1 0 0 1-.108 0l-1.158-.742a1.484 1.484 0 0 0-2.121.568l-.632 1.222a.1.1 0 0 1-.094.054l-1.374-.064a1.484 1.484 0 0 0-1.553 1.552l.064 1.374a.1.1 0 0 1-.054.094l-1.226.631a1.485 1.485 0 0 0-.569 2.121l.742 1.159a.1.1 0 0 1 0 .108l-.741 1.158a1.484 1.484 0 0 0 .568 2.12l1.221.631a.1.1 0 0 1 .054.094l-.064 1.375a1.49 1.49 0 0 0 .433 1.119 1.466 1.466 0 0 0 1.119.433l1.375-.064a.114.114 0 0 1 .094.054l.636 1.224a1.485 1.485 0 0 0 2.121.568l1.159-.742a.1.1 0 0 1 .108 0l1.158.741a1.484 1.484 0 0 0 2.121-.568l.631-1.221a.113.113 0 0 1 .094-.054l1.374.064a1.484 1.484 0 0 0 1.552-1.552l-.064-1.375a.1.1 0 0 1 .054-.094l1.221-.631a1.485 1.485 0 0 0 .569-2.121Zm-4.046-1.8a21.847 21.847 0 0 0-4.473 4.475.692.692 0 0 1-.553.275.692.692 0 0 1-.553-.279 8.879 8.879 0 0 0-2.136-2.144.692.692 0 0 1 .767-1.153 9.232 9.232 0 0 1 1.922 1.765 22.805 22.805 0 0 1 4.209-4.055.693.693 0 1 1 .819 1.117Z"
                    fill="#5ac782"
                />
            </g>
        </g>
    </svg>
)

export default AcceptIcon
