import React from 'react'
import { ChooseCardStyle } from './style';
import { SelectBox } from '../../select';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import { stringAvatar } from '../../../utils';
import { DeleteIcon } from '../../../assets';

export const ChooseCardWithSelect = ({
    menuOptionHeight = "200px",
    menuPlacement = "auto",
    placeholder = "",
    loadOptions = () => false,
    isReadOnly = false,
    onchange = () => false,
    border = "",
    key,
    value = "",
    header,
    isRequired = false,
    selectBoxHeader = "",
    onDelete = () => false,
    secPlaceholder = "",
    secLoadOptions = () => false,
    secIsReadOnly = false,
    secOnchange = () => false,
    secValue = "",
    isError = false,
    errorMsg = "",
    isSecError = false,
    errorSecMsg = ""
}) => {
    const classes = ChooseCardStyle({ border })

    const customStyles = {
        control: base => ({
            ...base,
            height: 200,
            minHeight: 200
        })
    };

    return (
        <>
            {header &&
                <Typography className={classes.header} mb={"10px"}>
                    {header}{isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                        *
                    </Typography>}
                </Typography>}
            <SelectBox
                // isRequired={true}
                key={key}
                label={''}
                menuOptionHeight={menuOptionHeight}
                menuPlacement={menuPlacement}
                placeholder={placeholder}
                borderRadius={"8px"}
                loadOptions={loadOptions}
                isPaginate={true}
                debounceTimeout={800}
                styles={customStyles}
                isReadOnly={isReadOnly}
                noSearch={false}
                onChange={(value) => {
                    onchange(value)
                }}
                value={value}
                selectHeight="40px"
                isError={isError}
                errorMessage={errorMsg}
                customOptionComponent={(props) => {
                    return (
                        <Box marginTop="8px" className={classes.selectParent} style={{ border: (props?.isSelected) ? "1px solid #5078E1" : "1px solid #E4E8EE", backgroundColor: (props?.isSelected) ? "#F1F7FF" : "#fff" }}>
                            <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={'space-between'}>
                                <Stack direction={"row"} p={0.5} alignItems={"center"}>
                                    <Avatar className={classes.optionAvatar} src={props?.data?.url ?? props?.data?.property_assets?.[0]?.url ?? props?.data?.unit_assets?.[0]?.url} {...stringAvatar(props?.data?.label)}></Avatar>
                                    <Stack sx={{ marginInlineStart: "16px" }}>
                                        <Typography className={classes.optionHeader}>{props?.data?.label ?? ""}</Typography>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <Typography className={classes.optionSubTitle} noWrap>{props?.data?.mobile_no_country_code ?? ''} {props?.data?.mobileNo ?? props?.data?.mobile_no}</Typography>
                                            {props?.data?.email_id && <Typography className={classes.optionSubTitle} noWrap >, {props?.data?.email_id}</Typography>}
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box>
                    )
                }}
            />

            {
                value &&
                <Box className={classes.selectedValueBox} mt={1}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={1}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Avatar className={classes.valueBoxAvatar} src={value?.url} {...stringAvatar(value?.label ?? "")}></Avatar>
                            <Stack sx={{ marginInlineStart: "8px" }}>
                                <Typography className={classes.valueBoxTitle}>{value?.label ?? "-"}</Typography>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography sx={{ marginInlineEnd: "6px" }} className={classes.valueBoxSubTitle}>
                                        {value?.mobile_no_country_code} {value?.mobile_no}</Typography>
                                    <Box className={classes.dot}></Box>
                                    <Typography sx={{ marginInlineStart: "6px" }} className={classes.valueBoxSubTitle}>
                                        {value?.email_id}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Box>
                            {!secIsReadOnly && <Box onClick={onDelete}>
                                <DeleteIcon />
                            </Box>}
                        </Box>
                    </Stack>
                    <Divider></Divider>
                    <Box p={1}>
                        <Typography className={classes.valueBoxSecondTitle} mb={1}>{selectBoxHeader}</Typography>
                        <SelectBox
                            label=''
                            key={JSON.stringify(value)}
                            menuOptionHeight={menuOptionHeight}
                            menuPlacement={menuPlacement}
                            placeholder={secPlaceholder}
                            borderRadius={"8px"}
                            loadOptions={secLoadOptions}
                            isPaginate={true}
                            debounceTimeout={800}
                            styles={customStyles}
                            isReadOnly={secIsReadOnly}
                            noSearch={false}
                            onChange={(value) => {
                                secOnchange(value)
                            }}
                            value={secValue}
                            color="#F5F7FA"
                            selectHeight={"40px"}
                            isError={isSecError}
                            errorMessage={errorSecMsg} />
                    </Box>
                </Box>
            }
        </>
    )
}