import makeStyles from "@mui/styles/makeStyles";
import { config } from "../../config";
import { Bold, login_background_color, login_tag_includes } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    tag: {
        position: "absolute",
        right: ({ right }) => right ?? 16,
        top: ({ top }) => top ?? 16,
        backgroundColor: `${login_background_color?.[config?.app_env]?.tag}`,
        padding: "4px 12px",
        borderRadius: theme.palette.borderRadius_2,
        display: (!Boolean((config?.app_env && login_tag_includes.includes(config?.app_env)))) && "none",
    },
    tag_text: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: "white",
    },
}));