import * as React from "react"

const QuotationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <clipPath id="c">
        <path d="M5.332 0h21.336A5.331 5.331 0 0 1 32 5.332v21.336A5.331 5.331 0 0 1 26.668 32H5.332A5.331 5.331 0 0 1 0 26.668V5.332A5.331 5.331 0 0 1 5.332 0Zm0 0" />
      </clipPath>
      <clipPath id="b">
        <path d="M0 0h32v32H0z" />
      </clipPath>
      <filter
        id="a"
        filterUnits="objectBoundingBox"
        x="0%"
        y="0%"
        width="100%"
        height="100%"
      >
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
      </filter>
      <mask id="e">
        <g filter="url(#a)">
          <path
            style={{
              fill: "#000",
              fillOpacity: 0.65098,
              stroke: "none",
            }}
            d="M0 0h32v32H0z"
          />
        </g>
      </mask>
      <g id="d" clipPath="url(#b)">
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.501.5 16.058.5l-.002 15.557L.5 16.056Zm0 0"
          transform="scale(1.33333) rotate(15 48.393 -79.927)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.501.5h12.97l-.001 12.97H.5Zm0 0"
          transform="scale(1.33333) rotate(15 42.835 -75.662)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.501.5 16.058.499l-.002 15.56L.5 16.057Zm0 0"
          transform="scale(1.33333) rotate(15 -34.16 -16.582)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.501.5 13.47.5V13.47H.5Zm0 0"
          transform="matrix(1.2879 .3451 -.3451 1.2879 -6.055 13.147)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="m.499.501 9.866-.002.002 9.866-9.866.002Zm0 0"
          transform="matrix(1.2879 .3451 -.3451 1.2879 -4.59 15.683)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.501h6.76l.001 6.76H.501Zm0 0"
          transform="scale(1.33333) rotate(15 -53.063 -2.077)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="m.5.498 3.656.004v3.654H.5Zm0 0"
          transform="scale(1.33333) rotate(15 -59.734 3.042)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5h1.588l-.002 1.586H.499Zm0 0"
          transform="scale(1.33333) rotate(15 -64.18 6.453)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5 16.056.499l.002 15.559-15.557-.002Zm0 0"
          transform="scale(1.33333) rotate(15 34.657 -13.174)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="m.5.5 12.97.002-.001 12.969H.499Zm0 0"
          transform="scale(1.33333) rotate(15 29.099 -8.91)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="m.5.5 9.867-.002-.001 9.867-9.866.002Zm0 0"
          transform="matrix(1.2879 .3451 -.3451 1.2879 -.288 -7.91)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.499.501 7.262.5 7.26 7.26.5 7.263Zm0 0"
          transform="scale(1.33333) rotate(15 15.754 1.33)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.501h3.658v3.655H.5Zm0 0"
          transform="scale(1.33333) rotate(15 9.084 6.45)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5h1.587v1.586H.501Zm0 0"
          transform="scale(1.33333) rotate(15 4.637 9.86)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.499.5 16.059.5l-.002 15.56L.5 16.056Zm0 0"
          transform="matrix(1.2879 .3451 -.3451 1.2879 -11.9 34.418)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5h15.557v15.556H.502Zm0 0"
          transform="scale(1.33333) rotate(15 -47.896 50.17)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.499.501 13.47.5V13.47H.501Zm0 0"
          transform="matrix(1.2879 .3451 -.3451 1.2879 -10.68 36.53)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.499h12.969v12.973L.499 13.47Zm0 0"
          transform="matrix(1.2879 .3451 -.3451 1.2879 16.357 20.92)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5h9.867l-.001 9.866-9.867-.001Zm0 0"
          transform="matrix(1.2879 .3451 -.3451 1.2879 17.82 23.455)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.498.499 7.262.5 7.26 7.262H.5Zm0 0"
          transform="matrix(1.2879 .3451 -.3451 1.2879 19.284 25.99)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5 4.157.498v3.657H.5Zm0 0"
          transform="scale(1.33333) rotate(15 -73.47 69.794)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5h1.587v1.586H.501Zm0 0"
          transform="scale(1.33333) rotate(15 -77.916 73.206)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.501 16.057.5l-.002 15.557H.5Zm0 0"
          transform="scale(1.33333) rotate(15 20.445 54.581)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.501.499h12.97l-.001 12.97L.5 13.47Zm0 0"
          transform="scale(1.33333) rotate(15 14.887 58.846)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.499.499 10.366.5l.001 9.866-9.866-.001Zm0 0"
          transform="scale(1.33333) rotate(15 8.213 63.967)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5 7.261.5l.001 6.76L.501 7.26Zm0 0"
          transform="scale(1.33333) rotate(15 1.542 69.086)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.501.5h3.655v3.657H.5Zm0 0"
          transform="scale(1.33333) rotate(15 -5.129 74.204)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.501h1.586v1.586H.5Zm0 0"
          transform="matrix(1.2879 .3451 -.3451 1.2879 26.35 6.83)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5h15.557l.001 15.556-15.559.002Zm0 0"
          transform="scale(1.33333) rotate(15 89.262 57.988)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.499h12.97l-.001 12.97L.5 13.471Zm0 0"
          transform="scale(1.33333) rotate(15 83.704 62.254)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.501.501 10.367.5v9.867l-9.868-.001Zm0 0"
          transform="scale(1.33333) rotate(15 77.03 67.374)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.498 16.057.5l.001 15.557L.5 16.058Zm0 0"
          transform="scale(1.33333) rotate(15 6.71 121.334)"
        />
        <path
          style={{
            fill: "none",
            strokeWidth: 1,
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            stroke: "#6288eb",
            strokeOpacity: 1,
            strokeMiterlimit: 4,
          }}
          d="M.5.5h12.97l-.001 12.97-12.97-.001Zm0 0"
          transform="scale(1.33333) rotate(15 1.15 125.599)"
        />
      </g>
    </defs>
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#5078e1",
        fillOpacity: 1,
      }}
      d="M5.332 0h21.336A5.331 5.331 0 0 1 32 5.332v21.336A5.331 5.331 0 0 1 26.668 32H5.332A5.331 5.331 0 0 1 0 26.668V5.332A5.331 5.331 0 0 1 5.332 0Zm0 0"
    />
    <g clipPath="url(#c)">
      <use xlinkHref="#d" mask="url(#e)" />
    </g>
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#fff",
        fillOpacity: 1,
      }}
      d="M13.012 9.336a.52.52 0 0 0-.188.039l-1.476.586-1.309-.582a.5.5 0 0 0-.707.457v10.367a1.843 1.843 0 0 0 1.84 1.84h6.848a1.923 1.923 0 0 1-.223-.48 1.17 1.17 0 0 1 .543-1.372 2.306 2.306 0 0 1-.512-.82H17.2a.502.502 0 0 1 0-1.004h.52c.043-.398.191-.773.43-1.094a.482.482 0 0 1-.282.09h-.664a.505.505 0 0 1 0-1.008h.664c.278 0 .504.227.504.504 0 .07-.02.141-.047.207.164-.18.36-.328.57-.445a1.175 1.175 0 0 1 1.149-.933V9.835a.495.495 0 0 0-.23-.422.491.491 0 0 0-.481-.035l-1.309.578-1.476-.586a.48.48 0 0 0-.375 0l-1.485.59-1.488-.59a.5.5 0 0 0-.187-.035Zm-1.504 4.012h6.36a.502.502 0 0 1 0 1.004h-6.36a.502.502 0 0 1 0-1.004Zm0 3.011h3.68a.502.502 0 0 1 0 1.004h-3.68a.502.502 0 0 1 0-1.004Zm8.535 0a.502.502 0 0 0-.504.5v.22a1.592 1.592 0 0 0 .422 3.129l.492-.001a.592.592 0 0 1 0 1.18h-.617a.472.472 0 0 1-.45-.332.503.503 0 0 0-.628-.328.504.504 0 0 0-.328.628c.16.508.582.891 1.105.997v.187a.502.502 0 1 0 1.004 0v-.164a1.589 1.589 0 0 0 1.531-1.633 1.59 1.59 0 0 0-1.62-1.547h-.493a.592.592 0 0 1-.008-1.183h.274c.207 0 .39.136.453.332.078.265.36.414.625.336a.503.503 0 0 0 .332-.63 1.458 1.458 0 0 0-1.094-1.003v-.192a.504.504 0 0 0-.504-.496Zm-8.535 2.008h3.68a.502.502 0 0 1 0 1.004h-3.68a.502.502 0 0 1 0-1.004Zm0 0"
    />
  </svg>
)

export default QuotationIcon
