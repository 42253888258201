export const TownshipView = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 102653">
            <g data-name="township view" fill="#c1c5cb">
                <path
                    data-name="Path 97598"
                    d="m17.826 107.767-1.421-.517a5.05 5.05 0 0 0-.66-.19v-2.1a.264.264 0 0 0-.053-.158l-.845-1.127a.264.264 0 0 0-.211-.105h-1.691a.264.264 0 0 0-.211.105l-.766 1.022h-.732v-.3a.264.264 0 1 0-.527 0v.3h-.3a.264.264 0 0 0-.236.146l-.564 1.127a.263.263 0 0 0-.028.118v2.455l-1.164.445v-1.778a.264.264 0 0 0-.028-.118l-.564-1.127a.264.264 0 0 0-.236-.146h-.58v-.3a.264.264 0 1 0-.527 0v.3h-.419l-.491-.982a.264.264 0 0 0-.236-.146h-.582v-.3a.264.264 0 1 0-.527 0v.3h-.791l1.417-1.417.578.144a1.392 1.392 0 0 0 .959-.105l.591-.3a.863.863 0 0 1 1 .162l.084.085-.941.941a.264.264 0 1 0 .373.373L9.071 103l.836.279a1.673 1.673 0 0 0 1.533-.249l.967-.725a1.139 1.139 0 0 1 1.2-.108l.446.223a1.674 1.674 0 0 0 1.154.127l.477-.119 1.866 1.872a.264.264 0 0 0 .373-.373l-4.107-4.107a1.629 1.629 0 0 0-2.3 0l-1.666 1.666-.821-.821a1.629 1.629 0 0 0-2.3 0l-4.03 4.03h-.745a.264.264 0 0 0-.236.146l-.564 1.127a.262.262 0 0 0-.028.118v2.091l-.74-.392a.264.264 0 0 0-.247.466l2.869 1.519a5.054 5.054 0 0 0 4.17.254l5.882-2.249a4.528 4.528 0 0 1 3.164-.026l1.421.517a.264.264 0 0 0 .18-.5Zm-5.942-7.578a1.1 1.1 0 0 1 1.558 0l1.805 1.805-.17.042a1.145 1.145 0 0 1-.79-.087l-.446-.223a1.664 1.664 0 0 0-1.752.158l-.967.725a1.146 1.146 0 0 1-1.049.17l-.586-.2Zm-4.791.845a1.1 1.1 0 0 1 1.558 0l.821.821-1.036 1.036-.085-.085a1.389 1.389 0 0 0-1.606-.261l-.591.3a.865.865 0 0 1-.6.065l-.271-.068Zm5.984 3.057H14.5l.45.6h-2.323Zm-2.505 1.127h1.264v.6h-1.564Zm-.463 1.127h1.727v1.33l-1.727.66Zm-2.381.6H6.164v-.6h1.264Zm-5.61-1.727h3.055l.3.6H1.817Zm1.138 4.082-.474-.251v-.431a.264.264 0 1 0-.527 0v.152l-.6-.318v-2.107h3.981v3.473a4.527 4.527 0 0 1-2.38-.518Zm3.735.227a4.519 4.519 0 0 1-.828.229v-2.284h1.728v1.711Zm5.882-2.249-.51.195v-2.255h2.854v1.756a5.057 5.057 0 0 0-2.344.305Z"
                    transform="translate(6.001 -90.339)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97599"
                    d="M369.007 283.655a.264.264 0 0 0-.264.263v.282a.264.264 0 1 0 .527 0v-.282a.263.263 0 0 0-.263-.263Z"
                    transform="translate(-349.779 -268.175)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97600"
                    d="M304.878 315.72a.264.264 0 0 0-.264.264v.282a.264.264 0 1 0 .528 0v-.282a.264.264 0 0 0-.264-.264Z"
                    transform="translate(-287.905 -299.113)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97601"
                    d="M128.524 355.8a.264.264 0 0 0-.264.264v.282a.264.264 0 0 0 .527 0v-.282a.264.264 0 0 0-.263-.264Z"
                    transform="translate(-117.75 -337.784)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97602"
                    d="M128.524 315.72a.264.264 0 0 0-.264.264v.282a.264.264 0 1 0 .527 0v-.282a.264.264 0 0 0-.263-.264Z"
                    transform="translate(-117.75 -299.113)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97603"
                    d="M96.459 355.8a.264.264 0 0 0-.264.264v.282a.264.264 0 0 0 .527 0v-.282a.264.264 0 0 0-.263-.264Z"
                    transform="translate(-86.812 -337.784)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97604"
                    d="M96.459 315.72a.264.264 0 0 0-.264.264v.282a.264.264 0 1 0 .527 0v-.282a.264.264 0 0 0-.263-.264Z"
                    transform="translate(-86.812 -299.113)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97605"
                    d="M64.394 315.72a.264.264 0 0 0-.264.264v.282a.264.264 0 1 0 .527 0v-.282a.264.264 0 0 0-.263-.264Z"
                    transform="translate(-55.875 -299.113)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97606"
                    d="M401.072 283.655a.263.263 0 0 0-.264.263v.282a.264.264 0 0 0 .528 0v-.282a.264.264 0 0 0-.264-.263Z"
                    transform="translate(-380.717 -268.175)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97607"
                    d="M192.652 347.784a.264.264 0 0 0-.263.264v.282a.264.264 0 1 0 .527 0v-.282a.264.264 0 0 0-.264-.264Z"
                    transform="translate(-179.625 -330.05)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
            <path data-name="Rectangle 54844" fill="none" d="M.001 0h30v30h-30z" />
        </g>
    </svg>
)