import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { SearchFilter, TableWithPagination } from "../../../components"
import { NewLoader } from "../../../components/newLoader"
import { config } from "../../../config"
import { NetworkCall } from "../../../networkcall"
import { NetWorkCallMethods } from "../../../utils"
import { useStyles } from "../style"
import { PlanDataType, PlanHeading, PlanPath, planPreviewTabOptions } from "../utils"

export const PlanPreview = ({ state = {}, data = {}, t = () => false, updateState = () => false, form_type = "Unit", isView = false }) => {
    const [searchText, setSearchText] = React.useState("")
    const classes = useStyles()
    // const [filterData, setFilterData] = React.useState({})
    const [tableData, setTableData] = React.useState({ row: [], count: 0 })
    const [loading, setLoading] = React.useState(false)
    const [tabValue, setTabValue] = React.useState("")
    const [tabOptions, setTabOptions] = React.useState(planPreviewTabOptions)
    // const [filterDrawer, setFilterDrawer] = React.useState({})
    // const [page, setPage] = React.useState(1)
    // const [limit, setLimit] = React.useState(10)
    // const [offset, setOffset] = React.useState(10)
    React.useEffect(() => {      
        getDynamicPlans()
        //eslint-disable-next-line
    }, [])

    const getDynamicPlans = () => {
        setLoading(true)
        const payload = {
            "config": data?.dynamic_schedule?.map((x) => {
                return {
                    frequency: x?.frequency?.value,
                    occurences: x?.occurence>1?x?.occurence:0,
                    start_date:  moment(x?.execution_start_date).format("YYYY-MM-DD"),
                }
            }),
            "properties": data?.selected_properties?.map((x) => {
                return {
                    "property_name": x?.label ?? x?.property_name,
                    "property_id": x?.value ?? x?.property_id,
                    "address": x?.property?.address ?? { city: x?.city, country: x?.country },
                    "logo": x?.property?.logo ?? x?.logo
                }
            })
        }

        NetworkCall(
            `${config.api_url}/preventive_dynamic_plan/schedule_work_order`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            const result = response?.data?.data?.map((x, index) => {
                return {
                    ...x,
                    name: data?.plan_name,
                    type: data?.PMPType,
                    criteria: data?.pmp_criteria,
                    frequency: x?.frequency,
                    execution: `${moment(x?.start_date)?.format("DD MMM YYYY")} - ${moment(x?.end_date)?.format("DD MMM YYYY")}`
                }
            })
            const tabResult = tabOptions?.map((val) => {
                return {
                    ...val,
                    count: response?.data?.data?.filter((x, index) => { return val?.label === x?.frequency })?.length ?? 0
                }
            })
            setTabOptions(tabResult)
            setTabValue(tabResult?.filter((val)=>{return val?.count>0})?.[0]?.value??"1")
            setTableData({ row: result?.filter((x) => { return x?.frequency === tabResult?.filter((val)=>{return val?.count>0})?.[0]?.label }), count: result?.length })
            updateState("dynamic_plan_preview", result)
        }).catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }

    const handleTableSearch = (val) => {
        setSearchText(val)
        if (val?.length > 0) {
            const result = tableData?.row?.filter((x) => { return (x?.property_name.toLowerCase().includes(val.toLowerCase()) || x?.property_name.includes(val)) })
            setTableData({ ...tableData, row: result })
        }
        else {
            setTableData({ ...tableData, row: tableData?.allData })
            // console.log("result","no selected",data)
        }
    }
    // const handleTablePagination = () => {

    // }
    // const handleTablePageLimit = () => {

    // }
    const handleTableIcon = (type, details, is_checked) => {
        if (type === "customCheckBox") {
            const result = tableData?.row?.map((x) => {
                return {
                    ...x,
                    is_checked: x?.value === details?.value ? is_checked : x?.is_checked
                }

            })
            updateState("selected_properties", result?.filter((val) => { return val?.is_checked }))
            setTableData({ ...tableData, row: result })
        }
    }
    const allhandleCheck = (is_checked) => {
        const result = tableData?.row?.map((x) => {
            return {
                ...x,
                is_checked: is_checked
            }

        })
        setTableData({ ...tableData, row: result })
        updateState("selected_properties", result?.filter((val) => { return val?.is_checked }))
    }
    const handleTabChange = (newValue) => {
        setTabValue(newValue?.value);
        const CurrentTabData = tabOptions?.filter((x) => { return x?.value === newValue?.value })
        const updatedData = data?.dynamic_plan_preview?.filter((x) => { return x?.frequency === CurrentTabData?.[0]?.label })
        setTableData({ ...tableData, row: updatedData })
    }
    return (
        <Box>
            {/* {form_type === "checkList" &&
                <TabContext value={tabValue}>
                    <TabList onChange={handleTabChange}>
                        <Tab label={t("Monthly")} value="1" />
                        <Tab label={t("Draft")} value="2" />
                    </TabList>
                    <Divider />
                </TabContext>
            } */}
            {loading ? <NewLoader />
                :
                <>
                  <Box sx={{ padding: "16px 16px 0px 16px" }} display={"flex"} justifyContent={"center"}>
                        <Stack direction="row" spacing={1.5} alignItems="center">
                            {tabOptions?.filter((val)=>{return val?.count>0})?.map((x) => {
                                return (

                                    <Box onClick={() => handleTabChange(x)}>
                                        <Typography className={tabValue === x?.value ? classes.selectedPublishText : classes.publishtext} sx={{ marginBottom: "6px" }}>{`${x.label} (${x?.count})`}</Typography>
                                        <Box sx={{ borderBottom: tabValue === x?.value ? "3px solid #5078E1" : "3px solid #FFFFFF" }} />
                                    </Box>

                                )
                            })}
                        </Stack>
                    </Box>
                    <Divider />                  
                    <Box p={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6} lg={3}>
                                <SearchFilter value={searchText} placeholder={t("Search")}
                                    handleChange={(value) => handleTableSearch(value)} />
                            </Grid>
                            <Grid item xs={6} sm={6} lg={9}>
                                <Box display={"flex"} justifyContent={"end"}>

                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt={2}>
                            <TableWithPagination
                                heading={PlanHeading(t)}
                                rows={tableData?.row}
                                path={PlanPath}
                                showpagination={false}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleTableIcon}
                                onClick={() => console.log("")}
                                tableType="no-side"
                                dataType={PlanDataType}
                                allhandleCheck={allhandleCheck}
                                checkboxKey="is_checked"
                                allSelect={tableData?.row?.every((x) => { return x?.is_checked })}
                                // handlePagination={handleTablePagination}
                                // handleChangeLimit={handleTablePageLimit}
                                // totalRowsCount={form_type === "unit" ? data?.selected_items?.length : form_type === "resource" ? data?.selected_resources?.length : data?.selected_checklist?.length}
                                // page={page}
                                // limit={limit}
                                height={isView ? 'calc(100vh - 340px)' : 'calc(100vh - 455px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Box>
                    </Box>
                </>
            }
        </Box>
    )
}