import React from "react";
import CreateOppertunityContextProvider from "../../contexts/createOppertunityContext";
import { withNavBars } from "../../HOCs";
import { CreateOpportunity } from "./createOppertunity";
const CreateOpportunityParent = () => {
    return (
        <CreateOppertunityContextProvider>
            <CreateOpportunity />
        </CreateOppertunityContextProvider>
    );
};
const props = {
    boxShadow: false
}
export default withNavBars(CreateOpportunityParent, props);
