import { Box, IconButton, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment';
import { CalendarStyle } from './style'
import { startOfWeek, startOfMonth, endOfMonth, endOfWeek, addMonths, subMonths } from "date-fns";
import { AmenitiesBookingContext } from '../amenitiesBookingContext';

export const Calendar = ({ getAmenities = () => false, selected_date = new Date() }) => {

    const { finalDays, setFinalDays, month, setMonth, getdateRange, data, setData } = React.useContext(AmenitiesBookingContext)

    // const monthStart = startOfMonth(month);
    // const monthEnd = endOfMonth(monthStart);
    // const monthstartDate = startOfWeek(monthStart);
    // const monthendDate = endOfWeek(monthEnd);

    const next = () => {
        let tempMonth = addMonths(month, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        setMonth(tempMonth)
        let finaldays = getdateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setFinalDays(finaldays)
    }

    const prev = () => {
        let tempMonth = subMonths(month, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        setMonth(tempMonth)
        let finaldays = getdateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setFinalDays(finaldays)
    }


    // React.useEffect(() => {
    //     let finaldays = getdateRange(monthstartDate, monthendDate)
    //     setFinalDays(finaldays)
    //     // eslint-disable-next-line
    // }, [])

    return (
        <Box>
            <Header next={next} prev={prev} month={month} />
            <Body
                finaldays={finalDays}
                month={month}
                getAmenities={getAmenities}
                selected_date={selected_date}
                data={data} setData={setData} />
        </Box>
    )
}

// Calendar header section 
const Header = ({ next = () => false, prev = () => false, month = {} }) => {
    const classes = CalendarStyle()
    return (
        <Stack direction={"row"} spacing={4} alignItems={"center"} justifyContent={"space-between"} sx={{ direction: "ltr" }}>
            <IconButton className={classes.iconbutton} onClick={prev}>
                <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography className={classes.dateTitle}>{moment(month).format("MMMM YYYY")}</Typography>
            <IconButton className={classes.iconbutton} onClick={next}>
                <KeyboardArrowRightIcon />
            </IconButton>
        </Stack>
    )
}

// Calendar body section

const Body = ({ finaldays = {}, month = {}, getAmenities = () => false, selected_date = "", data = {}, setData = () => false }) => {
    const classes = CalendarStyle()
    const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
    return (
        <Box pt={1}>
            <Grid container>
                {
                    days.map((day) => {
                        return (
                            <Grid item xs={1.7} md={1.7} sm={1.7} letterSpacing={.7} textAlign={"center"} className={classes.day} >
                                <Box><span>{day}</span></Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container>
                {
                    finaldays.map(e => {
                        // console.log("e",e, "===>", moment(e).format("M"))
                        return (
                            <Grid item xs={1.7} className={moment(e).format("M") === moment(month).format("M") ? classes.date_curr_month : classes.date_not_curr_month} textAlign={"center"}
                                onClick={() => {
                                    if (moment(e).format("M") === moment(month).format("M") &&
                                        moment(new Date()).format("YYYY-MM-DD") <= moment(e).format("YYYY-MM-DD")
                                        ) {
                                        // setData(false)
                                        getAmenities(e)
                                    }
                                }
                                } >
                                <Stack className={moment(e).format("DD MM") === moment(selected_date).format("DD MM") && classes.today} p={1}>
                                    <Box><span>{moment(e).format("DD")}</span></Box>
                                </Stack>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}