import { Box, Button, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import FilterIcon from "../../assets/filterIcon";
import { SearchFilter } from "../../components";

const ToolSearchBar = ({ t, handleAdd = () => false, handleSearch = () => false, searchText = "", openfilter = () => false, permissions = {} }) => {
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <SearchFilter value={searchText} placeholder={t("searchTools")} handleChange={(value) => handleSearch(value)} />

                </Grid>
                <Grid item xs={8}>
                    <Box display={"flex"} justifyContent={"end"}>
                        <Stack direction="row" spacing={2}
                            divider={<Divider orientation="vertical" flexItem />}>
                            <div style={{ cursor: "pointer" }}>
                                <FilterIcon
                                    onClick={openfilter} />
                            </div>
                            {
                                permissions?.create &&
                                <Button variant="contained" onClick={handleAdd}>
                                    {t("addNew")}
                                </Button>
                            }

                        </Stack>
                    </Box>
                </Grid>
            </Grid>


        </>
    )
}
export default withNamespaces("toolsMaster")(ToolSearchBar)