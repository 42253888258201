import React from "react";
import { withNavBars } from "../../HOCs";
import AmenityCategory from "./amenityCategory";

class AmenityCategoryParent extends React.Component {
  render() {
    return <AmenityCategory {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(AmenityCategoryParent, props);
