import { Box, Button, Divider, Menu, MenuItem, Switch, Typography } from '@mui/material'
import React from 'react'
import { ContractCalendar } from '../../../components/contractCalendar'
import { AddMenuStyle } from './style'
import { styled } from '@mui/material/styles';
import { CreateEditContext } from '../create/createEditContext';
import moment from 'moment';
import { EditIcon } from '../../../components';
import { ScopeMenu } from '../../../utils/contract';

const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#5AC782',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export const AddMenu = (
    {
        data = {},
        click = () => false,
        setSelected = () => false,
        setShow = () => false,
        selected = {},
        t
    }
) => {

    const { updateState, state, setScopeStatus } = React.useContext(CreateEditContext)

    const classes = AddMenuStyle()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (data?.popup) {
            click()
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleGetDate = (value) => {
        updateState("startEndDate", value)
        handleClose()
    }

    const labelFormat = (data) => {
        return {
            "01": `${moment(data?.label?.startDate).format("DD MMM YY")} - ${moment(data?.label?.endDate).format("DD MMM YY")}`,
            "02": `${data?.label?.name}`
        }
    }

    return (
        <>
            {
                data?.type === "button" ?
                    <Box>
                        <Button
                            variant='text'
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={state?.type !== "view" && handleClick}
                            className={Object.keys(data?.label)?.length > 0 ? classes.editBtn : classes.addBtn}
                        >
                            &#x202a;{Object.keys(data?.label)?.length > 0 ? labelFormat(data)?.[data?.count] : state?.type !== "view" && data?.buttonText}&#x202c;
                            {
                                (data?.count === "02" && Object.keys(data?.label)?.length > 0) &&
                                <Typography className={classes.extraLabel}>, {data?.label?.account_no}, {data?.label?.city ? data?.label?.city : "-"}</Typography>
                            }
                            {
                                (Object.keys(data?.label)?.length > 0 && state?.type !== "view") && <Box sx={{ marginLeft: "8px", marginTop: "2px" }}><EditIcon /></Box>
                            }
                        </Button>
                        {
                            data?.date &&
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem className={classes.menuItem} disableRipple>
                                    <Box sx={{height:"100%",width:400}}>
                                        <ContractCalendar click={handleGetDate} />
                                    </Box>
                                </MenuItem>
                            </Menu>
                        }

                        {
                            data?.scopeMenu &&
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {
                                    ScopeMenu?.map((e, i, length) => {
                                        return (
                                            <>
                                                <MenuItem className={classes.scopeMenuItem} disableRipple onClick={() => {
                                                    setSelected({ ...selected, type3: true })
                                                    setShow(true)
                                                    setScopeStatus(true)
                                                    handleClose()
                                                }}>
                                                    <Typography className={classes.menuLabel}>{e}</Typography>
                                                </MenuItem>
                                                {i !== length?.length - 1 && <Divider></Divider>}
                                            </>
                                        )
                                    })
                                }
                            </Menu>
                        }
                    </Box>
                    :
                    <CustomSwitch defaultChecked={state?.type?.length > 0 ? true : false}
                        disabled={state?.type === "view"}
                        onChange={(e) => {
                            // setSelected(data?.count)
                            if (state?.type !== "view") {                                
                                if (e?.target?.checked === true) {
                                    setSelected({ ...selected, [`type${(data?.count).slice(1, 2)}`]: true })
                                    setShow(true)
                                } else {
                                    setSelected({ ...selected, [`type${(data?.count).slice(1, 2)}`]: false })
                                    setShow(false)
                                }
                            }
                        }} />
            }
        </>
    )
}