import * as React from "react"

const SiteVisitsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={33} height={33} {...props}>
    <defs>
      <clipPath id="a">
        <rect
          data-name="Rectangle 53682"
          width={33}
          height={33}
          rx={8}
          transform="translate(.098)"
          fill="#30afce"
        />
      </clipPath>
    </defs>
    <g data-name="Group 101885" transform="translate(-.097)">
      <rect
        data-name="Rectangle 53585"
        width={33}
        height={33}
        rx={8}
        transform="translate(.098)"
        fill="#e29336"
      />
      <g data-name="Mask Group 6472" opacity={0.65} clipPath="url(#a)">
        <g data-name="Group 101884" fill="none" stroke="#e8a95e">
          <g data-name="Group 101876">
            <g data-name="Rectangle 53586">
              <path
                stroke="none"
                d="m-52.528-4.743 21.43 5.741-5.742 21.43-21.43-5.742z"
              />
              <path d="m-52.174-4.131 20.463 5.483-5.483 20.463-20.463-5.483z" />
            </g>
            <g data-name="Rectangle 53587">
              <path
                stroke="none"
                d="m-25.356-20.431 21.429 5.742L-9.669 6.74-31.098.998z"
              />
              <path d="m-25.003-19.819 20.464 5.483-5.484 20.464L-30.486.645z" />
            </g>
            <g data-name="Rectangle 53588">
              <path
                stroke="none"
                d="m-51.302-2.62 18.081 4.844-4.845 18.081-18.081-4.845z"
              />
              <path d="m-50.949-2.008 17.116 4.586-4.586 17.115-17.116-4.586z" />
            </g>
            <g data-name="Rectangle 53589">
              <path
                stroke="none"
                d="m-24.13-18.308 18.08 4.844-4.844 18.082-18.081-4.845z"
              />
              <path d="m-23.777-17.696 17.115 4.586-4.586 17.115L-28.363-.58z" />
            </g>
            <g data-name="Rectangle 53590">
              <path
                stroke="none"
                d="m-49.831-.073 14.063 3.768-3.768 14.063L-53.6 13.99z"
              />
              <path d="m-49.478.54 13.097 3.509-3.509 13.097-13.097-3.51z" />
            </g>
            <g data-name="Rectangle 53591">
              <path
                stroke="none"
                d="m-22.66-15.76 14.063 3.768-3.768 14.063-14.063-3.769z"
              />
              <path d="m-22.306-15.148 13.097 3.51-3.51 13.096-13.096-3.51z" />
            </g>
            <g data-name="Rectangle 53592">
              <path
                stroke="none"
                d="m-48.36 2.474 10.044 2.691-2.691 10.045-10.045-2.691z"
              />
              <path d="m-48.007 3.086 9.079 2.433-2.433 9.079-9.079-2.433z" />
            </g>
            <g data-name="Rectangle 53593">
              <path
                stroke="none"
                d="m-21.19-13.214 10.046 2.692-2.692 10.045L-23.88-3.17z"
              />
              <path d="m-20.836-12.601 9.08 2.432-2.433 9.08-9.08-2.433z" />
            </g>
            <g data-name="Rectangle 53594">
              <path
                stroke="none"
                d="m-46.89 5.02 6.027 1.616-1.615 6.027-6.027-1.615z"
              />
              <path d="m-46.537 5.633 5.062 1.357-1.356 5.061-5.062-1.356z" />
            </g>
            <g data-name="Rectangle 53595">
              <path
                stroke="none"
                d="m-19.719-10.667 6.028 1.615-1.615 6.028-6.028-1.615z"
              />
              <path d="m-19.365-10.054 5.061 1.356-1.356 5.061-5.061-1.356z" />
            </g>
            <g data-name="Rectangle 53596">
              <path
                stroke="none"
                d="m-45.91 6.72 3.348.896-.897 3.348-3.348-.897z"
              />
              <path d="m-45.556 7.332 2.382.638-.638 2.382-2.382-.638z" />
            </g>
            <g data-name="Rectangle 53597">
              <path
                stroke="none"
                d="m-18.738-8.968 3.348.897-.897 3.348-3.348-.897z"
              />
              <path d="m-18.385-8.356 2.382.638-.638 2.382-2.382-.638z" />
            </g>
          </g>
          <g data-name="Group 101877">
            <g data-name="Rectangle 53598">
              <path
                stroke="none"
                d="m-34.329 26.778 21.43 5.742-5.742 21.43-21.43-5.743z"
              />
              <path d="m-33.975 27.39 20.463 5.484-5.483 20.463-20.463-5.483z" />
            </g>
            <g data-name="Rectangle 53599">
              <path
                stroke="none"
                d="m-7.157 11.09 21.429 5.742-5.742 21.43-21.429-5.742z"
              />
              <path d="m-6.804 11.703 20.464 5.483-5.484 20.463-20.463-5.483z" />
            </g>
            <g data-name="Rectangle 53600">
              <path
                stroke="none"
                d="m-33.103 28.9 18.081 4.846-4.845 18.08-18.081-4.844z"
              />
              <path d="m-32.75 29.513 17.116 4.586-4.586 17.115-17.116-4.586z" />
            </g>
            <g data-name="Rectangle 53601">
              <path
                stroke="none"
                d="m-5.932 13.213 18.082 4.845L7.305 36.14l-18.081-4.845z"
              />
              <path d="m-5.578 13.826 17.115 4.586-4.586 17.115-17.115-4.586z" />
            </g>
            <g data-name="Rectangle 53602">
              <path
                stroke="none"
                d="m-31.632 31.449 14.063 3.768-3.768 14.063L-35.4 45.51z"
              />
              <path d="m-31.279 32.06 13.097 3.51-3.509 13.097-13.097-3.51z" />
            </g>
            <g data-name="Rectangle 53603">
              <path
                stroke="none"
                d="M-4.46 15.761 9.601 19.53 5.834 33.592l-14.063-3.768z"
              />
              <path d="m-4.107 16.373 13.097 3.51L5.48 32.98l-13.096-3.51z" />
            </g>
            <g data-name="Rectangle 53604">
              <path
                stroke="none"
                d="m-30.162 33.996 10.045 2.691-2.691 10.045-10.045-2.692z"
              />
              <path d="m-29.808 34.608 9.079 2.433-2.433 9.078-9.079-2.432z" />
            </g>
            <g data-name="Rectangle 53605">
              <path
                stroke="none"
                d="M-2.99 18.308 7.055 21 4.363 31.044l-10.045-2.691z"
              />
              <path d="m-2.637 18.92 9.08 2.433-2.433 9.079-9.08-2.433z" />
            </g>
            <g data-name="Rectangle 53606">
              <path
                stroke="none"
                d="m-28.691 36.543 6.027 1.615-1.615 6.027-6.027-1.615z"
              />
              <path d="m-28.338 37.155 5.062 1.356-1.356 5.062-5.062-1.357z" />
            </g>
            <g data-name="Rectangle 53607">
              <path
                stroke="none"
                d="m-1.52 20.855 6.028 1.615-1.615 6.027-6.028-1.615z"
              />
              <path d="m-1.166 21.467 5.061 1.357-1.356 5.061-5.061-1.356z" />
            </g>
            <g data-name="Rectangle 53608">
              <path
                stroke="none"
                d="m-27.71 38.24 3.347.898-.897 3.348-3.348-.897z"
              />
              <path d="m-27.357 38.853 2.382.638-.638 2.382-2.382-.638z" />
            </g>
            <g data-name="Rectangle 53609">
              <path
                stroke="none"
                d="m-.54 22.553 3.349.897-.897 3.348-3.348-.897z"
              />
              <path d="m-.186 23.166 2.382.638-.638 2.382-2.382-.638z" />
            </g>
          </g>
          <g data-name="Group 101878">
            <g data-name="Rectangle 53610">
              <path
                stroke="none"
                d="m-30.006 3.068 21.43 5.742-5.742 21.429-21.43-5.742z"
              />
              <path d="m-29.652 3.68 20.463 5.483-5.483 20.464-20.463-5.484z" />
            </g>
            <g data-name="Rectangle 53611">
              <path
                stroke="none"
                d="m-2.834-12.62 21.43 5.742-5.743 21.43L-8.576 8.81z"
              />
              <path d="m-2.48-12.007 20.463 5.483L12.5 13.939-7.964 8.456z" />
            </g>
            <g data-name="Rectangle 53612">
              <path
                stroke="none"
                d="m-28.78 5.19 18.081 4.845-4.845 18.082-18.08-4.845z"
              />
              <path d="m-28.426 5.803 17.115 4.586-4.586 17.115-17.115-4.586z" />
            </g>
            <g data-name="Rectangle 53613">
              <path
                stroke="none"
                d="m-1.608-10.497 18.08 4.845-4.844 18.081-18.081-4.845z"
              />
              <path d="M-1.255-9.885 15.86-5.299l-4.586 17.116L-5.84 7.23z" />
            </g>
            <g data-name="Rectangle 53614">
              <path
                stroke="none"
                d="m-27.309 7.738 14.063 3.769-3.768 14.063-14.063-3.769z"
              />
              <path d="m-26.955 8.35 13.097 3.51-3.51 13.097-13.097-3.51z" />
            </g>
            <g data-name="Rectangle 53615">
              <path
                stroke="none"
                d="m-.137-7.95 14.062 3.77-3.768 14.062-14.063-3.768z"
              />
              <path d="m.216-7.337 13.097 3.51L9.803 9.27-3.292 5.76z" />
            </g>
            <g data-name="Rectangle 53616">
              <path
                stroke="none"
                d="m-25.839 10.285 10.045 2.692-2.691 10.045-10.045-2.692z"
              />
              <path d="m-25.485 10.898 9.079 2.432-2.433 9.08-9.079-2.433z" />
            </g>
            <g data-name="Rectangle 53617">
              <path
                stroke="none"
                d="m1.333-5.402 10.045 2.691L8.686 7.334-1.358 4.643z"
              />
              <path d="m1.687-4.79 9.078 2.433-2.432 9.079-9.08-2.433z" />
            </g>
            <g data-name="Rectangle 53618">
              <path
                stroke="none"
                d="m-24.368 12.832 6.027 1.615-1.615 6.028-6.027-1.615z"
              />
              <path d="m-24.014 13.445 5.061 1.356-1.356 5.061-5.062-1.356z" />
            </g>
            <g data-name="Rectangle 53619">
              <path
                stroke="none"
                d="M2.804-2.855 8.83-1.24 7.216 4.787 1.188 3.172z"
              />
              <path d="M3.157-2.243 8.22-.887 6.862 4.175 1.801 2.819z" />
            </g>
            <g data-name="Rectangle 53620">
              <path
                stroke="none"
                d="m-23.388 14.53 3.348.898-.897 3.348-3.348-.897z"
              />
              <path d="m-23.034 15.143 2.382.638-.638 2.382-2.382-.638z" />
            </g>
            <g data-name="Rectangle 53621">
              <path
                stroke="none"
                d="m3.784-1.157 3.348.897-.897 3.348-3.348-.897z"
              />
              <path d="M4.138-.544 6.52.094 5.88 2.476 3.5 1.838z" />
            </g>
          </g>
          <g data-name="Group 101879">
            <g data-name="Rectangle 53622">
              <path
                stroke="none"
                d="M-11.807 34.589 9.622 40.33 3.88 61.76l-21.429-5.742z"
              />
              <path d="M-11.453 35.201 9.01 40.684 3.527 61.147l-20.464-5.483z" />
            </g>
            <g data-name="Rectangle 53623">
              <path
                stroke="none"
                d="m15.365 18.901 21.429 5.742-5.742 21.43-21.43-5.743z"
              />
              <path d="m15.718 19.514 20.463 5.483-5.483 20.463-20.463-5.483z" />
            </g>
            <g data-name="Rectangle 53624">
              <path
                stroke="none"
                d="M-10.582 36.711 7.5 41.556 2.655 59.637l-18.081-4.845z"
              />
              <path d="M-10.228 37.324 6.887 41.91 2.301 59.025l-17.115-4.586z" />
            </g>
            <g data-name="Rectangle 53625">
              <path
                stroke="none"
                d="m16.59 21.024 18.081 4.845-4.845 18.08-18.08-4.844z"
              />
              <path d="m16.944 21.636 17.115 4.586-4.586 17.115-17.115-4.586z" />
            </g>
            <g data-name="Rectangle 53626">
              <path
                stroke="none"
                d="m-9.11 39.26 14.062 3.767L1.184 57.09l-14.063-3.768z"
              />
              <path d="M-8.757 39.872 4.34 43.38.83 56.478l-13.096-3.51z" />
            </g>
            <g data-name="Rectangle 53627">
              <path
                stroke="none"
                d="m18.061 23.572 14.063 3.768-3.768 14.063-14.063-3.768z"
              />
              <path d="m18.415 24.184 13.097 3.51-3.51 13.096-13.097-3.509z" />
            </g>
            <g data-name="Rectangle 53628">
              <path
                stroke="none"
                d="m-7.64 41.806 10.045 2.692-2.692 10.044-10.044-2.691z"
              />
              <path d="m-7.286 42.419 9.078 2.432L-.64 53.93l-9.08-2.433z" />
            </g>
            <g data-name="Rectangle 53629">
              <path
                stroke="none"
                d="m19.532 26.119 10.044 2.691-2.691 10.045-10.045-2.692z"
              />
              <path d="m19.885 26.731 9.079 2.433-2.433 9.078-9.079-2.432z" />
            </g>
            <g data-name="Rectangle 53630">
              <path
                stroke="none"
                d="m-6.17 44.353 6.028 1.615-1.615 6.028-6.028-1.615z"
              />
              <path d="m-5.816 44.966 5.061 1.356-1.356 5.061-5.061-1.356z" />
            </g>
            <g data-name="Rectangle 53631">
              <path
                stroke="none"
                d="m21.002 28.666 6.027 1.615-1.615 6.027-6.027-1.615z"
              />
              <path d="m21.356 29.278 5.061 1.356-1.356 5.062-5.062-1.357z" />
            </g>
            <g data-name="Rectangle 53632">
              <path
                stroke="none"
                d="m-5.189 46.051 3.348.897-.897 3.348-3.348-.897z"
              />
              <path d="m-4.835 46.664 2.381.638-.638 2.382-2.382-.638z" />
            </g>
            <g data-name="Rectangle 53633">
              <path
                stroke="none"
                d="m21.983 30.364 3.347.897-.897 3.348-3.348-.897z"
              />
              <path d="m22.336 30.976 2.382.639-.638 2.382-2.382-.639z" />
            </g>
          </g>
          <g data-name="Group 101880">
            <g data-name="Rectangle 53634">
              <path
                stroke="none"
                d="m1.815-36.118 21.43 5.741-5.742 21.43-21.43-5.742z"
              />
              <path d="m2.169-35.506 20.463 5.483L17.15-9.56l-20.463-5.483z" />
            </g>
            <g data-name="Rectangle 53635">
              <path
                stroke="none"
                d="m28.987-51.806 21.429 5.742-5.742 21.429-21.429-5.742z"
              />
              <path d="m29.34-51.194 20.464 5.483-5.483 20.464-20.464-5.483z" />
            </g>
            <g data-name="Rectangle 53636">
              <path
                stroke="none"
                d="m3.04-33.996 18.082 4.845-4.845 18.081-18.08-4.845z"
              />
              <path d="m3.394-33.383 17.116 4.586-4.586 17.115-17.116-4.586z" />
            </g>
            <g data-name="Rectangle 53637">
              <path
                stroke="none"
                d="m30.212-49.683 18.082 4.844-4.845 18.082-18.081-4.845z"
              />
              <path d="m30.566-49.071 17.115 4.586-4.586 17.115-17.115-4.586z" />
            </g>
            <g data-name="Rectangle 53638">
              <path
                stroke="none"
                d="m4.512-31.448 14.063 3.768-3.768 14.063L.744-17.385z"
              />
              <path d="m4.865-30.836 13.097 3.51-3.509 13.097-13.097-3.51z" />
            </g>
            <g data-name="Rectangle 53639">
              <path
                stroke="none"
                d="m31.683-47.136 14.063 3.769-3.768 14.063-14.063-3.769z"
              />
              <path d="m32.037-46.523 13.097 3.51-3.51 13.096-13.096-3.51z" />
            </g>
            <g data-name="Rectangle 53640">
              <path
                stroke="none"
                d="m5.982-28.901 10.045 2.691-2.691 10.045L3.29-18.856z"
              />
              <path d="m6.336-28.289 9.079 2.433-2.433 9.079-9.079-2.433z" />
            </g>
            <g data-name="Rectangle 53641">
              <path
                stroke="none"
                d="m33.154-44.589 10.045 2.692-2.692 10.045-10.044-2.692z"
              />
              <path d="m33.508-43.976 9.078 2.432-2.432 9.08-9.08-2.433z" />
            </g>
            <g data-name="Rectangle 53642">
              <path
                stroke="none"
                d="m7.453-26.354 6.027 1.615-1.615 6.027-6.027-1.615z"
              />
              <path d="m7.806-25.742 5.062 1.357-1.356 5.061L6.45-20.68z" />
            </g>
            <g data-name="Rectangle 53643">
              <path
                stroke="none"
                d="m34.624-42.042 6.028 1.615-1.615 6.028-6.028-1.615z"
              />
              <path d="m34.978-41.43 5.061 1.357-1.356 5.061-5.061-1.356z" />
            </g>
            <g data-name="Rectangle 53644">
              <path
                stroke="none"
                d="m8.433-24.656 3.348.897-.897 3.348-3.348-.897z"
              />
              <path d="m8.787-24.043 2.382.638-.638 2.382-2.382-.638z" />
            </g>
            <g data-name="Rectangle 53645">
              <path
                stroke="none"
                d="m35.605-40.343 3.348.897-.897 3.348-3.348-.897z"
              />
              <path d="m35.959-39.73 2.382.637-.639 2.382-2.382-.638z" />
            </g>
          </g>
          <g data-name="Group 101881">
            <g data-name="Rectangle 53646">
              <path
                stroke="none"
                d="m20.014-4.597 21.43 5.742-5.742 21.43-21.43-5.743z"
              />
              <path d="M20.368-3.985 40.831 1.5l-5.483 20.463-20.463-5.483z" />
            </g>
            <g data-name="Rectangle 53647">
              <path
                stroke="none"
                d="m47.186-20.284 21.429 5.741-5.742 21.43-21.429-5.742z"
              />
              <path d="m47.54-19.672 20.463 5.483L62.52 6.274 42.056.791z" />
            </g>
            <g data-name="Rectangle 53648">
              <path
                stroke="none"
                d="M21.24-2.474 39.32 2.37l-4.845 18.08-18.08-4.844z"
              />
              <path d="M21.593-1.862 38.71 2.724 34.123 19.84l-17.116-4.586z" />
            </g>
            <g data-name="Rectangle 53649">
              <path
                stroke="none"
                d="m48.411-18.162 18.082 4.845-4.845 18.081L43.567-.08z"
              />
              <path d="m48.765-17.55 17.115 4.587-4.586 17.115L44.18-.434z" />
            </g>
            <g data-name="Rectangle 53650">
              <path
                stroke="none"
                d="m22.71.074 14.064 3.768-3.768 14.063-14.063-3.769z"
              />
              <path d="m23.064.686 13.097 3.51-3.509 13.096-13.097-3.51z" />
            </g>
            <g data-name="Rectangle 53651">
              <path
                stroke="none"
                d="m49.882-15.614 14.063 3.768-3.768 14.063L46.114-1.55z"
              />
              <path d="m50.236-15.002 13.097 3.51-3.51 13.097-13.096-3.51z" />
            </g>
            <g data-name="Rectangle 53652">
              <path
                stroke="none"
                d="m24.181 2.62 10.045 2.692-2.691 10.045-10.045-2.692z"
              />
              <path d="m24.535 3.233 9.079 2.433-2.433 9.078-9.079-2.432z" />
            </g>
            <g data-name="Rectangle 53653">
              <path
                stroke="none"
                d="m51.353-13.067 10.045 2.692L58.706-.331 48.662-3.022z"
              />
              <path d="m51.707-12.455 9.078 2.433-2.432 9.079-9.08-2.433z" />
            </g>
            <g data-name="Rectangle 53654">
              <path
                stroke="none"
                d="m25.652 5.168 6.027 1.615-1.615 6.027-6.027-1.615z"
              />
              <path d="m26.005 5.78 5.062 1.356-1.356 5.062-5.062-1.357z" />
            </g>
            <g data-name="Rectangle 53655">
              <path
                stroke="none"
                d="m52.823-10.52 6.028 1.615-1.615 6.027-6.028-1.615z"
              />
              <path d="m53.177-9.908 5.061 1.357-1.356 5.061-5.061-1.356z" />
            </g>
            <g data-name="Rectangle 53656">
              <path
                stroke="none"
                d="m26.632 6.866 3.348.897-.897 3.348-3.348-.897z"
              />
              <path d="m26.986 7.478 2.382.638-.638 2.382-2.382-.638z" />
            </g>
            <g data-name="Rectangle 53657">
              <path
                stroke="none"
                d="m53.804-8.822 3.348.897-.897 3.348-3.348-.897z"
              />
              <path d="m54.158-8.21 2.382.639L55.9-5.19l-2.382-.638z" />
            </g>
          </g>
          <g data-name="Group 101882">
            <g data-name="Rectangle 53658">
              <path
                stroke="none"
                d="m24.338-28.307 21.429 5.742-5.742 21.429-21.43-5.742z"
              />
              <path d="m24.691-27.695 20.463 5.483-5.483 20.464-20.463-5.484z" />
            </g>
            <g data-name="Rectangle 53659">
              <path
                stroke="none"
                d="m51.51-43.995 21.428 5.742-5.742 21.43-21.429-5.742z"
              />
              <path d="M51.863-43.382 72.326-37.9l-5.483 20.463-20.463-5.483z" />
            </g>
            <g data-name="Rectangle 53660">
              <path
                stroke="none"
                d="m25.563-26.184 18.081 4.844L38.8-3.258 20.72-8.103z"
              />
              <path d="m25.917-25.572 17.115 4.586-4.586 17.115L21.33-8.457z" />
            </g>
            <g data-name="Rectangle 53661">
              <path
                stroke="none"
                d="m52.735-41.872 18.08 4.845-4.844 18.081L47.89-23.79z"
              />
              <path d="m53.088-41.26 17.115 4.586-4.586 17.116-17.115-4.586z" />
            </g>
            <g data-name="Rectangle 53662">
              <path
                stroke="none"
                d="m27.034-23.637 14.063 3.769-3.768 14.063-14.063-3.769z"
              />
              <path d="m27.388-23.024 13.097 3.51-3.51 13.096-13.097-3.51z" />
            </g>
            <g data-name="Rectangle 53663">
              <path
                stroke="none"
                d="m54.206-39.324 14.063 3.768L64.5-21.493l-14.063-3.768z"
              />
              <path d="m54.56-38.712 13.096 3.51-3.51 13.097-13.096-3.51z" />
            </g>
            <g data-name="Rectangle 53664">
              <path
                stroke="none"
                d="m28.505-21.09 10.044 2.692-2.691 10.045-10.045-2.692z"
              />
              <path d="m28.858-20.477 9.079 2.432-2.433 9.08-9.079-2.433z" />
            </g>
            <g data-name="Rectangle 53665">
              <path
                stroke="none"
                d="m55.676-36.777 10.045 2.691-2.692 10.045-10.044-2.691z"
              />
              <path d="m56.03-36.165 9.078 2.433-2.432 9.079-9.079-2.433z" />
            </g>
            <g data-name="Rectangle 53666">
              <path
                stroke="none"
                d="m29.975-18.543 6.027 1.615-1.615 6.028-6.027-1.615z"
              />
              <path d="m30.329-17.93 5.061 1.356-1.356 5.061-5.062-1.356z" />
            </g>
            <g data-name="Rectangle 53667">
              <path
                stroke="none"
                d="m57.147-34.23 6.027 1.615-1.615 6.027-6.027-1.615z"
              />
              <path d="m57.5-33.618 5.062 1.356-1.357 5.062-5.061-1.356z" />
            </g>
            <g data-name="Rectangle 53668">
              <path
                stroke="none"
                d="m30.956-16.844 3.347.897-.897 3.348-3.348-.897z"
              />
              <path d="m31.31-16.232 2.381.638-.638 2.382-2.382-.638z" />
            </g>
            <g data-name="Rectangle 53669">
              <path
                stroke="none"
                d="m58.127-32.532 3.348.897-.897 3.348-3.348-.897z"
              />
              <path d="m58.48-31.92 2.383.639-.639 2.382-2.382-.638z" />
            </g>
          </g>
          <g data-name="Group 101883">
            <g data-name="Rectangle 53670">
              <path
                stroke="none"
                d="m42.536 3.214 21.43 5.742-5.743 21.429-21.429-5.742z"
              />
              <path d="M42.89 3.826 63.353 9.31 57.87 29.772 37.407 24.29z" />
            </g>
            <g data-name="Rectangle 53671">
              <path
                stroke="none"
                d="m69.708-12.474 21.429 5.742-5.742 21.43-21.43-5.743z"
              />
              <path d="m70.061-11.861 20.463 5.483-5.483 20.463-20.463-5.483z" />
            </g>
            <g data-name="Rectangle 53672">
              <path
                stroke="none"
                d="m43.762 5.336 18.08 4.845-4.844 18.081-18.081-4.845z"
              />
              <path d="m44.115 5.949 17.115 4.586-4.586 17.115-17.115-4.586z" />
            </g>
            <g data-name="Rectangle 53673">
              <path
                stroke="none"
                d="m70.933-10.351 18.081 4.845-4.845 18.08L66.09 7.73z"
              />
              <path d="m71.287-9.739 17.115 4.586-4.586 17.115L66.7 7.376z" />
            </g>
            <g data-name="Rectangle 53674">
              <path
                stroke="none"
                d="m45.233 7.884 14.062 3.768-3.768 14.063-14.063-3.768z"
              />
              <path d="m45.586 8.497 13.097 3.509-3.51 13.097-13.096-3.51z" />
            </g>
            <g data-name="Rectangle 53675">
              <path
                stroke="none"
                d="m72.404-7.803 14.063 3.768-3.768 14.063L68.636 6.26z"
              />
              <path d="m72.758-7.191 13.097 3.51-3.51 13.096-13.097-3.509z" />
            </g>
            <g data-name="Rectangle 53676">
              <path
                stroke="none"
                d="m46.703 10.431 10.045 2.692-2.692 10.044-10.044-2.691z"
              />
              <path d="m47.057 11.044 9.078 2.432-2.432 9.079-9.079-2.433z" />
            </g>
            <g data-name="Rectangle 53677">
              <path
                stroke="none"
                d="m73.875-5.256 10.044 2.691L81.228 7.48 71.183 4.788z"
              />
              <path d="m74.228-4.644 9.079 2.433-2.433 9.078-9.078-2.432z" />
            </g>
            <g data-name="Rectangle 53678">
              <path
                stroke="none"
                d="m48.174 12.978 6.027 1.615-1.615 6.028-6.027-1.615z"
              />
              <path d="m48.527 13.59 5.062 1.357-1.357 5.061-5.061-1.356z" />
            </g>
            <g data-name="Rectangle 53679">
              <path
                stroke="none"
                d="m75.345-2.71 6.027 1.616-1.615 6.027-6.027-1.615z"
              />
              <path d="M75.699-2.097 80.76-.741l-1.356 5.062-5.062-1.357z" />
            </g>
            <g data-name="Rectangle 53680">
              <path
                stroke="none"
                d="m49.154 14.676 3.348.897-.897 3.348-3.348-.897z"
              />
              <path d="m49.508 15.289 2.382.638-.639 2.382-2.382-.638z" />
            </g>
            <g data-name="Rectangle 53681">
              <path
                stroke="none"
                d="m76.326-1.011 3.348.897-.898 3.348-3.347-.897z"
              />
              <path d="M76.68-.399 79.06.24l-.638 2.382-2.382-.639z" />
            </g>
          </g>
        </g>
      </g>
      <path
        d="M19.858 9.188a4.011 4.011 0 0 0-4.03 3.981 3.955 3.955 0 0 0 .975 2.593c.067.075 1.65 1.848 2.184 2.35a1.273 1.273 0 0 0 1.74 0c.621-.584 2.123-2.28 2.19-2.356a3.947 3.947 0 0 0 .97-2.586 4.011 4.011 0 0 0-4.029-3.982ZM14.216 11.1v9.957l3.209 2.345v-5.756c-.605-.655-1.213-1.337-1.223-1.347a4.706 4.706 0 0 1-1-4.4Zm-1.209.016-4.184 3.042a.6.6 0 0 0-.249.489v8.463a.6.6 0 0 0 .96.489l3.473-2.526Zm6.851.893a1.209 1.209 0 1 1-1.209 1.209 1.209 1.209 0 0 1 1.209-1.209Zm3.069 4.945c-.519.575-1.246 1.368-1.647 1.745a2.065 2.065 0 0 1-2.771.057v4.784l4.165-3.042a.6.6 0 0 0 .249-.489Z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default SiteVisitsIcon
