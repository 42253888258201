export const Amenities = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98464">
            <path
                d="M21.74 13.687a.439.439 0 0 0-.472.074 1.678 1.678 0 0 1-2.238 0 .439.439 0 0 0-.586 0 1.677 1.677 0 0 1-2.238 0 .439.439 0 0 0-.586 0 1.678 1.678 0 0 1-2.238 0 .439.439 0 0 0-.586 0 1.677 1.677 0 0 1-2.096.116V9.852a.973.973 0 0 1 1.945 0 .44.44 0 1 0 .879 0 1.85 1.85 0 0 0-3.26-1.196A1.85 1.85 0 0 0 7 9.852v11.532A1.618 1.618 0 0 0 8.616 23h11.768A1.618 1.618 0 0 0 22 21.384v-7.3a.439.439 0 0 0-.26-.4Zm-11.916.19a1.675 1.675 0 0 1-1.945 0V11.7h1.945Zm0-3.053H7.879v-.972a.973.973 0 0 1 1.945 0Zm9.205 9.239a2.557 2.557 0 0 1-3.117.227 2.557 2.557 0 0 1-2.824 0 2.557 2.557 0 0 1-3.117-.226.44.44 0 0 1 .586-.655 1.678 1.678 0 0 0 2.238 0 .439.439 0 0 1 .586 0 1.678 1.678 0 0 0 2.238 0 .439.439 0 0 1 .586 0 1.677 1.677 0 0 0 2.238 0 .44.44 0 0 1 .586.655Zm0-2.824a2.557 2.557 0 0 1-3.117.227 2.557 2.557 0 0 1-2.824 0 2.557 2.557 0 0 1-3.117-.226.44.44 0 0 1 .586-.655 1.678 1.678 0 0 0 2.238 0 .439.439 0 0 1 .586 0 1.678 1.678 0 0 0 2.238 0 .439.439 0 0 1 .586 0 1.677 1.677 0 0 0 2.238 0 .44.44 0 0 1 .586.655Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path data-name="Rectangle 52148" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
