export const DocumentTemplate = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-2{fill:#c1c5cb}"}</style>
        </defs>
        <g id="Announcement" transform="translate(24889 -2957)">
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24889 2957)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
            <g
                id="Document_Template_Designer"
                data-name="Document Template Designer"
                transform="translate(-24882 2964)"
            >
                <path
                    id="Path_96768"
                    data-name="Path 96768"
                    className="cls-2"
                    d="M0 3.75h16V0H0Zm5.625-2.344h.938v.938h-.938Zm-1.875 0h.938v.938H3.75Zm-1.875 0h.938v.938h-.938Z"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96769"
                    data-name="Path 96769"
                    className="cls-2"
                    d="M361 225h1.875v6.625H361Z"
                    transform="translate(-349.719 -217.969)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96770"
                    data-name="Path 96770"
                    className="cls-2"
                    d="M90 407h5.656v.938H90Z"
                    transform="translate(-87.188 -394.281)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96771"
                    data-name="Path 96771"
                    className="cls-2"
                    d="M90 225h5.656v2.875H90Z"
                    transform="translate(-87.188 -217.969)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96772"
                    data-name="Path 96772"
                    className="cls-2"
                    d="M0 161.313h16V150H0Zm10.344-9.906h3.75v8.5h-3.75Zm-8.469 0h7.531v4.75H1.875Zm0 5.688h7.531v2.813H1.875Z"
                    transform="translate(0 -145.313)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
        </g>
    </svg>
)