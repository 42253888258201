import { Avatar, Stack, Typography } from "@mui/material"
import { categoryCardStyles } from "./style"
import { Arrow } from "./arrow"

import { AuthContext } from "../../../contexts"
import { useContext } from "react"

export const CategoryCard = ({ data = {}, onClick = () => false }) => {
    const classes = categoryCardStyles()
    const auth = useContext(AuthContext)
    return (
        <Stack className={classes.root}
            direction={"row"}
            justifyContent={"space-between"} alignItems={"center"}
            onClick={() => onClick(data)}>
            <Avatar className={classes.avatar} variant="rounded"
                src={data?.image}>
                {data?.category ? data?.category?.substring(0, 2).toUpperCase() : "#"}
            </Avatar>
            <Stack className={classes.text_div}
                direction={"column"} spacing={"4px"}>
                {data?.category &&
                    <Typography className={classes.category}>
                        {data?.category}
                    </Typography>}
                {data?.description &&
                    <Typography className={classes.description}>
                        {data?.description}
                    </Typography>}
            </Stack>

            <Arrow style={{ transform: auth?.auth?.auth?.language === 'ar' ? `rotate(180deg)` : '' }} />
        </Stack>
    )
}