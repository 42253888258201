import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TreeStylesParent } from "./style";
import { Box, Typography, Checkbox } from "@mui/material";
import "../usermanagement.css";
import { TreeItem, TreeView, treeItemClasses } from "@mui/x-tree-view";

// styled component
const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
  ({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px solid #E0E0E0`,
    },
  })
);

// label component
const LabelValue = (props) => {
  // styles
  const classes = TreeStylesParent();
  return (
    <div className={classes.searchBox}>
      <Box className={classes.displayFlex} style={{ position: "sticky" }}>
        {props?.previousCheckbox && (
          <Box>
            <Checkbox
              checked={props?.currentItem?.active_status}
              onClick={(e) => e.stopPropagation()}
              onChange={props?.commonPermission ? () => false : (event) =>
                props?.handleFullRepoCheck(
                  event,
                  props?.currentItem,
                  props?.permissionList,
                  props?.isCheckbox
                )
              }
              inputProps={{ "aria-label": "controlled" }}
              // disabled={props?.disabled || props?.currentItem?.active_status === false}
            />
          </Box>
        )}

        {!props?.noIcon && <Box>
          <img src={props?.currentItem?.icon} alt="icon" />
        </Box>}
        <Box className={classes.marginLeft} sx={{ width: "168px", marginInlineStart: props?.noIcon && "0px !important" }}>
          <Typography className={classes.treeTitle}>
            {props?.currentItem?.name}
          </Typography>
        </Box>
        {props?.badge?.length > 0 && (
          <Box className={classes.marginLeft}>
            <Typography className={classes.badge}>
              {props?.currentItem?.badge}
            </Typography>
          </Box>
        )}
      </Box>
      {props?.isCheckbox && (
        <Box className={classes.displayFlex} >
          {["create", "read", "update", "delete"]?.map((x) => {
            return (
              <div key={x} className={classes.checkBoxDesign}>
                <Checkbox
                  checked={
                    props?.isAccessChecked(
                      x,
                      props?.currentItem?.id,
                      props?.permissionList
                    ) === true
                  }
                  disabled={
                    (props?.disabled || props?.isDisabledChecked(
                      x,
                      props?.currentItem?.id,
                      props?.permissionList
                    ) === false)}
                  onClick={(e) => e.stopPropagation()}
                  onChange={props?.commonPermission ? () => false : (event) => {
                    props?.handleAccessChange(
                      event,
                      x,
                      props?.currentItem?.id,
                      props.permissionList
                    );
                  }}
                />
              </div>
            );
          })}
        </Box>
      )}
    </div>
  );
};

export const TreeComponent = (props) => {
  // styles
  const classes = TreeStylesParent();

  // functions
  // Recursive List flatten function
  const getFlatList = (list) => {
    const flatList = [];
    const flattenPermissionList = (list) => {
      list?.map((item) => {
        flatList?.push(item);
        if (item?.children) {
          flattenPermissionList(item?.children);
        }
        return item;
      });
    };
    flattenPermissionList(list);

    return flatList;
  };

  // Handler for full repo change
  const handleFullRepoCheck = (event, { id }, permissionList, check) => {
    // if create && update && etc . checkbox  is
    const selectAll = (event, id, permissionList) => {
      const updatedPermissionList = permissionList?.map((item) => {
        // Change current repo status
        if (item?.id === id) {
          if (check) {
            if (item.permission.create["is_editable"] === true) {
              item.permission.create["is_active"] = event?.target?.checked;
            }
            if (item.permission.update["is_editable"] === true) {
              item.permission.update["is_active"] = event?.target?.checked;
            }
            if (item.permission.read["is_editable"] === true) {
              item.permission.read["is_active"] = event?.target?.checked;
            }
            if (item.permission.delete["is_editable"] === true) {
              item.permission.delete["is_active"] = event?.target?.checked;
            }
            item["active_status"] = event?.target?.checked;
          } else {
            item["active_status"] = event?.target?.checked;
          }
          if (item?.children) {
            selectAll(event, id, item?.children);
          }
        }

        if (item?.parent_id === id) {
          if (check) {
            if (item.permission.create["is_editable"] === true) {
              item.permission.create["is_active"] = event?.target?.checked;
            }
            if (item.permission.update["is_editable"] === true) {
              item.permission.update["is_active"] = event?.target?.checked;
            }
            if (item.permission.read["is_editable"] === true) {
              item.permission.read["is_active"] = event?.target?.checked;
            }
            if (item.permission.delete["is_editable"] === true) {
              item.permission.delete["is_active"] = event?.target?.checked;
            }
            item["active_status"] = event?.target?.checked;
          } else {
            item["active_status"] = event?.target?.checked;
          }
          if (item?.children) {
            selectAll(event, item?.id, item?.children);
          }
        }

        if (item?.children) {
          selectAll(event, id, item?.children);
        }

        return item;
      });

      return updatedPermissionList;
    };
    const final = selectAll(event, id, permissionList);
    props?.handleChange(getData(final));
  };

  // repo checked function
  const isAnyChildRepoChecked = (id, permissionList, check) => {
    if (permissionList?.length === 0) return false;

    const flatList = getFlatList(permissionList);

    if (check) {
      const res = flatList
        .filter(
          (permission) => permission?.parent_id === id || permission?.id === id
        )
        ?.every(
          (item) =>
            item?.permission?.create?.is_active &&
            item?.permission?.update?.is_active &&
            item?.permission?.read?.is_active &&
            item?.permission?.delete?.is_active &&
            item?.active_status
        );
      return res;
    } else {
      const res = flatList
        .filter(
          (permission) => permission?.parent_id === id || permission?.id === id
        )
        ?.every((item) => item?.active_status);
      return res;
    }
  };

  // Function to check if an Access is checked
  const isAccessChecked = (actionName, id, permissionList) => {
    if (!Array?.isArray(permissionList)) {
      return true;
    }
    const flatList = getFlatList(permissionList);
    return flatList?.filter((x) => x?.id === id)?.[0]?.permission[actionName].is_active;
  };
  //disablechecked
  const isDisabledChecked = (actionName, id, permissionList) => {
    if (!Array?.isArray(permissionList)) {
      return true;
    }
    const flatList = getFlatList(permissionList);
    return flatList?.filter((x) => x?.id === id)?.[0]?.permission[actionName].is_editable;
  };

  // click create edit check box function
  // start
  const handleAccessChange = (event, actionName, id, permissionList) => {
    const updatedPermissionList = permissionList?.map((item) => {
      if (item?.id === id) {
        item.permission[actionName]["is_active"] = event.target.checked;
        if (
          item?.permission?.create?.is_active === true ||
          item?.permission?.update?.is_active === true ||
          item?.permission?.read?.is_active === true ||
          item?.permission?.delete?.is_active === true
        ) {
          item.active_status = true;
        } else {
          item.active_status = false;
        }
      }

      if (item?.children) {
        handleAccessChange(event, actionName, id, item?.children);
      }
      return item;
    });

    return updatedPermissionList;
  };

  const handlePermissionLoop = (event, actionName, id, permissionList) => {
    const final = handleAccessChange(event, actionName, id, permissionList);
    props.handleChange(getData(final));
  };

  const getData = (list) => {
    const data = list?.map((x) => {
      if (x?.children) {
        if (x?.children.some((item) => item?.active_status)) {
          x.active_status = true;
        } else {
          x.active_status = false;
        }
      }
      return x;
    });
    return data;
  };

  // end

  // render tree function
  const render = (TreeJson) => {

    let listData =
      Array?.isArray(TreeJson) &&
      TreeJson?.map((currentItem) => {
        return (
          <StyledTreeItem
            nodeId={currentItem?.id}
            key={currentItem?.id}
            label={
              // contant of body
              <LabelValue
                noIcon={props?.noIcon}
                currentItem={currentItem}
                handleFullRepoCheck={handleFullRepoCheck}
                isAnyChildRepoChecked={isAnyChildRepoChecked}
                isAccessChecked={isAccessChecked}
                isDisabledChecked={isDisabledChecked}
                handleAccessChange={handlePermissionLoop}
                previousCheckbox={props?.previousCheckbox}
                isCheckbox={props?.isCheckbox}
                permissionList={props?.TreeJson}
                disabled={props?.disabled}
                commonPermission={props?.commonPermission}
              />
            }
          >
            {Array.isArray(currentItem?.children)
              ? render(currentItem?.children)
              : null}
          </StyledTreeItem>
        );
      });
    return listData;
  };

  return (
    // tree view

    <TreeView
      className={classes.tree}
      defaultExpanded={["1"]}
      defaultCollapseIcon={<ArrowDropDownIcon className={classes.Icons} />}
      defaultExpandIcon={
        <div className={classes.iconrelative}>
          <ArrowRightIcon className={classes.Icons} />
          <Box className={classes.iconabsolute} />
        </div>
      }
      defaultEndIcon={
        <div className={classes.iconrelative}>
          {props?.noIcon ? <Box width={20} /> : <InfoOutlinedIcon className={classes.rightIcons} />}
          <Box className={classes.iconabsolute} sx={{width: props?.noIcon && "45px !important"}} />
        </div>
      }
    >
      {render(props?.TreeJson)}
    </TreeView>
  );
};
