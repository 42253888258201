export const EntriesPath = [
  "image",
  "vendor_id",
  "vendor_type",
  "vendor_person_name",
  "reference_id",
  "requested_by",
  "unit",
  "checked_in_gate",
  "checked_in_by",
  "checked_in",
  "checked_out_by",
  "checked_out_gate",
  "checked_out",
  "status"

];

export const EntriesHeading = (t) => [
  { title: " ", field: "image" },
  { title: t("Vendor ID"), field: "vendor_id" },
  { title: t("Vendor Name"), field: "vendor_type" },
  { title: t("Vendor Person Name"), field: "vendor_person_name" },
  { title: t("Reference ID"), field: "reference_id" },
  // { title: "Requested By", field: "requested_by" },
  { title: t("Unit No"), field: "unit" },
  { title: t("Entry Gate"), field: "checked_in_gate" },
  // { title: "Checked In By", field: "check_in_by" },
  { title: t("Checked In"), field: "checked_in" },
  { title: t("Exit Gate"), field: "checked_out_gate" },
  // { title: "Checked Out By", field: "checked_out_by" },
  { title: t("Checked Out"), field: "checked_out" },
  { title: t("status"), field: "status" },

];
export const EntriesType = [
  { type: ["avatarmanagement"], name: "image" },
  { type: ["text"], name: "vendor_id" },
  { type: ["text"], name: "vendor_type" },
  { type: ["text"], name: "vendor_person_name" },
  { type: ["text"], name: "reference_id" },
  // { type: ["text"], name: "requested_by" },
  { type: ["text"], name: "unit" },
  { type: ["text"], name: "checked_in_gate" },
  // { type: ["text"], name: "checked_in_by" },
  { type: ["text"], name: "checked_in" },
  { type: ["text"], name: "checked_out_gate" },
  // { type: ["text"], name: "checked_out_by" },
  { type: ["text"], name: "checked_out" },
  { type: ["text"], name: "status" },

];
