export const WorkflowBuilder = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-2{fill:#c1c5cb}"}</style>
        </defs>
        <g id="Announcement" transform="translate(24817 -2950)">
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24817 2950)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
            <g
                id="workflow_Builder"
                data-name="workflow Builder"
                transform="translate(-24810 2957)"
            >
                <path
                    id="Path_96788"
                    data-name="Path 96788"
                    className="cls-2"
                    d="M157.094 0h-5.625a.469.469 0 0 0-.469.469v3.437a.469.469 0 0 0 .469.469h5.625a.469.469 0 0 0 .469-.469V.469a.469.469 0 0 0-.469-.469Zm0 0"
                    transform="translate(-146.281)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96789"
                    data-name="Path 96789"
                    className="cls-2"
                    d="M157.094 186h-5.625a.469.469 0 0 0-.469.469v3.438a.469.469 0 0 0 .469.469h5.625a.469.469 0 0 0 .469-.469v-3.437a.469.469 0 0 0-.469-.47Zm0 0"
                    transform="translate(-146.281 -180.188)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96790"
                    data-name="Path 96790"
                    className="cls-2"
                    d="M157.094 372h-5.625a.469.469 0 0 0-.469.469v3.438a.469.469 0 0 0 .469.469h5.625a.469.469 0 0 0 .469-.469v-3.437a.469.469 0 0 0-.469-.47Zm0 0"
                    transform="translate(-146.281 -360.375)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96791"
                    data-name="Path 96791"
                    className="cls-2"
                    d="M384.344 78h-1.875a.469.469 0 0 0 0 .938h1.875a.782.782 0 0 1 .781.781v1.875a.782.782 0 0 1-.781.781h-.744l.45-.45a.469.469 0 0 0-.663-.663l-1.25 1.25a.469.469 0 0 0 0 .663l1.25 1.25a.469.469 0 0 0 .663-.663l-.45-.45h.743a1.721 1.721 0 0 0 1.719-1.719v-1.874A1.721 1.721 0 0 0 384.344 78Zm0 0"
                    transform="translate(-370.062 -75.563)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96792"
                    data-name="Path 96792"
                    className="cls-2"
                    d="M2.675 267.263a.469.469 0 0 0-.663.663l.45.45h-.743a.782.782 0 0 1-.781-.781v-1.875a.782.782 0 0 1 .781-.781h1.875a.469.469 0 0 0 0-.937H1.719A1.721 1.721 0 0 0 0 265.719v1.875a1.721 1.721 0 0 0 1.719 1.719h.743l-.45.45a.469.469 0 1 0 .663.663l1.25-1.25a.469.469 0 0 0 0-.663Zm0 0"
                    transform="translate(0 -255.75)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
        </g>
    </svg>
)