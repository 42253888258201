import { Stack } from "@mui/material";
import { useContext } from "react";
import { SubscriptionsContext } from "./subscriptionsContext";
import { MiniDetailsCard } from "./components/miniDetailsCard";
import { ContactInfoCard } from "./components/contactInfoCard";

export const BasicInfo = () => {
    const {
        t = () => false,
        basicInfoClasses: classes,
        basic_info_state,
        getBasicInfo = () => false,
    } = useContext(SubscriptionsContext);

    return (
        <Stack className={classes.tab_content_div} direction="column" spacing={"20px"}>
            <MiniDetailsCard t={t} {...basic_info_state} getBasicInfo={getBasicInfo} />
            <ContactInfoCard t={t} {...basic_info_state} />
        </Stack>
    )
}