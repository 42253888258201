export const ParkingInvoice = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105160">
            <g
                data-name="Invoice Management - Parking Invoices"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 98187"
                    d="M19.856 19.735a.234.234 0 0 0 .234-.234V8.562a1.562 1.562 0 0 0-2.889-.822 1.561 1.561 0 0 0-2.651 0 1.561 1.561 0 0 0-2.655 0 1.562 1.562 0 0 0-2.89.822v12.876a1.562 1.562 0 0 0 2.89.822 1.561 1.561 0 0 0 2.655 0 1.561 1.561 0 0 0 2.655 0 1.562 1.562 0 0 0 2.89-.822v-1a.234.234 0 0 0-.469 0v1a1.093 1.093 0 1 1-2.187 0 .234.234 0 0 0-.469 0 1.093 1.093 0 1 1-2.187 0 .234.234 0 0 0-.469 0 1.093 1.093 0 1 1-2.187 0 .234.234 0 0 0-.469 0 1.093 1.093 0 1 1-2.187 0V8.562a1.093 1.093 0 1 1 2.187 0 .234.234 0 0 0 .469 0 1.093 1.093 0 1 1 2.187 0 .234.234 0 0 0 .469 0 1.093 1.093 0 1 1 2.187 0 .234.234 0 0 0 .469 0 1.093 1.093 0 1 1 2.187 0V19.5a.234.234 0 0 0 .23.235Z"
                />
                <path
                    data-name="Path 98188"
                    d="M10.518 11.486a.234.234 0 0 0-.234.234v2.842a.669.669 0 0 0 .668.668h.313a.669.669 0 0 0 .668-.668v-.695h.424a2.091 2.091 0 1 0 0-4.182h-1.241a.83.83 0 0 0-.365.081.82.82 0 0 0-.468.746v.272a.234.234 0 0 0 .469 0v-.272a.356.356 0 0 1 .2-.323.371.371 0 0 1 .161-.034h1.241a1.622 1.622 0 1 1 0 3.244h-.66a.234.234 0 0 0-.233.234v.928a.2.2 0 0 1-.2.2h-.307a.2.2 0 0 1-.2-.2v-2.84a.234.234 0 0 0-.236-.235Z"
                />
                <path
                    data-name="Path 98189"
                    d="M11.704 12.688h.652a.91.91 0 1 0 0-1.82h-.657a.234.234 0 0 0-.234.235v1.358a.234.234 0 0 0 .239.227Zm.653-1.354a.442.442 0 1 1 0 .883h-.42v-.884h.422Z"
                />
                <path
                    data-name="Path 98190"
                    d="M17.731 20.315a.234.234 0 0 0 .234-.234v-.219a1.063 1.063 0 0 0 .8-.854.923.923 0 0 0-.635-1.056 3 3 0 0 1-.769-.359.316.316 0 0 1-.083-.307.412.412 0 0 1 .275-.325.668.668 0 0 1 .622.1.234.234 0 0 0 .3-.36 1.123 1.123 0 0 0-.512-.227v-.18a.234.234 0 0 0-.469 0v.2l-.076.02a.876.876 0 0 0-.6.687.774.774 0 0 0 .254.763 3.152 3.152 0 0 0 .9.432.463.463 0 0 1 .329.536.581.581 0 0 1-.576.49.912.912 0 0 1-.649-.171.234.234 0 1 0-.257.392 1.276 1.276 0 0 0 .673.242v.2a.234.234 0 0 0 .239.23Z"
                />
                <path
                    data-name="Path 98191"
                    d="M18.573 10.539h-3.086a.234.234 0 0 0 0 .469h3.086a.234.234 0 0 0 0-.469Z"
                />
                <path
                    data-name="Path 98192"
                    d="M18.573 11.644h-3.086a.234.234 0 0 0 0 .469h3.086a.234.234 0 0 0 0-.469Z"
                />
                <path
                    data-name="Path 98193"
                    d="M18.573 12.751h-3.086a.234.234 0 0 0 0 .469h3.086a.234.234 0 0 0 0-.469Z"
                />
                <path
                    data-name="Path 98194"
                    d="M18.573 13.858h-3.086a.234.234 0 0 0 0 .469h3.086a.234.234 0 0 0 0-.469Z"
                />
                <path
                    data-name="Path 98195"
                    d="M15.611 17.458h-4.583a.234.234 0 0 0 0 .469h4.583a.234.234 0 0 0 0-.469Z"
                />
                <path
                    data-name="Path 98196"
                    d="M15.611 18.565h-4.583a.234.234 0 0 0 0 .469h4.583a.234.234 0 0 0 0-.469Z"
                />
            </g>
        </g>
    </svg>
)