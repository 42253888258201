import React from "react";
import { withNavBars } from "../../HOCs";
import UnitReadiness from "./unitReadiness";

class UnitReadinessParent extends React.Component {
  render() {
    return <UnitReadiness />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(UnitReadinessParent, props);