import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({

    assignInsBox: {
        height: "100%",
        border: "1px solid" + theme.palette.border.secondary,
        borderRadius: 4,
        background: theme.typography.color.white,
    },
    userName: {
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontWeight: "600"
    },
    discDiv: {
        width: 6,
        height: 6,
        background: theme.palette.border.primary,
        borderRadius: "50%",
        marginLeft: 6,
    },
    num: {
        color: theme.typography.color.secondary,
        fontSize: "0.75rem",

    },
    mail: {
        color: theme.typography.color.secondary,
        fontSize: "0.75rem",
        marginLeft: 6,
    },
    numEmail: {
        alignItems: "center",
        marginTop: 7,

    },
    userDiv: {
        justifyContent: "space-between"
    },
    filterIconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "4px",
        padding: "12px"
    },
}))


// complete inspection popup

export const usePopupStyles = makeStyles((theme) => ({
    CompletionBox: {
        border: "1px solid" + theme.palette.border.secondary,
        borderRadius: 4,
        width: 400,
        height: '100%',
        background: theme.typography.color.white,
        padding: 13
    },
    svgItem: {
        textAlign: "center",
        marginTop: 24

    },
    completionTxt: {
        textAlign: "center",
        color: theme.typography.color.primary,
        fontSize: "1rem",
        fontWeight: "600",
        marginTop: 13

    },
    processDetails: {
        textAlign: "center",
        color: theme.typography.color.tertiary,
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        margin: "9px 0"
    },
    truckPass: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
    },
    loginTxt: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
    },
    submitBtn: {
        width: "100%",
        boxShadow: "none",
        marginTop: "8px 0"

    },
    entryTxt: {
        margin: "18px 0 27px 0",
        justifyContent: "space-between"
    },
    passBox: {
        alignItems: "center"
    }
}))