import * as React from "react"
const RecoveredIcon = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width={32}
  height={32}
  data-name="Group 115654"
  {...props}
>
  <circle cx={16} cy={16} r={16} fill="#ffecec" data-name="Ellipse 129948" />
  <path
    fill="#ff4b4b"
    d="M9.906 8a.632.632 0 0 0-.643.631v13.053A2.325 2.325 0 0 0 11.579 24h8.842a2.325 2.325 0 0 0 2.316-2.316V8.631a.632.632 0 0 0-.888-.577l-1.651.734-1.858-.743a.632.632 0 0 0-.469 0L16 8.793l-1.871-.748a.632.632 0 0 0-.469 0l-1.858.743-1.651-.734A.632.632 0 0 0 9.906 8ZM12 13.053h8a.632.632 0 1 1 0 1.263h-8a.632.632 0 0 1 0-1.263Zm0 3.789h4.632a.632.632 0 0 1 0 1.263H12a.632.632 0 0 1 0-1.263Zm7.158 0H20a.632.632 0 1 1 0 1.263h-.842a.632.632 0 1 1 0-1.263ZM12 19.368h4.632a.632.632 0 0 1 0 1.263H12a.632.632 0 0 1 0-1.263Zm7.158 0H20a.632.632 0 1 1 0 1.263h-.842a.632.632 0 1 1 0-1.263Z"
    data-name="icons8-bill (1)"
  />
</svg>
)
export default RecoveredIcon
