import * as React from "react"
const AccountIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={38}
    height={38}
    data-name="Group 115982"
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={0.275}
        x2={0.74}
        y1={0.062}
        y2={0.966}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#5961c3" />
        <stop offset={1} stopColor="#3a41ac" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={0.321}
        x2={0.677}
        y1={0.058}
        y2={0.936}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#a6acfc" />
        <stop offset={0.287} stopColor="#a0a7fa" />
        <stop offset={0.651} stopColor="#9198f4" />
        <stop offset={1} stopColor="#7b83eb" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="e"
        x1={0.412}
        x2={0.548}
        y1={0.01}
        y2={0.999}
      />
      <radialGradient
        id="c"
        cx={0.5}
        cy={0.5}
        r={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="d"
        cx={0.495}
        cy={0.995}
        r={0.999}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0.177} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
    </defs>
    <path
      fill="#e6f2ff"
      d="M4.75 0h28.5A4.75 4.75 0 0 1 38 4.75v28.5A4.75 4.75 0 0 1 33.25 38H4.75A4.75 4.75 0 0 1 0 33.25V4.75A4.75 4.75 0 0 1 4.75 0Z"
      data-name="Path 100578"
    />
    <g data-name="icons8-test-account (1)" transform="translate(9 9)">
      <path
        fill="url(#b)"
        d="M24 14A10 10 0 1 1 14 4a10 10 0 0 1 10 10Z"
        data-name="Path 101025"
        transform="translate(-4 -4)"
      />
      <circle
        cx={4.75}
        cy={4.75}
        r={4.75}
        fill="url(#c)"
        data-name="Ellipse 130047"
        transform="translate(5.25 3.25)"
      />
      <path
        fill="url(#d)"
        d="M17.059 36.5A9.961 9.961 0 0 0 24 33.689a7.482 7.482 0 0 0-13.882 0 9.961 9.961 0 0 0 6.941 2.811Z"
        data-name="Path 101026"
        transform="translate(-7.059 -16.5)"
      />
      <circle
        cx={4}
        cy={4}
        r={4}
        fill="url(#a)"
        data-name="Ellipse 130048"
        transform="translate(6 3.5)"
      />
      <path
        fill="url(#e)"
        d="M17.451 37A9.951 9.951 0 0 0 24 34.546a6.991 6.991 0 0 0-13.1 0A9.951 9.951 0 0 0 17.451 37Z"
        data-name="Path 101027"
        transform="translate(-7.451 -17)"
      />
    </g>
  </svg>
)
export default AccountIcon
