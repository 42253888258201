import React from "react";

export const OppertunityIcon = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_243_2237)">
      <circle cx="25.3999" cy="21.9191" r="21" fill="#826BF9" />
    </g>
    <g clip-path="url(#clip0_243_2237)">
      <path
        d="M19.5311 15.5366L17.5425 13.548C17.2679 13.2734 16.8229 13.2734 16.5483 13.548C16.2736 13.8227 16.2736 14.2676 16.5483 14.5423L18.5368 16.5308C18.8114 16.8055 19.2564 16.8055 19.5311 16.5308C19.8057 16.2562 19.8057 15.8112 19.5311 15.5366Z"
        fill="white"
      />
      <path
        d="M16.5625 21.2969H13.7031C13.3145 21.2969 13 21.6114 13 22C13 22.3886 13.3145 22.7031 13.7031 22.7031H16.5625C16.9511 22.7031 17.2656 22.3886 17.2656 22C17.2656 21.6114 16.9511 21.2969 16.5625 21.2969Z"
        fill="white"
      />
      <path
        d="M36.2969 21.2969H33.4375C33.0489 21.2969 32.7344 21.6114 32.7344 22C32.7344 22.3886 33.0489 22.7031 33.4375 22.7031H36.2969C36.6855 22.7031 37 22.3886 37 22C37 21.6114 36.6855 21.2969 36.2969 21.2969Z"
        fill="white"
      />
      <path
        d="M33.452 13.548C33.1773 13.2734 32.7323 13.2734 32.4577 13.548L30.4692 15.5366C30.1945 15.8112 30.1945 16.2562 30.4692 16.5308C30.7438 16.8055 31.1888 16.8055 31.4634 16.5308L33.452 14.5423C33.7266 14.2676 33.7266 13.8227 33.452 13.548Z"
        fill="white"
      />
      <path
        d="M25 10C24.6114 10 24.2969 10.3145 24.2969 10.7031V13.5625C24.2969 13.9511 24.6114 14.2656 25 14.2656C25.3886 14.2656 25.7031 13.9511 25.7031 13.5625V10.7031C25.7031 10.3145 25.3886 10 25 10Z"
        fill="white"
      />
      <path
        d="M28.9376 17.0359C27.4048 15.8265 25.4219 15.3906 23.4813 15.8547C21.2313 16.375 19.4173 18.1609 18.8688 20.3969C18.3204 22.6468 18.9532 24.9249 20.5703 26.514C21.1469 27.0906 21.4844 27.9953 21.4844 28.9234V29.0781C21.4844 29.4718 21.7938 29.7812 22.1876 29.7812H27.8126C28.2064 29.7812 28.5157 29.4718 28.5157 29.0781V28.9234C28.5157 28.0093 28.8672 27.0765 29.486 26.4718C30.6673 25.2765 31.3282 23.6875 31.3282 22C31.3282 20.0594 30.4563 18.2453 28.9376 17.0359ZM25.0001 19.8906C23.9393 19.8906 23.1671 20.5943 22.9648 21.4088C22.8728 21.7793 22.5001 22.0157 22.1127 21.9224C21.7364 21.829 21.5064 21.4472 21.5998 21.071C21.9482 19.6647 23.269 18.4844 25.0001 18.4844C25.3887 18.4844 25.7032 18.7988 25.7032 19.1875C25.7032 19.5761 25.3887 19.8906 25.0001 19.8906Z"
        fill="white"
      />
      <path
        d="M22.1875 31.1875V31.8906C22.1875 33.0538 23.1337 34 24.2969 34H25.7031C26.8663 34 27.8125 33.0538 27.8125 31.8906V31.1875H22.1875Z"
        fill="white"
      />
    </g>
    <path d="M25.4001 24.8H24.6001V25.6H25.4001V24.8Z" fill="#826BF9" />
    <defs>
      <filter
        id="filter0_d_243_2237"
        x="0.399902"
        y="0.919067"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_243_2237"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_243_2237"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_243_2237">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(13 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
