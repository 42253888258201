import { Box, Typography, Stack, Popper, Grow, Paper, ClickAwayListener, MenuList } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Bold, SemiBold } from "../../utils";
import { TextBox } from "../textbox";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const useStyles = makeStyles((theme) => ({
  margin: {
    margin: "0px",
    height: "100%",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      paddingRight: "20px !important",
      // width: '115px'
    },
  },
  dial_code: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    marginLeft: "4px"
  },
  label: {
    color: "rgba(146, 151, 165, 1)",
    textTransform: "uppercase",
    margin: "0px 8px",
  },
  autocomplete: {
    // width: 150,
    "& fieldset": {
      border: "none",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      fontSize:"0.875rem",
      // padding: "0px 9px",
      padding: "0px",
      fontFamily: SemiBold
    },
    [theme.breakpoints.down('sm')]: {
      width: 80
    },
    [theme.breakpoints.only('md')]: {
      width: 80
    },
    [theme.breakpoints.only('sm')]: {
      width: 75
    },
    [theme.breakpoints.only('xs')]: {
      width: "100%"
    },
  },
  menu: {
    minHeight: 200,
    maxHeight: 200,
    "& .MuiPaper-root": {
      padding: "0px 8px 0px 8px"
    }
  },
  codeText: {
    fontSize:"0.875rem",
    fontFamily: Bold,
    width: 200
  },
  textCode: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    color: theme.typography.color.primary
  }
}));




export const SimpleSelect = ({
  placeholder = "",
  id = "",
  options = [],
  disabled = false,
  handleChangeSelect = () => false,
  value = "",
}) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState("")
  const [constructedOption, setConstructedOption] = React.useState(matchCountry(""))
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    if(!disabled){
    setOpen((prevOpen) => !prevOpen);
  }
  };

  const handleClose = () => {
    setSearch("")
    setConstructedOption(matchCountry(""))
    setOpen(false);
  };

  function matchCountry(args) {
    const escapedArgs = args.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedArgs, 'i');
    const matchedCountries = options?.filter(country => {
      return regex.test(country.name) || regex.test(country.code) || regex.test(country.dial_code);
    });

    // Sort based on exact matches by order
    const sortedCountries = matchedCountries.sort((a, b) => {
      const aNameExactMatch = a.name.toLowerCase() === args.toLowerCase();
      const bNameExactMatch = b.name.toLowerCase() === args.toLowerCase();

      if (aNameExactMatch && !bNameExactMatch) {
        return -1; // a comes before b
      } else if (!aNameExactMatch && bNameExactMatch) {
        return 1; // b comes before a
      }

      const aIndex = a.name.indexOf(args);
      const bIndex = b.name.indexOf(args);
      return aIndex - bIndex;
    });

    return sortedCountries;
  }

  const searchOption = (key) => {
    setConstructedOption(matchCountry(key))
  }
  return (
    <div className={classes.margin}>
      <Stack
        direction={"row"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        onClick={handleClick}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        ref={anchorRef}
        sx={{cursor:"pointer"}}>
        <Typography className={classes.textCode}>{value}</Typography>
        {open ? <KeyboardArrowUpIcon className={classes.textCode} /> : <KeyboardArrowDownIcon className={classes.textCode} />}
      </Stack>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        style={{
          position: "relative",
          zIndex: 1000000
        }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <>
            <Grow
              {...TransitionProps}
              style={{
                marginBottom: placement === 'top-start' ? "15px" : "0px",
                marginTop: placement === 'bottom-start' ? "15px" : "0px",
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                padding: "0px 0px 0px 8px"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  // onKeyDown={handleListKeyDown}
                  >
                    <Box sx={{ width: "228px" }}>
                      <TextBox
                        onChange={(value) => {
                          setSearch(value?.target?.value)
                          searchOption(value?.target?.value)
                        }}
                        value={search}
                        label={null}
                        placeholder={"Search"}
                        height={"40px"}
                        padding={"8px"}
                        autocomplete={true}
                      />
                    </Box>
                    <Stack sx={{ height: "115px", overflow: "auto", width: "100%", paddingTop: "8px" }} spacing={"6px"}>
                      {
                        constructedOption?.map((e) => {
                          return (
                            <Stack direction={"row"} spacing={1}
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                handleChangeSelect(e?.dial_code, e?.image)
                                handleClose()
                              }}>
                              <img src={e?.image} style={{ height: "24px", width: "24px" }} alt='flag' loading="lazy" />
                              <Typography className={classes.codeText} noWrap>{e?.dial_code} {e?.name}</Typography>
                            </Stack>

                          )
                        })
                      }
                    </Stack>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          </>
        )}
      </Popper>


    </div>
  );
};
