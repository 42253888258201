
export const returnHeadingType = {
  'Common Banner': {
    heading: [
      { title: "Sno", field: "index", type: "numeric" },
      { title: "Updated by", field: "updatedby", },
      { title: "Updated at", field: "updated_at", },
      { title: "Company", field: "company", },
      { title: "Count", field: "count", },
      { title: "", field: "icon", },
    ],
    type: [
      { type: ["text"], name: "index" },
      { type: ["text"], name: "updatedby" },
      { type: ["text"], name: "updated_at" },
      { type: ["text"], name: "company" },
      { type: ["text"], name: "count" },
      { type: ["more_5"], icon: "icon", is_active: "is_active" },
    ]
  }
}