export const LeadPath = ["lead_no", "created_at", "subject", "unit_usage", "lead_source", "urgent", "owner", "status"]

export const LeadHeading = (t) => [
    { title: t("Opportunity No"), field: "lead_no" },
    { title: t("Date"), field: "created_at" },
    { title: t("Description"), field: "subject" },
    { title: t("Purpose"), field: "unit_usage" },
    { title: t("Source"), field: "lead_source" },
    { title: t("Priority"), field: "urgent" },
    { title: t("Owner"), field: "owner" },
    { title: t("Status"), field: "status" },
]

export const LeadType = [
    { type: ["text"], name: "lead_no" },
    { type: ["text"], name: "created_at" },
    { type: ["quill"], name: "subject" },
    { type: ["text"], name: "unit_usage" },
    { type: ["text"], name: "lead_source" },
    { type: ["text"], name: "urgent" },
    { type: ["text"], name: "owner" },
    { type: ["status"], name: "status" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const StatusOptionListLang = (t) => [
    { label: t('Active'), value: true },
    { label: t('Inactive'), value: false },
]