import React from "react";
import { withNavBars } from "../../HOCs";
import { ConfigurartionNew } from "./configurartion";

class ConfigurartionParent extends React.Component {
    render() {
        return <ConfigurartionNew />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ConfigurartionParent, props);
