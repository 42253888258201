import React from "react";
import { withNavBars } from "../../HOCs";
import { ResidentTable } from "./residentTable";

class ResidentTableParent extends React.Component {
    render() {
        return <ResidentTable />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ResidentTableParent, props);
