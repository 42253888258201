import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../../utils";

export const InspectionMappingComponentStyles = makeStyles((theme) => ({
    filterIconBox: {
        textAlign: "end",
        marginInlineEnd: "0px !important"
    },
    pdImage: {
        height: 140,
        width: 140,
        borderRadius: "50%"
    },
    imageBadge: {
        '& .MuiBadge-badge': {
            marginLeft: "68px",
            backgroundColor: theme.palette.secondary.main,
            color: theme.typography.color.white,
            whiteSpace: "nowrap",
            borderRadius: "4px"
        }
    },
    contentTitle: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: Bold,
        textTransform: "upperCase"
    },
    propertyTitle: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        marginBottom: "5px",
        marginLeft: "12px"
    },
    propertyTitleCard: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        marginTop: "12px",
        marginBottom: "4px"
    },
    propertyContent: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.typography.color.primary
    },
    dividerStyle: {
        height: "40px"
    },
    inspectionItemList: {
        borderRight: `1px solid ${theme.palette.border.secondary}`,
    },
    tabTitle: {
        fontSize:"0.875rem",
        fontFamily: Bold,
    },
    cartItem: {
        padding: "15px"
    },
    cartTitle: {
        fontSize:"1rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    delete: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.palette.error.main,
        cursor: "pointer"
    },
    title: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.tertiary,
        textTransform: "upperCase",
        padding: "0px 15px",
        marginTop: "20px"
    },
    templateList: {
        backgroundColor: theme.typography.color.white,
        border: `1px solid ${theme.palette.border.secondary}`,
        margin: "15px 20px",
        borderRadius: theme.palette.borderRadius
    },
    templateName: {
        fontWeight: "bold",
        fontSize:"1rem",
    },
    templateItem: {
        fontWeight: "bold",
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    itemList: {
        fontWeight: "bold",
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        margin: 12
    },
    list: {
        overflow: "auto",
        height: "320px"
    },
    avatar: {
        background: "transparent"
    },
    inspectionName: {
        color: theme.typography.color.secondary,
        fontSize:"0.875rem",
        fontWeight: "bold"
    },
    inspectionQty: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontWeight: "bold"
    },
    editbtn: {
        width: "100%",
        '&:hover': {
            background: "transparent"
        }
    },
    closeIcon: {
        color: theme.typography.color.icon,
        cursor: "pointer"
    },
    templateimage: {
        width: "40px",
        height: "40px",
        borderRadius: "4px",
        marginRight: "16px",
    },
    remove: {
        color: theme.palette.error.main,
        fontSize:"0.75rem",
    },
    border: {
        borderRight: `1px solid ${theme.palette.border.secondary}`,
    },
    filterIconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "4px",
        padding: "12px"
    },
    tab: {
        background: '#fff',
        '&.MuiTabPanel-root': {
            padding: '0px !important',
        },
    },


}))