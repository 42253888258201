// Autocomplete.js
import React, { Component } from 'react';
import styled from 'styled-components';
import './style.css';
import { FullScreenIcon } from '../../assets';

const Wrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align:center;
  display:flex;
`;

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.clearSearchBox = this?.clearSearchBox.bind(this);
    }

    componentDidMount({ map, mapApi } = this?.props) {

        // const options = {
        //     // restrict your search to a specific type of result
        //     // types: ['establishment'],
        //     // restrict your search to a specific country, or an array of countries
        //     // componentRestrictions: { country: ['in'] },
        // };
        this.autoComplete = new mapApi.places.Autocomplete(

            this.searchInput,
            // options,
        );
        this?.autoComplete?.addListener('place_changed', this.onPlaceChanged);
        this.autoComplete.bindTo('bounds', map);

    }

    componentWillUnmount({ mapApi } = this.props) {
        mapApi.event.clearInstanceListeners(this.searchInput);
    }

    onPlaceChanged = ({ map, addplace } = this.props) => {
        const place = this.autoComplete.getPlace();
        if (!place.geometry) return;
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        addplace(place);
        this.searchInput.blur();
    };

    clearSearchBox() {
        this.searchInput.value = '';
    }

    render() {
        return (
            <Wrapper >
                <div style={{ display: !this.props.fullScreenMap ? "" : "none" }}>
                    <input
                        className="search-input"
                        style={{
                            padding: "12px",
                            // height:"30px",
                            borderRadius: "6px",
                            border: "1px solid #CED3DD",
                            width: "100%"
                        }}
                        ref={(ref) => {
                            this.searchInput = ref;
                        }}
                        type="text"
                        // onFocus={this.clearSearchBox}
                        placeholder="Enter a location"
                    />


                </div>
                {!this.props.fullScreenMap &&
                    <div className='icon-wrapper' onClick={this.props.fullScreenControl ?? null}>
                        <FullScreenIcon />
                    </div>
                }
            </Wrapper>
        );
    }
}

export default AutoComplete;