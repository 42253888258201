export const TransferOwnership = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 102662">
            <path data-name="Rectangle 19" fill="none" d="M0 0h30v30H0z" />
            <g data-name="transfer (2)" fill="#c1c5cb">
                <path
                    data-name="Path 1"
                    d="M9.971 15.943a.063.063 0 0 0-.064-.064 5.43 5.43 0 0 0-.321-1.093.739.739 0 0 0-.579-.386H2.964a.618.618 0 0 0-.579.386A3.751 3.751 0 0 0 2 16.457 4.02 4.02 0 0 0 3.864 19.8c.064.064.129.129.129.193v1.35a.607.607 0 0 0 .643.643h3.278a.607.607 0 0 0 .643-.643v-.9h.193a1.127 1.127 0 0 0 1.093-1.093v-1.093l.257-.193a1.042 1.042 0 0 0 .193-1.479Z"
                    transform="translate(4 -.936)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 2"
                    d="M24.862 14.786a.739.739 0 0 0-.579-.386h-6.042a.618.618 0 0 0-.579.386 3.083 3.083 0 0 0-.321 1.093.063.063 0 0 1-.064.064l-.386.579A1.047 1.047 0 0 0 17.148 18l.257.193v1.093a1.127 1.127 0 0 0 1.095 1.093h.193v.9a.607.607 0 0 0 .643.643h3.279a.607.607 0 0 0 .643-.643v-1.35c0-.064.064-.193.129-.193a3.937 3.937 0 0 0 1.864-3.343 3.485 3.485 0 0 0-.389-1.607Z"
                    transform="translate(-1.248 -.936)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 3"
                    d="M6.907 10.721a.607.607 0 0 0 .643-.643c0-1.607 1.929-2.893 4.243-2.893a4.474 4.474 0 0 1 3.986 1.929l-1.029-.321a.651.651 0 1 0-.45 1.221l2.121.707a.582.582 0 0 0 .643-.193l1.35-1.479a.625.625 0 0 0-.064-.9c-.064-.257-.514-.257-.771 0l-.579.58C16.293 7.057 14.171 5.9 11.729 5.9 8.707 5.9 6.2 7.764 6.2 10.079a.818.818 0 0 0 .707.642Z"
                    transform="translate(2.5 2.1)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
        </g>
    </svg>
)