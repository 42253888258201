export const Agreements = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98337">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g fill={props?.fill ?? "#c1c5cb"}>
                    <path
                        data-name="Path 94691"
                        d="M12.48 20.692a.288.288 0 0 0-.288-.288h-.1a.277.277 0 0 0-.171.056l-.772.579a.293.293 0 0 0-.115.231.288.288 0 0 0 .288.288h.1a.277.277 0 0 0 .171-.056l.168-.126a.855.855 0 0 1 .2-.222l.506-.378a.286.286 0 0 0 .012-.083Z"
                    />
                    <path data-name="Rectangle 52121" d="M7 15.5h1.731v4.615H7z" />
                    <path
                        data-name="Path 94692"
                        d="M11.616 19.827a.288.288 0 0 0-.289-.288h-.1a.278.278 0 0 0-.171.056l-.772.577a.294.294 0 0 0-.115.231.288.288 0 0 0 .288.288h.1a.278.278 0 0 0 .171-.056l.772-.577a.3.3 0 0 0 .116-.231Z"
                    />
                    <path
                        data-name="Path 94693"
                        d="M11.487 15.386a1.644 1.644 0 0 1 .74-.175h.816a2.581 2.581 0 0 1 1.727.659l.046.041.721-.542a2.214 2.214 0 0 1 1.342-.447h1.194a2.228 2.228 0 0 1 1.619.7V8h-9.23v7.5h.542a1.07 1.07 0 0 0 .483-.114Zm3.59-6.809a2.885 2.885 0 1 1-2.885 2.885 2.885 2.885 0 0 1 2.885-2.885Z"
                    />
                    <path
                        data-name="Path 94694"
                        d="M15.077 13.769a2.308 2.308 0 1 0-2.308-2.307 2.308 2.308 0 0 0 2.308 2.307Zm-1.269-2.538.954.715 1.554-1.554.408.408-1.731 1.731a.288.288 0 0 1-.377.027l-1.154-.865Z"
                    />
                    <path
                        data-name="Path 94695"
                        d="M13.582 21.434a.285.285 0 0 0 .052-.164.288.288 0 0 0-.288-.289h-.1a.28.28 0 0 0-.164.051l-.012.01-.164.123a.851.851 0 0 1-.2.221l-.506.379a.283.283 0 0 0-.014.083.288.288 0 0 0 .288.288h.1a.278.278 0 0 0 .171-.056l.772-.579a.333.333 0 0 0 .065-.067Z"
                    />
                    <path
                        data-name="Path 94696"
                        d="M19.453 16.237a1.654 1.654 0 0 0-1.379-.737H16.88a1.644 1.644 0 0 0-1 .331L14.327 17a.293.293 0 0 0-.115.231.288.288 0 0 0 .288.288h.153a.285.285 0 0 0 .128-.029l1.033-.519a.288.288 0 0 1 .324.046l3.158 2.9.022.019.115-.234a.288.288 0 0 1 .26-.16h1.154v-3.177h-1.154a.288.288 0 0 1-.24-.128Z"
                    />
                    <path
                        data-name="Rectangle 52122"
                        d="M21.423 15.788h1.731v4.615h-1.731z"
                    />
                    <path
                        data-name="Path 94697"
                        d="m19.041 20.463-.005-.005-.171-.157-.29-.26-2.731-2.44-.805.4a.858.858 0 0 1-.386.091H14.5a.865.865 0 0 1-.519-1.558l.364-.273a2 2 0 0 0-1.3-.477h-.816a1.07 1.07 0 0 0-.483.114 1.644 1.644 0 0 1-.74.175H9.308v3.465h.865l.539-.4a.847.847 0 0 1 .519-.173h.1a.865.865 0 0 1 .865.865.865.865 0 0 1 .823.611.878.878 0 0 1 .236-.034h.1a.865.865 0 0 1 .865.865v.025a.876.876 0 0 1 .2-.025h.1a.857.857 0 0 1 .391.1l-.868-.744a.289.289 0 1 1 .372-.441l1.523 1.269a.823.823 0 0 1 .158.173l.179.152a.286.286 0 0 0 .188.07h.075a.288.288 0 0 0 .288-.288.3.3 0 0 0-.029-.142l-1.892-1.66a.289.289 0 1 1 .378-.436l1.817 1.558a.8.8 0 0 1 .144.163l.182.158a.308.308 0 0 0 .193.072h.072a.278.278 0 0 0 .2-.083.3.3 0 0 0 .058-.076l-2.487-2.229a.289.289 0 1 1 .385-.43l2.6 2.308a.29.29 0 0 1 .068.091l.058.053a.287.287 0 0 0 .192.072h.066a.288.288 0 0 0 .288-.288v-.04a.288.288 0 0 0-.067-.181Z"
                    />
                    <path
                        data-name="Path 94698"
                        d="M14.5 21.846h-.1a.277.277 0 0 0-.171.056l-.772.579a.293.293 0 0 0-.115.231.288.288 0 0 0 .288.288h.1a.277.277 0 0 0 .171-.056l.772-.579a.259.259 0 0 1 .023-.018.288.288 0 0 0-.2-.5Z"
                    />
                    <path
                        data-name="Path 94699"
                        d="M15.205 21.636a.839.839 0 0 1 .107.787h.053a.288.288 0 0 0 .288-.288.317.317 0 0 0-.024-.134Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
