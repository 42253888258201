import React from "react"

export const InvoiceNo = () =>(
    <svg id="Group_98671" data-name="Group 98671" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <g id="Group_95678" data-name="Group 95678">
    <rect id="Rectangle_49614" data-name="Rectangle 49614" width="40" height="40" rx="20" fill="#bdeff2"/>
  </g>
  <g id="icons8-ticket" transform="translate(13.234 11.925)">
    <path id="Path_2698" data-name="Path 2698" d="M5.888,7.819h8.3" transform="translate(-2.977 1.4)" fill="#4ac6cc"/>
    <path id="Path_2699" data-name="Path 2699" d="M5.888,7.819h8.3" transform="translate(-2.977 4.166)" fill="#4ac6cc"/>
    <path id="Path_2697" data-name="Path 2697" d="M5.888,7.819h8.3" transform="translate(-3.08 -1.901)" fill="#4ac6cc"/>
    <path id="icons8-bill_1_" data-name="icons8-bill (1)" d="M8.667,5A.655.655,0,0,0,8,5.655V19.192a2.412,2.412,0,0,0,2.4,2.4h9.171a2.412,2.412,0,0,0,2.4-2.4V5.655a.655.655,0,0,0-.921-.6l-1.713.762-1.927-.771a.655.655,0,0,0-.486,0l-1.94.776-1.94-.776a.655.655,0,0,0-.486,0l-1.927.771L8.921,5.056A.655.655,0,0,0,8.667,5Zm2.172,5.24h8.3a.655.655,0,1,1,0,1.31h-8.3a.655.655,0,1,1,0-1.31Zm0,3.93h4.8a.655.655,0,0,1,0,1.31h-4.8a.655.655,0,0,1,0-1.31Zm7.424,0h.873a.655.655,0,0,1,0,1.31h-.873a.655.655,0,0,1,0-1.31Zm-7.424,2.62h4.8a.655.655,0,0,1,0,1.31h-4.8a.655.655,0,1,1,0-1.31Zm7.424,0h.873a.655.655,0,1,1,0,1.31h-.873a.655.655,0,1,1,0-1.31Z" transform="translate(-8 -5)" fill="#4ac6cc"/>
  </g>
</svg>

)