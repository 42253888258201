import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import MoveoutInspection from './moveoutInspection'

class MoveoutInspectionParent extends Component {
    render() {
        return (
            <div>
                <MoveoutInspection />
            </div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(MoveoutInspectionParent, props) 