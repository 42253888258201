import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const dashboardStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 24px 80px",
    backgroundColor: theme.palette.background.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "10px 10px 80px",
    },
  },
  tabtitle: {
    fontSize:"0.75rem",
    color: "#4E5A6B",
    fontFamily: Bold,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  tabtitle1: {
    fontSize:"0.75rem",
    color: "#5078E1",
    fontFamily: Bold,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  selectBack1: {
    backgroundColor: "#F1F7FF",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px"
  },
  selectBack: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  loader:{
    height:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  }
}));
