import React from "react";
import AccountDetails from "../accountDetails";
import { useLocation } from "react-router-dom";

const VendorAccountDetailsParent = () => {
    const state = useLocation()?.state
    return <AccountDetails accid={state?.id}/>;
}

export default VendorAccountDetailsParent

