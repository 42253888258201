import { Avatar, Box, Stack, Typography, Divider, Grid, IconButton, Button } from '@mui/material'
import React from 'react'
import { ProfileCardStyle } from './style'
import { withNamespaces } from "react-i18next";
import { AlertProps, NetWorkCallMethods, ValidateEmail, stringAvatar } from '../../../utils';
import EditIMG from '../../../assets/editimage';
import { TextBox } from '../../../components';
import { NetworkCall } from '../../../networkcall';
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import moment from 'moment';

const ProfileCard = ({ t, data, setSelected, reload = () => false }) => {
    const classes = ProfileCardStyle()
    const alert = React.useContext(AlertContext)

    const [isEdit, setIsEdit] = React.useState({
        bool: false,
        value: data?.email_id,
        error: false,
        errMSg: "",
        disable: false
    })

    const updateState = (key, value) => {
        setIsEdit({
            ...isEdit,
            [key]: value
        })
    }

    const onSubmit = () => {

        if (ValidateEmail(isEdit?.value)) {
            updateState("diable", true)
            const payload = {
                email_id: isEdit?.value,
                user_profile_id: data?.user_profile_id,
                contact_id: data?.id,
                xEmail: data?.email_id
            }


            NetworkCall(
                `${config.api_url}/invite/update_email`,
                NetWorkCallMethods.post,
                payload, null, true, false
            ).then((res) => {
                if (res?.data?.data?.type === "success") {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: t("Email Successfully Edited"),
                    })
                    setIsEdit({
                        ...isEdit,
                        disable: false,
                        bool: false
                    })
                    setSelected({
                        ...data,
                        email_id: isEdit?.value
                    })

                    reload()
                } else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: res?.data?.data?.msg,
                    })
                    setIsEdit({
                        ...isEdit,
                        disable: false,
                        bool: false,
                        value: data?.email_id
                    })
                }

            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            })

        } else {
            setIsEdit({
                ...isEdit,
                errMSg: t("InValid Email Id"),
                error: true
            })
        }

    }

    React.useEffect(() => {
        setIsEdit({
            ...isEdit,
            value: data?.email_id,
            bool: false
        })
        // eslint-disable-next-line
    }, [data?.email_id])

    return (
        <Box className={classes.box} p={2}>
            <Stack direction={"row"} spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
                className={classes.stack}>
                <Avatar className={classes.avatar} alt='profileImg' src={data?.image_url}  {...stringAvatar(data?.name)}></Avatar>
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Typography className={classes.title}>{t("profiledetail")}</Typography>
                    <Grid container spacing={1}>
                        <Grid item md={6} sm={12} lg={3}>


                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography className={classes.subtitle}>{t("Name")}</Typography>
                                <Typography className={classes.content}>{data?.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={6} sm={12} lg={4}>
                            <Box display={"flex"} flexDirection={"column"} marginTop="2px">


                                <Typography className={classes.subtitle}>{t("EmailId")}</Typography>

                                {
                                    isEdit?.bool ?
                                        <Stack display={"flex"} direction={"row"} spacing={1}>
                                            <TextBox
                                                label={null}


                                                placeholder={t("Enter Email")}
                                                value={isEdit?.value}
                                                isError={isEdit?.error}
                                                onChange={(e) => updateState("value", e.target.value.trim(' '))}
                                                errorMessage={isEdit?.errMSg}
                                            />

                                            <Button sx={{ height: "40px", marginInlineStart: "8px" }} variant='contained' disabled={isEdit?.value === 0 || isEdit?.disable} onClick={onSubmit}>{t("Save")}</Button>
                                        </Stack >
                                        :
                                        <Stack display={"flex"} direction={"row"} spacing={1}>
                                            <Typography className={classes.content}>{isEdit?.value}</Typography>

                                            <IconButton onClick={() => updateState("bool", true)}>
                                                <EditIMG />
                                            </IconButton >
                                        </Stack >

                                }




                            </Box >
                        </Grid >
                        <Grid item md={6} sm={12} lg={4}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography className={classes.subtitle}>{t("MobileNumber")}</Typography>
                                <Typography className={classes.content}> {data?.mobile}</Typography>


                            </Box>
                        </Grid>
                        <Grid item md={6} sm={12} lg={4}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography className={classes.subtitle}>{t("LastLogin")}</Typography>
                                <Typography className={classes.content}> {data?.last_logged_in ? moment(data?.last_logged_in).format("DD MMM YYYY"):"-"}</Typography>


                            </Box>
                        </Grid>
                    </Grid>
                </Stack >
            </Stack >
        </Box >
    )
}

export default withNamespaces("inviteTrigger")(ProfileCard) 