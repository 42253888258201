export const DomesticContacts = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98402">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g fill={props?.fill ?? "#c1c5cb"}>
                    <path
                        data-name="Path 94785"
                        d="M13.494 9.76a.44.44 0 0 0 .44-.44V7.437a.44.44 0 0 0-.879 0V9.32a.439.439 0 0 0 .439.44Z"
                    />
                    <path
                        data-name="Path 94786"
                        d="M11.3 9.632a.44.44 0 1 0 .622-.622l-.471-.471a.44.44 0 0 0-.622.622Z"
                    />
                    <path
                        data-name="Path 94787"
                        d="m15.688 9.632.471-.471a.44.44 0 1 0-.622-.622l-.471.471a.44.44 0 0 0 .622.622Z"
                    />
                    <path
                        data-name="Path 94788"
                        d="M18.8 13.711a.643.643 0 0 0-.909-.909l-1.53 1.531a.643.643 0 0 0 .909.909l1.53-1.53Z"
                    />
                    <path
                        data-name="Path 94789"
                        d="m18.8 16.772 1.148-1.148a.643.643 0 1 0-.909-.909l-1.148 1.148a.643.643 0 1 0 .909.909Z"
                    />
                    <path
                        data-name="Path 94790"
                        d="M21.095 16.628a.643.643 0 0 0-.909 0l-.765.765a.643.643 0 0 0 .909.909l.765-.765a.643.643 0 0 0 0-.909Z"
                    />
                    <path
                        data-name="Path 94791"
                        d="M18.359 17.848v-.009a1.52 1.52 0 0 1-1.53-1.531 1.521 1.521 0 0 1-1.086-2.6l4.209-4.209a.643.643 0 1 0-.909-.909l-5.428 5.428.072.072a3.149 3.149 0 0 1 0 4.448.44.44 0 0 1-.622-.622 2.269 2.269 0 0 0 0-3.2l-.383-.383a.439.439 0 0 1-.117-.211l-.5-2.179a1.151 1.151 0 0 0-1.517-.821.44.44 0 0 0-.288.413l.006 6.219-1.13 1.13a.439.439 0 0 0 0 .622l2.368 2.368a.439.439 0 0 0 .311.129h4.336a1.176 1.176 0 0 0 .837-.347l2.371-2.378a1.524 1.524 0 0 1-1-1.43Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
