import * as React from "react"

const CloseIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={20}
        height={20}
        {...props}
    >
        <path
            d="M4.707 3.293 3.293 4.707 10.586 12l-7.293 7.293 1.414 1.414L12 13.414l7.293 7.293 1.414-1.414L13.414 12l7.293-7.293-1.414-1.414L12 10.586 4.707 3.293z"
            style={{
                fill: props?.fill ?? "#7C8594",
            }}
        />
    </svg>
)

export default CloseIcon
