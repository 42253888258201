import React from "react";
import { withNavBars } from "../../HOCs";
import { UploadApi } from "./upload";

class UploadParent extends React.Component {
    render() {
        return <UploadApi />
    }
}

export default withNavBars(UploadParent);
