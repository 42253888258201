import * as React from "react"
const TotalBeforeAmount = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <g data-name="Group 115513">
      <path
        fill="#ffab00"
        d="M25 14A11 11 0 1 1 14 3a11 11 0 0 1 11 11Z"
        data-name="Path 100907"
      />
      <path
        fill="#ffcc80"
        d="M14 5.2a8.8 8.8 0 1 0 8.8 8.8A8.791 8.791 0 0 0 14 5.2Z"
        data-name="Path 100908"
      />
      <path
        fill="#ffab00"
        d="M14 7.4c.162 0 .321.013.48.024l.112-1.124h-1.3l.113 1.13A6.87 6.87 0 0 1 14 7.4Zm-3.6 1.073-.6-.928c-.355.237-.711.533-1.007.77l.763.817a6.621 6.621 0 0 1 .844-.659Zm1.99-.866-.29-1.07a6.949 6.949 0 0 0-1.185.415l.443 1a6.592 6.592 0 0 1 1.028-.346ZM8.8 9.938l-.9-.677c-.237.355-.474.711-.711 1.007l1.018.565a6.627 6.627 0 0 1 .593-.895Zm9.678-.779.733-.845a6.483 6.483 0 0 0-1.007-.77l-.6.928a6.656 6.656 0 0 1 .876.687ZM7.766 11.845l-1.052-.332a9.443 9.443 0 0 0-.3 1.244l1.077.162a6.61 6.61 0 0 1 .275-1.074Zm12.04-.991.947-.526a2.576 2.576 0 0 0-.711-1.007l-.836.627a6.527 6.527 0 0 1 .599.905Zm.69 2.006 1.086-.163a6.17 6.17 0 0 0-.355-1.3l-1.02.376a6.552 6.552 0 0 1 .293 1.087Zm-3.859-4.91.443-1a7 7 0 0 0-1.185-.415l-.282 1.069a6.553 6.553 0 0 1 1.024.345Zm1.813 10.919.763.817a11.94 11.94 0 0 0 .829-.948l-.865-.648a6.633 6.633 0 0 1-.728.779Zm1.356-1.722.947.526a2.873 2.873 0 0 0 .474-1.244l-.979-.309a6.578 6.578 0 0 1-.442 1.027Zm-3.169 2.9.443 1a6.732 6.732 0 0 0 1.125-.592l-.6-.928a6.532 6.532 0 0 1-.968.524ZM20.6 14a6.609 6.609 0 0 1-.1 1.076l1.081.224A8.844 8.844 0 0 0 21.7 14Zm-6.061 6.573.113 1.127a9.174 9.174 0 0 0 1.244-.237l-.282-1.069a6.6 6.6 0 0 1-1.075.179ZM10.4 19.527l-.6.928a6.732 6.732 0 0 0 1.125.592l.443-1a6.532 6.532 0 0 1-.968-.52Zm-2.647-3.42-1.033.381c.178.415.355.829.533 1.185l.935-.55a6.565 6.565 0 0 1-.44-1.016ZM8.8 18.062l-.9.677c.3.355.592.652.888.948l.733-.845a6.651 6.651 0 0 1-.721-.78Zm3.6 2.338-.3 1.125 1.244.178.118-1.125h-.012a6.581 6.581 0 0 1-1.05-.178Zm-5-6.4H6.3a8.1 8.1 0 0 0 .118 1.244l1.077-.162A6.672 6.672 0 0 1 7.4 14Z"
        data-name="Path 100909"
      />
      <g data-name="Group 115512">
        <path
          fill="#ffab00"
          d="M15.1 10.15v7.7h-1.65v-5.66l-1.65.523v-1.375l3.146-1.188Z"
          data-name="Path 100910"
        />
      </g>
    </g>
  </svg>
)
export default TotalBeforeAmount
