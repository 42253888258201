export const BlockType = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98383">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g fill={props?.fill ?? "#c1c5cb"}>
                    <path
                        data-name="Path 94781"
                        d="m22.849 9.769-1.02-1.531A.535.535 0 0 0 21.383 8H9.606a.535.535 0 0 0-.445.239L8.09 9.845a.536.536 0 0 0 .445.833h13.929a.536.536 0 0 0 .384-.909Z"
                    />
                    <path
                        data-name="Path 94782"
                        d="M22.464 14.964H8.536A.536.536 0 0 0 8 15.5v1.607a.536.536 0 0 0 .536.536h13.928a.536.536 0 0 0 .536-.536V15.5a.536.536 0 0 0-.536-.536Z"
                    />
                    <path
                        data-name="Path 94783"
                        d="M8.536 18.178v4.286a.536.536 0 0 0 .536.536h1.071v-3.215a.536.536 0 0 1 .536-.536h1.071a.536.536 0 0 1 .536.536v3.214h2.143v-3.214a.536.536 0 0 1 .536-.536h1.071a.536.536 0 0 1 .536.536v3.214h2.143v-3.214a.536.536 0 0 1 .536-.536h1.071a.536.536 0 0 1 .536.536v3.214h1.071a.536.536 0 0 0 .536-.536v-4.285Z"
                    />
                    <path
                        data-name="Path 94784"
                        d="M8.536 11.214v3.214h1.607v-1.607a.536.536 0 0 1 .536-.536h1.071a.536.536 0 0 1 .536.536v1.607h2.143v-1.607a.536.536 0 0 1 .536-.536h1.071a.536.536 0 0 1 .536.536v1.607h2.143v-1.607a.536.536 0 0 1 .536-.536h1.071a.536.536 0 0 1 .536.536v1.607h1.607v-3.214Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
