export const VisitorWalkIn = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105199">
            <g data-name="VS - Security Services - Visitor Tracking">
                <g data-name="Group 105100" fill="#010002">
                    <path
                        data-name="Path 98374"
                        d="M44.384 0v2.451h.994V0ZM39.73 2.338l1.735 1.734.7-.7-1.732-1.738Zm7.865 1.032.7.7 1.734-1.734c-.246-.246-.457-.455-.7-.7Zm1.622 2.917v1h2.453v-1Zm-11.122 0v.995h2.453v-1Z"
                        transform="translate(-30.094 7)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        data-name="Path 98375"
                        d="M143.722 324.748h-4.376a1.523 1.523 0 0 0-1.423 1.6v4.065h7.222v-4.065a1.523 1.523 0 0 0-1.423-1.6Z"
                        transform="translate(-126.745 -307.411)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        data-name="Path 98376"
                        d="M176.221 152.25a2.471 2.471 0 1 0-2.468-2.47 2.47 2.47 0 0 0 2.468 2.47Z"
                        transform="translate(-161.435 -135.618)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                </g>
            </g>
        </g>
    </svg>
)