import React from "react"

export const TemplateIcon = () =>{
    return(
        <svg id="Group_98459" data-name="Group 98459" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_98363" data-name="Group 98363">
    <path id="icons8-plus-math_1_" data-name="icons8-plus-math (1)" d="M13,10.023l-2.411-.613L10.014,7H9.955L9.411,9.411,7,10.023V10.1l2.411.491L9.95,13h.068l.571-2.411L13,10.1Z" transform="translate(-7 -7)" fill="#cbbab7"/>
    <path id="icons8-plus-math_1_2" data-name="icons8-plus-math (1)" d="M12,9.52,9.991,9.009,9.511,7H9.463L9.009,9.009,7,9.52v.063l2.009.409L9.458,12h.057l.476-2.009L12,9.587Z" transform="translate(20 20)" fill="#cbbab7"/>
    <g id="icons8-separate-by-using-blank-sheets" transform="translate(-1 -1.02)">
      <path id="Path_93477" data-name="Path 93477" d="M35.778,6.783V17.467A.775.775,0,0,1,35,18.23H26.782A.775.775,0,0,1,26,17.467V6.783a.775.775,0,0,1,.782-.763H35A.775.775,0,0,1,35.778,6.783Z" transform="translate(-7.778 0)" fill="#714b44"/>
      <rect id="Rectangle_52132" data-name="Rectangle 52132" width="7.333" height="1.222" rx="0.611" transform="translate(19.444 7.841)" fill="#bea9a6"/>
      <rect id="Rectangle_52133" data-name="Rectangle 52133" width="7.333" height="1.222" rx="0.611" transform="translate(19.444 10.286)" fill="#bea9a6"/>
      <rect id="Rectangle_52134" data-name="Rectangle 52134" width="7.333" height="1.222" rx="0.611" transform="translate(19.444 12.73)" fill="#bea9a6"/>
      <rect id="Rectangle_52135" data-name="Rectangle 52135" width="7.333" height="1.222" rx="0.611" transform="translate(19.444 15.174)" fill="#bea9a6"/>
      <path id="Path_93478" data-name="Path 93478" d="M25.778,14.764V25.458a.775.775,0,0,1-.782.764H16.782A.776.776,0,0,1,16,25.458V14.764A.776.776,0,0,1,16.782,14H25a.776.776,0,0,1,.782.764Z" transform="translate(-3.889 -3.103)" fill="#a48782"/>
      <path id="Path_93479" data-name="Path 93479" d="M15.778,22.764V33.458a.776.776,0,0,1-.782.764H6.782A.776.776,0,0,1,6,33.458V22.764A.776.776,0,0,1,6.782,22H15a.776.776,0,0,1,.782.764Z" transform="translate(0 -6.214)" fill="#bea9a6"/>
      <g id="Group_98391" data-name="Group 98391" transform="translate(7.222 17.619)">
        <rect id="Rectangle_52137" data-name="Rectangle 52137" width="7.333" height="1.222" rx="0.611" fill="#a48782"/>
        <rect id="Rectangle_52138" data-name="Rectangle 52138" width="7.333" height="1.222" rx="0.611" transform="translate(0 2.444)" fill="#a48782"/>
        <rect id="Rectangle_52139" data-name="Rectangle 52139" width="7.333" height="1.222" rx="0.611" transform="translate(0 4.889)" fill="#a48782"/>
        <rect id="Rectangle_52140" data-name="Rectangle 52140" width="7.333" height="1.222" rx="0.611" transform="translate(0 7.333)" fill="#a48782"/>
      </g>
      <g id="Group_98392" data-name="Group 98392" transform="translate(12.111 15.174)">
        <path id="Path_93480" data-name="Path 93480" d="M19.056,21H16v.611h3.056a.613.613,0,0,1,.611.611v6.722h.611V22.222A1.226,1.226,0,0,0,19.056,21Z" transform="translate(-16 -21)" opacity="0.05"/>
        <path id="Path_93481" data-name="Path 93481" d="M19.056,21.5H16v.306h3.056a.613.613,0,0,1,.611.611v6.722h.306V22.417a.915.915,0,0,0-.917-.917Z" transform="translate(-16 -21.194)" opacity="0.07"/>
      </g>
      <g id="Group_98393" data-name="Group 98393" transform="translate(18.222 10.286)">
        <path id="Path_93482" data-name="Path 93482" d="M29.056,13H26v.611h3.056a.613.613,0,0,1,.611.611v6.722h.611V14.222A1.226,1.226,0,0,0,29.056,13Z" transform="translate(-26 -13)" opacity="0.05"/>
        <path id="Path_93483" data-name="Path 93483" d="M29.056,13.5H26v.306h3.056a.613.613,0,0,1,.611.611v6.722h.306V14.417a.915.915,0,0,0-.917-.917Z" transform="translate(-26 -13.194)" opacity="0.07"/>
      </g>
    </g>
  </g>
</svg>

    )
}