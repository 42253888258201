import * as React from "react"

export const TickIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      data-name="icons8-ok 1"
      d="M8 0a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm3.424 6.624-4 4a.6.6 0 0 1-.849 0l-2-2a.6.6 0 0 1 .848-.848L7 9.352l3.576-3.576a.6.6 0 0 1 .849.848Z"
      fill={props?.color ?? "#e4e8ee"}
    />
  </svg>
)
