import * as React from "react"

export const ExisitingAssignee = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={228} height={70} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Path 97336"
                    d="M1609.212 589.563a20 20 0 1 0 20-20 20 20 0 0 0-20 20"
                    transform="translate(-1609.212 -569.563)"
                    fill="#f5f7fa"
                />
            </clipPath>
            <clipPath id="b">
                <path
                    data-name="Path 97447"
                    d="M623.714 131.049a10 10 0 1 0 10-10 10 10 0 0 0-10 10"
                    transform="translate(-623.714 -121.049)"
                    fill="#f5f7fa"
                />
            </clipPath>
            <clipPath id="c">
                <path
                    data-name="Path 97336"
                    d="M1629.212 579.563a10 10 0 1 1-10-10 10 10 0 0 1 10 10"
                    transform="translate(-1609.212 -569.563)"
                    fill="#f5f7fa"
                />
            </clipPath>
            <clipPath id="d">
                <path
                    data-name="Path 97436"
                    d="M130.965 156.049a35 35 0 1 0 35-35 35 35 0 0 0-35 35"
                    transform="translate(-130.965 -121.049)"
                    fill="#f5f7fa"
                />
            </clipPath>
            <clipPath id="e">
                <path
                    data-name="Path 97447"
                    d="M623.714 141.049a20 20 0 1 0 20-20 20 20 0 0 0-20 20"
                    transform="translate(-623.714 -121.049)"
                    fill="#f5f7fa"
                />
            </clipPath>
        </defs>
        <g data-name="Group 103104">
            <g data-name="Group 103098" opacity={0.75}>
                <g
                    data-name="Group 103025"
                    transform="translate(29 15)"
                    clipPath="url(#a)"
                >
                    <path
                        data-name="Path 97485"
                        d="M0 20A20 20 0 1 0 20 0 20 20 0 0 0 0 20"
                        fill="#f5f7fa"
                    />
                    <path
                        data-name="Path 97328"
                        d="M26.826 11.08s3.542 1.339 3.282 6.99c-.246 5.353-1.52 15.956-1.52 15.956l-16.4.412s-4.326-15.007-1.047-21.6c3.609-7.26 14.6-5.377 15.682-1.755"
                        fill="#120d09"
                    />
                    <path
                        data-name="Path 97329"
                        d="M23.774 27.758a9.972 9.972 0 0 0 2.605-4.294c.342.47.86 0 .923-.442-.006-.672 1.241-3.716-.167-3.6l-.018.037c.957-12.952-15.2-12.935-14.234.005l-.021-.043c-1.41-.113-.162 2.921-.167 3.6.063.439.583.912.925.439a9.974 9.974 0 0 0 2.606 4.3 5.954 5.954 0 0 0 7.548 0"
                        fill="#fdcb91"
                    />
                    <path
                        data-name="Path 97330"
                        d="M23.617 28.876a5.648 5.648 0 0 1 .13-1.094l.027-.025a5.954 5.954 0 0 1-7.548 0l.026.025a5.681 5.681 0 0 1 .13 1.093 5.927 5.927 0 0 0 7.235 0"
                        fill="#e6b884"
                    />
                    <path
                        data-name="Path 97331"
                        d="M34.416 34.287c-1.546-2.539-6.678-2.612-8.89-2.6-1.423 0-1.939-1.4-1.909-2.816a5.927 5.927 0 0 1-7.235 0c.03 1.413-.484 2.814-1.909 2.816-2.211-.015-7.344.058-8.89 2.6a24.684 24.684 0 0 0-2.374 5.712H36.79a24.684 24.684 0 0 0-2.374-5.712"
                        fill="#fdcb91"
                    />
                    <path
                        data-name="Path 97332"
                        d="M26.75 20.61s2.161-5.617-.2-8.053c0 0-.786-2.946-5.107-3.535s-10.178 2.182-8.585 10.4l.685 1.826s1.419-8.771 11.554-7.985c0 0 1.729 2.239 1.65 7.346"
                        fill="#120d09"
                    />
                    <path
                        data-name="Path 97333"
                        d="M34.415 34.288c-1.546-2.539-6.678-2.612-8.89-2.6a1.661 1.661 0 0 1-.961-.28c-1.486.454-3.063 5.362-4.6 5.362s-3.039-4.909-4.527-5.364a1.666 1.666 0 0 1-.964.282c-2.211-.015-7.343.058-8.89 2.6A24.684 24.684 0 0 0 3.209 40H36.79a24.684 24.684 0 0 0-2.374-5.712"
                        fill="#005d96"
                    />
                    <path
                        data-name="Path 97334"
                        d="m15.825 31.036 3.037 5.019-1.953 2.048-2.917-6.413Z"
                        fill="#fff"
                    />
                    <path
                        data-name="Path 97335"
                        d="m24.15 30.994-3.037 5.019 1.953 2.048 2.917-6.413Z"
                        fill="#fff"
                    />
                </g>
            </g>
            <g data-name="Group 103100" opacity={0.5}>
                <g
                    data-name="Group 103069"
                    transform="translate(0 25)"
                    clipPath="url(#b)"
                >
                    <path
                        data-name="Path 97484"
                        d="M0 10A10 10 0 1 0 10 0 10 10 0 0 0 0 10"
                        fill="#f5f7fa"
                    />
                    <path
                        data-name="Path 97438"
                        d="M17.647 16.97c-.693-1.138-2.745-1.342-4.072-1.373l-1.989 1.556-1.586.62-1.533-.586-2.074-1.589c-1.328.033-3.353.243-4.041 1.372A13.093 13.093 0 0 0 1.093 20h17.813a13.094 13.094 0 0 0-1.26-3.03"
                        fill="#fd2856"
                    />
                    <path
                        data-name="Path 97439"
                        d="m10.001 17.773 1.586-.62 1.989-1.556a11.465 11.465 0 0 0-.644 0c-.755 0-1.029-.744-1.013-1.494a3.144 3.144 0 0 1-3.838 0c.016.75-.257 1.493-1.013 1.494-.189 0-.419 0-.675.005l2.074 1.59Z"
                        fill="#fdcb91"
                    />
                    <path
                        data-name="Path 97440"
                        d="M8.081 14.099s.052 1.061-.555 1.378l-.066-.779Z"
                        fill="#a20022"
                    />
                    <path
                        data-name="Path 97441"
                        d="M11.918 14.099s-.052 1.061.555 1.378l.066-.779Z"
                        fill="#a20022"
                    />
                    <path
                        data-name="Path 97442"
                        d="M12 13.506a5.289 5.289 0 0 0 1.382-2.278c.181.249.456 0 .49-.234 0-.356.659-1.971-.089-1.909l-.01.02c.508-6.87-8.063-6.862-7.551 0l-.011-.023c-.748-.06-.086 1.55-.089 1.909.034.233.309.484.491.233a5.291 5.291 0 0 0 1.383 2.279 3.158 3.158 0 0 0 4 0"
                        fill="#fdcb91"
                    />
                    <path
                        data-name="Path 97443"
                        d="M11.919 14.099a2.99 2.99 0 0 1 .069-.58l.014-.013a3.158 3.158 0 0 1-4 0l.014.013a3.021 3.021 0 0 1 .069.58 3.144 3.144 0 0 0 3.838 0"
                        fill="#8a6348"
                    />
                    <path
                        data-name="Path 97444"
                        d="m6.457 9.973-.45-1.574a3.452 3.452 0 0 1 .317-3.36 5.325 5.325 0 0 1 6.147-1.13 3.278 3.278 0 0 1 1.562 3.954c-.15.648-.415 2.027-.415 2.027a2.349 2.349 0 0 1-.565-1.562 1.822 1.822 0 0 0-2.214-1.808 6.1 6.1 0 0 1-1.531.007c-.668-.082-1.561-.074-1.953.455-.665.9-.266 2.226-.9 2.99"
                    />
                    <path
                        data-name="Path 97445"
                        d="M8.081 14.099s-1.444.294-1.6 1.5S10 17.776 10 17.776s-3.9-1.5-1.919-3.674"
                        fill="#df1944"
                    />
                    <path
                        data-name="Path 97446"
                        d="M11.919 14.099s1.444.294 1.6 1.5S10 17.776 10 17.776s3.9-1.5 1.919-3.674"
                        fill="#df1944"
                    />
                </g>
            </g>
            <g data-name="Group 103101" opacity={0.5}>
                <g
                    data-name="Group 103025"
                    transform="translate(208 25)"
                    clipPath="url(#c)"
                >
                    <path
                        data-name="Path 97485"
                        d="M20 10A10 10 0 1 1 10 0a10 10 0 0 1 10 10"
                        fill="#f5f7fa"
                    />
                    <path
                        data-name="Path 97328"
                        d="M6.587 5.54s-1.771.669-1.641 3.495c.123 2.677.76 7.978.76 7.978l8.2.206s2.163-7.5.524-10.8c-1.8-3.63-7.3-2.688-7.841-.877"
                        fill="#120d09"
                    />
                    <path
                        data-name="Path 97329"
                        d="M8.113 13.879a4.986 4.986 0 0 1-1.3-2.147c-.171.235-.43 0-.462-.221 0-.336-.621-1.858.084-1.8l.009.019c-.479-6.476 7.6-6.467 7.117 0l.01-.021c.7-.057.081 1.461.084 1.8-.031.219-.292.456-.462.22a4.986 4.986 0 0 1-1.3 2.148 2.977 2.977 0 0 1-3.774 0"
                        fill="#fdcb91"
                    />
                    <path
                        data-name="Path 97330"
                        d="M8.192 14.438a2.824 2.824 0 0 0-.065-.547l-.013-.013a2.977 2.977 0 0 0 3.774 0l-.013.012a2.846 2.846 0 0 0-.065.547 2.963 2.963 0 0 1-3.617 0"
                        fill="#fab86c"
                    />
                    <path
                        data-name="Path 97331"
                        d="M2.792 17.14c.773-1.269 3.339-1.306 4.445-1.3.711 0 .969-.7.955-1.408a2.963 2.963 0 0 0 3.617 0c-.015.707.242 1.407.955 1.408 1.105-.007 3.672.029 4.445 1.3a12.339 12.339 0 0 1 1.187 2.856H1.606a12.345 12.345 0 0 1 1.187-2.856"
                        fill="#fdcb91"
                    />
                    <path
                        data-name="Path 97332"
                        d="M6.625 10.305s-1.08-2.809.1-4.026a2.819 2.819 0 0 1 2.553-1.768c2.161-.295 5.089 1.091 4.293 5.2l-.342.913s-.709-4.385-5.777-3.992a6.151 6.151 0 0 0-.825 3.673"
                        fill="#120d09"
                    />
                    <path
                        data-name="Path 97333"
                        d="M2.792 17.144c.773-1.269 3.339-1.306 4.445-1.3a.83.83 0 0 0 .481-.14c.743.227 1.531 2.681 2.3 2.681s1.52-2.454 2.263-2.682a.833.833 0 0 0 .482.141c1.106-.008 3.672.029 4.445 1.3A12.338 12.338 0 0 1 18.395 20H1.605a12.345 12.345 0 0 1 1.187-2.856"
                        fill="#543f30"
                    />
                    <path
                        data-name="Path 97334"
                        d="m12.088 15.518-1.518 2.51.976 1.024 1.459-3.206Z"
                        fill="#fff"
                    />
                    <path
                        data-name="Path 97335"
                        d="m7.926 15.497 1.519 2.51-.976 1.024-1.459-3.206Z"
                        fill="#fff"
                    />
                </g>
            </g>
            <g data-name="Group 103102">
                <g
                    data-name="Group 103065"
                    transform="translate(79)"
                    clipPath="url(#d)"
                >
                    <path
                        data-name="Path 97482"
                        d="M0 35A35 35 0 1 0 35 0 35 35 0 0 0 0 35"
                        fill="#f5f7fa"
                    />
                    <path
                        data-name="Path 97427"
                        d="M42.007 47.271a18.514 18.514 0 0 0 4.837-7.972c.634.872 1.6-.006 1.714-.82-.011-1.247 2.305-6.9-.311-6.681l-.034.07c1.777-24.047-28.222-24.015-26.427.01l-.039-.079c-2.617-.21-.3 5.424-.311 6.681.118.815 1.083 1.694 1.717.816a18.517 18.517 0 0 0 4.84 7.975 11.054 11.054 0 0 0 14.014 0"
                        fill="#fdbc91"
                    />
                    <path
                        data-name="Path 97428"
                        d="M41.715 49.348a10.482 10.482 0 0 1 .242-2.03l.05-.046a11.054 11.054 0 0 1-14.014 0l.049.046a10.56 10.56 0 0 1 .241 2.03 11 11 0 0 0 13.432 0"
                        fill="#f2ad80"
                    />
                    <path
                        data-name="Path 97429"
                        d="M61.765 59.395c-2.87-4.714-12.4-4.849-16.5-4.821-2.641 0-3.6-2.605-3.545-5.227a11 11 0 0 1-13.432 0c.056 2.624-.9 5.225-3.544 5.228-4.105-.028-13.634.107-16.5 4.821a45.826 45.826 0 0 0-4.408 10.606h62.337a45.831 45.831 0 0 0-4.408-10.606"
                        fill="#fdbc91"
                    />
                    <path
                        data-name="Path 97430"
                        d="M47.103 34.918s-1.124-10.315-3.118-11.605c-.957-.619-2.542-.525-4.1-.13a17.176 17.176 0 0 1-8.627.016c-1.642-.436-3.342-.575-4.345.232-2.405 1.936-4.4 12.144-4.4 12.144s-3.989-12.437 0-18.128c0 0 3.109-3.813 3.109-4.928v1.115s1.877-2.875.939-4.693a4.645 4.645 0 0 1 2.288 1.467s1.115-2.347.411-3.579c0 0 3.227 1.349 4.107 2.757s1.173-1.232.587-1.877c0 0 4.107.176 5.749 1.643s.763-.739.763-.739a7.149 7.149 0 0 1 5.4 6.2l1.349-.64s4.686 9.117-.108 20.752"
                        fill="#592800"
                    />
                    <path
                        data-name="Path 97431"
                        d="M61.768 59.39c-2.871-4.714-12.4-4.849-16.506-4.821a3.044 3.044 0 0 1-2.761-1.593 15.55 15.55 0 0 1-15.016.025 3.045 3.045 0 0 1-2.746 1.567c-4.106-.028-13.635.107-16.506 4.821a45.832 45.832 0 0 0-4.408 10.606h62.351a45.831 45.831 0 0 0-4.408-10.606"
                        fill="#cfcfcf"
                    />
                    <path
                        data-name="Path 97432"
                        d="m35.009 54.884-2.086 3.022L35 59.527l1.939-1.621Z"
                        fill="#f20000"
                    />
                    <path
                        data-name="Path 97433"
                        d="m31.698 70 2.312-13.675h1.92L38.052 70Z"
                        fill="#f20000"
                    />
                    <path
                        data-name="Path 97434"
                        d="M28.17 51.017 35 54.891l-3.3 4.781-5.579-5.472Z"
                        fill="#f2f2f2"
                    />
                    <path
                        data-name="Path 97435"
                        d="m41.828 51.017-6.83 3.874 3.054 4.781 5.827-5.472Z"
                        fill="#f2f2f2"
                    />
                </g>
            </g>
            <g data-name="Group 103103" opacity={0.75}>
                <g
                    data-name="Group 103069"
                    transform="translate(159 15)"
                    clipPath="url(#e)"
                >
                    <path
                        data-name="Path 97484"
                        d="M0 20A20 20 0 1 0 20 0 20 20 0 0 0 0 20"
                        fill="#f5f7fa"
                    />
                    <path
                        data-name="Path 97438"
                        d="M35.295 33.94c-1.386-2.276-5.49-2.684-8.144-2.746l-3.977 3.112-3.173 1.24-3.066-1.171-4.148-3.179c-2.655.067-6.706.486-8.082 2.744A26.188 26.188 0 0 0 2.186 40h35.627a26.189 26.189 0 0 0-2.519-6.06"
                        fill="#006651"
                    />
                    <path
                        data-name="Path 97439"
                        d="m20 35.546 3.173-1.24 3.977-3.112a45.252 45.252 0 0 0-1.287-.009c-1.509 0-2.057-1.489-2.026-2.987a6.288 6.288 0 0 1-7.676 0c.032 1.5-.513 2.986-2.025 2.987-.379 0-.839 0-1.35.01l4.148 3.179Z"
                        fill="#fdcb91"
                    />
                    <path
                        data-name="Path 97440"
                        d="M16.161 28.198s.1 2.122-1.109 2.756l-.132-1.559Z"
                        fill="#003027"
                    />
                    <path
                        data-name="Path 97441"
                        d="M23.835 28.198s-.1 2.122 1.109 2.756l.132-1.559Z"
                        fill="#003027"
                    />
                    <path
                        data-name="Path 97442"
                        d="M24.004 27.012a10.578 10.578 0 0 0 2.764-4.555c.362.5.913 0 .98-.468-.006-.713 1.317-3.942-.178-3.818l-.019.04c1.016-13.741-16.127-13.723-15.1.006l-.022-.045c-1.5-.12-.172 3.1-.178 3.818.067.465.619.968.981.466a10.582 10.582 0 0 0 2.765 4.558 6.317 6.317 0 0 0 8.008 0"
                        fill="#fdcb91"
                    />
                    <path
                        data-name="Path 97443"
                        d="M23.838 28.198a5.988 5.988 0 0 1 .138-1.16l.028-.026a6.317 6.317 0 0 1-8.008 0l.028.026a6.032 6.032 0 0 1 .138 1.16 6.288 6.288 0 0 0 7.676 0"
                        fill="#8a6348"
                    />
                    <path
                        data-name="Path 97444"
                        d="m12.914 19.947-.9-3.148s-1.725-3.7.635-6.721 7.974-4.353 12.294-2.259 3.422 6.612 3.123 7.908-.831 4.054-.831 4.054a4.7 4.7 0 0 1-1.13-3.123 3.643 3.643 0 0 0-4.429-3.615 12.2 12.2 0 0 1-3.062.015c-1.336-.165-3.123-.148-3.906.909-1.329 1.794-.532 4.452-1.794 5.981"
                    />
                    <path
                        data-name="Path 97445"
                        d="M16.167 28.198s-2.888.588-3.2 2.994 7.038 4.354 7.038 4.354-7.808-3.005-3.838-7.348"
                        fill="#004537"
                    />
                    <path
                        data-name="Path 97446"
                        d="M23.838 28.198s2.888.588 3.2 2.994S20 35.546 20 35.546s7.808-3.005 3.838-7.348"
                        fill="#004537"
                    />
                </g>
            </g>
        </g>
    </svg>
)

