export const Template = {
  property_group_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/property_group_master_template.xlsx",
  rental_breakup_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/rental_breakup_master_template.xlsx",
  unit_rental_breakup_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/unit_rental_breakup_master_template.xlsx",
  block_type_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/block_type_master_template.xlsx",
  unit_type_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/unit_type_master_template.xlsx",
  amenities_type_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/amenities_type_master_template.xlsx",
  vat_group_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/vat_group_master_template.xlsx",
  vat_group_item:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/vat_group_item_template.xlsx",
  currency_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/currency_master_template.xlsx",
  company_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/company_master_template.xlsx",
  country_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/country_master_template.xlsx",
  state_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/state_master_template.xlsx",
  cities_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/cities_master_template.xlsx",
  cities_area_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/cities_area_master_template.xlsx",
  lead_type_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/lead_type_master_template.xlsx",
  activity_category_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/activity_category_master_template.xlsx",
  activity_sub_category_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/activity_sub_category_master_template.xlsx",
  general_category_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/general_category_master_template.xlsx",
  general_sub_category_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/general_sub_category_master_template.xlsx",
  maintenance_category_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/maintenance_category_master_template.xlsx",
  maintenance_sub_category_master:
    "https://protomate.objectstore.e2enetworks.net/masters_templates/maintenance_sub_category_master_template.xlsx",
  profession_master:
    "https://objectstore.e2enetworks.net/protomate/masters_templates/profession_master_template.xlsx",
  emergency_master:
    "https://objectstore.e2enetworks.net/protomate/masters_templates/emergency_master_template.xlsx",
  access_gates_master:
    "https://objectstore.e2enetworks.net/protomate/masters_templates/access_gates_master_template.xlsx",
  parking_master:
    "https://objectstore.e2enetworks.net/protomate/masters_templates/Parking_Bulk_Upload_Template.xlsx",
  domestic_helper_master:
    "https://objectstore.e2enetworks.net/protomate/masters_templates/domestic_helper_master_template.xlsx",
  service_providers_master:
    "https://objectstore.e2enetworks.net/protomate/masters_templates/service_providers_master_template.xlsx",
  vendor_master:
    "https://objectstore.e2enetworks.net/protomate/masters_templates/vendor_master_template.xlsx",
  property:
    "https://objectstore.e2enetworks.net/protomate/bulk_upload/Property_Bulk_Upload_Template.xlsx",
  block:
    "https://objectstore.e2enetworks.net/protomate/bulk_upload/Block_Bulk_Upload_Template.xlsx",
  floor:
    "https://objectstore.e2enetworks.net/protomate/bulk_upload/Floor_Bulk_Upload_Template.xlsx",
  unit: "https://objectstore.e2enetworks.net/protomate/bulk_upload/Unit_Bulk_Upload_Template.xlsx",
  general_status_master:
    "https://objectstore.e2enetworks.net/protomate/masters_templates/general_status_master_template.xlsx",
  maintenance_status_master:
    "https://objectstore.e2enetworks.net/protomate/masters_templates/maintanance_status_master_template.xlsx",
};
