export const DiscussionForum = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 98443">
      <g fill={props?.fill ?? "#c1c5cb"}>
        <path
          data-name="Path 94945"
          d="M8.358 12.31A1.4 1.4 0 1 0 7 10.909a1.379 1.379 0 0 0 1.358 1.401Z"
        />
        <path
          data-name="Path 94946"
          d="M21.641 9.511a1.4 1.4 0 1 0 1.358 1.4 1.379 1.379 0 0 0-1.358-1.4Z"
        />
        <path
          data-name="Path 94947"
          d="M21.641 13.413a1.089 1.089 0 0 0-.547.357l-1.512 1.761a.936.936 0 0 1-.712.33h-1.3a.692.692 0 0 0-.682.7v.382h-.3v-.38a1 1 0 0 1 .983-1.014h.1v-2.086a1.783 1.783 0 0 0-1.755-1.81h-1.834a1.783 1.783 0 0 0-1.755 1.81v2.087h.1a1 1 0 0 1 .983 1.014v.382h-.3v-.383a.692.692 0 0 0-.682-.7h-1.3a.936.936 0 0 1-.712-.33l-1.51-1.765a1.055 1.055 0 0 0-1.195-.326 1.109 1.109 0 0 0-.7 1.047v4.381a1.639 1.639 0 0 0 1.614 1.664h1.839a.44.44 0 0 1 .4.264l.841 1.877a.536.536 0 0 0 .693.285.564.564 0 0 0 .314-.7l-.83-2.547a1.241 1.241 0 0 0-1.173-.869H9.562v-1.531l-1.155-1.511.235-.189 1.14 1.488h.075v.466h4.838v5.121a.3.3 0 1 0 .6 0v-5.12h4.987v-.555l1.074-1.4.235.193-1.153 1.507v1.53h-1.143a1.241 1.241 0 0 0-1.173.87l-.831 2.551a.564.564 0 0 0 .314.7.536.536 0 0 0 .693-.285l.841-1.877a.44.44 0 0 1 .4-.264h1.843a1.639 1.639 0 0 0 1.614-1.664v-4.385a1.129 1.129 0 0 0-.42-.88 1.057 1.057 0 0 0-.932-.2Z"
        />
        <path
          data-name="Path 94948"
          d="M13.688 9.399a1.357 1.357 0 1 0 1.357-1.4 1.377 1.377 0 0 0-1.357 1.4Z"
        />
      </g>
      <path data-name="Rectangle 52139" fill="none" d="M0 0h30v30H0z" />
    </g>
  </svg>
)
