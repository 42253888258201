import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import { TextBox } from '../textbox';
import { AccordianStyle } from "./styles";
import ShowMoreText from "react-show-more-text";
export const NotesCard = ({ t = "", item = {}, MoveOut = false, setNotesData = () => false, notesData = "", onUpdateNotes = () => false, status = "", type = "", isDisableBtn = false }) => {
    const [expend, setExpend] = React.useState(false)
    const [notes, setNotes] = React.useState(false)
    const [info, setInfo] = React.useState("")
    const classes = AccordianStyle();
    //handleChangeNotes
    const handleChangeNotes = (e) => {
        setInfo(e.target.value)
    }

    //onclickupdate
    const updateNotes = () => {
        if (info?.length > 0) {
            setNotesData([...notesData?.managerNotes, { notes: info }])
            onUpdateNotes(info)
        }
    }

    return (
        <Box>
            <Grid sx={{ boxShadow: expend ? "0px 8px 24px #0717411F" : "auto" }} container className={classes.cardRoot} alignItems="center" onClick={() => { return (setNotes(!notes), setExpend(!expend)) }}>
                <Grid item sm={MoveOut === true ? 4 : 6} md={4} lg={4}>

                    <Stack direction="row" spacing={1} alignItems='center'>
                        <Typography className={classes.title}>{t("Notes")}</Typography>

                    </Stack>

                </Grid>
                <Grid item sm={3} md={4} lg={4}>

                    {
                        notesData?.managerNotes?.length > 0 ? <CheckCircleIcon color="success" /> : "-"
                    }
                </Grid>
                <Grid item sm={3} md={MoveOut === true ? 2 : 4} lg={MoveOut === true ? 2 : 4}>

                    <Stack direction="row" alignItems='center' justifyContent="space-between">

                        <Box width="100px" />
                        <KeyboardArrowDownIcon />
                    </Stack>
                </Grid>
                {
                    notes &&
                    <Grid item xs={12} >
                        <Grid container marginTop="16px">
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <Typography className={classes.subtitle1}>{t("Manager Note")}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={8} lg={8} onClick={(e) => notesData?.managerNotes?.length === 0 && e.stopPropagation()}>

                                {notesData?.managerNotes?.length > 0 ?
                                    notesData?.managerNotes?.map((val) => {
                                        return (
                                            <Typography className={classes.subtitle2} onClick={(e)=>e.stopPropagation()}>{val?.notes !== null ?
                                                <ShowMoreText
                                                    lines={5}
                                                    more="Show More"
                                                    less="Show Less"
                                                    className={classes.subtitle2}
                                                    anchorClass={classes.seeMoreLessTextStyle}
                                                    expanded={false}
                                                    truncatedEndingComponent={"... "}
                                                >
                                                    {val?.notes?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)}
                                                </ShowMoreText>
                                                : "-"}</Typography>
                                        )
                                    })
                                    : status === "Completed" && notesData?.managerNotes?.length === 0 ?
                                        <Typography className={classes.subtitle2}>{"-"}</Typography>

                                        :
                                        (status !== "Completed" &&
                                            <Box className={classes.textAreaBox}>
                                                <TextBox label="" placeholder={t('Type Notes here')} rowheight={6}
                                                    border={0} color="white" multiline onChange={(e) => handleChangeNotes(e)} value={info} />
                                                <Box mt={2}>
                                                    <Button variant="contained" fullWidth onClick={updateNotes} disabled={isDisableBtn}>{t("Update Note")}</Button>
                                                </Box>
                                            </Box>
                                        )
                                }
                            </Grid>
                            <Grid item xs={6} sm={6} md={4} lg={4}>
                                <Typography className={classes.subtitle1}>{t("Inspector Note")}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={8} lg={8} onClick={(e)=>e.stopPropagation()}>
                                {notesData?.inspectorNotes !== null ?
                                    <ShowMoreText
                                        lines={5}
                                        more="Show More"
                                        less="Show Less"
                                        className={classes.subtitle2}
                                        anchorClass={classes.seeMoreLessTextStyle}
                                        expanded={false}
                                        truncatedEndingComponent={"... "}
                                    >
                                        {notesData?.inspectorNotes?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)}
                                    </ShowMoreText>
                                    : <Typography className={classes.subtitle2} >{"-"}</Typography>
                                }
                            </Grid>
                            {(type === "service" || type === "general" || type === "site_visit" || type === "delivery-order") &&
                                <>
                                    <Grid item xs={6} sm={6} md={4} lg={4}>
                                        <Typography className={classes.subtitle1}>{(type === "service" || type === "general") ? "Costing & Material Request Notes" : type === "site_visit" ? "Site Survey Observation Notes" : "Delivery Discrepancy Notes"}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={8} lg={8} onClick={(e)=>e.stopPropagation()}>
                                        {notesData?.costing_materials_notes !== null ?
                                            <ShowMoreText
                                                lines={5}
                                                more="Show More"
                                                less="Show Less"
                                                className={classes.subtitle2}
                                                anchorClass={classes.seeMoreLessTextStyle}
                                                expanded={false}
                                                truncatedEndingComponent={"... "}
                                            >
                                                {notesData?.costing_materials_notes?.split('\n').map((item, i) => <Typography className={classes.subtitle2} key={i}>{item}</Typography>)}
                                            </ShowMoreText>
                                            : <Typography className={classes.subtitle2} >{"-"}</Typography>}
                                    </Grid>
                                </>
                            }
                        </Grid >
                    </Grid >
                }
            </Grid >
        </Box >
    )
}