import { checkNumber } from "../../utils"
import { ComponentTypes1, ItemMasterTypes1 } from "../freeTextInvoice/utlis/freeTextInvoice"
import { SqFeet, Bed, Bath, House, MoneyIcon } from "./components/assets"

export const headerFun = (details) => {
    return [{ name: details?.quott_no ?? "", is_active: !!details?.quott_no }, { name: details?.lease_start_date, is_active: !!details?.lease_start_date }, { name: details?.lease_end_date, is_active: !!details?.lease_end_date }, { name: details?.revenue_type?.value ?? details?.revenue_type, is_grid: true, is_active: !!details?.revenue_type?.value ?? !!details?.revenue_type }]
}

export const cardHeaderFun = (data) => {
    return [{ name: data?.property?.property_name ?? "", is_not_img: true }, { name: `${data?.total_area ?? 0} ${data?.uom?.name ?? ""}`, icon: <SqFeet /> }, { name: data?.total_bed_rooms ?? 0, icon: <Bed /> }, { name: data?.total_baths ?? 0, icon: <Bath /> }, { name: data?.unit_type ?? "", icon: <House /> }]
}

export const header = (t, classes, revenue_type) => {
    return [
        {
            title: <MoneyIcon />,
            is_icon: true,
            classes: classes?.headCellIcon,
            is_active: true
        },
        {
            title: t("COMPONENT TYPE"),
            is_required: true,
            is_active: true
        },
        {
            title: t("ITEM TYPE & CATEGORY"),
            is_active: true
        },
        {
            title: t("PRICING COMPONENT"),
            is_required: true,
            is_active: true
        },
        {
            title: t("BASED ON"),
            is_required: true,
            is_active: true
        },
        // {
        //     title: t("UOM"),
        //     is_required: true,
        //     is_active: true
        // },
        {
            title: t("VALUE"),
            is_required: true,
            is_active: true
        },
        {
            title: t("VALUE BASIS"),
            is_required: true,
            is_active: (revenue_type?.value === "Sale") ? false : true
        },
        {
            title: t("QTY"),
            is_required: true,
            is_active: true
        },
        {
            title: t("DISCOUNT"),
            is_active: true
        },
        {
            title: t("AMOUNTS"),
            is_active: true
        },
        {
            title: t("TAX GROUP"),
            is_active: true
        },
        {
            title: t("TAXES"),
            is_active: true
        },
        {
            title: t("LINE TOTAL"),
            width: "125px",
            classes: classes?.headCell1,
            is_active: true

        },
    ]
}

export const pricingFactormanualResponse = (array) => {
    const details = array?.map((i) => {
        return {
            label: i?.name,
            value: i?.id,
            ...i
        }
    })
    return details
};

export const defaultComponent = {
    "Wallet Credit": {
        label: "Wallet Points",
        value: "is_wallet"
    },
    "Late fees": {
        label: "Late Fees & Penalty",
        value: "is_late_fee"
    },
    "Wallet Item": {
        label: "Wallet Item",
        value: "is_wallet_item"
    },
    "Item Master": {
        label: "Inventory Items",
        value: "item_based"
    },
    "Commission & Payment":{
        label: "Commission & Payment",
        value: "is_commission_payment"
    }
}

export const addNewObj = (e, revenue_type, t) => {
    return {
        "id": "",
        "unitid": "",
        "value": '',
        "primary": '',
        "refundable": '',
        "isActive": true,
        "taxable": '',
        "vatGroupId": '',
        "currencyId": '',
        "displayPercentage": '',
        "isExternal": false,
        "valueBasisType": "",
        "revenueType": "",
        "isChargeable": '',
        "isSecurity": true,
        "isQuantity": '',
        "quantity": 1,
        "referenceNo": "",
        "companyId": '',
        "isOnetime": '',
        "isReserveQuantity": '',
        "paymentPeriod": (revenue_type?.value === "Sale") ? "Total" : "",
        "componenttype": e,
        "vatGroup": "",
        "PCid": "",
        "PCname": "",
        "currencyName": "",
        "currencySymbol": "",
        "inspection_id": "",
        "pricing_component": [],
        "pricing_components":"",
        "inspection": "",
        "rentalBreakup": "",
        "rentalBreakupName": "",
        "amount": 0,
        "componentvalue": 0,
        "taxPercentage": 0,
        "appliedTaxAmount": 0,
        "totalAmountWithTax": 0,
        "applied_value": 0,
        "tax": 0,
        "basecurrency": "",
        "discountType": { value: "%", label: "%" },
        "discountedPrice": 0,
        "discountValue": 0,
        "fixTax": "",
        "refundableAmount": "",
        "vat_id": "",
        "componentType": defaultComponent[t] ?? "",
        "subtotal": 0,
        "taxAmountValue": 0,
        "amountWithoutDiscount": 0,
        "is_active": true,
        "componentvalues": 0,
        "type": t,
        "is_wallet_item": t === "Wallet Item" ? true : false
    }
}

export const tableRowValidation = (data) => {
    let isValid = true
    const value = data?.flatMap(i => i?.pricing)
    if (value?.every((v) => !!v?.pricing_components)) {
        isValid = false
    }
    else {
        return isValid = true
    }
    if (value?.every((v) => !!v?.componentType)) {
        isValid = false
    }
    else {
        return isValid = true
    }
    if (value?.every((v) => v?.valueBasisType === "Rental Value" ? v?.componentvalues >= 0 : v?.componentvalues)) {
        isValid = false
    }
    else {
        return isValid = true
    }
    if (value?.every((v) => !!v?.quantity)) {
        isValid = false
    }
    else {
        return isValid = true
    }
    if (value?.every((v) => !!v?.valueBasisType)) {
        isValid = false
    }
    else {
        return isValid = true
    }
    if (value?.every((v) => !!v?.paymentPeriod)) {
        isValid = false
    }
    else {
        return isValid = true
    }
    // if (value?.every((v) => !!v?.uom_type)) {
    //     isValid = false
    // }
    // else {
    //     return isValid = true
    // }
    return isValid
}

export const OptionFunction = (array, value, type) => {

    if (type === "payment") {
        if (value?.value === "item_based" || value?.value === "is_wallet_item" || value?.value === "is_wallet" || value?.value === "is_commission_payment") {
            const filterValues = ['Monthly', 'Total']
            return array.filter(item => filterValues.includes(item.value));
        }
        if (value?.value === "refundable" || value?.value === "one_time" || value?.value === "is_late_fee") {
            const filterValues = ['Total']
            return array.filter(item => filterValues.includes(item.value));
        }

        const filterValues = ['Monthly']
        return array.filter(item => filterValues.includes(item.value));
    }
    if (value?.value === "primary") {
        const filterValues = ['Per UOM', 'Amount']
        return array.filter(item => filterValues.includes(item.value));
    }
    if (value?.value === "refundable") {
        const filterValues = ['Percentage', 'Amount', 'Rental Value']
        return array.filter(item => filterValues.includes(item.value));
    }
    if (value?.value === "item_based" || value?.value === "is_wallet_item") {
        const filterValues = ['Amount']
        return array.filter(item => filterValues.includes(item.value));
    }


    const filterValues = ['Per UOM', 'Amount', 'Percentage']
    return array.filter(item => filterValues.includes(item.value));
}

export const filterValues = ['is_wallet_item', 'is_late_fee', 'is_wallet' , 'is_commission_payment']

export const percentageFunct = (percentage, number) => {
    return (Number(checkNumber(percentage)) / 100) * Number(checkNumber(number));
}

export const percentageOfAmount = (value, total) => {
    return (Number(checkNumber(value))) / Number(checkNumber(total) * 100);
}

export const percentageOfAmounts = (amount, totalValue) => {
    const percentage = (amount / totalValue) * 100;
    return percentage
  }

export const HoleNumber = (value) => {
    const number = checkNumber(value)
    return Number(Number(number).toFixed(2));
}

export const NumberFormat = (value) => {
    return Number(checkNumber(value))
}

export const componentTypeOption = (t, p, d) => {
    if (t === "Wallet Credit") {
        return ComponentTypes1?.filter(c => c?.value === "is_wallet")
    }
    if (t === "Late fees") {
        return ComponentTypes1?.filter(c => c?.value === "is_late_fee")
    }
    if (t === "Wallet Item") {
        return ItemMasterTypes1?.filter(c => c?.value === "is_wallet_item")
    }
    if (t === "Item Master") {
        return ItemMasterTypes1?.filter(c => c?.value === "item_based")
    }
    if(p?.is_commission_payment){
        return ComponentTypes1?.filter(c => c?.value === "is_commission_payment")
    }
    if (p?.is_wallet) {
        return ComponentTypes1?.filter(c => c?.value === "is_wallet")
    }
    if (p?.is_late_fee) {
        return ComponentTypes1?.filter(c => c?.value === "is_late_fee")
    }
    if (d?.is_wallet_item) {
        return ItemMasterTypes1?.filter(c => c?.value === "is_wallet_item")
    }
    if (d?.inspection) {
        return ItemMasterTypes1?.filter(c => c?.value === "item_based")
    }
    return ComponentTypes1?.filter(c => !["is_wallet", "is_late_fee","is_commission_payment"].includes(c?.value))
}

export const calculateDaysBetweenDates = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const date1InMillis = date1.getTime();
    const date2InMillis = date2.getTime();
    const days = Math.round((date2InMillis - date1InMillis) / oneDay);
    return days;
};
export const convertDaysToMonth = (days) => {
    return (days / 30.4167).toFixed(0);
};

export const showMessage = (state, setState) => {
    const pricing = state?.map((v) => {
        return {
            ...v,
            pricing: v?.pricing?.map((c) => {
                return {
                    ...c,
                    error: {
                        componentvalues: c?.valueBasisType === "Rental Value" ? "" : !!c?.componentvalues ? "" : "Value is Required",
                        pricing_components: !!c?.pricing_components ? "" : "Pricing Component is Required",
                        quantity: c?.valueBasisType === "Rental Value" ? !!v?.quantity ? "Quantity is Required" : "" : "",
                        paymentPeriod: !!c?.paymentPeriod ? "" : "Value Basis is Required",
                        componentType: !!c?.componentType ? "" : "Component Type is Required",
                        valueBasisType: !!c?.valueBasisType ? "" : "Based On is Required",
                        // uom_type:!!c?.uom_type ? "" : 'UOM is Required'
                    }
                }
            })
        }
    })
    setState([...pricing])

}