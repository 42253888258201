import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { BackendRoutes } from "../../../router/routes";
import { concat_string, LocalStorageKeys, NetWorkCallMethods, timeZoneConverter } from "../../../utils";
import { listing_tab_data, revoked_tabs_data } from "./constant";

export const constructResponseGetData = (_, selected_main_tab, selected_revoked_sub_tab, t) => {
    let data = {
        row: [],
        row_count: 0
    }

    let temp_row = []

    switch (selected_main_tab?.value) {
        case listing_tab_data.property_listing.value:
            temp_row = _?.property?.map(i => {
                return constructPropertyMapData(i)
            })

            data = { ...data, row: temp_row ?? [], row_count: _?.count ?? 0 }
            break;
        case listing_tab_data.public_lisiting.value:
            temp_row = _?.unit?.map(i => {
                return constructUnitMapData(i, t)
            })

            data = { ...data, row: temp_row ?? [], row_count: _?.count ?? 0 }
            break;
        case listing_tab_data.private_listing.value:
            temp_row = _?.unit?.map(i => {
                return constructUnitMapData(i, t)
            })

            data = { ...data, row: temp_row ?? [], row_count: _?.count ?? 0 }
            break;
        case listing_tab_data.public_facility_listing.value:
            temp_row = _?.data?.map(i => {
                return constructFacilityMapData(i, t)
            })

            data = { ...data, row: temp_row ?? [], row_count: _?.count ?? 0 }
            break;
        case listing_tab_data.private_facility_listing.value:
            temp_row = _?.data?.map(i => {
                return constructFacilityMapData(i, t)
            })

            data = { ...data, row: temp_row ?? [], row_count: _?.count ?? 0 }
            break;
        case listing_tab_data.revoked.value:
            switch (selected_revoked_sub_tab?.value) {
                case revoked_tabs_data.unit_revoked_list.value:
                    temp_row = _?.unit?.map(i => {
                        return constructRevokeUnitMapData(i)
                    })

                    data = { ...data, row: temp_row ?? [], row_count: _?.count ?? 0 }
                    break;
                case revoked_tabs_data.facility_revoked_list.value:
                    temp_row = _?.data?.map(i => {
                        return constructRevokeFacilityMapData(i)
                    })

                    data = { ...data, row: temp_row ?? [], row_count: _?.count ?? 0 }
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }

    return data
}

export const constructPropertyMapData = (_) => {
    return {
        property_id: _?.property_no ?? "-",
        property_name: _?.name ?? "-",
        property_type: _?.type ?? "-",
        revenue_type: _?.revenue_type ?? "-",
        location: concat_string(
            {
                door_no: _?.door_no,
                street_1: _?.street_1,
                street_2: _?.street_2,
                street_3: _?.street_3,
                landmark: _?.landmark,
                city: _?.city,
                district: _?.district,
                state: _?.state,
                country: _?.country
            },
            [
                "door_no", "street_1", "street_2", "street_3",
                "landmark", "city", "district", "state",
                "country"
            ]
        ),
        updated_on: timeZoneConverter(_?.updated_at, "DD MMM YY") ?? "-",
        data: _
    }
}

export const constructUnitMapData = (_, t) => {
    return {
        property_name: _?.property_name ?? "-",
        property_type: _?.property_type_name ?? "-",
        unit_name: _?.name ?? "-",
        unit_category: _?.category ?? "-",
        unit_id: _?.unit_no ?? "-",
        unit_type: _?.type ?? "-",
        purpose: _?.unit_purpose ?? "-",
        revenue_type: _?.revenue_type ?? "-",
        updated_on: timeZoneConverter(_?.updated_at, "DD MMM YY") ?? "-",
        revoke: t("Revoke"),
        data: _
    }
}
export const constructFacilityMapData = (_, t) => {

    return {
        property_name: _?.property_name ?? "-",
        amenity_category: _?.amenity_category ?? "-",
        amenity_name: _?.amenities_name ?? "-",
        period: _?.period ?? "-",
        rate: `${_?.symbol ?? " "} ${_?.rate ?? "-"}`,
        updated_on: timeZoneConverter(_?.updated_at, "DD MMM YY") ?? "-",
        revoke: t("Revoke"),
        data: _
    }
}
export const constructRevokeFacilityMapData = (_, t) => {
    return {
        property_name: _?.property_name ?? "-",
        amenity_category: _?.amenity_category ?? "-",
        amenity_name: _?.amenities_name ?? "-",
        period: _?.period ?? "-",
        rate: `${_?.symbol ?? " "} ${_?.rate ?? "-"}`,
        revoked_on: timeZoneConverter(_?.updated_at, "DD MMM YY") ?? "-",
        data: _
    }
}

export const constructRevokeUnitMapData = (_) => {
    return {
        property_name: _?.property_name ?? "-",
        property_type: _?.property_type_name ?? "-",
        unit_name: _?.name ?? "-",
        unit_category: _?.category ?? "-",
        unit_id: _?.unit_no ?? "-",
        unit_type: _?.type ?? "-",
        purpose: _?.unit_purpose ?? "-",
        revenue_type: _?.revenue_type ?? "-",
        revoked_on: timeZoneConverter(_?.updated_at, "DD MMM YY") ?? "-",
        data: _
    }
}

export const constructPropertyDropdownData = (_) => {
    return {
        logo: _?.logo ?? "/images/propertyImg.svg",
        property_name: _?.name ?? "-",
        property_type: _?.group_name ?? "-",
        location: concat_string(
            { area: _?.area, city: _?.city },
            ["area", "city"]
        ),
        data: _
    }
}

export const constructSelectedPropertyData = (_, property_type_option) => {
    return {
        logo: _?.logo ?? "/images/propertyImg.svg",
        property_name: _?.name ?? "-",
        property_type: _?.group_name ?? "-",
        location: concat_string(
            { area: _?.area, city: _?.city },
            ["area", "city"]
        ),
        form: {
            selected_property_type: property_type_option?.find(i => i?.label === _?.group_name) ?? ""
        },
        updated: false,
        data: _
    }
}

export const constructUnitDropdownData = (_) => {
    return {
        logo: _?.logo ?? "/images/propertyImg.svg",
        unit_name: _?.name ?? "-",
        unit_id: _?.unit_no ?? "-",
        unit_type: _?.unit_type_name ?? "-",
        data: _
    }
}
export const constructFacilityDropdownData = (_) => {
    return {
        logo: _?.facility_type?.assets?.[0]?.url ?? "/images/propertyImg.svg",
        description: _?.description ?? "-",
        facility_no: _?.facility_no ?? "-",
        rates: `${_?.symbol ?? ""} ${_?.rate ?? "-"}`,
        data: _
    }
}

export const constructSelectedUnitData = (_, unit_type_option) => {
    return {
        logo: _?.logo ?? "/images/propertyImg.svg",
        unit_name: _?.name ?? "-",
        unit_id: _?.unit_no ?? "-",
        unit_type: _?.unit_type_name ?? "-",
        form: {
            selected_unit_type: unit_type_option?.find(i => i?.label === _?.unit_type_name) ?? ""
        },
        updated: false,
        data: _
    }
}

export const constructSelectedFacilityUnitData = (_, unit_type_option) => {
    return {
        logo: _?.logo ?? "/images/propertyImg.svg",
        unit_name: _?.name ?? "-",
        unit_id: _?.unit_no ?? "-",
        unit_type: _?.facility_type?.amenities_name ?? "-",
        form: {
            selected_unit_type: unit_type_option?.find(i => i?.label === _?.facility_type?.amenities_name) ?? ""
        },
        updated: false,
        data: _
    }
}

export const constructAdditionalPayload = (selected_main_tab, selected_revoked_sub_tab, payload, filter) => {
    let additional_payload = { ...payload, client_id: localStorage.getItem(LocalStorageKeys?.clinetID) }

    switch (selected_main_tab?.value) {
        case listing_tab_data.property_listing.value:
            additional_payload["property_type"] = filter?.property_type?.map(i => i?.value)
            additional_payload["revenue_type"] = filter?.revenue_type?.map(i => i?.value)
            break;
        case listing_tab_data.public_lisiting.value:
            additional_payload["is_public"] = true
            additional_payload["property_type"] = filter?.property_type?.map(i => i?.value)
            additional_payload["unit_type"] = filter?.unit_type?.map(i => i?.value)
            additional_payload["revenue_type"] = filter?.revenue_type?.map(i => i?.value)
            break;
        case listing_tab_data.private_listing.value:
            additional_payload["is_private"] = true
            additional_payload["property_type"] = filter?.property_type?.map(i => i?.value)
            additional_payload["unit_type"] = filter?.unit_type?.map(i => i?.value)
            additional_payload["revenue_type"] = filter?.revenue_type?.map(i => i?.value)
            break;
        case listing_tab_data.public_facility_listing.value:
            additional_payload["is_public"] = true;
            additional_payload["is_revoked"] = false;
            break;
        case listing_tab_data.private_facility_listing.value:
            additional_payload["is_private"] = true;
            additional_payload["is_revoked"] = false;
            break;
        case listing_tab_data.revoked.value:
            switch (selected_revoked_sub_tab?.value) {
                case revoked_tabs_data.unit_revoked_list.value:
                    additional_payload["property_type"] = filter?.property_type?.map(i => i?.value)
                    additional_payload["unit_type"] = filter?.unit_type?.map(i => i?.value)
                    additional_payload["revenue_type"] = filter?.revenue_type?.map(i => i?.value)
                    additional_payload["is_revoked"] = true;
                    break;
                case revoked_tabs_data.facility_revoked_list.value:
                    additional_payload["is_revoked"] = true

                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }

    return additional_payload
}

export const getApiEndpoint = (selected_main_tab, selected_revoked_sub_tab) => {
    let api_endpoint = ""

    switch (selected_main_tab?.value) {
        case listing_tab_data.property_listing.value:
            api_endpoint = BackendRoutes.listing_property_listing
            break;
        case listing_tab_data.public_lisiting.value:
            api_endpoint = BackendRoutes.listing_unit_listing
            break;
        case listing_tab_data.private_listing.value:
            api_endpoint = BackendRoutes.listing_unit_listing
            break;
        case listing_tab_data.public_facility_listing.value:
            api_endpoint = BackendRoutes.listing_facility_listing
            break;
        case listing_tab_data.private_facility_listing.value:
            api_endpoint = BackendRoutes.listing_facility_listing
            break;
        case listing_tab_data.revoked.value:
            switch (selected_revoked_sub_tab?.value) {
                case revoked_tabs_data.unit_revoked_list.value:
                    api_endpoint = BackendRoutes.revoke_unit
                    break;
                case revoked_tabs_data.facility_revoked_list.value:
                    api_endpoint = BackendRoutes.listing_facility_listing
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }

    return api_endpoint
}

export const ConstructFilterComponent = (t, selected_main_tab, selected_revoked_sub_tab, filterData, filterOption) => {
    let filter_component = []

    switch (selected_main_tab?.value) {
        case listing_tab_data.property_listing.value:
            filter_component = PropertyFilterComponent(t, filterData, filterOption)
            break;
        case listing_tab_data.public_lisiting.value:
            filter_component = UnitFilterComponent(t, filterData, filterOption)
            break;
        case listing_tab_data.private_listing.value:
            filter_component = UnitFilterComponent(t, filterData, filterOption)
            break;
        case listing_tab_data.public_facility_listing.value:
            filter_component = UnitFilterComponent(t, filterData, filterOption)
            break;
        case listing_tab_data.private_facility_listing.value:
            filter_component = UnitFilterComponent(t, filterData, filterOption)
            break;
        case listing_tab_data.revoked.value:
            switch (selected_revoked_sub_tab?.value) {
                case revoked_tabs_data.unit_revoked_list.value:
                    filter_component = UnitFilterComponent(t, filterData, filterOption)
                    break;
                case revoked_tabs_data.facility_revoked_list.value:
                    filter_component = UnitFilterComponent(t, filterData, filterOption)
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }

    return filter_component
}

export const PropertyFilterComponent = (t, filterData, filterOption) => {
    return [
        {
            component: "select",
            value: filterData?.property_type,
            isMulti: true,
            label: t("Property Type"),
            state_name: "property_type",
            loadOptions: (search, array, handleLoading) => loadOptionsPropertyType(search, array, handleLoading, BackendRoutes.sync_global_property_type),
            isPaginate: true,
            placeholder: t("Select Property Type")
        },
        {
            component: "select",
            value: filterData?.revenue_type,
            isMulti: true,
            label: t("Revenue Type"),
            state_name: "revenue_type",
            options: filterOption?.revenue_type,
            placeholder: t("Select Revenue Type")
        },
    ]
}

export const UnitFilterComponent = (t, filterData, filterOption) => {
    return [
        {
            component: "select",
            value: filterData?.property_type,
            isMulti: true,
            label: t("Property Type"),
            state_name: "property_type",
            loadOptions: (search, array, handleLoading) => loadOptionsPropertyType(search, array, handleLoading, BackendRoutes.sync_global_property_type),
            isPaginate: true,
            placeholder: t("Select Property Type")
        },
        {
            component: "select",
            value: filterData?.unit_type,
            isMulti: true,
            label: t("Unit Type"),
            state_name: "unit_type",
            loadOptions: (search, array, handleLoading) => loadOptionsUnitType(search, array, handleLoading, BackendRoutes.sync_global_unit_type),
            isPaginate: true,
            placeholder: t("Select Unit Type")
        },
        {
            component: "select",
            value: filterData?.revenue_type,
            isMulti: true,
            label: t("Revenue Type"),
            state_name: "revenue_type",
            options: filterOption?.revenue_type,
            placeholder: t("Select Revenue Type")
        },
    ]
}


export const isFilterApplied = (selected_main_tab, selected_revoked_sub_tab, filterData) => {
    let is_filter_applied = false

    switch (selected_main_tab?.value) {
        case listing_tab_data.property_listing.value:
            is_filter_applied = Boolean(filterData?.property_type?.length > 0 || filterData?.revenue_type?.length > 0)
            break;
        case listing_tab_data.public_lisiting.value:
            is_filter_applied = Boolean(filterData?.property_type?.length > 0 || filterData?.unit_type?.length > 0 || filterData?.revenue_type?.length > 0)
            break;
        case listing_tab_data.private_listing.value:
            is_filter_applied = Boolean(filterData?.property_type?.length > 0 || filterData?.unit_type?.length > 0 || filterData?.revenue_type?.length > 0)
            break;
        case listing_tab_data.public_facility_listing.value:
            is_filter_applied = Boolean(filterData?.property_type?.length > 0 || filterData?.unit_type?.length > 0 || filterData?.revenue_type?.length > 0)
            break;
        case listing_tab_data.private_facility_listing.value:
            is_filter_applied = Boolean(filterData?.property_type?.length > 0 || filterData?.unit_type?.length > 0 || filterData?.revenue_type?.length > 0)
            break;
        case listing_tab_data.revoked.value:
            switch (selected_revoked_sub_tab?.value) {
                case revoked_tabs_data.unit_revoked_list.value:
                    is_filter_applied = Boolean(filterData?.property_type?.length > 0 || filterData?.unit_type?.length > 0 || filterData?.revenue_type?.length > 0)
                    break;
                case revoked_tabs_data.facility_revoked_list.value:
                    is_filter_applied = Boolean(filterData?.property_type?.length > 0 || filterData?.unit_type?.length > 0 || filterData?.revenue_type?.length > 0)
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }

    return !is_filter_applied
}

export const loadOptionsPropertyType = async (search = "", array, handleLoading=()=>false, endPoint) => {
    let offset, temp_res, temp_option = 0;
    let limit = 20
    if (search && !Boolean(array?.length)) { offset = 0; }
    else { offset = array?.length; }
    let payload = { offset, limit, search }

    await NetworkCall(
        `${config.public_listing_api_url}${endPoint}`,
        NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
        temp_res = res?.data?.response
        temp_option = temp_res?.property_type?.map(i => {
            return {
                value: i?.id,
                label: i?.type
            }
        })
        handleLoading && handleLoading();
    }).catch((err) => {
        console.log(err)
        temp_option = []
    })
    return {
        options: [...temp_option],
        hasMore: (array?.length + temp_option?.length) < temp_res?.count
    }
};

export const loadOptionsUnitType = async (search = "",array, handleLoading=()=>false, endPoint) => {
    let offset, temp_res, temp_option = 0;
    let limit = 20
    if (search && !Boolean(array?.length)) { offset = 0; }
    else { offset = array?.length; }
    let payload = { offset, limit, search }

    await NetworkCall(
        `${config.public_listing_api_url}${endPoint}`,
        NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
        temp_res = res?.data?.response
        temp_option = temp_res?.unit_type?.map(i => {
            return {
                value: i?.id,
                label: i?.type
            }
        })
        handleLoading && handleLoading();
    }).catch((err) => {
        console.log(err)
        temp_option = []
    })
    return {
        options: [...temp_option],
        hasMore: (array?.length + temp_option?.length) < temp_res?.count
    }
};

export const loadOptionsAmenityCategory = async (search = "",array, handleLoading=()=>false, endPoint) => {
    let offset, temp_res, temp_option = 0;
    let limit = 20
    if (search && !Boolean(array?.length)) { offset = 0; }
    else { offset = array?.length; }
    let payload = { offset, limit, search }

    await NetworkCall(
        `${config.public_listing_api_url}${endPoint}`,
        NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
        temp_res = res?.data?.response
        temp_option = temp_res?.amenity_category?.map(i => {
            return {
                value: i?.id,
                label: i?.name
            }
        })
        handleLoading && handleLoading();
    }).catch((err) => {
        console.log(err)
        temp_option = []
    })
    return {
        options: [...temp_option],
        hasMore: (array?.length + temp_option?.length) < temp_res?.count
    }
};