import React from "react";
import { withNavBars } from "../../HOCs";
import AnnouncementBoard from "./accouncementBoard";

class AnnouncementBoardParent extends React.Component {
    render() {
        return <AnnouncementBoard {...this.props} />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AnnouncementBoardParent, props);