import React from "react";
import { withNavBars } from "../../HOCs";
import ResidentOnboarding from "./residentOnboarding";

class ResidentOnboardingParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
}
  render() {
    return <ResidentOnboarding {...this?.props}/>;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ResidentOnboardingParent, props);
