export const AgreementTerminationReq = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <linearGradient
        id="c"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0d61a9" />
        <stop offset={0.551} stopColor="#0d60a7" />
        <stop offset={0.75} stopColor="#0f5ca0" />
        <stop offset={0.892} stopColor="#125695" />
        <stop offset={1} stopColor="#154e85" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={0.5}
        y1={-891.995}
        x2={0.5}
        y2={-891.05}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#42a3f2" />
        <stop offset={1} stopColor="#42a4eb" />
      </linearGradient>
      <linearGradient id="d" y1={-443.495} y2={-442.55} xlinkHref="#a" />
      <linearGradient id="e" x1={0} y1={0.5} x2={1} y2={0.5} xlinkHref="#a" />
      <linearGradient
        id="b"
        x1={0.5}
        y1={-905.995}
        x2={0.5}
        y2={-905.05}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#1d59b3" />
        <stop offset={1} stopColor="#195bbc" />
      </linearGradient>
      <linearGradient id="f" y1={-909.995} y2={-909.05} xlinkHref="#b" />
      <linearGradient id="g" y1={-913.995} y2={-913.05} xlinkHref="#b" />
      <linearGradient id="h" y1={-901.995} y2={-901.05} xlinkHref="#b" />
    </defs>
    <path data-name="Rectangle 33" fill="none" d="M0 0h32v32H0z" />
    <path
      data-name="Rectangle 52642"
      transform="translate(15.7 7)"
      fill="url(#c)"
      d="M0 0h10.8v21H0z"
    />
    <path
      data-name="Rectangle 52643"
      transform="translate(24.1 11.8)"
      fill="url(#a)"
      d="M0 0h1.2v1.2H0z"
    />
    <path
      data-name="Rectangle 52644"
      transform="translate(21.7 11.8)"
      fill="url(#a)"
      d="M0 0h1.2v1.2H0z"
    />
    <path
      data-name="Rectangle 52645"
      transform="translate(19.3 11.8)"
      fill="url(#a)"
      d="M0 0h1.2v1.2H0z"
    />
    <path
      data-name="Rectangle 52646"
      transform="translate(24.1 8.2)"
      fill="url(#d)"
      d="M0 0h1.2v2.4H0z"
    />
    <path
      data-name="Rectangle 52647"
      transform="translate(21.7 8.2)"
      fill="url(#d)"
      d="M0 0h1.2v2.4H0z"
    />
    <path
      data-name="Rectangle 52648"
      transform="translate(19.3 8.2)"
      fill="url(#d)"
      d="M0 0h1.2v2.4H0z"
    />
    <path
      data-name="Rectangle 52649"
      transform="translate(16.9 11.8)"
      fill="url(#a)"
      d="M0 0h1.2v1.2H0z"
    />
    <path
      data-name="Rectangle 52650"
      transform="translate(16.9 8.2)"
      fill="url(#d)"
      d="M0 0h1.2v2.4H0z"
    />
    <path
      data-name="Path 93453"
      d="m18.1 12.4-6.6-3.6-6.6 3.6V28h13.2Z"
      fill="#d35230"
    />
    <path
      data-name="Path 93454"
      d="m36.211 22.582-3.989-1.5a1.193 1.193 0 0 0-.843 0l-3.989 1.5a.6.6 0 0 0-.389.565V34.8h9.6V23.147a.6.6 0 0 0-.39-.565Z"
      transform="translate(-8.9 -6.8)"
      fill="url(#e)"
    />
    <path
      data-name="Path 93455"
      d="M18.1 13a.6.6 0 0 1-.287-.073L11.5 9.483l-6.313 3.444a.6.6 0 0 1-.575-1.054l6.6-3.6a.6.6 0 0 1 .575 0l6.6 3.6A.6.6 0 0 1 18.1 13Z"
      fill="#b83c1f"
    />
    <path
      data-name="Path 93456"
      d="M23 7h10.8l-2.04-2.55a1.2 1.2 0 0 0-.937-.45h-4.846a1.2 1.2 0 0 0-.937.45Z"
      transform="translate(-7.3)"
      fill="url(#e)"
    />
    <g data-name="Group 96953">
      <path
        data-name="Rectangle 52651"
        transform="translate(24.7 20.2)"
        fill="url(#b)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52652"
        transform="translate(22.3 20.2)"
        fill="url(#b)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52653"
        transform="translate(19.9 20.2)"
        fill="url(#b)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52654"
        transform="translate(24.7 22.6)"
        fill="url(#f)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52655"
        transform="translate(22.3 22.6)"
        fill="url(#f)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52656"
        transform="translate(19.9 22.6)"
        fill="url(#f)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52657"
        transform="translate(24.7 25)"
        fill="url(#g)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52658"
        transform="translate(22.3 25)"
        fill="url(#g)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52659"
        transform="translate(19.9 25)"
        fill="url(#g)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52660"
        transform="translate(24.7 17.8)"
        fill="url(#h)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52661"
        transform="translate(22.3 17.8)"
        fill="url(#h)"
        d="M0 0h1.2v1.2H0z"
      />
      <path
        data-name="Rectangle 52662"
        fill="url(#h)"
        d="M0 0h1.2v1.2H0z"
        transform="translate(19.9 17.8)"
      />
    </g>
    <g data-name="Group 96954" fill="#ff8f6b">
      <path data-name="Rectangle 52663" d="M6.7 14.8h2.4v2.4H6.7z" />
      <path data-name="Rectangle 52664" d="M10.3 14.8h2.4v2.4h-2.4z" />
      <path data-name="Rectangle 52665" d="M13.9 14.8h2.4v2.4h-2.4z" />
      <path data-name="Rectangle 52666" d="M6.7 19h2.4v2.4H6.7z" />
      <path data-name="Rectangle 52667" d="M10.3 19h2.4v2.4h-2.4z" />
      <path data-name="Rectangle 52668" d="M13.9 19h2.4v2.4h-2.4z" />
      <path data-name="Rectangle 52669" d="M6.7 23.2h2.4v2.4H6.7z" />
      <path data-name="Rectangle 52670" d="M10.3 23.2h2.4v2.4h-2.4z" />
      <path data-name="Rectangle 52671" d="M13.9 23.2h2.4v2.4h-2.4z" />
    </g>
  </svg>
)
