export const GeneralJobIcon = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_116015" data-name="Group 116015" transform="translate(0.001)">
                <g id="Rectangle_58161" data-name="Rectangle 58161" transform="translate(-0.001)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <path id="icons8-cv" d="M13.625,3a2.03,2.03,0,0,0-2,1.8h-1.6A2.025,2.025,0,0,0,8,6.825v12.15A2.025,2.025,0,0,0,10.025,21h10.35A2.025,2.025,0,0,0,22.4,18.975V6.825A2.025,2.025,0,0,0,20.375,4.8h-1.6a2.03,2.03,0,0,0-2-1.8Zm0,1.35h3.15a.675.675,0,0,1,0,1.35h-3.15a.675.675,0,0,1,0-1.35ZM15.2,9.3a1.8,1.8,0,1,1-1.8,1.8A1.8,1.8,0,0,1,15.2,9.3Zm-2.784,4.95h5.57a.816.816,0,0,1,.815.816v.188c0,.865-1.144,2.146-3.6,2.146s-3.6-1.281-3.6-2.146v-.188A.816.816,0,0,1,12.416,14.25Z" transform="translate(-5.2 -2.5)" fill="#b3a16d" />
            </g>
        </svg>

    )
}