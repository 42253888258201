import React from "react";
import { withNavBars } from "../../HOCs";
import ItemSubCategoryMaster from "./itemSubCategoryMaster";

class ItemSubCategoryMasterParent extends React.Component {
  render() {
    return <ItemSubCategoryMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ItemSubCategoryMasterParent, props);
