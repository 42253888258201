import React from "react";
import { withNavBars } from "../../HOCs";
import ManagementCommitteRoleMaster from "./manegementCommitteRoleMaster";
class ManagementCommitteeRoleMasterParent extends React.Component {
  render() {
    return (
        <ManagementCommitteRoleMaster />
    );
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(ManagementCommitteeRoleMasterParent , props);