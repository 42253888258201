
export const RenewedDateIcon = () =>(
    <svg id="Group_105494" data-name="Group 105494" xmlns="http://www.w3.org/2000/svg"  width="48" height="48" viewBox="0 0 48 48">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_55683" data-name="Rectangle 55683" width="32" height="32" fill="#fff" stroke="#707070" stroke-width="1"/>
    </clipPath>
    <linearGradient id="linear-gradient" x1="0.204" y1="7.233" x2="1.035" y2="7.233" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#3537b0"/>
      <stop offset="1" stop-color="#4646cf"/>
    </linearGradient>
    <radialGradient id="radial-gradient" cx="0.628" cy="0.804" r="0.797" gradientUnits="objectBoundingBox">
      <stop offset="0"/>
      <stop offset="1" stop-opacity="0"/>
    </radialGradient>
    <linearGradient id="linear-gradient-2" x1="-0.012" y1="9.912" x2="0.756" y2="9.144" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#05acb3"/>
      <stop offset="1" stop-color="#038387"/>
    </linearGradient>
  </defs>
  <rect id="Rectangle_55573" data-name="Rectangle 55573" width="48" height="48" rx="24" fill="#fff"/>
  <g id="Mask_Group_93140" data-name="Mask Group 93140" transform="translate(8 8)" clip-path="url(#clip-path)">
    <g id="icons8-renew" transform="translate(4 5)">
      <path id="Path_99258" data-name="Path 99258" d="M41.756,11H40V28.561h1.756a1.17,1.17,0,0,0,1.171-1.171V12.171A1.17,1.17,0,0,0,41.756,11Z" transform="translate(-18.341 -8.659)" fill="url(#linear-gradient)"/>
      <path id="Path_99259" data-name="Path 99259" d="M25.244,25.732V8.171A1.17,1.17,0,0,0,24.073,7H4.171A1.17,1.17,0,0,0,3,8.171V25.732A1.17,1.17,0,0,0,4.171,26.9H26.415A1.17,1.17,0,0,1,25.244,25.732Z" transform="translate(-3 -7)" fill="#5286ff"/>
      <path id="Path_99260" data-name="Path 99260" d="M24.146,15.341H6.585A.586.586,0,0,1,6,14.756V13.585A.586.586,0,0,1,6.585,13H24.146a.586.586,0,0,1,.585.585v1.171A.586.586,0,0,1,24.146,15.341Z" transform="translate(-4.244 -9.488)" fill="#fff"/>
      <path id="Path_99261" data-name="Path 99261" d="M13.61,29.78H6.585A.586.586,0,0,1,6,29.2v-7.61A.586.586,0,0,1,6.585,21H13.61a.586.586,0,0,1,.585.585V29.2A.586.586,0,0,1,13.61,29.78Z" transform="translate(-4.244 -12.805)" fill="#fff"/>
      <path id="Path_99262" data-name="Path 99262" d="M32.2,22.756H24V21.585A.586.586,0,0,1,24.585,21H31.61a.586.586,0,0,1,.585.585Z" transform="translate(-11.707 -12.805)" fill="#fff"/>
      <rect id="Rectangle_55849" data-name="Rectangle 55849" width="8.195" height="1.756" transform="translate(12.293 9.951)" fill="#e6eeff"/>
      <rect id="Rectangle_55850" data-name="Rectangle 55850" width="8.195" height="1.756" transform="translate(12.293 11.707)" fill="#ccdcff"/>
      <rect id="Rectangle_55851" data-name="Rectangle 55851" width="8.195" height="1.756" transform="translate(12.293 13.463)" fill="#b3cbff"/>
      <path id="Path_99263" data-name="Path 99263" d="M31.61,34.756H24.585A.586.586,0,0,1,24,34.171V33h8.2v1.171A.586.586,0,0,1,31.61,34.756Z" transform="translate(-11.707 -17.78)" fill="#9abaff"/>
      <path id="Path_99264" data-name="Path 99264" d="M33.024,26a7,7,0,0,0-6.793,8.78h9.72a1.17,1.17,0,0,0,1.171-1.171V27.331A6.977,6.977,0,0,0,33.024,26Z" transform="translate(-12.537 -14.878)" fill="url(#radial-gradient)"/>
      <path id="Path_99265" data-name="Path 99265" d="M39.707,33.854A5.854,5.854,0,1,1,33.854,28,5.854,5.854,0,0,1,39.707,33.854Z" transform="translate(-13.366 -15.707)" fill="url(#linear-gradient-2)"/>
      <path id="Path_99266" data-name="Path 99266" d="M35.214,39.124a3.953,3.953,0,0,1-2.694-1.043,4,4,0,0,1-.6-5.1l-.163-.163a.784.784,0,0,1,.554-1.338h1.776a.868.868,0,0,1,.867.867v1.776a.784.784,0,0,1-1.327.565,1.685,1.685,0,0,0-.057.729,1.65,1.65,0,0,0,.728,1.146,1.661,1.661,0,0,0,1.831,0,1.673,1.673,0,0,0,.365-2.468.8.8,0,0,1,.042-1.079l.5-.5a.8.8,0,0,1,.565-.234.786.786,0,0,1,.591.266,4,4,0,0,1-.286,5.535A3.949,3.949,0,0,1,35.214,39.124Z" transform="translate(-14.72 -17.151)" opacity="0.05"/>
      <path id="Path_99267" data-name="Path 99267" d="M35.417,39.039A3.65,3.65,0,0,1,32.5,33.159l-.338-.338a.491.491,0,0,1,.347-.839h1.776a.575.575,0,0,1,.574.574v1.776a.493.493,0,0,1-.493.492.487.487,0,0,1-.346-.145l-.29-.29a1.977,1.977,0,0,0-.253,1.278,1.958,1.958,0,0,0,3.876-.014,1.968,1.968,0,0,0-.436-1.537.5.5,0,0,1,.024-.684l.5-.5a.51.51,0,0,1,.358-.148.5.5,0,0,1,.371.166,3.7,3.7,0,0,1-.266,5.127,3.658,3.658,0,0,1-2.493.963Z" transform="translate(-14.924 -17.358)" opacity="0.07"/>
      <path id="Path_99268" data-name="Path 99268" d="M38.159,33.349a.21.21,0,0,0-.3-.005l-.5.5a.213.213,0,0,0-.006.29,2.254,2.254,0,1,1-3.45.009l.535.535a.2.2,0,0,0,.339-.14V32.763a.282.282,0,0,0-.282-.282H32.713a.2.2,0,0,0-.14.339l.526.526a3.376,3.376,0,0,0,.222,4.72,3.383,3.383,0,0,0,4.839-4.716Z" transform="translate(-15.125 -17.565)" fill="#fff"/>
    </g>
  </g>
</svg>

)