import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TickIcon } from "../../../assets";
import CaretDown from "../../../assets/caretIcon";
import CaretUpIcon from "../../../assets/caretUp";
import NoDataFound from "../../../assets/noData";
import { TextBox } from "../../../components";

import { PriorityStyles } from "./style";
const PropertySelect = ({
    value = "",
    onChange = () => false,
    options = [],
    icon = "",
    selected = "",
    type = "",
    setSelected = () => false,
    handleSearch = () => false,

    fetchMoreData = () => false,
    reload = () => false,
    placeholder = "Property Name here",
    setOffset = () => false,
    t
}) => {
    // const [initialvalue, setInitialValue] = useState("low")
    const [open, setOpen] = React.useState(false);
    const [is_search, setIsSearch] = React.useState(false)
    const wrapperRef = useRef(null);
    // const size = useWindowDimensions()
    // const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setOpen(!open)
        if (!is_search) {
            reload(type)
        }
        setOffset(0)
    };
    const handleChangeProperty = (e) => {
        setIsSearch(true)
        handleSearch(type, e?.target.value)
    }
    React.useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const handleChange = (type, data) => {
        setSelected(type, data)
        setIsSearch(false)
        setOpen(false)
    }

    const classes = PriorityStyles();
    const language = localStorage.getItem("i18nextLng")
    return (
        <Box sx={{ marginInlineEnd: "12px!important" }}>

            <Box
                className={classes.priorityBox}
                style={{ width: type === "block" ? "170px" : type === "floor" ? "140px" : "auto" }}
                onClick={handleClick}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup={true}
                aria-expanded={open ? true : false}
            >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Box display="flex" justifyContent={"center"} alignItems="center">{icon}</Box>
                    <Box>
                        <TextBox label={null}
                            placeholder={placeholder}
                            border={0}
                            padding="8px 0px 8px 0px"
                            className={classes.textBox}
                            endAdornment={open ?
                                <CaretUpIcon className={classes.arrowIcon} />
                                : <CaretDown className={classes.arrowIcon} />}

                            value={value}
                            onChange={(e) => handleChangeProperty(e)}
                        />
                    </Box>
                </Stack>
                {/* {open ?
                    <CaretUpIcon className={classes.arrowIcon} />
                    : <CaretDown className={classes.arrowIcon} />} */}
            </Box >
            {open &&
                <Box className={language === "ar" ? classes.amenity_content_arabic : classes.amenity_content} ref={wrapperRef}>
                    {options?.length > 0 ?
                        <InfiniteScroll
                            dataLength={options?.length ?? ""}
                            next={() => fetchMoreData(type)}
                            hasMore={true}
                            height={200}

                        >
                            <Grid container spacing={2}>
                                {options?.map((item, index) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={4} onClick={() => handleChange(type, item)}>
                                            <Box display="flex" justifyContent={"space-between"} className={selected.id === item.id ? classes.applySelectedBorderBox : classes.applyBorderBox} alignItems="center">
                                                <Stack spacing={1} direction={"row"} alignItems="center">

                                                    <Avatar src={item.logo} />
                                                    <Box>
                                                        <Typography className={classes.name} noWrap>{item.name}</Typography>
                                                        {item.no_of_units > 0 &&
                                                            <Typography className={classes.subName} noWrap>
                                                                {`${item?.no_of_units} Available`} {item?.vacant_units > 0 && `/ ${item?.vacant_units} Vacant`}

                                                            </Typography >
                                                        }
                                                        {item?.block_name !== null &&
                                                            <Typography className={classes.subblockName} noWrap>
                                                                {item?.block_name}
                                                            </Typography >
                                                        }
                                                    </Box >
                                                    {/* <Box marginLeft={"24px !important"}> */}

                                                    {/* </Box> */}

                                                </Stack >
                                                {
                                                    selected.id === item.id &&
                                                    <TickIcon color="#5078E1" />
                                                }
                                            </Box >
                                        </Grid >
                                    )
                                })}


                            </Grid >
                        </InfiniteScroll >
                        : <Box display={"flex"} justifyContent="center" alignItems={"center"} height={250}><NoDataFound /></Box>}

                </Box >
            }

        </Box >
    );
};
export default PropertySelect;
