export const PropertyHeading = [
    { title: "Property No.", field: "propertyNo" },
    { title: "Property Name", field: "propertyName" },
    { title: "Company Name", field: "companyName" },
    { title: "Location", field: "location" },
    { title: "Units", field: "units" },
    { title: "Property Type", field: "propertyType" },
    { title: "Status", field: "status" },
]

export const Propertypath = [
    "propertyNo",
    "propertyName",
    "companyName",
    "location",
    "units",
    "propertyType",
    "status",
]

export const PropertyType = [
    { type: ["text"], name: "propertyNo" },
    { type: ["text"], name: "propertyName" },
    { type: ["text"], name: "companyName" },
    { type: ["description"], name: "location" },
    { type: ["text"], name: "units" },
    { type: ["propertyType"], name: "propertyType" },
    { type: ["status"], name: "status" },
]

export const UnitHeading = [
    { title: "Unit Number", field: "unitNo", },
    { title: "Unit Name", field: "unitName", },
    { title: "Unit Category", field: 'unitcategory', },
    { title: "Revenue Type", field: "revenuetype", },
    { title: "Unit Purpose", field: "unitpurpose", },
    { title: "Status", field: "status", },
]

export const UnitPath = [
    "unitNo",
    "unitName",
    "unitcategory",
    "revenuetype",
    "unitpurpose",
    "status"
]

export const UnitType = [
    { type: ["text"], name: "unitNo" },
    { type: ["text"], name: "unitName" },
    { type: ["text"], name: "unitcategory" },
    { type: ["text"], name: "revenuetype" },
    { type: ["text"], name: "unitpurpose" },
    { type: ["status"], name: "status" },
]

export const ShortTermPricingHeading = [
    { title: "Revenue Type", field: "revenueType" },
    { title: "Pricing Component", field: "pricingComponent" },
    { title: "Component Type", field: "componentType" },
    { title: "Vaule", field: "value" },
    { title: "Qty", field: "quantity" },
    { title: "Taxable", field: "taxable" },
    { title: "Chargeable", field: "chargeable" },
    { title: "", field: "icon" },
]

export const ShortTermPricingPath = [
    "revenueType",
    "pricingComponent",
    "componentType",
    "value",
    "quantity",
    "taxable",
    "chargeable",
]

export const ShortTermPricingType = [
    { type: ["text"], name: "revenueType" },
    { type: ["text"], name: "pricingComponent" },
    { type: ["text"], name: "componentType" },
    { type: ["text"], name: "value" },
    { type: ["text"], name: "quantity" },
    { type: ["text"], name: "taxable" },
    { type: ["text"], name: "chargeable" },
    { type: ["more_2"], icon: "icon" },
]