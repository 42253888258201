import React from "react"

export const SiteVisitIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_110226" data-name="Group 110226" transform="translate(-184 -328)">
    <rect id="Rectangle_55241" data-name="Rectangle 55241" width="32" height="32" rx="2" transform="translate(184 328)" fill="#dbe2ff"/>
    <g id="Group_111487" data-name="Group 111487" transform="translate(0 -1)">
      <g id="Rectangle_56608" data-name="Rectangle 56608" transform="translate(188 333)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-map-marker" d="M18.428,4A4.417,4.417,0,0,0,13.99,8.385a4.356,4.356,0,0,0,1.074,2.856c.074.083,1.817,2.035,2.406,2.589a1.4,1.4,0,0,0,1.917,0c.684-.643,2.339-2.511,2.413-2.6a4.347,4.347,0,0,0,1.068-2.849A4.417,4.417,0,0,0,18.428,4ZM12.214,6.106V17.073l3.534,2.583v-6.34c-.667-.722-1.337-1.472-1.347-1.484a5.183,5.183,0,0,1-1.1-4.844Zm-1.332.017L6.274,9.474A.666.666,0,0,0,6,10.013v9.321a.666.666,0,0,0,1.058.538l3.825-2.782Zm7.546.984A1.332,1.332,0,1,1,17.1,8.439,1.332,1.332,0,0,1,18.428,7.107Zm3.38,5.447c-.571.633-1.372,1.507-1.814,1.922a2.275,2.275,0,0,1-3.052.063v5.269l4.591-3.351a.664.664,0,0,0,.274-.538Z" transform="translate(186 333.411)" fill="#7683b7"/>
    </g>
  </g>
</svg>

)