import React from "react";
export const FullScreenIcon = (props) => {
    const width = props?.width ? props?.width : 14.975;
    const height = props?.height ? props?.height : 14.975;
    // const color = props?.color ? props?.color : '#9297a5';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 14.975 14.975"
            {...props}
        >
            <path id="Maximize" d="M4.664,3A1.676,1.676,0,0,0,3,4.664V7.992H4.664V4.664H7.992V3Zm8.319,0V4.664h3.328V7.992h1.664V4.664A1.676,1.676,0,0,0,16.311,3ZM3,12.983v3.328a1.676,1.676,0,0,0,1.664,1.664H7.992V16.311H4.664V12.983Zm13.311,0v3.328H12.983v1.664h3.328a1.676,1.676,0,0,0,1.664-1.664V12.983Z" transform="translate(-3 -3)" />
        </svg>
    );
};

