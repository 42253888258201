export const Notification = (props) => (
    <svg
        style={{ cursor: "pointer" }}
        width={24}
        height={24}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17.505 16.754H6.48a1.33 1.33 0 0 1-1.2-1.9l1.013-2.037v-2.375a5.766 5.766 0 0 1 5.428-5.835 5.7 5.7 0 0 1 5.975 5.693v2.525l1.008 2.026a1.329 1.329 0 0 1-1.2 1.906l.001-.003ZM11.749 5.176Zm-2 12.338c.293 1.729 2.349 2.493 3.7 1.375.421-.349.703-.837.794-1.375H9.749Z"
            fill="#fff"
        />
    </svg>
)