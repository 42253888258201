import React from "react"

export const PostedDate = () => (
    <svg id="Group_98671" data-name="Group 98671" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="Group_95678" data-name="Group 95678">
            <rect id="Rectangle_49614" data-name="Rectangle 49614" width="40" height="40" rx="20" fill="#bddef2" />
        </g>
        <path id="icons8-calendar" d="M21,9.333V8.708A2.711,2.711,0,0,0,18.292,6H8.708A2.711,2.711,0,0,0,6,8.708v.625ZM6,10.583v7.708A2.711,2.711,0,0,0,8.708,21h9.583A2.711,2.711,0,0,0,21,18.292V10.583ZM9.958,18.5A1.042,1.042,0,1,1,11,17.458,1.042,1.042,0,0,1,9.958,18.5Zm0-3.75A1.042,1.042,0,1,1,11,13.708,1.042,1.042,0,0,1,9.958,14.75ZM13.5,18.5a1.042,1.042,0,1,1,1.042-1.042A1.042,1.042,0,0,1,13.5,18.5Zm0-3.75a1.042,1.042,0,1,1,1.042-1.042A1.042,1.042,0,0,1,13.5,14.75Zm3.542,0a1.042,1.042,0,1,1,1.042-1.042A1.042,1.042,0,0,1,17.042,14.75Z" transform="translate(7 7)" fill="#5a9dc7" />
    </svg>

)