import React from "react";
import { Box, Typography, Grid, Avatar, Stack } from "@mui/material";
import { useStyles } from "./style";
import { TickIcon } from "../../assets";
import { SearchFilter } from "../../components";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import InfiniteScroll from "react-infinite-scroll-component";
import { stringAvatar } from "../../utils";

export const SelectAnnounsment = ({
  title = "",
  subtitle = "",
  selectAll = "",
  selectAllValue = false,
  count = "01",
  searchValue = "",
  placeHolder = "",
  searchFunction = () => false,
  data = [],
  chooseFuncion = () => false,
  selectAllFunction = () => false,
  fetchMoreData = () => false,
  div_height = "calc(100vh - 253px)",
  infinite_scroll_height = "calc(100vh - 417px)",
  type = "",
  isGridValueChange = false,
  isView = false
}) => {
  const classes = useStyles({ div_height, infinite_scroll_height });
  return (
    <div className={classes.root}>

      <Grid container>
        <Grid item sm={9} md={9} lg={9}>
          <Stack direction={"row"} alignItems={"center"}>
            <span className={classes.draft}>{count}</span>
            <Stack sx={{ marginInlineStart: "16px" }}>

              <Typography className={classes.title} textTransform={"capitalize"}>{title ?? ""}</Typography>
              {!isView &&
                <Typography className={classes.subtitle}>
                  {subtitle ?? ""}
                </Typography>
              }
            </Stack>
          </Stack>
        </Grid>
        {!isView &&
          <Grid item sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
            <Stack direction={"row"} columnGap={1} onClick={selectAllFunction}>
              <Box sx={{ width: "6px" }}>
                <TickIcon
                  color={selectAllValue ? "#5078E1" : "#e4e8ee"}
                />
              </Box>
              <Typography className={classes.selectAll}>{selectAll}</Typography>
            </Stack>
          </Grid>
        }
      </Grid>
      {!isView &&
        <>
          <Box height={"16px"} />
          {/* search box */}
          <SearchFilter
            value={searchValue}
            placeholder={placeHolder}
            handleChange={(value) => searchFunction(value)}
            marginBottom="0px !important"
          />
        </>
      }
      <Box height={"16px"} />
      {/* options */}

      <Box className={classes.divRoot}>
        <InfiniteScroll
          dataLength={data?.length ?? ""}
          next={fetchMoreData}
          hasMore={true}
          height={infinite_scroll_height}
        >
          <Grid container spacing={2}>
            {data?.map((x, index) => {
              return (
                <Grid item xs={6} sm={6} md={type !== "property" && 12} lg={6} xl={isGridValueChange ? 6 : 4}>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} className={classes.selectBox}>
                    <Stack direction={"row"} alignItems={"center"}>

                      {/* {x?.logo && ( */}
                      <Avatar src={x?.logo}
                        alt="images"
                        className={classes.images}
                        {...stringAvatar(x?.title)}></Avatar>
                      {/* )} */}

                      <Stack ml={x?.logo ? 2 : 0} sx={{ marginInlineStart: "16px" }}>
                        <Typography className={classes.optionTitle} noWrap sx={{
                          width: {
                            lg: type === "property" ? "200px" : "100px",
                            xl: type === "property" ? "200px" : "100px",
                            sm: type === "property" ? "140px" : "80px",
                            xs: type === "property" ? "140px" : "80px"
                          }
                        }}>
                          {/* {x?.title?.length > 0
                            ? `${x?.title?.substring(0, 13)}${
                                x?.title?.length > 13 ? "..." : ""
                              }`
                            : "-"} */}
                          {x?.title}
                        </Typography>
                        <Typography className={classes.optionSub} noWrap sx={{
                          width: {
                            lg: type === "property" ? "200px" : "100px",
                            xl: type === "property" ? "200px" : "100px",
                            sm: type === "property" ? "140px" : "80px",
                            xs: type === "property" ? "140px" : "80px"
                          }
                        }}>
                          {/* {x?.subTitle?.length > 0
                            ? `${x?.subTitle?.substring(0, 15)}${
                                x?.subTitle?.length > 15 ? "..." : ""
                              } `
                            : "-"} */}
                          {x?.subTitle}
                        </Typography>
                      </Stack>
                    </Stack>
                    {!isView &&
                      <Avatar
                        className={
                          x?.is_active
                            ? classes.completedCheckboxStyle
                            : classes.checkboxStyle
                        }
                        variant="circular"
                        onClick={() => {
                          chooseFuncion(!x?.is_active, index, "is_active");
                        }}
                      >
                        <DoneOutlinedIcon
                          className={classes.checkboxIconStyle}
                        />
                      </Avatar>
                    }
                  </Stack >


                </Grid >
              );
            })}
          </Grid >
        </InfiniteScroll >
      </Box >
    </div >
  );
};
