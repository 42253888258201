import React from "react"

export const InvoiceRaisedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_118506" data-name="Group 118506" transform="translate(-197 -106)">
                <g id="Rectangle_52035" data-name="Rectangle 52035" transform="translate(197 106)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-buy-for-change" d="M6.7,5A2.7,2.7,0,0,0,4,7.7V22.1a.9.9,0,0,0,1.118.873L7.3,22.428l2.182.545a.893.893,0,0,0,.436,0l2.182-.545,2.182.545A.9.9,0,0,0,15.4,22.1v-.519a7.5,7.5,0,1,0,0-10.962V7.7a.9.9,0,1,1,1.8,0v.359a8.625,8.625,0,0,1,1.784-.523A2.7,2.7,0,0,0,16.3,5Zm.6,4.8h4.8a.9.9,0,1,1,0,1.8H7.3a.9.9,0,1,1,0-1.8Zm13.2.6a5.7,5.7,0,1,1-5.1,8.244V13.556A5.681,5.681,0,0,1,20.5,10.4Zm-.014,1.187a.9.9,0,0,0-.87.734A2.374,2.374,0,0,0,17.8,14.6h0A2.4,2.4,0,0,0,20.17,17h.661a.6.6,0,0,1,0,1.192l-.887.007a.4.4,0,0,1-.388-.3.9.9,0,0,0-1.72.531,2.18,2.18,0,0,0,1.778,1.455.9.9,0,0,0,1.766-.009A2.372,2.372,0,0,0,20.83,15.2H20.17a.571.571,0,0,1-.57-.6h0a.571.571,0,0,1,.561-.6L20.6,14a.407.407,0,0,1,.39.3.9.9,0,1,0,1.724-.518A2.148,2.148,0,0,0,21.4,12.473.9.9,0,0,0,20.486,11.587ZM7.3,13.4h4.8a.9.9,0,1,1,0,1.8H7.3a.9.9,0,1,1,0-1.8Zm0,3.6h3a.9.9,0,0,1,0,1.8h-3a.9.9,0,0,1,0-1.8Zm9.258,6.843A2.69,2.69,0,0,1,14.5,24.8a2.86,2.86,0,0,1-.594-.066l-2-.45-1.481.414a2.689,2.689,0,0,1-.727.1,2.766,2.766,0,0,1-.732-.1L7.6,24.314V26.9a.9.9,0,0,0,1.1.878l2.445-.55,1.915.538a.9.9,0,0,0,.488,0l1.914-.538,2.445.55a.891.891,0,0,0,.2.022.9.9,0,0,0,.9-.9V24.664A8.615,8.615,0,0,1,16.558,23.843Z" transform="translate(193 101.6)" fill="#b3776d" />
            </g>
        </svg>
    )
}