import { useContext, useEffect, useState } from 'react';
import { Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, activeOptions, activeOptionsList, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from '../../utils';
import { useStyles } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { TABLE_HEADING, TABLE_PATH, TABLE_TYPE, INITIAL_ADD_FORM_STATE, INITIAL_FILTER_STATE, INITIAL_TABLE_PAGE, INITIAL_TABLE_LIMIT, INITIAL_TABLE_OFFSET } from './utils/tableUtils';
import { NetworkCall } from '../../networkcall';
import { BackendRoutes } from '../../router/routes';
import { withNamespaces } from 'react-i18next';
import { config } from '../../config';

const CustomerGroupMaster = (props) => {
    const { loading, handleLoading, t = () => false } = props;
    const table_offset = INITIAL_TABLE_OFFSET;

    const classes = useStyles();
    const debounce = UseDebounce();

    const alert = useContext(AlertContext);
    const backdrop = useContext(BackdropContext);
    const auth = useContext(AuthContext);

    const [permission_state, set_permission_state] = useState({});
    const [selected_company, set_selected_company] = useState("");
    const [company_list, set_company_list] = useState([]);
    const [is_button_disable, set_is_button_disable] = useState(false);
    const [is_table_loading, set_is_table_loading] = useState(true);
    const [table_state, set_table_state] = useState({ rows: [], total_rows_count: 0, });
    const [table_search_text, set_table_search_text] = useState("");
    const [table_page, set_table_page] = useState(INITIAL_TABLE_PAGE);
    const [table_limit, set_table_limit] = useState(INITIAL_TABLE_LIMIT);
    const [filter_state, set_filter_state] = useState(INITIAL_FILTER_STATE);
    const [is_filter_open, set_is_filter_open] = useState(false);
    const [is_add_form_open, set_is_add_form_open] = useState(false);
    const [add_form_state, set_add_form_state] = useState(INITIAL_ADD_FORM_STATE);

    useEffect(() => {
        const permission = getRoutePermissionNew(auth);
        if (permission) {
            set_permission_state(permission);
            if (permission?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    set_company_list(company?.list);
                    set_selected_company(company?.selected);
                    getInitialData({ company: company?.selected });
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getInitialData = async ({ company = selected_company }) => {
        return new Promise(async (resolve, reject) => {
            try {
                await Promise.all([
                    getTableData({ company }),
                ]);
                handleLoading(false);
            } catch (error) {
                reject(error);
            }
        })
    }

    const getTableData = ({
        company = selected_company,
        offset = table_offset,
        limit = table_limit,
        search = table_search_text,
        filter = filter_state,
    }) => {
        set_is_button_disable(true);
        set_is_table_loading(true);
        let payload = {
            offset, limit, search,
            company_id: company?.value,
            is_active: filter?.is_active?.length === 0
                ? [true, false]
                : filter?.is_active,
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.customer_group_master_list}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            let temp_response = response?.data?.data?.data ?? []
            let temp_row = temp_response?.map?.((_) => {
                return {
                    id: _?.id,
                    reference_no: _?.reference_no ?? "-",
                    name: _?.type ?? "-",
                    is_active: _?.is_active ? "Active" : "Inactive",
                    data: _,
                }
            })
            set_table_state({
                rows: temp_row ?? [],
                total_rows_count: response?.data?.data?.count ?? 0
            })
            set_is_button_disable(false);
            set_is_table_loading(false);
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }

    const handleCompanyChange = ({ company }) => {
        set_selected_company(company);
        getInitialData({ company });
    }

    const handleTableSearch = ({ search }) => {
        set_table_search_text(search)
        debounce(() => searchTableFunction({ search }), 800)
    }

    const searchTableFunction = ({ search }) => {
        if (table_page > 1) { set_table_page(1) }
        getTableData({ search });
    }

    const handleAdd = () => {
        set_is_button_disable(true);
        NetworkCall(
            `${config.api_url}${BackendRoutes?.customer_group_master_get_id}`,
            NetWorkCallMethods.post, {}, null, true, false
        ).then((response) => {
            let current_id = response?.data?.data?.current_id ?? "";
            set_is_button_disable(false);
            set_add_form_state({ ...INITIAL_ADD_FORM_STATE, reference_no: current_id, error: { reference_no: "", name: "" } });
            set_is_add_form_open(true);
        }).catch((e) => {
            set_is_button_disable(false);
            set_add_form_state({ ...INITIAL_ADD_FORM_STATE, error: { reference_no: "", name: "" } });
            set_is_add_form_open(true);
        });
    }

    const handleTableIcon = ({ type, data }) => {
        const full_data = data?.data
        const temp_add_state = {
            form_type: type,
            id: full_data?.id,
            reference_no: full_data?.reference_no,
            name: full_data?.type,
            is_active: type === "active" ? !full_data?.is_active : full_data?.is_active,
            is_delete: type === "delete",
            error: INITIAL_ADD_FORM_STATE?.error
        }
        set_add_form_state({ ...temp_add_state })
        if (type === "edit" || type === "view") {
            set_is_button_disable(false);
            set_is_add_form_open(true);
        }
        else if (type === "active" || type === "delete") {
            handleCreateEdit({ final_add_state: temp_add_state });
        }
    }

    const handleTablePagination = ({ page = table_page }) => {
        set_table_page(page);
        let offset = (page - 1) * table_limit;
        getTableData({ offset });
    }

    const handleTablePageLimit = ({ limit = table_limit }) => {
        set_table_limit(limit);
        set_table_page(1);
        getTableData({ limit });
    }

    const updateAddDialogState = ({ key, value, error_message }) => {
        let updated_errors = add_form_state?.error;
        updated_errors[key] = error_message ?? "";
        set_add_form_state({ ...add_form_state, [key]: value, error: updated_errors });
    }

    const validate = () => {
        let is_valid = true;
        let error = add_form_state?.error;
        if (add_form_state?.reference_no?.length === 0) { is_valid = false; error.name = t("Reference No is Required") }
        if (add_form_state?.name?.length === 0) { is_valid = false; error.name = t("Name is Required") }
        if (!is_valid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            });
        }
        set_add_form_state({ ...add_form_state, error });
        return is_valid;
    }

    const handleCreateEdit = async ({ final_add_state }) => {
        if ((final_add_state?.form_type === "active" || final_add_state?.form_type === "delete") ? true : validate()) {
            set_is_button_disable(true);

            const payload = {
                company_id: selected_company?.value,
                reference_no: final_add_state.reference_no ?? undefined,
                type: final_add_state?.name ?? undefined,
                is_active: final_add_state?.is_active ?? undefined,
                // is_delete: final_add_state?.is_delete ?? undefined,
            }

            switch (final_add_state?.form_type) {
                case "add":
                    break;
                case "edit":
                    payload.id = final_add_state?.id ?? undefined
                    break;
                case "active":
                    payload.id = final_add_state?.id ?? undefined
                    break;
                case "delete":
                    payload.id = final_add_state?.id ?? undefined
                    break;

                default:
                    break;
            }

            NetworkCall(
                `${config.api_url}${BackendRoutes?.customer_group_master_upsert}`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then(() => {
                set_table_page(1);
                set_table_search_text("");
                getInitialData({ company: selected_company });
                set_is_add_form_open(false);
                set_add_form_state({ ...INITIAL_ADD_FORM_STATE });
                set_is_button_disable(false);
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: final_add_state?.id
                        ? (final_add_state?.form_type === "delete"
                            ? t("Customer Group Master Deleted Successfully.!!!")
                            : t("Customer Group Master Updated Successfully.!!!")
                        )
                        : t("Customer Group Master Created Successfully.!!!"),
                })
            }).catch((error) => {
                updateAddDialogState({
                    key: "reference_no",
                    value: final_add_state?.reference_no,
                    error_message: t("Reference No Already Exists"),
                });
                set_is_button_disable(false);
                alert.setSnack({
                    ...alert, open: true,
                    msg: error?.response?.data?.errors?.errors?.[0]?.msg === "Reference No already exists"
                        ? t("Reference No Already Exists")
                        : t("Some Thing Went Wrong"),
                    severity: error?.response?.data?.errors?.errors?.[0]?.msg === "Reference No already exists"
                        ? AlertProps.severity.info
                        : AlertProps.severity.error,
                });
            });
        } else { return false }
    }

    const handleFilter = ({ filter }) => {
        set_filter_state(filter);
        set_table_page(1);
        getTableData({ filter });
    }

    const render = () => {
        return (
            <Box className={classes.root}>
                <Subheader
                    title={t("Customer Group Master")}
                    hideBackButton={true}
                    select
                    options={company_list}
                    value={selected_company}
                    onchange={(company) => handleCompanyChange({ company })} />
                <Box className={classes.body}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <SearchFilter
                                value={table_search_text}
                                placeholder={t("Search Customer Group Master")}
                                customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                handleChange={(search) => handleTableSearch({ search })} />
                        </Grid>
                        <Grid item xs={8}>
                            <Box display={"flex"} justifyContent={"end"}>
                                <Stack direction="row" spacing={2}
                                    divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}>
                                    <IconButton
                                        className={classes.filter_button}
                                        onClick={() => set_is_filter_open(!is_filter_open)}>
                                        <Badge
                                            variant="dot"
                                            color="primary"
                                            invisible={!(filter_state.is_active?.length > 0)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        onClick={handleAdd}>
                                        {t("Add A Customer Group Master")}
                                    </Button>
                                </Stack>
                            </Box>
                        </Grid >
                        <Grid item xs={12}>
                            <TableWithPagination
                                is_loading={is_table_loading}
                                tableType="no-side"
                                heading={TABLE_HEADING(t)}
                                path={TABLE_PATH}
                                dataType={TABLE_TYPE}
                                rows={table_state?.rows}
                                onClick={() => { }}
                                handleIcon={(type, data) => handleTableIcon({ type, data })}
                                showpagination={true}
                                page={table_page}
                                handlePagination={(page) => handleTablePagination({ page })}
                                limit={table_limit}
                                handleChangeLimit={(limit) => handleTablePageLimit({ limit })}
                                totalRowsCount={table_state?.total_rows_count}
                                height={'calc(100vh - 290px)'}
                                view={true}
                                edit={true}
                                delete={true} />
                        </Grid>
                    </Grid >
                    <FilterGenerator
                        open={is_filter_open}
                        onClose={() => set_is_filter_open(false)}
                        onApply={(filter) => handleFilter({ filter })}
                        components={[
                            {
                                component: "toggleButton",
                                value: filter_state?.is_active,
                                options: activeOptions(t),
                                isMulti: true,
                                state_name: "is_active",
                                label: t("Status")
                            },
                        ]} />
                    <Dialog
                        className={classes.add_dialog}
                        open={is_add_form_open}
                        onClose={() => set_is_add_form_open(false)}>
                        <Box className={classes.add_dialog_header}>
                            <Typography className={classes.add_dialog_header_title}>
                                {add_form_state?.form_type === "add"
                                    ? t("Add Customer Group Master")
                                    : add_form_state?.form_type === "edit"
                                        ? t("Edit Customer Group Master")
                                        : add_form_state?.form_type === "view"
                                            ? t("View Customer Group Master")
                                            : t("Add Customer Group Master")
                                }
                            </Typography>
                            <IconButton onClick={() => set_is_add_form_open(false)}
                                className={classes.add_dialog_close_button}>
                                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                            </IconButton>
                        </Box>
                        <Box className={classes.add_dialog_body}>
                            <TextBox
                                isrequired
                                isReadonly={add_form_state?.form_type === "view"}
                                label={t("Reference No")}
                                placeholder={t("Enter Reference No")}
                                value={add_form_state?.reference_no ?? ""}
                                onChange={(e) => updateAddDialogState({ key: "reference_no", value: e?.target?.value })}
                                isError={add_form_state?.error?.reference_no?.length > 0}
                                errorMessage={add_form_state?.error?.reference_no} />
                            <Box height={16} />
                            <TextBox
                                isrequired
                                isReadonly={add_form_state?.form_type === "view"}
                                label={t("Name")}
                                placeholder={t("Enter Name")}
                                value={add_form_state?.name ?? ""}
                                onChange={(e) => updateAddDialogState({ key: "name", value: e?.target?.value })}
                                isError={add_form_state?.error?.name?.length > 0}
                                errorMessage={add_form_state?.error?.name} />
                            <Box height={16} />
                            <Typography className={classes.add_dialog_field_label} noWrap>{t("Status")}</Typography>
                            <Box className={classes.add_dialog_button_container}>
                                {activeOptionsList?.map((_) => {
                                    return <Button
                                        className={_?.value === add_form_state?.is_active
                                            ? classes.add_dialog_button_selected
                                            : classes.add_dialog_button_unselected
                                        }
                                        onClick={() => (add_form_state?.form_type === "add" ||
                                            add_form_state?.form_type === "edit")
                                            ? updateAddDialogState({ key: "is_active", value: _?.value })
                                            : false
                                        }>
                                        {_?.label}
                                    </Button>
                                })}
                            </Box>
                        </Box>
                        {(add_form_state?.form_type === "add" || add_form_state?.form_type === "edit") &&
                            <Box className={classes.add_dialog_footer}>
                                {add_form_state?.form_type === "edit" &&
                                    <Button
                                        fullWidth
                                        className={classes.add_dialog_footer_close_button}
                                        onClick={() => set_is_add_form_open(false)}>
                                        {t("Cancel")}
                                    </Button>
                                }
                                <Button
                                    variant="contained"
                                    fullWidth
                                    className={classes.add_dialog_footer_button}
                                    disabled={is_button_disable}
                                    onClick={() => handleCreateEdit({ final_add_state: add_form_state })}>
                                    {add_form_state?.form_type === "add"
                                        ? t("Create")
                                        : t("Save")
                                    }
                                </Button>
                            </Box>}
                    </Dialog>
                </Box>
            </Box>
        );
    }

    return accessCheckRender(render, permission_state, "", loading);
}
export default withNamespaces("customerGroupMaster")(CustomerGroupMaster);
