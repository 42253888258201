export const PropertyDtls = {
    CompanyList: [
        {
            title: "Company Name",
            content: "Company Name"
        },
        {
            title: "Property Name",
            content: "Rubix Appartment"
        },
        {
            title: "Property Type",
            content: "Property Type"
        },
        {
            title: "Property Purpose",
            content: "Residential"
        },
        {
            title: "Total Units",
            content: "580"
        },
        {
            title: "Status",
            content: "Active"
        },
    ],
    description: "A while back I needed to count the amount of letters that a piece of text in an email template had (to avoid passing any character limits). Unfortunately, a street bike available at a starting price of Rs. 1,62,916 in India. It is available in 3 variants and 8 colours with top variant price starting from The Yamaha",
    profileImg: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    profileId: "ID - PG2PRP23"
}

export const InspectionItemHeading = (t) => [
    { title: t("Bulk Inspection ID"), field: "reference_no" },
    { title: t("Template Name"), field: "name" },
    { title: t("Qty"), field: "count" },
    { title: "", field: "" }
]

export const InspectionItemPath = [
    "reference_no",
    "name",
    "count",
]



export const BulkpropertyHeading = (t) => [
    { title: t("Image"), field: "logo", width: "100px" },
    { title: t("Property Name"), field: "name", width: "200px" },
    { title: t("Property Number"), field: "property_no", width: "200px" },
    { title: t("Bulk Template Count"), field: "count" },
]

export const BulkpropertyPath = [
    "logo",
    "name",
    "property_no",
    "count",
    "icon",
]

export const BulkpropertyRow = [
    {
        "image": "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
        "name": "alt",
        "companyName": "Property Automate 102",
        "bulkTemplateCounts": "25"
    },
    {
        "image": "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
        "name": "alt",
        "companyName": "Property Automate 102",
        "bulkTemplateCounts": "12"
    },
    {
        "image": "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
        "name": "alt",
        "companyName": "Property Automate 102",
        "bulkTemplateCounts": "10"
    },
    {
        "image": "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
        "name": "alt",
        "companyName": "Property Automate 102",
        "bulkTemplateCounts": "05"
    },
]