import React from "react";

export const SalesLeadIcon = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_243_2221)">
      <circle cx="25.3999" cy="21.9191" r="21" fill="#FF9640" />
    </g>
    <path
      d="M35.4871 20.0113L35.4875 20.0191L35.4881 20.0269C35.4959 20.1253 35.4979 20.2274 35.4991 20.3443L35.4991 20.346C35.5103 21.1438 35.414 21.9394 35.2127 22.7114L35.2126 22.7118C34.9588 23.6882 34.5428 24.6151 33.9819 25.4538C31.2162 29.589 25.6217 30.6993 21.4863 27.9337L21.1452 27.7056L20.8549 27.9956L14.784 34.0619C14.2609 34.5641 13.4341 34.5632 12.9121 34.0592L12.5658 34.4177L12.9121 34.0592C12.3769 33.5425 12.3611 32.6904 12.8759 32.1541L18.9461 26.0842L19.2371 25.7932L19.0075 25.4517C18.0067 23.9627 17.4775 22.2069 17.4887 20.4129H17.4887V20.4098C17.4887 20.2748 17.4915 20.1443 17.4998 20.0167L17.4999 20.0167L17.5003 20.0082C17.7235 15.3752 21.4303 11.6698 26.0637 11.4483C31.0306 11.2109 35.2497 15.0446 35.4871 20.0113ZM35.6965 22.8375C35.9091 22.0221 36.0109 21.1817 35.9991 20.339L35.6965 22.8375Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M31.471 24.422C28.9868 26.5076 25.4283 26.7158 22.7177 24.9341C22.4799 24.778 22.2522 24.607 22.0361 24.422C22.3983 21.8168 24.8038 19.9985 27.4091 20.3606C29.5189 20.6539 31.1774 22.3123 31.4707 24.422H31.471Z"
      fill="#FF9640"
    />
    <path
      d="M29.8798 17.1926C29.8808 17.5718 29.812 17.948 29.6769 18.3024C29.0662 19.9101 27.2678 20.7184 25.6601 20.1078C24.0523 19.4971 23.244 17.6988 23.8546 16.0911C24.4653 14.4834 26.2637 13.6751 27.8715 14.2858C29.079 14.7444 29.8781 15.9009 29.8798 17.1926Z"
      fill="#FF9640"
    />
    <defs>
      <filter
        id="filter0_d_243_2221"
        x="0.399902"
        y="0.919067"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_243_2221"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_243_2221"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
