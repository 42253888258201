import { Dialog, Grid } from "@mui/material";
import React from "react";
import { Slider } from "../../../components";
import { Assets } from "./assets";
import { filterStyles } from "./styles";

export const AssetView = ({ asset, assetAll, title, isNormal = false, maxWidth = 'md' }) => {
  const classes = filterStyles();
  const [open, setOpen] = React.useState(false);

  const openPopup = (index) => {
    if (isNormal) {
      setOpen(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {asset?.length === 1 && (
        <Grid container spacing={1}>
          <Grid item xs={12} onClick={openPopup}>
            <img src={asset[0]?.url} alt="" className={classes.detailImg} />
          </Grid>
        </Grid>
      )}
      {asset?.length > 1 && (
        <Grid container spacing={1} onClick={openPopup}>
          <Grid item xs={7}>
            <img src={asset[0]?.url} alt="" className={classes.detailImg} />
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <img
                  src={asset[1]?.url}
                  alt=""
                  className={classes.detailImg2}
                />
              </Grid>

              <Grid item xs={6}>
                <img
                  src={asset[2]?.url ?? "/images/imagesproperty.svg"}
                  alt=""
                  className={classes.detailImg2}
                />
              </Grid>
              <Grid item xs={6}>
                <img
                  src={asset[3]?.url ?? "/images/imagesproperty.svg"}
                  alt=""
                  className={classes.detailImg2}
                />
              </Grid>
              <Grid item xs={6}>
                {asset?.length > 5 ? (
                  <div className={classes.addImg} onClick={openPopup}>
                    <span className={classes.plus}>
                      +&nbsp;{asset?.length - 5}
                    </span>
                    <img
                      src={asset[4]?.url ?? "/images/imagesproperty.svg"}
                      alt=""
                      className={classes.detailImg2}
                    />
                  </div>
                ) : (
                  <img
                    src={asset[4]?.url ?? "/images/imagesproperty.svg"}
                    alt=""
                    className={classes.detailImg2}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={open}
        fullWidth
        maxWidth={maxWidth ?? "md"}
        onClose={() => setOpen(false)}
        className={classes.dialog}
      >
        {isNormal ? (
          <>
            <Slider assets={assetAll ?? []} padding={"0px 21px 0px 23px"} />
          </>
        ) : (
          <Assets
            assets={assetAll}
            title={title}
            onClose={() => setOpen(false)}
          />
        )}
      </Dialog>
    </>
  );
};
