import React from "react"

export const MoveInIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="Group_110198" data-name="Group 110198" transform="translate(-184 -328)">
            <rect id="Rectangle_55241" data-name="Rectangle 55241" width="32" height="32" rx="2" transform="translate(184 328)" fill="#efd3d3" />
            <g id="Group_111485" data-name="Group 111485" transform="translate(0 -1)">
                <g id="Rectangle_56606" data-name="Rectangle 56606" transform="translate(188 333)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-import" d="M13.552,15.5a.632.632,0,0,1-.893.893L9.711,13.447a.631.631,0,0,1,0-.893l2.947-2.947a.631.631,0,1,1,.893.893l-1.869,1.869h5.423V7.316A2.319,2.319,0,0,0,14.789,5H9.316A2.319,2.319,0,0,0,7,7.316V18.684A2.319,2.319,0,0,0,9.316,21h5.474a2.319,2.319,0,0,0,2.316-2.316V13.632H11.683Zm7.974-3.132H17.105v1.263h4.421a.632.632,0,1,0,0-1.263Z" transform="translate(186 332)" fill="#b3776d" />
            </g>
        </g>
    </svg>
)