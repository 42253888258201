/* eslint-disable array-callback-return */
import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { stringAvatar, useWindowDimensions } from "../../utils";
import { TextBox } from "../index";
import { useStyles } from "./style";
export const MenuList = ({
    list = [],
    selected = 1,
    setSelected = () => false,
    searchLabel = "",
    fetchMoreData = () => false,
    handleSearch = () => false,
    searchValue = "",
    boxPadding = 0,
    listHeight=230
}) => {

    const classes = useStyles()

    const size = useWindowDimensions();

    return (
        <Box>
            <TextBox
                onChange={(e) => handleSearch(e.target.value)}
                label={false}
                value={searchValue}
                placeholder={searchLabel}
                color="#F5F7FA"
            />
            <Box height="4px" />
            <InfiniteScroll
                dataLength={list?.length ?? ""}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - listHeight}
            >
                <Box className={classes.listRoot} sx={{ padding: boxPadding }}>
                    {list?.map((val) => {
                        return (
                            <Box
                                display={"flex"}
                                onClick={() => setSelected(val)}
                                className={
                                    selected === val?.id
                                        ? classes.labelSelectedContact
                                        : classes.lableContact
                                }
                            >
                                <Box marginLeft="4px">
                                    {/* {val?.image_url ? ( */}
                                        <Avatar sx={{ width: 34, height: 34 }} src={val?.image_url}  {...stringAvatar(val?.name)} />
                                    {/* ) : (
                                        <Avatar sx={{ width: 34, height: 34 }} />
                                    )} */}
                                </Box>
                                <Box marginLeft={"10px"}>
                                    <Typography className={selected === val?.id ? classes.labelSelectedContact : classes.lableContact}>
                                        {val?.name}
                                        {
                                            !val?.email_id &&
                                            <Typography className={selected === val?.id ? classes.jobTextSelected : classes.jobText}>
                                                {val?.subTitle1 ?? "-"}{" "}
                                                {val?.subTitle2 && (
                                                    <span>&nbsp; |&nbsp; {val?.subTitle2}</span>
                                                )}
                                            </Typography>
                                        }
                                        {
                                            val?.email_id &&
                                            <Typography className={selected === val?.id ? classes.jobTextSelected : classes.jobText}>
                                                {val?.email_id}
                                            </Typography>
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </InfiniteScroll>
        </Box>
    );
}