import { Box, Button, Typography } from "@mui/material"
import { TimeLine } from "./timeline"
import NoDataFound from "../../../assets/noData"
import InfiniteScroll from "react-infinite-scroll-component"

export const ActivityComments = ({
    t = () => false,
    classes = {},
    size = {},
    miniLeadCardHeight = 0,
    comments = {},
    is_initial_comment_loading = true,
    is_add_comment_loading = false,
    fetchMoreComments = () => false,
    is_add_comment_enabled = false,
    add_comment_state = "",
    set_add_comment_state = () => false,
    onCommentSubmit = () => false,
    addCommentRef,
}) => {
    return (
        <Box className={classes.comments_box}>
            {is_initial_comment_loading
                ? (
                    <Box sx={{ height: size?.height - (is_add_comment_enabled ? 289 : 178) - miniLeadCardHeight }}>
                        <TimeLine type="loading" datas={[1, 2, 3]} />
                    </Box>
                )
                : (
                    comments?.rows?.length > 0
                        ? (
                            <InfiniteScroll
                                dataLength={comments?.rows?.length}
                                next={fetchMoreComments}
                                height={size?.height - (is_add_comment_enabled ? 289 : 178) - miniLeadCardHeight}
                                hasMore={comments?.rows?.length < comments?.total_rows_count}
                                loader={<TimeLine type="loading" datas={[1]} />}
                                endMessage={
                                    <Box width={"100%"} mb={"16px"}
                                        display={"flex"} justifyContent={"center"}>
                                        <Typography className={classes.comments_end}>
                                            {t("End Of Comments")}
                                        </Typography>
                                    </Box>
                                }>
                                {is_add_comment_loading &&
                                    <Box ref={addCommentRef}>
                                        <TimeLine type="loading" datas={[1]} />
                                    </Box>
                                }
                                <TimeLine datas={comments?.rows} />
                            </InfiniteScroll>
                        )
                        : (
                            is_add_comment_loading
                                ? (
                                    <Box sx={{ height: size?.height - (is_add_comment_enabled ? 289 : 178) - miniLeadCardHeight }}>
                                        <TimeLine type="loading" datas={[1]} />
                                    </Box>
                                )
                                : (
                                    <Box className={classes.comments_no_data_box}
                                        sx={{ height: size?.height - (is_add_comment_enabled ? 289 : 178) - miniLeadCardHeight }}>
                                        <NoDataFound />
                                    </Box>
                                )
                        )
                )
            }
            {is_add_comment_enabled &&
                <Box className={classes.add_comment_box}>
                    <form onSubmit={onCommentSubmit}>
                        <input className={classes.add_comment_input_box}
                            placeholder={t("Type Comments Here")}
                            value={add_comment_state}
                            readOnly={is_add_comment_loading}
                            disabled={is_add_comment_loading}
                            onChange={(e) => set_add_comment_state(e?.target?.value)} />
                        <Button type="submit" variant="contained"
                            className={classes.add_comment_button}
                            disabled={is_add_comment_loading || !Boolean(add_comment_state?.length > 0)}>
                            {t("Comment")}
                        </Button>
                    </form>
                </Box>
            }
        </Box>
    )
}