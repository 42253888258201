import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "12px",
        borderRadius: "4px",
        boxShadow: "0px 1px 16px #00000014",
        display: "flex",
        alignItems: "center"

    },
    title: {
        fontSize:"0.875rem",
        color: "#091B29",
        fontFamily: SemiBold,
        marginBottom: "4px"

    }
}));