import { Box } from "@mui/material"
import React from "react"
import { withNavBars } from "../../../HOCs"
import Form from "./form"

const CreateFeedBackForm = () => {
    return (
        <Box>
            <Form />
        </Box>
    )
}
const props = {
    boxShadow: false,
};
export default withNavBars(CreateFeedBackForm, props);