import { makeStyles } from "@mui/styles"

export const DayCalendarStyles = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,
        borderTop: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        borderLeft: `0.5px solid ${theme.palette.border.secondary}`,
        borderBottom: `0.5px solid ${theme.palette.border.secondary}`,

    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        borderLeft: `1px solid ${theme.palette.border.secondary}`,
        borderRight: `1px solid ${theme.palette.border.secondary}`,
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        //overflow: "auto"
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 200px)",
        overflow: "auto",
        width: "100%",
        [theme.breakpoints.only('md')]: {
            height: `calc(100vh - 275px)`,
        },
        [theme.breakpoints.only('sm')]: {
            height: `calc(100vh - 275px)`,
        },

    },
    moreLabel: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: 8,
        cursor: "pointer"
    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
        '& .MuiTooltip-arrow': {
            background: 'red',
        },
        "&:before": {
            border: "1px solid #E6E8ED"
        },

    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize:"0.875rem",
        fontWeight: "bold",
        color: theme.typography.color.primary,
        textTransform: "capitalize"
    },
    ttsecTitle: {
        fontSize:"0.75rem",
        textTransform: "capitalize",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontWeight: "bold"
    },
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: "white",
        position: "absolute",
        top: "0px",
        left: "0px",
        fontSize: "40px"
    },
    toolRoot: {
        border: '1px solid #E4E8EE',
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        borderRadius: "4px",

    },
    contentbox: {
        position: "relative"
    },
    rowOverflow: {
        height: "85px",
        width: "100%",
        position: "absolute",
        overflow: "auto",
        top: "0",
        left: "0",
        right: "0"
    },
    successStrip: {
        background: theme.palette.success.light,
        color: theme.palette.success.main
    },
    cancelledStrip: {
        background: theme.palette.error.light,
        color: theme.palette.error.main
    },
    expiredStrip: {
        background: theme.palette.background.tertiary,
        color: theme.typography.color.secondary
    }
}))