import React from "react";
import { withNavBars } from "../../HOCs";
import UserManageMent from "./usermanagement";

class UserManageMentParent extends React.Component {
    render() {
        return <UserManageMent />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UserManageMentParent, props);
