import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import UnitHandOver from './unitHandOver'

class UnitHandOverParent extends Component {
    render() {
        return (
            <div><UnitHandOver /></div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UnitHandOverParent, props)