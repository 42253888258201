export const EmailSentSuccessIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={156} height={152} {...props}>
    <g data-name="Group 117063">
      <g data-name="Group 117025">
        <g fill="#eef9ee" data-name="Polygon 352">
          <path d="m78 0 .313 79.31 62.238-49.2L78.7 79.8 156 97.752 78.564 80.408 112.713 152 78 80.68 43.287 152l34.15-71.592L0 97.752 77.3 79.8 15.449 30.105l62.238 49.2Z" />
          <path
            fill="#5ac782"
            d="m78 0 .313 79.31 62.238-49.205L78.703 79.8 156 97.752 78.564 80.408 112.714 152 78 80.68 43.287 152l34.15-71.592L0 97.752l77.297-17.953L15.45 30.105 77.687 79.31 78 0Z"
          />
        </g>
        <path
          fill="#cfd8dc"
          d="M106.064 37H41.258A9.258 9.258 0 0 0 32 46.258V85.6a9.258 9.258 0 0 0 9.258 9.258h64.806a9.258 9.258 0 0 0 9.258-9.258V46.258A9.258 9.258 0 0 0 106.064 37Z"
          data-name="Path 101653"
        />
        <path
          fill="#78909c"
          d="M34.518 39.946a9.192 9.192 0 0 0-2.224 4.1l41.367 29.985 41.367-29.98a9.179 9.179 0 0 0-2.224-4.1L73.661 68.315Z"
          data-name="Path 101654"
        />
        <path
          fill="#43a047"
          d="M124.58 92.548a23.145 23.145 0 1 1-23.145-23.145 23.144 23.144 0 0 1 23.145 23.145"
          data-name="Path 101655"
        />
        <path
          fill="#dcedc8"
          d="M111.831 81.785 98.662 94.978l-6.314-6.3-4.914 4.925 11.237 11.211 18.083-18.115Z"
          data-name="Path 101656"
        />
      </g>
    </g>
  </svg>
)