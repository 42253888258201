export const Utilities = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98421">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path d="M8.268 10.947h3.214a.268.268 0 1 0 0-.536H8.268a.268.268 0 1 0 0 .536ZM8.536 8.268v1.607h.536V8.268a.268.268 0 0 0-.536 0ZM20.856 12.551h-.536a.268.268 0 0 0-.268.268v.616l1.071.833v-1.447a.268.268 0 0 0-.267-.27ZM10.678 8.268v1.607h.531V8.268a.268.268 0 0 0-.536 0ZM9.875 14.16a1.341 1.341 0 0 0 1.339-1.339v-1.339H8.536v1.339a1.341 1.341 0 0 0 1.339 1.339ZM12.285 21.931h.268v-.536h-.268a2.145 2.145 0 0 1-2.143-2.143v-4.575a1.684 1.684 0 0 1-.536 0v4.575a2.681 2.681 0 0 0 2.679 2.679ZM21.66 16.942l-4.282-3.335-4.285 3.333v5.919a.139.139 0 0 0 .139.139h8.292a.139.139 0 0 0 .139-.139Zm-2.533 2.54-2.089 2.3a.446.446 0 0 1-.519.107.457.457 0 0 1-.263-.473l.262-2.164h-.565a.447.447 0 0 1-.413-.271.462.462 0 0 1 .08-.5l2.089-2.3a.447.447 0 0 1 .519-.107.457.457 0 0 1 .263.473l-.261 2.164h.564a.447.447 0 0 1 .413.271.462.462 0 0 1-.08.5Z" />
                <path d="M17.374 13.002a.268.268 0 0 1 .164.057l4.722 3.673a.354.354 0 1 0 .434-.558l-5.1-3.97a.354.354 0 0 0-.434 0l-5.1 3.97a.354.354 0 1 0 .434.559l4.722-3.673a.268.268 0 0 1 .158-.058Z" />
                <path d="M17.727 19.159a.268.268 0 0 1-.065-.21l.268-2.211-1.8 1.976h.687a.268.268 0 0 1 .266.3l-.268 2.211 1.8-1.976h-.687a.268.268 0 0 1-.201-.09Z" />
            </g>
            <path data-name="Rectangle 52123" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
