import * as React from "react"

const PhoneImg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 48450"
          transform="translate(.5)"
          fill="#4e5a6b"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 96904">
      <g
        data-name="Mask Group 6285"
        transform="translate(-.5)"
        clipPath="url(#a)"
      >
        <path
          data-name="icons8-phone (1)"
          d="m11.666 5.3.688 1.622a1.854 1.854 0 0 1-.411 2.055l-1.285 1.219a.607.607 0 0 0-.188.534 6.53 6.53 0 0 0 .824 2.226 7.012 7.012 0 0 0 1.552 1.864.612.612 0 0 0 .586.1l1.5-.5a1.627 1.627 0 0 1 1.864.656l.986 1.448a1.857 1.857 0 0 1-.207 2.332l-.654.657a2.416 2.416 0 0 1-2.442.622q-3.047-.95-5.6-5.643T7.07 6.524A2.608 2.608 0 0 1 8.79 4.6l.861-.275a1.651 1.651 0 0 1 2.015.975Z"
          fill="#78b1fe"
        />
      </g>
    </g>
  </svg>
)

export default PhoneImg
