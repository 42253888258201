export const managementCommitteRoleMasterHeading = (t) => {
    const Heading = [
        { title: "", field: "icon", },
        { title: t("RoleName"), field: "role_name", },
        { title: t("CompanyName"), field: "company_name", },
        { title: t("CreatedOn"), field: "created_on", },
        { title: t("CreatedBy"), field: "created_by", },
        { title: t("Status"), field: "status", },
    ]
    return Heading
}

export const managementCommitteRoleMasterPath = ["role_name", "company_name", "created_on", "created_by", "status"]