import React from "react";
import { Box, Typography } from "@mui/material";
import { useStylesCreation } from "./style";

export const CardDetails = (props) => {
    const classes = useStylesCreation(props);
    return (
        <div className={classes.card}>
            <Box className={classes.detailsCard1}>

                <Typography className={classes.countCard}>
                    {props?.data?.count}
                </Typography>
                {props?.data?.icon ?
                    <Typography>{props?.data?.icon}</Typography>
                    :
                    <img src={props?.data?.image} alt="active_unit" />
                }

            </Box>
            <Box mt={1}>
                <Typography className={classes.detailsCard2}>
                    {props?.data?.title}
                </Typography>
            </Box>
        </div>
    );
};
