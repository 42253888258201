export const Subscribe = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 96941" fill={props?.fill ?? "#c1c5cb"}>
            <path
                data-name="Path 93625"
                d="M19.112 17.605h-.514v1.028h.514a.514.514 0 1 0 0-1.028Z"
            />
            <path
                data-name="Path 93626"
                d="M19.112 19.661h-.514v1.028h.514a.514.514 0 1 0 0-1.028Z"
            />
            <path
                data-name="Path 93627"
                d="M20.141 14.521H9.859a2.573 2.573 0 0 0-2.57 2.57v4.112a2.573 2.573 0 0 0 2.57 2.57h10.282a2.573 2.573 0 0 0 2.57-2.57v-4.112a2.573 2.573 0 0 0-2.57-2.57Zm-9.252 7.2a1.53 1.53 0 0 1-1.091-.452.514.514 0 0 1 .728-.726.532.532 0 0 0 .877-.364.514.514 0 0 0-.514-.514 1.542 1.542 0 1 1 1.091-2.632.514.514 0 0 1-.728.726.533.533 0 0 0-.877.364.514.514 0 0 0 .514.514 1.542 1.542 0 1 1 0 3.084Zm5.655-1.542a1.542 1.542 0 1 1-3.084 0v-3.088a.514.514 0 0 1 1.028 0v3.084a.514.514 0 1 0 1.028 0v-3.084a.514.514 0 0 1 1.028 0Zm4.112 0a1.544 1.544 0 0 1-1.542 1.542h-1.025a.514.514 0 0 1-.514-.514v-4.116a.514.514 0 0 1 .514-.514h1.028a42.722 42.722 0 0 1 1.542 3.6Z"
            />
            <path
                data-name="Path 93628"
                d="M15.001 6.227a2.573 2.573 0 0 0-2.57 2.57v2.057a.514.514 0 0 1-.514.514.514.514 0 1 0 0 1.028h2.57v.514a.514.514 0 1 0 1.028 0v-.514h2.57a.514.514 0 1 0 0-1.028.514.514 0 0 1-.514-.514V8.797a2.573 2.573 0 0 0-2.57-2.57Z"
            />
            <path
                data-name="Path 93629"
                d="M21.682 9.825a.514.514 0 0 0-.514-.514H20.14a.514.514 0 1 0 0 1.028h1.028a.514.514 0 0 0 .514-.514Z"
            />
            <path
                data-name="Path 93630"
                d="m20.261 6.919-.727.727a.514.514 0 1 0 .727.727l.727-.727a.514.514 0 1 0-.727-.727Z"
            />
            <path
                data-name="Path 93631"
                d="M19.538 11.28a.514.514 0 0 0 0 .727l.727.727a.514.514 0 0 0 .727-.727l-.727-.727a.514.514 0 0 0-.727 0Z"
            />
            <path
                data-name="Path 93632"
                d="M8.81 10.339h1.028a.514.514 0 1 0 0-1.028H8.81a.514.514 0 1 0 0 1.028Z"
            />
            <path
                data-name="Path 93633"
                d="m10.439 7.645-.722-.725a.514.514 0 1 0-.727.727l.727.727a.514.514 0 1 0 .727-.727Z"
            />
            <path
                data-name="Path 93634"
                d="m9.717 12.734.727-.727a.514.514 0 0 0-.727-.727l-.727.727a.514.514 0 1 0 .727.727Z"
            />
        </g>
        <path data-name="Rectangle 52035" fill="none" d="M0 0h30v30H0z" />
    </svg>
)
