import React from 'react'
import { DetailCardStyle } from './style'
import { Avatar, Stack, Tooltip, Typography } from '@mui/material'
import { stringAvatar } from '../../../utils'

export const DetailCard = ({
    logo,
    isEdit = false,
    editText = "",
    handleEdit = () => false,
    title = "",
    subtitle = "",
    header = "",
    isMore = false,
    url = "",
    isTooltip = false,
    tooltipMsg = "",
    isReadOnly = false,
    t = () => false,
    isHide = false,
    payment_term =""
}) => {
    const classes = DetailCardStyle()
    return (
        <Stack className={classes.box} p={2} rowGap={isEdit ? 1 : 0}>
            <Stack direction={"row"} alignItems={"flex-start"} justifyContent={"space-between"}>
                <Typography className={classes.header}>{header}</Typography>
                {
                    isEdit ?
                        !isReadOnly ? <Typography onClick={handleEdit} className={classes.editText}>{editText}</Typography> : "" :
                        isHide ? "" :
                        <Typography className={classes.immediate}>{payment_term}</Typography>
                }
            </Stack>
            <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                {logo ?? <Avatar className={classes.avatar} src={url} {...stringAvatar(title)}></Avatar>}
                <Stack>
                    <Typography className={classes.title}>{title}</Typography>
                    {
                        isTooltip ?
                            <Tooltip title={tooltipMsg} arrow>
                                <Typography className={isMore ? classes.isMoreText : classes.subtitle} noWrap >{subtitle}</Typography>
                            </Tooltip> :
                            <Typography className={isMore ? classes.isMoreText : classes.subtitle} noWrap >{subtitle}</Typography>
                    }
                </Stack>
            </Stack>
        </Stack>
    )
}