import makeStyles from "@mui/styles/makeStyles";
export const UserManageMentStylesParent = makeStyles((theme) => ({
      tab: {
            background: '#fff',
            '&.MuiTabPanel-root': {
                  padding: '0px !important',
            },
      },
      tabs: {
            "&.MuiTab-root": { padding: "12px 16px !important" }
      },
      root: {
            margin: '14px',
            borderRadius: '4px',
            background: '#fff',
            height: `calc(100vh - 200px)`,
            overflow: 'hidden',
            padding: '0px',
      },
      rootSection: {
            padding: '16px',
            height: `calc(100vh - 200px)`,
            overflow: "auto"
      }
}))