import React from "react"
import { withNavBars } from "../../../HOCs"
import PricingTableViewAll from "./pricingTableViewAll"

class PricingTableViewAllParent extends React.Component {
    render() { return <PricingTableViewAll /> }
}

const props = { boxShadow: false }

export default withNavBars(PricingTableViewAllParent, props)
