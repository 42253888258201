export const AlreadyPaidRows = [
    {
        request_id: "RC-2234234",
        requested_by: "Pradeep Kumar",
        invoice_number: "INV-0234523",
        payment_method: "Offline",
        reference_num: "42548657587876"
    },
    {
        request_id: "RC-2234234",
        requested_by: "Pradeep Kumar",
        invoice_number: "INV-0234523",
        payment_method: "Offline",
        reference_num: "42548657587876"
    },
    {
        request_id: "RC-2234234",
        requested_by: "Pradeep Kumar",
        invoice_number: "INV-0234523",
        payment_method: "Offline",
        reference_num: "42548657587876"
    }
]

export const AlreadyPaidHeading = (t) => [
    { title: "", field: "icon" },
    { title: t("Request Id"), field: "request_id" },
    { title: t("Date"), field: "date" },
    { title: t("Requested By"), field: "requested_by" },
    { title: t("Agreement No"), field: "agreement_no" },
    { title: t("Invoice Number"), field: "invoice_number" },
    { title: t("Amount Paid"), field: "amount_paid" },
    { title: t("Payment Date"), field: "payment_date" },
    { title: t("Payment Method"), field: "payment_method" },
    { title: t("Reference Number"), field: "reference_num" },
    { title: t("Status"), field: "status" }
]

export const AlreadyPaidPath = [
    "request_id",
    "date",
    "requested_by",
    "agreement_no",
    "invoice_number",
    "amount_paid",
    "payment_date",
    "payment_method",
    "reference_num",
    "status"
]
