import React from 'react';
import styled from "@mui/material/styles/styled";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Bold } from '../../utils';

const PaddingTwo = styled('div')(({ theme }) => ({
    padding: theme.spacing(2)
}));

const CustomButton = styled(Button)(({ theme }) => ({
    minHeight: 48,
    borderRadius: 4,
    boxShadow: "0px 6px 10px #00000014",
    fontFamily: Bold,
    fontSize:"0.875rem"
}));

const StickyFooter = styled('div')({
    position: 'sticky',
    bottom: 0,
    backgroundColor: "#fff",
    zIndex: 1
});


export const Footer = (props) => {

    const {
        buttonName,
        buttonAction = null
    } = props;

    return (
        <StickyFooter>
            <Divider />
            <PaddingTwo>
                <CustomButton
                    variant={"contained"}
                    color="primary"
                    fullWidth
                    onClick={buttonAction}
                >
                    {buttonName}
                </CustomButton>
            </PaddingTwo>
        </StickyFooter>
    )
}