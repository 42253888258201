import * as React from "react"

export const TickImageComponent = (props) => {
  return (
    <>
      {!props?.white ?
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={32} {...props}>
          <g data-name="Group 7092">
            <g data-name="Group 7097">
              <rect
                data-name="Rectangle 45755"
                width={32}
                height={32}
                rx={16}
                fill="#5078e1"
              />
              <path
                d="M22.2 11.554a.711.711 0 0 0-.489.215l-8.737 8.736-3.761-3.762a.711.711 0 1 0-1 1.005l4.264 4.264a.711.711 0 0 0 1 0l9.238-9.238a.711.711 0 0 0-.515-1.22Z"
                fill="#fff"
                stroke="#fff"
                strokeWidth={0.5}
              />
            </g>
          </g>
        </svg>
        :
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
          <path
            data-name="icons8-ok 1"
            d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm4.281 8.281-5 5a.751.751 0 0 1-1.061 0l-2.5-2.5A.75.75 0 0 1 6.78 9.72l1.969 1.969 4.47-4.47a.75.75 0 0 1 1.062 1.061Z"
            fill="#5078e1"
          />
        </svg>
      }
    </>
  )
}

