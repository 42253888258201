import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        padding: "16px",
        backgroundColor: "white",
        margin: "14px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
    },
    viewBox: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#F5F7FA",
        borderRadius: theme.palette.borderRadius,
        padding: "10px",
        alignItems: "center"
    },
    serviceText: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    serviceSubText: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular
    },
    viewDot: {
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        width: "6px",
        height: "6px",
        fontFamily: Regular
    },
    requestNo: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: SemiBold
    },
    requestBox: {
        backgroundColor: "#5078E11E",
        padding: "5px",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        alignItems: "center",
    },
    question: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    options: {
        borderRadius: theme.palette.borderRadius,
        width: "42px",
        height: "32px",
        padding: "4px",
        backgroundColor: "#A4B2C1",
        color: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginInlineStart: "12px"
    },
    selectedOption: {
        borderRadius: theme.palette.borderRadius,
        width: "42px",
        height: "32px",
        padding: "4px",
        backgroundColor: "#5078E1",
        color: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginInlineStart: "12px"
    },
    optionsSubText: {
        marginTop: "4px",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary
    },
    textViewBox: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        padding: "10px",
        width: "400px"
        // height:"80px"
    },
    textView: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: SemiBold
    },
    iconButton: {
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.primary}`,
        height: "40px",
        width: "40px"
    },
    closeBtnBox: {
        display: "flex",
        justifyContent: "end",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        border: "1px solid #E4E8EE"
    },
    closebtn: {
        color: theme.typography.color.secondary,
        border: "1px solid #E4E8EE",
        '&:hover': {
            backgroundColor: "white",
            border: "1px solid #E4E8EE",
        }
    },
    disablecheck: {

        cursor: 'not-allowed',
        '&.Mui-disabled': {
            pointerEvents: 'none',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            cursor: 'not-allowed',
            '& .MuiSvgIcon-root .innerBox': {
                fill: 'blue',
            },
        },
    }
}))