import * as React from "react"

export const MilstoneTemplate = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <defs>
      <linearGradient
        id="b"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.157}
        y1={0.157}
        x2={0.853}
        y2={0.853}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fede00" />
        <stop offset={1} stopColor="#ffd000" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={0.445}
        y1={0.592}
        x2={0.594}
        y2={0.218}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f0b421" stopOpacity={0} />
        <stop offset={0.512} stopColor="#e9ab12" stopOpacity={0.514} />
        <stop offset={1} stopColor="#e09f00" />
      </linearGradient>
      <radialGradient
        id="a"
        cx={0.167}
        cy={0.696}
        r={0.728}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
    </defs>
    <g data-name="Group 102167" transform="translate(-8603 -9818)">
      <rect
        data-name="Rectangle 53914"
        width={48}
        height={48}
        rx={4}
        transform="translate(8603 9818)"
        fill="#fff4eb"
      />
      <g transform="translate(8610 9823)">
        <circle
          data-name="Ellipse 129427"
          cx={1.667}
          cy={1.667}
          r={1.667}
          transform="translate(25.667 21.667)"
          fill="#c98e00"
        />
        <path
          data-name="Path 96852"
          d="m18.474 30.122 9.646-5.327-1.427-3-8.36 3.872Z"
          fill="#c98e00"
        />
        <circle
          data-name="Ellipse 129428"
          cx={4.667}
          cy={4.667}
          r={4.667}
          transform="translate(20.333 7)"
          fill="#ff8f6b"
        />
        <circle
          data-name="Ellipse 129429"
          cx={3.333}
          cy={3.333}
          r={3.333}
          transform="translate(21.667 8.333)"
          fill="#ed6c47"
        />
        <path
          data-name="Path 96853"
          d="M37 13.8a6.664 6.664 0 0 0-4.85-6.409 4.664 4.664 0 1 0 4.7 7.817A6.658 6.658 0 0 0 37 13.8Z"
          transform="translate(-8.667 -.129)"
          fill="url(#a)"
        />
        <path
          data-name="Path 96854"
          d="M31 29.333a1.666 1.666 0 0 0-2.306-1.539h-.007l-8.349 3.867-1-.667a1.662 1.662 0 0 0 .822-3.109v-.011l-9.1-4.577h-.017a3.277 3.277 0 0 0-2.717-.017h-.017L3 25.667v8.667l5.393-2.479L19 36.193a1.627 1.627 0 0 0 1.462-.071h.011l9.648-5.322A1.659 1.659 0 0 0 31 29.333Z"
          transform="translate(0 -5.333)"
          fill="url(#b)"
        />
        <circle
          data-name="Ellipse 129430"
          cx={5.333}
          cy={5.333}
          r={5.333}
          transform="translate(16.333 8.333)"
          fill="url(#c)"
        />
        <circle
          data-name="Ellipse 129431"
          cx={4}
          cy={4}
          r={4}
          transform="translate(17.667 9.667)"
          fill="#f5be00"
        />
        <path
          data-name="Path 96855"
          d="M20.476 15.632v-.791a1.737 1.737 0 0 0 .922.265.864.864 0 0 0 .576-.175.588.588 0 0 0 .207-.475q0-.627-.886-.627a5.444 5.444 0 0 0-.7.053v-2.363h2.338v.76h-1.54v.845c.123-.012.241-.017.351-.017a1.441 1.441 0 0 1 1.019.341 1.193 1.193 0 0 1 .368.919 1.353 1.353 0 0 1-.439 1.043 1.685 1.685 0 0 1-1.189.4 2.541 2.541 0 0 1-1.027-.178Z"
          fill="#fee119"
        />
        <circle
          data-name="Ellipse 129432"
          cx={1}
          cy={1}
          r={1}
          transform="translate(18.333 23)"
          fill="#ffde87"
        />
        <path
          data-name="Path 96856"
          d="m25 34.5-.993-.665c-.837-.04-1.495-.312-7.007-2.342v2.337l7.7 3.156A2.77 2.77 0 0 1 25 34.5Z"
          transform="translate(-4.667 -8.163)"
          fill="url(#d)"
        />
      </g>
    </g>
  </svg>
)

