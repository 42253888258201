import { Avatar, Box, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { UnitAndContactStyle } from './style'
import ExistingContactIcon from '../utils/existingContactIcon'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import { AmenitiesBookingContext } from '../amenitiesBookingContext'
import { MobileNumberInputComponent, SelectBox, TextBox } from '../../../components'
import { stringAvatar } from '../../../utils'
import { UnitListCard } from './unitListCard'
import NewContactIcon from '../utils/newContactIcon'

export const UnitAndContact = ({
    addUnit = {},
    setAddUnit = () => false,
    t
}) => {
    const classes = UnitAndContactStyle()

    const {
        selectedCompany,
        decoded,
        selectedExistingContact,
        setSelectedExistingContact,
        setContactDetail,
        contactDetail,
        setSelectedUnit
    } = React.useContext(AmenitiesBookingContext)

    const [propertyUnit, setPropertyUnit] = React.useState(false)

    const setExistingContact = (e) => {
        setSelectedExistingContact(e)
        setContactDetail({
            ...contactDetail,
            name: "",
            mobileNo: "",
            mailId: ""
        })
        // setOpenSearch(false)
    }
    const existingContactManualResponse = (array) => {
        const details = array?.map((i) => {
            return {
                ...i,
                label: i?.first_name,
                value: i?.id
            }
        })
        return details
    }

    const handleUnselectUnit = (existing) => {
        if (existing) {
            setSelectedExistingContact("")
            setAddUnit(false)
        } else {
            setSelectedUnit("")
            setAddUnit(false)
        }
    }

    const handleHideShow = (type) => {
        if (type === "property") {
            setExistingContact("")
            setPropertyUnit(false)
        } else {
            setContactDetail({
                name: "",
                mobileNo: "",
                mailId: "",
                account: "",
                error: {
                    name: "",
                    mobileNo: "",
                    mailId: ""
                }
            })
            setPropertyUnit(true)
        }
    }

    return (
        <Box className={classes.box}>
            <Stack p={2}>
                <Box pb={"4px"}>
                    <Stack direction={"row"} spacing={1.5} pb={!propertyUnit ? "14px !important" : "0px"}
                        onClick={() => handleHideShow("property")} sx={{ cursor: "pointer" }}>
                        <ExistingContactIcon />
                        <Stack>
                            <Typography className={classes.title}>Choose from property unit</Typography>
                            <Typography className={classes.subtitle}>Choose the unit from the property</Typography>
                        </Stack>
                    </Stack>

                    {!propertyUnit &&
                        <SelectBox
                            loadOptions={(search, array, handleLoading) =>
                                loadOptionsApis(
                                    "contact/list",
                                    {
                                        tenantId: decoded?.selected_client?.tenants?.id,
                                        company_id: selectedCompany?.value,
                                        search: "",
                                        filter: {
                                            relationship: [],
                                            status: [
                                                true
                                            ]
                                        },
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    existingContactManualResponse
                                )
                            }
                            isPaginate={true}
                            borderRadius={false}
                            noBorder={false}
                            placeholder={"Search Existing Contact"}
                            label={false}
                            value={selectedExistingContact}
                            onChange={(value) => {
                                setExistingContact(value)
                            }}
                            customOptionComponent={(props) => {
                                return (
                                    <Stack direction={"row"} spacing={2} p={1} alignItems={"center"} className={classes.selectParent} style={{ border: (props?.isSelected) ? "1px solid #5078E1" : "1px solid #E4E8EE", backgroundColor: (props?.isSelected) ? "#F1F7FF" : "#fff" }}>
                                        <Avatar className={classes.optionAvatar}
                                            src={props?.data?.image_url}
                                            {...stringAvatar(props?.data?.first_name)}></Avatar>
                                        <Stack>
                                            <Typography className={classes.optionHeader}>{props?.data?.first_name}</Typography>
                                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                <Typography className={classes.optionSubTitle}>{`${props?.data?.mobile_no_country_code} ${props?.data?.mobile_no}, ${props?.data?.email_id}`}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                )
                            }}
                        />}
                </Box>

                {
                    (selectedExistingContact && !propertyUnit) &&
                    <Box>
                        <UnitListCard
                            data={selectedExistingContact}
                            removeUnit={handleUnselectUnit}
                            close={true}
                            existing={true} />
                    </Box>
                }
            </Stack>
            <Divider></Divider>
            <Stack p={2}>
                <Stack direction={"row"} spacing={1.5} pb={propertyUnit ? "14px !important" : "0px"}
                    onClick={() => handleHideShow("")} sx={{ cursor: "pointer" }}>
                    <NewContactIcon />
                    <Stack>
                        <Typography className={classes.title}>Create New Contact</Typography>
                        <Typography className={classes.subtitle}>Create by providing name, mobile no, email Id</Typography>
                    </Stack>
                </Stack>


                {propertyUnit &&
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12}>
                            <TextBox
                                value={contactDetail?.name}
                                isrequired
                                label={t("name")}
                                placeholder={t("name")}
                                onChange={(e) => {
                                    setContactDetail(
                                        {
                                            ...contactDetail,
                                            name: e.target.value,
                                            error: { ...contactDetail.error, name: "" }
                                        }
                                    )
                                }}
                                isError={contactDetail?.error?.name?.length > 0}
                                errorMessage={contactDetail?.error?.name}
                                isReadonly={selectedExistingContact?.id}

                            />
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <MobileNumberInputComponent
                                label={t("Mobile Phone")}
                                placeholder={t("Mobile Phone")}
                                value={contactDetail?.mobileNo}
                                isRequired
                                handleChange={(value) => {
                                    setContactDetail(
                                        {
                                            ...contactDetail,
                                            mobileNo: value,
                                            error: { ...contactDetail.error, mobileNo: "" }
                                        }
                                    )
                                }}
                                isReadonly={selectedExistingContact?.id}
                                isError={contactDetail?.error?.mobileNo?.length > 0}
                                errorMessage={contactDetail?.error?.mobileNo}
                            />
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <TextBox
                                value={contactDetail?.mailId}
                                isrequired
                                label={t("MailID")}
                                placeholder={t("MailID")}
                                onChange={(e) => {
                                    setContactDetail(
                                        {
                                            ...contactDetail,
                                            mailId: e.target.value,
                                            error: { ...contactDetail.error, mailId: "" }
                                        }
                                    )
                                }}
                                isError={contactDetail?.error?.mailId?.length > 0}
                                errorMessage={contactDetail?.error?.mailId}
                                isReadonly={selectedExistingContact?.id}
                            />
                        </Grid>
                    </Grid>
                }
            </Stack>
        </Box>
    )
}