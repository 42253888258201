import { Divider, Grid, Stack, Typography } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import { useStyles } from "./style";
import React from "react";
import { AlertProps, NetWorkCallMethods, useWindowDimensions } from "../../../../utils";
import LogoUpload from "../../../../components/LogoUpload";

import { NetworkCall } from "../../../../networkcall";
import { AlertContext } from "../../../../contexts";
import { BackendRoutes } from "../../../../router/routes";
import { config } from "../../../../config";
export const MiniDetailsCard = ({
    t = () => false,
    logo = "",
    company_id = "",
    company_name = "",
    account_name = "",
    account_id = "",
    tax_id = "",
    id = "",
    getBasicInfo = () => false,
}) => {

    const size = useWindowDimensions()
    const classes = useStyles()
    const alert = React.useContext(AlertContext);

    const [is_logo_viewer_open, set_is_logo_viewer_open] = React.useState(false)

    const updateLogo = (value) => {
        const payload = {
            customer_id: id,
            logo: value?.src ?? null,
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.upload_customer}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            getBasicInfo();
            alert.setSnack({
                ...alert, open: true, msg: value?.src ? t("Logo Uploaded Successfully") : t("Logo Deleted Successfully"),
                severity: AlertProps.severity.success
            })
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }

    return (
        <Stack className={classes.root} direction={size?.width >= 600 ? "row" : "column"} spacing={"24px"}
            divider={size?.width >= 600 ? <Divider orientation="vertical" variant="middle" flexItem /> : <></>}>
            <div className={classes.logo_div}>
                <LogoUpload

                    logo_title={""}
                    handleChange={(value) => updateLogo(value)}
                    data={{ src: logo }}
                    removeBtn={false}
                    buttonName={t("Upload Image")}
                />
                {/* <Avatar
                    variant="circular"
                    src={logo?.length > 0 ? logo : "images/plan_logo.png"}
                    className={classes.avatar}
                    onClick={() => set_is_logo_viewer_open(true)} /> */}
                {company_id && <Typography className={classes.id_tag}>{company_id}</Typography>}
            </div >
            <Grid container>
                <div className={classes.title_div}>
                    <Typography className={classes.title}>{t("COMPANY DETAILS")}</Typography>

                </div>
                <Grid container spacing={"8px"}>
                    <Grid item xs={size?.width >= 1000 ? 2 : size?.width >= 450 ? 6 : 12}>
                        <Typography className={classes.heading}>{t("Company Name")}</Typography>
                        <Typography className={classes.content}>{company_name ?? " - "}</Typography>
                    </Grid>
                    <Grid item xs={size?.width >= 1000 ? 2 : size?.width >= 450 ? 6 : 12}>
                        <Typography className={classes.heading}>{t("Account Name")}</Typography>
                        <Typography className={classes.content}>{account_name ?? " - "}</Typography>
                    </Grid>
                    <Grid item xs={size?.width >= 1000 ? 2 : size?.width >= 450 ? 6 : 12}>
                        <Typography className={classes.heading}>{t("Account ID")}</Typography>
                        <Typography className={classes.content}>{account_id ?? " - "}</Typography>
                    </Grid>
                    <Grid item xs={size?.width >= 1000 ? 2 : size?.width >= 450 ? 6 : 12}>
                        <Typography className={classes.heading}>{t("Tax ID")}</Typography>
                        <Typography className={classes.content}>{tax_id ?? " - "}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {
                is_logo_viewer_open && (
                    <ImageViewer
                        src={[logo?.length > 0 ? logo : "images/plan_logo.png"]}
                        currentIndex={0}
                        onClose={() => set_is_logo_viewer_open(false)}
                        disableScroll={false}
                        backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                        className={classes.image_viewer}
                        closeOnClickOutside={true} />
                )
            }
        </Stack >
    )
}