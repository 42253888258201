export const UserManagement = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-2{fill:#c1c5cb}"}</style>
        </defs>
        <g id="Announcement" transform="translate(24899 -2958)">
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24899 2958)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
            <g
                id="User_Management"
                data-name="User Management"
                transform="translate(-24893 2965)"
            >
                <g
                    id="Group_102035"
                    data-name="Group 102035"
                    transform="translate(9.314)"
                >
                    <path
                        id="Path_96783"
                        data-name="Path 96783"
                        className="cls-2"
                        d="M267.882 3.765a1.882 1.882 0 1 1 1.882-1.882 1.884 1.884 0 0 1-1.882 1.882Z"
                        transform="translate(-266)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                </g>
                <path
                    id="Path_96784"
                    data-name="Path 96784"
                    className="cls-2"
                    d="M224.287 0a.954.954 0 0 0-.915.96v.922a1.569 1.569 0 0 1-1.572 1.569 2.82 2.82 0 0 1-4.259.507A3.292 3.292 0 0 0 216 6.746v2.823a1.1 1.1 0 0 0 1.1 1.1h.471v4.925a.471.471 0 0 0 .471.471h1.176v-4.927a.471.471 0 1 1 .941 0v4.925h1.176a.471.471 0 0 0 .471-.471V5.334a3.451 3.451 0 0 0 3.451-3.451V.942a.941.941 0 0 0-.97-.942Z"
                    transform="translate(-208.255)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96785"
                    data-name="Path 96785"
                    className="cls-2"
                    d="M72.725 3.451a1.725 1.725 0 1 1 1.725-1.725 1.727 1.727 0 0 1-1.725 1.725Z"
                    transform="translate(-67.804)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <g
                    id="Group_102036"
                    data-name="Group 102036"
                    transform="translate(1 3.451)"
                >
                    <path
                        id="Path_96786"
                        data-name="Path 96786"
                        className="cls-2"
                        d="M6.952 110a2.661 2.661 0 0 1-3.85.222A3.3 3.3 0 0 0 1 113.294v2.824a1.1 1.1 0 0 0 1.1 1.1h.471v4.925a.471.471 0 0 0 .471.471h1.174v-4.925a.471.471 0 1 1 .941 0v4.925h1.176a.471.471 0 0 0 .471-.471v-8.847A4.229 4.229 0 0 1 8.38 110Z"
                        transform="translate(-1 -110)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                </g>
            </g>
        </g>
    </svg>
)