import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { SemiBold } from '../../utils';
import { useStyles } from './styles';

const CustomInput = styled('input')({
    position: 'absolute',
    opacity: 0,
    inset: 0,
    zIndex: 1,
    cursor: 'pointer'
})



const UploadBtn = (props) => {

    const { t = () => false } = props

    const {
        accept = "image/*",
        buttonName = "Upload Image",
        fileUploadUrl = true,
        handleChange = null,
        isReadonly = false,
        assets = [],
        amenities,
    } = props;

    const UploadButton = styled(Button)(({ theme }) => ({
        borderColor: "#E4EFFF",
        padding: "20px",
        height: props?.height ? props?.height : "150px",
        color: "#5078E1",
        background: "#E4EFFF",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        cursor: "pointer",
        '&:hover': {
            background: "#E4EFFF"
        },
        borderRadius: theme.palette.borderRadius,

    }))

    const classes = useStyles()
    const handleUpload = async (data) => {
        //only allow if file selected
        if (data?.length) {
            if (fileUploadUrl) {
                handleChange(data)
            }
            else {
                handleChange && handleChange(data)
            }

        }
    }


    return (
        <div>
            <Stack
                alignItems={"center"}
            >

                <>
                    {buttonName &&
                        <UploadButton
                            fullWidth
                            variant={"outlined"}
                            className={amenities === true && assets.length === 0 && classes.uploadbutton}
                        >
                            <Box>
                                <FileUploadOutlinedIcon />
                                <br />
                                <Typography className={classes.btnName} noWrap>{t(buttonName)}</Typography>
                            </Box>
                            <CustomInput
                                type="file"
                                multiple
                                onChange={(e) => handleUpload(e.target.files)}
                                accept={accept ?? "image/*"}
                                disabled={isReadonly}
                            />
                        </UploadButton>
                    }
                </>


            </Stack>
        </div>
    )
}
export default withNamespaces("uploadBtn")(UploadBtn)

