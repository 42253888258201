export const WorkerImagePlaceholder = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        data-name="Group 117008"
        {...props}
    >
        <rect
            width={40}
            height={40}
            fill="#f2f4f7"
            data-name="Rectangle 58391"
            rx={8}
        />
        <path
            fill="#98a0ac"
            d="M20.292 10.001a.682.682 0 0 0-.7.682v.3a5 5 0 0 0-3.939 3.106h-.606a.682.682 0 0 0 0 1.364H25.5a.682.682 0 0 0 0-1.364h-.559a5 5 0 0 0-3.987-3.108v-.3a.682.682 0 0 0-.662-.68Zm-5 6.364a5 5 0 0 0 9.977 0Zm-.25 6.364A2.05 2.05 0 0 0 13 24.773v.545c0 2.559 3.041 4.583 6.973 4.683h.6c3.932-.1 6.973-2.123 6.973-4.682v-.545a2.05 2.05 0 0 0-2.046-2.046Z"
        />
    </svg>
)