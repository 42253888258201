import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Aggreement, InvoiveIMG, QuotationIMG } from "../../assets";
import { LoadingSection, PropertyTabs, Subheader } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils";
import { AggrementViewStyles } from "./styles";
import { AggreementDetails, Invoices, QuotationDetails } from "./tabs";
export const AggreementView = () => {
    const classes = AggrementViewStyles()
    const history = useHistory()
    const [value, setValue] = React.useState(1)
    const { id } = useParams()
    const [loading, setLoading] = React.useState(true)
    const [details, setDetails] = React.useState({
        agreement: {},
        units: [],
        contact: [],
        scheduled_invoices: []
    })
    //get agreement details
    const getAgreementDetails = () => {
        const payload = {
            tenantId: `${config.tenantId}`,
            agreement_id: id,
        }
        NetworkCall(
            `${config.api_url}/agreement/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {

                setDetails({
                    agreement: response.data.data,
                    units: response.data.data.unit_details,
                    contact: response.data.data.contacts,
                    scheduled_invoices: response.data.data.scheduled_invoices
                })
                setLoading(false)
            }).catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }
    React.useEffect(() => {
        getAgreementDetails()
        // eslint-disable-next-line
    }, [])

    //on change in tab
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //tab data json
    const tabTittle = [
        {
            label: 'Agreement Details',
            imgSelect: <Aggreement color={value === 1 ? '#5078E1' : '#98A0AC'} />,
            value: 1,
            className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: 'Quotation Details',
            imgSelect: <QuotationIMG color={value === 2 ? '#5078E1' : '#98A0AC'} />,
            value: 2,
            className: value === 2 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 2 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 2 ? classes.subtabtitle1 : classes.subtabtitle
        },
        {
            label: 'Invoices',
            imgSelect: <InvoiveIMG color={value === 3 ? '#5078E1' : '#98A0AC'} />,
            value: 3,
            className: value === 3 ? classes.tabtitle1 : classes.tabtitle,
            selectBack: value === 3 ? classes.selectBack1 : classes.selectBack,
            subtabtitle: value === 3 ? classes.subtabtitle1 : classes.subtabtitle
        },

    ]
    return (
        <div>
            {  //loading section
                loading ? <LoadingSection bottom={"45vh"} message="Loading Agreements Details..." /> :
                    <>
                        {/*sub header */}
                        <Subheader title="Agreements" goBack={() => history.goBack()} />
                        {/*tab */}
                        <PropertyTabs value={value} handleChange={handleChange}
                            tab1={<AggreementDetails
                                data={details?.agreement}
                                units={details?.units}
                                contact={details?.contact}
                            />}
                            tab2={<QuotationDetails data={details?.agreement} />}
                            tab3={<Invoices scheduled_invoices={details?.scheduled_invoices} />}
                            tabTittle={tabTittle}

                        />
                    </>
            }
        </div>
    )
}




