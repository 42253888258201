import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import FileViewer from "react-file-viewer";
import DeleteIcon from "../../assets/delete";
import { useStyles } from "./styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const AssetCard = ({
    x = {},
    onDelete = () => false,
    readOnly = false,
    onClick = () => false,
    imageHeight = "148px",
    border = false,
}) => {
    const classes = useStyles();
    const [showText, setShowtext] = React.useState(false);
    return (
        <Box sx={{ cursor: "pointer" }}>

            <Box onMouseOver={() => setShowtext(true)}
                onMouseLeave={() => setShowtext(false)} position={"relative"} height={imageHeight} className={border ? classes.applyBorder : classes.noborder}>

                {(x?.file_meta?.type === "application/pdf" || x?.file_meta?.type === "pdf") ?
                    <FileViewer fileType="pdf" filePath={x?.url} />
                    :
                    <img src={x?.url} className={`${classes.selectedImg}`} alt="" style={{
                        objectFit: "contain",
                        borderRadius: "8px"
                    }} />

                }
                {showText === true && (
                    <Box className={classes.hoverCard} style={{ height: imageHeight }}>
                        {
                            !readOnly &&
                            <Box sx={{ float: "right", marginRight: "12px", cursor: "pointer", marginTop: "12px" }} onClick={onDelete}>
                                <DeleteIcon />
                            </Box>

                        }
                        <Box display="flex" justifyContent={"space-between"} className={`${classes.imgtext}`}>
                            <Box>
                                <Typography className={`${classes.imgName}`} noWrap>
                                    {x?.file_meta?.name}
                                </Typography>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Typography className={`${classes.textName}`}>
                                        {x?.file_meta?.size}
                                    </Typography>
                                    <div className={`${classes.dot}`}></div>
                                    <Typography className={`${classes.textName}`} noWrap>{x?.file_meta?.type}</Typography>
                                </Box>
                            </Box>
                            <IconButton onClick={onClick}>
                                <MoreVertIcon sx={{color:"white",marginRight:"8px"}}/>
                            </IconButton>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}