import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Regular, SemiBold, stringAvatar, timeZoneConverter } from '../../utils'
import { Bold } from "../../utils";
import ShowMoreText from "react-show-more-text";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 2px 6px #53668523",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.background.paper,
  },
  Cardcontent: {
    padding: "16px 16px 10px",
  },
  // dot: {
  //   height: "6px",
  //   width: "6px",
  //   borderRadius: "50%",
  //   backgroundColor: "#CED3DD",
  //   margin: "6px",
  // },
  title: {
    fontSize:"0.875rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },
  name: {
    color: theme.typography.color.primary,
    fontFamily: Bold,
    fontSize:"0.875rem",
  },
  unit: {
    fontSize:"0.75rem",
    color: theme.palette.background.primary,
    fontFamily: Regular,
  },
  time: {
    fontSize:"0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: Regular,
  },
  dis: {
    background: "#F1E6FE",
    color: "#5D427F",
    padding: "0px 4px",
    borderRadius: "4px",
    fontSize:"0.75rem",
    fontFamily: Bold,
    display: "flex",
    alignItems: "center",
    height: "17px"
  },
  titlecontainer: {
    borderBottom: "1px solid #E4E8EE",
    paddingBottom: "12px",
  },
  like: {
    fontSize:"0.75rem",
    color: theme.typography.color.primary,
    fontFamily: SemiBold,
    display: 'flex',
    alignItems: 'center',
  },
  property: {
    fontSize:"0.75rem",
    color: "#5D427F",
    backgroundColor: "#F1E6FE",
    padding: "2px",
    borderRadius: theme.palette.borderRadius,
    fontFamily: Bold,
  },
  new: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    backgroundColor: "#FF4B4B",
    padding: "2px",
    borderRadius: theme.palette.borderRadius,
    fontFamily: Bold,
  },
  dot: {
    height: 6,
    width: 6,
    borderRadius: "50%",
    background: theme.palette.border.primary,
    // marginLeft: "0px !important"
  },
  sub: {
    color: theme.typography.color.secondary,
    fontSize:"0.75rem",
    fontFamily: SemiBold,
  },
  seeMoreLessTextStyle: {
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    color: theme.palette.primary.main,
  },
  imgAvatar: {
    height: 90,
    width: "100%",
    borderRadius: "4px"
  }
}));
export const CommunityCardComponent = React.forwardRef((props, ref) => {
  const classes = useStyles(props);
  const { t } = (props)

  return (
    <div>
      <div
        ref={ref}
        className={classes.card}
        onClick={() => {
          // history.push(Routes.discussions + "?discussionId=" + props.data.id);
          props?.commentShow(props?.data?.id, "first")

        }}
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex">
            <Box>
              <Avatar style={{ width: 50, height: 50 }} src={props.data?.image_url} {...stringAvatar(props?.data?.first_name)} />
            </Box>
            <Box flexGrow={1} marginInlineStart="10px">
              <Box alignItems="center" display="flex">
                <Stack direction={"row"} spacing={"6px"} alignItems={"center"}>
                  <Typography variant="subtitle2" className={classes.name}>
                    {props.data.first_name}&nbsp;{props.data?.last_name}
                  </Typography>
                  <Box className={classes.dot}></Box>
                  <Typography variant="subtitle2" className={classes.unit}>
                    {props.data.unit_name}
                  </Typography>
                </Stack>
                <Box flexGrow={1} textAlign={"end"}>
                  {/* {props.data.is_new && ( */}
                  <Box>
                    <span className={classes.new}>
                      &nbsp;{t("NEW")}&nbsp;
                    </span>
                  </Box>
                  {/* )} */}
                </Box>
              </Box>

              <Typography variant="subtitle2" className={classes.time}>
                {t("Posted at")}
                &nbsp; &#x202a; {timeZoneConverter(new Date(props?.data?.created_at), "DD MMM yyyy, hh:mm a")} &#x202c;

              </Typography >
            </Box >
          </Box >
          <div className={classes.titlecontainer}>
            <Stack direction={"row"} spacing={1} mt={1}>
              <Typography variant="subtitle2" className={classes.title} style={{ overflowWrap: 'anywhere' }}>
                {props.data.title}
              </Typography>
              <Typography variant="subtitle2" className={classes.dis} noWrap>
                #{props.data.property_name}

              </Typography>
            </Stack>
            <Box>
              {props?.data?.description &&
                <ShowMoreText
                  lines={2}
                  more={t("Show More")}
                  less={t("Show Less")}
                  className={classes.sub}
                  anchorClass={classes.seeMoreLessTextStyle}
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  <Typography className={classes.sub}
                    dangerouslySetInnerHTML={{ __html: (props?.data?.description).replace(/\n+/g, "<br />") ?? "" }}>
                  </Typography>
                </ShowMoreText>
              }
            </Box>
            {props?.data?.assets?.length > 0 &&
              <Box mt={"10px"}>
                <Avatar src={props?.data?.assets?.[0]?.url} className={classes.imgAvatar}></Avatar>
              </Box>
            }
          </div>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop="6px"
              >
                <Box>
                  <img src="\images\like.svg" alt="like" />
                </Box>
                <Box>
                  <Typography variant="subtitle2" className={classes.like}>
                    &nbsp;{props.data.reactions} {t("Likes")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop="10px"
              >
                <Box>
                  <img src="/images/chat.svg" alt="chat" style={{ marginTop: '8px' }} />
                </Box>
                <Box>
                  <Typography variant="subtitle2" className={classes.like}>
                    &nbsp;{props.data.comments} {t("Comments")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div >
      </div >
    </div >
  );
});
