import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Italic, Regular, SemiBold, remCalc } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        margin: "16px",
        backgroundColor: "white",
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius
    },
    filterButton: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    planTitle: {
        fontSize: remCalc(12),
        fontFamily: ExtraBold,
        color: theme.typography.color.primary,
        textTransform: "uppercase"
    },
    created_box: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F2F4F7",
        padding: "4px",
        textAlign: "center"
    },
    created_value: {
        fontSize: remCalc(12),
        fontFamily: Italic,
        color: theme.typography.color.secondary,
    },
    customlabel: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color:"rgb(152, 160, 172)",
    },
    card: {
        border: "1px solid #CED3DD",
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        cursor: "pointer",
    },
    dynamicriteriacard: {
        border: "1px solid #CED3DD",
        borderRadius: theme.palette.borderRadius,
        // padding: "8px",
        cursor: "pointer",
    },
    dynamicSelectedCard: {
        border: "1px solid #5078E1",
        borderRadius: theme.palette.borderRadius,
        padding: "24px 12px",
        backgroundColor: "#F1F7FF"
    },
    title: {
        fontSize: remCalc(12),
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    examples: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
    },
    selectedCard: {
        border: "1px solid #5078E1",
        borderRadius: theme.palette.borderRadius,
        padding: "8px",
        backgroundColor: "#F1F7FF"
    },
    criteriaTitle: {
        fontSize: remCalc(12),
        fontFamily: ExtraBold,
        color: theme.typography.color.primary,
    },
    criteriaSubTitle: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
    },
    fixedBottom: {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        padding: "16px",
        backgroundColor: "white"
    },
    draftBtn: {
        border: "1px solid #CED3DD",
        color: theme.typography.color.primary,
        "&:hover": {
            backgroundColor: "#ffffff75",
            color: theme.typography.color.primary,
        },
    },
    warningCard: {
        backgroundColor: "#FFF4EB",
        border: "0.5px solid #FF9340",
        borderRadius: theme.palette.borderRadius,
        padding: "4px",
        textAlign: "center"
    },
    warningText: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: "#FF9340",
    },
    deleteText: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: "#FF4B4B",
    },
    addBillLine: {
        fontSize: "0.875rem",
        color: theme.palette.primary.main,
        fontFamily: Bold,
        padding: "4px",
        cursor: "pointer",
        whiteSpace: "noWrap",
        width: "150px"
      },
    executionCard: {
        backgroundColor: "#F6F1FE",
        borderRadius: theme.palette.borderRadius,
        padding: "12px"
    },
    contractDetailsCard: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F5F7FA",
        padding: "12px"
    },
    resourceTitle: {
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    // selectedCard: {
    //     border: "1px solid #5078E1",
    //     backgroundColor: "#F1F7FF",
    //     borderRadius: "4px 4px 0px 0px",
    //     padding: "12px"
    // },
    selectedPMPCard: {
        border: "1px solid #5078E1",
        backgroundColor: "#F1F7FF",
        borderRadius: "4px",
        padding: "12px"
    },
    resourceCard: {
        border: "1px solid #CED3DD",
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer"
    },
    vendorCard: {
        // border:"1px solid #CED3DD",
        // borderRadius:theme.palette.borderRadius,
        cursor: "pointer",
        padding: "12px"
    },
    stepperValue: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor: "#5078E1",
        color: "#FFFFFF",
        width: "24px",
        height: "24px",
        padding: "4px"
    },
    horizontalBorder: {
        borderBottom: "3.5px solid #F2F4F7",
        width: "100%",
        position:"absolute",
        left:"50%",
        marginLeft:"20px",
        right:0,
        top:"10px",
        borderRadius:"8px",
        // margin:"0px 8px"
    },
    warningRedCard:{
        borderRadius:theme.palette.borderRadius,
        backgroundColor:"#FFECEC",
        padding:"8px"
    },
    warningRedText:{
        fontSize:remCalc(12),
        fontFamily:SemiBold,
        color:"#FF4B4B"
    }, 
    viewResourceCard: {
        borderRadius: theme.palette.borderRadius,
        padding: "16px",
        backgroundColor: "#F5F7FA"
    },  
    viewContractCard:{
        backgroundColor:"#FFFFFF",
        border:"1px solid #CED3DD",
        padding:"16px",
        borderRadius:theme.palette.borderRadius
    },
    viewAccountDetails:{
        backgroundColor:"#F1F7FF",
        padding:"12px",
        borderRadius:"6px"
    },
    viewContractDetails:{
        backgroundColor:"#F5F7FA",
        padding:"12px",
        borderRadius:"6px"
    },
    scheduleDetailsCard:{
        backgroundColor:"#F1F7FF",
        borderRadius:theme.palette.borderRadius,
        padding:"8px"
    },
    scheduletxt:{
        fontSize:remCalc(12),
        fontFamily:SemiBold,
        color:"#FFFFFF"  
    },
    scheduledetailstext:{
        fontSize:remCalc(14),
        fontFamily:SemiBold,
        color:theme.typography.color.primary  
    },
    scheduledetailslabel:{
        fontSize:remCalc(12),
        fontFamily:SemiBold,
        color:theme.typography.color.secondary   
    },
    publishtext: {
        fontSize: remCalc(14),
        color: theme.typography.color.primary,
        fontFamily: Bold,
        cursor:"pointer"
    },
    selectedPublishText: {
        fontSize: remCalc(14),
        color: "#5078E1",
        fontFamily: Bold,
        cursor:"pointer"
    }
}))
export const ChooseCardStyle = makeStyles((theme, props) => ({
    title: {
        fontSize: "0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold
    },
    subTitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    },
    box: {
        borderRadius: 4,
        // border: (props)=> props?.border ?? `1px solid ${theme.palette.border.primary}`,
        width: "100%"
    },
    header: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold
    },
    boldHeader: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    remove: {
        fontSize: "0.875rem",
        color: theme.palette.error.main,
        fontFamily: SemiBold,
        cursor: "pointer"
    },
    optionAvatar: {
        height: 32,
        width: 32,
        borderRadius: "4px !important",
        fontSize: "1rem",
        fontFamily: Bold
    },
    optionHeader: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: "#091B29"
    },
    optionSubTitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        whiteSpace: "noWrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginLeft: "0px !important",
        direction: "ltr"
    },
    dot: {
        height: 6,
        width: 6,
        background: theme.palette.border.primary,
        borderRadius: 50,
        margin: "0px 5px !important"
    },
    selectParent: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #E4E8EE",
        margin: "5px",
    },
    choosecards: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #F1F7FF",
        background: "#F1F7FF",
        position: "relative",
        display: "flex",
        // justifyContent:"space-between"

    },
    selectMultiParent: {
        borderBottom: "1px solid #E4E8EE !important",
        borderRadius: 0,
        borderTop: 'none !important',
        borderLeft: 'none !important',
        borderRight: 'none !important',
        margin: "5px"



    },
    choosecardsMuti: {
        padding: "5px",
        borderRadius: 4,
        border: "1px solid #E4E8EE",
        background: "#F6F1FE",
        position: "relative"

    },
    newRoot: {
        borderRadius: "0px 0px 4px 4px",
        cursor: "pointer",
        alignItems: "center",
        borderTop: "none"
    },
    existRoot: {
        borderRadius: "4px 4px 0px 0px",
        cursor: "pointer",
        alignItems: "center",
        borderBottom: "none"
    },
    labelBold: {
        color: "#showCard091B29",
        fontFamily: Bold,
        fontSize: "0.875rem",
        marginBottom: "8px"
    },
    SelectedcontractCard:{
        backgroundColor:"#F5F7FA",
        borderRadius:"6px",
        padding:"8px"
    },
    contractIcon:{
        backgroundColor:"#CED3DD",
        borderRadius:"6px",
        padding:"8px" 
    },
    contractTitle:{
        fontSize:remCalc(12),
        fontFamily:Bold,
        color:theme.typography.color.primary
    },
    contractSubTitle:{
        fontSize:remCalc(12),
        fontFamily:SemiBold,
        color:theme.typography.color.secondary
    },
    propertyTitle:{
        fontSize:remCalc(14),
        fontFamily:SemiBold,
        color:theme.typography.color.primary
    },

}))

export const CommonFormStyles = makeStyles((theme)=>({
    unitAssetCard:{
        backgroundColor:"#F1F7FF",
        borderRadius:theme.palette.borderRadius,
        padding:"12px"
    },
    unitTitle:{
        fontSize:remCalc(12),
        fontFamily:Bold,
        color:theme.typography.color.primary
    },
    unitSubTitle:{
        fontSize:remCalc(12),
        fontFamily:SemiBold,
        color:theme.typography.color.secondary
    },
    itemSelectedCard:{
        backgroundColor:"#F6F1FE",
        borderRadius:theme.palette.borderRadius,
        padding:"8px"
    },
    selectedCount:{
        fontSize:remCalc(14),
        fontFamily:SemiBold,
        color:theme.typography.color.primary 
    },
    fixedbtn:{
        position:"absolute",
        bottom:0,
        left:0,
        right:0,
        padding:"16px"
    },
    recommendedUnitTitle:{
        fontSize:remCalc(12),
        fontFamily:SemiBold,
        color:theme.typography.color.primary,
        textTransform:"uppercase"
    },
    unitItemCard:{
        border:"1px solid #CED3DD",
        borderRadius:"8px",
        padding:"8px"
    },
    selectedName:{
        fontSize:remCalc(14),
        fontFamily:Bold,
        color:theme.typography.color.primary 
    },
}))