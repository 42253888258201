import { Box, Grid } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { DialogDrawer, Subheader } from "../../components";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, BackendRoutes, NetWorkCallMethods, accessCheckRender, getCalendarOptions, getCompanyOption, getRoutePermissionNew, pageName } from "../../utils";
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent";
import { CalendarDetail, CardDetail, TableDetail } from "./components";
import { useStyles } from "./style";
import { StatDataFunc } from "./utils/data";
import { DiscussionDataType, DiscussionHeading, DiscussionPath, OwnerHeading, OwnerPath, OwnerType, ResidentHeading, ResidentPath, ResidentType } from "./utils/tableUtils";

const CommunityDashboard = (props) => {
    const { loading, handleLoading, t } = props;

    const classes = useStyles()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [residentList, setResidentList] = React.useState({ count: 0, list: [] })
    const [ownerList, setOwnerList] = React.useState({ count: 0, list: [] })
    const [discussionList, setDiscussionList] = React.useState({ count: 0, list: [] })
    // const [amenityBookingList, setAmenityBookingList] = React.useState({ count: 0, list: [{ unit_name: "B100", amenity: "Swimming Pool", payment_status: "Pending", "slot_status": "Available" }] })
    const [loader, setLoader] = React.useState(true)
    const [permission, setPermission] = React.useState({});
    const [limit, setLimit] = React.useState(10)
    const [page, setPage] = React.useState(1)
    const [zoom, setZoom] = useState(false);
    const [zoomGraph, setZoomGraph] = useState("");


    const [state, setState] = React.useState({
        company: [],
        selectedCompany: {},
        property: [],
        selectedProperty: {},
        calanderOption: [],
        selectedCalander: {}
    });

    const [stats, setStats] = React.useState({})

    const TableName = {
        resident: t("Resident and Tenant Information"),
        owner: t("Owner Information"),
        activeDiscussion: t("Active Discussions"),
        todayActivity: t("Today Activity"),
        amenitiesBookingCalendar: t("Amenities Booking Calendar"),
        amenityBooking: t("Amenity Booking")
    }
    const zoomGraphFunction = (e) => {
        setZoomGraph(e);
        setZoom(true);
    };

    // close popup
    const handleCloseModule = () => {
        setZoom(false);
    };
    // update a state
    const updateState = (k, v) => {
        setState({ ...state, [k]: v })
    }

    // Function to handle pagination in for Resident table 
    const handleTablePaginationResident = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        // getResidents(state?.selectedCompany, state?.selectedProperty?.value)
        getResidents(state?.selectedCompany, offset, limit, state?.selectedProperty?.value, false)
        // state?.selectedCompany, 0, value, reload, state?.selectedProperty?.value
    }

    // Function to handle page limit in Resident table
    const handleTablePageLimitResident = (value) => {
        setLimit(value)
        setPage(1)
        getResidents(state?.selectedCompany, 0, value, state?.selectedProperty?.value, false)
        // company = state?.selectedCompany, offsetValue, reload, property_id
    }

    // Function to handle pagination in for Owners table 
    const handleTablePaginationOwners = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getOwners(state?.selectedCompany, offset, limit, state?.selectedProperty?.value, false)

    }

    // Function to handle page limit in Owners table
    const handleTablePageLimitOwners = (value) => {
        setLimit(value)
        setPage(1)
        getOwners(state?.selectedCompany, 0, value, state?.selectedProperty?.value, false)

    }

    // Function to handle pagination in for Discussion table 
    const handleTablePaginationDiscussion = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getDiscussion(state?.selectedCompany, state?.selectedCalander?.value?.from_date, state?.selectedCalander?.value?.to_date, state?.selectedProperty?.value, offset, false, limit)
        // state?.selectedCompany, state?.selectedCalander?.value?.from_date, state?.selectedCalander?.value?.to_date, state?.selectedProperty?.value, 0, false, value
    }

    // Function to handle page limit in Discussion table
    const handleTablePageLimitDiscussion = (value) => {
        setLimit(value)
        setPage(1)
        getDiscussion(state?.selectedCompany, state?.selectedCalander?.value?.from_date, state?.selectedCalander?.value?.to_date, state?.selectedProperty?.value, 0, false, value)
        //   offset = 0, loading = false
    }

    // // Function to handle pagination in for Amenities table 
    // const handleTablePaginationAmenities = (value) => {
    //     setPage(value)
    //     let offset = (value - 1) * limit
    //     getAmenities(state?.selectedCompany, state?.selectedCalander?.value?.from_date, state?.selectedCalander?.value?.to_date, state?.selectedProperty?.value, offset, limit)
    // }

    // // Function to handle page limit in Amenities table
    // const handleTablePageLimitAmenities = (value) => {
    //     setLimit(value)
    //     setPage(1)
    //     getAmenities(state?.selectedCompany, state?.selectedCalander?.value?.from_date, state?.selectedCalander?.value?.to_date, state?.selectedProperty?.value, 0, value)
    // }

    // Function to change the company
    const handleCompanyChange = (company) => {
        setLoader(true)
        let properties =
            company?.property?.map((x) => {
                return {
                    value: x.id,
                    label: x.name
                }
            })
        parentFun({
            company: company,
            date: state?.selectedCalander,
            property_id: null,
        })
        setState({
            ...state,
            selectedCompany: company,
            property: [{label:"All Properties",value:null},...properties],
            selectedProperty: {label:"All Properties",value:null}
        })
    }
    // Function to change the Calendar
    const handleCalendarChange = (date) => {
        if (date?.load) {
            setLoader(true)
            updateState('selectedCalander', date)
            parentFun({
                company: state?.selectedCompany,
                date,
                property_id: state?.selectedProperty?.value
            })
        }
    }

    const getActivityDate = (val) => {
        let tempDate = val?._d ? val?._d : val;
        let _tempDate = moment(tempDate).format("YYYY-MM-DD")
        getDiscussion(state?.selectedCompany, _tempDate, _tempDate, state?.selectedProperty?.value, 0)
    }

    //Function to get STATS API Call
    const getStats = (company = state?.selectedCompany, start_date = state?.selectedCalander?.from_date, end_date = state?.selectedCalander?.end, property_id = state?.selectedProperty) => {
        const payload = {
            company_id: company?.value,
            start_date,
            end_date,
            property_id
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.communityDashboardStats}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            let tempStat = StatDataFunc(t, _?.data?.data)
            setStats(tempStat)
            handleLoading(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, severity: AlertProps.severity.error,
                open: true, msg: t("Some Thing Went Wrong"),
            })
        })
    }
    //Function to get residents List API Call
    const getResidents = (company = state?.selectedCompany, offsetValue, reload, property_id) => {
        const payload = {
            company_id: company?.value,
            offset: offsetValue,
            limit,
            property_id
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.communityDashboardResidents}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            let tempData = _?.data?.data ?? []
            let tempList = tempData?.data?.map((r) => {
                return {
                    saleRep: { image: r?.image_url ?? "-" },
                    name: r?.name ?? "",
                    unit_name: r?.unit_no + (r?.unit_name ? (" - " + r?.unit_name) : ""),
                    end_date: r?.lease_end_date ?? "-",
                    start_date: r?.lease_start_date ?? "-",
                    occupants_count: r?.occupants_count ?? 0,
                    last_login: r?.last_logged_in,
                    mobile_number:r?.mobile_no_country_code+r?.mobile_no??"-"

                }
            })
            let tempResidentList = { count: tempData?.count ?? 0, list: tempList ?? [] }
            setResidentList(tempResidentList)
            // if (reload) {
            //     setResidentList(tempResidentList)
            // } else {
            //     setResidentList({ ...residentList, list: [...residentList?.list, ...tempResidentList?.list] })
            // }
            handleLoading(false)
        }).catch((e) => {
        })
    }
    //Function to get owners List API Call
    const getOwners = (company = state?.selectedCompany, offsetValue, reload, property_id) => {
        const payload = {
            company_id: company?.value,
            offset: offsetValue,
            limit: 10,
            property_id
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.communityDashboardOwners}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            let tempData = _?.data?.data ?? []
            let tempList = tempData?.data?.map((r) => {
                return {
                    saleRep: { image: r?.image_url ?? "-" },
                    name: r?.name ?? "",
                    unit_name: r?.unit_no + (r?.unit_name ? (" - " + r?.unit_name) : ""),
                    last_login: r?.last_logged_in,
                    occupants_count: r?.occupants_count ?? 0,
                    mobile_number:r?.mobile_no_country_code+r?.mobile_no??"-"
                }
            })
            let tempOwnerList = { count: tempData?.count ?? 0, list: tempList ?? [] }
            setOwnerList(tempOwnerList)
            // if (reload) {
            //     setOwnerList(tempOwnerList)
            // } else {
            //     setOwnerList({ list: [...ownerList?.list, ...tempOwnerList?.list] })
            // }
            handleLoading(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, severity: AlertProps.severity.error,
                open: true, msg: t("Some Thing Went Wrong"),
            })
        })
    }

    //Function to get discussion List API Call
    const getDiscussion = (company = state?.selectedCompany, from_date = state?.selectedCalander?.value?.from_date, to_date = state?.selectedCalander?.end, property_id, offset = 0, loading = false) => {
        const payload = {
            company_id: company?.value,
            start_date: from_date,
            end_date:to_date,
            offset,
            limit: 10,
            property_id
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.communityDashboardDiscussions}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            let tempDiscussionList = { count: _?.data?.count ?? 0, data: loading ? [...discussionList?.data?.data, ..._?.data?.data] : _?.data?.data }
            setDiscussionList(tempDiscussionList)
            handleLoading(false)
            setLoader(false)
        }).catch((e) => {
            setLoader(false)
            alert.setSnack({
                ...alert, severity: AlertProps.severity.error,
                open: true, msg: t("Some Thing Went Wrong"),
            })
        })
    }
    // //Function to get amenity List API Call
    // const getAmenities = (company = state?.selectedCompany, from_date = state?.selectedCalander?.from_date, to_date = state?.selectedCalander?.end, property_id, offset = 0, limit = 10) => {
    //     const payload = {
    //         company_id: company?.value,
    //         start_date: from_date,
    //         end_date: to_date,
    //         property_id,
    //         limit,
    //         offset
    //     }
    //     NetworkCall(
    //         `${config.api_url}${BackendRoutes.communityDashboardAmenities}`,
    //         NetWorkCallMethods.post, payload, null, true, false
    //     ).then((_) => {
    //         let tempActivityList = _?.data?.data ?? []
    //         setAmenityBookingList({
    //             count: tempActivityList?.count ?? 0,
    //             data: tempActivityList?.data
    //         })
    //         handleLoading(false)
    //         setLoader(false)
    //     }).catch((e) => {
    //         alert.setSnack({
    //             ...alert, severity: AlertProps.severity.error,
    //             open: true, msg: t("Some Thing Went Wrong"),
    //         })
    //     })
    // }

    const parentFun = ({
        company = state?.selectedCompany,
        date = state?.selectedCalander,
        property_id = state?.selectedProperty
    }) => {
        getResidents(company, 0, true, property_id)
        getStats(company, date?.value?.from_date, date?.value?.to_date, property_id)
        getOwners(company, 0, true, property_id)
        getDiscussion(company, date.value.from_date, date.value.to_date, property_id)
        // getAmenities(company, date.value.from_date, date.value.to_date, property_id, 0)
        setOffset(0)
        setOwnerOffset(0)
        setActiveDiscussionOffset(0)
        // setAmenityBookingOffset(0)

    }

    const [offset, setOffset] = React.useState(0);
    const [ownerOffset, setOwnerOffset] = React.useState(0)
    const [activeDiscussionOffset, setActiveDiscussionOffset] = React.useState(0)
    // const [amenityBookingOffset, setAmenityBookingOffset] = React.useState(0)


    const fetchMoreData = () => {
        setOffset(offset + 10);
        getResidents(state?.selectedCompany, offset + 10, false, state?.selectedProperty?.value)
    };

    const fetchMoreOwnerData = () => {
        setOwnerOffset(ownerOffset + 10);
        getOwners(state?.selectedCompany, ownerOffset + 10, false, state?.selectedProperty?.value)
    };
    //fetch more active Discussions
    const fetchMoreDiscussionsData = () => {
        setActiveDiscussionOffset(activeDiscussionOffset + 10)
        getDiscussion(state?.selectedCompany, state?.selectedCalander.from_date, state?.selectedCalander?.to_date, state?.selectedProperty?.value, activeDiscussionOffset + 10)

    }
    //fetch more amenity booking data
    // const fetchMoreAmenityBookingData = () => {
    //     setAmenityBookingOffset(amenityBookingOffset + 10)
    //     getAmenities(state?.selectedCompany, state?.selectedCalander.from_date, state?.selectedCalander?.to_date, state?.selectedProperty?.value, amenityBookingOffset + 10)

    // }
    const onPropertyChange = (val) => {
        setLoader(true)
        updateState('selectedProperty', val)
        parentFun({
            company: state?.selectedCompany,
            date: state?.selectedCalander,
            property_id: val?.value
        })
    }

    // useEffect to get company list for company switcher when loading the screen
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    let properties =
                        company?.selected?.property?.map((x) => {
                            return {
                                value: x.id,
                                label: x.name
                            }
                        })
                    let calanderOption = getCalendarOptions()
                    setState({
                        ...state,
                        company: company?.list,
                        selectedCompany: company?.selected,
                        property: [{label:"All properties",value:null},...properties] ?? [],
                        selectedProperty: {label:"All properties",value:null},
                        calanderOption: calanderOption,
                        selectedCalander: calanderOption[6]
                    })
                    parentFun({
                        company: company?.selected,
                        date: calanderOption[6],
                        property_id: null
                    })

                }
            }
        }

        // eslint-disable-next-line
    }, [auth])

    const render = () => {
        return <div className={classes.root}>
            <Subheader
                hideBackButton={true}
                title={t("Community & HOA Dashboard")}
                calanderselect
                calendarOptions={state?.calanderOption}
                calanderValue={state?.selectedCalander}
                onChangeCalendar={(e) => handleCalendarChange(e)}
                select
                options={state?.company}
                value={state?.selectedCompany}
                onPropertyChange={(e) => {
                    onPropertyChange(e)
                }}
                selectProperty
                propertyOptions={state?.property}
                propertyValue={state?.selectedProperty}
                onchange={(e) => handleCompanyChange(e)}
            />
            {loader ? (<NewLoader minusHeight="100px" />) :
                (<div className={classes.body}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Grid container spacing={2}>
                                {stats?.cardData?.map((val) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={6} lg={6}>

                                            <CardDetail data={val} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid xs={12} sm={12} md={9} lg={9} item alignContent={"start"}>
                            <div className={classes.table}>
                                <TableDetail
                                    title={`${TableName.resident} (${residentList?.count})`}
                                    tableData={residentList?.list ?? []}
                                    tabelheight={256}
                                    heading={ResidentHeading(t)}
                                    path={ResidentPath}
                                    dataType={ResidentType}
                                    fetchMoreData={fetchMoreData}
                                    noDataSvg
                                    fontSize={"12px !important"}
                                    color={""}
                                    tableWithPagination
                                    handleTablePagination={handleTablePaginationResident}
                                    handleTablePageLimit={handleTablePageLimitResident}
                                    limit={limit}
                                    page={page}
                                    totalRowsCount={residentList?.count}
                                // headingColor
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <Grid container spacing={"16px"}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Grid container spacing={2}>
                                    {stats?.cardDetails2?.map((val) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <CardDetail data={val} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={12} md={9} lg={9} item alignContent={"start"}>
                                <div className={classes.table}>
                                    <TableDetail
                                        title={`${TableName.owner} (${ownerList?.count})`}
                                        tableData={ownerList?.list ?? []}
                                        tabelheight={256}
                                        heading={OwnerHeading(t)}
                                        path={OwnerPath}
                                        dataType={OwnerType}
                                        fetchMoreData={fetchMoreOwnerData}
                                        noDataSvg
                                        tableWithPagination
                                        handleTablePagination={handleTablePaginationOwners}
                                        handleTablePageLimit={handleTablePageLimitOwners}
                                        limit={limit}
                                        page={page}
                                        totalRowsCount={ownerList?.count}
                                    />
                                </div>
                            </Grid>
                            {/* <Grid xs={12} sm={12} md={2} lg={1.5} item alignContent={"start"}
                                    justifyContent={"space-between"} sx={{ display: "flex" }}>

                                    <Grid container spacing={"16px"}>
                                        {stats?.cardDetails4?.map((_) => {
                                            return <Grid item xs={12} sm={4} md={12} lg={12}>
                                                <CardDetail data={_} />
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={10} lg={7.5} item alignContent={"start"}>
                                    <div className={classes.table}>
                                        <CalendarDetail
                                            title={TableName?.activeDiscussion}
                                            // date={state?.selectedCalander?.from_date}
                                            // data={discussionList?.list ?? []}
                                            getDate={getActivityDate}
                                            value={{
                                                date: new Date(state?.selectedCalander?.value?.from_date)
                                            }}
                                            tableData={discussionList?.data?.data ?? []}
                                            tabelheight={255}
                                            heading={DiscussionHeading(t)}
                                            path={DiscussionPath}
                                            dataType={DiscussionDataType}
                                            fetchMoreData={fetchMoreDiscussionsData}
                                            limit={limit}
                                            page={page}
                                            totalRowsCount={discussionList?.data?.count}
                                            noDataSvg
                                            tableWithPagination
                                            handleTablePagination={handleTablePaginationDiscussion}
                                            handleTablePageLimit={handleTablePageLimitDiscussion}
                                        />
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={2} lg={1.5} item alignContent={"start"}
                                    justifyContent={"space-between"} sx={{ display: "flex" }}>
                                    <Grid container spacing={"16px"}>
                                        {stats?.cardDetails5?.map((_) => {
                                            return <Grid item xs={12} sm={4} md={12} lg={12}>
                                                <CardDetail data={_} />
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={2} lg={1.5} item alignContent={"start"}
                                    justifyContent={"space-between"}>
                                    <Grid container spacing={"16px"}>
                                        {stats?.cardDetails6?.map((_) => {
                                            return <Grid item xs={12} sm={4} md={12} lg={12}>
                                                <CardDetail data={_} />
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={1.5} item alignContent={"start"}
                                    justifyContent={"space-between"}>
                                    <Grid container spacing={"16px"}>
                                        {stats?.bookingData?.map((_) => {
                                            return <Grid item xs={12} sm={3} md={3} lg={12}>

                                                <CardDetail data={_} />
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={10} lg={10.5} item alignContent={"start"}>
                                    <div className={classes.table}>
                                        <TableDetail
                                            title={`${TableName.amenityBooking}(${amenityBookingList?.count})`}
                                            tableData={amenityBookingList?.data ?? []}
                                            tabelheight={388}
                                            heading={AmenityBookingHeading(t)}
                                            path={AmenityBookingPath}
                                            dataType={AmenityBookingDataType}
                                            fetchMoreData={fetchMoreAmenityBookingData}
                                            noDataSvg
                                            tableWithPagination
                                            handleTablePagination={handleTablePaginationAmenities}
                                            handleTablePageLimit={handleTablePageLimitAmenities}
                                            limit={limit}
                                            page={page}
                                            totalRowsCount={amenityBookingList?.count}
                                        />
                                    </div>
                                </Grid> */}
                        </Grid>
                    </Box>
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={12} md={12} lg={6} item alignContent={"start"}>
                                <div className={classes.table}>
                                    <CalendarDetail
                                        title={TableName?.activeDiscussion}
                                        // date={state?.selectedCalander?.from_date}
                                        // data={discussionList?.list ?? []}
                                        getDate={getActivityDate}
                                        value={{
                                            date: new Date()
                                        }}
                                        tableData={discussionList?.data?.data ?? []}
                                        tabelheight={discussionList?.data?.data?.length>0?600:635}
                                        heading={DiscussionHeading(t)}
                                        path={DiscussionPath}
                                        dataType={DiscussionDataType}
                                        fetchMoreData={fetchMoreDiscussionsData}
                                        limit={limit}
                                        page={page}
                                        totalRowsCount={discussionList?.data?.count}
                                        noDataSvg
                                        tableWithPagination
                                        handleTablePagination={handleTablePaginationDiscussion}
                                        handleTablePageLimit={handleTablePageLimitDiscussion}
                                    />
                                    {/* <div className={classes.table}> */}
                                    {/* <TableDetail
                                    title={`${TableName.activeDiscussion} (${discussionList?.data?.count})`}
                                    tableData={discussionList?.data?.data ?? []}
                                    tabelheight={500}
                                    heading={DiscussionHeading(t)}
                                    path={DiscussionPath}
                                    dataType={DiscussionDataType}
                                    fetchMoreData={fetchMoreData}
                                    noDataSvg
                                    fontSize={"12px !important"}
                                    color={""}
                                    tableWithPagination
                                    handleTablePagination={handleTablePaginationDiscussion}
                                    handleTablePageLimit={handleTablePageLimitDiscussion}
                                    limit={limit}
                                    page={page}
                                    totalRowsCount={discussionList?.data?.data}
                                // headingColor
                                /> */}
                                    {/* </div> */}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <GraphComponent
                                            t={t}
                                            title={t("KYC Information")}
                                            header={t("KYC Information")}
                                            isKyc
                                            data={stats?.kyc_type}
                                            margin={"20px"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <GraphComponent
                                            t={t}
                                            graphTitle={t("Correspondence & Letter Management")}
                                            chartHeader={t("Correspondence & Letter Management")}
                                            graphData={stats?.correspondence_type?.data ?? []}
                                            is_pie_new
                                            isShowLegend={true}
                                            height={"350px"}
                                            total={stats?.correspondence_type?.total ?? 0}
                                            innerRadius={70}
                                            onZoomClick={zoomGraphFunction}
                                            textMargin={'0px 0px 16px'}
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <GraphComponent
                                                padding={"0px 16px"}
                                                graphTitle={("Worker Requests")}
                                                chartHeader={t("Worker Requests")}
                                                height={"360px"}
                                                graphData={stats?.WorkerRequest_data ?? []}
                                                is_variable_pie_new
                                                t={t}
                                                innerRadius={75}
                                                paddingAngle={2}
                                                isTotal
                                                total={stats?.WorkerRequest_data?.map((item) => parseInt(item?.count))
                                                    .reduce((a, b) => a + b, 0) ?? 0}
                                                minWidth={true}
                                                onZoomClick={zoomGraphFunction}
                                                textMargin={'0px 0px 16px'}
                                                divider1={
                                                    {
                                                        xs: 12,
                                                        sm: 7.4,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                divider={
                                                    {
                                                        xs: 12,
                                                        sm: 4.6,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                gradiantStyle={{
                                                    margin: '0px 0px 0px',
                                                    display: "auto",
                                                    alignItems: "center",
                                                    top: {
                                                        lg: "auto",
                                                        md: '16px',
                                                        sm: "3px",
                                                    },
                                                    height: {
                                                        md: "400px"
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                            <GraphComponent
                                                graphTitle={("Login Access Requests")}
                                                chartHeader={t("Login Access Requests")}
                                                height={"320px"}
                                                isShowLegend={true}
                                                graphData={stats?.loginAccessRequest ?? []}
                                                is_semi_circle_new
                                                t={t}
                                                innerRadius={75}
                                                paddingAngle={2}
                                                isTotal
                                                total={stats?.loginAccessRequest?.map((item) => parseInt(item?.count))
                                                    .reduce((a, b) => a + b, 0) ?? 0}
                                                minWidth={true}
                                                onZoomClick={zoomGraphFunction}
                                                textMargin={'0px 0px 16px'}
                                                divider1={
                                                    {
                                                        xs: 12,
                                                        sm: 12,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                divider={
                                                    {
                                                        xs: 12,
                                                        sm: 12,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                gradiantStyle={{
                                                    margin: '0px 0px 0px',
                                                    display: "auto",
                                                    alignItems: "center",
                                                    top: {
                                                        lg: "auto",
                                                        md: '16px',
                                                        sm: "3px",
                                                    },
                                                    height: {
                                                        md: "400px"
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </div >)}
            {/* zoom dialog for each graph */}

            <DialogDrawer
                header={t(zoomGraph)}
                maxWidth={zoomGraph === "Map View" ? "lg" : "md"}
                handleClose={handleCloseModule}
                open={zoom}
                height={zoomGraph === "Map View" ? '700px' : "auto"}
                borderRadius={"12px"}
                padding={'0px'}
                onClose={handleCloseModule}
                component={
                    <>
                        {zoomGraph === "Correspondence & Letter Management" && (
                            <GraphComponent
                                graphData={stats?.correspondence_type?.data ?? []}
                                isPie
                                innerRadius={100}
                                isTotal
                                margin={"0px"}
                                minWidth={false}
                                total={stats?.correspondence_type?.total ?? 0}
                                paddingAngle={2}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                centerTop={"44%"}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '0px',
                                        sm: "auto",
                                    },
                                    bottom: {
                                        sm: "auto",
                                        md: "-16px",
                                        lg: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}

                            />
                        )}
                        {zoomGraph === "Worker Requests" && (
                            <GraphComponent
                                graphData={stats?.WorkerRequest_data ?? []}
                                isPie
                                innerRadius={100}
                                isTotal
                                margin={"0px"}
                                minWidth={false}
                                total={stats?.WorkerRequest_data?.map((item) => parseInt(item?.count))
                                    .reduce((a, b) => a + b, 0) ?? 0}
                                paddingAngle={2}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                centerTop={"44%"}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '0px',
                                        sm: "auto",
                                    },
                                    bottom: {
                                        sm: "auto",
                                        md: "-16px",
                                        lg: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}

                            />
                        )}
                            {zoomGraph === "Login Access Requests" && (
                            <GraphComponent
                                graphData={stats?.loginAccessRequest ?? []}
                                isPie
                                innerRadius={100}
                                isTotal
                                margin={"0px"}
                                minWidth={false}
                                total={stats?.loginAccessRequest?.map((item) => parseInt(item?.count))
                                    .reduce((a, b) => a + b, 0) ?? 0}
                                paddingAngle={2}
                                padding={"16px"}
                                maxHeightGraph={"250px"}
                                height={"auto"}
                                is_popUp
                                justifyContent={'start'}
                                centerTop={"44%"}
                                divider1={
                                    {
                                        xs: 12,
                                        sm: 5,
                                        md: 5,
                                        lg: 5
                                    }
                                }
                                divider={
                                    {
                                        xs: 12,
                                        sm: 7,
                                        md: 7,
                                        lg: 7
                                    }
                                }

                                gradiantStyle={{
                                    margin: '0px 0px 0px',
                                    display: "flex",
                                    alignItems: "center",
                                    grid_template: "100%",
                                    maxHeight: "auto",
                                    top: {
                                        lg: "auto",
                                        md: '0px',
                                        sm: "auto",
                                    },
                                    bottom: {
                                        sm: "auto",
                                        md: "-16px",
                                        lg: "auto",
                                    },
                                    height: {
                                        md: "auto",
                                        sm: "auto",
                                        lg: "100%"
                                    }
                                }}

                            />
                        )}
                    </>
                }
            />
        </div >


    }

    return <div>{accessCheckRender(render, permission, pageName.dashboard, loading)}</div>;
}

export default withNamespaces("communityDashboard")(CommunityDashboard);
