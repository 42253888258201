import * as React from "react"
const FinalSettleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    data-name="Group 111932"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#78b1fe" data-name="Ellipse 129948" />
    <path
      fill="#fff"
      d="M11.79 8a1.9 1.9 0 0 0-1.895 1.895v9.895h-.631a.632.632 0 0 0-.632.631v.842A2.747 2.747 0 0 0 11.369 24h9.263a2.747 2.747 0 0 0 2.737-2.737V9.895A1.9 1.9 0 0 0 21.474 8Zm1.684 3.368a.632.632 0 1 1-.631.632.632.632 0 0 1 .631-.632Zm2.105 0h4.211a.632.632 0 1 1 0 1.263h-4.211a.632.632 0 0 1 0-1.263Zm-2.105 2.527a.632.632 0 1 1-.632.632.632.632 0 0 1 .632-.632Zm2.105 0h4.211a.632.632 0 1 1 0 1.263h-4.211a.632.632 0 1 1 0-1.263Zm-2.105 2.526a.632.632 0 1 1-.632.632.632.632 0 0 1 .632-.632Zm2.105 0h4.211a.632.632 0 1 1 0 1.263h-4.211a.632.632 0 0 1 0-1.263Zm-5.684 4.632h8v.211a2.656 2.656 0 0 0 .494 1.474h-7.02a1.465 1.465 0 0 1-1.474-1.474Z"
      data-name="icons8-purchase-order (1)"
    />
  </svg>
)
export default FinalSettleIcon
