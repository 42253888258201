import React from "react";
import { SelectAnnounsment } from "../../../components/selectAnnounsment";
import { Grid, Box } from "@mui/material";
import { useStyles } from "../style";
import { useWindowDimensions } from "../../../utils";

export const PropertySelect = ({
  t,
  listingDetails = {},
  chooseProperty = () => false,
  chooseRole = () => false,
  ChooseAll = () => false,
  searchFunctionBuild = () => false,
  searchFunctionRole = () => false,
  searchText = {},
  fetchMoreDataProperty = () => false,
  fetchMoreDataRole = () => false,
  listingDetails1 = {}
}) => {
  const size = useWindowDimensions()
  // classes
  const classes = useStyles({ size });
  return (
    <div>
      <Box className={classes.roleRoot}>
        <Grid container spacing={"14px"}>
          <Grid item sm={12} md={8}>
            <SelectAnnounsment
              title={t("Select_Property")}
              subtitle={t("Select_Property_Sub")}
              selectAll={t("all_Property")}
              selectAllValue={listingDetails?.propertyselectAll ?? false}
              count="01"
              data={listingDetails?.property ?? []}
              chooseFuncion={chooseProperty}
              selectAllFunction={(e) => ChooseAll("property")}
              searchFunction={searchFunctionRole}
              searchValue={searchText?.propertySearch}
              fetchMoreData={fetchMoreDataProperty}
              type={"property"}
              div_height={
                size?.width > 899
                  ? (size?.height - 253)
                  : size?.height > 850
                  ? ((size?.height - 253) / 2)
                  : 280
              }
              infinite_scroll_height={
                size?.width > 899
                  ? (size?.height - 417)
                  : size?.height > 850
                  ? ((size?.height - 567) / 2)
                  : 120
              }
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <SelectAnnounsment
              title={t("Select_Role")}
              subtitle={t("Select_Role_Sub")}
              selectAll={t("all_Role")}
              selectAllValue={listingDetails1?.roleselectAll ?? false}
              count="02"
              data={listingDetails1?.role ?? []}
              chooseFuncion={chooseRole}
              selectAllFunction={(e) => ChooseAll("role")}
              searchFunction={searchFunctionBuild}
              searchValue={searchText?.roleSearch}
              fetchMoreData={fetchMoreDataRole}
              div_height={
                size?.width > 899
                  ? (size?.height - 253)
                  : size?.height > 850
                  ? ((size?.height - 253) / 2)
                  : 280
              }
              infinite_scroll_height={
                size?.width > 899
                  ? (size?.height - 417)
                  : size?.height > 850
                  ? ((size?.height - 567) / 2)
                  : 120
              }
              isGridValueChange
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
