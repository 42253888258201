import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../../utils";
export const useStyles = makeStyles((theme) => ({
    card: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
    },
    heading: {
        color: theme.typography.color.tertiary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        textTransform: "capitalize",
        display: "flex",
        alignItems: "center"
    },
    title: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: Bold
    },
    sub: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        overflow: "hidden",
        textOverflow: "ellipsis"

    },
    sub1: {
        color: theme.typography.color.primary,
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    addressRoot: {
        padding: "12px",
        minHeight: "200px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius
    },
}));