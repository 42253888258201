import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import Reports from './reports'

class ReportsParent extends Component {
    render() {
        return (
            <div><Reports {...this.props} /></div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ReportsParent, props)
