import React from "react";
import { withNavBars } from "../../../HOCs";
import VisitorAndWorkedGatePass from './visitorAndWorkedGatePass';

class VisitorAndWorkedGatePassParent extends React.Component {
  render() {
    return <VisitorAndWorkedGatePass {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(VisitorAndWorkedGatePassParent, props);
