import * as React from "react"

export const Aggreement = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 94883">
            <g data-name="Group 7567">
                <path data-name="Rectangle 49471" fill="none" d="M0 0h24v24H0z" />
            </g>
            <path
                d="M6.25 2A2.261 2.261 0 0 0 4 4.25v15.5A2.261 2.261 0 0 0 6.25 22h11.5A2.261 2.261 0 0 0 20 19.75V9.25a.75.75 0 0 0-.22-.53l-.008-.008L13.28 2.22a.75.75 0 0 0-.53-.22Zm0 1.5H12v4.25A2.261 2.261 0 0 0 14.25 10h4.25v9.75a.739.739 0 0 1-.75.75H6.25a.739.739 0 0 1-.75-.75V4.25a.739.739 0 0 1 .75-.75Zm7.25 1.061L17.439 8.5H14.25a.739.739 0 0 1-.75-.75ZM12 10.5a2.159 2.159 0 0 0-1.334.462l-2.344 1.847A2.159 2.159 0 0 0 7.5 14.5v2.8a1.7 1.7 0 0 0 1.687 1.688h5.625A1.7 1.7 0 0 0 16.5 17.3v-2.8a2.155 2.155 0 0 0-.82-1.693l-2.344-1.847A2.156 2.156 0 0 0 12 10.5Zm0 1.5a.648.648 0 0 1 .405.141l2.344 1.847A.652.652 0 0 1 15 14.5v2.8a.177.177 0 0 1-.187.188H9.186A.177.177 0 0 1 9 17.3v-2.8a.654.654 0 0 1 .25-.516l2.343-1.847A.652.652 0 0 1 12 12Zm-.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5Z"
                fill={props?.color}
                data-name="Group 94892"
            />
        </g>
    </svg>
)


