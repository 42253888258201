import * as React from "react"

export const UsedAmount = (props) => (
    <svg
        data-name="Group 98670"
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={50}
        {...props}
    >
        <rect
            data-name="Rectangle 49614"
            width={50}
            height={50}
            rx={25}
            fill="#c7d7ff"
        />
        <path
            d="M21.097 15.003a.71.71 0 0 0-.273.054l-2.151.858-1.912-.85a.725.725 0 0 0-.7.053.74.74 0 0 0-.329.615v15.121a2.687 2.687 0 0 0 2.683 2.683h5.492a6.2 6.2 0 0 1-.858-1.463h-4.634a1.22 1.22 0 0 1-1.219-1.219V16.86l1.166.516a.746.746 0 0 0 .571.01l2.165-.864 2.165.864a.709.709 0 0 0 .547 0l2.165-.864 2.165.864a.725.725 0 0 0 .571-.01l1.166-.516v6.507a6.382 6.382 0 0 1 1.463.414v-8.048a.729.729 0 0 0-1.029-.668l-1.912.848-2.151-.858a.708.708 0 0 0-.547 0l-2.169.864-2.165-.864a.71.71 0 0 0-.27-.052Zm-1.707 5.854a.732.732 0 1 0 0 1.463h8.292a.732.732 0 1 0 0-1.463Zm9.511 3.414a5.365 5.365 0 1 0 5.365 5.365 5.366 5.366 0 0 0-5.364-5.367Zm-9.511.976a.732.732 0 1 0 0 1.463h3.888a6.265 6.265 0 0 1 .921-1.327.7.7 0 0 0-.419-.136Zm9.511.488a.488.488 0 0 1 .488.488v2.927h2.927a.488.488 0 1 1 0 .976h-2.927v2.927a.488.488 0 1 1-.976 0v-2.931h-2.926a.488.488 0 1 1 0-.976h2.927V26.22a.488.488 0 0 1 .488-.488Zm-9.511 2.439a.732.732 0 1 0 0 1.463h3.17a6.291 6.291 0 0 1 .172-1.466Z"
            fill="#515fe0"
        />
    </svg>
)

