import React from "react"

export const ParentalLeaveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_106764" data-name="Group 106764" transform="translate(-3837 23734)">
      <g id="Rectangle_55651" data-name="Rectangle 55651" transform="translate(3837 -23734)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-family" d="M4.517,4A1.438,1.438,0,1,0,5.955,5.438,1.438,1.438,0,0,0,4.517,4Zm10.81.719a1.438,1.438,0,1,0,0,2.876,4.034,4.034,0,0,0,2.217-1.173.356.356,0,0,0,0-.531A4.034,4.034,0,0,0,15.326,4.719ZM4.317,7.6A2.248,2.248,0,0,0,2,9.753v3.6a.719.719,0,0,0,.719.729v3.945a.719.719,0,1,0,1.438,0V14.067h.719v3.955a.719.719,0,1,0,1.438,0V14.3a.719.719,0,0,0,.7-.047l1.1-.695v4.466a.719.719,0,1,0,1.438,0V15.865h.719v2.157a.719.719,0,1,0,1.438,0V13.606l.917.617a.719.719,0,0,0,.881.539v3.26a.719.719,0,1,0,1.438,0V14.786h.719v3.236a.719.719,0,1,0,1.438,0v-3.26a.719.719,0,0,0,.892-.8l-.558-3.924a2.173,2.173,0,0,0-4.263,0l-.366,2.57-1.633-1.1a.719.719,0,0,0-.138-.074,1.614,1.614,0,0,0-.865-.247H9.651a1.635,1.635,0,0,0-.6.114.719.719,0,0,0-.211.092l-1.8,1.139V9.753A2.249,2.249,0,0,0,4.716,7.6Zm5.593,0a1.438,1.438,0,1,0,1.438,1.438A1.438,1.438,0,0,0,9.91,7.6Z" transform="translate(3839 -23733)" fill="#8670ff"/>
    </g>
  </svg>
  

)