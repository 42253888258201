import React from "react";
import { ResetPassword } from "./resetPassword";

class ResetPasswordParent extends React.Component {
  render() {
    return <ResetPassword />;
  }
}

export default ResetPasswordParent;
