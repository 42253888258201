import { IconButton } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const BodyDiv = styled('div')((props) => ({
    border: "2px solid white",
    borderRadius: props?.theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 147px)`,
    overflow: "hidden",
    margin: "14px"
}))

export const FilterButton = styled(IconButton)((props) => ({
    border: `1px solid ${props?.theme.palette.border.secondary}`,
    borderRadius: props?.theme.palette.borderRadius,
    padding: "12px"
}))