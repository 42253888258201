import * as React from "react"
const DateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={38}
    data-name="Group 115981"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.012}
        x2={0.994}
        y1={0.055}
        y2={0.916}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#28afea" />
        <stop offset={1} stopColor="#047ed6" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.012}
        x2={0.978}
        y1={0.076}
        y2={0.96}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0077d2" />
        <stop offset={1} stopColor="#0b59a2" />
      </linearGradient>
    </defs>
    <path
      fill="#f1f6ff"
      d="M4.75 0h28.5A4.75 4.75 0 0 1 38 4.75v28.5A4.75 4.75 0 0 1 33.25 38H4.75A4.75 4.75 0 0 1 0 33.25V4.75A4.75 4.75 0 0 1 4.75 0Z"
      data-name="Path 100578"
    />
    <g data-name="icons8-calendar (1)">
      <path
        fill="url(#a)"
        d="M6 26.737V12h18.947v14.737a1.056 1.056 0 0 1-1.053 1.053H7.053A1.056 1.056 0 0 1 6 26.737Z"
        data-name="Path 101014"
        transform="translate(3.526 1.211)"
      />
      <path
        fill="url(#b)"
        d="M24.947 7.053v3.158H6V7.053A1.056 1.056 0 0 1 7.053 6h16.842a1.056 1.056 0 0 1 1.052 1.053Z"
        data-name="Path 101015"
        transform="translate(3.526 4.053)"
      />
      <path
        d="M25.052 13.474a1.579 1.579 0 0 1-1.579-1.579v-1.842h3.158v1.842a1.579 1.579 0 0 1-1.579 1.579Z"
        data-name="Path 101016"
        opacity={0.05}
      />
      <path
        d="M25.052 13.079a1.184 1.184 0 0 1-1.184-1.184v-1.842h2.368v1.842a1.184 1.184 0 0 1-1.184 1.184Z"
        data-name="Path 101017"
        opacity={0.07}
      />
      <path
        d="M12.947 13.474a1.579 1.579 0 0 1-1.579-1.579v-1.842h3.158v1.842a1.579 1.579 0 0 1-1.579 1.579Z"
        data-name="Path 101018"
        opacity={0.05}
      />
      <path
        d="M12.947 13.079a1.184 1.184 0 0 1-1.184-1.184v-1.842h2.368v1.842a1.184 1.184 0 0 1-1.184 1.184Z"
        data-name="Path 101019"
        opacity={0.07}
      />
      <path
        fill="#3ccbf4"
        d="M12.947 12.684a.79.79 0 0 1-.789-.789V9.789A.79.79 0 0 1 12.947 9a.79.79 0 0 1 .789.789v2.106a.79.79 0 0 1-.789.789Z"
        data-name="Path 101020"
      />
      <path
        fill="#fff"
        d="M26.368 17.158v1.579a.263.263 0 0 1-.263.263h-1.579a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Zm-4.211 0v1.579a.263.263 0 0 1-.263.263h-1.578a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Zm-4.211 0v1.579a.263.263 0 0 1-.263.263h-1.578a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Z"
        data-name="Path 101021"
      />
      <path
        fill="#fff"
        d="M26.368 20.842v1.579a.263.263 0 0 1-.263.263h-1.579a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Zm-4.211 0v1.579a.263.263 0 0 1-.263.263h-1.579a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Zm-4.211 0v1.579a.263.263 0 0 1-.263.263h-1.578a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Zm-4.211 0v1.579a.263.263 0 0 1-.263.263h-1.578a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Z"
        data-name="Path 101022"
      />
      <path
        fill="#fff"
        d="M22.157 24.526v1.579a.263.263 0 0 1-.263.263h-1.579a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Zm-4.211 0v1.579a.263.263 0 0 1-.263.263h-1.578a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Zm-4.211 0v1.579a.263.263 0 0 1-.263.263h-1.578a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263h1.579a.263.263 0 0 1 .263.263Z"
        data-name="Path 101023"
      />
      <path
        fill="#3ccbf4"
        d="M25.052 12.684a.79.79 0 0 1-.789-.789V9.789A.79.79 0 0 1 25.052 9a.79.79 0 0 1 .789.789v2.106a.79.79 0 0 1-.789.789Z"
        data-name="Path 101024"
      />
    </g>
  </svg>
)
export default DateIcon
