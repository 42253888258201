export const MilestoneMaster = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 102128">
            <g data-name="Milestone Master">
                <path
                    data-name="Path 96843"
                    d="M16 10.353V5.647h-1.441a3.77 3.77 0 0 0-3.735-3.294H5.608a2.824 2.824 0 1 0 0 .941h5.216a2.828 2.828 0 0 1 2.784 2.353h-2.314v1.882H5.647a3.3 3.3 0 0 0-3.265 2.858 2.824 2.824 0 1 0 3.226 3.259h5.021l-.608.608.666.666 1.745-1.745-1.745-1.745-.666.666.608.608H5.608A2.831 2.831 0 0 0 3.333 10.4a2.356 2.356 0 0 1 2.314-1.929h5.647v1.882ZM3.294 3.294h-.941v-.941h.941Zm9.882 4.235h.941v.941h-.941Zm-9.882 6.118h-.941v-.941h.941Z"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                    transform="translate(7 7)"
                />
            </g>
            <path
                data-name="Rectangle 52494"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
        </g>
    </svg>
)