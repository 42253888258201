import { Badge, Box, Grid, IconButton } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, wrapLabelsInT } from "../../utils";
import { Quotationheading, Quotationpath } from "../../utils/quotations";
import { quotationsStyles } from "./styles";
import { Routes } from "../../router/routes";
import FilterIMG from "../../assets/filter";
import { withNamespaces } from "react-i18next";

const QuotationsList = (props) => {
    const search = useLocation().search;
    const filter = new URLSearchParams(search).get("filter");
    const initial_filter = filter ? [filter] : ["Active", "Interested"];
    const { t } = (props);
    const classes = quotationsStyles();
    const history = useHistory()
    const debounce = UseDebounce();
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [permission, setPermission] = React.useState({})
    const [selectedTeams, setSelectedTeams] = React.useState('')
    const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({ quote_status: [] })
    const [enumValue, setEnumValue] = React.useState({ quote_status: [] })

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getEnum()
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.quote_status])
        setFilterData({ quote_status: result?.quote_status?.filter(i => Boolean(initial_filter.includes(i?.value))) })
        setEnumValue({ quote_status: result?.quote_status })
    }

    // eslint-disable-next-line
    const [quotationList, setQuotationList] = React.useState({
        list: [],
        count: 0,
    })

    React.useEffect(() => {
        if (selectedTeams?.value) {
            getQuotationTableListData(selectedCompany?.value, selectedTeams, 0, limit, "", filterData)
        }
        // eslint-disable-next-line
    }, [selectedTeams])
    //get quoation list
    const getQuotationTableListData = (company_id, team, offset = 0, limit = 10, searchText, filter) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "" })

        const payload = {
            tenantId: `${config.tenantId}`,
            company_id: company_id,
            searchText: searchText,
            offset: offset,
            limit: limit,
            quote_status: filter?.quote_status?.map(i => i.value),
        }
        if (team?.value !== "noteams") {
            payload["team"] = team?.value
        }
        NetworkCall(
            `${config.api_url}/quotation/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let temp_list = response?.data?.list?.map((i) => {
                    return {
                        ...i,
                        value: `${i?.symbol} ${i?.total_amount?.toLocaleString()}`,
                        tax: `${i?.symbol} ${i?.total_tax?.toLocaleString()}`,
                    }
                })
                setQuotationList({
                    list: Array.isArray(temp_list) ? temp_list : [],
                    count: response?.data?.count
                })
                backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((err) => {
                console.log(err)
            })
    }
    //on click rows
    const handleIcon = (type, data) => {
        if (type === "view") {
            history.push(`${Routes?.quotation_view}?id=${data?.id}`)
        }
    }
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getQuotationTableListData(selectedCompany?.value, selectedTeams, 0, 10, e, filterData)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getQuotationTableListData(selectedCompany?.value, selectedTeams, offset, limit, "", filterData)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getQuotationTableListData(selectedCompany?.value, selectedTeams, 0, value, "", filterData)
    }
    //on switch company
    const handleCompanyChange = (company) => {
        setSelectedCompany(company)

    }
    // Function to change property
    const handleTeamsChange = (team) => {
        setSelectedTeams(team)
    }

    const handleFilterChange = (filter) => {
        setFilterData(filter)
        getQuotationTableListData(selectedCompany?.value, selectedTeams, 0, limit, "", filter)
    }

    const render = () => {
        return <>
            {  //loading section
                <div className={classes.root}>
                    {/*sub header*/}
                    <Subheader
                        title={t("Quotations")}
                        hideBackButton
                        select
                        options={companyList}
                        value={selectedCompany}
                        onchange={(value) => {
                            handleCompanyChange(value)
                        }}
                        selectTeamsOptions
                        handleTeamsChange={handleTeamsChange}
                        companyId={selectedCompany?.value}
                        moduleId={moduleId}
                        selectedTeams={selectedTeams}
                    />
                    <Box p={2} className={classes.table} m={2}>
                        <Grid container >
                            <Grid item xs={4} >
                                {/*search field*/}
                                <SearchFilter value={searchText} placeholder={t("Search Quotations")} handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}}/>
                            </Grid>
                            <Grid item xs={8}>
                                <Box display={"flex"} justifyContent={'end'}>
                                    <IconButton onClick={() => setFilterDrawer(!fiterDrawer)}
                                        className={classes.filterButton}>
                                        <Badge variant="dot" color="primary"
                                            invisible={!(filterData?.quote_status?.length > 0)}>
                                            <FilterIMG color="#091b29" />
                                        </Badge>
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        {/*table container*/}
                        <TableWithPagination
                            heading={Quotationheading(t)}
                            rows={quotationList?.list}
                            path={Quotationpath}
                            showpagination={true}
                            count="2"
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            tableType="no-side"
                            handleIcon={handleIcon}
                            onClick={() => console.log("")}
                            dataType={[
                                { type: ["text"], name: "quotation_no" },
                                { type: ["date"], name: "created_at" },
                                { type: ["long_text"], name: "description" },
                                { type: ["text"], name: "purpose" },
                                { type: ["text"], name: "quotation_type" },
                                { type: ["text"], name: "units" },
                                { type: ["text"], name: "value" },
                                { type: ["text"], name: "tax" },
                                { type: ["date"], name: "quotation_expiry_date" },
                                { type: ["text"], name: "owner" },
                                { type: ["q_status"], name: "status" },
                                // { type: ["text"], name: "lead_no" },
                            ]}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={quotationList?.count}
                            page={page}
                            limit={limit}
                            height={`calc(100vh - 300px)`}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                    </Box>
                    <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                        onApply={(value) => handleFilterChange(value)}
                        components={[
                            {
                                component: "select",
                                value: wrapLabelsInT(filterData?.quote_status, t),
                                options: wrapLabelsInT(enumValue?.quote_status, t),
                                isMulti: true,
                                state_name: "quote_status",
                                label: t("Quotation Status"),
                                placeholder: t("Select Quotation Status")
                            },
                        ]} />
                </div>
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};
export default withNamespaces("quotationsList")(QuotationsList)
