import { Stack, Typography } from '@mui/material'
import React from 'react'
import NormalIcon from '../utils/normalIcon'
import { CollapesInfoCardStyle } from './style'
import ClearedIcon from '../utils/clearedIcon'
import UnclearedIcon from '../utils/unClearedIcon'

export const CollapesInfoCard = ({ data = {} }) => {
    const classes = CollapesInfoCardStyle()
    const icon = {
        "normal": <NormalIcon />,
        "cleared": <ClearedIcon />,
        "uncleared": <UnclearedIcon />
    }
    return (
        <>
            <Stack direction={"row"} alignItems={"center"} className={classes.box}>
                {icon?.[data?.status]}
                <Stack marginInlineStart={"16px"}>
                    <Typography className={classes.title} noWrap>{data?.title}</Typography>
                    <Typography className={classes.content} noWrap>{data?.content}</Typography>
                </Stack>
            </Stack>
        </>
    )
}