import { ActivePropertyIcon } from "../../assets";
import { CommunityIcon } from "../../assets/communityIcon";
import { ResourcesIcon } from "../../assets/resources";
import { SaleIcon } from "../../assets/sale";
import { StayIcon } from "../../assets/stay";
import { TenantIcon } from "../../assets/tenant";

export const propertyDetailsFunction = (data, t = () => false) => {
  let propertyDetails = [
    {
      count: data?.company_count ?? 0,
      image: <ActivePropertyIcon />,
      sub: t("Properties"),
    },
    {
      count: data?.property_count ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: t("Blocks"),
      image_type: true,
    },
    {
      count: data?.floor_count ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: t("Floors"),
      image_type: true,
    },
  ];
  let unitDetails = [
    {
      count: data?.unit_count ?? 0,
      image: "/images/propertdashboard/activeunits.png",
      sub: t("Units"),
      image_type: true,
    },
    {
      count: data?.vacant_count ?? 0,
      image: "/images/propertdashboard/vacant.png",
      sub: t("Vacant"),
      image_type: true,
    },
    {
      count: data?.occupied_count ?? 0,
      image: "/images/propertdashboard/occupied.png",
      sub: t("Occupied"),
      image_type: true,
    },
  ];
  let residentalDetails = [
    {
      count: data?.agreement_unit_user ?? 0,
      image: <TenantIcon />,
      sub: t("Residents"),
    },
    {
      count: data?.owner_count ?? 0,
      image: "/images/propertdashboard/occupied.png",
      sub: t("Owners"),
      image_type: true,
    },
    {
      count: data?.resource_count ?? 0,
      image: <ResourcesIcon />,
      sub: t("Resources"),
    },
  ];
  let soldDetails = [
    {
      count: data?.construction_count ?? 0,
      image: "/images/propertdashboard/constraction.png",
      sub: t("Construction"),
      image_type: true,
    },
    {
      count: data?.ready_unit ?? 0,
      image: "/images/propertdashboard/activeunits.png",
      sub: t("Ready Units"),
      image_type: true,
    },
    {
      count: data?.sold_unit ?? 0,
      image: "/images/propertdashboard/soldunit.png",
      sub: t("Sold Units"),
      image_type: true,
    },

  ];
  let areaDetails = [
    {
      area: t("Total Area"),
      data: `${data?.total_area ?? 0} ${data?.uom ?? ""}`,
      is_count: true,
    },
    {
      area: t("Carpet Area"),
      data: `${data?.carpet_area ?? 0} ${data?.uom ?? ''}`,
      is_count: true,
    },
  ];
  let unitStatus = [
    // {
    //   count: data?.reserved_units ?? 0,
    //   image: <BookingComponent />,
    //   sub: "Reserved",
    // },
    {
      count: data?.community ?? 0,
      image: <CommunityIcon />,
      sub: t("Community"),
    },
    {
      count: data?.price_missing ?? 0,
      image: "/images/propertdashboard/missiongprice.png",
      sub: t("Missing Pricing"),
      image_type: true,
    },
    {
      count: data?.lease_unit ?? 0,
      image: "/images/propertdashboard/resident.png",
      sub: t("Lease"),
      image_type: true,
    },
    {
      count: data?.sale_unit ?? 0,
      image: <SaleIcon />,
      sub: t("Sale"),
    },
    {
      count: data?.manage_unit ?? 0,
      image: "/images/propertdashboard/owner.png",
      sub: t("Manage"),
      image_type: true,
    },
    {
      count: data?.stay_unit ?? 0,
      image: <StayIcon />,
      sub: t("Stay"),
    },
  ];
  let propertyType = data?.property_type?.map((x) => {
    return {
      name: x?.group_name ?? "",
      count: parseInt(x?.count) ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  // let unitstatusGraph = data?.unit_status?.map((x) => {
  //   return {
  //     name: x?.status ?? "",
  //     count: parseInt(x?.count) ?? "",
  //     fill: `#${Math.random().toString(16).substring(2, 8)}`,
  //   };
  // });
  let unitstatusGraph = data?.unit_type ?? {}
  let unitType = data?.unit_status?.map((x) => {
    return {
      name: x?.type ?? "",
      count: parseInt(x?.count) ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let unit_catagory = data?.category_type ?? {};
  let residential_vs_commerical = [
    {
      name: t("Residential"),
      count: parseInt(data?.residential_vs_commerical?.residential_units) ?? 0,
      fill: `#F3E137`,
      // preffix: data?.uom,
    },
    {
      name: t("Commerical"),
      count: parseInt(data?.residential_vs_commerical?.commerical_units) ?? 0,
      fill: `#5AC782`,
      // preffix: data?.uom,
    },
  ];
  let vacant_unit_by_property = {
    labels: data?.property_unit_vacant?.map((x) => x?.name) ?? [],
    datasets: [
      {
        label: t("Vacant Units"),
        backgroundColor: "#58D0E0",
        barPercentage: 0.5,
        barThickness: 20,
        maxBarThickness: 20,
        data: data?.property_unit_vacant?.map((x) => x?.units),
      },
    ],
  };
  let property_occupancy_trends = data?.property_occupancy_trends;
  let residential_vs_commerical_total =
    parseInt(data?.residential_vs_commerical?.residential_units) +
    parseInt(data?.residential_vs_commerical?.commerical_units);

  let property_unit_occupancy = data?.property_unit_occupancy?.map((x) => { return { ...x, vacant: data?.property_unit_vacant?.filter((val) => { return x.id === val.id })?.[0]?.units } }) ?? {};

  const final = {
    propertyDetails,
    unitDetails,
    residentalDetails,
    soldDetails,
    areaDetails,
    unitStatus,
    propertyType,
    unitstatusGraph,
    unitType,
    unit_catagory,
    residential_vs_commerical,
    property_unit_occupancy,
    vacant_unit_by_property,
    residential_vs_commerical_total,
    property_occupancy_trends,
  };
  return final;
};

export const areaFunction = (data, t = () => false) => {
  let areaDetails = [
    {
      name: t("Residential"),
      count: data?.residential_unit ?? 0,
      fill: `#F3E137`,
      preffix: data?.uom,
    },
    {
      name: t("Commerical"),
      count: data?.commercial_unit ?? 0,
      fill: `#5AC782`,
      preffix: data?.uom,
    },
  ];
  let totalArea = data?.total ?? 0;
  const final = {
    areaDetails,
    totalArea,
  };
  return final;
};
