import * as React from "react"
const AttachmentIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18.564} height={19} {...props}>
        <g data-name="Request Ticket">
            <path fill="none" d="M.5.25h18v18H.5z" data-name="Rectangle 53523" />
            <path
                fill={props?.color}
                stroke="rgba(0,0,0,0)"
                d="M12.867.5a5.187 5.187 0 0 0-3.679 1.518L.764 10.442a.902.902 0 1 0 1.275 1.275l8.424-8.424a3.4 3.4 0 0 1 4.809 4.809L7.155 16.22a1.648 1.648 0 1 1-2.331-2.331l6.464-6.464a.9.9 0 1 0-1.276-1.27l-6.464 6.46a3.45 3.45 0 1 0 4.88 4.88l8.119-8.119A5.2 5.2 0 0 0 12.868.5Z"
            />
        </g>
    </svg>
)
export default AttachmentIcon
