import * as React from "react"

const LocaitonIMG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <g data-name="Group 96254">
            <path
                data-name="icons8-location (4)"
                d="M10 1.5a7 7 0 0 0-5.321 11.542s2.978 3.4 4.038 4.41a1.859 1.859 0 0 0 2.553 0c1.208-1.153 4.04-4.411 4.04-4.411A6.995 6.995 0 0 0 10 1.5Zm0 1.235a5.759 5.759 0 0 1 4.381 9.5c-.006.006-2.9 3.319-3.957 4.325a.6.6 0 0 1-.848 0c-.881-.841-3.948-4.315-3.957-4.325A5.76 5.76 0 0 1 10 2.735Zm0 3.294a2.452 2.452 0 0 0-1.851.787 2.6 2.6 0 0 0 0 3.368 2.572 2.572 0 0 0 3.7 0 2.6 2.6 0 0 0 0-3.368A2.452 2.452 0 0 0 10 6.029Zm0 1.235a1.138 1.138 0 0 1 .928.372 1.368 1.368 0 0 1 0 1.728 1.138 1.138 0 0 1-.928.372 1.138 1.138 0 0 1-.928-.372 1.368 1.368 0 0 1 0-1.728A1.138 1.138 0 0 1 10 7.265Z"
                fill={props?.color??"#ced3dd"}
                stroke="#ced3dd"
                strokeWidth={0.5}
            />
        </g>
    </svg>
)

export default LocaitonIMG
