export const FinanceAccounting = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 98444">
      <g fill={props?.fill ?? "#c1c5cb"}>
        <path
          data-name="Path 94949"
          d="M15.5 11.988a2.512 2.512 0 1 0 2.512 2.512 2.514 2.514 0 0 0-2.512-2.512Zm.747 3.348a.9.9 0 0 1-.505.242v.325h-.413v-.31a1.96 1.96 0 0 1-.42-.067 1.275 1.275 0 0 1-.332-.138l.223-.5a1.415 1.415 0 0 0 .328.136 1.282 1.282 0 0 0 .349.051.539.539 0 0 0 .205-.028.089.089 0 0 0 .062-.083q0-.064-.081-.1a1.738 1.738 0 0 0-.265-.07 3.012 3.012 0 0 1-.4-.107.674.674 0 0 1-.275-.187.508.508 0 0 1-.117-.353.6.6 0 0 1 .186-.442.921.921 0 0 1 .535-.234v-.319h.413v.313a1.47 1.47 0 0 1 .595.17l-.208.5a1.271 1.271 0 0 0-.571-.152q-.267 0-.267.129 0 .061.079.092a1.679 1.679 0 0 0 .261.066 2.681 2.681 0 0 1 .4.1.688.688 0 0 1 .28.186.5.5 0 0 1 .119.351.6.6 0 0 1-.177.429Z"
        />
        <path
          data-name="Path 94950"
          d="M19.702 16.811a4.761 4.761 0 0 0 .4-.975h1.232v-2.673h-1.226a4.764 4.764 0 0 0-.4-.977l.87-.87-1.894-1.891-.87.87a4.763 4.763 0 0 0-.977-.405V8.66h-2.674v1.23a4.763 4.763 0 0 0-.977.405l-.87-.87-1.891 1.891.87.87a4.765 4.765 0 0 0-.4.977H9.66v2.674h1.232a4.762 4.762 0 0 0 .4.975l-.872.872 1.891 1.891.873-.873a4.764 4.764 0 0 0 .974.4v1.234h2.674v-1.231a4.766 4.766 0 0 0 .974-.4l.873.873 1.895-1.895Zm-4.2 1.079a3.39 3.39 0 1 1 3.39-3.39 3.394 3.394 0 0 1-3.392 3.39Z"
        />
        <path data-name="Path 94951" d="M15.061 7h.879v.879h-.879Z" />
        <path data-name="Path 94952" d="M15.061 21.121h.879V22h-.879Z" />
        <path data-name="Path 94953" d="M22.121 14.061H23v.879h-.879Z" />
        <path data-name="Path 94954" d="M8 14.061h.879v.879H8Z" />
        <path
          data-name="Path 94955"
          d="m20.163 9.216.914-.914.622.621-.914.915Z"
        />
        <path
          data-name="Path 94956"
          d="m9.298 20.077.914-.914.622.621-.914.915Z"
        />
        <path
          data-name="Path 94957"
          d="m20.161 19.786.622-.622.914.915-.622.621Z"
        />
        <path
          data-name="Path 94958"
          d="m9.3 8.921.622-.622.914.915-.622.621Z"
        />
      </g>
      <path data-name="Rectangle 52140" fill="none" d="M0 0h30v30H0z" />
    </g>
  </svg>
)
