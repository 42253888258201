import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { AmenityDetailsStyle } from './style'
import { ProfileCard } from '../../alreadyPaid/components/profileCard'
import { stringAvatar } from '../../../utils'

const profiledata = {
    created_by: {
        image_url:"https://img.freepik.com/premium-photo/young-handsome-man-with-beard-isolated-keeping-arms-crossed-frontal-position_1368-132662.jpg?w=2000",
        first_name:"Doris Hughes",
        mobile_no_country_code:"+91",
        mobile_no:"9876543210",
        email_id:"Dorishughes@mail.com"
    }
}

const details = [
    {
        name:"Property Name",
        content:"Property location",
        url:"https://www.thinkconfluence.com/assets/images/NoCrop_2000x2000/1422c3f7f9e342f5adccde8b04e297b8.jpg"
    },
    {
        name:"Unit Name Here",
        content:"Unit Id here",
        url:"https://www.thinkconfluence.com/assets/images/NoCrop_2000x2000/1422c3f7f9e342f5adccde8b04e297b8.jpg"
    }
]
export const AmenityDetails = () => {
    const classes = AmenityDetailsStyle()
    return (
        <Box p={2} ml={2} mt={1} className={classes.box}>
            <Stack spacing={2}>
                <DetailsCard title='PROPERTY DETAILS' data={details[0]}/>
                <DetailsCard title='UNIT DETAILS' data={details[1]} />
                <Stack spacing={1}>
                    <Typography className={classes.title}>booked by</Typography>
                    <ProfileCard data={profiledata} />
                </Stack>
            </Stack>
        </Box>
    )
}

const DetailsCard = ({title="",data={}}) => {
    const classes = AmenityDetailsStyle()
    return (
        <Stack spacing={1}>
            <Typography className={classes.title}>{title}</Typography>
            <Stack direction={"row"} spacing={1}>
                <Avatar className={classes.avatar} src={data.url} {...stringAvatar(data?.name)}></Avatar>
                <Stack>
                    <Typography className={classes.name}>{data.name}</Typography>
                    <Typography className={classes.content}>{data.content}</Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}