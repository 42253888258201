import * as React from "react"
const InvoiceInactive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 115382" transform="translate(-5 -8)">
      <path
        fill="#ced3dd"
        d="M26.209 10.116A1.116 1.116 0 0 0 25.093 9H6.116A1.116 1.116 0 0 0 5 10.116v14.512a1.116 1.116 0 0 0 1.116 1.116h18.977a1.116 1.116 0 0 0 1.116-1.116Z"
        data-name="Path 100822"
      />
      <path
        fill="#98a0ac"
        d="M23.14 14.58H8.07a.279.279 0 0 1-.279-.274v-1.121a.279.279 0 0 1 .279-.279h15.07a.279.279 0 0 1 .279.279v1.121a.279.279 0 0 1-.279.274Z"
        data-name="Path 100823"
      />
      <path
        fill="#98a0ac"
        d="M23.144 17.371h-1.121a.279.279 0 0 1-.279-.279v-.558a.279.279 0 0 1 .279-.279h1.121a.279.279 0 0 1 .279.279v.558a.279.279 0 0 1-.279.279Z"
        data-name="Path 100824"
      />
      <path
        fill="#98a0ac"
        d="M19.233 17.371H8.07a.279.279 0 0 1-.279-.279v-.558a.279.279 0 0 1 .279-.279h11.163a.279.279 0 0 1 .279.279v.558a.279.279 0 0 1-.279.279Z"
        data-name="Path 100825"
      />
      <path
        fill="#057093"
        d="M23.144 19.603h-1.121a.279.279 0 0 1-.279-.279v-.558a.279.279 0 0 1 .279-.279h1.121a.279.279 0 0 1 .279.279v.558a.279.279 0 0 1-.279.279Z"
        data-name="Path 100826"
      />
      <path
        fill="#98a0ac"
        d="M18.117 19.603H8.07a.279.279 0 0 1-.279-.279v-.558a.279.279 0 0 1 .279-.279h10.047a.279.279 0 0 1 .279.279v.558a.279.279 0 0 1-.279.279Z"
        data-name="Path 100827"
      />
      <path
        fill="#057093"
        d="M23.144 20.721h-1.121a.279.279 0 0 1-.279-.279v-.558a.279.279 0 0 1 .279-.279h1.121a.279.279 0 0 1 .279.279v.558a.279.279 0 0 1-.279.279Z"
        data-name="Path 100828"
      />
      <path
        fill="#98a0ac"
        d="M19.233 21.836H8.07a.279.279 0 0 1-.279-.279v-.558a.279.279 0 0 1 .279-.279h11.163a.279.279 0 0 1 .279.279v.558a.279.279 0 0 1-.279.279Z"
        data-name="Path 100829"
      />
      <circle
        cx={6.14}
        cy={6.14}
        r={6.14}
        fill="#e9ecef"
        data-name="Ellipse 130020"
        transform="translate(16.721 18.488)"
      />
      <circle
        cx={4.912}
        cy={4.912}
        r={4.912}
        fill="#ced4da"
        data-name="Ellipse 130021"
        transform="translate(17.949 19.716)"
      />
      <path
        fill="#98a0ac"
        d="M21.49 26.89v-.915a2 2 0 0 0 1.061.305 1 1 0 0 0 .663-.2.68.68 0 0 0 .239-.548q0-.723-1.02-.722a6.378 6.378 0 0 0-.8.061v-2.716h2.691v.875h-1.778v.973q.213-.02.4-.02a1.659 1.659 0 0 1 1.173.393 1.374 1.374 0 0 1 .424 1.058 1.56 1.56 0 0 1-.505 1.2 1.94 1.94 0 0 1-1.37.466 2.93 2.93 0 0 1-1.178-.21Z"
        data-name="Path 100831"
      />
    </g>
  </svg>
)
export default InvoiceInactive
