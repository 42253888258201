import moment from "moment"
import { LocalStorageKeys, uniqueArr } from "../../utils"
import { getDateValue } from "../../screens/propertyFinder4/utils"
import { boardingPropertyType } from "../../screens/residentOnboarding/utils/residentOnboard"

export const returnLeadDatas = (company, dataNew) => {
  let data = {
    custom_date: company?.billing_day,
    bill_cycle_method: company?.billing_cycle,
    bill_cycle_date:
      company?.billing_cycle_date ===
        "As per the Agreement Date"
        ? company?.billing_cycle_date
        : company?.billing_day,
    payment_period:
      dataNew?.revenue_type === "sale"
        ? ""
        : {
          value: company?.payment_period,
          label: company?.payment_period,
        },
    required_area: {
      min: "",
      max: "",
      select: {
        value: company?.uom,
        label: company?.uom,
      },
    },
    budget: {
      min: "",
      max: "",
      select: {
        value: company?.currencyByID?.id,
        label: company?.currencyByID?.symbol,
      },
    },
    initial_bill_cycle_date: company?.billing_cycle_date ===
      "As per the Agreement Date"
      ? company?.billing_cycle_date
      : company?.billing_day,
    company: company?.id,
    grace_peroid: company?.grace_period !== null ? JSON.stringify(company.grace_period) : null,
    oppertunity_subject: `${moment(new Date()).format("DD-MM-YYYY HH:mm")} ${dataNew?.name}`
    
  }

  return data
}

export const returnExisitContact = (val) => {
  let data = {
    contact: val,
    is_exist:true,
    disableForm: true,
    name: `${val?.first_name ?? ''} ${val?.last_name ?? ''}`,
    first_name: `${val?.first_name ?? ''}`,
    last_name: ` ${val?.last_name ?? ''}`,
    email_id: val?.email_id,
    mobile: {
      mobile: val?.mobile_no ?? null,
      mobile_code: val?.mobile_no_country_code ?? null,
    },
    alter_mobile: {
      mobile: val?.alternative_mobile ?? null,
      mobile_code: val?.alternative_mobile_country_code ?? null,
    },
    telephone: {
      mobile: val?.phone ?? null,
      mobile_code: val?.phone_code ?? null,
    },
    fax: {
      mobile: val?.fax_no ?? null,
      mobile_code: val?.phone_code ?? null,
    },
    image_url:
      val?.image_url?.length > 0
        ? {
          src: val?.image_url ?? null,
        }
        : null,
    address: {
      street_1: val?.street_1 ?? null,
      street_2: val?.street_2 ?? null,
      street_3: val?.street_3 ?? null,
      city: val?.city ?? null,
      district: val?.district ?? null,
      state: val?.state ?? null,

      zipcode: val?.zipcode ?? null,
    },
  }

  return data
}

export const returnremoveExisitContact = (val) => {
  let data = {
    disableForm: false,
    name: "",
    first_name: "",
    last_name: "",
    email_id: "",
    mobile: null,
    alter_mobile: null,
    telephone: null,
    fax: null,
    image_url: null,
    existing: false,
    address: null,
    contact:null
    
  }

  return data
}


export const returnPayload = ({dataNew,is_edit,is_convert=false,proxy_id}) => {
  
  let payload = {
    is_convert,
    proxy_id:proxy_id??undefined
  };
  let propspective =
  {
    "first_name": dataNew?.name,
    "mobile_no_country_code": dataNew?.mobile?.mobile_code,
    "mobile_no": dataNew?.mobile?.mobile,
    "alternative_mobile": dataNew?.alter_mobile?.mobile,
    "alternative_mobile_country_code": dataNew?.alter_mobile?.mobile_code,
    "email_id": dataNew?.email_id,
    "fax_no": dataNew?.fax?.mobile,
    "door_no": dataNew?.address?.doorNo,
    "street_1": dataNew?.address?.addressLineOne,
    "street_2": dataNew?.address?.addressLineTwo,
    "landmark": dataNew?.address?.landmark,
    "country": dataNew?.address?.country,
    "longitude": dataNew?.address?.longitude,
    "latitude": dataNew?.address?.latitude,
    "area": dataNew?.address?.area,
    "state": dataNew?.address?.state,
    "zipcode": dataNew?.address?.pincode,
    "city": dataNew?.address?.city,
    "facebook": dataNew?.facebook,
    "linkedin": dataNew?.linkedIn,
    "twitter": dataNew?.twitter,
    "image_url": dataNew?.image_url?.src,
    "client": localStorage.getItem(LocalStorageKeys.clinetID),
    "phone": dataNew?.telephone?.mobile,
    "phone_code": dataNew?.telephone?.mobile_code,
    "fax_code": dataNew?.fax?.mobile_code,
    "company_id": dataNew?.company,
    "is_active": true
  }
  let lead={
    "id":dataNew?.lead_id?.length > 0 ? dataNew?.lead_id :  undefined,
    "subject": dataNew?.oppertunity_subject,
    "unit_usage": dataNew?.purpose?.value?.length > 0 ? dataNew?.purpose?.value : null,
    "lead_source": dataNew?.source?.value,
    "urgent": dataNew?.priority,
    "lease_start_date": dataNew?.lease_start_date !== null ? dataNew?.lease_start_date : null,
    "lease_end_date": dataNew?.lease_end_date !== null ? dataNew?.lease_end_date : null,
    // "budget_range": [dataNew?.budget?.min?.length > 0 ? dataNew?.budget?.min : null, dataNew?.budget?.max?.length > 0 ? dataNew?.budget?.max : null],
    "currency_id": dataNew?.budget?.select?.value,
    // "required_area": [dataNew?.required_area?.min?.length > 0 ? dataNew?.required_area?.min : null, dataNew?.required_area?.max?.length > 0 ? dataNew?.required_area?.max : null],
    "lease_period": dataNew?.duration?.select ?? null,
    "value": parseInt(dataNew?.duration?.value),
    "grace_period_type": "Days",
    "grace_period_value": dataNew?.grace_peroid?.length > 0 ? dataNew?.grace_peroid : null,
    "value_type": dataNew?.required_area?.select?.value,
    "lead_owner": localStorage.getItem(LocalStorageKeys.profileID),
    "company_id": dataNew?.company,
    "revenue_type": dataNew?.revenue_type?.value,
    "client": localStorage.getItem(LocalStorageKeys.clinetID),
    "billing_start_date": dataNew?.billing_start_date?.length !== 0 ? new Date(dataNew?.billing_start_date) : null,
    "revenue_based_lease": dataNew?.revenue_base_lease,
    "auto_renewal_escalation": dataNew?.renewal_based_on?.value ?? null,
    "payment_mode": dataNew?.payment_option?.value,
    "turnover_type": dataNew?.turn_over_rent_type?.value,
    "bill_generation": dataNew?.bill_cycle_method,
    "billing_cycle_date": dataNew?.bill_cycle_date === "As per the Agreement Date" ? dataNew?.bill_cycle_date : "Custom Date",
    "apply_grace_period": dataNew?.apply_grace_peroid?.length > 0 ? dataNew?.apply_grace_peroid : null,
    "include_market": dataNew?.market_places,
    "increment_escalation": dataNew?.esclation_percentage?.length > 0 ? dataNew?.esclation_percentage : null,
    "billing_day": dataNew?.bill_cycle_date === "As per the Agreement Date" ? null : dataNew?.bill_cycle_date,
    "payment_period": dataNew?.payment_period?.value ?? dataNew?.payment_period,
    "milestone_template": dataNew?.delivery_timestone_template?.value ?? null,
    "contract_start_date": dataNew?.contract_start_date?.length !== 0 ? dataNew?.contract_start_date : null,
    "occpation_date": dataNew?.earlist_occupation_date?.length !== 0 ? dataNew?.earlist_occupation_date : null,
    "is_active": true,
    "status": 2,
    // "contact_account": selectedAccount?.normal?.id ?? undefined,
    // "broker_account":selectedAccount?.broker?.id ?? undefined,
    "auto_renewal": dataNew?.auto_renewal ?? false,
    "proxy_lead_id": dataNew?.proxy_lead_id?.length > 0 ? dataNew?.proxy_lead_id : null,
    // "is_agreement_lock":dataNew?.agreement_lock
  
  }
  let total_properties= uniqueArr([...dataNew?.property , ...dataNew?.properties]);

  if(!dataNew?.lead_id) payload["prospective"] = propspective
  payload["lead"] = lead
  payload["lead_units"] = dataNew?.lead_id ? total_properties?.filter((x)=>!x?.lead_unit_id) : total_properties



  return payload;
}

export const returnOppertunityEditPayload = (data) => {

  let units=[]
  let properties=[]
  if((data?.lead_units?.length > 0 && data?.lead_units?.filter((val)=> val?.unit?.owner_id)?.length > 0)){
       units = data?.lead_units?.filter((val)=> val?.unit?.owner_id)?.map((x) => {
          const unit = x?.unit
          return {
              "label": unit?.unit_name,
              "value": unit?.id,
              "url": unit?.logo,
              "label1": unit?.unit_no,
              "unit_id": unit?.id,
              "id": unit?.id,
              "unit_no": unit?.unit_no,
              "unit_name": unit?.unit_name,
              "logo": unit?.logo,
              "property_id": unit?.property?.property_id,
              "property_name": unit?.property?.property_name,
              "status": "Vacant",
              "is_active": true,
              "lead_unit_id":x?.id,
              "contact_account":unit?.contact_account
  
          }
      })
  }
   if((data?.lead_units?.length > 0 && data?.lead_units?.filter((val)=> !val?.unit?.owner_id)?.length > 0)){
      properties = data?.lead_units?.filter((val)=> !val?.unit?.owner_id)?.map((x) => {
          const unit = x?.unit
          return {
              "label": unit?.unit_name,
              "value": unit?.id,
              "url": unit?.logo,
              "label1": unit?.unit_no,
              "unit_id": unit?.id,
              "id": unit?.id,
              "unit_no": unit?.unit_no,
              "unit_name": unit?.unit_name,
              "logo": unit?.logo,
              "property_id": unit?.property?.property_id,
              "property_name": unit?.property?.property_name,
              "status": "Vacant",
              "is_active": true,
              "lead_unit_id":x?.id,
              "property":unit?.property

  
          }
      })
  }



  let result = {
    name: data?.contact?.first_name,
    image_url: data?.contact?.get_assets_url_id?.length > 0 ? {
      src: data?.contact?.get_assets_url_id,
    } : "",
    mobile: {
      mobile: data?.contact?.mobile_no,
      mobile_code: data?.contact?.mobile_no_country_code,
    },
    alter_mobile: {
      mobile: data?.contact?.alternative_mobile ?? "",
      mobile_code: data?.contact?.alternative_mobile_country_code,
    },
    email_id: data?.contact?.email ?? "",
    telephone: {
      mobile: data?.contact?.phone ?? "",
      mobile_code: data?.contact?.phone_code,
    },
    fax: {
      mobile: data?.contact?.fax_no ?? "",
      mobile_code: data?.contact?.fax_code,
    },
    address: {
      doorNo: data?.contact?.door_no,
      addressLineOne: data?.contact?.street_1,
      addressLineTwo: data?.contact?.street_2,
      landmark: data?.contact?.landmark,
      country: data?.contact?.country,
      area: data?.contact?.area,
      state: data?.contact?.state,
      pincode: data?.contact?.zipcode,
      city: data?.contact?.city,
      latitude: data?.contact?.latitude,
      longitude: data?.contact?.longitude,
    },
    facebook: data?.contact?.facebook,
    twitter: data?.contact?.twitter,
    linkedIn: data?.contact?.linkedin,
    lead_subject: data?.subject,
    duration: {
      value: data?.value,
      select: data?.lease_period,
    },
    lease_start_date: new Date(data?.lease_start_date) ?? null,
    lease_end_date: new Date(data?.lease_end_date) ?? null,
    payment_period: {
      value: data?.payment_period,
      label: data?.payment_period
    },
    payment_option: {
      label: data?.payment_mode,
      value: data?.payment_mode,
    },
    market_places: data?.include_market,
    bill_cycle_method: data?.bill_generation,
    bill_cycle_date: data?.billing_cycle_date === "As per the Agreement Date" ? data?.billing_cycle_date : data?.billing_day,
    initial_bill_cycle_date:data?.billing_cycle_date === "As per the Agreement Date" ? data?.billing_cycle_date : data?.billing_day,
    oppertunity_subject: data?.subject,
    source: {
      value: data?.lead_source,
      label: data?.lead_source
    },
    revenue_base_lease: data?.revenue_based_lease,
    turn_over_rent_type: data?.turnover_type
      ? {
        label: data?.turnover_type,
        value: data?.turnover_type,
      }
      : "",
    required_area: {
      min: data?.required_area?.[0],
      max: data?.required_area?.[1],
      select: {
        label: data?.company?.uom,
        value: data?.company?.uom,
      },
    },
    budget: {
      min: data?.budget_range?.[0],
      max: data?.budget_range?.[1],
      select: {
        label: data?.currency?.symbol,
        value: data?.currency?.id,
      },
    },
    esclation_percentage: data?.increment_escalation === null ? null : JSON.stringify(data?.increment_escalation),
    auto_renewal: data?.auto_renewal,
    delivery_timestone_template: data?.milestone_template ?? null,
    renewal_based_on: {
      label: data?.auto_renewal_escalation,
      value: data?.auto_renewal_escalation,
    },
    purpose: {
      value: data?.unit_usage,
      label: data?.unit_usage
    },
    revenue_type: {
      value: data?.revenue_type,
      label: data?.revenue_type,
    },
    company: data?.company?.id,
    contact_id: data?.contact?.id,
    lead_id: data?.id,
    lead_no: data?.lead_no,
    lead_source: data?.lead_source,
    lead_name: data?.lead_name,
    disableForm: false,
    apply_grace_peroid: data?.apply_grace_period,
    grace_peroid: data?.grace_period_value !== null ? JSON.stringify(data?.grace_period_value) : null,
    lease_date:
      data?.lease_start_date?.length > 0
        ? {
          input: data?.value,
          period: data?.lease_period,
          date: new Date(data?.lease_start_date),
        }
        : "",
    earlist_occupation_date: new Date(data?.occpation_date),
    contract_start_date: new Date(data?.contract_start_date),
    contact_account: data?.contact_account,
    selectedUnit: [],
    lead_owner: null,
    edit_owner: null,
    property_id: data?.unit?.[0]?.property_id ?? null,
    changeUnit: [],
    mileStoneName: data?.milestone_template?.label ?? data?.payment_period,
    mailstoneList: [],
    custom_date: data?.billing_day ?? null,
    isEdit: true,
    billing_start_date: data?.grace_peroid !== null ? data?.billing_start_date : null,
    payment_period_value: {
      value: data?.payment_period,
      label: data?.payment_period
    },
    proxy_detail:data?.proxy_detail,  
    show_property:data?.lead_units?.length > 0 ? true : false,
    initial_units:units,
    deleted_property:[],
    unit_type:units?.length > 0 ? 2 : 1,
    property_against: properties?.length > 0 ? properties?.[0]?.property : null,
    properties: properties,
    owner: units?.length > 0 ? units?.[0]?.contact_account : null,
    property: units,
    priority:data?.urgent,
    error: {
      name: "",
      image_url: "",
      mobile: "",
      alter_mobile: "",
      email_id: "",
      telephone: "",
      fax: "",
      address: "",
      facebook: "",
      twitter: "",
      linkedIn: "",
      duration: "",
      lease_start_date: "",
      lease_end_date: "",
      billing_start_date: "",
      payment_period: "",
      lead_details: "",
      payment_option: "",
      market_places: "",
      bill_cycle_method: "",
      bill_cycle_date: "",
      oppertunity_subject: "",
      source: "",
      priority: "",
      revenue_base_lease: "",
      turn_over_rent_type: "",
      required_area: "",
      budget: "",
      esclation_percentage: "",
      earlist_occupation_date: "",
      auto_renewal: "",
      contract_start_date: "",
      delivery_timestone_template: "",
      renewal_based_on: "",
      custom_date: null,
      purpose: "",
      revenue_type: "",
      company: "",
      contact_id: "",
      lead_id: "",
      disableForm: false,
      lease_date: "",
    },
  };
  return result
}

export const returnConvertOppertunityEditPayload = (data, company, res, proxy_id,is_converted) => {


  let units=[]
  let properties=[]

  let unit_ids = is_converted?data?.units : data?.unit?.map((x) => {
    return {
      id: x?.unitByID?.id ?? "",
      logo: x?.unitByID?.logo ?? "",
      name: x?.unitByID?.unit_name ?? "",
      property_id: x?.unitByID?.propertyByID?.id ?? "",
      property_name: x?.unitByID?.propertyByID?.name ?? "",
      unit_no: x?.unitByID?.unit_no ?? "",
      is_active: true
    };
  })

  if(data?.proxy_lead_units?.length > 0){
       units = data?.proxy_lead_units?.map((unit) => {

        return {
            "label": unit?.unitByID?.unit_name,
            "value": unit?.unitByID?.id,
            "url": unit?.unitByID?.logo,
            "label1": unit?.unitByID?.unit_no,
            "unit_id": unit?.unitByID?.id,
            "id": unit?.unitByID?.id,
            "unit_no": unit?.unitByID?.unit_no,
            "unit_name": unit?.unitByID?.unit_name,
            "logo": unit?.unitByID?.logo,
            "property_id": unit?.unitByID?.propertyByID?.id,
            "property_name": unit?.unitByID?.propertyByID?.name,
            "status": "Vacant",
            "is_active": true,
            // "lead_unit_id":x?.id,
            "property":unit?.unitByID?.property
  
          }
      })
  }
   if(data?.proxy_lead_units?.length > 0){
      properties = data?.proxy_lead_units?.map((unit) => {
          return {
              "label": unit?.unitByID?.unit_name,
              "value": unit?.unitByID?.id,
              "url": unit?.unitByID?.logo,
              "label1": unit?.unitByID?.unit_no,
              "unit_id": unit?.unitByID?.id,
              "id": unit?.unitByID?.id,
              "unit_no": unit?.unitByID?.unit_no,
              "unit_name": unit?.unitByID?.unit_name,
              "logo": unit?.unitByID?.logo,
              "property_id": unit?.unitByID?.propertyByID?.id,
              "property_name": unit?.unitByID?.propertyByID?.name,
              "status": "Vacant",
              "is_active": true,
              // "lead_unit_id":x?.id,
              "property":unit?.unitByID?.property

  
          }
      })
  }

  let end_date = getDateValue(
    {
      date: data?.lease_start_date ?? "",
      input: data?.value ?? "",
      period: data?.lease_period ?? "",
    },
    true,
    0,
    true
  );

  const purpose = data?.purpose === "Mixed" ? "Commercial" : data?.purpose

  let result = {
    properties: properties,
    account:data?.account ?? null,
    owner: units?.length > 0 ? units?.[0]?.contact_account : null,
    property: units,
    is_exist:false,
    contact:null,
    billingAccount:false,
    billing:null,
    type: "",
    name: data?.name ?? null,
    image_url: null,
    mobile: data?.mobile_no? {
      mobile: data?.mobile_no ?? "",
      mobile_code: data?.mobile_country_code ?? ""
    }:null,
    alter_mobile: "",
    email_id: data?.email ?? "",
    telephone: "",
    fax: "",
    address: "",
    facebook: "",
    twitter: "",
    linkedIn: "",
    //lead details
    lead_subject: null,
    duration: {
      select: data?.select ?? "Monthly",
      value: data?.value ?? "",
    },
    lease_start_date: data?.lease_start_date ? new Date(data?.lease_start_date) : null,
    lease_end_date: new Date(end_date),
    billing_start_date: data?.lease_start_date ? new Date(data?.lease_start_date) : null,
    payment_period: res?.data?.company_master[0]?.payment_period ?? "",
    quotation_custom_date: null,
    market_places: false,
    bill_cycle_method: res?.data?.company_master[0]?.billing_cycle ?? "",
    bill_cycle_date: res?.data?.company_master[0]?.billing_cycle_date ?? "",
    oppertunity_subject: "",
    priority: data?.urgent ?? "medium",
    revenue_base_lease: false,
    disableForm: false,
    turn_over_rent_type: "",
    lead_details:data,
    esclation_percentage: "",
    earlist_occupation_date: "",
    auto_renewal: false,
    contract_start_date: "",
    delivery_timestone_template: "",
    //quotation states
    purpose: data?.purpose ? boardingPropertyType.find(i=>i?.value===purpose) : null,
    custom_date: res?.data?.company_master[0]?.billing_day ?? "",
    revenue_type: data?.revenue_type ? {
      label:data?.revenue_type,
      value:data?.revenue_type
    } : "",
    quotation_revenue_based_lease: false,
    quotation_turn_over_rent: "",
    quotation_lease_duration: "",
    quotation_lease_start_date: "",
    quotation_lease_end_date: "",
    quotation_billing_start_date: "",
    quotation_payment_peroid: "",
    quotation_payment_option: "",
    quotation_grace_period: "",
    quotation_billing_cycle: "",
    quotation_billing_cycle_date: "",
    quotation_description: "",
    quotation_renewal_based_on: "",
    quotation_auto_renewal: false,
    convert_to_open_agreement: { is_active: true },
    quotation_escalculation_percent: "",
    quotation_include_market_place: false,
    quotation_handover_date: "",
    short_term: "",
    quotation_contract_start_date: "",
    quotation_milestone: "",
    company: company?.value ?? "",
    searchdata: "",
    contact_id: "",
    lead_id: proxy_id,
    pdf: "",
    grace_peroid: null,
    lease_date: "",
    proxy_lead_id: proxy_id,
    is_convert: true,
    payment_option: {
      value: "Online Payment",
      label: "Online Payment",
    },
    source: {
      value: "Direct Sales",
      label: "Direct Sales",
    },
    renewal_based_on: {
      value: "Latest Price",
      label: "Latest Price",
    },
    apply_grace_peroid: "In the Beginning",
    units: unit_ids ?? [],
    fixUnit: unit_ids ?? [],
    edit_owner: null,
    property_id: null,
    changeUnit: [],
    mileStoneName: data?.milestone_template?.label ?? data?.payment_period,
    mailstoneList: [],
    selectedUnit: [],
    deleted_property:[],
    unit_type:1,
    city: data?.city,
    company_name: data?.company,
    country: data?.country_master ? {
      label: data?.country_master?.country_name,
      value: data?.country_master?.id
    } : "",
    internal_lead_source: data?.internal_lead_source ? {
      label: data?.internal_lead_source?.name,
      value: data?.internal_lead_source?.id
    } : "",
    budget_amount: data?.budget_amount,
    confirm_interest: data?.is_confirm_interest,
    error: {
      type: "",
      name: "",
      image_url: "",
      mobile: "",
      alter_mobile: "",
      email_id: "",
      telephone: "",
      fax: "",
      address: "",
      facebook: "",
      twitter: "",
      linkedIn: "",
      //lead details
      duration: "",
      lease_start_date: "",
      lease_end_date: "",
      billing_start_date: "",
      payment_period: "",
      lead_details: "",
      payment_option: "",
      market_places: "",
      bill_cycle_method: "",
      bill_cycle_date: "",
      oppertunity_subject: "",
      source: "",
      priority: "",
      revenue_base_lease: "",
      turn_over_rent_type: "",
      required_area: "",
      budget: "",
      esclation_percentage: "",
      earlist_occupation_date: "",
      auto_renewal: "",
      contract_start_date: "",
      delivery_timestone_template: "",
      renewal_based_on: "",
      custom_date: "",
      quotation_custom_date: null,
      //quotation states
      purpose: "",
      revenue_type: "",
      quotation_revenue_based_lease: "",
      quotation_turn_over_rent: "",
      quotation_lease_duration: "",
      quotation_lease_start_date: "",
      quotation_lease_end_date: "",
      quotation_billing_start_date: "",
      quotation_payment_peroid: "",
      quotation_payment_option: "",
      quotation_grace_period: "",
      quotation_billing_cycle: "",
      quotation_billing_cycle_date: "",
      quotation_description: "",
      quotation_renewal_based_on: "",
      quotation_auto_renewal: "",
      convert_to_open_agreement: "",
      quotation_escalculation_percent: "",
      quotation_include_market_place: "",
      quotation_handover_date: "",
      short_term: "",
      quotation_milestone: "",
      company: "",
      searchdata: "",
      contact_id: "",
      lead_id: "",
      units: "",
      pdf: "",
      disableForm: false,
      lease_date: "",
    }


  }
  return result
}