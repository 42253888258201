export const ContactLevel = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
        <g data-name="Group 102948" transform="translate(-11563 -17154)">
            <circle
                data-name="Ellipse 129451"
                cx={24}
                cy={24}
                r={24}
                transform="translate(11563 17154)"
                fill={props?.background_fill ?? "#f0f9f7"}
            />
            <g data-name="Group 102947">
                <path
                    data-name="Rectangle 54909"
                    fill="none"
                    d="M11575 17166h24v24h-24z"
                />
                <path
                    d="M11586.1 17170a3.81 3.81 0 1 0 3.8 3.81 3.814 3.814 0 0 0-3.8-3.81Zm5.333 8.394a1.787 1.787 0 0 0-1.106.371l-2.024 1.6a1.778 1.778 0 0 0-.68 1.4v2.851a1.383 1.383 0 0 0 1.377 1.384h4.857a1.383 1.383 0 0 0 1.381-1.382v-2.852a1.777 1.777 0 0 0-.68-1.4l-2.024-1.6a1.786 1.786 0 0 0-1.105-.372Zm-9.714.749a1.716 1.716 0 0 0-1.719 1.714v.457c0 2.2 2.677 3.924 6.1 3.924.288 0 .568-.017.844-.041a2.126 2.126 0 0 1-.082-.58v-2.851a2.536 2.536 0 0 1 .97-2l.787-.621Zm8 2.667h.381a.572.572 0 0 1 .571.571v1.143a.572.572 0 0 1-.571.571h-.381a.572.572 0 0 1-.571-.571v-1.143a.572.572 0 0 1 .566-.571Zm3.048 0h.381a.572.572 0 0 1 .571.571v1.143a.572.572 0 0 1-.571.571h-.381a.572.572 0 0 1-.571-.571v-1.143a.572.572 0 0 1 .566-.571Z"
                    fill={props?.icon_fill ?? "#43aa8b"}
                />
            </g>
        </g>
    </svg>
)