import * as React from "react"
const VisitingChargesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="Group 115873">
      <path
        fill="#4e5a6b"
        d="M2.2 1A2.2 2.2 0 0 0 0 3.2v9.4a.6.6 0 0 0 .718.588l1.682-.336V15.4a.6.6 0 0 0 .718.588l1.867-.373 1.47.367a.6.6 0 0 0 .291 0l1.47-.367 1.867.373A.59.59 0 0 0 10.2 16a.6.6 0 0 0 .6-.6v-1.208a5.719 5.719 0 0 1-1.2-.173v.65l-1.282-.257a.591.591 0 0 0-.263.007l-1.455.362-1.455-.363a.6.6 0 0 0-.263-.006l-1.282.257v-1.6l.455.113a.6.6 0 0 0 .291 0l1.47-.367 1.867.374a.59.59 0 0 0 .118.012.594.594 0 0 0 .593-.54A5 5 0 1 0 8.4 4.135V2.8a.6.6 0 0 1 1.2-.017 5.693 5.693 0 0 1 1.183-.17A1.8 1.8 0 0 0 9 1Zm0 1.2h5.1a1.8 1.8 0 0 0-.1.6v9.069l-1.282-.257a.591.591 0 0 0-.263.007l-1.455.362-1.455-.363A.6.6 0 0 0 2.6 11.6a.614.614 0 0 0-.118.012l-1.282.257V3.2a1 1 0 0 1 1-1Zm.4 1.988a.6.6 0 1 0 0 1.2h3.2a.6.6 0 1 0 0-1.2ZM11 4.6a3.8 3.8 0 1 1-2.6 6.565v-5.53A3.773 3.773 0 0 1 11 4.6Zm-.018 1.6a.6.6 0 0 0-.172.03l-1.2.4a.6.6 0 1 0 .38 1.139l.41-.137V10a.6.6 0 1 0 1.2 0V6.8a.6.6 0 0 0-.618-.6ZM2.6 6.588a.6.6 0 1 0 0 1.2h3.2a.6.6 0 1 0 0-1.2Zm0 2.4a.6.6 0 1 0 0 1.2h2a.6.6 0 1 0 0-1.2Z"
        data-name="icons8-bill (8)"
      />
    </g>
  </svg>
)
export default VisitingChargesIcon
