import React from "react";
import { withNavBars } from "../../HOCs";
import ItemCategoryMaster from "./itemCategoryMaster";

class ItemCategoryMasterParent extends React.Component {
  render() {
    return <ItemCategoryMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ItemCategoryMasterParent, props);
