import React from "react"

export const ApartmentIcon = () =>(
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_112315" data-name="Group 112315" transform="translate(-13981 -7826)">
    <g id="Group_112314" data-name="Group 112314">
      <g id="Rectangle_57217" data-name="Rectangle 57217" transform="translate(13981 7826)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-apartment" d="M8.336,4a.571.571,0,0,0-.415.176l-4.1,4.266A3.145,3.145,0,0,0,3,10.632v7.653a1,1,0,0,0,.2.615.846.846,0,0,0,.66.338h8.946a.846.846,0,0,0,.66-.338,1,1,0,0,0,.2-.615V10.632a3.146,3.146,0,0,0-.82-2.191l-4.1-4.266A.571.571,0,0,0,8.336,4Zm5.33.007a.569.569,0,0,0-.411.168l-1.727,1.8.793.824,1.345-1.4,3.69,3.838a2.051,2.051,0,0,1,.5,1.4V18.1H14.429v.19a1.793,1.793,0,0,1-.267.952H18.14a.9.9,0,0,0,.86-.952V10.632a3.17,3.17,0,0,0-.819-2.191l-4.1-4.266A.569.569,0,0,0,13.667,4.007ZM8.333,5.4l3.69,3.838a2.078,2.078,0,0,1,.5,1.4V18.1H4.143V10.632a2.078,2.078,0,0,1,.5-1.4Zm-1.9,5.08a.762.762,0,1,0,.762.762A.762.762,0,0,0,6.429,10.476Zm3.81,0a.762.762,0,1,0,.762.762A.762.762,0,0,0,10.238,10.476Zm5.333,0a.762.762,0,1,0,.762.762A.762.762,0,0,0,15.571,10.476ZM6.429,13.143a.762.762,0,1,0,.762.762A.762.762,0,0,0,6.429,13.143Zm3.81,0A.762.762,0,1,0,11,13.9.762.762,0,0,0,10.238,13.143Zm5.333,0a.762.762,0,1,0,.762.762A.762.762,0,0,0,15.571,13.143ZM6.429,15.81a.762.762,0,1,0,.762.762A.762.762,0,0,0,6.429,15.81Zm3.81,0a.762.762,0,1,0,.762.762A.762.762,0,0,0,10.238,15.81Zm5.333,0a.762.762,0,1,0,.762.762A.762.762,0,0,0,15.571,15.81Z" transform="translate(13982 7826)" fill="#4e5a6b"/>
    </g>
  </g>
</svg>


)