export const UtilityIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        data-name="Group 98362"
        {...props}
    >
        <g data-name="Group 98363">
            <path
                fill="#ced3dd"
                d="M6 3.023 3.589 2.41 3.014 0h-.059l-.544 2.411L0 3.023V3.1l2.411.491L2.95 6h.068l.571-2.411L6 3.1ZM32 29.52l-2.009-.511-.48-2.009h-.048l-.454 2.009L27 29.52v.063l2.009.409.449 2.008h.057l.476-2.009L32 29.587Z"
                data-name="icons8-plus-math (1)"
            />
            <path
                fill="#717b89"
                d="M10.812 23.5h-1.48a.25.25 0 0 1-.24-.319l.857-3a.251.251 0 0 1 .24-.181h1.48a.25.25 0 0 1 .241.319l-.857 3a.251.251 0 0 1-.241.181Z"
                data-name="Path 93466"
            />
            <path
                fill="#717b89"
                d="M21.189 23.5h1.48a.25.25 0 0 0 .24-.319l-.857-3a.251.251 0 0 0-.24-.181h-1.48a.25.25 0 0 0-.24.319l.857 3a.251.251 0 0 0 .24.181Z"
                data-name="Path 93467"
            />
            <path
                fill="#959ca6"
                d="M23.5 9.5A1.5 1.5 0 0 0 22 8h-4.5A1.5 1.5 0 0 0 16 9.5v7h7.5Z"
                data-name="Path 93468"
            />
            <path
                fill="#959ca6"
                d="M16 9.5A1.5 1.5 0 0 0 14.5 8H10a1.5 1.5 0 0 0-1.5 1.5v7H16Z"
                data-name="Path 93469"
            />
            <path
                fill="#465160"
                d="M23.5 18h-15v-2a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1Z"
                data-name="Path 93470"
            />
            <path
                fill="#b8bdc4"
                d="M8.5 21H8a2 2 0 0 1-2-2v-6.5a.5.5 0 0 1 .5-.5H8a.5.5 0 0 1 .5.5Z"
                data-name="Path 93471"
            />
            <path
                d="M22.052 20.181a.251.251 0 0 0-.24-.181h-1.48a.25.25 0 0 0-.24.319l.337 1.181h2Z"
                data-name="Path 93472"
                opacity={0.05}
            />
            <path
                d="M22.052 20.181a.251.251 0 0 0-.24-.181h-1.48a.25.25 0 0 0-.24.319l.266.931h2Z"
                data-name="Path 93473"
                opacity={0.07}
            />
            <path
                d="M9.958 20.181a.25.25 0 0 1 .24-.181h1.48a.25.25 0 0 1 .24.319l-.337 1.181h-2Z"
                data-name="Path 93474"
                opacity={0.05}
            />
            <path
                d="M9.958 20.181a.25.25 0 0 1 .24-.181h1.48a.25.25 0 0 1 .24.319l-.267.931h-2Z"
                data-name="Path 93475"
                opacity={0.07}
            />
            <path
                fill="#b8bdc4"
                d="M25.5 12H24a.5.5 0 0 0-.5.5V18H6v1a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6.5a.5.5 0 0 0-.5-.5Z"
                data-name="Path 93476"
            />
        </g>
    </svg>
)