export const PropertyPath = ["name", "location", "unitCounts", "propertyType", "status"]

export const PropertyHeading = (t) => [
    { title: t("Property Name"), field: "name" },
    { title: t("Location"), field: "location" },
    { title: t("Unit Counts"), field: "unitCounts" },
    { title: t("Property Type"), field: "propertyType" },
    { title: t("Status"), field: "status" },
]

export const PropertyType = [
    { type: ["text"], name: "name" },
    { type: ["text"], name: "location" },
    { type: ["text"], name: "unitCounts" },
    { type: ["propertyType"], name: "propertyType" },
    { type: ["status"], name: "status" },
]

export const ActiveStatus = (t) => [
    { label: t('Active'), value: true },
    { label: t('Inactive'), value: false },
]

export const UnitPath1 = ["propertyName", "unitNo", "unitName", "unitCategory", "unitType", "unitCurrentStatus", "status"]

export const UnitHeading1 = (t) => [
    { title: t("Property Name"), field: "propertyName" },
    { title: t("Unit Number"), field: "unitNo" },
    { title: t("Unit Name"), field: "unitName" },
    { title: t("Unit Category"), field: "unitCategory" },
    { title: t("Unit Type"), field: "unitType" },
    { title: t("Occupancy Status"), field: "unitCurrentStatus" },
    { title: t("Status"), field: "status" },
]

export const UnitType1 = [
    { type: ["text"], name: "propertyName" },
    { type: ["text"], name: "unitNo" },
    { type: ["text"], name: "unitName" },
    { type: ["text"], name: "unitCategory" },
    { type: ["text"], name: "unitType" },
    { type: ["status"], name: "unitCurrentStatus" },
    { type: ["status"], name: "status" },
]

export const UnitVacancyPath = ["startDate", "endDate", "currentStatus", "agreementNumber", "image", "companyName", "primaryContactName", "primaryContactNumber", "reservationNumber"]


export const UnitVacancyHeading = (t) => [
    { title: t("Start Date"), field: "startDate", },
    { title: t("End Date"), field: "endDate", },
    { title: t("Occupancy Status"), field: "currentStatus", },
    { title: t("Agreement Number"), field: "agreementNumber", },
    { title: t("Company Name"), field: "companyName", },
    // { title: " ", field: "image", },
    { title: t("Primary Contact Name"), field: "primaryContactName", },
    { title: t("Primary Contact Number"), field: "primaryContactNumber", },
    { title: t("Reservation Number"), field: "reservationNumber", },
]

export const UnitVacancyType = [
    { type: ["date"], name: "startDate" },
    { type: ["date"], name: "endDate" },
    { type: ["status"], name: "currentStatus" },
    { type: ["text"], name: "agreementNumber" },
    { type: ["text"], name: "companyName" },
    // { type: ["avatarmanagement"], name: "image" },
    { type: ["text"], name: "primaryContactName" },
    { type: ["text"], name: "primaryContactNumber" },
    { type: ["text"], name: "reservationNumber" },
]

export const UnitPath2 = ["unitName", "unitCategory", "floorName", "blockName", "unitCurrentStatus", "status"]

export const UnitHeading2 = (t) => [
    { title: t("Unit Name"), field: "unitName" },
    { title: t("Unit Category"), field: "unitCategory" },
    { title: t("Floor Name"), field: "floorName" },
    { title: t("Block Name"), field: "blockName" },
    { title: t("Unit Current Status"), field: "unitCurrentStatus" },
    { title: t("Status"), field: "status" },
]

export const UnitType2 = [
    { type: ["text"], name: "unitName" },
    { type: ["text"], name: "unitCategory" },
    { type: ["text"], name: "floorName" },
    { type: ["text"], name: "blockName" },
    { type: ["text"], name: "unitCurrentStatus" },
    { type: ["status"], name: "status" },
]