import React from "react";
import { withNavBars } from "../../HOCs";
import Accounts from '../accounts/account'

class VendorAccountListParent extends React.Component {
    render() {
        return <Accounts type="Vendor" subtitle="Vendor Account"/> ;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(VendorAccountListParent, props);

 