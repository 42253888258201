import React from "react"

export const MileStoneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="32" height="32" viewBox="0 0 32 32">
      <defs>
        <radialGradient id="radial-gradient" cx="0.167" cy="0.696" r="0.728" gradientUnits="objectBoundingBox">
          <stop offset="0" />
          <stop offset="1" stop-opacity="0" />
        </radialGradient>
        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#ffcf54" />
          <stop offset="0.261" stop-color="#fdcb4d" />
          <stop offset="0.639" stop-color="#f7c13a" />
          <stop offset="1" stop-color="#f0b421" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="0.157" y1="0.157" x2="0.853" y2="0.853" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fede00" />
          <stop offset="1" stop-color="#ffd000" />
        </linearGradient>
        <linearGradient id="linear-gradient-3" x1="0.445" y1="0.592" x2="0.594" y2="0.218" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#f0b421" stop-opacity="0" />
          <stop offset="0.512" stop-color="#e9ab12" stop-opacity="0.514" />
          <stop offset="1" stop-color="#e09f00" />
        </linearGradient>
      </defs>
      <g id="Group_102167" data-name="Group 102167" transform="translate(-8603 -9818)">
        <rect id="Rectangle_53914" data-name="Rectangle 53914" width="32" height="32" rx="4" transform="translate(8603 9818)" fill="#fff4eb" />
        <g id="icons8-receive-cash" transform="translate(8606 9818.429)">
          <circle id="Ellipse_129427" data-name="Ellipse 129427" cx="1.19" cy="1.19" r="1.19" transform="translate(19.19 17.476)" fill="#c98e00" />
          <path id="Path_96852" data-name="Path 96852" d="M26.1,35.14l6.89-3.8-1.019-2.144L26,31.957Z" transform="translate(-12.048 -11.624)" fill="#c98e00" />
          <circle id="Ellipse_129428" data-name="Ellipse 129428" cx="3.333" cy="3.333" r="3.333" transform="translate(15.381 7)" fill="#ff8f6b" />
          <circle id="Ellipse_129429" data-name="Ellipse 129429" cx="2.381" cy="2.381" r="2.381" transform="translate(16.333 7.952)" fill="#ed6c47" />
          <path id="Path_96853" data-name="Path 96853" d="M34.714,11.964A4.76,4.76,0,0,0,31.25,7.386a3.331,3.331,0,1,0,3.356,5.583A4.756,4.756,0,0,0,34.714,11.964Z" transform="translate(-13.619 -0.202)" fill="url(#radial-gradient)" />
          <path id="Path_96854" data-name="Path 96854" d="M23,27.524a1.19,1.19,0,0,0-1.647-1.1h0l-.005,0h0L15.381,29.19l-.714-.476a1.187,1.187,0,0,0,.587-2.22l0-.008-6.5-3.27H8.743A2.341,2.341,0,0,0,6.8,23.2h-.01L3,24.9V31.1l3.852-1.77,7.577,3.1h0a1.162,1.162,0,0,0,1.044-.051h.008l6.89-3.8h0A1.185,1.185,0,0,0,23,27.524Z" transform="translate(0 -8.381)" fill="url(#linear-gradient)" />
          <circle id="Ellipse_129430" data-name="Ellipse 129430" cx="3.81" cy="3.81" r="3.81" transform="translate(12.524 7.952)" fill="url(#linear-gradient-2)" />
          <circle id="Ellipse_129431" data-name="Ellipse 129431" cx="2.857" cy="2.857" r="2.857" transform="translate(13.476 8.905)" fill="#f5be00" />
          <path id="Path_96855" data-name="Path 96855" d="M29.214,16.716v-.568a1.24,1.24,0,0,0,.659.189.617.617,0,0,0,.411-.125.42.42,0,0,0,.148-.34q0-.448-.633-.448a3.889,3.889,0,0,0-.5.038V13.778h1.67v.543h-1.1v.6c.088-.009.172-.012.25-.012a1.029,1.029,0,0,1,.728.244.852.852,0,0,1,.263.656.967.967,0,0,1-.313.745,1.2,1.2,0,0,1-.85.289A1.815,1.815,0,0,1,29.214,16.716Z" transform="translate(-13.731 -3.55)" fill="#fee119" />
          <circle id="Ellipse_129432" data-name="Ellipse 129432" cx="0.714" cy="0.714" r="0.714" transform="translate(13.952 18.429)" fill="#ffde87" />
          <path id="Path_96856" data-name="Path 96856" d="M22.714,33.637l-.709-.475c-.6-.029-1.068-.223-5.005-1.673v1.672l5.5,2.254A1.979,1.979,0,0,1,22.714,33.637Z" transform="translate(-7.333 -12.828)" fill="url(#linear-gradient-3)" />
        </g>
      </g>
    </svg>

  )
}