import React from "react";
import { withNavBars } from "../../HOCs";
import Contract360 from "./contract360";

class Contract360Parent extends React.Component {
    render() {
        return (
            <Contract360 />
        )
    }
}

const props = {
    boxShadow: false
}

export default withNavBars(Contract360Parent, props);
