import { Box, Paper } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { moveOutReqTypePath, moveoutHeading, moveoutType } from "../.././utils/moveoutInspection";
import { FilterGenerator, LoadingSection, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, accessCheckRender, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew } from "../../utils";
import MoveOutHead from './moveOutHead';
import { useStylesInspection } from "./style";

const MoveoutInspection = ({ t }) => {

    const classes = useStylesInspection()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [searchText, setSearchText] = useState("")
    const [companyList, setCompanyList] = useState("")
    const [selectedCompany, setSelectedCompany] = useState("")
    const [permissions, setPermission] = React.useState({})
    const [tableData, setTableData] = useState([])
    const [totalRows, setTotalRows] = useState([])
    const debounce = UseDebounce()
    const history = useHistory()
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [loading, setLoading] = React.useState(true)
    const [filterData, setFilterData] = React.useState({
        status: null,
        dateRange: {
            startDate: null,
            endDate: null
        },
        completion_date: null
    });
    const [drawer, setDrawer] = useState(false)
    const [statusOptionList, setStatusOptionsList] = React.useState([])
    const moveoutHeader = moveoutHeading(t);
    //get tableData 
    const getAllAgreementRequestList = (company_id, offset = 0, limit = 10, searchText, filterData) => {

        const payload = {
            company_id: company_id,
            search: searchText,
            offset: offset,
            limit: limit,
            type: "move-out",
            status: filterData?.status?.map((x) => { return x?.value }),
            completed_on: filterData?.completion_date ? moment(filterData?.completion_date).format("YYYY-MM-DD") : null,
            start_date: filterData?.dateRange?.startDate ? moment(filterData?.dateRange?.startDate).format("YYYY-MM-DD") : null,
            end_date: filterData?.dateRange?.endDate ? moment(filterData?.dateRange?.endDate).format("YYYY-MM-DD") : null,
        }

        NetworkCall(
            `${config.api_url}/agreement_inspection/list_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                // const result = response?.data?.data?.map((val) => {
                //     return {
                //         ...val,
                //         property_name: `${val?.property?.[0]?.name}`
                //     }
                // })
                setTableData(response?.data?.data)
                setTotalRows(response?.data?.count)
                setLoading(false)

            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
                setLoading(false)

            })
    }
    const getEnum = async () => {
        const result = await enumSelect([
            enum_types?.agreement_inspection_status_type,
            enum_types?.agreement_inspection_status_type_new
        ]);
        const agreementJobStatus = result?.agreement_inspection_status_type_new?.filter((x) => { return x?.value !== "Completed" && x?.value !== "Yet to assign" })
        setStatusOptionsList([...result?.agreement_inspection_status_type, ...agreementJobStatus])
    };
    //initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getEnum()
                getAllAgreementRequestList(company?.selected?.value, 0, limit, "",filterData)
            }
        }
        // eslint-disable-next-line
    }, [auth])
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getAllAgreementRequestList(selectedCompany?.value, 0, 10, e,filterData)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getAllAgreementRequestList(selectedCompany?.value, offset, limit, "", filterData)
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getAllAgreementRequestList(selectedCompany?.value, 0, value, "", filterData)

    }
    //company handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getAllAgreementRequestList(value?.value, 0, 10, "",filterData)
    }
    //To filter data based on status
   //To filter data based on status
   const handleFilter = (data) => {
    getAllAgreementRequestList(selectedCompany?.value, 0, limit, "", data)
    setFilterData(data)
}
const handleCloseFilter = () => {
    setDrawer(false)
}
    const handleIcon = (type, data) => {
        history.push({
            pathname: Routes?.moveOutInspectionDetails,
            state: {
                company: selectedCompany,
                id: data?.id,
                title: data?.reference_id,
                type: data?.request_type,
                agreement_id: data?.agreement_id
            }
        })
    }

    const render = () => {
        return (
            <Box>
                <Subheader title={t("Offboard and Move-out")} hideBackButton={true} select value={selectedCompany} options={companyList} onchange={(e) => {
                    handleCompanyChange(e)
                }} />
                {
                    loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :
                        <Paper className={classes.root}>
                            <MoveOutHead t={t} handleSearch={handleSearch} searchText={searchText} openFilter={() => setDrawer(true)} dot={filterData?.status?.length > 0 || filterData?.dateRange?.startDate !== null || filterData?.completion_date !== null} placeholder={t("Search Move-out Requests")}/>
                            <TableWithPagination
                                heading={moveoutHeader}
                                rows={tableData}
                                dataType={moveoutType}
                                path={moveOutReqTypePath}
                                handleIcon={handleIcon}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                showpagination
                                tableType="no-side"
                                totalRowsCount={totalRows}
                                page={page}
                                height={`calc(100vh - 300px)`}
                                limit={limit}
                                className={classes.moveoutTable}

                            />
                {/*filter component */}
                {drawer && (
                    <FilterGenerator
                        open={drawer}
                        onClose={() => handleCloseFilter()}
                        components={[
                            {
                                component: "date_range",
                                value: filterData?.dateRange,
                                state_name: "dateRange",
                                label: t("Requested On"),
                                toLabel: t("To"),
                                startPlaceholder: t("Select Start Date"),
                                endPlaceholder: t("Select End Date")
                            },
                            {

                                component: "select",
                                value: filterData?.status,
                                options: statusOptionList,
                                isMulti: true,
                                state_name: "status",
                                label: t("Status"),
                                placeholder: t("Status"),
                            },
                            {
                                component: "date",
                                value: filterData?.completion_date,
                                state_name: "completion_date",
                                label: t("Completion Date"),
                                placeholder: t("Select Completion Date")
                            },

                        ]}
                        onApply={(value) => handleFilter(value)}
                    />
                )}
                        </Paper>
                }
            </Box>
        )
    }
    return (

        <Box>
            {accessCheckRender(render, permissions)}
        </Box>
    )
}
export default withNamespaces("moveoutInspection")(MoveoutInspection);