import { Box, Button, Divider, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { AggrementComponentStyles } from "./styles";
const QuotationDetailCard = ({ value = [], headerDet = {}, onClick = () => false, t = () => false }) => {
    // classes
    const classes = AggrementComponentStyles();
    const showOrHide = headerDet.type
    return (
        <>
            <Box className={classes.quoDtlCard}>
                <Box p={1.5} display={"flex"} flexDirection={"column"} alignItems={"left"} >
                    <Typography textTransform={"uppercase"} className={classes.quoDtlCardTitle}>{showOrHide === "Quotation" ? t("quotation") : t("agreement")} {t("details")}</Typography>
                    <Typography className={classes.quoDtlCardNumber}>{headerDet.quotationNumber}</Typography>
                    {
                        showOrHide === "Quotation" ?
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography className={classes.quoDtlCardStack}>{headerDet.sub1}</Typography>
                                <Box className={classes.dot}></Box>
                                <Typography className={classes.quoDtlCardStack}>{headerDet.sub2}</Typography>
                            </Stack> :
                            <Typography className={classes.quoDtlCardDate} >&#x202a;{headerDet.Date}&#x202c;</Typography>
                    }

                </Box>
                <Divider />
                {
                    showOrHide === "Quotation" ?
                        <Box padding="0px 8px">
                            <Table >
                                <TableBody >
                                    {value.map((e) => {
                                        return (
                                            <TableRow sx={{ width: "100%" }} className={classes.quoDtlCardTableRow}>
                                                <TableCell sx={{ borderBottom: "none" }} className={classes.quoDtlCardTableSec} component="th" scope="row">
                                                    {e.tableHeader}
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: "none" }} align="right" className={classes.quoDtlCardTableSec}>{e.amount}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                        : " "
                }

                <Box m={1}>
                    <Button onClick={onClick} fullWidth variant="outlined" className={classes.downBtn}>{t("download")} {showOrHide === "Quotation" ? t("Quotation") : t("Agreement")}</Button>
                </Box >

            </Box >
        </>
    )
}
export default withNamespaces("agreement")(QuotationDetailCard); 