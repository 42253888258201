import { withNamespaces } from "react-i18next"
import { Subheader } from "../../components"
import React from 'react';
import { Box, Typography, Grid, Stack, Pagination, Switch } from '@mui/material';
import { AlertDialog, LoadingSection, SearchFilter, UseDebounce } from '../../components';
import { NetworkCall } from '../../networkcall';
import { config } from "../../config";
import { AlertProps, NetWorkCallMethods } from '../../utils';
import { DocumentViewer } from '../../components/fileViewer';
import { Custom } from '../../assets/custom';
import { useHistory } from 'react-router-dom';
import { useStyles } from "./style";
import { styled } from "@mui/material/styles";
import { AlertContext } from "../../contexts";


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: "#5078E1",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const ExploreTemplate = ({
    t = () => false
}) => {
    const classes = useStyles()
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext);
    const history = useHistory();

    const [list, setLise] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [searchText, setSearchText] = React.useState("")
    const [value, setValue] = React.useState({
        step: 1,
        bool: false,
        dialog: false,
        data: [],
        offset: 0,
        selected: {},
        count:0
    });
    const [page, setPage] = React.useState(1);
    const [index, setIndex] = React.useState(null)

    const totalCount = value?.count > -1 ? value?.count : "";

    const getData = (offset = 0, search = "", type = 1, isLoader = false) => {
        if(isLoader) setLoading(true)
        let datas = {
            offset,
            limit: 10,
            search,
            type
        }
        NetworkCall(
            `${config.api_url}/pdf-type-master/get_all_pdf`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((rs) => {
                setValue({
                    ...value,
                    data: rs?.data?.data?.map((x)=>{
                        return{
                            ...x,
                            is_shortlist:x?.is_shortlist === "1" ? true : false
                        }
                    }),
                    offset: offset,
                    step: type,
                    count:rs?.data?.count
                })


                setLoading(false)
            })
            .catch((er) => {
                // alert.setSnack({
                //     ...alert,
                //     open: true,
                //     severity: AlertProps.severity.success,
                //     msg: "onBoarding Deleted Successfully",
                //     vertical: AlertProps.vertical.top,
                //     horizontal: AlertProps.horizontal.center,
                // });
                setLoading(false)

            });
    };

    const getTypes = () => {
        let datas = {}
        NetworkCall(
            `${config.api_url}/pdf-type-master/get_types`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((rs) => {
                setLise(rs?.data)
                getData(0, "", rs?.data?.data?.[0]?.id)
            })
            .catch((er) => {
                console.log(er);
            });
    };
    const updateState = (k, v) => {
        setValue({ ...value, [k]: v })
    }

    const handleSearch = (v) => {
        setSearchText(v)
        debounce(() => getData(0, v, value?.step), 1000);

    }
    const ChangeStatus = (data) => {
        setLoading(true)

        let datas = {
            id: data?.is_shortlist === true ? data?.id : undefined,
            is_active: data?.is_shortlist === true ? false : true,
            template_id: data?.value
        }
        // return console.log(datas , 'data')

        NetworkCall(
            `${config.api_url}/pdf-type-master/shortlist_create`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((rs) => {
                setLoading(false)
                getData(0, "", value?.step)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Template Shortlisted Sucessfully"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            })
            .catch((er) => {
                console.log(er);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Some Thing Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
                setLoading(false)

            });
    }

    const goBack = () => {
        history.goBack()
      }
      const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * 10;
        getData(offset , "" , value?.step , true)
    }
    React.useEffect(() => {
        getTypes()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Subheader title={t("Explore Template")} goBack={goBack}

            />
            <div style={{ padding: "16px" }}>
                <Box className={classes?.root1}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "20px", overflowX: "auto", overflowY: 'hidden', padding: "0px 16px", height: "47px" }}>
                                {
                                    list?.map((x) => {
                                        return (
                                            <Box onClick={() => {
                                                setLoading(true)
                                                getData(0, "", x?.id, true)
                                            }} style={{ textAlign: "center", position: 'relative' }}>
                                                <Typography noWrap className={x?.id === value?.step ? classes.tabNameseleted : classes.tabName} variant='span'>{x?.name}{(x?.id === value?.step) && <Box className={classes.absolute} />}</Typography>
                                            </Box>
                                        )
                                    })
                                }

                            </Box>
                        </Box>
                        <Box style={{ padding: "16px 16px 0px 16px" }}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <SearchFilter value={searchText} placeholder={t("Search Template")} handleChange={(value) => handleSearch(value)} />


                        </Grid>

                    </Grid>

                        </Box>
                        <Box style={{ padding: "16px", height: `calc(100vh - 318px)` , overflow:"auto" }}>
                            {
                                loading ? <LoadingSection bottom={"40vh"} message="Fetching ..." /> :
                                    <Grid container spacing={2}>
                                        {
                                            value?.data?.map((x, i) => {
                                                return (
                                                    <Grid item xs={12} sm={6} md={3} lg={2}>
                                                        <Box className={classes.container}
                                                           onMouseEnter={() => {
                                                            setIndex(i)
                                                            updateState('bool', true)
                                                        }}
                                                        onMouseLeave={() => {
                                                            setIndex(null)
                                                            updateState('bool', false)
                                                        }}>

                                                            <Box className={classes.pdfImg}>
                                                            {((i === index) && value?.bool) &&
                                                             <Typography className={classes.view} onClick={() => setValue({
                                                                ...value,
                                                                dialog: true,
                                                                selected: x
                                                            })}>{t("Preview Template")}</Typography>
                                                             } 
                                                                <DocumentViewer url={x?.image_url} />
                                                                {
                                                                    x?.default_pdf_type === "Custom" &&
                                                                    <Box className={classes.custom}>
                                                                        <Custom /> {((i === index) && value?.bool) && "Custom"}
                                                                    </Box>
                                                                }

                                                                {/* <img src={x?.image_url} alt='img' width={'100%'} height={'100%'}/> */}
                                                            </Box>
                                                            <Box className={classes.container1}>
                                                                <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <Typography className={classes.language} noWrap>{x?.language}</Typography>
                                                                  
                                                                    <IOSSwitch checked={x?.is_shortlist} onClick={() => ChangeStatus(x)} />

                                                                </Stack>
                                                                <Typography className={classes.templateTitle} noWrap>
                                                                    {x?.label}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                )
                                            })
                                        }

                                    </Grid>
                            }

                        </Box>

                        <Box display="flex" alignItems={'center'} justifyContent={'space-between'} style={{padding:"12px"}}>
                            <Box className={classes.pageCount}>
                                Page {page ?? 0}/<span>{Math.ceil(totalCount / 10)}</span>
                            </Box>
                            <Pagination
                                className={classes.paginate}
                                shape="rounded"
                                count={Math.ceil(totalCount / 10)}
                                page={page}
                                onChange={(e, value) => handlePagination(value)}
                                color="primary"

                            />
                        </Box>



                        {/* view */}
                        <AlertDialog
                            header={t("Preview Template")}
                            onClose={() => updateState('dialog', false)}
                            md
                            component={
                                <>
                                    <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={'center'} style={{ padding: "16px" }}>
                                        <Typography className={classes.dialogTitle} noWrap>{value?.selected?.label}</Typography>
                                        

                                    </Stack>
                                    <Box style={{ padding: "16px" }}>
                                        <Box className={classes.pdfImgs} width={"100%"} style={{ height: "calc(100vh - 249px)" }}>
                                            <DocumentViewer url={value?.selected?.image_url} />
                                        </Box>
                                    </Box>
                                </>
                            }
                            open={value?.dialog} />
                    </Box>


                </Box>        </div>
        </div>
    )
}
export default withNamespaces("clientSetting")(ExploreTemplate)