import React from "react";
import { withNavBars } from "../../HOCs";
import UtilitiesMapping from "./utilitiesMapping";

class UtilitiesMappingMasterParent extends React.Component {
    render() {
        return <UtilitiesMapping  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UtilitiesMappingMasterParent, props);
