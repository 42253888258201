import * as React from "react"

export const ReceiptTotal = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
        <g data-name="Group 98671">
            <g data-name="Group 95678" transform="translate(-540 -84)">
                <rect
                    data-name="Rectangle 49614"
                    width={50}
                    height={50}
                    rx={25}
                    transform="translate(540 84)"
                    fill="#bdf2d2"
                />
            </g>
            <path
                d="M21.365 15.003a.71.71 0 0 0-.273.054l-2.151.858-1.912-.85a.729.729 0 0 0-1.029.668v15.121a2.687 2.687 0 0 0 2.683 2.683h5.492a6.2 6.2 0 0 1-.858-1.463h-4.634a1.22 1.22 0 0 1-1.219-1.219V16.86l1.166.516a.725.725 0 0 0 .571.01l2.165-.864 2.165.864a.709.709 0 0 0 .547 0l2.165-.864 2.165.864a.725.725 0 0 0 .571-.01l1.166-.516v6.507a6.382 6.382 0 0 1 1.463.414v-8.048a.729.729 0 0 0-1.029-.668l-1.912.848-2.151-.858a.708.708 0 0 0-.547 0l-2.169.864-2.165-.864a.71.71 0 0 0-.27-.052Zm-1.707 5.854a.732.732 0 1 0 0 1.463h8.292a.732.732 0 1 0 0-1.463Zm9.511 3.414a5.365 5.365 0 1 0 5.365 5.365 5.366 5.366 0 0 0-5.364-5.367Zm-9.511.976a.732.732 0 1 0 0 1.463h3.888a6.265 6.265 0 0 1 .921-1.327.7.7 0 0 0-.419-.136ZM32.1 27.196a.488.488 0 0 1 .345.833l-3.9 3.9a.488.488 0 0 1-.69 0l-1.955-1.95a.488.488 0 0 1 .69-.69l1.606 1.606 3.557-3.557a.486.486 0 0 1 .347-.142Zm-12.438.976a.732.732 0 1 0 0 1.463h3.17A6.291 6.291 0 0 1 23 28.171Z"
                fill="#4acc7e"
            />
        </g>
    </svg>
)


