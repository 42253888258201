import React from "react"

export const AgreementIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_112519" data-name="Group 112519" transform="translate(-14797 -9521)">
    <g id="Rectangle_57304" data-name="Rectangle 57304" transform="translate(14797 9521)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="24" height="24" stroke="none"/>
      <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
    </g>
    <path id="icons8-regular-document_1_" data-name="icons8-regular-document (1)" d="M17.625,4H9.575A1.577,1.577,0,0,0,8,5.575v10.85A1.577,1.577,0,0,0,9.575,18h8.05A1.577,1.577,0,0,0,19.2,16.425V5.575A1.577,1.577,0,0,0,17.625,4Zm-6.65,2.8h2.45a.525.525,0,1,1,0,1.05h-2.45a.525.525,0,1,1,0-1.05Zm3.85,8.4h-3.85a.525.525,0,0,1,0-1.05h3.85a.525.525,0,1,1,0,1.05Zm1.4-2.1h-5.25a.525.525,0,1,1,0-1.05h5.25a.525.525,0,0,1,0,1.05Zm0-2.1h-5.25a.525.525,0,1,1,0-1.05h5.25a.525.525,0,0,1,0,1.05Z" transform="translate(14795 9522)" fill="#4e5a6b"/>
  </g>
</svg>

)