import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { IOSSwitch, SelectBox } from "../../../components";
import NoSelectionIcon from "../utils/noSelectionIcon";
import { ChooseCardStyle } from "./style";
import { DeleteIcon } from "../../../assets/deleteIconResident";
import { stringAvatar } from "../../../utils";
import { getTrueCheck } from "../function";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export const ChooseCard = ({
    header = "",
    options = [],
    showRemove = false,
    visible = true,
    handleRemove = () => false,
    title = "",
    subTitle = "",
    noSelectedTitle = "",
    icon = {},
    topHeader = "",
    onchange = () => false,
    value = {},
    onDelete = () => false,
    loadOptions = () => false,
    error = "",
    errorMsg = "",
    isRequired = true,
    isMulti = false,
    key,
    onDeleteMuti = () => false,
    onDeleteMutiCheck = () => false,
    menuPlacement = 'auto',
    border = "",
    isReadOnly = false,
    showCard = true,
    boldHeader = false,
    isBorder = false,
    is_toggle=false,
    state1,
    setState1=()=>false,
    t = () => false
}) => {


    const classes = ChooseCardStyle({ border })
    const language = localStorage.getItem("i18nextLng");
    const [primary , setPrimary]=useState(state1 ?? true)

    const customStyles = {
        control: base => ({
            ...base,
            height: 200,
            minHeight: 200
        })
    };


    const multiFunction = (data) => {
        if (!value?.map((x) => x?.value)?.includes(data?.value)) {
            onchange(data)
        }
    }
    return (
        <>
            {
                visible &&
                <Stack spacing={1}>
                    {
                        (boldHeader || header) &&

                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>

                            {boldHeader ?
                                <Typography className={classes.boldHeader}>{header}{isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                    *
                                </Typography>}</Typography>
                                :
                                <Typography className={showRemove ? classes.boldHeader : classes.header}>{header}{isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                                    *
                                </Typography>}</Typography>
                            }

                            {showRemove && <Typography className={classes.remove} onClick={handleRemove}>{t("Remove")}</Typography>}
                        </Stack>


                    }


                    <Stack spacing={1} className={classes.box} border={isBorder ? "1px solid #CED3DD" : "none"} p={isBorder ? "14px" : "none"}>
                        {
                            showCard &&
                            <Stack direction={"row"} style={{ alignItems: 'center' }} marginBottom="8px">
                                {icon}
                                <Stack sx={{ marginInlineStart: "8px" }} >
                                    <Typography className={classes.title}>{title}</Typography>
                                    {is_toggle && <Box sx={{display:"flex", alignItems:"center",gap:"10px"}}>
                                        <Typography className={classes.subTitle}>{t("Show only primary account")}</Typography>
                                       <Box> <IOSSwitch
                                        width={36}
                                        height={16}
                                        checked={primary}
                                        tamW={10}
                                        tamH={10}
                                        tamM={0}
                                        onChange={(event)=>{setPrimary(event.target.checked);setState1(event.target.checked);}}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                      /></Box>
                                        </Box>}
                                    <Typography className={classes.subTitle}>{subTitle}</Typography>
                                </Stack>
                            </Stack>
                        }


                        <SelectBox
                            // isRequired={true}
                            key={is_toggle ? (key || JSON.stringify(primary)):key}
                            label={''}
                            menuOptionHeight={'290px'}
                            menuPlacement={menuPlacement}
                            placeholder={title}
                            borderRadius={"8px"}
                            // options={options}
                            loadOptions={(search, array, handleLoading)=>loadOptions(search, array, handleLoading , primary)}
                            isPaginate={true}
                            debounceTimeout={800}
                            styles={customStyles}
                            isReadOnly={isReadOnly}
                            noSearch={true}

                            //             left={'10px'}
                            //             prefix={
                            //                 <SearchIMG
                            //   color="#a4b2c1"
                            //   style={{ color: "#999999" }}
                            // />
                            //             }
                            onChange={(value) => {

                                isMulti ? multiFunction(value) : onchange(value)
                            }}
                            // loading={true}
                            value={isMulti ? null : value}
                            selectHeight="38px"
                            customOptionComponent={(props) => {
                                return (
                                    <Box marginTop="8px" className={isMulti ? classes.selectMultiParent : classes.selectParent} style={{ border: (props?.isSelected && !isMulti) ? "1px solid #5078E1" : "1px solid #E4E8EE", backgroundColor: (props?.isSelected && !isMulti) ? "#F1F7FF" : "#fff" }}>
                                        <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={'space-between'}>
                                            <Stack direction={"row"} p={0.5} alignItems={"center"}>
                                                <Avatar  className={classes.optionAvatar} src={props?.data?.url ?? null} {...stringAvatar(props?.data?.label)}></Avatar>
                                                <Stack sx={{ marginInlineStart: "16px" }}>
                                                    <Typography className={classes.optionHeader}>{props?.data?.label ?? ""}</Typography>
                                                    <Stack direction={"row"} alignItems={"center"}>
                                                        <Typography className={classes.optionSubTitle} noWrap>{props?.data?.mobile_no_country_code ?? ''} {props?.data?.mobileNo || props?.data?.label1} {props?.data?.uom_master?.name ? props?.data?.uom_master?.name : ""}</Typography>
                                                        {getTrueCheck(props?.data?.mobileNo, props?.data?.email) && <Box className={classes.dot}></Box>}
                                                        <Typography className={classes.optionSubTitle} noWrap >{props?.data?.email?.length > 0
                                                            ? `${props?.data?.email?.substring(0, 24)}${props?.data?.email?.length > 24 ? "..." : ""
                                                            } `
                                                            : ""}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            <Box>
                                                {
                                                    isMulti && (
                                                        (value?.length > 0 && value?.map((x) => x?.value).includes(props?.data?.value)) ? <CheckCircleIcon sx={{ color: "#5078E1" }} /> : false
                                                    )

                                                    // <Checkbox
                                                    //     onChange={(event) => {
                                                    //         onDeleteMutiCheck(event?.target?.checked ? [...value , props?.data] : value?.filter((x)=> x?.value !== props?.data?.value))
                                                    //         event.stopPropagation()
                                                    //     }}
                                                    //     checked={value?.length > 0 ? value?.map((x) => x?.value).includes(props?.data?.value) ? true : false : false}
                                                    //     icon={<RadioButtonUncheckedIcon sx={{ color: "#E4E8EE" }} />}
                                                    //     checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />} />
                                                }
                                            </Box>
                                        </Stack>
                                    </Box>
                                )
                                // <div style={{ padding: "8px", backgroundColor: props?.isSelected ? "red" : "white" }}>{`${props?.data?.label} - ${props?.isSelected ? "Selected" : "Unselected"}`}</div>
                            }}
                        />
                        {
                            isMulti ? (
                                value?.length > 0 ?
                                    value?.map((element, i) => {
                                        return (
                                            <Stack direction={"row"} p={0.5} alignItems={"center"} className={classes.choosecardsMuti}>
                                                <Avatar className={classes.optionAvatar} {...stringAvatar(element?.label)} src={element?.url}></Avatar>
                                                <Stack marginInlineStart={'8px'}>
                                                    <Typography className={classes.optionHeader}>{element?.label ?? ""}</Typography>
                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                        <Typography className={classes.optionSubTitle} noWrap>{element?.mobileNo || element?.label1} {element?.uom_master?.name ? element?.uom_master?.name : ""}</Typography>
                                                        {getTrueCheck((element?.mobileNo || element?.label1), (element?.email)) && <Box className={classes.dot}></Box>}
                                                        <Typography className={classes.optionSubTitle} noWrap>{element?.email}</Typography>
                                                    </Stack>
                                                </Stack>
                                                {
                                                    !isReadOnly &&
                                                    <DeleteIcon style={{ cursor: "pointer", position: "absolute", left: language === "ar" && 3, right: language !== "ar" && 3 }} onClick={() => onDeleteMuti(i)} />
                                                }
                                            </Stack>
                                        )
                                    })
                                    :
                                    <center>
                                        <Stack alignItems={"center"} justifyContent={"center"}>
                                            <NoSelectionIcon />
                                            <Typography className={classes.subTitle}>{noSelectedTitle}</Typography>
                                        </Stack>
                                    </center>
                            )
                                :
                                (
                                    value ? <Stack direction={"row"}  p={0.5} alignItems={"center"} className={classes.choosecards}>
                                        <Avatar className={classes.optionAvatar} {...stringAvatar(value?.label)} src={value?.url}></Avatar>
                                        <Stack sx={{ marginInlineStart: "8px"}}>
                                            <Typography className={classes.optionHeader}>{value?.label ?? ""}</Typography>
                                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                <Typography className={classes.optionSubTitle} noWrap>{value?.mobileNo || value?.label1} {value?.uom_master?.name || value?.uom?.name}</Typography>
                                                {getTrueCheck((value?.mobileNo || value?.label1), (value?.email)) && <Box className={classes.dot}></Box>}
                                                <Typography className={classes.optionSubTitle} noWrap>{value?.email}</Typography>
                                            </Stack>
                                        </Stack>
                                        {
                                            !isReadOnly &&
                                            <DeleteIcon style={{ cursor: "pointer", position: "absolute", left: language === "ar" && 3, right: language !== "ar" && 3  }} onClick={onDelete} />
                                        }
                                    </Stack> : <center>
                                        <Stack alignItems={"center"} justifyContent={"center"}>
                                            <NoSelectionIcon />
                                            <Typography className={classes.subTitle}>{noSelectedTitle}</Typography>
                                        </Stack>
                                    </center>
                                )

                        }

                    </Stack>
                </Stack >
            }
            {
                error && (
                    <Typography variant={"caption"} color={"error"}>
                        {errorMsg}
                    </Typography>
                )
            }
        </>
    )
}