import * as React from "react"
const BackgroundTypeArabic = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={97} height={68.167} {...props}>
    <defs>
      <filter
        id="a"
        width={97}
        height={68.167}
        x={0}
        y={0}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={6} />
        <feGaussianBlur result="b" stdDeviation={4} />
        <feFlood floodColor="#3b3b63" floodOpacity={0.122} />
        <feComposite in2="b" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#a)">
      <path
        fill="#f4a261"
        d="M79 6.002a6 6 0 0 1 6 6v22a6 6 0 0 1-6 6H30a25.635 25.635 0 0 0-12.449 2.962c-3.351 1.906-5.32 4.462-5.534 7.206H12V12.002a6 6 0 0 1 6-6Z"
      />
    </g>
  </svg>
)
export default BackgroundTypeArabic
