/* eslint-disable react/jsx-no-target-blank */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
    Avatar, Button, Checkbox,
    IconButton,
    MenuItem, Popover, TableRow,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Menu from '@mui/material/Menu';
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactQuill from "react-quill";
import EditIMG from "../../assets/edit";
import InfoIcon from "../../assets/infoIcon";
import NoDataFound from "../../assets/noData";
import { enumName, SemiBold, stringAvatar, timeZoneConverter } from "../../utils";
import { Bold } from "../../utils";

const StyledTableCell = styled(TableCell)(({ theme, headingColor, fontSize }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.typography.color.secondary,
        fontSize: fontSize ?? "0.75rem",
        fontFamily: SemiBold,
        border: 0,
        backgroundColor: headingColor ?? "#F2F4F7",
        padding: "8px"
    },

    [`&.${tableCellClasses.body}`]: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        // border: 0,
        whiteSpace: "normal",
        wordWrap: "break-word",
        backgroundColor: headingColor ?? "auto",
        padding: "8px",

    },
}));

const useStyles = makeStyles((theme) => ({
    arrow: {
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        fontSize:"1rem",
        color: "black",
    },
    button: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        margin: 10,
    },
    paginate: {
        "& .MuiButtonBase-root.MuiPaginationItem-root": {
            borderRadius: theme.palette.borderRadius,
            border: "none",
            color: "#606060",
        },
        "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
            color: "white",
            borderRadius: theme.palette.borderRadius,
            border: "none",
            backgroundColor: "#5078E1",
        },
    },
    pagination: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    typo: {
        fontSize:"0.75rem",
        color: "#4E5A6B",
        fontFamily: Bold,
        fontWeight: "bold",
        margin: 0,
        overflow: "hidden"
    },
    tabletopbtn: {
        fontWeight: "lighter",
        boxShadow: "none",
        marginLeft: "6px",
        backgroundColor: "#F8F8F8",
        border: "1px solid #E9E9E9",
        borderRadius: "6px",
        color: "black",

        "&:nth-child(1)": {
            textTransform: "capitalize",
        },
        "&:hover": {
            backgroundColor: "#F8F8F8",
            border: "1px solid #E9E9E9",
            borderRadius: "6px",
            boxShadow: "none",
        },
    },
    root: {
        whiteSpace: "noWrap",
        "& .MuiTableContainer-root": {
            marginTop: (props) => props?.marginTop,
            "&::-webkit-scrollbar": {
                height: 1,
                width: 2
            },
            "& table": {
                borderCollapse: "separate",
            },
        },
    },
    flexRow: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    change: {
        textDecoration: "underline",
        color: "#4D7EFF",
        cursor: "pointer",
        marginLeft: 70,
    },
    thead: {
        backgroundColor: "#F2F4F7",
        "& th:first-child": {
            borderRadius: (props) =>
                props.tableType === "normal" ? "4px 0px 0px 4px" : "4px 0 0 0",
        },
        "& th:last-child": {
            borderRadius: (props) =>
                props.tableType === "normal" ? "0 4px 4px 0" : "0 4px 0 0",
        },
    },
    tbody: (props) => ({
        height: "52px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#F1F7FF",
        },    // border: (props.tableType === "normal" ? 0 : "1px solid #E4E8EE"),
        backgroundColor: props?.cellBackground ? props?.cellBackground : (props.tableType === "normal" ? "#F2F4F7" : "white"),
        "& th": {
            borderBlock: props.tableType === "normal" ? 0 : "1px solid #E4E8EE",
        },
        "& th:first-child": {
            borderRadius: props.tableType === "normal" ? "4px 0px 0px 4px" : 0,
            borderLeft: props.tableType === "normal" ? 0 : props?.tableType === "no-side" ?
                0 : "1px solid #E4E8EE",
        },
        "& th:last-child": {
            borderRadius: props.tableType === "normal" ? "0 4px 4px 0" : 0,
            borderRight: props.tableType === "normal" ? 0 : props?.tableType === "no-side" ?
                0 : "1px solid #E4E8EE",
        },
    }),
    select: {
        color: 'red',
        "& .MuiSelect-select": {
            paddingBlock: 0,
            fontSize:"0.75rem",
            color: "#4E5A6B",
            fontFamily: Bold,
            fontWeight: "bold",
            height: 20,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
        },
    },
    noRecordDiv: {
        textAlign: "center",
        marginTop: 30,
    },
    menu: {
        "& .MuiPopover-paper": {
            boxShadow: "0 0px 8px -4px #f2f3f5",
            border: "1px solid #f2f3f5",
            borderRadius: "5px",
        },
    },
    active: {
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        color: "#5AC782",
    },
    inprogress: {
        padding: "4px 6px",
        backgroundColor: "#78B1FE",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
        color: "white",
        fontSize:"0.75rem",
        fontFamily: Bold,
    },

    activego: {
        padding: "4px 6px",
        backgroundColor: "#5AC782",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
        color: "white",
        fontSize:"0.75rem",
        fontFamily: Bold,
    },
    inactivego: {
        padding: "4px 6px",
        backgroundColor: "#CED3DD",
        borderRadius: theme.palette.borderRadius,
        display: "inline",
        color: "white",
        fontSize:"0.75rem",
        fontFamily: Bold,
    },
    inactive: {
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        color: "red",
    },
    more: {
        fontSize: "1.25rem",
        color: "gray",
        cursor: "pointer"
    },
    menuList: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 6px #0717411F",
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "white",

        }
    },
    menuItem: {
        borderBottom: "1px solid #E4E8EE",
        margin: "0px 4px",
        fontSize:"0.875rem",
        color: "#071741",
        fontFamily: SemiBold,
        '&:last-child': {
            border: 0,
        },
    },
    text: {
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        color: "#091B29",
        '& .ql-editor': {
            padding: '0px 0px 16px 0px',
            color: theme.typography.color.primary,
            fontSize:"0.875rem",
            fontFamily: SemiBold,
            textTransform: "capitalize",
        }
    },
    tooltip: {
        '&:hover': {
            backgroundColor: theme.typography.color.white
        }
    },
    qstatus: {
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        color: "#5AC782",
        textTransform: "capitalize"
    },
    infoIcon: {
        color: "#4E5A6B",
        fontSize: "1.25rem"
    },
    yet: {
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        color: "#FF9340",
        textTransform: "capitalize",

    },
    link: {
        textDecoration: "underline",
        color: "#5078E1",
        fontSize:"0.75rem",
        fontFamily: Bold
    }
}));

export const TableWithInfinityScroll = (props) => {

    const classes = useStyles(props);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [index, setIndex] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const modules = {
        toolbar: false,
    };


    const getComponentType = (val, data) => {

        const main = data.main;
        switch (data.type) {
            case "status": {
                if (data.value === "Approved") {
                    return <p style={{ color: "#267C24", margin: '0' }}>{data.value}</p>;
                } else if (data.value === "Rejected") {
                    return <p style={{ color: "red", margin: '0' }}>{data.value}</p>;
                } else if (data.value === "Pending") {
                    return <p style={{ color: "#78B1FE", margin: '0' }}>{data.value}</p>;
                } else if (data.value === "Lost Lead") {
                    return <p style={{ color: "red", margin: '0' }}>{data.value}</p>;
                } else if (data.value === "Lost Lead") {
                    return <p style={{ color: "red", margin: '0' }}>{data.value}</p>;
                } else if (data.value === "In Progress") {
                    return <p style={{ color: "#5AC782", margin: '0' }}>{data.value}</p>;
                } else if (data.value === "Converted") {
                    return <p style={{ color: "#78B1FE", margin: '0' }}>{data.value}</p>;
                } else if (data.value === "Cancelled") {
                    return <p style={{ color: "#98A0AC", margin: '0' }}>{data.value}</p>;
                } else if (data.value === "Quote Accepted") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "#5AC782",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data?.value === "open") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "#5AC782",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data.value === "close" || data?.value === "closed") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "#CED3DD",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data?.value === "Closed") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "red",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                                padding: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data?.value === "Open") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "#5AC782",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                                padding: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data.value === "requested for visit") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "#FF9340",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data.value === "requested for re-quote accepted") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "#FF9340",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data.value === "inProgress") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "#78B1FE",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data.value === "In Progress") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "#78B1FE",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data.value === "Active") {
                    return (
                        <Typography className={classes.active}>{data.value}</Typography>
                    );
                } else if (data.value === "Paid") {
                    return (
                        <Typography className={classes.active}>{data.value}</Typography>
                    );
                } else if (data.value === "Vacant") {
                    return (
                        <Typography className={classes.active}>{data.value}</Typography>
                    );
                } else if (data.value === "Occupied") {
                    return (
                        <Typography className={classes.inactive}>{data.value}</Typography>
                    );
                } else if (data.value === "Inactive") {
                    return (
                        <Typography className={classes.inactive}>{data.value}</Typography>
                    );
                } else if (data.value === "Unpaid") {
                    return (
                        <Typography className={classes.inactive}>{data.value}</Typography>
                    );
                } else if (data.value === "delete") {
                    return (
                        <div>
                            <IconButton onClick={() => props.handleIcon("delete", main)}>
                                <DeleteForeverIcon color="primary" />
                            </IconButton>
                        </div>
                    );
                } else if (data.value === true) {
                    return <Typography className={classes.active}>Active</Typography>;
                } else if (data.value === false) {
                    return <Typography className={classes.inactive}>Inactive</Typography>;
                } else {
                    return data.value;
                }
            }
            case "status2": {
                if (data?.value === "Closed") {
                    return (
                        <Typography
                            sx={{
                                color: "#000",
                                backgroundColor: "#F2F4F7",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                                padding: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else if (data?.value === "Open") {
                    return (
                        <Typography
                            sx={{
                                color: "white",
                                backgroundColor: "#5AC782",
                                fontSize:"0.75rem",
                                fontFamily: Bold,
                                textAlign: "center",
                                borderRadius: "4px",
                                padding: "4px",
                            }}
                        >
                            {data.value}
                        </Typography>
                    );
                } else {
                    return data.value;
                }
            }
            case "object_status": {
                if (data?.value.value === enumName.approved) {
                    return (<Typography sx={{
                        color: "#5AC782", fontSize:"0.875rem",
                        fontFamily: SemiBold,
                    }}>{data.value.label}</Typography>);
                } else if (data?.value.value === enumName.cancelled) {
                    return (<Typography sx={{
                        color: "#4E5A6B", fontSize:"0.875rem",
                        fontFamily: SemiBold,
                    }}>{data.value.label}</Typography>);
                } else if (data?.value.value === enumName.pending) {
                    return (<Typography sx={{
                        color: "#FF9340", fontSize:"0.875rem",
                        fontFamily: SemiBold,
                    }}>{data.value.label}</Typography>);
                } else if (data?.value.value === enumName.rejected) {
                    return (<Typography sx={{
                        color: "#FF4B4B", fontSize:"0.875rem",
                        fontFamily: SemiBold,
                    }}>{data.value.label}</Typography>);
                } else {
                    return data.value;
                }
            }
            case "agreement_inspection_status": {
                return (
                    <Box>
                        {data.value === "Yet To Verify" ? (
                            <Box flexGrow={1} display="flex" alignItems="center">
                                <Box>
                                    <Typography className={classes.yet}>{data.value}</Typography>
                                </Box>
                            </Box>
                        ) : data.value === "Pending" ?
                            <p style={{ color: "#78B1FE", margin: "0" }}>{data.value}</p>
                            : data.value === "Yet to assign" || data.value === "Yet to Assign Inspector" ? (
                                <Box flexGrow={1} display="flex" alignItems="center">
                                    <Box>
                                        <Typography className={classes.yet}>{data.value}</Typography>
                                    </Box>
                                </Box>
                            ) : (
                                <Typography
                                    className={
                                        data.value === "Assigned"
                                            ? classes.assignstatus :
                                            data.value === "Rejected" ?
                                                classes.rejectedstatus
                                                : classes.completeStatus
                                    }
                                >
                                    {data.value}
                                </Typography>
                            )}
                    </Box>
                );
            }
            case "aminities_booking_status": {
                if (data.value === "Pending") {
                    return <p style={{ color: "#78B1FE", margin: "0" }}>{data.value}</p>;
                } else if (data.value === "Unpaid" || data.value === "Cancelled") {
                    return <p style={{ color: "#FF9340", margin: "0", textTransform: 'capitalize' }}>{data.value}</p>;
                } else if (data.value === "Approved" || data.value === "Booked" || data.value === "no-due") {
                    return <p style={{ color: "#5AC782", margin: "0", textTransform: 'capitalize' }}>{data.value}</p>;
                } else if (data.value === "Paid") {
                    return <p style={{ color: "#5AC782", margin: "0" }}>{data.value}</p>;
                } else if (data.value === "Decline") {
                    return <p style={{ color: "#FF4B4B", margin: "0" }}>{data.value}</p>;
                } else if (data.value === "Invoice conversion pending" || data.value === "due") {
                    return <p style={{ color: "#FF9340", margin: "0", textTransform: 'capitalize' }}>{data.value}</p>;
                } else {
                    return <p style={{ margin: "0" }}>{data.value}</p>;
                }
            }
            case "icon": {
                let editviewtoggle = (
                    <div style={{ display: "flex" }}>
                        <IconButton onClick={() => props.handleIcon("edit", main)}>
                            <EditIMG color="#98a0ac" />
                        </IconButton>
                        <IconButton onClick={() => props.handleIcon("view", main)}>
                            <RemoveRedEyeOutlinedIcon
                                sx={{ fontSize:"1rem", color: "#98A0AC" }}
                            />
                        </IconButton>

                        <Switch
                            checked={data.is_active}
                            onChange={(e) =>
                                props.handleIcon("toggle", main, e.target.checked)
                            }
                        />
                    </div>
                );
                let editview = (
                    <div style={{ display: "flex" }}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                props.handleIcon("edit", main);
                            }}
                        >
                            <EditIMG color="#98a0ac" />
                        </IconButton>
                        <IconButton onClick={() => props.handleIcon("view", main)}>
                            <RemoveRedEyeOutlinedIcon
                                sx={{ fontSize:"1rem", color: "#98A0AC" }}
                            />
                        </IconButton>
                    </div>
                );
                let deleteIcon = (
                    <div style={{ display: "flex" }}>
                        <IconButton onClick={() => props.handleIcon("delete", main)}>
                            <DeleteOutlineIcon sx={{ fontSize:"1rem", color: "#98A0AC" }} />
                        </IconButton>
                    </div>
                );
                let viewdelete = (
                    <div style={{ display: "flex" }}>
                        <IconButton onClick={() => props.handleIcon("edit", main)}>
                            <EditIMG color="#98a0ac" />
                        </IconButton>
                        <IconButton onClick={() => props.handleIcon("delete", main)}>
                            <DeleteOutlineIcon sx={{ fontSize:"1rem", color: "#98A0AC" }} />
                        </IconButton>
                    </div>
                );
                let edittoggle = (
                    <div style={{ display: "flex" }}>
                        <IconButton
                            onClick={() => props.handleIcon("edit", main, props?.edit)}
                        >
                            <EditIMG color="#98a0ac" />
                        </IconButton>
                        <Switch
                            checked={main.is_active}
                            onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
                        />
                    </div>
                );
                let viewedittoggle = (
                    <div style={{ display: "flex" }}>
                        <IconButton
                            onClick={() => props.handleIcon("edit", main, props?.edit)}
                        >
                            <EditIMG color="#98a0ac" />
                        </IconButton>
                        <IconButton
                            onClick={() => props.handleIcon("view", main, props?.view)}
                        >
                            <RemoveRedEyeOutlinedIcon
                                sx={{ fontSize:"1rem", color: "#98A0AC" }}
                            />
                        </IconButton>
                        <Switch
                            checked={main.is_active}
                            onChange={(e) => props.handleIcon("toggle", main, props?.edit)}
                        />
                    </div>
                );

                if (data.icon === "editviewtoggle") {
                    return editviewtoggle;
                }
                if (data.icon === "deleteIcon") {
                    return deleteIcon;
                }
                if (data.icon === "viewedittoggle") {
                    return viewedittoggle;
                }
                if (data.icon === "edittoggle") {
                    return edittoggle;
                }
                if (data.icon === "viewdelete") {
                    return viewdelete;
                }
                if (data.icon === "editview") {
                    return editview;
                } else if (data.icon === "edit") {
                    return (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                props.handleIcon("edit", main, props?.edit);
                            }}
                        >
                            <EditIMG color="#98a0ac" />
                        </IconButton>
                    );
                } else if (data.icon === "view") {
                    return (
                        <IconButton onClick={() => props.handleIcon("view", main)}>
                            <RemoveRedEyeOutlinedIcon
                                sx={{ fontSize:"1rem", color: "#98A0AC" }}
                            />
                        </IconButton>
                    );
                } else if (data.icon === "arrow") {
                    return (
                        <div style={{ display: "flex" }}>
                            <IconButton size="small" className={classes.arrow}>
                                <ArrowForwardIosIcon
                                    size="small"
                                    style={{ fontSize:"1rem" }}
                                />
                            </IconButton>
                        </div>
                    );
                }

                break;
            }
            case "leadEdit": {
                return (
                    <>
                        <IconButton onClick={() => props.handleIcon("view", main)}>
                            <RemoveRedEyeOutlinedIcon
                                sx={{ fontSize:"1rem", color: "#98A0AC" }}
                            />
                        </IconButton>
                    </>
                );
            }
            case "propertyType": {
                return (
                    <>
                        {data.value && (
                            <Typography
                                sx={{
                                    color: "white",
                                    backgroundColor: "#78B1FE",
                                    fontSize:"0.75rem",
                                    fontFamily: Bold,
                                    textAlign: "center",
                                    borderRadius: "4px",
                                    display: "inline-block",
                                }}
                            >
                                &nbsp;&nbsp;&nbsp;{data.value}&nbsp;&nbsp;&nbsp;
                            </Typography>
                        )}
                    </>
                );
            }
            case "blockType": {
                return (
                    <>
                        {data.value && (
                            <Typography
                                sx={{
                                    color: "white",
                                    backgroundColor: "#78B1FE",
                                    fontSize:"0.75rem",
                                    fontFamily: Bold,
                                    textAlign: "center",
                                    borderRadius: "4px",
                                    display: "inline-block",
                                }}
                            >
                                &nbsp;&nbsp;&nbsp;{data.value}&nbsp;&nbsp;&nbsp;
                            </Typography>
                        )}
                    </>
                );
            }
            case "checkBox": {
                return (
                    <Checkbox
                        sx={{ padding: "0px" }}
                        onChange={(e) =>
                            props.handleIcon("checkBox", main, e?.target?.checked)
                        }
                        color="success"
                        checked={data.value}
                        checkedIcon={<CheckCircleIcon />}
                        icon={<CircleOutlinedIcon style={{ color: "#c1c1c1" }} />}
                    />
                );
            }
            case "tooltip": {
                return (
                    <>
                        <Button
                            aria-describedby={props?.id}
                            className={classes.tooltip}
                            onClick={(e) => props?.handleHover(e, main)}
                        // onMouseLeave={props?.handleClose}
                        >
                            {data.value}
                        </Button>
                        <Popover
                            className={classes.menu}
                            style={{ boxShadow: "none !important" }}
                            id={props?.id}
                            open={props?.open}
                            anchorEl={props?.anchorEl}
                            onClose={props?.handleClose}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            {props?.roles?.map((val) => {
                                return <Typography sx={{ p: 1 }}>{val?.name}</Typography>;
                            })}
                        </Popover>
                    </>
                );
            }
            case "avatar": {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar src={data.image} sx={{ width: 34, height: 34 }} />
                        <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
                    </div>
                );
            }
            case "avatarText": {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar src={data.value.image}
                            sx={{ width: 34, height: 34, lineHeight: 0, fontSize:"0.875rem" }}>
                            {data?.value?.name ? data?.value?.name?.charAt(0) : ""}
                        </Avatar>
                        <Typography style={{ marginLeft: 10 }}>{data.value.name}</Typography>
                    </div>
                );
            }
            case "avatarmanagement": {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar src={data.value} sx={{ width: 34, height: 34 }} />
                    </div>
                );
            }
            case "text": {
                return (
                    <Typography
                        style={{
                            whiteSpace: "nowrap",
                        }}
                        className={classes.text}
                    >
                        {data.value ?? "-"}
                    </Typography>
                );
            }

            case "textObject": {
                return (
                    <Typography
                        style={{
                            whiteSpace: "nowrap",
                        }}
                        className={classes.text}
                    >
                        {data.value.label}
                    </Typography>
                );
            }
            case "textLink": {
                return (
                    <div
                        onClick={() => props?.onUnitClick(main, data)}
                        style={{ textDecorationLine: "underline", color: "blue" }}
                    >
                        {data.value}
                    </div>
                );
            }
            case "increment": {
                return data.index + 1;
            }
            case "image": {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                        }}
                    >
                        <img src={data.image} alt={data.name}></img>
                        <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
                        {/* <Typography className={classes.change}>Change</Typography> */}
                    </div>
                );
            }
            case "PFImage": {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                            src={data.value !== "" ? data.value : "/images/pf.svg"}
                            sx={{ width: 34, height: 34 }}
                        />
                    </div>
                );
            }
            case "logo": {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                            src={data?.value?.logo}
                            sx={{ width: 34, height: 34, lineHeight: 0, fontSize:"0.875rem" }}
                        >
                            {data?.value?.name ? data?.value?.name?.charAt(0) : ""}
                        </Avatar>
                    </div>
                );
            }
            case "link": {
                return (
                    <Typography
                        className={classes.link}
                        onClick={() => props?.handleLink(data)}
                    >
                        {main.url}
                    </Typography>
                );
            }
            case "date": {
                return (
                    <Typography
                        style={{
                            whiteSpace: "nowrap",
                        }}
                        className={classes.text}
                    >
                        &#x202a;{data.value ? timeZoneConverter(data.value) : "-"}&#x202c;
                    </Typography>
                );
            }
            case "description": {
                return (
                    <div>
                        <Typography
                            noWrap
                            readOnly
                            className={classes.text}
                            style={{
                                wordBreak: "break-all",
                                overflowWrap: "anywhere",
                            }}
                        >
                            {data?.value?.length > 0
                                ? `${data?.value?.substring(0, 15)}${data?.value?.length > 15 ? "..." : ""
                                } `
                                : "-"}
                        </Typography>
                    </div>
                );
            }
            case "quill": {
                return (
                    <div>
                        <ReactQuill
                            noWrap
                            readOnly
                            theme="bubble"
                            // value={data?.value ?? ""}
                            value={
                                data?.value?.length > 0
                                    ? `${data?.value?.substring(0, 100)}${data?.value?.length > 10 ? "..." : ""
                                    } `
                                    : "-"
                            }
                            modules={modules}
                            className={classes.text}
                            style={{
                                wordBreak: "break-all",
                                overflowWrap: "anywhere",
                            }}
                        />
                    </div>
                );
            }
            case "redirect": {
                return (
                    <a target="_blank" href={data.value}>
                        {data.value}
                    </a>
                );
            }
            case "object": {
                return data.value[data?.objectOption] ?? "-";
            }
            case "phone": {
                return (
                    <>
                        {main.code} {main.mobile}
                    </>
                );
            }
            case "costing": {
                return (
                    <div>
                        <IconButton
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setIndex(data.index);
                            }}
                        >
                            <MoreVertIcon className={classes.more} />
                        </IconButton>

                        {index === data?.index && (
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                className={classes.menuList}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("edit", main);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Edit
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("delete", main, main.status);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Delete
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("send", main, main.delete);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Send to customer
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("create", main, main.delete);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Create Invoice
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                );
            }
            case "more": {
                return (
                    <div>
                        <IconButton
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setIndex(data.index);
                            }}
                        >
                            <MoreVertIcon className={classes.more} />
                        </IconButton>

                        {index === data?.index && (
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                className={classes.menuList}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("edit", main);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Edit
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("active", main, main.status);
                                    }}
                                    className={classes.menuItem}
                                >
                                    {main.status === "Active" ? "In-active" : "Active"}
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("delete", main, main.delete);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Delete
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                );
            }
            case "more_2": {
                return (
                    <div>
                        <IconButton
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setIndex(data.index);
                            }}
                        >
                            <MoreVertIcon className={classes.more} />
                        </IconButton>

                        {index === data?.index && (
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                className={classes.menuList}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("edit", main);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Edit
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("delete", main);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Delete
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                );
            }
            case "more_3": {
                return (
                    <div>
                        <IconButton
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setIndex(data.index);
                            }}
                        >
                            <MoreVertIcon className={classes.more} />
                        </IconButton>

                        {index === data?.index && (
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                className={classes.menuList}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("active", main, main.status);
                                    }}
                                    className={classes.menuItem}
                                >
                                    {main.status === "Active" ? "In-active" : "Active"}
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("delete", main, main.delete);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Delete
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                );
            }
            case "more_4": {
                return (
                    <div>
                        <IconButton
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setIndex(data.index);
                            }}
                        >
                            <MoreVertIcon className={classes.more} />
                        </IconButton>
                        {index === data?.index && (
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                className={classes.menuList}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("edit", main);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Edit
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        props.handleIcon("active", main, main.status);
                                    }}
                                    className={classes.menuItem}
                                >
                                    {main.status === "Active" ? "In-active" : "Active"}
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                );
            }
            case "more_5": {
                return (
                    <div>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setAnchorEl(e.currentTarget);
                                setIndex(data.index);
                            }}
                        >
                            <MoreVertIcon className={classes.more} />
                        </IconButton>

                        {index === data?.index && (
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                className={classes.menuList}
                            >
                                {" "}
                                <MenuItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClose();
                                        props.handleIcon("edit", main);
                                    }}
                                    className={classes.menuItem}
                                >
                                    Edit
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                );
            }
            case "error_icon": {
                return (
                    <div style={{ display: "flex", alignItems: "center", transform: "rotate(180deg)" }}>
                        {main.iconToolTip && <InfoIcon fill="#FF4B4B" hoverFill="#FF4B4B" info={main.iconToolTip} />}
                    </div>
                    // {main?.iconToolTip && <LightTooltip title={main.iconToolTip} placement="top">
                    //    {/* <ErrorOutlineIcon /> */}
                    //    <InfoIcon fill="#FF4B4B" />
                    //  </LightTooltip>}
                );
            }
            case "block": {
                return (
                    <div
                        onClick={() => props?.onUnitClick(main, "block")}
                        style={{ textDecorationLine: "underline", color: "blue" }}
                    >
                        {data.value}
                    </div>
                );
            }
            case "unit": {
                return (
                    <div
                        onClick={() => props?.onUnitClick(main, "unit")}
                        style={{ textDecorationLine: "underline", color: "blue" }}
                    >
                        {data.value}
                    </div>
                );
            }
            case "floor": {
                return (
                    <div
                        onClick={() => props?.onUnitClick(main, "floor")}
                        style={{ textDecorationLine: "underline", color: "blue" }}
                    >
                        {data.value}
                    </div>
                );
            }
            case "active": {
                return (
                    <>
                        {data.value ? (
                            <Typography className={classes.activego}>Active</Typography>
                        ) : (
                            <Typography className={classes.inactivego}>Active</Typography>
                        )}
                    </>
                );
            }
            case "info": {
                return (
                    <IconButton onClick={() => props.handleIcon("info", main)}>
                        <InfoOutlinedIcon className={classes.infoIcon} />
                    </IconButton>
                );
            }
            case "payment_status": {
                return (
                    <>
                        {data.value === "Close" ? (
                            <Typography className={classes.inactivego}>Close</Typography>
                        ) : (
                            <Typography className={classes.inprogress}>
                                In Progress
                            </Typography>
                        )}
                    </>
                );
            }
            case "q_status": {
                return (
                    <>
                        {<Typography className={classes.qstatus}>{data.value}</Typography>}
                    </>
                );
            }
            case "inspection_status": {
                return (
                    <Box>
                        {
                            data.value === "Pending" ?
                                <Box flexGrow={1} display="flex" alignItems="center">
                                    <Box>
                                        <Typography className={classes.yet}>{data.value}</Typography>
                                    </Box>
                                    <Box marginLeft="30px">
                                        <Button sx={{ boxShadow: "none" }} onClick={() => props.handleIcon("assign", data)} variant="contained">Assign</Button>
                                    </Box>
                                </Box>
                                :
                                <Typography className={classes.qstatus}>{data.value}</Typography>
                        }

                    </Box>
                )
            }
            case "inputBox": {
                return (
                    <Checkbox checked={val?.is_active} onChange={(e) => props.handleIcon("checkBox", data, e.target.checked)} />
                )
            }
            case "avatarTextDashboard": {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar src={data?.value ?? ""} sx={{ width: 34, height: 34, lineHeight: 0, fontSize: "1.6rem !important" }} {...stringAvatar(main?.name)} />
                    </div>
                );
            }
            default: {
                return data.value ?? "-";
            }
        }
    };


    return (

        <div className={classes.root}>
            {props?.rows?.length === 0 ? (
                <div className={classes.noRecordDiv} style={{
                    minHeight: props?.height && props?.height,
                    maxHeight: props?.height && props?.height,
                }}>
                    {props?.noDataSvg ?
                        <NoDataFound />
                        :
                        <p className={classes.typo}>{props?.msg}</p>
                    }
                </div>
            ) : (
                <>

                    <TableContainer
                        sx={{
                            minHeight: props?.infinityheight && props?.infinityheight,
                            maxHeight: props?.infinityheight && props?.infinityheight,
                            mt: 2,
                            ...props?.table_div_style
                        }}
                    >
                        <InfiniteScroll
                            dataLength={props?.rows?.length ?? ""}
                            next={props?.fetchMoreData}
                            hasMore={true}
                            height={props?.height - 50}

                        >
                            {/* Table Component */}
                            <Table stickyHeader aria-label="customized table" >
                                {/* heading */}
                                {/* rows */}
                                <TableHead className={classes.thead}>
                                    {props?.heading?.length > 0 && (
                                        <TableRow>
                                            {props?.heading?.map((data, index) => {
                                                return (
                                                    <StyledTableCell headingColor={props?.headingColor} size="small" key={index}>
                                                        {data.title}
                                                    </StyledTableCell>
                                                );
                                            })}
                                        </TableRow>
                                    )}
                                </TableHead>
                                <TableBody>
                                    {props?.rows?.length > 0 ?
                                        <>
                                            {props?.rows?.map((row, index) => {
                                                return (
                                                    <>
                                                        <TableRow
                                                            key={index + 1}
                                                            className={classes.tbody}
                                                            style={{
                                                                backgroundColor:
                                                                    row?.status && props?.isColor === true ? "#F5F7FA" : "auto",
                                                            }}
                                                            onClick={() =>
                                                                props?.onClick && props?.onClick(row)
                                                            }
                                                        >
                                                            {props?.dataType?.map((val) => {
                                                                return (
                                                                    <StyledTableCell
                                                                        stickyHeader={true}
                                                                        headingColor={props?.cellColor}
                                                                        component="th"
                                                                        scope="row"

                                                                        onClick={() => {
                                                                            if (
                                                                                val.type[0] === "more" ||
                                                                                val.type[0] === "more_2" ||
                                                                                val.type[0] === "unit" ||
                                                                                val.type[0] === "block" ||
                                                                                val.type[0] === "textLink" ||
                                                                                val.type[0] === "floor" ||
                                                                                val.type[0] === "more_3" ||
                                                                                val?.type[0] === "info" ||
                                                                                val?.type[0] === "checkBox" ||
                                                                                val.type[0] === "more_4" ||
                                                                                val?.type[0] === "inspection_status" ||
                                                                                val?.type[0] === "inputBox"

                                                                            ) {
                                                                            } else {
                                                                                props.handleIcon &&
                                                                                    props.handleIcon("view", row);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {val.type.map((type) => {
                                                                            return getComponentType(row, {
                                                                                index: index,
                                                                                type: type,
                                                                                main: row,
                                                                                value: row[val.name],
                                                                                image: row[val.imagekey],
                                                                                icon: row[val.icon],
                                                                                is_active: row[val.is_active],
                                                                                objectOption: val?.label ?? "label",
                                                                            });
                                                                        })}
                                                                    </StyledTableCell>
                                                                );
                                                            })}
                                                        </TableRow>

                                                    </>
                                                );
                                            })}
                                        </>
                                        :
                                        <NoDataFound />
                                    }

                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </TableContainer>

                </>
            )}
        </div>
    );
};

TableWithInfinityScroll.defaultProps = {
    handleIcon: () => { },
};

