import React from "react"

export const ApproveRequestIcon = () =>(
<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
  <g id="Group_113345" data-name="Group 113345" transform="translate(16828 14726)">
    <circle id="Ellipse_129691" data-name="Ellipse 129691" cx="18" cy="18" r="18" transform="translate(-16828 -14726)" fill="#5ac782"/>
    <path id="Path_100252" data-name="Path 100252" d="M-14659.8,16461.566l4.827,5.729,9.178-10.9" transform="translate(-2157.199 -31169.846)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75"/>
  </g>
</svg>


)