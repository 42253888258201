import { Typography, Box, Grid, Dialog } from "@mui/material";
import React from "react";
import { reservationStyle } from "./style";
import UnitCard from "../../propertyFinder3/component/unitCard";
import { UnitSingleDetails } from "../../quotationDetails/components";
export const UnitDetails = ({ t, list = [] }) => {
  const classes = reservationStyle();

  const [selectedUnit, setSelectedUnit] = React.useState("");
  const [openUnitDetails, setOpenUnitDetails] = React.useState(false);

  //on click unit card
  const onClickUnit = (unit_id) => {
    setSelectedUnit(unit_id);
    setOpenUnitDetails(true);
  };
  return (
    <div className={classes.unitList}>
      <Typography className={classes.unitTitle}>{t("Unit_Details")}</Typography>
      <Box height={"16px"} />
      <Box>
        <Grid container spacing={2}>
          {list?.map((val, index) => {
            return (
              <Grid item xs={6}>
                <UnitCard
                  data={val}
                  noBtn
                  delete={true}
                  currency={val?.symbol}
                  onClick={onClickUnit}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {/*unit details dialog*/}
      <Dialog
        className={classes.dialog}
        maxWidth="xs"
        fullWidth
        open={openUnitDetails}
        onClose={() => setOpenUnitDetails(false)}
      >
        <UnitSingleDetails
          shortlist
          selectedUnits={selectedUnit}
          onClose={() => setOpenUnitDetails(false)}
          t={t}

        />
      </Dialog>
    </div>
  );
};
