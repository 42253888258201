import ArrowLeftTwoToneIcon from '@mui/icons-material/ArrowLeftTwoTone'
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { eachHourOfInterval } from 'date-fns'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import TaskSVG from '../../../assets/task'
import { JobType, stringAvatar, SwitchColorsInspection, SwitchInspectionRoutes, timeZoneConverter } from '../../../utils'
import { filterByReference } from "../../../utils/resourceBoard"
import { CalProgressbar } from './calProgressbar'
import { DayCalendarStyles } from './style'

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'transparent',
        maxWidth: "100%",

    },
}));

export const DayCalendar = ({ progress = 0, list = [], selectedCompany = {}, datas = {}, daycal = {}, t }) => {
    const classes = DayCalendarStyles();
    const history = useHistory()
    const [data, setDate] = React.useState([]);
    const [percentage, setPercentage] = React.useState(0)

    const result = eachHourOfInterval({
        start: new Date(2022, 7, 17, 1),
        end: new Date(2022, 7, 17, 23)
    })


    React.useEffect(() => {
        const resultDate = filterByReference(result, list)
     
        setDate(resultDate)
        const percentageResult = datas?.progress?.filter((val) => moment(val?.execution).format("DD MMM YYYY") === moment(daycal?.Date).format("DD MMM YYYY"))
        setPercentage(percentageResult?.[0]?.percentage ?? 0)
        // eslint-disable-next-line
    }, [list])

    //go requset list
    const requestList = (val) => {
        const RoutePath = SwitchInspectionRoutes(val?.request_type)
        history.push({
            pathname: RoutePath,
            state: {
                company: selectedCompany,
                id: val?.requset_id,
                title: val?.reference_id,
                type: val?.request_type,
                agreement_id: val?.agreement_id,
                request_id: val?.requset_id,
                reference_id: val?.reference_id,
                agreement_inspection_id: val?.agreement_insection_id
            }
        })

    }



    return (
        <>
            <Stack>
                <Grid container direction={"row"}>
                    <Grid item md={1} className={classes.header_timecell}>
                        <Box>
                            {t("LOAD")}
                        </Box>
                    </Grid>
                    <Grid item md={11} className={classes.header_eventCell}>
                        <CalProgressbar value={percentage} />
                    </Grid>
                </Grid>

                <Box className={classes.calRow}>
                    {
                        data?.map(e => {
                            return (
                                <>
                                    <Grid container>
                                        <Grid item md={1} className={classes.timeCell}>
                                            <Box marginTop="20px">
                                                {moment(e).format("HH:SS")}
                                            </Box>
                                        </Grid>
                                        <Grid item md={11} className={classes.eventCell} p={1}>
                                            {

                                                list?.filter((item) => (
                                                    moment(item?.date).format('HH') <= moment(e).format('HH'))
                                                    &&
                                                    (moment(item?.date).format('HH') >= moment(e).format('HH')))?.map((r) => {
                                                        return (
                                                            <Box>
                                                                <Box m={0.4}>

                                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}
                                                                        onClick={() => requestList(r)}
                                                                        sx={{
                                                                            cursor: "pointer",
                                                                            backgroundColor: SwitchColorsInspection(r?.request_type)?.backgroundColor,
                                                                            color: SwitchColorsInspection(r?.request_type)?.color
                                                                        }} p={0.7}>
                                                                        <HtmlTooltip

                                                                            title={
                                                                                <React.Fragment>
                                                                                    <CustomToolTip data={r} t={t} />
                                                                                </React.Fragment>
                                                                            }
                                                                            placement='right-start'

                                                                        >
                                                                            <Box display="flex" alignItems="center">
                                                                                <Typography className={classes.event_label}>{r?.reference_id}</Typography>
                                                                                <Box margin="0px 12px" className={classes.dot} sx={{ backgroundColor: SwitchColorsInspection(r?.request_type)?.color}}></Box>
                                                                                <Typography className={classes.event_label}> {JobType(r?.request_type)} </Typography>

                                                                                {
                                                                                    (r?.date) &&
                                                                                    <>
                                                                                        <Box margin="0px 12px" className={classes.dot} sx={{ backgroundColor: SwitchColorsInspection(r?.request_type)?.color}}></Box>
                                                                                        <Typography className={classes.event_label}> {timeZoneConverter(r?.end_date_time, 'DD-MM-YYYY')}</Typography>
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    (r?.eta) &&
                                                                                    <>
                                                                                        <Box margin="0px 12px" className={classes.dot} sx={{ backgroundColor: SwitchColorsInspection(r?.request_type)?.color}}></Box>
                                                                                        <Typography className={classes.event_label}> {r?.eta} {t("Hours")}</Typography>
                                                                                    </>
                                                                                }
                                                                                <Box margin="0px 12px" className={classes.dot} sx={{ backgroundColor: SwitchColorsInspection(r?.request_type)?.color}}></Box>
                                                                                <Typography className={classes.event_label}> {r?.unit_name} </Typography>
                                                                            </Box>
                                                                        </HtmlTooltip>
                                                                    </Stack>

                                                                </Box>
                                                                {/* {
                                                                v?.events?.length > 2 &&
                                                                <Box>
                                                                    <Box m={0.4}>
                                                                        <HtmlTooltip
                                                                            title={v?.events[0]?.module === "General Inspection" ?
                                                                                <React.Fragment>
                                                                                    <CustomToolTip />
                                                                                </React.Fragment> : ""
                                                                            }
                                                                            arrow
                                                                        >
                                                                            <Stack direction={"row"} spacing={2} alignItems={"center"}
                                                                                sx={{
                                                                                    backgroundColor: v?.events[0]?.status === "Meter Reading" ? "#FFFAD880" : v?.events[0]?.status === "Move In" ? "#FEEAEA80" : "#DBF0F180",
                                                                                    color: v?.events[0]?.status === "Meter Reading" ? "#B3A16D" : v?.events[0]?.status === "Move In" ? "#B3776D" : "#6DAFB3"
                                                                                }} p={0.7}>
                                                                                <Typography className={classes.event_label}> {v?.events[0]?.ins} , {v?.events[0]?.status} </Typography>
                                                                                <Box className={classes.dot}
                                                                                    sx={{ backgroundColor: v?.events[0]?.status === "Meter Reading" ? "#B3A16D" : v?.events[0]?.status === "Move In" ? "#B3776D" : "#6DAFB3" }}></Box>
                                                                                <Typography className={classes.event_label}> {v?.events[0]?.module} </Typography>
                                                                            </Stack>
                                                                        </HtmlTooltip>
                                                                    </Box>
                                                                    <Typography className={classes.moreLabel}> +{v?.events?.length - 1} more </Typography>
                                                                </Box>
                                                            } */}
                                                            </Box>
                                                        )
                                                    })

                                            }

                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })
                    }
                </Box>

            </Stack>
        </>
    )
}

const CustomToolTip = ({ data = {}, t }) => {
    const classes = DayCalendarStyles()
    return (
        <Stack spacing={2} padding={"0px 12px 12px 12px"} className={classes.toolRoot}>
            <ArrowLeftTwoToneIcon className={classes.arrow} />
            <Box marginTop="-10px">
                <Stack direction={"row"} >
                    <TaskSVG className={classes.ttavatar} />
                    <Stack marginInlineStart={"8px"}>
                        <Typography className={classes.ttTitle}> {data?.reference_id} </Typography>
                        <Typography className={classes.ttsecTitle}> {data?.request_type} </Typography>
                        <Typography className={classes.ttassigned_by}>{t("Assign By")} {data?.manager} </Typography>
                    </Stack>
                </Stack>
            </Box>
            <Stack direction={"row"} >
                <Avatar className={classes.ttavatar} src={data?.unit_image} {...stringAvatar(data?.unit_name)}></Avatar>
                <Stack marginInlineStart={"8px"}>
                    <Typography className={classes.ttname}> {data?.unit_name} </Typography>
                    <Typography className={classes.ttassigned_by}> {data?.unit_no} </Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}