import React from "react";
import { componentType, LightTooltip } from "./style";
import { Box, Radio, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const ComponentType = (props) => {
  // classes
  const classes = componentType(props);
  const { t = () => false } = props
  //   props
  const { data, handleChange, info, sub, borderColor } = props;
  //   state

  return (
    <Box className={classes.root}>
      {/* radio button */}
      <Box display="flex" alignItems="center">
        <Radio
          checked={borderColor}
          onChange={(e) => handleChange(e?.target?.checked, data)}
          name="radio-buttons"
          sx={{
            height: "30px",
            width: "30px",
            color: props?.bordercolor,
            "&.Mui-checked": {
              color: props?.bordercolor,
            },
          }}
        />
        <div>
          {/* component name */}
          {props?.isappraisal ? <Typography className={classes.isappraisalTitle}>{t(data?.name)}</Typography> : <Typography className={classes.title}>{t(data?.name)}</Typography>}
          

          {/* subtitle */}
          {sub && <Typography className={classes.sub}>{data?.sub}</Typography>}
        </div>
      </Box>

      {/* info icon */}
      {info && (
        <LightTooltip title={t(data?.tooltip)} placement="top">
          <InfoOutlinedIcon className={classes.infoIcon} />
        </LightTooltip>
      )}
    </Box>
  ); 
};
