export const LandLords = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 8128">
            <g data-name="Group 95517">
                <path
                    data-name="icons8-landlord (2)"
                    d="M14.857 4a4.286 4.286 0 1 0 4.286 4.286A4.291 4.291 0 0 0 14.857 4ZM9.705 14.286A1.707 1.707 0 0 0 8 15.991v.652a3.939 3.939 0 0 0 2.274 3.457 9.664 9.664 0 0 0 4.583 1.045 9.273 9.273 0 0 0 5.09-1.358c.018-.02.031-.041.049-.06l.084-.084-.119-.121a1.369 1.369 0 0 1 .022-1.939l.084-.082a1.727 1.727 0 0 1-.5-1.205v-.616c-.042-.034-.082-.07-.122-.105a3.563 3.563 0 0 1-.275-.271l-.013-.013a3.432 3.432 0 0 1-.625-1Z"
                    transform="translate(-3.424 -1)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="icons8-landlord (2)"
                    d="M21.714 10.434a2.569 2.569 0 0 0-1.286 4.795v1.065a.857.857 0 0 0 .251.606l.606.606-.7.688a.514.514 0 0 0-.008.727l.712.727-.683.683a.557.557 0 0 0-.006.782l.686.706a.6.6 0 0 0 .862 0l.614-.634a.858.858 0 0 0 .242-.6v-5.357a2.569 2.569 0 0 0-1.286-4.795Zm0 1.286a.857.857 0 1 1-.857.857.86.86 0 0 1 .857-.858Z"
                    transform="translate(-3.424 -1)"
                    style={{
                        fill: props?.fill ?? "#4e5a6b",
                    }}
                />
            </g>
        </g>
    </svg>
)
