import { Box, Button, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from "@mui/material"
import React from "react"
import { useHistory } from "react-router-dom"
import { TextBox } from "../../../components"
import { Routes } from "../../../router/routes"
import { useStyles } from "../style"


export const ViewFeedBackForm = ({ t, data = {}, tabValue = 1, closeViewDialog = () => false, setIsPublish = () => false }) => {
    const classes = useStyles()
    const history = useHistory()
    //editForm
    const editForm = () => {
        history.push({
            pathname: Routes?.formBuilder,
            state: {
                is_edit: true,
                data: data
            }
        })
    }
    //Publish Form
    const publishForm = () => {
        setIsPublish(true)
        closeViewDialog()
    }
    return (
        <Box>
            <Box className={classes.formCategory}><Typography className={classes.questiontxt}>{data?.feedback_master?.[0].type?.[0]?.name}</Typography></Box>
            <Box className={classes.question_bank} >
                <Box height="420px" overflow={"overlay"}>

                    {data?.feedback_elements?.length > 0 && data?.feedback_elements?.map((val, i) => {
                        return (
                            <Box>
                                <Box mt={1}>
                                    <Typography className={classes.viewQuestionhdr}>{`${val.order}.${val?.title}`}</Typography>
                                </Box>
                                <Box position={"relative"}>
                                    {val.type === "text" &&
                                        <Box mb={2} mt={1}>
                                            <TextBox value={val?.answer} isReadonly={true} label="" />
                                        </Box>
                                    }
                                    {val?.type === "check_box" &&
                                        <Box mb={1}>
                                            {val.data_type_options?.map((x) => {
                                                return (
                                                    <Box>
                                                        <FormControlLabel
                                                            // value="mandatory"
                                                            control={<Checkbox size="small" checked={x.value === val.answer ? true : false} />}
                                                            label={x.label}
                                                            labelPlacement="end"
                                                        />
                                                    </Box>
                                                )
                                            })}

                                        </Box>
                                    }
                                    {val?.type === "boolean" &&
                                        <Box mb={1}>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue={val.answer}
                                                name="radio-buttons-group"
                                            >
                                                {val.data_type_options?.map((x) => {
                                                    return (
                                                        <FormControlLabel control={<Radio size={"small"} checked={x.value === val.answer ? true : false} />} label={x.label} />
                                                    )
                                                })}
                                            </RadioGroup>
                                        </Box>
                                    }
                                    {val?.type === "rating" &&
                                        <Stack mt={1.5} mb={1.5} direction="row" spacing={1} alignItems="center">

                                            {val.data_type_options?.map((x) => {
                                                return (
                                                    <Box className={val?.answer === x?.value ? classes.selectedRating : classes.rating}>{x?.label}</Box>
                                                )
                                            })}
                                        </Stack>
                                    }
                                </Box>
                            </Box>
                        )
                    })}
                </Box>


            </Box>
            {tabValue === 2 &&
                <Box className={classes.bottomDiv}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button variant="outlined" className={classes.draftbtn} fullWidth onClick={() => editForm()}>{t("Edit Form")}</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" fullWidth onClick={() => publishForm()}>{t("Publish Form")}</Button>
                        </Grid>
                    </Grid>
                </Box>
            }

        </Box>
    )
}