import React from "react";

export const CommercialIcon = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_243_2387)">
      <circle cx="25.3999" cy="21.9191" r="21" fill="#B675F6" />
    </g>
    <g clip-path="url(#clip0_243_2387)">
      <path d="M23.5 28.75H26.5V33.25H23.5V28.75Z" fill="white" />
      <path
        d="M30.25 24.25C29.4691 24.7589 28.5571 25.0299 27.625 25.0299C26.6929 25.0299 25.7809 24.7589 25 24.25C24.2191 24.7589 23.3071 25.0299 22.375 25.0299C21.4429 25.0299 20.5309 24.7589 19.75 24.25C18.9659 24.7484 18.0541 25.0089 17.125 25C16.4813 25.0004 15.844 24.8729 15.25 24.625V32.5C15.25 32.6989 15.329 32.8897 15.4697 33.0303C15.6103 33.171 15.8011 33.25 16 33.25H22V28C22 27.8011 22.079 27.6103 22.2197 27.4697C22.3603 27.329 22.5511 27.25 22.75 27.25H27.25C27.4489 27.25 27.6397 27.329 27.7803 27.4697C27.921 27.6103 28 27.8011 28 28V33.25H34C34.1989 33.25 34.3897 33.171 34.5303 33.0303C34.671 32.8897 34.75 32.6989 34.75 32.5V24.625C34.156 24.8729 33.5187 25.0004 32.875 25C31.9459 25.0089 31.0341 24.7484 30.25 24.25Z"
        fill="white"
      />
      <path
        d="M27.6325 10.75H25.75V16.75H29.2675L27.6325 10.75Z"
        fill="white"
      />
      <path
        d="M24.2499 16.75V10.75H22.3674L20.7324 16.75H24.2499Z"
        fill="white"
      />
      <path
        d="M35.9651 16.75L33.1676 11.1625C33.1052 11.0388 33.0099 10.9348 32.8921 10.862C32.7742 10.7892 32.6386 10.7504 32.5001 10.75H29.1851L30.8201 16.75H35.9651Z"
        fill="white"
      />
      <path
        d="M20.8152 10.75H17.5002C17.3617 10.7504 17.226 10.7892 17.1082 10.862C16.9903 10.9348 16.895 11.0388 16.8327 11.1625L14.0352 16.75H19.1802L20.8152 10.75Z"
        fill="white"
      />
      <path
        d="M30.25 18.25H13.75V20.125C13.752 21.0195 14.1082 21.8768 14.7407 22.5093C15.3732 23.1418 16.2305 23.498 17.125 23.5C17.6311 23.4987 18.1302 23.3826 18.5849 23.1605C19.0396 22.9383 19.438 22.6159 19.75 22.2175C20.0614 22.6165 20.4597 22.9392 20.9145 23.1612C21.3694 23.3832 21.8689 23.4985 22.375 23.4985C22.8811 23.4985 23.3806 23.3832 23.8355 23.1612C24.2903 22.9392 24.6886 22.6165 25 22.2175C25.3114 22.6165 25.7097 22.9392 26.1645 23.1612C26.6194 23.3832 27.1189 23.4985 27.625 23.4985C28.1311 23.4985 28.6306 23.3832 29.0855 23.1612C29.5403 22.9392 29.9386 22.6165 30.25 22.2175C30.562 22.6159 30.9604 22.9383 31.4151 23.1605C31.8698 23.3826 32.3689 23.4987 32.875 23.5C33.7695 23.498 34.6268 23.1418 35.2593 22.5093C35.8918 21.8768 36.248 21.0195 36.25 20.125V18.25H30.25Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_243_2387"
        x="0.399902"
        y="0.919067"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_243_2387"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_243_2387"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_243_2387">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(13 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
