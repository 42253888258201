export const property_table_path = [
    "property_id",
    "property_name",
    "property_type",
    "revenue_type",
    "location",
    "updated_on",
]

export const propertyHeading = (t) => {
    return [
        { title: t("Property ID"), field: "property_id" },
        { title: t("Property Name"), field: "property_name" },
        { title: t("Property Type"), field: "property_type" },
        { title: t("Revenue Type"), field: "revenue_type" },
        { title: t("Location"), field: "location" },
        { title: t("Updated on"), field: "updated_on" },
    ]
}

export const property_table_type = [
    { type: ["text"], name: "property_id" },
    { type: ["long_text"], name: "property_name" },
    { type: ["text"], name: "property_type" },
    { type: ["text"], name: "revenue_type" },
    { type: ["long_text"], name: "location" },
    { type: ["text"], name: "updated_on" },
]

export const public_table_path = [
    "property_name",
    "property_type",
    "unit_name",
    "unit_category",
    "unit_id",
    "unit_type",
    "purpose",
    "revenue_type",
    "updated_on",
    "revoke",
]

export const publicHeading = (t) => {
    return [
        { title: t("Property Name"), field: "property_name" },
        { title: t("Property Type"), field: "property_type" },
        { title: t("Unit Name"), field: "unit_name" },
        { title: t("Unit Category"), field: "unit_category" },
        { title: t("Unit ID"), field: "unit_id" },
        { title: t("Unit Type"), field: "unit_type" },
        { title: t("Purpose"), field: "purpose" },
        { title: t("Revenue Type"), field: "revenue_type" },
        { title: t("Updated on"), field: "updated_on" },
        { title: "", field: "revoke" },
    ]
}

export const public_table_type = [
    { type: ["long_text"], name: "property_name" },
    { type: ["text"], name: "property_type" },
    { type: ["long_text"], name: "unit_name" },
    { type: ["text"], name: "unit_category" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "unit_type" },
    { type: ["text"], name: "purpose" },
    { type: ["text"], name: "revenue_type" },
    { type: ["text"], name: "updated_on" },
    { type: ["revoke"], name: "revoke" },
]

export const private_table_path = [
    "property_name",
    "property_type",
    "unit_name",
    "unit_category",
    "unit_id",
    "unit_type",
    "purpose",
    "revenue_type",
    "updated_on",
    "revoke",
]

export const privateHeading = (t) => {
    return [
        { title: t("Property Name"), field: "property_name" },
        { title: t("Property Type"), field: "property_type" },
        { title: t("Unit Name"), field: "unit_name" },
        { title: t("Unit Category"), field: "unit_category" },
        { title: t("Unit ID"), field: "unit_id" },
        { title: t("Unit Type"), field: "unit_type" },
        { title: t("Purpose"), field: "purpose" },
        { title: t("Revenue Type"), field: "revenue_type" },
        { title: t("Updated on"), field: "updated_on" },
        { title: "", field: "revoke" },
    ]
}

export const private_table_type = [
    { type: ["long_text"], name: "property_name" },
    { type: ["text"], name: "property_type" },
    { type: ["long_text"], name: "unit_name" },
    { type: ["text"], name: "unit_category" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "unit_type" },
    { type: ["text"], name: "purpose" },
    { type: ["text"], name: "revenue_type" },
    { type: ["text"], name: "updated_on" },
    { type: ["revoke"], name: "revoke" },
]

export const facility_table_path = [
    "property_name",
    "amenity_category",
    "amenity_name",
    "period",
    "rate",
    "updated_on",
    "revoke",
]

export const facilityHeading = (t) => {
    return [
        { title: t("Property Name"), field: "property_name" },
        { title: t("Amenity Category"), field: "amenity_category" },
        { title: t("Amenity Name"), field: "amenity_name" },
        { title: t("Period"), field: "period" },
        { title: t("Rate"), field: "rate" },
        { title: t("Updated on"), field: "updated_on" },
        { title: "", field: "revoke" },
    ]
}

export const facility_table_type = [
    { type: ["long_text"], name: "property_name" },
    { type: ["text"], name: "amenity_category" },
    { type: ["text"], name: "amenity_name" },
    { type: ["text"], name: "period" },
    { type: ["text"], name: "rate" },
    { type: ["text"], name: "updated_on" },
    { type: ["revoke"], name: "revoke" },
]

export const unit_revoked_table_path = [
    "property_name",
    "property_type",
    "unit_name",
    "unit_category",
    "unit_id",
    "unit_type",
    "purpose",
    "revenue_type",
    "revoked_on",
]

export const unitRevokedHeading = (t) => {
    return [
        { title: t("Property Name"), field: "property_name" },
        { title: t("Property Type"), field: "property_type" },
        { title: t("Unit Name"), field: "unit_name" },
        { title: t("Unit Category"), field: "unit_category" },
        { title: t("Unit ID"), field: "unit_id" },
        { title: t("Unit Type"), field: "unit_type" },
        { title: t("Purpose"), field: "purpose" },
        { title: t("Revenue Type"), field: "revenue_type" },
        { title: t("Revoked on"), field: "revoked_on" },
    ]
}

export const unit_revoked_table_type = [
    { type: ["long_text"], name: "property_name" },
    { type: ["text"], name: "property_type" },
    { type: ["long_text"], name: "unit_name" },
    { type: ["text"], name: "unit_category" },
    { type: ["text"], name: "unit_id" },
    { type: ["text"], name: "unit_type" },
    { type: ["text"], name: "purpose" },
    { type: ["text"], name: "revenue_type" },
    { type: ["text"], name: "revoked_on" },
]

export const facility_revoked_table_path = [
    "property_name",
    "amenity_category",
    "amenity_name",
    "period",
    "rate",
    "revoked_on",
]

export const facilityRevokedHeading = (t) => {
    return [
        { title: t("Property Name"), field: "property_name" },
        { title: t("Amenity Category"), field: "amenity_category" },
        { title: t("Amenity Name"), field: "amenity_name" },
        { title: t("Period"), field: "period" },
        { title: t("Rate"), field: "rate" },
        { title: t("Revoked on"), field: "revoked_on" },
    ]
}

export const facility_revoked_table_type = [
    { type: ["long_text"], name: "property_name" },
    { type: ["text"], name: "amenity_category" },
    { type: ["text"], name: "amenity_name" },
    { type: ["text"], name: "period" },
    { type: ["text"], name: "rate" },
    { type: ["text"], name: "revoked_on" },
]