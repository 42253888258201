import { Box, Grid, Stack, Typography } from "@mui/material"
import { fileUploadCardStyles } from "./style"
import { FileUploadIcon } from "./fileUploadIcon"

export const FileUploadCard = ({
    t,
    file_upload_limit,
    upload_file_ref,
    handleChooseFile = () => false,
    handleFileUpload = () => false,
}) => {
    const classes = fileUploadCardStyles()

    return (
        <Grid container spacing={"12px"}>
            <Grid item xs={12}>
                <Stack className={classes.file_upload_div} direction={"row"}
                    justifyContent={"center"} alignItems={"center"}>
                    <FileUploadIcon />
                    <Box width={"12px"} />
                    {/* <Typography className={classes.upload_file_text}>
                        {t("Drag and drop or")}&nbsp;
                    </Typography> */}
                    <Typography className={classes.upload_file_text_cta}
                        onClick={handleChooseFile}>
                        {t("Choose the file")}
                    </Typography>
                    <Typography className={classes.upload_file_text}>
                        &nbsp;{t("to upload")}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction={{ xs: "column", sm: "row" }} justifyContent={"space-between"}>
                    <Stack direction={"row"}>
                        <Typography className={classes.upload_file_criteria_label}>
                            {t("Supported Format:")}&nbsp;
                        </Typography>

                        <Typography className={classes.upload_file_criteria_value}>
                            {/* {".csv, .tsv, .xls, .xlsx, .txt, .pdf"} */}
                            {".csv, .xls, .xlsx"}
                        </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                        <Typography className={classes.upload_file_criteria_label}>
                            {t("Maximum size:")}&nbsp;
                        </Typography>
                        <Typography className={classes.upload_file_criteria_value}>
                            {file_upload_limit?.toString() + "MB"}
                        </Typography>
                    </Stack>
                </Stack>
            </Grid>
            <input type='file' id='file_upload'
                ref={upload_file_ref} style={{ display: 'none' }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => handleFileUpload(e?.target?.files?.[0])}
                onClick={(event) => { event.target.value = null }} />
        </Grid>
    )
}