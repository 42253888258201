import * as React from "react"

const WebSiteImg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 48450"
          transform="translate(.5)"
          fill="#4e5a6b"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 96904">
      <g
        data-name="Mask Group 6285"
        transform="translate(-.5)"
        clipPath="url(#a)"
      >
        <path
          data-name="icons8-geography (1)"
          d="M13.214 4v4.758H16.3C15.746 6.285 14.581 4.433 13.214 4ZM12 8.758V4c-1.367.433-2.533 2.285-3.091 4.758ZM12 20v-4.761H8.907C9.465 17.715 10.63 19.567 12 20Zm-4.661-8a16.9 16.9 0 0 1 .121-2.026H4.767a7.833 7.833 0 0 0 0 4.053h2.691A16.9 16.9 0 0 1 7.337 12ZM12 9.974H8.692a14.84 14.84 0 0 0 0 4.053H12Zm1.214 5.265v4.758c1.367-.433 2.533-2.285 3.091-4.758ZM7.653 8.758A10.607 10.607 0 0 1 9.4 4.56a8.15 8.15 0 0 0-4.215 4.2Zm0 6.485H5.182A8.151 8.151 0 0 0 9.4 19.439a10.606 10.606 0 0 1-1.747-4.2Zm8.867-5.269h-3.306v4.053h3.306a14.839 14.839 0 0 0 0-4.053Zm1.038 5.265a10.607 10.607 0 0 1-1.743 4.2 8.149 8.149 0 0 0 4.214-4.2Zm2.886-1.216a7.833 7.833 0 0 0 0-4.053h-2.691a17 17 0 0 1 0 4.053Zm-2.886-5.265h2.472a8.151 8.151 0 0 0-4.214-4.2 10.606 10.606 0 0 1 1.742 4.2Z"
          fill="#78b1fe"
        />
      </g>
    </g>
  </svg>
)

export default WebSiteImg
