import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../../../utils";
export const AddUnitStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            minWidth: "700px",
        },
    },
    header_div: {
        padding: "16px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E4E8EE"
    },
    title: {
        fontFamily: Bold,
        fontSize:"1rem",
        color: "#091B29"
    },
    close_button: {
        padding: "0px"
    },
    body_div: {
        position: "relative",
        height: `calc(100vh - 350px)`,
        margin: "20px 20px 0px 20px",
        padding: "16px",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.background.secondary,
    },
    search_div: {
        paddingBottom: "16px"
    },
    search_dropdown_div: {
        position: "absolute",
        top: "66px",
        width: "100%",
        padding: "0px 36px 0px 0px",
    },
    search_dropdown_list_div: {
        height: "300px",
        boxShadow: "0px 8px 24px #0717411F",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.background.tertiary1,
    },
    footer_div: {
        display: "flex",
        padding: "16px 20px",
    },
    footer_button: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.08)",
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "white"
    },
    loader_div: {
        display: "flex",
        height: "inherit",
        alignItems: "center",
        justifyContent: "center",
    },
    loader_text: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary
    },
    dropdown_root: {
        padding: "16px",
        cursor: "pointer",
    },
    logo: {
        height: "40px",
        width: "40px",
        objectFit: "cover",
        borderRadius: theme.palette.borderRadius,
    },
    heading: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.typography.color.primary
    },
    sub_heading: {
        fontSize:"0.75rem",
        fontFamily: Regular,
        color: theme.typography.color.secondary
    },
    divider: {
        margin: "0px 0px 0px 16px",
        display: "flex",
        height: "1px",
        backgroundColor: "#ededed"
    },
    period_divider: {
        borderRadius: "12px",
        backgroundColor: "#CED3DD",
        height: "6px",
        width: "6px",
    },
    selected_unit_stack: {
        width: "100%",
        height: `calc(100vh - 424px)`,
        overflow: "overlay",
        "&::-webkit-scrollbar": {
          display: "none",
        },
    },
    selected_unit_root: {
        padding: "16px",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.background.tertiary1,
        border: "1px solid #E4E8EE",
    },
    update_button: {
        height: "24px",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        "&:hover": {
            backgroundColor: theme.palette.background.tertiary1,
        },
    },
    update_text: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.selected_1
    },
    updated_text: {
        fontSize:"0.75rem",
        fontFamily: Regular,
        color: theme.typography.color.primary
    }
}));