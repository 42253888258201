export const ServiceJobIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_116014" data-name="Group 116014" transform="translate(0.001)">
                <rect id="Rectangle_58161" data-name="Rectangle 58161" width="20" height="20" transform="translate(-0.001)" fill="#fff" opacity="0" />
                <path id="icons8-service_1_" data-name="icons8-service (1)" d="M7.662,6a.744.744,0,0,0-.446.214l-1,1a.747.747,0,0,0-.1.93l1.922,3.015a1.732,1.732,0,0,0,1.281.809,1.811,1.811,0,0,0,.2.012,1.72,1.72,0,0,0,1.045-.359l3.661,3.661a2.565,2.565,0,0,0,.409,3.056l4.93,4.9A2.545,2.545,0,0,0,21.375,24h.01a2.562,2.562,0,0,0,1.82-4.366l-4.926-4.9a2.545,2.545,0,0,0-1.81-.76h-.012a2.539,2.539,0,0,0-1.143.28l-3.693-3.693a1.729,1.729,0,0,0-.454-2.523L8.146,6.116A.75.75,0,0,0,7.662,6Zm11.358,0a5.368,5.368,0,0,0-1.443.112,5.225,5.225,0,0,0-4.03,4.243,5.438,5.438,0,0,0-.065.651L15.565,13.1a3.419,3.419,0,0,1,.892-.119,3.549,3.549,0,0,1,2.53,1.055l1.945,1.934a5.217,5.217,0,0,0,2.752-6.361.745.745,0,0,0-1.232-.271L20.23,11.559a1.357,1.357,0,0,1-1.907.032,1.293,1.293,0,0,1,.022-1.806L20.63,7.5a.746.746,0,0,0-.269-1.232A5.166,5.166,0,0,0,19.02,6.008Zm-7.1,8.382L6.739,19.6a2.549,2.549,0,0,0,1.809,4.344h.01a2.53,2.53,0,0,0,1.8-.754l3.853-3.87-.278-.276a3.572,3.572,0,0,1-.874-3.522Z" transform="translate(-4.973 -5)" fill="#6d80b3" />
            </g>
        </svg>

    )
}