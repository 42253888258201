import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Box, Button, Divider, Grid, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import { Line } from "react-chartjs-2"
import { AggreementCardInfo, AlertDialog } from "../../../components"
import { useStyles } from "../style"
import ReceiptView from "./receipt"
import { AccountId } from "../../../assets/utilityReadings/accountId"
import { AgreementValue } from "../../../assets/utilityReadings/agreementValue"
import { Dollar } from "../../../assets/utilityReadings/dollar"
import { PostedDate } from "../../../assets/utilityReadings/postedDate"
import { InvoiceNo } from "../../../assets/utilityReadings/invoiceNo"
import { InvoiceAmt } from "../../../assets/utilityReadings/invoiceAmt"
import { InvoiceDate } from "../../../assets/utilityReadings/invoiceDate"
import { PlannedDate } from "../../../assets/utilityReadings/plannedDate"
export const UtilityInfo = ({ t, utilityData = {}, utilityGraphData = {}, utilityInvoiceDetails = [] }) => {
    const [showDialog, setShowDialog] = React.useState(false)
    const classes = useStyles()
    const [details, setDetails] = React.useState({
        isManage: false,
        isInvoice: false,
    })
    const lineChartOptions = {
        plugins: {
            legend: {
                display: false
            }
        }
    }
    const ManageInvoiceCards = [
        {
            url: <AgreementValue />,
            title: utilityInvoiceDetails?.managed_invoice_no ?? "-",
            subtitle: t("invoiceID"),
            color: "#E0FFED",
            isIcon: true
        },
        {
            url: <Dollar />,
            title: `${utilityInvoiceDetails?.currency_master?.symbol} ${utilityInvoiceDetails?.total_amount}` ?? "-",
            subtitle: t("invoiceAmount"),
            color: "#E0E9FF",
            isIcon: true

        },
        {
            url: <PlannedDate />,
            title: moment(utilityInvoiceDetails?.planned_date).format("DD MMM YYYY") ?? "-",
            subtitle: t("plannedDate"),
            color: "#FFF1E0",
            isIcon: true

        },
        {
            url: <PostedDate />,
            title: moment(utilityInvoiceDetails?.invoice?.posted_date).format("DD MMM YYYY") ?? "-",
            subtitle: t("postedDate"),
            color: "#E0F3FF",
            isIcon: true
        }]

    const InvoiceDetails = [
        {
            url: <InvoiceNo />,
            title: utilityInvoiceDetails?.invoice?.invoice_no ?? "-",
            subtitle: t("invoiceNumber"),
            color: "#E0FBFF",
            isIcon: true
        },
        {
            url: <InvoiceAmt />,
            title: `${utilityInvoiceDetails?.currency_master?.symbol} ${utilityInvoiceDetails?.invoice?.invoice_total_amount}` ?? "-",
            subtitle: t("invoiceAmount"),
            color: "#FFE7E0",
            isIcon: true

        },
        {
            url: <InvoiceDate />,
            title: moment(utilityInvoiceDetails?.invoice_date).format("DD MMM YYYY") ?? "-",
            subtitle: t("invoiceDate"),
            color: "#E6E0FF",
            isIcon: true

        },
        {
            url: <AccountId />,
            title: utilityInvoiceDetails?.invoice?.account_no,
            subtitle: t("accountID"),
            color: "#F9E0FF",
            isIcon: true

        }]
    const PaymentDetails = [
        {
            url: <InvoiceDate />,
            title: moment(utilityInvoiceDetails?.invoice?.invoice_receipt_settlements?.[0]?.reciept?.payment_date).format("DD MMM YYYY") ?? "-",
            subtitle: t("date"),
            color: "#E6E0FF",
            isIcon: true

        },
        {
            url: <InvoiceAmt />,
            title: utilityInvoiceDetails?.invoice?.invoice_receipt_settlements?.[0]?.amount_tagged ?? "-",
            subtitle: t("amount"),
            color: "#FFE7E0",
            isIcon: true

        },
        {
            url: <InvoiceNo />,
            title: utilityInvoiceDetails?.invoice?.invoice_receipt_settlements?.[0]?.reciept?.reciepts_no ?? "-",
            subtitle: t("receiptNumber"),
            color: "#E0FBFF",
            isIcon: true

        },
        {
            url: <AccountId />,
            title: utilityInvoiceDetails?.invoice?.invoice_receipt_settlements?.[0]?.settlement_no ?? "-",
            subtitle: t("settlementNumber"),
            color: "#F9E0FF",
            isIcon: true

        }
    ]



    const handleClose = () => {
        setShowDialog(false)
    }
    return (
        <Box className={classes.utilityBlock}>
            <Box p={2}>
                <Typography className={classes.utilityTitle}>{`${t("Utility Info")} (${utilityData?.meter_no} | ${("Reading No")}:${utilityData?.UOM})`}</Typography>
            </Box>
            <Box>
                <Divider className={classes.dividerStyle} />
            </Box>
            <Box p={2} className={classes.utilityBox}>
                <Typography className={classes.utilityTitle}>{`${utilityData?.Property_name}(${utilityData?.utility_name})`}</Typography>
                {utilityGraphData?.datasets?.length > 0 &&
                    <Box mt={1}>
                        <Line data={utilityGraphData} options={lineChartOptions} />
                    </Box>
                }

                {utilityInvoiceDetails?.managed_invoice_no?.length > 0 &&
                    <>
                        <Box mt={1} mb={1}>
                            <Divider />
                        </Box>

                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} onClick={() => setDetails({ isManage: !details?.isManage, isInvoice: false })} style={{ cursor: "pointer" }}>
                            <Typography className={classes.utilityTitle}>{t("Managed Invoice Details")}</Typography>
                            {details?.isManage ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </Box>
                        <>

                            {details?.isManage &&
                                <>
                                    <Box mt={1}>
                                        <Grid container spacing={2}>
                                            {/*info bottom card */}
                                            {
                                                ManageInvoiceCards?.map((val) => {
                                                    return (
                                                        <Grid item xs={6}>
                                                            <AggreementCardInfo data={val} />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>


                                </>
                            }
                        </>
                    </>

                }
                {utilityInvoiceDetails?.managed_invoice_no?.length > 0 &&
                    <Box mt={2} mb={2}>
                        <Divider />
                    </Box>}
                {utilityInvoiceDetails?.invoice?.invoice_total_amount &&
                    <>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} onClick={() => setDetails({ isManage: false, isInvoice: !details?.isInvoice })} style={{ cursor: "pointer" }}>
                            <Typography className={classes.utilityTitle}>{t("Invoice Details")}</Typography>
                            {details?.isInvoice ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </Box>
                        {details?.isInvoice &&
                            <>
                                <Box mt={1}>
                                    <Grid container spacing={2}>
                                        {/*info bottom card */}
                                        {
                                            InvoiceDetails?.map((val) => {
                                                return (
                                                    <Grid item xs={6}>
                                                        <AggreementCardInfo data={val} />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Box>




                            </>
                        }
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>

                    </>
                }

                {utilityInvoiceDetails?.invoice?.invoice_receipt_settlements?.length > 0 &&
                    <>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography className={classes.utilityTitle}>{t("Payment Details")}</Typography>
                        </Box>
                        <Box mt={1}>
                            <Grid container spacing={2}>
                                {/*info bottom card */}
                                {
                                    PaymentDetails?.map((val) => {
                                        return (
                                            <Grid item xs={6}>
                                                <AggreementCardInfo data={val} />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </>
                }


            </Box>
            {utilityInvoiceDetails?.invoice?.invoice_receipt_settlements?.length > 0 &&
                <Box p={2}>
                    <Button variant="contained" fullWidth onClick={() => setShowDialog(true)}>{t("View receipt details")}</Button>
                </Box>
            }
            <AlertDialog open={showDialog}
                onClose={() => handleClose()}
                isnotTitle
                medium
                component={
                    <Box>
                        <ReceiptView data={utilityInvoiceDetails} closeModal={handleClose} />

                    </Box>
                }
            />
        </Box>
    )
}