import * as React from "react"
const DeductionIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14.999} height={14} {...props}>
    <g fill="#4e5a6b" data-name="Group 115957">
      <path
        d="M3.783 12h-1.72A2.065 2.065 0 0 1 0 9.937V2.063A2.065 2.065 0 0 1 2.063 0h10.875A2.065 2.065 0 0 1 15 2.063v7.874A2.065 2.065 0 0 1 12.938 12h-1.721a3.788 3.788 0 0 0-.019-1.125h1.74a.927.927 0 0 0 .937-.938V2.063a.927.927 0 0 0-.937-.938H2.063a.928.928 0 0 0-.938.938v7.874a.928.928 0 0 0 .938.938H3.8A3.792 3.792 0 0 0 3.783 12Zm8.03-5.25h-.751a.563.563 0 1 1 0-1.125h.751a.563.563 0 1 1 0 1.125Zm-3 0H3.188a.563.563 0 1 1 0-1.125h5.624a.563.563 0 1 1 0 1.125Zm3-2.25h-.751a.563.563 0 0 1 0-1.125h.751a.563.563 0 0 1 0 1.125Zm-3 0H3.188a.563.563 0 0 1 0-1.125h5.624a.563.563 0 1 1 0 1.125Z"
        data-name="Subtraction 24"
      />
      <path
        d="M7.5 9a2.5 2.5 0 1 0 2.5 2.5A2.5 2.5 0 0 0 7.5 9Zm1.071 2.857H6.429a.357.357 0 1 1 0-.714h2.142a.357.357 0 0 1 0 .714Z"
        data-name="Path 101036"
      />
    </g>
  </svg>
)
export default DeductionIcon
