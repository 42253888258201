import React from "react";

export const CircleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <g id="Group_112546" data-name="Group 112546" transform="translate(-15459 -9353)">
            <g id="Ellipse_129958" data-name="Ellipse 129958" transform="translate(15459 9353)" fill="#fff" stroke="#ced3dd" stroke-width="1">
                <circle cx="6" cy="6" r="6" stroke="none" />
                <circle cx="6" cy="6" r="5.5" fill="none" />
            </g>
            <circle id="Ellipse_129959" data-name="Ellipse 129959" cx="3" cy="3" r="3" transform="translate(15462 9356)" fill="#ced3dd" />
        </g>
    </svg>
)