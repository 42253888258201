export const Opportunities = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Sales Opportunities">
            <path data-name="Rectangle 52494" fill="none" d="M0 0h30v30H0z" />
            <g data-name="Sales Opportunities">
                <g data-name="Group 99019" transform="translate(8 8)">
                    <path
                        data-name="Path 95291"
                        d="M5.301 12.451a.732.732 0 0 0 .732.732h2.93a.732.732 0 0 0 .732-.732v-.734H5.301Z"
                        fill={props?.fill ?? "#c1c5cb"}
                    />
                    <path
                        data-name="Path 95292"
                        d="M7.5 5.271a1.907 1.907 0 0 0-1.9 1.758h3.8a1.907 1.907 0 0 0-1.9-1.758Z"
                        fill={props?.fill ?? "#c1c5cb"}
                    />
                    <path
                        data-name="Path 95293"
                        d="M7.5-.002a5.125 5.125 0 0 0-3.216 9.117 2.672 2.672 0 0 1 1 1.723H9.72a2.672 2.672 0 0 1 1-1.723A5.125 5.125 0 0 0 7.5-.002Zm2.783 7.471a.439.439 0 0 1-.439.439H5.156a.439.439 0 0 1-.439-.439v-.291a2.786 2.786 0 0 1 1.5-2.467 1.758 1.758 0 1 1 2.575 0 2.786 2.786 0 0 1 1.5 2.467Z"
                        fill={props?.fill ?? "#c1c5cb"}
                    />
                    <circle
                        data-name="Ellipse 129231"
                        cx={0.879}
                        cy={0.879}
                        r={0.879}
                        transform="translate(6.621 2.637)"
                        fill={props?.fill ?? "#c1c5cb"}
                    />
                    <path
                        data-name="Path 95294"
                        d="M7.5 15a1.613 1.613 0 0 0 1.463-.937H6.037A1.613 1.613 0 0 0 7.5 15Z"
                        fill={props?.fill ?? "#c1c5cb"}
                    />
                    <g data-name="Group 99013">
                        <g data-name="Group 99011">
                            <path
                                data-name="Path 95295"
                                d="M13.682 2.197a.439.439 0 0 1-.244-.805l.879-.586a.44.44 0 1 1 .487.731l-.879.586a.437.437 0 0 1-.243.074Z"
                                fill={props?.fill ?? "#c1c5cb"}
                            />
                        </g>
                        <g data-name="Group 99012">
                            <path
                                data-name="Path 95296"
                                d="M14.561 9.521a.438.438 0 0 1-.243-.074l-.879-.586a.44.44 0 1 1 .487-.731l.879.586a.439.439 0 0 1-.244.805Z"
                                fill={props?.fill ?? "#c1c5cb"}
                            />
                        </g>
                    </g>
                    <g data-name="Group 99016">
                        <g data-name="Group 99014">
                            <path
                                data-name="Path 95297"
                                d="M1.318 2.197a.437.437 0 0 1-.243-.074L.2 1.538A.44.44 0 1 1 .687.807l.879.586a.439.439 0 0 1-.244.805Z"
                                fill={props?.fill ?? "#c1c5cb"}
                            />
                        </g>
                        <g data-name="Group 99015">
                            <path
                                data-name="Path 95298"
                                d="M.439 9.521a.44.44 0 0 1-.244-.805l.879-.586a.44.44 0 1 1 .488.731l-.879.586a.437.437 0 0 1-.243.074Z"
                                fill={props?.fill ?? "#c1c5cb"}
                            />
                        </g>
                    </g>
                    <g data-name="Group 99017">
                        <path
                            data-name="Path 95299"
                            d="M1.318 5.567H.439a.44.44 0 0 1 0-.879h.879a.44.44 0 0 1 0 .879Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                    <g data-name="Group 99018">
                        <path
                            data-name="Path 95300"
                            d="M14.56 5.567h-.879a.44.44 0 0 1 0-.879h.879a.44.44 0 1 1 0 .879Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
