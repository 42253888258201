import * as React from "react"
const TotalTaxes = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <g data-name="Group 115513">
      <path
        fill="#6dafb3"
        d="M25.04 12.728c.704.702.885 1.66.406 2.14L14.453 25.861c-.48.48-1.437.297-2.14-.405L2.13 15.273c-.702-.703-.884-1.66-.405-2.14L12.718 2.14c.48-.48 1.438-.298 2.14.405Z"
        data-name="Path 100911"
      />
      <g fill="#fff" data-name="Path 100912">
        <path d="M16.032 17.315a.862.862 0 0 1-.534-.297c-.202-.236-.3-.555-.3-.975v-.437c0-.41.1-.723.303-.956a.876.876 0 0 1 .51-.295c-.33.082-.76.38-.76 1.375v.337c0 .32.079.6.235.834.17.255.375.367.546.414Zm.539-.017c.295-.095.661-.368.686-1.138v-.554c0-.329-.074-.61-.22-.836a.935.935 0 0 0-.582-.422.886.886 0 0 1 .553.298c.203.229.301.553.301.99v.43c0 .41-.097.722-.296.956a.834.834 0 0 1-.442.276Zm-4.996-3.98a.918.918 0 0 0 .584-.434c.061-.101.109-.213.143-.335h.062c-.049.185-.13.341-.243.472a.867.867 0 0 1-.546.298Zm-.41 0a.893.893 0 0 1-.552-.3c-.204-.231-.304-.554-.304-.987v-.42c0-.405.099-.719.301-.959a.853.853 0 0 1 .544-.304.927.927 0 0 0-.585.437c-.14.227-.211.511-.211.844v.438c0 .34.078.625.234.85.147.211.346.35.573.401Zm1.201-.776V11.612c0-.322-.07-.6-.211-.827a.926.926 0 0 0-.588-.436.878.878 0 0 1 .552.3c.203.233.301.556.301.987v.43c0 .177-.018.334-.054.476Z" />
        <path d="m15.655 9.372.82.461-4.444 8.445-.814-.462 4.438-8.444Zm-4.296.461c.475 0 .854.163 1.137.488.282.325.424.763.424 1.315v.43c0 .533-.14.96-.422 1.283-.28.323-.656.484-1.128.484-.467 0-.844-.161-1.13-.484-.288-.323-.43-.762-.43-1.318v-.42c0-.523.139-.95.418-1.281.279-.331.656-.497 1.13-.497Zm.507 2.216v-.437a1.06 1.06 0 0 0-.136-.564c-.092-.148-.215-.222-.37-.222-.153 0-.274.074-.366.222-.09.147-.136.341-.136.581v.438c0 .236.048.425.145.564.097.14.22.21.367.21.152 0 .273-.073.362-.219.09-.146.134-.337.134-.573Zm4.385 1.784c.47 0 .847.16 1.131.482.285.321.427.761.427 1.32v.432c0 .528-.139.954-.415 1.279-.277.325-.654.487-1.132.487-.481 0-.863-.163-1.143-.49-.281-.327-.421-.76-.421-1.3v-.437c0-.532.142-.96.427-1.285.284-.325.66-.488 1.126-.488Zm.011 3.014c.315 0 .48-.235.495-.703v-.538c0-.233-.047-.42-.14-.565-.092-.143-.215-.215-.366-.215-.334 0-.5.301-.5.904v.337c0 .22.05.405.15.555.1.15.221.225.361.225Z" />
      </g>
    </g>
  </svg>
)
export default TotalTaxes
