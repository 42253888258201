import React from 'react';
import { Typography, Box } from "@mui/material";
import { useStyles } from "./style";
import axios from "axios";
import moment from "moment";
import { Sample } from "../../utils";
import { BackdropContext } from "../../contexts";
import { config } from "../../config";
export const WeatherComponent = () => {
      // classes
      const classes = useStyles();
      // context
      const backdrop = React.useContext(BackdropContext);
      // state
      const [data, setData] = React.useState('')
      // get weather api call
      const GetLocation = (latitude, longitude) => {
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: "Loading",
            });
            axios.get(`${config.weather}/weather?lat=${latitude}&lon=${longitude}&appid=${config.weatherId}&units=metric`).then((res) => {
                  setData(res?.data)
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            }).catch((err) => {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
            });

      }
      // get current location
      const getLocation = () => {
            navigator.geolocation.getCurrentPosition(getCoordinates);
      }
      const getCoordinates = (position) => {
            GetLocation(position.coords.latitude, position.coords.longitude)
      }
      // convert float to integer
      function float2int (value) {
            return value | 0;
        }
      //   use Effect
      React.useEffect(() => {
            getLocation()
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
      // get country name and flag
      let country = Sample?.filter?.(x => x?.code === data?.sys?.country).map((x) => x)


      return (
            <>
            {/* weather box */}
                  <Box className={classes.weatherBox}>
                        {
                             data?.base?.length > 0 ?
                                    <>
                                          <Box className={classes.weatherInnerBox}>

                                                <Box>
                                                      {/* weather */}
                                                      <Typography className={classes.weatherTilte}>
                                                            Weather
                                                      </Typography>
                                                      {/* celcius */}
                                                      <Typography className={classes.weatherReport}>
                                                            {float2int(data?.main?.temp)}<sup>°</sup> C
                                                      </Typography>
                                                </Box>
                                                {/* today date */}
                                                <Box textAlign={'right'}>
                                                      <Typography className={classes.weatherTimeDate}>
                                                            {moment(new Date()).tz(moment.tz.guess()).format("DD MMM YYYY , hh:mm A")}
                                                      </Typography>
                                                      {/* weather icon in api */}
                                                      {
                                                            data?.weather?.[0]?.icon?.length > 0 &&
                                                            <Typography className={classes.weatherReport}>
                                                                  <img src={`http://openweathermap.org/img/w/${data?.weather?.[0]?.icon}.png`} alt='weather' />
                                                            </Typography>
                                                      }

                                                </Box>
                                          </Box>
                                          {/* country */}
                                          <Box className={classes.weatherInnerBox}>
                                                {
                                                      country?.[0]?.name?.length > 0 &&
                                                      <Box>
                                                            {/* country */}
                                                            <Typography className={classes.weatherTilte}>
                                                                  City
                                                            </Typography>
                                                            {/* country name */}
                                                            <Typography className={classes.weatherReport}>
                                                            {data?.name ?? ""}
                                                            </Typography>
                                                      </Box>
                                                }
                                                {/* country  flag*/}
                                                <Box>
                                                      {
                                                            country?.[0]?.image?.length > 0 &&
                                                            <Typography className={classes.weatherReport}>
                                                                  <img src={country?.[0]?.image} alt='weather' className={classes.flagImage} />
                                                            </Typography>
                                                      }

                                                </Box>
                                          </Box>

                                    </> : <>
                                          <div>
                                          <img src='/images/weather.svg' alt='weather' />
                                                <Typography className={classes.weatherTilte}>
                                                      No Data
                                                </Typography>

                                          </div>
                                    </>
                        }


                  </Box>
            </>
      )
}