import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },

  required: {
    color: "red",
    fontFamily: SemiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
  textbox: {
    backgroundColor: (props) => props?.color ?? "auto",
    borderRadius: theme.palette.borderRadius,
    border: "none",
    fontFamily: SemiBold,
    fontSize: (props) => props?.fontSize ?? "auto",
    cursor: "pointer",
    color:"yellow",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: '#fff'
    },
    [`& fieldset`]: {
      borderRadius:(props)=>props?.borderRadius?? theme.palette.borderRadius,
      height: (props) => (props.multiline ? "unset" : props?.height ?? 53),
      border: (props) => props?.border ?? "1.5px solid #E4E8EE !important",
      "&:hover": {
        border: "1.5px solid #E4E8EE !important",
      },
      fontFamily: SemiBold,
      cursor: "pointer",
      fontSize: (props) => props?.fontSize ?? "auto",
          },
    "& .MuiOutlinedInput-input": {
      padding: (props) => props?.padding ?? "12px 14px 14px",
      fontFamily: (props) => props?.boldText ? Bold : SemiBold,
      marginTop:(props)=>props?.marginTop??"auto",
      cursor: "pointer",
      fontSize: (props) => props?.fontSize ?? "auto",
      backgroundColor: (props) => props?.textBoxColor ?? "transparent",
      color:(props)=>props?.textColor??theme.typography.color.primary,
      height: (props) => props?.textBoxHeight ?? "auto",
      borderColor: "#E4E8EE",
      textAlign: (props) => props?.textAlign ?? "left",
      "&.Mui-disabled":{
        "-webkit-text-fill-color":(props)=>props?.disableColor?theme.typography.color.tertiary:(props?.textColor??theme.typography.color.tertiary),
      }
    },

    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#E4E8EE',
      },


    },
  },
  readOnlyInput:{
    color:"#F5F7FA"
  }


}));