export const Organization = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98420">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g fill={props?.fill ?? "#c1c5cb"}>
                    <path
                        data-name="Path 94865"
                        d="M10.955 17.605H7.439a.439.439 0 0 0-.439.439v3.516a.439.439 0 0 0 .439.439h3.516a.439.439 0 0 0 .439-.439v-3.516a.439.439 0 0 0-.439-.439Z"
                    />
                    <path
                        data-name="Path 94866"
                        d="M9.637 16.726v-1.2h4.421v1.2h.879v-1.2h4.424v1.2h.879v-1.641a.439.439 0 0 0-.439-.439H14.94v-1.201h-.882v1.2H9.197a.439.439 0 0 0-.439.439v1.641Z"
                    />
                    <path
                        data-name="Path 94867"
                        d="M12.156 12.566h4.688a.439.439 0 0 0 .439-.439V7.439A.439.439 0 0 0 16.844 7h-4.687a.439.439 0 0 0-.44.439v4.688a.439.439 0 0 0 .439.439Z"
                    />
                    <path
                        data-name="Path 94868"
                        d="M16.258 17.605h-3.516a.439.439 0 0 0-.439.439v3.516a.439.439 0 0 0 .439.439h3.516a.439.439 0 0 0 .439-.439v-3.516a.439.439 0 0 0-.439-.439Z"
                    />
                    <path
                        data-name="Path 94869"
                        d="M21.56 17.605h-3.516a.439.439 0 0 0-.439.439v3.516a.439.439 0 0 0 .439.439h3.516a.439.439 0 0 0 .439-.439v-3.516a.439.439 0 0 0-.439-.439Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
