export const UtilityInvoices = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105169">
            <g
                data-name="LS - Invoice Management - Utility Invoices"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path d="M7.286 10.142h3.428a.286.286 0 0 0 0-.571H7.286a.286.286 0 0 0 0 .571ZM7.571 7.286V9h.571V7.286a.286.286 0 0 0-.571 0ZM20.713 11.855h-.571a.286.286 0 0 0-.286.286v.657l1.143.889v-1.546a.286.286 0 0 0-.286-.286ZM9.857 7.286V9h.571V7.286a.286.286 0 0 0-.571 0ZM8.999 13.571a1.43 1.43 0 0 0 1.428-1.428v-1.428H7.571v1.427a1.43 1.43 0 0 0 1.428 1.429ZM11.574 21.855h.286v-.571h-.286a2.288 2.288 0 0 1-2.286-2.286v-4.88a1.8 1.8 0 0 1-.571 0v4.88a2.86 2.86 0 0 0 2.857 2.857ZM21.57 16.538l-4.571-3.555-4.571 3.555v6.314a.149.149 0 0 0 .149.149h8.84a.149.149 0 0 0 .149-.149Zm-2.7 2.71-2.228 2.45a.476.476 0 0 1-.554.114.488.488 0 0 1-.281-.5l.279-2.308h-.6a.477.477 0 0 1-.441-.289.493.493 0 0 1 .086-.531l2.228-2.45a.477.477 0 0 1 .554-.114.487.487 0 0 1 .28.5l-.279 2.308h.6a.477.477 0 0 1 .441.289.492.492 0 0 1-.087.531Z" />
                <path d="M16.999 12.335a.286.286 0 0 1 .175.06l5.037 3.918a.379.379 0 1 0 .463-.6l-5.444-4.235a.377.377 0 0 0-.463 0l-5.444 4.234a.379.379 0 1 0 .463.6l5.037-3.917a.286.286 0 0 1 .176-.06Z" />
                <path d="M17.376 18.902a.286.286 0 0 1-.07-.224l.286-2.358-1.918 2.107h.733a.286.286 0 0 1 .283.32l-.286 2.358 1.918-2.107h-.733a.286.286 0 0 1-.213-.096Z" />
            </g>
        </g>
    </svg>
)