import React from "react";
import { withNavBars } from "../../HOCs";
import Jobs from "./jobs";

class JobsParent extends React.Component {
  render() {
    return <Jobs {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(JobsParent, props);
