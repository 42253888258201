import { SalesLeadIcon } from "../../../assets/SalesLeadIcon";
import { OppertunityIcon } from "../../../assets/OppertunityIcon";
import { QuotationsIcon } from "../../../assets/QuotationsIcon";
import { InterestedQuotationsIcon } from "../../../assets/InterestedQuotationsIcon";
import { WonQuotationsIcon } from "../../../assets/WonQuotationsIcon";
import { UnitBlockingRequestIcon } from "../../../assets/UnitBlockingRequestIcon";
import { OpenReservationsIcon } from "../../../assets/OpenReservationsIcon";
import { ResidentialIcon } from "../../../assets/ResidentialIcon";
import { CommercialIcon } from "../../../assets/CommercialIcon";
import { LeaseAndSales } from "../../../assets/LeaseAndSales";
import { FromAppIcon, UnVerifiedIcon, ValidIcon, VerifiedIcon } from "../../../assets/leasemanagerdashboard";
import { Routes } from "../../../router/routes";
import { LeadFunnelOptions, LeadStatusOptions, LetterTypeOptions, OpportunityFunnelOptions, OpportunityOptions, OpportunityRevenueOptions, QuotationFunnelOptions } from "./constant";

export const constructResponseGetStatData = ({ data = "", t = () => false, company }) => {
    const lead_count = [
        {
            count: data?.proxy_lead_count ? `${data?.proxy_lead_count}${data?.total_proxy_lead_count ? ` of ${data?.total_proxy_lead_count}` : ``}` : "0",
            sub: t("Open Leads"),
            route: Routes?.leadnew,
            icon: <SalesLeadIcon />
        },
        {
            count: data?.oppertunity_count ? `${data?.oppertunity_count}${data?.total_oppertunity_count ? ` of ${data?.total_oppertunity_count}` : ``}` : "0",
            sub: t("Open Opportunities"),
            route: Routes?.leads,
            icon: <OppertunityIcon />
        },
        {
            count: data?.quotation_count ?? "0",
            sub: t("Open Quotations"),
            route: Routes?.quotation + "?filter=Active",
            icon: <QuotationsIcon />
        },
        {
            count: data?.interested_quotation_count ?? "0",
            sub: t("Interested Quotations"),
            route: Routes?.quotation + "?filter=Interested",
            icon: <InterestedQuotationsIcon />
        },
        {
            count: data?.won_quotation_count ?? "0",
            sub: t("Won Quotations"),
            route: Routes?.quotation + "?filter=Won",
            icon: <WonQuotationsIcon />
        },
        {
            count: data?.unit_block_request ?? "0",
            sub: t("Unit Blocking Request"),
            route: Routes?.unitBlocking,
            icon: <UnitBlockingRequestIcon />
        },
        {
            count: data?.reservation_count ?? "0",
            sub: t("Open Reservations"),
            route: Routes?.reservation,
            icon: <OpenReservationsIcon />
        },
    ]?.map?.(_ => { return { ..._, icon_type: true } });

    const revenue_summary = [
        {
            count: `${company?.currency_symbol ?? ""}${data?.quotation_unit_usage?.find?.(_ => _?.unit_usage === "Residential")?.sum ?? "0"}`,
            label: t("Residential"),
            icon: <ResidentialIcon />
        },
        {
            count: `${company?.currency_symbol ?? ""}${data?.quotation_unit_usage?.find?.(_ => _?.unit_usage === "Commercial")?.sum ?? "0"}`,
            label: t("Commercial"),
            icon: <CommercialIcon />
        },
        {
            count: `${company?.currency_symbol ?? ""}${data?.quotation_revenue_type?.find?.(_ => _?.revenue_type === "Lease")?.sum ?? "0"}`,
            label: t("Lease"),
            icon: <LeaseAndSales />
        },
        {
            count: `${company?.currency_symbol ?? ""}${data?.quotation_revenue_type?.find?.(_ => _?.revenue_type === "Sale")?.sum ?? "0"}`,
            label: t("Sales"),
            icon: <LeaseAndSales />
        },
    ]?.map?.(_ => { return { ..._, icon_type: true } });

    const kyc_type = [
        {
            count: data?.kyc_type?.verified ?? 0,
            name: t("Verified KYC"),
            icon: <VerifiedIcon />
        },
        {
            count: data?.kyc_type?.pending ?? 0,
            name: t("Unverified KYC"),
            icon: <UnVerifiedIcon />
        },
        {
            count: data?.kyc_type?.valid ?? 0,
            name: t("Valid"),
            icon: <ValidIcon />
        },
        {
            count: data?.kyc_type?.app ?? 0,
            name: t("From App"),
            icon: <FromAppIcon />
        },
    ]

    const team_revenue = data?.team_revenue?.map?.(_ => {
        return {
            id: _?.team_id,
            team: _?.team_name ?? "-",
            total_revenue: `${company?.currency_symbol ?? ""}${_?.revenue ?? "0"}`,
            data: _,
        };
    });

    let quotation_total = data?.quotations_group?.map((_) => parseInt(_?.count ?? 0) ?? 0).reduce((a, b) => a + b, 0) ?? 0;

    let quotation_funnel_data = QuotationFunnelOptions?.({ t })?.map?.(_ => {
        const quotation = data?.quotations_group?.find?.(i => i?.type === _?.value);
        const name = _?.label;
        const count = parseInt(quotation?.count ?? 0);
        const fill = _?.backgroundColor;

        return { name, count, fill };
    })

    let quotation_funnel_graph_data = { total: quotation_total, data: quotation_funnel_data };

    let letter_type_total = data?.correspondence_type?.map((_) => parseInt(_?.count ?? 0) ?? 0).reduce((a, b) => a + b, 0) ?? 0;

    let letter_type_funnel_data = LetterTypeOptions?.({ t })?.map?.(_ => {
        const letter_type = data?.correspondence_type?.find?.(i => i?.type === _?.value);
        const name = _?.label;
        const count = parseInt(letter_type?.count ?? 0);
        const fill = _?.backgroundColor;

        return { name, count, fill };
    })

    let letter_type_graph_data = { total: letter_type_total, data: letter_type_funnel_data };

    const final_stat_data = {
        lead_count,
        revenue_summary,
        kyc_type,
        team_revenue,
        quotation_funnel_graph_data,
        letter_type_graph_data,
    };

    return final_stat_data;
}

export const constructResponseGetLeadSourceStats = (data) => {
    const default_color = "#FF9340"
    let lead_total = 0

    let lead_data = data?.map?.((lead, i) => {
        lead_total = lead_total + (lead?.count ? parseInt(lead?.count) : 0)
        return {
            name: lead?.type ?? "",
            fill: lead?.fill ?? default_color,
            count: lead?.count ? parseInt(lead?.count) : 0,
        }
    })

    return {
        total: lead_total,
        data: lead_data,
    };
}

export const constructResponseGetLeadFunnelStats = ({ data = [], t = () => false }) => {
    let lead_total = data?.map((_) => parseInt(_?.count ?? 0) ?? 0).reduce((a, b) => a + b, 0) ?? 0;

    let funnel_lead_data = LeadFunnelOptions?.({ t })?.map?.(_ => {
        const lead = data?.find?.(i => i?.type === _?.value);
        const label = _?.label;
        const value = parseInt(lead?.count ?? 0);
        const percentage = value / lead_total;
        const backgroundColor = _?.backgroundColor;

        return { label, value, percentage, backgroundColor };
    })

    return funnel_lead_data;
}

export const constructResponseGetOpportunitySourceStats = (data) => {
    const default_color = "#FF9340"
    let opportunity_total = 0

    let opportunity_data = data?.map?.((opportunity, i) => {
        opportunity_total = opportunity_total + (opportunity?.count ? parseInt(opportunity?.count) : 0)
        return {
            name: opportunity?.type ?? "",
            fill: opportunity?.fill ?? default_color,
            count: opportunity?.count ? parseInt(opportunity?.count) : 0,
        }
    })

    return {
        total: opportunity_total,
        data: opportunity_data,
    };
}

export const constructResponseGetOpportunityFunnelStats = ({ data = [], t = () => false, company }) => {
    let opportunity_total = data?.map((_) => parseInt(_?.count ?? 0) ?? 0).reduce((a, b) => a + b, 0) ?? 0;

    let opportunity_funnel_data = OpportunityFunnelOptions?.({ t })?.map?.(_ => {
        const opportunity = data?.find?.(i => i?.type === _?.value);
        const label = _?.label;
        const sub_label = `(${opportunity?.quotation ?? "0"} ${t("Quotation")} - ${company?.currency_symbol ?? ""} ${opportunity?.total_amount ?? "0"})`;
        const value = parseInt(opportunity?.count ?? 0);
        const percentage = value / opportunity_total;
        const tooltip_prefix = company?.currency_symbol;
        const backgroundColor = _?.backgroundColor;

        return { label, value, sub_label, percentage, tooltip_prefix, backgroundColor };
    })

    return opportunity_funnel_data;
}

export const constructResponseGetOpportunityRevenueStats = (data, t = () => false, company) => {
    const final_opportunity_revenue = {
        labels: data?.map?.(_ => _?.month_label),
        datasets: OpportunityRevenueOptions?.({ t, company })?.map?.(_ => {
            const datasets_data = data?.map?.(i => {
                let count = i?.data?.find?.(j => j?.status === _?.value)?.amount
                return parseInt(count ?? 0);
            });

            return { ..._, data: datasets_data };
        })
    }

    return final_opportunity_revenue;
}

export const constructResponseGetLeadStatusOwnerStats = ({ data = [], t = () => false, company }) => {
    const final_lead_status_owner = {
        labels: data?.data?.map?.(_ => _?.name),
        datasets: LeadStatusOptions?.({ t, company })?.map?.(_ => {
            const datasets_data = data?.data?.map?.(i => {
                let count = i?.data?.find?.(j => j?.type === _?.value)?.count
                return parseInt(count ?? 0);
            });

            return { ..._, data: datasets_data };
        })
    }

    return {
        total: data?.count,
        data: final_lead_status_owner,
    };
}

export const constructResponseGetOpportunityOwnerStats = ({ data = [], t = () => false, company }) => {
    const final_lead_status_owner = {
        labels: data?.data?.map?.(_ => _?.name),
        datasets: OpportunityOptions?.({ t, company })?.map?.(_ => {
            const datasets_data = data?.data?.map?.(i => {
                let count = i?.data?.find?.(j => j?.type === _?.value)?.count
                return parseInt(count ?? 0);
            });

            return { ..._, data: datasets_data };
        })
    }

    return {
        total: data?.count,
        data: final_lead_status_owner,
    };
}