import { Box, Grid } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { QuotationDetailCard } from "../components";
import { AggrementTabStyles } from "./styles";
import { IframeViwer, TemplateGenerator } from "../../../components";
import React from 'react'
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { AlertContext } from "../../../contexts";
const QuotationPreview = ({ details = {}, t = () => false }) => {
    const classes = AggrementTabStyles()

    const alert = React.useContext(AlertContext);

    const [template, setTemplate] = React.useState(false)
    const [pdf, setPdf] = React.useState({
        bool: false,
        data: ""
    })

    const QuotationCardHeader = {
        quotationNumber: details?.quotation_no,
        type: t("quotation"),
        sub1: details?.lease_period,
        sub2: details?.currency?.symbol
    }
    const QuotationCardTable = [
        {
            tableHeader: t("totalAmount"),
            amount: `${details?.currency?.symbol} ${details?.total_amount}`
        },
        {
            tableHeader: t("tax"),
            amount: `${details?.currency?.symbol} ${details?.total_tax}`
        },
        {
            tableHeader: t("totalQuoteAmount"),
            amount: `${details?.currency?.symbol} ${details?.total_amount + details?.total_tax}`,
            type: "total"
        }
    ]
    //download pdf
    const downloadPdf = () => {
        onClose()
    }



    const generate = (value, is_initial = false) => {
        if (!is_initial) onClose()
        setPdf({
            bool: false,
            data: ""
        })
        const payload = {
            id: details?.id,
            type_master_id: value?.value ?? undefined

        };
        NetworkCall(
            `${config.api_url}/quotation/get_quotation_pdf`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setPdf({
                    bool: true,
                    data: res?.data
                })

            })
            .catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("some thing went wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            });
    }

    const onClose = () => {
        setTemplate(!template)
    }

    React.useEffect(() => {
        generate(null, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box >
            <Grid container >
                <Grid item xs={4} p={2}>
                    <QuotationDetailCard onClick={downloadPdf} value={QuotationCardTable} headerDet={QuotationCardHeader} />
                </Grid>
                <Grid item xs={8} className={classes.pdfSection}>
                    <IframeViwer pdf={pdf} height={`250px`} />
                </Grid>
            </Grid>

            <TemplateGenerator t={t} name={t("Quotation Template")} btnText={t("Generate")} open={template} onClose={onClose} type={1} generate={generate} />
        </Box>
    )
}
export default withNamespaces("agreement")(QuotationPreview); 