import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { AgreementDetailCardStyle } from './style'

export const AgreementDetailCard = ({
    title = "",
    header = "",
    subheader = "",
    type = "",
    is_chip
}) => {
    const classes = AgreementDetailCardStyle()
    return (
        <Stack spacing={1} position={'relative'}>
            <Typography className={classes.title}>{title}</Typography>
            <Stack direction={"row"}>
                {type === "agreement" ? <img src={'/images/Group 102357.svg'} alt="agreement"/> : <img src={'/images/Group 102363.svg'} alt="account"/>}
                <Stack marginInlineStart={'16px'} width={'100%'}>
                    <Box display='flex' alignItems="center" justifyContent={'space-between'}>
                    <Typography className={classes.header} noWrap>{header}</Typography>            
                    {is_chip &&  <Box className={classes.chip}>Lease</Box>}
                    </Box>

                    <Typography className={classes.subheader}>{subheader}</Typography>
                </Stack>
            </Stack>

        </Stack>
    )
}