import React from "react";
import { withNavBars } from "../../HOCs";
import CheckListGroupView from "./checkListGroupView";

class CheckListGroupViewParent extends React.Component {
  render() {
    return <CheckListGroupView/>;
  }
}

export default withNavBars(CheckListGroupViewParent);