export const Department = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98380">
            <g data-name="Group 8127">
                <g data-name="Group 98373">
                    <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                    <g fill={props?.fill ?? "#c1c5cb"}>
                        <path
                            data-name="Path 94764"
                            d="M13.303 13.745v1.318h4.395v-1.318a2.2 2.2 0 0 0-4.395 0Zm0 0"
                        />
                        <path
                            data-name="Path 94765"
                            d="M17.258 17.697a1.758 1.758 0 1 0-1.758 1.758 1.76 1.76 0 0 0 1.758-1.758Zm0 0"
                        />
                        <path
                            data-name="Path 94766"
                            d="M11.955 14.182a1.756 1.756 0 0 0-1.318-1.7V9.758h3.103a1.758 1.758 0 1 0 3.516 0h3.105v2.728a1.758 1.758 0 1 0 .879 0V8.879h-4.227a1.742 1.742 0 0 0-3.027 0H9.758v3.607a1.755 1.755 0 1 0 2.2 1.7Zm0 0"
                        />
                        <path
                            data-name="Path 94767"
                            d="M20.8 15.939a2.2 2.2 0 0 0-2.2 2.2v1.318h1.758v2.666H17.7v-.469a2.2 2.2 0 1 0-4.395 0v.469h-2.668v-2.666h1.758v-1.318a2.2 2.2 0 1 0-4.395 0v1.318h1.758v3.545h11.484v-3.545H23v-1.318a2.2 2.2 0 0 0-2.2-2.2Zm0 0"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
