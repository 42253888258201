import React from "react";
import { withNavBars } from "../../HOCs";
import ContactView from "./contactView";

class ContactViewParent extends React.Component {
    render() {
        return <ContactView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ContactViewParent, props);
