import * as React from "react"

export const ExpiredIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 103315" transform="translate(-121 -201)">
      <circle
        data-name="Ellipse 129431"
        cx={20}
        cy={20}
        r={20}
        transform="translate(121 201)"
        fill="#f2f4f7"
      />
      <path
        data-name="icons8-calendar (1)"
        d="M149 216.556v-.667a2.892 2.892 0 0 0-2.889-2.889h-10.222a2.892 2.892 0 0 0-2.889 2.889v.667Zm-16 1.333v8.222a2.892 2.892 0 0 0 2.889 2.889h10.222a2.892 2.892 0 0 0 2.889-2.889v-8.222Zm4.222 8.444a1.111 1.111 0 1 1 1.111-1.111 1.111 1.111 0 0 1-1.111 1.111Zm0-4a1.111 1.111 0 1 1 1.111-1.111 1.111 1.111 0 0 1-1.111 1.111Zm3.778 4a1.111 1.111 0 1 1 1.111-1.111 1.111 1.111 0 0 1-1.111 1.111Zm0-4a1.111 1.111 0 1 1 1.111-1.111 1.111 1.111 0 0 1-1.111 1.111Zm3.778 0a1.111 1.111 0 1 1 1.111-1.111 1.111 1.111 0 0 1-1.111 1.111Z"
        fill="#98a0ac"
      />
    </g>
  </svg>
)
