import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Slider } from "../agreementDetails/components";
import { InspectionMasterStyles } from "./style";


export const TableView = ({ handleTableClose, viewData = "", t }) => {

    const classes = InspectionMasterStyles()
    return (
        <>
            <Box>
                <Box className={classes.viewImgBox1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center"
                        p={2} >
                        <Stack direction="row" alignItems="center" >
                            <Typography className={classes.viewHead}>{viewData.name}</Typography>
                            <Typography className={classes.viewBadge}>{viewData?.type}</Typography>
                        </Stack>
                        <IconButton onClick={handleTableClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Divider />
                    <Box p={2} mt={0.5}>
                        {viewData?.assets?.length > 0 ? (
                            <Slider
                                // onClick={onClickKyc}
                                isInventory={true}
                                assets={viewData?.assets?.length > 0 ? viewData.assets : []}
                                backgroundColor={"#000"}
                                pageNumber
                                padding={"0px 21px 0px 23px"}
                            />
                        ) :
                            <Box  >
                                <img className={classes.tempImage} src="/images/propertytumb.png" alt="" />
                            </Box>

                        }
                    </Box>
                </Box>
                <Box className={classes.viewImgBox}>
                    <Box p={2} className={classes.productdetails}>

                        <Typography className={classes.productHead}>{t("Product Description")}</Typography>
                        <Box>
                            <Typography className={classes.productDescr} mt={1}>
                                {viewData.description}
                            </Typography>

                        </Box>
                    </Box >
                </Box >
            </Box >

        </>
    )
}