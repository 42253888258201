import * as React from "react"
const NewContactIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 115026">
      <g fill="#f5f7fa" stroke="#e4e8ee" data-name="Rectangle 57916">
        <rect width={40} height={40} stroke="none" rx={4} />
        <rect width={39} height={39} x={0.5} y={0.5} fill="none" rx={3.5} />
      </g>
      <path
        fill="#98a0ac"
        d="M16.591 10a4.545 4.545 0 1 0 4.545 4.545A4.551 4.551 0 0 0 16.591 10Zm8.182 10a5 5 0 1 0 5 5 5 5 0 0 0-5-5Zm-13.081.909A1.755 1.755 0 0 0 10 22.718v.692a4.222 4.222 0 0 0 2.171 3.649 8.543 8.543 0 0 0 4.42 1.124 9.629 9.629 0 0 0 2.945-.45 5.891 5.891 0 0 1 .979-6.823Zm13.081.455a.455.455 0 0 1 .455.455v2.727h2.727a.455.455 0 1 1 0 .909h-2.728v2.727a.455.455 0 1 1-.909 0v-2.727h-2.727a.455.455 0 1 1 0-.909h2.727v-2.728a.455.455 0 0 1 .455-.454Z"
      />
    </g>
  </svg>
)
export default NewContactIcon
