import React from "react";
import { withNavBars } from "../../HOCs";
import { OwnerTable } from "./ownerTable";

class OwnerTableParent extends React.Component {
    render() {
        return <OwnerTable {...this.props} />
    }
}
const props = {
    boxShadow: false
  }

export default withNavBars(OwnerTableParent,props);
