export const DataManagement = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g
      id="Group_99672"
      data-name="Group 99672"
      transform="translate(-1527 504)"
    >
      <g
        id="Group_8127"
        data-name="Group 8127"
        transform="translate(-10 -1483)"
      >
        <g
          id="Group_99684"
          data-name="Group 99684"
          transform="translate(-4 -42)"
        >
          <path
            id="Rectangle_31"
            data-name="Rectangle 31"
            transform="translate(1541 1021)"
            style={{
              fill: "none",
            }}
            d="M0 0h30v30H0z"
          />
          <g
            id="data_management"
            data-name="data management"
            transform="translate(1547.078 1029)"
          >
            <path
              id="Path_95381"
              data-name="Path 95381"
              className="cls-2"
              d="M8.923 14.107c-2.816 0-5.177-.827-5.821-1.943a1.22 1.22 0 0 0-.179.612V14.6c0 1.41 2.687 2.555 6 2.555s6-1.144 6-2.555v-1.824a1.213 1.213 0 0 0-.181-.612c-.642 1.115-3 1.943-5.819 1.943Z"
              transform="translate(0 -5.928)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95382"
              data-name="Path 95382"
              className="cls-2"
              d="M8.923 21.464c-2.816 0-5.177-.828-5.821-1.942a1.214 1.214 0 0 0-.179.611v1.827c0 1.411 2.687 2.555 6 2.555s6-1.143 6-2.555v-1.827a1.2 1.2 0 0 0-.181-.61c-.642 1.112-3 1.941-5.819 1.941Z"
              transform="translate(0 -9.514)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95383"
              data-name="Path 95383"
              className="cls-2"
              d="M14.762 5.042C14.644 6.075 12.08 6.9 8.923 6.9S3.2 6.075 3.085 5.043a1.212 1.212 0 0 0-.163.585v1.825c0 1.411 2.687 2.555 6 2.555s6-1.143 6-2.555V5.627a1.217 1.217 0 0 0-.16-.585Z"
              transform="translate(0 -2.457)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95384"
              data-name="Path 95384"
              className="cls-2"
              d="M9.067 3.866c3.231 0 5.849-.865 5.849-1.933S12.3 0 9.067 0 3.219.866 3.219 1.933s2.619 1.933 5.848 1.933Z"
              transform="translate(-.145)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
