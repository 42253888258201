import React from "react"
import InspectionMapping from "./inspectionMapping"
import { withNavBars } from "../../HOCs"
const ParentInspectionMapping = () =>{
    return(
        <InspectionMapping />
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(ParentInspectionMapping,props)