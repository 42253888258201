import React from "react";

export const LeaseAndSales = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_243_2404)">
      <circle cx="25.3999" cy="21.9191" r="21" fill="#4D4CAC" />
    </g>
    <path
      d="M33.2498 14.5H25.4333C24.7718 12.9895 23.1488 11.9957 21.3353 12.307C19.8585 12.5605 18.6405 13.7245 18.3345 15.1915C17.8283 17.6155 19.665 19.75 21.9998 19.75C23.5365 19.75 24.8543 18.823 25.4333 17.5H27.8543C27.945 17.5 28.029 17.4535 28.077 17.3762L28.5878 16.5587C28.6733 16.4222 28.8593 16.3937 28.9815 16.498L30.0863 17.4422C30.1868 17.5277 30.3405 17.5 30.4043 17.3845L30.849 16.5737C30.9278 16.4305 31.116 16.393 31.2443 16.495L32.4998 17.5H33.2498L35.0265 16.0787C35.0768 16.0382 35.0768 15.9617 35.0265 15.9212L33.2498 14.5ZM20.8748 17.125C20.2538 17.125 19.7498 16.6217 19.7498 16C19.7498 15.3782 20.2538 14.875 20.8748 14.875C21.4958 14.875 21.9998 15.379 21.9998 16C21.9998 16.621 21.4958 17.125 20.8748 17.125Z"
      fill="white"
    />
    <path
      d="M22.3684 27.4675L20.4521 28.4013L19.1201 23.482L20.9936 22.5453C21.6986 22.1928 22.4756 22.009 23.2639 22.009H27.8996C28.5131 22.009 29.0464 22.522 29.0464 23.1558C29.0464 23.7355 28.5776 24.25 27.9964 24.2973L26.4476 24.4255C25.4591 24.5088 25.5821 26.002 26.5714 25.921L28.1194 25.7928C29.3186 25.6938 30.2876 24.7818 30.5006 23.6313L33.4204 22.8303C33.9461 22.6863 34.4936 22.9705 34.6781 23.4835C34.8596 23.9875 34.6294 24.5478 34.1456 24.778L27.9221 28.1478C27.2366 28.519 26.4334 28.609 25.6834 28.3983L22.3684 27.4675Z"
      fill="white"
    />
    <path
      d="M19.0533 30.091L14.5 32.5V24.625L17.1093 23.455C17.3193 23.3605 17.5645 23.4767 17.6245 23.6987L19.24 29.6612C19.2857 29.8307 19.2077 30.0092 19.0533 30.091Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_243_2404"
        x="0.399902"
        y="0.919067"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_243_2404"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_243_2404"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
