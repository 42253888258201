import * as React from "react"
const NotesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <defs>
      <linearGradient
        id="a"
        x2={1}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#33bef0" />
        <stop offset={1} stopColor="#0a85d9" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.5}
        x2={0.5}
        y1={0.034}
        y2={1.028}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#dedede" />
        <stop offset={1} stopColor="#d6d6d6" />
      </linearGradient>
    </defs>
    <g data-name="Group 115513">
      <path
        fill="url(#a)"
        d="M22.19 6H6.952A.952.952 0 0 0 6 6.952V22.19a.952.952 0 0 0 .952.952H22.19a.952.952 0 0 0 .952-.952V6.952A.952.952 0 0 0 22.19 6Z"
        data-name="Path 100927"
        transform="translate(-2 .857)"
      />
      <path
        d="M21.143 7.809a.952.952 0 0 0-.952-.952h-1.853l-8.237 8.239a.508.508 0 0 0-.133.235l-.8 3.194a.256.256 0 0 0 .311.311l3.194-.8a.509.509 0 0 0 .236-.134l8.234-8.245Z"
        data-name="Path 100928"
        opacity={0.05}
      />
      <path
        d="M21.143 7.809a.952.952 0 0 0-.955-.952h-1.513l-8.377 8.379a.414.414 0 0 0-.11.193l-.716 2.843a.211.211 0 0 0 .256.256l2.843-.716a.418.418 0 0 0 .193-.11l8.379-8.38Z"
        data-name="Path 100929"
        opacity={0.07}
      />
      <path
        fill="#c94f60"
        d="m23.844 5.529-1.373-1.372a.536.536 0 0 0-.758 0l-.645.643 2.13 2.13.647-.646a.537.537 0 0 0 0-.758"
        data-name="Path 100930"
      />
      <path
        fill="#f0f0f0"
        d="m10.43 15.44-.716 2.85 2.846-.717.3-2.469Z"
        data-name="Path 100931"
      />
      <path
        fill="#edbe00"
        d="m21.38 8.749-8.819 8.82-2.13-2.13 8.819-8.82Z"
        data-name="Path 100932"
      />
      <path
        fill="url(#b)"
        d="m38.023 3.5 1.816-1.817 2.131 2.13-1.816 1.819Z"
        data-name="Path 100933"
        transform="translate(-18.774 3.117)"
      />
      <path
        fill="#575757"
        d="m10.077 16.846-.363 1.439 1.439-.363Z"
        data-name="Path 100934"
      />
    </g>
  </svg>
)
export default NotesIcon
