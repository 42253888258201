import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import React from 'react';
import { CustomTypography, LocationIcon, LocationTarget } from ".";
import { Regular, SemiBold } from "../../../utils";

const Div = styled('div')({
  display: 'flex',
  marginTop: -9 //paddingtop 9 px for parent to align center
})

const Input = styled('input')({
  border: 0,
  padding: '5.8px 4px 8px 4px',
  width: '100%',
  color: '#091B29',
  fontFamily: SemiBold,
  fontSize:"0.875rem",
  '&::placeholder': {
    fontFamily: Regular,
    color: "#98A0AC"
  },
  '&:focus-visible': {
    outline: 'none'
  }
})

const Wrapper = styled(Stack)({
  border: '1px solid #E4E8EE',
  borderRadius: '4px',
  backgroundColor: '#fff',
  position: 'relative',
  overflow: 'hidden',
  padding: '9px',
  paddingBottom: '0px',
})

export const LocationTextField = props => {

  const { value, handleChange, inputRef, placeholder, label , getCurrentLocation } = props

  return (
    <Wrapper>
      <Stack direction={"row"} alignItems={"center"}>
        <div style={{ flex: 1 }}>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <LocationIcon />
            {label && (
              <CustomTypography
                fontFamily={SemiBold}
                fontSize={12}
                color={"#98A0AC"}
              >
                {label}
              </CustomTypography>
            )}
          </Stack>
          <Input
            ref={inputRef}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            placeholder={placeholder}
          />
        </div>
        {!props?.isPointer && (
          <Div onClick={getCurrentLocation}>
            <LocationTarget
              width={16}
              height={16}
              style={{ cursor: "pointer" }}
            />
          </Div>
        )}
      </Stack>
    </Wrapper>
  );
}
