import React from "react";
import { withNavBars } from "../../HOCs";
import { SalesCreation } from "./saleagent";

class SalesCreationParent extends React.Component {
    render() {
        return <SalesCreation />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(SalesCreationParent, props);