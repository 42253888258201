import React from "react";
import { withNavBars } from "../../HOCs";
import MarketingPortalConfigurartion from "./marketingPortalConfigurartion";

class MarketingPortalConfigurartionParent extends React.Component {
    render() {
        return <MarketingPortalConfigurartion />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(MarketingPortalConfigurartionParent, props);
