import React from "react";
import { withNavBars } from "../../HOCs";
import AlreadyPaid from "./alreadyPaid";

class AlreadyPaidParent extends React.Component {
    render() {
        return <AlreadyPaid />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AlreadyPaidParent, props);
