import React from "react";
import { withNavBars } from "../../HOCs";
import Contacts from "../contacts/contacts";

const ServiceProviderViewNew = () =>{
    return(
        <Contacts type="ServiceProvider" />
    )
}

export default withNavBars(ServiceProviderViewNew)