import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { FormGenerator } from "../../components"
import { StatusOptionList } from "../../utils/inventory"
import { useStylesManagement } from "./style"
import { ApartmentOutlineIcon } from "../../assets/appartmentOutlineIcon"
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
export const AddForm = ({ t, formdata = "", setFormData = () => false, updateState = () => false, type = "add", memberOptions = [], roleOptions = [], selectedMember = "", setSelectedMember = () => false, onFormSubmit = () => false, onCloseModal = () => false.valueOf, PropertyName = "", state = "", isDisableBtn = false }) => {
    const classes = useStylesManagement()
    const typeOptions = [
        {
            label: "Owner",
            value: "Owner"
        },
        {
            label: "Resident",
            value: "Resident"

        },
    ]

    const onSelectMember = (value) => {
        setSelectedMember(value)
        setFormData({
            ...formdata,
            "member_name": { value: value?.id, label: value?.name }
        })
    }
    const onRoleChange = (value) => {
        setFormData({
            ...formdata,
            "role": { value: value?.id, label: value?.role_name }
        })
    }
    const formComittee = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggle",
            label: t("Role Type"),
            value: formdata?.role_type,
            placeholder: t("Role Type"),
            onChange: (value) => updateState("role_type", value),
            error: formdata?.error?.role_type,
            isRequired: true,
            options: typeOptions
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "memberSelect",
            label: t("Member Name"),
            value: formdata?.member_name,
            placeholder: t("Member Name"),
            onChange: (value) => onSelectMember(value),
            error: formdata?.error?.member_name,
            isRequired: true,
            isPaginate: true,
            selectedMember: selectedMember,
            loadOptions: (search, array, handleLoading) => loadOptionsApis(
                "/owner-resident/members",
                {
                    property_id: state?.main?.propertyID,
                    is_owner: formdata?.role_type === "Owner" ? true : false
                },
                search,
                array,
                handleLoading, "data"
            ),
            memberDropdown: true,
            key: formdata?.role_type
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "memberSelect",
            label: t("Role"),
            value: formdata?.role,
            placeholder: "Role",
            onChange: (value) => onRoleChange(value),
            error: formdata?.error?.role,
            isRequired: true,
            isPaginate: true,
            isReadOnly: formdata.type === "edit" ? true : false,
            loadOptions: (search, array, handleLoading) => loadOptionsApis(
                "/management_commitee/roles",
                { property_id: state?.main?.propertyID, company_id: state?.main?.company_id },
                search,
                array,
                handleLoading, "data"
            )
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "date",
            label: t("Start Period"),
            value: formdata?.start_period,
            onChange: (value) => updateState("start_period", value),
            placeholder: t("Choose Start Period"),
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggle",
            label: t("Status"),
            value: formdata?.is_active,
            placeholder: t("Status"),
            onChange: (value) => updateState("is_active", value),
            error: formdata?.error?.status,
            isRequired: true,
            options: StatusOptionList
        },
    ]


    return (
        <Stack p={2} rowGap={"16px"}>
            <FormGenerator t={t} components={formComittee} />
            <Grid container>
                <Grid item xs={6}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Box mt={1}><ApartmentOutlineIcon /></Box>
                        <Box mt={1}>
                            <Typography className={classes.propertyName}>{PropertyName}</Typography>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="row" justifyContent={"end"} spacing={1}>
                        <Button variant="outlined" onClick={() => onCloseModal()} className={classes.cancelbtn}>{t("Cancel")}</Button>
                        <Button variant="contained" onClick={() => onFormSubmit()} disabled={isDisableBtn}>{formdata.type === "add" ? t("Add") : t("Save")}</Button>
                    </Stack>
                </Grid>
            </Grid>
        </Stack>
    )
}