export const ForgotPasswordIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={156} height={152} {...props}>
        <g data-name="Group 117060">
            <g data-name="Group 117025">
                <g fill="#eef9ee" data-name="Polygon 352">
                    <path d="M109.437 150.692c-.374 0-.751-.038-1.12-.115l-28.996-6.018a6.511 6.511 0 0 0-2.642 0l-28.997 6.018a5.522 5.522 0 0 1-1.119.115 5.473 5.473 0 0 1-4.905-3.016l-13.37-26.406a6.462 6.462 0 0 0-1.649-2.066L3.857 100.302a5.51 5.51 0 0 1-1.489-6.522l12.32-26.907a6.462 6.462 0 0 0 .588-2.578l.582-29.585a5.51 5.51 0 0 1 4.171-5.23l28.735-7.15a6.462 6.462 0 0 0 2.38-1.147L74.658 3.188A5.455 5.455 0 0 1 78 2.056c1.219 0 2.375.391 3.343 1.132l23.512 17.995c.711.545 1.512.93 2.38 1.146l28.736 7.152a5.51 5.51 0 0 1 4.17 5.229l.583 29.585c.017.896.215 1.764.588 2.578l12.32 26.907a5.51 5.51 0 0 1-1.49 6.522l-22.781 18.902a6.462 6.462 0 0 0-1.649 2.066l-13.37 26.406a5.473 5.473 0 0 1-4.905 3.016Z" />
                    <path
                        fill="#5ac782"
                        d="M78 2.556a4.96 4.96 0 0 0-3.039 1.03L51.45 21.58a6.96 6.96 0 0 1-2.564 1.234L20.15 29.966a5.009 5.009 0 0 0-3.792 4.754l-.582 29.585a6.96 6.96 0 0 1-.634 2.776L2.823 93.988a5.008 5.008 0 0 0 1.354 5.93l22.782 18.901a6.96 6.96 0 0 1 1.775 2.225l13.37 26.406a4.975 4.975 0 0 0 4.46 2.742c.34 0 .682-.035 1.017-.105l28.997-6.017a7.033 7.033 0 0 1 2.844 0l28.997 6.017c.335.07.678.105 1.018.105a4.975 4.975 0 0 0 4.459-2.742l13.37-26.406a6.96 6.96 0 0 1 1.775-2.225l22.782-18.901a5.008 5.008 0 0 0 1.354-5.93l-12.32-26.907a6.96 6.96 0 0 1-.633-2.776l-.582-29.585a5.009 5.009 0 0 0-3.792-4.754l-28.735-7.151a6.96 6.96 0 0 1-2.564-1.234L81.04 3.585A4.96 4.96 0 0 0 78 2.555m0-1a5.99 5.99 0 0 1 3.647 1.236l23.512 17.995a6 6 0 0 0 2.198 1.058l28.735 7.152a6 6 0 0 1 4.55 5.704l.581 29.585a6 6 0 0 0 .544 2.38l12.32 26.907a6 6 0 0 1-1.625 7.115L129.68 119.59a6 6 0 0 0-1.522 1.907l-13.37 26.406a6 6 0 0 1-6.572 3.165l-28.997-6.018a6 6 0 0 0-2.438 0l-28.997 6.018a6 6 0 0 1-6.572-3.165l-13.37-26.406a6 6 0 0 0-1.522-1.907L3.538 100.687a6 6 0 0 1-1.624-7.115l12.319-26.907a6 6 0 0 0 .543-2.38l.582-29.585a6 6 0 0 1 4.55-5.704l28.735-7.152a6 6 0 0 0 2.198-1.058L74.353 2.791A5.99 5.99 0 0 1 78 1.556Z"
                    />
                </g>
            </g>
            <path
                fill="#2a824b"
                d="M77.683 39a18.658 18.658 0 0 0-18.637 18.637v7.455h7.455v-7.455a11.182 11.182 0 1 1 22.364 0v7.455h7.455v-7.455A18.658 18.658 0 0 0 77.683 39"
                data-name="Path 101650"
            />
            <path
                fill="#5ac782"
                d="M101.91 113.547H53.455A7.455 7.455 0 0 1 46 106.091V72.546a7.455 7.455 0 0 1 7.455-7.455h48.455a7.455 7.455 0 0 1 7.455 7.455v33.545a7.455 7.455 0 0 1-7.455 7.455"
                data-name="Path 101651"
            />
            <path
                fill="#f1f8e9"
                d="M64.637 89.319a5.591 5.591 0 1 1-5.591-5.591 5.591 5.591 0 0 1 5.591 5.591m37.273 0a5.591 5.591 0 1 1-5.591-5.591 5.591 5.591 0 0 1 5.591 5.591m-18.637 0a5.591 5.591 0 1 1-5.59-5.591 5.591 5.591 0 0 1 5.591 5.591"
                data-name="Path 101652"
            />
        </g>
    </svg>
)