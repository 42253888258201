import React from 'react';
import { withNamespaces } from "react-i18next";
import { MiniPropertyDetail } from '../../../components';


const PropertyDetailsCard = ({ t, title = "", data = [] }) => {
    return (
        <MiniPropertyDetail
            logo={data?.image}
            property_no={data?.property_no}
            company_name={data?.company?.name}
            property_name={data?.propertyName}
            address={data?.address}
            t={t} />
    )
}

export default withNamespaces("inspection")(PropertyDetailsCard)