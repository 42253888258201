export const KycScreening = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g id="KYC" transform="translate(-30 9.95)">
      <path
        id="Rectangle_52129"
        data-name="Rectangle 52129"
        d="M0 0h30v30H0Z"
        transform="translate(30 -9.95)"
        style={{
          fill: "none",
        }}
      />
      <g id="Group_20" data-name="Group 20" transform="translate(30 -10)">
        <path
          id="Path_1"
          data-name="Path 1"
          className="cls-2"
          d="M14.9 15a3.076 3.076 0 0 1-3-3c0-1.7 2.3-4.5 2.6-4.8.2-.2.6-.2.7 0 .3.3 2.6 3 2.6 4.8a2.862 2.862 0 0 1-2.9 3Z"
          style={{
            fill: props?.fill ?? "#c1c5cb",
          }}
        />
        <path
          id="Path_2"
          data-name="Path 2"
          className="cls-2"
          d="M21.7 9.7h-3.1a6.943 6.943 0 0 1 .5 1.3h2.4v7.3H8.3V11h2.4a6.943 6.943 0 0 1 .5-1.3h-3A1.135 1.135 0 0 0 7 10.8v9A1.216 1.216 0 0 0 8.2 21h5.1a4.209 4.209 0 0 1-.8 1.4c-.1.1-.1.2-.1.4.1.1.2.2.3.2h4.7c.2 0 .3-.1.3-.2s.1-.3-.1-.4a3.438 3.438 0 0 1-.8-1.4h5.1a1.216 1.216 0 0 0 1.2-1.2v-9a1.5 1.5 0 0 0-1.4-1.1Z"
          style={{
            fill: props?.fill ?? "#c1c5cb",
          }}
        />
      </g>
    </g>
  </svg>
)