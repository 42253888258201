import React from 'react'
import { Drawer, Box, Stack, Typography, Button, Grid, Avatar } from '@mui/material'
import CloseIconComponent from '../../assets/closeIcons'
import { useStyles } from './style'
import { SearchFilter } from '../searchbar'
import { NetworkCall } from '../../networkcall';
import { NetWorkCallMethods } from '../../utils'
import { BackdropContext } from "../../contexts";
import { UseDebounce } from '../customHooks'
import { config } from '../../config'
import { DocumentViewer } from '../fileViewer'

const initial = {
    bool: false,
    search: "",
    data: [],
    offset: 0,
    selected: {}
}

export const TemplateGenerator = ({
    open = false,
    onClose = () => false,
    t = () => false,
    type,
    generate = () => false,
    name = "",
    btnText = t("Generate"),
    is_timeline=false,
    contact,
    onClick=()=>false,
}) => {
    const classes = useStyles()
    const backdrop = React.useContext(BackdropContext);

    const [state, setState] = React.useState({ ...initial })
    const [search, setSearch] = React.useState("")
    const [selected, setSelected] = React.useState({})
    const debounce = UseDebounce()

    const getData = (offset = 0, search = "", filter = false) => {
        if (!filter) {
            backdrop.setBackDrop({
                ...backdrop,
                open: true,
                message: "...Loading",
            });
        }
        let datas = {
            search,
            type
        }
        NetworkCall(
            `${config.api_url}/pdf-type-master/get_shortlist_templates`,
            NetWorkCallMethods.post,
            datas,
            null,
            true,
            false
        )
            .then((rs) => {
                setState({
                    ...state,
                    data: filter ? state?.data?.concat(rs?.data?.data) : rs?.data?.data,
                    offset: offset,
                })
                setSelected(rs?.data?.data?.find((x)=>x?.is_default === true))

                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "...Loading",
                });
            })
            .catch((er) => {
                console.log(er);
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "...Loading",
                });
            });
    };

   
    const handleSearch = (v) => {
        setSearch(v)
        debounce(() => getData(0, v), 1000);

    }

    const sumbit = () => {
        generate(selected)
    }

    const Viewer = React.useCallback(() => {
        return (
            <DocumentViewer url={selected?.image_url} waterMark />
        )
    }, [selected?.image_url])

    React.useEffect(() => {
        if (open) {
            getData()
            setState({ ...initial })
        }
        // eslint-disable-next-line
    }, [open])

    return (
        <React.Fragment key={'right'}>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={onClose}
            >
                <Box width={'700px'}>
                    {/* header */}
                    <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} className={classes.header}>
                        <Stack direction="row" spacing={2} alignItems={'center'}><Box style={{ cursor: "pointer" }}><CloseIconComponent onClick={onClose} /></Box><Typography className={classes.templateTitle}>{name}</Typography></Stack>

                         {!is_timeline&&
                        <Button className={classes.gnBtn} onClick={sumbit} disabled={selected?.value ? false : true}>{btnText}</Button>
                         }
                    </Stack >

                    {/* body */}
                    < Box >
                    {is_timeline &&
                    <Box className={classes.header}>
                    <Box display="flex" alignItems="center" className={classes.contactRoot}>
                        <Box>
                            <Avatar src={contact?.image_url?.length>0?contact?.image_url:null}/>
                        </Box>
                        <Box flexGrow={1} marginInlineStart={2}>
                             <Typography className={classes.name}>{contact?.first_name}</Typography>
                             <Typography className={classes.email}>{contact?.mobile_no_country_code??""}{contact?.mobile_no??""},{contact?.email_id??""}</Typography>
                        </Box>
                        <Box>
                            <Button variant='contained' onClick={()=>onClick(selected?.value)} className={classes.sendBtn}>{t("Send for preview")}</Button>
                        </Box>
                    </Box>
                    </Box>
                  }

                    

                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Box style={{ padding: "14px" }}>
                                    <Typography className={classes.bodyTitle}>{t("Other Templates")}</Typography>
                                    <Box height={'8px'} />
                                    <SearchFilter
                                        placeholder={t("Search Template")}
                                        value={search}
                                        handleChange={(value) => handleSearch(value)}
                                    />
                                    <Box height={'14px'} />

                                    <Box height={`calc(100vh - 192px)`} style={{overflow:"auto"}}  >
                                        <Grid container spacing={2}>

                                            {
                                                state?.data?.map((x) => {
                                                    return (
                                                        <Grid item xs={12}>

                                                            <Box className={classes.container} style={{ border: selected?.value === x?.value ? "1px solid #5078E1" : "1px solid #E4E8EE" }} onClick={() => setSelected(x)}>
                                                                <Box className={classes.pdfImg}>
                                                                    {/* <FileViewer fileType={'pdf'} filePath={x?.image_url} width={'100%'} /> */}
                                                                    {/* <img src={x?.image_url} alt='img' width={'100%'} height={'100%'}/> */}
                                                                    <DocumentViewer url={x?.image_url} />

                                                                </Box>
                                                                <Box className={classes.container1}>
                                                                    <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                                                        <Typography className={classes.language} noWrap>{x?.language}</Typography>
                                                                    </Stack>
                                                                    <Typography className={classes.templateTitle1}>
                                                                        {x?.label}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>

                                </Box>

                            </Grid>
                            <Grid item xs={8}>
                                <Box style={{ backgroundColor: '#F5F7FA', height: `calc(100vh - 88px)`, borderLeft: '1px solid #E4E8EE', overflow: "auto", textAlign: "center", padding: "16px" }}>
                                    {selected?.image_url ? <Viewer /> : ""}
                                </Box>
                            </Grid>

                        </Grid>
                    </Box >

                </Box >

            </Drawer >
        </React.Fragment >
    )
}