
import styled from '@mui/material/styles/styled';
import { Avatar, Button, Stack, Typography } from "@mui/material";
import { Bold, SemiBold } from '../../../utils';
export const BodyDiv = styled('div')({
    padding: "16px",
    height: `calc(100vh - 184px)`,
    overflow: "overlay"
})

export const ProfileDetailsStack = styled(Stack)((props) => ({
    backgroundColor: "white",
    borderRadius: props.theme.palette.borderRadius,
    padding: "16px"
}))

export const ProfileImageDiv = styled('div')({
    height: "100%",
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "16px 20px",
    textAlign: "-webkit-center"
})

export const ProfileDetailDiv = styled('div')({
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "16px 20px"
})

export const Image = styled(Avatar)((props) => ({
    cursor: "pointer",
    width: "141px",
    height: "141px",
    borderRadius: "71px",
    backgroundColor: "#F2F4F7",
    border: props?.src ? 0 : '1px solid #e3e5ec'
}))

export const UploadButton = styled(Button)({
    padding: "8px 16px",
    backgroundColor: "white",
    border: "1px solid #E4E8EE",
    color: "#071741",
    height: "32px",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    "&:hover": {
        backgroundColor: "#E4E8EE"
    }
})

export const RemoveButton = styled(Button)({
    padding: "0px",
    backgroundColor: "white",
    color: "#5078E1",
    height: "0px",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    textDecoration: "underline",
    "&:hover": {
        backgroundColor: "white"
    }
})

export const CardTitle = styled(Typography)({
    color: "#4E5A6B",
    fontSize:"0.75rem",
    fontFamily: Bold,
})

export const Title = styled(Typography)((props) => ({
    marginBottom: props?.marginBottom ?? "8px",
    color: "#98A0AC",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
}))

export const Desc = styled(Typography)((props) => ({
    color: "#091B29",
    fontSize:"0.875rem",
    fontFamily: SemiBold,
}))

export const AssignedDiv = styled('div')((props) => ({
    backgroundColor: "white",
    padding: "16px 20px",
    borderRadius: props?.theme.palette.borderRadius,
}))

export const CompanyRoleListDiv = styled('div')((props) => ({
    height: "79px",
    [props?.theme?.breakpoints?.down?.("lg")]: {
        height: "80px",
    },
    overflow: "overlay"
}))

export const PropertyListDiv = styled('div')((props) => ({
    height: "232px",
    [props?.theme?.breakpoints?.down?.("lg")]: {
        height: "234px",
    },
    overflow: "overlay"
}))

export const MapAddressDiv = styled('div')((props) => ({
    backgroundColor: "white",
    borderRadius: props?.theme.palette.borderRadius,
    padding: "16px",
}))

export const MapOuterDiv = styled('div')((props) => ({
    height: "250px"
}))

export const MapInnerDiv = styled('div')((props) => ({
    borderRadius: props?.theme.palette.borderRadius,
    overflow: "hidden",
    width: "100%",
    height: "100%"
}))

export const AddressDiv = styled('div')((props) => ({
    backgroundColor: "white",
    borderRadius: props?.theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    padding: "16px",
    height: "250px"
}))

export const FooterDiv = styled('div')({
    padding: "0px 16px",
    backgroundColor: "white",
    height: "64px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center"
})

export const CancelButton = styled(Button)({
    padding: "10px 16px",
    backgroundColor: "white",
    border: "1px solid #E4E8EE",
    color: "#5078E1",
    height: "40px",
    fontSize:"0.875rem",
    fontFamily: Bold,
    "&:hover": {
        backgroundColor: "#E4E8EE"
    }
})

export const SaveButton = styled(Button)({
    padding: "10px 16px",
    backgroundColor: "#5078E1",
    border: "1px solid #5078E1",
    color: "white",
    height: "40px",
    fontSize:"0.875rem",
    fontFamily: Bold
})