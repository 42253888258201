import { Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { correspondencesStyles } from './style'
import { AlertProps, NetWorkCallMethods, concat_string, timeZoneConverter } from '../../utils'
import { useHistory, useLocation } from 'react-router-dom'
import { ChatIcon } from '../../assets'
import { BackendRoutes, Routes } from '../../router/routes'
import { AlertDialog, Slider, Subheader, TemplateGenerator } from '../../components'
import { withNavBars } from '../../HOCs'
import { withNamespaces } from 'react-i18next'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import styled from '@mui/material/styles/styled';
import CloseIcon from '../../assets/closeIcon'
import { AlertContext, AuthContext } from '../../contexts'
import { NewLoader } from '../../components/newLoader'
import { useState } from 'react'
import { ConfirmationDialog } from './components/confirmationDialog'
import { AssetCard } from '../../components/resourceAsset/assetCard'

const CustomPaper = styled('div')(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 150px)`,
    overflow: "overlay",
    margin: "12px",
    position: "relative",
    padding: "12px"
}))

const CorrespondencesView = ({
    t = () => false,
}) => {
    const classes = correspondencesStyles()
    const state = useLocation()?.state
    const viewDialogData = state?.viewDialogData ?? {}


    const [isSeeMore, setIsSeeMore] = React.useState(true)
    const [template, setTemplate] = React.useState(false);
    const [baseValue, setBaseValue] = React.useState("")
    const [successDialogData, setSuccessDialogData] = React.useState({})
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = React.useState(false)
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
    const [confirmDialogData, setConfirmDialogData] = useState({})
    const history = useHistory()
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)
    const [selectedAsset, setSelectedAsset] = React.useState({
        asset: 0,
        bool: false
    })

    React.useEffect(() => {
        getPdf()
        // eslint-disable-next-line
    }, [])

    const getPdf = (v) => {
        const payload = {
            correspondence_id: viewDialogData?.correspondence_id,
            is_template: true,
            type_master_id: v?.value ?? undefined
        };
        NetworkCall(
            `${config.api_url}/correspondences/view_pdf`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setBaseValue(res?.data?.data)
                setTemplate(false)
            })
            .catch((error) => {
                console.log(error)
            });
    };

    //Function to handle send
    const handleSend = (id) => {
        setConfirmDialogData({
            question: t("Are you sure you want to publish this letter?"),
            yes_action: () => sendCorrespondence(id, 2),
            no_action: () => {
                setIsConfirmDialogOpen(false)
            },
        })
        setIsConfirmDialogOpen(true)
    }

    //Function to send correspondence
    const sendCorrespondence = (correspondence_id, status) => {
        let payload = { correspondence_id, status }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.correspondences_publish}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((res) => {
            setSuccessDialogData({
                success_message: t("Correspondences sent successfully!"),
                okay_action: () => {
                    setIsSuccessDialogOpen(false)
                    setIsConfirmDialogOpen(false)
                }
            })
            setIsSuccessDialogOpen(true)
        }).catch((error) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }


    const onClickAsset = (val) => {
        setSelectedAsset({
            bool: !selectedAsset?.bool,
            data: val,
        });
    };
    return (
        <>
            <Subheader title={t("View Correspondence")} goBack={() => { history.goBack() }} />
            <CustomPaper>
                <Stack spacing={"20px"} divider={<Divider classes={{ root: classes.divider }} />} p={1}>
                    <Stack direction={"row"} spacing={"8px"} alignItems={"center"} justifyContent={"space-between"}>
                        <Stack spacing={"4px"}>
                            <Typography className={classes.view_dialog_reference_no}>
                                {viewDialogData?.reference_no}
                            </Typography>
                            <Stack direction={"row"}>
                                <Typography className={classes.view_dialog_created}>
                                    &#x202a;{t("Created by") + " " +
                                        concat_string(
                                            {
                                                name: viewDialogData?.created_person ?? "-",
                                                date: viewDialogData?.created_at ?
                                                    timeZoneConverter(viewDialogData?.created_at, "DD MMM YY") : "-",
                                            },
                                            ["name", "date"],
                                            ", " + t("on") + " "
                                        )
                                    }&#x202c;
                                </Typography>
                                {viewDialogData?.is_read &&
                                    <Typography className={classes.view_dialog_created}>
                                        &#x202a;{", " + t("Read on") + " " +
                                            (viewDialogData?.read_on ?
                                                timeZoneConverter(viewDialogData?.read_on, "DD MMM YY") : "-")
                                        }&#x202c;
                                    </Typography>}
                            </Stack>
                        </Stack>
                        {viewDialogData?.status_id === 1 &&
                            <Stack direction={"row"} alignItems={"center"}>
                                <div className={classes.edit_button}
                                    onClick={() => history.push({
                                        pathname: Routes.edit_correspondences,
                                        state: {
                                            title: t("Edit") + " " + viewDialogData?.reference_no,
                                            level: viewDialogData?.level_id,
                                            id: viewDialogData?.correspondence_id
                                        }
                                    })}>
                                    <Typography className={classes.edit_button_text}>
                                        {t("Edit")}
                                    </Typography>
                                </div>
                                <div className={classes.send_button}
                                    onClick={() => handleSend(viewDialogData?.correspondence_id)}>
                                    <Typography className={classes.send_button_text}>
                                        {t("Send")}
                                    </Typography>
                                </div>
                            </Stack>}
                    </Stack>
                    {viewDialogData?.comment &&
                        <Stack direction={"row"} spacing={"8px"} alignContent={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} spacing={"8px"} alignItems={"start"} style={{ minWidth: "200px" }}>

                                <ChatIcon />
                                <Typography className={classes.replied_on_text}>
                                    {t("REPLIED ON") + " " +
                                        (viewDialogData?.replied_at ?
                                            timeZoneConverter(viewDialogData?.replied_at, "DD MMM YY") : "-")}
                                </Typography>
                            </Stack>
                            <Typography className={classes.reply_text}>
                                {((isSeeMore && viewDialogData?.comment?.length > 40) ?
                                    (viewDialogData?.comment?.substring(0, 40) + "... ") :
                                    (viewDialogData?.comment + " ")) ?? "-"}
                                <span className={classes.see_more_less_text}
                                    onClick={() => setIsSeeMore(!isSeeMore)}>
                                    {viewDialogData?.comment?.length > 40 ?
                                        (isSeeMore ? "See More" : "See Less") :
                                        ""}
                                </span>
                            </Typography>
                        </Stack>
                    }



                    {
                        baseValue?.length === 0 ?
                            <NewLoader /> :
                            <div className={classes.pdf_viewer_div}>
                                <Stack >
                                    <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
                                        <Typography className={classes.view_dialog_reference_no}>
                                            {t("Attachment")}
                                        </Typography>
                                        <Button variant='contained' onClick={() => setTemplate(true)}>{t("Generate")}</Button>
                                    </Stack>
                                    <Grid container spacing={2} mt={1} mb={2}>
                                        {
                                            state?.viewDialogData?.assets?.filter(i => i?.is_active)?.map((val, index) => {
                                                return (
                                                    <Grid item sm={6} lg={2} md={3} >
                                                        <Box>
                                                            <AssetCard
                                                             onClick={()=>onClickAsset(index)}
                                                                readOnly={true}
                                                                x={val} />
                                                        </Box>

                                                    </Grid >
                                                )
                                            })
                                        }
                                    </Grid>

                                    {/* <DocumentViewer url={viewDialogData?.file_url} /> */}
                                    < iframe
                                        src={`data:application/pdf;base64,${baseValue}`
                                        }
                                        alt="pdf"
                                        width="100%"
                                        style={{ height: `calc(100vh - 192px)` }}
                                        title="quotation"

                                    />
                                </Stack >


                            </div >}
                </Stack >
            </CustomPaper >

            <TemplateGenerator t={t} name={t("Correspondences Template")}
                btnText={t("Generate")}
                open={template} onClose={() => setTemplate(false)} type={5}
                generate={getPdf}
            />



            <Dialog
                className={classes.confirmSuccessDialog}
                open={isSuccessDialogOpen}
                onClose={() => setIsSuccessDialogOpen(false)}>
                <div className={classes.successDialogComponent}>
                    <div className={classes.confirmSuccessDialogHeader}>
                        <IconButton onClick={() => setIsSuccessDialogOpen(false)}
                            className={classes.dialogCloseButton}>
                            <CloseIcon htmlColor="#FFFFFF" height="14px" width="14px" />
                        </IconButton>
                    </div>
                    <div className={auth?.auth?.auth?.language === 'ar' ? classes.successDialogBodyRtl : classes.successDialogBody}>
                        <Stack spacing={"40px"} width={"300px"} alignItems={"center"}>
                            <Typography className={classes.confirm_question_success_message_text}>
                                {successDialogData?.success_message}
                            </Typography>
                            <div className={classes.okay_button}
                                onClick={() => { successDialogData?.okay_action() }}>
                                <Typography className={classes.yes_okay_button_text}
                                    onClick={() => history.replace(Routes.correspondences)}>
                                    {t("Okay")}
                                </Typography>
                            </div>
                        </Stack>
                    </div>
                </div >
            </Dialog >
            <ConfirmationDialog
                t={t}
                isConfirmDialogOpen={isConfirmDialogOpen}
                confirmDialogData={confirmDialogData}
                setIsConfirmDialogOpen={setIsConfirmDialogOpen}
                disableButton={false}
            />

            <AlertDialog
                open={selectedAsset?.bool}
                onClose={() => onClickAsset(0)}
                header={t("Attachment")}
                medium

                component={
                    <Box>
                        <Slider
                            height="650px"
                            onClick={onClickAsset}
                            assets={state?.viewDialogData?.assets?.map((val) => {
                                return {
                                    ...val,
                                    asset_type:val?.file_meta?.type==="application/pdf"?4:1
                                }
                            }) ?? []}
                            backgroundColor={"#000"}
                            pageNumber
                            padding={"0px 21px 0px 23px"}
                        />
                    </Box>
                }

            />
        </>
    )
}

const props = { boxShadow: false }
export default withNamespaces("correspondences")(withNavBars(CorrespondencesView, props))