import * as React from "react"

export const PropertyUnit = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 102949" transform="translate(-11563 -17227)">
            <circle
                data-name="Ellipse 129452"
                cx={12}
                cy={12}
                r={12}
                transform="translate(11563 17227)"
                fill="#f5f0f9"
            />
            <path
                d="M11573.333 17232.998a1.429 1.429 0 0 0-1.476 1.43v4.857h1a2.145 2.145 0 0 1 2.143 2.143v2.57a.99.99 0 0 1-.1.429h3.1a.429.429 0 0 0 .429-.429v-8.57a1.43 1.43 0 0 0-1.057-1.38l-3.714-1a1.407 1.407 0 0 0-.325-.05Zm.524 2.57h.571a.286.286 0 0 1 .286.286v.571a.286.286 0 0 1-.286.286h-.571a.286.286 0 0 1-.286-.286v-.569a.286.286 0 0 1 .286-.286Zm2 0h.571a.286.286 0 0 1 .286.286v.571a.286.286 0 0 1-.286.286h-.571a.286.286 0 0 1-.286-.286v-.569a.286.286 0 0 1 .286-.286Zm-2 2h.571a.286.286 0 0 1 .286.286v.571a.286.286 0 0 1-.286.286h-.571a.286.286 0 0 1-.286-.286v-.569a.286.286 0 0 1 .286-.286Zm2 0h.571a.286.286 0 0 1 .286.286v.571a.286.286 0 0 1-.286.286h-.571a.286.286 0 0 1-.286-.286v-.569a.286.286 0 0 1 .286-.286Zm3.143 0v2h.571a.286.286 0 0 1 .286.286v.571a.286.286 0 0 1-.286.286h-.571v.857h.571a.286.286 0 0 1 .286.286v.571a.286.286 0 0 1-.286.286h-.571v1.717h1.571a.429.429 0 0 0 .429-.43v-4.856a1.576 1.576 0 0 0-1.571-1.571Zm-3.143 2h.571a.286.286 0 0 1 .286.286v.571a.286.286 0 0 1-.286.286h-.571a.286.286 0 0 1-.286-.286v-.571a.286.286 0 0 1 .286-.284Zm-5.286.286a1.576 1.576 0 0 0-1.571 1.574v2.57a.429.429 0 0 0 .429.429h4.571a.429.429 0 0 0 .429-.429v-2.57a1.576 1.576 0 0 0-1.571-1.571Zm5.286 1.714h.571a.281.281 0 0 1 .223.114h.006v.016a.277.277 0 0 1 .057.162v.571a.279.279 0 0 1-.057.163v.004h-.006a.281.281 0 0 1-.223.114h-.571a.286.286 0 0 1-.286-.286v-.571a.286.286 0 0 1 .286-.285Zm-5.429 0h.572a.286.286 0 0 1 .286.286v.571a.286.286 0 0 1-.286.288h-.571a.286.286 0 0 1-.286-.286v-.571a.286.286 0 0 1 .286-.286Zm2 0h.572a.286.286 0 0 1 .286.286v.571a.286.286 0 0 1-.286.288h-.571a.286.286 0 0 1-.286-.286v-.571a.286.286 0 0 1 .286-.286Z"
                fill="#8f59c5"
                data-name="Group 102946"
            />
        </g>
    </svg>
)

