import * as React from "react"
export const LocationIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} {...props}>
        <g data-name="Group 108016">
            <path fill="none" d="M0 0h18v16H0z" data-name="Rectangle 56244" />
            <path
                // fill="#ced3dd"
                fill="currentColor"
                d="M9 0a6.91 6.91 0 0 0-7 6.8 6.692 6.692 0 0 0 1.685 4.419c.124.137 3.058 3.376 4.038 4.283a1.887 1.887 0 0 0 2.553 0c1.139-1.056 3.922-4.154 4.044-4.29A6.685 6.685 0 0 0 16 6.8 6.91 6.91 0 0 0 9 0Zm0 8.8a2 2 0 1 1 2.059-2A2.03 2.03 0 0 1 9 8.8Z"
                data-name="icons8-location (2)"
            />
        </g>
    </svg>
)
export const LocationIcon2 = (props) => (

    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} {...props}>
        <g data-name="Group 108017">
            <g data-name="Group 108016">
                <path fill="none" d="M0 0h18v16H0z" data-name="Rectangle 56244" />
                <path
                    // fill="#ced3dd"
                    fill="currentColor"

                    d="M5.1 0A4.083 4.083 0 0 0 1 4.053a4.028 4.028 0 0 0 .993 2.64c.069.077 1.679 1.881 2.223 2.392a1.3 1.3 0 0 0 1.772 0c.632-.594 2.16-2.321 2.229-2.4a4.021 4.021 0 0 0 .988-2.634A4.083 4.083 0 0 0 5.1 0Zm0 5.128A1.026 1.026 0 1 1 6.128 4.1 1.026 1.026 0 0 1 5.1 5.128Zm7.8 1.436a4.083 4.083 0 0 0-4.1 4.053 4.028 4.028 0 0 0 .993 2.64c.069.077 1.679 1.881 2.223 2.392a1.3 1.3 0 0 0 1.772 0c.632-.594 2.16-2.321 2.229-2.4A4.021 4.021 0 0 0 17 10.617a4.083 4.083 0 0 0-4.1-4.053Zm0 5.128a1.026 1.026 0 1 1 1.026-1.026 1.026 1.026 0 0 1-1.026 1.027Z"
                />
            </g>
        </g>
    </svg>

)
