import * as React from "react"
const CreditActive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g transform="translate(-5 -4)">
      <path
        fill="#ff9d6c"
        d="M6 23.637V8.364h19.636v15.273a1.094 1.094 0 0 1-1.091 1.091H7.091A1.094 1.094 0 0 1 6 23.637Z"
        data-name="Path 100832"
      />
      <path
        fill="#c6541b"
        d="M25.636 6.182v3.273H6V6.182a1.094 1.094 0 0 1 1.091-1.091h17.454a1.094 1.094 0 0 1 1.091 1.091Z"
        data-name="Path 100833"
      />
      <path
        d="M22.091 8.636A1.636 1.636 0 0 1 20.455 7V5.091h3.273V7a1.636 1.636 0 0 1-1.637 1.636Z"
        data-name="Path 100834"
        opacity={0.05}
      />
      <path
        d="M22.091 8.227A1.227 1.227 0 0 1 20.864 7V5.091h2.45V7a1.227 1.227 0 0 1-1.223 1.227Z"
        data-name="Path 100835"
        opacity={0.07}
      />
      <path
        d="M9.545 8.636A1.636 1.636 0 0 1 7.909 7V5.091h3.273V7a1.636 1.636 0 0 1-1.637 1.636Z"
        data-name="Path 100836"
        opacity={0.05}
      />
      <path
        d="M9.545 8.227A1.227 1.227 0 0 1 8.318 7V5.091h2.45V7a1.227 1.227 0 0 1-1.223 1.227Z"
        data-name="Path 100837"
        opacity={0.07}
      />
      <path
        fill="#ffad84"
        d="M9.545 7.818A.818.818 0 0 1 8.727 7V4.818A.818.818 0 0 1 9.545 4a.818.818 0 0 1 .818.818V7a.818.818 0 0 1-.818.818Z"
        data-name="Path 100838"
      />
      <path
        fill="#c6541b"
        d="M23.454 12.455v1.636a.273.273 0 0 1-.273.273h-1.636a.273.273 0 0 1-.273-.273v-1.636a.273.273 0 0 1 .273-.273h1.636a.273.273 0 0 1 .273.273Zm-4.364 0v1.636a.273.273 0 0 1-.273.273h-1.636a.273.273 0 0 1-.273-.273v-1.636a.273.273 0 0 1 .273-.273h1.636a.273.273 0 0 1 .273.273Zm-4.364 0v1.636a.273.273 0 0 1-.273.273h-1.635a.273.273 0 0 1-.273-.273v-1.636a.273.273 0 0 1 .273-.273h1.636a.273.273 0 0 1 .273.273Z"
        data-name="Path 100839"
      />
      <path
        fill="#c6541b"
        d="M23.455 16.273v1.636a.273.273 0 0 1-.273.273h-1.636a.273.273 0 0 1-.273-.273v-1.636a.273.273 0 0 1 .273-.273h1.636a.273.273 0 0 1 .273.273Zm-4.364 0v1.636a.273.273 0 0 1-.273.273h-1.636a.273.273 0 0 1-.273-.273v-1.636a.273.273 0 0 1 .273-.273h1.636a.273.273 0 0 1 .273.273Zm-4.364 0v1.636a.273.273 0 0 1-.273.273h-1.636a.273.273 0 0 1-.273-.273v-1.636a.273.273 0 0 1 .273-.273h1.636a.273.273 0 0 1 .273.273Zm-4.364 0v1.636a.273.273 0 0 1-.273.273H8.455a.273.273 0 0 1-.273-.273v-1.636A.273.273 0 0 1 8.455 16h1.636a.273.273 0 0 1 .273.273Z"
        data-name="Path 100840"
      />
      <path
        fill="#c6541b"
        d="M19.091 20.091v1.636a.273.273 0 0 1-.273.273h-1.636a.273.273 0 0 1-.273-.273v-1.636a.273.273 0 0 1 .273-.273h1.636a.273.273 0 0 1 .273.273Zm-4.364 0v1.636a.273.273 0 0 1-.273.273h-1.636a.273.273 0 0 1-.273-.273v-1.636a.273.273 0 0 1 .273-.273h1.636a.273.273 0 0 1 .273.273Zm-4.364 0v1.636a.273.273 0 0 1-.273.273H8.455a.273.273 0 0 1-.273-.273v-1.636a.273.273 0 0 1 .273-.273h1.636a.273.273 0 0 1 .273.273Z"
        data-name="Path 100841"
      />
      <path
        fill="#ffad84"
        d="M22.091 7.818A.818.818 0 0 1 21.273 7V4.818A.818.818 0 0 1 22.091 4a.818.818 0 0 1 .818.818V7a.818.818 0 0 1-.818.818Z"
        data-name="Path 100842"
      />
      <circle
        cx={5.455}
        cy={5.455}
        r={5.455}
        fill="#e2ae24"
        data-name="Ellipse 130022"
        transform="translate(18 17.091)"
      />
      <circle
        cx={4.364}
        cy={4.364}
        r={4.364}
        fill="#ffc429"
        data-name="Ellipse 130023"
        transform="translate(19.091 18.182)"
      />
      <path
        fill="#8d6c16"
        d="M22.237 24.555v-.813a1.776 1.776 0 0 0 .943.271.884.884 0 0 0 .589-.179.6.6 0 0 0 .212-.487q0-.641-.907-.641a5.717 5.717 0 0 0-.714.054v-2.412h2.391v.777H23.17v.864q.19-.018.358-.018a1.475 1.475 0 0 1 1.042.349 1.221 1.221 0 0 1 .376.94 1.386 1.386 0 0 1-.449 1.067 1.722 1.722 0 0 1-1.217.414 2.584 2.584 0 0 1-1.043-.186Z"
        data-name="Path 100844"
      />
    </g>
  </svg>
)
export default CreditActive
