export const DeliveryMilestone = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="delivery milestone">
            <g data-name="Group 96941">
                <path
                    data-name="Path 93625"
                    d="M22.955 17.339V12.66h-1.433a3.749 3.749 0 0 0-3.715-3.275h-5.186a2.808 2.808 0 1 0 0 .936h5.186a2.812 2.812 0 0 1 2.768 2.34h-2.3v1.871H12.66a3.28 3.28 0 0 0-3.247 2.842 2.808 2.808 0 1 0 3.208 3.241h4.993l-.6.6.662.662 1.729-1.732-1.735-1.735-.662.662.6.6h-4.987a2.815 2.815 0 0 0-2.262-2.287 2.343 2.343 0 0 1 2.3-1.918h5.616v1.872ZM10.32 10.32h-.935v-.935h.936Zm9.825 4.212h.936v.936h-.936Zm-9.825 6.083h-.935v-.936h.936Z"
                    fill={props?.fill ?? "#c1c5cb"}
                />
            </g>
            <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
