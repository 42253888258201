export const IntegrationMarketplace = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g
      id="Group_99669"
      data-name="Group 99669"
      transform="translate(-1505 512)"
    >
      <g
        id="Group_8127"
        data-name="Group 8127"
        transform="translate(-10 -1483)"
      >
        <g
          id="Group_99684"
          data-name="Group 99684"
          transform="translate(-4 -42)"
        >
          <path
            id="Rectangle_31"
            data-name="Rectangle 31"
            transform="translate(1519 1013)"
            style={{
              fill: "none",
            }}
            d="M0 0h30v30H0z"
          />
          <g id="marketplace" transform="translate(1527 1020.5)">
            <path
              id="Path_95389"
              data-name="Path 95389"
              className="cls-2"
              d="M15 7.7V1.818A1.32 1.32 0 0 0 13.682.5H1.318A1.32 1.32 0 0 0 0 1.818V7.7ZM6.827 5.016H2.622a.439.439 0 1 1 0-.879h4.205a.439.439 0 1 1 0 .879Zm0-1.4H2.622a.439.439 0 1 1 0-.879h4.205a.439.439 0 1 1 0 .879Zm0 2.8H2.622a.439.439 0 1 1 0-.879h4.205a.439.439 0 1 1 0 .879Zm2.755.463a.439.439 0 1 1 .439-.439.439.439 0 0 1-.439.441Zm1.216 0a.439.439 0 1 1 .439-.439.439.439 0 0 1-.437.441Zm1.962-3.073-.994 1.748a.439.439 0 0 1-.382.222H9.352a.44.44 0 0 1-.439-.44V3.163h-.227a.439.439 0 0 1 0-.879h.668a.439.439 0 0 1 .439.439v.425h2.584a.44.44 0 0 1 .382.657Zm0 0"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95390"
              data-name="Path 95390"
              className="cls-2"
              d="M0 276.219v1.18a1.32 1.32 0 0 0 1.318 1.318h4.328v1.565H4.432a.439.439 0 0 0 0 .879h6.136a.439.439 0 0 0 0-.879H9.354v-1.565h4.328A1.32 1.32 0 0 0 15 277.4v-1.18Zm7.31.41h.38a.439.439 0 0 1 0 .879h-.38a.439.439 0 0 1 0-.879Zm0 0"
              transform="translate(0 -267.641)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
