import { Box } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { TableWithPagination } from "../../../components";
import { getReceiptHeading, receiptdataType, receiptPath } from "../../../utils/receipt";
import { SearchAndFilter } from "./index";

const ReceiptTable = ({
    handleSearch = () => false,
    searchText = "",
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    handleIcon = () => false,
    value = {},
    openfilter = () => false,
    filterData = {},
    permission = {},
    t
}) => {
    const reciptHeading = getReceiptHeading(t);

    return (
        <Box>
            {/*search and filter */}
            <SearchAndFilter
                permission={permission}
                selected={value}
                openfilter={openfilter}
                handleSearch={handleSearch}
                searchText={searchText}
                filterData={filterData}
            />
            {/*table */}
            <TableWithPagination
                heading={reciptHeading}
                rows={list?.list}
                path={receiptPath}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                showpagination={true}
                handleIcon={handleIcon}
                dataType={receiptdataType}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={list?.count}
                page={page}
                limit={limit}
                height={`calc(100vh - 300px)`}
                view={permission?.read}
                edit={permission?.update}
                delete={permission?.delete} />
        </Box>
    )
}

export default withNamespaces("receipt")(ReceiptTable); 