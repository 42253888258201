import * as React from "react"
const TotalAfterTaxes = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <g data-name="Group 115513">
      <g data-name="icons8-cash (7)">
        <path
          fill="#a5d6a7"
          d="M2.571 12.714h21.714v11.429H2.571Z"
          data-name="Path 100915"
        />
        <path
          fill="#388e3c"
          d="M2 24.714h22.857V12.143H2Zm21.714-11.428v10.286H3.143V13.286Z"
          data-name="Path 100916"
        />
        <path
          fill="#388e3c"
          d="M6.571 17.285a1.143 1.143 0 1 0 1.143 1.143 1.143 1.143 0 0 0-1.143-1.143Zm6.857-2.857a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm-8.445-1.714H2.571v2.412a1.707 1.707 0 0 0 2.412-2.412Zm0 11.429a1.707 1.707 0 0 0-2.412-2.412v2.412Zm19.3 0v-2.412a1.707 1.707 0 0 0-2.412 2.412Z"
          data-name="Path 100917"
        />
        <path
          fill="#ff9800"
          d="M20.572 7.571A5.429 5.429 0 1 0 26.001 13a5.429 5.429 0 0 0-5.429-5.429Z"
          data-name="Path 100918"
        />
        <path
          fill="#ffcc80"
          d="M20.571 8.778A4.222 4.222 0 1 0 24.793 13a4.222 4.222 0 0 0-4.222-4.222Z"
          data-name="Path 100919"
        />
        <path
          fill="#ffc107"
          d="M16.857 3a6.286 6.286 0 1 0 6.286 6.286A6.286 6.286 0 0 0 16.857 3Z"
          data-name="Path 100920"
        />
        <path
          fill="#81c784"
          d="M19.143 18.429a1.115 1.115 0 0 1 .019-.191 5.393 5.393 0 0 1-1.9-.939 3.994 3.994 0 0 1 .165 1.131c0 .111-.007.221-.017.33a6.524 6.524 0 0 0 2.632.786 1.142 1.142 0 0 1-.899-1.117Zm2.286 0a1.139 1.139 0 0 1-1.047 1.133c.064 0 .126.01.19.01a6.532 6.532 0 0 0 3.143-.8v-1.351a5.39 5.39 0 0 1-2.293.935c.001.021.007.048.007.073Z"
          data-name="Path 100921"
        />
        <path
          fill="#2e7d32"
          d="M24.286 16.951a5.421 5.421 0 0 1-.571.469v1.349a6.5 6.5 0 0 0 1.143-.8v-1.646a5.464 5.464 0 0 1-.572.628Zm-2.857 1.477c0-.025-.006-.049-.007-.073a5.162 5.162 0 0 1-2.259-.118 1.115 1.115 0 0 0-.019.191 1.142 1.142 0 0 0 .9 1.116c.111.009.224.014.337.017a1.139 1.139 0 0 0 1.048-1.133Z"
          data-name="Path 100922"
        />
        <path
          fill="#81c784"
          d="M11.844 14.755a3.981 3.981 0 0 1 1.414-.318 6.329 6.329 0 0 1-1.25-1.151h-1.4a7.468 7.468 0 0 0 1.236 1.469Z"
          data-name="Path 100923"
        />
        <path
          fill="#2e7d32"
          d="M12.009 13.286a6.285 6.285 0 0 1-.745-1.143H9.999a7.4 7.4 0 0 0 .606 1.143Zm5.255 4.012a5.489 5.489 0 0 1-.655-.6 5.416 5.416 0 0 1-.869-1.235 6.2 6.2 0 0 1-2.478-1.03 3.981 3.981 0 0 0-1.414.318 7.4 7.4 0 0 0 3.151 1.719 6.622 6.622 0 0 0 2.411 2.289c.009-.109.016-.219.016-.33a3.994 3.994 0 0 0-.162-1.131Z"
          data-name="Path 100924"
        />
        <path
          fill="#ffe082"
          d="M16.857 4.143A5.143 5.143 0 1 0 22 9.286a5.143 5.143 0 0 0-5.143-5.143Z"
          data-name="Path 100925"
        />
        <path
          fill="#ffc107"
          d="m14.817 9.423.471-2.994h3.2v1.006h-2.215l-.232 1.239a2.229 2.229 0 0 1 1.05-.205 1.747 1.747 0 0 1 1.338.5 1.682 1.682 0 0 1 .469 1.283 1.948 1.948 0 0 1-.251.982 1.689 1.689 0 0 1-.7.675 2.291 2.291 0 0 1-1.072.237 2.475 2.475 0 0 1-1.031-.219 1.833 1.833 0 0 1-.755-.6 1.289 1.289 0 0 1-.269-.814h1.225a.616.616 0 0 0 .205.444 1.024 1.024 0 0 0 .618.182c.455 0 .81-.325.81-.974 0-.6-.259-.876-.817-.876a1.169 1.169 0 0 0-.817.408Z"
          data-name="Path 100926"
        />
      </g>
    </g>
  </svg>
)
export default TotalAfterTaxes
