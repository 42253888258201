import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { TableWithPagination } from "../../../components";
import { Contactheading, ContactPath } from "../../../utils/quotations";
import { Contact } from "../components";
import { tabStyles } from "./styles";
import {timeZoneConverter} from "../../../utils";

export const AggreementDetails = ({ data = {}, units = [], contact = [] }) => {


    const classes = tabStyles()
    const [selectedContact, setSelectedContact] = React.useState(contact[0])
    return (
        <div>
            <Box p={2} className={classes.card}>
                <Typography className={classes.title}>AGREEMENT DETAILS</Typography>
                {/*agreement details */}
                <Grid container spacing={2}>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Agreement Number</Typography>
                        <Typography className={classes.sub}>{data?.agreement_no}</Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Account Number</Typography>
                        <Typography className={classes.sub}>{data?.account?.account_no}</Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Account Name</Typography>
                        <Typography className={classes.sub}>{data?.account?.name}</Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Generated Date</Typography>
                        <Typography className={classes.sub}>
                            {timeZoneConverter(data?.created_at)}
                        </Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        {
                            data?.quotation?.revenue_type === "Sale" ?
                                <>
                                    <Typography className={classes.heading}>Handover Date</Typography>
                                    <Typography className={classes.sub}>
                                        {timeZoneConverter(data?.quotation?.occpation_date)}
                                    </Typography>
                                </>
                                :
                                <>
                                    <Typography className={classes.heading}>Lease Start Date</Typography>
                                    <Typography className={classes.sub}>
                                        {timeZoneConverter(data?.lease_start_date)}
                                    </Typography>
                                </>

                        }

                    </Grid>
                    <Grid item md={3} lg={2}>
                        {
                            data?.quotation?.revenue_type === "Sale" ?
                                <>
                                    <Typography className={classes.heading}>Contract Start Date</Typography>
                                    <Typography className={classes.sub}>
                                        {timeZoneConverter(data?.quotation?.contract_start_date)}
                                    </Typography>
                                </>

                                :
                                <>
                                    <Typography className={classes.heading}>Lease End Date</Typography>
                                    <Typography className={classes.sub}>
                                        {timeZoneConverter(data?.lease_end_date)}
                                    </Typography>
                                </>
                        }

                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Agreement Type</Typography>
                        <Typography className={classes.sub}>{data?.quotation?.unit_usage}</Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Primary Contact Name</Typography>
                        <Typography className={classes.sub}>{data?.primary_contact?.first_name ?? ""}&nbsp;{data?.primary_contact?.last_name ?? ""}</Typography>
                    </Grid>
                    <Grid item md={3} lg={2}>
                        <Typography className={classes.heading}>Contact Number</Typography>
                        <Typography className={classes.sub}>({data?.primary_contact?.mobile_no_country_code}){data?.primary_contact?.mobile_no}</Typography>
                    </Grid>

                </Grid>
            </Box>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {/*agreement unit user list */}
                    <Contact
                        contact={contact}
                        onContactSelect={(e) => setSelectedContact(e)}
                        selectedContact={selectedContact} />
                </Grid>
                <Grid item xs={8}>
                    <Box p={2} className={classes.card}>
                        {/*unit table*/}
                        <TableWithPagination
                            heading={Contactheading}
                            rows={units}
                            path={ContactPath}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            tableType="no-side"
                            dataType={[
                                { type: ["text"], name: "unit_type" },
                                { type: ["text"], name: "unit_no" },
                                { type: ["text"], name: "unit_name" },
                                { type: ["text"], name: "property_name" },


                            ]}
                            totalRowsCount={5}
                            height={`calc(100vh - 450px)`}
                            view={true}
                            edit={true}
                            delete={true} />
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}