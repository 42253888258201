import makeStyles from "@mui/styles/makeStyles";

export const useStylesInspection = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        padding: "16px",
        backgroundColor: "white",
        margin: "14px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",

    },
    filerIcon: {
        border: "1px solid " + theme.palette.border.secondary,
        borderRadius: theme.palette.borderRadius,
    },
    girdItem: {
        alignItems: "flex-end"
    },
    filterIconBox: {
        textAlign: "end"
    },
    moveoutTable: {
        height: 'calc(100vh - 285px)',
    },
    filterIconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "4px",
        padding: "12px"
    },
}))