import { Avatar, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { stringAvatar } from '../../../utils'
import { PopupCardStyle } from './style'

export const PopupCard = ({
    img_url = "",
    title = "",
    subtitle = "",
    data = {},
    handleClick = () => false,
    selected={},
    type={},
    t
}) => {
    const classes = PopupCardStyle()
    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                className={selected?.[type === "account" ? "name" : type === "property" ? "property_name" : 'unit_name'] === data?.[type === "account" ? "name" : type === "property" ? "property_name" : 'unit_name'] ? classes.selectedCard : classes.card} p={2} mt={1}
                onClick={() => handleClick(data)}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Avatar className={classes.avatar} src={img_url} {...stringAvatar(title)}></Avatar>
                    <Stack sx={{marginInlineStart:"8px"}}>
                        <Typography className={classes.title}>{title ?? "-"}</Typography>
                        <Typography className={classes.subtitle}> {subtitle} </Typography>
                    </Stack>
                </Stack>
                {selected?.[type === "account" ? "name" : type === "property" ? "property_name" : 'unit_name'] === data?.[type === "account" ? "name" : type === "property" ? "property_name" : 'unit_name']  && <Icon color="primary">check_circle</Icon>}
            </Stack>
        </>
    )
}