export const SwiftEntries = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Shift Entries">
            <g data-name="Group 96941" fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 93625"
                    d="M22.596 6.987H7.404a.417.417 0 0 0-.417.417v4.8a.417.417 0 0 0 .417.417h1.111a.417.417 0 0 0 .417-.417V8.931h12.136v12.137H8.931v-3.281a.417.417 0 0 0-.417-.417h-1.11a.417.417 0 0 0-.417.417v4.8a.417.417 0 0 0 .417.417h15.192a.417.417 0 0 0 .417-.417V7.404a.417.417 0 0 0-.417-.417Z"
                />
                <path
                    data-name="Path 93626"
                    d="M7.983 14.445v1.111a.417.417 0 0 0 .417.417h5.986l-1.887 1.885a.417.417 0 0 0 0 .589l.786.786a.417.417 0 0 0 .589 0l3.939-3.935a.417.417 0 0 0 0-.589l-3.938-3.938a.417.417 0 0 0-.589 0l-.786.786a.417.417 0 0 0 0 .589l1.886 1.886H8.4a.417.417 0 0 0-.417.413Z"
                />
            </g>
            <path data-name="Rectangle 52035" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
