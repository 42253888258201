import React from "react"

export const UnitReadinessIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_110226" data-name="Group 110226" transform="translate(-184 -328)">
    <rect id="Rectangle_55241" data-name="Rectangle 55241" width="32" height="32" rx="2" transform="translate(184 328)" fill="#ffdbdb"/>
    <g id="Group_111487" data-name="Group 111487" transform="translate(0 -1)">
      <g id="Rectangle_56608" data-name="Rectangle 56608" transform="translate(188 333)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-smart-home-checked_2_" data-name="icons8-smart-home-checked (2)" d="M12.857,4a.574.574,0,0,0-.354.123L7.089,8.387A2.838,2.838,0,0,0,6,10.631V17.9a.953.953,0,0,0,.952.952h6.194a4.95,4.95,0,0,1,6.567-7.218V10.631a2.838,2.838,0,0,0-1.089-2.244L13.211,4.121A.574.574,0,0,0,12.857,4Zm4.19,7.619a4.19,4.19,0,1,0,4.19,4.19A4.19,4.19,0,0,0,17.047,11.617ZM19.333,13.9a.381.381,0,0,1,.269.65L16.555,17.6a.381.381,0,0,1-.539,0l-1.524-1.524a.381.381,0,0,1,.539-.539l1.254,1.254,2.778-2.778A.38.38,0,0,1,19.333,13.9Z" transform="translate(186 333.002)" fill="#ce6f6f"/>
    </g>
  </g>
</svg>


)