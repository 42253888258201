import React from "react";
import {
  TableWithPagination,
  TextBox,
  MobileNumberInputComponent,
} from "../../components";
import { Grid } from "@mui/material";
import { ScreensStylesParent } from "../style";
import { NewUserheading, NewUserheading2, NewUserpath } from "../../utilities";

export const AddNewUser = (props) => {
  const { t } = props
  // style

  const classes = ScreensStylesParent();
  return (
    <div className={classes.dialogParent}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextBox
            placeholder={t("Name")}
            value={props?.addRole?.personname}
            onChange={(e) => props?.updateState("personname", e?.target?.value)}
            label={t("Name")}
            isError={props?.addRole?.error?.personname?.length > 0}
            errorMessage={props?.addRole?.error?.personname}
            isReadonly
          />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            placeholder={t("Email ID")}
            value={props?.addRole?.email}
            onChange={(e) => props?.updateState("email", e?.target?.value)}
            label={t("Email ID")}
            isError={props?.addRole?.error?.email?.length > 0}
            errorMessage={props?.addRole?.error?.email}
            isReadonly
          />
        </Grid>
        <Grid item xs={6}>
          <MobileNumberInputComponent
            label={t("Contact Number")}
            placeholder={t("Contact Number")}
            value={props?.addRole?.phone}
            handleChange={(value) => {
              props?.updateState("phone", value);
            }}
            isError={props?.addRole?.error?.phone?.length > 0}
            errorMessage={props?.addRole?.error?.phone}
            onKeyPress={(e) => {
              if (e.key === "e") {
                e.preventDefault();
              }
            }}
            isReadonly
          />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            placeholder={t("Employee ID")}
            value={props?.addRole?.employeeID}
            onChange={(e) => props?.updateState("employeeID", e?.target?.value)}
            label={t("Employee ID")}
            isError={props?.addRole?.error?.employeeID?.length > 0}
            errorMessage={props?.addRole?.error?.employeeID}
            isReadonly
          />
        </Grid>
        <Grid item xs={12}>
          <TableWithPagination
            heading={props?.moduleOption?.length > 0 ? NewUserheading(t) : NewUserheading2(t)}
            rows={props?.addRole?.access_roles ?? []}
            path={NewUserpath}
            showpdfbtn={false}
            showexcelbtn={false}
            showSearch={false}
            handleIcon={props?.handleIconRole}
            addrolePopFunction={() => props?.addrolePopFunction()}
            totalRowsCount={props?.addRole?.access_roles?.length}
            dataType={[
              { type: ["text"], name: "Module" },
              { type: ["text"], name: "Role" },
              { type: ["text"], name: "company" },
              { type: ["more_2"], icon: "icon" },
            ]}
            height={"calc(100vh - 380px)"}
            view={true}
            edit={true}
            delete={true} />
        </Grid>
      </Grid>
    </div>
  );
};
