import React from 'react';
import { unitStyles } from "../style";
import { Grid, Box, Typography, Avatar } from "@mui/material";
import { stringAvatar } from '../../../utils';


export const AddMemberCard = (props) => {

      const classes = unitStyles();
      return (
            // add member box
            <Grid container spacing={3}>
                  {
                        props?.data?.map((x, index) => {
                              return (

                                    <Grid item xs={6} md={3} lg={3}>
                                          <div className={classes.card} style={{ textAlign: 'center' }}>
                                                <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '23px' }}>
                                                      <Box className={classes.managericon}>
                                                            <img src='/images/manager.svg' alt='manager' />
                                                      </Box>
                                                      <Box >
                                                            <Typography className={classes.roles}>{x?.name}</Typography>
                                                      </Box>
                                                </Box>
                                                <Box className={classes.selectBox}>
                                                      <Box>
                                                            <Avatar src={props?.member?.image_url ?? ""} className={classes.avatarcard} {...stringAvatar(props?.member?.first_name)} />
                                                      </Box>
                                                      <Box>
                                                            <Typography className={classes.select}>{props?.member?.first_name ?? ""}</Typography>
                                                      </Box>
                                                </Box>



                                          </div>
                                    </Grid>

                              )
                        })
                  }
            </Grid>
      )
}