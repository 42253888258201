import React from "react";
import { withNavBars } from "../../HOCs";
import  GeneralInspection from "./generalInspection";
class GeneralInspectionParent extends React.Component {
  render() {
    return <GeneralInspection />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(GeneralInspectionParent, props);