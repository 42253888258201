export const TimeOffRequests = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g
            fillRule="evenodd"
            style={{
                fill: props?.fill ?? "#c1c5cb",
            }}
            data-name="Group 105175"
        >
            <path
                data-name="Path 98228"
                d="M14 23a8 8 0 1 1 7.99-8.245 4.985 4.985 0 0 0-1.641-.785 6.454 6.454 0 0 0-1.855-3.557 6.415 6.415 0 1 0-3.516 10.946 5.012 5.012 0 0 0 1.254 1.325A7.824 7.824 0 0 1 14 23Zm-1.661-6.746a.307.307 0 0 1-.265-.153.312.312 0 0 1 .112-.418l1.488-.856V12.86a.3.3 0 0 1 .306-.306.307.307 0 0 1 .306.306V15a.3.3 0 0 1-.153.265l-1.641.948a.246.246 0 0 1-.153.041Zm-.539-4.76a.307.307 0 0 1-.265-.153l-.429-.741a.305.305 0 0 1 .112-.418.312.312 0 0 1 .418.112l.428.744a.309.309 0 0 1-.112.418.288.288 0 0 1-.152.038ZM10.189 13.1a.288.288 0 0 1-.153-.041l-.744-.428a.306.306 0 1 1 .306-.53l.744.428a.315.315 0 0 1 .112.418.307.307 0 0 1-.265.153Zm-.744 4.831a.3.3 0 0 1-.265-.153.3.3 0 0 1 .112-.408l.744-.438a.306.306 0 0 1 .306.53l-.744.428a.288.288 0 0 1-.153.045Zm1.926 1.926a.288.288 0 0 1-.153-.041.3.3 0 0 1-.112-.418l.428-.744a.306.306 0 0 1 .53.306l-.428.744a.307.307 0 0 1-.265.157Zm6.44-6.757a.3.3 0 0 1-.265-.153.315.315 0 0 1 .112-.418l.744-.428a.306.306 0 1 1 .306.53l-.744.428a.288.288 0 0 1-.153.041Zm-1.61-1.61a.288.288 0 0 1-.153-.041.309.309 0 0 1-.112-.418l.428-.744a.306.306 0 0 1 .53.306l-.428.744a.307.307 0 0 1-.266.157Zm3.5 2.354a5.869 5.869 0 0 0-5.391-4.657v1.417a.306.306 0 1 1-.611 0V9.191a5.829 5.829 0 0 0-5.5 5.5H9.6a.307.307 0 0 1 .3.309.3.3 0 0 1-.306.306H8.191a5.822 5.822 0 0 0 5.5 5.5V19.4a.307.307 0 0 1 .309-.3.292.292 0 0 1 .224.1 7.993 7.993 0 0 1-.02-.489 4.91 4.91 0 0 1 4.9-4.9 5.76 5.76 0 0 1 .596.037Z"
            />
            <path
                data-name="Path 98229"
                d="M19.105 23a4.3 4.3 0 0 1-4.29-4.29 4.225 4.225 0 0 1 .071-.805l8.265 2.211A4.278 4.278 0 0 1 19.105 23Zm-4.056-5.687a4.29 4.29 0 0 1 8.347 1.4 4.257 4.257 0 0 1-.082.815Z"
            />
        </g>
    </svg>
)