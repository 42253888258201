import React from "react"

export const CancelRequestIcon = () =>(
<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
  <g id="Group_113346" data-name="Group 113346" transform="translate(16872 14726)">
    <circle id="Ellipse_129692" data-name="Ellipse 129692" cx="18" cy="18" r="18" transform="translate(-16872 -14726)" fill="#ff4b4b"/>
    <line id="Line_1835" data-name="Line 1835" x2="10.898" y2="10.898" transform="translate(-16859.449 -14713.449)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75"/>
    <line id="Line_1836" data-name="Line 1836" y1="10.898" x2="10.898" transform="translate(-16859.449 -14713.449)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.75"/>
  </g>
</svg>

)