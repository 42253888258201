import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Button, Divider, Drawer, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import FilterIMG from '../../assets/filter';
import { FilterGenerator, SearchFilter, SelectBox, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { NewLoader } from '../../components/newLoader';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { AlertProps, NetWorkCallMethods, accessCheckRender, activeOptions, activeOptionsList, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, useWindowDimensions } from '../../utils';
import { loadOptionsApis } from '../../utils/asyncPaginateLoadOptions';
import { useStyles } from "./style";
import { Heading, Path, Type, defaultAddState, defaultFilterData } from './utils/tableUtils';

const COAMaster = (props) => {
    const { loading, handleLoading, t } = props;
    const size = useWindowDimensions()
    const classes = useStyles()
    const debounce = UseDebounce()

    // useContext
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)

    // useState
    const [data, setData] = React.useState({
        row: []
    })
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState(defaultFilterData)
    const [fiterDrawer, setFilterDrawer] = React.useState(false)
    const [addDialogOpen, setAddDialogOpen] = React.useState(false)
    const [addState, setAddState] = React.useState(defaultAddState)
    const [permission, setPermission] = React.useState({})
    const [loader, setLoader] = React.useState(true)
    const [buttonDisable, setButtonDisable] = React.useState(false)
    const [selectedCompany, setSelectedCompany] = React.useState(false)
    const [companyList, setCompanyList] = React.useState([])
    const [options, setOptions] = React.useState({
        coa_type: []
    })

    // useEffect to get permission and data when loading the screen
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getOption()
                    getData(company?.selected?.value, 0, limit, searchText, filterData)
                }

            }
        }
        // eslint-disable-next-line
    }, [auth])

    // Function to get options
    const getOption = async () => {
        const result = await enumSelect([enum_types?.coa_type])
        setOptions({ coa_type: result?.coa_type })
    }

    // Function to get the list based on the input data
    const getData = (companyId = "", offset = 0, limit = 10, search = "", filter = defaultFilterData) => {
        let payload = {
            offset, limit, search,
            company_id: companyId,
            is_active: (!filter?.is_active || filter?.is_active?.length === 0) ?
                [true, false] : filter?.is_active,
            type: filter?.type?.map((x) => { return x?.value }) ?? [],
            ledger_id: filter?.ledger?.map((x) => { return x?.value }) ?? [],
            sub_ledger_id: filter?.subLedger?.map((x) => { return x?.value }) ?? []
        }
        NetworkCall(
            `${config.api_url}/com_master/list`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            const result = response?.data?.data?.data?.map((val) => {
                return {
                    ...val,
                    number: val?.number ?? "-",
                }
            })
            setData({
                row: result ?? [],
                totalRowsCount: response?.data?.data?.count ?? 0
            })
            setLoader(false)
            handleLoading(false)
        }).catch((e) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        });
    }


    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in the list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getData(selectedCompany?.value, 0, limit, e, filterData)
    }

    // Function to open add form
    const handleAdd = () => {
        setButtonDisable(false)
        setAddState({
            ...defaultAddState, error: {
                name: "",
                type: "",
                reference_number: "",
                ledger: "",
                subLedger: "",
                number: "",
                opening_balance: "",
                credit_balance: "",
                debit_balance: "",
                opening_balance_date: ""
            }
        })
        setAddDialogOpen(true)
    }

    // Function to handle icon in table row
    const getDataById = (type, data) => {
        let payload = {
            id: data?.id
        }
        NetworkCall(
            `${config.api_url}/com_master/get`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            const tempData = response?.data?.data
            // const defaultNumber = tempData?.number?.split("-")
            const tempAddState = {
                formType: type,
                id: tempData?.id,
                name: tempData?.name,
                // defaultNumber: tempData?.sub_ledger_number,
                number: tempData?.number,
                ledger: { value: tempData?.ledger_id, label: tempData?.ledger_name },
                subLedger:tempData?.sub_ledger_name?.length>0? { value: tempData?.sub_ledger_id, label: tempData?.sub_ledger_name }:"",
                type: tempData?.coa_type ? options?.coa_type?.find?.(({ value }) => value === tempData?.coa_type) : "",
                is_active: type === "active" ? !tempData?.is_active : tempData?.is_active,
                credit_balance: tempData?.credit_balance,
                debit_balance: tempData?.debit_balance,
                opening_balance: tempData?.opening_balance,
                opening_balance_date:tempData?.opening_balance_date,
                error: defaultAddState?.error
            }
            setAddState({ ...tempAddState })
        }).catch((error) => {
            console.log(error)
        });
    }
    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        if (type === "edit" || type === "view") {
            getDataById(type, data)
            setButtonDisable(false)
            setAddDialogOpen(true)
        }
        else if (type === "active" || type === "delete") { handleCreateEdit({ ...data, formType: type, is_active: type === "active" ? !data?.is_active : data?.is_active, }) }
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getData(selectedCompany?.value, offset, limit, searchText, filterData)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getData(selectedCompany?.value, 0, value, searchText, filterData)
    }

    // Function to update addState
    const updateAddDialogState = (k, v) => {
        let error = addState?.error
        error[k] = ""
        if (k === "subLedger") {
            setAddState({ ...addState, [k]: v,defaultNumber: v?.number,name:(addState?.number?.length>0?addState?.number+" - ":"")+(addState?.ledger?.label?.length>0?(addState?.ledger?.label+" , "):"")+v?.label, error })
        }
        else if(k==="number"){
            setAddState({...addState,[k]:v,name:v.concat((addState?.ledger?.label?.length>0?(" - "+addState?.ledger?.label):"")+(addState?.subLedger?.label?.length>0?(" , "+addState?.subLedger?.label):""))})
        }
        else if (k === "ledger") {
            setAddState({ ...addState, [k]: v, defaultNumber: "", subLedger: "",name:(addState?.number?.length>0?(addState?.number+" - "):"")+v?.label, error })
        }
        else if (k === "type") {
            setAddState({ ...addState, [k]: v, defaultNumber: "", ledger: "", subLedger: "",name:addState?.number,error })
        }
        else {
            setAddState({ ...addState, [k]: v, error })
        }
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = addState.error
        if (addState?.name?.length === 0) { isValid = false; error.name = t("Name is Required") }
        if (addState?.type?.length === 0) { isValid = false; error.type = t(" Account type is Required") }
        if (addState?.number?.length === 0) { isValid = false; error.number = t("Number is Required") }
        if (addState?.ledger?.length === 0) { isValid = false; error.ledger = t("Ledger is Required") }
        // if (addState?.subLedger?.length === 0) { isValid = false; error.subLedger = t("SubLedger is Required") }
        // if (addState?.credit_balance?.length === 0) { isValid = false; error.credit_balance = t("Credit Balance is Required") }
        // if (addState?.debit_balance?.length === 0) { isValid = false; error.debit_balance = t("Debit Balance is Required") }
        // if (addState?.opening_balance?.length === 0) { isValid = false; error.opening_balance = t("Opening Balance is Required") }
        // if (addState?.opening_balance_date?.length === 0) { isValid = false; error.opening_balance_date = t("Opening Balance Date is Required") }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setAddState({ ...addState, error })
        return isValid
    }

    // Function to create a leave master
    const handleCreateEdit = async (data) => {
        if ((data?.formType === "active" || data?.formType === "delete") ? true : validate()) {
            setButtonDisable(true)
            const payload = {
                "id": data?.id ?? undefined,
                "company_id": selectedCompany?.value,
                "name": data?.name,
                "number": parseInt(data?.number),
                "com_ledger_id": data?.ledger?.value,
                "type": data?.type?.value,
                "com_sub_ledger_id": data?.subLedger?.value,
                "credit_balance": parseInt(data?.credit_balance),
                "debit_balance": parseInt(data?.debit_balance),
                "opening_balance": parseInt(data?.opening_balance),
                "opening_balance_date": data?.opening_balance_date??null,
                "is_active": data?.is_active
            }
            NetworkCall(
                `${config.api_url}/com_master/upsert`,
                NetWorkCallMethods.post, payload, null, true, false
            ).then((r) => {
                setLoader(true)
                setAddState({ ...defaultAddState })
                setPage(1)
                setSearchText("")
                getData(selectedCompany?.value, 0, limit, "", filterData)
                setAddDialogOpen(false)
                alert.setSnack({
                    ...alert, open: true,
                    severity: AlertProps.severity.success,
                    msg: `${data?.id ? `${data?.formType === "delete" ? "Deleted" : "Updated"}` : `Created`} Successfully`,
                })
            }).catch((error) => {
                setButtonDisable(false)
                if (error?.response) {
                    alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error, msg: error?.response?.data?.errors?.errors?.[0]?.msg
                    })
                }
                else {
                    alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
                    })
                }
            });

        } else { return false }
    }

    // Function to filter
    const handleFilter = (value) => {
        setLoader(true)
        setFilterData(value)
        setPage(1)
        getData(selectedCompany?.value, 0, limit, searchText, value)
    }
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getData(value?.value, 0, limit, searchText, value)
    }
    const manualResponse = (data) => {
        let result = data?.data?.map((val) => {
            return {
                number: val?.number,
                value: val?.id,
                label: val?.name
            }
        })
        return result;
    }
    const manualResponseForSubLedger = (data) => {
        let result = data?.data?.map((val) => {
            return {
                number: val?.number,
                value: val?.id,
                label: val?.name
            }
        })
        return result;
    }
    const onClearFilter = () => {
        setFilterData({ ...defaultFilterData, is_active: [] })
    }
    const render = () => {
        return <div >
            <Subheader hideBackButton={true} title={t("Chart of Accounts Master")} select value={selectedCompany} options={companyList} onchange={(e) => {
                handleCompanyChange(e)
            }} />
            {
                loader ? (
                    <NewLoader minusHeight="100px" />
                ) : (
                    <div className={classes.root}>
                        <Grid container className={classes.content} spacing={1}>
                            <Grid item xs={4}>
                                <SearchFilter value={searchText} placeholder={t("Search by Name,Number")}
                                    handleChange={(value) => handleSearch(value)} />
                            </Grid>
                            <Grid item xs={8}>
                                <Box display={"flex"} justifyContent={"end"}>
                                    <Stack direction="row" spacing={2}
                                        divider={<Divider orientation="vertical" flexItem />}>
                                        <IconButton onClick={() => setFilterDrawer(!fiterDrawer)}
                                            className={classes.filterButton}>
                                            <Badge variant="dot" color="primary"
                                                invisible={!(filterData.is_active?.length > 0)}>
                                                <FilterIMG color="#091b29" />
                                            </Badge>
                                        </IconButton>
                                        <Button variant="contained" className={classes.button}
                                            onClick={handleAdd}>
                                            {t("Add")}
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                        <TableWithPagination
                            heading={Heading(t)}
                            rows={data?.row}
                            path={Path}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            handleIcon={handleTableIcon}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={Type}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={data?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={true}
                            edit={true}
                            delete={false} />
                        <FilterGenerator open={fiterDrawer} onClose={() => setFilterDrawer(false)}
                            onApply={(value) => handleFilter(value)}
                            is_customFilter={true}
                            clearFilter={onClearFilter}
                            components={[
                                {
                                    component: "select_box",
                                    is_active: true,
                                    value: filterData?.type,
                                    state_name: "type",
                                    label: t("COA Type"),
                                    options: options?.coa_type,
                                    onChange: (value) => setFilterData({ ...filterData, type: value, ledger: [], sub_ledger: [] }),
                                    isMulti: true
                                },
                                {
                                    component: "select_box",
                                    value: filterData?.ledger,
                                    is_active: true,
                                    state_name: "ledger",
                                    label: t("Ledger"),
                                    onChange: (value) => setFilterData({ ...filterData, ledger: value, sub_ledger: [] }),
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            `com_ledger/list`,
                                            {
                                                "company_id": selectedCompany?.value,
                                                type: filterData?.type?.map((x) => { return x?.value }) ?? [],
                                                is_active: [true]
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                            manualResponse,
                                        ),
                                    isPaginate: true,
                                    key: JSON.stringify(filterData?.type),
                                    isMulti: true
                                },
                                {
                                    component: "select",
                                    value: filterData?.subLedger,
                                    state_name: "subLedger",
                                    label: t("SubLedger"),
                                    loadOptions: (search, array, handleLoading) =>
                                        loadOptionsApis(
                                            `/com_sub_ledger/list`,
                                            {
                                                "company_id": selectedCompany?.value,
                                                type: filterData?.type?.map((x) => { return x?.value }) ?? [],
                                                ledger_id: filterData?.ledger?.map((x) => { return x?.value }) ?? [],
                                                is_active: [true]
                                            },
                                            search,
                                            array,
                                            handleLoading,
                                            "data",
                                            {},
                                            manualResponseForSubLedger,
                                        ),
                                    isPaginate: true,
                                    key: JSON.stringify({ type: filterData?.type, ledger: filterData?.ledger }),
                                    isMulti: true
                                },
                                {
                                    component: "toggleButton",
                                    value: filterData?.is_active,
                                    options: activeOptions(t),
                                    isMulti: true,
                                    state_name: "is_active",
                                    label: t("Status")
                                },
                            ]} />
                        <Drawer
                            className={classes.addDialog}
                            open={addDialogOpen}
                            anchor={"right"}
                            onClose={() => setAddDialogOpen(false)}>
                            <Box width="500px">
                                <div className={classes.addDialogHeader}>
                                    <Typography className={classes.addDialogHeaderTitle}>
                                        {addState?.formType === "add" ? t("Add New Chart of Accounts") :
                                            addState?.formType === "edit" ? t("Edit Chart of Accounts") :
                                                addState?.formType === "view" ? t("View Chart of Accounts") : t("Add New Chart of Accounts")}
                                    </Typography>
                                    <IconButton onClick={() => setAddDialogOpen(false)}
                                        className={classes.addDialogCloseButton}>
                                        <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                                    </IconButton>
                                </div>
                                <div className={classes.addDialogBody} style={{ height: size?.height - 130, overflow: "scroll" }}>
                                    <Grid container spacing={1} alignItems={"center"}>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <SelectBox
                                                label={t("Account Type")}
                                                placeholder={t("Select Account Type")}
                                                onChange={(val) => updateAddDialogState("type", val)}
                                                options={options?.coa_type}
                                                value={addState?.type ?? ""}
                                                isError={addState?.error?.type?.length > 0}
                                                errorMessage={addState?.error?.type}
                                                menuPlacement={"bottom"}
                                                isRequired
                                                isReadOnly={addState?.formType === "view"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <TextBox
                                                isrequired
                                                label={t("Number")}
                                                placeholder={t("Enter Number")}
                                                type="number"
                                                // startAdornment={<InputAdornment position="end"><Typography sx={{ fontSize: "0.875rem", color: "#091B29" }}>{`${addState?.defaultNumber} -`}</Typography></InputAdornment>}
                                                padding={"14px 14px 14px 4px"}
                                                paddingLeft={"5px"}
                                                value={addState?.number ?? ""}
                                                isReadonly={addState?.formType === "view"}
                                                onChange={(e) => updateAddDialogState("number", e.target.value)}
                                                isError={addState?.error?.number?.length > 0}
                                                errorMessage={addState?.error?.number}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box height={16} />
                                    <Grid container spacing={1} alignItems={"center"}>
                                        <Grid item xs={12} sm={6} lg={6}>
                                    <SelectBox
                                        label={t("Ledger")}
                                        placeholder={t("Select Ledger")}
                                        onChange={(val) => updateAddDialogState("ledger", val)}
                                        loadOptions={(search, array, handleLoading) =>
                                            loadOptionsApis(
                                                `com_ledger/list`,
                                                {
                                                    "company_id": selectedCompany?.value,
                                                    type: addState?.type?.value?.length > 0 ? [addState?.type?.value] : [],
                                                    is_active: [true]
                                                },
                                                search,
                                                array,
                                                handleLoading,
                                                "data",
                                                {},
                                                manualResponse,
                                            )}
                                        isPaginate={true}
                                        paddingLeft={"0px"}
                                        key={JSON.stringify(addState?.type?.value)}
                                        value={addState?.ledger ?? ""}
                                        isError={addState?.error?.ledger?.length > 0}
                                        errorMessage={addState?.error?.ledger}
                                        menuPlacement={"bottom"}
                                        isRequired
                                        isReadOnly={addState?.formType === "view"}
                                    />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={6}>
                                    <SelectBox
                                        label={t("SubLedger")}
                                        placeholder={t("Select SubLedger")}
                                        onChange={(val) => updateAddDialogState("subLedger", val)}
                                        loadOptions={(search, array, handleLoading) =>
                                            loadOptionsApis(
                                                `/com_sub_ledger/list`,
                                                {
                                                    "company_id": selectedCompany?.value,
                                                    type: addState?.type?.value?.length > 0 ? [addState?.type?.value] : [],
                                                    ledger_id: addState?.ledger?.value ? [addState?.ledger?.value] : [],
                                                    is_active: [true]
                                                },
                                                search,
                                                array,
                                                handleLoading,
                                                "data",
                                                {},
                                                manualResponseForSubLedger,
                                            )}
                                        isPaginate={true}
                                        paddingLeft={"0px"}
                                        key={JSON.stringify({ type: addState?.type, ledger: addState?.ledger })}
                                        value={addState?.subLedger ?? ""}
                                        // isError={addState?.error?.subLedger?.length > 0}
                                        // errorMessage={addState?.error?.subLedger}
                                        menuPlacement={"bottom"}
                                        // isRequired
                                        isReadOnly={addState?.formType === "view"}
                                    />
                                    </Grid>
                                    </Grid>
                                    <Box height={16} />
                                    <TextBox
                                        isrequired
                                        isReadonly={addState?.formType === "view"}
                                        label={t("Name")}
                                        placeholder={t("Enter Name")}
                                        value={addState?.name ?? ""}
                                        onChange={(e) => updateAddDialogState("name", e.target.value)}
                                        padding={"14px 14px 14px 4px"}
                                        isError={addState?.error?.name?.length > 0}
                                        errorMessage={addState?.error?.name}
                                    />
                                    <Box height={16} />
                                    {/* <Grid container spacing={1} alignItems={"center"}>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <TextBox
                                                // isrequired
                                                label={t("Debit")}
                                                placeholder={"Enter Debit Balance"}
                                                type="number"
                                                color={"#FF8F6B"}
                                                border={0}
                                                textColor={"white"}
                                                isReadonly={addState?.formType === "view"?true:true}
                                                // startAdornment={<InputAdornment position="end"><Typography sx={{ fontSize: "0.875rem", color: "#091B29" }}>{`${addState?.defaultNumber} -`}</Typography></InputAdornment>}
                                                padding={"14px 14px 14px 4px"}
                                                paddingLeft={"5px"}
                                                value={addState?.debit_balance ?? ""}
                                                onChange={(e) => updateAddDialogState("debit_balance", e.target.value)}
                                                isError={addState?.error?.debit_balance?.length > 0}
                                                errorMessage={addState?.error?.debit_balance}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <TextBox
                                                // isrequired
                                                label={t("Credit")}
                                                placeholder={"Enter Credit Balance"}
                                                type="number"
                                                // startAdornment={<InputAdornment position="end"><Typography sx={{ fontSize: "0.875rem", color: "#091B29" }}>{`${addState?.defaultNumber} -`}</Typography></InputAdornment>}
                                                padding={"14px 14px 14px 4px"}
                                                paddingLeft={"5px"}
                                                color={"#11a442bd"}
                                                border={0}
                                                textColor={"white"}
                                                isReadonly={addState?.formType === "view"?true:true}
                                                value={addState?.credit_balance ?? ""}
                                                onChange={(e) => updateAddDialogState("credit_balance", e.target.value)}
                                                isError={addState?.error?.credit_balance?.length > 0}
                                                errorMessage={addState?.error?.credit_balance}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box height={16} />
                                    <Grid container spacing={1} alignItems={"center"}>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <TextBox
                                                // isrequired
                                                label={t("Balance")}
                                                placeholder={"Enter Balance"}
                                                type="number"
                                                isReadonly={addState?.formType === "view"?true:true}
                                                // startAdornment={<InputAdornment position="end"><Typography sx={{ fontSize: "0.875rem", color: "#091B29" }}>{`${addState?.defaultNumber} -`}</Typography></InputAdornment>}
                                                padding={"14px 14px 14px 4px"}
                                                paddingLeft={"5px"}
                                                value={addState?.opening_balance ?? ""}
                                                onChange={(e) => updateAddDialogState("opening_balance", e.target.value)}
                                                isError={addState?.error?.opening_balance?.length > 0}
                                                errorMessage={addState?.error?.opening_balance}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6}>
                                            <DatePickerTimeNew
                                                // isRequired={true}
                                                isTime={true}
                                                // minDate={val?.minDate}
                                                // maxDate={val?.maxDate}
                                                label={t("Date")}
                                                placeholder={t("Select date")}
                                                value={addState?.opening_balance_date}
                                                onChange={(e) => updateAddDialogState("opening_balance_date", e)}
                                                isError={addState?.error?.opening_balance_date?.length > 0}
                                                errorMessage={addState?.error?.opening_balance_date}
                                                isReadonly={addState?.formType === "view"?true:true}
                                                // border={val?.border}
                                                is_popover={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box height={16} /> */}
                                    <Typography className={classes.addDialogFieldLabel} noWrap>{t("Status")}</Typography>
                                    <div className={classes.addDialogButtonContainer}>
                                        {activeOptionsList?.map((_) => {
                                            return <Button className={_?.value === addState?.is_active ?
                                                classes.addDialogButtonSelected :
                                                classes.addDialogButtonUnSelected}
                                                onClick={() => (addState?.formType === "add" ||
                                                    addState?.formType === "edit") ?
                                                    updateAddDialogState("is_active", _?.value) : false}>
                                                {t(_?.label)}
                                            </Button>
                                        })}
                                    </div>
                                </div>
                                {(addState?.formType === "add" || addState?.formType === "edit") &&
                                    <div className={classes.addDialogFooter}>
                                        {addState?.formType === "edit" && <><Button fullWidth
                                            className={classes.addDialogFooterCloseButton}
                                            onClick={() => setAddDialogOpen(false)}>
                                            {t("Cancel")}
                                        </Button></>}
                                        <Button variant="contained" fullWidth
                                            className={classes.addDialogFooterButton}
                                            disabled={buttonDisable}
                                            onClick={() => handleCreateEdit(addState)}>
                                            {addState?.formType === "add" ? t("Create") : t("Save")}
                                        </Button>
                                    </div>}
                            </Box>
                        </Drawer>
                    </div>)}
        </div >
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}
export default withNamespaces("charterOfAccounts")(COAMaster)
