export const scaleLine = {
    x: { grid: { display: false, }, stacked: true, },
    y: { grid: { display: false, }, stacked: true, }
}

export const TeamRevenueListHeading = ({ t = () => false }) => [
    { title: t("Team"), field: "team" },
    { title: t("Total Revenue"), field: "total_revenue" },
];

export const TeamRevenueListPath = ["team", "total_revenue",];

export const TeamRevenueListDataType = [
    { type: ["text"], name: "team", },
    { type: ["text"], name: "total_revenue", },
];

export const LeadFunnelOptions = ({ t = () => false }) => [
    { label: t("Open"), value: "In Progress", backgroundColor: "#64B5F6", },
    { label: t("Unqualified"), value: "Unqualified", backgroundColor: "#FFC107", },
    { label: t("Disqualified"), value: "Lost Lead", backgroundColor: "#E57373", },
    { label: t("Archieved"), value: "Archieved", backgroundColor: "#B0B0B0", },
    { label: t("Converted"), value: "Converted", backgroundColor: "#4CAF50", },
];

export const OpportunityFunnelOptions = ({ t = () => false }) => [
    { label: t("Open"), value: "Open", backgroundColor: "#64B5F6", },
    { label: t("Closed"), value: "Closed", backgroundColor: "#E57373", },
    { label: t("Won"), value: "Won", backgroundColor: "#4CAF50", },
];

export const QuotationFunnelOptions = ({ t = () => false }) => [
    { label: t("Active"), value: "Active", backgroundColor: "#64B5F6", },
    { label: t("Not Interested"), value: "Not Interested", backgroundColor: "#FFC107", },
    { label: t("Inactive"), value: "Inactive", backgroundColor: "#B0B0B0", },
    { label: t("Interested"), value: "Interested", backgroundColor: "#800080", },
    { label: t("Won"), value: "Won", backgroundColor: "#4CAF50", },
];

export const OpportunityRevenueOptions = ({ t = () => false, company = "" }) => [
    { label: t("Closed"), value: "Closed", borderColor: "#F50000", backgroundColor: "#F50000", tooltip_prefix: company?.currency_symbol, },
    { label: t("Open"), value: "Open", borderColor: "#FA861E", backgroundColor: "#FA861E", tooltip_prefix: company?.currency_symbol, },
    { label: t("Won"), value: "Won", borderColor: "#4CAF50", backgroundColor: "#4CAF50", tooltip_prefix: company?.currency_symbol, },
];

export const LeadStatusOptions = ({ t = () => false, company = "" }) => [
    { label: t("Closed"), value: "Closed", borderColor: "#F50000", backgroundColor: "#F50000", },
    { label: t("Open"), value: "Open", borderColor: "#FA861E", backgroundColor: "#FA861E", },
    { label: t("Won"), value: "Won", borderColor: "#4CAF50", backgroundColor: "#4CAF50",  },
];

export const OpportunityOptions = ({ t = () => false, company }) => [
    { label: t("Active"), value: "Active", borderColor: "#64B5F6", backgroundColor: "#64B5F6",  },
    { label: t("Not Interested"), value: "Not Interested", borderColor: "#FFC107", backgroundColor: "#FFC107",  },
    { label: t("Inactive"), value: "Inactive", borderColor: "#B0B0B0", backgroundColor: "#B0B0B0",  },
    { label: t("Interested"), value: "Interested", borderColor: "#800080", backgroundColor: "#800080",  },
    { label: t("Won"), value: "Won", borderColor: "#4CAF50", backgroundColor: "#4CAF50",  },
];

export const LetterTypeOptions = ({ t = () => false }) => [
    { label: t("Letters sent"), value: "Letters sent", backgroundColor: "#58D0E0", },
    { label: t("Letters acknowledged"), value: "Letters acknowledged", backgroundColor: "#FF9340", },
    { label: t("Letters Replied"), value: "Letters Replied", backgroundColor: "#5AC782", },
];