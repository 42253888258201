import { Avatar, Box, Button, Divider, Drawer, Grid, Menu, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { AlertDialog, DialogHeader, VerticalLinearStepper } from "../../../components"
import ReAssignModal from "../../../components/assignModal/reAssignModal"
import { RemoveZUTCDateTime, stringAvatar, useWindowDimensions } from '../../../utils'
import { useStyles } from "./style"
import { ViewServiceRequest } from "./viewServiceRequest"
import { TrackClockIcon } from "../../../assets/trackClockIcon"
import { CheckCircleIcon } from "../../../assets/checkCircleIcon"
import NoDataFound from "../../../assets/noData"
export const RequestCard = ({ t, title = "", icon = "", image = "", subTitle = "", subTitle1 = "", backgroundColor = "#FFFFFF", status = "", assign = false, openAssignModal = () => false, unit_details = {}, date = {}, AllResources = [], agreement_inspection_id = "", reAssign = () => false, reAssignModal = false, setReAssignModal = () => false, maintenanceData = {},details={} }) => {
    const classes = useStyles()
    const [isShow, setIsShow] = useState(false)
    const size = useWindowDimensions()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    // const handleClick = (event, title) => {
    //     setAnchorEl(event.currentTarget)
    //     setReqType(title)
    // }
    const handleClose = () => {
        setAnchorEl(null)
    }
    // const dialogHeader = () => {
    //     return (
    //         <Stack direction="row" spacing={1}>
    //             <Box onClick={() => setReAssignModal(false)} className={classes.iconbtn} display="flex" alignItems="center">
    //                 <ArrowBackIosIcon className={classes.icon} htmlColor="black" />
    //             </Box>
    //             <Typography className={classes.dialoghdrtext}>{"Re-Assign Inspector"}</Typography>
    //         </Stack>
    //     )
    // }

    return (
        <Box className={classes.reqCardBlock} style={{ backgroundColor: backgroundColor, border: backgroundColor !== "#FFFFFF" && "none" }}>
            {assign ?
                <>
                    <center>
                        {icon}
                        <Typography className={classes.Name}>{t("Assign Resource & Date")}</Typography>
                        <Typography className={classes.subTitle}>{t("Schedule inspection date & assign resources vehicle, tools, helper, etc")}</Typography>
                        <Box mt={1}>
                            <Button variant="contained" onClick={openAssignModal}>{t("Assign")}</Button>
                        </Box>
                    </center>
                </> :
                <>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography className={classes.title}>{title}</Typography>
                        <Box onClick={(event) => { (title === "Site Visit Request" || title === "Service Inspection Details") ? setIsShow(true) : setAnchorEl(event.currentTarget) }}><Typography className={classes.viewMore}>{open ? t("View Less") : t("View More")}</Typography></Box>


                    </Stack>
                    {title === "Resource Details" &&
                        <Box mt={1} mb={1} className={classes.resinspection} p={1}>
                            <Typography className={classes.insdateTime}>{t("Inspection Date & Time")}</Typography>
                            {/* <Typography className={classes.insdateTime}>{moment(RemoveZUTCDateTime(date)).format("DD MMM YY , hh:mm A")}</Typography> */}
                            <Typography className={classes.insdateTime}>{RemoveZUTCDateTime(date, "DD MMM YY HH:mm A")}</Typography>

                        </Box>
                    }
                    <Box display="flex" alignItems="center" mt={1}>

                        {image ?
                            <Box><Avatar src={AllResources?.[0]?.image_url} variant="square" className={classes.resimg}  {...stringAvatar(subTitle)} /></Box>
                            : <Box marginTop="10px">{icon} </Box>
                        }
                        <Box flexGrow={1} marginInlineStart="12px">
                            <Typography className={classes.Name}>{subTitle}</Typography>
                            <Box height="2px" />
                            <Typography noWrap className={classes.description}>{subTitle1}</Typography>
                        </Box>
                        {/* {status !== "Completed" && title ==="Inspection Detail" &&
                            <Box flexGrow={1} marginLeft="12px">
                                <Typography
                                    className={
                                        status === "Assigned"
                                            ? `${classes.Reassign}`
                                            : `${classes.status}`
                                    }
                                >
                                    {status}
                                </Typography>
                            </Box>
                        } */}


                    </Box>
                    {/* {status === "Completed" && title ==="Inspection Detail" &&
                        <center>
                            <Box mt={1} className={classes.completedDateTime}>
                                <Box><CheckIcon /></Box>
                                <Box ml={0.5}>
                                    <Typography className={classes.insdateTime}>Completed on 12 Dec 22</Typography>
                                </Box>
                            </Box>

                        </center>
                    } */}
                </>

            }
            <Menu
                id="simple-menu"
                className={classes.menuList}
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{ sx: { width: "350px", backgroundColor: "white", border: "1px solid #E4E8EE", boxShadow: "0px 0px 16px #00000014" } }}
            >

                <Box>
                    <DialogHeader title={title} onClose={handleClose} />
                    {title === "Track Update" ?
                        <>

                            <Box p={2}>
                            {details?.length > 0 ?
                                    <VerticalLinearStepper data={details?.map((val) => { return { name: val?.title, created_by: val?.created_by, date: RemoveZUTCDateTime(val?.created_at, "DD-MM-YYYY hh:mm A"), icon: val.title === "Inspection Completed" ? <Box className={classes.checkIconcircle}><CheckCircleIcon color="primary" /></Box> : <TrackClockIcon />, notes: val?.description } })} customTrack />
                                    : <center>
                                        <NoDataFound />
                                    </center>}
                            </Box>
                        </>
                        : title === "Resource Details" ?
                            <>
                                <Box p={1.5} style={{ height: !["Completed", "Rejected"].includes(status) ? size?.height - 550 : "auto", overflow: "auto" }}>
                                    {AllResources?.length > 0 && AllResources?.map((x, i) => {
                                        return (
                                            <Box className={classes.reqCardBlock} style={{ backgroundColor: backgroundColor, border: backgroundColor !== "#FFFFFF" && "none" }} mb={1}>
                                                <Box display="flex" alignItems="center" >

                                                    {image ?
                                                        <Avatar variant="square" className={classes.resimg} src={x.image_url} {...stringAvatar(x?.name)} />
                                                        : icon
                                                    }
                                                    <Box flexGrow={1} marginInlineStart="12px">
                                                        <Typography className={classes.Name}>{x.name}</Typography>
                                                        <Box height="2px" />
                                                        <Typography noWrap className={classes.mobileNo}>{`${x.mobile_no_country_code} ${x.mobile_no}`}</Typography>
                                                    </Box>
                                                    {/* <DeleteIcon /> */}

                                                </Box>
                                            </Box>
                                        )
                                    })}

                                </Box>
                                {!["Completed", "Rejected", "Yet To Verify"].includes(status) &&
                                    <Box mt={2} p={1.5}>
                                        <Button variant="contained" fullWidth onClick={() => { return (setReAssignModal(true), setAnchorEl(null)) }}>{t("Add Resource")}</Button>
                                    </Box>
                                }
                            </>
                            :
                            <Box p={1.5} mt={-2}>


                                <Box display="flex" alignItems="center" >
                                    <Box marginTop="10px">
                                        {icon}
                                    </Box>
                                    <Box marginInlineStart="12px">
                                        <Typography className={classes.Name}>{subTitle}</Typography>
                                        <Box height="2px" />
                                        <Typography noWrap className={classes.description}>{subTitle1}</Typography>
                                    </Box>
                                    {/* {status.length > 0 &&
                                        <Box flexGrow={1} marginLeft="12px">
                                            <Typography
                                                className={
                                                    status === "Assigned"
                                                        ? `${classes.Reassign}`
                                                        : `${classes.status}`
                                                }
                                            >
                                                {status}
                                            </Typography>
                                        </Box>
                                    } */}

                                </Box>
                                {/* <Box mt={1} mb={1}> <Divider className={classes.divStyle} /></Box>
                                <Box>
                                    <Typography className={classes.listText}>{"Tenant Details"}</Typography>
                                    <Stack direction={"row"} spacing={2} mt={1}>
                                        <Avatar />
                                        <Box>
                                            <Typography className={classes.Name}>{subTitle}</Typography>
                                            <Box height="2px" />
                                            <Typography noWrap className={classes.description}>{subTitle1}</Typography>
                                        </Box>
                                    </Stack>
                                </Box> */}
                                <Box mt={1} mb={1}> <Divider className={classes.divStyle} /></Box>
                                {unit_details?.unit_name &&
                                    <Box>
                                        <Typography className={classes.listText}>{t("Inspection Place")}</Typography>
                                        <Stack direction={"row"} mt={1} flexWrap={'wrap'}>
                                            <Avatar variant="square" className={classes.resimg} src={unit_details?.logo} {...stringAvatar(unit_details?.unit_name)} />
                                            <Box marginInlineStart={'12px'}>
                                                <Typography className={classes.Name}>{unit_details?.unit_no} / {unit_details?.unit_name} </Typography>
                                                <Box height="2px" />
                                                <Typography noWrap className={classes.location}>{unit_details?.property_name}</Typography>
                                                <Box height="2px" />
                                                <Box display="flex">
                                                    {unit_details?.street_1?.length > 0 &&
                                                        <Typography noWrap className={classes.address}>{unit_details?.street_1},</Typography>

                                                    }
                                                    {unit_details?.street_2?.length > 0 &&
                                                        <Typography noWrap className={classes.address}>{unit_details?.street_2},</Typography>
                                                    }
                                                    {unit_details?.city?.length > 0 &&
                                                        <Typography noWrap className={classes.address}>{unit_details?.city}</Typography>

                                                    } </Box>

                                            </Box>
                                        </Stack>
                                    </Box>
                                }
                            </Box>
                    }
                </Box>
            </Menu>

            <AlertDialog open={isShow}
                onClose={() => setIsShow(false)}
                header={title}
                isNormal={title === "Site Visit Request" && true}
                lg={title === "Service Inspection Details" && true}
                component={
                    title === "Site Visit Request" ?
                        <Box p={2}>
                            <Box className={classes.reqCardBlock}>
                                <Box display="flex" alignItems="center" >
                                    <Avatar variant="square" className={classes.resimg} {...stringAvatar(subTitle)} />
                                    <Box flexGrow={1} marginInlineStart="12px">
                                        <Typography className={classes.Name}>{subTitle}</Typography>
                                        <Box height="2px" />
                                        <Typography noWrap className={classes.mobileNo}>{subTitle1}</Typography>
                                    </Box>


                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Typography className={classes.siteText}>{("Category")}</Typography>
                                        <Typography className={classes.listsubText}>site Visit</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.siteText}>{t("Purpose")}</Typography>
                                        <Typography className={classes.listsubText}>Residential</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.siteText}>{t("Start Date")}</Typography>
                                        <Typography className={classes.listsubText}>7th Dec</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.siteText}>{t("Priority")}</Typography>
                                        <Typography className={classes.listsubText}>Urgent</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.siteText}>{t("Location")}</Typography>
                                        <Typography className={classes.listsubText}>Chennai</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={classes.siteText}>{t("Note")}</Typography>
                                        <Typography className={classes.listsubText}>site Visit</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        :
                        <Box style={{ height: size?.height - 200, overflow: "auto" }}>
                            <ViewServiceRequest t={t} value={maintenanceData}
                                request={"Maintenance"} />
                        </Box>

                }

            />
            <Drawer
                anchor={"right"}
                open={reAssignModal}
                onClose={() => setReAssignModal(false)}
            >
                <ReAssignModal
                    agreement_inspection_id={agreement_inspection_id}
                    reassign={true}
                    moduleName={"Unit Readiness Inspection"}
                    handleClose={() => setReAssignModal(false)}
                    header={t("Re-Assign Resource")}
                    buttonName={t("Re-Assign")}
                    onSubmit={reAssign}
                />
            </Drawer>

        </Box>
    )
}