import React from "react";
import { withNavBars } from "../../HOCs";
import  ExploreTemplate  from "./exploreTemplate";


class ExploreTemplateParent extends React.Component {
    render() {
        return <ExploreTemplate {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ExploreTemplateParent, props);