import * as React from "react"

export const InspectionIcon = (props) => (
  <svg
    data-name="Group 102360"
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    {...props}
  >
    <g data-name="Group 102366">
      <rect
        data-name="Rectangle 53874"
        width={40}
        height={40}
        rx={4}
        fill="rgba(254,234,234,0.5)"
      />
      <path
        d="M25.75 12h-1.776a2.245 2.245 0 0 0-2.224-2h-3.5a2.245 2.245 0 0 0-2.224 2H14.25A2.253 2.253 0 0 0 12 14.25v13.5A2.253 2.253 0 0 0 14.25 30h11.5A2.253 2.253 0 0 0 28 27.75v-13.5A2.253 2.253 0 0 0 25.75 12Zm-9.5 13.5a.75.75 0 1 1 .75-.75.75.75 0 0 1-.75.75Zm0-3a.75.75 0 1 1 .75-.75.75.75 0 0 1-.75.75Zm0-3a.75.75 0 1 1 .75-.75.75.75 0 0 1-.75.75Zm1.25-7.25a.751.751 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5a.751.751 0 0 1-.75-.75Zm6.25 13.25h-4.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 0 1.5Zm0-3h-4.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 0 1.5Zm0-3h-4.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 0 1.5Z"
        fill="#b3776d"
      />
    </g>
  </svg>
)

