import { Stack, Box, Grid } from '@mui/material'
import React from 'react'
import { AlertDialog, CalendarDateChange, LayoutSwitch } from '../../../components'
import { CalendarDropdown } from '../../../components/calendarDropdown'
import { UserList } from '../../../components/userList'
import { AlertEmpList } from './alertEmpList'
import { HeaderStyles } from './style'

export const EmpBookingCalendarHeader = ({ month = {}, prev = () => false, next = () => false,
     days = {}, empList = {}, open = () => false, showAll = ()=> false, setDropdownValue = () =>false,
     selected={}, t = () => false }) => {
    // classes
    const classes = HeaderStyles()

    const [openUserList, setOpenUserList] = React.useState(false);

    const OpenUserList = () => {
        setOpenUserList(true)
    }
    return (

        <Box>
            <Stack direction={"row"} justifyContent={"space-between"} p={1}>
                <Stack>
                    <UserList handleClick={OpenUserList} userAlert={false} empList={empList} />
                </Stack>
                <Stack>
                    {
                        selected === "Monthly" ?
                            <CalendarDateChange state={month} prev={prev} next={next} comp={"month"} />
                            :
                            <CalendarDateChange state={month} prev={prev} next={next} comp={"weekly"} />
                    }
                </Stack>
                <Stack sx={{ width: 220 }}>
                    <CalendarDropdown t={t} open={open} selected={selected} showAll={showAll} setDropdownValue={setDropdownValue}/>
                        <LayoutSwitch />
                </Stack>
            </Stack>
            {
                selected === "Monthly" ?
                    <Grid container >
                        {
                            days.map(e => {
                                return (
                                    <Grid item xs={1.7} textAlign={"center"} p={1}>
                                        <span className={classes.days}> {e} </span>
                                    </Grid>
                                )
                            })
                        }
                    </Grid> : ""
            }
            
            <AlertDialog open={openUserList}
                onClose={() => setOpenUserList(!openUserList)}
                header="Change Employee"
                component={<AlertEmpList empList={empList} />}
                isNormal
            />
        </Box>


    )
}