import React from "react"

export const FormDeleteIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
  <g id="Group_113263" data-name="Group 113263" transform="translate(-15518.5 -7261)">
    <rect id="icons8-cardboard-box-48" width="80" height="80" transform="translate(15518.5 7261)" fill="#fff"/>
    <g id="icons8-delete" transform="translate(15526.5 7266)">
      <path id="Path_99317" data-name="Path 99317" d="M41.505,60.661H21.641a6.008,6.008,0,0,1-6.02-5.117L9,11H54.146L47.375,55.545A5.969,5.969,0,0,1,41.505,60.661Z" transform="translate(0.505 2.524)" fill="#b39ddb"/>
      <path id="Path_99318" data-name="Path 99318" d="M35.068,6H23.029L14,15.029H44.1Z" transform="translate(3.029)" fill="#9575cd"/>
      <path id="Path_99319" data-name="Path 99319" d="M11.01,8H53.146a3.019,3.019,0,0,1,3.01,3.01v3.01H8V11.01A3.019,3.019,0,0,1,11.01,8Z" transform="translate(0 1.01)" fill="#7e57c2"/>
    </g>
  </g>
</svg>

    )
}