import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor:theme.palette.background.tertiary1,
        border:`1px solid ${theme.palette.border.secondary}`,
        boxShadow: "0px 0px 16px #00000014",
    },
    content:{
        height:`calc(100vh - 120px)`,
        overflow:"auto"
    },
    contentTitle:{
        color:theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontWeight:"bold"
    },
    companyListTitle:{
        color:theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontWeight:"bold"
    },
    companyListContent:{
        fontSize:"0.875rem",
        fontWeight:"bold",
        color:theme.typography.color.primary,
    },
    tableAddBtn:{
        fontWeight:"normal",
        padding:"2px 16px 2px 16px",
        fontSize:"0.875rem"
    }
}))