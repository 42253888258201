import React from "react";
import { withNavBars } from "../../HOCs";
import ActivitySubCategory from "./activitySubCategory";

class ActivitySubCategoryParent extends React.Component {
    render() {
        return <ActivitySubCategory />;
    }
}

const props = { boxShadow: false }

export default withNavBars(ActivitySubCategoryParent, props);
