import * as React from "react"
const VendorActive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 115369">
      <g data-name="Group 115366">
        <path
          fill="#ffca40"
          d="M1.143 9.429h21.714v13.145H1.143Z"
          data-name="Path 100862"
        />
        <path
          fill="#e2ae24"
          d="M5.143 12.286h13.714v10.286H5.143Z"
          data-name="Path 100864"
        />
        <path
          fill="#e2ae24"
          d="M5.714 12.857h12.571v9.714H5.714Z"
          data-name="Path 100865"
        />
        <path
          fill="#8d6c16"
          d="M13.715 9.428A1.714 1.714 0 1 1 12 7.714a1.715 1.715 0 0 1 1.714 1.714m6.857 0a1.714 1.714 0 1 1-1.713-1.714 1.715 1.715 0 0 1 1.714 1.714m-17.143 0a1.714 1.714 0 1 0 1.714-1.714 1.715 1.715 0 0 0-1.714 1.714"
          data-name="Path 100867"
        />
        <path
          fill="#715712"
          d="M21.143 2H2.857a1.143 1.143 0 0 0-1.143 1.143v1.714h20.571V3.143A1.143 1.143 0 0 0 21.143 2M10.285 4.857h3.429v4.571h-3.429Zm9.143 0h-2.857l.571 4.571h3.429Zm-14.857 0h2.857l-.571 4.571H3.428Z"
          data-name="Path 100868"
        />
        <g data-name="Group 115366">
          <path
            fill="#715712"
            d="M17.143 9.428a1.714 1.714 0 1 1-1.714-1.714 1.715 1.715 0 0 1 1.714 1.714m6.857 0a1.714 1.714 0 1 1-1.714-1.714Zm-17.143 0a1.714 1.714 0 1 0 1.714-1.714 1.715 1.715 0 0 0-1.714 1.714M0 9.428a1.714 1.714 0 1 0 1.714-1.714Z"
            data-name="Path 100869"
          />
        </g>
        <g data-name="Group 115367">
          <path
            fill="#8d6c16"
            d="M16.571 4.857h-2.857v4.571h3.429Zm5.714 0h-2.856l1.143 4.571H24Zm-14.856 0h2.857v4.571H6.857Zm-5.715 0h2.857L3.429 9.428H0Z"
            data-name="Path 100870"
          />
        </g>
        <path
          fill="#aa821b"
          d="M11.429 12.857h1.143v9.714h-1.143Z"
          data-name="Path 100871"
        />
        <g data-name="Group 115368">
          <path
            fill="#aa821b"
            d="M11.429 15.974v6.6h1.143v-7.119Zm2.857 1.455a.571.571 0 1 0 .571.571.571.571 0 0 0-.571-.571Zm-4.571 0a.571.571 0 1 0 .571.571.571.571 0 0 0-.572-.571Z"
            data-name="Path 100872"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default VendorActive
