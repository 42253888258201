export const Polls = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
        <defs>
            <linearGradient
                id="b"
                x1={0.217}
                y1={-12.344}
                x2={0.789}
                y2={-11.389}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#33bef0" />
                <stop offset={1} stopColor="#0a85d9" />
            </linearGradient>
            <clipPath id="a">
                <path
                    data-name="Rectangle 52013"
                    transform="translate(82 183)"
                    fill="#fff"
                    stroke="#707070"
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 102439">
            <g
                data-name="Group 6395"
                transform="translate(-78 -179)"
                clipPath="url(#a)"
            >
                <g data-name="icons8-poll (1)">
                    <path
                        data-name="Path 7278"
                        d="M24.25 4.8H3.55A1.15 1.15 0 0 0 2.4 5.95V20.9a1.15 1.15 0 0 0 1.15 1.15h4.6v4.6a.575.575 0 0 0 .982.407l5.005-5.005H24.25A1.15 1.15 0 0 0 25.4 20.9V5.95a1.15 1.15 0 0 0-1.15-1.15Z"
                        transform="translate(79.998 178.863)"
                        fill="url(#b)"
                    />
                    <path
                        data-name="Path 7279"
                        d="M86.423 198.613v-4.887a.864.864 0 0 1 .862-.863h3.738v-5.75a1.151 1.151 0 0 1 1.15-1.15h3.45a1.151 1.151 0 0 1 1.15 1.15v2.3h3.45a1.151 1.151 0 0 1 1.15 1.15v8.05Z"
                        opacity={0.05}
                    />
                    <path
                        data-name="Path 7280"
                        d="M100.223 189.7h-3.45a.853.853 0 0 0-.288.053v-2.64a.864.864 0 0 0-.862-.862h-3.45a.864.864 0 0 0-.862.862v6.037h-4.026a.576.576 0 0 0-.575.575v4.6h14.375v-7.763a.864.864 0 0 0-.862-.862Z"
                        opacity={0.07}
                    />
                    <path
                        data-name="Path 7281"
                        d="M92.173 186.538h3.45a.575.575 0 0 1 .575.575v10.925h-4.6v-10.925a.575.575 0 0 1 .575-.575Z"
                        fill="#e6eeff"
                    />
                    <path
                        data-name="Path 7282"
                        d="M96.773 189.988h3.45a.575.575 0 0 1 .575.575v7.475h-4.6v-7.475a.575.575 0 0 1 .575-.575Z"
                        fill="#ccdcff"
                    />
                    <path
                        data-name="Path 7283"
                        d="M87.286 193.438h4.025a.288.288 0 0 1 .287.287v4.313h-4.6v-4.312a.288.288 0 0 1 .288-.288Z"
                        fill="#fff"
                    />
                </g>
            </g>
            <path data-name="Rectangle 53879" fill="none" d="M0 0h32v32H0z" />
        </g>
    </svg>
)