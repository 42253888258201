import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { LoadingSection } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from '../../../networkcall';
import { BackendRoutes } from "../../../router/routes";
import { NetWorkCallMethods, currencyFormater, concat_string } from "../../../utils";
import { useStyles } from "../styles";
export const DetailsContainer = ({ selectedAccount = "", symbol = "", code = "", t }) => {
    const classes = useStyles()
    const [details, setDetails] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const getAccountDetails = () => {
        setLoading(true)
        const payload = {
            account_no: selectedAccount
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes.account_statistics}`,
            NetWorkCallMethods.post,
            payload,
            true,
            true,
            false
        ).then((res) => {
            setDetails(
                [
                    {
                        title: t("Total Revenue For This Year"),
                        icon: "/images/account/revenue.svg",
                        total: code ?
                            currencyFormater(code, res?.data?.data?.total_revenue ?? 0) :
                            res?.data?.data?.total_revenue ?? 0,
                        color: "#F5F7FA",
                    },
                    {
                        title: t("Total Unbilled For This Year"),
                        icon: "/images/account/unbilled.svg",
                        total: code ?
                            currencyFormater(code, res?.data?.data?.total_unbilled ?? 0) :
                            res?.data?.data?.total_unbilled ?? 0,
                        color: "#F5F7FA"
                    },
                    {
                        title: t("Total Received For This Year"),
                        icon: "/images/account/recived.svg",
                        total: code ? currencyFormater(code, res?.data?.data?.total_received ?? 0) : res?.data?.data?.total_received ?? 0,
                        color: "#F5F7FA"
                    },
                    {
                        title: t("Total Pending For This Year"),
                        icon: "/images/account/pending.svg",
                        total: code ? currencyFormater(code, res?.data?.data?.total_pending ?? 0) : res?.data?.data?.total_pending ?? 0,
                        color: "#F5F7FA"
                    },
                    {
                        title: t("Valid Agreements"),
                        icon: "/images/account/agreemnts.svg",
                        total: concat_string(
                            {
                                current: `${res?.data?.data?.total_current_agreement ?? 0} (${t("Current")})`,
                                upcoming: `${res?.data?.data?.total_upcoming_agreement ?? 0} (${t("Upcoming")}`
                            },
                            ["current", "upcoming"]
                        ),
                        color: "#F5F7FA"
                    }
                ])
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }
    React.useEffect(() => {
        getAccountDetails()
        // eslint-disable-next-line
    }, [selectedAccount])

    return (
        <Box>
            <Typography className={classes.title}>{t("Account Screen info")}</Typography>
            {
                loading ?
                    <LoadingSection bottom={"45vh"} message="Fetching ..." /> :
                    <Stack spacing={"16px"}>
                        {
                            details?.map((val) => {
                                return (
                                    <Stack spacing={"8px"} className={classes.card_root}>
                                        <Typography className={classes.card_title}>
                                            {val?.title}
                                        </Typography>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <img src={val?.icon} alt={val?.title} />
                                            <Typography className={classes.card_total}>
                                                {val?.total}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                )
                            })
                        }
                    </Stack>
            }

        </Box>
    )
}