import React from "react"

export const HolidayPayIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_106766" data-name="Group 106766" transform="translate(-3837 23667)">
    <g id="Rectangle_55653" data-name="Rectangle 55653" transform="translate(3837 -23667)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="24" height="24" stroke="none"/>
      <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
    </g>
    <path id="icons8-holiday_1_" data-name="icons8-holiday (1)" d="M10.986,2.978a.6.6,0,0,0-.59.608v.228a5.88,5.88,0,0,0-3.97,1.974A6.263,6.263,0,0,0,5.227,7.8a.6.6,0,0,0,.572.781h4.6v2.2a.6.6,0,1,0,1.2,0v-2.2h4.6a.6.6,0,0,0,.572-.781,6.263,6.263,0,0,0-1.2-2.014A5.88,5.88,0,0,0,11.6,3.815V3.587a.6.6,0,0,0-.609-.608Zm6.606,7.6a1.2,1.2,0,1,0,1.2,1.2,1.2,1.2,0,0,0-1.2-1.2Zm-8.772.4a.8.8,0,0,0-.662.32L6,14.181H4.8a.8.8,0,1,0,0,1.6H6.209a.8.8,0,0,0,.35,0H11a1.4,1.4,0,0,0,.169-.012,3.2,3.2,0,0,0,.964-.223l2.725-1.1a.8.8,0,0,0,.417-.369,1.4,1.4,0,0,0-1.45-2.239v0l-.013,0a1.4,1.4,0,0,0-.212.08l-2.8,1.063L9.531,11.427,9.437,11.3a.8.8,0,0,0-.618-.32ZM8.8,13.115l.8,1.065H8Zm10.577.662a.6.6,0,0,0-.215.05l-7.485,3.151H6.3a.553.553,0,0,0-.2,0H4.6a.6.6,0,1,0,0,1.2h1v1a.6.6,0,1,0,1.2,0v-1h4.933a.6.6,0,0,0,.112,0h5.349v1a.6.6,0,1,0,1.2,0v-1h1a.6.6,0,1,0,0-1.2h-1.5a.553.553,0,0,0-.2,0h-2.93l4.858-2.046a.6.6,0,0,0-.251-1.156Z" transform="translate(3837 -23665.979)" fill="#5b77ae"/>
  </g>
</svg>


)