import * as React from "react"

export const Revenue = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 102036">
      <path
        d="M4.025 0A2.025 2.025 0 0 0 2 2.025v13.95A2.025 2.025 0 0 0 4.025 18h10.35a2.025 2.025 0 0 0 2.025-2.025V7.2h-5.175A2.025 2.025 0 0 1 9.2 5.175V0Zm6.525.4v4.775a.676.676 0 0 0 .675.675H16ZM5.375 9h7.65a.675.675 0 0 1 0 1.35h-7.65a.675.675 0 0 1 0-1.35Zm0 2.7h3.15a.675.675 0 0 1 0 1.35h-3.15a.675.675 0 1 1 0-1.35Zm6.3 0a.675.675 0 0 1 .675.675v.675h.675a.675.675 0 0 1 0 1.35h-.675v.675a.675.675 0 0 1-1.35 0V14.4h-.675a.675.675 0 0 1 0-1.35H11v-.675a.675.675 0 0 1 .675-.675Zm-6.3 2.7h2.7a.675.675 0 0 1 0 1.35h-2.7a.675.675 0 1 1 0-1.35Z"
        fill={props?.color}
        data-name="Group 93903"
      />
    </g>
  </svg>
)
