import { Box } from "@mui/material";
import { ProofComponent } from "../../../components";
import { useStyles } from "../styles";
export const Proof = ({ update = () => false, data = [], t }) => {
    const classes = useStyles()

    return (
        <Box className={classes.box2}>
            <ProofComponent t={t} file={data} update={update} />
        </Box>
    )
}