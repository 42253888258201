export const Prospect = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g
      id="Group_99675"
      data-name="Group 99675"
      transform="translate(-1646 480)"
    >
      <g
        id="Group_8127"
        data-name="Group 8127"
        transform="translate(-10 -1483)"
      >
        <g
          id="Group_99684"
          data-name="Group 99684"
          transform="translate(-4 -42)"
        >
          <path
            id="Rectangle_31"
            data-name="Rectangle 31"
            transform="translate(1660 1045)"
            style={{
              fill: "none",
            }}
            d="M0 0h30v30H0z"
          />
          <g id="prospects" transform="translate(1664 1050)">
            <path
              id="Path_95392"
              data-name="Path 95392"
              className="cls-2"
              d="M3 42.1h.561l.215 2.586h1.552l.214-2.586H6.1V39H3Z"
              transform="translate(0 -26.69)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <circle
              id="Ellipse_129253"
              data-name="Ellipse 129253"
              className="cls-2"
              cx={1.034}
              cy={1.034}
              r={1.034}
              transform="translate(4.81 3)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <circle
              id="Ellipse_129254"
              data-name="Ellipse 129254"
              className="cls-2"
              cx={1.034}
              cy={1.034}
              r={1.034}
              transform="translate(14.121 3)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95393"
              data-name="Path 95393"
              className="cls-2"
              d="M49 41.1h.561l.215 2.586h1.552l.215-2.586h.557V38H49Z"
              transform="translate(-34.103 -25.948)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95394"
              data-name="Path 95394"
              className="cls-2"
              d="M8 14.1h.561l.139 1.617a1.578 1.578 0 0 1 .856.639 4.634 4.634 0 0 1 1.544-3.385V11H8Z"
              transform="translate(-3.707 -5.931)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95395"
              data-name="Path 95395"
              className="cls-2"
              d="M44 12.971a4.64 4.64 0 0 1 1.534 3.147 1.552 1.552 0 0 1 .9-.665l.111-1.35h.555V11H44Z"
              transform="translate(-30.397 -5.931)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95396"
              data-name="Path 95396"
              className="cls-2"
              d="M19.4 3.517 18.621 3v3.437a4.138 4.138 0 0 0-3.349 3.8H15v.517h.272a4.138 4.138 0 0 0 3.866 3.879v.267h.517v-.259a4.138 4.138 0 0 0 3.879-3.879h.259v-.517h-.259a4.138 4.138 0 0 0-3.362-3.8V3Zm-.706 3.432.447.447v.013a3.1 3.1 0 0 0-2.832 2.832h-.517a3.621 3.621 0 0 1 2.9-3.292Zm.447 7.172a3.621 3.621 0 0 1-3.349-3.362h.517a3.1 3.1 0 0 0 2.829 2.841Zm0-1.293v.259a2.586 2.586 0 0 1-2.315-2.328h.246v-.517h-.246a2.586 2.586 0 0 1 2.328-2.315v1.306a1.293 1.293 0 1 0 .517 0V7.927A2.586 2.586 0 0 1 22 10.254h-.272v.517h.259a2.586 2.586 0 0 1-2.332 2.329v-.272Zm.517 1.293V13.6a3.1 3.1 0 0 0 2.842-2.841h.517a3.621 3.621 0 0 1-3.362 3.362Zm3.362-3.879h-.52a3.1 3.1 0 0 0-2.845-2.832V7.4l.447-.447a3.621 3.621 0 0 1 2.915 3.292Z"
              transform="translate(-8.897)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <circle
              id="Ellipse_129255"
              data-name="Ellipse 129255"
              className="cls-2"
              cx={1.034}
              cy={1.034}
              r={1.034}
              transform="translate(3.517 10.241)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <circle
              id="Ellipse_129256"
              data-name="Ellipse 129256"
              className="cls-2"
              cx={1.034}
              cy={1.034}
              r={1.034}
              transform="translate(15.414 9.983)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
