import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { TextBox, MobileNumberInputComponent } from "../../../components";
import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  contact: {
    display: "flex",
    alignItems: "center",
  },
  contact1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "16px 0px",
  },
  name: {
    color: "#98A0AC",
    fontSize:"0.75rem",
  },
  contant: {
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: Bold,
  },
  tittle: {
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: "600",
    fontFamily: Bold,
  },
  change: {
    fontSize:"0.875rem",
    fontWeight: "600",
    fontFamily: Bold,
    cursor: "pointer",
  },
  dropdown: {
    margin: "10px 0px",
  },
  imageParent: {
    backgroundColor: "#F5F7FA",
    borderRadius: "12px",
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export const ContactDetails = (props) => {
  const classes = useStyles(props);
  return (
    <div>
      <Box className={classes.contact1}>
        <Box>
          <Typography className={classes.tittle}>Contact</Typography>
        </Box>
        <Box>
          <Typography
            color="primary"
            className={classes.change}
            onClick={props?.change ? props?.saveFunction : props?.changeFunction}
          >
            {props?.change ? "Save" : "Change"}
          </Typography>
        </Box>
      </Box>

      {props?.change ? (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className={classes.dropdown}>
              <TextBox
                isRequired
                label="Contact Name"
                placeholder="Contact Name"
                value={props?.data?.name}
                onChange={(e) => {
                  props?.updateState("name", e.target.value);
                }}
                isError={props?.data?.error?.name?.length > 0}
              // errorMessage={"Name is Required"}
              />
            </div>
            {/* mobile number */}
            <div className={classes.dropdown}>
              <MobileNumberInputComponent
                label={"Mobile"}
                placeholder={"Mobile"}
                value={props?.data?.mobile}
                isRequired
                handleChange={(value) => {
                  props?.updateState("mobile", value);
                }}
                isError={props?.data?.error?.mobile?.length > 0}
                errorMessage={props?.data?.error?.mobile}
                onKeyPress={(e) => {
                  if (e.key === "e") {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.dropdown}>
              <MobileNumberInputComponent
                label={"Alternative Mobile"}
                placeholder={"Alternative Mobile"}
                value={props?.data?.altermobile}
                isRequired
                handleChange={(value) => {
                  props?.updateState("altermobile", value);
                }}
                isError={props?.data?.error?.altermobile?.length > 0}
                errorMessage={props?.data?.error?.altermobile}
                onKeyPress={(e) => {
                  if (e.key === "e") {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box className={classes.contact}>
              <Box className={classes.imageParent}>
                <img src="\images\personimage.svg" alt="images" />
              </Box>
              <Box style={{ marginLeft: "20px" }}>
                <Typography className={classes.name}>Contact Name</Typography>
                <Typography className={classes.contant}>
                  {props?.data?.name}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.contact}>
              <Box className={classes.imageParent}>
                <img src="\images\phone.svg" alt="contact images" />
              </Box>
              <Box style={{ marginLeft: "20px" }}>
                <Typography className={classes.name}>
                  Primary Contact Number
                </Typography>
                <Typography className={classes.contant}>
                  {props?.data?.mobile?.mobile_code}&nbsp; {props?.data?.mobile?.mobile}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.contact}>
              <Box className={classes.imageParent}>
                <img src="\images\phone.svg" alt="images" />
              </Box>
              <Box style={{ marginLeft: "20px" }}>
                <Typography className={classes.name}>
                  Alternate Contact Number
                </Typography>
                <Typography className={classes.contant}>
                  {props?.data?.altermobile?.mobile_code}&nbsp; {props?.data?.altermobile?.mobile}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
