import React from "react"


export const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g id="Group_106745" data-name="Group 106745" transform="translate(-33 -15)">
            <g id="Rectangle_55634" data-name="Rectangle 55634" transform="translate(33 15)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                <rect width="14" height="14" stroke="none" />
                <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
            </g>
            <path id="icons8-ok_1" data-name="icons8-ok 1" d="M10,4a6,6,0,1,0,6,6A6.007,6.007,0,0,0,10,4Zm2.568,4.968-3,3a.45.45,0,0,1-.637,0l-1.5-1.5a.45.45,0,0,1,.636-.636L9.25,11.014l2.682-2.682a.45.45,0,0,1,.637.636Z" transform="translate(30 12)" fill="#5ac782" />
        </g>
    </svg>

)