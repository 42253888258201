import * as React from "react"

export const Account = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
        <path
            d="M.6 0a.6.6 0 0 0-.6.6v6a.6.6 0 0 0 .6.6h1.6c.019-.518.158-3.052 1.052-3.945S6.681 2.222 7.2 2.2V.6a.6.6 0 0 0-.6-.6Zm6.7 3c-.026 0-2.772.113-3.48.82S3 7.274 3 7.3s.113 2.773.82 3.48a3.032 3.032 0 0 0 1.4.567c-.007-.115-.018-.23-.018-.347A5.807 5.807 0 0 1 11 5.2c.117 0 .232.011.347.018a3.034 3.034 0 0 0-.567-1.4C10.072 3.113 7.326 3 7.3 3ZM11 6a5 5 0 1 0 5 5 5.005 5.005 0 0 0-5-5Z"
            fill="#ef6464"
        />
    </svg>
)

