import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const weeklyCalendarStyle = makeStyles((theme) => ({
    cells: {

        textAlign: "center",
        height: "80px",
        border: `1px solid ${theme.palette.border.secondary}`,
        maxWidth: "100px",
        minWidth: "100px",
        overflow: "hidden"
    },
    titleCells: {
        textAlign: "center",
        height: "80px",
        border: `1px solid ${theme.palette.border.secondary}`,
        maxWidth: "60px",
        minWidth: "60px",
        overflow: "hidden"
    },
    headCells: {
        textAlign: "center",
        border: `1px solid ${theme.palette.border.secondary}`,
        maxWidth: "100px",
        minWidth: "100px",
        overflow: "hidden",
        padding: "12px"
    },
    loadCell: {
        textAlign: "center",
        border: `1px solid ${theme.palette.border.secondary}`,
        maxWidth: "60px",
        minWidth: "60px",
        overflow: "hidden",
        padding: "12px"
    },

    hovercells: {
        display: "flex",
        justifyContent: "center",
        height: 80,
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    dayLabel: {
        fontSize:"0.75rem",
        textTransform: "uppercase",
        color: theme.typography.color.tertiary,
        textAlign: "center"
    },
    dateLabel: {
        fontSize:"1rem",
        fontFamily: Bold,
        textTransform: "uppercase",
        color: theme.typography.color.secondary
    },
    listTitle: {
        color: theme.typography.color.tertiary,
        fontSize:"1rem",
    },
    calender: {
        zIndex: "0"
    },
    calenderRows: {
        height: `calc(100vh - 320px)`,
        overflow: "auto",
        width: "100%",
    },
    cell_hover: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.main,
        height: 80,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer"
    },
    header_timecell: {
        height: 40,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontFamily: Bold
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontFamily: Bold,
        cursor: "pointer",
        float: "left",
        padding: "8px 0px"

    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize:"1.125rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    ttsecTitle: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
    },
    ttassigned_by: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    ttname: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold
    },
    table_container: {
        maxHeight: `calc(100vh - 237px)`,
        [theme.breakpoints.only('md')]: {
            height: `calc(100vh - 270px)`,
        },
        [theme.breakpoints.only('sm')]: {
            height: `calc(100vh - 270px)`,
        },

    },
    successStrip: {
        background: theme.palette.success.light,
        color: theme.palette.success.main
    },
    cancelledStrip: {
        background: theme.palette.error.light,
        color: theme.palette.error.main
    },
    expiredStrip: {
        background: theme.palette.background.tertiary,
        color: theme.typography.color.secondary
    }
}))

export const eventListStyle = makeStyles((theme) => ({
    header_timecell: {
        height: 40,
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    timeCell: {
        display: "flex",
        justifyContent: "center",
        height: 100,
        alignItems: 'end',
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
    },
    header_eventCell: {
        height: 40,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%",
        padding: 4,
        display: "flex",
        justifyContent: "left",
        alignItems: "center"
    },
    eventCell: {
        height: 100,
        border: `1px solid ${theme.palette.border.secondary}`,
        width: "100%"
    },
    event_label: {
        padding: 2,
        fontSize:"0.75rem",
        fontFamily: Bold
    },
    dot: {
        height: 5,
        width: 5,
        borderRadius: "50%",
    },
    calRow: {
        height: "calc(100vh - 250px)",
        overflow: "auto",
        width: "100%",
    },
    moreLabel: {
        fontSize:"0.75rem",
        color: theme.palette.info.main,
        fontFamily: Bold,
        marginLeft: 8,
        cursor: "pointer",
        float: "left",

    },
    customTooltip: {
        // '& .MuiTooltip-arrow':{
        //     backgroundColor:"#fff",
        //     color:"#fff",
        //     border: '1px solid #dadde9'
        // }
    },
    ttavatar: {
        height: 24,
        width: 24,
    },
    ttTitle: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    ttsecTitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold
    },
    ttassigned_by: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    },
    ttname: {
        fontSize:"1rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold
    },
    boxSuccess: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.palette.success.main,
        background: theme.palette.success.light,
        borderRadius: 4,
        width: "50px"
    },
    toolTipAvatar: {
        height: 24,
        width: 24,
        borderRadius: 2

    }
}))