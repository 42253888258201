import React from "react";
import { withNavBars } from "../../HOCs";
import Contacts from "../contacts/contacts";

const OwnerViewNew = () =>{
    return(
        <Contacts type="Owner" />
    )
}

export default withNavBars(OwnerViewNew)