import * as React from "react"
const TotalDiscount = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} {...props}>
    <g data-name="Group 115513">
      <path
        fill="#6d80b3"
        d="m14 2 2.458 2.743L20 3.636l.8 3.564 3.565.8-1.091 3.542L26 14l-2.727 2.458L24.364 20l-3.565.8L20 24.364l-3.542-1.107L14 26l-2.458-2.743L8 24.364 7.2 20.8 3.636 20l1.091-3.542L2 14l2.727-2.458L3.636 8 7.2 7.2 8 3.636l3.542 1.107Z"
        data-name="Path 100913"
      />
      <path
        fill="#fff"
        d="m12.182 17.774-.772-.455 4.409-7.092.772.455Zm-.455-8.319a1.732 1.732 0 0 0-1.818 1.818v.455a1.732 1.732 0 0 0 1.818 1.818 1.739 1.739 0 0 0 1.818-1.818v-.455a1.717 1.717 0 0 0-1.818-1.818Zm.819 2.273c0 .591-.318.909-.819.909a.815.815 0 0 1-.818-.909v-.455a.828.828 0 1 1 1.637 0Zm3.727 6.818a1.732 1.732 0 0 0 1.818-1.818v-.455a1.818 1.818 0 1 0-3.636 0v.455a1.717 1.717 0 0 0 1.818 1.818Zm-.819-2.273c0-.591.318-.909.819-.909a.815.815 0 0 1 .819.909v.455a.828.828 0 1 1-1.637 0Z"
        data-name="Path 100914"
      />
    </g>
  </svg>
)
export default TotalDiscount
