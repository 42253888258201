import React from "react";
import { withNavBars } from "../../HOCs";
import AccountDetails from "./accountDetails";

class AccountDetailsParent extends React.Component {
    render() {
        return <AccountDetails />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AccountDetailsParent, props);
