import { makeStyles } from "@mui/styles"

export const MonthlyCalendarStyles = makeStyles((theme)=>({
    // calendar dates styles
    calDates:{
        textAlign:"center",
    },
    date:{
        color:theme.typography.color.secondary,
        fontSize:"1.125rem",
        fontWeight:"bold"
    },
    date_outline:{
        color:"black",
        border: `1px solid ${theme.palette.border.secondary}`, 
        height: 90,
    },
    hidden_other_month_dates:{
        visibility:"hidden"
    },
    eventlabel:{
        overflow:"hidden",
        width:"98%",
    },
    eventtxt:{
        whiteSpace:"nowrap",
        width:"99%",
        fontSize:"0.75rem",
    },
    moreLabel:{
        float:"left",
        fontSize:"0.75rem",
        fontWeight:"bold",
        color:theme.palette.info.main,
    }
}))

export const HeaderStyles = makeStyles((theme)=>({
    days:{
        fontSize:"0.75rem",
        textTransform:"uppercase",
        color:theme.typography.color.tertiary,
        textAlign:"center"
    }
}))
