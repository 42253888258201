import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        padding: "16px",
        backgroundColor: "white",
        margin: "14px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
    },

    addIcon: {
        float: "right",
        height: '40px'
    },
    iconButton: {
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.primary}`,
        height: '40px',
        width: "40px"
    },
}))
