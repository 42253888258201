import { Box, IconButton, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment';
import { CalendarStyle } from './style'
import { startOfWeek, addDays, startOfMonth, endOfMonth, endOfWeek, addMonths, subMonths } from "date-fns";

export const Calendar = () => {

    const [month, setMonth] = React.useState(new Date())

    const next = () => {
        setMonth(addMonths(month, 1))
    }

    const prev = () => {
        setMonth(subMonths(month, 1))
    }

    // Month rendar funtion and state

    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    let day = startDate;

    let finaldays = []
    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            day = addDays(day, 1);
            finaldays.push(day)
        }
    }

    return (
        <Box p={2}>
            <Header next={next} prev={prev} month={month} />
            <Body finaldays={finaldays} month={month} />
        </Box>
    )
}

// Calendar header section 
const Header = ({ next = () => false, prev = () => false, month = {} }) => {
    const classes = CalendarStyle()
    return (
        <Stack direction={"row"} spacing={4} alignItems={"center"} justifyContent={"space-between"}>
            <IconButton className={classes.iconbutton} onClick={prev}>
                <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography>{moment(month).format("MMMM YYYY")}</Typography>
            <IconButton className={classes.iconbutton} onClick={next}>
                <KeyboardArrowRightIcon />
            </IconButton>
        </Stack>
    )
}

// Calendar body section

const Body = ({ finaldays = {}, month = {} }) => {
    const classes = CalendarStyle()
    const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]
    return (
        <Box pl={3} pt={1}>
            <Grid container>
                {
                    days.map((day) => {
                        return (
                            <Grid item xs={1.7} textAlign={"center"} className={classes.day}>
                                <Box><span>{day}</span></Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container>
                {
                    finaldays.map(e => {
                        return (
                            <Grid item xs={1.7} className={moment(e).format("M") === moment(month).format("M") ? classes.date_curr_month : classes.date_not_curr_month} textAlign={"center"}>
                                <Stack className={moment(e).format("DD MM") === moment(new Date()).format("DD MM") && classes.today} p={1}>
                                <Box mt={1}><span>{moment(e).format("DD")}</span></Box>
                                </Stack>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}