import React from "react";
import { withNavBars } from "../../HOCs";
import { PropertyDetails } from "./propertyDetails";

class PropertyDetailsParent extends React.Component {
    render() {
        return <PropertyDetails />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(PropertyDetailsParent, props);
