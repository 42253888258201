import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { componantsStyles } from "./styles";
import { stringAvatar, timeZoneConverter } from "../../../utils";
import { AgreementDetail } from "../../../assets/agreementDetail";

export const LeadUnitDetails = (props) => {
    const classes = componantsStyles(props)
    return (
        <div className={classes.rootcard} style={{ padding: props?.padding ?? 'auto' }}>
            {
                props?.lead &&
                <Box display="flex" p={1} >
                    <Box>
                        <Avatar
                            src={props.data.img?.length > 0 ? props.data.img : null}
                            alt={props.data.name}
                            className={classes.img}
                            {...stringAvatar(props?.data?.name,14,"4px !important")}
                        />

                    </Box>
                    <Box flexGrow={1} marginInlineStart="12px">
                        <Stack direction="row">
                            <Typography className={classes.name}>
                                {props.data.name}
                            </Typography>

                            {/* {
                                props?.isNew && */}
                            <Typography className={classes.prospect}>
                                {props?.isNew ? 'New Prospect' : 'Contact'}
                            </Typography>
                            {/* } */}

                        </Stack>

                        <Box height="4px" />
                        <Stack direction="row">
                            {/* <Box>
                                <Typography className={classes.call}>
                                    {props.data.phone}
                                </Typography>
                            </Box>
                            {
                                (props.data.mail?.length > 0 && props.data.phone?.length > 0) && <Box className={classes.periodStyle} />
                            } */}

                            <Box>
                                <Typography className={classes.mail}>
                                    {props.data.mail}
                                </Typography>
                            </Box>
                            {props.data.nationality &&
                                <>
                                    <Box className={classes.periodStyle} />
                                    <Box>
                                        <Typography className={classes.mail}>
                                            {props.data.nationality}
                                        </Typography>
                                    </Box>
                                </>
                            }

                        </Stack>
                    </Box>
                </Box>
            }
            {
                props?.unit &&
                <Stack direction="row" p={1} alignItems="center" width="100%">
                    <AgreementDetail />
                    <Box display="flex" alignItems="center" width="100%">
                        
                        <Box flexGrow={1} marginLeft="12px">
                            <Typography className={classes.name}>
                                {props?.quott?.unit > 1 ? `${props?.quott?.unit} Units` : props?.quott?.unit_details?.[0]?.name}
                            </Typography>
                            <Typography className={classes.call}>
                                {timeZoneConverter(props?.quott?.startDate)
                                } - {timeZoneConverter(props?.quott?.endDate)
                                }
                            </Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.name} style={{ color: "#4E5A6B" }}>
                                {props?.quott?.symbol}&nbsp;{props?.total}
                            </Typography>
                        </Box>
                    </Box>

                </Stack>

            }

        </div>
    )
}