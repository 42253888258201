export const INITIAL_TABLE_OFFSET = 0;

export const INITIAL_TABLE_PAGE = 1;

export const INITIAL_TABLE_LIMIT = 10;

export const TABLE_PATH = ["reference_no", "name", "is_active"]

export const TABLE_HEADING = (t) => [
    { title: t("Reference No"), field: "reference_no" },
    { title: t("Name"), field: "name" },
    { title: t("Status"), field: "is_active" },
    { title: "", field: "icon" },
]

export const TABLE_TYPE = [
    { type: ["text"], name: "reference_no" },
    { type: ["long_text"], name: "name" },
    { type: ["status"], name: "is_active" },
    { type: ["more_4"], icon: "icon" },
]

export const INITIAL_ADD_FORM_STATE = {
    form_type: "add",
    id: "",
    reference_no: "",
    name: "",
    is_active: true,
    is_delete: false,
    error: {
        reference_no: "",
        name: "",
    }
}

export const INITIAL_FILTER_STATE = { is_active: [true] }