import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold, remCalc } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    tab: {
        cursor: "pointer",
        marginInlineEnd: "12px",
    },
    selected_tab_text: {
        textAlign: "center",
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.selected_1,
    },
    unselected_tab_text: {
        textAlign: "center",
        fontSize: remCalc(14),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
        marginInlineEnd: "12px"

    },
    selected_tab_indicator: {
        width: "100%",
        height: "3px",
        borderRadius: "4px 4px 0px 0px",
        backgroundColor: theme.palette.primary.main,
    },
    unselected_tab_indicator: {
        width: "100%",
        height: "3px",
        borderRadius: "4px 4px 0px 0px",
        backgroundColor: theme.palette.primary.contrastText,
    },
}));