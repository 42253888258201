import * as React from "react"

export const SqFeet = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 117775">
      <path
        fill="#98a0ac"
        stroke="#98a0ac"
        strokeWidth={0.25}
        d="M2.948 3.344a.587.587 0 0 0-.578.6v11.734a.987.987 0 0 0 .978.978h6.26a.987.987 0 0 0 .978-.978v-1.565a.987.987 0 0 0-.978-.978H8.239v-.978a.987.987 0 0 0-.978-.978h-1.37v-.978a.987.987 0 0 0-.978-.978H3.544V3.94a.587.587 0 0 0-.6-.6Zm2.357.008a.587.587 0 1 0 .587.587.587.587 0 0 0-.587-.586Zm2.348 0a.587.587 0 1 0 .587.587.587.587 0 0 0-.588-.586Zm2.348 0a.587.587 0 1 0 .587.587.587.587 0 0 0-.588-.586Zm2.348 0a.587.587 0 1 0 .587.587.587.587 0 0 0-.588-.586Zm2.739 0a.987.987 0 0 0-.978.978v.978h-1.371a.987.987 0 0 0-.978.978v.98h-1.37a.987.987 0 0 0-.978.978v1.565a.987.987 0 0 0 .978.978h6.065v5.279a.587.587 0 1 0 1.174 0V10.2a.587.587 0 0 0-.025-.179.947.947 0 0 0 .025-.212V4.331a.987.987 0 0 0-.978-.978Zm.2 1.174h1.174v5.087h-5.875V8.439h1.761a.587.587 0 0 0 .587-.587v-1.37h1.761a.587.587 0 0 0 .587-.587Zm-11.744 5.87h1.174v1.37a.587.587 0 0 0 .587.587H7.07v1.369a.587.587 0 0 0 .587.587h1.761v1.174H3.544Zm8.8 5.087a.587.587 0 1 0 .587.587.587.587 0 0 0-.583-.588Zm2.348 0a.587.587 0 1 0 .587.587.587.587 0 0 0-.584-.588Z"
        data-name="Group 112287"
      />
    </g>
  </svg>
)

export const Bed = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 96257">
      <g data-name="Group 96235">
        <path
          fill="#98a0ac"
          stroke="#98a0ac"
          strokeWidth={0.25}
          d="M2.562 4A.571.571 0 0 0 2 4.58v11.048a.572.572 0 1 0 1.143 0v-.571h13.714v.571a.572.572 0 1 0 1.143 0V12.58a1.342 1.342 0 0 0-1.333-1.333H8.1v-.381A2.294 2.294 0 0 0 5.814 8.58H4.286a2.214 2.214 0 0 0-1.143.353V4.58a.571.571 0 0 0-.58-.58Zm1.724 5.722h1.523a1.135 1.135 0 0 1 1.143 1.143v.381H3.143v-.381a1.135 1.135 0 0 1 1.143-1.143Zm-1.143 2.667H7.43a.572.572 0 0 0 .185 0h9.051a.182.182 0 0 1 .19.19v1.333H3.143Z"
          data-name="icons8-empty-bed (1)"
        />
      </g>
    </g>
  </svg>
)

export const Bath = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 96257">
      <g data-name="Group 96235">
        <path
          fill="#98a0ac"
          stroke="#98a0ac"
          strokeWidth={0.25}
          d="M6.866 3.011a2.552 2.552 0 0 0-2.544 2.544v3.634H3.051a.545.545 0 0 0-.13 1.076l.788 4.592a2.369 2.369 0 0 0 1.707 1.879.545.545 0 0 0 1.053.082h8.057a.545.545 0 0 0 1.053-.082 2.37 2.37 0 0 0 1.712-1.881l.788-4.592a.545.545 0 0 0-.13-1.076H5.416V5.555a1.45 1.45 0 0 1 2.9-.086 1.639 1.639 0 0 0-1.04 1.174.308.308 0 0 0 .302.368h2.572a.308.308 0 0 0 .308-.366 1.638 1.638 0 0 0-1.054-1.18 2.55 2.55 0 0 0-2.538-2.454Zm-2.837 7.267H16.97l-.754 4.394a1.263 1.263 0 0 1-1.172 1.05h-.011a.545.545 0 0 0-.08.008H6.047a.545.545 0 0 0-.1-.008 1.263 1.263 0 0 1-1.165-1.049Z"
          data-name="icons8-bathtub (1)"
        />
      </g>
    </g>
  </svg>
)

export const House = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 96257">
      <g data-name="Group 96235">
        <path
          fill="#98a0ac"
          stroke="#98a0ac"
          strokeWidth={0.25}
          d="M10.444 3a.538.538 0 0 0-.315.116l-5.1 4.019A2.694 2.694 0 0 0 4 9.249V16.1a.905.905 0 0 0 .9.9h3.59a.905.905 0 0 0 .9-.9v-3.59a.171.171 0 0 1 .179-.179h1.795a.171.171 0 0 1 .179.179v3.59a.905.905 0 0 0 .9.9h3.59a.905.905 0 0 0 .9-.9V9.249A2.694 2.694 0 0 0 15.9 7.135l-5.1-4.019A.539.539 0 0 0 10.444 3Zm.018 1.224 4.768 3.757a1.613 1.613 0 0 1 .616 1.268v6.673h-3.231v-3.41a1.265 1.265 0 0 0-1.256-1.256H9.564a1.265 1.265 0 0 0-1.256 1.256v3.41H5.077V9.249a1.613 1.613 0 0 1 .616-1.268Z"
          data-name="icons8-home (7)"
        />
      </g>
    </g>
  </svg>
)

export const CloseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13.997} height={14} {...props}>
    <path
      fill="#7c8594"
      d="M13.366 0a.617.617 0 0 0-.43.19L6.998 6.128 1.06.19a.617.617 0 1 0-.872.872L6.126 7 .188 12.937a.617.617 0 1 0 .872.872l5.938-5.938 5.938 5.938a.617.617 0 1 0 .872-.872L7.87 6.999l5.937-5.937A.617.617 0 0 0 13.365 0Z"
      data-name="icons8-delete (3)"
    />
  </svg>
)

export const CustomQuotIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={82.345}
    height={107.941}
    {...props}
  >
    <g data-name="icons8-bill (15)">
      <g data-name="Group 117804">
        <rect
          width={82.233}
          height={107.941}
          fill="#c5cae9"
          data-name="Rectangle 58516"
          rx={6}
        />
      </g>
      <path
        fill="#9fa8da"
        d="M.106 18.122h82.238v2.57H.106Zm0 64.249h82.238v2.57H.106Z"
        data-name="Path 101824"
      />
      <g data-name="Group 117803">
        <path
          fill="#3949ab"
          d="M7.816 28.402h5.14v5.14h-5.14Zm10.28 0h30.839v5.14H18.096Zm48.829 0h10.28v5.14h-10.28ZM7.816 38.682h5.14v5.14h-5.14Zm10.28 0h15.42v5.14h-15.42Zm48.829 0h10.28v5.14h-10.28ZM7.816 48.962h5.14v5.14h-5.14Zm10.28 0h28.269v5.14H18.096Zm48.829 0h10.28v5.14h-10.28ZM7.816 59.241h5.14v5.14h-5.14Zm10.28 0h33.409v5.14H18.096Zm48.829 0h10.28v5.14h-10.28ZM7.816 69.521h5.14v5.14h-5.14Zm10.28 0h17.99v5.14h-17.99Zm48.829 0h10.28v5.14h-10.28Z"
          data-name="Path 101825"
        />
        <path
          fill="#3f51b5"
          d="M18.096 5.272h48.829v5.14H18.096ZM66.925 92.65h10.28v5.14h-10.28Zm-30.839 0h23.13v5.14h-23.13Z"
          data-name="Path 101826"
        />
      </g>
    </g>
  </svg>
)

export const MoneyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    data-name="Component 7757 \u2013 21"
    {...props}
  >
    <path
      fill="#98a0ac"
      d="M1.432 2A1.433 1.433 0 0 0 0 3.432v6.955a1.433 1.433 0 0 0 1.432 1.432h7.842a3.842 3.842 0 0 1 .777-1.227H3.219A1.629 1.629 0 0 0 1.227 8.6V5.22a1.635 1.635 0 0 0 1.992-1.993h11.562a1.629 1.629 0 0 0 1.992 1.991v2.746A3.24 3.24 0 0 1 18 8.836v-5.4A1.433 1.433 0 0 0 16.568 2H1.432ZM9 4.455A2.278 2.278 0 0 0 6.955 6.91 2.278 2.278 0 0 0 9 9.364a2.278 2.278 0 0 0 2.045-2.455A2.278 2.278 0 0 0 9 4.455ZM4.5 6.091a.818.818 0 1 0 .818.818.818.818 0 0 0-.818-.818Zm9 0a.818.818 0 1 0 .818.818.818.818 0 0 0-.818-.818Zm2.045 2.455a2.456 2.456 0 0 0-1.894.892 3.887 3.887 0 0 1 3.118 3.69 2.454 2.454 0 0 0-1.223-4.581Zm-2.659 1.636a3.068 3.068 0 1 0 3.068 3.068 3.068 3.068 0 0 0-3.068-3.068Z"
      data-name="icons8-money (2)"
    />
  </svg>
)
