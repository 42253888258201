import React from "react";

function ResidentialInActiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g data-name="Group 113774" transform="translate(8945 23374)">
        <g transform="translate(-8949 -23376)">
          <path
            fill="#9b9da0"
            d="M0 0H13.263V5.684H0z"
            data-name="Rectangle 57565"
            transform="translate(9.474 17.316)"
          ></path>
          <path
            fill="#babcbf"
            d="M14 6.895h9.474v-.948A.948.948 0 0022.526 5h-7.579a.948.948 0 00-.947.947z"
            data-name="Path 100570"
            transform="translate(-2.632)"
          ></path>
          <path
            fill="#babcbf"
            d="M10 11v9.474h13.263V11z"
            data-name="Path 100571"
            transform="translate(-.526 -3.158)"
          ></path>
          <path
            fill="#7a7c7f"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57566"
            transform="translate(11.368 19.211)"
          ></path>
          <path
            fill="#7a7c7f"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57573"
            transform="translate(18.947 19.211)"
          ></path>
          <path
            fill="#7a7c7f"
            d="M0 0H2.842V3.789H0z"
            data-name="Rectangle 57567"
            transform="translate(14.684 19.211)"
          ></path>
          <path
            fill="#7a7c7f"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57568"
            transform="translate(11.368 13.526)"
          ></path>
          <path
            fill="#7a7c7f"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57574"
            transform="translate(18.947 13.526)"
          ></path>
          <path
            fill="#7a7c7f"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57569"
            transform="translate(15.158 13.526)"
          ></path>
          <path
            fill="#7a7c7f"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57570"
            transform="translate(11.368 9.737)"
          ></path>
          <path
            fill="#7a7c7f"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57571"
            transform="translate(15.158 9.737)"
          ></path>
          <path
            fill="#7a7c7f"
            d="M0 0H1.895V1.895H0z"
            data-name="Rectangle 57572"
            transform="translate(18.947 9.737)"
          ></path>
          <path
            fill="#9b9da0"
            d="M22.737 9.947H9.474a.474.474 0 010-.947h13.263a.474.474 0 110 .947z"
            data-name="Path 100572"
            transform="translate(0 -2.105)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ResidentialInActiveIcon;
