import { Box } from "@mui/material";
import styled from '@mui/material/styles/styled';
import React from "react";
import { MonthlyCalendar } from "./components/monthlyCalendar";
import { WeeklyCalendar } from "./components/weeklyCalendar";
import { CalendarHeader } from "./components/calendarHeader";
import { DailyCalendar } from "./components/dailyCalendar";
const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 202px)`,
    overflow: "hidden",
    marginTop: "10px"
}))
export const AnnouncementCalendar = (
    {
        openDeclinePopup = () => false,
        loading = false,
        daily = {},
        nextdate = () => false,
        prevdate = () => false,
        weekprev = () => false,
        weeknext = () => false,
        monthnext = () => false,
        monthprev = () => false,
        month = "",
        weekdates = {},
        dates = [],
        monthdays = [],
        calendarData = [],
        open = "",
        select = "",
        selected = "",
        setselect = () => false,
        showAll = () => false,
        setDropdownValue = () => false,
        handleSelected = () => false,
        calendarFunctions = () => false,
        selectedCompany = {},
        selectedTeams = {},
        openFilter=()=>false,
        is_announcementBoard = false,
        showDailyCalendar=()=>false,
        t,
        filterData={}
    }
) => {


    return (
        <Box p={2}>
            <CalendarHeader
                selected={selected}
                weekprev={weekprev}
                weeknext={weeknext}
                monthnext={monthnext}
                monthprev={monthprev}
                month={month}
                weekdates={weekdates}
                setDropdownValue={setDropdownValue}
                setselect={setselect}
                select={select}
                showAll={showAll}
                open={open}
                daily={daily.date}
                nextdate={nextdate}
                prevdate={prevdate}
                calendarFunctions={calendarFunctions}
                selectedCompany={selectedCompany}
                selectedTeams={selectedTeams}
                t={t}
                openFilter={openFilter}
                is_announcementBoard={is_announcementBoard}
                filterData={filterData}
            />
            <CustomPaper>
                <Box>
                    <Box>
                        {
                            selected === "Weekly" &&
                            <WeeklyCalendar weekdates={dates}
                                data={calendarData}
                                openDeclinePopup={openDeclinePopup}
                                loading={loading}
                                handleSelected={handleSelected}
                                showDailyCalendar={showDailyCalendar}
                                t={t}
                            />
                        }
                    </Box>
                    <Box>
                        {
                            selected === "Monthly" &&
                            <MonthlyCalendar dates={monthdays} month={month}
                                data={calendarData}
                                openDeclinePopup={openDeclinePopup}
                                loading={loading}
                                handleSelected={handleSelected}
                                showDailyCalendar={showDailyCalendar}
                                t={t}
                            />
                        }
                    </Box>
                    <Box>
                        {
                            selected === "Daily" &&
                            <DailyCalendar progress="0" list={calendarData} openDeclinePopup={openDeclinePopup} t={t} handleSelected={handleSelected} />
                        }
                    </Box>
                </Box>
            </CustomPaper>
        </Box>
    )
}