import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { ParkingCalenderIcon } from "../../../assets";
import FilterIMG from "../../../assets/filter";
import FilterIcon from "../../../assets/filterIcon";
import { CalendarDateChange, DialogBox } from "../../../components";
import { CustomDropDown } from "../../scheduleBoard/components/customDropDown";
import { CreateActivity } from "./createActivity";
import { useStyles } from "./style";

export const CalendarHeader = ({
  t,
  selected = "",
  weekdates = "",
  weekprev = () => false,
  weeknext = () => false,
  month = "",
  monthprev = () => false,
  monthnext = () => false,
  open = "",
  daily = "",
  prevdate = () => false,
  nextdate = () => false,
  showAll = false,
  setDropdownValue = () => false,
  select = "",
  setselect = "",
  calendarFunctions = () => false,
  selectedCompany = {},
  selectedTeams = {},
  is_announcementBoard = false,
  filterData = {},
}) => {
  const classes = useStyles();
  const [chooseActivity, setChooseActivity] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState({});
  const [showActivityType, setShowActivityType] = React.useState(true);
  // const [size, setSize] = React.useState({ isMedium: true, isNormal: false })
  const openfilter = () => {};
  return (
    <Box>
      <Grid container alignItems={"center"} justifyContent="space-between">
        <Stack
          direction={"row"}
          spacing={2}
          width="250px"
          style={{ marginBottom: "10px" }}
        >
          {/* <Grid item>
                        <CustomSelect
                            noSearch
                            options={[]}
                            // isReadOnly={props?.isReadOnly}
                            color="white"
                            placeholder={"My Team"}
                        // value={assignData?.company}
                        // onChange={(value) => {
                        //     updateState("company", value, true)
                        // }} 
                        />
                    </Grid>
                    <Grid item>
                        <CustomSelect
                            noSearch
                            options={[]}
                            // isReadOnly={props?.isReadOnly}
                            color="white"
                            placeholder={"Team 01"}
                        // value={assignData?.company}
                        // onChange={(value) => {
                        //     updateState("company", value, true)
                        // }} 
                        />
                    </Grid> */}
          <CustomDropDown t={t} type={selected} setType={setDropdownValue} />
        </Stack>
        <Grid item style={{ marginBottom: "10px" }}>
          <Box>
            {selected === "Weekly" && (
              <CalendarDateChange
                state={weekdates}
                prev={weekprev}
                next={weeknext}
                comp={"weekly"}
              />
            )}
            {selected === "Monthly" && (
              <CalendarDateChange
                state={month}
                prev={monthprev}
                next={monthnext}
                comp={"month"}
              />
            )}
            {selected === "Daily" && (
              <CalendarDateChange
                state={daily}
                prev={prevdate}
                next={nextdate}
                comp={"daily"}
              />
            )}
          </Box>
        </Grid>
        <Grid item style={{ marginBottom: "10px" }}>
          {is_announcementBoard ? (
            <Stack direction="row" spacing={2} alignItems={"center"}>
              {filterData?.category?.length > 0 ? (
                <IconButton onClick={openfilter}>
                  <FilterIcon />
                </IconButton>
              ) : (
                <IconButton onClick={openfilter} className={classes.iconButton}>
                  <FilterIMG />
                </IconButton>
              )}
              <Box className={classes.verticalborder} />
              <CustomDropDown
                t={t}
                type={selected}
                setType={setDropdownValue}
              />
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              {/* <CalendarDropDown
                        open={open}
                        selected={selected}
                        showAll={showAll}
                        setDropdownValue={setDropdownValue}
                        select={select}
                        right

                    /> */}

              <Button
                variant="contained"
                fullWidth
                onClick={() => setChooseActivity(true)}
              >
                {t("Create Activity")}
              </Button>
              <Box className={classes.layoutSwitch}>
                <Stack direction={"row"} spacing={1} alignItems="center">
                  <Box
                    className={
                      select === "nonlist"
                        ? classes.selectedBtn
                        : classes.notSelectedBtn
                    }
                  >
                    <ParkingCalenderIcon
                      onClick={() => setselect("nonlist")}
                      fill={select === "nonlist" ? "#FFFFFF" : "#98A0AC"}
                    />
                  </Box>
                  <Box
                    className={
                      select === "list"
                        ? classes.selectedBtn
                        : classes.notSelectedBtn
                    }
                  >
                    <MenuIcon onClick={() => setselect("list")} />
                  </Box>
                </Stack>
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>
      <DialogBox
        padding={"24px"}
        maxWidth={showActivityType ? "sm" : "md"}
        width={"748px !important"}
        background={"#fff"}
        open={chooseActivity}
        onClose={() => {
          setChooseActivity(false);
          setSelectedType({});
        }}
        header={`${t("Create Activity")} ${selectedType?.title ? "-" : ""} ${
          selectedType?.title ? t(selectedType?.title) : ""
        }`}
        component={
          <CreateActivity
            calendarFunctions={calendarFunctions}
            handleClose={() => setChooseActivity(false)}
            selectedCompany={selectedCompany}
            selectedTeams={selectedTeams}
            setSelectedType={setSelectedType}
            showActivityType={showActivityType}
            setShowActivityType={setShowActivityType}
            t={t}
          />
        }
      />
    </Box>
  );
};
