import React from "react";
import { withNavBars } from "../../HOCs";
import Teams from "./teams";

class TeamsParent extends React.Component {
  render() {
    return <Teams {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(TeamsParent, props);
