import { Box } from "@mui/material";
import styled from '@mui/material/styles/styled';
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths } from "date-fns";
import moment from "moment";
import React from "react";
import { withNamespaces } from "react-i18next";
import { DialogBox, FilterGenerator, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods, accessCheckRender, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew } from "../../utils";
import { ViewAnnounsment } from "../announsmentNew/viewAnnounsment";
import { AnnouncementCalendar } from "./calendar";
import { DailyCalendar } from "./components/dailyCalendar";

const CustomPaper = styled('div')(({ theme }) => ({
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    // height: `calc(100vh - 202px)`,
    overflow: "hidden",
}))
const AnnouncementBoard = ({ t = () => false }) => {
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [month, setMonth] = React.useState(new Date())
    const [finalDays, setFinalDays] = React.useState([])
    const [calendarData, setCalendarData] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState("Weekly")
    const [weekdates, setWeekdates] = React.useState({ startDate: new Date(), endDate: addDays(new Date(), 6) })
    const [daily, setDaily] = React.useState({ date: new Date() })
    const [permission, setPermission] = React.useState({})
    const [buildList, setBuildList] = React.useState([]);
    const debounce = UseDebounce()
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const [propertyData, setPropertyData] = React.useState([])
    const [filterData, setFilterData] = React.useState({
        properties: [],
        category: [],
        role: []
    })
    const [enumValue, setEnumValue] = React.useState({
        category: [],
    });
    const [isDrawer, setIsDrawer] = React.useState(false)
    const [isDaily, setIsDaily] = React.useState({
        bool: false,
        date: ""
    })

    // table functions
    const [details, setDetails] = React.useState({
        details: {},
        bool: false,
        property_ids: [],
        builds: [],
    });





    //dateRange
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }
    // Daily Calendar Functions
    //nextdate
    const nextdate = () => {
        setDaily({ ...daily, date: addDays(daily?.date, 1) })
        debounce(() => {
            getCalendarData(moment(addDays(daily?.date, 1)).format("YYYY-MM-DD 00:00:00"), moment(addDays(daily?.date, 1)).format("YYYY-MM-DD 23:59:59"), 1, selectedCompany?.value, filterData)

        }, 800)
    }
    //prevdate
    const prevdate = () => {
        setDaily({ ...daily, date: subDays(daily?.date, 1) })
        debounce(() => {
            getCalendarData(moment(subDays(daily?.date, 1)).format("YYYY-MM-DD 00:00:00"), moment(subDays(daily?.date, 1)).format("YYYY-MM-DD 23:59:59"), 1, selectedCompany?.value, filterData)

        }, 800)
    }

    let weekstartDate = weekdates.startDate
    const weekendDate = weekdates.endDate

    var dates = []
    while (weekstartDate <= weekendDate) {
        dates.push(weekstartDate)
        weekstartDate = addDays(weekstartDate, 1)
    }
    //nextweek
    const weeknext = () => {
        setWeekdates({
            startDate: addDays(weekdates.endDate, 1), endDate: addDays(weekdates.endDate, 7)
        })
        debounce(() => {
            getCalendarData(addDays(weekdates.endDate, 1), addDays(weekdates.endDate, 7), 4, selectedCompany?.value, filterData)

        }, 800)

    }
    //prevweek
    const weekprev = () => {
        setWeekdates({
            startDate: subDays(weekdates.startDate, 7), endDate: subDays(weekdates.startDate, 1)
        })
        debounce(() => {
            getCalendarData(subDays(weekdates.startDate, 7), subDays(weekdates.startDate, 1), 4, selectedCompany?.value, filterData)

        }, 800)
    }
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)

        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
                // Month rendar funtion and state
                const monthStart = startOfMonth(month);
                const monthEnd = endOfMonth(monthStart);
                const monthstartDate = startOfWeek(monthStart);
                const monthendDate = endOfWeek(monthEnd);
                let monthdays = dateRange(monthstartDate, monthendDate)
                setFinalDays(monthdays)

            }
        }
        //eslint-disable-next-line
    }, [auth])
    const returnAllPropertyJson = (list) => {
        const data = list?.map((val) => {
            return {
                name: val?.name,
                id: val?.id
            }
        })

        return (
            ([
                {
                    name: t("All Properties"),
                    id: null
                },
                ...data]
            ))
    }
    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            setPropertyData(returnAllPropertyJson(company?.selected?.property))
            getCalendarData(weekdates?.startDate, weekdates?.endDate, 4, company?.selected?.value, filterData)
            getBuilds()
            getEnum()
        }
    }


    //next month
    const monthnext = () => {
        const datebyMonth = addMonths(month, 1)
        setMonth(addMonths(month, 1))
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let monthdays = dateRange(monthstartDate, monthendDate)
        setFinalDays(monthdays)
        debounce(() => {
            getCalendarData(startOfMonth(addMonths(month, 1)), endOfMonth(addMonths(month, 1)), 24, selectedCompany?.value, filterData)

        }, 800)
    }
    //prevmonth
    const monthprev = () => {
        const datebyMonth = subMonths(month, 1)
        setMonth(subMonths(month, 1))
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let monthdays = dateRange(monthstartDate, monthendDate)
        setFinalDays(monthdays)
        debounce(() => {
            getCalendarData(startOfMonth(subMonths(month, 1)), endOfMonth(subMonths(month, 1)), 24, selectedCompany?.value, filterData)

        }, 800)
    }

    // header functions

    const showAll = () => {
        setOpen(!open)
    }
    const setDropdownValue = (value) => {
        setSelected(value)
        setOpen(false)
        if (value === "Weekly") {

            getCalendarData(weekdates?.startDate, weekdates?.endDate, 4, selectedCompany?.value, filterData)

        } else if (value === "Monthly") {

            getCalendarData(addDays(startOfMonth(month), 1), endOfMonth(month), 24, selectedCompany?.value, filterData)

        } else if (value === "Daily") {

            getCalendarData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 4, selectedCompany?.value, filterData)

        }
    }


    const getCalendarData = (startDate, endDate, duration, companyId = selectedCompany?.value, filteredData = {}) => {
        const payload = {
            company_id: [companyId],
            is_draft: false,
            from_date: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
            to_date: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
            batchDuration: duration,
            category: filteredData?.category?.map((x) => { return x?.value }) ?? [],
            property_id: filteredData?.property?.filter((x) =>{return x?.label !=="All Properties"})?.map((i)=>{return i?.value}) ?? [],
            to_builds: filteredData?.role?.map((x) => { return x?.value }) ?? [],
            is_active: [true],
        }
        NetworkCall(
            `${config.api_url}/notifications/calender_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let myObject = response?.data?.data
                let result = []
                for (var key in myObject) {
                    if (myObject.hasOwnProperty(key)) {
                        result.push({
                            date: key,
                            calendarData: myObject[key]
                        })
                    }
                }
                setCalendarData(result)
            }).catch((err) => {
                console.log(err)
            })
    }
    const handleSelected = (data, date, name = "") => {
        getDetails(data?.id)
    }

    const calendarFunctions = () => {
        if (selected === "Weekly") {
            getCalendarData(weekdates?.startDate, weekdates?.endDate, 4, selectedCompany?.value, filterData)
        } else if (selected === "Monthly") {
            getCalendarData(addDays(startOfMonth(month), 1), endOfMonth(month), 24, selectedCompany?.value, filterData)
        } else if (selected === "Daily") {
            getCalendarData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 1, selectedCompany?.value, filterData)

        }
    }
    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enum_types.notification_category_type]);
        setEnumValue({
            category: result?.notification_category_type,
        });
    }
    // View Details
    const getDetails = (id) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "",
        });
        const requestPayload = {
            id: id ?? "",
            user_profile_id: localStorage.getItem("profileID"),
            company_id: selectedCompany?.value,

        };
        NetworkCall(
            `${config.api_url}/notifications/view`,
            NetWorkCallMethods.post,
            requestPayload,
            null,
            true,
            false
        )
            .then((res) => {
                setDetails({
                    ...details,
                    details: res?.data?.data?.notification_details,
                    bool: true,
                    property_ids: [],
                    builds: res?.data?.data?.buildName ?? [],
                });
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
            })
            .catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,

                    msg: t("Some Thing Went Wrong. Please try again"),
                });
            });
    };
    // get builds list
    const getBuilds = () => {
        NetworkCall(
            `${config.api_url}/app_build`,
            NetWorkCallMethods.post,
            {},
            null,
            true,
            false
        )
            .then((res) => {
                const listData = res?.data?.data?.map((x) => {
                    return {
                        label: x?.build_name,
                        value: x?.build_code,
                    };
                });
                setBuildList(listData);
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong. Please try again"),
                });
            });
    };

    const CloseViewModal = () => {
        setDetails({
            ...details,
            details: {},
            bool: !details?.bool,
            property_ids: [],
            builds: [],
        });
    };
    const openFilter = () => {
        setIsDrawer(true)
    }
    const onApplyFilter = (value) => {
        setFilterData(value)
        if (selected === "Weekly") {
            getCalendarData(weekdates?.startDate, weekdates?.endDate, 4, selectedCompany?.value, value)
        }
        else if (selected === "Monthly") {
            getCalendarData(addDays(startOfMonth(month), 1), endOfMonth(month), 24, selectedCompany?.value, value)
        }
        else {
            getCalendarData(moment(daily?.date).format("YYYY-MM-DD 00:00:00"), moment(daily?.date).format("YYYY-MM-DD 23:59:59"), 1, selectedCompany?.value, value)
        }
    }
    const showDailyCalendar = (date) => {
        getCalendarData(date, date, 4, selectedCompany?.value, filterData)
        setIsDaily({ bool: true, date: date })

    }
    const closeDailyCalendar = () => {
        setIsDaily({ bool: false, date: "" })
        if (selected === "Weekly") {
            getCalendarData(weekdates?.startDate, weekdates?.endDate, 4, selectedCompany?.value, filterData)
        }
        else {
            getCalendarData(addDays(startOfMonth(month), 1), endOfMonth(month), 24, selectedCompany?.value, filterData)
        }

    }
    const render = () => {
        return (

            <>
                <Box>
                    <Subheader
                        hideBackButton
                        title={t("Announcement Board")}
                        select
                        options={companyList}
                        value={selectedCompany}
                        onchange={(e) =>
                            setSelectedCompany(e)
                        }
                        companyId={selectedCompany?.value}
                    />
                </Box>
                <Box>
                    <AnnouncementCalendar
                        setDaily={setDaily}
                        daily={daily}
                        prevdate={prevdate}
                        nextdate={nextdate}
                        weekprev={weekprev}
                        weeknext={weeknext}
                        monthnext={monthnext}
                        monthprev={monthprev}
                        month={month}
                        weekdates={weekdates}
                        dates={dates}
                        monthdays={finalDays}
                        calendarData={calendarData}
                        open={open}
                        selected={selected}
                        showAll={showAll}
                        openFilter={openFilter}
                        setDropdownValue={setDropdownValue}
                        handleSelected={handleSelected}
                        calendarFunctions={calendarFunctions}
                        selectedCompany={selectedCompany}
                        t={t}
                        filterData={filterData}
                        is_announcementBoard={true}
                        showDailyCalendar={showDailyCalendar}
                    />
                </Box>
                <FilterGenerator
                    open={isDrawer}
                    onClose={() => setIsDrawer(false)}
                    components={[
                        {
                            component: "select",
                            value: filterData?.property,
                            options: propertyData?.map((x) => {
                                return {
                                    label: x?.name,
                                    value: x?.id,
                                };
                            }) ?? [],
                            isMulti: true,
                            label: t("Properties"),
                            state_name: "property",
                            placeholder: t("Select Properties")
                        },
                        {
                            component: "select",
                            value: filterData?.category,
                            options: enumValue?.category ?? [],
                            isMulti: true,
                            label: t("Category"),
                            state_name: "category",
                            placeholder: t("Select Category")
                        },
                        {
                            component: "select",
                            value: filterData?.role,
                            options: buildList ?? [],
                            isMulti: true,
                            label: t("Role"),
                            state_name: "role",
                            placeholder: t("Select Role")
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
                <DialogBox
                    id={"id_123"}
                    open={details?.bool}
                    onClose={() => CloseViewModal(false)}
                    handleClose={() => CloseViewModal(false)}
                    header={t("View Announcement")}
                    isNormal
                    width={"710px !important"}
                    maxWidth={"md"}
                    component={
                        <Box>
                            <ViewAnnounsment
                                t={t}
                                details={details?.details}
                                property={details?.property_ids ?? []}
                                company={selectedCompany}
                                builds={details?.builds ?? []}
                            />
                        </Box>
                    }
                />
                <DialogBox
                    open={isDaily?.bool}
                    onClose={() => closeDailyCalendar()}
                    handleClose={() => closeDailyCalendar()}
                    header={moment(isDaily?.date).format("DD MMM YYYY")}
                    isNormal
                    width={"710px !important"}
                    maxWidth={"md"}
                    component={
                        <Box>
                            <CustomPaper>
                                <DailyCalendar progress="0" list={calendarData} t={t} handleSelected={handleSelected} />
                            </CustomPaper>
                        </Box>
                    }
                />

            </>
        )
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}
export default withNamespaces("announsment")(AnnouncementBoard)