export const PropertyManagementFoundation = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98342">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <path
                    d="M21.094 20.349v-8.175l-5.538.006v8.168h-1.125V9l-5.525 1.481v9.867H7.5v.959h15v-.959Zm-4.048-6.8h2.526V14.6h-2.526Zm0 2.4h2.526v1.057h-2.526Zm0 2.4h2.526v1.061h-2.526Zm-6.63-7.1h2.505v1.048h-2.505Zm0 2.384h2.505v1.048h-2.505Zm0 2.384h2.505v1.048h-2.505Zm0 2.384h2.505v1.048h-2.505Z"
                    fill={props?.fill ?? "#c1c5cb"}
                />
            </g>
        </g>
    </svg>
)
