/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Tabs, Typography, tabsClasses } from '@mui/material';
import React from 'react';
import ScrollContainer from "react-indiana-drag-scroll";
import { TableWithPagination } from '../../../components';
import { CollapesInfoCard } from './collapesInfoCard';
import { CollapesStyle } from './style';

export const Collapes = ({
    data = {},
    page = "",
    limit = "",
    handlePagination = () => false,
    handleChangeLimit = () => false,
    handleIcon = () => false,
    getTableData = () => false,
    tableData = {},
    t = () => false,
    tab = [],
    tabState,
    onTabChange = () => false
}) => {
    const classes = CollapesStyle()
    React.useEffect(() => {
        getTableData(data?.key)
    }, [tabState])
    const handleChange = (event, newValue) => {
        onTabChange(newValue)
    };

    return (
        <>
            <Box className={classes.box}>
                {/* <Stack direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Box className={classes.tab}>
                        {
                            tab?.map((x) => {
                                return (
                                    <Box className={x?.title === tabState ? classes.tabTitle1 : classes.tabTitle} onClick={() => {
                                        onTabChange(x?.title)
                                    }}>
                                        <Typography noWrap>
                                            {x?.title}
                                            {
                                                x?.title === tabState && <Box className={classes.absolute} />

                                            }
                                        </Typography>

                                    </Box>
                                )
                            })
                        }

                    </Box>

                </Stack> */}
                <Box
                    sx={{
                        flexGrow: 1,
                        bgcolor: 'background.paper',
                    }}
                >
                    <Tabs
                        value={tabState}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                              '&.Mui-disabled': { opacity: 0.3 },
                            },
                          }}
                    >
                        {
                            tab?.map((x) => {
                                return (
                                    <Box className={x?.title === tabState ? classes.tabTitle1 : classes.tabTitle} onClick={() => {
                                        onTabChange(x?.title)
                                    }}>
                                        <Typography noWrap>
                                            {x?.label}
                                            {
                                                x?.title === tabState && <Box className={classes.absolute} />

                                            }
                                        </Typography>

                                    </Box>
                                )
                            })}
                    </Tabs>
                </Box>
                <Box style={{ backgroundColor: "#E4E8EE", height: "1.1px" }} />
                {
                    data?.show > 0 &&
                    <ScrollContainer className="scroll-container">
                        <Stack pt={"12px"} direction={"row"} >
                            {
                                data?.cardData?.map((e) => {
                                    return (
                                        <CollapesInfoCard data={e} />
                                    )
                                })
                            }
                        </Stack>
                    </ScrollContainer>
                }

                <Box p={2}>
                    <TableWithPagination
                        heading={tableData?.[data?.key]?.heading}
                        rows={tableData?.[data?.key]?.list ?? []}
                        dataType={tableData?.[data?.key]?.datatype}
                        path={tableData?.[data?.key]?.path}
                        showpagination
                        tableType="no-side"
                        handleIcon={handleIcon}
                        handlePagination={handlePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={tableData?.[data?.key]?.count}
                        page={page}
                        limit={limit}
                        view={true}
                        height={`calc(100vh - 400px)`}
                        edit={true}
                        delete={true}
                        extraData={data?.key}
                        noDataSvg={true} />
                </Box>
            </Box>
        </>
    )
}