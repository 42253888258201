import React from "react";
import ResourceMaster from "./resourceMaster";
import { Box } from "@mui/material";
import { withNavBars } from "../../HOCs";
const ResourceMasterParent = () =>{
    return(
        <Box>
            <ResourceMaster/>
        </Box>
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(ResourceMasterParent,props)