import { Avatar, Box, Stack, Typography } from "@mui/material"
import React from "react"
import { stringAvatar } from "../../../utils"
import { useStyles } from "./styles"
export const LeadDetailCard = ({ t = () => false, data = [] }) => {
    const classes = useStyles()

    const navigate = (id) => {
        window.open(`/leadview?id=${id}`, "_blank");
    }

    return (
        <Box className={classes.interestunitcard}>
            <Box className={classes.unitproperty}>
                <Typography className={classes.intTitle}>{t("LEAD DETAIL")}</Typography>
                <Stack direction="row" spacing={1} mt={2} onClick={() => navigate(data?.id)} sx={{ cursor: "pointer" }}>
                    <Avatar src={data?.logo} {...stringAvatar(data?.first_name)} {...stringAvatar(data?.lead_name)} />
                    <Box mt={0.5}>
                        <Typography className={classes.unit_name}>{data?.lead_name ?? "-"}</Typography>
                        <Typography className={classes.property_name}>
                            {data?.reference_no}
                        </Typography>
                    </Box>
                </Stack>
                {/* <Box mt={1}>
                    {index !== list.length - 1 &&
                        <Divider />
                    }
                </Box> */}
            </Box >

        </Box >
    )
}