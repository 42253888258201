import * as React from "react"
export const AddUnit = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      fill="#6dafb3"
      d="M14 16.001H2a1.953 1.953 0 0 1-2-1.9v-7.21a3.094 3.094 0 0 1 1.271-2.488l5.216-3.9a2.535 2.535 0 0 1 1.514-.5 2.533 2.533 0 0 1 1.511.5l5.216 3.9A3.093 3.093 0 0 1 16 6.891v7.21a1.953 1.953 0 0 1-2 1.9ZM7.458 9.544v2.459H8.54V9.544H11V8.461H8.54v-2.46H7.458v2.46H5v1.083Z"
      data-name="Path 97629"
    />
  </svg>
)
