import * as React from "react"

const OtherResourcesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={144} height={170} {...props}>
    <defs>
      <filter
        id="a"
        x={0}
        y={40}
        width={144}
        height={50}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur" />
        <feFlood floodColor="#272727" floodOpacity={0.051} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="b"
        x={0}
        y={80}
        width={144}
        height={50}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-2" />
        <feFlood floodColor="#272727" floodOpacity={0.039} />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="c"
        x={0}
        y={0}
        width={144}
        height={50}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-3" />
        <feFlood floodColor="#272727" floodOpacity={0.078} />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="d"
        x={0}
        y={120}
        width={144}
        height={50}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-4" />
        <feFlood floodColor="#272727" floodOpacity={0.039} />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 104524">
      <g
        data-name="Group 104525"
        transform="translate(-467 -189.5)"
        opacity={0.9}
      >
        <g transform="translate(467 189.5)" filter="url(#a)">
          <rect
            data-name="Rectangle 55396"
            width={126}
            height={32}
            rx={4}
            transform="translate(9 46)"
            fill="#fff"
          />
        </g>
        <circle
          data-name="Ellipse 129647"
          cx={10}
          cy={10}
          r={10}
          transform="translate(482 241.5)"
          fill="#fff4eb"
        />
        <g data-name="Group 104524" fill="#e4e8ee">
          <rect
            data-name="Rectangle 55399"
            width={46}
            height={4}
            rx={2}
            transform="translate(506 245.5)"
            opacity={0.7}
          />
          <rect
            data-name="Rectangle 55400"
            width={46}
            height={4}
            rx={2}
            transform="translate(506 254.5)"
            opacity={0.7}
          />
        </g>
        <path
          data-name="Path 97587"
          d="M594.928 251.464h-2.556v2.544h-.744v-2.544h-2.556v-.756h2.556v-2.556h.744v2.556h2.556Z"
          fill="#ced3dd"
        />
      </g>
      <g
        data-name="Group 104526"
        transform="translate(-467 -149.5)"
        opacity={0.7}
      >
        <g transform="translate(467 149.5)" filter="url(#b)">
          <rect
            data-name="Rectangle 55396"
            width={126}
            height={32}
            rx={4}
            transform="translate(9 86)"
            fill="#fff"
          />
        </g>
        <circle
          data-name="Ellipse 129647"
          cx={10}
          cy={10}
          r={10}
          transform="translate(482 241.5)"
          fill="#eef9ee"
        />
        <g data-name="Group 104524" fill="#f2f4f7">
          <rect
            data-name="Rectangle 55399"
            width={46}
            height={4}
            rx={2}
            transform="translate(506 245.5)"
          />
          <rect
            data-name="Rectangle 55400"
            width={46}
            height={4}
            rx={2}
            transform="translate(506 254.5)"
          />
        </g>
        <path
          data-name="Path 97587"
          d="M594.928 251.464h-2.556v2.544h-.744v-2.544h-2.556v-.756h2.556v-2.556h.744v2.556h2.556Z"
          fill="#ced3dd"
        />
      </g>
      <g data-name="Group 104529" transform="translate(-467 -229.5)">
        <g transform="translate(467 229.5)" filter="url(#c)">
          <rect
            data-name="Rectangle 55396"
            width={126}
            height={32}
            rx={4}
            transform="translate(9 6)"
            fill="#fff"
          />
        </g>
        <circle
          data-name="Ellipse 129647"
          cx={10}
          cy={10}
          r={10}
          transform="translate(482 241.5)"
          fill="#f1f7ff"
        />
        <g data-name="Group 104524" fill="#e4e8ee">
          <rect
            data-name="Rectangle 55399"
            width={46}
            height={4}
            rx={2}
            transform="translate(506 245.5)"
          />
          <rect
            data-name="Rectangle 55400"
            width={46}
            height={4}
            rx={2}
            transform="translate(506 254.5)"
          />
        </g>
        <path
          data-name="Path 97587"
          d="M594.928 251.464h-2.556v2.544h-.744v-2.544h-2.556v-.756h2.556v-2.556h.744v2.556h2.556Z"
          fill="#ced3dd"
        />
      </g>
      <g
        data-name="Group 104527"
        transform="translate(-467 -109.5)"
        opacity={0.4}
      >
        <g transform="translate(467 109.5)" filter="url(#d)">
          <rect
            data-name="Rectangle 55396"
            width={126}
            height={32}
            rx={4}
            transform="translate(9 126)"
            fill="#fff"
          />
        </g>
        <circle
          data-name="Ellipse 129647"
          cx={10}
          cy={10}
          r={10}
          transform="translate(482 241.5)"
          fill="#ffecec"
        />
        <g data-name="Group 104524" fill="#f2f4f7">
          <rect
            data-name="Rectangle 55399"
            width={46}
            height={4}
            rx={2}
            transform="translate(506 245.5)"
          />
          <rect
            data-name="Rectangle 55400"
            width={46}
            height={4}
            rx={2}
            transform="translate(506 254.5)"
          />
        </g>
        <path
          data-name="Path 97587"
          d="M594.928 251.464h-2.556v2.544h-.744v-2.544h-2.556v-.756h2.556v-2.556h.744v2.556h2.556Z"
          fill="#ced3dd"
        />
      </g>
    </g>
  </svg>
)

export default OtherResourcesIcon
