import * as React from "react"
const AmenityAccountCardSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={87} height={87} {...props}>
    <defs>
      <clipPath id="a">
        <rect
          width={87}
          height={87}
          fill="#fff"
          stroke="#707070"
          data-name="Rectangle 56399"
          opacity={0}
          rx={4}
        />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" data-name="Mask Group 93115">
      <g data-name="Group 110014">
        <g data-name="icons8-cash (2)">
          <path
            fill="#a5d6a7"
            d="m16.525 76.019 67.353-38.914 20.481 35.45-67.353 38.913Z"
            data-name="Path 100056"
          />
          <path
            fill="#388e3c"
            d="m36.258 114.265 70.897-40.962-22.53-38.994-70.897 40.962Zm46.871-74.363 18.433 31.904-63.808 36.866-18.433-31.904Z"
            data-name="Path 100057"
          />
          <path
            fill="#388e3c"
            d="M37.125 83.03a4.094 4.094 0 1 0 5.593 1.497 4.094 4.094 0 0 0-5.593-1.497Zm16.149-21.15a14.33 14.33 0 1 0 19.575 5.238 14.33 14.33 0 0 0-19.575-5.238Zm-29.268 9.817-7.481 4.322 4.322 7.481a6.095 6.095 0 0 0 4.343-.667 6.14 6.14 0 0 0 2.245-8.39 6.095 6.095 0 0 0-3.429-2.746Zm20.481 35.449a6.095 6.095 0 0 0-.667-4.343 6.116 6.116 0 0 0-11.137 1.183l4.323 7.481Zm59.872-34.592-4.323-7.482a6.116 6.116 0 0 0-3.159 11.804Z"
            data-name="Path 100058"
          />
          <path
            fill="#ff9800"
            d="M63.14 27.81a19.447 19.447 0 1 0 26.566 7.109 19.447 19.447 0 0 0-26.566-7.11Z"
            data-name="Path 100059"
          />
          <path
            fill="#ffcc80"
            d="M65.301 31.55a15.126 15.126 0 1 0 20.664 5.53 15.126 15.126 0 0 0-20.664-5.53Z"
            data-name="Path 100060"
          />
          <path
            fill="#ffc107"
            d="M43.427 20.286a22.517 22.517 0 1 0 30.762 8.232 22.517 22.517 0 0 0-30.762-8.232Z"
            data-name="Path 100061"
          />
          <path
            fill="#81c784"
            d="M78.166 64.046a3.993 3.993 0 0 1-.283-.63 19.318 19.318 0 0 1-7.571.49 14.303 14.303 0 0 1 2.536 3.212c.2.346.373.7.541 1.054a23.37 23.37 0 0 0 9.572-2.28 4.092 4.092 0 0 1-4.795-1.846Zm7.09-4.097a4.081 4.081 0 0 1-1.218 5.392c.2-.11.409-.196.607-.31a23.4 23.4 0 0 0 8.31-8.12l-2.417-4.184a19.305 19.305 0 0 1-5.438 7.01c.051.072.11.134.156.212Z"
            data-name="Path 100062"
          />
          <path
            fill="#2e7d32"
            d="M91.471 50.247a19.417 19.417 0 0 1-.932 2.48l2.417 4.183a23.826 23.826 0 0 0 1.139-2.122 23.74 23.74 0 0 0 .975-2.402l-2.952-5.11a19.572 19.572 0 0 1-.647 2.971Zm-6.215 9.702c-.045-.078-.105-.14-.155-.213a19.388 19.388 0 0 1-2.504 1.75 19.37 19.37 0 0 1-4.713 1.931 3.993 3.993 0 0 0 .282.629 4.092 4.092 0 0 0 4.796 1.85c.363-.172.718-.359 1.076-.552a4.081 4.081 0 0 0 1.218-5.395Z"
            data-name="Path 100063"
          />
          <path
            fill="#81c784"
            d="M48.954 65.726a14.26 14.26 0 0 1 3.817-3.52 22.672 22.672 0 0 1-5.95-1.326l-4.346 2.51a26.752 26.752 0 0 0 6.479 2.336Z"
            data-name="Path 100064"
          />
          <path
            fill="#2e7d32"
            d="M46.82 60.88a22.514 22.514 0 0 1-4.358-2.21l-3.914 2.261a26.506 26.506 0 0 0 3.927 2.46Zm23.492 3.026a19.662 19.662 0 0 1-3.1-.675 19.405 19.405 0 0 1-4.91-2.277 22.33 22.33 0 0 1-5.151 1.2 22.45 22.45 0 0 1-4.382.047 14.26 14.26 0 0 0-3.816 3.523 26.517 26.517 0 0 0 12.861-.333 23.722 23.722 0 0 0 11.58 2.781 14.924 14.924 0 0 0-.542-1.053 14.303 14.303 0 0 0-2.54-3.213Z"
            data-name="Path 100065"
          />
          <path
            fill="#ffe082"
            d="M45.475 23.83a18.423 18.423 0 1 0 25.169 6.736 18.423 18.423 0 0 0-25.17-6.735Z"
            data-name="Path 100066"
          />
          <path
            fill="#ffc107"
            d="m48.605 43.867-3.91-10.129 9.94-5.743 1.8 3.118-6.874 3.972 1.503 4.264a7.985 7.985 0 0 1 2.89-2.52 6.258 6.258 0 0 1 5.04-.855 6.025 6.025 0 0 1 3.756 3.143 6.979 6.979 0 0 1 .98 3.497 6.05 6.05 0 0 1-.974 3.351 8.202 8.202 0 0 1-2.901 2.655 8.865 8.865 0 0 1-3.592 1.17 6.567 6.567 0 0 1-3.412-.51 4.617 4.617 0 0 1-2.297-2.04l3.798-2.196a2.2 2.2 0 0 0 1.433 1.01 3.669 3.669 0 0 0 2.241-.543c1.411-.815 1.93-2.46.766-4.473-1.077-1.865-2.374-2.252-4.102-1.254a4.189 4.189 0 0 0-1.802 2.729Z"
            data-name="Path 100067"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default AmenityAccountCardSvg
