import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";

export const CartItemStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 15px"
  },
  cart: {
    width: "76px",
    height: "28px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid" + theme.palette.border.primary,
    borderRadius: "4px",
    cursor: "pointer",
    "& button": {
      minWidth: 0,
      padding: "0px 8px !important",
      "&:hover": {
        backgroundColor: "#ffffff !important"
      }
    },
  },

  cartText: {
    fontSize: "0.75rem !important",
    fontWeight: 700,
  },
  deleteIcon: {
    color: theme.palette.error.main,
    fontSize: "1.25rem"
  },
  inspectionName: {
    color: theme.typography.color.secondary,
    fontFamily: SemiBold,
    fontSize:"0.875rem",
    marginTop: "3px"
  },
  cartCard: {
    margin: "15px 0px"
  },
  divider: {
    color: theme.palette.border.secondary
  }
}))