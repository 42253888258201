import React from "react";
import { withNavBars } from "../../HOCs";
import PropertyFinder from "./propertyFinder";
import PropertyResult from "./propertyResult";
import { withRouter } from "react-router-dom";
import { Routes } from "../../router/routes";
class propertyFinder4 extends React.Component {

    render() {
        const { match } = this.props;
        return (
            <>
                {match.path === Routes.propertyFinder && <PropertyFinder {...this.props} />}
                {(match.path === Routes.propertyResult) && <PropertyResult {...this.props} />}
            </>
        );
    }
}
const props = {
    boxShadow: false
}

export default withRouter(withNavBars(propertyFinder4, props));
