import * as React from "react"

export const ConvertToBooking = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={303.531}
        height={232.638}
        {...props}
    >
        <defs>
            <filter
                id="a"
                x={38.941}
                y={0}
                width={158.806}
                height={177.048}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={12} />
                <feGaussianBlur stdDeviation={8} result="blur" />
                <feFlood floodOpacity={0.078} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
            <filter
                id="b"
                x={0}
                y={17.034}
                width={176.199}
                height={209.42}
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy={12} />
                <feGaussianBlur stdDeviation={8} result="blur-2" />
                <feFlood floodOpacity={0.078} />
                <feComposite operator="in" in2="blur-2" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g data-name="Group 98949">
            <path
                data-name="Rectangle 52466"
                fill="none"
                d="M24.531 12h279v220.638h-279z"
            />
            <g data-name="Group 98883">
                <path
                    data-name="Path 95128"
                    d="M293.511 155.031a84.126 84.126 0 0 1-11.7 15.846 106.935 106.935 0 0 1-28.034 21.108 413.54 413.54 0 0 1-50.635 23.028 342.04 342.04 0 0 1-23.691 7.883q-6.5 1.893-12.558 3.381a259.451 259.451 0 0 1-36.2 6.365H95.051c-.795-.117-1.2-.2-1.2-.2a90.589 90.589 0 0 1-26.47-4.92A75.757 75.757 0 0 1 47.2 217.169a60.61 60.61 0 0 1-15.328-16.191C11.1 168.629 60.487 113.628 124.16 74.935h.007q3.641-2.214 7.333-4.349a379.247 379.247 0 0 1 25.219-13.353 337.649 337.649 0 0 1 23.971-10.462 268.894 268.894 0 0 1 5.532-2.106h.021a.066.066 0 0 1 .035-.011h.02c.09-.045.2-.086.29-.124h.021q5.823-2.137 11.6-3.976c73.772-23.469 128.743 55.552 95.3 114.481"
                    fill="#f2efff"
                />
                <g data-name="Group 98873">
                    <g data-name="Group 98870">
                        <g transform="matrix(1.004 0 0 1.004 -.012 -.02)" filter="url(#a)">
                            <g data-name="Path 95180" fill="#fff">
                                <path d="M136.935 138.843 71.41 121.28c-4.404-1.18-7.027-5.724-5.847-10.129l24.27-90.548c1.182-4.405 5.725-7.028 10.13-5.847l65.525 17.563c4.404 1.181 7.027 5.725 5.847 10.13l-24.271 90.548c-1.18 4.404-5.724 7.027-10.129 5.846Z" />
                                <path
                                    d="M99.833 15.241c-4.137-1.109-8.405 1.355-9.514 5.492l-24.27 90.548c-1.11 4.137 1.354 8.405 5.491 9.514l65.525 17.563c4.137 1.11 8.405-1.354 9.514-5.491l24.27-90.549c1.11-4.137-1.354-8.405-5.491-9.514L99.833 15.241m.26-.97 65.525 17.563a8.772 8.772 0 0 1 6.202 10.744l-24.271 90.549a8.772 8.772 0 0 1-10.744 6.201L71.28 121.765a8.772 8.772 0 0 1-6.202-10.744l24.27-90.548a8.772 8.772 0 0 1 10.745-6.202Z"
                                    fill="#e4e8ee"
                                />
                            </g>
                        </g>
                        <path
                            data-name="Line 1119"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m92.094 71.15 16.575 4.445"
                        />
                        <path
                            data-name="Line 1122"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m89.577 80.538 16.575 4.445"
                        />
                        <path
                            data-name="Path 95143"
                            d="m87.06 89.926 16.575 4.444"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                        />
                        <path
                            data-name="Line 1128"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m81.947 108.995 16.576 4.444"
                        />
                        <g data-name="Group 98868" fill="none" strokeLinecap="round">
                            <path
                                data-name="Path 95197"
                                d="m97.364 51.495 33.003 8.848"
                                stroke="#e4e8ee"
                                strokeWidth={2.00024}
                            />
                            <path
                                data-name="Line 1118"
                                stroke="#f5f5f5"
                                strokeWidth={6.000719999999999}
                                d="m96.027 56.482 51.78 13.883"
                            />
                            <path
                                data-name="Line 1117"
                                stroke="#e4e8ee"
                                strokeWidth={8.00096}
                                d="m139.315 62.742 9.828 2.636"
                            />
                        </g>
                        <g data-name="Group 98887" fill="none" strokeLinecap="round">
                            <path
                                data-name="Line 1118"
                                stroke="#f5f5f5"
                                strokeWidth={6.000719999999999}
                                d="m96.03 36.826 51.779 13.883"
                            />
                            <path
                                data-name="Line 1117"
                                stroke="#e4e8ee"
                                strokeWidth={4.00048}
                                d="m107.006 36.828 40.45 12.258"
                            />
                        </g>
                        <path
                            data-name="Line 1120"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m134.045 82.399 9.828 2.635"
                        />
                        <path
                            data-name="Line 1124"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m131.528 91.786 9.828 2.635"
                        />
                        <path
                            data-name="Line 1127"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m129.011 101.174 9.828 2.635"
                        />
                        <path
                            data-name="Line 1130"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m123.899 120.243 9.828 2.635"
                        />
                        <path
                            data-name="Line 1121"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="M115.27 77.365 125.098 80"
                        />
                        <path
                            data-name="Line 1123"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m112.753 86.752 9.828 2.635"
                        />
                        <path
                            data-name="Line 1126"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m110.236 96.14 9.828 2.635"
                        />
                        <path
                            data-name="Line 1129"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                            d="m105.123 115.209 9.828 2.635"
                        />
                    </g>
                </g>
                <g data-name="Group 98872">
                    <g data-name="Group 98870">
                        <g transform="matrix(1.004 0 0 1.004 -.01 0)" filter="url(#b)">
                            <g data-name="Path 95179" fill="#fff">
                                <path d="M144.15 168.187 65.308 189.32a4.254 4.254 0 0 1-5.204-3.005L26.756 61.903c-1.18-4.405 1.442-8.948 5.847-10.13l74.958-20.09c4.405-1.182 8.948 1.441 10.129 5.846l32.307 120.529c1.18 4.404-1.443 8.948-5.847 10.129Z" />
                                <path
                                    d="M32.733 52.259c-4.137 1.109-6.6 5.377-5.492 9.514L60.59 186.185a3.751 3.751 0 0 0 4.59 2.65l78.84-21.133c4.138-1.109 6.602-5.377 5.493-9.514l-32.307-120.53c-1.109-4.136-5.377-6.6-9.514-5.491L32.733 52.259m-.26-.97 74.958-20.092a8.772 8.772 0 0 1 10.744 6.202l32.307 120.529a8.772 8.772 0 0 1-6.202 10.744l-78.842 21.133a4.751 4.751 0 0 1-5.82-3.36L26.272 62.033a8.772 8.772 0 0 1 6.202-10.744Z"
                                    fill="#e4e8ee"
                                />
                            </g>
                        </g>
                        <path
                            data-name="Path 95188"
                            d="m57.43 110.369 18.471-4.952"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={4.00048}
                        />
                        <path
                            data-name="Path 95198"
                            d="m46.567 70.4 60.472-16.213"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={5.0005999999999995}
                        />
                        <path
                            data-name="Path 95187"
                            d="m60.235 120.83 18.471-4.952"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={4.00048}
                        />
                        <path
                            data-name="Path 95143"
                            d="m63.04 131.292 18.47-4.953"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={4.00048}
                        />
                        <path
                            data-name="Path 95186"
                            d="m68.737 152.542 18.471-4.953"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={4.00048}
                        />
                        <g
                            data-name="Group 98868"
                            fill="none"
                            strokeLinecap="round"
                            strokeWidth={4}
                        >
                            <path
                                data-name="Path 95183"
                                d="m50.556 83.465 36.779-9.86"
                                stroke="#e4e8ee"
                                strokeWidth={4.00048}
                            />
                            <path
                                data-name="Path 95184"
                                d="m53.047 94.022 57.702-15.47"
                                stroke="#f5f5f5"
                                strokeWidth={4.00048}
                            />
                            <path
                                data-name="Path 95185"
                                d="m97.307 70.93 10.951-2.936"
                                stroke="#e4e8ee"
                                strokeWidth={4.00048}
                            />
                        </g>
                        <path
                            data-name="Path 95196"
                            d="m104.18 97.834 10.952-2.936"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={4.00048}
                        />
                        <path
                            data-name="Path 95195"
                            d="m106.986 108.296 10.951-2.936"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                        />
                        <path
                            data-name="Path 95194"
                            d="m109.79 118.757 10.952-2.936"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                        />
                        <path
                            data-name="Path 95193"
                            d="m115.488 140.007 10.951-2.936"
                            fill="none"
                            stroke="#ced3dd"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                        />
                        <path
                            data-name="Path 95192"
                            d="m83.257 103.444 10.952-2.936"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={4.00048}
                        />
                        <path
                            data-name="Path 95191"
                            d="m86.062 113.906 10.952-2.936"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={4.00048}
                        />
                        <path
                            data-name="Path 95190"
                            d="m88.867 124.367 10.951-2.936"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={4.00048}
                        />
                        <path
                            data-name="Path 95189"
                            d="m94.564 145.617 10.952-2.936"
                            fill="none"
                            stroke="#e4e8ee"
                            strokeLinecap="round"
                            strokeWidth={8.00096}
                        />
                    </g>
                </g>
                <path
                    data-name="Path 95129"
                    d="M281.811 103.511v67.37a106.93 106.93 0 0 1-28.034 21.1 413.458 413.458 0 0 1-50.636 23.027V103.511Z"
                    fill="#b9b0e1"
                />
                <path
                    data-name="Path 95130"
                    d="m228.883 44.521-33.182 68.314H94.894l52.556-68.064Z"
                    fill="#d2b082"
                />
                <path
                    data-name="Path 95131"
                    d="m151.429 44.77-52.556 68.065h3v119.8h28.825a259.46 259.46 0 0 0 36.2-6.365q6.058-1.494 12.558-3.382a342.575 342.575 0 0 0 23.691-7.883V105.706Z"
                    fill="#7f75ad"
                />
                <path
                    data-name="Path 95132"
                    d="M151.767 78.678h-.964a13.609 13.609 0 0 0-13.612 13.609v32.507h27.74V91.845a13.167 13.167 0 0 0-13.164-13.167"
                    fill="#f2efff"
                />
                <path
                    data-name="Path 95133"
                    d="M292.771 116.625h-91.5L147.183 44.52l30.447.007 59.48.009Z"
                    fill="#f7ecde"
                />
                <path
                    data-name="Path 95134"
                    d="M292.77 116.625h-16.767l-98.376-72.099 59.48.009Z"
                    fill="#e9dac1"
                />
                <path
                    data-name="Path 95135"
                    d="m140.441 131.915-23.624 48.637H45.048l37.417-48.459Z"
                    fill="#d2b082"
                />
                <path
                    data-name="Path 95136"
                    d="m84.616 132.093-37.417 48.459v36.618a75.748 75.748 0 0 0 20.182 10.351 90.594 90.594 0 0 0 26.47 4.92s.4.079 1.2.2h23.759v-52.089h.157Z"
                    fill="#7f75ad"
                />
                <path
                    data-name="Path 95137"
                    d="M179.452 180.547v42.341q-6.5 1.893-12.558 3.381a259.5 259.5 0 0 1-36.2 6.365h-11.883v-52.087Z"
                    fill="#b9b0e1"
                />
                <path
                    data-name="Path 95138"
                    d="M186.116 183.427h-65.145l-38.507-51.335h19.4l44.62.009Z"
                    fill="#f7ecde"
                />
                <path
                    data-name="Path 95139"
                    d="M97.551 191.349v41.289h-2.5c-.795-.117-1.2-.2-1.2-.2a90.589 90.589 0 0 1-26.47-4.92v-35.671a15.335 15.335 0 0 1 15.334-15.333 14.838 14.838 0 0 1 14.836 14.836"
                    fill="#f2efff"
                />
                <path
                    data-name="Path 95140"
                    d="M79.08 159.094a3.386 3.386 0 1 1 3.386 3.385 3.386 3.386 0 0 1-3.386-3.385"
                    fill="#f2efff"
                />
                <path
                    data-name="Path 95141"
                    d="M186.116 183.428h-12.364l-71.883-51.332 44.62.009Z"
                    fill="#e9dac1"
                />
                <path
                    data-name="Path 95142"
                    d="M293.511 155.031a84.121 84.121 0 0 1-11.7 15.846 106.934 106.934 0 0 1-28.034 21.108 413.533 413.533 0 0 1-50.635 23.021 342.04 342.04 0 0 1-23.691 7.883q-6.5 1.893-12.558 3.381c1.414-4.388 4.747-10.154 12.558-14.317 5.439-2.9 13.049-5.02 23.691-5.363 38.7-1.234 22.74-23.919 39.333-44.114 10-12.174 27.228-12.132 39.336-10.179a87.613 87.613 0 0 1 11.7 2.732"
                    fill="#5d8a88"
                />
            </g>
        </g>
    </svg>
)

