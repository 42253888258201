import * as React from "react"

const InspectionIcon = (props) => (
    <svg
        height={40}
        viewBox="0 0 64 64"
        width={40}
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 3"
        {...props}
    >
        <path d="M2 46h60v6H2z" fill="#4d5d7a" />
        <path d="M4 18h38v28H4z" fill="#e7e4dd" />
        <path
            d="M33.61 39.61a12.039 12.039 0 0 1-5 5l3.683 3.683a1 1 0 0 0 1.414 0l3.586-3.586a1 1 0 0 0 0-1.414z"
            fill="#243242"
        />
        <path d="M42 22h18v24H42z" fill="#d5cec2" />
        <path d="m60 18-7-8H38.5l3.5 4h2v4z" fill="#243242" />
        <path d="m42 14-7-8H11l-7 8" fill="#4d5d7a" />
        <path d="M42 10V6h6v4" fill="#cbc3b6" />
        <path d="M40 2h10v4H40z" fill="#a8b0bc" />
        <path d="M2 14h42v4H2z" fill="#8892a0" />
        <path d="M42 18h20v4H42z" fill="#758190" />
        <path
            d="M40 6h6.85a10.123 10.123 0 0 0 .418-2.866A10.11 10.11 0 0 0 47.2 2H40z"
            fill="#c1c8d1"
        />
        <path d="M46.85 6H42v4h2.572a10.121 10.121 0 0 0 2.278-4z" fill="#e7e4dd" />
        <path d="M46 28h10v10H46z" fill="#8892a0" />
        <path d="M55.999 32h-4v-4H50v4h-4v1.999h4v4h1.999v-4h4z" fill="#a8b0bc" />
        <circle cx={23} cy={34} fill="#758190" r={12} />
        <circle cx={23} cy={34} fill="#f7d881" r={8} />
        <path
            d="m28 34-1.586 1.586A4.828 4.828 0 0 1 23 37a4.828 4.828 0 0 1-3.414-1.414L18 34l1.586-1.586A4.828 4.828 0 0 1 23 31a4.828 4.828 0 0 1 3.414 1.414z"
            fill="#fff"
        />
        <path d="M23 36a2 2 0 1 1 2-2 2 2 0 0 1-2 2z" fill="#606060" />
        <path
            d="m34 48 13.379 13.379a2.12 2.12 0 0 0 1.5.621A2.122 2.122 0 0 0 51 59.879a2.12 2.12 0 0 0-.621-1.5L37 45"
            fill="#cbc3b6"
        />
        <path d="M62 23a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-1.545l-6.7-7.658A1 1 0 0 0 53 9h-4V7h1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H40a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1v2h-2.046l-3.2-3.659A1 1 0 0 0 35 5H11a1 1 0 0 0-.752.342L3.548 13H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1v26H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h35.586l9.086 9.086a3.122 3.122 0 1 0 4.415-4.415L46.416 53H62a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-1V23zm-1-2H43v-2h18zM41 3h8v2h-8zm2 4h4v2h-4zm9.546 4 5.25 6H45v-3a1 1 0 0 0-1-1h-1.546l-1.749-2zM11.455 7h23.092l5.25 6H6.205zM3 15h40v2H3zm2 4h36v26h-2.585l-.068-.068A1.972 1.972 0 0 0 38 42.586L34.813 39.4A13 13 0 1 0 16.1 45H5zm28 28.586-2.78-2.781a13.1 13.1 0 0 0 3.585-3.585L36.586 44zM22.961 45a11 11 0 1 1 .078 0zM3 51v-4h26.585l2 2a1.972 1.972 0 0 0 2.346.346L35.586 51zm47 8.88A1.122 1.122 0 0 1 48.88 61a1.111 1.111 0 0 1-.793-.328L35.416 48 37 46.416l12.672 12.671a1.11 1.11 0 0 1 .328.793zM44.416 51l-4-4H61v4zM59 45H43V23h16z" />
        <path d="M46 39h10a1 1 0 0 0 1-1V28a1 1 0 0 0-1-1H46a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1zm1-5h3v3h-3zm5 3v-3h3v3zm3-5h-3v-3h3zm-5-3v3h-3v-3zM23 25a9 9 0 1 0 9 9 9.011 9.011 0 0 0-9-9zm0 16a7 7 0 1 1 7-7 7.01 7.01 0 0 1-7 7z" />
        <path d="m28.709 33.3-1.587-1.587a5.827 5.827 0 0 0-8.243 0l-1.585 1.586a1 1 0 0 0 0 1.413l1.585 1.587a5.827 5.827 0 0 0 8.243 0l1.586-1.585a1 1 0 0 0 0-1.414zm-9.294.7.878-.879a3.74 3.74 0 0 1 2.159-1.036 1.989 1.989 0 0 0-.009 3.827 3.748 3.748 0 0 1-2.149-1.033zm6.293.878a3.74 3.74 0 0 1-2.147 1.033 1.988 1.988 0 0 0-.009-3.825 3.739 3.739 0 0 1 2.156 1.035l.879.879z" />
    </svg>
)

export default InspectionIcon
