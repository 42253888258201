export const Township = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 102663">
            <path data-name="Rectangle 19" fill="none" d="M0 0h30v30H0z" />
            <g fill="#c1c5cb" fillRule="evenodd">
                <path
                    data-name="Path 97609"
                    d="M16.692 20.25H1.808a.558.558 0 0 0 0 1.116h14.884a.558.558 0 0 0 0-1.116Z"
                    transform="translate(5.75 1.145)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97610"
                    d="M16.448 8.25H2.308a.558.558 0 0 0 0 1.116h14.14a.558.558 0 0 0 0-1.116Z"
                    transform="translate(5.622 4.215)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97611"
                    d="M12.366 3.552v-.744a.558.558 0 1 0-1.116 0v.744a.558.558 0 1 0 1.116 0Z"
                    transform="translate(3.192 5.75)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97612"
                    d="M4.983 8.25H3.308a.558.558 0 0 0-.558.558v8.93a.558.558 0 0 0 .558.558h1.675Zm2.79 10.05a.558.558 0 0 1-.558-.558v-4.469a.558.558 0 0 1 .558-.558h3.721a.558.558 0 0 1 .558.558v4.465a.558.558 0 0 1-.558.558h1.674V8.25H6.1V18.3Zm6.512-10.05V18.3h1.674a.558.558 0 0 0 .558-.558V8.808a.558.558 0 0 0-.558-.558Zm-5.954 5.581h2.6v3.349h-2.6Zm1.3-3.535a.744.744 0 1 1-.744.744.744.744 0 0 1 .747-.74Z"
                    transform="translate(5.366 4.215)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97613"
                    d="m5.613 6.61 1.86-1.86H5.781a.556.556 0 0 0-.394.164l-1.7 1.7Z"
                    transform="translate(5.126 5.11)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97614"
                    d="M7.689 6.227h6.416l-2.813-2.813a.557.557 0 0 0-.789 0Z"
                    transform="translate(4.103 5.494)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97615"
                    d="M17.085 6.61h1.925l-1.7-1.7a.556.556 0 0 0-.394-.164h-1.691Z"
                    transform="translate(2.175 5.11)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
        </g>
    </svg>
)