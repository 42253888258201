export const Path = ["requestNo", "agreementNo", "requestedBy", "requestedOn", "noOfUnits", "period", "renewal_period", "renewalType", "incrementPercentage", "status"]

export const Heading = (t) => [
    { title: t("Request Number"), field: "requestNo" },
    { title: t("Agreement Number"), field: "agreementNo" },
    { title: t("Requested By"), field: "requestedBy", },
    { title: t("Requested On"), field: "requestedOn", },
    { title: t("Units"), field: "noOfUnits", },
    { title: t("Period"), field: "period", },
    { title: t("Renewal Period"), field: "renewal_period", },
    { title: t("renewalType"), field: "renewalType", },
    { title: t("Increment Percentage"), field: "incrementPercentage", },
    { title: t("Status"), field: "status", },
]

export const Type = [
    { type: ["text"], name: "requestNo" },
    { type: ["text"], name: "agreementNo" },
    { type: ["text"], name: "requestedBy" },
    { type: ["date"], name: "requestedOn" },
    { type: ["text"], name: "noOfUnits" },
    { type: ["text"], name: "period" },
    { type: ["text"], name: "renewal_period" },
    { type: ["text"], name: "renewalType" },
    { type: ["text"], name: "incrementPercentage" },
    { type: ["object_status"], name: "status" },
]

export const StatusOptionList =(t) => [
    { label: t('Active'), value: true },
    { label: t('Inactive'), value: false },
]

export const UnitPath = ["logo", "unit_name", "unit_no", "property_name"]

export const UnitHeading = (t) => [
    { title: t("Image"), field: "logo" },
    { title: t("Name"), field: "unit_name" },
    { title: t("Unit ID"), field: "unit_no", },
    { title: t("Property Name"), field: "property_name", },
    { title: "", field: "icon" },
]

export const UnitType = [
    { type: ["avatarmanagement"], name: "logo" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "property_name" },
    { type: ["error_icon"], icon: "icon" },
]