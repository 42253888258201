import React from "react";
import { withNavBars } from "../../HOCs";
import ManufacturerMaster from "./manufacturerMaster";

class ManufacturerMasterParent extends React.Component {
  render() {
    return <ManufacturerMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ManufacturerMasterParent, props);
