import { Grid, InputAdornment, Typography, IconButton } from '@mui/material'
import React from 'react'
import DeleteIcon from '../../../assets/delete'
import { SelectBox, TextBox } from '../../../components'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions';
import { CreateEditContext } from '../create/createEditContext';
// import { RateConfigComponentStyle } from './style';

// const initialState = {
//     pricingFactor: "",
//     pricingFactorAmount: "",
//     pricingComponent: ""
// }
export const RateConfigComponent = ({ selectedValue = "a", data = {}, rowData = {}, index = {}, t }) => {
    const {
        selectedCompany,
        state,
        removeCommissionList,
        updateCommissionState,
        selectedCommissionEnum
    } = React.useContext(CreateEditContext)

    // const classes = RateConfigComponentStyle()

    // const [rateConfig, setRateConfig] = React.useState({
    //     ...initialState
    // })

    const pricingFactormanualResponse = (array) => {
        const details = array?.data?.map((i) => {
            return {
                label: i?.name,
                value: i?.id
            }
        })
        return details
    };

    // const updateLocalState = (key, value) => {
    //     setRateConfig({ ...rateConfig, [key]: value })
    // }

    // const handleAdd = () => {
    //     const result = {
    //         enumtype: data?.value,
    //         pricingFactor: rateConfig?.pricingFactor ?? undefined,
    //         pricingFactorAmount: rateConfig?.pricingFactorAmount ?? undefined,
    //         pricingComponent: rateConfig?.pricingComponent ?? undefined
    //     }
    //     updateState("rateConfiguration", result)
    // }

    // React.useEffect(() => {
    //     const commissionData = state?.viewData?.commission
    //     const constructedData = {
    //         enumtype: commissionData?.commission_rate_type,
    //         pricingFactor: commissionData?.commission_config?.name ? { label: commissionData?.commission_config?.name, value: commissionData?.commission_config?.id } : {},
    //         pricingFactorAmount: commissionData?.value ?? "",
    //         pricingComponent: commissionData?.pricing_component?.name ? { label: commissionData?.pricing_component?.name, value: commissionData?.pricing_component?.id } : {}
    //     }
    //     // if (Object.keys(state?.viewData)?.length > 0) {
    //     //     setRateConfig(
    //     //         {
    //     //             ...rateConfig,
    //     //             ...constructedData
    //     //         }
    //     //     )
    //     //     updateState("rateConfiguration", constructedData)
    //     // }
    //     // eslint-disable-next-line
    // }, [state])

    // const clearRowData = () => {
    //     setRateConfig({ ...initialState })
    // }
    return (
        <>
            <Grid container spacing={3} sx={{ width: 800 }}>
                <Grid item md={selectedValue === 'a' ? 4 : 3}>
                    <SelectBox
                        placeholder={t("Add Pricing Factor")}
                        onChange={(value) => updateCommissionState('pricingFactor', value, rowData)}
                        value={rowData?.pricingFactor}
                        color="white"
                        // loading={loading === "currency"}
                        loadOptions={(search, array, handleLoading) =>
                            loadOptionsApis(
                                "contract/commission_rate_config",
                                {
                                    contract_type:state?.contractType?.id,
                                    commission_rate_type: selectedCommissionEnum
                                },
                                search,
                                array,
                                handleLoading,
                                "data",
                                {},
                                pricingFactormanualResponse
                            )
                        }
                        isPaginate
                        isReadOnly={state?.type === "view"}
                        debounceTimeout={800}
                        isRequired
                        isError={rowData?.error?.pricingFactor?.length > 0}
                        errorMessage={rowData?.error?.pricingFactor}
                    />
                </Grid>
                
                <Grid item md={selectedValue === 'a' ? 4 : 3}>
                    <TextBox label={null}
                        placeholder='0'
                        type='number'
                        endAdornment={<InputAdornment position="end">
                            <Typography sx={{ opacity: "0.5" }}>{selectedValue === 'a' ? selectedCompany?.currency_symbol : "%"}</Typography>
                        </InputAdornment>}
                        onChange={(e) => updateCommissionState('pricingFactorAmount', e?.target?.value, rowData)}
                        value={rowData?.pricingFactorAmount}
                        isReadonly={state?.type === "view"}
                        isRequired
                        isError={rowData?.error?.pricingFactorAmount?.length > 0}
                        errorMessage={rowData?.error?.pricingFactorAmount}
                    />
                </Grid>
                {
                    selectedValue === 'b' &&
                    <Grid item md={3}>
                        <SelectBox
                            placeholder={t("Select Component")}
                            onChange={(value) => updateCommissionState('pricingComponent', value, rowData)}
                            value={rowData?.pricingComponent}
                            color="white"
                            loadOptions={(search, array, handleLoading) =>
                                loadOptionsApis(
                                    "pricing_component/get_component",
                                    {
                                        company_id: state?.selectedCompany?.value
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    pricingFactormanualResponse
                                )
                            }
                            isPaginate
                            isReadOnly={state?.type === "view"}
                            debounceTimeout={800}
                            isRequired
                            isError={rowData?.error?.pricingComponent?.length > 0}
                            errorMessage={rowData?.error?.pricingComponent}
                        />
                    </Grid>
                }
                <Grid item md={selectedValue === 'a' ? 4 : 3} sx={{ display: "flex", alignItems: "center" }}>
                    {
                        (state?.type !== "view" && index !== 0) &&
                        // <Box sx={{cursor:"pointer"}} onClick={() => removeCommissionList(rowData)}>
                        //     <DeleteIcon />
                        // </Box>
                        <IconButton sx={{ cursor: "pointer" }} onClick={() => removeCommissionList(rowData)}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </Grid>
            </Grid>

            {/* <Box mt={2}>
                <Button variant="outlined" className={classes.btn} onClick={() => {
                    handleAdd()
                }}
                    disabled={state?.type === "view"}>
                    {selectedValue === 'a' ? 'Add Pricing Factor' : 'Add Percentage Factor'}
                </Button>
            </Box> */}
        </>
    )
}