import * as React from "react"
const AgreementLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} {...props}>
    <g data-name="Group 115457">
      <path
        fill="#fff8e7"
        d="M4.75 0h28.5A4.75 4.75 0 0 1 38 4.75v28.5A4.75 4.75 0 0 1 33.25 38H4.75A4.75 4.75 0 0 1 0 33.25V4.75A4.75 4.75 0 0 1 4.75 0Z"
        data-name="Path 100578"
      />
      <g data-name="icons8-agreement (4)" transform="translate(1.951 3)">
        <path
          fill="#ffc429"
          d="M25.1 6.073v18.244a1.076 1.076 0 0 1-1.073 1.073H10.073A1.076 1.076 0 0 1 9 24.317V6.073A1.073 1.073 0 0 1 10.073 5h13.952A1.073 1.073 0 0 1 25.1 6.073Z"
          data-name="Path 100873"
        />
        <path
          fill="#a1c56a"
          d="M21.873 22.305h-3.208v4.535a.16.16 0 0 0 .243.137l1.361-.782 1.362.782a.16.16 0 0 0 .243-.137Z"
          data-name="Path 100874"
        />
        <rect
          width={6.439}
          height={1.073}
          fill="#aa821b"
          data-name="Rectangle 58000"
          rx={0.537}
          transform="translate(11.683 8.756)"
        />
        <rect
          width={8.585}
          height={1.073}
          fill="#aa821b"
          data-name="Rectangle 58001"
          rx={0.537}
          transform="translate(11.683 11.439)"
        />
        <rect
          width={10.732}
          height={1.073}
          fill="#aa821b"
          data-name="Rectangle 58002"
          rx={0.537}
          transform="translate(11.683 14.122)"
        />
        <rect
          width={3.22}
          height={1.073}
          fill="#aa821b"
          data-name="Rectangle 58003"
          rx={0.537}
          transform="translate(19.195 8.756)"
        />
        <rect
          width={4.293}
          height={1.073}
          fill="#aa821b"
          data-name="Rectangle 58004"
          rx={0.537}
          transform="translate(11.683 16.805)"
        />
        <path
          fill="#72a820"
          d="m22.808 20.844-.188-.113a.359.359 0 0 1-.158-.217v-.005a.358.358 0 0 1 .028-.269l.106-.191a.3.3 0 0 0-.254-.44h-.221a.358.358 0 0 1-.246-.109.357.357 0 0 1-.109-.246v-.221a.3.3 0 0 0-.44-.254l-.191.106a.358.358 0 0 1-.269.028h-.005a.359.359 0 0 1-.217-.158l-.113-.188a.3.3 0 0 0-.509 0l-.112.187a.363.363 0 0 1-.218.16.359.359 0 0 1-.272-.028l-.191-.106a.3.3 0 0 0-.44.254v.221a.359.359 0 0 1-.109.246.358.358 0 0 1-.246.109h-.221a.3.3 0 0 0-.254.44l.106.191a.358.358 0 0 1 .028.269v.005a.359.359 0 0 1-.158.217l-.188.113a.3.3 0 0 0 0 .509l.188.113a.359.359 0 0 1 .158.217v.005a.358.358 0 0 1-.028.269l-.106.191a.3.3 0 0 0 .254.44h.221a.358.358 0 0 1 .246.109.358.358 0 0 1 .109.246v.221a.3.3 0 0 0 .44.254l.191-.106a.358.358 0 0 1 .269-.028h-.004a.359.359 0 0 1 .217.158l.113.188a.3.3 0 0 0 .509 0l.113-.188a.359.359 0 0 1 .217-.158h.005a.358.358 0 0 1 .269.028l.191.106a.3.3 0 0 0 .44-.254v-.221a.358.358 0 0 1 .109-.246.358.358 0 0 1 .246-.109h.221a.3.3 0 0 0 .254-.44l-.106-.191a.358.358 0 0 1-.028-.269v-.005a.359.359 0 0 1 .158-.217l.188-.113a.3.3 0 0 0 0-.508Z"
          data-name="Path 100877"
        />
      </g>
    </g>
  </svg>
)
export default AgreementLogo
