import styled from "@mui/material/styles/styled";
import { Typography } from "@mui/material";
import { Bold } from "../../utils";

export const initial = {
  Category: "",
  subCategory: "",
  Unit: "",
  Title: "",
  Description: "",
  status: true,
  Property: "",
  Agreement: "",
  member: "",
  error: {
    Category: "",
    subCategory: "",
    Unit: "",
    Title: "",
    Description: "",
    status: "",
    Property: "",
    Agreement: "",
    member: "",
  },
};

export const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize:"0.75rem",
  fontFamily: Bold,
  color: "#4E5A6B",
  marginBottom: theme.spacing(1),
}));
