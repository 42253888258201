import { ActivePropertyIcon } from "../../assets";
import { ResourcesIcon } from "../../assets/resources";
import { SecurityViolationIcon, VisitorIcon } from "../../assets/securitymanager";
import ParkingSlotIcon from "../../assets/securitymanager/parkingSlot";
import { startAndEndOfMonth, startAndEndOfWeek, timeZoneConverter } from "../../utils";

export const month_object = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December",
}

export const scaleLine = {
  y: {
    ticks: {
      // Include a dollar sign in the ticks
      callback: function (value, index, ticks) {
        return value;
      },
    },
    stacked: true,
  },
  x: {
    stacked: true,
  },
  xAxes: [
    {
      gridLines: { display: false },
    },
  ],
  yAxes: [
    {
      gridLines: {
        drawBorder: false,
      },
      ticks: {
        min: 30,
      },
    },
  ],
};

export const stratureData = (data, t = () => false) => {
  let visitorDetails = [
    {
      count: data?.visitorEntries ?? 0,
      image: <VisitorIcon />,
      sub: t("Visitor Entries"),
    },
    {
      count: data?.workerEntries ?? 0,
      image: <ResourcesIcon />,
      sub: t("Worker Entries"),
    },
    {
      count: data?.vendorEntries ?? 0,
      image: "../images/vendor.svg",
      image_type: true,
      sub: t("Vendor Entries"),
    },
    {
      count: data?.serviceProviderEntries ?? 0,
      image: "../images/serviceProvider.svg",
      image_type: true,
      sub: t("Service Provider Entries"),
    },
    {
      count: data?.totalSecurityStaff ?? 0,
      image: "../images/security.svg",
      image_type: true,
      sub: t("Total Security Staff"),
    },
    {
      count: data?.securityStaffOnDuty ?? 0,
      image: "../images/securityOnDuty.svg",
      image_type: true,
      sub: t("Security Staff On Duty Today"),
    },
    {
      count: data?.securityViolationRecords ?? 0,
      image: <SecurityViolationIcon />,
      sub: t("Security Violation Records"),
    },
    {
      count: data?.yetToCheckout ?? 0,
      image: "../images/checkout.svg",
      image_type: true,
      isView: true,
      sub: t("Yet To Checkout"),
    },
  ];
  let parkingDetails = [
    {
      count: data?.reservedSlots ?? 0,
      image: "../images/reservedSlotIcon.svg",
      image_type: true,
      sub: t("Reserved Slots"),
    },
    {
      count: data?.visitorSlots ?? 0,
      image: "../images/visitorSlot.svg",
      image_type: true,
      sub: t("Visitor Slots"),
    },
    {
      count: data?.parkingLevels ?? 0,
      image: <ParkingSlotIcon />,
      sub: t("Parking Levels"),
    },
    {
      count: data?.parkingAreas ?? 0,
      image: <ParkingSlotIcon color="#896DB3" />,
      sub: t("Parking Areas"),
    },
    {
      count: data?.totalParkingSlot ?? 0,
      image: <ParkingSlotIcon color="#6DAFB3" />,
      sub: t("Total Parking Slots"),
    },
    {
      count: data?.fourWheelers ?? 0,
      image: "../images/fourWheeler.svg",
      image_type: true,
      sub: t("Four Wheelers"),
    },
    {
      count: data?.otherVehicles ?? 0,
      image: "../images/otherVehicles.svg",
      image_type: true,
      sub: t("Other Vehicles"),
    },
    {
      count: data?.registeredVehicles ?? 0,
      image: "../images/registeredVehicle.svg",
      image_type: true,
      sub: t("Registered Vehicles"),
    },
  ];
  let visitorCard = [
    {
      count: data?.visitorWokerEntiries ?? 0,
      image: <ActivePropertyIcon />,
      sub: t("Visitors & Workers Entries"),
    },
    {
      count: data?.domesticHelperEntieirs ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: t("Domestic Help Entries"),
      image_type: true,
    },
    {
      count: data?.vendorAndServiceEntieirs ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: t("Vendors & Service Providers Entries"),
      image_type: true,
    },
  ];
  let agreementCard = [
    {
      count: data?.executedAgreements ?? 0,
      image: <ActivePropertyIcon />,
      sub: t("New Agreement"),
    },
    {
      count: data?.declinedAgreements ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: t("Declined Agreement"),
      image_type: true,
    },
    {
      count: data?.underOnboardingAgreements ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: t("Under Onboarding"),
      image_type: true,
    },
  ];
  const Requests = [
    {
      count: data?.openVisitorRequest ?? 0,
      image: <ActivePropertyIcon />,
      sub: t("Open Visitors Requests"),
    },
    {
      count: `${data?.openDomesticHelperRequest ?? 0}`,
      image: <ActivePropertyIcon />,
      sub: t("Open Domestic Help Requests"),
    },
    {
      count: data?.openCollectionRequest ?? 0,
      image: <ActivePropertyIcon />,
      sub: t("Open Collection Requests"),
    },
  ];
  let visitors = [
    {
      count: data?.openVisitorParking ?? 0,
      image: <ActivePropertyIcon />,
      sub: t("Open Visitor Parking Requests"),
    },
    {
      count: data?.vacantVisitorParking ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: t("Vacant Visitor Parking Slots"),
      image_type: true,
    },
    {
      count: data?.reservedVisitorParking ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: t("Reserved Parking Slots"),
      image_type: true,
    },
  ];
  let vicle = [
    {
      count: data?.vehicleStats?.fourWheelers ?? 0,
      image: <ActivePropertyIcon />,
      sub: t("Registered Four Wheelers"),
    },
    {
      count: data?.vehicleStats?.twoWheelers ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: t("Registered Two Wheelers"),
      image_type: true,
    },
    {
      count: data?.vehicleStats?.biCycles ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: t("Registered Bicycles"),
      image_type: true,
    },
  ];
  let security = [
    {
      count: data?.violatedRequests ?? 0,
      image: <ActivePropertyIcon />,
      sub: t("Security Violation Recorded"),
    },
    {
      count: data?.secrityStaffs ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: t("Security Staff"),
      image_type: true,
    },
    {
      count: data?.securityStaffAppAccess ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: t("Security Staff With App Access"),
      image_type: true,
    },
  ];
  let visitor_requests = [
    {
      name: "Checked In Entires" ?? "",
      count: data?.checkInAndOut?.checkedInEntires ?? 0,
      fill: `#F3E137`,
    },
    {
      name: "Checked Out Entires" ?? "",
      count: data?.checkInAndOut?.checkedOutEntires ?? 0,
      fill: `#5AC782`,
    },
  ];
  let workersRequest = data?.workersRequest?.map((x) => {
    return {
      name: x?.month ?? "",
      value: x?.count ?? 0,
    };
  });
  let collectionRequest = data?.collectionRequest?.data?.map((x) => {
    return {
      name: x?.delivery_status ?? 0,
      count: x?.count ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let collectionRequestTotal = data?.collectionRequest?.total ?? 0;
  let visitor_requestsTotal = data?.checkInAndOut?.total ?? 0;
  let domesticHelperRequest = data?.domesticHelperRequest?.data?.map((x) => {
    return {
      name: x?.request_status ?? 0,
      count: x?.count ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let domesticRequestTotal = data?.domesticHelperRequest?.total ?? 0;
  let parkingSlotRequest = data?.parkingSlotRequest?.data?.map((x) => {
    return {
      name: x?.parking_type ?? 0,
      count: x?.count ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let parkingSlotRequestTotal = data?.parkingSlotRequest?.total ?? 0;
  let parkingSlotOccupancy = data?.parkingSlotOccupancy?.map((x) => {
    return {
      name: x?.parking_status ?? 0,
      count: JSON.parse(x?.count) ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let entriesByGates = data?.entriesByGates?.map((x) => {
    return {
      name: x?.name ?? 0,
      count: JSON.parse(x?.count) ?? 0,
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let gatesAndParkingArea = data?.gatesAndParkingArea?.map((x) => {
    return {
      name: x?.label,
      value: x?.value ?? 0,
    };
  });
  let checkInEntiries = data?.checkInEntiries ?? [];
  // let checkInAndOutEntiries = data?.checkInAndOutEntiries ?? [];
  let deliveryCollectionSummary = data?.deliveryCollectionSummary ?? [];
  let peopleVisited = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [{
      label: 'People Visited',
      data: [10, 20, 25, 40, 30, 10],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    }, {
      label: 'People not visited',
      data: [10, 20, 25, 40, 30, 10],
      backgroundColor: 'rgba(53, 0, 235, 0.5)',
    }]
  }
  let checkInAndOutEntiries = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Check-in',
        data: [10, 20, 25, 40, 30, 10],
        backgroundColor: '#896DB3',
        barPercentage: 0.5,
        barThickness: 20,
        maxBarThickness: 30,
      },
      {
        label: 'Check-out',
        data: [5, 15, 5, 50, 38, 18],
        backgroundColor: '#6DAFB3',
        barPercentage: 0.5,
        barThickness: 20,
        maxBarThickness: 30,
      }
    ]
  }
  let visitorRequest = [
    {
      name: "Pending",
      count: 50,
      fill: "#B2766C"
    },
    {
      name: "Approved",
      count: 80,
      fill: "#5AC782"
    },
  ]
  const final = {
    visitorCard,
    agreementCard,
    Requests,
    visitors,
    vicle,
    security,
    visitor_requests,
    workersRequest,
    collectionRequest,
    collectionRequestTotal,
    visitor_requestsTotal,
    domesticHelperRequest,
    domesticRequestTotal,
    parkingSlotRequest,
    parkingSlotRequestTotal,
    parkingSlotOccupancy,
    entriesByGates,
    gatesAndParkingArea,
    checkInEntiries,
    checkInAndOutEntiries,
    deliveryCollectionSummary,
    visitorDetails,
    peopleVisited,
    visitorRequest,
    parkingDetails
  };
  return final;
};
export const getMonth = [
  {
    label: "Today",
    value: {
      startDate: new Date(),
      endDate: new Date(),
    },
  },
  {
    label: "YesterDay",
    value: {
      startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
    },
  },
  {
    label: "This Week",
    value: startAndEndOfWeek(new Date()),
  },
  {
    label: "This Month",
    value: startAndEndOfMonth(),
  },
];

export const scale = {
  x: {
    stacked: true,
    grid: {
      // drawBorder: false,
      display: false,
    }
  },
  y: {
    stacked: true,
    grid: {
      // drawBorder: false,
      display: false,
    }
  },
};

export const constructBasicStatsData = (data, t = () => false) => {
  const entry_data = [
    {
      count: data?.visitors_count ?? 0,
      image: <VisitorIcon />,
      sub: t("Visitor Entries"),
    },
    {
      count: data?.workers_count ?? 0,
      image: <ResourcesIcon />,
      sub: t("Worker Entries"),
    },
    {
      count: data?.vendor_count ?? 0,
      image: "../images/vendor.svg",
      image_type: true,
      sub: t("Vendor Entries"),
    },
    {
      count: data?.service_provider_count ?? 0,
      image: "../images/serviceProvider.svg",
      image_type: true,
      sub: t("Service Provider Entries"),
    },
    {
      count: 0,
      image: "../images/security.svg",
      image_type: true,
      sub: t("Total Security Staff"),
    },
    {
      count: 0,
      image: "../images/securityOnDuty.svg",
      image_type: true,
      sub: t("Security Staff On Duty Today"),
    },
    {
      count: data?.security_violation_recorded_count ?? 0,
      image: <SecurityViolationIcon />,
      sub: t("Security Violation Records"),
    },
    {
      count: data?.yet_to_checkout_count ?? 0,
      image: "../images/checkout.svg",
      image_type: true,
      isView: true,
      sub: t("Yet To Checkout"),
    },
  ];

  const parking_data = [
    {
      count: data?.total_reserved_slot_count ?? 0,
      image: "../images/reservedSlotIcon.svg",
      image_type: true,
      sub: t("Reserved Slots"),
    },
    {
      count: data?.total_visitor_slot_count ?? 0,
      image: "../images/visitorSlot.svg",
      image_type: true,
      sub: t("Visitor Slots"),
    },
    {
      count: data?.parking_levels ?? 0,
      image: <ParkingSlotIcon />,
      sub: t("Parking Levels"),
    },
    {
      count: data?.parking_area_count ?? 0,
      image: <ParkingSlotIcon color="#896DB3" />,
      sub: t("Parking Areas"),
    },
    {
      count: data?.parking_slot_count ?? 0,
      image: <ParkingSlotIcon color="#6DAFB3" />,
      sub: t("Total Parking Slots"),
    },
    {
      count: data?.four_wheeler_count ?? 0,
      image: "../images/fourWheeler.svg",
      image_type: true,
      sub: t("Four Wheelers"),
    },
    {
      count: data?.other_vehicles ?? 0,
      image: "../images/otherVehicles.svg",
      image_type: true,
      sub: t("Other Vehicles"),
    },
    {
      count: data?.registered_count ?? 0,
      image: "../images/registeredVehicle.svg",
      image_type: true,
      sub: t("Registered Vehicles"),
    },
  ]

  const temp_reserved_slots_data = [
    { type: t("Parking Master"), count: data?.parking_master_reserved_count, },
    { type: t("Available"), count: data?.reserved_available_slot_count, },
    { type: t("Not Available"), count: data?.reserved_not_available_slot_count, },
    { type: t("Total"), count: data?.total_reserved_slot_count, },
  ]

  let reserved_slots_data = temp_reserved_slots_data?.map?.((lead, i) => {
    return {
      name: lead?.type ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
      count: lead?.count ? parseInt(lead?.count) : 0,
    }
  })
  let reservedSlotsTotal = data?.total_reserved_slot_count
  const temp_visitor_slots_data = [
    { type: t("Parking Master"), count: data?.parking_master_visitor_count, },
    { type: t("Available"), count: data?.visitor_available_slot_count, },
    { type: t("Not Available"), count: data?.visitor_not_available_slot_count, },
    { type: t("Total"), count: data?.total_visitor_slot_count, },
  ]

  let visitor_slots_data = temp_visitor_slots_data?.map?.((lead, i) => {
    return {
      name: lead?.type ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
      count: lead?.count ? parseInt(lead?.count) : 0,
    }
  })
  let visitorSlotsTotal = data?.total_visitor_slot_count
  const temp_visitorRequest_data = [
    { type: t("Pending"), count: data?.visitor_pending_count, },
    { type: t("Approved"), count: data?.visitor_approved_count, },
    { type: t("Cancelled"), count: data?.visitor_cancelled_count, },
    { type: t("Rejected"), count: data?.visitor_rejected_count, },
  ]

  let VisitorRequest_data = temp_visitorRequest_data?.map?.((val, i) => {
    return {
      name: val?.type ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
      count: val?.count ? parseInt(val?.count) : 0,
    }
  })
  let visitorRequestTotal = data?.visitor_request_total
  const temp_workerRequest_data = [
    { type: t("Pending"), count: data?.worker_pending_count, },
    { type: t("Approved"), count: data?.worker_approved_count, },
    { type: t("Cancelled"), count: data?.worker_cancelled_count, },
    { type: t("Rejected"), count: data?.worker_rejected_count, },
  ]

  let WorkerRequest_data = temp_workerRequest_data?.map?.((val, i) => {
    return {
      name: val?.type ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
      count: val?.count ? parseInt(val?.count) : 0,
    }
  })
  let workerRequestTotal = data?.worker_request_total
  const temp_parkingRequest_data = [
    { type: t("Pending"), count: data?.parking_pending_count, },
    { type: t("Approved"), count: data?.parking_approved_count, },
    { type: t("Cancelled"), count: data?.parking_cancelled_count, },
    { type: t("Rejected"), count: data?.parking_rejected_count, },
  ]

  let ParkingRequest_data = temp_parkingRequest_data?.map?.((val, i) => {
    return {
      name: val?.type ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
      count: val?.count ? parseInt(val?.count) : 0,
    }
  })
  let parkingRequestTotal = data?.parking_request_total
  const temp_collection_request_data = [
    {
      type: t("Cancelled"), count: data?.delivery_cancelled_count,
    },
    { type: t("Collected"), count: data?.delivery_collected_count, },
    { type: t("Yet To Receive"), count: data?.delivery_yet_to_receive_count, },
    { type: t("Received At Gate"), count: data?.delivery_received_at_gate_count, },
  ]

  let collectionRequest_data = temp_collection_request_data?.map?.((val, i) => {
    return {
      name: val?.type ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
      count: val?.count ? parseInt(val?.count) : 0,
    }
  })
  let collectionRequestTotal = data?.delivery_request_total
  const result = {
    entry_data,
    parking_data,
    reserved_slots_data,
    visitor_slots_data,
    VisitorRequest_data,
    visitorRequestTotal,
    workerRequestTotal,
    WorkerRequest_data,
    ParkingRequest_data,
    parkingRequestTotal,
    reservedSlotsTotal,
    visitorSlotsTotal,
    collectionRequest_data,
    collectionRequestTotal
  }

  return result;
};

export const constructPeopleVisitedData = (data, t = () => false) => {
  let labels = []

  let datasets = [
    { label: t("Total Records"), key: "totalRecords", data: [], },
    { label: t("Security Request Visitors"), key: "securityRequestVisitors", data: [], },
    { label: t("Swift In Out"), key: "swiftInOut", data: [], },
  ]

  for (let i = 0; i < data?.length; i++) {

    labels = [...labels, month_object[data?.[i]?.month?.split("-")?.[1]]]

    datasets = [
      {
        label: t("Total Records"), backgroundColor: "#739c66", borderWidth: 1, barThickness: 5,
        data: [
          ...datasets?.[0]?.data,
          data?.[i]?.[datasets?.[0]?.key] ? parseInt(data?.[i]?.[datasets?.[0]?.key]) : 0,
        ],
      },
      {
        label: t("Security Request Visitors"), backgroundColor: "#2936ed", borderWidth: 1, barThickness: 5,
        data: [
          ...datasets?.[1]?.data,
          data?.[i]?.[datasets?.[1]?.key] ? parseInt(data?.[i]?.[datasets?.[1]?.key]) : 0,
        ],
      },
      {
        label: t("Swift In Out"), backgroundColor: "#f91f85", borderWidth: 1, barThickness: 5,
        data: [
          ...datasets?.[2]?.data,
          data?.[i]?.[datasets?.[2]?.key] ? parseInt(data?.[i]?.[datasets?.[2]?.key]) : 0,
        ],
      }
    ]
  }

  return { labels, datasets };
}

export const constructEntriesData = (data, t = () => false) => {
  let temp_labels = []

  let check_in = data?.checkin ?? []
  let check_out = data?.checkout ?? []

  let datasets = [
    { label: t("Check In"), backgroundColor: "#739c66", borderWidth: 1, barThickness: 5, data: [], },
    { label: t("Check Out"), backgroundColor: "#2936ed", borderWidth: 1, barThickness: 5, data: [], },
  ]

  for (let i = 0; i < check_in?.length; i++) {
    temp_labels = [...temp_labels, month_object[check_in?.[i]?.month?.split("-")?.[1]]]

    datasets[0] = {
      ...datasets[0],
      data: [
        ...datasets?.[0]?.data,
        check_in?.[i]?.total_records ? parseInt(check_in?.[i]?.total_records) : 0,
      ],
    }
  }

  for (let i = 0; i < check_out?.length; i++) {
    temp_labels = [...temp_labels, month_object[check_out?.[i]?.month?.split("-")?.[1]]]

    datasets[1] = {
      ...datasets[1],
      data: [
        ...datasets?.[1]?.data,
        check_out?.[i]?.total_records ? parseInt(check_out?.[i]?.total_records) : 0,
      ],
    }
  }

  let labels = [...new Set(temp_labels)];

  return { labels, datasets };
}

export const constructOnDutyData = (data) => {
  let list = data?.map?.((security) => {
    return {
      location: "-",
      name: security?.first_name ?? "-",
      check_in: "-",
      check_out: "-",
      contact_number: security?.mobile_no ?
        ((security?.mobile_no_country_code && (security?.mobile_no_country_code + " ")) + security?.mobile_no) : "-",
    }
  })

  return list
}

export const constructEntriesByGatesData = (data) => {
  let list = data?.map?.((entry) => {
    return {
      pass_type: entry?.pass_type ?? "-",
      pass_id: entry?.request_no ?? "-",
      gate: entry?.gate_name ?? "-",
      visitor_name: entry?.visitor_name ?? "-",
      visitor_contact: entry?.mobile_no ?
        ((entry?.mobile_country_code && (entry?.mobile_country_code + " ")) + entry?.mobile_no) : "-",
      tagged_unit: entry?.unit_name ?? "-",
      check_in: entry?.check_in_time ? timeZoneConverter(entry?.check_in_time, "DD MMM YY ,hh:mm") : "-",
      check_in_by: entry?.check_in_by ?? "-",
      check_out: entry?.check_out_time ? timeZoneConverter(entry?.check_out_time, "DD MMM YY ,hh:mm") : "-",
      check_out_by: entry?.check_out_by ?? "-",
    }
  })

  return list
}