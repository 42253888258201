export const WaitingForApprovalPath = [
  "id",
  "helper_id",
  "helper_name",
  "Profession",
  "category",
  "reference_id",
  "requested_by",
  "unit_no",
  "domestic_help_number",
  "raised_on"
];

export const WaitingForApprovalHeading = (t) => [
  { title: t("Sno"), field: "id", type: "numeric" },
  { title: t("Domestic Help ID"), field: "helper_id" },
  { title: t("Domestic Helper Name"), field: "helper_name" },
  { title: t("Profession"), field: "Profession" },
  { title: t("Category"), field: "category" },
  { title: t("Reference ID"), field: "reference_id" },
  { title: t("Requested By"), field: "requested_by" },
  { title: t("Unit No"), field: "unit_no" },
  { title: t("Domestic Help Number"), field: "domestic_help_number" },
  { title: t("Raised On"), field: "raised_on" },
  { title: "", field: "icon" },
];
export const WaitingForApprovalType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "helper_id" },
  { type: ["text"], name: "helper_name" },
  { type: ["text"], name: "Profession" },
  { type: ["text"], name: "category" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "requested_by" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "domestic_help_number" },
  { type: ["text"], name: "raised_on" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const ApprovedPath = [
  "id",
  "helper_id",
  "helper_name",
  "Profession",
  "category",
  "reference_id",
  "requested_by",
  "unit_no",
  "domestic_help_number",
  "raised_on"
];

export const ApprovedHeading = (t) => [
  { title: t("Sno"), field: "id", type: "numeric" },
  { title: t("Domestic Help ID"), field: "helper_id" },
  { title: t("Domestic Helper Name"), field: "helper_name" },
  { title: t("Profession"), field: "Profession" },
  { title: t("Category"), field: "category" },
  { title: t("Reference ID"), field: "reference_id" },
  { title: t("Requested By"), field: "requested_by" },
  { title: t("Unit No"), field: "unit_no" },
  { title: t("Domestic Help Number"), field: "domestic_help_number" },
  { title: t("Raised On"), field: "raised_on" },
  { title: "", field: "icon" },
];
export const ApprovedType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "helper_id" },
  { type: ["text"], name: "helper_name" },
  { type: ["text"], name: "Profession" },
  { type: ["text"], name: "category" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "requested_by" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "domestic_help_number" },
  { type: ["text"], name: "raised_on" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const DeclinedPath = [
  "id",
  "helper_id",
  "helper_name",
  "category",
  "reference_id",
  "requested_by",
  "unit_no",
  "domestic_help_number",
  "raised_on"
];

export const DeclinedHeading = (t) => [
  { title: t("Sno"), field: "id", type: "numeric" },
  { title: t("Domestic Help ID"), field: "helper_id" },
  { title: t("Domestic Helper Name"), field: "helper_name" },
  { title: t("Profession"), field: "Profession" },
  { title: t("Category"), field: "category" },
  { title: t("Reference ID"), field: "reference_id" },
  { title: t("Requested By"), field: "requested_by" },
  { title: t("Unit No"), field: "unit_no" },
  { title: t("Domestic Help Number"), field: "domestic_help_number" },
  { title: t("Raised On"), field: "raised_on" },
  { title: "", field: "icon" },
];
export const DeclinedType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "helper_id" },
  { type: ["text"], name: "helper_name" },
  { type: ["text"], name: "Profession" },
  { type: ["text"], name: "category" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "requested_by" },
  { type: ["text"], name: "unit_no" },
  { type: ["text"], name: "domestic_help_number" },
  { type: ["text"], name: "raised_on" },
  { type: ["icon"], icon: "icon", is_active: "is_active" },
];

export const EntriesPath = [
  "id",
  "helper_id",
  "helper_name",
  "category",
  "reference_id",
  "requested_by",
  "unit_no",
  "domestic_help_number",
  "checked_in_gate",
  "checked_in_by",
  "checked_in",
  "checked_out_by",
  "checked_out_gate",
  "checked_out",
];

export const EntriesHeading = (t) => [
  { title: t("Sno"), field: "id", type: "numeric" },
  { title: t("Domestic Help ID"), field: "helper_id" },
  { title: t("Domestic Helper Name"), field: "helper_name" },
  // { title: t("Profession"), field: "Profession" },
  { title: t("Category"), field: "category" },
  { title: t("Reference ID"), field: "reference_id" },
  { title: t("Requested By"), field: "requested_by" },
  { title: t("Unit"), field: "unit_no" },
  { title: t("Domestic Help Number"), field: "domestic_help_number" },
  { title: t("Checked In Gate"), field: "checked_in_gate" },
  { title: t("Checked In By"), field: "check_in_by" },
  { title: t("Checked In Date and Time"), field: "checked_in" },
  { title: t("Checked Out Gate"), field: "checked_out_gate" },
  { title: t("Checked Out By"), field: "checked_out_by" },
  { title: t("Checked Out Date and Time"), field: "checked_out" },
];
export const EntriesType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "helper_id" },
  { type: ["text"], name: "helper_name" },
  { type: ["text"], name: "category" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "requested_by" },
  { type: ["textLink"], name: "unit_no" },
  { type: ["text"], name: "domestic_help_number" },
  { type: ["text"], name: "checked_in_gate" },
  { type: ["text"], name: "checked_in_by" },
  { type: ["text"], name: "checked_in" },
  { type: ["text"], name: "checked_out_gate" },
  { type: ["text"], name: "checked_out_by" },
  { type: ["text"], name: "checked_out" },
];
