import { Box, Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { Subheader } from "../../components";
import { useStyles } from "./style";
import { MarketPlaceCard } from "./components";
import { NetworkCall } from "../../networkcall";
import { AlertContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { NewLoader } from "../../components/newLoader";
import { withNamespaces } from "react-i18next";
import { Routes } from "../../router/routes";


const MarketPlace = ({
    t = () => false
}) => {
    const classes = useStyles()
    const alert = React.useContext(AlertContext)
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory()

    //get initial data
    const getAddonCategoryData = () => {
        setLoading(true)
        NetworkCall(
            `${config.api_url}/subscription/get_all_addon_category`,
            NetWorkCallMethods.post,
            {},
            null,
            true,
            false
        )
            .then((response) => {
                setData(response?.data?.data?.data)
                setLoading(false)

            })
            .catch((error) => {
                console.log(error)

                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong please try again"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                });
                setLoading(false)

            });

    }

    //Initial load
    React.useEffect(() => {
        getAddonCategoryData()
        // eslint-disable-next-line
    }, [])



    const handleClick = (data) => {
        history.push({
            pathname: Routes.marketPlaceView,
            state: { id: data?.id }
        })
    }


    return (
        <Box>
            <Subheader title={t("Market Place")} goBack={() => history.goBack()} />
            {loading ? <NewLoader minusHeight="150px" /> :
                <Box className={classes.box} m={2}>
                    <Grid container spacing={2} p={2} className={classes.gatWayRoot}>
                        {data?.map((item) => {
                            return (
                                <Grid item xs={12} md={3}>

                                    <MarketPlaceCard t={t} data={item} master={true} onClick={handleClick} />
                                </Grid>
                            )
                        })}

                    </Grid>

                </Box>
            }
        </Box >
    )
}
export default withNamespaces("marketingPortalConfigurartion")(MarketPlace)
