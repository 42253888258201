export const UnitLevel = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
        <g data-name="Group 102949" transform="translate(-11563 -17227)">
            <circle
                data-name="Ellipse 129452"
                cx={24}
                cy={24}
                r={24}
                transform="translate(11563 17227)"
                fill={props?.background_fill ?? "#f5f0f9"}
            />
            <g data-name="Group 102946">
                <path
                    data-name="Rectangle 54908"
                    fill="none"
                    d="M11575 17239h24v24h-24z"
                />
                <path
                    d="M11584.778 17242.998a1.905 1.905 0 0 0-1.968 1.9v6.476h1.333a2.861 2.861 0 0 1 2.857 2.863v3.429a1.32 1.32 0 0 1-.134.571h4.134a.572.572 0 0 0 .571-.571v-11.429a1.907 1.907 0 0 0-1.409-1.839l-4.952-1.334a1.875 1.875 0 0 0-.432-.066Zm.7 3.426h.762a.381.381 0 0 1 .381.381v.762a.381.381 0 0 1-.381.381h-.764a.381.381 0 0 1-.376-.378v-.762a.381.381 0 0 1 .376-.381Zm2.667 0h.755a.381.381 0 0 1 .381.381v.762a.381.381 0 0 1-.381.381h-.762a.381.381 0 0 1-.381-.381v-.759a.381.381 0 0 1 .386-.381Zm-2.669 2.674h.762a.381.381 0 0 1 .381.381v.762a.381.381 0 0 1-.381.381h-.762a.381.381 0 0 1-.381-.381v-.762a.381.381 0 0 1 .381-.381Zm2.667 0h.757a.381.381 0 0 1 .381.381v.762a.381.381 0 0 1-.381.381h-.762a.381.381 0 0 1-.381-.381v-.762a.381.381 0 0 1 .386-.381Zm4.19 0v2.667h.767a.381.381 0 0 1 .381.381v.762a.381.381 0 0 1-.381.381h-.762v1.143h.762a.381.381 0 0 1 .381.381v.762a.381.381 0 0 1-.381.381h-.762v2.286h2.1a.572.572 0 0 0 .562-.576v-6.477a2.1 2.1 0 0 0-2.1-2.1Zm-4.19 2.666h.757a.381.381 0 0 1 .381.381v.753a.381.381 0 0 1-.381.381h-.762a.381.381 0 0 1-.381-.381v-.762a.381.381 0 0 1 .386-.376Zm-7.043.378a2.1 2.1 0 0 0-2.1 2.1v3.429a.572.572 0 0 0 .571.571h6.1a.572.572 0 0 0 .571-.571v-3.434a2.1 2.1 0 0 0-2.1-2.1Zm7.048 2.285h.752a.374.374 0 0 1 .3.153h.008v.012a.37.37 0 0 1 .076.216v.762a.371.371 0 0 1-.076.217v.011h-.008a.374.374 0 0 1-.3.152h-.762a.381.381 0 0 1-.381-.381v-.761a.381.381 0 0 1 .386-.381Zm-7.238 0h.762a.381.381 0 0 1 .381.381v.762a.381.381 0 0 1-.381.381h-.772a.381.381 0 0 1-.381-.381v-.762a.381.381 0 0 1 .381-.381Zm2.667 0h.762a.381.381 0 0 1 .381.381v.762a.381.381 0 0 1-.381.381h-.768a.381.381 0 0 1-.381-.381v-.762a.381.381 0 0 1 .381-.381Z"
                    fill={props?.icon_fill ?? "#8f59c5"}
                />
            </g>
        </g>
    </svg>
)
