import { Box, Typography, IconButton } from "@mui/material";
import React from "react";
import NoDataFound from "../../../assets/noData";
import { TableWithPagination, ChartComponent } from "../../../components";
import { dashboardStyles } from "./style";

export const GraphComponentRevenue = (props) => {
  const classes = dashboardStyles(props);
  return (
    <div className={classes.cardGraph}>
      <Box className={classes.flexBoxWithSpace}>
        <Box>
          <Typography className={classes.titleFont}>{props?.title}</Typography>
        </Box>

        <IconButton onClick={() => props?.onZoomClick(props?.title)}>
          {props?.isZoom && (
            <img
              src="/images/propertdashboard/enlare.png"
              alt="zoom"
              onClick={() => props?.onZoomClick(props?.title)}
            />
          )}
        </IconButton>
      </Box>
      {props?.isTabel ? (
        <TableWithPagination
          heading={props?.heading}
          rows={props?.tableData ?? []}
          path={props?.path}
          // showpagination={true}
          showpdfbtn={false}
          showexcelbtn={false}
          showSearch={false}
          tableType="no-side"
          count="2"
          dataType={props?.dataType}
          height={props?.height ?? `calc(100vh - 355px)`}
          view={true}
          edit={true}
          delete={true} />
      ) : (
        <div style={{ margin: props?.margin }}>
          {props?.data?.length > 0 ? (
            <ChartComponent
              type={props?.type}
              height={props?.height}
              LineKey={props?.LineKey}
              barKey={props?.barKey}
              data={props?.data}
              XAxisKey={props?.XAxisKey}
              yAxisKey={props?.yAxisKey}
              isHorzontal={props?.isHorzontal}
            />
          ) : (
            <Box mt={2} alignItems="center" justifyContent={"center"} display="flex"><NoDataFound/></Box>
          )}
        </div>
      )}
    </div>
  );
};
