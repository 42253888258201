import * as React from "react"

const ChooseResourceIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      data-name="Path 97564"
      d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0Z"
      fill="#f1f7ff"
    />
    <path
      data-name="Path 97603"
      d="M10.6 18.7V7.9a.9.9 0 0 1 .9-.9h10.8a.9.9 0 0 1 .9.9v14.4a.9.9 0 0 1-.9.9l-7.65-.45Z"
      fill="#3778ce"
    />
    <path
      data-name="Path 97604"
      d="M22.3 23.2H10.6V7.9h10.035A1.661 1.661 0 0 1 22.3 9.565Z"
      opacity={0.05}
    />
    <path
      data-name="Path 97605"
      d="M21.85 23.2H12.9a2.3 2.3 0 0 1-2.3-2.3V8.35h9.99a1.288 1.288 0 0 1 1.26 1.26Z"
      opacity={0.07}
    />
    <path
      data-name="Path 97606"
      d="M8.8 24.1V9.7a.9.9 0 0 1 .9-.9h10.8a.9.9 0 0 1 .9.9v14.4a.9.9 0 0 1-.9.9H9.7a.9.9 0 0 1-.9-.9Z"
      fill="#78b1fe"
    />
    <path
      data-name="Path 97607"
      d="M14.65 13.3h.9a.425.425 0 0 1 .45.45v6.3a.425.425 0 0 1-.45.45h-.9a.425.425 0 0 1-.45-.45v-6.3a.425.425 0 0 1 .45-.45Z"
      fill="#fff"
    />
    <path
      data-name="Path 97608"
      d="M18.7 16.45v.9a.425.425 0 0 1-.45.45h-6.3a.425.425 0 0 1-.45-.45v-.9a.425.425 0 0 1 .45-.45h6.3a.425.425 0 0 1 .45.45Z"
      fill="#fff"
    />
  </svg>
)

export default ChooseResourceIcon
