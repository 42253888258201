import React from "react";

export const Camera = (props) => {
    const width = props.width ? props.width : "12.632";
    const height = props.height ? props.height : "12";
    const color = props.color ? props.color : "#091b29";
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 12.632 12"
        >
            <path id="icons8-camera_1_" data-name="icons8-camera (1)" d="M9.2,5a1.422,1.422,0,0,0-1.227.7l-.694,1.19H6.053A2.06,2.06,0,0,0,4,8.947v6A2.06,2.06,0,0,0,6.053,17h8.526a2.06,2.06,0,0,0,2.053-2.053v-6a2.06,2.06,0,0,0-2.053-2.053H13.351L12.657,5.7A1.422,1.422,0,0,0,11.43,5Zm0,.947H11.43a.472.472,0,0,1,.409.235l.831,1.425a.474.474,0,0,0,.409.235h1.5a1.1,1.1,0,0,1,1.105,1.105v6a1.1,1.1,0,0,1-1.105,1.105H6.053a1.1,1.1,0,0,1-1.105-1.105v-6A1.1,1.1,0,0,1,6.053,7.842h1.5a.474.474,0,0,0,.409-.235l.831-1.425A.472.472,0,0,1,9.2,5.947Zm1.114,2.842a2.842,2.842,0,1,0,2.842,2.842A2.849,2.849,0,0,0,10.316,8.789Zm0,.947a1.895,1.895,0,1,1-1.895,1.895A1.888,1.888,0,0,1,10.316,9.737Z" transform="translate(-4 -5)"
                fill={color}
            />
        </svg>
    );
};
