import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {Regular, timeZoneConverter} from '../../utils'
import { Bold } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",

    backgroundColor: theme.palette.background.paper,
  },
  Cardcontent: {
    [theme.breakpoints.up("sm")]: {
      padding: "19px",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "19px",
    },
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize:"1rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
  },
  progress: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
  },
  yetTorecived: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
  },
  RescivedAtGate: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#FF9340",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
  },
  checked: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    background: "#5078E11E",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
  },
  cancel: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#CED3DD",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize:"0.75rem",
    color: theme.palette.background.primary,
    fontFamily: Regular,
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    padding: "5px 11px 2px",
  },
  date: {
    color: "#4E5A6B",
    fontSize:"0.75rem",
    fontFamily: Regular,
  },
  approved: {
    fontSize:"0.75rem",
    color: "#FFFFFF",
    fontFamily: Bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: theme.palette.borderRadius,
  },
}));

export const DeleviryCard = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <div
        className={classes.card}
        onClick={
          () => false
          // history.push(Routes.passDetails + "?passId=" + props.data.id)
        }
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex">
            <Box className={classes.img}>
              <img
                src="/images/deleveryToken.svg"
                alt=""
                style={{ objectFit: "contain", marginTop: "4px" }}
              />
            </Box>
            <Box flexGrow={1} marginInlineStart="10px">
              <Box alignItems="center" display="flex">
                <Box>
                  <Typography variant="h6" className={classes.title} noWrap>
                    {props?.data?.name?.length > 15 ? (
                      <>{props?.data?.name?.slice(0, 15)}...</>
                    ) : (
                      props?.data?.name
                    )}
                  </Typography>
                </Box>

                <Box marginInlineStart="10px">
                  {props?.data?.delivery_status === "Yet To Receive" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.yetTorecived}
                    >
                      {props?.data?.delivery_status}
                    </Typography>
                  )}
                  {props?.data?.status === "Collected" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.approved}
                    >
                      {props?.data?.status}
                    </Typography>
                  )}
                  {props.data.delivery_status === "Received At Gate" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.RescivedAtGate}
                    >
                      {props?.data?.delivery_status}
                    </Typography>
                  )}
                  {props.data.delivery_status === "Delete" && (
                    <Typography variant="subtitle2" className={classes.delete}>
                      {props?.data?.delivery_status}
                    </Typography>
                  )}
                  {props.data.delivery_status === "Yet To Check Out" && (
                    <Typography variant="subtitle2" className={classes.checked}>
                      {props?.data?.delivery_status}
                    </Typography>
                  )}
                  {props?.data?.status === "Declined" && (
                    <Typography variant="subtitle2" className={classes.delete}>
                      {props?.data?.status}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box alignItems="center" display="flex" marginTop="4px">
                <Box>
                  <Typography variant="subtitle2" className={classes.sub}>
                    {props?.data?.requested_by},&nbsp;
                    <span variant="subtitle2" className={classes.sub} noWrap>
                      {props?.data?.unit?.[0]?.unit_no}
                    </span>
                  </Typography>
                </Box>
                {/* <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                  >
                    {props.data.count}
                  </Typography>
                </Box> */}
                <Box className={classes.dot} />
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.date}
                    noWrap
                  >
                    &#x202a;{timeZoneConverter(props?.data?.request_from, "DD MMM YY")
                    }&#x202c;
                  </Typography>
                </Box>
                <Box className={classes.dot} />
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.date}
                    noWrap
                  >
                    {props?.data?.request_no}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
