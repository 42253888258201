import React from "react";
import { withNavBars } from "../../HOCs";
import Import from "./import";

class ImportParent extends React.Component {
  render() {
    return <Import {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ImportParent, props);
