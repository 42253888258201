import React from "react";
import { FormGenerator } from "../../components";
import { Box, Button, Grid, Stack } from "@mui/material";
import { Status } from "./utils/managementRoleMaster";
import { ManagementRoleMasterStyle } from "./style";

export const AddRoleForm = ({
    onCloseModal = () => false,
    data = {},
    updateState = () => false,
    isDisableBtn = false,
    handleSubmitForm = () => false,
    t = () => false
}) => {
    const classes = ManagementRoleMasterStyle()

    const formComittee = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("Role Name"),
            value: data?.role_name,
            placeholder: t("Enter Role Name"),
            onChange: (event) => updateState("role_name", event?.target?.value),
            error: data?.error?.role_name,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "text",
            label: t("Description"),
            value: data?.description,
            placeholder: t("Enter Description"),
            onChange: (event) => updateState("description", event?.target?.value),
            multiline: true,
            error: data?.error?.description,
            isRequired: true,
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "toggle",
            label: t("Status"),
            value: data?.is_active,
            placeholder: t("Select Status"),
            onChange: (value) => updateState("is_active", value),
            error: data?.error?.is_active,
            isRequired: true,
            options: Status
        },
    ]
    return (
        <>
            <Box p={2}>
                <FormGenerator t={t} components={formComittee} />
                <Grid container>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                        <Stack direction="row" justifyContent={"end"} spacing={1}>
                            <Button variant="outlined" onClick={() => onCloseModal()} className={classes.cancelbtn}>{t("Cancel")}</Button>
                            <Button variant="contained" onClick={() => handleSubmitForm()} disabled={isDisableBtn}>{data?.type === "create" ? t("Submit") : t("Save")}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}