import React from "react";
import { viewCardStyle } from "./style";
import { Box, Typography, Grid } from "@mui/material";

export const ViewCardMuti = (props) => {
  const classes = viewCardStyle(props);
  const { data, subTitle } = props;
  return (
    <Box className={classes.root1}>
      {data?.map((data) => {
        return (
          <Box className={classes.main}>
            <Box>
              {/* title */}
              <Typography className={classes.title}>{data?.name}</Typography>
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  {/* subGataogry */}
                  <Typography className={classes.subCatagory}>
                    {data?.city ?? ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.textAlign}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div textAlign="center">
                    <Typography className={classes.total}>
                      {data?.no_of_units ?? 0}
                    </Typography>
                    <Typography className={classes.units}>
                      {subTitle}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
