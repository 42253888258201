import { Avatar, Dialog, Grid, Typography } from "@mui/material";
import React from "react";
import { AlertContext } from "../../../contexts";
import { CreateShortListContext } from "../../../contexts/shortlistLedContext";
import { AlertProps, stringAvatar, timeZoneConverter } from "../../../utils";
import { UnitSingleDetails } from "../../propertyFinder3/component/singleUnitDetail";
import UnitCard from "../../propertyFinder3/component/unitCard";
import { LeadPreviewStyles as useStyles } from "./styles";

export const LeadPreview = () => {
  const classes = useStyles();
  const { dataNew, setDataNew, t } = React.useContext(CreateShortListContext);
  const alert = React.useContext(AlertContext);
  const [selectedUnits, setSelectedunit] = React.useState("")
  const [open, setOpen] = React.useState(false)
  //on open units
  const openDetails = (value) => {
    setOpen(true)
    setSelectedunit(value)
  }
  //on delete units
  const onDelete = (id) => {
    if (dataNew?.units?.length > 1) {
      const deleted = dataNew?.units.filter((ids, index) => index !== id)
      setDataNew({ ...dataNew, units: deleted });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.info,
        msg: t("Cannot Delete"),
      });
    }
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" className={classes.card}>
        <Grid item xs={12} md={6} lg={1.5} className={classes.rightBorder}>
          <div className={classes.imgdiv}>


            <Avatar
              src={dataNew?.image_url?.length > 0 ? dataNew?.image_url : null}
              className={classes.image}
              {...stringAvatar(dataNew?.name, 14, "4px")}
            />

            <div className={classes.prority}>
              <center>
                <span className={classes.proritydata}>
                  {dataNew.priority}

                </span>
              </center>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={10.5} p={1}>
          <Typography className={classes.title}>
            OPPORTUNITY DETAILS
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>{t("Contact Name")}</Typography>
              <Typography className={classes.sub}> {dataNew.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>{t("Mobile")}</Typography>
              <Typography className={classes.sub}>
                {dataNew.mobile?.mobile_code ?? ""}&nbsp; {dataNew.mobile?.mobile ?? "-"}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Alternative Mobile</Typography>
              <Typography className={classes.sub}>
                {dataNew.alter_mobile?.mobile_code??""}&nbsp; {dataNew.alter_mobile?.mobile??"-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} marginTop="8px">
              <Typography className={classes.heading}>Telephone</Typography>
              <Typography className={classes.sub}>
                {dataNew.alter_mobile?.mobile_code??""}&nbsp; {dataNew.telephone?.mobile??"-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} marginTop="8px">
              <Typography className={classes.heading}>Fax</Typography>
              <Typography className={classes.sub}>
                {dataNew.alter_mobile?.mobile_code??""}&nbsp; {dataNew.fax?.mobile??"-"}
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Typography className={classes.heading}>Email Id</Typography>
              <Typography className={classes.sub}>
                {dataNew?.email_id}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {dataNew?.revenue_type?.value !== "Sale" &&
        <Grid container className={classes.card} marginTop="12px" p={1}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{dataNew?.revenue_type?.value} {t("Duration")}</Typography>
              <Typography className={classes.sub}>{dataNew?.duration?.value} {dataNew?.duration?.select}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{dataNew?.revenue_type?.value} {t("Start And End Date")}</Typography>
              <Typography className={classes.sub}>{timeZoneConverter(dataNew?.lease_start_date)} to {timeZoneConverter(dataNew?.lease_end_date)}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Source Type")}</Typography>
              <Typography className={classes.sub}>{dataNew?.source?.label ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Priority")}</Typography>
              <Typography className={classes.sub}>{dataNew?.priority ?? "-"}</Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Billing Cycle Method")}</Typography>
              <Typography className={classes.sub}>{dataNew?.bill_cycle_method ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Billing Cycle Date")}</Typography>
              <Typography className={classes.sub}>{dataNew?.bill_cycle_date ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Grace Period")}</Typography>
              <Typography className={classes.sub}>{dataNew?.grace_peroid ?? "-"} Days</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Apply Grace Period")}</Typography>
              <Typography className={classes.sub}>{dataNew?.apply_grace_peroid ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Payment Period")}</Typography>
              <Typography className={classes.sub}>{dataNew?.payment_period?.value ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Prefered Payment Options")}</Typography>
              <Typography className={classes.sub}>{dataNew?.payment_option?.label ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Renewal Based On")}</Typography>
              <Typography className={classes.sub}>{dataNew?.renewal_based_on?.label ?? "-"}</Typography>
            </Grid>
            {
              dataNew?.renewal_based_on?.label === "Increment %" &&
              <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Increment Percentage")}</Typography>
              <Typography className={classes.sub}>{`${dataNew?.esclation_percentage??0}%`}</Typography>
            </Grid>
            }
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Auto-renewal")}</Typography>
              <Typography className={classes.sub}>{dataNew?.auto_renewal ? "True" : "False"}</Typography>
            </Grid>
          </Grid>
        </Grid >
      }
      {dataNew?.revenue_type?.value === "Sale" &&
        <Grid container className={classes.card} marginTop="12px" p={1}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Earliest Occupation Date")}</Typography>
              <Typography className={classes.sub}>{timeZoneConverter(dataNew?.earlist_occupation_date)}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Contract Start Date")}</Typography>
              <Typography className={classes.sub}>{timeZoneConverter(dataNew?.contract_start_date)}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Source Type")}</Typography>
              <Typography className={classes.sub}>{dataNew?.source?.label ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Priority")}</Typography>
              <Typography className={classes.sub}>{dataNew?.priority ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Payment Period")}</Typography>
              <Typography className={classes.sub}>{dataNew?.payment_period?.value ?? "-"}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>{t("Payment Options")}</Typography>
              <Typography className={classes.sub}>{dataNew?.payment_option?.value ?? "-"}</Typography>
            </Grid>
            {dataNew?.payment_period?.value === "Milestone Based" &&
              <Grid item xs={3}>

                <Typography className={classes.heading}>{t("Delivery Mailstone Template")}</Typography>
                <Typography className={classes.sub}>{dataNew?.mileStoneName}</Typography>

              </Grid>
            }
          </Grid>
        </Grid>
      }
      <br />
      <Grid container spacing={2}>

        {dataNew?.units?.map((val, index) => {
          return (
            <Grid item lg={2.5} md={6} sm={12}>
              <UnitCard
                currency={val?.currencyByID?.symbol}
                data={{ ...val, unit_type: val?.unit_type?.name }}
                onDelete={() => onDelete(index)}
                onClick={openDetails} delete={true} />
            </Grid>

          )
        })}

      </Grid>
      <Dialog maxWidth="xs" className={classes.dialog} fullWidth open={open} onClose={() => setOpen(false)}>
        <UnitSingleDetails
          startDate={dataNew?.lease_start_date}
          endDate={dataNew?.lease_end_date}
          grace={dataNew?.grace_peroid}
          shortlist={true}
          t={t}
          selectedUnits={selectedUnits} onClose={() => setOpen(false)}  />
      </Dialog>



    </div >
  );
};