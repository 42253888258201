export const tabOptions= (t) =>[
    {
        value:"1",
        label:t("Unit Type"),
        type:"Unit"
    },
    {
        value:"2",
        label:t("Item Category"),
        type:"General"
    },
    {
        value:"3",
        label:t("Item/Asset"),
        type:"Item Group"
    },
]