import React from "react";
import { SelectAnnounsment } from "../../../components/selectAnnounsment";
import { Grid, Box } from "@mui/material";
import { useStyles } from "../style";
import { useWindowDimensions } from "../../../utils";

export const PropertyAndrRoles = ({ t = () => false, data = {},fetchMoreDataProperty=()=>false }) => {
    const size = useWindowDimensions()
    // classes
    const classes = useStyles({ size });
    return (
        <div>
            <Box className={classes.roleRoot}>
                <Grid container spacing={"14px"}>
                    <Grid item sm={12} md={8}>
                        <SelectAnnounsment
                            title={t("Selected Properties")}
                            // selectAll={t("all_Property")}
                            // selectAllValue={listingDetails?.propertyselectAll ?? false}
                            count="01"
                            data={data?.property ?? []}
                            // chooseFuncion={chooseProperty}
                            // selectAllFunction={(e) => ChooseAll("property")}
                            // searchFunction={searchFunctionRole}
                            // searchValue={searchText?.propertySearch}
                            fetchMoreData={fetchMoreDataProperty}
                            type={"property"}
                            isView={true}
                            div_height={
                                size?.width > 899
                                    ? (size?.height - 220)
                                    : size?.height > 850
                                        ? ((size?.height - 220) / 2)
                                        : 280
                            }
                            infinite_scroll_height={
                                size?.width > 899
                                    ? (size?.height - 300)
                                    : size?.height > 850
                                        ? ((size?.height - 350) / 2)
                                        : 120
                            }
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <SelectAnnounsment
                            title={t("Selected Roles")}
                            // subtitle={t("Select_Property_Sub")}
                            // selectAll={t("all_Property")}
                            // selectAllValue={listingDetails?.propertyselectAll ?? false}
                            count="02"
                            data={data?.roles ?? []}
                            isView={true}
                            // chooseFuncion={chooseProperty}
                            // selectAllFunction={(e) => ChooseAll("property")}
                            // searchFunction={searchFunctionRole}
                            // searchValue={searchText?.propertySearch}
                            // fetchMoreData={fetchMoreDataRoles}
                            type={"role"}
                            div_height={
                                size?.width > 899
                                    ? (size?.height - 220)
                                    : size?.height > 850
                                        ? ((size?.height - 220) / 2)
                                        : 280
                            }
                            infinite_scroll_height={
                                size?.width > 899
                                    ? (size?.height - 300)
                                    : size?.height > 850
                                        ? ((size?.height - 350) / 2)
                                        : 120
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};
