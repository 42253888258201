import React from "react";
import { withNavBars } from "../../HOCs";
import  ReservationDetails  from "./reservationDetails";

class ReservationDetailsParent extends React.Component {
    render() {
        return <ReservationDetails />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ReservationDetailsParent,props);
