export const BackButtonRound = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
        <g data-name="Group 117068" transform="translate(-259 -87)">
            <circle
                cx={16}
                cy={16}
                r={16}
                fill="#e4e8ee"
                data-name="Ellipse 40058"
                transform="rotate(-90 189 -70)"
            />
            <path
                fill="#091b29"
                stroke="#091b29"
                strokeWidth={0.75}
                d="M271.431 102.596a.522.522 0 0 0 0 .738l4.956 4.956a.522.522 0 0 0 .738-.738l-4.588-4.587 4.588-4.588a.522.522 0 0 0-.738-.738Z"
            />
        </g>
    </svg>
)