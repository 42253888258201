export const Path = ["activity", "activityCategory", "activitySubCategory", "status"]

export const Heading = (t) => [
    { title: t("Activity"), field: "activity" },
    { title: t("Activity Category"), field: "activityCategory" },
    { title: t("Activity Sub Category"), field: "activitySubCategory" },
    { title: t("Status"), field: "status", },
    { title: "", field: "icon" },
]

export const Type = [
    { type: ["textObject"], name: "activity" },
    { type: ["textObject"], name: "activityCategory" },
    { type: ["text"], name: "activitySubCategory" },
    { type: ["status"], name: "status" },
    { type: ["more"], icon: "icon" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]