import { makeStyles } from "@mui/styles";
import { SemiBold, remCalc } from "../../../utils";

export const ContractDetailsCardStyle = makeStyles((theme) => ({
    card: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "8px"
    },
    title: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.primary
    },
    subtitle: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    }
}))