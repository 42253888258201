import React from "react";
import { withNavBars } from "../../HOCs";
import SubLedgerMaster from "./subLedgerMaster";

class SubLedgerMasterParent extends React.Component {
  render() {
    return <SubLedgerMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(SubLedgerMasterParent, props);
