import * as React from "react"

export const General = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16.65} {...props}>
        <path
            d="M7.875 8.55h-7.2A.675.675 0 0 0 0 9.225V14.4a2.25 2.25 0 0 0 2.25 2.25H6.3a2.25 2.25 0 0 0 2.25-2.25V9.225a.675.675 0 0 0-.675-.675Zm-2.7 3.15h-1.8a.675.675 0 1 1 0-1.35h1.8a.675.675 0 0 1 0 1.35Zm12.15-3.15h-7.2a.675.675 0 0 0-.675.675V14.4a2.25 2.25 0 0 0 2.25 2.25h4.05A2.25 2.25 0 0 0 18 14.4V9.225a.675.675 0 0 0-.675-.675Zm-2.7 3.15h-1.8a.675.675 0 1 1 0-1.35h1.8a.675.675 0 1 1 0 1.35ZM12.375 0h-7.2A.675.675 0 0 0 4.5.675V5.4a2.25 2.25 0 0 0 2.25 2.25h4.05a2.25 2.25 0 0 0 2.25-2.25V.675A.675.675 0 0 0 12.375 0Zm-2.7 3.15h-1.8a.675.675 0 1 1 0-1.35h1.8a.675.675 0 1 1 0 1.35Z"
            fill="#6db37d"
        />
    </svg>
)

