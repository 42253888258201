import makeStyles from "@mui/styles/makeStyles";
import { Bold, Italic, Regular, SemiBold, remCalc } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "8px",
  },
  subTitle: {
    fontSize:"0.875rem",
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold
  },
  leadDetailsRoot: {

  },
  addBrokerAcc: {
    marginTop: "4px",
    fontSize:"0.875rem",
    fontFamily: Bold,
    "&:hover": {
      backgroundColor: "white",
    }
  },

  buttonUnSelected: {
    borderRadius: theme.palette.borderRadius,
    marginRight: "2px",
    height: "45px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#E4E8EE ",
    },
  },
  buttonTextUnSelected: {
    fontSize: "0.875rem",
    color: "#4E5A6B",
  },
  buttonSelected: {
    borderRadius: theme.palette.borderRadius,
    marginRight: "2px",
    height: "45px",
    border: "1px solid #5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#5078E1 ",
    },
  },
  buttonTextSelected: {
    fontSize: "0.875rem",
    color: "white",
  },
  typeBg: {
    position: "absolute",
    textAlign: "center",
    color: "white",
    right: "-10px",
    zIndex: 1
  },
  typeBgArabic: {
    position: "absolute",
    textAlign: "center",
    color: "white",
    left: "0px",
    zIndex: 1,
    direction: "ltr"
  },
  centeredText: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  type: {
    fontSize:"0.875rem",
    fontFamily: Bold,
    marginInlineStart: "3px",
    marginTop: "1px"
  },
  leadDetailsRootDivider: {
    borderRadius: "4px",
    backgroundColor: "white",
    height: `calc(100vh - 278px)`,
    overflow: 'auto',
    boxShadow: "0px 0px 16px #00000014",
    [theme.breakpoints.down('lg')]: {
      height: "auto",
    },
  },
  srcText: {
    color: "#896DB3",
    fontSize: "0.875rem",
    fontFamily: Bold,
    marginInlineStart: "3px"
  },
  arrowIcon: {
    color: "#896DB3",
  },
  typeText: {
    color: "#6D80B3",
    fontSize: "0.875rem",
    fontFamily: Bold,
    marginInlineStart: "3px"
  },
  sourceTypeIcon: {
    marginLeft: "3px"
  },
  homeIcon: {
    color: "#6D80B3",
  },
  purposebox: {
    backgroundColor: "#E4EDFF80",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  srctypebox: {
    backgroundColor: "#EDE4FE80",
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    marginTop: "-12px"
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
  },
  userImg: {
    height: "48px",
    width: "48px",
    borderRadius: "4px",
    objectFit: "fill"
  },
  detailRoot: {
    // backgroundColor: "#F5F7FA",
    // padding: "6px 6px 6px 6px"
    display: "flex",
    justifyContent: "space-between"
  },
  leadName: {
    fontSize: "0.875rem",
    fontFamily: Bold,
    // marginInlineStart: "12px",
  },
  leadNo: {
    fontSize:"0.875rem",
    fontFamily: Bold,
    backgroundColor: theme.palette.background.secondary,
    color: theme.typography.color.tertiary,
    borderRadius: "4px",
    padding: "2px 4px",
    marginTop: "4px",
    width: "fit-content"
  },
  mobileNo: {
    fontSize:"0.875rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold,
    direction: "ltr"
  },
  mailIcon: {
    fontSize: "19px",
    color: "#98a0ac",
    marginLeft: "3px"
  },
  divider: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  dataBold: {
    fontSize:"0.875rem",
    color: "#4E5A6B",
    fontFamily: Bold
  },
  tabContant: {
    padding: "12px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    marginTop: "53px",
  },
  tabTitle: {
    color: "#4E5A6B",
   fontSize:"0.875rem",
    fontFamily: SemiBold,
  },
  image: {
    borderRight: "1px solid #E4E8EE",
  },
  viewCard: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "16px",
    marginTop: '16px'
  },
  customTypography: {
    fontSize:"0.875rem",
    fontFamily: Bold,
    color: '#4E5A6B',
    marginBottom: 10
  },
  //unitCard Styles
  unitCard: {
    padding: "15px",
    backgroundColor: "#DBF0F180",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: theme.palette.borderRadius,
    marginTop: "15px",
    cursor: "pointer"
  },
  selectionIcon: {
    color: "#6DAFB3",
    marginTop: "4px"
  },
  selectionText: {
    color: "#6DAFB3",
    fontFamily: Bold,
    marginInlineStart: "8px"
  },
  selectarrowRightIcon: {
    color: "#6DAFB3 !important",
    marginTop: "8px"
  },
  ownerSelection: {
    padding: "25px",
  },
  selectCard: {
    display: "flex",
    justifyContent: "space-between",
    padding: "25px",
    alignItems: 'center'
  },
  ownerText: {
   fontSize:"0.875rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
    marginInlineStart: "16px"
  },
  ownerSubText: {
    fontSize: "0.875rem",
    color: theme.typography.color.tertiary,
    fontFamily: Regular
  },
  ownerTitle: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    color: theme.typography.color.tertiary,
    marginBottom: "5px"
  },
  ownerAgainstUnits: {
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    marginTop: "5px"
  },
  individualownerSelection: {
    padding: "25px",
    height: "400px"
  },
  unitName: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: SemiBold
  },
  unitLocation: {
    fontSize:"0.875rem",
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold
  },
  vacant: {
    fontSize:"0.875rem",
    color: "#5AC782",
    fontFamily: SemiBold,
    marginTop: "10px"
  },
  mileStoneBg: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    padding: "15px"
  },
  mileStoneName: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },
  interestunitcard: {
    backgroundColor: "#F5F7FA"
  },
  unitproperty: {
    padding: "12px"
  },
  unit_name: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: SemiBold
  },
  property_name: {
    fontSize:"0.875rem",
    color: theme.typography.color.tertiary,
    fontFamily: Italic
  },
  intTitle: {
    fontSize: "0.875rem",
    color: theme.typography.color.secondary,
    fontFamily: Bold
  },
  btnAdd: {
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "11px",
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
  },
  arrowIcons: {
    color: '#E4EDFF80',
  },
  gridFlex: {
    display: "flex !important"
  },
  accountText: {
    color: "#B3A16D",
    fontSize: "0.875rem",
    fontFamily: Bold,
    marginInlineStart: "4px"
  },
  endAdornment:{
    fontSize: remCalc(14),
    fontFamily: SemiBold,
  },
  ddMenuListText:{
    fontSize: remCalc(14),
    fontFamily: SemiBold,
    color: "#896DB3"
  }
}));

export const useStylesDetailsCard = makeStyles((theme) => ({
  root: {
    padding: "12px",
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  heading: {
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    color: " #98A0AC",
  },
  sub: {
    fontSize: "0.875rem",
    fontFamily: SemiBold,
    color: "#091B29",
    marginTop: "6px",
  },
  status: {
    fontSize:"0.875rem",
    fontFamily: Bold,
    padding: "2px 10px",
    borderRadius: "2px",
    display: "inline-block",
    marginTop: "6px",
  },
  btn: {
    padding: "0px",
    boxShadow: "none",
    marginTop: "6px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  title: {
    fontSize:"0.875rem",
    fontFamily: Bold,
    color: "#4E5A6B",
  },
  imageView: {
    height: "100px",
    width: "100px",
    margin: '0 auto'
  },
  interestunitcard: {
    backgroundColor: "#F5F7FA"
  },
  unitproperty: {
    padding: "12px"
  },
  unit_name: {
    fontSize: "0.875rem",
    color: theme?.typography?.color?.primary,
    fontFamily: SemiBold
  },
  property_name: {
    fontSize:"0.875rem",
    color: theme?.typography?.color?.tertiary,
    fontFamily: Italic
  },
  intTitle: {
    fontSize: "0.875rem",
    color: theme?.typography?.color?.secondary,
    fontFamily: Bold
  },
  unitselectivemain: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius
  },
  unitselective: {
    padding: "12px"
  },
}));
