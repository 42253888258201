import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SearchFilter, UseDebounce } from '../../components'
import { NewLoader } from '../../components/newLoader'
import { PopupCard } from './components/popupCard'
import { CreateEditContext } from './create/createEditContext'
import { PopupStyle } from './style'

export const PropertyPopup = ({
    t
}) => {
    const classes = PopupStyle()

    const {
        propertyList,
        getPropertyList,
        propertyLoading,
        updateTodoState,
        setPropertyPopup,
        selectedTodo } = React.useContext(CreateEditContext)

    const [searchText, setSearchText] = React.useState("")
    const [offset, setOffset] = React.useState(0)
    const [selected, setSelected] = React.useState({})

    const debounce = UseDebounce();

    React.useEffect(() => {
        getPropertyList(0, "", true)
        // eslint-disable-next-line
    }, [])

    const searchFunction = (e) => {
        setSearchText(e)
        debounce(() => getPropertyList(0, e, true), 800)
    }

    //fetch more
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getPropertyList(offset + 10, "", false);
    };

    const saveList = (value) => {
        setSelected(value)
    }

    React.useEffect(() => {
        setSelected(selectedTodo?.property)
    }, [selectedTodo])

    return (
        <>
            <Stack m={2}>
                <SearchFilter
                    value={searchText}
                    handleChange={(e) => searchFunction(e)}
                    placeholder={t("Search")} />
                {
                    propertyLoading ?
                        <Box sx={{ height: "600px" }}>
                            <NewLoader minusHeight={"450px"} />
                        </Box>
                        :
                        <Box mt={1}>
                            {
                                propertyList?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={propertyList.length}
                                        next={fetchMoreData}
                                        hasMore={true}
                                        height={`calc(100vh - 290px)`}
                                    >
                                        {
                                            propertyList?.map((e) => {
                                                return (
                                                    <PopupCard img_url={e?.property_logo ?? "-"}
                                                        title={e?.property_name ?? "-"}
                                                        subtitle={`${e?.property_address?.state ? e?.property_address?.state : "-"} ${e?.property_address?.country ? e?.property_address?.country : "-"}`}
                                                        data={e}
                                                        handleClick={saveList}
                                                        selected={selected}
                                                        type={"property"} />
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                                    :
                                    <Box className={classes.noData}>
                                        <Typography>{t("No Property Found")}</Typography>
                                    </Box>
                            }
                        </Box>
                }
                <Box>
                    <Button fullWidth variant='contained'
                        className={classes.addbtn}
                        onClick={() => {
                            updateTodoState("property", selected)
                            setPropertyPopup(false)
                        }}
                        disabled={selected?.property_id?.length > 0 ? false : true}>{t("Add")}</Button>
                </Box>
            </Stack>
        </>
    )
}