import React from "react";
import { withNavBars } from "../../HOCs";
import DeliveryWorkOrder from "./deliveryWorkOrders"

class DeliveryWorkOrderParent extends React.Component {
  render() {
    return <DeliveryWorkOrder />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(DeliveryWorkOrderParent, props);