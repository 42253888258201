import { CalanderSelect, CalanderUnSelect, ClockSlected, ClockUnSlected } from "../components/calanderselect"
export const tab_data =(t)=> {
   return{
    manual_backup: {
        value: "manual_backup",
        label: t("Manual Backup"),
        add_button_text: t("Create Manual Backup"),
    },
    system_backup: {
        value: "system_backup",
        label: t("System Backup"),
    }
   }
}

export const tab_paths = (t)=> [
    tab_data(t).manual_backup.value,
    tab_data(t).system_backup.value,
]

export const initial_create_backup_form_state = {
    title: "",
    schedule_date: new Date(),
    type: "Schedule",
    error: {
        title: "",
        schedule_date: "",
        type: "",
    }
}


export const SlectedTypes = {
    "Schedule": <CalanderSelect />,
    "Now": <ClockSlected />
}

export const SlectedUnTypes = {
    "Schedule": <CalanderUnSelect />,
    "Now": <ClockUnSlected />
}