import React from "react";
import { withNavBars } from "../../HOCs";
import CreateContactNew from "./createContactNew";

class CreateContactParent extends React.Component {
    render() {
        return <CreateContactNew type="Contact" />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CreateContactParent, props);