import { Grid } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const CarouselSliders = ({ data = [], assets = false }) => {
  var settings = {
    dots: false,
    infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    //autoplay: true,
    speed: 2000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ marginTop: "10px" }} alignItems={"center"}>
          <Slider {...settings}>
            {data.map((val) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={assets ? val.url : val}
                    alt=""
                    style={{
                      height: assets ? "300px" : "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            })}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
};
