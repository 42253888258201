import makeStyles from "@mui/styles/makeStyles";


export const useStyles = makeStyles((theme) => ({
  root: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 0px 16px #00000014`,
    backgroundColor: "white",
    padding: "16px 0px 16px 16px",
    margin: "14px",
    height: `calc(100vh - 147px)`,
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "13px",
    height: '40px'
  },
  outerCircle: {
    padding: "2px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
  },
  tableMain: {
    padding: "0px 16px !important",
  },
}));
