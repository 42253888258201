import React from "react"

export const TransportationIcon = () =>(
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_112503" data-name="Group 112503" transform="translate(-154 -2545)">
    <g id="Rectangle_57299" data-name="Rectangle 57299" transform="translate(154 2545)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="32" height="32" stroke="none"/>
      <rect x="0.5" y="0.5" width="31" height="31" fill="none"/>
    </g>
    <path id="icons8-public-transportation_1_" data-name="icons8-public-transportation (1)" d="M10.82,6a5.39,5.39,0,0,0-5.385,5.385v1.843A1.073,1.073,0,0,0,4,14.256v3.59a1.077,1.077,0,0,0,1.436,1.029V31.128a1.435,1.435,0,0,0,1.436,1.436H7.59a1.435,1.435,0,0,0,1.436-1.436h5.744V25.744a4.573,4.573,0,0,1,.834-2.638l.638-2.73a4.76,4.76,0,0,1,.243-.747L7.59,19.64V11.385A3.235,3.235,0,0,1,10.82,8.154H24.461a3.235,3.235,0,0,1,3.231,3.231v5.407a4.628,4.628,0,0,1,3.59,2.033V14.256A1.077,1.077,0,0,0,30.2,13.179a1.065,1.065,0,0,0-.359.066V11.385A5.39,5.39,0,0,0,24.461,6Zm5.026,3.59a1.077,1.077,0,1,0,0,2.154h3.59a1.077,1.077,0,1,0,0-2.154Zm4.94,8.615a3.241,3.241,0,0,0-3.147,2.5l-.688,2.95q-.007.034-.013.069a3.2,3.2,0,0,0-.733,2.023v6.1a.718.718,0,0,0,.718.718h1.436a.718.718,0,0,0,.718-.718v-.718H29.128v.718a.718.718,0,0,0,.718.718h1.436A.718.718,0,0,0,32,31.846v-6.1a3.2,3.2,0,0,0-.732-2.022q-.007-.035-.015-.07L30.564,20.7h0a3.239,3.239,0,0,0-3.145-2.5Zm0,2.154h6.633a1.065,1.065,0,0,1,1.047.832h0l.308,1.324H19.429l.309-1.324A1.066,1.066,0,0,1,20.786,20.359Zm-9.607,3.59a1.436,1.436,0,1,1-1.436,1.436A1.435,1.435,0,0,1,11.179,23.949ZM19.436,26.1a1.077,1.077,0,1,1-1.077,1.077A1.077,1.077,0,0,1,19.436,26.1Zm9.333,0a1.077,1.077,0,1,1-1.077,1.077A1.077,1.077,0,0,1,28.769,26.1Z" transform="translate(152 2542)" fill="#98a0ac"/>
  </g>
</svg>
)