import { Box, Grid, Typography } from "@mui/material";
import React from 'react';
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { useStyles } from "../style";
import ReactQuill from "react-quill";
import { BreakupList } from "./breakupList/breakupList";


export const UnitDetails = (props) => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const modules = {
        toolbar: false,
    };
    return (
        <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "24px" }}>
            <Grid container className={classes.card}>
                <Grid item md={2} lg={1.5} className={classes.imgDiv}>
                    <img src={(props?.data?.logo && props?.data?.logo !== "") ? props?.data?.logo : "/images/imagesproperty.svg"} alt="" className={classes.unitImg} />
                    <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.unit_no}&nbsp;&nbsp;</Typography>
                </Grid>
                <Grid item md={10} lg={10.5} className={classes.imgDiv2}>
                    <Typography className={classes.title}>UNIT DETAILS</Typography>
                    <Grid container>
                        <Grid item md={12} lg={9}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="8px">
                                <Box>
                                    <Typography className={classes.heading}>Company Name</Typography>
                                    <Typography className={classes.sub}>{props?.data?.property?.company?.name}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>Unit Name</Typography>
                                    <Typography className={classes.sub}>{props?.data?.name}</Typography>
                                </Box>
                                <Box>
                                    <Typography className={classes.heading}>Status</Typography>
                                    <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In active"}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box marginTop="14px">
                        <Typography className={classes.heading}>Unit Description</Typography>
                        {props?.data?.description &&
                            <ReactQuill
                                readOnly
                                theme="bubble"
                                value={props?.data?.description ?? ""}
                                modules={modules}
                                className={classes.sub}
                            />
                        }
                    </Box>
                </Grid>
                <Grid item md={0} lg={4.5} />
            </Grid>
            <BreakupList data={props?.data} assets={props?.assets} permission={props?.permission} />
        </div>
    )
}