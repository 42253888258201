export const MarketingListing = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98431">
            <g data-name="list (2)" fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 94916"
                    d="M10.125 8h-2.5A.625.625 0 0 0 7 8.625v2.5a.625.625 0 0 0 .625.625h2.5a.625.625 0 0 0 .625-.625v-2.5A.625.625 0 0 0 10.125 8Z"
                />
                <path
                    data-name="Path 94917"
                    d="M21.375 8h-8.75a.625.625 0 0 0-.625.625v2.5a.625.625 0 0 0 .625.625h8.75a.625.625 0 0 0 .625-.625v-2.5A.625.625 0 0 0 21.375 8Z"
                />
                <path
                    data-name="Path 94918"
                    d="M10.125 13.625h-2.5A.625.625 0 0 0 7 14.25v2.5a.625.625 0 0 0 .625.625h2.5a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.625-.625Z"
                />
                <path
                    data-name="Path 94919"
                    d="M21.375 13.625h-8.75a.625.625 0 0 0-.625.625v2.5a.625.625 0 0 0 .625.625h8.75A.625.625 0 0 0 22 16.75v-2.5a.625.625 0 0 0-.625-.625Z"
                />
                <path
                    data-name="Path 94920"
                    d="M10.125 19.25h-2.5a.625.625 0 0 0-.625.625v2.5a.625.625 0 0 0 .625.625h2.5a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.625-.625Z"
                />
                <path
                    data-name="Path 94921"
                    d="M21.375 19.25h-8.75a.625.625 0 0 0-.625.625v2.5a.625.625 0 0 0 .625.625h8.75a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.625-.625Z"
                />
            </g>
            <path data-name="Rectangle 52132" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
