import React from "react";
import { withNavBars } from "../../HOCs";
import MarketPlace from "./marketPlace";

class marketPlaceParent extends React.Component {
    render() {
        return <MarketPlace />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(marketPlaceParent, props);
