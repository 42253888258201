/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Badge, Box, Button, Dialog, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import FilterIMG from '../../assets/filter';
import { AlertDialog, FilterGenerator, SearchFilter, Subheader, TableWithPagination, TextBox, UseDebounce } from '../../components';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { accessCheckRender, AlertProps, enumName, enumSelect, enum_types, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods, convertTimeUtcToZoneCalander, stringAvatar, agreement_status_object, dateDiff } from '../../utils';
import { useStyles } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import { Heading, Path, Type, StatusOptionList, UnitPath, UnitHeading, UnitType } from '../../utils/agreementTermination/agreementTerminationUtils';
import moment from "moment";
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import AGRIcon from '../../assets/agreementDetails/agrIcon';
import RequestDeatilsIcon from '../../assets/agreementDetails/requestDetailsIcon';
import DeclineIcon from '../../assets/agreementDetails/declineIcon';
import AcceptIcon from '../../assets/agreementDetails/acceptIcon';
import TentativeIcon from '../../assets/agreementDetails/tentativeIcon';
import AlarmIcon from '../../assets/agreementDetails/alarmIcon';
import { ArrowForwardIosOutlined } from '@mui/icons-material';
import { MoveOutTable } from './moveOutTable';
import { withNamespaces } from 'react-i18next';

const AgreementTermination = ({
    t = () => false
}) => {


    const defaultRequestState = {
        id: "",
        formType: "",
        status: "",
        requestNo: "",
        agreementNo: "",
        requestedBy: "",
        requestedOn: "",
        remarks: "",
        acceptDeclineRemarks: "",
        leaseStart: "",
        leaseEnd: "",
        contactImg: "",
        contactName: "",
        contactNo: "",
        contactEmail: "",
        units: [],
        error: {
            acceptDeclineRemarks: ""
        }
    }

    const debounce = UseDebounce()

    // useContext
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)


    // useState
    const [open, setOpen] = React.useState(false);
    const onClose = () => {
        setRequestDialogOpen(false)
        setOpen(!open)
    }
    const [companyList, setCompanyList] = React.useState([])
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [list, setList] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [filterData, setFilterData] = React.useState({
        status: [true],
        agreementStatus: [agreement_status_object(t, "termination")?.[enumName.pending]],
        dateRange: ""
    })
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [requestDialogOpen, setRequestDialogOpen] = React.useState(false)
    const [requestState, setRequestState] = React.useState({ ...defaultRequestState })
    const [acceptDeclineDialogOpen, setAcceptDeclineDialogOpen] = React.useState(false)
    const [enumValue, setEnumValue] = React.useState({ agreement_request_action: [] })
    const [permission, setPermission] = React.useState({})
    const [disable, setDisable] = React.useState(false)
    const classes = useStyles()


    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getEnum()
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    // useEffect to get agreement termination list using selected company and filter data when loading the screen
    React.useEffect(() => {
        setPage(1)
        if (selectedCompany?.value) { getList() }
    }, [selectedCompany, filterData])

    // Function to get agreement termination list based on the input data
    const getList = (offset = 0, limit = 10, search = "", agreementStatus = [enumName.pending]) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
        let startDate = filterData?.dateRange?.startDate
        let endDate = filterData?.dateRange?.endDate
        let payload = {
            company_id: selectedCompany?.value, search, offset, limit,
            status: filterData?.agreementStatus?.map((_) => _?.value) ?? agreementStatus,
            request_purpose: enumName.termination,
            from_date: startDate && moment(startDate).format("YYYY-MM-DD"),
            to_date: endDate && moment(endDate).format("YYYY-MM-DD"),
            active: (!filterData?.status || filterData?.status?.length === 0) ?
                [true, false] : filterData?.status,
        }
        NetworkCall(
            `${config.api_url}/agreement_request/list`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((r) => {
            setList({
                data: r?.data?.data ?? [],
                totalRowsCount: r?.data?.count
            })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error
            })
        })
    }

    // Function to get Enum value
    const getEnum = async () => {
        const result = await enumSelect([enum_types.agreement_request_action])
        let agreement_request_action = result?.agreement_request_action?.map((_) => {
            return agreement_status_object(t, "termination")?.[_?.value ?? "default"]
        })
        setEnumValue({ agreement_request_action })
    }

    // Set row data for table
    const Rows = React.useCallback(list?.data?.map((_) => {
        let days_difference = dateDiff(new Date(_?.lease_end_date), new Date(_?.tentative_termination_date));
        let j
        try {
            j = {
                id: _?.id,
                requestNo: _?.reference_id ?? "-",
                agreementNo: _?.agreement_no ?? "-",
                requestedBy: _?.created_by ?? "-",
                requestedOn: _?.created_at,
                noOfUnits: _?.no_of_units ?? "-",
                agree_exit_date: _?.termination_date,
                moveOutDate: _?.tentative_termination_date,
                actual_exit_date: _?.agreement_exit_date ?? _?.termination_date,
                days_difference: (days_difference || days_difference === 0) &&
                {
                    value: days_difference,
                    label: `${days_difference} ${days_difference === 1 ? t("Day") : t("Days")}`,
                    text_color: days_difference > 0 ? "#FF4B4B" : "#5AC782"
                },
                status: agreement_status_object(t, "termination")?.[_?.status ?? "default"],
                data: _
            }
        } catch (err) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error, msg: t("Some Thing Went Wrong")
            })
        }
        return j
    }), [list])

    // Function to change the company
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
    }

    // Function for search in search component
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data in agreement request list
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }
        getList(0, limit, e)
    }

    // Function to handle pagination in table
    const handleTablePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getList(offset, limit, searchText)
    }

    // Function to handle page limit in table
    const handleTablePageLimit = (value) => {
        setLimit(value)
        setPage(1)
        getList(0, value, searchText)
    }

    // Function to update agreementRequestState
    const updateRequestDialogState = (k, v) => {
        let error = requestState?.error
        error[k] = ""
        setRequestState({ ...requestState, [k]: v, error })
    }

    // Function for updating addNewState
    const validate = () => {
        let isValid = true
        let error = requestState.error
        if (requestState?.acceptDeclineRemarks?.length === 0) { isValid = false; error.acceptDeclineRemarks = t("Remarks is Required") }
        if (!isValid) {
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory field"),
            })
        }
        setRequestState({ ...requestState, error })
        return isValid
    }

    // Function to accept/decline a agreement request
    const handleAcceptDecline = () => {
        backdrop.setBackDrop({
            ...backdrop, open: true,
            message: `${requestState?.formType === "Accept" ? "Accepting" : "Declining"} the request`,
        });
        const payload = {
            "request_id": requestState?.id,
            "status": requestState?.formType === "Accept" ? "Approved" : "Rejected",
            "manager_remarks": requestState?.acceptDeclineRemarks,
        }
        NetworkCall(
            `${config.api_url}/agreement/termination`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((_) => { 
            getList()
            setDisable(false)
            setAcceptDeclineDialogOpen(false)
            setRequestState({ ...defaultRequestState })
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch(() => {
            setDisable(false)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true, severity: AlertProps.severity.success,
                msg: `Agreement Termination Request ${requestState?.formType === "Accept" ? "Accepted" : "Declined"}`,
            });
        });
    }

    //move out api request
    const moveOut = (data) => {
        setDisable(true)
        if (validate()) {
        
            if (requestState?.formType === "Accept") {
                const payload = {
                    agreement_id: data?.data?.agreement?.id,
                    agreement_request_id: data?.id
                }
                NetworkCall(
                    `${config.api_url}/move_out/convert_move_out`,
                    NetWorkCallMethods.post,
                    payload, null, true, false
                ).then((res) => {
                    handleAcceptDecline()
                }).catch((error) => {
                    setDisable(false)
                    setAcceptDeclineDialogOpen(false)
                    alert.setSnack({
                        ...alert, open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Internal error. Please try again later."),
                    });
                });
            } else {
                handleAcceptDecline()
            }
        } else { 
            setDisable(false)
         }
    }

    // Function to handle icon in table row
    const handleTableIcon = (type, data) => {
        backdrop.setBackDrop({ ...backdrop, open: true, message: t("Loading Request"), });
        let payload = { request_id: data?.id }
        NetworkCall(
            `${config.api_url}/agreement_request`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((res) => {
            let _ = res?.data?.data
            setRequestState({
                id: _?.agreement_request?.id ?? "",
                status: agreement_status_object(t, "termination")?.[_?.agreement_request?.status ?? "default"],
                requestNo: _?.agreement_request?.reference_id ?? "-",
                agreementNo: _?.agreement?.agreement_no ?? "-",
                requestedBy: _?.agreement_request?.requested_by?.name ?? "-",
                requestedOn: _?.agreement_request?.created_at ?
                    convertTimeUtcToZoneCalander(_?.agreement_request?.created_at) : "-",
                acceptDeclineRemarks: _?.agreement_request?.remarks ?? "-",
                leaseStart: _?.agreement?.lease_start_date ?
                    convertTimeUtcToZoneCalander(_?.agreement?.lease_start_date) : "-",
                leaseEnd: _?.agreement?.lease_end_date ?
                    convertTimeUtcToZoneCalander(_?.agreement?.lease_end_date) : "-",
                contactImg: _?.contact?.image_url ?? "",
                contactName: _?.contact?.name ?? "-",
                contactNo: _?.contact?.mobile_no ?
                    (_?.contact?.mobile_no_country_code + " " + _?.contact?.mobile_no) : "-",
                contactEmail: _?.contact?.email_id ?? "",
                units: _?.agreement_units ?? [],
                tentativeTerminationDate: _?.agreement_request?.tentative_termination_date ?
                    convertTimeUtcToZoneCalander(_?.agreement_request?.tentative_termination_date) : "-",
                prematureTermination: new Date() < new Date(_?.agreement?.lease_end_date) ? true : false,
                approvedBY: _?.agreement_request?.approved?.manager_name ?? "-",
                managerRemarks: _?.agreement_request?.manager_remarks ?? "-",
                updatedOn: _?.agreement_request?.updated_at ?
                    convertTimeUtcToZoneCalander(_?.agreement_request?.updated_at) : "-",
                termination_date: _?.agreement_request?.termination_date ?
                    convertTimeUtcToZoneCalander(_?.agreement_request?.termination_date) : "-",
                agreement_exit_date: _?.agreement?.agreement_exit_date &&
                convertTimeUtcToZoneCalander(_?.agreement?.agreement_exit_date),
                account_no: _?.agreement?.account_id,
                lock_in_period: _?.agreement?.is_agreement_lock,
                data: _,
                row_data: data,
                error: defaultRequestState?.error
            })
            setRequestDialogOpen(true)
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
        }).catch((error) => {
            backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Internal error. Please try again later."),
            });
        });
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={true} title={t("Agreement Termination")}
                select options={companyList} value={selectedCompany} onchange={(e) => handleCompanyChange(e)} />
            <div className={classes.root}>
                <Grid container className={classes.content}>
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} placeholder={t("Search Agreement")}
                            handleChange={(value) => handleSearch(value)} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" }}} />
                    </Grid >
                    <Grid item xs={8}>
                        <Box display={"flex"} justifyContent={"end"}>
                            <Stack direction="row" spacing={2}
                                divider={<Divider orientation="vertical" flexItem />}>
                                <IconButton onClick={() => setFilterDrawer(!filterDrawer)}
                                    className={classes.filterButton}>
                                    <Badge variant="dot" color="primary"
                                        invisible={!(filterData.status?.length > 0 || filterData?.dateRange)}>
                                        <FilterIMG color="#091b29" />
                                    </Badge>
                                </IconButton>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TableWithPagination
                            heading={Heading(t)}
                            rows={Rows}
                            path={Path}
                            showpagination={true}
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            onClick={() => console.log("")}
                            tableType="no-side"
                            dataType={Type}
                            handleIcon={handleTableIcon}
                            handlePagination={handleTablePagination}
                            handleChangeLimit={handleTablePageLimit}
                            totalRowsCount={list?.totalRowsCount}
                            page={page}
                            limit={limit}
                            height={'calc(100vh - 290px)'}
                            view={permission?.read}
                            edit={permission?.update}
                            delete={permission?.delete} />
                    </Grid>
                </Grid >
                <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                    onApply={(value) => setFilterData(value)}
                    components={[
                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: StatusOptionList(t),
                            isMulti: true,
                            state_name: "status",
                            label: t("Status")
                        },
                        {
                            component: "select",
                            value: filterData?.agreementStatus,
                            options: enumValue?.agreement_request_action,
                            isMulti: true,
                            state_name: "agreementStatus",
                            label: t("Agreement Status"),
                            placeholder: t("Select Agreement Status")
                        },
                        {
                            component: "date_range",
                            value: filterData?.dateRange,
                            state_name: "dateRange",
                            label: t("Requested On"),
                            startPlaceholder: t("Select Start Date"),
                            endPlaceholder: t("Select End Date")
                        },
                    ]} />
                <Dialog
                    className={classes.viewDialog}
                    open={requestDialogOpen}
                    onClose={() => setRequestDialogOpen(false)}>
                    <div className={classes.viewDialogHeader}>
                        <div className={classes.viewDialogHeaderContainer}>
                            <Typography className={classes.viewDialogHeaderTitle}>
                                {t("Request View")}
                            </Typography>

                        </div>
                        <IconButton onClick={() => setRequestDialogOpen(false)}
                            className={classes.viewDialogCloseButton}>
                            <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                        </IconButton>
                    </div>
                    <div className={classes.viewDialogBody}>
                        <Grid container>
                            <Grid item xs={6} paddingInlineEnd={"8px"}>
                                <Box height={requestState?.is_external ? 'calc(100vh - 237px)' : 'calc(100vh - 277px)'} overflow={"overlay"} paddingBottom={"8px"}>
                                {(requestState?.status?.value !== enumName.pending) &&
                                    <>
                                        <Box className={classes.boxWithOutFlex}>
                                            <Box className={classes.boxWithOutBorder}>
                                                <Box>
                                                    {requestState?.status?.value === enumName.rejected ? <DeclineIcon /> : <AcceptIcon />}
                                                </Box>
                                                <Box flexGrow={1} marginLeft={"10px"} >
                                                    <Typography className={classes.viewDialogText4} textTransform={"capitalize"}>
                                                        {requestState?.status?.label}
                                                    </Typography>
                                                    <Typography className={classes.italicText}>
                                                        By {requestState?.approvedBY} on {requestState?.updatedOn}
                                                    </Typography>
                                                    <Typography className={classes.semiBoldText}>
                                                        Remarks : <span className={classes.regularText}>{requestState?.managerRemarks}</span>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {requestState?.status?.value === enumName.approved &&
                                                <Box
                                                    className={classes.boxWithOutBorderColor}
                                                    onClick={onClose}
                                                >
                                                    <Box>
                                                        <AlarmIcon />
                                                    </Box>
                                                    <Box className={classes.moveOut}>
                                                        <Typography className={classes.semiBoldText} color={"primary"}>
                                                            {t(" Move out process is Initiated")}
                                                        </Typography >
                                                        <ArrowForwardIosOutlined color={"primary"} fontSize={"12px"} />
                                                    </Box >
                                                </Box >
                                            }
                                        </Box >
                                    </>
                                }


                                <Box margin={"12px 0px"}>
                                    <Typography className={classes.heading}>
                                        {t("REQUEST DETAILS")}
                                    </Typography >
                                </Box >
                                <Box className={classes.boxWithOutFlex}>
                                    <Box className={classes.boxWithOutBorder}>
                                        <Box>
                                            <RequestDeatilsIcon />

                                        </Box>
                                        <Box flexGrow={1} marginInlineStart={"10px"} >
                                            <Box className={classes.boxFlex}>
                                                <Box>
                                                    <Typography className={classes.viewDialogText6}>
                                                        {requestState?.requestedBy}
                                                    </Typography>
                                                </Box>

                                                <Box className={requestState?.status?.value === enumName.pending ? classes.inProgress : requestState?.status?.value === enumName.rejected ? classes.decline : classes.approved}>
                                                    {requestState?.status?.label}
                                                </Box>

                                            </Box>

                                            <Typography className={classes.italicText}>
                                                {requestState?.requestNo} on {requestState?.requestedOn}
                                            </Typography>
                                            <Typography mt={"12px"} className={classes.semiBoldText}>
                                                {t("Remarks")} : <span className={classes.regularText}>{requestState?.acceptDeclineRemarks}</span>
                                            </Typography >

                                        </Box >
                                    </Box >
                                    <Box margin={"8px 0px"}>
                                        <Divider />
                                    </Box>
                                    <Box className={classes.boxWithOutBorder} paddingBottom={"8px"}>
                                        <Box>
                                            <TentativeIcon />
                                        </Box>
                                        <Box flexGrow={1} marginInlineStart={"10px"} >
                                            <Box className={classes.boxFlex}>
                                                <Box>
                                                    <Typography className={classes.viewDialogText6}>
                                                        &#x202a;{requestState?.tentativeTerminationDate}&#x202c;
                                                    </Typography>
                                                </Box>
                                                {requestState?.prematureTermination &&
                                                    <Box className={classes.conform}>
                                                        {t("Premature Termination")}
                                                    </Box >
                                                }
                                            </Box >
                                            <Typography className={classes.italicText}>
                                                {t("Tentative move-out date")}
                                            </Typography >

                                        </Box >
                                    </Box >
                                    {requestState?.row_data?.days_difference?.value &&
                                        <Box className={
                                            requestState?.row_data?.days_difference?.value > 0
                                                ? classes.days_difference_gt_0
                                                : classes.days_difference_lte_0
                                        }>
                                            {`${t("No. of Days Difference")}: ${requestState?.row_data?.days_difference?.label}`}
                                        </Box >}
                                    <Box margin={"8px 0px"}>
                                        <Divider />
                                    </Box>
                                <Box margin={"12px 0px"}>
                                    <Typography className={classes.heading}>
                                        {t("AGREEMENT DETAILS")}
                                    </Typography >
                                </Box >
                                    <Box className={classes.boxWithOutBorder} paddingBottom={"8px"}>
                                        <Box>
                                            <AGRIcon />
                                        </Box>
                                        <Box flexGrow={1} marginInlineStart={"10px"} >
                                            <Box className={classes.boxFlex}>
                                                <Box>
                                                    <Typography className={classes.viewDialogText6}>
                                                    {requestState?.agreementNo}
                                                    </Typography>
                                                </Box>
                                            </Box >
                                            <Typography className={classes.italicText}>
                                                &#x202a;{requestState?.leaseStart}&#x202c; - &#x202a;{requestState?.termination_date}&#x202c;
                                            </Typography>

                                        </Box >
                                    </Box >
                                    {requestState?.account_no && 
                                    <Stack direction={"row"} justifyContent={"space-between"}>                                     
                                    <Typography className={classes.viewDialogText7} textTransform={"capitalize"}>
                                        {t("Account Number")}   
                                    </Typography >                              
                                    <Typography className={classes.viewDialogText8}>
                                        {requestState?.account_no}
                                    </Typography > 
                                    </Stack>}
                                    <Stack direction={"row"} justifyContent={"space-between"} marginTop={"8px"}>                                     
                                    <Typography className={classes.viewDialogText7} textTransform={"capitalize"}>
                                        {t("Lock-In Period")}    
                                    </Typography >                             
                                    <Typography className={classes.viewDialogText8} textTransform={"capitalize"}>
                                        {requestState?.lock_in_period?t("yes"):t("no")}
                                    </Typography > 
                                    </Stack>

                                </Box >
                                <Box className={classes.boxWithOutFlex} marginTop={"12px"}>
                                    <Stack direction={"row"} justifyContent={"space-between"} divider={<Box width={"1px"} height={"40px"} sx={{backgroundColor:"#E4E8EE", margin:"0px 8px"}}/>}>
                                    <Stack direction={"column"}>                                        
                                    <Typography className={classes.viewDialogText7} textTransform={"capitalize"}>
                                        {t("Exit Date")}
                                    </Typography >                                      
                                    <Typography className={classes.viewDialogText8} textTransform={"capitalize"}>
                                        {requestState?.termination_date}
                                    </Typography >
                                    </Stack>
                                    <Stack direction={"column"}>                                        
                                    <Typography className={classes.viewDialogText7} textTransform={"capitalize"}>
                                        {t("Move Out Date")}
                                    </Typography >                                      
                                    <Typography className={classes.viewDialogText8} textTransform={"capitalize"}>
                                        {requestState?.tentativeTerminationDate}
                                    </Typography >
                                    </Stack>
                                    <Stack direction={"column"}>                                        
                                    <Typography className={classes.viewDialogText7} textTransform={"capitalize"}>
                                        {t("Actual Exit Date")}
                                    </Typography >                                      
                                    <Typography className={classes.viewDialogText8} textTransform={"capitalize"}>
                                        {requestState?.agreement_exit_date ?? requestState?.termination_date}
                                    </Typography >
                                    </Stack>
                                    </Stack>
                                </Box>
                                <Box margin={"12px 0px"}>
                                    <Typography className={classes.heading} textTransform={"uppercase"}>
                                        {t("Primary Contact")}
                                    </Typography >
                                </Box >
                                <Box className={classes.boxWithOutFlex}>
                                <Box display="flex" alignItems="center" className={classes.viewDialogContactCard}>
                                                <Avatar src={requestState?.contactImg} variant={"rounded"}
                                                    className={classes.contactAvatar} {...stringAvatar(requestState?.contactName)} />
                                                <Box flexGrow={1}>
                                                    <Grid container justifyContent={"start"} alignItems={"center"}
                                                        direction={"row"} paddingBottom={"6px"}>
                                                        <Typography className={classes.viewDialogText4}>
                                                            {requestState?.contactName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container justifyContent={"start"} alignItems={"center"} direction={"row"}>
                                                        <Typography className={classes.viewDialogText5}>
                                                            {requestState?.contactNo}
                                                        </Typography>
                                                        <Box className={classes.period} />
                                                        <Typography className={classes.viewDialogText5}>
                                                            {requestState?.contactEmail}
                                                        </Typography>
                                                    </Grid >
                                                </Box >
                                            </Box >
                                </Box> 
                                </Box>
                                {(permission?.update &&
                                    requestState?.status?.value === enumName.pending) &&
                                    <>
                                    { !requestState?.is_external &&
                                        <div className={classes.viewDialogButtonContainer}>
                                        <Button fullWidth className={classes.declineButton}
                                            onClick={() => {
                                                setRequestState({
                                                    ...requestState,
                                                    formType: "Decline", acceptDeclineRemarks: ""
                                                })
                                                setRequestDialogOpen(false)
                                                setAcceptDeclineDialogOpen(true)
                                            }}>
                                            {t("Decline")}
                                        </Button >
                                        <Button fullWidth className={classes.acceptButton}
                                        
                                            onClick={() => {
                                                setRequestState({
                                                    ...requestState,
                                                    formType: "Accept", acceptDeclineRemarks: ""
                                                })
                                                setRequestDialogOpen(false)
                                                setAcceptDeclineDialogOpen(true)
                                            }}>
                                            {t("Accept")}
                                        </Button >
                                    </div >}</>}
                            </Grid >
                            <Grid item xs={6} paddingLeft={"8px"}>
                                <Grid container className={classes.viewDialogTableContainer}>
                                    <Grid container>
                                        <Typography className={classes.viewDialogText4}>
                                            {"NUMBER OF UNITS (" + requestState?.units?.length + ")"}
                                        </Typography>
                                        <Grid className={classes.viewDialogTable}>
                                            <TableWithPagination
                                                heading={UnitHeading(t)}
                                                rows={requestState?.units}
                                                path={UnitPath}
                                                showpagination={false}
                                                showpdfbtn={false}
                                                showexcelbtn={false}
                                                showSearch={false}
                                                onClick={() => false}
                                                cellBackground={"transparent"}
                                                tableType="no-side"
                                                dataType={UnitType}
                                                totalRowsCount={requestState?.units?.length}
                                                height={'calc(100vh - 290px)'}
                                                view={permission?.read}
                                                edit={permission?.update}
                                                delete={permission?.delete} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid >
                        </Grid >
                    </div >
                </Dialog >
                <Dialog
                    className={classes.acceptDeclineDialog}
                    open={acceptDeclineDialogOpen}
                    onClose={() => setAcceptDeclineDialogOpen(false)}>
                    <div className={classes.viewDialogHeader}>
                        <Typography className={classes.viewDialogHeaderTitle}>
                            {requestState?.formType === "Accept" ? "Accept Confirmation" :
                                requestState?.formType === "Decline" ? "Decline Confirmation" :
                                    t("Accept Confirmation")}
                        </Typography>
                        <IconButton onClick={() => setAcceptDeclineDialogOpen(false)}
                            className={classes.viewDialogCloseButton}>
                            <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                        </IconButton>
                    </div>
                    <div className={classes.acceptDeclineDialogBody}>
                        <Box className={classes.boxWithOutFlex} marginBottom={"8px"}>
                            <Box className={classes.boxWithOutBorder}>
                                <Box>
                                    <TentativeIcon />
                                </Box>
                                <Box flexGrow={1} marginInlineStart={"10px"} >
                                    <Box className={classes.boxFlex}>
                                        <Box>
                                            <Typography className={classes.viewDialogText4}>
                                                &#x202a;{requestState?.tentativeTerminationDate}&#x202c;
                                            </Typography>
                                        </Box>
                                        {requestState?.prematureTermination &&
                                            <Box className={classes.conform}>
                                                {t("Premature Termination")}
                                            </Box >
                                        }
                                    </Box >
                                    <Typography className={classes.semiBoldText}>

                                        {t("Tentative move-out date")}

                                    </Typography >

                                </Box >
                            </Box >
                            <Box margin={"16px 0px"}>
                                <Divider />
                            </Box>
                            <Box marginInlineStart={"8px"} alignItems={"center"} className={classes.boxWithOutBorder}>
                                <img src='images/moveout.svg' alt='moveout' />
                                <Box flexGrow={1} marginInlineStart={"10px"} >
                                    <Typography className={classes.semiBoldText}>
                                        {t("Move out process will initiate once it is approved")}
                                    </Typography>
                                </Box>
                            </Box>


                        </Box >

                        <TextBox
                            multiline
                            isrequired
                            isReadonly={requestState?.formType === "view"}
                            label={t("Remarks")}
                            placeholder={t("Enter Remarks")}
                            value={requestState?.acceptDeclineRemarks ?? ""}
                            onChange={(e) => updateRequestDialogState("acceptDeclineRemarks", e.target.value)}
                            isError={requestState?.error?.acceptDeclineRemarks?.length > 0}
                            errorMessage={requestState?.error?.acceptDeclineRemarks} />
                    </div >
                    <div className={classes.acceptDeclineDialogFooter}>
                        <Button fullWidth className={classes.cancelButton}
                            onClick={() => {
                                setRequestState({
                                    ...requestState,
                                    formType: "", acceptDeclineRemarks: ""
                                })
                                setRequestDialogOpen(true)
                                setAcceptDeclineDialogOpen(false)
                            }}>
                            {t("Cancel")}
                        </Button >
                        <Button fullWidth className={classes.acceptDeclineButton}
                            onClick={() => moveOut(requestState)}
                            disabled={disable}
                        >
                            {requestState?.formType}
                        </Button>
                    </div >
                </Dialog >
                {/* move out table */}
                < AlertDialog
                    header={t("Attachments")}
                    open={open}
                    onClose={onClose}
                    component={
                        <>
                            <MoveOutTable t={t} agreementRequestId={requestState?.id} />
                        </>
                    }
                    medium={false}

                />
            </div >
        </>
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}
export default withNamespaces("agreement")(AgreementTermination)

