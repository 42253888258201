import React from "react"

export const ViewContractIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <g id="Group_118690" data-name="Group 118690" transform="translate(-837 -283)">
          <path id="Path_101947" data-name="Path 101947" d="M4.5,0h27A4.5,4.5,0,0,1,36,4.5v27A4.5,4.5,0,0,1,31.5,36H4.5A4.5,4.5,0,0,1,0,31.5V4.5A4.5,4.5,0,0,1,4.5,0Z" transform="translate(837 283)" fill="#78b1fe"/>
          <path id="Path_101948" data-name="Path 101948" d="M37.4,28,35.22,30.115l-3.01-.426-.525,3L29,34.112l1.335,2.734L29,39.58l2.686,1.426.525,3,3.01-.426L37.4,45.693l2.184-2.115L42.6,44l.525-3,2.685-1.426-1.335-2.734,1.335-2.734-2.686-1.426-.525-3-3.01.426L37.4,28Z" transform="translate(817.596 264.154)" fill="#fff"/>
        </g>
      </svg>
      

    )
}