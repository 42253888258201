
export const ToolsMasterPath = ["name", "tool_id", "booking_status", "is_active"]

export const ToolsMasterHeading = (t) => {
    const Heading = [
        { title: "", field: "icon" },
        { title: t("toolName"), field: "name" },
        { title: t("toolId"), field: "tool_id", },
        { title: t("Cost"), field: "cost", },
        { title: t("currentStatus"), field: "is_active" },


    ]
    return Heading

}


export const ToolsMasterType = [
    { type: ["view_more"], name: "icon" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "tool_id" },
    { type: ["text"], name: "cost" },
    { type: ["status"], name: "is_active" },

]

export const StatusOptionList = (t=()=>false)=> [
    { label: t("Active"), value: true },
    { label: t("Inactive"), value: false },
]

export const initialState = () => {
    return {
        name: "",
        status: true,
        cost: "",
        period: "",
        description: "",
        error: {
            description: "",
            name: "",
            status: "",
            cost: "",
            period: "",
        }
    }
}
