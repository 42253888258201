import React from "react";
import { withNavBars } from "../../HOCs";
import CreateBlock from "./createBlock";

class CreateBlockParent extends React.Component {
    render() {
        return <CreateBlock />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CreateBlockParent, props);
