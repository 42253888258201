import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { stringAvatar } from '../../../utils'
import { VenueCardStyle } from './style'

export const VenueCard = ({ list = {}, showAvailableSlot = () => false, selectedVenue = "" }) => {
    const classes = VenueCardStyle()
    return (
        <Box className={classes.root}>
            {
                list.map(li => {
                    return (
                        <Box className={(selectedVenue === li.court_name) ? classes.selectedbox : classes.box} p={2} mt={1}
                            onClick={() => showAvailableSlot(li.court_name)}>
                            <Stack direction={"row"} alignItems="center" spacing={3}>
                                <Avatar src={li.image} {...stringAvatar(li?.court_name)}></Avatar>
                                <Stack>
                                    <Typography className={classes.name}> {li.court_name} </Typography>
                                    <Typography className={classes.slot}> {li.slot} slot available</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    )
                })
            }
        </Box>
    )
}