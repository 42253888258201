import { Box, Grid, CircularProgress, Skeleton } from "@mui/material";

import React, { useEffect, useContext } from "react";
import CardDetails from "../components/cardDetails";
import { useStyles } from "../styles";
import {
  owner,
  ownerDay,
  ownerStatus,
  oppertunityTimeLine,
} from "../../../utils/agreementUtils";
import { CardComponent } from "../components";
import { NetWorkCallMethods, AlertProps } from "../../../utils";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { AlertContext, AuthContext } from "../../../contexts";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import NoDataFound from "../../../assets/noData";

export const Oppertunity = ({ t, agreement_id = "", statusType = [], is_overall = false }) => {
  const classes = useStyles();
  // context
  const alert = useContext(AlertContext);
  // states
  const [list, setList] = React.useState({
    data: "",
    list: [],
  });
  const [loader, setLoader] = React.useState(false);
  // const [show, setShow] = React.useState(false);

  const auth = React.useContext(AuthContext)

  const getLeadDetails = (id) => {
    setLoader(true);
    const requestPayload = {
      id: agreement_id ?? "",
      status: statusType,
      type: "Lead",
    };
    NetworkCall(
      `${config.api_url}/agreement/time_duration`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        const result = oppertunityTimeLine(res?.data?.data);
        setList({
          data: res?.data?.data,
          list: result ?? [],
        });
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong. Please try again"),
        });
      });
  };

  useEffect(() => {
    if (agreement_id) {
      getLeadDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement_id]);
  return (
    <Box>
      <Grid container spacing={12}>
        <Grid item xs={9}>
          {loader ? (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {/* <Box className={classes.parent}>
                    {list?.list?.map((val, index) => {
                      return (
                        <Box className={classes.treeBox}>
                          <Box className={auth?.auth?.auth?.language === "ar" ? classes.borderRtl : classes.border}>
                            <Box
                              className={
                                val?.assignee !== null
                                  ? auth?.auth?.auth?.language === "ar" ? classes.avatarRtl : classes.avatars
                                  : auth?.auth?.auth?.language === "ar" ? classes.avatarRtl : classes.avatars1
                              }
                            >
                              {val?.image}
                            </Box>
                            <CardDetails data={val} t={t} />
                          </Box>
                        </Box>
                      );
                    })}
                  </Box> */}
                  {
                    list?.list?.length > 0 ?
                      <Timeline sx={{ padding: "0px" }}>
                        {
                          list?.list?.map((val, index, length) => {
                            return (
                              <TimelineItem sx={{ padding: "0px" }}>
                                <TimelineSeparator>
                                  <TimelineDot className={classes.timeLineDot}>
                                    <Box
                                    // className={
                                    //   val?.assignee !== null
                                    //     ? auth?.auth?.auth?.language === "ar" ? classes.avatarRtl : classes.avatars
                                    //     : auth?.auth?.auth?.language === "ar" ? classes.avatarRtl : classes.avatars1
                                    // }
                                    >
                                      {val?.image}
                                    </Box>
                                  </TimelineDot>
                                  {length?.length - 1 !== index && <TimelineConnector className={classes.timeLineConnector} />}
                                </TimelineSeparator>
                                {
                                  auth?.auth?.auth?.language === "ar" ?
                                    <TimelineOppositeContent>
                                      <Box sx={{ marginTop: "-16px" }}>
                                        <CardDetails data={val} t={t} />
                                      </Box>
                                    </TimelineOppositeContent> :
                                    <TimelineContent>
                                      <Box sx={{ marginTop: "-16px" }}>
                                        <CardDetails data={val} t={t} />
                                      </Box>
                                    </TimelineContent>
                                }
                              </TimelineItem>
                            )
                          })
                        }

                      </Timeline>
                      :
                      <Box className={classes.noDataBox}>
                        <NoDataFound />
                      </Box>
                  }
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={3}>
          {
            loader ?
              Array.apply(null, Array(3)).map((value, index) => (
                <Grid key={index} item style={{ padding: "12px" }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Skeleton
                        variant="rectangular"
                        height={'95px'}
                        style={{ borderRadius: "4px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))

              :
              <Box className={classes.relativeCard}>
                <Box className={classes.parentCard}>
                  <Box>
                    {owner(list?.data).map((x) => {
                      return (
                        <CardComponent
                          title={x?.title}
                          subTitle={x?.subtitle}
                          image={x?.image}
                          color={x?.color}
                          backgroundColor={x?.backgroundColor}
                        />
                      );
                    })}
                  </Box>
                  <Box height={"8px"} />
                  <Box>
                    {
                      list?.data?.duration &&
                      <>
                        {ownerDay(list?.data).map((x) => {
                          return (
                            <CardComponent
                              title={x?.title}
                              subTitle={x?.subtitle}
                              image={x?.image}
                              color={x?.color}
                              backgroundColor={x?.backgroundColor}
                            />
                          );
                        })}</>
                    }

                  </Box>
                  <Box height={"8px"} />
                  {
                    is_overall && <>
                      {ownerStatus(list?.data)?.map((x) => {
                        return (
                          <Box >
                            {x?.is_active && (
                              <CardComponent
                                title={x?.title}
                                subTitle={x?.subtitle}
                                image={x?.image}
                                color={x?.color}
                                backgroundColor={x?.backgroundColor}
                                is_divider={x?.is_divider}
                              />
                            )}
                          </Box>
                        );
                      })}</>
                  }

                </Box>
              </Box>
          }


        </Grid>
      </Grid>
    </Box>
  );
};
