import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold, remCalc } from "../../utils";

export const generalCardStyles = makeStyles((theme) => ({
  generalCard: {
    borderRadius: "6px",
    display: "flex",
    padding: "8px",
    border: "1px solid" + theme.palette.border.secondary,
    margin: "12px 0px 0px 0px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  productCard: {
    borderRadius: "6px",
    display: "flex",
    padding: "8px",
    border: "1px solid" + theme.palette.border.secondary,
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatar: {
    borderRadius: "4px",
    marginInlineEnd: "12px",
    backgroundColor: "#F2F4F7"
  },

  imageText: {
    display: "flex",
    alignItems: "center",
  },

  image: {
    "& img": {
      width: "40px",
      height: "40px",
      borderRadius: "4px",
      marginRight: "16px",
    },
  },

  text: {
    fontSize: "0.875rem",
    fontWeight: 800,
    color: theme.typography.color.primary,
  },

  addBtn: {
    width: "76px",
    height: "28px",
    fontSize: "0.75rem",
    fontWeight: 700,
    color: theme.typography.status.primary,
    border: "1px solid" + theme.palette.border.primary,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  cart: {
    width: "76px",
    height: "28px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid" + theme.palette.border.primary,
    borderRadius: "4px",
    cursor: "pointer",
    "& button": {
      minWidth: 0,
      padding: "0px 8px !important",
      "&:hover": {
        backgroundColor: "#ffffff !important",
      },
    },
  },

  cartText: {
    fontSize: "0.75rem !important",
    fontWeight: 700,
  },
  title: {
    display: "flex",
  },
  clipIcon: {
    marginLeft: "8px",
    "& svg": {
      width: "15.8px",
      height: "16px",
      color: "#CED3DD",
    },
  },
  toggle: {
    marginRight: "0px !important",
  },
  tooltipTitle: {
    fontSize: "0.875rem",
    fontWeight: 800,
    color: theme.typography.color.primary,
  },
  tooltipSubTitle: {
    fontSize: "0.75rem",
    fontWeight: 600,
    color: "#CED3DD",
  },
  subText: {
    display: "flex",
    alignItems: "center",
  },
  items: {
    "& p": {
      fontSize: "0.75rem",
      color: theme.typography.color.tertiary,
      fontWeight: 700,
    },
  },
  dot: {
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.primary,
    marginLeft: "6px",
  },
  viewText: {
    marginLeft: "6px",
    cursor: "pointer",
    fontSize: "0.75rem",
    color: theme.typography.status.primary,
    fontWeight: 700,

  },
  inspectionItemCard: {
    margin: "20px 20px",
  },
  product: {
    "& p": {
      fontSize: "0.75rem",
      fontWeight: 700,
      color: theme.typography.color.tertiary,
    },
  },
  AvatarGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "12px",
  },
  AvatarImg: {
    justifyContent: "flex-end",
    "& .MuiAvatar-root": {
      height: "32px",
      width: "32px",
      fontSize: "0.75rem",
    },
  },
  upload: {
    "& p": {
      fontSize: "0.75rem",
      fontStyle: "italic",
      fontWeight: 500,
      color: theme.typography.color.tertiary,
    },
  },
  textGroup: {
    marginTop: "30px",
  },
  subTexts: {
    fontSize: "0.75rem",
    fontStyle: "italic",
    fontWeight: 700,
    color: theme.typography.color.tertiary,
  },
  hypen: {
    fontSize: "0.875rem",
    fontWeight: 900,
    color: theme.typography.color.primary + "!impotant",
  },
  tab: {
    padding: "12px"
  },
  itemTitle: {
    fontSize: remCalc(14),
    fontFamily: Bold,
    color: theme.typography.color.primary
  },
  itemspacing: {
    width: "6px",
    height: "6px",
    backgroundColor: theme.typography.color.tertiary,
    borderRadius: "50%",
    marginInlineStart: "5px !important"
  },
  itemDataTitle: {
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: theme.typography.color.tertiary
  },
  itemDatasubTitle: {
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: theme.typography.color.secondary
  },
  itemDatasubvalue: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.secondary,
    marginLeft: "5px",
    marginInlineStart: "5px"
  },
  downloadbtn: {
    backgroundColor: "#FFFFFF",
    color: theme.typography.color.secondary,
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    height: "30px",
    "&:hover": {
      backgroundColor: "#ffffff !important",
    },
  },
  downloadbtntwo: {
    backgroundColor: "#DBEBFF",
    color: "#316AB4",
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    // border: "1px solid #E4E8EE",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#DBEBFF !important",
    },
  },
  filtermenuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 16px #00000014",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
      marginTop: "6px",
      //   width:"170px"
    },
  },
  filterOptionLabel: {
    fontSize: "0.75rem",
    color: "#091B29",
    fontFamily: Bold
  },
  menuItemlist: {
    padding: "5px 12px !important",
    width:"95px"
  },
}));



