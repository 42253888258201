import * as React from "react"
const CameraIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12.632} height={12} {...props}>
    <path
      fill="#091b29"
      d="M5.2 0a1.422 1.422 0 0 0-1.227.7l-.694 1.19H2.053A2.06 2.06 0 0 0 0 3.947v6A2.06 2.06 0 0 0 2.053 12h8.526a2.06 2.06 0 0 0 2.053-2.053v-6a2.06 2.06 0 0 0-2.053-2.053H9.351L8.657.7A1.422 1.422 0 0 0 7.43 0Zm0 .947h2.23a.472.472 0 0 1 .409.235l.831 1.425a.474.474 0 0 0 .409.235h1.5a1.1 1.1 0 0 1 1.105 1.105v6a1.1 1.1 0 0 1-1.105 1.105H2.053A1.1 1.1 0 0 1 .948 9.947v-6a1.1 1.1 0 0 1 1.105-1.105h1.5a.474.474 0 0 0 .409-.235l.831-1.425A.472.472 0 0 1 5.2.947Zm1.114 2.842a2.842 2.842 0 1 0 2.842 2.842 2.849 2.849 0 0 0-2.84-2.842Zm0 .947a1.895 1.895 0 1 1-1.895 1.895 1.888 1.888 0 0 1 1.897-1.894Z"
      data-name="icons8-camera (1)"
    />
  </svg>
)
export default CameraIcon
