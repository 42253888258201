import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

export const NewLoader = ({ minusHeight = "0px" }) => {
    const useStyles = makeStyles((theme) => ({
        box: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#fff",
            height: `calc(100vh - ${minusHeight})`
        },

    }))
    const classes = useStyles()
    return (
        <>
            <Box className={classes.box}>
                <img src='/images/loader.gif' alt='' />
            </Box>
        </>
    )
}