import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from 'react';
import { SemiBold } from '../../utils';
export const btnStyles = makeStyles((theme) => ({
    input: {
        width: "60px",
        border: "none",
        outline: "none"
    },
    custom: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,

    },
    check: {
        color: "#5078E1",
        marginTop: "8px",
        marginRight: "4px",
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius
    },
    chip: {
        backgroundColor: "#5078E1",
        boxShadow: 'none',
        fontSize:"0.75rem",
        color: "white",
        borderRadius: "4px",
        fontFamily: SemiBold,
        height: "45px"
    }
}))
export const CustomBtn = ({
    handleDelete = () => false,
    onChange = () => false,
    value = "",
    setShow = () => false,
    height = false,
    customValue = null,
    is_filter=false
}) => {
    const classes = btnStyles()

    const [show1, setShow1] = React.useState(is_filter ? !value ? false : (value === "Custom" ? false : ((value !== 15 || value !== 30) ? true : false)) : value ? parseInt(value) <= customValue ?? 31 ? false : true : false)
    const [day, setDay] = React.useState("")
    return (
        show1 ?
            <Chip deleteIcon={<CloseRoundedIcon style={{ color: "white", fontSize:"1rem" }} />} label={`${value} Days`} className={classes.chip}
                onDelete={() => {
                    handleDelete()
                    setShow1(false)
                    setShow(true)
                }} /> :
            <Box display='flex' alignItems="center" className={classes.custom}>
                <Box marginLeft="8px">
                    <input type="number"
                        value={day} onChange={(e) => setDay(e.target.value)} placeholder="Enter Custom Days" className={classes.input} />
                </Box>
                <Box marginLeft="4px"
                    onClick={() => {
                        if (day) {
                            onChange(day)
                            setShow1(true)
                        }
                        // setShow(true)
                    }}>
                    <CheckBoxRoundedIcon className={classes.check} />
                </Box>
            </Box>
    )
}