import { Grid, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { useWindowDimensions } from "../../utils";
import { vacancyStyles } from "./styles";
import { UnitTable } from "./unitTable";
export const DetailCard = (props) => {
    const classes = vacancyStyles()
    const size = useWindowDimensions()
    const modules = { toolbar: false };
    return (
        <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "24px" }}>
            {/* property details */}
            <Grid container className={classes.card}>
                <Grid item xs={12} sm={12} md={2} lg={2} >
                    <div className={classes.imgDiv}>
                        <img src={(props?.data?.logo && props?.data?.logo !== "") ? props?.data?.logo : "/images/imagesproperty.svg"} alt="" className={classes.unitImg} />
                        <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.property_no}&nbsp;&nbsp;</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2} >
                    <Typography className={classes.title} marginBottom={"14px"}>PROPERTY DETAILS</Typography>

                    <Grid container >
                        <Grid item md={12} lg={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Typography className={classes.heading}>Company Name</Typography>
                                    <Typography className={classes.sub}>{props?.data?.company?.name ? props?.data?.company?.name : " - "}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>Property Name</Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.name ? props?.data?.name : " - "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>Property Type</Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.property_groupByID?.group_name ? props?.data?.property_groupByID?.group_name : "- "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>Property Purpose</Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.property_purpose ? props?.data?.property_purpose : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.heading}>Total Units</Typography>
                                    <Typography className={classes.sub}>
                                        {props?.data?.unit_count.length > 0 ? props?.data?.unit_count?.[0]?.count_id : "-"}
                                    </Typography>
                                </Grid>

                                <Grid item xs={1}>
                                    <Typography className={classes.heading}>Status</Typography>
                                    <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In active"}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.heading}> Property Description</Typography>
                                    {props?.data?.description &&
                                        <ReactQuill
                                            readOnly
                                            theme="bubble"
                                            value={props?.data?.description ?? ""}
                                            modules={modules}
                                            className={classes.sub}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            {/* unit table */}
            <Grid container>
                <Grid item xs={12}>
                    <UnitTable />
                </Grid>
            </Grid>
        </div>
    )
}