import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../../../utils";
export const useStyles = makeStyles((theme) => ({
    file_upload_div: {
        backgroundColor: "#F5F7FA",
        border: "2px dashed #E4E8EE",
        borderRadius: "4px",
        padding: "28px",
    },
    upload_file_text: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "#404E61",
    },
    upload_file_text_cta: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
        color: "#5078E1",
        textDecoration: "underline",
        cursor: "pointer",
    },
    upload_file_criteria_label: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: "#4E5A6B",
    },
    upload_file_criteria_value: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: "#98A0AC",
    },
}));