import { Box, Grid, Stack, Typography } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import React from 'react'
import { DailyCalendarStyle, eventListStyle } from './style'
import { SwitchColors } from './weeklyCalendar'


export const DailyCalendar = ({list = [], handleSelected = () => false, openDeclinePopup = () => false, t = () => false }) => {
    const classes = DailyCalendarStyle()
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));

    const hours = [
        { label: "00:00-04:00", start: "00", end: "04" },
        { label: "04:00-08:00", start: "04", end: "08" },
        { label: "08:00-12:00", start: "08", end: "12" },
        { label: "12:00-16:00", start: "12", end: "16" },
        { label: "16:00-20:00", start: "16", end: "20" },
        { label: "20:00-24:00", start: "20", end: "24" },
    ]

    return (
        <Box className={classes.calRow}>
            {
                hours?.map(e => {
                    return (
                        <>
                            <Grid container sx={{borderTop:"1px solid #E4E8EE"}}>
                                <Grid item md={1} sm={1} className={classes.timeCell}>
                                    <Box marginTop="12px">
                                        {e?.label}
                                    </Box>
                                </Grid>
                                <Grid item md={11} sm={11} className={classes.eventCell} p={1} sx={{ overflow: "auto" }}>
                                    {
                                        list?.[0]?.calendarData?.filter((item) => (item.start.slice(11, 13) >= e.start && item.end.slice(11, 13) <= e.end))?.map((data) => {
                                            return (
                                                data?.activity?.map((x) => {
                                                    return (
                                                        // moment.utc(x?.date_time).format("HH") === e?.start &&
                                                        <>
                                                            {
                                                                data?.activity?.length === 1 ?

                                                                    <HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <CustomToolTip t={t} data={x} openDeclinePopup={openDeclinePopup}  handleSelected={handleSelected}/>
                                                                            </React.Fragment>
                                                                        }
                                                                        arrow className={classes.customTooltip}
                                                                    >
                                                                        <Box className={classes.contentbox} style={{ backgroundColor: SwitchColors(x?.category)?.backgroundColor }}>
                                                                            <Stack direction="row" spacing={1} alignItems="center" p={1}>
                                                                                {/* <img src={SwitchActivityIcons(x?.activity_name)} alt="icon" /> */}
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}>
                                                                                    {x?.title}
                                                                                </Typography>
                                                                                <div className={classes.dot} style={{ backgroundColor: SwitchColors(x?.category)?.color }}></div>
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}>{x?.category}</Typography>
                                                                                {/* <div className={classes.dot} style={{ backgroundColor: SwitchColors(x?.category)?.color }}></div>
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}>{x?.property_name}</Typography> */}
                                                                                <div className={classes.dot} style={{ backgroundColor: SwitchColors(x?.category)?.color }}></div>
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}> {x?.no_of_builds} {x?.no_of_builds>1?t("Assigned Roles"):t("Assigned Role")}</Typography>
                                                                                <div className={classes.dot} style={{ backgroundColor: SwitchColors(x?.category)?.color }}></div>
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}>{`${t("Created by")} ${x?.created_by_first_name} ${t("on")} ${moment(x?.created_at).format("DD MMM YYYY")}`}</Typography>
                                                                            </Stack>
                                                                        </Box>

                                                                    </HtmlTooltip> :


                                                                    < HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <CustomToolTip data={x} openDeclinePopup={openDeclinePopup} t={t} handleSelected={handleSelected}/>
                                                                            </React.Fragment>
                                                                        }
                                                                        arrow className={classes.customTooltip}
                                                                    >
                                                                        <Box className={classes.contentbox} style={{ backgroundColor: SwitchColors(x?.category)?.backgroundColor }} mt={1} mb={1}>
                                                                            <Stack direction="row" spacing={1} alignItems="center" p={1}>
                                                                                {/* <img src={SwitchActivityIcons(x?.activity_name)} alt="icon" /> */}
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}>
                                                                                    {x?.title}
                                                                                </Typography>
                                                                                <div className={classes.dot} style={{ backgroundColor: SwitchColors(x?.category)?.color }}></div>
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}>{x?.category}</Typography>
                                                                                {/* <div className={classes.dot} style={{ backgroundColor: SwitchColors(x?.category)?.color }}></div>
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}>{x?.property_name}</Typography> */}
                                                                                <div className={classes.dot} style={{ backgroundColor: SwitchColors(x?.category)?.color }}></div>
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}> {x?.no_of_builds>1?t("Assigned Roles"):t("Assigned Role")} </Typography>
                                                                                <div className={classes.dot} style={{ backgroundColor: SwitchColors(x?.category)?.color }}></div>
                                                                                <Typography className={classes.mobileNo} style={{ color: SwitchColors(x?.category)?.color }}>{`${t("Created by")} ${x?.created_by_first_name} ${t("on")} ${moment(x?.created_at).format("DD MMM YYYY")}`}</Typography>
                                                                            </Stack>
                                                                        </Box>

                                                                    </HtmlTooltip>



                                                            }
                                                        </>
                                                    )
                                                })
                                            )
                                        })}
                                </Grid>
                            </Grid>
                        </>
                    )
                })
            }
        </Box >
    )
}
const CustomToolTip = ({ t = () => false, data = {}, startTime = null, endTime = null,handleSelected=()=>false }) => {
    const classes = eventListStyle()
    return (
        <Box p={1}>
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <img src={"/images/announcement.svg"} width="40px" height="40px" alt="icon" className={classes.announceImg} />
            </Grid>
            <Grid item xs={10}>
                <Typography className={classes.ttTitle}> {data?.title} </Typography>
                <Box ml={1} mt={1}>
                    <Typography className={classes.ttsecTitle}>{data?.category}</Typography>
                    <Typography className={classes.ttassigned_by}>{`${t("Created on")} ${moment(data.created_at).format("DD MMM YYYY")} ${t("by")} ${data?.created_by_first_name}`}</Typography>
                    <Typography className={classes.moreLabel} onClick={() => {
                        handleSelected(data)
                    }}> {t("View More")}</Typography>
                </Box>
            </Grid>
        </Grid>
    </Box>
    )
}