import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../utils";

export const StepsStyle = makeStyles((theme) => ({
    addBtn: {
        color: theme.palette.info.main,
        fontSize: "0.875rem",
        fontFamily: Bold,
        background: theme.palette.info.light,
        padding: 8,
        borderRadius: 6,
        width: "100%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    boldHeader: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
        marginLeft: "12px",
        marginTop: "4px"
    },

    header: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold
    },
    selectedValue: {
        fontSize: "0.875rem",
        fontFamily: SemiBold,
    },
    endAdornment: {
        display: "flex",
        borderLeft: "1px solid #E4E8EE",
        width: "100px",
        height: "42.13px",
        alignItems: "center",
        cursor: "pointer",
        paddingLeft: 8
    },
    durationLabel: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        marginBottom: "5px",
    },
    optionAvatar: {
        height: 35,
        width: 35,
        borderRadius: 4,
        marginInlineEnd: "8px"

    },
    optionHeader: {
        fontSize: "0.75rem",
        fontFamily: Bold,
        color: "#091B29"
    },
    optionSubTitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        whiteSpace: "noWrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginLeft: "0px !important"
    },
    selectParent: {
        padding: "5px",
        borderRadius: 6,
        margin: "5px"
    },
    AccountImg1: {
        borderRadius: theme.palette.borderRadius,
        // padding: "12px 14px 10px 14px",
        // backgroundColor: "#F2F4F7",
    },
    roundedAvatar: {
        height: 35,
        width: 35,
        borderRadius: "50px",
        marginInlineEnd: "8px"
    },
    stepCount: {
        height: 22,
        width: 22,
        borderRadius: "50%",
        color: theme.typography.color.tertiary,
        background: theme.palette.background.tertiary,
        fontSize: "0.75rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    stepLabel: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    selectedListCard: {
        background: theme.palette.background.secondary,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 8,
        width: "100%"
    },
    reactQuil: {
        "& .ql-toolbar.ql-snow": {
            borderRadius: "8px 8px 0px 0px"
        },
        "& .ql-container.ql-snow": {
            borderRadius: "0px 0px 8px 8px"
        },
        "& .ql-editor": {
            textAlign: "start",
            fontSize: "0.875rem",
            fontFamily: Bold,
            color: "#091B29",
            height: "150px",
            padding: "8px"
        },
        "& .ql-container.ql-bubble.ql-disabled": {
            border: `1px solid ${theme.palette.border.primary}`,
            borderRadius: "8px",
            padding: 8
        }
    },
    headingText: {
        fontSize: "0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    }
}))