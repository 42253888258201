import React from 'react';
import { ToggleButtonComponent } from '../../togglebutton';
import { CustomTypography } from '../customTypography';
import useTheme from '@mui/styles/useTheme';
import { Bold } from '../../../../../utils';


export const ToggleButton = (props) => {

    const {
        label = "",
        options = [],
        value,
        onChange = null,
        isMulti,
        fullWidth = false,
        buttonStyle = {},
        buttonGroupStyle = {},
        error = false,
        errorText = "",
        required = false,
    } = props;

    const theme = useTheme();

    return (
        <div>
            {label &&
                <CustomTypography fontFamily={Bold} marginBottom={16}>
                    {label}
                    {required && <CustomTypography component={"span"} marginLeft={theme.spacing(1)} color={theme.palette.error.main} fontFamily={Bold}>*</CustomTypography>}
                </CustomTypography>
            }
            <ToggleButtonComponent
                options={options}
                value={value}
                onChange={(value) => onChange(value)}
                isMulti={isMulti}
                fullWidth={fullWidth}
                buttonStyle={{ ...buttonStyle }}
                buttonGroupStyle={{ ...buttonGroupStyle }}
            />
            {error &&
                <CustomTypography fontFamily={Bold} marginTop={theme.spacing(1)} color={theme?.palette?.error?.main} fontSize={12}>{errorText}</CustomTypography>
            }
        </div>
    )
}