import * as React from "react"

export const Budget = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={21.999} {...props}>
        <g data-name="Group 6113">
            <g data-name="Path 3644" fill="#e4e8ee">
                <path d="M21.25 16.666H.751V5.333H21.25v11.333Z" />
                <path
                    d="M1.501 6.083v9.833H20.5V6.083H1.501m-1.5-1.5H22v12.833H.001V4.583Z"
                    fill={props?.color ?? "#4e5a6b"}
                />
            </g>
            <path
                data-name="Path 3652"
                d="M18.333 15.583a1.834 1.834 0 0 1 1.834-1.834 1.834 1.834 0 0 1 1.834 1.834 1.834 1.834 0 0 1-1.834 1.833 1.834 1.834 0 0 1-1.834-1.833Zm-18.332 0a1.834 1.834 0 0 1 1.834-1.834 1.834 1.834 0 0 1 1.834 1.834 1.834 1.834 0 0 1-1.834 1.833 1.834 1.834 0 0 1-1.834-1.833Zm18.332-9.167a1.833 1.833 0 0 1 1.834-1.832 1.833 1.833 0 0 1 1.834 1.832 1.834 1.834 0 0 1-1.834 1.834 1.834 1.834 0 0 1-1.834-1.835Zm-18.332 0a1.833 1.833 0 0 1 1.834-1.832 1.833 1.833 0 0 1 1.834 1.832A1.834 1.834 0 0 1 1.835 8.25 1.834 1.834 0 0 1 .001 6.415Z"
                fill={props?.color ?? "#4e5a6b"}
            />
            <path
                data-name="Path 3653"
                d="M8.251 11A2.75 2.75 0 0 1 11 8.25 2.75 2.75 0 0 1 13.749 11 2.749 2.749 0 0 1 11 13.75 2.749 2.749 0 0 1 8.251 11Zm7.332 0a.917.917 0 0 1 .918-.917.916.916 0 0 1 .916.917.916.916 0 0 1-.916.917.917.917 0 0 1-.917-.917Zm-11 0a.916.916 0 0 1 .916-.917.917.917 0 0 1 .918.917.917.917 0 0 1-.918.917.916.916 0 0 1-.915-.917Z"
                fill={props?.color ?? "#4e5a6b"}
            />
        </g>
    </svg>
)


