export const Terminations = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Agreement Termination">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 93625"
                    d="m23.398 14.518-3.837-3.289a.274.274 0 0 0-.232-.061l-.368.074a2.178 2.178 0 0 1-1.646-.326l-1.355-.9a.824.824 0 0 1 .878-.091l1.327.663a.274.274 0 0 0 .368-.368l-1.1-2.192a.274.274 0 0 0-.284-.149l-.94.134a.82.82 0 0 1-.854-.449l2.022-.225 2.925 2.393.016.012 2.74 1.918.314-.449-2.732-1.912-3.007-2.46a.274.274 0 0 0-.2-.06l-2.466.274a.274.274 0 0 0-.236.339l.034.137a.875.875 0 0 0 .021.073l-1.874-.8a.274.274 0 0 0-.2 0l-1.892.71a1.368 1.368 0 0 0-.615.461l-1.421 1.9-2.149 1.343.291.465 2.192-1.37a.274.274 0 0 0 .074-.068l1.452-1.936a.821.821 0 0 1 .369-.277l1.789-.671 1.547.663a.764.764 0 0 1-.715.292l-1.06-.151a.274.274 0 0 0-.277.135l-1.1 1.918a.274.274 0 0 0 .325.4l1.564-.521a.826.826 0 0 1 .988.4l-1.651.708a.274.274 0 0 0-.106.081l-.022.027a2.2 2.2 0 0 1-2.405.71h-.013a.274.274 0 0 0-.26.048l-3.014 2.466.347.424 2.019-1.674v7.743h-.822a.274.274 0 0 0-.274.274v.548a1.372 1.372 0 0 0 1.37 1.37h10.414a1.372 1.372 0 0 0 1.37-1.37v-8.644l2.014 1.726ZM16.29 8.555l.744-.106.635 1.28-.591-.3a1.364 1.364 0 0 0-.81-.129l.082-.246a.274.274 0 0 0-.032-.239l-.168-.252a.717.717 0 0 0 .14-.008Zm-.94-.206.442.663-.2.593a1.364 1.364 0 0 0-.265.294l-.011.017a.273.273 0 0 0-.042.2l.256 1.537-.488.732a.274.274 0 0 0-.026.254l.5 1.25-.8 1.475.281-1.686a.274.274 0 0 0-.051-.209l-.7-.932.7-.932a.274.274 0 0 0 .041-.251l-.274-.822a.274.274 0 0 0-.015-.036l-.123-.246a1.354 1.354 0 0 0-.1-.165l.494-.989a.274.274 0 0 0 .015-.209l-.187-.561a1.312 1.312 0 0 0 .178-.336 1.365 1.365 0 0 0 .375.361ZM12.913 9.56l-.884.295.648-1.134.876.125a1.309 1.309 0 0 0 .795-.137l.08.241-.37.741a1.373 1.373 0 0 0-1.144-.131Zm7.566 12.292a.822.822 0 0 1-1.644 0v-.548a.274.274 0 0 0-.274-.274H9.517v-8.192l.334-.273a2.752 2.752 0 0 0 2.851-.9l1.582-.678.134.4-.736.981a.274.274 0 0 0 0 .329l.752 1-.529 3.173a.274.274 0 0 0 .511.176l1.644-3.014a.274.274 0 0 0 .014-.233l-.495-1.236.468-.7a.274.274 0 0 0 .042-.2l-.17-1.02 1.09.727a2.722 2.722 0 0 0 2.06.409l.238-.048 1.172 1.008Z"
                />
                <path data-name="Path 93626" d="M16.368 12.534h3.014v.548h-3.014Z" />
                <path data-name="Path 93627" d="M16.642 13.904h2.74v.548h-2.74Z" />
                <path data-name="Path 93628" d="M10.613 13.904h3.014v.548h-3.014Z" />
                <path data-name="Path 93629" d="M10.613 15.274h2.74v.548h-2.74Z" />
                <path data-name="Path 93630" d="M16.094 15.274h3.289v.548h-3.289Z" />
                <path data-name="Path 93631" d="M15.272 16.645h4.111v.548h-4.111Z" />
                <path data-name="Path 93632" d="M16.094 18.563h2.74v.548h-2.74Z" />
                <path data-name="Path 93633" d="M10.613 16.645h2.74v.548h-2.74Z" />
                <path data-name="Path 93634" d="M16.642 19.659h1.644v.548h-1.644Z" />
                <path data-name="Path 93635" d="M11.161 18.563h2.74v.548h-2.74Z" />
                <path data-name="Path 93636" d="M11.709 19.659h1.644v.548h-1.644Z" />
            </g>
            <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
