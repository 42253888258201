import React from 'react'
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components'
import { FinalSettlementHeading, FinalSettlementPath, finalSettlementListOptions } from '../../utils/finalSettlement'
import styled from '@mui/material/styles/styled';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import { FinalSettlementStyle } from './style';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { NetWorkCallMethods, accessCheckRender, getCompanyOption, getRoutePermissionNew } from '../../utils';
import { AuthContext, BackdropContext } from '../../contexts';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';

const CustomPaper = styled('div')(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 144px)`,
    overflow: "hidden",
    margin: "12px",
    position: "relative"
}))

const FinalSettlement = (props) => {
    const { loading, handleLoading, t = () => false } = props;
    const classes = FinalSettlementStyle()
    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [permission, setPermission] = React.useState({})

    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [fiterDrawer, setFiterDrawer] = React.useState(false)
    const [filterData, setFilterData] = React.useState({ status: ["Terminated"] })

    const [list, setList] = React.useState({
        count: "",
        data: []
    })

    const history = useHistory()
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)
    const debounce = UseDebounce();

    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        //eslint-disable-next-line
    }, [auth])

    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getFinalSettlementList(company?.selected?.value, 0, 10, "")
        }
    }

    const handlePagination = (e) => {
        setPage(e)
        let offset = (e - 1) * limit
        getFinalSettlementList(selectedCompany?.value, offset, limit, "")
    }

    const handleChangeLimit = (e) => {
        setLimit(e)
        setPage(1)
        getFinalSettlementList(selectedCompany?.value, 0, limit, "")
    }

    const handleSearch = (value) => {
        setSearchText(value)
        debounce(() => {
            getFinalSettlementList(selectedCompany?.value, 0, limit, value)
        }, 800)
    }

    const handleIcon = (type, data) => {
        if (type === "view") {
            history.push({
                pathname: Routes.finalSettlementView,
                state: {
                    data: data,
                    selectedCompany: selectedCompany
                }
            })
        }
    }


    const getFinalSettlementList = (company_id = selectedCompany?.value, offset, limit, searchText = "", filter_data) => {
        // backdrop.setBackDrop({
        //     ...backdrop,
        //     open: true,
        //     message: "",
        // });
        const payload = {
            company_id: company_id,
            search: searchText,
            offset: offset,
            limit: limit,
            status: filter_data?.status ?? filterData?.status
        }
        NetworkCall(
            `${config.api_url}/final_settlement/agreement_list`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            // backdrop.setBackDrop({
            //     ...backdrop,
            //     open: false,
            //     message: "",
            // });
            const list = res?.data?.data?.agreement_list?.map((e) => {
                return {
                    agreement_no: e?.agreement_no,
                    account_no: e?.account_no,
                    account_name: e?.account_name,
                    generated_date: moment(e?.generate_date).format("DD MMM YYYY"),
                    start_date: moment(e?.start_date).format("DD MMM YYYY"),
                    end_date: moment(e?.end_date).format("DD MMM YYYY"),
                    status: e?.status,
                    agreement_id: e?.id
                }
            })
            setList({ ...list, count: res?.data?.data?.count, data: list })
            handleLoading(false)
        }).catch(() => {
        });
    }


    const handleCompanyChange = (e) => {
        setSelectedCompany(e)
        getFinalSettlementList(e?.value)
    }

    const handleFilterApply = (value) => {
        setFilterData(value)
        getFinalSettlementList(selectedCompany?.value, 0, limit, "", value)
    }
    const render = () => {
        return (
            <>
                <Subheader hideBackButton={true} title={t("Final Settlement")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) =>
                        handleCompanyChange(e)
                    } />
                <CustomPaper>
                    <Grid container justifyContent="space-between" p={"16px 22px 0px 22px"}>
                        <Grid item xs={4}>
                            {/*search */}
                            <SearchFilter
                                handleChange={handleSearch}
                                value={searchText}
                                placeholder={t("Search")}
                                height='40px'
                                customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} 
                            />
                        </Grid>
                        <Grid item xs={8} alignContent="flex-end">
                            <Stack
                                spacing={2}
                                justifyContent="flex-end"
                                alignItems="center"
                                direction="row">
                                {/*add btn */}
                                {/* {
                                filterData?.contact?.length > 0 ?
                                    <Badge variant="dot" color="primary">
                                        <IconButton className={classes.iconButton} onClick={openfilter}>
                                            <img src="/images/filter.svg" alt="filter" />
                                        </IconButton>
                                    </Badge>
                                    : */}
                                <IconButton className={classes.iconButton} onClick={() => setFiterDrawer(!fiterDrawer)}>
                                    <img src="/images/filter.svg" alt="filter" />
                                </IconButton>
                                {/* } */}


                            </Stack>
                        </Grid>
                    </Grid>
                    <Box p={"0px 18px 18px 18px"}>
                        <TableWithPagination
                            heading={FinalSettlementHeading(t)}
                            rows={list?.data}
                            dataType={[
                                { type: ["text"], name: "agreement_no" },
                                { type: ["text"], name: "account_no" },
                                { type: ["text"], name: "account_name" },
                                { type: ["text"], name: "generated_date" },
                                { type: ["text"], name: "start_date" },
                                { type: ["text"], name: "end_date" },
                                { type: ["status"], name: "status" },
                            ]}
                            path={FinalSettlementPath}
                            showpagination
                            tableType="no-side"
                            handleIcon={handleIcon}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={list?.count}
                            page={page}
                            limit={limit}
                            view={permission?.view}
                            height={`calc(100vh - 290px)`}
                            edit={permission?.edit}
                            delete={permission?.delete} />
                    </Box>

                    <FilterGenerator open={fiterDrawer}
                        onClose={() => setFiterDrawer(!fiterDrawer)}
                        onApply={(value) => handleFilterApply(value)}
                        components={[
                            {
                                component: "toggleButton",
                                value: filterData?.status,
                                options: finalSettlementListOptions(t),
                                isMulti: true,
                                state_name: "status",
                                label: t("Status")
                            },
                        ]} />
                </CustomPaper>
            </>
        )
    }

    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}

export default withNamespaces("finalSettlement")(FinalSettlement)