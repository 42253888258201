import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../utils";

export const categoryCardStyles = makeStyles((theme) => ({
    root: {
        border: "1px solid #E4E8EE",
        padding: "10px 16px 10px 10px",
        borderRadius: "4px",
        cursor: "pointer",
    },
    avatar: {
        borderRadius: "4px",
        boxShadow: "0px 3px 6px #00000014",
        backgroundColor: "#5078e1",
        color: "white",
    },
    text_div: {
        display: "flex",
        width: "75%",
        marginInlineStart: "16px"
    },
    category: {
        color: "#071741",
        fontSize:"1rem",
        fontFamily: Bold,
    },
    description: {
        color: "#4E5A6B",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
    }
}));