import React from "react"

export const RestaurantIcon = () =>(
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_112495" data-name="Group 112495" transform="translate(-154 -2545)">
    <g id="Rectangle_57293" data-name="Rectangle 57293" transform="translate(154 2545)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="32" height="32" stroke="none"/>
      <rect x="0.5" y="0.5" width="31" height="31" fill="none"/>
    </g>
    <path id="icons8-restaurant-building" d="M30.477,12.748l-8.669-6.83a4.05,4.05,0,0,0-5.028,0l-8.669,6.83A5.509,5.509,0,0,0,6,17.1V29.72a3.328,3.328,0,0,0,3.323,3.323h5.539V25.533a4.069,4.069,0,0,1-2.954-3.9V15.351a.739.739,0,1,1,1.477,0v4.8a.37.37,0,0,0,.369.369h1.108a.37.37,0,0,0,.369-.369v-4.8a.739.739,0,0,1,1.477,0v4.8a.37.37,0,0,0,.369.369h1.108a.37.37,0,0,0,.369-.369v-4.8a.739.739,0,0,1,1.477,0v6.278a4.069,4.069,0,0,1-2.954,3.9v7.511h5.17V15.351a.738.738,0,0,1,1.2-.576c.131.1,3.231,2.669,3.231,10.029V25.5a1.908,1.908,0,0,1-.563,1.357l-1.653,1.653v4.536h4.8a3.328,3.328,0,0,0,3.323-3.323V17.1A5.509,5.509,0,0,0,30.477,12.748Z" transform="translate(151 2541.956)" fill="#ff4b4b"/>
  </g>
</svg>


)