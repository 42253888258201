import * as React from "react"
export const TypeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 102940">
      <path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 54905" />
      <path
        fill="#6d80b3"
        d="M14.364 11.886H10a1.274 1.274 0 0 1-1.273-1.273V8.057a1.625 1.625 0 0 1 .624-1.29l1.818-1.428a1.681 1.681 0 0 1 2.025 0l1.819 1.428a1.626 1.626 0 0 1 .624 1.285v2.561a1.274 1.274 0 0 1-1.273 1.273Zm4.964-2.913c-.545 0-.838.31-1.165 1.135-.224.565-.436 1.147-.631 1.647.031.025.065.044.095.071a1.787 1.787 0 0 1 .131 2.5l-1.281 1.431a.455.455 0 0 1-.679-.605l1.25-1.4a.906.906 0 0 0 .061-1.161.873.873 0 0 0-1.253-.114l-1.089.992a4.118 4.118 0 0 1-1.328.925 1.55 1.55 0 0 0-.088.046 1.985 1.985 0 0 0-.987 1.735v2.79a.546.546 0 0 0 .545.545h2.909a.545.545 0 0 0 .545-.545 2.048 2.048 0 0 1 .664-1.538c.47-.4.934-.888 1.677-1.436.54-.4.949-1.284 1.121-3.528.044-.572.173-2.376.173-2.842a.7.7 0 0 0-.67-.648Zm-14.655 0c.545 0 .838.31 1.165 1.135.224.565.436 1.147.631 1.647-.031.025-.065.044-.095.071a1.787 1.787 0 0 0-.131 2.5l1.281 1.431a.455.455 0 0 0 .679-.605l-1.25-1.4a.906.906 0 0 1-.061-1.161.873.873 0 0 1 1.253-.114l1.089.992a4.118 4.118 0 0 0 1.328.925c.027.013.056.028.088.046a1.985 1.985 0 0 1 .987 1.735v2.79a.546.546 0 0 1-.545.545h-2.91a.545.545 0 0 1-.545-.545 2.048 2.048 0 0 0-.664-1.538c-.47-.4-.934-.888-1.677-1.436-.54-.4-.949-1.284-1.121-3.528C4.129 11.9 4 10.096 4 9.63a.7.7 0 0 1 .672-.657Z"
      />
    </g>
  </svg>
)
