export const Enrollments = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 96943">
            <g data-name="Group 96942">
                <g
                    data-name="Group 96941"
                    fill={props?.fill ?? "#c1c5cb"}
                    transform="translate(6.419 6.419)"
                >
                    <path
                        data-name="Path 93625"
                        d="M15.487 0H1.676A1.678 1.678 0 0 0 0 1.676v4.023a1.678 1.678 0 0 0 1.676 1.676H6.17l1.08 4.032-.337-.234a1.172 1.172 0 0 0-1.649 1.6 8.165 8.165 0 0 0 4.006 3.19l.255.95a.335.335 0 0 0 .411.237l4.47-1.2a.335.335 0 0 0 .237-.411l-.295-1.1a3.137 3.137 0 0 0 .21-2.195l-.77-2.873a1.106 1.106 0 0 0-1.76-.577 1.107 1.107 0 0 0-1.8-.385A1.106 1.106 0 0 0 8.7 8.251l-.235-.877h3.858a.335.335 0 1 0 0-.67H8.281a1.106 1.106 0 0 0-2.025-.261 1.114 1.114 0 0 0-.109.261H1.676A1.007 1.007 0 0 1 .67 5.699V1.676A1.007 1.007 0 0 1 1.676.67h13.811a1.007 1.007 0 0 1 1.006 1.006v4.023a1.007 1.007 0 0 1-1.006 1.006h-.151a.335.335 0 0 0 0 .67h.151a1.678 1.678 0 0 0 1.676-1.676V1.676A1.678 1.678 0 0 0 15.487 0ZM6.836 6.779a.436.436 0 0 1 .8.105L9 11.959a.335.335 0 1 0 .648-.174l-.689-2.552a.436.436 0 0 1 .841-.226l.684 2.552a.335.335 0 1 0 .648-.174l-.554-2.067a.436.436 0 0 1 .843-.226l.554 2.066a.335.335 0 0 0 .648-.172L12.3 9.774a.436.436 0 0 1 .843-.225l.77 2.873a2.471 2.471 0 0 1-.215 1.823.335.335 0 0 0-.029.246l.241.9-3.825 1.025-.213-.8a.335.335 0 0 0-.209-.228l-.147-.054a7.491 7.491 0 0 1-3.691-2.93.5.5 0 0 1 .706-.686l1.089.755a.335.335 0 0 0 .515-.362L6.793 7.11a.433.433 0 0 1 .044-.331Z"
                    />
                    <path
                        data-name="Path 93626"
                        d="M9.719 2.346v1.562L8.633 2.173a.335.335 0 0 0-.619.178v2.677a.335.335 0 0 0 .67 0V3.516L9.71 5.151a.365.365 0 0 0 .415.169.38.38 0 0 0 .267-.382V2.346a.335.335 0 1 0-.67 0Z"
                    />
                    <path
                        data-name="Path 93627"
                        d="M3.81 2.346v2.682a.335.335 0 1 0 .67 0V2.346a.335.335 0 1 0-.67 0Z"
                    />
                    <path
                        data-name="Path 93628"
                        d="M6.304 2.681a1 1 0 0 1 .562.172.335.335 0 0 0 .375-.556 1.676 1.676 0 1 0-.937 3.066 1.523 1.523 0 0 0 1.493-1.676.335.335 0 0 0-.335-.335h-.8a.335.335 0 0 0 0 .67h.428a.784.784 0 0 1-.784.67 1.006 1.006 0 1 1 0-2.011Z"
                    />
                    <path
                        data-name="Path 93629"
                        d="M2.517 4.698a.957.957 0 0 1-.621-.224.335.335 0 1 0-.44.506 1.614 1.614 0 0 0 1.061.389 1.106 1.106 0 0 0 1.128-.857 1.01 1.01 0 0 0-.78-1.112 10.317 10.317 0 0 1-.73-.3.186.186 0 0 1-.066-.174.269.269 0 0 1 .213-.215.894.894 0 0 1 .738.171.335.335 0 0 0 .43-.515 1.563 1.563 0 0 0-1.361-.3.943.943 0 0 0-.684.762.852.852 0 0 0 .38.844c.015.01.431.2.847.35.118.043.387.166.352.365a.452.452 0 0 1-.468.3Z"
                    />
                    <path
                        data-name="Path 93630"
                        d="M11.668 2.011a.335.335 0 0 0-.335.335v1.99a.982.982 0 0 0 .5.858 1.079 1.079 0 0 0 .589.17 1.137 1.137 0 0 0 .516-.12 1 1 0 0 0 .6-.909V2.346a.335.335 0 1 0-.67 0v1.988c0 .1-.028.207-.234.31a.466.466 0 0 1-.215.048.414.414 0 0 1-.229-.064c-.161-.1-.193-.188-.193-.293v-1.99a.335.335 0 0 0-.329-.334Z"
                    />
                    <circle
                        data-name="Ellipse 129192"
                        cx={0.335}
                        cy={0.335}
                        r={0.335}
                        transform="translate(13.492 6.704)"
                    />
                </g>
            </g>
            <path
                data-name="Path 93631"
                d="M21.145 8.43h-.579a.335.335 0 0 0-.335.335v2.682a.335.335 0 0 0 .67 0v-.831h.244a1.092 1.092 0 1 0 0-2.184Zm0 1.514h-.241V9.1h.243a.422.422 0 1 1 0 .843Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
        </g>
        <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
    </svg>
)
