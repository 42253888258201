import { Box, Typography } from '@mui/material';
import { AggreementCardBottomStyles } from "./styles";
export const AggreementCardBottom = ({ data = {} }) => {
    const classes = AggreementCardBottomStyles(data)
    return (
        <Box className={classes.cardRoot} >
            <Typography className={classes.title}>{data?.title}</Typography>
            <Typography className={classes.subtitle}>{data?.subtitle}</Typography>
        </Box>
    )
}