import React from "react";
import { withNavBars } from "../../HOCs";
import PricingAppraisal from "./pricingAppraisal";

class PricingAppraisalParent extends React.Component {
  render() {
    return <PricingAppraisal />;
  }
}

const props = {
  boxShadow: false,
};

export default withNavBars(PricingAppraisalParent, props);
