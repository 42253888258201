import { Box, Grid, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React from "react";
import { Bar, Line } from "react-chartjs-2";
import NoDataFound from "../../../assets/noData";
import {
  CustomSelectOptions,
  GroupPieCharts,
  SearchFilter,
  SelectBox, TableWithInfinityScroll
} from "../../../components";
import PieCharts from "../../../components/piechart";
import { loadOptionsApis } from "../../../utils/asyncPaginateLoadOptions";
import { useStylesCreation } from "./style";
import { Bold } from "../../../utils";

export const UtilityGraphComponent = (props) => {
  const { t } = props;
  const classes = useStylesCreation(props);
  const maualResponseTenants = (val) => {
    let result = val?.units?.map((val) => {
      return {
        value: val?.id,
        label: val?.name
      }
    })
    return result;
  }
  const maualResponseUtility = (val) => {
    let result = val?.map((val) => {
      return {
        value: val?.value,
        label: val?.label
      }
    })

    return result;
  }
  const manualResponseMeterReading = (val) => {
    if (val?.data?.length > 0) {
      let result = val?.data?.map((val) => {
        return {
          value: val?.value,
          label: val?.label
        }
      })

      return [{ label: "All Meters", value: null }, ...result];
    }
    else {
      return []
    }
  }
  const lineChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      responsive: true,
    },
    scales: {
      x: {
        grid: {
          // drawBorder: false,
          display: false,
        }
      },
      y: {
        grid: {
          // drawBorder: false,
          display: false,
        }
      }
    },
  }

  return (
    <div className={classes.card}>
      <Box className={classes.flexBoxWithSpace}>
        {props?.isTab ? (
          <Box>
            <TabComponent
              tabList={props?.tabList}
              tabNameState={props?.tabNameState}
              keyType={props?.keyType}
              updateState={props?.updateState}
            />
          </Box>
        ) : (
          <Box>
            {!props?.isSearch &&
              <Typography className={classes.titleFont}>
                {props?.header}
              </Typography>
            }
            {props?.titleTab && (
              <div style={{ margin: props?.margin }}>
                <TabComponent
                  tabList={props?.tabList}
                  tabNameState={props?.tabNameState}
                  keyType={props?.keyType}
                  updateState={props?.updateState}
                />
              </div>
            )}

          </Box>
        )}
        {props?.isSearch &&
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} sm={8} md={8} lg={9}>
              <Typography className={classes.titleFont}>
                {props?.header}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4} lg={3}>

              <Box>
                <SearchFilter label={false} placeholder={t("Search")} handleChange={(value) => props?.handleSearch(value)} value={props?.searchText} color="#FFFFFF" />
              </Box>
            </Grid>
          </Grid>
        }

        {
          props?.isSelect &&
          <Box sx={{ width: "250px" }}>
            <CustomSelectOptions
              placeholder={t("Search Resource")}
              padding="12px"

              loadOptions={(search, array, handleLoading) =>
                loadOptionsApis(
                  "inspection_manager/get_property_resources",
                  {
                    "property_id": props?.property_id,
                  },
                  search,
                  array,
                  handleLoading,
                  "data"
                )
              }
              value={props?.selectedResource}
              isPaginate={true}
              closeIcon={false}
              onChange={(val) => props?.onChangeResource(val)}
            // options={props?.resourceList}
            />
          </Box>
        }
        {
          props?.isZoom &&
          <IconButton onClick={() => props?.onZoomClick(props?.title)}>

            <img
              src="/images/propertdashboard/enlare.png"
              alt="zoom"
              onClick={() => props?.onZoomClick(props?.title)}
            />

          </IconButton>
        }
      </Box >

      <>
        {props?.isTable ? (
          <>
            {/* <InfiniteScroll
              dataLength={props?.tableData?.length ?? ""}
              next={props?.fetchMoreData}
              hasMore={true}
              height={props?.tabelheight}
            > */}
            <TableWithInfinityScroll
              heading={props?.heading}
              rows={props?.tableData ?? []}
              path={props?.path}
              fetchMoreData={props?.fetchMoreData}
              height={props?.tabelheight}
              showpagination={props?.showpagination ?? false}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              tableType="no-side"
              count="2"
              dataType={props?.dataType}
              // height={props?.tabelheight ?? `calc(100vh - 355px)`}
              handlePagination={props?.handlePagination}
              handleChangeLimit={props?.handleChangeLimit}
              handleIcon={props?.handleIcon}
              totalRowsCount={props?.totalRowsCount}
              page={props?.page}
              limit={props?.limit}
              noDataSvg={true}
              view={true}
              edit={true}
              enable_double_click={props?.enable_double_click}
              delete={true}
              data={props?.resourceData}
              selectedBackgroundColor={props?.selectedBackgroundColor}
            />
            {/* </InfiniteScroll> */}
          </>
        ) : (
          <>
            {props?.isImageList ? (
              <Box>
                <ImageList
                  variant="quilted"
                  cols={3}
                  gap={5}
                  sx={{
                    width: "100%",
                    height: props?.imageheight ?? "100%",
                    overflow: "auto",
                  }}
                >
                  {props?.iamgeData?.map((item) => (
                    <ImageListItem key={item.name}>
                      <Box
                        className={classes.dividerBox}
                        style={{ backgroundColor: item?.color }}
                      >
                        <Typography className={classes.dividerBoxText}>
                          {item?.name}
                        </Typography>
                        <Box height={"42px"} />
                        <Typography className={classes.dividerBoxText}>
                          {item?.meter}
                        </Typography>
                      </Box>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            ) : (
              <Grid container spacing={2} className={classes.graphParentFlex}>
                <Grid item xs={12} lg={props?.divider ?? 12}>
                  {props?.line ? (
                    <div style={{ margin: props?.margin }}>
                      {props?.graphData?.datasets?.length > 0 && (
                        <GroupPieCharts
                          data={props?.graphData}
                          scale={props?.scale}
                          indexAxis={props?.indexAxis}
                          line={props?.line}
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      {props?.isBar ? (
                        <Box mt={2}>
                          <Grid item xs={12} md={12} lg={12}
                          >
                            {props?.graphData?.datasets?.length > 0 ? (
                              //   <GroupPieCharts
                              //   data={props?.graphData}
                              //   scale={props?.scale}
                              //   indexAxis={props?.indexAxis}
                              //   datalabels={props?.isDatalabels}
                              //   legend={props?.isLegend}
                              //   height={props?.gheight}
                              //   width={props?.gwidth}
                              // />
                              <Bar data={props?.graphData} options={lineChartOptions} height={props?.gheight} width={props?.gwidth ?? null} />
                            ) :
                              <Grid item xs={12}>
                                <Box alignItems={"center"} display={"flex"} justifyContent={"center"} height={"180px"}>
                                  <NoDataFound />
                                </Box>
                              </Grid>
                            }
                          </Grid>
                        </Box>
                      ) : props?.isUtility ? (
                        <Box>
                          <Grid container spacing={1} mt={0.1}>
                            <Grid item xs={6} sm={props?.isMeterDropdown ? 3 : 4} md={props?.isMeterDropdown ? 3 : 4} lg={props?.isMeterDropdown ? 3 : 4}>
                              <SelectBox
                                isRequired={true}
                                label={""}
                                options={props?.utilityData?.propertyData}
                                value={props?.utilityData?.selected_property}
                                placeholder={t("Property")}
                                iconColor="black"
                                fontFamily={Bold}
                                onChange={(value) => props?.updateState('selected_property', value)}
                              // isReadonly={disable}
                              />
                            </Grid>
                            <Grid item xs={6} sm={props?.isMeterDropdown ? 3 : 4} md={props?.isMeterDropdown ? 3 : 4} lg={props?.isMeterDropdown ? 3 : 4}>
                              <SelectBox
                                isRequired={true}
                                label={""}
                                iconColor="black"
                                loadOptions={(search, array, handleLoading) =>
                                  loadOptionsApis(
                                    `/unit/property_units`,
                                    {
                                      "company_id": props?.selectedCompany,
                                      "property_id": props?.utilityData?.selected_property?.value,
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    maualResponseTenants,
                                  )
                                }
                                fontFamily={Bold}
                                value={props?.utilityData?.selected_unit?.label?.length > 0 ? props?.utilityData?.selected_unit : props?.defaultUtilityUnit}
                                placeholder={props?.defaultUtilityUnit?.length > 0 ? t("Select Unit") : t("No Units")}
                                key={JSON.stringify(props?.utilityData?.selected_property)}
                                isPaginate={true}
                                onChange={(value) => props?.updateState('selected_unit', value)}
                              // isReadonly={disable}
                              />
                            </Grid>
                            <Grid item xs={6} sm={props?.isMeterDropdown ? 3 : 4} md={props?.isMeterDropdown ? 3 : 4} lg={props?.isMeterDropdown ? 3 : 4}>
                              <SelectBox
                                isRequired={true}
                                label={""}
                                iconColor="black"
                                fontFamily={Bold}
                                value={props?.utilityData?.selected_utility?.label?.length > 0 ? props?.utilityData?.selected_utility : props?.defaultUtilityItem}
                                placeholder={props?.defaultUtilityItem?.length > 0 ? t("Select Utility") : t("No Utilities")}
                                isPaginate={true}
                                key={JSON.stringify(props?.utilityData?.selected_unit)}
                                onChange={(value) => props?.updateState('selected_utility', value)}
                                loadOptions={(search, array, handleLoading) =>
                                  loadOptionsApis(
                                    "unit_meter_reading/get_utility",
                                    {
                                      // "search": "",
                                      "unit_id": props?.utilityData?.selected_unit?.value ?? props?.defaultUtilityUnit?.value,
                                    },
                                    search,
                                    array,
                                    handleLoading,
                                    "data",
                                    {},
                                    maualResponseUtility,

                                  )}
                              />
                            </Grid>
                            {props?.isMeterDropdown &&
                              <Grid item xs={6} sm={props?.isMeterDropdown ? 3 : 4} md={props?.isMeterDropdown ? 3 : 4} lg={props?.isMeterDropdown ? 3 : 4}>
                                <SelectBox
                                  isRequired={true}
                                  label={""}
                                  iconColor="black"
                                  fontFamily={Bold}
                                  value={props?.selectedMeter?.label?.length > 0 ? props?.selectedMeter : props?.defaultUtilityMeter}
                                  placeholder={props?.selectedMeter?.length > 0 ? t("Select Meter") : t("No Meters")}
                                  isPaginate={true}
                                  key={JSON.stringify(props?.utilityData?.selected_unit, props?.utilityData?.selected_utility)}
                                  onChange={(value) => props?.handleMeter(value)}
                                  loadOptions={(search, array, handleLoading) =>
                                    loadOptionsApis(
                                      "queries/unit_utilities",
                                      {
                                        // "search": "",
                                        "unit_id": props?.utilityData?.selected_unit?.value ?? props?.defaultUtilityUnit?.value,
                                        utility_id: props?.utilityData?.selected_utility?.value ?? props?.defaultUtilityItem?.value,
                                      },
                                      search,
                                      array,
                                      handleLoading,
                                      "data",
                                      {},
                                      manualResponseMeterReading,

                                    )}
                                />
                              </Grid>
                            }
                          </Grid>
                          <Grid container alignItems={props?.alignItems ? props?.alignItems : "center"} mt={1} spacing={2}>
                            {props?.graphData?.datasets?.length > 0 && (

                              // <GroupPieCharts
                              //   data={props?.graphData}
                              //   //scale={props?.scale}
                              //   //indexAxis={props?.indexAxis}
                              //   line={true}
                              //   width={"350px"}
                              //   height={"150px"}
                              //   datalabels={false}
                              //   legend={false}
                              // />
                              <Grid item xs={12} sm={(props?.selectedMeter !== "" && props?.selectedMeter?.label !== "All Meters")?(props?.isCustomSpacing ? 9.5 : 8):12} md={(props?.selectedMeter !== "" && props?.selectedMeter?.label !== "All Meters")?(props?.isCustomSpacing ? 9.5 : 8):12} lg={(props?.selectedMeter !== "" && props?.selectedMeter?.label !== "All Meters")?(props?.isCustomSpacing ? 9.5 : 8):12}>
                                <Box width={props?.width ? props?.width : 350} height={props?.height ? props?.height : 150}>
                                  <Line data={props?.graphData} options={lineChartOptions} />
                                </Box>
                              </Grid>
                            )}
                            {props?.utilityCards?.length > 0 &&
                              <Grid item xs={12} sm={props?.isCustomSpacing ? 2.5 : 4} md={props?.isCustomSpacing ? 2.5 : 4} lg={props?.isCustomSpacing ? 2.5 : 4}>
                                <Box>
                                  {props?.utilityCards?.map((x) => {
                                    return (
                                      <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                                        <img src={x?.image} alt="current_reading" width={"32px"} height={"32px"} />
                                        < Box >
                                          <Typography className={classes.reading}>{x.reading}</Typography>
                                          <Typography className={classes.utilityTitle}>{x?.title}</Typography>

                                        </Box>
                                      </Stack>

                                    )
                                  })}
                                </Box>
                              </Grid>
                            }
                          </Grid>
                        </Box>
                      ) : (
                        <div>
                          {props?.timeOff &&
                            <Box className={classes.type} mt={1}>
                              <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
                                {/* <Grid item xs={6}> */}
                                <Box className={props?.type === "Paid" ? classes.typebtn : classes.nobtn} onClick={() => props?.onChangeType("Paid")}>
                                  <Typography className={props?.type === "Paid" ? classes.textcontd : classes.textcont}>{t("Paid Time Off")}</Typography>
                                </Box>
                                {/* </Grid> */}
                                {/* <Grid item xs={6}> */}
                                <Box className={props?.type === "Earned" ? classes.typebtn : classes.nobtn2} onClick={() => props?.onChangeType("Earned")}>
                                  <Typography className={props?.type === "Earned" ? classes.textcontd : classes.textcont}>{t("Loss of Pay")}</Typography>
                                </Box>
                                {/* </Grid> */}
                              </Box>
                            </Box>
                          }
                          <div className={classes.graphMargin}>
                            <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
                              {props?.graphData?.length > 0 ?
                                <>
                                  <Grid item xs={12} sm={6} md={6} lg={props?.title1 === "Job Completion History" ? 12 : 5}>
                                    <PieCharts
                                      data={props?.graphData}
                                      radius={props?.innerRadius}
                                      width={160}
                                      isTotal={props?.isTotal}
                                      total={props?.total}
                                      height={180}
                                      t={t}
                                    />
                                  </Grid>
                                  <>
                                    {props?.isPie && (
                                      props?.title1 === "Job Completion History" ?
                                        <Grid item xs={12} sm={6} md={6} lg={props?.divider1 ?? 12} className={props?.title1 === "Job Completion History" ? classes.newGridFlex : props?.title === "Inspection Job Type" ? classes.gridGraphParentIns : classes.gridGraphParent}>


                                          <>
                                            {props?.graphData?.map((x) => {
                                              return (

                                                <Stack direction={"row"} alignItems={"center"} ml={2}>

                                                  <Box
                                                    className={classes.dot}
                                                    style={{ backgroundColor: x?.fill }}
                                                  />
                                                  <Typography
                                                    className={classes.graphDataTitle}
                                                    noWrap
                                                  >
                                                    {x?.type ?? x?.name}
                                                  </Typography>
                                                </Stack>

                                              );
                                            })}
                                          </>

                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={6} md={6} lg={props?.divider1 ?? 6} className={props?.title === "Inspection Job Type" ? classes.gridGraphParentIns : classes.gridGraphParent}>


                                          <Grid container spacing={2}>
                                            {props?.graphData?.map((x) => {
                                              return (
                                                <Grid item xs={props?.title === "Inspection Job Type" ? 6 : props?.title === "Time Off Graph" ? 6 : 12}>
                                                  <Stack direction={"row"} alignItems={"center"} ml={2}>

                                                    <Box
                                                      className={classes.dot}
                                                      style={{ backgroundColor: x?.fill }}
                                                    />
                                                    {props?.timeOff ?
                                                      <Typography
                                                        className={classes.graphDataTitle}
                                                        noWrap
                                                      >
                                                        {x?.type ?? x?.name} - {x.count}
                                                      </Typography>
                                                      : <Typography
                                                        className={classes.graphDataTitle}
                                                        noWrap
                                                      >
                                                        {x?.type ?? x?.name}
                                                      </Typography>}
                                                  </Stack>
                                                </Grid>
                                              );
                                            })}
                                          </Grid>

                                        </Grid>
                                    )}
                                  </>
                                </>
                                :
                                <Grid item xs={12}>
                                  <Box alignItems={"center"} display={"flex"} justifyContent={"center"} height={"180px"}>
                                    <NoDataFound />
                                  </Box>
                                </Grid>
                              }

                            </Grid >
                          </div >
                        </div >
                      )}
                    </>
                  )}
                </Grid >

                {/* pie chart */}

              </Grid >
            )}
          </>
        )
        }
      </>
    </div >
  );
};

// tab component
const TabComponent = (props) => {
  const classes = useStylesCreation(props);
  return (
    <List className={classes.list}>
      {props?.tabList?.map((x) => {
        return (
          <ListItem
            className={
              x === props?.tabNameState ? classes.listText : classes.listText1
            }
            onClick={() => props?.updateState(props?.keyType, x)}
          >
            <Typography className={classes.tabname}>{x}</Typography>
          </ListItem>
        );
      })}
    </List>
  );
};
