import React from "react"

export const PurposeIcon = () =>(
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_112300" data-name="Group 112300" transform="translate(-13981 -7826)">
    <g id="Group_112299" data-name="Group 112299">
      <g id="Rectangle_57214" data-name="Rectangle 57214" transform="translate(13981 7826)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <g id="Group_112298" data-name="Group 112298" transform="translate(13933.001 7461.254)">
        <g id="Path_100393" data-name="Path 100393" transform="translate(51 370.746)" fill="#e4e8ee">
          <path d="M 17.24951934814453 11.25 L 0.7499997019767761 11.25 L 0.7499997019767761 0.7500004768371582 L 17.24951934814453 0.7500004768371582 L 17.24951934814453 11.25 Z" stroke="none"/>
          <path d="M 1.5 1.500000953674316 L 1.5 10.5 L 16.49951934814453 10.5 L 16.49951934814453 1.500000953674316 L 1.5 1.500000953674316 M 0 9.5367431640625e-07 L 17.99951934814453 9.5367431640625e-07 L 17.99951934814453 12 L 0 12 L 0 9.5367431640625e-07 Z" stroke="none" fill="#98a0ac"/>
        </g>
        <path id="Path_100394" data-name="Path 100394" d="M-410,7432a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5,1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1-410,7432Zm-15,0a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5,1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1-425,7432Zm15-8.5a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5,1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1-410,7423.5Zm-15,0a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5,1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1-425,7423.5Z" transform="translate(476 -7051.254)" fill="#4e5a6b"/>
        <path id="Path_100395" data-name="Path 100395" d="M-422,7424.25a2.25,2.25,0,0,1,2.25-2.251,2.25,2.25,0,0,1,2.25,2.251,2.25,2.25,0,0,1-2.25,2.25A2.25,2.25,0,0,1-422,7424.25Zm6,0a.75.75,0,0,1,.751-.751.75.75,0,0,1,.749.751.75.75,0,0,1-.749.75A.75.75,0,0,1-416,7424.25Zm-9,0a.75.75,0,0,1,.749-.751.75.75,0,0,1,.751.751.75.75,0,0,1-.751.75A.75.75,0,0,1-425,7424.25Z" transform="translate(479.75 -7047.254)" fill="#4e5a6b"/>
      </g>
    </g>
  </g>
</svg>



)