export const SecurityRoles = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98477">
            <g data-name="user (1)" fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 95036"
                    d="M14.848 19.416a.5.5 0 0 1 .206-.33l.556-.4c-.006-.026-.01-.051-.014-.074l-.007-.046-.673-.125a.489.489 0 0 1-.383-.592l.272-1.518a.5.5 0 0 1 .207-.327.491.491 0 0 1 .373-.078l.667.124.039-.059.025-.039-.4-.583a.465.465 0 0 1-.088-.262.507.507 0 0 1 .213-.428l.81-.569a4.732 4.732 0 0 0-1-.544c-.051.04-.1.077-.156.114l-.046.034q-.135.094-.276.175l-.023.014q-.151.085-.308.155c-.034.015-.069.027-.1.042a3.784 3.784 0 0 1-.21.08c-.047.016-.1.03-.144.044s-.124.035-.187.05-.1.022-.148.032c-.07.014-.141.024-.211.034-.043.006-.085.012-.128.016-.115.011-.232.018-.348.018s-.232-.007-.347-.018c-.043 0-.086-.011-.129-.017a3.778 3.778 0 0 1-.36-.066c-.051-.012-.121-.031-.181-.049s-.1-.029-.15-.046c-.067-.023-.132-.049-.2-.075l-.116-.046c-.1-.046-.2-.1-.3-.151l-.056-.034a4.688 4.688 0 0 1-.242-.153l-.066-.048c-.045-.033-.092-.064-.136-.1A4.977 4.977 0 0 0 8 18.215v2.281h2.278a.228.228 0 0 1-.055-.145v-1.238a.232.232 0 0 1 .464 0v1.242a.227.227 0 0 1-.055.145h4.787l-.489-.72a.478.478 0 0 1-.081-.36Zm0 0"
                />
                <path
                    data-name="Path 95037"
                    d="M19.199 16.546a1.275 1.275 0 0 0-.232-.021 1.3 1.3 0 0 0-.75.242 1.338 1.338 0 0 0-.552.861 1.352 1.352 0 0 0 .217 1 1.3 1.3 0 0 0 .843.56 1.291 1.291 0 0 0 .982-.22 1.336 1.336 0 0 0 .552-.861 1.382 1.382 0 0 0-.218-1.006 1.306 1.306 0 0 0-.842-.558Zm0 0"
                />
                <path
                    data-name="Path 95038"
                    d="m11.526 13.315.012.007c.078.055.158.105.241.153.033.019.067.036.1.053.054.029.109.057.164.083s.088.038.133.056l.144.055c.044.017.1.033.147.048s.1.028.148.04.1.024.146.033.117.02.176.028c.041.006.082.013.124.017a3.054 3.054 0 0 0 .619 0c.041 0 .082-.011.122-.017a2.669 2.669 0 0 0 .322-.062l.152-.041c.052-.015.1-.03.142-.046s.1-.037.151-.058.085-.035.127-.054c.059-.027.116-.057.173-.087l.091-.049c.083-.048.164-.1.243-.155l.01-.006a3.5 3.5 0 0 0 1.454-2.862 3.3 3.3 0 1 0-6.594 0 3.5 3.5 0 0 0 1.454 2.862Zm0 0"
                />
                <path
                    data-name="Path 95039"
                    d="m22.908 17.723-.836-.155a.166.166 0 0 1-.131-.124 1.349 1.349 0 0 1-.024-.154c0-.02 0-.04-.012-.072 0-.01-.009-.036-.018-.079a.534.534 0 0 0-.023-.086.166.166 0 0 1 .059-.2l.7-.495a.1.1 0 0 0 .043-.069v-.026l-.92-1.378-.032.024h-.011l-.753.508a.167.167 0 0 1-.208-.015.7.7 0 0 0-.1-.07l-.055-.037a.836.836 0 0 0-.153-.088.166.166 0 0 1-.094-.18l.153-.849a.1.1 0 0 0-.015-.081.1.1 0 0 0-.066-.041l-1.5-.277h-.03a.105.105 0 0 0-.086.082l-.153.849a.166.166 0 0 1-.123.132 1.21 1.21 0 0 1-.144.024h-.017c-.017 0-.033 0-.062.011l-.073.017a.5.5 0 0 0-.087.024.165.165 0 0 1-.2-.06l-.487-.716-.019-.03h-.035a.159.159 0 0 0-.038-.007l-.207.13h-.046v.032l-1.041.732a.1.1 0 0 0-.043.069v.026l.509.762a.166.166 0 0 1-.014.2.708.708 0 0 0-.07.1l-.035.054a.86.86 0 0 0-.088.158.166.166 0 0 1-.181.1l-.836-.155h-.03a.105.105 0 0 0-.086.083l-.276 1.543v.087l.9.174a.166.166 0 0 1 .131.124 1.368 1.368 0 0 1 .024.154c0 .02 0 .04.009.062a1.039 1.039 0 0 0 .043.174.165.165 0 0 1-.058.195l-.689.5a.107.107 0 0 0-.045.071v.026l.925 1.374.031-.019a.091.091 0 0 0 .025 0l.744-.508a.17.17 0 0 1 .209.015.694.694 0 0 0 .1.07l.056.037a.827.827 0 0 0 .153.088.166.166 0 0 1 .094.18l-.153.849a.1.1 0 0 0 .081.122l1.518.282h.015a.107.107 0 0 0 .086-.082l.153-.849a.166.166 0 0 1 .124-.132 1.354 1.354 0 0 1 .144-.024l.067-.009a1 1 0 0 0 .173-.044.167.167 0 0 1 .2.061l.487.716.025.029h.032a.121.121 0 0 0 .034.006l1.294-.894a.1.1 0 0 0 .043-.069v-.026l-.509-.762a.165.165 0 0 1 .014-.2.684.684 0 0 0 .07-.1l.036-.055a.875.875 0 0 0 .088-.157.165.165 0 0 1 .182-.1l.851.158h.015a.105.105 0 0 0 .086-.082l.272-1.524a.128.128 0 0 0-.086-.134Zm-2.256.458a1.732 1.732 0 0 1-.715 1.116 1.684 1.684 0 0 1-2.383-.442 1.741 1.741 0 0 1 .435-2.415 1.7 1.7 0 0 1 .978-.314 1.669 1.669 0 0 1 .305.028 1.7 1.7 0 0 1 1.1.729 1.779 1.779 0 0 1 .279 1.3Zm0 0"
                />
            </g>
            <path data-name="Rectangle 52166" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
