import * as React from "react"

export const DeclineForm = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <g data-name="Group 105404" transform="translate(-203.5 -498.5)">
      <rect
        data-name="Rectangle 55504"
        width={64}
        height={64}
        rx={32}
        transform="translate(203.5 498.5)"
        fill="#ffecec"
      />
      <path
        d="M235.5 518.5a12 12 0 1 0 12 12 12.018 12.018 0 0 0-12-12Zm0 2.4a9.522 9.522 0 0 1 5.864 2.039l-13.425 13.425A9.562 9.562 0 0 1 235.5 520.9Zm7.561 3.736a9.562 9.562 0 0 1-13.425 13.425Z"
        fill="#ff4b4b"
      />
    </g>
  </svg>
)
