import * as React from "react"

const DeclineIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 110231" transform="translate(-344.84 -312.088)">
            <rect
                data-name="Rectangle 55272"
                width={40}
                height={40}
                rx={4}
                transform="translate(344.84 312.088)"
                fill="#ffecec"
            />
            <g data-name="Group 104827">
                <path
                    data-name="Path 97631"
                    d="m365.47 323.592.343.4.452-.263a1.458 1.458 0 0 1 2.085.716l.2.485.513-.1a1.458 1.458 0 0 1 1.74 1.354l.027.522.518.071a1.458 1.458 0 0 1 1.206 1.845l-.144.5.467.235a1.458 1.458 0 0 1 .541 2.137l-.3.429.366.374a1.458 1.458 0 0 1-.182 2.2l-.422.309.224.472a1.458 1.458 0 0 1-.886 2.019l-.5.155.059.52a1.457 1.457 0 0 1-1.493 1.622l-.523-.016-.113.511a1.458 1.458 0 0 1-1.942 1.042l-.489-.185-.272.446a1.458 1.458 0 0 1-2.174.363l-.4-.334-.4.333a1.458 1.458 0 0 1-2.174-.363l-.272-.446-.489.185a1.458 1.458 0 0 1-1.946-1.051l-.113-.511-.523.016a1.458 1.458 0 0 1-1.493-1.622l.059-.52-.5-.155a1.458 1.458 0 0 1-.88-2.017l.224-.472-.424-.309a1.457 1.457 0 0 1-.182-2.2l.366-.374-.3-.429a1.458 1.458 0 0 1 .541-2.137l.467-.235-.144-.5a1.458 1.458 0 0 1 1.205-1.844l.518-.071.029-.521a1.458 1.458 0 0 1 1.74-1.354l.513.1.2-.485a1.458 1.458 0 0 1 2.085-.716l.452.263.343-.4a1.457 1.457 0 0 1 2.197.006Z"
                    fill="#ff4b4b"
                />
                <path
                    data-name="icons8-delete (3)"
                    d="M366.614 330.089a.22.22 0 0 0-.154.068l-2.121 2.12-2.12-2.12a.22.22 0 1 0-.312.312l2.121 2.12-2.121 2.122a.22.22 0 1 0 .312.312l2.12-2.123 2.117 2.121a.22.22 0 1 0 .311-.311l-2.116-2.121 2.121-2.121a.22.22 0 0 0-.158-.379Z"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth={0.5}
                />
            </g>
        </g>
    </svg>
)

export default DeclineIcon
