import moment from "moment";
import { CompanyIcon, ExpiryDateIcon, FunctionalLocationIcon, PropertyIcon, RenewedDateIcon } from "../../../assets/clientDashboard";
import { Routes } from "../../../router/routes";

export const constructResponseGetStatData = ({ data = "", t = () => false }) => {
    const count_1 = [
        {
            count: data?.company_count ? `${data?.company_count}${data?.total_company_count ? `/${data?.total_company_count}` : ``}` : "0",
            sub: t("Companies"),
            route: Routes?.companyMaster,
            icon: <CompanyIcon />,
            icon_type: true,
        },
        {
            count: data?.functional_location_count ?? "0",
            sub: t("Functional Locations"),
            route: Routes?.functionalLocation,
            icon: <FunctionalLocationIcon />,
            icon_type: true,
        },
        {
            count: data?.property_count ? `${data?.property_count}${data?.total_property_count ? `/${data?.total_property_count}` : ``}` : "0",
            sub: t("Properties"),
            route: Routes?.properties,
            icon: <PropertyIcon />,
            icon_type: true,
        },
    ];

    const subscription_summary = [
        {
            icon: <RenewedDateIcon />,
            date: data?.client_plans?.created_at
                ? moment(data?.client_plans?.created_at).format("DD MMM YYYY")
                : "-",
            description: t("Last Renewed Date"),
        },
        {
            icon: <ExpiryDateIcon />,
            date: data?.client_plans?.valid_till
                ? moment(data?.client_plans?.valid_till).format("DD MMM YYYY")
                : "-",
            description: t("Expiry Date"),
        },
    ];

    const property_units = data?.unit_count ? `${data?.unit_count}${data?.total_unit_count ? `/${data?.total_unit_count}` : ``}` : "0";

    const seats = data?.seat_count ? `${data?.seat_count}${data?.total_seat_count ? `/${data?.total_seat_count}` : ``}` : "0";

    const count_2 = [
        {
            count: data?.residential_unit_count ?? "0",
            label: t("Residential"),
        },
        {
            count: data?.commercial_unit_count ?? "0",
            label: t("Commercial"),
        },
        {
            count: data?.stay_count ?? "0",
            label: t("Stay"),
        },
        {
            count: data?.property_facility_count ?? "0",
            label: t("Facilities"),
        },
    ];

    const storage_utilization = {
        details: `${data?.storage?.used_storage_value?.toFixed(2) ?? 0}GB of ${data?.storage?.total_storage_value ?? 0}GB ${t("used")}`,
        progress_bar_value: ((data?.storage?.used_storage_value / data?.storage?.total_storage_value) * 100) ?? 0,
    }

    const listing_units = (
        data?.private_listing_unit_count
        || data?.public_listing_unit_count
        || data?.private_facility_size
        || data?.listing_stay_unit_count)
        ? `${parseInt(data?.private_listing_unit_count ?? 0)
        + parseInt(data?.public_listing_unit_count ?? 0)
        + parseInt(data?.private_facility_size ?? 0)
        + parseInt(data?.listing_stay_unit_count ?? 0)}
        ${(data?.total_private_listing_size
            || data?.total_public_listing_size
            || data?.total_private_facility_size
            || data?.total_listing_stay_size)
            ? `/${parseInt(data?.total_private_listing_size ?? 0)
            + parseInt(data?.total_public_listing_size ?? 0)
            + parseInt(data?.total_private_facility_size ?? 0)
            + parseInt(data?.total_listing_stay_size ?? 0)}`
            : ``}`
        : "0";

    const count_3 = [
        {
            count: data?.private_listing_unit_count ? `${data?.private_listing_unit_count}${data?.total_private_listing_size ? `/${data?.total_private_listing_size}` : ``}` : "0",
            label: t("Listed Private"),
        },
        {
            count: data?.public_listing_unit_count ? `${data?.public_listing_unit_count}${data?.total_public_listing_size ? `/${data?.total_public_listing_size}` : ``}` : "0",
            label: t("Listed Public"),
        },
        {
            // count: (data?.private_facility_size || data?.private_facility_size)
            //     ? `${parseInt(data?.private_facility_size ?? 0) + parseInt(data?.private_facility_size ?? 0)}
            //     ${(data?.total_private_facility_size || data?.total_public_facility_size)
            //         ? `/${parseInt(data?.total_private_facility_size ?? 0) + parseInt(data?.total_public_facility_size ?? 0)}`
            //         : ``}`
            //     : "0",
            count: data?.private_facility_size ? `${data?.private_facility_size}${data?.total_private_facility_size ? `/${data?.total_private_facility_size}` : ``}` : "0",
            label: t("Facilities"),
        },
        {
            count: data?.listing_stay_unit_count ? `${data?.listing_stay_unit_count}${data?.total_listing_stay_size ? `/${data?.total_listing_stay_size}` : ``}` : "0",
            label: t("Stay"),
        },
    ];

    const items_assets = (
        data?.inventory_inspection_count
        || data?.total_assets
        || data?.service_inspection_count)
        ? `${parseInt(data?.inventory_inspection_count ?? 0)
        + parseInt(data?.total_assets ?? 0)
        + parseInt(data?.service_inspection_count ?? 0)}`
        : "0";

    const count_4 = [
        {
            count: data?.inventory_inspection_count ?? "0",
            label: t("Inventory"),
        },
        {
            count: data?.total_assets ?? "0",
            label: t("Assets"),
        },
        {
            count: data?.service_inspection_count ?? "0",
            label: t("Services"),
        },
    ];

    const resources = (
        data?.employee_count
        || data?.worker_app_count
        || data?.inspector_app_count
        || data?.security_guard_count)
        ? `${parseInt(data?.employee_count ?? 0)
        + parseInt(data?.worker_app_count ?? 0)
        + parseInt(data?.inspector_app_count ?? 0)
        + parseInt(data?.security_guard_count ?? 0)}
            ${data?.total_resource_count
            ? `/${data?.total_resource_count}`
            : ``}`
        : "0";

    const count_5 = [
        {
            count: data?.employee_count ?? "0",
            label: t("Employees"),
        },
        {
            count: data?.worker_app_count ?? "0",
            label: t("Workers"),
        },
        {
            count: data?.inspector_app_count ?? "0",
            label: t("Inspectors"),
        },
        {
            count: data?.security_guard_count ?? "0",
            label: t("Guards"),
        },
    ];

    const count_6 = [
        {
            count: data?.total_account ?? "0",
            label: t("Account"),
        },
        {
            count: data?.contact_count ?? "0",
            label: t("Contacts"),
        },
        {
            count: data?.vendor_count ?? "0",
            label: t("Vendors"),
        },
        {
            count: data?.customer_count ?? "0",
            label: t("Customers"),
        },
    ];

    const resource_chart = data?.resource_type?.map((val) => {
        return {
            fill: val?.fill,
            count: parseInt(val?.count),
            name: val?.type
        }
    }) ?? [];

    const roles_chart = data?.client_roles?.map((val) => {
        return {
            fill: val?.fill,
            count: parseInt(val?.count),
            name: val?.type
        }
    }) ?? [];

    const units_chart = data?.unit_type?.map((val) => {
        return {
            fill: val?.fill,
            count: parseInt(val?.count),
            name: val?.type
        }
    }) ?? [];

    const final_stat_data = {
        count_1,
        subscription_summary,
        property_units,
        seats,
        count_2,
        storage_utilization,
        listing_units,
        count_3,
        items_assets,
        count_4,
        resources,
        count_5,
        count_6,
        resource_chart,
        roles_chart,
        units_chart,
    };

    return final_stat_data;
}