import * as React from "react"
const NormalIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 112684" transform="translate(-128 -499)">
      <rect
        width={40}
        height={40}
        fill="#f1f7ff"
        data-name="NoPath - Copy (7)"
        rx={6}
        transform="translate(128 499)"
      />
      <path
        fill="#78b1fe"
        d="M153.556 511h-11.112a2.447 2.447 0 0 0-2.444 2.444v11.112a2.447 2.447 0 0 0 2.444 2.444h11.112a2.447 2.447 0 0 0 2.444-2.444v-11.112a2.447 2.447 0 0 0-2.444-2.444Zm-1.334 12.444h-8.444a.667.667 0 1 1 0-1.333h8.444a.667.667 0 0 1 0 1.333Zm0-3.778h-8.444a.667.667 0 1 1 0-1.333h8.444a.667.667 0 0 1 0 1.333Zm0-3.778h-8.444a.667.667 0 1 1 0-1.333h8.444a.667.667 0 0 1 0 1.333Z"
      />
    </g>
  </svg>
)
export default NormalIcon
