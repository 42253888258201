import * as React from "react"
const AllUnitsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 105250" transform="translate(-103 -277)">
      <circle
        cx={20}
        cy={20}
        r={20}
        fill="#f3f6ff"
        data-name="Ellipse 129562"
        transform="translate(103 277)"
      />
      <g data-name="Group 105249">
        <path fill="none" d="M111 285h24v24h-24z" data-name="Rectangle 33" />
      </g>
      <g data-name="Group 105462">
        <path fill="none" d="M113 287h20v20h-20z" data-name="Rectangle 55517" />
        <path
          fill="#5078e1"
          d="M122.238 289a.57.57 0 0 0-.353.122l-5.415 4.266a2.856 2.856 0 0 0-1.089 2.244v7.268a.952.952 0 0 0 .952.952h3.429a.952.952 0 0 0 .952-.952v-4.376a.381.381 0 0 1 .381-.381h2.286a.381.381 0 0 1 .381.381v1.812l.773-.773a3.23 3.23 0 0 1-.011-.278 3.419 3.419 0 0 1 4.571-3.228v-.424a2.858 2.858 0 0 0-1.089-2.245l-5.414-4.266a.57.57 0 0 0-.354-.122Zm5.714 7.619a2.668 2.668 0 0 0-2.61 3.214l-2.993 2.993a.38.38 0 0 0-.112.269v1.524a.381.381 0 0 0 .381.381h1.524a.381.381 0 0 0 .381-.381v-.762h.758a.381.381 0 0 0 .381-.381v-.762h.762a.381.381 0 0 0 .381-.381v-.641a2.665 2.665 0 1 0 1.143-5.073Zm.381 1.524a.762.762 0 1 1-.762.762.762.762 0 0 1 .762-.762Z"
          data-name="icons8-house-keys (1)"
        />
      </g>
    </g>
  </svg>
)
export default AllUnitsIcon
