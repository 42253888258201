import React from "react"
import { withNavBars } from "../../HOCs"
import UnitVacancyAllList from "./unitVacancyAllList"

class UnitVacancyAllListParent extends React.Component {
    render() {
        return <UnitVacancyAllList />
    }
}
const props = { boxShadow: false }

export default withNavBars(UnitVacancyAllListParent, props)