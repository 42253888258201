import * as React from "react"

const DeclineIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32.093} height={32} {...props}>
    <g data-name="icons8-approval (1)">
      <path
        data-name="Path 97461"
        d="m17.9.848.577.666.761-.443a2.456 2.456 0 0 1 3.512 1.205l.329.817.863-.171a2.456 2.456 0 0 1 2.93 2.28l.046.879.873.119a2.456 2.456 0 0 1 2.031 3.108l-.242.846.787.4a2.456 2.456 0 0 1 .911 3.6l-.5.722.616.63a2.455 2.455 0 0 1-.306 3.7l-.711.52.378.8a2.456 2.456 0 0 1-1.491 3.4l-.842.261.1.875a2.454 2.454 0 0 1-2.514 2.731l-.88-.026-.19.86a2.455 2.455 0 0 1-3.265 1.767l-.824-.311-.459.752a2.456 2.456 0 0 1-3.662.611l-.679-.562-.678.562a2.455 2.455 0 0 1-3.662-.611l-.459-.752-.824.311a2.456 2.456 0 0 1-3.265-1.767l-.19-.86-.88.026a2.455 2.455 0 0 1-2.514-2.731l.1-.875-.842-.261a2.456 2.456 0 0 1-1.491-3.4l.378-.8-.711-.52a2.455 2.455 0 0 1-.306-3.7l.616-.63-.5-.722a2.456 2.456 0 0 1 .911-3.6l.787-.4-.242-.846A2.455 2.455 0 0 1 4.3 6.2l.873-.119.046-.879a2.455 2.455 0 0 1 2.93-2.28l.864.172.329-.817a2.456 2.456 0 0 1 3.511-1.205l.761.443.577-.666A2.453 2.453 0 0 1 17.9.848Z"
        fill="#ff4b4b"
      />
      <path
        data-name="icons8-delete (3)"
        d="M20.813 10.76a.462.462 0 0 0-.322.142l-4.445 4.445-4.446-4.445a.462.462 0 1 0-.653.653L15.392 16l-4.445 4.446a.462.462 0 1 0 .653.653l4.445-4.445 4.445 4.445a.462.462 0 1 0 .653-.653l-4.445-4.445 4.445-4.445a.462.462 0 0 0-.331-.795Z"
        fill="#fff"
        stroke="#fff"
      />
    </g>
  </svg>
)

export default DeclineIcon
