export const ReraPriceChart = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g
      id="Group_99667"
      data-name="Group 99667"
      transform="translate(-1509 524)"
    >
      <g
        id="Group_8127"
        data-name="Group 8127"
        transform="translate(-10 -1483)"
      >
        <g
          id="Group_99684"
          data-name="Group 99684"
          transform="translate(-4 -42)"
        >
          <path
            id="Rectangle_31"
            data-name="Rectangle 31"
            transform="translate(1523 1001)"
            style={{
              fill: "none",
            }}
            d="M0 0h30v30H0z"
          />
          <g
            id="RERA_Price_Chart"
            data-name="RERA Price Chart"
            transform="translate(1531 952.021)"
          >
            <g
              id="Group_99672"
              data-name="Group 99672"
              transform="translate(1.767 61.075)"
            >
              <path
                id="Path_95366"
                data-name="Path 95366"
                className="cls-2"
                d="M62.627 205.641h-2.432V200.2a.245.245 0 0 1 .245-.245h1.942a.245.245 0 0 1 .245.245Z"
                transform="translate(-60.195 -198.887)"
                style={{
                  fill: props?.fill ?? "#c1c5cb",
                }}
              />
              <path
                id="Path_95367"
                data-name="Path 95367"
                className="cls-2"
                d="M216.471 170.207h-2.432V163.7a.245.245 0 0 1 .245-.245h1.942a.245.245 0 0 1 .245.245Z"
                transform="translate(-209.522 -163.453)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
              />
              <path
                id="Path_95368"
                data-name="Path 95368"
                className="cls-2"
                d="M370.315 218.556h-2.432v-5.047a.245.245 0 0 1 .245-.245h1.942a.245.245 0 0 1 .245.245Z"
                transform="translate(-358.849 -211.802)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
              />
            </g>
            <g
              id="Group_99673"
              data-name="Group 99673"
              transform="translate(0 68.693)"
            >
              <path
                id="Path_95369"
                data-name="Path 95369"
                className="cls-2"
                d="M14.56 423.8H.44a.44.44 0 1 1 0-.881h14.12a.44.44 0 1 1 0 .881Z"
                transform="translate(0 -422.922)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
              />
            </g>
            <path
              id="Path_95370"
              data-name="Path 95370"
              className="cls-2"
              d="M26.327 59.654a.441.441 0 0 0-.338-.467l-1.423-.346a.44.44 0 1 0-.208.856l.4.1-1.141.7-4.326-2.463a.44.44 0 0 0-.4-.016l-4.3 2.018-2.055-1.283a.44.44 0 1 0-.466.747l2.259 1.41a.44.44 0 0 0 .42.025l4.31-2.023 4.354 2.479a.455.455 0 0 0 .449-.008l1.383-.853-.092.431a.44.44 0 0 0 .861.184l.305-1.43a.512.512 0 0 0 .01-.064Z"
              transform="translate(-11.511)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)