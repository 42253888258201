export const DomesticHelper = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 102650">
            <path data-name="Rectangle 54845" fill="none" d="M-.001 0h30v30h-30z" />
            <g transform="translate(4.999 5)" fill="#c1c5cb">
                <circle
                    data-name="Ellipse 129439"
                    cx={0.533}
                    cy={0.533}
                    r={0.533}
                    transform="translate(5.733 7.333)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <circle
                    data-name="Ellipse 129440"
                    cx={0.533}
                    cy={0.533}
                    r={0.533}
                    transform="translate(8.4 7.333)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97573"
                    d="M2.267 18h9.067a.267.267 0 0 0 .267-.267v-2.666a1.333 1.333 0 0 0-1.067-1.307V12.4a1.312 1.312 0 0 0-.267-.8 2.4 2.4 0 0 1 2.4 2.4.267.267 0 1 0 .533 0 2.933 2.933 0 0 0-2.933-2.933H9.44a3.467 3.467 0 0 0 1.627-2.933V4.4a2.4 2.4 0 0 0-2.4-2.4H6.533a2.4 2.4 0 0 0-2.4 2.4v3.733a3.467 3.467 0 0 0 1.627 2.934h-.827A2.933 2.933 0 0 0 2 14v1.867a.267.267 0 0 0 .533 0V14a2.4 2.4 0 0 1 2.4-2.4 1.312 1.312 0 0 0-.267.8v1.36A1.333 1.333 0 0 0 3.6 15.067v2.4H2.533v-.533a.267.267 0 1 0-.533 0v.8a.267.267 0 0 0 .267.266Zm3.467-.533v-.8a.8.8 0 0 1 .8-.8h2.133a.8.8 0 0 1 .8.8v.8Zm.533-3.733v-1.1a1.867 1.867 0 0 0 2.667 0v1.1Zm-.533 0H5.2V12.4a.8.8 0 0 1 .533-.755ZM8.907 11.6a1.333 1.333 0 0 1-2.613 0ZM10 13.733h-.533v-2.088A.8.8 0 0 1 10 12.4ZM6 5.2h4.507A1.333 1.333 0 0 1 9.2 6.267H6A1.333 1.333 0 0 1 4.693 5.2Zm-1.333-.8a1.867 1.867 0 0 1 1.866-1.867h2.134A1.867 1.867 0 0 1 10.533 4.4v.267H9.467v-.8A.267.267 0 0 0 9.2 3.6H7.867a.267.267 0 0 0 0 .533h1.066v.533H6.267v-.533H6.8a.267.267 0 0 0 0-.533H6a.267.267 0 0 0-.267.267v.8H4.667Zm0 3.733v-1.9A1.867 1.867 0 0 0 6 6.8h3.2a1.867 1.867 0 0 0 1.333-.563v1.9a2.933 2.933 0 0 1-5.867 0Zm-.533 6.933a.8.8 0 0 1 .8-.8h5.333a.8.8 0 0 1 .8.8v2.4H10v-.8a1.333 1.333 0 0 0-1.333-1.333H6.533A1.333 1.333 0 0 0 5.2 16.667v.8H4.133Z"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97574"
                    d="M44.533 14.8h-.8V2.267A.267.267 0 0 0 43.467 2H42.4a.267.267 0 0 0-.267.267V14.8h-.8A1.333 1.333 0 0 0 40 16.133v1.6a.267.267 0 0 0 .267.267h.8a.267.267 0 0 0 0-.533h-.533V16.4h.533v.267a.267.267 0 1 0 .533 0V16.4h.533v.267a.267.267 0 1 0 .533 0V16.4h.534v.267a.267.267 0 0 0 .533 0V16.4h.533v.267a.267.267 0 1 0 .533 0V16.4h.533v1.067h-3.2a.267.267 0 0 0 0 .533H45.6a.267.267 0 0 0 .267-.267v-1.6a1.333 1.333 0 0 0-1.334-1.333ZM42.667 2.533h.533V14.8h-.533Zm-2.088 13.334a.8.8 0 0 1 .755-.533h3.2a.8.8 0 0 1 .755.533Z"
                    transform="translate(-27.867)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97575"
                    d="M21.6 30.267a.267.267 0 0 0-.267-.267h-1.066a.267.267 0 0 0 0 .533h1.067a.267.267 0 0 0 .266-.266Z"
                    transform="translate(-13.2 -20.533)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
        </g>
    </svg>
)