import PhoneCallIcon from '../../../assets/phonecall';
import EventIcon from '../../../assets/event';
import SiteVisitsIcon from '../../../assets/sitevisits';
import QuotationIcon from '../../../assets/quotation';
import EmailSlIcon from '../../../assets/emailsl';
import FollowupIcon from '../../../assets/followup';
import AppoinmentIcon from '../../../assets/appointment';
import MeetIcon from '../../../assets/meet';

export const activityLogo = {
    "Phone call": <PhoneCallIcon />,
    "Follow up": <FollowupIcon />,
    "Event": <EventIcon />,
    "Send Email": <EmailSlIcon />,
    "Send Quotation": <QuotationIcon />,
    "Arrange Site Visit": <SiteVisitsIcon />,
    "Appointment": <AppoinmentIcon />,
    "Meeting": <MeetIcon />
} 