import * as React from "react"

export const AsPerAgreement = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 102948" transform="translate(-11563 -17154)">
      <circle
        data-name="Ellipse 129451"
        cx={24}
        cy={24}
        r={24}
        transform="translate(11563 17154)"
        fill="#f0f9f7"
      />
      <g data-name="Group 102947">
        <path
          data-name="Rectangle 54909"
          fill="none"
          d="M11575 17166h24v24h-24z"
        />
        <path
          data-name="icons8-agreement (2)"
          d="M11582.4 17170a1.8 1.8 0 0 0-1.8 1.8v12.4a1.8 1.8 0 0 0 1.8 1.8h9.2a1.8 1.8 0 0 0 1.8-1.8v-7.8h-4.6a1.8 1.8 0 0 1-1.8-1.8v-4.6Zm5.8.352v4.248a.6.6 0 0 0 .6.6h4.248Zm-3 7.648a1.78 1.78 0 0 1 1.8 2 4.057 4.057 0 0 1-.916 2.372 1.592 1.592 0 0 0 .316.032c.328 0 .452-.152.7-.668.18-.372.456-.932 1.1-.932.74 0 .908.712.992 1.056.124.528.172.544.408.544a.6.6 0 0 0 .208-.5.6.6 0 1 1 1.184-.192 1.8 1.8 0 0 1-.416 1.408 1.278 1.278 0 0 1-.976.48 1.48 1.48 0 0 1-1.5-1.176 1.858 1.858 0 0 1-1.7 1.176 2.618 2.618 0 0 1-1.352-.36 3.019 3.019 0 0 1-1.448.36.6.6 0 0 1 0-1.2 1.849 1.849 0 0 0 .5-.068 4.337 4.337 0 0 1-.7-2.332 1.781 1.781 0 0 1 1.8-2Zm0 1.2c-.164 0-.6 0-.6.8a3.083 3.083 0 0 0 .512 1.672 2.931 2.931 0 0 0 .688-1.672c0-.8-.436-.8-.6-.8Z"
          fill="#43aa8b"
        />
      </g>
    </g>
  </svg>
)
