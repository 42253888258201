import * as React from "react"

const DeleteIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={13} height={13} {...props}>
        <path
            data-name="icons8-trash (2)"
            d="M6.5 0a2.366 2.366 0 0 0-2.367 1.95H1.792a.485.485 0 0 0-.164 0H.513a.488.488 0 1 0 0 .975h.732l.861 8.46A1.854 1.854 0 0 0 3.979 13H9.02a1.854 1.854 0 0 0 1.873-1.615l.862-8.46h.732a.488.488 0 1 0 0-.975h-1.114a.54.54 0 0 0-.164 0H8.867A2.366 2.366 0 0 0 6.5 0Zm0 .975a1.345 1.345 0 0 1 1.321.975H5.179A1.345 1.345 0 0 1 6.5.975Zm-4.225 1.95h8.449l-.853 8.366a.833.833 0 0 1-.851.734H3.979a.834.834 0 0 1-.851-.734Zm3.019 1.618a.5.5 0 0 0-.505.494v4.875a.514.514 0 0 0 1.026 0V5.038a.475.475 0 0 0-.15-.352.528.528 0 0 0-.371-.143Zm2.395 0a.5.5 0 0 0-.505.494v4.875a.514.514 0 0 0 1.026 0V5.038a.475.475 0 0 0-.15-.352.528.528 0 0 0-.371-.143Z"
            fill="#ff4b4b"
        />
    </svg>
)

export default DeleteIcon
