import React from "react";
import ContextProvider from "../../contexts/createQuotationContext";
import { withNavBars } from "../../HOCs";
import { CreateQuotation } from "./createquotation";
const CreateQuotationParent = (props) => {


  return (
    <ContextProvider props={props}>
      <CreateQuotation props={props} />
    </ContextProvider>
  );
};
const props = {
  boxShadow: false
}
export default withNavBars(CreateQuotationParent, props);
