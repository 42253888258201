import { Box, Grid } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { useHistory } from "react-router-dom"
import { SearchFilter, Subheader, TableWithPagination, UseDebounce } from '../../components'
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { Routes } from '../../router/routes'
import { accessCheckRender, AlertProps, BulkpropertyHeading, BulkpropertyPath, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from '../../utils'
import { useStyles } from './style'

const InspectionBulk = ({ t }) => {
    const classes = useStyles()
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [companyList, setCompanyList] = React.useState([])
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [permissions, setPermission] = React.useState({})
    const [searchText, setSearchText] = React.useState("")
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [tableData, setTableData] = React.useState(false)
    const [totalRows, setTotalRows] = React.useState("")
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext);

    // History
    const history = useHistory();
    //for pagination
    const handleTablePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getPropertyTemplateList(selectedCompany?.value, offset, limit, "")
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getPropertyTemplateList(selectedCompany?.value, 0, limit, "")

    }
    //handleIcon
    const handleIcon = (type, data) => {
        history.push({
            pathname: Routes?.insItemPropertyDetails,
            state: {
                company: selectedCompany,
                id: data?.id,
                no: data?.property_no,
                title: t("inspectionTemplateView")
            }
        })
    }
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        let company = getCompanyOption(backdrop, auth, alert)

        if (perm) {
            setPermission(perm)
            if (company && perm?.read) {
                setCompanyList(company?.list)
                setSelectedCompany(company?.selected)
                getPropertyTemplateList(company?.selected?.value, 0, limit, "")
            }
        }

        // eslint-disable-next-line
    }, [auth])
    //initial table data
    const getPropertyTemplateList = (company_id, offset = 0, limit = 10, searchText) => {

        const payload = {
            company_id: company_id,
            search: searchText,
            offset: offset,
            limit: limit
        }

        NetworkCall(
            `${config.api_url}/inspection_template/getAllPropertyTemplate`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setTableData(response?.data?.data)
                setTotalRows(response?.data?.count)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
            })
    }
    //handleSearch
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getPropertyTemplateList(selectedCompany?.value, 0, 10, e)
    }
    //company handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getPropertyTemplateList(value?.value, 0, 10, "")
    }
    const render = () => {
        return (
            <Box>
                <Subheader title={t("inspectionBulkTemplate")} hideBackButton={true} select value={selectedCompany} options={companyList} onchange={(e) => {
                    handleCompanyChange(e)
                }} />
                <Box className={classes.root} m={2} p={2}>
                    <Grid container>
                        <Grid item xs={4}>
                            {/* searchbar */}
                            <SearchFilter value={searchText} placeholder={t("searchProperty")} handleChange={(value) => handleSearch(value)} />
                        </Grid>
                    </Grid>
                    {/* Inspectiontable */}
                    <TableWithPagination
                        heading={BulkpropertyHeading(t)}
                        path={BulkpropertyPath}
                        rows={tableData ? tableData : []}
                        handleIcon={handleIcon}
                        tableType="no-side"
                        showpagination
                        handlePagination={handleTablePagination}
                        handleChangeLimit={handleChangeLimit}
                        totalRowsCount={totalRows}
                        page={page}
                        limit={limit}
                        dataType={[

                            { type: ["avatarmanagement"], name: "logo", width: "100px" },
                            { type: ["text"], name: "name", width: "250px" },
                            { type: ["text"], name: "property_no", width: "250px" },
                            { type: ["text"], name: "count" },

                        ]}
                        height={`calc(100vh - 300px)`}

                    />
                </Box>
            </Box>
        )
    }
    return (
        <>
            {accessCheckRender(render, permissions)}
        </>
    )
}
export default withNamespaces("inspection")(InspectionBulk)