import { Dialog, IconButton, Stack, Typography } from "@mui/material"
import { correspondencesStyles } from "../style"
import CloseIcon from '@mui/icons-material/Close';

export const ConfirmationDialog = (
    {
        t = () => false,
        isConfirmDialogOpen = "",
        confirmDialogData = "",
        setIsConfirmDialogOpen = () => false,
        disableButton = "",
    }
) => {
    const classes = correspondencesStyles()

    return (
        <Dialog
            className={classes.confirmSuccessDialog}
            open={isConfirmDialogOpen}
            onClose={() => {
                setIsConfirmDialogOpen(false)
            }}>
            <div className={classes.confirmDialogComponent}>
                <div className={classes.confirmSuccessDialogHeader}>
                    <IconButton
                        onClick={() => {
                            setIsConfirmDialogOpen(false)
                        }}
                        className={classes.dialogCloseButton}>
                        <CloseIcon htmlColor="#FFFFFF" height="14px" width="14px" />
                    </IconButton>
                </div>
                <div className={classes.confirmDialogBody}>
                    <Stack spacing={"40px"} width={"300px"}>
                        <Typography className={classes.confirm_question_success_message_text}>
                            {confirmDialogData?.question}
                        </Typography>
                        <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                            <div className={classes.yes_button}
                                onClick={() => { !disableButton && confirmDialogData?.yes_action() }}>
                                <Typography className={classes.yes_okay_button_text}>
                                    {t("Yes")}
                                </Typography>
                            </div>
                            <div className={classes.no_button}
                                onClick={() => { !disableButton && confirmDialogData?.no_action() }}>
                                <Typography className={classes.no_button_text}>
                                    {t("No")}
                                </Typography>
                            </div>
                        </Stack>
                    </Stack>
                </div >
            </div >
        </Dialog >
    )
}