import * as React from "react"

const BookingComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 22.219 24"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        y1={-340}
        x2={1}
        y2={-340}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c8d3de" />
        <stop offset={1} stopColor="#c8d3de" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1={0.225}
        y1={-35.926}
        x2={0.705}
        y2={-36.855}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fcfcfc" />
        <stop offset={0.495} stopColor="#f4f4f4" />
        <stop offset={0.946} stopColor="#e8e8e8" />
        <stop offset={1} stopColor="#e8e8e8" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1={0.5}
        y1={-61.131}
        x2={0.5}
        y2={-62.179}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#d43a02" />
        <stop offset={1} stopColor="#b9360c" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1={-0.467}
        y1={-1.034}
        x2={0.913}
        y2={1.179}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f44f5a" />
        <stop offset={0.443} stopColor="#ee3d4a" />
        <stop offset={1} stopColor="#e52030" />
      </linearGradient>
    </defs>
    <g id="Icon" transform="translate(-0.891)">
      <g id="icons8-booking" transform="translate(-0.8 -0.533)">
        <path
          id="Path_96425"
          data-name="Path 96425"
          d="M22.4,20.267H3.2v1.067A1.066,1.066,0,0,0,4.267,22.4H21.334A1.066,1.066,0,0,0,22.4,21.334Z"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_96426"
          data-name="Path 96426"
          d="M22.4,20.267H3.2V10.133l9.6-9.067,9.6,9.067Z"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_96427"
          data-name="Path 96427"
          d="M12.8,1.6a1.064,1.064,0,0,0-.707.268L9.235,4.434,3.2,10.133v2.875l9.6-8.513,9.6,8.513V10.133l-6.035-5.7L13.507,1.868A1.064,1.064,0,0,0,12.8,1.6Z"
          opacity={0.05}
        />
        <path
          id="Path_96428"
          data-name="Path 96428"
          d="M12.8,1.067a1.068,1.068,0,0,0-.707.267L9.235,3.885,3.2,9.552V12.41l9.6-8.465,9.6,8.465V9.552L16.366,3.885,13.507,1.333A1.068,1.068,0,0,0,12.8,1.067Z"
          opacity={0.07}
        />
        <path
          id="Path_96429"
          data-name="Path 96429"
          d="M23.731,9.871,13.507.8a1.066,1.066,0,0,0-1.414,0L1.869,9.871a.533.533,0,0,0-.034.763l.873.931a.533.533,0,0,0,.741.036L12.8,3.428,22.151,11.6a.534.534,0,0,0,.741-.036l.873-.931a.533.533,0,0,0-.034-.763Z"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_96430"
          data-name="Path 96430"
          d="M15.467,11.733H10.133A1.067,1.067,0,0,0,9.067,12.8V23.968a.566.566,0,0,0,.894.46L12.8,22.4l2.839,2.028a.566.566,0,0,0,.894-.46V12.8A1.067,1.067,0,0,0,15.467,11.733Z"
          fill="url(#linear-gradient-4)"
        />
        <path
          id="Path_96431"
          data-name="Path 96431"
          d="M15.467,11.2H10.133a1.6,1.6,0,0,0-1.6,1.6v9.6h.533V12.8a1.07,1.07,0,0,1,1.067-1.067h5.333A1.07,1.07,0,0,1,16.534,12.8v9.6h.533V12.8A1.6,1.6,0,0,0,15.467,11.2Z"
          opacity={0.05}
        />
        <path
          id="Path_96432"
          data-name="Path 96432"
          d="M15.467,11.467H10.133A1.334,1.334,0,0,0,8.8,12.8v9.6h.267V12.8a1.07,1.07,0,0,1,1.067-1.067h5.333A1.07,1.07,0,0,1,16.534,12.8v9.6H16.8V12.8a1.334,1.334,0,0,0-1.333-1.333Z"
          opacity={0.07}
        />
      </g>
    </g>
  </svg>
)

export default BookingComponent
