import { Box } from '@mui/material'
import React from 'react'
import { CalendarHeader } from './components/calendarHeader'
import { startOfWeek, addDays, startOfMonth, endOfMonth, endOfWeek, subDays, addMonths, subMonths } from "date-fns";
import AmenitiesBookingTable from './amenitiesBookingTable'
import styled from '@mui/material/styles/styled';
import { DailyCalendar, MonthlyCalendar, WeeklyCalendar } from '../../components'
import { FabButton } from './components';
import { Routes } from '../../router/routes';
const CustomPaper = styled('div')(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    margin: "0px 24px 24px",
    height: `calc(100vh - 202px)`,
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.only('md')]: {
        height: `calc(100vh - 250px)`,
    },
    [theme.breakpoints.only('sm')]: {
        height: `calc(100vh - 250px)`,
    },
}))
export const Calendars = (
    {
        openPopup = () => false,
        handleTableIcon = () => false,
        amenityCategoryList = [],
        selectedAmenityCategory = {},
        handleAmenityCategoryChange = () => false,
        amenityList = [],
        selectedAmenity = {},
        selectedAmenityView = {},
        handleAmenityChange = () => false,
        handleAmenityViewChange = () => false,
        searchText = "",
        handleSearchTextChange = () => false,
        amenityBooking = {},
        permission = {},
        page = {},
        limit = {},
        handlePagination = () => false,
        handleChangeLimit = () => false,
        getDateRange = () => false,
        handleEvent = () => false,
        calendarData = {},
        handleMonthEvent = () => false,
        setWeekdates = () => false,
        weekdates = {},
        selected = {},
        setSelected = () => false,
        t = () => false,
        fromType = ""
    }
) => {

    // Daily, weekly, monthly calendar states and functions

    const [open, setOpen] = React.useState(false)
    const [select, setSelect] = React.useState("nonlist")

    const showAll = () => {
        setOpen(!open)
    }

    const setDropdownValue = (value) => {
        setSelected(value)
        sendDateRange([], value)
        setOpen(false)
    }

    // Layout switch ( list or calendar )
    const setselect = (e) => {
        setSelect(e)
        setSelected("Weekly")
    }

    // weekly calendar functions

    let weekstartDate = weekdates.startDate
    const weekendDate = weekdates.endDate

    var dates = []
    while (weekstartDate <= weekendDate) {
        dates.push(weekstartDate)
        weekstartDate = addDays(weekstartDate, 1)
    }
    const weeknext = () => {
        let tempDateRange = [addDays(weekdates.endDate, 1), addDays(weekdates.endDate, 7)]
        setWeekdates({
            startDate: tempDateRange[0], endDate: tempDateRange[1]
        })
        sendDateRange(tempDateRange, selected)
    }

    const weekprev = () => {
        let tempDateRange = [subDays(weekdates.startDate, 7), subDays(weekdates.startDate, 1)]
        setWeekdates({
            startDate: tempDateRange[0], endDate: tempDateRange[1]
        })
        sendDateRange(tempDateRange, selected)
    }

    // Month rendar funtion and state
    const [month, setMonth] = React.useState(new Date())
    const [finalDays, setFinalDays] = React.useState([])

    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(monthStart);
    const monthstartDate = startOfWeek(monthStart);
    const monthendDate = endOfWeek(monthEnd);

    const monthnext = () => {
        let tempMonth = addMonths(month, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        let tempDateRange = [monthStart, monthEnd]
        setMonth(tempMonth)
        let finaldays = dateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setFinalDays(finaldays)
        sendDateRange(tempDateRange, selected)
    }

    const monthprev = () => {
        let tempMonth = subMonths(month, 1)
        let monthStart = startOfMonth(tempMonth);
        let monthEnd = endOfMonth(monthStart);
        let tempDateRange = [monthStart, monthEnd]
        setMonth(tempMonth)
        let finaldays = dateRange(startOfWeek(monthStart), endOfWeek(monthEnd))
        setFinalDays(finaldays)
        sendDateRange(tempDateRange, selected)
    }

    // Daily Calendar Functions

    const [daily, setDaily] = React.useState({ date: new Date() })
    const nextdate = () => {
        let tempDaily = addDays(daily?.date, 1)
        let tempDateRange = [tempDaily, tempDaily]
        setDaily({ ...daily, date: tempDaily })
        sendDateRange(tempDateRange, selected)
    }
    const prevdate = () => {
        let tempDaily = subDays(daily?.date, 1)
        let tempDateRange = [tempDaily, tempDaily]
        setDaily({ ...daily, date: tempDaily })
        sendDateRange(tempDateRange, selected)
    }

    const sendDateRange = (date_range = [], type = selected) => {
        let dateRange = []
        switch (type) {
            case "Daily":
                dateRange = date_range?.length > 0 ? date_range : [daily?.date, daily?.date]
                getDateRange(dateRange, type)
                break;
            case "Weekly":
                dateRange = date_range?.length > 0 ? date_range : [weekdates?.startDate, weekdates?.endDate]
                getDateRange(dateRange, type)
                break;
            case "Monthly":
                dateRange = date_range?.length > 0 ? date_range : [monthStart, monthEnd]
                getDateRange(dateRange, type)
                break;
            default:
                dateRange = date_range?.length > 0 ? date_range : [weekdates?.startDate, weekdates?.endDate]
                getDateRange(dateRange, type)
                break;
        }
    }

    //dateRange
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    React.useEffect(() => {
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        sendDateRange()
        // eslint-disable-next-line
    }, [])

    return (
        <Box>
            <CalendarHeader
                selected={selected}
                weekprev={weekprev}
                weeknext={weeknext}
                monthnext={monthnext}
                monthprev={monthprev}
                month={month}
                weekdates={weekdates}
                setDropdownValue={setDropdownValue}
                setselect={setselect}
                select={select}
                showAll={showAll}
                open={open}
                daily={daily.date}
                nextdate={nextdate}
                prevdate={prevdate}
                amenityCategoryList={amenityCategoryList}
                selectedAmenityCategory={selectedAmenityCategory}
                handleAmenityCategoryChange={handleAmenityCategoryChange}
                amenityList={amenityList}
                selectedAmenity={selectedAmenity}
                selectedAmenityView={selectedAmenityView}
                handleAmenityChange={handleAmenityChange}
                handleAmenityViewChange={handleAmenityViewChange}
                t={t}
            />
            <CustomPaper sx={{overflow: select === "nonlist" ? "hidden" : "auto" }}>
                {
                    select === "nonlist" ?
                        <Box p={2}>
                            <Box>
                                {
                                    selected === "Daily" &&
                                    <DailyCalendar progress="0" list={calendarData?.dailyList} handleEvent={handleEvent} />
                                }
                            </Box>
                            <Box>
                                {
                                    selected === "Weekly" &&
                                    <WeeklyCalendar weekdates={dates} data={calendarData?.weeklyList} handleEvent={handleMonthEvent} />
                                }
                            </Box>
                            <Box>
                                {
                                    selected === "Monthly" &&
                                    <MonthlyCalendar dates={finalDays} month={month} events={calendarData?.monthlyList} handleEvent={handleMonthEvent} />
                                }
                            </Box>
                        </Box>
                        :
                        <Box>
                            <AmenitiesBookingTable
                                handleTableIcon={handleTableIcon}
                                searchText={searchText}
                                handleSearchTextChange={handleSearchTextChange}
                                amenityBooking={amenityBooking}
                                permission={permission}
                                page={page}
                                limit={limit}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                fromType={fromType} />
                        </Box>

                }
                {window.location.pathname === Routes?.amenitiesBooking &&
                    <FabButton openPopup={openPopup} select={select} />}

            </CustomPaper>
        </Box>
    )
}