/* eslint-disable array-callback-return */
import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TextBox } from "../../../components";
import { stringAvatar, useWindowDimensions } from "../../../utils";
import { useStyles } from "./style";
import { SearchFilter } from "../../../components";
export const SettingList = ({ t, title = "Contacts Lists", list = [], selected = 1, name = "", handleSearch = () => false, searchText = "", isSearch = false, setSelected = () => false, contactSetting = false, fetchMoreData = () => false }, seacrh = true, styles = false) => {
  const classes = useStyles()
  const [search, setSearch] = React.useState("")
  const size = useWindowDimensions()

  return (
    <>
      {!contactSetting ? (
        <Box className={classes.listRoot}>
          {!seacrh && (
            <>
              <TextBox
                onChange={(e) => setSearch(e.target.value)}
                label={false}
                placeholder={t("Search Settings")}
                color="#F5F7FA"
              />
              <Box height="12px" />
            </>
          )}

          {list
            ?.filter((s) => {
              if (s === "") {
                return s;
              } else if (
                s?.label?.toLowerCase()?.includes(search?.toLowerCase())
              ) {
                return s;
              }
            })
            ?.map((val) => {
              return (
                <Typography
                  onClick={() => setSelected(val)}
                  className={
                    selected === val?.value
                      ? classes.labelSelected
                      : classes.lable
                  }
                >
                  {val?.label}
                </Typography>
              );
            })}
        </Box>
      ) : (
        <>
          <Typography className={classes.heading}>{title}</Typography>
          {isSearch === true &&
            <Box m={2}>
              <SearchFilter placeholder={name} handleChange={(value) => handleSearch(value)} value={searchText} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
            </Box>
          }
          <InfiniteScroll
            dataLength={list?.length ?? ""}
            next={fetchMoreData}
            hasMore={true}
            height={size?.height - 250}
          >
            <Box className={styles === "true" ? classes.listRoot : classes.newListRoot}>
              {list?.map((val) => {
                return (
                  <Box
                    display={"flex"}
                    onClick={() => setSelected(val)}
                    className={
                      selected === val?.id
                        ? classes.labelSelectedContact
                        : classes.lableContact
                    }
                  >
                    <Box>
                      {/* { (
                        <Avatar src={val?.image_url} />
                      ) : val?.logo ? (
                        <Avatar  />
                      ) : ( */}
                      <Avatar src={val?.image_url ? val?.image_url : val?.logo} {...stringAvatar(val?.first_name)}></Avatar>
                      {/* )} */}
                    </Box>
                    <Box sx={{ marginInlineStart: "8px" }}>
                      <Typography
                        className={
                          selected === val?.id
                            ? classes.labelSelectedContact
                            : classes.lableContact
                        }
                      >
                        {val?.first_name && val?.first_name}
                        {val?.name && val?.name}

                        <Typography
                          className={
                            selected === val?.id
                              ? classes.jobTextSelected
                              : classes.jobText
                          }
                        >
                          {
                            val?.job_title && (
                              <span>{val?.job_title} {(val?.job_title && val?.user_no) && <>&nbsp;|&nbsp;</>}</span>
                            )
                          }
                          {val?.address && (val?.address?.city ?? "-")} {" "}

                          {
                            val?.user_no && (
                              <span> {val?.user_no}</span>
                            )
                          }
                          {
                            val?.property_no && (
                              <span>&nbsp; |&nbsp; {val?.property_no}</span>
                            )
                          }


                        </Typography >
                      </Typography >
                    </Box >
                  </Box >
                );
              })}
            </Box >
          </InfiniteScroll >
        </>
      )}
    </>
  );
}