import * as React from "react"
export const ProjectedBOM = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_116017" data-name="Group 116017" transform="translate(0.001)">
                <g id="Rectangle_58161" data-name="Rectangle 58161" transform="translate(-0.001)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <path id="icons8-screw_1_" data-name="icons8-screw (1)" d="M3.266,13.78l1.381,4.2-2.868.986A.655.655,0,0,1,1.587,19a.588.588,0,0,1-.556-.779l1.111-3.232a.548.548,0,0,1,.137-.223Zm1.675-1.675L6.4,16.55l-.747.747L4.2,12.849Zm1.624-1.624,1.46,4.449-.693.693-1.46-4.449ZM11.66,5.367l2.974,2.974c-.074.063-.153.133-.231.211L8.956,14,7.5,9.55l3.956-3.956A2.667,2.667,0,0,0,11.66,5.367ZM19,6.627a.586.586,0,0,1-.172.415l-.986.986a1.76,1.76,0,0,1-1.111.513c-.039,0-.078,0-.117,0a1.674,1.674,0,0,1-.317-.031,1.972,1.972,0,0,1-.305-.094c-.051-.023-.1-.047-.149-.074a1.732,1.732,0,0,1-.27-.184c-.043-.035-.086-.074-.125-.113l-.129-.129L12.106,4.706,12,4.592a1.72,1.72,0,0,1-.023-2.434l.986-.986a.52.52,0,0,1,.192-.129.589.589,0,0,1,.442,0,.568.568,0,0,1,.2.129l5.04,5.04A.586.586,0,0,1,19,6.627Z" transform="translate(-0.503)" fill="#b3776d" />
            </g>
        </svg>

    )
}