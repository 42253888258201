import { Box, Divider } from "@mui/material";
import React from 'react';
import { withNamespaces } from "react-i18next";
import { DetailsCardCoponent } from "../detailsCardComponent";
import { AccordianStyle } from "./styles";

const ParkingDetails = ({ details = {}, t, onClose = () => false }) => {
    const { vehicle_image, unit_no, first_name, mobile_no, mobile_no_country_code, unit_type } = details;
    const classes = AccordianStyle();

    const card = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: t("tagged_unit"),
            subtitle: `${unit_no}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: t("unit_type"),
            subtitle: `${unit_type}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: t("tenant_name"),
            subtitle: `${first_name}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: t("tenant_mobile_no"),
            subtitle: `${mobile_no} ${mobile_no_country_code}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
            },
            isActive: true,
            component: "primary_button",
            fullWidth: true,
            subtitle: t("close"),
            onClick: () => onClose(),
            variant: "contained"
        }

    ]

    return (
        <Box p={2}>
            <img className={classes.image} src={vehicle_image ?? "/images/propertytumb.png"} alt="" />
            <Divider />
            <DetailsCardCoponent border={true} components={card} />

        </Box>
    )
}
export default withNamespaces("inspection")(ParkingDetails)