import React from "react";
import { withNavBars } from "../../HOCs";
import Propspect from "./propspect";

class PropspectParent extends React.Component {
  render() {
    return <Propspect />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(PropspectParent, props);
