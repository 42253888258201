import { Avatar, Box, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { SearchFilter } from '../../../components'
import { ChangeEmployeeStyle } from './style'
import { remCalc, stringAvatar, useWindowDimensions } from '../../../utils'
import InfiniteScroll from 'react-infinite-scroll-component'

export const ChangeEmployee = ({
    dutyData = [],
    fetchMoreData = () => false,
    handleSelect = () => false,
    search = "",
    handleSearchEmploee = () => false,
    t = () => false
}) => {
    const classes = ChangeEmployeeStyle()
    const size = useWindowDimensions();
    return (
        <>
            <Box>
                <SearchFilter
                    value={search}
                    handleChange={(e) => handleSearchEmploee(e)}
                    placeholder={t("Search Employee")}
                />

                <Box mt={2}>
                    <InfiniteScroll
                        dataLength={dutyData?.employeeList?.length}
                        next={fetchMoreData}
                        hasMore={true}
                        height={size?.height - 400}
                    >
                        <Stack rowGap={1}>
                            {
                                dutyData?.employeeList?.map((data) => {
                                    return (
                                        <Stack direction="row" justifyContent={"space-between"}
                                            alignItems={"center"}
                                            className={dutyData?.selectedEmploee?.id === data?.id ? classes.selectedCard : classes.card}
                                            onClick={() => handleSelect(data)}>
                                            <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                                                <Avatar src={data?.image_url} {...stringAvatar(data?.name, remCalc(16), "50%")}></Avatar>
                                                <Stack rowGap={"4px"}>
                                                    <Typography className={classes.title}>{data?.name}</Typography>
                                                    <Typography className={classes.subTitle}>{data?.job_name}</Typography>
                                                </Stack>
                                            </Stack>
                                            {dutyData?.selectedEmploee?.id === data?.id && <Icon color="primary">check_circle</Icon>}
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                    </InfiniteScroll>
                </Box>

            </Box>
        </>
    )
}