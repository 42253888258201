import Grid from "@mui/material/Grid";
import React from 'react';
import { SelectBox, TextBox } from '..';
import MyGoogleMap from '../interactivemap/myGoogleMaps';

export const MapWithFields = (props) => {

    const {
        fields = [],
        onChangeFields = null,
        mapOptions = {},
        mapResult,
        breakpoints = {},
        mapHeight = "auto",
        autoCompletePlacement = {},
        fullScreenControl,
        fullScreenMap
    } = props;

    const switchComponents = (val) => {
        switch (val?.component) {
            case 'TextField':
                return (
                    <TextBox
                        isrequired={val?.isrequired ?? false}
                        label={val?.label ?? ''}
                        placeholder={val?.placeholder ?? ''}
                        value={val?.value ?? ""}
                        onChange={(e) => onChangeFields(val?.state_name, e.target.value)}
                        isError={val?.error ?? false}
                        errorMessage={val?.errorMessage ?? ''}
                    />
                )
            case 'Select':
                return (
                    <SelectBox
                        isRequired={val?.isrequired ?? false}
                        label={val?.label ?? ''}
                        placeholder={val?.placeholder ?? ''}
                        options={val?.options ?? []}
                        value={val?.value ?? null}
                        onChange={(value) => onChangeFields(val?.state_name, value)}
                        isError={val?.error ?? false}
                        errorMessage={val?.errorMessage ?? ''}
                    />
                )
            default:
                return null
        }
    }
    return (
        <div>
            <Grid container spacing={3}>
                <Grid
                    item
                    xs={breakpoints?.map?.xs ? breakpoints?.map?.xs : 12}
                    sm={breakpoints?.map?.sm ? breakpoints?.map?.sm : 12}
                    md={fullScreenMap ? 8 : breakpoints?.map?.md ? breakpoints?.map?.md : 5}
                    lg={fullScreenMap ? 8 : breakpoints?.map?.lg ? breakpoints?.map?.lg : 8}
                    xl={fullScreenMap ? 8 : breakpoints?.map?.xl ? breakpoints?.map?.xl : 8}
                    style={{
                        height: fullScreenMap ? "100vh" : mapHeight,
                        // position: "relative"
                    }}
                >
                    <MyGoogleMap
                        isInput={mapOptions?.isInput}
                        lat={mapOptions?.lat}
                        lng={mapOptions?.lng}
                        center={mapOptions?.center}
                        mapLoad={mapOptions?.mapLoad}
                        mapResult={mapResult}
                        autoCompletePlacement={{
                            // top: 30,
                            ...autoCompletePlacement
                        }}
                        fullScreenControl={fullScreenControl}
                        fullScreenMap={fullScreenMap}

                    />
                </Grid>
                <Grid
                    item
                    xs={breakpoints?.components?.xs ? breakpoints?.components?.xs : 12}
                    sm={breakpoints?.components?.sm ? breakpoints?.components?.sm : 12}
                    md={fullScreenMap ? 4 : breakpoints?.components?.md ? breakpoints?.components?.md : 7}
                    lg={fullScreenMap ? 8 : breakpoints?.components?.lg ? breakpoints?.components?.lg : 4}
                    xl={fullScreenMap ? 8 : breakpoints?.components?.xl ? breakpoints?.components?.xl : 4}
                >
                    <Grid container spacing={2}>
                        {
                            fields?.length && fields?.map(_ => (
                                <Grid item xs={12} sm={_?.breakpoints?.sm ?? 12}
                                    md={fullScreenMap ? 6 : _?.breakpoints?.md ?? 4}
                                    lg={_?.breakpoints?.lg ?? 4}
                                    xl={_?.breakpoints?.xl ?? 4}
                                >
                                    {switchComponents(_)}
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}