import React from "react";
import { withNavBars } from "../../../HOCs";
import DeliveryOrderCollection from './deliveryOrderCollection';

class DeliveryOrderCollectionParent extends React.Component {
  render() {
    return <DeliveryOrderCollection {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(DeliveryOrderCollectionParent, props);
