import * as React from "react"

export const DownloadImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16.01} {...props}>
    <path
      data-name="icons8-download (2)"
      d="M7.99 0a.667.667 0 0 0-.656.676v9.5L5.8 8.65a.667.667 0 1 0-.943.943l2.667 2.667a.667.667 0 0 0 .943 0l2.667-2.667a.667.667 0 1 0-.943-.943l-1.529 1.529v-9.5a.667.667 0 0 0-.677-.676ZM2.445 4.9A2.455 2.455 0 0 0 0 7.343v6.222a2.455 2.455 0 0 0 2.444 2.444h11.112A2.455 2.455 0 0 0 16 13.566V7.343a2.455 2.455 0 0 0-2.444-2.444h-2.667a.667.667 0 1 0 0 1.333h2.667a1.1 1.1 0 0 1 1.111 1.111v6.222a1.1 1.1 0 0 1-1.111 1.111H2.444a1.1 1.1 0 0 1-1.111-1.111V7.343a1.1 1.1 0 0 1 1.111-1.111h2.667a.667.667 0 1 0 0-1.333Z"
      fill="#091b29"
    />
  </svg>
)
