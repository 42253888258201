import React from "react"

export const InputFieldIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g id="Group_113387" data-name="Group 113387" transform="translate(-541 -418)">
            <g id="Rectangle_57465" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                <rect width="26" height="26" stroke="none" />
                <rect x="0.5" y="0.5" width="25" height="25" fill="none" />
            </g>
            <path id="icons8-text-input-form" d="M6.4,11A3.419,3.419,0,0,0,3,14.4V23.69a3.419,3.419,0,0,0,3.4,3.4H25.6a3.419,3.419,0,0,0,3.4-3.4V14.4A3.419,3.419,0,0,0,25.6,11Zm0,1.857H25.6A1.534,1.534,0,0,1,27.143,14.4V23.69A1.534,1.534,0,0,1,25.6,25.238H6.4A1.534,1.534,0,0,1,4.857,23.69V14.4A1.534,1.534,0,0,1,6.4,12.857Zm1.238,8.667a.929.929,0,1,0,.929.929.929.929,0,0,0-.929-.929Zm3.1,0a.929.929,0,1,0,.929.929.929.929,0,0,0-.929-.929Zm3.1,0a.929.929,0,1,0,.929.929.929.929,0,0,0-.929-.929Z" transform="translate(538 412.571)" fill="#78b1fe" />
        </g>
    </svg>

)