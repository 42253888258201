import { Box } from "@mui/material"
import React from "react"
import DomesticContacts from "./domesticContacts"
import { withNavBars } from "../../HOCs"
const DomesticContactParent = () => {
    return (
        <Box>
            <DomesticContacts />
        </Box>
    )
}
const props={
    boxShadow:false
}
export default withNavBars(DomesticContactParent,props)