import { Avatar, Box, Dialog, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import FileViewer from "react-file-viewer";
import ShowMoreText from "react-show-more-text";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Slider as ImageViewer } from "../../screens/requestView/components/assets";
import { NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { AccordianStyle } from "./styles";

export const InfoCard = ({ state = {}, t = () => false, isCustom = false, is_unit = false, data = {} }) => {
    const size = useWindowDimensions()
    const classes = AccordianStyle()
    const [isViewImages, setIsViewImages] = React.useState({
        imageViewer: false,
        selected_image: []
    })
    const [list, setList] = React.useState([])
    const getDetailsBasedOnChecklist = () => {
        const payload = {
            "mapping_id": state?.inspection_item_map ?? null,
            "inspection_id": state?.id,
            "check_list_id": data?.check_list_id,
            is_unit: false
        }
        NetworkCall(
            `${config.api_url}/inspection_jobs/get_check_list_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setList([res?.data?.data])
        }).catch((err) => {
            console.log(err)
        })
    }
    React.useEffect(() => {
        getDetailsBasedOnChecklist()
        //eslint-disable-next-line
    }, [])
    const handleImageView = (data) => {
        // setProduct({ ...product, selectedAsset: data })
        setIsViewImages({
            imageViewer: true, selected_image:
                [{
                    "url": data?.url,
                    "priority": data?.priority,
                    "file_meta": {
                        "name": data?.file_meta?.name,
                        "size": data?.file_meta?.size,
                        "file_type": data?.file_meta?.type
                    },
                    "asset_type": data?.asset_type
                }]
        })
    }
    // close image viwer
    const closeImageViewer = () => {
        setIsViewImages({ ...isViewImages, imageViewer: false, selected_image: [] })
    };

    return (
        <Box p={1} height={isCustom ? size?.height - 90 : size?.height - 80} overflow={"overlay"}>
            <>
                <Box className={classes.instructionSection}>
                    {list?.length > 0 && list?.map((val) => {
                        return (
                            <>
                                <Box p={1}>
                                    <Typography className={classes.unitNoTitle}>{t("Instructions")}</Typography>
                                    <Box mt={0.5}>
                                        <ShowMoreText
                                            lines={5}
                                            more="Show More"
                                            less="Show Less"
                                            className={classes.productValue}
                                            anchorClass={classes.seeMoreLessTextStyle}
                                            expanded={false}
                                            truncatedEndingComponent={"... "}
                                        >
                                            {(val?.instructions)?.match(/\n+/g) ?
                                                <Typography className={classes.subtitle2} dangerouslySetInnerHTML={{ __html: (val?.instructions).replace(/\n+/g, "<br />") ?? "" }}>
                                                </Typography>
                                                : val?.instructions
                                            }

                                            {/* <Typography className={classes.productValue}>{val?.instructions?.split("\n")?.map((item, i) => <div key={i}>{item}</div>)}</Typography> */}
                                        </ShowMoreText>
                                    </Box>
                                </Box>
                                <Box>
                                    <Divider />
                                </Box>
                                <Box p={1}>
                                    <Typography className={classes.unitNoTitle}>{t("Url")}</Typography>
                                    <a href={val?.url} target="_blank" rel="noreferrer" className={classes.title}><Typography className={classes.title}>{val?.url ?? "-"}</Typography></a>
                                </Box>
                                <Box>
                                    <Divider />
                                </Box>
                                <Box p={1}>
                                    {val?.assets?.length > 0 &&
                                        <Box>
                                            <Typography className={classes.unitNoTitle}>{t("Documents")}</Typography>

                                            <Box mt={1}>
                                                {val?.assets?.map((x) => {
                                                    return (
                                                        <Stack direction="row" spacing={1} alignItems="center" mt={1}>


                                                            {x?.file_meta?.type === "pdf" ?
                                                                <Box className={classes.inspectionItemIcon}>
                                                                    <FileViewer fileType={x?.file_meta?.type} filePath={x?.url} />
                                                                    {/* <DocumentViewer fileType={"pdf"} filePath={`data:application/pdf;base64,${x?.url}`} /> */}
                                                                </Box>
                                                                :
                                                                <Avatar src={x?.url} variant="square" className={classes.inspectionItemIcon} />
                                                            }
                                                            <Box style={{ marginInlineStart: "8px" }}>
                                                                <Typography className={classes.title}>{x?.file_meta?.name ?? "-"}</Typography>
                                                                <Typography className={classes.documentViewTitle} onClick={() => handleImageView(x)}>{t("View")}</Typography>
                                                            </Box>



                                                            {/* <Avatar src={product?.selectedProduct?.assets !== null ? JSON.parse(product?.selectedProduct?.assets)?.[0]?.url : InspectionItem} variant="square" className={classes.inspectionItemIcon} /> */}

                                                        </Stack>
                                                    )
                                                })}
                                            </Box>

                                        </Box>
                                    }
                                </Box>
                            </>
                        )
                    })}

                </Box>
            </>
            <Dialog
                open={isViewImages?.imageViewer}
                fullWidth
                maxWidth={"sm"}
                onClose={() => closeImageViewer()}
                className={classes.dialog}
            >
                <Box p={2}>
                    <ImageViewer assets={isViewImages?.selected_image ?? []} padding={"0px 21px 0px 23px"} />
                </Box>
            </Dialog>
        </Box>
    )
}