export const SaleAgents = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98344">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g data-name="call-center-operator (1)">
                    <path
                        data-name="Path 94723"
                        d="M21.598 21v2H8v-2a3.239 3.239 0 0 1 1.8-2.573l2.212-1.045 1.533 3.194.785-2.128a2.514 2.514 0 0 0 .469.068 2.519 2.519 0 0 0 .469-.068l.745 2.023 1.5-3.125 2.279 1.084A3.24 3.24 0 0 1 21.598 21Zm-11.592-6.987v-1.441a.69.69 0 0 1 .319-.582 4.505 4.505 0 0 1 8.951 0 .691.691 0 0 1 .319.582v1.441a.693.693 0 1 1-1.386 0v-.3c-.375 1.887-1.756 3.634-3.409 3.634s-3.033-1.747-3.408-3.637v.3a.691.691 0 0 1-.488.658 4.043 4.043 0 0 0 1.733 2.284.707.707 0 0 1 .544.058c.307.16.463.47.347.692a.563.563 0 0 1-.767.111.671.671 0 0 1-.383-.5 4.491 4.491 0 0 1-1.937-2.658.692.692 0 0 1-.435-.642Zm.791-2.184a.2.2 0 0 1 .061.009.22.22 0 0 1 .112.1.691.691 0 0 1 .344.325 3.239 3.239 0 0 1 3.485-2.933 3.239 3.239 0 0 1 3.486 2.929.687.687 0 0 1 .525-.371 4.058 4.058 0 0 0-8.013-.06Z"
                        fill={props?.fill ?? "#c1c5cb"}
                    />
                </g>
            </g>
        </g>
    </svg>
)
