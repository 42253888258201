import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const quotationsStyles = makeStyles((theme) => ({
  container: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    //overflow: "hidden",
    margin: "14px",
    height: `calc(100vh - 147px)`,
  },
  summeryRoot: {
    backgroundColor: theme.palette?.background?.secondary,
    borderRadius: theme.palette.borderRadius,
  },
  unitTitle: {
    color: theme.typography?.color?.secondary,
    fontSize:"0.875rem",
    fontFamily: Bold,
  },
  btn: {
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
    },
    height: "40px",
  },
  root: {
    backgroundColor: "white",
    height: `calc(100vh - 166px)`,
    position: "relative",
  },
  btnParent: {
    position: "absolute",
    bottom: "10px",
    width:"100%",
    backgroundColor:"white"
  },
}));
