import * as React from "react"

const CustomerSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 102948" transform="translate(-11563 -17154)">
      <circle
        data-name="Ellipse 129451"
        cx={24}
        cy={24}
        r={24}
        transform="translate(11563 17154)"
        fill="#f0f9f7"
      />
      <g data-name="Group 102947">
        <path
          data-name="Rectangle 54909"
          fill="none"
          d="M11575 17166h24v24h-24z"
        />
        <path
          d="M11583.444 17169a3.111 3.111 0 1 0 3.111 3.111 3.115 3.115 0 0 0-3.111-3.111Zm7.111 0a3.111 3.111 0 1 0 3.111 3.111 3.115 3.115 0 0 0-3.11-3.111Zm-10 7.111a1.557 1.557 0 0 0-1.555 1.556v4.222a4.437 4.437 0 0 0 7.389 3.32 5.306 5.306 0 0 1-1.167-3.32v-4.222a2.434 2.434 0 0 1 .561-1.556Zm7.111 0a1.557 1.557 0 0 0-1.556 1.556v4.222a4.444 4.444 0 1 0 8.889 0v-4.222a1.557 1.557 0 0 0-1.556-1.556Z"
          fill="#43aa8b"
        />
      </g>
    </g>
  </svg>
)

export default CustomerSvg
