import { Box } from "@mui/material";
import React from "react";
import { CompanySettingsList } from "../../../components";
import { basicSettingState, returnSaveData } from "../../../utils/clientsettings";
import { SettingSave } from "../../companyCreation/components";
import { useStyles } from "../styles";



export const GlobalControls = ({ t = () => false, selected = {}, settings = {}, onSubmitSettings = () => false, isDisableBtn = false }) => {

    const classes = useStyles();
    const [data, setData] = React.useState({ ...basicSettingState })
    //update data
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setData({ ...data, [key]: value, error });
    }
    //on submit form
    const onSubmit = () => {
        const payload = returnSaveData(data)
        onSubmitSettings(payload)

    }
    //initial load
    React.useEffect(() => {
        setData(settings)
        // eslint-disable-next-line
    }, [settings])
    return (
        <Box>
            <SettingSave title={selected?.label} subTitle={selected?.subtitle} btn={true} onSave={onSubmit} isDisableBtn={isDisableBtn} t={t} />
            <Box className={classes.settingRoot}>
                {/*Visitor Pass Auto Approval*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Visitor Pass Auto Approval")}
                        onChange={(value) => updateState("is_visitor_worker_pass_approval", value)}
                        value={data?.is_visitor_worker_pass_approval}
                        toggle={true}
                    />
                </Box>
                {/*Unit Request Access Auto Approval*/}
                <Box padding="8px 12px">
                    <CompanySettingsList
                        title={t("Unit Request Access Auto Approval")}
                        onChange={(value) => updateState("is_unit_request_access_approval", value)}
                        value={data?.is_unit_request_access_approval}
                        toggle={true}
                    />
                </Box>
            </Box>
        </Box>
    )
}