import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { SearchFilter, TableWithPagination } from "../../../components";
import { FilterIcon } from "../../propertyFinder4/components";
import { InspectionMappingComponentStyles } from "./styles";
const InspectionMappingTable = ({
    handleIcon = () => false,
    list = [],
    handlePagination = () => false,
    handleChangeLimit = () => false,
    page = "",
    limit = "",
    path = [],
    heading = [],
    dataType = [],
    placeholder = "",
    searchText = "",
    handleSearch = () => false,
    onClick = () => false,
    height = "",
    add = false,
    handleAddInspection = () => false,
    onFilter = () => false,
    dot = false,
    permissions = {},
    t = () => false,
    is_loading = false,
}) => {
    const classes = InspectionMappingComponentStyles()

    return (
        <Box>
            <Grid container>
                {/* searchbar */}
                <Grid item xs={4}>
                    <SearchFilter
                        handleChange={handleSearch}
                        value={searchText}
                        placeholder={t(`${placeholder}`)}
                        customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                </Grid>
                {/* filterIcon */}
                <Grid item xs={8} >
                    {add &&
                        <Stack
                            direction="row"
                            divider={<Divider className={classes.dividerStyle} orientation="vertical" flexItem sx={{ marginInlineStart: "16px !important", }} />}
                            spacing={2}
                            justifyContent={"end"}
                        >
                            {
                                dot ?

                                    <IconButton onClick={onFilter} className={classes.filterIconbtn}>
                                        <Badge variant="dot" color="primary">
                                            <FilterIcon />
                                        </Badge>
                                    </IconButton>

                                    :
                                    <IconButton onClick={onFilter} className={classes.filterIconbtn}>
                                        <FilterIcon />
                                    </IconButton>
                            }

                            {
                                permissions?.create &&
                                <Box className={classes.filterIconBox}>
                                    <Button variant="contained" onClick={() => handleAddInspection()}>{t("Add")}</Button>
                                </Box>
                            }


                        </Stack>
                    }

                </Grid>
            </Grid>
            {/* InspectionMappingtable */}
            <TableWithPagination
                is_loading={is_loading}
                heading={heading}
                rows={list?.list}
                path={path}
                dataType={dataType}
                showpagination={true}
                count="2"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                onClick={onClick}
                handleIcon={handleIcon}
                view={true}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={list?.count}
                page={page}
                limit={limit}
                height={height}
            />
        </Box >
    )
}
export default withNamespaces("inspection")(InspectionMappingTable)