import React from "react";
import { withNavBars } from "../../HOCs";
import CreateContactNew from "../createContactNew/createContactNew";

const SalesAgentCreateNew = () => {
    return (
        <CreateContactNew type="Agent" />
    )
}

export default withNavBars(SalesAgentCreateNew)