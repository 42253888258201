import React from "react";
import { TextField, Typography, LinearProgress, Box } from "@mui/material";
import { useStyles } from "./style";

export const ProgressTextFeild = (props) => {
  const classes = useStyles(props);
  return (
    <div>
      <Typography className={classes.Label}>{props?.label}</Typography>
      <LinearProgress
        value={props?.progressValue ?? 0}
        color={props?.color}
        variant="determinate"
      className={classes.progressBar}
      />
      <Box height={"10px"} />
      <div className={classes.text}>
        <TextField
          className={classes.textbox}
          type={props.type}
          onKeyPress={(e) => {
            if (props.type === "number") {
              if (e.key === "e") {
                e.preventDefault();
              }
            }
          }}
          // autoComplete={false}
          id={props.id}
          placeholder={props.placeholder}
          variant={props.variant ?? "outlined"}
          fullWidth
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{
            readOnly: props?.isReadonly ?? false,
            autocomplete: props?.autocomplete,
          }}
          InputProps={{
            endAdornment: props?.endAdornment,
            startAdornment: props?.startAdornment,
            style: {
              padding: props.multiline ? 0 : "none",
            },
          }}
          disabled={props?.isReadonly ?? false}
          size="small"
          multiline={props.multiline}
          rows={3}
          autoFocus={props?.autoFocus}
          rowsMax={10}
          onChange={props.onChange}
          value={props.value}
          error={props.error}
          helperText={props.helperText}
          // onKeyPress={props?.onKeyPress}
        />
         {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
      </div>
      <Typography className={classes.Label1}>{props?.bottomLabel}</Typography>
    </div>
  );
};
