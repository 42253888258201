export const Path = ["coa_type", "number","name","debit_balance", "credit_balance", "opening_balance","opening_balance_date","bank_cash","is_active"]

export const Heading = (t) => [
    { title: t("Account Type"), field: "coa_type" },
    { title: t("Number"), field: "number" },
    { title: t("Name"), field: "name" },
    { title: t("Debit"), field: "debit_balance" },
    { title: t("Credit"), field: "credit_balance" },
    { title: t("Balance"), field: "opening_balance" },
    { title: t("Date"), field: "opening_balance_date" },
    { title: t("Bank & Cash"), field: "bank_cash" },
    { title: t("Status"), field: "is_active", },
    { title: "", field: "icon" },
]

export const Type = [
    { type: ["text"], name: "coa_type" },
    { type: ["text"], name: "number" },
    { type: ["long_text"], name: "name" },
    { type: ["debit"], name: "debit_balance" },
    { type: ["credit"], name: "credit_balance" },
    { type: ["text"], name: "opening_balance" },
    { type: ["date"], name: "opening_balance_date" },
    { type: ["text"], name: "bank_cash" },
    { type: ["status"], name: "is_active" },
    { type: ["more"], icon: "icon" },
]

export const defaultAddState = {
    formType: "add",
    name: "",
    reference_number: "",
    ledger: "",
    subLedger: "",
    type: "",
    number: "",
    opening_balance: "",
    credit_balance: "",
    debit_balance: "",
    opening_balance_date:null,
    is_active: true,
    error: {
        name: "",
        type: "",
        reference_number: "",
        ledger: "",
        subLedger: "",
        number: "",
        opening_balance: "",
        credit_balance: "",
        debit_balance: "",
        opening_balance_date:"",
    }
}

export const defaultFilterData = { is_active: [true], type: [], ledger: [], subLedger: [] }