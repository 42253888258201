import { Grid } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { DocumentViewer } from "../fileViewer";
export const DynamicSlider = ({ data = [], assets = false }) => {
  var settings = {
    dots: false,
    infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  }; 
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} style={{ marginTop: "10px" }} alignItems={"center"}>
          <Slider {...settings}>
            {data.map((val) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {val.assets_master_type === 3 && (
                    <video
                      style={{
                        objectFit: "cover",
                        height: assets ? "250px" : "100%",
                        width: "100%",
                      }}
                      controls
                    >
                      <source src={val.url} type="video/mp4" />
                    </video>
                  )}
                  {val.assets_master_type === 1 ||
                  val.assets_master_type === 2 ||
                  val.assets_master_type === 5 ? (
                    <img
                      src={assets ? val.url : val}
                      alt=""
                      style={{
                        height: assets ? "250px" : "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {val.assets_master_type === 4 && (
                    <div
                      style={{
                        height: assets ? "250px" : "100%",
                        width: "100%",
                        objectFit: "cover",
                        overflow: "hidden",
                      }}
                    >
                      <DocumentViewer url={assets ? val.url : val} />
                    </div>
                  )}
                </div>
              );
            })}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
};
