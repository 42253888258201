import * as React from "react"

const DebitSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 107573">
      <path data-name="Rectangle 56159" fill="none" d="M0 0h18v18H0z" />
      <path
        d="M8.091 2a1.269 1.269 0 0 0-1.273 1.263V9.4c0 .023.006.045.007.068l1.084-.639V4.165A1.085 1.085 0 0 0 9 3.083h7.455a.541.541 0 1 0 0-1.083Zm5.636 1.8a2.376 2.376 0 0 0-2.182 2.526 2.376 2.376 0 0 0 2.182 2.526 2.376 2.376 0 0 0 2.182-2.526A2.376 2.376 0 0 0 13.727 3.8ZM9.363 5.6a.722.722 0 1 0 .727.722.725.725 0 0 0-.726-.713Zm-3.273.179-1.069.326A2.726 2.726 0 0 0 3.5 7.288l-2.42 4a.535.535 0 0 0-.08.276v3.609a.544.544 0 0 0 .545.541h2.971a3.093 3.093 0 0 0 2.116-.832l4.5-4.141a1.29 1.29 0 0 0 .025-1.858 1.3 1.3 0 0 0-1.62-.18l-3.2 1.882a.542.542 0 1 1-.558-.93l.3-.179Zm6.161 3.791a1.746 1.746 0 0 1-.178 1.083h4.381a.541.541 0 1 0 0-1.083Z"
        fill="#ff9340"
      />
    </g>
  </svg>
)

export default DebitSvg
