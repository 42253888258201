export const UnitHandover = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98409">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g data-name="gift (1)" fill={props?.fill ?? "#c1c5cb"}>
                    <path
                        data-name="Path 94838"
                        d="M10.783 12.395h4.248v-1.172h.879v1.172h4.248a.439.439 0 0 0 .439-.439v-1.173a.439.439 0 0 0-.439-.439h-2.081a1.61 1.61 0 0 0-2.606-1.837 1.61 1.61 0 0 0-2.606 1.837h-2.082a.439.439 0 0 0-.439.439v1.172a.439.439 0 0 0 .439.44Zm5.861-3.516a.733.733 0 0 1 0 1.465h-.732v-.733a.733.733 0 0 1 .732-.732Zm-3.076.732a.733.733 0 0 1 1.465 0v.732h-.732a.733.733 0 0 1-.734-.732Z"
                    />
                    <path
                        data-name="Path 94839"
                        d="M22.666 17.068a1.089 1.089 0 0 0-1.53-.1l-1.751 1.527v.067a1.979 1.979 0 0 1-1.977 1.977h-2.354a.44.44 0 0 1 0-.879h2.36a1.1 1.1 0 1 0 0-2.2h-2.3a3.61 3.61 0 0 0-5.039-.376l-.206.174L12.03 22.3h5.584a2.194 2.194 0 0 0 1.468-.562l3.5-3.143a1.089 1.089 0 0 0 .084-1.527Z"
                    />
                    <path
                        data-name="Path 94840"
                        d="M15.91 16.588h1.5a1.979 1.979 0 0 1 1.707.978l.9-.781v-3.512H15.91Z"
                    />
                    <path
                        data-name="Path 94841"
                        d="M12.4 15.362c.113 0 .228 0 .342.013a4.464 4.464 0 0 1 2.29.835v-2.937H10.93v2.339a4.471 4.471 0 0 1 1.47-.25Z"
                    />
                    <path
                        data-name="Path 94842"
                        d="M8.266 18.055a.439.439 0 0 0-.231.577l1.758 4.1a.439.439 0 0 0 .577.231l.833-.357-2.102-4.908Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
