import React from "react";
import { withNavBars } from "../../HOCs";
import { ShortTermPricing } from "./shortTermPricing";

class ShortTermPricingParent extends React.Component {
  render() {
    return <ShortTermPricing />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ShortTermPricingParent, props);
