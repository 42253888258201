import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import ToolsMaster from './toolsMaster'

class ToolsMasterParent extends Component {
    render() {
        return (
            <div>
                <ToolsMaster />
            </div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ToolsMasterParent, props)