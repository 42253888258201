import { Box, Stack, Typography, Divider, Popover } from '@mui/material'
import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { calendarDropDownStyle } from './style'

export const CalendarDropDown = ({ selected = {}, showAll = () => false, setDropdownValue = () => false, right = "", select = "", t = () => false }) => {
    const calendarType = right ? ["Weekly", "Monthly"] : ["Daily", "Weekly", "Monthly"]
    const classes = calendarDropDownStyle(right)

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    React.useEffect(() => {
        if (select === "P") {
            setDropdownValue("Daily")
        }
        // eslint-disable-next-line
    }, [select])
    return (
        <Box
            className={right ? classes.dropdown2 : classes.dropdown}
        >

            {/* <Stack direction={"row"} spacing={0} alignItems={"center"} justifyContent={"space-between"} aria-describedby={id} onClick={handleClick}>

                <Typography className={classes.value}> {select === "P" ? "Daily" : selected} </Typography>
                <Box pl={2}>

                    {
                        select === "P" ? <KeyboardArrowDownIcon /> :
                            open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                    }
                </Box>
            </Stack> */}
            <Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }} justifyContent={"space-between"} aria-describedby={id} onClick={handleClick}>
                <Typography className={classes.value}> {select === "P" ? t("Daily") : t(selected)} </Typography>
                {
                    select === "P" ? <KeyboardArrowDownIcon /> :
                        open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
            </Stack>

            {select === "P" ? "" :
                // <Dialog
                //     open={open}
                //     onClose={showAll}
                //     aria-labelledby="alert-dialog-title"
                //     aria-describedby="alert-dialog-description"
                //     className={right ? classes.customDialogForParking : classes.customDialog}>
                <Popover
                    sx={{ marginTop: "12px", marginLeft: "-10px" }}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {/* <DialogContent> */}
                    <Box className={classes.content} sx={{ width: 120, padding: "4px" }}>
                        <Stack spacing={1} alignItems={"left"}>
                            {
                                calendarType.map((e, index, length) => {
                                    return (
                                        <>
                                            <Typography className={selected === e ? classes.selected : classes.listText}
                                                onClick={() => {
                                                    setDropdownValue(e)
                                                    handleClose()
                                                }}> {t(e)} </Typography>
                                            {length.length - 1 !== index && <Divider />}
                                        </>
                                    )
                                })
                            }
                        </Stack>
                        {/* <Box mt={1} mb={1}>
                          
                        </Box> */}
                    </Box>
                    {/* </DialogContent> */}
                    {/* </Dialog> */}
                </Popover>
            }
        </Box>
    )
}