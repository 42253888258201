export const Contacts = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98446">
            <path
                data-name="Path 94962"
                d="M10.222 14.5a.566.566 0 0 1-.556.577h-1.11A.566.566 0 0 1 8 14.5a.566.566 0 0 1 .556-.577h1.111a.566.566 0 0 1 .556.577Zm0-3.692a.566.566 0 0 0-.556-.577h-1.11a.566.566 0 0 0-.556.577.566.566 0 0 0 .556.577h1.111a.566.566 0 0 0 .556-.577Zm0 7.385a.566.566 0 0 0-.556-.577h-1.11a.566.566 0 0 0-.556.577.566.566 0 0 0 .556.577h1.111a.566.566 0 0 0 .556-.577ZM20 8.846v11.308A1.813 1.813 0 0 1 18.222 22h-7.777a1.813 1.813 0 0 1-1.778-1.846v-.923h1a1.039 1.039 0 0 0 0-2.077h-1v-1.616h1a1.039 1.039 0 0 0 0-2.077h-1v-1.615h1a1.039 1.039 0 0 0 0-2.077h-1v-.923A1.813 1.813 0 0 1 10.444 7h7.778A1.813 1.813 0 0 1 20 8.846Zm-7.048 3.626a1.715 1.715 0 1 0 3.428 0 1.715 1.715 0 1 0-3.428 0Zm4.381 4.352a1.943 1.943 0 0 0-1.9-1.978H13.9a1.943 1.943 0 0 0-1.9 1.978v.791h5.333Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path data-name="Rectangle 52142" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
