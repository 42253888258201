import * as React from "react"

export const GatepassIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={19.048}
    height={20}
    {...props}
  >
    <defs>
      <linearGradient
        id="c"
        x1={0.5}
        y1={-71.071}
        x2={0.5}
        y2={-72.071}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0.502} stopColor="#4a4a4a" />
        <stop offset={0.7} stopColor="#444" />
        <stop offset={0.97} stopColor="#323232" />
        <stop offset={1} stopColor="#303030" />
      </linearGradient>
      <linearGradient
        id="b"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#912fbd" />
        <stop offset={1} stopColor="#9332bf" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={-0.137}
        y1={-65.643}
        x2={1.053}
        y2={-65.643}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ae4cd5" />
        <stop offset={1} stopColor="#ac4ad5" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={-2.804}
        y1={-65.643}
        x2={-1.614}
        y2={-65.643}
        xlinkHref="#a"
      />
      <linearGradient
        id="e"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c965eb" />
        <stop offset={1} stopColor="#c767e5" />
      </linearGradient>
      <linearGradient id="f" y1={-45.7} y2={-45.7} xlinkHref="#b" />
      <linearGradient
        id="g"
        y1={-60.75}
        x2={1}
        y2={-60.75}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#7819a2" />
        <stop offset={1} stopColor="#771aa9" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={0.146}
        y1={-24.246}
        x2={0.854}
        y2={-24.954}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#21ad64" />
        <stop offset={1} stopColor="#088242" />
      </linearGradient>
    </defs>
    <g data-name="Group 102961">
      <path
        data-name="Path 97079"
        d="M35.381 35.476A.476.476 0 0 0 34.9 35h-1.424a.476.476 0 0 0-.476.476v2.381a.476.476 0 0 0 .476.476H34.9a.476.476 0 0 0 .476-.476Z"
        transform="translate(-21.095 -21.19)"
        fill="url(#c)"
      />
      <path
        data-name="Path 97080"
        d="M12.381 35.476A.476.476 0 0 0 11.9 35h-1.424a.476.476 0 0 0-.476.476v2.381a.476.476 0 0 0 .476.476H11.9a.476.476 0 0 0 .476-.476Z"
        transform="translate(-9.048 -21.19)"
        fill="url(#c)"
      />
      <path
        data-name="Path 97081"
        d="M23.333 16.476V7.429A1.428 1.428 0 0 0 21.9 6H11.429A1.428 1.428 0 0 0 10 7.429v9.048Z"
        transform="translate(-9.048 -6)"
        fill="url(#b)"
      />
      <path
        data-name="Path 97082"
        d="M36.857 17.857a.476.476 0 0 1-.476.476h-1.9a.476.476 0 0 1-.481-.476v-2.381a.476.476 0 0 1 .476-.476h1.9a.476.476 0 0 1 .476.476Z"
        transform="translate(-21.619 -10.714)"
        fill="url(#a)"
      />
      <path
        data-name="Path 97083"
        d="M8 15.476A.476.476 0 0 1 8.476 15h1.9a.476.476 0 0 1 .476.476v2.381a.476.476 0 0 1-.476.476h-1.9A.476.476 0 0 1 8 17.857Z"
        transform="translate(-8 -10.714)"
        fill="url(#d)"
      />
      <path
        data-name="Path 97084"
        d="M23.238 26.952a.953.953 0 0 0-.952-.952H8.952a.953.953 0 0 0-.952.952v3.81a.953.953 0 0 0 .952.952h13.334a.953.953 0 0 0 .952-.952Z"
        transform="translate(-8 -16.476)"
        fill="url(#e)"
      />
      <path
        data-name="Path 97085"
        d="M23.476 19.1H13v-7.148a.953.953 0 0 1 .952-.952h8.571a.953.953 0 0 1 .952.952Z"
        transform="translate(-10.619 -8.619)"
        fill="url(#e)"
      />
      <circle
        data-name="Ellipse 129444"
        cx={0.952}
        cy={0.952}
        r={0.952}
        transform="translate(.952 10.952)"
        fill="#fff"
      />
      <circle
        data-name="Ellipse 129445"
        cx={0.952}
        cy={0.952}
        r={0.952}
        transform="translate(12.381 10.952)"
        fill="#fff"
      />
      <path
        data-name="Path 97086"
        d="M23.571 13.476A.476.476 0 0 0 23.1 13h-7.624a.476.476 0 0 0-.476.476v3.81a.476.476 0 0 0 .476.476H23.1a.476.476 0 0 0 .476-.476Z"
        transform="translate(-11.667 -9.667)"
        fill="url(#f)"
      />
      <path
        data-name="Path 97087"
        d="M16.476 28h6.667a.476.476 0 0 1 .476.476v2.857a.476.476 0 0 1-.476.476h-6.667a.476.476 0 0 1-.476-.476v-2.857a.476.476 0 0 1 .476-.476Z"
        transform="translate(-12.19 -17.524)"
        fill="url(#g)"
      />
      <path
        data-name="Path 97088"
        d="M37.524 32.762A4.762 4.762 0 1 1 32.762 28a4.762 4.762 0 0 1 4.762 4.762Z"
        transform="translate(-18.476 -17.524)"
        fill="url(#h)"
      />
      <path
        data-name="Path 97089"
        d="m13.641 17.311-1.9-1.9a.238.238 0 0 1 0-.337l.337-.337a.238.238 0 0 1 .337 0l1.4 1.4 2.59-2.59a.238.238 0 0 1 .337 0l.337.337a.238.238 0 0 1 0 .337l-3.1 3.1a.238.238 0 0 1-.338-.01Z"
        fill="#fff"
      />
    </g>
  </svg>
)


