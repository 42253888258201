import React from "react";

export const Image = (props) => {
    const width = props.width ? props.width : "36";
    const height = props.height ? props.height : "36";
    const color = props.color ? props.color : "#e4e8ee";
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 36 36"
        >
            <path id="icons8-image_3_" data-name="icons8-image (3)" d="M11.5,6A5.523,5.523,0,0,0,6,11.5v25A5.523,5.523,0,0,0,11.5,42h25A5.523,5.523,0,0,0,42,36.5v-25A5.523,5.523,0,0,0,36.5,6Zm0,3h25A2.478,2.478,0,0,1,39,11.5V31.955l-6.012-5.816,0,0a4.045,4.045,0,0,0-5.625,0l-2.049,1.982-6.18-5.979a4.038,4.038,0,0,0-5.627,0l0,0L9,26.5v-15A2.478,2.478,0,0,1,11.5,9Zm19,4a4.506,4.506,0,0,0-3.371,1.441,4.708,4.708,0,0,0,0,6.117,4.663,4.663,0,0,0,6.742,0,4.708,4.708,0,0,0,0-6.117A4.506,4.506,0,0,0,30.5,13Zm0,3a1.32,1.32,0,0,1,1.129.434,1.718,1.718,0,0,1,0,2.133,1.686,1.686,0,0,1-2.258,0,1.718,1.718,0,0,1,0-2.133A1.32,1.32,0,0,1,30.5,16ZM16.318,24a1.029,1.029,0,0,1,.727.3l0,0,6.107,5.908L14.064,39H11.5A2.478,2.478,0,0,1,9,36.5V30.674L15.59,24.3A1.034,1.034,0,0,1,16.318,24Zm13.855,4a1.026,1.026,0,0,1,.729.295L39,36.129V36.5A2.478,2.478,0,0,1,36.5,39H18.381L29.447,28.295A1.028,1.028,0,0,1,30.174,28Z" transform="translate(-6 -6)"
                fill={color}
            />
        </svg>
    );
};
