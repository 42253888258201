export const AssetInventory = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105114">
            <g
                data-name="FM - Asset Management - Asset Inventory"
                transform="translate(4 4)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path data-name="Path 97878" d="M6.862 7.966h.552v1.1h-.552Z" />
                <circle
                    data-name="Ellipse 129540"
                    cx={0.276}
                    cy={0.276}
                    r={0.276}
                    transform="translate(16.241 7.966)"
                />
                <path
                    data-name="Path 97879"
                    d="M9.069 6.208a.1.1 0 0 0-.036-.078l-1.59-1.362a.47.47 0 0 0-.611 0L5.242 6.13a.1.1 0 0 0-.036.078.1.1 0 0 0 .1.1h3.661a.1.1 0 0 0 .102-.1Z"
                />
                <path
                    data-name="Path 97880"
                    d="M11 7.966a3.013 3.013 0 0 1 .333.019.821.821 0 0 1-.057-.3v-.873a.554.554 0 0 1 .443-.541l.978-.2.151-.756a.83.83 0 0 1 .812-.665h2.406a.829.829 0 0 1 .811.665l.151.755.977.2a.554.554 0 0 1 .443.541v.879a.829.829 0 0 1-.828.828h-.326a.823.823 0 0 1-1.554 0h-1.757a.825.825 0 0 1-.656.54 3.026 3.026 0 0 1 .612 1.207 4.723 4.723 0 0 1 .922-.091 4.652 4.652 0 0 1 2.125.514 4.137 4.137 0 1 0-6.177-2.712c.063 0 .126-.01.19-.01Z"
                />
                <path
                    data-name="Path 97881"
                    d="M7.138 11.276a4.161 4.161 0 0 0 .837-.086c0-.063-.01-.126-.01-.19A3.015 3.015 0 0 1 8.3 9.621H5.759a.552.552 0 0 1-.552-.552V6.852a.652.652 0 0 1-.324-1.14l1.59-1.362a1.047 1.047 0 0 1 1.329 0l1.59 1.362a.652.652 0 0 1-.323 1.14v1.809a3.027 3.027 0 0 1 1.194-.6 4.723 4.723 0 0 1-.091-.922 4.656 4.656 0 0 1 .514-2.125 4.137 4.137 0 1 0-3.549 6.263Z"
                />
                <circle
                    data-name="Ellipse 129541"
                    cx={0.276}
                    cy={0.276}
                    r={0.276}
                    transform="translate(12.931 7.966)"
                />
                <path
                    data-name="Path 97882"
                    d="M5.013 11.313a4.145 4.145 0 0 0-1.183 1.065H8.3a3.018 3.018 0 0 1-.241-.643 4.718 4.718 0 0 1-.922.091 4.651 4.651 0 0 1-2.125-.514Z"
                />
                <path data-name="Path 97883" d="M12.931 15.69h4.414v.552h-4.414Z" />
                <path data-name="Path 97884" d="M14.586 16.793h1.1v.552h-1.1Z" />
                <path
                    data-name="Path 97885"
                    d="M8.518 9.069V6.862H5.759v2.207h.552V7.414h1.655v1.655Z"
                />
                <path
                    data-name="Path 97886"
                    d="M17.345 12.931h-4.006a3.065 3.065 0 0 1-.408.408v1.8h4.414Z"
                />
                <path
                    data-name="Path 97887"
                    d="M11 8.517A2.483 2.483 0 1 0 13.483 11 2.485 2.485 0 0 0 11 8.517Zm.828 2.207h-.552v-.276a.276.276 0 0 0-.276-.276h-.276v.276a.276.276 0 0 0 .276.276.829.829 0 0 1 .828.828v.828h-.552v.552h-.552v-.6a.826.826 0 0 1-.552-.777v-.276h.552v.276a.276.276 0 0 0 .276.276h.276v-.276a.276.276 0 0 0-.276-.276.829.829 0 0 1-.828-.828v-.834h.552v-.552h.552v.6a.826.826 0 0 1 .552.777Z"
                />
                <path
                    data-name="Path 97888"
                    d="M17.294 7.966h.327a.276.276 0 0 0 .276-.276v-.878l-1.338-.268-.223-1.116a.276.276 0 0 0-.27-.222H13.66a.276.276 0 0 0-.27.222l-.223 1.116-1.339.268v.878a.276.276 0 0 0 .276.276h.324a.823.823 0 0 1 1.554 0h1.757a.823.823 0 0 1 1.553 0Z"
                />
                <path
                    data-name="Path 97889"
                    d="M6.862 17.896h2.759v-1.655H6.862Zm1.655-1.1h.552v.545h-.552Zm-1.1 0h.545v.545H7.41Z"
                />
                <path
                    data-name="Path 97890"
                    d="M7.134 19a4.109 4.109 0 0 0 2.059-.552H5.082A4.109 4.109 0 0 0 7.134 19Z"
                />
                <path
                    data-name="Path 97891"
                    d="M11 14.034a3.032 3.032 0 0 1-2.483-1.3v1.3h-.551v1.655h2.207v1.976a4.118 4.118 0 0 0 1.1-2.8 4.161 4.161 0 0 0-.086-.837c-.06 0-.121.006-.187.006Z"
                />
                <path
                    data-name="Path 97892"
                    d="M14.862 10.724a4.162 4.162 0 0 0-.837.086c0 .063.01.126.01.19a3.015 3.015 0 0 1-.335 1.379h3.645a.552.552 0 0 1 .552.552v3.31a.552.552 0 0 1-.552.552h-1.1v.552h.552v.552h-3.319v-.552h.552v-.552h-1.1a.552.552 0 0 1-.552-.552v-2.542a3.013 3.013 0 0 1-.643.241 4.718 4.718 0 0 1 .091.922 4.651 4.651 0 0 1-.514 2.125 4.137 4.137 0 1 0 3.55-6.263Z"
                />
                <path
                    data-name="Path 97893"
                    d="M6.31 15.689h1.1v-1.655H4.655v3.862H6.31Zm0-1.1h.552v.545H6.31Zm-.555 2.755h-.548v-.552h.548Zm0-1.1h-.548v-.552h.548Zm0-1.1h-.548v-.552h.548Z"
                />
                <path
                    data-name="Path 97894"
                    d="M3.552 12.801a4.119 4.119 0 0 0 .548 4.865v-3.632h-.548Z"
                />
                <path data-name="Path 97895" d="M4.103 12.931h3.862v.552H4.103Z" />
            </g>
        </g>
    </svg>
)