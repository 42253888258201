import React, { useState, useMemo, useContext } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import { AlertContext, AuthContext, BackdropContext } from "../contexts";
import { NetworkCall } from "../networkcall";
import { useHistory } from 'react-router-dom';
import { config } from "../config";
import { accessCheckRender, AlertProps, bussinesssEditActions, bussinessUpdateActions, getCompanyOption, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, propertyEditActions, propertyUpdateActions, SemiBold, serviceAddActions, serviceEditActions } from "../utils";
import { getTableActions } from '../screens/master/utils';
import { FilterGenerator, GenerateForm, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../components";
import Box from "@mui/material/Box";
import { Badge, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";
import { NewLoader } from "../components/newLoader";
import FilterIMG from "../assets/filter";

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.palette.borderRadius,
  fontSize: "0.875rem",
  height: "40px",
  fontFamily: SemiBold,
  textTransform: 'capitalize',

}))

const CustomPaper = styled('div')(({ theme }) => ({
  border: "2px solid white",
  borderRadius: theme.palette.borderRadius,
  boxShadow: "0px 0px 16px #00000014",
  backgroundColor: "white",
  padding: "16px",
  height: `calc(100vh - 147px)`,
  overflow: "hidden",
  margin: "14px"
}))

const withMasters = (Component) => (props) => {
  const defaultFilterState = { status: [true] };
  const { type, title, routes = null, create, loading, handleLoading = () => false, t = () => false, header } = props;

  //---------------------effects--------------------------

  //---------------------states--------------------------

  const [editId, setEditId] = useState(null);
  const [open, setOpen] = useState(false);
  const typeH = type;
  const [tableData, setTableData] = useState({
    data: {},
    drawer: [],
    totalRowsCount: 0,
  });
  const [main, setMain] = useState({
    isD: false,
    Main: "",
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [disable, setDiable] = useState(false);
  const [readOnly, setRead] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  // const { setBackDrop, open: loading } = React.useContext(BackdropContext);
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext)
  const [edit, isEdit] = useState(false);
  // company drop down option
  const [companyList, setCompanyList] = React.useState([]);
  const [permission, setPermission] = React.useState({})
  const [loader, setLoader] = useState(true);
  const [filterData, setFilterData] = React.useState(defaultFilterState)
  const [filterDrawer, setFilterDrawer] = React.useState(false)

  // use effect to get permission
  React.useEffect(() => {
    const _ = getRoutePermissionNew(auth)
    if (_) {
      setPermission(_)
      if (_?.read) {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
          setCompanyList(company?.list)
          setSelectedCompany(company?.selected)
          if (props?.isSelect) {
            if (company?.list?.length > 0)
              getTable(0, limit, search, company?.selected, filterData?.status);
          } else {
            getTable(0, limit, search, {}, filterData?.status);

          }
        }
      }
    }
    // eslint-disable-next-line
  }, [auth, filterData?.status]);

  //---------------------context--------------------------

  const alert = useContext(AlertContext);
  const history = useHistory();

  //---------------------context--------------------------

  //---------------------custom hooks--------------------------

  const debounce = UseDebounce();

  //---------------------custom hooks--------------------------

  //---------------------Functions--------------------------

  const getTable = (offset = 0, limit = 10, search = "", company = {}, status) => {
    setLoader(true)
    const jsonData = getTableActions(type, title, offset, limit, search, company, status, t);

    let payload = {
      offset, limit, search,
      company_id: company?.value,
      is_active: status
    }

    NetworkCall(
      `${config.api_url}${title === "Company" ? `/company/get_all` : `${jsonData?.endpoint}`}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {

        let main = response?.data?.data;
        const wData = jsonData.resfunc(main);
        setTableData({
          ...tableData,
          data: wData,
          drawer: jsonData.tbdata.drawer,
          totalRowsCount: response?.data?.data?.count?.[0]?.count,
        });
        setLoader(false)
        handleLoading(false);
      })
      .catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  //---------------------Functions--------------------------

  const addNew = () => {
    if (routes?.add) {
      history.push({
        pathname: routes?.add,
      });
      return;
    }
    setEditId(null);
    isEdit(false);
    setRead(false);
    setOpen(true);
    setDiable(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEditId(null);
    isEdit(false);
    setRead(false);
  };

  const onDeleteOpen = (value) => {
    setMain({
      isD: true,
      Main: value,
    });
  };

  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    if (props?.isSelect) {
      getTable(offset, limit, search, selectedCompany ?? {}, filterData?.status);
    }
    else {
      getTable(offset, limit, search, {}, filterData?.status);
    }

  };

  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    if (props?.isSelect) {
      getTable(0, value, search, selectedCompany ?? {}, filterData?.status);
    } else {
      getTable(0, value, search, {}, filterData?.status);
    }

  };

  const handleSearch = (e) => {
    setSearch(e);
    debounce(() => searchTableFunction(e), 800);
  };

  const searchTableFunction = (e) => {
    if (page > 1) {
      setPage(1);
    }
    if (props?.isSelect) {
      getTable(0, limit, e, selectedCompany ?? {}, filterData?.status);
    }
    else {
      getTable(0, limit, e, {}, filterData?.status);
    }
  };

  const handleIcon = (type, value, checked) => {
    if (type === "edit" || type === "view") {
      if (type === "edit") {
        if (routes?.edit) {
          history.push({
            pathname: `${routes?.edit?.split("/:")[0]}/${value.id}`,
            state: { from: "edit" },
          });
          return;
        }
        setRead(false);
        isEdit(true);
        setEditId(value.id);
        setDiable(false);
      } else {
        if (routes?.view) {
          history.push({
            pathname: `${routes?.edit?.split("/:")[0]}/${value.id}`,
            state: { from: "view" },
          });
          return;
        }
        setRead(true);
      }

      const newDrawer = [...tableData.drawer]?.map((val) => {
        Object.keys(value).map((k) => {
          if (val.state_name === k) {
            val.value = value[val.state_name];
          }
          return k;
        });
        return val;
      });

      setTableData({
        ...tableData,
        drawer: newDrawer,
      });
      setOpen(true);
    }

    if (type === "active") {
      const c = {
        is_active: checked === "Active" ? false : true,
        updated_at: new Date().toISOString(),
        updated_by: localStorage.getItem(LocalStorageKeys.profileID),
      };
      if (typeH === "Property Master") {
        editTablefuncPM(title, c, value.id);
      }
      if (typeH === "Business Master") {
        let modifiedTitle = title;
        if (title === "Tax Group") {
          modifiedTitle = "TAX Group";
        }
        editTablefuncBM(modifiedTitle, c, value.id);
      }
      if (typeH === "Service Master") {
        editTablefuncSM(title, c, value.id);
      }
    }

    if (type === "delete") {
      onDeleteOpen(value);
    }
  };

  const handleDelete = () => {
    const c = {
      is_active: false,
      is_delete: true,
      updated_at: new Date().toISOString(),
      updated_by: localStorage.getItem(LocalStorageKeys.profileID),
    };

    if (type === "Property Master") {
      editTablefuncPM(title, c, main.Main.id);
    }
    if (type === "Business Master") {
      let modifiedTitle = title;
      if (title === "Tax Group") {
        modifiedTitle = "TAX Group";
      }
      editTablefuncBM(modifiedTitle, c, main.Main.id);
    }
    if (type === "Service Master") {
      editTablefuncSM(title, c, main.Main.id);
    }
  };

  const returnHeading = useMemo(() => {
    return getTableActions(type, title, null, null, null, null, null, t)?.tbdata?.heading;
    // eslint-disable-next-line
  }, []);

  const returnType = useMemo(() => {
    return getTableActions(type, title)?.tbdata?.type;
    // eslint-disable-next-line
  }, []);

  const saveFunction = (datas, title) => {
    if (type === "Property Master") {
      if (title === "Property Type") {
        const res = {};
        if (datas.company_name.value) {
          res.company_id = datas.company_name.value;
        } else {
          var newDrawer = [...tableData.drawer];
          // eslint-disable-next-line
          newDrawer.map((keys) => {
            if (keys.state_name === "property_name") {
              // eslint-disable-next-line
              keys.options.map((m) => {
                if (m.label === datas.company_name) {
                  res.company_id = m.value;
                }
              });
            }
          });
        }
        res.is_active = datas.is_active ? datas.is_active : false;
        res.group_name = datas.group_name;
        res.purpose = datas?.purpose?.value;
        edit ? editTablefuncPM(title, res) : updateTablefuncPM(title, res);
      }
      if (title === "Pricing Component") {
        const returnData = (val) =>
          typeof val === "boolean" ? val : val === "Yes" ? true : false;
        let rental = {};
        if (datas.company_name.value) {
          rental.company_id = datas.company_name.value;
        } else {
          // eslint-disable-next-line
          var newDrawer = [...tableData.drawer];
          // eslint-disable-next-line
          newDrawer.map((keys) => {
            if (keys.state_name === "property_name") {
              // eslint-disable-next-line
              keys.options.map((m) => {
                if (m.label === datas.company_name) {
                  rental.company_id = m.value;
                }
              });
            }
          });
        }
        rental.revenue_type = datas.revenue_type.value;
        rental.name = datas.name;
        rental.primary = returnData(datas?.primary);
        rental.refundable = returnData(datas?.refundable);
        rental.is_onetime = returnData(datas?.is_onetime);
        rental.is_reserve_quality = returnData(datas?.is_reserve_quality);
        rental.taxtable = returnData(datas?.taxtable);
        rental.is_parking = returnData(datas?.is_parking);
        rental.is_quantity = returnData(datas?.is_quantity);
        rental.is_override = returnData(datas?.is_override);
        rental.is_short_term = returnData(datas?.is_short_term);
        if (datas.payment_period.value) {
          rental.payment_period = datas.payment_period.value;
        } else {
          // eslint-disable-next-line
          var newDrawer = [...tableData.drawer];
          // eslint-disable-next-line
          newDrawer.map((keys) => {
            if (keys.state_name === "payment") {
              // eslint-disable-next-line
              keys.options.map((m) => {
                if (m.label === datas.payment_period) {
                  rental.payment_period = m.value;
                }
              });
            }
          });
        }
        rental.is_active = returnData(datas?.is_active);
        edit
          ? editTablefuncPM(title, rental)
          : updateTablefuncPM(title, rental);
      }
      if (title === "Block Type") {
        const block = {};
        if (datas.company_name.value) {
          block.company_id = datas.company_name.value;
        } else {
          // eslint-disable-next-line
          var newDrawer = [...tableData.drawer];
          // eslint-disable-next-line
          newDrawer.map((keys) => {
            if (keys.state_name === "property_name") {
              // eslint-disable-next-line
              keys.options.map((m) => {
                if (m.label === datas.company_name) {
                  block.company_id = m.value;
                }
              });
            }
          });
        }
        block.name = datas.name;
        block.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncPM(title, block) : updateTablefuncPM(title, block);
      }
      if (title === "Unit Purpose") {
        const block = {};
        block.type = datas.type;
        block.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncPM(title, block) : updateTablefuncPM(title, block);
      }
      if (title === "Unit Type") {
        const block = {};
        if (datas.company_name.value) {
          block.company_id = datas.company_name.value;
        } else {
          // eslint-disable-next-line
          var newDrawer = [...tableData.drawer];
          // eslint-disable-next-line
          newDrawer.map((keys) => {
            if (keys.state_name === "property_name") {
              // eslint-disable-next-line
              keys.options.map((m) => {
                if (m.label === datas.company_name) {
                  block.company_id = m.value;
                }
              });
            }
          });
        }
        block.name = datas.name;
        block.settings = datas?.settings?.value;
        block.beds = datas?.beds;
        block.baths = datas?.baths;
        block.configured_units = datas?.configured_units;
        block.purpose = datas?.purpose?.value;
        block.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncPM(title, block) : updateTablefuncPM(title, block);
      }
      if (title === "Ameneties") {
        const block = {};
        if (datas.company_name.value) {
          block.company_id = datas.company_name.value;
        } else {
          // eslint-disable-next-line
          var newDrawer = [...tableData.drawer];
          // eslint-disable-next-line
          newDrawer.map((keys) => {
            if (keys.state_name === "property_name") {
              // eslint-disable-next-line
              keys.options.map((m) => {
                if (m.label === datas.company_name) {
                  block.company_id = m.value;
                }
              });
            }
          });
        }
        block.amenities_name = datas.name;
        block.settings = datas?.settings?.value;
        block.description = datas.description;
        block.notes = datas.notes;
        block.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncPM(title, block) : updateTablefuncPM(title, block);
      }
      if (title === "Unit Rental Breakup") {
        const unit = {};
        if (datas?.unit_name?.value) {
          unit.unit_id = datas.unit_name.value;
        }
        if (datas?.tax_group?.value) {
          unit.vat_group_id = datas.tax_group.value;
        }
        if (datas?.component_type?.value) {
          unit.rental_breakup_id = datas?.component_type?.value;
        }
        // if(datas?.value.currency_code?.id){
        unit.currency_id = datas?.value.currency_code?.value
          ? datas?.value.currency_code?.value
          : 29;
        // }
        if (datas?.value_type?.value) {
          unit.value_basis_type = datas.value_type?.value;
        }
        unit.period_type = datas.period_type;
        unit.value = datas.value.currency;
        unit.display_percentage = datas.display;
        unit.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncPM(title, unit) : updateTablefuncPM(title, unit);
      }
    }
    if (type === "Business Master") {
      if (title === "Country") {
        let res = {};
        res.country_name = datas.country_name;
        res.is_active = datas.is_active ? datas.is_active : false;

        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "Currency") {
        let res = {};

        res.name = datas?.name;
        res.code = datas?.code;
        res.symbol = datas?.symbol;
        if (datas?.country_name?.value) {
          res.country_id = datas?.country_name?.value;
        }
        res.is_active = datas?.is_active ? datas?.is_active : false;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "TAX Group") {
        let res = {};
        res.group_name = datas.group_name;
        res.vat_group_code = datas.tax_group_code?.value;
        res.total_rate = datas.total_rate;
        res.hsn_code=datas.hsn_code;
        if (datas?.country_name?.value) {
          res.country_id = datas.country_name?.value;
        }
        // res.com_master_id =datas?.coa_account_number?.value;
        res.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "Tax Item") {
        let res = {};
        res.group_name = datas.group_name;
        res.vat_group_item_code = datas.tax_group_code?.value;
        res.rate = datas.rate;
        if (datas.Vat_group?.value) {
          res.vat_group_master_id = datas.Vat_group?.value;
        }
        res.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "Company") {
        let res = {};
        res.name = datas.name;
        res.logo = datas.upload_logo?.logo ?? "";
        res.description = datas?.description;
        res.company_no = datas?.company_code;
        res.city = datas?.city?.value;
        res.currency_id = datas?.currency?.value;
        res.company_registration_number = datas?.company_registration;
        res.vat_group_id = datas?.tax_group?.value;
        res.tax_number = datas?.tax_registration;
        res.is_active = datas.is_active ? datas.is_active : false;
        res.cash_collection_office = datas.cash_collection_office;
        res.cheque_name = datas.cheque_name;
        res.description = datas.description;
        res.name = datas.name;
        res.primary_bank_name = datas.primary_bank_name;
        res.routing_type = datas?.routing_type?.value;
        res.account_type = datas?.account_type?.value;
        res.primary_account_no = datas?.primary_account_no;
        res.bank_routing_code = datas?.bank_routing_code;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "State") {
        let res = {};
        res.state_name = datas.state_name;
        if (datas.country_name?.value) {
          res.country_id = datas.country_name?.value;
        }

        res.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "City") {
        let res = {};
        res.city = datas.city;
        if (datas.state_name?.value) {
          res.state = datas.state_name?.value;
        }

        res.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "Locality") {
        let res = {};
        res.area_name = datas.area_name;
        if (datas.city?.value) {
          res.city_master_id = datas.city?.value;
        }
        res.description = datas.description;
        res.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "Lead type") {
        let res = {};
        res.name = datas.name;
        res.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "Activity") {
        let res = {};
        res.name = datas.type;
        res.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "Activity Category") {
        let res = {};
        if (datas.type?.value) {
          res.lead_activity_master_id = datas.type?.value;
        }
        res.name = datas.activity_category;
        res.is_active = datas.is_active ? datas.is_active : false;

        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }

      if (title === "Activity Sub-Category") {
        let res = {};
        res.name = datas.sub_category;
        // if (val.type?.value) {
        //   res.lead_activity_master_id = val.type?.value;
        // }
        if (datas.activity_category?.value) {
          res.activity_category_master_id = datas.activity_category?.value;
        }
        res.is_active = datas.is_active ? datas.is_active : false;
        edit ? editTablefuncBM(title, res) : updateTableFuncBM(title, res);
      }
    }
    if (type === "Service Master") {
      let res = { ...datas };
      res.is_active = res?.is_active ? res?.is_active : false;
      if (title === "General Sub-Category") {
        res.name = datas?.type;
        res.general_category_id = datas?.generalcategory?.value;
        delete res.type;
        delete res.generalcategory;
      } else if (title === "Maintenance Sub-Category") {
        res.name = datas?.type;
        res.maintenance_category_id = datas?.maintenancecategory?.value;
        delete res.type;
        delete res.maintenancecategory;
      }

      if (editId) {
        res.name = datas?.type;
        res.updated_at = new Date().toISOString();
        res.updated_by = localStorage.getItem(LocalStorageKeys.profileID);
        delete res.type;
        editTablefuncSM(title, res);
      } else {
        res.name = datas?.type;
        res.created_at = new Date().toISOString();
        res.created_by = localStorage.getItem(LocalStorageKeys.profileID);
        res.updated_at = new Date().toISOString();
        res.updated_by = localStorage.getItem(LocalStorageKeys.profileID);
        res.client = localStorage.getItem(LocalStorageKeys.clinetID);
        delete res.type;
        addTablefuncSM(title, res);
      }
    }
  };

  const updateTablefuncPM = (name, data) => {
    setDiable(true);

    data.created_at = new Date().toISOString();
    data.created_by = localStorage.getItem(LocalStorageKeys.profileID);
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.profileID);
    data.client = localStorage.getItem(LocalStorageKeys.clinetID);

    const jsonData = propertyUpdateActions[name];
    const payload = { params: [{ ...data }] };
    NetworkCall(
      `${config?.api_url}${jsonData.endpoint}`,
      NetWorkCallMethods.post, payload, null, true, false
    )
      .then((response) => {
        setDiable(false);
        if (page > 1) {
          setPage(1);
        }
        if (props?.isSelect) {
          getTable(0, limit, search, selectedCompany ?? {}, filterData?.status);
        }
        else {
          getTable(0, limit, search, {}, filterData?.status);
        }
        handleClose();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Created Successfully!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((error) => {
        setDiable(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Not Created!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const editTablefuncPM = (name, data, id) => {
    setDiable(true);
    data.id = id ? id : editId;
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.profileID);

    const jsonData = propertyEditActions[name];
    const payload = { params: [data] };
    NetworkCall(
      `${config?.api_url}${jsonData.endpoint}`,
      NetWorkCallMethods.post, payload, null, true, false
    )
      .then((response) => {
        if (main.isD) {
          setMain({ isD: false, Main: "" });
        }
        setDiable(false);
        if (props?.isSelect) {
          getTable(0, limit, search, selectedCompany ?? {}, filterData?.status);
        }
        else {
          getTable(0, limit, search, {}, filterData?.status);
        }

        handleClose();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: main.isD ? t("Deleted Successfully!") : t("Updated Successfully!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((error) => {
        setDiable(false);

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Not Edited!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  const StatusOptionList = [
    { label: t("Active"), value: true },
    { label: t("In Active"), value: false },
  ]
  const updateTableFuncBM = (name, data) => {
    setDiable(true);
    data.created_at = new Date().toISOString();
    data.created_by = localStorage.getItem(LocalStorageKeys.profileID);
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.profileID);
    data.client = localStorage.getItem(LocalStorageKeys.clinetID);

    if (title === "Company") {
      const payload = {
        company_no: data?.company_no
      };
      NetworkCall(
        `${config.api_url}/queries/company_master/check_company_no`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          if (response?.data?.data?.company_master?.length > 0) {
            setDiable(false);
            handleClose();
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("Company Code Already Exist!"),
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            const jsonData = bussinessUpdateActions[name];
            const payload = { params: data };
            NetworkCall(
              `${config?.api_url}${jsonData.endpoint}`,
              NetWorkCallMethods.post,
              payload,
              null,
              true,
              false
            )
              .then(async (response) => {
                let main = response.data.data;
                //Creating placeholder images for dashboard while creating company
                await new Promise(async (resolve, reject) => {
                  if (main?.company_master?.id) {
                    const payload = {
                      tenantId: config.tenantId,
                      company_id: main?.company_master?.id,
                      user_profile_id: localStorage.getItem(
                        LocalStorageKeys.profileID
                      ),
                    };
                    await NetworkCall(
                      `${config.api_url}/company-banner/seeder`,
                      NetWorkCallMethods.post,
                      payload,
                      null,
                      true,
                      false
                    )
                      .then((response) => {
                        setDiable(false);
                        handleClose();
                        resolve();
                      })
                      .catch((error) => {
                        setDiable(false);
                        handleClose();
                        alert.setSnack({
                          ...alert,
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: t("Please fill all mandatory field"),
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.center,
                        });
                        console.log(error);
                        reject();
                      });
                  }
                }).catch((error) => {
                  setDiable(false);
                  handleClose();
                  alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Some Thing Went Wrong"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                  });
                });
                //end
                if (page > 1) {
                  setPage(1);
                }
                if (props?.isSelect) {
                  getTable(0, limit, search, selectedCompany ?? {}, filterData?.status);
                }
                else {
                  getTable(0, limit, search, {}, filterData?.status);
                }
                setDiable(false);
                handleClose();
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.success,
                  msg: t("Created Successfully!"),
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              })
              .catch((error) => {
                setDiable(false);
                handleClose();
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: t("Some Thing Went Wrong"),
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              });
          }
        })
        .catch((error) => {
          setDiable(false);
          handleClose();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    } else {
      const jsonData = bussinessUpdateActions[name];
      const payload = { params: data };
      NetworkCall(
        `${config?.api_url}${jsonData.endpoint}`,
        NetWorkCallMethods.post, payload, null, true, false
      )
        .then(async (response) => {
          if (page > 1) {
            setPage(1);
          }
          if (props?.isSelect) {
            getTable(0, limit, search, selectedCompany ?? {}, filterData?.status);
          }
          else {
            getTable(0, limit, search, {}, filterData?.status);
          }
          setDiable(false);
          handleClose();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Created Successfully!"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        })
        .catch((error) => {
          setDiable(false);
          handleClose();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    }
  };

  const editTablefuncBM = (name, data, id) => {
    setDiable(true);
    data.id = id ? id : editId;
    data.updated_at = new Date().toISOString();
    data.updated_by = localStorage.getItem(LocalStorageKeys.profileID);
    data.client = localStorage.getItem(LocalStorageKeys.clinetID);
    if (title === "Company" && editId) {
      const payload = {
        id: id ? id : editId,
        company_no: data?.company_no
      };
      NetworkCall(
        `${config.api_url}/queries/company_master/check_company_no`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          if (response?.data?.data?.existing?.length > 0) {
            const jsonData = bussinesssEditActions[name];
            const payload = {
              params: {
                id: id ? id : editId,
                ...data
              }
            };
            NetworkCall(
              `${config?.api_url}${jsonData.endpoint}`,
              NetWorkCallMethods.post, payload, null, true, false
            )
              .then((response) => {
                if (main.isD) {
                  setMain({ isD: false, Main: "" });
                }
                setDiable(false);
                // let offset = (page - 1) * limit;
                if (props?.isSelect) {
                  getTable(0, limit, search, selectedCompany ?? {}, filterData?.status);

                }
                else {
                  getTable(0, limit, search, {}, filterData?.status);
                }
                handleClose();
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.success,
                  msg: main.isD
                    ? t("Deleted Successfully!")
                    : t("Updated Successfully!"),
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              })
              .catch((error) => {
                setDiable(false);
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: t("Some Thing Went Wrong"),
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              });
          } else if (response?.data?.data?.new?.length > 0) {
            setDiable(false);
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("Company Code Already Exist!"),
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {

            const jsonData = bussinesssEditActions[name];
            const payload = {
              params: {
                id: id ? id : editId,
                ...data
              }
            };
            NetworkCall(
              `${config?.api_url}${jsonData.endpoint}`,
              NetWorkCallMethods.post,
              payload,
              null,
              true,
              false
            )
              .then((response) => {
                if (main.isD) {
                  setMain({ isD: false, Main: "" });
                }
                setDiable(false);
                // let offset = (page - 1) * limit;
                if (props?.isSelect) {
                  getTable(0, limit, search, selectedCompany?.value, filterData?.status);
                }
                else {
                  getTable(0, limit, search, {}, filterData?.status);
                }
                handleClose();
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.success,
                  msg: main.isD
                    ? t("Deleted Successfully!")
                    : t("Updated Successfully!"),
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              })
              .catch((error) => {
                setDiable(false);
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: t("Some Thing Went Wrong"),
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.center,
                });
              });
          }
        })
        .catch((error) => {
          setDiable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    } else {
      const jsonData = bussinesssEditActions[name];
      const payload = {
        params: {
          id: id ? id : editId,
          ...data
        }
      };
      NetworkCall(
        `${config?.api_url}${jsonData.endpoint}`,
        NetWorkCallMethods.post, payload, null, true, false
      )
        .then((response) => {
          if (main.isD) {
            setMain({ isD: false, Main: "" });
          }
          // let offset = (page - 1) * limit;
          if (props?.isSelect) {
            getTable(0, limit, search, selectedCompany ?? {}, filterData?.status);
          }
          else {
            getTable(0, limit, search, {}, filterData?.status);
          }
          handleClose();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.isD ? t("Deleted Successfully!") : t("Updated Successfully!"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        })
        .catch((error) => {
          handleClose();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some Thing Went Wrong"),
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    }
  };

  const editTablefuncSM = (name, data, id) => {
    setDiable(false);
    data.id = id ? id : editId;
    const jsonData = serviceEditActions[name];
    const payload = { params: [data] };
    NetworkCall(
      `${config?.api_url}${jsonData.endpoint}`,
      NetWorkCallMethods.post, payload, null, true, false
    )
      .then((response) => {
        if (main.isD) {
          setMain({ isD: false, Main: "" });
        }
        setDiable(false);
        // let offset = (page - 1) * limit;
        if (props?.isSelect) {
          getTable(0, limit, search, selectedCompany ?? {}, filterData?.status);
        }
        else {
          getTable(0, limit, search, {}, filterData?.status);
        }
        handleClose();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: main.isD ? t("Deleted Successfully!") : t("Updated Successfully!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Not Edited!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const addTablefuncSM = (name, data) => {
    setDiable(true);
    const jsonData = serviceAddActions[name];
    const payload = { params: [data] };
    NetworkCall(
      `${config?.api_url}${jsonData.endpoint}`,
      NetWorkCallMethods.post, payload, null, true, false
    )
      .then((response) => {
        if (page > 1) {
          setPage(1);
        }
        if (props?.isSelect) {
          getTable(0, limit, search, selectedCompany ?? {}, filterData?.status);
        }
        else {
          getTable(0, limit, search, {}, filterData?.status);
        }
        setDiable(false);
        handleClose();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Created Successfully!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Not Created!"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
  };

  //   company switcher
  const handleCompanyChange = (e) => {
    setSelectedCompany(e);
    getTable(0, limit, search, e ?? {}, filterData?.status);
  };

  const render = () => {
    return <>
      <Subheader
        title={header}
        hideBackButton
        select={props?.isSelect ? true : false}
        options={companyList}
        value={selectedCompany}
        onchange={(e) => {
          handleCompanyChange(e);
        }}
      />
      <CustomPaper>
        <Grid container>
          <Grid item xs={4}>
            <SearchFilter
              value={search}
              handleChange={handleSearch}
              placeholder={`${t("Search")} ${header}`}
              customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
            />
          </Grid>
          {permission?.create && <Grid item xs={8} textAlign={"right"}>
            <Box display={"flex"} sx={{ justifyContent: "end" }}>
              <Stack direction="row"
                divider={<Divider sx={{ marginInline: "16px" }} orientation="vertical" flexItem />}>
                <IconButton onClick={() => setFilterDrawer(!filterDrawer)} sx={{ height: '40px', width: "40px", border: "1px solid #E4E8EE", borderRadius: "4px", padding: "4px" }}
                >
                  <Badge variant="dot" color="primary"
                    invisible={!(filterData.status?.length > 0)}>
                    <FilterIMG color="#091b29" />
                  </Badge>
                </IconButton>
                {create && (
                  <CustomButton
                    color="primary"
                    variant="contained"
                    onClick={addNew}
                  >
                    {`${t("Add")} ${header}`}
                  </CustomButton>
                )}
              </Stack>

            </Box>
          </Grid>}
          <Grid item xs={12}>
            {loader ? <NewLoader minusHeight="200px" /> : <TableWithPagination
              heading={returnHeading}
              rows={tableData?.data?.length ? tableData?.data : []}
              showpagination={true}
              tableType="no-side"
              handleIcon={handleIcon}
              onClick={() => null}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              dataType={returnType}
              handlePagination={handlePagination}
              handleChangeLimit={handleChangeLimit}
              totalRowsCount={tableData?.totalRowsCount}
              page={page}
              limit={limit}
              height={"calc(100vh - 290px)"}
              view={permission?.read}
              edit={permission?.update}
              delete={title === "Currency" ? false : permission?.delete} />}
          </Grid>
          <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
            onApply={(value) => setFilterData(value)}
            defaultState={defaultFilterState}
            components={[
              {
                component: "toggleButton",
                value: filterData?.status,
                options: StatusOptionList,
                isMulti: true,
                state_name: "status",
                label: t("Status")
              },
            ]} />
        </Grid>
        <Component {...props}>{props.children}</Component>
      </CustomPaper>
      {tableData.drawer && (
        <GenerateForm
          parent_id={
            title.replaceAll(" ", "_")?.toLocaleLowerCase() + "_drawer"
          }
          nocancel
          loading={disable}
          edit={edit}
          open={open}
          readOnly={readOnly}
          save={saveFunction}
          handlecancel={handleClose}
          // changeDrop={changeDrop}
          header={header}
          json={tableData.drawer}
          t={t}
        />
      )}

      {main.isD && (
        <Modal
          open={main.isD}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("Are you sure you want to Delete!")}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                margin: 10,
              }}
            >
              <Button
                variant="contained"
                size="medium"
                style={{ backgroundColor: "#FF0000", width: "144px" }}
                onClick={handleDelete}
              >
                {t("Yes")}
              </Button>
              <Button
                variant="contained"
                size="medium"
                style={{ backgroundColor: "#4E9F3D", width: "144px" }}
                onClick={() => setMain({ isD: false, Main: "" })}
              >
                {t("No")}
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </>
  }

  return (
    <div>
      {accessCheckRender(render, permission, "", loading)}
    </div>
  );
};

export default withMasters;
// export default withNamespaces("master")(withMasters); 
