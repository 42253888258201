import * as React from "react"
const UploadIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <path
            fill="#5078e1"
            d="M11.986 0a1 1 0 0 0-.693.293l-4 4a1 1 0 1 0 1.414 1.414L11 3.414v14.253a1 1 0 1 0 2 0V3.414l2.293 2.293a1 1 0 1 0 1.414-1.414l-4-4A1 1 0 0 0 11.986 0ZM3.667 7.333A3.682 3.682 0 0 0 0 11v9.333A3.682 3.682 0 0 0 3.667 24h16.666A3.682 3.682 0 0 0 24 20.333V11a3.682 3.682 0 0 0-3.667-3.667H19a1 1 0 1 0 0 2h1.333A1.652 1.652 0 0 1 22 11v9.333A1.652 1.652 0 0 1 20.333 22H3.667A1.652 1.652 0 0 1 2 20.333V11a1.652 1.652 0 0 1 1.667-1.667H5a1 1 0 1 0 0-2Z"
            data-name="icons8-upload (1)"
        />
    </svg>
)
export default UploadIcon
