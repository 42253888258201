import * as React from "react"

export const ManagedInvoices = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
        <g data-name="Request Ticket">
            <path data-name="Rectangle 53523" fill="none" d="M0 0h18v18H0z" />
            <path
                data-name="icons8-bill (1)"
                d="M1.764 0a.773.773 0 0 0-.539.2A.693.693 0 0 0 1 .71v14.684A2.692 2.692 0 0 0 3.75 18h10.5A2.692 2.692 0 0 0 17 15.394V.71a.7.7 0 0 0-.341-.6.786.786 0 0 0-.713-.053l-1.961.826-2.206-.836a.789.789 0 0 0-.557 0L9 .893 6.778.051a.789.789 0 0 0-.557 0L4.015.887 2.055.061A.785.785 0 0 0 1.764 0ZM4.25 5.684h9.5a.712.712 0 1 1 0 1.421h-9.5a.712.712 0 1 1 0-1.421Zm0 4.263h5.5a.712.712 0 1 1 0 1.421h-5.5a.712.712 0 1 1 0-1.421Zm8.5 0h1a.712.712 0 1 1 0 1.421h-1a.712.712 0 1 1 0-1.421Zm-8.5 2.842h5.5a.712.712 0 1 1 0 1.421h-5.5a.712.712 0 1 1 0-1.421Zm8.5 0h1a.712.712 0 1 1 0 1.421h-1a.712.712 0 1 1 0-1.421Z"
                fill={props?.color}
            />
        </g>
    </svg>
)


