import * as React from "react"

const ContractProperty = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16.2} height={18} {...props}>
    <path
      d="M2.7 0A2.7 2.7 0 0 0 0 2.7v1.125a.675.675 0 0 0 .675.675h1.8a2.915 2.915 0 0 1 2.106.9h8.244a.675.675 0 0 1 0 1.35H5.318a2.9 2.9 0 0 1 .082.675V8.1h2.925a.675.675 0 0 1 0 1.35H5.4v5.171a2.484 2.484 0 0 1-.3 1.185L3.9 18h10.275a2.027 2.027 0 0 0 2.025-2.025V2.7A2.7 2.7 0 0 0 13.5 0Zm0 1.35h8.463A2.679 2.679 0 0 0 10.8 2.7v.45H1.35V2.7A1.352 1.352 0 0 1 2.7 1.35ZM2.475 5.4A2.025 2.025 0 0 0 .45 7.425v7.2a1.579 1.579 0 0 0 .192.754l1.24 2.274a.675.675 0 0 0 1.185 0l1.24-2.275a1.573 1.573 0 0 0 .192-.753v-7.2A2.025 2.025 0 0 0 2.475 5.4Zm9.675 4.5a1.8 1.8 0 1 1-1.8 1.8 1.79 1.79 0 0 1 1.8-1.8Zm-1.8 4.379a3.121 3.121 0 0 0 3.6 0v1.7a.675.675 0 0 1-1.01.586l-.79-.452-.79.452a.675.675 0 0 1-1.01-.586Z"
      fill={props?.color}
    />
  </svg>
)

export default ContractProperty
