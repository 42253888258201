// import { LeadStylesParent } from "./style";
// // styles
// const classes = LeadStylesParent();
// export const TabList = (data) => [
//   {
//     label: "Activity Comments",
//     value: 1,
//     className: data?.tabValue === 1 ? classes.tabtitle1 : classes.tabtitle,
//     selectBack: data?.tabValue === 1 ? classes.selectBack1 : classes.selectBack,
//   },
//   {
//     label: "Number of Units (03)",
//     value: 2,
//     className: data?.tabValue === 2 ? classes.tabtitle1 : classes.tabtitle,
//     selectBack: data?.tabValue === 2 ? classes.selectBack1 : classes.selectBack,
//   },
//   {
//     label: "To Do List",
//     value: 3,
//     className: data?.tabValue === 3 ? classes.tabtitle1 : classes.tabtitle,
//     selectBack: data?.tabValue === 3 ? classes.selectBack1 : classes.selectBack,
//   },
//   {
//     label: "Activity",
//     value: 4,
//     className: data?.tabValue === 4 ? classes.tabtitle1 : classes.tabtitle,
//     selectBack: data?.tabValue === 4 ? classes.selectBack1 : classes.selectBack,
//   },
// ];

import { ThreadParty } from "../../assets/3rdParty";
import { Internal } from "../../assets/internal";

export const UnitPath = ["image", "propertyname", "name", "unit_no", "type"];
export const UnitHeading = (t = () => false) => {
  const heading = [
    { title: "", field: "icon" },
    { title: t("Image"), field: "image" },
    { title: t("Property Name"), field: "propertyname" },
    { title: t("Name"), field: "name" },
    { title: t("Unit Id"), field: "unit_no" },
    { title: t("Unit Type"), field: "type" },
  ]
  return heading
};

export const ToDoPath = ["", "Name", "Description"];
export const ToDoHeading = (t = () => false) => {
  const todoHeading = [
    { title: "", field: "checkbox" },
    { title: t("Name"), field: "Name" },
    { title: t("Description"), field: "Description" },
    { title: t("Edit"), field: "icon" },
  ]
  return todoHeading
}

export const ActivityPath = [
  "image",
  "name",
  "info",
  "Date",
  "CreatedBy",
  "Priority",
  "Status",
];
export const ActivityHeading = [
  { title: "", field: "image" },
  { title: "Activity Name", field: "name" },
  { title: "Action Info", field: "info" },
  { title: "Date", field: "Date" },
  { title: "Created By", field: "CreatedBy" },
  { title: "Priority", field: "Priority" },
  { title: "Status", field: "Status" },
  { title: "Edit", field: "icon" },
];

export const leadtransfer = [
  {
    title: "3rd Party Portal Inventory",
    image: <ThreadParty />,
    is_active: true,
  },
  {
    title: "Internal",
    image: <Internal />,
    is_active: true,
  },
];

export const ExternalUnitPath = ["property_name" , "unit_name" , "type" , "period" , "price" , "agent_name" , "owner_name" , "external_view_listing_source"];
export const ExternalUnitHeading =(t)=> [
  { title: "", field: 'icon' },
  { title: t("Property Name"), field: "property_name" },
  { title: t("Unit Name"), field: "unit_name" },
  { title: t("Unit Type"), field: "type" },
  { title: t("Period"), field: "period" },
  { title: t("Price"), field: "price" },
  { title: t("Agent"), field: "agent_name" },
  { title: t("Owner"), field: "owner_name" },
  { title: t("Listing Site"), field: "external_view_listing_source" },
];
