import { ActivePropertyIcon } from "../../assets";
import { FromAppIcon, UnVerifiedIcon, ValidIcon, VerifiedIcon } from "../../assets/leasemanagerdashboard";
import { startAndEndOfMonth, startAndEndOfWeek } from "../../utils";

export const Lease_Manager = {
    active_leads: 10,
    active_opportunities: 10,
    active_reservations: 10,
    active_quotations: 10,
    won_quotations: 10,
    onboarded_residents_for_this_month: 10,
    opportunity_revenue: 300,
    currency: "INR",
    lead_count_by_status: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    opportunity_count_by_lead_source: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    opportunity_count_by_status: {
        labels: ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
            {
                label: "My First dataset",
                backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                borderWidth: 1,
                //stack: 1,
                data: [65, 59, 80, 81, 56, 55, 40],
            },

            {
                label: "My second dataset",
                backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                borderWidth: 1,
                //stack: 1,
                data: [45, 79, 50, 41, 16, 85, 20],
            },
        ],
    },
    opportunity_reveune_by_status: {
        labels: ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
            {
                label: "My First dataset",
                backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                borderWidth: 1,
                //stack: 1,
                data: [65, 59, 80, 81, 56, 55, 40],
            },

            {
                label: "My second dataset",
                backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                borderWidth: 1,
                //stack: 1,
                data: [45, 79, 50, 41, 16, 85, 20],
            },
        ],
    },
    opportunity_count_by_period: {
        labels: ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
            {
                label: "My First dataset",
                backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                borderWidth: 1,
                //stack: 1,
                data: [65, 59, 80, 81, 56, 55, 40],
            },
        ],
    },
    reservation_and_Booking_status: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    inspection_request_status: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    team_performance: [
        {
            Image: "https://mui.com/static/images/avatar/2.jpg",
            rep: "test",
            Amount: "12000 k",
            Target: "12000 l",
        },
    ],
    my_open_leads: [
        {
            Lead: "test",
            Email: "test",
            Phone: "test",
            Company: "test",
        },
    ],
    my_tasks: [
        {
            to_do: "Dashboard",
            Lead: "test",
            Email: "test",
            Phone: "test",
            Company: "test",
        },
    ],
};

export const won_opportunities = {
    won: 1000,
    target: 1200,
};

export const revenue_dashboard = {
    new_agreement: 10,
    live_agreement: 10,
    agreement_expiring_this_month: 10,
    agreement_revenue_month: 12,
    invoice_revenue: 12,
    declined_agreement: 10,
    under_onboarding: 10,
    overdue_accounts: 10,
    overdue_total: 10,
    total_outstanding_for_this_month: 10,

    agreement_count_by_status: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    new_agreements: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    agreement_revenue_for_three_years: [
        {
            name: "Jan",
            sale: 1000,
            target: 2400,
        },
        {
            name: "Feb",
            sale: 3000,
            target: 1398,
        },
    ],
    invoices_total_by_invoice_type: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    invoices_revenue_by_rental_component: [
        {
            name: "Rubix",
            meter: "2M",
            color: `#${Math.random().toString(16).substring(2, 8)}`,
        },
        {
            name: "Vetrox",
            meter: "2M",
            color: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    posted_invoice_revenue_month: 10,
    planned_invoices_for_this_month: 10,
    customers: [
        {
            sub: "03",
            sub1: "Active Customers",
        },
        {
            sub: "52",
            sub1: "New Customers for this month",
        },
        {
            sub: "03",
            sub1: "Agreement expiring this quarter",
        },
        {
            sub: "52",
            sub1: "Agreement expiring this month",
        },
    ],
    invoiced_revenue_For_tree_years: [
        {
            name: "Jan",
            sales: 1000,
            target: 2400,
        },
        {
            name: "Feb",
            sales: 3000,
            target: 1398,
        },
    ],
    expiring_agreements: [
        {
            name: "PageA",
            value: 1000,
        },
        {
            name: "PageB",
            value: 3000,
        },
    ],
    tenant_mix_by_activity: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    top_ten_properties: [
        {
            name: `Rubix Apartment`,
            amount: `INR 23,1000`,
        },
    ],
    top_ten_agreement: [
        {
            name: `Rubix Apartment`,
            amount: `INR 23,1000`,
        },
    ],
    top_ten_account: [
        {
            number: 1,
            name: `Rubix Apartment`,
            amount: `INR 23,1000`,
        },
    ],
    active_agreement_by_agreement_type: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    active_agreement_by_activity: [
        {
            name: `Vacant`,
            count: 10,
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ],
    top_ten_properties_with_highest_agreement: {
        labels: ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
            {
                label: "My First dataset",
                backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                borderWidth: 1,
                //stack: 1,
                data: [65, 59, 80, 81, 56, 55, 40],
            },
        ],
    },
};

export const constructData = (data) => {
    let leads = [
        {
            count: data?.active_leads ?? 0,
            image: <ActivePropertyIcon />,
            sub: "Active Leads",
        },
        {
            count: data?.active_opportunities ?? 0,
            image: "/images/propertdashboard/block.svg",
            sub: "Active Opportunities",
            image_type: true,
        },
        {
            count: data?.active_reservations ?? 0,
            image: "/images/propertdashboard/floor.svg",
            sub: "Active Reservations",
            image_type: true,
        },
    ];
    let qutation = [
        {
            count: data?.active_quotations ?? 0,
            image: <ActivePropertyIcon />,
            sub: "Active Quotations",
        },
        {
            count: data?.won_quotations ?? 0,
            image: "/images/propertdashboard/block.svg",
            sub: "Won Quotations",
            image_type: true,
        },
        {
            count: data?.unit_blocking_requests ?? 0,
            image: "/images/propertdashboard/floor.svg",
            sub: "Unit Blocking Requests",
            image_type: true,
        },
    ];
    let lead_count_by_status = data?.lead_count_by_status;
    let opportunity_count_by_lead_source = data?.opportunity_count_by_lead_source;
    let opportunity_count_by_status = data?.opportunity_count_by_status ?? {};
    let opportunity_reveune_by_status = data?.opportunity_reveune_by_status;
    let opportunity_count_by_period = data?.opportunity_count_by_period;
    let reservation_and_Booking_status = data?.reservation_and_Booking_status;
    let inspection_request_status = data?.inspection_request_status;
    let team_performance = data?.team_performance;
    let my_open_leads = data?.my_open_leads;
    let my_tasks = data?.my_tasks;
    let currency = data?.currency;
    let revenue = data?.opportunity_revenue;
    const final = {
        leads,
        qutation,
        lead_count_by_status,
        opportunity_count_by_lead_source,
        opportunity_count_by_status,
        opportunity_reveune_by_status,
        opportunity_count_by_period,
        reservation_and_Booking_status,
        inspection_request_status,
        team_performance,
        my_open_leads,
        my_tasks,
        revenue,
        currency,
    };
    return final;
};

export const constructBasicStatsData = (data, t = () => false) => {
    const default_color = "#FF9340"

    let leads = [
        {
            count: data?.proxy_lead_count ?? 0,
            image: <ActivePropertyIcon />,
            sub: t("Active Leads"),
        },
        {
            count: data?.oppertunity_count ?? 0,
            image: "/images/propertdashboard/block.svg",
            sub: t("Active Opportunities"),
            image_type: true,
        },
        {
            count: data?.reservation_count ?? 0,
            image: "/images/propertdashboard/floor.svg",
            sub: t("Active Reservations"),
            image_type: true,
        },
    ];

    let quotation = [
        {
            count: data?.quotation_count ?? 0,
            image: <ActivePropertyIcon />,
            sub: t("Active Quotations"),
        },
        {
            count: data?.won_quotation_count ?? 0,
            image: "/images/propertdashboard/block.svg",
            sub: t("Won Quotations"),
            image_type: true,
        },
        {
            count: data?.unit_block_request ?? 0,
            image: "/images/propertdashboard/floor.svg",
            sub: t("Unit Blocking Requests"),
            image_type: true,
        },
    ];

    let kyc_type = [
        {
            count: data?.kyc_type?.verified ?? 0,
            name: t("Verified KYC"),
            icon: <VerifiedIcon />
        },
        {
            count: data?.kyc_type?.pending ?? 0,
            name: t("Unverified KYC"),
            icon: <UnVerifiedIcon />
        },
        {
            count: data?.kyc_type?.valid ?? 0,
            name: t("Valid"),
            icon: <ValidIcon />
        },
        {
            count: 0,
            name: t("From App"),
            icon: <FromAppIcon />
        },
    ]

    let correspondence_type_total = 0

    let correspondence_type_data = data?.correspondence_type?.map?.((type, i) => {
        correspondence_type_total = correspondence_type_total + (type?.count ? parseInt(type?.count) : 0)
        return {
            name: type?.type ?? "",
            fill: type?.fill ?? default_color,
            count: type?.count ? parseInt(type?.count) : 0,
        }
    })

    let correspondence_type = {
        total: correspondence_type_total,
        data: correspondence_type_data
    }

    const final = { leads, quotation, kyc_type, correspondence_type };

    return final;
};

export const constructLeadCountData = (data) => {
    const default_color = "#FF9340"
    let lead_total = 0

    let lead_data = data?.map?.((lead, i) => {
        lead_total = lead_total + (lead?.count ? parseInt(lead?.count) : 0)
        return {
            name: lead?.type ?? "",
            fill: lead?.fill ?? default_color,
            count: lead?.count ? parseInt(lead?.count) : 0,
        }
    })

    return {
        total: lead_total,
        data: lead_data
    };
}

export const constructOpportunityData = (data) => {
    const default_color = "#FF9340"
    let opportunity_total = 0

    let opportunity_data = data?.map?.((opportunity, i) => {
        opportunity_total = opportunity_total + (opportunity?.count ? parseInt(opportunity?.count) : 0)
        return {
            name: opportunity?.type ?? "",
            fill: opportunity?.fill ?? default_color,
            count: opportunity?.count ? parseInt(opportunity?.count) : 0,
        }
    })

    return {
        total: opportunity_total,
        data: opportunity_data
    };
}

export const constructOpportunityStatusData = (data, t = () => false) => {
    let labels = []
    let datasets = [
        { label: t("Closed"), backgroundColor: "#739c66", borderWidth: 1, barThickness: 5, data: [], },
        { label: t("Open"), backgroundColor: "#2936ed", borderWidth: 1, barThickness: 5, data: [], },
        { label: t("Won"), backgroundColor: "#f91f85", borderWidth: 1, barThickness: 5, data: [], }
    ]

    let temp_data_1 = data?.map((item_1) => {
        let temp_data_2
        if (item_1?.data?.length > 0) {
            temp_data_2 = [
                { status: t("Closed"), count: item_1?.data?.find?.((i) => i?.status === "Closed")?.count ?? 0, },
                { status: t("Open"), count: item_1?.data?.find?.((i) => i?.status === "Open")?.count ?? 0, },
                { status: t("Won"), count: item_1?.data?.find?.((i) => i?.status === "Won")?.count ?? 0, },
            ]
        }

        return { ...item_1, data: temp_data_2, }
    })

    for (let i = 0; i < temp_data_1?.length; i++) {
        if (temp_data_1?.[i]?.data?.length > 0) {
            labels = [...labels, temp_data_1?.[i]?.month_label]

            datasets = [
                {
                    label: t("Closed"), backgroundColor: "#739c66", borderWidth: 1, barThickness: 5,
                    data: [
                        ...datasets?.[0]?.data,
                        parseInt(temp_data_1?.[i]?.data?.find?.((dataset) => dataset?.status === "Closed")?.count) ?? 0,
                    ],
                },
                {
                    label: t("Open"), backgroundColor: "#2936ed", borderWidth: 1, barThickness: 5,
                    data: [
                        ...datasets?.[1]?.data,
                        parseInt(temp_data_1?.[i]?.data?.find?.((dataset) => dataset?.status === "Open")?.count) ?? 0,
                    ],
                },
                {
                    label: t("Won"), backgroundColor: "#f91f85", borderWidth: 1, barThickness: 5,
                    data: [
                        ...datasets?.[2]?.data,
                        parseInt(temp_data_1?.[i]?.data?.find?.((dataset) => dataset?.status === "Won")?.count) ?? 0,
                    ],
                }
            ]
        }
    }

    return { labels, datasets };
}

export const constructOpportunityRevenueData = (data, t = () => false) => {
    let labels = []
    let datasets = [
        { label: t("Closed"), backgroundColor: "#739c66", borderWidth: 1, barThickness: 5, data: [], },
        { label: t("Open"), backgroundColor: "#2936ed", borderWidth: 1, barThickness: 5, data: [], },
        { label: t("Won"), backgroundColor: "#f91f85", borderWidth: 1, barThickness: 5, data: [], }
    ]

    let temp_data_1 = data?.map((item_1) => {
        let temp_data_2
        if (item_1?.data?.length > 0) {
            temp_data_2 = [
                { status: t("Closed"), count: item_1?.data?.find?.((i) => i?.status === "Closed")?.amount ?? 0, },
                { status: t("Open"), count: item_1?.data?.find?.((i) => i?.status === "Open")?.amount ?? 0, },
                { status: t("Won"), count: item_1?.data?.find?.((i) => i?.status === "Won")?.amount ?? 0, },
            ]
        }

        return { ...item_1, data: temp_data_2, }
    })

    for (let i = 0; i < temp_data_1?.length; i++) {
        if (temp_data_1?.[i]?.data?.length > 0) {
            labels = [...labels, temp_data_1?.[i]?.month_label]

            datasets = [
                {
                    label: t("Closed"), backgroundColor: "#739c66", borderWidth: 1, barThickness: 5,
                    data: [
                        ...datasets?.[0]?.data,
                        parseInt(temp_data_1?.[i]?.data?.find?.((dataset) => dataset?.status === "Closed")?.count) ?? 0,
                    ],
                },
                {
                    label: t("Open"), backgroundColor: "#2936ed", borderWidth: 1, barThickness: 5,
                    data: [
                        ...datasets?.[1]?.data,
                        parseInt(temp_data_1?.[i]?.data?.find?.((dataset) => dataset?.status === "Open")?.count) ?? 0,
                    ],
                },
                {
                    label: t("Won"), backgroundColor: "#f91f85", borderWidth: 1, barThickness: 5,
                    data: [
                        ...datasets?.[2]?.data,
                        parseInt(temp_data_1?.[i]?.data?.find?.((dataset) => dataset?.status === "Won")?.count) ?? 0,
                    ],
                }
            ]
        }
    }

    return { labels, datasets };
}

export const constructPerformanceTeamsByMemberData = (data) => {
    let list = data?.map?.((team) => {
        return {
            Image: team?.image_url ?? "",
            rep: team?.first_name ?? "",
            opportunities: team?.open ?? 0,
            won: team?.won ?? 0,
            lost: team?.lost ?? 0,
            Amount: team?.revenue ? `${(team?.revenue / 1000).toFixed(2)} k` : "0 k",
        }
    })

    return list
}

export const constructPerformanceTeamsData = (data) => {
    let list = data?.map?.((team) => {
        return {
            name: team?.team_name ?? "",
            opportunities: team?.open ?? 0,
            won: team?.won ?? 0,
            lost: team?.lost ?? 0,
            Amount: team?.revenue ? `${(team?.revenue / 1000).toFixed(2)} k` : "0 k",
        }
    })

    return list
}

export const constractOppertunityWon = (data) => {
    const final = {
        labels: [""],
        datasets: [
            {
                label: "My First dataset",
                backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                barThickness: 50,
                borderWidth: 1,
                //stack: 1,
                data: [65, 59, 80, 81, 56, 55, 40],
            },

            {
                label: "My second dataset",
                backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                borderWidth: 1,
                barThickness: 50,
                data: [45, 79, 50, 41, 16, 85, 20],
            },
        ],
    };
    return final;
};

export const getMonth = [
    {
        label: "Today",
        value: {
            startDate: new Date(),
            endDate: new Date(),
        },
    },
    {
        label: "YesterDay",
        value: {
            startDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
            endDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        },
    },
    {
        label: "This Week",
        value: startAndEndOfWeek(new Date()),
    },
    {
        label: "This Month",
        value: startAndEndOfMonth(),
    },
];

export const constractData1 = (data, t, currency_symbol) => {
    let agreement = [
        {
            count: data?.new_agreement ?? 0,
            image: <ActivePropertyIcon />,
            sub: "New Agreement",
        },
        {
            count: data?.live_agreement ?? 0,
            image: "/images/propertdashboard/block.svg",
            sub: "Live Agreements",
            image_type: true,
        },
        {
            count: data?.agreement_expiring_this_month ?? 0,
            image: "/images/propertdashboard/floor.svg",
            sub: "Agreement Expiring this month",
            image_type: true,
        },
    ];
    const accountDetails = [
        {
            count: data?.total_invoice_amount ? `${currency_symbol}${data?.total_invoice_amount?.toLocaleString()}` : 0,
            image: <ActivePropertyIcon />,
            sub: t("Total Invoiced"),
        },
        {
            count: data?.oustanding_invoice_amount ? `${currency_symbol}${data?.oustanding_invoice_amount?.toLocaleString()}` : 0,
            image: <ActivePropertyIcon color="#FF6D65" />,
            sub: t("Total Outstanding"),
        },
        {
            count: data?.overdue_amount_amount ? `${currency_symbol}${data?.overdue_amount_amount?.toLocaleString()}` : 0,
            image: <ActivePropertyIcon color="#686AF3" />,
            sub: t("Total Overdues"),
        },
        {
            count: data?.collected_amount ? `${currency_symbol}${data?.collected_amount?.toLocaleString()}` : 0,
            image: <ActivePropertyIcon color="#F49920" />,
            sub: t("Total Collected"),
        },
        {
            count: data?.total_void_projected ? `${currency_symbol}${data?.total_void_projected?.toLocaleString()}` : 0,
            image: <ActivePropertyIcon color="#686AF3" />,
            sub: t("Projected Revenue"),
        },
        {
            count: data?.total_cancelled_amount ? `${currency_symbol}${data?.total_cancelled_amount?.toLocaleString()}` : 0,
            image: <ActivePropertyIcon color="#F49920" />,
            sub: t("Total Cancelled"),
        },
        {
            count: data?.total_tax_amount ? `${currency_symbol}${data?.total_tax_amount?.toLocaleString()}` : 0,
            image: <ActivePropertyIcon color="#686AF3" />,
            sub: t("Total Taxes"),
        },
        {
            count: data?.refunable_amount ? `${currency_symbol}${data?.refunable_amount?.toLocaleString()}` : 0,
            image: <ActivePropertyIcon color="#F49920" />,
            sub: t("Total Refundable"),
        },
    ]
    const customerDetail = [
        {
            count: data?.total_account ?? 0,
            image: <ActivePropertyIcon />,
            sub: t("Accounts"),
        },
        // {
        //     count: data?.vendor_account ?? 0,
        //     image: <ActivePropertyIcon color="#F49920" />,
        //     sub: "Vendors",
        // },
        {
            count: data?.customer_account ?? 0,
            image: <ActivePropertyIcon color="#686AF3" />,
            sub: t("Customers"),
        },
        // {
        //     count: data?.owner_account ?? 0,
        //     image: <ActivePropertyIcon />,
        //     sub: t("Landlords"),
        // },
        // {
        //     count: 0,
        //     image: <ActivePropertyIcon />,
        //     sub: "Contacts",
        // },
        {
            count: data?.broker_account ?? 0,
            image: <ActivePropertyIcon color="#F49920" />,
            sub: t("Brokers"),
        },
        // {
        //     count: data?.resident ?? 0,
        //     image: <ActivePropertyIcon color="#686AF3" />,
        //     sub: "Resident",
        // },
        {
            count: (parseInt(data?.tenant ?? 0) + parseInt(data?.resident ?? 0) + parseInt(data?.owner_count ?? 0)) ?? 0,
            image: <ActivePropertyIcon />,
            sub: t("App Users"),
        },
    ]

    const agreement2 = [
        {
            count: 0,
            image: <ActivePropertyIcon />,
            sub: "xxxx",
        },
        {
            count: 0,
            image: "/images/propertdashboard/vacant.png",
            sub: "Declined Agreement",
            image_type: true,
        },
        {
            count: 0,
            image: "/images/propertdashboard/occupied.png",
            sub: "Under Onboarding",
            image_type: true,
        },
    ];
    let agreement_count_by_status = data?.agreement_count_by_status ?? [];
    let active_agreement_by_agreement_type =
        data?.active_agreement_by_agreement_type ?? [];
    let agreement_revenue_for_three_years =
        data?.agreement_revenue_for_three_years?.map((x) => {
            return {
                name: x?.name,
                // uv: 0,
                bar: x?.sum,
            };
        });
    let invoices_total_by_invoice_type =
        data?.invoices_total_by_invoice_type ?? [];
    let account = [
        {
            count: data?.overdue_accounts ?? 0,
            image: <ActivePropertyIcon />,
            sub: "Overdue Accounts",
        },
        {
            count: data?.overdue_total ?? 0,
            image: "/images/propertdashboard/vacant.png",
            sub: "Overdue Total",
            image_type: true,
        },
        {
            count: data?.total_outstanding_for_this_month ?? 0,
            image: "/images/propertdashboard/occupied.png",
            sub: "Total Outstanding for this month",
            image_type: true,
        },
    ];
    const iamgeData = Array.from(Array(20).keys())?.map((v, i) => {
        return {
            name: `Rubix${i}`,
            meter: "2M",
            color: `#${Math.random().toString(16).substring(2, 8)}`,
        };
    });
    const inVoice1 = [
        {
            sub: data?.active_customers ?? 0,
            sub1: "Active Customers",
            icon: <ActivePropertyIcon />,
        },
        {
            sub: data?.new_customers_for_this_month ?? 0,
            sub1: "New Customers for this month",
            icon: <ActivePropertyIcon />,
        },
    ];
    let invoiced_revenue_For_tree_years =
        data?.invoiced_revenue_For_tree_years?.map((x) => {
            return {
                name: x?.name,
                // uv: 0,
                bar: x?.sum,
            };
        });
    let projected_revenue_overtime = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
        datasets: [
            {
                label: 'Invoiced',

                data: [1, 8, 25, 8, 6, 1, 9],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgb(53, 162, 235)',
            },
            {
                label: 'Collected',

                data: [123, 7, 45, 9, 17, 35, 98, 76],
                borderColor: '#FF9340',
                backgroundColor: '#FF9340',
            },
        ]
    }
    let expiring_agreements = {
        labels: data?.expiring_aggrements?.map((x) => { return x?.month }),
        datasets: [
            {
                label: '',
                data: data?.expiring_aggrements?.map((x) => { return parseInt(x.total_records) }),

                backgroundColor: data?.expiring_aggrements?.map((e) => { return `#${Math.random().toString(16).substring(2, 8)}` })
            },
        ]
    }

    let age_summary = {
        labels: ["<30 days", "<60 days", "<90 days", "<120 days", "older"],
        datasets: [
            {
                label: 'Age',
                data: [data?.accounts_age?.lessThan30, data?.accounts_age?.greaterThan30LessThan60, data?.accounts_age?.greaterThan60LessThan90, data?.accounts_age?.greaterThan90LessThan120, data?.accounts_age?.greaterThan120],

                backgroundColor: '#58D0E0',
            },
        ]
    }
    // let expiring_agreements = data?.expiring_agreements?.map((x) => {
    // return {
    // name: x?.name,
    // value: x?.count,
    // };
    // });
    let Tenant = Array.from(Array(9).keys())?.map((v, i) => {
        return {
            name: `Vacant${i * 1}` ?? "",
            count: 10 ?? "",
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        };
    });
    const topproperties = Array.from(Array(9).keys())?.map((v, i) => {
        return {
            name: `Rubix Apartment${i * 1}` ?? "",
            amount: `INR${i * 100}` ?? "",
        };
    });
    const topagreement = data?.top_agreements?.map((item) => {
        return {
            name: `${item?.agreement_no}` ?? "",
            amount: `${data?.currency}-${item?.total ?? 0}` ?? "",
        };
    });
    const topaccount = data?.top_invoiced_accounts?.map((x) => {
        return {
            number: `${x?.account_no}`,
            name: `${x?.name}`,
            amount: `${data?.currency}-${x?.total}`,
        };
    });
    const inVoice = [
        {
            sub: data?.invoice_total ?? 0,
            sub1: "Posted Invoice This Month",
            icon: <ActivePropertyIcon />,
        },
        {
            sub: data?.quotation_payment_schedule_count ?? 0,
            sub1: "Planned Invoices for this Month",
            icon: <ActivePropertyIcon />,
        },
    ];
    const invoice_by_status = data?.invoice_by_status?.map((v, i) => {
        return {
            name: v?.name ?? "",
            count: v?.count ?? "",
            fill: v?.fill,
        };
    });
    const invoice_by_type = data?.invoice_by_type?.map((v, i) => {
        return {
            name: v?.invoice_type ?? "",
            count: v?.amount ?? "",
            fill: v?.fill,
        };
    });
    const top5accounts = data?.top_5_accounts?.map((v, i) => {
        return {
            name: v?.account_no ?? "",
            count: v?.invoice_due_amount ?? "",
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        };
    });
    const accountsReceivable = [
        {
            name: `Balance`,
            count: data?.balance_percentage ? parseFloat(parseFloat(data?.balance_percentage).toFixed(2)) : 0,
            custom_count: data?.Overdue_balance ?? 0,

            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
        {
            name: `Balance OverDue`,
            count: data?.overdue_percent ? parseFloat(parseFloat(data?.overdue_percent).toFixed(2)) : 0,
            custom_count: data?.account_receivable ?? 0,

            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        },
    ]
    let top_ten_properties_with_highest_agreement =
        data?.top_ten_properties_with_highest_agreement ?? [];

    let invoice_ageing = {
        labels: ["> 30 Days", "30 < 60 Days", "60 < 90 Days", "90 < 120 Days", "< 120 Days"],
        datasets: [
            {
                label: 'Invoives',
                data: [data?.invoice_age?.lessThan30, data?.invoice_age?.greaterThan30LessThan60, data?.invoice_age?.greaterThan60LessThan90, data?.invoice_age?.greaterThan90LessThan120, data?.invoice_age?.greaterThan120],

                backgroundColor: [1, 2, 3, 4, 5]?.map((e) => { return `#${Math.random().toString(16).substring(2, 8)}` })
            },
        ]
    }

    let foreCasted = {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        datasets: [
            {
                label: 'Revenue',
                data: data?.forcost?.map((e) => { return e?.amount?.toFixed(2) }),
                backgroundColor: data?.forcost?.map((e) => { return `#${Math.random().toString(16).substring(2, 8)}` }),
            },
        ]
    }

    let collectedForeCasted = {
        labels: data?.collected_forcost?.map((e) => { return e?.month_label }),
        datasets: [
            {
                label: 'Revenue',
                data: data?.collected_forcost?.map((e) => { return e?.sum ? e?.sum?.toFixed(2) : 0 }),
                backgroundColor: data?.collected_forcost?.map((e) => { return `#${Math.random().toString(16).substring(2, 8)}` }),
            },
        ]
    }

    let topTenRevenueAccount = data?.top_5_accounts?.map((e) => {
        return {
            "amount_paid": `${currency_symbol}${e?.amount_paid?.toLocaleString()}`,
            "name": e?.name
        }
    })

    let topTenOverDueAccount = data?.top_10_overdue_account?.map((e) => {
        return {
            "invoice_total_amount": `${currency_symbol}${e?.invoice_total_amount?.toLocaleString()}`,
            "name": e?.name
        }
    })

    const final = {
        agreement,
        totalInvoices: data?.invoice_by_status?.[0]?.total,
        totalInvoicesByType: data?.invoice_by_type?.[0]?.total,
        agreement_count_by_status,
        accountsReceivable,
        accountsReceivableOverDue: data?.overdue_percent + "%",
        projected_revenue_overtime,
        age_summary,
        invoice_by_status,
        agreement2,
        invoice_by_type,

        active_agreement_by_agreement_type,
        agreement_revenue_for_three_years,
        invoices_total_by_invoice_type,
        account,
        iamgeData,
        inVoice1,
        invoiced_revenue_For_tree_years,
        expiring_agreements,
        Tenant,
        topproperties,
        topagreement,
        accountDetails,
        topaccount,
        top_ten_properties_with_highest_agreement,
        inVoice,
        agreement_revenue: data?.agreement_revenue ?? 0,
        currency: data?.currency ?? 0,
        invoice_total: data?.invoice_total ?? 0,
        payment_total: data?.payment_total ?? 0,
        active_agreement_by_unit_type: data?.active_agreement_by_unit_type ?? [],
        top5accounts,
        top5accountsTotal: data?.top_5_accounts?.map((item) => parseInt(item?.invoice_due_amount)).reduce((a, b) => a + b, 0) ?? 0,
        customerDetail,
        invoice_ageing,
        foreCasted,
        collectedForeCasted,
        topTenRevenueAccount,
        topTenOverDueAccount
    };
    return final;
};

export const CustomMonths = [
    {
        label: "Jan",
        value: "01"
    },
    {
        label: "Feb",
        value: "02"
    },
    {
        label: "Mar",
        value: "03"
    },
    {
        label: "Apr",
        value: "04"
    },
    {
        label: "May",
        value: "05"
    },
    {
        label: "Jun",
        value: "06"
    },
    {
        label: "Jul",
        value: "07"
    },
    {
        label: "Aug",
        value: "08"
    },
    {
        label: "Sep",
        value: "09"
    },
    {
        label: "Oct",
        value: "10"
    },
    {
        label: "Nov",
        value: "11"
    },
    {
        label: "Dec",
        value: "12"
    },
]
export const getYearList = (startYear) => {
    var currentYear = new Date().getFullYear() + 20, years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
        years.push(startYear++);
    }

    return years?.map((val) => {
        return {
            value: val,
            label: val
        }
    });
}

export const revenuePath = ["name", "amount_paid"];
export const revenueHead = (t = () => false) => {
    return [
        { title: t("Account Name"), field: "name" },
        { title: t("Total Revenue"), field: "amount_paid" },
    ];
}

export const overduePath = ["name", "invoice_total_amount"];
export const overdueHead = (t = () => false) => {
    return [
        { title: t("Account Name"), field: "name" },
        { title: t("Total Amount Overdue"), field: "invoice_total_amount" },
    ];
}

export const constructResponseGetRevenueInvoiceTypeStats = ({ data = [] }) => {
    const final_revenue_invoice_type = {
        labels: data?.data?.map?.(_ => _?.invoice_type),
        datasets: [
            {
                label: '',
                data: data?.data?.map((x) => { return parseInt(x?.amount) }),
                backgroundColor: data?.data?.map((e) => { return `#${Math.random().toString(16).substring(2, 8)}` })
            },
        ]
    }

    return {
        total: data?.count,
        data: final_revenue_invoice_type,
    };
}