export const Document = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={42} height={42} {...props}>
    <g data-name="Group 105006">
      <g data-name="Group 105004" transform="translate(-3676 868)">
        <rect
          data-name="Rectangle 55338"
          width={42}
          height={42}
          rx={4}
          transform="translate(3676 -868)"
          fill="#e3ebfe"
        />
      </g>
      <path
        data-name="icons8-rental-house-contract (1)"
        d="M14.565 10A2.568 2.568 0 0 0 12 12.565v17.668a2.568 2.568 0 0 0 2.565 2.567h13.108a2.568 2.568 0 0 0 2.565-2.565V19.119h-6.554a2.568 2.568 0 0 1-2.565-2.565V10Zm8.264.5v6.053a.856.856 0 0 0 .855.855h6.053ZM21.12 20.846a2.461 2.461 0 0 1 1.521.511l2.672 2.1a2.447 2.447 0 0 1 .936 1.931v3.186a1.94 1.94 0 0 1-1.924 1.94h-6.412a1.94 1.94 0 0 1-1.924-1.94v-3.182a2.445 2.445 0 0 1 .937-1.931l2.672-2.1a2.465 2.465 0 0 1 1.522-.515Zm-.856 3.973a.855.855 0 0 0-.855.855v1.71a.855.855 0 0 0 .855.855h1.71a.855.855 0 0 0 .855-.855v-1.71a.855.855 0 0 0-.855-.855Z"
        fill="#7578fe"
      />
    </g>
  </svg>
)

