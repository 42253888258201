export const ClearIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} {...props}>
        <g
            data-name="Group 110653"
            style={{
                transformOrigin: "215.5px 10px",
            }}
            transform="matrix(1.45833 0 0 1.45833 -215.5 -10)"
        >
            <rect
                width={24}
                height={24}
                fill="#ffecec"
                data-name="Rectangle 56948"
                rx={4}
                transform="translate(215.5 10)"
            />
            <path
                d="m234.357 16.523-1.381-1.38-5.476 5.476-5.476-5.476-1.381 1.38L226.119 22l-5.476 5.476 1.381 1.381 5.476-5.477 5.476 5.477 1.381-1.381L228.882 22l5.475-5.477Z"
                style={{
                    fill: "#ff4b4b",
                }}
            />
        </g>
    </svg>
)