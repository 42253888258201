import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import {
    Box, Checkbox, Divider, Grid,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { UnitList } from '../../components';
import { Details } from '../details';
import { Bold, ExtraBold, SemiBold } from "../../utils";


const useStyles = makeStyles((theme) => ({
    root: {
        height: `calc(100vh - 88px)`,
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        overflow: "auto",
        position: "relative"
    },
    shortBtn: {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0
    },

    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
        // borderColor: theme.palette.secondary.main,
    },
    text: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.secondary
    },
    subText: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    bottomTitle: {
        fontSize:"1.125rem",
        fontFamily: ExtraBold

    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        marginBottom: "12px"
    },
    contentBottom: {
        // padding: "12px",

    },
    drawer: {
        "& .MuiDrawer-paper": {

            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px"
        },
    },
    grid: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: theme.palette.borderRadius,
        padding: "4px"
    },
    title: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.primary

    },
    subTitle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    }
}))

export const PropertyFinderDetails = props => {

    const classes = useStyles();

    const data = [
        {
            id: 0,
            apartmentName: "RUBIX APARTMENT",
            leaseableArea: 1000,
            units: 2400,
            totalArea: "165480 Sq.Ft",
            address: "23 Main Street, 3rd Cross street, 3rd Sector, Chennai, Tamilnadu, India -60001",
            bussinessPh: "044 - 22642212",
            mobilePh: "044 - 22642212",
            website: "propertyautomate.com",
            emailid: "Mail@Propertyautomate.Com",
            img: [
                "/images/build.jpg",
                "/images/build2.jpg",
            ],

        }
    ]

    const unitlisted = [
        {
            id: 0,
            image: "/images/image1.png",
            title: "K-F01-394",
            address: "K Tower, 1 Floor",
            sqft: 1250,
            bed: 3,
            shortlist: 12,
            location: "tnagar"
        },
        {
            id: 1,
            image: "/images/image1.png",
            title: "K-F01-394",
            address: "K Tower, 1 Floor",
            sqft: 1250,
            bed: 3,
            amount: "1,200.00",
            shortlist: 12,
            location: "tnagar"

        },
    ];
    return <div className={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={3.5} >
                {data.map((item) => (
                    <>
                        <Details data={item} />
                    </>
                )
                )}
            </Grid>
            <Grid item xs={12} md={12} lg={8.5}>
                <Grid container >
                    <Grid item xs={8} >
                        <Box display={"flex"} alignItems={"center"} marginTop={"14px"}>
                            <Typography className={classes.text}>units</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} textAlign={"right"}>
                        <Typography className={classes.subText}>Select All<span><Checkbox
                            checkedIcon={<CheckCircleIcon />}
                            icon={
                                <CircleOutlinedIcon style={{ color: "#c1c1c1" }} />
                            }
                        /></span>
                        </ Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {unitlisted.map((val) => {
                                return (
                                    <Grid item xs={6}>
                                        <React.Fragment key={val.id}>
                                            <UnitList
                                                data={val} checked />
                                        </React.Fragment>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>


    </div>
}