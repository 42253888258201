import * as React from "react"

export const AggreeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 102360">
      <g data-name="Group 102366" transform="translate(-128.5 -386.75)">
        <rect
          data-name="Rectangle 53874"
          width={40}
          height={40}
          rx={4}
          transform="translate(128.5 386.75)"
          fill="rgba(219,240,241,0.5)"
        />
        <path
          d="M143.5 397.75a2.085 2.085 0 0 0-2 2.138v14.724a2.085 2.085 0 0 0 2 2.138h10.246a2.085 2.085 0 0 0 2-2.138v-9.974a.737.737 0 0 0-.2-.5l-.007-.007-5.774-6.172a.648.648 0 0 0-.472-.209Zm6.457 2.433 3.509 3.742h-2.837a.681.681 0 0 1-.668-.713Zm-1.332 7.067a.555.555 0 0 1 .361.134l2.087 1.754a.635.635 0 0 1 .222.489v2.654a.163.163 0 0 1-.167.178h-5.01a.163.163 0 0 1-.167-.178v-2.653a.638.638 0 0 1 .223-.49l2.087-1.754a.558.558 0 0 1 .364-.134Zm-.445 2.375a.461.461 0 0 0-.445.475v.95a.461.461 0 0 0 .445.475h.891a.461.461 0 0 0 .445-.475v-.95a.461.461 0 0 0-.445-.475Z"
          fill="#6dafb3"
        />
      </g>
    </g>
  </svg>
)

