import { Box, Button, Dialog, DialogContent, DialogContentText, Divider, Drawer, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { FilterGenerator, SearchFilter, Subheader, TableWithPagination, UseDebounce } from "../../components";
import { ContractStyle } from "./style";
import styled from '@mui/material/styles/styled';
import { ContractTableHeading, ContractTablePath } from "../../utils/contract";
import { NetworkCall } from "../../networkcall";
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
// import { useHistory } from "react-router";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { format } from "date-fns";
import { CreateNewContractEdit } from "./createNewContractEdit";
import { CreateNewEditContext } from "./createNewEditContext";

const CustomPaper = styled('div')(({ theme }) => ({
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: 4,
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    height: 'calc(100vh - 170px)'
}))

const Contract = () => {
    const classes = ContractStyle()
    const {
        t,
        handleViewEdit,
        openPopup,
        // setOpenPopup,
        handleCreate,
        handleOnClose,
        contractList,
        count,
        loading,
        publish,
        setPublish,
        getContractList,
        filterData,
        setFilterData,
        stateData
    } = React.useContext(CreateNewEditContext)

    const debounce = UseDebounce()

    //const 

    const heading = ContractTableHeading(t)
    // const history = useHistory()
    const alert = React.useContext(AlertContext)
    // states
    const [searchText, setSearchText] = React.useState("")
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)


    // const [contractType, setContractType] = React.useState([])
    const [deleteOkayPopup, setDeleteOkayPopup] = React.useState(false)
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [filterDrawer, setFilterDrawer] = React.useState(false)
    const [selectedDeleteRow, setSelectedDeleteRow] = React.useState([])
    //permission and company dropdown functions
    const [permission, setPermission] = React.useState({})

    const [companyList, setCompanyList] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = React.useState({})
const [isDisableBtn,setIsDisableBtn] = React.useState(false)
    const auth = React.useContext(AuthContext)
    const backdrop = React.useContext(BackdropContext)


    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getCompany()
            }
        }
        //eslint-disable-next-line
    }, [auth])


    const getCompany = () => {
        let company = getCompanyOption(backdrop, auth, alert)
        if (company) {
            setCompanyList(company?.list)
            setSelectedCompany(company?.selected)
            getContractList(0, "", company?.selected?.value)
            // getContractType()
        }
    }



    // contract type

    // const getContractType = () => {
    //     // setLoading(true)
    //     const payload = {
    //     }
    //     NetworkCall(
    //         `${config.api_url}/contract/contract_type`,
    //         NetWorkCallMethods.post,
    //         payload,
    //         null,
    //         true,
    //         false
    //     ).then((res) => {
    //         let result = res?.data?.data?.data?.map((data) => {
    //             return {
    //                 typeName: `${data?.name} Contract`,
    //                 ...data
    //             }
    //         })
    //         setContractType(result)
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    // }

    // company change

    const handleCompanyChange = (e) => {
        setSelectedCompany(e)
        getContractList(0, "", e?.value)
    }

    // Function to change search text
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }

    // Function to search data 
    const searchTableFunction = (e) => {
        if (page > 1) { setPage(1) }

        getContractList(0, e, selectedCompany?.value)
    }

    // Function to handle pagination in table
    const handlePagination = (e) => {
        setPage(e)
        let offset = (e - 1) * limit

        getContractList(offset, "", selectedCompany?.value)
    }

    // Function to handle page limit in table
    const handleChangeLimit = (e) => {
        setLimit(e)
        setPage(1)
        getContractList(0, e)
    }

    const handleIcon = (type, data) => {
        if (type === "delete") {
            setSelectedDeleteRow(data)
            setDeletePopup(!deletePopup)
        } else if (type === "view") {
            handleViewEdit(type, data)
            return false
        } else if (type === "edit") {
            handleViewEdit(type, data)
            return false
        }
    }

    const handleYes = () => {
        handleDelete(selectedDeleteRow?.id)
    }

    const handleDelete = (id) => {
        // setLoading(true)
        const payload = {
            contract_id: id
        }
        NetworkCall(
            `${config.api_url}/contract/delete`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setDeleteOkayPopup(true)
            setDeletePopup(!deletePopup)
            getContractList(0, "")
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, severity: AlertProps.severity.error,
                msg: AlertProps.message.some_thing_went_wrong,
            })
        })
    }

    const handlePublish = (data) => {
        setPublish(true)
        setIsDisableBtn(true)
        const payload = {
            contract_id: data?.id,
            current_date: format(new Date(), "yyyy-MM-dd")
        }
        NetworkCall(
            `${config.api_url}/contract/publish`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setDeleteOkayPopup(true)
            setIsDisableBtn(false)
            getContractList(0, "", selectedCompany?.value, true)
        }).catch((error) => {
            setIsDisableBtn(false)
            if (error?.response?.data?.error?.code === 406) {
                // Request made and server responded
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.info,
                    msg: error?.response?.data?.error?.message ?? "Try Again",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            } else {
                alert.setSnack({
                    ...alert, open: true, severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong,
                })
            }
        })
    }

    const getFilterData = (e) => {
        setFilterData(e)
        getContractList(0, "", selectedCompany?.value, false, e)
    }

    const loadOptions = async (search = "", array, type, handleLoading) => {
        handleLoading(type)
        let result, payload, offset = 0;
        let limit = 10
        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }

        switch (type) {
            case 'account_number':
                payload = {
                    company_id: selectedCompany?.value,
                    type: ["Property Owner"],
                    offset, limit
                }
                result = await NetworkCall(
                    `${config.api_url}/account/get_all`,
                    NetWorkCallMethods.post, payload, null, true, false
                ).catch(() => {
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                });
                handleLoading(null);
                return {
                    options: [...result?.data?.data?.list?.map((i) => {
                        return {
                            label: i?.account_no,
                            value: i?.account_no,
                            ...i
                        }
                    })],
                    hasMore: (array?.length + result?.data?.data?.list?.length) < result?.data?.data?.count
                }
            case 'status_master':
                payload = {
                    offset, limit
                }
                result = await NetworkCall(
                    `${config.api_url}/contract/status_master`,
                    NetWorkCallMethods.post, payload, null, true, false
                ).catch(() => {
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                });
                handleLoading(null);
                return {
                    options: [...result?.data?.data?.data?.map((i) => {
                        return {
                            label: i?.name,
                            value: i?.id,
                            ...i
                        }
                    })],
                    hasMore: (array?.length + result?.data?.data?.data?.length) < result?.data?.data?.count
                }
            default:
                return { options: [] }
        }
    }

    const render = () => {
        return (
            <Box>
                <Subheader
                    title={t("Contract")}
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) =>
                        handleCompanyChange(e)
                    }
                    // goBack={() => history.goBack()}
                />

                <CustomPaper>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={4}>
                            <Box sx={{ width: 340 }}>
                                {/*search */}
                                <SearchFilter label={false} placeholder={t("search")}
                                    handleChange={(value) => handleSearch(value)} value={searchText}
                                    customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={8} display={"flex"} justifyContent={'end'}>
                            <Box display={"flex"} alignItems="center" sx={{ float: "right" }}>
                                <Stack
                                    direction="row"
                                    display={"flex"}
                                    alignItems="center"
                                    divider={<Divider orientation="vertical" flexItem sx={{ marginInline: '16px' }} />}
                                >
                                    <Box className={classes.filterIcon}>
                                        <IconButton
                                            size="small"
                                            className={classes.img}
                                            onClick={() => setFilterDrawer(true)}
                                        >
                                            <img src="/images/filter.svg" alt="filter" />
                                        </IconButton>
                                    </Box>
                                    <Button variant={"contained"} onClick={() => handleCreate("create")} sx={{ height: '40px' }}>
                                        {t("Create New")}
                                    </Button>
                                </Stack>
                                <Box width={"15px"} />
                            </Box>
                        </Grid>
                    </Grid>

                    {
                        loading ?
                            <NewLoader minusHeight="400px" />
                            :
                            <TableWithPagination
                                heading={heading}
                                rows={contractList}
                                dataType={[
                                    { type: ["more_contract"], name: "icon" },
                                    { type: ["text"], name: "contract_id" },
                                    { type: ["text"], name: "date" },
                                    { type: ["text"], name: "contract_type" },
                                    { type: ["text"], name: "account_id" },
                                    { type: ["text"], name: "account_name" },
                                    { type: ["text"], name: "start_date" },
                                    { type: ["text"], name: "end_date" },
                                    { type: ["text"], name: "created_by" },
                                    { type: ["kyc_status"], name: "status" },
                                    { type: ["publish_button"], name: "publish" }
                                ]}
                                path={ContractTablePath}
                                showpagination
                                tableType="no-side"
                                handleIcon={handleIcon}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={count}
                                page={page}
                                limit={limit}
                                view={true}
                                height={`calc(100vh - 320px)`}
                                edit={true}
                                delete={true}
                                isDisableBtn={isDisableBtn}
                                handlePublish={handlePublish} />
                    }


                    <FilterGenerator open={filterDrawer} onClose={() => setFilterDrawer(false)}
                        onApply={(value) => getFilterData(value)}
                        components={[
                            {
                                component: "select",
                                value: filterData?.accountNumber ?? [],
                                options: [],
                                isMulti: true,
                                label: t("Account Number"),
                                state_name: "accountNumber",
                                placeholder: t("Select Account Number"),
                                loadOptions: (search, array, handleLoading) => loadOptions(search, array, 'account_number', handleLoading),
                                debounceTimeout: 800,
                                isPaginate: true,
                            },
                            {
                                component: "select",
                                label: t("Status"),
                                value: filterData?.status ?? [],
                                state_name: "status",
                                placeholder: t("Select Status"),
                                isPaginate: true,
                                isMulti: true,
                                loadOptions: (search, array, handleLoading) => loadOptions(search, array, 'status_master', handleLoading),
                                debounceTimeout: 800,
                            }
                        ]} />
                </CustomPaper>

                {/* <AlertDialog
                    open={openPopup}
                    header={t("Create Contract")}
                    md={true}
                    onClose={() => setOpenPopup(!openPopup)}
                    component={
                        <CreateContractPopup t={t} list={contractType} selectedCompany={selectedCompany} />
                    }
                /> */}

                {/* delete popup */}
                <Dialog
                    open={deletePopup}
                    onClose={() => setDeletePopup(!deletePopup)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.publishDialog}
                >
                    <DialogContent sx={{ padding: 0 }}>
                        <DialogContentText sx={{ background: "transparent" }}>
                            <div style={{
                                paddingInlineEnd: auth?.auth?.auth?.language === 'ar' ? "240px" : "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                backgroundImage: `url(${"images/announsment.svg"})`,
                                backgroundRepeat: "no-repeat",
                                height: 250,
                                width: 600,
                            }}>
                                <Stack spacing={2} alignItems={"center"}>
                                    <Typography className={classes.deletePopupTitle}>
                                        {t("Are you sure you want to delete this Contract?")}
                                    </Typography>
                                    <Stack direction={"row"}>
                                        <Button className={classes.Yes} onClick={() => handleYes()}>{t("Yes")}</Button>
                                        <Button className={classes.No} onClick={() => setDeletePopup(!deletePopup)}>{t("No")}</Button>
                                    </Stack>
                                </Stack>

                            </div>

                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                {/* delete okay popup */}
                <Dialog
                    open={deleteOkayPopup}
                    onClose={() => setDeleteOkayPopup(!deleteOkayPopup)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.publishDialog}
                >
                    <DialogContent sx={{ padding: 0 }}>
                        <DialogContentText sx={{ background: "transparent" }}>
                            <div style={{
                                paddingInlineEnd: auth?.auth?.auth?.language === 'ar' ? "280px" : "60px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                backgroundImage: `url(${"images/success.svg"})`,
                                backgroundRepeat: "no-repeat",
                                height: 250,
                                width: 600
                            }}>
                                <Stack spacing={2} alignItems={"center"}>
                                    <Typography className={classes.publishPopupTitle}>{publish ? t("Published Succesfully!") : t("Deleted Successfully!")}</Typography>
                                    <Box className={classes.publishPopupBtn}
                                        onClick={() => setDeleteOkayPopup(!deleteOkayPopup)}>
                                        <Typography className={classes.publishbtnText}>{t("Okay")}</Typography>
                                    </Box>
                                </Stack>

                            </div>

                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <React.Fragment key={'right'}>
                    <Drawer
                        anchor={'right'}
                        open={openPopup}
                        onClose={handleOnClose}
                    >
                        {/* <Create t={t} onClose={toggleDrawer} state={state} reload={() => listFunction(0, 10, 1, null)} company_id={state?.SelectedCompany?.value} quote_id={state?.selected_id} lead_id={state?.lead_id} btnName="" />
                         */}
                        <CreateNewContractEdit title={stateData?.type === "view" ? t("View Contract") : stateData?.type === "edit" ? t("Edit Contract") : t("Create Contract")} onClose={handleOnClose} />
                    </Drawer>
                </React.Fragment>

            </Box>
        )
    }

    return <div>
        {accessCheckRender(render, permission)}
    </div>
}

export default Contract