import React from "react"

export const MedicalLeaveIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_106899" data-name="Group 106899" transform="translate(-3837 23495)">
    <g id="Rectangle_55658" data-name="Rectangle 55658" transform="translate(3837 -23495)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <path d="M0,0H24a0,0,0,0,1,0,0V22a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" stroke="none"/>
      <path d="M1,.5H23a.5.5,0,0,1,.5.5V22A1.5,1.5,0,0,1,22,23.5H1A.5.5,0,0,1,.5,23V1A.5.5,0,0,1,1,.5Z" fill="none"/>
    </g>
    <path id="icons8-aed" d="M14.619,6A4.778,4.778,0,0,0,11,7.674,4.778,4.778,0,0,0,7.381,6C5.382,6,3,6.826,3,10.762c0,5.651,7.466,8.779,7.783,8.91a.572.572,0,0,0,.434,0c.318-.13,7.783-3.258,7.783-8.91C19,6.826,16.618,6,14.619,6Zm-1.345,7.616L11.2,16.46a.381.381,0,0,1-.308.156.374.374,0,0,1-.118-.019.381.381,0,0,1-.262-.363l0-1.822L9.086,14.12a.381.381,0,0,1-.231-.6l2.074-2.841.02-.026a.4.4,0,0,1,.069-.063.377.377,0,0,1,.191-.07h.036a.38.38,0,0,1,.376.381c0,.01,0,.019,0,.028l0,1.8,1.425.293a.382.382,0,0,1,.232.6Z" transform="translate(3838 -23496)" fill="#ff4b4b"/>
  </g>
</svg>



)