export const managementRoleMasterHeading = (t = () => false) => {
    return [
        { title: t("ID"), field: "id" },
        { title: t("Name"), field: "name" },
        { title: t("Description"), field: "desc" },
        { title: t("Status"), field: "is_active" },
        { title: "", field: "more" },
    ]
}

export const managementRoleMasterDatatype = [
    { type: ["text"], name: "id" },
    { type: ["text"], name: "name" },
    { type: ["description"], name: "desc" },
    { type: ["status"], name: "is_active" },
    { type: ["more"], name: "icon" },
];

export const managementRoleMasterPath = [
    "id",
    "name",
    "desc",
    "is_active",
    "more"
]

export const Status = [
    {
        label: "Active", value: true
    },
    {
        label: "In-Active", value: false
    }
]
