export const AgreementRequest = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 102641">
            <path data-name="Rectangle 54841" fill="none" d="M0 0h30v30H0z" />
            <g data-name="Agreement Request" fill="#c1c5cb">
                <path
                    data-name="Path 97560"
                    d="M130.636 403.79h-.016a.635.635 0 0 0-.633-.578h-.016a.635.635 0 0 0-.633-.579h-.017a.636.636 0 0 0-1.082-.39l-.431.431a.637.637 0 0 0 .45 1.087h.016a.636.636 0 0 0 .633.576h.016a.637.637 0 0 0 .633.58h.016a.636.636 0 0 0 1.083.389l.431-.431a.636.636 0 0 0-.45-1.086Z"
                    transform="translate(-116.634 -382.492)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97561"
                    d="m188.622 272-1.891-.355a1.364 1.364 0 0 0-1.061.244l-1.847 1.37a.682.682 0 0 0-.005 1.092.989.989 0 0 0 1.116.049l.9-.565a1.5 1.5 0 0 0 .641.668 1.773 1.773 0 0 0 1.425.015l1.685 1.214a.214.214 0 0 0 .219.019l1.16-.568-1.428-3.617Z"
                    transform="translate(-170.812 -256.083)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97562"
                    d="m386.065 222.794-1.75-4.573a.234.234 0 0 0-.3-.137l-.628.224.928 2.563a.234.234 0 1 1-.439.16l-.93-2.565-.775.277a.235.235 0 0 0-.139.307l1.844 4.669a.234.234 0 0 0 .311.129l1.75-.754a.234.234 0 0 0 .125-.3Z"
                    transform="translate(-363.08 -204.256)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97563"
                    d="M4.039 218.86a.235.235 0 0 0-.135-.125l-.776-.276-.928 2.566a.234.234 0 1 1-.439-.16l.928-2.563-.629-.224a.234.234 0 0 0-.3.137l-1.747 4.573a.234.234 0 0 0 .125.3l1.75.754a.234.234 0 0 0 .311-.129l1.844-4.669a.236.236 0 0 0 0-.183Z"
                    transform="translate(7.002 -204.25)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97564"
                    d="M98.38 273.979a.681.681 0 0 1-.4-.129l-1.5-1.078a2.593 2.593 0 0 1-.668.094 1.919 1.919 0 0 1-.886-.208 1.857 1.857 0 0 1-.563-.446l-.516.323a1.457 1.457 0 0 1-1.648-.072 1.151 1.151 0 0 1 .009-1.842l1.338-.993-.052-.038a1.494 1.494 0 0 0-1.291-.219l-.793.236-.631-.377-1.515 3.835 1.057.6.427-.427a2.459 2.459 0 0 1 2.43.891 1.51 1.51 0 0 1 1.08 2.408l-.006.006.091.068a.826.826 0 0 0 1.081-.081l.027-.027a.828.828 0 0 0 .225-.442.825.825 0 0 0 .807-.21l.019-.019a.821.821 0 0 0 .236-.488.82.82 0 0 0 .816-.208l.013-.013a.825.825 0 0 0 .234-.472.824.824 0 0 0 1.015-.805l-.141.069a.686.686 0 0 1-.295.064Z"
                    transform="translate(-79.48 -253.816)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97565"
                    d="M406.94 110.234a.234.234 0 0 0 .234.234h1.3a.234.234 0 0 0 0-.469h-1.3a.234.234 0 0 0-.234.234Z"
                    transform="translate(-387.223 -99.562)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97566"
                    d="M391.537 51.014a.235.235 0 0 0 .119-.031l.974-.572a.235.235 0 1 0-.237-.406l-.974.573a.234.234 0 0 0 .119.438Z"
                    transform="translate(-372.072 -41.41)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97567"
                    d="m391.475 152.1.974.573a.235.235 0 0 0 .237-.406l-.974-.572a.234.234 0 1 0-.238.4Z"
                    transform="translate(-372.129 -139.924)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97568"
                    d="M48.734 110.469h1.3a.234.234 0 1 0 0-.469h-1.3a.234.234 0 0 0 0 .469Z"
                    transform="translate(-39.985 -99.562)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97569"
                    d="m74.564 50.41.974.572a.234.234 0 1 0 .238-.4L74.8 50a.235.235 0 1 0-.237.406Z"
                    transform="translate(-65.121 -41.41)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97570"
                    d="m75.537 151.755-.974.571a.235.235 0 0 0 .237.406l.974-.572a.234.234 0 1 0-.238-.4Z"
                    transform="translate(-65.121 -139.982)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97571"
                    d="M185.766 49.531a2.266 2.266 0 1 0-2.266-2.266 2.266 2.266 0 0 0 2.266 2.266Zm-1.41-2.537a.234.234 0 0 1 .331.011l.724.776 1.433-1.535a.235.235 0 0 1 .344.32l-1.6 1.719a.235.235 0 0 1-.344 0l-.9-.959a.234.234 0 0 1 .012-.332Z"
                    transform="translate(-170.766 -36.594)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    data-name="Path 97572"
                    d="M142.172 7.344a3.672 3.672 0 1 0-3.672-3.672 3.672 3.672 0 0 0 3.672 3.672Zm0-6.406a2.734 2.734 0 1 1-2.734 2.734 2.734 2.734 0 0 1 2.734-2.734Z"
                    transform="translate(-127.172 7)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
        </g>
    </svg>
)