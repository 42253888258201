import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    dialog_root: {
        "& .MuiDialog-paper": {
            borderRadius: theme.palette.borderRadius,
            padding: "0px",
            width: "600px",
        },
    },
    dialog_component: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url("/images/confirmBGImage.svg")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "250px",
    },
    dialog_header: {
        padding: "20px",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    dialog_close_button: {
        padding: "0px"
    },
    dialog_body: {
        padding: "0px 54px",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    question_text: {
        textAlign: "center",
        fontFamily: Bold,
        fontSize:"1rem",
        color: theme.typography.color.white
    },
    yes_button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
        height: "40px",
        padding: "10px 16px",
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.contrastText,
    },
    yes_button_text: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.palette.primary.main,
    },
    no_button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
        height: "40px",
        padding: "10px 16px",
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.primary.contrastText}`,
        backgroundColor: theme.palette.primary.main,
        marginInlineStart:"8px !important"
    },
    no_button_text: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.typography.color.white,
    },
}));