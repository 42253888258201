import * as React from "react"

export const Timer = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 107306" transform="translate(-1008 -319)">
      <circle
        data-name="Ellipse 129564"
        cx={20}
        cy={20}
        r={20}
        transform="translate(1008 319)"
        fill="#d9f6d9"
      />
      <g data-name="Group 105522">
        <path
          data-name="Rectangle 55539"
          fill="none"
          d="M1016 327h24v24h-24z"
        />
        <path
          data-name="icons8-tenses (1)"
          d="M1025 331a8 8 0 1 0 1.412 15.868 7.6 7.6 0 0 0 2.824 0 8 8 0 1 0 0-15.735 7.6 7.6 0 0 0-2.824 0A7.991 7.991 0 0 0 1025 331Zm7.875 1.8a6.582 6.582 0 0 1 0 12.392 7.984 7.984 0 0 0 0-12.392Zm-2.821 0a6.582 6.582 0 0 1 0 12.39 7.987 7.987 0 0 0 0-12.39Zm-5.76 1.489a.706.706 0 0 1 .706.711v3.529h2.588a.706.706 0 0 1 0 1.412h-3.294a.706.706 0 0 1-.706-.706V335a.706.706 0 0 1 .706-.706Z"
          fill="#499364"
        />
      </g>
    </g>
  </svg>
)
