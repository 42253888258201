import React from "react";
import { withNavBars } from "../../../HOCs";
import { ShortTermPricingPropertyView } from "./shortTermPricingPropertyView";

class ShortTermPricingPropertyViewParent extends React.Component {
    render() {
        return <ShortTermPricingPropertyView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ShortTermPricingPropertyViewParent, props);
