import * as React from "react"

const ClockArrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    <path
      d="M6 0A6 6 0 0 0 .115 7.168a1.045 1.045 0 0 1 1.679.407A4.49 4.49 0 0 0 9.36 8.991 1.047 1.047 0 0 1 9.45 6.9h2.1a1.04 1.04 0 0 1 .365.069A5.965 5.965 0 0 0 6 0Zm-.15 2.4a.45.45 0 0 1 .45.45V5.7h1.95a.45.45 0 1 1 0 .9h-2.4a.45.45 0 0 1-.45-.45v-3.3a.45.45 0 0 1 .45-.45ZM.82 7.494a.45.45 0 0 0-.427.616A5.992 5.992 0 0 0 11.1 9.159v.891a.45.45 0 1 0 .9 0v-2.1a.45.45 0 0 0-.45-.45h-2.1a.45.45 0 1 0 0 .9h1.05l-.019.03a5.094 5.094 0 0 1-9.251-.641.45.45 0 0 0-.41-.295Z"
      fill={props.color?props.color:"#4e5a6b"}
    />
  </svg>
)

export default ClockArrow
