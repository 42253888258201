import React from "react"

export const ServiceInspectionIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g id="Group_105063" data-name="Group 105063" transform="translate(0 -0.5)">
                <g id="Group_102366" data-name="Group 102366">
                    <rect id="Rectangle_53874" data-name="Rectangle 53874" width="40" height="40" rx="4" transform="translate(0 0.5)" fill="#5ac782" />
                    <path id="icons8-clipboard-list" d="M21.75,6H19.974A2.245,2.245,0,0,0,17.75,4h-3.5a2.245,2.245,0,0,0-2.224,2H10.25A2.253,2.253,0,0,0,8,8.25v13.5A2.253,2.253,0,0,0,10.25,24h11.5A2.253,2.253,0,0,0,24,21.75V8.25A2.253,2.253,0,0,0,21.75,6Zm-9.5,13.5a.75.75,0,1,1,.75-.75A.75.75,0,0,1,12.25,19.5Zm0-3a.75.75,0,1,1,.75-.75A.75.75,0,0,1,12.25,16.5Zm0-3a.75.75,0,1,1,.75-.75A.75.75,0,0,1,12.25,13.5ZM13.5,6.25a.751.751,0,0,1,.75-.75h3.5a.75.75,0,0,1,0,1.5h-3.5A.751.751,0,0,1,13.5,6.25ZM19.75,19.5h-4.5a.75.75,0,0,1,0-1.5h4.5a.75.75,0,0,1,0,1.5Zm0-3h-4.5a.75.75,0,0,1,0-1.5h4.5a.75.75,0,0,1,0,1.5Zm0-3h-4.5a.75.75,0,0,1,0-1.5h4.5a.75.75,0,0,1,0,1.5Z" transform="translate(4 6)" fill="#fff" />
                </g>
            </g>
        </svg>

    )
}