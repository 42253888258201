import React from "react";
import { withNavBars } from "../../HOCs";
import UnitVacancy from "./unitVacancy";

class UnitVacancyParent extends React.Component {
    render() {
        return <UnitVacancy />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(UnitVacancyParent, props);
