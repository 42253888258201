export const ScheduleBoard = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105178">
            <g data-name="LS - Schedule Board">
                <g
                    data-name="Group 105074"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                >
                    <path
                        data-name="Path 98260"
                        d="M10.98 10.73h.233a.641.641 0 0 0 .641-.641V7.654a.641.641 0 0 0-.641-.641h-.233a.641.641 0 0 0-.641.641v2.435a.641.641 0 0 0 .641.641Z"
                    />
                    <path
                        data-name="Path 98261"
                        d="M19.204 10.711h.233a.641.641 0 0 0 .641-.641V7.641A.641.641 0 0 0 19.437 7h-.233a.641.641 0 0 0-.641.641v2.431a.641.641 0 0 0 .641.639Z"
                    />
                    <path
                        data-name="Path 98262"
                        d="M22.246 8.319H20.6v1.921a1.032 1.032 0 0 1-1.162.995H19.2a1.164 1.164 0 0 1-1.162-1.162V8.319h-5.662v1.769a1.164 1.164 0 0 1-1.163 1.163h-.233a1.164 1.164 0 0 1-1.162-1.162v-1.77H8.042A1.044 1.044 0 0 0 7 9.361v12.6a1.044 1.044 0 0 0 1.042 1.042h14.2a1.044 1.044 0 0 0 1.046-1.042v-12.6a1.044 1.044 0 0 0-1.042-1.042Zm0 13.639H8.042v-9.513h14.2v9.516Z"
                    />
                    <path
                        data-name="Path 98263"
                        d="M15.682 15.636h1.868a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134h-1.872a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .138.134Z"
                    />
                    <path
                        data-name="Path 98264"
                        d="M18.736 15.636h1.872a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134h-1.872a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .134.134Z"
                    />
                    <path
                        data-name="Path 98265"
                        d="M9.573 18.289h1.872a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134H9.573a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .134.134Z"
                    />
                    <path
                        data-name="Path 98266"
                        d="M12.627 18.289h1.872a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134h-1.872a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .134.134Z"
                    />
                    <path
                        data-name="Path 98267"
                        d="M15.682 18.289h1.872a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134h-1.872a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .134.134Z"
                    />
                    <path
                        data-name="Path 98268"
                        d="M18.736 18.289h1.872a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134h-1.872a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .134.134Z"
                    />
                    <path
                        data-name="Path 98269"
                        d="M11.445 19.053H9.573a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .134.134h1.872a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134Z"
                    />
                    <path
                        data-name="Path 98270"
                        d="M14.499 19.053h-1.872a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .134.134h1.872a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134Z"
                    />
                    <path
                        data-name="Path 98271"
                        d="M17.554 19.053h-1.872a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .134.134h1.872a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134Z"
                    />
                    <path
                        data-name="Path 98272"
                        d="M20.608 19.053h-1.872a.134.134 0 0 0-.134.134v1.621a.134.134 0 0 0 .134.134h1.872a.134.134 0 0 0 .134-.134v-1.621a.134.134 0 0 0-.134-.134Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)