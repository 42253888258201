export const DeliveryJobTabIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_115987" data-name="Group 115987" transform="translate(4807.666 7031)">
                <path id="icons8-image_2_" data-name="icons8-image (2)" d="M8.75,6A2.75,2.75,0,0,0,6,8.75V17.7l3.753-3.63a2.067,2.067,0,0,1,2.813,0l3.089,2.988,1.024-.99a2.073,2.073,0,0,1,2.813,0L24,20.429V8.75A2.75,2.75,0,0,0,21.25,6Zm9.5,3.5A2.25,2.25,0,1,1,16,11.75,2.253,2.253,0,0,1,18.25,9.5Zm0,1.5a.75.75,0,1,0,.75.75A.75.75,0,0,0,18.25,11Zm-7.091,4a.52.52,0,0,0-.363.144L6,19.788V21.25a2.75,2.75,0,0,0,2.5,2.737L14.577,18.1l-3.054-2.954A.523.523,0,0,0,11.159,15Zm6.928,2a.521.521,0,0,0-.363.143L10.639,24H21.25a2.75,2.75,0,0,0,2.537-1.69l-5.336-5.162A.524.524,0,0,0,18.087,17Z" transform="translate(-4813.666 -7037)" fill="#98a0ac" opacity="0" />
                <path id="icons8-box_2_" data-name="icons8-box (2)" d="M6.947,10.211v9.711A3.083,3.083,0,0,0,10.026,23h9a3.083,3.083,0,0,0,3.079-3.079V10.211Zm9.711,4.263H12.395a.711.711,0,1,1,0-1.421h4.263a.711.711,0,1,1,0,1.421Zm5.211-5.211H7.184A1.186,1.186,0,0,1,6,8.079V6.184A1.186,1.186,0,0,1,7.184,5H21.868a1.186,1.186,0,0,1,1.184,1.184V8.079A1.186,1.186,0,0,1,21.868,9.263Z" transform="translate(-4813.192 -7036)" fill="#98a0ac" />
            </g>
        </svg>

    )
}