import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React from 'react'
import { AlertDialog } from '../../../components'
import { JobType, SwitchColorsInspection, SwitchInspectionTypeIcons } from '../../../utils'
import { ScheduleCardStyle } from './style'
import Table from "./tableView"

export const ScheduleCard = ({ t, datas = {}, background_color = "", color = "", card_color = "", companyId = "", propertyId = "", resourceType = "", scheduleData = "", type = "", count = "" }) => {
    const classes = ScheduleCardStyle()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isView, setIsView] = React.useState(false)
    const open = Boolean(anchorEl);
    const [requestType, setRequestType] = React.useState("")
    //handle clik
    const handleClick = (event, reqtype) => {
        setAnchorEl(event.currentTarget);
        setRequestType(reqtype)
    };
    //handle close menu
    const handleClose = (type) => {
        setAnchorEl(null);
    };
    return (
        <>
            {datas.resources?.hasOwnProperty("move-in") &&
                <Stack alignItems="center" className={classes.card} sx={{ backgroundColor: "#FEEAEA80" }} direction={"row"}
                    onMouseOver={(event) => handleClick(event, "move-in")}
                >
                    {SwitchInspectionTypeIcons("move-in")}
                    <Typography className={classes.status} sx={{ color: "#B3776D" }}> {t("Move In")}  {datas?.resources?.["move-in"]?.length > 1 ? `+ ${datas?.resources?.["move-in"]?.length - 1}` : ""}</Typography>

                </Stack>
            }
            {datas.resources?.hasOwnProperty("move-out") &&

                <Stack alignItems="center" className={classes.card} sx={{ backgroundColor: "#DBF0F180" }} direction={"row"}
                    onMouseOver={(event) => handleClick(event, "move-out")}
                >
                    {SwitchInspectionTypeIcons("move-out")}
                    <Typography className={classes.status} sx={{ color: "#6DAFB3" }}> {t("Move Out")} {datas?.resources?.["move-out"]?.length > 1 ? `+ ${datas?.resources?.["move-out"]?.length - 1}` : ""}</Typography>
                </Stack>
            }
            {datas.resources?.hasOwnProperty("service") &&
                <Stack alignItems="center" className={classes.card} sx={{ backgroundColor: "#FEEFFC" }} direction={"row"}
                    onMouseOver={(event) => handleClick(event, "service")}
                >
                    {SwitchInspectionTypeIcons("service")}
                    <Typography className={classes.status} sx={{ color: "#A5499A" }}> {t("Service")} {datas?.resources?.["service"]?.length > 1 ? `+ ${datas?.resources?.["service"]?.length - 1}` : ""}</Typography>
                </Stack>
            }
            {datas.resources?.hasOwnProperty("site_visit") &&
                <Stack alignItems="center" className={classes.card} sx={{ backgroundColor: "#E4E9FE80" }} direction={"row"}
                    onMouseOver={(event) => handleClick(event, "site_visit")}
                >
                    {SwitchInspectionTypeIcons("site_visit")}
                    <Typography className={classes.status} sx={{ color: "#7683B7" }}> {t("Site Visit")} {datas?.resources?.["site_visit"]?.length > 1 ? `+ ${datas?.resources?.["site_visit"]?.length - 1}` : ""}</Typography>
                </Stack>
            }
            {datas.resources?.hasOwnProperty("general") &&
                <Stack alignItems="center" className={classes.card} sx={{ backgroundColor: "#FFFAD880" }} direction={"row"}
                    onMouseOver={(event) => handleClick(event, "general")}
                >
                    {SwitchInspectionTypeIcons("general")}
                    <Typography className={classes.status} sx={{ color: "#B3A16D" }}> {t("General")} {datas?.resources?.["general"]?.length > 1 ? `+ ${datas?.resources?.["general"]?.length - 1}` : ""}</Typography>
                </Stack>
            }
            {datas.resources?.hasOwnProperty("unit_readiness") &&

                <Stack alignItems="center" className={classes.card} sx={{ backgroundColor: "#FFD8D880" }} direction={"row"}
                    onMouseOver={(event) => handleClick(event, "unit_readiness")}
                >
                    {SwitchInspectionTypeIcons("unit_readiness")}
                    <Typography className={classes.status} sx={{ color: "#CE6F6F" }}> {t("Unit Readiness")} {datas?.resources?.["unit_readiness"]?.length > 1 ? `+ ${datas?.resources?.["unit_readiness"]?.length - 1}` : ""}</Typography>
                </Stack>
            }
            {datas.resources?.hasOwnProperty("unit_handover") &&
                <Stack alignItems="center" className={classes.card} sx={{ backgroundColor: "#F1F7FF" }} direction={"row"}
                    onMouseOver={(event) => handleClick(event, "unit_handover")}
                >
                    {SwitchInspectionTypeIcons("unit_handover")}
                    <Typography className={classes.status} sx={{ color: "#78B1FE" }}> {t("Unit Handover")} {datas?.resources?.["unit_handover"]?.length > 1 ? `+ ${datas?.resources?.["unit_handover"]?.length - 1}` : ""}</Typography>
                </Stack>
            }
            {datas.resources?.hasOwnProperty("delivery-order") &&

                <Stack alignItems="center" className={classes.card} sx={{ backgroundColor: "#DBF0F180" }} direction={"row"}
                    onMouseOver={(event) => handleClick(event, "delivery-order")}
                >
                    {SwitchInspectionTypeIcons("delivery-order")}
                    <Typography className={classes.status} sx={{ color: "#6DAFB3" }}> {t("Delivery Order")} {datas?.resources?.["delivery-order"]?.length > 1 ? `+ ${datas?.resources?.["delivery-order"]?.length - 1}` : ""}</Typography>
                </Stack>
            }
            <Menu
                className={classes.menuList}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                MenuListProps={{ onMouseLeave: handleClose }}
                PaperProps={{ backgroundColor: "#FFFFFF" }}
            >
                <MenuItem className={classes.menuItem}>
                    <Box>

                        <Stack direction="row">
                            <Box className={classes.avatar} >{SwitchInspectionTypeIcons(requestType)}</Box>
                            <Box marginInlineStart={'8px'}>
                                <Typography className={classes.countNo}> {datas?.resources?.[requestType]?.length} {datas?.resources?.[requestType]?.length > 1 ? t("Requests") : t("Request")}</Typography>
                                <Typography className={classes.status1} sx={{ color: SwitchColorsInspection(requestType)?.color }}> {JobType(requestType)} </Typography>
                            </Box>
                        </Stack>
                        <Typography className={classes.viewAll} onClick={() => { return (setIsView(true), setAnchorEl(null)) }} >{t("View All")}</Typography>
                    </Box>
                </MenuItem>


            </Menu>

            <AlertDialog open={isView}
                onClose={() => setIsView(false)}
                header={t(JobType(requestType))}
                component={<Table t={t} data={datas} type={requestType} companyId={companyId} propertyId={propertyId} resourceType={resourceType} />}

            />
        </>
    )
}