export const Accounts = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98478">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 95040"
                    d="M15.5 10.767a2.616 2.616 0 1 0 2.616 2.616 2.619 2.619 0 0 0-2.616-2.616Zm0 0"
                />
                <path
                    data-name="Path 95041"
                    d="M15.5 8a7.5 7.5 0 0 0-5.6 12.488 5.748 5.748 0 0 1 3.736-4.149 3.5 3.5 0 1 1 3.728 0 5.753 5.753 0 0 1 3.73 4.15A7.5 7.5 0 0 0 15.5 8Zm0 0"
                />
                <path
                    data-name="Path 95042"
                    d="M10.667 21.232a7.484 7.484 0 0 0 9.662 0 4.861 4.861 0 0 0-9.662 0Zm0 0"
                />
            </g>
            <path data-name="Rectangle 52167" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
