import React from 'react'
import OwnerTableView from '../ownerTableView/ownerTableView'
import { ResidentTableView } from '../residnetTableView/residentTableView'

export const Details = (props) => {
    return (
        <>{
            props?.type === "Owner" ?
                <OwnerTableView userId={props?.data?.created_by}
                    companyId={props?.data?.company_id}
                    hideSubheader={false} />
                :
                <ResidentTableView userId={props?.data?.user_profile_id}
                    companyId={props?.data?.company_id}
                    hideSubheader={false} />
        }

        </>
    )
}