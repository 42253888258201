import { styled } from "@mui/material/styles";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";

// styled component
export const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
  ({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px solid ${theme?.palette?.border?.third}`,
    },
  })
);

export const useStyles = makeStyles((theme) => ({
  treeContainer: {
    position: "relative",
    "& .MuiTreeItem-content .MuiTreeItem-label": {
      position: "relative",
    },
    "& .MuiTreeItem-content.Mui-selected": {
      color: `${theme?.typography?.status?.primary} !important`,
      fontFamily: Bold,
      fontSize:"0.875rem",
      borderRadius: "4px",
      backgroundColor: theme?.palette?.info?.light,
      "& .fileIcon": {
        color: `${theme?.palette?.border?.primary} !important`,
      },
    },
    "& .MuiTreeItem-content": {
      fontFamily: Bold,
      fontSize:"0.875rem",
      color: "#4E5A6B",
      marginBottom: "3px",
      padding: "0 !important",
    },
    "& .MuiTreeItem-content:hover": {
      color:`${theme?.typography?.status?.primary} !important`,
      fontFamily: Bold,
      fontSize:"0.875rem",
      borderRadius: "4px",
      backgroundColor: theme?.palette?.info?.light,
    },
  },
  Icons: {
    position: "absolute",
    right: "10px",
    top: "11px",
  },
  treeContainerParent: {
    padding: "8px",
    borderRight: `1px solid ${theme?.palette?.border?.secondary}`,
    height: `calc(100vh - 205px)`,
    overflow: "auto",
  },
//   fileIcon: {
//     color: "#CED3DD",
//   },
}));
