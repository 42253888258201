import { Dialog, IconButton, Stack, Typography } from "@mui/material"
import { useStyles } from "./style"
import CloseIcon from '@mui/icons-material/Close';

export const SuccessDialog = ({
    isDialogOpen = false,
    setIsDialogOpen = () => false,
    isButtonDisable = false,
    message = "",
    okay_text = "",
    okay_action = () => false,
}) => {

    const classes = useStyles()

    return <Dialog
        className={classes.dialog_root}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}>
        <div className={classes.dialog_component}>
            <div className={classes.dialog_Header}>
                <IconButton onClick={() => setIsDialogOpen(false)}
                    className={classes.dialog_close_button}>
                    <CloseIcon htmlColor="#FFFFFF" height="14px" width="14px" />
                </IconButton>
            </div>
            <div className={classes.dialog_body}>
                <Stack spacing={"40px"} width={"300px"} alignItems={"center"}>
                    <Typography className={classes.message_text}>
                        {message}
                    </Typography>
                    <div className={classes.okay_button}
                        onClick={() => { !isButtonDisable && okay_action() }}>
                        <Typography className={classes.okay_button_text}>
                            {okay_text}
                        </Typography>
                    </div>
                </Stack>
            </div>
        </div>
    </Dialog>
}