import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import { ListingDashBoard } from './listingDashboard'

class ListingDashBoardParent extends Component {
    render() {
        return (
            <div>
                <ListingDashBoard  {...this.props} />
            </div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ListingDashBoardParent, props)