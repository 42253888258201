export const DeleteBackupIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} {...props}>
        <g data-name="Group 113263">
            <path fill="#fff" d="M0 0h80v80H0z" />
            <path
                fill="#b39ddb"
                d="M50.01 68.185H30.146a6.008 6.008 0 0 1-6.02-5.117l-6.621-44.544h45.146L55.88 63.069a5.969 5.969 0 0 1-5.87 5.116Z"
                data-name="Path 99317"
            />
            <path
                fill="#9575cd"
                d="M46.097 11H34.058l-9.029 9.029h30.1Z"
                data-name="Path 99318"
            />
            <path
                fill="#7e57c2"
                d="M19.01 14.01h42.136a3.019 3.019 0 0 1 3.01 3.01v3.01H16v-3.01a3.019 3.019 0 0 1 3.01-3.01Z"
                data-name="Path 99319"
            />
        </g>
    </svg>
)