import makeStyles from "@mui/styles/makeStyles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";
export const useStylesManagement = makeStyles((theme) => ({
      root: {
            border: "2px solid white",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 0px 16px #00000014",
            backgroundColor: "white",
            padding: "16px",
            height: `calc(100vh - 88px)`,
      },

      card: {
            border: "2px solid white",
            borderRadius: 12,
            boxShadow: "0px 0px 16px #00000014",
            backgroundColor: "white",
            padding: "16px",
      },

      title: {
            color: '#98A0AC',
            fontSize:"0.75rem",
            fontFamily: Bold,

      },

      roles: {
            fontSize:"0.875rem",
            color: '#091B29',
            fontFamily: Bold,
            marginBottom: '0 !important',
            marginLeft: '10px',
            width:150,
            textAlign:"left"
      },
      addmember: {
            width: '100%',
            borderRadius:8,
            '&:hover': {
                  backgroundColor: '#fff',

            }
      },
      managericon: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#E29336',
            justifyContent: 'center',
            padding: '10px 15px'

      },
      // card
      cards: {
            cursor: "pointer",

            backgroundColor: theme.palette.background.paper,
            margin: "auto 4px",
            overflow: "hidden",
      },
      Cardcontent: {
            [theme.breakpoints.up("sm")]: {
                  padding: "8px 12px",
            },

            [theme.breakpoints.down("sm")]: {
                  padding: "8px 0px",
            },
      },
      title1: {
            fontSize:"1rem",
            color: theme.typography.color.secondary,
            fontFamily: Bold,
      },
      progress: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: Bold,
            background: "#78B1FE",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
      },
      approved: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: Bold,
            background: "#5AC782",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
      },
      delete: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: Bold,
            background: "red",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
      },
      cancel: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: Bold,
            background: "#CED3DD",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
      },
      dot: {
            height: "6px",
            width: "6px",
            borderRadius: "50%",
            backgroundColor: "#CED3DD",
            margin: "6px",
      },
      sub1: {
            fontSize:"0.875rem",
            color: '#091B29',
            fontFamily: Regular,
            fontWeight: 600
      },
      img: {
            borderRadius: theme.palette.borderRadius,
            border: "1px solid #E4E8EE",
            padding: "2px 8px",
      },
      rejected: {
            fontSize:"0.75rem",
            color: "#FFFFFF",
            fontFamily: Bold,
            background: "#EC903F",
            padding: "1px 8px",
            borderRadius: theme.palette.borderRadius,
            display: "inline",
      },
      boxes: {
            flexFlow: 'wrap !important'
      },
      // dialog
      dialog: {
            width: "100%",
            maxWidth: "auto",
            padding: "0 !important",
            "& .MuiPaper-root": {
                  width: "100% !important",
                  borderRadius: theme.palette.borderRadius,
            },
            "& .MuiDialogContent-root": {
                  padding: "0px !important",
                  position: 'relative'
            },
      },
      header: {
            border: "1px solid #E4E8EE",
            fontSize:"1rem",
            fontFamily: ExtraBold,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
      },
      select: {
            color: '#091B29',
            fontSize:"0.75rem",
            fontFamily: Bold,
            marginLeft: '10px'
      },
      selectBox: {
            display: 'flex',
            alignItems: 'center',
            padding: '5px 10px 5px 5px',
            backgroundColor: '#F5F7FA',
            borderRadius: '50px',
            marginTop: '12px',
            cursor: 'pointer',
            justifyContent:"space-between",
            width:"100%",
            height:50
      },
      avatarOuter:{
            background:theme.palette.border.secondary,
            height:28,
            width:28,
            borderRadius:"50%"
      },
      avatar: {
            height: '30px',
            width: '30px',
            padding: '8px'
      },
      button: {
            width: '100%',
            color: '#fff',
            fontSize:"0.875rem",
            height: '40px',
            fontFamily: Bold,
            '&:hover': {
                  backgroundColor: '#5078E1',
            }
      },
      preview: {
            color: '#091B29',
            fontSize:"0.875rem",
            fontFamily: Bold,
      },
      previewdetails: {
            color: '#091B29',
            fontSize:"0.875rem",
            fontFamily: SemiBold,
      },
      detailsBox: {
            display: 'flex',
            justifyContent:"space-between",
            flexFlow: 'wrap !important',
            alignItems: 'center'
      },
      box: {
            padding: '10px'
      }
}));