export const Communications = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <path data-name="Rectangle 52494" fill="none" d="M0 0h30v30H0z" />
        <g data-name="communication">
            <g data-name="Group 99038" fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 95340"
                    d="M9.99 14.393v-2.412h-.355A2.638 2.638 0 0 0 7 14.616v7.888a.5.5 0 0 0 .846.35l1.845-1.845H17.3a2.638 2.638 0 0 0 2.635-2.635v-.355h-6.319a3.63 3.63 0 0 1-3.626-3.626Z"
                />
                <path
                    data-name="Path 95341"
                    d="M21.281 8h-7.665a2.638 2.638 0 0 0-2.635 2.635v3.758a2.638 2.638 0 0 0 2.635 2.635h7.609l1.845 1.845a.5.5 0 0 0 .846-.35v-7.888A2.638 2.638 0 0 0 21.281 8Zm-6.819 4.972a.5.5 0 1 1 .5-.5.5.5 0 0 1-.499.5Zm2.986 0a.5.5 0 1 1 .5-.5.5.5 0 0 1-.499.5Zm2.986 0a.5.5 0 1 1 .5-.5.5.5 0 0 1-.5.5Z"
                />
            </g>
        </g>
    </svg>
)
