import { Box } from "@mui/material"
import React from "react"
import { withNavBars } from "../../HOCs"
import { AllResourceIcon } from "../../assets/allResourcesIcon"
import { Subheader } from "../../components"
import { config } from "../../config"
import { NetworkCall } from "../../networkcall"
import { NetWorkCallMethods, useWindowDimensions } from "../../utils"
import { MapComponent } from "./components/mapComponent"

const ServiceHubMapView = ({ company_id = "", property_id = "", date = "", setShowMap = () => false, data = {}, t }) => {
    const [mapLoading, setMapLoading] = React.useState(false);
    const size = useWindowDimensions()
    const [searchResourceTxt, setSearchResourceTxt] = React.useState("")
    const [offset, setOffset] = React.useState(0)
    const [inspectorData, setInspectorData] = React.useState({
        list: [],
        selectedInspector: ""
    })
    const [mapData, setMapData] = React.useState([])
    const [mapSearchData, setMapSearchData] = React.useState("")
    React.useEffect(() => {
        getInspectorList()
        //eslint-disable-next-line
    }, [])
    //get Inspector list
    const getInspectorList = (offset = 0, limit = 10, search = "", fetchMore = false, isSearch = false) => {
        const payload = {
            "company_id": company_id,
            "property": property_id,
            "start_date": date?.value?.from_date,
            "end_date": date?.value?.to_date,
            "offset": offset,
            "limit": limit,
            "search": search
        };
        NetworkCall(
            `${config.api_url}/inspection_manager/get_inspector`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const allOption = [{ value: null, id: null, first_name: "All Resources", isIcon: true, icon: <AllResourceIcon /> }]
                if (fetchMore) {
                    setInspectorData({ ...inspectorData, list: inspectorData?.list?.concat(res?.data?.data?.list) })
                }
                else if (isSearch) {
                    setInspectorData({ ...inspectorData, list: res?.data?.data?.list })
                }
                else {
                    getMapData(allOption?.[0]?.id)
                    setInspectorData({ list: allOption.concat(res?.data?.data?.list), selectedInspector: allOption?.[0] })
                }

            })
            .catch((error) => {
                console.log(error)
            })
    }
    //get Map details
    const getMapData = (resource_id = "", search = "") => {
        const payload = {
            "company_id": company_id,
            "resource_id": resource_id,
            "property": property_id,
            "start_date": date?.value?.from_date,
            "end_date": date?.value?.to_date,
            "search": search
        };
        NetworkCall(
            `${config.api_url}/inspection_manager/get_inspection_jobs`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setMapLoading(false)
                const result = res?.data?.data?.map((val) => {
                    return {
                        ...val,
                        address: {
                            "country": val?.country,
                            "district": `${val?.city},${val?.state}`,
                            "longitude": val?.longitude,
                            "latitude": val?.latitude,
                        },
                        "total_inspection": val?.inspections?.length
                    }
                })
                setMapData(result)
            })
            .catch((error) => {
                setMapLoading(false)
                console.log(error)
            })
    }
    //handle resource
    const handleResource = (val, setAnchorEl) => {
        setInspectorData({ ...inspectorData, selectedInspector: val })
        getMapData(val?.id, "")
        setAnchorEl(null)
    }
    //handle search resource
    const handleSearchResource = (val) => {
        setSearchResourceTxt(val)
        getInspectorList(0, 10, val, false, true)
    }
    //fetch more resources
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getInspectorList(offset + 10, 10, "", true)
    }
    //handleMapSearchdata
    const handleMapSearchData = (val) => {
        setMapSearchData(val)
        getMapData(inspectorData?.selectedInspector?.id, val)
    }
    return (
        <Box>
            <Subheader
                goBack={() => setShowMap(false)}
                title={t("Request Map View")}
            />
            <Box p={2} height={size?.height - 120}>
                <MapComponent
                    title={""}
                    mapData={mapData}
                    data={data}
                    // total={locationDetails?.total}
                    // updateState={updateState}
                    // data={state}
                    // tabNameState={state?.status}
                    // tabList={["Vacant", "Occupied"]}
                    // keyType={"status"}
                    mapLoading={mapLoading}
                    // sumbit={sumbit}
                    inspectorData={inspectorData?.list}
                    selectedInspector={inspectorData?.selectedInspector}
                    height={"100%"}
                    handleResource={handleResource}
                    handleSearchResource={handleSearchResource}
                    searchResourceTxt={searchResourceTxt}
                    fetchMoreData={fetchMoreData}
                    handleMapSearchData={handleMapSearchData}
                    mapSearchData={mapSearchData}
                    // onZoomClick={zoomGraphFunction}
                    // isZoom
                    flexFlow={"column"}
                    t={t}
                />
            </Box>
        </Box>
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(ServiceHubMapView, props)