export const CompaniesPrefixIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 107961">
      <path
        data-name="icons8-male-user (4)"
        d="M12 4a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8Zm0 3.6a2 2 0 1 1-2 2 2 2 0 0 1 2-2Zm3.6 6.615c0 1.144-1.459 2.185-3.6 2.185s-3.6-1.041-3.6-2.185v-.27a.745.745 0 0 1 .745-.745h5.71a.745.745 0 0 1 .745.745Z"
        fill={props?.fill ?? "#98a0ac"}
      />
    </g>
  </svg>
)