import React from "react";
import { withNavBars } from "../../HOCs";
import CreateEditCorrespondences from "./createEditCorrespondences";

class CreateEditCorrespondencesParent extends React.Component {
  render() { return <CreateEditCorrespondences {...this.props} />; }
}

const props = { boxShadow: false }

export default withNavBars(CreateEditCorrespondencesParent, props);
