import React from "react";
import { withNavBars } from "../../HOCs";
import { Search } from "./search";
import ContextProvider from "./searchContext";
const SearchParent = () => {
  return (
    <ContextProvider>
      <Search />
    </ContextProvider>
  );
};

export default withNavBars(SearchParent);
