import * as React from "react"

const FilterIMG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      d="M.972 0A.981.981 0 0 0 0 .972v1.2a2.918 2.918 0 0 0 1.112 2.291l3.941 3.075v5.875a.583.583 0 0 0 .922.475L8.7 11.947a.583.583 0 0 0 .245-.475v-3.93l3.941-3.075A2.918 2.918 0 0 0 14 2.174v-1.2A.981.981 0 0 0 13.028 0Zm.194 1.167h11.667v1.007a1.748 1.748 0 0 1-.667 1.374L8 6.8a.583.583 0 0 0-.225.46v3.915l-1.556 1.111V7.257A.583.583 0 0 0 6 6.8L1.834 3.548a1.748 1.748 0 0 1-.667-1.374Z"
      fill={props?.color}
    />
  </svg>
)

export default FilterIMG
