
export const TabTitleList = (t = () => false) => {
    return [
        {
            label: t("Invoices"),
            value: "Invoice"
        },
        {
            label: t("Payments"),
            value: "Payment"
        },
        {
            label: t("Receipts"),
            value: "Receipt"
        },
        {
            label: t("Maintenance Requests"),
            value: "Maintenance"
        },
        {
            label: t("Inspection Orders"),
            value: "Inspection Jobs"
        },
        {
            label: t("Units"),
            value: "Units"
        }
    ]
}


export const InvoiceHeading = (t = () => false) => {
    return [
        { title: t("Invoice number"), field: "invoice_no" },
        { title: t("Invoice type"), field: "invoice_type" },
        { title: t("Invoice Total Amount"), field: "invoice_total_amount" },
        { title: t("Invoice due amount"), field: "invoice_due_amount" },
        { title: t("Invoice date"), field: "invoice_date" },
        { title: t("Invoice Tax"), field: "invoice_tax" },
        { title: t("Due Date"), field: "due_date" },
        { title: t("Status"), field: "status" },
    ];
}

export const InvoicePath = [
    "invoice_no",
    "invoice_type",
    "invoice_total_amount",
    "invoice_due_amount",
    "invoice_date",
    "invoice_tax",
    "due_date",
    "status"
]

export const InvoiceDatatype = [
    { type: ["text"], name: "invoice_no" },
    { type: ["text"], name: "invoice_type" },
    { type: ["text"], name: "invoice_total_amount" },
    { type: ["text"], name: "invoice_due_amount" },
    { type: ["date"], name: "invoice_date" },
    { type: ["text"], name: "invoice_tax" },
    { type: ["date"], name: "due_date" },
    { type: ["status"], name: "status" },
]

export const PaymentHeading = (t = () => false) => {
    return [
        { title: t("Settlement"), field: "settlement" },
        { title: t("Invoice Number"), field: "invoice_no" },
        { title: t("Tagged Date"), field: "tagged_date" },
        { title: t("Receipt Number"), field: "receipt_no" },
        { title: t("Source"), field: "source" },
        { title: t("Tagged Amount"), field: "tagged_amount" },
    ];
}

export const PaymentPath = [
    "settlement",
    "invoice_no",
    "tagged_date",
    "receipt_no",
    "source",
    "tagged_amount"
]

export const PaymentDatatype = [
    { type: ["text"], name: "settlement" },
    { type: ["text"], name: "invoice_no" },
    { type: ["date"], name: "tagged_date" },
    { type: ["text"], name: "receipt_no" },
    { type: ["text"], name: "source" },
    { type: ["text"], name: "tagged_amount" }
]


export const ReceiptsHeading = (t = () => false) => {
    return [
        { title: t("Receipt Number"), field: "receipt_no" },
        { title: t("Receipt Date"), field: "receipt_date" },
        { title: t("Receipt Amount"), field: "receipt_amount" },
        { title: t("Unused Balance"), field: "unused_balance" },
        { title: t("Payment Method"), field: "payment_method" },
    ];
}

export const ReceiptsPath = [
    "receipt_no",
    "receipt_date",
    "receipt_amount",
    "unused_balance",
    "payment_method"
]

export const ReceiptsDatatype = [
    { type: ["text"], name: "receipt_no" },
    { type: ["date"], name: "receipt_date" },
    { type: ["text"], name: "receipt_amount" },
    { type: ["text"], name: "unused_balance" },
    { type: ["text"], name: "payment_method" },
]

export const MaintenanceRequestHeading = (t = () => false) => {
    return [
        { title: t("Request Id"), field: "request_id" },
        { title: t("Description"), field: "description" },
        { title: t("Property Name"), field: "property_name" },
        { title: t("Unit Name"), field: "unit_name" },
        { title: t("Category"), field: "category" },
        { title: t("Raised On"), field: "raised_on" },
        { title: t("Date Closed"), field: "date_closed" },
        { title: t("Status"), field: "status" },
    ];
}

export const MaintenanceRequestPath = [
    "request_id",
    "description",
    "property_name",
    "unit_name",
    "category",
    "raised_on",
    "date_closed",
    "status"
]

export const MaintenanceRequestDatatype = [
    { type: ["text"], name: "request_id" },
    { type: ["text"], name: "description" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "category" },
    { type: ["date"], name: "raised_on" },
    { type: ["date"], name: "date_closed" },
    { type: ["status"], name: "status" }
]


export const InspectionOrderHeading = (t = () => false) => {
    return [
        { title: t("Job Id"), field: "job_id" },
        { title: t("Job Type"), field: "job_type" },
        { title: t("Unit Name"), field: "unit_name" },
        { title: t("Job Date"), field: "job_date" },
        { title: t("Inspector Name"), field: "inspector_name" },
        { title: t("Job Status"), field: "status" },
    ];
}

export const InspectionOrderPath = [
    "job_id",
    "job_type",
    "unit_name",
    "job_date",
    "inspector_name",
    "status"
]

export const InspectionOrderDatatype = [
    { type: ["text"], name: "job_id" },
    { type: ["text"], name: "job_type" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "job_date" },
    { type: ["text"], name: "inspector_name" },
    { type: ["status"], name: "status" }
]

export const UnitsHeading = (t = () => false) => {
    return [
        { title: t("Unit No"), field: "unit_no" },
        { title: t("Unit Name"), field: "unit_name" },
        { title: t("Property No"), field: "property_no" },
        { title: t("Property Name"), field: "property_name" },
        { title: t("Location"), field: "location" },
        { title: t("Move In Date"), field: "move_in_date" },
        { title: t("Move Out Date"), field: "move_out_date" },
        { title: t("Status"), field: "status" },
    ];
}

export const UnitsPath = [
    "unit_no",
    "unit_name",
    "property_no",
    "property_name",
    "location",
    "move_in_date",
    "move_out_date",
    "status"
]

export const UnitsDatatype = [
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "property_no" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "location" },
    { type: ["date"], name: "move_in_date" },
    { type: ["date"], name: "move_out_date" },
    { type: ["status"], name: "status" }
]