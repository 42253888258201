import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { contactViewStyles } from "../style";
import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';
import { AlertDialog } from '../../../components'
import { stringAvatar } from "../../../utils";

export const GeneralDetails = (props) => {
    const { t } = (props);
    const classes = contactViewStyles()
    const size = useWindowDimensions()
    const [imageViwer, setImageViwer] = React.useState(false)
    const { data } = props;
    const address = data ? {
        latitude: data?.latitude,
        longitude: data?.longitude,
    } : {};


    const GoogleMap = () => {
        return (
            <MyGoogleMap
                lat={address?.latitude}
                lng={address?.longitude}
                center={{
                    lat: address?.latitude,
                    lng: address?.longitude
                }}
                zoom={13} readonly />
        )
    }
    return (
        <div style={{ height: size?.height - 210, overflow: "auto", padding: "4px", margin: "-4px" }}>
            <Grid container className={classes.card}>
                <Grid item md={2} lg={1.5} className={classes.imgDiv}>
                    {/* <img src={props?.data?.image_url ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                        onClick={() => setImageViwer(true)} /> */}
                    <Avatar src={props?.data?.image_url} className={classes.unitImg}
                        {...stringAvatar(props?.data?.first_name)}
                        onClick={() => setImageViwer(true)}></Avatar>
                </Grid>
                <Grid item md={10} lg={10.5} className={classes.imgDiv2}>
                    <Typography className={classes.title} style={{ marginBottom: '10px' }}>{t("Profile Details")}</Typography>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.company_name ?? ""}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Name")}</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.first_name ?? ""}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Job Title")}</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.job_title ?? ""}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Company ID")}</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.company_no ?? ""}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("Relation Ship")}</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.relationship ?? ""}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Box>
                                <Typography className={classes.heading}>{t("UCID")}</Typography>
                                <Typography className={classes.sub} noWrap>{props?.data?.customer_id ?? ""}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} lg={6}>
                            <Box>
                                <Typography className={classes.heading}>{t("Properties")}</Typography>
                                {
                                    props?.data?.properties?.map((x, index) => {
                                        return (
                                            <Typography className={classes.sub} noWrap>{index + 1}.&nbsp;{x?.name ?? ""}</Typography>
                                        )
                                    })
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={0} lg={4.5} />
            </Grid>
            <br />
            <div className={classes.card}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className={classes.map}>
                            <GoogleMap />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.addressRoot}>
                            <Stack direction="row" >
                                <img src='/images/loc.svg' alt='' />
                                <Typography className={classes.title}>&nbsp;Address</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Typography className={classes.sub}>
                                {props?.data?.door_no ? props?.data?.door_no + ", " ?? "" : ""}
                                {props?.data?.street_1 ? props?.data?.street_1 + ", " ?? "" : ""}
                                {props?.data?.street_2 ? props?.data?.street_2 + ", " ?? "" : ""}
                                {props?.data?.landmark ? props?.data?.landmark + ", " ?? "" : ""}
                                {props?.data?.area ? props?.data?.area + ", " ?? "" : ""}
                                {props?.data?.city ? props?.data?.city + ", " ?? "" : ""}
                                {props?.data?.state ? props?.data?.state + ", " ?? "" : ""}
                                {props?.data?.country ? props?.data?.country + ", " ?? "" : ""}
                                {props?.data?.zipcode ? props?.data?.zipcode ?? "" : ""}
                            </Typography>
                            <Box height="16px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Latitude")} : </Typography>
                                <Typography className={classes.sub1} >&nbsp;{props?.data?.latitude ?? ""}</Typography>
                            </Stack>
                            <Box height="12px" />
                            <Stack direction="row" >
                                <Typography className={classes.heading}>{t("Longitude")} : </Typography>
                                <Typography className={classes.sub1} >&nbsp;{props?.data?.longitude ?? ""}</Typography>
                            </Stack>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.card} style={{ marginTop: '20px' }}>
                <Stack direction="row" marginBottom={2} >
                    <img src='/images/Group 7015.svg' alt='' />
                    <Typography className={classes.title}>&nbsp;{t("Contact & Other Information")}</Typography>
                </Stack>
                <Grid container rowSpacing={2}>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Preferred Method Of Contact")} :</Typography>
                        <Typography className={classes.sub} noWrap>{props?.data?.preferred_mode_contact ?? ""}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Primary Telephone")} :</Typography>
                        <Typography className={classes.sub} noWrap>
                            {(props?.data?.phone && props?.data?.phone !== "") ?
                                ((props?.data?.phone_code ?? "") + (props?.data?.phone_code && "-") + props?.data?.phone ?? "") : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Primary Mobile")} :</Typography>
                        <Typography className={classes.sub} noWrap>
                            {(props?.data?.mobile_no && props?.data?.mobile_no !== "") ?
                                ((props?.data?.mobile_no_country_code ?? "") + (props?.data?.mobile_no_country_code && "-") + props?.data?.mobile_no ?? "") : "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                        <Typography className={classes.sub} noWrap>{props?.data?.email_id ?? "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Emergency Contact Name")} :</Typography>
                        <Typography className={classes.sub} noWrap>{props?.data?.emergency_contact_name ?? "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Emergency Contact Relationship")} :</Typography>
                        <Typography className={classes.sub} noWrap>{props?.data?.emergency_contact_relationship ?? "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Emergency Contact Number")} :</Typography>
                        <Typography className={classes.sub} noWrap>
                            {(props?.data?.emergency_contact_number && props?.data?.emergency_contact_number !== "") ?
                                ((props?.data?.emergency_contact_code ?? "") + (props?.data?.emergency_contact_code && "-") + props?.data?.emergency_contact_number ?? "") : "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Facebook")} :</Typography>
                        <Typography className={classes.sub} noWrap>{props?.data?.facebook ?? "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Twitter")} :</Typography>
                        <Typography className={classes.sub} noWrap>{props?.data?.twitter ?? "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Typography className={classes.heading}>{t("Linkedin")} :</Typography>
                        <Typography className={classes.sub} noWrap>{props?.data?.linkedin ?? "-"}</Typography>
                    </Grid>
                </Grid>
            </div>
            <AlertDialog isNormal isnotTitle component={
                <div style={{ display: 'flex' }}>
                    <img src={props?.data?.image_url} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                </div>
            } open={imageViwer} onClose={() => setImageViwer(false)} />
        </div>
    )
}