import React from "react";
import { withNavBars } from "../../HOCs";
import LedgerMaster from "./ledgerMaster";

class LedgerMasterParent extends React.Component {
  render() {
    return <LedgerMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(LedgerMasterParent, props);
