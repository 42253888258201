export const Import = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g
      id="Group_99685"
      data-name="Group 99685"
      transform="translate(-1527 505)"
    >
      <g
        id="Group_8127"
        data-name="Group 8127"
        transform="translate(-10 -1483)"
      >
        <g
          id="Group_99684"
          data-name="Group 99684"
          transform="translate(-4 -42)"
        >
          <path
            id="Rectangle_31"
            data-name="Rectangle 31"
            transform="translate(1541 1020)"
            style={{
              fill: "none",
            }}
            d="M0 0h30v30H0z"
          />
          <g id="export" transform="translate(1545.616 1028)">
            <path
              id="Path_95385"
              data-name="Path 95385"
              className="cls-2"
              d="M14.37 23.348h-2.241v1.33h2.241c.667 0 1.184.34 1.184.634v5.9c0 .293-.517.634-1.184.634H5.9c-.667 0-1.184-.34-1.184-.634v-5.9c0-.293.517-.634 1.184-.634h2.239v-1.33H5.9a2.265 2.265 0 0 0-2.514 1.963v5.9A2.265 2.265 0 0 0 5.9 33.173h8.47a2.265 2.265 0 0 0 2.514-1.963v-5.9a2.265 2.265 0 0 0-2.514-1.962Z"
              transform="translate(0 -18.173)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
            <path
              id="Path_95386"
              data-name="Path 95386"
              className="cls-2"
              d="M22.621 6.4a.663.663 0 0 1 .47.195l.833.833V-.335A.665.665 0 0 1 24.589-1a.665.665 0 0 1 .665.665v7.81l.878-.878a.665.665 0 0 1 .94.94l-1.986 1.987a.662.662 0 0 1-.47.194h-.01a.662.662 0 0 1-.47-.194l-1.985-1.986a.665.665 0 0 1 .47-1.135Z"
              transform="translate(-14.457 2)"
              style={{
                fill: props?.fill ?? "#c1c5cb",
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
