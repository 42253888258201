import React from "react";
import { withNavBars } from "../../HOCs";
import UtilitiesCategory from "./utilitiesCategory";

class UtilitiesCategoryParent extends React.Component {
  render() {
    return <UtilitiesCategory {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(UtilitiesCategoryParent, props);
