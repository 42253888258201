import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { ExtraBold, NetWorkCallMethods } from "../../../utils";
import { NewLoader } from "../../newLoader";
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    header:{
        padding:"12px",
        borderBottom:"1px solid #E4E8EE",
        backgroundColor:"white"
    },
    templateTitle: {
        color: '#091B29',
        fontFamily: ExtraBold,
        fontSize:"1rem",
        marginInlineStart:"16px"
    },
}))
export const PreviewComponent = ({ template_id, id, open, onClose }) => {
    const [pdf, setPdf] = React.useState({
        loading: true,
        file: null
    });
    const classes=useStyles();

    React.useEffect(() => {
        if (template_id) {
            const payload = {
                id,
                type_master_id: template_id
            }
            NetworkCall(
                `${config.api_url}/agreement/template`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((res) => {
                setPdf({
                    loading: false,
                    file: res?.data
                })
            })
        }
// eslint-disable-next-line
    }, [template_id])

    return (
        <React.Fragment key={'right'}>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={onClose}
            >
                <Box width={'700px'}>
                <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} className={classes.header}>
                        <Stack direction="row" spacing={2} alignItems={'center'}>
                            <IconButton onClick={onClose} style={{backgroundColor:"#F2F4F7",borderRadius:"4px"}}>
                            <CloseIcon  />
                            </IconButton>
                            <Typography className={classes.templateTitle}>View</Typography></Stack>
                    </Stack >
                    {
                        pdf?.loading ?
                          
                            <NewLoader minusHeight={'250px'} />

                            :
                            <iframe
                            src={`data:application/pdf;base64,${pdf?.file
                                }#toolbar=0`}
                            alt="pdf"
                            width="100%"
                            style={{ height: `calc(100vh - 72px)` }}
                            title="quotation"

                        />
                    }
                </Box>
            </Drawer >
        </React.Fragment >
    )
}