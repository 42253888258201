export const TermsAndConditionIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={188}
    height={204.723}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f1f7ff" />
        <stop offset={1} stopColor="#dbeaff" />
      </linearGradient>
      <clipPath id="b">
        <path
          fill="url(#a)"
          d="M89.877-24.254C174.035-26.029 188 39.407 188 89.227s-42.085 90.207-94 90.207S0 139.046 0 89.227 5.719-22.479 89.877-24.254Z"
          data-name="Path 100824"
          transform="translate(-23436 -5821.434)"
        />
      </clipPath>
    </defs>
    <g data-name="Group 114493" transform="translate(-78 -90.277)">
      <circle
        cx={94}
        cy={94}
        r={94}
        fill="url(#a)"
        data-name="Ellipse 129961"
        transform="translate(78 107)"
      />
      <g
        clipPath="url(#b)"
        data-name="Mask Group 108217"
        transform="translate(23514 5936)"
      >
        <g data-name="Group 114493">
          <g
            data-name="Group 115781"
            transform="translate(-24056.887 -6116.853)"
          >
            <rect
              width={105.416}
              height={122.577}
              fill="#c7dffe"
              data-name="Rectangle 57725"
              rx={12}
              transform="translate(662.228 286.04)"
            />
            <path
              fill="#adb5bd"
              d="M756.362 305.443h-60.525a1.9 1.9 0 0 0 0 3.677h60.523a1.9 1.9 0 0 0 0-3.677Z"
              data-name="Path 100659"
            />
            <path
              fill="#adb5bd"
              d="M755.936 315.372h-82a1.839 1.839 0 0 0 0 3.677h82a1.839 1.839 0 0 0 0-3.677Z"
              data-name="Path 100660"
            />
            <path
              fill="#adb5bd"
              d="M755.936 325.694h-82a1.839 1.839 0 0 0 0 3.677h82a1.839 1.839 0 0 0 0-3.677Z"
              data-name="Path 100661"
            />
            <path
              fill="#adb5bd"
              d="M755.936 335.622h-82a1.839 1.839 0 0 0 0 3.678h82a1.839 1.839 0 0 0 0-3.678Z"
              data-name="Path 100662"
            />
            <path
              fill="#adb5bd"
              d="M755.936 345.945h-82a1.839 1.839 0 0 0 0 3.678h82a1.839 1.839 0 0 0 0-3.678Z"
              data-name="Path 100663"
            />
            <path
              fill="#adb5bd"
              d="M755.936 355.87h-82a1.839 1.839 0 0 0 0 3.678h82a1.839 1.839 0 0 0 0-3.678Z"
              data-name="Path 100664"
            />
            <path
              fill="#adb5bd"
              d="M755.936 366.192h-82a1.839 1.839 0 0 0 0 3.678h82a1.839 1.839 0 0 0 0-3.678Z"
              data-name="Path 100665"
            />
            <path
              fill="#adb5bd"
              d="M755.936 376.123h-82a1.839 1.839 0 0 0 0 3.678h82a1.839 1.839 0 0 0 0-3.678Z"
              data-name="Path 100666"
            />
            <path
              fill="#adb5bd"
              d="M755.936 386.445h-82a1.839 1.839 0 0 0 0 3.678h82a1.839 1.839 0 0 0 0-3.678Z"
              data-name="Path 100667"
            />
            <path
              fill="#adb5bd"
              d="M755.936 396.374h-82a1.839 1.839 0 0 0 0 3.678h82a1.839 1.839 0 0 0 0-3.678Z"
              data-name="Path 100668"
            />
          </g>
          <g
            data-name="Group 115779"
            transform="translate(-23921.349 -6105.205)"
          >
            <rect
              width={116.341}
              height={135.281}
              fill="#78b1fe"
              data-name="Rectangle 57725"
              rx={12}
              transform="translate(521.228 286.04)"
            />
            <rect
              width={94.189}
              height={19.36}
              fill="#d5e7ff"
              data-name="Rectangle 57726"
              rx={8}
              transform="translate(532.12 296.902)"
            />
            <rect
              width={94.189}
              height={19.36}
              fill="#dee2e6"
              data-name="Rectangle 58101"
              rx={1.128}
              transform="translate(532.12 328.69)"
            />
            <rect
              width={94.189}
              height={19.36}
              fill="#dee2e6"
              data-name="Rectangle 58102"
              rx={1.128}
              transform="translate(532.12 359.569)"
            />
            <rect
              width={94.189}
              height={19.36}
              fill="#dee2e6"
              data-name="Rectangle 58103"
              rx={1.128}
              transform="translate(532.12 391.357)"
            />
          </g>
          <g data-name="Group 115780">
            <g
              fill="#fff"
              stroke="#f2f4f7"
              data-name="Rectangle 57725"
              transform="translate(-23406 -5806)"
            >
              <rect width={128.098} height={164.952} stroke="none" rx={12} />
              <rect
                width={127.098}
                height={163.952}
                x={0.5}
                y={0.5}
                fill="none"
                rx={11.5}
              />
            </g>
            <path
              fill="rgba(120,177,254,0.31)"
              d="M-23292.13-5736.206h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
              data-name="Path 100663"
            />
            <path
              fill="rgba(120,177,254,0.31)"
              d="M-23292.13-5724.144h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
              data-name="Path 100664"
            />
            <path
              fill="rgba(120,177,254,0.31)"
              d="M-23292.13-5711.602h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
              data-name="Path 100665"
            />
            <path
              fill="rgba(120,177,254,0.31)"
              d="M-23292.13-5699.535h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
              data-name="Path 100666"
            />
            <path
              fill="rgba(120,177,254,0.31)"
              d="M-23292.13-5662.863h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
              data-name="Path 100825"
            />
            <path
              fill="rgba(120,177,254,0.31)"
              d="M-23292.13-5686.992h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
              data-name="Path 100667"
            />
            <path
              fill="rgba(120,177,254,0.31)"
              d="M-23292.13-5650.32h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
              data-name="Path 100826"
            />
            <path
              fill="rgba(120,177,254,0.31)"
              d="M-23292.13-5674.927h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
              data-name="Path 100668"
            />
            <path
              fill="rgba(120,177,254,0.31)"
              d="M-23292.13-5639.255h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
              data-name="Path 100827"
            />
            <path
              fill="#42a4ee"
              d="m-23345.781-5747.366-7.678-4.509 7.1-11.177-13.269.569v-9l13.369.664-7.1-11.269 7.676-4.508 6.158 11.842 6.068-11.852 7.779 4.619-7.094 11.163 13.262-.663v9l-13.269-.569 7.107 11.185-7.784 4.5-6.162-11.845Zm6.163-15.227 6.791 13.06 4.969-2.873-7.837-12.33 14.627.627v-5.742l-14.634.735 7.849-12.349-4.974-2.953-6.686 13.055-6.793-13.064-4.878 2.864 7.843 12.447-14.731-.735v5.742l14.627-.627-7.836 12.335 4.875 2.863Z"
              data-name="* - Outline"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
