import React from "react";

export const CommunityIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <defs>
    <linearGradient id="linear-gradient" x1="0.168" y1="0.098" x2="0.715" y2="0.974" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#31abec"/>
      <stop offset="0.324" stop-color="#2498e1"/>
      <stop offset="0.704" stop-color="#1988d8"/>
      <stop offset="1" stop-color="#1582d5"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.74" y1="0.991" x2="0.164" y2="0.119" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#0968b5"/>
      <stop offset="1" stop-color="#0471c7"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" x1="0.161" y1="37.639" x2="0.858" y2="36.942" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#32bdef"/>
      <stop offset="1" stop-color="#1ea2e4"/>
    </linearGradient>
    <linearGradient id="linear-gradient-5" x1="0.161" y1="36.839" x2="0.858" y2="36.141" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#31abec"/>
      <stop offset="1" stop-color="#1582d5"/>
    </linearGradient>
    <linearGradient id="linear-gradient-7" x1="0.161" y1="36.839" x2="0.858" y2="36.141" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#0471c7"/>
      <stop offset="1" stop-color="#0968b5"/>
    </linearGradient>
    <linearGradient id="linear-gradient-8" x1="0.913" y1="1.164" x2="0.139" y2="0.141" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#1ea2e4"/>
      <stop offset="1" stop-color="#32bdef"/>
    </linearGradient>
  </defs>
  <g id="Group_113702" data-name="Group 113702" transform="translate(-1296 -272)">
    <g id="Group_113568" data-name="Group 113568">
      <rect id="icons8-smart-home-error-48" width="32" height="32" transform="translate(1296 272)" fill="#fff"/>
      <g id="icons8-microsoft-people_1_" data-name="icons8-microsoft-people (1)" transform="translate(1295 270)">
        <path id="Path_100215" data-name="Path 100215" d="M13.667,35.333H4.333A1.333,1.333,0,0,1,3,34V28a6,6,0,0,1,6-6H9a6,6,0,0,1,6,6v6A1.333,1.333,0,0,1,13.667,35.333Z" transform="translate(0 -5.333)" fill="url(#linear-gradient)"/>
        <path id="Path_100216" data-name="Path 100216" d="M37.667,35.333H28.333A1.333,1.333,0,0,1,27,34V28a6,6,0,0,1,6-6h0a6,6,0,0,1,6,6v6A1.333,1.333,0,0,1,37.667,35.333Z" transform="translate(-8 -5.333)" fill="url(#linear-gradient-2)"/>
        <path id="Path_100217" data-name="Path 100217" d="M13.667,35.333H4.333A1.333,1.333,0,0,1,3,34V28a6,6,0,0,1,6-6H9a6,6,0,0,1,6,6v6A1.333,1.333,0,0,1,13.667,35.333Z" transform="translate(0 -5.333)" fill="url(#linear-gradient)"/>
        <circle id="Ellipse_129971" data-name="Ellipse 129971" cx="3.333" cy="3.333" r="3.333" transform="translate(13.667 6)" fill="url(#linear-gradient-4)"/>
        <circle id="Ellipse_129972" data-name="Ellipse 129972" cx="3.333" cy="3.333" r="3.333" transform="translate(5.667 8.667)" fill="url(#linear-gradient-5)"/>
        <path id="Path_100218" data-name="Path 100218" d="M37.667,35.333H28.333A1.333,1.333,0,0,1,27,34V28a6,6,0,0,1,6-6h0a6,6,0,0,1,6,6v6A1.333,1.333,0,0,1,37.667,35.333Z" transform="translate(-8 -5.333)" fill="url(#linear-gradient-2)"/>
        <circle id="Ellipse_129973" data-name="Ellipse 129973" cx="3.333" cy="3.333" r="3.333" transform="translate(21.667 8.667)" fill="url(#linear-gradient-7)"/>
        <g id="Group_113565" data-name="Group 113565" transform="translate(9.667 16.917)" opacity="0.05">
          <path id="Path_100219" data-name="Path 100219" d="M18.149,34.792H15a.668.668,0,0,1-.667-.667v-8a5.957,5.957,0,0,1,.947-3.223,5.957,5.957,0,0,0-1.238-.527A7.277,7.277,0,0,0,13,26.125v8a1.987,1.987,0,0,0,.52,1.333H17A1.329,1.329,0,0,0,18.149,34.792Z" transform="translate(-13 -22.376)"/>
          <path id="Path_100220" data-name="Path 100220" d="M32.426,34.125v-8a7.277,7.277,0,0,0-1.043-3.749,5.957,5.957,0,0,0-1.238.527,5.957,5.957,0,0,1,.947,3.223v8a.668.668,0,0,1-.667.667H27.277a1.328,1.328,0,0,0,1.149.667h3.48A1.987,1.987,0,0,0,32.426,34.125Z" transform="translate(-17.759 -22.376)"/>
        </g>
        <g id="Group_113566" data-name="Group 113566" transform="translate(10 17.027)" opacity="0.07">
          <path id="Path_100221" data-name="Path 100221" d="M18.04,35.18H15.167a1,1,0,0,1-1-1v-8a6.289,6.289,0,0,1,.985-3.377,5.912,5.912,0,0,0-.619-.263A6.952,6.952,0,0,0,13.5,26.18v8a1.661,1.661,0,0,0,.677,1.333h2.99A1.323,1.323,0,0,0,18.04,35.18Z" transform="translate(-13.5 -22.54)"/>
          <path id="Path_100222" data-name="Path 100222" d="M32.23,34.18v-8A6.948,6.948,0,0,0,31.2,22.541a5.974,5.974,0,0,0-.619.263,6.285,6.285,0,0,1,.985,3.376v8a1,1,0,0,1-1,1H27.69a1.323,1.323,0,0,0,.873.333h2.99A1.661,1.661,0,0,0,32.23,34.18Z" transform="translate(-18.23 -22.54)"/>
        </g>
        <path id="Path_100223" data-name="Path 100223" d="M26,34H15.333A1.333,1.333,0,0,1,14,32.667v-8A6.667,6.667,0,0,1,20.667,18h0a6.667,6.667,0,0,1,6.667,6.667v8A1.333,1.333,0,0,1,26,34Z" transform="translate(-3.667 -4)" fill="url(#linear-gradient-8)"/>
      </g>
    </g>
  </g>
</svg>

)