import * as React from "react"
const NoContractIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={181} height={45} {...props}>
    <g data-name="Group 113822" transform="translate(-979 -383.588)">
      <rect
        width={181}
        height={45}
        fill="#fff"
        data-name="Rectangle 57599"
        rx={8}
        transform="translate(979 383.588)"
      />
      <g data-name="Group 113821">
        <path
          fill="#ced3dd"
          d="M990.035 392.643h20.517a3.419 3.419 0 0 1 3.419 3.419v20.517a3.419 3.419 0 0 1-3.419 3.419h-20.517a3.419 3.419 0 0 1-3.419-3.419v-20.517a3.419 3.419 0 0 1 3.419-3.419Z"
          data-name="Path 100576"
        />
        <path
          fill="#fff"
          d="M1000.294 400.337a3.1 3.1 0 0 0-3.206 2.992 3.1 3.1 0 0 0 3.206 2.992 3.1 3.1 0 0 0 3.206-2.992 3.1 3.1 0 0 0-3.206-2.992Zm3.854 7.181h-7.707a1.237 1.237 0 0 0-1.276 1.19v.455a2.746 2.746 0 0 0 1.7 2.412 7.654 7.654 0 0 0 3.428.729c2.47 0 5.129-.983 5.129-3.142v-.455a1.237 1.237 0 0 0-1.274-1.189Z"
        />
      </g>
      <rect
        width={132}
        height={8}
        fill="#ced3dd"
        data-name="Rectangle 57600"
        rx={4}
        transform="translate(1020 396.274)"
      />
      <rect
        width={32}
        height={5}
        fill="#e4e8ee"
        data-name="Rectangle 57601"
        rx={2.5}
        transform="translate(1020 410.957)"
      />
      <rect
        width={32}
        height={5}
        fill="#e4e8ee"
        data-name="Rectangle 57603"
        rx={2.5}
        transform="translate(1105 410.957)"
      />
      <rect
        width={40}
        height={5}
        fill="#e4e8ee"
        data-name="Rectangle 57602"
        rx={2.5}
        transform="translate(1059 410.957)"
      />
    </g>
  </svg>
)
export default NoContractIcon
