import React from "react"

export const TotalIcon = () =>(

<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
  <defs>
    <linearGradient id="linear-gradient" x1="0.5" y1="28.472" x2="0.5" y2="29.472" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#f0f0f0"/>
      <stop offset="1" stop-color="#bbc1c4"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.019" y1="180.352" x2="0.94" y2="181.273" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#33bef0"/>
      <stop offset="1" stop-color="#0a85d9"/>
    </linearGradient>
    <linearGradient id="linear-gradient-3" x1="0.5" y1="16.221" x2="0.5" y2="17.203" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#393c40"/>
      <stop offset="0.102" stop-color="#35383c"/>
      <stop offset="0.471" stop-color="#2d3033"/>
      <stop offset="1" stop-color="#2b2e30"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" x1="0.5" y1="76.847" x2="0.5" y2="77.825" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#d43a02"/>
      <stop offset="1" stop-color="#b9360c"/>
    </linearGradient>
  </defs>
  <g id="Group_113558" data-name="Group 113558" transform="translate(9967 23246)">
    <rect id="icons8-smart-home-checked-96" width="36" height="36" transform="translate(-9967 -23246)" fill="#fff" opacity="0"/>
    <g id="icons8-warehouse_2_" data-name="icons8-warehouse (2)" transform="translate(-9968.888 -23246.145)">
      <path id="Path_100208" data-name="Path 100208" d="M33.05,35.232H7.591A1.591,1.591,0,0,1,6,33.641V12.956L20.32,5l14.32,7.956V33.641A1.591,1.591,0,0,1,33.05,35.232Z" transform="translate(-0.432 -0.379)" fill="url(#linear-gradient)"/>
      <path id="Path_100209" data-name="Path 100209" d="M21.8,12h3.182a.8.8,0,0,1,.8.8v3.182a.8.8,0,0,1-.8.8H21.8a.8.8,0,0,1-.8-.8V12.8A.8.8,0,0,1,21.8,12Z" transform="translate(-3.498 -1.81)" fill="url(#linear-gradient-2)"/>
      <rect id="Rectangle_57500" data-name="Rectangle 57500" width="23.867" height="15.912" transform="translate(7.955 18.941)" fill="url(#linear-gradient-3)"/>
      <path id="Path_100210" data-name="Path 100210" d="M32.867,22.591H9v-.8a.8.8,0,0,1,.8-.8H32.072a.8.8,0,0,1,.8.8Z" transform="translate(-1.045 -3.65)" fill="#64717c"/>
      <path id="Path_100211" data-name="Path 100211" d="M33.934,14.244l-14.045-7.8-14.045,7.8a.8.8,0,0,1-1.082-.309l-.773-1.391A.8.8,0,0,1,4.3,11.462L18.729,3.444a2.388,2.388,0,0,1,2.318,0l14.432,8.018a.8.8,0,0,1,.309,1.082l-.773,1.391A.793.793,0,0,1,33.934,14.244Z" transform="translate(0 0)" fill="url(#linear-gradient-4)"/>
      <path id="Path_100212" data-name="Path 100212" d="M22.16,41.365H15.4a.4.4,0,0,1-.4-.4V35.4a.4.4,0,0,1,.4-.4H22.16Z" transform="translate(-2.272 -6.512)" fill="#e3a600"/>
      <path id="Path_100213" data-name="Path 100213" d="M30.762,41.365H24V35h6.762a.4.4,0,0,1,.4.4v5.569A.4.4,0,0,1,30.762,41.365Z" transform="translate(-4.111 -6.512)" fill="#c48c00"/>
      <path id="Path_100214" data-name="Path 100214" d="M26.558,33.365H19.4a.4.4,0,0,1-.4-.4V27.4a.4.4,0,0,1,.4-.4h7.16a.4.4,0,0,1,.4.4v5.569A.4.4,0,0,1,26.558,33.365Z" transform="translate(-3.089 -4.877)" fill="#fec730"/>
    </g>
  </g>
</svg>


)