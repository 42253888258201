import { makeStyles } from "@mui/styles"
import { SemiBold } from "../../utils"


export const MonthlyCalendarStyles = makeStyles((theme) => ({
    // calendar dates styles
    calDates: {
        textAlign: "center",
        overflow: "auto",
        height: `calc(100vh - 280px)`,
        [theme.breakpoints.only('md')]: {
            height: `calc(100vh - 310px)`,
        },
        [theme.breakpoints.only('sm')]: {
            height: `calc(100vh - 310px)`,
        },

    },
    date: {
        color: theme.typography.color.secondary,
        fontSize:"1.125rem",
        fontWeight: "bold"
    },
    date_outline: {
        color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 120,
    },
    hidden_other_month_dates: {
        visibility: "hidden"
    },
    eventlabel: {
        overflow: "hidden",
        width: "100%",
        cursor: "pointer",
        // background:"#8FAEFF"

    },
    eventtxt: {
        whiteSpace: "nowrap",
        width: "99%",
        fontSize:"0.75rem",
        cursor: "pointer"

    },
    moreLabel: {
        float: "left",
        fontSize:"0.75rem",
        fontWeight: "bold",
        color: theme.palette.info.main,
    },
    days: {
        fontSize:"0.75rem",
        textTransform: "uppercase",
        color: theme.typography.color.tertiary,
        textAlign: "center"
    },
    successStrip: {
        background: theme.palette.success.light,
        color: theme.palette.success.main,
        whiteSpace: "nowrap",
        // width: "99%",
        fontSize:"0.75rem",
        cursor: "pointer",
        fontFamily: SemiBold,
        display: "flex",
        alignItems: "center",
        height: "25px",
        padding: 4,
        textOverflow: "ellipsis"
    },
    cancelledStrip: {
        background: theme.palette.error.light,
        color: theme.palette.error.main,
        whiteSpace: "nowrap",
        width: "99%",
        fontSize:"0.75rem",
        cursor: "pointer",
        fontFamily: SemiBold,
        display: "flex",
        alignItems: "center",
        height: "25px",
        padding: 4
    },
    expiredStrip: {
        background: theme.palette.background.tertiary,
        color: theme.typography.color.secondary,
        whiteSpace: "nowrap",
        width: "99%",
        fontSize:"0.75rem",
        cursor: "pointer",
        fontFamily: SemiBold,
        display: "flex",
        alignItems: "center",
        height: "25px",
        padding: 4

    }
}))