import { KeyboardDoubleArrowLeft } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { addMonths, addYears, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, subMonths, subYears } from "date-fns";
import moment from 'moment';
import React, { useContext } from 'react';
import { AlertContext } from '../../contexts';
import { AlertProps } from '../../utils';
import { CalendarStyle } from './style';
import { withNamespaces } from 'react-i18next';
const CalendarWithTime = ({ inspection = false, t, handleDateChange = () => false, selected_date = "", dateTime = "", setAnchorEl = () => false, noTime = false, hideCondition = false }) => {


    const classes = CalendarStyle()
    const alert = useContext(AlertContext)
    const [month, setMonth] = React.useState(dateTime === "" ? new Date() : dateTime)
    const [selectedTime, setSelectedTime] = React.useState(moment(dateTime).format("HH:mm"))
    const [finalDays, setFinalDays] = React.useState([])
    const [time, setTime] = React.useState([])

    //dateRange
    const dateRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());

        const dates = [];

        while (date <= endDate) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }
    const next = () => {
        const datebyMonth = addMonths(month, 1)
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        setMonth(addMonths(month, 1))
    }

    const prev = () => {
        const datebyMonth = subMonths(month, 1)
        const monthStart = startOfMonth(datebyMonth);
        const monthEnd = endOfMonth(monthStart);
        const monthstartDate = startOfWeek(monthStart);
        const monthendDate = endOfWeek(monthEnd);
        let finaldays = dateRange(monthstartDate, monthendDate)
        setFinalDays(finaldays)
        setMonth(subMonths(month, 1))
    }
    // get year
    const nextYear = () => {
        const year = addYears(month, 1)
        if (hideCondition) {
            const monthStart = startOfMonth(year);
            const monthEnd = endOfMonth(monthStart);
            const startDate = startOfWeek(monthStart);
            const endDate = endOfWeek(monthEnd);
            let finaldays = dateRange(startDate, endDate)
            setFinalDays(finaldays)
            setMonth(addYears(month, 1))
        } else {
            if (moment(month).format("YYYY") <= moment(year).format("YYYY")) {
                const monthStart = startOfMonth(year);
                const monthEnd = endOfMonth(monthStart);
                const startDate = startOfWeek(monthStart);
                const endDate = endOfWeek(monthEnd);
                let finaldays = dateRange(startDate, endDate)
                setFinalDays(finaldays)
                setMonth(addYears(month, 1))
            }
        }
    }
    const prevYear = () => {
        const year = subYears(month, 1)
        if (hideCondition || noTime) {
            const monthStart = startOfMonth(year);
            const monthEnd = endOfMonth(monthStart);
            const startDate = startOfWeek(monthStart);
            const endDate = endOfWeek(monthEnd);
            let finaldays = dateRange(startDate, endDate)
            setFinalDays(finaldays)
            setMonth(subYears(month, 1))
        } else {
            if (moment(new Date()).format("YY") <= moment(year).format("YY")) {
                const monthStart = startOfMonth(year);
                const monthEnd = endOfMonth(monthStart);
                const startDate = startOfWeek(monthStart);
                const endDate = endOfWeek(monthEnd);
                let finaldays = dateRange(startDate, endDate)
                setFinalDays(finaldays)
                setMonth(subYears(month, 1))
            }
        }

    }
    // Month rendar funtion and state
    function intervals(startString, endString) {
        var start = moment(startString, 'YYYY-MM-DD hh:mm a');
        var end = moment(endString, 'YYYY-MM-DD hh:mm a');

        // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
        // note that 59 will round up to 60, and moment.js handles that correctly
        start.minutes(Math.ceil(start.minutes() / 30) * 30);

        var result = [];

        var current = moment(start);

        while (current <= end) {
            result.push(current.format('HH:mm'));
            current.add(30, 'minutes');
        }

        return result;
    }
    const onChangeTime = (val) => {

        if (!noTime) {
            if (moment(dateTime).format("DD-MM-YYYY") === moment(new Date()).format("DD-MM-YYYY")) {
                if (val >= moment(new Date()).format("HH:mm")) {
                    setSelectedTime(val)
                    getDateTime(dateTime, val)
                    setAnchorEl(null)
                }
                else {
                    if (!hideCondition) {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Please Choose Time Greater than or equal to current time"),
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                        });
                    }
                }
            }
            else {
                setSelectedTime(val)
                getDateTime(dateTime, val)
                setAnchorEl(null)
            }
        }
        // const element=document.getElementById(val)
        // element.scrollIntoView()
    }
    const getDateTime = (seldate, selTime) => {
        if (hideCondition) {
            if (moment(seldate).format("YY-MM-DD") <= moment(new Date()).format("YY-MM-DD")) {
                const date = moment(seldate).format("YYYY-MM-DD")
                const time = selTime
                const chosenDateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss')
                handleDateChange(chosenDateTime)
                if (noTime) {
                    setAnchorEl(false)
                }
            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please Choose Date Lower than or equal to current date"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            }
        }
        else if ((!hideCondition || !noTime) && !inspection) {

            const date = moment(seldate).format("YYYY-MM-DD")
            const time = selTime
            const chosenDateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss')
            handleDateChange(new Date(chosenDateTime))
            if (noTime) {
                setAnchorEl(false)
            }
        }
        else {
            if (moment(seldate).format("YY-MM-DD") >= moment(new Date()).format("YY-MM-DD")) {
                const date = moment(seldate).format("YYYY-MM-DD")
                const time = selTime
                const chosenDateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss')
                handleDateChange(new Date(chosenDateTime))
                if (noTime) {
                    setAnchorEl(false)
                }
            }
            else {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Please Choose Date Greater than or equal to current date"),
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                });
            }
        }
    }
    React.useEffect(() => {
        const monthStart = startOfMonth(month);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        let finaldays = dateRange(startDate, endDate)
        setFinalDays(finaldays)
        const final = intervals(startOfDay(new Date()), endOfDay(new Date()))
        setTime(final)
        // eslint-disable-next-line
    }, [])
    return (
        <Box className={classes.calendarBox}>
            <Grid container spacing={2}>
                <Grid item xs={noTime ? 12 : 9.5}>
                    <Box>
                        <Header next={next} prev={prev} month={month} nextYear={nextYear} prevYear={prevYear} />
                        <Body finaldays={finalDays} month={month} getDateTime={getDateTime} dateTime={dateTime} time={time} selectedTime={selectedTime} />
                    </Box>
                </Grid>
                {!noTime && <Grid item xs={2.5}>
                    <TimePicker time={time} onChangeTime={onChangeTime} selectedTime={selectedTime} dateTime={dateTime} />
                </Grid>}
            </Grid>
        </Box>
    )
}

// Time
const TimePicker = ({ time = [], onChangeTime = () => false, selectedTime = "" }) => {
    const classes = CalendarStyle()

    React.useEffect(() => {
        setTimeout(() => {
            executeScroll()
        }, 500)
        //eslint-disable-next-line
    }, [])

    const executeScroll = () => {
        const section = document.getElementById(selectedTime);
        if (section) {
            section?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: "center" });
        }
    }

    return (
        <>
            <Box mt={1} textAlign="center" className={classes.headerMonth}>Time</Box>
            <Stack spacing={1} className={classes.timeBox}>
                {time.length > 0 && time.map((x, index) => {
                    return (
                        <div id={x === selectedTime ? selectedTime : ""} className={x === selectedTime ? classes.selectedTime : ""} onClick={() => onChangeTime(x)} textAlign="center">
                            <Typography>{x}</Typography>
                        </div>
                    )
                })}
            </Stack>
        </>
    )
}
// Calendar header section 
const Header = ({ next = () => false, prev = () => false, month = {}, prevYear = () => false, nextYear = () => false }) => {
    const classes = CalendarStyle()
    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ direction: "ltr" }}>

            <IconButton className={classes.iconbutton} onClick={prevYear}>
                <KeyboardDoubleArrowLeft />
            </IconButton>
            <IconButton className={classes.iconbutton} onClick={prev}>
                <KeyboardArrowLeftIcon />
            </IconButton>
            <Typography className={classes.headerMonth}>{moment(month).format("MMMM YYYY")}</Typography>
            <IconButton className={classes.iconbutton} onClick={next}>
                <KeyboardArrowRightIcon />
            </IconButton>
            <IconButton className={classes.iconbutton} onClick={nextYear}>
                <KeyboardDoubleArrowRightIcon />
            </IconButton>
        </Stack>
    )
}

// Calendar body section

const Body = ({ finaldays = {}, month = {}, getDateTime = () => false, selected_date = "", dateTime = "", selectedTime = "" }) => {
    const classes = CalendarStyle()
    const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
    return (
        <Box pl={1} pt={1}>
            <Grid container>
                {
                    days.map((day) => {
                        return (
                            <Grid item xs={1.7} textAlign={"center"} className={classes.day} >
                                <Box><span>{day}</span></Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container>
                {
                    finaldays.map(e => {
                        return (
                            <Grid item md={1.7} className={moment(e).format("M") === moment(month).format("M") ? classes.date_curr_month : classes.date_not_curr_month} textAlign={"center"}
                                onClick={() => getDateTime(e, selectedTime)}
                            >
                                <Stack className={((moment(e).format("DD MM YY") === moment(dateTime).format("DD MM YY") && (moment(e).format("M YY") === moment(month).format("M YY")))) && classes.today} p={1}>
                                    <Box mt={0.5}><span className={moment(e).format("M") !== moment(month).format("M") && classes.date_hidden} >{moment(e).format("DD")}</span></Box>
                                </Stack>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}
export default withNamespaces("calendarWithTime")(CalendarWithTime)

