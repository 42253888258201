export const PricingTable = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98430">
            <g data-name="list (1)" fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 94909"
                    d="M18.645 15.016a2.661 2.661 0 1 0 2.661 2.661 2.66 2.66 0 0 0-2.661-2.661Zm0 2.419a.917.917 0 0 1 .242 1.8v.133a.242.242 0 0 1-.484 0v-.133a.917.917 0 0 1-.677-.883.242.242 0 0 1 .484 0 .435.435 0 1 0 .435-.435.917.917 0 0 1-.242-1.8v-.133a.242.242 0 1 1 .484 0v.132a.917.917 0 0 1 .677.883.242.242 0 1 1-.484 0 .435.435 0 1 0-.435.435Z"
                />
                <path
                    data-name="Path 94910"
                    d="M18.162 20.781v.283a.967.967 0 0 1-.968.968H9.452v.484a.485.485 0 0 0 .484.484h8.71a.485.485 0 0 0 .484-.484v-1.735a2.867 2.867 0 0 1-.968 0Z"
                />
                <path
                    data-name="Path 94911"
                    d="M19.129 14.574V9.936a.485.485 0 0 0-.484-.484h-.484v5.122a2.867 2.867 0 0 1 .968 0Z"
                />
                <path
                    data-name="Path 94912"
                    d="M9.763 11.075a.238.238 0 0 0 .172.07.248.248 0 0 0 .172-.07l.726-.726a.241.241 0 0 0-.341-.341l-.554.554-.07-.07a.241.241 0 0 0-.341.341Z"
                />
                <path
                    data-name="Path 94913"
                    d="M9.763 14.704a.238.238 0 0 0 .172.07.248.248 0 0 0 .172-.07l.726-.726a.241.241 0 0 0-.341-.341l-.554.554-.07-.07a.241.241 0 1 0-.341.341Z"
                />
                <path
                    data-name="Path 94914"
                    d="M9.763 18.333a.238.238 0 0 0 .172.07.248.248 0 0 0 .172-.07l.726-.726a.241.241 0 0 0-.341-.341l-.554.554-.07-.07a.241.241 0 1 0-.341.341Z"
                />
                <path
                    data-name="Path 94915"
                    d="M17.677 21.065v-.4a3.142 3.142 0 0 1 0-5.981v-6.2A.485.485 0 0 0 17.194 8h-8.71A.485.485 0 0 0 8 8.484v12.581a.485.485 0 0 0 .484.484h8.71a.485.485 0 0 0 .483-.484Zm-5.322-10.646h4.355a.242.242 0 1 1 0 .484h-4.355a.242.242 0 0 1 0-.484Zm0 .968h2.9a.242.242 0 1 1 0 .484h-2.9a.242.242 0 1 1 0-.484Zm0 2.661h4.355a.242.242 0 1 1 0 .484h-4.355a.242.242 0 1 1 0-.484Zm-.968 5.081H8.968V16.71h2.419Zm0-3.629H8.968v-2.419h2.419Zm0-3.629H8.968V9.452h2.419Zm2.661 7.258h-1.693a.242.242 0 0 1 0-.484h1.694a.242.242 0 0 1 0 .484Zm.726-.968h-2.419a.242.242 0 1 1 0-.484h2.419a.242.242 0 1 1 0 .484Zm.484-2.661h-2.9a.242.242 0 0 1 0-.484h2.9a.242.242 0 1 1 0 .484Z"
                />
            </g>
            <path data-name="Rectangle 52131" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
