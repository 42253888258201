import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    cardblock: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
        cursor: 'pointer'
    },
    selcardblock: {
        border: "1px solid #5078E1",
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        marginBottom: "10px",
        display: "flex",
        cursor: 'pointer',
        justifyContent: "space-between",
        backgroundColor: "#F1F7FF"
    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
    },
    location: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
    },
    address: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Regular,
    },
    checkIcon: {
        marginTop: "6px",
        color: "#5078E1",
        cursor: "pointer"
    },
    unitblock: {
        borderRadius: theme.palette.borderRadius,
        padding: "12px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between"
    },
    templateIcon: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F9E0E0",
        width: "40px",
        height: "40px",
        textAlign: "center",
        paddingTop: "3px"
    },
    listText: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    listsubText: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
    },
    inspectionName: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
        marginTop: "3px"
    },
    divStyle: {
        border: "1px solid #E4E8EE"
    },
    previewHdr: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold,
        textTransform: "uppercase"
    },
    previousbtn: {
        backgroundColor: "white",
        border: "1px solid #E4E8EE",
        color: theme.typography.color.primary,
        "&:hover": {
            backgroundColor: "white",
            border: "1px solid #E4E8EE",
        }
    },
    reqCardBlock: {
        backgroundColor: theme.palette.background.tertiary1,
        border: `1px solid ${theme.palette.border.secondary}`,
        // boxShadow: "0px 0px 16px #00000014",
        borderRadius: theme.palette.borderRadius,
        padding: "8px 12px 8px 12px"
    },
    title: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: Bold,
        // letterSpacing: "0.24px",
        textTransform: "capitalize"
    },
    viewMore: {
        color: theme.typography.status.primary,
        fontSize:"0.75rem",
        fontFamily: Bold,
        textTransform: "capitalize",
        // letterSpacing: "0.24px",
        cursor: "pointer"
    },
    Name: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
    },
    subTitle: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: Regular,
    },
    description: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: Regular,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        // overflow: "hidden",
        // width: "90%",
    },
    status: {
        backgroundColor: theme.palette.warning.light,
        borderRadius: theme.palette.borderRadius,
        border: `1px solid ${theme.palette.border.tertiary}`,
        color: theme.typography.status.secondary,
        fontSize:"0.75rem",
        fontFamily: Bold,
        padding: "2px 8px",
        textAlign: "center",
        marginTop: "-6px",
        textTransform: "capitalize"
    },
    Reassign: {
        textTransform: "upperCase",
        color: theme.typography.status.primary,
        fontSize:"0.75rem",
        fontFamily: Bold,
        cursor: "pointer"
    },
    closeIcon: {
        cursor: "pointer",
    },
    iconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: theme.palette.border.secondary,
        padding: "8px",
        borderRadius: "50%",
        height: "24px",
        width: "24px",
        cursor: "pointer"
    },
    icon: {
        fontSize:"0.875rem",
        marginLeft: "-2px",
    },
    resimg: {
        borderRadius: theme.palette.borderRadius,
    },
    hdrtitle: {
        fontSize:"1rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    hdrdescription: {
        fontSize:"0.875rem",
        fontFamily: Regular,
        color: theme.typography.color.tertiary
    },
    cmpbox: {
        border: "1px solid #E4E8EE",
        borderRadius: "10px",
        padding: "10px",
    },
    cmpblock: {
        backgroundColor: "#FFFFFF"
    },
    projectedTime: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F5F7FA",
        padding: "12px"
    },
    actualworkinghr: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#B2060614",
        padding: "10px"
    },
    siteText: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Bold,
        textTransform: "capitalize"
    },
    resinspection: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: '#EEF9EE',
        borderRadius: theme.palette.borderRadius
    },
    completedDateTime: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: '#EEF9EE',
        borderRadius: "14px",
        padding: "2px 10px",
        width: "fit-content"
    },
    insdateTime: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: "#568656"
    },
    tracksection: {
        borderColor: theme.palette.border.secondary,
        borderLeftStyle: "solid",
        borderLeftWidth: "1px",
    },
    anchorBottom: {
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
    },
    menuList: {
        "& .MuiMenu-list": {
            paddingLeft: "6px !important",
            paddingRight: "6px !important"
        }
    },
    checkIconcircle: {
        backgroundColor: "#5078E1",
        borderRadius: "50%",
        padding: "3px",
        width: "25px",
        height: "25px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center"
    },
}))