import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { stringAvatar } from '../../../utils'
import { ProfileCardStyle } from './style'
export const ProfileCard = ({ data = {} }) => {
    const classes = ProfileCardStyle()
    return (
        <Box>
            <Stack direction={"row"} alignItems={"center"}>
                <Avatar src={data?.Created_by?.image_url} {...stringAvatar(data?.Created_by?.first_name)}></Avatar>
                <Stack>
                    <Stack sx={{ marginInlineStart: "8px" }}>
                        <Typography className={classes.name}> {data?.Created_by?.first_name} </Typography>

                        <Stack direction={"row"} alignItems={"center"}>

                            <Typography className={classes.mobno_email}>{data?.Created_by?.mobile_no_country_code} {data?.Created_by?.mobile_no}</Typography>
                            <Box className={classes.dot}></Box>
                            <Typography className={classes.mobno_email}>{data?.Created_by?.email_id}</Typography>
                        </Stack>
                    </Stack>
                </Stack >
            </Stack >
        </Box >
    )
}