import React from "react";
import { withNavBars } from "../../../HOCs";
import { AmenitiesMappingUnitView } from "./amenitiesMappingUnitView";

class AmenitiesMappingUnitViewParent extends React.Component {
    render() {
        return <AmenitiesMappingUnitView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AmenitiesMappingUnitViewParent, props);
