import { AnnounsmentPerson, AnnounsmentDetails } from "../../assets";

export const tabTittle = (value, classes) => [
  {
    label: "Property & Roles",
    imgSelect:
      value === 1 ? (
        <img src="/images/correct_icon.svg" alt="icon" />
      ) : (
        <AnnounsmentPerson color={value === 0 ? "#5078E1" : "#98A0AC"} />
      ),
    className: value === 0 ? classes.tabtitle1 : classes.tabtitle,
    selectBack: value === 0 ? classes.selectBack1 : classes.selectBack,
    subtabtitle: value === 0 ? classes.subtabtitle1 : classes.subtabtitle,
  },
  {
    label: "Announcement Details",

    imgSelect: (
      <AnnounsmentDetails color={value === 1 ? "#5078E1" : "#98A0AC"} />
    ),
    className: value === 1 ? classes.tabtitle1 : classes.tabtitle,
    selectBack: value === 1 ? classes.selectBack1 : classes.selectBack,
    subtabtitle: value === 1 ? classes.subtabtitle1 : classes.subtabtitle,
  },
];


export function formatSizeUnits(bytes){
  if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
  else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
  else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
  else if (bytes > 1)           { bytes = bytes + " bytes"; }
  else if (bytes === 1)          { bytes = bytes + " byte"; }
  else                          { bytes = "0 bytes"; }
  return bytes;
}

export const datajson = Array.from(Array(10).keys())?.map((v, i) => {
  return {
    title: "Property Name..",
    subTitle: "Location Here",
    is_active: false,
  };
});

export const datajsonRole = Array.from(Array(10).keys())?.map((v, i) => {
  return {
    title: "Property Name..",
    subTitle: "Location Here",
    is_active: false,
  };
});