import React from "react";
import { withNavBars } from "../../HOCs";
import CreateAccount from "./createAccount";

class CreateAccountParent extends React.Component {
    render() {
        return <CreateAccount reltype="AllAccount" />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CreateAccountParent, props);
