import React from "react";

export const Dollar = () => (
    <svg id="Group_98671" data-name="Group 98671" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="Group_95678" data-name="Group 95678">
            <rect id="Rectangle_49614" data-name="Rectangle 49614" width="40" height="40" rx="20" fill="#bdccf2" />
        </g>
        <path id="Path_100139" data-name="Path 100139" d="M9.845,1.178a3.261,3.261,0,0,1-1,2.408A4.619,4.619,0,0,1,6.2,4.821V6.776H4.389V4.856a7.976,7.976,0,0,1-2.195-.462,5.938,5.938,0,0,1-1.75-1l.764-1.653a6.755,6.755,0,0,0,1.839,1,6.179,6.179,0,0,0,2.017.32A3.173,3.173,0,0,0,6.921,2.6,1.536,1.536,0,0,0,7.57,1.267,1.071,1.071,0,0,0,7.215.431a2.6,2.6,0,0,0-.88-.506Q5.811-.262,4.851-.51a15.985,15.985,0,0,1-2.186-.684A3.723,3.723,0,0,1,1.226-2.261a2.9,2.9,0,0,1-.6-1.928,3.278,3.278,0,0,1,1.04-2.435A4.755,4.755,0,0,1,4.354-7.885V-9.84H6.166V-7.9a7.505,7.505,0,0,1,1.928.5,5.072,5.072,0,0,1,1.519.924L8.85-4.829a5.625,5.625,0,0,0-3.536-1.3,2.881,2.881,0,0,0-1.75.48,1.564,1.564,0,0,0-.649,1.333,1.269,1.269,0,0,0,.373.951,2.655,2.655,0,0,0,.951.578,13.9,13.9,0,0,0,1.555.444A15.777,15.777,0,0,1,7.9-1.692,3.431,3.431,0,0,1,9.276-.67,2.824,2.824,0,0,1,9.845,1.178Z" transform="translate(14.855 21.532)" fill="#4a6fcc" />
    </svg>

)