import * as React from "react"
export const AgreementDetail = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 97922" transform="translate(-345 -314.97)">
      <rect
        width={40}
        height={40}
        fill="#f1f7ff"
        data-name="NoPath - Copy (7)"
        rx={4}
        transform="translate(345 314.97)"
      />
      <path
        fill="#78b1fe"
        d="M359.923 325.97a1.925 1.925 0 0 0-1.923 1.923v13.246a1.925 1.925 0 0 0 1.923 1.923h5.612a5.563 5.563 0 0 1-.922-1.282h-4.69a.642.642 0 0 1-.641-.641v-13.246a.642.642 0 0 1 .641-.641h4.914v3.632a1.925 1.925 0 0 0 1.923 1.923h3.632v.927a5.523 5.523 0 0 1 1.282.354v-1.918a.638.638 0 0 0-.188-.452l-5.55-5.55a.639.639 0 0 0-.458-.198Zm6.2 2.188 3.366 3.366h-2.729a.642.642 0 0 1-.641-.641Zm2.167 6.576a.782.782 0 0 0-.848.575l-.141.527a.782.782 0 0 1-.688.578l-.543.047a.782.782 0 0 0-.641 1.11l.231.494a.782.782 0 0 1-.156.884l-.386.386a.782.782 0 0 0 .222 1.262l.494.23a.782.782 0 0 1 .449.777l-.048.543a.782.782 0 0 0 .981.824l.527-.141a.783.783 0 0 1 .844.307l.312.447a.782.782 0 0 0 1.282 0l.312-.447a.783.783 0 0 1 .844-.307l.527.142a.782.782 0 0 0 .981-.824l-.048-.543a.784.784 0 0 1 .449-.778l.494-.23a.783.783 0 0 0 .223-1.263l-.386-.385a.784.784 0 0 1-.156-.885l.231-.493a.782.782 0 0 0-.641-1.11l-.542-.048a.782.782 0 0 1-.689-.577l-.141-.527a.781.781 0 0 0-1.2-.438l-.446.312a.782.782 0 0 1-.9 0l-.446-.313a.775.775 0 0 0-.361-.135Z"
      />
    </g>
  </svg>
)
