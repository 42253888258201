export const listing_tab_data = {
    property_listing: {
        value: "property_listing",
        label: "Property Listing",
        add_button_text: "Add Property"
    },
    public_lisiting: {
        value: "public_lisiting",
        label: "Public Listing",
        add_button_text: "Add Unit",
        payload_key_value: {
            is_key: "is_public",
            size_type_value: "public_listing_size"
        },
    },
    private_listing: {
        value: "private_listing",
        label: "Private Listing",
        add_button_text: "Add Unit",
        payload_key_value: {
            is_key: "is_private",
            size_type_value: "private_listing_size"
        },
    },
    public_facility_listing: {
        value: "public_facility_listing",
        label: "Public Facility Listing",
        add_button_text: "Add Facility",
        payload_key_value: {
            is_key: "is_public",
            size_type_value: "public_facility_size"
        },
    },
    private_facility_listing: {
        value: "private_facility_listing",
        label: "Private Facility Listing",
        add_button_text: "Add Facility",
        payload_key_value: {
            is_key: "is_private",
            size_type_value: "private_facility_size"
        },
    },
    revoked: {
        value: "revoked",
        label: "Revoked"
    },
}

export const listing_tab_paths = [
    listing_tab_data.property_listing.value,
    listing_tab_data.public_lisiting.value,
    listing_tab_data.private_listing.value,
    listing_tab_data.public_facility_listing.value,
    listing_tab_data.private_facility_listing.value,
    listing_tab_data.revoked.value,
]

export const revoked_tabs_data = {
    unit_revoked_list: {
        value: "unit_revoked_list",
        label: "Unit Revoked List"
    },
    facility_revoked_list: {
        value: "facility_revoked_list",
        label: "Facility Revoked List"
    },
}

export const revoked_tabs_path = [
    revoked_tabs_data.unit_revoked_list.value,
    revoked_tabs_data.facility_revoked_list.value,
]