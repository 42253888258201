import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CartItemCard, FormGenerator, GeneralCard, NoInspectionImage, SearchFilter
} from "../../../components";
import { config } from '../../../config';
import { AlertContext } from "../../../contexts";
import { NetworkCall } from '../../../networkcall';
import { AlertProps, NetWorkCallMethods } from '../../../utils';
import { useStyles } from "./style";


const AddBulkItem = ({ t, details, reload = () => false, editData = "", edit = "", buttonName = "", setEdit = false, setNewBulk = false, setpropertyItems = false, company,setEditData=()=>false }) => {
  const initialState = {
    name: "",
    error: {

      name: "",
    }
  }
  const classes = useStyles();
  const [inspectionItems, setInspectionItems] = React.useState([]);
  const [selectedInspectionItem, setSelectedInspectionItem] = React.useState([])
  const [data, setData] = React.useState({ ...initialState })
  const [search, setSearch] = React.useState("");
  const [offset, setOffset] = React.useState(0);
  const [isDisableBtn, setIsDisableBtn] = React.useState(false)
  const alert = React.useContext(AlertContext);
  //get inspection items
  const getInspectionItems = (search, offset = 0, showMore = false, isSearch = false) => {
    const payload = {
      company_id: company,
      search: search,
      offset: offset,
      limit: 10,
    }
    NetworkCall(
      `${config.api_url}/inspection_items/getAll`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (edit) {
          const data = response.data.data?.map((val) => {
            let item_count = editData?.inspection_template_items?.filter((s => s?.inspection_item === val?.id))?.[0]?.item_count
            let template_id = editData?.inspection_template_items?.filter((s => s?.inspection_item === val?.id))?.[0]?.id

            return {
              id:val?.id,
              Name: val?.name,
              image: "/images/Group 98362.svg",
              count: item_count ?? 0,
              is_active: item_count > 0 ? true : false,
              name: val?.name,
              open: item_count > 0 ? true : false,
              template_id: template_id
            }
          })

          if (!isSearch) {
            let result = inspectionItems.concat(data)?.map((x) => {
              let data = selectedInspectionItem?.filter((val) => { return x?.id === val.id && val?.is_active})
              if (data?.length > 0) {
                return data?.[0]
              }
              else {
                return x
              }

            })
            setInspectionItems(showMore ? result : data)
          }
          else {
            // let result = data?.map((x) => {
            //   let selectedItem = selectedInspectionItem?.filter((val) => { return x?.id === val.id })
            //   if (selectedItem?.length > 0) {
            //     return selectedItem?.[0]
            //   }
            //   else {
            //     return x
            //   }

            // })
            // setInspectionItems(result)
            const tempdata = response.data.data?.map((val) => {
            let template_id = editData?.inspection_template_items?.filter((s => s?.inspection_item === val?.id))?.[0]?.id

              return {
                id: val?.id,
                Name: val?.name,
                image: "/images/Group 98362.svg",
                count: 0,
                is_active: false,
                name: val?.name,
                template_id: template_id
              }
            })
            let result = tempdata?.map((x) => {
              let selectedItem = selectedInspectionItem?.filter((val) => { return x?.id === val.id&&val?.is_active})
              if (selectedItem?.length > 0) {
                return selectedItem?.[0]
              }
              else {
                return x
              }

            })
            setInspectionItems(result)
          }

          // if (reload) {
          //   setInspectionItems(reload ? data : inspectionItems.concat(data));
          // }
          // else {
          //   setInspectionItems(reload ? data : inspectionItems.concat(data));
          // }


        }
        else {
          const data = response.data.data?.map((val) => {
            return {
              id: val?.id,
              Name: val?.name,
              image: "/images/Group 98362.svg",
              count: 0,
              is_active: false,
              name: val?.name
            }
          })
          if (!isSearch) {
            let result = inspectionItems.concat(data)?.map((x) => {
              let data = selectedInspectionItem?.filter((val) => { return x?.id === val.id })
              if (data?.length > 0) {
                return data?.[0]
              }
              else {
                return x
              }

            })
            setInspectionItems(showMore ? result : data)
          }
          else {
            let result = data?.map((x) => {
              let selectedItem = selectedInspectionItem?.filter((val) => { return x?.id === val.id })
              if (selectedItem?.length > 0) {
                return selectedItem?.[0]
              }
              else {
                return x
              }

            })
            setInspectionItems(result)
          }
        }


        // setLoading(false)

      }).catch((err) => {


      })

  }
  //validateForm
  const validateForm = () => {
    let isValid = true;
    let error = data.error;
    if (data?.name === undefined || data?.name?.length === 0) {
      isValid = false;
      error.name = `${t("templateName")} ${t("isRequired")}`;
    }
    setData({ ...data, error });
    return isValid;
  };
  //more
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getInspectionItems(search, offset + 10, true, false);
  }
  //initial load
  React.useEffect(() => {
    getInspectionItems("", 0, false, false)
    setData({ ...data, name: editData.name })
    if (edit) {
      const selectedData = editData?.inspection_template_items?.map((val) => {
        return {
          id: val?.inspection_item,
          Name: val?.name,
          image: "/images/Group 98362.svg",
          count: val?.item_count ?? 0,
          is_active: val?.is_active,
          name: val?.name,
          open: val?.item_count > 0 ? true : false,
          template_id: val?.id,
        }
      })
      setSelectedInspectionItem(selectedData)
    }
    // eslint-disable-next-line
  }, [])
  //onclick inspection item
  const changeInspectionItems = (data, bool) => {
    const edited = inspectionItems.map((val) =>
      val.id === data?.id
        ? {
          ...val,
          is_active: bool,
          open: bool ? true : false,
          count: bool ? 1 : 0
        }
        : val
    );
    const selectedData = {
      ...data,
      is_active: bool,
      open: bool ? true : false,
      count: bool ? 1 : 0
    }
    setInspectionItems(edited)
    setSelectedInspectionItem([...selectedInspectionItem, selectedData])
  }
  // remove Item 
  const removeInspectionItems = (data, bool) => {
    const edited = inspectionItems.map((val) =>
      val.id === data?.id
        ? {
          ...val,
          is_active: bool,
          open: bool ? true : false,
          count: bool ? 1 : 0
        }
        : val
    );
    setInspectionItems(edited)
    const result = selectedInspectionItem.map((val) =>
      val.id === data?.id
        ? {
          ...val,
          is_active: bool,
          open: bool ? true : false,
          count: bool ? 1 : 0
        }
        : val
    );
    setSelectedInspectionItem(result)
  }
  //handle state update
  const updateState = (k, v) => {
    let error = data.error;
    error[k] = "";
    setData({ ...data, [k]: v, error })

  }
  //add inspectionitem
  const addInspectionItem = (data, type) => {

    const edited = inspectionItems.map((val) => {
      let count = type ? val?.count + 1 : val?.count - 1;

      let selected = val.id === data?.id
        ? {
          ...val,
          count: count > 0 ? count : 0,
          is_active: count > 0 ? true : false,
          open: count > 0 ? true : false,
        }
        : val

      return selected;
    });
    const selectedItems = selectedInspectionItem?.filter((x) => { return x?.is_active })?.map((val) => {
      let count = type ? val?.count + 1 : val?.count - 1;
      let selected = val.id === data?.id
        ? {
          ...val,
          count: count > 0 ? count : 0,
          is_active: count > 0 ? true : false,
          open: count > 0 ? true : false,
        }
        : val
      return selected;
    })
    setSelectedInspectionItem(selectedItems)
    setInspectionItems(edited)

  }
  //Form submit
  const onSubmit = () => {
    if (validateForm()) {
      setIsDisableBtn(true)
      const inspection_items = selectedInspectionItem.filter((val) => val?.is_active).map((val) => {
        return {
          id: val?.template_id ?? undefined,
          name: val?.name,
          inspection_item: val?.id,
          item_count: val?.count
        }
      });
      const deleted_inspection_items = selectedInspectionItem.filter((val) => !val?.is_active).filter((item) => item.template_id).map((val) => {
        return {
          id: val?.template_id ?? undefined,
          name: val?.name,
          inspection_item: val?.id,
          item_count: val?.count,
          is_active: false
        }
      });
      const payload = {
        id: editData?.id ?? undefined,
        name: data?.name,
        property_id: details?.id,
        inspection_template_items: [...inspection_items, ...deleted_inspection_items],
        is_active: true
      }
      setIsDisableBtn(true)
      NetworkCall(
        `${config.api_url}/inspection_template/upsert`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          setIsDisableBtn(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `${t("Item Successfully")} ${edit === true ? t("Updated") : t("Created")}`,
          });
          setEdit(false)
          setNewBulk(false)
          setpropertyItems(false)
          reload()
          setIsDisableBtn(false)
        }).catch((err) => {
          setIsDisableBtn(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: `${t("Something Went Wrong")}`,
          });
          setIsDisableBtn(false)
        })
    }
    else {
      return false
    }
  }
  //delete all
  const deleteAll = () => {
    getInspectionItems("", 0, false, false)
    setSelectedInspectionItem([])
  }
  const changeInput = [
    {
      size: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12
      },
      component: "text",
      isActive: true,
      label: t("templateName"),
      placeholder: t("enterTemplateName"),
      value: data?.name ?? editData?.name,
      onChange: (e) => updateState("name", e.target.value),
      error: data?.error?.name,
      isRequired: true,
    },

  ]
  return (
    <Box>
      <Grid container style={{ width: "100%" }}>
        <Grid item xs={6} className={classes.abGriditem}>
          <Box className={classes.abAddComp} >
            <Box p={2}>
              <FormGenerator t={t} components={changeInput} />
              <Box height="16px" />
              <Divider />
              <Box height="16px" />
              <SearchFilter value={search}
                handleChange={(val) => {
                  setSearch(val)
                  setOffset(0)
                  getInspectionItems(val, 0, false, true)
                }}
                placeholder={t("searchInspectionItem")} />
            </Box>
            <InfiniteScroll
              dataLength={inspectionItems?.length ?? ""}
              next={fetchMoreData}
              hasMore={true}
              height={350}
            >
              <Box className={classes.abAddCompList} p={2} marginTop="-10px">
                {inspectionItems.map((val) => {
                  return (
                    <Box >
                      <GeneralCard
                        list={val}
                        handleChange={() => changeInspectionItems(val, true)}
                        increaseQty={() => addInspectionItem(val, true)}
                        decreaseQty={() => addInspectionItem(val, false)}
                        component={"increment"}
                        buttonText={t("Add")}
                      />
                    </Box>
                  );
                })}
              </Box>
            </InfiniteScroll>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box p={1}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
              <Typography className={classes.abPreviewTitle}>

                {(data?.name || editData?.name) ?
                  `${data?.name ?? editData?.name} - ${t("Preview")}`
                  :
                  t("Preview")
                }
              </Typography>
              {!edit && selectedInspectionItem?.filter((x) => { return x?.is_active })?.length > 0 &&
                <Typography
                  className={classes.abDeleteAll}
                  onClick={() => deleteAll()}
                >
                  {t("Delete All")}
                </Typography>
              }
            </Stack>

            {selectedInspectionItem?.filter((x) => { return x?.is_active })?.length > 0 ? (
              <Box>
                <Box className={classes.cartList}>
                  {selectedInspectionItem?.filter((x) => { return x?.is_active })?.map((val) => {
                    return (
                      <Box>
                        <CartItemCard
                          list={val}
                          increaseQty={() => addInspectionItem(val, true)}
                          decreaseQty={() => addInspectionItem(val, false)}
                          removeitem={() => removeInspectionItems(val, false)}
                        // count={val.count}
                        />
                      </Box>
                    );
                  })}
                </Box>
                <Box p={1}>
                  <Button className={classes.createBtn} variant="contained" onClick={onSubmit} disabled={isDisableBtn}>
                    {buttonName}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                <NoInspectionImage message={t("message")} note={t("note")} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default withNamespaces("inspection")(AddBulkItem)