import * as React from "react"

const MyProfile = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12.801} height={16} {...props}>
    <path
      data-name="icons8-user (1)"
      d="M6.4 0a4 4 0 1 0 4 4 4.009 4.009 0 0 0-4-4Zm0 1.2A2.8 2.8 0 1 1 3.6 4a2.791 2.791 0 0 1 2.8-2.8ZM1.591 9.6A1.6 1.6 0 0 0 0 11.191v.609a3.728 3.728 0 0 0 2.123 3.225A9 9 0 0 0 6.4 16a9 9 0 0 0 4.277-.975A3.866 3.866 0 0 0 12.7 12.4h.1v-1.209A1.6 1.6 0 0 0 11.209 9.6Zm0 1.2h9.617a.383.383 0 0 1 .392.391v.609a2.437 2.437 0 0 1-1.5 2.175 7.974 7.974 0 0 1-3.7.825 7.974 7.974 0 0 1-3.7-.825A2.437 2.437 0 0 1 1.2 11.8v-.609a.383.383 0 0 1 .391-.391Z"
      fill="#4e5a6b"
    />
  </svg>
)

export default MyProfile
