export const RoleBuilder = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g id="Announcement" transform="translate(24875 -2963)">
      <path
        id="Rectangle_52494"
        data-name="Rectangle 52494"
        transform="translate(-24875 2963)"
        style={{
          fill: "none",
        }}
        d="M0 0h30v30H0z"
      />
      <g
        id="Role_Builder"
        data-name="Role Builder"
        transform="translate(-24868 2970)"
      >
        <path
          id="Path_96776"
          data-name="Path 96776"
          className="cls-2"
          d="M15.531 5.656h-.9a7.023 7.023 0 0 0-.283-.687l.637-.637a.469.469 0 0 0 0-.663l-2.654-2.652a.469.469 0 0 0-.663 0l-.637.637a7.027 7.027 0 0 0-.687-.283v-.9A.469.469 0 0 0 9.875 0h-3.75a.469.469 0 0 0-.469.469v.9a7.021 7.021 0 0 0-.687.283l-.637-.637a.469.469 0 0 0-.663 0L1.017 3.669a.469.469 0 0 0 0 .663l.637.637a7.023 7.023 0 0 0-.283.687h-.9A.469.469 0 0 0 0 6.125v3.75a.469.469 0 0 0 .469.469h.9a7.021 7.021 0 0 0 .283.687l-.637.637a.469.469 0 0 0 0 .663l2.652 2.652a.469.469 0 0 0 .663 0l.637-.637a7.025 7.025 0 0 0 .687.283v.9a.469.469 0 0 0 .471.471h3.75a.469.469 0 0 0 .469-.469v-.9a7.01 7.01 0 0 0 .687-.283l.637.637a.469.469 0 0 0 .663 0l2.652-2.652a.469.469 0 0 0 0-.663l-.637-.637a7.024 7.024 0 0 0 .283-.687h.9A.469.469 0 0 0 16 9.875v-3.75a.469.469 0 0 0-.469-.469ZM8 12.219A4.219 4.219 0 1 1 12.219 8 4.224 4.224 0 0 1 8 12.219Z"
          style={{
            fill: props?.fill ?? "#c1c5cb",
          }}
        />
        <path
          id="Path_96777"
          data-name="Path 96777"
          className="cls-2"
          d="M175.515 254.42a2.5 2.5 0 0 1-3.718 0 2.029 2.029 0 0 0-.8 1.612v.625a.469.469 0 0 0 .469.469h4.375a.469.469 0 0 0 .469-.469v-.625a2.029 2.029 0 0 0-.795-1.612Z"
          transform="translate(-165.656 -246.469)"
          style={{
            fill: props?.fill ?? "#c1c5cb",
          }}
        />
        <circle
          id="Ellipse_129421"
          data-name="Ellipse 129421"
          className="cls-2"
          cx={1.563}
          cy={1.563}
          r={1.563}
          transform="translate(6.437 4.719)"
          style={{
            fill: props?.fill ?? "#c1c5cb",
          }}
        />
      </g>
    </g>
  </svg>
)
