import { Box, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import { AssetView } from '../resourceMaster/components/assetView'
import { ViewCourtStyle } from './style'
import ShowMoreText from "react-show-more-text";

export const ViewCourt = (
    {
        type = "",
        selectedAmenityView = {},
        t = () => false
    }) => {
    const classes = ViewCourtStyle()
    // const urls = selectedAmenityView?.amenity_assets?.map((e) => {
    //     return { url: e }
    // })
    return (
        <Stack spacing={2} p={"16px 24px 24px"}>
            <Box>
                <AssetView asset={selectedAmenityView?.facility_assets} />
            </Box>
            <Box m={2}>
                {/* <Stack spacing={2} p={2}>
                    <Stack justifyContent={"space-between"} direction="row" alignItems={"center"}>
                        <Stack>
                            <Stack spacing={1}>
                                <Typography className={classes.court_name}>{selectedAmenityView?.label}</Typography>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Typography className={classes.subname}>{selectedAmenityView?.category}</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={"row"} spacing={1} alignItems={'center'}>

                                <Typography className={classes.subTitle}>{selectedAmenityView?.amenity_category}</Typography>
                                <Box className={classes.dot}></Box>
                                <Typography className={classes.subTitle}>
                                    {

                                        `${selectedAmenityView?.period === "Hourly" ? selectedAmenityView?.slot_partition : 24} Hr/Slot`
                                    }
                                </Typography>
                                <Box className={classes.dot}></Box>
                                <Typography className={classes.subTitle}>{selectedAmenityView?.is_active ? 'Active' : "InActive"}</Typography>
                            </Stack>
                        </Stack>
                        <Box>
                            <Typography className={classes.content}>
                                {selectedAmenityView?.amount + " " + selectedAmenityView?.symbol + " / " + selectedAmenityView?.period}
                            </Typography>
                        </Box>
                    </Stack>
                    <Divider />
                    <Grid container>
                        <Grid item md={3} sm={3} lg={3}>
                            <Typography className={classes.title}>Available Days</Typography>
                        </Grid>
                        <Grid item md={9} sm={9} lg={9}>
                            <Stack direction={"row"} spacing={2} sx={{ float: "right" }}>
                                {selectedAmenityView?.applicable_days?.map((i) => {
                                    return <Typography className={classes.content}>{i}</Typography>

                                })}
                            </Stack>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container alignItems={"center"}>
                        <Grid item md={3} sm={3} lg={3}>
                            <Typography className={classes.title}>Slot Sessions</Typography>
                        </Grid>
                        <Grid item md={9} sm={9} lg={9} sx={{ float: "right" }}>
                            <Grid container spacing={1} sx={{ float: "right" }}>
                                {selectedAmenityView?.selected_slots?.map((item, index, array) => {
                                    return ((index !== array.length - 1) && <Grid item md={6}>
                                        <Typography className={classes.content} textAlign="right">
                                            {addSecondFormat(item?.[0], 0, "hh:mm A") + " - " + addSecondFormat(array?.[index + 1]?.[0], 0, "hh:mm A")}
                                        </Typography>
                                    </Grid>)
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack > */}
                < Grid container spacing={2} >
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Stack spacing={0.5}>
                            <Typography className={classes.title}>{t("Amenity Category")}</Typography>
                            <Typography className={classes.content}>{selectedAmenityView?.amenity_category}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Stack spacing={0.5}>
                            <Typography className={classes.title}>{t("Amenity Name")}</Typography>
                            <Typography className={classes.content}>{selectedAmenityView?.description}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Stack spacing={0.5}>
                            <Typography className={classes.title}>{t("Status")}</Typography>
                            <Typography className={classes.content}>{selectedAmenityView?.is_active ? 'Active' : "In-Active"}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Stack spacing={0.5}>
                            <Typography className={classes.title}>{t("Booking Required")}</Typography>
                            <Typography className={classes.content}>{selectedAmenityView?.is_booking ? "Yes" : "No"}</Typography>
                        </Stack>
                    </Grid>
                    {
                        selectedAmenityView?.amenity_description &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ShowMoreText
                                lines={3}
                                more="Show More"
                                less="Show Less"
                                className={classes.description}
                                anchorClass={classes.seeMoreLessTextStyle}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                            >
                                <Typography className={classes.description}>
                                    {selectedAmenityView?.amenity_description}
                                </Typography>
                            </ShowMoreText>
                        </Grid>
                    }
                </Grid >

            </Box >

        </Stack >
    )
}