export const AutoNumbering = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g
      id="Group_99676"
      data-name="Group 99676"
      transform="translate(-1502 510)"
    >
      <g
        id="Group_8127"
        data-name="Group 8127"
        transform="translate(-10 -1483)"
      >
        <g
          id="Group_99684"
          data-name="Group 99684"
          transform="translate(-4 -42)"
        >
          <path
            id="Rectangle_31"
            data-name="Rectangle 31"
            transform="translate(1516 1015)"
            style={{
              fill: "none",
            }}
            d="M0 0h30v30H0z"
          />
          <g
            id="auto_numbering"
            data-name="auto numbering"
            transform="translate(1524 1007.693)"
          >
            <g
              id="Group_99682"
              data-name="Group 99682"
              transform="translate(0 15.307)"
            >
              <path
                id="Path_95375"
                data-name="Path 95375"
                className="cls-2"
                d="M163.945 15.307h-9.234a.811.811 0 0 0-.811.811v2.162a.811.811 0 0 0 .811.811h9.234a.811.811 0 0 0 .811-.811v-2.162a.811.811 0 0 0-.811-.811Z"
                transform="translate(-149.756 -15.307)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
              />
              <path
                id="Path_95376"
                data-name="Path 95376"
                className="cls-2"
                d="M163.945 204.194h-9.234a.811.811 0 0 0-.811.811v2.162a.811.811 0 0 0 .811.811h9.234a.811.811 0 0 0 .811-.811V205a.811.811 0 0 0-.811-.806Z"
                transform="translate(-149.756 -199.107)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
              />
              <path
                id="Path_95377"
                data-name="Path 95377"
                className="cls-2"
                d="M163.945 401.167h-9.234a.811.811 0 0 0-.811.811v2.162a.811.811 0 0 0 .811.811h9.234a.811.811 0 0 0 .811-.811v-2.162a.811.811 0 0 0-.811-.811Z"
                transform="translate(-149.756 -390.775)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
              />
              <path
                id="Path_95378"
                data-name="Path 95378"
                className="cls-2"
                d="M14.577 21.647a2.331 2.331 0 0 0 .721-.385V23.5a.082.082 0 0 0 .082.082h.642a.082.082 0 0 0 .078-.082v-3.362a.082.082 0 0 0-.082-.082H15.5a.083.083 0 0 0-.078.055 1.109 1.109 0 0 1-.377.5 1.781 1.781 0 0 1-.521.3.082.082 0 0 0-.057.078v.583a.082.082 0 0 0 .108.078Z"
                transform="translate(-14.079 -19.928)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
              />
              <path
                id="Path_95379"
                data-name="Path 95379"
                className="cls-2"
                d="M.967 210.883a4.3 4.3 0 0 0-.736.821 1.64 1.64 0 0 0-.231.674.082.082 0 0 0 .021.064.083.083 0 0 0 .061.027h2.25a.082.082 0 0 0 .082-.082v-.6a.082.082 0 0 0-.082-.082H1.216l.035-.041c.052-.059.18-.181.379-.364a4.244 4.244 0 0 0 .429-.437 1.848 1.848 0 0 0 .269-.449 1.244 1.244 0 0 0 .087-.461.959.959 0 0 0-.309-.725 1.173 1.173 0 0 0-.835-.29 1.29 1.29 0 0 0-.8.248 1.132 1.132 0 0 0-.391.817.082.082 0 0 0 .074.091l.64.064a.084.084 0 0 0 .061-.019.083.083 0 0 0 .029-.058.552.552 0 0 1 .113-.35.346.346 0 0 1 .261-.1.349.349 0 0 1 .26.091.366.366 0 0 1 .091.272.627.627 0 0 1-.118.346 4.517 4.517 0 0 1-.524.543Z"
                transform="translate(0 -203.727)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
              />
              <path
                id="Path_95380"
                data-name="Path 95380"
                className="cls-2"
                d="M2.509 408.1a1.089 1.089 0 0 0 .347-.811.886.886 0 0 0-.191-.57.838.838 0 0 0-.33-.25.812.812 0 0 0 .37-.679.87.87 0 0 0-.258-.614 1.079 1.079 0 0 0-.818-.327 1.251 1.251 0 0 0-.534.112.924.924 0 0 0-.38.314 1.412 1.412 0 0 0-.2.516.083.083 0 0 0 .013.063.081.081 0 0 0 .054.035l.592.1a.085.085 0 0 0 .063-.015.083.083 0 0 0 .032-.057.461.461 0 0 1 .117-.284.305.305 0 0 1 .223-.091.28.28 0 0 1 .209.076.283.283 0 0 1 .076.211.321.321 0 0 1-.109.257.468.468 0 0 1-.315.093h-.026a.082.082 0 0 0-.082.071l-.071.523a.082.082 0 0 0 .1.09c.252-.07.406-.064.529.07a.463.463 0 0 1 .108.328.5.5 0 0 1-.115.351.351.351 0 0 1-.273.124.356.356 0 0 1-.258-.1.512.512 0 0 1-.135-.314.083.083 0 0 0-.092-.072l-.621.075a.083.083 0 0 0-.072.091 1.114 1.114 0 0 0 .375.737 1.189 1.189 0 0 0 .808.282 1.194 1.194 0 0 0 .864-.335Z"
                transform="translate(-.454 -394.359)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
