import React from "react"

export const AssetPMP = (props) =>{
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={26}
        {...props}
      >
        <defs>
          <linearGradient
            id="a"
            x1={0.5}
            x2={0.5}
            y1={-0.098}
            y2={1.244}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#28afea" />
            <stop offset={1} stopColor="#0b88da" />
          </linearGradient>
          <linearGradient xlinkHref="#a" id="c" y1={0.009} y2={2.435} />
          <linearGradient
            id="b"
            x1={0.004}
            x2={1.005}
            y1={0.498}
            y2={0.498}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#3079d6" />
            <stop offset={1} stopColor="#297cd2" />
          </linearGradient>
          <linearGradient xlinkHref="#b" id="d" x1={0} x2={1} y1={0.5} y2={0.5} />
        </defs>
        <g data-name="Group 118073">
          <g data-name="Group 118072" transform="translate(-120 -299)">
            <g transform="translate(114 294.333)">
              <path
                fill="url(#a)"
                d="M23.333 36h-16A1.337 1.337 0 0 1 6 34.667V22h18.667v12.667A1.337 1.337 0 0 1 23.333 36Z"
                data-name="Path 101885"
                transform="translate(0 -5.667)"
              />
              <path
                fill="url(#c)"
                d="M24.667 15H6V6.333A1.337 1.337 0 0 1 7.333 5h16a1.337 1.337 0 0 1 1.333 1.333Z"
                data-name="Path 101886"
              />
              <path
                fill="#0078d4"
                d="M6 15h18.667v1.333H6Z"
                data-name="Path 101887"
              />
              <path
                d="M20 17.667a1.964 1.964 0 0 0-2 2V29a1.861 1.861 0 0 0 .533 1.333h4.8A1.337 1.337 0 0 0 24.666 29V17.667Z"
                data-name="Path 101888"
                opacity={0.05}
              />
              <path
                d="M20 18a1.65 1.65 0 0 0-1.667 1.667V29A1.74 1.74 0 0 0 19 30.333h4.333A1.337 1.337 0 0 0 24.666 29V18Z"
                data-name="Path 101889"
                opacity={0.07}
              />
              <path
                fill="#50e6ff"
                d="M20 18.333h8.667A1.337 1.337 0 0 1 30 19.666v9.333a1.337 1.337 0 0 1-1.333 1.334H20A1.337 1.337 0 0 1 18.667 29v-9.334A1.337 1.337 0 0 1 20 18.333Z"
                data-name="Path 101890"
              />
              <path
                fill="#fff"
                d="M26.333 19h2a.315.315 0 0 1 .333.333V20a.315.315 0 0 1-.333.333h-2A.315.315 0 0 1 26 20v-.667a.315.315 0 0 1 .333-.333Z"
                data-name="Path 101891"
              />
              <circle
                cx={3.667}
                cy={3.667}
                r={3.667}
                fill="url(#b)"
                data-name="Ellipse 131053"
                transform="translate(20.6 21.667)"
              />
              <path
                fill="#184f85"
                d="M24.266 23a2.333 2.333 0 1 0 2.333 2.333A2.319 2.319 0 0 0 24.266 23Z"
                data-name="Path 101892"
              />
              <path
                fill="url(#d)"
                d="M36.4 36h.933v-2H36.4a.43.43 0 0 0-.4.4v1.2a.43.43 0 0 0 .4.4Z"
                data-name="Path 101893"
                transform="translate(-10 -9.667)"
              />
              <circle
                cx={0.333}
                cy={0.333}
                r={0.333}
                fill="#fff"
                data-name="Ellipse 131054"
                transform="translate(21.333 19)"
              />
              <circle
                cx={0.333}
                cy={0.333}
                r={0.333}
                fill="#fff"
                data-name="Ellipse 131055"
                transform="translate(22.667 19)"
              />
              <circle
                cx={0.333}
                cy={0.333}
                r={0.333}
                fill="#fff"
                data-name="Ellipse 131056"
                transform="translate(20 19)"
              />
              <path
                fill="#0078d4"
                d="M9 25.667h-.667A.315.315 0 0 1 8 25.333v-6A.315.315 0 0 1 8.333 19H9a.315.315 0 0 1 .333.333v6a.315.315 0 0 1-.333.334Z"
                data-name="Path 101894"
              />
              <path
                fill="#0078d4"
                d="M9 12.4h-.667A.315.315 0 0 1 8 12.067V8.733a.315.315 0 0 1 .333-.333H9a.315.315 0 0 1 .333.333v3.333A.357.357 0 0 1 9 12.4Z"
                data-name="Path 101895"
              />
            </g>
            <rect
              width={24}
              height={26}
              fill="#fff"
              data-name="Rectangle 58616"
              rx={1}
              style={{
                mixBlendMode: "hue",
                isolation: "isolate",
              }}
              transform="translate(120 299)"
            />
          </g>
        </g>
      </svg>

    )
}