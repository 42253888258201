import React from "react";
import { withNavBars } from "../../HOCs";
import Settlements from "./settlement";

class SettlementParent extends React.Component {
    render() {
        return <Settlements />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(SettlementParent, props);
