import * as React from "react"
const PropertyOwnerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 113946" transform="translate(7176 23931)">
      <rect
        width={40}
        height={40}
        fill="#f6f1fe"
        data-name="Rectangle 57624"
        rx={8}
        transform="translate(-7176 -23931)"
      />
      <g data-name="Group 113949">
        <path
          fill="#896db3"
          d="M-7166.326-23920.767a2.239 2.239 0 0 1 2.233-2.233h10.046a2.239 2.239 0 0 1 2.233 2.233v17.86h-14.512Z"
          data-name="Path 100588"
        />
        <path
          fill="#7a59aa"
          d="M-7166.326-23902.907h14.512v1.116h-14.512Z"
          data-name="Path 100589"
        />
        <path
          fill="#cbb2f0"
          d="M-7163.535-23906.809h3.349v2.791h-3.349Zm0-4.465h3.349v2.791h-3.349Zm5.581 0h3.349v2.791h-3.349Zm0 4.465h3.349v2.791h-3.349Zm-5.581-8.935h3.349v2.791h-3.349Zm5.581 0h3.349v2.791h-3.349Zm-5.581-4.465h3.349v2.791h-3.349Zm5.581 0h3.349v2.791h-3.349Z"
          data-name="Path 100590"
        />
        <path
          fill="#715699"
          d="M-7147.349-23899h-12.838a1.674 1.674 0 0 1-1.674-1.675v-7.814a1.674 1.674 0 0 1 1.674-1.674h12.838a1.674 1.674 0 0 1 1.674 1.674v7.814a1.674 1.674 0 0 1-1.674 1.675Z"
          data-name="Path 100591"
        />
        <path
          fill="#cbb2f0"
          d="M-7147.347-23902.163a3.944 3.944 0 0 0-6.7 0v.93h6.7Z"
          data-name="Path 100592"
        />
        <path
          fill="#cbb2f0"
          d="M-7150.699-23907.93a1.674 1.674 0 1 0 1.674 1.674 1.674 1.674 0 0 0-1.674-1.674Z"
          data-name="Path 100593"
        />
        <path
          fill="#cbb2f0"
          d="M-7159.628-23906.256h4.465v1.116h-4.465Zm0 2.233h3.349v1.116h-3.349Z"
          data-name="Path 100594"
        />
      </g>
    </g>
  </svg>
)
export default PropertyOwnerIcon
