import * as React from "react"

export const Skip = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 105387">
      <path data-name="Rectangle 55504" fill="none" d="M0 0h20v20H0z" />
      <path
        d="M9.57 4C5.185 4 3.388 8.53 3.388 8.53a.525.525 0 1 0 .975.39S5.916 5.05 9.57 5.05c2.631 0 4.348 2.017 5.106 3.15h-1.7a.525.525 0 1 0 0 1.05h3.15a.525.525 0 0 0 .525-.525v-3.15a.525.525 0 1 0-1.05 0v2.108C14.756 6.388 12.769 4 9.57 4Zm-5.345 7A1.226 1.226 0 0 0 3 12.225v1.75A1.226 1.226 0 0 0 4.225 15.2h1.75A1.226 1.226 0 0 0 7.2 13.975v-1.75A1.226 1.226 0 0 0 5.975 11Zm4.9 0A1.226 1.226 0 0 0 7.9 12.225v1.75A1.226 1.226 0 0 0 9.125 15.2h1.75a1.226 1.226 0 0 0 1.225-1.225v-1.75A1.226 1.226 0 0 0 10.875 11Zm4.9 0a1.226 1.226 0 0 0-1.225 1.225v1.75a1.226 1.226 0 0 0 1.225 1.225h1.75A1.226 1.226 0 0 0 17 13.975v-1.75A1.226 1.226 0 0 0 15.775 11Z"
        fill="#5078e1"
      />
    </g>
  </svg>
)
