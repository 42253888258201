export const ScheduleBoardWeekly = [
    {
        date:"19-08-2022",
        startTime:"01:00",
        endTime:"08:00",
        schedule:[
            {
                status:"Move Out",
                request:"14",
                background_color:"#DBF0F180",
                color:"#6DAFB3",
                card_color:"#C7EEF0"
            },
            {
                status:"General Inspection",
                request:"08",
                background_color:"#FFFAD880",
                color:"#B3A16D",
                card_color:"#F1EDCE"
            },
            {
                status:"Meter Reading",
                request:"08",
                background_color:"#EDE4FE80",
                color:"#896DB3",
                card_color:"#E7DEF8"
            },
            {
                status:"Other Request",
                request:"Count here",
                background_color:"#E4F3FE80",
                color:"#5F8DAF",
                card_color:"#D4E3EF"
            },
            {
                status:"Move In",
                request:"25",
                background_color:"#FEEAEA80",
                color:"#B3776D",
                card_color:"#EFD3D3"
            },
        ]
    },
    {
        date:"21-08-2022",
        startTime:"16:00",
        endTime:"24:00",
        schedule:[
            {
                status:"Move Out",
                request:"14",
                background_color:"#DBF0F180",
                color:"#6DAFB3",
                card_color:"#C7EEF0"
            },
            {
                status:"General Inspection",
                request:"08",
                background_color:"#FFFAD880",
                color:"#B3A16D",
                card_color:"#F1EDCE"
            },
            {
                status:"Meter Reading",
                request:"08",
                background_color:"#EDE4FE80",
                color:"#896DB3",
                card_color:"#E7DEF8"
            },
            {
                status:"Other Request",
                request:"Count here",
                background_color:"#E4F3FE80",
                color:"#5F8DAF",
                card_color:"#D4E3EF"
            },
            {
                status:"Move In",
                request:"25",
                background_color:"#FEEAEA80",
                color:"#B3776D",
                card_color:"#EFD3D3"
            },
        ]
    },
    {
        date:"20-08-2022",
        startTime:"08:00",
        endTime:"16:00",
        schedule:[
            {
                status:"Other Request",
                request:"Count here",
                background_color:"#E4F3FE80",
                color:"#5F8DAF",
                card_color:"#D4E3EF"
            },
            {
                status:"Move In",
                request:"25",
                background_color:"#FEEAEA80",
                color:"#B3776D",
                card_color:"#EFD3D3"
            },
        ]
    },
    {
        date:"23-08-2022",
        startTime:"01:00",
        endTime:"08:00",
        schedule:[
            {
                status:"Move Out",
                request:"14",
                background_color:"#DBF0F180",
                color:"#6DAFB3",
                card_color:"#C7EEF0"
            },
            {
                status:"General Inspection",
                request:"08",
                background_color:"#FFFAD880",
                color:"#B3A16D",
                card_color:"#F1EDCE"
            },
            {
                status:"Meter Reading",
                request:"08",
                background_color:"#EDE4FE80",
                color:"#896DB3",
                card_color:"#E7DEF8"
            }
        ]
    },

]