import { Avatar, Box, Stack, Typography, Grid } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CourtDropdownStyle } from './style'
export const CourtDropdown = ({ openCourt = () => false }) => {
    const classes = CourtDropdownStyle()

    const [open, setOpen] = React.useState(false)
    const showAll = () => {
        open ? setOpen(false) : setOpen(true)
    }
    const [selected, setSelected] = React.useState({
        label: "Amenity Name",
        value: "Amenity Name"
    })
    const select = (e) => {
        setSelected(e)
        setOpen(false)
    }

    return (
        <Box>
            <Box p={1} ml={1} className={classes.dropdown}>
                <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={showAll} justifyContent="center" >

                    <Avatar className={classes.avatar} src="https://cdn.shopify.com/s/files/1/2183/6715/files/badminton-grass-racket-115016_800x.jpg?v=1556705907"></Avatar>
                    <Typography> {selected.label} </Typography>
                    <span>
                        {
                            open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                    </span>
                </Stack>

            </Box>
            <Box>
                {
                    open &&
                    <Box m={2} className={classes.court_content}>
                        <Grid container spacing={2}>
                            <Grid item md={3} onClick={() => select}>
                                <Stack spacing={1} direction={"row"}>
                                    <Avatar src="https://cdn.shopify.com/s/files/1/2183/6715/files/badminton-grass-racket-115016_800x.jpg?v=1556705907"></Avatar>
                                    <Stack spacing={1}>
                                        <Typography className={classes.name}>Court Name 1</Typography>
                                        <Typography className={classes.view}
                                            onClick={openCourt}>View</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={3}>
                                <Stack spacing={1} direction={"row"}>
                                    <Avatar src="https://cdn.shopify.com/s/files/1/2183/6715/files/badminton-grass-racket-115016_800x.jpg?v=1556705907"></Avatar>
                                    <Stack spacing={1}>
                                        <Typography className={classes.name}>Court Name 1</Typography>
                                        <Typography className={classes.view}>View</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={3}>
                                <Stack spacing={1} direction={"row"}>
                                    <Avatar src="https://cdn.shopify.com/s/files/1/2183/6715/files/badminton-grass-racket-115016_800x.jpg?v=1556705907"></Avatar>
                                    <Stack spacing={1}>
                                        <Typography className={classes.name}>Court Name 1</Typography>
                                        <Typography className={classes.view}>View</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={3}>
                                <Stack spacing={1} direction={"row"}>
                                    <Avatar src="https://cdn.shopify.com/s/files/1/2183/6715/files/badminton-grass-racket-115016_800x.jpg?v=1556705907"></Avatar>
                                    <Stack spacing={1}>
                                        <Typography className={classes.name}>Court Name 1</Typography>
                                        <Typography className={classes.view}>View</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={3}>
                                <Stack spacing={1} direction={"row"}>
                                    <Avatar src="https://cdn.shopify.com/s/files/1/2183/6715/files/badminton-grass-racket-115016_800x.jpg?v=1556705907"></Avatar>
                                    <Stack spacing={1}>
                                        <Typography className={classes.name}>Court Name 1</Typography>
                                        <Typography className={classes.view}>View</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={3}>
                                <Stack spacing={1} direction={"row"}>
                                    <Avatar src="https://cdn.shopify.com/s/files/1/2183/6715/files/badminton-grass-racket-115016_800x.jpg?v=1556705907"></Avatar>
                                    <Stack spacing={1}>
                                        <Typography className={classes.name}>Court Name 1</Typography>
                                        <Typography className={classes.view}>View</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={3}>
                                <Stack spacing={1} direction={"row"}>
                                    <Avatar src="https://cdn.shopify.com/s/files/1/2183/6715/files/badminton-grass-racket-115016_800x.jpg?v=1556705907"></Avatar>
                                    <Stack spacing={1}>
                                        <Typography className={classes.name}>Court Name 1</Typography>
                                        <Typography className={classes.view}>View</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item md={3}>
                                <Stack spacing={1} direction={"row"}>
                                    <Avatar src="https://cdn.shopify.com/s/files/1/2183/6715/files/badminton-grass-racket-115016_800x.jpg?v=1556705907"></Avatar>
                                    <Stack spacing={1}>
                                        <Typography className={classes.name}>Court Name 1</Typography>
                                        <Typography className={classes.view}>View</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Box>
        </Box>
    )
}