import { Box } from "@mui/material";
import { TemplateGenerator } from "../../../components";
import { BackdropContext } from '../../../contexts';
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";

import React from 'react'
import { config } from "../../../config";
import { NewLoader } from "../../../components/newLoader";


export const Pdfpreview = ({ url = "", height = "", quote_id = "", t = () => false, type_master_id="" }) => {
    const backdrop = React.useContext(BackdropContext)

    const [template, setTemplate] = React.useState(false)
    const [pdf, setPdf] = React.useState({
        bool: false,
        data: ""
    })

    const generate = async (value) => {
        await backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "...Loading",
        });
        const payload = {
            id: url,
            type_master_id: value?.value

        };
        await NetworkCall(
            `${config.api_url}/quotation/get_quotation_pdf`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setPdf({
                    bool: true,
                    data: res?.data
                })
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                setTemplate(false)
            })
            .catch((err) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                setTemplate(false)

            });
    }

    React.useState(()=>{
        if(type_master_id?.value){
            generate({
                url,
                value:type_master_id?.value
            })
        }
    },[type_master_id])

    return (
        <>
            <Box display="flex" >
                <Box flexGrow={1}>
                    <Box>
                        <center>
                            <Box>
                                {
                                    pdf?.bool ?
                                        <iframe
                                            src={`data:application/pdf;base64,${pdf?.data
                                                }`}
                                            alt="pdf"
                                            width="100%"
                                            style={{ height: `calc(100vh - 305px)` }}
                                            title="quotation"

                                        />

                                        :
                                        <NewLoader />
                                }
                            </Box>

                        </center>
                    </Box>
                </Box>
            </Box>
            <TemplateGenerator t={t} name={"Quotation Template"} open={template} onClose={() => setTemplate(false)} type={1} generate={generate} />


        </>
    )
}
