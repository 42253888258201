import React, { createContext, useState } from "react";

export const AssignModalContext = createContext();

const AssignModalContextProvider = (props) => {
    const [assignData, setAssignData] = useState({
        dateTime: new Date(),
        company: "",
        hours: "",
        department: {
            label: "All",
            value: ""
        },
        role: {
            label: "All",
            value: ""
        },
        resource_type: {
            label: "Users"
        },
        otherResources: false,
        companyOptions: [],
        departmentOptions: [],
        roleOptions: [],
        resourceTypeOptions: [],
        moduleId: "",
        agreement_inspection_id: "",
        primarySearchText: "",
        primarySearch: false,
        otherResourceSearchText: "",
        otherResourceSearch: false,
        resourceList: [],
        otherResourcesList: [],
        error: {
            hours: ""
        }
    })
    const [resourceList, setResourceList] = useState([])
    const [otherResourcesList, setOtherResourcesList] = useState([])
    const [selectedResource, setSelectedResource] = useState([])
    const [selectedOtherResources, setSelectedOtherResources] = useState([])
    return (
        <AssignModalContext.Provider
            value={{
                assignData,
                setAssignData,
                resourceList,
                setResourceList,
                otherResourcesList,
                setOtherResourcesList,
                selectedResource,
                setSelectedResource,
                selectedOtherResources,
                setSelectedOtherResources
            }}
        >
            {props.children}
        </AssignModalContext.Provider>
    );
}
export default AssignModalContextProvider