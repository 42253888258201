import React from "react";
import { Button, Dialog, IconButton, Stack, Typography } from "@mui/material"
import { AddUnitStyles } from "./style"
import CloseIcon from '@mui/icons-material/Close'
import { SearchFilter, SelectBox, UseDebounce } from "../../../../components"
import InfiniteScroll from "react-infinite-scroll-component"
import { constructSelectedUnitData, loadOptionsUnitType } from "../../utils/common"
import { DeleteIcon, GreenTickFilledCircle } from "../../../../assets"
import { BackendRoutes } from "../../../../router/routes";
import { AlertProps, concat_string, LocalStorageKeys, NetWorkCallMethods } from "../../../../utils";
import { NetworkCall } from "../../../../networkcall";
import { config } from "../../../../config";
import { AlertContext } from "../../../../contexts";

export const AddUnitDialog = ({
    t,
    isOpen = true,
    setIsOpen = () => false,
    isButtonDisable = false,
    title = "",
    isSearchDropdownOpen = false,
    setIsSearchDropdownOpen = () => false,
    unitDropdownList = [],
    setUnitDropdownList = () => false,
    unitDropdownOnClick = () => false,
    unitDropdownNext = () => false,
    isUnitDropdownLoading = true,
    setIsUnitDropdownLoading = () => false,
    unitUpdateList = [],
    setUnitUpdateList = () => false,
    unitTypeOption = [],
    unitDropdownSearchText = "",
    setUnitDropdownSearchText = () => false,
    unitDropdownsearch = () => false,
    updateState = () => false,
    selectedProperty = "",
    setSelectedProperty = () => false,
}) => {
    const classes = AddUnitStyles()
    const debounce = UseDebounce()
    const [propertyDropdownLoading, setPropertyDropdownLoading] = React.useState(false)
    const [unitTypeDropdownLoading, setUnitTypeDropdownLoading] = React.useState(false)
    const alert = React.useContext(AlertContext)
    const client_id = localStorage.getItem(LocalStorageKeys?.clinetID)

    // Function for search in search component
    const handleSearch = (v) => {
        setIsUnitDropdownLoading(true)
        setUnitDropdownSearchText(v)
        debounce(() => unitDropdownsearch(v), 800)
    }

    const handleClose = () => {
        if (!isButtonDisable) {
            setIsSearchDropdownOpen(false)
            setIsOpen(false)
            setSelectedProperty("")
            setUnitDropdownSearchText("")
            setUnitDropdownList([])
            setUnitUpdateList([])
            setIsUnitDropdownLoading(true)
        }
    }

    const handleSearchOnClick = () => {
        if (!isButtonDisable && !isSearchDropdownOpen && Boolean(selectedProperty?.value)) {
            unitDropdownOnClick()
            setIsSearchDropdownOpen(true)
        }
    }

    const handleCloseUnitDropdown = () => {
        if (!isButtonDisable) {
            setIsSearchDropdownOpen(false)
            setUnitDropdownList([])
            setIsUnitDropdownLoading(true)
        }
    }

    const handleDropdownClick = (v) => {
        if (!isButtonDisable) {
            let temp_selected = constructSelectedUnitData(v, unitTypeOption)
            setUnitUpdateList([{ ...temp_selected }, ...unitUpdateList])
            setIsSearchDropdownOpen(false)
            setUnitDropdownList([])
            setIsUnitDropdownLoading(true)
        }
    }

    const handleUpdate = (i, _) => {
        let payload = {
            id: _?.data?.id,
            unit_type: _?.form?.selected_unit_type?.value,

        }
        updateState('sync', i, "updated", true, payload)
    }

    const loadOptions = async (search = "", array, type) => {
        setPropertyDropdownLoading(type);
        let offset, temp_res, temp_option = 0;
        let limit = 20
        if (search && !Boolean(array?.length)) { offset = 0; }
        else { offset = array?.length; }
        let payload = { offset, limit, search, client_id}

        switch (type) {
            case 'public_property_listing':
                await NetworkCall(
                    `${config.public_listing_api_url}${BackendRoutes.listing_property_listing}`,
                    NetWorkCallMethods.post, payload, null, true, false
                ).then((res) => {
                    temp_res = res?.data?.response
                    temp_option = temp_res?.property?.map(i => {
                        return {
                            value: i?.id,
                            label: concat_string(
                                { name: i?.name, no: i?.property_no },
                                ["name", "no"]
                            )
                        }
                    })
                    setPropertyDropdownLoading(null)
                }).catch((err) => {
                    console.log(err)
                    alert.setSnack({
                        ...alert, open: true, msg: t("Some Thing Went Wrong"),
                        severity: AlertProps.severity.error
                    })
                })
                return {
                    options: [...temp_option],
                    hasMore: (array?.length + temp_option?.length) < temp_res?.count
                }
            default:
                return { options: [] }
        }
    };

    return <Dialog
        className={classes.root}
        open={isOpen}
        onClose={handleClose}>
        <div className={classes.header_div}
            onClick={handleCloseUnitDropdown}>
            <Typography className={classes.title}>
                {title}
            </Typography>
            <IconButton onClick={handleClose}
                disabled={isButtonDisable}
                className={classes.close_button}>
                <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
            </IconButton>
        </div>
        <div style={{ padding: "16px 20px 0px 20px" }}>
            <SelectBox
                isRequired
                isReadOnly={isButtonDisable}
                label={t("Choose Property")}
                placeholder={t("Select Property")}
                value={selectedProperty}
                onChange={(value) => {
                    handleCloseUnitDropdown()
                    setSelectedProperty(value)
                }}
                loading={propertyDropdownLoading === "public_property_listing"}
                isPaginate
                debounceTimeout={800}
                loadOptions={(search, array) => loadOptions(search, array, 'public_property_listing')} />
        </div>
        <div className={classes.body_div}>
            <div className={classes.search_div}
                onClick={handleSearchOnClick}>
                <SearchFilter
                    isReadonly={!Boolean(selectedProperty?.value)}
                    color={"white"}
                    placeholder={t("Search Unit")}
                    value={unitDropdownSearchText}
                    handleChange={(v) => handleSearch(v)} />
            </div>
            {isSearchDropdownOpen && <div className={classes.search_dropdown_div} style={{ zIndex: 1 }}>
                <div className={classes.search_dropdown_list_div}>
                    {isUnitDropdownLoading ?
                        <div className={classes.loader_div}>
                            <Typography className={classes.loader_text}>
                                {t("Units Loading...")}
                            </Typography>
                        </div> :
                        (
                            (unitDropdownList && unitDropdownList?.length > 0) ?
                                <InfiniteScroll
                                    dataLength={unitDropdownList?.length ?? ""}
                                    next={unitDropdownNext}
                                    hasMore={true}
                                    height={300}>
                                    <Stack divider={<div className={classes.divider} />}>
                                        {unitDropdownList?.map((_) => {
                                            return (
                                                <Stack className={classes.dropdown_root} onClick={() => handleDropdownClick(_?.data)}
                                                    direction={"row"} alignItems={"center"}>
                                                    <img src={_?.logo} alt={t("Unit Logo")} className={classes.logo} />
                                                    <Stack spacing={"8px"} justifyContent={"space-between"} marginInlineStart={"8px"}>
                                                        <Typography className={classes.heading}>{_?.unit_name}</Typography>
                                                        <Stack direction={"row"} spacing={"6px"} alignItems={"center"}
                                                            divider={<div className={classes.period_divider} />}>
                                                            <Typography className={classes.sub_heading}>{_?.unit_id}</Typography>
                                                            <Typography className={classes.sub_heading}>{_?.unit_type}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            )
                                        })}
                                    </Stack>
                                </InfiniteScroll> :
                                <div className={classes.loader_div}>
                                    <Typography className={classes.loader_text}>
                                        {t("No Unit Found")}
                                    </Typography>
                                </div>)}
                </div>
            </div>}
            <Stack spacing={"4px"} className={classes.selected_unit_stack} onClick={handleCloseUnitDropdown}>
                {unitUpdateList?.map((_, i) => {
                    return <Stack direction={"row"} spacing={"8px"} alignItems={"center"} justifyContent={"space-between"}
                        className={classes.selected_unit_root}>
                        <Stack direction={"row"} alignItems={"center"} width={"40%"}>
                            <img src={_?.logo} alt={t("Unit Logo")} className={classes.logo} />
                            <Stack spacing={"8px"} justifyContent={"space-between"} marginInlineStart={"8px"}>
                                <Typography className={classes.heading}>{_?.unit_name}</Typography>
                                <Stack direction={"row"} alignItems={"center"}
                                    divider={<div className={classes.period_divider} />}>
                                    <Typography className={classes.sub_heading}>{_?.unit_id}</Typography>
                                    <Typography className={classes.sub_heading}>{_?.unit_type}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <div style={{ width: "40%" }}>
                            <SelectBox
                                isReadOnly={isButtonDisable || _?.updated}
                                selectHeight={"32px"}
                                placeholder={t("Choose global unit type")}
                                value={_?.form?.selected_unit_type}
                                loading={unitTypeDropdownLoading === "global_unit_type"}
                                loadOptions={(search, array) => loadOptionsUnitType(search, array,setUnitTypeDropdownLoading,BackendRoutes.sync_global_unit_type)}
                                isPaginate={true}
                                onChange={(v) => updateState('value', i, "selected_unit_type", v)} />
                        </div>
                        <Stack direction={"row"} spacing={"4px"} alignItems={"center"} justifyContent={"end"} width={"20%"}>
                            <Button className={classes.update_button}
                                disabled={_?.updated ? true : (isButtonDisable || !Boolean(_?.form?.selected_unit_type?.value))}
                                onClick={() => handleUpdate(i, _)}>
                                <Stack spacing={"4px"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    {_?.updated && <GreenTickFilledCircle />}
                                    <Typography className={_?.updated ? classes.updated_text : classes.update_text}>
                                        {_?.updated ? t("Updated") : t("Update")}
                                    </Typography>
                                </Stack>
                            </Button>
                            {!_?.updated && <IconButton onClick={() => updateState('delete', i)}
                                disabled={isButtonDisable}
                                className={classes.close_button}>
                                <DeleteIcon />
                            </IconButton>}
                        </Stack>
                    </Stack>
                })}
            </Stack>
        </div>
        <div className={classes.footer_div}
            onClick={handleCloseUnitDropdown}>
            <Button variant="contained" fullWidth
                disabled={isButtonDisable}
                className={classes.footer_button}
                onClick={handleClose}>
                {t("Done")}
            </Button>
        </div>
    </Dialog>
}