import * as React from "react"
const Camera = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.579}
    height={12.623}
    {...props}
  >
    <path
      fill="#4e5a6b"
      fillRule="evenodd"
      d="M5.897.002a1.264 1.264 0 0 0-1.2.732L3.315 3.688a1.266 1.266 0 0 0 .613 1.684l.494.231-.231.493a1.266 1.266 0 0 0 .614 1.684l.689.321-.81 1.01a.182.182 0 0 1-.142.068H3.261V8.04a.907.907 0 0 0-.757-.894L.633 6.835A.544.544 0 0 0 0 7.371v4.71a.542.542 0 0 0 .633.535l1.871-.311a.905.905 0 0 0 .757-.893v-1.143h1.282a1.268 1.268 0 0 0 .991-.475l.975-1.219.923.43a1.266 1.266 0 0 0 1.684-.614l.229-.491 1.305.611a.544.544 0 0 0 .62-.115l.266-.273 1.891.883a.543.543 0 0 0 .722-.263l1.379-2.955a.545.545 0 0 0-.263-.723l-.515-.244a.544.544 0 0 0-.16-.872L6.377.119a1.263 1.263 0 0 0-.48-.117Zm-.117 1.1a.181.181 0 0 1 .139.006l7.515 3.503-2.672 2.75-1.449-.675-3.941-1.839-.985-.46a.181.181 0 0 1-.088-.241l1.379-2.954a.179.179 0 0 1 .102-.094Zm8.163 4.547.371.173-.92 1.97-1.056-.491Zm-8.537.416L7.59 7.081l.77.359-.229.493a.182.182 0 0 1-.1.093.184.184 0 0 1-.139-.006L5.263 6.796a.184.184 0 0 1-.093-.1.18.18 0 0 1 .006-.137ZM1.087 8.011l1.087.181v3.064l-1.087.181Z"
    />
  </svg>
)
export default Camera
