import {
    Box
} from "@mui/material";
import React from "react";
import { CreateOppertunityContext } from "../../../contexts/createOppertunityContext";
import { LeaseForm, SaleForm } from "../component";
export const LeadNew = (props) => {
    const { dataNew, updateStateNew, setDataNew, t = () => false, opperEdit = false, setOpperEdit = () => false } = React.useContext(CreateOppertunityContext);


    return (
        <Box >

            {
                (dataNew?.revenue_type?.value === "Lease" || dataNew?.revenue_type?.value === "Manage") &&
                <LeaseForm
                    data={dataNew}
                    updateState={updateStateNew}
                    purpose={dataNew?.purpose?.value}
                    type={dataNew?.revenue_type?.value}
                    setData={setDataNew}
                    is_convert={props?.is_convert}
                    t={t}
                    contact_id={props?.contact_id}
                    opperEdit={opperEdit}
                    setOpperEdit={setOpperEdit}
                />
            }

            {
                dataNew?.revenue_type?.value === "Sale" &&
                <SaleForm
                    data={dataNew}
                    updateState={updateStateNew}
                    purpose={dataNew?.purpose?.value}
                    type={dataNew?.revenue_type?.value}
                    setData={setDataNew}
                    is_convert={props?.is_convert}

                    t={t}
                    contact_id={props?.contact_id}
                    opperEdit={opperEdit}
                    setOpperEdit={setOpperEdit}
                />
            }

            {/* Short Term Rental */}
            {
                dataNew?.purpose?.value === "Short Term Rental" &&
                <LeaseForm
                    data={dataNew}
                    purpose={dataNew?.purpose?.value}
                    type={dataNew?.revenue_type?.value}
                    updateState={updateStateNew}
                    setData={setDataNew}
                    hideUnit={true}
                    t={t}
                    contact_id={props?.contact_id}
                    opperEdit={opperEdit}
                    setOpperEdit={setOpperEdit}
                />
            }


        </Box>


    );
};
