import { Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { Bold, Regular } from "../../utils";
const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: -2
    },
    tabroot: {
        display: "flex",
        alignItems: "center",
    },
    tabItem: {
        textTransform: "capitalize",
        fontWeight: "bold",
        color: "#071741",
        padding: "0px",
        minHeight: "auto",
        borderRadius: "6px",
        "& .MuiButtonBase-root-MuiTab-root": {
            borderRadius: "20px",
        },
        minWidth: "auto",

        marginRight: "8px",
    },
    tabItemSelect: {
        textTransform: "capitalize",
        fontWeight: "bold",
        color: theme.typography.color.primary,
        marginRight: "8px",
        padding: "0px",
        borderRadius: "6px",
        minHeight: "auto",
        "& .MuiButtonBase-root-MuiTab-root": {
            minHeight: "0px",
        },
        minWidth: "auto",
    },
    title: {
        padding: "8px 14px",
        borderRadius: "6px",
        background: theme.palette.background.paper,
        color: "#98A0AC",
        fontFamily: Regular,
        fontSize:"0.75rem",
    },
    titleselect: {
        padding: "8px 14px",
        borderRadius: "6px",
        backgroundColor: "#4E5A6B",
        color: "white",
        fontSize:"0.75rem",
        fontFamily: Bold
    },
    more: {
        border: "1px solid #E4E8EE",
        borderRadius: "20px",
        background: theme.palette.background.paper,
    },
}));
export const PropertyFinderTabs = ({
    value = 0,
    handleChange = () => false,
    list = []
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                indicatorColor="none"
                textColor="secondary"
                className={classes.tabroot}
                variant="scrollable"
                visibleScrollbar={false}
                scrollButtons={false}
            >
                {
                    list?.map((val) => {
                        return (
                            <Tab
                                className={value === val?.value ? classes.tabItemSelect : classes.tabItem}
                                label={
                                    <Typography className={value === val?.value ? classes.titleselect : classes.title}>
                                        {val?.label}
                                    </Typography>
                                }
                                onClick={() => handleChange(val?.value)}
                            />
                        )
                    })
                }

            </Tabs>
        </div>
    );
};
