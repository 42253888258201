import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.tertiary1,
        width: "1px",
        height: "inherit",
        display: "flex",
        marginLeft: (props) => props?.marginLeft ?? null,

    },
    inner_div: {
        backgroundColor: theme.palette.background.primary,
        width: "1px",
        margin: "8px 0px",
        height: "inherit",
        display: "flex"
    }
}));