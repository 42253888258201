export const Jobs = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Group 98466">
      <g fill={props?.fill ?? "#c1c5cb"}>
        <path
          data-name="Path 94984"
          d="M13.45 14.495c1.578 0 2.688-2.469 2.688-3.807a2.688 2.688 0 1 0-5.376 0c0 1.334 1.107 3.807 2.688 3.807Z"
        />
        <path
          data-name="Path 94985"
          d="M8.301 21.62h2.46v-1.942a.3.3 0 1 1 .6 0v1.941h2.616v-2.921a1.521 1.521 0 0 1 1.524-1.52h.369a1.527 1.527 0 0 1 1.365-1.413 3.211 3.211 0 0 0-2.118-.789.3.3 0 0 0-.243.126l-1.424 1.976-1.425-1.974a.3.3 0 0 0-.243-.126 3.231 3.231 0 0 0-3.21 2.844l-.571 3.451a.3.3 0 0 0 .066.243.3.3 0 0 0 .231.1Z"
        />
        <path
          data-name="Path 94986"
          d="M16.465 17.276v.5h-.966a.923.923 0 0 0-.921.921v3.379a.923.923 0 0 0 .921.921h5.736a.923.923 0 0 0 .921-.921v-3.378a.923.923 0 0 0-.921-.921h-.966v-.5a.922.922 0 0 0-.924-.921h-1.956a.922.922 0 0 0-.924.921Zm.6 0a.324.324 0 0 1 .324-.321h1.96a.324.324 0 0 1 .324.321v.5h-2.6Z"
        />
      </g>
      <path data-name="Rectangle 52150" fill="none" d="M0 0h30v30H0z" />
    </g>
  </svg>
)
