export const Prospects = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 96941" fill={props?.fill ?? "#c1c5cb"}>
            <path
                data-name="Path 93625"
                d="M20.792 6.901H9.212a2.24 2.24 0 0 0-2.24 2.235l.025 10.1a2.217 2.217 0 0 0 2.215 2.217h9.08a6.461 6.461 0 0 0 .39.82 1.731 1.731 0 0 0 3.194-1.1 2.214 2.214 0 0 0 1.131-1.931l.025-10.1a2.24 2.24 0 0 0-2.24-2.241Zm1.7 12.338a1.7 1.7 0 0 1-.764 1.42 1.468 1.468 0 0 0-.1-.2 11.209 11.209 0 0 0-1.594-1.807 4.274 4.274 0 1 0-2.242 1.382c.091.314.195.625.3.914h-8.88a1.709 1.709 0 0 1-1.708-1.708v-8.533h14.988Zm-3.741-.149a3.774 3.774 0 1 1 1.247-5.189 3.772 3.772 0 0 1-1.243 5.188Zm1.627-10.289a.615.615 0 1 1-.615-.615.616.616 0 0 1 .615.615Zm-2.47 0a.4.4 0 0 1-.4.4h-5.016a.4.4 0 0 1 0-.8h5.016a.4.4 0 0 1 .4.4Zm-7.058 0a.614.614 0 1 1-.615-.615.616.616 0 0 1 .614.615Z"
            />
            <path
                data-name="Path 93626"
                d="M15.19 13.276a3.046 3.046 0 1 0 4.188 1 3.052 3.052 0 0 0-4.188-1Zm3.007 4.123a2.362 2.362 0 0 1-2.819 0 .33.33 0 0 1-.005-.519.987.987 0 0 1 .635-.23h.243v-.157a.871.871 0 0 1-.187-.147 1 1 0 0 1-.291-.61.431.431 0 0 1-.324-.418.421.421 0 0 1 .223-.375c0-.071-.005-.137-.005-.154a1.083 1.083 0 0 1 1.116-.982 1.129 1.129 0 0 1 1.1.868 1.652 1.652 0 0 1 .008.263.429.429 0 0 1 .228.38.434.434 0 0 1-.321.42 1.014 1.014 0 0 1-.478.754v.157h.24a.994.994 0 0 1 .668.258.319.319 0 0 1-.028.488Z"
            />
            <path
                data-name="Path 93627"
                d="M9.144 13.898h1.4a.5.5 0 0 0 .5-.5v-1.24a.5.5 0 0 0-.5-.5h-1.4a.5.5 0 0 0-.5.5v1.24a.5.5 0 0 0 .5.5Zm.154-1.369a.192.192 0 0 1 .268.028l.154.192.423-.357a.189.189 0 0 1 .268.023.186.186 0 0 1-.023.266l-.569.481a.189.189 0 0 1-.271-.025l-.278-.34a.189.189 0 0 1 .028-.268Z"
            />
            <path
                data-name="Path 93628"
                d="M9.144 16.961h1.4a.5.5 0 0 0 .5-.5v-1.238a.5.5 0 0 0-.5-.5h-1.4a.5.5 0 0 0-.5.5v1.238a.5.5 0 0 0 .5.5Zm.154-1.332a.192.192 0 0 1 .268.028l.154.192.423-.357a.189.189 0 0 1 .268.023.186.186 0 0 1-.023.266l-.569.481a.189.189 0 0 1-.271-.025l-.278-.344a.189.189 0 0 1 .028-.264Z"
            />
            <path
                data-name="Path 93629"
                d="M9.144 20.026h1.4a.5.5 0 0 0 .5-.5v-1.241a.5.5 0 0 0-.5-.5h-1.4a.507.507 0 0 0-.5.5v1.238a.507.507 0 0 0 .5.503Zm.154-1.306a.192.192 0 0 1 .268.028l.154.192.423-.357a.189.189 0 0 1 .268.023.186.186 0 0 1-.023.266l-.569.481a.189.189 0 0 1-.271-.025l-.278-.337a.189.189 0 0 1 .025-.268Z"
            />
        </g>
        <path data-name="Rectangle 52035" fill="none" d="M0 0h30v30H0z" />
    </svg>
)
