import * as React from "react"

const CycleIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={39.489}
        height={90}
        {...props}
    >
        <defs>
            <linearGradient
                id="a"
                x1={343.818}
                y1={429.616}
                x2={347.232}
                y2={429.616}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#302c28" />
                <stop offset={0.09} stopColor="#3a3632" />
                <stop offset={0.35} stopColor="#514d4b" />
                <stop offset={0.5} stopColor="#5a5654" />
                <stop offset={0.65} stopColor="#514d4b" />
                <stop offset={0.91} stopColor="#3a3632" />
                <stop offset={1} stopColor="#302c28" />
            </linearGradient>
            <linearGradient
                id="b"
                x1={578.854}
                y1={-412.499}
                x2={580.812}
                y2={-412.499}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.05} stopColor="#b9b9b9" />
                <stop offset={0.21} stopColor="#999a9a" />
                <stop offset={0.26} stopColor="#a1a1a1" />
                <stop offset={0.34} stopColor="#b6b6b6" />
                <stop offset={0.42} stopColor="#d1d0d0" />
                <stop offset={0.53} stopColor="#f8fafb" />
                <stop offset={0.69} stopColor="#d1d0d0" />
                <stop offset={0.74} stopColor="#b3b4b3" />
                <stop offset={0.78} stopColor="#a6a7a6" />
                <stop offset={0.83} stopColor="#aaa" />
                <stop offset={0.87} stopColor="#b3b3b3" />
                <stop offset={0.97} stopColor="#bdbdbd" />
            </linearGradient>
            <linearGradient
                id="n"
                x1={339.866}
                y1={-138.786}
                x2={343.269}
                y2={-138.786}
                xlinkHref="#a"
            />
            <linearGradient
                id="o"
                x1={458.781}
                y1={-78.565}
                x2={458.781}
                y2={-78.717}
                xlinkHref="#b"
            />
            <linearGradient
                id="p"
                x1={-1778.754}
                y1={-423.261}
                x2={-1775.878}
                y2={-423.261}
                xlinkHref="#a"
            />
            <linearGradient
                id="q"
                x1={356.615}
                y1={-159.825}
                x2={359.434}
                y2={-159.825}
                xlinkHref="#a"
            />
            <linearGradient
                id="c"
                x1={80.768}
                y1={-432.223}
                x2={80.408}
                y2={-437.049}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#302c28" />
                <stop offset={0.24} stopColor="#211e1b" />
                <stop offset={0.71} stopColor="#080807" />
                <stop offset={1} />
            </linearGradient>
            <linearGradient
                id="r"
                x1={84.401}
                y1={-417.798}
                x2={84.032}
                y2={-422.36}
                xlinkHref="#c"
            />
            <linearGradient
                id="s"
                x1={596.542}
                y1={-394.393}
                x2={598.521}
                y2={-394.393}
                xlinkHref="#b"
            />
            <linearGradient
                id="t"
                x1={64.367}
                y1={-123.013}
                x2={65.078}
                y2={-124.279}
                xlinkHref="#a"
            />
            <linearGradient
                id="d"
                x1={64.367}
                y1={-123.598}
                x2={65.038}
                y2={-123.598}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#040000" />
                <stop offset={0.04} stopColor="#0c0909" />
                <stop offset={0.25} stopColor="#333232" />
                <stop offset={0.41} stopColor="#4c4b4b" />
                <stop offset={0.51} stopColor="#555" />
                <stop offset={0.61} stopColor="#505050" />
                <stop offset={0.7} stopColor="#454444" />
                <stop offset={0.8} stopColor="#312f2f" />
                <stop offset={0.9} stopColor="#151212" />
                <stop offset={0.92} stopColor="#110e0e" />
            </linearGradient>
            <linearGradient
                id="u"
                x1={87.453}
                y1={-557.84}
                x2={87.659}
                y2={-557.84}
                xlinkHref="#d"
            />
            <linearGradient
                id="v"
                x1={87.846}
                y1={-547.729}
                x2={88.051}
                y2={-547.729}
                xlinkHref="#d"
            />
            <linearGradient
                id="e"
                x1={84.69}
                y1={-442.613}
                x2={84.292}
                y2={-447.744}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} />
                <stop offset={0.33} stopColor="#eff3f6" />
                <stop offset={1} />
            </linearGradient>
            <linearGradient
                id="w"
                x1={-498.429}
                y1={1537.193}
                x2={-498.825}
                y2={1532.05}
                xlinkHref="#e"
            />
            <linearGradient
                id="f"
                x1={68.786}
                y1={-417.012}
                x2={68.786}
                y2={-421.686}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#eff3f6" />
                <stop offset={0.22} />
                <stop offset={0.83} />
                <stop offset={1} stopColor="#eff3f6" />
            </linearGradient>
            <linearGradient
                id="x"
                x1={68.846}
                y1={-421.395}
                x2={68.846}
                y2={-426.111}
                xlinkHref="#f"
            />
            <linearGradient
                id="y"
                x1={-2617.031}
                y1={228.862}
                x2={-2617.031}
                y2={228.735}
                xlinkHref="#b"
            />
            <linearGradient
                id="z"
                x1={255.198}
                y1={1147.283}
                x2={258.087}
                y2={1147.283}
                xlinkHref="#a"
            />
            <linearGradient
                id="A"
                x1={-2010.54}
                y1={545.595}
                x2={-2007.686}
                y2={545.595}
                xlinkHref="#a"
            />
            <linearGradient
                id="B"
                x1={-456.131}
                y1={1492.673}
                x2={-456.492}
                y2={1487.847}
                xlinkHref="#c"
            />
            <linearGradient
                id="C"
                x1={-478.747}
                y1={1440.293}
                x2={-479.119}
                y2={1435.731}
                xlinkHref="#c"
            />
            <linearGradient
                id="D"
                x1={-3503.47}
                y1={1363.316}
                x2={-3501.47}
                y2={1363.316}
                xlinkHref="#b"
            />
            <linearGradient
                id="E"
                x1={-366.218}
                y1={425.916}
                x2={-365.504}
                y2={424.65}
                xlinkHref="#a"
            />
            <linearGradient
                id="F"
                x1={93.725}
                y1={-550.582}
                x2={93.931}
                y2={-550.582}
                xlinkHref="#d"
            />
            <linearGradient
                id="G"
                x1={93.332}
                y1={-552.494}
                x2={93.538}
                y2={-552.494}
                xlinkHref="#d"
            />
            <linearGradient
                id="H"
                x1={68.644}
                y1={-124.6}
                x2={69.313}
                y2={-124.6}
                xlinkHref="#d"
            />
            <linearGradient
                id="I"
                x1={-481.652}
                y1={1526.526}
                x2={-482.05}
                y2={1521.396}
                xlinkHref="#e"
            />
            <linearGradient
                id="J"
                x1={72.379}
                y1={-411.149}
                x2={71.983}
                y2={-416.28}
                xlinkHref="#e"
            />
            <linearGradient
                id="K"
                x1={-389.294}
                y1={1456.072}
                x2={-389.294}
                y2={1451.356}
                xlinkHref="#f"
            />
            <linearGradient
                id="L"
                x1={-389.219}
                y1={1455.787}
                x2={-389.219}
                y2={1451.072}
                xlinkHref="#f"
            />
            <linearGradient
                id="M"
                x1={110.205}
                y1={-14.042}
                x2={110.205}
                y2={-13.923}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.05} stopColor="#b16f43" />
                <stop offset={0.15} stopColor="#75543f" />
                <stop offset={0.26} stopColor="#b16f43" />
                <stop offset={0.4} stopColor="#e6a65e" />
                <stop offset={0.74} stopColor="#f7b767" />
                <stop offset={0.87} stopColor="#d29053" />
                <stop offset={1} stopColor="#b16f43" />
            </linearGradient>
            <linearGradient
                id="N"
                x1={974.546}
                y1={-13.968}
                x2={974.546}
                y2={-13.984}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.12} stopColor="#b16f43" />
                <stop offset={0.23} stopColor="#c27e48" />
                <stop offset={0.46} stopColor="#eea755" />
                <stop offset={0.54} stopColor="#ffb75a" />
                <stop offset={0.9} stopColor="#ca8f5d" />
                <stop offset={1} stopColor="#bc845e" />
            </linearGradient>
            <linearGradient
                id="O"
                x1={105.663}
                y1={-14.091}
                x2={114.01}
                y2={-14.091}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#b16f43" />
                <stop offset={0.02} stopColor="#b7784d" />
                <stop offset={0.11} stopColor="#ce9b72" />
                <stop offset={0.2} stopColor="#dfb78f" />
                <stop offset={0.29} stopColor="#eccba4" />
                <stop offset={0.38} stopColor="#f4d7b0" />
                <stop offset={0.46} stopColor="#f7dbb5" />
                <stop offset={0.55} stopColor="#f3d6af" />
                <stop offset={0.66} stopColor="#eac7a0" />
                <stop offset={0.77} stopColor="#dbb087" />
                <stop offset={0.9} stopColor="#c58f65" />
                <stop offset={1} stopColor="#b16f43" />
            </linearGradient>
            <linearGradient
                id="i"
                x1={232.052}
                y1={-15.73}
                x2={248.515}
                y2={-15.73}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#b16f43" />
                <stop offset={0.03} stopColor="#b7784d" />
                <stop offset={0.12} stopColor="#ce9b72" />
                <stop offset={0.2} stopColor="#dfb78f" />
                <stop offset={0.29} stopColor="#eccba4" />
                <stop offset={0.38} stopColor="#f4d7b0" />
                <stop offset={0.46} stopColor="#f7dbb5" />
                <stop offset={0.55} stopColor="#f3d6af" />
                <stop offset={0.66} stopColor="#eac7a0" />
                <stop offset={0.77} stopColor="#dbb087" />
                <stop offset={0.9} stopColor="#c58f65" />
                <stop offset={1} stopColor="#b16f43" />
            </linearGradient>
            <linearGradient
                id="g"
                x1={410.151}
                y1={-367.722}
                x2={410.389}
                y2={-367.508}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.12} stopColor="#b16f43" />
                <stop offset={0.21} stopColor="#b87c54" />
                <stop offset={0.38} stopColor="#ca9e80" />
                <stop offset={0.63} stopColor="#e7d4c7" />
                <stop offset={0.81} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="P"
                x1={411.605}
                y1={-366.603}
                x2={411.83}
                y2={-366.389}
                xlinkHref="#g"
            />
            <linearGradient
                id="Q"
                x1={413.06}
                y1={-365.498}
                x2={413.285}
                y2={-365.285}
                xlinkHref="#g"
            />
            <linearGradient
                id="R"
                x1={414.502}
                y1={-364.375}
                x2={414.74}
                y2={-364.161}
                xlinkHref="#g"
            />
            <linearGradient
                id="S"
                x1={415.956}
                y1={-363.271}
                x2={416.181}
                y2={-363.057}
                xlinkHref="#g"
            />
            <linearGradient
                id="T"
                x1={417.398}
                y1={-362.151}
                x2={417.636}
                y2={-361.938}
                xlinkHref="#g"
            />
            <linearGradient
                id="U"
                x1={418.853}
                y1={-360.323}
                x2={419.078}
                y2={-360.11}
                xlinkHref="#g"
            />
            <linearGradient
                id="V"
                x1={420.294}
                y1={-359.924}
                x2={420.532}
                y2={-359.71}
                xlinkHref="#g"
            />
            <linearGradient
                id="W"
                x1={421.749}
                y1={-358.8}
                x2={421.974}
                y2={-358.586}
                xlinkHref="#g"
            />
            <linearGradient
                id="X"
                x1={423.204}
                y1={-356.997}
                x2={423.429}
                y2={-356.784}
                xlinkHref="#g"
            />
            <linearGradient
                id="Y"
                x1={424.645}
                y1={-356.572}
                x2={424.87}
                y2={-356.358}
                xlinkHref="#g"
            />
            <linearGradient
                id="Z"
                x1={426.1}
                y1={-355.468}
                x2={426.325}
                y2={-355.254}
                xlinkHref="#g"
            />
            <linearGradient
                id="aa"
                x1={427.542}
                y1={-354.349}
                x2={427.78}
                y2={-354.135}
                xlinkHref="#g"
            />
            <linearGradient
                id="ab"
                x1={428.996}
                y1={-353.23}
                x2={429.221}
                y2={-353.016}
                xlinkHref="#g"
            />
            <linearGradient
                id="ac"
                x1={430.438}
                y1={-352.121}
                x2={430.676}
                y2={-351.907}
                xlinkHref="#g"
            />
            <linearGradient
                id="ad"
                x1={431.893}
                y1={-351.002}
                x2={432.118}
                y2={-350.788}
                xlinkHref="#g"
            />
            <linearGradient
                id="ae"
                x1={433.347}
                y1={-349.21}
                x2={433.572}
                y2={-348.997}
                xlinkHref="#g"
            />
            <linearGradient
                id="af"
                x1={434.789}
                y1={-348.769}
                x2={435.014}
                y2={-348.555}
                xlinkHref="#g"
            />
            <linearGradient
                id="ag"
                x1={436.244}
                y1={-347.665}
                x2={436.469}
                y2={-347.451}
                xlinkHref="#g"
            />
            <linearGradient
                id="ah"
                x1={437.699}
                y1={-346.536}
                x2={437.923}
                y2={-346.322}
                xlinkHref="#g"
            />
            <linearGradient
                id="ai"
                x1={439.153}
                y1={-344.673}
                x2={439.378}
                y2={-344.46}
                xlinkHref="#g"
            />
            <linearGradient
                id="aj"
                x1={411.6}
                y1={1220.738}
                x2={411.825}
                y2={1220.941}
                xlinkHref="#g"
            />
            <linearGradient
                id="ak"
                x1={413.042}
                y1={1221.857}
                x2={413.28}
                y2={1222.07}
                xlinkHref="#g"
            />
            <linearGradient
                id="al"
                x1={414.497}
                y1={1222.976}
                x2={414.721}
                y2={1223.189}
                xlinkHref="#g"
            />
            <linearGradient
                id="am"
                x1={415.938}
                y1={1221.695}
                x2={416.176}
                y2={1221.908}
                xlinkHref="#g"
            />
            <linearGradient
                id="an"
                x1={417.393}
                y1={1225.204}
                x2={417.618}
                y2={1225.407}
                xlinkHref="#g"
            />
            <linearGradient
                id="ao"
                x1={418.848}
                y1={1226.313}
                x2={419.072}
                y2={1226.526}
                xlinkHref="#g"
            />
            <linearGradient
                id="ap"
                x1={420.289}
                y1={1227.392}
                x2={420.514}
                y2={1227.616}
                xlinkHref="#g"
            />
            <linearGradient
                id="aq"
                x1={421.744}
                y1={1228.511}
                x2={421.969}
                y2={1228.725}
                xlinkHref="#g"
            />
            <linearGradient
                id="ar"
                x1={423.185}
                y1={1227.22}
                x2={423.41}
                y2={1227.433}
                xlinkHref="#g"
            />
            <linearGradient
                id="as"
                x1={424.64}
                y1={1230.739}
                x2={424.865}
                y2={1230.943}
                xlinkHref="#g"
            />
            <linearGradient
                id="at"
                x1={426.082}
                y1={1231.888}
                x2={426.32}
                y2={1232.101}
                xlinkHref="#g"
            />
            <linearGradient
                id="au"
                x1={427.537}
                y1={1232.977}
                x2={427.761}
                y2={1233.181}
                xlinkHref="#g"
            />
            <linearGradient
                id="av"
                x1={428.991}
                y1={1234.086}
                x2={429.216}
                y2={1234.3}
                xlinkHref="#g"
            />
            <linearGradient
                id="aw"
                x1={430.433}
                y1={1235.205}
                x2={430.658}
                y2={1235.409}
                xlinkHref="#g"
            />
            <linearGradient
                id="ax"
                x1={431.888}
                y1={1236.314}
                x2={432.112}
                y2={1236.518}
                xlinkHref="#g"
            />
            <linearGradient
                id="ay"
                x1={433.329}
                y1={1237.433}
                x2={433.554}
                y2={1237.637}
                xlinkHref="#g"
            />
            <linearGradient
                id="az"
                x1={434.784}
                y1={1238.552}
                x2={435.009}
                y2={1238.756}
                xlinkHref="#g"
            />
            <linearGradient
                id="aA"
                x1={436.225}
                y1={1239.68}
                x2={436.463}
                y2={1239.894}
                xlinkHref="#g"
            />
            <linearGradient
                id="aB"
                x1={437.68}
                y1={1238.358}
                x2={437.905}
                y2={1238.561}
                xlinkHref="#g"
            />
            <linearGradient
                id="aC"
                x1={439.135}
                y1={1241.899}
                x2={439.373}
                y2={1242.103}
                xlinkHref="#g"
            />
            <linearGradient
                id="aD"
                x1={440.59}
                y1={1243.018}
                x2={440.815}
                y2={1243.222}
                xlinkHref="#g"
            />
            <linearGradient
                id="aH"
                x1={595.634}
                y1={-14.886}
                x2={640.23}
                y2={-14.886}
                xlinkHref="#i"
            />
            <linearGradient
                id="aI"
                x1={1407.186}
                y1={-14.886}
                x2={1512.596}
                y2={-14.886}
                xlinkHref="#i"
            />
            <linearGradient
                id="aJ"
                x1={1406.868}
                y1={-14.882}
                x2={1512.277}
                y2={-14.882}
                xlinkHref="#i"
            />
            <linearGradient
                id="aK"
                x1={201.716}
                y1={-17.602}
                x2={218.187}
                y2={-17.602}
                xlinkHref="#i"
            />
            <linearGradient
                id="aL"
                x1={357.703}
                y1={-408.022}
                x2={357.941}
                y2={-407.808}
                xlinkHref="#g"
            />
            <linearGradient
                id="aM"
                x1={359.157}
                y1={-406.899}
                x2={359.382}
                y2={-406.685}
                xlinkHref="#g"
            />
            <linearGradient
                id="aN"
                x1={360.612}
                y1={-405.794}
                x2={360.836}
                y2={-405.58}
                xlinkHref="#g"
            />
            <linearGradient
                id="aO"
                x1={362.052}
                y1={-403.881}
                x2={362.277}
                y2={-403.668}
                xlinkHref="#g"
            />
            <linearGradient
                id="aP"
                x1={363.507}
                y1={-406.914}
                x2={363.731}
                y2={-406.698}
                xlinkHref="#g"
            />
            <linearGradient
                id="aQ"
                x1={364.948}
                y1={-402.443}
                x2={365.172}
                y2={-402.229}
                xlinkHref="#g"
            />
            <linearGradient
                id="aR"
                x1={366.402}
                y1={-401.334}
                x2={366.627}
                y2={-401.121}
                xlinkHref="#g"
            />
            <linearGradient
                id="aS"
                x1={367.843}
                y1={-400.219}
                x2={368.081}
                y2={-400.006}
                xlinkHref="#g"
            />
            <linearGradient
                id="aT"
                x1={369.297}
                y1={-399.096}
                x2={369.522}
                y2={-398.883}
                xlinkHref="#g"
            />
            <linearGradient
                id="aU"
                x1={370.751}
                y1={-397.991}
                x2={370.976}
                y2={-397.778}
                xlinkHref="#g"
            />
            <linearGradient
                id="aV"
                x1={372.192}
                y1={-396.872}
                x2={372.417}
                y2={-396.659}
                xlinkHref="#g"
            />
            <linearGradient
                id="aW"
                x1={373.646}
                y1={-395.759}
                x2={373.871}
                y2={-395.546}
                xlinkHref="#g"
            />
            <linearGradient
                id="aX"
                x1={375.087}
                y1={-394.644}
                x2={375.312}
                y2={-394.431}
                xlinkHref="#g"
            />
            <linearGradient
                id="aY"
                x1={376.541}
                y1={-392.767}
                x2={376.766}
                y2={-392.554}
                xlinkHref="#g"
            />
            <linearGradient
                id="aZ"
                x1={377.982}
                y1={-392.412}
                x2={378.22}
                y2={-392.199}
                xlinkHref="#g"
            />
            <linearGradient
                id="ba"
                x1={379.437}
                y1={-391.293}
                x2={379.661}
                y2={-391.08}
                xlinkHref="#g"
            />
            <linearGradient
                id="bb"
                x1={380.891}
                y1={-390.184}
                x2={381.116}
                y2={-389.971}
                xlinkHref="#g"
            />
            <linearGradient
                id="bc"
                x1={382.332}
                y1={-389.065}
                x2={382.556}
                y2={-388.852}
                xlinkHref="#g"
            />
            <linearGradient
                id="bd"
                x1={383.799}
                y1={-387.189}
                x2={384.024}
                y2={-386.976}
                xlinkHref="#g"
            />
            <linearGradient
                id="be"
                x1={355.699}
                y1={1180.464}
                x2={355.921}
                y2={1180.677}
                xlinkHref="#g"
            />
            <linearGradient
                id="bf"
                x1={357.126}
                y1={1181.611}
                x2={357.348}
                y2={1181.825}
                xlinkHref="#g"
            />
            <linearGradient
                id="bg"
                x1={358.566}
                y1={1182.702}
                x2={358.788}
                y2={1182.905}
                xlinkHref="#g"
            />
            <linearGradient
                id="bh"
                x1={359.993}
                y1={1183.821}
                x2={360.215}
                y2={1184.024}
                xlinkHref="#g"
            />
            <linearGradient
                id="bi"
                x1={361.433}
                y1={1184.948}
                x2={361.656}
                y2={1185.162}
                xlinkHref="#g"
            />
            <linearGradient
                id="bj"
                x1={362.86}
                y1={1183.724}
                x2={363.083}
                y2={1183.937}
                xlinkHref="#g"
            />
            <linearGradient
                id="bk"
                x1={364.3}
                y1={1187.021}
                x2={364.523}
                y2={1187.234}
                xlinkHref="#g"
            />
            <linearGradient
                id="bl"
                x1={365.74}
                y1={1188.295}
                x2={365.963}
                y2={1188.509}
                xlinkHref="#g"
            />
            <linearGradient
                id="bm"
                x1={367.167}
                y1={1189.414}
                x2={367.39}
                y2={1189.628}
                xlinkHref="#g"
            />
            <linearGradient
                id="bn"
                x1={368.608}
                y1={1190.523}
                x2={368.83}
                y2={1190.737}
                xlinkHref="#g"
            />
            <linearGradient
                id="bo"
                x1={370.035}
                y1={1191.614}
                x2={370.257}
                y2={1191.827}
                xlinkHref="#g"
            />
            <linearGradient
                id="bp"
                x1={371.475}
                y1={1192.751}
                x2={371.697}
                y2={1192.965}
                xlinkHref="#g"
            />
            <linearGradient
                id="bq"
                x1={372.902}
                y1={1191.521}
                x2={373.125}
                y2={1191.724}
                xlinkHref="#g"
            />
            <linearGradient
                id="br"
                x1={374.342}
                y1={1194.971}
                x2={374.565}
                y2={1195.174}
                xlinkHref="#g"
            />
            <linearGradient
                id="bs"
                x1={375.769}
                y1={1196.069}
                x2={376.005}
                y2={1196.283}
                xlinkHref="#g"
            />
            <linearGradient
                id="bt"
                x1={377.209}
                y1={1197.217}
                x2={377.432}
                y2={1197.431}
                xlinkHref="#g"
            />
            <linearGradient
                id="bu"
                x1={378.65}
                y1={1198.297}
                x2={378.872}
                y2={1198.511}
                xlinkHref="#g"
            />
            <linearGradient
                id="bv"
                x1={380.077}
                y1={1199.445}
                x2={380.299}
                y2={1199.659}
                xlinkHref="#g"
            />
            <linearGradient
                id="bw"
                x1={381.53}
                y1={1200.535}
                x2={381.752}
                y2={1200.749}
                xlinkHref="#g"
            />
            <linearGradient
                id="bx"
                x1={382.957}
                y1={1201.683}
                x2={383.179}
                y2={1201.897}
                xlinkHref="#g"
            />
            <linearGradient
                id="by"
                x1={122.759}
                y1={-54.44}
                x2={125.585}
                y2={-54.44}
                xlinkHref="#a"
            />
            <linearGradient
                id="bz"
                x1={-1550.633}
                y1={-91.768}
                x2={-1549.633}
                y2={-91.768}
                xlinkHref="#a"
            />
            <linearGradient
                id="j"
                x1={286.257}
                y1={-86.042}
                x2={290.009}
                y2={-86.042}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#3b3734" />
                <stop offset={0.14} stopColor="#494543" />
                <stop offset={0.32} stopColor="#55514f" />
                <stop offset={0.5} stopColor="#595654" />
                <stop offset={0.68} stopColor="#55514f" />
                <stop offset={0.86} stopColor="#494543" />
                <stop offset={1} stopColor="#3b3734" />
            </linearGradient>
            <linearGradient
                id="bA"
                x1={-3344.408}
                y1={-142.447}
                x2={-3343.478}
                y2={-142.447}
                xlinkHref="#a"
            />
            <linearGradient
                id="bB"
                x1={290.917}
                y1={-87.286}
                x2={294.67}
                y2={-87.286}
                xlinkHref="#j"
            />
            <linearGradient
                id="bC"
                x1={-3370.587}
                y1={-142.447}
                x2={-3369.65}
                y2={-142.447}
                xlinkHref="#a"
            />
            <linearGradient
                id="bD"
                x1={-1550.633}
                y1={-20.212}
                x2={-1549.633}
                y2={-20.212}
                xlinkHref="#a"
            />
            <linearGradient
                id="bE"
                x1={260.706}
                y1={-20.367}
                x2={277.422}
                y2={-20.367}
                xlinkHref="#j"
            />
            <linearGradient
                id="bF"
                x1={208.687}
                y1={-304.276}
                x2={208.687}
                y2={-303.264}
                xlinkHref="#b"
            />
            <linearGradient
                id="m"
                x1={208.293}
                y1={-303.75}
                x2={208.761}
                y2={-303.75}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.3} stopColor="#989697" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="bG"
                x1={105.024}
                y1={-50.81}
                x2={107.855}
                y2={-50.81}
                xlinkHref="#a"
            />
            <linearGradient
                id="k"
                x1={159.44}
                y1={-66.284}
                x2={162.359}
                y2={-66.582}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} />
                <stop offset={0.07} stopColor="#111010" />
                <stop offset={0.26} stopColor="#383634" />
                <stop offset={0.41} stopColor="#514d4b" />
                <stop offset={0.5} stopColor="#5a5654" />
                <stop offset={0.59} stopColor="#514d4b" />
                <stop offset={0.74} stopColor="#383634" />
                <stop offset={0.93} stopColor="#111010" />
                <stop offset={1} />
            </linearGradient>
            <linearGradient
                id="bH"
                x1={105.028}
                y1={-50.81}
                x2={107.858}
                y2={-50.81}
                xlinkHref="#j"
            />
            <linearGradient
                id="bI"
                x1={592.583}
                y1={1280.592}
                x2={594.541}
                y2={1280.592}
                xlinkHref="#b"
            />
            <linearGradient
                id="bJ"
                x1={106.433}
                y1={160.216}
                x2={109.268}
                y2={160.216}
                xlinkHref="#a"
            />
            <linearGradient
                id="bK"
                x1={162.298}
                y1={209.404}
                x2={165.224}
                y2={209.106}
                xlinkHref="#k"
            />
            <linearGradient
                id="bL"
                x1={106.429}
                y1={160.216}
                x2={109.264}
                y2={160.216}
                xlinkHref="#j"
            />
            <linearGradient
                id="bM"
                x1={82.382}
                y1={-78.958}
                x2={84.213}
                y2={-78.228}
                xlinkHref="#d"
            />
            <linearGradient
                id="bN"
                x1={82.182}
                y1={-78.906}
                x2={84.009}
                y2={-78.177}
                xlinkHref="#d"
            />
            <linearGradient
                id="bO"
                x1={-240.425}
                y1={-1521.778}
                x2={-240.142}
                y2={-1521.778}
                xlinkHref="#d"
            />
            <linearGradient
                id="bQ"
                x1={88.448}
                y1={274.96}
                x2={90.279}
                y2={275.689}
                xlinkHref="#d"
            />
            <linearGradient
                id="bR"
                x1={88.232}
                y1={274.69}
                x2={90.058}
                y2={275.419}
                xlinkHref="#d"
            />
            <linearGradient
                id="bS"
                x1={436.64}
                y1={794.719}
                x2={436.923}
                y2={794.719}
                xlinkHref="#d"
            />
            <linearGradient
                id="bT"
                x1={139.263}
                y1={1200.108}
                x2={139.263}
                y2={1200.738}
                xlinkHref="#m"
            />
            <radialGradient
                id="h"
                cx={0.375}
                cy={0.5}
                r={0.353}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#cfa68b" />
                <stop offset={0.11} stopColor="#ca9d80" />
                <stop offset={0.62} stopColor="#b87b54" />
                <stop offset={0.88} stopColor="#b16f43" />
            </radialGradient>
            <radialGradient
                id="aE"
                cx={3573.696}
                cy={-14.091}
                r={0.353}
                xlinkHref="#h"
            />
            <radialGradient
                id="aF"
                cx={3571.029}
                cy={-14.079}
                r={0.353}
                xlinkHref="#h"
            />
            <radialGradient
                id="aG"
                cx={0.493}
                cy={0.502}
                r={0.356}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#fff" />
                <stop offset={0.12} stopColor="#fcfaf9" />
                <stop offset={0.27} stopColor="#f6efea" />
                <stop offset={0.43} stopColor="#ecdcd1" />
                <stop offset={0.6} stopColor="#ddc1ae" />
                <stop offset={0.79} stopColor="#cb9f81" />
                <stop offset={0.97} stopColor="#b4754b" />
                <stop offset={1} stopColor="#b16f43" />
            </radialGradient>
            <radialGradient
                id="l"
                cx={0.091}
                cy={-4.581}
                r={5.875}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.87} stopColor="#989697" />
                <stop offset={0.94} stopColor="#eff3f6" />
                <stop offset={1} stopColor="#989697" />
            </radialGradient>
            <radialGradient
                id="bP"
                cx={33.457}
                cy={-112.572}
                r={5.879}
                xlinkHref="#l"
            />
        </defs>
        <g data-name="Group 106538">
            <path
                data-name="Path 99381"
                d="M10.6 156.68a.252.252 0 0 1 .263.249v3.38a.26.26 0 0 1-.263.249h-.607a.252.252 0 0 1-.263-.249v-3.38a.26.26 0 0 1 .263-.249Z"
                transform="translate(6.684 -86.071)"
                fill="url(#a)"
            />
            <g data-name="Group 106410">
                <path
                    data-name="Path 99382"
                    d="M14.82 157.72h.54a.519.519 0 0 1 .125.3v.693c0 .166-.055.3-.125.3h-.54v-1.288Z"
                    transform="translate(10.105 -85.975)"
                    fill="url(#b)"
                />
                <path
                    data-name="Path 99383"
                    d="M25.313 71.994v.8a.246.246 0 0 0 .166.235h-.568v-1.284h.568a.241.241 0 0 0-.18.235Z"
                    opacity={0.3}
                />
            </g>
            <path
                data-name="Path 99384"
                d="M13.233 156.68a.252.252 0 0 0-.263.249v3.38a.26.26 0 0 0 .263.249h.609a.252.252 0 0 0 .263-.249v-3.38a.252.252 0 0 0-.263-.249Z"
                transform="translate(8.904 -86.071)"
                fill="url(#n)"
            />
            <g data-name="Group 106414">
                <g data-name="Group 106411">
                    <path
                        data-name="Path 99385"
                        d="M14.114 170.425v6.039a.535.535 0 0 0 .582.457h.277l.014-6.094a.54.54 0 0 0-.6-.457l-.291.055Z"
                        transform="translate(9.451 -121.394)"
                        fill="url(#o)"
                    />
                </g>
                <path
                    data-name="Path 99387"
                    d="M13.32 174.17h.914c.111 0 .208.18.208.4v.914c0 .222-.1.4-.208.4h-.914v-1.7Z"
                    transform="translate(9.151 -125.624)"
                    fill="url(#p)"
                />
                <path
                    data-name="Path 99388"
                    d="M12.927 173.67c-.152 0-.277.1-.277.208v2.812c0 .111.125.208.277.208h.609a.239.239 0 0 0 .263-.208v-2.812a.248.248 0 0 0-.263-.208Z"
                    transform="translate(8.893 -125.941)"
                    fill="url(#q)"
                />
                <g data-name="Group 106413">
                    <path
                        data-name="Path 99389"
                        d="M18.689 171.089a1.181 1.181 0 0 1 .679.415c.263.332-.249.18-.72.222s-2.355-.014-2.548-.014-.429 0-.277-.139-.346.166-.5.3 1 .277 1 .277l3.989.083-.554-1.094a2.2 2.2 0 0 0-1.066-.042Z"
                        transform="translate(9.918 -117.653)"
                        fill="url(#c)"
                    />
                    <path
                        data-name="Path 99390"
                        d="M19.089 169.95a1.511 1.511 0 0 1 .36.055c.083.028-.1.222-.263.319s-.457.083-1.288.111-1.648-.014-1.759-.028a.419.419 0 0 1-.388-.18 2.332 2.332 0 0 0-.291-.332l.3.97 3.67.139.789-1.233-1.136.194Z"
                        transform="translate(9.975 -114.645)"
                        fill="url(#r)"
                    />
                    <path
                        data-name="Path 99391"
                        d="M19.08 170.47h.54a.519.519 0 0 1 .125.3v.693c0 .166-.055.3-.125.3h-.54v-1.288Z"
                        transform="translate(11.369 -116.383)"
                        fill="url(#s)"
                    />
                    <path
                        data-name="Path 99392"
                        d="M30.837 54.322v.8a.246.246 0 0 0 .166.235h-.568v-1.284h.568a.241.241 0 0 0-.18.235Z"
                        opacity={0.3}
                    />
                    <path
                        data-name="Path 99393"
                        d="M20.419 169.929c.291.4.554.859.554.859v1.468s-.263.457-.554.859a1.037 1.037 0 0 1-.928.485h-3.463c-.443 0-.554-.194-.845-.637a6.19 6.19 0 0 1-.443-.817v-1.26a9.179 9.179 0 0 1 .443-.817c.291-.443.4-.637.845-.637h3.463a1.069 1.069 0 0 1 .928.485Zm-4.519 2.146a5.891 5.891 0 0 0 .346.554c.1.111.194.139.6.139h2.382a.681.681 0 0 0 .582-.319c.139-.194.249-.36.249-.36H15.9Zm.942-1.8c-.4 0-.512.028-.6.139a5.893 5.893 0 0 0-.346.554h4.155l-.249-.374a.681.681 0 0 0-.582-.319Z"
                        transform="translate(9.698 -116.77)"
                        fill="url(#t)"
                    />
                    <g data-name="Group 106412">
                        <path
                            data-name="Path 99394"
                            d="M20.419 169.929c.291.4.554.859.554.859v1.468s-.263.457-.554.859a1.037 1.037 0 0 1-.928.485h-3.463c-.443 0-.554-.194-.845-.637a6.19 6.19 0 0 1-.443-.817v-1.26a9.179 9.179 0 0 1 .443-.817c.291-.443.4-.637.845-.637h3.463a1.069 1.069 0 0 1 .928.485Zm-4.39-.36c-.374 0-.443.125-.72.554l-.028.042a8.319 8.319 0 0 0-.429.776v1.2a5.838 5.838 0 0 0 .429.776l.028.042c.263.415.346.54.72.54h3.463a.9.9 0 0 0 .817-.443 8.391 8.391 0 0 0 .526-.817v-1.413a9.556 9.556 0 0 0-.526-.817.928.928 0 0 0-.817-.429h-3.464Z"
                            transform="translate(9.698 -116.77)"
                            opacity={0.3}
                            fill="url(#d)"
                        />
                        <path
                            data-name="Path 99395"
                            d="M19.65 170.494a.793.793 0 0 1-.693.374h-2.381c-.4 0-.568-.028-.693-.18a4.177 4.177 0 0 1-.346-.568l-.1-.18h4.584l-.125.194s-.111.18-.249.374Zm-4.017-.443a5.89 5.89 0 0 0 .346.554c.1.111.194.139.6.139h2.382a.681.681 0 0 0 .582-.319c.139-.194.249-.36.249-.36h-4.158Z"
                            transform="translate(9.968 -114.745)"
                            opacity={0.3}
                            fill="url(#u)"
                        />
                        <path
                            data-name="Path 99396"
                            d="M19.65 171.6c.139.194.249.374.249.374l.125.194H15.44l.1-.18s.249-.457.346-.582c.125-.152.291-.18.693-.18h2.382a.827.827 0 0 1 .689.374Zm-3.075-.249c-.4 0-.512.028-.6.139a5.89 5.89 0 0 0-.346.554h4.155l-.249-.374a.681.681 0 0 0-.582-.319Z"
                            transform="translate(9.968 -117.836)"
                            opacity={0.3}
                            fill="url(#v)"
                        />
                    </g>
                    <path
                        data-name="Path 99397"
                        d="M15.65 170.736s2.853.028 3.352.028a.884.884 0 0 0 .928-.443c.222-.374.457-.72.457-.72a6.49 6.49 0 0 1-.679.651c-.208.152-.263.346-1.454.346s-2.6.152-2.6.152Z"
                        transform="translate(10.048 -114.17)"
                        opacity={0.5}
                        fill="url(#e)"
                    />
                    <path
                        data-name="Path 99398"
                        d="M19.637 171.448s-2.853-.028-3.352-.028a.884.884 0 0 0-.928.443c-.235.374-.457.72-.457.72a6.49 6.49 0 0 1 .679-.651c.222-.152.263-.332 1.468-.332s2.6-.152 2.6-.152Z"
                        transform="translate(9.76 -118.511)"
                        opacity={0.5}
                        fill="url(#w)"
                    />
                    <path
                        data-name="Path 99399"
                        d="M20.689 171.561v-1.191s.014.457-.249.5-5.125.083-5.263.083-.3-.291-.346-.4v1.047s.111-.36.277-.388 5.014-.055 5.235-.014.346.374.346.374Z"
                        transform="translate(9.733 -116.076)"
                        opacity={0.5}
                        fill="url(#f)"
                    />
                    <path
                        data-name="Path 99400"
                        d="M20.729 171.837v-1.177s0 .457-.249.5-5.125.069-5.263.069-.3-.291-.346-.4v1.053s.111-.36.277-.388 5.014-.055 5.235-.014.346.374.346.374Z"
                        transform="translate(9.748 -116.753)"
                        opacity={0.5}
                        fill="url(#x)"
                    />
                </g>
            </g>
            <g data-name="Group 106418">
                <g data-name="Group 106415">
                    <path
                        data-name="Path 99403"
                        d="M9.613 182.3v-7.2a.535.535 0 0 0-.582-.457h-.277l-.014 7.271a.535.535 0 0 0 .582.457l.291-.055Z"
                        transform="translate(6.584 -132.756)"
                        fill="url(#y)"
                    />
                </g>
                <path
                    data-name="Path 99405"
                    d="M10.482 176.067h-.914c-.111 0-.208-.18-.208-.4v-.914c0-.222.1-.4.208-.4h.914v1.7Z"
                    transform="translate(6.823 -126.054)"
                    fill="url(#z)"
                />
                <path
                    data-name="Path 99406"
                    d="M10.873 176.977a.239.239 0 0 0 .263-.208v-2.812a.248.248 0 0 0-.263-.208h-.609a.239.239 0 0 0-.263.208v2.812a.248.248 0 0 0 .263.208Z"
                    transform="translate(7.069 -126.132)"
                    fill="url(#A)"
                />
                <g data-name="Group 106417">
                    <path
                        data-name="Path 99407"
                        d="M6.18 179.832a1.181 1.181 0 0 1-.679-.416c-.263-.332.249-.18.72-.222s2.355.014 2.548.014.429 0 .277.139.36-.166.5-.3-1-.277-1-.277l-3.989-.083.554 1.094a2.2 2.2 0 0 0 1.066.042Z"
                        transform="translate(4.975 -135.857)"
                        fill="url(#B)"
                    />
                    <path
                        data-name="Path 99408"
                        d="M5.72 180.969a2.913 2.913 0 0 1-.36-.055c-.083-.028.1-.222.263-.319a3.763 3.763 0 0 1 1.274-.111 17.64 17.64 0 0 1 1.759.028.419.419 0 0 1 .388.18 3.09 3.09 0 0 0 .291.346l-.3-.97-3.67-.139-.789 1.233 1.136-.194Z"
                        transform="translate(4.978 -138.877)"
                        fill="url(#C)"
                    />
                    <path
                        data-name="Path 99409"
                        d="M4.581 180.482h-.54a.55.55 0 0 1-.111-.3v-.693c0-.166.055-.3.111-.3h.54v1.288Z"
                        transform="translate(4.732 -137.158)"
                        fill="url(#D)"
                    />
                    <path
                        data-name="Path 99410"
                        d="M8.925 43.075v-.8a.23.23 0 0 0-.166-.239h.568v1.288h-.568a.241.241 0 0 0 .18-.235Z"
                        opacity={0.3}
                    />
                    <path
                        data-name="Path 99411"
                        d="M4.794 181.83c-.291-.4-.554-.859-.554-.859V179.5s.263-.457.554-.859a1.053 1.053 0 0 1 .928-.485h3.462c.457 0 .554.194.845.637a6.189 6.189 0 0 1 .443.817v1.26a6.189 6.189 0 0 1-.443.817c-.291.457-.388.637-.845.637H5.722a1.069 1.069 0 0 1-.928-.485Zm4.515-2.147a5.892 5.892 0 0 0-.346-.554c-.1-.111-.194-.139-.6-.139H5.985a.681.681 0 0 0-.582.319c-.139.194-.249.374-.249.374Zm-.942 1.8c.4 0 .512-.028.6-.139a5.894 5.894 0 0 0 .346-.554H5.154l.249.374a.651.651 0 0 0 .582.319Z"
                        transform="translate(4.851 -137.592)"
                        fill="url(#E)"
                    />
                    <g data-name="Group 106416">
                        <path
                            data-name="Path 99412"
                            d="M8.871 180.184a4.176 4.176 0 0 1 .346.568l.1.18H4.73l.125-.194.249-.374a.81.81 0 0 1 .693-.374h2.382c.4 0 .568.028.693.18Zm-3.089-.055a.681.681 0 0 0-.582.319c-.139.194-.249.374-.249.374h4.156a5.892 5.892 0 0 0-.346-.554c-.1-.111-.194-.139-.6-.139Z"
                            transform="translate(5.04 -138.729)"
                            opacity={0.3}
                            fill="url(#F)"
                        />
                        <path
                            data-name="Path 99413"
                            d="M8.871 179.452c-.139.152-.3.18-.693.18H5.8a.827.827 0 0 1-.693-.374c-.139-.194-.249-.374-.249-.374l-.125-.194h4.581l-.1.18s-.246.457-.343.582Zm-3.92-.637.249.374a.651.651 0 0 0 .582.319h2.383c.4 0 .512-.028.6-.139a5.894 5.894 0 0 0 .346-.554Z"
                            transform="translate(5.04 -135.629)"
                            opacity={0.3}
                            fill="url(#G)"
                        />
                        <path
                            data-name="Path 99414"
                            d="M10.029 178.793a6.189 6.189 0 0 1 .443.817v1.26a6.189 6.189 0 0 1-.443.817c-.291.457-.388.637-.845.637H5.722a1.069 1.069 0 0 1-.928-.485c-.291-.4-.554-.859-.554-.859v-1.468s.263-.457.554-.859a1.053 1.053 0 0 1 .928-.485h3.462c.457 0 .554.194.845.637Zm-4.307-.512a.911.911 0 0 0-.817.443 8.389 8.389 0 0 0-.526.817v1.413a9.563 9.563 0 0 0 .526.817.941.941 0 0 0 .817.443h3.462c.374 0 .457-.125.72-.554l.028-.042a8.321 8.321 0 0 0 .429-.776v-1.2a8.317 8.317 0 0 0-.429-.776l-.028-.042c-.263-.416-.346-.554-.72-.554H5.722Z"
                            transform="translate(4.851 -137.602)"
                            opacity={0.3}
                            fill="url(#H)"
                        />
                    </g>
                    <path
                        data-name="Path 99415"
                        d="M9.147 180.188s-2.853-.028-3.352-.028a.884.884 0 0 0-.928.443c-.235.374-.457.72-.457.72a6.49 6.49 0 0 1 .679-.651c.222-.152.263-.346 1.454-.346s2.6-.152 2.6-.152Z"
                        transform="translate(4.917 -139.357)"
                        opacity={0.5}
                        fill="url(#I)"
                    />
                    <path
                        data-name="Path 99416"
                        d="M5.174 179.476s2.853.028 3.352.028a.884.884 0 0 0 .928-.443c.235-.374.457-.72.457-.72a6.488 6.488 0 0 1-.679.651c-.222.152-.263.346-1.468.346s-2.6.152-2.6.152Z"
                        transform="translate(5.206 -135.016)"
                        opacity={0.5}
                        fill="url(#J)"
                    />
                    <path
                        data-name="Path 99417"
                        d="M4.42 179.358v1.191s-.014-.457.249-.5 5.125-.069 5.263-.069.3.291.346.4v-1.05s-.111.346-.277.388-5.014.055-5.235 0-.346-.374-.346-.374Z"
                        transform="translate(4.921 -137.432)"
                        opacity={0.5}
                        fill="url(#K)"
                    />
                    <path
                        data-name="Path 99418"
                        d="M4.39 179.078v1.191s-.014-.457.249-.485 5.125-.084 5.261-.084.3.291.346.4v-1.05s-.111.36-.277.388-5.014.055-5.235 0-.332-.374-.332-.374Z"
                        transform="translate(4.909 -136.765)"
                        opacity={0.5}
                        fill="url(#L)"
                    />
                </g>
            </g>
            <g data-name="Group 106467">
                <path
                    data-name="Path 99421"
                    d="M12.618 186.285h.152a1.643 1.643 0 0 1 1.648 1.648v30.055a1.643 1.643 0 0 1-1.648 1.648h-.152a.293.293 0 0 1-.222.125.3.3 0 0 1-.235-.125h-.139a1.655 1.655 0 0 1-1.662-1.648v-30.055a1.655 1.655 0 0 1 1.662-1.648h.139a.238.238 0 0 1 .222-.125.272.272 0 0 1 .235.125Z"
                    transform="translate(7.432 -186.105)"
                    fill="url(#M)"
                />
                <path
                    data-name="Path 99422"
                    d="M11.66 186.275v33.365a.3.3 0 0 0 .235.125.263.263 0 0 0 .222-.139v-33.351a.272.272 0 0 0-.235-.125.238.238 0 0 0-.222.125Z"
                    transform="translate(7.932 -186.095)"
                    opacity={0.7}
                    fill="url(#N)"
                />
                <path
                    data-name="Path 99423"
                    d="M14.422 187.888v30.055a1.643 1.643 0 0 1-1.648 1.648h-.762a1.655 1.655 0 0 1-1.662-1.648v-30.055a1.655 1.655 0 0 1 1.662-1.648h.762a1.643 1.643 0 0 1 1.648 1.648Zm-2.258.485c-.554 0-1 .637-1 1.427v25.845c0 .79.443 1.427 1 1.427h.457a1.564 1.564 0 0 0 1.385-1.427V189.8a1.576 1.576 0 0 0-1.385-1.427Z"
                    transform="translate(7.428 -186.046)"
                    opacity={0.3}
                    fill="url(#O)"
                />
                <g data-name="Group 106419">
                    <path
                        data-name="Path 99424"
                        d="M12.128 187.38c-.512 0-.928 6.717-.928 14.986s.416 15 .928 15 .928-6.717.928-15-.416-14.986-.928-14.986Z"
                        transform="translate(7.755 -185.399)"
                        opacity={0.4}
                        fill="url(#i)"
                    />
                </g>
                <g data-name="Group 106441" opacity={0.6}>
                    <g data-name="Group 106420">
                        <path
                            data-name="Path 99425"
                            d="M12 186.791a4.083 4.083 0 0 1 .554.554c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -155.336)"
                            fill="url(#g)"
                        />
                        <path
                            data-name="Path 99426"
                            d="M20.063 31.457v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.055-.166-.4-.485-.789a3.415 3.415 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106421">
                        <path
                            data-name="Path 99427"
                            d="M12 187.891a4.083 4.083 0 0 1 .554.554c.319.388.54.748.485.79s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -157.96)"
                            fill="url(#P)"
                        />
                        <path
                            data-name="Path 99428"
                            d="M20.063 29.933v-.028a3.193 3.193 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.416 3.416 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106422">
                        <path
                            data-name="Path 99429"
                            d="M12 188.957a3.616 3.616 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -160.535)"
                            fill="url(#Q)"
                        />
                        <path
                            data-name="Path 99430"
                            d="M20.063 28.422v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.372 4.372 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106423">
                        <path
                            data-name="Path 99431"
                            d="M12 190.081a4.081 4.081 0 0 1 .554.554c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -163.183)"
                            fill="url(#R)"
                        />
                        <path
                            data-name="Path 99432"
                            d="M20.063 26.898v-.028a3.2 3.2 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.79a3.416 3.416 0 0 0-.554-.555Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106424">
                        <path
                            data-name="Path 99433"
                            d="M12 191.147a3.616 3.616 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -165.759)"
                            fill="url(#S)"
                        />
                        <path
                            data-name="Path 99434"
                            d="M20.063 25.388v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.831.457.831.055-.042-.166-.4-.485-.789a4.375 4.375 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106425">
                        <path
                            data-name="Path 99435"
                            d="M12 192.247a3.616 3.616 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -168.382)"
                            fill="url(#T)"
                        />
                        <path
                            data-name="Path 99436"
                            d="M20.063 23.865v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.055-.166-.4-.485-.789a4.373 4.373 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106426">
                        <path
                            data-name="Path 99437"
                            d="M12 193.369a4.083 4.083 0 0 1 .554.554c.319.388.54.748.485.79s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -171.028)"
                            fill="url(#U)"
                        />
                        <path
                            data-name="Path 99438"
                            d="M20.063 22.341v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.415 3.415 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106427">
                        <path
                            data-name="Path 99439"
                            d="M12 194.437a3.615 3.615 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -173.605)"
                            fill="url(#V)"
                        />
                        <path
                            data-name="Path 99440"
                            d="M20.063 20.832v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.375 4.375 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106428">
                        <path
                            data-name="Path 99441"
                            d="M12 195.561a4.08 4.08 0 0 1 .554.554c.319.388.54.748.485.789s-.36-.235-.693-.623a6.582 6.582 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.063 -176.253)"
                            fill="url(#W)"
                        />
                        <path
                            data-name="Path 99442"
                            d="M20.063 19.308v-.028a3.2 3.2 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.415 3.415 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106429">
                        <path
                            data-name="Path 99443"
                            d="M12 196.625a3.617 3.617 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623a6.582 6.582 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.063 -178.827)"
                            fill="url(#X)"
                        />
                        <path
                            data-name="Path 99444"
                            d="M20.063 17.798v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.79a4.371 4.371 0 0 0-.554-.567Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106430">
                        <path
                            data-name="Path 99445"
                            d="M12 197.751a4.084 4.084 0 0 1 .554.554c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -181.476)"
                            fill="url(#Y)"
                        />
                        <path
                            data-name="Path 99446"
                            d="M20.063 16.275v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.414 3.414 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106431">
                        <path
                            data-name="Path 99447"
                            d="M12 198.817a3.615 3.615 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -184.052)"
                            fill="url(#Z)"
                        />
                        <path
                            data-name="Path 99448"
                            d="M20.063 14.765v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.831.457.831.055-.055-.166-.4-.485-.789a4.371 4.371 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106432">
                        <path
                            data-name="Path 99449"
                            d="M12 199.917a3.617 3.617 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623a6.582 6.582 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.063 -186.675)"
                            fill="url(#aa)"
                        />
                        <path
                            data-name="Path 99450"
                            d="M20.063 13.242v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.79a4.372 4.372 0 0 0-.554-.567Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106433">
                        <path
                            data-name="Path 99451"
                            d="M12 201.017a3.615 3.615 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -189.299)"
                            fill="url(#ab)"
                        />
                        <path
                            data-name="Path 99452"
                            d="M20.063 11.718v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.375 4.375 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106434">
                        <path
                            data-name="Path 99453"
                            d="M12 202.107a3.614 3.614 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -191.899)"
                            fill="url(#ac)"
                        />
                        <path
                            data-name="Path 99454"
                            d="M20.063 10.208v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.831.457.831.055-.042-.166-.4-.485-.789a4.375 4.375 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106435">
                        <path
                            data-name="Path 99455"
                            d="M12 203.207a3.613 3.613 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623a6.582 6.582 0 0 1-.346-.457Z"
                            transform="translate(8.063 -194.522)"
                            fill="url(#ad)"
                        />
                        <path
                            data-name="Path 99456"
                            d="M20.063 8.685v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.372 4.372 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106436">
                        <path
                            data-name="Path 99457"
                            d="M12 204.3a3.616 3.616 0 0 1 .554.568c.319.388.54.748.485.79s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.278Z"
                            transform="translate(8.063 -197.12)"
                            fill="url(#ae)"
                        />
                        <path
                            data-name="Path 99458"
                            d="M20.063 7.175v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.372 4.372 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106437">
                        <path
                            data-name="Path 99459"
                            d="M12 205.421a4.084 4.084 0 0 1 .554.554c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -199.769)"
                            fill="url(#af)"
                        />
                        <path
                            data-name="Path 99460"
                            d="M20.063 5.652v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.79a3.415 3.415 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106438">
                        <path
                            data-name="Path 99461"
                            d="M12 206.487a3.615 3.615 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -202.345)"
                            fill="url(#ag)"
                        />
                        <path
                            data-name="Path 99462"
                            d="M20.063 4.142v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.375 4.375 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106439">
                        <path
                            data-name="Path 99463"
                            d="M12 207.6a3.616 3.616 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.235-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -204.993)"
                            fill="url(#ah)"
                        />
                        <path
                            data-name="Path 99464"
                            d="M20.063 2.604v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.79a4.375 4.375 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106440">
                        <path
                            data-name="Path 99465"
                            d="M12 208.695a3.616 3.616 0 0 1 .554.568c.319.388.54.748.485.789s-.36-.222-.693-.623c-.139-.166-.249-.332-.346-.457v-.277Z"
                            transform="translate(8.063 -207.614)"
                            fill="url(#ai)"
                        />
                        <path
                            data-name="Path 99466"
                            d="M20.063 1.081v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.372 4.372 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Group 106463" opacity={0.6}>
                    <g data-name="Group 106442">
                        <path
                            data-name="Path 99467"
                            d="M11.971 186.8a4.083 4.083 0 0 0-.554.554c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -155.36)"
                            fill="url(#aj)"
                        />
                        <path
                            data-name="Path 99468"
                            d="M19.619 31.441v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.055.166-.4.485-.789a3.415 3.415 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106443">
                        <path
                            data-name="Path 99469"
                            d="M11.971 187.9a4.084 4.084 0 0 0-.554.554c-.319.388-.54.748-.485.789s.374-.235.693-.623a3.976 3.976 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -157.984)"
                            fill="url(#ak)"
                        />
                        <path
                            data-name="Path 99470"
                            d="M19.619 29.917v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a3.416 3.416 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106444">
                        <path
                            data-name="Path 99471"
                            d="M11.971 189a4.084 4.084 0 0 0-.554.554c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.71 5.71 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -160.607)"
                            fill="url(#al)"
                        />
                        <path
                            data-name="Path 99472"
                            d="M19.619 28.394v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a3.416 3.416 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106445">
                        <path
                            data-name="Path 99473"
                            d="M11.971 190.089a4.083 4.083 0 0 0-.554.554c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.71 5.71 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -163.205)"
                            fill="url(#am)"
                        />
                        <path
                            data-name="Path 99474"
                            d="M19.619 26.886v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a3.415 3.415 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106446">
                        <path
                            data-name="Path 99475"
                            d="M11.971 191.191a4.083 4.083 0 0 0-.554.554c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -165.83)"
                            fill="url(#an)"
                        />
                        <path
                            data-name="Path 99476"
                            d="M19.619 25.363v-.028a3.2 3.2 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a3.415 3.415 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106447">
                        <path
                            data-name="Path 99477"
                            d="M11.971 192.281a4.084 4.084 0 0 0-.554.554c-.319.388-.54.748-.485.789s.374-.235.693-.623a3.979 3.979 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -168.43)"
                            fill="url(#ao)"
                        />
                        <path
                            data-name="Path 99478"
                            d="M19.619 23.851v-.028a3.193 3.193 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a3.416 3.416 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106448">
                        <path
                            data-name="Path 99479"
                            d="M11.971 193.357a3.617 3.617 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -171.03)"
                            fill="url(#ap)"
                        />
                        <path
                            data-name="Path 99480"
                            d="M19.619 22.327v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.055.166-.4.485-.79a4.372 4.372 0 0 1 .554-.567Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106449">
                        <path
                            data-name="Path 99481"
                            d="M11.971 194.447a3.617 3.617 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -173.629)"
                            fill="url(#aq)"
                        />
                        <path
                            data-name="Path 99482"
                            d="M19.619 20.818v-.028a3.193 3.193 0 0 0-.582.568c-.609.693-.457.831-.457.831-.055-.055.166-.4.485-.789a4.373 4.373 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106450">
                        <path
                            data-name="Path 99483"
                            d="M11.971 195.545a3.615 3.615 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.713 5.713 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -176.251)"
                            fill="url(#ar)"
                        />
                        <path
                            data-name="Path 99484"
                            d="M19.619 19.294v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a4.375 4.375 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106451">
                        <path
                            data-name="Path 99485"
                            d="M11.971 196.637a3.614 3.614 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.707 5.707 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -178.853)"
                            fill="url(#as)"
                        />
                        <path
                            data-name="Path 99486"
                            d="M19.619 17.784v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a4.374 4.374 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106452">
                        <path
                            data-name="Path 99487"
                            d="M11.971 197.761a4.081 4.081 0 0 0-.554.554c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -181.5)"
                            fill="url(#at)"
                        />
                        <path
                            data-name="Path 99488"
                            d="M19.619 16.261v-.028a3.2 3.2 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.055.166-.4.485-.789a3.415 3.415 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106453">
                        <path
                            data-name="Path 99489"
                            d="M11.971 198.837a3.617 3.617 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -184.1)"
                            fill="url(#au)"
                        />
                        <path
                            data-name="Path 99490"
                            d="M19.619 14.737v-.028a3.2 3.2 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.79a4.372 4.372 0 0 1 .554-.567Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106454">
                        <path
                            data-name="Path 99491"
                            d="M11.971 199.927a3.617 3.617 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a3.979 3.979 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -186.699)"
                            fill="url(#av)"
                        />
                        <path
                            data-name="Path 99492"
                            d="M19.619 13.228V13.2a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.055.166-.4.485-.79a4.375 4.375 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106455">
                        <path
                            data-name="Path 99493"
                            d="M11.971 201.027a3.617 3.617 0 0 0-.554.568c-.319.388-.54.748-.485.79s.374-.235.693-.623a5.707 5.707 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -189.323)"
                            fill="url(#aw)"
                        />
                        <path
                            data-name="Path 99494"
                            d="M19.619 11.704v-.028a3.2 3.2 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.79a4.371 4.371 0 0 1 .554-.567Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106456">
                        <path
                            data-name="Path 99495"
                            d="M11.971 202.117a3.615 3.615 0 0 0-.554.568c-.319.388-.54.748-.485.79s.374-.235.693-.623a5.707 5.707 0 0 0 .346-.457Z"
                            transform="translate(7.649 -191.923)"
                            fill="url(#ax)"
                        />
                        <path
                            data-name="Path 99496"
                            d="M19.619 10.194v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a4.371 4.371 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106457">
                        <path
                            data-name="Path 99497"
                            d="M11.971 203.217a3.614 3.614 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a3.978 3.978 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -194.546)"
                            fill="url(#ay)"
                        />
                        <path
                            data-name="Path 99498"
                            d="M19.619 8.671v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a4.375 4.375 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106458">
                        <path
                            data-name="Path 99499"
                            d="M11.971 204.317a3.616 3.616 0 0 0-.554.568c-.319.388-.54.748-.485.79s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457Z"
                            transform="translate(7.649 -197.17)"
                            fill="url(#az)"
                        />
                        <path
                            data-name="Path 99500"
                            d="M19.619 7.147v-.028a3.194 3.194 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.789a4.373 4.373 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106459">
                        <path
                            data-name="Path 99501"
                            d="M11.971 205.407a4.081 4.081 0 0 0-.554.554c-.319.388-.54.748-.485.8s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457Z"
                            transform="translate(7.649 -199.769)"
                            fill="url(#aA)"
                        />
                        <path
                            data-name="Path 99502"
                            d="M19.619 5.638V5.61a3.2 3.2 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.055.166-.4.485-.8a3.415 3.415 0 0 1 .554-.557Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106460">
                        <path
                            data-name="Path 99503"
                            d="M11.971 206.505a3.617 3.617 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a5.707 5.707 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -202.391)"
                            fill="url(#aB)"
                        />
                        <path
                            data-name="Path 99504"
                            d="M19.619 4.114v-.028a3.2 3.2 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.79a4.371 4.371 0 0 1 .554-.567Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106461">
                        <path
                            data-name="Path 99505"
                            d="M11.971 207.607a3.614 3.614 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a3.977 3.977 0 0 0 .346-.457Z"
                            transform="translate(7.649 -205.016)"
                            fill="url(#aC)"
                        />
                        <path
                            data-name="Path 99506"
                            d="M19.619 2.591v-.028a3.194 3.194 0 0 0-.582.568c-.609.679-.457.817-.457.817-.055-.042.166-.4.485-.789a4.372 4.372 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106462">
                        <path
                            data-name="Path 99507"
                            d="M11.971 208.707a3.616 3.616 0 0 0-.554.568c-.319.388-.54.748-.485.789s.374-.235.693-.623a3.977 3.977 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.649 -207.64)"
                            fill="url(#aD)"
                        />
                        <path
                            data-name="Path 99508"
                            d="M19.619 1.067v-.028a3.2 3.2 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.485-.79a4.372 4.372 0 0 1 .554-.567Z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <path
                    data-name="Path 99509"
                    d="M11.745 219.616s0-.526.028-1.44c0-.457.014-1.011.028-1.648v-27.2c0-.637-.014-1.191-.028-1.648 0-.914-.028-1.427-.028-1.427s0 .526-.014 1.427c0 .457-.014 1.011-.028 1.648 0 .319 0 .651-.014 1.011v25.18c0 .36 0 .693.014 1.011 0 .637.014 1.191.028 1.648.001.911.014 1.438.014 1.438Z"
                    transform="translate(7.944 -186.084)"
                    opacity={0.5}
                    fill="url(#h)"
                />
                <path
                    data-name="Path 99510"
                    d="M11.7 219.616s0-.526.014-1.44c0-.457.014-1.011.028-1.648 0-.319 0-.651.014-1.011v-25.18c0-.36 0-.693-.014-1.011 0-.637-.014-1.191-.028-1.648 0-.914-.014-1.427-.014-1.427s0 .526-.028 1.427c0 .457 0 1.011-.028 1.648 0 .319 0 .651-.014 1.011v25.18c0 .36 0 .693.014 1.011 0 .637.014 1.191.028 1.648 0 .913.028 1.44.028 1.44Z"
                    transform="translate(7.921 -186.084)"
                    opacity={0.5}
                    fill="url(#aE)"
                />
                <path
                    data-name="Path 99511"
                    d="M11.949 219.636s0-.526.014-1.44c0-.457 0-1.011.028-1.648 0-.319 0-.651.014-1.011V190.37c0-.36 0-.693-.014-1.011 0-.637-.014-1.191-.028-1.648 0-.914-.014-1.44-.014-1.44s0 .526-.028 1.44c0 .457 0 1.011-.028 1.648 0 .319 0 .651-.014 1.011v25.166c0 .36 0 .693.014 1.011 0 .637.014 1.191.028 1.648.014.914.028 1.441.028 1.441Z"
                    transform="translate(8.017 -186.131)"
                    opacity={0.3}
                    fill="url(#aF)"
                />
                <path
                    data-name="Path 99512"
                    d="M20.049 33.532s0-.526.028-1.44c0-.457 0-1.011.028-1.648 0-.319 0-.651.014-1.011V4.253c0-.36 0-.693-.014-1.011 0-.637-.014-1.191-.028-1.648 0-.914-.028-1.427-.028-1.427s0 .526-.014 1.427c0 .457-.014 1.011-.028 1.648 0 .319 0 .651-.014 1.011v26.191c0 .637.014 1.191.028 1.648 0 .914.014 1.44.014 1.44Z"
                    fill="#fff"
                    opacity={0.5}
                />
                <path
                    data-name="Path 99513"
                    d="M12.606 186.38h.152a1.643 1.643 0 0 1 1.648 1.648v30.055a1.643 1.643 0 0 1-1.648 1.648h-.152a.1.1 0 0 1-.042.042l-.028.014a.043.043 0 0 0-.028.014h-.083a.3.3 0 0 1-.235-.125h-.139a1.655 1.655 0 0 1-1.662-1.648v-30.055a1.655 1.655 0 0 1 1.662-1.648h.139a.238.238 0 0 1 .222-.125.272.272 0 0 1 .235.125Z"
                    transform="translate(7.443 -186.2)"
                    opacity={0.6}
                    fill="url(#aG)"
                />
                <g data-name="Group 106464">
                    <path
                        data-name="Path 99514"
                        d="M12.69 187.3c-.194 0-.36 7.064-.36 15.762s.166 15.762.36 15.762.36-7.064.36-15.762-.166-15.762-.36-15.762Z"
                        transform="translate(8.19 -186.746)"
                        opacity={0.5}
                        fill="url(#aH)"
                    />
                </g>
                <g data-name="Group 106465">
                    <path
                        data-name="Path 99515"
                        d="M12.632 187.3c-.083 0-.152 7.064-.152 15.762s.069 15.762.152 15.762.152-7.064.152-15.762-.069-15.762-.152-15.762Z"
                        transform="translate(8.248 -186.746)"
                        fill="url(#aI)"
                    />
                </g>
                <g data-name="Group 106466">
                    <path
                        data-name="Path 99516"
                        d="M12.722 187.31c-.083 0-.152 7.05-.152 15.762s.069 15.762.152 15.762.152-7.05.152-15.762-.069-15.762-.152-15.762Z"
                        transform="translate(8.283 -186.77)"
                        fill="url(#aJ)"
                    />
                </g>
            </g>
            <path
                data-name="Path 99517"
                d="M20.056.18h.152a1.643 1.643 0 0 1 1.648 1.648v30.055a1.643 1.643 0 0 1-1.648 1.648h-.152a.1.1 0 0 1-.042.042l-.028.014a.043.043 0 0 0-.028.014h-.083a.3.3 0 0 1-.235-.125h-.139a1.655 1.655 0 0 1-1.662-1.648V1.773A1.655 1.655 0 0 1 19.501.125h.139A.238.238 0 0 1 19.862 0a.272.272 0 0 1 .235.125Z"
                fill="#283242"
            />
            <g data-name="Group 106516">
                <path
                    data-name="Path 99518"
                    d="M20 56.519h.152a1.652 1.652 0 0 1 1.648 1.648v30.055a1.652 1.652 0 0 1-1.648 1.648H20a.293.293 0 0 1-.222.125.3.3 0 0 1-.235-.125h-.152a1.652 1.652 0 0 1-1.648-1.648v-30.05a1.643 1.643 0 0 1 1.648-1.648h.152a.238.238 0 0 1 .222-.125.272.272 0 0 1 .235.12Z"
                    fill="#283242"
                />
                <g data-name="Group 106468">
                    <path
                        data-name="Path 99521"
                        d="M12.1 146.69c-.512 0-.928 6.717-.928 15s.416 15 .928 15 .928-6.717.928-15-.418-15-.928-15Z"
                        transform="translate(7.735 -88.366)"
                        opacity={0.4}
                        fill="url(#aK)"
                    />
                </g>
                <g data-name="Group 106490" opacity={0.6}>
                    <g data-name="Group 106470">
                        <path
                            data-name="Path 99524"
                            d="M11.97 147.187a3.616 3.616 0 0 1 .554.568c.332.388.54.748.485.789s-.374-.235-.693-.623a3.976 3.976 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -60.912)"
                            fill="url(#aL)"
                        />
                        <path
                            data-name="Path 99525"
                            d="M20.013 86.275v-.028a3.193 3.193 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.373 4.373 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106471">
                        <path
                            data-name="Path 99526"
                            d="M11.97 148.311a4.082 4.082 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.707 5.707 0 0 1-.346-.457v-.274Z"
                            transform="translate(8.043 -63.56)"
                            fill="url(#aM)"
                        />
                        <path
                            data-name="Path 99527"
                            d="M20.013 84.751v-.028a3.2 3.2 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.415 3.415 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106472">
                        <path
                            data-name="Path 99528"
                            d="M11.97 149.377a3.616 3.616 0 0 1 .554.568c.332.388.54.748.485.789s-.374-.235-.693-.623a5.71 5.71 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -66.135)"
                            fill="url(#aN)"
                        />
                        <path
                            data-name="Path 99529"
                            d="M20.013 83.242v-.028a3.2 3.2 0 0 1 .582.568c.609.693.457.817.457.817.055-.055-.166-.4-.485-.79a4.372 4.372 0 0 0-.554-.567Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106473">
                        <path
                            data-name="Path 99530"
                            d="M11.97 150.5a4.084 4.084 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a3.977 3.977 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -68.781)"
                            fill="url(#aO)"
                        />
                        <path
                            data-name="Path 99531"
                            d="M20.013 81.718v-.028a3.2 3.2 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.415 3.415 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106474">
                        <path
                            data-name="Path 99532"
                            d="M11.97 151.575a4.083 4.083 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.7 5.7 0 0 1-.346-.457Z"
                            transform="translate(8.043 -71.367)"
                            fill="url(#aP)"
                        />
                        <path
                            data-name="Path 99533"
                            d="M20.013 80.21v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.416 3.416 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106475">
                        <path
                            data-name="Path 99534"
                            d="M11.97 152.691a4.083 4.083 0 0 1 .554.554c.332.388.54.748.485.79s-.374-.235-.693-.623a5.7 5.7 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -74.006)"
                            fill="url(#aQ)"
                        />
                        <path
                            data-name="Path 99535"
                            d="M20.013 78.687v-.028a3.193 3.193 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.416 3.416 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106476">
                        <path
                            data-name="Path 99536"
                            d="M11.97 153.781a4.083 4.083 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.707 5.707 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -76.606)"
                            fill="url(#aR)"
                        />
                        <path
                            data-name="Path 99537"
                            d="M20.013 77.175v-.028a3.2 3.2 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.415 3.415 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106477">
                        <path
                            data-name="Path 99538"
                            d="M11.97 154.857a3.616 3.616 0 0 1 .554.568c.332.388.54.748.485.789s-.374-.235-.693-.623a5.707 5.707 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -79.205)"
                            fill="url(#aS)"
                        />
                        <path
                            data-name="Path 99539"
                            d="M20.013 75.652v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.055-.166-.4-.485-.789a4.373 4.373 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106478">
                        <path
                            data-name="Path 99540"
                            d="M11.97 155.981a4.083 4.083 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.7 5.7 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -81.853)"
                            fill="url(#aT)"
                        />
                        <path
                            data-name="Path 99541"
                            d="M20.013 74.128V74.1a3.2 3.2 0 0 1 .582.568c.609.693.457.831.457.831.055-.055-.166-.4-.485-.789a3.416 3.416 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106479">
                        <path
                            data-name="Path 99542"
                            d="M11.97 157.047a3.615 3.615 0 0 1 .554.568c.332.388.54.748.485.789s-.374-.235-.693-.623a5.707 5.707 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -84.429)"
                            fill="url(#aU)"
                        />
                        <path
                            data-name="Path 99543"
                            d="M20.013 72.618v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.055-.166-.4-.485-.789a4.375 4.375 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106480">
                        <path
                            data-name="Path 99544"
                            d="M11.97 158.147a3.616 3.616 0 0 1 .554.568c.332.388.54.748.485.789s-.374-.235-.693-.623a3.977 3.977 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -87.052)"
                            fill="url(#aV)"
                        />
                        <path
                            data-name="Path 99545"
                            d="M20.013 71.095v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.374 4.374 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106481">
                        <path
                            data-name="Path 99546"
                            d="M11.97 159.261a4.082 4.082 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.707 5.707 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -89.676)"
                            fill="url(#aW)"
                        />
                        <path
                            data-name="Path 99547"
                            d="M20.013 69.585v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.415 3.415 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106482">
                        <path
                            data-name="Path 99548"
                            d="M11.97 160.337a3.616 3.616 0 0 1 .554.568c.332.388.54.748.485.789s-.374-.235-.693-.623a5.71 5.71 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -92.275)"
                            fill="url(#aX)"
                        />
                        <path
                            data-name="Path 99549"
                            d="M20.013 68.062v-.028a3.2 3.2 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.372 4.372 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106483">
                        <path
                            data-name="Path 99550"
                            d="M11.97 161.425a3.615 3.615 0 0 1 .554.568c.332.388.54.748.485.789s-.374-.235-.693-.623a3.979 3.979 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -94.873)"
                            fill="url(#aY)"
                        />
                        <path
                            data-name="Path 99551"
                            d="M20.013 66.552v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a4.373 4.373 0 0 0-.554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106484">
                        <path
                            data-name="Path 99552"
                            d="M11.97 162.551a4.084 4.084 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.707 5.707 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -97.523)"
                            fill="url(#aZ)"
                        />
                        <path
                            data-name="Path 99553"
                            d="M20.013 65.028V65a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.416 3.416 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106485">
                        <path
                            data-name="Path 99554"
                            d="M11.97 163.651a4.083 4.083 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.7 5.7 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -100.146)"
                            fill="url(#ba)"
                        />
                        <path
                            data-name="Path 99555"
                            d="M20.013 63.505v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.415 3.415 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106486">
                        <path
                            data-name="Path 99556"
                            d="M11.97 164.741a4.084 4.084 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.707 5.707 0 0 1-.346-.461v-.277Z"
                            transform="translate(8.043 -102.746)"
                            fill="url(#bb)"
                        />
                        <path
                            data-name="Path 99557"
                            d="M20.013 61.995v-.028a3.2 3.2 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.79a3.416 3.416 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106487">
                        <path
                            data-name="Path 99558"
                            d="M11.97 165.841a4.083 4.083 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.71 5.71 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -105.369)"
                            fill="url(#bc)"
                        />
                        <path
                            data-name="Path 99559"
                            d="M20.013 60.472v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.414 3.414 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106488">
                        <path
                            data-name="Path 99560"
                            d="M11.97 166.939a4.084 4.084 0 0 1 .554.554c.332.388.54.748.485.789s-.374-.235-.693-.623a5.7 5.7 0 0 1-.346-.457v-.277Z"
                            transform="translate(8.043 -107.991)"
                            fill="url(#bd)"
                        />
                        <path
                            data-name="Path 99561"
                            d="M20.013 58.948v-.028a3.194 3.194 0 0 1 .582.568c.609.693.457.817.457.817.055-.042-.166-.4-.485-.789a3.414 3.414 0 0 0-.554-.554Z"
                            fill="#fff"
                        />
                    </g>
                </g>
                <g data-name="Group 106512" opacity={0.6}>
                    <g data-name="Group 106492">
                        <path
                            data-name="Path 99566"
                            d="M11.944 147.2a3.09 3.09 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -60.937)"
                            fill="url(#be)"
                        />
                        <path
                            data-name="Path 99567"
                            d="M19.569 86.26v-.028a3.748 3.748 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a4.373 4.373 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106493">
                        <path
                            data-name="Path 99568"
                            d="M11.944 148.321a3.415 3.415 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.707 5.707 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -63.584)"
                            fill="url(#bf)"
                        />
                        <path
                            data-name="Path 99569"
                            d="M19.569 84.737v-.028a3.748 3.748 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a3.414 3.414 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106494">
                        <path
                            data-name="Path 99570"
                            d="M11.944 149.387a3.089 3.089 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a3.979 3.979 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -66.16)"
                            fill="url(#bg)"
                        />
                        <path
                            data-name="Path 99571"
                            d="M19.569 83.227v-.028a3.749 3.749 0 0 0-.582.568c-.609.679-.457.817-.457.817-.055-.042.166-.4.5-.789a4.373 4.373 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106495">
                        <path
                            data-name="Path 99572"
                            d="M11.944 150.487a3.088 3.088 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a3.978 3.978 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -68.783)"
                            fill="url(#bh)"
                        />
                        <path
                            data-name="Path 99573"
                            d="M19.569 81.704v-.028a3.747 3.747 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a4.375 4.375 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106496">
                        <path
                            data-name="Path 99574"
                            d="M11.944 151.6a3.417 3.417 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.71 5.71 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -71.407)"
                            fill="url(#bi)"
                        />
                        <path
                            data-name="Path 99575"
                            d="M19.569 80.194v-.028a3.747 3.747 0 0 0-.582.568c-.609.692-.457.817-.457.817-.055-.055.166-.4.5-.789a3.415 3.415 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106497">
                        <path
                            data-name="Path 99576"
                            d="M11.944 152.675a3.09 3.09 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.71 5.71 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -74.005)"
                            fill="url(#bj)"
                        />
                        <path
                            data-name="Path 99577"
                            d="M19.569 78.67v-.028a3.749 3.749 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a4.374 4.374 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106498">
                        <path
                            data-name="Path 99578"
                            d="M11.944 153.8a3.416 3.416 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.222.693-.623a5.707 5.707 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -76.654)"
                            fill="url(#bk)"
                        />
                        <path
                            data-name="Path 99579"
                            d="M19.569 77.147v-.028a3.749 3.749 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.79a3.414 3.414 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106499">
                        <path
                            data-name="Path 99580"
                            d="M11.944 154.891a3.416 3.416 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.235.693-.623a3.978 3.978 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -79.254)"
                            fill="url(#bl)"
                        />
                        <path
                            data-name="Path 99581"
                            d="M19.569 75.639v-.028a3.749 3.749 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a3.414 3.414 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106500">
                        <path
                            data-name="Path 99582"
                            d="M11.944 155.991a3.416 3.416 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -81.877)"
                            fill="url(#bm)"
                        />
                        <path
                            data-name="Path 99583"
                            d="M19.569 74.116v-.028a3.749 3.749 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a3.416 3.416 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106501">
                        <path
                            data-name="Path 99584"
                            d="M11.944 157.081a3.416 3.416 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.707 5.707 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -84.477)"
                            fill="url(#bn)"
                        />
                        <path
                            data-name="Path 99585"
                            d="M19.569 72.604v-.028a3.747 3.747 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.055.166-.4.5-.789a3.416 3.416 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106502">
                        <path
                            data-name="Path 99586"
                            d="M11.944 158.157a3.09 3.09 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.71 5.71 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -87.077)"
                            fill="url(#bo)"
                        />
                        <path
                            data-name="Path 99587"
                            d="M19.569 71.08v-.028a3.749 3.749 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.055.166-.4.5-.789a4.374 4.374 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106503">
                        <path
                            data-name="Path 99588"
                            d="M11.944 159.271a3.416 3.416 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.707 5.707 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -89.7)"
                            fill="url(#bp)"
                        />
                        <path
                            data-name="Path 99589"
                            d="M19.569 69.571v-.028a3.749 3.749 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.79a3.415 3.415 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106504">
                        <path
                            data-name="Path 99590"
                            d="M11.944 160.345a3.089 3.089 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.713 5.713 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -92.298)"
                            fill="url(#bq)"
                        />
                        <path
                            data-name="Path 99591"
                            d="M19.569 68.047v-.028a3.749 3.749 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a4.374 4.374 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106505">
                        <path
                            data-name="Path 99592"
                            d="M11.944 161.447a3.089 3.089 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a3.978 3.978 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -94.923)"
                            fill="url(#br)"
                        />
                        <path
                            data-name="Path 99593"
                            d="M19.569 66.524v-.028a3.748 3.748 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a4.375 4.375 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106506">
                        <path
                            data-name="Path 99594"
                            d="M11.944 162.537a3.09 3.09 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.707 5.707 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -97.523)"
                            fill="url(#bs)"
                        />
                        <path
                            data-name="Path 99595"
                            d="M19.569 65.014v-.028a3.748 3.748 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a4.372 4.372 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106507">
                        <path
                            data-name="Path 99596"
                            d="M11.944 163.661a3.416 3.416 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.707 5.707 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -100.171)"
                            fill="url(#bt)"
                        />
                        <path
                            data-name="Path 99597"
                            d="M19.569 63.49v-.028a3.748 3.748 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a3.415 3.415 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106508">
                        <path
                            data-name="Path 99598"
                            d="M11.944 164.727a3.09 3.09 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.707 5.707 0 0 0 .347-.461v-.277Z"
                            transform="translate(7.626 -102.746)"
                            fill="url(#bu)"
                        />
                        <path
                            data-name="Path 99599"
                            d="M19.569 61.981v-.028a3.748 3.748 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a4.373 4.373 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106509">
                        <path
                            data-name="Path 99600"
                            d="M11.944 165.851a3.415 3.415 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.235.693-.623a3.978 3.978 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -105.394)"
                            fill="url(#bv)"
                        />
                        <path
                            data-name="Path 99601"
                            d="M19.569 60.457v-.028a3.748 3.748 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.042.166-.4.5-.789a3.414 3.414 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106510">
                        <path
                            data-name="Path 99602"
                            d="M11.944 166.927a3.09 3.09 0 0 0-.554.568c-.332.388-.54.748-.5.789s.374-.235.693-.623a3.978 3.978 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -107.993)"
                            fill="url(#bw)"
                        />
                        <path
                            data-name="Path 99603"
                            d="M19.569 58.934v-.028a3.747 3.747 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.055.166-.4.5-.789a4.373 4.373 0 0 1 .554-.568Z"
                            fill="#fff"
                        />
                    </g>
                    <g data-name="Group 106511">
                        <path
                            data-name="Path 99604"
                            d="M11.944 168.051a3.416 3.416 0 0 0-.554.554c-.332.388-.54.748-.5.789s.374-.235.693-.623a5.7 5.7 0 0 0 .346-.457v-.277Z"
                            transform="translate(7.626 -110.641)"
                            fill="url(#bx)"
                        />
                        <path
                            data-name="Path 99605"
                            d="M19.569 57.41v-.028a3.748 3.748 0 0 0-.582.568c-.609.693-.457.817-.457.817-.055-.055.166-.4.5-.789a3.415 3.415 0 0 1 .554-.554Z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
            <path
                data-name="Path 99614"
                d="M19.353 58.047a1.781 1.781 0 0 0-1.967 1.5v27.38c0 .831.873 1.5 1.967 1.5h.873a1.781 1.781 0 0 0 1.967-1.5V59.543a1.781 1.781 0 0 0-1.967-1.5Z"
                fill="#656364"
            />
            <path
                data-name="Path 99617"
                d="M11.426 171.51a.71.71 0 0 0-.776.609v8.227a.71.71 0 0 0 .776.609h1.787a.71.71 0 0 0 .776-.609v-8.227a.71.71 0 0 0-.776-.609Z"
                transform="translate(7.555 -127.009)"
                fill="url(#by)"
            />
            <g data-name="Group 106518">
                <path
                    data-name="Path 99620"
                    d="M15.45 191.146c0-.083-.332-.166-.748-.166s-.762.069-.762.166v5.346c0 .083.346.152.762.152.416 0 .748-.069.748-.152Z"
                    transform="translate(9.618 -169.664)"
                    fill="url(#bz)"
                />
                <path
                    data-name="Path 99621"
                    d="M14.692 196.645c-.416 0-.762-.069-.762-.152v-5.346c0-.083.346-.166.762-.166s.748.069.748.166v5.346c0 .083-.333.152-.748.152Zm0-4.972c-.734 0-.6.817-.6 1.842s-.152 2.285.6 2.285.6-1.26.6-2.285.134-1.842-.6-1.842Z"
                    transform="translate(9.614 -169.664)"
                    opacity={0.7}
                    fill="url(#j)"
                />
                <path
                    data-name="Path 99622"
                    d="M14.921 193.447c0-.886 0-1.607-.332-1.607s-.346.72-.346 1.607-.1 2.05.346 2.05.332-1.164.332-2.05Z"
                    transform="translate(9.731 -169.707)"
                    opacity={0.9}
                    fill="url(#bA)"
                />
            </g>
            <g data-name="Group 106520">
                <path
                    data-name="Path 99624"
                    d="M10.36 191.146c0-.083-.332-.166-.762-.166-.416 0-.748.069-.748.166v5.346c0 .083.332.152.748.152s.762-.069.762-.152Z"
                    transform="translate(6.113 -169.664)"
                    fill="url(#bz)"
                />
                <path
                    data-name="Path 99625"
                    d="M9.6 196.645c-.416 0-.748-.069-.748-.152v-5.346c0-.083.332-.166.748-.166s.762.069.762.166v5.346c-.002.083-.335.152-.762.152Zm0-4.972c-.734 0-.6.817-.6 1.842s-.152 2.285.6 2.285.6-1.26.6-2.285.132-1.842-.6-1.842Z"
                    transform="translate(6.113 -169.664)"
                    opacity={0.7}
                    fill="url(#bB)"
                />
                <path
                    data-name="Path 99626"
                    d="M9.831 193.447c0-.886 0-1.607-.346-1.607s-.332.72-.332 1.607-.1 2.05.332 2.05.346-1.164.346-2.05Z"
                    transform="translate(6.226 -169.707)"
                    opacity={0.9}
                    fill="url(#bC)"
                />
            </g>
            <g data-name="Group 106522">
                <path
                    data-name="Path 99628"
                    d="M12.73 161.148a.745.745 0 0 0-.748-.748.757.757 0 0 0-.762.748v23.726a.769.769 0 0 0 .762.762.757.757 0 0 0 .748-.762Z"
                    transform="translate(7.744 -116.3)"
                    fill="url(#bD)"
                />
                <path
                    data-name="Path 99629"
                    d="M11.972 185.635a.769.769 0 0 1-.762-.762v-23.725a.757.757 0 0 1 .762-.748.745.745 0 0 1 .748.748v23.726a.757.757 0 0 1-.748.761Zm0-23.753c-.734 0-.6 3.934-.6 8.781s-.152 10.9.6 10.9.6-6.053.6-10.9.134-8.781-.6-8.781Z"
                    transform="translate(7.74 -116.3)"
                    opacity={0.7}
                    fill="url(#bE)"
                />
            </g>
            <g data-name="Group 106526">
                <path
                    data-name="Path 99632"
                    d="M19.76 26.551h1.08c.429 0 .914 0 1.468.028 3.573.055 4.252 1.482 4.183 3.019a6.656 6.656 0 0 1-1.967 4.5c-1.482 1.662-2.341 6.773-2.715 8.255s-.679 2.645-1.856 2.645h-.429c-1.177 0-1.482-1.163-1.842-2.645s-1.233-6.593-2.715-8.255a6.544 6.544 0 0 1-1.967-4.5c-.055-1.537.609-2.95 4.183-3.019.554 0 1.053-.014 1.468-.028h1.08Z"
                    fill="#484a4f"
                />
                <path
                    data-name="Path 99633"
                    d="M20.84 26.565c.429 0 .914 0 1.468.028 3.573.055 4.252 1.482 4.183 3.019a6.015 6.015 0 0 1-.845 2.992 3.013 3.013 0 0 0 .111-2.313 3.29 3.29 0 0 0-3.324-1.967c-1.717 0-5.969.18-6.773.3s-1.911 1.607-1.967 2.95a3.4 3.4 0 0 0 .194 1.039 5.981 5.981 0 0 1-.859-3.006c-.055-1.537.609-2.95 4.183-3.019.554 0 1.053-.014 1.468-.028h2.161Z"
                    fill="#484a4f"
                />
                <path
                    data-name="Path 99634"
                    d="M20.84 26.565c.429 0 .914 0 1.468.028a5.68 5.68 0 0 1 3.255.789 6.937 6.937 0 0 0-2.7-.457c-.554 0-1.053-.014-1.482-.014H19.22c-.429 0-.914 0-1.468.014-3.573.055-4.252 1.427-4.183 2.922a6.284 6.284 0 0 0 1.967 4.349c1.482 1.607 2.341 6.565 2.715 7.992s.679 2.562 1.856 2.562h.429a.81.81 0 0 0 .263-.028 1.312 1.312 0 0 1-.817.263h-.429c-1.177 0-1.482-1.163-1.842-2.645s-1.233-6.593-2.715-8.255a6.544 6.544 0 0 1-1.967-4.5c-.055-1.537.609-2.95 4.183-3.019.554 0 1.053-.014 1.468-.028h2.161Z"
                    fill="#fff"
                />
            </g>
            <path
                data-name="Path 99639"
                d="M12.983 162.6c0-.886.152-1.662-1-1.662s-.831 1.7-.873 1.759S13 162.6 13 162.6Z"
                transform="translate(7.707 -94.106)"
                fill="url(#bF)"
            />
            <path
                data-name="Path 99640"
                d="M12.983 162.6c0-.886.152-1.662-1-1.662s-.831 1.7-.873 1.759S13 162.6 13 162.6Z"
                transform="translate(7.707 -94.106)"
                fill="url(#m)"
            />
            <g data-name="Group 106527">
                <path
                    data-name="Path 99641"
                    d="M14.332 162.2a2.451 2.451 0 0 0-1.565-2.59c-.332-.1-.457.18-.457.679v-3.2a4.961 4.961 0 0 1 2.922 2.216c.983 1.759.762 4.709.706 6.524-.055 2.147-1.108 1.634-1.69 1.69 0 0 .1-4.155.1-5.319Z"
                    transform="translate(8.776 -93.612)"
                    fill="url(#bG)"
                />
                <path
                    data-name="Path 99642"
                    d="M15.422 165.252s.042-2.424.014-3.269a5.259 5.259 0 0 0-.762-2.95 3.121 3.121 0 0 0-1.5-1.025c-.3-.083-.18.125.18.457a5.127 5.127 0 0 1 1.648 2.867c.083 1.274.111 2.812.139 3.449s.125 2.078.263.471Z"
                    transform="translate(9.044 -93.314)"
                    opacity={0.9}
                    fill="url(#k)"
                />
                <path
                    data-name="Path 99644"
                    d="M12.3 160.288v-3.2a4.961 4.961 0 0 1 2.922 2.216c.983 1.759.762 4.709.706 6.524-.055 2.147-1.108 1.634-1.69 1.69 0 0 .1-4.155.1-5.319a2.451 2.451 0 0 0-1.565-2.59c-.332-.1-.457.18-.457.679Zm1.759-.166a3.346 3.346 0 0 1 .776 2.452v3.476a.471.471 0 0 0 .942 0c0-1.856.429-5.166-.776-6.9a2.656 2.656 0 0 0-1.537-.983c-.429-.139-.762.4-.277.942.36.4.651.72.886 1.011Z"
                    transform="translate(8.772 -93.612)"
                    opacity={0.7}
                    fill="url(#bH)"
                />
            </g>
            <g data-name="Group 106528">
                <path
                    data-name="Path 99646"
                    d="M8.895 157.72h-.54a.519.519 0 0 0-.125.3v.693c0 .166.055.3.125.3h.54v-1.288Z"
                    transform="translate(5.621 -85.975)"
                    fill="url(#bI)"
                />
                <path
                    data-name="Path 99647"
                    d="M14.128 71.994v.8a.246.246 0 0 1-.166.235h.568v-1.284h-.568a.241.241 0 0 1 .18.235Z"
                    opacity={0.3}
                />
            </g>
            <g data-name="Group 106529">
                <path
                    data-name="Path 99648"
                    d="M10.223 162.2a2.451 2.451 0 0 1 1.565-2.59c.346-.1.457.18.457.679v-3.2a4.961 4.961 0 0 0-2.922 2.211c-.983 1.759-.762 4.709-.706 6.524.055 2.147 1.108 1.634 1.69 1.69 0 0-.1-4.155-.1-5.319Z"
                    transform="translate(6.109 -93.612)"
                    fill="url(#bJ)"
                />
                <path
                    data-name="Path 99649"
                    d="M8.788 165.252s-.042-2.424-.028-3.269a5.374 5.374 0 0 1 .762-2.95 3.121 3.121 0 0 1 1.5-1.025c.3-.083.18.125-.18.457a5.127 5.127 0 0 0-1.648 2.867c-.083 1.274-.1 2.812-.139 3.449s-.128 2.078-.267.471Z"
                    transform="translate(6.186 -93.314)"
                    opacity={0.9}
                    fill="url(#bK)"
                />
                <path
                    data-name="Path 99651"
                    d="M11.785 159.609a2.44 2.44 0 0 0-1.565 2.59c0 1.177.1 5.319.1 5.319-.582-.055-1.634.457-1.69-1.69-.055-1.814-.277-4.778.706-6.524a4.961 4.961 0 0 1 2.922-2.216v3.2c0-.5-.125-.776-.457-.679Zm-.4-.512c.5-.554.166-1.094-.277-.942a2.656 2.656 0 0 0-1.537.983c-1.2 1.731-.776 5.028-.776 6.9a.471.471 0 0 0 .942 0c0-1.163-.014-2.313 0-3.476a3.33 3.33 0 0 1 .776-2.452c.235-.291.526-.609.886-1.011Z"
                    transform="translate(6.113 -93.612)"
                    opacity={0.7}
                    fill="url(#bL)"
                />
            </g>
            <g data-name="Group 106537">
                <g data-name="Group 106532">
                    <path
                        data-name="Path 99653"
                        d="M11.74 164.588c.748.014 7.313.125 9.764-.859 1.662-.665 2.992-3.019 3.144-3.283l-1.288-.706a6.876 6.876 0 0 1-2.4 2.632c-1.537.623-5.845.817-9.183.748l-.028 1.482Zm11.62-4.848Z"
                        transform="translate(11.364 -94.353)"
                        fill="url(#l)"
                    />
                    <g data-name="Group 106530" opacity={0.2}>
                        <path
                            data-name="Path 99654"
                            d="M32.864 69.39c-2.451.983-9.03.873-9.764.859l.028-1.482c3.338.069 7.645-.125 9.183-.748a6.877 6.877 0 0 0 2.4-2.632l1.288.706c-.139.263-1.482 2.618-3.144 3.283Zm-.443-.942a28.883 28.883 0 0 1-5.886.72c-1.33.014-1.579.222-1.537.471s.374.388 1.593.346a25.514 25.514 0 0 0 6.011-.72c1.579-.679 1.842-1.4 1.69-1.607s-.845.388-1.87.776Z"
                        />
                    </g>
                    <g data-name="Group 106531" opacity={0.4}>
                        <path
                            data-name="Path 99655"
                            d="M36.012 66.108c-.138.263-1.482 2.618-3.144 3.283-2.451.983-9.03.873-9.764.859l.028-1.482c3.338.069 7.645-.125 9.183-.748a6.877 6.877 0 0 0 2.4-2.632l1.288.706Zm-3.74 2.2a32.572 32.572 0 0 1-6.219.6c-1.33.014-1.122.485-1.122.734-.028.637.471.512 1.7.471a25.1 25.1 0 0 0 5.969-.72c1.579-.679 2.258-1.5 1.69-1.842-.914-.554-.983.374-2.008.762Zm2.188-1.607c.166.139.319.263.5.388a.153.153 0 0 0 .111 0 .407.407 0 0 0 .139-.166.256.256 0 0 0-.1-.194c-.166-.083-.36-.139-.54-.222-.1-.042-.18.111-.1.18Z"
                            opacity={0.4}
                        />
                    </g>
                    <path
                        data-name="Path 99657"
                        d="M35.959 66.579s1.759-4.183 1.994-4.931.249-.706-.028-.928-1.136-.734-1.413-.277-2.271 5.208-2.271 5.208Z"
                        fill="#302c28"
                    />
                    <path
                        data-name="Path 99660"
                        d="M34.031 65.54a.263.263 0 0 0-.332.166.261.261 0 0 0 .125.346l2.091.831a.263.263 0 0 0 .332-.166.261.261 0 0 0-.125-.346Z"
                        fill="#302c28"
                    />
                    <path
                        data-name="Path 99662"
                        d="M20.343 167.089a.291.291 0 0 1 .471-.055c.291.249.693.429 1.274-.651s.388-2.341 1.136-3.615.693-.942.886-1.4.845.263.72.637-.111-.028-.332.083a2.5 2.5 0 0 0-.942 1.357c-.291.8-.388 2.95-1.357 3.809s-1.676.526-1.953.249c-.083-.083.111-.415.111-.415Z"
                        transform="translate(14.631 -99.611)"
                        fill="url(#bM)"
                    />
                    <path
                        data-name="Path 99663"
                        d="M24.83 161.882a.783.783 0 0 0-.374-.388.1.1 0 0 0-.139.139v.042c-.609.291-.928 1.191-1.163 1.759-.374.928-.388 1.911-.72 2.825a1.972 1.972 0 0 1-.457.789c-.291.291-.512.249-.873.291-.152.028-.3.069-.3.249 0 .152.194.194.416.208a1.06 1.06 0 0 1-.97-.291c-.083-.083.111-.416.111-.416a.291.291 0 0 1 .471-.055c.291.249.693.429 1.274-.651s.388-2.341 1.136-3.615.693-.942.886-1.4c.166-.4.706.125.734.5Z"
                        transform="translate(14.631 -99.625)"
                        fill="url(#bN)"
                    />
                    <path
                        data-name="Path 99664"
                        d="M19.569 161.49a.222.222 0 0 0-.277.125.224.224 0 0 0 .028.3l1.371.8c.083.055.208 0 .277-.139a.224.224 0 0 0-.028-.3l-1.371-.8Z"
                        transform="translate(14.255 -94.898)"
                        fill="url(#bO)"
                    />
                </g>
                <g data-name="Group 106535">
                    <path
                        data-name="Path 99670"
                        d="M15.384 163.122c-3.352.069-7.645-.125-9.2-.748a6.876 6.876 0 0 1-2.4-2.632l-1.3.706c.139.263 1.482 2.618 3.144 3.283 2.451.983 9.03.873 9.764.859l-.028-1.482Zm-11.593-3.38Z"
                        transform="translate(.974 -94.355)"
                        fill="url(#bP)"
                    />
                    <g data-name="Group 106533" opacity={0.2}>
                        <path
                            data-name="Path 99671"
                            d="m3.464 66.108 1.3-.706a6.877 6.877 0 0 0 2.4 2.632c1.537.623 5.845.817 9.2.748l.028 1.482c-.734.014-7.313.125-9.764-.859-1.662-.665-3.005-3.019-3.144-3.283Zm1.717 1.565c-.166.208.1.928 1.69 1.607a26.282 26.282 0 0 0 6.011.72c1.233.042 1.565-.1 1.607-.346s-.208-.457-1.537-.471a28.884 28.884 0 0 1-5.886-.72c-1.025-.388-1.7-.983-1.87-.776Z"
                        />
                    </g>
                    <g data-name="Group 106534" opacity={0.4}>
                        <path
                            data-name="Path 99672"
                            d="M4.766 65.387a6.877 6.877 0 0 0 2.4 2.632c1.537.623 5.845.817 9.2.748l.028 1.482c-.734.014-7.313.125-9.764-.859-1.662-.665-3.005-3.019-3.144-3.283l1.3-.706Zm.443 2.147c-.582.346.1 1.163 1.676 1.842a25.855 25.855 0 0 0 5.969.72c1.233.042 1.717.166 1.7-.471 0-.249.208-.72-1.136-.734a31.489 31.489 0 0 1-6.219-.6c-1.025-.388-1.108-1.316-2.008-.762Zm-.277-1.025c-.18.069-.36.139-.54.222-.014 0-.028.028-.042.042a.1.1 0 0 0-.055.152.8.8 0 0 0 .139.166.153.153 0 0 0 .111 0c.166-.125.332-.249.5-.388.083-.069 0-.222-.1-.18Z"
                            opacity={0.4}
                        />
                    </g>
                    <path
                        data-name="Path 99674"
                        d="M3.533 66.579s-1.759-4.183-2.008-4.931-.249-.706.028-.928 1.136-.734 1.413-.277 2.285 5.208 2.285 5.208l-1.717.928Z"
                        fill="#302c28"
                    />
                    <path
                        data-name="Path 99677"
                        d="M5.459 65.54a.263.263 0 0 1 .332.166.261.261 0 0 1-.125.346l-2.091.831a.263.263 0 0 1-.332-.166.261.261 0 0 1 .125-.346l2.091-.831Z"
                        fill="#302c28"
                    />
                    <path
                        data-name="Path 99679"
                        d="M4.492 167.089a.291.291 0 0 0-.471-.055c-.291.249-.693.429-1.274-.651s-.388-2.341-1.136-3.615-.693-.942-.886-1.4-.845.263-.72.637.111-.028.332.083a2.5 2.5 0 0 1 .942 1.357c.291.8.388 2.95 1.357 3.809s1.676.526 1.953.249c.083-.083-.111-.415-.111-.415Z"
                        transform="translate(.011 -99.611)"
                        fill="url(#bQ)"
                    />
                    <path
                        data-name="Path 99680"
                        d="M0 161.882a.756.756 0 0 1 .388-.388.1.1 0 0 1 .139.139v.042c.6.291.928 1.191 1.163 1.759.374.928.388 1.911.72 2.825a1.972 1.972 0 0 0 .457.789c.291.291.526.249.873.291.152.028.291.069.3.249 0 .152-.194.194-.416.208a1.074 1.074 0 0 0 .97-.291c.083-.083-.111-.416-.111-.416a.291.291 0 0 0-.471-.055c-.291.249-.693.429-1.274-.651s-.388-2.341-1.136-3.615-.693-.942-.886-1.4c-.166-.4-.706.125-.734.5Z"
                        transform="translate(.012 -99.625)"
                        fill="url(#bR)"
                    />
                    <path
                        data-name="Path 99681"
                        d="M4.481 161.49a.222.222 0 0 1 .277.125.224.224 0 0 1-.028.3l-1.371.8c-.083.055-.208 0-.277-.139a.224.224 0 0 1 .028-.3l1.371-.8Z"
                        transform="translate(1.185 -94.898)"
                        fill="url(#bS)"
                    />
                    <path
                        data-name="Path 99686"
                        d="M9.76 159.765v.54c.028.319.069.623.069.928 1.4.028 2.493 0 2.77 0l-.028-1.482c-.886.014-1.856.014-2.812 0Z"
                        transform="translate(3.773 -91.012)"
                        fill="url(#bT)"
                    />
                </g>
                <g data-name="Group 106536" transform="translate(18.137 68.337)">
                    <rect
                        data-name="Rectangle 55614"
                        width={3.199}
                        height={2.465}
                        rx={1.233}
                        fill="#302c28"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default CycleIcon
