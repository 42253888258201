import React from "react"

export const DropDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
  <g id="Group_113387" data-name="Group 113387" transform="translate(-541 -418)">
    <g id="Rectangle_57465" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="26" height="26" stroke="none"/>
      <rect x="0.5" y="0.5" width="25" height="25" fill="none"/>
    </g>
    <path id="icons8-checked-radio-button" d="M9.682,11a7.682,7.682,0,1,0,7.682,7.682A7.7,7.7,0,0,0,9.682,11Zm0,1.773a5.909,5.909,0,1,1-5.909,5.909A5.9,5.9,0,0,1,9.682,12.773Zm2.937,2.946a.886.886,0,0,0-.609.268L8.5,19.5,7.058,18.055A.886.886,0,1,0,5.8,19.309l2.068,2.068a.886.886,0,0,0,1.253,0l4.136-4.136a.886.886,0,0,0-.644-1.521Zm7.4,2.076a.886.886,0,1,0,0,1.773h7.091a.886.886,0,1,0,0-1.773Z" transform="translate(539 412.909)" fill="#78b1fe"/>
  </g>
</svg>


)