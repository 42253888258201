import { Divider, Grid, Stack, Typography } from "@mui/material";
import { showAddress, useWindowDimensions } from "../../utils";
import React from "react";
// import ImageViewer from "react-simple-image-viewer";
import { useStyles } from "./style";

import { AlertDialog } from "../dialog";
export const MiniPropertyDetail = ({
    logo = "",
    property_no = "",
    company_name = "",
    property_name = "",
    unit_no = "",
    unit_name = "",
    address = {},
    t
}) => {

    const size = useWindowDimensions()
    const classes = useStyles()

    const [is_logo_viewer_open, set_is_logo_viewer_open] = React.useState(false)

    return (
        <>
            <Stack className={classes.root} direction={size?.width >= 600 ? "row" : "column"}
                divider={size?.width >= 600 ? <Divider orientation="vertical" variant="middle" flexItem /> : <></>}>
                <div className={classes.logo_div}>
                    <img src={logo ?? "/images/imagesproperty.svg"} alt="" className={classes.logo}
                        onClick={() => logo ? set_is_logo_viewer_open(true) : false} />
                </div>
                <Grid container>
                    <div className={classes.title_div}>
                        <Typography className={classes.title}>{unit_no ? t("UNIT DETAILS") : t("PROPERTY DETAILS")}</Typography>
                        {property_no && <Typography className={classes.title_tag}>{property_no}</Typography>}
                        {unit_no && <Typography className={classes.title_tag}>{unit_no}</Typography>}

                    </div>
                    <Grid container sx={{ marginInlineStart: "16px" }}>
                        {company_name &&
                            <Grid item xs={size?.width >= 1000 ? 2 : size?.width >= 450 ? 6 : 12}>
                                <Typography className={classes.heading}>{t("Company Name")}</Typography>
                                <Typography className={classes.content}>{company_name ?? " - "}</Typography>
                            </Grid>
                        }
                        {property_name &&
                            <Grid item xs={size?.width >= 1000 ? 2 : size?.width >= 450 ? 6 : 12}>
                                <Typography className={classes.heading}>{t("Property Name")}</Typography>
                                <Typography className={classes.content}>{property_name ?? " - "}</Typography>
                            </Grid>
                        }
                        {unit_name &&
                            <Grid item xs={size?.width >= 1000 ? 2 : size?.width >= 450 ? 6 : 12}>
                                <Typography className={classes.heading}>{t("Unit Name")}</Typography>
                                <Typography className={classes.content}>{unit_name ?? " - "}</Typography>
                            </Grid>
                        }
                        <Grid item xs={size?.width >= 1000 ? 8 : 12}>
                            <Typography className={classes.heading}>{t("Location")}</Typography>
                            <Typography className={classes.content}>
                                {address?.id ? showAddress(address) : " - "}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
            {/* {is_logo_viewer_open && (
                <ImageViewer
                    src={[logo]}
                    currentIndex={0}
                    onClose={() => set_is_logo_viewer_open(false)}
                    disableScroll={false}
                    backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                    className={classes.image_viewer}
                    closeOnClickOutside={true} />
            )} */}
            <AlertDialog
                noBorder
                border
                noBg
                image
                medium={true}
                open={is_logo_viewer_open}
                onClose={() => set_is_logo_viewer_open(false)}
                component={
                    <img
                        src={logo}

                        alt=""
                        style={{
                            height: "100%",
                            width: "100%"
                        }}
                    />
                }
            />
        </>
    )
}