import * as React from "react"

export const Male = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
        <path
            data-name="Path 97589"
            d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0Z"
            fill="#ede7f6"
        />
        <path
            data-name="Path 97590"
            d="M16 32a15.679 15.679 0 0 0 9.52-3.2c-.48-6.08-6.56-7.2-6.56-7.2l-2.96.64-2.96-.64s-6.08 1.12-6.56 7.2A15.679 15.679 0 0 0 16 32Z"
            fill="#673ab7"
        />
        <path
            data-name="Path 97591"
            d="M16 26.4a4.7 4.7 0 0 0 4.72-4.24 9.753 9.753 0 0 0-1.6-.56 3.142 3.142 0 0 1-3.2 3.12 3.194 3.194 0 0 1-3.2-3.12 9.753 9.753 0 0 0-1.6.56A4.982 4.982 0 0 0 16 26.4Z"
            fill="#311b92"
        />
        <path
            data-name="Path 97592"
            d="M22.4 14.8a1.2 1.2 0 1 1-1.2-1.2 1.23 1.23 0 0 1 1.2 1.2m-10.4 0a1.2 1.2 0 1 0-1.2 1.2 1.23 1.23 0 0 0 1.2-1.2"
            fill="#ffa726"
        />
        <path
            data-name="Path 97593"
            d="M16 24.8a3.134 3.134 0 0 1-3.2-3.2v-3.2h6.4v3.2a3.134 3.134 0 0 1-3.2 3.2Z"
            fill="#ff9800"
        />
        <path
            data-name="Path 97594"
            d="M21.6 11.76c0-4.72-11.2-3.04-11.2 0v3.52a5.6 5.6 0 0 0 11.2 0Z"
            fill="#ffb74d"
        />
        <path
            data-name="Path 97595"
            d="M16 5.6a6.545 6.545 0 0 0-6.4 6.56v1.44l1.6 1.6V12l7.36-2.4L20.8 12v3.2l1.6-1.6v-.64c0-2.56-.64-5.44-3.84-6.08l-.64-1.28Z"
            fill="#424242"
        />
        <path
            data-name="Path 97596"
            d="M17.6 14.4a.8.8 0 1 1 .8.8.756.756 0 0 1-.8-.8m-4.8 0a.8.8 0 1 0 .8-.8.756.756 0 0 0-.8.8"
            fill="#784719"
        />
    </svg>
)


