import React, { useEffect, useState } from "react";
import { Subheader } from "../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import {
  LocalStorageKeys,
  accessCheckRender,
  getCompanyOption,
  getRoutePermissionNew,
  pageName,
} from "../../utils";

import DashBoard from "./dashboard";
import { withNamespaces } from "react-i18next";

const PropertyDashBoard = (props) => {
  const { loading, handleLoading, t } = props;
  // states
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [permission, setPermission] = React.useState({});
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)
  const [selectedTeams, setSelectedTeams] = React.useState('')

  // use effect to get permission
  useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompanyList(company?.list);
          setSelectedCompany(company?.selected);
          handleLoading(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [auth]);

  //   company change
  const handleCompanyChange = (e) => {
    setSelectedCompany(e);
  };

  // Function to change team
  const handleTeamsChange = (team) => {
    setSelectedTeams(team)
  }

  const render = () => {
    return (
      <>
        {/* sub header */}
        <Subheader
          hideBackButton={true}
          title={t("Property Manager Dashboard")}
          select
          options={companyList ?? []}
          value={selectedCompany}
          onchange={(e) => {
            handleCompanyChange(e);
          }}
          selectTeamsOptions
          handleTeamsChange={handleTeamsChange}
          companyId={selectedCompany?.value}
          moduleId={moduleId}
          selectedTeams={selectedTeams}
        />
        <DashBoard selectedcompany={selectedCompany} selectedTeams={selectedTeams} t={t}
        />
      </>
    );
  };

  return (
    <div>
      {accessCheckRender(render, permission, pageName.dashboard, loading)}
    </div>
  );
};
export default withNamespaces("propertyDashBoard")(PropertyDashBoard);
