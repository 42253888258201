import * as React from "react"
const CalendarLoadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <path
      fill="#ced3dd"
      d="M9 0a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9Zm2.052 11.952a.676.676 0 0 1-.955 0l-2.25-2.25a.676.676 0 0 1-.2-.477v-4.95a.675.675 0 1 1 1.35 0v4.671L11.052 11a.675.675 0 0 1 0 .952Z"
    />
  </svg>
)
export default CalendarLoadIcon
