export const DeliveryJobIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_116016" data-name="Group 116016" transform="translate(0.001)">
                <g id="Rectangle_58161" data-name="Rectangle 58161" transform="translate(-0.001)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <path id="icons8-box_2_" data-name="icons8-box (2)" d="M6.947,10.211v9.711A3.083,3.083,0,0,0,10.026,23h9a3.083,3.083,0,0,0,3.079-3.079V10.211Zm9.711,4.263H12.395a.711.711,0,1,1,0-1.421h4.263a.711.711,0,1,1,0,1.421Zm5.211-5.211H7.184A1.186,1.186,0,0,1,6,8.079V6.184A1.186,1.186,0,0,1,7.184,5H21.868a1.186,1.186,0,0,1,1.184,1.184V8.079A1.186,1.186,0,0,1,21.868,9.263Z" transform="translate(-4.526 -4)" fill="#6dafb3" />
            </g>
        </svg>

    )
}