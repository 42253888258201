import React from "react";
import { withNavBars } from "../../HOCs";
import CreateInvoiceFreeText from "./createInvoiceFreeText";

const CreateInvoiceFreeTextParent = () => {
    return <CreateInvoiceFreeText />;
}
const props = {
    boxShadow: false
}
export default withNavBars(CreateInvoiceFreeTextParent, props);