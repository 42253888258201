import React from "react";
import { withNavBars } from "../../HOCs";
import Occupants from "./occupants";

class OccupantsParent extends React.Component {
  render() {
    return <Occupants {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(OccupantsParent, props);
