import React from "react";
import { withNavBars } from "../../HOCs";
import CustomizeQuote from "./customizeQuote";

class CustomQuoteParent extends React.Component {
    render() {
        return <CustomizeQuote {...this.props}/>;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CustomQuoteParent, props);
