import React, { useState } from "react";
import { Button, Box, Divider, Avatar, Typography } from "@mui/material";
import { useStyleTimeLine } from "../../styles";
import { TextBox } from "../../../../components";
import { NewAgreementIcon } from "./assets";
import { NetworkCall } from "../../../../networkcall";
import { config } from "../../../../config";
import { AlertProps, NetWorkCallMethods, timeZoneConverter } from "../../../../utils";
import {monthDiff} from "../../../../utils/constants";
import { AlertContext } from "../../../../contexts";
import { addDays, addMonths } from "date-fns";
export const AsPerAgreement = ({ t, details, agreement_id, onCloseDialog, agreementReload }) => {
  const classes = useStyleTimeLine();
  const [remark, setRemark] = useState("");
  const [disable, setDisable] = useState(false);
  const [state,setState]=React.useState({});

  const alert = React.useContext(AlertContext);


  const sumbit = () => {
    setDisable(true)
    const variables = {
      "agreement_id": agreement_id,
      "remarks": remark,
      "contact_id": details?.contact?.id,
      "renewal_type": "As Per Agreement",
      "period": details?.lease_period,
      "start_date": state?.lease_start_date,
      "end_date": state?.lease_end_date,
      "request_purpose": "Renewal",
      "status": "Pending"
    };
    NetworkCall(
      `${config.api_url}/agreement_request/create_agreement_request`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        onCloseDialog()
        agreementReload()
        setDisable(false)

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Renewal request created successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });

      })
      .catch((err) => {
        console.log(err);
        setDisable(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });

      });
  };


 React.useEffect(()=>{

  const totalMonths = monthDiff(new Date(details?.lease_start_date), new Date(details?.lease_end_date))
  
  const lease_start_date  = addDays(new Date(details?.lease_end_date), 1);
 const lease_end_date = addMonths(new Date(details?.lease_end_date), totalMonths);


 setState({
  ...details,
  lease_start_date,
  lease_end_date
 })
 },[details])

  return (
    <Box p={2}>
      {/* customer card */}
      <Box className={classes.customerCard}>
        <Box display="flex" alignItems={"center"}>
          <Avatar
            src={details?.contact?.image_url}

            className={classes.customerIcon}
          />
          <Box width={"10px"} />
          <Box>
            <Typography className={classes.customerTitle}>
              {state?.contact?.first_name ?? ""} {state?.contact?.last_name ?? ""}
            </Typography >
            {/* <Box height={"8px"} /> */}
            < Box display="flex" alignItems={"center"} >
              <Typography className={classes.customerSub}>
                {state?.contact?.mobile_no_country_code ?? ""} {state?.contact?.mobile_no ?? ""}
              </Typography>
              <Box className={classes.dot} />
              <Typography className={classes.customerSub}>
                {state?.contact?.email_id ?? ""}

              </Typography>
            </Box>
          </Box >
        </Box >
        <Box height={"16px"} />
        <Divider />
        <Box height={"16px"} />
        <Box display="flex" alignItems={"center"}>
          <Box className={classes.customerIcon}>
            <NewAgreementIcon />
          </Box>
          <Box width={"10px"} />
          <Box>
            <Typography className={classes.customerTitle}>
              New Agreement
            </Typography>
            <Box height={"8px"} />
            <Typography className={classes.customerSub}>
              {timeZoneConverter(state?.lease_start_date)} - {timeZoneConverter(state?.lease_end_date)}

            </Typography>
          </Box>
        </Box >
      </Box >
      <Box height={"24px"} />
      <TextBox
        label={t("Remarks_optional")}
        placeholder={t("Remarks_optional")}
        value={remark ?? ""}
        onChange={(e) => {
          setRemark(e.target.value);
        }}
        multiline
      />
      <Box height={"24px"} />
      <Button className={classes.Yes} onClick={sumbit} disabled={disable ? true : remark?.length > 0 ? false : true}>{t("Submit")}</Button>

    </Box >
  );
};
