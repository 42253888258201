export const AssetMapping = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105115">
            <g
                data-name="FM - Asset Management - Asset Mapping"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 97896"
                    d="M14.715 11.286a3.122 3.122 0 0 1 .333-1.405l-.721-.595h-4.011l2.222 2Zm0 0"
                />
                <path data-name="Path 97897" d="M9.286 12.429h.571v.857h-.571Zm0 0" />
                <path
                    data-name="Path 97898"
                    d="m14.314 15.401.4-.267v-3.276h-2v4.857h1.255a1.017 1.017 0 0 1-.113-.457 1.029 1.029 0 0 1 .459-.857Zm0 0"
                />
                <path data-name="Path 97899" d="M9 15.001h1.143v1.714H9Zm0 0" />
                <path
                    data-name="Path 97900"
                    d="M14.429 16.258a.459.459 0 0 0 .459.457h7.656a.459.459 0 0 0 .254-.84l-.526-.351a.286.286 0 0 1-.129-.238v-4a2.574 2.574 0 0 0-2.571-2.571.286.286 0 0 1-.257-.414l.652-1.3h-2.5l.652 1.3a.286.286 0 0 1-.257.414 2.574 2.574 0 0 0-2.571 2.571v4a.286.286 0 0 1-.127.238l-.535.353a.457.457 0 0 0-.2.383Zm3.857-5.257h.143v-.572H19V11h.857v.577h-1.571a.429.429 0 1 0 0 .857h.857a1 1 0 0 1 0 2H19v.571h-.571v-.576h-.857v-.571h1.571a.429.429 0 1 0 0-.857h-.857a1 1 0 0 1 0-2Zm0 0"
                />
                <path
                    data-name="Path 97901"
                    d="M8.429 14.715a.286.286 0 0 1 .286-.286h1.714a.286.286 0 0 1 .286.286v2h1.428v-5.011L9.571 9.385 7 11.704v5.016h1.429Zm.286-2.571A.286.286 0 0 1 9 11.858h1.143a.286.286 0 0 1 .286.286v1.429a.286.286 0 0 1-.286.286H9a.286.286 0 0 1-.286-.286Zm0 0"
                />
                <path
                    data-name="Path 97902"
                    d="M22.98 17.286H7.02A2 2 0 0 0 9 19h4.285l2.127-.005a4.379 4.379 0 0 1 4.229.005H21a2 2 0 0 0 1.98-1.714Zm0 0"
                />
                <path
                    data-name="Path 97903"
                    d="m15.686 19.498-.073.04a.286.286 0 0 1-.137.035h-2.19a.572.572 0 0 0 0 1.143h2.286a.286.286 0 1 1 0 .571h-2.858a.286.286 0 0 1-.119-.026l-3.249-1.488a.648.648 0 0 0-.918.589v.062a.644.644 0 0 0 .358.58l3.331 1.665a3.153 3.153 0 0 0 1.405.332h5.762v-3.545a3.813 3.813 0 0 0-3.6.041Zm0 0"
                />
                <path
                    data-name="Path 97904"
                    d="M19.857 19.572v3.429H23v-3.429Zm1.714 2.286a.571.571 0 1 1-.571-.571.571.571 0 0 1 .571.571Zm0 0"
                />
                <path
                    data-name="Path 97905"
                    d="m10.281 19.572 1.9.871a1.12 1.12 0 0 1 .119-.871Zm0 0"
                />
            </g>
        </g>
    </svg>
)