export const PriceAppraisals = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 96941" fill={props?.fill ?? "#c1c5cb"}>
            <path
                data-name="Path 93639"
                d="m17.453 20.048.077-.077-.569-.569a3.709 3.709 0 1 1 1.165-1.168l.569.569.077-.077a.272.272 0 0 1 .373 0l1.661 1.661v-6.253L15 8.972l-5.808 5.162v8.476h10.45l-2.189-2.189a.264.264 0 0 1 0-.373Z"
            />
            <path
                data-name="Path 93640"
                d="m18.323 19.178-.511-.511a3.732 3.732 0 0 1-.419.419l.511.511Z"
            />
            <path data-name="Path 93641" d="M20.808 8.355h-2.112v1.651l2.112 1.9Z" />
            <path
                data-name="Path 93642"
                d="M15 14.162a2.112 2.112 0 1 0 2.112 2.112A2.114 2.114 0 0 0 15 14.162Zm0 1.848a.79.79 0 0 1 .264 1.535v.317h-.528v-.313a.791.791 0 0 1-.528-.743h.528a.264.264 0 1 0 .264-.264.79.79 0 0 1-.264-1.535v-.313h.528v.313a.791.791 0 0 1 .528.743h-.528a.264.264 0 1 0-.264.264Z"
            />
            <path
                data-name="Path 93643"
                d="m18.96 19.287-.947.947 2.18 2.18a.67.67 0 1 0 .947-.947Z"
            />
            <path
                data-name="Path 93644"
                d="M15 13.106a3.168 3.168 0 1 0 3.168 3.168A3.171 3.171 0 0 0 15 13.106Zm0 5.808a2.64 2.64 0 1 1 2.64-2.64 2.643 2.643 0 0 1-2.64 2.64Z"
            />
            <path
                data-name="Path 93645"
                d="m15 7.39-7.536 6.782.419.419 6.942-6.17a.263.263 0 0 1 .351 0l6.941 6.17.419-.419Z"
            />
        </g>
        <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
    </svg>
)
