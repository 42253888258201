import NonSelectedProperty from "./nonSelectedProperty";
import NonSelectedUnit from "./nonSelectedUnit";
import SelectedProperty from "./selectedProperty";
import SelectedUnit from "./selectedUnit";


export const PropertyUnitType = (t) => {
    return [
        {
            title: t("Properties"),
            value: "Property",
            selectedIcon: <SelectedProperty />,
            notSelectedIcon: <NonSelectedProperty />,
            disable: true
        },
        {
            title: t("Units"),
            value: "Unit",
            selectedIcon: <SelectedUnit />,
            notSelectedIcon: <NonSelectedUnit />,
            disable: true
        }
    ]
}


export const GetContactType = {
    Landlord: ["Property Owner"],
    Service: ["Customer"],
    Vendor: ["Vendor"],
    Agent: ["Vendor"]
}

export const GetMomentAddKey = {
    Monthly: "months",
    Yearly: "years",
    Weekly: "weeks"
}