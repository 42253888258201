import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DeleteIcon, GreenTickFilledCircle } from "../../../../assets";
import { SearchFilter, SelectBox, UseDebounce } from "../../../../components";
import { BackendRoutes } from "../../../../router/routes";
import { constructSelectedPropertyData, loadOptionsPropertyType } from "../../utils/common";
import { AddPropertyStyles } from "./style";

export const AddPropertyDialog = ({
    t,
    isOpen = true,
    setIsOpen = () => false,
    isButtonDisable = false,
    title = "",
    isSearchDropdownOpen = false,
    setIsSearchDropdownOpen = () => false,
    propertyDropdownList = [],
    setPropertyDropdownList = () => false,
    propertyDropdownOnClick = () => false,
    propertyDropdownNext = () => false,
    isPropertyDropdownLoading = true,
    setIsPropertyDropdownLoading = () => false,
    propertyUpdateList = [],
    setPropertyUpdateList = () => false,
    propertyTypeOption = [],
    propertyDropdownSearchText = "",
    setPropertyDropdownSearchText = () => false,
    propertyDropdownsearch = () => false,
    updateState = () => false,
}) => {
    const classes = AddPropertyStyles()
    const [propertyTypeDropdownLoading, setPropertyTypeDropdownLoading] = React.useState(false)
    const debounce = UseDebounce()
    // Function for search in search component
    const handleSearch = (v) => {
        setIsPropertyDropdownLoading(true)
        setPropertyDropdownSearchText(v)
        debounce(() => propertyDropdownsearch(v), 800)
    }

    const handleClose = () => {
        if (!isButtonDisable) {
            setIsSearchDropdownOpen(false)
            setIsOpen(false)
            setPropertyDropdownSearchText("")
            setPropertyDropdownList([])
            setPropertyUpdateList([])
            setIsPropertyDropdownLoading(true)
        }
    }

    const handleSearchOnClick = () => {
        if (!isButtonDisable && !isSearchDropdownOpen) {
            propertyDropdownOnClick()
            setIsSearchDropdownOpen(true)
        }
    }

    const handleClosePropertyDropdown = () => {
        if (!isButtonDisable) {
            setIsSearchDropdownOpen(false)
            setPropertyDropdownList([])
            setIsPropertyDropdownLoading(true)
        }
    }

    const handleDropdownClick = (v) => {
        if (!isButtonDisable) {
            let temp_selected = constructSelectedPropertyData(v, propertyTypeOption)
            setPropertyUpdateList([{ ...temp_selected }, ...propertyUpdateList])
            setIsSearchDropdownOpen(false)
            setPropertyDropdownList([])
            setIsPropertyDropdownLoading(true)
        }
    }

    const handleUpdate = (i, _) => {
        let payload = {
            id: _?.data?.id,
            property_type: _?.form?.selected_property_type?.value
        }
        updateState('sync', i, "updated", true, payload)
    }

    return (
        <Dialog
            className={classes.root}
            open={isOpen}
            onClose={handleClose}>
            <div className={classes.header_div}
                onClick={handleClosePropertyDropdown}>
                <Typography className={classes.title}>
                    {title}
                </Typography>
                <IconButton onClick={handleClose}
                    disabled={isButtonDisable}
                    className={classes.close_button}>
                    <CloseIcon htmlColor="#7C8594" height="14px" width="14px" />
                </IconButton>
            </div>
            <div className={classes.body_div}>
                <div className={classes.search_div}
                    onClick={handleSearchOnClick}>
                    <SearchFilter
                        color={"white"}
                        placeholder={t("Search Property")}
                        value={propertyDropdownSearchText}
                        handleChange={(v) => handleSearch(v)} />
                </div>
                {isSearchDropdownOpen && <div className={classes.search_dropdown_div} style={{ zIndex: 1 }}>
                    <div className={classes.search_dropdown_list_div}>
                        {isPropertyDropdownLoading ?
                            <div className={classes.loader_div}>
                                <Typography className={classes.loader_text}>
                                    {t("Properties Loading...")}
                                </Typography>
                            </div> :
                            (
                                (propertyDropdownList && propertyDropdownList?.length > 0) ?
                                    <InfiniteScroll
                                        dataLength={propertyDropdownList?.length ?? ""}
                                        next={propertyDropdownNext}
                                        hasMore={true}
                                        height={300}>
                                        <Stack divider={<div className={classes.divider} />}>
                                            {propertyDropdownList?.map((_) => {
                                                return (
                                                    <Stack className={classes.dropdown_root} onClick={() => handleDropdownClick(_?.data)}
                                                        direction={"row"} alignItems={"center"}>
                                                        <img src={_?.logo} alt={t("Property Logo")} className={classes.logo} />
                                                        <Stack spacing={"8px"} justifyContent={"space-between"} marginInlineStart={"8px"}>
                                                            <Typography className={classes.heading}>{_?.property_name}</Typography>
                                                            <Stack direction={"row"} alignItems={"center"}
                                                                divider={<div className={classes.period_divider} marginInline={"6px"} />}>
                                                                <Typography className={classes.sub_headingloc}>{_?.location}</Typography>
                                                                <Typography className={classes.sub_heading}>{_?.property_type}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>
                                                )
                                            })}
                                        </Stack>
                                    </InfiniteScroll> :
                                    <div className={classes.loader_div}>
                                        <Typography className={classes.loader_text}>
                                            {t("No Property Found")}
                                        </Typography>
                                    </div>)}
                    </div>
                </div>}
                <Stack spacing={"4px"} className={classes.selected_property_stack} onClick={handleClosePropertyDropdown}>
                    {propertyUpdateList?.map((_, i) => {
                        return <Stack direction={"row"} spacing={"8px"} alignItems={"center"} justifyContent={"space-between"}
                            className={classes.selected_property_root}>
                            <Stack direction={"row"} alignItems={"center"} width={"40%"}>
                                <img src={_?.logo} alt={t("Property Logo")} className={classes.logo} />
                                <Stack spacing={"8px"} justifyContent={"space-between"} marginInlineStart={"8px"}>
                                    <Typography className={classes.heading}>{_?.property_name}</Typography>
                                    <Stack direction={"row"} alignItems={"center"}
                                        divider={<div className={classes.period_divider} />}>
                                        <Typography className={classes.sub_headingloc}>{_?.location}</Typography>
                                        <Typography className={classes.sub_heading}>{_?.property_type}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <div style={{ width: "40%" }}>
                                <SelectBox
                                    isReadOnly={isButtonDisable || _?.updated}
                                    selectHeight={"32px"}
                                    placeholder={t("Choose global property type")}
                                    value={_?.form?.selected_property_type}
                                    loading={propertyTypeDropdownLoading==="global_property_type"}
                                    loadOptions={(search, array) => loadOptionsPropertyType(search, array,setPropertyTypeDropdownLoading,BackendRoutes.sync_global_property_type)}
                                    isPaginate={true}
                                    onChange={(v) => updateState('value', i, "selected_property_type", v)} />
                            </div>
                            <Stack direction={"row"} spacing={"4px"} alignItems={"center"} justifyContent={"end"} width={"20%"}>
                                <Button className={classes.update_button}
                                    disabled={_?.updated ? true : (isButtonDisable || !Boolean(_?.form?.selected_property_type?.value))}
                                    onClick={() => handleUpdate(i, _)}>
                                    <Stack spacing={"4px"} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                        {_?.updated && <GreenTickFilledCircle />}
                                        <Typography className={_?.updated ? classes.updated_text : classes.update_text}>
                                            {_?.updated ? t("Updated") : t("Update")}
                                        </Typography>
                                    </Stack>
                                </Button>
                                {!_?.updated && <IconButton onClick={() => updateState('delete', i)}
                                    disabled={isButtonDisable}
                                    className={classes.close_button}>
                                    <DeleteIcon />
                                </IconButton>}
                            </Stack>
                        </Stack>
                    })}
                </Stack>
            </div>
            <div className={classes.footer_div}
                onClick={handleClosePropertyDropdown}>
                <Button variant="contained" fullWidth
                    disabled={isButtonDisable}
                    className={classes.footer_button}
                    onClick={handleClose}>
                    {t("Done")}
                </Button>
            </div>
        </Dialog>
    )
}