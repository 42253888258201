import React, { Component } from 'react'
import InspectionRequest from './inspectionRequest'
import { withNavBars } from "../../HOCs";
import AssignModalContextProvider from '../../contexts/assignModalContext';
class InspectionReqParent extends Component {
  render() {
    return (
      <AssignModalContextProvider>
        <InspectionRequest />
      </AssignModalContextProvider>
    )
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(InspectionReqParent, props);