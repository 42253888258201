import * as React from "react"
const VisitorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}
  >
    <defs>
      <radialGradient
        id="d"
        cx={0.5}
        cy={0.5}
        r={0.438}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </radialGradient>
      <radialGradient
        id="b"
        cx={0.072}
        cy={0.077}
        r={1.764}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c26715" />
        <stop offset={0.508} stopColor="#b85515" />
        <stop offset={1} stopColor="#ad3f16" />
      </radialGradient>
      <radialGradient xlinkHref="#b" id="e" cx={0.297} cy={0.562} r={4.693} />
      <linearGradient
        id="a"
        x1={0.25}
        x2={0.882}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c48f0c" />
        <stop offset={0.251} stopColor="#d19b16" />
        <stop offset={0.619} stopColor="#dca51f" />
        <stop offset={1} stopColor="#e0a922" />
      </linearGradient>
      <linearGradient xlinkHref="#a" id="c" x1={88.782} x2={89.414} />
    </defs>
    <g data-name="Group 113641">
      <g data-name="icons8-account (5)">
        <path
          fill="#33c481"
          d="M20.487 20.359C14.143 20.359 9 23.888 9 27.744v.821a1.436 1.436 0 0 0 1.436 1.436h20.1a1.436 1.436 0 0 0 1.436-1.436v-.821c.002-3.856-5.14-7.385-11.485-7.385Z"
          data-name="Path 100431"
        />
        <path
          fill="#21a366"
          d="M24.794 21.815v-.885a16.521 16.521 0 0 0-8.615 0v.885c0 1.635 4.308 6.031 4.308 6.031s4.307-4.397 4.307-6.031Z"
          data-name="Path 100432"
        />
        <path
          fill="#d6a121"
          d="M24.076 21.385c0 1.362-3.59 5.026-3.59 5.026s-3.589-3.66-3.589-5.026v-5.769h7.179Z"
          data-name="Path 100433"
        />
        <path
          fill="#fff"
          d="M24.076 21.385c0 1.362-3.59 5.026-3.59 5.026s-3.589-3.664-3.589-5.026a5.493 5.493 0 0 0 3.59 1.436 5.493 5.493 0 0 0 3.589-1.436Z"
          data-name="Path 100434"
        />
        <path
          fill="url(#a)"
          d="M32.767 18h-.378v2.872h.5a.733.733 0 0 0 .747-.531l.247-1.223A1.023 1.023 0 0 0 32.767 18Z"
          data-name="Path 100435"
          transform="translate(-5.879 -6.667)"
        />
        <path
          fill="url(#c)"
          d="M14.637 18h.378v2.872h-.5a.733.733 0 0 1-.747-.531l-.247-1.223A1.023 1.023 0 0 1 14.637 18Z"
          data-name="Path 100436"
          transform="translate(-.551 -6.667)"
        />
        <path
          fill="url(#d)"
          d="M21.462 6.4C18.273 6.4 15 6.885 15 13.777v4.809c0 2.46 3.877 5.476 6.462 5.476s6.462-3.015 6.462-5.476v-4.809C27.923 6.885 24.65 6.4 21.462 6.4Z"
          data-name="Path 100437"
          transform="translate(-.974 -3.395)"
        />
        <path
          fill="url(#b)"
          d="M21.179 5.545c-3.126 0-3.59 1.175-3.59 1.175C16.319 6.907 14 8.769 14 10.9a29.377 29.377 0 0 0 .718 5.026c.391-4.7 1.615-6.462 2.872-6.462.91 0 1.632.718 3.59.718 1.653 0 2.064-.718 3.59-.718 2.154 0 2.872 5.721 2.872 6.462a31.116 31.116 0 0 0 .718-5.026c-.001-2.465-2.723-5.355-7.181-5.355Z"
          data-name="Path 100438"
          transform="translate(-.692 -3.154)"
        />
        <path
          fill="url(#e)"
          d="M22.747 5C19.758 5 19 6.567 19 6.567a3.393 3.393 0 0 0 .375 1.567s.546 1.175 3.662 1.175A4.335 4.335 0 0 0 27.616 5Z"
          data-name="Path 100439"
          transform="translate(-2.103 -3)"
        />
        <path
          fill="#21a366"
          d="M19.769 26.41h1.436V30h-1.436z"
          data-name="Rectangle 57518"
        />
      </g>
    </g>
  </svg>
)
export default VisitorIcon
