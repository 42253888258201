import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SemiBold } from "../../utils";
import { Label, SelectBox } from "../index";
export const useStyles = makeStyles((theme) => ({
  durationBox: {
    display: "flex",
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    height: (props) => props?.customHeight ?? "auto"
  },
  borderRight: {
    borderRight: "1px solid #E4E8EE",
    height: (props) => props?.customHeight ?? "auto"
  },
  durationType: {
    //padding: "10px",
    height: (props) => props?.customHeight ?? "auto"
  },
  durationLabel: {
    fontSize: "12px",
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    marginBottom: (props) => props?.marginBottom ?? "5px",
  },
  menu: {
    "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
    {
      borderRadius: theme.palette.borderRadius,
      padding: "0px !important",
      boxShadow: "0px 8px 24px #0717411F",
    },
    "& .MuiMenu-list": {
      padding: "0 !important",
    },
  },
  priorityName: {
    fontSize: "12px",
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
  },
  value: {
    fontFamily: SemiBold,
    fontSize: 14,
    color: "#091B29",
    padding: (props) => props?.customPadding ?? "auto"
  },
  arrow: {
    fontSize: "20px",
    color: "#091B29",
  },
  selectBox: {
    width: "100%",
    border: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "7px",
      border: "none",
    },

  },
}));
export const DurationBox = ({
  val = "",
  options = [],
  onChange = () => false,
  value = "",
  isError = false,
  errorMessage = "",
  isReadonly = false,
  isRequired = false,
  fontFamily = SemiBold,
  size = {
    item: 6.5,
    item1: 5.5,
  },
  marginBottom = "5px",
  menuPlacement = "auto",
}) => {
  const classes = useStyles({ marginBottom });


  const handleClose = (key, value) => {
    if (key === "save") {
      onChangeValue("select", value);
    }
  };
  const onChangeValue = (key, val) => {
    let updateValue = {
      ...value,
      [key]: val,
    };
    onChange && onChange(updateValue);
  };
  return (
    <Box>
      {val?.label && <Label label={val?.label} isRequired={isRequired} />}
      <Box className={classes.durationBox}>
        <Grid container>
          <Grid item xs={size?.item} className={classes.borderRight}>

            <input
                type="number"
                value={val?.value?.value}
                onChange={(e) => onChangeValue("value", e?.target?.value)}
                disabled={isReadonly}
                className={classes.value}
                onKeyPress={(e) => {
                  if (e.key === "e") {
                    e.preventDefault();
                  }
                }}
              /> 
          </Grid>
          <Grid item xs={size?.item1} className={classes.durationType}>

            <SelectBox
              options={options}
              value={{
                value: val?.value?.select,
                label: val?.value?.select
              }}
              onChange={(val) => handleClose("save", val.value)}
              noBorder={true}
              noSearch={true}
              isReadOnly={isReadonly}
              fontFamily={fontFamily}
              menuPlacement={menuPlacement ?? "auto"}
            />

          </Grid>
        </Grid>
      </Box >
      {
        isError && (
          <Typography variant={"caption"} color={"error"}>
            {errorMessage}
          </Typography>
        )
      }



    </Box >
  );
};
