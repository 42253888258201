export const Leads = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98406">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g transform="translate(-37 7)" fill={props?.fill ?? "#c1c5cb"}>
                    <circle
                        data-name="Ellipse 129217"
                        cx={1.758}
                        cy={1.758}
                        r={1.758}
                        transform="translate(47.318)"
                    />
                    <circle
                        data-name="Ellipse 129218"
                        cx={1.758}
                        cy={1.758}
                        r={1.758}
                        transform="translate(53.471)"
                    />
                    <path
                        data-name="Path 94810"
                        d="M58.3 7.031H46v1.108l4.395 3.076V15l3.515-1.169v-2.613l4.395-3.076Z"
                    />
                    <path
                        data-name="Path 94811"
                        d="M46.439 6.153h5.273a2.637 2.637 0 0 0-5.273 0Z"
                    />
                    <path
                        data-name="Path 94812"
                        d="M52.592 6.153h5.273a2.637 2.637 0 0 0-5.273 0Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
