import React from "react";
import { viewCardStyle } from "./style";
import { Box, Typography } from "@mui/material";

export const ViewCard = (props) => {
  const classes = viewCardStyle(props);
  const { data } = props;
  return (
    <Box className={classes.root}>
      <Box>
        {/* title */}
        <Typography className={classes.title}>{data?.title}</Typography>
        <Box display="flex" alignItems="center">
          {data?.subCatagory?.map((x, index) => {
            return (
              <Box display="flex" alignItems="center">
                {/* dot */}
                {index !== 0 && <Box className={classes.dot} />}
                {/* subGataogry */}
                <Typography className={classes.subCatagory}>
                  {x?.title}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className={classes.textAlign}>
        {data?.amount?.map((item) => {
          return (
            <Box className={classes.marginLest}>
              <Typography className={classes.total}>{item?.total ?? 0}</Typography>
              <Typography className={classes.units}>{item?.units ?? "Units"}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
