import { Box } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { DetailsCardCoponent } from "../../../components";
const RevenueSummery = ({ details = {}, t }) => {
    const card = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementCurrency"),
            subtitle: `${details?.quotation?.currency?.symbol}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementTotalAmount"),
            subtitle: `${details?.quotation?.currency?.symbol} ${details?.total_amount}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementTotalDiscount"),
            subtitle: `${details?.quotation?.currency?.symbol} ${details?.total_discount}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementTotalTaxes"),
            subtitle: `${details?.quotation?.currency?.symbol} ${details?.total_tax}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("agreementTotalRefundable"),
            subtitle: `${details?.quotation?.currency?.symbol} ${details?.total_refundable}`
        }
    ]

    return (
        <Box p={2}>
            <DetailsCardCoponent components={card} />
        </Box>
    )
}
export default withNamespaces("agreement")(RevenueSummery); 