export const NewResidents = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={32}
        height={32}
        {...props}
    >
        <defs>
            <linearGradient
                id="b"
                x1={0.333}
                y1={92.805}
                x2={0.667}
                y2={93.726}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#ffcf54" />
                <stop offset={0.261} stopColor="#fdcb4d" />
                <stop offset={0.639} stopColor="#f7c13a" />
                <stop offset={1} stopColor="#f0b421" />
            </linearGradient>
            <linearGradient
                id="a"
                x1={0.403}
                y1={82.784}
                x2={0.536}
                y2={83.86}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#32bdef" />
                <stop offset={1} stopColor="#1ea2e4" />
            </linearGradient>
            <linearGradient
                id="c"
                x1={0.31}
                y1={53.833}
                x2={0.669}
                y2={54.971}
                xlinkHref="#a"
            />
            <linearGradient
                id="d"
                x1={0.047}
                y1={74.884}
                x2={1.36}
                y2={75.694}
                xlinkHref="#b"
            />
        </defs>
        <g data-name="Group 102613">
            <path
                data-name="Path 96865"
                d="M25.547 28H6.818a1.174 1.174 0 0 1-1.171-1.171V14.536l10.537-9.951 10.537 9.951v12.293A1.174 1.174 0 0 1 25.547 28Z"
                fill="#d35230"
            />
            <path
                data-name="Path 96866"
                d="M15.305 27.122a1.116 1.116 0 0 1-1.171-1.054v-3.571a.528.528 0 0 1-.293.059.913.913 0 0 1-.878-.937v-3.98a2.774 2.774 0 0 1 2.81-2.81h.82a2.849 2.849 0 0 1 2.81 2.81v3.98a.913.913 0 0 1-.878.937.528.528 0 0 1-.293-.059v3.571a1.116 1.116 0 0 1-1.168 1.054Z"
                opacity={0.07}
            />
            <path
                data-name="Path 96867"
                d="M16.184 5.171a1.233 1.233 0 0 0-.761.293l-3.161 2.807-6.615 6.266v3.161l10.537-9.366 10.537 9.366v-3.161l-6.615-6.266-3.159-2.807a1.233 1.233 0 0 0-.763-.293Z"
                opacity={0.05}
            />
            <path
                data-name="Path 96868"
                d="M16.184 4.585a1.233 1.233 0 0 0-.761.293l-3.161 2.807-6.615 6.207v3.161l10.537-9.307 10.537 9.307v-3.161l-6.615-6.207-3.159-2.807a1.233 1.233 0 0 0-.763-.293Z"
                opacity={0.07}
            />
            <path
                data-name="Path 96869"
                d="M16.184 14.361a1.974 1.974 0 0 1-1.99-1.99 1.925 1.925 0 0 1 1.99-1.932 1.974 1.974 0 0 1 1.99 1.99 1.925 1.925 0 0 1-1.99 1.932Z"
                opacity={0.07}
            />
            <path
                data-name="Path 96870"
                d="M16.887 14.537a2.343 2.343 0 0 0 1.58-2.166 2.286 2.286 0 0 0-2.283-2.283 2.279 2.279 0 0 0-.7 4.449 3.115 3.115 0 0 0-2.81 3.1v3.98a1.226 1.226 0 0 0 1.171 1.229v3.22a1.39 1.39 0 0 0 1.463 1.346h1.754a1.39 1.39 0 0 0 1.463-1.346v-3.22a1.188 1.188 0 0 0 1.171-1.229v-3.98a3.158 3.158 0 0 0-2.809-3.1Z"
                opacity={0.05}
            />
            <path
                data-name="Path 96871"
                d="M28.184 14.244 16.945 4.293a1.136 1.136 0 0 0-1.522 0L4.184 14.244a.627.627 0 0 0-.059.82l.937 1a.627.627 0 0 0 .82.059l10.3-8.956 10.244 8.956a.569.569 0 0 0 .82-.059l.937-1a.566.566 0 0 0 .001-.82Z"
                fill="#b83c1f"
            />
            <path
                data-name="Path 96872"
                d="M22.8 16.8a1.7 1.7 0 1 1 1.7-1.7 1.7 1.7 0 0 1-1.7 1.7"
                transform="translate(-6.614 -2.727)"
                fill="url(#b)"
            />
            <path
                data-name="Path 96873"
                d="M14.428 20.976v5.093a.8.8 0 0 0 .878.761h1.7a.844.844 0 0 0 .878-.761v-5.093Z"
                fill="#007ad9"
            />
            <path
                data-name="Path 96874"
                d="M19 24.8v-1.283A2.5 2.5 0 0 1 21.517 21h.82a2.5 2.5 0 0 1 2.517 2.517V24.8Z"
                transform="translate(-5.743 -5.878)"
                fill="url(#a)"
            />
            <path
                data-name="Path 96875"
                d="M22.756 21A1.725 1.725 0 0 0 21 22.756v4.1h3.512v-4.1A1.725 1.725 0 0 0 22.756 21Z"
                transform="translate(-6.572 -5.878)"
                fill="url(#c)"
            />
            <path
                data-name="Path 96876"
                d="M28.171 26H27v3.571a.588.588 0 1 0 1.171 0Z"
                transform="translate(-9.06 -7.951)"
                fill="url(#d)"
            />
            <path
                data-name="Path 96877"
                d="M20.171 26H19v3.571a.588.588 0 1 0 1.171 0Z"
                transform="translate(-5.743 -7.951)"
                fill="url(#d)"
            />
        </g>
    </svg>
)