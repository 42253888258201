import * as React from "react"

export const Purpose = (props) => {

    const {
        width = 16,
        height = 16,
    } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={width}
            height={height}
            {...props}
        >
            <g id="Group_96235" data-name="Group 96235">
                <g id="Rectangle_51039" data-name="Rectangle 51039" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="16" height="16" stroke="none" />
                    <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
                </g>
                <path id="icons8-house-keys" d="M12.429,4a.534.534,0,0,0-.331.114l-5.076,4A2.663,2.663,0,0,0,6,10.218v6.818a.894.894,0,0,0,.893.893h3.571a.894.894,0,0,0,.893-.893V13.107a.179.179,0,0,1,.179-.179h1.786a.179.179,0,0,1,.179.179v1.878l1.071-1.071v-.806a1.251,1.251,0,0,0-1.25-1.25H11.536a1.251,1.251,0,0,0-1.25,1.25v3.75H7.071V10.218a1.6,1.6,0,0,1,.612-1.262l4.745-3.739,4.745,3.739a1.6,1.6,0,0,1,.612,1.262v.211a3.192,3.192,0,0,1,1.071.188v-.4a2.663,2.663,0,0,0-1.021-2.1l-5.076-4A.535.535,0,0,0,12.429,4Zm5.357,7.143a2.5,2.5,0,0,0-2.447,3.013l-2.806,2.806a.356.356,0,0,0-.1.252v1.429a.357.357,0,0,0,.357.357h1.429a.357.357,0,0,0,.357-.357v-.714h.714a.357.357,0,0,0,.357-.357v-.714h.714a.357.357,0,0,0,.357-.357v-.6a2.5,2.5,0,1,0,1.071-4.756Zm.357,1.429a.714.714,0,1,1-.714.714A.714.714,0,0,1,18.143,12.571Z" transform="translate(-5.142 -3.5)" fill="#98a0ac" />
            </g>
        </svg>
    )
}

