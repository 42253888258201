import * as React from "react"
const VacantUnitIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 105250" transform="translate(-103 -277)">
      <circle
        cx={20}
        cy={20}
        r={20}
        fill="#eef9ee"
        data-name="Ellipse 129562"
        transform="translate(103 277)"
      />
      <g data-name="Group 105249">
        <path fill="none" d="M111 285h24v24h-24z" data-name="Rectangle 33" />
      </g>
      <g data-name="Group 105462">
        <path fill="none" d="M113 287h20v20h-20z" data-name="Rectangle 55517" />
        <path
          fill="#5ac782"
          d="M122.238 289.002a.574.574 0 0 0-.354.123l-5.414 4.264a2.838 2.838 0 0 0-1.089 2.244v7.269a.953.953 0 0 0 .952.952h6.194a4.95 4.95 0 0 1 6.567-7.218v-1.003a2.838 2.838 0 0 0-1.089-2.244l-5.413-4.266a.574.574 0 0 0-.354-.121Zm4.19 7.619a4.19 4.19 0 1 0 4.19 4.19 4.19 4.19 0 0 0-4.19-4.192Zm2.286 2.281a.381.381 0 0 1 .269.65l-3.047 3.05a.381.381 0 0 1-.539 0l-1.524-1.524a.381.381 0 0 1 .539-.539l1.254 1.254 2.778-2.778a.38.38 0 0 1 .27-.113Z"
          data-name="icons8-smart-home-checked (2)"
        />
      </g>
    </g>
  </svg>
)
export default VacantUnitIcon
