import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SelectBox } from "../../components";
import { enumSelect, enum_types } from "../../utils";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  Label: {
    color: "#98A0AC",
    fontSize:"0.75rem",
    marginTop: "10px",
  },
  Requirements: {
    padding: "10px",
    width: "100%",
    textAlign: "center",
    borderRadius: "10px",
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",
    width: "100%",
    textAlign: "center",
    borderRadius: "10px",

    fontSize:"0.875rem",

    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  Filter: {
    marginBottom: "20px",
  },
}));

const FilterComponent = (props) => {
  const { t } = props
  const classes = useStyles();
  const [enumValue, setEnumValue] = React.useState({
    type: []
  })
  const getEnum = async () => {
    const result = await enumSelect([enum_types.general_maintenance_type])
    setEnumValue({
      type: result?.general_maintenance_type.filter(i => i?.value !== "Reopened"),
    })
  }
  React.useEffect(() => {
    getEnum()
  }, [])
  return (
    <div>
      {/* company name */}
      <div className={classes.Filter}>
        <Typography variant="body1" className={classes.Label} gutterBottom>
          {t("Request Type")} <sup style={{ color: "red" }}>*</sup>
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Typography
              onClick={() => {
                props?.upDateFilter("Type", "General");
              }}
              className={
                props?.filterdata?.Type?.includes("General")
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              {t("General")}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              onClick={() => {
                props?.upDateFilter("Type", "Maintenance");
              }}
              style={{ width: "98px" }}
              className={
                props?.filterdata?.Type?.includes("Maintenance")
                  ? classes.Requirements
                  : classes.Requirementsqfts
              }
            >
              {t("Maintenance")}
            </Typography>
          </Grid>
        </Grid>
        {props?.filterdata?.error?.Type?.length > 0 && (
          <span style={{ fontSize:"0.75rem", color: "red" }}>
            {t("Requirement Type is required")}
          </span>
        )}
      </div>
      {/*Priority Type */}
      <Divider />
      <div className={classes.Filter} style={{ marginTop: "10px" }}>
        <SelectBox
          label="Property"
          options={props?.property}
          placeholder={t("Select Property")}
          value={props?.filterdata?.propperty}
          onChange={(value) => {
            props?.upDateFilter("propperty", value);
          }}
          isError={props?.filterdata?.error?.propperty?.length > 0}
          errorMessage={props?.filterdata?.error?.propperty}
        />
      </div>
      <Divider />
      <div className={classes.Filter}>
        <Typography variant="body1" className={classes.Label} gutterBottom>
          {t("Units")}
        </Typography>
        <SelectBox
          options={props?.units}
          placeholder={t("Units")}
          value={props?.filterdata?.unit}
          onChange={(value) => {
            props?.upDateFilter("unit", value);
          }}
          isError={props?.filterData?.error?.unit?.length > 0}
          errorMessage={props?.filterData?.error?.unit}
        />
      </div>
      {props?.filterdata?.Type.includes("General") ? (
        <>
          <Divider />
          {/*  General Status */}
          <Typography variant="body1" className={classes.Label} gutterBottom>
            {t("General Status")}
          </Typography>
          <Grid container spacing={1} className={classes.Filter}>
            {
              enumValue?.type?.map((val) => {
                return (
                  <Grid item xs={4}>
                    <Typography
                      onClick={() => {
                        props?.upDateFilter("status", val?.value);
                      }}
                      className={
                        props?.filterdata?.status?.includes(val?.value)
                          ? classes.Requirements
                          : classes.Requirementsqfts
                      }
                    >
                      {val?.label}
                    </Typography>
                  </Grid>
                )
              })
            }

          </Grid>
        </>
      ) : (
        <>
          <Divider />
          {/*  Maintanance Status */}
          <Typography variant="body1" className={classes.Label} gutterBottom>
            {t("Maintanance Status")}
          </Typography>
          <Grid container spacing={1} className={classes.Filter}>
            {
              enumValue?.type?.map((val) => {
                return (
                  <Grid item xs={4}>
                    <Typography
                      onClick={() => {
                        props?.upDateFilter("status", val?.value);
                      }}
                      className={
                        props?.filterdata?.status?.includes(val?.value)
                          ? classes.Requirements
                          : classes.Requirementsqfts
                      }
                    >
                      {val?.label}
                    </Typography>
                  </Grid>
                )
              })
            }

          </Grid>
        </>
      )}
    </div>
  );
};
export default withNamespaces("filterComponent")(FilterComponent)
