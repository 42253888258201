import React from "react"

export const AssetsCovered = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.16" viewBox="0 0 24 24.16">
        <g id="Group_118506" data-name="Group 118506" transform="translate(-197 -106)">
          <g id="Rectangle_52035" data-name="Rectangle 52035" transform="translate(197 106)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="24" height="24" stroke="none"/>
            <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
          </g>
          <path id="icons8-fridge" d="M9,14.8v9.9A3.3,3.3,0,0,0,12.3,28H23.7A3.3,3.3,0,0,0,27,24.7V14.8Zm5.4,5.7a.9.9,0,1,1-1.8,0v-3a.9.9,0,0,1,1.8,0ZM27,13V7.3A3.3,3.3,0,0,0,23.7,4H12.3A3.3,3.3,0,0,0,9,7.3V13ZM12.6,8.5a.9.9,0,1,1,1.8,0v1.8a.9.9,0,0,1-1.8,0Z" transform="translate(191 102.16)" fill="#896db3"/>
        </g>
      </svg>
      
    )
}