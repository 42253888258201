import { makeStyles } from "@mui/styles";

export const InviteTriggerStyle = makeStyles((theme) => ({
    contentBox: {
        background: "#fff",
        width: "100%",
        borderRadius: 4,
        height: `calc(100vh - 150px)`,
        boxShadow: "0px 0px 16px #00000014",
        overflow: "overlay"

    },
    nameTitle: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    title: {
        fontSize:"0.875rem",
        fontWeight: "bold",
        color: theme.typography.color.primary
    },
    subtitle: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    inviteBox: {
        height: `calc(100vh - 320px)`,
        overflow: "auto"
    },
    listGrid: {
        height: `calc(100vh - 150px)`,
        background: "#fff",
        borderRadius: 4,
        boxShadow: "0px 0px 16px #00000014"
    },
    memberList: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    inviteBtn: {
        height: 42,
        width: "auto",
        whiteSpace: "no-wrap"
    },
}))