import React from "react"
import { withNavBars } from "../../HOCs"
import FeedBackSubCategoryMaster from "./feedbackSubCategory"


const FeedbackSubCategoryMasterParent = () => {
    return (
        <FeedBackSubCategoryMaster />
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(FeedbackSubCategoryMasterParent, props)