import { Box } from "@mui/material"
import React from "react"
import { useLocation } from "react-router"
import { withNavBars } from "../../../HOCs"
import BasicDetails from "./basicDetails"
import  ViewFeedBackForm  from "./viewFeedBackForm"

const CreateSurveyForm = () => {
    const { state } = useLocation()
    return (
        <Box>
            {state?.main?.is_view ?
                <ViewFeedBackForm state={state} />
                :
                <BasicDetails state={state} />
            }
        </Box>
    )
}
const props = {
    boxShadow: false,
};
export default withNavBars(CreateSurveyForm, props);