import * as React from "react"

export const QuotationIMG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={14.4} height={18} {...props}>
        <path
            data-name="icons8-regular-document (1)"
            d="M2.025 0A2.035 2.035 0 0 0 0 2.025v13.95A2.035 2.035 0 0 0 2.025 18h10.35a2.035 2.035 0 0 0 2.025-2.025V2.025A2.035 2.035 0 0 0 12.375 0Zm0 1.35h10.35a.665.665 0 0 1 .675.675v13.95a.665.665 0 0 1-.675.675H2.025a.665.665 0 0 1-.675-.675V2.025a.665.665 0 0 1 .675-.675Zm2.25 2.25a.675.675 0 1 0 0 1.35h2.25a.675.675 0 1 0 0-1.35Zm0 4.05a.675.675 0 1 0 0 1.35h5.85a.675.675 0 1 0 0-1.35Zm0 2.7a.675.675 0 1 0 0 1.35h5.85a.675.675 0 1 0 0-1.35Zm0 2.7a.675.675 0 1 0 0 1.35h4.05a.675.675 0 1 0 0-1.35Z"
            fill={props?.color}
        />
    </svg>
)

