import * as React from "react"
const BillingContactIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 113946" transform="translate(7176 23931)">
      <rect
        width={40}
        height={40}
        fill="#edf7f8"
        data-name="Rectangle 57624"
        rx={8}
        transform="translate(-7176 -23931)"
      />
      <g data-name="Group 113948">
        <path
          fill="#6dafb3"
          d="M-7148.4-23900h-14.3v-22h14.3a2.206 2.206 0 0 1 2.2 2.2v17.6a2.206 2.206 0 0 1-2.2 2.2Z"
          data-name="Path 100585"
        />
        <path
          fill="#337276"
          d="M-7163.8-23922h1.1v22h-1.1a2.206 2.206 0 0 1-2.2-2.2v-17.6a2.206 2.206 0 0 1 2.2-2.2Z"
          data-name="Path 100586"
        />
        <path
          fill="#337276"
          d="M-7155-23915.4a2.2 2.2 0 1 0 2.2 2.2 2.2 2.2 0 0 0-2.2-2.2Zm3.85 7.7a4.27 4.27 0 0 0-3.85-2.2 4.27 4.27 0 0 0-3.85 2.2v1.1h7.7Z"
          data-name="Path 100587"
        />
      </g>
    </g>
  </svg>
)
export default BillingContactIcon
