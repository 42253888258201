import { Stack, Typography } from "@mui/material"
import { useStyles } from "./style"

export const BubbleTab = ({ t, path = [], data = {}, selected = {}, onClick = () => false }) => {
    const classes = useStyles()

    return <Stack direction={"row"} className={classes.root}>
        {path?.map((i) => {
            return <div
                className={
                    selected?.value === i ?
                        classes.selected_tab :
                        classes.unselected_tab
                }
                onClick={() => onClick(data?.[i])}>
                <Typography
                    className={
                        selected?.value === i ?
                            classes.selected_tab_text :
                            classes.unselected_tab_text
                    }>
                    {t(data?.[i]?.label)}
                </Typography>
            </div>
        })}
    </Stack>
}