import * as React from "react"

const QuotationLeaseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_102894" data-name="Group 102894" transform="translate(-274 -230)">
                <rect id="Rectangle_54897" data-name="Rectangle 54897" width="18" height="18" transform="translate(274 230)" fill="none" />
                <path id="icons8-bookmark-page" d="M9.575,4A1.577,1.577,0,0,0,8,5.575v10.85A1.577,1.577,0,0,0,9.575,18h8.05A1.577,1.577,0,0,0,19.2,16.425V9.6H15.175A1.577,1.577,0,0,1,13.6,8.025V4Zm5.075.308V8.025a.526.526,0,0,0,.525.525h3.717ZM13.633,10.4a.432.432,0,0,1,.384.314l.418,1.341h1.351l.072.005a.463.463,0,0,1,.185.82L14.95,13.7l.418,1.34.016.069a.44.44,0,0,1-.69.441L13.6,14.726l-1.094.829-.058.038a.445.445,0,0,1-.616-.548l.418-1.34-1.093-.829-.055-.049a.46.46,0,0,1,.312-.777l1.352,0,.418-1.338.029-.074A.428.428,0,0,1,13.633,10.4Z" transform="translate(269 228)" fill="#fff" />
            </g>
        </svg>

    )
}
export default QuotationLeaseIcon