import * as React from "react"

export const Document = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={38} height={48} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.048}
        y1={250.588}
        x2={0.509}
        y2={250.127}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#4997e8" />
      </linearGradient>
    </defs>
    <path
      data-name="Path 97716"
      d="M38 13.895v31.579A2.529 2.529 0 0 1 35.467 48H2.533A2.529 2.529 0 0 1 0 45.474V2.526A2.529 2.529 0 0 1 2.533 0h21.534l3.8 10.105Z"
      fill="#acf3ff"
    />
    <path
      data-name="Path 97717"
      d="M28 5v11.368a2.53 2.53 0 0 0 2.535 2.526h11.409Z"
      transform="translate(-3.944 -5)"
      fill="url(#a)"
    />
    <path
      data-name="Path 97718"
      d="M29.762 24H8.239a.633.633 0 0 1-.633-.631v-1.263a.633.633 0 0 1 .633-.632h21.523a.633.633 0 0 1 .633.632v1.263a.633.633 0 0 1-.633.631Z"
      fill="#1a9cc6"
    />
    <path
      data-name="Path 97719"
      d="M27.242 29.052H8.228a.633.633 0 0 1-.634-.631v-1.263a.633.633 0 0 1 .634-.632h19.014a.633.633 0 0 1 .634.632v1.263a.633.633 0 0 1-.634.631Z"
      fill="#1a9cc6"
    />
    <path
      data-name="Path 97720"
      d="M29.762 34.105H8.239a.633.633 0 0 1-.633-.631v-1.263a.633.633 0 0 1 .633-.632h21.523a.633.633 0 0 1 .633.632v1.263a.633.633 0 0 1-.633.631Z"
      fill="#1a9cc6"
    />
    <path
      data-name="Path 97721"
      d="M27.242 39.158H8.228a.633.633 0 0 1-.634-.631v-1.263a.633.633 0 0 1 .634-.632h19.014a.633.633 0 0 1 .634.632v1.263a.633.633 0 0 1-.634.631Z"
      fill="#1a9cc6"
    />
  </svg>
)
