import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { AmenityDetailsStyle } from './style'
import { ProfileCard } from '../../alreadyPaid/components/profileCard'
import { withNamespaces } from 'react-i18next'
import { stringAvatar } from '../../../utils'

const AmenityDetails = (
    {
        amenityBookingDetail = {},
        t = () => false
    }
) => {
    const classes = AmenityDetailsStyle()
        return (
        <Box p={"16px 16px 8px 16px"} ml={2} mt={1} className={classes.box}>
            <Stack spacing={2}>
                <DetailsCard
                    title={t('PROPERTYDETAILS')}
                    data={{
                        url: amenityBookingDetail?.amenity_assets,
                        name: amenityBookingDetail?.property_name,
                        content: `${amenityBookingDetail?.area}${amenityBookingDetail?.city && ','} ${amenityBookingDetail?.city}`
                    }} />
                <DetailsCard
                    title={amenityBookingDetail?.is_walkin ? t("CONTACT DETAIL") : t('UNITDETAILS')}
                    data={{
                        url: amenityBookingDetail?.is_walkin ? amenityBookingDetail?.walkin_contact?.image_url : amenityBookingDetail?.unit_logo,
                        name: amenityBookingDetail?.is_walkin ? amenityBookingDetail?.walkin_contact?.first_name : amenityBookingDetail?.unit_name,
                        content: amenityBookingDetail?.is_walkin ? `${amenityBookingDetail?.walkin_contact?.mobile_no_country_code ?? ""} ${amenityBookingDetail?.walkin_contact?.mobile_no ?? "-"}, ${amenityBookingDetail?.walkin_contact?.email_id ?? "-"}` : amenityBookingDetail?.unit_no,
                    }} />
                <Stack spacing={1}>
                    <Typography className={classes.title}>{t("bookedby")}</Typography>
                    <ProfileCard data={{
                        Created_by: {
                            image_url: amenityBookingDetail?.user_profile_picture ?? "-",
                            first_name: amenityBookingDetail?.user_first_name ?? "-",
                            mobile_no_country_code: amenityBookingDetail?.mobile_country_code ?? "-",
                            mobile_no: amenityBookingDetail?.user_mobile_no ?? "-",
                            email_id: amenityBookingDetail?.email_id ?? "-"
                        }
                    }} />
                </Stack>
            </Stack>
        </Box>
    )
}

const DetailsCard = ({ title = "", data = {} }) => {
    const classes = AmenityDetailsStyle()
    return (
        <Stack spacing={1}>
            <Typography className={classes.title}>{title}</Typography>
            <Stack direction={"row"} spacing={1}>
                <Avatar className={classes.avatar} src={data.url}{...stringAvatar(data?.name)}></Avatar>
                <Stack>
                    <Typography className={classes.name}>{data.name ?? "-"}</Typography>
                    <Typography className={classes.content}>{data.content ?? "-"}</Typography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default withNamespaces("amenityBooking")(AmenityDetails)
