import { Box, Grid } from "@mui/material";
import React from 'react';
import { DialogWithTable } from "../../../components/dialogWithTable";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";

export const OtherInfo = (props) => {
    const { t } = props
    // eslint-disable-next-line
    const [count, setCount] = React.useState({
        owners: 0,
        resident: 0,
        breakup: 0,
        vacancy: 0,
        amenities: 0,
        rooms: 0,
        requset: 0,
        agreement: 0,
        invoice: 0
    })
    React.useEffect(() => {
        const payload = {
            unit_id: props?.unitID,
        }
        NetworkCall(
            `${config.api_url}/queries/get_counts_by_unit_id`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {

            setCount({
                owners: res.data?.totalOwners?.[0]?.count_owner_profile_id,
                resident: res.data?.totalResident?.[0]?.count_tenant_profile_id,
                breakup: res.data?.pricing_table?.[0]?.count_id,
                vacancy: res.data?.unit_vacancy_period?.[0]?.count_id,
                amenities: res.data?.unit_amenities_breakup?.[0]?.count_id,
                rooms: res.data?.unit?.[0]?.count_id,
                requset: res.data?.general_request_units?.[0]?.count_id + res.data?.maintenance_request_units?.[0]?.count_id,
                agreement: res.data?.agreement_units?.[0]?.count_id,
                invoice: res.data?.invoice_units?.[0]?.count_id
            })
        })
        // eslint-disable-next-line
    }, [])
    const tableData = []

    return (

        <div>
            <Box height="8px" />
            <Grid container spacing={2}>
                {tableData.map((item) => (
                    <>
                        <Grid item xs={2} sm={3} md={3} lg={2}>
                            <Box p={0.5}>
                                <DialogWithTable data={item} t={t}/>
                            </Box>
                        </Grid>
                    </>

                )
                )}
            </Grid>

        </div>


    )
}