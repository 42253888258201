import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { returnTypeImage } from '../../../utils';
import { HeaderStyles } from './style';

export const DropdownCard = ({ options = [], setSeletedType = () => false, selectedType = {}, t }) => {
    const classes = HeaderStyles()
    const [open, setOpen] = React.useState(false)

    const showAll = () => {
        open ? setOpen(false) : setOpen(true)
    }
    const setDropdownValue = (value) => {
        setSeletedType(value)
        setOpen(false)
    }

    return (
        <>
            <Box className={classes.dropdown} p={1} >
                <Box display="flex" spacing={1} alignItems={"center"} onClick={showAll} >
                    <Box>
                        <Avatar variant='square' sx={{ backgroundColor: returnTypeImage(selectedType?.label)?.color, borderRadius: "4px" }}>
                            {returnTypeImage(selectedType?.label)?.image}
                        </Avatar>
                    </Box>
                    <Box marginInlineStart="12px" flexGrow={1}>
                        <Typography className={classes.dropdown_name}> {selectedType?.label} </Typography>
                    </Box>
                    <Box pl={2}>
                        {
                            open ? <KeyboardArrowUpIcon className={classes.dropdown_icon} /> : <KeyboardArrowDownIcon className={classes.dropdown_icon} />
                        }
                    </Box>
                </Box>
                {
                    open ?
                        <Box className={classes.list}>
                            <Box mt={1} mb={1}>
                                <Divider />
                            </Box>

                            <Stack spacing={1}>
                                {
                                    options.map(e => {
                                        return (
                                            <>
                                                <Stack direction={"row"} alignItems={"center"} onClick={() => setDropdownValue(e)}>
                                                    <Avatar variant='square' sx={{ backgroundColor: returnTypeImage(e?.label)?.color, borderRadius: "4px", marginInlineEnd: "8px" }}>
                                                        {returnTypeImage(e?.label)?.image}
                                                    </Avatar>
                                                    <Stack>
                                                        <Typography className={classes.dropdown_name}> {e?.label} </Typography>
                                                    </Stack>
                                                </Stack>
                                            </>
                                        )
                                    })
                                }
                            </Stack>
                        </Box>
                        : " "
                }
            </Box>
        </>
    )
}