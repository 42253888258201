export const Path = ["name", "is_active"]

export const Heading = (t) => [
    { title: t("Name"), field: "name" },
    { title: t("Status"), field: "is_active", },
    { title: "", field: "icon" },
]

export const Type = [
    { type: ["text"], name: "name" },
    { type: ["status"], name: "is_active" },
    { type: ["more"], icon: "icon" },
]

export const defaultAddState = {
    formType: "add",
    id: "",
    name: "",
    is_active: true,
    is_delete: false,
    error: {
        name: "",
    }
}

export const defaultFilterData = { is_active: [true] }