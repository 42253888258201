import React from "react";
import { withNavBars } from "../../HOCs";
import MaintanceRequest from "./maintenancerequest";
class MaintanceRequestParent extends React.Component {
  render() {
    return <MaintanceRequest />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(MaintanceRequestParent, props);
