export const MilestoneTemplates = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 102129">
            <path
                d="M86.169 100.489Zm6.123 2.875a2.826 2.826 0 1 0-1.08 3.837 2.834 2.834 0 0 0 1.08-3.837Zm-2.123 1.987a.7.7 0 0 1-.9-1.024l-.487-.812a.271.271 0 1 1 .465-.279l.489.818a.7.7 0 0 1 .434 1.3Zm2.123-1.987a2.826 2.826 0 1 0-1.08 3.837 2.834 2.834 0 0 0 1.08-3.837Zm-2.123 1.987a.7.7 0 0 1-.9-1.024l-.487-.812a.271.271 0 1 1 .465-.279l.489.818a.7.7 0 0 1 .434 1.3Zm3.676-2.859a4.576 4.576 0 0 0-1.606-1.664 4.65 4.65 0 0 0-2.142-.672v-.287h.263a.619.619 0 0 0 .627-.612v-.127a.62.62 0 0 0-.613-.626l-.955-.011a.62.62 0 0 0-.627.612v.127a.62.62 0 0 0 .613.626h.15v.293a4.6 4.6 0 0 0-1.748.456l-.141-.253.179-.1a.619.619 0 0 0 .237-.844l-.063-.112a.621.621 0 0 0-.845-.236l-.831.466a.618.618 0 0 0-.236.844l.064.112a.621.621 0 0 0 .841.231l.18-.1.143.253a4.6 4.6 0 1 0 6.508 1.616Zm-2.368 5.183a3.363 3.363 0 1 1 1.287-4.577 3.378 3.378 0 0 1-1.286 4.576Zm-3.036-5.387a2.821 2.821 0 1 0 3.85 1.077 2.816 2.816 0 0 0-3.85-1.078Zm1.728 3.064a.7.7 0 0 1-.9-1.024l-.487-.812a.271.271 0 1 1 .465-.279l.489.818a.7.7 0 0 1 .434 1.3Zm7.728 6.526-1.627-1.9a.272.272 0 0 0-.206-.094h-1.782l.949 2.056a.273.273 0 0 1 0 .227l-.949 2.056h1.783a.27.27 0 0 0 .206-.1l1.627-1.9a.273.273 0 0 0 .002-.347Zm-4.213-1.993h-2.3l.949 2.056a.273.273 0 0 1 0 .227l-.949 2.056h2.3l1-2.169Zm-2.894 0h-2.3l.949 2.055a.273.273 0 0 1 0 .227l-.949 2.056h2.3l1-2.169Zm-2.894 0H85.6l.949 2.056a.273.273 0 0 1 0 .227l-.949 2.055h2.3l1-2.169Zm-2.894 0h-2.768a.271.271 0 0 0-.236.406l1.007 1.764L82 113.817a.271.271 0 0 0 .236.4H85l1-2.169Z"
                transform="translate(-74.963 -91.494)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            />
            <path
                data-name="Rectangle 52494"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
        </g>
    </svg>
)