import makeStyles from "@mui/styles/makeStyles";
import { SemiBold } from "../../utils";
export const ReceiptStyles = makeStyles((theme) => ({
    root: {
        padding: "12px",
        margin: "20px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 6px #00000014",
        borderRadius: "4px",
    },
    iconButton: {
        borderRadius: "4px",
        border: `1px solid ${theme.palette.border.primary}`,
        height: '40px',
        width: '40px'
    },
    menuList: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 6px #0717411F",
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "white",
            marginTop: "12px"

        }
    },
    menuItem: {
        borderBottom: "1px solid #E4E8EE",
        margin: "0px 4px",
        fontSize:"0.875rem",
        color: "#071741",
        fontFamily: SemiBold,
        '&:last-child': {
            border: 0,
        },
    },

}));