import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
// import { CustomDropDown } from './component/customDropdown'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { CalendarDateChange } from '../../../components'
import { ImageGalleryStyle, eventListStyle } from './style'
import moment from 'moment'

export const ViewMore = ({ t, details = {}, openDeclinePopup = () => false, type = "Weekly" }) => {
    const classes = ImageGalleryStyle()
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));
    return (
        <Box p={2}>
            <Grid container p={1} alignItems="center">
                {type === "Weekly" &&
                    <Grid item xs={12} textAlign={"center"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <CalendarDateChange t={t} state={details?.start} comp={"daily"} noArrow={true} />
                    </Grid>
                }
                <Grid item xs={12} marginTop="12px">

                    <Box className={classes.calRow}>
                        <>
                            <Grid container>
                                <Grid item md={1} className={classes.timeCell}>
                                    <Box marginTop="20px" textAlign={"center"}>
                                        {type === "Weekly" ? `${details?.start?.slice(11, 16)} - ${details?.end?.slice(11, 16)}` : moment(details?.start).format("DD MMM YYYY")}
                                    </Box>
                                </Grid>
                                <Grid item md={11} className={classes.eventCell} p={1}>
                                    {

                                        details?.booking?.map((r) => {
                                            return (
                                                <Box>
                                                    <Box m={0.4}>

                                                        <Stack direction={"row"} spacing={2} alignItems={"center"}
                                                            sx={{
                                                                backgroundColor: r?.parking_master_parking_type === "Visitors" ? "#FEEAEA80" : "#DBF0F180",
                                                                color: r?.parking_master_parking_type === "Visitors" ? "#B3776D" : "#6DAFB3"
                                                            }} p={0.7}>
                                                            <HtmlTooltip

                                                                title={
                                                                    <React.Fragment>
                                                                        <CustomToolTip data={r} t={t} openDeclinePopup={openDeclinePopup} />
                                                                    </React.Fragment>
                                                                }
                                                                placement='right-start'

                                                            >
                                                                <Box display="flex" alignItems="center">
                                                                    <Typography className={classes.event_label}>{r?.reference_id}</Typography>
                                                                    {
                                                                        (r?.parking_no) &&
                                                                        <>
                                                                            <Box margin="0px 12px" className={classes.dot} sx={{
                                                                                color: r?.parking_master_parking_type === "Visitors" ? "#FEEAEA80" : "#DBF0F180",
                                                                                backgroundColor: r?.parking_master_parking_type === "Visitors" ? "#B3776D" : "#6DAFB3"
                                                                            }}></Box>
                                                                            <Typography className={classes.event_label}>{r?.parking_no}</Typography>
                                                                        </>
                                                                    }
                                                                    {
                                                                        (r?.parking_master_parking_type
                                                                        ) &&
                                                                        <>
                                                                            <Box margin="0px 12px" className={classes.dot} sx={{
                                                                                color: r?.parking_master_parking_type === "Visitors" ? "#FEEAEA80" : "#DBF0F180",
                                                                                backgroundColor: r?.parking_master_parking_type === "Visitors" ? "#B3776D" : "#6DAFB3"
                                                                            }}></Box>
                                                                            <Typography className={classes.event_label}> {r?.parking_master_parking_type
                                                                            }</Typography>
                                                                        </>
                                                                    }
                                                                    {r?.first_name &&
                                                                        <>
                                                                            <Box margin="0px 12px" className={classes.dot} sx={{
                                                                                color: r?.parking_master_parking_type === "Visitors" ? "#FEEAEA80" : "#DBF0F180",
                                                                                backgroundColor: r?.parking_master_parking_type === "Visitors" ? "#B3776D" : "#6DAFB3"
                                                                            }}></Box>
                                                                            <Typography className={classes.event_label}> {r?.first_name} </Typography>
                                                                        </>
                                                                    }
                                                                    {r?.mobile_no &&
                                                                        <>
                                                                            <Box margin="0px 12px" className={classes.dot} sx={{
                                                                                color: r?.parking_master_parking_type === "Visitors" ? "#FEEAEA80" : "#DBF0F180",
                                                                                backgroundColor: r?.parking_master_parking_type === "Visitors" ? "#B3776D" : "#6DAFB3"
                                                                            }}></Box>
                                                                            <Typography className={classes.event_label}>{r?.mobile_no_country_code} {r?.mobile_no} </Typography>
                                                                        </>
                                                                    }
                                                                </Box>
                                                            </HtmlTooltip>
                                                        </Stack>

                                                    </Box>

                                                </Box>
                                            )
                                        })

                                    }

                                </Grid>
                            </Grid>
                        </>
                    </Box>


                </Grid>
            </Grid>
        </Box>
    )
}
const CustomToolTip = ({ data = {}, startTime = null, endTime = null, openDeclinePopup = () => false }) => {
    const classes = eventListStyle()
    return (
        <Stack>
            <Stack direction={"column"} spacing={1}>
                <Stack>
                    <Typography className={classes.ttTitle}> {data?.parking_no} ,{data?.reference_id}  </Typography>
                </Stack>
                <Stack>
                    <Typography className={classes.ttsecTitle}> {data?.parking_master_parking_type} </Typography>
                </Stack>
                <Stack>
                    <Typography className={classes.ttassigned_by}>booked by : {data?.first_name}, {`${data?.mobile_no_country_code ?? ""} ${data?.mobile_no ?? ""}`} </Typography>
                </Stack>
                <Stack>
                    <Typography className={classes.moreLabel} onClick={() => openDeclinePopup(data)}> {"view more"}</Typography>
                </Stack>
            </Stack>

        </Stack>
    )
}