import { Avatar, Box, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { UnitListCardStyle } from './style'
import CloseIcon from '@mui/icons-material/Close';
import EditIconSvg from '../../../assets/editIcon';
import { SelectBox } from '../../../components';
import { stringAvatar } from '../../../utils';

export const UnitListCard = (
    {
        data = {},
        click = () => false,
        selected = {},
        removeUnit = () => false,
        close = "",
        edit = "",
        existing = false
    }
) => {
    const classes = UnitListCardStyle()
    return (
        <Box className={classes.box} mt={2}>
            <Stack p={edit ? 0 : 1} onClick={() => !close && click(data)}
                sx={{ cursor: "pointer" }} spacing={1}>
                <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"}>
                        <Avatar className={classes.avatar} src={existing ? data?.image_url : data?.unit_logo} {...stringAvatar(existing ? data?.first_name : data?.unit_name)}></Avatar>
                        <Stack sx={{ marginInlineStart: "8px" }}>
                            <Typography className={classes.title}> {existing ? data?.first_name : data?.unit_name} </Typography>
                            <Typography className={classes.content}>{existing ? `${data?.mobile_no_country_code} ${data?.mobile_no}` : data?.property_name}, {existing ? data?.email_id : data?.amenity_user}</Typography>
                        </Stack>
                    </Stack>
                    {/* {selected?.agreement_unit_id === data?.agreement_unit_id && <Icon color="primary">check_circle</Icon>} */}
                    {close && <CloseIcon sx={{ color: "red", cursor: "pointer" }} onClick={() => removeUnit(existing)} ></CloseIcon>}
                    {edit && <EditIconSvg />}
                </Stack>
                {!existing && <Divider></Divider>}
                {
                    !existing &&
                    <Grid container spacing={1}>
                        <Grid item md={6} lg={6} sm={12} xs={12} px={1}>
                            <SelectBox
                                value={{
                                    label: data?.agreement_no,
                                    value: data?.agreement_id
                                }}
                                label={"Agreement"}
                                placeholder={"Choose Agreement"}
                                selectHeight={32}
                                color={"#fff"}
                                isReadOnly={true} />
                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12} px={1}>
                            <SelectBox
                                value={{
                                    label: data?.account_name,
                                    value: data?.account_id
                                }}
                                label={"Account"}
                                placeholder={"Choose Account"}
                                selectHeight={32}
                                color={"#fff"}
                                isReadOnly={true} />
                        </Grid>
                    </Grid>
                }

            </Stack>
        </Box >
    )
}