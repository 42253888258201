import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold, remCalc } from "../../../utils";
export const AggrementTabStyles = makeStyles((theme) => ({
  box: {
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: "4px",
    height: "calc(100vh - 200px)",
    overflow: "auto",
    backgroundColor: "white",
  },
  box1: {
    height: "calc(100vh - 260px)",
    overflow: "auto",
    backgroundColor: "white",
  },
  timelineRoot: {
    backgroundColor: "white",
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: "4px",
    height: "calc(100vh - 210px)",
    overflow: "hidden"
  },
  kycbox: {
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: "4px",
    height: "calc(100vh - 220px)",
    overflow: "auto",
    backgroundColor: "white",
  },
  detailsKYC: {
    height: "calc(100vh - 220px)",
    backgroundColor: "#F5F7FA",
    padding: "16px",
    overflow: "auto",
    cursor: "pointer"
  },
  buttomBtn: {
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    padding: "16px",
  },
  decline: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#FF4B4B",
    color: "#fff",
    fontFamily: Bold,
    "&:hover": {
      backgroundColor: "#FF4B4B",
      color: "#fff",
    },
  },
  approve: {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#5AC782",
    color: "#fff",
    marginInlineStart: '10px',
    fontFamily: Bold,
    "&:hover": {
      backgroundColor: "#5AC782",
      color: "#fff",
    },
  },
  managedInvoiceRoot: {
    padding: "12px",
    height: "calc(100vh - 348px)",
    overflow: "auto",
    backgroundColor: "#F5F7FA",
  },
  managedInvoiceRoot1: {
    padding: "12px",
    height: "calc(100vh - 272px)",
    overflow: "auto",
    backgroundColor: "#F5F7FA",
  },
  submit: {
    borderRadius: "4px",
    fontFamily: Bold,
    boxShadow: "none",
    padding: "8px",
    "&:hover": {
      color: "#fff",
    },
  },
  kycForm: {
    position: "absolute",
    backgroundColor: "white",
    zIndex: "1",
    bottom: "0px",
    left: "0px",
    right: "0px",
    width: "100%",
    padding: "12px",
    boxShadow: "0px -10px 10px #00000014"
  },
  title: {
    color: "#4E5A6B",
    fontSize: "1rem",
    fontFamily: Bold,
    textTransform: "capitalize"
  },
  btn: {
    boxShadow: "none",
    padding: "8px 12px",
    color: "white"
  }
}));

export const AccessRightsStyles = makeStyles((theme) => ({
  headTitle: {
    fontSize: remCalc(16),
    color: theme.typography.color.primary,
    fontFamily: SemiBold
  },
  gridContainer: {
    border: `0.5px solid ${theme.palette.border.secondary}`,
    borderRadius: 8
  },
  gridItem: {
    border: `0.5px solid ${theme.palette.border.secondary}`,
    padding: "16px 20px"
  },
  leftItem: {
    border: `0.5px solid ${theme.palette.border.secondary}`,
    padding: "16px 20px",
    borderRadius: "6px 0px 0px 6px"
  },
  rightItem: {
    border: `0.5px solid ${theme.palette.border.secondary}`,
    padding: "16px 20px",
    borderRadius: "0px 6px 6px 0px"
  },
  unitSubTitle: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: theme.typography.color.secondary
  }
}))


export const AttachmentStyle = makeStyles((theme) => ({
  img: {
    // backgroundImage: (props) => `url(${props?.url})`,
    height: 150,
    width: "100%",
    border: "1px solid",
    objectFit: "contain",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%",
    borderRadius: "4px",
    position: "relative",
    overflow: "hidden"
  },
  hoverContent: {
    backgroundColor: "#0000005C",
    position: "absolute",
    zIndex: 1,
    height: 150,
    width: "100%",
    top: 0,
    left: 0
  },
  delete: {
    color: "red",
    position: "absolute",
    top: "4px",
    right: "8px",
    backgroundColor: "white",
  },
  contentText: {
    fontSize: remCalc(12),
    fontFamily: SemiBold,
    color: "#fff",
    maxWidth: 185,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  dot: {
    height: 4,
    width: 4,
    background: "#fff",
    borderRadius: "50%"
  },
  viewBtn: {
    backgroundColor: "white",
  }
}))