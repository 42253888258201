export const CloseButtonSquare = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props} style={{ cursor: "pointer" }}>
        <g data-name="Group 113741" transform="translate(-3 -1)">
            <rect
                width={32}
                height={32}
                fill="#f2f4f7"
                data-name="Rectangle 57546"
                rx={4}
                transform="translate(3 1)"
            />
            <path
                fill="#7c8594"
                d="M25.366 9.999a.617.617 0 0 0-.43.19l-5.938 5.938-5.938-5.938a.617.617 0 1 0-.872.872l5.938 5.938-5.938 5.937a.617.617 0 1 0 .872.872l5.938-5.938 5.938 5.938a.617.617 0 1 0 .872-.872l-5.938-5.938 5.937-5.937a.617.617 0 0 0-.442-1.062Z"
                data-name="icons8-delete (3)"
            />
        </g>
    </svg>
)
