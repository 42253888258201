
export const InventoryPath = ["name", "product_id", "type", "is_active"]

export const InventoryHeading = (t) => {
    const Heading = [
        { title: t("productName"), field: "name" },
        { title: t("productId"), field: "product_id", },
        { title: t("productType"), field: "type", },
        { title: t("status"), field: "is_active" },
        { title: "", field: "" },

    ]
    return Heading

}


export const InventoryType = [
    { type: ["text"], name: "name" },
    { type: ["text"], name: "product_id" },
    { type: ["propertyType"], name: "type" },
    { type: ["status"], name: "is_active" },
    { type: ["more"], icon: "icon" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const StatusOptionListLAng = (t) => [
    { label: t('Active'), value: true },
    { label: t('Inactive'), value: false },
]

export const productType = [
    { label: 'Consumable', value: "Consumable" },
    { label: 'Asset', value: "Asset" },
]
export const initialState = {
    name: "",
    type: "",
    is_active: "",
    description: "",
    error: {
        description: "",
        name: "",
        type: "",
        status: "",
    }
}
