import { Paper } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { TableWithPagination } from "../../components"
import { ToolsMasterStyles } from "./style"
import ToolSearchBar from "./toolSearchBar"
import { ToolsMasterHeading, ToolsMasterPath, ToolsMasterType } from "./toolsMasterUtils"


const TableComp = ({ t, handleTableIcon, handleAdd = () => false, data = [], handleSearch = () => false, searchText = "",
    openfilter = () => false, handlePagination = () => false, handleChangeLimit = () => false, page = "", limit = "", totalRows = "", permissions = {} }) => {
    const classes = ToolsMasterStyles()
    const Heading = ToolsMasterHeading(t)
    return (
        <>
            <Paper className={classes.roots}>
                <ToolSearchBar
                    handleAdd={handleAdd}
                    handleSearch={handleSearch}
                    searchText={searchText}
                    permissions={permissions}
                    openfilter={openfilter}

                />
                <TableWithPagination
                    handleIcon={handleTableIcon}
                    // onClick={handleClick}
                    heading={Heading}
                    rows={data}
                    path={ToolsMasterPath}
                    showpagination={true}
                    tableType="no-side"
                    dataType={ToolsMasterType}
                    totalRowsCount={totalRows}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    page={page}
                    limit={limit}
                    height={'calc(100vh - 290px)'}
                    view={permissions?.read}
                    edit={permissions?.update}
                    delete={permissions?.delete}
                />
            </Paper>

        </>
    )
}
export default withNamespaces("toolsMaster")(TableComp)