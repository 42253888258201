import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import ReactQuill from 'react-quill';
import NoTermsAndCondition from '../../../assets/notermsandcondition';
import { NewLoader } from '../../../components/newLoader';
import { TermsAndConditionStyle } from './styles';

const TermsAndCondition = ({ t = () => false, settings = {}, tcLoading = false, onSubmit = () => false }) => {
    const [data, setData] = React.useState({
        open: false,
        quil: "",
        edit: false,
        view: false
    })

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, false] }],
                [{ color: [] }, { background: [] }],
                ["bold", "italic", "underline", "strike"],
                [{ align: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
                ["emoji"],
                ["code-block"],
            ],
        },
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true,
    }

    const classes = TermsAndConditionStyle()

    const updateState = (key, value) => {
        setData({
            ...data,
            [key]: value
        })
    }
    React.useEffect(() => {
        if (settings?.terms_and_conditions !== null) {
            setData({
                ...data,
                view: true,
                open: true,
                edit: false,
                quil: settings?.terms_and_conditions
            })
        }
        // eslint-disable-next-line
    }, [])

    const handleCreateEditSave = () => {
        const payload = {
            terms_and_conditions: data?.quil,
            agreement_id: settings?.id,
        }
        if (!data?.edit && !data?.view) {
            updateState("view", true)
            onSubmit(payload)
        } else if (!data?.edit && data?.view) {
            setData({ ...data, edit: true, view: false, quil: settings?.terms_and_conditions })
        } else if (data?.edit && !data?.view) {
            onSubmit(payload)
            setData({ ...data, edit: false, view: true })
        }
    }



    return (
        <Box p={2}>
            <Stack direction={"row"} justifyContent={"end"} alignItems={"center"}>
                {
                    data?.open &&
                    <Button variant={data?.view ? 'outlined' : 'contained'}
                        disableElevation className={data?.view ? classes.editBtn : classes.createSaveBtn}
                        onClick={() => handleCreateEditSave()}>
                        {data?.edit ? t("Save") : data?.view ? t("Edit") : t("Create")}
                    </Button>
                }
            </Stack>
            {
                !data?.open ?
                    <Box className={classes.createBox}>
                        <Stack spacing={2} alignItems={"center"}>
                            <Stack alignItems={"center"} spacing={"21px"}>
                                <NoTermsAndCondition />
                                <Typography className={classes.createBoxText}>{t("No Terms and Conditions Found")}</Typography>
                            </Stack>
                            <Button variant='contained' className={classes.createBoxButton} disableElevation
                                onClick={() => updateState("open", true)}>{t("Create")}</Button>
                        </Stack>
                    </Box> :
                    <Box pt={"10px"} className={classes.contentBox}>
                        {
                            data?.view ?
                                <>
                                    {
                                        !tcLoading ?
                                            <>
                                                {/* <div dangerouslySetInnerHTML={{
                                                    __html: settings?.terms_and_conditions,
                                                }} ></div> */}
                                                <ReactQuill
                                                    theme="bubble"
                                                    readOnly
                                                    value={settings?.terms_and_conditions ?? t("No Data Found")}
                                                ></ReactQuill>
                                            </> :
                                            <NewLoader />
                                    }
                                </>
                                :
                                <Box>
                                    <ReactQuill
                                        onChange={(value) => updateState("quil", value)}
                                        value={data?.quil}
                                        modules={modules}
                                        className={classes.reactQuil}
                                        theme="snow"
                                    />
                                </Box>
                        }
                    </Box>
            }

        </Box>
    )
}
export default withNamespaces("agreement")(TermsAndCondition); 