import { Box, Grid } from "@mui/material";
import React from "react";
import { AlertDialog } from "../../../components/dialog";
import { Slider } from "./Slider";
import { VehicleMasterComponentStyles } from "./styles";
export const AssetView = ({ asset, t }) => {
    const classes = VehicleMasterComponentStyles();
    const [open, setOpen] = React.useState(false);
    const onClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Box sx={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                <Grid container spacing={1}>
                    <Grid item xs={asset?.length > 1 ? 8 : 12}>
                        <img className={classes.mainImg} src={asset?.[0]?.url} alt="" />
                    </Grid>
                    {
                        asset?.[1]?.url &&
                        <Grid item xs={4}>
                            <img className={classes.subImg} src={asset?.[1]?.url} alt="" />

                            {
                                asset?.[2]?.url &&
                                <img className={classes.subImg} src={asset?.[2]?.url} alt="" />
                            }
                        </Grid>
                    }
                </Grid>

            </Box >
            <AlertDialog
                header={t("Asset View")}
                open={open}
                onClose={onClose}
                component={
                    <Box height="450px" alignItems="center">
                        <Slider assets={asset} />
                    </Box>
                }
                medium={true}

            />
        </>

    )
}