import { makeStyles } from "@mui/styles";

export const ManagementRoleMasterStyle = makeStyles((theme)=>({
    dividerStyle: {
        height: "40px"
    },
    addbtn: {
        height: 40
    },
    cancelbtn: {
        '&:hover': {
            backgroundColor: "white"
        }
    },
}))