export const PricingComponentPath = [
    "company_name",
    "pricing_component",
    "revenue_type",
    "name",
    "taxes",
    "priceOverride",
    "autoReservation",
    "autoSecurityDeposit",
    "status",
    "icon",
];

export const PricingComponentHeading = (t) => [
    { title: t("Company Name"), field: "company_name" },
    { title: t("Pricing Component"), field: "pricing_component" },
    { title: t("Revenue Type"), field: "revenue_type" },
    { title: t("Component Name"), field: "name" },
    { title: t("Taxes"), field: "taxes" },
    { title: t("Price Override"), field: "priceOverride" },
    { title: t("Auto Reservation"), field: "autoReservation" },
    { title: t("Auto Security Deposit"), field: "autoSecurityDeposit" },
    { title: t("Status"), field: 'status', },
    { title: "", field: 'icon' },
];

export const PricingComponentType = [
    { type: ["text"], name: "company_name" },
    { type: ["text"], name: "pricing_component" },
    { type: ["text"], name: "revenue_type" },
    { type: ["long_text"], name: "name", max_width: 250, tooltip_placement: "top" },
    { type: ["text"], name: "taxes" },
    { type: ["text"], name: "priceOverride" },
    { type: ["text"], name: "autoReservation" },
    { type: ["text"], name: "autoSecurityDeposit" },
    { type: ["status"], name: "status" },
    { type: ["more"], icon: "icon" },
];