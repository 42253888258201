import React from "react";
import { withNavBars } from "../../HOCs";
import  Reservation  from "./reservation";

class ReservationParent extends React.Component {
    render() {
        return <Reservation />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ReservationParent,props);
