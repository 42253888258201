import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
      Subheader
} from "../../../components";
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { accessCheckRender, AlertProps, getRoutePermissionNew, NetWorkCallMethods } from "../../../utils";
import { PropertyDetails } from './propertyView/propertydetails';
import { withNamespaces } from "react-i18next";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { BackendRoutes } from "../../../router/routes";


const PricingTablePropertyView = ({
      t
}) => {
      const history = useHistory()
      const [data, setData] = React.useState([])
      const search = useLocation().search;
      const alert = React.useContext(AlertContext);
      const propertyID = new URLSearchParams(search).get("propertyID");
      const backdrop = React.useContext(BackdropContext);
      const auth = React.useContext(AuthContext)
      const [permission, setPermission] = React.useState({})

      // use effect to get permission
      React.useEffect(() => {
            const perm = getRoutePermissionNew(auth)
            if (perm) {
                  setPermission(perm)
                  if (perm?.read) {
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: true,
                              message: "",
                        });
                        getPropertyById()
                  }
            }
            // eslint-disable-next-line
      }, [auth]);

      const getPropertyById = () => {
            const payload = { propertyID }
            NetworkCall(
                  `${config.api_url}${BackendRoutes?.queries_property_get}`,
                  NetWorkCallMethods.post, payload, null, true, false
            ).then((response) => {
                  backdrop.setBackDrop({
                        ...backdrop,
                        open: false,
                        message: "",
                  });
                  setData(response?.data?.data)
            }).catch((err) => {
                  console.log(err)
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                  });
            });
      };

      const goBack = () => {
            history.goBack(-1)
      }

      const render = () => {
            return <>
                  <Subheader title={data?.property?.[0]?.name} goBack={goBack} select isReadOnly={true} value={data?.property?.[0]?.company} />
                  <PropertyDetails t={t} data={data?.property?.[0]} assets={data?.asset} />
            </>
      }

      return (
            <div>
                  {accessCheckRender(render, permission)}
            </div>
      )
}
export default withNamespaces("pricingComponent")(PricingTablePropertyView)
