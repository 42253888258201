export const Parking = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <path
            data-name="Path 93625"
            d="M14.103 16.792h1.344a3.137 3.137 0 1 0 0-6.273h-3.136v8.962h1.792ZM7.834 6.934h14.335a.9.9 0 0 1 .9.9v14.335a.9.9 0 0 1-.9.9H7.834a.9.9 0 0 1-.9-.9V7.834a.9.9 0 0 1 .9-.9Zm6.273 5.377h1.344a1.345 1.345 0 1 1 0 2.689h-1.348Z"
            fill={props?.fill ?? "#c1c5cb"}
        />
        <path data-name="Rectangle 52035" fill="none" d="M0 0h30v30H0z" />
    </svg>
)
