import React from 'react';
import { Box, Grid } from "@mui/material";
import { useStylesCreation } from "./style";
import { TextBox } from "../../../components";

export const Bussiness = (props) => {
      const { t } = (props);
      const classes = useStylesCreation();
      return (
            <Box className={classes.imagebox1}>
                  <Grid container rowSpacing={2} columnSpacing={3}>
                        <Grid item xs={12} md={3} lg={2}>
                              <TextBox
                                    label={t("Land Number")}
                                    placeholder={t("Enter Land Number")}
                                    value={props?.data?.landNumber ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("landNumber", e.target.value);
                                    }}
                                    isError={props?.data?.error?.landNumber?.length > 0}
                                    errorMessage={props?.data?.error?.landNumber} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                              <TextBox
                                    label={t("Municipality Authority")}
                                    placeholder={t("Enter Municipality Authority")}
                                    value={props?.data?.municipality ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("municipality", e.target.value);
                                    }}
                                    isError={props?.data?.error?.municipality?.length > 0}
                                    errorMessage={props?.data?.error?.municipality} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                              <TextBox
                                    label={t("Electricity & Water Authority")}
                                    placeholder={t("Enter Electricity & Water Authority")}
                                    value={props?.data?.electricity ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("electricity", e.target.value);
                                    }}
                                    isError={props?.data?.error?.electricity?.length > 0}
                                    errorMessage={props?.data?.error?.electricity} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                              <TextBox
                                    label={t("National Geographic Addressing")}
                                    placeholder={t("Enter National Geographic Addressing")}
                                    value={props?.data?.geographic ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("geographic", e.target.value);
                                    }}
                                    isError={props?.data?.error?.geographic?.length > 0}
                                    errorMessage={props?.data?.error?.geographic} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                              <TextBox
                                    label={t("Utilities Natural Gas Connection")}
                                    placeholder={t("Enter Utilities Natural Gas Connection")}
                                    value={props?.data?.naturalGas ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("naturalGas", e.target.value);
                                    }}
                                    isError={props?.data?.error?.naturalGas?.length > 0}
                                    errorMessage={props?.data?.error?.naturalGas} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                              <TextBox
                                    label={t("Utilities Electricity Connection")}
                                    placeholder={t("Enter Utilities Electricity Connection")}
                                    value={props?.data?.utilitieselectricity ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("utilitieselectricity", e.target.value);
                                    }}
                                    isError={props?.data?.error?.utilitieselectricity?.length > 0}
                                    errorMessage={props?.data?.error?.utilitieselectricity} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                              <TextBox

                                    label={t("Utilities Water Connection")}
                                    placeholder={t("Enter Utilities Water Connection")}
                                    value={props?.data?.water ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("water", e.target.value);
                                    }}
                                    isError={props?.data?.error?.water?.length > 0}
                                    errorMessage={props?.data?.error?.water} />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                              <TextBox
                                    label={t("Utilities Gas Connection")}
                                    placeholder={t("Enter Utilities Gas Connections")}
                                    value={props?.data?.gas ?? ""}
                                    onChange={(e) => {
                                          props?.updateState("gas", e.target.value);
                                    }}
                                    isError={props?.data?.error?.gas?.length > 0}
                                    errorMessage={props?.data?.error?.gas} />
                        </Grid>
                  </Grid>

            </Box>
      )
}