import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Popover, Box, Button } from "@mui/material";
import { managrmentStyles } from "./style";
import Datepicker from "react-datepicker";

export const RangeDatePicker = (props) => {
  const classes = managrmentStyles();
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [state, setState] = useState(new Date());

  const onChange = (dates) => {
    if (props?.isRange) {
      const [start, end] = dates;
      setDate({
        startDate: start,
        endDate: end,
      });
    } else {
      setState(dates);
    }
  };

  const handleSubmit = () => {
    if (props?.isRange) {
      props?.onSumbit({
        startDate: date?.startDate,
        endDate: date?.endDate,
      });
    } else {
      props?.onSumbit(state);
    }

    props?.openClosePopOver(!props?.anchorEl);
  };

  const reset = () => {
    if (props?.isRange) {
      setDate({
        startDate: new Date(),
        endDate: null,
      });
    } else {
      setState(new Date());
      props?.openClosePopOver(!props?.anchorEl);
    }
  };

  const open = Boolean(props?.anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      {/* calander */}
      <Popover
        id={id}
        open={open}
        anchorEl={props?.anchorEl}
        onClose={props?.openClose}
        anchorOrigin={props?.anchorOrigin}
        transformOrigin={props?.transformOrigin}
        className={classes.Popover}
      >
        {props?.isRange ? (
          <DatePicker
            className={classes.datePicker}
            selected={date?.startDate}
            onChange={onChange}
            startDate={date?.startDate}
            endDate={date?.endDate}
            selectsRange
            inline
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        ) : (
          <Datepicker
            selected={state}
            onChange={onChange}
            peekNextMonth
            disabled={props?.disabled}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className={classes.textbox}
            placeholderText={props?.placeholder}
            minDate={props?.minDate ?? false}
            maxDate={props?.maxDate ?? false}
            inline
          />
        )}

        <Box className={classes.btnParent}>
          {props?.outline?.length && (
            <Button className={classes.outLine} onClick={reset}>
              {props?.outline}
            </Button>
          )}
          {props?.container?.length > 0 && (
            <Button className={classes.btn} onClick={handleSubmit}>
              {props?.container}
            </Button>
          )}
        </Box>
      </Popover>
    </div>
  );
};
