import * as React from "react"

export const TransferLead = (props) => (
    <svg
        data-name="Group 93530"
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        {...props}
    >
        <path
            data-name="icons8-exchange (1)"
            d="M7.592 5a2.08 2.08 0 1 0 2.08 2.08A2.082 2.082 0 0 0 7.592 5Zm7.941 0a2.08 2.08 0 1 0 2.08 2.08A2.082 2.082 0 0 0 15.533 5ZM5.239 10.294A1.231 1.231 0 0 0 4 11.519v.081a2 2 0 0 0 .624 1.453 4.178 4.178 0 0 0 2.968 1.02 4.178 4.178 0 0 0 2.968-1.02 2 2 0 0 0 .624-1.453v-.083a1.231 1.231 0 0 0-1.239-1.225Zm7.941 0a1.231 1.231 0 0 0-1.239 1.225v.081a2 2 0 0 0 .624 1.453 4.178 4.178 0 0 0 2.968 1.02 4.178 4.178 0 0 0 2.968-1.02 2 2 0 0 0 .624-1.453v-.083a1.231 1.231 0 0 0-1.239-1.225Zm3.482 4.532a.567.567 0 0 0-.4.974l.544.544H5.2a.567.567 0 1 0 0 1.134h11.611l-.544.544a.567.567 0 1 0 .8.8l1.513-1.513a.567.567 0 0 0 0-.8L17.069 15a.567.567 0 0 0-.407-.172Z"
            fill="#78b1fe"
        />
    </svg>
)


