import { Close } from "@mui/icons-material"
import { Box } from "@mui/material"
import React from "react"
import NoDataFound from "../../../assets/noData"
import { DocumentViewer } from "../../../components/fileViewer"

const ReceiptView = ({ data = {}, closeModal = () => false }) => {
    // const classes = useStyles()
    // const downloadPdf = (val) => {
    //     saveAs(val?.reciept?.pdf, `${val.reciept?.reciepts_no}.pdf`);
    // };
    return (
        <Box>
            <Box p={2} onClick={() => closeModal()} style={{ cursor: "pointer", display: "flex", justifyContent: "end" }}><Close /></Box>
            <center>
                <Box>
                    {
                        data?.invoice?.invoice_receipt_settlements?.length > 0 ? data?.invoice?.invoice_receipt_settlements?.map((val) => {
                            return (
                                <>
                                    {/* <Box display={"flex"} justifyContent={"space-between"} p={2} className={classes.receiptBox}>

                                <Typography className={classes.utilityTitle}>{val?.reciept?.reciepts_no}</Typography>
                                { }
                                <Stack direction="row" spacing={2} alignItems={"center"}>
                                    {val?.reciept?.pdf.length > 0 &&
                                        <Button className={classes.downBtn} variant="outlined" startIcon={<Download />} onClick={() => downloadPdf(val)}>
                                            Download
                                        </Button>
                                    }
                                    <Box onClick={() => closeModal()} style={{ cursor: "pointer" }}><Close /></Box>
                                </Stack>
                            </Box> */}
                                    <Box mt={2}>
                                        <DocumentViewer url={val?.reciept?.pdf} />
                                    </Box>
                                </>
                            )
                        })

                            :
                            <Box marginTop="12px">
                                <NoDataFound />
                            </Box>
                    }
                </Box>
            </center>
        </Box>
    )
}
export default ReceiptView