import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import TextField from '@mui/material/TextField';
import React from 'react';
import { Bold, SemiBold } from '../../../../../utils';
import { SelectBox } from "../../select";
import { CustomTypography } from '../customTypography';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& input': {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        padding: 12,
        '&::placeholder': {
            color: theme.typography.color.tertiary,
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 0
    },
    '& .MuiInputAdornment-root p': {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: SemiBold,
    }
}))

const CustomStack = styled(Stack)({
    border: '1px solid #E4E8EE',
    borderRadius: 4,
})

const CustomSelect = styled(Select)(({ theme }) => ({
    minWidth: 110,
    backgroundColor: "#F2F4F7",
    '& .MuiSelect-select': {
        padding: 12,
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#F2F4F7',
        borderRadius: 4
    },
    '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F2F4F7',
        },
    }
}))

export const MinMaxTextFieldWithSelect = (props) => {

    const {
        value,
        onChange,
        label = "",
        required = false,
        select = true,
        placeholders = {},
        endAdornmentLabels = {},
        options = [],
        error = false,
        errorText = "",
        aysnc = false,
        disabled = false,
        loading = "",
        loadOptions = () => false

    } = props;

    const theme = useTheme();

    const handleChange = (key, val) => {
        let updateValue = {
            ...value,
            [key]: val
        };
        onChange && onChange(updateValue)
    }

    return (
        <>
            {label &&
                <CustomTypography fontFamily={Bold} marginBottom={16}>
                    {label}
                    {required && <CustomTypography component={"span"} marginLeft={theme.spacing(1)} color={theme.palette.error.main} fontFamily={Bold}>*</CustomTypography>}
                </CustomTypography>
            }
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap={"10px"}
            >
                <CustomStack
                    direction={"row"}
                    alignItems={"center"}
                    divider={<Divider flexItem orientation={"vertical"} />}
                    gap={"10px"}
                >
                    <CustomTextField
                        value={value?.min ?? ''}
                        onChange={(e) => handleChange('min', e.target.value)}
                        InputProps={
                            endAdornmentLabels?.input1 && {
                                endAdornment: <InputAdornment position="end">{endAdornmentLabels?.input1}</InputAdornment>,
                            }
                        }
                        placeholder={placeholders?.input1}
                    />
                    <CustomTextField
                        value={value?.max ?? ''}
                        onChange={(e) => handleChange('max', e.target.value)}
                        InputProps={
                            endAdornmentLabels?.input2 && {
                                endAdornment: <InputAdornment position="end">{endAdornmentLabels?.input2}</InputAdornment>,
                            }
                        }
                        placeholder={placeholders?.input2}

                    />
                </CustomStack>
                {
                    (select) &&
                        aysnc ?
                        <SelectBox
                            value={value?.select}
                            onChange={(e) => handleChange('select', e)}
                            loading={loading}
                            isPaginate
                            width="110px"
                            color="#F2F4F7"
                            // eslint-disable-next-line
                            style={true}
                            isReadOnly={disabled}
                            placeholder='Select'
                            debounceTimeout={800}
                            loadOptions={loadOptions}
                        />
                        :
                        <CustomSelect
                            disabled={disabled}
                            value={value?.select}
                            onChange={(e) => handleChange('select', e.target.value)}
                            displayEmpty
                            renderValue={value => value ? options?.find(_ => _.value === value)?.label : <CustomTypography sx={{ lineHeight: 1.643 }} fontFamily={SemiBold} color={theme.typography.color.tertiary}>{placeholders?.select}</CustomTypography>}
                        >
                            {
                                options?.map(({ label, value }) => (
                                    <MenuItem value={value}>{label}</MenuItem>
                                ))
                            }
                        </CustomSelect>
                }
            </Stack>
            {error &&
                <CustomTypography fontFamily={Bold} marginTop={theme.spacing(1)} color={theme?.palette?.error?.main} fontSize={12}>{errorText}</CustomTypography>
            }
        </>
    )
}