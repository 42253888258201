import React from "react";
import { withNavBars } from "../../HOCs";
import KycDetails from "./kycDetails";

// const KycDetailsParent = () => {
//     return (
//         <KycDetails />
//     )
// }
// const props = {
//     boxShadow: false
// }
// export default withNavBars(KycDetailsParent,props)



// import React from "react";
// import { withNavBars } from "../../HOCs";
// import AgreementDetails from "./agreementDetails";

class KycDetailsParent extends React.Component {
    render() {
        return <KycDetails {...this.props} />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(KycDetailsParent, props);
