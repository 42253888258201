import Box from "@mui/material/Box";
import { LightTooltip, useStyles } from "./style";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const FunnelChart = ({
    width = 300,
    height = 300,
    is_reverse = false,
    gap_height = 1,
    tooltip_type = "default",
    data = [],
}) => {
    const classes = useStyles({ width, height, is_reverse, gap_height });

    const tooltip = (_) => {
        switch (tooltip_type) {
            case "type_1":
                return (
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} columnGap={"8px"}>
                        <Box className={classes?.tooltip_legend}
                            sx={{ backgroundColor: _?.backgroundColor }} />
                        <Stack direction={"column"} justifyContent={"start"} alignItems={"start"} rowGap={"4px"}>
                            <Stack direction={"row"} justifyContent={"start"} alignItems={"center"} columnGap={"8px"}>
                                <Typography className={classes?.tooltip_value}>
                                    {_?.value}
                                </Typography>
                                <Typography className={classes?.tooltip_label}>
                                    {_?.label}
                                </Typography>
                            </Stack>
                            <Typography className={classes?.tooltip_sub_label}>
                                {_?.sub_label}
                            </Typography>
                        </Stack>
                    </Stack>
                )

            default:
                return (
                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} columnGap={"8px"}>
                        <Box className={classes?.tooltip_legend}
                            sx={{ backgroundColor: _?.backgroundColor }} />
                        <Typography className={classes?.tooltip_value}>
                            {_?.value}
                        </Typography>
                        <Typography className={classes?.tooltip_label}>
                            {_?.label}
                        </Typography>
                    </Stack>
                )
        }
    }

    return (
        <Box display={"flex"} width={"100%"} justifyContent={"center"}>
            <Box className={classes?.root}>
                {data?.filter(_ => _?.value)?.map?.((item, index, array) => {
                    let box_height = 0;
                    box_height = ((height - ((array?.length - 1) * gap_height)) * item?.percentage);
                    return (
                        <>
                            <LightTooltip
                                placement="top"
                                title={tooltip(item)}>
                                <Box sx={{
                                    width: width, backgroundColor: item?.backgroundColor,
                                    height: `${box_height}px`,
                                }} />
                            </LightTooltip>
                            {((array?.length - 1) > index) && <Box className={classes?.gap} />}
                        </>
                    )
                })}
            </Box>
        </Box>
    );
}