import React from "react"

export const FloorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_112310" data-name="Group 112310" transform="translate(-13981 -7826)">
            <g id="Group_112309" data-name="Group 112309">
                <g id="Rectangle_57216" data-name="Rectangle 57216" transform="translate(13981 7826)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-home_7_" data-name="icons8-home (7)" d="M13.978,4a.667.667,0,0,0-.391.143L7.271,9.119A3.335,3.335,0,0,0,6,11.737v8.484a1.121,1.121,0,0,0,1.111,1.111h4.444a1.121,1.121,0,0,0,1.111-1.111V15.777a.212.212,0,0,1,.222-.222h2.222a.212.212,0,0,1,.222.222v4.444a1.121,1.121,0,0,0,1.111,1.111h4.444A1.121,1.121,0,0,0,22,20.222V11.737a3.335,3.335,0,0,0-1.271-2.618L14.412,4.143A.667.667,0,0,0,13.978,4ZM14,5.515l5.9,4.652a2,2,0,0,1,.762,1.57V20h-4V15.777a1.566,1.566,0,0,0-1.556-1.556H12.889a1.566,1.566,0,0,0-1.556,1.556V20h-4V11.737a2,2,0,0,1,.762-1.57Z" transform="translate(13979 7825)" fill="#4e5a6b" />
            </g>
        </g>
    </svg>

)