import React from "react";
import { withNavBars } from "../../HOCs";
import ImportFileUpload from "./importFileUpload";
import LeadImportFileUpload from "./leadImportFileUpload";

class ImportFileUploadParent extends React.Component {
  render() {
    return <ImportFileUpload {...this.props} />;
  }
}

class LeadImportFileUploadParent extends React.Component {
  render() {
    return <LeadImportFileUpload {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ImportFileUploadParent, props);
export const LeadImportFileUploadWithNavBars =  withNavBars(LeadImportFileUploadParent, props);
