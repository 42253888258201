import * as React from "react";

export const SearchUnit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={240}
    height={138.352}
    {...props}
  >
    <g data-name="Group 117189">
      <g data-name="Group 117188">
        <g data-name="icons8-search (2)">
          <path
            fill="#ced4da"
            d="m132.778 70.947 7.165-7.165 30.401 30.4-7.165 7.166Z"
            data-name="Path 101869"
          />
          <path
            fill="#adb5bd"
            d="M150.081 40.54A40.54 40.54 0 1 1 109.54 0a40.539 40.539 0 0 1 40.54 40.54"
            data-name="Path 101870"
          />
          <path
            fill="#6c757d"
            d="m141.152 79.324 7.165-7.165 22.023 22.023-7.165 7.165Z"
            data-name="Path 101871"
          />
          <path
            fill="#e9ecef"
            d="M76.601 40.54a32.939 32.939 0 1 1 32.939 32.939A32.938 32.938 0 0 1 76.601 40.54"
            data-name="Path 101872"
          />
        </g>
        <g data-name="icons8-condo (1)">
          <path
            fill="#adb5bd"
            d="M110.423 23.053H96.332a1.884 1.884 0 0 0-1.879 1.879v20.667h17.849V24.932a1.884 1.884 0 0 0-1.879-1.879Z"
            data-name="Path 101873"
          />
          <path
            fill="#e9ecef"
            d="M101.969 26.811h-3.758v3.758h3.758Zm0 6.576h-3.758v3.758h3.758Zm0 6.576h-3.758v3.758h3.758Z"
            data-name="Path 101874"
          />
          <path
            fill="#ced4da"
            d="M94.453 45.599h17.849v1.879H94.453Z"
            data-name="Path 101875"
          />
          <path
            fill="#ced4da"
            d="M103.847 56.872h24.425V28.69a1.884 1.884 0 0 0-1.879-1.879h-20.667a1.884 1.884 0 0 0-1.879 1.879Z"
            data-name="Path 101876"
          />
          <path
            fill="#adb5bd"
            d="M128.272 56.872h-24.425v1.879h24.425Z"
            data-name="Path 101877"
          />
          <path
            fill="#adb5bd"
            d="M117.938 51.236h-3.757v3.758h3.758Zm6.576 0h-3.757v3.758h3.758Zm-13.152 0h-3.757v3.758h3.758Zm6.576-13.152h-3.757v3.758h3.758Zm6.576 0h-3.757v3.758h3.758Zm-13.152 0h-3.757v3.758h3.758Zm6.576 6.576h-3.757v3.758h3.758Zm6.576 0h-3.757v3.758h3.758Zm-13.152 0h-3.757v3.758h3.758Zm6.576-13.152h-3.757v3.758h3.758Zm6.576 0h-3.757v3.758h3.758Zm-13.151 0h-3.758v3.758h3.758Z"
            data-name="Path 101878"
          />
        </g>
      </g>
      <text
        fill="#98a0ac"
        stroke="rgba(0,0,0,0)"
        data-name="Search unit &amp; raise request"
        fontFamily="NotoSans-SemiBold, Noto Sans"
        fontSize={18}
        fontWeight={600}
        transform="translate(120 132.352)"
      >
        <tspan x={-118} y={0}>
          {"Search unit & raise request"}
        </tspan>
      </text>
    </g>
  </svg>
)
