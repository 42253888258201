import React from "react"

export const ItemAsset = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_118359" data-name="Group 118359" transform="translate(9508 -4368)">
                <g id="Rectangle_58700" data-name="Rectangle 58700" transform="translate(-9508 4368)" fill="#896db3" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <g id="Group_118358" data-name="Group 118358" transform="translate(-9507.248 4368.529)">
                    <g id="icons8-living-room" transform="translate(3.248 4.471)">
                        <path id="Path_101926" data-name="Path 101926" d="M11.628,35.965H10.375a.212.212,0,0,1-.2-.27l.726-2.541A.212.212,0,0,1,11.1,33h1.254a.212.212,0,0,1,.2.27l-.726,2.541A.212.212,0,0,1,11.628,35.965Z" transform="translate(-7.553 -22.835)" fill="#5c3d8a" />
                        <path id="Path_101927" data-name="Path 101927" d="M33.1,35.965h1.254a.212.212,0,0,0,.2-.27l-.726-2.541a.212.212,0,0,0-.2-.154H32.375a.212.212,0,0,0-.2.27l.726,2.541A.212.212,0,0,0,33.1,35.965Z" transform="translate(-20.235 -22.835)" fill="#5c3d8a" />
                        <path id="Path_101928" data-name="Path 101928" d="M30.353,10.271A1.271,1.271,0,0,0,29.082,9H25.271A1.271,1.271,0,0,0,24,10.271V16.2h6.353Z" transform="translate(-15.529 -9)" fill="#ac94cf" />
                        <path id="Path_101929" data-name="Path 101929" d="M15.353,10.271A1.271,1.271,0,0,0,14.082,9H10.271A1.271,1.271,0,0,0,9,10.271V16.2h6.353Z" transform="translate(-6.882 -9)" fill="#ac94cf" />
                        <path id="Path_101930" data-name="Path 101930" d="M21.706,25.541H9V23.847A.847.847,0,0,1,9.847,23H20.859a.847.847,0,0,1,.847.847Z" transform="translate(-6.882 -17.071)" fill="#5c3d8a" />
                        <path id="Path_101931" data-name="Path 101931" d="M6.118,24.624H5.694A1.694,1.694,0,0,1,4,22.929V17.424A.424.424,0,0,1,4.424,17H5.694a.424.424,0,0,1,.424.424Z" transform="translate(-4 -13.612)" fill="#896db3" />
                        <path id="Path_101932" data-name="Path 101932" d="M33.832,33.154a.212.212,0,0,0-.2-.154H32.375a.212.212,0,0,0-.2.27l.286,1h1.694Z" transform="translate(-20.235 -22.835)" fill="#896db3" opacity="0.05" />
                        <path id="Path_101933" data-name="Path 101933" d="M33.832,33.154a.212.212,0,0,0-.2-.154H32.375a.212.212,0,0,0-.2.27l.225.789H34.09Z" transform="translate(-20.235 -22.835)" fill="#896db3" opacity="0.07" />
                        <path id="Path_101934" data-name="Path 101934" d="M11.482,33.154a.212.212,0,0,1,.2-.154h1.254a.212.212,0,0,1,.2.27l-.286,1H11.163Z" transform="translate(-8.129 -22.835)" fill="#896db3" opacity="0.05" />
                        <path id="Path_101935" data-name="Path 101935" d="M11.564,33.154a.212.212,0,0,1,.2-.154h1.254a.212.212,0,0,1,.2.27L13,34.059H11.305Z" transform="translate(-8.211 -22.835)" fill="#896db3" opacity="0.07" />
                        <path id="Path_101936" data-name="Path 101936" d="M20.518,17H19.247a.424.424,0,0,0-.424.424v4.659H4v.847a1.694,1.694,0,0,0,1.694,1.694H19.247a1.694,1.694,0,0,0,1.694-1.694V17.424A.424.424,0,0,0,20.518,17Z" transform="translate(-4 -13.612)" fill="#896db3" />
                    </g>
                </g>
            </g>
        </svg>

    )
}