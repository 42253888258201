export const GeneralInspection = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105141">
            <g
                data-name="IM - Inspection Orders - General Inspection"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path data-name="Path 98051" d="M9.2 7.312 6.312 10.2H9.2Z" />
                <path
                    data-name="Path 98052"
                    d="M12.4 15a4.805 4.805 0 0 1 4.8-4.8 4.7 4.7 0 0 1 .533.054V7h-7.466v4.267H6V23h11.733v-3.254a4.7 4.7 0 0 1-.533.054 4.805 4.805 0 0 1-4.8-4.8Zm-3.733 7.154L7.223 20.71l.754-.754.69.69 1.756-1.756.754.754Zm0-3.2L7.223 17.51l.754-.754.69.69 1.756-1.756.754.754Zm0-3.2L7.223 14.31l.754-.754.69.69 1.756-1.756.754.754Z"
                />
                <path
                    data-name="Path 98053"
                    d="m24.205 21.258-4.024-4.031a3.766 3.766 0 1 0-.754.754l4.024 4.031Z"
                />
            </g>
        </g>
    </svg>
)