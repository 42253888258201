import { Grid, Typography } from "@mui/material";
import React from 'react';
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { unitStyles } from "../style";



export const BankDetails = (props) => {
      const { t } = (props);
      const classes = unitStyles()
      const size = useWindowDimensions()


      return (
            <div style={{ height: size?.height - 210, overflow: "auto", padding: "4px", margin: "-4px" }}>
                  <div className={classes.card}>
                        <Typography className={classes.title} >{t("Banking Basic Details")}</Typography>
                        {/*bank detials first card */}
                        <Grid container>
                              <Grid item md={12}>
                                    <Grid container>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Bank Name")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.bank_name}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Branch Name")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.branch_name}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Currency")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.bank_currency_name}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Bank Account Type")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.account_type}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Account Number")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.account_no}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Bank Routing Type")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.routing_type}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Bank Routing Code")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.routing_code}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Preferred Cash Collection Office")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.cash_collection_office}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Cheque Name")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.cheque_name}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Address Line 1")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.address_1}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Address Line 2")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.address_2}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("City")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.bank_city}</Typography>
                                          </Grid>
                                          <Grid item xs={2} style={{ marginTop: '10px' }}>
                                                <Typography className={classes.heading}>{t("Country")}</Typography>
                                                <Typography className={classes.sub}>{props?.data?.bank_country}</Typography>
                                          </Grid>
                                    </Grid>

                              </Grid>
                        </Grid>
                  </div>
            </div>
      )
}