export const BOM = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_115991" data-name="Group 115991" transform="translate(-885 -116)">
                <path id="icons8-image_2_" data-name="icons8-image (2)" d="M8.75,6A2.75,2.75,0,0,0,6,8.75V17.7l3.753-3.63a2.067,2.067,0,0,1,2.813,0l3.089,2.988,1.024-.99a2.073,2.073,0,0,1,2.813,0L24,20.429V8.75A2.75,2.75,0,0,0,21.25,6Zm9.5,3.5A2.25,2.25,0,1,1,16,11.75,2.253,2.253,0,0,1,18.25,9.5Zm0,1.5a.75.75,0,1,0,.75.75A.75.75,0,0,0,18.25,11Zm-7.091,4a.52.52,0,0,0-.363.144L6,19.788V21.25a2.75,2.75,0,0,0,2.5,2.737L14.577,18.1l-3.054-2.954A.523.523,0,0,0,11.159,15Zm6.928,2a.521.521,0,0,0-.363.143L10.639,24H21.25a2.75,2.75,0,0,0,2.537-1.69l-5.336-5.162A.524.524,0,0,0,18.087,17Z" transform="translate(879 110)" fill="#5078e1" opacity="0" />
                <path id="icons8-bill_9_" data-name="icons8-bill (9)" d="M8.723,5A.711.711,0,0,0,8,5.71V20.394A2.616,2.616,0,0,0,10.605,23h9.947a2.616,2.616,0,0,0,2.605-2.605V5.71a.711.711,0,0,0-1-.649L20.3,5.887l-2.09-.836a.711.711,0,0,0-.527,0l-2.1.842-2.1-.842a.711.711,0,0,0-.527,0l-2.09.836L9,5.061A.711.711,0,0,0,8.723,5Zm2.355,5.684h9a.711.711,0,0,1,0,1.421h-9a.711.711,0,0,1,0-1.421Zm0,4.263h5.21a.711.711,0,0,1,0,1.421h-5.21a.711.711,0,0,1,0-1.421Zm8.053,0h.947a.711.711,0,0,1,0,1.421h-.947a.711.711,0,1,1,0-1.421Zm-8.053,2.842h5.21a.711.711,0,0,1,0,1.421h-5.21a.711.711,0,1,1,0-1.421Zm8.053,0h.947a.711.711,0,0,1,0,1.421h-.947a.711.711,0,1,1,0-1.421Z" transform="translate(878 111)" fill="#98a0ac" />
            </g>
        </svg>

    )
}