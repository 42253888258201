import React from "react";
import { withNavBars } from "../../../HOCs";
import AmenitiesMappingPropertyView from "./amenitiesMappingPropertyView";

class AmenitiesMappingPropertyViewParent extends React.Component {
    render() {
        return <AmenitiesMappingPropertyView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AmenitiesMappingPropertyViewParent, props);
