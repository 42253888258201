export const propertyPurposeListresidential = [
    {
        label: "Lease",
        value: "Lease",
        unitCategory: [
            {
                label: "All",
                value: null,
            },
            {
                label: "Residential Unit",
                value: "Residential Unit",
            },
            {
                label: "Community Unit",
                value: "Community Unit",
            },
            {
                label: "Parking Unit",
                value: "Parking Unit",
            },
            {
                label: "Land",
                value: "Land",
            },
            {
                label: "Plot",
                value: "Plot",
            }
        ]
    },
    {
        label: "Buy",
        value: "Sale",
        unitCategory: [
            {
                label: "All",
                value: null,
            },
            {
                label: "Residential Unit",
                value: "Residential Unit",
            },
            {
                label: "Community Unit",
                value: "Community Unit",
            },
            {
                label: "Parking Unit",
                value: "Parking Unit",
            },
            {
                label: "Land",
                value: "Land",
            },
            {
                label: "Plot",
                value: "Plot",
            }
        ]
    },

];

export const propertyPurposeListCommercial = [
    {
        label: "Lease",
        value: "Lease",
        unitCategory: [
            {
                label: "All",
                value: null,
            },

            {
                label: "Commercial Unit",
                value: "Commercial Unit",
            },
            {
                label: "Parking Unit",
                value: "Parking Unit",
            },
            {
                label: "Land",
                value: "Land",
            },
            {
                label: "Plot",
                value: "Plot",
            }
        ]
    },
    {
        label: "Buy",
        value: "Sale",
        unitCategory: [
            {
                label: "All",
                value: null,
            },

            {
                label: "Community Unit",
                value: "Community Unit",
            },
            {
                label: "Parking Unit",
                value: "Parking Unit",
            },
            {
                label: "Land",
                value: "Land",
            },
            {
                label: "Plot",
                value: "Plot",
            }
        ]
    },

];

export const propertyPurposeListStorage = [
    {
        label: "Lease",
        value: "Lease",
        unitCategory: [
            {
                label: "All",
                value: null,
            },

            {
                label: "Storage Unit",
                value: "Storage Unit",
            },

        ]
    },
    {
        label: "Buy",
        value: "Sale",
        unitCategory: [
            {
                label: "All",
                value: null,
            },

            {
                label: "Storage",
                value: "Storage",
            }
        ]
    },

];