import * as React from "react"

const LeftArrow = (props) => (
    <svg
        data-name="Group 102050"
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        {...props}
    >
        <g data-name="Ellipse 129422" fill="#fff" stroke="#e4e8ee">
            <circle cx={12.5} cy={12.5} r={12.5} stroke="none" />
            <circle cx={12.5} cy={12.5} r={12} fill="none" />
        </g>
        <g data-name="Group 102049" stroke={props?.color}>
            <path
                d="M7.739 12.792a.412.412 0 0 1 0-.583l3.92-3.916a.412.412 0 0 1 .579.579L8.613 12.5l3.625 3.625a.412.412 0 1 1-.579.583Z"
                fill={props?.color}
                strokeWidth={0.5}
            />
            <path
                data-name="Line 1219"
                fill="none"
                strokeLinecap="round"
                strokeWidth={1.5}
                d="M9.009 12.5h8.374"
            />
        </g>
    </svg>
)

export default LeftArrow
