import React from "react";
import { withNavBars } from "../../HOCs";
import DomesticHelperList from "./domesticHelperList";

class DomesticHelperListParent extends React.Component {
  render() {
    return <DomesticHelperList />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(DomesticHelperListParent, props);
