import * as React from "react"
const BillingAmountIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 115895" transform="translate(-916 -146)">
      <rect
        width={48}
        height={48}
        fill="#f6f1fe"
        data-name="Rectangle 58150"
        rx={4}
        transform="translate(916 146)"
      />
      <g data-name="Group 115894">
        <path
          fill="#896db3"
          d="M931.3 159a3.3 3.3 0 0 0-3.3 3.3v14.1a.9.9 0 0 0 1.077.882l2.523-.5v3.818a.9.9 0 0 0 1.077.882l2.8-.56 2.2.551a.893.893 0 0 0 .436 0l2.2-.551 2.8.56a.885.885 0 0 0 .177.018.9.9 0 0 0 .9-.9v-1.812a8.578 8.578 0 0 1-1.8-.26v.972l-1.923-.386a.887.887 0 0 0-.395.011l-2.182.544-2.182-.545a.9.9 0 0 0-.395-.009l-1.913.385v-2.4l.682.17a.893.893 0 0 0 .436 0l2.2-.551 2.8.561a.885.885 0 0 0 .177.018.891.891 0 0 0 .889-.81 7.5 7.5 0 1 0 .016-12.788v-2a.9.9 0 0 1 1.8-.026 8.54 8.54 0 0 1 1.774-.255A2.7 2.7 0 0 0 941.5 159Zm0 1.8h7.655a2.693 2.693 0 0 0-.155.9v13.6l-1.923-.386a.887.887 0 0 0-.395.011l-2.182.544-2.182-.545a.892.892 0 0 0-.218-.024.922.922 0 0 0-.177.018l-1.923.382v-13a1.5 1.5 0 0 1 1.5-1.5Zm.6 2.982a.9.9 0 1 0 0 1.8h4.8a.9.9 0 1 0 0-1.8Zm12.6.618a5.7 5.7 0 1 1-3.9 9.847v-8.294a5.66 5.66 0 0 1 3.9-1.553Zm-.027 2.4a.9.9 0 0 0-.258.046l-1.8.6a.9.9 0 1 0 .57 1.709l.615-.205v3.55a.9.9 0 1 0 1.8 0v-4.8a.9.9 0 0 0-.927-.9Zm-12.573.582a.9.9 0 1 0 0 1.8h4.8a.9.9 0 1 0 0-1.8Zm0 3.6a.9.9 0 1 0 0 1.8h3a.9.9 0 1 0 0-1.8Z"
          data-name="icons8-bill (8)"
        />
      </g>
    </g>
  </svg>
)
export default BillingAmountIcon
