import React from "react";
import { withNavBars } from "../../HOCs";
import LeaveMaster from "./leaveMaster";

class LeaveMasterParent extends React.Component {
  render() {
    return <LeaveMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(LeaveMasterParent, props);
