import React from "react"

export const CompanyIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_55834" data-name="Rectangle 55834" width="32" height="32" fill="#fff" stroke="#707070" stroke-width="1"/>
    </clipPath>
    <linearGradient id="linear-gradient" x1="0.989" y1="0.5" x2="-0.003" y2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#0d61a9"/>
      <stop offset="0.482" stop-color="#0d60a7"/>
      <stop offset="0.711" stop-color="#0f5c9f"/>
      <stop offset="0.886" stop-color="#125592"/>
      <stop offset="1" stop-color="#154e85"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.957" y1="0.5" x2="-0.01" y2="0.5" gradientUnits="objectBoundingBox">
      <stop offset="0.365" stop-color="#32bdef"/>
      <stop offset="0.49" stop-color="#2fb7e9"/>
      <stop offset="0.66" stop-color="#26a5d7"/>
      <stop offset="0.854" stop-color="#1789bb"/>
      <stop offset="1" stop-color="#096fa1"/>
    </linearGradient>
  </defs>
  <g id="Group_93153" data-name="Group 93153" clip-path="url(#clip-path)">
    <g id="icons8-company" transform="translate(-0.685 -1)">
      <path id="Path_99317" data-name="Path 99317" d="M34,15h3.789a1.263,1.263,0,0,1,1.263,1.263V32.684H34Z" transform="translate(-10.684 -3.684)" fill="url(#linear-gradient)"/>
      <path id="Path_99318" data-name="Path 99318" d="M34,31.632h2.526V29.105H34Zm0-10.105h2.526V19H34Zm0,5.053h2.526V24.053H34Z" transform="translate(-10.684 -5.158)" fill="url(#linear-gradient-2)"/>
      <rect id="Rectangle_55885" data-name="Rectangle 55885" width="17.684" height="7.579" transform="translate(5.632 21.421)" fill="#0078d4"/>
      <path id="Path_99319" data-name="Path 99319" d="M10,7.526H22.632V6.263A1.264,1.264,0,0,0,21.368,5H11.263A1.264,1.264,0,0,0,10,6.263Z" transform="translate(-1.842)" fill="#199be2"/>
      <path id="Path_99320" data-name="Path 99320" d="M6,11V23.632H23.684V11Z" transform="translate(-0.368 -2.211)" fill="#199be2"/>
      <rect id="Rectangle_55886" data-name="Rectangle 55886" width="2.526" height="2.526" transform="translate(8.158 23.947)" fill="#005094"/>
      <rect id="Rectangle_55887" data-name="Rectangle 55887" width="2.526" height="2.526" transform="translate(18.263 23.947)" fill="#005094"/>
      <rect id="Rectangle_55888" data-name="Rectangle 55888" width="3.789" height="5.053" transform="translate(12.579 23.947)" fill="#005094"/>
      <rect id="Rectangle_55889" data-name="Rectangle 55889" width="2.526" height="2.526" transform="translate(8.158 16.368)" fill="#0d62ab"/>
      <rect id="Rectangle_55890" data-name="Rectangle 55890" width="2.526" height="2.526" transform="translate(13.211 16.368)" fill="#0d62ab"/>
      <rect id="Rectangle_55891" data-name="Rectangle 55891" width="2.526" height="2.526" transform="translate(18.263 16.368)" fill="#0d62ab"/>
      <rect id="Rectangle_55892" data-name="Rectangle 55892" width="2.526" height="2.526" transform="translate(8.158 11.316)" fill="#0d62ab"/>
      <rect id="Rectangle_55893" data-name="Rectangle 55893" width="2.526" height="2.526" transform="translate(13.211 11.316)" fill="#0d62ab"/>
      <rect id="Rectangle_55894" data-name="Rectangle 55894" width="2.526" height="2.526" transform="translate(18.263 11.316)" fill="#0d62ab"/>
      <path id="Path_99321" data-name="Path 99321" d="M23.316,10.263H5.632A.632.632,0,1,1,5.632,9H23.316a.632.632,0,1,1,0,1.263Z" transform="translate(0 -1.474)" fill="#35c1f1"/>
    </g>
  </g>
</svg>

)