import { Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export const CircularProgressBar = (props) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: props?.background_color ?? "#E4E8EE",
                }}
                size={props?.size ?? 40}
                thickness={props?.thickness ?? 4}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                sx={{
                    color: props?.fill_color ?? "#FF4B4B",
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: props?.strokeLinecap ?? 'round',
                    },
                }}
                size={props?.size ?? 40}
                thickness={props?.thickness ?? 4}
                value={props?.value ?? 0}
            />
        </Box>
    );
}