import DeleteIcon from "@mui/icons-material/Delete";
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { AlertContext, BackdropContext } from "../../contexts";
import { multiFileUpload, assestType } from '../../utils/common';
import { AlertProps, allowed_file_size, SemiBold } from "../../utils";
const useStyles = makeStyles((theme) => ({
  fileroot: {
    width: "100%",

    fontSize:"0.75rem",
    color: "#5078E1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "2px dashed #E4E8EE",
    borderRadius: "15px",
    padding: "9px",
  },
  imgContainer: {
    width: "100%",
    maxWidth: "150px",
    margin: "0px 10px",
    borderRadius: "12px",
    display: "flex",
  },
  delete: {
    color: "red",
    position: "absolute",
    top: "4px",
    right: "8px",
    backgroundColor: "white",
  },
  uploadTittle: {
    fontSize:"0.875rem",
    color: "#828C99",
  },
  uploadcount: {
    fontSize:"1rem",
    color: "#404E61",
    fontFamily: SemiBold,
    paddingLeft: "16px",
  },
}));
export const SingleImgUpload = (props) => {
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);

  const updateState = async (data) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    try {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      let propsData = { companyId: props?.companyId, type: assestType?.General_Images }
      const uploaded_files = await multiFileUpload(data, propsData, alert, allowed_file_size)
      uploaded_files?.length > 0 && props?.selectedImageURL?.push(...uploaded_files);
      props?.updateImageURL(props?.selectedImageURL?.map((val) => val));
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
    }
    catch (err) {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Not Edited!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
    }

  }

  const deleteImg = (e) => {
    props?.handleFile(e);
  };

  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2} style={{ marginTop: "2px" }}>
        <Typography className={classes.uploadcount}>Upload Images</Typography>
        <Grid item xs={12}>
          <label className="btn label">
            <div className={classes.fileroot}>
              <input
                accept={props?.accept}
                type="file"
                name="myImage"
                style={{ display: "none" }}
                onChange={(e) => {
                  updateState(e.target.files);
                }}
                multiple
              />
              <center>
                <DriveFolderUploadOutlined
                  style={{ fontSize: "58px", color: "#5078E1" }}
                />
                <Typography className={classes.uploadTittle}>
                  Upload image here
                </Typography>
              </center>
            </div>
          </label>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "16px" }}>
        <Typography className={classes.uploadcount}>
          Uploading {props?.selectedImageURL?.length} images
        </Typography>
        <Grid item xs={12} style={{ display: "flex", marginTop: "6px" }}>
          {props?.selectedImageURL?.map((val, index) => {
            return (
              <>
                <Box className={classes.imgContainer}>
                  <div style={{ position: "relative" }}>
                    <img
                      type="file"
                      accept="image/*"
                      multiple
                      alt="not fount"
                      width={"250px"}
                      src={val?.url ?? ""}
                      style={{
                        objectFit: "cover",
                        height: "150px",
                        width: "100%",
                        borderRadius: "12px",
                      }}
                    />

                    <br />
                    <IconButton
                      className={classes.delete}
                      onClick={() => deleteImg(val)}
                      size="small"
                    >
                      <DeleteIcon style={{ fontSize:"0.875rem" }} />
                    </IconButton>
                  </div>
                </Box>
              </>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};
