import React from "react";
import { withNavBars } from "../../HOCs";
import SurveyForm from "./surveyForm";

class SurveyFormParent extends React.Component {
  render() {
    return <SurveyForm />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(SurveyFormParent, props);