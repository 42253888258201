export const Inventory = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-1{fill:#c1c5cb}"}</style>
        </defs>
        <g
            id="Group_102131"
            data-name="Group 102131"
            transform="translate(24585 -3978)"
        >
            <g id="inventory" transform="translate(-24578 3985)">
                <path
                    id="Path_96845"
                    data-name="Path 96845"
                    className="cls-1"
                    d="M0 7.531h7.531V0H0ZM3.3.938h.938v1.406H3.3Zm-2.362 0h1.421v2.343h2.813V.938h1.422v5.656H.938Zm0 0"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96846"
                    data-name="Path 96846"
                    className="cls-1"
                    d="M271 0v7.531h7.531V0Zm3.3.938h.938v1.406h-.938Zm3.3 5.656h-5.656V.938h1.422v2.343h2.813V.938h1.422Zm0 0"
                    transform="translate(-262.531)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96847"
                    data-name="Path 96847"
                    className="cls-1"
                    d="M0 278.531h7.531V271H0Zm3.3-6.594h.938v1.406H3.3Zm-2.359 0h1.418v2.344h2.813v-2.344h1.422v5.656H.938Zm0 0"
                    transform="translate(0 -262.531)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96848"
                    data-name="Path 96848"
                    className="cls-1"
                    d="M271 278.531h7.531V271H271Zm3.3-6.594h.938v1.406h-.938Zm-2.359 0h1.422v2.344h2.813v-2.344h1.422v5.656h-5.656Zm0 0"
                    transform="translate(-262.531 -262.531)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24585 3978)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
        </g>
    </svg>
)
