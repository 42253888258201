import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular } from "../../utils";

export const useStylesCreation = makeStyles((size) => ({
      tabtitle: {
            fontSize:"0.75rem",
            color: '#4E5A6B',
            fontFamily: Bold
      },
      tabtitle1: {
            fontSize:"0.75rem",
            color: '#5078E1',
            fontFamily: Bold
      },
      tabsub: {
            fontSize:"0.75rem",
            color: '#98A0AC',
            fontFamily: Regular
      },
      tabs: {
            '&.MuiTab-root': {
                  padding: '0px !important'
            }
      },
      after: {
            position: 'absolute',
            right: '0',
            fontSize: 'larger',
            color: '#e3e3e3',
            fontWeight: 100
      }
}));