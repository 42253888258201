export const Quotationpath = ["quotationId", "leadName", "agent", "units", "value", "period", "expireOn", "status", "icon"]

export const Quotationrow = [
  {
    sno: "",
    quotationId: "1234",
    leadName: "Cris",
    agent: "Zidane",
    units: "1",
    value: "$ 2000",
    period: "2 months",
    expireOn: "23 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Kroos",
    agent: "Marcelo",
    units: "2",
    value: "$ 2000",
    period: "2 months",
    expireOn: "23 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
  {
    sno: "",
    quotationId: "1234",
    leadName: "Modric",
    agent: "Benzema",
    units: "3",
    value: "$ 2000",
    period: "2 months",
    expireOn: "22 Oct 23",
    status: "Quote Accepted",
    icon: "view"
  },
]
export const Quotationheading = [
  { title: "Quotation Id", field: "quotationId", },
  { title: "Lead Name", field: "leadName", },
  { title: "Agent", field: 'agent', },
  { title: "Units", field: "units", },
  { title: "Value", field: 'value', },
  { title: "Period", field: 'period', },
  { title: "Expire On", field: 'expireOn', },
  { title: "Status", field: 'status', },
  { title: "", field: 'icon' },
]

export const QuotationSummaryPath = ["description", "qty", "amount"]

export const QuotationSummaryRow = [
  {
    description: "Total Amount",
    qty: "3 Nos",
    amount: "$ 3000.OO"
  },
  {
    description: "Total Refundable",
    qty: "0%",
    amount: "$ 0"
  },
  {
    description: "Total Tax",
    qty: "18%",
    amount: "$ 648"
  },
]
export const QuotationSummaryHeading = [
  { title: "DESCRIPTION", field: "description", type: "numeric" },
  { title: "QTY", field: "qty", },
  { title: "AMOUNT", field: "amount", },
]

export const JSON = [
  {
    image: "image",
    headtext: "K-F01-394",
    flatAddress: "K Tower, 1 Floor",
    sqft: "1250 Sq ft",
    bhk: "3 Bedroom",
    price: "$ 1,200.00",
  },
  {
    image: "image",
    headtext: "K-F01-394",
    flatAddress: "K Tower, 1 Floor",
    sqft: "1250 Sq ft",
    bhk: "3 Bedroom",
    price: "$ 1,200.00",
  },
  {
    image: "image",
    headtext: "K-F01-394",
    flatAddress: "K Tower, 1 Floor",
    sqft: "1250 Sq ft",
    bhk: "3 Bedroom",
    price: "$ 1,200.00",
  },
  {
    image: "image",
    headtext: "K-F01-394",
    flatAddress: "K Tower, 1 Floor",
    sqft: "1250 Sq ft",
    bhk: "3 Bedroom",
    price: "$ 1,200.00",
  },
  {
    image: "image",
    headtext: "K-F01-394",
    flatAddress: "K Tower, 1 Floor",
    sqft: "1250 Sq ft",
    bhk: "3 Bedroom",
    price: "$ 1,200.00",
  }
]

export const QuotationDetails =
{
  quotationId: 12345,
  quoteStartDate: "30 jan 22",
  quoteExpireDate: "28 feb 22",
  leaseperiod: "Yearly",
  leaseStartDate: "30 Jan 22",
  leaseEndDate: "30 Jan 23",
  duration: "3 Weeks",
  gracePeriod: "30 Days",
  agent: "Alan Ford Taylor"
}

export const dummyData = [
  {
    "id": "ce9204e8-e82c-4b3d-86e0-9faca5f00abd",
    "url": "https://protomate.objectstore.e2enetworks.net/1649812205721-pexels-photo-106399.jpeg",
    "name": "Unit 10209",
    "unit_no": "PG2UNT220413-146",
    "currency": 1,
    "room_rent": null,
    "unit_type": null,
    "furnishing": "Furnished",
    "total_area": 1230,
    "year_built": null,
    "area_metric": "Hectare",
    "carpet_area": 1200,
    "description": "<p>test unit</p>",
    "total_baths": null,
    "total_rooms": 3,
    "total_bed_rooms": null
  },
  {
    "id": "e33a5d9c-a8fc-4b5a-941b-f24b74b7ed22",
    "url": "https://protomate.objectstore.e2enetworks.net/1649812205473-download%20%283%29.jfif.jpeg",
    "name": "Test buying unit",
    "unit_no": "PG2UNT220413-147",
    "currency": 1,
    "room_rent": null,
    "unit_type": null,
    "furnishing": "Non-Furnished",
    "total_area": 1223,
    "year_built": "2022-06-15T00:00:00+05:30",
    "area_metric": "Hectare",
    "carpet_area": 1200,
    "description": "<p>test</p>",
    "total_baths": null,
    "total_rooms": 1,
    "total_bed_rooms": null
  },
  {
    "id": "ce9204e4-e82c-4b3d-86e0-9faca5f00abd",
    "url": "https://protomate.objectstore.e2enetworks.net/1649812205721-pexels-photo-106399.jpeg",
    "name": "Unit 10209",
    "unit_no": "PG2UNT220413-146",
    "currency": 1,
    "room_rent": null,
    "unit_type": null,
    "furnishing": "Furnished",
    "total_area": 1230,
    "year_built": null,
    "area_metric": "Hectare",
    "carpet_area": 1200,
    "description": "<p>test unit</p>",
    "total_baths": null,
    "total_rooms": 3,
    "total_bed_rooms": null
  },
  {
    "id": "e33a5d94-a8fc-4b5a-941b-f24b74b7ed22",
    "url": "https://protomate.objectstore.e2enetworks.net/1649812205473-download%20%283%29.jfif.jpeg",
    "name": "Test buying unit",
    "unit_no": "PG2UNT220413-147",
    "currency": 1,
    "room_rent": null,
    "unit_type": null,
    "furnishing": "Non-Furnished",
    "total_area": 1223,
    "year_built": "2022-06-15T00:00:00+05:30",
    "area_metric": "Hectare",
    "carpet_area": 1200,
    "description": "<p>test</p>",
    "total_baths": null,
    "total_rooms": 1,
    "total_bed_rooms": null
  }
]

export const relationENUM = [
  {
    label: "Customer",
    value: "Customer"
  },
  {
    label: "Vendor",
    value: "Vendor"
  },
  {
    label: "Partner",
    value: "Partner"
  },
  {
    label: "Supplier",
    value: "Supplier"
  },
  {
    label: "Service Provider",
    value: "Service Provider"
  },
  {
    label: "Consultant",
    value: "Consultant"
  },
  {
    label: "External Broker",
    value: "External Broker"
  },
  {
    label: "Investor",
    value: "Investor"
  },
  {
    label: "Reseller",
    value: "Reseller"
  },
  {
    label: "Other",
    value: "Other"
  },

]



export const mapDescription = (map) => {
  let updateData = {
    latitude: map?.lat,
    longitude: map?.lng
  };
  if (map) {
    let keys = ['description', 'addressLineOne', 'addressLineTwo', 'landmark', 'area', 'district', 'city', 'state', 'country', 'pincode', 'zipcode'];
    let findAddress = map?.result?.address_components;

    keys?.map(_ => {
      switch (_) {
        case 'description':
          updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["premise"]?.includes(type)))?.long_name ?? ''
          break;
        case 'addressLineOne':
          updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["establishment", "street_number"]?.includes(type)))?.long_name ?? ''
          break;
        case 'addressLineTwo':
          updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["neighborhood", "route",]?.includes(type)))?.long_name ?? ''
          break;
        case 'landmark':
          updateData[_] = findAddress?.find(_ => _?.types?.includes('landmark'))?.long_name ?? ''
          break;
        case 'area':
          updateData[_] = findAddress?.find(_ => _?.types?.includes("political", "locality"))?.long_name ?? ''
          break;
        case 'district':
          updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
          break;
        case 'city':
          updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
          break;
        case 'state':
          updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_1'))?.long_name ?? ''
          break;
        case 'country':
          updateData[_] = findAddress?.find(_ => _?.types?.includes('country'))?.long_name ?? ''
          break;
        case 'pincode':
          updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
          break;
        case 'zipcode':
          updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
          break;
        default:
          return null
      }
      return _
    })
  }

  return updateData
}