import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Stack, Typography, Menu, MenuItem } from "@mui/material";
import React, { useRef } from "react";
import { PrioritySelectUseStyles } from "./styles";
export const PrioritySelect = ({
  value = "",
  onChange = () => false,
  options = [],
  isRequired = false,
  t=()=>false
}) => {
  // const [initialvalue, setInitialValue] = useState("low")
  const dropdown_ref = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key, value) => {
    if (key === "save") {
      onChange(value);
    }
    setAnchorEl(null);
  };

  const colorObject = {
    "low": "#06D6A0",
    "medium": "#FFD166",
    "high": "#EFB93C",
    "urgent": "#EF476F"
  }

  const classes = PrioritySelectUseStyles();
  return (
    <Box>
      <Typography className={classes.priorityLabel}>
        {t("Priority")}
        {isRequired ? (
          <Typography variant="caption" className={classes.required}>
            *
          </Typography>
        ) : (
          <Typography
            variant="caption"
            className={classes.required}
          ></Typography>
        )}
      </Typography>
      <Box
        ref={dropdown_ref}
        className={classes.priorityBox}
        onClick={handleClick}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{
          backgroundColor: colorObject[value],
        }}>
        <Typography className={classes.initialText}>{value}</Typography>
        <KeyboardArrowDown className={classes.arrowIcon} />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className={classes.menu}
      >
        {options.map((x, index) => {
          return (
            <MenuItem onClick={() => handleClose("save", x?.value)}
              sx={{
                height: "44px",
                width: dropdown_ref?.current?.clientWidth,
                borderBottom: index !== options.length - 1 && "1px solid #E4E8EE"
              }}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Box
                  className={classes.dot}
                  style={{
                    backgroundColor: colorObject[x?.value],
                  }}
                ></Box>
                <Typography className={classes.priorityName}>
                  {x.label}
                </Typography>
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
