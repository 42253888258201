import CheckCircle from '@mui/icons-material/CheckCircle';
import { Avatar, Box, Typography } from "@mui/material";
import React from 'react';
import { stringAvatar } from '../../../utils';
import { useStyles } from "../style";

export const CompanyList = (props) => {
      const classes = useStyles();
      return (
            <Box className={props?.data?.id === props?.companyID ? classes.selectCompanyBorder : classes.selectCompany} onClick={() => props?.companySelect(props?.data)}>
                  <Box className={classes.companySelect}>
                        {/* company logo */}
                        <Avatar src={props?.data?.company_logo ?? ""} alt='comapany_img' className={classes.comapnyImage} {...stringAvatar(props?.data?.name)}/>
                        {/* company name */}
                        <Typography className={classes.companyName} Wrap>{props?.data?.name}</Typography>
                  </Box>
                  {/* tick icon */}
                  <Box>
                        {
                              props?.data?.id === props?.companyID && 
                              <CheckCircle className={classes.tickIcon}/>
                              // <TickImageComponent white={true}/>
                        }
                       

                  </Box>
            </Box>
      )
}