export const DomesticHelp = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Delivery Collection">
            <path data-name="Rectangle 52494" fill="none" d="M0 0h30v30H0z" />
            <g data-name="Domestic Help">
                <g data-name="Group 99025">
                    <g data-name="Group 99024">
                        <path
                            data-name="Path 95320"
                            d="M11.763 19.437v2.848a.715.715 0 0 0 1.43 0v-2.848Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
                <g data-name="Group 99027">
                    <g data-name="Group 99026" transform="translate(12.112 8)">
                        <circle
                            data-name="Ellipse 129235"
                            cx={1.235}
                            cy={1.235}
                            r={1.235}
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
                <g data-name="Group 99029">
                    <g data-name="Group 99028">
                        <path
                            data-name="Path 95321"
                            d="m12.421 10.863.517 1.459h.8l.517-1.459Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
                <g data-name="Group 99031">
                    <g data-name="Group 99030">
                        <path
                            data-name="Path 95322"
                            d="M20.751 22.193c-.253-.288-.782-.251-1.141-.183a.217.217 0 0 1-.258-.2c-.008-.182-.161-.416-.758-.6a1.67 1.67 0 0 0-.531-.075v-7.29l.177-.072a.6.6 0 0 0-.177-1.145V9.655a.3.3 0 0 0-.6 0v3.146l-1.227.5c-.185-.465-.365-.882-.484-1.227a1.623 1.623 0 0 0-1.024-1.165l-.521 1.471.6 3.848a.292.292 0 0 1-.288.342h-.111l-.252 2.318a.614.614 0 0 1-.612.549h-.046v2.848a.715.715 0 0 0 1.43 0v-4.321h.455a.331.331 0 0 0 .325-.394l-.856-4.515c.036.088.317.79.485 1.231a.609.609 0 0 0 .318.342.6.6 0 0 0 .467.007l1.342-.547v7.145a3.237 3.237 0 0 0-1.119.663c-1.749 1.57 2.974.9 4.17 1.052s.64-.296.236-.755Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
                <g data-name="Group 99033">
                    <g data-name="Group 99032">
                        <path
                            data-name="Path 95323"
                            d="M13.617 14.968a.661.661 0 0 0-.01 1.021h-1.973a.982.982 0 0 1 .22-.588l-.425-.206a1.443 1.443 0 0 0-.259.794l.3 2.857a.217.217 0 0 0 .216.194h1.871a.217.217 0 0 0 .216-.194l.3-2.857a1.448 1.448 0 0 0-.456-1.021Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
                <g data-name="Group 99035">
                    <g data-name="Group 99034">
                        <path
                            data-name="Path 95324"
                            d="m12.933 14.175-1.471-.714.679-1.1-.254.883.463.225.129-1.053-.532-1.5a1.545 1.545 0 0 0-.834.828 89.773 89.773 0 0 1-.975 1.571.621.621 0 0 0-.128.5.6.6 0 0 0 .325.426l2.078 1.009a.6.6 0 1 0 .52-1.072Z"
                            fill={props?.fill ?? "#c1c5cb"}
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
