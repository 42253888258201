import * as React from "react"
const ParkingIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <defs>
      <clipPath id="a">
        <path
          fill="#4e5a6b"
          stroke="#707070"
          d="M46 112h16v16H46z"
          data-name="Rectangle 57270"
        />
      </clipPath>
    </defs>
    <g
      clipPath="url(#a)"
      data-name="Mask Group 93141"
      transform="translate(-46 -112)"
    >
      <path
        fill="#4e5a6b"
        d="M54.001 112a2.207 2.207 0 0 0-1.368.472l-5.009 3.951a3.162 3.162 0 0 0-1.2 2.482v7.618a1.485 1.485 0 0 0 1.475 1.475H60.11a1.485 1.485 0 0 0 1.475-1.475v-7.62a3.162 3.162 0 0 0-1.2-2.482l-5.017-3.948a2.207 2.207 0 0 0-1.367-.473Zm0 1.261a.945.945 0 0 1 .586.2l5.012 3.949a1.894 1.894 0 0 1 .725 1.493v7.62a.2.2 0 0 1-.211.211h-1.9a.422.422 0 0 0 .421-.421v-3.579a1.873 1.873 0 0 0-.429-1.181q0-.024-.01-.047l-.4-1.731a1.9 1.9 0 0 0-1.846-1.465h-3.895a1.9 1.9 0 0 0-1.847 1.465l-.4 1.731q0 .022-.008.045a1.874 1.874 0 0 0-.43 1.183v3.582a.422.422 0 0 0 .421.421h-1.9a.2.2 0 0 1-.211-.211v-7.623a1.894 1.894 0 0 1 .723-1.489l5.012-3.949a.945.945 0 0 1 .587-.204Zm-1.947 6.312h3.893a.625.625 0 0 1 .615.488l.181.777h-5.485l.181-.777a.626.626 0 0 1 .615-.488Zm-.793 2.528h5.478a.623.623 0 0 1 .632.632v1.9H50.63v-1.9a.623.623 0 0 1 .632-.632Zm.843.843a.632.632 0 1 0 .632.632.632.632 0 0 0-.631-.632Zm3.792 0a.632.632 0 1 0 .632.632.632.632 0 0 0-.631-.632Zm-4.845 2.95h5.9v.421a.422.422 0 0 0 .421.421H50.63a.422.422 0 0 0 .421-.421Z"
      />
    </g>
  </svg>
)
export default ParkingIcon
