import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold, Regular } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  catagoryTilte: {
    padding: "16px",
    borderBottom: `1px solid #E4E8EE`,
  },
  iconViewParent: {
    height: "calc(100vh - 300px)",
    overflow: "overlay"
  },
  root: {
    boxShadow: `0px 0px 16px #0000001A`,
    borderRadius: "4px",
    backgroundColor: "white",
    margin: "16px",
  },
  FileManagertCatogry: {
    color: "#091B29",
    fontFamily: SemiBold,
    fontSize:"0.875rem",
  },
  active_hierarchy: {
    cursor: "default",
    color: "#091B29",
    fontFamily: SemiBold,
    fontSize:"0.875rem",
  },
  inactive_hierarchy: {
    cursor: "pointer",
    color: "#98A0AC",
    fontFamily: SemiBold,
    fontSize:"0.875rem",
  },
  fileManager: {
    color: "#98A0AC",
    fontFamily: Bold,
    fontSize:"0.875rem",
    marginTop: "12px",
  },
  viewType: {
    border: `1px solid #E4E8EE`,
    borderRadius: "4px",
    padding: "8px",
  },
  img: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: theme.palette.borderRadius,
    padding: "13px",
  },
  viewIcon: {
    borderRadius: theme.palette.borderRadius,
    padding: "5px",
  },
  paddingRoot: {
    padding: "16px 16px 16px 0px",
  },
  btn: {
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "10px 15px",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
  },
  menuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  menuItem: {
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
    margin: "0px 4px",
    fontSize:"0.875rem",
    color: theme?.palette?.secondary?.main,
    fontFamily: `${Regular}`,
    "&:last-child": {
      border: 0,
    },
  },
  isPrivateCheckBox: {
    cursor: "pointer",
    fontFamily: SemiBold,
    color: theme?.typography?.color?.primary,
    fontSize:"0.875rem"
  },
  deleteQuestion: {
    fontFamily: SemiBold,
    color: theme?.typography?.color?.primary,
    fontSize:"0.875rem"
  },
  outLine: {
    fontFamily: SemiBold,
    color: theme?.typography?.color?.primary,
    backgroundColor: "white",
    padding: "10px 15px",
    width: "100%",
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    "&:hover": {
      color: theme?.typography?.color?.primary,
      backgroundColor: "white",
      border: `1px solid ${theme?.palette?.border?.secondary}`,
    },
  },
  create: {
    fontFamily: SemiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "10px 15px",
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
  },
  detailsBox: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    backgroundColor: theme?.palette?.background?.background
  },
  viewFileTilte: {
    color: theme?.typography?.color?.primary,
    fontSize:"0.875rem",
    fontFamily: Bold
  },
  viewFileSub: {
    color: theme?.typography?.color?.secondary,
    fontSize:"0.75rem",
    fontFamily: SemiBold
  },
  viewDetailsTilte: {
    color: theme?.typography?.color?.Tertiary,
    fontSize:"0.75rem",
    fontFamily: SemiBold
  },
  viewDetailsSub: {
    color: theme?.typography?.color?.primary,
    fontSize:"0.875rem",
    fontFamily: SemiBold
  },
}));
