import CommercialActiveIcon from "./commercialActiveIcon";
import CommercialInActiveIcon from "./commercialInActiveIcon";
import OwnerActiveIcon from "./ownerActiveIcon";
import OwnerInActiveIcon from "./ownerInActiveIcon";
import ResidentialActiveIcon from "./residentialActiveIcon";
import ResidentialInActiveIcon from "./residentialInActiveIcon";
import TenentActiveIcon from "./tenentActiveIcon";
import TenentInActiveIcon from "./tenentInActiveIcon";

export const boardingPropertyType = [
    {
        title:"Residential",
        label: "Residential",
        value: "Residential",
        selectedIcon: <ResidentialActiveIcon />,
        notSelectedIcon: <ResidentialInActiveIcon />
    },
    {
        title:"Commercial",
        label: "Commercial",
        value: "Commercial",
        selectedIcon: <CommercialActiveIcon />,
        notSelectedIcon: <CommercialInActiveIcon />
    }
]

export const boardingPropertyTypeArabic = (t) => [
    { 
        title:t("Residential"),
        label: t("Residential"),
        value: "Residential",
        selectedIcon: <ResidentialActiveIcon />,
        notSelectedIcon: <ResidentialInActiveIcon />
    },
    {
        title: t("Commercial"),
        label: t("Commercial"),
        value: "Commercial",
        selectedIcon: <CommercialActiveIcon />,
        notSelectedIcon: <CommercialInActiveIcon />
    }
]

export const activeOccupents = [
    {
        title: "Tenant",
        selectedIcon: <TenentActiveIcon />,
        notSelectedIcon: <TenentInActiveIcon />
    },
    {
        title: "Owner",
        selectedIcon: <OwnerActiveIcon />,
        notSelectedIcon: <OwnerInActiveIcon />
    }
]

export const durationOption = [
    {
        label: "Daily",
        value: "Daily"
    },
    {
        label: "Monthly",
        value: "Monthly"
    },
    {
        label: "Yearly",
        value: "Yearly"
    }
]

export const billingCycleMethod = [
    {
        label: "Prepaid",
        value: "Prepaid"
    },
    {
        label: "Postpaid",
        value: "Postpaid"
    }
]

export const billingCycleDate = [
    {
        label: "Agreement",
        value: "Agreement"
    },
    {
        label: "Custom",
        value: "Custom"
    }
]