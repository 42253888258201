import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../utils";
export const ActiveCardStyles = makeStyles((theme) => ({
    card: {
        boxShadow: "0px 3px 30px #5C86CB2E",
        borderRadius: theme.palette.borderRadius,
        // border: "3px solid white",
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        minHeight: (props) => props?.height ? props?.height : "74px",
    },
    Cardcontent: {
        padding: "12px 16px",
    },
    title: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
    },
    count: {
        fontSize: "1.25rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
    },

    image: {
        width: 46,
        height: 46,
        backgroundColor: (props) => props.data.color,
    },
    div: {
        backgroundColor: "#F5F7FA",
        borderRadius: theme.palette.borderRadius,

    }
}));