import * as React from "react"

const SettleOfflineSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 104942">
      <g data-name="Group 104943" transform="translate(-1497 -1130)">
        <rect
          data-name="Rectangle 55474"
          width={32}
          height={32}
          rx={4}
          transform="translate(1497 1130)"
          fill="#f1f7ff"
        />
        <path
          d="M1514 1138a1.112 1.112 0 0 0-1.111 1.111v6.773l1.333-.853v-4.364a1.333 1.333 0 0 0 1.333-1.333h4a1.333 1.333 0 0 0 1.333 1.333v10.666a1.333 1.333 0 0 0-1.333 1.333h-4a1.335 1.335 0 0 0-1.023-1.3l-.311.333-1.271 1.36-.04.043a1.11 1.11 0 0 0 1.09.898h7.111a1.112 1.112 0 0 0 1.111-1.111v-13.778a1.112 1.112 0 0 0-1.111-1.111H1514Zm3.556 2.667a.889.889 0 1 0 .889.889.889.889 0 0 0-.889-.889Zm-5.556 1.28-2.96.911a2.687 2.687 0 0 0-1.34.942l-3.52 4.632a.882.882 0 0 0-.18.532v4.147a.892.892 0 0 0 .889.889h4.436a3.545 3.545 0 0 0 2.6-1.129l4.573-4.9a1.425 1.425 0 0 0-1.778-2.2l-2.248 1.436-1.352.9a.6.6 0 0 1-.2.084l-.027.018.007-.009a.552.552 0 0 1-.147.017.666.666 0 0 1-.353-1.217l1.6-1.031Zm5.556 1.879a2.516 2.516 0 0 0-1.933.853 2.314 2.314 0 0 1 1.826 3.49.857.857 0 0 0 .107 0A2.321 2.321 0 0 0 1520 1146a2.321 2.321 0 0 0-2.444-2.174Zm0 5.729a.889.889 0 1 0 .889.889.889.889 0 0 0-.889-.888Z"
          fill="#78b1fe"
        />
      </g>
    </g>
  </svg>
)

export default SettleOfflineSvg
