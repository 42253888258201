import React from "react";

function CommercialInActiveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x2="1"
          y1="0.5"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#3079d6"></stop>
          <stop offset="1" stopColor="#297cd2"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x2="1"
          y1="0.5"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#1d59b3"></stop>
          <stop offset="1" stopColor="#195bbc"></stop>
        </linearGradient>
      </defs>
      <g data-name="Group 113773" transform="translate(8945 23374)">
        <g transform="translate(-8945 -23376)">
          <path
            fill="#50e6ff"
            d="M5 19h16.286v8.571H5z"
            data-name="Path 100562"
            transform="translate(-1.143 -7.429)"
          ></path>
          <path
            fill="#35c1f1"
            d="M5 39h16.286v.429a.857.857 0 01-.857.857H5.857A.857.857 0 015 39.429z"
            data-name="Path 100563"
            transform="translate(-1.143 -18.857)"
          ></path>
          <path
            fill="url(#linear-gradient)"
            d="M23 26h.857v5.571H23z"
            data-name="Path 100564"
            transform="translate(-11.429 -11.429)"
          ></path>
          <path
            fill="#0078d4"
            d="M20.429 6H5.857A.86.86 0 005 6.857v.857h16.286v-.857A.86.86 0 0020.429 6z"
            data-name="Path 100565"
            transform="translate(-1.143)"
          ></path>
          <path
            fill="#0078d4"
            d="M19.714 16H4.286L3 17.286v.429a.857.857 0 00.857.857h16.286a.857.857 0 00.857-.858v-.429z"
            data-name="Path 100566"
            transform="translate(0 -5.714)"
          ></path>
          <path
            fill="#199be2"
            d="M20.143 10H3.857L3 13.857h18z"
            data-name="Path 100567"
            transform="translate(0 -2.286)"
          ></path>
          <path
            fill="url(#linear-gradient-2)"
            d="M0 0H0.857V1.286H0z"
            data-name="Rectangle 57562"
            transform="translate(11.571 20.143)"
          ></path>
          <path
            fill="#199be2"
            d="M25 26h4.286a.429.429 0 01.429.429v5.143H25z"
            data-name="Path 100568"
            transform="translate(-12.571 -11.429)"
          ></path>
          <path
            fill="#0078d4"
            d="M0 0H4.714V1.286H0z"
            data-name="Rectangle 57563"
            transform="translate(12.429 20.143)"
          ></path>
          <path
            fill="#199be2"
            d="M12.429 26h4.286v5.571H12v-5.142a.429.429 0 01.429-.429z"
            data-name="Path 100569"
            transform="translate(-5.143 -11.429)"
          ></path>
          <path
            fill="#0078d4"
            d="M0 0H4.714V1.286H0z"
            data-name="Rectangle 57564"
            transform="translate(6.857 20.143)"
          ></path>
          <circle
            cx="0.429"
            cy="0.429"
            r="0.429"
            fill="#0078d4"
            data-name="Ellipse 129996"
            transform="translate(13.286 18)"
          ></circle>
          <circle
            cx="0.429"
            cy="0.429"
            r="0.429"
            fill="#0078d4"
            data-name="Ellipse 129997"
            transform="translate(9.857 18)"
          ></circle>
        </g>
        <path
          fill="#f2f4f7"
          d="M0 0H24V24H0z"
          data-name="Rectangle 57576"
          transform="translate(-8945 -23374)"
          style={{ mixBlendMode: "color", isolation: "isolate" }}
        ></path>
      </g>
    </svg>
  );
}

export default CommercialInActiveIcon;
