export const Section = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <path data-name="Rectangle 52494" fill="none" d="M0 0h30v30H0z" />
        <g data-name="Section">
            <path
                data-name="Path 95333"
                d="M22.061 16.306H20.45v-1.612h1.611Zm-5.757 0H14.69v-1.612h1.614Zm5.757 5.757H20.45v-1.612h1.611Zm-5.757 0H14.69v-1.612h1.614Zm-5.757 0H8.936v-1.612h1.611Zm0-5.757H8.936v-1.612h1.611Zm11.982-2.549h-.8v-2.68a.469.469 0 0 0-.469-.469h-2.432V8.469A.469.469 0 0 0 18.359 8h-5.722a.469.469 0 0 0-.469.469v2.14H9.741a.468.468 0 0 0-.468.469v2.679h-.806a.468.468 0 0 0-.468.469v2.548a.468.468 0 0 0 .468.469h2.55a.468.468 0 0 0 .468-.469v-2.548a.468.468 0 0 0-.468-.469h-.807v-2.211h1.958v.667a.469.469 0 0 0 .469.469h2.393v1.075h-.806a.469.469 0 0 0-.469.469v2.548a.469.469 0 0 0 .469.469h.806v.669H9.741a.468.468 0 0 0-.468.469v1.133h-.806a.468.468 0 0 0-.468.469v2.549a.468.468 0 0 0 .468.468h2.55a.468.468 0 0 0 .468-.469v-2.549a.468.468 0 0 0-.468-.469h-.807v-.664h4.82v.664h-.806a.469.469 0 0 0-.469.469v2.549a.469.469 0 0 0 .469.469h2.548a.469.469 0 0 0 .469-.469v-2.549a.469.469 0 0 0-.469-.469h-.8v-.664h4.818v.664h-.8a.469.469 0 0 0-.469.469v2.549a.469.469 0 0 0 .469.469h2.548a.469.469 0 0 0 .469-.469v-2.549a.469.469 0 0 0-.469-.469h-.8V18.38a.469.469 0 0 0-.469-.469h-5.302v-.668h.8a.469.469 0 0 0 .469-.469v-2.548a.469.469 0 0 0-.469-.469h-.8v-1.075h2.391a.469.469 0 0 0 .469-.469v-.667h1.963v2.211h-.8a.469.469 0 0 0-.469.469v2.548a.469.469 0 0 0 .469.469h2.548a.469.469 0 0 0 .469-.469v-2.548a.469.469 0 0 0-.469-.469Z"
                fill={props?.fill ?? "#c1c5cb"}
                fillRule="evenodd"
            />
        </g>
    </svg>
)
