import { Divider, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { StepperStyles } from './style';




export const Stepper = ({ data = [], icon = "", customTrack = false }) => {

    const classes = StepperStyles()


    return (
        <Box>
            {data?.map((x, index) => (
                <Box display={"flex"}>
                    <Box>
                        {/* circleIcon */}
                        <Box className={customTrack ? classes.applybackground : ""}>
                            <Box display="flex" alignItems={"center"}>{x?.icon}</Box>
                        </Box>
                        {/* connector */}
                        {index !== data.length - 1 &&
                            <div className={customTrack ? `${classes.customconnector}` : `${classes.connector}`}></div>
                        }
                    </Box>
                    <Box className={`${classes.detail}`}>
                        {/* name */}
                        <Typography className={`${classes.Name}`}>{x?.name}</Typography>
                        {x.dateRange && 
                        <Typography className={`${classes.Name}`}>&#x202a;{x?.dateRange}&#x202c;</Typography>                        
                        }
                        {x?.agreement_no&&
                        <Typography className={`${classes.description}`}>{`${x?.agreement_no},${x?.contact_name}`}</Typography>
                        }
                        {x?.notes?.length > 0 &&
                            <Typography className={classes.notes}>
                                Notes : {x?.notes}
                            </Typography>
                        }
                        {/* description */}
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={1}
                            mt={0.5}
                        >
                            <Typography className={`${classes.description}`}>{x.date}</Typography>
                            <Typography className={`${classes.description}`}>{x.created_by}</Typography>

                        </Stack>
                    </Box>

                </Box>
            ))}
        </Box>
    );
}
