import { Box, Button, Grid, Typography,ButtonGroup } from "@mui/material";
import React from "react";
import LocaitonIMG from "../../../assets/location";
import NoDataFound from "../../../assets/noData";
import { CustomMap } from "../../../components";
import { locationdata } from "../../../utils";
import { nearByStyles } from "./style";

export const NearBy = ({ list = [] ,onChange=()=>false, t = () => false}) => {
    const classes = nearByStyles();
    const [selected, setSelected] = React.useState({
        list: [],
        bool: false,
        select: {},
        kms: 5
    });

    React.useEffect(() => {

        const edited = list?.map((val) => {
            return {
                address: {
                    latitude: Number(val?.geometry?.location?.lat),
                    longitude: Number(val?.geometry?.location?.lng),
                },
                total_units: val?.name?.slice(0, 1),
                name: val?.name,
                icon: val?.icon,
                vicinity: val?.vicinity
            }
        })

        setTimeout(() => {
            setSelected({
                list: edited,
                bool: true,
                select: edited?.[0],
                kms: 5
            })
        }, 1000)

    }, [list])


    const changeState = (key, value) => {
        setSelected({
            ...selected,
            [key]: value
        })
    }


    const changeKms = (ms) => {
        setSelected({
            ...selected,
            kms: ms
        })
    }

    return (
        <Box p={2} justifyContent="center">
            {list?.length > 0 ?
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        {
                            selected?.bool &&
                            <CustomMap
                                changeKms={changeKms}
                                lat={
                                    Number(selected?.select?.address?.latitude)
                                }
                                lang={
                                    Number(selected?.select?.address?.longitude)

                                }
                                handleChange={(val) => changeState("select", val)}
                                noDetails={true}
                                isLoc={true}
                                // hideControl={true}
                                radius={selected?.kms}
                                list={selected?.list}
                                // zoom={20}
                                is_zoom={true}
                            />
                        }


                    </Grid>
                    <Grid item xs={6}>

                      <center>
                        <ButtonGroup variant="contained" >
                            {
                                locationdata?.map((val) => {
                                    return(
                                        <Button onClick={()=>onChange(val)}>{t(val?.name)}</Button>
                                    )
                                })
                            }
                        </ButtonGroup>

                        </center>
                        <Box height="450px" overflow="scroll">

                            {selected?.list?.map((val) => {
                                return (
                                    <Box onClick={() => changeState("select", val)} display="flex" className={classes.locRoot} padding={2} alignItems="center">
                                        <Box marginTop="2px">
                                            <LocaitonIMG color={selected?.select?.name === val?.name ? "#1F74EA" : null} />
                                        </Box>
                                        <Box flexGrow={1} marginLeft="4px" >
                                            <Typography className={classes.locName}>{val?.name}</Typography>

                                        </Box>

                                    </Box>
                                )
                            })


                            }
                        </Box>

                    </Grid>
                </Grid> :
                <Box height="450px">
                    <center>
                        <NoDataFound />
                    </center>
                </Box>

            }

        </Box>
    )
}