import React from "react";
import { withNavBars } from "../../HOCs";
import Accounts from '../accounts/account'

class CashBankAccountListParent extends React.Component {
    render() {
        return <Accounts {...this.props} type="cashBank" subtitle="Cash and Bank Account" />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CashBankAccountListParent, props);
