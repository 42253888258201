import React from "react";
import { FullImageCard } from "./fullImageCard";

class FullImageCardParent extends React.Component {
  render() {
    return <FullImageCard />;
  }
}

export default FullImageCardParent;
