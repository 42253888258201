import * as React from "react"

const TaskSVG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={25} {...props}>
  <path
    d="M7.813 0a2.82 2.82 0 0 0-2.78 2.5h-2.22A2.816 2.816 0 0 0 0 5.313v16.874A2.816 2.816 0 0 0 2.813 25h14.375A2.816 2.816 0 0 0 20 22.188V5.313A2.816 2.816 0 0 0 17.188 2.5h-2.22a2.82 2.82 0 0 0-2.78-2.5H7.813zm0 1.875h4.375a.938.938 0 0 1 0 1.875H7.813a.938.938 0 0 1 0-1.875zm5 7.521a2.171 2.171 0 0 1 1.546 3.713l-5.3 5.302a.623.623 0 0 1-.284.164l-2.991.782a.627.627 0 0 1-.764-.764l.782-2.99a.627.627 0 0 1 .164-.283l5.302-5.302a2.184 2.184 0 0 1 1.545-.622z"
    fill="#78B1FE"
  />
</svg>
)

export default TaskSVG
