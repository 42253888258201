import * as React from "react"

export const Countinity = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={19.739} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 53779"
          transform="translate(0 -.13)"
          fill={props?.color ?? "#c1c5cb"}
          d="M0 0h20v19.739H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 105474">
      <g
        data-name="Group 102047"
        transform="translate(0 .131)"
        clipPath="url(#a)"
      >
        <path
          d="M5.109-.131A2.194 2.194 0 0 0 3 2.119v15.5a2.194 2.194 0 0 0 2.109 2.25h10.782A2.194 2.194 0 0 0 18 17.619v-10.5a.776.776 0 0 0-.206-.53l-.007-.008L11.7.089a.682.682 0 0 0-.5-.22Zm6.8 2.561L15.6 6.369h-2.99a.717.717 0 0 1-.7-.75ZM10.5 9.869a.584.584 0 0 1 .38.141l2.2 1.847a.669.669 0 0 1 .233.515v2.797a.171.171 0 0 1-.176.188H7.862a.171.171 0 0 1-.176-.188v-2.8a.671.671 0 0 1 .234-.516l2.2-1.847a.587.587 0 0 1 .38-.137Zm-.469 2.5a.485.485 0 0 0-.469.5v1a.485.485 0 0 0 .469.5h.937a.485.485 0 0 0 .469-.5v-1a.485.485 0 0 0-.469-.5Z"
          fill={props?.color ?? "#c1c5cb"}
        />
      </g>
    </g>
  </svg>
)
