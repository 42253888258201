export const ResourceListHeading = ({ t = () => false }) => [
    { title: t("Image"), field: "image" },
    { title: t("Name"), field: "name" },
    { title: t("Job/Role"), field: "job_role" },
];

export const ResourceListPath = ["image", "name", "email", "mobile", "job_role"];

export const ResourceListDataType = [
    { type: ["avatar_1"], name: "image", },
    { type: ["text"], name: "name", },
    { type: ["text"], name: "job_role", },
];

export const OngoingJobsListHeading = ({ t = () => false }) => [
    { title: t("Job ID"), field: "job_id" },
    { title: t("Job Type"), field: "job_type" },
    { title: t("Job Date"), field: "job_date" },
    { title: t("KPI"), field: "kpi" },
    { title: t("Job Status"), field: "job_status" },
];

export const OngoingJobsListPath = ["resource_name", "job_id", "job_type", "job_date", "kpi", "job_status"];

export const OngoingJobsListDataType = [
    { type: ["text"], name: "job_id", },
    { type: ["text"], name: "job_type", },
    { type: ["date"], name: "job_date", },
    { type: ["text"], name: "kpi", },
    { type: ["text"], name: "job_status", },
];

export const ResourceJobsListHeading = ({ t = () => false }) => [
    { title: t("Resource Name"), field: "resource_name" },
    { title: t("Total Jobs"), field: "total_jobs" },
    { title: t("Assigned"), field: "assigned" },
    { title: t("Ongoing"), field: "ongoing" },
    { title: t("Completed"), field: "completed" },
];

export const ResourceJobsListPath = ["resource_name", "total_jobs", "assigned", "ongoing", "completed"];

export const ResourceJobsListDataType = [
    { type: ["text"], name: "resource_name", },
    { type: ["text"], name: "total_jobs", },
    { type: ["text"], name: "assigned", },
    { type: ["text"], name: "ongoing", },
    { type: ["text"], name: "completed", },
];

export const CompletedJobsListHeading = ({ t = () => false }) => [
    { title: t("Resource Name"), field: "resource_name" },
    { title: t("Jobs"), field: "jobs" },
    { title: t("Projected"), field: "projected" },
    { title: t("Actual"), field: "actual" },
    { title: t("Others"), field: "others" },
    { title: t("Ontime %"), field: "ontime" },
    { title: t("Delayed %"), field: "delayed" },
];

export const CompletedJobsListPath = ["resource_name", "jobs", "projected", "actual", "others", "ontime", "delayed"];

export const CompletedJobsListDataType = [
    { type: ["text"], name: "resource_name", },
    { type: ["text"], name: "jobs", },
    { type: ["text"], name: "projected", },
    { type: ["text"], name: "actual", },
    { type: ["text"], name: "others", },
    { type: ["text"], name: "ontime", },
    { type: ["text"], name: "delayed", },
];