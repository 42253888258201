import * as React from "react"

export const TerminationRequest = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={19.739} {...props}>
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 55500" fill="none" d="M0 0h20v19.739H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 105702">
      <g data-name="Group 105372" clipPath="url(#a)">
        <path
          data-name="icons8-cancel-subscription (2)"
          d="M2.884 1.87A1.887 1.887 0 0 0 1 3.754v10.465a2.726 2.726 0 0 0 2.721 2.721h6.091a5.43 5.43 0 0 1 .661-6.7h-.892a.628.628 0 0 1 0-1.256H12.3a5.462 5.462 0 0 1 3.353-.272V3.754a1.887 1.887 0 0 0-1.886-1.884Zm14.023 3.37v3.943a5.407 5.407 0 0 1 1.674 1.351V7.103a1.88 1.88 0 0 0-1.674-1.863Zm-12.767.4h8.372a.628.628 0 0 1 0 1.256H4.14a.628.628 0 0 1 0-1.256Zm0 3.349h3.348a.628.628 0 1 1 0 1.256H4.14a.628.628 0 0 1 0-1.256Zm10.256.419a4.6 4.6 0 1 0 4.6 4.6A4.61 4.61 0 0 0 14.4 9.405Zm0 1.256a3.326 3.326 0 0 1 1.88.58l-4.649 4.646a3.346 3.346 0 0 1 2.773-5.229Zm-10.256.834h3.348a.628.628 0 1 1 0 1.256H4.14a.628.628 0 1 1 0-1.256Zm13.024.631a3.346 3.346 0 0 1-4.649 4.649Z"
          fill="#ff4b4b"
          stroke="rgba(0,0,0,0)"
        />
      </g>
    </g>
  </svg>
)
