import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const InspectionAssigneeStyles = makeStyles((theme) => ({
    card: {
        padding: "24px",
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
        borderRadius: "4px"
    },
    title: {
        fontFamily: Bold,
        color: theme.typography.color.secondary,
        textAlign: "center",
        marginTop: "4px"
    },
    iconBtn: {
        backgroundColor: theme.palette.background.secondary,
        fontSize:"0.75rem",
        marginTop: "4px"
    },
    accountListRoot: {
        height: "200px",
        overflow: "auto",
        boxShadow: "0px 0px 16px #00000014",
        marginTop: "10px"
    },
    btn: {
        padding: "8px 12px",
        boxShadow: "none"
    },

    contactList: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        backgroundColor: "white",
        borderRadius: "4px",
        zIndex: "1",
        boxShadow: "0px 0px 16px #00000014",
        minHeight: "60px",
        overflow: "auto"
    },
    selectedList: {
        backgroundColor: "#F5F7FA",
        borderRadius: "4px",
        marginTop: "12px",
        padding: "8px"
    },
    tag1: {
        color: "#FF4B4B",
        backgroundColor: "#FFECEC",
        fontSize:"0.75rem",
        fontFamily: Bold,
        padding: "2px 10px",
        borderRadius: "2px",
        display: "inline-block",
    },
    dot: {
        borderRadius: "50%",
        width: "6px",
        height: "6px",
        background: "#CED3DD",
    },
    name: {
        color: theme.typography.color.primary,
        fontFamily: Bold,
        fontSize:"0.875rem",
        borderRadius: "4px"
    },
    email: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        borderRadius: "4px"
    },
}));