import * as React from "react"

export const NoInvoice = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 54950"
                    fill="#51a7e0"
                    d="M0 0h18.172v20H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 98668" transform="translate(-541 -84)">
            <rect
                data-name="Rectangle 49614"
                width={50}
                height={50}
                rx={25}
                transform="translate(541 84)"
                fill="#c7e9ff"
            />
            <g
                data-name="Group 103175"
                transform="translate(557 99.001)"
                clipPath="url(#a)"
                fill="#51a7e0"
            >
                <path
                    data-name="Path 97505"
                    d="M7.523 10.513h4.59a.686.686 0 1 0 .02-1.371H7.542a.686.686 0 1 0-.019 1.371"
                />
                <path
                    data-name="Path 97506"
                    d="M7.542 5.028h4.571a.686.686 0 0 0 .019-1.371h-4.59a.686.686 0 1 0-.019 1.371h.019"
                />
                <path
                    data-name="Path 97507"
                    d="M7.523 7.77h4.59a.686.686 0 1 0 .02-1.371H7.542a.686.686 0 1 0-.019 1.371"
                />
                <path
                    data-name="Path 97508"
                    d="M5.257 9.142a.686.686 0 1 0 .686.686.686.686 0 0 0-.686-.686"
                />
                <path
                    data-name="Path 97509"
                    d="M5.257 3.656a.686.686 0 1 0 .686.686.686.686 0 0 0-.686-.686"
                />
                <path
                    data-name="Path 97510"
                    d="M7.8 16H2.971a1.59 1.59 0 0 1-1.6-1.6v-.23h7.707l1.371-1.37H2.743V2.057a.676.676 0 0 1 .686-.686h10.512a.675.675 0 0 1 .686.686V9.6H16V2.057A2.067 2.067 0 0 0 13.941 0H3.428a2.067 2.067 0 0 0-2.057 2.057V12.8H.686a.686.686 0 0 0-.686.684v.916a2.982 2.982 0 0 0 2.971 2.971h5.092A2.049 2.049 0 0 1 7.8 16"
                />
                <path
                    data-name="Path 97511"
                    d="M5.257 6.399a.686.686 0 1 0 .686.686.686.686 0 0 0-.686-.686"
                />
                <path
                    data-name="Path 97512"
                    d="M14.5 10.513a1.134 1.134 0 0 0-.806.333l-4.676 4.676a1.14 1.14 0 0 0 0 1.612l2.533 2.533a1.14 1.14 0 0 0 1.612 0l4.676-4.676a1.134 1.134 0 0 0 .333-.806V11.4a.887.887 0 0 0-.887-.887Zm1.3 1.725a.65.65 0 1 1-.65.65.649.649 0 0 1 .65-.65Z"
                />
            </g>
        </g>
    </svg>
)


