import React from 'react'
import { Stack, IconButton, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CalendarDateChangeStyle } from './style';
import { timeZoneConverter } from '../../utils'

export const CalendarDateChange = ({ state = {}, prev = () => false, next = () => false, comp = {}, noArrow = false }) => {
    const classes = CalendarDateChangeStyle()
    return (
        <Stack sx={{ direction: "ltr" }} direction={"row"} alignItems={"center"} >
            {!noArrow &&
                <IconButton onClick={prev} className={classes.button}>
                    <ArrowBackIosIcon sx={{ position: "relative", left: "3px"}} className={classes.button_icon} />
                </IconButton>
            }
            {
                comp === "month" ?
                    <Typography className={classes.dateLabel}> {timeZoneConverter(state?.Date ?? state, "MMMM YYYY")} </Typography>
                    :
                    comp === "daily" ?
                        <Typography className={classes.dateLabel}> {timeZoneConverter(state?.Date ?? state, "DD MMM YYYY")} </Typography>
                        :
                        <Typography className={classes.dateLabel}> {timeZoneConverter(state.startDate, "DD MMM")} - {timeZoneConverter(state.endDate, "DD MMM")} </Typography>
            }
            {!noArrow &&

                <IconButton onClick={next} className={classes.button}>
                    <ArrowForwardIosIcon className={classes.button_icon} />
                </IconButton>
            }
        </Stack>
    )
}