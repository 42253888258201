import React from "react"

export const PaymentCollectedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_118506" data-name="Group 118506" transform="translate(-197 -106)">
                <g id="Rectangle_52035" data-name="Rectangle 52035" transform="translate(197 106)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-payment" d="M24.564,13.821a6.786,6.786,0,0,0-2.48,1.046c-1.082.562-2.082,1.1-2.717,1.485a3.263,3.263,0,0,1-3.185,4.015H12.091a.818.818,0,1,1,0-1.636h4.091a1.63,1.63,0,0,0,1.075-2.861,2.434,2.434,0,0,0-1.347-.412H13.182c-.016,0-.031,0-.046,0a11.9,11.9,0,0,1-2.735-.3,7.966,7.966,0,0,0-1.949-.236c-4,0-6.283,4.91-6.377,5.119a.818.818,0,0,0,.086.825l3.818,5.179a.814.814,0,0,0,.657.33.821.821,0,0,0,.794-.611,2.055,2.055,0,0,1,1.935-1.568,26.567,26.567,0,0,1,3.231.27,26.9,26.9,0,0,0,3.314.275c1.507,0,2.592-1.156,5.727-4.364C25.619,16.291,26,15.935,26,15.127a1.372,1.372,0,0,0-1.436-1.306ZM21.091,6.727a2.735,2.735,0,0,1-1.745,2.547,4.651,4.651,0,0,0-3.529-3.529,2.729,2.729,0,0,1,5.275.982Zm-6.273,0a3.545,3.545,0,1,0,3.545,3.545,3.545,3.545,0,0,0-3.545-3.545Z" transform="translate(195 102.818)" fill="#5ac782" />
            </g>
        </svg>

    )
}