export const Templates = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98475">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 95033"
                    d="M22.742 8h-6.207a.278.278 0 0 0-.259.259v3.362h2.328a.762.762 0 0 1 .772.779v2.324h3.362a.278.278 0 0 0 .259-.259V8.259A.278.278 0 0 0 22.742 8Zm-2.066 1.034h-2.072a.259.259 0 1 1 0-.517h2.072a.259.259 0 1 1 0 .517Zm1.552 0h-.517a.259.259 0 1 1 0-.517h.517a.259.259 0 1 1 0 .517Z"
                />
                <path
                    data-name="Path 95034"
                    d="M18.604 12.138h-6.207a.278.278 0 0 0-.259.259v3.362h2.328a.762.762 0 0 1 .776.776v2.328h3.362a.278.278 0 0 0 .259-.259v-6.207a.278.278 0 0 0-.259-.259Z"
                />
                <path
                    data-name="Path 95035"
                    d="M14.466 16.276H8.259a.278.278 0 0 0-.259.259v6.207a.278.278 0 0 0 .259.259h6.207a.278.278 0 0 0 .259-.259v-6.207a.278.278 0 0 0-.259-.259Zm-2.587 6.207h-.517a.259.259 0 0 1 0-.517h.517a.259.259 0 0 1 0 .517Zm2.328-.75a.751.751 0 0 1-.75.75h-.543a.259.259 0 0 1 0-.517h.543a.232.232 0 0 0 .233-.233v-1.057a.259.259 0 1 1 .517 0Z"
                />
            </g>
            <path data-name="Rectangle 52164" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
