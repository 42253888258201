import * as React from "react"

export const AgreementInvoices = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18.25} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    data-name="Rectangle 53776"
                    fill={props?.color}
                    d="M0 0h14.427v18H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Request Ticket">
            <path data-name="Rectangle 53523" fill="none" d="M0 0h18v18H0z" />
            <g
                data-name="Group 102038"
                transform="translate(1.787 .25)"
                clipPath="url(#a)"
            >
                <path
                    data-name="Path 96781"
                    d="M0 9.857v-5.7a2.079 2.079 0 0 1 2.339-2.352c.225 0 .451-.017.674 0a.6.6 0 0 0 .756-.482A1.883 1.883 0 0 1 5.662.01c1-.018 2-.009 3 0a1.925 1.925 0 0 1 1.993 1.387.516.516 0 0 0 .653.411c2.107-.222 3.2.5 3.117 3.18-.109 3.544-.024 7.094-.028 10.642A2.094 2.094 0 0 1 12 17.999H2.4A2.093 2.093 0 0 1 0 15.628V9.857"
                    fill={props?.color}
                />
                <path
                    data-name="Path 96782"
                    d="M10.597 7.878c-.461-.474-.843-.147-1.2.214-1 1.009-2.015 2-3 3.021-.245.253-.389.279-.631 0a14.684 14.684 0 0 0-1.061-1.05.589.589 0 0 0-.694-.1.654.654 0 0 0-.4.584c-.02.448 2.062 2.52 2.675 2.486.1-.009.28-.184.456-.36l3.647-3.647c.342-.34.639-.714.215-1.151"
                    fill="#fdfdfd"
                />
                <path
                    data-name="Path 96783"
                    d="M5.743 2.705h2.911c.469 0 .787-.2.781-.694-.006-.448-.306-.654-.743-.655H5.707c-.437 0-.736.206-.743.655-.008.492.311.7.779.694"
                    fill="#fdfdfd"
                />
            </g>
        </g>
    </svg>
)


