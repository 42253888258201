import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
  fileroot: {
    width: "100%",
    fontSize:"0.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "9px",
    backgroundColor: "#E4EFFF",
    height: "188px",
  },
  imgContainer: {
    width: "100%",
    maxWidth: "188px",
    margin: "0px 10px",
    borderRadius: "12px",
    display: "flex",
    height: "188px",
  },
  delete: {
    color: "red",
    position: "absolute",
    top: "4px",
    right: "8px",
    backgroundColor: "white",
  },
  uploadTittle: {
    fontSize:"0.75rem",
    color: "#5078E1",
    fontFamily: SemiBold,
  },
  uploadcount: {
    fontSize:"1rem",
    color: "#404E61",
    fontFamily: SemiBold,
    paddingLeft: "16px",
  },
  imagesParent: {
    padding: '0px !important'
  },
  allocatedroot: {
    border: "1px solid #E4E8EE",
    marginTop: "8px",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#fff",
  },
  profileTilte: {
    color: "#091B29",
    fontSize:"1rem",
    fontFamily: Bold,
    [theme.breakpoints.down("md")]: {
      maxWidth:"200px"
    }
  },
  leadcontact: {
    color: "#4E5A6B",
    fontSize:"0.75rem",
    fontFamily: Regular,
    display: "flex",
    alignItems: "center",
  },
  leadcontactNo: {
    color: "#4E5A6B",
    fontSize:"0.75rem",
    fontFamily: Regular,
    display: "flex",
    alignItems: "center",
    direction:"ltr"
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  imagleBox:{
    display:"flex",
    alignItems:"center",
    overflow:"auto", 
  }
}));
