import React from "react";

export const ResidentialIcon = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_243_2374)">
      <circle cx="25.3999" cy="21.9191" r="21" fill="#62955A" />
    </g>
    <g clip-path="url(#clip0_243_2374)">
      <path
        d="M27.2179 33.9995L28.3088 33.9996C28.3093 33.9996 28.3098 33.9995 28.3102 33.9995H34.127C34.7295 33.9995 35.2179 33.5111 35.2179 32.9086V24.545H15.5815V32.9086C15.5815 33.5111 16.07 33.9995 16.6725 33.9995H22.4892C22.4897 33.9995 22.4902 33.9996 22.4906 33.9996H23.5815V30.9993V27.4541H27.2179V30.9993V33.9995Z"
        fill="white"
      />
      <path
        d="M36.309 22.3633C36.6491 22.3633 36.9696 22.2047 37.1759 21.9345C37.3823 21.6643 37.4509 21.3134 37.3615 20.9853L35.1797 12.9853C35.0503 12.5107 34.6191 12.1814 34.1272 12.1814H29.3999V11.0914C29.3999 10.4889 28.9115 10.0005 28.309 10.0005C27.7066 10.0005 27.2181 10.4889 27.2181 11.0914V12.1814H16.6727C16.1807 12.1814 15.7497 12.5106 15.6202 12.9852L13.4383 20.9852C13.3489 21.3132 13.4175 21.6642 13.6239 21.9345C13.8303 22.2046 14.1508 22.3632 14.4908 22.3632H15.5818H35.2181H36.309V22.3633Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_243_2374"
        x="0.399902"
        y="0.919067"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_243_2374"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_243_2374"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_243_2374">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(13.3999 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
