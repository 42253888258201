export const reqHeading = (t) => [
    { title: t("inspectionOrderId"), field: "reference_id" },
    { title: t("agreementId"), field: "agreement_no" },
    { title: t("requestedOn"), field: "created_at" },
    { title: t("requested_by"), field: "requested_by" },
    { title: t("jobs"), field: "no_of_units", },
    { title: t("status"), field: "status" },
    { title: t("completionDate"), field: "completed_on" },

]

export const reqType = [
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "agreement_no" },
    { type: ["date"], name: "created_at" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "no_of_units" },
    { type: ["agreement_inspection_status"], name: "status" },
    { type: ["date"], name: "completed_on" },
]
export const reqTypePath = ["reference_id",
    "property_name", "no_of_units", "created_at", "agreement_manager", "inspection_manager", "status", "completed_on"]

export const StatusOptionList = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Completed', value: 'Completed' },
]