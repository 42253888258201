import { Box } from "@mui/material";
import React from "react"
import { Editor } from "../../../components";


const ScopeOfWork = ({ t=()=>false,updateState = () => false, value = "" }) => {

    return (
        <Box p={"16px 22px 16px 22px"} height={350}>
            <Editor
                label={t("Scope Of Work")}
                handleChange={(e) => {
                    updateState("scopeOfWork",e);
                }}
                value={value}
                height={"150px"}
            />
        </Box>
    )
}
export default ScopeOfWork