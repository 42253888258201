import * as React from "react"

const RightCheveronIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={8.046}
        height={14.522}
        {...props}
    >
        <path
            data-name="icons8-expand-arrow (2)"
            d="M.25.772a.5.5 0 0 0 .154.349l6.141 6.14-6.141 6.142a.5.5 0 1 0 .706.707l6.494-6.495a.5.5 0 0 0 0-.706L1.11.415a.5.5 0 0 0-.86.357Z"
            fill={props?.fill ?? "#c1c5cb"}
            stroke={props?.fill ?? "#c1c5cb"}
            strokeWidth={0.5}
        />
    </svg>
)

export default RightCheveronIcon
