import * as React from "react"

const RequestDeatilsIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 104832" transform="translate(-344.84 -312.547)">
            <rect
                data-name="Rectangle 55272"
                width={40}
                height={40}
                rx={4}
                transform="translate(344.84 312.547)"
                fill="rgba(237,228,254,0.5)"
            />
            <g data-name="Group 104810">
                <path
                    data-name="Rectangle 55278"
                    fill="none"
                    d="M352.84 320.545h24v24h-24z"
                />
                <path
                    data-name="icons8-cancel-subscription (3)"
                    d="M357.724 323.545a1.961 1.961 0 0 0-1.884 2.025v11.25a2.834 2.834 0 0 0 2.721 2.925h6.091a6.117 6.117 0 0 1-.858-3.15 6.043 6.043 0 0 1 1.519-4.05h-.892a.677.677 0 0 1 0-1.35h2.719a5.122 5.122 0 0 1 2.1-.45 5.041 5.041 0 0 1 1.256.157v-5.332a1.961 1.961 0 0 0-1.889-2.025Zm14.023 3.623v4.239a5.5 5.5 0 0 1 1.674 1.453v-3.69a1.973 1.973 0 0 0-1.674-2.002Zm-12.767.427h8.372a.677.677 0 0 1 0 1.35h-8.372a.677.677 0 0 1 0-1.35Zm0 3.6h3.348a.677.677 0 0 1 0 1.35h-3.348a.677.677 0 0 1 0-1.35Zm10.26.45a4.792 4.792 0 0 0-4.6 4.95 4.792 4.792 0 0 0 4.6 4.95 4.792 4.792 0 0 0 4.6-4.95 4.792 4.792 0 0 0-4.6-4.95Zm0 1.35a3.167 3.167 0 0 1 1.88.624l-4.649 5a3.766 3.766 0 0 1-.58-2.021 3.485 3.485 0 0 1 3.349-3.603Zm-10.256.9h3.344a.677.677 0 0 1 0 1.35h-3.348a.677.677 0 0 1 0-1.35Zm13.024.679a3.766 3.766 0 0 1 .58 2.021 3.485 3.485 0 0 1-3.349 3.6 3.167 3.167 0 0 1-1.88-.624Z"
                    fill="#a68adb"
                />
            </g>
        </g>
    </svg>
)

export default RequestDeatilsIcon
