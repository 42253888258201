import * as React from "react"
const NoTermsAndCondition = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={179.098}
    height={182.952}
    {...props}
  >
    <g data-name="Group 115782">
      <g data-name="Group 115781" transform="translate(-611.228 -252.04)">
        <rect
          width={128.098}
          height={148.952}
          fill="#f2f4f7"
          data-name="Rectangle 57725"
          rx={12}
          transform="translate(662.228 286.04)"
        />
        <path
          fill="#adb5bd"
          d="M776.615 309.618H703.07a2.312 2.312 0 0 0 0 4.469h73.545a2.312 2.312 0 0 0 0-4.469Z"
          data-name="Path 100659"
        />
        <path
          fill="#adb5bd"
          d="M776.098 321.684h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100660"
        />
        <path
          fill="#adb5bd"
          d="M776.098 334.226h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100661"
        />
        <path
          fill="#adb5bd"
          d="M776.098 346.291h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100662"
        />
        <path
          fill="#adb5bd"
          d="M776.098 358.834h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100663"
        />
        <path
          fill="#adb5bd"
          d="M776.098 370.896h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100664"
        />
        <path
          fill="#adb5bd"
          d="M776.098 383.438h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100665"
        />
        <path
          fill="#adb5bd"
          d="M776.098 395.505h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100666"
        />
        <path
          fill="#adb5bd"
          d="M776.098 408.048h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100667"
        />
        <path
          fill="#adb5bd"
          d="M776.098 420.113h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100668"
        />
        <rect
          width={23.707}
          height={21.317}
          fill="#6c757d"
          data-name="Rectangle 57726"
          rx={1.128}
          transform="translate(674.221 296.449)"
        />
      </g>
      <g data-name="Group 115779" transform="translate(-496.456 -267.04)">
        <rect
          width={128.098}
          height={148.952}
          fill="#eaedf2"
          data-name="Rectangle 57725"
          rx={12}
          transform="translate(521.228 286.04)"
        />
        <rect
          width={103.707}
          height={21.317}
          fill="#dee2e6"
          data-name="Rectangle 57726"
          rx={1.128}
          transform="translate(533.221 298)"
        />
        <rect
          width={103.707}
          height={21.317}
          fill="#dee2e6"
          data-name="Rectangle 58101"
          rx={1.128}
          transform="translate(533.221 333)"
        />
        <rect
          width={103.707}
          height={21.317}
          fill="#dee2e6"
          data-name="Rectangle 58102"
          rx={1.128}
          transform="translate(533.221 367)"
        />
        <rect
          width={103.707}
          height={21.317}
          fill="#dee2e6"
          data-name="Rectangle 58103"
          rx={1.128}
          transform="translate(533.221 402)"
        />
      </g>
      <g data-name="Group 115780" transform="translate(-662.228 -286.04)">
        <rect
          width={128.098}
          height={148.952}
          fill="#f2f4f7"
          data-name="Rectangle 57725"
          rx={12}
          transform="translate(662.228 286.04)"
        />
        <path
          fill="#adb5bd"
          d="M776.615 309.618H703.07a2.312 2.312 0 0 0 0 4.469h73.545a2.312 2.312 0 0 0 0-4.469Z"
          data-name="Path 100659"
        />
        <path
          fill="#adb5bd"
          d="M776.098 321.684h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100660"
        />
        <path
          fill="#adb5bd"
          d="M776.098 334.226h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100661"
        />
        <path
          fill="#adb5bd"
          d="M776.098 346.291h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100662"
        />
        <path
          fill="#adb5bd"
          d="M776.098 358.834h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100663"
        />
        <path
          fill="#adb5bd"
          d="M776.098 370.896h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100664"
        />
        <path
          fill="#adb5bd"
          d="M776.098 383.438h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100665"
        />
        <path
          fill="#adb5bd"
          d="M776.098 395.505h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100666"
        />
        <path
          fill="#adb5bd"
          d="M776.098 408.048h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100667"
        />
        <path
          fill="#adb5bd"
          d="M776.098 420.113h-99.643a2.235 2.235 0 0 0 0 4.469h99.643a2.235 2.235 0 0 0 0-4.469Z"
          data-name="Path 100668"
        />
        <rect
          width={23.707}
          height={21.317}
          fill="#6c757d"
          data-name="Rectangle 57726"
          rx={1.128}
          transform="translate(674.221 296.449)"
        />
      </g>
    </g>
  </svg>
)
export default NoTermsAndCondition
