import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold, remCalc } from "../../../utils";
export const useStyles = makeStyles((theme) => ({

    image: {
        height: "120px",
        width: "120px",
        objectFit: "fill",
        borderRadius: "50%",
        fontSize:remCalc(20),
    },
    priority: {
        backgroundColor: "#071741",
        color: "white",
        padding: "2px 12px",
        fontSize:"0.75rem",
        borderRadius: "2px",
        display: "inline",
    },
    content: {
        borderLeft: "1px solid #E4E8EE",
        padding: "0px 20px",
        marginLeft: "8px"
    },
    title: {
        color: "#4E5A6B",
        fontSize:"0.75rem",
        fontFamily: Bold
    },
    header: {
        color: "#98A0AC",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        marginTop: "12px",
    },
    subHeader: {
        color: "#091B29",
        marginTop: "8px",
        fontSize:"0.75rem",
        fontFamily: SemiBold
    },
    subHeaderNo: {
        color: "#091B29",
        marginTop: "8px",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        direction:"ltr",
        textAlign:"right"
    },
    borderRoot: {
        border: "1px solid #E4E8EE",
        padding: "12px",
        borderRadius: "4px",
        height: "100px",
        overflow: "auto"
    },
    requirement: {
        color: "#98A0AC",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        marginTop: "4px"
    },
    requirementDetails: {
        color: "#091B29",
        fontSize:"0.75rem",
        fontFamily: Bold
    },
    notes: {
        color: "#4E5A6B",
        fontSize:"0.875rem",
        marginTop: "8px"

    },
    borderRoot1: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        padding: "12px"

    },
    header1: {
        color: "#98A0AC",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
    },
    fab: {
        position: "fixed",
        bottom: 129,
        right: 30,
        display: "flex",
        alignItems: "center",
        background: '#5078E1',
        boxShadow: "none",
        "&:hover": {
            background: '#5078E1',
        },
        [theme.breakpoints.down("sm")]: {
            bottom: 70,
            right: 14,
        },
    },
    fabArabic: {
        position: "fixed",
        bottom: 129,
        left: 30,
        display: "flex",
        alignItems: "center",
        background: '#5078E1',
        boxShadow: "none",
        "&:hover": {
            background: '#5078E1',
        },
        [theme.breakpoints.down("sm")]: {
            bottom: 70,
            right: 14,
        },
    },

    sumbitidea: {
        color: "#fff",
        marginLeft: "10px",
        fontSize:"0.875rem",
    },
    borderRootChart: {
        border: "1px solid #E4E8EE",
        padding: "12px",
        borderRadius: "4px",
        overflow: "auto",
        // display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position:'relative',
    },
    count:{
        color:"#4E5A6B",
        fontFamily:SemiBold,
        fontSize:"1rem"
    },
    name:{
        color:"#98A0AC",
        fontFamily:SemiBold,
        fontSize:"0.75rem"
    },
    dot:{
        padding:"4px",
        borderRadius:"50%"
    },
    legends:{
        marginTop:"16px",
        overflow:"auto",

        "&::-webkit-scrollbar": {
            width: "0px !important",
            display:"none"
        },
    },
    total:{
        color:"#4E5A6B",
        fontFamily:SemiBold,
        fontSize:"0.75rem"
    },
    graphTitle:{
        color:"#4E5A6B",
        fontFamily:Bold,
        fontSize:"0.75rem" ,
        marginBottom:"28px"
    }
}));