import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Divider, Grid, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import CalendarIcon from '../../assets/calendar';
import ClockArrow from '../../assets/clockArrow';
import ClockIcon from '../../assets/clockIcon';
import { Male } from "../../assets/male";
import StarIcon from '../../assets/starIcon';
import { AssignModalContext } from '../../contexts/assignModalContext';
import { CustomSelectOptions } from "../customOptions";
import { DateTimePicker } from '../dateTimePicker';
import { CustomSelect } from '../filterGenerator/components';
import { TextBox } from '../textbox';
import AddOtherResources from './addOtherResources';
import { PopupCard } from './card';
import { AssignCompenentStyles } from './styles';
const AssignModal = (props) => {
    const { t, disable } = props
    const classes = AssignCompenentStyles()
    const [editHours, setEditHours] = useState(false)
    const [edit, setEdit] = useState(false)
    const [isSelect, setIsSelect] = useState(false)
    const { selectedResource, setSelectedResource } = useContext(AssignModalContext)

    //To edit hours
    const onEditHours = (type) => {
        if (type === "edit") {
            setEditHours(false)
            setEdit(true)
        }
        else if (type === "check") {
            setEditHours(true)
            setEdit(false)
        }

    }
    const selectedResourceList = (data) => {
        setIsSelect(true)
        setSelectedResource([data])
    }

    return (
        <Box className={classes.main}>
            <Grid container className={classes.formRoot}>
                <Grid item xs={6} className={classes.section1} >
                    <Box className={classes.section1header}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography className={classes.modalName}>{props?.moduleName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomSelect
                                    options={props?.assignData?.companyOptions}
                                    isReadOnly={props?.isReadOnly}
                                    color="white"
                                    placeholder={t("companyName")}
                                    value={props?.assignData?.company}
                                    onChange={(value) => {
                                        props?.handleChange("company", value, true)
                                    }} />
                            </Grid>
                        </Grid>



                    </Box>
                    <Box className={classes.steps}>
                        <Typography className={classes.stepTitle}>{t("step1")}</Typography>
                    </Box>
                    <Grid container >
                        <Grid item xs={5.8} className={classes.subHeader}  >
                            <Box className={classes.datePicker}>
                                <CalendarIcon className={classes.icon} />
                                <Typography className={classes.datePickerText}>{t("startDateandTime")}</Typography>
                                <Tooltip title={t("toolTipTextForDate")} placement="top" arrow>
                                    <Box>
                                        <InfoOutlinedIcon className={classes.icon} />
                                    </Box>
                                </Tooltip>
                            </Box>
                            <Box>
                                <DateTimePicker
                                    isReadOnly={disable}
                                    handleDateChange={(value) => props?.handleChange("dateTime", value)}
                                    dateTime={props?.assignData?.dateTime}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={5.8} className={classes.subHeader} >
                            <Box className={classes.datePicker}>
                                <Box><ClockIcon className={classes.icon} /></Box>
                                <Box flexGrow={1} marginLeft={"8px"}>
                                    <Typography className={classes.datePickerText}>{t("projectedHours")}</Typography>
                                </Box>
                                <Box>
                                    <Tooltip title={t("toolTipTextForHours")} placement="top" arrow>
                                        <Box>
                                            <InfoOutlinedIcon className={classes.icon} />
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Box>
                                <TextBox label={null}
                                    placeholder=''
                                    border={0}
                                    type='number'
                                    isReadonly={disable}
                                    endAdornment={<InputAdornment position="end">{t("hr")}</InputAdornment>}
                                    color="white"
                                    onChange={(e) => props?.handleChange("hours", e.target.value, true)}
                                    value={props?.assignData?.hours}
                                    isError={props?.assignData?.error?.hours?.length > 0}
                                    errorMessage={props?.assignData?.error?.hours}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider className={classes.dividerstyle} />
                    {props?.assignData?.hours?.length > 0 &&
                        <>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box className={classes.steps}>
                                    <Typography className={classes.stepTitle}>{t("step2")}</Typography>
                                </Box>
                                <Box className={classes.step2}>
                                    <StarIcon className={classes.icon} />
                                    <Typography className={classes.PrimaryTitle}>{t("primaryResource")}</Typography>
                                </Box>

                            </Box>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box display={"flex"}>

                                    <Male />

                                    <Box ml={1.5}>
                                        <Typography className={classes.resourceTitle}>{t("selectResource")}</Typography>
                                        <Typography className={classes.resourceSubTitle}>{t("selectSpecificResource")}</Typography>
                                    </Box>
                                </Box>
                                {editHours === true ?
                                    <Box display={"flex"} className={classes.editAssignedhrs}>

                                        <ClockArrow className={classes.arrowicon} color="#5AC782" />
                                        <Typography className={classes.edithoursText}>Assigned for {props?.assignData?.hours} Hr</Typography>
                                        <EditOutlinedIcon className={classes.editIcon} onClick={() => onEditHours("edit")} />
                                    </Box>
                                    : edit === true ?
                                        <Box>
                                            <Box display={"flex"} className={classes.editAssignedhrs}>

                                                <ClockArrow className={classes.arrowicon} color="#5AC782" />
                                                <Typography className={classes.edithoursText}>Edit Hour</Typography>
                                                <OutlinedInput
                                                    size="small"
                                                    className={classes.smalltextfield}
                                                    endAdornment={<InputAdornment position="end">{t("hr")}</InputAdornment>}
                                                    onChange={(e) => props?.handleChange("hours", e.target.value, true)}
                                                    value={props?.assignData?.hours}
                                                />
                                                <CheckCircleIcon className={classes.checkIcon} onClick={() => onEditHours("check")} />
                                            </Box>
                                        </Box>
                                        :
                                        <Box className={classes.assignedhrs}>
                                            <ClockArrow className={classes.icon} />
                                            <Typography className={classes.assignText} onClick={() => setEditHours(true)}>Assigned for {props?.assignData?.hours} Hr</Typography>
                                        </Box>
                                }
                            </Box>
                            <Box className={classes.searchBlock}>
                                <Stack
                                    direction="row"
                                    divider={<Divider orientation="vertical" flexItem />}
                                >
                                    <Grid item xs={6}>
                                        <Typography className={classes.selecttitle}>{t("department")}</Typography>
                                        <CustomSelect options={props?.assignData?.departmentOptions}
                                            borderRadius={false}
                                            noBorder={true}
                                            isReadOnly={props?.isReadOnly}
                                            placeholder={"All"}
                                            label={false}
                                            value={props?.assignData?.department}
                                            onChange={(value) => {
                                                props?.handleChange("department", value, true)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.selecttitle}>{t("role")}</Typography>
                                        <CustomSelect options={props?.assignData?.roleOptions}
                                            borderRadius={false}
                                            noBorder={true}
                                            isReadOnly={props?.isReadOnly}
                                            placeholder={"All"}
                                            label={false}
                                            value={props?.assignData?.role}
                                            onChange={(value) => {
                                                props?.handleChange("role", value, true)
                                            }} />
                                    </Grid>
                                </Stack>
                                <Divider className={classes.dividerstyle1} />
                                <Box padding="8px 0px">
                                    <CustomSelectOptions
                                        padding="12px"
                                        onChange={(val) => selectedResourceList(val)}
                                        options={props?.resourceList} noBorder={true} />

                                </Box>
                            </Box>

                            <Box >
                                {isSelect === true && selectedResource.length > 0 && selectedResource?.map((item) => {
                                    return (
                                        <PopupCard
                                            list={item}
                                            setAssignData={props?.setAssignData}
                                            removeData={props?.removeData}
                                            deleteIcon={true}
                                            selected={true}
                                        />
                                    )
                                })}
                            </Box>
                        </>
                    }

                </Grid>
                <Grid item xs={6} >
                    <AddOtherResources
                        handleSearchResource={props?.handleSearchResource}
                        assignData={props?.assignData}
                        handleChange={props?.handleChange}
                        otherResourcesList={props?.otherResourcesList}
                        removeData={props?.removeData}

                    />
                </Grid>
            </Grid>
            <Box className={classes.footer} display={"flex"} justifyContent={"space-between"} p={2}>
                <Button variant="outlined" className={classes.cancelBtn} onClick={() => props?.handleClose()}>{t("close")}</Button>
                <Button variant="contained" onClick={() => props?.handleSubmit()}>{t(`${props?.buttonName}`)}</Button>
            </Box>
        </Box>
    )
}
export default withNamespaces("assignModal")(AssignModal)