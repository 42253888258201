export const InspectionManagement = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98382">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g fill={props?.fill ?? "#c1c5cb"}>
                    <path
                        data-name="Path 94771"
                        d="M7.468 23h8.482a.468.468 0 0 0 .468-.468v-.468H9.339a1.4 1.4 0 0 1-1.4-1.4V8.936h-.471A.468.468 0 0 0 7 9.403v13.133a.468.468 0 0 0 .468.468Zm0 0"
                    />
                    <path
                        data-name="Path 94772"
                        d="M14.547 10.807h2.533l-2.533-2.533Zm0 0"
                    />
                    <path
                        data-name="Path 94773"
                        d="M9.339 8a.467.467 0 0 0-.472.468v12.193a.467.467 0 0 0 .468.468h7.547a.468.468 0 0 0 .468-.468v-.6a3.685 3.685 0 0 1-.936.134 3.742 3.742 0 1 1 0-7.484 3.7 3.7 0 0 1 .936.133v-1.1h-3.271a.467.467 0 0 1-.468-.468V8Zm0 0"
                    />
                    <path
                        data-name="Path 94774"
                        d="M20.029 18.736a.468.468 0 0 0-.661 0l-.667-.667a2.82 2.82 0 1 0-.661.662l.667.667a.468.468 0 0 0 0 .662l2.663 2.663a.936.936 0 1 0 1.323-1.323Zm0 0"
                    />
                </g>
            </g>
        </g>
    </svg>
)
