import * as React from "react"
const RestoreErrorImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={510}
    height={313.623}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          fill="none"
          d="M0 0h384.5v257.322H0z"
          data-name="Rectangle 58087"
        />
      </clipPath>
    </defs>
    <g data-name="Group 115525">
      <g data-name="Group 115524">
        <g
          clipPath="url(#a)"
          data-name="Group 115523"
          transform="translate(62.5)"
        >
          <path
            fill="#f9f8f8"
            d="M381.515 195.15h-40.428a158.153 158.153 0 0 0-12.636-109.175h28.67a2.985 2.985 0 0 0 0-5.97h-31.886A157.565 157.565 0 0 0 85.613 37.87H37.1a2.985 2.985 0 0 0 0 5.97h41.929q-5.022 4.818-9.609 10.063H2.985a2.985 2.985 0 1 0 0 5.97h61.469a157.745 157.745 0 0 0-22.727 156.088h-7.866a2.986 2.986 0 0 0 0 5.971h10.4q1.629 3.632 3.434 7.165H12.367a2.986 2.986 0 0 0 0 5.971H50.9a157.756 157.756 0 0 0 12.349 18.594h249.639a157.338 157.338 0 0 0 20.9-36.164h29.3a2.986 2.986 0 0 0 0-5.971h-26.979q1.869-5.122 3.389-10.406h42.017a2.985 2.985 0 1 0 0-5.97"
            data-name="Path 100907"
          />
          <path
            fill="#f2f2f2"
            d="M254.282 253.651h-19.085a2.5 2.5 0 0 1 2.495-2.495h14.095a2.5 2.5 0 0 1 2.495 2.495"
            data-name="Path 100908"
          />
          <path
            fill="#f2f2f2"
            d="M249.757 251.154h-10.043l3.264-93.028 2.193 1.458Z"
            data-name="Path 100909"
          />
          <path
            fill="#f2f2f2"
            d="M252.861 253.651h-19.085a2.5 2.5 0 0 1 2.495-2.495h14.095a2.5 2.5 0 0 1 2.5 2.495"
            data-name="Path 100910"
          />
          <path
            fill="#f2f2f2"
            d="m243.319 158.952 48.021 27.725-.525-2.729a2.252 2.252 0 0 0-.833-1.356l-31.373-24.285a2.252 2.252 0 0 0-2.023-.377l-6.294 1.881a2.252 2.252 0 0 1-1.771-.208l-4.183-2.415Z"
            data-name="Path 100911"
          />
          <path
            fill="#f2f2f2"
            d="m242.3 157.187-48.021 27.725 2.622.909a2.266 2.266 0 0 0 1.6-.044l36.708-15.023a2.265 2.265 0 0 0 1.346-1.573l1.516-6.38a2.264 2.264 0 0 1 1.071-1.437l4.178-2.413Z"
            data-name="Path 100912"
          />
          <path
            fill="#f2f2f2"
            d="M244.336 157.187v-55.446l-2.1 1.817a2.26 2.26 0 0 0-.76 1.4l-5.344 39.307a2.258 2.258 0 0 0 .687 1.947l4.771 4.506a2.258 2.258 0 0 1 .708 1.642v4.827Z"
            data-name="Path 100913"
          />
          <path
            fill="#f2f2f2"
            d="M247.927 251.154h-9.217l3.264-93.028h2.689Z"
            data-name="Path 100914"
          />
          <path
            fill="#f2f2f2"
            d="M245.679 158.132a2.361 2.361 0 1 1-2.361-2.361 2.361 2.361 0 0 1 2.361 2.361"
            data-name="Path 100915"
          />
          <path
            fill="#f2f2f2"
            d="M245.311 157.991a2.153 2.153 0 1 1-2.153-2.153 2.153 2.153 0 0 1 2.153 2.153"
            data-name="Path 100916"
          />
          <path
            fill="#f2f2f2"
            d="m195.13 185.207 45.875-27.217-.041-.032-46.686 26.954Z"
            data-name="Path 100917"
          />
          <path
            fill="#f2f2f2"
            d="M238.731 250.552h9.196v.605h-9.196z"
            data-name="Rectangle 58084"
          />
          <path
            fill="#f2f2f2"
            d="m243.656 102.328.631 53.715.048-.02v-54.281Z"
            data-name="Path 100918"
          />
          <path
            fill="#f2f2f2"
            d="m291.17 185.792-46.509-26.12-.007.052 46.686 26.954Z"
            data-name="Path 100919"
          />
          <path
            fill="#ececec"
            d="M316.981 253.651h-30.554a4 4 0 0 1 3.995-3.994h22.565a3.994 3.994 0 0 1 3.994 3.994"
            data-name="Path 100920"
          />
          <path
            fill="#ececec"
            d="M309.743 249.657h-16.078l5.226-148.935 3.511 2.334Z"
            data-name="Path 100921"
          />
          <path
            fill="#f2f2f2"
            d="M314.708 253.651h-30.554a3.994 3.994 0 0 1 3.994-3.994h22.565a3.994 3.994 0 0 1 3.994 3.994"
            data-name="Path 100922"
          />
          <path
            fill="#f2f2f2"
            d="m299.431 102.04 76.881 44.387-.936-4.871a2.256 2.256 0 0 0-.834-1.357l-51.18-39.617a2.255 2.255 0 0 0-2.026-.377l-11.3 3.376a2.255 2.255 0 0 1-1.773-.208l-7.2-4.158Z"
            data-name="Path 100923"
          />
          <path
            fill="#f2f2f2"
            d="m297.8 99.215-76.881 44.391 4.686 1.624a2.257 2.257 0 0 0 1.594-.044l60.971-24.953 3.218-13.546 8.043-4.644Z"
            data-name="Path 100924"
          />
          <path
            fill="#f2f2f2"
            d="M301.059 99.214V10.441l-3.749 3.245a2.261 2.261 0 0 0-.76 1.4l-8.719 64.128a2.259 2.259 0 0 0 .687 1.947l8.571 8.095a2.26 2.26 0 0 1 .708 1.643v8.313Z"
            data-name="Path 100925"
          />
          <path
            fill="#f2f2f2"
            d="M306.809 249.657h-14.757l5.226-148.935h4.305Z"
            data-name="Path 100926"
          />
          <path
            fill="#ececec"
            d="M303.21 100.723a3.779 3.779 0 1 1-3.779-3.779 3.779 3.779 0 0 1 3.779 3.779"
            data-name="Path 100927"
          />
          <path
            fill="#f2f2f2"
            d="M302.62 100.5a3.446 3.446 0 1 1-3.446-3.446 3.446 3.446 0 0 1 3.446 3.446"
            data-name="Path 100928"
          />
          <path
            fill="#ececec"
            d="m222.283 144.074 73.446-43.574-.066-.051-74.743 43.153Z"
            data-name="Path 100929"
          />
          <path
            fill="#ececec"
            d="M292.087 248.689h14.723v.968h-14.723z"
            data-name="Rectangle 58085"
          />
          <path
            fill="#ececec"
            d="m299.971 11.386 1.011 86 .077-.032V10.441Z"
            data-name="Path 100930"
          />
          <path
            fill="#ececec"
            d="m376.04 145.011-74.459-41.818-.011.083 74.743 43.153Z"
            data-name="Path 100931"
          />
          <path
            fill="#f2f2f2"
            d="M72.266 181.541a17.94 17.94 0 0 1-17.887-17.887v-41.173a17.887 17.887 0 0 1 35.774 0v41.174a17.939 17.939 0 0 1-17.887 17.887"
            data-name="Path 100932"
          />
          <path
            fill="#e4e4e4"
            d="M73.242 253.74H71.29V133.203a.976.976 0 0 1 1.952 0Z"
            data-name="Path 100933"
          />
          <path
            fill="#e4e4e4"
            d="m73.242 149.54-5.427-9.4a.976.976 0 1 0-1.69.976l5.426 9.4Z"
            data-name="Path 100934"
          />
          <path
            fill="#e4e4e4"
            d="m72.98 160.782 5.426-9.4a.976.976 0 1 0-1.69-.976l-5.426 9.4Z"
            data-name="Path 100935"
          />
          <path
            fill="#f2f2f2"
            d="M117.943 161.95a22.781 22.781 0 0 1-22.714-22.714V86.952a22.714 22.714 0 0 1 45.428 0v52.285a22.78 22.78 0 0 1-22.714 22.714"
            data-name="Path 100936"
          />
          <path
            fill="#e4e4e4"
            d="M119.182 253.632h-2.478V100.567a1.239 1.239 0 1 1 2.478 0Z"
            data-name="Path 100937"
          />
          <path
            fill="#e4e4e4"
            d="m119.182 121.313-6.891-11.935a1.239 1.239 0 1 0-2.146 1.239l6.891 11.935Z"
            data-name="Path 100938"
          />
          <path
            fill="#e4e4e4"
            d="m118.85 135.588 6.891-11.935a1.239 1.239 0 0 0-2.146-1.239l-6.891 11.935Z"
            data-name="Path 100939"
          />
          <path
            fill="#e4e4e4"
            d="M83.3 246.976c1.042-.98 1.519-2.1 1.108-2.885-.509-.975-2.211-1.124-4.028-.438q.14-.355.258-.732c.968-3.093.415-6.019-1.235-6.535-1.609-.5-3.667 1.467-4.667 4.431-.828-3.4-2.954-5.791-4.777-5.362-1.716.4-2.558 3.167-2.005 6.335-2.515-1.11-4.941-1.022-5.714.317-.8 1.386.446 3.637 2.821 5.277a2.618 2.618 0 0 0-2.006 1.933c-.13 1.063.782 2.112 2.228 2.759a1.029 1.029 0 0 0-.054.615c.151.641.963 1.037 2.026 1.079h13.587v-.012a.6.6 0 0 0 .523-.322.474.474 0 0 0-.044-.386 1.547 1.547 0 0 0 1.412-.814 1.31 1.31 0 0 0-.248-1.194c1.834-.123 3.244-.927 3.289-1.961.039-.91-.988-1.733-2.472-2.109"
            data-name="Path 100940"
          />
          <path
            fill="#f2f2f2"
            d="M111.54 241.273c1.917-1.8 2.793-3.858 2.037-5.306-.936-1.793-4.066-2.067-7.408-.8q.256-.653.474-1.346c1.781-5.687.764-11.068-2.272-12.019-2.959-.927-6.743 2.7-8.582 8.15-1.523-6.246-5.433-10.649-8.786-9.861-3.157.742-4.7 5.825-3.688 11.65-4.625-2.042-9.087-1.879-10.509.583-1.472 2.549.821 6.688 5.189 9.7-2.071.694-3.495 1.963-3.69 3.556-.239 1.956 1.437 3.884 4.1 5.074a1.891 1.891 0 0 0-.1 1.131c.277 1.179 1.771 1.907 3.725 1.984v.007h24.988v-.021a1.1 1.1 0 0 0 .962-.592.87.87 0 0 0-.081-.711 2.846 2.846 0 0 0 2.6-1.5 2.408 2.408 0 0 0-.457-2.2c3.374-.226 5.966-1.7 6.048-3.606.073-1.673-1.817-3.187-4.547-3.879"
            data-name="Path 100941"
          />
          <path
            fill="#eaeaea"
            d="M279.534 242.559c-1.707-1.6-2.488-3.435-1.814-4.725.833-1.6 3.621-1.84 6.6-.717q-.228-.582-.422-1.2c-1.586-5.064-.68-9.856 2.023-10.7 2.635-.825 6 2.4 7.642 7.257 1.356-5.562 4.838-9.483 7.823-8.781 2.811.661 4.188 5.187 3.284 10.374 4.118-1.818 8.092-1.674 9.357.519 1.311 2.27-.731 5.956-4.621 8.642 1.844.618 3.112 1.748 3.285 3.166.213 1.741-1.28 3.458-3.648 4.519a1.684 1.684 0 0 1 .089 1.007c-.247 1.05-1.577 1.7-3.317 1.766v.006h-22.251v-.019a.979.979 0 0 1-.857-.527.775.775 0 0 1 .072-.633 2.535 2.535 0 0 1-2.312-1.333 2.144 2.144 0 0 1 .407-1.956c-3-.2-5.312-1.518-5.386-3.211-.064-1.49 1.618-2.837 4.049-3.454"
            data-name="Path 100942"
          />
          <path
            fill="#e4e4e4"
            d="M304.927 249.21c-.69-.648-1.005-1.388-.733-1.909.337-.645 1.463-.744 2.665-.29q-.092-.235-.171-.484c-.641-2.046-.275-3.982.817-4.324 1.065-.333 2.426.971 3.088 2.932.548-2.247 1.955-3.831 3.161-3.548 1.135.267 1.692 2.1 1.327 4.191 1.664-.734 3.269-.676 3.781.21.53.917-.3 2.406-1.867 3.492a1.732 1.732 0 0 1 1.327 1.279c.086.7-.517 1.4-1.474 1.826a.68.68 0 0 1 .036.407c-.1.424-.637.686-1.34.714h-8.99v-.008a.4.4 0 0 1-.346-.213.315.315 0 0 1 .029-.256 1.024 1.024 0 0 1-.934-.538.866.866 0 0 1 .164-.79c-1.214-.081-2.146-.613-2.176-1.3-.026-.6.654-1.146 1.636-1.4"
            data-name="Path 100943"
          />
          <path
            fill="#f2f2f2"
            d="M196.737 243.203c-1.609-1.512-2.345-3.238-1.71-4.454.785-1.5 3.413-1.735 6.218-.675q-.215-.549-.4-1.13c-1.495-4.774-.641-9.29 1.907-10.088 2.484-.778 5.66 2.265 7.2 6.84 1.278-5.243 4.56-8.939 7.374-8.277 2.649.623 3.948 4.889 3.1 9.778 3.882-1.714 7.627-1.577 8.82.489 1.235 2.14-.689 5.614-4.355 8.146 1.738.583 2.933 1.647 3.1 2.984.2 1.641-1.207 3.26-3.438 4.259a1.588 1.588 0 0 1 .084.949c-.233.99-1.487 1.6-3.126 1.665v.005h-20.974v-.018a.922.922 0 0 1-.808-.5.73.73 0 0 1 .068-.6 2.389 2.389 0 0 1-2.18-1.256 2.021 2.021 0 0 1 .383-1.844c-2.832-.189-5.008-1.43-5.077-3.027-.061-1.4 1.525-2.675 3.816-3.256"
            data-name="Path 100944"
          />
          <path
            fill="#e4e4e4"
            d="M220.022 247.753c-.911-.857-1.328-1.834-.969-2.523.445-.852 1.933-.983 3.522-.383q-.122-.311-.225-.64c-.847-2.7-.363-5.262 1.08-5.714 1.407-.441 3.206 1.283 4.08 3.874.724-2.969 2.583-5.063 4.177-4.688 1.5.353 2.236 2.769 1.753 5.539 2.2-.971 4.32-.893 5 .277.7 1.212-.39 3.18-2.467 4.614a2.288 2.288 0 0 1 1.754 1.691c.114.93-.683 1.846-1.948 2.412a.9.9 0 0 1 .048.537c-.132.561-.842.907-1.771.943h-11.88v-.01a.522.522 0 0 1-.458-.281.415.415 0 0 1 .038-.338 1.353 1.353 0 0 1-1.234-.712 1.145 1.145 0 0 1 .217-1.044c-1.6-.107-2.836-.81-2.875-1.714-.035-.8.864-1.515 2.162-1.844"
            data-name="Path 100945"
          />
          <path
            fill="#f2f2f2"
            d="M231.817 106.363a1.4 1.4 0 0 0-.42.064c0-.07.008-.141.008-.212a2.96 2.96 0 0 0-5.81-.8 1.918 1.918 0 1 0-.522 3.764h6.744a1.406 1.406 0 1 0 0-2.812"
            data-name="Path 100946"
          />
          <path
            fill="#f2f2f2"
            d="M180.14 74.037a4.241 4.241 0 0 0-2.189.6 6.446 6.446 0 0 0-7.089-4.949 9.136 9.136 0 0 0-17.47 3.74v.161a4.713 4.713 0 1 0-2.013 8.974h28.763a4.263 4.263 0 0 0 0-8.526"
            data-name="Path 100947"
          />
          <path
            fill="#f2f2f2"
            d="M136.302 175.554a2.789 2.789 0 0 1 1.44.4 4.241 4.241 0 0 1 4.663-3.256 6.01 6.01 0 0 1 11.494 2.457v.106a3.1 3.1 0 1 1 1.324 5.9h-18.921a2.804 2.804 0 1 1 0-5.608"
            data-name="Path 100948"
          />
          <path
            fill="#e4e4e4"
            d="m324.493 236.907-.045.17a5.108 5.108 0 0 1-.409 1 3.671 3.671 0 0 1-3.6 2.127 5.157 5.157 0 0 1-1.074-.126l-.17-.043.044-.17a5.148 5.148 0 0 1 .409-1 3.671 3.671 0 0 1 3.6-2.127 5.163 5.163 0 0 1 1.073.126Zm-4.864 2.87a3.589 3.589 0 1 0 1.663-2.24 4.286 4.286 0 0 0-1.663 2.24"
            data-name="Path 100949"
          />
          <path
            fill="#f2f2f2"
            d="M322.058 240.994a1.881 1.881 0 1 1 2.446-2.415 5.087 5.087 0 0 1-2.446 2.415"
            data-name="Path 100950"
          />
          <path
            fill="#e4e4e4"
            d="M318.587 217.405v.219a6.4 6.4 0 0 1-.19 1.331 4.567 4.567 0 0 1-3.705 3.658 6.382 6.382 0 0 1-1.333.173h-.219v-.219a6.379 6.379 0 0 1 .19-1.331 4.566 4.566 0 0 1 3.705-3.658 6.367 6.367 0 0 1 1.333-.173Zm-5 4.936a4.465 4.465 0 1 0 1.328-3.206 5.333 5.333 0 0 0-1.328 3.206"
            data-name="Path 100951"
          />
          <path
            fill="#f2f2f2"
            d="M316.882 223.067a2.34 2.34 0 1 1 2.221-3.654 6.329 6.329 0 0 1-2.221 3.654"
            data-name="Path 100952"
          />
          <path
            fill="#e4e4e4"
            d="m93.438 211.684.138.118a5.308 5.308 0 0 1 .748.834 3.8 3.8 0 0 1 .353 4.324 5.338 5.338 0 0 1-.6.945l-.117.139-.138-.118a5.316 5.316 0 0 1-.748-.834 3.8 3.8 0 0 1-.353-4.324 5.347 5.347 0 0 1 .6-.944Zm.476 5.835a3.721 3.721 0 0 0-.433-5.311 3.72 3.72 0 0 0 .433 5.311"
            data-name="Path 100953"
          />
          <path
            fill="#f2f2f2"
            d="M96.143 215.798a5.274 5.274 0 0 1-1.737 3.111 4.385 4.385 0 0 1 .592-6.485 5.273 5.273 0 0 1 1.145 3.374"
            data-name="Path 100954"
          />
          <path
            fill="#e4e4e4"
            d="m58.921 232.369.153-.01a4.5 4.5 0 0 1 .94.062 3.2 3.2 0 0 1 2.751 2.394 4.474 4.474 0 0 1 .192.922l.011.153-.153.01a4.5 4.5 0 0 1-.94-.062 3.2 3.2 0 0 1-2.751-2.394 4.484 4.484 0 0 1-.192-.922Zm3.713 3.231a3.128 3.128 0 0 0-3.379-2.941 3.128 3.128 0 0 0 3.379 2.941"
            data-name="Path 100955"
          />
          <path
            fill="#f2f2f2"
            d="M62.967 233.256a4.433 4.433 0 0 1 .781 2.892 3.687 3.687 0 0 1-3.451-4.249 4.433 4.433 0 0 1 2.67 1.358"
            data-name="Path 100956"
          />
          <path
            fill="#e4e4e4"
            d="M272.813 227.709h.172a5.027 5.027 0 0 1 1.051.113 3.59 3.59 0 0 1 2.975 2.81 5 5 0 0 1 .173 1.043l.006.172h-.172a5.005 5.005 0 0 1-1.051-.113 3.589 3.589 0 0 1-2.975-2.81 5.023 5.023 0 0 1-.173-1.043Zm4.015 3.792a3.51 3.51 0 1 0-2.555-.955 4.192 4.192 0 0 0 2.555.955"
            data-name="Path 100957"
          />
          <path
            fill="#f2f2f2"
            d="M277.308 228.889a1.839 1.839 0 1 1-2.932-1.644 4.974 4.974 0 0 1 2.932 1.644"
            data-name="Path 100958"
          />
          <path
            fill="#5078e1"
            d="M225.252 243.19h-79.309l1.443-5.224 2.818-10.2 1.707-6.176 2.516-9.107 4.85-17.555 2.516-9.107 17.687-64.019h12.235l6.482 23.463 11.206 40.555 2.516 9.107 4.85 17.555 2.516 9.107 4.525 16.378Z"
            data-name="Path 100959"
          />
          <path
            fill="#5078e1"
            d="M129.716 243.19h111.763v10.626H129.716z"
            data-name="Rectangle 58086"
          />
          <path
            fill="#232851"
            d="M225.252 243.19h-79.309l1.443-5.224h76.423Z"
            data-name="Path 100960"
          />
          <path
            fill="#fff"
            d="M211.919 194.926h-52.646l2.516-9.106h47.61Z"
            data-name="Path 100961"
          />
          <path
            fill="#fff"
            d="M219.288 221.588H151.91l2.516-9.107h62.342Z"
            data-name="Path 100962"
          />
          <path
            fill="#242850"
            d="M257.899 257.323H111.736a1.8 1.8 0 0 1-1.8-1.8v-.052a1.8 1.8 0 0 1 1.8-1.8h146.163a1.8 1.8 0 0 1 1.8 1.8v.052a1.8 1.8 0 0 1-1.8 1.8"
            data-name="Path 100963"
          />
          <path
            fill="#242850"
            d="M114.508 257.323H90.839a1.8 1.8 0 0 1-1.8-1.8v-.052a1.8 1.8 0 0 1 1.8-1.8h23.669a1.8 1.8 0 0 1 1.8 1.8v.052a1.8 1.8 0 0 1-1.8 1.8"
            data-name="Path 100964"
          />
          <path
            fill="#242850"
            d="M79.986 257.323H55.119a1.8 1.8 0 0 1-1.8-1.8v-.052a1.8 1.8 0 0 1 1.8-1.8h24.867a1.8 1.8 0 0 1 1.8 1.8v.052a1.8 1.8 0 0 1-1.8 1.8"
            data-name="Path 100965"
          />
          <path
            fill="#242850"
            d="M289.454 257.323h-20.413a1.8 1.8 0 0 1-1.8-1.8v-.052a1.8 1.8 0 0 1 1.8-1.8h20.413a1.8 1.8 0 0 1 1.8 1.8v.052a1.8 1.8 0 0 1-1.8 1.8"
            data-name="Path 100966"
          />
          <path
            fill="#242850"
            d="M316.138 257.323h-15.529a1.8 1.8 0 0 1-1.8-1.8v-.052a1.8 1.8 0 0 1 1.8-1.8h15.526a1.8 1.8 0 0 1 1.8 1.8v.052a1.8 1.8 0 0 1-1.8 1.8"
            data-name="Path 100967"
          />
        </g>
      </g>
      <text
        fill="#98a0ac"
        data-name={props?.message??"Request cannot be processed. Backup Restoring is in Progress"}
        fontFamily="NunitoSans-SemiBold, Nunito Sans"
        fontSize={18}
        fontWeight={600}
        transform="translate(255 307.623)"
      >
        <tspan x={-254} y={0}>
          {props?.message??"Request cannot be processed. Backup Restoring is in Progress"}
        </tspan>
      </text>
    </g>
  </svg>
)
export default RestoreErrorImage
