import * as React from "react"

const Location = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 48450"
          transform="translate(.5)"
          fill="#4e5a6b"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 96904">
      <g
        data-name="Mask Group 6285"
        transform="translate(-.5)"
        clipPath="url(#a)"
      >
        <path
          data-name="icons8-location (1)"
          d="M12.406 4a6.9 6.9 0 0 0-5.243 11.395c.123.139 3.017 3.428 3.984 4.35a1.822 1.822 0 0 0 2.519 0c1.124-1.072 3.869-4.219 3.989-4.357A6.9 6.9 0 0 0 12.406 4Zm0 8.937a2.031 2.031 0 1 1 2.031-2.031 2.031 2.031 0 0 1-2.031 2.031Z"
          fill="#78b1fe"
        />
      </g>
    </g>
  </svg>
)

export default Location
