import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";

export const TitleBar = (props) => {
  const size = useWindowDimensions();
  const classes = useStyles({ size, is_back_button: props?.is_back_button });

  return (
    <Container className={classes.root} maxWidth="sm">
      <Stack className={classes.content} direction={"row"}
        justifyContent={props?.is_back_button ? "space-between" : "center"} alignItems={"center"}>
        {props?.is_back_button
          ? <IconButton size="small" onClick={props?.goBack}>
            <ArrowBackIosIcon className={classes.arrow} fontSize="small" />
          </IconButton>
          : <Box height={"30px"} width={"30px"} />}
        <Typography className={classes.title} noWrap>
          {props?.title}
        </Typography>
        <Box height={"30px"} width={"30px"} />
      </Stack>
    </Container>
  );
};
