import React, { useState, useContext } from "react";
import {
  Subheader,
  TableWithPagination,
  FilterGenerator,
  AlertDialog,
} from "../../components";
import { useStyles } from "./style";
import { Box, Button, Grid, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { withNamespaces } from "react-i18next";
import {

  NetWorkCallMethods,
  AlertProps,
  getCompanyOption,
  getRoutePermissionNew,
  accessCheckRender,
  LocalStorageKeys,
} from "../../utils";
import { DownloadImage } from "../../assets";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { BackdropContext, AlertContext, AuthContext } from "../../contexts";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import { NewLoader } from "../../components/newLoader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ReportCheckBox } from "./components/checkBox";
import { BackendRoutes } from "../../router/routes";

const Report = (props) => {
  const { loading, handleLoading, t } = props
  const moduleId = localStorage.getItem(LocalStorageKeys.activeRoleModuleId)

  // histroy
  const history = useHistory();
  // location
  const { state } = useLocation();
  // styles
  const classes = useStyles();
  // -----------------------------------STATES------------------------------------------------------------
  const [company, setCompany] = useState({
    searchText: "",
    drawer: false,
    property_management: "",
    SelectedCompany: {},
  });
  const [filterData, setFilterData] = useState({});
  const [filterComponentsValue, setFilterComponentsValue] = useState({});
  const [list, setList] = useState({
    data: [],
    count: 0,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const auth = useContext(AuthContext);
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  const [permission, setPermission] = React.useState({})
  const [loader, setLoader] = React.useState(true)
  const [selectedColumns, setSelectedColumns] = React.useState([])
  const [tableData, setTableData] = React.useState([])
  const [openColumnsDialog, setOpenColumnsDialog] = React.useState(false)
  const [showColumns, setShowColumns] = React.useState(false)
  const [newDataType, setNewDataType] = React.useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  //To show Add menu 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //toclose dialog
  const handleClose = (value) => {
    setAnchorEl(null);
  };

  //--------------------------------FUNCTION-------------------------------------------
  const updateState = (k, v) => {
    setCompany({ ...company, [k]: v });
  };

  //   company selection
  const companySelect = (e) => {
    updateState("SelectedCompany", e);
  };
  const updateState1 = (value) => {
    setFilterData(value);
  };

  const getData = (offset = 0, limit = 40, search = "") => {
    if (state?.reportId?.length > 0) {
      let payload = {
        offset, limit, search,
        report_id: state?.reportId
      }
      NetworkCall(
        `${config?.api_url}${BackendRoutes.reports_get_report}`,
        NetWorkCallMethods.post, payload, null, true, false
      ).then((res) => {
        let temp_res = res?.data?.data?.[0] ?? []
        setTableData(temp_res)
      }).catch((error) => {
        console.log(error)
        alert.setSnack({
          ...alert, open: true, msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error
        })
      });
    } else {
      alert.setSnack({
        ...alert, open: true, msg: t("Please select the report"),
        severity: AlertProps.severity.error
      })
      history.push("/")
    }
  }
  const dateFilter = (value) => {
    if (value?.date?.startDate > value?.date?.endDate) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: t("End Date is Must Be Greater than Start Date"),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return false
    }
    if (value?.date?.startDate && value?.date?.endDate) {
      const filters = {}
      for (const key in value) {
        filters[key] = value[key] && Array.isArray(value[key]) ? value[key]?.map((item) => item.value ?? item) : value[key]
      }
      getList(
        0,
        10,
        filters
      );
      setPage(1);
      setLimit(10);
      setFilterData(filters);
    } else {
      if (value?.date?.startDate && (!value?.date?.endDate)) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("End Date is required"),
        });
      }
      if ((!value?.date?.startDate) && value?.date?.endDate) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Start Date is required"),
        });
      }
    }
  }
  const onApplyFilter = (value) => {
    setFilterComponentsValue(value)
    if (value?.date) {
      dateFilter(value)
    } else {
      const filters = {}
      for (const key in value) {
        filters[key] = value[key] && Array.isArray(value[key]) ? value[key]?.map((item) => item.value ?? item) : value[key]
      }
      getList(
        0,
        10,
        filters
      );
      setPage(1);
      setLimit(10);
      setFilterData(filters);
    }

  };
  //   company selection
  //reports get
  const getList = (
    offset,
    limit,
    filters = {},
  ) => {

    setLoader(true)
    const requestPayload = {
      filters: filters,
      offset: offset,
      limit: limit,
      company_id: company?.SelectedCompany?.value
    };
    NetworkCall(
      `${config.api_url}${state?.end_point}`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        let data = res?.data?.data?.map((item, index) => {
          return {
            index: offset + index + 1,
            ...item,
          }
        })
        setList({
          data: data ?? [],
          count: res?.data?.count ?? 0,
        });
        handleLoading(false)
        setLoader(false)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong. Please try again"),
        });
      });
  };
  //reports downloadExcelAndPdf
  const downloadExcelAndPdf = (type) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "downloading...",
    });
    const keyMapper = selectedColumns?.length > 0 && Object.fromEntries(selectedColumns?.map((item, index) => [item.value, { header: item.label, index: index }]));
    const requestPayload = {
      report_id: state?.reportId,
      filters: filterData,
      offset: offset,
      limit: limit,
      key_mapper: keyMapper,
      format_type: type,
      company_id: company?.SelectedCompany?.value
    };
    NetworkCall(
      `${config.api_url}/reports/export`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false,
      { responseType: "blob" }
    )
      .then((res) => {
        const { data, headers } = res;
        console.log(res, "res");
        setAnchorEl(null);
        var blob = new Blob([data], { type: data?.type });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = JSON.parse(
          headers["content-disposition"].split("=")[
          headers["content-disposition"].split("=").length - 1
          ]
        );
        link.download = fileName;
        link.click();
        backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      })
      .catch((error) => {
        setAnchorEl(null);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong. Please try again"),
        });
      });
  };
  // pagination
  const handlePagination = (value) => {
    setPage(value);
    let offset = (value - 1) * limit;
    getList(
      offset,
      limit,
      filterData
    );
    setOffset(offset);
  };
  // pagination limit change function
  const handleChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    getList(
      0,
      value,
      filterData
    );
  };



  const clearFilter = () => {
    setFilterData({});
    setFilterComponentsValue({});
    getList(
      0,
      10,
      {}
    );
    updateState("drawer", false);
  };

  // useEffect to get company list for company switcher when loading the screen
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) {
        let company = getCompanyOption(backdrop, auth, alert);
        if (company) {
          setCompany({
            ...company,
            companyList: company?.list,
            SelectedCompany: company?.selected,
          });
        }
        getData()
      }
    }
    // eslint-disable-next-line
  }, [auth])

  React.useEffect(() => {
    if (company?.SelectedCompany?.label?.length > 0 && tableData?.name?.length > 0) {
      getList(0, 10, {})

    }
    // eslint-disable-next-line
  }, [company?.SelectedCompany, tableData])
  const filterComponents = tableData?.schema?.filters?.length > 0 ?
    tableData?.schema?.filters?.map((item) => {
      return {
        component: item?.component,
        value: item?.value ?? filterComponentsValue[item?.key],
        options: item?.options,
        isMulti: item?.is_multi,
        label: item?.label,
        placeholder: item?.placeholder,
        state_name: item?.key,
        loadOptions: (search, array, handleLoading) => loadOptionsApis(
          item?.load_options_end_point,
          item?.load_options_end_point === "/enum" ? item?.payload : { company_id: company?.SelectedCompany?.value },
          search,
          array,
          handleLoading,
          item?.extract_label ?? "data",
        ),
        debounceTimeout: item?.debounceTimeout,
        isPaginate: item?.isPaginate,
        onChange: (value) => updateState1(item?.state_name, value),
        // minMaxlabel: item?.minMaxlabel ?? false

      }
    }) : []
  //column options(
  const columnOptions = tableData?.schema?.table_headers?.map((item) => {
    return {
      value: item?.field,
      label: item?.title
    }
  })
  const onSelectedColumns = () => {
    setShowColumns(true)
    const ResultArrayObjtwo = tableData?.schema?.table_data_type?.filter(({ name }) => selectedColumns?.some(({ value }) => value === name));
    setNewDataType(ResultArrayObjtwo)
    setOpenColumnsDialog(false)
  }
  const onClearColumns = () => {
    setShowColumns(false)
    setSelectedColumns([])
  }

  //options of menu
  const optionsMenu = [{
    label: "Excel", value: "excel"
  },
  {
    label: "Pdf", value: "pdf"
  }]

  const render = () => {
    return (
      <div>
        {" "}
        {/* sub Navbar */}
        <Subheader
          title={tableData?.name ?? ""}
          goBack={() => {
            history.goBack(-1);
          }}
          select={moduleId === "5" ? false : true}
          options={company?.companyList}
          value={company?.SelectedCompany}
          placeholder={t("Search")}
          onchange={(e) => {
            companySelect(e);
          }}
        />

        <div className={classes.root}>
          <Grid container className={classes.content} spacing={2}>
            <Grid item xs={3} className={classes.PaddingTop}>
              <Box>
                <IconButton
                  size="small"
                  className={classes.img3}
                  onClick={() => setOpenColumnsDialog(true)}
                >
                  {
                    t("Filter columns")
                  } &nbsp;<VisibilityIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={9} className={classes.PaddingTop}>
              <Box display={"flex"} alignItems="center" sx={{ float: "right" }}>
                <Stack
                  direction="row"
                  spacing={0.6}
                  display={"flex"}
                  alignItems="center"
                >
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    className={`${classes.menuparent}`}

                  >
                    {optionsMenu.map((x, index) => {
                      return (
                        <Box className={`${classes.menuparent}`}>
                          <MenuItem onClick={() => downloadExcelAndPdf(x?.value)}>
                            <Typography className={`${classes.menutext}`}>{x?.label}</Typography>
                          </MenuItem>
                          <hr className={index === optionsMenu?.length - 1 ? `${classes.alt_divider}` : `${classes.divider_menu}`
                          }
                          />
                        </Box>
                      )
                    })}
                  </Menu>
                  <IconButton
                    size="small"
                    className={classes.img}
                    onClick={handleClick}
                    disabled={list?.data === 0}
                  >
                    <DownloadImage />
                  </IconButton>
                  <IconButton
                    size="small"
                    className={classes.img}
                    onClick={() => updateState("drawer", true)}
                  >
                    <img src="/images/filter.svg" alt="filter" />
                  </IconButton>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.noPadding}>
              {
                loader ? (
                  <NewLoader minusHeight="300px" />
                ) : (
                  <TableWithPagination
                    heading={showColumns ? selectedColumns?.length > 0 && selectedColumns?.map(({ value, label }) => ({ title: label, field: value })) : tableData?.schema?.table_headers}
                    rows={list?.data ?? []}
                    path={tableData?.schema?.table_path ?? []}
                    showpagination={true}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    totalRowsCount={list?.count}
                    page={page ?? 1}
                    limit={limit ?? 10}
                    tableType="no-side"
                    dataType={showColumns ? newDataType?.length > 0 && newDataType : tableData?.schema?.table_data_type}
                    height={"calc(100vh - 301px)"}
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                  />
                )}
            </Grid>
          </Grid>
        </div>
        {company?.drawer && (
          <FilterGenerator
            open={company?.drawer}
            onClose={() => updateState("drawer", false)}
            components={filterComponents}
            onApply={(value) => onApplyFilter(value)}
            clearFilter={clearFilter}
            is_customFilter
          />
        )}
        {/* column select */}
        <AlertDialog
          open={openColumnsDialog}
          onClose={() => setOpenColumnsDialog(false)}
          header={t("Choose Columns To Display")}
          isNormal
          component={
            <Box p={2} >
              <Box height="400px" >
                <ReportCheckBox
                  t={t}
                  options={columnOptions}
                  value={selectedColumns}
                  onChange={(value) => setSelectedColumns(value)}
                  placeholder={t("Search Columns")}
                />
              </Box>
              {/* buttons */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button variant="contained"
                    className={classes.previousbtn}
                    disabled={selectedColumns?.length === 0}
                    fullWidth onClick={onClearColumns}>{t("Clear All")}</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.submitbtn}
                    disabled={selectedColumns?.length === 0}
                    variant="contained" fullWidth onClick={onSelectedColumns}>{t("Apply")}</Button>
                </Grid>
              </Grid>
            </Box >
          }
        />
      </div>
    );
  }
  return <div>
    {accessCheckRender(render, permission, "", loading)}
  </div>

};

export default withNamespaces("reports")(Report);
