import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import InspectionTemplateImg from "../../../assets/inspectionTemplate";
import { InspectionMappingComponentStyles } from "./styles";
const BulkTemplate = ({ BulkData = "", handleClose = () => false, crossicon = false, handleClick = () => false, buttonName = "" }) => {
    const classes = InspectionMappingComponentStyles();

    return (
        <Box className={`${classes.templateList}`}>
            {BulkData.length > 0 && BulkData.map((item) => {
                return (
                    <Box m={2}>
                        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
                            {/* <img src={InspectionTemplateImg} alt="Inspection Template Image" /> */}
                            <Stack direction={"row"} spacing={2}>
                                <InspectionTemplateImg />
                                <Stack>
                                    <Typography className={classes.templateName}>{item.Name}</Typography>
                                    <Typography className={classes.templateItem}>{item.sub_items.length} items</Typography>
                                </Stack>
                            </Stack>
                            {crossicon === true &&
                                <Stack className={`${classes.closeIcon}`} onClick={handleClose}><CloseIcon sx={{ color: "#c1c1c1" }} /></Stack>
                            }
                        </Stack>
                    </Box>
                )
            })}
            <Box className={classes.list} margin="0px 12px 12px 12px">
                {BulkData[0]?.sub_items && BulkData[0].sub_items.map((x) => {
                    return (
                        <>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={1}>
                                <Stack spacing={3} direction={"row"} alignItems={"center"}>
                                    <Avatar variant="square" className={classes.avatar}>
                                        <img src="/images/Group 98362.svg" alt="" />
                                    </Avatar>
                                    <Typography className={classes.inspectionName}> {x.Name} </Typography>
                                </Stack>
                                <Typography className={classes.inspectionQty}>{x.count} QTY</Typography>
                            </Stack>
                            <Divider />
                        </>


                    )

                })}
            </Box>
            <Box m={2}>
                <Button variant="outlined" className={classes.editbtn} onClick={handleClick}>{buttonName}</Button></Box>
        </Box>
    )
}
export default BulkTemplate