import React from "react"

export const UnitWithAssetsIcon = () =>(

<svg xmlns="http://www.w3.org/2000/svg"  width="36" height="36" viewBox="0 0 36 36">
  <defs>
    <linearGradient id="linear-gradient" x1="0.5" y1="54.899" x2="0.5" y2="56.241" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#d43a02"/>
      <stop offset="1" stop-color="#b9360c"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" y1="32.033" x2="1" y2="32.033" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#7819a2"/>
      <stop offset="1" stop-color="#771aa9"/>
    </linearGradient>
    <linearGradient id="linear-gradient-3" y1="116" x2="1" y2="116" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#ae4cd5"/>
      <stop offset="1" stop-color="#ac4ad5"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" y1="42.591" x2="1" y2="42.591" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#c965eb"/>
      <stop offset="1" stop-color="#c767e5"/>
    </linearGradient>
    <linearGradient id="linear-gradient-5" y1="39" y2="39"/>
  </defs>
  <g id="Group_113557" data-name="Group 113557" transform="translate(10000 23261)">
    <rect id="icons8-smart-home-checked-96" width="36" height="36" transform="translate(-10000 -23261)" fill="#fff" opacity="0"/>
    <g id="icons8-interior_2_" data-name="icons8-interior (2)" transform="translate(-10001.17 -23261)">
      <path id="Path_100194" data-name="Path 100194" d="M32.537,35.2H7.561A1.586,1.586,0,0,1,6,33.591V16.685L20.049,3,34.1,16.685V33.591A1.586,1.586,0,0,1,32.537,35.2Z" transform="translate(-0.621 -0.22)" fill="#f1f1f1"/>
      <path id="Path_100195" data-name="Path 100195" d="M20.049,4a1.557,1.557,0,0,0-1.035.393L14.831,8.148,6,16.488v4.207L20.049,8.236,34.1,20.695V16.488l-8.831-8.34L21.084,4.393A1.557,1.557,0,0,0,20.049,4Z" transform="translate(-0.621 -0.439)" opacity="0.05"/>
      <path id="Path_100196" data-name="Path 100196" d="M20.049,3a1.563,1.563,0,0,0-1.035.39L14.831,7.124,6,15.417V19.6L20.049,7.212,34.1,19.6V15.417L25.266,7.124,21.084,3.39A1.563,1.563,0,0,0,20.049,3Z" transform="translate(-0.621 -0.22)" opacity="0.07"/>
      <path id="Path_100197" data-name="Path 100197" d="M35.424,15.664,20.463,2.393a1.56,1.56,0,0,0-2.07,0L3.431,15.664a.779.779,0,0,0-.05,1.116l1.277,1.362a.78.78,0,0,0,1.085.052L19.428,6.236,33.112,18.2a.781.781,0,0,0,1.085-.052l1.277-1.362A.78.78,0,0,0,35.424,15.664Z" fill="url(#linear-gradient)"/>
      <path id="Path_100198" data-name="Path 100198" d="M31.245,40.061h1.412a.238.238,0,0,0,.229-.3l-.867-3.035H30.112l.9,3.166A.237.237,0,0,0,31.245,40.061Z" transform="translate(-5.914 -7.622)" fill="#e3a600"/>
      <path id="Path_100199" data-name="Path 100199" d="M15.972,40.061H14.56a.238.238,0,0,1-.229-.3l.867-3.035H17.1l-.9,3.166A.237.237,0,0,1,15.972,40.061Z" transform="translate(-2.448 -7.622)" fill="#e3a600"/>
      <path id="Path_100200" data-name="Path 100200" d="M27.057,30.707H17.431A1.431,1.431,0,0,1,16,29.277V20.431A1.431,1.431,0,0,1,17.431,19h9.626a1.431,1.431,0,0,1,1.431,1.431v8.845A1.431,1.431,0,0,1,27.057,30.707Z" transform="translate(-2.816 -3.732)" fill="url(#linear-gradient-2)"/>
      <path id="Path_100201" data-name="Path 100201" d="M28.488,33.122H16V30.954A.954.954,0,0,1,16.954,30h10.58a.954.954,0,0,1,.954.954Z" transform="translate(-2.816 -6.146)" fill="url(#linear-gradient-3)"/>
      <path id="Path_100202" data-name="Path 100202" d="M15.341,34.585h-.434A1.907,1.907,0,0,1,13,32.678v-6.2A.477.477,0,0,1,13.477,26h1.388a.477.477,0,0,1,.477.477Z" transform="translate(-2.158 -5.268)" fill="url(#linear-gradient-4)"/>
      <path id="Path_100203" data-name="Path 100203" d="M32.284,37.649l-.265-.927H30.112l.272.954H32.02A2.288,2.288,0,0,0,32.284,37.649Z" transform="translate(-5.914 -7.622)" opacity="0.05"/>
      <path id="Path_100204" data-name="Path 100204" d="M32.218,37.417l-.2-.7H30.111l.2.716h1.7C32.087,37.438,32.151,37.424,32.218,37.417Z" transform="translate(-5.914 -7.622)" opacity="0.07"/>
      <path id="Path_100205" data-name="Path 100205" d="M15.1,37.649l.265-.927h1.908L17,37.676H15.37A2.284,2.284,0,0,1,15.1,37.649Z" transform="translate(-2.62 -7.622)" opacity="0.05"/>
      <path id="Path_100206" data-name="Path 100206" d="M15.19,37.417l.2-.7H17.3l-.2.716h-1.7C15.32,37.438,15.256,37.424,15.19,37.417Z" transform="translate(-2.639 -7.622)" opacity="0.07"/>
      <path id="Path_100207" data-name="Path 100207" d="M29.694,26H28.306a.477.477,0,0,0-.477.477v5.767H13v1.214a1.907,1.907,0,0,0,1.908,1.908H28.262a1.907,1.907,0,0,0,1.908-1.908V26.478A.477.477,0,0,0,29.694,26Z" transform="translate(-2.158 -5.268)" fill="url(#linear-gradient-5)"/>
    </g>
  </g>
</svg>

)