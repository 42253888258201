import { Box, Grid } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { SearchFilter, TableWithPagination, UseDebounce } from "../../../components"
import { getInvoiceHeading, invoicedataType, invoicePath } from "../../../utils/agreementUtils"
import { AggrementComponentStyles } from "./styles"
const InvoiceTable = ({
    data = {},
    getInvoice = () => false,
    handleClick = () => false,
    t
}) => {
    const classes = AggrementComponentStyles()
    const debounce = UseDebounce()
    const invoiceHeading = getInvoiceHeading(t)
    const [limit, setLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);

    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getInvoice(0, 10, e)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getInvoice(offset, limit, "")
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getInvoice(0, value, "")
    }

    return (
        <Box className={classes.invoiceTable} p={2}>
            <Grid container>
                <Grid item xs={4}>
                    <SearchFilter placeholder={t("search_invoice")} value={searchText} handleChange={handleSearch} />
                </Grid>
            </Grid>
            <TableWithPagination
                heading={invoiceHeading}
                rows={data?.list}
                path={invoicePath}
                showpagination={true}
                count="2"
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                onClick={handleClick}
                dataType={invoicedataType}
                handlePagination={handlePagination}
                handleChangeLimit={handleChangeLimit}
                totalRowsCount={data?.count}
                page={page}
                limit={limit}
                height={`calc(100vh - 370px)`}
                view={true}
                edit={true}
                delete={true} />
        </Box>
    )
}
export default withNamespaces("agreement")(InvoiceTable); 