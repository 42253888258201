import * as React from "react"

export const TerminationDocument = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 103277" transform="translate(-121 -201)">
      <circle
        data-name="Ellipse 129431"
        cx={20}
        cy={20}
        r={20}
        transform="translate(121 201)"
        fill="#f1f7ff"
      />
      <g data-name="Group 102049">
        <g data-name="Group 102052">
          <path
            data-name="Path 96791"
            d="M140.826 223.694a1.471 1.471 0 0 1-1.458-1.166c-.158-.707.1-1.46.792-1.564 1.4-.21 2.038-1.312 2.949-2.106a3.264 3.264 0 0 1 .562-.453.638.638 0 0 1 .852.23.628.628 0 0 1-.033.88c-.406.448-.838.873-1.259 1.308a2.668 2.668 0 0 0-1.074 1.785c-.038.7-.7 1.025-1.332 1.086"
            fill="#fff"
          />
        </g>
      </g>
      <path
        d="M136.025 212a2.028 2.028 0 0 0-2.025 2.025v13.95a2.028 2.028 0 0 0 2.025 2.025h10.35a2.028 2.028 0 0 0 2.025-2.025V219.2h-5.175a2.028 2.028 0 0 1-2.025-2.025V212Zm6.525.4v4.775a.676.676 0 0 0 .675.675H148Zm-3.375 8.6a2 2 0 0 1 2.025 2.246 4.564 4.564 0 0 1-1.03 2.668 1.791 1.791 0 0 0 .355.036c.369 0 .508-.171.792-.751.2-.419.513-1.049 1.233-1.049.833 0 1.022.8 1.116 1.188.14.594.193.612.459.612a.679.679 0 0 0 .234-.567.675.675 0 0 1 1.332-.216 2.024 2.024 0 0 1-.468 1.584 1.438 1.438 0 0 1-1.1.549 1.665 1.665 0 0 1-1.687-1.323 2.091 2.091 0 0 1-1.911 1.323 2.945 2.945 0 0 1-1.525-.405 3.4 3.4 0 0 1-1.629.405.675.675 0 1 1 0-1.35 2.08 2.08 0 0 0 .567-.076 4.879 4.879 0 0 1-.792-2.624 2 2 0 0 1 2.029-2.25Zm0 1.35c-.184 0-.675 0-.675.9a3.468 3.468 0 0 0 .576 1.881 3.3 3.3 0 0 0 .774-1.885c0-.896-.491-.896-.675-.896Z"
        fill="#78b1fe"
        stroke="rgba(0,0,0,0)"
      />
    </g>
  </svg>
)