import {
  Box,
  Grid,
  Hidden,
  Skeleton
} from "@mui/material";
import React from "react";
import { AlertProps, NetWorkCallMethods, useWindowDimensions } from "../../utils";
import { ForgotPasswordPage } from "./forgotPasswordPage";
import { useStyles } from "./style";
import { withNamespaces } from "react-i18next";
import { useState } from "react";
import { getLeftSectionGrid, getRightSectionGrid, initital_screen_state } from "../../utils/login/utils";
import { useEffect } from "react";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { BackendRoutes } from "../../router/routes";

const ForgotPassword = ({
  t = () => false,
}) => {
  const size = useWindowDimensions()
  const classes = useStyles({ size });
  const [is_loading, set_is_loading] = useState(true);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);
  const [screen_state, set_screen_state] = useState(initital_screen_state);

  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line
  }, []);

  const initialLoad = () => {
    getInitialRender();
  }

  const getInitialRender = () => {
    NetworkCall(
      `${config?.authapi}${BackendRoutes?.login_domain_initial_render}`,
      NetWorkCallMethods?.post, {}, null, false, false)
      .then((res) => {
        let is_custom_domain = res?.data?.data?.is_custom_domain;
        let _ = res?.data?.data?.render_data?.[0]
        if (_) {
          let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain,
            [is_custom_domain && "is_custom_domain"]: is_custom_domain,
          }
          set_screen_state(temp_screen_state);
        }
        set_is_loading(false);
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        set_is_loading(false);
      });
  }

  const handleImageOnLoad = () => {
    setIsBackgroundImageLoading(false);
  }

  const handleImageOnError = () => {
    set_screen_state({ ...screen_state, web_background: initital_screen_state?.web_background });
  }

  return (
    <>
      <Hidden smUp>
        {is_loading
          ? <></>
          : <ForgotPasswordPage t={t} size={size} screen_state={screen_state} />
        }
      </Hidden>
      <Hidden smDown>
        {is_loading
          ? <></>
          : <Box className={classes.root}>
            {is_loading
              ? <></>
              : <Grid container className={classes.grid}>
                <Grid item xs={getLeftSectionGrid(size)} className={classes.left_section}>
                  {size?.width > 599 &&
                    <>
                      {isBackgroundImageLoading && <Skeleton variant="rounded" className={classes.web_background} />}
                      <img className={classes.web_background}
                        style={{ display: isBackgroundImageLoading ? "none" : "block" }}
                        src={screen_state?.web_background} alt=""
                        onLoad={handleImageOnLoad}
                        onError={handleImageOnError} />
                    </>}
                </Grid>
                <Grid item
                  xs={getRightSectionGrid(size)}
                  className={classes.right_section}>
                  <ForgotPasswordPage t={t} size={size} screen_state={screen_state} />
                </Grid>
              </Grid>
            }
          </Box>
        }
      </Hidden>
    </>
  );
};
export default withNamespaces("forgotPassword")(ForgotPassword)