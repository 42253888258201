import React from 'react'
import { SelectBox, TextBox } from '../../../components'
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { DeleteIcon } from '../../../assets'
import { CreateNewEditContext } from '../createNewEditContext'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'

export const NewRateConfigComponent = ({ selectedValue = "a", data = {}, rowData = {}, index = {}, t = () => false }) => {

    const {
        selectedCompany,
        updateCommissionState,
        removeCommissionList,
        contractData,
        stateData
    } = React.useContext(CreateNewEditContext)

    const pricingFactormanualResponse = (array) => {
        const details = array?.data?.map((i) => {
            return {
                label: i?.name,
                value: i?.id
            }
        })
        return details
    };

    return (
        <Grid container spacing={"10px"}>
            <Grid item md={6} sm={6} lg={6}>
                <SelectBox
                    placeholder={selectedValue === 'a' ? t("Add Pricing Factor") : t("Select Component")}
                    onChange={(value) => updateCommissionState(selectedValue === 'a' ? 'pricingFactor' : "pricingComponent", value, rowData)}
                    value={selectedValue === 'a' ? rowData?.pricingFactor : rowData?.pricingComponent}
                    color="white"
                    options={[]}
                    selectHeight={"40px"}
                    // loading={loading === "currency"}
                    loadOptions={(search, array, handleLoading) =>
                        loadOptionsApis(
                            selectedValue === 'a' ? "contract/commission_rate_config" : "pricing_component/get_component",
                            selectedValue === 'a' ? {
                                contract_type: contractData?.selectedContractType?.id,
                                commission_rate_type: contractData?.selectedRateConfiguration
                            } : {
                                company_id: selectedCompany?.value
                            },
                            search,
                            array,
                            handleLoading,
                            "data",
                            {},
                            pricingFactormanualResponse
                        )
                    }
                    isPaginate
                    isReadOnly={stateData?.type === "view"}
                    debounceTimeout={800}
                    isRequired
                    isError={selectedValue === 'a' ? rowData?.error?.pricingFactor?.length > 0 : rowData?.error?.pricingComponent?.length > 0}
                    errorMessage={selectedValue === 'a' ? rowData?.error?.pricingFactor : rowData?.error?.pricingComponent}
                />
            </Grid>

            <Grid item md={4} sm={4} lg={4}>
                <TextBox label={null}
                    placeholder='0'
                    type='number'
                    endAdornment={<InputAdornment position="end">
                        <Typography sx={{ opacity: "0.5" }}>{selectedValue === 'a' ? selectedCompany?.currency_symbol : "%"}</Typography>
                    </InputAdornment>}
                    onChange={(e) => updateCommissionState('pricingFactorAmount', e?.target?.value, rowData)}
                    value={rowData?.pricingFactorAmount}
                    isReadonly={stateData?.type === "view"}
                    isRequired
                    isError={rowData?.error?.pricingFactorAmount?.length > 0}
                    errorMessage={rowData?.error?.pricingFactorAmount}
                    height={"44px"}
                    padding={"10px 14px"}
                />
            </Grid>
            {/* {
                selectedValue === 'b' &&
                <Grid item md={3}>
                    <SelectBox
                        placeholder={t("Select Component")}
                        // onChange={(value) => updateCommissionState('pricingComponent', value, rowData)}
                        value={rowData?.pricingComponent}
                        color="white"
                        options={[
                            {
                                label: "1111",
                                value: "1111"
                            },
                            {
                                label: "2222",
                                value: "2222"
                            }
                        ]}
                    // loadOptions={(search, array, handleLoading) =>
                    //     loadOptionsApis(
                    //         "pricing_component/get_component",
                    //         {
                    //             company_id: state?.selectedCompany?.value
                    //         },
                    //         search,
                    //         array,
                    //         handleLoading,
                    //         "data",
                    //         {},
                    //         pricingFactormanualResponse
                    //     )
                    // }
                    // isPaginate
                    // isReadOnly={state?.type === "view"}
                    // debounceTimeout={800}
                    // isRequired
                    // isError={rowData?.error?.pricingComponent?.length > 0}
                    // errorMessage={rowData?.error?.pricingComponent}
                    />
                </Grid>
            } */}
            <Grid item md={2} sm={2} lg={2} sx={{ display: "flex", alignItems: "center", float: "right" }}>
                {
                    (stateData?.type !== "view" && index !== 0) &&
                    <IconButton sx={{ cursor: "pointer" }}
                        onClick={() => removeCommissionList(rowData)}
                    >
                        <DeleteIcon />
                    </IconButton>
                }
            </Grid>
        </Grid>
    )
}