import { Badge, Box, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import { addHours, subSeconds } from 'date-fns';
import moment from "moment";
import React from "react";
import { withNamespaces } from "react-i18next";
import FilterIMG from "../../../../../assets/filter";
import { AlertDialog, FilterGenerator, SearchFilter, TableWithPagination, UseDebounce } from "../../../../../components";
import { config } from "../../../../../config";
import { NetworkCall } from "../../../../../networkcall/index";
import { AmenitiesHeadingUnit, AmenitiesPath, NetWorkCallMethods } from "../../../../../utils";
import { AddAmenitiesPopup } from "../../../addAmenitiesPopup";
import { UnitAmenitiesStyles } from "./style";
const initialState = () => {
    return {
        id: null,
        amenity: "",
        chargeable: "Yes",
        amount: "",
        period: "",
        status: "Active",
        notes: "",
        edit: false,
        view: false,
        error: {
            amenity: "",
            chargeable: "",
            amount: "",
            period: "",
            status: "",
        },
    }
}

const UnitAmenities = (props) => {
    const { t } = props
    const classes = UnitAmenitiesStyles();
    const theme = useTheme()
    const [unitAmenities, setUnitAmenities] = React.useState({ ...initialState() })
    const [openDialog, setOpenDialog] = React.useState(false)
    const [drawer, setDrawer] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [page, setPage] = React.useState(1);
    // const [loading, setLoading] = React.useState(true)
    const [limit, setLimit] = React.useState(10);
    const debounce = UseDebounce();
    const [filterData, setFilterData] = React.useState({ status: [true] });
    const companyId = props?.companyId
    const currencyId = props?.currency?.id
    const unitId = props?.unitId

    //pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        UnitAmenitiesTableData(offset, limit, searchText)
    }
    //page change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        UnitAmenitiesTableData(0, value, searchText)
    }
    //search in table
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        UnitAmenitiesTableData(0, limit, e)
    }
    //table listing function
    const UnitAmenitiesTableData = async (offset = 0, limit = 10, search = "") => {
        return false
    }

    // is active changing function
    const changeactive = async (data, value) => {
        return false
    }
    //delete function
    const deleteTableData = async (data) => {
        return false
    }

    React.useEffect(() => {
        if (props?.permission?.read) {
            if (unitId) {
                UnitAmenitiesTableData()
            }
        }
        //eslint-disable-next-line
    }, [unitId, filterData, props?.permission]);
    //handle icon function
    const handleIcon = (type, data, status) => {
        if (type === "view") {
            setOpenDialog(true)
            setUnitAmenities({
                ...unitAmenities,
                amenityName: data?.amenityName,
                chargeable: data?.chargeable,
                amount: data?.amountt,
                period: data?.periodd,
                notes: data?.notes,
                booking: data?.booking === "yes" ? { label: "Required", value: "Required" } : { label: "Not Required", value: "Not Required" },
                amenity: { label: data?.amenityName, value: data?.amenityId },
                edit: false,
                view: true,
                id: data?.id,
                status: data?.status,

            })
        }
        if (type === "edit") {
            setOpenDialog(true)
            setUnitAmenities({
                ...unitAmenities,
                amenityName: data?.amenityName,
                chargeable: data?.chargeable,
                amount: data?.amountt,
                period: data?.periodd,
                notes: data?.notes,
                booking: data?.booking === "yes" ? { label: "Required", value: "Required" } : { label: "Not Required", value: "Not Required" },
                amenity: { label: data?.amenityName, value: data?.amenityId },
                edit: true,
                id: data?.id,
                status: data?.status,

            })
        }
        else if (type === "active") {
            changeactive(data.id, status)
        }
        else if (type === "delete") {
            deleteTableData(data.id, data.IsDelete)
        }

    }
    //apply filter
    const onApplyFilter = (value) => {
        setFilterData(value)
    }

    //onclose modal
    const OncloseModal = () => {
        setOpenDialog(false)
        setUnitAmenities({ ...initialState() })
    }

    //Pop up netwoerk call

    const getUnitAmenities = (e) => {
        // eslint-disable-next-line
        let const_arr = e?.slot_time?.map((arr) => {
            if (arr.length !== 0)
                return [
                    moment(arr[0]).format("HH:mm:ss"),
                    moment(subSeconds(addHours(new Date(arr[arr.length - 1]), 1), 1)).format("HH:mm:ss")
                ]
        })

        const result = const_arr.filter(element => {
            return element !== undefined
        })
        const variables = {
            "id": e?.id ?? undefined,
            "is_active": true,
            "unit_id": unitId,
            "amenities_type": e?.amenity_reference?.value,
            "is_chargeable": e?.chargeable,
            "total_slots": e?.slot_time.length === 0 ? 1 : e?.slot_time.length,
            "applicable_days": e?.days,
            "slot_partition": e?.slot,
            "selected_slots": result,
            "currency_id": currencyId,
            "rate": e?.chargeable_value,
            "period": e?.slot_type,
            "amenity_category_id": e?.amenity_category?.id
        }
        NetworkCall(
            `${config.api_url}/unit_amenities_breakup/upsert`,
            NetWorkCallMethods.post,
            variables,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.type === "success") {
                    OncloseModal()
                    UnitAmenitiesTableData()
                }
            })
            .catch((error) => {

            });
    }
    return (
        <>
            {
                // loading ? <LoadingSection bottom={"45vh"} message={t("Loading Amenities Listing...")} /> :
                    <div>
                        <Box p={2} className={classes.root}  >
                            <Grid container >
                                <Grid item xs={4}>
                                    <SearchFilter value={searchText} placeholder={t("Search Amenities")} handleChange={(value) => handleSearch(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <Box
                                        display={"flex"}
                                        sx={{ float: "right" }}>
                                        <Stack
                                            direction="row"
                                            divider={<Divider orientation="vertical" flexItem />}
                                            spacing={2}>
                                            {filterData.status?.length > 0 || filterData.property_type?.length > 0 ? (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <Badge variant="dot" color="primary">
                                                        <FilterIMG color="#091b29" />
                                                    </Badge>
                                                </IconButton>

                                            ) : (
                                                <IconButton onClick={() => setDrawer(true)} className={classes.img}>
                                                    <FilterIMG color="#091b29" />
                                                </IconButton>)}
                                            {props?.permission?.create &&
                                                <Button
                                                    variant="contained"
                                                    sx={{ borderRadius: theme.palette.borderRadius }}
                                                    onClick={() => setOpenDialog(true)}
                                                >
                                                    {t("Add Amenities")}
                                                </Button>}

                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                            <TableWithPagination
                                heading={AmenitiesHeadingUnit}
                                rows={[]}
                                path={AmenitiesPath}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                tableType="no-side"
                                onClick={() => console.log("")}
                                dataType={[
                                    { type: ["text"], name: "amenityName" },
                                    { type: ["description"], name: "notes" },
                                    { type: ["text"], name: "chargeable" },
                                    { type: ["text"], name: "amount" },
                                    { type: ["text"], name: "period" },
                                    { type: ["text"], name: "booking" },
                                    { type: ["status"], name: "status" },
                                    { type: ["more"], icon: "icon" },

                                ]}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={0}
                                page={page}
                                limit={limit}
                                height={`calc(100vh - 395px)`}
                                view={props?.permission?.read}
                                edit={props?.permission?.update}
                                delete={props?.permission?.delete} />
                        </Box>
                    </div>
            }
            {/* filter drawer */}
            {
                drawer &&
                <FilterGenerator
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    components={[

                        {
                            component: "toggleButton",
                            value: filterData?.status,
                            options: [
                                { label: 'Active', value: true },
                                { label: 'Inactive', value: false },
                            ],
                            isMulti: true,
                            state_name: "status",
                            label: "Status",
                            // required:true
                        },
                        {
                            component: "toggleButton",
                            value: filterData?.chargeable,
                            options: [
                                { label: 'Yes', value: true },
                                { label: 'No', value: false },
                            ],
                            isMulti: true,
                            state_name: "chargeable",
                            label: "Chargeable",
                        },
                    ]}
                    onApply={(value) => onApplyFilter(value)}
                />
            }
            {/* add and edit  and view */}
            <AlertDialog
                md
                header={unitAmenities?.view ? t("View Amenities") : unitAmenities?.edit ? t("Edit Amenities") : t("Add Amenities")}
                onClose={OncloseModal}
                open={openDialog}
                component={
                    <>
                        <div>
                            <AddAmenitiesPopup click={getUnitAmenities}
                                currency={props?.currency} companyId={companyId}
                                data={unitAmenities} closeModel={OncloseModal} type={"unit"} />
                        </div>
                    </>
                }
            />
        </>
    );
};
export default withNamespaces("amenities")(UnitAmenities)
