import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../../utils";
export const resultStyles = makeStyles((theme) => ({
    root: {
        padding: 16,
        backgroundColor: '#FFFFFF',
        borderTop: '1px solid #E4E8EE',
        marginTop: 0
    },
    searchRoot: {
        borderBottom: "1px solid #E4E8EE",
        backgroundColor: "white",
        padding: "6px 8px 14px 8px"
    },
    cardRoot: {
        backgroundColor: "white",
        position: "relative",
        borderRight: "1px solid #E4E8EE",
    },
    total: {
        fontSize:"1rem",
        color: "#4E5A6B",
        fontFamily: Bold,
        marginLeft: "12px",
        marginTop: "12px"
    },

    popoverroot: {

        "& .MuiPopover-paper": {
            borderRadius: theme.palette.borderRadius,
            boxShadow: "none",
            border: 0,
            backgroundColor: "transparent",
            position: "absolute",
            top: "0px"
        }
    },
    dialogRoot: {
        width: "500px",
        height: `calc(100vh - 170px)`,
        overflow: "hidden",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 10px #0000000F",
        border: "1px solid #E4E8EE",
        marginLeft: "10px",
        backgroundColor: "white"
    },
    dialogclose: {
        borderRadius: theme.palette.borderRadius,
        border: "1px solid gray",
        backgroundColor: "white",
        marginLeft: "8px",
        "&:hover": {
            borderRadius: theme.palette.borderRadius,
            border: "1px solid gray",
            backgroundColor: "white",
        }

    },
    shortlistbtnlength: {
        boxShadow: "none",
        padding: "8px 16px",
        position: "absolute",
        bottom: "10px",
        right: "10px"
    },
    shortlistbtnlengthArabic: {
        boxShadow: "none",
        padding: "8px 16px",
        position: "absolute",
        bottom: "10px",
        left: "10px"
    },
    startbtn: {
        "& .MuiButton-startIcon": {
            marginLeft: "4px !important"
        }
    },
    noData: {
        textAlign: "center",
        marginTop: "10%",
        color: "#091B29",
        fontFamily: Bold,
        fontSize: "1.25rem"
    },
    existingLeadRoot: {
        position: "absolute",
        bottom: 100,
        right: 0
    }


}));