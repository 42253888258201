import React from 'react';
import { useStyles } from "../styles";
import { Box, Button, Typography } from "@mui/material";

export const CancelDialog = ({
    t,
    cancel = () => false,
    termination = () => false

}) => {
    const classes = useStyles()
    return (
        <div>
            <Box className={classes.createnewsection}>
                <Box>
                    {" "}
                    <img
                        src="\images\deletebig.svg"
                        alt="close"
                    />
                </Box>
                <Box>
                    <Typography
                        className={classes.modaltitle}
                
                    >
                        {t("Are you sure this will revert all the changes made and cancel the agreement?")}
                    </Typography>
                </Box>
            </Box>
            <Box display={'flex'} alignItems="center" mt={2}>
                <Button
                    variant="contained"
                    onClick={termination}
                    className={classes.btnSelction}
                    style={{padding:'16px'}}

                >
                    {t("yes")}
                </Button>
                <Box width={'16px'}/>
                <Button
                    className={classes.Cancel}
                    variant="outlined"
                    onClick={cancel}
                    style={{padding:'16px'}}
                >
                    {t("no")}
                </Button>
            </Box>
        </div>
    )
}