import React from "react"

export const TrackClockIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <g id="Group_103135" data-name="Group 103135" transform="translate(-8 -8)">
                <circle id="Ellipse_39518" data-name="Ellipse 39518" cx="12.5" cy="12.5" r="12.5" transform="translate(8 8)" fill="#5078e1" />
                <path id="icons8-clock" d="M11,4a7,7,0,1,0,7,7A7.008,7.008,0,0,0,11,4Zm0,1.05A5.95,5.95,0,1,1,5.05,11,5.942,5.942,0,0,1,11,5.05Zm-.533,1.742a.525.525,0,0,0-.517.533v3.85a.525.525,0,0,0,.154.371l1.75,1.75a.525.525,0,1,0,.742-.742l-1.6-1.6V7.325a.525.525,0,0,0-.533-.533Z" transform="translate(9.501 9.5)" fill="#fff" stroke="#fff" stroke-width="0.5" />
            </g>
        </svg>

    )
}