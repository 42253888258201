export const Path = [
    "imageUrl",
    "name",
    "gender",
    "mobileNo",
    "profession",
    "idType",
    "idNumber",
    "barred",
    "status"
]

export const Heading = (t) => [
    { title: "", field: "icon" },
    { title: t("Image"), field: "imageUrl" },
    { title: t("Name"), field: "name" },
    { title: t("Gender"), field: "gender" },
    { title: t("Mobile Number"), field: "mobileNo" },
    { title: t("Profession"), field: "profession" },
    // { title: "ID Type", field: "idType" },
    // { title: "ID Number", field: "idNumber" },
    { title: t("Barred"), field: "barred" },
    { title: t("Status"), field: "status", },
]

export const Type = [
    { type: ["more"], icon: "icon" },
    { type: ["logo"], name: "imageUrl" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "gender" },
    { type: ["text"], name: "mobileNo" },
    { type: ["text"], name: "profession" },
    // { type: ["text"], name: "idType" },
    // { type: ["text"], name: "idNumber" },
    { type: ["text"], name: "barred" },
    { type: ["status"], name: "status" },
]

export const StatusOptionList = (t) => [
    { label: t('Active'), value: true },
    { label: t('Inactive'), value: false },
]

export const GenderOptionList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
]