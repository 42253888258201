import * as React from "react"

export const TerminationIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={38} height={39} {...props}>
    <g data-name="icons8-cancel-subscription (1)">
      <path
        data-name="Path 97497"
        d="M5 37.5A4.505 4.505 0 0 1 .5 33V4.5h13V33a5.5 5.5 0 0 0 2.341 4.5Z"
        fill="#fff"
      />
      <path
        data-name="Path 97498"
        d="M13 5v28a5.976 5.976 0 0 0 1.532 4H5a4 4 0 0 1-4-4V5h12m1-1H0v29a5 5 0 0 0 5 5h14a5 5 0 0 1-5-5V4Z"
        fill="#e4e8ee"
      />
      <path
        data-name="Path 97499"
        d="M6 37.5v-.028A4.507 4.507 0 0 0 10 33V.5h26V33a4.505 4.505 0 0 1-4.5 4.5Z"
        fill="#fff"
      />
      <path
        data-name="Path 97500"
        d="M35 1v32a4 4 0 0 1-4 4H8a5 5 0 0 0 2-4V1h25m1-1H9v33a4 4 0 0 1-4 4v1h26a5 5 0 0 0 5-5V0Z"
        fill="#e4e8ee"
      />
      <path
        data-name="Path 97501"
        d="M14 5h17v3H14Zm10 6h7v1h-7Zm0 3h7v1h-7Zm0 3h7v1h-7ZM4 10h5v1H4Zm0 3h5v1H4Zm0 3h5v1H4Zm0 3h5v1H4Zm0 3h5v1H4Zm0 3h5v1H4Zm0 3h5v1H4Zm0 3h5v1H4Zm10-20h7v1h-7Zm0 3h7v1h-7Zm0 3h7v1h-7Zm0 3h7v1h-7Zm0 3h7v1h-7Zm0 3h7v1h-7Zm0 3h7v1h-7Zm0 3h7v1h-7Z"
        fill="#98a0ac"
      />
      <g data-name="Group 103163">
        <path
          data-name="Path 97502"
          d="M29 38.5a8.5 8.5 0 1 1 8.5-8.5 8.51 8.51 0 0 1-8.5 8.5Zm-2.712-3.667.711.279A5.476 5.476 0 0 0 34.112 28l-.279-.711ZM29 24.5a5.476 5.476 0 0 0-5.112 7.5l.279.711 7.545-7.545-.712-.278a5.454 5.454 0 0 0-2-.388Z"
          fill="#ffdcdc"
        />
        <path
          data-name="Path 97503"
          d="M29 22a8 8 0 1 1-8 8 8.009 8.009 0 0 1 8-8m-5.02 11.606 1.081-1.081 6.465-6.465 1.081-1.081-1.423-.558a5.978 5.978 0 0 0-7.762 7.762l.558 1.423M29 36a5.977 5.977 0 0 0 5.578-8.183l-.558-1.423-1.081 1.081-6.465 6.465-1.081 1.081 1.423.558A6 6 0 0 0 29 36m0-15a9 9 0 1 0 9 9 9 9 0 0 0-9-9Zm-4.647 10.819a4.978 4.978 0 0 1 6.466-6.466l-6.466 6.466ZM29 35a4.98 4.98 0 0 1-1.819-.353l6.465-6.465A4.95 4.95 0 0 1 34 30a5 5 0 0 1-5 5Z"
          fill="#ff4b4b"
        />
      </g>
      <g data-name="Group 103164">
        <path
          data-name="Path 97504"
          d="M20.059 29h-1.008A10.007 10.007 0 0 0 19 30h1a8.935 8.935 0 0 1 .059-1ZM35 22.019V23.3a9.053 9.053 0 0 1 1 1.044v-1.473a10.153 10.153 0 0 0-1-.852ZM19.461 27h1.062a8.925 8.925 0 0 1 .424-1h-1.106a9.85 9.85 0 0 0-.38 1Zm3.888 10h-1.478a10 10 0 0 0 1.155 1h1.864a8.962 8.962 0 0 1-1.541-1Zm-2.826-4a8.839 8.839 0 0 1-.292-1H19.2a9.843 9.843 0 0 0 .26 1Z"
          fill="#c5d4de"
        />
      </g>
    </g>
  </svg>
)
