import * as React from "react"
const Icon2Arabic = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={67.157}
    height={59.142}
    {...props}
  >
    <path
      fill="#fc0"
      d="M61.209 29.226A26.908 26.908 0 1 1 34.301 2.318a26.908 26.908 0 0 1 26.908 26.908"
    />
    <path
      fill="#fcdc59"
      d="M54.889 11.907a26.907 26.907 0 0 0-45.98 26.226q22.761-13.515 45.98-26.226M37.412 55.945a26.821 26.821 0 0 0 15.888-7.67c-4.2 2.019-8.392 4.062-12.588 6.092-1.1.531-2.2 1.051-3.3 1.578"
    />
    <path
      fill="#da8100"
      d="M54.336 28.8a20.456 20.456 0 1 1-20.46-20.456A20.455 20.455 0 0 1 54.336 28.8"
    />
    <path
      fill="#f0aa00"
      d="M54.908 29.653A20.181 20.181 0 1 1 34.727 9.471a20.18 20.18 0 0 1 20.181 20.182"
    />
    <path
      fill="#ff9340"
      d="m3.368 28.375 1.191 2.177 2.177 1.191-2.177 1.19-1.19 2.177-1.192-2.177L0 31.743l2.177-1.191Z"
    />
    <path
      fill="#ff9340"
      d="m9.558 6.63.6 1.106 1.108.606-1.108.606-.6 1.11-.606-1.108-1.108-.606 1.104-.608Z"
      opacity={0.5}
    />
    <path
      fill="#ff9340"
      d="m53.3 0 1.172 2.144 2.143 1.172-2.143 1.172L53.3 6.631l-1.172-2.143-2.143-1.173 2.143-1.172ZM55.71 51.137l.851 1.556 1.549.851-1.556.851-.844 1.556-.851-1.556-1.556-.851 1.556-.851Z"
    />
    <path
      fill="#ff9340"
      d="m65.562 30.148.564 1.031 1.031.564-1.031.564-.564 1.031-.564-1.031-1.031-.564 1.031-.564ZM15.016 55.951l.564 1.031 1.031.564-1.031.564-.564 1.031-.564-1.031-1.031-.564 1.031-.564ZM65.561 18.587l.194.354.354.194-.354.194-.194.354-.194-.354-.355-.194.355-.194ZM2.819 18.587l.194.354.355.194-.355.194-.194.354-.194-.354-.354-.194.355-.194Z"
      opacity={0.5}
    />
    <path
      fill="#ea9c00"
      d="M41.551 39.652H27.417v-2.8l6.671-7.112a16.453 16.453 0 0 0 2.032-2.62 4.209 4.209 0 0 0 .658-2.124 3.149 3.149 0 0 0-.694-2.159 2.5 2.5 0 0 0-1.982-.785 2.7 2.7 0 0 0-2.188.956 3.786 3.786 0 0 0-.8 2.513h-4.106a6.759 6.759 0 0 1 .9-3.441 6.337 6.337 0 0 1 2.542-2.443 7.726 7.726 0 0 1 3.723-.885 7.315 7.315 0 0 1 4.949 1.529 5.41 5.41 0 0 1 1.763 4.319 6.932 6.932 0 0 1-.793 3.115 17.312 17.312 0 0 1-2.719 3.7l-4.687 4.937h8.864Z"
    />
    <path
      fill="#fc0"
      d="M40.268 39.652H26.134v-2.8l6.671-7.112a16.453 16.453 0 0 0 2.032-2.62 4.209 4.209 0 0 0 .658-2.124 3.149 3.149 0 0 0-.694-2.159 2.5 2.5 0 0 0-1.982-.785 2.7 2.7 0 0 0-2.188.956 3.786 3.786 0 0 0-.8 2.513h-4.106a6.759 6.759 0 0 1 .9-3.441 6.337 6.337 0 0 1 2.542-2.443 7.726 7.726 0 0 1 3.723-.885 7.315 7.315 0 0 1 4.949 1.529 5.41 5.41 0 0 1 1.763 4.319 6.932 6.932 0 0 1-.793 3.115 17.312 17.312 0 0 1-2.719 3.7l-4.687 4.937h8.864Z"
    />
  </svg>
)
export default Icon2Arabic
