import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import CityProperty from '../../assets/cityproperty';
import { Bold, SemiBold } from '../../utils';
import { AuthContext } from '../../contexts';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: "0px 3px 6px #53668523",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "white",
    },

    title: {
        fontSize:"0.8125rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
        minHeight:"37px"

    },
    count: {
        fontSize: "1.25rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,


    },
    viewdetails: {
        fontSize:"0.75rem",

        color: theme.palette.primary.main,
        fontFamily: SemiBold,


    },

    image: {
        width: 55,
        height: 55,
        backgroundColor: (props) => props.data.color,

    },
    bottomLine: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F1F7FF", padding: "8px", borderRadius: "0px 0px 4px 4px",
        justifyContent: "space-between",
        cursor: "pointer",

    }
}));

export const OtherInfoCard = (props) => {

    const auth = React.useContext(AuthContext)
    const classes = useStyles(props);
    const {t = () => false} = props;
    return (
        <>
            <Box className={classes.card}>
                <Grid container spacing={1} padding="8px">
                    <Grid item xs={12}>
                        <Box display={"flex"} alignItems="center" padding="8px">
                            <Box>
                                <Avatar className={classes.image} >
                                    <CityProperty color="#F5FFFB" />
                                </Avatar>
                            </Box>
                            <Box sx={{ marginInlineStart: "10px" }}>
                                <Typography variant="body1" className={classes.count}>
                                    {props.data.count}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.title}>
                                    {t(props.data.name)}
                                </Typography>
                            </Box>
                        </Box>

                    </Grid>
                </Grid>

                <Box className={classes.bottomLine}
                    onClick={() => {
                        if (props?.unit) {
                            props?.onClick()
                        } else {
                            props?.open(props?.data)
                            props?.setIndex(props?.index)
                        }
                    }}
                >
                    <Box>
                        <Typography variant="subtitle2" className={classes.viewdetails}>
                            {props.data.view}
                        </Typography>
                    </Box>

                    <Box textAlign={"right"} sx={{ transform: auth?.auth?.auth?.language === "ar" ? `rotate(180deg)` : '' }} >
                        <ChevronRightIcon style={{ color: "#5078E1", }} />

                    </Box>
                </Box>
            </Box>
        </>
    );
}
