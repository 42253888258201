import React from "react"

export const WFHIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_106769" data-name="Group 106769" transform="translate(-3837 23563)">
    <g id="Rectangle_55656" data-name="Rectangle 55656" transform="translate(3837 -23563)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <path d="M0,0H24a0,0,0,0,1,0,0V22a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" stroke="none"/>
      <path d="M1,.5H23a.5.5,0,0,1,.5.5V22A1.5,1.5,0,0,1,22,23.5H1A.5.5,0,0,1,.5,23V1A.5.5,0,0,1,1,.5Z" fill="none"/>
    </g>
    <path id="Path_99696" data-name="Path 99696" d="M12.513,16H2.256A2.259,2.259,0,0,1,0,13.744v-6.6A3.06,3.06,0,0,1,1.173,4.726L7,.132a.616.616,0,0,1,.762,0L13.6,4.726a3.059,3.059,0,0,1,1.173,2.417v6.6A2.259,2.259,0,0,1,12.513,16ZM3.569,13.21a.369.369,0,0,0,0,.738H11.2a.369.369,0,1,0,0-.738h-.437a1.089,1.089,0,0,0,.068-.369V10.133A1.109,1.109,0,0,0,9.723,9.026H5.046a1.109,1.109,0,0,0-1.108,1.108v2.708a1.08,1.08,0,0,0,.068.369ZM7.385,4.1A1.969,1.969,0,1,0,9.354,6.072,1.971,1.971,0,0,0,7.385,4.1Zm0,7.877a.492.492,0,1,1,.493-.492A.493.493,0,0,1,7.385,11.979Z" transform="translate(3842 -23559)" fill="#fe5d9f"/>
  </g>
</svg>

)