import { ActivePropertyIcon } from "../../assets";
import { BookingComponent } from "../../assets/leasemanagerdashboard";

export const propertyDetailsFunction = (data) => {
  let propertyDetails = [
    {
      count: data?.active_properties ?? 0,
      image: <ActivePropertyIcon />,
      sub: "Active Properties",
    },
    {
      count: data?.active_blocks ?? 0,
      image: "/images/propertdashboard/block.svg",
      sub: "Blocks",
      image_type: true,
    },
    {
      count: data?.active_floors ?? 0,
      image: "/images/propertdashboard/floor.svg",
      sub: "Floors",
      image_type: true,
    },
  ];
  let unitDetails = [
    {
      count: data?.active_units ?? 0,
      image: "/images/propertdashboard/activeunits.png",
      sub: "Active Unit",
      image_type: true,
    },
    {
      count: data?.vacant_units ?? 0,
      image: "/images/propertdashboard/vacant.png",
      sub: "Vacant",
      image_type: true,
    },
    {
      count: data?.occupied_units ?? 0,
      image: "/images/propertdashboard/occupied.png",
      sub: "Occupied",
      image_type: true,
    },
  ];
  let residentalDetails = [
    {
      count: data?.residents ?? 0,
      image: "/images/propertdashboard/resident.png",
      sub: "Residents",
      image_type: true,
    },
    {
      count: data?.owners ?? 0,
      image: "/images/propertdashboard/owner.png",
      sub: "Owners",
      image_type: true,
    },
    {
      count: data?.co_residents ?? 0,
      image: "/images/propertdashboard/co-owner.png",
      sub: "Co- Residents",
      image_type: true,
    },
  ];
  let soldDetails = [
    {
      count: data?.sale_units ?? 0,
      image: "/images/propertdashboard/saleunit.png",
      sub: "Sale Units",
      image_type: true,
    },
    {
      count: data?.sold_units ?? 0,
      image: "/images/propertdashboard/soldunit.png",
      sub: "Sold Units",
      image_type: true,
    },
    {
      count: data?.construction_units ?? 0,
      image: "/images/propertdashboard/constraction.png",
      sub: "Construction",
      image_type: true,
    },
  ];
  let areaDetails = [
    {
      area: "Total Area",
      data: `${data?.total_area ?? 0} ${data?.uom}`,
      is_count: true,
    },
    {
      area: "Carpet Area",
      data: `${data?.carpet_area ?? 0} ${data?.uom}`,
      is_count: true,
    },
  ];
  let unitStatus = [
    {
      count: data?.reserved_units ?? 0,
      image: <BookingComponent />,
      sub: "Reserved",
    },
    {
      count: data?.missing_pricing_units ?? 0,
      image: "/images/propertdashboard/missiongprice.png",
      sub: "Missing Pricing",
      image_type: true,
    },
    {
      count: data?.booked_units ?? 0,
      image: "images/resevation.svg",
      sub: "Booked",
      image_type: true,
    },
    {
      count: data?.listing_units ?? 0,
      image: "/images/propertdashboard/listed.png",
      sub: "Listed",
      image_type: true,
    },
  ];
  let propertyType = data?.property_type?.map((x) => {
    return {
      name: x?.group_name ?? "",
      count: parseInt(x?.count) ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let unitstatusGraph = data?.unit_status?.map((x) => {
    return {
      name: x?.status ?? "",
      count: parseInt(x?.count) ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let unitType = data?.unit_type?.map((x) => {
    return {
      name: x?.name ?? "",
      count: parseInt(x?.count) ?? "",
      fill: `#${Math.random().toString(16).substring(2, 8)}`,
    };
  });
  let unit_catagory = data?.unit_category ?? {};
  let residential_vs_commerical = [
    {
      name: "Residential",
      count: parseInt(data?.residential_vs_commerical?.residential_units) ?? 0,
      fill: `#F3E137`,
      // preffix: data?.uom,
    },
    {
      name: "Commerical",
      count: parseInt(data?.residential_vs_commerical?.commerical_units) ?? 0,
      fill: `#5AC782`,
      // preffix: data?.uom,
    },
  ];
  let vacant_unit_by_property = {
    labels: data?.property_unit_vacant?.map((x) => x?.name) ?? [],
    datasets: [
      {
        label: "Vacant Units",
        backgroundColor: "#58D0E0",
        barPercentage: 0.5,
        barThickness: 20,
        maxBarThickness: 20,
        data: data?.property_unit_vacant?.map((x) => x?.units),
      },
    ],
  };
  let property_occupancy_trends = data?.property_occupancy_trends;
  let residential_vs_commerical_total =
    parseInt(data?.residential_vs_commerical?.residential_units) +
    parseInt(data?.residential_vs_commerical?.commerical_units);

  let property_unit_occupancy = data?.property_unit_occupancy ?? {};

  const final = {
    propertyDetails,
    unitDetails,
    residentalDetails,
    soldDetails,
    areaDetails,
    unitStatus,
    propertyType,
    unitstatusGraph,
    unitType,
    unit_catagory,
    residential_vs_commerical,
    property_unit_occupancy,
    vacant_unit_by_property,
    residential_vs_commerical_total,
    property_occupancy_trends,
  };
  return final;
};

export const areaFunction = (data) => {
  let areaDetails = [
    {
      name: "Residential",
      count: data?.residential_unit ?? 0,
      fill: `#F3E137`,
      preffix: data?.uom,
    },
    {
      name: "Commerical",
      count: data?.commercial_unit ?? 0,
      fill: `#5AC782`,
      preffix: data?.uom,
    },
  ];
  let totalArea = data?.total ?? 0;
  const final = {
    areaDetails,
    totalArea,
  };
  return final;
};
