import { config } from "../../config"
import { NetworkCall } from "../../networkcall"
import { AlertProps, NetWorkCallMethods } from "../../utils"
import {SelectBox} from "../select"
import {BackdropContext,AlertContext} from "../../contexts"
import React from "react"

export const RenewalSelect = ({ val }) => {

    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);


    const onChange=(value)=>{


        if(value?.value === "Price Index"){

            backdrop.setBackDrop({
                ...backdrop,
                open: true,
                message: "Checking CPI Index...",
                isOnclose:false
              });

              NetworkCall(
                `${config.api_url}/client/settings/get`,
                NetWorkCallMethods.get,
                {},
                null,
                true,
                false
              ).then((res)=>{

                if(res?.data?.settings?.cpi_percentage){
                    val?.onChange(value)
                }else{
                    alert.setSnack({
                                open: true,
                                msg: "CPI value not configured in client settings",
                                severity: AlertProps.severity.info,
                    });
                }

                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                    isOnclose:false
                  });

              })
              .catch((err)=>{
                 console.log(err)
              })

        }else{
            val?.onChange(value)
        }
    
        
    }

    return (
        <SelectBox
            menuPlacement={val?.menuPlacement}
            parentID={val?.id}
            isRequired={val?.isRequired}
            label={val?.label}
            placeholder={val?.placeholder}
            value={val.value}
            zIndex={val?.zIndex}
            onChange={onChange}
            isError={val?.error?.length > 0}
            errorMessage={val?.error}
            isReadOnly={val?.isReadonly}
            isPaginate={val?.isPaginate}
            loadOptions={val?.loadOptions}
            loading={val?.loading}
            debounceTimeout={800}
            options={val?.options ?? []}
            key={val?.key}
            menuOptionHeight={val?.menuOptionHeight}
            fontFamily={val?.fontFamily}
        />
    )
}