import React from "react";
import { withNavBars } from "../../HOCs";
import  AmenitiesMaster  from "./amenitiesMaster";

class AmenitiesMasterParent extends React.Component {
    render() {
        return <AmenitiesMaster  {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AmenitiesMasterParent, props);
