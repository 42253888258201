import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold, remCalc } from "../../../utils";

export const EmployeeCardStyle = makeStyles((theme) => ({
    box: {
        borderRadius: 6,
        border: `1px solid ${theme.palette.border.secondary}`,
        background: "#fff",
        padding: "8px"
    },
    avatar: {
        height: 40,
        width: 40,
        borderRadius: 4
    },
    title: {
        fontSize: remCalc(14),
        fontFamily: Bold
    },
    subTitle: {
        fontSize: remCalc(12),
        fontFamily: Regular,
        color: theme.typography.color.tertiary
    },
    changeText: {
        fontSize: remCalc(12),
        fontFamily: Bold,
        color: theme.palette.info.main,
        cursor: "pointer"
    },
    qrBox: {
        borderRadius: 6,
        border: `1px solid ${theme.palette.border.secondary}`,
        background: "#fff",
        padding: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    qrText: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary
    }
}))

export const CalendarChangeBtnStyle = makeStyles((theme) => ({
    arrowBox: {
        height: 40,
        width: 40,
        borderRadius: "50%",
        border: `1px solid ${theme.palette.border.secondary}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    arrowColor: {
        color: theme.typography.color.tertiary
    },
    dateText: {
        fontSize: remCalc(16),
        fontFamily: Bold,
        color: theme.typography.color.primary
    }
}))

export const CalendarStyle = makeStyles((theme) => ({
    dayText: {
        fontSize: remCalc(12),
        color: theme.typography.color.tertiary,
        fontFamily: Regular,
        textTransform: "uppercase"
    },
    date_outline: {
        color: "black",
        border: `1px solid ${theme.palette.border.secondary}`,
        height: 120,
        width: "100%"
    },
    calDates: {
        textAlign: "center",
        overflow: "auto",
        height: `calc(100vh - 250px)`,
        [theme.breakpoints.only('md')]: {
            height: `calc(100vh - 250px)`,
        },
        [theme.breakpoints.only('sm')]: {
            height: `calc(100vh - 250px)`,
        },

    },
    dateText: {
        fontSize: remCalc(18),
        fontFamily: Bold,
        color: theme.typography.color.secondary
    },
    nonMonthDate: {
        display: "none"
    },
    listBox: {
        overflow: "auto",
        width: "100%",
        minHeight: 30,
        maxHeight: 80,
    },
    listText: {
        fontSize: remCalc(12),
        fontFamily: Bold,
        color:"#CE6F6F",
        width: "100%",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
        overflow: "hidden",
        alignItems: "center"
    },
    contentBox: {
        overflow: "hidden",
        height: "100%",
        width: "100%"
    },
    viewMoreText: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: theme.palette.info.main,
        padding: "4px 0px",
        cursor: "pointer"
    },
    successTick: {
        color: theme.palette.success.main,
        fontSize: 14
    },
    dutyLocationText: {
        fontSize: remCalc(12),
        fontFamily: Bold,
        color: theme.palette.primary.main,
        textTransform: "uppercase"
    },
    checkInOutText: {
        fontSize: remCalc(12),
        fontFamily: Bold,
        color: theme.typography.color.tertiary,
    },
    checkInOutTime: {
        fontSize: remCalc(12),
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    locationName: {
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    timeDifference:{
        backgroundColor:"#FFD8D880",
        display:"flex",
        justifyContent:"center",
        padding:"5px",
        borderRadius:theme.palette.borderRadius
    }
}))

export const ChangeEmployeeStyle = makeStyles((theme) => ({
    card: {
        borderRadius: 4,
        border: `1px solid ${theme.palette.border.secondary}`,
        padding: "16px",
        cursor: "pointer"
    },
    selectedCard: {
        borderRadius: 4,
        border: `1px solid ${theme.palette.info.main}`,
        padding: "16px",
        background: theme.palette.info.light,
        cursor: "pointer"
    },
    title: {
        fontSize: remCalc(14),
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subTitle: {
        fontSize: remCalc(12),
        fontFamily: Regular,
        color: theme.typography.color.secondary
    }
}))