import React from "react"

export const MoveOutIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_110200" data-name="Group 110200" transform="translate(-184 -328.5)">
    <rect id="Rectangle_55241" data-name="Rectangle 55241" width="32" height="32" rx="2" transform="translate(184 328.5)" fill="#c7eef0"/>
    <g id="Group_111486" data-name="Group 111486">
      <g id="Rectangle_56607" data-name="Rectangle 56607" transform="translate(189 332)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-export" d="M9.316,5A2.318,2.318,0,0,0,7,7.316V18.684A2.318,2.318,0,0,0,9.316,21h5.053a2.318,2.318,0,0,0,2.316-2.316V13.632H10.579a.632.632,0,0,1,0-1.263h6.105V7.316A2.318,2.318,0,0,0,14.368,5Zm7.368,7.368v1.263H20L18.132,15.5a.632.632,0,1,0,.893.893l2.947-2.947a.632.632,0,0,0,0-.893L19.025,9.606a.632.632,0,1,0-.893.893L20,12.368Z" transform="translate(186 331)" fill="#6dafb3"/>
    </g>
  </g>
</svg>
)