import {
  ChargeableList,
  PaymentPeriodList,
  RevenueTypeList,
  ValueBasisList1,
} from "../../utils/pricingComponent";
import { max, rec, min } from "../../utils";

export const defaultAddNewState = {
  formType: "question",
  question: "",
  category: "",
  categoryQuestionSet: {
    isPrimary: false,
    isOneTime: false,
    isRefundable: false,
    isQuantity: false,
    isParking: false,
  },
  info: "",
  primaryColor: "",
  secondaryColor: "",
  id: "",
  revenueType: RevenueTypeList[0],
  pricingComponent: "",
  taxGroup: "",
  chargeable: ChargeableList[0],
  valueBasis: ValueBasisList1[0],
  value: "",
  currency: "",
  currencyCode: "",
  uom: "",
  paymentPeriod: PaymentPeriodList[0],
  quantity: "",
  companyID: "",
  propertyID: "",
  unitID: "",
  countryID: "",
  isChargeable: false,
  minValue: "",
  recommanded: "",
  maxValue: "",
  error: {
    pricingComponent: "",
    taxGroup: "",
    value: "",
    quantity: "",
  },
};

// calulate actual value
const calculateActualValue = (value, details) => {
  if (details?.value_basis_type === "Amount") {
    return value;
  } else {
    const result = Number(details?.value) * Number(details?.total_area);
    return result;
  }
};

export const constractData = (t, data, company) => {
  const propertyDetails = [
    {
      catagory: t("Property_Type"),
      result: "Appartment",
      divider: data?.property_type ?? "-",
    },
    {
      catagory: t("Unit_Type"),
      result: "2BHK",
      divider: data?.unit_type ?? "-",
    },
    {
      catagory: t("Pricing"),
      result: "Lifetime Settlement",
      divider: data?.component_name ?? "-",
    },
    {
      catagory: t("Revenue"),
      result: "Lease",
      divider: data?.revenue_type ?? "-",
    },
  ];
  const pricingValues = [
    {
      catagory: t("Maximum"),
      result: `${company?.currency_symbol} ${data?.max ?? 0}` ?? "-",
      divider: 4,
    },
    {
      catagory: t("Recommended"),
      result:
        `${company?.currency_symbol} ${data?.recommended_value ?? 0}` ?? "-",
      divider: 4,
    },
    {
      catagory: t("Minimum"),
      result: `${company?.currency_symbol} ${data?.min ?? 0}` ?? "-",
      divider: 4,
    },
  ];
  const pricingProgress = [
    {
      catagory: t("SqYard"),
      result:
           data?.min !== null
          ? max(
              data?.max,
              data?.value_basis_type === "Amount"
                ? data?.max ?? 0
                : data?.max ?? 0 * data?.total_area
            )
          : 0,
      divider: 4,
      color: "#FF4B4B",
      progress: "error",
    },
    {
      catagory: t("SqYard"),
      result:
        data?.max > data?.recommended_value && data?.min !== null
          ? rec(
              data?.max,
              data?.value_basis_type === "Amount"
                ? data?.recommended_value ?? 0
                : data?.recommended_value ?? 0 * data?.total_area
            )
          : 0,
      divider: 4,
      color: "#5AC782",
      progress: "success",
    },
    {
      catagory: t("SqYard"),
      result:
        data?.max > data?.min && data?.min !== null
          ? min(
              data?.max,
              data?.value_basis_type === "Amount"
                ? data?.min ?? 0
                : data?.min ?? 0 * data?.total_area
            )
          : 0,
      divider: 4,
      color: "#FF9340",
      progress: "warning",
    },
  ];
  const amountDetails = [
    {
      title: t("BUILT_UP"),
      contant: `${data?.carpet_area ?? 0} ${company?.uom?.label}` ?? "-",
    },
    {
      title: t("SUPER_BUILT_UP"),
      contant: `${data?.total_area ?? 0} ${company?.uom?.label}` ?? "-",
    },
    {
      title: t("TOTAL_BALCONY"),
      contant: `${data?.balcony_area ?? 0} ${company?.uom?.label}` ?? "-",
    },
  ];

  return {
    propertyDetails,
    pricingValues,
    pricingProgress,
    amountDetails,
    property_name: data?.property_name,
    property_logo: data?.property_logo,
    door_no: data?.door_no,
    street_1: data?.street_1,
    street_2: data?.street_2,
    landmark: data?.landmark,
    city: data?.city,
    district: data?.district,
    state: data?.state,
    total_area: data?.total_area ?? 0,
    total_baths: data?.total_baths ?? 0,
    total_bed_rooms: data?.total_bed_rooms ?? 0,
    total_rooms: data?.total_rooms ?? 0,
    zipcode: data?.zipcode,
    email: data?.email,
    furnishing: data?.furnishing,
    actual_value: calculateActualValue(data?.value ?? 0, data),
  };
};
