import React from 'react'
import { componentsStyle } from './style'
import { Box, Switch, Typography } from '@mui/material'
import { styled } from "@mui/material/styles";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: "1px 0px",
        transitionDuration: "300ms",
        color: "#98A0AC",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#5AC782",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#EEF9EE",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 18,
        height: 18,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E4E8EE" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));
export const PortalCard = (props) => {
    const { t } = props
    const classes = componentsStyle(props)
    return (
        <>
            <Box className={classes.parentBox}>
                <Box className={classes.imgParent}>
                    <div>
                    {props?.data?.icons}
                    </div>
                    <Box className={classes.headerParent}>
                        <Typography className={classes.header}>{props?.data?.name}</Typography>
                        <span className={classes.subHearder}>{props?.data?.is_purchased ? t("Purchased") : t("Not Purchased")}</span>
                    </Box>
                </Box>
                <Box>
                    <Typography className={classes.text}>{props?.data?.current} / {props?.data?.total}</Typography>
                    <Typography className={classes.text2}>{t("Unit listed")}</Typography>
                </Box>
                <Box>
                    <IOSSwitch checked={props?.data?.is_active} />
                </Box>
            </Box>
        </>
    )
}