import React from "react"

export const PersonalLeaveIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_106897" data-name="Group 106897" transform="translate(-3837 23529)">
    <g id="Rectangle_55657" data-name="Rectangle 55657" transform="translate(3837 -23529)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <path d="M0,0H24a0,0,0,0,1,0,0V22a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" stroke="none"/>
      <path d="M1,.5H23a.5.5,0,0,1,.5.5V22A1.5,1.5,0,0,1,22,23.5H1A.5.5,0,0,1,.5,23V1A.5.5,0,0,1,1,.5Z" fill="none"/>
    </g>
    <path id="icons8-account" d="M14.4,4a4,4,0,1,0,4,4A4.009,4.009,0,0,0,14.4,4ZM9.8,13.6A1.809,1.809,0,0,0,8,15.4v.48a3.627,3.627,0,0,0,1.884,2.957A8.418,8.418,0,0,0,14.4,20a8.418,8.418,0,0,0,4.516-1.163A3.627,3.627,0,0,0,20.8,15.88V15.4A1.809,1.809,0,0,0,19,13.6Z" transform="translate(3835 -23529)" fill="#78b1fe"/>
  </g>
</svg>


)