import * as React from "react"
const UnitListingIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    fill="#1f74ea"
                    stroke="#707070"
                    d="M287 21h24v24h-24z"
                    data-name="Rectangle 56641"
                />
            </clipPath>
        </defs>
        <g data-name="Group 110543" transform="translate(-152 -317)">
            <rect
                width={48}
                height={48}
                fill="#e1fcff"
                data-name="Rectangle 56639"
                rx={8}
                transform="translate(152 317)"
            />
            <g
                clipPath="url(#a)"
                data-name="Mask Group 93119"
                transform="translate(-123 308)"
            >
                <g
                    fill="#30afce"
                    data-name="icons8-house (1)"
                    transform="translate(289 24)"
                >
                    <path
                        d="M16 18H4a3 3 0 0 1-3-3V7h2l7-5 7 5h2v8a3 3 0 0 1-3 3Z"
                        data-name="Path 100168"
                        opacity={0.35}
                    />
                    <path
                        d="M18 0H2a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h2.172a2 2 0 0 0 1.414-.586l2.782-2.782a2.319 2.319 0 0 1 3.273-.008l2.825 2.8A2 2 0 0 0 15.873 9H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z"
                        data-name="Path 100169"
                    />
                    <path d="M8 13h4v5H8z" data-name="Rectangle 56640" />
                    <circle
                        cx={2}
                        cy={2}
                        r={2}
                        data-name="Ellipse 129916"
                        transform="translate(8 11)"
                    />
                    <path
                        d="M16.5 14h-2a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5Z"
                        data-name="Path 100170"
                    />
                    <path
                        d="M5.5 14h-2a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5Z"
                        data-name="Path 100171"
                    />
                </g>
            </g>
        </g>
    </svg>
)
export default UnitListingIcon
