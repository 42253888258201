import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import // AlertContext,  // BackdropContext
  "../../contexts";
import {
  CardDetails,
} from "../propertymanagerdashboard/components";
import GraphComponent from "../propertymanagerdashboard/components/graphcomponent";
import { dashboardStyles } from "./style";
// import { ActivePropertyIcon } from "../../assets";
import moment from "moment-timezone";
import { DialogDrawer } from "../../components";

import { NewLoader } from "../../components/newLoader";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  NetWorkCallMethods,
  teamHead,
  teamPath,
  teamPerformHead,
  teamPerformPath
} from "../../utils";

import { constructBasicStatsData, constructLeadCountData, constructOpportunityData, constructOpportunityRevenueData, constructOpportunityStatusData, constructPerformanceTeamsByMemberData, constructPerformanceTeamsData } from "./common";
import { BackendRoutes } from "../../router/routes";
import { withNamespaces } from "react-i18next";


const scaleLine = {
  y: {
    ticks: {
      // Include a dollar sign in the ticks
      callback: function (value) {

        return value;
      },
    },
    stacked: true,
    grid: {
      // drawBorder: false,
      display: false,
    }
  },
  x: {
    stacked: true,
    grid: {
      // drawBorder: false,
      display: false,
    }
  },
  xAxes: [
    {
      gridLines: { display: false },
    },
  ],
  yAxes: [
    {
      gridLines: {
        drawBorder: false,
      },
      ticks: {
        min: 30,
      },
    },
  ],
};

const LeaseManagerDashboard = (props) => {


  const { t } = props;
  const options = [{ label: "Status", value: "status" }, { label: "Source", value: "source" }]
  const table_limit = 10
  const classes = dashboardStyles();

  // useContext
  const alert = React.useContext(AlertContext);

  // useState

  // const [details, setDetails] = useState({});
  const [stats_state, set_stats_state] = useState({});
  const [lead_count_state, set_lead_count_state] = useState({});
  const [opportunity_state, set_opportunity_state] = useState({});
  const [opportunity_status_state, set_opportunity_status_state] = useState({});
  const [opportunity_revenue_state, set_opportunity_revenue_state] = useState({});
  const [performance_teams_by_member_state, set_performance_teams_by_member_state] = useState([]);
  const [performance_teams_state, set_performance_teams_state] = useState([]);
  const [zoom, setZoom] = useState(false);
  const [zoomGraph, setZoomGraph] = useState("");
  const [loader, setLoader] = useState(true);
  const [selectedValue, setSelectedvalue] = useState({
    lead_stats_type: options[0],
    opportunity_stats_type: options[0],
    team: props?.selectedTeams ?? ""
  })
  const [table_offset, set_table_offset] = React.useState({ by_member: 0, by_team: 0, })
  const zoomGraphFunction = (e) => {
    setZoomGraph(e);
    setZoom(true);
  };

  // close popup
  const handleCloseModule = () => {
    setZoom(false);
  };

  // console.log("zoomGraph", zoomGraph)

  const getInitialData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setSelectedvalue({
          lead_stats_type: options[0],
          opportunity_stats_type: options[0],
          team: props?.selectedTeams ?? ""
        })
        await Promise.all([

          // getDetails(),
          getBasicStats(),
          getLeadCountStats(),
          getOpportunityStats(),
          getOpportunityStatusStats(),
          getOpportunityRevenueStats(),
          getPerformanceTeamsByMemberStats(table_offset?.by_member, table_limit, props?.selectedTeams),
          getPerformanceTeamsStats(table_offset?.by_team, table_limit, props?.selectedTeams)
        ])
        setLoader(false)
      } catch (error) {
        reject(error);
      }
    })
  }


  // getDetails
  // const getDetails = () => {
  //   let teamsId = props?.selectedTeams?.value;
  //   const payload = {
  //     company_id: props?.selectedcompany?.value ?? "",
  //     start_date: `${moment(props?.calandervalue?.value?.from_date).format("YYYY-MM-DD")} 00:00:00`,
  //     end_date: `${moment(props?.calandervalue?.value?.to_date).format("YYYY-MM-DD")} 23:59:59`,
  //   };
  //   if (teamsId !== "noteams") { payload["team"] = teamsId }
  //   NetworkCall(
  //     `${config.api_url}/dashboard/lead/sale_dashboard`,
  //     NetWorkCallMethods.post,
  //     payload, null, true, false
  //   ).then((res) => {
  //     const result = constructData(res?.data?.data);
  //     setDetails(result);
  //   }).catch((error) => {
  //     alert.setSnack({
  //       ...alert, open: true, msg: t("Some Thing Went Wrong"),
  //       severity: AlertProps.severity.error,
  //     });
  //   });
  // };

  // get basic stats
  const getBasicStats = () => {
    let teamsId = props?.selectedTeams?.value;
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      from_date: `${moment(props?.calandervalue?.value?.from_date).format("YYYY-MM-DD")}`,
      to_date: `${moment(props?.calandervalue?.value?.to_date).format("YYYY-MM-DD")}`,
    };
    if (teamsId !== "noteams") { payload["team_id"] = teamsId }
    NetworkCall(
      `${config.api_url}${BackendRoutes.sales_dashboard}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructBasicStatsData(res?.data?.data, t);
      set_stats_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // get basic stats
  const getLeadCountStats = (lead_stats_type) => {
    let teamsId = props?.selectedTeams?.value;
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      from_date: `${moment(props?.calandervalue?.value?.from_date).format("YYYY-MM-DD")}`,
      to_date: `${moment(props?.calandervalue?.value?.to_date).format("YYYY-MM-DD")}`,
      type: lead_stats_type?.value ?? "status"
    };
    if (teamsId !== "noteams") { payload["team_id"] = teamsId }
    NetworkCall(
      `${config.api_url}${BackendRoutes.sales_dashboard_proxy_lead}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructLeadCountData(res?.data?.data);
      set_lead_count_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // get opportunity stats
  const getOpportunityStats = (opportunity_stats_type) => {
    let teamsId = props?.selectedTeams?.value;
    const payload = {
      company_id: props?.selectedcompany?.value ?? "",
      from_date: `${moment(props?.calandervalue?.value?.from_date).format("YYYY-MM-DD")}`,
      to_date: `${moment(props?.calandervalue?.value?.to_date).format("YYYY-MM-DD")}`,
      type: opportunity_stats_type?.value ?? "status"
    };
    if (teamsId !== "noteams") { payload["team_id"] = teamsId }
    NetworkCall(
      `${config.api_url}${BackendRoutes.sales_dashboard_oppertunity}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructOpportunityData(res?.data?.data);
      set_opportunity_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // get opportunity status stats
  const getOpportunityStatusStats = () => {
    let teamsId = props?.selectedTeams?.value;
    const payload = {
      company_id: props?.selectedcompany?.value ?? "", type: "Status",
      year: `${parseInt(moment(props?.calandervalue?.value?.from_date).format("YYYY"))}`,
    };
    if (teamsId !== "noteams") { payload["team_id"] = teamsId }
    NetworkCall(
      `${config.api_url}${BackendRoutes.sales_dashboard_oppertunity_revenue}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructOpportunityStatusData(res?.data?.data, t);
      set_opportunity_status_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // get opportunity status stats
  const getOpportunityRevenueStats = () => {
    let teamsId = props?.selectedTeams?.value;
    const payload = {
      company_id: props?.selectedcompany?.value ?? "", type: "Revenue",
      year: `${parseInt(moment(props?.calandervalue?.value?.from_date).format("YYYY"))}`,
    };
    if (teamsId !== "noteams") { payload["team_id"] = teamsId }
    NetworkCall(
      `${config.api_url}${BackendRoutes.sales_dashboard_oppertunity_revenue}`,
      NetWorkCallMethods.post,
      payload, null, true, false
    ).then((res) => {
      const result = constructOpportunityRevenueData(res?.data?.data, t);
      set_opportunity_revenue_state(result);
    }).catch((error) => {
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error,
      });
    });
  };

  // get performance teams by member stats
  const getPerformanceTeamsByMemberStats = (offset, limit, teams, more = false) => {
    let team_id = teams?.value;
    if (team_id !== "noteams") {
      const payload = {
        company_id: props?.selectedcompany?.value ?? "",
        from_date: `${moment(props?.calandervalue?.value?.from_date).format("YYYY-MM-DD")}`,
        to_date: `${moment(props?.calandervalue?.value?.to_date).format("YYYY-MM-DD")}`,
        start: offset, length: limit, team_id
      };
      NetworkCall(
        `${config.api_url}${BackendRoutes.sales_dashboard_perform_team_by_person}`,
        NetWorkCallMethods.post,
        payload, null, true, false
      ).then((res) => {
        const result = constructPerformanceTeamsByMemberData(res?.data?.data);
        set_performance_teams_by_member_state(
          more ? [...performance_teams_by_member_state, ...result] : result
        )

      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true, msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error,
        });
      });
    }
  };
  const fetchMorePerformanceTeamsByMemberStats = () => {
    let offset = table_offset?.by_member + table_limit
    set_table_offset({ ...table_offset, by_member: offset })
    getPerformanceTeamsByMemberStats(offset, table_limit, selectedValue?.team, true)
  }

  // get performance teams stats
  const getPerformanceTeamsStats = (offset, limit, teams, more = false) => {
    let team_id = teams?.value;
    if (team_id !== "noteams") {
      const payload = {
        company_id: props?.selectedcompany?.value ?? "",
        from_date: `${moment(props?.calandervalue?.value?.from_date).format("YYYY-MM-DD")}`,
        to_date: `${moment(props?.calandervalue?.value?.to_date).format("YYYY-MM-DD")}`,
        start: offset, length: limit, team_id
      };
      NetworkCall(
        `${config.api_url}${BackendRoutes.sales_dashboard_perform_by_team}`,
        NetWorkCallMethods.post,
        payload, null, true, false
      ).then((res) => {
        const result = constructPerformanceTeamsData(res?.data?.data);
        set_performance_teams_state(
          more ? [...performance_teams_state, ...result] : result
        );
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true, msg: t("Some Thing Went Wrong"),
          severity: AlertProps.severity.error,
        });
      });
    }
  };
  const fetchMorePerformanceTeamsStats = () => {
    let offset = table_offset?.by_team + table_limit
    set_table_offset({ ...table_offset, by_team: offset })
    getPerformanceTeamsStats(offset, table_limit, selectedValue?.team, true)
  }

  useEffect(() => {
    if (props?.selectedcompany?.value
      && props?.calandervalue?.value
      && props?.selectedTeams?.value) {
      getInitialData(props?.selectedTeams)
    }
    // eslint-disable-next-line
  }, [props?.calandervalue, props?.selectedTeams]);
  //change lead count by status/source
  const handleLeadStatsTypeChange = (val) => {
    setSelectedvalue({ ...selectedValue, lead_stats_type: val })
    getLeadCountStats(val)
  }
  const handleOpportunityStatsChange = (val) => {
    setSelectedvalue({ ...selectedValue, opportunity_stats_type: val })
    getOpportunityStats(val)
  }
  const handleTeamChange = (val) => {
    setSelectedvalue({ ...selectedValue, team: val })
    set_table_offset({ by_member: 0, by_team: 0 })
    getPerformanceTeamsByMemberStats(0, table_limit, val, false)
    getPerformanceTeamsStats(0, table_limit, val, false)
  }
  return (loader ?
    (
      <NewLoader minusHeight="100px" />
    ) :
    (<div className={classes.root}>
      {/* section1 */}
      <Grid container spacing={2}>
        {/* lead card */}
        <Grid item xs={12} sm={4} md={6} lg={1.5} sx={{ display: "flex" }}>

          <Grid container spacing={2}>
            {stats_state?.leads?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} padding={16} />
                </Grid >
              );
            })}
          </Grid >
        </Grid >
        {/* Lead Count by Status */}
        < Grid item xs={12} sm={8} md={6} lg={3} >
          <GraphComponent
            padding={"10px 16px 16px"}
            margin={"30px"}
            t={t}
            title={t("Lead Count")}
            header={t("Lead Count")}
            graphData={lead_count_state?.data ?? []}
            isPie
            select
            isSale
            selectValue={selectedValue?.lead_stats_type}
            options={options}
            handleChange={handleLeadStatsTypeChange}
            isZoom
            onZoomClick={zoomGraphFunction}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              }
            }
            relativeGrid={{
              sm: "inherit"
            }}
            gradiantStyle={{
              margin: '10px 0px 0px',
              maxHeight: "70px",
              display: "flex",
              alignItems: "center",
              top: {
                lg: "auto",
                md: 'auto',
                sm: "auto",
              },
              height: {
                md: "400px"
              },
              height1: {
                md: "auto !important"
              },
              bottom: {
                md: "16px",
                sm: "0px"
              },
              relative: {
                md: "absolute !important"
              },
            }}

          />
        </Grid >
        {/* Opportunity Count By Status */}
        < Grid item xs={12} sm={6} md={6} lg={3} >
          <GraphComponent
            padding={"10px 16px 16px"}

            margin={"30px"}
            t={t}
            title={t("Opportunity")}
            header={t("Opportunity")}
            graphData={opportunity_state?.data ?? []}
            isPie
            select
            isSale
            selectValue={selectedValue?.opportunity_stats_type}
            options={options}
            handleChange={handleOpportunityStatsChange}
            isZoom
            onZoomClick={zoomGraphFunction}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              }
            }
            relativeGrid={{
              sm: "inherit"
            }}
            gradiantStyle={{
              margin: '10px 0px 0px',
              maxHeight: "70px",
              display: "flex",
              alignItems: "center",
              top: {
                lg: "auto",
                md: 'auto',
                sm: "auto",
              },
              height: {
                md: "400px"
              },
              height1: {
                md: "auto !important"
              },
              bottom: {
                md: "16px",
                sm: "0px"
              },
              relative: {
                md: "absolute !important"
              },
            }}

          />
        </Grid >
        {/* Opportunity Count By Status */}
        < Grid item xs={12} sm={6} md={6} lg={4.5} >
          <GraphComponent
            t={t}
            title={t("Opportunity Status")}
            header={t("Opportunity Status")}
            graphData={opportunity_status_state ?? []}
            margin={"20px"}
            isBar
            scale={scaleLine ?? false}
            gheight={230}
            isZoom
            onZoomClick={zoomGraphFunction}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              maxHeight: "70px",
              top: {
                lg: "auto",
                md: '0px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              },
              bottom: {
                lg: "auto",
                md: '16px',
                sm: "auto",
              },
              relativeGrid: {
                sm: "initial !important"
              }
            }}
          />
        </Grid >
      </Grid >
      {/* section 2 */}
      < Grid container spacing={2} mt={0.3} >
        {/* quotation card */}
        < Grid item xs={12} sm={4} md={6} lg={1.5} sx={{ display: "flex" }}>

          <Grid container spacing={2}>
            {stats_state?.quotation?.map((x) => {
              return (
                <Grid item xs={12}>
                  <CardDetails data={x} height="130px" t={t} />
                </Grid>
              );
            })}
          </Grid>
        </Grid >
        {/* Opportunity Reveune By Status */}
        < Grid item xs={12} sm={8} md={6} lg={4.5} >
          <GraphComponent
            t={t}
            title={t("Opportunity Revenue")}
            header={t("Opportunity Revenue")}
            graphData={opportunity_revenue_state ?? []}
            isBar
            gheight={300}
            margin={"20px"}
            isZoom
            scale={scaleLine ?? false}
            onZoomClick={zoomGraphFunction}
            justifyContent={'start'}
            gradiantStyle={{
              margin: '15px 0px 0px',
              maxHeight: "70px",
              top: {
                lg: "auto",
                md: '7px',
                sm: "3px",
              },
              height: {
                sm: "63px"
              }
            }}

          />
        </Grid >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <GraphComponent
            t={t}
            title={t("KYC Information")}
            header={t("KYC Information")}
            isKyc
            data={stats_state?.kyc_type}
            margin={"20px"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <GraphComponent
            t={t}
            title={t("Correspondence & Letter Management")}
            header={t("Correspondence & Letter Management")}
            graphData={stats_state?.correspondence_type?.data ?? []}
            isPie
            total={stats_state?.correspondence_type?.total ?? 0}
            isTotal
            margin={"44px"}
            innerRadius={70}
            isZoom
            onZoomClick={zoomGraphFunction}
            textMargin={'0px 0px 16px'}
            divider1={
              {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              }
            }
            divider={
              {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              }
            }
            relativeGrid={{
              sm: "inherit"
            }}
            gradiantStyle={{
              margin: '10px 0px 0px',
              // maxHeight: "70px",
              display: "flex",
              alignItems: "center",
              top: {
                lg: "auto",
                md: 'auto',
                sm: "auto",
              },
              height: {
                md: "400px"
              },
              height1: {
                md: "auto !important"
              },
              bottom: {
                md: "16px",
                sm: "0px"
              },
              relative: {
                md: "absolute !important"
              },
            }}
          />
        </Grid>

      </Grid >
      {/* section 3 */}
      {
        props?.teams_list?.length > 0 &&
        <Grid container spacing={2} mt={0.3}>
          {/* Performance by team members*/}
          <Grid item xs={12} sm={12} lg={6}>
            <GraphComponent
              title={t("Performance By Team Members")}
              header={t("Performance By Team Members")}
              isTableWithInfinity
              tableData={performance_teams_by_member_state ?? []}
              heading={teamPerformHead(t)}

              path={teamPerformPath}
              select
              options={props?.teams_list}
              selectValue={selectedValue?.team}
              handleChange={handleTeamChange}
              fetchMoreData={fetchMorePerformanceTeamsByMemberStats}
              dataType={[
                { type: ["avatarmanagement"], name: "Image" },
                { type: ["text"], name: "rep" },
                { type: ["text"], name: "opportunities" },
                { type: ["text"], name: "won" },
                { type: ["text"], name: "lost" },
                { type: ["text"], name: "Amount" },
              ]}
              tabelheight={296}

            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <GraphComponent
              t={t}
              title={t("Performance By Team")}
              header={t("Performance By Team")}
              isTableWithInfinity
              tableData={performance_teams_state ?? []}
              heading={()=>teamHead(t)}
              path={teamPath}
              handleChange={handleTeamChange}
              fetchMoreData={fetchMorePerformanceTeamsStats}
              dataType={[
                { type: ["text"], name: "name" },
                { type: ["text"], name: "opportunities" },
                { type: ["text"], name: "won" },
                { type: ["text"], name: "lost" },
                { type: ["text"], name: "Amount" },
              ]}
              tabelheight={296}
            />
          </Grid>
        </Grid >}
      {/* zoom dialog for each graph */}

      <DialogDrawer
        header={t(zoomGraph)}
        maxWidth={zoomGraph === "Map View" ? "lg" : "md"}
        handleClose={handleCloseModule}
        open={zoom}
        height={zoomGraph === "Map View" ? '700px' : "auto"}
        borderRadius={"12px"}
        padding={'0px'}
        onClose={handleCloseModule}
        component={
          <>
            {zoomGraph === "Lead Count" && (
              <GraphComponent
                graphData={lead_count_state?.data ?? []}
                isPie
                margin={"0px"}
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
            {zoomGraph === "Opportunity" && (
              <GraphComponent
                graphData={opportunity_state?.data ?? []}
                isPie
                margin={"0px"}
                padding={"16px"}
                maxHeightGraph={"250px"}
                minWidth={false}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '-8px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
            {zoomGraph === "Opportunity Status" && (
              <GraphComponent
                graphData={opportunity_status_state ?? []}
                margin={"0px"}
                isBar
                scale={scaleLine ?? false}
                minWidth={false}
                gheight={'500px'}
                gwidth={'400px'}
                is_popUp
                justifyContent={'start'}
                padding={"0px 16px 16px"}
                divider1={
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8
                  }
                }

                gradiantStyle={{
                  margin: '15px 0px 0px',
                  grid_template: "100%",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '16px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
            {zoomGraph === "Opportunity Reveune" && (
              <GraphComponent
                graphData={opportunity_revenue_state ?? []}
                isBar
                margin={"0px"}
                scale={scaleLine ?? false}
                minWidth={false}
                gheight={'500px'}
                gwidth={'400px'}
                is_popUp
                justifyContent={'start'}
                padding={"0px 16px 16px"}
                divider1={
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8
                  }
                }

                gradiantStyle={{
                  margin: '15px 0px 0px',
                  grid_template: "100%",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '16px',
                    sm: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}
              />
            )}
            {zoomGraph === "Correspondence & Letter Management" && (
              <GraphComponent
                graphData={stats_state?.correspondence_type?.data ?? []}
                isPie
                innerRadius={100}
                isTotal
                margin={"0px"}
                minWidth={false}
                total={stats_state?.correspondence_type?.total ?? 0}
                paddingAngle={2}
                padding={"16px"}
                maxHeightGraph={"250px"}
                height={"auto"}
                is_popUp
                justifyContent={'start'}
                centerTop={"44%"}
                divider1={
                  {
                    xs: 12,
                    sm: 5,
                    md: 5,
                    lg: 5
                  }
                }
                divider={
                  {
                    xs: 12,
                    sm: 7,
                    md: 7,
                    lg: 7
                  }
                }

                gradiantStyle={{
                  margin: '0px 0px 0px',
                  display: "flex",
                  alignItems: "center",
                  grid_template: "100%",
                  maxHeight: "auto",
                  top: {
                    lg: "auto",
                    md: '0px',
                    sm: "auto",
                  },
                  bottom: {
                    sm: "auto",
                    md: "-16px",
                    lg: "auto",
                  },
                  height: {
                    md: "auto",
                    sm: "auto",
                    lg: "100%"
                  }
                }}

              />
            )}
          </>
        }
      />
    </div >)
  );
};

export default withNamespaces("leaseAndSalesManagerDashboard")(LeaseManagerDashboard);

