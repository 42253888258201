import * as React from "react"
const LocationIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="Group 106751">
      <g data-name="Group 106750">
        <g data-name="Group 106748">
          <g data-name="Group 106635">
            <g data-name="Group 106604">
              <g data-name="Group 106603">
                <path
                  fill="none"
                  d="M0 .001h16v16H0z"
                  data-name="Rectangle 55638"
                />
                <path
                  fill="#78b1fe"
                  d="M8.1 2.001a5.1 5.1 0 0 0-3.872 8.414c.091.1 2.228 2.532 2.942 3.212a1.346 1.346 0 0 0 1.86 0c.83-.792 2.857-3.115 2.946-3.217A5.1 5.1 0 0 0 8.1 2.001Zm0 6.6a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5Z"
                  data-name="icons8-location (1)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default LocationIcon
