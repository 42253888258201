import React from "react"
import { withNavBars } from "../../HOCs"
import FeedBackCategoryMaster from "./feedBackCategory"

const FeedbackCategoryMasterParent = () => {
    return (
        <FeedBackCategoryMaster />
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(FeedbackCategoryMasterParent, props)