import React from "react"

export const BussinessTripIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_106768" data-name="Group 106768" transform="translate(-3837 23598)">
    <g id="Rectangle_55655" data-name="Rectangle 55655" transform="translate(3837 -23598)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="24" height="24" stroke="none"/>
      <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
    </g>
    <path id="icons8-airport" d="M22.512,8.591a1.971,1.971,0,0,0-2.7.116l-2.46,2.6L10.015,9.133a1.65,1.65,0,0,0-1.761.473,1.073,1.073,0,0,0-.265.819,1.158,1.158,0,0,0,.447.805l5.359,3.826-3.387,3.493L9.231,18.19a1.656,1.656,0,0,0-1.886.288,1.1,1.1,0,0,0-.337.788,1.147,1.147,0,0,0,.361.837l.967.69-.15.222a1.383,1.383,0,0,0,.167,1.75h0a1.383,1.383,0,0,0,1.75.167l.223-.15.715,1a1.139,1.139,0,0,0,.8.331h.01a1.1,1.1,0,0,0,.788-.337,1.637,1.637,0,0,0,.3-1.85L12.57,20.71l3.493-3.387,3.832,5.366a1.152,1.152,0,0,0,.8.44,1.069,1.069,0,0,0,.819-.265,1.65,1.65,0,0,0,.476-1.753l-2.18-7.34,2.6-2.46A1.971,1.971,0,0,0,22.512,8.591Z" transform="translate(3833.992 -23602.109)" fill="#ff9340"/>
  </g>
</svg>


)