import { Box, IconButton, Popover } from '@mui/material';
import React from 'react'
import { DatePickerManangeInvoice } from '../../assets';
import { managrmentStyles } from './style';
import { DateRangePicker } from 'materialui-daterange-picker';

export const DatePicker = ({
    onSumbit = () => false
}) => {
    const classes = managrmentStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        if (anchorEl !== null) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box marginInlineStart={1}>
            <IconButton
                size="small"
                className={classes.img}
                aria-describedby={id} onClick={handleClick}
            >
                <DatePickerManangeInvoice />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >

                <DateRangePicker
                    open={anchorEl}
                    toggle={handleClick}
                    onChange={(range) => {
                        onSumbit(range)
                        handleClose();
                    }}
                    // initialDateRange={data?.[val?.state_name]}
                    definedRanges={[]}
                />
            </Popover>
        </Box>
    )
}