import React from 'react'
import { useStyles } from '../styles';
import { NewDatePicker, TextBox } from '../../../components';
import { Box, Grid, Typography } from '@mui/material';

export const IdDetails = ({
    t = () => false,
    data = "",
    updateState = () => false,
}) => {
    const classes = useStyles()
    const textBoxStyle = {
        padding: "8px 14px",
        borderRadius: 8,
        height: 47
    }
    return (
        <>
            <Box className={classes.box} marginTop="16px">
                <Typography className={classes.title}>{t("ID Details")}</Typography>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("National ID")}
                            placeholder={t("Enter National ID")}
                            value={data?.nationalID ?? null}
                            onChange={(e) => {
                                updateState("nationalID", e.target.value);
                            }}
                            isError={data?.error?.nationalID?.length > 0}
                            errorMessage={data?.error?.nationalID}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <NewDatePicker
                            label={t("National ID Expiry")}
                            placeholder={t("National ID Expiry")}
                            minDate={new Date()}
                            value={data?.nationalIDExpiry ?? null}
                            onChange={(value) => updateState("nationalIDExpiry", value)}
                            isError={data?.error?.nationalIDExpiry?.length > 0}
                            errorMessage={data?.error?.nationalIDExpiry}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Passport ID")}
                            placeholder={t("Enter Passport ID")}
                            value={data?.passportID ?? null}
                            onChange={(e) => {
                                updateState("passportID", e.target.value);
                            }}
                            isError={data?.error?.passportID?.length > 0}
                            errorMessage={data?.error?.passportID}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <NewDatePicker
                            label={t("Passport Expiry")}
                            placeholder={t("Passport Expiry")}
                            minDate={new Date()}
                            value={data?.passportIDExpiry ?? null}
                            onChange={(value) => updateState("passportIDExpiry", value)}
                            isError={data?.error?.passportIDExpiry?.length > 0}
                            errorMessage={data?.error?.passportIDExpiry}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Resident ID")}
                            placeholder={t("Enter Resident ID")}
                            value={data?.residentID ?? null}
                            onChange={(e) => {
                                updateState("residentID", e.target.value);
                            }}
                            isError={data?.error?.residentID?.length > 0}
                            errorMessage={data?.error?.residentID}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <NewDatePicker
                            label={t("Resident ID Expiry")}
                            placeholder={t("Resident ID Expiry")}
                            minDate={new Date()}
                            value={data?.residentIDExpiry ?? null}
                            onChange={(value) => updateState("residentIDExpiry", value)}
                            isError={data?.error?.residentIDExpiry?.length > 0}
                            errorMessage={data?.error?.residentIDExpiry}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <TextBox
                            {...textBoxStyle}
                            label={t("Visa Number")}
                            placeholder={t("Enter Visa Numbers")}
                            value={data?.visaNumber ?? null}
                            onChange={(e) => {
                                updateState("visaNumber", e.target.value);
                            }}
                            isError={data?.error?.visaNumber?.length > 0}
                            errorMessage={data?.error?.visaNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <NewDatePicker
                            label={t("Visa Expiry")}
                            placeholder={t("Visa Expiry")}
                            minDate={new Date()}
                            value={data?.visaNumberExpiry ?? null}
                            onChange={(value) => updateState("visaNumberExpiry", value)}
                            isError={data?.error?.visaNumberExpiry?.length > 0}
                            errorMessage={data?.error?.visaNumberExpiry}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}