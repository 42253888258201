import { Box, Typography } from "@mui/material"
import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { SearchFilter } from "../../../components"
import { Cards } from "./card"

export const ChooseUnit = ({ t, unitData = [], updateState = () => false, selectedUnit = {}, fetchMoreData = () => false, handleSearch = () => false, searchText = "" }) => {
    const onChangeUnit = (val) => {
        if (selectedUnit?.id && val?.id !== selectedUnit?.id) {
            updateState("selectedUnit", val, "selectedInspectionItem", [])
        }
        else {
            updateState("selectedUnit", val)
        }
    }
    return (
        <Box p={"16px 22px 16px 22px"}>

            <Box mb={2}>
                <SearchFilter placeholder={t("Search Unit")} value={searchText} handleChange={(value) => handleSearch(value)} />
            </Box>
            <InfiniteScroll
                dataLength={unitData?.length ?? ""}
                next={fetchMoreData}
                hasMore={true}
                height={350}
            >
                <Box>
                    {unitData?.length > 0 ? unitData?.map((val) => {
                        return (
                            <Cards details={val} unit={true} isSelect={val?.id === selectedUnit?.id ? true : false} onClick={() => onChangeUnit(val)} />
                        )
                    })
                        :
                        <Typography textAlign={"center"}>{t("No Data Found")}</Typography>
                    }
                </Box>
            </InfiniteScroll>
        </Box>
    )
}