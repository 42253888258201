import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "12px 18px",
        backgroundColor: theme.palette.background.secondary,
        borderRadius: "4px"
    },
    label: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary
    },
    close: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.palette.error.main,
        cursor: "pointer"
    },
    add: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    textField: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "0px",
        border: "none",
        borderRight: "1px solid #CED3DD",
        height: "30px",

        [`& fieldset`]: {
            borderRadius: "0px",
            border: "0px",
        },
        "& .MuiOutlinedInput-input": {
            padding: "4px 8px 0px 8px"
        }
    },

    textRoot: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "4px",
        marginTop: "12px"

    },
    detailRoot: {
        padding: "4px 12px",
        backgroundColor: theme.palette.border.secondary,
        borderRadius: "4px",
        marginTop: "8px"
    },
    value: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    reco: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary
    },
    recoIcon: {
        fontSize: "1.25rem",
        color: theme.typography.color.tertiary,
        marginTop: "8px",
        cursor: "pointer"
    }
}))