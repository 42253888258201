export const Extensions = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Agreement extend">
      <g
        data-name="Group 96941"
        transform="translate(4.811 7.835)"
        fill={props?.fill ?? "#c1c5cb"}
      >
        <path
          data-name="Path 93625"
          d="M7.004 1.911A.955.955 0 1 0 6.05.955a.956.956 0 0 0 .954.956Zm0-1.274a.318.318 0 1 1-.318.318.319.319 0 0 1 .318-.318Z"
        />
        <path
          data-name="Path 93626"
          d="M9.233 11.145h-.318v-.318a.319.319 0 1 0-.637 0v.318H7.96a.319.319 0 1 0 0 .637h.318v.318a.319.319 0 1 0 .637 0v-.318h.318a.319.319 0 1 0 0-.637Z"
        />
        <circle
          data-name="Ellipse 129192"
          cx={0.318}
          cy={0.318}
          r={0.318}
          transform="translate(13.691 .955)"
        />
        <circle
          data-name="Ellipse 129193"
          cx={0.318}
          cy={0.318}
          r={0.318}
          transform="translate(12.099 13.693)"
        />
        <path
          data-name="Path 93627"
          d="m20.063 6.486-2.226-2.548a1.274 1.274 0 0 0-1.917 1.678l.381.435h-2.288a1.274 1.274 0 1 0 0 2.547h2.288l-.381.435a1.274 1.274 0 0 0 1.917 1.677l2.229-2.547a1.278 1.278 0 0 0-.003-1.677Zm-.48 1.258-2.229 2.547a.655.655 0 0 1-.9.06.637.637 0 0 1-.06-.9l.843-.963a.319.319 0 0 0-.24-.528h-2.99a.637.637 0 0 1 0-1.274h2.99a.319.319 0 0 0 .24-.528l-.843-.963a.637.637 0 1 1 .958-.839l2.229 2.547a.639.639 0 0 1 .003.841Z"
        />
        <path
          data-name="Path 93628"
          d="M10.826 6.05H4.083l.381-.435a1.274 1.274 0 0 0-1.917-1.677L.318 6.485a1.279 1.279 0 0 0 0 1.677l2.229 2.548a1.274 1.274 0 0 0 1.917-1.678L4.083 8.6h6.746a1.274 1.274 0 0 0 0-2.547Zm0 1.911H3.383a.319.319 0 0 0-.24.528l.843.963a.638.638 0 0 1-.06.9.655.655 0 0 1-.9-.06L.797 7.745a.639.639 0 0 1 0-.839l2.229-2.547a.637.637 0 1 1 .957.841l-.843.963a.319.319 0 0 0 .24.528h7.448a.637.637 0 1 1 0 1.274Z"
        />
      </g>
      <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
    </g>
  </svg>
)
