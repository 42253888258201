import React from 'react';
import { Grid, Button, Box, Typography, Avatar } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useStylesCreation } from './sytle';
import { getElipses, stringAvatar } from '../../../utils';


export const AddMemberCard = (props) => {
      const classes = useStylesCreation();
      return (
            // add member box
            <Grid container spacing={3}>
                  {
                        props?.data?.map((x, index) => {
                              return (

                                    <Grid item xs={12}>
                                          <div style={{ textAlign: 'center' }}>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                      <Box >
                                                            <Typography className={classes.roles}>{getElipses(x?.name,30,30)}</Typography>
                                                      </Box>
                                                </Box>
                                                {
                                                      props?.member?.first_name?.length > 0 ? <Box className={classes.selectBox} onClick={() => props?.closeDrawer(props?.member, 'delete')}>
                                                            <Box>
                                                                  <Avatar src={props?.member?.image_url ?? ""} className={classes.avatarcard} {...stringAvatar(props?.member?.first_name)} />
                                                            </Box>
                                                            <Box>
                                                                  <Typography className={classes.select}>{props?.member?.first_name ?? ""}</Typography>
                                                            </Box>
                                                      </Box>
                                                            :
                                                            <Button variant='outlined' className={classes.addmember} onClick={() => props?.closeDrawer(x, 'add')}>Add Member</Button>
                                                }

                                          </div>
                                    </Grid>

                              )
                        })
                  }
            </Grid>
      )
}
export const Card = ((props) => {

      const classes = useStylesCreation();
      return (
            <>
                  {/* add member card */}
                  {
                        props?.data?.map((x, index) => {
                              return (
                                    <div
                                          id={props?.id}
                                          className={classes.card1} onClick={() => props?.selectUser(x, index)} style={{ cursor: 'pointer', borderBottom: "1px solid #E4E8EE", }}
                                    >
                                          <div className={classes.Cardcontent}>
                                                <Box alignItems="center" display="flex">
                                                      <Box style={{ marginRight: '4px' }}>
                                                            <Avatar src={x?.image_url} className={classes.avatarcard} {...stringAvatar(x?.first_name)} />
                                                      </Box>
                                                      <Box flexGrow={1} marginLeft="10px">
                                                            <Box alignItems="center" display="flex" justifyContent='space-between'>
                                                                  <Box>
                                                                        <Typography
                                                                              variant="subtitle2"
                                                                              className={classes.title1}
                                                                              noWrap
                                                                        >

                                                                              {getElipses(`${x?.first_name}   ${x?.department_name ? `(${x?.department_name})` : ""}`,40,40)}
                                                                              
                                                                        </Typography>
                                                                  </Box>
                                                                  <Box>
                                                                        {
                                                                              x?.isSelected && <img src="/images/ticknew.svg" alt="tick_img" />
                                                                        }

                                                                  </Box>

                                                            </Box>
                                                            <Box
                                                                  alignItems="center"
                                                                  display="flex"
                                                                  marginTop="4px"
                                                                  style={{ flexFlow: "wrap !important" }}
                                                                  className={classes.boxes}
                                                            >
                                                                  {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Box>
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    className={classes.sub}
                                                                                    noWrap
                                                                              >
                                                                                    {x?.mobile_no_country_code ?? ""}&nbsp;{x?.mobile_no ?? ""}
                                                                              </Typography>
                                                                        </Box>
                                                                  </div> */}


                                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {/* <Box className={classes.dot} /> */}
                                                                        <Box>
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    className={classes.sub}
                                                                                    noWrap
                                                                              >
                                                                                    {x?.email_id ?? ""}
                                                                              </Typography>
                                                                        </Box>
                                                                  </div>


                                                            </Box>
                                                      </Box>
                                                      <Box>

                                                      </Box>
                                                </Box>
                                          </div>
                                    </div>
                              )
                        })
                  }

            </>
      )
})


export const Card1 = (props) => {
      const classes = useStylesCreation();
      return (
            <>
                  {props?.data?.map((x, index) => {
                        return (
                              <div
                                    className={classes.card1} style={{ cursor: 'pointer' }}
                              >
                                    <div className={classes.Cardcontent}>
                                          <Box alignItems="center" display="flex">
                                                <Box style={{ marginRight: '4px' }}>
                                                      <Avatar src={x.image_url} className={classes.avatarcard} {...stringAvatar(x?.first_name)} />
                                                </Box>
                                                <Box flexGrow={1} marginLeft="10px">
                                                      <Box alignItems="center" display="flex" justifyContent='space-between'>
                                                            <Box>
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        className={classes.title1}
                                                                        noWrap
                                                                  >
                                                                        {getElipses(`${x?.first_name}   ${x?.department_name ? `(${x?.department_name})` : ""}`,40,40)}
                                                                  </Typography>
                                                            </Box>
                                                            <Box>
                                                                  <DeleteOutlineIcon style={{ color: 'red' }} onClick={() => props?.deleteUser(x, index)} />
                                                            </Box>

                                                      </Box>
                                                      <Box
                                                            alignItems="center"
                                                            display="flex"
                                                            marginTop="4px"
                                                            style={{ flexFlow: "wrap !important" }}
                                                            className={classes.boxes}
                                                      >
                                                            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                  <Box>
                                                                        <Typography
                                                                              variant="subtitle2"
                                                                              className={classes.sub}
                                                                              noWrap
                                                                        >
                                                                              {x.mobile_no_country_code}&nbsp;{x.mobile_no}
                                                                        </Typography>
                                                                  </Box>
                                                            </div> */}


                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                  {/* <Box className={classes.dot} /> */}
                                                                  <Box>
                                                                        <Typography
                                                                              variant="subtitle2"
                                                                              className={classes.sub}
                                                                              noWrap
                                                                        >
                                                                              {x.email_id}
                                                                        </Typography>
                                                                  </Box>
                                                            </div>


                                                      </Box>
                                                </Box>
                                                <Box>

                                                </Box>
                                          </Box>
                                    </div>
                              </div>
                        )
                  })

                  }
            </>
      )
}