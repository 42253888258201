import { Box, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { Download } from "../../../assets";
import { LoadingSection, SearchFilter, TableWithPagination } from "../../../components";
import {
    AccountStatementDataType,
    AccountStatementHeading,
    AccountStatementListPath
} from "../utils";
import { StatementOfAccountComponentStyles } from "./styles";
const
    AccountStatementTable = ({
        handleIcon = () => false,
        list = [],
        handlePagination = () => false,
        handleChangeLimit = () => false,
        page = "",
        limit = "",
        path = [],
        heading = [],
        dataType = [],
        placeholder = "",
        searchText = "",
        handleSearch = () => false,
        onClick = () => false,
        height = "",
        add = false,
        handleAddInspection = () => false,
        onFilter = () => false,
        dot = false,
        options = [],
        onAdd = () => false,
        t,
        loading = false,
        DownloadPdf = () => false
    }) => {

        const classes = StatementOfAccountComponentStyles()


        return (
            <Box>
                <Grid container>
                    {/* searchbar */}
                    <Grid item xs={4}>
                        <SearchFilter
                            handleChange={handleSearch}
                            value={searchText}
                            placeholder={t("search Details")}
                            customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                    </Grid>
                    {/* filterIcon */}
                    <Grid item md={8} className={classes.filterIconBox}>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={"end"}
                        >
                            <Box className={classes.downBtn}>
                                <IconButton onClick={() => DownloadPdf()}>
                                    <Download />
                                </IconButton>
                            </Box>

                        </Stack>

                    </Grid>
                </Grid>

                {
                    loading ?
                        <LoadingSection top="20vh" message="Fetching ..." />
                        :
                        < TableWithPagination
                            heading={AccountStatementHeading(t)}
                            rows={list?.row}
                            path={AccountStatementListPath}
                            dataType={AccountStatementDataType}
                            showpagination={true}
                            count="2"
                            showpdfbtn={false}
                            showexcelbtn={false}
                            showSearch={false}
                            tableType="no-side"
                            onClick={onClick}
                            handleIcon={handleIcon}
                            view={true}
                            handlePagination={handlePagination}
                            handleChangeLimit={handleChangeLimit}
                            totalRowsCount={list?.count}
                            page={page}
                            limit={limit}
                            height={height}

                        />
                }
            </Box>

        )
    }
export default withNamespaces("inspection")(AccountStatementTable)