import * as React from "react"
const LoginAccessIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
        <g data-name="Group 105298">
            <g data-name="Group 105332">
                <path fill="none" d="M0 0h20v20H0z" data-name="Rectangle 55483" />
                <path
                    fill="#091b29"
                    d="M8.714 1A4.286 4.286 0 1 0 13 5.286 4.3 4.3 0 0 0 8.714 1Zm0 1.286a3 3 0 1 1-3 3 2.99 2.99 0 0 1 3-3Zm-5.152 9a1.707 1.707 0 0 0-1.705 1.705v.652A3.939 3.939 0 0 0 4.131 17.1a9.666 9.666 0 0 0 4.583 1.045h.021l-1.378-1.38c-2.258-.3-4.215-1.36-4.215-3.122v-.652a.42.42 0 0 1 .419-.419h10.3a.421.421 0 0 1 .42.419v.031l.989-.989a1.7 1.7 0 0 0-1.409-.747Zm13.937 0a.641.641 0 0 0-.455.188l-5.974 5.974L8.526 14.9a.643.643 0 1 0-.909.909l3 3a.642.642 0 0 0 .908 0l6.432-6.426a.643.643 0 0 0-.455-1.1Z"
                    data-name="icons8-checked-user-male (2)"
                />
            </g>
        </g>
    </svg>
)
export default LoginAccessIcon
