export const UnitType = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98340">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g fill={props?.fill ?? "#c1c5cb"}>
                    <path
                        data-name="Path 94716"
                        d="M7 19.511v1a.469.469 0 0 0 .938 0v-.535h14.125v.535a.469.469 0 1 0 .937 0v-2.544H7Z"
                    />
                    <path
                        data-name="Path 94717"
                        d="M21.276 14.021H8.724A1.726 1.726 0 0 0 7 15.745v1.289h16v-1.289a1.726 1.726 0 0 0-1.724-1.724Z"
                    />
                    <path
                        data-name="Path 94718"
                        d="M13.243 11.946a.787.787 0 0 0-.786.786v.351h5.087v-.351a.787.787 0 0 0-.786-.786Z"
                    />
                    <path
                        data-name="Path 94719"
                        d="M11.518 12.734a1.726 1.726 0 0 1 1.722-1.726h3.515a1.726 1.726 0 0 1 1.726 1.726v.351h2.51v-2.361A1.726 1.726 0 0 0 19.267 9h-8.535a1.726 1.726 0 0 0-1.724 1.724v2.359h2.51Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)
