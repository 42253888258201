import React from 'react'
import { addMonths, subMonths } from 'date-fns'
import { Box, Stack } from '@mui/material'
import { EmpBookingCalendarHeader } from './components/calHeader'
import { EmpBookingMonthlyCalendar } from './components/empBookingCalendar'
import { startOfWeek, addDays, startOfMonth, endOfMonth, endOfWeek, subDays } from "date-fns";
import { EmpBookingCalDays, Events, UserList, weeklyCalendarData } from '../../utils'
import { EmpBookingWeeklyCalendar } from './components/weeklyCalendar'
export const EmployeesBookingCalendar = () => {
    const [month, setMonth] = React.useState(new Date())

    const next = () => {
        setMonth(addMonths(month, 1))
    }

    const prev = () => {
        setMonth(subMonths(month, 1))
    }

    // Month rendar funtion and state

    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    let day = startDate;

    let finaldays = []
    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            day = addDays(day, 1);
            finaldays.push(day)
        }
    }

    // weekly calendar functions

    const [weekdates, setWeekdates] = React.useState({ startDate: new Date(), endDate: addDays(new Date(), 6) })

    let weekstartDate = weekdates.startDate
    const weekendDate = weekdates.endDate

    var dates = []
    while (weekstartDate <= weekendDate) {
        dates.push(weekstartDate)
        weekstartDate = addDays(weekstartDate, 1)
    }
    const weeknext = () => {
        setWeekdates({
            startDate: addDays(weekdates.endDate, 1), endDate: addDays(weekdates.endDate, 7)
        })
    }

    const weekprev = () => {
        setWeekdates({
            startDate: subDays(weekdates.startDate, 7), endDate: subDays(weekdates.startDate, 1)
        })
    }

    // Daily, weekly, monthly calendar states and functions
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState("Daily")

    const showAll = () => {
        open ? setOpen(false) : setOpen(true)
    }

    const setDropdownValue = (value) => {
        setSelected(value)
        setOpen(false)
    }

    return (
        <Stack>
            <Box>
                <EmpBookingCalendarHeader
                    month={selected === "Monthly" ? month : weekdates}
                    prev={selected === "Monthly" ? prev : weekprev}
                    next={selected === "Monthly" ? next : weeknext}
                    days={EmpBookingCalDays}
                    empList={UserList}
                    open={open}
                    showAll={showAll}
                    setDropdownValue={setDropdownValue}
                    selected={selected}
                />
            </Box>
            <Box>
                {
                    selected === "Monthly" ?
                        <EmpBookingMonthlyCalendar dates={finaldays} month={month} events={Events} />
                        : 
                        <EmpBookingWeeklyCalendar weekdates={dates} data={weeklyCalendarData} />
                }
            </Box>
        </Stack>
    )
}