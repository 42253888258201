import Popover from '@mui/material/Popover';
import withStyles from '@mui/styles/withStyles';
import React from "react";
import { PopoverContext } from "./contexts";


const styles = (theme) => ({
    popover: {
        "& .MuiPopover-paper": {
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 8px 24px #0717411F",
            padding: "0px"
        }
    },
    title: {
        textAlign: 'center'
    },

})


class AppPopover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            title: "",
            id: "",
            body: "",
            positiveBtn: "Ok",
            negativeBtn: "Cancel",
            negativeBtnDontShow: false,
            positiveBtnDontShow: false,
            sx: {},
            anchorEl: null,
            onOk: () => null,
            onCancel: this.close,

        };
    }

    close = () => {
        this.setState({
            open: false,
        });
    };

    set = (props) => {
        this.setState({ ...props });
    };

    render() {
        const { classes } = this.props
        const id = this.state.open ? 'simple-popover' : undefined;

        return (
            <PopoverContext.Provider
                value={{
                    ...this.state,
                    setDialog: this.set,
                }}
            >
                {this.props.children}
                <Popover
                    id={id}
                    className={classes.popover}
                    open={this.state.open}
                    onClose={this.state.onCancel}
                    anchorEl={this.state.anchorEl}
                    // onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}

                >
                    {this.state.body &&
                        <>
                            {this.state.body}</>
                    }
                </Popover>
                {/* <Dialog
                    open={this.state.open}
                    onClose={this.state.onCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={
                        {
                            paper: classes.dialog
                        }
                    }
                    sx={this.state?.sx}

                >
                    {this.state.title && <DialogTitle id="alert-dialog-title" className={classes.title}>{this.state.title}</DialogTitle>}
                    

                </Dialog> */}
            </PopoverContext.Provider>
        );
    }
}

export default withStyles(styles)(AppPopover);
