import React from "react";
import { withNavBars } from "../../HOCs";
import CreateAccount from '../addAccount/createAccount'

const CashBankAccountCreateParent = () =>{
    return <CreateAccount reltype="cashBank"/>
}
const props = {
    boxShadow: false
}
export default withNavBars(CashBankAccountCreateParent, props);
