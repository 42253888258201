export const ServicesMarketplace = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Marketplace Integrations">
            <g transform="translate(6.972 6.951)" fill={props?.fill ?? "#c1c5cb"}>
                <path d="M14.229 11.763a2.96 2.96 0 0 0-1.076-5.7 3.967 3.967 0 0 0-7.932 0 2.96 2.96 0 0 0-2.906 2.959c0 1.235.668 2.737 1.83 2.737ZM9.184 3.146a3.016 3.016 0 0 0-3.016 2.94h-.356a3.374 3.374 0 0 1 3.372-3.3ZM5.235 7.018a2.009 2.009 0 0 0-1.972 2.009h-.358a2.366 2.366 0 0 1 2.324-2.366Zm9.87 2.009a2.008 2.008 0 0 0-1.976-2.009l.006-.356a2.366 2.366 0 0 1 2.324 2.365Z" />
                <g data-name="Group 96944">
                    <circle
                        cx={0.533}
                        cy={0.533}
                        r={0.533}
                        transform="translate(3.347)"
                    />
                    <circle
                        cx={0.533}
                        cy={0.533}
                        r={0.533}
                        transform="rotate(-90 2.207 2.207)"
                    />
                    <circle
                        cx={0.533}
                        cy={0.533}
                        r={0.533}
                        transform="translate(3.347 3.44)"
                    />
                </g>
                <g data-name="Group 96945">
                    <path d="M6.554 14.959v-3.321h.626v3.321ZM8.871 14.959v-3.321h.626v3.321ZM11.187 14.959v-3.321h.626v3.321Z" />
                </g>
                <circle
                    cx={0.658}
                    cy={0.658}
                    r={0.658}
                    transform="translate(6.209 14.782)"
                />
                <circle
                    cx={0.658}
                    cy={0.658}
                    r={0.658}
                    transform="translate(8.526 14.782)"
                />
                <circle
                    cx={0.658}
                    cy={0.658}
                    r={0.658}
                    transform="translate(10.842 14.782)"
                />
                <path d="M11.751 13.155h2.346v1.8h-.626v-1.173h-1.72Z" />
                <circle
                    cx={0.658}
                    cy={0.658}
                    r={0.658}
                    transform="translate(13.125 14.782)"
                />
                <path d="M6.613 13.782h-1.72v1.178h-.626v-1.8h2.346Z" />
                <circle
                    cx={0.658}
                    cy={0.658}
                    r={0.658}
                    transform="translate(3.926 14.782)"
                />
                <path d="M.761 3.017a.892.892 0 0 1 .659.775h.5a5.87 5.87 0 0 1 .362-1.935 3.2 3.2 0 0 1 .571-.98 3.27 3.27 0 0 0-2.092 2.14ZM.713 4.753A3.268 3.268 0 0 0 2.65 6.992l.071-.088a3.69 3.69 0 0 1-.531-1.08 6.078 6.078 0 0 1-.27-1.673h-.538a.893.893 0 0 1-.669.602ZM2.277 4.151a5.71 5.71 0 0 0 .252 1.566 3.409 3.409 0 0 0 .439.922 3.328 3.328 0 0 1 .734-.536V4.846a.892.892 0 0 1-.694-.694ZM2.614 1.987a5.5 5.5 0 0 0-.337 1.81h.73a.892.892 0 0 1 .694-.7V1.406a.892.892 0 0 1-.536-.342 2.754 2.754 0 0 0-.551.922ZM4.059 3.097a.892.892 0 0 1 .694.694h.73a5.5 5.5 0 0 0-.336-1.807 2.754 2.754 0 0 0-.551-.922.892.892 0 0 1-.536.342ZM4.059 4.846v1.092a3.3 3.3 0 0 1 .821-.206 4.294 4.294 0 0 1 .473-1.58h-.6a.892.892 0 0 1-.694.694ZM5.48 1.858a5.674 5.674 0 0 1 .343 1.581 4.35 4.35 0 0 1 .99-.9A3.274 3.274 0 0 0 4.907.876a3.2 3.2 0 0 1 .573.982Z" />
            </g>
            <path data-name="Rectangle 52035" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
