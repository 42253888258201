import * as React from "react"
const CustomerActive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="icons8-circled-user-male-skin-type-7 (2)">
      <path
        fill="#ede7f6"
        d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0Z"
        data-name="Path 100854"
      />
      <path
        fill="#673ab7"
        d="M12 24a11.759 11.759 0 0 0 7.14-2.4c-.36-4.56-4.92-5.4-4.92-5.4l-2.22.48-2.22-.48s-4.56.84-4.92 5.4A11.759 11.759 0 0 0 12 24Z"
        data-name="Path 100855"
      />
      <path
        fill="#311b92"
        d="M12 19.8a3.528 3.528 0 0 0 3.54-3.18 7.315 7.315 0 0 0-1.2-.42 2.356 2.356 0 0 1-2.4 2.34 2.4 2.4 0 0 1-2.4-2.34 7.315 7.315 0 0 0-1.2.42A3.736 3.736 0 0 0 12 19.8Z"
        data-name="Path 100856"
      />
      <path
        fill="#edbd28"
        d="M16.8 11.1a.9.9 0 1 1-.9-.9.923.923 0 0 1 .9.9m-7.8 0a.9.9 0 1 0-.9.9.923.923 0 0 0 .9-.9"
        data-name="Path 100857"
      />
      <path
        fill="#edb405"
        d="M12 18.6a2.351 2.351 0 0 1-2.4-2.4v-2.4h4.8v2.4a2.351 2.351 0 0 1-2.4 2.4Z"
        data-name="Path 100858"
      />
      <path
        fill="#ffca28"
        d="M16.2 8.82c0-3.54-8.4-2.28-8.4 0v2.64a4.2 4.2 0 0 0 8.4 0Z"
        data-name="Path 100859"
      />
      <path
        fill="#795548"
        d="M12 4.2a4.908 4.908 0 0 0-4.8 4.92v1.08l1.2 1.2V9l5.52-1.8L15.6 9v2.4l1.2-1.2v-.48c0-1.92-.48-4.08-2.88-4.56l-.48-.96Z"
        data-name="Path 100860"
      />
      <path
        fill="#784719"
        d="M13.2 10.8a.6.6 0 1 1 .6.6.567.567 0 0 1-.6-.6m-3.6 0a.6.6 0 1 0 .6-.6.567.567 0 0 0-.6.6"
        data-name="Path 100861"
      />
    </g>
  </svg>
)
export default CustomerActive
