import { Avatar, Box, Stack, Tooltip, Typography, tooltipClasses } from '@mui/material'
import React from 'react'
import QRCode from "react-qr-code";
import { EmployeeCardStyle } from './style';
import { styled } from '@mui/material/styles';
import { stringAvatar } from '../../../utils';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export const EmployeeCard = ({
    openPopup = () => false,
    data = {},
    t = () => false
}) => {
    const classes = EmployeeCardStyle()
    return (
        <>
            <Stack direction={"row"} className={classes.box} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction={"row"} columnGap={1}>
                    <Avatar className={classes.avatar} src={data?.image_url} {...stringAvatar(data?.name)}></Avatar>
                    <Stack>
                        <Typography className={classes.title}>{data?.name}</Typography>
                        <Typography className={classes.subTitle}>{data?.user_no}</Typography>
                    </Stack>
                </Stack>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Stack alignItems={"center"}>
                                <QRCode value={data?.user_no ?? "-"} size={50} />
                                <Typography className={classes.qrText} mt={1}>{data?.user_no ?? "-"}</Typography>
                            </Stack>
                        </React.Fragment>
                    } arrow placement={"right"}>
                    <Box className={classes.qrBox}>
                        <QRCode value={data?.user_no ?? "-"} size={25} />
                    </Box>
                </HtmlTooltip>
                <Typography className={classes.changeText} onClick={openPopup}>{t("Change")}</Typography>
            </Stack>
        </>
    )
}