import * as React from "react"
export const DeleteIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 110641">
      <path
        fill="#ff4b4b"
        d="M12 5a2.548 2.548 0 0 0-2.55 2.1H6.93a.522.522 0 0 0-.177 0h-1.2a.526.526 0 1 0 0 1.05h.787l.928 9.11A2 2 0 0 0 9.285 19h5.429a2 2 0 0 0 2.017-1.74l.928-9.11h.788a.526.526 0 1 0 0-1.05h-1.2a.581.581 0 0 0-.177 0h-2.521A2.548 2.548 0 0 0 12 5Zm0 1.05a1.448 1.448 0 0 1 1.422 1.05h-2.845A1.448 1.448 0 0 1 12 6.05Zm-4.55 2.1h9.1l-.918 9.009a.9.9 0 0 1-.917.791h-5.43a.9.9 0 0 1-.917-.791Zm3.25 1.742a.538.538 0 0 0-.544.533v5.25a.553.553 0 0 0 1.105 0v-5.25a.512.512 0 0 0-.162-.379.568.568 0 0 0-.399-.154Zm2.579 0a.538.538 0 0 0-.544.533v5.25a.553.553 0 0 0 1.105 0v-5.25a.512.512 0 0 0-.162-.379.568.568 0 0 0-.397-.154Z"
        data-name="icons8-trash (2)"
      />
    </g>
  </svg>
)
