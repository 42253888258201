import * as React from "react"
const AccessRightIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={19.5} height={18} {...props}>
        <defs>
            <clipPath id="a">
                <path
                    fill={props?.color}
                    stroke="rgba(0,0,0,0)"
                    d="M0 0h18.5v18H0z"
                    data-name="Rectangle 53777"
                />
            </clipPath>
        </defs>
        <g data-name="Request Ticket">
            <path fill="none" d="M0 0h18v18H0z" data-name="Rectangle 53523" />
            <g clipPath="url(#a)" data-name="Group 102039" transform="translate(1)">
                <path
                    fill={props?.color}
                    d="M12.579 0a5.9 5.9 0 0 0-5.7 7.491L.708 13.658a.711.711 0 0 0-.208.5v3.129a.71.71 0 0 0 .711.713h3.315a.71.71 0 0 0 .711-.711v-1.184h1.658a.71.71 0 0 0 .711-.711v-1.657h1.658a.71.71 0 0 0 .711-.711v-1.794A5.919 5.919 0 1 0 12.579 0Zm.711 6.632a1.421 1.421 0 1 1 1.421-1.421 1.421 1.421 0 0 1-1.422 1.421Z"
                    data-name="icons8-key (2)"
                />
            </g>
        </g>
    </svg>
)
export default AccessRightIcon
