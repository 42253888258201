import { ActivePropertyIcon } from "../../../assets";
import { StayIcon } from "../../../assets/stay";
import { Routes } from "../../../router/routes";
import { UnitCategoryOptions, UnitStatusOptions } from "./constant";

export const constructResponseGetStatData = ({ data = "", t = () => false }) => {
    const Custom_Level_1 = data?.client_property_hierarchy_label?.find?.(_ => _?.hierarchy_id === 5);
    const Custom_Level_2 = data?.client_property_hierarchy_label?.find?.(_ => _?.hierarchy_id === 6);
    const count_1 = [
        {
            count: data?.company_count ?? 0,
            image: <ActivePropertyIcon />,
            sub: t("Properties"),
            route: Routes?.properties,
        },
        {
            count: data?.property_count ?? 0,
            image: "/images/propertdashboard/block.svg",
            sub: Custom_Level_1?.is_primary
                ? Custom_Level_1?.block_label
                : t("Blocks"),
            image_type: true,
        },
        {
            count: data?.floor_count ?? 0,
            image: "/images/propertdashboard/floor.svg",
            sub: Custom_Level_1?.is_primary
                ? Custom_Level_1?.floor_label
                : Custom_Level_2?.is_primary
                    ? `${Custom_Level_2?.block_label}/${Custom_Level_2?.floor_label}`
                    : t("Floors"),
            image_type: true,
        },
    ];

    const property_type = data?.property_type?.map(_ => {
        return {
            name: _?.group_name ?? "",
            count: parseInt(_?.count) ?? "",
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        };
    });

    const count_2 = [
        {
            count: data?.vacant_count ?? 0,
            image: "/images/propertdashboard/vacant.png",
            sub: t("Vacant Units"),
            image_type: true,
        },
        {
            count: data?.occupied_count ?? 0,
            image: "/images/propertdashboard/occupied.png",
            sub: t("Occupied Units"),
            image_type: true,
        },
        {
            count: data?.non_performing_units ?? 0,
            image: "/images/propertdashboard/occupied.png",
            sub: t("Non Performing Units"),
            image_type: true,
            info: t("Non performing Units where there is no agreement has made for the last 6 months")
        },
    ];



    const unit_status = {
        ...data?.unit_type,
        datasets: UnitStatusOptions?.({ t })?.map?.(_ => {
            const unit_status = data?.unit_type?.datasets?.find?.(i => i?.label === _?.value);
            const datasets_data = unit_status?.data;
            const barThickness= 18;

            return { ..._, barThickness, data: datasets_data };
        })
    }

    const count_3 = [
        {
            count: data?.unit_count ?? 0,
            image: "/images/propertdashboard/activeunits.png",
            sub: t("Total Units"),
            image_type: true,
        },
        {
            count: data?.price_missing ?? 0,
            image: "/images/propertdashboard/missiongprice.png",
            sub: t("Missing Pricing"),
            image_type: true,
        },
        {
            count: data?.execution_status_unit_group?.find?.(_ => _?.execution_status === "Plan")?.count ?? 0,
            image: "/images/propertdashboard/resident.png",
            sub: t("Plan"),
            image_type: true,
        },
        {
            count: data?.execution_status_unit_group?.find?.(_ => _?.execution_status === "Develop")?.count ?? 0,
            image: "/images/propertdashboard/resident.png",
            sub: t("Develop"),
            image_type: true,
        },
        {
            count: data?.execution_status_unit_group?.find?.(_ => _?.execution_status === "Release")?.count ?? 0,
            image: "/images/propertdashboard/resident.png",
            sub: t("Released"),
            image_type: true,
        },
        {
            count: data?.execution_status_unit_group?.find?.(_ => _?.execution_status === "Block")?.count ?? 0,
            image: "/images/propertdashboard/resident.png",
            sub: t("Blocked"),
            image_type: true,
        },
    ];

    const count_4 = [
        {
            count: data?.function_location ?? 0,
            image: <StayIcon />,
            sub: t("Locations"),
        },
        {
            count: data?.mapped_amenities ?? 0,
            image: <StayIcon />,
            sub: t("Amenities"),
        },
        {
            count: data?.parking_master ?? 0,
            image: <StayIcon />,
            sub: t("Parking Slots"),
        },
    ];

    const unit_type = data?.unit_status?.map(_ => {
        return {
            ..._,
            name: _?.type ?? "",
            count: parseInt(_?.count) ?? "",
        };
    });

    const unit_purpose_total = data?.unit_purpose_group?.map((_) => parseInt(_?.count ?? 0) ?? 0).reduce((a, b) => a + b, 0) ?? 0;

    const unit_purpose_data = data?.unit_purpose_group?.map(_ => {
        return {
            name: _?.unit_purpose ?? "",
            count: parseInt(_?.count) ?? "",
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        };
    });

    const unit_purpose = {
        total: unit_purpose_total,
        data: unit_purpose_data,
    }

    const count_5 = [
        {
            count: data?.construction_count ?? 0,
            image: "/images/propertdashboard/activeunits.png",
            sub: t("Under Development"),
            image_type: true,
        },
        {
            count: data?.ready_unit ?? 0,
            image: "/images/propertdashboard/activeunits.png",
            sub: t("Sale Ready Units"),
            image_type: true,
        },
        {
            count: data?.sold_unit ?? 0,
            image: "/images/propertdashboard/activeunits.png",
            sub: t("Sold Units"),
            image_type: true,
        },
        {
            count: data?.sale_blocked_unit ?? 0,
            image: "/images/propertdashboard/activeunits.png",
            sub: t("Sale Blocked Units"),
            image_type: true,
        },
    ];

    const final_stat_data = {
        count_1,
        property_type,
        count_2,
        unit_status,
        count_3,
        count_4,
        unit_type,
        unit_purpose,
        count_5,
    };

    return final_stat_data;
}

export const constructResponseGetAreaStatisticsListData = ({ data = "" }) => {
    const rows = data?.list?.map?.(_ => {
        return {
            id: _?.id,
            unit_type: _?.purpose,
            carpet_area: _?.carpetArea,
            build_up_area: _?.buildupArea,
            super_build_up_area: _?.superbuildupArea,
            units: _?.unit_count,
            data: _,
        };
    });

    const final_list_data = {
        rows,
        count: data?.count,
    };

    return final_list_data;
}

export const constructResponseGetOccupancyPropertyListData = ({ data = "" }) => {
    const rows = data?.data?.map?.(_ => {
        return {
            id: _?.id,
            property_name: _?.name,
            total_units: _?.total_unit,
            vacant: _?.vacant_unit,
            occupied_units: _?.occupied_unit,
            percentage: _?.occupied_percentage,
            data: _,
        };
    });

    const final_list_data = {
        rows,
        count: data?.count,
    };

    return final_list_data;
}

export const constructResponseGetUnitCategoryStats = ({ data = [], t = () => false }) => {
    const final_unit_category = {
        ...data?.data,
        datasets: UnitCategoryOptions?.({ t })?.map?.(_ => {
            const unit_category = data?.data?.datasets?.find?.(i => i?.label === _?.value);
            const datasets_data = unit_category?.data ?? [];

            return { ..._, data: datasets_data };
        })
    }

    return {
        total: data?.count,
        data: final_unit_category,
    };
}