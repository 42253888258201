import { CardStyle } from "./style";
import { Grid, Button, Box, Typography, Avatar } from "@mui/material";
import { AlertDialog, SearchFilter } from "../../components";
import React, { useState, useEffect } from "react";
import { AddMemberPopUp } from "./addmember";
import {
  NetWorkCallMethods,
  AlertProps,
  useWindowDimensions,
  stringAvatar,
} from "../../utils";
import { AlertContext } from "../../contexts";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import InfiniteScroll from "react-infinite-scroll-component";
import { withNamespaces } from "react-i18next";

const AddMemberCard = (props) => {
  const { t } = props
  const classes = CardStyle();
  const { data, selectedMember } = props;
  const [open, setOpen] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [list, setList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [selectedData, setSelectedData] = useState({});
  // context
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  //   drawer function
  const closeDrawer = (data, key) => {
    setOpen(!open);
  };
  // infinity scroll
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getList(searchData, offset, true);
  };
  // get user profiles in pop up
  const getList = (v, offset, filter) => {
    const payload = {
      tenantId: `${config.tenantId}`,
      type: ["Resident", "Owner"],
      company_id: [1],
      search: v ?? "",
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/owner-resident`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        if (filter) {
          setList(list?.concat(res?.data?.data?.list));
        } else {
          setList(res?.data?.data?.list);
        }
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something went wrong please try again"),
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  useEffect(() => {
    getList(searchData, 0, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // selectUser
  const selectUser = (data) => {
    setSelectedData(data);
  };
  const sumbit = () => {
    props?.updateState("member", selectedData);
    setOpen(!open);
  };
  return (
    // add member box
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3} lg={3}>
          <div className={classes.card} style={{ textAlign: "center" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "23px",
              }}
            >
              <Box className={classes.managericon}>
                <img src="/images/manager.svg" alt="manager" />
              </Box>
              <Box>
                <Typography className={classes.roles}>{data?.name}</Typography>
              </Box>
            </Box>
            {selectedMember?.first_name?.length > 0 ? (
              <Box
                className={classes.selectBox}
                onClick={() => closeDrawer(data, "delete")}
              >
                <Box>
                  <Avatar
                    src={selectedMember?.image_url ?? ""}
                    className={classes.avatarcard1}
                    {...stringAvatar(selectedMember?.first_name)}
                  />
                </Box>
                <Box>
                  <Typography className={classes.select}>
                    {selectedMember?.first_name ?? ""}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Button
                variant="outlined"
                className={classes.addmember}
                onClick={() => closeDrawer(data, "add")}
              >
                {data?.btnText}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      {/*           //     add member poup */}
      <AlertDialog
        isNormal
        header={t("Add Member")}
        onClose={() => closeDrawer("close")}
        open={open}
        component={
          <Box className={classes.dialogBox}>
            <SearchFilter
              value={searchData}
              handleChange={(value) => {
                setSearchData(value);
                getList(value, 0, false);
              }}
              placeholder={t("Search by Name")}
            />
            <Box height={"16px"} />
            <InfiniteScroll
              dataLength={list?.length ?? ""}
              next={fetchMoreData}
              hasMore={true}
              height={size?.height - 420}
            >
              {list?.map((x) => {
                return (
                  <AddMemberPopUp
                    t={t}
                    data={x}
                    selectUser={selectUser}
                    selectedData={selectedData}
                  />
                );
              })}
            </InfiniteScroll>
            <Box height={"16px"} />
            <Button
              variant="contained"
              className={classes.sumbit}
              onClick={sumbit}
            >
              {t("Add Member")}
            </Button>
          </Box>
        }
      />
    </>
  );
};
export default withNamespaces("addMember")(AddMemberCard)

