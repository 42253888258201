import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const ToolsMasterStyles = makeStyles((theme) => ({
    roots: {

        border: "2px solid white",
        padding: "16px",
        margin: "14px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
    },
    filterIcon: {
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: "4px",
        padding: "10px"
    },
    viewHead: {
        color: theme.typography.color.primary,
        fontWeight: "bold",
        fontSize:"1rem"
    },
    viewBadge: {
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.light,
        padding: "5px",
        fontSize:"0.75rem",
        fontWeight: "bold",
        borderRadius: 4,
        marginLeft: "12px"
    },
    viewBadge2: {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.light,
        padding: "5px",
        fontSize:"0.75rem",
        fontWeight: "bold",
        borderRadius: 4,
        marginLeft: "12px"
    },
    viewImgBox: {
        background: "#fff",
        margin: "14px 14px 14px 0",
        boxShadow: "0px 0px 16px #00000014",
    },
    viewImgBox1: {
        background: "#fff",
        margin: "14px 14px 14px 0",
        boxShadow: "0px 0px 16px #00000014",
        height: `calc(100vh - 498px)`,
        overflow: "auto"
    },
    productHead: {
        color: theme.typography.color.tertiary,
        fontSize:"0.8125rem",
    },
    productDescr: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontWeight: "bold",

    },
    productdetails: {
        // overflow: "scroll",
        // height: "155px",
    },


    // delete popup
    delTxt: {
        color: theme.typography.color.primary,
        fontSize:"1rem",
        fontWeight: "bold",
    },
    delIcon: {
        width: "35px",
        height: "35px",
        margin: "auto",
        borderRadius: "50%",
        padding: "10px",
        backgroundColor: theme.palette.error.light
    },
    delBox: {
        backgroundColor: "#fff",
        width: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    delIconSvg: {
        display: "flex",
        margin: "auto"
    },
    delBtn: {
        fontSize: 13,
        fontWeight: "500"
    },
    delBtn_outlined: {
        fontSize: 13,
        fontWeight: "500",
        '&:hover': {
            background: "#fff"
        }
    },
    delBtn_1: {
        marginLeft: "10px",
        fontSize: 13,
        fontWeight: "500"
    },
    commonTxt: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: `${SemiBold}`,
        textTransform: "capitalize"
    },
    addpopupBox: {
        // width: "700px",
        background: "#fff"
    },
    productInput: {
        "& .MuiInputBase-root": {
            width: "250px",
            height: "36px"
        },
        paddingBottom: "16px"
    },
    productTxtBox: {
        width: "100%",

        "& .MuiInputBase-root": {
            width: "100%",
            height: "80px",
        },
        paddingBottom: "16px"
    },
    dragBox: {
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: theme.palette.background.secondary,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px"
    },
    dargtxt: {
        fontSize:"1rem",
        fontWeight: "normal"
    },
    divider: {
        color: theme.typography.color.tertiary,
        "& .css-1ws8119-MuiDivider-root::before": {
            width: "40px !Important"
        },
    },
    imgUpload: {
        "& .MuiBadge-badge": {
            height: "20px",
            width: "20px",
            color: "#98A0AC",
            border: "1px solid #98A0AC",
            background: "#F5F7FA",
        },

    },
    assetHead: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontWeight: "bold"
    },
    BrowseBtn: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "4px",
        fontSize:"0.875rem",
        fontWeight: "bold",
        cursor: "pointer",
    },
    savebtn: {
        display: "flex",
        margin: "0 0 0 auto",
        // width: "34%",
    },
    fileIcon: {
        color: theme.palette.primary.main,
        width: "37px",
        height: "37px",
        marginBottom: "20px"
    },
    btn_outlined: {
        backgroundColor: theme.palette.background.tertiary1,
        border: `1px solid ${theme.palette.border.secondary}`,
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: `${Bold}`,
        marginRight: "10px",
        marginLeft: "10px",
        '&:hover': {
            backgroundColor: theme.palette.background.tertiary1,
            border: `1px solid ${theme.palette.border.secondary}`,
        }
    },
    btn_contained: {
        fontSize:"0.875rem",
        fontFamily: `${Bold}`,
        marginRight: "3px",
        marginLeft: "3px",
    },
    btngrp: {
        display: "flex",
        justifyContent: "end",
        // marginTop: "10px",
        backgroundColor: "white"
    },
    alt_divider: {
        display: "none"
    },
    divider_menu: {
        border: "1px solid #EBEEF3",
        margin: "3px 0px"
    },
    menutext: {
        fontSize:"0.875rem",
        fontFamily: `${SemiBold}`,
        color: theme.palette.secondary.main
    },
    menuparent: {
        padding: "0px 5px"
    },
    fromSection: {
        height: `450px`,
        overflow: "auto",
        backgroundColor: "white"
    },
    img: {
        height: "180px",
        objectFit: "cover",
        width: "100%",
        borderRadius: "4px",

    },
    tempImage: {
        height: "220px",
        borderRadius: "4px",
        width: "100%",
        objectFit: "cover"
    }
}))