import React from "react";
import { withNavBars } from "../../HOCs";
import AmenityMappingNew from "./amenityMapping";

class AmenitiesMappingNewMasterParent extends React.Component {
    render() {
        return <AmenityMappingNew />
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AmenitiesMappingNewMasterParent, props);