import React from "react";
import { withNavBars } from "../../HOCs";
import VendorGroupMaster from "./vendorGroupMaster";

class VendorGroupMasterParent extends React.Component {
  render() {
    return <VendorGroupMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(VendorGroupMasterParent, props);
