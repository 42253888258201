import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import  Report  from './report'

class ReportParent extends Component {
    render() {
        return (
            <div><Report {...this.props} /></div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ReportParent, props)