import * as React from "react"
const SendMailIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      fill="#5078e1"
      d="M.506-.001a.525.525 0 0 0-.468.722l2.546 6.278-2.545 6.278a.525.525 0 0 0 .721.667l12.95-6.475a.525.525 0 0 0 0-.939L.76.055a.525.525 0 0 0-.254-.056Zm1.028 1.617 10.767 5.383-10.767 5.384 1.967-4.858h4.721a.525.525 0 1 0 0-1.05H3.501Z"
      data-name="icons8-sent (1)"
    />
  </svg>
)
export default SendMailIcon
