import * as React from "react"
const EditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    data-name="Group 115817"
    {...props}
  >
    <g data-name="Group 115610">
      <g fill="none" stroke="#e4e8ee" data-name="Rectangle 45263">
        <rect width={24} height={24} stroke="none" rx={4} />
        <rect width={23} height={23} x={0.5} y={0.5} rx={3.5} />
      </g>
    </g>
    <path
      fill="#091b29"
      d="M15.792 6a2.2 2.2 0 0 0-1.563.645l-6.992 6.991a1.105 1.105 0 0 0-.281.479l-.937 3.282a.474.474 0 0 0 .585.585l3.282-.937a1.108 1.108 0 0 0 .478-.281l6.991-6.992A2.208 2.208 0 0 0 15.792 6Zm0 .942a1.258 1.258 0 0 1 .893.373 1.256 1.256 0 0 1 0 1.787l-.613.613-1.786-1.788.613-.613a1.259 1.259 0 0 1 .893-.372Zm-2.176 1.655 1.783 1.787-5.705 5.706a.16.16 0 0 1-.068.04l-2.462.7.7-2.462a.154.154 0 0 1 .04-.068Z"
      data-name="icons8-edit (1)"
    />
  </svg>
)
export default EditIcon
