import * as React from "react"

const NoInspectionData = (props) => (
    <svg
    data-name="Group 98363"
    xmlns="http://www.w3.org/2000/svg"
    width={132.098}
    height={132.098}
    {...props}
  >
    <path
      data-name="icons8-plus-math (1)"
      d="m24.768 12.481-9.951-2.529L12.441 0H12.2L9.953 9.951 0 12.481v.31l9.951 2.026 2.227 9.951h.282l2.356-9.951 9.951-2ZM132.097 121.857l-8.293-2.108-1.98-8.292h-.2l-1.873 8.293-8.294 2.107v.259l8.293 1.688 1.856 8.293h.235l1.963-8.293 8.293-1.667Z"
      fill="#ced3dd"
    />
    <path
      data-name="Path 93466"
      d="M44.625 97.009h-6.1a1.032 1.032 0 0 1-.993-1.315l3.533-12.384a1.034 1.034 0 0 1 .993-.749h6.11a1.032 1.032 0 0 1 .993 1.315l-3.536 12.385a1.034 1.034 0 0 1-1 .748Z"
      fill="#717b89"
    />
    <path
      data-name="Path 93467"
      d="M87.467 97.009h6.11a1.032 1.032 0 0 0 .993-1.315L91.034 83.31a1.034 1.034 0 0 0-1-.749h-6.1a1.032 1.032 0 0 0-.993 1.315l3.533 12.385a1.034 1.034 0 0 0 .993.748Z"
      fill="#717b89"
    />
    <path
      data-name="Path 93468"
      d="M97.009 39.216a6.192 6.192 0 0 0-6.192-6.192H72.241a6.193 6.193 0 0 0-6.192 6.192v28.9h30.96Z"
      fill="#959ca6"
    />
    <path
      data-name="Path 93469"
      d="M66.048 39.216a6.192 6.192 0 0 0-6.192-6.192H41.28a6.193 6.193 0 0 0-6.192 6.192v28.9h30.96Z"
      fill="#959ca6"
    />
    <path
      data-name="Path 93470"
      d="M97.009 74.304H35.088v-8.256a4.13 4.13 0 0 1 4.128-4.128h53.665a4.13 4.13 0 0 1 4.128 4.128Z"
      fill="#465160"
    />
    <path
      data-name="Path 93471"
      d="M35.088 86.688h-2.064a8.256 8.256 0 0 1-8.256-8.252V51.6a2.065 2.065 0 0 1 2.064-2.064h6.192a2.065 2.065 0 0 1 2.064 2.064Z"
      fill="#b8bdc4"
    />
    <path
      data-name="Path 93472"
      d="M91.034 83.31a1.034 1.034 0 0 0-1-.749h-6.1a1.032 1.032 0 0 0-.993 1.315l1.393 4.877h8.256Z"
      opacity={0.05}
    />
    <path
      data-name="Path 93473"
      d="M91.034 83.31a1.034 1.034 0 0 0-1-.749h-6.1a1.032 1.032 0 0 0-.993 1.315l1.1 3.845h8.256Z"
      opacity={0.07}
    />
    <path
      data-name="Path 93474"
      d="M41.107 83.31a1.032 1.032 0 0 1 .993-.749h6.11a1.032 1.032 0 0 1 .993 1.315l-1.393 4.877h-8.257Z"
      opacity={0.05}
    />
    <path
      data-name="Path 93475"
      d="M41.107 83.31a1.032 1.032 0 0 1 .993-.749h6.11a1.032 1.032 0 0 1 .993 1.315l-1.1 3.845h-8.257Z"
      opacity={0.07}
    />
    <path
      data-name="Path 93476"
      d="M105.268 49.536h-6.2a2.065 2.065 0 0 0-2.064 2.064v22.7H24.768v4.136a8.256 8.256 0 0 0 8.256 8.256h66.044a8.256 8.256 0 0 0 8.261-8.256V51.6a2.065 2.065 0 0 0-2.061-2.064Z"
      fill="#b8bdc4"
    />
  </svg>
)

export default NoInspectionData
