import * as React from "react"
const ExistingContactIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g
      fill="#78b1fe"
      data-name="Group 115025"
      transform="translate(-1928 -10153)"
    >
      <rect
        width={40}
        height={40}
        data-name="Rectangle 57913"
        opacity={0.2}
        rx={4}
        transform="translate(1928 10153)"
      />
      <path
        d="M1938.732 10163a.732.732 0 1 0 0 1.463h.732v9.773a1.71 1.71 0 0 0-1.464 1.691v4.873a.732.732 0 0 0 .732.732h5.368a.732.732 0 0 0 .732-.732v-3.166a.488.488 0 0 1 .488-.488h.025a6.3 6.3 0 0 1 .455-1.946.488.488 0 0 1-.488-.488v-.976a.488.488 0 0 1 .488-.488h.976a.47.47 0 0 1 .242.073 6.34 6.34 0 0 1 2.2-1.537.487.487 0 0 1-.487-.488v-.976a.488.488 0 0 1 .488-.488h.976a.488.488 0 0 1 .488.488v.976a.461.461 0 0 1-.017.086 6.007 6.007 0 0 1 2.456.09v-7.009h.732a.732.732 0 1 0 0-1.463Zm3.658 3.415h.976a.488.488 0 0 1 .488.488v.976a.488.488 0 0 1-.488.488h-.976a.488.488 0 0 1-.488-.488v-.979a.488.488 0 0 1 .488-.485Zm3.415 0h.976a.488.488 0 0 1 .488.488v.976a.488.488 0 0 1-.488.488h-.981a.488.488 0 0 1-.488-.488v-.979a.488.488 0 0 1 .488-.485Zm3.415 0h.976a.488.488 0 0 1 .488.488v.976a.488.488 0 0 1-.488.488h-.976a.488.488 0 0 1-.488-.488v-.979a.488.488 0 0 1 .487-.485Zm-6.83 3.414h.976a.488.488 0 0 1 .488.488v.976a.488.488 0 0 1-.488.488h-.976a.488.488 0 0 1-.488-.488v-.976a.488.488 0 0 1 .488-.488Zm3.415 0h.976a.488.488 0 0 1 .488.488v.976a.488.488 0 0 1-.488.488h-.981a.488.488 0 0 1-.488-.488v-.976a.488.488 0 0 1 .488-.488Zm5.854 2.439a5.366 5.366 0 1 0 5.366 5.366 5.366 5.366 0 0 0-5.367-5.366Zm-9.268.976h.976a.488.488 0 0 1 .488.488v.976a.488.488 0 0 1-.488.488h-.977a.488.488 0 0 1-.488-.488v-.976a.488.488 0 0 1 .488-.488Zm9.268.976a1.463 1.463 0 1 1-1.463 1.463 1.463 1.463 0 0 1 1.462-1.464Zm-10.732 2.926h.973a.488.488 0 0 1 .488.488v.976a.488.488 0 0 1-.488.488h-.973a.488.488 0 0 1-.488-.488v-.976a.488.488 0 0 1 .488-.488Zm8.537.976h4.39a.732.732 0 0 1 .732.732v.246c0 1.078-1.31 1.951-2.927 1.951s-2.927-.874-2.927-1.951v-.244a.732.732 0 0 1 .731-.734Z"
        data-name="icons8-property (1)"
      />
    </g>
  </svg>
)
export default ExistingContactIcon
