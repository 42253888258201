import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "24px",
    },
    logo_div: {
        position: 'relative',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logo: {
        height: "75px",
        width: "75px",
        borderRadius: "50%",
        objectFit: "fill"
    },
    title_div: {
        display: "flex",
        marginBottom: "16px",
        alignItems: "center"
    },
    title: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        fontFamily: Bold,
    },
    heading: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        textTransform: "capitalize",
        marginBottom: "8px",
    },
    content: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        textTransform: "capitalize",
        '& .ql-editor': {
            padding: '0px 0px 16px 0px',
            color: theme.typography.color.primary,
            fontSize:"0.875rem",
            fontFamily: SemiBold,
            textTransform: "capitalize",
        }
    },
    image_viewer: {
        '& .styles-module_close__2I1sI': {
            color: 'white',
            position: 'absolute',
            top: '67px',
            right: '100px',
            fontSize: '40px',
            fontWeight: 'bold',
            opacity: 0.2,
            cursor: 'pointer',
        }
    },
    avatar: {
        height: "75px",
        width: "75px",
        cursor: "pointer",
    },
    id_tag: {
        position: "absolute",
        bottom: -8,
        display: "flex",
        alignItems: "center",
        padding: "3px 8px",
        minWidth: "100px",
        justifyContent: "center",
        textAlign: "center",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.background.tertiary1,
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        borderRadius: theme.palette.borderRadius,
    }
}));