import { Grid, Typography } from "@mui/material"
import React from "react"
import { useStyles } from "./style"

export const Loader = (props) => {

    const {
        message = ""
    } = props

    const classes = useStyles(props)

    return <div className={classes.root}>
        <Grid container direction={"column"}
            justifyContent={"center"} alignItems={"center"}>
            <Typography variant="h6" align="center" textAlign="center">
                {message}
            </Typography>
        </Grid>
    </div>
}
