import * as React from "react"
export const ExpiryDateIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    data-name="Group 105496"
    {...props}
  >
    <defs>
      <radialGradient
        id="c"
        cx={-0.657}
        cy={-0.011}
        r={1.118}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#4b4b4b" />
        <stop offset={0.531} stopColor="#393939" />
        <stop offset={1} stopColor="#252525" />
      </radialGradient>
      <radialGradient
        id="d"
        cx={0.08}
        cy={0.279}
        r={0.72}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0.847} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="f"
        cx={0.5}
        cy={0.5}
        r={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="g"
        cx={0.497}
        cy={0.012}
        r={1.114}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fafafb" />
        <stop offset={1} stopColor="#c8cdd1" />
      </radialGradient>
      <linearGradient
        id="b"
        x1={-7.423}
        x2={0.807}
        y1={25.279}
        y2={13.709}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffda1c" />
        <stop offset={1} stopColor="#feb705" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0.146}
        x2={0.854}
        y1={0.146}
        y2={0.854}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#889097" />
        <stop offset={1} stopColor="#64717c" />
      </linearGradient>
      <clipPath id="a">
        <path
          fill="#fff"
          stroke="#707070"
          d="M0 0h32v32H0z"
          data-name="Rectangle 55770"
        />
      </clipPath>
    </defs>
    <rect
      width={48}
      height={48}
      fill="#fff"
      data-name="Rectangle 55573"
      rx={24}
    />
    <g
      clipPath="url(#a)"
      data-name="Mask Group 93144"
      transform="translate(8 8)"
    >
      <g transform="translate(2.829 3.393)">
        <path
          fill="#FACC15"
          d="M27.371 24.947 16.323 7.352a1.171 1.171 0 0 0-2.06 0L3.214 24.947a1.238 1.238 0 0 0-.214.676 1.17 1.17 0 0 0 1.171 1.171h22.244a1.17 1.17 0 0 0 1.171-1.171 1.238 1.238 0 0 0-.215-.676Z"
          data-name="Path 99253"
          transform="translate(-1.244 -2.793)"
        />
        <path
          fill="url(#c)"
          d="M22.967 28.707a1.494 1.494 0 0 1-1.061-.39 1.243 1.243 0 0 1-.418-.944 1.2 1.2 0 0 1 .422-.948 1.544 1.544 0 0 1 1.057-.369 1.5 1.5 0 0 1 1.052.373 1.222 1.222 0 0 1 .41.944 1.249 1.249 0 0 1-.406.956 1.489 1.489 0 0 1-1.056.378Zm1.3-11.285-.282 7.127a.406.406 0 0 1-.406.39H22.3a.406.406 0 0 1-.406-.391l-.266-7.126a.406.406 0 0 1 .41-.422h1.824a.405.405 0 0 1 .405.422Z"
          data-name="Path 99254"
          transform="translate(-8.91 -7.049)"
        />
        <path
          fill="url(#d)"
          d="M18.546 6.737a1.174 1.174 0 0 0-1.03.615l-6.67 10.623c.224.019.447.039.676.039a8.2 8.2 0 0 0 8.2-8.2 8.086 8.086 0 0 0-.547-2.9 1.126 1.126 0 0 0-.629-.177Z"
          data-name="Path 99255"
          opacity={0.2}
          transform="translate(-4.497 -2.793)"
        />
        <circle
          cx={6.991}
          cy={6.991}
          r={6.991}
          fill="url(#e)"
          data-name="Ellipse 129725"
        />
        <circle
          cx={6.117}
          cy={6.117}
          r={6.117}
          fill="url(#f)"
          data-name="Ellipse 129726"
          transform="translate(.874 .874)"
        />
        <circle
          cx={6.117}
          cy={6.117}
          r={6.117}
          fill="url(#g)"
          data-name="Ellipse 129727"
          transform="translate(.874 .874)"
        />
        <path
          fill="#45494d"
          d="M7.025 7.317a.292.292 0 0 1-.207-.5l2.98-2.98a.293.293 0 0 1 .414.414l-2.98 2.98a.292.292 0 0 1-.207.086Z"
          data-name="Path 99256"
        />
        <path
          fill="#45494d"
          d="M7.023 7.463a.434.434 0 0 1-.2-.046L3.316 5.661a.439.439 0 1 1 .392-.782l3.515 1.752a.439.439 0 0 1-.2.832Z"
          data-name="Path 99257"
        />
        <circle
          cx={0.878}
          cy={0.878}
          r={0.878}
          fill="#1e2021"
          data-name="Ellipse 129728"
          transform="translate(6.146 6.146)"
        />
      </g>
    </g>
  </svg>
)

