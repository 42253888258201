/* eslint-disable no-unused-vars */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from 'react-i18next';
import { DatePickerNew, MobileNumberInputComponent, SelectBox, TextBox } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { componantsStyles } from "./styles";
const CreateForm = ({ contact = {}, updateState = () => false, t }) => {
    const classes = componantsStyles()
    const [loading, setLoading] = React.useState(false)
    const [file, setFile] = React.useState([])
    const loadOptions = async (search = "", array, type) => {

        setLoading(type);
        let result

        switch (type) {

            case 'idendifiction':
                result = await networkCallBack( "queries/identification_master",{"api":true});
                return {
                    options: [...result],
                    hasMore: (array?.length + result?.length) < (result?.count[0]?.count??result?.count)
                }

            default:
                return { options: [] }
        }
    }
    const networkCallBack = async (endPoint, payload = {}) => {


        const options = await NetworkCall(
            `${config.api_url}/${endPoint}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response.data.data;
                setLoading(null);
                return main
            })
            .catch((error) => {
                setLoading(null);

                return null
            });

        return options
    }

    const FileList = (props) => {

        return (
            <Box display="flex" alignItems="center" className={classes.fileRoot}>
                <Box className={classes.pin}>
                    <AttachFileIcon style={{ color: "#5AC782" }} />
                </Box>
                <Box flexGrow={1} marginLeft="4px">
                    <Typography className={classes.fileName}>{props?.data?.name}</Typography>
                    <Typography className={classes.fileSize}>{Math.floor(props?.data?.size / 1024)}KB</Typography>
                </Box>
                <Box>
                    <IconButton onClick={props?.onDelete}>
                        <img src="/images/icons8-trash.svg" alt="" />
                    </IconButton>
                </Box>
            </Box>
        )
    }
    const handleFile = (value) => {
        let addedFile = [...file, value]
        setFile(addedFile)
        updateState("files", addedFile)
    }
    const onDelete = (index) => {
        setFile(file.filter((val, indexs) => indexs !== index))
        updateState("files", file.filter((val, indexs) => indexs !== index))
    }
    return (
        <div>
            <Box className={classes.border} />
            <Box p={2}>
                <Typography className={classes.formHead}>{t("PROOF DETAILS")}</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextBox
                            value={contact?.firstName}
                            onChange={(e) => updateState("firstName", e.target.value)}
                            label={t("First Name")}
                            placeholder='First Name'
                            isError={contact?.error?.firstName?.length > 0}
                            errorMessage={contact?.error?.firstName}
                            isrequired
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBox
                            value={contact?.lastName}
                            onChange={(e) => updateState("lastName", e.target.value)}
                            label={t("Last Name")}
                            placeholder='Last Name'
                            isError={contact?.error?.lastName?.length > 0}
                            errorMessage={contact?.error?.lastName}
                            isrequired
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox
                            value={contact?.email}
                            onChange={(e) => updateState("email", e.target.value)}
                            label={t("Email ID")}
                            placeholder='Email ID'
                            isError={contact?.error?.email?.length > 0}
                            errorMessage={contact?.error?.email}
                            isrequired
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MobileNumberInputComponent
                            xs={3.5}
                            isRequired
                            value={contact?.phone}
                            handleChange={(value) => updateState("phone", value)}
                            label={t("Phone Number")}
                            placeholder='Phone Number'
                            isError={contact?.error?.phone?.length > 0}
                            errorMessage={contact?.error?.phone}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectBox
                            label={t("ID Proof Type")}
                            value={contact?.idProof}
                            onChange={(value) => updateState("idProof", value)}
                            placeholder='ID Proof Type'
                            isRequired
                            isPaginate
                            loadOptions={(search, array) => loadOptions(search, array, 'idendifiction')}
                            loading={loading === "idendifiction"}
                            debounceTimeout={800}
                            isError={contact?.error?.idProof?.length > 0}
                            errorMessage={contact?.error?.idProof}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBox
                            value={contact?.idNumber}
                            onChange={(e) => updateState("idNumber", e.target.value)}
                            label={t("ID Proof Number")}
                            placeholder='ID Proof Number'
                            isError={contact?.error?.idNumber?.length > 0}
                            errorMessage={contact?.error?.idNumber}
                            isrequired
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePickerNew
                            label={t("ID Proof Expire Date")}
                            placeholder={t("ID Proof Expire Date")}
                            value={contact?.expireDate}
                            handleChange={(value) => updateState("expireDate", value)}
                            isError={contact?.error?.expireDate?.length > 0}
                            errorMessage={contact?.error?.expireDate}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePickerNew
                            label={t("Passport Expire Date")}
                            placeholder='Passport Expire Date'
                            value={contact?.passportExpire}
                            handleChange={(value) => updateState("passportExpire", value)}
                            isError={contact?.error?.passportExpire?.length > 0}
                            errorMessage={contact?.error?.passportExpire}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBox
                            label={t("Passport Number")}
                            placeholder='Enter Passport Number'
                            value={contact?.passNumber}
                            onChange={(e) => updateState("passNumber", e.target.value)}
                            isError={contact?.error?.passNumber?.length > 0}
                            errorMessage={contact?.error?.passNumber}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBox
                            label={t("Visa Number")}
                            value={contact?.visaNo}
                            placeholder='Enter Visa Number'
                            onChange={(e) => updateState("visaNo", e.target.value)}
                            isError={contact?.error?.visaNo?.length > 0}
                            errorMessage={contact?.error?.visaNo}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.border} marginTop="8px" />
            <Box p={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography className={classes.formHead}>{t("PROOF ATTACHMENT")}</Typography>
                    <label htmlFor="uplodebtn">
                        <Typography className={classes.add}>+ {t("ADD ATTACHMENT")}</Typography>
                    </label>
                    <input
                        type="file"
                        name="img"
                        accept=".pdf"
                        style={{ display: "none" }}
                        id="uplodebtn"
                        onChange={(value) => handleFile(value.target.files[0])

                        }
                    />
                </Stack>
                <Grid container spacing={1}>
                    {
                        file?.map((val, index) => {
                            return (
                                <Grid item xs={12}>
                                    <FileList onDelete={() => onDelete(index)} data={val} />
                                </Grid>
                            )

                        })
                    }
                </Grid>

            </Box>
        </div>
    )
}
export default withNamespaces("quotationsList")(CreateForm)
