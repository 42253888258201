import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SearchFilter, UseDebounce } from '../../components'
import { NewLoader } from '../../components/newLoader'
import { PopupCard } from './components/popupCard'
import { CreateEditContext } from './create/createEditContext'
import { PopupStyle } from './style'

export const UnitPopup = ({
    t
}) => {
    const classes =  PopupStyle()

    const { 
        unitList, 
        getUnitList, 
        unitLoading, 
        updateTodoState, 
        setUnitPopup,
        selectedTodo } = React.useContext(CreateEditContext)

    const [searchText, setSearchText] = React.useState("")
    const [offset, setOffset] = React.useState(0)
    const [selected, setSelected] = React.useState({})

    const debounce = UseDebounce();

    React.useEffect(() => {
        getUnitList(0, "", true)
        // eslint-disable-next-line
    }, [])

    const searchFunction = (e) => {
        setSearchText(e)
        debounce(() => getUnitList(0, e, true), 800)
    }

    //fetch more
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getUnitList(offset + 10, "", false);
    };

    const saveList = (value) => {
        setSelected(value)
    }

    React.useEffect(() => {
        setSelected(selectedTodo?.unit)
    }, [selectedTodo])

    return (
        <>
            <Stack m={2}>
                <SearchFilter
                    value={searchText}
                    handleChange={(e) => searchFunction(e)}
                    placeholder={t("Search")} />
                {
                    unitList?.length > 0 ?
                        <Box mt={1}>
                            {
                                unitLoading ?
                                    <NewLoader minusHeight={"550px"} />
                                    :
                                    <InfiniteScroll
                                        dataLength={unitList.length}
                                        next={fetchMoreData}
                                        hasMore={true}
                                        height={`calc(100vh - 290px)`}
                                    >
                                        {
                                            unitList?.map((e) => {
                                                return (
                                                    <PopupCard img_url={e?.unit_logo ?? "-"}
                                                        title={e?.unit_name ?? "-"}
                                                        subtitle={`${e?.address?.state ? e?.address?.state : "-"} ${e?.address?.country ? e?.address?.country : "-"}`}
                                                        data={e}
                                                        handleClick={saveList}
                                                        selected={selected}
                                                        t={t}
                                                         />
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                            }
                        </Box>
                        :
                        <Box className={classes.noData}>
                            <Typography>{t("No Unit Found")}</Typography>
                        </Box>
                }
                <Box>
                    <Button fullWidth variant='contained' onClick={() => {
                        updateTodoState("unit", selected)
                        setUnitPopup(false)
                    }}
                        disabled={selected?.unit_id?.length > 0 ? false : true}>{t("Add")}</Button>
                </Box>
            </Stack>
        </>
    )
}