import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    box: {
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px",
        height: 'calc(100vh - 145px)',
        overflow: "auto",
        backgroundColor: "white"
    },


    gatWayRoot: {
        borderBottom: "1px solid #E4E8EE"
    },

}))