import React from "react";
import { ResetYourPassword } from "./resetYourPassword";


class ResetPasswordParent extends React.Component {
    render() {
        return <ResetYourPassword />;
    }
}

export default (ResetPasswordParent);
