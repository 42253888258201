import * as React from "react"

export const Agreement = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 105249">
      <path data-name="Rectangle 33" fill="none" d="M0 0h24v24H0z" />
      <path
        d="M16.259 2.999a.9.9 0 0 0-.623.311l-.573.656a1.849 1.849 0 0 0-.277-.036 1.806 1.806 0 0 0-1.421.623L2.823 16.668a1.346 1.346 0 0 0-.3.62l-.509 2.463a.457.457 0 0 0 .146.432.466.466 0 0 0 .3.114.573.573 0 0 0 .15-.023l2.385-.823a1.347 1.347 0 0 0 .582-.4l1.243-1.432a2.508 2.508 0 0 1 2.207-3.7 5.026 5.026 0 0 1 .915.109l6.136-7.046a1.88 1.88 0 0 0 .373-1.838l.559-.642a.91.91 0 0 0-.751-1.503Zm-6.8 4.658-2.172.62a3.224 3.224 0 0 0-1.556.989L2.534 12.94a2.2 2.2 0 0 0-.526 1.614 2.031 2.031 0 0 0 .458 1.139Zm6.536.814-5.007 5.749a7.15 7.15 0 0 0 1.406.155 2.092 2.092 0 0 0 1.493-.706.453.453 0 0 1 .756.5 2.952 2.952 0 0 1-2.235 1.115 9.126 9.126 0 0 1-1.907-.228l-.373-.073a6.8 6.8 0 0 0-1.1-.155 1.591 1.591 0 0 0-1.5 1.056 1.573 1.573 0 0 0 .846 1.989l3 1.384a.924.924 0 0 1 .114.059l.077.046a6.669 6.669 0 0 0 3.382.928 6.755 6.755 0 0 0 2.439-.4l.178-.054a8.674 8.674 0 0 1 3.3-.455h.455a.682.682 0 0 0 .683-.683v-6.367a.681.681 0 0 0-.628-.683h-.05c-.928 0-3.542-1.761-5.2-3.074-.039-.027-.123-.094-.132-.099Z"
        fill={props?.color ?? "#c1c5cb"}
      />
    </g>
  </svg>
)
