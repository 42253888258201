import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Avatar, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Regular, SemiBold } from "../../utils";

const useStyles = makeStyles((theme) => ({
    outerCircle: {
        padding: "3px",
        border: "1px solid #5078E1",
        borderRadius: "50%",
    },
    Avatar: {
        backgroundColor: theme.palette.primary.main,
        width: "18px",
        height: "18px",
        fontSize:"0.75rem",
    },
    stepInactive: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Regular
    },
    stepactive: {
        fontSize:"0.75rem",
        color: theme.palette.primary.main,
        fontFamily: SemiBold
    },
    stepInactiveArrow: {
        color: theme.typography.color.tertiary,
        fontSize: "1.25rem",
        marginTop: "8px"
    },
    stepactiveArrow: {
        color: theme.palette.primary.main,
        fontSize: "1.25rem",
        marginTop: "8px"
    },
    stepRoot: {
        borderBottom: "1px solid #E4E8EE",
        borderTop: "1px solid #E4E8EE",
        padding: (props) => props?.padding ?? "6px 12px",
        backgroundColor: "white"
    }
}));
export const Stepper = (props) => {
    const {steps = [], t = () => false } = props
    const classes = useStyles(props)
    const language = localStorage.getItem("i18nextLng")
    return (
        <Box display="flex" alignItems="center" className={classes.stepRoot}>
            {
                steps?.map((val, index) => {
                    return (
                        <Box display="flex" alignItems="center">
                            <Box>
                                {
                                    val?.value === val?.step &&
                                    <div className={classes.outerCircle}>
                                        <Avatar className={classes.Avatar}>{val?.stepValue ? val?.stepValue : val?.step}</Avatar>
                                    </div>
                                }

                            </Box>
                            <Box marginLeft="4px" marginRight="4px">
                                <Typography noWrap className={val?.value === val?.step ? classes.stepactive : classes.stepInactive}>{t(val?.label)}</Typography>
                            </Box>
                            {
                                index !== (steps?.length - 1) &&
                                <Box>
                                    {language === "ar" ?
                                        <ChevronLeftIcon className={val?.value === val?.step ? classes.stepactiveArrow : classes.stepInactiveArrow} /> :
                                        <ChevronRightIcon className={val?.value === val?.step ? classes.stepactiveArrow : classes.stepInactiveArrow} />
                                    }
                                </Box>
                            }

                        </Box>
                    )
                })
            }

        </Box>
    )
}