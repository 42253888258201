import { Box } from "@mui/material";
import { ProofComponent } from "../../../components";
import { useStylesCreation } from "../style";
export const ContactProof = ({ update = () => false, data = [], t }) => {
    const classes = useStylesCreation()

    return (
        <Box className={classes.box2}>
            <ProofComponent t={t} file={data} update={update} />
        </Box>
    )
}