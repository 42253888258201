import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold, remCalc } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    templateTitle: {
        color: '#091B29',
        fontFamily: Bold,
        fontSize:"1rem",
        marginInlineStart:"16px"
    },
    gnBtn:{
        border:`1px solid #5078E1`,
        borderRadius:"4px",
        color:"#5078E1",
        backgroundColor:"#fff",
        '&:hover':{
            backgroundColor:"#fff",
            color:"#5078E1",
        }
    },
    header:{
        padding:"12px",
        borderBottom:"1px solid #E4E8EE",
        backgroundColor:"white"
    },
    bodyTitle:{
        color:'#98A0AC',
        fontFamily:SemiBold,
        fontSize:"0.75rem"
    },
    container:{
        border:`1px solid #E4E8EE`,
        borderRadius:'8px',
        height:'100%',
        position:"relative",
        cursor:"pointer",
     
        '&:hover':{
            border:`1px solid #5078E1`,
 
        }
    },
        container1:{
        padding:"12px",
        borderTop:`1px solid #E4E8EE`,
    },
    language:{
        color:'#98A0AC',
        fontFamily:SemiBold,
        fontSize:"0.75rem",
        backgroundColor:'#F2F4F7',
        borderRadius:'4px',
        padding:'5px'
    },
     pdfImg:{
        height:'119px',
        overflow:'auto',
        padding:"14px 14px 0px",
        display:"flex",
        width:"100%",
        textAlign:"center",
        // alignItems:"center",
        justifyContent:"center",
        backgroundColor:'#F5F7FA',
        borderRadius:"8px 8px 0px 0px",
    },
    templateTitle1:{
        color:"#4E5A6B",
        fontSize:"0.875rem",
        fontFamily:Bold,
        marginTop:"10px"
    },
     selectBoxParent:{
        position:"absolute",
        top:"0",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width:"100%",
        zIndex:999
    },
    selectBox:{
        backgroundColor:"#5078E1",
        borderRadius:'0px 0px 8px 8px',
        display:"flex",
        alignItems:"center",
        padding:"6px"
        // justifyContent:"center"
    },
    name:{
        color:"#091B29",
        fontFamily:SemiBold,
        fontSize:remCalc(14),
    },
    email:{
        color:"#4E5A6B",
        fontFamily:Regular,
        fontSize:remCalc(12),
    },
    sendBtn:{
        padding:"8px 16px",
        borderRadius:"4px",
        fontFamily:SemiBold,
        fontSize:remCalc(14),
    },
    contactRoot:{
        padding:"12px",
        border:"1px solid #CED3DD",
        borderRadius:"8px",
        backgroundColor:"white"
    }
}));