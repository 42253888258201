import React from "react";
import CreateShortlistContextProvider from "../../contexts/shortlistLedContext";
import { withNavBars } from "../../HOCs";
import { Shortlistedlead } from "./createShortlist";
const ShortlistedleadParent = () => {
  return (
    <CreateShortlistContextProvider>
      <Shortlistedlead />
    </CreateShortlistContextProvider>
  );
};
const props = {
  boxShadow: false
}
export default withNavBars(ShortlistedleadParent, props);
