import React, { Component } from 'react'
import { withNavBars } from '../../HOCs'
import  ReqTable  from './reqTable'

class AccordionParent extends Component {
    render() {
        return (
            <div><ReqTable /></div>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AccordionParent, props)