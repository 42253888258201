import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { IncrementDecrementStyle } from "./style";

export const IncrementDecrement = ({ count = 1, decrease = () => false, view = false, increase = () => false }) => {
    const classes = IncrementDecrementStyle()
    return (
        <>
            <Stack direction={"row"} alignItems={"center"}>
                <Button className={classes.btn} variant="text" onClick={() => decrease(count - 1)} disabled={view ? true : count === 1}>-</Button>
                <Box className={classes.countBox}>
                    <Typography className={classes.count}> {count} </Typography>
                </Box>
                <Button className={classes.btn} variant="text"
                    disabled={view}
                    onClick={() => {
                        increase(count + 1)
                    }}>+</Button>
            </Stack>
        </>
    )
}