import React from "react";
import { withNavBars } from "../../HOCs";
import { FinanceManagerDashboard } from "./financeManagerDashboard";

class FinanceManagerDashboardParent extends React.Component {
    render() {
        return <FinanceManagerDashboard />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(FinanceManagerDashboardParent, props);