import { makeStyles } from "@mui/styles";
import { Bold } from "../../utils";

export const InspectionStyles = makeStyles((theme) => ({
  content: {
    height: `calc(100vh - 100px)`,
    overflow: "auto"
  },
  inspection: {
    padding: "12px",
  },
  inspectionTable: {
    padding: "12px",
    backgroundColor: theme.palette.background.tertiary1,
    border: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 0px 16px #00000014",
    borderRadius: theme.palette.borderRadius,
    marginTop: "12px"
  },

  search: {
    padding: "24px 24px 10px 24px",
  },

  innerCard: {
    margin: "8px",
  },

  cardSection: {
    height: "468px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: "1px solid" + theme.palette.border.secondary,
  },

  assign: {
    padding: "24px",
  },

  assignBtn: {
    width: "100%",
  },
  iconbtn: {
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.border.secondary,
    padding: "8px",
    borderRadius: "50%",
    height: "24px",
    width: "24px",
    cursor: "pointer"
},
icon: {
    fontSize:"0.875rem",
    marginLeft: "-2px",
},
dialoghdrtext:{
    fontSize:"1rem",
    color:theme.typography.color.primary,
    fontFamily: Bold,
}
}));
