import * as React from "react"
const TotalDeductionIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28.368} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.646}
        x2={1.354}
        y1={0.646}
        y2={1.354}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f44f5a" />
        <stop offset={0.443} stopColor="#ee3d4a" />
        <stop offset={1} stopColor="#e52030" />
      </linearGradient>
    </defs>
    <g data-name="icons8-invoice (3)">
      <g data-name="Group 115959">
        <g data-name="Group 115958">
          <path
            fill="#50e6ff"
            d="M26 5.263A1.263 1.263 0 0 0 24.733 4H3.263A1.263 1.263 0 0 0 2 5.263v16.421a1.263 1.263 0 0 0 1.263 1.263h21.47a1.263 1.263 0 0 0 1.263-1.263Z"
            data-name="Path 101046"
          />
          <path
            fill="#057093"
            d="M22.526 9.211h-1.263a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.316h1.263a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.316Z"
            data-name="Path 101048"
          />
          <path
            fill="#057093"
            d="M18.106 9.211H5.474a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.316h12.632a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.316Z"
            data-name="Path 101049"
          />
          <path
            fill="#057093"
            d="M22.526 11.737h-1.263a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.316h1.263a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.316Z"
            data-name="Path 101050"
          />
          <path
            fill="#057093"
            d="M16.843 11.737H5.474a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.316h11.369a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.316Z"
            data-name="Path 101051"
          />
          <path
            fill="#057093"
            d="M22.526 14.266h-1.263a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.316h1.263a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.316Z"
            data-name="Path 101052"
          />
          <path
            fill="#057093"
            d="M18.106 14.266H5.474a.316.316 0 0 1-.316-.316v-.632a.316.316 0 0 1 .316-.316h12.632a.316.316 0 0 1 .316.316v.632a.316.316 0 0 1-.316.316Z"
            data-name="Path 101053"
          />
        </g>
      </g>
      <g data-name="Group 115960">
        <g
          fill="url(#a)"
          data-name="Path 101055"
          transform="translate(52.246 59.93)"
        >
          <path d="M-37.614-32.061a5.559 5.559 0 0 1-5.553-5.553 5.559 5.559 0 0 1 5.553-5.553 5.559 5.559 0 0 1 5.553 5.553 5.559 5.559 0 0 1-5.553 5.553Z" />
          <path
            fill="#fff"
            d="M-37.614-42.667a5.053 5.053 0 1 0 0 10.106 5.053 5.053 0 0 0 0-10.106m0-1a6.06 6.06 0 0 1 6.053 6.053 6.06 6.06 0 0 1-6.053 6.053 6.06 6.06 0 0 1-6.053-6.053 6.06 6.06 0 0 1 6.053-6.053Z"
          />
        </g>
        <path
          fill="#fff"
          d="M12.105 22.568v-.505a.253.253 0 0 1 .253-.253h4.547a.253.253 0 0 1 .253.253v.505a.253.253 0 0 1-.253.253h-4.547a.253.253 0 0 1-.253-.253Z"
          data-name="Path 101056"
        />
      </g>
    </g>
  </svg>
)
export default TotalDeductionIcon
