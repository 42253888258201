import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
// import MyGoogleMap from '../../components/interactivemap/myGoogleMaps';
import { AlertDialog } from '../../components'
import { contactsViewStyles } from "./contactsViewStyle";
import { timeZoneConverter } from "../../utils";


export const ContactsView = (props) => {
    const { t } = (props);
    const classes = contactsViewStyles()
    const [imageViwer, setImageViwer] = React.useState(false)
    // const { data } = props;
    // //eslint-disable-next-line
    // const address = data ? {
    //     latitude: data?.latitude,
    //     longitude: data?.longitude,
    // } : {};


    // const GoogleMap = React.useCallback(() => {
    //     return (
    //         <MyGoogleMap
    //             lat={address?.latitude}
    //             lng={address?.longitude}
    //             center={{
    //                 lat: address?.latitude,
    //                 lng: address?.longitude
    //             }}
    //             zoom={13} readonly />
    //     )
    //     //eslint-disable-next-line
    // }, [address])

    const constructAddress = (_) => {
        let address = ""
        if (!_?.door_no && !_?.street_1 && !_?.street_2 &&
            !_?.landmark && !_?.area && !_?.city &&
            !_?.state && !_?.country && !_?.zipcode) {
            address = "Address Not Available"
        } else {
            address = ((_?.door_no && (_?.door_no + ", ")) ?? "") +
                ((_?.street_1 && (_?.street_1 + ", ")) ?? "") +
                ((_?.street_2 && (_?.street_2 + ", ")) ?? "") +
                ((_?.landmark && (_?.landmark + ", ")) ?? "") +
                ((_?.area && (_?.area + ", ")) ?? "") +
                ((_?.city && (_?.city + ", ")) ?? "") +
                ((_?.state && (_?.state + ", ")) ?? "") +
                ((_?.country && (_?.country + ", ")) ?? "") +
                ((_?.zipcode && (_?.zipcode + ", ")) ?? "")
        }

        return address
    }
    return (
        <>
            <Box className={classes.boxTab}>
                {/* FIRST HALF */}
                <div className={classes.card}>
                    <Stack
                        direction={{ sm: "row", xs: "column" }}
                        // divider={<Divider flexItem orientation={"vertical"} />}
                        // gap={{ xs: 2, sm: 3 }}
                        alignItems={{ sm: "center" }}
                        p={"16px"}
                        bgcolor="#F5F7FA"
                        sx={{ borderRadius: "6px" }}>
                        <Grid item xs={12} md={3} lg={2} className={classes.imgDiv}>
                            <img src={props?.data?.image_url ? props?.data?.image_url : "/images/imagesproperty.svg"} alt="" className={classes.unitImg}
                                onClick={() => setImageViwer(true)} />
                        </Grid>
                        <Grid item xs={12} md={9} lg={10} className={classes.imgDiv2}>
                            <Typography className={classes.title} style={{ marginBottom: '10px' }}>{t("Profile Details")}</Typography>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Name")}</Typography>
                                        <Typography className={classes.sub} noWrap>{props?.data?.first_name ?? "-"}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("No. Of Opportunities")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            {props?.data?.noOfOpportunities?.[0]?.count ?? "-"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Box>
                                        <Typography className={classes.heading}>{t("Created At")}</Typography>
                                        <Typography className={classes.sub} noWrap>
                                            &#x202a;{props?.data?.created_at ?
                                                timeZoneConverter(props?.data?.created_at) : "-"}&#x202c;
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={0} lg={4.5} />
                    </Stack>
                    <br />
                    <Divider />
                    <br />
                    {/* SECOND HALF */}

                    <Grid container spacing={2}>
                        {/* <Grid item xs={4}>
                            <div className={classes.map}>
                                <GoogleMap />
                            </div>
                        </Grid> */}
                        {/* when map component is needed plz use below commented grid item */}
                        {/* <Grid item xs={4}> */}
                        <Grid item xs={6}>
                            <div className={classes.addressRoot}>
                                <Stack direction="row" >
                                    <img src='/images/loc.svg' alt='' />
                                    <Typography className={classes.title}>&nbsp;{t("Address")}</Typography>
                                </Stack>
                                <Box height="12px" />
                                <Typography className={classes.sub}>
                                    {constructAddress(props?.data)}
                                </Typography>
                                <Box height="16px" />
                                <Stack direction="row" >
                                    <Typography className={classes.heading}>{t("Latitude")} : </Typography>
                                    <Typography className={classes.sub1} >&nbsp;{props?.data?.latitude ?? "-"}</Typography>
                                </Stack>
                                <Box height="12px" />
                                <Stack direction="row" >
                                    <Typography className={classes.heading}>{t("Longitude")} : </Typography>
                                    <Typography className={classes.sub1} >&nbsp;{props?.data?.longitude ?? "-"}</Typography>
                                </Stack>
                            </div>
                        </Grid>
                        {/* when map component is needed plz use below commented grid item */}
                        {/* <Grid item xs={4}> */}
                        <Grid item xs={6}>
                            <div className={classes.addressRoot}>
                                <Stack direction="row" >
                                    <img src='/images/loc.svg' alt='' />
                                    <Typography sx={{ marginInlineStart: "4px" }} className={classes.title}>{t("Contact & Other Information")}</Typography>
                                </Stack>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography mt={"6px"} className={classes.heading}>{t("Mobile Number")} :</Typography>
                                        <Typography className={classes.subMob} noWrap>
                                            {(props?.data?.mobile_no && props?.data?.mobile_no !== "") ?
                                                ((props?.data?.mobile_no_country_code ?? "") + (props?.data?.mobile_no_country_code && "-") + props?.data?.mobile_no) : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography mt={"6px"} className={classes.heading}>{t("Alternate Number")} :</Typography>
                                        <Typography className={classes.subMob} noWrap>
                                            {(props?.data?.alternative_mobile && props?.data?.alternative_mobile !== "") ?
                                                ((props?.data?.alternative_mobile_country_code ?? "") + (props?.data?.alternative_mobile_country_code && "-") + props?.data?.alternative_mobile) : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.heading}>{t("Email Address")} :</Typography>
                                        <Typography className={classes.sub2} noWrap>{props?.data?.email_id ?? "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.heading}>{t("Facebook")} :</Typography>
                                        <Typography className={classes.sub2} noWrap>{props?.data?.facebook ?? "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.heading}>{t("Twitter")} :</Typography>
                                        <Typography className={classes.sub2} noWrap>{props?.data?.twitter ?? "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className={classes.heading}>{t("Linkedin")} :</Typography>
                                        <Typography className={classes.sub2} noWrap>{props?.data?.linkedin ?? "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <AlertDialog isNormal isnotTitle component={
                        <div style={{ display: 'flex' }}>
                            <img src={props?.data?.image_url} alt='' style={{ objectFit: 'cover', width: '444px' }} />
                        </div>
                    } open={imageViwer} onClose={() => setImageViwer(false)} />
                </div>
            </Box>

        </>
    )
}