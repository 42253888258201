export const ParkingSlot = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98482">
            <g data-name="021---Free-Parking-Spaces" fill={props?.fill ?? "#c1c5cb"}>
                <path d="M22.75 15.25h-4.5v-7a.25.25 0 0 0-.5 0v7h-4.5v-7a.25.25 0 0 0-.5 0v7h-4.5a.25.25 0 0 0 0 .5h4.5v7a.25.25 0 0 0 .5 0v-7h4.5v7a.25.25 0 0 0 .5 0v-7h4.5a.25.25 0 0 0 0-.5Z" />
                <path d="M9 11.75h3V13H9Z" />
                <path
                    data-name="Shape"
                    d="M12 10.828a.25.25 0 0 1-.11-.025 3.167 3.167 0 0 0-1.39-.302 3.167 3.167 0 0 0-1.39.3.247.247 0 0 1-.11.023v.425h3ZM9.75 14.5h1.5a.75.75 0 0 0 .75-.75v-.25H9v.25a.75.75 0 0 0 .75.75ZM12 10.307V9.75a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 0-.75.75v.557a3.812 3.812 0 0 1 3 0ZM14 20.175a.246.246 0 0 1 .11.023 3.349 3.349 0 0 0 2.779 0 .243.243 0 0 1 .11-.023v-.425H14Z"
                />
                <path data-name="Rectangle-path" d="M14 18h3v1.25h-3Z" />
                <path
                    data-name="Shape"
                    d="M14 20.693v.557a.75.75 0 0 0 .75.75h1.5a.75.75 0 0 0 .75-.75v-.557a3.812 3.812 0 0 1-3 0ZM16.25 16.5h-1.5a.75.75 0 0 0-.75.75v.25h3v-.25a.75.75 0 0 0-.75-.75Z"
                />
                <path data-name="Rectangle-path" d="M19 10.5h3v1.25h-3Z" />
                <path
                    data-name="Shape"
                    d="M20.5 13a3.167 3.167 0 0 0 1.39-.3.246.246 0 0 1 .11-.023v-.427h-3v.425a.246.246 0 0 1 .11.023A3.167 3.167 0 0 0 20.5 13ZM19.75 14.499h1.5a.75.75 0 0 0 .75-.75v-.557a3.812 3.812 0 0 1-3 0v.557a.75.75 0 0 0 .75.75ZM22 9.75a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 0-.75.75V10h3ZM21.89 18.3a3.349 3.349 0 0 0-2.78 0 .25.25 0 0 1-.11.023v.425h3v-.423a.25.25 0 0 1-.11-.025Z"
                />
                <path data-name="Rectangle-path" d="M19 19.25h3v1.25h-3Z" />
                <path
                    data-name="Shape"
                    d="M19 21.25a.75.75 0 0 0 .75.75h1.5a.75.75 0 0 0 .75-.75V21h-3ZM21.25 16.5h-1.5a.75.75 0 0 0-.75.75v.557a3.812 3.812 0 0 1 3 0v-.557a.75.75 0 0 0-.75-.75ZM10.25 17H9.5a.25.25 0 0 0-.25.25v4.25a.25.25 0 0 0 .5 0V20h.5a1.5 1.5 0 0 0 0-3Zm0 2.5h-.5v-2h.5a1 1 0 0 1 0 2ZM14.5 14a.25.25 0 0 0 .25-.25v-1.5h.5a1.5 1.5 0 0 0 0-3h-.75a.25.25 0 0 0-.25.25v4.25a.25.25 0 0 0 .25.25Zm.75-4.25a1 1 0 0 1 0 2h-.5v-2Z"
                />
            </g>
            <path data-name="Rectangle 52160" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
