import { Box, Button, Divider, Radio, Stack, Typography } from '@mui/material'
import React from 'react'
import { enumSelect, enum_types } from '../../../utils';
import { CreateEditContext } from '../create/createEditContext';
import { RateConfigComponent } from './rateConfigComponent';
import { RateConfigComponentStyle } from './style';

export const RateConfiguration = ({
    t
}) => {

    const { state, commissionList, addCommission, resetCommission, setSelectedCommissionEnum } = React.useContext(CreateEditContext)

    const classes = RateConfigComponentStyle()

    const [selectedValue, setSelectedValue] = React.useState('a');
    const [enumData, setEnumData] = React.useState([])

    const handleChange = (value) => {
        setSelectedValue(value);
    };

    React.useEffect(() => {
        getEnum()
        if (Object.keys(state?.viewData)?.length > 0) {
            switch (state?.viewData?.commission?.[0]?.commission_rate_type) {
                case "Fixed Pricing":
                    setSelectedValue("a")
                    break
                case "Percentage Base":
                    setSelectedValue("b")
                    break
                case "Custom":
                    setSelectedValue("c")
                    break
                default:
                    return false;
            }
        }
        // eslint-disable-next-line
    }, [state])

    const getEnum = async () => {
        const result = await enumSelect([enum_types?.contract_commission_rate_type]);
        setEnumData(result?.contract_commission_rate_type);
        setSelectedCommissionEnum(result?.contract_commission_rate_type?.[0]?.value)
    };

    const radio = {
        1: { radioValue: "a", subtitle: t("Select the incentive factors to apply fixed pricing modelling") },
        2: { radioValue: "b", subtitle: t("Enter the percentage value to apply percentage base modelling") },
        3: { radioValue: "c", subtitle: t("It will be customised modelling") },
    }
    let mapData = enumData?.map((e, i) => {
        return {
            label: e?.label,
            value: e?.value,
            ...radio?.[i + 1]
        }
    })


    return (
        <>
            {
                mapData?.map((d, i, length) => {
                    return (
                        <Box pl={3} pt={1.5} pb={2}>

                            <Stack direction={"row"} spacing={2} onClick={() => {
                                if (state?.type !== "view") {
                                    resetCommission(d)
                                    handleChange(d?.radioValue)
                                }
                            }}
                                sx={{ cursor: "pointer" }}>
                                <Radio
                                    checked={selectedValue === d?.radioValue}
                                    value={d?.radioValue}
                                    name="radio-buttons"
                                // inputProps={{ 'aria-label': 'A' }}
                                />
                                <Stack>
                                    <Typography className={classes.title}> {d?.label} </Typography>
                                    <Typography className={classes.subtitle}> {d?.subtitle} </Typography>
                                </Stack>
                            </Stack>
                            <Box ml={7.5} mt={2} pb={2} mb={2}>
                                {
                                    (selectedValue === d?.radioValue && selectedValue !== 'c') &&
                                    commissionList?.map((row, i) => {
                                        return (
                                            <Box mb={1}>
                                                <RateConfigComponent t={t} selectedValue={selectedValue} data={d} rowData={row} index={i} />
                                            </Box>
                                        )
                                    })
                                }


                                {
                                    (selectedValue === d?.radioValue && selectedValue !== 'c') &&
                                    <Box mt={2}>
                                        <Button variant="outlined" className={classes.btn} onClick={() => {
                                            addCommission()
                                        }}
                                            disabled={state?.type === "view"}>
                                            {selectedValue === 'a' ? t("Add Pricing Factor") : t("Add Percentage Factor")}
                                        </Button>
                                    </Box>
                                }
                            </Box>


                            {i !== length?.length - 1 && <Divider></Divider>}

                        </Box>
                    )
                })
            }
        </>
    )
}