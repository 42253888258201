import React from "react";
import { withNavBars } from "../../HOCs";
import CorrespondenceType from "./correspondenceType";

class CorrespondenceTypeParent extends React.Component {
  render() {
    return <CorrespondenceType {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(CorrespondenceTypeParent, props);
