import * as React from "react"

export const Lead = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 105462">
      <path data-name="Rectangle 55517" fill="none" d="M0 0h20v20H0z" />
      <path
        d="M9.993 2a.417.417 0 0 0-.41.422v2.5a.417.417 0 1 0 .833 0v-2.5A.417.417 0 0 0 9.993 2ZM5.779 3.614a.417.417 0 0 0-.291.715l1.768 1.768a.417.417 0 1 0 .589-.583l-1.768-1.77a.417.417 0 0 0-.3-.126Zm8.43 0a.417.417 0 0 0-.286.126l-1.768 1.774a.417.417 0 1 0 .589.589l1.768-1.768a.417.417 0 0 0-.3-.715ZM10 6.173a2.079 2.079 0 0 0-1.561.666 2.185 2.185 0 0 0 0 2.835 2.163 2.163 0 0 0 3.122 0 2.185 2.185 0 0 0 0-2.835A2.079 2.079 0 0 0 10 6.173Zm0 1.25a.75.75 0 0 1 .627.246.938.938 0 0 1 0 1.175.75.75 0 0 1-.627.246.75.75 0 0 1-.627-.246.938.938 0 0 1 0-1.175.75.75 0 0 1 .627-.246Zm-6.25 1.25a2.079 2.079 0 0 0-1.561.666 2.184 2.184 0 0 0 0 2.835 2.079 2.079 0 0 0 1.561.666 2.079 2.079 0 0 0 1.561-.666 2.185 2.185 0 0 0 0-2.835 2.079 2.079 0 0 0-1.561-.666Zm12.5 0a2.079 2.079 0 0 0-1.561.666 2.185 2.185 0 0 0 0 2.835 2.163 2.163 0 0 0 3.122 0 2.185 2.185 0 0 0 0-2.835 2.079 2.079 0 0 0-1.561-.666Zm-12.5 1.25a.75.75 0 0 1 .627.246.938.938 0 0 1 0 1.175.75.75 0 0 1-.627.246.75.75 0 0 1-.627-.246.938.938 0 0 1 0-1.175.75.75 0 0 1 .627-.246Zm12.5 0a.75.75 0 0 1 .627.246.938.938 0 0 1 0 1.175.922.922 0 0 1-1.253 0 .938.938 0 0 1 0-1.175.75.75 0 0 1 .626-.246Zm-8.728 1.25a1.282 1.282 0 0 0-1.272 1.272v3.29a2.256 2.256 0 0 0 1.15 1.847 4.82 4.82 0 0 0 2.6.675 4.818 4.818 0 0 0 2.6-.675 2.257 2.257 0 0 0 1.155-1.847v-3.29a1.282 1.282 0 0 0-1.272-1.272Zm0 1.25h4.956c.019 0 .022 0 .022.022v3.29c0 .231-.151.522-.576.793a3.65 3.65 0 0 1-1.924.479 3.65 3.65 0 0 1-1.924-.479 1.02 1.02 0 0 1-.576-.793v-3.29c0-.02 0-.022.022-.022Zm-6.25 1.25A1.274 1.274 0 0 0 0 14.945v.79c0 1.438 1.612 2.522 3.75 2.522a5 5 0 0 0 2.458-.589 2.807 2.807 0 0 1-.792-1.933v-2.062Zm13.311 0v2.062a2.807 2.807 0 0 1-.792 1.933 5 5 0 0 0 2.458.589c2.138 0 3.75-1.084 3.75-2.522v-.79a1.274 1.274 0 0 0-1.272-1.272Z"
        fill={props?.color ?? "#fff"}
      />
    </g>
  </svg>
)
