import React from 'react'
import { useStyles } from '../styles'
import { Box, Grid, Typography } from '@mui/material'

export const RegisteredDetails = ({
    t = () => false,
    data = ""
}) => {
    const classes = useStyles()
    return (
        <>
            <Box className={classes.box} mt={2}>
                <Typography className={classes.title} marginBottom={"14px"}>{t("Registered Details")}</Typography>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("Registered Company")}</Typography>
                        <Typography className={classes.sub}>
                            {data?.is_registered ? "Yes" : "No"}
                        </Typography>
                    </Grid>
                    {
                        data?.is_registered &&
                        <Grid item xs={3}>
                            <Typography className={classes.heading}>{t("CR Number")}</Typography>
                            <Typography className={classes.sub}>
                                {data?.cr_number}
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={3}>
                        <Typography className={classes.heading}>{t("Tax Registered")}</Typography>
                        <Typography className={classes.sub}>
                            {data?.is_tax_registered ? "Yes" : "No"}
                        </Typography>
                    </Grid>
                    {
                        data?.is_tax_registered &&
                        <Grid item xs={3}>
                            <Typography className={classes.heading}>{t("Tax Number")}</Typography>
                            <Typography className={classes.sub}>
                                {data?.tax_number}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        </>
    )
}