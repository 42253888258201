

import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
import { Box, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from "react";
import { dates } from "./utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '45px',
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
        cursor: "pointer",
        fontFamily: Bold,
        fontSize:"0.75rem",
        backgroundColor: (props) => props?.value ? "#5078E1" : "white",
        alignItems: "center",
        padding: "8px"

    },
    dropDown: {
        padding: "8px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        position: "absolute",
        top: "auto",
        backgroundColor: "white",
        cursor: "pointer",
        height: "130px",
        overflow: "auto",
        left: 0,
        right: 0,
        marginTop: "2px",
        zIndex: 999,
    },
    font: {
        fontFamily: Bold,
        fontSize:"0.75rem",
        color: (props) => props?.value ? "white" : "#98A0AC"
    },
    fontDate: {
        fontFamily: Bold,
        fontSize:"0.75rem",
        color: "#98A0AC"
    }
}));



export const AgreementCustomDate = ({ value = null, onChange = () => false, disabled = false }) => {

    const classes = useStyles({ value: dates?.find(i => i?.label === value)?.value });
    const [open, setOpen] = React.useState(false);


    return (
        <Box position="relative" >
            <Box className={classes.root} onClick={() => {
                if (!disabled) {
                    setOpen(!open)
                }
            }}>
                <Box display="flex" alignItems="center">
                    <Box>
                        <Typography className={classes.font}>{dates?.find(i => i?.label === value)?.value ?? "00"}</Typography>
                    </Box>
                    <Box marginLeft="2px">
                        <ArrowDropDownIcon fontSize="small" style={{ color: dates?.find(i => i?.label === value)?.value ? "white" : "98A0AC", marginTop: "4px" }} />
                    </Box>
                    <Box marginLeft="4px">
                        <Typography className={classes.font}>of Cycle</Typography>
                    </Box>
                </Box>

            </Box>
            {
                open &&
                <Box className={classes.dropDown}>
                    <Stack direction="row">
                        <Typography className={classes.fontDate}>Select Date</Typography>
                    </Stack>
                    {
                        dates?.map((val) => {
                            return (
                                <Typography onClick={() => {
                                    setOpen(false)
                                    onChange(val?.label)

                                }}
                                    style={{ color: "#091B29", marginTop: "2px" }}
                                    className={classes.font}>{val?.value}</Typography>
                            )
                        })
                    }

                </Box>
            }

        </Box>
    )
}