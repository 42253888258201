export const InventoryCheckList = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-1{fill:#c1c5cb}"}</style>
        </defs>
        <g
            id="Group_102124"
            data-name="Group 102124"
            transform="translate(24750 -3960)"
        >
            <g id="Glyph" transform="translate(-24743 3966.999)">
                <g id="Glyph-2" data-name="Glyph" transform="translate(0 .001)">
                    <path
                        id="Path_96817"
                        data-name="Path 96817"
                        className="cls-1"
                        d="m26.18 24.911-3.244 3a.612.612 0 0 0-.062.828l.259.323a.613.613 0 0 0 .889.068l2.836-2.62a.052.052 0 0 1 .068 0l2.836 2.62a.614.614 0 0 0 .889-.068l.258-.323a.611.611 0 0 0-.063-.828l-3.244-3a1.054 1.054 0 0 0-1.422 0Z"
                        transform="translate(-15.042 -16.295)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Path_96818"
                        data-name="Path 96818"
                        className="cls-1"
                        d="M1.524 19.558h7.118a.169.169 0 0 0 .135-.271 1.5 1.5 0 0 1-.313-.914v-.339a.169.169 0 0 0-.169-.169H2.2a.508.508 0 0 1-.508-.508V7.2a.508.508 0 0 1 .508-.507h.339a.169.169 0 0 0 .169-.169V5.169A.169.169 0 0 0 2.539 5H1.524A1.524 1.524 0 0 0 0 6.524v11.511a1.524 1.524 0 0 0 1.524 1.523Z"
                        transform="translate(0 -3.307)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Path_96819"
                        data-name="Path 96819"
                        className="cls-1"
                        d="M31.016 7.2v4.034a.169.169 0 0 0 .223.158 1.4 1.4 0 0 1 1.209.139.169.169 0 0 0 .261-.142V6.524A1.524 1.524 0 0 0 31.185 5h-1.016a.169.169 0 0 0-.169.169v1.524h.508a.508.508 0 0 1 .508.508Z"
                        transform="translate(-19.843 -3.307)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Path_96820"
                        data-name="Path 96820"
                        className="cls-1"
                        d="M30.908 37.159a1.185 1.185 0 0 0 1.185-1.185v-1.642a.169.169 0 0 0-.108-.157.945.945 0 0 1-.3-.187l-2.525-2.333a.169.169 0 0 0-.23 0l-2.53 2.332a.951.951 0 0 1-.3.187.169.169 0 0 0-.108.157v1.643a1.185 1.185 0 0 0 1.186 1.185h.677v-2.37a.508.508 0 0 1 .508-.508h1.354a.508.508 0 0 1 .508.508v2.37Z"
                        transform="translate(-17.196 -20.909)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Path_96821"
                        data-name="Path 96821"
                        className="cls-1"
                        d="M9.846 4.4h5.078a.846.846 0 0 0 .846-.846V1.862a.846.846 0 0 0-.846-.846h-1a1.681 1.681 0 0 0-3.088 0h-1A.846.846 0 0 0 9 1.862v1.693a.846.846 0 0 0 .846.845Zm2.539-3.216a.508.508 0 1 1-.508.508.508.508 0 0 1 .509-.507Z"
                        transform="translate(-5.953 -.001)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <rect
                        id="Rectangle_53904"
                        data-name="Rectangle 53904"
                        className="cls-1"
                        width={2.37}
                        height={1.016}
                        rx={0.508}
                        transform="translate(7.618 6.602)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Path_96822"
                        data-name="Path 96822"
                        className="cls-1"
                        d="M8 18.539a.846.846 0 0 0 .846.846h1.693a.846.846 0 0 0 .846-.846V18.2a.169.169 0 1 0-.339 0v.339a.508.508 0 0 1-.508.508H8.846a.508.508 0 0 1-.508-.508v-1.693a.508.508 0 0 1 .508-.508h2.031a.169.169 0 0 0 0-.339H8.846a.846.846 0 0 0-.846.847Z"
                        transform="translate(-5.292 -10.583)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Path_96823"
                        data-name="Path 96823"
                        className="cls-1"
                        d="M14.163 18.049a.169.169 0 0 0-.239 0l-1.1 1.1a.169.169 0 0 1-.239 0l-.529-.528a.169.169 0 1 0-.239.239l.529.528a.508.508 0 0 0 .718 0l1.1-1.1a.169.169 0 0 0 0-.24Z"
                        transform="translate(-7.78 -11.906)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Path_96824"
                        data-name="Path 96824"
                        className="cls-1"
                        d="M8.846 29.339h2.031a.169.169 0 1 0 0-.339H8.846a.846.846 0 0 0-.846.846v1.693a.846.846 0 0 0 .846.846h1.693a.846.846 0 0 0 .846-.846V31.2a.169.169 0 0 0-.339 0v.339a.508.508 0 0 1-.508.508H8.846a.508.508 0 0 1-.508-.508v-1.693a.508.508 0 0 1 .508-.507Z"
                        transform="translate(-5.292 -19.182)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Path_96825"
                        data-name="Path 96825"
                        className="cls-1"
                        d="M14.163 31.049a.169.169 0 0 0-.239 0l-1.1 1.1a.169.169 0 0 1-.239 0l-.529-.528a.169.169 0 1 0-.239.239l.529.528a.508.508 0 0 0 .718 0l1.1-1.1a.169.169 0 0 0 0-.24Z"
                        transform="translate(-7.78 -20.505)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                </g>
            </g>
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24750 3960)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
        </g>
    </svg>
)
