import React from "react";
import { withNavBars } from "../../HOCs";
import VisitorandSecurityDashboard  from "./visitorandsecuritymanagerdashboard";
class VisitorandSecurityDashboardParent extends React.Component {
  render() {
    return (
        <VisitorandSecurityDashboard {...this.props} />
    );
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(VisitorandSecurityDashboardParent,props);