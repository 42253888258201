export const receiptPath = ["receipt_no", "receipt_date", "account_no", "account_name", "receipt_amount", "payment_method", "reference", "unused_balance"]

export const getReceiptHeading = (t) => {
    const receiptheading = [
        { title: t("receiptNumber"), field: "receipt_no", },
        { title: t("Payment Date"), field: "receipt_date", },
        { title: t("accountNumber"), field: "account_no", },
        { title: t("accountName"), field: "account_name", },
        { title: t("receivedAmount"), field: "receipt_amount", },
        { title: t("paymentMethod"), field: "payment_method" },
        { title: t("reference"), field: "reference" },
        { title: t("receiptDate"), field: "created_at" },
        // { title: t("source"), field: "source" },
        { title: t("unusedBalance"), field: "unused_balance", },
    ]
    return receiptheading
}

export const receiptdataType = [
    { type: ["text"], name: "receipt_no" },
    { type: ["date"], name: "receipt_date" },
    { type: ["text"], name: "account_no" },
    { type: ["text"], name: "account_name" },
    { type: ["text"], name: "receipt_amount" },
    { type: ["text"], name: "payment_method" },
    { type: ["text"], name: "reference" },
    { type: ["date"], name: "created_at" },
    // { type: ["text"], name: "source" },
    { type: ["unused_balance"], name: "unused_balance" },


]





export const getAccountDetails = (t, details) => {
    const data = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("accountNumber"),
            subtitle: details?.subTitle1
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("accountName"),
            subtitle: details?.name
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("accountType"),
            subtitle: details?.account_type ?? "-"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("email"),
            subtitle: details?.email_id ?? "-"
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 3
            },
            isActive: true,
            component: "text",
            heading: t("mobile_no"),
            subtitle: `${details?.mobile_no_country_code ?? "-"} ${details?.mobile_no ?? "-"}`
        },
    ]
    return data
}

export const accountFormState = {
    amountTopay: "",
    paymentmethod: "",
    bankName: "",
    branch: "",
    chequeNo: "",
    depoiteDate: "",
    clearanceStatus: "",
    available_balance: 0,
    total: 0,
    paymentDate:new Date(),
    error: {
        amountTopay: "",
        paymentmethod: "",
        bankName: "",
        branch: "",
        chequeNo: "",
        depoiteDate: "",
        clearanceStatus: ""
    }
}

export const invoicePath = ["selected", "invoice_id", "agreement_id", "invoice_generate_date", "status", "pending_amount", "total_amount"]

export const getInvoiceHeading = (t, selected = 0) => {
    const receiptheading = [
        { title: `${selected} ${t("selected")}`, field: "invoice_id", },
        { title: t("invoiceId"), field: "invoice_id", },
        { title: t("agreementId"), field: "agreement_id", },
        { title: t("generatedDate"), field: "invoice_generate_date", },
        { title: t("status"), field: "status", },
        { title: t("pendingAmount"), field: "pending_amount", },
        { title: t("totalAmount"), field: "total_amount" },
        { title: "", field: "link" },
    ]
    return receiptheading
}

export const invoicedataType = [
    { type: ["inputBox"], name: "selected" },
    { type: ["text"], name: "invoice_id" },
    { type: ["text"], name: "agreement_id" },
    { type: ["date"], name: "invoice_generate_date" },
    { type: ["status"], name: "status" },
    { type: ["text"], name: "pending_amount" },
    { type: ["text"], name: "total_amount" },
    { type: ["link"], name: "link" },

]



export const getReceiptDetails = (t, details, currency) => {
    const data = [
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("customerName"),
            subtitle: details?.account?.name
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("customerMobile"),
            subtitle: `${details?.account?.mobile_no_country_code} ${details?.account?.mobile_no}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("paymentMethod"),
            subtitle: details?.payment_mode
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: details?.payment_mode === "Cheque",
            component: "text",
            heading: t("bankName"),
            subtitle: details?.bank_name
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: details?.payment_mode === "Cheque",
            component: "text",
            heading: t("chequeNo"),
            subtitle: details?.cheque_no
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: details?.payment_mode === "Cheque",
            component: "status",
            heading: t("paymentStatus"),
            status: "Pending",
            color: details?.cheque_status
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("paymentAmount"),
            subtitle: `${currency} ${details?.amount_paid}`
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "date",
            heading: t("paymentTime"),
            subtitle: details?.created_at
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("accountNo"),
            subtitle: details?.account?.account_no
        },
        {
            size: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 2.4
            },
            isActive: true,
            component: "text",
            heading: t("receiptGenBy"),
            subtitle: details?.generated_by?.name
        },
    ]
    return data
}

export const historyPath = ["reciepts_no", "settlement_no", "datetime", "account_no", "agreement_id", "invoice_no", "invoice_date", "invoice_due_amount", "amount_settled"]

export const getHistoryHeading = (t) => {
    const historyheading = [
        { title: t("receiptNo"), field: "reciepts_no", },
        { title: t("settlement_no"), field: "settlement_no", },
        { title: t("datetime"), field: "datetime", },
        { title: t("account_no"), field: "account_no", },
        { title: t("agreementNo"), field: "agreement_id", },
        { title: t("invoiceNo"), field: "invoice_no", },
        { title: t("invoice_date"), field: "invoice_date", },
        { title: t("invoiceDue"), field: "invoice_due_amount" },
        { title: t("amount_settled"), field: "amount_settled" },
    ]
    return historyheading
}

export const historydataType = [
    { type: ["text"], name: "reciepts_no" },
    { type: ["text"], name: "settlement_no" },
    { type: ["date"], name: "datetime" },
    { type: ["text"], name: "account_no" },
    { type: ["text"], name: "agreement_id" },
    { type: ["text"], name: "invoice_no" },
    { type: ["date"], name: "invoice_date" },
    { type: ["text"], name: "invoice_due_amount" },
    { type: ["text"], name: "amount_settled" },

]
