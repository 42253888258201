import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular } from "../../utils";
export const ReceiptStyles = makeStyles((theme) => ({
    root: {
        padding: "12px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 16px #00000014",
        borderRadius: "4px"
    },
    tabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: Bold,
    },
    tabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: Bold,
    },
    subtabtitle: {
        fontSize:"0.75rem",
        color: '#4E5A6B',
        fontFamily: Regular,
    },
    subtabtitle1: {
        fontSize:"0.75rem",
        color: '#5078E1',
        fontFamily: Regular,
    },
    selectBack1: {
        backgroundColor: '#F1F7FF',
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    selectBack: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
    },

}));