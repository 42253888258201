import * as React from "react"

const AccountIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width={40} height={40}>
        <g data-name="Group 99043" transform="translate(-128 -490)">
            <rect
                data-name="NoPath - Copy (7)"
                width={40}
                height={40}
                rx={8}
                transform="translate(128 490)"
                fill="#eef9ee"
            />
            <path
                d="M147.563 502.004a2.2 2.2 0 0 0-1.361.47l-5.61 4.42a.6.6 0 0 0 .371 1.067h13.2a.6.6 0 0 0 .371-1.071l-5.609-4.42a2.2 2.2 0 0 0-1.362-.466Zm0 1.2a1 1 0 0 1 .619.216l4.251 3.341h-9.74l4.251-3.348a1 1 0 0 1 .619-.216Zm0 1.165a.8.8 0 1 0 .8.8.8.8 0 0 0-.8-.808Zm-5.8 4.4v5.248a1.8 1.8 0 0 0 .4 3.552h4.2v-1.2h-4.2a.6.6 0 1 1 0-1.2h4.2v-1.2h-.8v-5.2h-1.2v5.2h-1.4v-5.2Zm5.2 0v3.056a1.353 1.353 0 0 1 .8-.256h.4v-2.8Zm2.6 0v2.8h1.2v-2.8Zm2.6 0v2.8h1.2v-2.8Zm-4 3.6a1.009 1.009 0 0 0-1 1v4.4a1.009 1.009 0 0 0 1 1h7.2a1.009 1.009 0 0 0 1-1v-4.4a1.009 1.009 0 0 0-1-1Zm.2 1.2h6.8v.8h-6.8Zm0 2h6.8v2h-6.8Z"
                fill="#5ac782"
            />
        </g>
    </svg>
)

export default AccountIcon
