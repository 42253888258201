import * as React from "react"

const ShortListIMG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={11} height={11} {...props}>
        <path
            data-name="Path 93461"
            d="M10.236 11H.764A.737.737 0 0 1 0 10.294V4.912a2.047 2.047 0 0 1 .874-1.663L5.217.091a.488.488 0 0 1 .567 0l4.344 3.159A2.048 2.048 0 0 1 11 4.911v5.384a.737.737 0 0 1-.764.705ZM2.75 6.267v.91H5v2.066h1V7.177h2.25v-.91H6V4.2H5v2.067Z"
            fill={props?.color}
        />
    </svg>
)

export default ShortListIMG
