import { Grid } from "@mui/material";
import React from "react";
import { PropertyFinderTabs } from "../../components";
import { withNavBars } from "../../HOCs";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { finderStyles } from "./style";
import { Commercial } from "./tab/commercial";
import { Lease } from "./tab/lease";
import { Residential } from "./tab/residential";
import { Storage } from "./tab/strorage";
const PropertyFinders = () => {
    const classes = finderStyles()
    const [value, setValue] = React.useState(1);
    const size = useWindowDimensions()
    const tabTittle = [
        {
            label: 'Residential',
            value: 1
        },
        {
            label: 'Commercial',
            value: 2
        },
        {
            label: 'Storage',
            value: 3
        },
        {
            label: "Short Term Lease",
            value: 4
        }
    ]
    const handleChange = (value) => {
        setValue(value);
    };

    return (

        <div style={{ height: size?.height - 50 }} className={classes.root}>

            <div className={classes.card}>
                <Grid container >
                    <Grid item xs={10}>
                        <PropertyFinderTabs value={value} handleChange={handleChange} list={tabTittle} />
                    </Grid>
                </Grid>
                {value === 1 && <Residential />}
                {value === 2 && <Commercial />}
                {value === 3 && <Storage />}
                {value === 4 && <Lease />}

            </div>


        </div>
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(PropertyFinders, props)