import * as React from "react"
const NonSelectedUnit = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 115542">
      <g data-name="icons8-property (2)">
        <path
          fill="#e4e8ee"
          d="M19.894 7.789v13.158A1.052 1.052 0 0 1 18.842 22H5.158a1.052 1.052 0 0 1-1.053-1.053V3.053A1.052 1.052 0 0 1 5.158 2h8.947Z"
          data-name="Path 100979"
        />
        <path
          fill="#959eaa"
          d="M14.105 2v4.737a1.052 1.052 0 0 0 1.053 1.053h4.737Z"
          data-name="Path 100980"
        />
        <path
          fill="#ccc"
          d="M16.21 18.842H7.789a.527.527 0 0 1-.526-.526V12.79L12 8.316l4.737 4.474v5.526a.527.527 0 0 1-.527.526Z"
          data-name="Path 100981"
        />
        <path
          fill="#959eaa"
          d="M8.842 14.105H12v4.737H8.842Z"
          data-name="Path 100982"
        />
        <path
          fill="#959eaa"
          d="m17.393 12.66-5.045-4.475a.527.527 0 0 0-.7 0L6.603 12.66a.263.263 0 0 0-.017.376l.431.459a.263.263 0 0 0 .366.018l4.614-4.032 4.614 4.032a.263.263 0 0 0 .366-.018l.431-.459a.263.263 0 0 0-.015-.376Z"
          data-name="Path 100983"
        />
        <path
          fill="#959eaa"
          d="M13.315 14.105h1.579a.263.263 0 0 1 .263.263v1.579a.263.263 0 0 1-.263.263h-1.579a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263Z"
          data-name="Path 100984"
        />
      </g>
    </g>
  </svg>
)
export default NonSelectedUnit
