export const SLA = (props) =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g id="Group_115989" data-name="Group 115989" transform="translate(4807.666 7031)">
          <path id="icons8-image_2_" data-name="icons8-image (2)" d="M8.75,6A2.75,2.75,0,0,0,6,8.75V17.7l3.753-3.63a2.067,2.067,0,0,1,2.813,0l3.089,2.988,1.024-.99a2.073,2.073,0,0,1,2.813,0L24,20.429V8.75A2.75,2.75,0,0,0,21.25,6Zm9.5,3.5A2.25,2.25,0,1,1,16,11.75,2.253,2.253,0,0,1,18.25,9.5Zm0,1.5a.75.75,0,1,0,.75.75A.75.75,0,0,0,18.25,11Zm-7.091,4a.52.52,0,0,0-.363.144L6,19.788V21.25a2.75,2.75,0,0,0,2.5,2.737L14.577,18.1l-3.054-2.954A.523.523,0,0,0,11.159,15Zm6.928,2a.521.521,0,0,0-.363.143L10.639,24H21.25a2.75,2.75,0,0,0,2.537-1.69l-5.336-5.162A.524.524,0,0,0,18.087,17Z" transform="translate(-4813.666 -7037)" fill={props?.color?props?.color:"#98a0ac"} opacity="0"/>
          <path id="icons8-agreement-new" d="M10.025,4A2.025,2.025,0,0,0,8,6.025v13.95A2.025,2.025,0,0,0,10.025,22h10.35A2.025,2.025,0,0,0,22.4,19.975V11.2H17.225A2.025,2.025,0,0,1,15.2,9.175V4Zm6.525.4V9.175a.676.676,0,0,0,.675.675H22ZM11.375,13h7.65a.675.675,0,0,1,0,1.35h-7.65a.675.675,0,0,1,0-1.35Zm0,2.7h3.15a.675.675,0,0,1,0,1.35h-3.15a.675.675,0,1,1,0-1.35Zm6.3,0a.675.675,0,0,1,.675.675v.675h.675a.675.675,0,0,1,0,1.35H18.35v.675a.675.675,0,0,1-1.35,0V18.4h-.675a.675.675,0,0,1,0-1.35H17v-.675A.675.675,0,0,1,17.675,15.7Zm-6.3,2.7h2.7a.675.675,0,0,1,0,1.35h-2.7a.675.675,0,1,1,0-1.35Z" transform="translate(-4813.666 -7035)" fill={props?.color?props?.color:"#98a0ac"}/>
        </g>
      </svg>
      

    )
}