import React from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DeleteIcon } from '../../../assets';
import { AttachmentStyle } from '../sections/styles';
import { DocumentViewer } from '../../../components/fileViewer';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DialogBox } from '../../../components';

export const AttachmentCard = ({ val = "", deleteFile = () => false }) => {
    const classes = AttachmentStyle()
    const [hoverContent, setHoverContent] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    // const [selected, setSelected] = React.useState(val)

    const handleDownload = () => {
        document.getElementById('download').click();
    }
    return (
        <>
            <Box style={{ backgroundImage: val?.file_meta?.type === "pdf" ? "" : `url(${val?.url})` }} className={classes.img}
                onMouseOver={() => setHoverContent(true)}
                onMouseLeave={() => setHoverContent(false)}>
                {val?.file_meta?.type === "pdf" &&
                    <center><Box sx={{
                        height: 150,
                        width: "100%"
                    }}>
                        <DocumentViewer url={val?.url} />
                    </Box></center>
                }
                {
                    hoverContent &&
                    <Box className={classes.hoverContent}>
                        <Stack justifyContent={"space-between"} height={"100%"}>
                            <Stack direction={"row"} spacing={1} p={"4px"}>
                                <IconButton
                                    className={classes.viewBtn}
                                    onClick={(e) => {
                                        setOpen(true)
                                        e.stopPropagation()
                                    }}
                                    size="small"
                                >
                                    <VisibilityIcon style={{ fontSize: "18px", color: "#000" }} />
                                </IconButton>
                                <IconButton
                                    className={classes.viewBtn}
                                    onClick={(e) => {
                                        handleDownload()
                                        e.stopPropagation()
                                    }}
                                    size="small"
                                >
                                    <DownloadIcon style={{ fontSize: "18px", color: "#000" }} />
                                </IconButton>
                            </Stack>
                            <Box sx={{ display: "flex", justifyContent: "end" }}>
                                <IconButton
                                    className={classes.delete}
                                    onClick={(e) => {
                                        deleteFile(val)
                                        e.stopPropagation()
                                    }}
                                    size="small"
                                >
                                    <DeleteIcon style={{ fontSize: "0.75rem" }} />
                                </IconButton>
                            </Box>
                            <Stack p={"0px 0px 12px 12px"}>
                                <a href={val?.url} id="download" style={{display: "none"}} download={val?.file_meta?.name}>download</a>
                                <Typography className={classes.contentText}>{val?.file_meta?.name}</Typography>
                                <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                                    <Typography className={classes.contentText}>{val?.file_meta?.size ?? ""}</Typography>
                                    <Box className={classes.dot}></Box>
                                    <Typography className={classes.contentText}>{val?.file_meta?.type ?? ""}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                }
            </Box>

            <DialogBox
                open={open}
                maxWidth={"md"}
                onClose={() => setOpen(false)}
                header={"View"}
                component={
                    <Box sx={{ height: "500px" }} p={1}>
                        {
                            val?.file_meta?.type === "pdf" ?
                                <center><DocumentViewer url={val?.url} /></center> :
                                <img src={val?.url} style={{
                                    height: "500px",
                                    width: "100%"
                                }} alt="" />
                        }
                    </Box>
                }
            />
        </>
    )
}