import React from "react"

export const GoBack = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="80.5" height="80" viewBox="0 0 80.5 80">
  <g id="Group_113263" data-name="Group 113263" transform="translate(-15518.5 -7261)">
    <rect id="icons8-cardboard-box-48" width="80" height="80" transform="translate(15518.5 7261)" fill="#fff"/>
    <g id="icons8-logout-rounded-left" transform="translate(15534.5 7267)">
      <path id="Path_99313" data-name="Path 99313" d="M34,4A30,30,0,1,1,4,34,30.034,30.034,0,0,1,34,4Z" fill="none" stroke="rgba(255,147,64,0.64)" stroke-width="1"/>
      <path id="Path_99316" data-name="Path 99316" d="M29.887,4A25.887,25.887,0,1,1,4,29.887,25.916,25.916,0,0,1,29.887,4Z" transform="translate(4.113 4.113)" fill="#fff4eb"/>
      <path id="Path_99314" data-name="Path 99314" d="M4,29,22,14V44Z" transform="translate(-20 5)" fill="#ff9340"/>
      <path id="Path_99315" data-name="Path 99315" d="M14,21H48.848v9H14Z" transform="translate(-15 8.5)" fill="#ff9340"/>
    </g>
  </g>
</svg>

)