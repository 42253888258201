import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    inspectionInfoCard:{
        backgroundColor:"#FFFFFF",
        borderRadius:"16px",
        width:"400px"
    },
    resourceName:{
        fontSize:"0.875rem",
        width:"200px",
        fontFamily:Bold,
        color:theme.typography.color.primary
    },
    roleName:{
        fontSize:"0.75rem",
        fontFamily:Regular,
        color:theme.typography.color.secondary
    },
    requestName:{
        fontSize:"1.125rem",
        fontFamily:Bold,
        color:theme.typography.color.primary
    },
    seperateDot:{
        width:"6px",
        height:"6px",
        backgroundColor:"#CED3DD",
        borderRadius:"50%"
    },
    address:{
        fontSize:"0.75rem",
        fontFamily:Bold,
        color:theme.typography.color.secondary 
    },
    moreData:{
        borderRadius:"50%",
        backgroundColor:"#EDE4FE80",
        width:"28px",
        height:"28px",
        textAlign:"center"
    },
    moreDataTxt:{
        fontSize:"0.75rem",
        color:"#896DB3",
        fontFamily:Bold,
        marginTop:"6px",
    }
}))