export const FacilityAndAmenitiesBooking = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105163">
            <g
                data-name="Layer 2"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 98202"
                    d="M23 11.571V9.857a1.714 1.714 0 0 0-1.714-1.714H19v-.572a.572.572 0 1 0-1.143 0v.571h-5.714v-.571a.571.571 0 1 0-1.143 0v.571H8.714A1.714 1.714 0 0 0 7 9.857v1.714Z"
                />
                <path
                    data-name="Path 98203"
                    d="M7 12.714v8.571a1.714 1.714 0 0 0 1.714 1.714h12.572A1.714 1.714 0 0 0 23 21.285v-8.571Zm11.229 3.291-4 3.429a.571.571 0 0 1-.775-.03l-1.715-1.715a.571.571 0 1 1 .808-.808l1.341 1.341 3.6-3.086a.571.571 0 1 1 .743.868Z"
                />
            </g>
        </g>
    </svg>
)