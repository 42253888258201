import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { NewLineChartStyle } from './style';

export const NewLineChart = ({
    data = [],
    height = "200px",
    width = "100%"
}) => {
    const classes = NewLineChartStyle()

    const renderTooltip = ({ active, payload, label }) => {
        return (
            <>
                <Box className={classes.tooltipBox}>
                    <Typography className={classes.ttTitle} p={"4px"}>{label}</Typography>
                    <Divider></Divider>
                    <Stack p={1}>
                        <Stack>
                            <Typography className={classes.ttTitle}>{payload?.[0]?.name}</Typography>
                            <Stack direction="row" alignItems={"center"} columnGap={1}>
                                <Box className={classes.ttDot} sx={{ background: payload?.[0]?.color }}></Box>
                                <Typography className={classes.ttValue}>{payload?.[0]?.value}</Typography>
                            </Stack>
                        </Stack>

                        <Stack>
                            <Typography className={classes.ttTitle}>{payload?.[1]?.name}</Typography>
                            <Stack direction="row" alignItems={"center"} columnGap={1}>
                                <Box className={classes.ttDot} sx={{ background: payload?.[1]?.color }}></Box>
                                <Typography className={classes.ttValue}>{payload?.[1]?.value}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box >
            </>
        )
    }

    return (
        <div style={{ width: '100%', height: "100%", position: "relative" }}>
            <ResponsiveContainer
                width={width ?? "100%"}
                height={height ?? 200}
                style={{ margin: "0 auto" }}
            >
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={renderTooltip} />
                    <Legend />
                    <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" activeDot={{ r: 6 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}