import * as React from "react"

const InvoiceNotesSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 102949" transform="translate(-11563 -17227)">
      <circle
        data-name="Ellipse 129452"
        cx={24}
        cy={24}
        r={24}
        transform="translate(11563 17227)"
        fill="#f1f7ff"
      />
      <g data-name="Group 107571">
        <path
          data-name="Rectangle 54909"
          fill="none"
          d="M11575 17239h24v24h-24z"
        />
        <path
          d="M11579.8 17241a.79.79 0 0 0-.8.789v16.311a2.907 2.907 0 0 0 2.895 2.9h11.052a2.907 2.907 0 0 0 2.895-2.9v-16.311a.79.79 0 0 0-1.11-.722l-2.064.918-2.322-.929a.79.79 0 0 0-.586 0l-2.339.935-2.339-.935a.79.79 0 0 0-.586 0l-2.322.929-2.064-.917a.79.79 0 0 0-.31-.068Zm2.617 6.316h10a.79.79 0 0 1 0 1.579h-10a.79.79 0 1 1 0-1.579Zm0 4.737h5.793a.79.79 0 0 1 0 1.579h-5.789a.79.79 0 1 1 0-1.579Zm8.947 0h1.053a.79.79 0 0 1 0 1.579h-1.049a.79.79 0 1 1 0-1.579Zm-8.943 3.157h5.789a.79.79 0 1 1 0 1.579h-5.789a.79.79 0 1 1 0-1.579Zm8.947 0h1.053a.79.79 0 1 1 0 1.579h-1.053a.79.79 0 1 1 0-1.579Z"
          fill="#78b1fe"
        />
      </g>
    </g>
  </svg>
)

export default InvoiceNotesSvg
