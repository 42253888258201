import { Grid, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { DeleteIcon } from '../../../assets';
import { DocumentViewer } from '../../fileViewer';
import { deleteS3File } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    fileroot: {
        backgroundColor: "#DEEAFC",
        height: (props) => props?.imgHeight ?? "150px",
        fontSize: "0.75rem",
        color: "#5078E1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
    },
    imgContainer: {
        width: "100%",
        borderRadius: theme.palette.borderRadius,
    },
    hoverCard: {
        opacity: "12",
        backgroundColor: '#0000009c',
        position: 'absolute',
        width: "100%",
        height: "150px",
        top: 0,
        bottom: 0,
        borderRadius: 4
    },
    delete: {
        color: "red",
        position: "absolute",
        top: "4px",
        right: "8px",
        backgroundColor: "white",
    },
    img: {
        objectFit: "cover",
        height: (props) => props?.imgHeight ?? "150px",
        width: "100%",
        borderRadius: theme.palette.borderRadius,
    },
}))

export const CustomFileUpload = (props) => {
    const classes = useStyles()
    const { t = () => false } = props;

    const deleteImg = (val) => {
        deleteS3File({ urls: [val?.url] });
        props?.handleFile(val);
    }


    return (
        <>
            <Grid container spacing={props?.spacing ?? 1}>

                {!props?.hideUpload &&
                    <Grid item xs={12} md={props?.uploadMD ?? 6}>
                        <label className="btn label">
                            <div className={classes.fileroot}>
                                <input
                                    accept={props?.accept}
                                    type="file"
                                    name="myImage"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                        props?.updateURL(e.target.files);
                                    }}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                    multiple={props?.multiple}
                                />

                                <center>
                                    <>
                                        <DriveFolderUploadOutlined
                                            style={{ fontSize: "58px", color: "#5078E1" }}
                                        />
                                        <Typography>{t("Upload File")}</Typography>
                                    </>
                                </center>
                            </div>
                        </label>
                    </Grid>
                }
                {
                    !props?.hideImages && props?.selectedURL?.map((val) => {
                        return (
                            <Grid item xs={6} md={props?.imageMD ?? 6} className={classes.imgContainer}>
                                {
                                    val?.file_meta?.type === "pdf" ?
                                        <div
                                            style={{
                                                position: "relative",
                                                overflow: "hidden",
                                                height: "150px",
                                            }}
                                        >
                                            <DocumentViewer url={val.url} />
                                            <br />
                                            {(!props?.readOnly) &&

                                                <IconButton
                                                    className={classes.delete}
                                                    onClick={(e) => {
                                                        deleteImg(val)
                                                        e.stopPropagation()
                                                    }}
                                                    size="small"
                                                >
                                                    <DeleteIcon style={{ fontSize: "0.875rem" }} />
                                                </IconButton>
                                            }
                                        </div>
                                        :

                                        <div
                                            style={{ position: "relative", cursor: "pointer" }}
                                        // onClick={() => onClickImage ? onClickImage(val, index) : onViewImage(val, index)}

                                        >
                                            <img
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                alt="not found"
                                                width={"250px"}
                                                src={val?.url}
                                                className={classes.img}
                                            />

                                            <br />
                                            {
                                                !props?.readOnly &&
                                                <IconButton
                                                    className={classes.delete}
                                                    onClick={(e) => {
                                                        deleteImg(val)
                                                        e.stopPropagation()
                                                    }}
                                                    size="small"
                                                >
                                                    <DeleteIcon style={{ fontSize: "0.875rem" }} />
                                                </IconButton>

                                            }


                                        </div>
                                }

                            </Grid>
                        )
                    })
                }

            </Grid>
        </>
    )
}