import React from "react";
import { withNavBars } from "../../HOCs";
import { WelcomePanner } from "./welcomepanner";

class WelcomePannerParent extends React.Component {
    render() {
        return <WelcomePanner {...this.props}/>;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(WelcomePannerParent, props);
