import makeStyles from "@mui/styles/makeStyles";
import { Regular, SemiBold, Bold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    labelRoot: { display: "flex",marginBottom:"5px" },
    Label: {
        color: (props) => props?.labelColor ?? theme.typography.color.tertiary,
        fontFamily: (props) => (props?.fontFamily === "bold" ? Bold : SemiBold),
        fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
        textAlign: (props) => props?.textAlign ?? "left",
    },
    text: {},
    datePickerRoot: { position: 'relative', height: "100%" },
    textbox: {
        fontSize:"0.875rem",
        fontFamily: `${Regular}`,
        height: (props) => (props.multiline ? "unset" : 45),
        width: '100%',
        borderRadius: theme.palette.borderRadius,
        border: '1.5px solid #E4E8EE !important',
        cursor: 'pointer',
        [`& fieldset`]: {
            borderRadius: theme.palette.borderRadius,
            height: (props) => (props.multiline ? "unset" : 45),
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: (props) => (props.multiline ? "0px" : "0px"),
        },
        ":-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            backgroundColor: "red !important",
        },
        '&:focusVisible': {
            border: '1.5px solid #E2E2E2',
        },

        padding: '20px 18px'
    },
    calander: {
        // position: 'absolute',
        top: (props) => props?.height ?? "55%",
        right: '15px',

    },
    calanderrtl: {
        // position: 'absolute',
        top: (props) => props?.height ?? "55%",
        left: (props) => props?.left ?? '8px',
    },
    menuList: {
        '&:hover': {
            backgroundColor: "transparent"
        }
    },
    Datepicker:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        height: (props) => (props.multiline ? "unset" : 45),
        width: '100%',
        borderRadius: theme.palette.borderRadius,
        border: '1.5px solid #E4E8EE !important',
        cursor: 'pointer',
        ":-webkit-autofill": {
            WebkitBoxShadow: "none !important",
            backgroundColor: "red !important",
        },
        '&:focusVisible': {
            border: '1.5px solid #E2E2E2',
        },

        padding: '20px 18px'
    },
    input:{
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        padding:"0px !important",
        color:"#091B29",
    },
    value: {
        color: "#a2a2a2",
        fontSize:"0.75rem",
        fontFamily: SemiBold
    },
    placeholder: {
        color: "#333333",
        fontSize: "0.8125rem",
    },
    dateBox: {
        border: "1.5px solid #E4E8EE",
        borderRadius: "4px"
    },
    placeholderCustom:{
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
        overflow: "hidden",
        color: "#b4b4b4"
    }
}));