export const SecurityPersonal = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-2{fill:#c1c5cb}"}</style>
        </defs>
        <g id="Announcement" transform="translate(24956 -2962)">
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24956 2962)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
            <g
                id="Security_Personal_Assignement"
                data-name="Security Personal Assignement"
                transform="translate(-24984.496 2967)"
            >
                <g
                    id="Group_102026"
                    data-name="Group 102026"
                    transform="translate(35.496 11.596)"
                >
                    <g id="Group_102025" data-name="Group 102025">
                        <path
                            id="Path_96778"
                            data-name="Path 96778"
                            className="cls-2"
                            d="M40.914 322.591a.555.555 0 0 1-.853-.178l-1.4-2.8a5.538 5.538 0 0 0-3.168 5v1.424a.555.555 0 0 0 .555.555h6.975v-5.768Z"
                            transform="translate(-35.496 -319.615)"
                            style={{
                                fill: props?.fill ?? "#c1c5cb",
                            }}
                        />
                    </g>
                </g>
                <g
                    id="Group_102028"
                    data-name="Group 102028"
                    transform="translate(44.137 11.63)"
                >
                    <g id="Group_102027" data-name="Group 102027">
                        <path
                            id="Path_96779"
                            data-name="Path 96779"
                            className="cls-2"
                            d="m277.926 320.55-1.373 2.763a.555.555 0 0 1-.858.174l-2.03-1.74v5.753h6.8a.555.555 0 0 0 .555-.555v-1.424a5.538 5.538 0 0 0-3.094-4.971Zm-.073 5.291h-1.717a.555.555 0 0 1 0-1.11h1.717a.555.555 0 1 1 0 1.11Z"
                            transform="translate(-273.664 -320.55)"
                            style={{
                                fill: props?.fill ?? "#c1c5cb",
                            }}
                        />
                    </g>
                </g>
                <g
                    id="Group_102030"
                    data-name="Group 102030"
                    transform="translate(39.194 7.361)"
                >
                    <g id="Group_102029" data-name="Group 102029">
                        <path
                            id="Path_96780"
                            data-name="Path 96780"
                            className="cls-2"
                            d="M146.04 203.377a4.317 4.317 0 0 0-.029-.5 8.875 8.875 0 0 1-4.257.937h-.007a8.839 8.839 0 0 1-4.28-.952 4.3 4.3 0 0 0 1.406 3.719 5.517 5.517 0 0 0-.913.142l1.023 2.048 1.525-1.274a4.3 4.3 0 0 0 2.567-.034l1.515 1.3 1-2.02a5.508 5.508 0 0 0-.991-.162 4.292 4.292 0 0 0 1.441-3.204Z"
                            transform="translate(-137.437 -202.864)"
                            style={{
                                fill: props?.fill ?? "#c1c5cb",
                            }}
                        />
                    </g>
                </g>
                <g
                    id="Group_102032"
                    data-name="Group 102032"
                    transform="translate(38.931 4.751)"
                >
                    <g id="Group_102031" data-name="Group 102031">
                        <path
                            id="Path_96781"
                            data-name="Path 96781"
                            className="cls-2"
                            d="M134.746 130.938h-.008a16.213 16.213 0 0 0-4.574.743c.422 1.124 2.51 1.709 4.575 1.709h.007c2.079 0 4.181-.593 4.583-1.732a17.163 17.163 0 0 0-4.583-.72Z"
                            transform="translate(-130.164 -130.938)"
                            style={{
                                fill: props?.fill ?? "#c1c5cb",
                            }}
                        />
                    </g>
                </g>
                <g
                    id="Group_102034"
                    data-name="Group 102034"
                    transform="translate(37.074 .001)"
                >
                    <g id="Group_102033" data-name="Group 102033">
                        <path
                            id="Path_96782"
                            data-name="Path 96782"
                            className="cls-2"
                            d="M91.844 2.291a.555.555 0 0 0-.363-.373L85.6.027a.556.556 0 0 0-.34 0l-5.879 1.89a.555.555 0 0 0-.257.883l1.363 1.643a17.2 17.2 0 0 1 4.94-.8h.008a18.121 18.121 0 0 1 4.957.782L91.739 2.8a.555.555 0 0 0 .105-.509Z"
                            transform="translate(-78.998 -.001)"
                            style={{
                                fill: props?.fill ?? "#c1c5cb",
                            }}
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)