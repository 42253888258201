import React from "react"

export const CompletedJobAsset = () => {
    return (
        <svg id="Group_118506" data-name="Group 118506" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Rectangle_52035" data-name="Rectangle 52035" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
          <rect width="24" height="24" stroke="none"/>
          <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
        </g>
        <path id="icons8-task-completed" d="M15.5,3a2.706,2.706,0,0,0-2.67,2.4H10.7A2.7,2.7,0,0,0,8,8.1V24.3A2.7,2.7,0,0,0,10.7,27H24.5a2.7,2.7,0,0,0,2.7-2.7V8.1a2.7,2.7,0,0,0-2.7-2.7H22.37A2.706,2.706,0,0,0,19.7,3Zm0,1.8h4.2a.9.9,0,1,1,0,1.8H15.5a.9.9,0,0,1,0-1.8Zm2.1,6a5.4,5.4,0,1,1-5.4,5.4A5.4,5.4,0,0,1,17.6,10.8Zm3,3a.6.6,0,0,0-.424.176L16.7,17.452l-1.676-1.676a.6.6,0,1,0-.848.848l2.1,2.1a.6.6,0,0,0,.848,0l3.9-3.9A.6.6,0,0,0,20.6,13.8Z" transform="translate(-5.6 -3)" fill="#86b36d"/>
      </svg>
      
    )
}