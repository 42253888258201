import React from "react";
import { withNavBars } from "../../HOCs";
import ChecklistGroup from "./checklistGroup";

class CheckListGroupParent extends React.Component {
  render() {
    return <ChecklistGroup/>;
  }
}

export default withNavBars(CheckListGroupParent);