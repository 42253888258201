export const Modules = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98411">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g data-name="Group 98401">
                    <g data-name="Group 98400" fill={props?.fill ?? "#c1c5cb"}>
                        <path
                            data-name="Path 94856"
                            d="M11.913 8H8.435A.435.435 0 0 0 8 8.435v3.478a.435.435 0 0 0 .435.435h3.478a.435.435 0 0 0 .435-.435V8.435A.435.435 0 0 0 11.913 8Z"
                        />
                        <path
                            data-name="Path 94857"
                            d="M17.13 8h-3.478a.435.435 0 0 0-.435.435v3.478a.435.435 0 0 0 .435.435h3.478a.435.435 0 0 0 .435-.435V8.435A.435.435 0 0 0 17.13 8Z"
                        />
                        <path
                            data-name="Path 94858"
                            d="M11.913 13.217H8.435a.435.435 0 0 0-.435.435v3.477a.435.435 0 0 0 .435.435h3.478a.435.435 0 0 0 .435-.435v-3.478a.435.435 0 0 0-.435-.434Z"
                        />
                        <path
                            data-name="Path 94859"
                            d="M17.13 13.217h-3.478a.435.435 0 0 0-.435.435v3.477a.435.435 0 0 0 .435.435h3.478a.435.435 0 0 0 .435-.435v-3.478a.435.435 0 0 0-.435-.434Z"
                        />
                        <path
                            data-name="Path 94860"
                            d="M22.347 8h-3.478a.435.435 0 0 0-.435.435v3.478a.435.435 0 0 0 .435.435h3.478a.435.435 0 0 0 .435-.435V8.435A.435.435 0 0 0 22.347 8Z"
                        />
                        <path
                            data-name="Path 94861"
                            d="M22.347 13.217h-3.478a.435.435 0 0 0-.435.435v3.477a.435.435 0 0 0 .435.435h3.478a.435.435 0 0 0 .435-.435v-3.478a.435.435 0 0 0-.435-.434Z"
                        />
                        <path
                            data-name="Path 94862"
                            d="M11.913 18.652H8.435a.435.435 0 0 0-.435.435v3.478a.435.435 0 0 0 .435.435h3.478a.435.435 0 0 0 .435-.435v-3.478a.435.435 0 0 0-.435-.435Z"
                        />
                        <path
                            data-name="Path 94863"
                            d="M17.13 18.652h-3.478a.435.435 0 0 0-.435.435v3.478a.435.435 0 0 0 .435.435h3.478a.435.435 0 0 0 .435-.435v-3.478a.435.435 0 0 0-.435-.435Z"
                        />
                        <path
                            data-name="Path 94864"
                            d="M22.347 18.652h-3.478a.435.435 0 0 0-.435.435v3.478a.435.435 0 0 0 .435.435h3.478a.435.435 0 0 0 .435-.435v-3.478a.435.435 0 0 0-.435-.435Z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
