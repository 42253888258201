import React from "react";
import StatementOfAccount from "./statementOfAccount";
import { Box } from "@mui/material";
import { withNavBars } from "../../HOCs";
const StatementOfAccountParent = () => {
    return (
        <Box>
            <StatementOfAccount />
        </Box>
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(StatementOfAccountParent, props)
