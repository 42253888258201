import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold, remCalc } from "../../../utils";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { PRIORITY_BG_COLOR } from "../utils/quickViewUtils";

export const QuickViewDrawerStyles = makeStyles((theme) => ({
    home: {},
    tab_div: {
        padding: "0px 16px"
    },
    tab_box: {
        padding: "0px 16px"
    },
    mini_lead_card: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#FFFFFF",
    },
    view_details_button: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        },
    },
    view_details_text: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: "#5078E1",
    },
    mini_lead_card_lead_name: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#091B29",
    },
    mini_lead_card_reference_no: {
        borderRadius: theme.palette.borderRadius,
        padding: "2px 6px",
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#091B29",
        backgroundColor: "#E1E8F8",
    },
    mini_lead_card_purpose: {
        borderRadius: theme.palette.borderRadius,
        padding: "2px 6px",
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#091B29",
        backgroundColor: "#E4E8EE",
    },
    mini_lead_card_revenue_type: {
        borderRadius: theme.palette.borderRadius,
        padding: "2px 6px",
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#091B29",
        backgroundColor: "#E4E8EE",
    },
    round_box: {
        borderRadius: "50%",
        height: "6px",
        width: "6px",
        backgroundColor: "#CED3DD",
    },
    mini_lead_card_mobile_no: {
        fontSize:"0.875rem",
        fontFamily: Regular,
        color: "#4E5A6B",
    },
    mini_lead_card_email_id: {
        fontSize:"0.875rem",
        fontFamily: Regular,
        color: "#4E5A6B",
    },
    mini_lead_card_description: {
        fontSize:"0.875rem",
        fontFamily: Regular,
        color: "#4E5A6B",
    },
    mini_lead_card_description_section: {
        maxWidth: "100%",
        overflow: "hidden",
        maxHeight: "50px",
    },
    comments_box: {
        padding: "16px 0px 0px 0px",
        textAlign: "-webkit-auto",
        overflow: "overlay",
    },
    comments_no_data_box: {
        padding: "16px 0px 0px 0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    add_comment_box: {
        width: "100%",
        padding: "16px",
        borderRadius: theme?.palette?.borderRadius,
        border: "1px solid #E4E8EE",
    },
    add_comment_input_box: {
        width: "100%",
        height: "35px",
        border: "none !important",
        outline: "none !important",
        color: "#091B29",
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        fontWeight: 600,
        padding: '0px !important',
        "&:focus": {
            border: "none !important",
            outline: "none !important",
        },
    },
    add_comment_button: {
        width: "100%",
        background: "#5078E1",
        color: "#fff",
        height: "32px",
        marginTop: "10px",
        "&:hover": {
            background: "#5078E1",
            color: "#fff",
        },
    },
    comments_end: {
        color: "#091B29",
        fontSize:"0.875rem",
        fontFamily: SemiBold,
    },
    fab: {
        position: "fixed",
        bottom: 99,
        right: '70px',
        display: "flex",
        alignItems: "center",
        background: '#5078E1',
        boxShadow: "none",
        "&:hover": {
            background: '#5078E1',
        },
    },
    fabArabic: {
        position: "fixed",
        bottom: 99,
        left: '54px',
        display: "flex",
        alignItems: "center",
        background: '#5078E1',
        boxShadow: "none",
        "&:hover": {
            background: '#5078E1',
        },
    },
    toDoButton: {
        borderRadius: theme.palette.borderRadius,
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1",
        },
    },
    toDoButtonText: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: "FFFFFF",
    },
    mini_lead_card_priority: {
        borderRadius: theme.palette.borderRadius,
        padding: "2px 6px",
        fontSize: remCalc(12),
        textTransform: "capitalize",
        fontFamily: SemiBold,
        color: ({ priority }) => priority ? "#FFFFFF" : "#091B29",
        backgroundColor: ({ priority }) => priority ? PRIORITY_BG_COLOR?.[priority] : "#E4E8EE",
    },
}));

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "#7C8594",
        backgroundColor: "#FFFFFF",
        border: "1px solid #E4E8EE",
        padding: "4px 7px",
        zIndex: 999,
        borderRadius: "4px",
        position: "relative",
        maxWidth: "none",
    },
}));