import { Box, Grid, IconButton } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { SearchFilter, TableWithPagination } from "../../../components";
import { InspectionListDataType, InspectionListHeading, InspectionListPath } from "../../../utils";
import FilterIcon from "../../../assets/filterIcon";
import { useStyles } from "./styles";
const InspectionTable = (props) => {
    const { t } = props
    const classes = useStyles()
    return (
        <Box>
            <Grid container justifyContent="space-between">
                {/* searchbar */}
                <Grid item xs={3}>
                    <SearchFilter placeholder={t("searchInspection")} handleChange={(value) => props?.handleSearch(value)} value={props?.searchText} />
                </Grid>
                <Grid item xs={9} display="flex" justifyContent={"end"} alignItems="flex-end" onClick={() => props?.openFilter()}>
                    {props?.dot ?
                        <FilterIcon />
                        : <IconButton
                            size="small"
                            className={classes.filterIconbtn}
                        >
                            <img src="/images/filter.svg" alt="filter" />
                        </IconButton>}
                </Grid>
            </Grid>



            {/* Inspectiontable */}
            <TableWithPagination
                heading={InspectionListHeading(t)}
                path={InspectionListPath}
                rows={props?.tableData ? props?.tableData : []}
                handleIcon={props?.handleIcon}
                tableType="no-side"
                dataType={InspectionListDataType}
                handlePagination={props?.handlePagination}
                handleChangeLimit={props?.handleChangeLimit}
                totalRowsCount={props?.totalRows}
                page={props?.page}
                limit={props?.limit}
                height={props?.height}
            />
        </Box>
    )
}
export default withNamespaces("inspection")(InspectionTable)