import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { TabContext, TabList } from "@mui/lab";
import { Box, Stack, Tab, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useStylesCreation } from "./style";

export const DynamicTabs = ({ handleChange = () => false, value = 1, tabList = [], count = {}, handleMapView = () => false, t }) => {
    const classes = useStylesCreation()
    const matches = useMediaQuery('(max-width:600px)');
    return (
        <Box>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">

                            {tabList?.map((x) => {
                                return (
                                    <Tab style={{marginInlineStart:"8px"}} label={<Stack direction="row" spacing={1} alignItems="center">
                                        <Box className={classes.countBox} style={{ backgroundColor: value === x.value ? "#5078E1" : "#98A0AC" }}>{x.value === 1 ? count?.maintenanceCount : count?.generalCount}</Box>
                                        <Typography className={classes.tabtitle}>{x.title}</Typography>
                                    </Stack>} value={x.value} />
                                )
                            })}


                        </TabList>
                        {matches ? <FmdGoodIcon style={{ color: "#5078E1" }} className={classes.mbviewBox} onClick={() => handleMapView()} />
                            :
                            <Stack direction="row" alignItems={"center"} spacing={0.5} className={classes.viewBox} onClick={() => handleMapView()}>
                                <FmdGoodIcon style={{ color: "#5078E1" }} />
                                <Typography className={classes.viewMapTitle}>{t("View in map")}</Typography>
                            </Stack>
                        }
                    </Box>
                </Box>
            </TabContext>
        </Box>
    )
}