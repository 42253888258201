import React from "react";
import { withNavBars } from "../../../HOCs";
import PricingTablePropertyView from "./pricingTablePropertyView";

class PricingTablePropertyViewParent extends React.Component {
    render() {
        return <PricingTablePropertyView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(PricingTablePropertyViewParent, props);
