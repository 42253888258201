import * as React from "react"

export const AnnounsmentPerson = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Request Ticket">
      <path data-name="Rectangle 53523" fill="none" d="M.5 3h18v18H.5z" />
      <g data-name="Group 104014">
        <path data-name="Rectangle 55220" fill="none" d="M0 0h24v24H0z" />
        <path
          d="M10.857 3a4.286 4.286 0 1 0 4.286 4.286A4.291 4.291 0 0 0 10.857 3ZM5.705 13.286A1.707 1.707 0 0 0 4 14.991v.652A3.939 3.939 0 0 0 6.274 19.1a9.666 9.666 0 0 0 4.583 1.045h.021l-1.724-1.724a1.5 1.5 0 1 1 2.121-2.121l1.939 1.939 4.2-4.2a1.7 1.7 0 0 0-1.409-.747Zm13.937 0a.641.641 0 0 0-.455.188l-5.974 5.974-2.544-2.548a.643.643 0 1 0-.909.909l3 3a.642.642 0 0 0 .908 0l6.432-6.426a.643.643 0 0 0-.455-1.1Z"
          fill={props?.color ?? "#98a0ac"}
        />
      </g>
    </g>
  </svg>
)
