import React from "react";

export const WonQuotationsIcon = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_243_2309)">
      <circle cx="25.3999" cy="21.9191" r="21" fill="#F0CC3A" />
    </g>
    <g clip-path="url(#clip0_243_2309)">
      <path
        d="M36.6979 13.9651C36.2179 13.4551 35.5369 13.1611 34.8349 13.1611H32.5999C32.6089 12.7591 32.6149 12.3451 32.6149 11.9191H18.1999C18.1999 12.345 18.2059 12.7591 18.2149 13.1611H15.9649C15.2629 13.1611 14.5819 13.4551 14.1019 13.9651C13.6159 14.4781 13.3639 15.1801 13.4059 15.8911C13.8079 22.5931 17.2669 26.7991 22.7509 27.4231L21.6889 31.1191C20.4259 31.1191 19.4029 32.1421 19.4029 33.4051V35.9191H31.4149V33.4051C31.4149 32.1421 30.3919 31.1191 29.1289 31.1191L28.0669 27.4231C33.5389 26.7931 36.9949 22.5902 37.3939 15.8911C37.4359 15.1831 37.1839 14.4811 36.6979 13.9651ZM15.8029 15.7471C15.7999 15.6811 15.8269 15.6361 15.8509 15.6121C15.8959 15.5641 15.9499 15.5641 15.9649 15.5641H18.3289C18.6859 20.2831 19.7269 22.9591 20.738 24.4711C16.8889 22.8571 15.9709 18.5461 15.8029 15.7471ZM30.0769 24.4621C31.0879 22.9471 32.1259 20.2741 32.4829 15.5611H34.8379C34.8559 15.5611 34.9069 15.5611 34.9519 15.6091C34.9759 15.6331 35.0029 15.6781 34.9999 15.7441C34.8289 18.5431 33.9139 22.8421 30.0769 24.4621Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_243_2309"
        x="0.399902"
        y="0.919067"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_243_2309"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_243_2309"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_243_2309">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(13.3999 11.9191)"
        />
      </clipPath>
    </defs>
  </svg>
);
