import React from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { TreeStylesParent } from "./style";
import SearchIcon from "@mui/icons-material/Search";
export const Search = (props) => {
  const classes = TreeStylesParent();
  const {t=()=>false}=props;
  return (
    <>
      <Box className={classes.searchBox}>
        <Box>
          <Typography className={classes.searchTitle}>
            {props?.title}
          </Typography>
        </Box>
        {props?.addBtn &&
          <Box>
            <Button
              className={classes.addBtn}
              onClick={props?.handleAdd}
              disabled={props?.disabled}
              variant="contained"
            >
              + {t("Add")}
            </Button>
          </Box>}
      </Box>
      <Box>
        <TextField
          className={classes.searchField}
          placeholder={props?.placeholder}
          value={props?.permissionSearch}
          onChange={(e) => props?.searchFumction(e.target.value)}
          variant={props.variant ?? "outlined"}
          fullWidth
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          size="small"
        />
      </Box>
    </>
  );
};
