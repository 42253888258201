export const AccessGatesPropertyPath = ["name", "location", "gates", "propertyType", "status"]

export const AccessGatesPropertyHeading = (t) => [
    { title: t("Name"), field: "name" },
    { title: t("Location"), field: "location" },
    { title: t("Gates"), field: "gates" },
    { title: t("Property Type"), field: "propertyType" },
    { title: t("Status"), field: "status" },
]

export const AccessGatesPropertyType = [
    { type: ["text"], name: "name", width: "200px" },
    { type: ["text"], name: "location" },
    { type: ["text"], name: "gates" },
    { type: ["propertyType"], name: "propertyType", width: "200px" },
    { type: ["status"], name: "status" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]
export const StatusOptionListLang =(t=()=>false)=> [
    { label_lng: t("Active"),label: t('Active'), value: true },
    { label_lng: t("Inactive"),label: t('Inactive'), value: false },
]

export const AccessGatesPath = ["name", "gateNo", "description", "status"]

export const AccessGatesHeading = (t) => [
    { title: `${t("Gate Name")}`, field: "name" },
    { title: t("Gate Number"), field: "gateNo" },
    { title: t("Notes"), field: "description" },
    { title: t("Status"), field: "status" },
    { title: "", field: "icon" },
]

export const AccessGatesType = [
    { type: ["text"], name: "name", width: "200px" },
    { type: ["text"], name: "gateNo", width: "200px" },
    { type: ["text"], name: "description", width: "200px" },
    { type: ["status"], name: "status", width: "750px" },
    { type: ["more"], icon: "icon" },
]