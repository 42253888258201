import * as React from "react"
const ContractIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={38}
    height={38}
    data-name="Group 113946"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.048}
        x2={0.509}
        y1={-15.861}
        y2={-15.4}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#297cd2" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.213}
        x2={0.727}
        y1={0.193}
        y2={1.089}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#42a3f2" />
        <stop offset={1} stopColor="#42a4eb" />
      </linearGradient>
      <linearGradient xlinkHref="#a" id="c" x1={0} x2={1} y1={0.5} y2={0.5} />
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={0.5}
        x2={0.5}
        y1={-0.015}
        y2={1.033}
      />
      <linearGradient
        xlinkHref="#a"
        id="e"
        x1={0.006}
        x2={0.952}
        y1={0.006}
        y2={0.952}
      />
    </defs>
    <rect
      width={38}
      height={38}
      fill="#eafcff"
      data-name="Rectangle 57624"
      rx={8}
    />
    <path
      fill="#50e6ff"
      d="M28.761 13.879v15.634a1.25 1.25 0 0 1-1.251 1.251H11.251A1.25 1.25 0 0 1 10 29.513V8.251A1.25 1.25 0 0 1 11.251 7h10.631Z"
      data-name="Path 101007"
    />
    <path
      fill="url(#a)"
      d="M28 5v5.628a1.25 1.25 0 0 0 1.251 1.251h5.628Z"
      data-name="Path 101008"
      transform="translate(-6.118 2)"
    />
    <path
      fill="url(#b)"
      d="M25.631 29.507H15.625a.626.626 0 0 1-.625-.625v-6.566L20.628 17l5.628 5.316v6.566a.626.626 0 0 1-.625.625Z"
      data-name="Path 101009"
      transform="translate(-1.248 -2.496)"
    />
    <path
      fill="url(#c)"
      d="M18 28h3.752v5.628H18Z"
      data-name="Path 101010"
      transform="translate(-2.372 -6.617)"
    />
    <path
      fill="url(#d)"
      d="m26.507 21.974-5.994-5.317a.626.626 0 0 0-.829 0l-5.994 5.317a.313.313 0 0 0-.02.447l.512.546a.313.313 0 0 0 .435.021L20.1 18.2l5.483 4.791a.313.313 0 0 0 .435-.021l.512-.546a.313.313 0 0 0-.023-.45Z"
      data-name="Path 101011"
      transform="translate(-.718 -2.308)"
    />
    <path
      fill="url(#e)"
      d="M26.313 28h1.876a.313.313 0 0 1 .313.313v1.876a.313.313 0 0 1-.313.313h-1.876a.313.313 0 0 1-.313-.313v-1.876a.313.313 0 0 1 .313-.313Z"
      data-name="Path 101012"
      transform="translate(-5.369 -6.617)"
    />
  </svg>
)
export default ContractIcon
