import { Avatar, IconButton, Stack, Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const UnitVacancyDiv = styled('div')((props) => ({
    border: "2px solid white",
    borderRadius: props?.theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px",
    height: `calc(100vh - 147px)`,
    overflow: "hidden",
    margin: "0px 14px 14px"
}))

export const FilterButton = styled(IconButton)((props) => ({
    border: `1px solid ${props?.theme.palette.border.secondary}`,
    borderRadius: props?.theme.palette.borderRadius,
    padding: "12px"
}))

export const ProfileDetailsStack = styled(Stack)((props) => ({
    backgroundColor: "white",
    borderRadius: props.theme.palette.borderRadius,
    padding: "16px",
    margin: "14px"
}))

export const ProfileImageOuterDiv = styled('div')({
    alignSelf: "center"
})

export const ProfileImageInnerDiv = styled('div')({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: "8px 8px 19px"
})

export const Image = styled(Avatar)((props) => ({
    cursor: "pointer",
    width: "141px",
    height: "141px",
    borderRadius: "71px",
    backgroundColor: "#F2F4F7",
    border: props?.src ? 0 : '1px solid #e3e5ec'
}))

export const UserID = styled(Typography)((props) => ({
    padding: "2px 6px",
    borderRadius: props?.theme.palette.borderRadius,
    backgroundColor: "#071741",
    color: "white",
    fontSize:"0.75rem",
    fontFamily: "'IBMPlexSans-SemiBold'",
    position: "absolute",
    bottom: "-11px",
    textAlign: "center",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    display: props?.userNo ? "inline" : "none",
}))

export const CardTitle = styled(Typography)({
    color: "#4E5A6B",
    fontSize:"0.75rem",
    fontFamily: "'NunitoSans-Bold'",
})

export const Title = styled(Typography)((props) => ({
    marginBottom: props?.marginBottom ?? "8px",
    color: "#98A0AC",
    fontSize:"0.75rem",
    fontFamily: "'IBMPlexSans-SemiBold'",
}))

export const Desc = styled(Typography)({
    color: "#091B29",
    fontSize:"0.875rem",
    fontFamily: "'IBMPlexSans-SemiBold'",
})