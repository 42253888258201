import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";

import { renewalRequest } from "../../../../utils/agreementUtils";
import { AlertDialog } from "../../../../components";
import { useStyleTimeLine } from "../../styles";
import { TermisionRequestForm } from "./customRequest";
import { AsPerAgreement } from "./asPerAgreement";
import { Arrow } from "./assets";

export const RenewalRequest = ({ t, open = false, setOpen = () => false, details = {}, agreement_id, agreementReload }) => {

  const classes = useStyleTimeLine();
  const [dialog, setDialog] = useState({
    bool: false,
    type: "",
  });
  const onCloseDialog = (type) => {

    setDialog({
      bool: !dialog.bool,
      type: type,
    });
    setOpen(false);
  };

  const onClick = (type) => {
    setDialog({
      bool: !dialog.bool,
      type: type,
    });
  }

  return (
    <div>
      <AlertDialog
        isNormal={true}
        header={t("Renewal Request")}
        onClose={() => setOpen({
          ...open,
          bool: false
        })}

        open={open}
        component={
          <>
            {renewalRequest(t, onCloseDialog)?.map((x, i) => {
              return (
                <>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent="space-between"
                    sx={{ padding: "23px 32px", cursor: "pointer" }}
                    onClick={() => onClick(x?.type)}

                  >
                    <Box display="flex" alignItems={"center"}>
                      {x?.icon}
                      <Box width={"14px"} />
                      <Box>
                        <Typography className={classes.renewalTitle}>
                          {x?.title}
                        </Typography>
                        <Typography className={classes.renewalDes}>
                          {x?.des}
                        </Typography>
                        <Box height={"4px"} />
                        <Typography></Typography>
                      </Box>
                      {/* <Arrow/> */}
                    </Box >
                    <Arrow />

                  </Box >
                  {!i > 0 && <Divider />}
                </>
              );
            })}
          </>
        }
      />
      < AlertDialog
        isNormal={true}
        header={dialog?.type === "custom" ? t("Custom") : t("As_Per_Agreement")}

        onClose={() => onCloseDialog(false)}
        open={dialog.bool}
        component={
          <>
            {
              dialog?.type === "custom" ? (
                <TermisionRequestForm t={t} details={details} onCloseDialog={onCloseDialog} agreementReload={agreementReload} agreement_id={agreement_id} />
              ) : (
                <AsPerAgreement t={t} details={details} onCloseDialog={onCloseDialog} agreementReload={agreementReload} agreement_id={agreement_id} />

              )
            }
          </>
        }
      />
    </div >
  );
};
