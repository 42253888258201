import React from 'react';
import { Avatar, Box, Typography } from "@mui/material";
import { LeadStylesParent } from '../style';
import { stringAvatar } from '../../../utils';

export const AllocatedCard = (props) => {

      const { t } = (props)
      // styles
      const classes = LeadStylesParent();
      return (
            <div className={classes.allocatedroot}>
                  <div style={{ padding: '12px', display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginInlineEnd: '10px' }}>
                              <Avatar src={props?.viewDetails?.member?.image_url ?? ""}  {...stringAvatar(props?.viewDetails?.member?.first_name)}/>
                        </div>
                        <div>
                              <div style={{ flexFlow: 'wrap', display: 'flex', alignItems: 'center' }}> <Typography className={classes.profileTilte}>{props?.viewDetails?.member?.first_name ?? ""} {props?.viewDetails?.member?.last_name ?? ""}</Typography></div>
                              <Box style={{ flexFlow: 'wrap', display: 'flex', alignItems: 'center' }}>
                                    <Box> <Typography className={classes.leadcontact}>{t("Agent")}</Typography></Box>
                                    <Box> <Typography className={classes.leadcontact} noWrap>{(props?.viewDetails?.member?.mobile_no_country_code || props?.viewDetails?.member?.mobile_no) && <Box className={classes.dot} />} {props?.viewDetails?.member?.mobile_no_country_code ?? ""} {props?.viewDetails?.member?.mobile_no ?? ""}</Typography></Box>
                                    <Box> <Typography className={classes.leadcontact} noWrap><Box className={classes.dot} />{props?.viewDetails?.member?.email_id ?? ""}</Typography></Box>
                              </Box>
                        </div>
                  </div>
            </div>
      )
}
