import React from "react"
import { CalProgressbar } from "../../resourceBoard/component"
import { Grid, Box } from "@mui/material"
import { DayCalendarStyles } from "./style"
import { DailyCalendar } from "../../../components/dailyCalendar"
const DayCalendar = ({ t, progress = 60, list = {} }) => {
    const classes = DayCalendarStyles()
    return (
        <>
            <Grid container direction={"row"}>
                <Grid item md={1} className={classes.header_timecell}>
                    <Box>
                        {t("LOAD")}
                    </Box>
                </Grid>
                <Grid item md={11} className={classes.header_eventCell}>
                    <CalProgressbar value={progress} />
                </Grid>
            </Grid>
            <DailyCalendar list={list} />
        </>
    )
}
export default DayCalendar