import React from "react";
import { withNavBars } from "../../HOCs";
import FreeTextInvoice from "./freeTextInvoice";
import FreeTextInvoiceContext from "./freeTextInvoiceContext";
class FreeTextInvoiceParent extends React.Component {
    render() {
        return (
            <FreeTextInvoiceContext>
                <FreeTextInvoice />
            </FreeTextInvoiceContext>
        );
    }
}

const props = {
    boxShadow: false
}
export default withNavBars(FreeTextInvoiceParent, props);