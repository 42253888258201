import React, { useState } from 'react';
import styled from "@mui/material/styles/styled";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { CustomSelect, ToggleButton, MinMaxTextFieldWithSelect } from './components';

const Div = styled('div')(({ theme }) => ({
    flex: 1,
    padding: theme.spacing(2)
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
    marginBlock: theme.spacing(2),
    width: "100%"
}));


export const Body = (props) => {

    const {
        data = {},
        error = {},
        components = [],
        onChangeState = null
    } = props;

    const [loading, setLoading] = useState(null);


    const switchComponents = (val) => {

        switch (val.component) {
            case "select":

                const loadOptions = (search,prevArray,val) =>{
                    setLoading(val?.state_name)
                    return val?.loadOptions(search,prevArray,setLoading,data)
                  }

                return (
                    <CustomSelect
                        label={val?.label ?? ''}
                        options={val?.options?.length ? val?.options : []}
                        isMulti={val?.isMulti}
                        value={data?.[val?.state_name] ?? null}
                        onChange={(value) => onChangeState(val?.state_name, value, val)}
                        error={error?.[val?.state_name] ?? false}
                        errorText={"This field is required"}
                        required={val?.required ?? false}
                        isPaginate={val?.isPaginate}
                        debounceTimeout={val?.debounceTimeout}
                        loadOptions={(search, prevArray) => loadOptions(search, prevArray, val)}
                        loading={loading === val?.state_name}
                    />
                )
            case "toggleButton":
                return (
                    <ToggleButton
                        options={val?.options?.length ? val?.options : []}
                        value={data?.[val?.state_name]}
                        onChange={(value) => onChangeState(val?.state_name, value, val)}
                        isMulti={val?.isMulti}
                        fullWidth={val?.fullWidth}
                        buttonStyle={{
                            borderColor: "#E4E8EE",
                            borderRadius: 1,
                            backgroundColor: "#fff",
                            ...val?.buttonStyle
                        }}
                        buttonGroupStyle={{ ...val?.buttonGroupStyle }}
                        label={val?.label ?? ''}
                        error={error?.[val?.state_name] ?? false}
                        errorText={"This field is required"}
                        required={val?.required ?? false}
                    />
                )
            case "minMaxTextFieldWithSelect":
                return (
                    <MinMaxTextFieldWithSelect
                        value={data?.[val?.state_name] ?? null}
                        onChange={(value) => onChangeState(val?.state_name, value, val)}
                        label={val?.label ?? ''}
                        error={error?.[val?.state_name] ?? false}
                        errorText={"This field is required"}
                        required={val?.required ?? false}
                        placeholders={val?.placeholders ?? null}
                        endAdornmentLabels={val?.endAdornmentLabels ?? null}
                        options={val?.options}
                        select={val?.select}
                    />
                )
            default:
                return null;
        }
    }

    return (
        <Div>
            {
                <Grid container>
                    {
                        components?.length && components.map(_ => (
                            <>
                                <Grid item xs={12}>
                                    {switchComponents(_)}
                                </Grid>
                                <CustomDivider />
                            </>
                        ))
                    }
                </Grid>
            }
        </Div>
    )
}