import * as React from "react"

export const LocationIcon = (props) => {

    const {
        width = 16,
        height = 16,
    } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={width}
            height={height}
            {...props}
        >
            <g id="Group_98144" data-name="Group 98144" transform="translate(-21012 -5602)">
                <rect id="Rectangle_52046" data-name="Rectangle 52046" width="16" height="16" transform="translate(21012 5602)" fill="none" />
                <path id="icons8-location_4_" data-name="icons8-location (4)" d="M13.377,4A6.373,6.373,0,0,0,8.53,14.515l0,0,0,0s2.713,3.1,3.679,4.017a1.694,1.694,0,0,0,2.326,0c1.1-1.05,3.68-4.018,3.68-4.018v0l0,0A6.372,6.372,0,0,0,13.377,4Zm0,1.125a5.246,5.246,0,0,1,3.991,8.658c-.005.006-2.644,3.024-3.6,3.94a.548.548,0,0,1-.773,0c-.8-.766-3.6-3.931-3.6-3.94h0a5.247,5.247,0,0,1,3.992-8.657Zm0,3a2.234,2.234,0,0,0-1.687.717,2.368,2.368,0,0,0,0,3.068,2.343,2.343,0,0,0,3.373,0,2.368,2.368,0,0,0,0-3.068A2.234,2.234,0,0,0,13.377,8.126Zm0,1.125a1.037,1.037,0,0,1,.845.338,1.246,1.246,0,0,1,0,1.574,1.037,1.037,0,0,1-.845.338,1.037,1.037,0,0,1-.845-.338,1.246,1.246,0,0,1,0-1.574A1.037,1.037,0,0,1,13.377,9.251Z" transform="translate(21006.5 5598.5)" fill="#98a0ac" />
            </g>
        </svg>
    )
}