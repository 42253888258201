import * as React from "react"
const VendorInactive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={20.571} {...props}>
    <path
      fill="#ced4da"
      d="M1.143 7.429h21.714v10.857H1.143Z"
      data-name="Path 100862"
    />
    <path
      fill="#ced4da"
      d="M1.143 18.286h21.714v2.286H1.143Z"
      data-name="Path 100863"
    />
    <path
      fill="#dee2e6"
      d="M5.143 10.286h13.714v10.286H5.143Z"
      data-name="Path 100864"
    />
    <path
      fill="#adb5bd"
      d="M5.714 10.857h12.571v9.714H5.714Z"
      data-name="Path 100865"
    />
    <path
      fill="#dee2e6"
      d="M13.715 7.428A1.714 1.714 0 1 1 12 5.714a1.715 1.715 0 0 1 1.714 1.714m6.857 0a1.714 1.714 0 1 1-1.713-1.714 1.715 1.715 0 0 1 1.714 1.714m-17.143 0a1.714 1.714 0 1 0 1.714-1.714 1.715 1.715 0 0 0-1.714 1.714"
      data-name="Path 100867"
    />
    <path
      fill="#adb5bd"
      d="M21.143 0H2.857a1.143 1.143 0 0 0-1.143 1.143v1.714h20.571V1.143A1.143 1.143 0 0 0 21.143 0M10.285 2.857h3.429v4.571h-3.429Zm9.143 0h-2.857l.571 4.571h3.429Zm-14.857 0h2.857l-.571 4.571H3.428Z"
      data-name="Path 100868"
    />
    <g data-name="Group 115366">
      <path
        fill="#adb5bd"
        d="M17.143 7.428a1.714 1.714 0 1 1-1.714-1.714 1.715 1.715 0 0 1 1.714 1.714m6.857 0a1.714 1.714 0 1 1-1.714-1.714Zm-17.143 0a1.714 1.714 0 1 0 1.714-1.714 1.715 1.715 0 0 0-1.714 1.714M0 7.428a1.714 1.714 0 1 0 1.714-1.714Z"
        data-name="Path 100869"
      />
    </g>
    <g data-name="Group 115367">
      <path
        fill="#ced4da"
        d="M16.571 2.857h-2.857v4.571h3.429Zm5.714 0h-2.856l1.143 4.571H24Zm-14.856 0h2.857v4.571H6.857Zm-5.715 0h2.857L3.429 7.428H0Z"
        data-name="Path 100870"
      />
    </g>
    <path
      fill="#ced4da"
      d="M11.429 10.857h1.143v9.714h-1.143Z"
      data-name="Path 100871"
    />
    <g data-name="Group 115368">
      <path
        fill="#ced4da"
        d="M11.429 13.974v6.6h1.143v-7.119Zm2.857 1.455a.571.571 0 1 0 .571.571.571.571 0 0 0-.571-.571Zm-4.571 0a.571.571 0 1 0 .571.571.571.571 0 0 0-.572-.571Z"
        data-name="Path 100872"
      />
    </g>
  </svg>
)
export default VendorInactive
