import {
  Box
} from "@mui/material";
import React from "react";
import { CreateShortListContext } from "../../../contexts/shortlistLedContext";
import { LeaseForm, SaleForm } from "../../createquotation/component";
export const LeadNew = () => {
  const { dataNew, updateStateNew, setDataNew, setSelectedAccount, selectedAccount, t } = React.useContext(CreateShortListContext);
  return (
    <Box >

      {
        (dataNew?.searchdata?.revenue_type === "Lease" || dataNew?.searchdata?.revenue_type === "Manage") &&
        <LeaseForm
          data={dataNew}
          updateState={updateStateNew}
          purpose={dataNew?.searchdata?.property_purpose}
          type={dataNew?.searchdata?.revenue_type}
          setData={setDataNew}
          setSelectedAccount={setSelectedAccount}
          selectedAccount={selectedAccount}
          t={t}
        />
      }

      {
        dataNew?.searchdata?.revenue_type === "Sale" &&
        <SaleForm
          data={dataNew}
          updateState={updateStateNew}
          purpose={dataNew?.searchdata?.property_purpose}
          type={dataNew?.searchdata?.revenue_type}
          setData={setDataNew}
          setSelectedAccount={setSelectedAccount}
          selectedAccount={selectedAccount}
          t={t}
        />
      }

      {/* Short Term Rental */}
      {
        dataNew?.searchdata?.property_purpose === "Short Term Rental" &&
        <LeaseForm
          data={dataNew}
          purpose={dataNew?.searchdata?.property_purpose}
          type={dataNew?.searchdata?.revenue_type}
          updateState={updateStateNew}
          setData={setDataNew}
          setSelectedAccount={setSelectedAccount}
          selectedAccount={selectedAccount}
          t={t}
        />
      }


    </Box>


  );
};