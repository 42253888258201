import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LoadingSection, Subheader, UseDebounce } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { accessCheckRender, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";
import { revenueType } from "../../utils/quotations";
import ChartContainer from "./chartContainer";
import { quotationsStyles } from "./styles";
import AggreementsTable from "./table";

const AggreementsList = (props) => {
    const { t } = props;
    const classes = quotationsStyles();
    const history = useHistory()
    const backdrop = React.useContext(BackdropContext)
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)
    const debounce = UseDebounce();
    const [loading, setLoading] = React.useState(false)
    const [searchText, setSearchText] = React.useState("");
    const [companyList, setCompanyList] = React.useState([])
    const [revenue, setRevenue] = React.useState({})
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [selectedAgreement, setSelectedAgreement] = React.useState("")
    const [agreementList, setAgreementList] = React.useState({
        list: [],
        count: 0
    })
    const [permission, setPermission] = React.useState({})
    const [filterData, setFilterData] = React.useState({
        account: [],
        status: [],
      });

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    setRevenue(revenueType[0])
                    getAgreementList(company?.selected?.value, 0, limit, "")
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    //on click rows
    const handleIcon = (type, data) => {
        if (type === "view") {
            setSelectedAgreement(data?.id)
        }
        if (type === "info" || type === "double_click") {
            history.push(`${Routes.agreementView}?id=${data?.id}`)
        }
    }
    //get quoation list
    const getAgreementList = (company_id, offset = 0, limit = 10, searchText , agreement_id , status) => {
        setLoading(true)
        const payload = {
            tenantId: `${config.tenantId}`,
            company_id: company_id,
            searchText: searchText,
            offset: offset,
            limit: limit,
            agreement_id:agreement_id?.length > 0 ? agreement_id?.map((x)=>x?.value) : undefined,
            status:status?.length > 0 ? status?.map((x)=>x?.value) : undefined

        }
        NetworkCall(
            `${config.api_url}/agreement/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setAgreementList({
                    list: response?.data?.data?.agreementList,
                    count: response?.data?.data?.count
                })
                setSelectedAgreement(response?.data?.data?.agreementList?.[0]?.id)
                setLoading(false)
                backdrop.setBackDrop({ ...backdrop, open: false, message: "" })
            }).catch((err) => {
                console.log(err)
            })
    }

    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getAgreementList(selectedCompany?.value, 0, 10, e)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getAgreementList(selectedCompany?.value, offset, limit, "" , filterData?.account , filterData?.status)
    }
    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getAgreementList(selectedCompany?.value, 0, value, "" , filterData?.account , filterData?.status)
    }
    //on switch company
    const handleCompanyChange = (company) => {
        setSelectedCompany(company)
        getAgreementList(company?.value, 0, 10, "" )
    }
    const getAgreement=(value)=>{
        setFilterData(value)
        getAgreementList(selectedCompany?.value, 0, 10, "" , value?.account , value?.status)
    }

    const render = () => {
        return <>
            {  //loading section
                loading ? <LoadingSection bottom={"45vh"} message={t("loadMsg")} /> :

                    <div className={classes.root}>
                        {/*sub header*/}
                        <Subheader title={t("agreements")} goBack={() => { history.goBack() }} select options={companyList} value={selectedCompany}
                            onchange={(value) => {
                                handleCompanyChange(value)
                            }}
                            hideBackButton={true}
                        />
                        <Box className={classes.table} m={2} >
                            <Grid container >
                                <Grid item xs={9}>
                                    <Box p={2}>
                                        {/*Aggreements Table*/}
                                        <AggreementsTable
                                            list={agreementList}
                                            handleSearch={handleSearch}
                                            searchText={searchText}
                                            revenue={revenue}
                                            handleIcon={handleIcon}
                                            handleChangeLimit={handleChangeLimit}
                                            handlePagination={handlePagination}
                                            page={page}
                                            limit={limit}
                                            permission={permission}
                                            selectedCompany={selectedCompany}
                                            getAgreement={getAgreement}
                                            filterData={filterData}
                                            enable_double_click={true}
                                            enable_single_click={true}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={3} className={classes.chartContainer}>
                                    <Box p={2}>
                                        {/*Chart Container*/}
                                        <Typography className={classes.title}>{t("AgreementSummary")}</Typography>
                                        {
                                            agreementList?.list?.length > 0 ?
                                                <ChartContainer selectedAgreement={selectedAgreement} />
                                                : ""
                                        }

                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>
                    </div>
            }
        </>
    }

    return (
        <>
            {accessCheckRender(render, permission)}
        </>
    );
};
export default withNamespaces("agreement")(AggreementsList);