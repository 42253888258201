import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { componantsStyles } from "./styles";
import {timeZoneConverter} from "../../../utils";

export const QuotationVaersionCard = ({ data , t , is_arrow=true}) => {
    const classes = componantsStyles()
    return (
        <div>
            <Box display="flex" px={2} py={"14px"} alignItems="center" className={classes.cardRoot}>
                <Box flexGrow={1}>
                    <Stack direction="row">
                        <Typography className={classes.quottNo}>{data?.quott_no}</Typography>
                        {data?.active && <Typography className={classes.active}>{data?.active ?? "Active"}</Typography>
                        }

                    </Stack>
                    <Typography className={classes.quottCardsub}>{t("Generated on")} &#x202a;{timeZoneConverter(data?.startDate)}&#x202c;</Typography>
                </Box>
                {/* {
                    is_arrow &&
                    <Box>
                    <IconButton className={classes.arrowIcon} size="small">
                        {language === "ar" ?
                            <ArrowBackIosIcon style={{ fontSize:"0.75rem" }} /> :
                            <ArrowForwardIosIcon style={{ fontSize:"0.75rem" }} />
                        }
                    </IconButton>
                </Box>
} */}
               

            </Box>

        </div>
    )
}