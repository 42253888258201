import React from "react";
import { Box, Typography } from "@mui/material";
import { useStylesCreation } from "./style";

export const CardDetails = (props) => {
  const classes = useStylesCreation(props);
  return (
    <div className={classes.card}>
      <Box className={classes.detailsCard1}>
        {props?.data?.is_count ? (
          <Box>
            <Typography className={classes.detailsCard2}>
              {props?.data?.area}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography className={classes.countCard}>
              {props?.data?.count}
            </Typography>
          </Box>
        )}
        <Box>
          {props?.data?.is_count > 0 ? (
            <Typography className={classes.areaMeter}>{props?.data?.data}</Typography>
          ) : (
            <>
              {props?.data?.image_type ? (
                <img src={props?.data?.image} alt="images" />
              ) : (
                <Typography>{props?.data?.image}</Typography>
              )}
            </>
          )}
        </Box>
      </Box>
      {props?.data?.sub?.length > 0 && (
        <Box>
          <Typography className={classes.detailsCard2}>
            {props?.data?.sub}
          </Typography>
        </Box>
      )}
    </div>
  );
};
