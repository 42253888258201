import * as React from "react"
export const Arrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6.971}
    height={12.52}
    {...props}
  >
    <path
      fill="#98a0ac"
      stroke="#98a0ac"
      strokeWidth={0.5}
      d="M.25.698a.428.428 0 0 0 .132.3L5.646 6.26.382 11.524a.428.428 0 1 0 .605.605l5.566-5.567a.428.428 0 0 0 0-.605L.987.391A.428.428 0 0 0 .25.698Z"
      data-name="icons8-expand-arrow (2)"
    />
  </svg>
)


export const CustomIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 102949" transform="translate(-11563 -17227)">
      <circle
        cx={24}
        cy={24}
        r={24}
        fill="#f5f0f9"
        data-name="Ellipse 129452"
        transform="translate(11563 17227)"
      />
      <g data-name="Group 102946">
        <path
          fill="none"
          d="M11575 17239h24v24h-24z"
          data-name="Rectangle 54908"
        />
        <path
          fill="#8f59c5"
          d="M11581.196 17243a1.8 1.8 0 0 0-1.8 1.8v12.4a1.8 1.8 0 0 0 1.8 1.8h9.2a1.8 1.8 0 0 0 1.8-1.8v-4.324l-2.124 2.124a2.158 2.158 0 0 1-.876.524l-1.988.612a1.465 1.465 0 0 1-.412.06 1.39 1.39 0 0 1-.988-.412 1.322 1.322 0 0 1-.276-.388h-2.336a.601.601 0 0 1-.08-1.2h2.4l.492-1.6h-2.812a.6.6 0 0 1 0-1.2h3.52l2-2h-1.12a1.8 1.8 0 0 1-1.8-1.8V17243Zm5.8.352v4.248a.6.6 0 0 0 .6.6h2.324l.852-.852a.7.7 0 0 1 .116-.1Zm6.784 3.64a.8.8 0 0 0-.55.242l-.232.232a1.706 1.706 0 0 0-1.66.445l-4.18 4.18a1.306 1.306 0 0 0-.324.54l-.612 1.993a.6.6 0 0 0 .573.776.59.59 0 0 0 .176-.027l1.992-.611a1.308 1.308 0 0 0 .541-.325l4.209-4.208a1.637 1.637 0 0 0 .418-1.629l.23-.23a.8.8 0 0 0-.581-1.373Z"
        />
      </g>
    </g>
  </svg>
)


export const NewAgreementIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={43} {...props}>
    <g data-name="Group 105709" transform="translate(-32 -589)">
      <rect
        width={40}
        height={43}
        fill="#f1f7ff"
        data-name="Rectangle 55313"
        rx={4}
        transform="translate(32 589)"
      />
      <path
        fill="#78b1fe"
        d="M52.018 601.004a1.925 1.925 0 0 0-1.418.524l-.447.41-.594-.124a2.01 2.01 0 0 0-2.33 1.369l-.182.578-.577.19a2.008 2.008 0 0 0-1.336 2.347l.131.591-.4.453a2.01 2.01 0 0 0 .018 2.7l.41.447-.123.594a2 2 0 0 0 .518 1.8l-2.488 2.489a.675.675 0 0 0 .277 1.126l2.584.795.795 2.584a.675.675 0 0 0 1.122.279l3.875-3.875c.056.006.109.017.166.018s.088-.011.133-.015l3.872 3.872a.675.675 0 0 0 1.122-.279l.795-2.584 2.584-.795a.675.675 0 0 0 .279-1.122l-2.486-2.486a2 2 0 0 0 .517-1.8l-.123-.594.41-.447a2.01 2.01 0 0 0 .018-2.7l-.4-.453.131-.591a2.008 2.008 0 0 0-1.341-2.354l-.577-.19-.182-.578a2.01 2.01 0 0 0-2.332-1.368l-.594.124-.445-.412a1.941 1.941 0 0 0-1.382-.523Zm-5.086 12.543.179.057.19.576a2.011 2.011 0 0 0 2.35 1.337l.591-.132.313.28-2.74 2.74-.57-1.854a.675.675 0 0 0-.446-.446l-1.854-.57Zm10.136 0 1.987 1.987-1.854.57a.675.675 0 0 0-.446.447l-.57 1.854-2.741-2.741.313-.279.592.132a2.011 2.011 0 0 0 2.35-1.337l.19-.576Z"
        data-name="icons8-warranty (1)"
      />
    </g>
  </svg>
)
