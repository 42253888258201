import makeStyles from "@mui/styles/makeStyles";

export const useStylesCreation = makeStyles((theme) => ({
  root: {
    padding: "24px 24px 80px",
    backgroundColor: theme.palette.background.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "10px 10px 80px",
    },
},
loader:{
  height:"100%",
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}
}));
