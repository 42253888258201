export const AccessDeniedSVG = (props) => (
  <svg
    width={300}
    height={200}
    viewBox="0 0 300 232"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M0 0h30000v20000H0Z"
        style={{
          fill: "transparent",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M3990.87 2009.9S846.586 5061.8 2846.96 8652.3c2000.37 3590.6 4687.03 1784.9 4557.02 5964.1-130.03 4179.1 6178.82 4538.2 8186.92 3326.4 2008.1-1211.8 1996.1-2671.2 6141.6-2188.4 4145.6 482.9 4937.6-3542.4 3859.1-5593.9-1530.3-2910.7 4247-2430.6 606.7-8150.6H3990.87"
        style={{
          fill: "#ebf3fa",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M17798.7 9191.2s668.1 2831.7 2921.9 3143c0 0 14.6 1.8 39.4 1.8 110.9 0 425.8-36 545.2-430.8 146-482.8-725.9-486.3-1264.8-680.6-539-194.2-1795.4-1017.6-2241.7-2033.4"
        style={{
          fill: "#d9e5fc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M17747.5 9721.1s-234.3 2345.6 1410.4 3178c0 0 76.1 37.1 181.7 37.1 111.7 0 256.4-41.4 379.2-211.7 239.1-331.5-427.9-563.9-789.7-854.7-361.7-290.9-1107.4-1252.8-1181.6-2148.7"
        style={{
          fill: "#d9e5fc",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M27726.7 1980.1H1878.51c-16.43 0-29.87 13.5-29.87 29.9v11.4c0 16.4 13.44 29.9 29.87 29.9H27726.7c16.4 0 29.9-13.5 29.9-29.9V2010c0-16.4-13.5-29.9-29.9-29.9"
        style={{
          fill: "#2e3552",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M5586.95 4139.6c-347.97 792.9 914.22 1474.1 914.22 1474.1S8164.4 5075.4 8403.21 5223c238.8 147.7-1332.16 738-1332.16 738 387.6 379.2 1873.87 726.8 1873.87 726.8s1002.55-1383.5 1197.68-1181.6c195.1 201.8-950.22 1179.7-950.22 1179.7 505.09 205.6 1267.02 36.8 1267.02 36.8 1097.2-390.6 538.9-1779.3 538.9-1779.3s357.8-472 1068.5-771.4c0 0 142.9-45.9 341.9-159.3l-110.9-89.3c-33 22.4-66.1 43.1-99.4 61.8l-.6.4-1.5.8c-21.4 11-51.9 26.6-89.5 45.3l-.1.1-.2.1c-87.3 39-149.7 69.6-192.7 93.3l-.3.2c-269.3 126.5-634 280-777.4 258.3 0 0-1.6 27.1-17.2 70 5.5-22.4 11.2-45.6 17.2-70 137.8-559.7-756.8-1392.1-1372.98-1709.5-616.22-317.5-505.84 17.3-416.07 488.8 89.77 471.4 737.85 888.2 837.65 1125.1 99.8 236.9-234.28 124.3-564.99-186.8-330.72-311.1-223.03-805.6-748.86-1318.6-525.84-513-1822.58 208.8-1822.58 208.8 491.53 194.7 1021.91 979.6 939.81 1137-82.1 157.5-1115.3-697.1-1310.79-787.5-195.48-90.3-742.36 5.7-1090.34 798.6M21478.1 3186.3c507.7 599-412.9 1517.7-412.9 1517.7s-1584.7-37.3-1754 152.8c-169.3 190.1 1348.7 296.5 1348.7 296.5-238.7 429.9-1440.3 1116.7-1440.3 1116.7s-1229.4-942.8-1346.7-716.9c-117.3 225.9 1131.1 779.3 1131.1 779.3-385.7 309.4-1091.5 359.9-1091.5 359.9-1054.6-55.5-928.9-1406.8-928.9-1406.8s-433.1-317.5-1128.2-393.8h-.1s-136-2.8-338.3-49.8l73.3-106.4c34.4 11 68.6 20.4 102.4 28l.6.2 1.5.3c21.4 4 52 9.7 89.5 16.2l.1.1h.2c86 11.3 148.1 21.7 191.6 31.2l.3.1c266.7 40.2 623.5 79.2 742.4 23.2 0 0 8.5 23.2 33.1 56.4-10.5-18-21.5-36.7-33.1-56.4-264.6-450.7 297.4-1405.6 750.7-1841 453.3-435.4 444.1-115.8 488.1 317.1 44 432.9-411.3 962.8-436.7 1194.6-25.4 231.7 235.8 47.4 442.6-308.6 206.9-355.9-14.7-757.8 309.5-1339.7 324.2-581.9 1637.9-290.4 1637.9-290.4-376.7 296.5-634.5 1115.8-522.4 1231.4 112.1 115.6 788.8-894.5 935.3-1023.6 146.5-129.2 646.6-187.2 1154.2 411.7"
        style={{
          fill: "#cde0fb",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="m13404.6 8147.2-161.8 45.3c-1.1 47.1-3.9 93.1-8.8 137.9l-.1.9-.2 1.9c-4.1 28.1-10.1 68.2-17.8 117.1v.2l-.1.2c-22.3 110.7-35.7 191.5-42.4 249.1v.5c-63.1 345.4-165.7 801.1-284.9 924-171.9-200.2-489.6-279.3-856.6-279.3-579.5 0-1281.8 197.1-1727.2 426.4-727.55 374.4-330.17 497.9 184.3 734.9 137.6 63.3 298.8 85.6 466.7 85.6 201 0 411.6-31.9 603.4-63.9 191.7-32 364.4-64 489.2-64 36.3 0 68.5 2.7 96.1 8.9 296 66.5-41.2 310.3-566.9 414.7-56.5 11.3-111.6 16.2-166 16.2-187.6 0-366.5-58.4-566.4-116.7-199.8-58.4-420.5-116.7-691.4-116.7-109.9 0-228.08 9.6-356.52 32.7-853.37 153.5-1049.01 1894.3-1049.01 1894.3 286.1-185 749.2-260.5 1126.17-260.5 313.56 0 567.56 52.2 610.66 137.1 95 186.8-1434.45 593.6-1655.33 719.5-220.86 125.8-503.51 717.1 19.7 1595.1 193.87 325.3 524.67 427.7 864.21 427.7 576.82 0 1178.92-295.4 1178.92-295.4s623.2-1967.1 928.7-2095.4c9.9-4.1 19-6.1 27.2-6.1 246 0-231.5 1792-231.5 1792 630.1-112.4 1983.1-1302.1 1983.1-1302.1s-641.9-1911.8-314.2-1960.9c4.5-.6 9-1 13.4-1 318.6 0 468.6 1722.9 468.6 1722.9 543.9-344.4 904.1-1192.2 904.1-1192.2 376.9-1322-1340.1-1737.7-1340.1-1737.7s-208.1-667.3-8.6-1555.5c0 0 53.9-168.8 81.4-437.7M12944 9688.8c-17.7-20.6-36.1-42-55.5-64.5 0 0 25 20.2 55.5 64.5"
        style={{
          fill: "#dce9fb",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M15433.7 13915.6h-634.1l-89.8 2166.5h813.7zm-742.4-733.9c0 56.8 10.1 109.2 30.4 157.3 20.2 48.2 48.9 89.9 85.9 125.1 37 35.2 81.4 62.8 133.4 82.5 51.9 19.8 110.5 29.7 175.7 29.7 65.1 0 124.2-9.9 177-29.7 52.8-19.7 97.7-47.3 134.7-82.5 37-35.2 65.6-76.9 85.9-125.1 20.2-48.1 30.4-100.5 30.4-157.3 0-56.7-10.2-108.7-30.4-156-20.3-47.3-48.9-88.5-85.9-123.8-37-35.2-81.9-62.7-134.7-82.5-52.8-19.7-111.9-29.6-177-29.6-65.2 0-123.8 9.9-175.7 29.6-52 19.8-96.4 47.3-133.4 82.5-37 35.3-65.7 76.5-85.9 123.8-20.3 47.3-30.4 99.3-30.4 156"
        style={{
          fill: "#f78248",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M13346.6 15862.9h-486.7l-68.9 1662.8h624.5zm-569.8-563.2c0 43.5 7.7 83.7 23.3 120.7 15.5 36.9 37.5 68.9 65.9 96 28.4 27 62.5 48.1 102.4 63.3 39.9 15.2 84.8 22.8 134.8 22.8 50 0 95.3-7.6 135.9-22.8 40.6-15.2 75-36.3 103.4-63.3 28.4-27.1 50.4-59.1 65.9-96 15.5-37 23.3-77.2 23.3-120.7 0-43.6-7.8-83.5-23.3-119.8s-37.5-67.9-65.9-95c-28.4-27-62.8-48.1-103.4-63.3-40.6-15.2-85.9-22.8-135.9-22.8s-94.9 7.6-134.8 22.8c-39.9 15.2-74 36.3-102.4 63.3-28.4 27.1-50.4 58.7-65.9 95-15.6 36.3-23.3 76.2-23.3 119.8M22800.3 13053.3h-486.6l-69 1662.8h624.5zm-569.8-563.2c0 43.5 7.8 83.8 23.3 120.7 15.6 36.9 37.5 69 65.9 96 28.4 27 62.5 48.2 102.4 63.4 39.9 15.1 84.9 22.7 134.9 22.7s95.3-7.6 135.8-22.7c40.6-15.2 75.1-36.4 103.5-63.4 28.3-27 50.3-59.1 65.8-96 15.6-36.9 23.4-77.2 23.4-120.7 0-43.6-7.8-83.5-23.4-119.8-15.5-36.3-37.5-67.9-65.8-95-28.4-27-62.9-48.1-103.5-63.3-40.5-15.1-85.8-22.8-135.8-22.8-50 0-95 7.7-134.9 22.8-39.9 15.2-74 36.3-102.4 63.3-28.4 27.1-50.3 58.7-65.9 95-15.5 36.3-23.3 76.2-23.3 119.8M21160.1 8258.5c-101.7-188.7-228.4-379.3-358.3-534.3v-.1c-136-162.4-275.2-285.8-392.7-327.6l-.9-.3c-6.2-2.1-12.2-4.1-18.3-5.7l-.9-.2-.1-.1c-23.8-6.8-46.6-9.8-68.2-8.8-385.6 17.3-792 844.5-792 844.5s10.6 98 85.6 167.3c65.4 60.4 115.7 76.2 115.7 76.2s415.3-426.5 516.8-457.2c63.8-19.2 147.7 278.6 267.9 652.6 120.3 374 542.6 466.2 542.6 466.2s427.4-270.5 102.8-872.5"
        style={{
          fill: "#aec3ff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M20801.8 7724.2v-.1c-136-162.4-275.2-285.8-392.7-327.6l-.9-.3-18.3-5.7-1-.3.1.1c4.3 2.8 130 68 149.7 189.1 20.2 123.6-110.7 224.8 218.2 416.1 191.6 111.5 44.9-271.3 44.9-271.3"
        style={{
          fill: "#94b0ed",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M20500.8 2051.3h903.7c64.7 43.9-17.5 473.2-17.5 473.2s-246.6 93.6-289.2-96.7c-42.8-190.2-314.2-250.7-457.8-273.9-98.3-16-129.4-70-139.2-102.6"
        style={{
          fill: "#24406d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M21878.9 6501.1s-28.5-527.2-64.2-1138.1c-25.6-438-54.8-919.1-79.9-1279.4-3.3-47.8-6.6-93.5-9.8-136.7-.9-13.4-1.9-26.7-2.9-40-64.7-807.9-281.1-1515.7-281.1-1515.7-254-53.5-368.8 44.4-368.8 44.4s-175.1 2051.8-239.9 2879.3c-47.7 609.1-88.4 1496-88.4 1496l1135-309.8"
        style={{
          fill: "#ff9e55",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M21814.7 5363c-25.6-438-54.8-919.1-79.9-1279.4-166.1 391.8-505.5 1919-505.5 1919l585.4-639.6"
        style={{
          fill: "#ed7d2b",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M21841 2051.3h903.8c64.7 43.9-17.5 473.2-17.5 473.2s-246.7 93.6-289.3-96.7c-42.7-190.2-314.1-250.7-457.8-273.9-98.3-16-129.3-70-139.2-102.6"
        style={{
          fill: "#24406d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M20989.4 6605.9s645.3-1606.4 851.6-2215.3c147-433.7 561.4-1955.3 561.4-1955.3s180.3-54.9 331.7 51.1c0 0 154.2 1554.6-157.2 1992.9-165.3 232.6-41.1 2417.6-711.1 2436.3-670 18.7-876.4-309.7-876.4-309.7"
        style={{
          fill: "#ff9e55",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M22310.8 7122.6c-2.7-228.4-5.2-396.1-4.6-438.5 1.8-138.2-86.6-169.4-177.7-190.5 0 0-84.1-18.1-190.3-36.1-104-17.4-229.5-34.6-318.1-34-453.1 3.6-832.3 137-832.3 137-85.3 0-124.1 131.8-138.1 316-9.5 124.6 29 1367.5 33.8 1677.8 3.3 222.6 125.5 428.3 159.7 458.4 316.4 279.8 1250.1 244.5 1418.1 51.5 41.6-47.7 53.7-507.8 55.1-1011.9.9-324.5-2.4-667.1-5.6-929.7"
        style={{
          fill: "#aec3ff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M21750.2 9175c-119.2-129.2-519.6-110-507.7 2.7 1.6 13.9 4.2 96.4 5.6 109.2 1 10.3 2 20.4 2.8 29.8 2.7 27.8 4.9 52.4 6.7 73.6 5.4 61.8 7.1 95.3 7.1 95.3h.4c11.4 3.3 268.9 76.4 396.9 106.7 43 10.1 71.5 15.5 71 11.7-39.3-287.6 56.3-386.6 17.2-429"
        style={{
          fill: "#ffb27d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M21675.6 9507.4s-107.5-109.2-418-117.1c5.4 61.8 7.1 95.3 7.1 95.3h.4c11.4 3.3 268.9 76.4 396.9 106.7 22.6-9.3-7.3-69.4 13.6-84.9"
        style={{
          fill: "#ed975d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M21186 9500.9s78.6-28.2 178.8-41.6c146.5-19.8 339.3-8.2 399.8 169.2 102 298.4 232.2 477.4-67.9 599.2-160.6 65.3-263.4 68.7-333.2 43.8-60.6-21.4-96.4-64.4-123.4-106.8-58.3-91.4-175.5-611.5-54.1-663.8"
        style={{
          fill: "#ffb27d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M21262.7 10395.6c-107.2-70.4-72.2-235.3 54.1-257.6 75.7-13.3 139.2-15.9 175.9-32.2 74.6-33.2 138.8-68.4 121.1-184.1-5.9-39.4-25.1-87.4 76.2-63.6 16.6 3.9 28.6 2.8 36.8-2.2 42-24.9-11.7-145.8-27.9-172 0 0-23.3-86.6 28.5-128.7 6.2-5.1 107.9-49.5 352.9 325.4 38.5 58.8 75.2 191.4 15.8 319.1-59.4 127.6-225.1 289.1-537.2 289.1-81.3 0-203.4-32.2-296.2-93.2"
        style={{
          fill: "#233862",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M22310.8 7122.6c-45.6-211.9-34-214.1-103.9-158.5-285 226.5-201.7 345.2-138.4 541.5 63.3 196.3-158.9 503.3 0 634.2 155.5 127.9 244-78.3 247.9-87.5.9-324.5-2.4-667.1-5.6-929.7"
        style={{
          fill: "#94b0ed",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M22303.8 7149.9s-107.4-175.7-177.8-169.5c-70.4 6.2-165.5 32.5-220.3-19.9-54.9-52.3-143.3-187.4-118.6-223.4 24.7-35.9 124.1-4.1 195.4 35.3 71.5 39.4 183.9-19.4 243.8 25.4 59.9 44.7 203.4 222.3 203.4 222.3l-125.9 129.8"
        style={{
          fill: "#ffb27d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M22261.3 9064.2s891.5-838.8 775.8-1396.9c-52.8-254.3-216.5-468.8-652.7-816.1 0 0-206.4 142.6-251.7 258.4 0 0 396 557.7 380.7 652.7-18.8 116.2-289.1 407.7-386 453-97 45.3-88.1 751.6 133.9 848.9"
        style={{
          fill: "#aec3ff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M22126 6980.4s-94 41.1-124.2 58.7c-30.2 17.6-23.5-10.9-14.2-25.1 9.2-14.3 34.4-46.2 71.3-49.5 36.9-3.4 67.1 15.9 67.1 15.9"
        style={{
          fill: "#ffb27d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M6450.04 8803.3c-32.23 62.2 703.2 286 714.85-327.5 0 0 1377.66 33.9 719.86 801.8-227.73 265.8-204.3 485.6-197.47 690.2 6.91 204.6-141.85 799.3-651.78 739.5 0 0-133.54 54.3-300.2-32.2-379-196.6-1228.01-2098-285.26-1871.8"
        style={{
          fill: "#163560",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M8702.34 8240.2s89.68 136.5 161.95 139.2c72.27 2.7 206.11 40.1 195.4 13.4-10.71-26.8-93.68-29.5-101.71-45.5-8.03-16.1 123.13-40.2 160.6-42.9 37.48-2.6 125.81 0 123.13-18.7-2.67-18.7-159.21-96.4-269.65-85.7-110.45 10.8-246.97-32.1-246.97-32.1l-22.75 72.3"
        style={{
          fill: "#ffb27d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M7405.43 8196s48.75-96.5 137.17-207.4c106.56-133.5 270.86-287.6 477.16-318.1 98.53-14.5 194.9 6.7 283.47 46l.32.1c250.55 111.2 438.95 367 438.95 367 44.16 120.5-28.11 202.1-28.11 202.1s-505.92-113.8-554.1-89.7c-48.18 24.1-344.41 681.3-397.77 790.8-113.42 232.6-293.4 299.4-293.4 299.4L7405.43 8196"
        style={{
          fill: "#aec3ff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M7542.6 7988.6c106.56-133.5 270.86-287.6 477.16-318.1 98.53-14.5 194.9 6.7 283.47 46-9.79-1.2-260.02-32.2-359.76 146.3-101.74 182-211.76 471.1-362.89 374.7-65.21-41.6-62.32-144.9-37.98-248.9"
        style={{
          fill: "#94b0ed",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M5517.96 2735s-154.37-140.9-124.25-241c30.12-100.1 169.43-166.9 188.26-289.2 18.83-122.4 94.13-189.1 161.9-189.1h323.12s49.89 86.1-70.6 112.1c-120.49 25.9-165.92 169.7-158.39 303.1 7.53 133.5-11.29 311.5-11.29 311.5l-256.04 59.3-52.71-66.7M6824.57 2675.1s-55.54-596.8 12.24-630.1l867.35 1.7s0 100.1-139.32 122.4c-139.31 22.2-384.04 103.8-425.46 285.5-41.42 181.6-314.81 220.5-314.81 220.5"
        style={{
          fill: "#1d3356",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M6157.36 8598.6s-12.93-182.2 0-400.9c8.6-145 155.22-339.1 176.02-387.1 52.2-120.5 111.06-140.1 111.06-140.1l455.4-111.6 160.13-39.2 504.95 150.8s229.99 642 137.64 1003.3c-92.35 361.4-233.44 612.4-233.44 612.4s-342.32 135-948.55 34.2l-363.21-721.8"
        style={{
          fill: "#aec3ff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M6290.66 8138.5c8.59-144.9 21.92-279.9 42.72-327.9 52.2-120.5 111.06-140.1 111.06-140.1l455.4-111.6 197.79 58.1s-660.26 100.1-587.1 668.2l-219.87-146.7"
        style={{
          fill: "#f2ccaa",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M7532.8 7573.1s250.06-778.4 266.13-1043.4c16.06-265-478.01-3764.6-497.89-3886.7-18.74-115.1-161.66-188.4-161.66-188.4s-65.74-38.8-159.56-12.3c-130.16 36.7-171.31 80.7-171.31 80.7s-194.63 2495.9-74.18 3387.5c120.45 891.6 121.86 1662.6 121.86 1662.6h676.61"
        style={{
          fill: "#233862",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M6473.34 7596.3s-381.45-511.8-417.01-857.8c-35.56-346-55.79-1561.2-131.54-1898.5-75.76-337.3-354.12-986.3-354.12-2038.3 0 0 28.93-60.3 118.28-92.4 28.56-10.3 66.84-20.3 104-28.8 73.68-16.9 149.26 19.1 182.88 86.8 188.19 378.9 787.64 1601 818.37 1854.6 21.75 179.5 211.02 1367.7 623.88 2064.8 99.12 167.4-60.82 833-60.82 833l-883.92 76.6"
        style={{
          fill: "#2b478b",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M7572.75 7693.2s-100.4-79.4-299.82-83.4c-238.2-4.8-828.46 60.7-828.46 60.7s-26.23-73.2-25.16-150.6c0 0 720.9-181.9 1138.6-26.6 0 0 53.87 101.4 14.84 199.9"
        style={{
          fill: "#233862",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M7257.42 7527.6c0-48 32.59-87 72.66-87 40.08 0 72.67 39 72.67 87 0 47.9-32.59 87-72.67 87-40.07 0-72.66-39.1-72.66-87zm-36.03 0c0 67.8 48.75 123 108.69 123s108.7-55.2 108.7-123c0-67.9-48.76-123.1-108.7-123.1-59.94 0-108.69 55.2-108.69 123.1"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="m5744.99 7485.7 585.4-509.7c42.3-36.8 106.45-32.4 143.28 9.9l746.96 857.8c36.84 42.3 32.4 106.5-9.9 143.3l-585.4 509.7c-42.3 36.9-106.45 32.4-143.28-9.9L5735.09 7629c-36.83-42.3-32.4-106.4 9.9-143.3"
        style={{
          fill: "#f78248",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M6611.05 7550.1c8.31-2.2 310.95-37.8 311-100.3.06-72.3-88.7-169.2-172.31-164.6-33.89 1.8-190.01 145.4-190.01 145.4l51.32 119.5M6766.46 9353.5c34.43 83.5 54.53 210.7 53.96 368.1l22.87-4.5 384.42-76.1s-8.74-121-9-235c0-.9 0-1.9-.01-2.8-.47-99 66.88-198.3-91.9-237.6-49.07-12.1-432.65 12.5-360.34 187.9"
        style={{
          fill: "#ffb27d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M7227.72 9641s-8.75-121-9.01-235c-215.12 6.1-328.83 202.3-375.42 311.1l384.43-76.1"
        style={{
          fill: "#e8945b",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M7299.56 9533.1s-508.98-8.4-509.33 308.6c-.35 317.1-66.27 529.5 258.68 548.3 324.95 18.7 388.22-102.2 414.08-208 25.86-105.8-31.04-638.6-163.43-648.9"
        style={{
          fill: "#ffb27d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M7280.72 10397.7s-139.36-371.9-353.22-426.9c-213.87-55-306.64 2.3-306.64 2.3s164.05 130.3 198.09 357.8c0 0 356.65 243.9 461.77 66.8"
        style={{
          fill: "#163560",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M7246.18 10367.5s103.34-38.6 145.87-114c41.59-73.7 77.03-171.3 68.8-291 0 0 105 252.8-12.99 395.8-125.12 151.6-201.68 9.2-201.68 9.2"
        style={{
          fill: "#163560",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M6611.05 7550.1s72.53 23.3 121 16.5c48.47-6.8 84.34 1 84.34-12.6s-62.58-19.9-75.88-26.4c-13.31-6.6-129.46 22.5-129.46 22.5"
        style={{
          fill: "#ffb27d",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M6520.57 9320.4s-361.3-25.4-499.8-362c-138.49-336.7-401.26-902-401.26-997.1 0-95.1 146.78-388.2 935.08-556.8 0 0 81.54 85.8 76.6 153.6 0 0-492.09 422.7-490.76 472.1 2.5 93.4 80.67 216.8 512.7 649 117.47 117.5-132.56 641.2-132.56 641.2"
        style={{
          fill: "#aec3ff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M16617.7 12189.2h-3904.4c-94.4 0-184.9-37.5-251.7-104.2L9700.81 9324.2a356.063 356.063 0 0 1-104.28-251.8V5168.1c0-94.4 37.51-185 104.28-251.8l2760.79-2760.7c66.8-66.8 157.3-104.3 251.7-104.3h3904.4c94.4 0 185 37.5 251.7 104.3l2760.8 2760.7a356.13 356.13 0 0 1 104.3 251.8v3904.3c0 94.5-37.5 185-104.3 251.8L16869.4 12085c-66.7 66.7-157.3 104.2-251.7 104.2"
        style={{
          fill: "#f78248",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="M12988 11333.9c-43.7 0-84.8-17.1-115.8-48l-2372.3-2372.4c-30.9-30.9-48-72-48-115.7v-3355c0-43.8 17.1-84.9 48-115.8l2372.3-2372.4c30.9-30.9 72.1-47.9 115.8-47.9h3355c43.7 0 84.9 17 115.8 47.9L18831.1 5327c31 30.9 48 72 48 115.8v3355c0 43.7-17 84.8-48 115.7l-2372.3 2372.4c-30.9 30.9-72.1 48-115.8 48zm3355-8775.1h-3355c-136.6 0-265.1 53.2-361.8 149.8L10253.9 5081c-96.6 96.6-149.9 225.1-149.9 361.8v3355c0 136.6 53.3 265.1 149.9 361.8l2372.3 2372.3c96.7 96.6 225.2 149.9 361.8 149.9h3355c136.7 0 265.2-53.3 361.8-149.9l2372.3-2372.4c96.7-96.6 149.9-225.1 149.9-361.7v-3355c0-136.7-53.2-265.2-149.9-361.8l-2372.3-2372.3c-96.6-96.7-225.1-149.9-361.8-149.9"
        style={{
          fill: "#ffe5d4",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
      <path
        d="m17371.4 6992.6-68.3 59.2c-171.7 148.6-433.9 129.8-582.5-41.9l-386.1-445.9c-55.2-63.7-159.8-24.7-159.8 59.6 0 35.3-2 70.2-5.6 104.6v2807.1c0 184.4-150.9 335.2-335.2 335.2h-24.8c-184.4 0-335.2-150.8-335.2-335.2V7708.4c0-44.4-35.9-80.3-80.3-80.3-48.9 0-88.6 39.7-88.6 88.6v2172.6c0 178.2-145.8 324-324 324h-47.1c-178.2 0-324-145.8-324-324V7702.4c0-41-33.3-74.3-74.3-74.3-52.3 0-94.6 42.3-94.6 94.6V9466c0 184.4-150.8 335.2-335.2 335.2h-24.8c-184.3 0-335.2-150.8-335.2-335.2V7708.5c0-44.4-36-80.4-80.4-80.4-48.8 0-88.4 39.6-88.4 88.5v1375c0 184.3-150.9 335.2-335.2 335.2h-24.8c-184.4 0-335.2-150.9-335.2-335.2V6677.3c0-6.7.3-13.3.7-19.9-.4-11.2-.7-22.5-.7-33.8V5294.7c0-540.1 441.9-982 982-982h1328.9c345.4 0 650.6 180.8 825.8 452.4 52.9 82 110.2 161.2 174.1 235l1220.7 1410c148.7 171.7 129.9 433.8-41.9 582.5"
        style={{
          fill: "#ffe5d4",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.01154 0 0 -.01192 -21 222.38)"
      />
    </g>
    <text
      style={{
        whiteSpace: "pre",
        fill: "#333",
        fontFamily: "Arial,sans-serif",
        fontSize: 30,
      }}
      x={49.702}
      y={225.997}
    >
      {"Access Denied"}
    </text>
  </svg>
)