import { makeStyles } from "@mui/styles";

export const ImageUploadStyle = makeStyles((theme)=>({
    avatar:{
        height:100,
        width:"150px",
        borderRadius:4
    },
    viewImageBox:{
        overflow:"hidden",
        '&:hover':{
            overflow:"auto"
        }       
    },
    popupAvatar:{
        height:"450px",
        width:"100%",
        borderRadius:"4px",
        padding:"8px"
    }
}))

