import React, { useState, useEffect } from "react";
import { DialogBox } from "../../components";
import { areaFunction } from "./common";
import { AlertContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { GraphComponent } from "./components";

const initial = {
  location: "",
  status: "Vacant",
  area: "Total Area",
};
export const AreaChart = (props) => {
  // states
  const [state, setState] = useState({ ...initial });
  const [areaState, setAreaState] = useState([]);
  // context
  const alert = React.useContext(AlertContext);
  const [zoom, setZoom] = useState(false);
  //   update state
  const updateState = (key, value) => {
    if (key === "area") {
      getArea(value);
    }
    setState({ ...state, [key]: value });
  };
  // getArea
  const getArea = (e) => {
    // if (!zoom) {
    //   backdrop.setBackDrop({
    //     ...backdrop,
    //     open: true,
    //     message: "Loading...",
    //   });
    // }
    const payload = {
      company_id: props?.selectedcompany?.value ?? undefined,
      is_carpet: e === "Total Area" ? false : true,
    };
    NetworkCall(
      `${config.api_url}/dashboard/property/area`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const final = areaFunction(res?.data?.data);
        setAreaState(final);
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
      })
      .catch((error) => {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  // zoom function
  const zoomGraphFunction = (e) => {
    setZoom(true);
  };
  const handleCloseModule = () => {
    setZoom(false);
  };
  useEffect(() => {
    if (props?.selectedcompany?.label?.length > 0) {
      getArea(state?.area);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedcompany]);
  return (
    <>
      <GraphComponent
        graphData={areaState?.areaDetails ?? []}
        innerRadius={50}
        updateState={updateState}
        margin={"20px"}
        data={state}
        tabNameState={state?.area}
        tabList={["Total Area", "Carpet Area"]}
        keyType={"area"}
        isTab
        isPie
        isTotal
        total={areaState?.totalArea ?? 0}
        onZoomClick={zoomGraphFunction}
        isZoom
      />

      {/* zoom dialog for each graph */}
      <DialogBox
        isnotTitle
        maxWidth={"sm"}
        handleClose={handleCloseModule}
        open={zoom}
        component={
          <div>
            <GraphComponent
              graphData={areaState?.areaDetails ?? []}
              innerRadius={50}
              title={"Area"}
              updateState={updateState}
              margin={"40px"}
              data={state}
              tabNameState={state?.area}
              tabList={["Total Area", "Carpet Area"]}
              keyType={"area"}
              isTab
              isPie
              isTotal
              divider={6}
              divider1={6}
              total={areaState?.totalArea ?? 0}
              padding={"24px 24px 47px"}
            />
          </div>
        }
      />
    </>
  );
};
