import * as React from "react"
const NonSelectedProperty = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={-3.333}
        x2={-2.333}
        y1={0.5}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
    </defs>
    <g data-name="Group 115543">
      <g data-name="icons8-skyscrapers (2)">
        <path
          fill="#a1a1a1"
          d="M13.698 6.651h5.116a.465.465 0 0 1 .465.465v1.4h-5.581Z"
          data-name="Path 100968"
        />
        <path
          fill="#a1a1a1"
          d="M12.302 8.047h7.907a.465.465 0 0 1 .465.465V22h-8.372Z"
          data-name="Path 100969"
        />
        <path
          fill="#ececec"
          d="M9.976 8.977H4.395V7.692a.93.93 0 0 1 .514-.832l5.067-2.534Z"
          data-name="Path 100970"
        />
        <path
          fill="#a7b6cb"
          d="M18.813 9.907h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm1.86 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-3.719h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm1.86 1.86h.93v-.931h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-1.858h.93v-.933h-.93Zm0 1.86h.93v-.93h-.93Z"
          data-name="Path 100971"
        />
        <path
          fill="#ebebeb"
          d="M3 22h8.372V5.256l-7.82 3.475a.93.93 0 0 0-.552.85Z"
          data-name="Path 100972"
        />
        <path
          fill="url(#a)"
          d="M0 0h5.581v1.86H0z"
          data-name="Rectangle 58088"
          transform="rotate(180 6.849 4.256)"
        />
        <path
          fill="#a7b6cb"
          d="M4.86 10.837h-.93v.93h.93Zm1.861-1.86H5.79v.93h.93Zm0 1.86H5.79v.93h.93Zm-1.86 1.86H3.93v.93h.93Zm0 1.86H3.93v.93h.93Zm0 1.86H3.93v.93h.93Zm1.86-3.721H5.79v.93h.93Zm0 1.86H5.79v.93h.93Zm0 1.86H5.79v.93h.93ZM4.86 18.277h-.93v.93h.93Zm0 1.86h-.93v.93h.93Zm1.86-1.86h-.93v.93h.93Zm0 1.86h-.93v.93h.93Z"
          data-name="Path 100973"
        />
        <path
          fill="#d3d3d3"
          d="M8.116 4.326h7.442a.465.465 0 0 1 .465.465V22H7.651V4.791a.465.465 0 0 1 .465-.465Z"
          data-name="Path 100974"
        />
        <path
          fill="#d3d3d3"
          d="M9.977 2h3.721a.465.465 0 0 1 .465.465v2.326H9.512V2.465A.465.465 0 0 1 9.977 2Z"
          data-name="Path 100975"
        />
        <path
          fill="#a7b6cb"
          d="M15.093 5.256v.93h-.93v-.93Zm-.93 2.791h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-5.581h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.862-5.58h.93v-.93h-.93Zm1.86-1.86h.93V2.93h-.93Zm-1.86 0h.93V2.93h-.93Zm0 3.721h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-5.581h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm5.581 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-3.716h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.861-3.72h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-3.72h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm5.581 1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.86-1.86h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Zm-1.861-1.861h.93v-.93h-.93Zm0 1.86h.93v-.93h-.93Z"
          data-name="Path 100976"
        />
        <path
          d="M16.023 6.651V22h.463V6.651Zm-8.837-.93V22h.465V5.488Z"
          data-name="Path 100977"
          opacity={0.05}
        />
        <path
          d="M16.019 6.651V22h.233V6.651ZM7.419 22h.233V5.488l-.233.116Z"
          data-name="Path 100978"
          opacity={0.07}
        />
      </g>
    </g>
  </svg>
)
export default NonSelectedProperty
