export const ScheduledMaintenance = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105130">
            <g
                data-name="FM - Service Requests - Scheduled Maitenance"
                transform="translate(4 4)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 97981"
                    d="M5.759 12.38h1.3l.178.5.13.054.049-.023v-1.083H5.759Z"
                />
                <path data-name="Path 97982" d="M7.966 7.414h1.655v1.655H7.966Z" />
                <path
                    data-name="Path 97983"
                    d="m7.966 12.828 1.21 1.21h.446v-2.21H7.966Z"
                />
                <path data-name="Path 97984" d="M7.966 9.621h1.655v1.655H7.966Z" />
                <path data-name="Path 97985" d="M5.759 7.414h1.655v1.655H5.759Z" />
                <path
                    data-name="Path 97986"
                    d="M7.138 4.655h.552a.276.276 0 0 0 .276-.276v-1.1A.276.276 0 0 0 7.69 3h-.552a.276.276 0 0 0-.276.276v1.1a.276.276 0 0 0 .276.279Z"
                />
                <path
                    data-name="Path 97987"
                    d="M14.31 4.655h.552a.276.276 0 0 0 .276-.276v-1.1A.276.276 0 0 0 14.862 3h-.552a.276.276 0 0 0-.276.276v1.1a.276.276 0 0 0 .276.279Z"
                />
                <path data-name="Path 97988" d="M5.759 9.621h1.655v1.655H5.759Z" />
                <path data-name="Path 97989" d="M10.172 11.828h1.655v2.207h-1.655Z" />
                <path
                    data-name="Path 97990"
                    d="m9.069 15.331-.384-.135-.043-.127a2.66 2.66 0 0 0-.132-.316l-.059-.12.176-.368-.894-.894-.368.176-.12-.059a2.66 2.66 0 0 0-.316-.132l-.129-.041-.133-.384H5.4l-.135.384-.127.043a2.66 2.66 0 0 0-.316.132l-.12.059-.368-.176-.894.894.176.368-.059.12a2.66 2.66 0 0 0-.132.316l-.043.127-.382.133v1.265l.384.135.043.127a2.66 2.66 0 0 0 .132.316l.059.12-.176.368.894.894.368-.176.12.059a2.661 2.661 0 0 0 .316.132l.127.043.135.384h1.265l.135-.384.127-.043a2.66 2.66 0 0 0 .316-.132l.12-.059.368.176.894-.894-.176-.368.059-.12a2.66 2.66 0 0 0 .132-.316l.043-.127.384-.135Zm-3.035 2.566a1.931 1.931 0 1 1 1.931-1.931 1.931 1.931 0 0 1-1.931 1.931Z"
                />
                <path
                    data-name="Path 97991"
                    d="M14.586 13.109a3.3 3.3 0 0 1 1.655-.453v-.828h-1.655Z"
                />
                <path data-name="Path 97992" d="M14.586 9.621h1.655v1.655h-1.655Z" />
                <path data-name="Path 97993" d="M14.586 7.414h1.655v1.655h-1.655Z" />
                <path data-name="Path 97994" d="M10.172 9.621h1.655v1.655h-1.655Z" />
                <path
                    data-name="Path 97995"
                    d="m4.834 12.883.178-.5h.2V7.41a.552.552 0 0 1 .552-.552h10.477a.552.552 0 0 1 .552.552v5.286a3.8 3.8 0 0 1 .552.13V6.31H4.655v6.6l.049.023c.043-.015.086-.033.13-.05Z"
                />
                <path
                    data-name="Path 97996"
                    d="M17.345 4.379a.276.276 0 0 0-.276-.276h-1.38v.276a.829.829 0 0 1-.828.828h-.552a.829.829 0 0 1-.828-.828v-.276H8.517v.276a.829.829 0 0 1-.828.828h-.552a.829.829 0 0 1-.828-.828v-.276H4.931a.276.276 0 0 0-.276.276v1.379h12.69Z"
                />
                <path
                    data-name="Path 97997"
                    d="M12.379 14.035h1.176a3.328 3.328 0 0 1 .479-.528v-1.679h-1.655Z"
                />
                <path
                    data-name="Path 97998"
                    d="M15.685 15.138v-.276h3.31a3.1 3.1 0 0 0-2.754-1.655 2.755 2.755 0 0 0-2.386 1.379h-4.77l-.023.049q.029.065.054.13l.5.178v2.045l-.5.178a4.514 4.514 0 0 1-.054.13l.023.049h4.77a2.755 2.755 0 0 0 2.386 1.379A3.1 3.1 0 0 0 19 17.069h-2.483Z"
                />
                <path data-name="Path 97999" d="M10.172 7.414h1.655v1.655h-1.655Z" />
                <path data-name="Path 98000" d="M12.379 9.621h1.655v1.655h-1.655Z" />
                <path data-name="Path 98001" d="M12.379 7.414h1.655v1.655h-1.655Z" />
                <circle
                    data-name="Ellipse 129542"
                    cx={0.828}
                    cy={0.828}
                    r={0.828}
                    transform="translate(5.207 15.138)"
                />
            </g>
        </g>
    </svg>
)