
export const WaitingForApprovalPath = ["request_type", "requested_date", "request_against_detail", "existing_access", "requested_by", "name", "mobile_no", "email_id"];



export const WaitingForApprovalHeading = (t) => [
    { title: t("Request Type"), field: "request_type" },
    { title: t("Requested Date"), field: "requested_date" },
    { title: t("Request Against Detail"), field: "request_against_detail" },
    { title: t("Existing Access"), field: "existing_access" },
    { title: t("Requested By"), field: "requested_by" },
    { title: t("Name"), field: "name" },
    { title: t("Mobile No"), field: "mobile_no" },
    { title: t("Email ID"), field: "email_id" },
];
export const WaitingForApprovalType = [
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "requested_date" },
    { type: ["description"], name: "request_against_detail" },
    { type: ["text"], name: "existing_access" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "mobile_no" },
    { type: ["text"], name: "email_id" },
];


export const ApprovedPath = ["request_type", "requested_date", "request_against_detail", "existing_access", "requested_by", "name", "mobile_no", "email_id"];



export const ApprovedHeading = (t) => [
    { title: t("Request Type"), field: "request_type" },
    { title: t("Requested Date"), field: "requested_date" },
    { title: t("Request Against Detail"), field: "request_against_detail" },
    { title: t("Existing Access"), field: "existing_access" },
    { title: t("Requested By"), field: "requested_by" },
    { title: t("Name"), field: "name" },
    { title: t("Mobile No"), field: "mobile_no" },
    { title: t("Email ID"), field: "email_id" },
];

export const ApprovedType = [
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "requested_date" },
    { type: ["text"], name: "request_against_detail" },
    { type: ["text"], name: "existing_access" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "mobile_no" },
    { type: ["text"], name: "email_id" },
];

export const DeclinedPath = ["request_type", "requested_date", "request_against_detail", "existing_access", "requested_by", "name", "mobile_no", "email_id"];



export const DeclinedHeading = (t) => [

    { title: t("Request Type"), field: "request_type" },
    { title: t("Requested Date"), field: "requested_date" },
    { title: t("Request Against Detail"), field: "request_against_detail" },
    { title: t("Existing Access"), field: "existing_access" },
    { title: t("Requested By"), field: "requested_by" },
    { title: t("Name"), field: "name" },
    { title: t("Mobile No"), field: "mobile_no" },
    { title: t("Email ID"), field: "email_id" },
];

export const DeclinedType = [
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "requested_date" },
    { type: ["text"], name: "request_against_detail" },
    { type: ["text"], name: "existing_access" },
    { type: ["text"], name: "requested_by" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "mobile_no" },
    { type: ["text"], name: "email_id" },
];