import * as React from "react"
const GreenClock = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="Group 106751">
      <g data-name="Group 106750">
        <g data-name="Group 106748">
          <g data-name="Group 106635">
            <g data-name="Group 106604">
              <g data-name="Group 106603">
                <path
                  fill="none"
                  d="M0 .001h16v16H0z"
                  data-name="Rectangle 55638"
                />
                <path
                  fill="#5ac782"
                  d="M8 2.001a6 6 0 1 0 6 6 6.018 6.018 0 0 0-6-6Zm2.25 6.9h-2.4a.461.461 0 0 1-.45-.45v-3.6a.45.45 0 1 1 .9 0v3.15h1.95a.45.45 0 1 1 0 .9Z"
                  data-name="icons8-clock (2)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default GreenClock
