import { Box, Button, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { TextBox, WeatherComponent } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, Regular, SemiBold, RetainLocalStorage } from "../../utils";
import { ExtraBold, NetWorkCallMethods } from "../../utils/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(/images/mainbackgroundimage.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    position: 'relative'
  },
  grid: {
    justifyContent: "center",
  },
  child: {
    margin: "5% 0% 0% 50%",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: "12px 0px 0px 12px",
    },
  },
  signupform: {
    padding: "0px 28px 0px 12px",
    backgroundColor: "white",
    boxShadow: "0px 8px 69px #0000001A",
    borderRadius: "16px",
    marginTop: "20%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
    },
  },
  text: {
    fontSize:"1.5rem",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
    marginTop: "12px",
  },
  btn: {
    borderRadius: "12px",
    boxShadow: "0px 6px 10px #00000014",
    opacity: 1,
    padding: "8px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    marginTop: 30,
  },
  img: {
    width: "auto",
    height: "21.9px",
  },
  verificationText: {
    fontSize:"0.875rem",
    fontFamily: Regular,
    textAlign: "center",
    color: theme.typography.color.primary,
    backgroundColor: theme.palette.background.secondary,
    borderRadius: "15px",
    padding: "20px",
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    fontSize:"0.75rem",
    color: theme.typography.color.tertiary,
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: SemiBold,
    fontSize:"0.75rem",
  },
}));
const InitialState = {
  email: "",
  error: {
    email: "",
  },
};
export const ResetPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const alert = React.useContext(AlertContext);
  const [value, setValue] = React.useState({ ...InitialState });

  const updateState = (key, email) => {
    let error = value.error;
    error[key] = "";
    setValue({ ...value, [key]: email, error });
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = value.error;
    if (value.email.length === 0) {
      isValid = false;
      error.email = "Email is Required";
    }
    setValue({ ...value, error });
    return isValid;
  };
  const verifyOtp = () => {
    if (isIamValideToLogin()) {
      const payload = {
        email_id: value?.email
      };
      NetworkCall(
        `${config.authapi}/auth/forgotpassword`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          if (response.status === 200) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Email send Successfully",
            });
            history.goBack(-1)

          } else {
            RetainLocalStorage();
            history.push(Routes.login);
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Invalid Email",
            });
          }
        })
        .catch((err) => {

          RetainLocalStorage();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong",
          });
        });
    } else {
      return false;
    }
  };
  return (
    <div className={classes.root}>
      <Grid container className={classes.grid}>
        <Grid item xs={11} sm={6} md={4} lg={3} className={classes.child}>
          <Grid container className={classes.signupform} spacing={2}>
            <Grid
              item
              className={classes.content}
              style={{ padding: "0px 16px 0px 16px" }}
            >
              <Grid item xs={12}>
                <br />
                <Typography className={classes.text}>
                  Don't worry we can reset your password
                </Typography>
              </Grid>
              <Box height={"24px"} />
              <Grid item xs={12}>
                <TextBox
                  placeholder="Enter email ID"
                  label="Enter your register email ID"
                  value={value.email}
                  onChange={(e) => updateState('email', e.target.value)}
                  isError={value.error.email.length > 0}
                  errorMessage={value.error.email}
                />
                <br />
                <br />
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btn}
                  onClick={verifyOtp}
                >
                  <Typography className={classes.buttonTextStyle}>
                    Submit
                  </Typography>
                </Button>
                <br />
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <WeatherComponent />
    </div>
  );
};
