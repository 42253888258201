import React from 'react';
import { ChipComponent } from './chips';

export const FilterChips = (props) => {

    const {
        data = {},
        onClear = null,
        backgroundColor
    } = props;

    const constructValue = (value) => {
        if (value?.constructor === Array) {
            return typeOfValue(value?.[0])
        }
        else {
            return typeOfValue(value)
        }
    }

    const typeOfValue = (value) => {
        if (value?.constructor === Object) {
            return value?.label
        }
        else {
            return value
        }
    }

    const onClearChip = (key, value) => {
        onClear && onClear(key, value)
    }

    return (
        <>
            {
                Object.keys(data)?.map((key, index) => (
                    <ChipComponent
                        key={index}
                        value={constructValue(data[key])}
                        count={data[key]?.constructor === Array ? data[key]?.length > 1 ? data[key]?.length - 1 : '' : ''}
                        onClear={() => onClearChip(key, data[key])}
                        backgroundColor={backgroundColor}
                    />
                ))
            }
        </>
    )
}