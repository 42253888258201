/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog, Divider, Grid, IconButton, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from "react-router-dom";
import { Filter, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { NewLoader } from '../../components/newLoader';
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from '../../router/routes';
import { accessCheckRender, AlertProps, ExtraBold, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils";

import { Bold } from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
        margin: "14px"
    },
    btn: {
        borderRadius: theme.palette.borderRadius,
    },
    btn2: {
        borderRadius: theme.palette.borderRadius,
        color: theme.palette.secondary.main,
    },
    text: {
        fontSize:"1rem",
        fontFamily: Bold,
        color: theme.typography.color.primary
    },
    subText: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.secondary
    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "10px"
    },
    searchdrop: {
        borderRadius: "10px",
        border: "1px solid #E4E8EE",
        cursor: "pointer",
    },
    chip: {
        backgroundColor: "#F2F4F7",
        marginLeft: "6px",
        marginTop: "10px",
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.875rem",
        cursor: "pointer",
        border: "none",
        boxShadow: "none",
        color: theme.typography.color.secondary,
        "&:hover": {
            border: "none",
            boxShadow: "none",
        },
        display: "flex",
        alignItems: "center",
    },
    Label: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        marginTop: "6px",
    },
    UnitStatusactive: {
        padding: "10px 16px",
        textAlign: "center",
        borderRadius: theme.palette.borderRadius,
        color: "white",
        fontSize:"0.875rem",
        backgroundColor: "#5078E1",
        cursor: "pointer",
    },
    UnitStatus: {
        padding: "10px 16px",
        textAlign: "center",
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.875rem",
        cursor: "pointer",
        backgroundColor: "#F2F4F7",
    },
    previousbtn: {
        backgroundColor: "white",
        color: "#091B29",
        border: "1px solid #CED3DD",
        borderRadius: "10px",
        padding: "12px 8px",
        boxShadow: "none",
    },
    submitbtn: {
        borderRadius: "10px",
        padding: "12px 8px",
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
            maxHeight: `calc(100% - 22px)`
        },
    },
    drawerHeaderStyle: {
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    },
    drawerHeaderTextStyle: {
        fontSize:"1rem",
        fontFamily: ExtraBold,
        color: theme.typography.color.primary,
    },
}))

export const OwnerTable = props => {
    const { loading, handleLoading } = props;
    const history = useHistory();
    const classes = useStyles();
    const [ownerListTable, setOwnerListTable] = React.useState([]);
    const [ownerSearch, setOwnerSearch] = React.useState("");
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [count, setCount] = React.useState("");
    const [drawer, setDrawer] = React.useState(null);
    const alert = React.useContext(AlertContext);
    const backdrop = React.useContext(BackdropContext);
    const auth = React.useContext(AuthContext);
    const [loader, setLoader] = React.useState(true);

    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [permission, setPermission] = React.useState({})

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);


    const defaultData = {
        company: [],
    };
    const [selectedFilter, setSelectedFilter] = React.useState(defaultData);
    const [companyList, setCompanyList] = React.useState([]);
    // const draweropen = () => {
    //     setDrawer(true);
    // };
    const drawerclose = () => {
        setDrawer(false);
    };
    const filterData = [
        {
            id: "1",
            title: "Company",
            key: "company",
            filterType: "CHECKBOX",
            values: companyList,
        },

    ];
    const applyFilter = (value) => {
        setSelectedFilter({ ...value });
        getOwners(offset, limit, ownerSearch, value, "filter")
        drawerclose();
    };


    //owners
    const getOwners = (offset, limit, v, filterid, key) => {
        let companyId = [selectedCompany?.value]
        if (key) {
            setOwnerSearch(v)

            const payload = {
                tenantId: `${config.tenantId}`,
                type: "Owner",
                company_id: companyId,
                offset: offset,
                limit: limit,
                search: v

            };
            NetworkCall(
                `${config.api_url}/owner-resident/listOwners`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    let arry = [];
                    if (response?.data?.data?.list && response?.data?.data?.list?.length > 0) {
                        response.data.data?.list?.map((val, index) => {
                            let obj = {
                                sno: (offset ?? 0) + index + 1,
                                index: offset + index + 1,
                                ownerName: `${val?.first_name} ${""}${val?.last_name ? val?.last_name : ""}`,
                                userNo: val?.user_no ? val?.user_no : " - ",
                                phoneNumber: val?.mobile_no_country_code + val?.mobile_no ? val?.mobile_no_country_code + val?.mobile_no : " -",
                                emailId: val?.email_id ? val?.email_id : " - ",
                                totalUnits: val?.units ? val?.units : "-",
                                companyName: val?.company_name ? val?.company_name : " - ",
                                icon: "view",
                                ...val,
                            };
                            arry.push(obj);
                        });
                    }
                    setOwnerListTable(arry)
                    setCount(response?.data?.data?.count)
                    selectedFilter.company = filterid?.company
                    setSelectedFilter({ ...selectedFilter });
                    handleLoading(false)
                    setLoader(false)
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                    })
                });
        } else {
            setOwnerSearch(v)

            const payload = {
                tenantId: `${config.tenantId}`,
                type: "Owner",
                company_id: companyId,
                offset: offset,
                limit: limit,
                search: v

            };
            NetworkCall(
                `${config.api_url}/owner-resident/listOwners`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    let arry = [];
                    if (response?.data?.data?.list && response?.data?.data?.list?.length > 0) {
                        response.data.data?.list?.map((val, index) => {
                            let obj = {
                                sno: (offset ?? 0) + index + 1,
                                index: offset + index + 1,
                                ownerName: `${val?.first_name} ${""}${val?.last_name ? val?.last_name : ""}`,
                                userNo: val?.user_no ? val?.user_no : " - ",
                                phoneNumber: val?.mobile_no_country_code + val?.mobile_no ? val?.mobile_no_country_code + val?.mobile_no : " -",
                                emailId: val?.email_id ? val?.email_id : " - ",
                                totalUnits: val?.units ? val?.units : "-",
                                companyName: val?.company_name ? val?.company_name : " - ",
                                icon: "view",
                                ...val,
                            };
                            arry.push(obj);
                        });
                    }
                    setOwnerListTable(arry)
                    setCount(response?.data?.data?.count)
                    handleLoading(false)
                    setLoader(false)
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center
                    })
                });
        }
    };
    //pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getOwners(offset, limit, ownerSearch);
    };
    //change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getOwners(0, value, ownerSearch);
    };
    React.useEffect(() => {
        if (selectedCompany?.value) {
            getOwners(offset, limit, ownerSearch);
        }
        // eslint-disable-next-line
    }, [selectedCompany]);
    const OwnerTablepath = ["sno", "ownerName", "userNo", "phoneNumber", "emailId", "totalUnits", "companyName", "currentStatus", "status"]

    const OwnerTableheading = [
        { title: "S No", field: "sno", },
        { title: "Owner Name", field: 'ownerName', },
        { title: "User Id", field: "userNo", },
        { title: "Phone Number", field: 'phoneNumber', },
        { title: "Email Id", field: 'emailId', },
        { title: "Total Units", field: 'totalUnits', },
        { title: "Company Name", field: 'companyName', },

        // { title: "Current Status", field: 'currentStatus', },
        // { title: "", field: "status", },
    ]



    const handleIcon = (type, data) => {
        if (type === "view") {
            history.push(Routes.ownerTableDetail + "?userId=" + data?.id + "&companyId=" + data?.company_id + "&topNavBarTitle=" + data?.first_name)
        }
    }
    const handleCompanyChange = (value) => {
        setLoader(true)
        setSelectedCompany(value)
    }

    const render = () => {
        return <>

            <Subheader hideBackButton={true} title="Landlords"
                select options={companyList} value={selectedCompany}
                goBack={() => {
                    history.push(Routes.home)
                }}
                onchange={(e) => {
                    handleCompanyChange(e)
                }

                }
            />
            {loader ? <NewLoader minusHeight='100px' /> :
                <div className={classes.root}>
                    <Grid container className={classes.content} spacing={2}>
                        <Grid item xs={4}>
                            <SearchFilter placeholder={"Search by owner name"} value={ownerSearch} handleChange={(value) => getOwners(offset, limit, value)} />
                        </Grid>
                        <Grid item xs={12} marginTop="-14px">
                            <TableWithPagination
                                heading={OwnerTableheading}
                                rows={ownerListTable}
                                path={OwnerTablepath}
                                showpagination={true}
                                tableType="no-side"
                                onClick={() => false}
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                handleIcon={handleIcon}
                                handlePagination={handlePagination}
                                handleChangeLimit={handleChangeLimit}
                                totalRowsCount={count}
                                page={page}
                                limit={limit}
                                height={'calc(100vh - 290px)'}
                                dataType={[
                                    { type: ["increment"], name: "sno" },
                                    { type: ["text"], name: "ownerName" },
                                    { type: ["text"], name: "userNo" },
                                    { type: ["text"], name: "phoneNumber" },
                                    { type: ["text"], name: "emailId" },
                                    { type: ["text"], name: "totalUnits" },
                                    { type: ["text"], name: "companyName" },
                                ]}
                                view={permission?.read}
                                edit={permission?.update}
                                delete={permission?.delete} />
                        </Grid>

                    </Grid>
                    {/* filter drawer */}
                    <Dialog
                        fullWidth={true}
                        maxWidth="sm"
                        open={drawer}
                        onClose={drawerclose}
                        className={classes.dialog}>
                        <Grid
                            container
                            direction="row"
                            className={classes.drawerHeaderStyle}>
                            <Grid>
                                <Typography className={classes.drawerHeaderTextStyle}>
                                    {"Filter By"}
                                </Typography>
                            </Grid>
                            <Grid>
                                <IconButton
                                    style={{
                                        padding: "0px",
                                    }}
                                    onClick={drawerclose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />
                        {/* <Grid container> */}
                        <Grid item xs={12}>
                            <Filter
                                filterData={filterData}
                                onGoBack={drawerclose}
                                selectedList={filterData[0]}
                                filters={{
                                    company: selectedFilter.company,
                                }}
                                onApply={applyFilter}
                                onClose={drawerclose}
                            />
                        </Grid>
                        {/* </Grid> */}
                    </Dialog>
                </div>}
        </>
    }
    return <Grid>
        {accessCheckRender(render, permission, "", loading)}
    </Grid>
}