import React from "react";
import { withNavBars } from "../../HOCs";
import ManagementRoleMaster from "./managementRoleMaster";

class ManagementRoleMasterParent extends React.Component {
    render() {
        return <ManagementRoleMaster />
    }
}
const props = {
    boxShadow: false
}

export default withNavBars(ManagementRoleMasterParent, props);
