import React from "react";
import { withNavBars } from "../../HOCs";
import  ResourceBooking from "./resourceBooking";

class ResourceBookingParent extends React.Component {
    render() {
        return <ResourceBooking/>;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(ResourceBookingParent, props);