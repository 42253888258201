import React, { useState } from "react";
import { FormGenerator } from "../../../../components";
import { Button, Box } from "@mui/material";
import { useStyleTimeLine } from "../../styles";
import { AlertContext } from "../../../../contexts";
import { AlertProps, NetWorkCallMethods, getDateFun } from "../../../../utils";
import { subDays } from "date-fns";
import { NetworkCall } from "../../../../networkcall";
import { config } from "../../../../config";
import moment from "moment-timezone";

const initial = {
  duration: {
    "value": 1,
    "select": "Month"
  },
  start_date: "",
  end_date: "",
  des: "",
  type: "Month",
  typeValue: "",
  sucess: false,
  error: {
    duration: "",
    start_date: "",
    end_date: "",
    des: "",
    type: "",
  },
};

export const TermisionRequestForm = ({ t, details, agreement_id, onCloseDialog, agreementReload }) => {
  const classes = useStyleTimeLine();
  const alert = React.useContext(AlertContext);

  const [state, setState] = useState({ ...initial, start_date: details?.lease_end_date ? addDays(details?.lease_end_date, 1) : "" });
  const [disable, setDisable] = useState(false)

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const updateState = (key, value) => {
    let error = state?.error;
    error[key] = "";
    setState({ ...state, [key]: value, error });
  };

  const validateContact = () => {
    let isValid = true;
    let error = state.error;
    if (state?.duration?.value?.length === 0) {
      isValid = false;
      error.duration = "Agreement Duration is Required";
    }
    if (state?.start_date === "") {
      isValid = false;
      error.start_date = "Start Date is Required";
    }
    // if (state?.des?.length === 0) {
    //   isValid = false;
    //   error.des = "Remarks is Required";
    // }

    setState({ ...state, error });

    return isValid;
  };

  React.useMemo(() => {
    if (state?.start_date !== "" && state?.duration !== "") {
      const lease_end_date = getDateFun(
        state?.duration?.select,
        state?.start_date,
        state?.duration?.value
      );
      setState({
        ...state,
        end_date: subDays(lease_end_date, 1),
      });
    } else if (state?.duration === "") {
      setState({
        ...state,
        end_date: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.start_date, state?.duration]);

  const sumbit = () => {
    if (validateContact()) {
      setDisable(true)
      const variables = {
        "agreement_id": agreement_id,
        "remarks": state?.Description,
        "contact_id": details?.contact?.id,
        "renewal_type": "Custom",
        "period": details?.lease_period,
        start_date: moment(state?.start_date).format("YYYY/MM/DD") ?? null,
        end_date: moment(state?.end_date).format("YYYY/MM/DD") ?? null,
        "request_purpose": "Renewal",
        "status": "Pending"
      };
      NetworkCall(
        `${config.api_url}/agreement_request/create_agreement_request`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((res) => {

          onCloseDialog()
          agreementReload()
          setDisable(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Renewal request created successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });

        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Some Thing Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          setDisable(false)
        });
    }

  };
  return (
    <Box p={2}>
      <FormGenerator
        t={t}
        components={[
          {
            size: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
            },
            isActive: true,
            component: "textWithToogle",
            label: "Agreement Duration",
            value: state?.duration,
            placeholder: "Agreement Duration",
            onChange: (value) => updateState("duration", value),
            error: state?.error?.duration,
            isrequired: true,

            options: [
              {
                value: "Month",
                label: "Month",
              },
              {
                value: "Yearly",
                label: "Yearly",
              },
            ],
            is_toogle: true,
          },
          {
            isActive: true,
            component: "date",
            label: t("Start_Date"),
            value: state?.start_date,
            placeholder: t("Start_Date"),
            onChange: (value) => updateState("start_date", value),
            error: state?.error?.start_date,
            maxDate: new Date(),
            isRequired: true,
            isNot: true,
            size: {
              xs: 6,
            },
          },
          {
            isActive: true,
            component: "date",
            label: t("End_Date"),
            value: state?.end_date,
            placeholder: t("End_Date"),
            onChange: (value) => updateState("end_date", value),
            error: state?.error?.end_date,
            maxDate: new Date(),
            isRequired: true,
            isNot: true,
            size: {
              xs: 6,
            },
          },
          {
            isActive: true,
            component: "text",
            label: t("Remarks_optional"),
            value: state?.Description,
            placeholder: t("Remarks_optional"),
            onChange: (e) => updateState("Description", e?.target?.value),
            error: state?.error?.Description,
            multiline: true,
            size: {
              xs: 12,
            },
          },
        ]}
      />
      <Box height={"24px"} />
      <Button onClick={sumbit} disabled={disable} className={classes.Yes}>{t("Submit")}</Button>

    </Box >
  );
};
