import React from "react";
import { withNavBars } from "../../HOCs";
import FunctionalLocation from "./functionalLocation";

class FunctionalLocationParent extends React.Component {
    render() {
        return (
            <FunctionalLocation />
        );
    }
}

const props = {
    boxShadow: false
}
export default withNavBars(FunctionalLocationParent, props);