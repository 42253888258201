import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '@mui/material';
import Divider from "@mui/material/Divider";
import Popover from '@mui/material/Popover';
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalenderIcon, CustomTypography } from '.';
import { ToggleButtonComponent } from '../../../components';
import { AlertContext } from '../../../contexts';
import { AlertProps, Regular, SemiBold } from '../../../utils';
import { Bold } from '../../../utils';

const Wrapper = styled(Stack)({
    border: '1px solid #E4E8EE',
    borderRadius: '4px',
    backgroundColor: '#fff',
    position: 'relative',
    // overflow: 'hidden',
    cursor: 'pointer',
    padding: '9px',
})

const Container = styled('div')({
    padding: 16,
    boxShadow: '0px 8px 24px #0717411F',
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    '& .react-datepicker': {
        border: 0,
        '& .react-datepicker__header': {
            backgroundColor: '#F2F4F7',
            borderBottom: '2px solid #2579ba'
        },
        '& .react-datepicker__month-select':{
            fontFamily: SemiBold
        },
        '& .react-datepicker__year-select':{
            fontFamily: SemiBold
        },
        "& .react-datepicker__month-container":{
            margin:"0px !important",
            padding:"0px !important",
            height:"306px"
        },
    }
})

const Div = styled('div')({
    display: 'flex'
})

const Input = styled('input')({
    maxWidth: 50,
    maxHeight: 26,
    paddingInline: 11,
    color: '#091B29',
    fontFamily: Bold,
    fontSize:"0.875rem",
    border: '1px solid #9eaabb !important',
    borderRadius: '4px !important',
    '&:focus-visible': {
        outline: '1px solid #9eaabb !important'
    }
})

const CustomDivider = styled(Divider)({
    marginBlock: '16px'
})

const DURATION_PERIOD = [
    //{ label: "Day", value: "Day", },
    { label: "Month", value: "Month", },
    { label: "Year", value: "Year", }
]

export const CustomDatePicker = (props) => {

    const {
        label,
        placeholder = "Choose Date",
        value = null,
        onChange = null,
        data = "",
        title = "",
        showEndDate = false,
        lead = null,
        disabled = false,
        min_date_duration = new Date()
    } = props;

    const alert = useContext(AlertContext);
    const [anchorEl, setAnchorE1] = useState(null)
    const [state, setState] = useState({});

    useEffect(() => {
        if (anchorEl) {
            setState(value)
        }
    }, [value, anchorEl])

    const onChangeValue = (key, val) => {
        let updateValue = {
            ...state,
            [key]: val
        }
        setState(updateValue)
    }


    const onClear = () => {
        setState({})
    }

    const onApply = () => {
        if (showEndDate) {
            if (state?.date && state?.input && state?.period) {
                onChange && onChange({ ...state, input: state?.input === "0" ? 1 : state?.input });
                setAnchorE1(null)
            }
            else {
                showAlert('Please fill all the fields')
            }
        }
        else {
            if (state?.date) {
                onChange && onChange(state);
                setAnchorE1(null)
            }
            else {
                showAlert('Please choose date')
            }
        }
    }


    const showAlert = (msg = "") => {
        alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center
        })
    }

    const onClose = () => {
        onChange && onChange(value);
        setAnchorE1(null)
    }


    return (
        <div>
            <Wrapper onClick={e => {
                if (!disabled) {
                    setAnchorE1(e.target)
                }
            }}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                >
                    <div style={{ flex: 1, width: '80%' }}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <CalenderIcon />
                            {label &&
                                <CustomTypography
                                    fontFamily={SemiBold}
                                    fontSize={12}
                                    color={"#98A0AC"}
                                    textTransform={"capitalize"}
                                >
                                    {label}
                                </CustomTypography>
                            }
                        </Stack>

                        <CustomTypography
                            fontFamily={data ? SemiBold : Regular}
                            fontSize={14}
                            color={data ? '#091B29' : '#98A0AC'}
                            noWrap={!data}
                            textTransform={"capitalize"}
                            marginTop={'3px'}
                        >
                            &#x202a;{
                                lead ?
                                    <>
                                        {lead?.lease_start_date ? moment(lead?.lease_start_date).format("DD MMM YY") : ""} - {lead?.lease_end_date ? moment(lead?.lease_end_date).format("DD MMM YY") : ""}
                                    </>
                                    :
                                    data ? data : placeholder
                            }&#x202c;


                        </CustomTypography>
                    </div>
                    <Div>
                        <ArrowDropDownIcon
                            style={{
                                fontSize: "26px",
                                color: "#98A0AC",
                                transform: anchorEl ? 'rotate(180deg)' : 'rotate(0)',
                                transition: '0.25s',
                            }} />
                    </Div>
                </Stack>
            </Wrapper>
            {
                anchorEl &&
                <Popover
                    open={true}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Container>
                        {
                            title &&
                            <CustomTypography
                                color={"#4E5A6B"}
                                fontSize={12}
                                fontFamily={SemiBold}
                                marginBottom={8}
                            >
                                {title}
                            </CustomTypography>
                        }
                        {
                            showEndDate &&
                            <>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={'6px'}
                                >
                                    <Input
                                        min="1"
                                        InputProps={{ inputProps: { min: 1, max: 10 } }}
                                        type="number"
                                        value={state?.input ?? new Date()}
                                        onChange={e => onChangeValue('input', e.target.value)}
                                        sx={{maxHeight:"28px !important"}}
                                    />
                                    <Div style={{ flex: 1 }}>
                                        <ToggleButtonComponent
                                            options={DURATION_PERIOD}
                                            value={state?.period}
                                            onChange={(value) => onChangeValue('period', value)}
                                            // buttonHeight
                                            buttonGroupStyle={{
                                                gap: '6px'
                                            }}
                                            buttonStyle={{
                                                height: '32px',
                                                borderColor: '#E4E8EE',
                                                borderRadius: '4px !important',
                                                paddingBlock: 0,
                                                flex: 1,
                                                backgroundColor: '#F2F4F7'
                                            }}
                                        />
                                    </Div>
                                </Stack>
                                <CustomDivider />
                            </>
                        }
                        <DatePicker
                            minDate={min_date_duration}
                            selected={state?.date ?? ''}
                            onChange={(value) => onChangeValue('date', value)}
                            placeholderText={placeholder}
                            inline
                            peekNextMonth
                            dropdownMode="select"
                            showMonthDropdown
                            showYearDropdown
                        />
                        <CustomDivider sx={{ marginTop: 0, marginBottom: '12px' }} />
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <CustomTypography
                                color={"#071741"}
                                fontSize={12}
                                fontFamily={Bold}
                                sx={{ cursor: 'pointer', userSelect: 'none' }}
                                onClick={onClear}
                            >
                                Clear
                            </CustomTypography>
                            <Button variant='contained' onClick={onApply}>
                                Apply
                            </Button>
                            {/* <CustomTypography
                                color={"#5078E1"}
                                fontSize={12}
                                fontFamily= SemiBold
                                sx={{ cursor: 'pointer', userSelect: 'none' }}
                                onClick={onApply}
                            >
                                Apply
                            </CustomTypography> */}
                        </Stack>
                    </Container>
                </Popover>
            }
        </div>
    )
}
