import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';

export const SwitchComponent = (props) => {

    const {
        checked = false,
        handleChange = null,
        readOnly = false,
        label = "",
        labelPlacement = "top",
        parent_id = ""
    } = props;

    const onChange = value => {
        handleChange && handleChange(value)
    }

    return (
        <FormGroup>
            <FormControlLabel
                labelPlacement={labelPlacement}
                control={
                    <Switch
                        id={`${parent_id}__switch`}
                        checked={checked ? checked : false}
                        onChange={(e) => onChange(e.target.checked)}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        disabled={readOnly}
                    />}
                label={label}
            />
        </FormGroup>

    )
}


SwitchComponent.propTypes = {
    checked: PropTypes.bool,
    handleChange: PropTypes.func,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    parent_id: PropTypes.string,
    labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
}