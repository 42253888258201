import React from 'react';
import "../../../../App.css";
import { MiniPropertyDetail } from "../../../../components";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { UnitList } from "./unitList/unitList";

export const PropertyDetails = (props) => {
      const { t = () => false } = props
      // const classes = propertyStyles()
      const size = useWindowDimensions()
      // const modules = {
      //       toolbar: false,
      // };
      return (

            <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "24px" }}>
                  {/*property detials first card */}
                  <MiniPropertyDetail
                        t={t}
                        logo={props?.data?.logo}
                        property_no={props?.data?.property_no}
                        company_name={props?.data?.company?.name}
                        property_name={props?.data?.name}
                        address={props?.data?.address} />
                  <UnitList t={t} companyId={props?.companyId} />
            </div>
      )
}