export const Renewals = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="agreement renew">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 93625"
                    d="M15.302 20.686H9.313V7.762h11.374v7.54a4.122 4.122 0 0 1 .517.253V7.503a.258.258 0 0 0-.259-.258H9.054a.258.258 0 0 0-.258.258v13.183H7.503a.258.258 0 0 0-.258.259v.517a1.294 1.294 0 0 0 1.292 1.292h8.6a4.161 4.161 0 0 1-1.834-2.068Z"
                />
                <path
                    data-name="Path 93626"
                    d="M19.136 15.517a3.619 3.619 0 1 0 3.619 3.619 3.619 3.619 0 0 0-3.619-3.619Zm2.381 1.638-.429 1.073-.516.168-1.182-.783v-.531l.959-.8a3.117 3.117 0 0 1 1.168.873Zm-3.6-.872.959.8v.532l-1.165.787-.517-.168-.436-1.089a3.118 3.118 0 0 1 1.159-.862Zm-1.877 3.069.994-.626.51.166.442 1.385-.317.436-1.159.077a3.082 3.082 0 0 1-.468-1.438Zm3.094 2.886a3.1 3.1 0 0 1-.761-.095l-.285-1.127.316-.435h1.46l.307.424-.289 1.143a3.1 3.1 0 0 1-.747.091Zm2.63-1.459-1.175-.078-.306-.423.458-1.391.51-.166.979.617a3.081 3.081 0 0 1-.464 1.441Z"
                />
                <path
                    data-name="Path 93627"
                    d="M15.775 11.381h.258a.259.259 0 0 0 .135-.038l3.36-2.068-.271-.44-3.3 2.03h-1.922l-3.3-2.03-.271.44 3.36 2.068a.258.258 0 0 0 .135.038l-.465.62a.259.259 0 0 0 .024.338 1.271 1.271 0 0 0 1.469.238l.273-.136 1.523.653a.67.67 0 0 1-.3.41l-1.082.676a.78.78 0 0 1-.822 0l-1.082-.676a.67.67 0 0 1-.317-.572.258.258 0 0 0-.133-.226l-2.326-1.292-.251.452 2.2 1.224a1.183 1.183 0 0 0 .55.852l1.082.676a1.3 1.3 0 0 0 1.37 0l1.081-.676a1.183 1.183 0 0 0 .549-.852l2.2-1.224-.251-.452-2.198 1.234-1.693-.726a.258.258 0 0 0-.217.006l-.38.19a.755.755 0 0 1-.694-.009l.544-.725Z"
                />
                <path data-name="Path 93628" d="M10.088 17.843h4.911v.517h-4.911Z" />
                <path data-name="Path 93629" d="M10.088 19.136h4.911v.517h-4.911Z" />
                <path data-name="Path 93630" d="M11.898 16.551h3.1v.517h-3.1Z" />
                <path
                    data-name="Path 93631"
                    d="m13.051 9.365.279.435a3.1 3.1 0 0 1 3.34 0 .258.258 0 0 0 .38-.313l-.517-1.3-.481.191.265.669a3.625 3.625 0 0 0-3.266.318Z"
                />
                <path
                    data-name="Path 93632"
                    d="m18.444 13.528-.492-.159a3.1 3.1 0 0 1-5.9 0 .258.258 0 0 0-.5.053l-.162 1.551.514.054.067-.639a3.622 3.622 0 0 0 6.481-.859Z"
                />
            </g>
            <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
