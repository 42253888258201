import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import DeclineIcon from '../../../assets/declineIcon'
import { config } from '../../../config'
import { AlertContext } from '../../../contexts'
import { NetworkCall } from '../../../networkcall'
import { AlertProps, NetWorkCallMethods } from '../../../utils'
import { CancelBookingStyle } from './style'

const CancelBooking = (
    {
        amenityBookingDetail = {},
        t = () => false,
        closeDecline = () => false,
        closeCancel = () => false,
        updateAmenityBookingList = () => false
    }
) => {
    const classes = CancelBookingStyle()
    const alert = React.useContext(AlertContext)
    const [remark, setRemark] = React.useState("")

    const cancelBooking = () => {
        const data = {
            booking_id: amenityBookingDetail?.booking_id,
            remarks: remark
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/cancel_booking`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.type === "success") {
                    closeDecline()
                    closeCancel()
                    updateAmenityBookingList()
                }
            })
            .catch((error) => {
                if (error?.response?.data?.error?.code === 406) {
                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.info,

                        msg: error?.response?.data?.error?.message ?? t("Try Again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                } else {
                    alert.setSnack({
                        ...alert, open: true, severity: AlertProps.severity.error,
                        msg: AlertProps.message.some_thing_went_wrong,
                    })
                }
                // alert.setSnack({
                //     ...alert,
                //     open: true,
                //     severity: AlertProps.severity.error,
                //     msg: AlertProps.message.some_thing_went_wrong,
                // });
            });
    };

    return (
        <Box p={3}>
            <Stack direction={"row"} spacing={1.4} alignItems={"center"} className={classes.declineBox} p={2}>
                <DeclineIcon />
                <Stack>
                    <Typography className={classes.declineTitle}>{t("Declining the amenities request")}</Typography>
                    <Typography className={classes.declineContent}>{t("This request will be closed and mark as declined")}</Typography>
                </Stack>
            </Stack>
            <Stack pt={3}>
                <Typography className={classes.header}>{t("ReasonforCancellation")}</Typography>
                <TextField
                    multiline
                    minRows={3}
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                />
            </Stack>
            <Box pt={4}>
                <Stack spacing={2} direction={"row"}>
                    <Button variant='outlined' fullWidth onClick={closeCancel} className={classes.submitBtn}>{t("Cancel")}</Button>
                    <Button variant='contained' fullWidth onClick={cancelBooking} className={classes.submitBtn}>{t("Update")}</Button>

                </Stack>
            </Box>
        </Box >
    )
}

export default withNamespaces("amenityBooking")(CancelBooking)