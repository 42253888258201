import { Box, Grid, Stack, Typography } from "@mui/material";
import { Label, TextBox } from "../index";

import { CompanyToggle } from "../../components";
export const TextWithToogle = ({
    onChange = () => onChange,
    value = "",
    isError = false,
    errorMessage = "",
    options = [],
    isReadonlySelect = false,
    isReadonly = false,
    custom = false,
    openCustom = false,
    isrequired = false,
    is_toogle = false,
    label = "Duration"

}) => {

    const onChangeValue = (key, val) => {
        let updateValue = {
            ...value,
            [key]: val
        }
        onChange && onChange(updateValue)
    }

    return (
        <Grid container spacing={1} >

            <Grid item xs={12}>
                {label && <Label label={label} isRequired={isrequired} />}

                <Stack spacing={1} direction="row" alignItems="center">
                    <Box width="60px">
                        <TextBox
                            isrequired={isrequired}
                            label=""

                            placeholder=""
                            isReadonly={isReadonly}
                            onChange={(value) => onChangeValue("value", value.target.value)}
                            value={value?.value ?? ""}
                            padding="13px 12px"


                        />
                    </Box>
                    {
                        is_toogle && <CompanyToggle
                            onChange={(value) => onChangeValue("select", value)}
                            value={value?.select}
                            options={options}
                            disabled={isReadonly}
                            isMulti
                            custom={custom}
                            openCustom={openCustom}
                            height="45px"

                        />
                    }

                </Stack>

            </Grid>


            {
                isError && (
                    <Grid item xs={12}>
                        <Typography variant={"caption"} color={"error"}>
                            {errorMessage}
                        </Typography>
                    </Grid>

                )
            }


        </Grid >
    )
}