export const PropertyManagementCore = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98429">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 94904"
                    d="M9.047 11.122a.426.426 0 0 0-.138-.015.472.472 0 0 0-.413.7.548.548 0 0 0 .291.23.483.483 0 0 0 .6-.321.468.468 0 0 0-.046-.352.51.51 0 0 0-.294-.242Zm0 0"
                />
                <path
                    data-name="Path 94905"
                    d="M11.633 13.357a2.384 2.384 0 0 0 1.209-.322.312.312 0 1 1 .321.536 2.983 2.983 0 0 1-1.531.413 1.67 1.67 0 0 1-.275-.015"
                />
                <path
                    data-name="Path 94906"
                    d="M10.18 12.867a2.4 2.4 0 0 0 1.393.49 2.982 2.982 0 0 0-2.128-3.52c-.153-.046-.306-.061-.444-.092l.505.214a2.49 2.49 0 0 0-.2.6 1.107 1.107 0 0 1 .612.505 1.119 1.119 0 0 1 .107.826 1.1 1.1 0 0 1-1.071.8 1.142 1.142 0 0 1-.306-.046 1.1 1.1 0 0 1-.673-.52 1.119 1.119 0 0 1-.107-.826 1.088 1.088 0 0 1 .8-.765 2.992 2.992 0 0 1 .245-.8 2.966 2.966 0 0 0-1.76.367 3.03 3.03 0 0 0-1.443 1.806 2.916 2.916 0 0 0 .949 3.138.155.155 0 0 1 .046.153l-.612 2.342a.226.226 0 0 0 .015.107l.352.8a.167.167 0 0 1-.061.2l-.582.383a.167.167 0 0 0-.061.2l.291.612a.15.15 0 0 1-.061.2l-.566.367a.167.167 0 0 0-.061.2l.291.612a.161.161 0 0 1-.061.2l-.505.275a.155.155 0 0 0-.061.214l.566 1.026a.226.226 0 0 0 .092.077h.045a.114.114 0 0 0 .075-.028l1.179-.689a.157.157 0 0 0 .077-.092l1.821-6.26a.13.13 0 0 1 .123-.107 3.155 3.155 0 0 0 2.327-1.852 3 3 0 0 1-1.576-.612.317.317 0 0 1-.061-.444.381.381 0 0 1 .49-.061Zm0 0"
                />
                <path
                    data-name="Path 94907"
                    d="M8.894 9.714a3.04 3.04 0 0 1 5.694.627.314.314 0 0 1-.245.367.338.338 0 0 1-.383-.245 2.41 2.41 0 0 0-4.5-.52"
                />
                <path
                    data-name="Path 94908"
                    d="m23.909 16.173-5.372-5.311a1.4 1.4 0 0 0-.857-.413l-3.475-.322h-.107a1.288 1.288 0 0 0-.949.413 1.329 1.329 0 0 0-.413 1.026v.031l.306 3.444a1.283 1.283 0 0 0 .413.842l5.372 5.311a.333.333 0 0 0 .459 0l4.638-4.577a.33.33 0 0 0-.015-.444Zm-7.24-.367a.337.337 0 0 1-.23-.092.314.314 0 0 1 0-.444l1.531-1.515a.314.314 0 0 1 .444.444l-1.531 1.515a.3.3 0 0 1-.212.092Zm3.536.153-1.534 1.515a.32.32 0 0 1-.459-.444l1.531-1.515a.314.314 0 0 1 .444 0 .3.3 0 0 1 .018.444Zm0 0"
                />
            </g>
            <path data-name="Rectangle 52130" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
