import { Avatar, Box, Divider, IconButton, Stack, Typography } from "@mui/material"
import moment from "moment"
import React from "react"
import CloseIcon from "../../assets/closeIcon"
import { InspectionHubDataType, InspectionHubHeading, InspectionHubPath, JobType } from "../../utils"
import { AlertDialog } from "../dialog"
import { TableWithInfinityScroll } from "../tableWithInfinity"
import { useStyles } from "./style"

export const InspectionInfo = ({ data = {}, onClose = () => false, viewRequest = () => false }) => {
    const classes = useStyles()
    const [moreData, setMoreData] = React.useState(false)
    //handle table data
    const handleIcon = (type, data) => {
        viewRequest(data)
        setMoreData(false)
        onClose()
    }
    //showMoreData
    const showMoreData = () => {
        setMoreData(true)
    }
    return (
        <Box>
            {!moreData ?
                <Box className={classes.inspectionInfoCard}>
                    <Box display={"flex"} justifyContent={"space-between"} p={2}>
                        <Stack direction="row" spacing={1}>
                            <Avatar src={data?.inspections?.[0]?.image_url} />
                            <Box>
                                <Typography className={classes.resourceName} noWrap>{data?.inspections?.[0]?.first_name}</Typography>
                                <Typography className={classes.roleName} noWrap>{data?.inspections?.[0]?.job_name}</Typography>
                            </Box>
                        </Stack>
                        <IconButton
                            size="small"
                            onClick={onClose}
                            style={{
                                marginTop: "-14px",
                                marginRight: "-10px",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider />
                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"} style={{ padding: "8px 16px", cursor: "pointer" }} onClick={() => data?.inspections?.length > 1 ? showMoreData() : viewRequest(data?.inspections?.[0], onClose)}>
                        <Box>
                            <Typography className={classes.requestName}>{data?.inspections?.[0]?.maintance_subject}</Typography>
                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                <Typography className={classes.roleName}>{JobType(data?.inspections?.[0]?.request_type)}</Typography>
                                <Box className={classes.seperateDot}></Box>
                                <Typography className={classes.roleName}>{moment(data.inspections?.[0]?.execution).format("DD MMM YY")}</Typography>
                                <Box className={classes.seperateDot}></Box>
                                <Typography className={classes.roleName}>{data?.inspections?.[0]?.maintenance_request_no}</Typography>
                            </Stack>
                        </Box>
                        {data?.inspections?.length > 1 &&
                            <Box className={classes.moreData}>
                                <Typography className={classes.moreDataTxt}>+{Number(data?.inspections?.length - 1)}</Typography>
                            </Box>
                        }
                    </Box>
                    <Box style={{ padding: "0px 16px" }} mb={1}>
                        <Stack direction="row" alignItems="center">
                            <img src="/images/locs.svg" alt="" />
                            <Typography className={classes.address}>
                                {data?.address?.country},{data?.address?.district}
                            </Typography>
                        </Stack>
                    </Box>

                </Box>
                :
                <AlertDialog open={moreData}
                    onClose={() => { return (setMoreData(false), onClose()) }}
                    header={"Inspection Request"}
                    component={
                        <Box p={2}>
                            <TableWithInfinityScroll
                                heading={InspectionHubHeading}
                                rows={data?.inspections?.map((val) => {
                                    return {
                                        ...val,
                                        inspection_id: val.reference_id,
                                        type: JobType(val.request_type),
                                        unit_name: val?.unit_name,
                                        request_no: val?.maintenance_request_no ?? val?.general_request_no,
                                        resource_name: val?.first_name,
                                        assigned_on: val?.execution,
                                        agreement_inspection_status: val?.status
                                    }
                                })}
                                path={InspectionHubPath}
                                dataType={InspectionHubDataType}
                                showpagination={true}
                                count="2"
                                showpdfbtn={false}
                                showexcelbtn={false}
                                showSearch={false}
                                tableType="no-side"
                                handleIcon={handleIcon}
                                height={`calc(100vh - 300px)`}
                            />
                        </Box>
                    }

                />
            }
        </Box>
    )
}