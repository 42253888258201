export const Path = ["file_name", "uploaded_date", "file_format", "file_size", "status"]

export const Heading = (t) => [
    { title: t("File Name"), field: "file_name" },
    { title: t("Uploaded Date"), field: "uploaded_date" },
    { title: t("File Format"), field: "file_format" },
    { title: t("File Size"), field: "file_size" },
    { title: t("Status"), field: "status" },
    { title: "", field: "icon" },
]

export const Type = [
    { type: ["text"], name: "file_name" },
    { type: ["date_time"], name: "uploaded_date" },
    { type: ["file_ext_text"], name: "file_format" },
    { type: ["text"], name: "file_size" },
    { type: ["text"], name: "status" },
    { type: ["import_table_option"], icon: "icon" },
]