import React from "react";
import {
  PieCharts,
  BarChartComponent,
  LineChartComponent,
  BarWithLine,
} from "./charts";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme, props) => ({
  root: {
    height: (props) => props?.height ?? "100%",
  },
}));
export const ChartComponent = (props) => {
  const classes = useStyles(props);
  const switchComponents = (val) => {
    switch (val) {
      case "pie":
        return <PieCharts data={props?.data} />;
      case "bar":
        return (
          <BarChartComponent
            data={props?.data}
            barKey={props?.barKey}
            XAxisKey={props?.XAxisKey}
            yAxisKey={props?.yAxisKey}
            isHorzontal={props?.isHorzontal}
          />
        );
      case "line":
        return <LineChartComponent data={props?.data} />;
      case "barline":
        return (
          <BarWithLine
            data={props?.data}
            barKey={props?.barKey}
            LineKey={props?.LineKey}
            XAxisKey={props?.XAxisKey}
            yAxisKey={props?.yAxisKey}
            Legend={props?.Legend}
          />
        );
      default:
        return null;
    }
  };
  return <div className={classes.root}>{switchComponents(props?.type)}</div>;
};
