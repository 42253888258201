import React from "react";
import AmenitiesBookingContext from "./amenitiesBookingContext";
import { withNavBars } from "../../HOCs";
import AmenitiesBooking from "./amenitiesBooking";
class AmenitiesBookingParent extends React.Component {
    render() {
        return (
            <AmenitiesBookingContext {...this.props} isCommunity={true}>
                <AmenitiesBooking />
            </AmenitiesBookingContext>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AmenitiesBookingParent, props);
