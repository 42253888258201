import React from "react";
import { withNavBars } from "../../../HOCs";
import AccessGatesPropertyView from "./accessGatesPropertyView";

class AccessGatesPropertyViewParent extends React.Component {
    render() { return <AccessGatesPropertyView /> }
}
const props = { boxShadow: false }

export default withNavBars(AccessGatesPropertyViewParent, props);
