export const Area = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 51191"
          fill="#98a0ac"
          stroke="#ced3dd"
          strokeWidth={0.25}
          d="M88.116 326.037h20v20h-20z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Sq ft icvon"
      transform="translate(-88.116 -326.037)"
      clipPath="url(#a)"
    >
      <path
        d="M90.224 329.537a.617.617 0 0 0-.608.622v12.35a1.038 1.038 0 0 0 1.029 1.029h6.584a1.038 1.038 0 0 0 1.029-1.029v-1.65a1.038 1.038 0 0 0-1.029-1.029h-1.44v-1.025a1.038 1.038 0 0 0-1.029-1.029h-1.444v-1.028a1.038 1.038 0 0 0-1.025-1.029H90.85v-5.56a.617.617 0 0 0-.627-.626Zm2.479.009a.617.617 0 1 0 .613.613.617.617 0 0 0-.614-.613Zm2.469 0a.617.617 0 1 0 .617.617.617.617 0 0 0-.618-.617Zm2.469 0a.617.617 0 1 0 .617.617.617.617 0 0 0-.618-.617Zm2.469 0a.617.617 0 1 0 .617.617.617.617 0 0 0-.618-.617Zm2.881 0a1.038 1.038 0 0 0-1.029 1.029v1.029h-1.446a1.038 1.038 0 0 0-1.029 1.029v1.026h-1.44a1.038 1.038 0 0 0-1.029 1.029v1.646a1.038 1.038 0 0 0 1.029 1.029h6.378v5.555a.618.618 0 1 0 1.235 0v-6.17a.617.617 0 0 0-.022-.189 1 1 0 0 0 .027-.223v-5.761a1.038 1.038 0 0 0-1.029-1.029Zm.206 1.234h1.234v5.35h-6.174v-1.234h1.852a.617.617 0 0 0 .617-.617v-1.44h1.852a.617.617 0 0 0 .617-.617Zm-12.347 6.173h1.235v1.44a.617.617 0 0 0 .617.617h1.852v1.44a.617.617 0 0 0 .617.617h1.852v1.234H90.85Zm9.259 5.349a.617.617 0 1 0 .617.617.617.617 0 0 0-.617-.616Zm2.469 0a.617.617 0 1 0 .617.617.617.617 0 0 0-.617-.616Z"
        fill="#ced3dd"
        stroke="#ced3dd"
        strokeWidth={0.2}
        data-name="SQ FT"
      />
    </g>
  </svg>
)