export const LocationAndAssetView = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105117">
            <g
                data-name="money management"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 97909"
                    d="M15.258 15.258a4.129 4.129 0 1 1 4.129-4.129 4.129 4.129 0 0 1-4.129 4.129Zm0-7.226a3.1 3.1 0 1 0 3.1 3.1 3.1 3.1 0 0 0-3.1-3.1Z"
                />
                <path
                    data-name="Path 97910"
                    d="M9.994 23H8.135A1.137 1.137 0 0 1 7 21.865v-1.859a1.137 1.137 0 0 1 1.135-1.135h1.859a1.137 1.137 0 0 1 1.135 1.135v1.858a1.137 1.137 0 0 1-1.135 1.135Zm-1.858-3.1a.1.1 0 0 0-.1.1v1.858a.1.1 0 0 0 .1.1h1.858a.1.1 0 0 0 .1-.1v-1.852a.1.1 0 0 0-.1-.1Z"
                />
                <path
                    data-name="Path 97911"
                    d="M16.188 23h-1.859a1.137 1.137 0 0 1-1.135-1.135v-1.859a1.137 1.137 0 0 1 1.135-1.135h1.858a1.137 1.137 0 0 1 1.135 1.135v1.858A1.137 1.137 0 0 1 16.188 23Zm-1.858-3.1a.1.1 0 0 0-.1.1v1.858a.1.1 0 0 0 .1.1h1.858a.1.1 0 0 0 .1-.1v-1.852a.1.1 0 0 0-.1-.1Z"
                />
                <path
                    data-name="Path 97912"
                    d="M22.381 23h-1.859a1.137 1.137 0 0 1-1.135-1.135v-1.859a1.137 1.137 0 0 1 1.135-1.135h1.858a1.137 1.137 0 0 1 1.135 1.135v1.858A1.137 1.137 0 0 1 22.381 23Zm-1.858-3.1a.1.1 0 0 0-.1.1v1.858a.1.1 0 0 0 .1.1h1.858a.1.1 0 0 0 .1-.1v-1.852a.1.1 0 0 0-.1-.1Z"
                />
                <path
                    data-name="Path 97913"
                    d="M15.258 19.903a.516.516 0 0 1-.516-.516v-4.645a.516.516 0 0 1 1.032 0v4.645a.516.516 0 0 1-.516.516Z"
                />
                <path
                    data-name="Path 97914"
                    d="M9.064 19.903a.516.516 0 0 1-.516-.513v-2.584a.516.516 0 0 1 .516-.516h6.194a.516.516 0 1 1 0 1.032H9.58v2.068a.516.516 0 0 1-.516.513Z"
                />
                <path
                    data-name="Path 97915"
                    d="M21.452 19.903a.516.516 0 0 1-.516-.516v-2.065h-5.678a.516.516 0 0 1 0-1.032h6.194a.516.516 0 0 1 .516.516v2.584a.516.516 0 0 1-.516.513Z"
                />
                <path
                    data-name="Path 97916"
                    d="M15.516 13.194h-1.29a.516.516 0 0 1 0-1.032h1.29a.258.258 0 0 0 0-.516H15a1.29 1.29 0 0 1 0-2.581h1.29a.516.516 0 1 1 0 1.032H15a.258.258 0 0 0 0 .516h.516a1.29 1.29 0 1 1 0 2.581Z"
                />
                <path
                    data-name="Path 97917"
                    d="M15.258 10.096a.516.516 0 0 1-.516-.516v-.516a.516.516 0 0 1 1.032 0v.516a.516.516 0 0 1-.516.516Z"
                />
                <path
                    data-name="Path 97918"
                    d="M15.258 13.709a.516.516 0 0 1-.516-.516v-.516a.516.516 0 1 1 1.032 0v.516a.516.516 0 0 1-.516.516Z"
                />
            </g>
        </g>
    </svg>
)