import React from "react";
import { withNavBars } from "../../HOCs";
import UnitItemLocationMaster from "./unitItemLocationMaster";

class UnitItemLocationMasterParent extends React.Component {
  render() {
    return <UnitItemLocationMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(UnitItemLocationMasterParent, props);
