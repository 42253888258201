import { BackendRoutes } from "../../../router/routes";
import { timeZoneConverter } from "../../../utils";
import { tab_data } from "./constant";

export const constructResponseGetData = (_, previous_state, selected_main_tab , t) => {
    let data = {
        ...previous_state,
        [selected_main_tab?.value]: {
            row: [],
            row_count: 0
        }
    }

    let temp_row = []

    switch (selected_main_tab?.value) {
        case tab_data(t).manual_backup.value:
            temp_row = _?.backup_list?.map(i => {
                return constructManualBackupMapData(i)
            })
            break;
        case tab_data(t).system_backup.value:
            temp_row = _?.backup_list?.map(i => {
                return constructSystemBackupMapData(i)
            })
            break;
        default:
            break;
    }

    data = {
        ...data,
        [selected_main_tab?.value]: {
            row: temp_row ?? [],
            row_count: _?.count ?? 0
        }
    }

    return data
}

export const constructManualBackupMapData = (_) => {
    return {
        title: _?.title ?? "-",
        created_on: timeZoneConverter(_?.created_at, "DD MMM YY") ?? "-",
        created_by: _?.users?.username ?? "-",
        scheduled_date: _?.scheduled_date ?? "-",
        status: _?.status ?? "-",
        restore_date: _?.client_restore?.length > 0
            ? timeZoneConverter(_?.client_restore?.[0]?.created_at, "DD MMM YY")
            : "-",
        restore_by: _?.client_restore?.length > 0
            ? (_?.client_restore?.[0]?.users?.username ?? "-")
            : "-",
        data: _
    }
}

export const constructSystemBackupMapData = (_, t) => {
    return {
        backup_title: _?.title ?? "-",
        created_on: timeZoneConverter(_?.created_at, "DD MMM YY") ?? "-",
        status: _?.status ?? "-",
        restore_date: _?.client_restore?.length > 0
            ? timeZoneConverter(_?.client_restore?.[0]?.created_at, "DD MMM YY")
            : "-",
        restore_by: _?.client_restore?.length > 0
            ? (_?.client_restore?.[0]?.users?.username ?? "-")
            : "-",
        data: _
    }
}

export const constructAdditionalPayload = (selected_main_tab, payload , t) => {
    let additional_payload = { ...payload }

    switch (selected_main_tab?.value) {
        case tab_data(t).manual_backup.value:
            additional_payload["is_system"] = false
            break;
        case tab_data(t).system_backup.value:
            additional_payload["is_system"] = true
            break;
        default:
            break;
    }

    return additional_payload
}

export const getApiEndpoint = (selected_main_tab , t=()=>false) => {
    let api_endpoint = ""

    switch (selected_main_tab?.value) {
        case tab_data(t).manual_backup.value:
            api_endpoint = BackendRoutes.listing_property_listing
            break;
        case tab_data(t).system_backup.value:
            api_endpoint = BackendRoutes.listing_unit_listing
            break;
        default:
            break;
    }

    return api_endpoint
}