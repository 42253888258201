import React from "react";
import { withNavBars } from "../../HOCs";
import CreateAnnounsmentNew from "./createAnnounsment";
class AnnounsmentParent extends React.Component {
  render() {
    return <CreateAnnounsmentNew />;
  }
}
const props = {
  boxShadow: false,
};
export default withNavBars(AnnounsmentParent, props);
