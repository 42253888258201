import * as React from "react"
const AgreementInformationIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 111933" transform="translate(-112 -125)">
      <circle
        cx={16}
        cy={16}
        r={16}
        fill="#78b1fe"
        data-name="Ellipse 129949"
        transform="translate(112 125)"
      />
      <path
        fill="#fff"
        d="M123.2 133a2.4 2.4 0 0 0-2.4 2.4v1a.6.6 0 0 0 .6.6h1.6a2.591 2.591 0 0 1 1.872.8h7.328a.6.6 0 1 1 0 1.2h-6.673a2.58 2.58 0 0 1 .073.6v.6h2.6a.6.6 0 1 1 0 1.2h-2.6v4.6a2.208 2.208 0 0 1-.269 1.053L124.268 149h9.132a1.8 1.8 0 0 0 1.8-1.8v-11.8a2.4 2.4 0 0 0-2.4-2.4Zm0 1.2h7.523a2.382 2.382 0 0 0-.323 1.2v.4H122v-.4a1.2 1.2 0 0 1 1.2-1.2Zm-.2 3.6a1.8 1.8 0 0 0-1.8 1.8v6.4a1.4 1.4 0 0 0 .171.67l1.1 2.021a.6.6 0 0 0 1.053 0l1.1-2.022a1.4 1.4 0 0 0 .176-.669v-6.4a1.8 1.8 0 0 0-1.8-1.8Zm8.6 4a1.6 1.6 0 1 1-1.6 1.6 1.591 1.591 0 0 1 1.6-1.6Zm-1.6 3.892a2.775 2.775 0 0 0 3.2 0v1.508a.6.6 0 0 1-.9.521l-.7-.4-.7.4a.6.6 0 0 1-.9-.521Z"
        data-name="icons8-conclusion-contract (1)"
      />
    </g>
  </svg>
)
export default AgreementInformationIcon
