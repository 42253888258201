export const Path = ["propertyName", "name", "department", "contactNumber", "status"]

export const Heading = (t) => [
    { title: t("Property Name"), field: "propertyName" },
    { title: t("Name"), field: "name" },
    { title: t("Department"), field: "department" },
    { title: t("Contact Number"), field: "contactNumber" },
    { title: t("Status"), field: "status", },
    { title: "", field: "icon" },
]

export const Type = [
    { type: ["text"], name: "propertyName" },
    { type: ["text"], name: "name" },
    { type: ["text"], name: "department" },
    { type: ["text"], name: "contactNumber" },
    { type: ["status"], name: "status" },
    { type: ["more"], icon: "icon" },
]

export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]