export const Path = ["correspondences_no", "subject", "to", "correspondence_type", "created_on", "status"]

export const Heading = (t) => [
    { title: "", field: "icon" },
    { title: t("Letter No"), field: "correspondences_no" },
    { title: t("Subject"), field: "subject" },
    { title: t("To"), field: "to" },
    { title: t("Correspondence Type"), field: "correspondence_type" },
    { title: t("Created on"), field: "created_on" },
    { title: t("Status"), field: "status" },
]

export const Type = [
    { type: ["correspondence_option"], icon: "icon" },
    { type: ["text"], name: "correspondences_no" },
    { type: ["long_text"], name: "subject" },
    { type: ["long_text"], name: "to" },
    { type: ["text"], name: "correspondence_type" },
    { type: ["text"], name: "created_on" },
    { type: ["object_status_color"], name: "status" },
]