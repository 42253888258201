export const Resources = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105173">
            <path
                data-name="LS - Resource Management - Resources"
                d="m27.416 17.967-.669-.086a.572.572 0 0 0-.486.172 3.715 3.715 0 0 0-.878 1.521.572.572 0 0 0 .094.507l.409.537-.409.537a.571.571 0 0 0-.094.507 3.714 3.714 0 0 0 .878 1.521.571.571 0 0 0 .486.172l.669-.086.261.623a.571.571 0 0 0 .392.335 3.715 3.715 0 0 0 1.757 0 .571.571 0 0 0 .392-.335l.26-.623.669.086a.571.571 0 0 0 .486-.172 3.713 3.713 0 0 0 .878-1.521.571.571 0 0 0-.094-.507l-.409-.537.409-.537a.572.572 0 0 0 .094-.507 3.714 3.714 0 0 0-.878-1.521.572.572 0 0 0-.486-.172l-.669.086-.26-.623a.571.571 0 0 0-.392-.335 3.712 3.712 0 0 0-1.757 0 .571.571 0 0 0-.392.335Zm-2.59 5.223a4.854 4.854 0 0 1-.541-1.207 1.713 1.713 0 0 1 .176-1.365 1.713 1.713 0 0 1-.176-1.365 4.855 4.855 0 0 1 1.149-1.99 1.713 1.713 0 0 1 1.27-.53A1.713 1.713 0 0 1 27.8 15.9a2.849 2.849 0 0 0-1.884-.708h-3.421a2.86 2.86 0 0 0-2.86 2.86V21.5a1.687 1.687 0 0 0 1.687 1.687Zm4.121-4.572a2 2 0 1 0 2 2 2 2 0 0 0-2-1.999Zm0 1.136a.864.864 0 1 1-.864.864.865.865 0 0 1 .864-.864Zm-4.74-11.421a2.857 2.857 0 1 0 2.857 2.857 2.859 2.859 0 0 0-2.857-2.857Z"
                transform="translate(-10.635 -1.333)"
                fillRule="evenodd"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            />
        </g>
    </svg>
)