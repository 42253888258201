import * as React from "react"
const FacilityListingIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={48}
        height={48}
        viewBox="0 0 48 48"
        {...props}
    >
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_56643"
                    data-name="Rectangle 56643"
                    width={24}
                    height={24}
                    transform="translate(676 21)"
                    fill="#e29336"
                />
            </clipPath>
        </defs>
        <g
            id="Group_110543"
            data-name="Group 110543"
            transform="translate(-152 -317)"
        >
            <rect
                id="Rectangle_56639"
                data-name="Rectangle 56639"
                width={48}
                height={48}
                rx={8}
                transform="translate(152 317)"
                fill="#fff5e8"
            />
            <g
                id="Mask_Group_93121"
                data-name="Mask Group 93121"
                transform="translate(-512 308)"
                clipPath="url(#clip-path)"
            >
                <g id="icons8-interior" transform="translate(678.25 23.25)">
                    <path
                        id="Path_100183"
                        data-name="Path 100183"
                        d="M21.211,23.259H6.044a2.166,2.166,0,0,1-2.167-2.167V10a3.249,3.249,0,0,1,1.672-2.84l6.5-3.611a3.252,3.252,0,0,1,3.157,0l6.5,3.611A3.25,3.25,0,0,1,23.378,10V21.092A2.166,2.166,0,0,1,21.211,23.259Z"
                        transform="translate(-3.878 -3.145)"
                        fill="#e29336"
                        opacity={0.35}
                    />
                    <circle
                        id="Ellipse_129917"
                        data-name="Ellipse 129917"
                        cx={1.625}
                        cy={1.625}
                        r={1.625}
                        transform="translate(2.167 9.281)"
                        fill="#e29336"
                    />
                    <circle
                        id="Ellipse_129918"
                        data-name="Ellipse 129918"
                        cx={1.625}
                        cy={1.625}
                        r={1.625}
                        transform="translate(14.083 9.281)"
                        fill="#e29336"
                    />
                    <path
                        id="Path_100184"
                        data-name="Path 100184"
                        d="M11.424,24.141h0a1.084,1.084,0,0,1-1.083-1.083V21.974h2.167v1.083A1.084,1.084,0,0,1,11.424,24.141Z"
                        transform="translate(-4.924 -6.193)"
                        fill="#e29336"
                    />
                    <path
                        id="Path_100185"
                        data-name="Path 100185"
                        d="M19.18,24.141h0A1.084,1.084,0,0,1,18.1,23.057V21.974h2.167v1.083A1.084,1.084,0,0,1,19.18,24.141Z"
                        transform="translate(-6.18 -6.193)"
                        fill="#e29336"
                    />
                    <path
                        id="Path_100186"
                        data-name="Path 100186"
                        d="M18.589,16.162v1.516H9.922V16.053l-2.167-.542v4.333a2.166,2.166,0,0,0,2.167,2.167h8.667a2.166,2.166,0,0,0,2.167-2.167V15.511Z"
                        transform="translate(-4.506 -5.147)"
                        fill="#e29336"
                    />
                    <path
                        id="Path_100187"
                        data-name="Path 100187"
                        d="M11.215,15.007a2.791,2.791,0,0,1-.054.542H17.77a2.706,2.706,0,0,1,2.111-3.2V11.215a2.166,2.166,0,0,0-2.167-2.167h-6.5a2.166,2.166,0,0,0-2.167,2.167v1.137A2.71,2.71,0,0,1,11.215,15.007Z"
                        transform="translate(-4.715 -4.1)"
                        fill="#e29336"
                    />
                </g>
            </g>
        </g>
    </svg>

)
export default FacilityListingIcon
