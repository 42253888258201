import { KeyboardArrowRight } from "@mui/icons-material"
import { Avatar, Box, Button, Divider, Grid, Menu, MenuItem, Stack, Typography } from "@mui/material"
import moment from "moment"
import React, { useCallback, useState } from "react"
import ImageViewer from "react-simple-image-viewer"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { withNavBars } from "../../../HOCs"
import NoDataFound from "../../../assets/noData"
import { AgreementIcon } from "../../../assets/propertyBoard/agreementIcon"
import { ApartmentIcon } from "../../../assets/propertyBoard/apartmentIcon"
import { AreaIcon } from "../../../assets/propertyBoard/areaIcon"
import { BlockIcon } from "../../../assets/propertyBoard/blockIcon"
import { CalendarIcon } from "../../../assets/propertyBoard/calendarIcon"
import { CircleIcon } from "../../../assets/propertyBoard/circleIcon"
import { CommunityIcon } from "../../../assets/propertyBoard/communityIcon"
import { FloorIcon } from "../../../assets/propertyBoard/floorIcon"
import { FurnishIcon } from "../../../assets/propertyBoard/furnishIcon"
import { HistoryIcon } from "../../../assets/propertyBoard/historyIcon"
import { HospitalIcon } from "../../../assets/propertyBoard/hospitalIcon"
import ParkingIcon from "../../../assets/propertyBoard/parkingIcon"
import { ParkIcon } from "../../../assets/propertyBoard/parksIcon"
import { PurposeIcon } from "../../../assets/propertyBoard/purposeIcon"
import { RestaurantIcon } from "../../../assets/propertyBoard/restaurantIcon"
import { RevenueIcon } from "../../../assets/propertyBoard/revenueIcon"
import { SchoolIcons } from "../../../assets/propertyBoard/schoolIcon"
import { ShareIcon } from "../../../assets/propertyBoard/shareIcon"
import { TransportationIcon } from "../../../assets/propertyBoard/transportationIcon"
import { WIFIIcon } from "../../../assets/propertyBoard/wifiIcon"
import { AlertDialog, Subheader } from "../../../components"

import { config } from "../../../config"
import { NetworkCall } from "../../../networkcall"
import { NetWorkCallMethods, assestType, locationdata } from "../../../utils"
import { BathIcon, BedIcon } from "../../propertyFinder4/components"
import { AssetView } from "../../requestView/components"
import { getColor } from "./card"
import { blockOptions } from "./constants"
import { NearBy } from "./nearBy"
import { Stepper } from "./stepper"
import { useStyles } from "./style"
import { ReservationIcon } from "../../../assets/propertyBoard/reservationIcon"
import Camera from "../../../assets/camera"
import AirConditioner from "../../../assets/airConditioner"
import Player from "../../../assets/player"

const ViewPropertyDetails = ({ t, data = {}, closeModal = () => false, openDialog = () => false, TabData = [], getPropertyDetails = () => false, changeExecutionStatus = () => false }) => {
    const classes = useStyles()
    const [selectedTab, setSelectedTab] = React.useState(1)
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [loc, setLoc] = React.useState({
        type: "",
        list: "",
        bool: false
    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [executionStatus, setExecutionStatus] = React.useState("")
    // const executionStatusOptions = [
    //     {
    //         label: "Plan",
    //         value: "Plan"
    //     },
    //     {
    //         label: "Develop",
    //         value: "Develop"
    //     },
    //     {
    //         label: "Release",
    //         value: "Release"
    //     },
    //     {
    //         label: "Block",
    //         value: "Block"
    //     },
    // ]//{Plan,Block,Release,Develop}
    const getIconColor = (val) => {
        switch (val) {
            case "Restaurants":
                return { color: "#FFECEC", icon: <RestaurantIcon /> }
            case "Transportations":
                return { color: "#0000000F", icon: <TransportationIcon /> }
            case "Schools":
                return { color: "#F1F7FF", icon: <SchoolIcons /> }
            case "Hospitals":
                return { color: "#FFF4EB", icon: <HospitalIcon /> }
            case "Release":
                return { color: "#5078E1" }
            case "Plan":
                return { color: "#FFA024" }
            case "Develop":
                return { color: "#5AC782" }
            case "Block":
                return { color: "#FFA024" }

            default:
                return { color: "#EEF9EE", icon: <ParkIcon /> }
        }
    }
    // open image viwer
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
        //eslint-disable-next-line
    }, []);
    // close image viwer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const onCloseLoc = (field, value) => {
        setLoc({
            ...loc, [field]: value
        })
    }

    const onLoc = (type, det) => {

        const payload = {
            lat: data?.prop_lat,
            long: data?.prop_long,
            type: type,
            radius: 500
        };
        NetworkCall(
            `${config.api_url}/property_board/near_by`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false).then((response) => {

                setLoc({
                    type: det,
                    bool: true,
                    list: response?.data?.data?.results
                })
            })
            .catch(() => {

            });


    }


    React.useEffect(() => {
        setExecutionStatus(data?.execution_status)
    }, [data])

    //Block unit selection
    const onBlockUnit = (val) => {
        if (val === "Opportunity") {
            openDialog("block")
        }
        setAnchorEl(null)

    }

    return (
        <>
            <Subheader
                title={data?.unit_name}
                goBack={closeModal}
            />
            <Box p={2}>
                <Box className={classes.root}>

                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box>
                            <Grid container alignItems={"center"} className={classes.viewMoreTabs}>
                                {TabData?.map((x) => {
                                    return (
                                        <Grid item xs={`${12 / Number(TabData?.length)}`} className={selectedTab === x.value ? classes.selectedTab : classes.tabText}
                                            onClick={() => {
                                                x.value === 2 && getPropertyDetails()
                                                setSelectedTab(x.value)
                                            }}>
                                            {x.label}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>

                        {
                            data?.execution_status !== "Block" &&
                            <Stack direction="row" alignItems="center">
                                <Box style={{ marginInlineEnd: "8px" }} className={classes.sharebox} onClick={() => openDialog("share")}><ShareIcon /></Box>
                                {data?.status !== "Occupied" &&
                                    <Button variant="outlined" fullWidth onClick={handleClick} className={classes.closebtn}>{t("Block Unit")}</Button>
                                }
                                {/* <Button variant="contained" fullWidth onClick={() => openDialog("reserve")}>Reserve Unit</Button> */}
                            </Stack>
                        }
                    </Box >
                    <Box mt={2} height={580} overflow={"scroll"}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6.5} p={2}>
                                {selectedTab === 2 ?
                                    <AssetView
                                        asset={data?.property_assets
                                            ?.filter((x) => x?.asset_type !== assestType?.Videos)
                                            ?.filter((x) => x?.asset_type !== assestType?.Documents)
                                            ?.map((x) => x)}
                                        assetAll={data?.property_assets}
                                        openImageViewer={openImageViewer}
                                        isNormal
                                        maxWidth={"sm"}
                                        isPropertyBoard={true}
                                    />
                                    :

                                    <AssetView
                                        asset={data?.assets
                                            ?.filter((x) => x?.asset_type !== assestType?.Videos)
                                            ?.filter((x) => x?.asset_type !== assestType?.Documents)
                                            ?.map((x) => x)}
                                        assetAll={data?.assets}
                                        openImageViewer={openImageViewer}
                                        isNormal
                                        maxWidth={"sm"}
                                        isPropertyBoard={true}
                                    />
                                }
                                <Box mt={1} display="flex" justifyContent={"space-between"} alignItems="center">
                                    {selectedTab === 1 ?
                                        <Stack direction={"row"} spacing={1} alignItems={"center"} divider={<Divider orientation="vertical" flexItem />}>
                                            <Typography className={classes.amounttxt}>{data?.unit_name}</Typography>
                                            <Typography className={classes.amounttxt}>{data?.data?.unit_type}</Typography>
                                        </Stack>
                                        : <Typography className={classes.amounttxt}>{data?.property_name}</Typography>
                                    }

                                    {selectedTab === 1 ?
                                        <Typography className={classes.amounttxt}>{`${data?.amount ?? 0} ${data?.currency_symbol} / ${data?.payment_period === "Monthly" ? "Month" : data?.payment_period}`}</Typography>
                                        :
                                        <Box className={classes.breakupBox}><Typography className={classes.brkuptxt}>{`${data?.total_unit} ${t("units available")}`}</Typography></Box>
                                    }
                                </Box>
                                <Box mt={1} display="flex" justifyContent={selectedTab === 1 ? "end" : "space-between"} alignItems="center">
                                    {selectedTab === 2 &&
                                        <Typography className={classes.unitDetails}>{selectedTab === 1 ? data?.unit_no : data?.property_no}</Typography>
                                    }
                                    {selectedTab === 1 &&
                                        <Typography className={classes.subtext} style={{ color: getColor(data?.execution_status === "Block" ? "Block" : data?.vacany_status)?.color, backgroundColor: getColor(data?.execution_status === "Block" ? "Block" : data?.vacany_status)?.background }}>{data?.execution_status === "Block" ? `${t("Blocked")}` : `${data?.vacany_status}`}</Typography>
                                    }
                                </Box>
                                {selectedTab === 1 &&
                                    <>
                                        <>
                                            <Stack mt={1} direction="row" spacing={1} alignItems={"center"}>

                                                {data?.bed &&
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <BedIcon />
                                                        <Typography className={classes.floorTxt} noWrap>{`${data?.bed} ${t("Bedrooms")}`}</Typography>


                                                    </Stack>
                                                }
                                                {data?.bed &&
                                                    <Box className={classes.dotunit}></Box>
                                                }
                                                {data?.bathrooms &&
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <BathIcon />
                                                        <Typography className={classes.floorTxt} noWrap>{`${data?.bathrooms} Baths`}</Typography>


                                                    </Stack>
                                                }
                                                {data?.bathrooms &&
                                                    <Box className={classes.dotunit}></Box>
                                                }
                                                {data?.furnishing &&
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <FurnishIcon />
                                                        <Typography className={classes.floorTxt} noWrap>{data?.furnishing}</Typography>
                                                    </Stack>
                                                }
                                                {data?.furnishing &&
                                                    <Box className={classes.dotunit}></Box>
                                                }
                                                {data?.area &&
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <AreaIcon />
                                                        <Typography className={classes.floorTxt} noWrap>{data?.area}</Typography>
                                                    </Stack>
                                                }
                                            </Stack>
                                        </>
                                        <Box mt={2} className={classes.amenitiesBox}>
                                            <Grid container spacing={2} alignItems={"center"}>
                                                {data?.building_type &&
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <ApartmentIcon />
                                                            <Typography className={classes.amenitiesText} noWrap>{data?.building_type}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                }
                                                {data?.community &&
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <CommunityIcon />
                                                            <Typography className={classes.amenitiesText} noWrap>{data?.community}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                }
                                                {data?.property_type &&
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <RevenueIcon />
                                                            <Typography className={classes.amenitiesText} noWrap>{data?.property_type}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                }
                                                {data?.orient &&
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <FloorIcon />
                                                            <Typography className={classes.amenitiesText} noWrap>{data?.orient}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                }
                                                {data?.revenue_type &&
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <PurposeIcon />
                                                            <Typography className={classes.amenitiesText} noWrap>{data?.revenue_type}</Typography>
                                                        </Stack>
                                                    </Grid>
                                                }
                                                {data?.built_year &&
                                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <CalendarIcon />
                                                            <Typography className={classes.amenitiesText} noWrap>&#x202a;{`${data?.built_year} ${t("build")}`}&#x202c;</Typography>
                                                        </Stack>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                    </>
                                }

                                <Box mt={2} mb={2}>
                                    <Divider />
                                </Box>

                                <Box mt={1} alignItems={"center"}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <img src="/images/mapIcon.svg" width="24px" alt="mapIcon" />
                                        <Box width={475}>
                                            <Typography className={classes.floorTxt}>{data?.address}</Typography>
                                        </Box>

                                    </Stack>
                                </Box>

                                {/* <Box mt={2} mb={2}>
                                    <Divider />
                                </Box> */}

                            </Grid >
                            <Grid item xs={12} md={6} lg={5.5} className={classes?.applyBorderLeft}>

                                {
                                    selectedTab === 1 &&
                                    data?.amenities?.length > 0 &&
                                    <>
                                        <Box mt={1}>
                                            <Typography className={classes.amenitiesTitleText}>{t("AMENITIES")}</Typography>
                                            <Grid container spacing={1}>
                                                {data?.amenities?.map((x) => {
                                                    // console.log(x?.facility_type?.amenities_name)
                                                    return (
                                                        <Grid item xs={12} sm={3} md={4} lg={3} alignItems="center">
                                                            <Stack mt={1} className={classes.amenitiesDataBox} direction={"row"} spacing={1} alignItems="center">
                                                                <Box display={"flex"} alignItems={"center"}>{x?.facility_type?.amenities_name === "Free WiFi" ? <WIFIIcon /> : x?.facility_type?.amenities_name === "Car Parking" ? <ParkingIcon /> : x?.facility_type?.amenities_name === "24 hrs CCTV" ? <Camera /> : x?.facility_type?.amenities_name === "Air Conditioner" ? <AirConditioner /> : <Player />}</Box>
                                                                <Typography className={classes.amenityTxt} noWrap>{x?.facility_type?.amenities_name}</Typography>

                                                            </Stack>
                                                        </Grid>

                                                    )
                                                })}
                                            </Grid>
                                        </Box >
                                        <Box mt={2} mb={2}>
                                            <Divider />
                                        </Box>
                                    </>
                                }
                                {
                                    selectedTab === 2 &&
                                    data?.property_amenities?.length > 0 &&
                                    <>
                                        <Box mt={1}>
                                            <Typography className={classes.amenitiesTitleText}>{t("AMENITIES")}</Typography>
                                            <Grid container spacing={1}>
                                                {data?.property_amenities?.map((x) => {
                                                    return (
                                                        <Grid item xs={3} sm={3} md={4} lg={3} alignItems="center">
                                                            <Stack mt={1} className={classes.amenitiesDataBox} direction={"row"} spacing={1} alignItems="center">
                                                                <Box display={"flex"} alignItems={"center"}>{x?.facility_type?.amenities_name === "Free WiFi" ? <WIFIIcon /> : x?.facility_type?.amenities_name === "Car Parking" ? <ParkingIcon /> : x?.facility_type?.amenities_name === "24 hrs CCTV" ? <Camera /> : x?.facility_type?.amenities_name === "Air Conditioner" ? <AirConditioner /> : <Player />}</Box>
                                                                <Typography className={classes.amenityTxt} noWrap>{x?.facility_type?.amenities_name}</Typography>

                                                            </Stack>
                                                        </Grid>

                                                    )
                                                })}
                                            </Grid>
                                        </Box >
                                        <Box mt={2} mb={2}>
                                            <Divider />
                                        </Box>
                                    </>
                                }
                                {
                                    selectedTab === 1 &&
                                    <>
                                        {data?.pricing?.length > 0 &&
                                            <>
                                                <Box mt={1}>
                                                    {/* <Typography className={classes.amenitiesText}>BREAKUP</Typography> */}
                                                    <Box mt={1} className={classes.breakupBox}>
                                                        <Typography className={classes.brkuptxt}>{`${t("Pricing Details For the Period")} ${data?.from} ${t("to")} ${data?.to}`}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box mt={2}>
                                                    {
                                                        data?.pricing?.map((val) => {
                                                            return (

                                                                <Box mt={1} display="flex" justifyContent={"space-between"}>
                                                                    <Typography className={classes.brkupdatatxt}>{val?.rentalBreakup}</Typography>
                                                                    <Stack direction={"row"} sx={{ direction: "ltr" }}>
                                                                        <Typography sx={{ marginInlineEnd: "4px" }} className={classes.brkupdata}>{val?.currencySymbol}</Typography>
                                                                        <Typography className={classes.brkupdata}>{val?.value}</Typography>
                                                                    </Stack>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                                <Box mt={1.5} mb={1}>
                                                    <Divider />
                                                </Box>

                                            </>
                                        }

                                        <Box mt={2} className={classes.executionstatus}>
                                            <Typography className={classes.amounttxt}>{t("Execution Status")}</Typography>
                                            {/* <Box mt={1}>
                                                        <Typography className={classes.floorTxt}>This status update will reflect on this unit listing.Please be cautious while Updates</Typography>
                                                    </Box> */}


                                            {/* <PrioritySelect
                                                value={executionStatus}
                                                options={executionStatusOptions}
                                                onChange={(val) => {
                                                    changeExecutionStatus(val)
                                                    setExecutionStatus(val)
                                                }}
                                                isLabel={false}
                                            /> */}

                                            <Box className={classes.executionStatusBox} alignItems="center">
                                                <Stack direction="row" spacing={0.8} alignItems="center">
                                                    <Box className={classes.executionDot} style={{ backgroundColor: getIconColor(executionStatus)?.color }}></Box>
                                                    <Typography className={classes.executionText} style={{ color: getIconColor(executionStatus)?.color }}>{t(executionStatus)}</Typography>
                                                </Stack>
                                            </Box>

                                        </Box >
                                        {data?.unit_block_request?.unit_id &&
                                            <>

                                                <Box className={classes.blockBox} mt={2} p={2}>
                                                    <Typography className={classes.unitBlock}>{t("Unit Block Details")}</Typography>
                                                    <Box mt={2} display="flex" justifyContent={"space-between"} alignItems="center">
                                                        <Box>
                                                            <Typography className={classes.amounttxt}>{data?.unit_block_request?.prospective_name}</Typography>
                                                            <Stack mt={0.5} direction={"row"} spacing={1} alignItems="center">
                                                                <Typography className={classes.unitblockDetails}>{data?.unit_block_request?.status}</Typography>
                                                                <Box className={classes.dotunit}></Box>
                                                                <Typography className={classes.unitblockDetails}>&#x202a;{data?.unit_block_request?.from} {t("to")} {data?.unit_block_request?.to}&#x202c;</Typography>
                                                            </Stack>
                                                        </Box>
                                                        <BlockIcon />
                                                    </Box>
                                                </Box>
                                            </>

                                        }
                                    </>
                                }
                                {
                                    selectedTab === 2 &&
                                    <>
                                        <Box mt={1}>
                                            <Typography className={classes.amenitiesText}>{t("LOCATION & NEARBY")}</Typography>

                                        </Box >
                                        <Box mt={1}>
                                            {/* <Carousel showArrows={false} breakPoints={breakPoints}> */}
                                            <Grid container spacing={1.5}>
                                                {locationdata?.map((x) => {
                                                    return (
                                                        <Grid item xs={6} sm={6} md={6} lg={4}>
                                                            <Box className={classes.nearbyBox}>

                                                                <Box className={classes.iconBox} display="flex" justifyContent={"center"} style={{ backgroundColor: x?.color }} alignItems="center">
                                                                    {getIconColor(x?.name)?.icon}
                                                                </Box>
                                                                <Box onClick={() => onLoc(x?.type, x)} className={classes.nearybyTitleBox} display="flex" justifyContent={"space-between"} alignItems="center">
                                                                    <Box>
                                                                        <Typography className={classes.amounttxt}>{t(x?.name)}</Typography>
                                                                        <Typography className={classes.floorTxt}>{t("Near this Property")}</Typography>
                                                                    </Box>
                                                                    <KeyboardArrowRight className={classes.arrowIcon} />
                                                                </Box >
                                                            </Box >
                                                        </Grid >

                                                    )
                                                })}
                                            </Grid >
                                            {/* </Carousel> */}
                                        </Box >
                                    </>
                                }
                                {
                                    selectedTab !== 2 && data?.vacany_status === "Occupied" &&

                                    <Box mt={2} className={classes.agreementBox}>
                                        <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                            <AgreementIcon />
                                            <Typography className={classes.amenitiesText}>{t("Current Agreement Details")}</Typography>
                                        </Stack>
                                        {
                                            data?.over_lapping_agreement?.length > 0 ?

                                                <Box mt={2}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>{t("Agreement ID")}</Typography>
                                                            <Typography className={classes.agreementamounttxt}>{data?.over_lapping_agreement?.[0]?.agreement_no}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>{t("From")}</Typography>
                                                            <Typography className={classes.agreementamounttxt}>&#x202a;{moment(data?.over_lapping_agreement?.[0]?.lease_start_date).format("DD MMM YYYY")}&#x202c;</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>{t("To")}</Typography>
                                                            <Typography className={classes.agreementamounttxt}>&#x202a;{moment(data?.over_lapping_agreement?.[0]?.lease_end_date).format("DD MMM YYYY")}&#x202c;</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>{t("Revenue")}</Typography>
                                                            <Typography className={classes.agreementamounttxt}>{`${data?.over_lapping_agreement?.[0]?.total_amount ?? "0"} ${data?.currency_symbol}`}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>{t("Contact Name")}</Typography>
                                                            <Typography className={classes.amounttxt}>{data?.over_lapping_agreement?.[0]?.contact_name}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        data?.reservationDetails?.occupant_name?.length > 0 &&
                                                        <>
                                                            <Box mt={1} mb={1}>
                                                                <Divider />
                                                            </Box>

                                                            <Box mt={1}>
                                                                <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                                                    <ReservationIcon />
                                                                    <Typography className={classes.amenitiesText}>{t("Reservation Details")}</Typography>
                                                                </Stack>
                                                                <Stack mt={1} direction="row" spacing={1} alignItems="center">
                                                                    <Avatar src={data?.reservationDetails} />
                                                                    <Box>
                                                                        <Typography className={classes.amounttxt}>{data?.reservationDetails?.occupant_name ?? "-"}</Typography>
                                                                        <Typography className={classes.floorTxt}>{` ${t("Reserved By")} ${data?.reservationDetails?.reserved_by}`}</Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Box>
                                                        </>
                                                    }
                                                </Box>


                                                :

                                                <center>
                                                    <NoDataFound />
                                                </center>
                                        }



                                    </Box>
                                }
                                {
                                    selectedTab !== 2 && data?.unit_vaccancy?.length > 0 &&
                                    <Box mt={2}>
                                        <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                            <HistoryIcon />
                                            <Typography className={classes.amenitiesText}>{t("Occupant History")}</Typography>
                                        </Stack >
                                        <Box mt={1} p={1}>
                                            <Stepper t={t} data={data?.unit_vaccancy?.map((x) => {

                                                return {
                                                    ...x,
                                                    dateRange: `${moment(x?.available_from).format("DD MMM YYYY")} - ${moment(x?.available_to).format("DD MMM YYYY")}`,
                                                    icon: <CircleIcon />
                                                }
                                            })} />
                                        </Box>
                                    </Box >
                                }
                            </Grid >
                        </Grid >
                    </Box >
                    {/* {selectedTab === 3 ?
                        <Box height="600px" p={2} overflow="overlay">


                            <Box mt={2} className={classes.agreementBox}>
                                <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                    <AgreementIcon />
                                    <Typography className={classes.amenitiesText}>Agreement Details</Typography>
                                </Stack>
                                {
                                    data?.over_lapping_agreement?.length > 0 ?
                                        data?.over_lapping_agreement?.map((val, index) => {
                                            return (
                                                <Box mt={1}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>Agreement ID</Typography>
                                                            <Typography className={classes.amounttxt}>{val?.agreement_no}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>From</Typography>
                                                            <Typography className={classes.amounttxt}>{moment(val?.lease_start_date).format("DD MMM YYYY")}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>To</Typography>
                                                            <Typography className={classes.amounttxt}>{moment(val?.lease_end_date).format("DD MMM YYYY")}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>Revenue</Typography>
                                                            <Typography className={classes.amounttxt}>{val?.revenue_type}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography className={classes.agreementTxt}>Contact Name</Typography>
                                                            <Typography className={classes.amounttxt}>{val?.contact_name}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        (data?.over_lapping_agreement?.length - 1) !== index &&
                                                        <Box mt={1} mb={1}>
                                                            <Divider />
                                                        </Box>
                                                    }

                                                </Box>
                                            )

                                        }) :
                                        <center>
                                            <NoDataFound />
                                        </center>
                                }


                                <Box mt={1}>
                                    <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                        <ReservationIcon />
                                        <Typography className={classes.amenitiesText}>Reservation Details</Typography>
                                    </Stack>
                                    <Stack mt={1} direction="row" spacing={1} alignItems="center">
                                        <Avatar src={data?.reservationDetails} />
                                        <Box>
                                            <Typography className={classes.amounttxt}>{data?.reservationDetails?.occupant_name}</Typography>
                                            <Typography className={classes.floorTxt}>{` Reserved By ${data?.reservationDetails?.reserved_by}`}</Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Box>

                            {data?.unit_vaccancy?.length > 0 &&
                                <Box mt={1}>
                                    <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                        <HistoryIcon />
                                        <Typography className={classes.amenitiesText}>Occupant History</Typography>
                                    </Stack>
                                    <Box mt={1} p={1}>
                                        <Stepper data={data?.unit_vaccancy?.map((x) => {
                                            console.log(x)
                                            return {
                                                ...x,
                                                dateRange: `${moment(x?.available_from).format("DD MMM YYYY")} - ${moment(x?.available_to).format("DD MMM YYYY")}`,
                                                icon: <CircleIcon />
                                            }
                                        })} />
                                    </Box>
                                </Box>
                            }
                        </Box>
                        :
                        <Box height="600px" p={2} overflow="overlay">
                            <Box mt={1}>
                                <AssetView
                                    asset={data?.assets
                                        ?.filter((x) => x?.asset_type !== assestType?.Videos)
                                        ?.filter((x) => x?.asset_type !== assestType?.Documents)
                                        ?.map((x) => x)}
                                    assetAll={data?.assets}
                                    openImageViewer={openImageViewer}
                                    isNormal
                                    maxWidth={"sm"}
                                />
                            </Box>
                            <Box mt={1} display="flex" justifyContent={"space-between"} alignItems="center">
                                <Typography className={classes.amounttxt}>{selectedTab === 1 ? data?.unit_name : data?.property_name}</Typography>
                                {selectedTab === 1 ?
                                    <Typography className={classes.amounttxt}>{data?.amount}</Typography>
                                    :
                                    <Box className={classes.breakupBox}><Typography className={classes.brkuptxt}>{`${data?.total_unit} units available`}</Typography></Box>
                                }
                            </Box>
                            <Box mt={1} display="flex" justifyContent={"space-between"} alignItems="center">
                                <Typography className={classes.unitDetails}>{selectedTab === 1 ? data?.unit_no : data?.property_no}</Typography>
                                {selectedTab === 1 &&
                                    <Typography className={classes.subtext} style={{ color: data?.status === "Occupied" ? "#98A0AC" : getColor(data?.type)?.color, backgroundColor: data?.status === "Occupied" ? "#E4E8EE" : getColor(data?.type)?.background }}>{data?.type}</Typography>
                                }
                            </Box>
                            {selectedTab === 1 &&
                                <>
                                    <Stack mt={1} direction="row" spacing={1} alignItems="center">

                                        {data?.bed &&
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Box><BedIcon /></Box>
                                                <Typography className={classes.floorTxt} noWrap>{`${data?.bed} Bedrooms`}</Typography>


                                            </Stack>
                                        }
                                        {data?.bed &&
                                            <Box className={classes.dotunit}></Box>
                                        }
                                        {data?.bathrooms &&
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Box><BathIcon /></Box>
                                                <Typography className={classes.floorTxt} noWrap>{`${data?.bathrooms} Baths`}</Typography>


                                            </Stack>
                                        }
                                        {data?.bathrooms &&
                                            <Box className={classes.dotunit}></Box>
                                        }
                                        {data?.furnishing &&
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Box><FurnishIcon /></Box>
                                                <Typography className={classes.floorTxt} noWrap>{data?.furnishing}</Typography>
                                            </Stack>
                                        }
                                        {data?.bathrooms &&
                                            <Box className={classes.dotunit}></Box>
                                        }
                                        {data?.area &&
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Box><AreaIcon /></Box>
                                                <Typography className={classes.floorTxt} noWrap>{data?.area}</Typography>
                                            </Stack>
                                        }
                                    </Stack>
                                    <Box mt={2} className={classes.amenitiesBox}>
                                        <Grid container spacing={2}>
                                            {data?.building_type &&
                                                <Grid item xs={4}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Box><ApartmentIcon /></Box>
                                                        <Typography className={classes.amenitiesText}>{data?.building_type}</Typography>
                                                    </Stack>
                                                </Grid>
                                            }
                                            {data?.community &&
                                                <Grid item xs={4}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Box><CommunityIcon /></Box>
                                                        <Typography className={classes.amenitiesText}>{data?.community}</Typography>
                                                    </Stack>
                                                </Grid>
                                            }
                                            {data?.property_type &&
                                                <Grid item xs={4}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Box><RevenueIcon /></Box>
                                                        <Typography className={classes.amenitiesText}>{data?.property_type}</Typography>
                                                    </Stack>
                                                </Grid>
                                            }
                                            {data?.orient &&
                                                <Grid item xs={4}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Box><FloorIcon /></Box>
                                                        <Typography className={classes.amenitiesText}>{data?.orient}</Typography>
                                                    </Stack>
                                                </Grid>
                                            }
                                            {data?.revenue_type &&
                                                <Grid item xs={4}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Box><PurposeIcon /></Box>
                                                        <Typography className={classes.amenitiesText}>{data?.revenue_type}</Typography>
                                                    </Stack>
                                                </Grid>
                                            }
                                            {data?.built_year &&
                                                <Grid item xs={4}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <CalendarIcon />
                                                        <Typography className={classes.amenitiesText}>{`${data?.built_year} build`}</Typography>
                                                    </Stack>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Box>

                                </>
                            }
                            <Box mt={2} mb={2}>
                                <Divider />
                            </Box>
                            <Box mt={1}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <img src="/images/mapIcon.svg" width="24px" alt="mapIcon" />
                                    <Typography className={classes.floorTxt}>{data?.address}</Typography>
                                </Stack>
                            </Box>
                            <Box mt={2} mb={2}>
                                <Divider />
                            </Box>

                            {
                                selectedTab === 1 &&
                                data?.amenities?.length > 0 &&
                                <>
                                    <Box mt={1}>
                                        <Typography className={classes.amenitiesText}>AMENITIES</Typography>
                                        <Grid container spacing={1}>
                                            {data?.amenities?.map((x) => {
                                                return (
                                                    <Grid item xs={3} alignItems="center">
                                                        <Stack mt={1} className={classes.amenitiesDataBox} direction={"row"} spacing={1} alignItems="center">
                                                            <Box>{x?.facility_type?.amenities_name === "Free WiFi" ? <WIFIIcon /> : x?.facility_type?.amenities_name === "Car Parking" ? <ParkingIcon /> : ""}</Box>
                                                            <Typography className={classes.floorTxt} noWrap>{x?.facility_type?.amenities_name}</Typography>
                                                        </Stack>
                                                    </Grid>

                                                )
                                            })}
                                        </Grid>
                                    </Box>
                                    <Box mt={2} mb={2}>
                                        <Divider />
                                    </Box>
                                </>
                            }
                            {
                                selectedTab === 2 &&
                                data?.property_amenities?.length > 0 &&
                                <>
                                    <Box mt={1}>
                                        <Typography className={classes.amenitiesText}>AMENITIES</Typography>
                                        <Grid container spacing={1}>
                                            {data?.property_amenities?.map((x) => {
                                                return (
                                                    <Grid item xs={3} alignItems="center">
                                                        <Stack mt={1} className={classes.amenitiesDataBox} direction={"row"} spacing={1} alignItems="center">
                                                            <Box>{x?.facility_type?.amenities_name === "Free WiFi" ? <WIFIIcon /> : x?.facility_type?.amenities_name === "Car Parking" ? <ParkingIcon /> : ""}</Box>
                                                            <Typography className={classes.floorTxt} noWrap>{x?.facility_type?.amenities_name}</Typography>
                                                        </Stack>
                                                    </Grid>

                                                )
                                            })}
                                        </Grid>
                                    </Box>
                                    <Box mt={2} mb={2}>
                                        <Divider />
                                    </Box>
                                </>
                            }
                            {selectedTab === 1 &&
                                <>
                                    <Box mt={1}>
                                        <Typography className={classes.amenitiesText}>BREAKUP</Typography>
                                        <Box mt={1} className={classes.breakupBox}>
                                            <Typography className={classes.brkuptxt}>This Break Up is {data?.from} to {data?.to}</Typography>
                                        </Box>
                                    </Box>
                                    <Box mt={2}>
                                        {
                                            data?.pricing?.map((val) => {
                                                return (

                                                    <Box mt={1} display="flex" justifyContent={"space-between"}>
                                                        <Typography className={classes.brkupdata}>{val?.rentalBreakup}</Typography>
                                                        <Typography className={classes.brkupdata}>{val?.amount}</Typography>
                                                    </Box>


                                                )
                                            })
                                        }
                                    </Box>

                                    <Box mt={2} className={classes.executionstatus}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <Typography className={classes.amounttxt}>Execution Status</Typography>
                                                <Box mt={1}>
                                                    <Typography className={classes.floorTxt}>This status update will reflect on this unit listing.Please be cautious while Updates</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <PrioritySelect
                                                    value={executionStatus}
                                                    options={executionStatusOptions}
                                                    onChange={(val) => {
                                                        changeExecutionStatus(val)
                                                        setExecutionStatus(val)
                                                    }}
                                                    isLabel={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </>
                            }
                            {selectedTab === 2 &&
                                <>
                                    <Box mt={1}>
                                        <Typography className={classes.amenitiesText}>LOCATION & NEARBY</Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <Carousel showArrows={false} breakPoints={breakPoints}>

                                            {locationdata?.map((x) => {
                                                return (

                                                    <Box className={classes.nearbyBox} marginRight={"12px"} >
                                                        <Box className={classes.iconBox} display="flex" justifyContent={"center"} style={{ backgroundColor: x?.color }} alignItems="center">
                                                            {getIconColor(x?.name)?.icon}
                                                        </Box>
                                                        <Box onClick={() => onLoc(x?.type, x)} className={classes.nearybyTitleBox} display="flex" justifyContent={"space-between"} alignItems="center">
                                                            <Box>
                                                                <Typography className={classes.amounttxt}>{x?.name}</Typography>
                                                                <Typography className={classes.floorTxt}>{"Near this Property"}</Typography>
                                                            </Box>
                                                            <KeyboardArrowRight className={classes.arrowIcon} />
                                                        </Box>
                                                    </Box>


                                                )
                                            })}

                                        </Carousel>
                                    </Box>
                                </>
                            }
                        </Box>
                    } */}
                </Box >

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    className={classes.menuList}
                >
                    {blockOptions(t)?.map((val, i) => {
                        return (
                            <MenuItem onClick={() => onBlockUnit(val)} className={classes.menuItemlist}>

                                <Box>
                                    <Typography className={classes.blockOptionLabel}>{val}</Typography>
                                    {blockOptions?.length - 1 !== i &&
                                        <Box className={classes.applyblockborder} />
                                    }
                                </Box>

                            </MenuItem>
                        )

                    })}
                </Menu>
                {
                    isViewerOpen && (
                        <ImageViewer
                            src={data?.assets}
                            currentIndex={currentImage}
                            onClose={closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)",
                            }}
                            closeOnClickOutside={true}
                        />
                    )
                }

                <AlertDialog

                    header={t(loc?.type?.name)}
                    open={loc?.bool}

                    onClose={() => onCloseLoc("bool", false)}
                    component={<NearBy t={t} list={loc?.list} type={loc?.type} onChange={(x) => onLoc(x?.type, x)} />}
                />
            </Box>
        </>
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(ViewPropertyDetails, props)