import { LeadIcon } from "../../../assets/lead"
import { concat_string } from "../../../utils";

export const constructResponseGetStatData = ({ data = "", t = () => false }) => {
    const resource_count = [
        {
            count: data?.resource_count ?? "0",
            sub: t("Total Resource"),
        },
        {
            count: data?.inspector_count ?? "0",
            sub: t("Inspector"),
        },
        {
            count: data?.employee_count ?? "0",
            sub: t("Employees"),
        },
        {
            count: data?.worker_count ?? "0",
            sub: t("Workers"),
        },
        {
            count: data?.security_count ?? "0",
            sub: t("Security Guards"),
        },
        {
            count: data?.registered_worker_count ?? "0",
            sub: t("Registered Workers"),
        },
    ]?.map?.(_ => { return { ..._, icon: <LeadIcon />, icon_type: true } });

    const job_roles_total = data?.roles_jobs?.map(_ => parseInt(_?.count)).reduce((a, b) => a + b, 0);
    const job_roles_data = data?.roles_jobs?.map(_ => {
        return {
            name: _?.name,
            count: parseInt(_?.count),
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        }
    })
    const job_roles = { data: job_roles_data, total: job_roles_total };

    const profession_skills_total = data?.profession?.map(_ => parseInt(_?.count)).reduce((a, b) => a + b, 0);
    const profession_skills_data = data?.profession?.map(_ => {
        return {
            name: _?.name,
            count: parseInt(_?.count),
            fill: `#${Math.random().toString(16).substring(2, 8)}`,
        }
    })
    const profession_skills = { data: profession_skills_data, total: profession_skills_total };

    const final_stat_data = {
        resource_count,
        job_roles,
        profession_skills,
    };

    return final_stat_data;
}

export const constructResponseGetResourceListData = ({ data = "" }) => {
    const rows = data?.data?.map?.(_ => {
        return {
            id: _?.id,
            image: { image_src: _?.contact_image, name: _?.contact_name ?? "-" },
            name: _?.contact_name ?? "-",
            email: _?.contact_email ?? "-",
            mobile: _?.contact_mobile_no
                ? concat_string(
                    {
                        mobile_code: _?.contact_mobile_no_country_code,
                        mobile_no: _?.contact_mobile_no,
                    },
                    ["mobile_code", "mobile_no"],
                    "-"
                )
                : "-",
            job_role: _?.job_name ?? "-",
            data: _,
        };
    });

    const final_list_data = {
        rows,
        count: data?.count,
    };

    return final_list_data;
}

export const constructResponseGetOngoingJobsListData = ({ data = "", t = () => false }) => {
    const rows = data?.list?.list?.map?.(_ => {
        return {
            id: _?.id,
            resource_name: _?.first_name ?? "-",
            job_id: _?.reference_id ?? "-",
            job_type: _?.request_type ?? "-",
            job_date: _?.execution ?? "-",
            kpi: _?.work_status ?? "-",
            job_status: _?.status ?? "-",
            data: _,
        };
    });

    const resource_kpi_data = [
        {
            fill: "#F9A666",
            name: t("On Time"),
            count: data?.resource_kpi?.Ontime,
        },
        {
            fill: "#F66C02",
            name: t("Delayed"),
            count: data?.resource_kpi?.delayed,
        },
    ]

    const final_list_data = {
        rows,
        count: data?.list?.count,
        job_completion_kpi: {
            total: data?.resource_kpi?.total,
            data: resource_kpi_data,
        },
    };

    return final_list_data;
}

export const constructResponseGetLeaveTimeOffData = ({ data = "", t = () => false }) => {
    const total = data?.map(_ => parseInt(_?.count)).reduce((a, b) => a + b, 0);
    const temp_data = data?.map(_ => {
        return {
            name: _?.type,
            count: parseInt(_?.count),
            fill: _?.fill,
        }
    })

    const final_list_data = { data: temp_data, total };

    return final_list_data;
}

export const constructResponseGetResourceJobsListData = ({ data = "" }) => {
    const rows = data?.resources?.map?.(_ => {
        return {
            id: _?.id,
            resource_name: _?.label ?? "-",
            total_jobs: _?.total_jobs ?? "-",
            assigned: _?.assigned_jobs ?? "-",
            ongoing: _?.ongoing_jobs ?? "-",
            completed: _?.completed_jobs ?? "-",
            data: _,
        };
    });

    const final_list_data = {
        rows,
        count: data?.count,
    };

    return final_list_data;
}

export const constructResponseGetCompletedJobsListData = ({ data = "" }) => {
    const rows = data?.actual_projected_otherhours?.result?.map?.(_ => {
        return {
            id: _?.id,
            resource_name: _?.first_name ?? "-",
            jobs: _?.jobs ?? "-",
            projected: _?.projected_hour ?? "-",
            actual: _?.actual_hours ?? "-",
            others: _?.other_hour ?? "-",
            ontime: _?.on_time ?? "-",
            delayed: _?.delay ?? "-",
            data: _,
        };
    });

    const final_list_data = {
        rows,
        count: data?.actual_projected_otherhours?.count,
    };

    return final_list_data;
}