export const ManagementCommittee = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98376">
            <g data-name="Group 8127">
                <g data-name="Group 98373">
                    <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                    <g transform="translate(-1.217 -1.298)" fill={props?.fill ?? "#c1c5cb"}>
                        <path
                            data-name="Path 94741"
                            d="M23.217 18.227h.714v-1.429H8.217v1.429h15Z"
                        />
                        <path
                            data-name="Path 94742"
                            d="M12.86 14.298v1.786h1.071v-.714a.357.357 0 1 1 .714 0v.714h.714v-.714a.357.357 0 0 1 .038-.16l.216-.432-.809-.539a.358.358 0 1 1 .4-.594l.873.582.873-.582a.358.358 0 0 1 .4.594l-.809.539.216.432a.357.357 0 0 1 .038.16v.714h.714v-.714a.357.357 0 1 1 .714 0v.714h1.066v-1.786a1.073 1.073 0 0 0-1.071-1.071h-1.073a.357.357 0 0 1-.357-.357v-.482a2.1 2.1 0 0 1-1.429 0v.482a.357.357 0 0 1-.357.357h-1.071a1.073 1.073 0 0 0-1.071 1.071Z"
                        />
                        <path
                            data-name="Path 94743"
                            d="M9.646 15.369a.357.357 0 0 1 .714 0v.714h1.786v-1.236l-.16.106a.357.357 0 0 1-.4 0l-1.067-.715a.358.358 0 1 1 .4-.594l.874.583.39-.259a1.773 1.773 0 0 1 .424-.852.355.355 0 0 1-.1-.246v-.482a2.1 2.1 0 0 1-1.429 0v.482a.357.357 0 0 1-.357.357H9.646a1.073 1.073 0 0 0-1.07 1.071v1.786h1.07Z"
                        />
                        <circle
                            data-name="Ellipse 129210"
                            cx={1.429}
                            cy={1.429}
                            r={1.429}
                            transform="translate(10.36 9.298)"
                        />
                        <circle
                            data-name="Ellipse 129211"
                            cx={1.429}
                            cy={1.429}
                            r={1.429}
                            transform="translate(14.646 9.298)"
                        />
                        <circle
                            data-name="Ellipse 129212"
                            cx={1.429}
                            cy={1.429}
                            r={1.429}
                            transform="translate(18.931 9.298)"
                        />
                        <path
                            data-name="Path 94744"
                            d="M19.545 13.116a1.773 1.773 0 0 1 .424.852l.39.259.874-.583a.358.358 0 0 1 .4.594l-1.071.714a.357.357 0 0 1-.4 0l-.16-.106v1.237h1.786v-.714a.357.357 0 1 1 .714 0v.714h1.071v-1.785a1.073 1.073 0 0 0-1.071-1.071h-1.071a.357.357 0 0 1-.357-.357v-.482a2.1 2.1 0 0 1-1.429 0v.482a.355.355 0 0 1-.1.246Z"
                        />
                        <path
                            data-name="Path 94745"
                            d="M9.288 18.941v5.357H22.86v-5.357Zm1.429 1.429a.357.357 0 1 1 .357-.357.357.357 0 0 1-.357.357Zm1.071 0a.357.357 0 1 1 .357-.357.357.357 0 0 1-.357.357Zm1.071 0a.357.357 0 1 1 .357-.357.357.357 0 0 1-.357.357Zm1.071 0a.357.357 0 1 1 .357-.357.357.357 0 0 1-.359.357Z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
