import * as React from "react"
const BOMIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="Group 115872">
      <path
        fill="#4e5a6b"
        d="M2.2 1.6A2.209 2.209 0 0 0 0 3.8v8.4a2.209 2.209 0 0 0 2.2 2.2h11.6a2.209 2.209 0 0 0 2.2-2.2V3.8a2.209 2.209 0 0 0-2.2-2.2Zm0 1.2h11.6a.991.991 0 0 1 1 1v8.4a.991.991 0 0 1-1 1H2.2a.991.991 0 0 1-1-1V3.8a.991.991 0 0 1 1-1Zm1.2 2.4a.6.6 0 1 0 0 1.2h6a.6.6 0 1 0 0-1.2Zm8.4 0a.6.6 0 1 0 0 1.2h.8a.6.6 0 1 0 0-1.2ZM3.4 7.6a.6.6 0 1 0 0 1.2h6a.6.6 0 1 0 0-1.2Zm8.4 0a.6.6 0 1 0 0 1.2h.8a.6.6 0 1 0 0-1.2Zm0 2.4a.6.6 0 1 0 0 1.2h.8a.6.6 0 1 0 0-1.2Z"
        data-name="icons8-bill (7)"
      />
    </g>
  </svg>
)
export default BOMIcon
