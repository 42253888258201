import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../../../utils";
export const propertyStyles = makeStyles((theme) => ({
  card: {
    border: "2px solid white",
    borderRadius: "12px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px 16px 16px 0px",
  },
  cards: {
    border: "2px solid white",
    borderRadius: "12px",
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",

  },
  unitImg: {
    height: "120px",
    width: "120px",
    borderRadius: "50%",
    margin: "4px",
    objectFit: "fill"
  },
  imgTag: {
    padding: "2px 6px",
    borderRadius: "5px",
    backgroundColor: "#071741",
    color: "white",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    position: "absolute",
    bottom: "10px",
    display: "inline",

  },
  imgDiv: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    borderRight: "1px solid #00000014",
    padding: "4px"
  },
  title: {
    color: theme.typography.color.secondary,
    fontSize:"0.75rem",
    fontFamily: Bold
  },
  imgDiv2: {
    padding: "0px 12px"
  },
  heading: {
    color: theme.typography.color.tertiary,
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    textTransform: "capitalize"
  },
  sub: {
    color: theme.typography.color.primary,
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    textTransform: "capitalize",
    '& .ql-editor': {
      padding: '0px 0px 16px 0px',
      color: theme.typography.color.primary,
      fontSize:"0.875rem",
      fontFamily: SemiBold,
      textTransform: "capitalize",
      textAlign: "start"
    }

  },
  sub1: {
    color: theme.typography.color.primary,
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    textTransform: "capitalize"
  },
  matric: {
    color: theme.typography.color.tertiary,
    fontSize:"0.75rem",
    fontFamily: Regular,
    textTransform: "capitalize"
  },
  map: {
    borderRadius: "12px",
    overflow: "hidden"
  },
  addressRoot: {
    padding: "12px",
    minHeight: "200px",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius
  },
  list: {
    '&.MuiListItem-root': {
      width: '135px !important',
      padding: '0px!important',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      border: '1px solid #E4E8EE',
      cursor: 'pointer',
      marginTop: "-4px"
    }
  },
  tabname: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    cursor: 'pointer'
  },
  imagebox1: {
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: '12px',
  },
  imagebox: {
    textAlign: 'center',
    padding: '23px 16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: '12px',
  },
  listText: {
    backgroundColor: '#5078E1',
    width: '120px !important',
    color: '#fff',
    borderRadius: '6px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: "-4px"
  },
}));