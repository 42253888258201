/* eslint-disable array-callback-return */
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import * as React from "react";

export const CheckboxesGroup = ({
    selectedValues = [],
    onSelect = () => false,
    required = false,
    error = false,
    title = "",
    helperText = "",
    filters = [],
    onChangeAll = () => false,
}) => {
    const handleChange = (event, value) => {
        onSelect({ value, isChecked: event.target.checked });
    };

    const fetchAll = filters.map((val, index) => {
        let _d = [];
        try {
            _d.push(val.value);
        } catch (err) {
           
        }
        return _d;
    });

    return (
        <Box sx={{ display: "flex" }}>
            <FormControl
                required={required}
                error={error}
                component="fieldset"
                variant="standard"
            >
                {title && <FormLabel component="legend">{title}</FormLabel>}

                <FormGroup>
                    {filters.map((filter, key, index) => (
                        <>
                            {filter.all === "true0" ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={fetchAll.length === selectedValues.length}
                                            onChange={(event) => onChangeAll(event, filters)}
                                        />
                                    }
                                    label="All"
                                />
                            ) : (
                                ""
                            )}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedValues.indexOf(filter.value) > -1}
                                        onChange={(event) => handleChange(event, filter.value)}
                                    />
                                }
                                label={filter.label}
                            />
                        </>
                    ))}
                </FormGroup>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </Box>
    );
};
