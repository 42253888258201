import CustomerActive from "./customerActive";
import CustomerInactive from "./customerInactive";
import VendorActive from "./vendorActive";
import VendorInactive from "./vendorInActive";

export const AccountType = (t = () => false) => {
    return [
        {
            title: t("Customer"),
            value: "Customer",
            selectedIcon: <CustomerActive />,
            notSelectedIcon: <CustomerInactive />,
        },
        {
            title: t("Vendor"),
            value: "Vendor",
            selectedIcon: <VendorActive />,
            notSelectedIcon: <VendorInactive />,
        }
    ]
}


export const ComponentTypes = [
    {
        label: "Primary",
        value: "primary"
    },
    {
        label: "Secondary",
        value: "secondary"
    },
    {
        label: "One Time Charges",
        value: "one_time"
    },
    {
        label: "Refundables",
        value: "refundable"
    },
    {
        label: "Parking Slot",
        value: "parking_slot"
    }
]

export const ItemMasterTypes = [
    {
        label: "Inventory Items",
        value: "item_based"
    },
]

export const getComponentValue=(component , is_wallet_item)=>{
    if(!component){
        if(is_wallet_item){
            return {label:"Wallet Item" , value:"is_wallet_item"} 
        }
        return {label:"Inventory Items" , value:"item_based"} 
    }
    if(component?.primary){
        return {label:"Primary" , value:"primary"}
    }
    if(component?.isOnetime){
        return {label:"One Time Charges" , value:"one_time"}
    }
    if(component?.isQuantity){
        return {label:"Inventory Items" , value:"item_based"}
    }
    if(component?.refundable){
        return {label:"Refundables" , value:"refundable"}
    }
    if(component?.is_parking){
        return {label:"Parking Slot" , value:"parking_slot"}
    }
    if(component?.is_wallet){
        return {label:"Wallet Points" , value:"is_wallet"}
    }
    if(component?.is_late_fee){
        return {label:"Late Fees & Penalty" , value:"is_late_fee"}
    }
    if(component?.is_commission_payment){
        return {label:"Commission & Payment" , value:"is_commission_payment"}
    }
    if(!component?.primary && !component?.isOnetime && !component?.refundable && !component?.isQuantity && !component?.is_parking){
        return {label:"Secondary" , value:"secondary"}
    }
    

}

export const ComponentTypes1 = [
    {
        label: "Primary",
        value: "primary"
    },
    {
        label: "Secondary",
        value: "secondary"
    },
    {
        label: "One Time Charges",
        value: "one_time"
    },
    {
        label: "Refundables",
        value: "refundable"
    },
    {
        label: "Parking Slot",
        value: "parking_slot"
    },
    {
        label: "Wallet Points",
        value: "is_wallet"
    },
    // {
    //     label: "Late Fees & Penalty",
    //     value: "is_late_fee"
    // },
    {label:"Commission & Payment" , value:"is_commission_payment"}
]

export const ItemMasterTypes1 = [
    {
        label: "Inventory Items",
        value: "item_based"
    },
    {
        label: "Wallet Item",
        value: "is_wallet_item"
    },
]
