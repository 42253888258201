import { Box, Grid, Stack, Typography } from "@mui/material";
import { useStyles } from "./style";
import React from "react";
export const ContactInfoCard = ({
    t = () => false,
    address = "",
    business_phone = "",
    company_website = "",
    alternate_phone = "",
    company_email_id = "",
}) => {

    const classes = useStyles()

    return (
        <div className={classes.card} >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div className={classes.addressRoot}>
                        <Stack direction="row" >
                            <img src='/images/loc.svg' alt='' />
                            <Typography className={classes.title}>{`${t("Address")} : `}</Typography>
                        </Stack>
                        <Box height="12px" />
                        <Typography className={classes.sub}>
                            {address ?? " - "}
                        </Typography>

                    </div >
                </Grid >
                <Grid item xs={6}>
                    <div className={classes.addressRoot}>
                        <Stack direction="row" >
                            <img src='/images/Group 7015.svg' alt='' />
                            <Typography className={classes.title}>{`${t("Contact & Other Information")} : `}</Typography>
                        </Stack>
                        <Grid container>
                            <Grid item xs={6}>
                                <Box marginTop="18px">
                                    <Typography className={classes.heading}>{`${t("Business Phone")} : `}</Typography>
                                    <Typography className={classes.sub}>{business_phone ?? " - "}</Typography>
                                </Box>
                                <Box marginTop="12px">
                                    <Typography className={classes.heading}>{`${t("Website")} : `}</Typography>
                                    <Typography className={classes.sub}>{company_website ?? " - "}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box marginTop="18px">
                                    <Typography className={classes.heading}>{`${t("Mobile Phone")} : `}</Typography>
                                    <Typography className={classes.sub}>{alternate_phone ?? " - "}</Typography>
                                </Box>
                                <Box marginTop="12px">
                                    <Typography className={classes.heading}>{`${t("Email Address")} : `}</Typography>
                                    <Typography className={classes.sub}>{company_email_id ?? " - "}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid >
        </div >
    )
}