export const Specifications = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98375">
            <g data-name="Group 8127">
                <g data-name="Group 98373">
                    <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                    <g data-name="Group 98360">
                        <g data-name="Group 98359">
                            <path
                                data-name="Path 94739"
                                d="M16.471 8.879h-.825l-.1-.293a.879.879 0 0 0-1.658 0l-.1.293h-.825a.879.879 0 0 0-.879.879v.442a.439.439 0 0 0 .439.439h4.395a.439.439 0 0 0 .439-.439v-.442a.879.879 0 0 0-.886-.879Z"
                                fill={props?.fill ?? "#c1c5cb"}
                            />
                        </g>
                    </g>
                    <g data-name="Group 98362">
                        <g data-name="Group 98361">
                            <path
                                data-name="Path 94740"
                                d="M19.107 9.758h-.879v.439a1.32 1.32 0 0 1-1.318 1.318h-4.394a1.32 1.32 0 0 1-1.316-1.318v-.439h-.879A1.32 1.32 0 0 0 9 11.076v10.547A1.371 1.371 0 0 0 10.318 23h8.789a1.371 1.371 0 0 0 1.318-1.377V11.076a1.32 1.32 0 0 0-1.318-1.318Zm-4.963 9.539-1.757 1.761a.439.439 0 0 1-.621 0l-.879-.879a.44.44 0 1 1 .621-.621l.568.568 1.447-1.447a.44.44 0 1 1 .621.621Zm0-3.076-1.758 1.758a.439.439 0 0 1-.621 0l-.879-.879a.44.44 0 1 1 .621-.621l.568.568 1.447-1.447a.44.44 0 1 1 .621.621Zm0-3.076-1.758 1.758a.439.439 0 0 1-.621 0l-.879-.879a.44.44 0 1 1 .621-.621l.568.568 1.447-1.447a.44.44 0 1 1 .621.621Zm4.084 7.16h-2.636a.44.44 0 0 1 0-.879h2.637a.44.44 0 0 1 0 .879Zm0-3.076h-2.636a.44.44 0 0 1 0-.879h2.637a.44.44 0 0 1 0 .879Zm0-3.076h-2.636a.44.44 0 0 1 0-.879h2.637a.44.44 0 0 1 0 .879Z"
                                fill={props?.fill ?? "#c1c5cb"}
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
