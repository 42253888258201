import { makeStyles } from "@mui/styles"

export const CalendarDropDownStyles = makeStyles((theme) => ({
    dropdown: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 3,
        position: "absolute",
        backgroundColor: "#fff",
        top: "128px",
        boxShadow: "0px 0px 16px  #00000014"
    },
    dropdown2: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 1,
        position: "relative",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 16px  #00000014"
    },
    bottom: {
        position: "absolute",
        top: "39px",
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
        borderRight: `1px solid ${theme.palette.border.secondary}`,
        borderLeft: `1px solid ${theme.palette.border.secondary}`,
        borderBottomLeftRadius: theme.palette.borderRadius,
        borderBottomRightRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 1,
        backgroundColor: "#fff",
        left: 0,
        right: 0,
        width: "100%",
        padding: "0px 8px"
    },
    selected: {
        '&:hover': {
            color: theme.palette.info.main
        }
    }
}))