import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const proofComponentStyles = makeStyles((theme) => ({
    title: {
        fontSize:"0.875rem",
        color: "#4E5A6B",
        fontFamily: Bold,
    },
    titleType: {
        fontSize:"0.875rem",
        color: "#4E5A6B",
        fontFamily: Bold,
        margin: "8px 0px 2px 0px"
    },
    addtitle: {
        fontSize:"0.875rem",
        color: "#5078E1",
        fontFamily: Bold,
        cursor: "pointer",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "white",
            boxShadow: "none",
        },
    },
    menuList: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 6px #0717411F",
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "white",
            padding: "0px"
        }
    },
    menuItem: {
        borderBottom: "1px solid #E4E8EE",
        margin: "0px",
        fontSize:"0.875rem",
        color: "#071741",
        fontFamily: SemiBold,
        '&:last-child': {
            border: 0,
        },
    },
    label: {
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        color: theme.typography.color.tertiary,
        marginBottom: "4px"
    },
    uploadBtn: {
        "&:hover": {
            color: "white",
        },
        marginTop: "16px",
        padding: "10px 18px"
    },
    rootUpload: {
        border: "1px solid #5078E1",
        height: "44px",
        cursor: "pointer",
        borderRadius: "4px",
        position: "relative",
    },

    uploadlabel: {
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color: "#5078E1",
        marginLeft: "8px"
    },
    uploadIcon: {
        fontSize:"1.5rem",
        color: "#5078E1"
    },
    uploadedimg: {
        width: "100%",
        objectFit: "contain",
        height: "120px",
        borderRadius: "4px",
    },
    previewRoot: {
        backgroundColor: "#F5F7FA",
        borderRadius: "4px",
        padding: "12px 12px 8px 12px",
        marginTop: "16px"
    },
    fileName: {
        fontSize:"0.75rem",
        color: "#4E5A6B",
        fontFamily: Bold,
        marginLeft: "8px",
    },
    fileroot: {
        backgroundColor: "#DEEAFC",
        height: "150px",
        fontSize:"0.75rem",
        color: "#5078E1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
    },
    imgContainer: {
        width: "100%",
        borderRadius: theme.palette.borderRadius,
    },
    delete: {
        color: "red",
        position: "absolute",
        top: "12px",
        right: "11px",
        backgroundColor: "white",
        padding: "8px"
    },


}));