import { Box, Grid, Typography } from "@mui/material";
import React from 'react';
import ReactQuill from "react-quill";
import "../../../../App.css";
import useWindowDimensions from "../../../../utils/useWindowDimensions";
import { useStyles } from "./style";
import { UnitList } from "./unitList/unitList";

export const PropertyDetails = (props) => {
      const classes = useStyles()
      const size = useWindowDimensions()
      const modules = {
            toolbar: false,
      };
      return (

            <div style={{ height: size?.height - (64 + 49), overflow: "auto", padding: "24px" }}>
                  {/*property detials first card */}
                  <Grid container className={classes.card}>
                        <Grid item xs={12} sm={12} md={2} lg={2} >
                              <div className={classes.imgDiv}>
                                    <img src={(props?.assets?.[0]?.url && props?.assets?.[0]?.url !== "") ? props?.assets?.[0]?.url : "/images/imagesproperty.svg"} alt="" className={classes.unitImg} />
                                    <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.property_no}&nbsp;&nbsp;</Typography>
                              </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} className={classes.imgDiv2}>
                              <Typography className={classes.title}>PROPERTY DETAILS</Typography>
                              <Grid container>
                                    <Grid item md={12} lg={9}>
                                          <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="8px">
                                                <Box>
                                                      <Typography className={classes.heading}>Company Name</Typography>
                                                      <Typography className={classes.sub}>{props?.data?.company?.name ? props?.data?.company?.name : " - "}</Typography>
                                                </Box>
                                                <Box>
                                                      <Typography className={classes.heading}>Property Name</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.name ? props?.data?.name : " - "}
                                                      </Typography>
                                                </Box>
                                                <Box>
                                                      <Typography className={classes.heading}>Property Type</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.property_groupByID?.group_name ? props?.data?.property_groupByID?.group_name : "- "}
                                                      </Typography>
                                                </Box>
                                                <Box>
                                                      <Typography className={classes.heading}>Property Purpose</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.property_purpose ? props?.data?.property_purpose : "-"}
                                                      </Typography>
                                                </Box>
                                                <Box>
                                                      <Typography className={classes.heading}> Payment Period</Typography>
                                                      <Typography className={classes.sub}>
                                                            {props?.data?.payment_period ? props?.data?.payment_period : "-"}
                                                      </Typography>
                                                </Box>

                                                <Box>
                                                      <Typography className={classes.heading}>Status</Typography>
                                                      <Typography className={classes.sub}>{props?.data?.is_active ? "Active" : "In active"}</Typography>
                                                </Box>
                                          </Box>
                                    </Grid>
                              </Grid>

                              <Box marginTop="14px">
                                    <Typography className={classes.heading}> Property Description</Typography>
                                    {props?.data?.description &&
                                          <ReactQuill
                                                readOnly
                                                theme="bubble"
                                                value={props?.data?.description ?? ""}
                                                modules={modules}
                                                className={classes.sub}
                                          />
                                    }
                              </Box>
                        </Grid>
                        <Grid item md={0} lg={4.5} />
                  </Grid>
                  <UnitList />
            </div>
      )
}