import * as React from "react"
const GreenTickConfirmed = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={31.999} {...props}>
    <path
      fill="#5ac782"
      d="M30.264 16.098a.176.176 0 0 1 0-.191l1.318-2.058a2.638 2.638 0 0 0-1.01-3.77l-2.173-1.127a.18.18 0 0 1-.1-.167l.113-2.443a2.638 2.638 0 0 0-2.76-2.76l-2.438.116a.219.219 0 0 1-.167-.1l-1.122-2.169a2.639 2.639 0 0 0-3.77-1.011l-2.056 1.318a.176.176 0 0 1-.191 0L13.846.418a2.638 2.638 0 0 0-3.77 1.01l-1.123 2.17a.177.177 0 0 1-.167.1l-2.444-.115a2.638 2.638 0 0 0-2.76 2.76l.117 2.442a.181.181 0 0 1-.1.167l-2.17 1.121a2.639 2.639 0 0 0-1.011 3.77l1.319 2.06a.176.176 0 0 1 0 .191L.418 18.152a2.638 2.638 0 0 0 1.01 3.77l2.171 1.123a.18.18 0 0 1 .1.167l-.114 2.444a2.648 2.648 0 0 0 .77 1.99 2.605 2.605 0 0 0 1.99.77l2.444-.113a.2.2 0 0 1 .167.1l1.122 2.171a2.64 2.64 0 0 0 3.77 1.01l2.06-1.319a.174.174 0 0 1 .191 0l2.058 1.318a2.638 2.638 0 0 0 3.77-1.01l1.119-2.175a.2.2 0 0 1 .167-.1l2.443.113a2.638 2.638 0 0 0 2.76-2.76l-.117-2.439a.181.181 0 0 1 .1-.167l2.171-1.122a2.639 2.639 0 0 0 1.011-3.77Zm-7.193-3.205a38.838 38.838 0 0 0-7.951 7.955 1.231 1.231 0 0 1-.982.488 1.231 1.231 0 0 1-.982-.5 15.785 15.785 0 0 0-3.8-3.812 1.231 1.231 0 1 1 1.364-2.05 16.412 16.412 0 0 1 3.416 3.138 40.542 40.542 0 0 1 7.483-7.209 1.231 1.231 0 1 1 1.456 1.985Z"
      data-name="icons8-instagram-check-mark (3)"
    />
  </svg>
)
export default GreenTickConfirmed
