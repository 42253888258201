import { KeyboardArrowLeft } from "@mui/icons-material"
import { Box, Grid, Stack, Typography, Divider } from "@mui/material"
import React, { useState } from "react"
import { KeyboardArrowRight } from "@mui/icons-material"
import Carousel from "react-elastic-carousel"
import ImageViewer from "react-simple-image-viewer"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { ApartmentIcon } from "../../../assets/propertyBoard/apartmentIcon"
import { AreaIcon } from "../../../assets/propertyBoard/areaIcon"
import { CalendarIcon } from "../../../assets/propertyBoard/calendarIcon"
import { CommunityIcon } from "../../../assets/propertyBoard/communityIcon"
import { FloorIcon } from "../../../assets/propertyBoard/floorIcon"
import { FurnishIcon } from "../../../assets/propertyBoard/furnishIcon"
import { HospitalIcon } from "../../../assets/propertyBoard/hospitalIcon"
import ParkingIcon from "../../../assets/propertyBoard/parkingIcon"

import { ParkIcon } from "../../../assets/propertyBoard/parksIcon"
import { PurposeIcon } from "../../../assets/propertyBoard/purposeIcon"
import { RestaurantIcon } from "../../../assets/propertyBoard/restaurantIcon"
import { RevenueIcon } from "../../../assets/propertyBoard/revenueIcon"
import { SchoolIcons } from "../../../assets/propertyBoard/schoolIcon"
import { TransportationIcon } from "../../../assets/propertyBoard/transportationIcon"
import { WIFIIcon } from "../../../assets/propertyBoard/wifiIcon"
import PrioritySelect from "../../../components/prioritySelect/prioritySelect"
import { assestType } from "../../../utils"
import { BathIcon, BedIcon } from "../../propertyFinder4/components"
import { AssetView } from "../../requestView/components"
import { getColor, PropertyDetailCard } from "./card"
import { useStyles } from "./style"
import { useCallback } from "react"
export const ViewMoreDetails = ({ t, data = {}, setMoreDetails = () => false, propertyData = {}, openViewModal = () => false }) => {

    const classes = useStyles()
    const TabData = [{ value: 1, label: "House Details" }, { value: 2, label: "Property Details" }]
    const [selectedTab, setSelectedTab] = React.useState(1)
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const breakPoints = [{ width: 1, itemsToShow: 2 }];

    const [executionStatus, setExecutionStatus] = React.useState("Plan")
    const executionStatusOptions = (t) => [
        {
            label: t("Plan"),
            value: "Plan"
        },
        {
            label: t("Develop"),
            value: "Develop"
        },
        {
            label: t("Release"),
            value: "Release"
        },
    ]
    const getIconColor = (val) => {
        switch (val) {
            case "Restaurants":
                return { color: "#FFECEC", icon: <RestaurantIcon /> }
            case "Transportation":
                return { color: "#0000000F", icon: <TransportationIcon /> }
            case "Schools":
                return { color: "#F1F7FF", icon: <SchoolIcons /> }
            case "Hospitals":
                return { color: "#FFF4EB", icon: <HospitalIcon /> }
            default:
                return { color: "#EEF9EE", icon: <ParkIcon /> }
        }
    }
    // open image viwer
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    // close image viwer
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    console.log(data)
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={4} className={classes.applyBorder}>
                    <Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box className={classes.arrowLeft} onClick={() => setMoreDetails(false)}><KeyboardArrowLeft /></Box>
                            <Typography className={classes.amounttxt}>{data?.unit_name}</Typography>
                        </Stack>
                    </Box>
                    <Box mt={2}>
                        <Stack direction={"row"} spacing={2} className={classes.viewMoreTabs}>
                            {TabData?.map((x) => {
                                return (
                                    <Grid item xs={6} className={selectedTab === x.value ? classes.selectedMoretab : classes.tabText} onClick={() => setSelectedTab(x.value)} >
                                        {x.label}
                                    </Grid>
                                )
                            })}
                        </Stack>
                    </Box>
                    <Box mt={2} height="450px" overflow="overlay">
                        <Box mt={1}>
                            <AssetView
                                asset={data?.assets
                                    ?.filter((x) => x?.asset_type !== assestType?.Videos)
                                    ?.filter((x) => x?.asset_type !== assestType?.Documents)
                                    ?.map((x) => x)}
                                assetAll={data?.assets}
                                openImageViewer={openImageViewer}
                                isNormal
                                maxWidth={"sm"}
                            />
                        </Box>
                        <Box mt={2} display="flex" justifyContent={"space-between"} alignItems="center">
                            <Typography className={classes.amounttxt}>{selectedTab === 1 ? data?.unit_name : data?.property_name}</Typography>
                            {selectedTab === 1 ?
                                <Typography className={classes.amounttxt}>{data?.amount}</Typography>
                                :
                                <Box className={classes.breakupBox}><Typography className={classes.brkuptxt}>{`${data?.total_units} units available`}</Typography></Box>

                            }
                        </Box>
                        <Box mt={1} display="flex" justifyContent={"space-between"} alignItems="center">
                            <Typography className={classes.unitDetails}>{selectedTab === 1 ? data?.unit_id : data?.property_id}</Typography>
                            {selectedTab === 1 &&
                                <Typography className={classes.subtext} style={{ color: data?.status === "Occupied" ? "#98A0AC" : getColor(data?.type)?.color, backgroundColor: data?.status === "Occupied" ? "#E4E8EE" : getColor(data?.type)?.background }}>{data?.type}</Typography>
                            }
                        </Box>
                        {selectedTab === 1 &&
                            <>
                                <Stack mt={1} direction="row" spacing={1} alignItems="center">

                                    {data?.bed &&
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <BedIcon />
                                            <Typography className={classes.floorTxt} noWrap>{`${data?.bed} Bedrooms`}</Typography>



                                        </Stack >
                                    }
                                    {
                                        data?.bed &&
                                        <Box className={classes.dotunit}></Box>
                                    }
                                    {
                                        data?.bathrooms &&
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <BathIcon />
                                            <Typography className={classes.floorTxt} noWrap>{`${data?.bathrooms} Baths`}</Typography>



                                        </Stack >
                                    }
                                    {
                                        data?.bathrooms &&
                                        <Box className={classes.dotunit}></Box>
                                    }
                                    {
                                        data?.furnishing &&
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <FurnishIcon />
                                            <Typography className={classes.floorTxt} noWrap>{data?.furnishing}</Typography>
                                        </Stack>
                                    }
                                    {
                                        data?.bathrooms &&
                                        <Box className={classes.dotunit}></Box>
                                    }
                                    {
                                        data?.area &&
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <AreaIcon />
                                            <Typography className={classes.floorTxt} noWrap>{data?.area}</Typography>
                                        </Stack>
                                    }
                                </Stack >
                                <Box mt={2} className={classes.amenitiesBox}>
                                    <Grid container spacing={2}>
                                        {data?.building_type &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <ApartmentIcon />

                                                    <Typography className={classes.amenitiesText}>{data?.building_type}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.community &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <CommunityIcon />
                                                    <Typography className={classes.amenitiesText}>{data?.community}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.property_type &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <RevenueIcon />
                                                    <Typography className={classes.amenitiesText}>{data?.property_type}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.orient &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <FloorIcon />
                                                    <Typography className={classes.amenitiesText}>{data?.orient}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.revenue_type &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <PurposeIcon />
                                                    <Typography className={classes.amenitiesText}>{data?.orient}</Typography>
                                                </Stack>
                                            </Grid>
                                        }
                                        {data?.built_year &&
                                            <Grid item xs={4}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <CalendarIcon />
                                                    <Typography className={classes.amenitiesText}>{`${data?.built_year} build`}</Typography>

                                                </Stack >
                                            </Grid >
                                        }
                                    </Grid >
                                </Box >

                            </>
                        }
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>
                        <Box mt={1}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <img src="/images/mapIcon.svg" width="24px" alt="mapIcon" />
                                <Typography className={classes.floorTxt}>{data?.address}</Typography>
                            </Stack>
                        </Box>
                        <Box mt={2} mb={2}>
                            <Divider />
                        </Box>
                        {
                            data?.amenities?.length > 0 &&
                            <>
                                <Box mt={1}>

                                    <Typography className={classes.amenitiesText}>{t("AMENITIES")}</Typography>
                                    <Grid container spacing={1}>
                                        {data?.amenities?.map((x) => {
                                            return (
                                                <Grid item xs={3} alignItems="center">
                                                    <Stack mt={1} className={classes.amenitiesDataBox} direction={"row"} spacing={1} alignItems="center">
                                                        <Box>{x === "Free WiFi" ? <WIFIIcon /> : x === "Car Parking" ? <ParkingIcon /> : <FloorIcon />}</Box>
                                                        <Typography className={classes.floorTxt} noWrap>{x}</Typography>
                                                    </Stack>
                                                </Grid>

                                            )
                                        })}
                                    </Grid>
                                </Box >
                                <Box mt={2} mb={2}>
                                    <Divider />
                                </Box>
                            </>
                        }
                        {
                            selectedTab === 1 &&
                            <>
                                <Box mt={1}>

                                    <Typography className={classes.amenitiesText}>{t("BREAKUP")}</Typography>
                                    <Box mt={1} className={classes.breakupBox}>
                                        <Typography className={classes.brkuptxt}>{t("This Break Up is 22 Jan 22")}</Typography>
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <Box mt={1} display="flex" justifyContent={"space-between"}>

                                        <Typography className={classes.brkupdata}>{t("Base Rent (Monthly)")}</Typography>
                                        <Typography className={classes.brkupdata}>{data?.base_rent}</Typography>
                                    </Box >
                                    <Box mt={1} display="flex" justifyContent={"space-between"}>
                                        <Typography className={classes.brkupdata}>{`Maintenance (Monthly - ${data.maintenance_percentage})`}</Typography>
                                        <Typography className={classes.brkupdata}>{data?.maintenance_amount}</Typography>
                                    </Box>
                                    <Box mt={1} display="flex" justifyContent={"space-between"}>

                                        <Typography className={classes.brkupdata}>{t("Deposit (OneTime - Refundable)")}</Typography>
                                        <Typography className={classes.brkupdata}>{data?.deposit}</Typography>
                                    </Box >
                                </Box >
                                <Box mt={2} className={classes.executionstatus}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            <Typography className={classes.amounttxt}>{t("Execution Status")}</Typography>
                                            <Box mt={1}>
                                                <Typography className={classes.floorTxt}>{t("This status update will reflect on this unit listing.Please be cautious while Updates")}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <PrioritySelect
                                                value={executionStatus}
                                                options={executionStatusOptions(t)}
                                                onChange={(val) => setExecutionStatus(val)}
                                                isLabel={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        }
                        {
                            selectedTab === 2 &&
                            <>
                                <Box mt={1}>

                                    <Typography className={classes.amenitiesText}>{t("LOCATION & NEARBY")}</Typography>
                                </Box >
                                <Box mt={1}>
                                    <Carousel showArrows={false} breakPoints={breakPoints}>

                                        {data?.nearby?.map((x) => {
                                            return (

                                                <Box className={classes.nearbyBox}>
                                                    <Box className={classes.iconBox} display="flex" justifyContent={"center"} style={{ backgroundColor: getIconColor(x)?.color }} alignItems="center">
                                                        {getIconColor(x)?.icon}
                                                    </Box>
                                                    <Box className={classes.nearybyTitleBox} display="flex" justifyContent={"space-between"} alignItems="center">
                                                        <Box>
                                                            <Typography className={classes.amounttxt}>{t(x)}</Typography>

                                                            <Typography className={classes.floorTxt}>{t("Near this Property")}</Typography>
                                                        </Box >
                                                        <KeyboardArrowRight className={classes.arrowIcon} />
                                                    </Box >
                                                </Box >


                                            )
                                        })}

                                    </Carousel >
                                </Box >
                            </>
                        }
                    </Box >
                    {
                        isViewerOpen && (
                            <ImageViewer
                                src={data?.assets}
                                currentIndex={currentImage}
                                onClose={closeImageViewer}
                                disableScroll={false}
                                backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.9)",
                                }}
                                closeOnClickOutside={true}
                            />
                        )
                    }
                </Grid >
                <Grid item xs={8}>

                    <Typography className={classes.amounttxt}>{t("Bedrooms & Beds")}</Typography>
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            {propertyData?.map((x) => {
                                return (
                                    <Grid item>
                                        <PropertyDetailCard t={t} val={x} openViewModal={openViewModal} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                </Grid >
            </Grid >
        </Box >
    )
}