import React from "react"

export const FurnishIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_112285" data-name="Group 112285" transform="translate(0.413 0.03)">
    <g id="Group_112284" data-name="Group 112284">
      <g id="Rectangle_57207" data-name="Rectangle 57207" transform="translate(-0.413 -0.03)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="20" height="20" stroke="none"/>
        <rect x="0.5" y="0.5" width="19" height="19" fill="none"/>
      </g>
      <path id="icons8-armchair" d="M7.178,6A2.37,2.37,0,0,0,4.817,8.361V9.642A2.007,2.007,0,0,0,3,11.631a1.982,1.982,0,0,0,1.453,1.887v2.473A2.732,2.732,0,0,0,5.906,18.4v.864a.545.545,0,1,0,1.09,0v-.554c.06,0,.12.009.182.009h6.908c.059,0,.117-.005.176-.009v.554a.545.545,0,1,0,1.09,0V18.4A2.73,2.73,0,0,0,16.811,16s0,0,0,0l0-2.479a1.982,1.982,0,0,0,1.45-1.886,2.007,2.007,0,0,0-1.817-1.989V8.361A2.37,2.37,0,0,0,14.081,6Zm0,1.09h6.9a1.264,1.264,0,0,1,1.272,1.272V9.7a2.371,2.371,0,0,0-1.817,2.295v1.131a1.973,1.973,0,0,0-.908-.223h-4a1.973,1.973,0,0,0-.908.223V11.994A2.371,2.371,0,0,0,5.906,9.7V8.361A1.264,1.264,0,0,1,7.178,7.09Zm2.18,2.543a.545.545,0,1,0,.545.545A.545.545,0,0,0,9.358,9.633Zm2.543,0a.545.545,0,1,0,.545.545A.545.545,0,0,0,11.9,9.633ZM5,10.723h.274a.545.545,0,0,0,.14,0,1.261,1.261,0,0,1,1.22,1.267v4a.545.545,0,0,0,.545.545h6.9a.545.545,0,0,0,.545-.545v-4a1.261,1.261,0,0,1,1.22-1.267.545.545,0,0,0,.138,0h.276a.908.908,0,0,1,0,1.817.545.545,0,0,0-.544.546l0,2.911a1.626,1.626,0,0,1-1.635,1.629H7.178a1.626,1.626,0,0,1-1.635-1.635V13.084A.545.545,0,0,0,5,12.539a.908.908,0,1,1,0-1.817Zm3.633,3.27h4a.9.9,0,0,1,.908.908v.545H7.723V14.9A.9.9,0,0,1,8.631,13.993Z" transform="translate(-1.042 -2.932)" fill="#4e5a6b"/>
    </g>
  </g>
</svg>

)