import { Box, Button, Container, Drawer, Grid, Hidden, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { LightTooltip, useStyles } from "./style";
import { useHistory, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { AlertContext } from "../../contexts";
import { TermsAndCondition } from "../../components/termsAndCondition/termsAndCondition";
import { Label, Password, TextBox } from "../../components";
import { AlertProps, NetWorkCallMethods, RetainLocalStorage } from "../../utils";
import { Routes } from "../../router/routes";
import { EnvTag } from "../../components/envTag";
import { TitleBar } from "../../components/titlebar";
import { CloseButtonSquare } from "../../assets/close_button_square";
import { BackButtonRound } from "../../assets/back_button_round";
import { useState } from "react";
import { useContext } from "react";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { InfoIcon } from "./assets/info_icon";
import { PasswordInfo } from "../../components/passwordInfo";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { getCurrentBuildNumber } from "../../utils/version";

const initial = {
      password: '',
      conformpassword: "",
      email: '',
      error: {
            password: '',
            conformpassword: "",
            email: '',
      }
}

export const SetPasswordPage = ({
      t = () => false,
      size = {},
      term_and_condition_state = {},
      screen_state = {},
      set_screen_state = () => false,
      initital_screen_state = {},
}) => {
      const classes = useStyles({ size });
      const history = useHistory();
      const [data, setData] = useState({ ...initial })
      const [decoded, setDecoded] = useState(null);
      const search = useLocation().search;
      const alert = useContext(AlertContext);
      const welcomePageAuthToken = new URLSearchParams(search).get(
            "welcomeAuthToken"
      );
      const [is_drawer_open, set_is_drawer_open] = React.useState(false);
      const [disable, setDisable] = React.useState(false);
      const [isInfoOpen, setIsInfoOpen] = React.useState(false)
      const [app_version, set_app_version] = useState(null);
      const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);

      const getCurrentBuild = () => {
            return new Promise(async (resolve, reject) => {
                  try {
                        const [temp_app_version] = await Promise.all([getCurrentBuildNumber()]);
                        set_app_version(temp_app_version);
                  } catch (error) {
                        reject(error);
                  }
            });
      }

      // sumbit function
      const handleSubmit = (e) => {
            setDisable(true);
            e.preventDefault()
            if (validate()) {
                  const payload = {
                        password: data?.conformpassword ?? "",
                  };
                  NetworkCall(
                        `${config?.authapi}/auth/updatepassword?token=${welcomePageAuthToken}`,
                        NetWorkCallMethods.post,
                        payload, null, false, false,
                  ).then((response) => {
                        if (response?.status === 201) {
                              alert.setSnack({
                                    ...alert,
                                    open: true,
                                    severity: AlertProps.severity.success,
                                    msg: "Password created successfully",
                              });
                              RetainLocalStorage();
                              history.push(
                                    Routes.login + "?welcomeAuthToken=" + welcomePageAuthToken
                              );
                        }
                  }).catch((err) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some thing went wrong",
                        });
                        setDisable(false);
                  });
            } else {
                  setDisable(false);
                  return false;
            }
      }

      // set state
      const updateState = (key, value) => {
            let error = data?.error;
            error[key] = "";
            setData({ ...data, [key]: value })
      }

      // validation
      const validate = () => {
            let isValid = true;
            let error = data.error;

            const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
            const is_password_strong = is_password_strong_regex.test(data.password)

            if (data?.password?.length === 0) {
                  isValid = false;
                  error.password = "Password is Required";
            } else if (!Boolean(is_password_strong)) {
                  isValid = false;
                  error.password = "Password is not stong";
            }
            if (data?.conformpassword?.length === 0) {
                  isValid = false;
                  error.conformpassword = "Conform Password is Required";
            }
            if (data?.conformpassword !== data?.password) {
                  isValid = false;
                  error.conformpassword = "Password and Conform Password must be same";
            }

            setData({ ...data, error });

            return isValid;
      }


      useEffect(() => {
            getCurrentBuild();

            if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
                  setDecoded(jwt_decode(welcomePageAuthToken));
            }
            // eslint-disable-next-line
      }, []);

      const handleGoBack = () => {
            history.goBack(-1);
      }

      const handleTermsAndConditions = (is_open = false) => {
            if (term_and_condition_state && is_open) {
                  set_is_drawer_open(true);
            } else {
                  set_is_drawer_open(false);
            }
      }

      const handleImageOnLoad = () => {
            setIsBackgroundImageLoading(false);
      }

      const handleImageOnError = () => {
            set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
      }

      return (
            <>
                  {/* Mobile UI */}
                  <Hidden smUp>
                        <Container className={classes.mob_right_section_card} maxWidth="sm">
                              <TitleBar is_back_button title={t("Set Up New Account")} goBack={handleGoBack} />
                              <Grid container className={classes.mob_content}>
                                    <Grid item xs={12} className={classes.mob_body_items}
                                          sx={{ height: size?.height - 160, overflow: "overlay" }}>
                                          <Box className={classes.mob_background}>
                                                {isBackgroundImageLoading && <Skeleton variant="rounded" className={classes.mob_background_image} />}
                                                <img className={classes.mob_background_image}
                                                      style={{ display: isBackgroundImageLoading ? "none" : "block" }}
                                                      src={screen_state?.mob_background} alt=""
                                                      onLoad={handleImageOnLoad}
                                                      onError={handleImageOnError} />
                                          </Box>
                                          <Box height={"16px"} />
                                          <Typography className={classes.web_title}>
                                                {t("Set up your account details below")}
                                          </Typography>
                                          <Box height={"16px"} />
                                          {!Boolean(screen_state?.is_custom_domain) &&
                                                <>
                                                      <Label label={t("Region")} isRequired />
                                                      <Stack className={classes.flag_dropdown}
                                                            direction={"row"} columnGap={"8px"}
                                                            justifyContent={"space-between"} alignItems={"center"}>
                                                            <Stack direction={"row"} columnGap={"8px"}
                                                                  justifyContent={"space-between"} alignItems={"center"}>
                                                                  <img className={classes.region_flag} src={screen_state?.flag} alt="" />
                                                                  <Typography className={classes.region_text}>
                                                                        {screen_state?.region}
                                                                  </Typography>
                                                            </Stack>
                                                            <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                                                      </Stack>
                                                      <Box height={"24px"} />
                                                </>
                                          }
                                          <TextBox
                                                value={decoded?.email_id?.length > 0 ? decoded?.email_id : data?.email}
                                                onChange={(e) => updateState("email", e.target.value)}
                                                label={'Email Id'}
                                                placeholder={'Enter Email Id'}
                                                isError={data?.error?.email?.length > 0}
                                                errorMessage={data?.error?.email}
                                                isrequired
                                                type="email" />
                                          <Box height={"24px"} />
                                          <Password
                                                value={data?.password ?? ""}
                                                onChange={(e) => updateState("password", e.target.value)}
                                                label={'Password'}
                                                placeholder={'Enter Password'}
                                                isError={data?.error?.password?.length > 0}
                                                errorMessage={data?.error?.password}
                                                isrequired />
                                          <Box height={"24px"} />
                                          <Password
                                                value={data?.conformpassword ?? ""}
                                                onChange={(e) => updateState("conformpassword", e.target.value)}
                                                label={'Confirm Password'}
                                                placeholder={'Enter Confirm Password'}
                                                isError={data?.error?.conformpassword?.length > 0}
                                                errorMessage={data?.error?.conformpassword}
                                                isrequired />
                                          <Box height={"10px"} />
                                          <Box display={"flex"} justifyContent={"end"}>
                                                <LightTooltip title={<PasswordInfo />} placement="left" open={isInfoOpen} arrow>
                                                      <Box
                                                            onClick={() => setIsInfoOpen(!isInfoOpen)}
                                                            onMouseEnter={() => setIsInfoOpen(true)}
                                                            onMouseLeave={() => setIsInfoOpen(false)}>
                                                            <InfoIcon />
                                                      </Box>
                                                </LightTooltip>
                                          </Box>
                                    </Grid>
                                    <Grid item xs={12} alignSelf={"self-end"} className={classes.mob_bottom_items}>
                                          <Stack direction={"column"} alignItems={"start"}>
                                                <Button className={classes.mob_set_up_new_button} disabled={disable}
                                                      onClick={handleSubmit}>
                                                      <Typography className={classes.mob_set_up_new_button_text}>
                                                            {t("Set Password")}
                                                      </Typography>
                                                </Button>
                                                <Box height={"16px"} />
                                                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                                                      justifyContent={"space-between"} alignItems={"center"}>
                                                      {/* <Typography className={classes.terms_of_use_word}
                                                            onClick={() => handleTermsAndConditions(true)}>
                                                            {t("Terms of Use")}
                                                      </Typography> */}
                                                      <img className={classes.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                                                      {app_version &&
                                                            <Typography className={classes.version_no}>
                                                                  {`V ${app_version}`}
                                                            </Typography>}
                                                </Stack>
                                          </Stack>
                                    </Grid>
                              </Grid>
                        </Container>
                  </Hidden>

                  {/* Web & Tablet UI */}
                  <Hidden smDown>
                        <Container className={classes.web_right_section_card} maxWidth="sm">
                              <Grid container className={classes.web_content}>
                                    <EnvTag top={16} right={16} />
                                    <Grid item xs={12}>
                                          <Stack direction={"column"} alignItems={"start"} display={"grid"}>
                                                <Box className={classes.web_back_button}
                                                      onClick={() => history.goBack(-1)}>
                                                      <BackButtonRound />
                                                </Box>
                                                <Box height={"16px"} />
                                                <img className={classes.web_logo} src={screen_state?.logo} alt={t("logo")} />
                                                <Box height={"16px"} />
                                                <Typography className={classes.web_title}>
                                                      {t("Set up your account details below")}
                                                </Typography>
                                                <Box height={"24px"} />
                                                <Label label={t("Region")} isRequired />
                                                <Stack className={classes.flag_dropdown}
                                                      direction={"row"} columnGap={"8px"}
                                                      justifyContent={"space-between"} alignItems={"center"}>
                                                      <Stack direction={"row"} columnGap={"8px"}
                                                            justifyContent={"space-between"} alignItems={"center"}>
                                                            <img className={classes.region_flag} src={screen_state?.flag} alt="" />
                                                            <Typography className={classes.region_text}>
                                                                  {screen_state?.region}
                                                            </Typography>
                                                      </Stack>
                                                      <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                                                </Stack>
                                                <Box height={"24px"} />
                                                <TextBox
                                                      value={decoded?.email_id?.length > 0 ? decoded?.email_id : data?.email}
                                                      onChange={(e) => updateState("email", e.target.value)}
                                                      label={'Email ID'}
                                                      placeholder={'Enter Email ID'}
                                                      isError={data?.error?.email?.length > 0}
                                                      errorMessage={data?.error?.email}
                                                      isrequired
                                                      type="email" />
                                                <Box height={"24px"} />
                                                <Password
                                                      value={data?.password ?? ""}
                                                      onChange={(e) => updateState("password", e.target.value)}
                                                      label={'Password'}
                                                      placeholder={'Enter Password'}
                                                      isError={data?.error?.password?.length > 0}
                                                      errorMessage={data?.error?.password}
                                                      isrequired />
                                                <Box height={"24px"} />
                                                <Password
                                                      value={data?.conformpassword ?? ""}
                                                      onChange={(e) => updateState("conformpassword", e.target.value)}
                                                      label={'Confirm Password'}
                                                      placeholder={'Enter Confirm Password'}
                                                      isError={data?.error?.conformpassword?.length > 0}
                                                      errorMessage={data?.error?.conformpassword}
                                                      isrequired />
                                                <Box height={"10px"} />
                                                <Box display={"flex"} justifyContent={"end"}>
                                                      <LightTooltip title={<PasswordInfo />} placement="left" open={isInfoOpen} arrow>
                                                            <Box
                                                                  onClick={() => setIsInfoOpen(!isInfoOpen)}
                                                                  onMouseEnter={() => setIsInfoOpen(true)}
                                                                  onMouseLeave={() => setIsInfoOpen(false)}>
                                                                  <InfoIcon />
                                                            </Box>
                                                      </LightTooltip>
                                                </Box>
                                                <Box height={"24px"} />
                                          </Stack>
                                    </Grid>
                                    <Grid item xs={12} alignSelf={"self-end"}>
                                          <Stack direction={"column"} alignItems={"start"} display={"grid"}>
                                                <Button className={classes.web_login_button} disabled={disable}
                                                      onClick={handleSubmit}>
                                                      <Typography className={classes.web_login_button_text}>
                                                            {t("Set Password")}
                                                      </Typography>
                                                </Button>
                                                <Box height={"24px"} />
                                                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                                                      justifyContent={"space-between"} alignItems={"center"}>
                                                      {app_version &&
                                                            <Typography className={classes.version_no}>
                                                                  {`V ${app_version}`}
                                                            </Typography>
                                                      }
                                                      {/* <Typography className={classes.terms_of_use_word}
                                                            onClick={() => handleTermsAndConditions(true)}>
                                                            {t("Terms of Use")}
                                                      </Typography> */}
                                                      <img className={classes.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                                                </Stack>
                                          </Stack>
                                    </Grid>
                              </Grid>
                        </Container>
                  </Hidden >

                  {/* Terms & Conditions */}
                  < Drawer
                        anchor={size?.width > 599 ? "right" : "bottom"
                        }
                        className={classes.drawer}
                        open={is_drawer_open}
                        onClose={() => handleTermsAndConditions(false)}>
                        <Grid container>
                              <Grid className={classes.drawer_header_grid}
                                    item xs={12}>
                                    <Box height={"32px"} onClick={() => handleTermsAndConditions(false)}>
                                          <CloseButtonSquare />
                                    </Box>
                                    {size?.width > 599 &&
                                          <Typography className={classes.drawer_header_text}>
                                                {t("Terms Of Use")}
                                          </Typography>
                                    }
                              </Grid>
                              <Grid item xs={12}>
                                    <Box className={classes.drawer_content_box}>
                                          <TermsAndCondition termsCondition={term_and_condition_state} t={t} />
                                    </Box>
                              </Grid>
                        </Grid>
                  </Drawer >
            </>
      );
}