import React from "react";
import { withNavBars } from "../../HOCs";
import { Announsment } from "./announsment";
class AnnounsmentParent extends React.Component {
  render() {
    return (
        <Announsment />

    );
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(AnnounsmentParent,props);