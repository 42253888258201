import { Button, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import DeleteIcon from "../../assets/delete"
import { InspectionMasterStyles } from "./style"


export const Delpopup = ({ t, handleDelete = () => false, handleClose = () => false }) => {

    const classes = InspectionMasterStyles()

    return (
        <>
            <Box className={classes.delBox} p={3}>
                <Box className={classes.delIcon}>
                    <DeleteIcon className={classes.delIconSvg} />
                </Box>
                <Typography className={classes.delTxt} mt={1}>{t("Are you sure you want to delete this Item ?")}</Typography>
                <Stack direction="row" mt={2} spacing={2}>
                    <Button variant="outlined" className={classes.delBtn_outlined} onClick={handleClose}>{t("Cancel")}</Button>
                    <Button variant="contained" className={classes.delBtn} onClick={handleDelete}>{t("Yes, Delete")}</Button>
                </Stack>
            </Box>
        </>
    )
}