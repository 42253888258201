import React from "react"

export const ServiceIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_110226" data-name="Group 110226" transform="translate(-184 -328)">
    <rect id="Rectangle_55241" data-name="Rectangle 55241" width="32" height="32" rx="2" transform="translate(184 328)" fill="#ffd6fa"/>
    <g id="Group_111487" data-name="Group 111487" transform="translate(0 -1)">
      <g id="Rectangle_56608" data-name="Rectangle 56608" transform="translate(188 333)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-service" d="M17.761,10.239a4.74,4.74,0,0,0-1.732.73c-.756.392-1.454.765-1.9,1.037a2.279,2.279,0,0,1-2.225,2.8H9.048a.571.571,0,1,1,0-1.143h2.857a1.138,1.138,0,0,0,.751-2,1.7,1.7,0,0,0-.941-.288H9.81c-.011,0-.021,0-.032,0a8.313,8.313,0,0,1-1.911-.213A5.565,5.565,0,0,0,6.506,11c-2.793,0-4.388,3.429-4.455,3.575a.571.571,0,0,0,.06.576l2.667,3.617A.568.568,0,0,0,5.237,19a.573.573,0,0,0,.554-.427,1.436,1.436,0,0,1,1.352-1.1,18.557,18.557,0,0,1,2.257.189,18.792,18.792,0,0,0,2.315.192c1.053,0,1.811-.807,4-3.048,2.782-2.846,3.048-3.1,3.048-3.659a.958.958,0,0,0-1-.912M14.454,7.054a.543.543,0,0,1,0-.869l.234-.175a.358.358,0,0,0,.135-.379,3.609,3.609,0,0,0-.888-1.53.357.357,0,0,0-.4-.072l-.265.113a.543.543,0,0,1-.752-.434l-.035-.29a.357.357,0,0,0-.26-.306,3.594,3.594,0,0,0-1.77,0,.356.356,0,0,0-.26.306l-.035.29a.543.543,0,0,1-.752.434l-.265-.113a.357.357,0,0,0-.4.072A3.609,3.609,0,0,0,7.86,5.63a.357.357,0,0,0,.135.379l.234.175a.543.543,0,0,1,0,.869l-.234.175a.358.358,0,0,0-.135.379,3.609,3.609,0,0,0,.888,1.53.357.357,0,0,0,.4.072L9.408,9.1a.543.543,0,0,1,.752.434l.035.29a.358.358,0,0,0,.26.306,3.6,3.6,0,0,0,1.77,0,.356.356,0,0,0,.26-.306l.035-.29a.543.543,0,0,1,.752-.434l.265.113a.357.357,0,0,0,.4-.072,3.609,3.609,0,0,0,.888-1.53.357.357,0,0,0-.135-.379Zm-3.113.518a.952.952,0,1,1,.952-.952A.952.952,0,0,1,11.341,7.572Z" transform="translate(189 334)" fill="#a5499a"/>
    </g>
  </g>
</svg>
)