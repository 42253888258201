import * as React from "react"

const VendorSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 102949" transform="translate(-11563 -17227)">
      <circle
        data-name="Ellipse 129452"
        cx={24}
        cy={24}
        r={24}
        transform="translate(11563 17227)"
        fill="#f5f0f9"
      />
      <g data-name="Group 102946">
        <path
          data-name="Rectangle 54908"
          fill="none"
          d="M11575 17239h24v24h-24z"
        />
        <path
          data-name="icons8-caretaker (1)"
          d="M11585.4 17243a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm5.6 8.813a1.876 1.876 0 0 0-1.161.39l-2.125 1.677a1.867 1.867 0 0 0-.714 1.475v2.994a1.452 1.452 0 0 0 1.45 1.451h5.1a1.452 1.452 0 0 0 1.45-1.452v-2.993a1.866 1.866 0 0 0-.714-1.474l-2.125-1.681a1.875 1.875 0 0 0-1.161-.387Zm-10.2.787a1.8 1.8 0 0 0-1.8 1.8v.48c0 2.31 2.811 4.12 6.4 4.12.3 0 .6-.018.886-.043a2.233 2.233 0 0 1-.086-.609v-2.993a2.663 2.663 0 0 1 1.019-2.1l.827-.652Zm8.4 2.8h.4a.6.6 0 0 1 .6.6v1.2a.6.6 0 0 1-.6.6h-.4a.6.6 0 0 1-.6-.6v-1.2a.6.6 0 0 1 .6-.6Zm3.2 0h.4a.6.6 0 0 1 .6.6v1.2a.6.6 0 0 1-.6.6h-.4a.6.6 0 0 1-.6-.6v-1.2a.6.6 0 0 1 .6-.6Z"
          fill="#8f59c5"
        />
      </g>
    </g>
  </svg>
)

export default VendorSvg
