import dayjs from 'dayjs';

export const Path = [
    "company_name",
    "shift_name",
    "starting_time",
    "ending_time",
    "break_period",
    "total_working_hours",
    "status"
]

export const Heading = (t) => [
    { title: "", field: "icon" },
    { title: t("company_name"), field: "company_name" },
    { title: t("shift_name"), field: "shift_name" },
    { title: t("starting_time"), field: "starting_time" },
    { title: t("ending_time"), field: "ending_time" },
    { title: t("break_period"), field: "break_period" },
    { title: t("total_working_hours"), field: "total_working_hours" },
    { title: t("status"), field: "status" },
]

export const Type = [
    { type: ["more"], icon: "icon" },
    { type: ["text"], name: "company_name" },
    { type: ["text"], name: "shift_name" },
    { type: ["text"], name: "starting_time" },
    { type: ["text"], name: "ending_time" },
    { type: ["text"], name: "break_period" },
    { type: ["text"], name: "total_working_hours" },
    { type: ["status"], name: "status" },
]

export const initialDialogState = {
    formType: "add",
    id: "",
    shift_name: "",
    shift_starting_time: dayjs(new Date()),
    shift_ending_time: dayjs(new Date()),
    break_starting_time: null,
    break_ending_time: null,
    description: "",
    starting_time: "",
    ending_time: "",
    break_period: "",
    total_working_hours: "",
    is_active: true,
    week_off_days: [],
    error: {
        shift_name: "",
        shift_starting_time: "",
        shift_ending_time: "",
        break_starting_time: "",
        break_ending_time: "",
        description: "",
        week_off_days: [],
    }
}