import * as React from "react"

export const PrinterImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={15.2} {...props}>
    <path
      d="M5 0a1.809 1.809 0 0 0-1.8 1.8v.6h-.6A2.609 2.609 0 0 0 0 5v5.6a1.809 1.809 0 0 0 1.8 1.8h1.4v1A1.809 1.809 0 0 0 5 15.2h6a1.809 1.809 0 0 0 1.8-1.8v-1h1.4a1.809 1.809 0 0 0 1.8-1.8V5a2.609 2.609 0 0 0-2.6-2.6h-.6v-.6A1.809 1.809 0 0 0 11 0Zm0 1.2h6a.591.591 0 0 1 .6.6v.6H4.4v-.6a.591.591 0 0 1 .6-.6ZM2.6 3.6h10.8A1.391 1.391 0 0 1 14.8 5v5.6a.591.591 0 0 1-.6.6h-1.4V9.8A1.809 1.809 0 0 0 11 8H5a1.809 1.809 0 0 0-1.8 1.8v1.4H1.8a.591.591 0 0 1-.6-.6V5a1.391 1.391 0 0 1 1.4-1.4ZM5 9.2h6a.591.591 0 0 1 .6.6v3.6a.591.591 0 0 1-.6.6H5a.591.591 0 0 1-.6-.6V9.8a.591.591 0 0 1 .6-.6Z"
      fill="#091b29"
    />
  </svg>
)
