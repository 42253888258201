import React from "react";
import { withNavBars } from "../../HOCs";
import PropertyView from "./propertyView";

class PropertyViewingParent extends React.Component {
    render() {
        return <PropertyView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(PropertyViewingParent, props);
