import * as React from "react"

export const Sqft = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={13.756} height={12} {...props}>
        <g data-name="SQ FT">
            <path
                data-name="Rectangle 45738"
                fill="#ced3dd"
                d="M7.071 4.286h6V6h-6z"
            />
            <path
                data-name="Rectangle 45739"
                fill="#ced3dd"
                d="M8.786 2.571h4.286v1.714H8.786z"
            />
            <path
                data-name="Rectangle 45740"
                fill="#ced3dd"
                d="M11.357.857h1.714v2.571h-1.714z"
            />
            <path
                data-name="Rectangle 45741"
                fill="#ced3dd"
                d="M1.071 9.428h6v1.714h-6z"
            />
            <path
                data-name="Rectangle 45742"
                fill="#ced3dd"
                d="M1.071 7.714H4.5v2.571H1.071z"
            />
            <path
                data-name="Rectangle 45743"
                fill="#ced3dd"
                d="M1.071 6h1.714v5.143H1.071z"
            />
            <path
                d="M.521-.001A.529.529 0 0 0 0 .536v10.585a.89.89 0 0 0 .882.882h5.643a.89.89 0 0 0 .882-.882V9.707a.89.89 0 0 0-.882-.882H5.291v-.882a.89.89 0 0 0-.882-.882H3.174v-.882a.89.89 0 0 0-.882-.882H1.058V.536a.529.529 0 0 0-.537-.537Zm2.125.008a.529.529 0 1 0 .529.529.529.529 0 0 0-.53-.529Zm2.116 0a.529.529 0 1 0 .529.529.529.529 0 0 0-.529-.529Zm2.116 0a.529.529 0 1 0 .529.529.529.529 0 0 0-.529-.529Zm2.116 0a.529.529 0 1 0 .529.529.529.529 0 0 0-.529-.529Zm2.469 0a.89.89 0 0 0-.882.882v.882H9.347a.89.89 0 0 0-.882.882v.882H7.231a.89.89 0 0 0-.882.882v1.411a.89.89 0 0 0 .882.882H12.7v4.762a.529.529 0 1 0 1.058 0V6.179a.529.529 0 0 0-.025-.158.854.854 0 0 0 .023-.192V.889a.89.89 0 0 0-.882-.882Zm.176 1.058H12.7V5.65H7.407V4.592h1.587a.529.529 0 0 0 .529-.529V2.829h1.587a.529.529 0 0 0 .529-.529ZM1.058 6.356h1.058v1.235a.529.529 0 0 0 .529.529h1.587v1.234a.529.529 0 0 0 .529.529h1.587v1.058h-5.29Zm7.936 4.585a.529.529 0 1 0 .529.529.529.529 0 0 0-.529-.529Zm2.116 0a.529.529 0 1 0 .529.529.529.529 0 0 0-.529-.529Z"
                fill="#4e5a6b"
            />
        </g>
    </svg>
)


