import * as React from "react"

export const Settings = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14.607} height={15} {...props}>
    <path
      data-name="Path 93425"
      d="m14.354 9.144-1.379-1.078a5.428 5.428 0 0 0 .032-.566 5.264 5.264 0 0 0-.032-.566l1.38-1.079a.676.676 0 0 0 .163-.861l-1.433-2.481a.665.665 0 0 0-.829-.3l-1.627.653a5.578 5.578 0 0 0-.977-.567L9.404.575A.664.664 0 0 0 8.742 0H5.867a.662.662 0 0 0-.661.57L4.957 2.3a5.75 5.75 0 0 0-.975.567l-1.631-.653a.678.678 0 0 0-.823.293L.09 4.991a.672.672 0 0 0 .163.866l1.381 1.077a4.945 4.945 0 0 0 0 1.132L.254 9.145a.676.676 0 0 0-.163.861l1.434 2.481a.663.663 0 0 0 .829.3l1.627-.653a5.627 5.627 0 0 0 .976.567l.247 1.725a.663.663 0 0 0 .662.576h2.875a.663.663 0 0 0 .662-.57l.248-1.731a5.777 5.777 0 0 0 .975-.568l1.631.655a.672.672 0 0 0 .245.046.66.66 0 0 0 .578-.339L14.522 10a.675.675 0 0 0-.168-.856ZM7.304 10a2.5 2.5 0 1 1 2.5-2.5 2.5 2.5 0 0 1-2.5 2.5Z"
      fill={props?.color}
    />
  </svg>
)
