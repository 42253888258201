import { Badge, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import { withNamespaces } from "react-i18next";
import { SearchFilter } from "../../../components";
import { useStyles } from "../style";

const SearchFilters = ({
    onClick = () => false,
    searchText = "",
    handleSearch = () => false,
    openfilter = () => false,
    filterData = {},
    permissions = {},
    t

}) => {
    const classes = useStyles()
    return (
        <Grid container justifyContent="space-between">
            <Grid item xs={4}>
                {/*search */}
                <SearchFilter
                    handleChange={handleSearch}
                    value={searchText}
                    placeholder={"Search Name"}
                    customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }}
                />
            </Grid>
            <Grid item xs={8} alignContent="flex-end">
                <Stack

                    divider={<Divider orientation="vertical" flexItem sx={{ marginInline: "16px" }} />}
                    justifyContent="flex-end"
                    alignItems="center"
                    direction="row">
                    {/*add btn */}
                    {
                        filterData?.category?.length > 0 || filterData?.inspection_item_type?.length > 0 ?
                            <Badge variant="dot" color="primary">
                                <IconButton className={classes.iconButton} onClick={openfilter}>
                                    <img src="/images/filter.svg" alt="filter" />
                                </IconButton>
                            </Badge>
                            :
                            <IconButton className={classes.iconButton} onClick={openfilter}>
                                <img src="/images/filter.svg" alt="filter" />
                            </IconButton>
                    }
                    {permissions?.create &&
                        <Button onClick={onClick} className={classes.addIcon} variant="contained">{t("add")}</Button>
                    }

                </Stack>
            </Grid>
        </Grid>
    )
}
export default withNamespaces("inspectionItemmaster")(SearchFilters); 