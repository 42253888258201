import * as React from "react"

export const Client = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} {...props}>
        <path
            d="M9.9 0a6.19 6.19 0 1 0 6.19 6.19A6.19 6.19 0 0 0 9.9 0Zm8.667 13.681a4.967 4.967 0 0 0-4.952 4.952 4.8 4.8 0 0 0 .867 2.786l-3.034 3.034a.954.954 0 0 0-.062 1.3 1.059 1.059 0 0 0 1.361 0l3.034-3.034a4.8 4.8 0 0 0 2.786.867 4.952 4.952 0 0 0 0-9.9ZM2.786 14.857A2.774 2.774 0 0 0 0 17.643v.929c0 3.467 4.333 6.19 9.9 6.19a3.331 3.331 0 0 1 .619-1.238l2.415-2.415a5.852 5.852 0 0 1-.557-2.538 6.366 6.366 0 0 1 1.238-3.714Zm15.786.619a3.1 3.1 0 1 1-3.1 3.1 3.065 3.065 0 0 1 3.099-3.1Z"
            fill={props?.color}
        />
    </svg>
)
