import { Grid, Typography } from "@mui/material";
import React from 'react';
import { residnetTableViewStyles } from "./style";

export const ResidnetDetail = (props) => {
    const classes = residnetTableViewStyles()
    return (
        <div style={{ padding: "4px" }}>
            {/* first card */}
            <Grid container className={classes.card}>
                <Grid item md={2} lg={2} className={classes.imgDiv}>
                    <img src={props?.assets?.[0]?.url ?? "/images/imagesproperty.svg"} alt="" className={classes.unitImg} />
                    <Typography className={classes.imgTag}>&nbsp;&nbsp;{props?.data?.user_no}&nbsp;&nbsp;</Typography>
                </Grid>
                <Grid item md={10} lg={10} className={classes.imgDiv2}>
                    <Typography className={classes.title} marginBottom={"14px"}>RESIDENT DETAILS</Typography>
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography className={classes.heading}>Resident Name</Typography>
                            <Typography className={classes.sub}>{`${props?.data?.first_name} ${props?.data?.last_name ? props?.data?.last_name : " "}`}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Mobile</Typography>
                            <Typography className={classes.sub}>{`${props?.data?.mobile_no_country_code} - ${props?.data?.mobile_no}`}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Alternative Mobile</Typography>
                            {props?.data?.alternative_mobile ?
                                <Typography className={classes.sub}>{`${props?.data?.alternative_mobile_country_code} - ${props?.data?.alternative_mobile}`}</Typography>
                                : " - "}
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Telephone</Typography>
                            <Typography className={classes.sub}>{`${props?.data?.mobile_no_country_code} - ${props?.data?.mobile_no}`}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={classes.heading}>Fax</Typography>
                            <Typography className={classes.sub}>{props?.data?.fax_no ? props?.data?.fax_no : " - "}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Email Id</Typography>
                            <Typography className={classes.sub}>{props?.data?.email ? props?.data?.email : " - "} </Typography>
                        </Grid>

                    </Grid>

                    <Grid container marginTop={"14px"}>
                        <Grid item xs={3}>
                            <Typography className={classes.heading}>Company Name</Typography>
                            <Typography className={classes.sub}>{props?.data?.company_name ? props?.data?.company_name : "- "}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Facebook</Typography>
                            <Typography className={classes.sub}>{props?.data?.facebook ?? " - "}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>Twitter</Typography>
                            <Typography className={classes.sub}>{props?.data?.twitter ?? "- "}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.heading}>LinkedIn</Typography>
                            <Typography className={classes.sub}>{props?.data?.linkedin ?? "-"}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.heading}>Address</Typography>
                            {props?.data?.street_1 === null ? " - " :
                                <Typography className={classes.sub}>
                                    {props?.data?.street_1}{", "}
                                    {props?.data?.street_2}{", "}
                                    {props?.data?.street_3}{", "}
                                    {props?.data?.district}{", "}
                                    {props?.data?.state}{", "}
                                    {props?.data?.country}{", "}
                                    {props?.data?.zipcode}{", "}</Typography>}
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item md={0} lg={4.5} /> */}
            </Grid>

        </div>
    )
}