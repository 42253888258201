import * as React from "react"

export const Complete = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <g data-name="Group 105404" transform="translate(-203.5 -498.5)">
      <rect
        data-name="Rectangle 55504"
        width={64}
        height={64}
        rx={32}
        transform="translate(203.5 498.5)"
        fill="#eef9ee"
      />
      <path
        data-name="icons8-ok (2)"
        d="M235.5 518.5a12 12 0 1 0 12 12 12.014 12.014 0 0 0-12-12Zm5.137 9.937-6 6a.9.9 0 0 1-1.273 0l-3-3a.9.9 0 1 1 1.273-1.273l2.363 2.363 5.363-5.363a.9.9 0 0 1 1.274 1.273Z"
        fill="#5ac782"
      />
    </g>
  </svg>
)
