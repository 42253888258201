import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, AvatarGroup, Box, Divider, Drawer, Grid, Stack, Typography } from "@mui/material";
import React from 'react';
import { CloseIconWithBG } from '../../assets';
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import { NetWorkCallMethods, useWindowDimensions } from '../../utils';
import { AlertDialog } from "../dialog";
import { Slider } from '../slider';
import { CheckListDetails } from './CheckListDetails';
import { ItemDetailsCard } from './itemDetails';
import { AccordianStyle } from "./styles";
import { LoadingSection } from '../loadingSection';
export const Card = ({ t = () => false, state = {}, item = [], unitDetails = {}, MoveIn = true, MoveOut = false, utility = false, setNotesData = () => false, notesData = "", onUpdateNotes = () => false, status = "", type = "", isDisableBtn = false }) => {
    const [expend, setExpend] = React.useState(false)
    const size = useWindowDimensions()
    const [loading, setLoading] = React.useState(true)
    const [isView, setIsView] = React.useState({
        bool: false,
        is_unit: false
    })
    const classes = AccordianStyle();
    const [open, setOpen] = React.useState({
        bool: false,
        data: []
    })
    const [itemMappingDetails, setItemMappingdetails] = React.useState({})
    const [instructions, setInstructions] = React.useState([])
    //onclick images
    const onClickImage = (data) => {
        setOpen({
            bool: !open?.bool,
            data: data ?? []
        })
        setExpend(true)

    }
    const getItemDetails = (is_unit = false, val) => {
        const payload = {
            "id": state?.request_id,
            "agreement_inspection_id": state?.id,
            "inspection_item_map": is_unit ? null : val?.inspection_item_map,
            "activity_id": is_unit ? null : val?.id
        }
        NetworkCall(
            `${config.api_url}/agreement_inspection/activity_items`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setLoading(false)
            setItemMappingdetails(res?.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const handleClose = (val) => {
        setIsView({ ...isView, bool: false, selectedItem: {}, selectedUnit: {}, is_unit: false })
    }
    const handleView = (is_unit = false, val) => {
        setIsView({ ...isView, bool: true, selectedItem: val, is_unit: is_unit })
        setInstructions([])
        setItemMappingdetails([])
        getItemDetails(is_unit, val)
    }
    return (
        <Box>

            {!utility &&
                <>
                    <Grid sx={{ boxShadow: expend ? "0px 8px 24px #0717411F" : "auto" }} container className={classes.cardRoot} onClick={() => handleView(true, null)} alignItems="center">
                        <Grid item sm={MoveOut === true ? 4 : 6} md={4} lg={4}>

                            <Stack direction="row" spacing={1} alignItems='center'>
                                {unitDetails?.logo !== null ?
                                    <Avatar src={unitDetails?.logo} className={classes.avatar} />
                                    :
                                    <img src={"/images/Group 98362.svg"} alt="product_image" className={classes.avatar} />
                                }
                                <Box>
                                    <Typography className={classes.title}>{unitDetails?.unit_name}</Typography>
                                    <Typography className={classes.noof}>{unitDetails?.unit_no}</Typography>
                                </Box>

                                {/* <Typography className={classes.noof}>({item?.noOfItems} Nos)</Typography> */}
                            </Stack>

                        </Grid>
                        <Grid item sm={MoveOut === true ? 4 : 3} md={4} lg={4}>

                            {
                                unitDetails?.is_move_in ? <CheckCircleIcon color="success" /> : "-"
                            }
                        </Grid>
                        {MoveOut &&
                            <Grid item sm={MoveOut === true ? 2 : 3} md={MoveOut === true ? 2 : 3} lg={2} sx={{ display: "flex", justifyContent: "center" }}>

                                {
                                    unitDetails?.is_move_out ? <CheckCircleIcon color="success" /> : "-"
                                }
                            </Grid>
                        }
                        <Grid item sm={MoveOut === true ? 2 : 3} md={MoveOut === true ? 2 : 4} lg={MoveOut === true ? 2 : 4}>

                            <Stack direction="row" alignItems='center' justifyContent="space-between">
                                {/* <CheckCircleIcon color="success" /> */}
                                <Box width="100px" />
                                <KeyboardArrowDownIcon />
                            </Stack>
                        </Grid>
                    </Grid >
                    {item?.length > 0 && item?.map((val) => {
                        return (
                            <Grid sx={{ boxShadow: expend ? "0px 8px 24px #0717411F" : "auto" }} container className={classes.cardRoot} onClick={() => handleView(false, val)} alignItems="center">
                                <Grid item sm={MoveOut === true ? 4 : 6} md={4} lg={4}>

                                    <Stack direction="row" spacing={1} alignItems='center'>
                                        {val?.assets?.length > 0 ?
                                            <Avatar src={val?.assets?.[0]?.url} className={classes.avatar} />
                                            :
                                            <img src={"/images/Group 98362.svg"} alt="product_image" className={classes.avatar} />
                                        }
                                        <Box>
                                            <Typography className={classes.title}>{val?.name}</Typography>
                                            <Typography className={classes.noof}>{val?.manufacturer}</Typography>
                                        </Box>

                                        {/* <Typography className={classes.noof}>({item?.noOfItems} Nos)</Typography> */}
                                    </Stack>

                                </Grid>
                                <Grid item sm={MoveOut === true ? 4 : 3} md={4} lg={4}>

                                    {
                                        val?.is_move_in ? <CheckCircleIcon color="success" /> : "-"
                                    }
                                </Grid>
                                {MoveOut &&
                                    <Grid item sm={MoveOut === true ? 2 : 3} md={MoveOut === true ? 2 : 3} lg={2} sx={{ display: "flex", justifyContent: "center" }}>

                                        {
                                            val?.is_move_out ? <CheckCircleIcon color="success" /> : "-"
                                        }
                                    </Grid>
                                }
                                <Grid item sm={MoveOut === true ? 2 : 3} md={MoveOut === true ? 2 : 4} lg={MoveOut === true ? 2 : 4}>

                                    <Stack direction="row" alignItems='center' justifyContent="space-between">
                                        {/* <CheckCircleIcon color="success" /> */}
                                        <Box width="100px" />
                                        <KeyboardArrowDownIcon />
                                    </Stack>
                                </Grid>

                            </Grid >
                        )
                    })}

                </>
            }
            {
                utility && item?.length > 0 && item?.map((val) => {
                    return (
                        <Grid sx={{ boxShadow: expend ? "0px 8px 24px #0717411F" : "auto" }} container className={classes.cardRoot} onClick={() => setExpend(!expend)} alignItems="center">
                            <Grid item sm={6} md={3} lg={4}>
                                <Stack direction="row" spacing={1} alignItems='center'>
                                    <Typography className={classes.title}>{val?.utility_name}</Typography>
                                    {/* <Typography className={classes.noof}>({item?.noOfItems} Nos)</Typography> */}
                                </Stack>

                            </Grid>
                            <Grid item sm={3} md={3} lg={4}>
                                {
                                    val?.meter_serial_no?.length > 0 ? <CheckCircleIcon color="success" /> : "-"
                                }
                            </Grid>
                            <Grid item sm={3} md={3} lg={MoveOut === true ? 2 : 4}>
                                <Stack direction="row" alignItems='center' justifyContent="space-between">
                                    {/* <CheckCircleIcon color="success" /> */}
                                    <Box width="100px" />
                                    <KeyboardArrowDownIcon />
                                </Stack>
                            </Grid>
                            {
                                expend &&
                                <Grid item xs={12} >

                                    <Grid container marginTop="16px">


                                        <Grid item xs={4}>

                                        </Grid>
                                        <Grid item xs={4}>


                                            <AvatarGroup onClick={() => onClickImage(val?.assets)} className={classes.avatarGroup} max={4} sx={{ float: "left" }}>
                                                {
                                                    val?.assets?.map((src) => {
                                                        return <Avatar sx={{ width: 34, height: 34 }} alt="" src={src} />
                                                    })
                                                }
                                            </AvatarGroup>
                                        </Grid>


                                    </Grid>
                                    <Grid container marginTop="16px">


                                        <Grid item xs={4}>
                                            <Typography className={classes.subtitle1}>Meter Serial Number</Typography>
                                            <Typography className={classes.subtitle1}>Reading</Typography>
                                        </Grid>
                                        <Grid item xs={4}>



                                            <Typography className={classes.subtitle2}>{val?.meter_serial_no ?? "-"}</Typography>

                                            <Typography className={classes.subtitle2}>{val?.present_reading ?? "-"}</Typography>
                                        </Grid>


                                    </Grid>

                                </Grid>
                            }
                        </Grid>
                    )
                })
            }




            <AlertDialog
                open={open?.bool}
                header="Assets"
                md={true}
                onClose={() => onClickImage()}
                component={
                    <Box p={1}>
                        <Slider backgroundColor="black" assets={open?.data} imageOnly={true} />
                    </Box>
                }
            />
            <Drawer
                anchor={"right"}
                open={isView?.bool}
                onClose={handleClose}
            >
                <Stack direction="row" spacing={2} p={1.5} alignItems="center" sx={{backgroundColor:"#F2F4F7"}}>
                    <Box onClick={handleClose} style={{ cursor: "pointer" }}><CloseIconWithBG /></Box>
                    <Typography className={classes.drawerHeader}>{t("Item Details")}</Typography>
                </Stack>
                <Divider />
                <Box style={{ width: size?.width - 300 }}>
                    {loading ?
                        <LoadingSection top="20vh" message={"Fetching Details"} />
                        :
                        <Grid container spacing={0}>
                            <Grid item xs={6} sm={3} md={3} lg={4}>
                                <Box className={classes.applyborder} p={1}>
                                    <ItemDetailsCard unitDetails={unitDetails} item={itemMappingDetails?.inspection_item_mapping} checkListGroupDetails={instructions} t={t} is_unit={isView?.is_unit} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={9} md={9} lg={8}>
                                <CheckListDetails instructions={instructions} state={{ ...state, inspection_item_map: isView?.selectedItem?.inspection_item_map }} is_unit={isView?.is_unit} item={itemMappingDetails} setInstructions={setInstructions} t={t} MoveOut={MoveOut} setNotesData={setNotesData} notesData={notesData} onUpdateNotes={onUpdateNotes} status={status} type={type} isDisableBtn={isDisableBtn} setLoading={setLoading} />
                            </Grid>
                        </Grid>
}
                </Box>
            </Drawer>
        </Box >
    )
}