export const Marketplace = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g transform="translate(3 2.75)">
            <path data-name="Rectangle 52035" fill="none" d="M-3-2.75h30v30H-3z" />
            <path
                data-name="Path 94752"
                d="M14.77 8.274a.276.276 0 0 1-.263.195h-.644v.548h.644a.276.276 0 0 1 .263.195.651.651 0 0 0 .047.112.274.274 0 0 1-.049.321l-.2.195h1.485a1.1 1.1 0 1 1 1.1-1.1 1.1 1.1 0 0 1-1.1 1.1h1.485l-.2-.195a.274.274 0 0 1-.049-.321.65.65 0 0 0 .047-.112.276.276 0 0 1 .263-.195h.644v-.548h-.64a.276.276 0 0 1-.263-.2.65.65 0 0 0-.047-.112.274.274 0 0 1 .049-.321l.458-.455-.389-.389-.455.458a.274.274 0 0 1-.321.049.65.65 0 0 0-.112-.047.276.276 0 0 1-.195-.263v-.638h-.548v.644a.276.276 0 0 1-.195.263.651.651 0 0 0-.112.047.274.274 0 0 1-.321-.049l-.455-.458-.389.389.458.455a.274.274 0 0 1 .049.321.65.65 0 0 0-.047.112Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path
                data-name="Path 94753"
                d="M18.247 15.67v-3.09h-3.288a.268.268 0 0 1-.244-.151l-.578-1.154v5.337l.734.195a1.091 1.091 0 0 1 .619.447l2.671-1.542Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path
                data-name="Path 94754"
                d="M13.419 10.387H9.375l-.822 1.644h4.044Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <circle
                data-name="Ellipse 129213"
                cx={0.548}
                cy={0.548}
                r={0.548}
                transform="translate(15.507 8.195)"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path
                data-name="Path 94755"
                d="M8.932 6.825a1.906 1.906 0 0 0 .644 1.43 1.355 1.355 0 0 1 .456 1.017v.567h.548v-.822a.274.274 0 1 1 .548 0v.822h.548v-.567a1.348 1.348 0 0 1 .456-1.016 1.918 1.918 0 0 0-.181-3v1.569a.274.274 0 0 1-.123.227l-.822.548a.264.264 0 0 1-.3 0l-.822-.548a.274.274 0 0 1-.123-.227V5.25a1.913 1.913 0 0 0-.829 1.575Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path
                data-name="Path 94756"
                d="m14.307 10.387.822 1.644h4.044l-.822-1.644Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path
                data-name="Path 94757"
                d="m10.779 15.713 2.805.751v-5.189l-.578 1.153a.268.268 0 0 1-.244.151H9.479v3.211l.342-.085a1.906 1.906 0 0 1 .958.008Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path
                data-name="Path 94758"
                d="M6.192 15.866h1.644v4.384H6.192Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path
                data-name="Path 94759"
                d="M9.595 19.976h4.1a1.365 1.365 0 0 0 .689-.183l4.6-2.658a.548.548 0 0 0 .2-.748.555.555 0 0 0-.745-.2l-2.755 1.592a1.128 1.128 0 0 1-.03.37 1.089 1.089 0 0 1-.512.666 1.073 1.073 0 0 1-.545.148 1.115 1.115 0 0 1-.285-.038l-3.268-.874a.275.275 0 1 1 .142-.532l3.268.877a.534.534 0 0 0 .414-.055.545.545 0 0 0-.132-1l-4.088-1.1a1.36 1.36 0 0 0-.688-.005l-1.576.392v2.9l.6.3a1.358 1.358 0 0 0 .611.148Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
            <path
                data-name="Path 94760"
                d="M4 16.414h1.644v3.288H4Z"
                fill={props?.fill ?? "#c1c5cb"}
            />
        </g>
    </svg>
)
