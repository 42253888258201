import { useStyles } from "./styles";
import { Box, Typography, Button } from "@mui/material"
import { Time } from "../../assets";
import { UpdateForm } from "./updateForm"
import { AlertDialog } from "../dialog";
import React from "react";

export const EditInspectionHour = (props) => {
    const { t } = (props)
    const classes = useStyles(props);
    const [open, setOpen] = React.useState(false);

    const onClose = () => {
        setOpen(!open)
    }




    return (
        <>
            <Box className={classes.editCardRoot} alignItems="center" display="flex">
                <Box>
                    <Time />
                </Box>
                <Box flexGrow={1} marginInlineStart="12px">
                    <Typography className={classes.cardTitle}>{t("Billing Hours")}</Typography>
                </Box>
                <Box>
                    <Button className={classes.updateBtn} variant="outlined" onClick={onClose}>{props?.status==="Completed"?t("View"):t("Update")}</Button>
                </Box>
            </Box>
            
        
        
        <AlertDialog
                open={open}
                onClose={onClose}
                isNormal
                header={props?.status==="Completed"?t("View Billing Hours"):t("Update Billing Hours")}
                component={<UpdateForm t={t} onClose={onClose} {...props} />}
            />
        </>
    )
}