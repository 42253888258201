import * as React from "react"
const ElectricIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    <path
      fill="#f5aa00"
      d="M4.051 12a.274.274 0 0 1-.263-.356l1.385-4.54h-2.9a.276.276 0 0 1-.216-.448L7.323.103a.276.276 0 0 1 .481.248L6.507 4.897h2.789a.277.277 0 0 1 .216.448l-5.244 6.552a.274.274 0 0 1-.217.103Z"
      data-name="Group 114100"
    />
  </svg>
)
export default ElectricIcon
