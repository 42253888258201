export const KycRecords = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    x={0}
    y={0}
    viewBox="0 0 30 30"
    style={{
      enableBackground: "new 0 0 30 30",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st0{fill:#c1c5cb}"}</style>
    <g id="XMLID_1270_">
      <path
        id="XMLID_1271_"
        className="st0"
        d="M16.3 14.2c.4.3.7.8 1 1.2.7-.4 1.6-.7 2.5-.7.4 0 .7.1 1.1.1-.6-.7-1.3-1.3-2.1-1.7-.7.6-1.6 1-2.5 1.1z"
        style={{
          fill: props?.fill ?? "#c1c5cb",
        }}
      />
      <path
        id="XMLID_1273_"
        className="st0"
        d="M15.1 19.3c0-1.3.6-2.5 1.4-3.3-.5-.8-1.1-1.4-2-1.9-.7.5-1.6.8-2.5.8-.9 0-1.8-.3-2.5-.8-1.8.9-3 3-3 5.3V23h10.2c-.9-.9-1.6-2.2-1.6-3.7z"
        style={{
          fill: props?.fill ?? "#c1c5cb",
        }}
      />
      <path
        id="XMLID_1274_"
        className="st0"
        d="M16.4 10.5c0 .9-.3 1.8-.8 2.5v.1h.4c1.7 0 3.1-1.4 3.1-3.1S17.7 7 16 7c-.3 0-.7.1-1 .2.8.9 1.4 2 1.4 3.3z"
        style={{
          fill: props?.fill ?? "#c1c5cb",
        }}
      />
      <circle
        id="XMLID_1275_"
        className="st0"
        cx={12}
        cy={10.5}
        style={{
          fill: props?.fill ?? "#c1c5cb",
        }}
        r={3.4}
      />
      <path
        id="XMLID_1278_"
        className="st0"
        d="M23.4 19.3c0-2-1.6-3.6-3.6-3.6s-3.6 1.6-3.6 3.6 1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6zm-5.8.5v-1h1.6v-1.6h1v1.6h1.6v1h-1.6v1.6h-1v-1.6h-1.6z"
        style={{
          fill: props?.fill ?? "#c1c5cb",
        }}
      />
    </g>
  </svg>
)