import React from "react";
import { withNavBars } from "../../HOCs";
import Contacts from "./contacts";

class ContactsParent extends React.Component {
  render() {
    return <Contacts reltype="AllContact" />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ContactsParent, props);
