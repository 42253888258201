import { Dialog, Grid } from "@mui/material";
import React from "react";
import { filterStyles } from "../../propertyFinder3/component/styles";
import { Slider } from "./assets";

export const AssetView = ({ asset = [], assetAll, title, isNormal = false, maxWidth = 'md', isMaintenance = false, isPropertyBoard = false }) => {
    const classes = filterStyles();
    const [open, setOpen] = React.useState(false);
    const openPopup = (index) => {
        if (isNormal) {
            setOpen(true);
        } else {
            setOpen(true);
        }
    };

    return (
        <>
            {asset?.length === 0 && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {
                            <img src={"/images/propertyImagePlaceholder.svg"} alt="" className={isMaintenance ? classes.requestServiceSingleImg : isPropertyBoard ? classes.propertyBoardSingleImg : classes.detailImg} />
                        }
                    </Grid>
                </Grid>
            )}
            {asset?.length === 1 && (
                <Grid container spacing={1}>
                    <Grid item xs={12} onClick={openPopup}>

                        {
                            asset[0]?.file_meta?.file_type === "mp4" ? <video
                                className={isMaintenance ? classes.requestServiceSingleImg : isPropertyBoard ? classes.propertyBoardSingleImg : classes.detailImg}
                                controls
                            >
                                <source src={asset[0]?.url} type="video/mp4" />
                            </video> : <img src={asset[0]?.url ?? "/images/imagesproperty.svg"} alt="" className={isMaintenance ? classes.requestServiceSingleImg : isPropertyBoard ? classes.propertyBoardSingleImg : classes.detailImg} />
                        }
                    </Grid>
                </Grid>
            )}
            {asset?.length > 1 && isMaintenance &&
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                        {
                            asset[0]?.file_meta?.file_type === "mp4" ? <video
                                className={`${classes.requestServiceImg}`}
                                controls
                            >
                                <source src={asset[0]?.url} type="video/mp4" />
                            </video> : <img src={asset[0]?.url ?? "/images/imagesproperty.svg"} alt="" className={classes.requestServiceImg} />
                        }
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6} lg={6}>
                                {
                                    asset[1]?.file_meta?.file_type === "mp4" ? <video
                                        className={`${classes.requestImg2}`}
                                        controls
                                    >
                                        <source src={asset[1]?.url ?? "/images/imagesproperty.svg"} type="video/mp4" />
                                    </video> : <img
                                        src={asset[1]?.url}
                                        alt=""
                                        className={classes.requestImg2}
                                    />

                                }
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                {asset?.length >= 2 && (
                                    <div className={classes.addImgService} onClick={openPopup}>
                                        {asset?.length - 3 > 0 &&
                                            <span className={classes.plus}>
                                                +&nbsp;{asset?.length - 3}
                                            </span>
                                        }
                                        {
                                            asset[2]?.file_meta?.file_type === "mp4" ? <video
                                                className={`${classes.requestImgOpacity}`}
                                                controls
                                            >
                                                <source src={asset[2]?.url} type="video/mp4" />
                                            </video> : <img
                                                src={asset[2]?.url ?? "/images/imagesproperty.svg"}
                                                alt=""
                                                className={classes.requestImgOpacity}
                                            />

                                        }
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {/* {asset?.length === 0 && isMaintenance &&
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                        {
                            asset[0]?.file_meta?.file_type === "mp4" ? <video
                                className={`${classes.requestServiceSingleImg}`}
                                controls
                            >
                                <source src={"/images/imagesproperty.svg"} type="video/mp4" />
                            </video> : <img src={"/images/imagesproperty.svg"} alt="" className={classes.requestServiceSingleImg} />
                        }
                    </Grid>
                </Grid>
            } */}
            {!isMaintenance && asset?.length > 1 && (
                <Grid container spacing={1} onClick={openPopup}>
                    <Grid item xs={7}>
                        {
                            asset[0]?.file_meta?.file_type === "mp4" ? <video
                                className={`${isPropertyBoard ? classes.propertyBoardSingleImg : classes.detailImg}`}
                                controls
                            >
                                <source src={asset[0]?.url} type="video/mp4" />
                            </video> : <img src={asset[0]?.url} alt="" className={isPropertyBoard ? classes.propertyBoardSingleImg : classes.detailImg} />
                        }
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>

                                {
                                    asset[1]?.file_meta?.file_type === "mp4" ? <video
                                        className={`${isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}`}
                                        controls
                                    >
                                        <source src={asset[1]?.url ?? "/images/imagesproperty.svg"} type="video/mp4" />
                                    </video> : <img
                                        src={asset[1]?.url}
                                        alt=""
                                        className={isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}
                                    />

                                }
                            </Grid>

                            <Grid item xs={6}>

                                {
                                    asset[2]?.file_meta?.file_type === "mp4" ? <video
                                        className={`${isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}`}
                                        controls
                                    >
                                        <source src={asset[2]?.url} type="video/mp4" />
                                    </video> : <img
                                        src={asset[2]?.url ?? "/images/imagesproperty.svg"}
                                        alt=""
                                        className={isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}
                                    />

                                }
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    asset[3]?.file_meta?.file_type === "mp4" ? <video
                                        className={`${isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}`}
                                        controls
                                    >
                                        <source src={asset[3]?.url} type="video/mp4" />
                                    </video> : <img
                                        src={asset[3]?.url ?? "/images/imagesproperty.svg"}
                                        alt=""
                                        className={isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}
                                    />

                                }
                            </Grid>
                            <Grid item xs={6}>
                                {asset?.length > 5 ? (
                                    <div className={classes.addImg} onClick={openPopup}>
                                        <span className={classes.plus}>
                                            +&nbsp;{asset?.length - 5}
                                        </span>
                                        {
                                            asset[4]?.file_meta?.file_type === "mp4" ? <video
                                                className={`${isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}`}
                                                controls
                                            >
                                                <source src={asset[4]?.url} type="video/mp4" />
                                            </video> : <img
                                                src={asset[4]?.url ?? "/images/imagesproperty.svg"}
                                                alt=""
                                                className={isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}
                                            />

                                        }
                                    </div>
                                ) : (


                                    asset[4]?.file_meta?.file_type === "mp4" ? <video
                                        className={`${isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}`}
                                        controls
                                    >
                                        <source src={asset[4]?.url} type="video/mp4" />
                                    </video> : <img
                                        src={asset[4]?.url ?? "/images/imagesproperty.svg"}
                                        alt=""
                                        className={isPropertyBoard ? classes.propertyBoarddetailImg : classes.detailImg2}
                                    />


                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Dialog
                open={open}
                fullWidth
                maxWidth={maxWidth ?? "md"}
                onClose={() => setOpen(false)}
                className={classes.dialog}
            >
                <Slider assets={assetAll ?? []} padding={"0px 21px 0px 23px"} />
            </Dialog>
        </>
    );
};