import * as React from "react"

const RightArrow = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} {...props}>
        <g data-name="Group 102045" transform="translate(-1310 -108)">
            <circle
                data-name="Ellipse 129422"
                cx={12.5}
                cy={12.5}
                r={12.5}
                transform="translate(1310 108)"
                fill={props?.color}
            />
            <g data-name="Group 102049" stroke="#fff">
                <path
                    d="M1327.262 120.792a.412.412 0 0 0 0-.583l-3.916-3.916a.412.412 0 0 0-.583.579l3.62 3.628-3.62 3.625a.412.412 0 1 0 .583.583Z"
                    fill="#fff"
                    strokeWidth={0.5}
                />
                <path
                    data-name="Line 1219"
                    fill="none"
                    strokeLinecap="round"
                    strokeWidth={1.5}
                    d="M1325.992 120.5h-8.374"
                />
            </g>
        </g>
    </svg>
)

export default RightArrow
