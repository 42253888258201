import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import IconInfo from '../../../assets/iconInfo'
import { CreateDeliveryMilestoneTemplateStyles } from '../style'

export const TemplateCard = (props) => {
    const { t } = (props)
    const classes = CreateDeliveryMilestoneTemplateStyles()
    const [hover, setHover] = React.useState(false)
    const [hover2, setHover2] = React.useState(false)
    return (
        <div onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Box className={classes.box3}>
                <Box position={"relative"} onMouseOver={() => setHover2(true)} onMouseLeave={() => setHover2(false)}>
                    <IconInfo />
                    {hover2 &&
                        <Box position={"absolute"} className={classes.info}>
                            <Typography className={classes.subHeadingInfo}>
                                {props?.data?.description}
                            </Typography>
                        </Box>
                    }
                </Box>
                <Box display={"flex"} justifyContent="space-between" sx={{ marginInlineStart: "50px" }} flexGrow={1}>
                    <Box>
                        <Typography className={classes.heading}>{props.data.name}</Typography>
                    </Box>
                    {hover &&
                        <Box>
                            <Button
                                variant='contained'
                                size="small"
                                onClick={() => props?.Add(props?.data)}
                                disabled={props?.list?.map((val) => val?.id)?.some(item => item === props?.data?.id)}
                            >{t("add")}</Button>
                        </Box>
                    }

                </Box>
            </Box>

        </div >
    )
}
