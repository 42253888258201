import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        padding: "16px 16px 0px 16px",
        backgroundColor: "white",
        // margin: "14px",
        height: `calc(100vh - 150px)`,
        overflow: "auto",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
    },
    blockdetailsbox: {
        borderRadius: "6px",

        boxShadow: "0px 0px 16px #00000014",
        border: "1px solid #E4E8EE",
        padding: "12px",
        backgroundColor: "white",
    },
    propertyCard: {
        border: "1px solid #CED3DD",
        backgroundColor: "#FFFFFF",
        margin: "8px",
        borderRadius: "12px",
        padding: "8px",
        position: "relative",
        minHeight: "285px"
    },
    fixedStatusbtn: {
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        padding: "8px"
    },
    propertyImage: {
        width: "100%",
        height: "103px",
        overflow: "hidden",
        borderRadius: "6px",
    },
    unit_img: {
        borderRadius: "6px",
        width: "100%",
        height: "103px",
        objectFit: "cover",
        transition: "transform 0s",
        "&:hover": {
            transform: "scale(1.1)",
        },
    },
    hoverUnitImg: {
        borderRadius: "6px",
        width: "100%",
        height: "103px",
        objectFit: "cover",
        transition: "transform 0s",
        transform: "scale(1.1)",
    },
    amounttxt: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: Bold,
        direction: "rtl",
        textAlign: "-webkit-match-parent",
    },
    agreementamounttxt: {
        direction: "ltr",
        textAlign: "-webkit-match-parent",
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: Bold
    },
    subtext: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        borderRadius: theme.palette.borderRadius,
        padding: "6px",
        textAlign: "center"
    },
    vacantbox: {
        borderRadius: theme.palette.borderRadius,
        padding: "5px",
        textAlign: "center",
        width: "70px",
        height: "32px"
    },
    dotunit: {
        width: "6px",
        height: "6px",
        backgroundColor: "#CED3DD",

        borderRadius: "50%",
        marginInlineStart: "6px !important"
    },
    unitDetails: {
        fontSize:"0.875rem",
        color: theme.typography.color.tertiary,
        fontFamily: Regular
    },
    shareUnitDetails: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: Regular
    },
    unitblockDetails: {
        fontSize:"0.75rem",
        color: "#4E5A6B",
        fontFamily: Regular
    },
    floorTxt: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        marginInlineStart: "8px !important"

    },
    shareFloortxt: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold
    },
    amenityTxt: {
        fontSize:"0.875rem",
        color: "#4E5A6B",
        fontFamily: SemiBold,

    },
    view: {
        color: "#5078E1",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        cursor: "pointer"
    },
    textOnimg: {
        position: "relative",
        textAlign: "center"
    },
    property_type: {
        position: "absolute",
        top: "8px",
        right: "8px",
        borderRadius: "6px",
        backgroundColor: theme.typography.color.primary
    },
    property_text: {
        fontSize:"0.75rem",
        textAlign: "center",
        fontFamily: SemiBold,
        color: "#FFFFFF",
        padding: "5px",
        opacity: "95%",
    },
    status_type: {
        position: "absolute",
        top: "0px",
        left: "0px",
    },
    viewTabs: {
        backgroundColor: "#F2F4F7",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "space-around",
        padding: "8px",
        textAlign: "center",
        alignItems: "center"
    },
    viewMoreTabs: {
        backgroundColor: "#F2F4F7",
        borderRadius: theme.palette.borderRadius,
        padding: "5px",
        textAlign: "center",
        alignItems: "center",
        // width: "312px"
    },
    tabText: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
        display: "flex",
        justifyContent: "center",
        cursor: "pointer"

    },
    selectedTab: {
        backgroundColor: "#FFFFFF",
        padding: "5px",
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.primary,
        display: "flex",
        justifyContent: "center",
        width: "150px",
        cursor: "pointer"
    },
    selectedMoretab: {
        backgroundColor: "#FFFFFF",
        padding: "5px",
        borderRadius: theme.palette.borderRadius,
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    amenitiesBox: {
        backgroundColor: "#F5F7FA",
        padding: "12px 16px",
        borderRadius: theme.palette.borderRadius
    },
    amenitiesText: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.secondary
    },
    amenitiesTitleText: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: "#98A0AC"
    },
    amenitiesDataBox: {
        backgroundColor: "#F5F7FA",
        padding: "8px",
        borderRadius: theme.palette.borderRadius,
        alignItems: "center",
        marginInlineStart: "8px !important"


    },
    breakupBox: {
        backgroundColor: "#EEF9EE",
        padding: "6px",
        borderRadius: theme.palette.borderRadius
    },
    brkuptxt: {
        color: "#5AC782",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        textAlign: "center"
    },
    brkupdata: {
        fontSize:"0.875rem",
        fontFamily: Bold,
        color: theme.typography.color.secondary,
    },
    brkupdatatxt: {

        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary
    },
    executionstatus: {
        backgroundColor: "#F1F7FF",
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    bottomfix: {
        // position: "fixed",
        // left: 0,
        // bottom: 0,
        position: "sticky",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
    },
    sharebox: {
        backgroundColor: "#F1F7FF",
        borderRadius: theme.palette.borderRadius,
        padding: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        width: 40,
        height: 40

    },
    nearbyBox: {
        borderRadius: "8px",
        border: "1px solid #E4E8EE",
        // width: "190px",

        cursor: "pointer"
    },
    iconBox: {
        height: "80px",
        borderRadius: "8px 8px 0px 0px",
    },
    nearybyTitleBox: {
        padding: "12px"
    },
    arrowIcon: {
        color: "#E4E8EE"
    },
    blockBox: {
        backgroundColor: "#F1F7FF",
        borderRadius: "8px",
        position: "relative",
        padding: "12px",
    },
    unitBlock: {
        backgroundColor: "#78B1FE",
        borderRadius: theme.palette.borderRadius,
        padding: "5px",
        color: "#FFFFFF",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        width: "fit-content",
        position: "absolute",
        top: 0,
        left: 16
    },
    agreementBox: {
        backgroundColor: "#F5F7FA",
        borderRadius: "8px",
        padding: "16px",
        opacity: "90%"

    },
    agreementTxt: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: Regular,
        marginBottom: "2px"

    },
    unitImage: {
        borderRadius: "6px",
        height: "103px",
        width: "103px"
    },
    sharelink: {
        backgroundColor: "#F1F7FF",
        paddingRight: "0px",

        borderRadius: theme.palette.borderRadius
    },
    linktxt: {
        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: SemiBold
    },
    copyBox: {
        backgroundColor: "rgb(120,177,254,.2)",
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    copiedBox: {
        backgroundColor: "#78b1fe",
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    copytext: { fontSize:"0.75rem", fontFamily: SemiBold, color: "#FFFFFF" },
    selectcustomborder: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius
    },
    searchBorderRight: {
        borderRight: "1px solid #E4E8EE"
    },
    closebtn: {
        width: "201px",
        height: "40px",

        "&:hover": {
            backgroundColor: "white"
        }
    },
    closebtncmn: {
        marginInlineStart: "8px !important",

        "&:hover": {
            backgroundColor: "white"
        }

    },
    Morebeds: {
        borderRadius: theme.palette.borderRadius,
        padding: "4px",
        textAlign: "center",
        backgroundColor: "#F5F7FA",
        display: "flex",
        justifyContent: "center"
    },
    arrowLeft: {
        backgroundColor: "#E4E8EE",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    applyBorder: {
        borderRight: "1px solid #E4E8EE",
        padding: "16px"
    },
    shareBlockCard: {
        border: "1px solid #E4E8EE",
        margin: "30px",
        borderRadius: theme.palette.borderRadius
    },
    tabBorder: {
        border: "1px solid #E4E8EE",
        borderRadius: "246px",
        padding: "0px 8px 0px 8px",
        display: "flex",
        alignItems: "center",
        width: "160px",
        height: "56px",
        cursor: "pointer",
        [theme.breakpoints.only('xs')]: {
            width: "100%",
        },
        [theme.breakpoints.only('sm')]: {
            width: "250px",
        },

    },
    countALL: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: Bold
    },
    statusLabel: {
        fontSize:"0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: Bold
    },
    blockedicon: {
        backgroundColor: "#FFF4EB",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px"
    },
    statusbtn: {
        borderRadius: "8px",
        height: "34px",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: "#FFFFFF",
        opacity: "95%"
    },
    startfrom: {
        color: theme.typography.color.primary,
        fontSize:"0.75rem",
        fontFamily: Regular
    },
    roomrent: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
        direction: "rtl"
    },
    paymentPeriod: {
        fontSize:"0.875rem",
        color: "#4E5A6B",
        fontFamily: Regular
    },
    taxtxt: {
        fontSize:"0.75rem",
        color: "#4E5A6B",
        fontFamily: Regular
    },
    viewbtn: {
        borderRadius: "8px",
        height: "32px",
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        opacity: "95%",
        "&:hover": {
            backgroundColor: "#FFFFFF"
        }
    },
    applyBorderLeft: {
        borderLeft: "1px solid #E4E8EE",
        [theme.breakpoints.up('md')]: {
            height: 590,
            overflow: "scroll",
            padding: "16px",
        },

    },
    executionStatusBox: {
        border: "1px solid #E4E8EE",
        backgroundColor: "#FFFFFF",
        borderRadius: theme.palette.borderRadius,
        padding: "4px 8px",
        // width: "92px",
        height: "28px"
    },
    executionDot: {
        width: "8px",
        height: "8px",
        borderRadius: "50%"
    },
    executionText: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
    },
    blockOptionLabel: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.primary
    },
    applyblockborder: {
        borderBottom: "1px solid #E4E8EE",
        width: "170px",
        marginTop: "8px"
    },
    unitName: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: "#4E5A6B"
    },
    required: {
        color: "red",
        fontFamily: SemiBold,
        fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
    },
    menuItemlist: {
        padding: "5px 12px !important"
    },
    menuList: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 16px #00000014",
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "white",
            marginTop: "5px",
            width: "201px"
        },
    },

}))
export const StepperStyles = makeStyles((theme) => ({

    connector: {
        borderColor: theme.palette.border.secondary,
        borderLeftStyle: "dashed",
        borderLeftWidth: "1px",
        height: "35px",
        marginLeft: "6px",
        marginTop: "0px"
    },
    customconnector: {
        borderColor: "#5078E1",
        borderLeftStyle: "dashed",
        borderLeftWidth: "1px",
        height: "30px",
        marginLeft: "18px",
        marginTop: "0px"
    },
    Name: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold
    },
    description: {
        color: theme.typography.color.tertiary,
        fontSize:"0.75rem",
        fontFamily: Regular
    },
    detail: {
        marginLeft: "15px",
        // marginTop: "-10px"
    },
    applybackground: {
        backgroundColor: "#F2F4F7",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    notes: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
        marginTop: "3px"
    },
    popover: {
        "& .MuiPopover-paper": {
            borderRadius: "16px",
            boxShadow: "none",

            border: "1px solid #CED3DD",
            direction: "ltr"
        }
    },
    dateOptionLabel: {
        fontSize:"0.75rem",
        color: "#071741",
        fontFamily: SemiBold
    },
    filterLabel: {
        marginTop: "2px",
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        color: "#091B29"
    },
    filterOptionLabel: {
        fontSize:"0.75rem",
        color: "#091B29",
        fontFamily: SemiBold
    },
    applyblockborder: {
        borderBottom: "1px solid #E4E8EE",
        width: "150px",
        marginTop: "8px"
    },
    applyfilterborder: {
        borderBottom: "1px solid #E4E8EE",
        width: "130px",
        marginTop: "8px"
    },
    menuList: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 6px #0717411F",
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "white",
            marginTop: "5px",
            width: "170px"
        },
    },
    menuItemlist: {
        padding: "5px 12px !important"
    },
    filtermenuList: {
        "& .MuiPopover-paper": {
            boxShadow: "0px 0px 16px #00000014",
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "white",
            marginTop: "6px",
            //   width:"170px"
        },
    },
}))
export const PriorityStyles = makeStyles((theme) => ({
    priorityBox: {
        padding: "2px",

        borderRadius: theme.palette.borderRadius,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        // marginTop: "5px",
        border: "1px solid #E4E8EE",
        cursor: "pointer"
    },
    priorityLabel: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        marginBottom: "5px"
    },
    initialText: {
        color: theme.typography.color.primary,
        textTransform: "capitalize",
        fontSize:"0.75rem",
        fontFamily: SemiBold

    },
    arrowIcon: {
        color: "#E4E8EE"
    },
    menuOptions: {
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        borderRadius: "4px",
        marginTop: "2px",
        padding: "12px"
    },
    dot: {
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: "black",
        marginTop: "8px"
    },
    priorityDivider: {
        // marginBottom:"5px"
    },
    priorityName: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
        textTransform: "capitalize"
    },
    menu: {
        "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
        {
            borderRadius: theme.palette.borderRadius,
            padding: "0px !important",
            boxShadow: "0px 8px 24px #0717411F",

        },
        "& .MuiMenu-list": {
            padding: "0 !important",
        },
    },
    required: {
        color: "red",
        fontFamily: SemiBold,
        fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
    },
    applyBorderBox: {
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        padding: "5px"
    },
    menuBox: {
        backgroundColor: "white",
        boxShadow: "0px 8px 24px #0717411F",
        borderRadius: theme.palette.borderRadius,
        padding: "8px",
        width: "600px"
    },
    amenity_content: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 3,
        position: "absolute",
        backgroundColor: "#fff",
        // top: "190px",
        padding: 12,
        width: "900px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        // height: "200px",
        left: "0px",
        marginTop: "8px",
        overflow: "overlay",
        boxShadow: "0px 0px 16px #00000014",
    },
    amenity_content_arabic: {

        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        cursor: "pointer",
        zIndex: 3,
        position: "absolute",
        backgroundColor: "#fff",
        // top: "190px",
        padding: 12,
        width: "900px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        // height: "200px",
        right: "0px",
        marginTop: "8px",
        overflow: "overlay",
        boxShadow: "0px 0px 16px #00000014",

    },
    name: {
        fontSize:"0.875rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
        marginInlineStart: "8px",
        maxWidth:"190px"
    },
    subName: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: "#5AC782",
        marginInlineStart: "8px"
    },
    subblockName:{
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
        marginInlineStart: "8px" 
    },
    applySelectedBorderBox: {
        border: "1px solid #78B1FE",
        borderRadius: theme.palette.borderRadius,
        padding: "5px",
        backgroundColor: "#F1F7FF"
    },
    floorIconBox: {
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#E4E8EE",
        padding: "5px"
    },
    textBox: {

        fontSize:"0.75rem",
        color: theme.typography.color.primary,
        fontFamily: SemiBold,
        padding: "12px 8px"
    }



}))

export const nearByStyles = makeStyles((theme) => ({

    locRoot: {
        borderBottom: "1px solid #CED3DD",
        cursor: "pointer"
    },
    locName: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.secondary,
    }


}))