import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: (size) => (size?.height - (64 + 49)),
    overflow: "auto",
    padding: "16px",
  },
  card: {
    border: "2px solid white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    padding: "16px 16px 16px 0px",
  },
  imgDiv: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    borderRight: "1px solid #00000014",
    padding: "4px"
  },
  img: {
    height: "120px",
    width: "120px",
    borderRadius: "50%",
    margin: "4px",
    objectFit: "fill"
  },
  imgTag: {
    padding: "2px 6px",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "#071741",
    color: "white",
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    position: "absolute",
    bottom: "10px",
    display: "inline",
  },
  detailDiv: {
    padding: "0px 12px"
  },
  title: {
    color: theme.typography.color.secondary,
    fontSize:"0.75rem",
    fontFamily: Bold
  },
  heading: {
    color: theme.typography.color.tertiary,
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    textTransform: "capitalize"
  },
  sub: {
    color: theme.typography.color.primary,
    fontSize:"0.875rem",
    fontFamily: SemiBold,
    textTransform: "capitalize",
    '& .ql-editor': {
      padding: '0px 0px 16px 0px',
      color: theme.typography.color.primary,
      fontSize:"0.875rem",
      fontFamily: SemiBold,
      textTransform: "capitalize",
    }
  },
}));