import * as React from "react"

const ImagesProperty = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <path
      data-name="icons8-image (2)"
      d="M2.75 0A2.75 2.75 0 0 0 0 2.75v8.95l3.753-3.63a2.067 2.067 0 0 1 2.813 0l3.089 2.988 1.024-.99a2.073 2.073 0 0 1 2.813 0L18 14.429V2.75A2.75 2.75 0 0 0 15.25 0Zm9.5 3.5A2.25 2.25 0 1 1 10 5.75a2.253 2.253 0 0 1 2.25-2.25Zm0 1.5a.75.75 0 1 0 .75.75.75.75 0 0 0-.75-.75ZM5.159 9a.52.52 0 0 0-.363.144L0 13.788v1.462a2.75 2.75 0 0 0 2.5 2.737L8.577 12.1 5.523 9.146A.523.523 0 0 0 5.159 9Zm6.928 2a.521.521 0 0 0-.363.143L4.639 18H15.25a2.75 2.75 0 0 0 2.537-1.69l-5.336-5.162a.524.524 0 0 0-.364-.148Z"
      fill={props?.color}
    />
  </svg>
)

export default ImagesProperty