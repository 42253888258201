import React from "react";
import { withNavBars } from "../../HOCs";
import { AggreementView } from "./aggreementView";

class AggreementViewParent extends React.Component {
    render() {
        return <AggreementView />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AggreementViewParent, props);
