import React from "react"
import { withNavBars } from "../../HOCs";
import PropertyBoard from "./propertyBoard"
class PropertyBoardParent extends React.Component {
    render() {
        return <PropertyBoard {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(PropertyBoardParent, props);