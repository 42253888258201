export const EntriesPath = [
  "id",
  "vendor_type",
  "vendor_person_name",
  "reference_id",
  "property",
  "unit",
  "checked_in",
  "checked_out",
];

export const EntriesHeading = [
  { title: "Sno", field: "id", type: "numeric" },
  { title: "Vendor Type", field: "vendor_type" },
  { title: "Vendor Person Name", field: "vendor_person_name" },
  { title: "Reference ID", field: "reference_id" },
  { title: "Property", field: "property" },
  { title: "Unit", field: "unit" },
  { title: "Checked In", field: "checked_in" },
  { title: "Checked Out", field: "checked_out" },
];
export const EntriesType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "vendor_type" },
  { type: ["text"], name: "vendor_person_name" },
  { type: ["text"], name: "reference_id" },
  { type: ["text"], name: "property" },
  { type: ["textLink"], name: "unit" },
  { type: ["text"], name: "checked_in" },
  { type: ["text"], name: "checked_out" },
];

export const ServicePath = [
  "id",
  "vendor_type",
  "vendor_person_name",
  "reference_id",
  "property",
  "unit",
  "checked_in_gate",
  "checked_in_by",
  "checked_in",
  "checked_out_by",
  "checked_out_gate",
  "checked_out",
  "status"
];

export const ServiceHeading = (t) => [
  { title: t("Sno"), field: "id", type: "numeric" },
  { title: t("Service Provider Name"), field: "vendor_type" },
  { title: t("Service Provider Person Name"), field: "vendor_person_name" },
  { title: t("Reference ID"), field: "reference_id" },
  // { title: "Property", field: "property" },
  { title: t("Visiting Unit No"), field: "unit" },
  { title: t("Entry Gate"), field: "checked_in_gate" },
  // { title: "Checked In By", field: "check_in_by" },
  { title: t("Checked In"), field: "checked_in" },
  { title: t("Exit Gate"), field: "checked_out_gate" },
  // { title: "Checked Out By", field: "checked_out_by" },
  { title:t("Checked Out"), field: "checked_out" },
  { title: t("Status"), field: "status" },
];
export const ServiceType = [
  { type: ["increment"], name: "id" },
  { type: ["text"], name: "vendor_type" },
  { type: ["text"], name: "vendor_person_name" },
  { type: ["text"], name: "reference_id" },
  // { type: ["text"], name: "property" },
  { type: ["text"], name: "unit" },
  { type: ["text"], name: "checked_in_gate" },
  // { type: ["text"], name: "checked_in_by" },
  { type: ["text"], name: "checked_in" },
  { type: ["text"], name: "checked_out_gate" },
  // { type: ["text"], name: "checked_out_by" },
  { type: ["text"], name: "checked_out" },
  { type: ["text"], name: "status" },
];
