import styled from "@mui/material/styles/styled";
import { Typography } from "@mui/material";
import { Bold, remCalc } from "../../utils";

export const WorkerTypeOptions = ({ t = () => false }) => [
  { label: t("Registered"), value: "registered" },
  { label: t("Unregistered"), value: "unregistered" },
]

export const initial = ({ t = () => false }) => {
  return {
    Category: "",
    subCategory: "",
    location: "",
    Unit: "",
    Title: "",
    priority: "medium",
    visitorDate: new Date(),
    Description: "",
    workNo: "",
    workId: "",
    status: true,
    alternativeContact: "",
    PrimaryContact: {
      mobile: "",
    },
    Property: "",
    problemSince: new Date(),
    contactName: "",
    member: "",
    workdate: null,
    vendor: "",
    workerStatus: "",
    vendor_email_id: "",
    worker_type: WorkerTypeOptions({ t })?.[0]?.value,
    reg_worker: "",
    worker_contact_name: "",
    profession: "",
    worker_contact: {
      mobile: "",
    },
    alternative_company: "",
    worker_company_location: "",
    service_charges: "",
    material_charges: "",
    total_paid: "",
    payment_mode: "",
    paid_to: "",
    payment_date: new Date(),
    invoice_no: "",
    invoice_date: new Date(),
    work_completion: "",
    assigned_date: new Date(),
    visitorTime:"",
    error: {
      Category: "",
      subCategory: "",
      Unit: "",
      Title: "",
      visitorDate: "",
      Description: "",
      workNo: "",
      workId: "",
      status: "",
      location: "",
      alternativeContact: "",
      PrimaryContact: "",
      Property: "",
      problemSince: "",
      contactName: "",
      member: "",
      priority: "",
      vendor_email_id: "",
      reg_worker: "",
      worker_contact_name: "",
      profession: "",
      alternative_company: "",
      worker_company_location: "",
      service_charges: "",
      material_charges: "",
      total_paid: "",
      payment_mode: "",
      paid_to: "",
      payment_date: "",
      invoice_no: "",
      invoice_date: "",
      work_completion: "",
      assigned_date: "",
      visitorTime:"",
    },
  }
};
export const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  fontFamily: Bold,
  color: "#4E5A6B",
  marginBottom: theme.spacing(1),
}));

export const CustomTypography1 = styled(Typography)(({ theme }) => ({
  fontSize: remCalc(12),
  fontFamily: Bold,
  color: "#091B29",
  marginBottom: theme.spacing(1),
}));

export const CustomTypographyWorker = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  fontFamily: Bold,
  color: "#4E5A6B",
  marginBottom: theme.spacing(1),
}));