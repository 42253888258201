export const RuleBuilder = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-2{fill:#c1c5cb}"}</style>
        </defs>
        <g id="Announcement" transform="translate(24956 -2962)">
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24956 2962)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
            <g id="Page-1" transform="translate(-24947 2969)">
                <g id="_013---Rule-Book" data-name="013---Rule-Book">
                    <path
                        id="Rectangle-path"
                        className="cls-2"
                        d="M10 22h8v2.571h-8Z"
                        transform="translate(-7.143 -15.714)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Shape"
                        className="cls-2"
                        d="M0 52.571v.046a.607.607 0 0 0 .62.526h11.094a.286.286 0 0 0 .286-.286V52H.571a.571.571 0 0 0-.571.571Z"
                        transform="translate(0 -37.143)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Shape-2"
                        data-name="Shape"
                        className="cls-2"
                        d="M12.571 14V.286A.286.286 0 0 0 12.285 0H.857A.857.857 0 0 0 0 .857v13.582a1.134 1.134 0 0 1 .571-.153h11.714a.286.286 0 0 0 .286-.286ZM8.286 2a.571.571 0 0 1 .571-.571h.571a.286.286 0 1 1 0 .571h-.571v.571h.286a.286.286 0 0 1 0 .571h-.286v.571h.571a.286.286 0 1 1 0 .571h-.571a.571.571 0 0 1-.571-.571Zm-1.715-.286a.286.286 0 1 1 .571 0v2h.286a.286.286 0 0 1 0 .571h-.285a.571.571 0 0 1-.571-.571Zm-2.286 0a.286.286 0 1 1 .571 0v1.715a.286.286 0 1 0 .571 0V1.714a.286.286 0 1 1 .571 0v1.715a.857.857 0 1 1-1.714 0ZM1.143 13.428a.286.286 0 0 1-.571 0V3.714a.286.286 0 1 1 .571 0Zm0-10.857a.286.286 0 0 1-.571 0V1.143a.286.286 0 0 1 .571 0ZM2 4V2a.571.571 0 0 1 .571-.571h.286a.853.853 0 0 1 .549 1.51 1.135 1.135 0 0 1 .309.775V4a.286.286 0 1 1-.571 0v-.286a.571.571 0 0 0-.571-.571V4A.286.286 0 1 1 2 4Zm6.857 8.857h-4a.286.286 0 1 1 0-.571h4a.286.286 0 1 1 0 .571Zm.857-1.429H4a.286.286 0 1 1 0-.571h5.714a.286.286 0 1 1 0 .571Zm1.714-2.571a.571.571 0 0 1-.571.571h-8a.571.571 0 0 1-.571-.571V6.286a.571.571 0 0 1 .571-.571h8a.571.571 0 0 1 .571.571Zm-.286-6.286a.857.857 0 1 1 0 1.714h-.571a.286.286 0 0 1 0-.571h.571a.286.286 0 0 0 0-.571.857.857 0 0 1 0-1.714h.571a.286.286 0 1 1 0 .571h-.571a.286.286 0 0 0 0 .571Z"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                    <path
                        id="Shape-3"
                        data-name="Shape"
                        className="cls-2"
                        d="M9.571 7.286A.286.286 0 0 0 9.286 7H9v.571h.286a.286.286 0 0 0 .285-.285Z"
                        transform="translate(-6.429 -5)"
                        style={{
                            fill: props?.fill ?? "#c1c5cb",
                        }}
                    />
                </g>
            </g>
        </g>
    </svg>
)
