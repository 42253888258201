import * as React from "react"

export const LocationTarget = (props) => {

    const {
        width = 16.492,
        height = 16.5,
    } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.492 16.5"
            width={width}
            height={height}
            {...props}
        >
            <path id="icons8-target" d="M9.987,1.978a.545.545,0,0,0-.537.553v1.3A6.186,6.186,0,0,0,3.845,9.437h-1.3a.545.545,0,1,0,0,1.09h1.3a6.186,6.186,0,0,0,5.606,5.606v1.3a.545.545,0,1,0,1.09,0v-1.3a6.186,6.186,0,0,0,5.606-5.606h1.3a.545.545,0,1,0,0-1.09h-1.3a6.186,6.186,0,0,0-5.606-5.606v-1.3a.545.545,0,0,0-.554-.553ZM9.931,4.9a.545.545,0,0,0,.132,0,5.076,5.076,0,0,1,5.017,5.02.545.545,0,0,0,0,.132,5.076,5.076,0,0,1-5.02,5.017.515.515,0,0,0-.133,0,5.076,5.076,0,0,1-5.017-5.02.545.545,0,0,0,0-.132A5.076,5.076,0,0,1,9.931,4.9ZM10,8.165a1.813,1.813,0,0,0-1.362.581,1.906,1.906,0,0,0,0,2.473,1.887,1.887,0,0,0,2.723,0,1.906,1.906,0,0,0,0-2.473A1.813,1.813,0,0,0,10,8.165Zm0,1.09a.654.654,0,0,1,.547.214.819.819,0,0,1,0,1.025.654.654,0,0,1-.547.214.654.654,0,0,1-.547-.214.819.819,0,0,1,0-1.025A.654.654,0,0,1,10,9.255Z" transform="translate(-1.75 -1.728)" fill="#98a0ac" stroke="#98a0ac" stroke-width="0.5" />
        </svg>
    )
}
