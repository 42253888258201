import * as React from "react"

const EmailIMG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 48450"
          transform="translate(.5)"
          fill="#4e5a6b"
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 96904">
      <g
        data-name="Mask Group 6285"
        transform="translate(-.5)"
        clipPath="url(#a)"
      >
        <path
          d="M6.9 5.6a2.4 2.4 0 0 0-2.392 2.2l7.992 4.316L20.492 7.8A2.4 2.4 0 0 0 18.1 5.6ZM4.5 9.156V16a2.4 2.4 0 0 0 2.4 2.4h11.2a2.4 2.4 0 0 0 2.4-2.4V9.156l-7.716 4.172a.6.6 0 0 1-.569 0Z"
          fill="#78b1fe"
        />
      </g>
    </g>
  </svg>
)

export default EmailIMG
