export const WorkerWalkIn = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105200">
            <g
                data-name="VS - Security Services - Workers Tracking"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path
                    data-name="Path 98377"
                    d="m20.362 16.371-1.207 1.871-.8-.322-2.785 5.081h3.272l1.434-1.9h1.23l1.317 1.9h1.109l-2.539-6.349Z"
                />
                <path data-name="Path 98378" d="M21.341 21.416h-.907L19.234 23h3.2Z" />
                <path
                    data-name="Path 98379"
                    d="m7.272 19.958-.942 3.041h1.307l1.046-3.033.3-2.357h1l.9 2.443-.547 2.943h1.258l.623-3.333-.623-1.757-.227-.795 3.433 1.948-.644 1.118 1.495.861 1.581-2.745-1.495-.861-.621 1.078-3.996-2.263-.215-.757.392-1.588.169 1.451.271.7 1.07.626-.444-1.577-.343-3.03-1.109-.046-.195-.536a1.69 1.69 0 0 0 1.343-1.569l-3.338-.443a1.685 1.685 0 0 0 .712 1.759h-.029l-.668.562-.856-.072L6 13.366v2.5l1.493.369Zm-.321-5v-.747l.634-.579V15.1Z"
                />
                <path
                    data-name="Path 98380"
                    d="m12.044 9.604.507.067c.067-1.508-.588-2.494-1.774-2.651a2.181 2.181 0 0 0-.289-.019c-1.051 0-1.815.769-2.114 2.117l.442.059Z"
                />
            </g>
        </g>
    </svg>
)