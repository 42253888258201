import * as React from "react"
const OwnerInActiveIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={0.449}
        x2={0.512}
        y1={-0.008}
        y2={0.992}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#33bef0" />
        <stop offset={1} stopColor="#0a85d9" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={0.5}
        x2={0.5}
        y1={19.557}
        y2={18.555}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#e5a505" />
        <stop offset={0.01} stopColor="#e9a804" />
        <stop offset={0.06} stopColor="#f4b102" />
        <stop offset={0.129} stopColor="#fbb600" />
        <stop offset={0.323} stopColor="#fdb700" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0.152}
        x2={0.797}
        y1={15.425}
        y2={14.488}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fede00" />
        <stop offset={1} stopColor="#ffd000" />
      </linearGradient>
      <radialGradient
        id="a"
        cx={0.072}
        cy={0.077}
        r={1.764}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c26715" />
        <stop offset={0.508} stopColor="#b85515" />
        <stop offset={1} stopColor="#ad3f16" />
      </radialGradient>
      <radialGradient xlinkHref="#a" id="c" cx={0.297} cy={0.562} r={4.693} />
    </defs>
    <g data-name="Group 113971">
      <g data-name="icons8-landlord (4)">
        <path
          fill="url(#b)"
          d="M15.442 30.571C11.332 30.571 8 32.86 8 35.355v.532a.93.93 0 0 0 .93.93h13.023a.93.93 0 0 0 .93-.93v-.532c.001-2.495-3.331-4.784-7.441-4.784Z"
          data-name="Path 100608"
          transform="translate(-5 -16.678)"
        />
        <path
          fill="#ecc255"
          d="M12.767 14.093c0 .882-2.326 3.256-2.326 3.256s-2.325-2.374-2.325-3.256v-3.272h4.651Z"
          data-name="Path 100609"
        />
        <path
          fill="#ecc255"
          d="M14.589 8.047h-.245v1.86h.327a.475.475 0 0 0 .484-.344l.16-.792a.662.662 0 0 0-.726-.724Z"
          data-name="Path 100610"
        />
        <path
          fill="#ecc255"
          d="M6.295 8.047h.245v1.86h-.327a.475.475 0 0 1-.484-.344l-.16-.792a.663.663 0 0 1 .726-.724Z"
          data-name="Path 100611"
        />
        <path
          fill="#ffd976"
          d="M10.442 2.651c-2.066 0-4.186.314-4.186 4.779v3.115c0 1.594 2.512 3.547 4.186 3.547s4.186-1.953 4.186-3.547V7.43c0-4.465-2.12-4.779-4.186-4.779Z"
          data-name="Path 100612"
        />
        <path
          fill="url(#a)"
          d="M18.651 5.545c-2.025 0-2.326.761-2.326.761A3.191 3.191 0 0 0 14 9.012a19.032 19.032 0 0 0 .465 3.256c.253-3.044 1.046-4.186 1.86-4.186.589 0 1.057.465 2.326.465 1.071 0 1.337-.465 2.326-.465 1.4 0 1.86 3.706 1.86 4.186a20.158 20.158 0 0 0 .463-3.256c0-1.595-1.761-3.467-4.649-3.467Z"
          data-name="Path 100613"
          transform="translate(-8.209 -3.292)"
        />
        <path
          fill="url(#c)"
          d="M21.428 5C19.491 5 19 6.015 19 6.015a2.2 2.2 0 0 0 .243 1.015s.353.761 2.372.761A2.808 2.808 0 0 0 24.582 5Z"
          data-name="Path 100614"
          transform="translate(-10.884 -3)"
        />
        <path
          fill="url(#d)"
          d="M40.093 41.791a.578.578 0 0 1 .233-.462v-.765a.461.461 0 0 1 0-.8V39H38v3.72l.465.466h1.311l.549-.466v-.467a.578.578 0 0 1-.232-.462Z"
          data-name="Path 100615"
          transform="translate(-21.047 -21.186)"
        />
        <path
          fill="url(#e)"
          d="M40.047 29.023a3.023 3.023 0 1 0-6.047 0c0 .079.006.155.012.233H34v1.86a.931.931 0 0 0 .93.93h4.186a.931.931 0 0 0 .93-.93v-1.86h-.012c.006-.077.013-.156.013-.233Zm-3.023-1.86a.93.93 0 1 1-.93.93.931.931 0 0 1 .929-.93Z"
          data-name="Path 100616"
          transform="translate(-18.907 -14.233)"
        />
      </g>
      <path
        fill="#f2f4f7"
        d="M0 0h24v24H0z"
        data-name="Rectangle 57633"
        style={{
          mixBlendMode: "color",
          isolation: "isolate",
        }}
      />
    </g>
  </svg>
)
export default OwnerInActiveIcon
