import React from "react"

export const EarnedLeaveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_106767" data-name="Group 106767" transform="translate(-3837 23632)">
            <g id="Rectangle_55654" data-name="Rectangle 55654" transform="translate(3837 -23632)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <path id="icons8-month-in-love" d="M8.6,6A2.6,2.6,0,0,0,6,8.6v.6H20.4V8.6A2.6,2.6,0,0,0,17.8,6ZM6,10.4v7.4a2.6,2.6,0,0,0,2.6,2.6h4.623a5.185,5.185,0,0,1,.336-6.07,1.02,1.02,0,1,1,.583-.606,5.178,5.178,0,0,1,6.259-.5V10.4Zm3.8,2a1,1,0,1,1-1,1A1,1,0,0,1,9.8,12.4Zm7.8.8A4.4,4.4,0,1,0,22,17.6,4.4,4.4,0,0,0,17.6,13.2Zm-1.2,2.4a1.4,1.4,0,0,1,1.2.691,1.4,1.4,0,1,1,2.231,1.653c-.008.008-.02.023-1.949,1.941a.4.4,0,0,1-.564,0c-1.929-1.918-1.942-1.932-1.951-1.942A1.4,1.4,0,0,1,16.4,15.6ZM9.8,16a1,1,0,1,1-1,1A1,1,0,0,1,9.8,16Z" transform="translate(3835 -23634)" fill="#52b2cf" />
        </g>
    </svg>
)