import React, { useEffect } from "react";
import { TreeComponent } from "./components";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Grid } from "@mui/material";
import { UserManageMentStylesParent } from "./style";
import { Subheader } from "./components";
import { PermisionList, Roles, Person } from "./screens";
import axios from "axios";
import { CustomSelect } from "./components/filterGenerator/components/select";
import { config } from "../../config";
import { NetWorkCallMethods } from "../../utils";
import { withNamespaces } from "react-i18next";
import { NetworkCall } from "../../networkcall";
// tab title
const tabTitle = (t) => [
  {
    title: t("Repository"),
    id: "1",
  },
  {
    title: t("Permission List"),
    id: "2",
  },
  {
    title: t("Roles"),
    id: "3",
  },
  {
    title: t("Person"),
    id: "4",
  },
];

const UserManageMent = ({
  t
}) => {
  // style
  const classes = UserManageMentStylesParent();
  // state
  const [value, setValue] = React.useState("1");
  const [repository, setRepository] = React.useState([]);
  const [modules, setModule] = React.useState("");
  const [moduleOption, setmoduleOption] = React.useState([]);
  // check box click
  const handleChange = (e, data) => {
    e.stopPropagation();
  };
  const selectModule = (e) => {
    setModule(e);
    getRepository(e?.value);
  };

  const getModule = () => {
    const payload = {}
    NetworkCall(
      `${config.api_url}/subscription/subscribe_modules`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        let result = res?.data?.data ?? []
        setmoduleOption(result);
        setModule(result?.[0])
        if (value === "1") {
          getRepository(result?.[0]?.value);
        }
      })
      .catch((err) => { });
  };

  const getRepository = (e) => {
    setRepository([])
    axios
      .get(
        `${config?.api_url}/iam/get_repository?module_id=${e ?? 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      )
      .then((res) => {
        setRepository(res?.data?.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {

    getModule();

    // eslint-disable-next-line
  }, [value]);

  return (
    <Box>
      {/* sub header */}
      <Subheader title={t("User Management")} hideBackButton={true} />
      <TabContext value={value}>
        {/* tab title */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            className={classes.tab}
            onChange={(event, newValue) => setValue(newValue)}
            aria-label="lab API tabs example"
          >
            {tabTitle(t)?.map((x) => {
              return <Tab label={x?.title} value={x?.id} className={classes.tabs} />;
            })}
          </TabList>
        </Box>
        {/* tab body */}
        <div className={classes.root}>
          <TabPanel value="1" className={classes.tab}>
            <div className={classes.rootSection}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <TreeComponent
                    TreeJson={repository}
                    handleChange={handleChange}
                    t={t}
                    noIcon
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomSelect
                    color={"#98A0AC"}
                    fontSize={12}
                    required
                    fontWeight={400}
                    placeholder={t("Select Module")}
                    options={moduleOption}
                    onChange={(value) => selectModule(value)}
                    value={modules}
                  />
                </Grid>
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value="2" className={classes.tab}>
            <PermisionList t={t} moduleOption={moduleOption} />
          </TabPanel>
          <TabPanel value="3" className={classes.tab}>
            <Roles t={t} moduleOption={moduleOption} />
          </TabPanel>
          <TabPanel value="4" className={classes.tab}>
            <div className={classes.rootSection}>
              <Person t={t} moduleOption={moduleOption} />
            </div>
          </TabPanel>
        </div>
      </TabContext>
    </Box>
  );
}
export default withNamespaces("userManageMent")(UserManageMent);
