import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    file_upload_root: {
        padding: "16px",
    },
    file_upload_content: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
    },
    table_root: {
        padding: "0px 16px 16px 16px",
    },
    table_content: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        // height: `calc(100vh - 377px)`,
        // overflow: "hidden",

    },
    title: {
        color: "#4E5A6B",
        fontSize:"0.875rem",
        fontFamily: Bold
    },
    root: {
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - 120px)`,
            overflow: "auto"
        },

    }
}));