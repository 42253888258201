import React from "react";
import VehicleMaster from "./vehicleMaster";
import { Box } from "@mui/material";
import { withNavBars } from "../../HOCs";
const VehicleMasterParent = () => {
    return (
        <Box>
            <VehicleMaster />
        </Box>
    )
}
const props = {
    boxShadow: false
}
export default withNavBars(VehicleMasterParent, props)