import { Box, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from "@mui/material"
import React from "react"
import { useStyles } from "./style"

export const ViewFeedBack = ({ t, details = {}, onCloseModal = () => false }) => {
    const classes = useStyles()
    return (
        <Box>
            {/* <Box className={classes.viewBox}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar variant="square" />
                    <Box>
                        <Typography className={classes.serviceText}>Water Leakage Repair</Typography>
                        <Stack mt={0.5} direction="row" alignItems="center" spacing={1}>
                            <Typography className={classes.serviceSubText}>MNT-112</Typography>
                            <Box className={classes.viewDot}></Box>
                            <Typography className={classes.serviceSubText}>Maintenance</Typography>
                        </Stack>
                    </Box>
                </Stack>
                <Box className={classes.requestBox}>
                    <Typography className={classes.requestNo}>TKT-1234</Typography>
                </Box>
            </Box> */}
            <Box p={3} height="450px" overflow={"scroll"}>
                <Grid container spacing={2}>
                    {details?.map((val, i) => {
                        return (
                            <>
                                <Grid item xs={12}>
                                    <Typography className={classes.question}>{`${val?.order}. ${val?.title}?`}</Typography>
                                    {val?.type === "boolean" ?
                                        <Stack mt={1} direction="row" alignItems="center">
                                            <FormControl  >
                                                <RadioGroup value={val?.value}>
                                                    {val?.data_type_options?.map((x) => {
                                                        return <FormControlLabel value={x?.value} control={<Radio size="small" className={classes.disablecheck} />} label={x?.label} />
                                                    })}
                                                </RadioGroup>
                                            </FormControl>


                                            {/* <Button variant="outlined">No</Button> */}
                                        </Stack>
                                        : val?.type === "check_box" ?
                                            <Box>
                                                {val?.data_type_options?.map((x) => {
                                                    return (
                                                        <Box>
                                                            <FormControlLabel
                                                                // value="mandatory"
                                                                control={<Checkbox size="small" checked={val?.value !== null && val?.value.includes(x.value)} className={classes.disablecheck} />}

                                                                label={<Typography className={classes.textView}>{x.label}</Typography>}
                                                                labelPlacement="end"
                                                            />
                                                        </Box>
                                                    )
                                                })}

                                            </Box>
                                            : val?.type === "text" &&

                                            <Box mt={1} className={classes.textViewBox}>
                                                <Typography className={classes.textView}>{val?.value ?? "-"}</Typography>
                                            </Box>
                                    }
                                </Grid>

                                {val?.type === "rating" &&
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={1.5}>

                                            {val?.data_type_options?.map((x) => {
                                                return (
                                                    <>
                                                        <Box>
                                                            <Box className={x?.value <= parseInt(val?.value) ? classes.selectedOption : classes.options}>{x?.value}</Box>
                                                            {/* <Typography className={classes.optionsSubText} noWrap>{x === 1 ? "Not Satisfied" : x === 5 ? "Very Satisfied" : ""}</Typography> */}
                                                        </Box>
                                                    </>
                                                )
                                            })}

                                        </Stack>
                                    </Grid>

                                }
                            </>
                        )
                    })}



                </Grid>
            </Box>
            {/* Close button not needed in this state, Because this only viewable popup */}
            {/* <Box mt={2} p={2} className={classes.closeBtnBox}>
                <Button variant="outlined" onClick={onCloseModal} className={classes.closebtn}>{t("Close")}</Button>
            </Box> */}

        </Box>
    )
}