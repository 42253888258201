import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Popover } from "@mui/material";
import React from "react";
import { PropertyDetails } from "../component/details";
import { resultStyles } from "./style";
import { DialogBox } from '../../../components';
import { ViewAllDetails } from '../component/viewAllDetails'

export const Details = (props) => {

    const language = localStorage.getItem("i18nextLng")
    const { t } = (props)
    const classes = resultStyles()
    const open = Boolean(props?.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        props?.setAnchorEl(null);
        //props?.setShortlistId([])
    };

    const [view, setView] = React.useState(false)

    const updateView = () => {
        setView(!view)
    }

    return (
        <div>
            <Popover
                id={id}
                open={open}
                className={classes.popoverroot}
                anchorEl={props?.anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: language === "ar" ? "right" : "left",
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: language === "ar" ? "right" : "left",
                }}

            >
                <Box display="flex" marginTop="10px">
                    <Box>
                        <div className={classes.dialogRoot}>
                            <PropertyDetails
                                revenue_type={props?.revenue_type}
                                grace={props?.grace}
                                startDate={props?.date?.fromDate}
                                endDate={props?.date?.toDate}
                                updateView={updateView}
                                t={t}
                                setShortlistId={props?.setShortlistId} shortlistid={props?.shortlistid} selectedProperty={props?.selectedProperty} />
                        </div>
                    </Box>
                    <Box>
                        <IconButton className={classes.dialogclose} onClick={handleClose}>
                            <CloseIcon style={{ fontSize: "1.25rem" }} />
                        </IconButton>
                    </Box>
                </Box>

            </Popover>

            {/* view all */}
            <DialogBox
                isnotTitle
                fullScreen
                handleClose={updateView}
                open={view}
                background={true}
                height={"100%"}
                borderRadius={"4px"}
                component={
                    <div>
                        <ViewAllDetails
                            revenue_type={props?.revenue_type}
                            grace={props?.grace}
                            startDate={props?.date?.fromDate}
                            endDate={props?.date?.toDate}
                            onClose={updateView}
                            btn={props?.btn}
                            setShortlistId={props?.setShortlistId} t={t} shortlistid={props?.shortlistid} selectedProperty={props?.selectedProperty} />
                    </div>
                }
            />
        </div>
    )
} 