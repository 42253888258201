export const PropertyPrefixIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 107951">
            <path
                data-name="icons8-company (2)"
                d="M4.909 5a.545.545 0 1 0 0 1.091h.182v7.286A1.275 1.275 0 0 0 4 14.636v3.636a.546.546 0 0 0 .545.545H8a.364.364 0 0 0 .364-.364v-2.544a.364.364 0 0 1 .364-.364h1.09a.364.364 0 0 1 .364.364v2.545a.364.364 0 0 0 .364.364H14a.546.546 0 0 0 .545-.545v-3.637a1.275 1.275 0 0 0-1.091-1.26V6.091h.182a.545.545 0 1 0 0-1.091Zm2.727 2.545h.727a.364.364 0 0 1 .364.364v.727A.364.364 0 0 1 8.364 9h-.728a.364.364 0 0 1-.364-.364v-.727a.364.364 0 0 1 .364-.364Zm2.545 0h.727a.364.364 0 0 1 .364.364v.727a.364.364 0 0 1-.363.364h-.727a.364.364 0 0 1-.364-.364v-.727a.364.364 0 0 1 .364-.364Zm4 0v5.31a2.012 2.012 0 0 1 1.091 1.781v3.636a1.26 1.26 0 0 1-.128.545h4.31a.545.545 0 0 0 .546-.544V9.545a2 2 0 0 0-2-2Zm-6.545 2.546h.727a.364.364 0 0 1 .364.364v.727a.364.364 0 0 1-.364.364h-.727a.364.364 0 0 1-.364-.364v-.727a.364.364 0 0 1 .364-.364Zm2.545 0h.727a.364.364 0 0 1 .364.364v.727a.364.364 0 0 1-.364.364h-.726a.364.364 0 0 1-.364-.364v-.727a.364.364 0 0 1 .364-.364Zm6.545 0h.727a.364.364 0 0 1 .364.364v.727a.364.364 0 0 1-.364.364h-.727a.364.364 0 0 1-.364-.364v-.727a.364.364 0 0 1 .365-.364Zm-9.09 2.545h.727a.364.364 0 0 1 .364.364v.727a.364.364 0 0 1-.364.364h-.727a.364.364 0 0 1-.364-.364V13a.364.364 0 0 1 .364-.364Zm2.545 0h.727a.364.364 0 0 1 .365.364v.727a.364.364 0 0 1-.364.364h-.727a.364.364 0 0 1-.364-.364V13a.364.364 0 0 1 .364-.364Zm6.545 0h.727a.364.364 0 0 1 .364.364v.727a.364.364 0 0 1-.364.364h-.727a.364.364 0 0 1-.364-.364V13a.364.364 0 0 1 .365-.364Zm0 2.545h.727a.364.364 0 0 1 .364.364v.727a.364.364 0 0 1-.364.364h-.727a.364.364 0 0 1-.364-.364v-.727a.364.364 0 0 1 .365-.363Zm-10.909.364h.727a.364.364 0 0 1 .364.364v.727a.364.364 0 0 1-.363.364h-.727a.364.364 0 0 1-.364-.364v-.727a.364.364 0 0 1 .364-.364Zm6.182 0h.727a.364.364 0 0 1 .364.364v.727a.364.364 0 0 1-.364.364H12a.364.364 0 0 1-.364-.364v-.727a.364.364 0 0 1 .364-.364Z"
                fill={props?.fill ?? "#98a0ac"}
            />
        </g>
    </svg>
)
