import { Box } from "@mui/material";
import React from "react";
import { NewLoader } from "../newLoader";



export const IframeViwer = ({
    root={},
    pdf={
        bool:false,
        data:""
    },
    height="125px"
}) => {
    
    return (
        <Box sx={{ ...root }}>
            {
                pdf?.bool ?
                    <iframe
                        src={`data:application/pdf;base64,${pdf?.data
                            }`}
                        alt="pdf"
                        width="100%"
                        style={{ height:`calc(100vh - ${height})` }}
                        title="quotation"

                    />

                    :
                    <NewLoader minusHeight={height}/>
            }
        </Box>
    )
}