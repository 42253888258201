import * as React from "react"

export const TableDoc = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={36} {...props}>
    <g data-name="Group 101566" transform="translate(-627 -395)">
      <rect
        data-name="Rectangle 53609"
        width={32}
        height={36}
        rx={4}
        transform="translate(627 395)"
        fill="#f1f7ff"
      />
      <path
        data-name="Path 96649"
        d="M639.5 414.25a.25.25 0 0 1 .25-.25H641v1.5h-1.5Zm0 2.75h1.5v1.5h-1.25a.25.25 0 0 1-.25-.25Zm7 1.25V417h-4v1.5h3.75a.25.25 0 0 0 .25-.25Zm-4-4.25v1.5h4v-1.25a.25.25 0 0 0-.25-.25Zm.5-5v-6h-6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-10h-6a2 2 0 0 1-2-2Zm-3.25 3.5h6.5a1.751 1.751 0 0 1 1.75 1.75v4a1.75 1.75 0 0 1-1.75 1.75h-6.5a1.75 1.75 0 0 1-1.75-1.75v-4a1.751 1.751 0 0 1 1.75-1.75Zm4.75-3.5v-5.5l6 6H645a.5.5 0 0 1-.5-.5Z"
        fill="#5078e1"
      />
    </g>
  </svg>
)
