import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';
// import MyGoogleMap from '../../../components/interactivemap/myGoogleMaps';
import { BuldingIcon, Location, PhoneIcon } from '../../../components/svg';
import { Bold, SemiBold } from '../../../utils';
import { CustomPaper, CustomTypography, IconWithText, TitleDesc } from '../components';
import { returnValue } from '../utils';
const Image = styled(Avatar)({
    width: 141,
    height: 141,
    borderRadius: 71,
    backgroundColor: "#F2F4F7",
})

export const CompanyProfileView = (props) => {
    const { t } = props

    const theme = useTheme();

    const {
        profileDetails = null,
        address = null,
        contactInformation = null,
        logo = null,
    } = props?.data ?? {};

    const constructAddress = (value = "", delimiter = "") => value ? value?.concat(",") : delimiter;


    return (
        <div>
            <CustomPaper height={'100%'} marginBottom={theme.spacing(3)}>
                <Stack
                    direction={{ sm: "row", xs: "column" }}
                    divider={<Divider flexItem orientation={"vertical"} />}
                    gap={{ xs: 2, sm: 3 }}
                    alignItems={{ sm: "center" }}
                >
                    {
                        <Image
                            src={logo?.src ? logo?.src : ""}
                            alt={"profile_img"}
                            sx={{
                                border: logo?.src ? 0 : '1px solid #e3e5ec'
                            }}
                        >
                            {!(!!logo?.src) && <BuldingIcon />}
                        </Image>
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomTypography
                                fontSize={12}
                                fontFamily={Bold}
                                // marginBottom={theme?.spacing(2)}
                                color={theme?.typography?.color?.secondary}
                            >
                                {t("Profile Details")}
                            </CustomTypography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={t("Company Code")}
                                desc={returnValue(profileDetails?.companyCode, "-")}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={t("Company Name")}
                                desc={returnValue(profileDetails?.companyName, "-")}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={t("Company Long Name")}
                                desc={returnValue(profileDetails?.companyLongName, "-")}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={t("Company's Country")}
                                desc={returnValue(profileDetails?.companyCountry?.label, '-')}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={t("Company Type")}
                                desc={returnValue(profileDetails?.companyType?.label, '-')}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TitleDesc
                                title={t("Status")}
                                desc={returnValue(profileDetails?.status?.label, '-')}
                            />
                        </Grid>
                        <Grid item xs={6} md={12}>
                            <TitleDesc
                                title={t("Business Description")}
                                dangerouslySetInnerHTML={returnValue(profileDetails?.businessDescription, "-")}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </CustomPaper>
            <CustomPaper>
                <Grid container spacing={2}>
                    {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4.5}
                        sx={{
                            height: { xs: "300px" }
                        }}
                    >
                        <div
                        style={{
                            borderRadius:4,
                            overflow:"hidden",
                            width:"100%",
                            height:"100%"
                        }}
                        >
                            <MyGoogleMap
                                lat={address?.latitude}
                                lng={address?.longitude}
                                center={{
                                    lat: address?.latitude,
                                    lng: address?.longitude
                                }}
                                readonly
                            />
                        </div>
                    </Grid> */}
                    {/* when map component is needed plz use below commented grid item */}
                    {/* <Grid item xs={12} sm={6} md={3}> */}
                    <Grid item xs={12} sm={6} md={6}>
                        <CustomPaper
                            border={"1px solid #E4E8EE"}
                            boxShadow={"none"}
                            borderRadius={4}
                            padding={theme.spacing(2)}
                            height={'100%'}
                        >
                            <IconWithText
                                icon={<Location />}
                                text={t("Address")}
                            />
                            <CustomTypography
                                fontFamily={SemiBold}
                                marginBottom={theme?.spacing(2)}
                                marginTop={theme?.spacing(2)}
                                color={theme?.typography?.color?.primary}
                            >
                                {/* {`23 Main Street, 3rd Cross street, 3rd Sector, Chennai, Tamilnadu, India -60001`} */}
                                {
                                    returnValue((`
                                    ${constructAddress(address?.doorNumber)}
                                    ${constructAddress(address?.addressLineOne)}
                                    ${constructAddress(address?.addressLineTwo)}
                                    ${constructAddress(address?.landmark)}
                                    ${constructAddress(address?.area)}
                                    ${constructAddress(address?.city)}
                                    ${constructAddress(address?.state)}
                                    ${constructAddress(address?.country)}
                                    ${returnValue(address?.pincode)}
                                    `)
                                        .trim(), "-"
                                    )
                                }
                            </CustomTypography>
                            <CustomTypography
                                fontSize={12}
                                fontFamily={SemiBold}
                                marginBottom={theme?.spacing(2)}
                                color={theme?.typography?.color?.tertiary}
                            >
                                {t("Latitude")} : {" "}
                                <CustomTypography
                                    component={"span"}
                                    fontSize={12}
                                    fontFamily={SemiBold}
                                    color={theme?.typography?.color?.primary}
                                >
                                    {returnValue(address?.latitude, '-')}
                                </CustomTypography>
                            </CustomTypography>
                            <CustomTypography
                                fontSize={12}
                                fontFamily={SemiBold}
                                // marginBottom={theme?.spacing(2)}
                                color={theme?.typography?.color?.tertiary}
                            >
                                {t("Longitude")} : {" "}
                                <CustomTypography
                                    component={"span"}
                                    fontSize={12}
                                    fontFamily={SemiBold}
                                    color={theme?.typography?.color?.primary}
                                >
                                    {returnValue(address?.longitude, '-')}
                                </CustomTypography>
                            </CustomTypography>
                        </CustomPaper>
                    </Grid>
                    {/* when map component is needed plz use below commented grid item */}
                    {/* <Grid item xs={12} sm={6} md={4.5}> */}
                    <Grid item xs={12} sm={6} md={6}>
                        <CustomPaper
                            border={"1px solid #E4E8EE"}
                            boxShadow={"none"}
                            borderRadius={4}
                            padding={theme.spacing(2)}
                            height={'100%'}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <IconWithText
                                        icon={<PhoneIcon />}
                                        text={t("Contact & Other Information")}
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <TitleDesc
                                        title={"Title"}
                                        desc={returnValue(contactInformation?.title, "-")}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={"Primary Contact"}
                                        desc={(
                                            returnValue((`${returnValue(contactInformation?.primaryContact?.mobile_code)} ${returnValue(contactInformation?.primaryContact?.mobile)}`).trim(), "-")
                                        )}
                                    />
                                </Grid> */}
                                <Grid item xs={6}>
                                    <TitleDesc
                                        direction="ltr"
                                        textAlign="-webkit-match-parent"
                                        title={t("Primary Phone Number")}
                                        desc={(
                                            returnValue((`${returnValue(contactInformation?.primaryTelephone?.mobile_code)} ${returnValue(contactInformation?.primaryTelephone?.mobile)}`)?.trim(), "-")
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        direction="ltr"
                                        textAlign="-webkit-match-parent"
                                        title={t("Primary Mobile Number")}
                                        desc={(
                                            returnValue((`${returnValue(contactInformation?.primaryMobile?.mobile_code)} ${returnValue(contactInformation?.primaryMobile?.mobile)}`)?.trim(), "-")
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={t("Email Address :")}
                                        desc={returnValue(contactInformation?.primaryEmailAddress, "-")}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TitleDesc
                                        title={t("Website")}
                                        desc={returnValue(contactInformation?.website, "-")}
                                    />
                                </Grid>
                            </Grid>
                        </CustomPaper>
                    </Grid>
                </Grid >
            </CustomPaper >
        </div >
    )
}