import { Box, Grid, Typography } from '@mui/material';
import styled from '@mui/material/styles/styled';
import React from 'react';
import { Editor } from '../../../components';

import { useStylesCreation } from '../../propertcreation/createproperty/style';
import { Bold } from '../../../utils';
const CustomTypography = styled(Typography)(({ theme }) => ({
    fontSize:"0.75rem",
    fontFamily: Bold,
    color: theme.typography.color.secondary,
    marginBottom: theme.spacing(1)
}));




export const TermsAndCondition = (props) => {

    const {
        data = {},
        updateState = null,
        t

    } = props;

    const classes = useStylesCreation();

    const {
        TermsAndConditionDetails = null,
    } = data;



    return (
        <div>
            <Box className={classes.imagebox1}>
                <CustomTypography>{t("Terms & Conditions")}</CustomTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Editor
                            height={"150px"}
                            value={TermsAndConditionDetails?.termsAndConditionDescription ?? ""}
                            label=""
                            handleChange={(e) => updateState("TermsAndConditionDetails", "termsAndConditionDescription", e)}
                            id={props?.id}
                        />
                    </Grid>

                </Grid>
            </Box>
        </div>
    )
}