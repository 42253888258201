import * as React from "react"

const FollowupIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={33} height={32} {...props}>
    <defs>
      <clipPath id="a">
        <rect
          data-name="Rectangle 53574"
          width={33}
          height={32}
          rx={8}
          transform="translate(.089)"
          fill="#35a27d"
        />
      </clipPath>
    </defs>
    <g data-name="Group 101836" transform="translate(-.089)">
      <rect
        data-name="Rectangle 53573"
        width={33}
        height={32}
        rx={8}
        transform="translate(.089)"
        fill="#35a27d"
      />
      <g data-name="Mask Group 6469" opacity={0.5} clipPath="url(#a)">
        <g data-name="Group 101835" fill="none">
          <g data-name="Group 101821">
            <g data-name="Path 96512">
              <path d="M10.121 5.645a12.121 12.121 0 0 1 16.513 4.402 12 12 0 0 1-4.424 16.431 12.121 12.121 0 0 1-16.512-4.403 12 12 0 0 1 4.423-16.43Z" />
              <path
                d="M10.6 6.523a10.964 10.964 0 0 0-5.15 6.71 10.908 10.908 0 0 0 1.113 8.34c3.057 5.27 9.853 7.08 15.15 4.038l.02-.012a10.964 10.964 0 0 0 5.15-6.71c.758-2.836.362-5.799-1.113-8.34-3.057-5.27-9.853-7.081-15.15-4.038l-.02.012m-.5-.866.022-.014c5.782-3.321 13.175-1.35 16.513 4.403 3.338 5.754 1.357 13.11-4.424 16.432-5.782 3.322-13.175 1.35-16.513-4.403C2.364 16.33 4.336 8.985 10.1 5.657Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96513">
              <path d="M11.103 7.333a10.155 10.155 0 0 1 13.835 3.689 10.051 10.051 0 0 1-3.706 13.767A10.155 10.155 0 0 1 7.395 21.1a10.051 10.051 0 0 1 3.708-13.767Z" />
              <path
                d="M11.583 8.21a9.025 9.025 0 0 0-4.24 5.523A8.978 8.978 0 0 0 8.26 20.6c2.517 4.337 8.112 5.828 12.473 3.323l.017-.01a9.025 9.025 0 0 0 4.24-5.523 8.978 8.978 0 0 0-.917-6.865C21.556 7.186 15.96 5.695 11.6 8.201l-.017.01m-.5-.867.02-.01c4.844-2.784 11.038-1.132 13.835 3.688 2.796 4.82 1.137 10.984-3.708 13.767-4.844 2.783-11.038 1.132-13.835-3.689-2.793-4.813-1.14-10.968 3.688-13.756Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96514">
              <path d="M12.083 9.022a8.19 8.19 0 0 1 11.157 2.975A8.106 8.106 0 0 1 20.25 23.1a8.19 8.19 0 0 1-11.157-2.975 8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M12.567 9.897a7.086 7.086 0 0 0-3.328 4.336 7.048 7.048 0 0 0 .72 5.39c1.976 3.406 6.37 4.576 9.794 2.609l.013-.008a7.086 7.086 0 0 0 3.329-4.336 7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.576-9.794-2.609l-.014.008m-.5-.866.016-.01c3.906-2.244 8.902-.912 11.157 2.976 2.256 3.887.917 8.858-2.99 11.102-3.906 2.245-8.901.913-11.157-2.975-2.252-3.882-.92-8.845 2.974-11.093Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96515">
              <path d="M13.063 10.712a6.224 6.224 0 0 1 8.48 2.262 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.48-2.263 6.16 6.16 0 0 1 2.272-8.437Z" />
              <path
                d="M13.551 11.585a5.147 5.147 0 0 0-2.418 3.149 5.118 5.118 0 0 0 .523 3.914c1.436 2.475 4.628 3.325 7.116 1.895l.01-.005a5.147 5.147 0 0 0 2.418-3.15 5.118 5.118 0 0 0-.522-3.913c-1.436-2.475-4.629-3.326-7.117-1.896l-.01.006m-.5-.866.012-.007c2.969-1.706 6.765-.694 8.48 2.26a6.16 6.16 0 0 1-2.273 8.439c-2.969 1.705-6.765.693-8.48-2.261a6.16 6.16 0 0 1 2.261-8.431Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96516">
              <path d="M14.206 12.682a3.931 3.931 0 0 1 5.357 1.428 3.891 3.891 0 0 1-1.435 5.331 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.435-5.33Z" />
              <path
                d="M14.699 13.552a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .292 2.192 2.934 2.934 0 0 0 3.994 1.062l.004-.002a2.885 2.885 0 0 0 1.356-1.766 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.994-1.062l-.004.003m-.5-.866.007-.004a3.931 3.931 0 0 1 5.356 1.428 3.89 3.89 0 0 1-1.435 5.329 3.931 3.931 0 0 1-5.356-1.428 3.89 3.89 0 0 1 1.428-5.325Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96517">
              <path d="M15.024 14.09a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.838 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .838-3.11Z" />
              <path
                d="M15.519 14.959a1.269 1.269 0 0 0-.468 1.738 1.294 1.294 0 0 0 1.764.466 1.269 1.269 0 0 0 .467-1.738 1.293 1.293 0 0 0-1.759-.469l-.004.003m-.5-.866.004-.003a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .833-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101822">
            <g data-name="Path 96518">
              <path d="M9.809-18.953a12.121 12.121 0 0 1 16.513 4.402 12 12 0 0 1-4.424 16.432A12.121 12.121 0 0 1 5.385-2.522a12 12 0 0 1 4.424-16.43Z" />
              <path
                d="M10.287-18.075a10.964 10.964 0 0 0-5.15 6.71A10.908 10.908 0 0 0 6.25-3.024c3.058 5.27 9.854 7.08 15.15 4.038l.021-.012a10.964 10.964 0 0 0 5.15-6.71c.757-2.837.362-5.799-1.113-8.341-3.057-5.27-9.853-7.08-15.15-4.038l-.02.012m-.5-.866.022-.013c5.782-3.321 13.175-1.35 16.513 4.403 3.338 5.753 1.356 13.11-4.425 16.432C16.117 5.203 8.723 3.23 5.385-2.522c-3.333-5.746-1.362-13.09 4.402-16.419Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96519">
              <path d="M10.79-17.264a10.155 10.155 0 0 1 13.835 3.688A10.051 10.051 0 0 1 20.92.192 10.155 10.155 0 0 1 7.082-3.497a10.051 10.051 0 0 1 3.708-13.767Z" />
              <path
                d="M11.27-16.387a9.025 9.025 0 0 0-4.239 5.523 8.978 8.978 0 0 0 .917 6.865C10.465.34 16.06 1.831 20.42-.675l.017-.01a9.025 9.025 0 0 0 4.24-5.523 8.978 8.978 0 0 0-.917-6.865c-2.517-4.338-8.111-5.829-12.472-3.323l-.017.01m-.5-.867.019-.01c4.844-2.784 11.038-1.132 13.835 3.688 2.797 4.82 1.137 10.984-3.707 13.767C16.074 2.975 9.88 1.324 7.083-3.497 4.29-8.31 5.942-14.465 10.77-17.253Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96520">
              <path d="M11.77-15.575A8.19 8.19 0 0 1 22.928-12.6a8.106 8.106 0 0 1-2.99 11.102A8.19 8.19 0 0 1 8.781-4.473a8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M12.255-14.7a7.086 7.086 0 0 0-3.329 4.336 7.048 7.048 0 0 0 .72 5.39c1.976 3.406 6.37 4.576 9.794 2.609l.014-.008a7.086 7.086 0 0 0 3.328-4.336 7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.577-9.794-2.609l-.013.008m-.5-.866.015-.01c3.907-2.244 8.902-.912 11.158 2.976 2.255 3.887.917 8.858-2.99 11.102-3.906 2.244-8.902.913-11.157-2.975-2.253-3.882-.92-8.845 2.974-11.093Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96521">
              <path d="M12.75-13.886a6.224 6.224 0 0 1 8.48 2.263 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.48-2.263 6.16 6.16 0 0 1 2.272-8.438Z" />
              <path
                d="M13.239-13.013a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .522 3.914c1.436 2.474 4.629 3.325 7.117 1.895l.01-.005a5.147 5.147 0 0 0 2.418-3.15 5.118 5.118 0 0 0-.523-3.914c-1.436-2.474-4.628-3.325-7.116-1.895l-.01.005m-.5-.866.011-.006c2.97-1.706 6.766-.694 8.48 2.26a6.16 6.16 0 0 1-2.272 8.438c-2.97 1.706-6.766.694-8.48-2.26a6.16 6.16 0 0 1 2.26-8.432Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96522">
              <path d="M13.894-11.915a3.931 3.931 0 0 1 5.356 1.428 3.891 3.891 0 0 1-1.434 5.331 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.435-5.33Z" />
              <path
                d="M14.387-11.045A2.885 2.885 0 0 0 13.03-9.28a2.866 2.866 0 0 0 .293 2.192 2.934 2.934 0 0 0 3.993 1.062l.005-.003a2.885 2.885 0 0 0 1.355-1.765 2.866 2.866 0 0 0-.292-2.192 2.934 2.934 0 0 0-3.994-1.062l-.004.003m-.5-.866.007-.005a3.931 3.931 0 0 1 5.356 1.428 3.89 3.89 0 0 1-1.436 5.33 3.931 3.931 0 0 1-5.355-1.428 3.89 3.89 0 0 1 1.428-5.325Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96523">
              <path d="M14.711-10.507a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.838 3.109 2.293 2.293 0 0 1-3.123-.833 2.27 2.27 0 0 1 .837-3.11Z" />
              <path
                d="M15.207-9.638a1.269 1.269 0 0 0-.468 1.738 1.294 1.294 0 0 0 1.763.466 1.269 1.269 0 0 0 .468-1.738 1.293 1.293 0 0 0-1.76-.469l-.003.003m-.5-.866.004-.003a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .833-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101823">
            <g data-name="Path 96524">
              <path d="M31.208 17.93a12.121 12.121 0 0 1 16.513 4.402 12 12 0 0 1-4.424 16.432 12.121 12.121 0 0 1-16.512-4.403 12 12 0 0 1 4.423-16.43Z" />
              <path
                d="M31.686 18.808a10.964 10.964 0 0 0-5.15 6.71 10.908 10.908 0 0 0 1.114 8.34c3.057 5.27 9.853 7.081 15.15 4.038l.02-.012a10.964 10.964 0 0 0 5.15-6.71c.758-2.836.362-5.798-1.113-8.34-3.057-5.27-9.853-7.08-15.15-4.038l-.02.012m-.5-.866.022-.013c5.782-3.322 13.175-1.35 16.513 4.403 3.338 5.753 1.357 13.11-4.424 16.432-5.782 3.321-13.175 1.35-16.513-4.403-3.334-5.746-1.362-13.091 4.401-16.419Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96525">
              <path d="M32.19 19.618a10.155 10.155 0 0 1 13.835 3.69 10.051 10.051 0 0 1-3.706 13.767 10.155 10.155 0 0 1-13.837-3.69 10.051 10.051 0 0 1 3.708-13.767Z" />
              <path
                d="M32.67 20.496a9.025 9.025 0 0 0-4.24 5.523 8.978 8.978 0 0 0 .917 6.865c2.517 4.338 8.112 5.829 12.473 3.324l.016-.01a9.025 9.025 0 0 0 4.24-5.523 8.978 8.978 0 0 0-.917-6.865c-2.516-4.338-8.111-5.83-12.472-3.324l-.017.01m-.5-.866.02-.011c4.843-2.783 11.038-1.132 13.834 3.689 2.797 4.82 1.138 10.984-3.707 13.767-4.844 2.783-11.038 1.131-13.835-3.689-2.793-4.814-1.141-10.968 3.688-13.756Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96526">
              <path d="M33.17 21.308a8.19 8.19 0 0 1 11.157 2.975 8.106 8.106 0 0 1-2.99 11.102A8.19 8.19 0 0 1 30.18 32.41a8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M33.654 22.182a7.086 7.086 0 0 0-3.328 4.336 7.048 7.048 0 0 0 .72 5.39c1.975 3.406 6.37 4.577 9.794 2.61l.013-.008a7.086 7.086 0 0 0 3.329-4.336 7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.577-9.795-2.61l-.013.008m-.5-.866.016-.009c3.906-2.244 8.902-.912 11.157 2.975 2.256 3.888.917 8.859-2.99 11.103-3.906 2.244-8.901.912-11.157-2.975-2.252-3.882-.92-8.845 2.974-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96527">
              <path d="M34.15 22.997a6.224 6.224 0 0 1 8.48 2.263 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.481-2.263 6.16 6.16 0 0 1 2.273-8.438Z" />
              <path
                d="M34.638 23.87a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .523 3.913c1.435 2.475 4.628 3.326 7.116 1.896l.01-.006a5.147 5.147 0 0 0 2.418-3.15 5.118 5.118 0 0 0-.523-3.913c-1.435-2.475-4.628-3.325-7.116-1.896l-.01.006m-.5-.866.012-.007c2.969-1.705 6.765-.693 8.48 2.261a6.16 6.16 0 0 1-2.273 8.438c-2.969 1.706-6.765.694-8.48-2.26a6.16 6.16 0 0 1 2.261-8.432Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96528">
              <path d="M35.293 24.967a3.931 3.931 0 0 1 5.357 1.428 3.891 3.891 0 0 1-1.435 5.332 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.435-5.33Z" />
              <path
                d="M35.786 25.837a2.885 2.885 0 0 0-1.355 1.766 2.866 2.866 0 0 0 .292 2.192 2.934 2.934 0 0 0 3.994 1.062l.004-.003a2.885 2.885 0 0 0 1.356-1.765 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.994-1.062l-.004.002m-.5-.866.007-.004a3.931 3.931 0 0 1 5.356 1.428 3.89 3.89 0 0 1-1.435 5.33 3.931 3.931 0 0 1-5.356-1.429 3.89 3.89 0 0 1 1.428-5.325Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96529">
              <path d="M36.11 26.375a2.293 2.293 0 0 1 3.125.834 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.124-.832 2.27 2.27 0 0 1 .837-3.11Z" />
              <path
                d="M36.606 27.244a1.269 1.269 0 0 0-.468 1.739 1.294 1.294 0 0 0 1.763.466 1.269 1.269 0 0 0 .468-1.738 1.293 1.293 0 0 0-1.759-.47l-.004.003m-.5-.866.004-.002a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.108 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .833-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101824">
            <g data-name="Path 96530">
              <path d="M-32.067 5.105a12.121 12.121 0 0 1 16.514 4.402 12 12 0 0 1-4.424 16.432 12.121 12.121 0 0 1-16.513-4.403 12 12 0 0 1 4.423-16.43Z" />
              <path
                d="M-31.588 5.983a10.964 10.964 0 0 0-5.15 6.71 10.908 10.908 0 0 0 1.113 8.34c3.057 5.27 9.853 7.081 15.15 4.039l.02-.013a10.964 10.964 0 0 0 5.151-6.709c.757-2.837.362-5.8-1.113-8.341-3.058-5.27-9.854-7.08-15.15-4.038l-.021.012m-.5-.866.023-.013c5.781-3.322 13.174-1.35 16.513 4.403 3.338 5.753 1.356 13.11-4.425 16.432-5.782 3.321-13.175 1.35-16.513-4.403-3.334-5.746-1.362-13.091 4.402-16.419Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96531">
              <path d="M-31.085 6.794a10.155 10.155 0 0 1 13.835 3.688 10.051 10.051 0 0 1-3.706 13.768 10.155 10.155 0 0 1-13.837-3.689 10.051 10.051 0 0 1 3.708-13.767Z" />
              <path
                d="M-30.604 7.671a9.025 9.025 0 0 0-4.24 5.523 8.978 8.978 0 0 0 .917 6.865c2.516 4.338 8.111 5.829 12.472 3.324l.017-.01a9.025 9.025 0 0 0 4.24-5.523 8.978 8.978 0 0 0-.917-6.865c-2.517-4.338-8.112-5.829-12.473-3.324l-.016.01m-.5-.866.019-.011c4.844-2.783 11.038-1.132 13.835 3.689 2.797 4.82 1.137 10.984-3.707 13.767-4.844 2.783-11.039 1.131-13.835-3.689-2.793-4.814-1.142-10.968 3.688-13.756Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96532">
              <path d="M-30.105 8.483a8.19 8.19 0 0 1 11.157 2.975 8.106 8.106 0 0 1-2.99 11.102 8.19 8.19 0 0 1-11.156-2.975 8.106 8.106 0 0 1 2.989-11.102Z" />
              <path
                d="M-29.62 9.357a7.086 7.086 0 0 0-3.33 4.337 7.048 7.048 0 0 0 .72 5.39c1.977 3.405 6.37 4.576 9.795 2.609l.013-.008a7.086 7.086 0 0 0 3.329-4.336 7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.577-9.794-2.61l-.013.008m-.5-.866.015-.009c3.907-2.244 8.902-.912 11.157 2.975 2.256 3.888.917 8.859-2.99 11.103-3.906 2.244-8.901.912-11.157-2.975-2.252-3.882-.92-8.845 2.975-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96533">
              <path d="M-29.125 10.172a6.224 6.224 0 0 1 8.48 2.263 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.48-2.263 6.16 6.16 0 0 1 2.272-8.438Z" />
              <path
                d="M-28.637 11.045a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .523 3.913c1.436 2.475 4.628 3.326 7.116 1.896l.01-.006a5.147 5.147 0 0 0 2.418-3.149 5.118 5.118 0 0 0-.522-3.914c-1.436-2.475-4.629-3.325-7.117-1.896l-.01.006m-.5-.866.012-.007c2.97-1.705 6.766-.693 8.48 2.261a6.16 6.16 0 0 1-2.272 8.438c-2.97 1.706-6.766.694-8.48-2.26a6.16 6.16 0 0 1 2.26-8.432Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96534">
              <path d="M-27.982 12.143a3.931 3.931 0 0 1 5.357 1.428 3.891 3.891 0 0 1-1.435 5.331 3.931 3.931 0 0 1-5.356-1.43 3.891 3.891 0 0 1 1.434-5.33Z" />
              <path
                d="M-27.489 13.013a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .293 2.192 2.934 2.934 0 0 0 3.993 1.062l.005-.003a2.885 2.885 0 0 0 1.355-1.765 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.993-1.062l-.005.003m-.5-.866.008-.005a3.931 3.931 0 0 1 5.355 1.428 3.89 3.89 0 0 1-1.435 5.33 3.931 3.931 0 0 1-5.355-1.429 3.89 3.89 0 0 1 1.427-5.324Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96535">
              <path d="M-27.164 13.55a2.293 2.293 0 0 1 3.124.834 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.124-.832 2.27 2.27 0 0 1 .838-3.11Z" />
              <path
                d="M-26.669 14.42a1.269 1.269 0 0 0-.468 1.738 1.294 1.294 0 0 0 1.764.466 1.269 1.269 0 0 0 .468-1.738 1.293 1.293 0 0 0-1.76-.47l-.004.003m-.5-.866.004-.002a2.293 2.293 0 0 1 3.125.833 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.124-.832 2.27 2.27 0 0 1 .833-3.107Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101825">
            <g data-name="Path 96536">
              <path d="M-10.667 41.988A12.121 12.121 0 0 1 5.846 46.39 12 12 0 0 1 1.422 62.82a12.121 12.121 0 0 1-16.513-4.402 12 12 0 0 1 4.424-16.431Z" />
              <path
                d="M-10.189 42.866a10.964 10.964 0 0 0-5.15 6.71 10.908 10.908 0 0 0 1.113 8.34c3.058 5.27 9.854 7.081 15.15 4.038l.021-.012a10.964 10.964 0 0 0 5.15-6.71c.757-2.836.362-5.798-1.113-8.34-3.057-5.27-9.853-7.081-15.15-4.038l-.02.012m-.5-.866.022-.013c5.782-3.322 13.175-1.35 16.513 4.403C9.185 52.143 7.203 59.5 1.422 62.82c-5.781 3.322-13.175 1.35-16.513-4.403-3.333-5.745-1.362-13.09 4.402-16.418Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96537">
              <path d="M-9.686 43.676A10.155 10.155 0 0 1 4.15 47.365 10.051 10.051 0 0 1 .443 61.132a10.155 10.155 0 0 1-13.837-3.688 10.051 10.051 0 0 1 3.708-13.768Z" />
              <path
                d="M-9.205 44.554a9.025 9.025 0 0 0-4.24 5.522 8.978 8.978 0 0 0 .917 6.866c2.517 4.338 8.112 5.829 12.472 3.323l.017-.01a9.025 9.025 0 0 0 4.24-5.522 8.978 8.978 0 0 0-.917-6.866C.767 43.53-4.828 42.04-9.188 44.544l-.017.01m-.5-.866.019-.011c4.844-2.783 11.038-1.132 13.835 3.689C6.946 52.186 5.286 58.35.442 61.133c-4.844 2.783-11.038 1.131-13.835-3.69-2.793-4.813-1.141-10.967 3.688-13.755Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96538">
              <path d="M-8.706 45.366A8.19 8.19 0 0 1 2.452 48.34a8.106 8.106 0 0 1-2.99 11.103 8.19 8.19 0 0 1-11.157-2.975 8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M-8.221 46.24a7.086 7.086 0 0 0-3.329 4.336 7.048 7.048 0 0 0 .72 5.39c1.976 3.406 6.37 4.577 9.794 2.61l.014-.008a7.086 7.086 0 0 0 3.328-4.336 7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.577-9.794-2.61l-.013.008m-.5-.866.015-.009c3.907-2.244 8.902-.912 11.158 2.975 2.255 3.887.917 8.858-2.99 11.103-3.906 2.244-8.902.912-11.157-2.975-2.253-3.882-.92-8.845 2.974-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96539">
              <path d="M-7.726 47.055a6.224 6.224 0 0 1 8.48 2.263 6.16 6.16 0 0 1-2.272 8.437 6.224 6.224 0 0 1-8.48-2.262 6.16 6.16 0 0 1 2.272-8.438Z" />
              <path
                d="M-7.237 47.928a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .522 3.913c1.436 2.475 4.629 3.325 7.117 1.896l.01-.006a5.147 5.147 0 0 0 2.418-3.15 5.118 5.118 0 0 0-.523-3.913c-1.436-2.475-4.628-3.325-7.116-1.896l-.01.006m-.5-.866.011-.007c2.97-1.706 6.766-.693 8.48 2.261a6.16 6.16 0 0 1-2.272 8.438c-2.97 1.706-6.766.693-8.48-2.261a6.16 6.16 0 0 1 2.26-8.431Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96540">
              <path d="M-6.582 49.025a3.931 3.931 0 0 1 5.356 1.428 3.891 3.891 0 0 1-1.434 5.332 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.435-5.33Z" />
              <path
                d="M-6.09 49.895a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .293 2.192 2.934 2.934 0 0 0 3.993 1.062l.005-.002A2.885 2.885 0 0 0-1.8 53.147a2.866 2.866 0 0 0-.292-2.192 2.934 2.934 0 0 0-3.994-1.062l-.004.002m-.5-.866.007-.004a3.931 3.931 0 0 1 5.355 1.428 3.89 3.89 0 0 1-1.435 5.33 3.931 3.931 0 0 1-5.355-1.429A3.89 3.89 0 0 1-6.59 49.03Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96541">
              <path d="M-5.765 50.433a2.293 2.293 0 0 1 3.124.834 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .838-3.109Z" />
              <path
                d="M-5.27 51.302a1.269 1.269 0 0 0-.467 1.738 1.294 1.294 0 0 0 1.763.467 1.269 1.269 0 0 0 .468-1.739 1.293 1.293 0 0 0-1.76-.468l-.004.002m-.5-.866.005-.002a2.293 2.293 0 0 1 3.124.832 2.27 2.27 0 0 1-.837 3.11 2.293 2.293 0 0 1-3.124-.834 2.27 2.27 0 0 1 .832-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101826">
            <g data-name="Path 96542">
              <path d="M-10.817 17.673a12.121 12.121 0 0 1 16.514 4.402 12 12 0 0 1-4.424 16.431 12.121 12.121 0 0 1-16.513-4.402 12 12 0 0 1 4.423-16.431Z" />
              <path
                d="M-10.338 18.551a10.964 10.964 0 0 0-5.15 6.71 10.908 10.908 0 0 0 1.113 8.34c3.057 5.27 9.853 7.081 15.15 4.038l.02-.012a10.964 10.964 0 0 0 5.15-6.71c.758-2.836.362-5.798-1.113-8.34-3.057-5.27-9.853-7.08-15.15-4.038l-.02.012m-.5-.866.023-.013c5.781-3.322 13.174-1.35 16.512 4.403 3.339 5.753 1.357 13.11-4.424 16.431-5.782 3.322-13.175 1.351-16.513-4.402-3.334-5.746-1.362-13.091 4.402-16.419Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96543">
              <path d="M-9.835 19.361A10.155 10.155 0 0 1 4 23.051 10.051 10.051 0 0 1 .294 36.816a10.155 10.155 0 0 1-13.837-3.688A10.051 10.051 0 0 1-9.835 19.36Z" />
              <path
                d="M-9.355 20.239a9.025 9.025 0 0 0-4.24 5.523 8.978 8.978 0 0 0 .917 6.865c2.517 4.338 8.112 5.829 12.473 3.323l.017-.01a9.025 9.025 0 0 0 4.24-5.522 8.978 8.978 0 0 0-.917-6.865c-2.517-4.338-8.112-5.83-12.473-3.324l-.017.01m-.5-.866.02-.011C-4.991 16.579 1.203 18.23 4 23.05 6.796 27.87 5.137 34.035.293 36.818c-4.845 2.783-11.039 1.131-13.836-3.69-2.793-4.813-1.14-10.967 3.688-13.755Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96544">
              <path d="M-8.855 21.05a8.19 8.19 0 0 1 11.157 2.976 8.106 8.106 0 0 1-2.99 11.102 8.19 8.19 0 0 1-11.157-2.975 8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M-8.37 21.925a7.086 7.086 0 0 0-3.33 4.336 7.048 7.048 0 0 0 .72 5.39c1.977 3.406 6.37 4.577 9.795 2.61l.013-.008a7.086 7.086 0 0 0 3.329-4.336 7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.577-9.794-2.61l-.013.008m-.5-.866.015-.009c3.907-2.244 8.902-.912 11.157 2.975 2.256 3.888.917 8.859-2.99 11.103-3.906 2.244-8.901.912-11.157-2.975-2.252-3.882-.92-8.845 2.975-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96545">
              <path d="M-7.875 22.74a6.224 6.224 0 0 1 8.48 2.263 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.48-2.263 6.16 6.16 0 0 1 2.272-8.438Z" />
              <path
                d="M-7.387 23.613a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .523 3.913c1.436 2.475 4.628 3.325 7.116 1.896l.01-.006a5.147 5.147 0 0 0 2.418-3.15 5.118 5.118 0 0 0-.522-3.913c-1.436-2.475-4.629-3.325-7.117-1.896l-.01.006m-.5-.866.012-.007c2.969-1.706 6.765-.693 8.48 2.261a6.16 6.16 0 0 1-2.273 8.438c-2.969 1.706-6.765.694-8.48-2.26a6.16 6.16 0 0 1 2.261-8.432Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96546">
              <path d="M-6.732 24.71a3.931 3.931 0 0 1 5.357 1.428A3.891 3.891 0 0 1-2.81 31.47a3.931 3.931 0 0 1-5.356-1.43 3.891 3.891 0 0 1 1.434-5.33Z" />
              <path
                d="M-6.239 25.58a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .292 2.192A2.934 2.934 0 0 0-3.308 30.6l.004-.003a2.885 2.885 0 0 0 1.356-1.765 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.993-1.062l-.005.002m-.5-.866.008-.004a3.931 3.931 0 0 1 5.355 1.428 3.89 3.89 0 0 1-1.435 5.33 3.931 3.931 0 0 1-5.356-1.429 3.89 3.89 0 0 1 1.428-5.325Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96547">
              <path d="M-5.914 26.118a2.293 2.293 0 0 1 3.124.834 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.124-.832 2.27 2.27 0 0 1 .838-3.11Z" />
              <path
                d="M-5.419 26.987a1.269 1.269 0 0 0-.468 1.739 1.294 1.294 0 0 0 1.764.466 1.269 1.269 0 0 0 .467-1.739 1.293 1.293 0 0 0-1.759-.468l-.004.002m-.5-.866.004-.002a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.108 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .833-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101827">
            <g data-name="Path 96548">
              <path d="M-11.13-6.924A12.121 12.121 0 0 1 5.385-2.522 12 12 0 0 1 .96 13.909a12.121 12.121 0 0 1-16.512-4.402A12 12 0 0 1-11.13-6.924Z" />
              <path
                d="M-10.65-6.046a10.964 10.964 0 0 0-5.151 6.71 10.908 10.908 0 0 0 1.113 8.34c3.058 5.27 9.854 7.081 15.15 4.038l.021-.012a10.964 10.964 0 0 0 5.15-6.71C6.39 3.485 5.996.523 4.52-2.02 1.463-7.29-5.333-9.102-10.63-6.059l-.02.012m-.5-.866.022-.013c5.782-3.322 13.175-1.35 16.513 4.403C8.723 3.23 6.742 10.588.96 13.909c-5.781 3.322-13.174 1.35-16.513-4.403-3.333-5.745-1.362-13.09 4.402-16.418Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96549">
              <path d="M-10.148-5.236A10.155 10.155 0 0 1 3.687-1.547 10.051 10.051 0 0 1-.018 12.22a10.155 10.155 0 0 1-13.838-3.688 10.051 10.051 0 0 1 3.708-13.768Z" />
              <path
                d="M-9.667-4.358a9.025 9.025 0 0 0-4.24 5.522 8.978 8.978 0 0 0 .917 6.866c2.517 4.338 8.112 5.829 12.472 3.323l.017-.01a9.025 9.025 0 0 0 4.24-5.522 8.978 8.978 0 0 0-.917-6.866C.306-5.382-5.289-6.873-9.65-4.368l-.017.01m-.5-.866.02-.011C-5.305-8.018.89-6.367 3.686-1.546 6.484 3.274 4.824 9.438-.02 12.22c-4.844 2.783-11.038 1.131-13.835-3.69-2.793-4.813-1.141-10.967 3.688-13.755Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96550">
              <path d="M-9.168-3.546A8.19 8.19 0 0 1 1.99-.572 8.106 8.106 0 0 1-1 10.531a8.19 8.19 0 0 1-11.157-2.975 8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M-8.683-2.672a7.086 7.086 0 0 0-3.329 4.336 7.048 7.048 0 0 0 .72 5.39c1.976 3.406 6.37 4.577 9.794 2.61l.014-.008A7.086 7.086 0 0 0 1.844 5.32a7.048 7.048 0 0 0-.72-5.39C-.85-3.476-5.245-4.647-8.67-2.68l-.013.008m-.5-.866.015-.009C-5.26-5.79-.266-4.459 1.99-.572 4.245 3.315 2.907 8.286-1 10.531c-3.906 2.244-8.902.912-11.157-2.975-2.253-3.882-.92-8.845 2.974-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96551">
              <path d="M-8.188-1.857A6.224 6.224 0 0 1 .293.406 6.16 6.16 0 0 1-1.98 8.843a6.224 6.224 0 0 1-8.48-2.262 6.16 6.16 0 0 1 2.272-8.438Z" />
              <path
                d="M-7.7-.984a5.147 5.147 0 0 0-2.417 3.15 5.118 5.118 0 0 0 .522 3.913c1.436 2.475 4.629 3.325 7.117 1.896l.01-.006A5.147 5.147 0 0 0-.05 4.82 5.118 5.118 0 0 0-.573.907C-2.009-1.57-5.2-2.42-7.689-.99l-.01.006m-.5-.866.012-.007C-5.218-3.563-1.422-2.55.293.404A6.16 6.16 0 0 1-1.98 8.842c-2.969 1.706-6.765.693-8.48-2.261A6.16 6.16 0 0 1-8.2-1.85Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96552">
              <path d="M-7.044.113a3.931 3.931 0 0 1 5.356 1.428 3.891 3.891 0 0 1-1.434 5.332 3.931 3.931 0 0 1-5.357-1.43A3.891 3.891 0 0 1-7.044.112Z" />
              <path
                d="M-6.551.983a2.885 2.885 0 0 0-1.356 1.765 2.866 2.866 0 0 0 .293 2.192A2.934 2.934 0 0 0-3.62 6.002L-3.616 6a2.885 2.885 0 0 0 1.355-1.765 2.866 2.866 0 0 0-.292-2.192A2.934 2.934 0 0 0-6.547.98l-.004.002m-.5-.866.007-.004A3.931 3.931 0 0 1-1.688 1.54a3.89 3.89 0 0 1-1.435 5.33A3.931 3.931 0 0 1-8.48 5.441 3.89 3.89 0 0 1-7.05.117Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96553">
              <path d="M-6.227 1.521a2.293 2.293 0 0 1 3.124.834 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.123-.833 2.27 2.27 0 0 1 .837-3.109Z" />
              <path
                d="M-5.731 2.39A1.269 1.269 0 0 0-6.2 4.128a1.294 1.294 0 0 0 1.763.467 1.269 1.269 0 0 0 .468-1.739 1.293 1.293 0 0 0-1.76-.468l-.003.002m-.5-.866.004-.003a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.11 2.293 2.293 0 0 1-3.124-.834 2.27 2.27 0 0 1 .833-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101828">
            <g data-name="Path 96554">
              <path d="M10.27 29.96a12.121 12.121 0 0 1 16.514 4.401 12 12 0 0 1-4.424 16.432A12.121 12.121 0 0 1 5.847 46.39a12 12 0 0 1 4.424-16.43Z" />
              <path
                d="M10.75 30.837a10.964 10.964 0 0 0-5.151 6.71 10.908 10.908 0 0 0 1.113 8.341c3.057 5.27 9.854 7.08 15.15 4.038l.021-.012a10.964 10.964 0 0 0 5.15-6.71c.757-2.837.362-5.799-1.113-8.341-3.057-5.27-9.854-7.08-15.15-4.038l-.02.012m-.5-.866.022-.013c5.782-3.321 13.175-1.35 16.513 4.403 3.338 5.753 1.356 13.11-4.425 16.432-5.782 3.322-13.175 1.35-16.513-4.403-3.333-5.746-1.362-13.09 4.402-16.419Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96555">
              <path d="M11.252 31.648a10.155 10.155 0 0 1 13.835 3.688 10.051 10.051 0 0 1-3.706 13.768 10.155 10.155 0 0 1-13.837-3.689 10.051 10.051 0 0 1 3.708-13.767Z" />
              <path
                d="M11.733 32.525a9.025 9.025 0 0 0-4.24 5.523 8.978 8.978 0 0 0 .917 6.865c2.517 4.338 8.111 5.83 12.472 3.324l.017-.01a9.025 9.025 0 0 0 4.24-5.523 8.978 8.978 0 0 0-.917-6.865C21.705 31.5 16.11 30.01 11.75 32.516l-.017.01m-.5-.867.019-.01c4.844-2.784 11.038-1.132 13.835 3.688 2.797 4.82 1.137 10.984-3.707 13.767-4.844 2.783-11.038 1.132-13.835-3.689-2.793-4.814-1.141-10.968 3.688-13.756Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96556">
              <path d="M12.232 33.337a8.19 8.19 0 0 1 11.157 2.975A8.106 8.106 0 0 1 20.4 47.414 8.19 8.19 0 0 1 9.243 44.44a8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M12.717 34.212a7.086 7.086 0 0 0-3.329 4.336 7.048 7.048 0 0 0 .72 5.39c1.976 3.406 6.37 4.576 9.794 2.609l.013-.008a7.086 7.086 0 0 0 3.33-4.336 7.048 7.048 0 0 0-.72-5.39c-1.977-3.406-6.37-4.576-9.795-2.609l-.013.008m-.5-.866.015-.01c3.907-2.244 8.902-.912 11.158 2.976 2.255 3.887.916 8.858-2.99 11.102-3.907 2.245-8.902.913-11.157-2.975-2.253-3.882-.92-8.845 2.974-11.093Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96557">
              <path d="M13.212 35.026a6.224 6.224 0 0 1 8.48 2.263 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.48-2.263 6.16 6.16 0 0 1 2.272-8.438Z" />
              <path
                d="M13.7 35.9a5.147 5.147 0 0 0-2.417 3.149 5.118 5.118 0 0 0 .522 3.914c1.436 2.474 4.628 3.325 7.117 1.895l.01-.005a5.147 5.147 0 0 0 2.417-3.15 5.118 5.118 0 0 0-.522-3.914c-1.436-2.474-4.628-3.325-7.117-1.895l-.01.005m-.5-.866.012-.006c2.97-1.706 6.766-.694 8.48 2.26a6.16 6.16 0 0 1-2.272 8.438c-2.97 1.706-6.766.694-8.48-2.26a6.16 6.16 0 0 1 2.26-8.432Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96558">
              <path d="M14.355 36.997a3.931 3.931 0 0 1 5.357 1.428 3.891 3.891 0 0 1-1.434 5.331 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.434-5.33Z" />
              <path
                d="M14.848 37.867a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .293 2.192 2.934 2.934 0 0 0 3.993 1.062l.005-.003a2.885 2.885 0 0 0 1.355-1.765 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.993-1.062l-.005.003m-.5-.866.008-.005a3.931 3.931 0 0 1 5.355 1.428 3.89 3.89 0 0 1-1.435 5.33 3.931 3.931 0 0 1-5.355-1.428A3.89 3.89 0 0 1 14.348 37Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96559">
              <path d="M15.173 38.405a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.838 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .838-3.11Z" />
              <path
                d="M15.668 39.274a1.269 1.269 0 0 0-.467 1.738 1.294 1.294 0 0 0 1.763.466 1.269 1.269 0 0 0 .468-1.738 1.293 1.293 0 0 0-1.76-.469l-.004.003m-.5-.866.005-.003a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .832-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101829">
            <g data-name="Path 96560">
              <path d="M31.058-6.385a12.121 12.121 0 0 1 16.514 4.402 12 12 0 0 1-4.424 16.432 12.121 12.121 0 0 1-16.513-4.403 12 12 0 0 1 4.423-16.43Z" />
              <path
                d="M31.537-5.507a10.964 10.964 0 0 0-5.15 6.71A10.908 10.908 0 0 0 27.5 9.544c3.057 5.27 9.854 7.08 15.15 4.038l.021-.012a10.964 10.964 0 0 0 5.15-6.71c.757-2.837.362-5.8-1.113-8.341-3.057-5.27-9.854-7.08-15.15-4.038l-.021.012m-.5-.866.023-.013c5.782-3.322 13.175-1.35 16.513 4.403 3.338 5.753 1.356 13.11-4.425 16.432-5.782 3.321-13.175 1.35-16.513-4.403C23.302 4.3 25.273-3.045 31.037-6.373Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96561">
              <path d="M32.04-4.696a10.155 10.155 0 0 1 13.835 3.688A10.051 10.051 0 0 1 42.17 12.76 10.155 10.155 0 0 1 28.332 9.07 10.051 10.051 0 0 1 32.04-4.696Z" />
              <path
                d="M32.52-3.819a9.025 9.025 0 0 0-4.239 5.523 8.978 8.978 0 0 0 .917 6.865c2.516 4.338 8.111 5.829 12.472 3.324l.017-.01a9.025 9.025 0 0 0 4.24-5.523 8.978 8.978 0 0 0-.917-6.865c-2.517-4.338-8.112-5.829-12.472-3.324l-.017.01m-.5-.866.019-.011c4.844-2.783 11.038-1.131 13.835 3.689 2.797 4.82 1.137 10.984-3.707 13.767-4.844 2.783-11.038 1.131-13.835-3.689C25.54 4.257 27.192-1.897 32.02-4.685Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96562">
              <path d="M33.02-3.007A8.19 8.19 0 0 1 44.177-.032a8.106 8.106 0 0 1-2.989 11.102 8.19 8.19 0 0 1-11.157-2.975 8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M33.505-2.133a7.086 7.086 0 0 0-3.329 4.337 7.048 7.048 0 0 0 .72 5.39c1.976 3.405 6.37 4.576 9.794 2.609l.013-.008a7.086 7.086 0 0 0 3.33-4.336 7.048 7.048 0 0 0-.72-5.39c-1.977-3.406-6.37-4.577-9.795-2.61l-.013.008m-.5-.866.015-.008c3.907-2.245 8.902-.913 11.158 2.974 2.255 3.888.916 8.859-2.99 11.103-3.907 2.244-8.902.912-11.157-2.975-2.253-3.882-.92-8.845 2.974-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96563">
              <path d="M34-1.318A6.224 6.224 0 0 1 42.48.945a6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.48-2.263A6.16 6.16 0 0 1 34-1.318Z" />
              <path
                d="M34.489-.445a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .522 3.913c1.436 2.475 4.628 3.326 7.117 1.896l.01-.006a5.147 5.147 0 0 0 2.417-3.149 5.118 5.118 0 0 0-.522-3.914C40.179-1.03 36.987-1.88 34.498-.45l-.01.005m-.5-.866L34-1.318c2.97-1.705 6.766-.693 8.48 2.261a6.16 6.16 0 0 1-2.272 8.438c-2.97 1.706-6.766.694-8.48-2.26a6.16 6.16 0 0 1 2.26-8.432Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96564">
              <path d="M35.143.653A3.931 3.931 0 0 1 40.5 2.08a3.891 3.891 0 0 1-1.434 5.331 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.434-5.33Z" />
              <path
                d="M35.636 1.523a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .293 2.192 2.934 2.934 0 0 0 3.993 1.062l.005-.003a2.885 2.885 0 0 0 1.355-1.765 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.993-1.062l-.005.003m-.5-.866.008-.005a3.931 3.931 0 0 1 5.355 1.428 3.89 3.89 0 0 1-1.435 5.33 3.931 3.931 0 0 1-5.355-1.428A3.89 3.89 0 0 1 35.136.657Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96565">
              <path d="M35.96 2.06a2.293 2.293 0 0 1 3.125.834 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.124-.832 2.27 2.27 0 0 1 .838-3.11Z" />
              <path
                d="M36.456 2.93a1.269 1.269 0 0 0-.467 1.738 1.294 1.294 0 0 0 1.763.466 1.269 1.269 0 0 0 .468-1.738 1.293 1.293 0 0 0-1.76-.47l-.004.003m-.5-.866.005-.002a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .832-3.107Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101830">
            <g data-name="Path 96566">
              <path d="M30.746-30.982A12.121 12.121 0 0 1 47.26-26.58a12 12 0 0 1-4.424 16.431 12.121 12.121 0 0 1-16.512-4.402 12 12 0 0 1 4.423-16.431Z" />
              <path
                d="M31.225-30.104a10.964 10.964 0 0 0-5.15 6.71 10.908 10.908 0 0 0 1.113 8.34c3.057 5.27 9.853 7.081 15.15 4.038l.02-.012a10.964 10.964 0 0 0 5.15-6.71c.758-2.836.362-5.798-1.113-8.34-3.057-5.27-9.853-7.08-15.15-4.038l-.02.012m-.5-.866.022-.013c5.782-3.322 13.175-1.35 16.513 4.403 3.338 5.753 1.357 13.11-4.424 16.432-5.782 3.321-13.175 1.35-16.513-4.403-3.334-5.746-1.362-13.091 4.402-16.419Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96567">
              <path d="M31.728-29.294a10.155 10.155 0 0 1 13.835 3.69 10.051 10.051 0 0 1-3.706 13.767 10.155 10.155 0 0 1-13.837-3.69 10.051 10.051 0 0 1 3.708-13.767Z" />
              <path
                d="M32.208-28.416a9.025 9.025 0 0 0-4.24 5.523 8.978 8.978 0 0 0 .917 6.865c2.517 4.338 8.112 5.829 12.473 3.323l.017-.01a9.025 9.025 0 0 0 4.24-5.522 8.978 8.978 0 0 0-.917-6.865c-2.517-4.338-8.112-5.83-12.473-3.324l-.017.01m-.5-.866.02-.011c4.844-2.783 11.038-1.132 13.835 3.689 2.796 4.82 1.137 10.984-3.708 13.767-4.844 2.783-11.038 1.131-13.835-3.689-2.793-4.814-1.14-10.968 3.688-13.756Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96568">
              <path d="M32.708-27.604a8.19 8.19 0 0 1 11.157 2.975 8.106 8.106 0 0 1-2.99 11.102 8.19 8.19 0 0 1-11.157-2.975 8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M33.192-26.73a7.086 7.086 0 0 0-3.328 4.336 7.048 7.048 0 0 0 .72 5.39c1.975 3.406 6.37 4.577 9.794 2.61l.013-.008a7.086 7.086 0 0 0 3.329-4.336 7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.577-9.794-2.61l-.014.008m-.5-.866.016-.009c3.906-2.244 8.902-.912 11.157 2.975 2.256 3.888.917 8.859-2.99 11.103-3.906 2.244-8.901.912-11.157-2.975-2.252-3.882-.92-8.845 2.974-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96569">
              <path d="M33.688-25.915a6.224 6.224 0 0 1 8.48 2.263 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.48-2.263 6.16 6.16 0 0 1 2.272-8.438Z" />
              <path
                d="M34.176-25.042a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .523 3.913c1.436 2.475 4.628 3.325 7.116 1.896l.01-.006a5.147 5.147 0 0 0 2.418-3.15 5.118 5.118 0 0 0-.523-3.913c-1.435-2.475-4.628-3.325-7.116-1.896l-.01.006m-.5-.866.012-.007c2.969-1.705 6.765-.693 8.48 2.261a6.16 6.16 0 0 1-2.273 8.438c-2.969 1.706-6.765.694-8.48-2.26a6.16 6.16 0 0 1 2.261-8.432Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96570">
              <path d="M34.831-23.945a3.931 3.931 0 0 1 5.357 1.428 3.891 3.891 0 0 1-1.435 5.332 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.435-5.33Z" />
              <path
                d="M35.324-23.075a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .292 2.193 2.934 2.934 0 0 0 3.994 1.062l.004-.003a2.885 2.885 0 0 0 1.356-1.765 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.994-1.062l-.004.002m-.5-.866.007-.004a3.931 3.931 0 0 1 5.356 1.428 3.89 3.89 0 0 1-1.435 5.33 3.931 3.931 0 0 1-5.356-1.429 3.89 3.89 0 0 1 1.428-5.325Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96571">
              <path d="M35.648-22.537a2.293 2.293 0 0 1 3.125.834 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.124-.832 2.27 2.27 0 0 1 .837-3.11Z" />
              <path
                d="M36.144-21.668a1.269 1.269 0 0 0-.468 1.739 1.294 1.294 0 0 0 1.764.466 1.269 1.269 0 0 0 .467-1.738 1.293 1.293 0 0 0-1.759-.47l-.004.003m-.5-.866.004-.002a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.108 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .833-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101831">
            <g data-name="Path 96572">
              <path d="M52.145 5.9a12.121 12.121 0 0 1 16.514 4.403 12 12 0 0 1-4.424 16.431 12.121 12.121 0 0 1-16.513-4.402A12 12 0 0 1 52.145 5.9Z" />
              <path
                d="M52.624 6.779a10.964 10.964 0 0 0-5.15 6.71 10.908 10.908 0 0 0 1.113 8.34c3.057 5.27 9.853 7.081 15.15 4.038l.02-.012a10.964 10.964 0 0 0 5.151-6.71c.757-2.836.362-5.799-1.113-8.34-3.058-5.27-9.854-7.081-15.15-4.038l-.021.012m-.5-.866.023-.013c5.781-3.322 13.175-1.35 16.513 4.402 3.338 5.754 1.356 13.11-4.425 16.432-5.782 3.322-13.175 1.35-16.513-4.403-3.333-5.745-1.362-13.09 4.402-16.418Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96573">
              <path d="M53.127 7.59a10.155 10.155 0 0 1 13.835 3.688 10.051 10.051 0 0 1-3.706 13.767 10.155 10.155 0 0 1-13.837-3.688 10.051 10.051 0 0 1 3.708-13.768Z" />
              <path
                d="M53.608 8.467a9.025 9.025 0 0 0-4.24 5.522 8.978 8.978 0 0 0 .917 6.866c2.516 4.338 8.111 5.829 12.472 3.323l.017-.01a9.025 9.025 0 0 0 4.24-5.522 8.978 8.978 0 0 0-.917-6.866c-2.517-4.337-8.112-5.828-12.473-3.323l-.016.01m-.5-.866.019-.012c4.844-2.783 11.038-1.131 13.835 3.69 2.797 4.82 1.137 10.983-3.707 13.767-4.844 2.783-11.039 1.131-13.835-3.69-2.793-4.813-1.142-10.967 3.688-13.755Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96574">
              <path d="M54.107 9.278a8.19 8.19 0 0 1 11.157 2.975 8.106 8.106 0 0 1-2.99 11.102 8.19 8.19 0 0 1-11.156-2.974 8.106 8.106 0 0 1 2.989-11.103Z" />
              <path
                d="M54.592 10.153a7.086 7.086 0 0 0-3.329 4.336 7.048 7.048 0 0 0 .72 5.39c1.976 3.406 6.37 4.577 9.794 2.61l.013-.008a7.086 7.086 0 0 0 3.329-4.337 7.048 7.048 0 0 0-.72-5.39c-1.976-3.405-6.37-4.576-9.794-2.609l-.013.008m-.5-.866.015-.009c3.907-2.244 8.902-.912 11.157 2.975 2.256 3.887.917 8.858-2.99 11.102-3.906 2.245-8.901.913-11.156-2.974-2.253-3.883-.92-8.846 2.974-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96575">
              <path d="M55.087 10.968a6.224 6.224 0 0 1 8.48 2.263 6.16 6.16 0 0 1-2.272 8.437 6.224 6.224 0 0 1-8.48-2.262 6.16 6.16 0 0 1 2.272-8.438Z" />
              <path
                d="M55.575 11.84a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .523 3.914c1.436 2.475 4.628 3.325 7.117 1.896l.01-.006a5.147 5.147 0 0 0 2.417-3.15 5.118 5.118 0 0 0-.522-3.913c-1.436-2.475-4.629-3.325-7.117-1.896l-.01.006m-.5-.866.012-.007c2.97-1.706 6.766-.694 8.48 2.26a6.16 6.16 0 0 1-2.272 8.439c-2.97 1.706-6.766.693-8.48-2.261a6.16 6.16 0 0 1 2.26-8.431Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96576">
              <path d="M56.23 12.938a3.931 3.931 0 0 1 5.357 1.428 3.891 3.891 0 0 1-1.434 5.332 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.434-5.33Z" />
              <path
                d="M56.723 13.808a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .293 2.192 2.934 2.934 0 0 0 3.993 1.062l.005-.002a2.885 2.885 0 0 0 1.355-1.765 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.993-1.062l-.005.002m-.5-.866.008-.004a3.931 3.931 0 0 1 5.355 1.428 3.89 3.89 0 0 1-1.435 5.329 3.931 3.931 0 0 1-5.355-1.428 3.89 3.89 0 0 1 1.427-5.325Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96577">
              <path d="M57.048 14.346a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.838 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .838-3.109Z" />
              <path
                d="M57.543 15.215a1.269 1.269 0 0 0-.468 1.738 1.294 1.294 0 0 0 1.764.467 1.269 1.269 0 0 0 .468-1.739 1.293 1.293 0 0 0-1.76-.469l-.004.003m-.5-.866.005-.003a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.109 2.293 2.293 0 0 1-3.125-.833 2.27 2.27 0 0 1 .833-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101832">
            <g data-name="Path 96578">
              <path d="M51.996-18.414a12.121 12.121 0 0 1 16.513 4.402 12 12 0 0 1-4.424 16.431 12.121 12.121 0 0 1-16.512-4.402 12 12 0 0 1 4.423-16.431Z" />
              <path
                d="M52.474-17.536a10.964 10.964 0 0 0-5.15 6.71 10.908 10.908 0 0 0 1.114 8.34c3.057 5.27 9.853 7.081 15.15 4.038l.02-.012a10.964 10.964 0 0 0 5.15-6.71c.758-2.836.362-5.798-1.113-8.34-3.057-5.27-9.853-7.081-15.15-4.038l-.02.012m-.5-.866.022-.013c5.782-3.322 13.175-1.35 16.513 4.403 3.338 5.753 1.357 13.11-4.424 16.431-5.782 3.322-13.175 1.35-16.513-4.403-3.334-5.745-1.362-13.09 4.401-16.418Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96579">
              <path d="M52.978-16.726a10.155 10.155 0 0 1 13.835 3.689A10.051 10.051 0 0 1 63.107.73 10.155 10.155 0 0 1 49.27-2.958a10.051 10.051 0 0 1 3.708-13.768Z" />
              <path
                d="M53.458-15.848a9.025 9.025 0 0 0-4.24 5.523 8.978 8.978 0 0 0 .917 6.865C52.652.878 58.247 2.369 62.608-.137l.016-.01a9.025 9.025 0 0 0 4.24-5.522 8.978 8.978 0 0 0-.917-6.866c-2.516-4.337-8.111-5.828-12.472-3.323l-.017.01m-.5-.866.02-.011c4.843-2.783 11.038-1.132 13.834 3.689C69.61-8.216 67.95-2.052 63.105.73 58.261 3.514 52.067 1.862 49.27-2.96c-2.793-4.813-1.141-10.967 3.688-13.755Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96580">
              <path d="M53.958-15.036a8.19 8.19 0 0 1 11.157 2.975A8.106 8.106 0 0 1 62.125-.96a8.19 8.19 0 0 1-11.157-2.975 8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M54.442-14.162a7.086 7.086 0 0 0-3.328 4.336 7.048 7.048 0 0 0 .719 5.39c1.976 3.406 6.37 4.577 9.795 2.61l.013-.008A7.086 7.086 0 0 0 64.97-6.17a7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.577-9.795-2.61l-.013.008m-.5-.866.016-.009c3.906-2.244 8.902-.912 11.157 2.975 2.256 3.887.917 8.858-2.99 11.103-3.906 2.244-8.901.912-11.157-2.975-2.252-3.882-.92-8.845 2.974-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96581">
              <path d="M54.938-13.347a6.224 6.224 0 0 1 8.48 2.263 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.481-2.263 6.16 6.16 0 0 1 2.273-8.438Z" />
              <path
                d="M55.426-12.474a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .523 3.913c1.435 2.475 4.628 3.325 7.116 1.896l.01-.006a5.147 5.147 0 0 0 2.418-3.15 5.118 5.118 0 0 0-.523-3.913c-1.436-2.475-4.628-3.325-7.116-1.896l-.01.006m-.5-.866.012-.007c2.969-1.706 6.765-.693 8.48 2.261a6.16 6.16 0 0 1-2.273 8.438c-2.969 1.706-6.765.693-8.48-2.261a6.16 6.16 0 0 1 2.261-8.431Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96582">
              <path d="M56.08-11.377a3.931 3.931 0 0 1 5.358 1.428 3.891 3.891 0 0 1-1.435 5.332 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.435-5.33Z" />
              <path
                d="M56.574-10.507a2.885 2.885 0 0 0-1.356 1.765 2.866 2.866 0 0 0 .293 2.192 2.934 2.934 0 0 0 3.994 1.062l.004-.002a2.885 2.885 0 0 0 1.356-1.765 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.994-1.062l-.004.002m-.5-.866.007-.004a3.931 3.931 0 0 1 5.356 1.428 3.89 3.89 0 0 1-1.435 5.33 3.931 3.931 0 0 1-5.356-1.429 3.89 3.89 0 0 1 1.428-5.325Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96583">
              <path d="M56.898-9.969a2.293 2.293 0 0 1 3.125.834 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .837-3.109Z" />
              <path
                d="M57.394-9.1a1.269 1.269 0 0 0-.468 1.738 1.294 1.294 0 0 0 1.763.467 1.269 1.269 0 0 0 .468-1.739 1.293 1.293 0 0 0-1.759-.468l-.004.002m-.5-.866.004-.002a2.293 2.293 0 0 1 3.124.832 2.27 2.27 0 0 1-.837 3.11 2.293 2.293 0 0 1-3.124-.834 2.27 2.27 0 0 1 .833-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101833">
            <g data-name="Path 96584">
              <path d="M51.684-43.01a12.121 12.121 0 0 1 16.513 4.402 12 12 0 0 1-4.424 16.431 12.121 12.121 0 0 1-16.512-4.403 12 12 0 0 1 4.423-16.43Z" />
              <path
                d="M52.162-42.132a10.964 10.964 0 0 0-5.15 6.71 10.908 10.908 0 0 0 1.114 8.34c3.057 5.27 9.853 7.08 15.15 4.038l.02-.012a10.964 10.964 0 0 0 5.15-6.71c.758-2.836.362-5.799-1.113-8.34-3.057-5.27-9.853-7.081-15.15-4.038l-.02.012m-.5-.866.022-.014c5.782-3.321 13.175-1.35 16.513 4.403 3.338 5.754 1.357 13.11-4.424 16.432-5.782 3.322-13.175 1.35-16.513-4.403-3.334-5.745-1.362-13.09 4.401-16.418Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96585">
              <path d="M52.666-41.322A10.155 10.155 0 0 1 66.5-37.633a10.051 10.051 0 0 1-3.706 13.767 10.155 10.155 0 0 1-13.837-3.689 10.051 10.051 0 0 1 3.708-13.767Z" />
              <path
                d="M53.146-40.445a9.025 9.025 0 0 0-4.24 5.523 8.978 8.978 0 0 0 .917 6.866c2.517 4.337 8.112 5.828 12.473 3.323l.016-.01a9.025 9.025 0 0 0 4.24-5.522 8.978 8.978 0 0 0-.916-6.866c-2.517-4.338-8.112-5.829-12.473-3.323l-.017.01m-.5-.867.02-.01c4.844-2.784 11.038-1.132 13.834 3.688 2.797 4.82 1.138 10.984-3.707 13.767-4.844 2.783-11.038 1.132-13.835-3.689-2.793-4.813-1.141-10.967 3.688-13.756Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96586">
              <path d="M53.646-39.633a8.19 8.19 0 0 1 11.157 2.975 8.106 8.106 0 0 1-2.99 11.102 8.19 8.19 0 0 1-11.157-2.975 8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M54.13-38.758a7.086 7.086 0 0 0-3.328 4.336 7.048 7.048 0 0 0 .72 5.39c1.975 3.406 6.37 4.577 9.794 2.609l.013-.008a7.086 7.086 0 0 0 3.329-4.336 7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.576-9.794-2.609l-.014.008m-.5-.866.016-.01c3.906-2.243 8.902-.912 11.157 2.976 2.256 3.887.917 8.858-2.99 11.102-3.906 2.245-8.901.913-11.157-2.975-2.252-3.882-.92-8.845 2.974-11.093Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96587">
              <path d="M54.626-37.943a6.224 6.224 0 0 1 8.48 2.262 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.481-2.263 6.16 6.16 0 0 1 2.273-8.437Z" />
              <path
                d="M55.114-37.07a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .523 3.913c1.435 2.475 4.628 3.325 7.116 1.896l.01-.006a5.147 5.147 0 0 0 2.418-3.15 5.118 5.118 0 0 0-.523-3.913c-1.435-2.475-4.628-3.326-7.116-1.896l-.01.006m-.5-.866.012-.007c2.969-1.706 6.765-.694 8.48 2.26a6.16 6.16 0 0 1-2.273 8.439c-2.969 1.705-6.765.693-8.48-2.261a6.16 6.16 0 0 1 2.261-8.431Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96588">
              <path d="M55.769-35.973a3.931 3.931 0 0 1 5.357 1.428 3.891 3.891 0 0 1-1.435 5.331 3.931 3.931 0 0 1-5.357-1.43 3.891 3.891 0 0 1 1.435-5.33Z" />
              <path
                d="M56.262-35.103a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .292 2.192 2.934 2.934 0 0 0 3.994 1.062l.004-.002a2.885 2.885 0 0 0 1.356-1.766 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.994-1.062l-.004.003m-.5-.866.007-.004a3.931 3.931 0 0 1 5.356 1.428 3.89 3.89 0 0 1-1.435 5.329 3.931 3.931 0 0 1-5.356-1.428 3.89 3.89 0 0 1 1.428-5.325Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96589">
              <path d="M56.586-34.565a2.293 2.293 0 0 1 3.125.833 2.27 2.27 0 0 1-.838 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .837-3.11Z" />
              <path
                d="M57.082-33.696a1.269 1.269 0 0 0-.468 1.738 1.294 1.294 0 0 0 1.763.466 1.269 1.269 0 0 0 .468-1.738 1.293 1.293 0 0 0-1.759-.469l-.004.003m-.5-.866.004-.003a2.293 2.293 0 0 1 3.124.833 2.27 2.27 0 0 1-.837 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .833-3.106Z"
                fill="#5db597"
              />
            </g>
          </g>
          <g data-name="Group 101834">
            <g data-name="Path 96590">
              <path d="M73.083-6.128a12.121 12.121 0 0 1 16.514 4.402 12 12 0 0 1-4.424 16.432 12.121 12.121 0 0 1-16.513-4.403 12 12 0 0 1 4.423-16.43Z" />
              <path
                d="M73.562-5.25a10.964 10.964 0 0 0-5.15 6.71A10.908 10.908 0 0 0 69.525 9.8c3.057 5.27 9.853 7.08 15.15 4.038l.02-.012a10.964 10.964 0 0 0 5.151-6.71c.757-2.837.362-5.8-1.113-8.341-3.058-5.27-9.854-7.08-15.15-4.038l-.021.012m-.5-.866.023-.013c5.781-3.322 13.174-1.35 16.513 4.403 3.338 5.753 1.356 13.11-4.425 16.432-5.782 3.321-13.175 1.35-16.513-4.403-3.334-5.746-1.362-13.09 4.402-16.419Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96591">
              <path d="M74.065-4.44A10.155 10.155 0 0 1 87.9-.75a10.051 10.051 0 0 1-3.706 13.767 10.155 10.155 0 0 1-13.837-3.689 10.051 10.051 0 0 1 3.708-13.767Z" />
              <path
                d="M74.546-3.562a9.025 9.025 0 0 0-4.24 5.523 8.978 8.978 0 0 0 .917 6.865c2.516 4.338 8.111 5.829 12.472 3.324l.017-.01a9.025 9.025 0 0 0 4.24-5.523 8.978 8.978 0 0 0-.917-6.865c-2.517-4.338-8.112-5.829-12.473-3.324l-.016.01m-.5-.866.019-.011C78.909-7.222 85.103-5.57 87.9-.75c2.797 4.82 1.137 10.984-3.707 13.767-4.844 2.783-11.039 1.132-13.835-3.689-2.793-4.814-1.142-10.968 3.688-13.756Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96592">
              <path d="M75.045-2.75A8.19 8.19 0 0 1 86.202.225a8.106 8.106 0 0 1-2.99 11.102 8.19 8.19 0 0 1-11.157-2.975 8.106 8.106 0 0 1 2.99-11.102Z" />
              <path
                d="M75.53-1.876a7.086 7.086 0 0 0-3.33 4.337 7.048 7.048 0 0 0 .72 5.39c1.977 3.406 6.37 4.576 9.795 2.609l.013-.008a7.086 7.086 0 0 0 3.329-4.336 7.048 7.048 0 0 0-.72-5.39c-1.976-3.406-6.37-4.577-9.794-2.61l-.013.008m-.5-.866.015-.008c3.907-2.245 8.902-.913 11.157 2.974 2.256 3.888.917 8.859-2.99 11.103-3.906 2.244-8.901.912-11.157-2.975-2.252-3.882-.92-8.845 2.975-11.094Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96593">
              <path d="M76.025-1.06a6.224 6.224 0 0 1 8.48 2.262 6.16 6.16 0 0 1-2.272 8.438 6.224 6.224 0 0 1-8.48-2.263 6.16 6.16 0 0 1 2.272-8.438Z" />
              <path
                d="M76.513-.188a5.147 5.147 0 0 0-2.418 3.15 5.118 5.118 0 0 0 .523 3.914c1.436 2.474 4.628 3.325 7.116 1.895l.01-.005a5.147 5.147 0 0 0 2.418-3.15 5.118 5.118 0 0 0-.522-3.914C82.204-.773 79.01-1.623 76.523-.193l-.01.005m-.5-.866.012-.007c2.97-1.705 6.766-.693 8.48 2.261a6.16 6.16 0 0 1-2.272 8.438c-2.97 1.706-6.766.694-8.48-2.26a6.16 6.16 0 0 1 2.26-8.432Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96594">
              <path d="M77.168.91a3.931 3.931 0 0 1 5.357 1.428 3.891 3.891 0 0 1-1.435 5.331 3.931 3.931 0 0 1-5.356-1.43 3.891 3.891 0 0 1 1.434-5.33Z" />
              <path
                d="M77.661 1.78a2.885 2.885 0 0 0-1.355 1.765 2.866 2.866 0 0 0 .293 2.192 2.934 2.934 0 0 0 3.993 1.062l.005-.003a2.885 2.885 0 0 0 1.355-1.765 2.866 2.866 0 0 0-.293-2.192 2.934 2.934 0 0 0-3.993-1.062l-.005.003m-.5-.866.008-.005a3.931 3.931 0 0 1 5.355 1.428 3.89 3.89 0 0 1-1.435 5.33 3.931 3.931 0 0 1-5.355-1.428A3.89 3.89 0 0 1 77.16.914Z"
                fill="#5db597"
              />
            </g>
            <g data-name="Path 96595">
              <path d="M77.986 2.317a2.293 2.293 0 0 1 3.124.834 2.27 2.27 0 0 1-.838 3.108 2.293 2.293 0 0 1-3.124-.832 2.27 2.27 0 0 1 .838-3.11Z" />
              <path
                d="M78.481 3.186a1.269 1.269 0 0 0-.468 1.739 1.294 1.294 0 0 0 1.764.466 1.269 1.269 0 0 0 .468-1.738 1.293 1.293 0 0 0-1.76-.47l-.004.003m-.5-.866.004-.002a2.293 2.293 0 0 1 3.125.833 2.27 2.27 0 0 1-.838 3.109 2.293 2.293 0 0 1-3.124-.833 2.27 2.27 0 0 1 .833-3.107Z"
                fill="#5db597"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        d="M16.061 8.381a7.478 7.478 0 0 1 5.25 2.148V9.318a.563.563 0 1 1 1.125 0v2.625a.661.661 0 0 1-.771.562h-2.418a.563.563 0 1 1 0-1.125h1.327a6.374 6.374 0 1 0 1.86 4.5.564.564 0 1 1 1.127.001 7.5 7.5 0 1 1-7.5-7.5Zm0 3a2.25 2.25 0 0 1 0 4.5c-1.5 0-3.056-1.681-3.23-1.873a.563.563 0 0 1 0-.754c.173-.192 1.73-1.873 3.23-1.873Zm2.527 5.25a1.224 1.224 0 0 1 1.222 1.222v.406c0 1.423-1.612 2.5-3.75 2.5s-3.75-1.074-3.75-2.5v-.406a1.224 1.224 0 0 1 1.222-1.222Z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default FollowupIcon
