import React from "react";
import ForgotPassword from "./forgotPassword";


class ForgotPasswordParent extends React.Component {
    render() {
        return <ForgotPassword />;
    }
}

export default (ForgotPasswordParent);
