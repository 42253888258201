import * as React from "react"
const ExpiredIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 111494">
      <path
        fill="#4e5a6b"
        d="M8.762 0a8.762 8.762 0 1 0 8.762 8.762A8.779 8.779 0 0 0 8.762 0Zm0 2.286a6.476 6.476 0 1 1-6.476 6.476 6.459 6.459 0 0 1 6.476-6.476Zm-.018 1.507a1.143 1.143 0 0 0-1.125 1.159v3.81a1.143 1.143 0 0 0 .335.808l1.524 1.524a1.143 1.143 0 1 0 1.616-1.616L9.9 8.289V4.952a1.143 1.143 0 0 0-1.156-1.159Zm9.4.778a10.232 10.232 0 0 1 .9 4.19 10.323 10.323 0 0 1-.183 1.9H32V9.524a4.958 4.958 0 0 0-4.952-4.952Zm0 8.381a10.261 10.261 0 0 1-13.577 5.2v8.9A4.958 4.958 0 0 0 9.524 32h17.524A4.958 4.958 0 0 0 32 27.048v-14.1Zm.137 3.81a1.9 1.9 0 1 1-1.9 1.9 1.9 1.9 0 0 1 1.905-1.9Zm6.476 0a1.9 1.9 0 1 1-1.9 1.9 1.9 1.9 0 0 1 1.905-1.9ZM11.81 23.619a1.9 1.9 0 1 1-1.9 1.9 1.9 1.9 0 0 1 1.9-1.9Zm6.476 0a1.9 1.9 0 1 1-1.9 1.9 1.9 1.9 0 0 1 1.9-1.9Z"
        data-name="icons8-schedule (1)"
      />
    </g>
  </svg>
)
export default ExpiredIcon