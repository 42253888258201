import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    existingLeadRoot: {
        position: "absolute",
        bottom: 20,
        right: 0
    },
    promotionRoot: {
        boxShadow: "0px 0px 16px #00000014",
        padding: "16px",
        borderRadius: "4px",
        backgroundColor: "white",
        minHeight: "270px"
    },
    title: {
        fontFamily: Bold,
        fontSize: "1.25rem",
        textAlign: "center",
        marginTop: "20px"
    }
}));