import React from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@mui/material';

export const LoadingSection = (props) => {

  const {
    // message,
    top,
    bottom,
    screen=false
  } = props;

  return <div><Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    style={{ marginTop: top, marginBottom: bottom }}
  >
    <Grid item>
      {screen ? 
      <img src="/images/loader.gif" alt=""/>
      :
      <CircularProgress />
    }
    </Grid>
    {/* <Grid item>
      {!screen &&
      <Typography variant="h6" align="center">
        
        {message}
      </Typography>
      }
    </Grid> */}

  </Grid>
  </div>
}

LoadingSection.propTypes = {
  message: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string
}

LoadingSection.defaultProps = {
  message: 'Loading...',
  top: '30vh',
  bottom: '0vh'
}

