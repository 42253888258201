import React from "react";
import { withNavBars } from "../../HOCs";
import CustomerFeedBack from "./customerFeedback"

class CustomerFeedBackParent extends React.Component {
    render() {
        return <CustomerFeedBack />;
    }
}
const props = {
    boxShadow: false,
};
export default withNavBars(CustomerFeedBackParent, props);