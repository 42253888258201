
import { NewContact } from "../../../assets";
import ContactIcon from "../utils/contactIcon";
import { ChooseCardStyle } from "./style";
import { Stack, Typography, Box } from "@mui/material"

export const ContactTypeSelect = ({ options = [], value = null, onChange, t = () => false }) => {

    const classes = ChooseCardStyle();

    return (


        <Box>

            <Typography className={classes.labelBold}>{t("Contact Type")}</Typography>

            <Stack direction={"row"} p={1.5}
                className={classes.existRoot}
                sx={{ border: `1px solid ${value === true ? "#5078E1" : "#E4E8EE"}`,backgroundColor: `${value === true ? "#F1F7FF" : "white"}`}}
                onClick={() => onChange(true)}>
                <ContactIcon />
                <Stack sx={{ marginInlineStart: "8px"}}>
                    <Typography className={classes.title}>{t("Existing Contact")}</Typography>
                </Stack>
            </Stack>
            <Box light sx={{ borderBottom: "1px solid #5078E1" }} />
            <Stack direction={"row"} p={1.5}
                className={classes.newRoot}
                sx={{ border: `1px solid ${value === false ? "#5078E1" : "#E4E8EE"}`,backgroundColor: `${value === false ? "#F1F7FF" : "white"}` }}
                onClick={() => onChange(false)}>
                <NewContact/>
                <Stack sx={{ marginInlineStart: "8px"}}>
                    <Typography className={classes.title}>{t("Create New Prospect")}</Typography>
                </Stack>
            </Stack>

        </Box>



    )
}