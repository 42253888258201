import React from "react";
import AgreementUnitAccess from './agreementUnitAccess';
import { withNavBars } from "../../HOCs";

class AgreementUnitAccessParent extends React.Component {
  render() {
    return <AgreementUnitAccess />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(AgreementUnitAccessParent, props);
