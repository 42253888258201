import * as React from "react"

export const Finance = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 105465">
      <path data-name="Rectangle 55483" fill="none" d="M0 0h20v20H0z" />
      <path
        d="M17.071 4.571h-4.928v.857a1.284 1.284 0 0 1-1.286 1.286H9.143a1.284 1.284 0 0 1-1.286-1.285v-.858H2.929A1.929 1.929 0 0 0 1 6.5v9a1.931 1.931 0 0 0 1.929 1.929h14.142A1.929 1.929 0 0 0 19 15.5v-9a1.929 1.929 0 0 0-1.929-1.929ZM5.714 8.429a1.286 1.286 0 1 1-1.286 1.286 1.287 1.287 0 0 1 1.286-1.286Zm2.143 4.5-.009.09-.021.133c-.193.866-.973 1.281-2.113 1.281-1.2 0-2-.459-2.134-1.414l-.009-.094v-.511a.643.643 0 0 1 .639-.557h3.086a.643.643 0 0 1 .557.639Zm8.014.639H11.41a.643.643 0 0 1 0-1.273l.09-.009h4.281l.086.009a.642.642 0 0 1 0 1.273Zm0-3H11.414a.645.645 0 0 1 0-1.277h4.457a.645.645 0 0 1 0 1.277ZM10 2a2.143 2.143 0 0 0-2.143 2.143v.429h4.286v-.429A2.143 2.143 0 0 0 10 2Z"
        fill={props?.color ?? "#c1c5cb"}
      />
    </g>
  </svg>
)
