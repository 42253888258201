export const Amendments = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <g data-name="Agreement Amend">
      <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
      <path
        data-name="Path 93625"
        d="M19.376 16c-.084 0-.167.006-.25.013v-2.37l.452-.5a8.412 8.412 0 0 0 .83-1.093l2.178-3.415a.25.25 0 0 0-.384-.315l-.413.4a1.756 1.756 0 0 0-1.663-1.22h-8.988a2.255 2.255 0 0 0-2.25 2.247l-.012 9.503h-.765a.535.535 0 0 0-.453.246.155.155 0 0 0-.011.018 1.9 1.9 0 0 0 .287 2.328 2.266 2.266 0 0 0 1.592.658h8.35a1.239 1.239 0 0 0 .565-.139A3.249 3.249 0 1 0 19.376 16Zm.611-4.22a7.916 7.916 0 0 1-.781 1.028l-1.57 1.745-.486-.38 1.794-2c.129-.143.263-.282.4-.414l1.69-1.625Zm-2.6 3.212-.194.742-1.5.609.481-1.646.573-.207Zm1.739-3.712v-.53h.55Zm1.101-3.27a1.245 1.245 0 0 1 1.135 1.118l-1.166 1.122h-1.032a4 4 0 0 1 1.063-2.24ZM9.388 9.748A1.754 1.754 0 0 1 11.138 8h8.438a4.487 4.487 0 0 0-.939 2.48c-.008.1-.011.192-.011.288v1.019c-.017.019-.036.036-.053.056l-1.932 2.149-.765.276a.25.25 0 0 0-.155.165l-.66 2.263a.25.25 0 0 0 .334.3l2.1-.85a.25.25 0 0 0 .148-.168l.252-.964.733-.816v1.893a3.254 3.254 0 0 0-2.5 3.159H9.376ZM9.526 22a1.763 1.763 0 0 1-1.238-.512 1.4 1.4 0 0 1-.22-1.7l.008-.024a.034.034 0 0 1 .03-.014h8.062A3.251 3.251 0 0 0 17.651 22Zm9.85 0a2.75 2.75 0 1 1 2.75-2.75 2.753 2.753 0 0 1-2.75 2.75Zm0 0"
        fill={props?.fill ?? "#c1c5cb"}
      />
      <path
        data-name="Path 93626"
        d="m18.839 20.401-1.057-.846-.312.391 1.25 1a.25.25 0 0 0 .349-.037l2.25-2.75-.387-.316Zm0 0"
        fill={props?.fill ?? "#c1c5cb"}
      />
      <path
        data-name="Path 93627"
        d="M11.126 9.25h6.75v.5h-6.75Zm0 0"
        fill={props?.fill ?? "#c1c5cb"}
      />
      <path
        data-name="Path 93628"
        d="M10.126 11h7.75v.5h-7.75Zm0 0"
        fill={props?.fill ?? "#c1c5cb"}
      />
      <path
        data-name="Path 93629"
        d="M10.126 12.75h6.75v.5h-6.75Zm0 0"
        fill={props?.fill ?? "#c1c5cb"}
      />
      <path
        data-name="Path 93630"
        d="M9.876 14.5h5.25v.5h-5.25Zm0 0"
        fill={props?.fill ?? "#c1c5cb"}
      />
      <path
        data-name="Path 93631"
        d="M9.876 16.25h4.5v.5h-4.5Zm0 0"
        fill={props?.fill ?? "#c1c5cb"}
      />
      <path
        data-name="Path 93632"
        d="M14.376 17.25h.5v.5h-.5Zm0 0"
        fill={props?.fill ?? "#c1c5cb"}
      />
      <path
        data-name="Path 93633"
        d="M9.876 18h3.75v.5h-3.75Zm0 0"
        fill={props?.fill ?? "#c1c5cb"}
      />
    </g>
  </svg>
)
