import React from "react";
import { withNavBars } from "../../HOCs";
import Request from './request'

const CreateGeneralRequest = () => {
  return <><Request/></>;
};
const props = {
  boxShadow: false,
};
export default withNavBars(CreateGeneralRequest, props);
