import { ActivePropertyIcon } from "../../assets";
import { PropertyIcon } from "../../assets/clientDashboard";
import { month_array } from "./constants";

export const constructBasicStatsData = (t, data) => {
    let agreement_stats = [
        {
            count: data?.agreement?.draft_agreement ?? 0,
            image: <ActivePropertyIcon />,
            sub: t("Draft Agreements"),
        },
        {
            count: data?.agreement?.renewed_agreement ?? 0,
            image: <ActivePropertyIcon color="#686AF3" />,
            sub: t("Renewed Agreements"),
        },
        {
            count: data?.agreement?.declined_agreement ?? 0,
            image: <ActivePropertyIcon color="#FF6D65" />,
            sub: t("Declined Agreements"),
        },
        {
            count: data?.agreement?.terminated_agreement ?? 0,
            image: "/images/propertdashboard/vacant.png",
            sub: t("Terminated Agreements"),
            image_type: true,
        },
        {
            count: data?.agreement?.expired_agreement ?? 0,
            image: "/images/propertdashboard/occupied.png",
            image_type: true,
            sub: t("Expired Agreements"),
        },
        {
            count: data?.agreement?.live_agreement ?? 0,
            image: <PropertyIcon />,
            sub: t("Live Agreements"),
        },
    ];

    let contact_stats = [
        {
            count: data?.contract_counts?.draft_contract ?? 0,
            image: <ActivePropertyIcon color="#5AC782" />,
            sub: t("Draft Contracts"),
        },
        {
            count: data?.contract_counts?.landlord_contract ?? 0,
            image: <ActivePropertyIcon color="#FF9340" />,
            sub: t("Landlords"),
        },
        {
            count: data?.contract_counts?.published_contract ?? 0,
            image: <ActivePropertyIcon color="#4E5A6B" />,
            sub: t("Published Contracts"),
        },
        {
            count: data?.contract_counts?.customers_account_contract ?? 0,
            image: <ActivePropertyIcon color="#EE5188" />,
            sub: t("Customers"),
        },
        {
            count: data?.contract_counts?.expired_contract ?? 0,
            image: <ActivePropertyIcon color="#686AF3" />,
            sub: t("Expired Contracts"),
        },
        {
            count: data?.contract_counts?.vendor_account_contract ?? 0,
            image: <ActivePropertyIcon color="#FF6D65" />,
            sub: t("Vendors"),
        },
    ]

    let temp_active_agreements_by_agreement_type_total_count = 0

    let active_agreements_by_agreement_type = {
        data: data?.agreement_type?.map?.((item) => {

            if (item?.count) {
                temp_active_agreements_by_agreement_type_total_count =
                    temp_active_agreements_by_agreement_type_total_count + parseInt(item?.count);
            }

            return {
                ...item,
                name: item?.type ?? "",
                count: item?.count ? parseInt(item?.count) : 0,
            }
        }),
        total_count: temp_active_agreements_by_agreement_type_total_count
    }

    let temp_agreement_revenue = data?.agreement_revenue

    let temp_agreement_types = []

    for (let i = 0; i < temp_agreement_revenue?.length; i++) {
        for (let j = 0; j < temp_agreement_revenue?.[i]?.agreement?.length; j++) {
            temp_agreement_types = [...temp_agreement_types, temp_agreement_revenue?.[i]?.agreement?.[j]?.agreement_type]
        }
    }

    let agreement_types = [...new Set(temp_agreement_types)]

    let agreement_revenue = {
        data: {
            labels: temp_agreement_revenue?.map?.((item) => month_array[item?.month]),
            datasets: agreement_types?.map((type) => {
                let temp_random_color = `#${Math.random().toString(16).substring(2, 8)}`;

                let temp_data = [];

                for (let i = 0; i < temp_agreement_revenue?.length; i++) {
                    let temp_agreement = temp_agreement_revenue?.[i]?.agreement;
                    let temp_filtered_agreement = temp_agreement.find((agreement) => agreement?.agreement_type === type);
                    temp_data = [...temp_data, temp_filtered_agreement?.sum ? temp_filtered_agreement?.sum : 0]
                }

                return {
                    label: type,
                    data: temp_data,
                    borderColor: temp_random_color,
                    backgroundColor: temp_random_color,
                }
            })
        }
    }

    const forcast_revenue_random_color = `#${Math.random().toString(16).substring(2, 8)}`;
    const forcast_revenue = {
        labels: data?.forcast_revenue?.map?.(_ => `${month_array[_?.month]} ${_?.year}`),
        datasets: [
            {
                label: "",
                data: data?.forcast_revenue?.map?.(_ => _?.amount),
                borderColor: forcast_revenue_random_color,
                backgroundColor: forcast_revenue_random_color,
            },
        ],
    }

    let temp_scheduled_invoices = data?.invoice_24_months

    let temp_invoice_types = []

    for (let i = 0; i < temp_scheduled_invoices?.length; i++) {
        for (let j = 0; j < temp_scheduled_invoices?.[i]?.invoices?.length; j++) {
            temp_invoice_types = [...temp_invoice_types, temp_scheduled_invoices?.[i]?.invoices?.[j]?.invoice_type]
        }
    }

    let invoice_types = [...new Set(temp_invoice_types)]

    let scheduled_invoices = {
        data: {
            labels: temp_scheduled_invoices?.map?.((item) => month_array[item?.month]),
            datasets: invoice_types?.map((type) => {
                let temp_random_color = `#${Math.random().toString(16).substring(2, 8)}`;

                let temp_data = [];

                for (let i = 0; i < temp_scheduled_invoices?.length; i++) {
                    let temp_invoices = temp_scheduled_invoices?.[i]?.invoices;
                    let temp_filtered_invoice = temp_invoices.find((invoice) => invoice?.invoice_type === type);
                    temp_data = [...temp_data, temp_filtered_invoice?.sum ? temp_filtered_invoice?.sum : 0]
                }

                return {
                    label: type,
                    data: temp_data,
                    backgroundColor: temp_random_color,
                    borderWidth: 1,
                    barThickness: 20,
                }
            })
        }
    }

    let contract_types_labels = data?.contract_type?.map?.(_ => _?.name);
    let contract_types_datasets = [
        {
            label: "", backgroundColor: "#739c66", borderWidth: 1, barThickness: 20,
            data: data?.contract_type?.map?.(_ => _?.count ?? 0),
        },
    ]

    const contract_types = { labels: contract_types_labels, datasets: contract_types_datasets }

    const final = {
        agreement_stats,
        contact_stats,
        active_agreements_by_agreement_type,
        agreement_revenue,
        scheduled_invoices,
        contract_types,
        forcast_revenue,
    };
    return final;
};

export const constructScheduleVsInvoiceVsReceiptData = (data) => {
    let dataset_labels = Object.keys(data);

    let month_index_array = Object.keys(month_array);

    let temp_data = {
        labels: Object.values(month_array),
        datasets: dataset_labels?.map((label) => {
            let temp_random_color = `#${Math.random().toString(16).substring(2, 8)}`;

            let temp_data = [];

            for (let i = 0; i < month_index_array.length; i++) {
                let temp_filtered_data = data?.[label]?.find((item) => month_index_array?.[i] === item?.month?.toString());
                temp_data = [...temp_data, temp_filtered_data?.total_amount ? temp_filtered_data?.total_amount : 0]
            }

            return {
                label: label?.split("_")?.join(" ").replace(/\b\w/g, match => match.toUpperCase()),
                data: temp_data,
                borderColor: temp_random_color,
                backgroundColor: temp_random_color,
            }
        })
    }

    const final = {
        data: temp_data,
    };
    return final;
};

export const construct_year_option = (date) => {
    const start_year = 2012;
    let end_year = date?.getFullYear();

    const year_array = Array.from({ length: end_year - start_year + 1 }, (_, index) => end_year - index);

    return year_array?.map?.((year) => {
        return { label: year, value: year, }
    })
}