import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { AvailableSlots, Calendar, VenueCard } from "./components";
import { CheckAvailabilityStyles } from './style'
import { withNamespaces } from "react-i18next";
import styled from '@mui/material/styles/styled';
import { AmenitiesBookingContext } from "./amenitiesBookingContext";
import { MemberCount } from "./components/memberCount";
import { AlertDialog } from "../../components";
import { AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";

const CustomPaper = styled('div')(({ theme }) => ({
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 16px #00000014",
    backgroundColor: "white",
    height: `calc(100vh - 150px)`,
    overflow: "auto",
    margin: "16px",
    position: "relative"
}))

const CheckAvailability = (
    {
        // getAmenityBookingList = () => false,
        // closeCheckAvailability = () => false,
        // selectedCompany = {},
        // selectedProperty = {},
        // selectedAmenityCategory = {},
        t = () => false
    }
) => {
    const classes = CheckAvailabilityStyles()
    const alert = React.useContext(AlertContext)

    const {
        getViewAmenities,
        getAmenitiesSelectedDate,
        getAmenitiesBooking,
        selectSlot,
        data,
        setStep,
        setBookWalkin,
        setData,
        setContactDetail,
        setSelectedExistingContact,
        setSelectedUnit,
        member,
        setMember
    } = React.useContext(AmenitiesBookingContext)
    const showAvailableSlot = (e) => {
        getAmenitiesBooking(e)
    }
    // const [popup, setPopup] = React.useState(false)
    const [selectedType, setSelectedType] = React.useState("")
    const openPopup = (type = "", mcStatus) => {
        if (type === "walkin") {
            setBookWalkin(true)
            if (mcStatus === "enable") {
                setMember({ ...member, bool: true })
            } else {
                setStep("step3")
            }
            setData({ ...data, is_back: false })
            setSelectedUnit("")
        } else {
            setBookWalkin(false)
            if (mcStatus === "enable") {
                setMember({ ...member, bool: true })
            } else {
                setStep("step3")
            }
            setData({ ...data, is_back: false })
            setSelectedExistingContact("")
            setContactDetail({
                name: "",
                mobileNo: "",
                mailId: "",
                account: "",
                error: {
                    name: "",
                    mobileNo: "",
                    mailId: ""
                }
            })
        }
    }

    React.useEffect(() => {
        getViewAmenities(new Date())
        // eslint-disable-next-line
    }, [])



    // const closeBookingResidentPopup = () => {
    //     setPopup(!popup)
    // }

    const handleContinueMemberCount = () => {
        let array = []
        const normalSlot = data?.selected_slot?.filter((i) => i?.value?.extraInvoice === false)
        // eslint-disable-next-line
        normalSlot?.map((e) => {
            array.push(Number(data?.selectedVenue?.participants_count) - Number(e?.value?.info?.book_count))
        })
        let minValue = Math.min(...array)
        if (minValue >= Number(member?.count)) {
            // bookAmenities()
            //   setbpOpen(true)
            openPopup(selectedType)
            setMember({ ...member, bool: false })
        } else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: `${t("Member count should not greater than")} ${Math.min(...array)}`,
            });
        }
    }


    // const validateCount = () => {
    //     let array = []
    //     // eslint-disable-next-line
    //     data?.selected_slot?.map((e) => {
    //         array.push(Number(e?.value?.participants_count) - Number(e?.value?.slot_detail?.book_count))
    //     })
    //     let minValue = Math.min(...array)
    //     if (minValue >= Number(pcCount)) {
    //         // bookAmenities()
    //         setbpOpen(true)
    //     } else {
    //         alert.setSnack({
    //             ...alert,
    //             open: true,
    //             severity: AlertProps.severity.error,
    //             msg: `${t("Member count should not greater than")} ${Math.min(...array)}`,
    //         });
    //     }
    // }

    return (
        <>
            {/* <Subheader title="Check Availability" /> */}
            <CustomPaper>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: "calc(100vh - 225px)", overflow: "auto" }}>
                        <Grid container sx={{ height: "100%" }}>
                            <Grid item md={6} lg={3} sm={6} className={classes.grid_border} p={2}>
                                <Stack spacing={1}>
                                    <Typography className={classes.title}>{t("CHOOSE THE BOOKING DATE")}</Typography>
                                    <Calendar getAmenities={getAmenitiesSelectedDate} selected_date={data?.selectedDate} />
                                </Stack>
                            </Grid>
                            <Grid item md={6} lg={3} sm={6} className={classes.grid_border} p={2}>
                                <Stack spacing={1} className={classes.venueSection}>
                                    <Typography className={classes.title}>{t("Venue")}</Typography>
                                    {
                                        data?.venue?.length === 0 ?
                                            <Box className={classes.noVenueBox}>
                                                <Typography className={classes.noneTitle}>{t("No Venues Available on this day")}</Typography>
                                            </Box> :
                                            <Box>
                                                <VenueCard list={data?.venue} showAvailableSlot={showAvailableSlot} selectedVenue={data?.selectedVenue} />
                                            </Box>
                                    }
                                </Stack>
                            </Grid>
                            <Grid item md={12} lg={6} sm={12} className={classes.grid_border} p={2}>
                                <Stack spacing={1} className={classes.available_slot}>
                                    <Typography className={classes.title}>{t("AvailableSlot")}</Typography>
                                    <AvailableSlots
                                        t={t}
                                        selectedVenue={data?.selectedVenue}
                                        availableSlot={data?.availableSlot}
                                        openPopup={openPopup}
                                        selectSlot={selectSlot}
                                        selectedSlot={data?.selected_slot}
                                        data={data} />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Stack direction={"row"} spacing={1} justifyContent={"right"} className={classes.btnBox}>

                            <Button variant="outlined" disableElevation className={classes.walkinBtn}
                                disabled={data?.selected_slot?.length === 0}
                                onClick={() => {
                                    openPopup("walkin", data?.selectedVenue?.is_overbooking ? "enable" : "")
                                    setSelectedType("walkin")
                                }}
                            >{t("Bookforwalkin")}</Button>
                            <Button variant="contained" disableElevation className={classes.bookBtn}
                                disabled={data?.selected_slot?.length === 0}
                                onClick={() => {
                                    openPopup("", data?.selectedVenue?.is_overbooking ? "enable" : "")
                                    setSelectedType("")
                                }}>{t("BookforResident")}</Button>
                        </Stack >
                    </Grid >
                </Grid >

                <AlertDialog open={member?.bool}
                    onClose={() => setMember({ ...member, bool: false, count: "" })}
                    header={t("Enter Member Count")}
                    component={
                        <MemberCount
                            member={member}
                            setMember={setMember}
                            handleContinueMemberCount={handleContinueMemberCount}
                            t={t} />
                    }
                    isNormal
                />
            </CustomPaper >
        </>
    )
}

export default withNamespaces("amenityBooking")(CheckAvailability)
