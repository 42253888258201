export const TabDetails = [
    {
        value: "1",
        label: "Scope The Plan"
    },
    {
        value: "2",
        label: "Unit/Asset"
    },
    {
        value: '3',
        label: "Resources"
    },
    {
        value: "4",
        label: "Projected BOM"
    },
    {
        value: '5',
        label: "Checklists"
    },
    {
        value: "6",
        label: "Schedule Work Orders"
    },
    {
        value: '7',
        label: "Executed Work Orders"
    },
    {
        value: "8",
        label: "Executed Jobs"
    },
    {
        value: "9",
        label: "Work Permit"
    },
    {
        value: "10",
        label: "Actual BOM"
    },
    {
        value: '11',
        label: "Invoice"
    },
]
export const DynamicTabDetails  = [
    {
        value: "1",
        label: "Scope The Plan"
    },
    {
        value: "2",
        label: "Property"
    },
    {
        value: '3',
        label: "Checklists"
    },
    {
        value: "4",
        label: "Projected BOM"
    },
    {
        value: '5',
        label: "Plans"
    },
]
export const BOMPath = ["item_type", "category_name", "subcategory_name", "item_name", "amount_per_item", "quatity", "line_amount"]

export const BOMHeading = (t) => [
    { title: t("Type"), field: "item_type" },
    { title: t("Category"), field: "category_name" },
    { title: t("Subcategory"), field: "subcategory_name" },
    { title: t("Item"), field: "item_name", },
    { title: t("Value"), field: "amount_per_item", },
    // { title: t("UOM"), field: "uom", },
    { title: t("Qty"), field: "quatity", },
    { title: t("Total Amount"), field: "line_amount", },
]

export const BOMDataType = [
    { type: ["text"], name: "item_type" },
    { type: ["text"], name: "category_name" },
    { type: ["text"], name: "subcategory_name" },
    { type: ["text"], name: "item_name" },
    { type: ["text"], name: "amount_per_item" },
    // { type: ["text"], name: "uom" },
    { type: ["text"], name: "quatity" },
    { type: ["text"], name: "line_amount" },
]
export const CheckListPath = [ "name", "inspection_type", "checlist_count", "is_active"]

export const CheckListHeading = (t) => [
    // { title: t("Check List Name"), field: "name" },
    { title: t("Check List Group"), field: "name" },
    { title: t("Item Type"), field: "inspection_type" },
    // { title: t("Item Category"), field: "item_category", },
    { title: t("Conditions"), field: "checlist_count", },
    // { title: t("Mandatory Check List"), field: "is_mandatory", },
    // { title: t("Software & Hardware"), field: "is_hardware_software", },
    { title: t("Status"), field: "is_active", },
]

export const CheckListDataType = [
    { type: ["text"], name: "name" },
    // { type: ["text"], name: "group_name" },
    { type: ["text"], name: "inspection_type" },
    // { type: ["text"], name: "item_category" },
    { type: ["text"], name: "checlist_count" },
    // { type: ["text"], name: "is_mandatory" },
    // { type: ["text"], name: "is_hardware_software" },
    { type: ["status"], name: "is_active" },
]
export const ScheduleWorkOrdersPath = ["execution", "item_reference_no", "item_name", "unit_name", "property_name", "first_name", "is_active"]

export const ScheduleWorkOrdersHeading = (t) => [
    // { title: t("Schedule ID"), field: "reference_id" },
    { title: t("Execution Date"), field: "execution" },
    { title: t("Item ID"), field: "item_reference_no" },
    { title: t("Item Name"), field: "item_name", },
    { title: t("Unit"), field: "unit_name", },
    { title: t("Property"), field: "property_name", },
    { title: t("Resource"), field: "first_name", },
    { title: t("Status"), field: "is_active", },
]

export const ScheduleWorkOrdersDataType = [
    // { type: ["text"], name: "reference_id" },
    { type: ["date"], name: "execution" },
    { type: ["text"], name: "item_reference_no" },
    { type: ["text"], name: "item_name" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "first_name" },
    { type: ["status"], name: "is_active" },
]
export const PropertyPath = ["logo","property_no", "name", "location", "is_active"]

export const PropertyHeading = (t) => [
    { title: "", field: "logo" },
    { title: t("Property Id"), field: "property_no" },
    { title: t("Property Name"), field: "name" },
    { title: t("Location"), field: "location", },
    { title: t("Status"), field: "is_active" },

]

export const PropertyDataType = [
    { type: ["avatarmanagement"], name: "logo" },
    { type: ["text"], name: "property_no" },
    { type: ["text"], name: "name" },
    { type: ["long_text"], name: "location" },
    { type: ["status"], name: "is_active" },
]
export const ExecutedWorkOrdersPath = [
    "plan_no",
    "job_id",
    "execution",
    "item_reference_no",
    "item_name",
    "unit_name",
    "property_name",
    "status",
  ];

export const ExecutedWorkOrdersHeading = (t) => [
    { title: t("Plan ID"), field: "plan_no" },
    { title: t("Job ID"), field: "job_id" },
    { title: t("Execution Date"), field: "execution" },
    { title: t("Item ID"), field: "item_reference_no" },
    { title: t("Item Name"), field: "item_name", },
    { title: t("Unit"), field: "unit_name", },
    { title: t("Property"), field: "property_name", },
    { title: t("Status"), field: "status", },
]

export const ExecutedWorkOrdersDataType = [
    { type: ["text"], name: "plan_no" },
    { type: ["text"], name: "job_id" },
    { type: ["text"], name: "execution" },
    { type: ["text"], name: "item_reference_no" },
    { type: ["text"], name: "item_name" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "property_name" },
    { type: ["agreement_inspection_status"], name: "status" },
  ]
export const ExecutedJobsPath = ["plan_reference", "job_referenece_no", "job_name", "execution", "completed_on", "eta","billable_hour","status"]

export const ExecutedJobsHeading = (t) => [
    { title: t("Plan ID"), field: "plan_reference" },
    { title: t("Job ID"), field: "job_referenece_no" },
    { title: t("Job Type"), field: "job_name" },
    { title: t("Execution Date"), field: "execution", },
    { title: t("Completed on"), field: "completed_on", },
    { title: t("Total Hours"), field: "eta", },
    { title: t("Billable Hours"), field: "billable_hour", },
    { title: t("Status"), field: "status", },
]

export const ExecutedJobsDataType = [
    { type: ["text"], name: "plan_reference" },
    { type: ["text"], name: "job_referenece_no" },
    { type: ["text"], name: "job_name" },
    { type: ["date"], name: "execution" },
    { type: ["date"], name: "completed_on" },
    { type: ["text"], name: "eta" },
    { type: ["text"], name: "billable_hour" },
    { type: ["agreement_inspection_status"], name: "status" },
]
export const WorkPermitPath = ["plan_id", "reference_id", "resource_name", "job_title", "property", "unit", "period", "request_type", "status"]

export const WorkPermitHeading = (t) => [
    { title: t("Plan ID"), field: "plan_id" },
    { title: t("Schedule Job ID"), field: "reference_id" },
    { title: t("Resource Name"), field: "resource_name" },
    { title: t("Job Title"), field: "job_title" },
    { title: t("Property"), field: "property", },
    { title: t("Unit Name"), field: "unit", },
    { title: t("Period"), field: "period", },
    { title: t("Request Type"), field: "request_type" },
    { title: t("Status"), field: "status", },
]

export const WorkPermitDataType = [
    { type: ["text"], name: "plan_id" },
    { type: ["text"], name: "reference_id" },
    { type: ["text"], name: "resource_name" },
    { type: ["text"], name: "job_title" },
    { type: ["text"], name: "property" },
    { type: ["text"], name: "unit" },
    { type: ["text"], name: "period" },
    { type: ["text"], name: "request_type" },
    { type: ["text"], name: "status" },
]
export const InvoicePath = ["plan_id", "work_order_id", "job_id", "account_id", "invoice_no", "invoice_date", "invoice_amount", "due_date","status","balance","agreement_id","contract_id"]

export const InvoiceHeading = (t) => [
    { title: t("Plan ID"), field: "plan_id" },
    { title: t("Work Order ID"), field: "work_order_id" },
    { title: t("Job ID"), field: "job_id" },
    { title: t("Account ID"), field: "account_id" },
    { title: t("Invoice Number"), field: "invoice_no" },
    { title: t("Invoice Date"), field: "invoice_date" },
    { title: t("Invoice Amount"), field: "invoice_amount"},
    { title: t("Due Date"), field: "due_date"},
    { title: t("Status"), field: "status"},
    { title: t("Balance"), field: "balance"},
    { title: t("Agreement ID"), field: "agreement_id"},
    { title: t("Contract ID"), field: "contract_id"},
]

export const InvoiceDataType = [
    { type: ["text"], name: "plan_id" },
    { type: ["text"], name: "work_order_id" },
    { type: ["text"], name: "job_id" },
    { type: ["text"], name: "account_id" },
    { type: ["text"], name: "invoice_no" },
    { type: ["date"], name: "invoice_date" },
    { type: ["text"], name: "invoice_amount" },
    { type: ["date"], name: "due_date" },
    { type: ["text"], name: "status" },
    { type: ["text"], name: "balance" },
    { type: ["text"], name: "agreement_id" },
    { type: ["text"], name: "contract_id" },
]