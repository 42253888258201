import ClearIcon from '@mui/icons-material/Clear';
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import ClockArrow from "../../assets/clockArrow";
import DelSvg from "../../assets/popupSvg/delSvg";
import { stringAvatar } from '../../utils';
import { PopUpCardStyles } from "./styles";
export const PopupCard = ({ selected = false, deleteSvg = false, Resources = false, deleteIcon = false, list = {}, assignData = "", removeData = () => false, hours = "", handleClickResource = () => false, mt = 2 }) => {
    const classes = PopUpCardStyles();
    return (
        <>
            <Box className={selected === true ? classes.selectInsBox : classes.assignInsBox} mt={mt}>
                {Resources === true ?
                    <Box display={"flex"} justifyContent={"space-between"} p={1} onClick={() => handleClickResource(list)} className={classes.otherResourcediv}>
                        <Box display={"flex"} alignItems="center">
                            <Box> <Avatar src={list?.url} {...stringAvatar(list?.name)}></Avatar></Box>
                            <Box marginLeft="12px" >
                                <Box display="flex">
                                    <Typography className={classes.userName}>
                                        {list?.reference_id ? list?.reference_id : ""}
                                    </Typography>
                                    <Typography className={classes.type}>{list?.type ?? ""}</Typography>
                                </Box>
                                <Typography className={classes.num}>
                                    {list?.name}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display={"flex"} mt={1} >
                            {
                                hours &&
                                <>
                                    <ClockArrow className={classes.icon} />
                                    <Typography className={classes.hrstext}>{hours ? `${hours} hr` : "-"}</Typography>
                                </>
                            }

                        </Box>
                        <Box className={classes.hoursection} >
                            <center>
                                <Typography className={classes.totalhourRate}>{list?.total_rate ? `${list?.total_rate ?? ""} ${list?.currency_symbol}` : "-"}</Typography>
                                <Typography className={classes.perhourRate}>{`${list?.hourly_charge} ${list?.currency_symbol} / Hr ` ?? "-"}</Typography>

                            </center>
                        </Box>
                        {deleteSvg === true &&
                            <Box className={classes.deletepointer} onClick={() => removeData(list.resource_id, "otherResource")}>
                                <DelSvg />
                            </Box>
                        }


                    </Box>
                    :
                    <Box p={1} className={classes.userDiv} display="flex" justifyContent={"space-between"}>
                        <Box display="flex" alignItems="center" onClick={() => handleClickResource(list)}>
                            <Box >
                                <Avatar src={list?.url} {...stringAvatar(list?.name)}></Avatar>
                            </Box>
                            <Box flexGrow={1} marginInlineStart="10px">
                                <Typography className={classes.userName}>
                                    {list?.name}
                                </Typography>
                                <Typography className={classes.num}>
                                    {list?.role}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display={"flex"} mt={1} >
                            {
                                hours &&
                                <>
                                    <ClockArrow className={classes.icon} />
                                    <Typography className={classes.hrstext}>{hours ? `${hours} hr` : "-"}</Typography>
                                </>
                            }

                        </Box>
                        <Box className={classes.hoursection} >
                            <center>
                                <Typography className={classes.totalhourRate}>{list?.total_rate ? `${list?.total_rate ?? ""} ${list?.currency_symbol}` : "-"}</Typography>
                                <Typography className={classes.perhourRate}>{`${list?.hourly_charge} ${list?.currency_symbol} / Hr ` ?? "-"}</Typography>

                            </center>
                        </Box>
                        <Box>
                            {deleteIcon === true &&
                                <IconButton onClick={() => removeData(list.resource_id, "primary", assignData)}>
                                    <ClearIcon />
                                </IconButton>
                            }
                        </Box>
                    </Box>
                }
            </Box>
        </>

    );
};
