import React, { useState } from "react";
import { FormGenerator, AlertDialog } from "../../../components";
import { Button, Box } from "@mui/material";
import { useStyleTimeLine } from "../styles";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import moment from "moment-timezone";
import { AlertContext } from "../../../contexts";
import { addDays } from "date-fns";
export const TermisionRequestForm = ({
  open = false,
  setOpen = () => false,
  t,
  agreement_id,
  details,
  agreementReload = () => false
}) => {
  const classes = useStyleTimeLine();
  const alert = React.useContext(AlertContext);

  const [state, setState] = useState({
    termissionDate: "",
    tentativeDate: "",
    Description: "",
    error: {
      termissionDate: "",
      tentativeDate: "",
    }
  });
  const [disable, setDisable] = useState(false)


  const updateState = (key, value) => {
    let error = state.error;
    error[key] = "";
    if (key === "termissionDate") {
      setState({
        ...state,
        [key]: value,
        date: value < state?.tentativeDate ? value : state?.tentativeDate,
        error
      });
    } else {
      setState({ ...state, [key]: value ,error});    }
    
  };

  const sumbit = () => {
    if(validateContact()){
      setDisable(true)
      const variables = {
        "agreement_id": agreement_id,
        "tentative_termination_date": moment(state?.tentativeDate).format('YYYY/MM/DD'),
        "remarks": state?.Description,
        "request_purpose": "Termination",
        "termination_date": moment(state?.termissionDate).format('YYYY/MM/DD'),
        "contact_id": details?.contact?.id
      };
      NetworkCall(
        `${config.api_url}/agreement_request/create_agreement_request`,
        NetWorkCallMethods.post,
        variables,
        null,
        true,
        false
      )
        .then((res) => {
          agreementReload()
          setOpen({ ...open, bool: false })
          setDisable(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Termination request created successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
  
        })
        .catch((err) => {
          setDisable(false)
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Some Thing Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          console.log(err);
        });
    }

  };
  // const dateChange=(value)=>{
  //   setState({
  //     ...state,
  //     termissionDate:value,
  //     tentativeDate:(!!state?.tentativeDate && value < state?.tentativeDate) ? value : state?.tentativeDate
  //   })
  // }
  const validateContact = () => {
    let isValid = true;
    let error = state.error;
    if (state?.termissionDate === "") {
      isValid = false;
      error.termissionDate = `${t(
        "Agreement Termination Date is Required"
      )}`;
    }
    if (state?.tentativeDate === "") {
      isValid = false;
      error.tentativeDate = `${t("Tentative Vacating Date is Required")}`;
    }


    setState({ ...state, error });

    return isValid;
  };


  return (
    <Box>
      <AlertDialog
        isNormal={true}
        header={t("Termination_Request")}
        onClose={() => setOpen({ ...open, bool: false })}
        open={open}
        component={
          <Box sx={{ padding: '24px' }}>

            <FormGenerator
              t={t}
              components={[
                {
                  isActive: true,
                  component: "date",
                  label: t("Agreement_Termination_Date"),
                  value: state?.termissionDate,
                  placeholder: t("Agreement_Termination_Date"),
                  onChange: (value) => updateState("termissionDate", value),
                  error: state?.error?.termissionDate,
                  minDate:new Date(),
                  maxDate: !!details?.lease_end_date  ? addDays(new Date(details?.lease_end_date),30) : new Date(),
                  isRequired: true,
                  isNot: true,
                  is_popover:true,
                  size: {
                    xs: 12,
                  },
                },
                {
                  isActive: true,
                  component: "date",

                  label: t("Tentative_Vacating_Date"),
                  value: state?.tentativeDate,
                  placeholder: t("Tentative_Vacating_Date"),
                  onChange: (value) => updateState("tentativeDate", value),
                  error: state?.error?.tentativeDate,
                  isRequired: true,
                  isNot: true,
                  minDate: new Date(),
                  maxDate: new Date(state?.termissionDate),

                  size: {
                    xs: 12,
                  },
                },
                {
                  isActive: true,
                  component: "text",
                  label: t("Reason_for_Termination"),
                  value: state?.Description,
                  placeholder: t("Reason_for_Termination"),
                  onChange: (e) => updateState("Description", e?.target?.value),
                  error: state?.error?.Description,
                  size: {
                    xs: 12,
                  },
                  multiline: true,

                },
              ]}
            />
            <Box height={"24px"} />
            <Button className={classes.Yes} onClick={sumbit} disable={disable}>{t("Submit")}</Button>

          </Box >
        }
      />
    </Box >
  );
};
