import { Box, IconButton, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import ShortListIMG from "../../../assets/shortlistImg";
import { filterStyles } from "./styles";
import { withNamespaces } from "react-i18next";
const ListViewCard = (props) => {
    const { t } = (props);

    const classes = filterStyles();
    return (
        <Grid container  className={classes.UnitCardroot} sx={{padding:"12px 12px 8px 12px !important"}}>
            <Grid item xs={12} md={4} lg={3} sx={{position:"relative"}}>
                <img src={props?.data?.url ?? "/images/imagesproperty.svg"} alt="" className={classes.unitCardImg} />
                <>
                    {props?.data?.unit_category && <Typography className={classes.type}>{props?.data?.unit_category}</Typography>}
                    {props?.data?.unit_categoryByID?.id && <Typography className={classes.type}>{props?.data?.unit_categoryByID?.name}</Typography>}
                </>
            </Grid>
            <Grid item xs={12} md={8} lg={9} sx={{position:"relative",paddingLeft:"8px"}}>
            {
                    !props?.noBtn &&
                    <>
                        {
                            props?.delete ?
                                <IconButton className={classes.shortlistBTN} onClick={props?.onDelete} size="small">
                                    <img src="/images/delete.svg" alt="" height="10px" />
                                </IconButton> :
                                props?.data?.room_rent &&

                                <IconButton className={props?.shortlistid?.some((val) => val === props?.data?.id) ? classes.shortlistBTNactive : classes.shortlistBTN} sx={{backgroundColor: props?.shortlistid?.some((val) => val === props?.data?.id) ? "auto":"#F5F7FA !important"}} onClick={() => props?.handleShortlist(props?.data?.id)} size="small">
                                    <ShortListIMG color={props?.shortlistid?.some((val) => val === props?.data?.id) ? "white" : "#98a0ac"} />
                                </IconButton>
                        }
                    </>
                }

                {props?.data?.pricing?.some((val) => val?.discountValue > 0) &&
                    <Typography className={classes.discountAppliedRoot}>% {t("Discount Applied")}</Typography>
                }
                <Typography className={classes.unitName} sx={{marginTop:"4px"}}>{props?.currency ?? ""} {props?.data?.room_rent?.toFixed(2)??0}</Typography>
                <Typography className={classes.unitName} sx={{marginTop:"6px"}}>{props?.data?.name}</Typography>
                <Stack direction="row" alignItems="center" marginTop="4px">
                    {/* <Typography className={classes.build1} noWrap>{props?.data?.unit_no}</Typography>
                    {
                        props?.data?.unit_no && <Box className={classes.dot} />
                    } */}
                    <Typography className={classes.build1} noWrap>{props?.data?.unit_type}&nbsp;</Typography>
                    {
                        props?.data?.unit_type && <Box className={classes.dot} />
                    } 
                    <Typography noWrap className={classes.build1}>{props?.data?.total_area}&nbsp;{props?.data?.uom_master?.name ?? props?.data?.uom_name}</Typography>
                   
            </Stack >
            <Stack direction="row" alignItems="flex-end"  >
                        {
                            props?.data?.total_bed_rooms &&
                            <Box display="flex" alignItems="flex-end" marginTop="4px">
                                <img src="/images/bed.svg" alt="" />
                                <Typography className={classes.build1} noWrap>{props?.data?.total_bed_rooms} {t("Bed")}</Typography>

                            </Box>
                        }
                        {
                            props?.data?.total_bed_rooms &&
                            <Box marginTop="4px">
                                <Box className={classes.dot} />
                            </Box>
                        }
                        {
                            props?.data?.total_baths &&
                            <Box display="flex" alignItems="flex-end" marginTop="4px">
                                <img src="/images/bath.svg" alt="" />
                                <Typography className={classes.build1} noWrap>&nbsp;{props?.data?.total_baths}&nbsp;{t("Bath")}</Typography>

                            </Box>
                        }
                        {/* {
                            props?.data?.total_baths &&
                            <Box>
                                <Box className={classes.dot} />
                            </Box>
                        } */}
{/* 
                        {
                            props?.data?.unit_type &&
                            <Box display="flex" alignItems="flex-end">
                                <img src="/images/Group 96257.svg" alt="" />
                                <Typography className={classes.build1} noWrap>{props?.data?.unit_type}&nbsp;</Typography>

                            </Box>
                        }
                        */}
                </Stack>
                <Typography className={classes.viewdetail} 
                sx={{marginTop:"4px"}}
                            onClick={() => {
                                props?.onClick(props?.data?.id)
                                if (props?.quotation) {
                                    props?.onSelectedUnit(props?.data)
                                }
                            }}>
                            {props?.quotation ? t("Edit Pricing") : t("View Details")}
                        </Typography>

        </Grid>

    
           
        </Grid >
    )
}
export default withNamespaces("shortListUnits")(ListViewCard)
