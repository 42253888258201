export const Path = ["name", "coa_type", "is_active"]

export const Heading = (t) => [
    { title: t("Name"), field: "name" },
    // { title: t("Number"), field: "number" },
    { title: t("Type"), field: "coa_type" },
    { title: t("Status"), field: "is_active", },
    { title: "", field: "icon" },
]

export const Type = [
    { type: ["text"], name: "name" },
    // { type: ["number"], name: "number"},
    { type: ["text"], name: "coa_type" },
    { type: ["status"], name: "is_active" },
    { type: ["more"], icon: "icon" },
]

export const defaultAddState = {
    formType: "add",
    name: "",
    type: "",
    is_active: true,
    error: {
        name: "",
        type: "",
    }
}

export const defaultFilterData = { is_active: [true],type:[] }