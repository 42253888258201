import { Avatar, Box, Button, Stack, Typography } from "@mui/material"
import React from "react"
import { SearchFilter, TickIcon, UseDebounce } from "../../../components"
import { NetworkCall } from "../../../networkcall"
import { config } from "../../../config"
import { AlertProps, NetWorkCallMethods, stringAvatar, useWindowDimensions } from "../../../utils"
import { AlertContext } from "../../../contexts"
import { AggrementTabStyles } from "./styles"
import InfiniteScroll from "react-infinite-scroll-component"
import { Simmer } from "./simmer"

export const OwnerDetails = ({
    agreement_id = "",
    t = () => false,
    reload = () => false,
    value = "",
    onChange = () => false,
    onClose=()=>false
}) => {
    const alert = React.useContext(AlertContext);
    const classes = AggrementTabStyles()
    const [offset, setOffset] = React.useState(0)
    const size = useWindowDimensions()
    const debounce = UseDebounce()
    const [bool, setBool] = React.useState(false)
    const [owner, setOwner] = React.useState(null)
    const [data, setData] = React.useState({
        data: [],
        count: 0
    })
    const [search, setSearch] = React.useState("")

    const onSearch = (v) => {
        setSearch(v)
        debounce(() => getOwner(0, false, v), 800)

    }
    const fetchMore = () => {
        setOffset(offset + 10)
        getOwner(offset + 10, true)
    }
    const getOwner = (off, filter, searchText) => {
        setBool(true)
        const payload = {
            agreement_id: agreement_id ?? undefined,
            offset: off,
            limit: 10,
            search: searchText
        }
        NetworkCall(
            `${config.api_url}/agreement/get_owner`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setBool(false)
            setData({
                data: filter ? [...data?.data, ...response?.data?.data] : response?.data?.data,
                count: response?.data?.count
            })
            // reload();
        }).catch((err) => {
            setBool(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Something went wrong"),
            });
        })
    }
    const addFun=()=>{
        onChange(owner)
        onClose()
    }

    React.useEffect(() => {
        getOwner(offset)
        setOwner(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreement_id])
    return (
        <Box >
            <Box p={2}>
                <SearchFilter value={search} handleChange={onSearch} placeholder={t("Search")} label={""} />
            </Box>
            <Box>
                <InfiniteScroll
                    dataLength={data?.data?.length}
                    next={fetchMore}
                    height={size?.height - 400}
                    hasMore={true}
                    loader={bool ? data?.data?.length ? <Simmer classes={classes} /> :
                        <Stack direction={"column"} spacing={2} p={2}>
                            {
                                Array.from(Array(9).keys())?.map((c) => {
                                    return <Simmer classes={classes} />
                                })
                            }

                        </Stack>
                        : ''}

                >
                    <Stack direction={"column"} spacing={2} p={2}>
                        {
                            data?.data?.map((val) => {
                                return (
                                    <Stack direction={"row"} spacing={2} mt={0.5} alignItems={'center'} width="100%" onClick={() => setOwner(val)} sx={{cursor:"pointer"}}>
                                        <Stack direction={"row"} spacing={2} width="100%" alignItems={'center'}>
                                            <Avatar src={val?.image_url} {...stringAvatar(val?.first_name)}>{val?.image_url ?? ""}</Avatar>
                                            <Box>
                                                <Typography className={classes.sub1}>{val?.first_name ?? ""}{val?.last_name ?? ""}</Typography>
                                                <Stack direction={"row"} alignItems={'center'} mt={0.5} spacing={2}>
                                                    <Typography className={classes.summarytitle1}>{val?.email_id ?? ""}</Typography>
                                                    <Box className={classes.dot} />
                                                    <Typography className={classes.summarytitle1}>{val?.mobile_no_country_code ?? ""}{val?.mobile_no ?? ""}</Typography>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                        <Box>
                                            {
                                                val?.id === owner?.id && <TickIcon />
                                            }

                                        </Box>
                                    </Stack>
                                )
                            })
                        }
                    </Stack>
                </InfiniteScroll>
                <Box className={classes.root}>
                    <Button variant="contained" className={classes.btn} onClick={addFun} sx={{ width: "100%" }}>Add</Button>
                </Box>
            </Box>
        </Box>
    )
}