import * as React from "react"

export const KycDetails = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
        <g data-name="Group 102037">
            <path
                d="M13.689 0H3.288A2.365 2.365 0 0 0 1 2.429v13.142A2.365 2.365 0 0 0 3.288 18h10.4a2.365 2.365 0 0 0 2.288-2.429V2.429A2.365 2.365 0 0 0 13.689 0Zm-5.2 4.141a2.148 2.148 0 0 1 2.08 2.209 2.084 2.084 0 1 1-4.16 0 2.148 2.148 0 0 1 2.08-2.209ZM12.233 11c0 1.263-1.517 2.413-3.744 2.413S4.744 12.267 4.744 11v-.3a.8.8 0 0 1 .775-.822h5.939a.8.8 0 0 1 .775.822Z"
                fill={props?.color}
                data-name="Group 101118"
            />
        </g>
    </svg>
)


