import * as React from "react"

const Percentage = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={67.157}
        height={59.142}
        {...props}
    >
        <g data-name="Group 102571">
            <g data-name="Group 102568">
                <g data-name="Group 102569">
                    <g data-name="Group 102566">
                        <g data-name="Group 102570">
                            <path
                                data-name="Path 96926"
                                d="M61.209 29.226A26.908 26.908 0 1 1 34.301 2.318a26.908 26.908 0 0 1 26.908 26.908"
                                fill="#fc0"
                            />
                            <path
                                data-name="Path 96927"
                                d="M54.889 11.907a26.907 26.907 0 0 0-45.98 26.226q22.761-13.515 45.98-26.226"
                                fill="#fcdc59"
                            />
                            <path
                                data-name="Path 96928"
                                d="M37.412 55.945a26.821 26.821 0 0 0 15.888-7.67c-4.2 2.019-8.392 4.062-12.588 6.092-1.1.531-2.2 1.051-3.3 1.578"
                                fill="#fcdc59"
                            />
                            <path
                                data-name="Path 96929"
                                d="M54.336 28.8a20.456 20.456 0 1 1-20.46-20.456A20.455 20.455 0 0 1 54.336 28.8"
                                fill="#da8100"
                            />
                            <path
                                data-name="Path 96930"
                                d="M54.908 29.653A20.181 20.181 0 1 1 34.727 9.471a20.18 20.18 0 0 1 20.181 20.182"
                                fill="#f0aa00"
                            />
                            <path
                                data-name="Path 96933"
                                d="m3.368 28.375 1.191 2.177 2.177 1.191-2.177 1.19-1.19 2.177-1.192-2.177L0 31.743l2.177-1.191Z"
                                fill="#78b1fe"
                            />
                            <path
                                data-name="Path 96934"
                                d="m9.558 6.63.6 1.106 1.108.606-1.108.606-.6 1.11-.606-1.108-1.108-.606 1.104-.608Z"
                                fill="#78b1fe"
                                opacity={0.5}
                            />
                            <path
                                data-name="Path 96935"
                                d="m53.3 0 1.172 2.144 2.143 1.172-2.143 1.172L53.3 6.631l-1.172-2.143-2.143-1.173 2.143-1.172Z"
                                fill="#78b1fe"
                            />
                            <path
                                data-name="Path 96936"
                                d="m55.71 51.137.851 1.556 1.549.851-1.556.851-.844 1.556-.851-1.556-1.556-.851 1.556-.851Z"
                                fill="#78b1fe"
                            />
                            <path
                                data-name="Path 96937"
                                d="m65.562 30.148.564 1.031 1.031.564-1.031.564-.564 1.031-.564-1.031-1.031-.564 1.031-.564Z"
                                fill="#78b1fe"
                                opacity={0.5}
                            />
                            <path
                                data-name="Path 96940"
                                d="m15.016 55.951.564 1.031 1.031.564-1.031.564-.564 1.031-.564-1.031-1.031-.564 1.031-.564Z"
                                fill="#78b1fe"
                                opacity={0.5}
                            />
                            <path
                                data-name="Path 96938"
                                d="m65.561 18.587.194.354.354.194-.354.194-.194.354-.194-.354-.355-.194.355-.194Z"
                                fill="#78b1fe"
                                opacity={0.5}
                            />
                            <path
                                data-name="Path 96939"
                                d="m2.819 18.587.194.354.355.194-.355.194-.194.354-.194-.354-.354-.194.355-.194Z"
                                fill="#78b1fe"
                            />
                            <text
                                data-name="%"
                                transform="translate(24.869 39.652)"
                                fill="#ea9c00"
                                fontSize={29}
                                fontFamily="Roboto-Bold, Roboto"
                                fontWeight={700}
                            >
                                <tspan x={0} y={0}>
                                    {"%"}
                                </tspan>
                            </text>
                            <text
                                data-name="%"
                                transform="translate(23.586 39.652)"
                                fill="#fc0"
                                fontSize={29}
                                fontFamily="Roboto-Bold, Roboto"
                                fontWeight={700}
                            >
                                <tspan x={0} y={0}>
                                    {"%"}
                                </tspan>
                            </text>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Percentage
