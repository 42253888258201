export const InvoiceTabIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="Group_115993" data-name="Group 115993" transform="translate(4807.166 7031)">
                <path id="icons8-image_2_" data-name="icons8-image (2)" d="M8.75,6A2.75,2.75,0,0,0,6,8.75V17.7l3.753-3.63a2.067,2.067,0,0,1,2.813,0l3.089,2.988,1.024-.99a2.073,2.073,0,0,1,2.813,0L24,20.429V8.75A2.75,2.75,0,0,0,21.25,6Zm9.5,3.5A2.25,2.25,0,1,1,16,11.75,2.253,2.253,0,0,1,18.25,9.5Zm0,1.5a.75.75,0,1,0,.75.75A.75.75,0,0,0,18.25,11Zm-7.091,4a.52.52,0,0,0-.363.144L6,19.788V21.25a2.75,2.75,0,0,0,2.5,2.737L14.577,18.1l-3.054-2.954A.523.523,0,0,0,11.159,15Zm6.928,2a.521.521,0,0,0-.363.143L10.639,24H21.25a2.75,2.75,0,0,0,2.537-1.69l-5.336-5.162A.524.524,0,0,0,18.087,17Z" transform="translate(-4813.166 -7037)" fill="#98a0ac" opacity="0" />
                <path id="icons8-bill_10_" data-name="icons8-bill (10)" d="M6.025,5A2.027,2.027,0,0,0,4,7.025v10.8a.675.675,0,0,0,.838.655l1.637-.409,1.637.409a.67.67,0,0,0,.327,0l1.637-.409,1.637.409a.675.675,0,0,0,.838-.655v-.389a5.625,5.625,0,1,0,0-8.221V7.025a.675.675,0,1,1,1.35,0v.269A6.469,6.469,0,0,1,15.238,6.9,2.022,2.022,0,0,0,13.225,5Zm.45,3.6h3.6a.675.675,0,1,1,0,1.35h-3.6a.675.675,0,1,1,0-1.35Zm9.9.45a4.275,4.275,0,1,1-3.825,6.183V11.417A4.261,4.261,0,0,1,16.375,9.05Zm-.02,1.8a.675.675,0,0,0-.193.034l-1.35.45a.675.675,0,1,0,.427,1.281l.461-.154v2.663a.675.675,0,1,0,1.35,0v-3.6a.675.675,0,0,0-.7-.675Zm-9.88.45h3.6a.675.675,0,1,1,0,1.35h-3.6a.675.675,0,1,1,0-1.35Zm0,2.7h2.25a.675.675,0,1,1,0,1.35H6.475a.675.675,0,1,1,0-1.35Zm6.943,5.132a2.018,2.018,0,0,1-1.543.718,2.145,2.145,0,0,1-.446-.049l-1.5-.337-1.111.31a2.017,2.017,0,0,1-.545.076,2.074,2.074,0,0,1-.549-.076L6.7,19.485v1.94a.674.674,0,0,0,.823.658l1.833-.412,1.436.4a.673.673,0,0,0,.366,0l1.435-.4,1.833.412a.668.668,0,0,0,.149.017.674.674,0,0,0,.675-.675V19.748A6.462,6.462,0,0,1,13.418,19.132Z" transform="translate(-4811.166 -7036)" fill="#98a0ac" />
            </g>
        </svg>

    )
}