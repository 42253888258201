import { Avatar, Box, Checkbox, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { useStyle } from "../style"
import { OptionFunction, cardHeaderFun, componentTypeOption, filterValues, pricingFactormanualResponse } from "../function"
import { CustomSelectBox } from "../../../components/customSelectBox"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TypeCategoryComp } from "../../freeTextInvoice/components/typeCategoryComp";
import { NewFormStep } from "../../../components/newFormSteps";
import { LocalStorageKeys, checkNumber, getTotalArray } from "../../../utils";
import { TextBox } from "../../../components";
import { DeleteIcon } from "../../../assets";
import { AuthContext } from "../../../contexts";
import { useContext } from "react";

export const UnitCard = ({ data = {}, header = [], updateState = () => false, t = () => false, anchorEl = false, handleAddMenuClose = () => false, handleCloseNewLine = () => false, deleteComponent = () => false, enumValue, revenue_type,company_id }) => {
    const classes = useStyle()
    const auth = useContext(AuthContext);

    const total_amount = getTotalArray(data?.pricing?.filter((x) => x?.is_active === true)?.filter(item => !filterValues.includes(item?.componentType?.value)), 'subtotal') 

    const currency = data?.pricing?.find((x) => x?.currencySymbol)?.currencySymbol ? data?.pricing?.find((x) => x?.currencySymbol)?.currencySymbol : data?.pricing?.find((x) => x?.currency?.currencySymbol)?.currency?.currencySymbol
   const is_taxable = auth?.auth?.auth?.is_taxable
    const componentTypes = {
        "Component": "Component",
        "Item Master": "Inspection",
        "Wallet Credit": "Component",
        "Wallet Item": "Component",
        "Late fees": "Component",
        "Commission & Payment":"Component"
    }

    const endAdornmentOption = {
        "Amount": JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_symbol,
        "Percentage": "%",
        // "Rental Value": JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_symbol,
        "Per UOM": "UOM"
    }
    return (
        <Box className={classes.cardRoot}>
            <Stack direction="row" justifyContent={'space-between'} style={{ padding: "15px" }} alignItems="center">
                <Stack direction="row" gap={1} alignItems="center">
                    <Avatar style={{ height: "48px", width: "48px", borderRadius: "4px" }} src={data?.url} />
                    <Box>
                        <Typography className={classes.cardTitle}>{data?.name ?? ""}</Typography>
                        <Stack direction={'row'} gap={1} alignItems="center">
                            {
                                cardHeaderFun(data)?.map((x, i) => {
                                    return (
                                        <Stack direction={'row'} gap={1} alignItems="center">
                                            <Stack direction={'row'} gap={1} alignItems="center">
                                                {!x?.is_not_img && x?.icon}
                                                <Typography className={classes.customSubTitle}>{x?.name}</Typography>
                                            </Stack>
                                            {i !== (cardHeaderFun(data)?.length - 1) && <Box style={{ backgroundColor: "#CED3DD", borderRadius: '50%', width: '8px', height: "8px" }} />}
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                    </Box>
                </Stack>
                <Box textAlign="right">
                    <Typography className={classes.money}>{checkNumber(total_amount).toFixed(2)} {currency}</Typography>
                    {/* {
                        Number(total_discount_amount) > 0 &&
                        <Typography className={classes.currency}>{total_discount_amount ? `- ${checkNumber(total_discount_amount).toFixed(2)} ${currency}` : 0}  Discount Applied</Typography>
                    } */}

                </Box>
            </Stack>
            {/* table */}
            <Box style={{ borderBottom: '1px solid #E4E8EE', borderTop: '1px solid #E4E8EE' }}>
                <Box>
                    <TableContainer className={classes.table}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {
                                        header?.filter((v) => v?.is_active)?.map((e) => {
                                            return (
                                                <TableCell className={e?.classes ? e?.classes : classes.headCell} sx={{ width: e?.delete === "delete" ? 30 : "auto" }} pt={2}>
                                                    <Stack direction={'row'} alignItems={'top'} spacing={1}>
                                                        {
                                                            e?.is_icon ? e.title :
                                                                <><Typography className={classes.headText} noWrap> {e?.title} </Typography>
                                                                    {e?.is_required && <Typography variant="caption" sx={{ color: "red" }}>*</Typography>}</>
                                                        }

                                                    </Stack>
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data?.pricing?.map((item, i) => {
                                        return (
                                            <TableRow>
                                                <TableCell className={classes.bodyCellAuto}>
                                                    <Checkbox
                                                        checked={item?.is_active}
                                                        onChange={(val) => {
                                                            updateState(val.target.checked, item, "is_active", i)
                                                        }}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.bodyCell} pl={2}>
                                                    <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                        <Box sx={{ width: 140 }}>
                                                            <CustomSelectBox
                                                                height={'auto'}
                                                                value={item?.componentType}
                                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                                options={componentTypeOption(item?.type, item?.pricing_components, item)}
                                                                onChange={(val) => {
                                                                    updateState(val, item, "componentType", i)
                                                                }}
                                                                placeholder={t("Component Type")}
                                                            />
                                                        </Box>
                                                        {
                                                            (item?.error?.componentType?.length > 0) &&
                                                            <Tooltip arrow title={item?.error?.componentType ?? ""} placement="top">
                                                                <Box m="4px 0px 0px 4px" sx={{ cursor: "pointer" }}>
                                                                    <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                </Box>
                                                            </Tooltip>
                                                        }
                                                    </Stack>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Box sx={{ width: 140 }}>
                                                        <TypeCategoryComp
                                                            t={t}
                                                            onChange={(val) => {
                                                                updateState(val, item, "inspection", i)
                                                            }}
                                                            value={item?.inspection}
                                                            isReadOnly={item?.componenttype === "Component" ? true : false}
                                                        />
                                                    </Box>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                        <Box sx={{ width: 140 }}>
                                                            <CustomSelectBox
                                                                value={item?.pricing_components}
                                                                menuOptionWidth={250}
                                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                                networkCallData={
                                                                    {
                                                                        path: "free_text_invoice/get_free_text_item_types",
                                                                        payload: {
                                                                            company_id: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.value ?? company_id,
                                                                            item_type: (item?.componenttype === "Component") ? item?.componentType?.value === "is_wallet_item" ? "Item Master" : 'Component' : "Item Master",
                                                                            item_master_type: item?.inspection?.type?.value,
                                                                            item_master_category: item?.inspection?.category?.value,
                                                                            component_type: item?.componentType?.value,
                                                                            revenue_type: revenue_type?.value
                                                                        },
                                                                        mappingVariable: "items",
                                                                        manualResponse: pricingFactormanualResponse
                                                                    }
                                                                }
                                                                key={JSON.stringify(item?.itemTypeCategory)}
                                                                onChange={(val) => {
                                                                    updateState(val, item, "pricing_components", i)
                                                                }}
                                                                placeholder={t("Select Component")}
                                                            />

                                                        </Box>
                                                        {
                                                            (item?.error?.pricing_components?.length > 0) &&
                                                            <Tooltip arrow title={item?.error?.pricing_components ?? ""} placement="top">
                                                                <Box m="4px 0px 0px 4px" sx={{ cursor: "pointer" }}>
                                                                    <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                </Box>
                                                            </Tooltip>
                                                        }

                                                    </Stack>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                        <Box sx={{ width: 140 }}>
                                                            <CustomSelectBox
                                                                height={'auto'}
                                                                value={{ value: item?.valueBasisType, label: item?.valueBasisType }}
                                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                                onChange={(val) => {
                                                                    updateState(val?.value, item, "valueBasisType", i)
                                                                }}
                                                                placeholder={t("Based On")}
                                                                options={OptionFunction(enumValue?.unit_breakup_type, item?.componentType)}


                                                            />

                                                        </Box>
                                                        {
                                                            (item?.error?.valueBasisType?.length > 0) &&
                                                            <Tooltip arrow title={item?.error?.valueBasisType ?? ""} placement="top">
                                                                <Box m="4px 0px 0px 4px" sx={{ cursor: "pointer" }}>
                                                                    <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                </Box>
                                                            </Tooltip>
                                                        }
                                                    </Stack>
                                                </TableCell>

                                                {/* <TableCell className={classes.bodyCell}>
                                                    <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                        <Box sx={{ width: 140 }}>
                                                            <CustomSelectBox
                                                                height={'auto'}
                                                                value={{ value: item?.uom_type, label: item?.uom_type }}
                                                                menuOptionPadding={"0px 0px 8px 0px"}
                                                                onChange={(val) => {
                                                                    updateState(val?.value, item, "uom_type", i)
                                                                }}
                                                                placeholder={t("Select UOM")}
                                                                options={enumValue?.utility_period_type}


                                                            />

                                                        </Box>
                                                        {
                                                            (item?.error?.uom_type?.length > 0) &&
                                                            <Tooltip arrow title={item?.error?.uom_type ?? ""} placement="top">
                                                                <Box m="4px 0px 0px 4px" sx={{ cursor: "pointer" }}>
                                                                    <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                </Box>
                                                            </Tooltip>
                                                        }
                                                    </Stack>
                                                </TableCell> */}

                                                <TableCell className={classes.bodyCell}>
                                                    <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                        <TextBox
                                                            label={""}
                                                            height={38}
                                                            type="number"
                                                            value={item?.componentvalues}
                                                            padding={"6px 8px"}
                                                            textAlign={"right"}
                                                            placeholder={t("value")}
                                                            step="0.01"
                                                            onChange={(val) => {
                                                                updateState(val?.target?.value, item, "componentvalues", i)
                                                            }}
                                                            onInput={(event) => {
                                                                let inputValue = event.target.value;

                                                                // Use a regular expression to allow only up to two decimal places
                                                                let regex = /^\d*\.?\d{0,2}$/;

                                                                // Test the input against the regular expression
                                                                if (!regex.test(inputValue)) {
                                                                    // If the input doesn't match, clear the field
                                                                    event.target.value = inputValue.slice(0, -1);
                                                                }
                                                            }}
                                                            isReadonly={item?.valueBasisType === "Rental Value"}
                                                            endAdornment={
                                                                endAdornmentOption[item?.valueBasisType]} />
                                                        {
                                                            (item?.error?.componentvalues?.length > 0) &&
                                                            <Tooltip arrow title={item?.error?.componentvalues ?? ""} placement="top">
                                                                <Box m="4px 0px 0px 4px" sx={{ cursor: "pointer" }}>
                                                                    <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                </Box>
                                                            </Tooltip>
                                                        }
                                                    </Stack>
                                                </TableCell>
                                                {
                                                    revenue_type?.value !== "Sale" &&
                                                    <TableCell className={classes.bodyCell}>
                                                        <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                            <Box sx={{ width: 140 }}>
                                                                <CustomSelectBox
                                                                    isReadOnly={(item?.paymentPeriod && OptionFunction(enumValue?.pricing_period_type, item?.componentType, "payment")?.length <= 1) ? true : false}
                                                                    height={'auto'}
                                                                    value={{ value: item?.paymentPeriod, label: item?.paymentPeriod }}
                                                                    menuOptionPadding={"0px 0px 8px 0px"}
                                                                    onChange={(val) => {
                                                                        updateState(val?.value, item, "paymentPeriod", i)
                                                                    }}
                                                                    placeholder={t("Based On")}
                                                                    options={OptionFunction(enumValue?.pricing_period_type, item?.componentType, "payment")}


                                                                />

                                                            </Box>
                                                            {
                                                                (item?.error?.paymentPeriod?.length > 0) &&
                                                                <Tooltip arrow title={item?.error?.paymentPeriod ?? ""} placement="top">
                                                                    <Box m="4px 0px 0px 4px" sx={{ cursor: "pointer" }}>
                                                                        <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                    </Box>
                                                                </Tooltip>
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                }

                                                <TableCell className={classes.bodyCellAuto} sx={{ width: 80 }}>
                                                    <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                        <TextBox
                                                            label={""}
                                                            height={38}
                                                            value={item?.quantity ?? 0}
                                                            padding={"6px 8px"}
                                                            type={"number"}
                                                            onBlur={(val) => updateState(1, item, "quantity")}
                                                            onChange={(val) => {
                                                                updateState(val?.target?.value, item, 'quantity', i)
                                                            }}
                                                            keyPress={(x) => {
                                                                if (x.key === ".") {
                                                                    x.preventDefault();
                                                                }
                                                            }}
                                                            textAlign={"right"}
                                                            isReadonly={(item?.componentType?.value === "item_based" || item?.componentType?.value === "is_wallet_item") ? false : (item?.valueBasisType === "Rental Value") ? false : true}

                                                        />
                                                        {
                                                            (item?.error?.quantity?.length > 0) &&
                                                            <Tooltip arrow title={item?.error?.quantity ?? ""} placement="top">
                                                                <Box m="4px 0px 0px 4px" sx={{ cursor: "pointer" }}>
                                                                    <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                </Box>
                                                            </Tooltip>
                                                        }
                                                    </Stack>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Stack sx={{ width: "170px" }} direction={"row"} columnGap={"4px"} alignItems={"center"}>

                                                        <NewFormStep component={[
                                                            {
                                                                sm: 12,
                                                                md: 12,
                                                                lg: 12,
                                                                isActive: true,
                                                                type: "textWithSelect",
                                                                label: "",
                                                                value: item?.discountValue ?? 0,
                                                                handleChange: (val) => {
                                                                    updateState(val, item, "discountValue", i)
                                                                },
                                                                placeholder: t("Select Discount"),
                                                                customOption: [
                                                                    { label: "%", value: "%" },
                                                                    { label: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_symbol, value: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_symbol },
                                                                ],
                                                                selectChange: (value) => updateState(value, item, "discountType", i),
                                                                selectedValue: item?.discountType,
                                                                customHeight: 38,
                                                                customPadding: "6px 8px",
                                                                header: "",
                                                                textAlign: "right"
                                                            }
                                                        ]} />
                                                        {
                                                            (item?.discountError?.length > 0 && checkNumber(item?.discountValue) !== 0) &&
                                                            <Tooltip arrow title={item?.discountError ?? ""} placement="top">
                                                                <Box m="4px 0px 0px 4px" sx={{ cursor: "pointer" }}>
                                                                    <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                </Box>
                                                            </Tooltip>
                                                        }
                                                    </Stack>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <TextBox
                                                        label={""}
                                                        height={38}
                                                        isReadonly
                                                        value={Number(item?.beforeTax ?? 0)?.toFixed(2)?.toLocaleString('en-IN')}
                                                        padding={"6px 8px"}
                                                        textAlign={"right"}
                                                        endAdornment={
                                                            JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_symbol
                                                        } />
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <Stack direction={"row"} columnGap={"4px"} alignItems={"center"}>
                                                        <Box sx={{ width: 160 }}>
                                                            <CustomSelectBox
                                                                value={item?.vatGroup}
                                                                menuOptionPadding={"0px 8px"}
                                                                networkCallData={
                                                                    {
                                                                        path: "vat-group/getAll",
                                                                        payload: {
                                                                            country_id: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.data?.country?.id
                                                                        },
                                                                        mappingVariable: "vat_group_master",
                                                                    }
                                                                }
                                                                onChange={(val) => {
                                                                    updateState(val, item, "vatGroup", i)
                                                                }}
                                                                placeholder={t("Select Tax Group")}
                                                                type="tax"
                                                                isReadOnly={!is_taxable}
                                                            />
                                                        </Box>
                                                        {
                                                            (item?.error?.vatGroup?.length > 0) &&
                                                            <Tooltip arrow title={item?.error?.vatGroup ?? ""} placement="top">
                                                                <Box m="4px 0px 0px 4px" sx={{ cursor: "pointer" }}>
                                                                    <InfoOutlinedIcon style={{ color: "#FF4B4B" }} />
                                                                </Box>
                                                            </Tooltip>
                                                        }
                                                    </Stack>
                                                </TableCell>
                                                <TableCell className={classes.bodyCell}>
                                                    <TextBox
                                                        label={""}
                                                        height={38}
                                                        isReadonly
                                                        value={Number(item?.tax ?? 0)?.toFixed(2)?.toLocaleString('en-IN')}
                                                        padding={"6px 8px"}
                                                        textAlign={"right"}
                                                        endAdornment={
                                                            JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_symbol
                                                        } />
                                                </TableCell>
                                                <TableCell className={classes.bodyCell1}>
                                                    <Stack direction="row" alignItems="center" sx={{ gap: "4px" }}>
                                                        <TextBox
                                                            label={""}
                                                            height={38}
                                                            isReadonly
                                                            value={Number(item?.subtotal ?? 0)?.toFixed(2)?.toLocaleString('en-IN')}
                                                            padding={"6px 8px"}
                                                            textAlign={"right"}
                                                            endAdornment={
                                                                JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_symbol
                                                            } />
                                                        <Box sx={{ cursor: "pointer" }} onClick={() => deleteComponent(i, item)}>
                                                            <DeleteIcon />
                                                        </Box>
                                                    </Stack>
                                                </TableCell>

                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <Box style={{ padding: "8.5px 15px", cursor: "pointer" }}>
                <Typography className={classes.add} id="add_new_row"
                    aria-controls={'add-new-menu'}
                    aria-expanded={'true'}
                    aria-haspopup="true" onClick={(e) => handleAddMenuClose(true, e)}>{t("Add +")}</Typography>
                <Menu
                    id="add-new-menu"
                    anchorEl={anchorEl}
                    open={data?.is_open}
                    onClose={() => handleAddMenuClose(false)}
                    MenuListProps={{
                        'aria-labelledby': 'add_new_row',
                    }}
                    className={classes.popover}
                >
                    {
                        ["Component", "Item Master", "Wallet Credit", "Wallet Item", "Commission & Payment"]?.map((e) => {
                            return (
                                <MenuItem onClick={() => handleCloseNewLine(componentTypes[e], revenue_type, e)} className={classes.menuItem1}>{t(e)}</MenuItem>
                            )
                        })
                    }
                </Menu>
            </Box>
        </Box>
    )
}