import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const quotationsStyles = makeStyles((theme) => ({
    table: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        //padding: "16px",
        //height: `calc(100vh - 147px)`,
        overflow: "hidden",

    },
    chartContainer: {
        borderLeft: "1px solid #E4E8EE",
        height: `calc(100vh - 150px)`,
        overflow: "auto"
    },
    title: {
        fontSize:"1rem",
        color: "#4E5A6B",
        fontFamily: Bold,
        marginBottom: "8px"

    },
    img: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: theme.palette.borderRadius,
        padding: "10px",
        height: "40px",
        width: '40px'
    },
}));