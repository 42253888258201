import * as React from "react"

export const User = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16.2} {...props}>
        <path
            data-name="icons8-management (1)"
            d="M9 0a2.7 2.7 0 1 0 2.7 2.7A2.7 2.7 0 0 0 9 0ZM3.15.9A2.25 2.25 0 1 0 5.4 3.15 2.25 2.25 0 0 0 3.15.9Zm11.7 0a2.25 2.25 0 1 0 2.25 2.25A2.25 2.25 0 0 0 14.85.9ZM4.05 12.15V7.875A2.465 2.465 0 0 1 4.617 6.3H1.575A1.577 1.577 0 0 0 0 7.875V10.8a3.6 3.6 0 0 0 3.6 3.6 3.516 3.516 0 0 0 .927-.122h.009a4.913 4.913 0 0 1-.486-2.128Zm9.9 0V7.875a2.465 2.465 0 0 0-.567-1.575h3.042A1.577 1.577 0 0 1 18 7.875V10.8a3.6 3.6 0 0 1-3.6 3.6 3.516 3.516 0 0 1-.927-.122h-.009a4.913 4.913 0 0 0 .486-2.128ZM11.475 6.3h-1.58a.334.334 0 0 1-.024.139l-.4.9c0 .009-.01.015-.015.024s0 .01.005.015l.441 3.094a.34.34 0 0 1-.067.253l-.562.731a.337.337 0 0 1-.535 0l-.562-.731a.335.335 0 0 1-.067-.253l.441-3.094c0-.006 0-.01.005-.015s-.011-.015-.015-.024l-.405-.9a.331.331 0 0 1-.024-.138h-1.58A1.576 1.576 0 0 0 4.95 7.875v4.275a4.05 4.05 0 0 0 8.1 0V7.875A1.577 1.577 0 0 0 11.475 6.3Z"
            fill="#78b1fe"
        />
    </svg>
)

