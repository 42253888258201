import { Box, Button, Drawer, Fab, Grid, SpeedDialIcon, Typography } from "@mui/material"
import { LeadStylesParent } from "./style";
// eslint-disable-next-line
import { DialogBox, FormGenerator, LoadingSection, TableWithPagination } from "../../../components";
import { ExternalUnitHeading, ExternalUnitPath } from "../common";
import React, { useContext, useState } from "react";
import CloseIconComponent from "../../../assets/closeIcons";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, enumSelect, enum_types } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { AlertContext, BackdropContext } from "../../../contexts";
import { loadOptionsApis } from "../../maintenancerequest/utils";

export const External = (props) => {
    const classes = LeadStylesParent(props);

    const { t, lead_id, getThirdParty = () => false } = (props);
    const language = localStorage.getItem("i18nextLng");
    const alert = useContext(AlertContext);
    const backdrop = useContext(BackdropContext)

    const [state, setState] = useState({
        bool: false,
        detail: null,
        listing: "",
        reference: "",
        property_name: "",
        unit_name: "",
        price: "",
        url: "",
        owner_name: "",
        agent_name: "",
        error:{
            property_name: "",
            unit_name: "",   
        }
    })
    const [enumValue, setEnumValue] = React.useState({
        source: [],
        purpose: [],
        revenue_type: [],
        payment_value_type: []
    });
    const [dialog, setDialog] = React.useState(false);
    const [disableButton, setDisableButton] = useState(false);
    // eslint-disable-next-line
    const [deleteDialog, setDeleteDialog] = useState({
        open: false,
        data: ""
    })

    const updateState = (k, v) => {
        setState({ ...state, [k]: v })
    }
    const closeDrawer = (type) => {
        if (type === "close") {
            setState("detail", {
                bool: false,
                detail: null,
                listing: "",
                reference: "",
                property_name: "",
                unit_name: "",
                price: "",
                url: "",
                owner_name: "",
                agent_name: ""
            })
        }
        setDialog(!dialog);
    };
    const validate = () => {
        let isValid = true
        let error = state.error
        if (state?.property_name?.length === 0) {
            isValid = false; error.property_name = t("Property Name is Required")
        }
        if (state?.unit_name?.length === 0) {
            isValid = false; error.unit_name = t("Unit Name is Required")
        }
      
        setState({ ...state, error })
        return isValid
    }

    // add and edit function
    const save = (payload = null) => {
        if(validate()){
            setDisableButton(true)
            backdrop.setBackDrop({ ...backdrop, open: true, message: "creating..." })
            let datas = {
                external_view_listing_source: state?.listing,
                property_name: state?.property_name,
                unit_type: state?.type?.value,
                price: state?.amount ? parseFloat(state?.amount) : undefined,
                period: state?.Period?.value,
                reference_no: state?.reference,
                url: state?.url,
                proxy_lead_id: lead_id,
                unit_name: state?.unit_name,
                id: state?.detail?.id ?? undefined,
                owner_name: state?.owner_name,
                agent_name: state?.agent_name,
            };
            datas = payload ? payload : datas
            NetworkCall(
                `${config.api_url}/lead_proxy/create/third_party`,
                NetWorkCallMethods.post,
                datas,
                null,
                true,
                false
            )
                .then((response) => {
                    backdrop.setBackDrop({ ...backdrop, open: false, message: payload?.is_delete ? "" : "creating..." })
    
                    if(payload){
                        setDisableButton(false)
                        getThirdParty(0, 10)
                        return alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.success,
                            msg: `${t("Lead Updated successfully.")}`,
                        });
                    }
                    setDisableButton(false)
                    closeDrawer("close")
                    getThirdParty(0, 10)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: `${state?.detail?.id === true ? t("Lead Updated successfully.") : t("Lead Created successfully.")}`,
                    });
                })
                .catch((error) => {
                    backdrop.setBackDrop({ ...backdrop, open: false, message: "creating..." })
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Internal error. Please try again later."),
                    });
                    setDisableButton(false)
                });
        }
    };
    const getEnum = async () => {
        const result = await enumSelect([
            enum_types.lead_source,
            enum_types?.purpose_global_type,
            enum_types.revenue_type,
            enum_types.payment_value_type
        ]);
        const filterValues = ['Monthly', 'Total', "Yearly"]

        setEnumValue({
            source: result?.lead_source,
            revenue_type: result?.revenue_type?.filter((x) => x?.value !== "Stay"),
            purpose: [...result?.purpose_global_type?.filter((x) => x?.value === "Commercial"), ...result?.purpose_global_type?.filter((x) => x?.value === "Residential"), ...result?.purpose_global_type?.filter((x) => x?.value === "Mixed")],
            payment_value_type: result?.payment_value_type.filter(item => filterValues.includes(item.value)),
        });
    };
    const handleIcon = (type, value) => {
        if (type === "edit") {
            setState({
                ...state,
                detail: value,
                listing: value?.external_view_listing_source ?? "",
                reference: value?.reference_no,
                property_name: value?.property_name,
                unit_name: value?.unit_name,
                type: { value: value?.unit_type, label: value?.type },
                price: { select: value?.period, value: value?.price },
                url: value?.url,
                owner_name: value?.owner_name,
                agent_name: value?.agent_name,
                Period: { value: value?.period, label: value?.period },
                amount: value?.price
            })
            setDialog(true)
        }
        else {
            setDeleteDialog({
                open: true,
                data: value
            })
            save({
                is_active:false,
                id:value?.id,
                is_delete:true
            })
        }
    }
    React.useEffect(() => {
        getEnum();
        // eslint-disable-next-line
    }, []);

    const header = ExternalUnitHeading(t)

    return (
        <Box sx={{ position: "relative" }}>
            <TableWithPagination
                heading={header}
                marginTop={'1px'}
                rows={props?.unitList?.data ?? []}
                path={ExternalUnitPath}
                showpagination={true}
                handlePagination={props?.handlePagination}
                handleChangeLimit={props?.handleChangeLimit}
                page={props?.page}
                limit={props?.limit}
                totalRowsCount={props?.unitList?.count}
                tableType="no-side"
                count="2"
                dataType={[
                    { type: ["icon"], icon: "icon" },
                    { type: ["text"], name: "property_name" },
                    { type: ["text"], name: "unit_name" },
                    { type: ["text"], name: "type" },
                    { type: ["text"], name: "period" },
                    { type: ["text"], name: "price" },
                    { type: ["text"], name: "agent_name" },
                    { type: ["text"], name: "owner_name" },
                    { type: ["text"], name: "external_view_listing_source" }
                ]}
                height={"calc(100vh - 298px)"}
                view={false}
                edit={props?.Status}
                delete={props?.Status}
                is_lead={true}
                handleIcon={handleIcon}
                noDataSvg
            />

            {props?.Status && (
                <Fab
                    className={language === "ar" ? classes.fabArabic : classes.fab}
                    size="large"
                    color="primary"
                    onClick={() => setDialog(true)}
                >
                    <SpeedDialIcon />
                </Fab>
            )}

            <React.Fragment key={'right'}>
                <Drawer
                    anchor={'right'}
                    open={dialog}
                    onClose={() => closeDrawer('close')}
                >
                    <Box className={classes.formRoot}>
                        <Box className={classes.bottombtn1}>
                            <Typography className={classes.drawerTitle}>{!!state?.detail?.id ? "Edit" : "Create"} 3rd Party Portal Inventory</Typography>
                            <Box display={'flex'} alignItems={'center'} style={{ cursor: 'pointer' }} onClick={() => {
                                closeDrawer('close')
                            }}><CloseIconComponent /></Box>
                        </Box>
                        {
                            state?.loading ?
                                <Box sx={{ height: `calc(100vh - 125px)`, overflow: 'auto' }}
                                >
                                    <LoadingSection message="Fetching Details ..." top="30vh" />
                                </Box>
                                :
                                <Box style={{ height: `calc(100vh - 126px)`, overflow: 'auto' }} p={2}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormGenerator t={t}
                                                spacing={1.5}
                                                components={[
                                                    {
                                                        size: {
                                                            xs: 6,
                                                            sm: 6,
                                                            md: 6,
                                                            lg: 6,
                                                        },
                                                        isActive: true,
                                                        component: "text",
                                                        label: t("Listing Portal"),
                                                        value: state?.listing,
                                                        placeholder: t("Listing Portal"),
                                                        onChange: (value) => updateState("listing", value.target.value),
                                                        error: state?.error?.listing,

                                                        fontSize: '14px !important'
                                                    },
                                                    {
                                                        size: {
                                                            xs: 6,
                                                            sm: 6,
                                                            md: 6,
                                                            lg: 6,
                                                        },
                                                        isActive: true,
                                                        component: "text",
                                                        label: t("Portal Reference"),
                                                        value: state?.reference,
                                                        placeholder: t("Portal Reference"),
                                                        onChange: (value) => updateState("reference", value.target.value),
                                                        error: state?.error?.reference,

                                                        fontSize: '14px !important'
                                                    },
                                                    {
                                                        size: {
                                                            xs: 6,
                                                            sm: 6,
                                                            md: 6,
                                                            lg: 6,
                                                        },
                                                        isActive: true,
                                                        component: "text",
                                                        label: t("Property Name"),
                                                        value: state?.property_name,
                                                        placeholder: t("Property Name"),
                                                        onChange: (value) => updateState("property_name", value.target.value),
                                                        error: state?.error?.property_name,

                                                        fontSize: '14px !important',
                                                        isRequired:true
                                                    },
                                                    {
                                                        size: {
                                                            xs: 6,
                                                            sm: 6,
                                                            md: 6,
                                                            lg: 6,
                                                        },
                                                        isActive: true,
                                                        component: "text",
                                                        label: t("Unit Name"),
                                                        value: state?.unit_name,
                                                        placeholder: t("Unit Name"),
                                                        onChange: (value) => updateState("unit_name", value.target.value),
                                                        error: state?.error?.unit_name,

                                                        fontSize: '14px !important',
                                                        isRequired:true
                                                    },

                                                    {
                                                        size: {
                                                            xs: 12,
                                                            sm: 12,
                                                            md: 6,
                                                            lg: 6
                                                        },
                                                        isActive: true,
                                                        component: "select",
                                                        label: t("Unit Type"),
                                                        placeholder: t("Select Unit Type"),
                                                        value: state?.type,

                                                        onChange: (value) => updateState("type", value),
                                                        error: state?.error?.type,
                                                        loadOptions: (search, array, handleLoading) =>
                                                            loadOptionsApis(
                                                                "queries/unit_type",
                                                                {
                                                                    company_id: JSON.parse(localStorage.getItem(LocalStorageKeys?.selectedCompany))?.value
                                                                },
                                                                search,
                                                                array,
                                                                handleLoading,
                                                                "data",
                                                                {},
                                                            ),
                                                        debounceTimeout: 800,
                                                        isPaginate: true,
                                                        menuOptionHeight: "150px",
                                                        menuPlacement: "bottom"

                                                    },
                                                    {
                                                        size: {
                                                            xs: 6,
                                                            sm: 6,
                                                            md: 6,
                                                            lg: 6,
                                                        },
                                                        isActive: true,
                                                        component: "text",
                                                        label: t("Price"),
                                                        value: state?.amount,
                                                        placeholder: t("Price"),
                                                        onChange: (value) => updateState("amount", value.target.value),
                                                        error: state?.error?.amount,

                                                        fontSize: '14px !important',
                                                        type: "number",
                                                        endAdornment: JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))?.currency_symbol
                                                    },

                                                    {
                                                        size: {
                                                            xs: 6,
                                                            sm: 6,
                                                            md: 6,
                                                            lg: 6
                                                        },
                                                        isActive: true,
                                                        component: "select",
                                                        label: t("Period"),
                                                        placeholder: t("Select Period"),
                                                        value: state?.Period,
                                                        onChange: (value) => updateState("Period", value),
                                                        error: state?.error?.Period,
                                                        options: enumValue?.payment_value_type,
                                                        menuOptionHeight: "150px",
                                                        menuPlacement: "bottom"

                                                    },
                                                    {
                                                        size: {
                                                            xs: 6,
                                                            sm: 6,
                                                            md: 6,
                                                            lg: 6,
                                                        },
                                                        isActive: true,
                                                        component: "text",
                                                        label: t("URL"),
                                                        value: state?.url,
                                                        placeholder: t("URL"),
                                                        onChange: (value) => updateState("url", value.target.value),
                                                        error: state?.error?.url,

                                                        fontSize: '14px !important'
                                                    },
                                                    {
                                                        size: {
                                                            xs: 6,
                                                            sm: 6,
                                                            md: 6,
                                                            lg: 6,
                                                        },
                                                        isActive: true,
                                                        component: "text",
                                                        label: t("Agent Name"),
                                                        value: state?.agent_name,
                                                        placeholder: t("Agent Name"),
                                                        onChange: (value) => updateState("agent_name", value.target.value),
                                                        error: state?.error?.agent_name,

                                                        fontSize: '14px !important'
                                                    },
                                                    {
                                                        size: {
                                                            xs: 6,
                                                            sm: 6,
                                                            md: 6,
                                                            lg: 6,
                                                        },
                                                        isActive: true,
                                                        component: "text",
                                                        label: t("Owner Name"),
                                                        value: state?.owner_name,
                                                        placeholder: t("Owner Name"),
                                                        onChange: (value) => updateState("owner_name", value.target.value),
                                                        error: state?.error?.owner_name,

                                                        fontSize: '14px !important'
                                                    },
                                                ]} />
                                        </Grid>
                                    </Grid >
                                </Box >
                        }

                    </Box >




                    {/* end */}
                    {/* button */}
                    <Box className={classes.bottombtn}>
                        <Button
                            className={classes.next}
                            disabled={disableButton}
                            onClick={() => save()}
                        >
                            {!!state?.detail?.id ? t("Save") : t("Create Lead")}
                        </Button>
                    </Box>
                </Drawer >
            </React.Fragment >

        </Box>
    )
}