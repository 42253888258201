import * as React from "react"

const InspectionTemplateImg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 98459"
    width={32}
    height={32}
    {...props}
  >
    <g data-name="Group 98363">
      <path
        data-name="icons8-plus-math (1)"
        d="M6 3.023 3.589 2.41 3.014 0h-.059l-.544 2.411L0 3.023V3.1l2.411.491L2.95 6h.068l.571-2.411L6 3.1ZM32 29.52l-2.009-.511-.48-2.009h-.048l-.454 2.009L27 29.52v.063l2.009.409.449 2.008h.057l.476-2.009L32 29.587Z"
        fill="#cbbab7"
      />
      <g transform="translate(-1 -1.02)">
        <path
          data-name="Path 93477"
          d="M28 6.783v10.684a.775.775 0 0 1-.778.763h-8.218a.775.775 0 0 1-.782-.763V6.783a.775.775 0 0 1 .782-.763h8.218a.775.775 0 0 1 .778.763Z"
          fill="#714b44"
        />
        <rect
          data-name="Rectangle 52132"
          width={7.333}
          height={1.222}
          rx={0.611}
          transform="translate(19.444 7.841)"
          fill="#bea9a6"
        />
        <rect
          data-name="Rectangle 52133"
          width={7.333}
          height={1.222}
          rx={0.611}
          transform="translate(19.444 10.286)"
          fill="#bea9a6"
        />
        <rect
          data-name="Rectangle 52134"
          width={7.333}
          height={1.222}
          rx={0.611}
          transform="translate(19.444 12.73)"
          fill="#bea9a6"
        />
        <rect
          data-name="Rectangle 52135"
          width={7.333}
          height={1.222}
          rx={0.611}
          transform="translate(19.444 15.174)"
          fill="#bea9a6"
        />
        <path
          data-name="Path 93478"
          d="M21.889 11.661v10.694a.775.775 0 0 1-.782.764h-8.214a.776.776 0 0 1-.782-.764V11.661a.776.776 0 0 1 .782-.764h8.218a.776.776 0 0 1 .782.764Z"
          fill="#a48782"
        />
        <path
          data-name="Path 93479"
          d="M15.778 16.55v10.694a.776.776 0 0 1-.782.764H6.782A.776.776 0 0 1 6 27.244V16.55a.776.776 0 0 1 .782-.764H15a.776.776 0 0 1 .782.764Z"
          fill="#bea9a6"
        />
        <g
          data-name="Group 98391"
          transform="translate(7.222 17.619)"
          fill="#a48782"
        >
          <rect
            data-name="Rectangle 52137"
            width={7.333}
            height={1.222}
            rx={0.611}
          />
          <rect
            data-name="Rectangle 52138"
            width={7.333}
            height={1.222}
            rx={0.611}
            transform="translate(0 2.444)"
          />
          <rect
            data-name="Rectangle 52139"
            width={7.333}
            height={1.222}
            rx={0.611}
            transform="translate(0 4.889)"
          />
          <rect
            data-name="Rectangle 52140"
            width={7.333}
            height={1.222}
            rx={0.611}
            transform="translate(0 7.333)"
          />
        </g>
        <g data-name="Group 98392">
          <path
            data-name="Path 93480"
            d="M15.167 15.174h-3.056v.611h3.056a.613.613 0 0 1 .611.611v6.722h.611v-6.722a1.226 1.226 0 0 0-1.222-1.222Z"
            opacity={0.05}
          />
          <path
            data-name="Path 93481"
            d="M15.167 15.48h-3.056v.306h3.056a.613.613 0 0 1 .611.611v6.722h.306v-6.722a.915.915 0 0 0-.917-.917Z"
            opacity={0.07}
          />
        </g>
        <g data-name="Group 98393">
          <path
            data-name="Path 93482"
            d="M21.278 10.286h-3.056v.611h3.056a.613.613 0 0 1 .611.611v6.722h.611v-6.722a1.226 1.226 0 0 0-1.222-1.222Z"
            opacity={0.05}
          />
          <path
            data-name="Path 93483"
            d="M21.278 10.592h-3.056v.306h3.056a.613.613 0 0 1 .611.611v6.722h.306v-6.722a.915.915 0 0 0-.917-.917Z"
            opacity={0.07}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default InspectionTemplateImg
