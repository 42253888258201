import makeStyles from "@mui/styles/makeStyles";
import { Bold, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        order: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        margin: "16px"
    },
    detailsContainer: {
        borderLeft: "1px solid #E4E8EE",
        borderInlineStart: "1px solid #E4E8EE",
        height: `calc(100vh - 150px)`,
        overflow: "auto"
    },
    btn: {
        padding: "8px 12px",
        boxShadow: "none",
        height: '40px'
    },
    title: {
        fontSize:"1rem",
        color: "#4E5A6B",
        fontFamily: Bold,
        marginBottom: "8px"

    },
    card_root: {
        padding: "12px",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F5F7FA",
        cursor: "pointer"
    },
    card_title: {
        fontSize:"0.875rem",
        color: theme.typography.color.secondary,
        fontFamily: SemiBold,
    },
    card_total: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
        marginInlineStart: "8px"
    },
    filterBox:{
        border: `1.5px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        padding: "4px"
    }
}))