import { Dialog, IconButton, Stack, Typography } from "@mui/material"
import { useStyles } from "./style"
import CloseIcon from '@mui/icons-material/Close';

export const ConfirmDialog = ({
    isDialogOpen = false,
    setIsDialogOpen = () => false,
    isButtonDisable = false,
    question = "",
    yes_text = "",
    no_text = "",
    yes_action = () => false,
    no_action = () => false,
}) => {

    const classes = useStyles()

    return <Dialog
        className={classes.dialog_root}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}>
        <div className={classes.dialog_component}>
            <div className={classes.dialog_header}>
                <IconButton onClick={() => setIsDialogOpen(false)}
                    className={classes.dialog_close_button}>
                    <CloseIcon htmlColor="#FFFFFF" height="14px" width="14px" />
                </IconButton>
            </div>
            <div className={classes.dialog_body}>
                <Stack spacing={"40px"} width={"300px"}>
                    <Typography className={classes.question_text}>
                        {question}
                    </Typography>
                    <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
                        <div className={classes.yes_button}
                            onClick={() => { !isButtonDisable && yes_action() }}>
                            <Typography className={classes.yes_button_text}>
                                {yes_text}
                            </Typography>
                        </div>
                        <div className={classes.no_button}
                            onClick={() => { !isButtonDisable && no_action() }}>
                            <Typography className={classes.no_button_text}>
                                {no_text}
                            </Typography>
                        </div>
                    </Stack>
                </Stack>
            </div>
        </div>
    </Dialog>
}