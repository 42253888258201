import React from "react";
import { withNavBars } from "../../HOCs";
import CreateUnit from "./createUnit";

class CreateUnitParent extends React.Component {
    render() {
        return <CreateUnit />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CreateUnitParent, props);
