import * as React from "react"
const FromAppIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.048}
        x2={0.509}
        y1={250.588}
        y2={250.127}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#297cd2" />
      </linearGradient>
    </defs>
    <g data-name="Group 113355">
      <path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 57485" />
      <path
        fill="#50e6ff"
        d="M25.474 10.947v15.79A1.263 1.263 0 0 1 24.211 28H7.79a1.263 1.263 0 0 1-1.263-1.263V5.263A1.263 1.263 0 0 1 7.79 4h10.737l1.895 5.053Z"
        data-name="Path 100310"
      />
      <path
        fill="url(#a)"
        d="M28 5v5.684a1.263 1.263 0 0 0 1.263 1.263h5.684Z"
        data-name="Path 100311"
        transform="translate(-9.473 -1)"
      />
      <path
        fill="#057093"
        d="m14.417 23.434-3.954-3.954a.494.494 0 0 1 0-.7l.7-.7a.494.494 0 0 1 .7 0l2.906 2.906 5.374-5.374a.494.494 0 0 1 .7 0l.7.7a.494.494 0 0 1 0 .7l-6.426 6.422a.5.5 0 0 1-.7 0Z"
        data-name="Path 100312"
      />
    </g>
  </svg>
)
export default FromAppIcon
