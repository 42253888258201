import { Box, Grid, Stack, Typography } from "@mui/material";
import { SummaryUseStyles } from "./style"
import { useWindowDimensions } from "../../../utils";
import { constructDetailJSON } from "../utils";

export const SummaryView = ({
    t = () => false,
    data = {},
}) => {
    const size = useWindowDimensions();
    const classes = SummaryUseStyles({ size });

    const sections = constructDetailJSON({ t, data });

    return (
        <Stack className={classes.root} direction={"column"} rowGap={"16px"}>
            {sections?.map?.((section) => {
                return (
                    <Box className={classes.section}>
                        <Grid container direction={"row"} spacing={"8px"}>
                            {section?.map?.((detail) => {
                                const { xs, sm, md, lg, xl } = detail?.layout;

                                return (
                                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                        {detail?.is_header
                                            ? <Typography className={classes.header}>
                                                {t(detail?.label)}
                                            </Typography>
                                            : <Stack direction={"column"} rowGap={"4px"}>
                                                <Typography className={classes.title}>
                                                    {t(detail?.label)}
                                                </Typography>
                                                <Typography className={classes.description}>
                                                    {detail?.value ?? "-"}
                                                </Typography>
                                            </Stack>
                                        }
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                );
            })}
        </Stack>
    )
}