import * as React from "react"
export const AgreementSend = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    data-name="icons8-agreement (2)"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          fill="none"
          d="M0 22V0h22v22Zm13.438-3.667a3.521 3.521 0 1 0 3.521-3.521 3.514 3.514 0 0 0-3.521 3.521Z"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" data-name="Group 118729">
      <g data-name="Group 118728">
        <path
          fill="#5ac782"
          d="M5.729 1.833A2.066 2.066 0 0 0 3.667 3.9v14.2a2.066 2.066 0 0 0 2.063 2.063h10.541a2.066 2.066 0 0 0 2.062-2.063V9.167h-5.271A2.066 2.066 0 0 1 11 7.1V1.833Zm6.646.4V7.1a.688.688 0 0 0 .688.688h4.867ZM8.937 11A2.04 2.04 0 0 1 11 13.287 4.649 4.649 0 0 1 9.951 16a1.824 1.824 0 0 0 .362.037c.376 0 .518-.174.807-.765.206-.426.523-1.068 1.256-1.068.848 0 1.041.816 1.137 1.21.142.605.2.623.467.623a.692.692 0 0 0 .238-.577.687.687 0 0 1 1.357-.22 2.062 2.062 0 0 1-.477 1.613 1.465 1.465 0 0 1-1.118.559 1.7 1.7 0 0 1-1.719-1.347 2.129 2.129 0 0 1-1.948 1.347 3 3 0 0 1-1.55-.413 3.459 3.459 0 0 1-1.659.413.688.688 0 0 1 0-1.375 2.118 2.118 0 0 0 .577-.078 4.969 4.969 0 0 1-.807-2.672A2.041 2.041 0 0 1 8.937 11Zm0 1.375c-.188 0-.687 0-.687.917a3.532 3.532 0 0 0 .586 1.916 3.358 3.358 0 0 0 .789-1.92c0-.912-.5-.912-.687-.912Z"
          data-name="Path 102005"
        />
      </g>
    </g>
    <g data-name="Group 118730">
      <path
        fill="#5ac782"
        d="M16.958 15.354a2.979 2.979 0 1 0 2.979 2.979 2.979 2.979 0 0 0-2.979-2.979Zm1.821 2.087-2.171 2.167a.271.271 0 0 1-.383 0l-1.083-1.083a.271.271 0 0 1 .383-.383l.892.892 1.975-1.98a.271.271 0 0 1 .383.383Z"
        data-name="Path 102006"
      />
    </g>
  </svg>
)

