import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment';
import { MonthlyCalendarStyles, eventListStyle } from './style';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { AlertDialog, LoadingSection } from '../../../components';
import { SwitchColors } from './weeklyCalendar';
import { ViewActivity } from './viewMore';
import { activityLogo } from '../utils';

export const MonthlyCalendar = ({ dates = {},
    month = {}, data = [],
    openDeclinePopup = () => false, loading = false,
    handleSelected = () => false, t }) => {
    const classes = MonthlyCalendarStyles()
    const [viewMore, setViewMore] = React.useState(false)
    const [selected, setSelected] = React.useState({})
    const days = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",

    ]
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: "100%",
            border: '1px solid #dadde9',
        },
    }));

    // const handleClick = (e) => {
    //     handleSelected(e)
    // }

    return (
        <Box>
            <Grid container>
                {
                    days.map(e => {
                        return (
                            <Grid item xs={1.7} textAlign={"center"} p={1}>
                                <span className={classes.days}> {e} </span>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container mt={0} className={classes.calDates}>
                <Grid item md={12} lg={12}>
                    <Grid container>
                        {
                            loading ?
                                <Grid item xs={12}>
                                    <LoadingSection />
                                </Grid>
                                :
                                dates.map(date => {
                                    return (
                                        <Grid item xs={1.7} className={classes.date_outline}>
                                            <Stack mt={1} justifyContent={"space-between"} height="100%" pb={2}>
                                                <Box className={classes.date}><span className={moment(date).format("M") === moment(month).format("M")
                                                    ? " " : classes.hidden_other_month_dates}>{moment(date).format("DD")}</span></Box>
                                                <Box sx={{ textAlign: "right" }}>

                                                    <Box m={1}>
                                                        {
                                                            data?.map(evnt => {
                                                                return (
                                                                    evnt?.calendarData?.map(x => {
                                                                        let data = [
                                                                            {
                                                                                name: "Phone call",
                                                                                count: x?.activity?.filter((i) => i?.activity_name === "Phone call")?.length
                                                                            },
                                                                            {
                                                                                name: "Follow up",
                                                                                count: x?.activity?.filter((i) => i?.activity_name === "Follow up")?.length
                                                                            },
                                                                            {
                                                                                name: "Meeting",
                                                                                count: x?.activity?.filter((i) => i?.activity_name === "Meeting")?.length
                                                                            },
                                                                            {
                                                                                name: "Appointment",
                                                                                count: x?.activity?.filter((i) => i?.activity_name === "Appointment")?.length
                                                                            },
                                                                            {
                                                                                name: "Arrange Site Visit",
                                                                                count: x?.activity?.filter((i) => i?.activity_name === "Arrange Site Visit")?.length
                                                                            },
                                                                            {
                                                                                name: "Send Quotation",
                                                                                count: x?.activity?.filter((i) => i?.activity_name === "Send Quotation")?.length
                                                                            },
                                                                            {
                                                                                name: "Send Email",
                                                                                count: x?.activity?.filter((i) => i?.activity_name === "Send Email")?.length
                                                                            }
                                                                        ]
                                                                        return (

                                                                            moment(date).format("DD") === moment(evnt?.date).format("DD")
                                                                                && moment(evnt?.date).format("MM") === moment(date).format("MM") ?
                                                                                // x?.activity?.map((val, i) => {
                                                                                //     return (
                                                                                //         <>
                                                                                //             {
                                                                                //                 x?.activity?.length === 1 ?
                                                                                //                     <HtmlTooltip
                                                                                //                         title={
                                                                                //                             <React.Fragment>
                                                                                //                                 <CustomToolTip data={val} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} />
                                                                                //                             </React.Fragment>
                                                                                //                         }
                                                                                //                         arrow className={classes.customTooltip}
                                                                                //                     >
                                                                                //                         <Stack direction={"row"} spacing={1} alignItems={"center"}
                                                                                //                             sx={{
                                                                                //                                 backgroundColor: SwitchColors(val?.activity_name),

                                                                                //                             }} p={0.7} onClick={() => setSelected(val)}>
                                                                                //                             <img src={SwitchActivityIcons(val?.activity_name)} alt="icon" />
                                                                                //                             <Typography className={classes.event_label}> {val?.activity_name} , {val?.first_name} </Typography>
                                                                                //                         </Stack>
                                                                                //                     </HtmlTooltip>
                                                                                //                     :
                                                                                //                     i === 0 &&

                                                                                //                     <>

                                                                                //                         <HtmlTooltip
                                                                                //                             title={
                                                                                //                                 <React.Fragment>
                                                                                //                                     <CustomToolTip data={x?.activity?.[0]} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} />
                                                                                //                                 </React.Fragment>
                                                                                //                             }
                                                                                //                             arrow className={classes.customTooltip}
                                                                                //                         >

                                                                                //                             <>
                                                                                //                                 <Stack direction={"row"} spacing={1} alignItems={"center"}
                                                                                //                                     sx={{
                                                                                //                                         backgroundColor: SwitchColors(x?.activity?.[0]?.activity_name),

                                                                                //                                     }} p={0.7}>
                                                                                //                                     <img src={SwitchActivityIcons(x?.activity?.[0]?.activity_name)} alt="icon" />
                                                                                //                                     <Typography className={classes.event_label}> {x?.activity?.[0]?.activity_name}, {x?.activity?.[0]?.first_name}  </Typography>
                                                                                //                                 </Stack>
                                                                                //                             </>

                                                                                //                         </HtmlTooltip>

                                                                                //                         <Typography className={classes.moreLabel} onClick={() => handleClick(e)} > +{x?.activity?.length - 1} more </Typography>
                                                                                //                     </>

                                                                                //             }
                                                                                //         </>
                                                                                //     )
                                                                                // })
                                                                                <Stack spacing={1}>
                                                                                    {
                                                                                        data?.map((e) => {
                                                                                            return (
                                                                                                e?.count !== 0 &&
                                                                                                <HtmlTooltip
                                                                                                    title={
                                                                                                        // x?.parking_master_parking_type === "Reserved" ?
                                                                                                        <React.Fragment>
                                                                                                            <CustomToolTip t={t} data={e} openDeclinePopup={openDeclinePopup} setViewMore={setViewMore} setSelected={setSelected} handleSelected={handleSelected} date={date} />
                                                                                                        </React.Fragment>
                                                                                                        // : ""
                                                                                                    }
                                                                                                    arrow className={classes.customTooltip}
                                                                                                >
                                                                                                    <Stack direction={"row"} spacing={1} alignItems={"center"}
                                                                                                        sx={{
                                                                                                            backgroundColor: SwitchColors(e?.name),

                                                                                                        }} p={0.7} onClick={() => handleSelected(date)}>
                                                                                                        <Box sx={{ marginTop: 0.5, marginLeft: 0.2 }}>
                                                                                                            {activityLogo?.[e?.name]}
                                                                                                        </Box>
                                                                                                        <Typography className={classes.event_label}> {`${e?.name} ${e?.count > 1 ? "+" : ""}${e?.count === 1 ? "" : e?.count - 1}`} </Typography>
                                                                                                    </Stack>
                                                                                                </HtmlTooltip>

                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Stack>
                                                                                : ""
                                                                        )
                                                                    })

                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    )
                                })
                        }
                    </Grid>
                </Grid>
            </Grid>
            <AlertDialog
                md
                open={viewMore}
                onClose={() => setViewMore(false)}
                header={t("Activity")}
                component={<ViewActivity data={selected} handleClose={() => setViewMore(false)} />}

            />
        </Box>
    )
}
const CustomToolTip = ({ data = {}, handleSelected = () => false, date = "", t }) => {
    const classes = eventListStyle()
    return (
        <Stack p={1}>
            <Stack direction={"column"} spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                    {/* <img src={SwitchActivityIcons(data?.name)} width="24px" height="24px" alt="icon" /> */}
                    {activityLogo?.[data?.name]}
                    <Typography className={classes.ttTitle}> {data?.name} </Typography>
                </Stack>
                {/* <Stack pl={3.5}>
                    <Typography className={classes.ttsecTitle}> {moment.utc(data?.date).format("DD-MM-YYYY hh:mm A")} </Typography>
                </Stack>
                <Stack pl={3.5}>
                    <Typography className={classes.ttassigned_by}>Created By : {data?.first_name}, {`${data?.mobile_no_country_code ?? ""} ${data?.mobile_no ?? ""}`} </Typography>
                </Stack> */}
                <Stack pl={3.5}>
                    <Typography className={classes.moreLabel} onClick={() => {
                        handleSelected(date, data?.name)
                    }}> {t("View All")}</Typography>
                </Stack>
            </Stack>

        </Stack>
    )
}