export const ListingsMapping = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-1{fill:#c1c5cb}"}</style>
        </defs>
        <g
            id="Group_102125"
            data-name="Group 102125"
            transform="translate(24726 -3966)"
        >
            <g
                id="Listing_Master"
                data-name="Listing Master"
                transform="translate(-24719 3923)"
            >
                <path
                    id="Path_96826"
                    data-name="Path 96826"
                    className="cls-1"
                    d="M4.414 52H1.086A1.087 1.087 0 0 0 0 53.086v3.328A1.087 1.087 0 0 0 1.086 57.5h3.328A1.087 1.087 0 0 0 5.5 56.414v-3.328A1.087 1.087 0 0 0 4.414 52Z"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96827"
                    data-name="Path 96827"
                    className="cls-1"
                    d="M4.414 284H1.086A1.087 1.087 0 0 0 0 285.086v3.328a1.087 1.087 0 0 0 1.086 1.086h3.328a1.087 1.087 0 0 0 1.086-1.086v-3.328A1.087 1.087 0 0 0 4.414 284Z"
                    transform="translate(0 -224.75)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96828"
                    data-name="Path 96828"
                    className="cls-1"
                    d="M232.5 101h-8a.5.5 0 0 1 0-1h8a.5.5 0 0 1 0 1Z"
                    transform="translate(-217 -46.5)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96829"
                    data-name="Path 96829"
                    className="cls-1"
                    d="M229.75 161h-5.25a.5.5 0 0 1 0-1h5.25a.5.5 0 0 1 0 1Z"
                    transform="translate(-217 -104.625)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96830"
                    data-name="Path 96830"
                    className="cls-1"
                    d="M232.5 329h-8a.5.5 0 0 1 0-1h8a.5.5 0 0 1 0 1Z"
                    transform="translate(-217 -267.375)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96831"
                    data-name="Path 96831"
                    className="cls-1"
                    d="M229.75 393h-5.25a.5.5 0 0 1 0-1h5.25a.5.5 0 0 1 0 1Z"
                    transform="translate(-217 -329.375)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24726 3966)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
        </g>
    </svg>
)