import { Box, Stack, Typography, useMediaQuery } from "@mui/material"
import React from "react"
import AllUnitsIcon from "../../../assets/propertyBoard/allUnits"
import BlockUnitIcon from "../../../assets/propertyBoard/blockUnit"
import VacantUnitIcon from "../../../assets/propertyBoard/vacantUnit"
import { useStyles } from "./style"


export const StatusTab = ({t=()=>false,count = "", handleStatus = () => false }) => {
    const matches = useMediaQuery('(max-width:600px)');
    const status = [
        {
            icon: <AllUnitsIcon />,
            name: t("All units"),
            value: null,
            label: "All",
            count: count?.total ?? 0
        },
        {
            icon: <VacantUnitIcon />,
            name: t("Vacant"),
            value: "vacant",
            label: "Vacant",
            count: count?.vacant ?? 0
        },
        {
            icon: <BlockUnitIcon />,
            name: t("Blocked"),
            value: "blocked",
            label: "Blocked",
            count: count?.blocked ?? 0
        },
    ]
    const classes = useStyles()
    return (
        <Stack direction={matches ? "column" : "row"} alignItems={matches ? "start" : "center"} >
            {status?.map((val, i) => {
                return (
                    <Box className={classes.tabBorder} onClick={() => handleStatus(val)} sx={{ marginInlineEnd: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            {val.name !== "Blocked" ? val?.icon :
                                <Box className={classes.blockedicon}>{val?.icon}</Box>
                            }
                            <Box marginInlineStart={"8px"} >

                                <Typography className={classes.countALL}>{val?.count}</Typography>
                                <Typography className={classes.statusLabel}>{val?.name}</Typography>
                            </Box>
                        </Stack>
                    </Box >
                )
            })}

        </Stack >
    )
}