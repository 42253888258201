import { Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { PropertyUnitDetailsStyle } from './style'

export const CustomTextBox = ({
    value = {},
    type = {},
    setRowData = () => false,
    disable = false,
    e = "",
    item = {},
    selectedCompany = {}
}) => {
    const classes = PropertyUnitDetailsStyle()
    const [hide, setHide] = React.useState(false)
    return (
        <>
            {
                (!hide && !disable) ?
                    <TextField
                        value={value}
                        color="primary" 
                        size="small"
                        focused
                        disabled={disable}
                        className={classes.customText}
                        type={type}
                        onBlur={(val) => {
                            if (e === "discount" || e === "amount") {
                                val?.target?.value?.length === 0 ?
                                    setRowData(0, item, e === "discount" ? "discount" : "amount")
                                    : setRowData(val?.target?.value, item, e)
                            }
                            else if (e === "qty") {
                                val?.target?.value?.length === 0 ?
                                    setRowData(1, item, "qty")
                                    : setRowData(val?.target?.value, item, e)
                            }
                            else {
                                setRowData(val?.target?.value, item, e)
                            }
                            if (val?.target?.value?.length > 0) {
                                setHide(true)
                            }
                        }}
                        autoComplete={false}
                        onChange={(val) => {
                            setRowData(val?.target?.value, item, e)
                        }}
                        InputProps={{
                            endAdornment: (
                                e === "amount" ? <Typography>{selectedCompany?.currency_symbol ?? '-'}</Typography> : <></>
                            )
                        }}
                    /> :
                    <Stack direction={"row"}>
                        <Typography noWrap className={classes.textBoxTypography} onClick={() => setHide(false)}>
                            {(value).toLocaleString('en-IN')}
                        </Typography>
                        {e === "amount" && <span style={{ float: "right", marginRight: 4 }}>{selectedCompany?.currency_symbol}</span>}
                    </Stack>
            }
        </>
    )
}