import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import {
    Grid,
    IconButton, Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { Upload2 } from "./fileupload";



const useStyles = makeStyles((theme) => ({
    rootContainer: {
        position: "relative",
        minHeight: "100%",
        // backgroundColor: theme.palette.background.secondary,
        margin: 0,
    },
    root: {

    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
    },
    content: {
        display: "inline-flex",
        width: "100%",
    },
    cardContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2),
        boxShadow: "0px 10px 15px #00000014",
        border: "2px solid #E2E2E2",
        borderRadius: theme.palette.borderRadius,
    },
    Container: {
        marginBottom: theme.spacing(2),

    },
    circularLoader: {
        paddingRight: "8px",
        position: "relative",
        "& .MuiCircularProgress-root": {
            position: "relative",
        },
    },
    loadCenter: {
        position: "absolute",
        left: 14,
    },
    uploadText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    errorText: {
        color: 'red',
        fontSize: '0.75rem'
    }
}));

export const UploadReports2 = (props) => {
    const classes = useStyles(props);
    const [uploaddocc, setuploaddocc] = React.useState(props?.value ? props?.value : []);

    const handleUpload = async (e) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file)
            );
            setuploaddocc((prevImages) => prevImages.concat(filesArray));
            Array.from(e.target.files).map(
                (file) => URL.revokeObjectURL(file) // avoid memory leak
            );
        }


    };
    const handleDelete = (i) => {
        uploaddocc.splice(i, 1);
        setuploaddocc([...uploaddocc]);
        props.onChange([...uploaddocc]);
    };

    const getLabel = (props) => {
        return <Typography variant="body1" style={{ fontSize: "0.75rem", color: "textsecondary" }} gutterBottom >{props?.label} {props?.isrequired && <Typography variant="caption" style={{ color: "red", marginLeft: "2px" }}>*</Typography>}</Typography>
    }


    return (
        <div className={classes.rootContainer}>

            <div className={classes.root} noValidate>
                <Typography variant="body1" color={"textPrimary"}>
                    {getLabel(props)}
                </Typography>
                <Grid container>
                    {!props?.isReadonly &&
                        <Grid item xs={3}>
                            <Upload2 onChange={handleUpload} single={props?.single} state={uploaddocc} />
                        </Grid>}

                    <Grid item xs={9}>

                        {uploaddocc?.length > 0 &&
                            <div>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    className={classes.Container}
                                    spacing={2}
                                >

                                    {/* <Grid item xs={12}>
                                        <Box display="flex">
                                            <Box flexGrow={1}>
                                                <Typography
                                                    style={{
                                                        color: "#404E61",
                                                        fontSize:"1rem",
                                                        fontFamily:  SemiBold
                                                    }}>
                                                    Uploaded {uploaddocc?.length} images
                                                </Typography>

                                            </Box>
                                            <Box>
                                                <Typography
                                                    onClick={() => onRemoveAll()}
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "#5078E1",
                                                        fontSize:"0.875rem",
                                                        fontFamily:  SemiBold
                                                    }}
                                                >Remove All
                                                </Typography>
                                            </Box>
                                        </Box>


                                    </Grid> */}
                                    {
                                        uploaddocc?.map((item) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{ position: "relative", textAlign: "center" }}
                                                >
                                                    <img src={item} alt="upload" style={{ width: "100%", height: "159px", borderRadius: 10, }}></img>
                                                    {!props?.isReadonly &&


                                                        <IconButton
                                                            style={{ position: "absolute", top: "8px", right: "-8px", }}
                                                            onClick={() => handleDelete()}
                                                            aria-label="settings"
                                                            disabled={props?.isReadonly}
                                                        >
                                                            <DeleteForeverOutlined style={{ color: "Red" }} />

                                                        </IconButton>

                                                    }
                                                </Grid>

                                            );
                                        })
                                    }
                                </Grid>
                            </div>}
                    </Grid>
                </Grid>


            </div>
        </div >
    );
};
