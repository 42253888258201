import makeStyles from "@mui/styles/makeStyles";

export const useStylesCreation = makeStyles((theme) => ({
dashboardRoot: {
  padding: "16px 16px 80px",
  backgroundColor: theme.palette.background.secondary,
  height: `calc(100vh - 64px)`,
  overflow: "auto",
  [theme.breakpoints.down("md")]: {
    padding: "10px 10px 80px",
  },
},
}));
