import React from "react";
import {
  LineChart,
  ResponsiveContainer,

  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { SemiBold } from "../../../utils";
import { makeStyles } from "@mui/styles";
import { Box, Stack, Typography } from "@mui/material";


const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: "#fff",
    borderRadius: 8,
    boxShadow: "0px 3px 6px #00000029"
  },
  value1dot: {
    height: 10,
    width: 10,
    borderRadius: 50,
    background: "#686AF3"
  },
  value2dot: {
    height: 10,
    width: 10,
    borderRadius: 50,
    background: "#F15A29"
  },
  toolTip: {
    padding: 4,
    background: "#fff",
    "& .custom-tooltip": {
      border: "none"
    }
  },
  title: {
    fontSize:"0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: SemiBold
  },
  subtitle: {
    fontSize:"0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold
  }
}))

export const LineChartComponent = (props) => {
  const classes = useStyles()

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Stack className={classes.toolTip}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
              <Box className={classes.value1dot}></Box>
              <Typography className={classes.title}>{props?.key1}</Typography>
            </Stack>
            <Typography className={classes.subtitle}>{payload?.[0].value}</Typography>
          </Stack>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
              <Box className={classes.value2dot}></Box>
              <Typography className={classes.title}>{props?.key2}</Typography>
            </Stack>
            <Typography className={classes.subtitle}>{payload?.[1].value}</Typography>
          </Stack>
        </Stack>
      );
    }

    return null;
  };
  return (
    <ResponsiveContainer width={'100%'} height={300}   >
      <LineChart
        height={300}
        data={props?.data}
        margin={{ top: 0, right: 10, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="0" horizontal={true} vertical={false} />
        <XAxis dataKey="name" axisLine={false} style={{
          fontSize:"0.75rem",
          fontFamily: SemiBold,
        }} />
        <YAxis type="number"
          style={{
            fontSize:"0.75rem",
            fontFamily: SemiBold,
          }} />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} contentStyle={{ border: 'none' }} />
        <Line dataKey={props?.key1} type="monotone" stroke="#686AF3" />
        <Line dataKey={props?.key2} type="monotone" stroke="#F15A29" />

      </LineChart>
    </ResponsiveContainer>
  );
};
