import { Box, Grid } from '@mui/material'
import React from 'react'
import GraphComponent from '../propertymanagerdashboard/components/graphcomponent'
import { DialogDrawer, Subheader } from '../../components'
import { AlertContext, AuthContext, BackdropContext } from '../../contexts'
import { config } from '../../config'
import { AlertProps, NetWorkCallMethods, accessCheckRender, getCalendarOptions, getCompanyOption, getRoutePermissionNew } from '../../utils'
import { NetworkCall } from '../../networkcall'
import { CardDetails } from '../propertymanagerdashboard/components'
import { ActivePropertyIcon } from '../../assets'
import { AmenitiesDashboardStyle } from './style'
import { withNamespaces } from 'react-i18next'

const AmenityDashBoard = ({
    loading,
    t = () => false
}) => {
    const alert = React.useContext(AlertContext)
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)

    const classes = AmenitiesDashboardStyle()

    const scale = {
        x: {
            stacked: true,
            grid: {
                // drawBorder: false,
                display: false,
            }
        },
        y: {
            stacked: true,
            grid: {
                // drawBorder: false,
                display: false,
            }
        },
    };

    const [dashboardData, setDashboardData] = React.useState({
        firstCount: [],
        secondCount: []
    })
    const [dashBoardChart, setDashboardChart] = React.useState({
        bookingByRoom: {}
    })
    const [zoom, setZoom] = React.useState(false);
    const [zoomGraph, setZoomGraph] = React.useState("");
    const [state, setState] = React.useState({})
    const [permission, setPermission] = React.useState({});

    const zoomGraphFunction = (e) => {
        setZoomGraph(e);
        setZoom(true);
    };

    const handleCloseModule = () => {
        setZoom(false);
        setZoomGraph("");
    };


    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert)
                if (company) {
                    let calanderOption = getCalendarOptions()
                    setState({
                        ...state,
                        company: company?.list,
                        selectedCompany: company?.selected,
                        calanderOption: calanderOption,
                        selectedCalander: calanderOption[0]
                    })

                    getAmenityDashoardCounts(company?.selected?.value)
                    getAmenityDashoardChart(company?.selected?.value, calanderOption[0]?.value)
                }
            }
        }

        // eslint-disable-next-line
    }, [auth])

    const handleCalendarChange = (date) => {
        getAmenityDashoardChart(state?.selectedCompany?.value, date?.value)
        setState({
            ...state,
            selectedCalander: date
        })
    }

    const handleCompanyChange = (company) => {
        getAmenityDashoardCounts(company?.value)
        getAmenityDashoardChart(company?.value, state?.selectedCalander?.value)
        setState({
            ...state,
            selectedCompany: company
        })
    }

    const getAmenityDashoardCounts = (company_id = state?.selectedCompany?.valueF) => {
        const payload = {
            company_id: company_id
        }
        NetworkCall(
            `${config.api_url}/amenity_dashboard/count`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false)
            .then((res) => {
                const resData = res?.data?.data
                const first_count = [
                    {
                        count: resData?.checked_in_amenities ?? 0,
                        image: <ActivePropertyIcon color="#FF6D65" />,
                        sub: t("Bookings with Checked in"),
                    },
                    {
                        count: resData?.booked_amenities ?? 0,
                        image: <ActivePropertyIcon color="#F49920" />,
                        sub: t("Confirmed"),
                    },
                    {
                        count: resData?.cancelled_unpaid_amenities ?? 0,
                        image: <ActivePropertyIcon />,
                        sub: t("Unpaid/Cancelled Bookings"),
                    },
                    {
                        count: resData?.paid_amenities ?? 0,
                        image: <ActivePropertyIcon color="#F49920" />,
                        sub: t("Paid Bookings"),
                    },
                    {
                        count: resData?.goto_booked_amenities ?? 0,
                        image: <ActivePropertyIcon />,
                        sub: t("Bookings from AmenityGOTO"),
                    },
                ]

                const second_count = [
                    {
                        count: resData?.property_amenities ?? 0,
                        image: <ActivePropertyIcon color="#FF6D65" />,
                        sub: t("Properties with Amenities"),
                    },
                    {
                        count: resData?.unit_amenities ?? 0,
                        image: <ActivePropertyIcon />,
                        sub: t("Units with Amenities"),
                    },
                    {
                        count: resData?.chargeable_amenities ?? 0,
                        image: <ActivePropertyIcon color="#686AF3" />,
                        sub: t("Chargeable Amenities"),
                    },
                    {
                        count: resData?.non_chargeable_amenities ?? 0,
                        image: <ActivePropertyIcon color="#FF6D65" />,
                        sub: t("Non-Chargeable Amenities"),
                    },
                    {
                        count: resData?.peak_hour_facilities ?? 0,
                        image: <ActivePropertyIcon color="#F49920" />,
                        sub: t("Peak Hour Amenities"),
                    },
                    {
                        count: resData?.closed_maintenance_amenities ?? 0,
                        image: <ActivePropertyIcon color="#FF6D65" />,
                        sub: t("Closed/Under Maintnenace"),
                    },
                ]

                const mapped_amenities = resData?.mapped_amenities?.data?.map((data) => {
                    return {
                        name: data?.amenities_name ?? "",
                        fill: `#${Math.random().toString(16).substring(2, 8)}`,
                        count: parseFloat(data?.count) ?? 0
                    }
                })

                setDashboardData({
                    ...dashboardData,
                    firstCount: first_count,
                    secondCount: second_count,
                    mappedAmenities: {
                        data: mapped_amenities,
                        total: resData?.mapped_amenities?.total
                    }
                })
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong
                });
            });
    }

    const getAmenityDashoardChart = (company_id = state?.selectedCompany?.value, date = {}) => {
        const payload = {
            company_id: company_id,
            date_range: date
        }
        NetworkCall(
            `${config.api_url}/amenity_dashboard/chart`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false)
            .then((res) => {
                const resData = res?.data?.data

                const booking_by_room = resData?.amenityBooking?.data?.map((data) => {
                    return {
                        name: data?.amenities_name ?? "",
                        fill: `#${Math.random().toString(16).substring(2, 8)}`,
                        count: parseFloat(data?.count) ?? 0
                    }
                })

                const revenue_by_room = resData?.amenityrevenue?.data?.map((data) => {
                    return {
                        name: data?.amenities_name ?? "",
                        fill: `#${Math.random().toString(16).substring(2, 8)}`,
                        count: parseFloat(data?.sum) ?? 0,
                        prefix: resData?.amenityrevenue?.currency ?? "",
                    }
                })

                let booking_by_status = {
                    labels: resData?.bookingStatus?.map((x) => { return x?.status }),
                    datasets: [
                        {
                            label: '',
                            data: resData?.bookingStatus?.map((x) => { return parseInt(x?.count) }),
                            backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                        },
                    ]
                }

                let booking_by_payment_method = {
                    labels: resData?.amenityPaymentMode?.map((x) => { return x?.payment }),
                    datasets: [
                        {
                            label: '',
                            data: resData?.amenityPaymentMode?.map((x) => { return parseInt(x?.count) }),
                            backgroundColor: `#${Math.random().toString(16).substring(2, 8)}`,
                        },
                    ]
                }

                setDashboardChart({
                    ...dashBoardChart,
                    bookingByRoom: {
                        "total": resData?.amenityBooking?.total,
                        "data": booking_by_room
                    },
                    revenueByRoom: {
                        "tooltip_prefix": resData?.amenityrevenue?.currency ?? "",
                        "total": `${resData?.amenityrevenue?.currency} ${resData?.amenityrevenue?.total}`,
                        "data": revenue_by_room
                    },
                    bookingByStatus: booking_by_status,
                    bookingByPaymentMethod: booking_by_payment_method
                })
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: AlertProps.message.some_thing_went_wrong
                });
            });
    }

    const render = () => {
        return (
            <>
                <Subheader title={t("Amenity Dashboard")}
                    hideBackButton={true}
                    calanderselect
                    calendarOptions={state?.calanderOption}
                    calanderValue={state?.selectedCalander}
                    onChangeCalendar={(e) => handleCalendarChange(e)}
                    select
                    options={state?.company}
                    value={state?.selectedCompany}
                    propertyValue={state?.selectedProperty}
                    onchange={(e) => handleCompanyChange(e)}
                />
                <Box p={2} className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={3} sx={{ display: "flex" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {/* <GraphComponent
                                        t={t}
                                        title={"Utilization shows how many units configured with the Master"}
                                        header={t("Utilization shows how many units configured with the Master")}
                                        graphData={dashboardData?.mappedAmenities?.data ?? []}
                                        isPie
                                        total={dashboardData?.mappedAmenities?.total ?? 0}
                                        isTotal
                                        margin={"44px"}
                                        innerRadius={70}
                                        isZoom
                                        onZoomClick={zoomGraphFunction}
                                        textMargin={'0px 0px 16px'}
                                        divider1={
                                            {
                                                xs: 5,
                                                sm: 5,
                                                md: 12,
                                                lg: 12
                                            }
                                        }
                                        divider={
                                            {
                                                xs: 7,
                                                sm: 7,
                                                md: 12,
                                                lg: 12
                                            }
                                        }
                                        relativeGrid={{
                                            sm: "inherit"
                                        }}
                                        gradiantStyle={{
                                            margin: '10px 0px 0px',
                                            // minHeight: "270px",
                                            // maxHeight: "270px",
                                            display: "flex",
                                            alignItems: "center",
                                            top: {
                                                lg: "auto",
                                                md: 'auto',
                                                sm: "auto",
                                            },
                                            height: {
                                                md: "400px"
                                            },
                                            height1: {
                                                md: "auto !important"
                                            },
                                            bottom: {
                                                md: "16px",
                                                sm: "0px"
                                            },
                                            relative: {
                                                md: "absolute !important"
                                            },
                                        }}
                                    /> */}

                                    <GraphComponent
                                        t={t}
                                        title={"Utilization shows how many units configured with the Master"}
                                        header={t("Utilization shows how many units configured with the Master")}
                                        graphData={dashboardData?.mappedAmenities?.data ?? []}
                                        innerRadius={60}
                                        isTotal={true}
                                        total={dashboardData?.mappedAmenities?.total ?? 0}
                                        isPie
                                        margin={"20px"}
                                        onZoomClick={zoomGraphFunction}
                                        isZoom
                                        textMargin={'0px 0px 16px'}
                                        divider1={
                                            {
                                                xs: 12,
                                                sm: 7.4,
                                                md: 12,
                                                lg: 12
                                            }
                                        }
                                        divider={
                                            {
                                                xs: 12,
                                                sm: 4.6,
                                                md: 12,
                                                lg: 12
                                            }
                                        }
                                        gradiantStyle={{
                                            margin: '0px 0px 0px',
                                            display: "auto",
                                            alignItems: "center",
                                            top: {
                                                lg: "auto",
                                                md: '16px',
                                                sm: "3px",
                                            },
                                            height: {
                                                md: "400px"
                                            }
                                        }}
                                    />
                                </Grid>
                                {
                                    dashboardData?.secondCount?.map((e) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={12} lg={6} sx={{ display: "flex" }}>
                                                <CardDetails data={e} padding={16} />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={8} lg={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {
                                            dashboardData?.firstCount?.map((e) => {
                                                return (
                                                    <Grid item xs={12} sm={6} md={6} lg={2.4}>
                                                        <CardDetails data={e} padding={16} />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} sx={{ display: "flex" }}>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <GraphComponent
                                                t={t}
                                                title={"Booking by Amenities"}
                                                header={t("Booking by Amenities")}
                                                graphData={dashBoardChart?.bookingByRoom?.data ?? []}
                                                innerRadius={60}
                                                isTotal={true}
                                                total={dashBoardChart?.bookingByRoom?.total ?? 0}
                                                isPie
                                                margin={"20px"}
                                                onZoomClick={zoomGraphFunction}
                                                isZoom
                                                textMargin={'0px 0px 16px'}
                                                divider1={
                                                    {
                                                        xs: 12,
                                                        sm: 7.4,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                divider={
                                                    {
                                                        xs: 12,
                                                        sm: 4.6,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                gradiantStyle={{
                                                    margin: '0px 0px 0px',
                                                    display: "auto",
                                                    alignItems: "center",
                                                    top: {
                                                        lg: "auto",
                                                        md: '16px',
                                                        sm: "3px",
                                                    },
                                                    height: {
                                                        md: "400px"
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <GraphComponent
                                                t={t}
                                                title={"Revenue by Amenities"}
                                                header={t("Revenue by Amenities")}
                                                graphData={dashBoardChart?.revenueByRoom?.data ?? []}
                                                innerRadius={60}
                                                isTotal={true}
                                                total={dashBoardChart?.revenueByRoom?.total ?? 0}
                                                isPie
                                                margin={"20px"}
                                                onZoomClick={zoomGraphFunction}
                                                isZoom
                                                textMargin={'0px 0px 16px'}
                                                divider1={
                                                    {
                                                        xs: 12,
                                                        sm: 7.4,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                divider={
                                                    {
                                                        xs: 12,
                                                        sm: 4.6,
                                                        md: 12,
                                                        lg: 12
                                                    }
                                                }
                                                gradiantStyle={{
                                                    margin: '0px 0px 0px',
                                                    display: "auto",
                                                    alignItems: "center",
                                                    top: {
                                                        lg: "auto",
                                                        md: '16px',
                                                        sm: "3px",
                                                    },
                                                    height: {
                                                        md: "400px"
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <GraphComponent
                                                title={"Booking by Status"}
                                                header={t("Booking by Status")}
                                                graphData={dashBoardChart?.bookingByStatus ?? []}
                                                isBar
                                                scale={scale}
                                                gheight={300}
                                                // isLegend
                                                isNoLegend
                                                isZoom
                                                onZoomClick={zoomGraphFunction}
                                                maxWidthGraph={"250px"}
                                                justifyContent={'start'}
                                                gradiantStyle={{
                                                    margin: '15px 0px 0px',
                                                    top: {
                                                        lg: "auto",
                                                        md: '7px',
                                                        sm: "3px",
                                                    },
                                                    height: {
                                                        sm: "63px"
                                                    }
                                                }}
                                                t={t}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <GraphComponent
                                                title={"Booking by Payment Method"}
                                                header={t("Booking by Payment Method")}
                                                graphData={dashBoardChart?.bookingByPaymentMethod ?? []}
                                                isBar
                                                scale={scale}
                                                gheight={300}
                                                // isLegend
                                                isNoLegend
                                                isZoom
                                                onZoomClick={zoomGraphFunction}
                                                maxWidthGraph={"250px"}
                                                justifyContent={'start'}
                                                gradiantStyle={{
                                                    margin: '15px 0px 0px',
                                                    top: {
                                                        lg: "auto",
                                                        md: '7px',
                                                        sm: "3px",
                                                    },
                                                    height: {
                                                        sm: "63px"
                                                    }
                                                }}
                                                t={t}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <DialogDrawer
                        header={t(zoomGraph)}
                        maxWidth={zoomGraph === "Map View" ? "lg" : "md"}
                        handleClose={handleCloseModule}
                        open={zoom}
                        height={zoomGraph === "Map View" ? '700px' : "auto"}
                        borderRadius={"12px"}
                        padding={'0px'}
                        onClose={handleCloseModule}
                        component={
                            <>
                                {zoomGraph === "Utilization shows how many units configured with the Master" && (

                                    <GraphComponent
                                        graphData={dashboardData?.mappedAmenities?.data ?? []}
                                        isPie
                                        innerRadius={100}
                                        isTotal
                                        total={dashboardData?.mappedAmenities?.total ?? 0}
                                        paddingAngle={2}
                                        t={t}
                                        margin={"20px"}
                                        padding={"16px"}
                                        centerTop={"44%"}
                                        maxHeightGraph={"250px"}
                                        minWidth={false}
                                        height={"auto"}
                                        is_popUp
                                        justifyContent={'start'}
                                        divider1={
                                            {
                                                xs: 12,
                                                sm: 5,
                                                md: 5,
                                                lg: 5
                                            }
                                        }
                                        divider={
                                            {
                                                xs: 12,
                                                sm: 7,
                                                md: 7,
                                                lg: 7
                                            }
                                        }

                                        gradiantStyle={{
                                            margin: '0px 0px 0px',
                                            display: "flex",
                                            alignItems: "center",
                                            grid_template: "100%",
                                            maxHeight: "auto",
                                            top: {
                                                lg: "auto",
                                                md: '-8px',
                                                sm: "auto",
                                            },
                                            height: {
                                                md: "auto",
                                                sm: "auto",
                                                lg: "100%"
                                            }
                                        }}

                                    />

                                )}

                                {
                                    zoomGraph === "Booking by Amenities" && (
                                        <GraphComponent
                                            t={t}
                                            graphData={dashBoardChart?.bookingByRoom?.data ?? []}
                                            isPie
                                            total={dashBoardChart?.bookingByRoom?.total ?? 0}
                                            isTotal
                                            innerRadius={100}
                                            paddingAngle={2}
                                            margin={"20px"}
                                            padding={"16px"}
                                            centerTop={"44%"}
                                            maxHeightGraph={"250px"}
                                            minWidth={false}
                                            height={"auto"}
                                            is_popUp
                                            justifyContent={'start'}
                                            divider1={
                                                {
                                                    xs: 12,
                                                    sm: 5,
                                                    md: 5,
                                                    lg: 5
                                                }
                                            }
                                            divider={
                                                {
                                                    xs: 12,
                                                    sm: 7,
                                                    md: 7,
                                                    lg: 7
                                                }
                                            }

                                            gradiantStyle={{
                                                margin: '0px 0px 0px',
                                                display: "flex",
                                                alignItems: "center",
                                                grid_template: "100%",
                                                maxHeight: "auto",
                                                top: {
                                                    lg: "auto",
                                                    md: '-8px',
                                                    sm: "auto",
                                                },
                                                height: {
                                                    md: "auto",
                                                    sm: "auto",
                                                    lg: "100%"
                                                }
                                            }}
                                        />
                                    )
                                }
                                {
                                    zoomGraph === "Revenue by Amenities" && (
                                        <GraphComponent
                                            t={t}
                                            graphData={dashBoardChart?.revenueByRoom?.data ?? []}
                                            isPie
                                            total={dashBoardChart?.revenueByRoom?.total ?? 0}
                                            isTotal
                                            innerRadius={100}
                                            paddingAngle={2}
                                            margin={"20px"}
                                            padding={"16px"}
                                            centerTop={"44%"}
                                            maxHeightGraph={"250px"}
                                            minWidth={false}
                                            height={"auto"}
                                            is_popUp
                                            justifyContent={'start'}
                                            divider1={
                                                {
                                                    xs: 12,
                                                    sm: 5,
                                                    md: 5,
                                                    lg: 5
                                                }
                                            }
                                            divider={
                                                {
                                                    xs: 12,
                                                    sm: 7,
                                                    md: 7,
                                                    lg: 7
                                                }
                                            }

                                            gradiantStyle={{
                                                margin: '0px 0px 0px',
                                                display: "flex",
                                                alignItems: "center",
                                                grid_template: "100%",
                                                maxHeight: "auto",
                                                top: {
                                                    lg: "auto",
                                                    md: '-8px',
                                                    sm: "auto",
                                                },
                                                height: {
                                                    md: "auto",
                                                    sm: "auto",
                                                    lg: "100%"
                                                }
                                            }}
                                        />
                                    )
                                }
                                {
                                    zoomGraph === "Booking by Status" && (
                                        <GraphComponent
                                            graphData={dashBoardChart?.bookingByStatus ?? []}
                                            isBar
                                            scale={scale}
                                            gheight={500}
                                            // isLegend
                                            isNoLegend
                                            maxWidthGraph={"250px"}
                                            justifyContent={'start'}
                                            gradiantStyle={{
                                                margin: '15px 0px 0px',
                                                top: {
                                                    lg: "auto",
                                                    md: '7px',
                                                    sm: "3px",
                                                },
                                                height: {
                                                    sm: "63px"
                                                }
                                            }}
                                            t={t}
                                        />
                                    )
                                }
                                {
                                    zoomGraph === "Booking by Payment Method" && (
                                        <GraphComponent
                                            graphData={dashBoardChart?.bookingByPaymentMethod ?? []}
                                            isBar
                                            scale={scale}
                                            gheight={500}
                                            // isLegend
                                            isNoLegend
                                            maxWidthGraph={"250px"}
                                            justifyContent={'start'}
                                            gradiantStyle={{
                                                margin: '15px 0px 0px',
                                                top: {
                                                    lg: "auto",
                                                    md: '7px',
                                                    sm: "3px",
                                                },
                                                height: {
                                                    sm: "63px"
                                                }
                                            }}
                                            t={t}
                                        />
                                    )
                                }
                            </>
                        }
                    />
                </Box>
            </>
        )
    }

    return <div>{accessCheckRender(render, permission, "", loading)}</div>;
}

export default withNamespaces("amenityDashboard")(AmenityDashBoard)