import makeStyles from "@mui/styles/makeStyles";

export const imageStyles = makeStyles((theme) => ({
  images: {
    width: "32px",
    height: "32px",
    opacity: 0.5,
    cursor: "pointer",
  },

  topImage: {
    position: "relative",
    "& img": {
      height: "420px",
      width: "100%",
      padding: "20px",
      borderRadius: "25px",
    },
  },

  bottomImages: {
    display: "flex",
    justifyContent: "center",
    gap: "6px",
    borderRadius: "4px",
    paddingBottom:20,
    "& img": {
      borderRadius: "4px",
    },
  },

  arrows: {
    width: "100%",
    padding: "0 20px",
  },

  leftArrow: {
    width: "40px",
    height: "40px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.tertiary1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    left: "20px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    "& svg": {
      width: "50%",
      heightL: "50%",
      marginLeft: "6px",
    },
  },

  rightArrow: {
    width: "40px",
    height: "40px",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.tertiary1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    right: "20px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    "& svg": {
      width: "50%",
      heightL: "50%",
    },
  },

  activeImage: {
    border: "2px solid #5078E1",
    opacity: 1,
  },
}));
