import * as React from "react"

const ClientmanagementIcon = (props) => (
    <svg
        height={40}
        viewBox="0 0 64 64"
        width={40}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M52 23a1 1 0 0 1-1 1h-5v2h5a3.009 3.009 0 0 0 3-3v-9h-2zM51 38h-5v2h5a1 1 0 0 1 1 1v9h2v-9a3.009 3.009 0 0 0-3-3zM12 23a1 1 0 0 0 1 1h5v2h-5a3.009 3.009 0 0 1-3-3v-5h2zM13 38h5v2h-5a1 1 0 0 0-1 1v9h-2v-9a3.009 3.009 0 0 1 3-3z"
            fill="#ff6243"
        />
        <circle cx={11} cy={11} fill="#ffcd00" r={8} />
        <path
            d="M59 49H46a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2z"
            fill="#ff9811"
        />
        <path
            d="M24.365 23.029a19.843 19.843 0 0 1-5.208-2.145A16.918 16.918 0 0 0 15 32h8a28.885 28.885 0 0 1 1.365-8.971z"
            fill="#004fac"
        />
        <path
            d="M23 32h-8a16.918 16.918 0 0 0 4.157 11.116 19.843 19.843 0 0 1 5.208-2.145A28.885 28.885 0 0 1 23 32z"
            fill="#00479b"
        />
        <path
            d="M32 15a16.947 16.947 0 0 0-12.843 5.884 19.843 19.843 0 0 0 5.208 2.145C25.954 18.214 28.775 15 32 15zM32 49a16.947 16.947 0 0 0 12.843-5.884 19.843 19.843 0 0 0-5.208-2.145C38.046 45.786 35.225 49 32 49z"
            fill="#005ece"
        />
        <path
            d="M39.635 40.971a19.843 19.843 0 0 1 5.208 2.145A16.918 16.918 0 0 0 49 32h-8a28.885 28.885 0 0 1-1.365 8.971z"
            fill="#006df0"
        />
        <path
            d="M32 15c3.225 0 6.046 3.214 7.635 8.029a19.843 19.843 0 0 0 5.208-2.145A16.947 16.947 0 0 0 32 15z"
            fill="#57a4ff"
        />
        <path
            d="M24.365 40.971a19.843 19.843 0 0 0-5.208 2.145A16.947 16.947 0 0 0 32 49c-3.225 0-6.046-3.214-7.635-8.029z"
            fill="#003f8a"
        />
        <path
            d="M41 32h8a16.918 16.918 0 0 0-4.157-11.116 19.843 19.843 0 0 1-5.208 2.145A28.885 28.885 0 0 1 41 32z"
            fill="#2488ff"
        />
        <path
            d="M32 24a29.668 29.668 0 0 1-7.635-.971A28.885 28.885 0 0 0 23 32h9z"
            fill="#005ece"
        />
        <path
            d="M32 32h9a28.885 28.885 0 0 0-1.365-8.971A29.668 29.668 0 0 1 32 24zM24.365 23.029A29.668 29.668 0 0 0 32 24v-9c-3.225 0-6.046 3.214-7.635 8.029z"
            fill="#006df0"
        />
        <path
            d="M39.635 23.029C38.046 18.214 35.225 15 32 15v9a29.668 29.668 0 0 0 7.635-.971z"
            fill="#2488ff"
        />
        <path
            d="M24.365 40.971C25.954 45.786 28.775 49 32 49v-9a29.668 29.668 0 0 0-7.635.971z"
            fill="#00479b"
        />
        <path
            d="M39.635 40.971A29.668 29.668 0 0 0 32 40v9c3.225 0 6.046-3.214 7.635-8.029zM23 32a28.885 28.885 0 0 0 1.365 8.971A29.668 29.668 0 0 1 32 40v-8z"
            fill="#004fac"
        />
        <path
            d="M32 40a29.668 29.668 0 0 1 7.635.971A28.885 28.885 0 0 0 41 32h-9z"
            fill="#005ece"
        />
        <path
            d="M11 57c4.418 0 8-1.343 8-3v-4c0 1.657-3.582 3-8 3s-8-1.343-8-3v4c0 1.657 3.582 3 8 3z"
            fill="#7ed63e"
        />
        <path
            d="M11 61c4.418 0 8-1.343 8-3v-4c0 1.657-3.582 3-8 3s-8-1.343-8-3v4c0 1.657 3.582 3 8 3z"
            fill="#5eac24"
        />
        <ellipse cx={11} cy={50} fill="#a4e276" rx={8} ry={3} />
        <path
            d="M11 47c-.513 0-1.014.02-1.5.055 3.7.263 6.5 1.481 6.5 2.945s-2.8 2.682-6.5 2.945c.486.035.987.055 1.5.055 4.418 0 8-1.343 8-3s-3.582-3-8-3z"
            fill="#91dc5a"
        />
        <rect fill="#ff9811" height={6} rx={2} width={17} x={44} y={55} />
        <path
            d="M59 55h-.277A1.987 1.987 0 0 1 59 56v2a2 2 0 0 1-2 2H44.277A1.994 1.994 0 0 0 46 61h13a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2zM59 49h-.277A1.987 1.987 0 0 1 59 50v2a2 2 0 0 1-2 2H44.277A1.994 1.994 0 0 0 46 55h13a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2z"
            fill="#ee8700"
        />
        <path
            d="M12 12h-2a4 4 0 0 0-4 4v1.24a7.982 7.982 0 0 0 10 0V16a4 4 0 0 0-4-4z"
            fill="#ed1c24"
        />
        <circle cx={11} cy={9} fill="#ed1c24" r={3} />
        <path
            d="M57 7a3.86 3.86 0 0 0-1.08.15 4.991 4.991 0 0 0-9.84 0A3.86 3.86 0 0 0 45 7a4 4 0 0 0 0 8h12a4 4 0 0 0 0-8z"
            fill="#57a4ff"
        />
        <path
            d="M54.92 7.15a3.817 3.817 0 0 1 .963-.142A4.981 4.981 0 0 0 51 3a4.837 4.837 0 0 0-.5.045 4.975 4.975 0 0 1 4.42 4.105zM57 7a3.936 3.936 0 0 0-.576.043A3.989 3.989 0 0 1 56 15h1a4 4 0 0 0 0-8z"
            fill="#2488ff"
        />
        <g fill="#f1f2f2">
            <path d="M53 57h2v2h-2zM50 57h2v2h-2zM47 57h2v2h-2zM53 51h2v2h-2zM50 51h2v2h-2zM47 51h2v2h-2z" />
        </g>
    </svg>
)

export default ClientmanagementIcon
