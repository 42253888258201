export const constructUploadPayload = (file, profile_id, state, selectedSubCategory) => {
    let form_data = new FormData();

    form_data.append("files", file);
    form_data.append("file_name", file?.name?.split(".")?.[0],);
    form_data.append("file_format", file?.name?.split(".")?.[1],);
    form_data.append("file_size", file?.size);
    form_data.append("is_delete", false);
    form_data.append("is_active", true);
    form_data.append("created_by", profile_id);
    form_data.append("updated_by", profile_id);
    form_data.append("category_id", state?.category_id);
    form_data.append("sub_category_id", selectedSubCategory?.value);
    form_data.append("import_category", state?.category_name);
    form_data.append("import_sub_category", selectedSubCategory?.label);
    form_data.append("masterType", selectedSubCategory?.table_name);

    return form_data;
}