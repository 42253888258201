export const Accountpath = ["account_id", "acc_pic", "name", "email_id", "mobile", "relationship"]


export const Accountheading = (t) => [
    { title: "", field: 'more', },
    { title: `${t("Image")}`, field: "acc_pic", },
    { title: `${t("Account No")}`, field: "account_id", },
    { title: `${t("Name")}`, field: "name", },
    // { title: "Location", field: "location", },
    { title: `${t("Email")}`, field: "email_id", },
    { title: `${t("Mobile Number")}`, field: "mobile", },
    { title: t("Relationship"), field: 'relationship', },
]

export const dataType =
    [
        { type: ["info"], name: "icon" },
        { type: ["avatarmanagement"], name: "acc_pic" },
        { type: ["text"], name: "account_id" },
        { type: ["text"], name: "name" },
        // { type: ["text"], name: "location" },
        { type: ["text"], name: "email_id" },
        { type: ["text"], name: "mobile" },
        { type: ["text"], name: "relationship" },
    ]


export const accountState = {
    acc_id: null,
    image_url: "",
    company: "",
    bank_currency_id: "",
    acc_name: "",
    relation_ship: "",
    description: "",
    address: "",
    primary_contact: "",
    title: "",
    primary_telephone: "",
    primary_email: "",
    bank_name: "",
    branch_name: "",
    doorNo: "",
    addressLineOne: "",
    addressLineTwo: "",
    landmark: "",
    area: "",
    district: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    account_type: "",
    account_no: "",
    bank_route_type: "",
    bank_route_code: "",
    collection_office: "",
    cheque_name: "",
    bank_addrees1: "",
    bank_addrees2: "",
    bank_city: "",
    bank_country: "",
    company_register_type: "",
    company_register_no: "",
    regularity_authority: "",
    pera: "",
    pera_broker: "",
    tax_group: "",
    tax_code: "",
    currency: "",
    tax_register_no: "",
    contact: "",
    lat: "",
    long: "",
    proofFile: "",
    account_type_ap_ar: "",
    bussiness_type: "",
    registered_company: false,
    cr_number: "",
    tax_registered: false,
    tax_number: "",
    account_group: "",
    nationalID: "",
    nationalIDExpiry: "",
    passportID: "",
    passportIDExpiry: "",
    residentID: "",
    residentIDExpiry: "",
    visaNumber: "",
    visaNumberExpiry: "",
    alt_acc_no: "",
    error: {
        acc_name: "",
        relation_ship: "",
        primary_contact: "",
        title: "",
        primary_email: "",
        bank_name: "",
        branch_name: "",
        account_type: "",
        account_no: "",
        bank_route_type: "",
        bank_route_code: "",
        collection_office: "",
        cheque_name: "",
        bank_addrees1: "",
        bank_addrees2: "",
        bank_city: "",
        bank_country: "",
        company_register_type: "",
        company_register_no: "",
        regularity_authority: "",
        pera: "",
        pera_broker: "",
        tax_group: "",
        tax_code: "",
        tax_register_no: "",
        contact: "",
        bank_currency_id: "",
        account_type_ap_ar: "",
        bussiness_type: "",
        cr_number: "",
        tax_number: "",
        account_group: "",
        alt_acc_no: ""
    }
}

export const returnPayload = (data) => {
    let payload = {
        id: data?.acc_id ?? undefined,
        contact_id: data?.relation_ship?.value === "Service Provider" || data?.relation_ship?.value === "Vendor" ? undefined : data?.contact?.value,
        vendor_id: data?.relation_ship?.value === "Vendor" ? data?.contact?.vendor_id : undefined,
        provider_id: data?.relation_ship?.value === "Service Provider" ? data?.contact?.provider_id : undefined,
        name: data?.acc_name,
        company_id: data?.company?.value,
        logo: data?.image_url?.src ?? null,
        mobile_no: data?.primary_contact?.mobile,
        mobile_no_country_code: data?.primary_contact?.mobile_code,
        email_id: data?.primary_email,
        currency_id: data?.currency?.value,
        vat_group_id: data?.tax_group?.value,
        relationship: data?.relation_ship?.value,
        description: data?.description,
        phone_code: data?.primary_telephone?.mobile_code?.length > 0 ? data?.primary_telephone?.mobile_code : null,
        telephone: data?.primary_telephone?.mobile > 0 ? data?.primary_telephone?.mobile : null,
        bank_name: data?.bank_name,
        branch_name: data?.branch_name,
        bank_currency_id: data?.bank_currency_id?.value,
        bank_account_no: data?.account_no,
        account_type: data?.account_type?.length > 0 ? data?.account_type : null,
        routing_code: data?.bank_route_code,
        routing_type: data?.bank_route_type?.value,
        address_1: data?.bank_addrees1 ?? null,
        address_2: data?.bank_addrees2 ?? null,
        bank_city: data?.bank_city,
        country: data?.country?.value ?? null,
        door_no: data?.doorNo?.length > 0 ? data?.doorNo : null,
        street_1: data?.addressLineOne?.length > 0 ? data?.addressLineOne : null,
        street_2: data?.addressLineTwo?.length > 0 ? data?.addressLineTwo : null,
        street_3: data?.addressLineThree?.length > 0 ? data?.addressLineThree : null,
        area: data?.area?.length > 0 ? data?.area : null,
        city: data?.district?.length > 0 ? data?.district : null,
        state: data?.state?.length > 0 ? data?.state : null,
        zipcode: data?.pincode?.length > 0 ? data?.pincode : null,
        longitude: data?.long ?? null,
        latitude: data?.lat ?? null,
        title: data?.title?.length > 0 ? data?.title : null,
        cash_collection_office: data?.collection_office,
        cheque_name: data?.cheque_name,
        proof: data?.proofFile?.filter((val) => val?.is_active)?.map(val => {
            return {
                id: val?.id ?? undefined,
                proof_id: val?.type?.value,
                proof_type: "Company and Account",
                kyc_type: "Both",
                name: val?.name,
                number: val?.id_no,
                valid_from: val?.valid_from,
                valid_to: val?.valid_to,
                url: val?.file,
                is_mandatory: val?.mandatry_verify,
                country_id: val?.issuing_country?.value,
                is_active: val?.is_active
            }
        })
    }
    return payload;
}

export const returnPayloadAccount = (data) => {
    let payload = {
        id: data?.acc_id ?? undefined,
        contact_id: data?.relation_ship?.value === "Cash" ? undefined : data?.relation_ship?.value === "Sales Broker" ? undefined : data?.relation_ship?.value === "Service Provider" || data?.relation_ship?.value === "Vendor" ? undefined : data?.contact?.value,
        vendor_id: data?.relation_ship?.value === "Sales Broker" ? data?.contact?.value : data?.relation_ship?.value === "Vendor" ? data?.contact?.vendor_id : undefined,
        provider_id: data?.relation_ship?.value === "Service Provider" ? data?.contact?.provider_id : undefined,
        name: data?.acc_name,
        company_id: data?.company?.value,
        logo: data?.image_url?.src ?? null,
        mobile_no: data?.primary_contact?.mobile,
        mobile_no_country_code: data?.primary_contact?.mobile_code,
        email_id: data?.primary_email,
        currency_id: data?.currency?.value,
        vat_group_id: data?.tax_group?.value,
        relationship: data?.relation_ship?.value,
        description: data?.description,
        phone_code: data?.primary_telephone?.mobile_code?.length > 0 ? data?.primary_telephone?.mobile_code : null,
        telephone: data?.primary_telephone?.mobile ?? null,
        bank_name: data?.bank_name,
        branch_name: data?.branch_name,
        bank_currency_id: data?.bank_currency_id?.value,
        bank_account_no: data?.account_no,
        account_type: data?.account_type?.length > 0 ? data?.account_type : null,
        routing_code: data?.bank_route_code,
        routing_type: data?.bank_route_type?.value,
        address_1: data?.bank_addrees1 ?? null,
        address_2: data?.bank_addrees2 ?? null,
        bank_city: data?.bank_city,
        country: data?.country?.label ?? null,
        country_id: data?.bank_country?.value ?? null,
        door_no: data?.doorNo?.length > 0 ? data?.doorNo : null,
        street_1: data?.addressLineOne?.length > 0 ? data?.addressLineOne : null,
        street_2: data?.addressLineTwo?.length > 0 ? data?.addressLineTwo : null,
        landmark: data?.addressLineThree?.length > 0 ? data?.addressLineThree : null,
        city: data?.city?.length > 0 ? data?.city : null,
        area: data?.area?.length > 0 ? data?.area : null,
        // city: data?.city?.length > 0 ? data?.district : null,
        state: data?.state?.length > 0 ? data?.state : null,
        zipcode: data?.pincode?.length > 0 ? data?.pincode : null,
        longitude: data?.long ?? null,
        latitude: data?.lat ?? null,
        title: data?.title?.length > 0 ? data?.title : null,
        cash_collection_office: data?.collection_office,
        cheque_name: data?.cheque_name,
        is_registered: data?.registered_company === "" ? false : data?.registered_company,
        is_tax_registered: data?.tax_registered === "" ? false : data?.tax_registered,
        cr_number: data?.cr_number,
        tax_number: data?.tax_number,
        company_type_id: 1,
        account_payment_classification: data?.account_type_ap_ar,
        vendor_group_master_id: data?.account_type_ap_ar === "AP" ? data?.account_group?.value : null,
        customer_group_master_id: data?.account_type_ap_ar === "AR" ? data?.account_group?.value : null,
        resident_id: data?.residentID,
        resident_id_expiry: data?.residentIDExpiry === "" ? undefined : data?.residentIDExpiry,
        visa_expiry: data?.visaNumberExpiry === "" ? undefined : data?.visaNumberExpiry,
        visa_no: data?.visaNumber,
        passport_id: data?.passportID,
        passport_expiry: data?.passportIDExpiry === "" ? undefined : data?.passportIDExpiry,
        national_id: data?.nationalID,
        national_id_expiry: data?.nationalIDExpiry === "" ? undefined : data?.nationalIDExpiry,
        alternative_account_number: data?.alt_acc_no ?? "",
        com_master_id: data?.relation_ship?.value === "Cash" ? data?.contact?.value : undefined,
        proof: data?.proofFile?.filter((val) => val?.is_active)?.map(val => {
            return {
                id: val?.id ?? undefined,
                proof_id: val?.type?.value,
                proof_type: "Company and Account",
                kyc_type: "Both",
                name: val?.name,
                number: val?.id_no,
                valid_from: val?.valid_from,
                valid_to: val?.valid_to,
                url: val?.file,
                is_mandatory: val?.mandatry_verify,
                country_id: val?.issuing_country?.value,
                is_active: val?.is_active
            }
        })
    }
    return payload;
}

export const returnEditPayload = (data, acc_id) => {

    let result = {
        acc_id: acc_id,
        image_url: { src: data?.logo },
        company: {
            value: data?.company?.company_ids,
            label: data?.company?.company_name,
        },
        acc_name: data?.name,
        relation_ship: {
            value: data?.relationship,
            label: data?.relationship,
        },
        description: data?.description,
        address: "",
        primary_contact: {
            mobile: data?.mobile_no,
            mobile_code: data?.mobile_no_country_code
        },
        title: data?.title,
        primary_telephone: {
            mobile: data?.mobile_no,
            mobile_code: data?.mobile_no_country_code
        },
        primary_email: data?.email_id,
        bank_name: data?.bank_name,
        branch_name: data?.branch_name,
        doorNo: data?.address?.[0]?.door_no,
        addressLineOne: data?.address?.[0]?.street_1,
        addressLineTwo: data?.address?.[0]?.street_2,
        landmark: data?.address?.[0]?.street_3,
        area: data?.address?.[0]?.area,
        district: data?.address?.[0]?.district,
        city: data?.address?.[0]?.city,
        state: data?.address?.[0]?.state,
        country: data?.address?.[0]?.country,
        pincode: data?.address?.[0]?.zipcode,
        account_type: data?.account_type,
        account_no: data?.bank_account_no,
        bank_route_type: {
            value: data?.routing_type,
            label: data?.routing_type,
        },
        bank_currency_id: {
            value: data?.bank_currency?.id,
            label: data?.bank_currency?.symbol,
        },
        bank_route_code: data?.routing_code,
        collection_office: data?.cash_collection_office,
        cheque_name: data?.cheque_name,
        bank_addrees1: data?.address_1,
        bank_addrees2: data?.address_2,
        bank_city: data?.city,
        bank_country: data?.country_master,
        company_register_type: {
            value: data?.company_registration,
            label: data?.company_registration,
        },
        company_register_no: data?.company_registration_no,
        regularity_authority: {
            value: data?.rera_type,
            label: data?.rera_type,
        },
        pera: data?.rera_registration_no,
        pera_broker: data?.rera_broker_registration_no,
        tax_group: {
            value: data?.vat_group?.id,
            label: data?.vat_group?.name,
        },
        tax_code: {
            value: data?.tax_code,
            label: data?.tax_code,
        },
        currency: {
            value: data?.company?.currency?.id,
            label: data?.company?.currency?.symbol,
        },
        tax_register_no: data?.tax_registration_no,
        contact: data?.contact_id ? data?.contact : data?.vendor_id ? data?.vendor : data?.provider_id ? data?.provider : "",
        lat: data?.address?.[0]?.lat,
        long: data?.address?.[0]?.long,
        proofFile: data?.know_your_client?.map(val => {
            return {
                id: val?.id,
                type: val?.identification_masterByID,
                kyc_type: "Both",
                name: val?.name,
                id_no: val?.number,
                valid_from: new Date(val?.valid_from),
                valid_to: new Date(val?.valid_to),
                file: val?.url,
                mandatry_verify: val?.is_mandatory,
                issuing_country: val?.country_master,
                is_active: val?.is_active
            }
        }),
        error: {
            acc_name: "",
            relation_ship: "",
            primary_contact: "",
            title: "",
            primary_email: "",
            bank_name: "",
            branch_name: "",
            account_type: "",
            account_no: "",
            bank_route_type: "",
            bank_route_code: "",
            collection_office: "",
            cheque_name: "",
            bank_addrees1: "",
            bank_addrees2: "",
            bank_city: "",
            bank_country: "",
            company_register_type: "",
            company_register_no: "",
            regularity_authority: "",
            pera: "",
            pera_broker: "",
            tax_group: "",
            tax_code: "",
            tax_register_no: "",
            contact: "",
        }
    }
    return result
}

export const returnEditPayloadAccount = (data, acc_id) => {
    let result = {
        acc_id: acc_id,
        contact_account_no: data?.account_no,
        image_url: { src: data?.logo },
        company: {
            value: data?.company?.company_ids,
            label: data?.company?.company_name,
        },
        acc_name: data?.name,
        relation_ship: {
            value: data?.relationship,
            label: data?.relationship,
        },
        description: data?.description,
        address: "",
        primary_contact: {
            mobile: data?.mobile_no,
            mobile_code: data?.mobile_no_country_code
        },
        title: data?.title,
        primary_telephone: {
            mobile: data?.telephone,
            mobile_code: data?.phone_code
        },
        primary_email: data?.email_id,
        bank_name: data?.bank_name,
        branch_name: data?.branch_name,
        doorNo: data?.address?.[0]?.door_no,
        addressLineOne: data?.address?.[0]?.street_1,
        addressLineTwo: data?.address?.[0]?.street_2,
        addressLineThree: data?.address?.[0]?.street_3 ?? null,
        landmark: data?.address?.[0]?.street_3,
        area: data?.address?.[0]?.area,
        district: data?.address?.[0]?.district,
        city: data?.address?.[0]?.city,
        state: data?.address?.[0]?.state,
        country: data?.address?.[0]?.country ? {
            value: data?.address?.[0]?.country ?? null,
            label: data?.address?.[0]?.country ?? ""
        } : "",
        pincode: data?.address?.[0]?.zipcode,
        account_type: data?.account_type,
        account_no: data?.bank_account_no,
        bank_route_type: data?.routing_type ? {
            value: data?.routing_type,
            label: data?.routing_type,
        } : "",
        bank_currency_id: data?.bank_currency?.id ? {
            value: data?.bank_currency?.id,
            label: data?.bank_currency?.symbol,
        } : "",
        bank_route_code: data?.routing_code,
        collection_office: data?.cash_collection_office,
        cheque_name: data?.cheque_name,
        bank_addrees1: data?.address_1,
        bank_addrees2: data?.address_2,
        bank_city: data?.city,
        // bank_country: data?.country_master,
        company_register_type: {
            value: data?.company_registration,
            label: data?.company_registration,
        },
        company_register_no: data?.company_registration_no,
        regularity_authority: {
            value: data?.rera_type,
            label: data?.rera_type,
        },
        pera: data?.rera_registration_no,
        pera_broker: data?.rera_broker_registration_no,
        tax_group: {
            value: data?.vat_group?.id,
            label: data?.vat_group?.name,
        },
        tax_code: {
            value: data?.tax_code,
            label: data?.tax_code,
        },
        currency: data?.company?.currency?.id ? {
            value: data?.company?.currency?.id,
            label: data?.company?.currency?.symbol,
        } : "",
        tax_register_no: data?.tax_registration_no,
        contact: data?.relationship === "Cash" ? data?.com_master : data?.contact_id ? data?.contact : data?.vendor_id ? data?.vendor : data?.provider_id ? data?.provider : "",
        lat: data?.address?.[0]?.lat,
        long: data?.address?.[0]?.long,
        bank_country: data?.bank_country?.id ? {
            label: data?.bank_country?.country_name,
            value: data?.bank_country?.id
        } : "",
        account_type_ap_ar: data?.account_payment_classification,
        bussiness_type: data?.company_type,
        registered_company: data?.is_registered,
        cr_number: data?.cr_number,
        tax_registered: data?.is_tax_registered,
        tax_number: data?.tax_number,
        account_group: data?.account_payment_classification === "AR" ? data?.customer_group_master : data?.account_payment_classification === "AP" && data?.vendor_group_master,
        nationalID: data?.national_id,
        nationalIDExpiry: data?.national_id_expiry?.toString()?.length ? new Date(data?.national_id_expiry) : "",
        passportID: data?.passport_id,
        passportIDExpiry: data?.passport_expiry?.toString()?.length ? new Date(data?.passport_expiry) : "",
        residentID: data?.resident_id,
        residentIDExpiry: data?.resident_id_expiry?.toString()?.length ? new Date(data?.resident_id_expiry) : "",
        visaNumber: data?.visa_no,
        visaNumberExpiry: data?.visa_expiry?.toString()?.length ? new Date(data?.visa_expiry) : "",
        alt_acc_no: data?.alternative_account_number ?? "",
        proofFile: data?.know_your_client?.map(val => {
            return {
                id: val?.id,
                type: val?.identification_masterByID,
                kyc_type: "Both",
                name: val?.name,
                id_no: val?.number,
                valid_from: new Date(val?.valid_from),
                valid_to: new Date(val?.valid_to),
                file: val?.url,
                mandatry_verify: val?.is_mandatory,
                issuing_country: val?.country_master,
                is_active: val?.is_active
            }
        }),
        error: {
            acc_name: "",
            relation_ship: "",
            primary_contact: "",
            title: "",
            primary_email: "",
            bank_name: "",
            branch_name: "",
            account_type: "",
            account_no: "",
            bank_route_type: "",
            bank_route_code: "",
            collection_office: "",
            cheque_name: "",
            bank_addrees1: "",
            bank_addrees2: "",
            bank_city: "",
            bank_country: "",
            company_register_type: "",
            company_register_no: "",
            regularity_authority: "",
            pera: "",
            pera_broker: "",
            tax_group: "",
            tax_code: "",
            tax_register_no: "",
            contact: "",
        }
    }
    return result
}

export const AccordionJson = [
    {
        title: "Light",
        noOfItems: "2",
        moveInStatus: true,
        moveOutStatus: true,
        items: [
            {
                title: "Light",
                moveInAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveOutAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "E"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "G"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveInDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                },
                moveOutDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                }
            },
            {
                title: "Light",
                moveInAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveOutAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "E"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "G"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveInDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                },
                moveOutDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                }
            }
        ]
    },
    {
        title: "Light",
        noOfItems: "2",
        moveInStatus: true,
        moveOutStatus: true,
        items: [
            {
                title: "Light",
                moveInAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveOutAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "E"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "G"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveInDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                },
                moveOutDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                }
            }
        ]
    },
    {
        title: "Light",
        noOfItems: "2",
        moveInStatus: true,
        moveOutStatus: true,
        items: [
            {
                title: "Light",
                moveInAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveOutAssignee: [
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "E"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "T"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "G"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                    {
                        src: "https://mui.com/static/images/avatar/1.jpg",
                        alt: "R"
                    },
                ],
                moveInDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                },
                moveOutDetails: {
                    productId: "LIG-123-456",
                    condition: "Average",
                    cleanliness: "Clean",
                    description: "Light Working Fine Without any issues"
                }
            }
        ]
    },
]
export const StatusOptionList = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const accountType = [
    { label: 'A', value: "A" },
    { label: 'B', value: "B" },
]

export const yesOrNo = [
    { label: "Yes", value: true },
    { label: "No", value: false }
]

export const yesOrNoLang = (t) => [
    { label: t("Yes"), value: true },
    { label: t("No"), value: false }
]