import * as React from "react"

export const BedBath = (props) => {

    const {
        width = 16,
        height = 16,
    } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width={width}
            height={height}
            {...props}
        >
            <g id="Group_98150" data-name="Group 98150" transform="translate(-12 -4)">
                <g id="Rectangle_52047" data-name="Rectangle 52047" transform="translate(12 4)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="16" height="16" stroke="none" />
                    <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
                </g>
                <path id="icons8-floor-plan" d="M8.526,6A2.535,2.535,0,0,0,6,8.526v8.94a2.535,2.535,0,0,0,2.526,2.526h3.4a.583.583,0,0,0,.189,0h5.348a2.535,2.535,0,0,0,2.526-2.526V8.526A2.535,2.535,0,0,0,17.466,6H15.523a.583.583,0,1,0,0,1.166h1.943a1.352,1.352,0,0,1,1.36,1.36V13H17.077a.583.583,0,1,0,0,1.166h1.749v3.3a1.352,1.352,0,0,1-1.36,1.36H12.607V14.162h1.36a.583.583,0,1,0,0-1.166H9.692a.583.583,0,1,0,0,1.166h1.749v4.664H8.526a1.352,1.352,0,0,1-1.36-1.36V8.526a1.352,1.352,0,0,1,1.36-1.36h1.757L12.852,9a.583.583,0,1,0,.677-.949L10.808,6.109A.583.583,0,0,0,10.47,6Z" transform="translate(7.504 -0.5)" fill="#98a0ac" />
            </g>
        </svg>
    )
}

