export const ResourceBoard = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105177">
            <g
                data-name="LS - Resources Board"
                transform="translate(5 5)"
                style={{
                    fill: props?.fill ?? "#c1c5cb",
                }}
            >
                <path data-name="Path 98232" d="M6.286 11.714h2v1.143h-2Z" />
                <path data-name="Path 98233" d="M4 11.714h1.714v1.143H4Z" />
                <path data-name="Path 98234" d="M4 13.429h1.714v1.429H4Z" />
                <path
                    data-name="Path 98235"
                    d="M4 15.938a.853.853 0 0 1 .286.634v.286h1.429v-1.429H4Z"
                />
                <circle
                    data-name="Ellipse 129545"
                    cx={0.857}
                    cy={0.857}
                    r={0.857}
                    transform="translate(5.714 7.429)"
                />
                <path data-name="Path 98236" d="M6.286 13.429h2v1.429h-2Z" />
                <path data-name="Path 98237" d="M6.286 15.429h2v1.429h-2Z" />
                <path
                    data-name="Path 98238"
                    d="M13.429 2.286v1.143a.286.286 0 1 0 .571 0V2.286a.286.286 0 1 0-.571 0Z"
                />
                <path
                    data-name="Path 98239"
                    d="M8.571 2.286v1.143a.286.286 0 1 0 .571 0V2.286a.286.286 0 1 0-.571 0Z"
                />
                <path
                    data-name="Path 98240"
                    d="M6 2.286v1.143a.286.286 0 1 0 .571 0V2.286a.286.286 0 1 0-.571 0Z"
                />
                <path
                    data-name="Path 98241"
                    d="M10.857 2.286v1.143a.286.286 0 1 0 .571 0V2.286a.286.286 0 1 0-.571 0Z"
                />
                <path
                    data-name="Path 98242"
                    d="M16 2.286v1.143a.286.286 0 1 0 .571 0V2.286a.286.286 0 1 0-.571 0Z"
                />
                <path
                    data-name="Path 98243"
                    d="M3.429 11.715h-.286a.286.286 0 1 1 0-.571h1.162a2.29 2.29 0 0 1 1.323-1.793 1.429 1.429 0 1 1 1.886 0 2.291 2.291 0 0 1 .771.571 2.3 2.3 0 0 1 .771-.574 1.429 1.429 0 1 1 1.889 0 2.3 2.3 0 0 1 .771.574 2.291 2.291 0 0 1 .771-.571 1.429 1.429 0 1 1 1.886 0 2.29 2.29 0 0 1 1.32 1.793h1.163a.286.286 0 0 1 0 .571h-.286v5.143a.571.571 0 0 1-.571.571H4.286V18h13.428a.286.286 0 0 0 .286-.285V6.286H2v9.429h1.429Z"
                />
                <path
                    data-name="Path 98244"
                    d="M4.883 11.143h2.851a2.26 2.26 0 0 1 .219-.724 1.693 1.693 0 0 0-1.381-.7 1.716 1.716 0 0 0-1.689 1.424Z"
                />
                <circle
                    data-name="Ellipse 129546"
                    cx={0.857}
                    cy={0.857}
                    r={0.857}
                    transform="translate(9.143 7.429)"
                />
                <path data-name="Path 98245" d="M8.857 15.429h2.286v1.429H8.857Z" />
                <path data-name="Path 98246" d="M8.857 13.429h2.286v1.429H8.857Z" />
                <path data-name="Path 98247" d="M14.286 13.429H16v1.429h-1.714Z" />
                <circle
                    data-name="Ellipse 129547"
                    cx={0.857}
                    cy={0.857}
                    r={0.857}
                    transform="translate(12.571 7.429)"
                />
                <path
                    data-name="Path 98248"
                    d="M12.047 10.414a2.26 2.26 0 0 1 .219.724h2.851a1.716 1.716 0 0 0-1.689-1.424 1.693 1.693 0 0 0-1.381.7Z"
                />
                <path data-name="Path 98249" d="M8.857 11.714h2.286v1.143H8.857Z" />
                <path
                    data-name="Path 98250"
                    d="M8.311 11.143h3.377a1.712 1.712 0 0 0-3.377 0Z"
                />
                <path data-name="Path 98251" d="M14.286 11.714H16v1.143h-1.714Z" />
                <path
                    data-name="Path 98252"
                    d="M18 3.714a.571.571 0 0 0-.571-.571h-.286v.286a.857.857 0 1 1-1.714 0v-.286h-.857v.286a.857.857 0 0 1-1.714 0v-.286H12v.286a.857.857 0 0 1-1.714 0v-.286h-.572v.286a.857.857 0 0 1-1.714 0v-.286h-.857v.286a.857.857 0 0 1-1.714 0v-.286h-.858v.286a.857.857 0 0 1-1.714 0v-.286h-.286A.571.571 0 0 0 2 3.714v2h16Z"
                />
                <path
                    data-name="Path 98253"
                    d="M2.286 18H3.31L2 16.69v1.025a.286.286 0 0 0 .286.285Z"
                />
                <path
                    data-name="Path 98254"
                    d="m2.404 16.286 1.31 1.31v-1.024a.286.286 0 0 0-.285-.286Z"
                />
                <path data-name="Path 98255" d="M11.714 11.714h2v1.143h-2Z" />
                <path data-name="Path 98256" d="M11.714 15.429h2v1.429h-2Z" />
                <path data-name="Path 98257" d="M11.714 13.429h2v1.429h-2Z" />
                <path data-name="Path 98258" d="M14.286 15.429H16v1.429h-1.714Z" />
                <path
                    data-name="Path 98259"
                    d="M3.429 2.286v1.143a.286.286 0 1 0 .571 0V2.286a.286.286 0 1 0-.571 0Z"
                />
            </g>
        </g>
    </svg>
)