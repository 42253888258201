import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../../utils";

export const CountCardStyle = makeStyles((theme)=>({
    box:{
        background:"#fff",
        boxShadow:"0px 0px 16px #00000014",
        height:94,
        width:"100%",
        borderRadius: theme.palette.borderRadius,
    },
    title:{
        fontSize:"1.5rem",
        fontFamily: Bold
    },
    content:{
        fontSize:"0.875rem",
        fontFamily: Bold
    },
    uTitle:{
        fontSize:"0.75rem",
        color:theme.typography.color.tertiary,
        fontFamily:Bold
    },
    uSubtitle1:{
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        color:theme.typography.color.primary,
    },
    uSubtitle2:{
        fontSize:"0.75rem",
        fontFamily: Regular,
        color:theme.typography.color.tertiary,
        direction:"ltr"
    },
    avatar:{
        height:40,
        width:40,
        borderRadius:4
    }
}))