import * as React from "react"

const AlarmIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 104895">
            <path data-name="Rectangle 55310" fill="none" d="M0 0h24v24H0z" />
            <path
                d="M18.333 17.128H6.437a1.436 1.436 0 0 1-1.3-2.047l1.093-2.2V10.31a6.226 6.226 0 0 1 5.857-6.3 6.154 6.154 0 0 1 6.451 6.147v2.727l1.087 2.188a1.435 1.435 0 0 1-1.293 2.059ZM12.117 4.621ZM9.96 17.949a2.459 2.459 0 0 0 4.849 0Z"
                fill="#78b1fe"
            />
        </g>
    </svg>
)

export default AlarmIcon
