import React from "react";
import { withNavBars } from "../../HOCs";
import Contract from "./contract";
import CreateNewEditContext from "./createNewEditContext";

class ContractParent extends React.Component {
  render() {
    return (
      <CreateNewEditContext>
        <Contract />
      </CreateNewEditContext>
    );
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ContractParent, props);
