import React from "react";
import { withNavBars } from "../../HOCs";
import  TimeOffResources from "./timeOffResources";

class TimeOffResourcesParent extends React.Component {
    render() {
        return <TimeOffResources/>;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(TimeOffResourcesParent, props);