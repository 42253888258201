import { Box, Button, Container, Drawer, Grid, Hidden, Menu, MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useStyles } from "./style";
import { useHistory, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { AlertContext, FirebaseContext } from "../../contexts";
import { TermsAndCondition } from "../../components/termsAndCondition/termsAndCondition";
import { Password, TextBox } from "../../components";
import { AlertProps, ValidateEmail } from "../../utils";
import { silentOTP } from "./silentOTP";
import { Routes } from "../../router/routes";
import { EnvTag } from "../../components/envTag";
import { TitleBar } from "../../components/titlebar";
import { CloseButtonSquare } from "../../assets/close_button_square";
import { BackButtonRound } from "../../assets/back_button_round";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useContext } from "react";
import { useState } from "react";
import { getCurrentBuildNumber } from "../../utils/version";

const InitialState = {
      email: "",
      password: "",
      error: {
            email: "",
            password: "",
      },
};

export const LoginPage = ({
      t = () => false,
      size = {},
      region_list = [],
      term_and_condition_state = {},
      screen_state = {},
      set_screen_state = () => false,
      initital_screen_state = {},
}) => {
      const classes = useStyles({ size });
      const alert = useContext(AlertContext);
      const firebaseContext = useContext(FirebaseContext);
      const react_history = useHistory();
      const search = useLocation().search;
      const token = new URLSearchParams(search).get("welcomeAuthToken");
      const state = useLocation().state;
      const [is_drawer_open, set_is_drawer_open] = useState(false);
      const [login, setLogin] = useState({ ...InitialState });
      const [disable, setDisable] = useState(false);
      const [regionAnchorEl, setRegionAnchorEl] = useState(null);
      const [app_version, set_app_version] = useState(null);
      const is_region_open = Boolean(regionAnchorEl);
      const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(true);

      useEffect(() => {
            getCurrentBuild();

            if (localStorage.getItem("device_token")) {
            } else {
                  firebaseContext.requestPermission();
            }
            if (token !== null && token !== "") {
                  const email_id = jwt_decode(token)
                  setLogin({ ...login, email: email_id?.email_id })
            }

            if (state?.email?.length > 0) {
                  setLogin({
                        ...login,
                        email: state?.email
                  })
            }
            // eslint-disable-next-line
      }, []);

      const getCurrentBuild = () => {
            return new Promise(async (resolve, reject) => {
                  try {
                        const [temp_app_version] = await Promise.all([getCurrentBuildNumber()]);
                        set_app_version(temp_app_version);
                  } catch (error) {
                        reject(error);
                  }
            });
      }

      const handleTermsAndConditions = (is_open = false) => {
            if (term_and_condition_state && is_open) {
                  set_is_drawer_open(true);
            } else {
                  set_is_drawer_open(false);
            }
      }

      const updateState = (key, value) => {
            if (key === "email") {
                  ValidateEmail(value);
            }
            let error = login.error;
            error[key] = "";
            setLogin({ ...login, [key]: value, error });
      };

      const isIamValideToLogin = () => {
            let isValid = true;
            let error = login.error;

            if (login.email.length === 0) {
                  isValid = false;
                  error.email = t("Enter your email/mobile is required");
            }
            if (login.password.length === 0) {
                  isValid = false;
                  error.password = t("Password is required");
            }
            if (login.email !== "" && login.password !== "") {
                  isValid = true;
            }
            setLogin({ ...login, error });
            return isValid;
      };

      const handleForgotPassword = () => {
            react_history.push(Routes.forgotPassword);
      }

      const handleLogin = () => {
            setDisable(true)
            silentOTP(login, alert, react_history, isIamValideToLogin, setDisable)
      }

      const handleGoBack = () => {
            react_history.goBack(-1);
      }

      const handleRegion = (e) => {
            setRegionAnchorEl(e.currentTarget);
      }

      const handleRegionClick = (_) => {
            setRegionAnchorEl(null);

            let temp_screen_state = {
                  ...screen_state,
                  [_?.id && "id"]: _?.id,
                  [_?.region && "region"]: _?.region,
                  [_?.login_logo && "logo"]: _?.login_logo,
                  [_?.web_background && "web_background"]: _?.web_background,
                  [_?.mobile_background && "mob_background"]: _?.mobile_background,
                  [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
                  [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
                  [_?.flag && "flag"]: _?.flag,
                  [_?.domain && "domain"]: _?.domain?.[0]?.domain,
            }

            set_screen_state(temp_screen_state);

            let link = `${temp_screen_state?.domain}${window.location.pathname}${window.location.search}`;
            window.open(link, "_self");

            alert.setSnack({
                  ...alert, open: true,
                  severity: AlertProps.severity.info,
                  msg: `${t("Region Changed to")} ${temp_screen_state?.region} ${t("successfully.")}`,
            });
      }

      const handleImageOnLoad = () => {
            setIsBackgroundImageLoading(false);
      }

      const handleImageOnError = () => {
            set_screen_state({ ...screen_state, mob_background: initital_screen_state?.mob_background });
      }

      return (
            <>
                  {/* Mobile UI */}
                  <Hidden smUp>
                        <Container className={classes.mob_right_section_card} maxWidth="sm">
                              <TitleBar is_back_button={Boolean(state?.origin === Routes?.welcome)}
                                    title={t("Log In")} goBack={handleGoBack} />
                              <Grid container className={classes.mob_content}>
                                    <Grid item xs={12} className={classes.mob_body_items}
                                          sx={{ height: size?.height - 161, overflow: "overlay" }}>
                                          <Box className={classes.mob_background}>
                                                {isBackgroundImageLoading && <Skeleton variant="rounded" className={classes.mob_background_image} />}
                                                <img className={classes.mob_background_image}
                                                      style={{ display: isBackgroundImageLoading ? "none" : "block" }}
                                                      src={screen_state?.mob_background} alt=""
                                                      onLoad={handleImageOnLoad}
                                                      onError={handleImageOnError} />
                                          </Box>
                                          <Box height={"16px"} />
                                          <Stack direction={"row"} columnGap={"16px"}
                                                justifyContent={"space-between"} alignItems={"start"}>
                                                <Typography className={classes.web_title}>
                                                      {t("Login in to Gateway")}
                                                </Typography>
                                                {!Boolean(screen_state?.is_custom_domain) &&
                                                      <Box className={classes.region_box} onClick={handleRegion}>
                                                            <img className={classes.region_flag} src={screen_state?.flag} alt="" />
                                                            {is_region_open
                                                                  ? <KeyboardArrowUpRoundedIcon fontSize="small" color="#4E5A6B" />
                                                                  : <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                                                            }
                                                      </Box>
                                                }
                                          </Stack>
                                          <Box height={"16px"} />
                                          <TextBox
                                                fullWidth
                                                value={login.email}
                                                onChange={(e) => updateState("email", e.target.value)}
                                                label={t("Email ID")}
                                                placeholder={t("Enter your Email ID")}
                                                isError={login.error.email.length > 0}
                                                errorMessage={login.error.email}
                                                isRequired />
                                          <Box height={"24px"} />
                                          <Password
                                                value={login.password}
                                                onChange={(e) => updateState("password", e.target.value)}
                                                label={t("Password")}
                                                placeholder={t("Enter Password")}
                                                isError={login.error.password.length > 0}
                                                errorMessage={login.error.password}
                                                isRequired />
                                          <Box height={"8px"} />
                                          <Typography className={classes.web_forget_password}
                                                onClick={handleForgotPassword}>
                                                {t("Forgot password?")}
                                          </Typography>
                                    </Grid>
                                    <Grid item xs={12} alignSelf={"self-end"} className={classes.mob_bottom_items}>
                                          <Stack direction={"column"} alignItems={"start"}>
                                                <Button className={classes.mob_set_up_new_button} disabled={disable}
                                                      onClick={handleLogin}>
                                                      <Typography className={classes.mob_set_up_new_button_text}>
                                                            {t("Login")}
                                                      </Typography>
                                                </Button>
                                                <Box height={"16px"} />
                                                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                                                      justifyContent={"space-between"} alignItems={"center"}>
                                                      {/* <Typography className={classes.terms_of_use_word}
                                                            onClick={() => handleTermsAndConditions(true)}>
                                                            {t("Terms of Use")}
                                                      </Typography> */}
                                                      <img className={classes.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                                                      {app_version &&
                                                            <Typography className={classes.version_no}>
                                                                  {`V ${app_version}`}
                                                            </Typography>
                                                      }
                                                </Stack>
                                          </Stack>
                                    </Grid>
                              </Grid>
                        </Container>
                  </Hidden>

                  {/* Web & Tablet UI */}
                  <Hidden smDown>
                        <Container className={classes.web_right_section_card} maxWidth="sm">
                              <Grid container className={classes.web_content} justifyContent={"space-between"}>
                                    <EnvTag top={16} right={16} />
                                    <Grid item xs={12}>
                                          {state?.origin === Routes?.welcome &&
                                                <Box className={classes.web_back_button}
                                                      onClick={() => react_history.goBack(-1)}>
                                                      <BackButtonRound />
                                                </Box>}
                                    </Grid>
                                    <Grid item xs={12}>
                                          <Stack direction={"column"} alignItems={"start"} display={"grid"}>
                                                <Box height={"16px"} />
                                                <img className={classes.web_logo} src={screen_state?.logo} alt={t("logo")} />
                                                <Box height={"16px"} />
                                                <Stack direction={"row"} columnGap={"16px"}
                                                      justifyContent={"space-between"} alignItems={"start"}>
                                                      <Typography className={classes.web_title}>
                                                            {t("Login in to Gateway")}
                                                      </Typography>
                                                      <Box className={classes.region_box} onClick={handleRegion}>
                                                            <img className={classes.region_flag} src={screen_state?.flag} alt="" />
                                                            {is_region_open
                                                                  ? <KeyboardArrowUpRoundedIcon fontSize="small" color="#4E5A6B" />
                                                                  : <KeyboardArrowDownRoundedIcon fontSize="small" color="#4E5A6B" />
                                                            }
                                                      </Box>
                                                </Stack>
                                                <Box height={"24px"} />
                                                <TextBox
                                                      fullWidth
                                                      value={login.email}
                                                      onChange={(e) => updateState("email", e.target.value)}
                                                      label={t("Email Id")}
                                                      placeholder={t("Enter your Email Id")}
                                                      isError={login.error.email.length > 0}
                                                      errorMessage={login.error.email}
                                                      isRequired />
                                                <Box height={"24px"} />
                                                <Password
                                                      value={login.password}
                                                      onChange={(e) => updateState("password", e.target.value)}
                                                      label={t("Password")}
                                                      placeholder={t("Enter Password")}
                                                      isError={login.error.password.length > 0}
                                                      errorMessage={login.error.password}
                                                      isRequired />
                                                <Box height={"12px"} />
                                                <Typography className={classes.web_forget_password}
                                                      onClick={handleForgotPassword}>
                                                      {t("Forgot password?")}
                                                </Typography>
                                                <Box height={"24px"} />
                                          </Stack>
                                    </Grid>
                                    <Grid item xs={12} alignSelf={"self-end"}>
                                          <Stack direction={"column"} alignItems={"start"} display={"grid"}>
                                                <Button className={classes.web_login_button} disabled={disable}
                                                      onClick={handleLogin}>
                                                      <Typography className={classes.web_login_button_text}>
                                                            {t("Login")}
                                                      </Typography>
                                                </Button>
                                                <Box height={"24px"} />
                                                <Stack direction={"row"} columnGap={"16px"} width={"100%"} display={"flex"}
                                                      justifyContent={"space-between"} alignItems={"center"}>
                                                      {app_version &&
                                                            <Typography className={classes.version_no}>
                                                                  {`V ${app_version}`}
                                                            </Typography>
                                                      }
                                                      {/* <Typography className={classes.terms_of_use_word}
                                                            onClick={() => handleTermsAndConditions(true)}>
                                                            {t("Terms of Use")}
                                                      </Typography> */}
                                                      <img className={classes.powered_by_logo} src={screen_state?.powered_by_logo} alt={t("powered_by")} />
                                                </Stack>
                                          </Stack>
                                    </Grid>
                              </Grid>
                        </Container>
                  </Hidden>

                  {/* Region List */}
                  <Menu
                        id="region-menu_list" open={is_region_open}
                        anchorEl={regionAnchorEl} elevation={0}
                        className={classes.region_menu_list}
                        onClose={() => setRegionAnchorEl(null)}
                        MenuListProps={{ "aria-labelledby": "basic-button", }}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
                        transformOrigin={{ vertical: "top", horizontal: "right", }}>
                        {region_list?.map?.((_) => {
                              return (
                                    <MenuItem
                                          onClick={() => handleRegionClick(_)}
                                          className={_?.id === screen_state?.id
                                                ? classes.selected_region_menu_item
                                                : classes.unselected_region_menu_item}>
                                          <img className={classes.region_flag} src={_?.flag} alt="" />
                                          <Box width={"10px"} />
                                          <Typography className={_?.id === screen_state?.id
                                                ? classes.selected_region_name
                                                : classes.unselected_region_name}>
                                                {_?.region}
                                          </Typography>
                                    </MenuItem>
                              );
                        })}
                  </Menu>

                  {/* Terms & Conditions */}
                  <Drawer
                        anchor={size?.width > 599 ? "right" : "bottom"}
                        className={classes.drawer}
                        open={is_drawer_open}
                        onClose={() => handleTermsAndConditions(false)}>
                        <Grid container>
                              <Grid className={classes.drawer_header_grid}
                                    item xs={12}>
                                    <Box height={"32px"} onClick={() => handleTermsAndConditions(false)}>
                                          <CloseButtonSquare />
                                    </Box>
                                    {size?.width > 599 &&
                                          <Typography className={classes.drawer_header_text}>
                                                {t("Terms Of Use")}
                                          </Typography>
                                    }
                              </Grid>
                              <Grid item xs={12}>
                                    <Box className={classes.drawer_content_box}>
                                          <TermsAndCondition termsCondition={term_and_condition_state} t={t} />
                                    </Box>
                              </Grid>
                        </Grid>
                  </Drawer>
            </>
      );
}