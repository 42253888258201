import { Box, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import SearchIMG from "../../assets/search";
import { SemiBold } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  search: {
    position: "relative",
    borderRadius: theme.palette.borderRadius,
    marginLeft: 0,
    width: "100%",
    display: "inline-flex",
    height: "41px",
    backgroundColor: (props) =>
      props?.color ? "white" : theme.palette.background.secondary,
    paddingBottom: (props) =>
      props?.color ? "" : "2px"
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    height: "40px",
    color: "#C5C5C5",
    width: "100%",
    border: "1px solid #E4E8EE",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: "1px solid #E4E8EE",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  textfield: {
    backgroundColor: (props) => props?.color ?? "auto",
    borderRadius: theme.palette.borderRadius,
    border: "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      backgroundColor: '#fff'
    },
    [`& fieldset`]: {
      borderRadius: theme.palette.borderRadius,
      height: (props) => (props.multiline ? "unset" : props?.height ?? 46),
      border: (props) => props?.border ?? "1.5px solid #E4E8EE",
      "&:hover": {
        border: "1px solid red",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => props?.padding ?? "11px 14px",
    },
    '& .MuiOutlinedInput-root': {
      paddingInlineStart: "14px !important",
      paddingLeft: "0px !important",
      '&:hover fieldset': {
        borderColor: '#5078E1',


      },

    },
  },
  newtextfield: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      border: "none",
      "&:hover fieldset": {
        borderColor: "none",
      },
    },
  },
  Label: {
    color: theme.typography.color.tertiary,
    fontFamily: SemiBold,
    fontSize:"0.75rem",

    marginBottom: (props) => props?.marginBottom ?? "5px",
  },
}));

export const SearchFilter = ({
  handleChange = () => false,
  value = "",
  placeholder = "",
  color = false,
  isReadonly = false,
  borderRadius = true,
  label = "",
  marginBottom = null,
  height = "",
  customfieldSx = {},
}) => {
  const classes = useStyles({ color, marginBottom, borderRadius });
  const getLabel = (label = "") => {
    return (
      <Typography className={classes.Label} noWrap>
        {label}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <Box>
        {label && getLabel(label)}
        <div className={classes.search}>
          <TextField
            sx={{ ...customfieldSx }}
            autoComplete="off"
            InputProps={{
              style: {
                fontSize:"0.875rem",
                padding: "auto",
                fontFamily: SemiBold,
                color: "#98A0AC"
              },
              startAdornment: (
                <SearchIMG
                  color="#a4b2c1"
                  style={{ color: "#999999" }}
                />
              ),
            }}
            placeholder={placeholder ? placeholder : "Search"}
            onChange={(e) => handleChange(e.target.value)}
            value={value}
            disabled={isReadonly}
            size={"small"}
            fullWidth
            className={borderRadius ? classes.textfield : classes.newtextfield}
            height={height ?? "auto"}
          />
        </div>
      </Box>
    </div>
  );
};
