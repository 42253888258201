import * as React from "react"
export const FunctionalLocationIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <defs>
      <radialGradient
        id="c"
        cx={0.5}
        cy={0.5}
        r={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0.177} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="b"
        x1={0.147}
        x2={0.854}
        y1={0.124}
        y2={0.721}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#d43a02" />
        <stop offset={1} stopColor="#b9360c" />
      </linearGradient>
      <clipPath id="a">
        <path
          fill="#fff"
          stroke="#707070"
          d="M0 0h32v32H0z"
          data-name="Rectangle 55378"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" data-name="Mask Group 93089">
      <g transform="translate(-1.721 4)">
        <path
          fill="#35c1f1"
          d="M24.977 24H14.93V3.907h10.047a1.116 1.116 0 0 1 1.116 1.116v17.86A1.116 1.116 0 0 1 24.977 24Z"
          data-name="Path 99322"
        />
        <path
          fill="#199be2"
          d="M10.714 1.799a.554.554 0 0 0-.249-.059v20.093a.554.554 0 0 1 .249.059L14.93 24V3.907Z"
          data-name="Path 99323"
        />
        <path
          fill="#50e6ff"
          d="m10.216 1.8-3.6 1.8a1.117 1.117 0 0 0-.617 1v18.5a.558.558 0 0 0 .808.5l3.408-1.7a.561.561 0 0 1 .249-.059V1.74a.562.562 0 0 0-.248.06Z"
          data-name="Path 99324"
        />
        <path
          d="M26.088 5.023a1.116 1.116 0 0 0-1.112-1.116h-8.93a7.246 7.246 0 0 0 1.5 7.844l4.631 4.635a.806.806 0 0 0 1.14 0l2.776-2.779V5.023Z"
          data-name="Path 99325"
          opacity={0.05}
        />
        <path
          d="M26.093 5.023a1.116 1.116 0 0 0-1.117-1.116h-8.622a6.951 6.951 0 0 0 1.389 7.649l4.453 4.457a.775.775 0 0 0 1.1 0l2.8-2.8v-8.19Z"
          data-name="Path 99326"
          opacity={0.07}
        />
        <path
          fill="url(#b)"
          d="M35.5 11.362a6.7 6.7 0 1 0-9.6 0l4.275 4.279a.744.744 0 0 0 1.053 0Z"
          data-name="Path 99327"
          transform="translate(-7.953)"
        />
        <circle
          cx={3.126}
          cy={3.126}
          r={3.126}
          fill="url(#c)"
          data-name="Ellipse 129735"
          transform="translate(19.619 3.572)"
        />
        <circle
          cx={2.679}
          cy={2.679}
          r={2.679}
          fill="#f9f9f9"
          data-name="Ellipse 129736"
          transform="translate(20.065 4.019)"
        />
      </g>
    </g>
  </svg>
)

