export const Vehicles = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 96942">
            <g data-name="Group 96941">
                <path
                    data-name="Path 93625"
                    d="M24.255 12.474a.482.482 0 0 0-.372-.177h-1.822a6.213 6.213 0 0 0-1.7-2.787c-2.3-1.516-8.415-1.516-10.719 0a6.244 6.244 0 0 0-1.706 2.787H6.115a.48.48 0 0 0-.469.577l.27 1.308a.479.479 0 0 0 .469.382h.54a3.238 3.238 0 0 0-.777 2.116 3.17 3.17 0 0 0 1 2.38l.021.017v1.83a.72.72 0 0 0 .719.719h1.683a.72.72 0 0 0 .719-.719v-.736h9.418v.736a.72.72 0 0 0 .719.719h1.681a.72.72 0 0 0 .719-.719v-1.794a3.29 3.29 0 0 0 .21-4.55h.576a.478.478 0 0 0 .469-.383l.27-1.308a.483.483 0 0 0-.097-.398ZM10.43 10.711c1.836-1.209 7.3-1.209 9.138 0a5.929 5.929 0 0 1 1.236 2.339H9.195a5.93 5.93 0 0 1 1.235-2.339Zm-2.151 6.15a1.415 1.415 0 1 1 1.415 1.415 1.415 1.415 0 0 1-1.415-1.415Zm12.045 1.415a1.415 1.415 0 1 1 1.412-1.415 1.415 1.415 0 0 1-1.412 1.415Z"
                    fill={props?.fill ?? "#c1c5cb"}
                />
            </g>
        </g>
        <path data-name="Rectangle 52035" fill="none" d="M0 0h30v30H0z" />
    </svg>
)
