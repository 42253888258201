import * as React from "react"
export const NewContact = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 113989" transform="translate(-994 -460)">
      <rect
        width={40}
        height={40}
        fill="#eef9ee"
        data-name="Rectangle 57639"
        rx={8}
        transform="translate(994 460)"
      />
      <g data-name="Group 113987">
        <g data-name="icons8-contact (1)">
          <path
            fill="#6dafb3"
            d="M1021.938 471.215h-9.969l-2.215-2.215h-5.539a2.215 2.215 0 0 0-2.215 2.215v3.877h22.154v-1.661a2.215 2.215 0 0 0-2.215-2.215"
            data-name="Path 100625"
          />
          <path
            fill="#6dafb3"
            d="M1021.938 471.215h-17.723a2.215 2.215 0 0 0-2.215 2.215v12.185a2.215 2.215 0 0 0 2.215 2.215h2.77v-.707a1.108 1.108 0 1 1 1.108 0v.707h9.969v-.707a1.108 1.108 0 1 1 1.108 0v.707h2.769a2.215 2.215 0 0 0 2.215-2.215V473.43a2.215 2.215 0 0 0-2.216-2.215"
            data-name="Path 100626"
          />
          <g data-name="Group 113985">
            <path
              fill="#edf7f8"
              d="M1010.307 476.753a2.215 2.215 0 1 1-2.215-2.215 2.215 2.215 0 0 1 2.215 2.215m1.662 4.985a4.552 4.552 0 0 0-3.877-1.662 4.552 4.552 0 0 0-3.877 1.662v1.108h7.754Zm1.662-6.646h8.308v1.108h-8.309Zm0 2.769h8.308v1.108h-8.309Zm0 2.769h4.431v1.108h-4.432Z"
              data-name="Path 100627"
            />
          </g>
        </g>
        <g data-name="Group 113986">
          <g
            fill="#5ac782"
            stroke="#eef9ee"
            strokeWidth={1.5}
            data-name="Ellipse 130007"
            transform="translate(1014.923 479.523)"
          >
            <circle cx={5.538} cy={5.538} r={5.538} stroke="none" />
            <circle cx={5.538} cy={5.538} r={6.288} fill="none" />
          </g>
          <path
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.5}
            d="M1023.153 485.547h-2.206v2.206h-.971v-2.206h-2.206v-.971h2.206v-2.206h.971v2.206h2.206Z"
            data-name="Path 100628"
          />
        </g>
      </g>
    </g>
  </svg>
)
