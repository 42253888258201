export const Sales = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>{".cls-2{fill:#c1c5cb}"}</style>
    </defs>
    <g
      id="Group_99668"
      data-name="Group 99668"
      transform="translate(-1501 511)"
    >
      <g
        id="Group_8127"
        data-name="Group 8127"
        transform="translate(-10 -1483)"
      >
        <g
          id="Group_99684"
          data-name="Group 99684"
          transform="translate(-4 -42)"
        >
          <path
            id="Rectangle_31"
            data-name="Rectangle 31"
            transform="translate(1515 1014)"
            style={{
              fill: "none",
            }}
            d="M0 0h30v30H0z"
          />
          <g id="Sales" transform="translate(1523 1006)">
            <g
              id="Group_99675"
              data-name="Group 99675"
              transform="translate(0 16)"
            >
              <g id="Group_99674" data-name="Group 99674">
                <path
                  id="Path_95371"
                  data-name="Path 95371"
                  className="cls-2"
                  d="M14.941 16.219a.44.44 0 0 0-.38-.219H.439a.439.439 0 0 0-.382.657l1.411 2.419h12.063l1.411-2.419a.44.44 0 0 0-.001-.438Z"
                  transform="translate(0 -16)"
                  style={{
                    fill: props?.fill ?? "#c1c5cb",
                  }}
                />
              </g>
            </g>
            <g
              id="Group_99677"
              data-name="Group 99677"
              transform="translate(3.729 23.031)"
            >
              <g id="Group_99676" data-name="Group 99676">
                <path
                  id="Path_95372"
                  data-name="Path 95372"
                  className="cls-2"
                  d="m127.295 256 1.256 2.2h5.03l1.256-2.2Z"
                  transform="translate(-127.295 -256)"
                  style={{
                    fill: props?.fill ?? "#c1c5cb",
                  }}
                />
              </g>
            </g>
            <g
              id="Group_99679"
              data-name="Group 99679"
              transform="translate(1.971 19.955)"
            >
              <g id="Group_99678" data-name="Group 99678">
                <path
                  id="Path_95373"
                  data-name="Path 95373"
                  className="cls-2"
                  d="m67.295 151 1.256 2.2H77.1l1.256-2.2Z"
                  transform="translate(-67.295 -151)"
                  style={{
                    fill: props?.fill ?? "#c1c5cb",
                  }}
                />
              </g>
            </g>
            <g
              id="Group_99681"
              data-name="Group 99681"
              transform="translate(5.303 26.107)"
            >
              <g id="Group_99680" data-name="Group 99680">
                <path
                  id="Path_95374"
                  data-name="Path 95374"
                  className="cls-2"
                  d="M181 361v3.516a.439.439 0 0 0 .439.439h3.516a.439.439 0 0 0 .439-.439V361Z"
                  transform="translate(-181 -361)"
                  style={{
                    fill: props?.fill ?? "#c1c5cb",
                  }}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
