import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { CreateBackupFormUseStyles } from './styles';
import Restore from './restor';
import { CloseIconWithBG } from '../../../assets';
import { timeZoneConverter } from '../../../utils';
import PlaceHolder from './placeholder';


export const RestoreLog = ({
    handleClose = () => false,
    data={},
    t=()=>false
}) => {
    const classes = CreateBackupFormUseStyles()
    return (
        <Box>
               <Stack className={classes.header} direction={"row"}  
                spacing={"12px"} p={"16px"} alignItems={"center"} sx={{backgroundColor:"#F2F4F7"}}>
                <div className={classes.close_button} onClick={handleClose}>
                    <CloseIconWithBG />
                </div>
                <Typography className={classes.header_title}>{t("Restore Log")}</Typography>
            </Stack>

            {/* body */}
            <Box padding={'16px'} height={'calc(100vh - 72px)'} overflow={'auto'} className={classes.content1}>

{/* back up details */}
<Box className={classes.parentBox}>
    <Stack direction="row" justifyContent={'space-between'} marginBottom={'6px'}>
        <Typography className={classes.backuptitle}>
            {data?.title}
        </Typography>

        <Box className={data?.status === "Completed" ? classes.statusBox : classes.statusBox1}>
        {data?.status}
        </Box>
    </Stack>

    <Typography className={classes.desc}>{timeZoneConverter(data?.scheduled_date)} , {data?.users?.username}</Typography>
</Box>


{/* logs */}
<Typography className={classes.restoreTitle}>{t("RESTORE LOG")}</Typography>

<Box height={'calc(100vh - 237px)'} overflow="auto">
    {
       data?.client_restore?.length > 0 ?  data?.client_restore?.map((x , i)=>{
            return(
                <>
                    <Stack direction={'row'} spacing={'14px'}>
        <Box className={classes.restoreIcon}>
            <Restore/>
        </Box>
        <Box>
            <Typography className={classes.date}>{timeZoneConverter(x?.created_at)}</Typography>
            <Typography className={classes.person}>{x?.users?.username}</Typography>

        </Box>
    </Stack>
    {
       ( data?.client_restore?.length !== i+1) && 
        <Box className={classes.borderBox}/>
    }

                </>
            )
        }) : <Box textAlign="center"><PlaceHolder/></Box>
    }

</Box>
</Box>
        </Box>

    )
}