export const PricingComponent = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98483">
            <g data-name="Group 98460">
                <g data-name="Group 98459">
                    <path
                        data-name="Path 95030"
                        d="M19.48 8h-4.912a1.174 1.174 0 0 0-.83.344l-6.394 6.393a1.174 1.174 0 0 0 0 1.66l4.913 4.913a1.173 1.173 0 0 0 1.659 0l6.394-6.394a1.176 1.176 0 0 0 .344-.831V9.176A1.173 1.173 0 0 0 19.48 8Zm-2.053 4.107a.88.88 0 1 1 .88-.88.88.88 0 0 1-.88.88Z"
                        fill={props?.fill ?? "#c1c5cb"}
                    />
                </g>
            </g>
            <g data-name="Group 98462">
                <g data-name="Group 98461">
                    <path
                        data-name="Path 95031"
                        d="M21.826 9.173v5.461a1.019 1.019 0 0 1-.3.722l-6.5 6.5.1.1a1.173 1.173 0 0 0 1.659 0l5.868-5.866a1.172 1.172 0 0 0 .344-.83v-4.911a1.173 1.173 0 0 0-1.171-1.176Z"
                        fill={props?.fill ?? "#c1c5cb"}
                    />
                </g>
            </g>
            <path data-name="Rectangle 52162" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
