import React from "react";
import { withNavBars } from "../../HOCs";
import AmenitiesBooking from "../amenitiesBooking/amenitiesBooking";
import AmenitiesBookingContext from "../amenitiesBooking/amenitiesBookingContext";

class AmenityBoardParent extends React.Component {
    render() {
        return (
            <AmenitiesBookingContext {...this.props} title="Amenity Board" isCommunity={true} fromType="amenityBoard">
                <AmenitiesBooking />;
            </AmenitiesBookingContext>
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AmenityBoardParent, props);