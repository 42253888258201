import { Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { withNamespaces } from "react-i18next";
import FilterIcon from "../../assets/filterIcon";
import { SearchFilter } from "../../components";
import { useStylesInspection } from "./style";
import { FlashOnOutlined } from "@mui/icons-material";



const MoveOutHead = ({ t, handleSearch = () => false, searchText = "", openFilter = () => false, dot = FlashOnOutlined }) => {

    const classes = useStylesInspection()

    return (
        <Box>
            <Grid container className={classes.girdItem}>
                <Grid item md={4}>
                    <SearchFilter placeholder={t("SearchNewRequests")} handleChange={(value) => handleSearch(value)} value={searchText} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                </Grid>
                <Grid item md={8} onClick={() => openFilter()} alignItems={"end"} display={"flex"} justifyContent={"end"}>
                    {dot ?
                        <FilterIcon />
                        : <IconButton
                            size="small"
                            className={classes.filterIconbtn}
                        >
                            <img src="/images/filter.svg" alt="filter" />
                        </IconButton>}
                </Grid>
            </Grid>
        </Box>
    )
}
export default withNamespaces("moveoutInspection")(MoveOutHead);
