import React from "react"

export const Parking = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_111981" data-name="Group 111981" transform="translate(21090 24766)">
      <g id="Rectangle_55756" data-name="Rectangle 55756" transform="translate(-21090 -24766)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path id="icons8-parking-and-1st-floor_1_" data-name="icons8-parking-and-1st-floor (1)" d="M17.05,6.509,13.084,3.385a1.745,1.745,0,0,0-2.165,0L6.953,6.509A2.5,2.5,0,0,0,6,8.473v.533a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V8.473A2.487,2.487,0,0,0,17.05,6.509ZM12.668,8.624a.382.382,0,1,1-.764,0V6.251l-.414.138a.382.382,0,0,1-.242-.725l.917-.306a.382.382,0,0,1,.5.363Zm.215,4.758a.612.612,0,0,1-.611.611h-.611V12.771h.611A.612.612,0,0,1,12.883,13.382ZM18,12.007v4a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1v-4a1,1,0,0,1,1-1H17A1,1,0,0,1,18,12.007Zm-4.355,1.375a1.377,1.377,0,0,0-1.375-1.375h-.993a.382.382,0,0,0-.382.382v2.9a.382.382,0,0,0,.764,0v-.535h.611A1.377,1.377,0,0,0,13.647,13.382Z" transform="translate(-21090 -24764.1)" fill="#98a0ac" />
    </g>
  </svg>

)