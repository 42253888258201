export const Reports = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98469">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 94993"
                    d="M17.085 21.893h-2.024v-1.985H11.04v-.878h4.86l1.107-1.107H11.04v-.877h6.848l1.107-1.107H11.04v-.878h8.833l1.146-1.146V8.553a.553.553 0 1 0-.554.553v.878H9.985V23h11.031v-5.037Zm-6.045-8.817h4.958v.878H11.04Z"
                />
                <path
                    data-name="Path 94994"
                    d="M8.553 9.107h10.589a1.431 1.431 0 0 1 0-1.107H8.553a.553.553 0 0 0 0 1.107Z"
                />
                <path
                    data-name="Path 94995"
                    d="M23 14.512a.554.554 0 0 0-.945-.391l-.391.391.783.783.391-.391a.55.55 0 0 0 .162-.392Z"
                />
                <path
                    data-name="Path 94996"
                    d="m17.141 20.595-.783-.783-.419.419v.783h.783Z"
                />
                <path
                    data-name="Path 94997"
                    d="m16.977 19.192 4.064-4.064.783.782-4.064 4.065Z"
                />
            </g>
            <path data-name="Rectangle 52153" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
