import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AggrementComponentStyles } from "../../agreementDetails/components/styles";

export const Slider = ({ assets }) => {
    const classes = AggrementComponentStyles();
    const [selected, setSelected] = React.useState(0)

    const next = () => {
        if ((assets?.length - 1) > selected) {
            setSelected(selected + 1)
        }
    }

    const previous = () => {
        if (selected !== 0) {
            setSelected(selected - 1)
        }
    }

    React.useEffect(() => {
        setSelected(0)
    }, [assets])

    return (
        <div className={classes.caroselRoot}>
            {/* carosel */}
            <Grid container alignItems="center" p={1}>
                <Grid item xs={1}>

                    <IconButton disabled={selected === 0} onClick={previous} size="small" className={classes.arrowBtn}>
                        <ArrowBackIosIcon style={{ fontSize:"0.75rem" }} />
                    </IconButton>


                </Grid>
                <Grid item xs={10}>
                    {assets[selected] &&
                        < Box >
                            <img
                                src={assets[selected].url}
                                alt=""
                                height="350px"
                                width="100%"
                                style={{ borderRadius: "4px !important", objectFit: "contain" }}
                            />
                        </Box>
                    }
                    {

                        <Typography sx={{ marginTop: "4px" }} textAlign="center" className={classes.title}>{selected + 1}/{assets?.length}</Typography>
                    }

                </Grid>
                <Grid item xs={1}>

                    <IconButton disabled={(assets?.length - 1) > selected ? false : true} onClick={next} size="small" className={classes.arrowBtn}>
                        <ArrowForwardIosIcon style={{ fontSize:"0.75rem" }} />
                    </IconButton>


                </Grid>
            </Grid>
        </div>
    );
};
