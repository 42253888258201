export const Invoices = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98428">
            <g data-name="Group 98417">
                <g data-name="Group 98416">
                    <path data-name="Path 94902" d="M17.5 7v3.75h3.75Z" fill={props?.fill ?? "#c1c5cb"} />
                </g>
            </g>
            <g data-name="Group 98419">
                <g data-name="Group 98418">
                    <path
                        data-name="Path 94903"
                        d="M17.5 11.688a.939.939 0 0 1-.937-.937V7h-5.625a.939.939 0 0 0-.938.938v13.125a.938.938 0 0 0 .938.938h9.375a.938.938 0 0 0 .938-.937v-9.376Zm-4.219-1.817v-.527a.469.469 0 0 1 .938 0v.528a1.927 1.927 0 0 1 .793.374.469.469 0 1 1-.592.727 1.064 1.064 0 0 0-.67-.222c-.508 0-.937.322-.937.7s.429.7.938.7a1.771 1.771 0 0 1 1.874 1.649 1.7 1.7 0 0 1-1.406 1.582v.527a.469.469 0 0 1-.937 0v-.531a1.927 1.927 0 0 1-.794-.378.47.47 0 0 1 .592-.728 1.059 1.059 0 0 0 .67.223c.508 0 .938-.322.938-.7s-.429-.7-.937-.7a1.771 1.771 0 0 1-1.875-1.641 1.7 1.7 0 0 1 1.405-1.583Zm5.625 10.254h-6.562a.469.469 0 0 1 0-.937h6.563a.469.469 0 0 1 0 .938Zm0-1.875h-6.562a.469.469 0 0 1 0-.937h6.563a.469.469 0 0 1 0 .938Zm0-1.875h-1.875a.469.469 0 0 1 0-.937h1.875a.469.469 0 0 1 0 .938Zm0-1.875h-1.875a.469.469 0 0 1 0-.937h1.875a.469.469 0 0 1 0 .938Z"
                        fill={props?.fill ?? "#c1c5cb"}
                    />
                </g>
            </g>
            <path data-name="Rectangle 52129" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
