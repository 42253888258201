export const manual_backup_table_path = [
    "title",
    "created_on",
    "created_by",
    "scheduled_date",
    "status",
    "restore_date",
    "restore_by",
]

export const manualBackupHeading = (t) => {
    return [
        { title: t("Title"), field: "title" },
        { title: t("Created On"), field: "created_on" },
        { title: t("Created By"), field: "created_by" },
        { title: t("Scheduled Date"), field: "scheduled_date" },
        { title: t("Status"), field: "status" },
        { title: t("Restore Date"), field: "restore_date" },
        { title: t("Restore By"), field: "restore_by" },
        { title: "", field: "icon" },
    ]
}

export const manual_backup_table_type = [
    { type: ["text"], name: "title" },
    { type: ["text"], name: "created_on" },
    { type: ["text"], name: "created_by" },
    { type: ["date"], name: "scheduled_date" },
    { type: ["backup_and_restore_status"], name: "status" },
    { type: ["text"], name: "restore_date" },
    { type: ["text"], name: "restore_by" },
    { type: ["manual_backup_option"], icon: "icon" },
]

export const system_backup_table_path = [
    "backup_title",
    "created_by",
    "status",
]

export const systemBackupHeading = (t) => {
    return [
        { title: t("Backup Title"), field: "backup_title" },
        { title: t("Created On"), field: "created_on" },
        { title: t("Status"), field: "status" },
        { title: t("Restore Date"), field: "restore_date" },
        { title: t("Restore By"), field: "restore_by" },
        { title: "", field: "icon" },
    ]
}

export const system_backup_table_type = [
    { type: ["text"], name: "backup_title" },
    { type: ["text"], name: "created_on" },
    { type: ["backup_and_restore_status"], name: "status" },
    { type: ["text"], name: "restore_date" },
    { type: ["text"], name: "restore_by" },
    { type: ["system_backup_option"], icon: "icon" },
]