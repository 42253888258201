import { Box, Button } from "@mui/material";
import React from "react";
import { DetailsCardCoponent, LoadingSection } from "../../../components";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { NetWorkCallMethods } from "../../../utils";
import { AssetView } from "./assetView";
import { ResourceMasterComponentStyles } from "./styles";

export const ViewResources = ({ data = "", onEdit = () => false, name = "", selected = {}, permissions = {}, t }) => {
    const classes = ResourceMasterComponentStyles();
    const [details, setDetails] = React.useState({});
    const [loading, setLoading] = React.useState(true)
    const getDetails = () => {
        const payload = {
            id: selected?.id
        }
        NetworkCall(
            `${config.api_url}/resources/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            setDetails(res?.data)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    React.useEffect(() => {
        getDetails()
        // eslint-disable-next-line
    }, []);


    const leaves = details?.leaves?.map((val) => {
        return {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: val?.name,
            subtitle: `${val?.days_count} ${t("Days")}`
        }
    })

    const card = [
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: t("Resource Type"),
            subtitle: `${details?.type_master?.name}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: t("Resource Name"),
            subtitle: `${name}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: true,
            component: "text",
            heading: t("Resource ID"),
            subtitle: `${details?.reference_id}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.departments?.label,
            component: "text",
            heading: t("Department"),
            subtitle: `${details?.departments?.label}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.jobs?.label,
            component: "text",
            heading: t("Job"),
            subtitle: `${details?.jobs?.label}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.profession?.label,
            component: "text",
            heading: t("Profession"),
            subtitle: `${details?.profession?.label}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.module?.label,
            component: "text",
            heading: t("Module Assigned"),
            subtitle: `${details?.module?.label}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.vehicle_inventories?.serial_number,
            component: "text",
            heading: t("Vehicle Number"),
            subtitle: `${details?.vehicle_inventories?.serial_number}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.tool_inventories?.serial_number,
            component: "text",
            heading: t("Serial Number"),
            subtitle: `${details?.tool_inventories?.serial_number}`
        },
        // {
        //     size: {
        //         xs: 12,
        //         sm: 12,
        //         md: 4,
        //         lg: 4
        //     },
        //     isActive: details?.contact?.contact_no,
        //     component: "text",
        //     heading: "Contact ID",
        //     subtitle: `${details?.contact?.contact_no}`
        // },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.account?.label,
            component: "text",
            heading: t("Account ID"),
            subtitle: `${details?.account?.label}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.hours?.lable,
            component: "text",
            heading: t("Working Calendar"),
            subtitle: `${details?.hours?.lable}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.rate_per_hour,
            component: "text",
            heading: t("Hours Rate"),
            subtitle: `${details?.currency?.symbol} ${details?.rate_per_hour}`
        },

        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.daily_rate,
            component: "text",
            heading: t("Daily Rate Day"),
            subtitle: `${details?.currency?.symbol} ${details?.daily_rate}`
        },
        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.reporting?.label,
            component: "text",
            heading: t("Reporting To"),
            subtitle: `${details?.reporting?.label}`
        },

        {
            size: {
                xs: 12,
                sm: 6,
                md: 4,
                lg: 4
            },
            isActive: details?.period,
            component: "text",
            heading: t("Period"),
            subtitle: `${details?.period}`
        },
        {
            size: { xs: 12, sm: 6, md: 4, lg: 4, },
            isActive: details?.function_locations?.label,
            component: "text",
            heading: t("Functional Location"),
            subtitle: `${details?.function_locations?.label}`
        },

    ]

    return (
        <Box>
            {
                loading ?
                    <LoadingSection top="30vh" bottom="30vh" />
                    :
                    <Box>
                        <Box p={2}>
                            {
                                details?.assets?.length > 0 ?
                                    <AssetView
                                        asset={details?.assets}
                                    />
                                    :
                                    <img src="/images/propertytumb.png" alt="" className={classes.image} />
                            }

                        </Box>
                        <DetailsCardCoponent components={leaves?.length > 0 ? [...card, ...leaves] : card} />
                        <Box display="flex" className={`${classes.btngrp}`} p={2}>
                            {/* <Button variant="outlined" className={`${classes.btn_outlined}`}>Delete</Button> */}
                            {
                                permissions?.update &&
                                <Button variant="contained" className={`${classes.btn_contained}`} onClick={() => onEdit(details?.id)}>{t("Edit")}</Button>
                            }


                        </Box>
                    </Box>
            }

        </Box>
    )
}