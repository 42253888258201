import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from "@mui/material";
import { useStyles } from "./style";
export const PaymentSelect = ({ img = "", selected = null, id = "", onClick = () => false }) => {
    const classes = useStyles()
    return (
        <Box onClick={() => onClick("type", id)} className={selected === id ? classes.paymetRootSelected : classes.paymetRoot}>
            {
                selected === id && <CheckCircleIcon className={classes.checked} />
            }

            <img src={img} alt="" />
        </Box>
    )
}