import * as React from "react"
const CaretDown = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={9} height={6.188} {...props}>
    <path
      fill="#98a0ac"
      d="M8.578 0H.422a.422.422 0 0 0-.336.678l4.078 5.344a.422.422 0 0 0 .671 0L8.913.678A.422.422 0 0 0 8.578 0Z"
      data-name="icons8-sort-down (1)"
    />
  </svg>
)
export default CaretDown
