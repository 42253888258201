import { Box, IconButton, Stack, Typography, Grid } from "@mui/material";
import React from "react";
import ShortListIMG from "../../../assets/shortlistImg";
import { filterStyles } from "./styles";
import { withNamespaces } from "react-i18next";
const CompactViewCard = (props) => {
    const { t } = (props);

    const classes = filterStyles();
    return (
        <Grid container  className={classes.UnitCardroot} sx={{padding:"12px 12px 8px 12px !important"}}>
            
            <Grid item xs={12} sx={{position:"relative",paddingLeft:"8px"}}>
            {
                    !props?.noBtn &&
                    <>
                        {
                            props?.delete ?
                                <IconButton className={classes.shortlistBTN} onClick={props?.onDelete} size="small">
                                    <img src="/images/delete.svg" alt="" height="10px" />
                                </IconButton> :
                                props?.data?.room_rent &&

                                <IconButton className={props?.shortlistid?.some((val) => val === props?.data?.id) ? classes.shortlistBTNactive : classes.shortlistBTN} sx={{backgroundColor: props?.shortlistid?.some((val) => val === props?.data?.id) ?"auto": "#F5F7FA !important"}} onClick={() => props?.handleShortlist(props?.data?.id)} size="small">
                                    <ShortListIMG color={props?.shortlistid?.some((val) => val === props?.data?.id) ? "white" : "#98a0ac"} />
                                </IconButton>
                        }
                    </>
                }

                {props?.data?.pricing?.some((val) => val?.discountValue > 0) &&
                    <Typography className={classes.discountAppliedRoot}>% {t("Discount Applied")}</Typography>
                }

                <Stack direction="row" alignItems={"center"} spacing={1}>
                <Typography className={classes.unitName}>{props?.data?.name}</Typography>
                <Typography className={classes.pricing} >&nbsp;{props?.currency ?? ""} {props?.data?.room_rent?.toFixed(2)??0}&nbsp;</Typography>
                </Stack>

               
                <Stack direction="row" alignItems="center" marginTop="4px">
                    {props?.data?.unit_category && <Typography className={classes.build1}>{props?.data?.unit_category}</Typography>}
                    {props?.data?.unit_categoryByID?.id && <Typography className={classes.build1}>{props?.data?.unit_categoryByID?.name}</Typography>}
                    <Box className={classes.dot} />
                    <Typography className={classes.build1} noWrap>{props?.data?.unit_type}&nbsp;</Typography>
                    {
                        props?.data?.unit_type && <Box className={classes.dot} />
                    } 
                    <Typography noWrap className={classes.build1}>{props?.data?.total_area}&nbsp;{props?.data?.uom_master?.name ?? props?.data?.uom_name}</Typography>
                    <Box className={classes.dot} />
                    <Typography className={classes.viewdetail} 
                            sx={{marginTop:"4px"}}
                            onClick={() => {
                                props?.onClick(props?.data?.id)
                                if (props?.quotation) {
                                    props?.onSelectedUnit(props?.data)
                                }
                            }}>
                            {props?.quotation ? t("Edit Pricing") : t("View Details")}
                        </Typography>

            </Stack >
            
               
        </Grid>

    
           
        </Grid >
    )
}
export default withNamespaces("shortListUnits")(CompactViewCard)
