export const InventoryMaster = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <defs>
            <style>{".cls-1{fill:#c1c5cb}"}</style>
        </defs>
        <g
            id="Group_102123"
            data-name="Group 102123"
            transform="translate(24805 -3923)"
        >
            <g id="_x31_3" transform="translate(-24800 3928)">
                <path
                    id="Path_96812"
                    data-name="Path 96812"
                    className="cls-1"
                    d="m17.668 6.572-7.429-4.519a.574.574 0 0 0-.479 0L2.332 6.572a.572.572 0 0 0 .239 1.09h14.858a.572.572 0 0 0 .239-1.09Z"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96813"
                    data-name="Path 96813"
                    className="cls-1"
                    d="M10.143 20.131 7 18.559v3.657l3.143 1.562Z"
                    transform="translate(-2.143 -7.097)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96814"
                    data-name="Path 96814"
                    className="cls-1"
                    d="m13.5 23.778 3.143-1.562v-3.657L13.5 20.131Z"
                    transform="translate(-4.929 -7.097)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96815"
                    data-name="Path 96815"
                    className="cls-1"
                    d="m13.771 16.592-3.085-1.533L7.6 16.592l3.085 1.542Z"
                    transform="translate(-2.401 -5.596)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
                <path
                    id="Path_96816"
                    data-name="Path 96816"
                    className="cls-1"
                    d="M4 22.143a.571.571 0 0 0 .571.571h12.572a.571.571 0 0 0 .571-.571V13H4Zm10-4.571h1.6l-.655-.655a.286.286 0 0 1 .4-.4l1.143 1.143a.286.286 0 0 1 0 .4l-1.143 1.14a.286.286 0 0 1-.4-.4l.655-.655H14a.286.286 0 1 1 0-.571Zm-8.857-1.715a.571.571 0 0 1 .316-.511l3.429-1.714a.572.572 0 0 1 .511 0l3.429 1.714a.571.571 0 0 1 .316.511v4a.571.571 0 0 1-.316.511L9.4 22.083a.572.572 0 0 1-.511 0l-3.43-1.715a.571.571 0 0 1-.316-.511Z"
                    transform="translate(-.857 -4.714)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
            <path
                id="Rectangle_52494"
                data-name="Rectangle 52494"
                transform="translate(-24805 3923)"
                style={{
                    fill: "none",
                }}
                d="M0 0h30v30H0z"
            />
        </g>
    </svg>
)