import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SearchFilter, UseDebounce } from '../../components'
import { NewLoader } from '../../components/newLoader'
import { PopupCard } from './components/popupCard'
import { CreateEditContext } from './create/createEditContext'
import { PopupStyle } from './style'

export const OwnerPopup = ({selected={} , t}) => {
    const classes = PopupStyle()
    const {
        ownerList,
        getOwnerList = () => false,
        setOwnerPopup,
        updateState,
        contractData,
        ownerLoading } = React.useContext(CreateEditContext)

    const [searchText, setSearchText] = React.useState("")
    const [offset, setOffset] = React.useState(0)
    const [selectedOwner, setSelectedOwner] = React.useState({})

    const debounce = UseDebounce();

    React.useEffect(() => {
        setSelectedOwner(contractData?.account ?? {})
        // if(Object.keys(contractData?.account)?.length === 0){
            getOwnerList(0, "", true)
        // }
        // eslint-disable-next-line
    }, [])



    const searchFunction = (e) => {
        setSearchText(e)
        debounce(() => getOwnerList(0, e, true), 800)
    }

    //fetch more
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getOwnerList(offset + 10, "", false);
    };

    const saveList = (value) => {
        setSelectedOwner(value)
    }

    const add = () => {
        updateState('account', selectedOwner)
        setOwnerPopup(false)
    }

    React.useEffect(() => {
        setSelectedOwner(contractData?.account)
    }, [contractData?.account])

    return (
        <>
            <Stack m={2}>
                <SearchFilter
                    value={searchText}
                    handleChange={(e) => searchFunction(e)}
                    placeholder={t("Search")} />
                {
                    ownerLoading ?
                        <Box sx={{ height: "600px" }}>
                            <NewLoader minusHeight={"450px"} />
                        </Box>
                        :
                        <Box mt={1}>
                            {
                                ownerList?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={ownerList.length}
                                        next={fetchMoreData}
                                        hasMore={true}
                                        height={`calc(100vh - 290px)`}
                                    >
                                        {
                                            ownerList?.map((e) => {
                                                return (
                                                    <PopupCard img_url={e?.logo}
                                                        title={e?.name}
                                                        subtitle={`${e?.account_no} ${e?.mobile_no ? e?.mobile_no : "-"}`}
                                                        data={e}
                                                        handleClick={saveList}
                                                        selected={selectedOwner}
                                                        type={"account"} />
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                                    :
                                    <Box className={classes.noData}>
                                        <Typography>{t("No Data Found")}</Typography>
                                    </Box>
                            }
                        </Box>
                }
                <Box pt={2}>
                    <Button fullWidth
                        variant='contained'
                        disabled={selectedOwner?.id?.length > 0 ? false : true}
                        onClick={() => add()}>{t("Add")}</Button>
                </Box>
            </Stack>
        </>
    )
}