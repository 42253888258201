import React from "react";
import { withNavBars } from "../../HOCs";
import CreateReceipt from "./receiptCreate";

class CreateReceiptParent extends React.Component {
    render() {
        return <CreateReceipt />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CreateReceiptParent, props);
