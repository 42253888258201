import React from "react";
import { withNavBars } from "../../HOCs";
import { BlockDetails } from "./block";

class BlockDetailsParent extends React.Component {
  render() {
    return <BlockDetails />;
  }
}

export default withNavBars(BlockDetailsParent);
