export const ReceiptHistoryHeading = [
    { title: "Tagged Date", field: "created_at" },
    { title: "Receipt Number", field: "reciepts_no" },
    { title: "Settlement No", field: "settlement_no"},
    { title: "Source", field: "source"},
    { title: "Tagged By", field: "tagged_by" },
    { title: "Amount", field: "amount_tagged"},
    
]


