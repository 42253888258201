import React from "react";
import { withNavBars } from "../../HOCs";
import COAMaster from "./charterOfAccountsMaster";

class COAMasterParent extends React.Component {
  render() {
    return <COAMaster {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(COAMasterParent, props);
