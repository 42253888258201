import React from "react";
import { LogoApperance } from "../clientSettings/tabs";
import { withNamespaces } from "react-i18next";






const LogoApperances = (props) => {
    const { t = () => false } = props
    return (
        <LogoApperance
            {...props}
            selected={{
                label: t("Logos & Appearances"),
                subtitle: "Logos upload and its visual appearance in interface screen"
            }} />
    )
}

export default withNamespaces("clientSettings")(LogoApperances);
