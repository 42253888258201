import * as React from "react"
const PercentageArabic = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...props}>
    <path
      fill="#fc0"
      d="M61.209 33.226A26.908 26.908 0 1 1 34.301 6.318a26.908 26.908 0 0 1 26.908 26.908"
    />
    <path
      fill="#fcdc59"
      d="M54.889 15.907a26.907 26.907 0 0 0-45.98 26.226q22.761-13.515 45.98-26.226M37.412 59.945a26.821 26.821 0 0 0 15.888-7.67c-4.2 2.019-8.392 4.062-12.588 6.092-1.1.531-2.2 1.051-3.3 1.578"
    />
    <path
      fill="#da8100"
      d="M54.336 32.8a20.456 20.456 0 1 1-20.46-20.456A20.455 20.455 0 0 1 54.336 32.8"
    />
    <path
      fill="#f0aa00"
      d="M54.908 33.653a20.181 20.181 0 1 1-20.181-20.182 20.18 20.18 0 0 1 20.181 20.182"
    />
    <path
      fill="#78b1fe"
      d="m3.368 32.375 1.191 2.177 2.177 1.191-2.177 1.19-1.19 2.177-1.192-2.177L0 35.743l2.177-1.191Z"
    />
    <path
      fill="#78b1fe"
      d="m9.558 10.63.6 1.106 1.108.606-1.108.606-.6 1.11-.606-1.108-1.108-.606 1.104-.608Z"
      opacity={0.5}
    />
    <path
      fill="#78b1fe"
      d="m53.3 4 1.172 2.144 2.143 1.172-2.143 1.172-1.172 2.143-1.172-2.143-2.143-1.173 2.143-1.172ZM55.71 55.137l.851 1.556 1.549.851-1.556.851-.844 1.556-.851-1.556-1.556-.851 1.556-.851Z"
    />
    <path
      fill="#78b1fe"
      d="m65.562 34.148.564 1.031 1.031.564-1.031.564-.564 1.031-.564-1.031-1.031-.564 1.031-.564ZM15.016 59.951l.564 1.031 1.031.564-1.031.564-.564 1.031-.564-1.031-1.031-.564 1.031-.564ZM65.561 22.587l.194.354.354.194-.354.194-.194.354-.194-.354-.355-.194.355-.194Z"
      opacity={0.5}
    />
    <path
      fill="#78b1fe"
      d="m2.819 22.587.194.354.355.194-.355.194-.194.354-.194-.354-.354-.194.355-.194Z"
    />
    <path
      fill="#ea9c00"
      d="M26.214 27a4.076 4.076 0 0 1 1.232-3.08 4.468 4.468 0 0 1 3.229-1.182 4.538 4.538 0 0 1 3.257 1.168 4.154 4.154 0 0 1 1.232 3.165v1.02a4.025 4.025 0 0 1-1.232 3.073 4.515 4.515 0 0 1-3.229 1.161 4.544 4.544 0 0 1-3.25-1.173 4.124 4.124 0 0 1-1.239-3.151Zm2.719 1.09a1.908 1.908 0 0 0 .488 1.362 1.682 1.682 0 0 0 1.281.517 1.6 1.6 0 0 0 1.26-.524 2.028 2.028 0 0 0 .467-1.4v-1.048a1.986 1.986 0 0 0-.467-1.374 1.638 1.638 0 0 0-1.289-.524 1.642 1.642 0 0 0-1.26.517 2.027 2.027 0 0 0-.48 1.436Zm7.136 10.507a4.024 4.024 0 0 1 1.246-3.08 4.53 4.53 0 0 1 3.229-1.165 4.577 4.577 0 0 1 3.25 1.154 4.131 4.131 0 0 1 1.239 3.176v1.02a4.054 4.054 0 0 1-1.218 3.065 4.488 4.488 0 0 1-3.246 1.168 4.542 4.542 0 0 1-3.267-1.175 4.106 4.106 0 0 1-1.233-3.115Zm2.719 1.1a1.893 1.893 0 0 0 .51 1.331 1.662 1.662 0 0 0 1.274.552q1.728 0 1.728-1.912v-1.071a1.924 1.924 0 0 0-.481-1.366 1.659 1.659 0 0 0-1.274-.517 1.659 1.659 0 0 0-1.274.517 1.985 1.985 0 0 0-.481 1.409Zm-7.278 2.412-2-1.076 10.071-16.115 2 1.076Z"
    />
    <path
      fill="#fc0"
      d="M24.931 27a4.076 4.076 0 0 1 1.232-3.08 4.468 4.468 0 0 1 3.229-1.182 4.538 4.538 0 0 1 3.257 1.168 4.154 4.154 0 0 1 1.232 3.165v1.02a4.025 4.025 0 0 1-1.232 3.073 4.515 4.515 0 0 1-3.229 1.161 4.544 4.544 0 0 1-3.25-1.173 4.124 4.124 0 0 1-1.239-3.151Zm2.719 1.09a1.908 1.908 0 0 0 .488 1.362 1.682 1.682 0 0 0 1.281.517 1.6 1.6 0 0 0 1.26-.524 2.028 2.028 0 0 0 .467-1.4v-1.048a1.986 1.986 0 0 0-.467-1.374 1.638 1.638 0 0 0-1.289-.524 1.642 1.642 0 0 0-1.26.517 2.027 2.027 0 0 0-.48 1.436Zm7.136 10.507a4.024 4.024 0 0 1 1.246-3.08 4.53 4.53 0 0 1 3.229-1.165 4.577 4.577 0 0 1 3.25 1.154 4.131 4.131 0 0 1 1.239 3.176v1.02a4.054 4.054 0 0 1-1.218 3.065 4.488 4.488 0 0 1-3.246 1.168 4.542 4.542 0 0 1-3.267-1.175 4.106 4.106 0 0 1-1.233-3.115Zm2.719 1.1a1.893 1.893 0 0 0 .51 1.331 1.662 1.662 0 0 0 1.274.552q1.728 0 1.728-1.912v-1.071a1.924 1.924 0 0 0-.481-1.366 1.659 1.659 0 0 0-1.274-.517 1.659 1.659 0 0 0-1.274.517 1.985 1.985 0 0 0-.481 1.409Zm-7.278 2.412-2-1.076 10.071-16.115 2 1.076Z"
    />
  </svg>
)
export default PercentageArabic
