import React from "react";
import { withNavBars } from "../../HOCs";
import { ContactList } from "./contactList";

class ContactListParent extends React.Component {
  render() {
    return <ContactList />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(ContactListParent, props);
