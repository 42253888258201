import React from "react";
import { withNavBars } from "../../HOCs";
import AmenityDashBoard from "./amenityDashboard";

class AmenityDashboardParent extends React.Component {
    render() {
        return (
            <AmenityDashBoard />
        )
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(AmenityDashboardParent, props);