export const EmergencyNumber = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98403">
            <g data-name="Group 8127">
                <path data-name="Rectangle 31" fill="none" d="M0 0h30v30H0z" />
                <g fill={props?.fill ?? "#c1c5cb"}>
                    <path d="m16.23 19.038-5.269-5.27 2.191-2.188-3.27-3.27-2.076 2.07a2.76 2.76 0 0 0 0 3.9l7.911 7.911a2.757 2.757 0 0 0 3.9 0l2.076-2.076-3.27-3.27ZM19.424 13.856h.936V9.84h-1.593v.936h.658ZM21.36 9.84v.936h.659v3.076h.936V9.84ZM16.757 12.559a1.353 1.353 0 0 0 .387-.056.7.7 0 0 1-.663.48h-.468v.936h.468a1.636 1.636 0 0 0 1.634-1.634v-1.177a1.357 1.357 0 1 0-1.354 1.451Zm0-1.779a.422.422 0 1 1-.422.422.422.422 0 0 1 .422-.422Z" />
                    <path d="m13.218 14.703.668-.668.662.662-.668.668ZM14.478 15.964l.668-.668.662.662-.668.668ZM19.392 15.897v.936a1.652 1.652 0 0 1 1.65 1.65h.936a2.589 2.589 0 0 0-2.586-2.586ZM13.15 10.586h.936A2.589 2.589 0 0 0 11.5 8v.936a1.652 1.652 0 0 1 1.65 1.65Z" />
                </g>
            </g>
        </g>
    </svg>
)
