import * as React from "react"

const targetIMG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11.495}
        height={11.5}
        {...props}
    >
        <path
            d="M5.741.25a.375.375 0 0 0-.369.38v.894a4.253 4.253 0 0 0-3.854 3.854H.625a.375.375 0 1 0 0 .75h.894a4.253 4.253 0 0 0 3.853 3.854v.89a.375.375 0 1 0 .75 0v-.89a4.253 4.253 0 0 0 3.854-3.854h.894a.375.375 0 1 0 0-.75h-.894a4.253 4.253 0 0 0-3.854-3.854V.631a.375.375 0 0 0-.381-.38Zm-.038 2.007a.375.375 0 0 0 .091 0 3.49 3.49 0 0 1 3.449 3.451.375.375 0 0 0 0 .091 3.49 3.49 0 0 1-3.451 3.449.375.375 0 0 0-.05 0h-.041A3.49 3.49 0 0 1 2.25 5.797a.375.375 0 0 0 0-.091 3.49 3.49 0 0 1 3.453-3.449Zm.047 2.247a1.247 1.247 0 0 0-.936.4 1.31 1.31 0 0 0 0 1.7 1.3 1.3 0 0 0 1.872 0 1.31 1.31 0 0 0 0-1.7 1.247 1.247 0 0 0-.936-.4Zm0 .75a.45.45 0 0 1 .376.147.563.563 0 0 1 0 .7.45.45 0 0 1-.376.147.45.45 0 0 1-.376-.147.563.563 0 0 1 0-.7.45.45 0 0 1 .376-.148Z"
            fill="#98a0ac"
            stroke="#98a0ac"
            strokeWidth={0.5}
        />
    </svg>
)

export default targetIMG
