import React from "react"

export const UnitHandOverIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_110226" data-name="Group 110226" transform="translate(-184 -328)">
    <rect id="Rectangle_55241" data-name="Rectangle 55241" width="32" height="32" rx="2" transform="translate(184 328)" fill="#ddecff"/>
    <g id="Group_111487" data-name="Group 111487" transform="translate(0 -1)">
      <g id="Rectangle_56608" data-name="Rectangle 56608" transform="translate(188 333)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-lease_1_" data-name="icons8-lease (1)" d="M13.273,2a2.546,2.546,0,0,0-2.491,3.068L9.016,6.834a.363.363,0,0,0-.107.257V8.545a.364.364,0,0,0,.364.364h1.455a.364.364,0,0,0,.364-.364V7.818h.727a.364.364,0,0,0,.364-.364V6.843A2.544,2.544,0,1,0,13.273,2Zm.364,2.909a.727.727,0,1,1,.727-.727A.727.727,0,0,1,13.636,4.909Zm3.407,4.364a4.522,4.522,0,0,0-1.653.7c-.721.375-1.388.731-1.812.99a2.175,2.175,0,0,1-2.123,2.676H8.727a.545.545,0,0,1,0-1.091h2.727a1.087,1.087,0,0,0,.716-1.907,1.623,1.623,0,0,0-.9-.275H9.455c-.011,0-.02,0-.031,0a7.977,7.977,0,0,1-1.824-.2A5.306,5.306,0,0,0,6.3,10c-2.666,0-4.188,3.273-4.252,3.412a.545.545,0,0,0,.057.55l2.545,3.452a.542.542,0,0,0,.438.22.547.547,0,0,0,.529-.407,1.37,1.37,0,0,1,1.29-1.045,17.7,17.7,0,0,1,2.154.18,17.936,17.936,0,0,0,2.209.184c1.005,0,1.728-.771,3.818-2.909C17.746,10.92,18,10.682,18,10.143A.914.914,0,0,0,17.043,9.273Z" transform="translate(190 334.999)" fill="#78b1fe"/>
    </g>
  </g>
</svg>



)