import { Box, Typography } from "@mui/material";
import React from 'react';
import { TableWithPagination } from "../../components";
import { LeadHeading, LeadPath, LeadType } from "../../utils/propspect/tableUtils";
import { contactsViewStyles } from "./contactsViewStyle";


export const Opportunities = (props) => {
    const { t } = (props);
    const classes = contactsViewStyles()
    return (
        <Box className={classes.boxTab}>
            {props?.data?.length > 0 ?
                <TableWithPagination
                    heading={LeadHeading(t)}
                    rows={props?.data ?? []}
                    path={LeadPath}
                    showpdfbtn={false}
                    showexcelbtn={false}
                    showSearch={false}
                    tableType="no-side"
                    dataType={LeadType}
                    view={true}
                    edit={true}
                    delete={true} />
                :
                <Typography className={classes.noData} >{t("No data found")}</Typography>
            }
        </Box>

    )

}