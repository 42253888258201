import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: `calc(100vh - 65px)`
    },
    button: {
        borderRadius: theme.palette.borderRadius,
        height: "45px",
        border: "1px solid #5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#5078E1 ",
        },
        color: "white"
    }
}))