import * as React from "react"
const TransactionTypeLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} {...props}>
    <g data-name="Group 115711">
      <path
        fill="#f1f6ff"
        d="M4.75 0h28.5A4.75 4.75 0 0 1 38 4.75v28.5A4.75 4.75 0 0 1 33.25 38H4.75A4.75 4.75 0 0 1 0 33.25V4.75A4.75 4.75 0 0 1 4.75 0Z"
        data-name="Path 100578"
      />
      <g data-name="Group 115360" transform="translate(8 8.958)">
        <path
          fill="#a1c56a"
          d="M20.326 1.07A1.069 1.069 0 0 0 19.256 0H1.07A1.069 1.069 0 0 0 0 1.07v13.907a1.069 1.069 0 0 0 1.07 1.07h18.186a1.069 1.069 0 0 0 1.07-1.07Z"
          data-name="Path 100822"
        />
        <path
          fill="#588218"
          d="M17.383 5.343H2.941a.268.268 0 0 1-.267-.263V4.01a.268.268 0 0 1 .267-.267h14.442a.268.268 0 0 1 .267.267v1.07a.268.268 0 0 1-.267.263Z"
          data-name="Path 100823"
        />
        <path
          fill="#588218"
          d="M17.384 8.022h-1.07a.268.268 0 0 1-.267-.27v-.535a.268.268 0 0 1 .267-.265h1.07a.268.268 0 0 1 .267.267v.533a.268.268 0 0 1-.267.27Z"
          data-name="Path 100824"
        />
        <path
          fill="#588218"
          d="M13.639 8.022h-10.7a.268.268 0 0 1-.265-.27v-.535a.268.268 0 0 1 .267-.265h10.7a.268.268 0 0 1 .267.267v.533a.268.268 0 0 1-.269.27Z"
          data-name="Path 100825"
        />
        <path
          fill="#057093"
          d="M17.384 10.162h-1.07a.268.268 0 0 1-.267-.27v-.535a.268.268 0 0 1 .267-.265h1.07a.268.268 0 0 1 .267.267v.533a.268.268 0 0 1-.267.27Z"
          data-name="Path 100826"
        />
        <path
          fill="#588218"
          d="M12.574 10.162H2.941a.268.268 0 0 1-.267-.27v-.535a.268.268 0 0 1 .267-.265h9.633a.268.268 0 0 1 .267.267v.533a.268.268 0 0 1-.267.27Z"
          data-name="Path 100827"
        />
        <path
          fill="#057093"
          d="M17.384 11.233h-1.07a.268.268 0 0 1-.267-.27v-.535a.268.268 0 0 1 .267-.265h1.07a.268.268 0 0 1 .267.267v.533a.268.268 0 0 1-.267.27Z"
          data-name="Path 100828"
        />
        <path
          fill="#588218"
          d="M13.639 12.302h-10.7a.268.268 0 0 1-.265-.27v-.535a.268.268 0 0 1 .267-.265h10.7a.268.268 0 0 1 .267.267v.533a.268.268 0 0 1-.269.27Z"
          data-name="Path 100829"
        />
        <circle
          cx={5.884}
          cy={5.884}
          r={5.884}
          fill="#e2ae24"
          data-name="Ellipse 130020"
          transform="translate(11.233 9.093)"
        />
        <circle
          cx={4.707}
          cy={4.707}
          r={4.707}
          fill="#ffc429"
          data-name="Ellipse 130021"
          transform="translate(12.409 10.27)"
        />
        <path
          fill="#8d6c16"
          d="M15.803 17.145v-.877a1.912 1.912 0 0 0 1.017.293.954.954 0 0 0 .635-.193.651.651 0 0 0 .229-.525q0-.692-.978-.692a6.111 6.111 0 0 0-.77.058v-2.6h2.579v.838h-1.7v.932q.2-.019.387-.019a1.589 1.589 0 0 1 1.124.377 1.317 1.317 0 0 1 .406 1.014 1.5 1.5 0 0 1-.484 1.151 1.859 1.859 0 0 1-1.313.447 2.808 2.808 0 0 1-1.132-.204Z"
          data-name="Path 100831"
        />
      </g>
    </g>
  </svg>
)
export default TransactionTypeLogo
