import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Pagination,
  Typography,
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Stack } from "@mui/system";
import React from "react";
import NoDataFound from "../../../assets/noData";
import {
  GroupPieCharts,
  SelectBox,
  TableWithInfinityScroll,
  TableWithPagination,
} from "../../../components";
import PieCharts from "../../../components/piechart";
import { useStylesCreation } from "./style";
import { withNamespaces } from "react-i18next";
import { Bold, isBarDatasets, isFunnelGraph } from "../../../utils";
import { NewLineChart } from "../../../components/newLineChart";
import { FunnelChart } from "../../salesManagerDashboard/components/funnelChart";
import { HighChartsPie } from "../../salesManagerDashboard/components/highChartsPie.js";
import { VariablePieChart } from "../../salesManagerDashboard/components/variableRadiusPie.js";
import { HighChartsBar } from "../../salesManagerDashboard/components/highChartsBar.js";
import { HighChartsFunnel } from "../../salesManagerDashboard/components/highChartsFunnel.js";
import { HighChartsSemiCircle } from "../../salesManagerDashboard/components/highChartsSemiCircle.js";
import { StackedSmoothLineChartHighcharts } from "../../salesManagerDashboard/components/highChartsLine.js";
import { LineBarChart } from "../../leaseandsalesmanagerdashboard/component/lineBar.js";
import { RaceBarChart } from "../../leaseandsalesmanagerdashboard/component/raceBarChart.js";
import HighChartPlainLine from "../../salesManagerDashboard/components/highChartsPlainLine.js";
import { GaugeBar } from "../../salesManagerDashboard/components/gaugebar.js";

const GraphComponent = (props) => {
  const graph_data_total =
    props?.graph_data_total > -1 ? props?.graph_data_total : "";
  const { t } = props;
  const classes = useStylesCreation(props);
  const handleIcon = (type, data) => {
    if (["view", "double_click"]?.includes(type)) {
      props?.occupancyNavigate(data?.id, data);
    }
  };

  return (
    <div className={classes.card}>
      <Box
        className={classes.flexBoxWithSpace}
        style={{ ...props?.title_div_style }}
      >
        {props?.isTab ? (
          <Box>
            <TabComponent
              tabList={props?.tabList}
              tabNameState={props?.tabNameState}
              keyType={props?.keyType}
              updateState={props?.updateState}
            />
          </Box>
        ) : props?.isSelect ? (
          <Stack
            direction={"row"}
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {props?.isZoom && (
              <IconButton
                sx={{ padding: "0px" }}
                onClick={() => props?.onZoomClick(props?.title)}
              >
                <img
                  src="/images/propertdashboard/enlare.png"
                  alt="zoom"
                  onClick={() => props?.onZoomClick(props?.title)}
                />
              </IconButton>
            )}
            <Typography className={classes.titleFont}>
              {props?.header}
            </Typography>
            <Box flexGrow={1} />
            <SelectBox
              menuPlacement={"bottom"}
              placeholder={t("Select")}
              value={props?.value}
              isPaginate
              fontFamily={Bold}
              debounceTimeout={800}
              loadOptions={props?.loadOptions}
              options={props?.options}
              iconColor="black"
              onChange={(val) => props?.handleChange(val)}
            />
          </Stack>
        ) : props?.select ? (
          <Stack
            direction={"row"}
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {props?.isZoom && (
              <IconButton
                sx={{ padding: "0px" }}
                onClick={() => props?.onZoomClick(props?.title)}
              >
                <img
                  src="/images/propertdashboard/enlare.png"
                  alt="zoom"
                  onClick={() => props?.onZoomClick(props?.title)}
                />
              </IconButton>
            )}
            <Typography className={classes.titleFont}>
              {props?.header}
            </Typography>
            <Box flexGrow={1} />
            <SelectBox
              menuPlacement={"bottom"}
              placeholder={t("Select")}
              value={props?.selectValue}
              noSearch={true}
              fontFamily={Bold}
              // isPaginate={true}
              iconColor="black"
              selectHeight="35px"
              closeIcon={false}
              onChange={(val) => props?.handleChange(val)}
              options={props?.options}
            />
          </Stack>
        ) : props?.is_async_select ? (
          <Stack
            direction={"row"}
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {props?.isZoom && (
              <IconButton
                sx={{ padding: "0px" }}
                onClick={() => props?.onZoomClick(props?.title)}
              >
                <img
                  src="/images/propertdashboard/enlare.png"
                  alt="zoom"
                  onClick={() => props?.onZoomClick(props?.title)}
                />
              </IconButton>
            )}
            <Typography className={classes.titleFont}>
              {props?.header}
            </Typography>
            <Box flexGrow={1} />
            <SelectBox
              menuPlacement={"bottom"}
              key={props?.async_select?.key}
              placeholder={props?.async_select?.placeholder}
              selectHeight="35px"
              width="100%"
              fontFamily={Bold}
              value={props?.async_select?.value}
              onChange={(value) => props?.async_select?.onChange(value)}
              loading={props?.async_select?.loading}
              debounceTimeout={800}
              isPaginate
              iconColor="black"
              loadOptions={(search, array) =>
                props?.async_select?.loadOptions(search, array)
              }
            />
          </Stack>
        ) : (
          <Box>
            <Stack direction={"row"} alignItems={"center"}>
              {props?.isZoom && (
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={() => props?.onZoomClick(props?.title)}
                >
                  <img
                    src="/images/propertdashboard/enlare.png"
                    alt="zoom"
                    onClick={() => props?.onZoomClick(props?.title)}
                  />
                </IconButton>
              )}
              <Typography
                className={classes.titleFont}
                sx={{
                  width: {
                    xs: "120px",
                    sm: "auto",
                    md: "auto",
                    lg: "auto",
                    xl: "auto",
                  },
                }}
              >
                {props?.header}
              </Typography>
            </Stack>

            {props?.titleTab && (
              <div style={{ margin: props?.margin }}>
                <TabComponent
                  tabList={props?.tabList}
                  tabNameState={props?.tabNameState}
                  keyType={props?.keyType}
                  updateState={props?.updateState}
                />
              </div>
            )}
          </Box>
        )}
      </Box>

      <>
        {props?.isTable ? (
          <>
            <TableWithPagination
              heading={props?.heading}
              rows={props?.tableData ?? []}
              path={props?.path}
              showpagination={props?.showpagination ?? false}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              tableType="no-side"
              count="2"
              dataType={props?.dataType}
              height={props?.tabelheight ?? `calc(100vh - 355px)`}
              handlePagination={props?.handlePagination}
              handleChangeLimit={props?.handleChangeLimit}
              totalRowsCount={props?.totalRowsCount}
              page={props?.page}
              limit={props?.limit}
              view={true}
              edit={true}
              delete={true}
              enable_double_click={props?.enable_double_click}
              handleIcon={handleIcon}
            />
          </>
        ) : props?.isTableWithInfinity ? (
          props?.tableData?.length > 0 ? (
            <TableWithInfinityScroll
              heading={props?.heading}
              rows={props?.tableData ?? []}
              path={props?.path}
              showpdfbtn={false}
              showexcelbtn={false}
              showSearch={false}
              tableType="no-side"
              fetchMoreData={props?.fetchMoreData}
              showpagination={false}
              view={true}
              edit={true}
              delete={true}
              dataType={props?.dataType}
              handleIcon={props?.handleIcon}
              height={props?.tabelheight}
              table_div_style={{
                marginTop: 0,
              }}
            />
          ) : (
            <Box display={"flex"} justifyContent={"center"}>
              <NoDataFound />
            </Box>
          )
        ) : (
          <>
            {props?.isImageList ? (
              <Box>
                <ImageList
                  variant="quilted"
                  cols={3}
                  gap={5}
                  sx={{
                    width: "100%",
                    height: props?.imageheight ?? "100%",
                    overflow: "auto",
                  }}
                >
                  {props?.iamgeData?.map((item) => (
                    <ImageListItem key={item.name}>
                      <Box
                        className={classes.dividerBox}
                        style={{ backgroundColor: item?.color }}
                      >
                        <Typography className={classes.dividerBoxText}>
                          {item?.name}
                        </Typography>
                        <Box height={"42px"} />
                        <Typography className={classes.dividerBoxText}>
                          {item?.meter}
                        </Typography>
                      </Box>
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
            ) : (
              <Box className={classes.graphParentFlex}>
                <Grid
                  container
                  justifyContent={"center"}
                  style={{ height: "100%" }}
                >
                  <Grid
                    item
                    xs={props?.divider?.xs ?? 12}
                    sm={props?.divider?.sm ?? 12}
                    md={props?.divider?.md ?? 12}
                    lg={props?.divider?.lg ?? 12}
                  >
                    {props?.isKyc ? (
                      props?.data?.map((val) => {
                        return (
                          <Box className={classes.KYCcard} mt={1}>
                            <Box>
                              <Typography className={classes.title}>
                                {val?.count}
                              </Typography>
                              <Typography className={classes.subTitle}>
                                {val?.name}
                              </Typography>
                            </Box>
                            <Box>{val?.icon}</Box>
                          </Box>
                        );
                      })
                    ) : props?.line ? (
                      <div style={{ marginTop: "16px" }}>
                        {props?.graphData?.datasets?.length > 0 && (
                          <GroupPieCharts
                            data={props?.graphData}
                            scale={props?.scale}
                            indexAxis={props?.indexAxis}
                            line={props?.line}
                            height={props?.gheight}
                            width={props?.gwidth}
                          />
                        )}
                      </div>
                    ) : props?.line_new ? (
                      <div className={classes.graphMargin}>
                        {props?.graphData?.datasets &&
                        props?.graphData?.datasets.some(
                          (dataset) =>
                            dataset.data &&
                            dataset.data.some((value) => value !== 0)
                        ) ? (
                          <div style={{ height: "90%" }}>
                            <StackedSmoothLineChartHighcharts
                              chartHeight={props?.chartHeight}
                              graphTitle={props?.graphTitle}
                              data={props?.graphData}
                              scale={props?.scale}
                              indexAxis={props?.indexAxis}
                              height={props?.gheight}
                              width={props?.gwidth}
                              isShowLegend={props?.isShowLegend}
                            />
                          </div>
                        ) : (
                          <>
                            <Typography className={classes.titleFont}>
                              {props?.chartHeader}
                            </Typography>
                            <Box
                              mt={!Boolean(props?.is_popUp) && 2}
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              style={{ height: "90%" }}
                            >
                              <NoDataFound />
                            </Box>
                          </>
                        )}
                      </div>
                    ) : (
                      <>
                        {props?.isBar ? (
                          <div
                            style={{
                              marginTop: "16px",
                              height:
                                props?.boxHeight ?? props?.height ?? "100%",
                              overflow: "auto",
                            }}
                          >
                            {(props?.disableNoData &&
                              props?.graphData?.datasets?.length > 0) ||
                            isBarDatasets(props?.graphData?.datasets) ? (
                              <GroupPieCharts
                                data={props?.graphData}
                                scale={props?.scale}
                                indexAxis={props?.indexAxis}
                                datalabels={props?.isDatalabels}
                                legend={props?.isLegend}
                                height={props?.gheight}
                                width={props?.gwidth}
                              />
                            ) : (
                              <Box
                                mt={2}
                                alignItems={"center"}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <NoDataFound />
                              </Box>
                            )}
                          </div>
                        ) : props?.isPieFlex ? (
                          <div className={classes.graphMarginFlex}>
                            <Grid container spacing={2} alignItems={"center"}>
                              {props?.graphData?.length > 0 ? (
                                <>
                                  <Grid item xs={12} md={6}>
                                    <PieCharts
                                      data={props?.graphData}
                                      radius={props?.innerRadius}
                                      width={200}
                                      paddingAngle={props?.paddingAngle}
                                      isTotal={props?.isTotal}
                                      tooltip_prefix={props?.tooltip_prefix}
                                      total={props?.total}
                                      centerTitle={props?.centerTitle}
                                      height={
                                        props?.isZoom
                                          ? null
                                          : "300px !important"
                                      }
                                      is_popUp={props?.is_popUp}
                                      centerTop={props?.centerTop}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    {props?.graphData?.map((x) => {
                                      return (
                                        <>
                                          <Stack
                                            direction="row"
                                            alignItems={"baseline"}
                                            spacing={1}
                                          >
                                            <Box
                                              className={classes.dot}
                                              style={{
                                                backgroundColor: x?.fill,
                                              }}
                                            />
                                            {/* <Stack alignItems={"center"} direction="row"> */}

                                            <Typography
                                              className={classes.graphDataCount}
                                            >
                                              {x?.prefix?.length > 0 &&
                                                x?.prefix}
                                              {x?.count ?? 0}
                                              {x?.preffix?.length > 0 && (
                                                <Typography
                                                  className={
                                                    classes.graphDataTitle
                                                  }
                                                >
                                                  {x?.preffix}
                                                </Typography>
                                              )}
                                            </Typography>

                                            <Typography
                                              className={classes.graphDataTitle}
                                              noWrap
                                            >
                                              {x?.name}
                                            </Typography>
                                            {/* </Stack> */}
                                          </Stack>
                                        </>
                                      );
                                    })}
                                  </Grid>
                                </>
                              ) : (
                                <Grid item xs={12} md={12}>
                                  <Box
                                    mt={2}
                                    alignItems={"center"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                  >
                                    <NoDataFound />
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                          </div>
                        ) : props?.newLineGraph ? (
                          <Grid
                            item
                            xs={props?.divider1?.xs ?? 12}
                            sm={props?.divider1?.sm ?? 12}
                            md={props?.divider1?.md ?? 12}
                            lg={props?.divider1?.lg ?? 12}
                            sx={{
                              position: {
                                // lg:"inherit",
                                // md:"inherit",
                                // sm:"relative",

                                lg: props?.relativeGrid?.lg ?? "inherit",
                                md: props?.relativeGrid?.md ?? "inherit",
                                sm: props?.relativeGrid?.sm ?? "relative",
                                xs: props?.relativeGrid?.xs ?? "relative",
                              },
                            }}
                          >
                            <NewLineChart
                              data={props?.graphData}
                              height={props?.gheight}
                              width={props?.gwidth}
                            />
                          </Grid>
                        ) : props?.is_funnel_graph_new ? (
                          isFunnelGraph(props?.graphData) ? (
                            <div
                              className={classes.graphMargin}
                              style={{ height: "100%" }}
                            >
                              <HighChartsFunnel
                                graphTitle={props?.graphTitle}
                                height={props.funnel_graph_height}
                                is_reverse={props?.is_funnel_graph_reverse}
                                width={props?.funnel_graph_width}
                                gap_height={props?.funnel_graph_gap_height}
                                tooltip_type={props?.funnel_graph_tooltip_type}
                                data={props?.graphData}
                                isShowLegend={props?.isShowLegend}
                              />
                            </div>
                          ) : (
                            <>
                              <Typography className={classes.titleFont}>
                                {props?.chartHeader}
                              </Typography>
                              <Box
                                mt={!Boolean(props?.is_popUp) && 2}
                                alignItems={"center"}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <NoDataFound />
                              </Box>
                            </>
                          )
                        ) : props?.is_bar_new ? (
                          props?.graphData?.datasets?.some(
                            (dataset) => dataset.data.length > 0
                          ) ? (
                            <div className={classes.graphMargin}>
                              <HighChartsBar
                                graphTitle={props?.graphTitle}
                                is_reverse={props?.is_bar_reverse}
                                height={props?.is_bar_height}
                                width={props?.is_bar_width}
                                gap_height={props?.is_bar_gap_height}
                                tooltip_type={props?.is_bar_tooltip_type}
                                data={props?.graphData}
                                isShowLegend={props?.isShowLegend}
                                chartHeight={props?.chartHeight}
                              />
                            </div>
                          ) : (
                            <>
                              <Typography className={classes.titleFont}>
                                {props?.chartHeader}
                              </Typography>
                              <Box
                                mt={!Boolean(props?.is_popUp) && 2}
                                alignItems={"center"}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <NoDataFound />
                              </Box>
                            </>
                          )
                        ) : props?.is_line_bar ? (
                          <div className={classes.graphMargin}>
                            {props?.graphData?.datasets &&
                            props?.graphData?.datasets.some(
                              (dataset) =>
                                dataset.data &&
                                dataset.data.some((value) => value !== 0)
                            ) ? (
                              <div style={{ height: "90%" }}>
                                <LineBarChart
                                  graphTitle={props?.graphTitle}
                                  is_reverse={props?.is_bar_reverse}
                                  height={props?.is_bar_height}
                                  width={props?.is_bar_width}
                                  gap_height={props?.is_bar_gap_height}
                                  tooltip_type={props?.is_bar_tooltip_type}
                                  data={props?.graphData}
                                  isShowLegend={props?.isShowLegend}
                                  chartHeight={props?.chartHeight}
                                  colors={props?.colors}
                                  graphColor={props?.graphColor}
                                />
                              </div>
                            ) : (
                              <>
                                <Typography className={classes.titleFont}>
                                  {props?.chartHeader}
                                </Typography>
                                <Box
                                  mt={!Boolean(props?.is_popUp) && 2}
                                  display={"flex"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  style={{ height: "90%" }}
                                >
                                  <NoDataFound />
                                </Box>
                              </>
                            )}
                          </div>
                        ) : props?.race_bar ? (
                          props?.graphData?.datasets &&
                          props?.graphData?.datasets.some(
                            (dataset) =>
                              dataset.data &&
                              dataset.data.some((value) => value !== 0)
                          ) ? (
                            <div className={classes.graphMargin}>
                              <RaceBarChart
                                graphTitle={props?.graphTitle}
                                is_reverse={props?.is_bar_reverse}
                                height={props?.is_bar_height}
                                width={props?.is_bar_width}
                                gap_height={props?.is_bar_gap_height}
                                tooltip_type={props?.is_bar_tooltip_type}
                                data={props?.graphData}
                                isShowLegend={props?.isShowLegend}
                                chartHeight={props?.chartHeight}
                              />
                            </div>
                          ) : (
                            <>
                              <Typography className={classes.titleFont}>
                                {props?.chartHeader}
                              </Typography>
                              <Box
                                mt={!Boolean(props?.is_popUp) && 2}
                                alignItems={"center"}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <NoDataFound />
                              </Box>
                            </>
                          )
                        ) : props?.is_gauge_bar ? (
                          props?.gaugeData ? (
                            <div className={classes.graphMargin}>
                              <GaugeBar
                                stat_data={props?.gaugeData} // Pass gaugeData as stat_data to GaugeBar
                                graphTitle={props?.graphTitle}
                                is_reverse={props?.is_bar_reverse}
                                height={props?.is_bar_height}
                                width={props?.is_bar_width}
                                gap_height={props?.is_bar_gap_height}
                                tooltip_type={props?.is_bar_tooltip_type}
                                isShowLegend={props?.isShowLegend}
                                chartHeight={props?.chartHeight}
                              />
                            </div>
                          ) : (
                            <>
                              <Typography className={classes.titleFont}>
                                {props?.chartHeader}
                              </Typography>
                              <Box
                                mt={!Boolean(props?.is_popUp) && 2}
                                alignItems={"center"}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <NoDataFound />
                              </Box>
                            </>
                          )
                        ) : props?.is_funnel_graph ? (
                          isFunnelGraph(props?.graphData) ? (
                            <div className={classes.graphMargin}>
                              <FunnelChart
                                is_reverse={props?.is_funnel_graph_reverse}
                                height={props?.funnel_graph_height}
                                chartHeight={props?.chartHeight}
                                width={props?.funnel_graph_width}
                                gap_height={props?.funnel_graph_gap_height}
                                tooltip_type={props?.funnel_graph_tooltip_type}
                                data={props?.graphData}
                              />
                            </div>
                          ) : (
                            <Box
                              mt={!Boolean(props?.is_popUp) && 2}
                              alignItems={"center"}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <NoDataFound />
                            </Box>
                          )
                        ) : props?.is_pie_new ? (
                          props?.graphData &&
                          props?.graphData.some((item) => item.count > 0) ? (
                            <div style={{ height: "100%" }}>
                              <HighChartsPie
                                graphTitle={props?.graphTitle}
                                height={props.height}
                                data={props?.graphData}
                                radius={props?.innerRadius}
                                width={200}
                                paddingAngle={props?.paddingAngle}
                                isTotal={props?.isTotal}
                                tooltip_prefix={props?.tooltip_prefix}
                                total={props?.total}
                                justifyContent={props?.justifyContent}
                                is_popUp={props?.is_popUp}
                                centerTop={props?.centerTop}
                                centerTitle={props?.centerTitle}
                                isShowLegend={props?.isShowLegend}
                              />
                            </div>
                          ) : (
                            <>
                              <Typography className={classes.titleFont}>
                                {props?.chartHeader}
                              </Typography>
                              <Box
                                mt={!Boolean(props?.is_popUp) && 4}
                                alignItems={"center"}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <NoDataFound />
                              </Box>
                            </>
                          )
                        ) : props?.is_semi_circle_new ? (
                          props?.graphData &&
                          props?.graphData.some((item) => item.count > 0) ? (
                            <div style={{ height: "100%" }}>
                              <HighChartsSemiCircle
                                graphTitle={props?.graphTitle}
                                height={props.height}
                                data={props?.graphData}
                                radius={props?.innerRadius}
                                width={200}
                                paddingAngle={props?.paddingAngle}
                                isTotal={props?.isTotal}
                                tooltip_prefix={props?.tooltip_prefix}
                                total={props?.total}
                                justifyContent={props?.justifyContent}
                                is_popUp={props?.is_popUp}
                                centerTop={props?.centerTop}
                                centerTitle={props?.centerTitle}
                                isShowLegend={props?.isShowLegend}
                              />
                            </div>
                          ) : (
                            <>
                              <Typography className={classes.titleFont}>
                                {props?.chartHeader}
                              </Typography>
                              <Box
                                mt={!Boolean(props?.is_popUp) && 4}
                                alignItems={"center"}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <NoDataFound />
                              </Box>
                            </>
                          )
                        ) : props?.is_variable_pie_new ? (
                          <div className={classes.graphMargin}>
                            {props?.graphData?.some((item) => item?.count > 0) ? (
                              <div style={{ height: "90%" }}>
                                <VariablePieChart
                                  graphTitle={props?.graphTitle}
                                  data={props?.graphData}
                                  radius={props?.innerRadius}
                                  width={200}
                                  paddingAngle={props?.paddingAngle}
                                  isTotal={props?.isTotal}
                                  tooltip_prefix={props?.tooltip_prefix}
                                  total={props?.total}
                                  height={props?.height}
                                  justifyContent={props?.justifyContent}
                                  is_popUp={props?.is_popUp}
                                  centerTop={props?.centerTop}
                                  centerTitle={props?.centerTitle}
                                  isShowLegend={props?.isShowLegend}
                                />
                              </div>
                            ) : (
                              <>
                                <Typography className={classes.titleFont}>
                                  {props?.chartHeader}
                                </Typography>
                                <Box
                                  mt={!Boolean(props?.is_popUp) && 2}
                                  display={"flex"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  style={{ height: "90%" }}
                                >
                                  <NoDataFound />
                                </Box>
                              </>
                            )}
                          </div>
                        ) : props?.is_line_plain ? (
                          <div className={classes.graphMargin}>
                            {props?.graphData &&
                            props?.graphData?.datasets?.some(
                              (dataset) =>
                                dataset.data &&
                                dataset.data.some((value) => value !== 0)
                            ) ? (
                              <div style={{ height: "90%" }}>
                                <HighChartPlainLine
                                  color={props?.color}
                                  chartHeight={props?.chartHeight}
                                  graphTitle={props?.graphTitle}
                                  data={props?.graphData}
                                  radius={props?.innerRadius}
                                  width={200}
                                  paddingAngle={props?.paddingAngle}
                                  isTotal={props?.isTotal}
                                  tooltip_prefix={props?.tooltip_prefix}
                                  total={props?.total}
                                  height={props?.height}
                                  justifyContent={props?.justifyContent}
                                  is_popUp={props?.is_popUp}
                                  centerTop={props?.centerTop}
                                  centerTitle={props?.centerTitle}
                                  isShowLegend={props?.isShowLegend}
                                />
                              </div>
                            ) : (
                              <>
                                <Typography className={classes.titleFont}>
                                  {props?.chartHeader}
                                </Typography>
                                <Box
                                  mt={!Boolean(props?.is_popUp) && 2}
                                  display={"flex"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  style={{ height: "90%" }}
                                >
                                  <NoDataFound />
                                </Box>
                              </>
                            )}
                          </div>
                        ) : props?.total !== 0 &&
                          props?.graphData?.length > 0 ? (
                          <div className={classes.graphMargin}>
                            <PieCharts
                              data={props?.graphData}
                              radius={props?.innerRadius}
                              width={200}
                              paddingAngle={props?.paddingAngle}
                              isTotal={props?.isTotal}
                              tooltip_prefix={props?.tooltip_prefix}
                              total={props?.total}
                              height={
                                props?.isZoom
                                  ? null
                                  : props?.ZoomTrue
                                  ? null
                                  : "300px !important"
                              }
                              justifyContent={props?.justifyContent}
                              is_popUp={props?.is_popUp}
                              centerTop={props?.centerTop}
                              centerTitle={props?.centerTitle}
                            />
                          </div>
                        ) : (
                          <Box
                            mt={!Boolean(props?.is_popUp) && 2}
                            alignItems={"center"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <NoDataFound />
                          </Box>
                        )}
                      </>
                    )}
                  </Grid>
                  {props?.is_pagination && (
                    <Box className={classes.graph_pagination_container}>
                      <Pagination
                        className={classes.graph_pagination}
                        shape="rounded"
                        color="primary"
                        count={Math.ceil(
                          graph_data_total / props?.graph_data_limit
                        )}
                        page={props?.graph_data_page}
                        onChange={(e, value) =>
                          props?.handle_graph_pagination(value)
                        }
                      />
                    </Box>
                  )}

                  {/* pie chart */}

                  {/* mapping data */}
                  {props?.isPie ||
                  (props?.is_funnel_graph &&
                    isFunnelGraph(props?.graphData)) ? (
                    <>
                      {props?.total !== 0 &&
                        props?.graphData?.length > 0 &&
                        !props?.isNoLegend && (
                          <Grid
                            item
                            xs={props?.divider1?.xs ?? 12}
                            sm={props?.divider1?.sm ?? 12}
                            md={props?.divider1?.md ?? 12}
                            lg={props?.divider1?.lg ?? 12}
                            sx={{
                              position: {
                                // lg:"inherit",
                                // md:"inherit",
                                // sm:"relative",

                                lg: props?.relativeGrid?.lg ?? "inherit",
                                md: props?.relativeGrid?.md ?? "inherit",
                                sm: props?.relativeGrid?.sm ?? "relative",
                                xs: props?.relativeGrid?.xs ?? "relative",
                              },
                            }}
                          >
                            <Box
                              className={classes.grapAbsolute}
                              style={{ position: props?.position }}
                            >
                              <Box className={classes.graphHeigth}>
                                <Box className={classes.gridGraphParent}>
                                  {props?.graphData?.map((x) => {
                                    return (
                                      <Box>
                                        {props?.isSale && (
                                          <Typography
                                            className={classes.graphDataCount}
                                            noWrap
                                          >
                                            {x?.prefix?.length > 0 && x?.prefix}
                                            {x?.value ?? x?.count ?? 0}
                                            {x?.preffix?.length > 0 && (
                                              <Typography
                                                className={
                                                  classes.graphDataTitle
                                                }
                                                noWrap
                                              >
                                                {x?.preffix}
                                              </Typography>
                                            )}
                                          </Typography>
                                        )}
                                        <Stack
                                          direction="row"
                                          alignItems={"baseline"}
                                          spacing={1}
                                        >
                                          <Box
                                            className={classes.dot}
                                            style={{
                                              backgroundColor:
                                                x?.backgroundColor ?? x?.fill,
                                            }}
                                          />
                                          {!props?.isSale && (
                                            <Typography
                                              className={classes.graphDataCount}
                                            >
                                              {x?.prefix?.length > 0 &&
                                                x?.prefix}
                                              {x?.value ??
                                                x?.custom_count ??
                                                x?.count ??
                                                0}
                                              {x?.preffix?.length > 0 && (
                                                <Typography
                                                  className={
                                                    classes.graphDataTitle
                                                  }
                                                  noWrap
                                                >
                                                  {x?.preffix}
                                                </Typography>
                                              )}
                                            </Typography>
                                          )}
                                          <Typography
                                            className={classes.graphDataTitle}
                                            noWrap
                                          >
                                            {x?.label ?? x?.name}
                                          </Typography>
                                          {/* </Stack> */}
                                        </Stack>
                                        {/* <Typography className={classes.graphDataCount} noWrap>
                                    {x?.count ?? 0}
                                    {x?.preffix?.length > 0 && (
                                      <Typography className={classes.graphDataTitle} noWrap>
                                        {x?.preffix}
                                      </Typography>
                                    )}
                                  </Typography> */}
                                        {/* <Box className={classes.dotParent}>
                                    <Box
                                      className={classes.dot}
                                      style={{ backgroundColor: x?.fill }}
                                    />
                                    <Box>
                                      <Typography
                                        className={classes.graphDataTitle}
                                        noWrap
                                      >
                                        {x?.name}
                                      </Typography>
                                    </Box>
                                  </Box> */}
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        )}
                    </>
                  ) : (
                    ((props?.disableNoData &&
                      props?.graphData?.datasets?.length > 0) ||
                      isBarDatasets(props?.graphData?.datasets)) &&
                    !props?.isNoLegend && (
                      <Grid
                        item
                        xs={props?.divider1?.xs ?? 12}
                        sm={props?.divider1?.sm ?? 12}
                        md={props?.divider1?.md ?? 12}
                        lg={props?.divider1?.lg ?? 12}
                        className={classes.grid}
                        sx={{
                          position: {
                            lg: "inherit",
                            md: "inherit",
                            sm: "relative",
                          },
                        }}
                      >
                        <Box
                          className={classes.grapAbsolute}
                          style={{ position: props?.position }}
                        >
                          <Box className={classes.graphHeigth}>
                            <Box className={classes.gridGraphParent}>
                              {props?.graphData?.datasets?.map((x) => {
                                return (
                                  <Box>
                                    {/* {props?.isSale &&
                                  <Typography className={classes.graphDataCount} noWrap>
                                    {x?.count ?? 0}
                                    {x?.preffix?.length > 0 && (
                                      <Typography className={classes.graphDataTitle } noWrap>
                                        {x?.preffix}
                                      </Typography>
                                    )}
                                  </Typography>} */}
                                    <Stack
                                      direction="row"
                                      alignItems={"baseline"}
                                      spacing={1}
                                    >
                                      <Box
                                        className={classes.dot}
                                        style={{
                                          backgroundColor: x?.backgroundColor,
                                        }}
                                      />

                                      {x?.label?.length > 0 &&
                                        !props?.noGraphtitle && (
                                          <Typography
                                            className={classes.graphDataTitle}
                                            noWrap
                                          >
                                            {x?.label}
                                          </Typography>
                                        )}

                                      <Typography
                                        className={classes.graphDataTitle}
                                        noWrap
                                      >
                                        {x?.name}
                                      </Typography>
                                      {/* </Stack> */}
                                    </Stack>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  )}

                  {!props?.isLegend && !props?.is_popUp && (
                    <Box className={classes.gradiantRelative}></Box>
                  )}
                </Grid>
              </Box>
            )}
            {props?.is_popup_pagination && (
              <Box className={classes.graph_pagination_container}>
                <Pagination
                  className={classes.graph_pagination}
                  shape="rounded"
                  color="primary"
                  count={Math.ceil(graph_data_total / props?.graph_data_limit)}
                  page={props?.graph_data_page}
                  onChange={(e, value) => props?.handle_graph_pagination(value)}
                />
              </Box>
            )}
          </>
        )}
      </>
    </div>
  );
};

// tab component
const TabComponent = (props) => {
  const classes = useStylesCreation(props);
  return (
    <List className={classes.list}>
      {props?.tabList?.map((x) => {
        return (
          <ListItem
            className={
              x === props?.tabNameState ? classes.listText : classes.listText1
            }
            onClick={() => props?.updateState(props?.keyType, x)}
          >
            <Typography className={classes.tabname}>{x}</Typography>
          </ListItem>
        );
      })}
    </List>
  );
};
export default withNamespaces("graphComponent")(GraphComponent);
