import * as React from "react"

const PropGoToComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width ?? 172.401}
    height={props?.height ?? 30.636}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 52714"
          fill="none"
          d="M0 0h172.401v30.636H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 99024">
      <g data-name="Group 99023" clipPath="url(#a)">
        <path
          data-name="Path 95296"
          d="M.138 12.452c0-2.223-.069-4.033-.139-5.633h3.756l.208 2.61h.07a6.593 6.593 0 0 1 5.806-2.99c3.894 0 7.127 3.338 7.127 8.623 0 6.119-3.859 9.075-7.719 9.075a5.563 5.563 0 0 1-4.763-2.26h-.069v8.762H.138Zm4.277 4.207a4.426 4.426 0 0 0 .139 1.182 3.867 3.867 0 0 0 3.755 2.988c2.712 0 4.311-2.26 4.311-5.6 0-2.99-1.46-5.424-4.207-5.424a3.985 3.985 0 0 0-3.824 3.169 3.867 3.867 0 0 0-.174 1.078Z"
          fill="#ec4218"
        />
        <path
          data-name="Path 95297"
          d="M20.48 12.278c0-2.295-.035-3.929-.139-5.459h3.718l.139 3.233h.14a5.048 5.048 0 0 1 4.621-3.615 4.517 4.517 0 0 1 1.008.1v4.034a5.916 5.916 0 0 0-1.252-.139 3.77 3.77 0 0 0-3.824 3.233 7.341 7.341 0 0 0-.132 1.325v8.762H20.48Z"
          fill="#ec4218"
        />
        <path
          data-name="Path 95298"
          d="M48.677 15.129c0 6.223-4.381 9.005-8.693 9.005-4.8 0-8.484-3.3-8.484-8.727 0-5.564 3.651-8.97 8.762-8.97 5.042 0 8.414 3.546 8.414 8.692m-12.76.174c0 3.269 1.6 5.737 4.207 5.737 2.434 0 4.138-2.4 4.138-5.806 0-2.643-1.182-5.668-4.1-5.668-3.025 0-4.242 2.921-4.242 5.737"
          fill="#ec4218"
        />
        <path
          data-name="Path 95299"
          d="M52.191 12.452c0-2.225-.07-4.033-.139-5.633h3.755l.206 2.61h.07a6.594 6.594 0 0 1 5.806-2.99c3.894 0 7.128 3.338 7.128 8.623 0 6.119-3.859 9.075-7.719 9.075a5.562 5.562 0 0 1-4.763-2.26h-.07v8.762h-4.277Zm4.277 4.207a4.409 4.409 0 0 0 .139 1.182 3.867 3.867 0 0 0 3.755 2.99c2.712 0 4.311-2.26 4.311-5.6 0-2.99-1.461-5.424-4.207-5.424a3.986 3.986 0 0 0-3.825 3.164 3.882 3.882 0 0 0-.174 1.078Z"
          fill="#ec4218"
        />
        <path
          data-name="Path 95300"
          d="M82.343 10.764h6.57a8.719 8.719 0 0 1 2.122 5.552 7.473 7.473 0 0 1-2.488 5.7 8.659 8.659 0 0 1-6.12 2.309 10.482 10.482 0 0 1-8.014-3.583 12.423 12.423 0 0 1-3.294-8.726 11.8 11.8 0 0 1 3.354-8.586A11.236 11.236 0 0 1 82.852 0a12.146 12.146 0 0 1 3.8.7v6.889a7.088 7.088 0 0 0-2.971-.764 5.413 5.413 0 0 0-3.965 1.511 5.257 5.257 0 0 0-1.537 3.922 5.311 5.311 0 0 0 1.426 3.905 5.173 5.173 0 0 0 3.854 1.443q1.545 0 1.545-.9 0-.679-1.019-.679h-1.642Z"
          fill="#323233"
        />
        <path
          data-name="Path 95301"
          d="M104.822 0a11.431 11.431 0 0 1 8.447 3.582 11.8 11.8 0 0 1 3.523 8.574 11.742 11.742 0 0 1-3.548 8.625 12.215 12.215 0 0 1-17.233-.017 11.73 11.73 0 0 1-3.565-8.608 11.637 11.637 0 0 1 3.591-8.625A12.055 12.055 0 0 1 104.822 0m-.2 6.825a4.818 4.818 0 0 0-3.616 1.562 5.254 5.254 0 0 0-1.494 3.769 5.238 5.238 0 0 0 1.5 3.769 4.976 4.976 0 0 0 7.233.008 5.262 5.262 0 0 0 1.485-3.778 5.263 5.263 0 0 0-1.485-3.777 4.825 4.825 0 0 0-3.625-1.554"
          fill="#323233"
        />
        <path
          data-name="Path 95302"
          d="M130.119 7.232v16.52h-6.893V7.232h-5.281V.577h17.436v6.655Z"
          fill="#323233"
        />
        <path
          data-name="Path 95303"
          d="M148.913 0a11.431 11.431 0 0 1 8.446 3.582 11.8 11.8 0 0 1 3.523 8.574 11.741 11.741 0 0 1-3.549 8.625 12.214 12.214 0 0 1-17.232-.017 11.728 11.728 0 0 1-3.565-8.608 11.637 11.637 0 0 1 3.591-8.625A12.057 12.057 0 0 1 148.913 0m-.2 6.825a4.821 4.821 0 0 0-3.616 1.562 5.258 5.258 0 0 0-1.494 3.769 5.239 5.239 0 0 0 1.5 3.769 4.975 4.975 0 0 0 7.232.008 5.261 5.261 0 0 0 1.486-3.778 5.262 5.262 0 0 0-1.486-3.777 4.824 4.824 0 0 0-3.63-1.553"
          fill="#323233"
        />
        <path
          data-name="Path 95304"
          d="M168.344 16.146a3.889 3.889 0 0 1 2.86 1.206 3.929 3.929 0 0 1 1.2 2.869 3.809 3.809 0 0 1-1.188 2.827 3.953 3.953 0 0 1-2.869 1.163 4.04 4.04 0 0 1-2.835-6.885 3.846 3.846 0 0 1 2.835-1.18"
          fill="#ec4218"
        />
      </g>
    </g>
  </svg>
)

export default PropGoToComponent
