import { Box, Typography } from "@mui/material";
import FileViewer from "react-file-viewer";

export const DocumentViewer = ({ url = "", waterMark = false }) => {

    const type = "pdf";
    const file = url;
    return (
        <>
            {
                waterMark ?
                    <Box sx={{ position: "relative" }}>
                        <Typography
                            sx={{
                                position: "absolute",
                                top: "35%",
                                left: "25%",
                                zIndex: 9,
                                transform: "rotate(-45deg)",
                                fontSize: "60px",
                                opacity: 0.2,
                                color: "#4E5A6B"
                            }}>Sample</Typography>
                        <FileViewer fileType={type} filePath={file} />
                    </Box> :
                    <FileViewer fileType={type} filePath={file} />
            }
        </>

    );
};
