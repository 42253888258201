import { Avatar, Box, Stack, Typography } from "@mui/material";
import { stringAvatar } from "../../../utils";
import { useStyles } from "./style";
export const StepTitle = ({ step = "1", title = "" }) => {
    const classes = useStyles()
    return (
        <Box>
            <Stack direction="row" spacing={1} alignItems="center">
                <Avatar className={classes.step} {...stringAvatar(title)}>{step}</Avatar>
                <Typography className={classes.stepTitle}>{title}</Typography>
            </Stack>

        </Box>
    )
}