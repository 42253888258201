import * as React from "react"

export const KeyIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={11.818} height={20} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.5}
        y1={-0.002}
        x2={0.5}
        y2={1.001}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#e5a505" />
        <stop offset={0.01} stopColor="#e9a804" />
        <stop offset={0.06} stopColor="#f4b102" />
        <stop offset={0.129} stopColor="#fbb600" />
        <stop offset={0.323} stopColor="#fdb700" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.152}
        y1={0.114}
        x2={0.797}
        y2={1.051}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fede00" />
        <stop offset={1} stopColor="#ffd000" />
      </linearGradient>
    </defs>
    <path
      data-name="Path 97090"
      d="M23.182 33.455a1.132 1.132 0 0 1 .455-.9v-1.5a.9.9 0 0 1 0-1.566V28H20v6.9a.909.909 0 0 0 .266.643l.643.643h1.654l.751-.637a.909.909 0 0 0 .322-.694v-.493a1.132 1.132 0 0 1-.454-.907Z"
      transform="translate(-15.909 -16.182)"
      fill="#FED700"
    />
    <path
      data-name="Path 97091"
      d="M22.818 7.909a5.909 5.909 0 0 0-11.818 0c0 .154.011.3.023.455H11V12a1.818 1.818 0 0 0 1.818 1.818H21A1.818 1.818 0 0 0 22.818 12V8.364H22.8c.007-.151.018-.301.018-.455Zm-5.909-3.636a1.818 1.818 0 1 1-1.818 1.818 1.818 1.818 0 0 1 1.818-1.818Z"
      transform="translate(-11 -2)"
      fill="#FED700"
    />
  </svg>
)

