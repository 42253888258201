import React from "react";
import Welcome from "./welcome";
import { LoginSuccess } from "../../router/access";
import { AlertProps, LocalStorageKeys } from "../../utils";
import { withRouter } from 'react-router-dom';
import { AlertContext } from "../../contexts";
import { withNamespaces } from "react-i18next";

class WelcomePagetParent extends React.Component {
    static contextType = AlertContext;

    componentDidMount() {
        if (localStorage.getItem(LocalStorageKeys.authToken)) {
            if (localStorage.getItem(LocalStorageKeys.permission)) {
                const contextValue = this?.context;
                contextValue?.setSnack({
                    ...contextValue, open: true, autoHideDuration: 5000,
                    severity: AlertProps?.severity?.info,
                    msg: this?.props?.t("You are already logged in via another account! Please logout and try again."),
                });
                this?.props?.history?.push(LoginSuccess(JSON.parse(localStorage.getItem(LocalStorageKeys.permission))))
            }
        }
    }
    render() {
        return <Welcome />;
    }
}

export default withNamespaces("welcomePage")(withRouter(WelcomePagetParent));
