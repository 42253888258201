import React from "react"

export const AreaIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Group_112288" data-name="Group 112288" transform="translate(0.426 -0.47)">
      <g id="Group_112287" data-name="Group 112287">
        <g id="Rectangle_57208" data-name="Rectangle 57208" transform="translate(-0.426 0.47)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
          <rect width="20" height="20" stroke="none"/>
          <rect x="0.5" y="0.5" width="19" height="19" fill="none"/>
        </g>
        <path id="icons8-storey" d="M5.578,6.978a.587.587,0,0,0-.578.6V19.312a.987.987,0,0,0,.978.978h6.26a.987.987,0,0,0,.978-.978V17.747a.987.987,0,0,0-.978-.978H10.869v-.978a.987.987,0,0,0-.978-.978H8.521v-.978a.987.987,0,0,0-.978-.978H6.174V7.574a.587.587,0,0,0-.6-.6Zm2.357.008a.587.587,0,1,0,.587.587A.587.587,0,0,0,7.935,6.987Zm2.348,0a.587.587,0,1,0,.587.587A.587.587,0,0,0,10.282,6.987Zm2.348,0a.587.587,0,1,0,.587.587A.587.587,0,0,0,12.63,6.987Zm2.348,0a.587.587,0,1,0,.587.587A.587.587,0,0,0,14.978,6.987Zm2.739,0a.987.987,0,0,0-.978.978v.978H15.369a.987.987,0,0,0-.978.978V10.9H13.021a.987.987,0,0,0-.978.978v1.565a.987.987,0,0,0,.978.978h6.065V19.7a.587.587,0,1,0,1.174,0V13.834a.587.587,0,0,0-.025-.179.947.947,0,0,0,.025-.212V7.965a.987.987,0,0,0-.978-.978Zm.2,1.174h1.174v5.087H13.217V12.073h1.761a.587.587,0,0,0,.587-.587v-1.37h1.761a.587.587,0,0,0,.587-.587ZM6.174,14.03H7.348V15.4a.587.587,0,0,0,.587.587H9.7v1.369a.587.587,0,0,0,.587.587h1.761v1.174H6.174Zm8.8,5.087a.587.587,0,1,0,.587.587A.587.587,0,0,0,14.978,19.116Zm2.348,0a.587.587,0,1,0,.587.587A.587.587,0,0,0,17.325,19.116Z" transform="translate(-3.056 -3.164)" fill="#4e5a6b"/>
      </g>
    </g>
  </svg>
  

)