import { makeStyles } from "@mui/styles";
import { Bold, SemiBold } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F8F9FA",
        padding: "12px",
        borderRadius: "4px",
        height: "100%",
        display: "flex",
        alignItems: "center"
    },
    select: {
        backgroundColor: "#F8F9FA",
        padding: "12px",
        borderRadius: "4px",
        height: "100%",
    },
    title: {
        fontFamily: Bold,
        color: theme.typography.color.secondary,
        fontSize:"0.875rem",
        textAlign: "center"
    },
    sub: {
        color: theme.typography.color.secondary,
        fontSize:"0.75rem",
        textAlign: "center",
        marginTop: "8px"
    },
    btnTitle: {
        fontSize:"0.875rem",
        fontFamily: Bold
    },
    name: {
        fontSize:"0.75rem",
        fontFamily: Bold
    },
    email: {
        color: "#98A0AC",
        fontSize:"0.75rem",
        // marginTop: "8px"
    },
    header: {
        borderBottom: "1px solid #E4E8EE",
        padding: "4px 0px"
    },
    detailRoot: {
        border: "1px solid #E4E8EE",
        borderRadius: "4px",
        backgroundColor: "white",
        //padding: "12px",
        marginTop: "8px",
        // height: `calc(100vh - 351px)`,
        overFlow: "auto"
    },
    selectedListCardRoot: {
        borderBottom: "1px solid #E4E8EE",
        padding: "8px",
        "&:last-child": {
            borderBottom: 0,
            padding: "8px 8px 0px 8px",
        }
    },

    vacant: {
        color: theme.palette.success.main,
        fontFamily: SemiBold,
        fontSize:"0.75rem"
    },
    heightBody: {
        height: (props) => props?.height ?? "auto"
    }
}))