import React from "react";
import {
  TableWithPagination,
  DialogBox,
  FormGenerator,
} from "../../../components";
import { ToDoHeading, ToDoPath } from "../common";
import { LeadStylesParent } from "./style";
import Fab from "@mui/material/Fab";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";

export const ToDoList = (props) => {

  const { t } = (props)
  // classes
  const classes = LeadStylesParent(props);
  const language = localStorage.getItem("i18nextLng");

  const formData = [
    {
      isActive: true,
      component: "text",
      label: t("Name"),
      value: props?.state?.name,
      placeholder: t("Name"),
      onChange: (value) => props?.updateState("name", value?.target?.value),
      error: props?.state?.error?.name,
      isRequired: true,
      isReadonly: props?.state?.isView ? true : false,
      size: {
        xs: 12,
      },
    },
    {
      isActive: true,
      component: "text",
      label: t("Description"),
      value: props?.state?.des,
      placeholder: t("Description"),
      isReadonly: props?.state?.isView ? true : false,
      onChange: (value) => props?.updateState("des", value?.target?.value),
      multiline: true,
      size: {
        xs: 12,
      },
    },
    {
      isActive: true,
      component: "button",
      onClick: props?.sumbit,
      label: props?.btnTitle,
      size: {
        xs: 12,
      },
      disable:props?.disable
    },
  ];

  const heading = ToDoHeading(t)
  return (
    <>
      <TableWithPagination
        heading={heading}
        rows={props?.toDoList?.data ?? []}
        path={ToDoPath}
        showpagination={true}
        showpdfbtn={false}
        showexcelbtn={false}
        showSearch={false}
        handlePagination={props?.handlePagination}
        handleChangeLimit={props?.handleChangeLimit}
        page={props?.page}
        limit={props?.limit}
        totalRowsCount={props?.toDoList?.count}
        handleIcon={props?.handleIconToDo}
        tableType="no-side"
        count="2"
        isColor
        marginTop={"1px"}
        dataType={[
          { type: ["checkBox"], name: "checkbox" },
          { type: ["text"], name: "Name" },
          { type: ["description"], name: "Description" },
          { type: ["icon"], icon: "icon" },
        ]}
        height={"calc(100vh - 298px)"}
        view={true}
        edit={props?.Status}
        noDataSvg
        delete={props?.Status} />

      <DialogBox
        open={props?.toDodialog}
        padding={"16px"}
        maxWidth={"xs"}
        onClose={props?.closeTodo}
        header={props?.dialogTittle}
        width={"374px !important"}
        background
        component={
          <div style={{ padding: "16px" }}>
            <FormGenerator t={t}components={formData} />
          </div>
        }
      />

      {/* fab */}
      {props?.Status && (
        <Fab
          className={language === "ar" ? classes.fabArabic : classes.fab}
          size="large"
          color="primary"
          onClick={props?.closeTodo}
        >
          <SpeedDialIcon />
        </Fab>
      )}
    </>
  );
};
