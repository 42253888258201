import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import NoDataFound from "../../../assets/noData";
import { CustomDatePicker, OutStandingCard } from "../../dashboard/components";
import { useStylesCreation } from "./style";
export const DatePicke = (props) => {
  //   classes
  const classes = useStylesCreation(props);

  return (
    <div className={classes.card}>
      <Box className={classes.expensesHeader} display="flex" alignItems="center">
        <Box>
          <TabComponent
            tabList={props?.tabList}
            tabNameState={props?.tabNameState}
            keyType={props?.keyType}
            updateState={props?.updateState}
          />
        </Box>
        <Box flexGrow={1}>
          <div style={{ float: "right" }}>
            <CustomDatePicker getDate={props?.getDate} value={{ date: props?.dateValue }} />
          </div>
        </Box>
      </Box>
      {/* <Divider /> */}
      <Box height={320} mt={1}>
        <Grid
          container
          // className={`cardHead ${classes.cardHead}`}
          sx={{ paddingLeft: document.getElementsByTagName("HTML")[0].dir === "rtl" ? "8px" : "0px" }}
        // className={classes.grid}
        >
          {props?.outStatndingData?.length > 0 ? (
            props?.outStatndingData?.map((item, index) => {
              return (
                <Grid item xs={12} onClick={() => props?.handleOnClick(item)}>

                  <Grid container spacing={3} className={classes.cardHandover}>
                    {/* <Grid item xs={2} alignItems="center">
                      <Typography className={classes.num}>
                        {12 + " AM"}
                      </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                      <OutStandingCard data={item} type={5} />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} className={classes.noData}>
              <NoDataFound />
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
};
// tab component
const TabComponent = (props) => {
  const classes = useStylesCreation(props);
  return (
    <List className={classes.list}>
      {props?.tabList?.map((x) => {
        return (
          <ListItem
            className={
              x?.value === props?.tabNameState ? classes.listtabText : classes.listtabText1
            }
            onClick={() => props?.updateState(x?.value)}
          >
            <Typography className={classes.tabname}>{x?.label}</Typography>
          </ListItem>
        );
      })}
    </List>
  );
};
