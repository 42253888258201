import React from "react"

export const PublishFormIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
  <g id="Group_113263" data-name="Group 113263" transform="translate(-15518.5 -7261)">
    <rect id="icons8-cardboard-box-48" width="80" height="80" transform="translate(15518.5 7261)" fill="#fff"/>
    <g id="Group_113422" data-name="Group 113422" transform="translate(-3.428 -2)">
      <g id="icons8-single-page-mode" transform="translate(15543.93 7292.561)" opacity="0.5">
        <rect id="Rectangle_57473" data-name="Rectangle 57473" width="34.997" height="42.369" rx="4" fill="#d1eaff"/>
        <rect id="Rectangle_57474" data-name="Rectangle 57474" width="24.921" height="2.519" rx="1.259" transform="translate(5.038 11.174)" fill="#1565c0"/>
        <rect id="Rectangle_57475" data-name="Rectangle 57475" width="24.921" height="2.519" rx="1.259" transform="translate(5.038 17.268)" fill="#1565c0"/>
        <rect id="Rectangle_57476" data-name="Rectangle 57476" width="24.921" height="2.519" rx="1.259" transform="translate(5.038 23.363)" fill="#1565c0"/>
        <rect id="Rectangle_57477" data-name="Rectangle 57477" width="24.921" height="2.519" rx="1.259" transform="translate(5.038 29.458)" fill="#1565c0"/>
      </g>
      <g id="icons8-single-page-mode-2" data-name="icons8-single-page-mode" transform="translate(15540.93 7281.721)">
        <rect id="Rectangle_57473-2" data-name="Rectangle 57473" width="41.444" height="48.047" rx="4" transform="translate(0 0)" fill="#c3e4ff"/>
        <rect id="Rectangle_57474-2" data-name="Rectangle 57474" width="30.018" height="2.857" rx="1.428" transform="translate(5.713 12.671)" fill="#c3e4ff"/>
        <rect id="Rectangle_57475-2" data-name="Rectangle 57475" width="30.018" height="2.857" rx="1.428" transform="translate(5.713 19.583)" fill="#c3e4ff"/>
        <rect id="Rectangle_57476-2" data-name="Rectangle 57476" width="30.018" height="2.857" rx="1.428" transform="translate(5.713 26.494)" fill="#c3e4ff"/>
        <rect id="Rectangle_57477-2" data-name="Rectangle 57477" width="30.018" height="2.857" rx="1.428" transform="translate(5.713 33.406)" fill="#c3e4ff"/>
      </g>
      <g id="icons8-single-page-mode-3" data-name="icons8-single-page-mode" transform="translate(15537.929 7271)">
        <rect id="Rectangle_57473-3" data-name="Rectangle 57473" width="47.299" height="54" rx="4" transform="translate(0 0)" fill="#90caf9"/>
        <rect id="Rectangle_57474-3" data-name="Rectangle 57474" width="35.299" height="3" rx="1.5" transform="translate(6 14.3)" fill="#1565c0"/>
        <rect id="Rectangle_57475-3" data-name="Rectangle 57475" width="35.299" height="3" rx="1.5" transform="translate(6 22.1)" fill="#1565c0"/>
        <rect id="Rectangle_57476-3" data-name="Rectangle 57476" width="35.299" height="3" rx="1.5" transform="translate(6 29.9)" fill="#1565c0"/>
        <rect id="Rectangle_57477-3" data-name="Rectangle 57477" width="35.299" height="3" rx="1.5" transform="translate(6 37.7)" fill="#1565c0"/>
      </g>
    </g>
  </g>
</svg>

)