import { Grid } from "@mui/material"
import React from "react"
import { withNamespaces } from "react-i18next"
import { AlertDialog, FilterGenerator, Subheader, UseDebounce } from "../../components"
import { config } from "../../config"
import { AlertContext, AuthContext, BackdropContext } from "../../contexts"
import { NetworkCall } from "../../networkcall"
import { accessCheckRender, AlertProps, getCompanyOption, getRoutePermissionNew, NetWorkCallMethods } from "../../utils"
import { productType, StatusOptionList } from "../../utils/inventory"
import AddPopup from "./addPopup"
import { Delpopup } from "./delPopup"
import TableComp from "./tableComp"
import { TableView } from "./tableView"

const InventoryMaster = ({ t }) => {
    const backdrop = React.useContext(BackdropContext)
    const auth = React.useContext(AuthContext)
    const [TableBtn, setTableBtn] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false)
    const [isdelete, setIsDelete] = React.useState(false)
    const [drawer, setDrawer] = React.useState(false);
    const [isEdit, setisEdit] = React.useState(false)
    const [companyList, setCompanyList] = React.useState([])
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const [tableData, setTableData] = React.useState(false)
    const [searchText, setSearchText] = React.useState("")
    const [editData, setEditData] = React.useState("")
    const [permissions, setPermission] = React.useState({})
    const [filterData, setFilterData] = React.useState({
        type: null,
        is_active: null
    });
    const [totalRows, setTotalRows] = React.useState("")
    const debounce = UseDebounce()
    const alert = React.useContext(AlertContext);

    //get tableData 
    const getInventoryList = (company_id, offset = 0, limit = 10, searchText, filterData) => {

        const payload = {
            company_id: company_id,
            search: searchText,
            active: filterData?.is_active ?? undefined,
            type: filterData?.type ?? undefined,
            offset: offset,
            limit: limit
        }

        NetworkCall(
            `${config.api_url}/inventory/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setTableData(response?.data?.data)
                setTotalRows(response?.data?.count)
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
            })
    }

    //To get editdata and viewdata by id
    const getAssestsData = (id, type) => {
        const payload = {
            id: id
        }

        NetworkCall(
            `${config.api_url}/inventory/get`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setEditData(response?.data?.data)
                if (type === "edit") {
                    setisEdit(true)
                    setIsOpen(true)
                }
                else if (type === "view") {
                    setisEdit(false)
                }
            }).catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("data not found"),
                });
            })
    }
    //initial load
    React.useEffect(() => {
        let company = getCompanyOption(backdrop, auth, alert)
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getInventoryList(company?.selected?.value, 0, limit, "")
                }
            }
        }

        // eslint-disable-next-line
    }, [auth])
    //on search
    const handleSearch = (e) => {
        setSearchText(e)
        debounce(() => searchTableFunction(e), 800)
    }
    //search function
    const searchTableFunction = (e) => {
        if (page > 1) {
            setPage(1);
        }
        getInventoryList(selectedCompany?.value, 0, 10, e)
    }
    //reload
    const reload = () => {
        getInventoryList(selectedCompany?.value, 0, 10, {})
    }

    // view table row descrip
    const handleTableIcon = (type, data) => {
        if (type === "view") {
            getAssestsData(data.id, type)
            setTableBtn(true)
        }
        else if (type === "edit") {
            getAssestsData(data.id, type)


        }
        else if (type === "delete") {
            setIsDelete(true)
        }
        else if (type === "active") {
            handleStatus(data?.id, !data?.is_active)
        }
    }
    //status Change function
    const handleStatus = (id, status) => {
        const payload = {
            is_active: status,
            id: id
        }
        NetworkCall(
            `${config.api_url}/inventory/upsert`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then(response => {
            reload();
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Status Updated"),
            });
        }).catch(er => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Status  not Updated"),
            });
        })
    }
    // close table row descrip
    const handleTableClose = () => {
        setTableBtn(false)
    }
    //add form open
    const handleAdd = () => {
        setIsOpen(true)
        setisEdit(false)
    }
    //To filter data based on status
    const handleFilter = (data) => {
        getInventoryList(selectedCompany?.value, 0, limit, "", data)
        setFilterData(data)
    }
    //handle pagination
    const handlePagination = (value) => {
        setPage(value);
        let offset = (value - 1) * limit;
        getInventoryList(selectedCompany?.value, offset, limit, "", filterData)
    }

    //on change limit
    const handleChangeLimit = (value) => {
        setLimit(value);
        setPage(1);
        getInventoryList(selectedCompany?.value, 0, limit, "", filterData)

    }
    //company handle change
    const handleCompanyChange = (value) => {
        setSelectedCompany(value)
        getInventoryList(value?.value, 0, 10, "")
    }
    const render = () => {
        return (
            <>
                <Subheader title={t("propertyInventoryMaster")} hideBackButton={true} select value={selectedCompany} options={companyList} onchange={(e) => {
                    handleCompanyChange(e)
                }} />
                <Grid container>
                    <Grid item xs={TableBtn ? 7 : 12}>
                        <TableComp
                            handleTableIcon={handleTableIcon}
                            handleAdd={handleAdd}
                            handleChangeLimit={handleChangeLimit}
                            handlePagination={handlePagination}
                            page={page}
                            permissions={permissions}
                            limit={limit}
                            data={tableData}
                            totalRows={totalRows}
                            handleSearch={handleSearch}
                            searchText={searchText}
                            handleFilter={handleFilter}
                            openfilter={() => setDrawer(true)}

                        />
                    </Grid>
                    <Grid item xs={!TableBtn ? 0 : 5} >
                        <TableView t={t} handleTableClose={handleTableClose} viewData={editData} />
                    </Grid >
                </Grid >
                {/* add /edit modal */}
                < AlertDialog
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    header={isEdit === true ? t("Edit Inventory Item") : t("Add New Inventory Item")}
                    medium
                    component={< AddPopup t={t} isEdit={isEdit} companyId={selectedCompany?.value} setIsOpen={setIsOpen} editData={editData} reload={reload} />}
                />
                {/* delete modal */}
                <AlertDialog
                    open={isdelete}
                    onClose={() => setIsDelete(false)}
                    isnotTitle={true}
                    isNormal
                    component={<Delpopup t={t} handleClose={() => setIsDelete(false)} />}
                />
                {/*filter component */}
                {
                    drawer && (
                        <FilterGenerator
                            open={drawer}
                            onClose={() => setDrawer(false)}
                            components={[
                                {
                                    component: "toggleButton",
                                    value: filterData?.is_active,
                                    state_name: "is_active",
                                    label: t("Status"),
                                    options: StatusOptionList,
                                    isMulti: true
                                },
                                {
                                    component: "toggleButton",
                                    value: filterData?.is_inventory,
                                    state_name: "type",
                                    label: t("Product Type"),
                                    options: productType,
                                    isMulti: true
                                },

                            ]}
                            onApply={(value) => handleFilter(value)}
                        />
                    )
                }
            </>
        )
    }
    return (
        <>
            {accessCheckRender(render, permissions)}
        </>

    )
}
export default withNamespaces("inspection")(InventoryMaster)