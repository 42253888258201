import { makeStyles } from "@mui/styles";
import { Bold, Italic } from "../../utils";

export const ListTableStyle = makeStyles((theme) => ({
    tableCell: {
        border: `1px solid ${theme.palette.border.primary}`,
        padding: "0px 6px",
        height:46,
        width:232
    },
    title: {
        color: theme.typography.color.secondary,
        fontFamily:Bold,
        fontSize:"0.875rem",
        textAlign: "-webkit-auto"
    },
    content: {
        color: theme.typography.color.primary,
        fontFamily: Bold,
        fontSize:"0.875rem",
        marginInlineEnd:"12px",
        direction: "ltr"
    },
    autogenerate:{
        fontSize:"0.875rem",
        fontFamily:Italic,
        color: theme.typography.color.tertiary,
        marginLeft:"8px"
    },
    edit:{
        cursor:"pointer"
    }
}))