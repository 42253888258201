import * as React from "react"

const StarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={8.421} height={8} {...props}>
    <path
      d="M1.899 8.001a.613.613 0 0 1-.4-.152.625.625 0 0 1-.184-.659c.051-.209.125-.5.2-.808.132-.513.277-1.082.358-1.443-.2-.116-.5-.305-.94-.606a2.843 2.843 0 0 1-.9-1.117.632.632 0 0 1 .085-.554.615.615 0 0 1 .5-.26h.225a4.906 4.906 0 0 0 .888-.037 3.378 3.378 0 0 0 .42-.109 3.606 3.606 0 0 1 .487-.123 11.633 11.633 0 0 1 .9-1.82.612.612 0 0 1 1.1.059 18.066 18.066 0 0 0 .818 1.655 8.634 8.634 0 0 1 2.519.4.622.622 0 0 1 .44.5.626.626 0 0 1-.267.613 7.324 7.324 0 0 0-1.617 1.424 9 9 0 0 1 .451 2.291.628.628 0 0 1-.269.591.607.607 0 0 1-.645.024 18.3 18.3 0 0 0-1.917-.991c-.363.181-1.229.645-1.96 1.045a.614.614 0 0 1-.292.077Zm5.9-4.969ZM4.057.624c0 .006 0 .012.008.018Z"
      fill="#78b1fe"
    />
  </svg>
)

export default StarIcon
