import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { useStyles } from "./styles";
import { Label } from "../../../../components";
import { useRef, useState } from "react";
export const CustomDropdown = ({
  t = () => false,
  label = "",
  isRequired = false,
  placeholder = "select",
  value = "",
  on_change = () => false,
  options = [],
  root_height = "40px",
  root_padding = "10px",
  menu_item_height = "40px",
}) => {
  const dropdown_ref = useRef(null);
  const classes = useStyles({
    root_height,
    root_padding,
    menu_item_height,
    menu_item_width: dropdown_ref?.current?.clientWidth,
  });
  const [anchorEl, set_anchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    set_anchorEl(event?.currentTarget);
  };

  const handleAction = (key, value) => {
    if (key === "select") {
      on_change(value);
    }
    set_anchorEl(null);
  };

  return (
    <Box>
      {label && <Label isRequired={isRequired} label={label} />}
      <Box ref={dropdown_ref} className={classes.root}
        onClick={handleClick}>
        <Typography className={classes.root_label}>
          {t(value?.label ?? value ?? placeholder)}
        </Typography>
        <KeyboardArrowDown className={classes.arrow_icon} />
      </Box>
      <Menu className={classes.menu}
        open={open} anchorEl={anchorEl}
        onClose={() => handleAction("close")}>
        {options?.length > 0
          ? options.map((_) => {
            return (
              <MenuItem className={classes.menu_item}
                onClick={() => handleAction("select", _)}>
                <Typography className={classes.menu_item_label}>
                  {t(_.label)}
                </Typography>
              </MenuItem>
            );
          })
          : <MenuItem className={classes.menu_item}
            onClick={() => handleAction("close")}>
            <Typography className={classes.menu_item_label}>
              {t("no options")}
            </Typography>
          </MenuItem>
        }
      </Menu>
    </Box>
  );
};
