import { concat_string, LocalStorageKeys, timeZoneConverter } from "../../utils";
const selectedCompany = JSON.parse(localStorage.getItem(LocalStorageKeys.selectedCompany))

export const constructDetailJSON = ({ data = {} }) => {
    const final_data = [
        [ // Contact Information:
            {
                is_header: true,
                layout: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, },
                label: "Contact Information",
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Contact Name",
                value: data?.lead_name,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Job Title",
                value: data?.job_title,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Company",
                value: data?.company,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Business Mobile",
                value: concat_string(
                    { code: data?.business_phone_country_code, number: data?.business_phone, },
                    ["code", "number",], "-",
                ),
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Mobile Phone",
                value: concat_string(
                    { code: data?.mobile_country_code, number: data?.mobile_no, },
                    ["code", "number",], "-",
                ),
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Email",
                value: data?.email,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Address 1",
                value: data?.address_1,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Address 2",
                value: data?.address_2,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "City",
                value: data?.city,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Country",
                value: data?.country_master?.country_name,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Website",
                value: data?.website,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Preferred Contact",
                value: data?.preferred_mode_contact,
            },
        ],
        [ // Referral and Showcase:
            {
                is_header: true,
                layout: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, },
                label: "Referral and Showcase",
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Referrer Name",
                value: data?.referrer_name,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Referrer Company",
                value: data?.referrer_company,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Showcase",
                value: data?.is_showcase ? "Yes" : "No",
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Last Showcase Date",
                value: data?.last_showcase_date ? timeZoneConverter(data?.last_showcase_date) : "-",
            },
        ],
        [ // Lead Information:
            {
                is_header: true,
                layout: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, },
                label: "Lead Information",
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Purpose",
                value: data?.purpose,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Type",
                value: data?.revenue_type,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "From Date",
                value: data?.lease_start_date ? timeZoneConverter(data?.lease_start_date) : "-",
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Duration",
                value: concat_string(
                    { value: data?.value?.toString(), period: data?.lease_period, },
                    ["value", "period",], " ",
                ),
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 },
                label: "Property Name",
                value: data?.property?.name,
              },
              {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 },
                label: "Unit Type",
                value: data?.unit_type_master?.name,
              },
        ],
        [ // Purchase and Budget Information:
            {
                is_header: true,
                layout: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, },
                label: "Purchase and Budget Information",
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Existing Customer",
                value: data?.is_existing_customer ? "Yes" : "No",
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Lead External Source",
                value: data?.lead_source,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Lead Internal Source",
                value: data?.internal_lead_source?.name,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Budget",
                value: data?.budget,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Budget Amount",
                value: data?.budget_amount ? `${data?.budget_amount} ${selectedCompany?.currency_symbol}` : "-",
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Confirm Interest",
                value: data?.is_confirm_interest ? "Yes" : "No",
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Expected Close Period",
                value: data?.expected_close_period,
            },
            {
                layout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 3, },
                label: "Monthly/Total Budget",
                value: data?.monthly_salary ? `${data?.monthly_salary} ${selectedCompany?.currency_symbol}` : "-",
            },
        ],
    ];

  return final_data;
};

export const constructFieldDataJSON = ({ data = {} }) => {
    const final_data = {
        lead_name: data?.lead_name ?? "",
        job_title: data?.job_title ?? "",
        company: data?.company ?? "",
        business_mobile: {
            mobile_code: data?.business_phone_country_code,
            mobile: data?.business_phone,
        },
        mobile_phone: {
            mobile_code: data?.mobile_country_code,
            mobile: data?.mobile_no,
        },
        email: data?.email ?? "",
        address_1: data?.address_1 ?? "",
        address_2: data?.address_2 ?? "",
        city: data?.city ?? "",
        country_name: data?.country_master?.id
            ? data?.country_master?.country_name
            : "",
        website: data?.website ?? "",
        preferred_mode_contact: data?.preferred_mode_contact
            ? {
                label: data?.preferred_mode_contact,
                value: data?.preferred_mode_contact,
            }
            : "",
        referrer_name: data?.referrer_name ?? "",
        referrer_company: data?.referrer_company ?? "",
        is_showcase: data?.is_showcase,
        last_showcase_date: data?.last_showcase_date
            ? new Date(data?.last_showcase_date)
            : "",
        purpose: data?.purpose ?? "",
        revenue_type: data?.revenue_type ?? "",
        lease_start_date: data?.lease_start_date ? timeZoneConverter(data?.lease_start_date) : "",
        duration: {
            value: data?.value,
            select: data?.lease_period
        },
        description: data?.description ?? "",
        is_existing_customer: data?.is_existing_customer,
        lead_source: data?.lead_source ?? "",
        internal_lead_source: data?.internal_lead_source?.name ?? "",
        budget: data?.budget
            ? {
                label: data?.budget,
                value: data?.budget,
            }
            : "",
        budget_amount: data?.budget_amount ?? "",
        is_confirm_interest: data?.is_confirm_interest,
        expected_close_period: data?.expected_close_period
            ? {
                label: data?.expected_close_period,
                value: data?.expected_close_period,
            }
            : "",
        monthly_salary: data?.monthly_salary?.length ? data?.monthly_salary : undefined
    };

  return final_data;
};
