import * as React from "react"

const EditIconSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} {...props}>
    <path
      d="M9.792 0a2.2 2.2 0 0 0-1.563.645L1.237 7.636a1.105 1.105 0 0 0-.281.479l-.937 3.282a.474.474 0 0 0 .585.585l3.282-.937a1.108 1.108 0 0 0 .478-.281l6.991-6.992A2.208 2.208 0 0 0 9.792 0Zm0 .942a1.258 1.258 0 0 1 .893.373 1.256 1.256 0 0 1 0 1.787l-.613.613-1.786-1.788.613-.613a1.259 1.259 0 0 1 .893-.372ZM7.616 2.597l1.783 1.787-5.705 5.706a.16.16 0 0 1-.068.04l-2.462.7.7-2.462a.154.154 0 0 1 .04-.068Z"
      fill="#5078e1"
    />
  </svg>
)

export default EditIconSvg