export const Violations = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <defs>
            <style>{".cls-1{fill:#f2f4f7;opacity:0}"}</style>
        </defs>
        <g
            id="Group_98470"
            data-name="Group 98470"
            transform="translate(-1515 246)"
        >
            <g
                id="Group_8128"
                data-name="Group 8128"
                transform="translate(7899 1282)"
            >
                <path
                    id="icons8-judge"
                    d="M8.849 3A3.655 3.655 0 1 0 12.5 6.655 3.663 3.663 0 0 0 8.849 3Zm0 6.213Zm6.043 1.014a.544.544 0 0 0-.387.161l-.13.129-2.067 2.067-.129.129a.548.548 0 1 0 .775.775l.129-.129.775.775-3.618 3.618a.731.731 0 1 0 1.034 1.034l3.618-3.618.775.775-.129.129a.548.548 0 0 0 .775.775l1.422-1.421.775-.775.129-.129a.548.548 0 1 0-.775-.775l-.129.129-2.585-2.584.13-.13a.548.548 0 0 0-.388-.935ZM4.454 11.772A1.456 1.456 0 0 0 3 13.226v.556a3.359 3.359 0 0 0 1.94 2.947 8.246 8.246 0 0 0 3.909.891c.205 0 .411-.009.617-.021a1.439 1.439 0 0 1 .257-.363l.348-.347.548-.549c.125-.216.134-.022.691-3.472a1.269 1.269 0 0 1 .352-.672l.424-.424Z"
                    transform="translate(-6383 -1527)"
                    style={{
                        fill: props?.fill ?? "#c1c5cb",
                    }}
                />
            </g>
        </g>
    </svg>
)
