import { Box, Grid } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Subheader } from "../../components";
import { AuthContext } from "../../contexts";
import { accessCheckRender, getRoutePermissionNew } from "../../utils";
import { accountFormState } from "../../utils/receipt";
import { AccountList, Details } from "./sections";
import { ReceiptStyles } from "./styles";

const CreateReceipt = ({ t }) => {
    const classes = ReceiptStyles()
    const history = useHistory();
    const { state } = useLocation()
    const [selected, setSelected] = React.useState({});
    const [data, setData] = React.useState({ ...accountFormState });
    const [list, setList] = React.useState();
    const auth = React.useContext(AuthContext)
    const [permission, setPermission] = React.useState({})
    const [count, setCount] = React.useState(0)

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
        }
        // eslint-disable-next-line
    }, [auth]);

    //validate form
    const validateForm = () => {
        let isValid = true;
        let error = data?.error;
        if (data?.amountTopay?.length === 0) {
            isValid = false;
            error.amountTopay = "Amount To Pay is Required";
        }
        if (data?.paymentmethod?.length === 0) {
            isValid = false;
            error.paymentmethod = "Payment Method is Required";
        }
        if (data?.paymentmethod?.value === "Cheque") {
            if (data?.bankName?.length === 0) {
                isValid = false;
                error.bankName = "Bank Name is Required";
            }
            if (data?.branch?.length === 0) {
                isValid = false;
                error.branch = "Branch is Required";
            }
            if (data?.chequeNo?.length === 0) {
                isValid = false;
                error.chequeNo = "Cheque Number is Required";
            }
            if (data?.clearanceStatus?.length === 0) {
                isValid = false;
                error.clearanceStatus = "Clearance Status is Required";
            }
            if (data?.depoiteDate?.length === 0) {
                isValid = false;
                error.depoiteDate = "Depoite Date is Required";
            }
        }
        setData({ ...data, error });
        return isValid;
    }

    const render = () => {
        return <>
            {/* subheader */}
            <Subheader title={t("createReceipt")} goBack={() => history.goBack()} />
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={2.5}>
                        {/* account list */}
                        <Box className={classes.root}>
                            <AccountList
                                setSelected={setSelected}
                                selected={selected}
                                company={state?.company}

                            />
                        </Box>
                    </Grid>
                    <Grid item xs={9.5}>
                        {/* details */}
                        <Box className={classes.detailRoot}>
                            <Details
                                permission={permission}
                                validateForm={validateForm}
                                type={state?.type}
                                currency={state?.company?.currency_symbol ?? ""}
                                company={state?.company}
                                selected={selected}
                                data={data}
                                setData={setData}
                                list={list}
                                setList={setList}
                                count={count}
                                setCount={setCount}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>
    )
}

export default withNamespaces("receipt")(CreateReceipt); 