import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { CreateBackupFormUseStyles } from './styles';
import { CloseIconWithBG } from '../../../assets';
import { DatePickerTimeNew, Label, TextBox } from '../../../components';
import { SlectedTypes, SlectedUnTypes } from '../utils/constant';

export const CreateManualBackupForm = ({
    handleClose = () => false,
    state,
    updateState = () => false,
    isButtonDisable = false,
    handleCreateBackup = () => false,
    t
}) => {
    const classes = CreateBackupFormUseStyles()

    return (
        <div className={classes.root}>
            <Stack className={classes.header} direction={"row"}
                spacing={"12px"} p={"16px"} alignItems={"center"} sx={{backgroundColor:"#F2F4F7"}}>
                <div className={classes.close_button} onClick={handleClose}>
                    <CloseIconWithBG />
                </div>

                <Typography className={classes.header_title}>{t("Create Manual Backup")}</Typography>
            </Stack>
            <div className={classes.content}>
                <Grid container spacing={"20px"}>
                    <Grid item xs={12}>
                        <Label isRequired label={t("Backup Type")} />
                        <Grid container spacing={1}>
                            {
                                [{value:"Schedule" , label:t("Schedule")},{value:"Now", label:t("Now")}]?.map((x) => {
                                    return (
                                        <Grid item lg={4} md={4} sm={4} sx={6}>
                                            <Box className={state?.type === x?.value ? classes.boxRootSelect : classes.boxRoot} onClick={() => updateState("type", x?.value)}>
                                                {
                                                    state?.type === x?.value ? SlectedTypes[x?.value] : SlectedUnTypes[x?.value]
                                                }
                                                <Typography className={state?.type === x?.value ? classes.typeNameSelect : classes.typeName}>{x?.label}</Typography>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }

                        </Grid>


                    </Grid>
                    <Grid item xs={12}>
                        <TextBox
                            isrequired
                            label={t("Backup Title")}
                            placeholder={t("Enter Backup Title")}
                            value={state?.title}
                            onChange={(e) => updateState("title", e?.target?.value)}
                            isError={state?.error?.title?.length > 0}
                            errorMessage={state?.error?.title} />
                    </Grid>
                    {
                        state?.type === "Schedule" &&
                        <Grid item xs={12}>
                            <DatePickerTimeNew
                                isRequired
                                label={t("Backup Schedule Date And Time")}
                                placeholder={t("Select Schedule Date And Time")}
                                dateFormat={"dd MMM yy, HH:mm"}
                                isTime
                                minDate={new Date()}
                                value={state?.schedule_date}
                                onChange={(value) => updateState("schedule_date", value)}
                                isError={state?.error?.schedule_date?.length > 0}
                                errorMessage={state?.error?.schedule_date} />
                        </Grid>
                    }
                </Grid >
            </div >
            <Stack className={classes.footer} direction={"row"}
                p={"16px"} alignItems={"center"}>
                <Button className={classes.create_button}
                    variant="contained" fullWidth disabled={isButtonDisable}
                    onClick={handleCreateBackup} >{t("Create Backup")}</Button>
            </Stack>
        </div >
    )
}