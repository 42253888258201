import React from "react"

export const RevenueIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_57219" data-name="Rectangle 57219" width="20" height="20" transform="translate(15.369 262.369)" fill="#ff4b4b"/>
      </clipPath>
    </defs>
    <g id="Group_112322" data-name="Group 112322" transform="translate(-13981 -7826)">
      <g id="Group_112321" data-name="Group 112321">
        <g id="Group_112320" data-name="Group 112320">
          <g id="Rectangle_57218" data-name="Rectangle 57218" transform="translate(13981 7826)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
            <rect width="24" height="24" stroke="none"/>
            <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
          </g>
          <g id="Group_112319" data-name="Group 112319" transform="translate(13967.631 7565.631)" clip-path="url(#clip-path)">
            <path id="icons8-price-tag" d="M14,0H9.6A2.4,2.4,0,0,0,7.9.7L.7,7.9A2.4,2.4,0,0,0,.7,11.3l4,4a2.4,2.4,0,0,0,3.394,0l7.2-7.2A2.4,2.4,0,0,0,16,6.4V2A2,2,0,0,0,14,0ZM12,5.2A1.2,1.2,0,1,1,13.2,4,1.2,1.2,0,0,1,12,5.2Z" transform="translate(23.988 261.644) rotate(45)" fill="#e4e8ee"/>
            <path id="icons8-price-tag-2" data-name="icons8-price-tag" d="M14,0H9.6A2.4,2.4,0,0,0,7.9.7L.7,7.9A2.4,2.4,0,0,0,.7,11.3l4,4a2.4,2.4,0,0,0,3.394,0l7.2-7.2A2.4,2.4,0,0,0,16,6.4V2A2,2,0,0,0,14,0ZM12,5.2A1.2,1.2,0,1,1,13.2,4,1.2,1.2,0,0,1,12,5.2Z" transform="translate(23.988 261.645) rotate(45)" fill="none" stroke="#4e5a6b" stroke-width="1"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
  

)