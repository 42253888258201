import makeStyles from "@mui/styles/makeStyles";

export const DashboardStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 16px 80px",
    backgroundColor: theme.palette.background.secondary,
    height: `calc(100vh - 64px)`,
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "10px 10px 80px",
    },
  },
  loader: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    height: (props) => (props?.height ? props?.height : "100%"),
    padding: (props) => props?.padding ?? "19px",
    minHeight: (props) => (props?.maxHeight ?? "100%"),
    width: "100%",
    overflow: "auto",
    display: 'flex',
    flexFlow: (props) => props?.flexFlow ?? "column",
  },
  hdrtext: {
    textTransform: "capitialize"
  }
}));
