import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone'
import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"
import { addDays, addMonths, addYears } from 'date-fns'
import React, { useContext } from "react"
import { useHistory } from 'react-router-dom'
import { AreaIcon } from "../../../assets/propertyBoard/areaIcon"
import { FurnishIcon } from "../../../assets/propertyBoard/furnishIcon"
import LinkIcon from "../../../assets/propertyBoard/linkIcon"

import { CompanyToggle, CustomSelectOptions, DatePickerNew, TextBox } from "../../../components"
import { config } from '../../../config'
import { AlertContext } from '../../../contexts'
import { NetworkCall } from '../../../networkcall'
import { AlertProps, enumSelect, enum_types, NetWorkCallMethods } from '../../../utils'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import { BathIcon, BedIcon } from "../../propertyFinder4/components"
import { getColor } from "./card"
import { useStyles } from "./style"

export const UnitCard = ({ t, val = {}, selectType = "", formData = {}, setFormData = () => false, updateState = () => false, purposeTypeOptions = [], resourceOptions = [], closeDialog = () => false, reload = () => false }) => {

    const classes = useStyles()
    const alert = useContext(AlertContext)
    const history = useHistory()
    const [copyLink, setCopyLink] = React.useState(false)
    const [enumValue, setEnumValue] = React.useState({ duration_type: [] })
    const [isDisableBtn, setIsDisableBtn] = React.useState(false)
    React.useEffect(() => {
        getEnum()
        //eslint-disable-next-line
    }, [])
    const copyUrl = (link) => {
        navigator.clipboard.writeText(link)
        setCopyLink(!copyLink)
    }
    const manualResponse = (array) => array?.map(_ => {
        return {
            ..._,
            label: _?.first_name,
            value: _?.id,
        }
    });
    const validateForm = () => {
        let isValid = true;
        let error = formData.error;

        if (formData?.resource?.length === 0) {
            isValid = false;
            error.resource = "Resource is Required";
        }
        if (formData?.duration?.length === 0) {
            isValid = false;
            error.duration = "Duration is Required";
        }
        setFormData({ ...formData, error });
        return isValid;
    }
    // enum get
    const getEnum = async () => {
        const result = await enumSelect([enum_types.duration_type]);
        setEnumValue({ duration_type: result?.duration_type })
    };
    //share unit
    const ShareUnit = (val) => {
        history.push(`/${val?.route}`)
    }
    //Block unit
    const BlockUnit = () => {
        if (validateForm()) {
            setIsDisableBtn(true)
            let payload = {
                "upsert_fields": {
                    "unit_id": val?.data?.id,
                    "lead_id": formData?.resource?.id,
                    "from": new Date(),
                    "to": formData?.end_period,
                    "duration": formData?.duration,
                    "duration_type": formData?.duration_type,
                    "purpose": formData?.blocking_purpose
                }
            }

            NetworkCall(
                `${config.api_url}/unit_block_requests/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            ).then((response) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("Block unit Request Created Successfully"),
                });
                reload(val?.data?.id, val)
                closeDialog()


            }).catch((error) => {
                setIsDisableBtn(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something went wrong"),

                });
                closeDialog()
            })
        }
    }
    const handleDurationDate = (type, duration, duration_type) => {
        // if(type==="value"){
        //     updateState("duration",duration)
        // }
        // else{
        //     updateState("duration_type",duration_type)

        // }
        const start_date = new Date()
        let endDate = new Date()
        if (duration_type === "Days") {
            endDate = addDays(start_date, duration);

        }
        else if (duration_type === "Months") {
            endDate = addMonths(start_date, duration);

        }
        else {
            endDate = addYears(start_date, duration);

        }
        updateState("end_period", endDate, type === "value" ? "duration" : "duration_type", type === "value" ? duration : duration_type)
    }
    return (
        <>
            <Box p={2}>
                <Box minHeight="auto" className={classes.blockdetailsbox} p={selectType === "share" ? "0px !important" : "12px"}>
                    <Stack direction="row" spacing={2} alignItems="center" p={selectType !== "share" ? "0px !important" : "12px"}>
                        <Box>
                            <Box className={classes.textOnimg}>
                                {val.image !== "" ?
                                    <img src={val.image} className={classes.unitImage} alt="square" />
                                    :
                                    <Avatar src={"/images/propertyImagePlaceholder.svg"} variant="square" className={classes.unitImage} />
                                }
                                <Box className={classes?.property_type} style={{ backgroundColor: getColor(val?.property_type)?.background }}><Typography className={classes.property_text} style={{ color: "#FFFFFF" }}>{val?.property_type}</Typography></Box>

                            </Box>
                        </Box>
                        {/* <Grid item xs={1}></Grid> */}
                        <Box>
                            <Grid container spacing={1} alignItems={"center"}>
                                <Grid item xs={9}>
                                    <Box>
                                        <Typography className={classes.amounttxt} >{val?.amount ?? 0} {val?.currency_symbol} / {val?.payment_period === "Monthly" ? t("Month") : val?.payment_period}</Typography>
                                        <Box>
                                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                                <Typography className={selectType === "share" ? classes.shareUnitDetails : classes.unitDetails}>{val?.unit_name}</Typography>
                                                <Box className={classes.dotunit}></Box>
                                                <Typography className={selectType === "share" ? classes.shareUnitDetails : classes.unitDetails}>{val?.unit_type}</Typography>

                                            </Stack>
                                        </Box>
                                    </Box >
                                </Grid >
                                <Grid item xs={3}>
                                    <Typography className={classes.subtext} style={{ color: getColor(val?.data?.vacany_status)?.color, backgroundColor: getColor(val?.data?.vacany_status)?.background }}>{val?.data?.vacany_status}</Typography>

                                </Grid >
                                {/* </Grid> */}

                                {/* <Grid container spacing={2} alignItems="center"> */}

                                {
                                    val?.bed &&
                                    <Grid item xs={5.5}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box><BedIcon /></Box>
                                            <Typography className={selectType === "share" ? classes.shareFloortxt : classes.floorTxt}>{`${val?.bed} Bedrooms`}</Typography>

                                        </Stack>
                                    </Grid>
                                }
                                {val?.bathrooms &&
                                    <Grid item xs={5.5} >
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box className={classes.dotunit}></Box>
                                            <Box><BathIcon /></Box>
                                            <Typography className={selectType === "share" ? classes.shareFloortxt : classes.floorTxt}>{`${val?.bathrooms} Baths`}</Typography>

                                        </Stack >
                                    </Grid >
                                }

                                {/* </Grid> */}
                                {/* <Grid container spacing={2} alignItems="center"> */}
                                {
                                    val?.furnishing &&
                                    <Grid item xs={5.5} >
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box><FurnishIcon /></Box>
                                            <Typography className={selectType === "share" ? classes.shareFloortxt : classes.floorTxt}>{val?.furnishing}</Typography>

                                        </Stack>
                                    </Grid>
                                }

                                {val?.area &&
                                    <Grid item xs={5.5} >
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box className={classes.dotunit}></Box>
                                            <Box><AreaIcon /></Box>
                                            <Typography className={selectType === "share" ? classes.shareFloortxt : classes.floorTxt}>{val?.area}</Typography>

                                        </Stack >
                                    </Grid >
                                }
                            </Grid >

                        </Box >

                    </Stack >
                    {selectType === "share" &&
                        <Grid item xs={12}>
                            <Box>
                                <Divider />
                            </Box>
                            <Box m={1.5} className={classes.sharelink} display="flex" justifyContent={"space-between"}>
                                <Stack direction="row" spacing={1} alignItems="center" overflow="hidden" p={1} onClick={() => ShareUnit(val)} style={{ cursor: "pointer" }}>
                                    <LinkIcon width={115} />
                                    <Typography className={classes.linktxt} noWrap>{val.link}</Typography>
                                </Stack>
                                <Box className={copyLink ? classes.copiedBox : classes?.copyBox} style={{ marginLeft: copyLink ? "-80px" : "0px" }} onClick={() => copyUrl(val?.link)}>
                                    {copyLink ?
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <ContentCopyTwoToneIcon style={{ color: "white", fontSize:"1rem" }} />
                                            <Typography className={classes.copytext}>{t("Copied")}</Typography>

                                        </Stack>

                                        : <ContentCopyTwoToneIcon style={{ color: "#5078E1", fontSize:"1rem" }} />}
                                </Box>
                            </Box>
                        </Grid >
                    }
                </Box >
                <Box mt={2}>
                    {selectType !== "share" &&
                        <>
                            <Stack direction="row" alignItems="center" spacing={0.5}>
                                <Typography className={classes.amenitiesText}>{selectType === "reserve" ? t("Reserving for") : t("Blocking for Opportunity")}</Typography>
                                <Typography variant="caption" className={classes.required}>*</Typography>
                            </Stack>

                            <Grid container mt={1}>
                                {/* <Grid item xs={6} className={classes.searchBorderRight}>
                                    <SelectBox label="" placeholder="Search" value={formData?.purpose} onChange={(val) => updateState("purpose", val)} options={purposeTypeOptions} noBorder={true} />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <CustomSelectOptions
                                        label=""

                                        placeholder={t("Search Resource")}
                                        value={formData?.resource}
                                        onChange={(val) => updateState("resource", val)}
                                        loadOptions={(search, array, handleLoading) =>
                                            loadOptionsApis(
                                                "opportunity",
                                                {
                                                    "unit_id": val?.id,
                                                    "offset": 0,
                                                    "limit": 10,
                                                    "search": search
                                                },
                                                search,
                                                array,
                                                handleLoading,
                                                "data",
                                                {},
                                                manualResponse
                                            )
                                        }
                                        isPaginate={true}
                                        required

                                        error={formData?.error?.resource?.length > 0}
                                        errorText={formData?.error?.resource}
                                        // noBorder={true}
                                        maxMenuHeight="150px"
                                        menuPlacement="bottom"
                                    />
                                </Grid>
                            </Grid>
                            <Box mt={1} mb={1}>
                                {selectType === "block" &&
                                    <Stack direction="row" alignItems="center" spacing={0.5}>
                                        <Typography className={classes.amenitiesText}>{t("Duration")}</Typography>
                                        <Typography variant="caption" className={classes.required}>*</Typography>
                                    </Stack>


                                }
                            </Box>
                            <Grid container spacing={2}>

                                {selectType === "reserve" &&
                                    <>
                                        <Grid item xs={6}>
                                            <DatePickerNew
                                                value={formData?.start_period ?? new Date()}

                                                label={t("Reserving Start period")}
                                                handleChange={(val) => updateState("start_period", val)}
                                            // isError={val?.error?.length > 0}
                                            // errorMessage={val?.error}
                                            // isReadonly={val?.isReadonly}
                                            // isrequired={val?.isRequired}
                                            // minDate={val?.minDate}
                                            // maxDate={val?.maxDate}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DatePickerNew
                                                value={formData?.end_period ?? new Date()}

                                                label={t("Reserving End period")}
                                                handleChange={(val) => updateState("end_period", val)}
                                            // isError={val?.error?.length > 0}
                                            // errorMessage={val?.error}
                                            // isReadonly={val?.isReadonly}
                                            // isrequired={val?.isRequired}
                                            // minDate={val?.minDate}
                                            // maxDate={val?.maxDate}
                                            />
                                        </Grid>
                                    </>
                                }
                                {selectType === "block" &&
                                    <>

                                        <Grid item xs={4}>
                                            <TextBox
                                                value={formData?.duration}
                                                label={""}
                                                placeholder={t("Enter Duration")}
                                                onChange={(val) => handleDurationDate("value", val?.target.value, formData?.duration_type)}
                                                isError={formData?.error?.duration?.length > 0}
                                                errorMessage={formData?.error?.duration}
                                                isrequired={true}
                                                type="number"

                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <CompanyToggle
                                                options={enumValue?.duration_type}
                                                value={formData?.duration_type}
                                                onChange={(value) => handleDurationDate('duration_type', formData?.duration, value)}
                                                isMulti={true}
                                                fullWidth={false}
                                                // custom={custom}
                                                height="46px"
                                            // customValue={customValue}
                                            // openCustom={openCustom}
                                            // onCustomChange={(value) => onChangeValue('value', value)}
                                            />
                                        </Grid>
                                    </>
                                }

                            </Grid >
                            <Box mt={1} mb={1}>
                                {selectType === "block" &&
                                    <Typography className={classes.amenitiesText}>{t("Blocking Purpose")}</Typography>
                                }
                            </Box>
                            <Grid container spacing={2}>


                                <Grid item xs={12} mb={4}>
                                    <TextBox
                                        placeholder={t("Enter Blocking Purpose")}
                                        value={formData?.blocking_purpose}

                                        label={t("Blocking Purpose")}
                                        onChange={(val) => updateState("blocking_purpose", val?.target?.value)}
                                        height={"100px"}
                                        rows={4}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    }

                </Box >
                {
                    selectType !== "share" &&
                    <Stack mt={8} direction="row" spacing={1} alignItems="center">

                        <Button variant="outlined" fullWidth className={classes.closebtncmn} onClick={closeDialog}>{t("Close")}</Button>
                        <Button variant="contained" fullWidth onClick={() => BlockUnit()} disabled={isDisableBtn}>{selectType === "reserve" ? t("Reserve") : t("Block")}</Button>
                    </Stack >
                }
            </Box >

        </>
    )
}