import {
    Grid
} from "@mui/material";
import React from "react";
import { useWindowDimensions } from "../../utils";
import { OtpPage } from "./otpPage";
import { useStyles } from "./style";

export const Otp = (props) => {
    const size = useWindowDimensions()
    const classes = useStyles(size);

    return (
        <div className={classes.root}>
            <Grid container className={classes.grid}>
                <Grid item xs={12} sm={6} className={classes.logo}>
                    <Grid container className={classes.image}>
                        <img src="/images/login_logo.svg" alt="company_logo" width={300} />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <OtpPage />
                </Grid>
            </Grid>
        </div>
    );
};

