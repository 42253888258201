import { Box, Grid } from "@mui/material";
import React from "react";
import {
  TerminationDocument,
  SkipForm,
  DeclineForm,
  ApprovalSend,
  ApprovalApproved
} from "../../../assets";
import {
  Assigned,
  ExpiredIcon,
  Finace,
  KYC,
} from "../../../assets/agreementTimelineStatusIcon";
import { AlertDialog, DialogBox, TemplateGenerator } from "../../../components";
import {
  StatusComponent,
  Form,
  ApprovedDialog
} from "../components";
import DetailCard from "../components/detailCard";
import TimelineForm from "../components/timelineForm";
import { useStyles } from "../styles";
import {
  renderFunctionTitle,
  renderFunctionSub,
  renderFunctionTitleType,
  renderFunctionSubType,
  getNextstatus
} from "../../../utils/agreementUtils";
import { Cards } from './cardComponents';
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { config } from "../../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import NoDataFound from "../../../assets/noData";
import { PreviewComponent } from "../components"
export const Agreement = ({
  list = [],
  details = {},
  reload = () => false,
  t,
  i = 0,
  is_status_card = false,
  agreement_id = "",
  is_loaded = "",
  statusType = [],
  type = "",
  load = "",
  step = 0,
  showType = false,
  is_terminated = true,
  is_overall = false,
  terminated = false,
  show_card = true,
  kycRequest = false,
  is_request = false,
  agreementReload = () => false,
  parentAgreement = [],
  is_request_allowed = false,
  is_termination_request_allowed = false,
  is_renewal_request_allowed = false,
  is_owner=false
}) => {
  const classes = useStyles();
  // context
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);


  const auth = React.useContext(AuthContext)
  const [template, setTemplate] = React.useState(false);
  const [templateType, setTemplateType] = React.useState(false);

  // state
  const [open, setOpen] = React.useState({
    bool: false,
    type: "",
    isType: false,
    isUnderOnboard: false,
    skipBool: false,
    Decline: false,
    cardShow: false,
    is_view_pdf: false,
    data_type: null,
    approval_status: null,
    is_declined: false,
  });
  const [remark, setRemark] = React.useState({
    skip: "",
    decline: "",
  });
  const [cancel, setCancel] = React.useState(false)
  const [approved, setApproved] = React.useState({
    data: "",
    bool: false
  })
  const [disable, setDisable] = React.useState(false)
  const onRemarkChange = (key, value) => {
    setRemark({ ...remark, [key]: value });
  };
  const [preview, setPreview] = React.useState({
    bool: false,
    template_id: null
  })
  const [selectedData , seSelectedData] = React.useState({
    data:null,
    is_skip:false,
    open:false
  })
  const onClick = (val, data, isType, isUnderOnboard, is_show, status, data_type) => {
    // if(data_type==="agreement_approval_send" && status==="Approved"){
    //   return updateApproved("open", data, "Create")
    // }
    if (data?.type === "Approved" && data?.status === "Review") {
      return updateApproved("open", data, "Intiate")
    }
    if (data?.type === "Approved" && data?.status === "KYC Screening") {
      return updateApproved("open", data, "Create")
    }
    if (data?.type === "Approved" && data?.status === "Finance Review") {
      return updateApproved("open", data, "Create")
    }
    if (data?.status === "Finance Review" && data?.type === "Declined") {
      return updateApproved("open", data, "Finance_Create")
    }
    if (data?.status === "Onboarding" && data?.type === "Declined") {
      return updateApproved("open", data, "Create")
    }
    // updateStatus(data)
    setOpen({
      ...open,
      bool: !open?.bool,
      type: val ?? open?.type,
      data: data,
      isType,
      isUnderOnboard,
      cardShow: is_show,
      data_type: data_type,
      approval_status: status,
      is_declined: false
    });
  };
  const generate = async (value, is_open) => {

    setTemplate(false)
    const payload = {
      id: agreement_id,
      type_master_id: value?.value ?? undefined

    };
    await NetworkCall(
      `${config.api_url}/agreement/template`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      });
  }
  const onClickSkip = (val, data, isType, isUnderOnboard, is_show, data_type) => {
    setOpen({
      ...open,
      skipBool: !open?.skipBool,
      type: val ?? open?.type,
      data: data,
      isType,
      isUnderOnboard,
      data_type: data_type
    });
  };
  const onClickDecline = (val, data) => {
    setOpen({
      ...open,
      Decline: !open?.Decline,
      type: val ?? open?.type,
      data: data,
    });
  };
  const renderFunction = (val, type, v) => {
    switch (val) {
      case "Live":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <img src="/images/onBoard.svg" alt="onBoard" />
            )}
          </Box>
        );
      case "Approved":
        return (
          <>
            <Box>
              {type?.length > 0 ? (
                <>
                  {type === "Declined" ? (
                    <img src="/images/exicutedDeclined.svg" alt="Declined" />
                  ) : (
                    <img src="/images/onBoard.svg" alt="onBoard" />
                  )}
                </>
              ) : (
                <img src="/images/onBoard.svg" alt="onBoard" />
              )}
            </Box>
          </>
        );
      case "Review":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <Assigned />
            )}
          </Box>
        );
      case "KYC Screening":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <KYC />
            )}
          </Box>
        );
      case "Finance Review":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              (v?.data_type === "approval_declined" || v?.data_type === "landlord_approval_declined") ?
                <ApprovalSend bg={"#9426262E"} color={"#FF4B4B"} />
                :
                (v?.data_type === "agreement_approval_send" || v?.data_type === "landlord_approval_send") ?
                  <ApprovalSend bg={"#EDE4FE80"} color={"#896DB3"} />
                  :
                  (v?.data_type === "approval_completed" || v?.data_type === "invoice_created") ?
                    <ApprovalApproved bg={"#EEF9EE"} color={"#5AC782"} />
                    :
                   ( v?.data_type === "skip_invoice_approval" || v?.data_type === "skip_landlord_approval") ?
                      <ApprovalSend bg={"#FEEAEA80"} color={"#FF9340"} />
                      :
                      <Finace />
            )}
          </Box>
        );
      case "Confirmed":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <Assigned />
            )}
          </Box>
        );
      case "Onboarding":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <KYC />
            )}
          </Box>
        );
      case "Terminated":
        return (
          <Box>
            {type?.length > 0 ? (
              <>
                {type === "Declined" ? (
                  <img src="/images/exicutedDeclined.svg" alt="Declined" />
                ) : (
                  <img src="/images/onBoard.svg" alt="onBoard" />
                )}
              </>
            ) : (
              <img src="/images/termintaed.svg" alt="Declined" />
            )}
          </Box>
        );
      case "Expired":
        return (
          <Box>
            {type?.length > 0 ? (
              <ExpiredIcon />
            ) : (
              <img src="/images/termintaed.svg" alt="Declined" />
            )}
          </Box>
        );
      case "Declined":
        return (
          <Box>
            {type?.length > 0 ? (
              <img src="/images/exicutedDeclined.svg" alt="Declined" />
            ) : (
              <img src="/images/termintaed.svg" alt="Declined" />
            )}
          </Box>
        );
      default:
        return (
          <Box>
            <img src="/images/onBoard.svg" alt="onBoard" />
          </Box>
        );
    }
  };

  const onSendPreview = (val, is_resend = false) => {
    const payload = {
      agreement_id,
      template_id: is_resend ? undefined : val,
      is_resend
    };
    NetworkCall(
      `${config.api_url}/agreement_tracker/send_preview`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((res) => {
      if (is_resend) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Agreement Resend successfully"),
        });
      } else {
        setTemplate(false)
        reload();
      }
    }).catch((err) => {
      console.log(err)
    })


  }
  const onBtnClick = (type, template_id) => {
    if (type === "resend") {
      onSendPreview(null, true)
    }
    else if (type === "view") {
      setPreview({
        bool: true,
        template_id
      })
    }
    else {
      setOpen({
        ...open,
        is_view_pdf: true,
        template_id
      })
      setTemplate(true)

    }
  }
  const renderCompoent = (val, details) => {
    switch (val?.status) {
      case "Review":
        return (
          <>
            <DetailCard

              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
              details={details}
              type={val?.type}
              approvedBy={'Completed'}
            />

          </>
        );
      case "KYC Screening":
        return (
          <>
            <DetailCard
              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
              details={details}
              type={val?.type}
              approvedBy={'Verified'}

            />
          </>
        );
      case "Finance Review":
        return (
          <>
            <DetailCard
              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
              details={details}
              isFinace
              type={val?.type}
              approvedBy={'Approved'}
              onBtnClick={onBtnClick}
              is_invoice_created={list?.filter((c)=>c?.data_type === "landlord_approval_send")?.length}
            />
          </>
        );
      case "Confirmed":
        return (
          <>
            <DetailCard
              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
              details={details}
              type={val?.type}
              approvedBy={'Approved'}
            />
          </>
        );
      case "Onboarding":
        return (
          <>
            <DetailCard
              data={val}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
              details={details}
              type={val?.type}
              approvedBy={'Approved'}
            />
          </>
        );
      case "Approved":
        return (
          <>
            <DetailCard data={val}
              details={details}
              type={val?.type}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
              approvedBy={'Approved'} />
          </>
        );
      case "Draft":
        return (
          <>
            <DetailCard
              data={val}
              details={details}
              backgroundColor={"#fff"}
              backgroundColorCard={"#F5F7FA"}
              isDraft={true}
              type={val?.type}
            />
          </>
        );
      default:
        return <DetailCard data={val} details={details} type={val?.type} approvedBy={'Approved'} />;

    }
  };

  // TERMINATED AGREEMENT
  const termintation = () => {
    const variables = {
      agreement_id: details?.id,
      is_agreement: true,
      is_declined: open?.is_declined
    };

    NetworkCall(
      `${config.api_url}/agreement/cancel_agreement`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data?.data?.data === "Cancellation not possible as there are live invoices for this agreement") {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Cancellation not possible as there are live invoices for this agreement"),
          });
          return cancelDialog()
        }
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Terminated Successfully"),
        });
        reload();
        cancelDialog()
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const cancelDialog = () => {
    setCancel(!cancel)
    setOpen({ ...open, is_declined: !open?.is_declined })
  }
  //update status
  const updateStatus = (value) => {
    setDisable(true)
    let variables = {
      agreement_id: details?.id,
      status: getNextstatus(approved?.data?.status),
      contact_id: details?.contact?.id,
      type: approved?.type === "Create" ? "Approved" : undefined
    };

    if ((approved?.data?.status === "KYC Screening" && approved?.data?.type === "Approved") || (approved?.data?.type === "Finance Review" && approved?.data?.type === "Declined")) {
      variables["type"] = undefined
    }
    if (approved?.data?.status === "Finance Review" && approved?.data?.type === "Declined") {
      variables["status"] = "Finance Review"
    }
    if (approved?.data?.status === "KYC Screening" && approved?.data?.type === "Approved") {
      variables["data_type"] = is_owner ? "landlord_approval" : "invoice_created"
    }
    NetworkCall(
      `${config.api_url}/agreement_tracker`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        if (res?.data?.data?.type === "error") {

          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: res?.data?.data?.msg
          });
          setDisable(false)
          return updateApproved("close")

        }
        setDisable(false)

        updateApproved("close")
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Log Created Successfully"),
        });
        reload();
      })
      .catch((err) => {
        setDisable(false)

        console.log(err);
      });
  };

  const updateApproved = (key, data, type) => {
    if (key === "close") {
      setApproved({ ...approved, bool: !approved?.bool })
    }
    else {
      setApproved({ ...approved, data: data, bool: !approved?.bool, type: type })
    }
  }

  const popupTitle = {
    "Intiate": t("initiate"),
    "Create": t("Are you sure you want to create?"),
    "Finance_Create": t("Are you sure you want to create?")
  }

  const sendPreview = () => {
    setTemplate(true)
  }

  const landloardClick = (type, data, isStatus, isOnboard, is_show, landload_type) => {
    if(type === "skip"){
      seSelectedData({
        data:data,
        type,
        open:true
      })
    }
    else{
      sendPreview(true)
      setTemplateType(true)
      seSelectedData({
        data:data,
        type,
        open:false
      })
    }
      }
  const createLandlord = (val)=>{
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "...sending",
    });
    setDisable(true)
    let variables = {
      agreement_id: details?.id,
      contact_id: details?.contact?.id,
      data_type:selectedData?.type === "create" ? "landlord_approval_send" : "skip_landlord_approval",
      status: "Finance Review",
      type: undefined,
      template_id: val ?? undefined,
    };
    NetworkCall(
      `${config.api_url}/agreement_tracker`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((res) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "...sending",
        });
        if (res?.data?.data?.type === "error") {

          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: res?.data?.data?.msg
          });
          setDisable(false)
          setTemplate(false)
          setTemplateType(false)
          return seSelectedData({data:null,is_skip:false , open:false})

        }
        setDisable(false)

        seSelectedData({data:null,is_skip:false , open:false})
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Log Created Successfully"),
        });
        setTemplate(false)
          setTemplateType(false)
        reload();
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "...sending",
        });
        setDisable(false)

        console.log(err);
      });
  }


  return (
    <Box>
      <Grid container spacing={12}>
        <Grid item xs={9}>
          <Box>
            <Box className={classes.parent}>
              {list?.length > 0 ?
                <Timeline sx={{ padding: "0px" }}>
                  {
                    list?.map((val, index, array) => {

                      return (
                        <TimelineItem sx={{ padding: "0px" }}>
                          <TimelineSeparator>
                            <TimelineDot className={classes.timeLineDot}>
                              {
                                (index === 0 &&
                                  terminated === false &&
                                  terminated === false &&
                                  is_status_card === false &&
                                  val?.status !== "Live" &&
                                  val?.status !== "Declined" &&
                                  val?.status !== "Declined" &&
                                  val?.status !== "Terminated"

                                ) ?
                                  <TerminationDocument /> :
                                  renderFunction(val?.status, val?.type, val)
                              }
                            </TimelineDot>
                            {array?.length - 1 !== index && <TimelineConnector className={classes.timeLineConnector} />}
                          </TimelineSeparator>
                          {
                            auth?.auth?.auth?.language === "ar" ?
                              <TimelineOppositeContent>
                                <Box sx={{ marginTop: "-16px" }}>
                                  <Box>
                                    {index === 0 &&
                                      terminated === false &&
                                      terminated === false &&

                                      is_status_card === false &&
                                      val?.status !== "Live" &&
                                      val?.status !== "Declined" &&
                                      val?.status !== "Declined" &&
                                      val?.status !== "Terminated" && (
                                        <Box sx={{
                                          maxWidth: "502px",
                                          width: "100%",
                                        }}>
                                          {((val?.data_type === "agreement_approval_send" || val?.data_type === "landlord_approval_send") && !val?.approval?.status)
                                            ?
                                            null
                                            :
                                            <StatusComponent
                                              data={val}
                                              t={t}
                                              sendPreview={sendPreview}
                                              onClick={onClick}
                                              cancelDialog={cancelDialog}
                                              title={
                                                val?.type?.length > 0
                                                  ? renderFunctionTitle(
                                                    val?.status,
                                                    val?.type,
                                                    val?.data_type,
                                                    val?.approval?.status
                                                  )
                                                  : renderFunctionTitleType(
                                                    val?.status,
                                                    val?.type,
                                                    val?.data_type,
                                                    val?.approval?.status

                                                  )
                                              }
                                              sub={
                                                val?.type?.length > 0
                                                  ? renderFunctionSub(val?.status, val?.type, val?.data_type, val?.approval?.status)
                                                  : renderFunctionSubType(
                                                    val?.status,
                                                    val?.type,
                                                    val?.data_type,
                                                    val?.approval?.status
                                                  )
                                              }
                                              isOnboard={
                                                val?.type?.length > 0
                                                  ? val?.type !== "Declined"
                                                    ? true
                                                    : false
                                                  : true
                                              }
                                              isButton={val?.type?.length > 0 ? false : true}
                                              marginLeft={
                                                val?.type?.length > 0 ? "0px" : "10px"
                                              }
                                              isStatus={
                                                val?.type?.length > 0
                                                  ? val?.type === "Declined"
                                                    ? true
                                                    : false
                                                  : true
                                              }
                                              is_show={val?.type?.length > 0 ? false : true}
                                              onClickSkip={onClickSkip}
                                              onClickDecline={onClickDecline}
                                              index={i}
                                              status={val?.status}

                                              disableBtn={kycRequest}
                                              data_type={val?.data_type}
                                              landloardClick={landloardClick}
                                              createLandlord={createLandlord}
                                            />
                                          }
                                        </Box>
                                      )}
                                    {(val?.show !== "is_show") && (renderCompoent(val, details))}
                                  </Box >
                                </Box>
                              </TimelineOppositeContent> :
                              <TimelineContent>
                                <Box sx={{ marginTop: "-16px" }}>
                                  <Box>
                                    {index === 0 &&
                                      terminated === false &&
                                      terminated === false &&

                                      is_status_card === false &&
                                      val?.status !== "Live" &&
                                      val?.status !== "Declined" &&
                                      val?.status !== "Declined" &&
                                      val?.status !== "Terminated" && (
                                        <Box sx={{
                                          maxWidth: "502px",
                                          width: "100%",
                                        }}>
                                          {((val?.data_type === "agreement_approval_send" || val?.data_type === "landlord_approval_send") && !val?.approval?.status)
                                            ?
                                            null
                                            :
                                            <StatusComponent
                                              data={val}
                                              t={t}
                                              sendPreview={sendPreview}
                                              onClick={onClick}
                                              cancelDialog={cancelDialog}
                                              title={
                                                val?.type?.length > 0
                                                  ? renderFunctionTitle(
                                                    val?.status,
                                                    val?.type,
                                                    val?.data_type,
                                                    val?.approval?.status
                                                  )
                                                  : renderFunctionTitleType(
                                                    val?.status,
                                                    val?.type,
                                                    val?.data_type,
                                                    val?.approval?.status

                                                  )
                                              }
                                              sub={
                                                val?.type?.length > 0
                                                  ? renderFunctionSub(val?.status, val?.type, val?.data_type, val?.approval?.status)
                                                  : renderFunctionSubType(
                                                    val?.status,
                                                    val?.type,
                                                    val?.data_type,
                                                    val?.approval?.status
                                                  )
                                              }
                                              isOnboard={
                                                val?.type?.length > 0
                                                  ? val?.type !== "Declined"
                                                    ? true
                                                    : false
                                                  : true
                                              }
                                              isButton={val?.type?.length > 0 ? false : true}
                                              marginLeft={
                                                val?.type?.length > 0 ? "0px" : "10px"
                                              }
                                              isStatus={
                                                val?.type?.length > 0
                                                  ? val?.type === "Declined"
                                                    ? true
                                                    : false
                                                  : true
                                              }
                                              is_show={val?.type?.length > 0 ? false : true}
                                              onClickSkip={onClickSkip}
                                              onClickDecline={onClickDecline}
                                              index={i}
                                              status={val?.status}

                                              disableBtn={kycRequest}
                                              data_type={val?.data_type}
                                              landloardClick={landloardClick}
                                            />
                                          }
                                        </Box>
                                      )}
                                    {(val?.show !== "is_show" ) && (renderCompoent(val, details))}
                                  </Box >
                                </Box>
                              </TimelineContent>}
                        </TimelineItem>
                      )
                    })
                  }

                </Timeline>
                :
                <Box className={classes.noDataBox}>
                  <NoDataFound />
                </Box>
              }

              {/* {Array.isArray(list) &&
                list?.map((val, index) => {
                  return (
                    <Box className={classes.treeBox}>
                      {index === 0 &&
                        terminated === false &&
                        terminated === false &&

                        is_status_card === false &&
                        val?.status !== "Live" &&
                        val?.status !== "Declined" &&
                        val?.status !== "Declined" &&
                        val?.status !== "Terminated" && (
                          <Box className={auth?.auth?.auth?.language === "ar" ? classes.borderRtl : classes.border}>
                            <Box className={auth?.auth?.auth?.language === "ar" ? classes.avatarRtl : classes.avatars}>
                              <TerminationDocument />
                            </Box>
                            <StatusComponent
                              data={val}
                              t={t}
                              onClick={onClick}
                              title={
                                val?.type?.length > 0
                                  ? renderFunctionTitle(
                                    val?.status,
                                    val?.type
                                  )
                                  : renderFunctionTitleType(
                                    val?.status,
                                    val?.type
                                  )
                              }
                              sub={
                                val?.type?.length > 0
                                  ? renderFunctionSub(val?.status, val?.type)
                                  : renderFunctionSubType(
                                    val?.status,
                                    val?.type
                                  )
                              }
                              isOnboard={
                                val?.type?.length > 0
                                  ? val?.type !== "Declined"
                                    ? true
                                    : false
                                  : true
                              }
                              isButton={val?.type?.length > 0 ? false : true}
                              marginLeft={
                                val?.type?.length > 0 ? "0px" : "10px"
                              }
                              isStatus={
                                val?.type?.length > 0
                                  ? val?.type === "Declined"
                                    ? true
                                    : false
                                  : true
                              }
                              is_show={val?.type?.length > 0 ? false : true}
                              onClickSkip={onClickSkip}
                              onClickDecline={onClickDecline}
                              index={i}
                              status={val?.status}

                              disableBtn={kycRequest}
                            />
                          </Box>
                        )}
                      {val?.show !== "is_show" && (
                        <Box className={auth?.auth?.auth?.language === "ar" ? classes.borderRtl : classes.border}>
                          <Box
                            className={
                              val?.assignee !== null
                                ? auth?.auth?.auth?.language === "ar" ? classes.avatarRtl : classes.avatars
                                : auth?.auth?.auth?.language === "ar" ? classes.avatarRtl : classes.avatars1
                            }
                          >
                            {renderFunction(val?.status, val?.type)}
                          </Box>
                          {renderCompoent(val)}

                        </Box >
                      )}
                    </Box >
                  );
                })} */}
            </Box >
          </Box >
        </Grid >
        <Grid item xs={3}>
          {
            !is_loaded &&
            <Cards details={details} reload={reload} t={t} agreement_id={agreement_id} statusType={statusType} type={type} loadApi={load} step={step} showType={showType} termintation={termintation} cancel={cancel} cancelDialog={cancelDialog} is_terminated={terminated ? true : is_terminated} is_overall={is_overall} show_card={show_card} is_request={is_request} agreementReload={agreementReload} parentAgreement={parentAgreement}
              is_request_allowed={is_request_allowed}
              is_termination_request_allowed={is_termination_request_allowed}
              is_renewal_request_allowed={is_renewal_request_allowed}
            />
          }

        </Grid>
      </Grid >
      <TemplateGenerator t={t} name="Preview Agreement" onClick={templateType ? createLandlord : onSendPreview} contact={templateType ? details?.owner : details?.contact} is_timeline open={template} onClose={() => {setTemplate(false); setTemplateType(false)}} type={3} generate={generate} />

      <AlertDialog
        isNormal={true}
        header={t(`${open?.type === "assign" ? t("Assign Task") : open?.type}`)}
        onClose={() => onClick(null)}
        open={open?.bool}
        component={
          <TimelineForm
            reload={reload}
            data={open?.data}
            type={open?.type}
            isType={open?.isType}
            isUnderOnboard={open?.isUnderOnboard}
            company={details?.company?.id}
            details={details}
            onClose={() => onClick(null)}
            is_show={open?.cardShow}
            approval={open}
          />
        }
      />

      <AlertDialog
        isNormal={true}
        header={t(`Skip This Process`)}
        onClose={() => {
          onClickSkip(null);
          onRemarkChange("skip", "");
        }}
        open={open?.skipBool}
        component={
          <>
            <Form
              image={<SkipForm />}
              title={t(`Skip This Process`)}
              descrition={t(`Skip_des`)}
              remarks={remark?.skip ?? ""}
              t={t}
              onChange={(e) => onRemarkChange("skip", e)}
              btnText={{
                btn1: t(`No`),
                btn2: t(`Yes_Skip`),
              }}
              no={() => {
                onClickSkip(null);
                onRemarkChange("skip", "");
              }}
              reload={reload}
              data={open?.data}
              type={open?.type}
              isType={open?.isType}
              isUnderOnboard={open?.isUnderOnboard}
              company={details?.company?.id}
              details={details}
              data_type={open?.data_type}
            />
          </>
        }
      />

      <AlertDialog
        isNormal={true}
        header={t(`Decline`)}
        onClose={() => {
          onClickDecline(null);
          onRemarkChange("decline", "");
        }}
        open={open?.Decline}
        component={
          <>
            <Form
              image={<DeclineForm />}
              title={t(`Decline`)}
              descrition={t(`Declined_des`)}
              remarks={remark?.decline ?? ""}
              t={t}
              onChange={(e) => onRemarkChange("decline", e)}
              btnText={{
                btn1: t(`No`),
                btn2: t(`Yes`),
              }}
              no={() => {
                onClickDecline(null);
                onRemarkChange("decline", "");
              }}
            />
          </>
        }
      />

      {/*  */}
      <DialogBox
        isnotTitle
        onClose={() => updateApproved("close")}
        header={approved?.type}
        open={approved?.bool}
        maxWidth={"sm"}
        isNormal={true} component={
          <Box>
            <ApprovedDialog title={popupTitle[approved?.type]} sub={t("The process cannot be reverted once done")} t={t} termination={updateStatus} cancel={() => updateApproved('close')} disable={disable} />

          </Box >
        } />

<DialogBox
        isnotTitle
        onClose={() => seSelectedData({data:null,is_skip:false , open:false})}
        header={"Skip This Process"}
        open={selectedData?.open}
        maxWidth={"sm"}
        isNormal={true} component={
          <Box>
            <ApprovedDialog title={"Skip This Process"} sub={t("The process cannot be reverted once done")} t={t} termination={()=>createLandlord()} cancel={() => seSelectedData({data:null,is_skip:false , open:false})} disable={disable} />

          </Box >
        } />

      <PreviewComponent
        t={t}
        open={preview?.bool}
        onClose={() => {
          setPreview({
            bool: false,
            template_id: null
          })
          seSelectedData({data:null,is_skip:false , open:false})
        }} id={details?.id} template_id={preview?.template_id} is_landloard={selectedData?.open}/>


    </Box >
  );
};
