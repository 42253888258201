import * as React from "react"

const EventIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={62} height={80} {...props}>
    <defs>
      <filter
        id="a"
        x={0}
        y={0}
        width={62}
        height={80}
        filterUnits="userSpaceOnUse"
      >
        <feOffset />
        <feGaussianBlur stdDeviation={4} result="blur" />
        <feFlood floodOpacity={0.071} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g data-name="Group 5194" transform="translate(-66.596 -204.867)">
      <rect
        data-name="Rectangle 45494"
        width={38}
        height={51}
        rx={8}
        transform="rotate(-14.98 886.305 -142.705)"
        fill="#d0f2f6"
      />
      <rect
        data-name="Rectangle 45495"
        width={38}
        height={52}
        rx={8}
        transform="rotate(14.98 -769.974 454.197)"
        fill="#d0f2f6"
      />
      <g transform="translate(66.6 204.87)" filter="url(#a)">
        <rect
          data-name="Rectangle 45493"
          width={38}
          height={56}
          rx={8}
          transform="translate(12 12)"
          fill="#cffaff"
        />
      </g>
      <g data-name="Group 6952">
        <path
          data-name="Rectangle 48446"
          fill="none"
          d="M85.596 232.867h24v24h-24z"
        />
        <path
          d="M90.938 235.01a3.207 3.207 0 0 0-3.2 3.2v.739h17.743v-.739a3.207 3.207 0 0 0-3.203-3.2Zm-3.2 5.422v9.118a3.207 3.207 0 0 0 3.2 3.2h5.7a6.371 6.371 0 0 1-1.014-3.45c0-.13.012-.255.019-.383l-.626.468a.634.634 0 0 1-.985-.7l.572-1.828-1.687-1.28a.634.634 0 0 1 .383-1.139h2.082l.628-1.825a.633.633 0 0 1 1.2 0l.628 1.825h.024a6.385 6.385 0 0 1 7.621-.535v-3.471Zm14.293 3.45a5.422 5.422 0 1 0 5.422 5.422 5.422 5.422 0 0 0-5.422-5.422Zm-.493 1.971a.493.493 0 0 1 .493.493v2.957h2.464a.493.493 0 1 1 0 .986h-2.957a.493.493 0 0 1-.493-.493v-3.45a.493.493 0 0 1 .493-.493Z"
          fill="#30afce"
        />
      </g>
    </g>
  </svg>
)

export default EventIcon
