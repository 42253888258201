import React from "react";
import { withNavBars } from "../../HOCs";
import CreateFloor from "./createFloor";

class CreateFloorParent extends React.Component {
    render() {
        return <CreateFloor />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(CreateFloorParent, props);
