import { TabContext, TabList } from "@mui/lab";
import { Box, Button, Drawer, Grid, Tab } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { LoadingSection, SearchFilter, Subheader, TableWithPagination } from "../../components";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, accessCheckRender, generalGroupDataType, generalGroupHeading, generalGroupPath, getCompanyOption, getRoutePermissionNew, itemGroupDataType, itemGroupHeading, itemGroupPath, unitGroupDataType, unitGroupHeading, unitGroupPath } from "../../utils";
import { CreateForm } from "./components/createForm";
import { useStyles } from "./style";
import { tabOptions } from "./util";

const CheckListGroup = ({ t }) => {
    const classes = useStyles()
    const history = useHistory()
    const auth = React.useContext(AuthContext);
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const [selectedCompany, setSelectedCompany] = React.useState({})
    const [companyList, setCompanyList] = React.useState([])
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [tabValue, setTabValue] = React.useState('1');
    const [loading, setLoading] = React.useState(true)
    const [searchText, setSearchText] = React.useState("")
    const [data, setData] = React.useState({
        group_name: "",
        unit_type: "",
        item_type:"",
        item_category: "",
        item_name: "",
        status: true,
        instructions: "",
        is_Edit: false,
        is_default_template:false,
        error: {
            group_name: "",
            unit_type: "",
            item_type: "",
            item_category: "",
            item_name: ""
        }
    })
    const [tableData, setTableData] = React.useState({
        list: [], count: 0
    })
    const [permission, setPermission] = React.useState({})
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    //get timeline details
    const getCheckListGroupDetails = (offset = 0, limit = 10, company_id, search, groupType = "1") => {
        const group_type = groupType === "1" ? "Unit" : groupType === "2" ? "General" : groupType === "3" ? "Item Group" : "Unit"
        const payload = {
            "company_id": company_id,
            "search": search,
            "start": offset,
            "length": limit,
            check_list_group_type: [group_type]
        }

        NetworkCall(
            `${config.api_url}/check_list_group`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setLoading(false)
            setTableData({ list: response?.data?.data, count: response?.data?.count })
        }).catch((err) => {
            setLoading(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth);
        if (perm) {
            setPermission(perm);
            if (perm?.read) {
                let company = getCompanyOption(backdrop, auth, alert);
                if (company) {
                    setCompanyList(company?.list)
                    setSelectedCompany(company?.selected)
                    getCheckListGroupDetails(0, 10, company?.selected?.value, "", tabValue)
                }
            }
        }
        // eslint-disable-next-line
    }, [auth]);
    const reload = () => {
        getCheckListGroupDetails(0, 10, selectedCompany?.value, "", tabValue)
    }
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        getCheckListGroupDetails(0, 10, selectedCompany?.value, "", newValue)
    };
    const handleCompanyChange = (val) => {
        setSelectedCompany(val)
        getCheckListGroupDetails(0, 10, val?.value, "", tabValue)
    }
    const handleSearch = (val) => {
        setSearchText(val)
        getCheckListGroupDetails(0, 10, selectedCompany?.value, val, tabValue)
    }
    //handle Pagination
    const handlePagination = (value) => {
        setPage(value)
        let offset = (value - 1) * limit
        getCheckListGroupDetails(offset, limit, selectedCompany?.value, "", tabValue)
    }

    const handleChangeLimit = (value) => {
        setLimit(value)
        setPage(1)
        getCheckListGroupDetails(0, value, selectedCompany?.value, "", tabValue)
    }
    const handleIcon = (type, value) => {
        if (type === "view") {
            const group_type = tabValue === "1" ? "Unit" : tabValue === "2" ? "General" : "Item Group"
            history.push(Routes?.checkListGroupView + "?id=" + value?.id + "&type=" + group_type)
        }
        else if (type === "edit") {
            setOpenDrawer(true)
            setData({ ...data, is_Edit: true, id: value?.id })
        }
        else {
            onIsActive(value)
        }
    }
    //on isActive
    //on isActive
    const onIsActive = (value) => {

        const payload = {
            id: value?.id,
            is_active: !value?.is_active,
        }
        NetworkCall(
            `${config.api_url}/check_list_group/is_active_status`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then(rs => {
            reload();
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: t("Updated Successfully"),
            });
        }).catch(er => {
            console.log(er)
        })


    }
    const closeDrawer = () => {
        setOpenDrawer(false)
        setData(
            {
                group_name: "",
                unit_type: "",
                item_type: "",
                item_category: "",
                item_name: "",
                status: true,
                instructions: null,
                is_Edit: false,
                is_default_template:false,
                error: {
                    group_name: "",
                    unit_type: "",
                    item_type: "",
                    item_category: "",
                    item_name: ""
                }
            }
        )
    }
    const render = () => {
        return (
            <Box>
                <Subheader
                    title={t("Check List Group")}
                    hideBackButton
                    select
                    options={companyList}
                    value={selectedCompany}
                    onchange={(e) =>
                        handleCompanyChange(e)
                        // getProperty(e)
                    }
                />
                {
                    loading ?
                        <LoadingSection top="20vh" message={t("loading")} /> :
                        <>
                            <Box className={classes.root}>
                                <Box style={{ width: "100%" }}>
                                    <TabContext value={tabValue}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleTabChange}>
                                                {tabOptions(t)?.map((x) => {
                                                    return (
                                                        <Tab label={t(x?.label)} value={x?.value} className={classes.tabs} />
                                                    )
                                                })}

                                            </TabList>
                                        </Box>
                                    </TabContext>
                                </Box>
                                <Box p={1}>
                                    <Box display="flex" justifyContent={"space-between"} mt={1}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <SearchFilter value={searchText} placeholder={t("Search")}
                                                    handleChange={(value) => handleSearch(value)} />
                                            </Grid>
                                        </Grid>
                                        {permission?.create &&
                                            <Button variant="contained" className={classes.approvebtn} onClick={() => setOpenDrawer(true)}>{t("Create")}</Button>
                                        }
                                    </Box>
                                    <Box mt={2}>
                                        <TableWithPagination
                                            heading={tabValue === "1" ? unitGroupHeading(t) : tabValue === "2" ? generalGroupHeading(t) : itemGroupHeading(t)}
                                            rows={tableData?.list}
                                            path={tabValue === "1" ? unitGroupPath : tabValue === "2" ? generalGroupPath : itemGroupPath}
                                            showpagination={true}
                                            showpdfbtn={false}
                                            showexcelbtn={false}
                                            showSearch={false}
                                            handleIcon={handleIcon}
                                            tableType="no-side"
                                            dataType={tabValue === "1" ? unitGroupDataType : tabValue === "2" ? generalGroupDataType : itemGroupDataType}
                                            handlePagination={handlePagination}
                                            handleChangeLimit={handleChangeLimit}
                                            totalRowsCount={tableData?.count}
                                            page={page}
                                            limit={limit}
                                            height={'calc(100vh - 350px)'}
                                            view={permission.read}
                                            edit={permission?.update}
                                            delete={permission.delete} />
                                    </Box>
                                </Box>
                            </Box>
                            <Drawer
                                anchor={"right"}
                                open={openDrawer}
                                onClose={() => closeDrawer()}
                            >
                                <CreateForm t={t} tabValue={tabValue} onClose={closeDrawer} company={selectedCompany} reload={reload} data={data} setData={setData} />
                            </Drawer>
                        </>
                }
            </Box>


        )
    }

    return (
        <Box>
            {accessCheckRender(render, permission)}
        </Box>

    )
}
export default withNamespaces("checkListGroup")(CheckListGroup)