import * as React from 'react';
import moment from 'moment';
import { Box, Stack, Typography, Menu, MenuItem } from '@mui/material';
import { MonthDropDownStyle } from './style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const MonthDropDown = ({ type = "", list = [], selected = {}, setSelected = () => false }) => {

    const classes = MonthDropDownStyle()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value) => {
        setAnchorEl(null);
        setSelected(moment(value).format(type === "month" ? "MMMM" : "YYYY"))
    };
    return (
        <>
            {
                // type === "month" ?
                <div>
                    <Box
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Stack direction={"row"} justifyContent={"space-between"} className={classes.dropdown} alignItems="center">
                            <Typography className={classes.selected}>
                                {selected}
                            </Typography>
                            <KeyboardArrowDownIcon sx={{ color: "#B6B6B6", fontSize:"0.75rem" }} />
                        </Stack>
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <Box sx={{ height: 300, overflow: "overlay" }}>
                            {
                                list?.map((month) => {
                                    return (
                                        <MenuItem className={selected === moment(month).format(type === "month" ? "MMMM" : "YYYY") ? classes?.selectedMenuItem : classes.menuItem} onClick={() => handleClose(month)}>
                                            {moment(month).format(type === "month" ? "MMMM" : "YYYY")}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Box>
                    </Menu>
                </div>
                // :
                // <div>
                //     <Box
                //         id="basic-button"
                //         aria-controls={open ? 'basic-menu' : undefined}
                //         aria-haspopup="true"
                //         aria-expanded={open ? 'true' : undefined}
                //         onClick={handleClick}
                //     >
                //         <Stack direction={"row"} justifyContent={"space-between"} className={classes.dropdown} alignItems="center">
                //             <Typography className={classes.selected}>
                //                 {selected}
                //             </Typography>
                //             <KeyboardArrowDownIcon sx={{ color: "#B6B6B6", fontSize:"0.875rem" }} />
                //         </Stack>
                //     </Box>
                //     <Menu
                //         id="basic-menu"
                //         anchorEl={anchorEl}
                //         open={open}
                //         onClose={handleClose}
                //         MenuListProps={{
                //             'aria-labelledby': 'basic-button',
                //         }}
                //     >
                //         <Box sx={{ height: 300, overflow: "overlay" }}>
                //             {
                //                 list?.map((month) => {
                //                     return (
                //                         <MenuItem className={classes.menuItem} onClick={() => handleClose(month)}>{moment(month).format("YYYY")}</MenuItem>
                //                     )
                //                 })
                //             }
                //         </Box>
                //     </Menu>
                // </div>
            }
        </>
    );
}