export const MoveIn = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98474">
            <path
                data-name="Path 95032"
                d="M14.842 20.015h-4.416a.441.441 0 0 1-.441-.441V9.426a.441.441 0 0 1 .441-.441h4.416a.441.441 0 0 1 .441.441v10.148a.441.441 0 0 1-.441.441Zm-5.956 1.1h3.309a.221.221 0 0 1 .221.221v.441a.221.221 0 0 1-.221.221H8.442a.441.441 0 0 1-.441-.441V7.441A.441.441 0 0 1 8.442 7h3.75a.221.221 0 0 1 .221.221v.441a.221.221 0 0 1-.221.221h-3.31Zm11.725-7.056-.855-.859a.221.221 0 0 1 0-.312l.312-.312a.221.221 0 0 1 .312 0l1.609 1.609a.441.441 0 0 1 0 .624l-1.609 1.612a.221.221 0 0 1-.312 0l-.312-.312a.221.221 0 0 1 0-.312l.856-.856h-3.57a.221.221 0 0 1-.221-.221v-.441a.221.221 0 0 1 .221-.221h3.567Z"
                fill={props?.fill ?? "#c1c5cb"}
                fillRule="evenodd"
            />
            <path data-name="Rectangle 52163" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
