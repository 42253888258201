import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useStyles } from "./style";
import { CustomDatePicker } from "../../../dashboard/components"
// import { useWindowDimensions } from "../../../../utils";
// import { TableWithInfinityScroll } from "../../../../components";
// import NoDataFound from "../../../../assets/noData";
import { TableDetail } from "../tableDetail/tableDetail";
export const CalendarDetail = (props) => {
  //   classes
  const classes = useStyles(props);
  // const size = useWindowDimensions();
  return (
    <div className={classes.card}>
      <Box className={classes.expensesHeader} display="flex">
        <Box>
          <Typography variant="subtitle2" className={classes.Expensestitle}>
            {props?.title}
          </Typography>
        </Box>
        <Box flexGrow={1}>
          <div style={{ float: "right" }}>
            <CustomDatePicker onlyCalendarIcon getDate={props?.getDate} value={props?.value} />
          </div>
        </Box>
      </Box>
      {/* <Divider /> */}
      <Box >
      {/* style={{ height: size?.height - 380, marginTop: "4px", overflow: "overlay" }} */}
        <Grid
          container
          className={classes.grid}
        >
          {/* Commented As Per CR */}
          {/* {props?.tableData?.length > 0 ?
            <Grid item xs={12} >

              <TableWithInfinityScroll
                heading={props?.heading}
                rows={props?.tableData ?? []}
                path={props?.path}
                showpdfbtn={false}
                showexcelbtn={false}
                showSearch={false}
                tableType="no-side"
                fetchMoreData={props?.fetchMoreData}
                showpagination={false}
                view={true}
                edit={true}
                delete={true}
                dataType={props?.dataType}
                height={props?.tabelheight}
              />
            </Grid>


            : (
              <Grid item xs={12} className={classes.noData}>
                <NoDataFound />
              </Grid>
            )
          } */}
          <Grid item xs={12}>
            <div className={classes.table}>
              <TableDetail
                // title={props?.title}
                tableData={props?.tableData}
                tabelheight={props?.tabelheight}
                heading={props?.heading}
                path={props?.path}
                dataType={props?.dataType}
                fetchMoreData={props?.fetchMoreData}
                noDataSvg
                tableWithPagination
                handleTablePagination={props?.handleTablePagination}
                handleTablePageLimit={props?.handleTablePageLimit}
                limit={props?.limit}
                page={props?.page}
                totalRowsCount={props?.totalRowsCount}
              />
            </div>
          </Grid>
          
        </Grid>
      </Box>
    </div>
  );
};
