export const PropertySearch = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98467">
            <g data-name="017---Property-Search" fill={props?.fill ?? "#c1c5cb"}>
                <path d="m19.175 20.257 1.078-1.078.72.719-1.079 1.078ZM22.777 21.699l-1.442-1.442-1.078 1.078 1.442 1.442a.762.762 0 0 0 1.078-1.078Z" />
                <path
                    data-name="Shape"
                    d="m19.896 18.818-1.078 1.078-.615-.615a6.552 6.552 0 0 0 1.078-1.078ZM11.305 16.645v-3.178l-.1.076a.254.254 0 0 1-.305-.407l3.049-2.288a.254.254 0 0 1 .305 0l3.051 2.288a.254.254 0 1 1-.305.407l-.1-.076v3.178h1.3a4.831 4.831 0 1 0-8.2 0Z"
                />
                <path
                    data-name="Shape"
                    d="M16.39 16.645v-3.56l-2.288-1.716-2.288 1.716v3.559h.763v-2.288a.508.508 0 0 1 .508-.508h1.017a.508.508 0 0 1 .508.508v2.288Z"
                />
                <path
                    data-name="Rectangle-path"
                    d="M13.085 14.356h1.017v2.288h-1.017Z"
                />
                <path data-name="Shape" d="M10.362 17.153a4.831 4.831 0 0 0 7.48 0Z" />
                <path
                    data-name="Shape"
                    d="M14.1 8a6.1 6.1 0 1 0 4.315 1.787A6.1 6.1 0 0 0 14.1 8Zm0 11.441a5.339 5.339 0 1 1 5.341-5.341 5.339 5.339 0 0 1-5.341 5.341Z"
                />
            </g>
            <path data-name="Rectangle 52151" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
