import React from "react"

export const SelectedVendorAsset = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} {...props}>
        <g data-name="Group 118072">
          <path
            fill="#cfd8dc"
            d="M0 18.591a6.613 6.613 0 0 1 5.909-3.545 6.613 6.613 0 0 1 5.909 3.545v2.364H0Zm26 0a6.7 6.7 0 0 0-11.818 0v2.364H26ZM5.909 13.864a2.955 2.955 0 1 0-2.955-2.955 2.954 2.954 0 0 0 2.955 2.955m14.182 0a2.955 2.955 0 1 0-2.955-2.955 2.954 2.954 0 0 0 2.955 2.955"
            data-name="Path 101913"
          />
          <path
            fill="#ffca28"
            d="M16.546 8.545A3.545 3.545 0 1 1 13 5a3.545 3.545 0 0 1 3.545 3.545"
            data-name="Path 101914"
          />
          <path
            fill="#546e7a"
            d="M20.091 18A7.928 7.928 0 0 0 13 13.864 7.928 7.928 0 0 0 5.909 18v2.955h14.182Z"
            data-name="Path 101915"
          />
        </g>
      </svg>

    )
}