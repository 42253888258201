export const ComponentBasedPricingPath = [
    "revenueType",
    "pricingComponent",
    "componentType",
    "value",
    "quantity",
    "taxable",
    "chargeable",
]

export const ComponentBasedPricingHeading = (t) => [
    { title: t("Revenue Type"), field: "revenueType" },
    { title: t("Pricing Component"), field: "pricingComponent" },
    { title: t("Component Type"), field: "componentType" },
    { title: t("Value"), field: "value" },
    { title: t("Qty"), field: "quantity" },
    { title: t("Taxable"), field: "taxable" },
    { title: t("Chargeable"), field: "chargeable" },
    { title: "", field: "icon" },
]

export const ComponentBasedPricingType = [
    { type: ["text"], name: "revenueType" },
    { type: ["long_text"], name: "pricingComponent", max_width: 250, tooltip_placement: "top" },
    { type: ["text"], name: "componentType" },
    { type: ["text"], name: "value" },
    { type: ["text"], name: "quantity" },
    { type: ["text"], name: "taxable" },
    { type: ["text"], name: "chargeable" },
    { type: ["more_2"], icon: "icon" },
];

export const ShortTermPricingPath = [
    "revenueType",
    "pricingComponent",
    "pricingPeriod",
    "valueAndBasis",
    "taxable",
    "chargeable",
]

export const ShortTermPricingHeading = [
    { title: "Revenue Type", field: "revenueType" },
    { title: "Pricing Component", field: "pricingComponent" },
    { title: "Pricing Period", field: "pricingPeriod" },
    { title: "Vaule And Basis", field: "valueAndBasis" },
    { title: "Taxable", field: "taxable" },
    { title: "Chargeable", field: "chargeable" },
    { title: "", field: "icon" },
]

export const ShortTermPricingType = [
    { type: ["text"], name: "revenueType" },
    { type: ["text"], name: "pricingComponent" },
    { type: ["text"], name: "pricingPeriod" },
    { type: ["text"], name: "valueAndBasis" },
    { type: ["text"], name: "taxable" },
    { type: ["text"], name: "chargeable" },
    { type: ["more_2"], icon: "icon" },
];

export const PricingTableViewAllPath = [
    "property_name",
    "property_no",
    "unit_name",
    "unit_no",
    "revenue_type",
    "pricing_component",
    "component_type",
    "value",
    "quantity",
    "taxable",
    "chargeable",
]

export const PricingTableViewAllHeading = (t) => [
    { title: t("property_name"), field: "property_name" },
    { title: t("property_no"), field: "property_no" },
    { title: t("unit_name"), field: "unit_name" },
    { title: t("unit_no"), field: "unit_no" },
    { title: t("revenue_type"), field: "revenue_type" },
    { title: t("pricing_component"), field: "pricing_component" },
    { title: t("component_type"), field: "component_type" },
    { title: t("vaule"), field: "value" },
    { title: t("quantity"), field: "quantity" },
    { title: t("taxable"), field: "taxable" },
    { title: t("chargeable"), field: "chargeable" },
]

export const PricingTableViewAllType = [
    { type: ["text"], name: "property_name" },
    { type: ["text"], name: "property_no" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "revenue_type" },
    { type: ["text"], name: "pricing_component" },
    { type: ["text"], name: "component_type" },
    { type: ["text"], name: "value" },
    { type: ["text"], name: "quantity" },
    { type: ["text"], name: "taxable" },
    { type: ["text"], name: "chargeable" },
]

export const CategoryTypes = {
    primary: {
        table_display_value: "Primary",
    },
    secondary: {
        table_display_value: "Secondary"
    },
    is_onetime: {
        table_display_value: "One Time"
    },
    refundable: {
        table_display_value: "Refundable"
    },
    is_quantity: {
        table_display_value: "Item Based"
    },
    is_parking: {
        table_display_value: "Parking Slot"
    }
}

export const GetTableComponentType = (_) => {
    return (_?.is_onetime
        ? CategoryTypes.is_onetime.table_display_value
        : _?.refundable
            ? CategoryTypes.refundable.table_display_value
            : _?.is_quantity
                ? CategoryTypes.is_quantity.table_display_value
                : _?.is_parking
                    ? CategoryTypes.is_parking.table_display_value
                    : _?.primary
                        ? CategoryTypes.primary.table_display_value
                        : CategoryTypes.secondary.table_display_value)
}

export const ConstructTableRow = (_) =>
({
    id: _?.id,
    property_name: _?.unitByID?.propertyByID?.name ?? "-",
    property_no: _?.unitByID?.propertyByID?.property_no ?? "-",
    unit_name: _?.unitByID?.name ?? "-",
    unit_no: _?.unitByID?.unit_no ?? "-",
    revenue_type: _?.revenue_type,
    pricing_component: _?.rental_breakupByID?.name,
    component_type: GetTableComponentType(_),
    value: _?.value ? (_?.value + " " +
        (_?.value_basis_type === "Amount"
            ? (_?.currencyByID?.code ?? "")
            : _?.value_basis_type === "Percentage"
                ? "%" : _?.value_basis_type === "Per UOM"
                    ? (_?.uom ?? "") : (_?.currencyByID?.code ?? "")) +
        (_?.payment_period ? (" / " + _?.payment_period) : "")) : "-",
    taxable: _?.taxable ? "Yes" : "No",
    quantity: _?.quantity ?? "-",
    chargeable: _?.is_chargeable ? "Yes" : "No",
    data: _,
})