import { makeStyles } from "@mui/styles";
import { Bold, ExtraBold, Regular, SemiBold } from "../../utils";


export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.tertiary1,
        border: `1px solid ${theme.palette.border.secondary}`,
        boxShadow: "0px 0px 16px #00000014",
        minHeight: `calc(100vh - 270px)`,
    },
    content: {
        height: `calc(100vh - 100px)`,
        overflow: "auto"
    },
    accoCardTitle: {
        fontWeight: "bold",
        fontSize:"1rem"
    },
    accoCardButton: {
        fontSize:"0.875rem",
        fontWeight: "normal",
        '&:hover': {
            color: "#fff"
        },
        marginInlineStart: "8px"
    },
    cancelButton: {
        fontSize:"0.875rem",
        backgroundColor: theme.typography.color.white,
        color: theme.palette.primary.main,
        marginInlineStart: "16px",
        border: `1px solid ${theme.palette.border.secondary}`,
        '&:hover': {
            backgroundColor: theme.typography.color.white,

        }
    },
    noData: {
        textAlign: "center",
        padding: "20px",
        fontSize:"1rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary
    },
    completeInsp: {
        padding: "16px"
    },
    nobtn: {
        '&:hover': {
            background: "#fff"
        }
    },
    msg: {
        fontSize:"1rem",
        fontFamily: SemiBold
    },
    iconbtn: {
        border: `1px solid ${theme.palette.border.secondary}`,
        backgroundColor: theme.palette.border.secondary,
        padding: "8px",
        borderRadius: "50%",
        height: "24px",
        width: "24px",
        cursor: "pointer"
    },
    icon: {
        fontSize:"0.875rem",
        marginLeft: "-2px",
    },
    dialoghdrtext: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: Bold,
    },
    Name: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
    },
    cmpbtn: {
        backgroundColor: "#5078E1",
        '&:hover': {
            backgroundColor: "#5078E1",
        }
    },
    cancelbtn: {
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: "white",
        }
    },
    viewTrack: {
        color: "#5078E1",
        fontSize:"0.875rem",
        fontFamily: Bold,
        cursor: "pointer"
    },
    checkIconcircle: {
        backgroundColor: "#5078E1",
        borderRadius: "50%",
        padding: "3px",
        width: "25px",
        height: "25px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center"
    },
    dialogTitle: {
        fontSize:"1rem",
        color: theme.typography.color.primary,
        fontFamily: ExtraBold,
        whiteSpace: "nowrap"

    },
    reqblock: {
        backgroundColor: "#5078E1",
        backgroundImage: `url(${"../images/requestView.svg"})`,
        height: "250px"
      },
      closeicon: {
        color: "white",
        cursor: "pointer"
      },
      q1hdr: {
        color: "white",
        fontFamily: SemiBold,
        fontSize:"0.875rem",
        marginLeft: "15px"
      },
      q1subhdr: {
        color: "white",
        fontFamily: Regular,
        fontSize:"0.75rem",
        marginLeft: "15px"
      },
      yesbtn: {
        backgroundColor: "white",
        color: "#5078E1",
        "&:hover": {
          backgroundColor: "white",
          color: "#5078E1",
        }
      },
      notxtbtn: {
        color: "white",
        border: "1px solid #E4E8EE",
        "&:hover": {
          color: "white",
          border: "1px solid white",
    
        },
        marginInlineStart:"8px"
      },
}))