import React from "react";
import { withNavBars } from "../../HOCs";
import Department from "./department";

class DepartmentParent extends React.Component {
  render() {
    return <Department {...this.props} />;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(DepartmentParent, props);
