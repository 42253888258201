import React from "react";
import { withNavBars } from "../../HOCs";
import FinalSettlement from "./finalSettlement";

class FinalSettlementParent extends React.Component {
  render() {
    return <FinalSettlement {...this.props}/>;
  }
}

const props = {
  boxShadow: false
}

export default withNavBars(FinalSettlementParent, props);
