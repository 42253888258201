import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../../utils";
export const useStylesCreation = makeStyles((theme) => ({
  imagebox: {
    textAlign: 'center',
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
    height: "298px"
  },
  imagebox1: {
    padding: '16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    borderRadius: theme.palette.borderRadius,
  },
  button: {
    padding: '8px 16px',
    borderRadius: theme.palette.borderRadius,
    cursor: 'pointer',
    border: '1px solid #E4E8EE',
    fontSize:"0.75rem",
    fontFamily: SemiBold,
    marginTop: '8px',
  },
  inputAdornment: {
    fontSize:"0.875rem"
  },
  Requirements: {
    padding: "10px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    color: "white",
    fontSize:"0.875rem",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  Requirementsqfts: {
    padding: "10px",
    textAlign: "center",
    borderRadius: theme.palette.borderRadius,
    fontSize:"0.875rem",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
  },
  completedCheckboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "#5078E1",
    border: "1px solid #5078E1",
  },
  checkboxIconStyle: {
    fontSize: "small",
    color: "white",
  },
  checkboxStyle: {
    height: "20px",
    width: "20px",
    backgroundColor: "white",
    border: "1px solid #98A0AC",
  },
  avatar: {
    height: '120px',
    width: '120px'
  },
  title: {
    fontSize:"0.75rem",
    color: '#4E5A6B',
    fontFamily: Bold,
    marginBottom: '12px',
    textTransform: 'capitalize'
  },
  label: {
    color: theme.typography.color.tertiary,
    fontSize:"0.75rem",
  },
  tabListAssests: {
    display: 'flex'
  },
  listText: {
    backgroundColor: '#5078E1',
    width: '120px !important',
    color: '#fff',
    borderRadius: theme.palette.borderRadius,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  listText1: {
    width: '120px !important',
    color: '#98A0AC',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  tabname: {
    fontSize:"0.75rem",
    fontFamily: Bold,
    cursor: 'pointer'
  },
  list: {
    '&.MuiListItem-root': {
      width: '135px !important',
      padding: '0px!important',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      border: '1px solid #E4E8EE',
      cursor: 'pointer'
    }
  },
  next: {
    marginLeft: "10px",
    fontFamily: SemiBold,
    color: "#fff",
    backgroundColor: '#5078E1',
    "&:hover": {
      backgroundColor: '#5078E1',
    },
  },
  Cancel: {
    backgroundColor: "#FFFFFF ",
    color: "#091B29",
    fontSize:"0.875rem",
    fontWeight: 600,
    fontFamily: SemiBold,
    border: '1px solid #E4E8EE',
    "&:hover": {
      backgroundColor: "#FFFFFF ",
    },
  },
  bottombtn: {
    padding: '11px 16px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 16px #00000014',
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #E4E8EE',
    position: 'sticky',
    bottom: '0px',
    width: '100%',
    zIndex: 2,
  },
  removeimg: {
    textDecoration: 'underline',
    color: '#5078E1',
    fontFamily: SemiBold,
    fontSize:"0.75rem",
    marginTop: '6px',
    cursor: 'pointer'
  },
  avatar1: {
    padding: '35px',
    background: '#98A0AC',
    height: '120px',
    width: '120px'
  },
  tabtitle: {
    fontSize:"0.75rem",
    color: '#4E5A6B',
    fontFamily: Bold,
  },
  tabtitle1: {
    fontSize:"0.75rem",
    color: '#5078E1',
    fontFamily: Bold,
  },
  subtabtitle: {
    fontSize:"0.75rem",
    color: '#4E5A6B',
    fontFamily: Regular,
  },
  subtabtitle1: {
    fontSize:"0.75rem",
    color: '#5078E1',
    fontFamily: Regular,
  },
  selectBack1: {
    backgroundColor: '#F1F7FF',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px"
  },
  selectBack: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px'
  },
}));
