import React from "react";
import { withNavBars } from "../../HOCs";
import { OwnerTableDetails } from "./ownerTableDetails";

class OwnerTableDetailsParent extends React.Component {
    render() {
        return <OwnerTableDetails />
    }
}

export default withNavBars(OwnerTableDetailsParent);
