import React from "react";

export const InterestedQuotationsIcon = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_243_2278)">
      <circle cx="25.3999" cy="21.9191" r="21" fill="#CE4281" />
    </g>
    <g clip-path="url(#clip0_243_2278)">
      <path
        d="M34.221 17.4034L27.9519 16.8219L25.4616 11.0393C25.2872 10.6344 24.7132 10.6344 24.5388 11.0393L22.0485 16.8219L15.7794 17.4034C15.3404 17.4441 15.1631 17.9901 15.4942 18.281L20.2243 22.4363L18.84 28.5783C18.7431 29.0084 19.2076 29.3458 19.5866 29.1207L25.0002 25.9062L30.4138 29.1207C30.7929 29.3458 31.2573 29.0084 31.1604 28.5783L29.7761 22.4363L34.5062 18.281C34.8374 17.9901 34.66 17.4441 34.221 17.4034Z"
        fill="white"
      />
      <path
        d="M18.5911 10.8401C18.9107 11.284 19.8128 13.1905 20.3285 14.3015C20.4096 14.4761 20.1905 14.6339 20.0505 14.5016C19.1605 13.6601 17.6387 12.1998 17.3191 11.7559C17.0662 11.4047 17.146 10.9149 17.4973 10.662C17.8485 10.4091 18.3382 10.4889 18.5911 10.8401Z"
        fill="white"
      />
      <path
        d="M31.4087 10.8401C31.0891 11.284 30.187 13.1905 29.6713 14.3015C29.5902 14.4761 29.8094 14.6339 29.9493 14.5016C30.8393 13.6601 32.3611 12.1998 32.6807 11.7559C32.9336 11.4047 32.8539 10.9149 32.5026 10.662C32.1513 10.4091 31.6616 10.4889 31.4087 10.8401Z"
        fill="white"
      />
      <path
        d="M36.4591 23.8577C35.9391 23.6881 33.8487 23.4079 32.6336 23.2542C32.4425 23.23 32.3588 23.4867 32.5273 23.5798C33.5995 24.1721 35.4531 25.1782 35.9731 25.3478C36.3846 25.482 36.827 25.2572 36.9612 24.8457C37.0954 24.4342 36.8706 23.9919 36.4591 23.8577Z"
        fill="white"
      />
      <path
        d="M13.5409 23.8577C14.0609 23.6881 16.1513 23.4079 17.3664 23.2542C17.5575 23.23 17.6412 23.4867 17.4727 23.5798C16.4005 24.1721 14.5469 25.1782 14.0269 25.3478C13.6154 25.482 13.173 25.2572 13.0388 24.8457C12.9046 24.4342 13.1294 23.9919 13.5409 23.8577Z"
        fill="white"
      />
      <path
        d="M24.2402 32.7021C24.2402 32.1551 24.622 30.0809 24.8527 28.8779C24.8889 28.6888 25.1589 28.6888 25.1952 28.8779C25.4258 30.0809 25.8076 32.1551 25.8076 32.7021C25.8076 33.1349 25.4567 33.4857 25.0239 33.4857C24.5911 33.4858 24.2402 33.1349 24.2402 32.7021Z"
        fill="white"
      />
      <path
        d="M26.3671 13.1421L25.4616 11.0393C25.2872 10.6344 24.7131 10.6344 24.5388 11.0393L22.0485 16.8219L15.7794 17.4034C15.3404 17.4441 15.1631 17.9901 15.4942 18.281L20.2243 22.4363L18.84 28.5783C18.7431 29.0084 19.2076 29.3458 19.5866 29.1207L20.3071 28.6929C21.4883 21.0466 24.8091 15.4337 26.3671 13.1421Z"
        fill="#F2EFEF"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_243_2278"
        x="0.399902"
        y="0.919067"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_243_2278"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_243_2278"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_243_2278">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(13 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
