import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { PriorityStyles } from "./style";
import {SelectBox} from "../select";
const PrioritySelect1 = ({
  value = "",
  onChange = () => false,
  options = [],
  isRequired = false,
  label = "",
  placeHolder = "",
  loadOptions = () => false,
  is_dot = false,
  isPaginate=false,
  menuPlacement="bottom",
  backgroundColor="auto"
}) => {
  const colorObject = {
    "low": "#06D6A0",
    "medium": "#FFD166",
    "high": "#EFB93C",
    "urgent": "#EF476F",
    "Plan": "#FFA024",
    "Develop": "#5AC782",
    "Release": "#5078E1",
    "Block": "#EF476F",
  }

  const classes = PriorityStyles();
  
  return (
    <Box>
      <SelectBox
        isRequired={isRequired}
        label={label}
        placeholder={placeHolder}
        borderRadius={"8px"}
        options={options}
        loadOptions={loadOptions}                                            // isPaginate
        debounceTimeout={800}
        onChange={(value) => {
          onChange(value)
        }}
        isPaginate={isPaginate}
        menuPlacement={menuPlacement}
        value={value}
        textColor={value?.value ? colorObject[value?.value] : null}
        textTrasfrom={"capitalize"}
        selectHeight={'40px'}
        customOptionComponent={(props , index) => {
          return (
            <Stack direction={"row"} spacing={1} className={classes.optionParent} style={{backgroundColor:value?.value === props?.data?.value ? "#BBCEFF47" : 'auto'}}>
              {
                is_dot && <Box
                  className={classes.dot}
                  style={{
                    backgroundColor: colorObject[props?.data?.value],
                  }}
                />
              }

              <Typography className={classes.priorityName} style={{color: is_dot ? `${colorObject[props?.data?.value]} !important`  : 'auto'}}>
                {props?.data?.label}
              </Typography>
              {
                options?.length === index + 1 && <Divider/>
              }
            </Stack>
          )
        }}
        color={backgroundColor}
      />
    </Box>
  )
};
export default PrioritySelect1;
