import { Grid } from '@mui/material';
import React from 'react';
import { SearchFilter, Subheader, UseDebounce } from '../../components';
import { AlertContext, AuthContext } from '../../contexts';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, accessCheckRender, getRoutePermissionNew, useWindowDimensions } from '../../utils';
import { useStyles } from "./style";
import { NewLoader } from '../../components/newLoader';
import { ReportCard } from './component/reportCard';
import { useHistory } from 'react-router-dom';
import { BackendRoutes, Routes } from '../../router/routes';
import { NetworkCall } from '../../networkcall';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoDataFound from '../../assets/noData';
import { withNamespaces } from 'react-i18next';
import { config } from '../../config';

const Reports = (props) => {
  const { t, loading, handleLoading } = props;
  const size = useWindowDimensions()
  const limit = 40

  const classes = useStyles()

  // useHooks
  const debounce = UseDebounce()
  const history = useHistory()

  // useContext
  const auth = React.useContext(AuthContext)
  const alert = React.useContext(AlertContext)

  // useState
  const [data, setData] = React.useState([])
  const [searchText, setSearchText] = React.useState("")
  const [permission, setPermission] = React.useState({})
  const [loader, setLoader] = React.useState(true)
  const [offset, setOffset] = React.useState(0)

  // useEffect to get permission and data when loading the screen
  React.useEffect(() => {
    const perm = getRoutePermissionNew(auth)
    if (perm) {
      setPermission(perm)
      if (perm?.read) { getData(offset, limit, searchText, false) }
    }
    // eslint-disable-next-line
  }, [auth])

  // Function to get the data
  const getData = (offset, limit, search, next) => {
    let payload = {
      offset, limit, search,
      module_id: Number(localStorage.getItem(LocalStorageKeys?.activeRoleModuleId))
    }
    NetworkCall(
      `${config?.api_url}${BackendRoutes.reports_get_module_reports}`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((res) => {
      let temp_res = res?.data?.data?.map((_) => {
        return {
          id: _?.id,
          report_name: _?.name ?? "",
          data: _,
        }
      })
      setData(next ? [...data, ...temp_res] : temp_res)
      setLoader(false)
      handleLoading(false)
    }).catch((error) => {
      console.log(error)
      alert.setSnack({
        ...alert, open: true, msg: t("Some Thing Went Wrong"),
        severity: AlertProps.severity.error
      })
    });
  }

  // Function for search in search component
  const handleSearch = (value) => {
    setSearchText(value)
    debounce(() => searchTableFunction(value), 800)
  }

  // Function to search data in the list
  const searchTableFunction = (value) => {
    setOffset(0)
    getData(0, limit, value, false)
  }

  // Function for on clicking category
  const handleReportOnClick = (report) => {
    history.push({
      pathname: Routes?.report,
      state: {
        schema: report?.data?.schema,
        reportName: report?.data?.name,
        reportId: report?.data?.id,
        end_point: report?.data?.end_point,
        main: {
          company: {},
          category_id: report ?? "",

        },
      },
    });
  }

  // Function to handle infinite scroll next
  const handleInfiniteScrollNext = () => {
    let temp_offset = offset + limit
    setOffset(temp_offset)
    getData(temp_offset, limit, searchText, true)
  }

  // Rendering function
  const render = () => {
    return <div>
      <Subheader hideBackButton={true} title={t("Reports")} />
      {
        loader ? (
          <NewLoader minusHeight="100px" />
        ) : (
          <div className={classes.root}>
            <div className={classes.content}>
              <Grid container spacing={"24px"}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <SearchFilter value={searchText} placeholder={t("Search")}
                    handleChange={(value) => handleSearch(value)} />
                </Grid>
                <Grid item xs={12}>
                  {
                    data?.length > 0 ?
                      <InfiniteScroll
                        dataLength={data?.length ?? 0}
                        next={handleInfiniteScrollNext}
                        hasMore={true}
                        height={size?.height - 232}>
                        <Grid container direction={"row"} spacing={"24px"} pb={"16px"}>
                          {data?.map((_) => {
                            return <Grid item
                              xs={12} sm={6} md={4} lg={4}>
                              <ReportCard
                                data={_}
                                onClick={() => handleReportOnClick(_)} />
                            </Grid>
                          })}
                        </Grid>
                      </InfiniteScroll> :
                      <Grid container padding={"16px"} justifyContent={"center"} alignItems={"center"}>
                        <NoDataFound />
                      </Grid>
                  }
                </Grid>
              </Grid>
            </div>
          </div>)}
    </div >
  }

  return <div>
    {accessCheckRender(render, permission, "", loading)}
  </div>
}

export default withNamespaces("reports")(Reports); 
