import * as React from "react"

const CityProperty = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <path
      d="M6.5-.001a2.083 2.083 0 0 0-1.374.462 2.3 2.3 0 0 0-.84 1.786v7.652h1.5A3.3 3.3 0 0 1 9 13.275v4.05a1.624 1.624 0 0 1-.151.675H13.5a.66.66 0 0 0 .643-.675v-13.5a2.238 2.238 0 0 0-1.585-2.173L6.986.077A2.017 2.017 0 0 0 6.5-.001Zm.786 4.047h.857a.44.44 0 0 1 .429.45v.9a.44.44 0 0 1-.429.45h-.857a.44.44 0 0 1-.429-.45v-.897a.44.44 0 0 1 .429-.45Zm3 0h.857a.44.44 0 0 1 .429.45v.9a.44.44 0 0 1-.429.45h-.857a.44.44 0 0 1-.429-.45v-.897a.44.44 0 0 1 .429-.45Zm-3 3.15h.857a.44.44 0 0 1 .429.45v.9a.44.44 0 0 1-.429.45h-.857a.44.44 0 0 1-.429-.45v-.9a.44.44 0 0 1 .429-.447Zm3 0h.857a.44.44 0 0 1 .429.45v.9a.44.44 0 0 1-.429.45h-.857a.44.44 0 0 1-.429-.45v-.9a.44.44 0 0 1 .429-.447ZM15 7.199v3.15h.857a.44.44 0 0 1 .429.45v.9a.44.44 0 0 1-.429.45H15v1.35h.857a.44.44 0 0 1 .429.45v.9a.44.44 0 0 1-.429.45H15v2.7h2.357a.66.66 0 0 0 .643-.674v-7.65a2.425 2.425 0 0 0-2.357-2.476Zm-4.714 3.15h.857a.44.44 0 0 1 .429.45v.9a.44.44 0 0 1-.429.45h-.857a.44.44 0 0 1-.429-.45v-.9a.44.44 0 0 1 .429-.45Zm-7.929.45A2.425 2.425 0 0 0 0 13.275v4.05a.66.66 0 0 0 .643.674H7.5a.66.66 0 0 0 .643-.675v-4.05a2.425 2.425 0 0 0-2.357-2.475Zm7.929 2.7h.857a.415.415 0 0 1 .334.18h.009v.014a.451.451 0 0 1 .085.256v.9a.453.453 0 0 1-.085.257v.014h-.009a.415.415 0 0 1-.334.179h-.857a.44.44 0 0 1-.429-.45v-.9a.44.44 0 0 1 .429-.45Zm-8.143 0H3a.44.44 0 0 1 .429.45v.9a.44.44 0 0 1-.429.45h-.857a.44.44 0 0 1-.429-.45v-.9a.44.44 0 0 1 .429-.45Zm3 0H6a.44.44 0 0 1 .429.45v.9a.44.44 0 0 1-.429.45h-.857a.44.44 0 0 1-.429-.45v-.9a.44.44 0 0 1 .429-.45Z"
      fill={props?.color}
    />
  </svg>
)

export default CityProperty