import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { ProfileCard, RequestMessage } from './../components'
import { RequestPopupStyle } from './style'
import { convertTimeUtcToZoneCalander } from '../../../utils'

export const RequestPopup = ({ openDecline = () => false, openReconcile = () => false, templateData = {}, enumData = {}, t = () => false }) => {

    const classes = RequestPopupStyle();

    const getPaidStatus = () => {
        let status = "Unpaid";
        let status_text_color = "#FF9340 !important";

        if (templateData?.invoice?.invoice_due_amount <= 0) {
            status = "Paid";
            status_text_color = "#5AC782 !important";
        } else if (templateData?.invoice?.invoice_due_amount >= templateData?.invoice?.invoice_total_amount) {
            status = "Unpaid";
            status_text_color = "#FF4B4B !important";
        } else {
            status = "Partially Paid";
            status_text_color = "#FF9340 !important";
        }
        return { status, status_text_color };
    }

    return (
        <Stack m={2} spacing={2}>
            <Box className={classes.content_box}>
                {
                    templateData?.compensated_type !== null && <>
                        {
                            templateData?.compensated_type === "Rejected" ?

                                <RequestMessage type="Rejected" title={t("Declined by ") + " " + templateData?.Created_by?.first_name} message={templateData?.remarks ? templateData?.remarks : "-"} remarks="" /> :
                                <RequestMessage type="Decline" title={t("Reconciled by ") + " " + templateData?.Created_by?.first_name} message={templateData?.remarks ? templateData?.remarks : "-"} remarks="" />

                        }
                    </>
                }

                <Stack className={classes.profileSection} spacing={2} p={2}>
                    <ProfileCard data={templateData} />
                    <Divider></Divider>
                    <Stack direction={"row"} spacing={2}
                        divider={<Divider orientation="vertical" flexItem />}
                        alignItems={"center"} justifyContent={"space-around"}>
                        <Stack direction={"row"}>
                            <Stack marginInlineStart={'40px'}>
                                <Typography className={classes.titleText}>{t("Invoice Number")}</Typography>
                                <Typography className={classes.contentText}> {templateData?.invoice?.invoice_no} </Typography>
                            </Stack>
                            <Stack marginInlineStart={'40px'}>
                                <Typography className={classes.titleText}>{t("Invoice Raised On")}</Typography>
                                <Typography className={classes.contentText}> &#x202a;{convertTimeUtcToZoneCalander(templateData?.invoice?.invoice_date)}&#x202c;</Typography>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Stack>
                                <Typography className={classes.contentText}>{templateData?.invoice?.currency?.symbol} {templateData?.invoice?.invoice_total_amount}</Typography>

                                <Typography className={classes.titleText}
                                    sx={{
                                        color: getPaidStatus?.()?.status_text_color,
                                    }}>
                                    {t(getPaidStatus?.()?.status)}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack >
                </Stack >
                <Box ml={1} p={2} pt={1} pb={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>{t("Date Of Request")}</Typography>
                                <Typography className={classes.contentText}>&#x202a;{convertTimeUtcToZoneCalander(templateData?.created_at)}&#x202c;</Typography>
                            </Stack >
                        </Grid >
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>{t("Payment Method")}</Typography>
                                <Typography className={classes.contentText}> {templateData?.payment_mode} </Typography>
                            </Stack >
                        </Grid >
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>{t("Amount Paid")}</Typography>
                                <Typography className={classes.contentText}>{templateData?.invoice?.currency?.symbol} {templateData?.amount_paid}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>{t("Payment Date")}</Typography>
                                <Typography className={classes.contentText}>&#x202a;{convertTimeUtcToZoneCalander(templateData?.paid_on)}&#x202c;</Typography>
                            </Stack >
                        </Grid >
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>{t("Bank Name")}</Typography>
                                <Typography className={classes.contentText}> {templateData?.bank} </Typography>
                            </Stack >
                        </Grid >
                        <Grid item xs={6}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>{t("Transaction/Reference Id")}</Typography>
                                <Typography className={classes.contentText}> {templateData?.trx_no} </Typography>
                            </Stack >
                        </Grid >
                        <Grid item xs={12}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.titleText}>{t("Notes")}</Typography>
                                <Typography className={classes.contentText}>{templateData?.notes ?? "-"}</Typography>
                            </Stack >
                        </Grid >
                    </Grid >
                </Box >
            </Box >
            {
                templateData?.compensated_type === null &&
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button className={classes.declineBtn}
                                onClick={openDecline}>{t("Decline")}</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className={classes.reconcile}
                                onClick={openReconcile}>{t("Reconcile")}</Button>
                        </Grid >
                    </Grid >
                </Box >
            }
        </Stack >
    )
}