import * as React from "react"
const SelectedUnit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.048}
        x2={0.509}
        y1={-15.861}
        y2={-15.4}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#297cd2" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.213}
        x2={0.727}
        y1={0.193}
        y2={1.089}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#42a3f2" />
        <stop offset={1} stopColor="#42a4eb" />
      </linearGradient>
      <linearGradient xlinkHref="#a" id="c" x1={0} x2={1} y1={0.5} y2={0.5} />
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={0.5}
        x2={0.5}
        y1={-0.015}
        y2={1.033}
      />
      <linearGradient
        xlinkHref="#a"
        id="e"
        x1={0.006}
        x2={0.952}
        y1={0.006}
        y2={0.952}
      />
    </defs>
    <g data-name="Group 115369">
      <g data-name="icons8-property (2)">
        <path
          fill="#50e6ff"
          d="M19.894 7.789v13.158A1.052 1.052 0 0 1 18.842 22H5.158a1.052 1.052 0 0 1-1.053-1.053V3.053A1.052 1.052 0 0 1 5.158 2h8.947Z"
          data-name="Path 100979"
        />
        <path
          fill="url(#a)"
          d="M28 5v4.737a1.052 1.052 0 0 0 1.053 1.053h4.737Z"
          data-name="Path 100980"
          transform="translate(-13.895 -3)"
        />
        <path
          fill="url(#b)"
          d="M23.947 27.526h-8.421A.527.527 0 0 1 15 27v-5.526L19.737 17l4.737 4.474V27a.527.527 0 0 1-.527.526Z"
          data-name="Path 100981"
          transform="translate(-7.737 -8.684)"
        />
        <path
          fill="url(#c)"
          d="M18 28h3.158v4.737H18Z"
          data-name="Path 100982"
          transform="translate(-9.158 -13.895)"
        />
        <path
          fill="url(#d)"
          d="m24.46 21.107-5.045-4.475a.527.527 0 0 0-.7 0l-5.045 4.475a.263.263 0 0 0-.017.376l.431.459a.263.263 0 0 0 .366.018l4.614-4.032 4.614 4.032a.263.263 0 0 0 .366-.018l.431-.459a.263.263 0 0 0-.015-.376Z"
          data-name="Path 100983"
          transform="translate(-7.067 -8.447)"
        />
        <path
          fill="url(#e)"
          d="M26.263 28h1.579a.263.263 0 0 1 .263.263v1.579a.263.263 0 0 1-.263.263h-1.579a.263.263 0 0 1-.263-.263v-1.579a.263.263 0 0 1 .263-.263Z"
          data-name="Path 100984"
          transform="translate(-12.948 -13.895)"
        />
      </g>
    </g>
  </svg>
)
export default SelectedUnit
