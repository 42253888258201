import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import CarIcon from '../../../assets/car';
import { ParkingBookingCardStyles } from './style';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PhotoIcon from '@mui/icons-material/Photo';
import BikeIcon from '../../../assets/bike';
import CycleIcon from '../../../assets/cycle';

export const ParkingBookingCard = (props) => {
    const classes = ParkingBookingCardStyles(props)
    const { t = () => false } = props
    const [showHover, setShowHover] = React.useState(false)
    return (
        <Box className={classes.root}
            onMouseOver={() => setShowHover(true)}
            onMouseLeave={() => setShowHover(false)}
        >
            <Box>
                <Typography
                    className={classes.text}
                >{props?.val?.parking_no ?? "-"}</Typography>
                <Typography
                    className={classes.text2}
                >
                    {props?.val?.is_available ? t("Available") :
                        props?.val?.parking_slots?.[0]?.reference_id ?? "-"}
                </Typography>
            </Box>
            {
                props?.val?.is_available ? " " :
                    <Box>
                        {
                            (props?.val?.parking_slots?.[0]?.visitor_vehicle_type
                                === "Four Wheeler" ||
                                props?.val?.parking_slots?.[0]?.vehicle_type
                                === "FOUR-WHEELER") &&
                            <CarIcon />}
                        {
                            (props?.val?.parking_slots?.[0]?.visitor_vehicle_type
                                === "Two Wheeler" ||
                                props?.val?.parking_slots?.[0]?.vehicle_type
                                === "TWO-WHEELER") ?
                                <BikeIcon /> :
                                <CycleIcon />
                        }
                    </Box>
            }
            {!props?.val?.is_available &&
                < Box
                    className={classes.spanBox}
                >
                    <span
                        className={classes.text3}
                    >
                        {
                            (props?.val?.parking_type === "Reserved" || props?.val?.parking_master_parking_type) ? props?.val?.parking_slots?.[0]?.number_plate :
                                props?.val?.parking_slots?.[0]?.visitor_vehicle_number}
                    </span>
                </Box>
            }
            {
                !props?.val?.is_available && showHover &&
                <Box className={props?.val?.parking_type !== "Reserved" ? classes.subRoot : classes.singleImgIcon}
                >
                    <IconButton
                        className={classes.btn}
                        onClick={() => props?.openPopup(props?.val?.parking_slots?.[0])}
                    >
                        <PhotoIcon style={{
                            color: "#5078E1",
                            fontSize: "1rem"
                        }} />
                    </IconButton>
                    {props?.val?.parking_type !== "Reserved" &&
                        <IconButton
                            className={classes.btn}
                            onClick={() => props?.openDeclinePopup(props?.val?.parking_slots?.[0])}
                        >
                            <MoreHorizIcon
                                style={{
                                    color: "#5078E1",
                                    fontSize: "1rem"
                                }}
                            />
                        </IconButton>
                    }
                </Box>
            }
            <Box className={classes.bottomArea}
            >
                <Typography
                    className={classes.text4}
                >
                    {props?.val?.parking_type}
                </Typography>

            </Box>
        </Box >
    )
}
