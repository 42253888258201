export const constructOptionResponse = (response_obj = []) => {
    let response_array = response_obj?.data ?? [];
    let constructed_response_array = {
        data: response_array?.map((i) => {
            return {
                ...i,
                label: i?.name,
                value: i?.id
            }
        }),
        count:response_obj?.count
    }

    return constructed_response_array;
};