import * as React from "react"
const Player = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12.247} height={14} {...props}>
    <path
      fill="#4e5a6b"
      d="M10.874 0a1.214 1.214 0 0 0-.712.315 1.791 1.791 0 0 0-.521 1.1 3.32 3.32 0 0 0 .1 1.379 1.948 1.948 0 0 0 .264.576 1.322 1.322 0 0 0 .135.144l-.316 1.9-1.362.227-1.411-.993a.472.472 0 0 0-.208-.081L4.767 4.29a1.332 1.332 0 0 1-.4-.119L.692 2.407a.472.472 0 1 0-.408.85l.579.278c1.007.667 3.221 1.814 3.221 1.814-.006.629 0 2.045.022 2.989v2.831a.472.472 0 0 0 .016.122l.629 2.36a.472.472 0 1 0 .912-.243l-.106-.4-.283-1.84.136-1.55.535 1.268a.61.61 0 0 0 .076.123v.009a.472.472 0 0 0 .108.168l2.2 2.2a.472.472 0 1 0 .667-.667L7.082 10.8c-.32-1.237-.654-2.475-.774-2.939a16.893 16.893 0 0 1 .589-2.173l1.185.83a.472.472 0 0 0 .348.079l1.888-.315a.472.472 0 0 0 .4-.48l.356-2.142a1.37 1.37 0 0 0 .2-.12 2.116 2.116 0 0 0 .432-.489 3.273 3.273 0 0 0 .52-1.326 1.638 1.638 0 0 0-.249-1.207 1.278 1.278 0 0 0-.852-.51A1.117 1.117 0 0 0 10.874 0Zm-8.97.31L.645.63a.315.315 0 0 0 0 .629l1.258.315Zm9.089.631a.329.329 0 0 1 .222.132.668.668 0 0 1 .077.519 2.511 2.511 0 0 1-.371.935 1.217 1.217 0 0 1-.178.208 1.019 1.019 0 0 1-.092-.211 2.538 2.538 0 0 1-.075-.979.864.864 0 0 1 .223-.532.219.219 0 0 1 .194-.067Zm-5.314.313a1.258 1.258 0 1 0 1.258 1.258 1.258 1.258 0 0 0-1.258-1.253Z"
    />
  </svg>
)
export default Player
