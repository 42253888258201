import * as React from "react"

export const Finace = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 102062" transform="translate(-121 -201)">
      <circle
        data-name="Ellipse 129431"
        cx={20}
        cy={20}
        r={20}
        transform="translate(121 201)"
        fill="rgba(254,234,234,0.5)"
      />
      <g data-name="Group 103256">
        <path data-name="Rectangle 55102" fill="none" d="M131 211h20v20h-20z" />
        <path
          d="M143.925 212a6.055 6.055 0 0 0-4.312 1.8h.037a8.559 8.559 0 0 1 8.55 8.55v.037A6.071 6.071 0 0 0 143.925 212Zm-4.275 2.7a7.65 7.65 0 1 0 7.65 7.65 7.659 7.659 0 0 0-7.65-7.65Zm-1.125 4.05h2.7a.675.675 0 1 1 0 1.35h-2.147l-.178.9h.976a2.48 2.48 0 1 1-.225 4.95 4.963 4.963 0 0 1-2.362-.541.675.675 0 0 1 .67-1.172 3.793 3.793 0 0 0 1.692.362c.833 0 1.35-.431 1.35-1.125a1.126 1.126 0 0 0-1.125-1.125h-1.8a.675.675 0 0 1-.662-.808l.45-2.25a.675.675 0 0 1 .661-.541Z"
          fill="#b3776d"
        />
      </g>
    </g>
  </svg>
)
