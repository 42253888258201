import { makeStyles } from "@mui/styles";
import { Bold, Regular, SemiBold } from "../../utils";

export const CalendarStyle = makeStyles((theme) => ({
    iconbutton: {
        border: `1px solid ${theme.palette.border.secondary}`
    },
    day: {
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        padding: 4
    },
    date_curr_month: {
        color: theme.typography.color.primary,
        fontSize:"0.75rem",
        fontWeight: "bold",
        cursor: "pointer"
    },
    date_not_curr_month: {
        color: theme.palette.border.secondary,
        fontSize:"0.75rem",
        fontWeight: "bold",
        cursor: "not-allowed"
    },
    selectedDate: {
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        color: "#fff !important",
        height: 20,
        width: 20,
        fontSize:"0.75rem",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "4px"
    },
    today: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.palette.primary.main
    },
    calendar: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
    },
    date: {
        fontSize:"0.75rem",
        fontFamily: SemiBold
    },
    non_selected_type: {
        height: 20,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 45,
        cursor: "pointer",
        marginInlineStart: "8px"
    },
    selected_type: {
        height: 20,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: 4,
        background: theme.palette.primary.main,
        color: "#fff",
        fontFamily: SemiBold,
        fontSize:"0.75rem",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 45,
        cursor: "pointer",
        marginInlineStart: "8px"

    },
    title: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary,
        textTransform: "uppercase",
        marginBottom: 8,
        marginTop: 8
    },
    secondaryTitle: {
        fontSize:"0.75rem",
        fontFamily: SemiBold,
        color: theme.typography.color.tertiary,
        textTransform: "Capitalize",
    },
    rangeDate: {
        fontSize:"0.75rem",
        fontFamily: Bold,
        color: theme.typography.color.primary,
    },
    btn: {
        height: 33,
        width: 71,
        fontSize:"0.75rem"
    }
}))


export const MonthDropDownStyle = makeStyles((theme) => ({
    menuItem: {
        fontSize:"0.75rem",
        fontFamily: Regular
    },
    dropdown: {
        width: 62,
        height: 16,
        cursor: "pointer"
    },
    selected: {
        fontSize:"0.75rem",
        fontFamily: SemiBold
    },
    selectedMenuItem: {
        fontSize:"0.75rem",
        fontFamily: Regular,
        color: theme.palette.primary.main
    }
}))