import * as React from "react"
const NoImageUnitProperty = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} {...props}>
    <g data-name="Group 115424" transform="translate(-120 -335)">
      <circle
        cx={19}
        cy={19}
        r={19}
        fill="#f5f7fa"
        data-name="Ellipse 130019"
        transform="translate(120 335)"
      />
      <path
        fill="#98a0ac"
        d="M137.63 346.999a.583.583 0 0 0-.084.008h-2.629a.583.583 0 0 0-.583.583v2.14h-1.362a.584.584 0 1 0 0 1.167h.194v4.706a1.354 1.354 0 0 0-1.166 1.318v4.278a.583.583 0 0 0 .583.583h5.056a.583.583 0 0 0 .583-.583v-2.136h1.556v2.139a.583.583 0 0 0 .583.583h5.056a.583.583 0 0 0 .583-.583v-4.281a1.354 1.354 0 0 0-1.167-1.322v-4.702h.194a.584.584 0 1 0 0-1.167h-1.36v-2.139a.583.583 0 0 0-.583-.583h-2.627a.583.583 0 0 0-.189 0h-2.533a.583.583 0 0 0-.1-.008Zm-2.13 1.175h1.556v1.556H135.5Zm2.722 0h1.556v1.556h-1.556Zm2.722 0h1.556v1.556h-1.556Zm-6.611 2.723h.488a.583.583 0 0 0 .189 0h2.533a.583.583 0 0 0 .189 0h2.533a.583.583 0 0 0 .189 0h2.533a.583.583 0 0 0 .189 0h.49v5.25a.583.583 0 0 0 .583.583h.389a.186.186 0 0 1 .194.194v3.697h-3.888v-2.141a.583.583 0 0 0-.583-.583h-2.722a.583.583 0 0 0-.583.583v2.141h-3.889v-3.7a.186.186 0 0 1 .194-.194h.389a.583.583 0 0 0 .583-.583Zm1.556 1.556a.389.389 0 0 0-.389.389v.779a.389.389 0 0 0 .389.389h.778a.389.389 0 0 0 .389-.389v-.78a.389.389 0 0 0-.389-.389Zm2.722 0a.389.389 0 0 0-.389.389v.779a.389.389 0 0 0 .389.389h.778a.389.389 0 0 0 .389-.389v-.78a.389.389 0 0 0-.389-.389Zm2.722 0a.389.389 0 0 0-.389.389v.779a.389.389 0 0 0 .389.389h.778a.389.389 0 0 0 .389-.389v-.78a.389.389 0 0 0-.389-.389Zm-5.444 2.721a.389.389 0 0 0-.389.389v.778a.389.389 0 0 0 .389.389h.778a.389.389 0 0 0 .389-.389v-.778a.389.389 0 0 0-.389-.389Zm2.722 0a.389.389 0 0 0-.389.389v.778a.389.389 0 0 0 .389.389h.778a.389.389 0 0 0 .389-.389v-.778a.389.389 0 0 0-.389-.389Zm2.722 0a.389.389 0 0 0-.389.389v.778a.389.389 0 0 0 .389.389h.778a.389.389 0 0 0 .389-.389v-.778a.389.389 0 0 0-.389-.389Zm-6.611 2.723a.389.389 0 0 0-.389.389v.778a.389.389 0 0 0 .389.389h.778a.389.389 0 0 0 .389-.389v-.778a.389.389 0 0 0-.389-.389Zm7.778 0a.389.389 0 0 0-.389.389v.778a.389.389 0 0 0 .389.389h.778a.389.389 0 0 0 .389-.389v-.778a.389.389 0 0 0-.389-.389Z"
        data-name="Group 115423"
      />
    </g>
  </svg>
)
export default NoImageUnitProperty
