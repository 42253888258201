import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    mileStoneBox: {
        border: "1px solid #E4E8EE",
        padding:"10px"
    },
    name:{
        fontSize:"0.8125rem",
        color:theme.typography.color.primary,
        fontFamily:SemiBold,
        // marginTop:"3px",
    },
    milestoneTitle:{
        fontSize:"0.75rem",
        color:theme.typography.color.tertiary,
        fontFamily:Bold,
        textTransform:"upperCase",
        padding:"0px 20px"

    },
    paymentSelectBox:{
        border: "1px solid #E4E8EE",
        padding:"10px",
        borderRadius:theme.palette.borderRadius,
    },
    chosenValue:{
        fontSize:"0.875rem",
        color:theme.typography.color.primary,
        fontFamily:SemiBold
    },
    placeholder:{
        fontSize:"0.75rem",
        color:theme.typography.color.tertiary,
        fontFamily:SemiBold
    },
    title:{
        fontSize:"0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: SemiBold,
        marginBottom: "5px"
    },
    error:{
        fontSize:"0.75rem",
        fontFamily: Regular,
        fontWeight:400,
        color:theme.palette.error.main,
        marginTop:"3px",

    },
    menu: {
        "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
          {
            borderRadius: '0px 0px 4px 4px',
            padding: "0px !important",
            boxShadow: "none !important",
            color: theme?.typography?.status?.tertiary ?? "",
            border:'1px solid #E4E8EE'
          },
        "& .MuiMenu-list": {
          padding: "0 !important",
          color: theme?.typography?.status?.tertiary ?? "",
          display:"block !important"
        },
      },
      required: {
        color: "red",
        fontFamily: SemiBold,
        fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
        marginBottom: (props) => props?.labelMarginBottom ?? "5px",
      },
}))