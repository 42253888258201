import { Avatar, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogDrawer, Subheader } from '../../components';
import { AlertContext, AuthContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { accessCheckRender, AlertProps, getRoutePermissionNew, LocalStorageKeys, NetWorkCallMethods, remCalc, stringAvatar } from '../../utils';
import { useStyles } from "./style";
import {
    BodyDiv, ProfileImageInnerDiv, Image, UserID, ProfileDetailsStack,
    CardTitle, Title, Desc, CompanyRoleListDiv, AssignedDiv, PropertyListDiv,
    FooterDiv, MapAddressDiv, AddressDiv, EditButton,
    ProfileImageOuterDiv
} from "./component/styledComponent";
import ImageViewer from "react-simple-image-viewer";
// import MyGoogleMap from '../../components/interactivemap/myGoogleMaps';
import { LocationPin } from './component/locationPin';
import { BackendRoutes, Routes } from '../../router/routes';
import { NewLoader } from '../../components/newLoader';
import { withNamespaces } from 'react-i18next';
import microsoft_logo_only from './assets/microsoft_logo_only.png';
import google_logo_only from './assets/google_logo_only.png';
import AddIcon from '@mui/icons-material/Add';
import { config } from '../../config';

const ProfileView = (props) => {
    const { loading, handleLoading, t } = props;
    const search = useLocation?.()?.search;
    const authorization_code = new URLSearchParams(search)?.get?.("code");
    const authorization_state = new URLSearchParams(search)?.get?.("state");

    // constants
    const classes = useStyles()
    const history = useHistory()

    // useContext
    const alert = React.useContext(AlertContext)
    const auth = React.useContext(AuthContext)

    // useState
    const [data, setData] = React.useState(null)
    const [isViewerOpen, setIsViewerOpen] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState("")
    const [permission, setPermission] = React.useState({})
    const [loader, setLoader] = React.useState(true)
    const [microsoft_account_details, set_microsoft_account_details] = useState({
        profile_image: "",
        name: "",
        email_id: "",
    });
    const [google_account_details, set_google_account_details] = useState({
        profile_image: "",
        name: "",
        email_id: "",
    });
    const [is_link_account_drawer_open, set_is_link_account_drawer_open] = useState(false);
    const [is_microsoft_account_details_drawer_open, set_is_microsoft_account_details_drawer_open] = useState(false);
    const [is_google_account_details_drawer_open, set_is_google_account_details_drawer_open] = useState(false);

    // use effect to get permission
    React.useEffect(() => {
        const perm = getRoutePermissionNew(auth)
        if (perm) {
            setPermission(perm)
            if (perm?.read) {
                getInitialData();
            }
        }
        // eslint-disable-next-line
    }, [auth]);

    const getInitialData = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let promise_array = [];
                const handle_link_account_promise_array = handleLinkAccount();

                if (authorization_code && authorization_code?.length > 0 &&
                    authorization_state && authorization_state?.length > 0) {
                    promise_array = handle_link_account_promise_array;
                } else {
                    promise_array = [
                        getProfileData(),
                        ...handle_link_account_promise_array,
                    ];
                }

                await Promise.all(promise_array);

                handleLoading(false);
            } catch (error) {
                reject(error);
            }
        })
    }

    const getProfileData = () => {
        NetworkCall(
            `${config?.api_url}/user-info?is_manager=true`,
            NetWorkCallMethods.get, null, null, true, false
        ).then((_) => {
            let tempData = _?.data?.data

            setData({
                ...tempData,
                userProfile: tempData?.user_profiles?.[0] ?? null,
                contact: tempData?.user_profiles?.[0]?.contact ?? null,
            })

            setLoader(false);
            handleLoading(false);
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("Some Thing Went Wrong"),
                severity: AlertProps.severity.error,
            })
        })
    }

    const handleLinkMicrosoftAccount = async () => {
        const state_obj = { origin: "microsoft_account_authorize_url" };

        const json_stringify_state_obj = JSON.stringify(state_obj);

        const link = `${config?.microsoft_account_auth_url}` +
            `client_id=${config?.microsoft_account_client_id}` +
            `&response_type=code` +
            `&redirect_uri=${config?.microsoft_account_redirect_uri}` +
            `&response_mode=query` +
            `&scope=user.read%20calendars.readwrite%20offline_access` +
            `&state=${json_stringify_state_obj}`;

        window.open(link, "_blank");
    }

    const handleLinkGoogleAccount = async () => {
        const state_obj = { origin: "google_account_authorize_url" };

        const json_stringify_state_obj = JSON.stringify(state_obj);

        const link = `${config?.google_account_auth_url}` +
            `client_id=${config?.google_account_client_id}` +
            `&response_type=code` +
            `&redirect_uri=${config?.google_account_redirect_uri}` +
            `&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar` +
            `&state=${json_stringify_state_obj}` +
            `&email=` +
            `&include_granted_scopes=true` +
            `&access_type=offline` +
            `&prompt=consent`;

        window.open(link, "_blank");
    }

    const handleLinkAccount = () => {
        let promise_array = [];
        if (authorization_code && authorization_code?.length > 0) {
            const parsed_authorization_state = JSON?.parse?.(authorization_state);

            switch (parsed_authorization_state?.origin) {
                case "microsoft_account_authorize_url":
                    promise_array = [handleLinkingMicrosoftAccount()];
                    break;
                case "google_account_authorize_url":
                    promise_array = [handleLinkingGoogleAccount()];
                    break;

                default:
                    break;
            }
        } else {
            promise_array = [
                handleMicrosoftAccountDetails(),
                handleGoogleAccountDetails(),
            ];
        }

        return promise_array;
    }

    const handleLinkingMicrosoftAccount = () => {
        const payload = {
            user_profile_id: localStorage?.getItem?.(LocalStorageKeys?.profileID),
            microsoft_link_code: authorization_code,
        };
        NetworkCall(
            `${config?.api_url}${BackendRoutes?.user_profile_link_microsoft_account}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            alert.setSnack({
                ...alert, open: true,
                msg: t("Successfully linked your Microsoft Account"),
                severity: AlertProps?.severity?.success,
            });
            history.replace(Routes?.profileView);
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true,
                msg: t("Error while linking your Microsoft Account. Please try again later."),
                severity: AlertProps?.severity?.error,
            });
        });
    }

    const handleLinkingGoogleAccount = () => {
        const payload = {
            user_profile_id: localStorage?.getItem?.(LocalStorageKeys?.profileID),
            google_link_code: authorization_code,
        };
        NetworkCall(
            `${config?.api_url}${BackendRoutes?.user_profile_link_google_account}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            alert.setSnack({
                ...alert, open: true,
                msg: t("Successfully linked your Google Account"),
                severity: AlertProps?.severity?.success,
            });
            history.replace(Routes?.profileView);
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true,
                msg: t("Error while linking your Google Account. Please try again later."),
                severity: AlertProps?.severity?.error,
            });
        });
    }

    const handleMicrosoftAccountDetails = () => {
        NetworkCall(
            `${config?.api_url}${BackendRoutes?.user_profile_get_microsoft_account_details}`,
            NetWorkCallMethods.get, null, null, true, false
        ).then((_) => {
            if (_?.data?.data?.is_linked) {
                set_microsoft_account_details({
                    profile_image: _?.data?.data?.microsoft_account_image,
                    name: _?.data?.data?.microsoft_account_name,
                    email_id: _?.data?.data?.microsoft_account_email,
                });
            }
        }).catch((err) => {
            console.log("Error while fetching your Microsoft Account details. Please try again later.", err);
            set_microsoft_account_details({
                profile_image: "",
                name: "",
                email_id: "",
            });
        });
    }

    const handleGoogleAccountDetails = () => {
        NetworkCall(
            `${config?.api_url}${BackendRoutes?.user_profile_get_google_account_details}`,
            NetWorkCallMethods.get, null, null, true, false
        ).then((_) => {
            if (_?.data?.data?.is_linked) {
                set_google_account_details({
                    profile_image: _?.data?.data?.google_account_image,
                    name: _?.data?.data?.google_account_name,
                    email_id: _?.data?.data?.google_account_email,
                });
            }
        }).catch((err) => {
            console.log("Error while fetching your Google Account details. Please try again later.", err);
            set_google_account_details({
                profile_image: "",
                name: "",
                email_id: "",
            });
        });
    }

    // Go Back
    const goBack = () => { history.goBack(-1) }

    //openImageViewer
    const openImageViewer = (image) => {
        setCurrentImage(image)
        setIsViewerOpen(true)
    }

    //closeImageViewer
    const closeImageViewer = () => {
        setCurrentImage(0)
        setIsViewerOpen(false)
    }

    const constructAddress = (_) => {
        let address = ""
        if (!_?.door_no && !_?.street_1 && !_?.street_2 &&
            !_?.landmark && !_?.area && !_?.city &&
            !_?.state && !_?.country && !_?.zipcode) {
            address = "Address Not Available"
        } else {
            address = ((_?.door_no && (_?.door_no + ", ")) ?? "") +
                ((_?.street_1 && (_?.street_1 + ", ")) ?? "") +
                ((_?.street_2 && (_?.street_2 + ", ")) ?? "") +
                ((_?.landmark && (_?.landmark + ", ")) ?? "") +
                ((_?.area && (_?.area + ", ")) ?? "") +
                ((_?.city && (_?.city + ", ")) ?? "") +
                ((_?.state && (_?.state + ", ")) ?? "") +
                ((_?.country && (_?.country + ", ")) ?? "") +
                ((_?.zipcode && (_?.zipcode)) ?? "")
        }

        return address
    }

    const handleUnLinkAccount = async ({ account_type = "" }) => {
        const payload = {
            params: {
                id: localStorage?.getItem?.(LocalStorageKeys?.profileID),
            }
        };

        switch (account_type) {
            case "microsoft_account":
                payload.params["ms_refresh_token"] = null;
                break;
            case "google_account":
                payload.params["google_refresh_token"] = null;
                break;

            default:
                break;
        }

        const alert_messages = {
            microsoft_account: {
                success: "Successfully unlinked your Microsoft Account",
                error: "Error while unlinking your Microsoft Account. Please try again later.",
            },
            google_account: {
                success: "Successfully unlinked your Google Account",
                error: "Error while unlinking your Google Account. Please try again later.",
            },
        };

        NetworkCall(
            `${config?.api_url}${BackendRoutes?.user_profile_upsert}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((_) => {
            alert.setSnack({
                ...alert, open: true,
                msg: t(alert_messages?.[account_type]?.success),
                severity: AlertProps?.severity?.success,
            });

            switch (account_type) {
                case "microsoft_account":
                    set_microsoft_account_details({
                        profile_image: "",
                        name: "",
                        email_id: "",
                    });

                    set_is_microsoft_account_details_drawer_open(false);
                    break;
                case "google_account":
                    set_google_account_details({
                        profile_image: "",
                        name: "",
                        email_id: "",
                    });

                    set_is_google_account_details_drawer_open(false);
                    break;

                default:
                    break;
            }
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true,
                msg: t(alert_messages?.[account_type]?.error),
                severity: AlertProps?.severity?.error,
            });
        });
    }

    const render = () => {
        return <>
            <Subheader hideBackButton={false} goBack={goBack} title={t("Manager Profile")} />
            {
                loader ? (
                    <NewLoader minusHeight="100px" />
                ) : (
                    <BodyDiv>
                        <Grid container>
                            <Grid container spacing={"16px"}>
                                <Grid xs={12} sm={12} md={12} lg={7} item>
                                    <ProfileDetailsStack
                                        direction={{ xs: "column", sm: "row" }}
                                        gap={"16px"}
                                        divider={<Divider variant="middle" flexItem orientation={"vertical"} />}>
                                        <ProfileImageOuterDiv>
                                            <ProfileImageInnerDiv>
                                                <Image
                                                    src={data?.userProfile?.image_url ?? ""}
                                                    alt={"profile_img"}
                                                    onClick={() => data?.userProfile?.image_url &&
                                                        openImageViewer([data?.userProfile?.image_url])} {...stringAvatar(data?.userProfile?.first_name, null, "50%")} />
                                                <UserID userNo={data?.userProfile?.user_no}>
                                                    {data?.userProfile?.user_no}
                                                </UserID>
                                            </ProfileImageInnerDiv>
                                        </ProfileImageOuterDiv>
                                        <Grid container spacing={"16px"}>
                                            <Grid item xs={12}>
                                                <CardTitle>{t("Profile Detail")}</CardTitle>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Title>{t("Contact Name")}</Title>
                                                <Desc>{data?.userProfile?.first_name ?? " - "}</Desc>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Title>{t("Email Address")}</Title>
                                                <Desc>{data?.userProfile?.email_id ?? " - "}</Desc>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Title>{t("Primary Mobile")}</Title>
                                                <Desc>
                                                    {data?.userProfile?.mobile_no ?
                                                        ((data?.userProfile?.mobile_no_country_code ?? "") +
                                                            (data?.userProfile?.mobile_no_country_code && " ") +
                                                            data?.userProfile?.mobile_no) : " - "}
                                                </Desc>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Title>{t("Alternate Mobile")}</Title>
                                                <Desc>
                                                    {data?.userProfile?.alternative_mobile_no ?
                                                        (data?.userProfile?.alternative_mobile_no_country_code ?? "" +
                                                            (data?.userProfile?.alternative_mobile_no_country_code && " ") +
                                                            data?.userProfile?.alternative_mobile_no) : " - "}
                                                </Desc>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={6}>
                                                <Title>Reporting To</Title>
                                                <Desc>{data?.contact?.reporting_to ?? " - "}</Desc>
                                            </Grid> */}
                                        </Grid>
                                    </ProfileDetailsStack>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={5} item>
                                    <Grid container>
                                        <Grid container spacing={"16px"}>
                                            <Grid xs={12} sm={6} md={6} lg={6} item>
                                                <Grid container>
                                                    <Grid container spacing={"16px"}>
                                                        <Grid xs={12} item>
                                                            <AssignedDiv>
                                                                <Title>{t("Assigned Companies")}</Title>
                                                                <CompanyRoleListDiv testProps={"hello"}>
                                                                    {data?.company_names?.map((_) =>
                                                                        <Desc marginBottom={"8px"}>{_ ?? " - "}</Desc>)}
                                                                </CompanyRoleListDiv>
                                                            </AssignedDiv>
                                                        </Grid>
                                                        <Grid xs={12} item>
                                                            <AssignedDiv>
                                                                <Title>{t("Associated Roles")}</Title>
                                                                <CompanyRoleListDiv>
                                                                    {data?.roles?.map((_) =>
                                                                        <Desc marginBottom={"8px"}>{_ ?? " - "}</Desc>)}
                                                                </CompanyRoleListDiv>
                                                            </AssignedDiv>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid xs={12} sm={6} md={6} lg={6} item>
                                                <AssignedDiv>
                                                    <Title>{t("Associated Roles")}</Title>
                                                    <PropertyListDiv>
                                                        {data?.property_names?.map((_) =>
                                                            <Desc marginBottom={"8px"}>{_ ?? " - "}</Desc>)}
                                                    </PropertyListDiv>
                                                </AssignedDiv>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} item>
                                    <MapAddressDiv>
                                        <Grid container>
                                            <Grid container spacing={"16px"}>
                                                {/* <Grid xs={12} sm={6} md={6} lg={8} item>
                                        <MapOuterDiv>
                                            <MapInnerDiv>
                                                {(data?.contact?.latitude && data?.contact?.longitude) &&
                                                    <MyGoogleMap readonly
                                                        lat={data?.contact?.latitude}
                                                        lng={data?.contact?.longitude}
                                                        center={{
                                                            lat: data?.contact?.latitude,
                                                            lng: data?.contact?.longitude
                                                        }} />}
                                            </MapInnerDiv>
                                        </MapOuterDiv>
                                    </Grid> */}
                                                <Grid xs={12} item>
                                                    <AddressDiv>
                                                        <Grid container>
                                                            <Grid container spacing={"16px"}>
                                                                <Grid xs={12} item>
                                                                    <Stack direction={"row"}
                                                                        gap={"4px"}>
                                                                        <LocationPin />
                                                                        <CardTitle color={"#091B29"}>{t("Address")}</CardTitle>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid xs={12} item>
                                                                    <Desc>
                                                                        {constructAddress(data?.contact)}
                                                                    </Desc>
                                                                </Grid>
                                                                <Grid xs={12} item>
                                                                    <Stack direction={"row"} gap={"4px"}
                                                                        alignItems={"baseline"}>
                                                                        <Title marginBottom={"0px"}>{t("Latitude")} : </Title>
                                                                        <Desc>{data?.contact?.latitude ?? " - "}</Desc>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid xs={12} item>
                                                                    <Stack direction={"row"} gap={"4px"}
                                                                        alignItems={"baseline"}>
                                                                        <Title marginBottom={"0px"}>{t("Longitude")} : </Title>
                                                                        <Desc>{data?.contact?.longitude ?? " - "}</Desc>
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </AddressDiv>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MapAddressDiv>
                                </Grid>
                            </Grid>
                        </Grid>
                    </BodyDiv>)}
            <FooterDiv>
                <Stack direction={"row"} columnGap={"8px"}
                    justifyContent={"start"} alignItems={"center"}>
                    <Typography className={classes.link}>
                        {(Boolean(microsoft_account_details?.email_id?.length > 0) ||
                            Boolean(google_account_details?.email_id?.length > 0))
                            ? t("Linked")
                            : t("Link")
                        }
                    </Typography>
                    {Boolean(microsoft_account_details?.email_id?.length > 0) &&
                        <Box height={"40px"} p={"10px 0px"} sx={{ cursor: "pointer" }}
                            onClick={() => set_is_microsoft_account_details_drawer_open(true)}>
                            <img src={microsoft_logo_only} alt='microsoft_logo' height={"20px"} />
                        </Box>
                    }
                    {Boolean(google_account_details?.email_id?.length > 0) &&
                        <Box height={"40px"} p={"10px 0px"} sx={{ cursor: "pointer" }}
                            onClick={() => set_is_google_account_details_drawer_open(true)}>
                            <img src={google_logo_only} alt='google_logo' height={"20px"} />
                        </Box>
                    }
                    {(permission?.update &&
                        (!Boolean(microsoft_account_details?.email_id?.length > 0) ||
                            !Boolean(google_account_details?.email_id?.length > 0))) &&
                        <Box className={classes.add_icon} sx={{ cursor: "pointer" }}
                            onClick={() => set_is_link_account_drawer_open(true)}>
                            <AddIcon />
                        </Box>
                    }
                </Stack>
                {permission?.update &&
                    <EditButton
                        onClick={() => history.push(Routes.profileEdit)}>
                        {t("Edit Profile")}
                    </EditButton>
                }
            </FooterDiv>
            {isViewerOpen && (<ImageViewer
                src={currentImage}
                currentIndex={0}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                className={classes.imageViewer}
                closeOnClickOutside={true} />)}
            <DialogDrawer
                open={is_link_account_drawer_open}
                header={t("Link Account")} height={"auto"} padding={'25px'}
                onClose={() => set_is_link_account_drawer_open(false)}
                component={
                    <Stack direction={"row"} columnGap={"50px"}
                        justifyContent={"center"} alignItems={"center"}>
                        {!Boolean(microsoft_account_details?.email_id?.length > 0) &&
                            <Box height={"100px"} p={"25px 0px"} sx={{ cursor: "pointer" }}
                                onClick={handleLinkMicrosoftAccount}>
                                <img src={microsoft_logo_only} alt="microsoft_logo" height={"50px"} />
                            </Box>
                        }
                        {!Boolean(google_account_details?.email_id?.length > 0) &&
                            <Box height={"100px"} p={"25px 0px"} sx={{ cursor: "pointer" }}
                                onClick={handleLinkGoogleAccount}>
                                <img src={google_logo_only} alt="google_logo" height={"50px"} />
                            </Box>
                        }
                    </Stack>
                } />
            <DialogDrawer
                open={is_microsoft_account_details_drawer_open}
                header={t("Your Microsoft Account Details")}
                height={"auto"} padding={'25px'}
                onClose={() => set_is_microsoft_account_details_drawer_open(false)}
                component={
                    <Stack direction={"row"} columnGap={"12px"}
                        justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} columnGap={"12px"}
                            justifyContent={"start"} alignItems={"center"}>
                            {(microsoft_account_details?.profile_image?.length > 0 ||
                                microsoft_account_details?.name?.length > 0) &&
                                <Avatar className={classes.link_account_profile_image}
                                    src={microsoft_account_details?.profile_image}
                                    {...stringAvatar(microsoft_account_details?.name, remCalc(16), "50%")}
                                    alt="profile_image" />
                            }
                            {(microsoft_account_details?.name?.length > 0 ||
                                microsoft_account_details?.email_id) &&
                                <Stack direction={"column"}
                                    justifyContent={"center"} alignItems={"start"}>
                                    {microsoft_account_details?.name &&
                                        <Typography className={classes.link_account_name}>
                                            {microsoft_account_details?.name}
                                        </Typography>
                                    }
                                    {microsoft_account_details?.email_id &&
                                        <Typography className={classes.link_account_email_id}>
                                            {microsoft_account_details?.email_id}
                                        </Typography>
                                    }
                                </Stack>
                            }
                        </Stack>
                        <Box className={classes.unlink_box}
                            onClick={() => handleUnLinkAccount({ account_type: "microsoft_account" })}>
                            <Typography className={classes.unlink_text}>
                                {t("Unlink")}
                            </Typography>
                        </Box>
                    </Stack>
                } />
            <DialogDrawer
                open={is_google_account_details_drawer_open}
                header={t("Your Google Account Details")}
                height={"auto"} padding={'25px'}
                onClose={() => set_is_google_account_details_drawer_open(false)}
                component={
                    <Stack direction={"row"} columnGap={"12px"}
                        justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} columnGap={"12px"}
                            justifyContent={"start"} alignItems={"center"}>
                            {(google_account_details?.profile_image?.length > 0 ||
                                google_account_details?.name?.length > 0) &&
                                <Avatar className={classes.link_account_profile_image}
                                    src={google_account_details?.profile_image}
                                    {...stringAvatar(google_account_details?.name, remCalc(16), "50%")}
                                    alt="profile_image" />
                            }
                            {(google_account_details?.name?.length > 0 ||
                                google_account_details?.email_id) &&
                                <Stack direction={"column"}
                                    justifyContent={"center"} alignItems={"start"}>
                                    {google_account_details?.name &&
                                        <Typography className={classes.link_account_name}>
                                            {google_account_details?.name}
                                        </Typography>
                                    }
                                    {google_account_details?.email_id &&
                                        <Typography className={classes.link_account_email_id}>
                                            {google_account_details?.email_id}
                                        </Typography>
                                    }
                                </Stack>
                            }
                        </Stack>
                        <Box className={classes.unlink_box}
                            onClick={() => handleUnLinkAccount({ account_type: "google_account" })}>
                            <Typography className={classes.unlink_text}>
                                {t("Unlink")}
                            </Typography>
                        </Box>
                    </Stack>
                } />
        </>
    }
    return <div>
        {accessCheckRender(render, permission, "", loading)}
    </div>
}
export default withNamespaces("profileView")(ProfileView);
