import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import CaretDown from "../../../assets/caretIcon";
import CaretUpIcon from "../../../assets/caretUp";
import { StepperStyles } from "./style";
export const FilterDropdownComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const classes = StepperStyles()
    const handleSelect = (value) => {
        props?.onChange(value)
        setAnchorEl(null)
    }
    return (
        <Box>
            <Box
                style={{
                    backgroundColor: "#FFFFFF",
                    padding: "8px",
                    borderRadius: "4px",
                    alignItems: "center",
                    display: "flex",
                    zIndex: 999,
                    cursor: "pointer",
                    border: "1px solid #E4E8EE",
                    width: "auto",
                    justifyContent: "space-between"

                }}
                onClick={handleClick}>
                    <Typography className={classes.filterLabel}>
                        {props?.value?.label}
                        {/* {(props?.calanderValue?.value?.from_date ? moment(props?.calanderValue?.value?.from_date).format("DD MMM YYYY"): "YYYY-MM-DD") + " to " +
                        (props?.calanderValue?.value?.to_date ? moment(props?.calanderValue?.value?.to_date).format("DD MMM YYYY"): "YYYY-MM-DD")} */}
                    </Typography>
                {open ? <CaretUpIcon /> :
                    <CaretDown />}

            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className={classes.filtermenuList}
            >
                {props?.options?.map((val, i) => {
                    return (
                        <MenuItem onClick={() => handleSelect(val)} className={classes.menuItemlist}>
                            <Box>
                                <Typography className={classes.filterOptionLabel}>{val?.label}</Typography>
                                {props?.options?.length - 1 !== i &&
                                    <Box className={classes.applyfilterborder} />
                                }
                            </Box>
                        </MenuItem>
                    )

                })}
            </Menu>
        </Box>
    )
}