import React from "react";
import { withNavBars } from "../../HOCs";
import PropertyManagerDashboard from "./dashboard";

class PropertyManagerDashboardParent extends React.Component {
    render() {
        return <PropertyManagerDashboard {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(PropertyManagerDashboardParent, props);