import * as React from "react"

export const SkipForm = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <g data-name="Group 105404" transform="translate(-203.5 -498.5)">
      <rect
        data-name="Rectangle 55504"
        width={64}
        height={64}
        rx={32}
        transform="translate(203.5 498.5)"
        fill="#f1f7ff"
      />
      <path
        d="M234.763 520.5c-7.518 0-10.6 7.766-10.6 7.766a.9.9 0 1 0 1.671.668s2.666-6.634 8.929-6.634c4.51 0 7.453 3.458 8.754 5.4H240.6a.9.9 0 1 0 0 1.8h5.4a.9.9 0 0 0 .9-.9v-5.4a.9.9 0 1 0-1.8 0v3.614c-1.447-2.22-4.854-6.314-10.337-6.314Zm-9.163 12a2.1 2.1 0 0 0-2.1 2.1v3a2.1 2.1 0 0 0 2.1 2.1h3a2.1 2.1 0 0 0 2.1-2.1v-3a2.1 2.1 0 0 0-2.1-2.1Zm8.4 0a2.1 2.1 0 0 0-2.1 2.1v3a2.1 2.1 0 0 0 2.1 2.1h3a2.1 2.1 0 0 0 2.1-2.1v-3a2.1 2.1 0 0 0-2.1-2.1Zm8.4 0a2.1 2.1 0 0 0-2.1 2.1v3a2.1 2.1 0 0 0 2.1 2.1h3a2.1 2.1 0 0 0 2.1-2.1v-3a2.1 2.1 0 0 0-2.1-2.1Z"
        fill="#5078e1"
      />
    </g>
  </svg>
)
