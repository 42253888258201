import { Avatar, Box, Grid, Icon, Popover, Stack, Typography } from '@mui/material';
import React from 'react'
import { CustomDropdownStyle } from './style';
import { stringAvatar } from '../../../utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { AlertDialog } from '../../../components';
import { ViewCourt } from '../viewCourt';

export const CustomDropdown = ({
    list = [],
    selected = {},
    handleChange = () => false,
    disableView = false,
    master = false,
    t = () => false
}) => {
    const classes = CustomDropdownStyle()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    // View amenities popup

    const [view, setView] = React.useState(false)
    return (
        <>
            <Box aria-describedby={id} onClick={list?.length > 0 && handleClick}
                className={Object.keys(selected)?.length === 0 ? classes.disabledDropdownBox : classes.dropDownBox} p={1}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={'row'} spacing={1} sx={{ width: "90%" }}>

                        <Avatar className={classes.avatar} src={master ? "" : selected?.facility_assets?.[0]?.url}
                            {...stringAvatar(selected?.label, 10)}></Avatar>
                        <Typography className={classes.label} noWrap>{selected?.label ?? "-"}</Typography>
                    </Stack>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Stack >
            </Box >
            <Popover
                sx={{ marginTop: "12px" }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid container spacing={2} sx={{ width: "700px" }} p={2}>
                    {
                        list?.map((data) => {
                            return (
                                <Grid item xs={4} md={4} lg={4}>
                                    <Box p={1} className={selected?.value === data?.value ? classes.selectedBox : ""}
                                        onClick={() => {
                                            handleChange(data)
                                            handleClose()
                                        }}>
                                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Stack direction={"row"} >
                                                <Avatar className={classes.listAvatar}
                                                    src={data?.facility_assets?.[0]?.url}
                                                    {...stringAvatar(data?.label)}
                                                    sx={{ cursor: "pointer" }}></Avatar>
                                                <Stack justifyContent={"center"}>
                                                    <Typography className={classes.label} noWrap
                                                        sx={{ cursor: "pointer", width: "125px" }}>{data?.label ?? "-"}</Typography>
                                                    {!disableView && <Typography className={classes.viewLabel}
                                                        onClick={() => setView(!view)}>{t("View")}</Typography>}
                                                </Stack>
                                            </Stack>
                                            {selected?.value === data?.value && <Icon color='primary'>check_circle</Icon>}
                                        </Stack >
                                    </Box >
                                </Grid >
                            )
                        })
                    }
                </Grid >
            </Popover >


            <AlertDialog open={view}
                onClose={() => setView(!view)}
                header={selected?.label}
                isNormal
                component={
                    <ViewCourt
                        t={t}
                        type="court"
                        selectedAmenityView={selected} />
                }
            />
        </>
    )
}