import React from "react"

export const CommunityIcon = () =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_112305" data-name="Group 112305" transform="translate(-13981 -7826)">
    <g id="Group_112304" data-name="Group 112304">
      <g id="Rectangle_57215" data-name="Rectangle 57215" transform="translate(13981 7826)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none"/>
        <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
      </g>
      <path id="icons8-city_3_" data-name="icons8-city (3)" d="M8.394,4A1.905,1.905,0,0,0,6.429,5.9v6.857H5.1a2.1,2.1,0,0,0-2.1,2.1v3.81a.572.572,0,0,0,.571.571H18.429A.572.572,0,0,0,19,18.666V12.19a2.1,2.1,0,0,0-2.1-2.1H15.19V7.238A1.91,1.91,0,0,0,13.781,5.4L8.829,4.065A1.906,1.906,0,0,0,8.394,4ZM8.359,5.143a.738.738,0,0,1,.172.025L13.484,6.5a.762.762,0,0,1,.564.736V18.095h-3.81V14.857a2.1,2.1,0,0,0-2.1-2.1H7.571V5.9a.761.761,0,0,1,.788-.761ZM9.1,7.428a.381.381,0,0,0-.381.381v.762a.381.381,0,0,0,.381.381h.762a.381.381,0,0,0,.381-.381V7.809a.381.381,0,0,0-.381-.381Zm2.667,0a.381.381,0,0,0-.381.381v.762a.381.381,0,0,0,.381.381h.762a.381.381,0,0,0,.381-.381V7.809a.381.381,0,0,0-.381-.381ZM9.1,10.095a.381.381,0,0,0-.381.381v.762a.381.381,0,0,0,.381.381h.762a.381.381,0,0,0,.381-.381v-.762a.381.381,0,0,0-.381-.381Zm2.667,0a.381.381,0,0,0-.381.381v.762a.381.381,0,0,0,.381.381h.762a.381.381,0,0,0,.381-.381v-.762a.381.381,0,0,0-.381-.381Zm0,2.667a.381.381,0,0,0-.381.381V13.9a.381.381,0,0,0,.381.381h.762A.381.381,0,0,0,12.9,13.9v-.762a.381.381,0,0,0-.381-.381Zm4.571,0H17.1a.381.381,0,0,1,.381.381V13.9a.381.381,0,0,1-.381.381h-.762a.381.381,0,0,1-.381-.381v-.762A.381.381,0,0,1,16.333,12.761ZM4.9,15.428h.762a.381.381,0,0,1,.381.381v.762a.381.381,0,0,1-.381.381H4.9a.381.381,0,0,1-.381-.381v-.762A.381.381,0,0,1,4.9,15.428Zm2.667,0h.762a.381.381,0,0,1,.381.381v.762a.381.381,0,0,1-.381.381H7.571a.381.381,0,0,1-.381-.381v-.762A.381.381,0,0,1,7.571,15.428Zm4.19,0a.381.381,0,0,0-.381.381v.762a.381.381,0,0,0,.381.381h.762a.381.381,0,0,0,.381-.381v-.762a.381.381,0,0,0-.381-.381Zm4.571,0H17.1a.381.381,0,0,1,.381.381v.762a.381.381,0,0,1-.381.381h-.762a.381.381,0,0,1-.381-.381v-.762A.381.381,0,0,1,16.333,15.428Z" transform="translate(13982 7826.001)" fill="#4e5a6b"/>
    </g>
  </g>
</svg>

)