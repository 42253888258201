import React from "react";
import { withNavBars } from "../../HOCs";
import { CreatedProperty } from "./createdProperty";

class CreatedPropertyParent extends React.Component {
    render() {
        return <CreatedProperty />;
    }
}

export default withNavBars(CreatedPropertyParent);
