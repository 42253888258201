import React from "react";
import { withNavBars } from "../../HOCs";
import FileManager from "./fileManager";

class FileManagerParent extends React.Component {
    render() {
        return <FileManager {...this.props} />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(FileManagerParent, props);