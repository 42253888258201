import React from "react";
import { withNavBars } from "../../HOCs";
import DomesticHelperCreateEdit from "./domesticHelperCreateEdit";

class DomesticHelperCreateEditParent extends React.Component {
    render() {
        return <DomesticHelperCreateEdit />;
    }
}
const props = {
    boxShadow: false
}
export default withNavBars(DomesticHelperCreateEditParent, props);
