import * as React from "react"

const Step2IconSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} {...props}>
    <g data-name="Group 110024">
      <path
        data-name="Path 100088"
        d="M34 15v25a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h17Z"
        fill="#199be2"
      />
      <path
        data-name="Path 100089"
        d="M8 6h26v34H11.681A3.681 3.681 0 0 1 8 36.319Z"
        opacity={0.05}
      />
      <path
        data-name="Path 100090"
        d="M9 6h19.941A5.059 5.059 0 0 1 34 11.059V39H11.839A2.839 2.839 0 0 1 9 36.161Z"
        opacity={0.07}
      />
      <path
        data-name="Path 100091"
        d="M38 4v32a2 2 0 0 1-2 2H12a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2Z"
        fill="#50e6ff"
      />
      <path
        data-name="Path 100092"
        d="M29 14H17a1 1 0 0 0-1 1 1 1 0 0 0 1 1h12a1 1 0 0 0 1-1 1 1 0 0 0-1-1Z"
        fill="#057093"
      />
      <path
        data-name="Path 100093"
        d="M32 10H17a1 1 0 0 0-1 1 1 1 0 0 0 1 1h15a1 1 0 0 0 1-1 1 1 0 0 0-1-1Z"
        fill="#057093"
      />
      <path
        data-name="Path 100094"
        d="M32 18H17a1 1 0 0 0-1 1 1 1 0 0 0 1 1h15a1 1 0 0 0 1-1 1 1 0 0 0-1-1Z"
        fill="#057093"
      />
      <path
        data-name="Path 100095"
        d="M29 22H17a1 1 0 0 0-1 1 1 1 0 0 0 1 1h12a1 1 0 0 0 1-1 1 1 0 0 0-1-1Z"
        fill="#057093"
      />
      <path
        data-name="Path 100096"
        d="M32 26H17a1 1 0 0 0-1 1 1 1 0 0 0 1 1h15a1 1 0 0 0 1-1 1 1 0 0 0-1-1Z"
        fill="#057093"
      />
      <path
        data-name="Path 100097"
        d="M29 30H17a1 1 0 0 0-1 1 1 1 0 0 0 1 1h12a1 1 0 0 0 1-1 1 1 0 0 0-1-1Z"
        fill="#057093"
      />
    </g>
  </svg>
)

export default Step2IconSvg
