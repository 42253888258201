import React from 'react';
import { useStylesCreation } from "../salseagent/saleagent/style";
import { Box, Typography, Avatar } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { stringAvatar } from '../../utils';
export const Card = React.forwardRef((props, ref) => {
      const classes = useStylesCreation();
      return (
            <>
                  {/* add member card */}
                  {
                        props?.data?.map((x, index) => {
                              return (
                                    <div
                                          id={props?.id}
                                          className={classes.card1} onClick={() => props?.selectUser(x, index)}
                                    >
                                          <div className={classes.Cardcontent}>
                                                <Box className={classes.profilecardContent}>
                                                      <Box className={classes.UserImage}>
                                                            <Avatar src={x?.image_url} className={classes.avatarcard} {...stringAvatar(x?.first_name)} />
                                                      </Box>
                                                      <Box flexGrow={1} className={classes.UserDetails}>
                                                            <Box className={classes.userCard}>
                                                                  <Box>
                                                                        <Typography
                                                                              className={classes.titleMember}
                                                                              noWrap
                                                                        >
                                                                              {x?.first_name}&nbsp;{x?.last_name}
                                                                        </Typography>
                                                                  </Box>


                                                            </Box>
                                                            <Box
                                                                  className={classes.boxes}
                                                            >
                                                                  {
                                                                        (x?.mobile_no_country_code || x?.mobile_no) &&
                                                                        <div className={classes.profilecardContent}>
                                                                              <Box>
                                                                                    <Typography

                                                                                          className={classes.emailPhone}
                                                                                          noWrap
                                                                                    >
                                                                                          {x?.mobile_no_country_code}& nbsp; {x?.mobile_no}
                                                                                    </Typography >
                                                                              </Box >
                                                                        </div >
                                                                  }



                                                                  <div className={classes.profilecardContent}>
                                                                        {
                                                                              (x?.mobile_no_country_code || x?.mobile_no) && <Box className={classes.dot} />
                                                                        }

                                                                        <Box>
                                                                              <Typography
                                                                                    className={classes.emailPhone}
                                                                                    noWrap
                                                                              >
                                                                                    {x?.email_id}
                                                                              </Typography >
                                                                        </Box >
                                                                  </div >


                                                            </Box >
                                                      </Box >
                                                      <Box>

                                                      </Box>
                                                </Box >
                                                <Box>
                                                      {
                                                            props?.select?.id === x?.id && <img src="/images/ticknew.svg" alt="tick_img" style={{ marginTop: '10px' }} />
                                                      }

                                                </Box>
                                          </div >
                                    </div >
                              )
                        })
                  }

            </>
      )
});

export const Card1 = (props) => {
      const classes = useStylesCreation();
      return (
            // delete member card
            <div
                  className={classes.card1} style={{ cursor: 'pointer' }}
            >
                  <div className={classes.Cardcontent}>
                        <Box className={classes.profilecardContent}>
                              <Box style={{ marginRight: '4px' }}>
                                    <Avatar src={props?.member?.image_url} className={classes.avatarcard} {...stringAvatar(props?.member?.name)} />
                              </Box>
                              <Box flexGrow={1} marginLeft="10px">
                                    <Box className={classes.profilecardContent} justifyContent='space-between'>
                                          <Box>
                                                <Typography
                                                      className={classes.titleMember}
                                                      noWrap
                                                >
                                                      {props?.member?.name}
                                                </Typography>
                                          </Box>
                                          <Box>
                                                <DeleteOutlineIcon className={classes.deleteIcon} onClick={props?.deleteMember} />
                                          </Box>

                                    </Box>
                                    <Box
                                          className={classes.memberboxes}
                                    >
                                          <div className={classes.profilecardContent}>
                                                <Box>
                                                      <Typography
                                                            className={classes.emailPhone}
                                                            noWrap
                                                      >
                                                            {props?.member?.mobile_no_country_code}&nbsp;{props?.member?.mobile_no}
                                                      </Typography>
                                                </Box>
                                          </div>


                                          <div className={classes.profilecardContent}>
                                                <Box className={classes.dot} />
                                                <Box>
                                                      <Typography
                                                            className={classes.emailPhone}
                                                            noWrap
                                                      >
                                                            {props?.member?.email_id}
                                                      </Typography>
                                                </Box>
                                          </div>


                                    </Box>
                              </Box>
                              <Box>

                              </Box>
                        </Box>
                  </div>
            </div>
      )
}