import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "16px",
    },
    content:{
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        padding: "16px",
        height: `calc(100vh - 147px)`,
        overflow: "hidden",
    }
}));