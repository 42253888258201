import { Stack, Typography } from '@mui/material'
import React from 'react'
import ScrollContainer from "react-indiana-drag-scroll";
import { CustomToggleButtonStyle } from './style';

export const CustomToggleButton = ({
    list = [],
    isRequired = false,
    header,
    value = {},
    onSelect = () => false,
    isReadOnly = false
}) => {
    const classes = CustomToggleButtonStyle()
    return (
        <>
            <Typography className={classes.header} mb={"10px"}>{header}{isRequired && <Typography variant="caption" style={{ color: "red", marginLeft: 4 }}>
                *
            </Typography>}</Typography>
            <ScrollContainer className="scroll-container">
                <Stack direction={"row"} alignItems={"center"}>
                    {
                        list?.map((i) => {
                            return (
                                <Typography sx={{ marginInlineEnd: "8px" }}
                                    onClick={() => !isReadOnly && onSelect(i)}
                                    className={value?.value === i?.value ? classes.selected : classes.unselected}>
                                    {i?.label}
                                </Typography>
                            )
                        })
                    }
                </Stack>
            </ScrollContainer>
        </>
    )
}