import React from "react";
import { withNavBars } from "../../HOCs";
import SelfCheckInEntries from './selfCheckInEntries'

class SelfCheckInEntriesParent extends React.Component {
    render() {
        return (
            <SelfCheckInEntries />
        );
    }
}

const props = {
    boxShadow: false
}
export default withNavBars(SelfCheckInEntriesParent, props);