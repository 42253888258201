import React from "react";

export const QuotationsIcon = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_243_2268)">
      <circle cx="25.3999" cy="21.9191" r="21" fill="#FF857D" />
    </g>
    <path
      d="M16.1406 9.91907H32.7332C34.0423 9.91907 35.1036 10.8509 35.1036 12.0004V30.7321C35.1036 32.4563 33.5117 33.854 31.548 33.854H18.511C17.2019 33.854 16.1406 32.9222 16.1406 31.7727V9.91907Z"
      fill="white"
    />
    <path
      d="M35.3999 25.2366V12.3581C35.3999 11.7038 35.1075 11.0885 34.5766 10.6257C34.0488 10.1655 33.3536 9.91431 32.6154 9.91914H14.1777C12.646 9.91914 11.3999 11.0133 11.3999 12.3581V13.3338C11.3999 13.6032 11.6487 13.8216 11.9555 13.8216H15.8443V31.4801C15.8443 32.8249 17.0904 33.9191 18.6221 33.9191H30.959C31.2195 33.9191 31.5202 33.9191 31.8443 33.9191C33.808 33.9191 35.3999 32.5232 35.3999 30.799C35.3999 30.5855 35.3999 30.329 35.3999 30.0166C35.3999 27.6608 35.3999 27.7442 35.3999 25.2366ZM15.8443 12.8459H12.511V12.3581C12.511 11.5516 13.2579 10.8953 14.1762 10.8946H14.1819C15.099 10.8967 15.8443 11.5524 15.8443 12.3581V12.8459ZM21.474 32.8134H20.2888C19.6962 32.8134 19.5411 32.8134 18.6221 32.8134C17.9184 32.6833 17.3258 32.293 16.9554 31.48V12.3581C16.9554 11.8095 16.748 11.3026 16.3985 10.8947H32.6193C32.6233 10.8946 32.6272 10.8946 32.6311 10.8946C33.0679 10.8946 33.4804 11.0447 33.7937 11.3179C34.113 11.5962 34.2888 11.9657 34.2888 12.3581V25.1385C34.2888 29.1711 35.8443 32.9434 29.1777 32.9434L22.0666 32.8134C21.7598 32.8134 21.474 32.8134 21.474 32.8134Z"
      fill="#E3665E"
    />
    <path
      d="M20.8806 15.3621L20.1884 15.1835C19.8641 15.0997 19.7095 14.8671 19.8439 14.6649C19.9085 14.5677 20.031 14.4918 20.1891 14.4509C20.3471 14.4102 20.5201 14.4098 20.6759 14.4501L21.022 14.5394C21.3498 14.624 21.7253 14.527 21.8611 14.3228C21.9967 14.1185 21.8411 13.8844 21.5134 13.7999L21.1674 13.7105C21.0897 13.6905 21.0108 13.6738 20.9309 13.6603V13.4413C20.9309 13.2202 20.6434 13.041 20.2887 13.041C19.9341 13.041 19.6466 13.2202 19.6466 13.4413V13.7251C19.1961 13.8507 18.846 14.0745 18.6573 14.3586C18.2519 14.9685 18.7182 15.6704 19.6969 15.9231L20.3891 16.1017C20.7135 16.1855 20.8681 16.4181 20.7336 16.6204C20.6691 16.7175 20.5465 16.7935 20.3884 16.8343C20.2304 16.8751 20.0574 16.8754 19.9016 16.8352L19.5556 16.7458C19.2281 16.6613 18.8523 16.7582 18.7166 16.9625C18.5808 17.1667 18.7364 17.4008 19.0641 17.4854L19.4102 17.5748C19.4878 17.5948 19.5667 17.6115 19.6466 17.625V17.844C19.6466 18.0651 19.9341 18.2443 20.2887 18.2443C20.6434 18.2443 20.9309 18.0651 20.9309 17.844V17.5602C21.3814 17.4346 21.7316 17.2107 21.9203 16.9266C22.3257 16.3166 21.8593 15.6148 20.8806 15.3621Z"
      fill="#E3665E"
    />
    <path
      d="M19.1777 22.602H21.9555C22.2622 22.602 22.511 22.3836 22.511 22.1142V19.6752C22.511 19.4057 22.2622 19.1874 21.9555 19.1874H19.1777C18.8709 19.1874 18.6221 19.4057 18.6221 19.6752V22.1142C18.6221 22.3836 18.8709 22.602 19.1777 22.602ZM19.7332 20.163H21.3999V21.6264H19.7332V20.163Z"
      fill="#E3665E"
    />
    <path
      d="M19.1777 27.8052H21.9555C22.2622 27.8052 22.511 27.5868 22.511 27.3174V24.8784C22.511 24.609 22.2622 24.3906 21.9555 24.3906H19.1777C18.8709 24.3906 18.6221 24.609 18.6221 24.8784V27.3174C18.6221 27.5868 18.8709 27.8052 19.1777 27.8052ZM19.7332 25.3662H21.3999V26.8296H19.7332V25.3662Z"
      fill="#E3665E"
    />
    <path
      d="M24.7332 15.5288H32.0666C32.3733 15.5288 32.6221 15.3104 32.6221 15.041C32.6221 14.7716 32.3733 14.5532 32.0666 14.5532H24.7332C24.4264 14.5532 24.1777 14.7716 24.1777 15.041C24.1777 15.3104 24.4264 15.5288 24.7332 15.5288Z"
      fill="#E3665E"
    />
    <path
      d="M32.0668 19.4311H27.2332C26.9264 19.4311 26.6777 19.6495 26.6777 19.9189C26.6777 20.1883 26.9264 20.4067 27.2332 20.4067H32.0668C32.3736 20.4067 32.6223 20.1883 32.6223 19.9189C32.6223 19.6495 32.3736 19.4311 32.0668 19.4311Z"
      fill="#E3665E"
    />
    <path
      d="M32.0668 24.6344H27.2332C26.9264 24.6344 26.6777 24.8528 26.6777 25.1222C26.6777 25.3916 26.9264 25.61 27.2332 25.61H32.0668C32.3736 25.61 32.6223 25.3916 32.6223 25.1222C32.6223 24.8528 32.3736 24.6344 32.0668 24.6344Z"
      fill="#E3665E"
    />
    <path
      d="M24.7332 17.48H30.1222C30.4289 17.48 30.6777 17.2616 30.6777 16.9922C30.6777 16.7228 30.4289 16.5044 30.1222 16.5044H24.7332C24.4264 16.5044 24.1777 16.7228 24.1777 16.9922C24.1777 17.2616 24.4264 17.48 24.7332 17.48Z"
      fill="#E3665E"
    />
    <path
      d="M24.7332 22.3581H27.8722C28.1789 22.3581 28.4277 22.1397 28.4277 21.8703C28.4277 21.6009 28.1789 21.3825 27.8722 21.3825H24.7332C24.4264 21.3825 24.1777 21.6009 24.1777 21.8703C24.1777 22.1397 24.4264 22.3581 24.7332 22.3581Z"
      fill="#E3665E"
    />
    <path
      d="M24.7332 27.5613H27.8722C28.1789 27.5613 28.4277 27.3429 28.4277 27.0735C28.4277 26.8041 28.1789 26.5857 27.8722 26.5857H24.7332C24.4264 26.5857 24.1777 26.8041 24.1777 27.0735C24.1777 27.3429 24.4264 27.5613 24.7332 27.5613Z"
      fill="#E3665E"
    />
    <path
      d="M24.7334 20.4067C25.0402 20.4067 25.2889 20.1883 25.2889 19.9189C25.2889 19.6495 25.0402 19.4311 24.7334 19.4311C24.4266 19.4311 24.1777 19.6495 24.1777 19.9189C24.1777 20.1883 24.4266 20.4067 24.7334 20.4067Z"
      fill="#E3665E"
    />
    <path
      d="M24.7334 25.61C25.0402 25.61 25.2889 25.3916 25.2889 25.1222C25.2889 24.8528 25.0402 24.6344 24.7334 24.6344C24.4266 24.6344 24.1777 24.8528 24.1777 25.1222C24.1777 25.3916 24.4266 25.61 24.7334 25.61Z"
      fill="#E3665E"
    />
    <defs>
      <filter
        id="filter0_d_243_2268"
        x="0.399902"
        y="0.919067"
        width="50"
        height="50"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_243_2268"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_243_2268"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
