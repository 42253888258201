import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import { CardDetails, GraphComponent } from "./components";
import { useStylesCreation } from "./style";
import { AlertContext } from "../../contexts";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { propertyDetailsFunction } from "./common";
import { occupencyDashboradHead, occupencyDashboradPath } from "../../utils";
import { DialogBox } from "../../components";
import { AreaChart } from "./areaChart";
import { Map } from "./map";
import { HandOverDateComponent } from "./handoverDate";
import { RequestsComponent } from "./reuests";

const scale = {
  x: {
    stacked: true,
  },
  y: {
    stacked: true,
  },
};
const scaleLine = {
  y: {
    ticks: {
      // Include a dollar sign in the ticks
      callback: function (value, index, ticks) {
        return value + "%";
      },
    },
  },
};

export const DashBoard = (props) => {
  // states
  const [propertyDetails, setPropertyDetails] = useState("");
  const [zoom, setZoom] = useState(false);
  const [zoomGraph, setZoomGraph] = useState("");
  const [loader, setLoader] = useState(false);
  const { t = () => false } = props;

  // context
  const alert = React.useContext(AlertContext);

  // use dimension

  //   classes
  const classes = useStylesCreation();
  // get property stats
  const getPropertyStats = (company) => {
    setLoader(true);
    const payload = {
      company_id: [company] ?? undefined,
    };
    NetworkCall(
      `${config.api_url}/dashboard/property/stats`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const property = propertyDetailsFunction(res?.data?.data);
        setPropertyDetails(property);
        setLoader(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
  };
  // zoom function
  const zoomGraphFunction = (e) => {
    setZoomGraph(e);
    setZoom(true);
  };
  useEffect(() => {
    // company get
    if (props?.selectedcompany?.label?.length > 0) {
      getPropertyStats(props?.selectedcompany?.value);
    }
    //eslint-disable-next-line
  }, [props?.selectedcompany]);

  // close popup
  const handleCloseModule = () => {
    setZoom(false);
  };
  return (
    <div>
      {/* sub header */}
      <div className={classes.root}>
        {loader ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* section 1 */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} lg={1.5}>
                {/* property cards */}
                <Grid container spacing={2}>
                  {propertyDetails?.propertyDetails?.map((x) => {
                    return (
                      <Grid item xs={12}>
                        <CardDetails data={x} maxHeight={"116px"} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} lg={3}>
                {/* property graph */}
                <GraphComponent
                  title={"Property Types"}
                  graphData={propertyDetails?.propertyType ?? []}
                  isPie
                  margin={"20px"}
                  onZoomClick={zoomGraphFunction}
                  isZoom
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={1.5}>
                {/* unit card details */}
                <Grid container spacing={2}>
                  {propertyDetails?.unitDetails?.map((x) => {
                    return (
                      <Grid item xs={12}>
                        <CardDetails data={x} maxHeight={"116px"} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} lg={3}>
                {/* unit graph */}
                <GraphComponent
                  title={"Unit Status"}
                  graphData={propertyDetails?.unitstatusGraph}
                  isPie
                  margin={"20px"}
                  onZoomClick={zoomGraphFunction}
                  isZoom
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                {/* reveserd details card */}
                <Grid container spacing={2}>
                  {propertyDetails?.unitStatus?.map((x) => {
                    return (
                      <Grid item xs={6}>
                        <CardDetails data={x} />
                      </Grid>
                    );
                  })}
                </Grid>
                {/* area cards */}
                <Grid container spacing={2} mt={0.5}>
                  {propertyDetails?.areaDetails?.map((x) => {
                    return (
                      <Grid item xs={12}>
                        <CardDetails data={x} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>

            {/* setion2 */}
            <Grid container spacing={2} mt={0.3}>
              <Grid item xs={12} sm={12} lg={7.5}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={2.4}>
                    {/* residental cards */}
                    <Grid container spacing={2}>
                      {propertyDetails?.residentalDetails?.map((x) => {
                        return (
                          <Grid item xs={12}>
                            <CardDetails data={x} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={9.6}>
                    {/* Unit Category graph */}
                    <GraphComponent
                      title={"Unit Type"}
                      graphData={propertyDetails?.unitType}
                      isPie
                      divider={6}
                      divider1={6}
                      height={"325px"}
                      margin={"30px"}
                      onZoomClick={zoomGraphFunction}
                      isZoom
                      maxHeightGraph={"250px"}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={0.3}>
                  <Grid item xs={12} sm={12} lg={2.4}>
                    {/* residental cards */}
                    <Grid container spacing={2}>
                      {propertyDetails?.soldDetails?.map((x) => {
                        return (
                          <Grid item xs={12}>
                            <CardDetails data={x} maxHeight={"116px"} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4.8}>
                    {/* Unit Category graph */}
                    <AreaChart selectedcompany={props?.selectedcompany} />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4.8}>
                    {/* Unit Category graph */}
                    <GraphComponent
                      title={"Residential Vs Commerical"}
                      graphData={
                        propertyDetails?.residential_vs_commerical ?? []
                      }
                      innerRadius={50}
                      margin={"30px"}
                      isPie
                      isTotal
                      total={
                        propertyDetails?.residential_vs_commerical_total ?? 0
                      }
                      onZoomClick={zoomGraphFunction}
                      isZoom
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} lg={4.5}>
                <Map selectedcompany={props?.selectedcompany} />
              </Grid>
            </Grid>

            {/* section 3 */}
            <Grid container spacing={2} mt={0.3}>
              <Grid item xs={12} sm={12} lg={4}>
                <HandOverDateComponent
                  t={t}
                  selectedcompany={props?.selectedcompany}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={8}>
                <RequestsComponent selectedcompany={props?.selectedcompany} />
              </Grid>
            </Grid>

            {/* section 4 */}
            <Grid container spacing={2} mt={0.3}>
              <Grid item xs={12} sm={12} lg={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    {/* Unit Category graph */}
                    <GraphComponent
                      title={"Unit Category"}
                      graphData={propertyDetails?.unit_catagory ?? []}
                      isBar
                      scale={scale}
                      margin={"30px 0px"}
                      onZoomClick={zoomGraphFunction}
                      isZoom
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <GraphComponent
                      title={"Vacant Units By Property"}
                      graphData={propertyDetails?.vacant_unit_by_property ?? []}
                      isBar
                      indexAxis={"y"}
                      margin={"30px"}
                      isDatalabels
                      onZoomClick={zoomGraphFunction}
                      isZoom
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <GraphComponent
                      title={"Occupancy Trend Analysis"}
                      graphData={
                        propertyDetails?.property_occupancy_trends ?? []
                      }
                      line
                      margin={"30px"}
                      onZoomClick={zoomGraphFunction}
                      isZoom
                      scale={scaleLine ?? false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6}>
                    <GraphComponent
                      isTable
                      title={"Occupancy By Property"}
                      tableData={propertyDetails?.property_unit_occupancy ?? []}
                      tabelheight={"calc(100vh - 355px)"}
                      heading={occupencyDashboradHead}
                      path={occupencyDashboradPath}
                      dataType={[
                        { type: ["text"], name: "property_no" },
                        { type: ["text"], name: "name" },
                        { type: ["text"], name: "total_units" },
                        { type: ["text"], name: "total_occupied_units" },
                        { type: ["text"], name: "occupancy" },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </div>

      {/* zoom dialog for each graph */}
      <DialogBox
        isnotTitle
        maxWidth={zoomGraph === "Map View" ? "lg" : "sm"}
        handleClose={handleCloseModule}
        open={zoom}
        component={
          <div>
            {zoomGraph === "Property Types" && (
              <GraphComponent
                title={"Property Types"}
                graphData={propertyDetails?.propertyType ?? []}
                isPie
                margin={"40px"}
                divider={6}
                divider1={6}
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
              />
            )}
            {zoomGraph === "Unit Status" && (
              <GraphComponent
                title={"Unit Status"}
                graphData={propertyDetails?.unitstatusGraph}
                isPie
                margin={"40px"}
                divider={6}
                divider1={6}
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
              />
            )}
            {zoomGraph === "Unit Type" && (
              <GraphComponent
                title={"Unit Type"}
                graphData={propertyDetails?.unitType}
                isPie
                divider={6}
                divider1={6}
                height={"325px"}
                margin={"40px"}
                padding={"24px 24px 47px"}
                maxHeightGraph={"250px"}
              />
            )}
            {zoomGraph === "Residential Vs Commerical" && (
              <GraphComponent
                title={"Residential Vs Commerical"}
                graphData={propertyDetails?.residential_vs_commerical ?? []}
                innerRadius={50}
                margin={"40px"}
                isPie
                isTotal
                divider={6}
                divider1={6}
                total={propertyDetails?.residential_vs_commerical_total ?? 0}
                padding={"24px 24px 47px"}
              />
            )}
            {zoomGraph === "Unit Category" && (
              <GraphComponent
                title={"Unit Category"}
                graphData={propertyDetails?.unit_catagory ?? []}
                isBar
                scale={scale}
                margin={"30px 0px"}
                padding={"24px"}
              />
            )}
            {zoomGraph === "Vacant Units By Property" && (
              <GraphComponent
                title={"Vacant Units By Property"}
                graphData={propertyDetails?.vacant_unit_by_property ?? []}
                isBar
                indexAxis={"y"}
                margin={"30px"}
                isDatalabels
                padding={"24px"}
              />
            )}
            {zoomGraph === "Occupancy Trend Analysis" && (
              <GraphComponent
                title={"Occupancy Trend Analysis"}
                graphData={propertyDetails?.property_occupancy_trends ?? []}
                line
                margin={"30px"}
                padding={"24px"}
              />
            )}
          </div>
        }
      />
    </div>
  );
};
