import React from "react";
import { withNavBars } from "../../../HOCs";
import  ContractCreateEdit  from "./contractCreateEdit";
import CreateEditContext from "./createEditContext";

class CreateEditContractParent extends React.Component {
  render() {
    return (
      <CreateEditContext>
        <ContractCreateEdit />
      </CreateEditContext>
    );
  }
}
const props = {
  boxShadow: false
}
export default withNavBars(CreateEditContractParent,props);
