export const Path = [
    "requestNo",
    "agreementNo",
    "requestedBy",
    "requestedOn",
    "noOfUnits",
    "agree_exit_date",
    "moveOutDate",
    "actual_exit_date",
    "days_difference",
    "status",
]

export const Heading = (t) => [
    { title: t("Request Number"), field: "requestNo" },
    { title: t("Agreement Number"), field: "agreementNo" },
    { title: t("Requested By"), field: "requestedBy", },
    { title: t("Requested On"), field: "requestedOn", },
    { title: t("Units"), field: "noOfUnits", },
    { title: t("Agree Exit Date"), field: "agree_exit_date", },
    { title: t("Move Out Date"), field: "moveOutDate", },
    { title: t("Actual Exit Date"), field: "actual_exit_date", },
    { title: t("Days Difference"), field: "days_difference", },
    { title: t("Status"), field: "status", },
]

export const Type = [
    { type: ["text"], name: "requestNo" },
    { type: ["text"], name: "agreementNo" },
    { type: ["text"], name: "requestedBy" },
    { type: ["date"], name: "requestedOn" },
    { type: ["text"], name: "noOfUnits" },
    { type: ["date"], name: "agree_exit_date" },
    { type: ["date"], name: "moveOutDate" },
    { type: ["date"], name: "actual_exit_date" },
    { type: ["object_status_color"], name: "days_difference" },
    { type: ["object_status"], name: "status" },
]

export const StatusOptionList = (t) => [
    { label: t('Active'), value: true },
    { label: t('Inactive'), value: false },
]

export const UnitPath = ["logo", "unit_name", "unit_no", "property_name"]

export const UnitHeading = (t) => [
    { title: t("Image"), field: "logo" },
    { title: t("Name"), field: "unit_name" },
    { title: t("Unit ID"), field: "unit_no", },
    { title: t("Property Name"), field: "property_name", }
]

export const UnitType = [
    { type: ["avatarmanagement"], name: "logo" },
    { type: ["text"], name: "unit_name" },
    { type: ["text"], name: "unit_no" },
    { type: ["text"], name: "property_name" }
]