/* eslint-disable no-unused-vars */
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import CloseIconComponent from "../../assets/closeIcons";
import { FilterGenerator, LoadingSection } from "../../components";
import Subheader from "../../components/subheader/subheader";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  ExtraBold,
  NetWorkCallMethods,
  accessCheckRender,
  getCalendarOptions,
  getCompanyOption,
  getRoutePermissionNew
} from "../../utils";
import { loadOptionsApis } from "../../utils/asyncPaginateLoadOptions";
import {
  status,
  transctionHistoryDetails
} from "../../utils/paymentDashboard";
import { ListCard, PaymenTable } from "./components";
import Piechart from "./components/pieChart";
import { paymentDashboardStyle } from "./style";

const Dashboard = ({ t }) => {
  const classes = paymentDashboardStyle();
  const alert = React.useContext(AlertContext);
  const auth = React.useContext(AuthContext);
  const backdrop = React.useContext(BackdropContext);
  const [permission, setPermission] = React.useState({});
  const [selectedCalendar, setSelectedCalendar] = React.useState([]);
  const [propertyValue, setPropertyValue] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [limit, setLimit] = React.useState(10); // limit text
  const [page, setPage] = React.useState(0); // page offset text
  const [isDrawer, setDrawer] = React.useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const [dashboardCards, setDashboardCards] = useState([]); //charts data
  const [tableList, setTableList] = useState([]); //table data
  const [historyList, setHistoryList] = useState(""); //account listing for filter
  const [isTransactionDrawer, setTransactionDrawer] = React.useState(false);
  const [companyList, setCompanyList] = useState([]); //property company list
  const [paymentCardList, setPaymentCardList] = useState([]); //payment list for filter
  const [filterData, setFilterData] = React.useState({
    task: "",
    status: "",
  });

  //navbar calendar select
  const handleCalendarChange = (e) => {
    setSelectedCalendar(e);
    const company_id = propertyValue?.value || "";
    const start_date = e?.value?.from_date;
    const end_state = e?.value?.to_date;
    getCardCharts(company_id, start_date, end_state);
    getTablelistApi(page, limit, searchText, company_id, start_date, end_state);
  };

  //navbar property select
  const onPropertyChange = (e) => {
    setPropertyValue(e);
    const start_date = selectedCalendar?.value?.from_date;
    const end_state = selectedCalendar?.value?.to_date;
    getCardCharts(e?.value, start_date, end_state);
    getTablelistApi(page, limit, searchText, e?.value, start_date, end_state);
  };

  //pagination offset
  const handlePagination = (value) => {
    const company_id = propertyValue?.value || "";
    const start_date = selectedCalendar?.value?.from_date;
    const end_state = selectedCalendar?.value?.to_date;
    setPage(value);
    let offset = (value - 1) * limit;
    getTablelistApi(
      offset,
      limit,
      searchText,
      company_id,
      start_date,
      end_state,
      filterData?.task,
      filterData?.status
    );
  };

  //on change limit
  const handleChangeLimit = (value) => {
    const company_id = propertyValue?.value || "";
    const start_date = selectedCalendar?.value?.from_date;
    const end_state = selectedCalendar?.value?.to_date;
    setLimit(value);
    setPage(1);
    getTablelistApi(
      0,
      value,
      searchText,
      company_id,
      start_date,
      end_state,
      filterData?.task,
      filterData?.status
    );
  };

  //on search
  const handleSearch = (e) => {
    const company_id = propertyValue?.value || "";
    const start_date = selectedCalendar?.value?.from_date;
    const end_state = selectedCalendar?.value?.to_date;
    getTablelistApi(
      page,
      limit,
      e,
      company_id,
      start_date,
      end_state,
      filterData?.task,
      filterData?.status
    );
    setSearchText(e);
  };

  //filter apply
  const handleApply = (value) => {
    // const client_id = localStorage.getItem("clinetID");
    setFilterData({ task: value?.task, status: value?.status });
    const company_id = propertyValue?.value || "";
    const start_date = selectedCalendar?.value?.from_date;
    const end_state = selectedCalendar?.value?.to_date;
    const status = value?.status;
    const payment_method = value?.task;
    const account_no = value?.account?.map((v) => v?.value);
    getTablelistApi(
      page,
      limit,
      searchText,
      company_id,
      start_date,
      end_state,
      payment_method,
      status,
      account_no
    );
    setDrawer(false);
  };

  //view transcation history
  const handleIcon = (row, data) => {
    setHistoryList(data);
    setTransactionDrawer(true);
  };

  //get payment
  const getpayment = () => {
    const payload = {
      client: localStorage.getItem("clinetID"),
      company_id: propertyValue?.value ?? "",
      offset: 0,
      limit: 10,
    };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    NetworkCall(
      `${config.payment_gateway_url}dashboard/get_payment_method`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        if (response?.data?.type === "success") {
          const result = response?.data?.data[0]?.map((val) => {
            return {
              label: val?.label,
              value: val?.label,
            };
          });
          setPaymentCardList(result);
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            msg: response?.data?.data?.message,
            severity: AlertProps.severity.error,
          });
        }
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert?.setSnack({
          ...alert,
          open: true,
          msg:
            err?.response?.data?.error?.message ??
            "something went wrong for client",
          severity: AlertProps.severity.error,
        });
      });
  };

  //get cardcharts data
  const getCardCharts = (company_id, start_date, end_date) => {
    const payload = {
      client: localStorage.getItem("clinetID") ?? "",
      company_id: company_id ?? "",
      start_date: start_date ?? "",
      end_date: end_date ?? "",
    };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    NetworkCall(
      `${config.payment_gateway_url}dashboard`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        if (response?.data?.type === "success") {
          setDashboardCards(response?.data?.data);
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            msg: response?.data?.data?.message,
            severity: AlertProps.severity.error,
          });
        }
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        // alert.setSnack({
        //   ...alert, open: true, msg: err?.response?.data?.error?.message ?? "something went wrong for client",
        //   severity: AlertProps.severity.error,
        // });
      });
  };

  //get table history data
  const getTablelistApi = (
    offset,
    limit,
    search,
    company_id,
    start_date,
    end_date,
    payment_method,
    status,
    account_no
  ) => {
    const payload = {
      client: localStorage.getItem("clinetID"),
      company_id: company_id ?? "",
      start_date: start_date ?? "",
      end_date: end_date ?? "",
      status: status ?? [],
      payment_method: payment_method ?? [],
      account_no: account_no ?? [],
      offset: offset ?? 0,
      limit: limit ?? 10,
      search: search ?? "",
    };
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    NetworkCall(
      `${config.payment_gateway_url}dashboard/get_transaction_history`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        if (response?.data?.type === "success") {
          setTableList(response?.data);
        } else {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            msg: response?.data?.data?.message,
            severity: AlertProps.severity.error,
          });
        }
      })
      .catch((err) => {
        // alert.setSnack({
        //   ...alert, open: true, msg: err?.response?.data?.error?.message ?? "something went wrong for client",
        //   severity: AlertProps.severity.error,
        // });
      });
  };

  //modifiedTable data
  const mappedData = tableList?.result?.map((v) => {
    return {
      ...v,
      date_time: v?.created_at,
      payment_method: v?.payment_method,
      reference_no: v?.reference_no,
      transaction_id: v?.transaction_id,
      amount: v?.amount,
      currency: propertyValue?.currency?.name ?? v?.currency,
      status: (
        <Typography color={v?.status === "failure" ? "#FF4B4B" : "#5AC782"}>
          {v?.status}
        </Typography>
      ),
    };
  });

  const pieOverAllPayment =
    dashboardCards?.over_all_payment_method &&
    dashboardCards?.over_all_payment_method?.map((v) => {
      return {
        count:
          parseInt(v?.percentage?.replaceAll("(", "").replaceAll(")", "")) ?? 0,
        name: v?.label ?? "",
        fill: v?.fill ?? "",
        totalValue: v?.value ?? 0,
      };
    });

  const pieFailurePayment =
    dashboardCards?.failure_payment_method &&
    dashboardCards?.failure_payment_method?.map((v) => {
      return {
        count:
          parseInt(v?.percentage?.replaceAll("(", "").replaceAll(")", "")) ?? 0,
        name: v?.label ?? "",
        fill: v?.fill ?? "",
        totalValue: v?.value ?? 0,
      };
    });

  useEffect(() => {
    const perm = getRoutePermissionNew(auth);
    if (perm) {
      setPermission(perm);
    }
    let company = getCompanyOption(backdrop, auth, alert);
    const start_date = getCalendarOptions()?.[0]?.value?.from_date;
    const end_state = getCalendarOptions()?.[0]?.value?.to_date;
    const initializeData = async () => {
      setSelectedCalendar(getCalendarOptions()?.[0]);
      setPropertyValue(company?.list?.[0]);
      setCompanyList(company?.list);
      getCardCharts(company?.list?.[0]?.value, start_date, end_state);
      getTablelistApi(
        page,
        limit,
        searchText,
        company?.list?.[0]?.value,
        start_date,
        end_state
      );
    };
    initializeData();
    // }
    // eslint-disable-next-line
  }, [auth]);

  const getValueForLabel = (label) => {
    switch (label) {
      case "Status":
        return historyList?.status;
      case "Amount":
        return historyList?.amount;
      case "Date & Time":
        return moment(historyList?.date_time).format("D MMM YY, h:mm A");
      case "Payment Method":
        return historyList?.payment_method;
      case "Reference Number":
        return historyList?.reference_no;
      case "Customer Name":
        return historyList?.customer.name;
      case "Email Id":
        return historyList?.customer?.email_id;
      case "Account Reference Number":
        return historyList?.customer?.reference_no;
      default:
        return "";
    }
  };

  const customerLabels = [
    "Customer Name",
    "Email Id",
    "Account Reference Number",
  ];
  const otherLabels = transctionHistoryDetails.filter(
    (label) => !customerLabels.includes(label)
  );

  const renderLabelValuePairs = (labels) => {
    return labels.map((label, index) => (
      <Stack
        key={index}
        flexDirection={"row"}
        justifyContent={"space-between"}
        p={"8px 0px"}
        alignItems={"center"}
      >
        <Typography className={classes.subTitle}>{label}</Typography>
        {label === "Status" ? (
          <Box display={"flex"}>
            {historyList?.status?.props?.children === "sucess" ? (
              <Typography className={classes.sccessBg}>{"Success"}</Typography>
            ) : (
              <Typography className={classes.failureBg}>{"Failure"}</Typography>
            )}
          </Box>
        ) : (
          <Typography className={classes.titleFont}>
            {getValueForLabel(label)}
          </Typography>
        )}
      </Stack>
    ));
  };

  const render = () => {
    return (
      <div>
        <Subheader
          hideBackButton={true}
          title={t("Payment Gateway Transactions")}
          calanderselect
          calendarPlaceholder={t("Select Calendar")}
          calendarOptions={getCalendarOptions()}
          calanderValue={selectedCalendar}
          onChangeCalendar={(e) => handleCalendarChange(e)}
          onPropertyChange={(e) => {
            onPropertyChange(e);
          }}
          propertyPlaceholder={t("Select Company")}
          selectProperty
          propertyOptions={companyList}
          propertyValue={propertyValue}
        />
        <Box
          sx={{
            padding: { xs: "12px 12px 0px", md: "16px 16px 0px" },
            height: "calc(80vh - 10px)",
            overflow: "auto",
          }}
        >
          {/* list card */}
          <ListCard
            currency={propertyValue?.currency?.code ?? ""}
            list={[
              dashboardCards?.total_transaction_value,
              dashboardCards?.total_transaction_count,
              dashboardCards?.success_transaction_count,
              dashboardCards?.failure_transaction_count,
            ]}
          />

          {/* pie chart */}
          <Grid container spacing={2}>
            {pieOverAllPayment?.length > 0 && (
              <Grid item xs={12} md={6}>
                <Piechart
                  header={t("Overall Payment Method")}
                  graphData={pieOverAllPayment}
                  isPie
                  margin={"20px"}
                  minWidth={true}
                  padding={"16px"}
                />
              </Grid>
            )}
            {pieFailurePayment?.length > 0 && (
              <Grid item xs={12} md={6}>
                <Piechart
                  header={t("Failure Payment Method")}
                  graphData={pieFailurePayment}
                  isPie
                  margin={"20px"}
                  minWidth={true}
                  padding={"16px"}
                />
              </Grid>
            )}
          </Grid>

          {/* table component */}
          {loading ? (
            <LoadingSection top="20vh" message={t("loading")} />
          ) : (
            <Box className={classes.root}>
              <PaymenTable
                openfilter={() => {
                  // getAccountList();
                  getpayment();
                  setDrawer(true);
                }}
                handleIcon={handleIcon}
                handleChangeLimit={handleChangeLimit}
                handlePagination={handlePagination}
                page={page}
                filterData={[]}
                count={tableList?.count}
                list={mappedData}
                limit={limit}
                handleSearch={handleSearch}
                searchText={searchText}
              />
            </Box>
          )}
        </Box>
        {/* view table transcation views */}
        {isTransactionDrawer && (
          <Drawer
            className={classes.drawer}
            open={isTransactionDrawer}
            anchor="right"
            onClose={() => setTransactionDrawer(false)}
          >
            <Box>
              <Box display="flex" p={1} alignItems="center">
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => setTransactionDrawer(false)}
                  >
                    <CloseIconComponent />
                  </IconButton>
                </Box>{" "}
                &nbsp;
                <Box flexGrow={1}>
                  <Typography className={classes.titleFont}>
                    {"Ref No:" + historyList?.reference_no}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ m: "10px 0px" }} />
              <Box p={2}>
                <Typography className={classes.title}>
                  {t("DETAILS")}
                </Typography>
                {renderLabelValuePairs(otherLabels)}
                <Divider sx={{ m: "10px 0px" }} />
                <Typography className={classes.title}>
                  {t("CUSTOMER DETAILS")}
                </Typography>
                {renderLabelValuePairs(customerLabels)}
              </Box>
            </Box>
          </Drawer>
        )}

        {isDrawer && (
          <FilterGenerator
            open={isDrawer}
            onClose={() => setDrawer(false)}
            components={[
              {
                component: "toggleButton",
                value: filterData?.task,
                options: paymentCardList,
                isMulti: true,
                state_name: "task",
                label: !paymentCardList?.length ? "" : t("Task Category"),
                is_divider: true,
                labelColor: "#091B29",
                labelSize: "16px",
                fontFamily: ExtraBold,
                buttonGroupStyle: {
                  gap: "10px",
                },
              },
              {
                component: "toggleButton",
                value: filterData?.status,
                options: status,
                isMulti: true,
                state_name: "status",
                label: t("Status"),
                labelColor: "#091B29",
                labelSize: "16px",
                fontFamily: ExtraBold,
                buttonGroupStyle: {
                  gap: "10px",
                },
              },
              {
                component: "select",
                // value: filterData?.account,
                state_name: "account",
                placeholder: t("Search Account"),
                label: t("Account"),
                loadOptions: (search, array, handleLoading) =>
                  loadOptionsApis(
                    `dashboard/get_all_accounts`,
                    {
                      client: localStorage.getItem("clinetID"),
                      company_id: propertyValue?.value,
                    },
                    search,
                    array,
                    handleLoading,
                    "data",
                    {},
                    { isPayment: true }
                  ),
                debounceTimeout: 800,
                isPaginate: true,
                // options: [],
                optionWidth: "100% !important",
                isMulti: true,
              },
            ]}
            onApply={(value) => handleApply(value)}
          />
        )}
      </div>
    );
  };

  return <div>{accessCheckRender(render, permission)}</div>;
};
export default withNamespaces("paymentDashboard")(Dashboard);
