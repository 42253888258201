export const Menu = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
    <defs>
      <style>
        {".cls-2{fill:#c1c5cb;stroke:transparent;stroke-miterlimit:10}"}
      </style>
    </defs>
    <g
      id="Group_99695"
      data-name="Group 99695"
      transform="translate(-1646 480)"
    >
      <g
        id="Group_8127"
        data-name="Group 8127"
        transform="translate(-10 -1483)"
      >
        <g
          id="Group_99684"
          data-name="Group 99684"
          transform="translate(-4 -42)"
        >
          <path
            id="Rectangle_31"
            data-name="Rectangle 31"
            transform="translate(1660 1045)"
            style={{
              fill: "none",
            }}
            d="M0 0h30v30H0z"
          />
          <g id="menu_1_" data-name="menu (1)" transform="translate(1668 990)">
            <g
              id="Group_99697"
              data-name="Group 99697"
              transform="translate(0 64)"
            >
              <g id="Group_99696" data-name="Group 99696">
                <path
                  id="Rectangle_52739"
                  data-name="Rectangle 52739"
                  className="cls-2"
                  transform="translate(0 10.333)"
                  style={{
                    fill: props?.fill ?? "#c1c5cb",
                  }}
                  d="M0 0h15v1.667H0z"
                />
                <path
                  id="Rectangle_52740"
                  data-name="Rectangle 52740"
                  className="cls-2"
                  transform="translate(0 5.167)"
                  style={{
                    fill: props?.fill ?? "#c1c5cb",
                  }}
                  d="M0 0h15v1.667H0z"
                />
                <path
                  id="Rectangle_52741"
                  data-name="Rectangle 52741"
                  className="cls-2"
                  style={{
                    fill: props?.fill ?? "#c1c5cb",
                  }}
                  d="M0 0h15v1.667H0z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
