import React from "react"

export const BuildingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
    <g id="Group_111981" data-name="Group 111981" transform="translate(21090 24766)">
      <g id="Rectangle_55756" data-name="Rectangle 55756" transform="translate(-21090 -24766)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="24" height="24" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
      </g>
      <path id="icons8-building" d="M17.575,4h-6.65A1.925,1.925,0,0,0,9,5.925v11.2A.875.875,0,0,0,9.875,18h2.45a.875.875,0,0,0,.875-.875V15.55a.35.35,0,0,1,.35-.35h1.4a.35.35,0,0,1,.35.35v1.575a.875.875,0,0,0,.875.875h2.45a.875.875,0,0,0,.875-.875V5.925A1.925,1.925,0,0,0,17.575,4Zm-5.6,9.45h-.35a.525.525,0,0,1,0-1.05h.35a.525.525,0,0,1,0,1.05Zm0-2.1h-.35a.525.525,0,0,1,0-1.05h.35a.525.525,0,0,1,0,1.05Zm0-2.1h-.35a.525.525,0,0,1,0-1.05h.35a.525.525,0,0,1,0,1.05Zm0-2.1h-.35a.525.525,0,0,1,0-1.05h.35a.525.525,0,0,1,0,1.05Zm2.45,6.3h-.35a.525.525,0,0,1,0-1.05h.35a.525.525,0,0,1,0,1.05Zm0-2.1h-.35a.525.525,0,0,1,0-1.05h.35a.525.525,0,0,1,0,1.05Zm0-2.1h-.35a.525.525,0,0,1,0-1.05h.35a.525.525,0,0,1,0,1.05Zm0-2.1h-.35a.525.525,0,0,1,0-1.05h.35a.525.525,0,0,1,0,1.05Zm2.45,6.3h-.35a.525.525,0,1,1,0-1.05h.35a.525.525,0,1,1,0,1.05Zm0-2.1h-.35a.525.525,0,1,1,0-1.05h.35a.525.525,0,1,1,0,1.05Zm0-2.1h-.35a.525.525,0,1,1,0-1.05h.35a.525.525,0,1,1,0,1.05Zm0-2.1h-.35a.525.525,0,1,1,0-1.05h.35a.525.525,0,1,1,0,1.05Z" transform="translate(-21092 -24765)" fill="#98a0ac" />
    </g>
  </svg>

)