export const AssetReplacementIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_116017" data-name="Group 116017" transform="translate(0.001)">
                <g id="Rectangle_58161" data-name="Rectangle 58161" transform="translate(-0.001)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <path id="icons8-replace" d="M7.734,5.973a1,1,0,0,0-.985,1.013v3.5a1,1,0,0,0,1,1h3.5a1,1,0,1,0,0-2h-.9a7.188,7.188,0,0,1,4.4-1.5,6.933,6.933,0,0,1,4.38,1.536,1,1,0,1,0,1.231-1.573,9.024,9.024,0,0,0-5.612-1.961,9.214,9.214,0,0,0-6,2.213V6.985A1,1,0,0,0,7.734,5.973Zm14.5,4.51a1,1,0,1,0,1,1A1,1,0,0,0,22.237,10.483ZM6.5,13.98a1,1,0,1,0,1,1A1,1,0,0,0,6.5,13.98Zm16.487,0a1,1,0,1,0,1,1A1,1,0,0,0,22.987,13.98Zm-15.738,3.5a1,1,0,1,0,1,1A1,1,0,0,0,7.249,17.477Zm10.992,1a1,1,0,1,0,0,2h.9a7.188,7.188,0,0,1-4.4,1.5,6.933,6.933,0,0,1-4.38-1.536,1,1,0,1,0-1.231,1.573,9.023,9.023,0,0,0,5.612,1.961,9.214,9.214,0,0,0,6-2.213v1.214a1,1,0,1,0,2,0v-3.5a1,1,0,0,0-1-1Z" transform="translate(-4.744 -4.973)" fill="#78b1fe" />
            </g>
        </svg>

    )
}