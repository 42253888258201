import * as React from "react"
const InvoiceCreateIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13.474} height={16} {...props}>
    <path
      fill="#4e5a6b"
      d="M.643 0A.632.632 0 0 0 0 .631v13.053A2.325 2.325 0 0 0 2.316 16h8.842a2.325 2.325 0 0 0 2.316-2.316V.631a.632.632 0 0 0-.888-.577l-1.651.734L9.077.045a.632.632 0 0 0-.469 0L6.737.793 4.866.045a.632.632 0 0 0-.469 0L2.539.788.888.054A.632.632 0 0 0 .643 0Zm3.988 1.312L6.5 2.06a.632.632 0 0 0 .469 0l1.871-.748 1.871.748a.632.632 0 0 0 .491-.009L12.21 1.6v12.08a1.043 1.043 0 0 1-1.053 1.053H2.316a1.043 1.043 0 0 1-1.053-1.053V1.6l1.007.447a.632.632 0 0 0 .491.009ZM3.157 5.053a.632.632 0 1 0 0 1.263h7.158a.632.632 0 1 0 0-1.263Zm0 3.789a.632.632 0 1 0 0 1.263h3.789a.632.632 0 1 0 0-1.263Zm6.316 0a.632.632 0 1 0 0 1.263h.842a.632.632 0 1 0 0-1.263Zm-6.316 2.526a.632.632 0 1 0 0 1.263h3.789a.632.632 0 1 0 0-1.263Zm6.316 0a.632.632 0 1 0 0 1.263h.842a.632.632 0 1 0 0-1.263Z"
      data-name="icons8-bill (6)"
    />
  </svg>
)
export default InvoiceCreateIcon
