export const CaseManagement = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g
            transform="translate(5 5)"
            style={{
                fill: props?.fill ?? "#c1c5cb",
            }}
            data-name="Group 105166"
        >
            <path
                data-name="Path 98210"
                d="m17.764 16.621-1.411-1.411a3.449 3.449 0 0 1-1.143 1.143l1.411 1.411a.808.808 0 0 0 1.143 0 .808.808 0 0 0 0-1.143Z"
            />
            <path
                data-name="Path 98211"
                d="M10.221 12.228a3.21 3.21 0 0 0-.792.478v3.007a.551.551 0 0 0 .118-.079 3.393 3.393 0 0 1 .855-.6 3.395 3.395 0 0 1-.181-2.806Z"
            />
            <path
                data-name="Path 98212"
                d="M13.429 10a3.427 3.427 0 0 1 2.857 1.537V2.571A.571.571 0 0 0 15.715 2h-3.527a5.49 5.49 0 0 0-2.759.852v2.292a6.473 6.473 0 0 1 3.143-.859h1.143a.286.286 0 0 1 0 .571h-1.143a5.55 5.55 0 0 0-3.143.994v2.723a6.473 6.473 0 0 1 3.143-.859h1.143a.286.286 0 0 1 0 .571h-1.143a5.55 5.55 0 0 0-3.143.993V12a5.193 5.193 0 0 1 1.2-.541 3.425 3.425 0 0 1 2.8-1.459Z"
            />
            <path
                data-name="Path 98213"
                d="M2.571 2A.571.571 0 0 0 2 2.571V14a.571.571 0 0 0 .571.571H6.1a4.1 4.1 0 0 1 2.641 1.064.551.551 0 0 0 .118.079v-3.007a5.55 5.55 0 0 0-3.143-.993H4.571a.286.286 0 0 1 0-.571h1.143A6.473 6.473 0 0 1 8.857 12V9.278a5.55 5.55 0 0 0-3.143-.993H4.571a.286.286 0 1 1 0-.571h1.143a6.473 6.473 0 0 1 3.143.859V5.85a5.55 5.55 0 0 0-3.143-.993H4.571a.286.286 0 1 1 0-.571h1.143a6.473 6.473 0 0 1 3.143.859V2.852A5.49 5.49 0 0 0 6.1 2Z"
            />
            <path
                data-name="Path 98214"
                d="M16.285 13.428a2.857 2.857 0 1 0-2.857 2.857 2.86 2.86 0 0 0 2.857-2.857Zm-2.857 2.286a2.286 2.286 0 1 1 2.286-2.286 2.288 2.288 0 0 1-2.286 2.286Z"
            />
            <circle
                data-name="Ellipse 129544"
                cx={1.714}
                cy={1.714}
                r={1.714}
                transform="translate(11.714 11.714)"
            />
        </g>
    </svg>
)