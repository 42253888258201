import React from "react"

const MerchantAccount = (props) =>{
  return(
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.762" viewBox="0 0 16 16.762">
  <path id="icons8-merchant-account" d="M13.2,4.1a2.2,2.2,0,0,0-1.361.47l-5.61,4.42A.6.6,0,0,0,6.6,10.057H19.8a.6.6,0,0,0,.371-1.071l-5.609-4.42A2.2,2.2,0,0,0,13.2,4.1Zm0,1.2a1,1,0,0,1,.619.216L18.07,8.857H8.33l4.251-3.348A1,1,0,0,1,13.2,5.293Zm0,1.165a.8.8,0,1,0,.8.8A.8.8,0,0,0,13.2,6.457Zm-5.8,4.4v5.248a1.8,1.8,0,0,0,.4,3.552H12v-1.2H7.8a.6.6,0,1,1,0-1.2H12v-1.2h-.8v-5.2H10v5.2H8.6v-5.2Zm5.2,0v3.056a1.353,1.353,0,0,1,.8-.256h.4v-2.8Zm2.6,0v2.8h1.2v-2.8Zm2.6,0v2.8H19v-2.8Zm-4,3.6a1.009,1.009,0,0,0-1,1v4.4a1.009,1.009,0,0,0,1,1H21a1.009,1.009,0,0,0,1-1v-4.4a1.009,1.009,0,0,0-1-1Zm.2,1.2h6.8v.8H14Zm0,2h6.8v2H14Z"
   transform="translate(-6 -4.096)" fill={props?.color?props.color:"#98a0ac"}/>
</svg>
  )
}
export default MerchantAccount

