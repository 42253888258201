export const Reservation = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 98471">
            <g fill={props?.fill ?? "#c1c5cb"}>
                <path
                    data-name="Path 94999"
                    d="M8.713 14.042h.26a.253.253 0 1 0 0-.5h-.261v.252l.001.248Z"
                />
                <path
                    data-name="Path 95000"
                    d="M20.497 14.884h.3c.342-.006.5-.355.5-.676s-.134-.68-.508-.68h-.292v1.356Z"
                />
                <path
                    data-name="Path 95001"
                    d="M15.525 14.042h.26a.253.253 0 1 0 0-.5h-.26v.5Z"
                />
                <path
                    data-name="Path 95002"
                    d="M23.09 16.344V11.7a.7.7 0 0 0-.7-.7H7.617a.7.7 0 0 0-.7.7v4.641Zm-3.017-3.028a.211.211 0 0 1 .211-.213h.5c.556 0 .93.443.93 1.1a1 1 0 0 1-.912 1.1h-.518a.21.21 0 0 1-.149-.061.21.21 0 0 1-.061-.135.206.206 0 0 1 0-.043v-1.751Zm-1.462.019a.211.211 0 0 1 .211-.211h.743a.211.211 0 1 1 0 .422h-.532v.46h.478a.211.211 0 0 1 0 .422h-.478v.46h.532a.211.211 0 0 1 0 .422h-.743a.211.211 0 0 1-.211-.211Zm-1.935-.111a.211.211 0 0 1 .389.024l.422 1.228.432-1.232a.212.212 0 0 1 .4.14l-.633 1.805a.211.211 0 0 1-.4 0l-.62-1.8a.209.209 0 0 1 .01-.165Zm-1.579.1a.211.211 0 0 1 .211-.211h.474a.688.688 0 0 1 .7.674.677.677 0 0 1-.518.65l.465.528a.211.211 0 0 1-.317.279l-.593-.674v.535a.211.211 0 0 1-.422 0Zm-1.592.008a.211.211 0 0 1 .211-.211h.743a.211.211 0 1 1 0 .422h-.532v.46h.478a.211.211 0 0 1 0 .422h-.478v.46h.532a.211.211 0 0 1 0 .422h-.742a.211.211 0 0 1-.211-.211Zm-1.835 1.347a.211.211 0 0 1 .3.015.625.625 0 0 0 .46.193.308.308 0 0 0 .319-.209c.017-.1-.069-.189-.238-.252-.266-.1-.5-.2-.527-.214l-.029-.016a.552.552 0 0 1-.246-.546.612.612 0 0 1 .443-.494 1.017 1.017 0 0 1 .888.194.211.211 0 0 1-.271.324.6.6 0 0 0-.5-.114.193.193 0 0 0-.148.15.131.131 0 0 0 .048.125c.063.027.264.114.483.2a.656.656 0 0 1 .507.722.728.728 0 0 1-.734.557 1.053 1.053 0 0 1-.772-.331.211.211 0 0 1 .015-.3Zm-1.569-1.347a.211.211 0 0 1 .211-.211h.743a.211.211 0 1 1 0 .422h-.532v.46h.478a.211.211 0 1 1 0 .422h-.478v.46h.532a.211.211 0 0 1 0 .422h-.743a.211.211 0 0 1-.211-.211Zm-1.812-.008a.211.211 0 0 1 .211-.211h.474a.688.688 0 0 1 .7.674.677.677 0 0 1-.518.65l.465.528a.211.211 0 0 1-.317.279l-.593-.674v.535a.211.211 0 0 1-.422 0Z"
                />
                <path
                    data-name="Path 95003"
                    d="M23.648 19.507a.352.352 0 0 0 .352-.352v-1.406a.352.352 0 0 0-.352-.352H6.352a.352.352 0 0 0-.352.352v1.406a.352.352 0 0 0 .352.352Z"
                />
            </g>
            <path data-name="Rectangle 52155" fill="none" d="M0 0h30v30H0z" />
        </g>
    </svg>
)
