import * as React from "react"

export const Folder = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={43} height={35} {...props}>
    <path
      data-name="Path 97708"
      d="M38.7 4.393H19.35L15.05 0H4.3A4.36 4.36 0 0 0 0 4.393v8.786h43V8.786a4.36 4.36 0 0 0-4.3-4.393Z"
      fill="#ffb73f"
    />
    <path
      data-name="Path 97709"
      d="M38.7 4.393H4.3A4.349 4.349 0 0 0 0 8.765v21.863A4.349 4.349 0 0 0 4.3 35h34.4a4.349 4.349 0 0 0 4.3-4.372V8.765a4.349 4.349 0 0 0-4.3-4.372Z"
      fill="#ffe9a6"
    />
  </svg>
)
