import React from "react"
import { withNavBars } from "../../HOCs"
import  WorkingHoursMaster  from "./workingHoursMaster"

class UtilitiesMasterParent extends React.Component {
    render() { return <WorkingHoursMaster {...this.props} /> }
}
const props = { boxShadow: false }

export default withNavBars(UtilitiesMasterParent, props)
