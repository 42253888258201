import { Box, Grid, IconButton, Stack } from '@mui/material'
import React from 'react'
import FilterIMG from '../../../assets/filter'
import FilterIcon from '../../../assets/filterIcon'
import { FilterGenerator, SearchFilter, TableWithPagination } from '../../../components'
import { utilityReadingHeading, utilityReadingPath } from '../../../utils'
import { loadOptionsApis } from '../../../utils/asyncPaginateLoadOptions'
import { useStyles } from '../style'

export const UtilityReadingsTable = (
    { t = () => false,
        handleSearch = () => false,
        handleIcon = () => false,
        handlePagination = () => false,
        handleChangeLimit = () => false,
        totalRows = 0,
        page = 1,
        limit = 10,
        tableData = [],
        utilityData = {},
        searchText = "",
        drawerOpen = () => false,
        filterData = "",
        drawer = false,
        drawerClose = () => false,
        onApplyFilter = () => false,
        unitValue = "",
        selectedCompany = "",
        propertyValue = ""

    }) => {
    const classes = useStyles()
    const maualResponseTenants = (val) => {
        let result = val?.map((val) => {
            return {
                value: val?.value,
                label: val?.label
            }
        })
        return result;
    }

    // const { t } = props
    return (
        <>
            <Box p={2}>

                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <SearchFilter value={searchText} handleChange={(value) => handleSearch(value)} placeholder={t('Search By Utility Name')} customfieldSx={{ "& .MuiOutlinedInput-root": { height: "40px" } }} />
                    </Grid>
                    <Grid item xs={8} display={'flex'} justifyContent={'end'}>
                        <Box
                            display={"flex"}
                            sx={{ float: "right" }}>
                            <Stack
                                direction="row"
                                spacing={2}>
                                {filterData.utility?.length > 0 || filterData.date?.length > 0 ? (
                                    <IconButton onClick={drawerOpen}>
                                        <FilterIcon />
                                    </IconButton>
                                )

                                    : (
                                        <IconButton onClick={drawerOpen} className={classes.filterImg}>
                                            <FilterIMG />
                                        </IconButton>
                                    )}

                            </Stack>
                        </Box>
                    </Grid>
                </Grid>

                {/* Inspectiontable */}
                <TableWithPagination
                    heading={utilityReadingHeading(t)}
                    path={utilityReadingPath}
                    rows={tableData?.list ? tableData?.list : []}
                    handleIcon={handleIcon}
                    noDataSvg
                    tableType="no-side"
                    showpagination
                    handlePagination={handlePagination}
                    handleChangeLimit={handleChangeLimit}
                    totalRowsCount={tableData?.count}
                    page={page}
                    limit={limit}
                    dataType={[
                        { type: ["utility_reading"], name: "icon" },
                        { type: ["text"], name: "reading_no" },
                        { type: ["date"], name: "date" },
                        { type: ["text"], name: "Property_name" },
                        { type: ["text"], name: "unit_id" },
                        { type: ["text"], name: "utility_name" },
                        { type: ["text"], name: "meter_no" },
                        { type: ["text"], name: "units_consumed" },
                        { type: ["text"], name: "ratePerUOM" },
                        // { type: ["status"], name: "status" },
                    ]}
                    view={true}
                    edit={true}
                    delete={false}
                    height={'calc(100vh - 300px)'}
                />
                {/* filter drawer */}
                {
                    drawer &&
                    <FilterGenerator
                        open={drawer}
                        onClose={drawerClose}
                        components={[
                            {
                                component: "select",
                                value: filterData?.utility,
                                options: [],
                                isMulti: true,
                                label: t("Utility"),
                                placeholder: t("Select Utility"),
                                state_name: "utility",
                                loadOptions: (search, array, handleLoading) =>
                                    loadOptionsApis(
                                        "unit_meter_reading/get_utility",
                                        {
                                            // "search": "",
                                            "company_id": selectedCompany?.value,
                                            "unit_id": unitValue?.value,
                                        },
                                        search,
                                        array,
                                        handleLoading,
                                        "data",
                                        {},
                                        maualResponseTenants,

                                    ),

                                debounceTimeout: 800,
                                isPaginate: true,
                            },
                            {
                                component: "date_range",
                                value: filterData?.dateRange,
                                state_name: "dateRange",
                                label: "Date"
                            },

                        ]}
                        onApply={(value) => onApplyFilter(value)}
                    />
                }
            </Box >
        </>
    )
}
