import { Box, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { ExtraBold } from "../../utils";
const useStyles = makeStyles((theme) => (
  {
    dialog: {
      width: "100%",
      maxWidth: "auto",
      padding: "0 !important",

      "& .MuiPaper-root": {
        width: (props) => props?.width ? props?.width : "100% !important",
        maxWidth: (props) => props?.maxWidth ?? "auto",
        borderRadius: "4px",
        backgroundColor: (props) => props?.noBg ? "transparent" : "auto",
        boxShadow: (props) => props?.noBg ? "none" : "auto",
      },
      "& .MuiDialogContent-root": {
        padding: "0px !important",
        position: 'relative',

      },
      '& .MuiDialog-container': {
        padding: (props) => props?.padding ?? '',
      },

    },
    dialogScrollhide: {
      width: "100%",
      maxWidth: "auto",
      padding: "0 !important",

      "& .MuiPaper-root": {
        width: (props) => props?.width ? props?.width : "100% !important",
        maxWidth: (props) => props?.maxWidth ?? "auto",
        borderRadius: "4px",
        backgroundColor: (props) => props?.noBg ? "transparent" : "auto",
        boxShadow: (props) => props?.noBg ? "none" : "auto",
      },
      "& .MuiDialogContent-root": {
        padding: "0px !important",
        position: 'relative',
        "&::-webkit-scrollbar": {
          width: "0px !important",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "1em",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      },
      '& .MuiDialog-container': {
        padding: (props) => props?.padding ?? '',
      },

    },
    header: {
      border: (props) => props?.border ? "none" : "1px solid #E4E8EE",
      fontSize:"1rem",
      fontFamily: ExtraBold,
      padding: (props) => props?.header_padding ?? "auto",

    },

  }));
export function AlertDialog(props) {
  const classes = useStyles(props);
  return (
    <div>
      <Dialog
        id="dialog"
        open={props?.open}
        onClose={props?.onClose}
        aria-labelledby="alert-dialog-title"
        className={props?.scrollHide ? classes.dialogScrollhide : classes.dialog}
        maxWidth={props?.md ? "md" : props?.isNormal ? "xs" : props?.medium ? "sm" : props?.custom ? "45%" : "lg"}
        fullWidth
        aria-describedby="alert-dialog-description"

      >
        {
          !props?.isnotTitle &&
          <DialogTitle className={classes.header}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <span>{props?.header}</span>{" "}
              </Box>
              {
                props?.render &&
                <Box marginRight="12px">
                  {props?.render}
                </Box>
              }
              <IconButton sx={{ padding: props?.close_button_padding ?? "auto", }} onClick={props?.onClose}>
                <img
                  src="/images/close.svg"
                  alt="close"

                  style={{ cursor: 'pointer' }}
                />
              </IconButton>


            </Box >



          </DialogTitle >
        }

        <DialogContent style={{ padding: "0 !important" }} className={classes.content}>
          {props?.component}
        </DialogContent>
      </Dialog >
    </div >
  );
}
