import * as React from "react"

export const Oppertunity = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 105463">
      <path data-name="Rectangle 55517" fill="none" d="M0 0h20v20H0z" />
      <path
        d="M5.167 0A4.171 4.171 0 0 0 1 4.167v8.889a4.173 4.173 0 0 0 2.959 3.988A4.154 4.154 0 0 0 7.944 20h6.667a4.171 4.171 0 0 0 4.167-4.167V6.944a4.164 4.164 0 0 0-2.958-3.985A4.173 4.173 0 0 0 11.833 0Zm0 1.667h6.667a2.5 2.5 0 0 1 2.5 2.5v8.889a2.5 2.5 0 0 1-2.5 2.5H5.167a2.5 2.5 0 0 1-2.5-2.5V4.167a2.5 2.5 0 0 1 2.5-2.5ZM8.5 2.778a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0-2.5-2.5ZM6.833 8.889a1.947 1.947 0 0 0-1.944 1.944 3.611 3.611 0 1 0 7.222 0 1.947 1.947 0 0 0-1.944-1.944Z"
        fill={props?.color ?? "#c1c5cb"}
      />
    </g>
  </svg>
)
