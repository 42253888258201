import * as React from "react"

export const ApprovedWhite = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24.001} height={24} {...props}>
        <path
            data-name="icons8-instagram-check-mark (1)"
            d="M22.699 12.072a.132.132 0 0 1 0-.143l.988-1.544a1.978 1.978 0 0 0-.758-2.828l-1.63-.842a.135.135 0 0 1-.073-.126l.085-1.832a1.978 1.978 0 0 0-2.07-2.07l-1.833.085a.164.164 0 0 1-.125-.072l-.841-1.628a1.979 1.979 0 0 0-2.828-.758l-1.542.985a.132.132 0 0 1-.143 0L10.385.311a1.978 1.978 0 0 0-2.828.758l-.842 1.63a.133.133 0 0 1-.126.073l-1.832-.085a1.978 1.978 0 0 0-2.07 2.07l.085 1.833a.135.135 0 0 1-.072.125l-1.628.841a1.979 1.979 0 0 0-.758 2.828l.985 1.545a.132.132 0 0 1 0 .143l-.988 1.544a1.978 1.978 0 0 0 .758 2.828l1.628.842a.135.135 0 0 1 .073.126l-.086 1.833a1.986 1.986 0 0 0 .577 1.492 1.954 1.954 0 0 0 1.492.577l1.833-.085a.152.152 0 0 1 .125.072l.841 1.628a1.98 1.98 0 0 0 2.828.758l1.545-.989a.13.13 0 0 1 .143 0l1.544.988a1.978 1.978 0 0 0 2.828-.758l.842-1.628a.151.151 0 0 1 .126-.073l1.832.085a1.978 1.978 0 0 0 2.07-2.07l-.085-1.833a.135.135 0 0 1 .072-.125l1.628-.841a1.979 1.979 0 0 0 .758-2.828Zm-5.395-2.4a29.129 29.129 0 0 0-5.964 5.967.923.923 0 0 1-.741.36.923.923 0 0 1-.737-.372 11.839 11.839 0 0 0-2.848-2.859.923.923 0 1 1 1.023-1.537 12.309 12.309 0 0 1 2.562 2.355 30.407 30.407 0 0 1 5.612-5.407.923.923 0 1 1 1.088 1.489Z"
            fill="#f5f7fa"
        />
    </svg>
)

