import * as React from "react"

const PropertyLeaseAndSaleIcon = (props) => (
    <svg
        height={40}
        viewBox="0 0 64 64"
        width={40}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="m7.2 19 3.818 10h41.964L56.8 19z" fill="#ef6a6a" />
        <path d="m47.636 43 3.819-10h-38.91l3.819 10z" fill="#f9954b" />
        <path d="M17.891 47 24 63h16l6.109-16z" fill="#f9ca48" />
        <path d="M3 1v7l2.673 7h52.654L61 8V1z" fill="#66becc" />
        <g fill="#292233">
            <path d="M56.8 18H7.2a.998.998 0 0 0-.934 1.357l3.818 10c.148.387.52.643.935.643h41.963c.415 0 .786-.256.935-.644l3.818-10a1.004 1.004 0 0 0-.111-.924A1.003 1.003 0 0 0 56.8 18zm-4.507 10H11.707l-3.055-8h46.695zM51.454 32H12.546a.998.998 0 0 0-.934 1.357l3.817 10a1 1 0 0 0 .934.643h31.273c.415 0 .786-.256.935-.644l3.817-10A1 1 0 0 0 51.454 32zm-4.506 10H17.052l-3.054-8h36.004zM46.109 46H17.891a.998.998 0 0 0-.934 1.357l6.109 16A1 1 0 0 0 24 64h16c.415 0 .786-.256.935-.644l6.109-16a1.004 1.004 0 0 0-.111-.924 1.003 1.003 0 0 0-.824-.432zm-6.797 16H24.688l-5.346-14h25.314zM61 0H3a1 1 0 0 0-1 1v7c0 .122.022.243.065.356l2.673 7c.149.388.52.644.935.644h52.654c.415 0 .786-.256.935-.644l2.673-7C61.978 8.243 62 8.122 62 8V1a1 1 0 0 0-1-1zm-1 7.815L57.639 14H6.361L4 7.815V2h56z" />
            <path d="M32 58a1 1 0 0 1-1-1h-2c0 1.302.839 2.402 2 2.816V61h2v-1.184A2.996 2.996 0 0 0 35 57c0-1.654-1.346-3-3-3a1 1 0 1 1 1-1h2a2.996 2.996 0 0 0-2-2.816V49h-2v1.184A2.996 2.996 0 0 0 29 53c0 1.654 1.346 3 3 3a1 1 0 0 1 0 2z" />
        </g>
    </svg>
)

export default PropertyLeaseAndSaleIcon
