import makeStyles from '@mui/styles/makeStyles'
import { SemiBold } from '../../utils'
export const useStyles = makeStyles((theme) => ({
    root: {
        border: "2px solid white",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 16px #00000014",
        backgroundColor: "white",
        // padding: "16px",
        //height: `calc(100vh - 147px)`,
        overflow: "hidden",

    },
    description: {
        color: theme.typography.color.primary,
        fontSize:"0.875rem",
        fontFamily: SemiBold,
        '& .ql-editor': {
            padding: '0px 0px 16px 0px',
            color: theme.typography.color.primary,
            fontSize:"0.875rem",
            fontFamily: SemiBold,
        }
    },
    imageViewer: {
        '& .styles-module_close__2I1sI': {
            color: 'white',
            position: 'absolute',
            top: '67px',
            right: '100px',
            fontSize: '40px',
            fontWeight: 'bold',
            opacity: 0.2,
            cursor: 'pointer',
        }
    }
}))