import React from "react";

export const EditIcon = (props) => {
    const width = props.width ? props.width : "14";
    const height = props.height ? props.height : "14";
    const color = props.color ? props.color : "#5078e1";

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 14 14"
        >
            <path id="Outline" d="M16.425,5.01a2.571,2.571,0,0,0-1.824.752L6.444,13.919a1.29,1.29,0,0,0-.328.559L5.022,18.306a.553.553,0,0,0,.683.683L9.535,17.9h0a1.292,1.292,0,0,0,.557-.328L18.249,9.41a2.576,2.576,0,0,0-1.824-4.4Zm0,1.1a1.468,1.468,0,0,1,1.042.435h0a1.465,1.465,0,0,1,0,2.084l-.715.715L14.668,7.258l.715-.715A1.469,1.469,0,0,1,16.425,6.109ZM13.886,8.04l2.085,2.085L9.31,16.785a.187.187,0,0,1-.08.047l-2.872.821.821-2.873h0a.18.18,0,0,1,.047-.079Z" transform="translate(-5.001 -5.01)"
                fill={color}
            />
        </svg>
    );
};

