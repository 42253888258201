export const RightChevron = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={6.971}
        height={12.52}
        {...props}
    >
        <path
            data-name="icons8-expand-arrow (2)"
            d="M.25.698a.428.428 0 0 0 .132.3L5.646 6.26.382 11.524a.428.428 0 1 0 .605.605l5.566-5.567a.428.428 0 0 0 0-.605L.987.391A.428.428 0 0 0 .25.698Z"
            fill="#98a0ac"
            stroke="#98a0ac"
            strokeWidth={0.5}
        />
    </svg>
)