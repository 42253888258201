import * as React from "react"

export const TemplateAmount = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27.059} height={20} {...props}>
    <path
      d="M10 0a10 10 0 1 0 1.765 19.835 9.5 9.5 0 0 0 3.529 0 10 10 0 1 0 0-19.669 9.494 9.494 0 0 0-3.529 0A9.982 9.982 0 0 0 10 0Zm0 1.765a8.3 8.3 0 0 1 1.5.141.882.882 0 0 0 .2.034 8.237 8.237 0 0 1 0 16.119.883.883 0 0 0-.2.034A8.233 8.233 0 1 1 10 1.765Zm6.314.491a8.228 8.228 0 0 1 0 15.489 9.98 9.98 0 0 0 0-15.489Zm3.529 0a8.228 8.228 0 0 1 0 15.489 9.98 9.98 0 0 0 0-15.489ZM8.733 5.882a.883.883 0 0 0-.867.719l-.5 2.647a.883.883 0 0 0 .867 1.045h1.912a1.031 1.031 0 0 1 1.029 1.029c0 .851-.639 1.029-1.176 1.029a2.666 2.666 0 0 1-1.518-.4.883.883 0 0 0-1.09 1.385 4.314 4.314 0 0 0 2.61.781 2.745 2.745 0 0 0 2.94-2.794 2.8 2.8 0 0 0-2.794-2.794H9.3l.165-.882h2.3a.882.882 0 0 0 0-1.765Z"
      fill="#578c8f"
    />
  </svg>
)

