import React from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { useStylesCreation } from "./style";
import { CustomDatePicker, OutStandingCard } from "../../dashboard/components";
import { useWindowDimensions } from "../../../utils";
export const DatePicke = (props) => {
  //   classes
  const classes = useStylesCreation();
  const size = useWindowDimensions();
  return (
    <div className={classes.card}>
      <Box className={classes.expensesHeader} display="flex">
        <Box>
          <Typography variant="subtitle2" className={classes.Expensestitle}>
            Property Vs Handover date
          </Typography>
        </Box>
        <Box flexGrow={1}>
          <div style={{ float: "right" }}>
            <CustomDatePicker getDate={props?.getDate} />
          </div>
        </Box>
      </Box>
      <Divider />
      <Box style={{ height: size?.height - 380 }}>
      <Grid
        container
        className={classes.grid}
      >
        {props?.outStatndingData?.length > 0 ? (
          props?.outStatndingData?.map((item, index) => {
            return (
              <Grid item xs={12}>
                <Grid container spacing={3} className={classes.cardHandover}>
                    <Grid item xs={2} alignItems="center">
                      <Typography className={classes.num}>
                        {12 + " AM"}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <OutStandingCard data={item} type={2} />
                    </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12} className={classes.noData}>
            <Typography className={classes.title}>{"No data found"}</Typography>
          </Grid>
        )}
      </Grid>
      </Box>
    </div>
  );
};
