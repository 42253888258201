export const WorkOrderIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_116017" data-name="Group 116017" transform="translate(0.001)">
                <g id="Rectangle_58161" data-name="Rectangle 58161" transform="translate(-0.001)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="20" height="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
                </g>
                <path id="icons8-bill_10_" data-name="icons8-bill (10)" d="M6.025,5A2.027,2.027,0,0,0,4,7.025v10.8a.675.675,0,0,0,.838.655l1.637-.409,1.637.409a.67.67,0,0,0,.327,0l1.637-.409,1.637.409a.675.675,0,0,0,.838-.655v-.389a5.625,5.625,0,1,0,0-8.221V7.025a.675.675,0,1,1,1.35,0v.269A6.469,6.469,0,0,1,15.238,6.9,2.022,2.022,0,0,0,13.225,5Zm.45,3.6h3.6a.675.675,0,1,1,0,1.35h-3.6a.675.675,0,1,1,0-1.35Zm9.9.45a4.275,4.275,0,1,1-3.825,6.183V11.417A4.261,4.261,0,0,1,16.375,9.05Zm-.02,1.8a.675.675,0,0,0-.193.034l-1.35.45a.675.675,0,1,0,.427,1.281l.461-.154v2.663a.675.675,0,1,0,1.35,0v-3.6a.675.675,0,0,0-.7-.675Zm-9.88.45h3.6a.675.675,0,1,1,0,1.35h-3.6a.675.675,0,1,1,0-1.35Zm0,2.7h2.25a.675.675,0,1,1,0,1.35H6.475a.675.675,0,1,1,0-1.35Zm6.943,5.132a2.018,2.018,0,0,1-1.543.718,2.145,2.145,0,0,1-.446-.049l-1.5-.337-1.111.31a2.017,2.017,0,0,1-.545.076,2.074,2.074,0,0,1-.549-.076L6.7,19.485v1.94a.674.674,0,0,0,.823.658l1.833-.412,1.436.4a.673.673,0,0,0,.366,0l1.435-.4,1.833.412a.668.668,0,0,0,.149.017.674.674,0,0,0,.675-.675V19.748A6.462,6.462,0,0,1,13.418,19.132Z" transform="translate(-3.054 -3.551)" fill="#896db3" />
            </g>
        </svg>

    )
}