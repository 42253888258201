import React from "react"
export const MultiSelectIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
        <g id="Group_113387" data-name="Group 113387" transform="translate(-541 -418)">
            <g id="Rectangle_57465" data-name="Rectangle 57465" transform="translate(541 418)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                <rect width="26" height="26" stroke="none" />
                <rect x="0.5" y="0.5" width="25" height="25" fill="none" />
            </g>
            <path id="icons8-checkbox" d="M5.25,12A3.264,3.264,0,0,0,2,15.25v7.682a3.264,3.264,0,0,0,3.25,3.25h7.682a3.264,3.264,0,0,0,3.25-3.25V15.25A3.264,3.264,0,0,0,12.932,12Zm0,1.773h7.682a1.464,1.464,0,0,1,1.477,1.477v7.682a1.464,1.464,0,0,1-1.477,1.477H5.25a1.464,1.464,0,0,1-1.477-1.477V15.25A1.464,1.464,0,0,1,5.25,13.773Zm6.778,2.356a.886.886,0,0,0-.609.268l-3.51,3.51L6.468,18.464a.886.886,0,1,0-1.254,1.253l2.068,2.068a.886.886,0,0,0,1.253,0l4.136-4.136a.886.886,0,0,0-.644-1.521Zm7.4,2.076a.886.886,0,1,0,0,1.773h7.682a.886.886,0,1,0,0-1.773Z" transform="translate(539 411.454)" fill="#78b1fe" />
        </g>
    </svg>

)